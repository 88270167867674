import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    IconButton,
    Table,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import {useTemplatesImportCsv} from "../../../hooks/templates/dialogs/useTemplatesImportCsv";
import {DialogContent} from "../../../../../../newShared/components/editorUnion/components/editorDialog/styled";
import React, {useEffect, useState} from "react";
import * as S from './styled';
import {FileDrop} from "react-file-drop";
import InsertDriveFileSharpIcon from '@mui/icons-material/InsertDriveFileSharp';
import {FileDropZone} from "../../../../../../newShared/components/fileDropZone";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import colors from "../../../../../../newShared/theme/colors";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {KycCsvDraggableRow} from "./components/kycCsvDraggableRow";
import {TKycCsvFieldWithAlias} from "../../../types";
import {DragIndicator, InfoOutlined} from "@mui/icons-material";
import {LightTooltip} from "../../../../../../newShared/components/editorUnion/components/editorSideMenu/styled";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {FILE_FIELD_TYPE} from "../../../constants";


export const TemplatesImportCsvDialog = () => {
    const {template, isOpen, handleClose, handleContinue, fileUpload, step, handlePerformCsv, uploadCsvResponse, isLoadingPerformCsv, isLoadingUploadCsv, handleDeleteFile} = useTemplatesImportCsv();

    const [mismatchFields, setMismatchFields] = useState<TKycCsvFieldWithAlias[]>([]);
    useEffect(() => {
        if (uploadCsvResponse) {
            setMismatchFields(
                uploadCsvResponse.fields
                    .map(e => ({
                        ...e,
                        mappedField: e.templateField === '-' ? '' : e.templateField,
                        isKey: false, skip: false,
                        templateField: undefined
                    }))
                    .map(e => ({...e, csvField: e.csvField === '-' && e.mappedField.length > 0 ? '' : e.csvField}))
                    // .sort((a,b) => (a.mappedField.length - b.mappedField.length > 1) ? -1 : 1)
            )

            const field = uploadCsvResponse.fields.find(e => e.templateField === '-' || e.templateField === '');
            field && setTimeout(() => {
                document.body.querySelector(`#${field.csvField.replaceAll(' ', '_')}`)?.scrollIntoView({behavior: 'smooth'});
            }, 200)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadCsvResponse?.fields])

    const handleDropOnTemplate = (mappedField: string) => (field: TKycCsvFieldWithAlias) => {
        setMismatchFields(prev => {
            if (mappedField === field.mappedField) return prev;

            const newPrev = JSON.parse(JSON.stringify(prev)) as TKycCsvFieldWithAlias[];

            const filled = newPrev.find(e => e.mappedField === mappedField);
            if (filled?.csvField.length) {
                newPrev.push({...filled, mappedField: ''});
            }


            return newPrev
                .filter(e => !(e.mappedField === '' && e.csvField === field.csvField))
                .map(e =>
                    e.mappedField === mappedField
                        ? ({...field, mappedField, skip: false})
                        : e.csvField === field.csvField
                            ? {...e, csvField: '', isKey: false, skip: false}
                            : e
                );
        });
    };

    const handleUnassign = (_field: TKycCsvFieldWithAlias) => {
        const field = JSON.parse(JSON.stringify(_field)) as TKycCsvFieldWithAlias;

        setMismatchFields(prev => {
            const index = prev.findIndex(e => e.csvField === field.csvField);
            if (index > -1) {
                prev[index].csvField = '';
                prev[index].isKey = false;
            }
            prev.push({...field, mappedField: ''})
            return [...prev];
        });

        setTimeout(() => {
            const el = document.body.querySelector(`#${field.csvField.replaceAll(' ', '_')}`);
            el?.scrollIntoView({behavior: 'smooth'})
        }, 100)
    }

    const handleChangeCheckbox = (field: TKycCsvFieldWithAlias) => {
        setMismatchFields(prev => {
            const index = prev.findIndex(e => e.csvField === field.csvField);
            if (index > -1) {
                prev[index].skip = field.skip;
                prev[index].isKey = field.isKey;
            }
            return [...prev];
        });
    };

    const resultMismatchFields = mismatchFields
        .filter(e => e.csvField !== '')
        .map(e => ({
            csvField: e.csvField,
            isKey: e.isKey,
            mappedField: (e.skip || e.mappedField === '')
                ? ''
                : e.mappedField
        }))

    return(
        <Dialog open={isOpen} onClose={handleClose} sx={{
            '& .MuiDialog-paper': {
                width: 'min(100vw, 800px)',
                maxWidth: 'min(100vw, 800px)',
            }
        }}>
            <DialogTitle>{'Import CSV'}</DialogTitle>

            <DialogContent sx={{padding: '0 16px', display: 'flex', flexDirection: 'column'}}>
                {step === 'upload' &&
                    <>
                        {!!fileUpload.uploadedFile ?
                            <S.FileRowContainer>
                                <InsertDriveFileSharpIcon sx={{fill: '#90CAF9'}}/>
                                <Typography>{fileUpload.uploadedFile.name}</Typography>

                                <IconButton onClick={handleDeleteFile}>
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </S.FileRowContainer>
                            :
                            <FileDrop onTargetClick={fileUpload.filePicker} onDrop={fileUpload.fileHandler}>
                                <FileDropZone
                                    accept={'.csv'}
                                    inputRef={fileUpload.ref}
                                    fileHandler={fileUpload.fileHandler}
                                />
                            </FileDrop>
                        }
                    </>
                }

                {step === 'structureMismatch' &&
                    <>
                        <Typography variant={'*bodyText2'} color={colors.text.dark}>{("We have identified a structure mismatch while processing your uploaded CSV file.")}</Typography>
                        <Typography variant={'*bodyText2'} color={colors.text.dark} sx={{mt: '14px'}}>{("For precise data integration, please assign CSV fields with corresponding template fields. Any unassigned fields will not be uploaded. To incorporate these fields, consider modifying the template accordingly.")}</Typography>

                        <S.CsvHeadersTableContainer>
                            <Table stickyHeader>
                                <S.CsvHeadersTableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant={'body2'} color={colors.text.grey_dark}>{('Template field')}</Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Box sx={{display: 'flex', alignItems: 'center', gap: '10px',}}>
                                                <DragIndicator sx={{opacity: 0}}/>
                                                <Typography variant={'body2'} color={colors.text.grey_dark}>{('CSV field')}</Typography>
                                            </Box>
                                        </TableCell>

                                        <TableCell>
                                            <Flex ai={"center"} gap={'12px'}>
                                                <Typography variant={'body2'} color={colors.text.grey_dark}>{('Ignore')}</Typography>
                                                <LightTooltip title={('Exclude from import')}>
                                                    <InfoOutlined fontSize={"small"}/>
                                                </LightTooltip>
                                            </Flex>
                                        </TableCell>

                                        <TableCell>
                                            <Flex ai={"center"} gap={'12px'}>
                                                <Typography variant={'body2'} color={colors.text.grey_dark}>{('Unique')}</Typography>
                                                <LightTooltip title={('Use as key value to avoid client duplication')}>
                                                    <InfoOutlined fontSize={"small"}/>
                                                </LightTooltip>
                                            </Flex>
                                        </TableCell>
                                    </TableRow>
                                </S.CsvHeadersTableHead>

                                <S.CsvHeadersTableBody>
                                    {mismatchFields
                                        .map(e => {
                                            return (
                                                <KycCsvDraggableRow
                                                    key={e.mappedField + e.csvField}
                                                    field={e}
                                                    isDisabled={uploadCsvResponse?.fields.some(f => f.csvField === e.csvField && f.templateField === e.mappedField && e.csvField !== '' && e.mappedField !== '')}
                                                    isFile={template?.fields.find(f => f.name === e.mappedField)?.type === FILE_FIELD_TYPE}
                                                    onDrop={handleDropOnTemplate(e.mappedField)}
                                                    onChange={handleChangeCheckbox}
                                                    onUnassign={() => handleUnassign(e)}
                                                />
                                            )
                                        })}
                                </S.CsvHeadersTableBody>
                            </Table>
                        </S.CsvHeadersTableContainer>
                    </>
                }

                {step === 'success' && <Typography variant={'body1'} color={colors.grayText} sx={{lineHeight: 'normal'}}>{('The file has been added to processing.')}</Typography>}
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'} size={'medium'}>{step === 'success' ? ('Close') : ('Cancel')}</Button>
                {step === 'upload' ?
                    <LoadingButton onClick={handleContinue} size={'medium'} loading={isLoadingUploadCsv}>{('Upload')}</LoadingButton>
                    :
                    <>
                        {step === 'structureMismatch' ? (
                            <LoadingButton size={'medium'} loading={isLoadingPerformCsv}
                                           disabled={resultMismatchFields.every(e => e.mappedField === '')}
                                           onClick={() => handlePerformCsv(resultMismatchFields)}>
                                {('Proceed')}
                            </LoadingButton>
                        ) : <></>}
                    </>
                }
            </DialogActions>
        </Dialog>
    )
}
