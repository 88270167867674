//core
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

//components
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {dialogs, hideDeleteTarget, loadings} from "../../store/slice";
import {DeleteTarget} from "../../store/actions";
import CommonLoading from "../../../../../newShared/components/Basic/CommonLoading";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useHistory} from "react-router-dom";
import {PATH_LOCAL_VULNERABILITY_SCANNED} from "../../../../../newShared/constants";
import {Flex} from '../../../../../newShared/components/Layouts';
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";


export const DeleteTargetDialog = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {isOpen, targetId, targetName} = useSelector(dialogs).deleteTarget;
    const isLoading = useSelector(loadings).isLoadingDeleteTarget;
    const {setMessage} = useMessageDialog();
    const {t} = useMainTranslation('', {keyPrefix: 'vulnerabilityScanner'});

    const onSuccess = () => {
        setMessage({message:t(`Target {{targetName}} has been deleted.`, {targetName}), title: t('Completed successfully')});
        history.push(PATH_LOCAL_VULNERABILITY_SCANNED);
    }

    const handleClick = () => {
        targetId && dispatch(DeleteTarget({targetId, onSuccess}));
    }

    const handleCloseButton = () => {
        dispatch(hideDeleteTarget());
    }

    return(
        <Dialog
            open={isOpen}
            maxWidth={"xs"}
            fullWidth
            onClose={handleCloseButton}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>{t('Delete Target')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('If you delete information about a target {{targetName}}, you will not be able to restore it.', {targetName: 'targetName'})}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {isLoading ? <Flex w={'100%'} ai={'center'} jc={'center'}><CommonLoading/></Flex> :
                    <>
                        <Button onClick={handleCloseButton} variant={'text'}>{t('Cancel')}</Button>
                        <Button onClick={handleClick} variant={'contained'}>{t('Delete')}</Button>
                    </>
                }
            </DialogActions>
        </Dialog>
    );
}
