import {FC, MouseEventHandler} from "react";
import {IconButton} from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import {withShrinkZero} from "../../../hoc/withShrinkZero";
import {EditorTooltip} from "../../../commonComponents/editorTooltip";


interface UndoRedoControlProps {
    undoDisabled: boolean;
    redoDisabled: boolean;
    undoClickHandler?: MouseEventHandler<HTMLButtonElement>;
    redoClickHandler?: MouseEventHandler<HTMLButtonElement>;
}

const UndoRedoControl: FC<UndoRedoControlProps> = ({ undoDisabled, redoDisabled, undoClickHandler, redoClickHandler }) => {

    return <>
        <EditorTooltip title="Undo">
            <span>
                <IconButton disabled={undoDisabled} onClick={undoClickHandler}>
                <UndoIcon/>
            </IconButton>
            </span>
        </EditorTooltip>
        <EditorTooltip title="Redo">
            <span>
                <IconButton disabled={redoDisabled} onClick={redoClickHandler}>
                <RedoIcon/>
            </IconButton>
            </span>
        </EditorTooltip>
    </>;
}

const wrapper = withShrinkZero<UndoRedoControlProps>(UndoRedoControl);

export {wrapper as UndoRedoControl};
