import {Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import AddIcon from "@mui/icons-material/Add";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {useExactHRSetting} from "../../hooks/useExactHRSetting";
import {TableRowSkeleton} from "../../../../../newShared/components/tableRowSkeleton";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {SettingsTypes} from "../../types";
// import colors from "../../../../../newShared/theme/colors";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {AddSettingsDialog} from "../../components/dialogs/addSettingsDialog";
import {EditSettingDialog} from "../../components/dialogs/editSettingDialog";
import {DeleteSettingDialog} from "../../components/dialogs/deleteSettingDialog";
import {DeleteForever, Edit} from "@mui/icons-material";
import colors from "../../../../../newShared/theme/colors";


export const ExactHRSetting = () => {
    const {
        id,
        settings,
        isLoadingHrSettings,
        normalizedName,
        handleCreateNewItem,
        handleGoBack,

        handleOpenEditSetting,
        handleOpenDeleteSetting,
    } = useExactHRSetting();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathHRManagement.settings'});
    const {isMobile, isLessThen1050} = useMedia();


    return(
        <>
            <Box width={'100%'} height={'100%'} display={'flex'} flexDirection={'column'}>
                <PageTitleAndActions
                    title={t(normalizedName)}
                    handleGoBack={handleGoBack}
                    actions={[
                        {
                            onClick: () => handleCreateNewItem(id),
                            variant: 'text',
                            icon: <AddIcon />,
                            title: t('Add new item'),
                            disabled: isLoadingHrSettings
                        }
                    ]}
                />

                <TableContainer sx={{mt: '10px'}}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{width: '50%'}} align={revDir ? 'right' : 'left'}>{t('Name')}</TableCell>
                                {id !== 'customFields' && <TableCell align={revDir ? 'right' : 'left'}>{t('Employees')}</TableCell>}
                                <TableCell sx={{width: '50%'}} align={revDir ? 'right' : 'left'}>{}</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            { id &&
                                <>
                                    {(settings && ![...Object.keys(settings[id as SettingsTypes])].length && !isLoadingHrSettings) && <TableRow><TableCell>{t('Nothing found. Create new one.')}</TableCell></TableRow>}
                                    {settings && settings[id as SettingsTypes] && [...Object.keys(settings[id as SettingsTypes])].map(e => (
                                        <TableRow key={e}>
                                            <TableCell sx={{width: id === 'customFields' ? '80%' : '50%'}} align={revDir ? 'right' : 'left'}>{e}</TableCell>
                                            {id !== 'customFields' && <TableCell sx={{padding: !isMobile ? '16px : 32px' : undefined}} align={isMobile ? 'center' : revDir ? 'right' : 'left'}>{settings[id as SettingsTypes][e]}</TableCell>}
                                            
                                            <TableCell align={revDir ? 'right' : 'left'}>
                                                <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                                                    <IconButton
                                                        onClick={() => handleOpenEditSetting(e, id)}
                                                        sx={{color: colors.text.blue, padding: '0', mr: '16px', ml: '20px'}}
                                                        size={isLessThen1050 ? 'small' : 'large'}>
                                                        <Edit/>
                                                    </IconButton>
                                                    <IconButton
                                                        sx={{color: colors.text.blue, padding: '0'}}
                                                        onClick={() => handleOpenDeleteSetting(e, id)}
                                                        size={isLessThen1050 ? 'small' : 'large'}>
                                                        <DeleteForever/>
                                                    </IconButton>

                                                </Box>

                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </>
                            }
                            {isLoadingHrSettings && <TableRowSkeleton columns={2} />}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {/*DIALOGS*/}
            <AddSettingsDialog onSuccess={() => {}} />
            <EditSettingDialog />
            <DeleteSettingDialog />
        </>
    )
}