import React, {FC, RefObject} from "react";
import {useDrag, useDrop} from "react-dnd";
import {QUESTION_OPTION_DND_TYPE} from "../../../../../constants";
import {Close, DragIndicator} from "@mui/icons-material";
import {Checkbox, Icon, IconButton, Radio, TextField, Typography} from "@mui/material";
import {TextFieldWithHelperPopover} from "../../../../../../../../newShared/components/textFieldWithPopover";
import colors from "../../../../../../../../newShared/theme/colors";
import {
    FlexRow
} from "../../../../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import {useCustomFormik} from "../../../../../../../../newShared/hooks/useCustomFormik";
import {MainTrainingExamFormik, MainTrainingExamFormikQuestion} from "../../../../../types";
import {FormikErrors} from "formik";
import {TrainingExamQuestionInput, TrainingExamQuestionType} from "../../../../../../../../newShared/GQLTypes";
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {handleMinMaxStep} from "../../../../../helpers";

type ExamViewQuestionOptionProps = {
    questionIndex: number;
    optionIndex: number;
    masterFormik: ReturnType<typeof useCustomFormik<MainTrainingExamFormik>>;
    setOptionCheckedRef: RefObject<(optionId: string, checked: boolean) => void>;
    moveOptionRef: RefObject<(dragId: string, toId: string) => void>;
    handleRemoveOptionRef: RefObject<(optionId: string) => void>;
}
export const ExamViewQuestionOption: FC<ExamViewQuestionOptionProps> = React.memo(({questionIndex, optionIndex, masterFormik: formik, setOptionCheckedRef, moveOptionRef, handleRemoveOptionRef}) => {
    const {t} = useMainTranslation();

    const row = formik.values.questions[questionIndex];
    const touched = formik.touched.questions?.[questionIndex];
    const errors = formik.errors.questions?.[questionIndex] as FormikErrors<TrainingExamQuestionInput> | undefined;

    const option = row.options[optionIndex];

    const getOptionErrorField = (field: keyof FormikErrors<TrainingExamQuestionInput["options"][number]>) => {
        const error = errors?.options;
        if (Array.isArray(error)) {
            const optionError = error[optionIndex];

            if (typeof optionError === 'object') {
                const optionErrorExact = optionError?.[field];
                return typeof optionErrorExact === "string" ? optionErrorExact : undefined;
            }
        }

        return undefined;
    };

    const [{ isDragging }, drag, preview] = useDrag(() => ({
        type: row.questionId + QUESTION_OPTION_DND_TYPE,
        item: { dragId: option.optionId },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }), [option.optionId]);

    const [, drop] = useDrop(() => ({
        accept: row.questionId + QUESTION_OPTION_DND_TYPE,
        hover: ({dragId}: { dragId: string }, monitor) => {
            if (dragId === option.optionId) {
                return;
            }
            moveOptionRef.current?.(dragId, option.optionId);
        },
    }), [moveOptionRef, option.optionId]);

    const opacity = isDragging ? 0 : 1;

    return (
        <FlexRow key={option.optionId} sx={{gap: '16px', alignItems: 'flex-start', opacity}}
                 ref={node => drop(preview(node as HTMLDivElement))}
        >
            <FlexRow sx={{gap: '16px', mt: '9px'}}>
                <Icon ref={drag}>
                    <DragIndicator/>
                </Icon>

                {row.type === TrainingExamQuestionType.Checkboxes &&
                    <Checkbox disableRipple
                              sx={{
                                  p: '0',
                                  color: !!getOptionErrorField('correct') ? "red" : undefined
                              }}
                              checked={option.correct}
                              onChange={(_, checked) => setOptionCheckedRef.current?.(option.optionId, checked)}
                    />
                }

                {row.type === TrainingExamQuestionType.MultipleChoice &&
                    <Radio disableRipple
                           sx={{
                               p: '0',
                               '& > span': {
                                   color: !!getOptionErrorField('correct') ? "red" : undefined
                               },
                           }}
                           checked={option.correct}
                           onChange={() => setOptionCheckedRef.current?.(option.optionId, true)}
                    />
                }
            </FlexRow>

            <TextField name={`questions[${questionIndex}]options[${optionIndex}]option`}
                       id={`questions[${questionIndex}]options[${optionIndex}]option`}
                       placeholder={t('Enter text...')}
                       variant={'outlined'}
                       size={"small"}
                       value={option.option}
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       error={touched?.options?.[optionIndex]?.option && !!getOptionErrorField('option')}
                       helperText={touched?.options?.[optionIndex]?.option && getOptionErrorField('option')}
                       sx={{
                           flexGrow: 1,
                           '& .MuiOutlinedInput-root:not(.Mui-focused)': {
                               '& fieldset': {
                                   border: 'none',
                               },
                           },
                       }}
            />

            {row.pointsAutoCount && option.correct &&
                <>
                    <TextFieldWithHelperPopover name={`questions[${questionIndex}]options[${optionIndex}]points`}
                                                variant={'outlined'}
                                                size={"small"}
                                                value={option.points}
                                                onChange={handleMinMaxStep(1, undefined, 1, formik.handleChange)}
                                                onBlur={formik.handleBlur}
                                                error={touched?.options?.[optionIndex]?.points && !!getOptionErrorField('points')}
                                                helperText={touched?.options?.[optionIndex]?.points && getOptionErrorField('points')}
                                                sx={{width: '70px', flexShrink: 0}}
                                                type={'number'}
                                                InputProps={{ inputProps: { min: 5, step: 5 }}}
                    />

                    <Typography variant={'*bodyText2'} color={colors.text.dark} sx={{mt: '9px'}}>
                        {t('points')}
                    </Typography>
                </>
            }

            {option.correct &&
                <Typography variant={'*bodyText2'} color={colors.text.success} sx={{mt: '9px', whiteSpace: 'nowrap'}}>
                    {t('Correct')}
                </Typography>
            }

            <IconButton size={"small"} onClick={e => {
                e.stopPropagation();
                handleRemoveOptionRef.current?.(option.optionId);
            }}>
                <Close/>
            </IconButton>
        </FlexRow>
    )
}, (prevProps, nextProps) => {

    return (
        prevProps.questionIndex === nextProps.questionIndex
        && prevProps.setOptionCheckedRef === nextProps.setOptionCheckedRef

        && JSON.stringify(prevProps.masterFormik.values.questions[prevProps.questionIndex].pointsAutoCount) === JSON.stringify(nextProps.masterFormik.values.questions[nextProps.questionIndex].pointsAutoCount)

        && JSON.stringify(prevProps.masterFormik.values.questions[prevProps.questionIndex].options?.[prevProps.optionIndex]) === JSON.stringify(nextProps.masterFormik.values.questions[nextProps.questionIndex].options?.[prevProps.optionIndex])
        && JSON.stringify(prevProps.masterFormik.touched.questions?.[prevProps.questionIndex]?.options?.[prevProps.optionIndex]) === JSON.stringify(nextProps.masterFormik.touched.questions?.[nextProps.questionIndex]?.options?.[nextProps.optionIndex])
        && JSON.stringify(((prevProps.masterFormik.errors.questions?.[prevProps.questionIndex] as FormikErrors<MainTrainingExamFormikQuestion>)?.options as FormikErrors<MainTrainingExamFormikQuestion["options"]>)?.[prevProps.optionIndex]) === JSON.stringify(((nextProps.masterFormik.errors.questions?.[nextProps.questionIndex] as FormikErrors<MainTrainingExamFormikQuestion>)?.options as FormikErrors<MainTrainingExamFormikQuestion["options"]>)?.[nextProps.optionIndex])
    )
})