import {Box, CircularProgress, circularProgressClasses, CircularProgressProps} from "@mui/material";
import {FC} from "react";
import {Flex} from "../../Layouts";
import {Typo} from "../../Typography";
import {useMainTranslation} from "../../../hooks/useMainTranslationHooks/useMainTranslation";

type TLoadingSize = 'mini' | 'small' | 'medium' | 'large'

type TCommonLoading = CircularProgressProps & {
    loadingSize?: TLoadingSize,
    margin?: string

}

const CommonLoading: FC<TCommonLoading> = ({loadingSize = 'medium', margin = '0', ...props}) => {
    const {t} = useMainTranslation('', {keyPrefix: 'loading'});
    const sizes: {[key: string]: {diameter: number, m: string}} = {
        mini: {diameter: 12, m: '8px 0 0 0'},
        small: {diameter: 16, m: '12px 0 0 0'},
        medium: {diameter: 20, m: '16px 0 0 0'},
        large: {diameter: 28, m: '24px 0 0 0'},
    }

    return (
        <Flex ai={'center'} jc={'center'} direction={'column'} m={margin}>
            <Box sx={{ position: 'relative' }}>
                {/*<CircularProgress*/}
                {/*    variant="determinate"*/}
                {/*    sx={{*/}
                {/*        color: (theme) => theme.palette.primary.light,*/}
                {/*    }}*/}
                {/*    size={sizes[loadingSize]}*/}
                {/*    thickness={4}*/}
                {/*    {...props}*/}
                {/*    value={100}*/}
                {/*/>*/}
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                        color: (theme) => (theme.palette.lightPrimary.dark),
                        animationDuration: '550ms',
                        position: 'absolute',
                        left: loadingSize === 'large' ? '-15px' : '-10px',

                        borderTop: '1px solid #90CAF9',
                        borderRight: '1px solid #90CAF9',
                        borderBottom: '1px solid #90CAF9',
                        borderLeft: '1.5px solid #106EBE',
                        borderRadius: '50%',

                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',

                        },
                    }}
                    size={sizes[loadingSize].diameter}
                    thickness={0}
                    {...props}
                />


            </Box>
            <Flex ai={'center'}>
                <Typo margin={sizes[loadingSize].m} fontSize={'14px'} color={'#106EBE'}>{t('Loading...')}</Typo>
            </Flex>

        </Flex>

    )
}

export default CommonLoading;