import {useAppDispatch} from "../../../../../newShared/redux";
import {useSelector} from "react-redux";
import {
    assetsDialogsSelector,
    assetsSettingsSelector,
    loadings,
    selectedAssetSelector,
    setChangeStatusDialogAction,
    setCreateSettingRowDialogAction
} from "../../store/store";
import {changeAssetStatusAction} from "../../store/actions";
import {TSetChangeStatusDialog} from "../../types";
import {useEffect, useState} from "react";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {PhysicalAssetStatusCountModel, WorkspaceCollaboratorModel} from "../../../../../newShared/GQLTypes";


export const useChangeStatusDialog = () => {
    const dispatch = useAppDispatch();
    // const id = useParams<{id: string}>().id;
    const {changeStatus: {isOpen, response}, createSettingRow: {isOpen: isOpenCreateSettingRow}}= useSelector(assetsDialogsSelector);
    const {isLoadingExact, isLoadingSettings} = useSelector(loadings);
    const _selected = useSelector(selectedAssetSelector);
    const settings = useSelector(assetsSettingsSelector);
    const {setMessage} = useMessageDialog();

    const [status, setStatus] = useState<{name: string, status: string} | null>(null);
    const [employee, setEmployee] = useState<WorkspaceCollaboratorModel | null>(null);

    useEffect(() => {
        if (_selected && isOpen) {
            setStatus(_selected.status);
            setEmployee(_selected.assignedTo || null);

        } else {
            setStatus(null);
            setEmployee(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isOpen, _selected])

    const handleSetStatus = (title: string) => {
        const newStatus = settings?.status.find(({name}) => name === title);
        if (newStatus?.status.toLowerCase() !== 'deployed') setEmployee(null);
        setStatus({name: title, status: newStatus?.status || ''});
    }

    const setChangeStatusDialog = (data: TSetChangeStatusDialog) => {dispatch(setChangeStatusDialogAction(data))}

    const handleClose = () => setChangeStatusDialog({clear: true})

    const isOk = () => {
        if (status?.status.toLowerCase() === 'deployed') {
            if (_selected?.status.name.toLowerCase() === status?.name.toLowerCase())
                return Boolean(employee?.publicId && _selected?.statuses?.[_selected?.statuses?.length - 1]?.action?.employee?.id !== employee.publicId)
            else return Boolean(employee?.publicId)
        }
        else return status && _selected && _selected.status.name !== status.name
    }

    const handleConfirm = () => {
        const newStatus: PhysicalAssetStatusCountModel | undefined = settings?.status.find(s => s.name === status?.name);

        if (newStatus && newStatus.status.toLowerCase() === 'deployed' && employee?.publicId && _selected)
            dispatch(changeAssetStatusAction({
                data: {organizationId: '', workspaceId: '', assignedTo: employee.publicId, status: {name: newStatus.name, status: newStatus.status}, assetId: _selected.id},
                onSuccess
            }))
        else (status && _selected && newStatus) && dispatch(changeAssetStatusAction({
            data: {organizationId: '', workspaceId: '', assignedTo: '', status: {name: status.name, status: newStatus.status}, assetId: _selected.id}, onSuccess
        }))
    }

    const openCreateSettingRowDialog = () => {
        dispatch(setCreateSettingRowDialogAction({isOpen: true, field: 'status', assetType: 'physical'}))}

    const onSuccess = () => {
        setMessage({title: 'Completed successfully', message: 'The status has been changed successfully.'});
    }




    return {
        isOk,
        settings,
        isOpen,
        isOpenCreateSettingRow,
        responseChange: response,

        status,
        setStatus,
        employee,
        setEmployee,

        isLoadingExact,
        isLoadingSettings,

        handleClose,
        handleConfirm,
        handleSetStatus,

        openCreateSettingRowDialog
    }
}