import {MouseEventHandler, useState} from "react";
import {TToolBarHandlerAction, TToolBarHandlerPayload} from "../../../types";

export interface UsePageControlProps {
    initialPageOpen?: boolean;
    changePageControlStatusHandler?: (action: TToolBarHandlerAction, payload: TToolBarHandlerPayload) => void;
}

export interface UsePageControlValue {
    isPagesOpen: boolean;
    togglePageBar: MouseEventHandler<HTMLButtonElement>;
}

export const usePageControl = ({initialPageOpen = false, changePageControlStatusHandler}: UsePageControlProps): UsePageControlValue => {
    const [isPagesOpen, changePagesOpen] = useState(() => !!initialPageOpen);

    const togglePageBar: UsePageControlValue["togglePageBar"] = (event) => {
        event.stopPropagation();

        let status = !isPagesOpen;
        changePagesOpen(status);
        changePageControlStatusHandler?.("pagesControl", status);
    }

    return {
        isPagesOpen,
        togglePageBar
    };
}

