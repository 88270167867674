import {forwardRef} from "react";
import {Skeleton, TableCell, TableRow} from "@mui/material";

type TProps = {
    columns?: number
}
export const TableRowSkeleton = forwardRef<HTMLTableRowElement, TProps>(({columns = 3}, ref) => {

    return (
        <TableRow ref={ref}>
            {Array(columns).fill(1).map((_, index) => (
                <TableCell key={columns + ' ' + index}><Skeleton/></TableCell>
            ))}
        </TableRow>
    )
})