import React, {FC} from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";
import {useKycScreeningsList} from "../../../hooks/screenings/useScreeningsList";
import {PageContainer,} from "../../../../../../newShared/components/style";
import {getNormalizedTargetType, getScreeningStatus} from "../../../helpers";
import {TScreeningStatus} from "../../../types";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {GenericTable} from "../../../../../../newShared/components/genericTable";
import {KycScreeningModel} from "../../../../../../newShared/GQLTypes";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";


export const KycScreeningsList: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.screenings'});

    const {
        screenings,
        configs,
        handleViewFullReport,
        handleGoToExactTarget,
        isOutdated,
        handleReloadData,
        genericTable
    } = useKycScreeningsList();
    const {isMobile} = useMedia();


    return (
        <PageContainer>
            <PageTitleAndActions
                title={t('Screenings')}
                showDataHasBeenUpdated={isOutdated}
                onReloadData={handleReloadData}
                actions={[]}
            />

            <GenericTable<KycScreeningModel>
                id={'KycScreeningsList'}
                columnsConfig={{totalName: t('Total screenings'), forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: screenings,
                    customCellPaddings: '8px',
                    rowActionsConfig: [
                        {
                            title: t('Open client'),
                            onClick: (row) => handleGoToExactTarget(row.targetId, row.targetType),
                            buttonProps: {variant: 'outlined'}
                        }
                    ],
                    getRowUniqueId: (row) => row.id,
                    onRowClick: handleViewFullReport,
                    columnsConfigs: [
                        {name: t('Client name'), key: 'targetName', default: true},
                        {name: t('Template'), key: 'templateName', default: true},
                        {name: t('Type'), key: 'type', default: true, valueGetter: (row) => getNormalizedTargetType(row.targetType ?? '-')},
                        {name: t('Start date'), key: 'startDate', default: true, valueGetter: (row) => parseDateAuto(row.startDate ?? '', true)},
                        {name: t('Scan status'), key: 'scanStatus', default: true, valueRenderer: (row) => getScreeningStatus(row.status as TScreeningStatus)}
                    ]
                }}
                filtersConfig={{
                    dontSendSearchIfEmpty: true,
                    searchCustomFilterKeyName: 'nameLike',
                    searchAsArray: true,
                    genericFilterProps: {
                        configs,
                        fetchResultWithSelectedFilters: () => null,
                        isAddFilters: false
                    }
                }}
                emptyArrayImageProps={{
                    type: 'kycTemplates',
                    filterNotApplied: {text: t('Screenings will appear here')}
                }}
            />


            {/*<FilterBox>*/}
            {/*    <GenericFiltersArray*/}
            {/*        isAddFilters={false}*/}
            {/*        configs={configs}*/}
            {/*        dateFilterFormat={KYC_DATE_FORMAT}*/}
            {/*        fetchResultWithSelectedFilters={(search, filters, signal) => {*/}
            {/*            dispatch(eraseScreenings());*/}
            {/*            dispatch(GetKycScreeningsWithFilterAndPaging({*/}
            {/*                data: { pageInfo: { page: 0, count: SCREENINGS_DEFAULT_PAGING, filters: {...filters, nameLike: [search] }, }},*/}
            {/*                signal*/}
            {/*            }))*/}
            {/*        }}*/}
            {/*    />*/}

            {/*</FilterBox>*/}

            {/*{!isNoScreeningsFound &&*/}
            {/*    <CommonTableContainer onScroll={scroll.onScroll} ref={scroll.scrollRef}>*/}
            {/*        <Table stickyHeader>*/}
            {/*            <CommonTableHead>*/}
            {/*                <CommonHeaderTableRow>*/}
            {/*                    <TableCell colSpan={6}>*/}
            {/*                        <Typography variant={'subtitle1'}*/}
            {/*                                    color={colors.text.grey}>{`${t('Total screenings')}: ${ isLoadingList ? "" : pageInfo.total }`}</Typography>*/}
            {/*                    </TableCell>*/}
            {/*                </CommonHeaderTableRow>*/}
            {/*                <CommonTableRow>*/}
            {/*                    <NameTableCell>*/}
            {/*                        <TypographyBody2Grey>{t('Client name')}</TypographyBody2Grey>*/}
            {/*                    </NameTableCell>*/}
            {/*                    <TemplateNameTableCell>*/}
            {/*                        <TypographyBody2Grey>{t('Template')}</TypographyBody2Grey>*/}
            {/*                    </TemplateNameTableCell>*/}
            {/*                    <CommonTableCell>*/}
            {/*                        <TypographyBody2Grey>{t('Type')}</TypographyBody2Grey>*/}
            {/*                    </CommonTableCell>*/}
            {/*                    <CommonTableCell>*/}
            {/*                        <TypographyBody2Grey>{t('Start date')}</TypographyBody2Grey>*/}
            {/*                    </CommonTableCell>*/}
            {/*                    <CommonTableCell>*/}
            {/*                        <TypographyBody2Grey>{t('Scan status')}</TypographyBody2Grey>*/}
            {/*                    </CommonTableCell>*/}
            {/*                    <ActionTableCell/>*/}
            {/*                </CommonTableRow>*/}
            {/*            </CommonTableHead>*/}


            {/*            <CommonTableBody>*/}
            {/*                {screenings.map(screening => (*/}
            {/*                    <TableRow hover key={screening.id} onClick={(e) => screening && handleViewFullReport(e, screening)}>*/}
            {/*                        <NameTableCell>*/}
            {/*                            <OverflowHiddenTextBody2>{screening.targetName}</OverflowHiddenTextBody2>*/}
            {/*                        </NameTableCell>*/}
            {/*                        <TemplateNameTableCell>*/}
            {/*                            <OverflowHiddenTextBody2>{screening.templateName ?? '-'}</OverflowHiddenTextBody2>*/}
            {/*                        </TemplateNameTableCell>*/}
            {/*                        <CommonTableCell>*/}
            {/*                            <TypographyBody2Grey>{getNormalizedTargetType(screening.targetType ?? '')}</TypographyBody2Grey>*/}
            {/*                        </CommonTableCell>*/}
            {/*                        <CommonTableCell>*/}
            {/*                            <TypographyBody2Grey>{parseDateAuto(screening.startDate ?? '', true)}</TypographyBody2Grey>*/}
            {/*                        </CommonTableCell>*/}
            {/*                        <CommonTableCell>*/}
            {/*                            {getScreeningStatus(screening.status as TScreeningStatus)}*/}
            {/*                        </CommonTableCell>*/}

            {/*                        <ActionTableCell>*/}
            {/*                            <SmallOutlinedButton onClick={(e) => handleGoToExactTarget(screening.targetId, screening.targetType)}>{t('Open client')}</SmallOutlinedButton>*/}
            {/*                        </ActionTableCell>*/}
            {/*                    </TableRow>*/}
            {/*                ))}*/}

            {/*                {(scroll.hasMore || isLoadingList) && <TableRowSkeleton columns={6} />}*/}
            {/*            </CommonTableBody>*/}
            {/*        </Table>*/}

            {/*    </CommonTableContainer>*/}
            {/*}*/}


            {/*<EmptyArrayImage*/}
            {/*    type={'kycTemplates'}*/}
            {/*    isShowing={isNoScreeningsFound}*/}
            {/*    text={('No screening to display.')}*/}
            {/*    withoutAdd*/}

            {/*/>*/}


        </PageContainer>
    )

}
