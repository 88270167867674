import {
    Avatar,
    AvatarGroup,
    IconButton,
    Menu,
    MenuItem,
    styled,
    TableCell,
    TableRow,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography
} from "@mui/material";
import React from "react";
import {Flex} from "../../../../../../../../newShared/components/Layouts";
import {MainVicaCategoryStepModel} from "../../../../../../../../newShared/GQLTypes";
import {useVicaStep} from "../../../../../hooks/useVicaStep";
import {TypographyNew} from "../../../../../../../../newShared/components/TypographyNew";
import colors from "../../../../../../../../newShared/theme/colors";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {VicaStepStatusNode} from "../vicaStepStatusNode";
import {getAvatarInitials} from "../../../../../../../../newShared/utils/text";
import {AddAvatarButton} from "../../../../../../../../newShared/components/Basic/AddAvatarButton";
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {DelegatedTaskCounterChip} from "../delegatedTaskCounterChip";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {MainVicaCategoryLocalModel} from "../../../../../types";

export const StatusesToolTip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({

    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: colors.backgrounds.grey_light,
        borderRadius: '6px',
        padding: '8px 16px',
        color: colors.text.dark,
        fontWeight: 400,
        fontSize: '14px',
        marginTop: '0px !important',
        maxWidth: 'unset'
    },

    [`& .${tooltipClasses.arrow}`]: {
        color: colors.backgrounds.grey_light,
    }
}));

export const VicaTaskTableRow = ({step, category}: {step: MainVicaCategoryStepModel, category: MainVicaCategoryLocalModel}) => {
    const {revDir} = useMainTranslation();
    const {actions, menu} = useVicaStep({step, category});
    return(
        <TableRow>
            <TableCell>
                <Flex ai={'center'} m={!revDir ? '0 0 0 50px' : '0 50px 0 0'}>
                    <TypographyNew
                        variant={'body2'}
                        color={colors.grayText}
                        noWrap
                        onClick={actions.handleOpenStep}
                        sx={{'&:hover': {color: colors.primary.blue}, cursor: 'pointer'}}
                    >{step.name}</TypographyNew>

                    <IconButton size={'small'} onClick={actions.handleOpenDescription}>
                        <InfoOutlinedIcon />
                    </IconButton>
                </Flex>
            </TableCell>

            <TableCell>
                <VicaStepStatusNode status={step.status}/>
            </TableCell>

            <TableCell>
                <Flex ai={'center'}>
                    <AvatarGroup
                        max={3}
                        componentsProps={{
                            additionalAvatar: {onClick: actions.handleOpenResponsibleDialog}
                        }}
                        sx={{
                            '& .MuiAvatar-root': {
                                maxWidth: '24px', maxHeight: '24px',
                                fontSize: '10px',
                            },
                            '& .MuiAvatar-root:hover': {
                                cursor: 'pointer'
                            },
                            '& .MuiAvatar-root:last-child': {
                                marginLeft: !revDir ? undefined : '-8px'
                            },
                        }}
                    >

                        {step.responsible && step.responsible.map((e, id, arr) => {
                            return(
                                <Avatar key={e.collaboratorId} sx={{ width: 24, height: 24, backgroundColor: ((id % 2) !== 0) ? colors.primary.blue : colors.text.success}}>
                                    <Typography color={colors.text.white} fontSize={'10px'} fontWeight={400}>{getAvatarInitials(e.fullName)}</Typography>
                                </Avatar>
                            )
                        })}
                    </AvatarGroup>

                    <AddAvatarButton onClick={actions.handleOpenResponsibleDialog}
                                     btnSize={'d24'}
                                     sx={{marginRight: !revDir ? undefined : '8px'}}
                    />
                </Flex>
            </TableCell>

            <TableCell>
                <StatusesToolTip title={
                    <Flex ai={'center'} gap={'4px'}>
                        <Typography variant={'bodySmall'} color={colors.grayText}>{'Created:'}</Typography>
                        <DelegatedTaskCounterChip type={'created'} count={step.delegatedTasks?.created ?? 0}/>
                        <Typography variant={'bodySmall'} color={colors.grayText}>{'Performed:'}</Typography>
                        <DelegatedTaskCounterChip type={'performed'} count={step.delegatedTasks?.performed ?? 0}/>
                        <Typography variant={'bodySmall'} color={colors.grayText}>{'Approved:'}</Typography>
                        <DelegatedTaskCounterChip type={'approved'} count={step.delegatedTasks?.approved ?? 0}/>
                        <Typography variant={'bodySmall'} color={colors.grayText}>{'Declined:'}</Typography>
                        <DelegatedTaskCounterChip type={'declined'} count={step.delegatedTasks?.declined ?? 0}/>
                    </Flex>
                }>
                    <Flex ai={'center'} gap={'9px'}>
                        <DelegatedTaskCounterChip type={'created'} count={step.delegatedTasks?.created ?? 0}/>
                        <DelegatedTaskCounterChip type={'performed'} count={step.delegatedTasks?.performed ?? 0}/>
                        <DelegatedTaskCounterChip type={'approved'} count={step.delegatedTasks?.approved ?? 0}/>
                        <DelegatedTaskCounterChip type={'declined'} count={step.delegatedTasks?.declined ?? 0}/>
                    </Flex>
                </StatusesToolTip>
            </TableCell>

            <TableCell>
                <Flex jc={'space-between'} ai={'center'}>
                    <Flex ai={'center'} gap={'10px'} cursor={'pointer'} onClick={(e) => actions.handleOpenFrameworksDialog(e)}>
                        <AvatarGroup
                            max={4}
                            sx={{
                                '& .MuiAvatar-root': {
                                    maxWidth: '24px',
                                    maxHeight: '24px',
                                    fontSize: '10px',
                                },
                                '& .MuiAvatar-root:hover': {
                                    cursor: 'pointer'
                                },
                                '& .MuiAvatar-root:last-child': {
                                    marginLeft: !revDir ? undefined : '-8px',
                                },
                                '& .MuiAvatar-root:first-child:not(:has(img))': {
                                    backgroundColor: colors.backgrounds.white,
                                    color: colors.text.blue,
                                    border: `1px solid ${colors.primary.hovered}`,
                                    marginLeft: !revDir ? '-6px' : '-12px',
                                    maxWidth: '22px',
                                    maxHeight: '22px',
                                    bottom: '-2px'
                                },
                            }}
                        >

                            {step.frameworks.map((e, id, arr) => {
                                return(
                                    <Avatar key={e.id} src={e.logo} sx={{ width: 24, height: 24, backgroundColor: colors.backgrounds.white}}>
                                        <Typography color={colors.text.white} fontSize={'10px'} fontWeight={400}>{getAvatarInitials(e.name)}</Typography>
                                    </Avatar>
                                )
                            })}
                        </AvatarGroup>

                        {/*<Flex shrink={'0'}>*/}
                        {/*    <Button size={'small'} variant={'text'} onClick={actions.handleOpenFrameworksDialog}>*/}
                        {/*        {('View more')}*/}
                        {/*    </Button>*/}
                        {/*</Flex>*/}
                    </Flex>

                    {step.actions.length > 0 &&
                        <>
                            <IconButton
                                size={'small'}
                                sx={{margin: !revDir ? '0 0 0 16px' : '0 16px 0 0'}}
                                aria-label="more"
                                id="long-button"
                                aria-controls={menu.open ? 'long-menu' : undefined}
                                aria-expanded={menu.open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={menu.handleClick}
                            >
                                {<MoreVertIcon />}
                            </IconButton>

                            <Menu
                                id="long-menu"
                                MenuListProps={{'aria-labelledby': 'long-button',}}
                                anchorEl={menu.anchorEl}
                                open={menu.open}
                                onClose={menu.handleClose}
                                PaperProps={{
                                    style: {
                                        boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)',
                                        borderRadius: '8px',
                                        border: '1px solid #F2F4F7'
                                    },
                                }}
                                sx={{
                                    '& .MuiMenu-list': {
                                        padding: '16px'
                                    },
                                    '& .MuiMenuItem-root': {
                                        margin: '0 auto',
                                        borderRadius: '4px',
                                        '&:hover': {
                                            backgroundColor: '#F2F4F7'
                                        }
                                    }
                                }}
                            >
                                {step.actions.map(action => {
                                    return(
                                        <MenuItem key={action.title} onClick={(event) => {event.stopPropagation(); actions.handleOpenActionDialog(action)}}>
                                            <Typography variant={'body1'}>{action.title}</Typography>
                                        </MenuItem>
                                    )
                                })}
                            </Menu>
                        </>
                    }
                </Flex>
            </TableCell>
        </TableRow>
    )
}
