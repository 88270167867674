import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {
    SETTING_ROOT_PATH,
    SETTINGS_ORGANIZATIONS_CREATE_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH,
    SETTINGS_ORGANIZATIONS_PATH
} from "../../../constants";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    cleanOrganizations,
    eraseDataAfterReturnToOrgsList,
    organizationPageInfo,
    organizations,
    organizationsLoadings,
    organizationsMinMaxLoadedPage,
    replaceOrganizationsMinMaxLoadedPage,
    setSelectedOrganization
} from "../../../store/slice";
import {GetOrganizations} from "../../../store/actions";
import {TOrganization} from "../../../types";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../../newShared/components/genericTable/types";

export const useOrganizationsList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const _orgs = useSelector(organizations);
    const {list} = useSelector(organizationsLoadings);
    const _pageInfo = useSelector(organizationPageInfo);
    const {maxLoadedPage, minLoadedPage} = useSelector(organizationsMinMaxLoadedPage);

    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    useSetBreadcrumbs([
        {
            title: tMenu('Settings'),
            path: SETTING_ROOT_PATH
        },
        {
            title: tMenu('Organizations'),
            path: SETTINGS_ORGANIZATIONS_PATH
        }
    ]);

    //initial fetch
    useEffect(() => {
        //deleting exact org and its data
        dispatch(eraseDataAfterReturnToOrgsList());

        // getData(0, DEFAULT_ORGANIZATIONS_PAGING, '', false);
        //eslint-disable-next-line
    }, []);

    //actions
    // const getData = (page: number, count: number, search: string, removeBeforeFetch: boolean) => {
    //     dispatch(GetOrganizations({
    //         page,
    //         count,
    //         name: search,
    //         removeBeforeFetch
    //     }));
    // }
    const handleGoToCreateOrg = () => history.push(SETTINGS_ORGANIZATIONS_CREATE_PATH);

    //search
    // const {form, setForm, handleChange} = useForm<{search: string, prevSearch: string}>({search: '', prevSearch: ''});
    //
    // const handleCleanSearch = () => {
    //     setForm({search: '', prevSearch: ''});
    //     getData(0, DEFAULT_ORGANIZATIONS_PAGING, '', true);
    // }
    //
    // const handleEnterKey = (e: any) => {
    //     if(e.keyCode === 13 && !form.search.trim().length) {
    //         setForm({...form, prevSearch: ''});
    //         getData(0, DEFAULT_ORGANIZATIONS_PAGING, '', true);
    //     }
    //     if(e.keyCode === 13 && form.search.trim().length > 0){
    //         setForm({...form, prevSearch: form.search});
    //         getData(0, DEFAULT_ORGANIZATIONS_PAGING, form.search, true);
    //     }
    // }

    const handleManageOrganization = (id: string) => {
        const org = _orgs.find(e => e.id === id);
        if(org){
            dispatch(setSelectedOrganization(org));
            history.push(SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH.replace(':id', id));
        }
    }

    // const handleLoadMore = (page: number) => {
    //     !list && getData(_pageInfo.page + 1, DEFAULT_ORGANIZATIONS_PAGING, form.search, false);
    // }

    const onOrganizationClick = (org: TOrganization) => {
        dispatch(setSelectedOrganization(org));
        history.push(SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH.replace(':orgId', org.id));
    }

    return{
        handleGoToCreateOrg,
        // search: {
        //     search: form.search,
        //     handleChange,
        //     handleCleanSearch,
        //     handleEnterKey
        // },
        organizations: _orgs,
        handleManageOrganization,
        isLoading: list,
        hasMore: _pageInfo.total > ((_pageInfo.page + 1) * _pageInfo.count),
        // handleLoadMore,
        // showNoOrganizations: !list && !_pageInfo.total && !form.prevSearch.trim().length,
        // showNoResult: form.prevSearch.trim().length > 0 && !list && !_pageInfo.total,
        onOrganizationClick,
        genericTable: {
            paging: {
                pageInfo: _pageInfo,
                isLoading: list,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceOrganizationsMinMaxLoadedPage(data)),
                clearRows: () => dispatch(cleanOrganizations()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    dispatch(GetOrganizations({
                        page,
                        count,
                        name: search,
                        removeBeforeFetch: false
                    }))
                },
            }
        }
    }
}