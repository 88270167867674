import {IconButton, InputAdornment, TextField} from "@mui/material";
import {FC} from "react";
import {TextFieldProps} from "@mui/material/TextField/TextField";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear';
import {useMainTranslation} from "../../../hooks/useMainTranslationHooks/useMainTranslation";

type TCommonTextField = TextFieldProps & {
    errorMessage?: string;
}

type TSearchField = TCommonTextField & {
    onClear: () => void;
}
export const SearchField: FC<TSearchField> = ({onClear, children, ...props}) => {
    const {tCommon} = useMainTranslation()

    return (
        <TextField
            placeholder={tCommon('Search')}
            // size={"small"}
            onBlur={(props.value as string || '').trim() === '' ? onClear : undefined}
            InputProps={{
                endAdornment: (
                    <InputAdornment position={'end'}>
                        {(props.value === '' || props.value === null || props.value === undefined) ? <SearchIcon /> : <IconButton sx={{padding: '0'}} disabled={props.disabled} onClick={() => {
                            onClear()
                        }}><ClearIcon /></IconButton>}
                    </InputAdornment>
                )
            }}
            {...props}
        >{children}</TextField>
    )
}
