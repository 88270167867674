import {useDispatch, useSelector} from "react-redux";
import {
    frameworkControlDialogsSelector,
    frameworkControlEvidencesSelector,
    frameworkControlIsLoadingSelector,
    hideCreateDelegatedTaskDialog
} from "../../store/control/store";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useFormik} from "formik";
import * as yup from 'yup';
import React, {useEffect, useState} from "react";
import {mainFrameworksCreateDelegatedTaskAction} from "../../store/control/actions";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {DelegatedTaskSources, MainFrameworksCreateDelegatedTaskInput} from "../../../../../newShared/GQLTypes";
import {useFrameworksPathParams} from "../useFrameworksPathParams";

export const useCreateDelegatedTaskDialog = ({refreshTable, delegatedTasksOpen}: {refreshTable: () => void, delegatedTasksOpen: boolean}) => {
    const dispatch = useDispatch();
    const {createDelegatedTaskDialog: {isOpen, isLoadingCreate}} = useSelector(frameworkControlDialogsSelector);
    const {evidences} = useSelector(frameworkControlEvidencesSelector);
    const {t} = useMainTranslation('', {keyPrefix: 'pathFrameworks.pathDelegatedTasks'})
    const {setMessage, isOpenMessageDialog} = useMessageDialog();
    const {frId, controlId} = useFrameworksPathParams();
    const isLoadingControl = useSelector(frameworkControlIsLoadingSelector);
    const [evidenceDescription, setEvidenceDescription] = useState<string>('');
    const handleClose = () => {
        dispatch(hideCreateDelegatedTaskDialog());
    }
    const handleCreate = () => {
        const task: MainFrameworksCreateDelegatedTaskInput = {
            source: DelegatedTaskSources.FrameworkModule,
            title: evidences?.find(e => e.id === formik.values.evidenceId)?.name || '',
            description: evidences?.find(e => e.id === formik.values.evidenceId)?.description || null,
            requestorComment: formik.values.comment || '',
            performer: formik.values.collaborator || {collaboratorId: '', email: '', fullName: ''},
            vicaStepId: '',
            frameworkId: frId,
            controlId,
            evidenceId: formik.values.evidenceId,
        };
        dispatch(mainFrameworksCreateDelegatedTaskAction({
            data: {workspaceId: '', task},
            onSuccess: (request, response) => {
                setMessage({
                    title: t('Completed successfully'),
                    message: t('Delegated task has been created')},
                );
                handleClose();
                delegatedTasksOpen && refreshTable();
            },
            onError: (request, _error, addictiveData) => {
                const error409 = _error.e409?.[0];
                switch (error409?.type){
                    case 'NOT_COLLABORATOR':
                        const performerName = error409?.values?.find(e => e?.parameter === 'performer')?.value;
                        setMessage({
                            title: t('Invalid performer status'),
                            message: t('Collaborator {{name}} has been removed from system', {name: performerName})}
                        );
                        break;
                    case 'DUPLICATE':
                        const id = error409?.values?.find(e => e?.parameter === 'id')?.value;
                        const status = error409?.values?.find(e => e?.parameter === 'status')?.value;
                        setMessage({
                            title: t('Duplicate task error'),
                            message: t('You already have task {{id}} for evidence {{name}} with status {{status}} and performer {{performer}}', {id, name: task.title, status, performer: task.performer.fullName})}
                        );
                        break;
                    case 'NO_PORTAL_ACCESS':
                        const name = error409?.values?.find(e => e?.parameter === 'performer')?.value;
                        setMessage({
                            title: t('Invalid performer status'),
                            message: t('Collaborator {{name}} does not have access to portal', {name})}
                        );
                        break;
                }
            },
        }))
    }
    const validationSchema = yup.object({
        comment: yup.string()
            .nullable()
            .max(1000, t('Comment should be less than 1000 symbols')),
        evidenceId: yup.string()
            .nullable()
            .required(t('Evidence field is required')),
        collaborator: yup.object({
            collaboratorId: yup.string()
                .required(t('Collaborator field is required')),
            fullName: yup.string()
                .required(t('Collaborator field is required')),
            email: yup.string()
                .required(t('Collaborator field is required')),

        })
            .nullable()
            .required(t('Collaborator field is required'))
    });
    const formik = useFormik<{ evidenceId: string | null, collaborator: {collaboratorId: string, fullName: string, email: string} | null, comment: string | null }>({
        initialValues: {evidenceId: null, collaborator: null, comment: ''},
        validationSchema,
        onSubmit: handleCreate,
        isInitialValid: false,
        validateOnBlur: true,
    })
    const formikOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldTouched(e.target.name, true, false);
        formik.handleChange(e);
    };

    useEffect(() => {
        formik.resetForm();
        setEvidenceDescription('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);


    return {
        isOpen: isOpen && !isOpenMessageDialog,
        isLoadingCreate,
        handleClose,
        handleCreate,
        evidences,
        formik: {...formik, handleChange: formikOnChange},
        isLoadingControl,
        evidenceDescription,
        setEvidenceDescription
    }


}