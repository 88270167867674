import {useDispatch, useSelector} from "react-redux";
import {assignmentsSelector, loadings} from "../../../store/slice";
import {useTrainingsDialogs} from "../../useTrainingsDialogs";
import {
    MainTrainingAssignmentExamValidationInput,
    MainTrainingAssignmentPostExamAnswerStatusForValidation,
    TrainingAssignmentExamAnswerStatus,
    TrainingAssignmentExamType,
    TrainingExamQuestionType
} from "../../../../../../newShared/GQLTypes";
import {mainTrainingsAcceptValidationReviewByAssignmentIdAndExamTypeAction} from "../../../store/actions";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useCustomFormik} from "../../../../../../newShared/hooks/useCustomFormik";
import {useValidationSchema} from "./useValidationSchema";
import {TQuestionWithValidationInput} from "../../../types";

export const useValidateExamAnswersDialog = ({refreshAssignment}: {refreshAssignment: () => void}) => {
    // const {t} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.dialogs'});

    const dispatch = useDispatch();
    const {setMessage} = useMessageDialog();

    const {validateExamAnswers: isLoadingConfirm} = useSelector(loadings);
    const {selectedAssignment} = useSelector(assignmentsSelector);

    const {isOpen, handleClose} = useTrainingsDialogs().validateExamAnswers;

    const handleConfirm = (question: MainTrainingAssignmentExamValidationInput[]) => {
        dispatch(mainTrainingsAcceptValidationReviewByAssignmentIdAndExamTypeAction({
            data: {
                workspaceId: '',
                questions: Object.values(question),
                assignmentId: selectedAssignment?.id ?? '',
                examType: TrainingAssignmentExamType.PostExam,
            },
            onSuccess: (request, response) => {
                setMessage({
                    title: 'Completed successfully',
                    message: 'Exam result was successfully evaluated.'},
                );
                handleClose();
                refreshAssignment();
            },
            onError: (request, _error, addictiveData) => {
                const error409 = _error.e409?.[0];
                switch (error409?.type){
                    case 'STATUS_CHANGED':
                        setMessage({
                            title: 'Error',
                            message: 'Status changed error'}
                        );
                        handleClose();
                        refreshAssignment();
                        break;
                    case 'EXAM_NOT_EXISTS':
                        setMessage({
                            title: 'Error',
                            message: 'Exam not exist error'}
                        );
                        handleClose();
                        refreshAssignment();
                        break;
                    case 'WRONG_QUESTION_COUNT':
                        setMessage({
                            title: 'Error',
                            message: 'Wrong question count error'}
                        );
                        break;
                    case 'QUESTIONS_NOT_CORRESPOND':
                        setMessage({
                            title: 'Error',
                            message: 'Questions not correspond'}
                        );
                        break;
                }
            },
        }))
    }

    const {questionsSchema} = useValidationSchema();
    const formik = useCustomFormik<{questions: TQuestionWithValidationInput[]}>(isOpen, {
        initialValues: {
            questions: selectedAssignment?.postExam?.questions
                ?.filter(e => e.status === TrainingAssignmentExamAnswerStatus.Validation)
                ?.filter((question) => question.type === TrainingExamQuestionType.ShortAnswer || question.type === TrainingExamQuestionType.Paragraph)
                .map((question): TQuestionWithValidationInput => ({
                    ...question,
                    validation: {
                        validationStatus: null,
                        validationComment: {
                            correctAnswer: [],
                            points: 0,
                        },
                    },
                })) || [],
        },
        onSubmit: (values) => {
            handleConfirm(values.questions.map((question): MainTrainingAssignmentExamValidationInput => ({
                questionId: question.questionId,
                status: question.validation.validationStatus || MainTrainingAssignmentPostExamAnswerStatusForValidation.Passed,
                validation: question.validation.validationComment,
            })));
        },
        validationSchema: questionsSchema,
    })

    return {
        isOpen,
        handleClose,
        isLoadingConfirm,

        examName: selectedAssignment?.postExam?.examName ?? 'NNM',
        recipientFullName: selectedAssignment?.recipient?.fullName ?? 'NNM',

        formik,
    }
}