import {useSelector} from "react-redux";
import {hrSettings} from "../../store/slice";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {TEmployeeStatus, THrSettingsJobTitle, usedManagerAutocompleteValue} from "../../types";
import {getEmployeesUsedCities, getEmployeesUsedManagers, getEmployeesUsedStreetsAutocomplete} from "../../api";
import {StatusChip} from "../../components/valueGetter";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TIdName} from "../../../../../newShared/types";


export const useGetEmployeesFilters = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathHRManagement.list'});
    const {jobTitle, department, location, division, maritalStatus, status, gender, ethnicity, country} = useSelector(hrSettings);
    
    const departmentConfig: keyConfig<string> = {
        type: 'filter',
        key: 'department',
        name: t('Department'),
        options: Object.keys(department),
        default: true
    }

    const locationsConfig: keyConfig<string> = {
        type: 'filter',
        key: 'location',
        name: t('Location'),
        options: Object.keys(location),
        default: true
    }

    const jobTitleConfig: keyConfig<THrSettingsJobTitle> = {
        type: 'filter',
        key: 'jobTitle',
        name: t('Job title'),
        options: jobTitle,
        getFilterValue: (option) => option.name,
        isOptionEqual: (a, b) => a.id === b.id,
        isOptionEqualToSearch: (option, search) => option.name.trim().toLowerCase().includes(search.trim().toLowerCase()),
        getOptionLabel: (option) => option.name,
        default: true
    }

    const divisionConfig: keyConfig<string> = {
        type: 'filter',
        key: 'division',
        name: t('Division'),
        options: Object.keys(division),
    }

    const managerConfig: keyConfig<usedManagerAutocompleteValue> = {
        type: 'filter',
        key: 'manager',
        name: t('Manager'),
        fetchOptions: getEmployeesUsedManagers,
        getOptionLabel: (a) => a.name,
        getFilterValue: (a) => a.id,
        isOptionEqual: (a, b) => a.id === b.id,
        isOptionEqualToSearch: (a, search) => a.name.trim().toLowerCase().includes(search.trim().toLowerCase())
    }

    const maritalStatusConfig: keyConfig<string> = {
        type: 'filter',
        key: 'maritalStatus',
        name: t('Marital status'),
        options: maritalStatus,
    }

    const statusConfig: keyConfig<TEmployeeStatus> = {
        type: 'filter',
        key: 'status',
        name: t('Status'),
        options: status,
        isOptionEqualToSearch: (e, search) => {return e.name.trim().toLowerCase().includes(search.trim().toLowerCase())},
        isOptionEqual: (a, b) => {return a.id === b.id},
        OptionRenderer: (a) => <StatusChip status={a} margin={'4px 0'}/>,
        getFilterValue: (a) => a.id,
        getOptionLabel: (a) => a.name
    }

    const genderConfig: keyConfig<string> = {
        type: 'filter',
        key: 'gender',
        name: t('Gender'),
        options: gender,
    }

    const ethnicityConfig: keyConfig<string> = {
        type: 'filter',
        key: 'ethnicity',
        name: t('Ethnicity'),
        options: ethnicity,
    }

    const countryConfig: keyConfig<string> = {
        type: 'filter',
        key: 'country',
        name: t('Country'),
        options: country,
    }

    const cityConfig: keyConfig<string> = {
        type: 'filter',
        key: 'city',
        name: t('City'),
        fetchOptions: getEmployeesUsedCities,
    }

    const streetConfig: keyConfig<string> = {
        type: 'filter',
        key: 'street',
        name: t('Street'),
        fetchOptions: getEmployeesUsedStreetsAutocomplete
    }

    const veteranStatusConfig: keyConfig<string> = {
        type: 'filter',
        key: 'veteranStatus',
        name: t('Veteran status'),
        options: [
            t('Active Duty Wartime or Campaign Badge Veteran'),
            t('Armed Forces Service Medal Veteran'),
            t('Disabled Veteran'),
            t('Recently Separated Veteran')
        ]
    }

    const startDateConfig: keyConfig<string> = {
        type: 'date',
        key: 'startDate',
        name: t('Start date'),
    }

    const hireDateConfig: keyConfig<string> = {
        type: 'date',
        key: 'hireDate',
        name: t('Hire date'),
    }

    const birthDayConfig: keyConfig<string> = {
        type: 'date',
        key: 'birthDay',
        name: t('Birth date'),
    }

    const isMappedCollaborator: keyConfig<TIdName> = {
        type: 'filter',
        key: 'isMapped',
        name: t('Collaborator'),
        options: [{id: 'true', name: 'Yes'}, {id: 'false', name: 'No'}],
        getOptionLabel: (e) => e.name,
        getFilterValue: (e) => e.id,
        isOptionEqual: (a, b) => a.id === b.id,
        isOptionEqualToSearch: (a, search) => a.name.trim().toLowerCase().includes(search.trim().toLowerCase()),
    }

    return{
        configs: [
            departmentConfig,
            jobTitleConfig,
            locationsConfig,
            divisionConfig,
            managerConfig,
            maritalStatusConfig,
            statusConfig,
            genderConfig,
            ethnicityConfig,
            countryConfig,
            cityConfig,
            streetConfig,
            veteranStatusConfig,
            startDateConfig,
            hireDateConfig,
            birthDayConfig,
            isMappedCollaborator
        ]
    }
}