import {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {
    CONFLUENCE_ENDPOINT_MAPPING_NAME,
    GITHUB_ENDPOINT_MAPPING_NAME,
    JIRA_ENDPOINT_MAPPING_NAME,
    PATH_INTEGRATIONS_ADDED,
    PATH_INTEGRATIONS_ADDED_EXACT,
    PATH_INTEGRATIONS_AVAILABLE,
    SALESFORCE_ENDPOINT_MAPPING_NAME,
    ZENDESK_ENDPOINT_MAPPING_NAME
} from "./constants";
import {AddedAppsList} from "./pages/addedAppsList";
import {AvailableAppsList} from "./pages/availableAppsList";
import {ExactApp} from "./pages/exactApp";
import {OAuthPopup} from "../../../newShared/components/oauth";

export const Routes: FC = () => {
    return (
        <Switch>
            <Route exact path={PATH_INTEGRATIONS_ADDED} component={AddedAppsList}/>
            <Route exact path={PATH_INTEGRATIONS_AVAILABLE} component={AvailableAppsList}/>
            <Route exact path={PATH_INTEGRATIONS_ADDED_EXACT} component={ExactApp}/>

            <Route exact path={[
                ...[
                    CONFLUENCE_ENDPOINT_MAPPING_NAME,
                    JIRA_ENDPOINT_MAPPING_NAME,
                    ZENDESK_ENDPOINT_MAPPING_NAME,
                    GITHUB_ENDPOINT_MAPPING_NAME,
                    SALESFORCE_ENDPOINT_MAPPING_NAME,
                ].map(e => `${PATH_INTEGRATIONS_AVAILABLE}/${e}/callback`)
            ]} component={OAuthPopup}/>

            <Redirect to={PATH_INTEGRATIONS_ADDED}/>
        </Switch>
    )
}