import {createCommonAsyncThunk} from "../../../../newShared/utils/asyncThunk";
import {
    MainVicaApproveDelegatedTaskByIdMutation,
    MainVicaApproveDelegatedTaskByIdMutationVariables,
    MainVicaCancelDelegatedTaskByIdMutation,
    MainVicaCancelDelegatedTaskByIdMutationVariables,
    MainVicaChangeStepStatusMutation,
    MainVicaChangeStepStatusMutationVariables,
    MainVicaCreateDelegatedTaskMutation,
    MainVicaCreateDelegatedTaskMutationVariables,
    MainVicaDeclineDelegatedTaskByIdMutation,
    MainVicaDeclineDelegatedTaskByIdMutationVariables,
    MainVicaGetCategoriesWithFilterQuery,
    MainVicaGetCategoriesWithFilterQueryVariables,
    MainVicaGetCategoryStepByIdQuery,
    MainVicaGetCategoryStepByIdQueryVariables,
    MainVicaGetCategoryStepsWithFilterQuery,
    MainVicaGetCategoryStepsWithFilterQueryVariables,
    MainVicaGetDelegatedTaskByIdQuery,
    MainVicaGetDelegatedTaskByIdQueryVariables,
    MainVicaGetDelegatedTasksWithFilterPaginationQuery,
    MainVicaGetDelegatedTasksWithFilterPaginationQueryVariables,
    MainVicaGetWsCollaboratorsQuery,
    MainVicaGetWsCollaboratorsQueryVariables,
    MainVicaUpdateCategorySupervisorMutation,
    MainVicaUpdateCategorySupervisorMutationVariables,
    MainVicaUpdateStepResponsibleCollaboratorsMutation,
    MainVicaUpdateStepResponsibleCollaboratorsMutationVariables,
} from "../../../../newShared/GQLTypes";
import {
    mainVicaApproveDelegatedTaskById,
    mainVicaCancelDelegatedTaskById,
    mainVicaChangeStepStatus,
    mainVicaDeclineDelegatedTaskById,
    mainVicaGetCategoriesWithFilter,
    mainVicaGetCategoryStepById,
    mainVicaGetCategoryStepsWithFilter,
    mainVicaGetDelegatedTaskById,
    mainVicaGetDelegatedTasksWithFilterPagination,
    mainVicaGetWSCollaborators,
    mainVicaUpdateCategorySupervisor,
    mainVicaUpdateStepResponsibleCollaborators,
    mainVicaCreateDelegatedTask
} from "../api/query";
import {TWithOptions} from "../../../../newShared/types";
import {UploadEvidenceAssetVicaMutationVariables} from "../../vica/types";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {getActionsData} from "../../../../newShared/redux";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {uploadEvidenceAssetApi} from "../api";


export const mainVicaGetCategoriesWithFilterAction =
    createCommonAsyncThunk<MainVicaGetCategoriesWithFilterQueryVariables, MainVicaGetCategoriesWithFilterQuery['mainVicaGetCategoriesWithFilter'], {previouslyOpenedCategories?: string[]}>(
        'vicaTasksRefactor', 'mainVicaGetCategoriesWithFilter', true, false, mainVicaGetCategoriesWithFilter
    );


export const mainVicaGetCategoryStepsWithFilterAction =
    createCommonAsyncThunk<MainVicaGetCategoryStepsWithFilterQueryVariables, MainVicaGetCategoryStepsWithFilterQuery['mainVicaGetCategoryStepsWithFilter']>(
        'vicaTasksRefactor', 'mainVicaGetCategoryStepsWithFilter', true, false, mainVicaGetCategoryStepsWithFilter
    );

export const mainVicaGetCategoryStepByIdAction =
    createCommonAsyncThunk<MainVicaGetCategoryStepByIdQueryVariables, MainVicaGetCategoryStepByIdQuery['mainVicaGetCategoryStepById']>(
        'vicaTasksRefactor', 'mainVicaGetCategoryStepById', true, false, mainVicaGetCategoryStepById
    );


export const mainVicaUpdateCategorySupervisorAction =
    createCommonAsyncThunk<MainVicaUpdateCategorySupervisorMutationVariables, MainVicaUpdateCategorySupervisorMutation['mainVicaUpdateCategorySupervisor']>(
        'vicaTasksRefactor', 'mainVicaUpdateCategorySupervisor', true, false, mainVicaUpdateCategorySupervisor
    );

export const mainVicaChangeStepStatusAction =
    createCommonAsyncThunk<MainVicaChangeStepStatusMutationVariables, MainVicaChangeStepStatusMutation['mainVicaChangeStepStatus']>(
        'vicaTasksRefactor', 'mainVicaChangeStepStatus', true, false, mainVicaChangeStepStatus
    );


export const mainVicaUpdateStepResponsibleCollaboratorsAction =
    createCommonAsyncThunk<MainVicaUpdateStepResponsibleCollaboratorsMutationVariables, MainVicaUpdateStepResponsibleCollaboratorsMutation['mainVicaUpdateStepResponsibleCollaborators']>(
        'vicaTasksRefactor', 'mainVicaUpdateStepResponsibleCollaborators', true, false, mainVicaUpdateStepResponsibleCollaborators
    );

export const uploadEvidenceAssetAction = createAsyncThunk(
    'vica/uploadEvidenceAsset',
    async (data: TWithOptions<UploadEvidenceAssetVicaMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await uploadEvidenceAssetApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const mainVicaGetDelegatedTasksWithFilterPaginationAction =
    createCommonAsyncThunk<MainVicaGetDelegatedTasksWithFilterPaginationQueryVariables, MainVicaGetDelegatedTasksWithFilterPaginationQuery["mainVicaGetDelegatedTasksWithFilterPagination"]>(
        'vicaTasksRefactor', 'mainVicaGetDelegatedTasksWithFilterPagination', true, false, mainVicaGetDelegatedTasksWithFilterPagination
    )

export const mainVicaGetDelegatedTaskByIdAction =
    createCommonAsyncThunk<MainVicaGetDelegatedTaskByIdQueryVariables, MainVicaGetDelegatedTaskByIdQuery["mainVicaGetDelegatedTaskById"]>(
        'vicaTasksRefactor', 'mainVicaGetDelegatedTaskById', true, false, mainVicaGetDelegatedTaskById
    )

export const mainVicaGetWSCollaboratorsAction =
    createCommonAsyncThunk<MainVicaGetWsCollaboratorsQueryVariables, MainVicaGetWsCollaboratorsQuery["mainVicaGetWSCollaborators"]>(
        'vicaTasksRefactor', 'mainVicaGetWSCollaborators', true, false, mainVicaGetWSCollaborators
    )

export const mainVicaCancelDelegatedTaskByIdAction =
    createCommonAsyncThunk<MainVicaCancelDelegatedTaskByIdMutationVariables, MainVicaCancelDelegatedTaskByIdMutation['mainVicaCancelDelegatedTaskById']>(
        "vicaTasksRefactor", 'mainVicaCancelDelegatedTaskById', true, false, mainVicaCancelDelegatedTaskById
    );

export const mainVicaDeclineDelegatedTaskByIdAction =
    createCommonAsyncThunk<MainVicaDeclineDelegatedTaskByIdMutationVariables, MainVicaDeclineDelegatedTaskByIdMutation['mainVicaDeclineDelegatedTaskById']>(
        "vicaTasksRefactor", 'mainVicaDeclineDelegatedTaskById', true, false, mainVicaDeclineDelegatedTaskById
    );

export const mainVicaApproveDelegatedTaskByIdAction =
    createCommonAsyncThunk<MainVicaApproveDelegatedTaskByIdMutationVariables, MainVicaApproveDelegatedTaskByIdMutation['mainVicaApproveDelegatedTaskById']>(
        "vicaTasksRefactor", 'mainVicaApproveDelegatedTaskById', true, false, mainVicaApproveDelegatedTaskById
    );
export const MainVicaCreateDelegatedTaskAction =
    createCommonAsyncThunk<MainVicaCreateDelegatedTaskMutationVariables, MainVicaCreateDelegatedTaskMutation['mainVicaCreateDelegatedTask']>(
        'vicaTasks', 'mainVicaCreateDelegatedTask', true, false, mainVicaCreateDelegatedTask
    );
