import axiosSync from "../../../../newShared/utils/axios/axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {buildCommonHeader} from "../../../../newShared/utils";
import {AxiosResponse} from "axios";
import {gql} from "graphql.macro";
import {print} from "graphql";
import {
    GetCollectorHistory1Query,
    GetCollectorHistory1QueryVariables,
    GetShortCollectorsQuery,
    GetShortCollectorsQueryVariables
} from "../../../../newShared/GQLTypes";

export const getShortCollectorsApi = async (data: GetShortCollectorsQueryVariables, signal?: AbortSignal): Promise<GetShortCollectorsQuery["getShortCollectors"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getShortCollectors($data:PaginationInput!, $workspaceId:String!){
                        getShortCollectors(data:$data, workspaceId:$workspaceId){
                            pageInfo{
                                page
                                count
                                total
                                sort
                            }
                            collectors{
                                id
                                name
                                description
                                lastRun
                                lastRunStatus
                                appIntegrations
                                assignId
                                active
                                requiredApps
                                trigger{
                                    start
                                    type
                                    repeat
                                }
                                evidence{
                                    type
                                    data{
                                        id
                                        controlId
                                        name
                                        frameworkId
                                    }
                                }
                            }

                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getShortCollectors;
    })
)

export const getCollectorHistoryApi = async (data: GetCollectorHistory1QueryVariables, signal?: AbortSignal): Promise<GetCollectorHistory1Query["getCollectorHistory"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getCollectorHistory1($workspaceId:String!, $data:PaginationInput!, $id:String!){
                        getCollectorHistory(workspaceId:$workspaceId, data:$data, id:$id){
                            collector {
                                id
                                name
                                description
                                lastRun
                                lastRunStatus
                                appIntegrations
                                assignId
                                active
                                requiredApps
                                trigger{
                                    start
                                    type
                                    repeat
                                }
                                evidence{
                                    type
                                    data{
                                        id
                                        controlId
                                        name
                                        frameworkId
                                    }
                                }
                            }
                            evidences {
                                frameworkId
                                frameworkName
                                controlId
                                controlName
                                evidenceId
                                evidenceName
                            }
                            
                            history{
                                id
                                collectorId
                                status
                                start
                                end
                                errorReason
                                workspaceId
                            }
                            pageInfo{
                                page
                                count
                                total
                                sort
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getCollectorHistory;
    })
)

// export const startCollector = async (workspaceId: string, collectorId: string): Promise<string> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                 mutation startCollector($workspaceId:String!, $collectorId:String!){
//                   startCollector(workspaceId:$workspaceId, collectorId:$collectorId){
//                     message
//                   }
//                 }
//                 `,
//                 variables: {
//                     workspaceId,
//                     collectorId
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.startCollector.message;
//     })
// )
//
// export const deleteCollector = async (workspaceId: string, collectorId: string): Promise<string> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                 mutation deleteWCMapper($collectorId:String!, $workspaceId:String!){
//                   deleteWCAssign(collectorId:$collectorId, workspaceId:$workspaceId){
//                     message
//                   }
//                 }
//                 `,
//                 variables: {
//                     workspaceId,
//                     collectorId
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.deleteWCAssign.message;
//     })
// )
//
// ///////
//
// export const getAllCollectorsDialogData = async (workspaceId: string, page: number, count: number, name: string): Promise<{collectors: ShortCollectorDialogType[], pageInfo: TPageInfo}> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                     query getAllCollectorsDialogData($data: PaginationInput!, $name: String!, $workspaceId: String!){
//                       getAllCollectorsDialogData(workspaceId:$workspaceId, data:$data, name:$name){
//                         pageInfo{
//                           page
//                           count
//                           total
//                           sort
//                         }
//                         collectors{
//                           name
//                           id
//                           description
//                           origin
//                           workspaceId
//                           visibility
//                           trigger{
//                             start
//                             type
//                             repeat
//                           }
//                           evidence{
//                             type
//                             data{
//                               id
//                               controlId
//                               name
//                               frameworkId
//                             }
//                           }
//                           requiredApps
//                         }
//                       }
//                     }
//                 `,
//                 variables: {
//                     workspaceId,
//                     data: {page, count},
//                     name,
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.getAllCollectorsDialogData;
//     })
// );
//
// export const getRegulaitCollectorsDialogData = async (workspaceId: string, page: number, count: number, name: string): Promise<{collectors: ShortCollectorDialogType[], pageInfo: TPageInfo}> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                 query getRegulaitCollectorsDialogData($data: PaginationInput!, $name: String!, $workspaceId: String!){
//                   getRegulaitCollectorsDialogData(workspaceId:$workspaceId, data:$data, name:$name){
//                     pageInfo{
//                       page
//                       count
//                       total
//                       sort
//                     }
//                     collectors{
//                       name
//                       id
//                       description
//                       origin
//                       workspaceId
//                       visibility
//                       trigger{
//                         start
//                         type
//                         repeat
//                       }
//                       evidence{
//                         type
//                         data{
//                           id
//                           controlId
//                           name
//                           frameworkId
//                         }
//                       }
//                       requiredApps
//                     }
//                   }
//                 }
//                 `,
//                 variables: {
//                     workspaceId,
//                     data: {page, count},
//                     name,
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.getRegulaitCollectorsDialogData;
//     })
// );
//
// export const getPrivateCollectorsDialogData = async (workspaceId: string, page: number, count: number, name: string): Promise<{collectors: ShortCollectorDialogType[], pageInfo: TPageInfo}> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                     query getRegulaitCollectorsDialogData($data: PaginationInput!, $name: String!, $workspaceId: String!){
//                       getPrivateCollectorsDialogData(workspaceId:$workspaceId, data:$data, name:$name){
//                         pageInfo{
//                           page
//                           count
//                           total
//                           sort
//                         }
//                         collectors{
//                           name
//                           id
//                           description
//                           origin
//                           workspaceId
//                           visibility
//                           trigger{
//                             start
//                             type
//                             repeat
//                           }
//                           evidence{
//                             type
//                             data{
//                               id
//                               controlId
//                               name
//                               frameworkId
//                             }
//                           }
//                           requiredApps
//                         }
//                       }
//                     }
//                 `,
//                 variables: {
//                     workspaceId,
//                     data: {page, count},
//                     name,
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.getPrivateCollectorsDialogData;
//     })
// );
//
// export const getPublicCollectorsDialogData = async (workspaceId: string, page: number, count: number, name: string): Promise<{collectors: ShortCollectorDialogType[], pageInfo: TPageInfo}> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                     query getRegulaitCollectorsDialogData($data: PaginationInput!, $name: String!, $workspaceId: String!){
//                       getPublicCollectorsDialogData(workspaceId:$workspaceId, data:$data, name:$name){
//                         pageInfo{
//                           page
//                           count
//                           total
//                           sort
//                         }
//                         collectors{
//                           name
//                           id
//                           description
//                           origin
//                           workspaceId
//                           visibility
//                           trigger{
//                             start
//                             type
//                             repeat
//                           }
//                           evidence{
//                             type
//                             data{
//                               id
//                               controlId
//                               name
//                               frameworkId
//                             }
//                           }
//                           requiredApps
//                         }
//                       }
//                     }
//                 `,
//                 variables: {
//                     workspaceId,
//                     data: {page, count},
//                     name,
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.getPublicCollectorsDialogData;
//     })
// );
//
// export const createWCMapper = async (workspaceId: string, data: WCMapper): Promise<string> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                     mutation createWCMapper($data:CreateWCMapperInput!, $workspaceId:String!){
//                       createWCMapper(workspaceId:$workspaceId, data: $data){
//                         message
//                       }
//                     }
//                 `,
//                 variables: {
//                     workspaceId,
//                     data,
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.createWCMapper.message;
//     })
// );
//
// export const toggleWCMapperActivity = async (workspaceId: string, assignId: string, newStatus: boolean): Promise<string> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                     mutation toggleWCMapperActivity($workspaceId:String!, $assignId:String!, $newStatus:Boolean!){
//                       toggleWCMapperActivity(workspaceId:$workspaceId, assignId:$assignId, newStatus:$newStatus){
//                         message
//                       }
//                     }
//                 `,
//                 variables: {
//                     workspaceId,
//                     assignId,
//                     newStatus
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.toggleWCMapperActivity.message;
//     })
// );
//
// export const updateWCMapper = async (workspaceId: string, data: WCMapper): Promise<string> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                     mutation updateWCMapper($data:CreateWCMapperInput!, $workspaceId:String!){
//                       updateWCMapper(workspaceId:$workspaceId, data: $data){
//                         message
//                       }
//                     }
//                 `,
//                 variables: {
//                     workspaceId,
//                     data,
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.updateWCMapper.message;
//     })
// );