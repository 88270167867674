import {MainVicaCategoryLocalModel} from "./types";
import {MainVicaCategoryModel, MainVicaStepStatus} from "../../../newShared/GQLTypes";
import colors from "../../../newShared/theme/colors";

export const convertVicaCategoryToMainVicaCategoryLocalModel = (fetchedArray: MainVicaCategoryModel[], prevArray: MainVicaCategoryLocalModel[],previouslyOpenedCategories?: string[] ): MainVicaCategoryLocalModel[] => {
    const openedCategoriesNames = previouslyOpenedCategories ?? prevArray.filter(e => e.isOpen).map(e => e.name);
    return fetchedArray.map(e => ({...e, stepsLocal: [], isOpen: openedCategoriesNames.some(s => s === e.name), fetchedNoResult: false}));
}

export const isOkEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email.length > 0 && re.test(email);
}

export const getVicaStepStatusColor = (step?: MainVicaStepStatus) => {
    switch (step) {
        case MainVicaStepStatus.Completed: return colors.text.success;
        case MainVicaStepStatus.InProgress: return colors.text.blue;
        case MainVicaStepStatus.NotStarted: return colors.text.grey_dark;
        default: return undefined;
    }
}
