import {Grid, Skeleton, Typography} from "@mui/material";
import colors from "../../../../theme/colors";
import React from "react";

import {styled} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {Flex} from "../../../Layouts";
import {ColumnConfigWithValueGetter} from "../../types";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '16px',
    borderRadius: '8px',
    border: `1px solid ${colors.stroke.table}`,
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
}));

export function DynamicCardSkeleton<T extends Object>({isActions, configs, isTop}: {isActions: boolean, configs: ColumnConfigWithValueGetter<T>[], isTop: boolean}){
    return(
        <Grid item
              sm={12}
              md={6}
              c1440={4}
              xl={4}
              c1680={3}
              data-skeleton={isTop ? 'top' : 'bottom'}
              sx={{width: 'inherit'}}
        >
            <Item>
                <Flex w={'100%'} direction={'column'} overflow={'hidden'}>
                    {isActions && <Flex w={'100%'} jc={'flex-end'} h={'34px'}>
                        <Skeleton width={24} height={24} variant={'circular'}/>
                    </Flex>
                    }

                    <Flex w={'100%'} gap={'26px'}>
                        <Flex direction={'column'} gap={'10px'}>
                            {configs.map(e => (<Typography variant={'*bodyText2'} noWrap overflow={'hidden'} color={colors.text.grey}>{e.name}</Typography>))}
                        </Flex>

                        <Flex direction={'column'} gap={'10px'} overflow={'hidden'}>
                            {configs.map((e, index) => {
                                return(
                                    <>
                                        <Typography
                                            variant={'*bodyText2'}
                                            noWrap
                                            sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}
                                            color={colors.grayText}
                                        >
                                            {e.customSkeleton ? e.customSkeleton : <Skeleton width={index  % 2 === 0 ? 75 : 85}/>}
                                        </Typography>
                                    </>
                                )
                            })}
                        </Flex>
                    </Flex>

                </Flex>
            </Item>
        </Grid>
    )
}