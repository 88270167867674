import {FC} from "react";
import {Breadcrumbs as MuiBreadcrumbs, Typography} from "@mui/material";
import {useBreadcrumbs} from "./hooks/useBreadcrumbs";
import {useHistory} from "react-router";
import colors from "../../../newShared/theme/colors";

const setStr = (str: string, words: number = 5) => {
    const newStr = str.split(' ').filter(e => e !== '').slice(0, words).join(' ');

    return str.trim().length > newStr.length
        ? `${newStr}...`
        : str.trim()
}

export const Breadcrumbs: FC = () => {
    const {breadcrumbs, maxItems} = useBreadcrumbs();
    const {push} = useHistory();

    return (
        <MuiBreadcrumbs itemsAfterCollapse={2} itemsBeforeCollapse={0} maxItems={maxItems}
                        sx={{'& .MuiButtonBase-root': {marginLeft: 0, marginRight: 0}}}>
            {breadcrumbs.map((bc, index, array) =>
                !bc.skip && (
                    <Typography variant={"subtitle1"} key={bc.title}
                                sx={{
                                    color: array.length - 1 === index ? colors.text.grey_dark : colors.text.grey,
                                    '&:hover': {cursor: bc.path ? 'pointer' : undefined},
                                }}
                                onClick={bc.path ? () => {bc.path && push(bc.path)} : undefined}
                    >
                        {setStr(bc.title)}
                    </Typography>
                ))}
        </MuiBreadcrumbs>
    )
}