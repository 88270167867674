import {useDispatch} from "react-redux";
import {useMenu} from "../../../../../../newShared/hooks/useMenu";
import {KycRefactorTemplateModel} from "../../../../../../newShared/GQLTypes";
import {
    openDeleteTemplate,
    openExportCsv,
    openOneTimeScreening,
    openPreviewTemplate,
    openTemplateInputClientData,
    openTemplateSendToClient,
    openTemplatesImportCsv,
    setSelectedTemplate
} from "../../../store/slice";
import {KYC_TEMPLATES_EXACT_PATH} from "../../../constants";
import {useHistory} from "react-router-dom";
import {CreateKycTemplate, GetKycAvailableFieldsForExport} from "../../../store/actions";
import {MouseEventHandler} from "react";

export const useTemplateCard = ({template} : {template: KycRefactorTemplateModel}) => {
    //root
    const history = useHistory();
    const dispatch = useDispatch();
    const menu = useMenu();

    //menu actions
    const handlePreview = (event: any) => {
        event.stopPropagation();
        dispatch(openPreviewTemplate(template));
        menu.handleClose();
    }

    const handleImportCsv = (event: any) => {
        event.stopPropagation();
        dispatch(openTemplatesImportCsv(template));
        menu.handleClose();
    }

    const handleUseAsTemplate = (event: any) => {
        event.stopPropagation();
        dispatch(CreateKycTemplate({template: {...template, name: `Copy of ${template.name}`, id: ''}, onSuccess: handleGoToCreatedTemplate}));
        menu.handleClose();
    }

    const handleGoToCreatedTemplate = (id: string) => {
        history.push(KYC_TEMPLATES_EXACT_PATH.replace(':id', id));
    }

    //card actions
    const handleDelete: MouseEventHandler<HTMLLIElement> = (event) => {
        event.stopPropagation();
        dispatch(openDeleteTemplate(template));
        menu.handleClose();
    }

    const handleSendToClient: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.stopPropagation();
        dispatch(openTemplateSendToClient(template));
    }

    const handleInputClientData: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.stopPropagation();
        dispatch(openTemplateInputClientData(template));
    }

    const handleOTS: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.stopPropagation();
        dispatch(openOneTimeScreening(template));
    }

    const handleView = (event: any) => {
        event.stopPropagation();
        dispatch(setSelectedTemplate(template));
        history.push(KYC_TEMPLATES_EXACT_PATH.replace(':id', template.id));
    }

    // const handleExportCsv = (event: any) => {
    //     event.stopPropagation();
    //     template && dispatch(ExportClientsByTemplateAutoDownload({templateId: template.id, templateName: template.name}));
    //     menu.handleClose();
    // }

    const handleOpenExportCsv = (event: any) => {
        event.stopPropagation();
        dispatch(openExportCsv({template}));
        dispatch(GetKycAvailableFieldsForExport({templateId: template.id}));
        menu.handleClose();
    }

    return{
        menu: {
            ...menu,
            actions: {
                handlePreview,
                handleImportCsv,
                handleUseAsTemplate,
                handleDelete,
                handleOpenExportCsv
                // handleExportCsv
            }
        },
        actions: {
            handleView,
            handleSendToClient,
            handleInputClientData,
            handleOTS
        }
    }
}
