import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../../newShared/redux/rootReducer";
import {
    TFullNcrReportPreview,
    TReportControl,
    TReportIntegration,
    TReportIntegrationLog,
    TReportSmallControl,
    TReportView,
    TSetCollectorView,
    TSetPreviewReportDialog,
    TSetStatementDialog,
    TSmallNCR
} from "../../types";
import {TDialog, TIdNameEmail, TPageInfo} from "../../../../../newShared/types";
import {
    deleteAssetFromNCRByUserAction,
    downloadEvidenceFileAction,
    DownloadLogAction,
    DownloadLogForPreviewAction,
    downloadReportCollectorAssetAction,
    GetAppLogsAction,
    getFullNcrReportPreviewByUserAction,
    getIntegrationDataByCollectionReportsAction,
    getIntegrationsReportAction,
    getReportAction,
    getReportAssetCollectorDataByUserAction,
    getReportControlByIdAction,
    getReportControlsAction,
    leaveResponseToNCRByUserAction,
    setSnapshotReportResponseByUserAction,
    uploadAssetsToNCRByUserAction
} from "./actions";
import {TAssetDataCOLLECTOR, TAssetDataCUSTOM} from "../../../frameworks/types";
import {
    SEND_REPORT_STATUS_CHANGED_ACTION,
    SEND_UPDATE_NCR_REPORT_ACTION,
    SEND_UPDATE_REVIEWED_CONTROL_ACTION
} from "../../actions";
import {setPaginationInStore} from "../../../../../newShared/hooks/useScroll/helpers";
import {minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {minMaxLoadedPageDefault} from "../../../../../newShared/components/genericTable/constants";
import {handlePagination} from "../../../../../newShared/components/genericTable/helpers";

export type TReportViewSlice = {
    report: {
        report: TReportView | null,
        NCRs: TSmallNCR[],
    },

    isLoading: {
        isLoadingReport: boolean,
        isLoadingControls: boolean,
        isLoadingControl: boolean,

        isSubmittingReport: boolean,
        isSubmittingNcr: string[],

        isLoadingFiles: string[],
        isDeletingFiles: string[],
        isUploadingAsset: boolean,
    },

    controls: {
        controls: (TReportSmallControl)[],
        minMaxLoadedPage: minMaxLoadedPageType,
        pageInfo: TPageInfo,

        selectedControl: TReportControl | null,
    },

    integrations: {
        integrations: TReportIntegration[],
        selectedApp: {
            app: TReportIntegration | null;
            selectedCollection: string | null;
            collectionData: {[key: string]: any}[];
            collectionDataPageInfo: TPageInfo;
            selectedCollectionObject: {[key: string]: any} | null;
            logsData: {
                logs: (TReportIntegrationLog | null)[];
                pageInfo: TPageInfo;
                selectedLog: TReportIntegrationLog | null;
                selectedLogResponse: string;
            }
        },
        loading: {
            isAppListLoading: boolean,
            isAppLoading: boolean,
            isDataByCollectionLoading: boolean,

            isLogsDownloading: boolean,
            isLogDownloading: boolean,
            isLogPreviewLoading: boolean,
        },
    },

    users: TIdNameEmail[];

    dialogs: {
        preview: TDialog & {
            reportId: string | null,
            data: TFullNcrReportPreview | null,
        },

        collectorView: TDialog & {
            ids: {reportId: string, controlId: string, assetId: string, evidenceId: string} | null,
            keys: string[],
            data: (TAssetDataCOLLECTOR[number] | null)[],
            pageInfo: TPageInfo,
        }

        statement: TDialog & {
            data: TAssetDataCUSTOM | null,
        }
    },
}

const initialState: TReportViewSlice = {
    report: {
        report: null,
        NCRs: [],
    },
    isLoading: {
        isLoadingReport: false,
        isLoadingControls: false,
        isLoadingControl: false,

        isSubmittingReport: false,
        isSubmittingNcr: [],

        isLoadingFiles: [],
        isDeletingFiles: [],
        isUploadingAsset: false,
    },

    controls: {
        controls: [],
        pageInfo: {page: 0, count: 50, total: 0},
        minMaxLoadedPage: minMaxLoadedPageDefault,

        selectedControl: null,
    },

    integrations: {
        integrations: [],
        selectedApp: {
            app: null,
            selectedCollection: null,
            collectionData: [],
            collectionDataPageInfo: {page: 0, count: 50, total: 0, sort: ''},
            selectedCollectionObject: null,
            logsData: {
                logs: [],
                pageInfo: {page: 0, count: 50, total: 0, sort: ''},
                selectedLog: null,
                selectedLogResponse: ''
            }
        },
        loading: {
            isAppListLoading: false,
            isAppLoading: false,
            isDataByCollectionLoading: false,

            isLogsDownloading: false,
            isLogDownloading: false,
            isLogPreviewLoading: false,
        },
    },

    users: [],

    dialogs: {
        preview: {
            isLoading: false,
            isOpen: false,
            reportId: null,
            data: null,
        },

        collectorView: {
            isLoading: false,
            isOpen: false,
            ids: null,
            data: [],
            keys: [],
            pageInfo: {page: 0, count: 50, total: 0, sort: ''}
        },

        statement: {
            isLoading: false,
            isOpen: false,
            data: null,
        },
    },
}

const Slice = createSlice({
    name: 'reportView',
    initialState,
    reducers: {
        replaceMinMaxLoadedPage(slice: TReportViewSlice, {payload}: {payload: minMaxLoadedPageType}) {
            slice.controls.minMaxLoadedPage = payload;
        },
        cleanAction(slice: TReportViewSlice) {
            slice.report = initialState.report;
            slice.controls = initialState.controls;

            slice.dialogs = initialState.dialogs;
        },
        cleanReportAction(slice: TReportViewSlice) {
            slice.report = initialState.report;
        },
        cleanReportControlsAction(slice: TReportViewSlice) {
            slice.controls = initialState.controls;
        },
        cleanSelectedControlAction(slice: TReportViewSlice) {
            slice.controls.selectedControl = initialState.controls.selectedControl;
        },

        setPreviewDialogAction(slice: TReportViewSlice, {payload}: {payload: TSetPreviewReportDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.preview.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.preview.isLoading = payload.isLoading;
            if (payload.reportId !== undefined) slice.dialogs.preview.reportId = payload.reportId;

            if (payload.clear) slice.dialogs.preview = initialState.dialogs.preview;
        },

        setCollectorViewAction(slice: TReportViewSlice, {payload}: {payload: TSetCollectorView}) {
            if (payload.isOpen !== undefined) slice.dialogs.collectorView.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.collectorView.isLoading = payload.isLoading;
            if (payload.ids !== undefined) slice.dialogs.collectorView.ids = payload.ids;

            if (payload.clear) slice.dialogs.collectorView = initialState.dialogs.collectorView;
        },

        setStatementDialogAction(slice: TReportViewSlice, {payload}: {payload: TSetStatementDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.statement.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.statement.isLoading = payload.isLoading;
            if (payload.data !== undefined) slice.dialogs.statement.data = payload.data;

            if (payload.clear) slice.dialogs.statement = initialState.dialogs.statement;
        },

        deselectApplicationAction: (slice: TReportViewSlice) => {
            slice.integrations.selectedApp = {
                app: null,
                selectedCollection: null,
                collectionData: [],
                collectionDataPageInfo: {page: 0, count: 50, sort: '', total: 0},
                selectedCollectionObject: null,
                logsData: {
                    logs: [],
                    pageInfo: {page: 0, count: 50, sort: '', total: 0},
                    selectedLog: null,
                    selectedLogResponse: ''
                }
            }
        },
        selectLogAction: (slice: TReportViewSlice, {payload}: {payload: TReportIntegrationLog}) => {
            if (slice.integrations.selectedApp){
                slice.integrations.selectedApp.logsData.selectedLog = payload;
            }
        },
        deselectLogAction: (slice: TReportViewSlice) => {slice.integrations.selectedApp.logsData.selectedLog = null},
        deselectLogFetchResultAction: (slice: TReportViewSlice) => {slice.integrations.selectedApp.logsData.selectedLogResponse = ''},

        eraseCollectionDataBeforeFetch: (slice) => {
            slice.integrations.selectedApp.selectedCollectionObject = null;
            slice.integrations.selectedApp.collectionData = [];
        },
        setSelectedCollectionObject: (slice, {payload}: {payload: {[key: string]: any}}) => {
            slice.integrations.selectedApp.selectedCollectionObject = payload;
        },
        deselectCollectionObject: (slice) => {slice.integrations.selectedApp.selectedCollectionObject = null},
    },
    extraReducers: builder => {
      builder
          .addCase(getReportAction.pending, (slice: TReportViewSlice) => {
              slice.isLoading.isLoadingReport = true;
              slice.report = initialState.report;
          })
          .addCase(getReportAction.rejected, (slice: TReportViewSlice) => {
              slice.isLoading.isLoadingReport = false;
          })
          .addCase(getReportAction.fulfilled, (slice: TReportViewSlice, {payload}) => {
              slice.isLoading.isLoadingReport = false;
              slice.report.report = payload.resp.report;
              slice.report.NCRs = payload.resp.NCRs;
          })

          .addCase(getReportControlsAction.pending, (slice: TReportViewSlice, {meta: {arg}}) => {
              slice.isLoading.isLoadingControls = true;
              if (slice.report?.report?.id !== arg.data.reportId || arg.clean) {
                  slice.controls = initialState.controls
              }
          })
          .addCase(getReportControlsAction.rejected, (slice: TReportViewSlice) => {
              slice.isLoading.isLoadingControls = false;
          })
          .addCase(getReportControlsAction.fulfilled, (slice: TReportViewSlice, {payload}) => {
              slice.isLoading.isLoadingControls = false;
              slice.users.push(...payload.resp.users.filter(user => !slice.users.some(e => e.id === user.id)))

              // const {array, pageInfo} = setPaginationInStore<TReportSmallControl>(
              //     slice.controls.pageInfo,
              //     slice.controls.controls,
              //     payload.resp.pageInfo,
              //     payload.resp.controls,
              //     payload.isNewOnTop
              // );
              // slice.controls.controls = array;
              // slice.controls.pageInfo = pageInfo;
              const {results, maxLoadedPage, minLoadedPage} = handlePagination<TReportSmallControl>(
                  slice.controls.controls,
                  slice.controls.pageInfo,
                  payload.resp.controls,
                  payload.resp.pageInfo,
                  slice.controls.minMaxLoadedPage.minLoadedPage,
                  slice.controls.minMaxLoadedPage.maxLoadedPage,
                  'id'
              );

              slice.controls.controls = results;
              slice.controls.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
              slice.controls.pageInfo = payload.resp.pageInfo;
          })

          .addCase(getReportControlByIdAction.pending, (slice: TReportViewSlice) => {
              slice.isLoading.isLoadingControl = true;
              slice.controls.selectedControl = initialState.controls.selectedControl;
          })
          .addCase(getReportControlByIdAction.rejected, (slice: TReportViewSlice) => {
              slice.isLoading.isLoadingControl = false;
          })
          .addCase(getReportControlByIdAction.fulfilled, (slice: TReportViewSlice, {payload}) => {
              slice.isLoading.isLoadingControl = false;
              slice.controls.selectedControl = payload.resp.controls[0];
              slice.users.push(...payload.resp.users.filter(user => !slice.users.some(e => e.id === user.id)))
          })

          .addCase(leaveResponseToNCRByUserAction.pending, (slice: TReportViewSlice, {meta: {arg}}) => {
              slice.isLoading.isSubmittingNcr.push(arg.data.ncrId);
          })
          .addCase(leaveResponseToNCRByUserAction.rejected, (slice: TReportViewSlice, {meta: {arg}}) => {
              slice.isLoading.isSubmittingNcr = slice.isLoading.isSubmittingNcr.filter(e => e !== arg.data.ncrId);
          })
          .addCase(leaveResponseToNCRByUserAction.fulfilled, (slice: TReportViewSlice, {payload}) => {
              slice.isLoading.isSubmittingNcr = slice.isLoading.isSubmittingNcr.filter(e => e !== payload.data.ncrId);
              if (slice.controls.selectedControl?.id === payload.resp.controlId) {
                  const tmp = slice.controls.selectedControl.ncr;
                  const index = tmp.findIndex(e => e.id === payload.resp.id);
                  if (index > -1) {
                      tmp[index] = payload.resp;
                      slice.controls.selectedControl.ncr = tmp;
                  }
              }

              {
                  const tmp = slice.controls.controls;
                  const index = tmp.findIndex(e => e?.id === payload.resp.controlId);
                  if (index > -1 && tmp[index]) {
                      const ncrIndex = tmp[index]!.ncr.findIndex(e => e.id === payload.resp.id);
                      if (ncrIndex > -1) {
                          tmp[index]!.ncr[ncrIndex].status = payload.resp.status;
                          slice.controls.controls = tmp;
                      }
                  }
              }

              {
                  const tmp = slice.report.NCRs;
                  const indexNcr = tmp.findIndex(e => e?.id === payload.resp.id);
                  if (indexNcr > -1) {
                      tmp[indexNcr].status = payload.resp.status;
                      slice.report.NCRs = tmp;
                  }
              }
          })

          .addCase(uploadAssetsToNCRByUserAction.pending, (slice: TReportViewSlice, {meta: {arg}}) => {
              slice.isLoading.isUploadingAsset = true;
          })
          .addCase(uploadAssetsToNCRByUserAction.rejected, (slice: TReportViewSlice, {meta: {arg}}) => {
              slice.isLoading.isUploadingAsset = false;
          })
          .addCase(uploadAssetsToNCRByUserAction.fulfilled, (slice: TReportViewSlice, {payload}) => {
              slice.isLoading.isUploadingAsset = false;
              if (slice.controls.selectedControl?.id === payload.resp.controlId) {
                  const tmp = slice.controls.selectedControl.ncr;
                  const index = tmp.findIndex(e => e.id === payload.resp.id);
                  if (index > -1) {
                      tmp[index] = payload.resp;
                      slice.controls.selectedControl.ncr = tmp;
                  }
              }

              {
                  const tmp = slice.controls.controls;
                  const index = tmp.findIndex(e => e?.id === payload.resp.controlId);
                  if (index > -1 && tmp[index]) {
                      const ncrIndex = tmp[index]!.ncr.findIndex(e => e.id === payload.resp.id);
                      if (ncrIndex > -1) {
                          tmp[index]!.ncr[ncrIndex].status = payload.resp.status;
                          slice.controls.controls = tmp;
                      }
                  }
              }

              {
                  const tmp = slice.report.NCRs;
                  const indexNcr = tmp.findIndex(e => e?.id === payload.resp.id);
                  if (indexNcr > -1) {
                      tmp[indexNcr].status = payload.resp.status;
                      slice.report.NCRs = tmp;
                  }
              }
          })

          .addCase(deleteAssetFromNCRByUserAction.pending, (slice: TReportViewSlice, {meta: {arg}}) => {
              slice.isLoading.isDeletingFiles.push(arg.data.assetId)
          })
          .addCase(deleteAssetFromNCRByUserAction.rejected, (slice: TReportViewSlice, {meta: {arg}}) => {
              slice.isLoading.isDeletingFiles = slice.isLoading.isDeletingFiles.filter(e => e !== arg.data.assetId);
          })
          .addCase(deleteAssetFromNCRByUserAction.fulfilled, (slice: TReportViewSlice, {payload}) => {
              slice.isLoading.isDeletingFiles = slice.isLoading.isDeletingFiles.filter(e => e !== payload.data.assetId);
              if (slice.controls.selectedControl?.id === payload.resp.controlId) {
                  const tmp = slice.controls.selectedControl.ncr;
                  const index = tmp.findIndex(e => e.id === payload.resp.id);
                  if (index > -1) {
                      tmp[index] = payload.resp;
                      slice.controls.selectedControl.ncr = tmp;
                  }
              }
          })

          .addCase(setSnapshotReportResponseByUserAction.pending, (slice: TReportViewSlice, {meta: {arg}}) => {
              slice.isLoading.isSubmittingReport = true;
          })
          .addCase(setSnapshotReportResponseByUserAction.rejected, (slice: TReportViewSlice, {meta: {arg}}) => {
              slice.isLoading.isSubmittingReport = false;
          })
          .addCase(setSnapshotReportResponseByUserAction.fulfilled, (slice: TReportViewSlice, {payload}) => {
              slice.isLoading.isSubmittingReport = false;
              if (slice.report.report?.id === payload.data.reportId) {
                  slice.report.report.reportStatus = 'RESPONSE';
              }
          })

          .addCase(downloadEvidenceFileAction.pending, (slice: TReportViewSlice, {meta: {arg}}) => {
              slice.isLoading.isLoadingFiles.push(arg.data.fileId);
          })
          .addCase(downloadEvidenceFileAction.rejected, (slice: TReportViewSlice, {meta: {arg}}) => {
              slice.isLoading.isLoadingFiles = slice.isLoading.isLoadingFiles.filter(e => e !== arg.data.fileId);
          })
          .addCase(downloadEvidenceFileAction.fulfilled, (slice: TReportViewSlice, {payload}) => {
              slice.isLoading.isLoadingFiles = slice.isLoading.isLoadingFiles.filter(e => e !== payload.data.fileId);
          })

          .addCase(downloadReportCollectorAssetAction.pending, (slice: TReportViewSlice, {meta: {arg}}) => {
              slice.isLoading.isLoadingFiles.push(arg.data.assetId);
          })
          .addCase(downloadReportCollectorAssetAction.rejected, (slice: TReportViewSlice, {meta: {arg}}) => {
              slice.isLoading.isLoadingFiles = slice.isLoading.isLoadingFiles.filter(e => e !== arg.data.assetId);
          })
          .addCase(downloadReportCollectorAssetAction.fulfilled, (slice: TReportViewSlice, {payload}) => {
              slice.isLoading.isLoadingFiles = slice.isLoading.isLoadingFiles.filter(e => e !== payload.data.assetId);
          })

          .addCase(getReportAssetCollectorDataByUserAction.pending, (slice: TReportViewSlice, {meta: {arg}}) => {
              slice.dialogs.collectorView.isLoading = true;
          })
          .addCase(getReportAssetCollectorDataByUserAction.rejected, (slice: TReportViewSlice, {meta: {arg}}) => {
              slice.dialogs.collectorView.isLoading = false;
              slice.dialogs.collectorView = initialState.dialogs.collectorView;
          })
          .addCase(getReportAssetCollectorDataByUserAction.fulfilled, (slice: TReportViewSlice, {payload}) => {
              slice.dialogs.collectorView.isLoading = false;
              slice.dialogs.collectorView.keys = payload.resp.tableFields;

              const {array, pageInfo} = setPaginationInStore<TAssetDataCOLLECTOR[number]>(
                  slice.dialogs.collectorView.pageInfo,
                  slice.dialogs.collectorView.data,
                  payload.resp.pageInfo,
                  payload.resp.data,
                  false
              );
              slice.dialogs.collectorView.data = array;
              slice.dialogs.collectorView.pageInfo = pageInfo;
          })

          //GetInitIntegrationsData
          .addCase(getIntegrationsReportAction.pending, (slice: TReportViewSlice) => {
              slice.integrations.loading.isAppListLoading = true;
          })
          .addCase(getIntegrationsReportAction.rejected, (slice: TReportViewSlice) => {
              slice.integrations.loading.isAppListLoading = false;
          })
          .addCase(getIntegrationsReportAction.fulfilled, (slice: TReportViewSlice, {payload: {data, resp}}) => {
              slice.integrations.loading.isAppListLoading = false;
              slice.integrations.integrations = resp.apps;
              slice.report.report = resp.report;
          })
          //GetDataByCollection
          .addCase(getIntegrationDataByCollectionReportsAction.pending, (slice: TReportViewSlice) => {
              slice.integrations.loading.isDataByCollectionLoading = true;
          })
          .addCase(getIntegrationDataByCollectionReportsAction.rejected, (slice: TReportViewSlice) => {
              slice.integrations.loading.isDataByCollectionLoading = false;
          })
          .addCase(getIntegrationDataByCollectionReportsAction.fulfilled, (slice: TReportViewSlice, {payload}) => {
              slice.integrations.loading.isDataByCollectionLoading = false;

              const {array, pageInfo} = setPaginationInStore<Record<string, any>[number]>(
                  slice.integrations.selectedApp.collectionDataPageInfo,
                  slice.integrations.selectedApp.collectionData,
                  payload.resp.pageInfo,
                  payload.resp.data,
                  false
              );
              slice.integrations.selectedApp.collectionData = array;
              slice.integrations.selectedApp.collectionDataPageInfo = pageInfo;
          })
          //GetAppLogs
          .addCase(GetAppLogsAction.pending, (slice: TReportViewSlice) => {
              slice.integrations.loading.isLogsDownloading = true;
          })
          .addCase(GetAppLogsAction.rejected, (slice: TReportViewSlice) => {
              slice.integrations.loading.isLogsDownloading = false;
          })
          .addCase(GetAppLogsAction.fulfilled, (slice: TReportViewSlice, {payload: {resp, data}}) => {
              slice.integrations.loading.isLogsDownloading = false;

              const {array, pageInfo} = setPaginationInStore<TReportIntegrationLog>(
                  slice.integrations.selectedApp.logsData.pageInfo,
                  slice.integrations.selectedApp.logsData.logs,
                  resp.pageInfo,
                  resp.logs,
                  false
              );
              slice.integrations.selectedApp.logsData.logs = array;
              slice.integrations.selectedApp.logsData.pageInfo = pageInfo;
          })
          //DownloadReport
          .addCase(DownloadLogAction.pending, (slice: TReportViewSlice) => {
              slice.integrations.loading.isLogDownloading = true;
          })
          .addCase(DownloadLogAction.rejected, (slice: TReportViewSlice) => {
              slice.integrations.loading.isLogDownloading = false;
          })
          .addCase(DownloadLogAction.fulfilled, (slice: TReportViewSlice, {payload}) => {
              slice.integrations.loading.isLogDownloading = false;
          })
          //DownloadLogForPreview
          .addCase(DownloadLogForPreviewAction.pending, (slice: TReportViewSlice) => {
              slice.integrations.loading.isLogPreviewLoading = true;
          })
          .addCase(DownloadLogForPreviewAction.rejected, (slice: TReportViewSlice) => {
              slice.integrations.loading.isLogPreviewLoading = false;
          })
          .addCase(DownloadLogForPreviewAction.fulfilled, (slice: TReportViewSlice, {payload: {resp, data}}) => {
              slice.integrations.loading.isLogPreviewLoading = false;
              if (slice.integrations.selectedApp){
                  slice.integrations.selectedApp.logsData.selectedLogResponse = resp;
              }
          })
          
          .addCase(getFullNcrReportPreviewByUserAction.pending, (slice: TReportViewSlice, {meta: {arg}}) => {
              slice.dialogs.preview.isLoading = true;
          })
          .addCase(getFullNcrReportPreviewByUserAction.rejected, (slice: TReportViewSlice, {meta: {arg}}) => {
              slice.dialogs.preview.isLoading = false;
          })
          .addCase(getFullNcrReportPreviewByUserAction.fulfilled, (slice: TReportViewSlice, {payload}) => {
              slice.dialogs.preview.isLoading = false;
              slice.dialogs.preview.data = payload.resp;
          })

          .addCase(SEND_UPDATE_NCR_REPORT_ACTION.fulfilled, (slice: TReportViewSlice, {payload}) => {
              {
                  const tmp = slice.report.NCRs;
                  const index = tmp.findIndex(e => e.id === payload.ncrControl.id);
                  if (index > -1) {
                      tmp[index] = {id: payload.ncrControl.id, status: payload.ncrControl.status, type: payload.ncrControl.type};
                      slice.report.NCRs = tmp;
                  }
              }
              {
                  const tmp = slice.controls.controls;
                  let ncrIndex = -1;
                  const index = tmp.findIndex(e => e?.ncr.some((e, index) => {if (e.id === payload.ncrControl.id) {ncrIndex = index; return true} else return false}));
                  if (index > -1 && ncrIndex > -1) {
                      tmp[index]!.ncr[ncrIndex] = {id: payload.ncrControl.id, status: payload.ncrControl.status, type: payload.ncrControl.type};
                      tmp[index]!.reviewed = true;
                      slice.controls.controls = tmp;
                  }
              }
              {
                  const tmp = slice.controls.selectedControl?.ncr;
                  const index = tmp?.findIndex(e => e.id === payload.ncrControl.reportId) ?? -1;
                  if (tmp && index > -1) {
                      tmp[index] = payload.ncrControl;
                      slice.users = [...slice.users.filter(e => !payload.users.some(user => e.id === user.id)), ...payload.users];
                      slice.controls.selectedControl!.ncr = tmp;
                  }
              }
          })

          .addCase(SEND_REPORT_STATUS_CHANGED_ACTION.fulfilled, (slice: TReportViewSlice, {payload}) => {
              if (slice.report.report?.id === payload.reportId) {
                  slice!.report!.report!.reportStatus = payload.status;
              }
          })
          .addCase(SEND_UPDATE_REVIEWED_CONTROL_ACTION.fulfilled, (slice: TReportViewSlice, {payload}) => {
              if (slice.report.report?.id === payload.reportId) {
                  const tmp = slice.controls.controls;
                  const index = tmp.findIndex(e => e?.id === payload.controlId);
                  if (index > -1) {
                      tmp[index]!.reviewed = payload.reviewed;
                      slice.controls.controls = tmp;
                  }
              }
          })
    }
});

export const reportViewReducerReducer = Slice.reducer;

export const {
    cleanAction,
    cleanReportAction,
    cleanSelectedControlAction,
    cleanReportControlsAction,

    setPreviewDialogAction,
    setCollectorViewAction,
    setStatementDialogAction,

    deselectApplicationAction, deselectLogAction, selectLogAction, deselectLogFetchResultAction,
    setSelectedCollectionObject, deselectCollectionObject, eraseCollectionDataBeforeFetch,
    replaceMinMaxLoadedPage
} = Slice.actions;

const selectSelf = (state: AppState): TReportViewSlice => state.reportVew;

export const reportViewSelector = createSelector(selectSelf, state => state.report);
export const reportControlsSelector = createSelector(selectSelf, state => state.controls);
export const reportIntegrationsSelector = createSelector(selectSelf, state => state.integrations);
export const reportIntegrationSelectedSelector = createSelector(selectSelf, state => state.integrations.selectedApp);
export const reportIntegrationLogsSelector = createSelector(selectSelf, state => state.integrations.selectedApp.logsData);
export const reportUsersSelector = createSelector(selectSelf, state => state.users);
export const isLoadingSelector = createSelector(selectSelf, state => state.isLoading);

export const reportViewDialogsSelector = createSelector(selectSelf, state => state.dialogs);