import {deleteRoleDialogPropsTypes} from "../../../types";
import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideDeleteRole} from "../../../store/slice";

export const useDeleteRoleDialog = (props: deleteRoleDialogPropsTypes) => {
    const dispatch = useDispatch();
    const isOpen = useSelector(dialogs).deleteRole;

    const handleClose = () => {
        dispatch(hideDeleteRole());
    }

    const handleSubmit = () => {
        props.handleDeleteRole(props.role?.roleName ?? '');
        dispatch(hideDeleteRole());
    }

    return{
        isOpen,
        handleClose,
        handleSubmit,
        role: props.role
    }
}