// import axiosSync from "../../../shared/utils/axios";
import axiosSync from "../../../../newShared/utils/axios/axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {AxiosResponse} from "axios";
import {
    ChangeAssetStatusMutation,
    ChangeAssetStatusMutationVariables,
    CreateCertificateAssetMutation,
    CreateCertificateAssetMutationVariables,
    CreateCertificateAssetSettingRowMutation,
    CreateCertificateAssetSettingRowMutationVariables,
    CreatePhysicalAssetMutation,
    CreatePhysicalAssetMutationVariables,
    CreatePhysicalAssetSettingRowMutation,
    CreatePhysicalAssetSettingRowMutationVariables,
    CreateSaasAssetMutation,
    CreateSaasAssetMutationVariables,
    CreateSaasAssetSettingRowMutation,
    CreateSaasAssetSettingRowMutationVariables,
    CreateSoftwareAssetMutation,
    CreateSoftwareAssetMutationVariables,
    CreateSoftwareAssetSettingRowMutation,
    CreateSoftwareAssetSettingRowMutationVariables,
    CreateVirtualAssetMutation,
    CreateVirtualAssetMutationVariables,
    CreateVirtualAssetSettingRowMutation,
    CreateVirtualAssetSettingRowMutationVariables,
    DeleteCertificateAssetMutation,
    DeleteCertificateAssetMutationVariables,
    DeleteCertificateAssetSettingRowMutation,
    DeleteCertificateAssetSettingRowMutationVariables,
    DeletePhysicalAssetMutation,
    DeletePhysicalAssetMutationVariables,
    DeletePhysicalAssetSettingRowMutation,
    DeletePhysicalAssetSettingRowMutationVariables,
    DeleteSaasAssetMutation,
    DeleteSaasAssetMutationVariables,
    DeleteSaasAssetSettingRowMutation,
    DeleteSaasAssetSettingRowMutationVariables,
    DeleteSoftwareAssetMutation,
    DeleteSoftwareAssetMutationVariables,
    DeleteSoftwareAssetSettingRowMutation,
    DeleteSoftwareAssetSettingRowMutationVariables,
    DeleteVirtualAssetMutation,
    DeleteVirtualAssetMutationVariables,
    DeleteVirtualAssetSettingRowMutation,
    DeleteVirtualAssetSettingRowMutationVariables,
    EditCertificateAssetSettingRowMutation,
    EditCertificateAssetSettingRowMutationVariables,
    EditPhysicalAssetSettingRowMutation,
    EditPhysicalAssetSettingRowMutationVariables,
    EditSaasAssetSettingRowMutation,
    EditSaasAssetSettingRowMutationVariables,
    EditSoftwareAssetSettingRowMutation,
    EditSoftwareAssetSettingRowMutationVariables,
    EditVirtualAssetSettingRowMutation,
    EditVirtualAssetSettingRowMutationVariables,
    ExportCsvCertificateAssetsQuery,
    ExportCsvCertificateAssetsQueryVariables,
    ExportCsvPhysicalAssetsQuery,
    ExportCsvPhysicalAssetsQueryVariables,
    ExportCsvSaasAssetsQuery,
    ExportCsvSaasAssetsQueryVariables,
    ExportCsvSoftwareAssetsQuery,
    ExportCsvSoftwareAssetsQueryVariables,
    ExportCsvVirtualAssetsQuery,
    ExportCsvVirtualAssetsQueryVariables,
    GetCertificateAssetSettingsQuery,
    GetCertificateAssetSettingsQueryVariables,
    GetCertificateAssetsQuery,
    GetCertificateAssetsQueryVariables,
    GetCertificatesAvailableFieldsForExportQuery,
    GetCertificatesAvailableFieldsForExportQueryVariables,
    GetPhysicalAssetsAvailableFieldsForExportQuery,
    GetPhysicalAssetsAvailableFieldsForExportQueryVariables,
    GetPhysicalAssetSettingsQuery,
    GetPhysicalAssetSettingsQueryVariables,
    GetPhysicalAssetsQuery,
    GetPhysicalAssetsQueryVariables,
    GetSaasAssetSettingsQuery,
    GetSaasAssetSettingsQueryVariables,
    GetSaasAssetsQuery,
    GetSaasAssetsQueryVariables,
    GetSaasAvailableFieldsForExportQuery,
    GetSaasAvailableFieldsForExportQueryVariables,
    GetShortPhysicalAssetsQuery,
    GetShortPhysicalAssetsQueryVariables,
    GetSoftwareAssetsAvailableFieldsForExportQuery,
    GetSoftwareAssetsAvailableFieldsForExportQueryVariables,
    GetSoftwareAssetSettingsQuery,
    GetSoftwareAssetSettingsQueryVariables,
    GetSoftwareAssetsQuery,
    GetSoftwareAssetsQueryVariables,
    GetVirtualAssetsAvailableFieldsForExportQuery,
    GetVirtualAssetsAvailableFieldsForExportQueryVariables,
    GetVirtualAssetSettingsQuery,
    GetVirtualAssetSettingsQueryVariables,
    GetVirtualAssetsQuery,
    GetVirtualAssetsQueryVariables,
    MainAssetsRequestRequestorPagingModel,
    ReleaseCertificateAssetsMutation,
    ReleaseCertificateAssetsMutationVariables,
    ReleasePhysicalAssetsMutation,
    ReleasePhysicalAssetsMutationVariables,
    ReleaseSaasAssetsMutation,
    ReleaseSaasAssetsMutationVariables,
    ReleaseSoftwareAssetsMutation,
    ReleaseSoftwareAssetsMutationVariables,
    ReleaseVirtualAssetsMutation,
    ReleaseVirtualAssetsMutationVariables,
    UpdateCertificateAssetMutation,
    UpdateCertificateAssetMutationVariables,
    UpdatePhysicalAssetMutation,
    UpdatePhysicalAssetMutationVariables,
    UpdateSaasAssetMutation,
    UpdateSaasAssetMutationVariables,
    UpdateSoftwareAssetMutation,
    UpdateSoftwareAssetMutationVariables,
    UpdateVirtualAssetMutation,
    UpdateVirtualAssetMutationVariables,
} from "../../../../newShared/GQLTypes";
import {
    changeAssetStatus,
    createCertificateAsset,
    createCertificateAssetSettingRow,
    createPhysicalAsset,
    createPhysicalAssetSettingRow,
    createSaasAsset,
    createSaasAssetSettingRow,
    createSoftwareAsset,
    createSoftwareAssetSettingRow,
    createVirtualAsset,
    createVirtualAssetSettingRow,
    deleteCertificateAsset,
    deleteCertificateAssetSettingRow,
    deletePhysicalAsset,
    deletePhysicalAssetSettingRow,
    deleteSaasAsset,
    deleteSaasAssetSettingRow,
    deleteSoftwareAsset,
    deleteSoftwareAssetSettingRow,
    deleteVirtualAsset,
    deleteVirtualAssetSettingRow,
    editCertificateAssetSettingRow,
    editPhysicalAssetSettingRow,
    editSaasAssetSettingRow,
    editSoftwareAssetSettingRow,
    editVirtualAssetSettingRow,
    exportCsvCertificateAssets,
    exportCsvPhysicalAssets,
    exportCsvSaasAssets,
    exportCsvSoftwareAssets,
    exportCsvVirtualAssets,
    getCertificateAssetById,
    getCertificateAssets,
    getCertificateAssetSettings,
    getPhysicalAssetById,
    getPhysicalAssets,
    getPhysicalAssetSettings,
    getSaasAssetById,
    getSaasAssets,
    getSaasAssetSettings,
    getShortPhysicalAssets,
    getSoftwareAssetById,
    getSoftwareAssets,
    getSoftwareAssetSettings,
    getVirtualAssetById,
    getVirtualAssets,
    getVirtualAssetSettings,
    importCsvTemplateCertificateAssets,
    importCsvTemplatePhysicalAssets,
    importCsvTemplateSaasAssets,
    importCsvTemplateSoftwareAssets,
    importCsvTemplateVirtualAssets,
    mainAssetsGetAssetRequestRequestorsAutocomplete,
    releaseCertificateAssets,
    releasePhysicalAssets,
    releaseSaasAssets,
    releaseSoftwareAssets,
    releaseVirtualAssets,
    updateCertificateAsset,
    updatePhysicalAsset,
    updateSaasAsset,
    updateSoftwareAsset,
    updateVirtualAsset
} from "./query";
import {TAsset, TCertificateAsset, TSaasAsset, TSoftwareAsset, TVirtualAsset} from "../types";
import {print} from "graphql";
import {gql} from "graphql.macro";


export const getPhysicalAssetByIdApi = async (data: {workspaceId: string, organizationId: string, id: string}, signal?: AbortSignal):Promise<TAsset> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getPhysicalAssetById,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        try{
            return result.data.data.getPhysicalAssetById;
        }catch (ex){
            throw new Error(result.data.errors[0] ?? 'Unknown error!');
        }
    })
)

export const getPhysicalAssetsApi = async (data: GetPhysicalAssetsQueryVariables, signal?: AbortSignal): Promise<GetPhysicalAssetsQuery['getPhysicalAssets']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getPhysicalAssets,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.getPhysicalAssets
        })
    )
)

export const createPhysicalAssetApi = async  (data: CreatePhysicalAssetMutationVariables): Promise<CreatePhysicalAssetMutation['createPhysicalAsset']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: createPhysicalAsset,
                variables: data,
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.createPhysicalAsset
        })
    )
)

export const updatePhysicalAssetApi = async (data: UpdatePhysicalAssetMutationVariables): Promise<UpdatePhysicalAssetMutation['updatePhysicalAsset']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: updatePhysicalAsset,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.updatePhysicalAsset
        })
    )
)

export const deletePhysicalAssetApi = async (data: DeletePhysicalAssetMutationVariables): Promise<DeletePhysicalAssetMutation['deletePhysicalAsset']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: deletePhysicalAsset,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.deletePhysicalAsset
        })
    )
)

export const changeAssetStatusApi = async (data: ChangeAssetStatusMutationVariables): Promise<ChangeAssetStatusMutation['changeAssetStatus']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: changeAssetStatus,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.changeAssetStatus;
    })
)

export const importCsvTemplatePhysicalAssetsApi = async (data: {workspaceId: string, organizationId: string}): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: importCsvTemplatePhysicalAssets,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.importCsvTemplatePhysicalAssets;
    })
)

export const exportCsvPhysicalAssetsApi = async (data: ExportCsvPhysicalAssetsQueryVariables): Promise<ExportCsvPhysicalAssetsQuery["exportCsvPhysicalAssets"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: exportCsvPhysicalAssets,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.exportCsvPhysicalAssets;
    })
)




// SETTINGS
export const getPhysicalAssetsSettingsApi = async (data: GetPhysicalAssetSettingsQueryVariables, signal?: AbortSignal): Promise<GetPhysicalAssetSettingsQuery['getPhysicalAssetSettings']> => {
    // console.log('getPhysicalAssetsSettingsApi - -----------')
    return await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getPhysicalAssetSettings,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getPhysicalAssetSettings;
    })
}

export const createPhysicalAssetsSettingRowApi = async (data: CreatePhysicalAssetSettingRowMutationVariables): Promise<CreatePhysicalAssetSettingRowMutation["createPhysicalAssetSettingRow"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: createPhysicalAssetSettingRow,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createPhysicalAssetSettingRow;
    })
)

export const editPhysicalAssetsSettingRowApi = async (data: EditPhysicalAssetSettingRowMutationVariables): Promise<EditPhysicalAssetSettingRowMutation["editPhysicalAssetSettingRow"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: editPhysicalAssetSettingRow,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.editPhysicalAssetSettingRow;
    })
)

export const deletePhysicalAssetsSettingRowApi = async (data: DeletePhysicalAssetSettingRowMutationVariables): Promise<DeletePhysicalAssetSettingRowMutation['deletePhysicalAssetSettingRow']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: deletePhysicalAssetSettingRow,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deletePhysicalAssetSettingRow;
    })
)

export const releasePhysicalAssetsApi = async (data: ReleasePhysicalAssetsMutationVariables): Promise<ReleasePhysicalAssetsMutation["releasePhysicalAssets"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: releasePhysicalAssets,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.releasePhysicalAssets
        })
    )
)

//VIRTUAL ASSETS
export const getVirtualAssetByIdApi = async (data: {workspaceId: string, organizationId: string, id: string}, signal?: AbortSignal):Promise<TVirtualAsset> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getVirtualAssetById,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        try{
            return result.data.data.getVirtualAssetById;
        }catch (ex){
            throw new Error(result.data.errors[0] ?? 'Unknown error!');
        }
    })
)

export const getVirtualAssetsApi = async (data: GetVirtualAssetsQueryVariables, signal?: AbortSignal): Promise<GetVirtualAssetsQuery['getVirtualAssets']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getVirtualAssets,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.getVirtualAssets
        })
    )
)

export const createVirtualAssetApi = async  (data: CreateVirtualAssetMutationVariables): Promise<CreateVirtualAssetMutation['createVirtualAsset']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: createVirtualAsset,
                variables: data,
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.createVirtualAsset
        })
    )
)

export const updateVirtualAssetApi = async (data: UpdateVirtualAssetMutationVariables): Promise<UpdateVirtualAssetMutation['updateVirtualAsset']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: updateVirtualAsset,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.updateVirtualAsset
        })
    )
)

export const deleteVirtualAssetApi = async (data: DeleteVirtualAssetMutationVariables): Promise<DeleteVirtualAssetMutation['deleteVirtualAsset']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: deleteVirtualAsset,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.deleteVirtualAsset
        })
    )
)

export const importCsvTemplateVirtualAssetsApi = async (data: {workspaceId: string, organizationId: string}): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: importCsvTemplateVirtualAssets,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.importCsvTemplateVirtualAssets;
    })
)
export const exportCsvVirtualAssetsApi = async (data: ExportCsvVirtualAssetsQueryVariables): Promise<ExportCsvVirtualAssetsQuery["exportCsvVirtualAssets"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: exportCsvVirtualAssets,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.exportCsvVirtualAssets;
    })
)

// SETTINGS
export const getVirtualAssetsSettingsApi = async (data: GetVirtualAssetSettingsQueryVariables, signal?: AbortSignal): Promise<GetVirtualAssetSettingsQuery['getVirtualAssetSettings']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getVirtualAssetSettings,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getVirtualAssetSettings;
    })
)

export const createVirtualAssetsSettingRowApi = async (data: CreateVirtualAssetSettingRowMutationVariables): Promise<CreateVirtualAssetSettingRowMutation["createVirtualAssetSettingRow"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: createVirtualAssetSettingRow,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createVirtualAssetSettingRow;
    })
)

export const editVirtualAssetsSettingRowApi = async (data: EditVirtualAssetSettingRowMutationVariables): Promise<EditVirtualAssetSettingRowMutation["editVirtualAssetSettingRow"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: editVirtualAssetSettingRow,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.editVirtualAssetSettingRow;
    })
)

export const deleteVirtualAssetsSettingRowApi = async (data: DeleteVirtualAssetSettingRowMutationVariables): Promise<DeleteVirtualAssetSettingRowMutation['deleteVirtualAssetSettingRow']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: deleteVirtualAssetSettingRow,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteVirtualAssetSettingRow;
    })
)

export const releaseVirtualAssetsApi = async (data: ReleaseVirtualAssetsMutationVariables): Promise<ReleaseVirtualAssetsMutation["releaseVirtualAssets"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: releaseVirtualAssets,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.releaseVirtualAssets
        })
    )
)


//SOFTWARE
export const getSoftwareAssetByIdApi = async (data: {workspaceId: string, organizationId: string, id: string}, signal?: AbortSignal):Promise<TSoftwareAsset> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getSoftwareAssetById,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        try{
            return result.data.data.getSoftwareAssetById;
        }catch (ex){
            throw new Error(result.data.errors[0] ?? 'Unknown error!');
        }
    })
)

export const getSoftwareAssetsApi = async (data: GetSoftwareAssetsQueryVariables, signal?: AbortSignal): Promise<GetSoftwareAssetsQuery['getSoftwareAssets']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getSoftwareAssets,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.getSoftwareAssets
        })
    )
)

export const createSoftwareAssetApi = async  (data: CreateSoftwareAssetMutationVariables): Promise<CreateSoftwareAssetMutation['createSoftwareAsset']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: createSoftwareAsset,
                variables: data,
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.createSoftwareAsset
        })
    )
)

export const updateSoftwareAssetApi = async (data: UpdateSoftwareAssetMutationVariables): Promise<UpdateSoftwareAssetMutation['updateSoftwareAsset']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: updateSoftwareAsset,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.updateSoftwareAsset
        })
    )
)

export const deleteSoftwareAssetApi = async (data: DeleteSoftwareAssetMutationVariables): Promise<DeleteSoftwareAssetMutation['deleteSoftwareAsset']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: deleteSoftwareAsset,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.deleteSoftwareAsset
        })
    )
)

export const importCsvTemplateSoftwareAssetsApi = async (data: {workspaceId: string, organizationId: string}): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: importCsvTemplateSoftwareAssets,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.importCsvTemplateSoftwareAssets;
    })
)
export const exportCsvSoftwareAssetsApi = async (data: ExportCsvSoftwareAssetsQueryVariables): Promise<ExportCsvSoftwareAssetsQuery["exportCsvSoftwareAssets"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: exportCsvSoftwareAssets,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.exportCsvSoftwareAssets;
    })
)

// SETTINGS
export const getSoftwareAssetsSettingsApi = async (data: GetSoftwareAssetSettingsQueryVariables, signal?: AbortSignal): Promise<GetSoftwareAssetSettingsQuery['getSoftwareAssetSettings']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getSoftwareAssetSettings,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getSoftwareAssetSettings;
    })
)

export const createSoftwareAssetsSettingRowApi = async (data: CreateSoftwareAssetSettingRowMutationVariables): Promise<CreateSoftwareAssetSettingRowMutation["createSoftwareAssetSettingRow"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: createSoftwareAssetSettingRow,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createSoftwareAssetSettingRow;
    })
)

export const editSoftwareAssetsSettingRowApi = async (data: EditSoftwareAssetSettingRowMutationVariables): Promise<EditSoftwareAssetSettingRowMutation["editSoftwareAssetSettingRow"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: editSoftwareAssetSettingRow,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.editSoftwareAssetSettingRow;
    })
)

export const deleteSoftwareAssetsSettingRowApi = async (data: DeleteSoftwareAssetSettingRowMutationVariables): Promise<DeleteSoftwareAssetSettingRowMutation['deleteSoftwareAssetSettingRow']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: deleteSoftwareAssetSettingRow,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteSoftwareAssetSettingRow;
    })
)

export const releaseSoftwareAssetsApi = async (data: ReleaseSoftwareAssetsMutationVariables): Promise<ReleaseSoftwareAssetsMutation["releaseSoftwareAssets"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: releaseSoftwareAssets,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.releaseSoftwareAssets
        })
    )
)


//SAAS
export const getSaasAssetByIdApi = async (data: {workspaceId: string, organizationId: string, id: string}, signal?: AbortSignal):Promise<TSaasAsset> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getSaasAssetById,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        try{
            return result.data.data.getSaasAssetById;
        }catch (ex){
            throw new Error(result.data.errors[0] ?? 'Unknown error!');
        }
    })
)

export const getSaasAssetsApi = async (data: GetSaasAssetsQueryVariables, signal?: AbortSignal): Promise<GetSaasAssetsQuery['getSaasAssets']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getSaasAssets,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.getSaasAssets
        })
    )
)

export const createSaasAssetApi = async  (data: CreateSaasAssetMutationVariables): Promise<CreateSaasAssetMutation['createSaasAsset']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: createSaasAsset,
                variables: data,
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.createSaasAsset
        })
    )
)

export const updateSaasAssetApi = async (data: UpdateSaasAssetMutationVariables): Promise<UpdateSaasAssetMutation['updateSaasAsset']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: updateSaasAsset,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.updateSaasAsset
        })
    )
)

export const deleteSaasAssetApi = async (data: DeleteSaasAssetMutationVariables): Promise<DeleteSaasAssetMutation['deleteSaasAsset']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: deleteSaasAsset,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.deleteSaasAsset
        })
    )
)

export const importCsvTemplateSaasAssetsApi = async (data: {workspaceId: string, organizationId: string}): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: importCsvTemplateSaasAssets,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.importCsvTemplateSaasAssets;
    })
)
export const exportCsvSaasAssetsApi = async (data: ExportCsvSaasAssetsQueryVariables): Promise<ExportCsvSaasAssetsQuery["exportCsvSaasAssets"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: exportCsvSaasAssets,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.exportCsvSaasAssets;
    })
)

// SETTINGS
export const getSaasAssetsSettingsApi = async (data: GetSaasAssetSettingsQueryVariables, signal?: AbortSignal): Promise<GetSaasAssetSettingsQuery['getSaasAssetSettings']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getSaasAssetSettings,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getSaasAssetSettings;
    })
)

export const createSaasAssetsSettingRowApi = async (data: CreateSaasAssetSettingRowMutationVariables): Promise<CreateSaasAssetSettingRowMutation["createSaasAssetSettingRow"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: createSaasAssetSettingRow,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createSaasAssetSettingRow;
    })
)

export const editSaasAssetsSettingRowApi = async (data: EditSaasAssetSettingRowMutationVariables): Promise<EditSaasAssetSettingRowMutation["editSaasAssetSettingRow"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: editSaasAssetSettingRow,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.editSaasAssetSettingRow;
    })
)

export const deleteSaasAssetsSettingRowApi = async (data: DeleteSaasAssetSettingRowMutationVariables): Promise<DeleteSaasAssetSettingRowMutation['deleteSaasAssetSettingRow']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: deleteSaasAssetSettingRow,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteSaasAssetSettingRow;
    })
)

export const releaseSaasAssetsApi = async (data: ReleaseSaasAssetsMutationVariables): Promise<ReleaseSaasAssetsMutation["releaseSaasAssets"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: releaseSaasAssets,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.releaseSaasAssets
        })
    )
)

//Certificate
export const getCertificateAssetByIdApi = async (data: {workspaceId: string, organizationId: string, id: string}, signal?: AbortSignal):Promise<TCertificateAsset> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getCertificateAssetById,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        try{
            return result.data.data.getCertificateAssetById;
        }catch (ex){
            throw new Error(result.data.errors[0] ?? 'Unknown error!');
        }
    })
)

export const getCertificateAssetsApi = async (data: GetCertificateAssetsQueryVariables, signal?: AbortSignal): Promise<GetCertificateAssetsQuery['getCertificateAssets']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getCertificateAssets,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.getCertificateAssets
        })
    )
)

export const createCertificateAssetApi = async  (data: CreateCertificateAssetMutationVariables): Promise<CreateCertificateAssetMutation['createCertificateAsset']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: createCertificateAsset,
                variables: data,
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.createCertificateAsset
        })
    )
)

export const updateCertificateAssetApi = async (data: UpdateCertificateAssetMutationVariables): Promise<UpdateCertificateAssetMutation['updateCertificateAsset']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: updateCertificateAsset,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.updateCertificateAsset
        })
    )
)

export const deleteCertificateAssetApi = async (data: DeleteCertificateAssetMutationVariables): Promise<DeleteCertificateAssetMutation['deleteCertificateAsset']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: deleteCertificateAsset,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.deleteCertificateAsset
        })
    )
)

export const importCsvTemplateCertificateAssetsApi = async (data: {workspaceId: string, organizationId: string}): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: importCsvTemplateCertificateAssets,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.importCsvTemplateCertificateAssets;
    })
)
export const exportCsvCertificateAssetsApi = async (data: ExportCsvCertificateAssetsQueryVariables): Promise<ExportCsvCertificateAssetsQuery["exportCsvCertificateAssets"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: exportCsvCertificateAssets,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.exportCsvCertificateAssets;
    })
)

// SETTINGS
export const getCertificateAssetsSettingsApi = async (data: GetCertificateAssetSettingsQueryVariables, signal?: AbortSignal): Promise<GetCertificateAssetSettingsQuery['getCertificateAssetSettings']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getCertificateAssetSettings,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getCertificateAssetSettings;
    })
)

export const createCertificateAssetsSettingRowApi = async (data: CreateCertificateAssetSettingRowMutationVariables): Promise<CreateCertificateAssetSettingRowMutation["createCertificateAssetSettingRow"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: createCertificateAssetSettingRow,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createCertificateAssetSettingRow;
    })
)

export const editCertificateAssetsSettingRowApi = async (data: EditCertificateAssetSettingRowMutationVariables): Promise<EditCertificateAssetSettingRowMutation["editCertificateAssetSettingRow"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: editCertificateAssetSettingRow,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.editCertificateAssetSettingRow;
    })
)

export const deleteCertificateAssetsSettingRowApi = async (data: DeleteCertificateAssetSettingRowMutationVariables): Promise<DeleteCertificateAssetSettingRowMutation['deleteCertificateAssetSettingRow']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: deleteCertificateAssetSettingRow,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteCertificateAssetSettingRow;
    })
)

export const releaseCertificateAssetsApi = async (data: ReleaseCertificateAssetsMutationVariables): Promise<ReleaseCertificateAssetsMutation["releaseCertificateAssets"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: releaseCertificateAssets,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.releaseCertificateAssets
        })
    )
)



export const getShortPhysicalAssetsApi = async (data: GetShortPhysicalAssetsQueryVariables, signal?: AbortSignal): Promise<GetShortPhysicalAssetsQuery['getShortPhysicalAssets']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getShortPhysicalAssets,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.getShortPhysicalAssets
        })
    )
)

export const getPhysicalAssetsAvailableFieldsForExport = async (data: GetPhysicalAssetsAvailableFieldsForExportQueryVariables): Promise<GetPhysicalAssetsAvailableFieldsForExportQuery["getPhysicalAssetsAvailableFieldsForExport"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getPhysicalAssetsAvailableFieldsForExport($workspaceId:String!){
                        getPhysicalAssetsAvailableFieldsForExport(workspaceId:$workspaceId){
                            fields {
                                fieldKey
                                fieldTitle
                            }
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getPhysicalAssetsAvailableFieldsForExport;
    })
);

export const getVirtualAssetsAvailableFieldsForExport = async (data: GetVirtualAssetsAvailableFieldsForExportQueryVariables): Promise<GetVirtualAssetsAvailableFieldsForExportQuery["getVirtualAssetsAvailableFieldsForExport"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getVirtualAssetsAvailableFieldsForExport($workspaceId:String!){
                        getVirtualAssetsAvailableFieldsForExport(workspaceId:$workspaceId){
                            fields {
                                fieldKey
                                fieldTitle
                            }
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getVirtualAssetsAvailableFieldsForExport;
    })
);

export const getSoftwareAssetsAvailableFieldsForExport = async (data: GetSoftwareAssetsAvailableFieldsForExportQueryVariables): Promise<GetSoftwareAssetsAvailableFieldsForExportQuery["getSoftwareAssetsAvailableFieldsForExport"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getSoftwareAssetsAvailableFieldsForExport($workspaceId:String!){
                        getSoftwareAssetsAvailableFieldsForExport(workspaceId:$workspaceId){
                            fields {
                                fieldKey
                                fieldTitle
                            }
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getSoftwareAssetsAvailableFieldsForExport;
    })
);

export const getSaasAvailableFieldsForExport = async (data: GetSaasAvailableFieldsForExportQueryVariables): Promise<GetSaasAvailableFieldsForExportQuery["getSaasAvailableFieldsForExport"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getSaasAvailableFieldsForExport($workspaceId:String!){
                        getSaasAvailableFieldsForExport(workspaceId:$workspaceId){
                            fields {
                                fieldKey
                                fieldTitle
                            }
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getSaasAvailableFieldsForExport;
    })
);

export const getCertificatesAvailableFieldsForExport = async (data: GetCertificatesAvailableFieldsForExportQueryVariables): Promise<GetCertificatesAvailableFieldsForExportQuery["getCertificatesAvailableFieldsForExport"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getCertificatesAvailableFieldsForExport($workspaceId:String!){
                        getCertificatesAvailableFieldsForExport(workspaceId:$workspaceId){
                            fields {
                                fieldKey
                                fieldTitle
                            }
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getCertificatesAvailableFieldsForExport;
    })
);

export const getAssetsRequestRequestorsAutocompleteApi = async (workspaceId: string, {page, count, search}: {search: string, page: number, count: number}, signal?: AbortSignal): Promise<MainAssetsRequestRequestorPagingModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(mainAssetsGetAssetRequestRequestorsAutocomplete),
                variables: {
                    workspaceId,
                    pageRequest: {page, count},
                    search
                }
            },
            {
                headers: buildCommonHeader(),
                // signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.mainAssetsGetAssetRequestRequestorsAutocomplete;
    })
)




