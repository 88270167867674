import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideTemplateInputClientData, kycDictionaries, loadings} from "../../../store/slice";
import {
    Autocomplete,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import * as S from './styled';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import colors from "../../../../../../newShared/theme/colors";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {initialCreateTemplateForm, KYC_CLIENTS_ID_FIELDS_PATH} from "../../../constants";
import {insertClientDataByTemplateRequestType, KycExactDictType} from "../../../types";
import {
    convertTemplateToFillableTemplate,
    getKycDictionary,
    getTargetTypeForPath,
    removeTimeZoneDateFields
} from "../../../helpers";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {InputClientDataDropZone} from "./components/InputClientDataDropZone";
import {useHistory} from "react-router-dom";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {forbiddenWrapperOrganizations} from "../../../../../authWorkspacesCookies/settings/store/slice";
import {CreateTarget} from "../../../store/actions";
import {DEFAULT_DATE_FORMAT} from "../../../../../../newShared/utils/dateTools";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const InputClientDataTemplateDialog = () => {
    const {currData: {selectedOrganization}} = UseManageWorkspacesAndOrganizations();
    const organizations = useSelector(forbiddenWrapperOrganizations);
    const org = organizations.find(e => e.id === selectedOrganization?.id);

    const dispatch = useDispatch();
    const history = useHistory();
    const {isOpen, template} = useSelector(dialogs).templateInputClientData;
    const isLoading = useSelector(loadings).createTarget;
    const dicts = useSelector(kycDictionaries);

    const {setForm, form} = useForm<insertClientDataByTemplateRequestType>(convertTemplateToFillableTemplate(initialCreateTemplateForm));
    const [isCreateAnotherClient, setIsCreateAnotherClient] = useState<boolean>(false);
    const {t} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.dialogs'});


    useEffect(() => {
        if(isOpen && template){
            setForm(convertTemplateToFillableTemplate(template));
        }

        if(!isOpen){
            setForm(convertTemplateToFillableTemplate(initialCreateTemplateForm));
            setIsCreateAnotherClient(false);
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const handleClose = () => {
        dispatch(hideTemplateInputClientData());
    }

    const handleChangeCustomField = (event: React.ChangeEvent<HTMLInputElement>) => {
        const id = event.currentTarget.name;
        const value = event.currentTarget.value;
        setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === id ? {...e, value} : e)}))
    }

    const handleChangeDate = (id: string, date: string | null) => {
        setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === id ? {...e, value: date} : e)}))

        // if(date !== null){
        //     try {
        //         const parsed = new Date(Date.parse(date)).toISOString();
        //         setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === id ? {...e, value: parsed} : e)}))
        //     }catch (ex){
        //         setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === id ? {...e, value: null} : e)}))
        //         console.log(`InputClientDataTemplateDialog handleChangeDate ex`, ex)
        //     }
        // }else{
        //     setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === id ? {...e, value: null} : e)}))
        // }
    }

    const isOk = () => {
        // console.log(`form`, form.fields, form.fields.some(e => (e.required && e.value === null) || (e.value !== null && !e.value.trim().length)));
        return !form.fields.some(e => {
            //looking for bad fields
            if(e.required && e.value === null) return true;
            if((e.required && e.value !== null && typeof e.value === 'string' && !e.value.trim().length)) return true;
            if(e.required && e.type === 'FILE' && (e.fileName === null || e.fileName === undefined || (e.fileName.trim().length === 0))) return true;
            if(e.type === 'DATE' && e.value !== null && e.value !== undefined){
                let parsed;
                try {
                    parsed = new Date(Date.parse(e.value)).toISOString();
                }catch (ex){
                    parsed = null;
                }

                return parsed === null;
            }
            return false;
        })
    }

    const handleSubmit = () => {
        if(isOk()){
            const newFields = removeTimeZoneDateFields(form.fields);
            dispatch(CreateTarget({
                data: {
                    fields: newFields,
                    templateId: form.id
                },
                onSuccess: isCreateAnotherClient ? handleClearForm : handleGoToTarget}
            ));
        }
    }

    const handleGoToTarget = (id: string) => {
        template && history.push(KYC_CLIENTS_ID_FIELDS_PATH.replace(':type', getTargetTypeForPath(undefined, template.type).pathName).replace(':id', id));
        dispatch(hideTemplateInputClientData());
    }

    const handleClearForm = () => {
        setForm((prev) => ({...prev, fields: [...prev.fields.map(e => ({...e, value: e.type === 'DATE' ? null : ''}))]}));
    }

    //comp - upload file - get fileId - fetch outer func - save in form
    const handleFileUpload = (fileId: string, fieldId: string, fileName: string) => {
        setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === fieldId ? {...e, value: fileId, fileName} : e)}))
    }

    const handleDeleteUploadedFile = (fieldId: string) => {
        setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === fieldId ? {...e, value: null, fileName: null} : e)}))
    }

    const handleChangeSelectField = (id: string, value: KycExactDictType | null) => {
        setForm((prev) => ({
            ...prev,
            fields: prev.fields.map(e => e.id === id ? {...e, value: value?.key ?? null} : e)
        }))
    }

    return(
        <>
            {template &&
                <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
                    <DialogTitle>{template.type === 'CORPORATE' ? t('Company verification') : t('Person verification')}</DialogTitle>

                    <DialogContent>
                        <S.PreviewTemplateContainer>
                            {/*<TobBarLogo />*/}
                            {org && <img src={org.logo} alt={org.name} width={'48px'}/>}
                            {org && <Typography align={'center'} variant={'body2'} fontWeight={700}>{t('{{name}} asks you fill form for KYC verification.', {name: org.name})}</Typography>}

                            {template && template.fields
                                .slice()
                                .sort((a, b) => a.order - b.order)
                                .map(e => {
                                    const currentField = form.fields.find(f => f.id === e.id);
                                    const currentDict = getKycDictionary(dicts, e.dictionary);
                                    switch (e.type){
                                    case 'TEXT': {
                                        return <TextField
                                            key={e.id}
                                            required={e.required}
                                            label={e.name}
                                            size={'small'}
                                            name={e.id}
                                            value={currentField?.value ?? ''}
                                            onChange={handleChangeCustomField}
                                        />
                                    }
                                    case 'NUMBER': {
                                        return <TextField
                                            key={e.id}
                                            type={'number'}
                                            required={e.required}
                                            // placeholder={e.name}
                                            label={e.name}
                                            size={'small'}
                                            name={e.id}
                                            value={currentField?.value ?? ''}
                                            onChange={handleChangeCustomField}
                                        />
                                    }
                                    case 'DATE': {
                                        return <DatePicker
                                            key={e.id}
                                            label={e.name}
                                            inputFormat={DEFAULT_DATE_FORMAT}
                                            value={currentField?.value ?? null}
                                            onChange={(newValue) => handleChangeDate(e.id, newValue)}
                                            renderInput={(params) => <TextField {...params} required={e.required} error={false} size={'small'} name={e.id}/>}
                                        />
                                    }
                                    case 'FILE': {
                                        return <S.FileDropContainer key={e.id}>
                                            <Typography fontSize={'12px'} fontWeight={600}>
                                                {e.name}
                                                {e.required && <span style={{color: colors.warningActions.red}}>*</span>}
                                            </Typography>

                                            <InputClientDataDropZone
                                                onFileUploaded={handleFileUpload}
                                                handleDelete={() => currentField && handleDeleteUploadedFile(e.id)}
                                                isLoadingCreateTarget={isLoading}
                                                id={e.id}
                                            />
                                        </S.FileDropContainer>
                                    }
                                    case 'SELECT': {
                                        return <Autocomplete
                                            key={e.id}
                                            id="combo-box-demo"
                                            options={currentDict.slice().sort((a, b) => a.order - b.order)}
                                            value={currentDict.find(f => f.key === currentField?.value)}
                                            isOptionEqualToValue={(a: KycExactDictType, b:  KycExactDictType | undefined) => b !== undefined && a.key === b.key}
                                            getOptionLabel={(e) => e.value}
                                            onChange={(event, value) => handleChangeSelectField(e.id, value)}
                                            sx={{width: '100%'}}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label={e.name}
                                                required={e.required}
                                                fullWidth
                                                error={false}
                                                size={'small'}
                                                name={e.id}
                                                // sx={{'& fieldset': {borderColor: 'black !important'}}}
                                            />}
                                        />
                                    }
                                    default: return <></>;
                                }
                            })}
                        </S.PreviewTemplateContainer>
                    </DialogContent>

                    <DialogActions>
                        <Flex w={'100%'} ai={'center'} jc={'space-between'}>
                            <FormControlLabel
                                checked={isCreateAnotherClient}
                                control={<Checkbox size={'small'}  />}
                                label={t('Create another client')}
                                onChange={() => setIsCreateAnotherClient(!isCreateAnotherClient)}
                            />

                            <Flex ai={'center'}>
                                <Button onClick={handleClose} variant={'text'} size={'medium'}>{t('Cancel')}</Button>
                                <LoadingButton onClick={handleSubmit} size={'medium'} disabled={!isOk()} loading={isLoading}>{t('Create client')}</LoadingButton>
                            </Flex>
                        </Flex>
                    </DialogActions>
                </Dialog>
            }
        </>
    )
}
