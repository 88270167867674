import {FC} from "react";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import {LinkIconButton} from "./styled";
import {TToolBarHandlerAction, TToolBarHandlerPayload} from "../../types";
import {withShrinkZero} from "../../../hoc/withShrinkZero";
import {EditorTooltip} from "../../../commonComponents/editorTooltip";

interface LinkControlProps {
    isLinkSet?: boolean;
    linkSetClickHandler?: (action: TToolBarHandlerAction, payload: TToolBarHandlerPayload) => void;
    isDisabled: boolean
}

const LinkControl: FC<LinkControlProps> = ({isLinkSet = false, linkSetClickHandler = () => {}, isDisabled}) => {

    return <EditorTooltip title="Insert link">
            <span>
                <LinkIconButton active={isLinkSet || undefined}
                                disabled={isDisabled}
                                size="medium"
                                edge={undefined}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    linkSetClickHandler("linkControl", true)
                                }}>
                <InsertLinkIcon/>
            </LinkIconButton>
            </span>
        </EditorTooltip>;
}

const wrapper = withShrinkZero<LinkControlProps>(LinkControl);

export {wrapper as LinkControl};
