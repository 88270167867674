import React, {createRef, FormEvent} from 'react';
import {TCharSequence, textComponentInitialState, textComponentProps} from "./types";
import {TextComponentWrapper} from "./components/styled";
import {
    addClassForSelectedText,
    applySubscriptOrSuperscript,
    convertToList,
    getCharSequenceByHTML,
    getClassNamesByRange,
    getClassNamesByToolBarSettings,
    getFontSizeByFontStyle,
    getGlobalRange,
    getHtmlForCharsAfterCursorUntilNewline,
    getLinkTitleByCursor,
    getNewSelectionWithVariables,
    getPopperHtmlElementByCursor,
    getSelectedElement,
    getToolBarSettingsByAction,
    getToolBarSettingsByClassNames,
    getVirtualElementFromSelection,
    handleBackspace,
    handleFocusedVariable,
    handleVariableRemove,
    handleVariableUpdate,
    insertChar,
    insertPastedText,
    insertVariable,
    isCopiedFromEditor,
    isCursorOrSelectionInLink,
    isCursorOrSelectionIsOverVariable,
    parsePastedHtml,
    removeAllFormattingForSelectedText,
    removeClassForSelectedText,
    removeLink,
    removeSubscriptOrSuperscript,
    renderCharSequence,
    renderTextCharSeq,
    replaceClassForSelectedText,
    replaceWithChar,
    setCaret,
    setSelection,
    updateLink,
    updateSubscriptOrSuperscriptAfterFontSizeUpdate,
} from "./helpers";
import './components/css/index.css';
import {
    BOLD_CSS_CLASS_NAME,
    CHAR_TO_OPEN_VARS,
    EDITOR_COPY_TEXT_ID,
    FONT_COLOR_CSS_CLASS_NAME,
    FONT_CSS_CLASS_NAME,
    FONTSIZE_CSS_CLASS_NAME,
    GET_FONT_COLOR_CSS_CLASS_NAME,
    GET_FONT_CSS_CLASS_NAME,
    GET_FONTSIZE_CSS_CLASS_NAME,
    isNewLine,
    isSpace,
    ITALIC_CSS_CLASS_NAME,
    LINK_DATA_ATTRIBUTE_NAME,
    NEWLINE_TO_SET,
    SPACE_0WIDTH_HTML_CODE,
    SPACE_TO_SET,
    STRIKE_THROUGH_CSS_CLASS_NAME,
    UNDERLINE_CSS_CLASS_NAME,
    VARIABLE_ID_DATA_ATTRIBUTE_NAME
} from "./constants";
import {
    EditorBlockItemType,
    EditorComponentsMethods,
    EditorTextData,
    MapDispatchToPropsObject,
    TChangeVariableEventData,
    TDeleteVariableEventData,
    TEditorTextElementData,
    TSetHighlightedVariableEventData
} from "../../types";
import {TFontStyle, TFontTitle, TListType, TToolBarHandlerAction, TToolBarHandlerPayload} from "../editorToolBar/types";
import {initialEditorToolBarState} from "../editorToolBar/constants";
import {SelectVariablePopper} from "./components/selectVariablePopper";

import {changeVariableEventName, deleteVariableEventName, setHighlightedVariableEventName} from "../../constants";
import {UpdateVariablePopper} from "./components/updateVariablePopper";
import {UpdateLinkPopper} from "./components/updateLinkPopper";
import {NewDocDataVariableModel} from "../../../../GQLTypes";
import debounce from "lodash.debounce";

export class TextComponent extends React.Component<textComponentProps, textComponentInitialState> implements EditorComponentsMethods<EditorTextData> {
    private readonly TYPE = EditorBlockItemType.TEXT;

    constructor(props: textComponentProps) {
        super(props);
        const charSeq = getCharSequenceByHTML(this.props.block.data.innerHtml);
        this.state = {
            isFocused: false,
            data: {
                innerHtml: this.props.block.data.innerHtml ?? '',
                content: [],
                alignText: this.props.block.data.alignText ?? 'left',
            },
            type: this.TYPE,
            id: props.block.id,
            // charSequence: [
            //     {char: '1', styles: [GET_FONTSIZE_CSS_CLASS_NAME(16), GET_FONT_COLOR_CSS_CLASS_NAME('#000000'), GET_FONT_CSS_CLASS_NAME('Monospace')]},
            //     {char: '2', styles: [GET_FONTSIZE_CSS_CLASS_NAME(16), GET_FONT_COLOR_CSS_CLASS_NAME('#000000'), GET_FONT_CSS_CLASS_NAME('Monospace')]},
            //     {char: '3', styles: [GET_FONTSIZE_CSS_CLASS_NAME(16), GET_FONT_COLOR_CSS_CLASS_NAME('#000000'), GET_FONT_CSS_CLASS_NAME('Monospace')]},
            //     {char: '4', styles: [GET_FONTSIZE_CSS_CLASS_NAME(16), GET_FONT_COLOR_CSS_CLASS_NAME('#000000'), GET_FONT_CSS_CLASS_NAME('Monospace')]},
            //     {char: NEWLINE_COMMAND, styles: [GET_FONTSIZE_CSS_CLASS_NAME(16), GET_FONT_COLOR_CSS_CLASS_NAME('#000000'), GET_FONT_CSS_CLASS_NAME('Monospace')]},
            //     {char: '5', styles: [GET_FONTSIZE_CSS_CLASS_NAME(16), GET_FONT_COLOR_CSS_CLASS_NAME('#000000'), GET_FONT_CSS_CLASS_NAME('Monospace')]},
            //     {char: '6', styles: [GET_FONTSIZE_CSS_CLASS_NAME(16), GET_FONT_COLOR_CSS_CLASS_NAME('#000000'), GET_FONT_CSS_CLASS_NAME('Monospace')]},
            //     {char: '7', styles: [GET_FONTSIZE_CSS_CLASS_NAME(16), GET_FONT_COLOR_CSS_CLASS_NAME('#000000'), GET_FONT_CSS_CLASS_NAME('Monospace')]},
            //     {char: '8', styles: [GET_FONTSIZE_CSS_CLASS_NAME(16), GET_FONT_COLOR_CSS_CLASS_NAME('#000000'), GET_FONT_CSS_CLASS_NAME('Monospace')]},
            //
            // ],
            charSequence: charSeq,
            cursorAtIndex: charSeq.length ?? 1,
            selectionStart: -1,
            selectionEnd: -1,
            //used to force focus on div in setFocus
            ref: createRef<HTMLDivElement>(),
            //used to prevent overwrite of toolBar when setting caret back at place after toolbar clicked
            //in setCursorPositionAndSelectionRange we check this bool, if false - updating toolbar by selected char
            //if true - dont update toolBar, and set this bool to true, so the next selection will affect toolBar
            // isDisableUpdateToolBarOnNextSelection: props.handleClickEnter !== undefined,
            isDisableUpdateToolBarOnNextSelection: false,
            currentPopperElement: null,
            isDisableUpdateCharSeqOnNextNewLine: false,
            updateVariablePopperElement: {
                element: null,
                varId: null
            },
            linkPopperElement: {
                element: null,
                href: null,
                title: null,
                linkEnd: -1,
                linkStart: -1
            }
        }
    }

    setActiveBlock: (ids: string[]) => void = () => {};

    setCursorPositionAndSelectionRange = () => {
        //used to find globalRange + update state + find styles by selection + update toolBar
        //
        const isFocused = this.isActive();
        // console.log(`setCursorPositionAndSelectionRange`, this.state.id, isFocused)
        if(isFocused && !this.props.readonly){
            const res = getGlobalRange(this.state.id);
            //if start = -1 & end = -1 - the selection was made out of root, so ignoring
            if(res.start === -1 && res.end === -1){
                // console.log(`ROOT NOT FOUND, RETURNING -1`);
                return;
            }
            console.log(`
            setCursorPositionAndSelectionRange: ${res.start} - ${res.end},
            this.state.id: ${this.state.id},
            stamp: ${new Date().toISOString()}
            `)
            const isCursor = res.start === res.end;
            const classNames = getClassNamesByRange(this.state.charSequence, res.start, res.end);
            const newToolBar = getToolBarSettingsByClassNames(classNames, this.state.data.alignText, !this.state.charSequence.length, this.state.charSequence, res.start, res.end);

            if(this.state.isDisableUpdateToolBarOnNextSelection){
                //if isDisableUpdateToolBarOnNextSelection - not making store and toolbar update
                //used to prevent rewrite toolbar, if new styles where selected and it was cursor
                //(those styles have to apply only next char)
                this.setState((prev) => ({...prev, isDisableUpdateToolBarOnNextSelection: false}));
                // console.log(`isDisableUpdateToolBarOnNextSelection: true - not updating state and toolBar`)
            }else{

                if(isCursorOrSelectionIsOverVariable(this.state.charSequence, res.start, res.end)){
                    //cursor stand inside of variable - selecting whole variable
                    // console.log(`Cursor over VAR`);
                    const newSelection = getNewSelectionWithVariables(this.state.charSequence, res.start, res.end);
                    const root = document.getElementById(this.state.id);
                    root && setSelection(root, newSelection.start, newSelection.end);
                    // console.log(`SELECTION EXPANDEDx`)

                    this.setState(prev => ({
                        ...prev,
                        cursorAtIndex: -1, //if start !== end - we got cursor position
                        selectionStart: newSelection.start,
                        selectionEnd: newSelection.end,
                    }));
                    this.setToolBar(newToolBar, []);
                }

                this.setState(prev => ({
                    ...prev,
                    cursorAtIndex: isCursor ? res.start : -1, //if start !== end - we got cursor position
                    selectionStart: !isCursor ? res.start : -1,
                    selectionEnd: !isCursor ? res.end : -1,
                }));
                this.setToolBar(newToolBar, []);
                // console.log(`CURSOR/SELECTION CALCULATED FOR ${this.state.id}, toolBarUpdated`);
            }
        }
    }

    handlePaste = (event: ClipboardEvent) => {
        const isFocused = this.isActive();
        const isLinkDialogOpened = Boolean(this.state.linkPopperElement.element);
        const isUpdateVariableDialogOpened = Boolean(this.state.updateVariablePopperElement.element);
        const isUseVariableDialogOpened = Boolean(this.state.currentPopperElement);
        // console.log(`PASTE PREVENTED1 isFocused: ${isFocused} | isLinkDialogOpened: ${isLinkDialogOpened} | isUpdateVariableDialogOpened: ${isUpdateVariableDialogOpened}`);
        if(isFocused && !isLinkDialogOpened && !isUpdateVariableDialogOpened && !isUseVariableDialogOpened && !this.props.readonly){
            event.preventDefault();
            // console.log(`--------PASTE PREVENTED2 isFocused: ${isFocused} | isLinkDialogOpened: ${isLinkDialogOpened} | isUpdateVariableDialogOpened: ${isUpdateVariableDialogOpened}`);
            const clipboardData = event.clipboardData;
            if(clipboardData){
                const pastedText = clipboardData.getData('text/plain');
                const pastedHtml = clipboardData.getData('text/html')
                // console.log(`pastedText, pastedHtml`, pastedText, '\n ', pastedHtml);
                if(pastedHtml && isCopiedFromEditor(pastedHtml)){
                    //define if its copied from editor
                    //search for variable -
                    const variables = this.props.editorWorkZoneRef?.getActualVariables();
                    if(variables){
                        const {cursorAt, charSeq} = parsePastedHtml(pastedHtml,
                            this.state.charSequence,
                            this.state.selectionStart,
                            this.state.selectionEnd,
                            this.state.cursorAtIndex,
                            variables
                        );

                        this.setState((prev) => ({...prev, charSequence: charSeq, cursorAtIndex: cursorAt, selectionStart: -1, selectionEnd: -1}));
                        this.rerender(charSeq, cursorAt);
                    }
                }else if(pastedText.length > 0){
                        if(pastedText.startsWith('$')){
                            try{
                                let variable = JSON.parse(pastedText.substring(1));
                                let allVars = this.props.editorWorkZoneRef?.getActualVariables();
                                if(variable && allVars && allVars.find(e => e.id === variable.id)){
                                    const linkHref = isCursorOrSelectionInLink(this.state.charSequence, this.state.cursorAtIndex, this.state.selectionStart, this.state.selectionEnd);
                                    if(linkHref !== null){
                                        //replace selected text with inserted + add href
                                        // console.log(`PASTE: cursor/selection inside link`, linkHref);
                                        if(this.state.cursorAtIndex !== -1){
                                            //cursor
                                            const {newCursor, newCharSeq} = updateLink(this.state.charSequence, variable.value.length > 0 ? variable.value : variable.title, linkHref, this.state.cursorAtIndex, this.state.cursorAtIndex);
                                            this.setState((prev) => ({
                                                ...prev,
                                                currentPopperElement: null,
                                                cursorAtIndex: newCursor,
                                                charSequence: newCharSeq,
                                            }));
                                            this.rerender(newCharSeq, newCursor);
                                        }else{
                                            //selection
                                            const {newCursor, newCharSeq} = updateLink(this.state.charSequence, variable.value.length > 0 ? variable.value : variable.title, linkHref, this.state.selectionStart, this.state.selectionEnd);
                                            this.setState((prev) => ({
                                                ...prev,
                                                currentPopperElement: null,
                                                cursorAtIndex: newCursor,
                                                charSequence: newCharSeq,
                                            }));
                                            this.rerender(newCharSeq, newCursor);
                                        }
                                    }else{
                                        const {newCursor, newCharSequence} = insertVariable(this.state.charSequence, this.state.cursorAtIndex, this.state.selectionStart, this.state.selectionEnd, variable.id, variable.value);
                                        this.setState((prev) => ({
                                            ...prev,
                                            currentPopperElement: null,
                                            cursorAtIndex: newCursor,
                                            charSequence: newCharSequence,
                                        }));
                                        this.rerender(newCharSequence, newCursor);
                                    }
                                    return;
                                }
                            }catch (ex){
                                // console.log(`pasteText`, ex)
                            }
                        }
                        const toolBar = this.props.getToolBarState();
                        const classNames = getClassNamesByToolBarSettings(toolBar);
                        // console.log(`PASTE TEXT WITH CLASSES`, classNames)
                        this.setState(prev => {
                            const {newCursorPosition, newSequence} = insertPastedText(
                                prev.charSequence,
                                pastedText,
                                prev.cursorAtIndex,
                                prev.selectionStart,
                                prev.selectionEnd,
                                classNames
                            );
                            // console.log(`Pasted seq`, newSequence)
                            this.rerender(newSequence, newCursorPosition);

                            return{
                                ...prev,
                                charSequence: newSequence,
                                cursorAtIndex: newCursorPosition,
                                selectionStart: -1,
                                selectionEnd: -1
                            }
                        })
                    }
                }
        }
    }

    handleCopy = (event: ClipboardEvent) => {
        const isFocused = this.isActive();
        const isLinkDialogOpened = Boolean(this.state.linkPopperElement.element);
        const isUpdateVariableDialogOpened = Boolean(this.state.updateVariablePopperElement.element);
        const isUseVariableDialogOpened = Boolean(this.state.currentPopperElement);

        // isFocused && console.log(`handleCopy ${this.state.id}
        //     isFocused: ${isFocused},
        //     isLinkDialogOpened: ${isLinkDialogOpened},
        //     isUpdateVariableDialogOpened: ${isUpdateVariableDialogOpened},
        //     isUseVariableDialogOpened: ${isUseVariableDialogOpened},
        //     this.props.readonly: ${this.props.readonly},
        //     event.clipboardData !== null: ${event.clipboardData !== null}
        // `)

        if(isFocused && !isLinkDialogOpened && !isUpdateVariableDialogOpened && !isUseVariableDialogOpened && !this.props.readonly) {
            if(event.clipboardData){
                if(this.state.selectionStart === this.state.selectionEnd){
                    return;
                }
                const selectedSeq = this.state.charSequence.slice(this.state.selectionStart, this.state.selectionEnd);
                const selectedSeqHtml = renderCharSequence(selectedSeq, false);
                const selectedSeqHtmlWithWrapper = `<div data-editor="${EDITOR_COPY_TEXT_ID}">${selectedSeqHtml}</div>`;
                const selectedSeqText = renderTextCharSeq(this.state.charSequence, this.state.selectionStart, this.state.selectionEnd);
                event.clipboardData.setData("text/html", selectedSeqHtmlWithWrapper);
                event.clipboardData.setData('text/plain', selectedSeqText);
                // console.log(`Copied html \n`, selectedSeqHtmlWithWrapper);
                // console.log(`Copied text \n`, selectedSeqText);
                event.preventDefault();
            }
        }
    }

    handleVariableChange = (ev: Event) => {
        const {detail: {id , value }} = ev as CustomEvent<TChangeVariableEventData>;
        if(this.state.charSequence.find(e => e.varId === id)){
            const charSequence = handleVariableUpdate(this.state.charSequence, id, value);
            this.setState((prev) => ({...prev, charSequence, cursorAtIndex: -1, selectionStart: -1, selectionEnd: -1 }));
            //this will rerender + try setSelection, but will not since selectionStart/selectionEnd are -1, so just rerender
            this.rerender(charSequence, -1);
        }
    }

    handleVariableFocus = (ev: Event) => {
        const {detail: {id}} = ev as CustomEvent<TSetHighlightedVariableEventData>;
        // console.log(`handleVariableFocus`, id)
        if(id ? this.state.charSequence.find(e => e.varId === id) : true) {
            const newSeq = handleFocusedVariable(this.state.charSequence, id);
            this.setState((prev) => ({...prev, charSequence: newSeq, cursorAtIndex: -1, selectionStart: -1, selectionEnd: -1 }));
            this.rerender(newSeq, this.state.cursorAtIndex, true);
        }
    }

    handleVariableDelete = (ev: Event) => {
        const {detail: {id}} = ev as CustomEvent<TDeleteVariableEventData>;
        // console.log(`handleVariableFocus`, id)
        if(id){
            const newSeq = handleVariableRemove(this.state.charSequence, id);
            this.setState((prev) => ({...prev, charSequence: newSeq}))
            this.rerender(newSeq, this.state.cursorAtIndex, true);
        }
    }

    handleClick = (e: MouseEvent) => {
        if(!this.isActive() || this.props.readonly) return;
        // console.log(e);
        //@ts-ignore
        const attributes: NamedNodeMap = e.target.attributes as NamedNodeMap;
        let attr = attributes.getNamedItem(VARIABLE_ID_DATA_ATTRIBUTE_NAME);
        if(attr){
            // console.log(`CLICKED VAR`, attr.value);
            this.setState((prev) => ({...prev, updateVariablePopperElement: {element: e.target as HTMLElement, varId: attr!.value}}));
        }else{
            attr = attributes.getNamedItem(LINK_DATA_ATTRIBUTE_NAME);
            if(attr){
                //link clicked - open dialog
                const {start} = getGlobalRange(this.state.id);
                const {linkTitle, to, from} = getLinkTitleByCursor(this.state.charSequence, start, attr.value);
                // console.log(`CLICKED LINK`, attr.value, linkTitle, this.state.id);
                if(this.state.linkPopperElement.element === null){
                    //dialog is closed

                    this.setState((prev) => ({
                        ...prev,
                        updateVariablePopperElement: {element: null, varId: null},
                        // linkPopperElement: {element: e.target as HTMLElement, href: attr!.value, title: linkTitle, linkStart: from, linkEnd: to}
                        linkPopperElement: {
                            element: getVirtualElementFromSelection(),
                            href: attr!.value, title: linkTitle, linkStart: from, linkEnd: to
                        }
                    }));
                }
            }else{
                //clicked something else - closing all dialogs
                this.setState((prev) => ({
                    ...prev,
                    updateVariablePopperElement: {element: null, varId: null},
                    linkPopperElement: {element: null, href: null, title: null, linkStart: -1, linkEnd: -1}
                }));
            }
        }
    }

    isActive = (): boolean => {
        return this.state.ref.current?.contains(document.activeElement) ?? this.props.parentRef?.checkIsFocused(this.state.id) ?? false;
    }

    _handleBackspace = (e: KeyboardEvent) =>{
        if (e.key === 'Backspace' && this.isActive()) {
            if (this.props.handleClickBackspaceOnEmpty) {
                if (
                    this.state.cursorAtIndex === 0 ||
                    (this.state.cursorAtIndex === 1 && (isSpace(this.state.charSequence[0].char) || this.state.charSequence[0].char === SPACE_0WIDTH_HTML_CODE)) ||
                    (!this.state.charSequence.length)
                ) {
                    this.props.handleClickBackspaceOnEmpty(this.getData());
                    return;
                }
            }
        }



        // if(e.key === 'Backspace' && this.isActive()){
        //     console.log('Backspace pressed', this.state.charSequence.length, this.state.cursorAtIndex)
        //     if (this.props.handleClickBackspaceOnEmpty) {
        //         if(this.state.charSequence.length === 0 || (this.state.charSequence.length === 1 && (this.state.charSequence.length === 1 && (isSpace(this.state.charSequence[0].char) || this.state.charSequence[0].char === SPACE_0WIDTH_HTML_CODE)))){
        //             this.props.handleClickBackspaceOnEmpty?.();
        //             return;
        //         }
        //     }
        //     // console.log(`_handleBackspace`, this.state.cursorAtIndex, this.state.charSequence, this.state.charSequence[0]?.char === SPACE_HTML_CODE, this.state.charSequence[0]?.char === SPACE_0WIDTH_HTML_CODE)
        // }
    }

    componentDidMount() {
        document.addEventListener("selectionchange", this.selectionchangeOnEvent);
        document.addEventListener("paste", this.handlePaste);
        document.addEventListener("copy", this.handleCopy);
        document.addEventListener(changeVariableEventName, this.handleVariableChange);
        document.addEventListener(deleteVariableEventName, this.handleVariableDelete);
        document.addEventListener(setHighlightedVariableEventName, this.handleVariableFocus);
        document.addEventListener('click', this.handleClick);
        document.addEventListener('keydown', this._handleBackspace)

        this.rerender(this.state.charSequence, this.state.cursorAtIndex, true);
    }

    componentWillUnmount() {
        document.removeEventListener("selectionchange", this.selectionchangeOnEvent);
        document.removeEventListener("paste", this.handlePaste);
        document.removeEventListener("copy", this.handleCopy);
        document.removeEventListener(changeVariableEventName, this.handleVariableChange);
        document.removeEventListener(deleteVariableEventName, this.handleVariableDelete);
        document.removeEventListener(setHighlightedVariableEventName, this.handleVariableFocus);
        document.removeEventListener('click', this.handleClick);
        document.removeEventListener('keydown', this._handleBackspace)
    }

    selectionchangeOnEvent = debounce(this.setCursorPositionAndSelectionRange, 50);

    handleOnInput = (e: FormEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        // @ts-ignore
        let char = e.nativeEvent.data;
        // @ts-ignore
        char = e.nativeEvent.inputType === 'insertParagraph' ? NEWLINE_TO_SET : char;
        char = isSpace(char) ? SPACE_TO_SET : char;
        // @ts-ignore
        const isBackspace = e.nativeEvent.inputType === 'deleteContentBackward';
        // @ts-ignore
        const isShiftEnter = e.nativeEvent.inputType === 'insertLineBreak';
        // @ts-ignore
        // console.log(`handleOnInput at ${this.state.cursorAtIndex} | ${char} | ${e.nativeEvent?.inputType} | isShiftEnter:${isShiftEnter}`, this.state.id)
        const toolBar = this.props.getToolBarState();
        // console.log(`INSERTING CHAR WITH TOOLBAR STYLES`, toolBar)
        const classNames = getClassNamesByToolBarSettings(toolBar);
        if(isBackspace){
            // console.log(`isBackspace`)
            //logic for deleting one char if cursor or deleting many by selection
            const {newCursorPosition, newSequence} = handleBackspace(this.state.charSequence, this.state.selectionStart, this.state.selectionEnd, this.state.cursorAtIndex, classNames);
            this.setState(prev => {return {
                ...prev,
                charSequence: newSequence,
                cursorAtIndex: newCursorPosition,
                selectionStart: -1,
                selectionEnd: -1,
            }})
            this.rerender(newSequence, newCursorPosition);
        }else{
            // console.log(`not backspace`)
            if(isShiftEnter){
                // console.log(`insert newLine (shift+enter)`)
                const linkHref = isCursorOrSelectionInLink(
                    this.state.charSequence,
                    this.state.cursorAtIndex,
                    this.state.selectionStart,
                    this.state.selectionEnd,
                    true
                );
                const {newCursorPosition, newSequence} = insertChar(
                    this.state.charSequence,
                    this.state.cursorAtIndex,
                    NEWLINE_TO_SET,
                    classNames,
                    linkHref
                );
                this.setState(prev => {return {
                    ...prev,
                    charSequence: newSequence,
                    cursorAtIndex: newCursorPosition
                }});
                this.rerender(newSequence, newCursorPosition);
                return;
            }
            if(isNewLine(char) && this.props.handleClickEnter !== undefined){
                // console.log(`insert newLine - this.props.handleClickEnter`)
                if(this.state.charSequence.length > this.state.cursorAtIndex){
                    // console.log(`insert newLine - this.props.handleClickEnter - this.state.charSequence.length > this.state.cursorAtIndex`)
                    //after newLine there are more chars
                    //they will go to next block - rendering part of charSeq and providing for next
                    let {newCharSeq, nextLineHtml, newCursor} = getHtmlForCharsAfterCursorUntilNewline(this.state.charSequence, this.state.cursorAtIndex);
                    // console.log(`html for next line`, nextLineHtml)
                    this.props.handleClickEnter(nextLineHtml);
                    this.setState((prev) => ({...prev, charSequence: newCharSeq, cursorAtIndex: newCursor}))
                    this.rerender(newCharSeq, newCursor);
                    return;
                }else{
                    // console.log(`insert newLine - this.props.handleClickEnter - !this.state.charSequence.length > this.state.cursorAtIndex`)
                    this.props.handleClickEnter();
                    // console.log(`this.rerender(this.state.charSequence, this.state.cursorAtIndex);`, this.state.charSequence, this.state.cursorAtIndex)
                    this.rerender(this.state.charSequence, this.state.cursorAtIndex);
                    return;
                }
            }
            //inserting char
            if(this.state.selectionStart === -1 && this.state.selectionEnd === -1){
                // console.log(`insert CHAR BY CURSOR`)
                //cursor
                const linkHref = isCursorOrSelectionInLink(
                    this.state.charSequence,
                    this.state.cursorAtIndex,
                    this.state.selectionStart,
                    this.state.selectionEnd,
                    true
                );
                const {newCursorPosition, newSequence} = insertChar(
                    this.state.charSequence,
                    this.state.cursorAtIndex,
                    char,
                    classNames,
                    linkHref
                );
                this.setState(prev => {return {
                    ...prev,
                    charSequence: newSequence,
                    cursorAtIndex: newCursorPosition
                }});
                this.rerender(newSequence, newCursorPosition);

                if(char === CHAR_TO_OPEN_VARS){
                    // console.log(`INSERTED CHAR IS ±CHAR_TO_OPEN_VARS`)
                    const elem = getPopperHtmlElementByCursor();
                    if(elem){
                        // console.log(`Elem found`, elem)
                        this.setState((prev) => ({...prev, currentPopperElement: elem}));
                    }
                }

            }else{
                // console.log(`insert CHAR BY SELECTION`)
                //replacing selection
                const linkHref = isCursorOrSelectionInLink(
                    this.state.charSequence,
                    this.state.cursorAtIndex,
                    this.state.selectionStart,
                    this.state.selectionEnd,
                    true
                );
                const {newCursorPosition, newSequence} = replaceWithChar(
                    this.state.charSequence,
                    this.state.cursorAtIndex,
                    this.state.selectionStart,
                    this.state.selectionEnd,
                    char,
                    classNames,
                    linkHref
                );
                this.setState(prev => {return {
                    ...prev,
                    charSequence: newSequence,
                    cursorAtIndex: newCursorPosition,
                    selectionStart: -1,
                    selectionEnd: -1
                }})
                this.rerender(newSequence, newCursorPosition);
            }
        }
    }

    replaceContent = (newInnerHTML: string, cursorAtIndex: number) => {
        const charSequence = getCharSequenceByHTML(newInnerHTML);
        this.setState((prev) => ({...prev, charSequence, cursorAtIndex}));
        // this.setFocus();
        this.rerender(charSequence, cursorAtIndex);
    }

    rerender = (charSequence: TCharSequence[], cursorAtIndex: number, disableSetCaretOrSelection?: boolean) => {
        // console.log(`rerender`, cursorAtIndex, this.state.id, charSequence)
        const root = document.getElementById(this.state.id);
        if(root){
            // const clearSeq = clearCoupleZWSPSequence(charSequence);
            root.innerHTML = renderCharSequence(charSequence, !!this.props.readonly);
            // this.setState((prev) => ({...prev, charSequence: clearSeq}));
            if(!disableSetCaretOrSelection){
                if(cursorAtIndex !== -1){
                    setCaret(root, cursorAtIndex);
                }else{
                    setSelection(root, this.state.selectionStart, this.state.selectionEnd);
                }
            }
        }
    }

    handleInsertVariable = (variable: NewDocDataVariableModel) => {
        setTimeout(() => {
            let varValue = (variable.value?.length ?? 0) > 0 ? variable.value : variable.title;
            const {newCursor, newCharSequence} = insertVariable(this.state.charSequence, this.state.cursorAtIndex, this.state.selectionStart, this.state.selectionEnd, variable.id, varValue ?? '');
            this.setState((prev) => ({
                ...prev,
                currentPopperElement: null,
                cursorAtIndex: newCursor,
                charSequence: newCharSequence,
            }));
            this.rerender(newCharSequence, newCursor);
        }, 100);
    }

    handleUpdateLink = (href: string, title: string, from: number, to: number) => {
        const {newCursor, newCharSeq} = updateLink(this.state.charSequence, title, href, from, to);
        this.setState((prev) => ({...prev, charSequence: newCharSeq, cursorAtIndex: newCursor}));
        this.rerender(newCharSeq, newCursor);
    }

    handleUnlink = (from: number, to: number) => {
        const newSeq = removeLink(this.state.charSequence, from, to);
        this.setState((prev) => ({...prev, charSequence: newSeq}));
        this.rerender(newSeq, this.state.cursorAtIndex, true); //text will be kept, so cursor keeps at same index
    }
//RENDER
    render() {
        // console.log(`Boolean(this.state.currentPopperElement)`, Boolean(this.state.currentPopperElement))
        return <>
            <TextComponentWrapper
                ref={this.state.ref}
                // autoFocus
                align={this.state.data.alignText}
                className={'TextComponentWrapper'}
                contentEditable={!this.props.readonly}
                id={this.state.id}
                onInput={this.handleOnInput}
                onFocus={() => this.setState((prev) =>({
                    ...prev,
                    currentPopperElement: null,
                    updateVariablePopperElement: {
                        element: null,
                        varId: null
                    }
                }))}
                //prevent user from dragging selected text
                onDragStart={(e ) => e.preventDefault()}
            />

            <SelectVariablePopper
                initialVariables={this.props.editorWorkZoneRef?.getActualVariables()}
                element={this.state.currentPopperElement}
                isFocused={!!this.state.linkPopperElement.element || this.isActive()}
                handleClose={() => this.setState((prev) => ({...prev, currentPopperElement: null}))}
                handleSelectVariable={this.handleInsertVariable}
            />

            <UpdateVariablePopper
                initialVariables={this.props.editorWorkZoneRef?.getActualVariables()}
                element={this.state.updateVariablePopperElement.element}
                isFocused={!!this.state.linkPopperElement.element || this.isActive()}
                handleClose={() => this.setState((prev) => ({...prev, updateVariablePopperElement: {element: null, varId: null}}))}
                varId={this.state.updateVariablePopperElement.varId}
            />

            <UpdateLinkPopper
                element={this.state.linkPopperElement.element}
                isFocused={!!this.state.linkPopperElement.element || this.isActive()}
                handleClose={() => this.setState((prev) => ({...prev, linkPopperElement: {element: null, href: null, title: null, linkStart: -1, linkEnd: -1}}))}
                href={this.state.linkPopperElement.href}
                title={this.state.linkPopperElement.title}
                from={this.state.linkPopperElement.linkStart}
                to={this.state.linkPopperElement.linkEnd}
                handleUpdateLink={this.handleUpdateLink}
                handleRemoveLink={this.handleUnlink}
            />
        </>
    }
//EditorComponentsMethods
    setTextByToolBar(action: TToolBarHandlerAction, payload: TToolBarHandlerPayload, ids: string[]): void {
        // console.log(`action`, action, payload, ids);
        const isCursor = this.state.cursorAtIndex !== -1;
        //if selection was made - changing styles of selected chars
        //if its cursor - making setToolBar with prev config + changes
        //so at this moment toolbar contains for example isBold: true
        //next inserting char will take this style
        //then cursor will be updated to n+1, where it will find bold css class and update toolbar with bold true,
        // now because of styles by cursor
        //if its cursor and not alignmentControl
        if(action in ['moreToolsOpenClose', 'fontStylesOpenClose', 'fontSizeOpenClose', 'fontOpenClose', 'colorPickerOpenClose']){
            //is used to keep caret at place when menus opening
            //so click on button that opens menu will setCaret
            //and then selection of prop will setCaret to
            //preventing overwrite of toolbar
            this.setState((prev) => ({...prev, isDisableUpdateToolBarOnNextSelection: true}));
            const root = document.getElementById(this.state.id);
            root && setCaret(root, this.state.cursorAtIndex);
        }
        if(isCursor && !['alignmentControl', 'removeFormatting', 'color', 'superscript', 'subscript', 'listTypeControl'].includes(action)){
            const prevToolBar = this.props.getToolBarState();
            const toolBar = getToolBarSettingsByAction(prevToolBar, action, payload);
            this.setToolBar(toolBar, []);
            this.setState((prev) => ({...prev, isDisableUpdateToolBarOnNextSelection: true}));
            this.rerender(this.state.charSequence, this.state.cursorAtIndex);
            // console.log(`modify toolBar + setCaret`)
        }else {
            switch (action){
                case "bold": {
                    if(typeof payload === 'boolean'){
                        let newSequence: TCharSequence[] = [];
                        if(!payload){
                            newSequence = removeClassForSelectedText(this.state.charSequence, this.state.selectionStart, this.state.selectionEnd, BOLD_CSS_CLASS_NAME)
                        }else{
                            newSequence = addClassForSelectedText(this.state.charSequence, this.state.selectionStart, this.state.selectionEnd, BOLD_CSS_CLASS_NAME)
                        }
                        this.setState(prev => ({...prev, charSequence: newSequence}));
                        this.rerender(newSequence, this.state.cursorAtIndex);
                        const classNames = getClassNamesByRange(newSequence, this.state.selectionStart, this.state.selectionEnd);
                        // this.setToolBar(getToolBarSettingsByClassNames(classNames, this.state.data.alignText, !this.state.charSequence.length), []);
                        this.setToolBar(getToolBarSettingsByClassNames(classNames, this.state.data.alignText, !this.state.charSequence.length, newSequence, this.state.selectionStart, this.state.selectionEnd), []);
                    }
                    return;
                }
                case "italic": {
                    if (typeof payload === 'boolean') {
                        let newSequence: TCharSequence[] = [];
                        if (!payload) {
                            newSequence = removeClassForSelectedText(this.state.charSequence, this.state.selectionStart, this.state.selectionEnd, ITALIC_CSS_CLASS_NAME)
                        } else {
                            newSequence = addClassForSelectedText(this.state.charSequence, this.state.selectionStart, this.state.selectionEnd, ITALIC_CSS_CLASS_NAME)
                        }
                        this.setState(prev => ({...prev, charSequence: newSequence}));
                        this.rerender(newSequence, this.state.cursorAtIndex);
                        const classNames = getClassNamesByRange(newSequence, this.state.selectionStart, this.state.selectionEnd);
                        // this.setToolBar(getToolBarSettingsByClassNames(classNames, this.state.data.alignText, !this.state.charSequence.length), []);
                        this.setToolBar(getToolBarSettingsByClassNames(classNames, this.state.data.alignText, !this.state.charSequence.length, newSequence, this.state.selectionStart, this.state.selectionEnd), []);
                    }
                    return;
                }
                case "underline":{
                    if (typeof payload === 'boolean') {
                        let newSequence: TCharSequence[] = [];
                        if (!payload) {
                            newSequence = removeClassForSelectedText(this.state.charSequence, this.state.selectionStart, this.state.selectionEnd, UNDERLINE_CSS_CLASS_NAME)
                        } else {
                            newSequence = addClassForSelectedText(this.state.charSequence, this.state.selectionStart, this.state.selectionEnd, UNDERLINE_CSS_CLASS_NAME)
                        }
                        this.setState(prev => ({...prev, charSequence: newSequence}));
                        this.rerender(newSequence, this.state.cursorAtIndex);
                        const classNames = getClassNamesByRange(newSequence, this.state.selectionStart, this.state.selectionEnd);
                        this.setToolBar(getToolBarSettingsByClassNames(classNames, this.state.data.alignText, !this.state.charSequence.length, newSequence, this.state.selectionStart, this.state.selectionEnd), []);
                        // this.setToolBar(getToolBarSettingsByClassNames(classNames, this.state.data.alignText, !this.state.charSequence.length), []);
                    }
                    return;
                }
                case "strikethrough":{
                    if (typeof payload === 'boolean') {
                        let newSequence: TCharSequence[] = [];
                        if (!payload) {
                            newSequence = removeClassForSelectedText(this.state.charSequence, this.state.selectionStart, this.state.selectionEnd, STRIKE_THROUGH_CSS_CLASS_NAME)
                        } else {
                            newSequence = addClassForSelectedText(this.state.charSequence, this.state.selectionStart, this.state.selectionEnd, STRIKE_THROUGH_CSS_CLASS_NAME)
                        }
                        this.setState(prev => ({...prev, charSequence: newSequence}));
                        this.rerender(newSequence, this.state.cursorAtIndex);
                        const classNames = getClassNamesByRange(newSequence, this.state.selectionStart, this.state.selectionEnd);
                        this.setToolBar(getToolBarSettingsByClassNames(classNames, this.state.data.alignText, !this.state.charSequence.length, newSequence, this.state.selectionStart, this.state.selectionEnd), []);
                        // this.setToolBar(getToolBarSettingsByClassNames(classNames, this.state.data.alignText, !this.state.charSequence.length), []);
                    }
                    return;
                }
                case "fontSizeControl":{
                    if (typeof payload === 'number') {
                        let newSequence: TCharSequence[] = [];
                        // const toolBar = this.props.getToolBarState();
                        // const isSuperscript = toolBar.decorationControl.isSuperscript;
                        newSequence = replaceClassForSelectedText(
                            this.state.charSequence,
                            this.state.selectionStart,
                            this.state.selectionEnd,
                            [],
                            [FONTSIZE_CSS_CLASS_NAME],
                            [GET_FONTSIZE_CSS_CLASS_NAME(payload)]
                        );
                        //if super/subscript was applied to some parts of text and fontSize updates -
                        //the old class name like superscript16 will be kept, so now we need to apply new superscript according to selected fontSize
                        newSequence = updateSubscriptOrSuperscriptAfterFontSizeUpdate(newSequence);
                        this.setState(prev => ({...prev, charSequence: newSequence}));
                        this.rerender(newSequence, this.state.cursorAtIndex);
                        const classNames = getClassNamesByRange(newSequence, this.state.selectionStart, this.state.selectionEnd);
                        // this.setToolBar(getToolBarSettingsByClassNames(classNames, this.state.data.alignText, !this.state.charSequence.length), []);
                        this.setToolBar(getToolBarSettingsByClassNames(classNames, this.state.data.alignText, !this.state.charSequence.length, newSequence, this.state.selectionStart, this.state.selectionEnd), []);
                    }
                    return;
                }
                case "fontControl":{
                    if (typeof payload === 'string') {
                        let newSequence: TCharSequence[] = [];
                        newSequence = replaceClassForSelectedText(
                            this.state.charSequence,
                            this.state.selectionStart,
                            this.state.selectionEnd,
                            [],
                            [FONT_CSS_CLASS_NAME],
                            [GET_FONT_CSS_CLASS_NAME(payload as TFontTitle)]
                        );
                        this.setState(prev => ({...prev, charSequence: newSequence}));
                        this.rerender(newSequence, this.state.cursorAtIndex);
                        const classNames = getClassNamesByRange(newSequence, this.state.selectionStart, this.state.selectionEnd);
                        // this.setToolBar(getToolBarSettingsByClassNames(classNames, this.state.data.alignText, !this.state.charSequence.length), []);
                        this.setToolBar(getToolBarSettingsByClassNames(classNames, this.state.data.alignText, !this.state.charSequence.length, newSequence, this.state.selectionStart, this.state.selectionEnd), []);
                    }
                    return;
                }
                case "removeFormatting": {
                    if(!isCursor){
                        //if its selection - removing formatting for selected text
                        // console.log(`else removeFormatting`)
                        let newSequence = removeAllFormattingForSelectedText(this.state.charSequence, this.state.selectionStart, this.state.selectionEnd);
                        this.setState(prev => ({...prev, charSequence: newSequence}));
                        this.rerender(newSequence, this.state.cursorAtIndex);
                        const classNames = getClassNamesByRange(newSequence, this.state.selectionStart, this.state.selectionEnd);
                        // this.setToolBar(getToolBarSettingsByClassNames(classNames, this.state.data.alignText, !this.state.charSequence.length), []);
                        this.setToolBar(getToolBarSettingsByClassNames(classNames, this.state.data.alignText, !this.state.charSequence.length, newSequence, this.state.selectionStart, this.state.selectionEnd), []);
                    }else{
                        //its cursor - updating toolbar to apply default toolBar for next inserted char
                        //also preventing updating toolbar by cursor on next change (to not overwrite values we set here)s
                        this.setDefaultToolBar();
                        this.setState((prev) => ({...prev, isDisableUpdateToolBarOnNextSelection: true}));
                        const root = document.getElementById(this.state.id);
                        root && setCaret(root, this.state.cursorAtIndex);
                        // console.log(`modify toolBar + setCaret (removeFormatting)`)
                    }
                    return;
                }
                case "alignmentControl": {
                    // console.log(`alignmentControl`, payload, this.state.id)
                    if (typeof payload === 'string') {
                        this.setState((prev) => ({...prev, data: {...prev.data, alignText: payload}}));
                        const classNames = getClassNamesByRange(this.state.charSequence, this.state.selectionStart, this.state.selectionEnd);
                        this.setToolBar(getToolBarSettingsByClassNames(classNames, payload as string, !this.state.charSequence.length, this.state.charSequence, this.state.selectionStart, this.state.selectionEnd), []);
                        this.rerender(this.state.charSequence, this.state.cursorAtIndex);
                    }
                    return;
                }
                case "styledControl":{
                    // console.log(`styledControl`, payload);
                    if(typeof payload === 'string'){
                        const style: TFontStyle = payload as TFontStyle;
                        let newSequence: TCharSequence[] = [];
                        //replacing fontSize + applying bold
                        newSequence = replaceClassForSelectedText(
                            this.state.charSequence,
                            this.state.selectionStart,
                            this.state.selectionEnd,
                            style === 'Normal text' ? [BOLD_CSS_CLASS_NAME] : [], //if normal text - no bold
                            [FONTSIZE_CSS_CLASS_NAME],
                            //if normal text - inserting only new font, if any heading - adding bold
                            style === 'Normal text' ? [getFontSizeByFontStyle(style)] : [getFontSizeByFontStyle(style), BOLD_CSS_CLASS_NAME]
                        );
                        //if super/subscript was applied to some parts of text and fontSize updates -
                        //the old class name like superscript16 will be kept, so now we need to apply new superscript according to selected fontSize
                        newSequence = updateSubscriptOrSuperscriptAfterFontSizeUpdate(newSequence);
                        this.setState(prev => ({...prev, charSequence: newSequence}));
                        this.rerender(newSequence, this.state.cursorAtIndex);
                        const classNames = getClassNamesByRange(newSequence, this.state.selectionStart, this.state.selectionEnd);
                        this.setToolBar(getToolBarSettingsByClassNames(classNames, this.state.data.alignText, !this.state.charSequence.length, newSequence, this.state.selectionStart, this.state.selectionEnd), []);
                        // this.setToolBar(getToolBarSettingsByClassNames(classNames, this.state.data.alignText, !this.state.charSequence.length), []);
                    }
                    return;
                }
                case "color": {
                    if(typeof payload === 'string') {
                        if(!isCursor){
                            // console.log(`color selection`, payload);
                            let newSequence: TCharSequence[] = [];
                            newSequence = replaceClassForSelectedText(
                                this.state.charSequence,
                                this.state.selectionStart,
                                this.state.selectionEnd,
                                [],
                                [FONT_COLOR_CSS_CLASS_NAME],
                                [GET_FONT_COLOR_CSS_CLASS_NAME(payload)]
                            );
                            this.setState(prev => ({...prev, charSequence: newSequence}));
                            this.rerender(newSequence, this.state.cursorAtIndex);
                            const classNames = getClassNamesByRange(newSequence, this.state.selectionStart, this.state.selectionEnd);
                            this.setToolBar(getToolBarSettingsByClassNames(classNames, this.state.data.alignText, !this.state.charSequence.length, newSequence, this.state.selectionStart, this.state.selectionEnd), []);
                        }else{
                            // console.log(`color cursor`, payload);
                            //its cursor - updating toolbar to apply default toolBar for next inserted char
                            //also preventing updating toolbar by cursor on next change (to not overwrite values we set here)s
                            const prevToolBar = this.props.getToolBarState();
                            this.setToolBar({...prevToolBar, decorationControl: {...prevToolBar.decorationControl, color: payload}}, []);
                            this.setState((prev) => ({...prev, isDisableUpdateToolBarOnNextSelection: true}));
                            const root = document.getElementById(this.state.id);
                            root && setCaret(root, this.state.cursorAtIndex);
                        }
                    }
                    return;
                }
                case "superscript": {
                    // console.log(`superscript SWITCH`, payload)
                    if(typeof payload === 'boolean'){
                        if(!isCursor){
                            let newSequence: TCharSequence[] = [];
                            if(payload){
                                newSequence = applySubscriptOrSuperscript(
                                    true,
                                    this.state.charSequence,
                                    this.state.selectionStart,
                                    this.state.selectionEnd,
                                );
                            }else{
                                newSequence = removeSubscriptOrSuperscript(
                                    this.state.charSequence,
                                    this.state.selectionStart,
                                    this.state.selectionEnd,
                                );
                            }
                            this.setState(prev => ({...prev, charSequence: newSequence}));
                            this.rerender(newSequence, this.state.cursorAtIndex);
                            const classNames = getClassNamesByRange(newSequence, this.state.selectionStart, this.state.selectionEnd);
                            this.setToolBar(getToolBarSettingsByClassNames(classNames, this.state.data.alignText, !this.state.charSequence.length, newSequence, this.state.selectionStart, this.state.selectionEnd), []);
                            // this.setToolBar(getToolBarSettingsByClassNames(classNames, this.state.data.alignText, !this.state.charSequence.length), []);
                        }else{
                            //cursor
                            const prevToolBar = this.props.getToolBarState();
                            this.setToolBar({...prevToolBar, decorationControl: {...prevToolBar.decorationControl, isSuperscript: payload, isSubscript: false}}, []);
                            this.setState((prev) => ({...prev, isDisableUpdateToolBarOnNextSelection: true}));
                            const root = document.getElementById(this.state.id);
                            root && setCaret(root, this.state.cursorAtIndex);
                        }
                    }
                    return;
                }
                case "subscript": {
                    if(typeof payload === 'boolean'){
                        if(!isCursor){
                            let newSequence: TCharSequence[] = [];
                            if(payload){
                                newSequence = applySubscriptOrSuperscript(
                                    false,
                                    this.state.charSequence,
                                    this.state.selectionStart,
                                    this.state.selectionEnd,
                                );
                            }else{
                                newSequence = removeSubscriptOrSuperscript(
                                    this.state.charSequence,
                                    this.state.selectionStart,
                                    this.state.selectionEnd,
                                );
                            }
                            this.setState(prev => ({...prev, charSequence: newSequence}));
                            this.rerender(newSequence, this.state.cursorAtIndex);
                            const classNames = getClassNamesByRange(newSequence, this.state.selectionStart, this.state.selectionEnd);
                            this.setToolBar(getToolBarSettingsByClassNames(classNames, this.state.data.alignText, !this.state.charSequence.length, newSequence, this.state.selectionStart, this.state.selectionEnd), []);
                            // this.setToolBar(getToolBarSettingsByClassNames(classNames, this.state.data.alignText, !this.state.charSequence.length), []);
                        }else{
                            //cursor
                            const prevToolBar = this.props.getToolBarState();
                            this.setToolBar({...prevToolBar, decorationControl: {...prevToolBar.decorationControl, isSuperscript: false, isSubscript: payload}}, []);
                            this.setState((prev) => ({...prev, isDisableUpdateToolBarOnNextSelection: true}));
                            const root = document.getElementById(this.state.id);
                            root && setCaret(root, this.state.cursorAtIndex);
                        }
                    }
                    return;
                }
                case "linkControl":{
                    if(payload){
                        //find element by selection
                        const elem = getSelectedElement();
                        let title = this.state.charSequence.slice(this.state.selectionStart, this.state.selectionEnd).map(e => e.char).join('');
                        if(elem){
                            // console.log(`title`, title, elem);

                            this.setState((prev) => ({
                                ...prev,
                                linkPopperElement: {
                                    element: getVirtualElementFromSelection(),
                                    href: elem.getAttribute(LINK_DATA_ATTRIBUTE_NAME) ?? '',
                                    title,
                                    linkStart: this.state.selectionStart,
                                    linkEnd: this.state.selectionEnd
                                }
                            }))
                        }
                    }
                    // console.log(`linkControl`, payload)
                    return;
                }
                case "listTypeControl": {
                    // console.log(`listTypeControl`, payload);
                    if(payload && typeof payload === 'string'){
                        if(this.props.handleClickEnter !== undefined){
                            //in list
                            this.rerender(this.state.charSequence, this.state.cursorAtIndex);
                        }else{
                            const result = convertToList(this.state.charSequence, this.state.cursorAtIndex, this.state.selectionStart, this.state.selectionEnd);
                            this.props.parentRef?.handleDivideToBlocks(result, payload as TListType);
                        }
                    }
                    return;
                }
            }
        }
    }

    setToolBar: MapDispatchToPropsObject["setToolBar"] = (toolBar, ids) => {
        // console.log(`UPDATE TOOLBAR`, toolBar, this.state.id)
        this.props.parentRef?.setToolBar(toolBar, [this.state.id])
    }

    getData: () => TEditorTextElementData = () => {
        const root = document.getElementById(this.state.id);

        //replacing all newlines to <br> to keep them in html
        const cloned = root?.cloneNode(true) as HTMLElement;
        const size = cloned?.children.length || 0;
        for (let i = 0; i < size; i++) {
            const elem = cloned?.children[i];
            if(elem?.tagName === 'SPAN' && elem.innerHTML === NEWLINE_TO_SET){
                const br = document.createElement('br');
                br.classList.add(...elem.classList);
                cloned.replaceChild(br, elem);
            }
        }

        return {
            id: this.state.id,
            type: this.state.type,
            data: {
                innerHtml: cloned?.innerHTML ?? '',
                alignText: this.state.data.alignText,
                content: []
            }
        }
    }

    setFocus: () => void = () => {
        //used by out component to focus on currently selected block
        // console.log(`setFocus`, this.state.id);
        if(this.state.ref.current && this.state.ref.current.parentElement){
            //focusing the block
            this.state.ref.current.focus();
            //updating tool bar to set isDisabled: false and default font/fontSize/fontStyle
            // this.setDefaultToolBar();

            // this.rerender(this.state.charSequence, this.state.cursorAtIndex)

            // if(this.state.cursorAtIndex === -1 && this.state.selectionEnd === -1 && this.state.selectionStart === -1){
            //     this.rerender(this.state.charSequence, this.state.charSequence.length)
            //     this.setState((prev) => ({...prev, cursorAtIndex: prev.charSequence.length}))
            // }else{
            //     console.log(`setFocus (cursor is not -1) doing nothing`, this.state.id);
            // }
            // if(this.props.handleClickEnter !== undefined){
            //     this.rerender(this.state.charSequence, this.state.charSequence.length - 1)
            // }
        }
    }

    setFocusByList: () => void = () => {
        //used by out component to focus on currently selected block
        // console.log(`setFocusByList`, this.state.id, this.state.charSequence);
        if(this.state.ref.current && this.state.ref.current.parentElement){
            //focusing the block
            this.state.ref.current.focus();
            // this.setDefaultToolBar();
            if(this.state.cursorAtIndex === -1){
                this.rerender(this.state.charSequence, this.state.charSequence.length)
                this.setState((prev) => ({...prev, cursorAtIndex: prev.charSequence.length}))
            }else{
                this.rerender(this.state.charSequence, this.state.cursorAtIndex)
            }
        }
    }

    onClickAway: () => void = () => {
        //used by out components
        //to remove selection if component is not focused anymore
        //if selection made starting from block and anded out of block - this block is no longed focused
        //so removing selection
        // console.log(`UNFOCUSED`, this.state.id)
        const selection = window.getSelection();
        if(selection && selection.toString().length > 0) {
            // let startNode = selection.anchorNode;
            // if(startNode?.parentElement?.parentElement?.id === this.state.id){
            //     //checking if roots are equal to prevent removing selection from unfocused block
            //     const range = selection.getRangeAt(0);
            //     selection.removeRange(range);
            //     // console.log(`RANGE REMOVED ON BLOCK`, this.state.id)
            // }else{
            //     // console.log(`UNFOCUS ROOT NOT FOUND`, startNode?.parentElement?.parentElement?.id, this.state.id);
            // }
            const range = selection.getRangeAt(0);
            selection.removeRange(range);
        }
    }

    private setDefaultToolBar = () => {
        this.setToolBar({
            ...initialEditorToolBarState,
            decorationControl: {
                ...initialEditorToolBarState.decorationControl,
                isDisabled: false
            },
            fontControl: {
                ...initialEditorToolBarState.fontControl,
                isDisabled: false,
                value: "Arial"
            },
            fontSizeControl: {
                ...initialEditorToolBarState.fontSizeControl,
                isDisabled: false,
                value: 16
            },
            styledControl: {
                ...initialEditorToolBarState.styledControl,
                isDisabled: false,
                value: "Normal text"
            },
            linkControl: {
                isShow: true,
                isDisabled: false,
                isOpenDialog: false,
                value: false,
            },
        }, []);
    }
}