import React, {KeyboardEvent, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
    addControlDialog,
    controls,
    fetchedControls,
    hideAddControl,
    isLoading,
    pageInfo
} from "../../../store/builder/slice";
import {ROOT_BUILDER} from "../../../constants";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {controlInputType, GET_CONTROLS_EVIDENCES_REQUEST_TYPES} from "../../../types";
import {AddControl, DeattachControl, GetControlsWithFilter, LinkControl} from "../../../store/builder/actions";
import {useScrollPagination} from "../../../../../../newShared/hooks/useScrollPagination";
import {LABEL} from "../../../../../../newShared/constants";


export const useAddControl = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const {currData} = UseManageWorkspacesAndOrganizations();
    const _isLoading = useSelector(isLoading);
    const _controls = useSelector(controls);
    const _fetchedControls = useSelector(fetchedControls);
    const _pageInfo = useSelector(pageInfo);

    const [search, setSearch] = useState<string>('');
    const [isExisting, setIsExisting] = useState<boolean>(false);
    const [category, setCategory] = useState<GET_CONTROLS_EVIDENCES_REQUEST_TYPES>('ALL');
    const controller = new AbortController();
    const {organizationId} = UseManageWorkspacesAndOrganizations();
    const {isOpen, frameworkId} = useSelector(addControlDialog);


    const emptyControl: controlInputType = {
        name: '',
        group: '',
        category: '',
        description: [],
        id: null,
        origin: false,
        organizationId: currData.selectedOrganization?.id || '',
        visibility: 'PRIVATE',
        // frameworkId: frameworkId || '',
    }
    const categoryVariants: GET_CONTROLS_EVIDENCES_REQUEST_TYPES[] = ['ALL', 'REGULAIT', 'PRIVATE', 'PUBLIC', 'PUBLIC_AND_PRIVATE'];

    const [form, updateForm] = useState<controlInputType>(emptyControl);
    const getControls = (page: number, count: number, type: GET_CONTROLS_EVIDENCES_REQUEST_TYPES, name: string, clean: boolean) => dispatch(GetControlsWithFilter({data:{page, count, name, type}, signal: controller.signal, clean}));
    const handleChangeCategory = (value: string) => {
        setCategory(value as GET_CONTROLS_EVIDENCES_REQUEST_TYPES);
    }

    const handleSetCreateOwnControl = () => {
        setIsExisting(false);
        updateForm(emptyControl);
    }

    const handleSetUseExisting = () => {
        setIsExisting(true);
        updateForm(emptyControl);
        setSearch('');
        setCategory('ALL');
    }

    useEffect(() => {
        if(isOpen) updateForm(emptyControl);
        // eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
    }, [_controls]);

    useEffect(() => {
        if(isOpen){
            setCategory('ALL');
            getControls(0, 50, 'ALL', '', true);
        }
        if (!isOpen) {
            controller.abort();
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const handleClose = () => {
        dispatch(hideAddControl());
        updateForm(emptyControl);
    }

    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        updateForm((prevValues) => ({
            ...prevValues,
            [event.target.name]: event.target.value as string,
        }));
    };

    const handleChangeDesc = (event:React.ChangeEvent<HTMLInputElement>) => {
        updateForm((prevValues) => ({
            ...prevValues,
            description: [event.target.value as string],
        }));
    }

    const validateForm = (): boolean => {
        return(
            form.name.trim().length > 0
            // && form.group.trim().length > 0
            // && form.category.trim().length > 0
            // && form.description.length > 0
            // && form.organizationId!.length > 0
        )
    }

    const handleSave = () => {
        if (validateForm()) {
            if (frameworkId) dispatch(AddControl({control: {...form, frameworkId}}));
            else dispatch(AddControl({control: form}))
        }
    }


    const handleChangeSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearch(event.target.value as string);
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement> | {keyCode: number}) => {
        if (event.keyCode === 13)  getControls(0, 50, category, search, true)
    }

    const handleClearSearch = () => {
        setSearch('');
        getControls(0, 50, category, '', true)
    }

    useEffect(() => {
        if(isOpen){
            setSearch('');
            getControls(0, 50, category, '', true);
        }
        //eslint-disable-next-line
    }, [category]);

    const getTitle = (value: GET_CONTROLS_EVIDENCES_REQUEST_TYPES) => {
        switch (value) {
            case ('ALL'):  return 'All';
            case ('REGULAIT'): return LABEL;
            case ('PRIVATE'): return 'Private';
            case ('PUBLIC'): return 'Public';
            case ('PUBLIC_AND_PRIVATE'): return 'Public and private';
            default:  return '';
        }
    }

    const handleDeattachControl = (e: any, id: string) => {
        e.stopPropagation();
        const onSuccess = () => history.push(ROOT_BUILDER + `/framework/${frameworkId}`)
        frameworkId && dispatch(DeattachControl({frameworkId, controlId: id, onSuccess}));
    }

    const handleAddControl = (id: string) => {
        if(frameworkId && id.length > 0) dispatch(LinkControl({frameworkId, controlId: id}));
    }

    // pagination
    const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => {
            getControls(
                page, count, category, search, false
            )
        },
        arrayLength: _fetchedControls.length,
        isLoading: _isLoading,
        pageInfo: _pageInfo,
        cleanData: () => {},
    })

    return {
        isOpen,
        form,
        isExisting,
        setIsExisting,
        handleClose,
        validateForm,
        handleSave,
        category,
        categoryVariants,
        getTitle,
        handleChangeCategory,
        search,
        handleChangeSearch,
        handleKeyDown,
        handleClearSearch,
        _controls,
        _fetchedControls,
        organizationId,
        handleSetCreateOwnControl,
        handleChangeDesc,
        handleChange,
        handleSetUseExisting,
        isLoading: _isLoading,
        _pageInfo,
        handleAddControl,
        handleDeattachControl,
        isShowingNoControls: !isLoading && _fetchedControls.length === 0 && !search.length,
        pagination: {
            bottomLoader: bottomLoader && _fetchedControls.length < _pageInfo.total,
            onScroll,
            scrollRef
        }
    }


}