import {createAsyncThunk} from "@reduxjs/toolkit";
import {changeCurrentUserInfo, changeCurrentUserPass, loadCurrentUserInfo} from "./api/api";
import {TChangeUserInfo, TChangeUserPass} from "./types";
import {setFirstNameAndLastName} from "../cookies/store/sharedWorkspacesAndOrganizations/slice";

export const getCurrentUserInfoAction = createAsyncThunk(
    'userProfile/getInfo',
    async (_, {dispatch, getState}) => {
        // const state = getState() as AppState;
        // const workspaceId = state.workspace.workspaceId || '';

        const resp = await loadCurrentUserInfo()

        if(!localStorage.getItem('publicId')){
            localStorage.setItem('publicId', resp.publicId);
        }

        return resp;
    },
);

export const changeCurrentUserInfoAction = createAsyncThunk(
    'userProfile/changeInfo',
    async (data: TChangeUserInfo, {dispatch, getState}) => {
        // const state = getState() as AppState;
        // const workspaceId = state.workspace.workspaceId || '';
        const resp = await changeCurrentUserInfo(data);
        dispatch(setFirstNameAndLastName({firstName: data.firstName, lastName: data.lastName}));
        return resp
    },
);

export const changeCurrentUserPassAction = createAsyncThunk(
    'userProfile/changePass',
    async (data: TChangeUserPass, {dispatch, getState}) => {
        // const state = getState() as AppState;
        // const workspaceId = state.workspace.workspaceId || '';
        const resp = await changeCurrentUserPass(data);
        return resp
    },
);
