import {Button, styled, TableCell} from "@mui/material";


export const NameTableCell = styled(TableCell)(() => ({
    maxWidth: '450px',
}));

export const TemplateNameTableCell = styled(TableCell)(() => ({
    maxWidth: '300px',
}));

export const ExceptionTableCell = styled(TableCell)(() => ({
    width: '320px',
}));

export const CommonTableCell = styled(TableCell)(() => ({
    minWidth: '100px'
}));

export const ActionTableCell = styled(TableCell)(() => ({
    maxWidth: '200px',
    minWidth: '100px',
}));



export const SmallTextButton = styled(Button)(() => ({
    textTransform: 'none',
    width: 'max-content',
}));
SmallTextButton.defaultProps = {
    variant: 'text',
    size: 'small'
}

export const SmallOutlinedButton = styled(Button)(() => ({
    textTransform: 'none',
    width: 'max-content',
}));
SmallOutlinedButton.defaultProps = {
    variant: 'outlined',
    size: 'small'
}

export const WidthCommonTableCell = styled(TableCell)(() => ({
    maxWidth: '400px',
}));


