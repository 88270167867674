import {gql} from "@apollo/react-hooks";
import {
    commentFragment,
    controlFragment,
    DelegatedTaskModelFragment,
    evidenceAutoFile,
    evidenceFragment,
    frameworkFragment,
    historyFRFragment
} from "./fragments";
import {pageInfoFragment} from "../../../../newShared/graphQl/fragments";

export const getAdoptedFrameworks = gql`
    query getAdoptedFrameworks($organizationId: String!, $workspaceId: String!) {
        getAdoptedFrameworks(organizationId: $organizationId, workspaceId: $workspaceId) {
            ...frameworkFragment
        }
    }
    ${frameworkFragment}
`

export const getAvailableFrameworks = gql`
    query getAvailableFrameworks($organizationId: String!, $workspaceId: String!, $pageInfo: PaginationInput!) {
        getAvailableFrameworks(organizationId: $organizationId, workspaceId: $workspaceId, pageInfo: $pageInfo) {
            frameworks {
                ...frameworkFragment
            }
            pageInfo {
                ...pageInfoFragment
            }
        }
    }
    ${frameworkFragment}
    ${pageInfoFragment}
`

export const adoptFrameworkToWorkspace = gql`
    mutation adoptFrameworkToWorkspace($organizationId: String!, $workspaceId: String!, $frameworkId: String!) {
        adoptFrameworkToWorkspace(organizationId: $organizationId, workspaceId: $workspaceId, frameworkId: $frameworkId) {
            ...frameworkFragment
        }
    }
    ${frameworkFragment}
`

export const removeFrameworkFromWorkspace = gql`
    mutation removeFrameworkFromWorkspace($workspaceId: String!, $wfId: String!){
        removeFrameworkFromWorkspace(workspaceId:$workspaceId, wfId: $wfId) {
            message
        }
    }
`

export const getControlsByFramework = gql`
    query getControlsByFramework($organizationId: String!, $workspaceId: String!, $frameworkId: String!, $pageInfo: PaginationInput!, $language: String!) {
        getControlsByFramework(organizationId: $organizationId, workspaceId: $workspaceId, frameworkId: $frameworkId, pageInfo: $pageInfo, language: $language) {
            framework {
                ...frameworkFragment,
            }
            controls {
                ...controlFragment
            }
            pageInfo {
                ...pageInfoFragment
            }
        }
    }
    ${frameworkFragment}
    ${controlFragment}
    ${pageInfoFragment}
`

export const getControlByIdFrameworkId = gql`
    query getControlByIdFrameworkId($organizationId: String!, $workspaceId: String!, $frameworkId: String!, $controlId: String!, $language: String!) {
        getControlByIdFrameworkId(organizationId: $organizationId, workspaceId: $workspaceId, frameworkId: $frameworkId, controlId: $controlId, language: $language) {
            framework {
                ...frameworkFragment,
            }
            control {
                ...controlFragment
            }
            evidences {
                ...evidenceFragment
            }
        }
    }
    ${frameworkFragment}
    ${controlFragment}
    ${evidenceFragment}
`

export const setEvidenceStatus = gql`
    mutation setEvidenceStatus($organizationId: String!, $workspaceId: String!, $evidenceId: String!, $status: String!) {
        setEvidenceStatus(organizationId: $organizationId, workspaceId: $workspaceId, evidenceId: $evidenceId, status: $status) 
    }
`

export const downloadEvidenceFile = gql`
    query downloadFile($workspaceId: String!, $fileId: String!, $fileName: String!){
        downloadEvidenceFile(workspaceId: $workspaceId, fileName: $fileName, fileId: $fileId) {
            file
            filename
        }
    }
`

export const downloadReportCollectorAsset = gql`
    query downloadReportCollectorAsset($organizationId: String!, $workspaceId: String!, $reportId: String!, $evidenceId: String!, $assetId: String!){
        downloadReportCollectorAsset(organizationId: $organizationId, workspaceId: $workspaceId, reportId: $reportId, evidenceId: $evidenceId, assetId: $assetId) {
            file
            filename
        }
    }
`

export const uploadEvidenceAsset = gql`
    mutation uploadEvidenceAsset($organizationId: String!, $workspaceId: String!, $data: EvidenceAssetInputDto!){
        uploadEvidenceAsset(organizationId: $organizationId, workspaceId: $workspaceId, data: $data) {
            ...evidenceAutoFile
        }
    }
    ${evidenceAutoFile}
`

export const removeEvidenceAsset = gql`
    mutation removeEvidenceAsset($organizationId: String!, $workspaceId: String!, $assetId: String!){
        removeEvidenceAsset(organizationId: $organizationId, workspaceId: $workspaceId, assetId: $assetId) {
            message
        }
    }
`

export const getAutoAssetData = gql`
    query getCollectorAssetData($workspaceId: String!, $assetId: String!, $data: PaginationInput!) {
        getAutoAssetData(workspaceId: $workspaceId, data: $data, id: $assetId) {
            data
            keys
            pageInfo {
                page
                count
                total
                sort
            }
        }
    }
`

export const downloadCollectorAssetPdf = gql`
    query downloadCollectorAssetPdf($organizationId: String!, $workspaceId: String!, $assetId: String!) {
        downloadCollectorAssetPdf(organizationId: $organizationId, workspaceId: $workspaceId, assetId: $assetId) {
            file
            filename
        }
    }
`

export const getEvidenceAssetById = gql`
    query getEvidenceAssetById($workspaceId: String!, $organizationId: String!, $id: String!) {
        getEvidenceAssetById(workspaceId: $workspaceId, organizationId: $organizationId, id: $id) {
            name
            id
            data
            lastCollected
            type
        }
    }
`

export const getFrameworkZip = gql`
    query getFrameworkZip($workspaceId: String!, $organizationId: String!, $frameworkId: String!) {
        getFrameworkZip(organizationId: $organizationId, workspaceId: $workspaceId, frameworkId: $frameworkId) {
            message
        }
    }
`


export const getComments = gql`
    query getComments($workspaceId: String!, $frameworkId: String!, $controlId: String!, $pageInfo: PaginationInput!) {
        getComments(workspaceId: $workspaceId, frameworkId: $frameworkId, controlId: $controlId, pageInfo: $pageInfo) {
            comments {
                ...commentFragment
            }
            pageInfo {
                page
                count
                total
                sort
            }
        }
    }
    ${commentFragment}
`

export const addComment = gql`
    mutation addComment($workspaceId: String!, $frameworkId: String!, $controlId: String!, $text: String!, $authorId: String!) {
        addComment(workspaceId: $workspaceId, frameworkId: $frameworkId, controlId: $controlId, text: $text, authorId: $authorId) {
            ... commentFragment
        }
    }
    ${commentFragment}
`

export const getControlAssignments = gql`
    query getControlAssignments($workspaceId: String!, $frameworkId: String!, $controlId: String!, $text: String!, $pageInfo: PaginationInput!) {
        getAssignmentsToControlStore(workspaceId: $workspaceId, frameworkId: $frameworkId, controlId: $controlId, text: $text, pageInfo: $pageInfo) {
            assignments {
                id
                name
                email
                added
#                logo
            }
            pageInfo {
                page
                count
                total
                sort
            }
        }
    }
`

export const addControlAssignment = gql`
    mutation addControlAssignment($workspaceId: String!, $frameworkId: String!, $controlId: String!, $userId: String!) {
        addAssignmentToControl(workspaceId: $workspaceId, frameworkId: $frameworkId, controlId: $controlId, userId: $userId) {
            id
            message
        }
    }
`

export const deleteControlAssignment = gql`
    mutation deleteControlAssignment($workspaceId: String!, $assignmentId: String!) {
        deleteAssignmentToControl(workspaceId: $workspaceId, assignmentId: $assignmentId) {
            message
        }
    }
`

//Analysis
export const getAnalysisHistoryByFrameworkIdControlId = gql`
    query getAnalysisHistoryByFrameworkIdControlId($organizationId: String!, $workspaceId: String!, $frameworkId: String!, $controlId: String!, $pageInfo: PaginationInput!) {
        getAnalysisHistoryByFrameworkIdControlId(organizationId: $organizationId, workspaceId: $workspaceId, frameworkId: $frameworkId, controlId: $controlId, pageInfo: $pageInfo) {
            history {
                ...historyFRFragment
            }
            pageInfo {
                page
                count
                total
            }
        }
    }
    ${historyFRFragment}
`

export const getAnalysisHistoryByHistoryId = gql`
    query getAnalysisHistoryByFrameworkIdControlIdHistoryId($organizationId: String!, $workspaceId: String!, $analyseId: String!, $historyId: String!) {
        getAnalysisHistoryByFrameworkIdControlIdHistoryId(organizationId: $organizationId, workspaceId: $workspaceId, analyseId: $analyseId, historyId: $historyId) {
            ...historyFRFragment
        }
    }
    ${historyFRFragment}
`

export const getPlaybookHistoryFrameworkDetails = gql`
    query getPlaybookHistoryFrameworkDetails($organizationId: String!, $workspaceId: String!, $playbookId: String!, $historyId: String!) {
        getPlaybookHistoryDetails(organizationId: $organizationId, workspaceId: $workspaceId, playbookId: $playbookId, historyId: $historyId) {
            details
        }
    }
`

export const mainFrameworksGetDelegatedTasksWithFilterPagination = gql`
    query mainFrameworksGetDelegatedTasksWithFilterPagination($workspaceId: String!, $controlId: String!, $pageRequest: SharedPaginationInput!, $filter: MainFrameworksDelegatedTasksFilter!) {
        mainFrameworksGetDelegatedTasksWithFilterPagination(workspaceId: $workspaceId, controlId: $controlId, pageRequest: $pageRequest, filter: $filter) {
            result {...DelegatedTaskModelFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${DelegatedTaskModelFragment}
`;

export const mainFrameworksGetDelegatedTaskById = gql`
    query mainFrameworksGetDelegatedTaskById($workspaceId: String!, $taskId: String!) {
        mainFrameworksGetDelegatedTaskById(workspaceId: $workspaceId, taskId: $taskId) {
            ...DelegatedTaskModelFragment
        }
    }
    ${DelegatedTaskModelFragment}
`

export const mainFrameworksCancelTaskById = gql`
    mutation mainFrameworksCancelTaskById($workspaceId: String!, $taskId: String!, $reason: String!) {
        mainFrameworksCancelTaskById(workspaceId: $workspaceId, taskId: $taskId, reason: $reason) {
            ...DelegatedTaskModelFragment
        }
    }
    ${DelegatedTaskModelFragment}
`

export const mainFrameworksDeclineTaskById = gql`
    mutation mainFrameworksDeclineTaskById($workspaceId: String!, $taskId: String!, $reason: String!) {
        mainFrameworksDeclineTaskById(workspaceId: $workspaceId, taskId: $taskId, reason: $reason) {
            ...DelegatedTaskModelFragment
        }
    }
    ${DelegatedTaskModelFragment}
`

export const mainFrameworksCreateDelegatedTask = gql`
    mutation mainFrameworksCreateDelegatedTask($workspaceId: String!, $task: MainFrameworksCreateDelegatedTaskInput!) {
        mainFrameworksCreateDelegatedTask(workspaceId: $workspaceId, task: $task) {
            message
        }
    }
`;

export const mainFrameworksGetDelegatedTaskPerformerAutocomplete = gql`
    query mainFrameworksGetDelegatedTaskPerformerAutocomplete($workspaceId:String!, $controlId: String!, $pageRequest:SharedPaginationInput!, $search: String!){
        mainFrameworksGetDelegatedTaskPerformerAutocomplete(workspaceId:$workspaceId, controlId: $controlId, pageRequest:$pageRequest, search: $search){
            result {
                collaboratorId
                fullName
            }
            pageInfo{...pageInfoFragment}
        }
    }
    ${pageInfoFragment}
`

export const mainFrameworksGetDelegatedTaskResolverAutocomplete = gql`
    query mainFrameworksGetDelegatedTaskResolverAutocomplete($workspaceId:String!, $controlId: String!, $pageRequest:SharedPaginationInput!, $search: String!){
        mainFrameworksGetDelegatedTaskResolverAutocomplete(workspaceId:$workspaceId, controlId: $controlId, pageRequest:$pageRequest, search: $search){
            result {
                collaboratorId
                fullName
            }
            pageInfo{...pageInfoFragment}
        }
    }
    ${pageInfoFragment}
`

export const mainFrameworksGetDelegatedTaskRequestorAutocomplete = gql`
    query mainFrameworksGetDelegatedTaskRequestorAutocomplete($workspaceId:String!, $controlId: String!, $pageRequest:SharedPaginationInput!, $search: String!){
        mainFrameworksGetDelegatedTaskRequestorAutocomplete(workspaceId:$workspaceId, controlId: $controlId, pageRequest:$pageRequest, search: $search){
            result {
                collaboratorId
                fullName
            }
            pageInfo{...pageInfoFragment}
        }
    }
    ${pageInfoFragment}
`

//mainFrameworksApproveTaskById
export const mainFrameworksApproveTaskById = gql`
    mutation mainFrameworksApproveTaskById($workspaceId: String!, $taskId: String!, $selectedAttachments: FrameworksDelegatedTaskDataFilesListInput!) {
        mainFrameworksApproveTaskById(workspaceId: $workspaceId, taskId: $taskId, selectedAttachments: $selectedAttachments) {
            ...DelegatedTaskModelFragment
        }
    }
    ${DelegatedTaskModelFragment}
`