import React, {Fragment, useEffect, useState} from "react";

import {TabPanel} from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import {Button, Typography} from "@mui/material";
import {IconTextButton} from "../../../../../newShared/components/Basic/CommonButtons";
import colors from "../../../../../newShared/theme/colors";
import {Flex} from "../../../../../newShared/components/Layouts";

import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";

import * as S from "../styled";
import {useExactInformation} from "../../hooks/useExactInformation";
import {ExactVendorLoading} from "../loadings/exactVendorLoading";
import {CreateSettingDialog} from "../dialogs/createSetting";
import {useAddNewSetting} from "../../hooks/useAddNewSetting";
import {optionIsTCollaborator} from "../../../../../newShared/components/AutocompleteCollaborators/types";
import {getName} from "../../../../../newShared/utils/text";
import {AutocompleteCollaborators} from "../../../../../newShared/components/AutocompleteCollaborators";
import {v4 as uuid} from "uuid";

export const ExactVendorInformation = () => {
    const {
        selectedVendor,
        isInformationEdit,
        editModeInformation,
        updateVendor,
        isLoadingUpdate,
        settings,
        isSettingsLoading,
        tmpVendor,
        setTmpVendor,
        isExist,
        isOk
    } = useExactInformation()

    const {t, revDir} = useMainTranslation('',{keyPrefix:'pathVendors.ExactVendor'});
    const {isMobile} = useMedia();

    const {TypeBlock, ServiceProvidedBlock, RiskBlock, field} = useAddNewSetting();

    const [uniqueInputId, setUniqueInputId] = useState<string>(uuid());
    useEffect(() => {
        setUniqueInputId(uuid());
    }, [tmpVendor?.owner?.email]);

    return (
        <TabPanel value={'Information'} sx={{padding: '16px 0 0 0', overflow: isInformationEdit? 'auto' : 'false'}}>

            {selectedVendor ?
                <>
                    {!isInformationEdit && !isLoadingUpdate &&
                        <>
                            <IconTextButton
                                variant={'text'}
                                icon={<EditIcon fontSize="small"/>}
                                onClick={editModeInformation}
                            >{t('Edit information')}
                            </IconTextButton>
                            <S.VendorOverviewSubWrapper>

                                <S.FieldTitleText>{`${t('NameForInfo')}: `}<S.FieldValueText>{selectedVendor?.name}</S.FieldValueText></S.FieldTitleText>

                                <S.FieldTitleText>{`${t('TypeForInfo')}: `} <S.FieldValueText>{selectedVendor?.type}</S.FieldValueText> </S.FieldTitleText>

                                <S.FieldTitleText>{`${t('ServiceProvidedForInfo')}: `} <S.FieldValueText>{selectedVendor?.serviceProvided}</S.FieldValueText></S.FieldTitleText>
                                <S.FieldTitleText>{`${t('Risk LevelForInfo')}: `} <S.FieldValueText>{selectedVendor?.risk || ''}</S.FieldValueText></S.FieldTitleText>

                                <S.FieldTitleText>{`${t('OwnerForInfo')}: `} <S.FieldValueText>{getName(selectedVendor.owner?.firstName || '', selectedVendor.owner?.lastName || '')}</S.FieldValueText></S.FieldTitleText>

                                <S.FieldTitleText>{`${t('LinkForInfo')}: `} <S.FieldValueText>{selectedVendor.link}</S.FieldValueText></S.FieldTitleText>

                                {Object.keys(selectedVendor.customFields).map(e =>
                                    <S.FieldTitleText key={JSON.stringify(e)}>{(`${e}: `)} <S.FieldValueText>{selectedVendor.customFields[e]}</S.FieldValueText></S.FieldTitleText>)}
                            </S.VendorOverviewSubWrapper>
                        </>
                    }
                    {(isInformationEdit || isLoadingUpdate) &&
                        <>
                            <S.VendorOverviewSubWrapper margin = {"10px 0 0 0"}>
                                <Flex ai={'center'}>
                                    <Button
                                        variant={'contained'}
                                        disabled={!isOk || isExist || isLoadingUpdate}
                                        size={isMobile ? 'small' : 'medium'}
                                        onClick={()=>{
                                            updateVendor();
                                            editModeInformation();
                                        }}
                                        sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                    >{t('Save')}</Button>
                                    <Button
                                        variant={'outlined'}
                                        disabled={isLoadingUpdate}
                                        size={isMobile ? 'small' : 'medium'}
                                        onClick={()=>{
                                            editModeInformation();
                                        }}
                                        sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                    >{t('Cancel')}</Button>
                                </Flex>
                            </S.VendorOverviewSubWrapper>
                            <S.CreateVendorInputsWrapper>
                                <Typography variant={"body1"} sx={{margin: '20px 0 0 0', color: colors.text.grey}}>
                                    {t('Basic information')}
                                </Typography>
                                <S.TextFieldCustom
                                    value={tmpVendor?.name || ""}
                                    required size={'small'}
                                    onChange={(e) => {
                                        e.persist();
                                        tmpVendor && setTmpVendor({...tmpVendor, name: e.target.value})
                                    }}
                                    disabled={isLoadingUpdate}
                                    name={'name'}
                                    label={t('Name')}
                                    sx={{margin: '18px 0 0 0', width: isMobile ? '100%' : '90%', color: colors.grayText, height: '40px', maxWidth: '300px'}}
                                    error={isExist}
                                    helperText={isExist ? t('Such vendor already exists.') : ''}/>

                                <S.AutoComplete
                                    sx={{margin: '18px 0 0 0', height: '40px', width: isMobile ? '100%' : '90%'}}
                                    disabled={!isInformationEdit || isLoadingUpdate || isSettingsLoading}
                                    id="combo-box-demo"
                                    options={settings?.type.map(e => e.name)}
                                    value={tmpVendor?.type || null}
                                    disableClearable
                                    onChange={(event, value) => {
                                        tmpVendor && setTmpVendor({...tmpVendor, type: value as string})}}
                                    renderInput={(params) => (
                                        <S.TextFieldCustom {...params} size="small" label={t("Type")} required

                                                           InputProps={{
                                                               ...params.InputProps,
                                                               endAdornment: (
                                                                   <Fragment>
                                                                       {params.InputProps.endAdornment}
                                                                   </Fragment>
                                                               ),
                                                           }}/>
                                    )}
                                    PaperComponent={TypeBlock}
                                />

                                <S.AutoComplete
                                    sx={{margin: '18px 0 0 0', height: '40px', width: isMobile ? '100%' : '90%'}}
                                    disabled={!isInformationEdit || isLoadingUpdate || isSettingsLoading}
                                    options={settings?.serviceProvided.map(e => e.name)}
                                    value={tmpVendor?.serviceProvided || null}
                                    onChange={(event, value) => {
                                        tmpVendor && setTmpVendor({...tmpVendor, serviceProvided: value as string})}}

                                    renderInput={(params) => (
                                        <S.TextFieldCustom {...params} size="small" label={t("Service provided")} required
                                                           InputProps={{
                                                               ...params.InputProps,
                                                               endAdornment: (
                                                                   <Fragment>
                                                                       {params.InputProps.endAdornment}
                                                                   </Fragment>
                                                               ),
                                                           }}/>
                                    )}
                                    PaperComponent={ServiceProvidedBlock}
                                />

                                <S.AutoComplete
                                    sx={{margin: '18px 0 0 0', height: '40px', width: isMobile ? '100%' : '90%'}}
                                    id="combo-box-demo"
                                    disabled={!isInformationEdit || isLoadingUpdate || isSettingsLoading}
                                    options={settings.risk.map(e=>e.name)}
                                    value={tmpVendor?.risk || null}
                                    onChange={(event, value) => {
                                        tmpVendor && setTmpVendor({...tmpVendor, risk: value as string})}}
                                    renderInput={(params) => (
                                        <S.TextFieldCustom {...params} size="small" label={t("Risk")} required
                                                           InputProps={{
                                                               ...params.InputProps,
                                                               endAdornment: (
                                                                   <Fragment >
                                                                       {params.InputProps.endAdornment}
                                                                   </Fragment>
                                                               ),
                                                           }}/>
                                    )}
                                    PaperComponent={RiskBlock}
                                />

                                <AutocompleteCollaborators
                                    key={uniqueInputId}
                                    disabled={!isInformationEdit || isLoadingUpdate}

                                    label={t("Owner")}
                                    getOptionLabelCustom={"name+email"}
                                    getOptionLabel={(option) => optionIsTCollaborator(option) ? getName(option.firstName || '', option.lastName || '') : option}

                                    sx={{
                                        width: `min(${isMobile ? '100%' : '90%'}, 300px)`,

                                        '& .MuiInputBase-input': {
                                            height: '24px',
                                        },
                                        '& label': {
                                            top: '0 !important',
                                        },

                                        margin: '18px 0 0 0', height: '40px'
                                    }}

                                    defaultValue={tmpVendor?.owner || null}
                                    onChange={value => {
                                        if (!value) tmpVendor && setTmpVendor({...tmpVendor, owner: null})
                                        else if (optionIsTCollaborator(value)) {
                                            tmpVendor && setTmpVendor({...tmpVendor, owner: value})
                                        }
                                    }}
                                />

                                <S.TextFieldCustom
                                    value={tmpVendor?.link || ''}
                                    size={'small'}
                                    onChange={(e) => {
                                        e.persist();
                                        tmpVendor && setTmpVendor({...tmpVendor, link: e.target.value.trim()})
                                    }}
                                    disabled={isLoadingUpdate}
                                    name={'link'}
                                    label={t('Link')}
                                    sx={{margin: '18px 0 0 0', width: isMobile ? '100%' : '90%', color: colors.grayText, height: '40px', maxWidth: '300px'}}/>

                                <Typography variant={"body1"} sx={{margin: '20px 0 0 0', color: colors.text.grey}}>
                                    {t('Custom field')}
                                </Typography>
                                {settings?.customFields.length>0 && settings.customFields.map(({name}) =>
                                    <S.TextFieldCustom
                                        key={name}
                                        value={tmpVendor?.customFields[name] || ''}
                                        name={name}
                                        label={name}
                                        disabled={isLoadingUpdate}
                                        sx={{margin: '18px 0 0 0', width: isMobile ? '100%' : '90%', color: colors.grayText, height: '40px', maxWidth: '300px'}}
                                        size={'small'}
                                        onChange={(event) => {
                                            event.persist();
                                            tmpVendor && setTmpVendor({
                                                ...tmpVendor,
                                                customFields: {...tmpVendor.customFields, [name]: event.target.value.trim()}
                                            })
                                        }}/>
                                )}
                            </S.CreateVendorInputsWrapper>
                        </>
                    }
                </>
                :
                <ExactVendorLoading/>
            }

            <CreateSettingDialog onSuccess={(str) => {
                switch (field) {
                    case 'risk': {
                        tmpVendor && setTmpVendor({...tmpVendor, risk: str});
                        break;
                    }
                    case 'type': {
                        tmpVendor && setTmpVendor({...tmpVendor, type: str});
                        break;
                    }
                    case 'serviceProvided': {
                        tmpVendor && setTmpVendor({...tmpVendor, serviceProvided: str});
                        break;
                    }
                }
            }}/>
        </TabPanel>
    )
}
