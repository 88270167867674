import React, {Fragment, useEffect, useState} from "react";

import colors from "../../../../../newShared/theme/colors";
import {IconTextButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {Flex} from "../../../../../newShared/components/Layouts"
import EditIcon from "@mui/icons-material/Edit";
import {TabPanel} from "@mui/lab";
import {Button, Rating, Typography} from "@mui/material";
import * as S from "../styled";
import {averageEvaluation} from "../../helpers"
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useExactEvaluation} from "../../hooks/useExactEvaluation";
import {optionIsTCollaborator} from "../../../../../newShared/components/AutocompleteCollaborators/types";
import {getName} from "../../../../../newShared/utils/text";
import {AutocompleteCollaborators} from "../../../../../newShared/components/AutocompleteCollaborators";
import {uuid} from "../../../../../newShared/utils";

export const ExactVendorEvaluation = () => {

    const {
        isEvaluationEdit,
        editModeEvaluation,
        updateVendor,
        isLoadingUpdate,
        selectedVendor,
        tmpVendor,
        changeEvaluator,
        changePrice,
        changeQuality,
        changeService,
        changeNotesAndRecommendations
    } = useExactEvaluation();

    const {revDir, t} = useMainTranslation('',{keyPrefix:'pathVendors.ExactVendor'});
    const {isMobile} = useMedia();

    const [uniqueInputId, setUniqueInputId] = useState<string>(uuid());
    useEffect(() => {
        setUniqueInputId(uuid())
    }, [isEvaluationEdit]);

    return (
        <TabPanel value={'Evaluation'} sx={{padding: '16px 0 0 0', overflow: isEvaluationEdit? 'auto' : 'false'}}>
            {!isEvaluationEdit && !isLoadingUpdate && selectedVendor &&
                <>
                    <IconTextButton
                        variant={'text'}
                        icon={<EditIcon fontSize="small"/>}
                        onClick={editModeEvaluation}
                    >{t('Edit evaluation')}
                    </IconTextButton>
                    <S.VendorOverviewSubWrapper>
                        <Flex m={'16px 0 0 0'}>
                            <Typography variant={"body1"} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}>
                                {t('EvaluatorForInfo')} </Typography>
                            <Typography variant={"body1"} sx={{color: colors.grayText}}>
                                {selectedVendor.evaluation?.evaluator ? getName(selectedVendor.evaluation?.evaluator.firstName || '', selectedVendor.evaluation?.evaluator.lastName || '') : '-'}</Typography>
                        </Flex>
                    </S.VendorOverviewSubWrapper>
                </>
            }
            {(isEvaluationEdit || isLoadingUpdate) &&
                <S.VendorOverviewSubWrapper margin = {"10px 0 0 0"}>
                    <Flex ai={'center'}>
                        <Button
                            variant={'contained'}
                            disabled={isLoadingUpdate}
                            size={isMobile ? 'small' : 'medium'}
                            onClick={()=>{
                                updateVendor();
                                editModeEvaluation();
                            }}
                            sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                        >{t('Save')}</Button>
                        <Button
                            variant={'outlined'}
                            size={isMobile ? 'small' : 'medium'}
                            disabled={isLoadingUpdate}
                            onClick={editModeEvaluation}
                            sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                        >{t('Cancel')}</Button>
                    </Flex>
                </S.VendorOverviewSubWrapper>
            }
            <S.VendorOverviewSubWrapper>
                {(isLoadingUpdate || isEvaluationEdit) && tmpVendor &&
                    <AutocompleteCollaborators
                        key={uniqueInputId}

                        label={t("Evaluator")}
                        getOptionLabelCustom={"name+email"}
                        getOptionLabel={(option) => optionIsTCollaborator(option) ? getName(option.firstName || '', option.lastName || '') : option}

                        sx={{
                            '& .MuiInputBase-input': {
                                height: '24px',
                            },
                            '& label': {
                                top: '0 !important',
                            },

                            margin: '18px 0 0 0', width: '60%', color: colors.grayText, height: '40px'
                        }}

                        defaultValue={tmpVendor.evaluation?.evaluator || null}
                        onChange={value => {
                            if (optionIsTCollaborator(value)) {
                                changeEvaluator(value)
                            }
                        }}
                    />
                }

                <Flex m={'15px 0 0 0'}>
                    <Typography fontSize={'16px'} fontWeight={400} sx={{margin: !revDir ? '5px 20px 0 0' : '5px 0 0 20px', color: colors.grayText}}>
                        {t('Price')}</Typography>
                    <Rating value={tmpVendor?.evaluation?.price || null}
                            readOnly={isLoadingUpdate || !isEvaluationEdit}
                            onChange={(e,value) => {
                                changePrice(value)
                            }}/>

                </Flex>

                <Flex m={'20px 0 0 0'}>
                    <Typography fontSize={'16px'} fontWeight={400} sx={{margin: !revDir ? '5px 20px 0 0' : '5px 0 0 20px', color: colors.grayText}}>
                        {t('Quality')}</Typography>
                    <Rating
                        value={tmpVendor?.evaluation?.quality || null}
                        readOnly={isLoadingUpdate || !isEvaluationEdit}
                        onChange={(event, value) => {
                            changeQuality (value)
                        }}
                    />
                </Flex>

                <Flex m={'20px 0 0 0'}>
                    <Typography fontSize={'16px'} fontWeight={400} sx={{margin: !revDir ? '5px 20px 0 0' : '5px 0 0 20px', color: colors.grayText}}>
                        {t('Service')}</Typography>
                    <Rating
                        value={tmpVendor?.evaluation?.service || null}
                        readOnly={isLoadingUpdate || !isEvaluationEdit}
                        onChange={(event, value) => {
                            changeService(value)
                        }}
                    />
                </Flex>

                <Flex m={'20px 0 0 0'}>
                    <Typography fontSize={'16px'} fontWeight={400} sx={{margin: !revDir ? '5px 20px 0 0' : '5px 0 0 20px', color: colors.grayText}}>
                        {t('Average')}</Typography>
                    <Rating
                        value={averageEvaluation(tmpVendor?.evaluation || null)}
                        readOnly
                    />
                </Flex>
                {!isEvaluationEdit && !isLoadingUpdate && selectedVendor &&
                    <>
                        <Typography variant={"body1"} sx={{margin: '20px 0 0 0', color: colors.text.grey}}>
                            {t('Notes and recommendations')}
                        </Typography>
                        <Typography variant={"body1"} sx={{margin: '8px 0 0 0', color: colors.grayText
                        }}>{selectedVendor.evaluation?.notesAndRecommendations || ''}</Typography>
                    </>
                }
                {(isEvaluationEdit || isLoadingUpdate) &&
                    <S.TextFieldCustom value={tmpVendor?.evaluation?.notesAndRecommendations || ''}
                                       multiline minRows={1}
                                       onChange={(e) => {
                                           changeNotesAndRecommendations(e.target.value)
                                       }}
                                       disabled={isLoadingUpdate}
                                       name={'notesAndRecommendations'}
                                       label={t('Notes and recommendations')}
                                       sx={{margin: '33px 0 0 0', width: isMobile ? '100%' : '60%', color: colors.grayText}}/>
                }

            </S.VendorOverviewSubWrapper>

        </TabPanel>
    )
}