import {ManagePermissionsDialogProps} from "../../../../types";
import {useDispatch, useSelector} from "react-redux";
import {hideManagePermissionsDialog, workspacesDialogs, workspacesLoadings} from "../../../../store/slice";
import {useForm} from "../../../../../../../newShared/hooks/useForm";
import {useEffect, useState} from "react";

export const useManagePermissionsDialog = ({availablePermissions, selectedPermissions, selectPermission, deselectPermission, roleName, selectAllPermission, deselectAllPermissions}: ManagePermissionsDialogProps) => {
    const dispatch = useDispatch();
    const isOpen = useSelector(workspacesDialogs).managePermission;
    const isLoading = useSelector(workspacesLoadings).getUsers;

    const handleClose = () => {
        dispatch(hideManagePermissionsDialog());
    }

    const onCellClick = (permission: string) => {
        // console.log(`${permission} - onCellClick`);
        const isAlreadySelected = selectedPermissions.some(e => e === permission);
        if(isAlreadySelected){
            deselectPermission(permission);
        }else{
            selectPermission(permission);
        }
    }

    useEffect(() => {
        setForm({search: ''});
        setFilteredPermission(availablePermissions);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        //updating array if page was inited here and waited for fetch
        setForm({search: ''});
        setFilteredPermission(availablePermissions);
        //eslint-disable-next-line
    }, [isLoading]);

    //search
    const [filteredPermission, setFilteredPermission] = useState<string[]>([]);

    const {form, setForm, handleChange} = useForm<{search: string}>({search: ''});

    const handleCleanSearch = () => {
        setForm({search: ''});
        setFilteredPermission(availablePermissions);
    }

    const handleEnterKey = (e: any) => {
        if(e.keyCode === 13 && form.search.trim().length > 0){
            setFilteredPermission(availablePermissions.filter(e => e.trim().toLowerCase().startsWith(form.search.trim().toLowerCase())));
        }
    }

    const counter = filteredPermission.filter(e => selectedPermissions.some(s => s === e)).length;

    // console.log(`counter: ${counter} | isSelectAllCheckboxSelected: counter > 0 -> ${counter > 0}`);
    return{
        availablePermissions: filteredPermission,
        selectedPermissions,
        selectPermission,
        deselectPermission,
        isOpen,
        handleClose,
        roleName,
        onCellClick,
        search: {
            handleChange,
            search: form.search,
            handleEnterKey,
            handleCleanSearch
        },
        selectAllPermission,
        deselectAllPermissions,
        isManagePermissionButtonDisabled: isLoading,
        isSelectAllCheckboxSelected: counter > 0,
        isShowNoResult: form.search.trim().length > 0 && !filteredPermission.length
    }
}