import {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {VicaTasksList} from "./pages/tasks/list";
import {PATH_VICA_DASHBOARD, PATH_VICA_TASKS, PATH_VICA_STEP_EXACT, PATH_VICA_STEP_EXACT_TASK_EXACT} from "./constants";
import {VicaStepExactPage} from "./pages/vicaStepExact";
import {Dashboard} from "./pages/dashboard";

export const Routes: FC = () => {
    return (
        <Switch>
            <Route exact path={PATH_VICA_DASHBOARD} component={Dashboard}/>
            <Route exact path={PATH_VICA_TASKS} component={VicaTasksList}/>
            <Route exact path={[PATH_VICA_STEP_EXACT, PATH_VICA_STEP_EXACT_TASK_EXACT]} component={VicaStepExactPage}/>
            {/*<Route exact path={PATH_CMD_FINDINGS} component={Findings}/>*/}

            <Redirect to={PATH_VICA_TASKS}/>
        </Switch>
    )
}
