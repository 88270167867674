import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {GetDutyRuleByIdAction, runRuleCheckAction} from "../../store/actions";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET,
    PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET_EXACT,
    PATH_LOCAL_ACCESS_MANAGEMENT
} from "../../constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    hideSaveConflictsSuccess,
    hideTerminateRuleCheck,
    openRunRuleCheck,
    openTerminateRuleCheck
} from "../../store/slice";
import {TDutyRule} from "../../types";
import {useSegregationOfDuties} from "../useSegregationOfDuties";
import {useMedia} from "../../../../../newShared/hooks/useMedia";


export const useRuleExact = () => {

    const dispatch = useDispatch();
    const {id} = useParams<{id: string}>();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const {isMobile} = useMedia();

    const {
        selectedRule,
        handleOpenEditRule,
        handleOpenDeleteRule,
        handleChangeIsActive,
        goToConflicts,
        goToRuleset,
        // handleOpenDenyConflict,
        loadings: {exact: isLoadingExact, update: isLoadingUpdate, runRuleCheck: isLoadingRunRuleCheck, updateException: isLoadingUpdateException},
        dialogs: {terminateRuleCheck, saveConflictsSuccessDialog, extendException: {isOpen: isOpenExtendDialog}},
        handleDeselectRule,
        handleOpenExtendException,
        handleOpenDeleteException,
        handleDenyException,
    } = useSegregationOfDuties();

    const controller = new AbortController();

    const onSuccessRunRuleCheck = (rule: TDutyRule,) => {
        dispatch(hideTerminateRuleCheck());
        dispatch(openRunRuleCheck({selectedDutyRule: rule}));
    }
    const handleOpenRunRuleCheck = (rule: TDutyRule) => {
        dispatch(runRuleCheckAction({
            data: {id: rule.id, workspaceId: '', organizationId: ''},
            signal: controller.signal,
            onSuccess: () => onSuccessRunRuleCheck(rule)
        }));
    }

    useEffect(() => {
        if(!isLoadingExact && !selectedRule){
            dispatch(GetDutyRuleByIdAction({data: {workspaceId:'', organizationId: '', id}, signal: controller.signal}));
        }

        return () => {
            controller.abort();
            handleDeselectRule();
        }
        //eslint-disable-next-line
    }, []);


    const handleOpenTerminateDialog = () => {
        dispatch(openTerminateRuleCheck());
    }

    const handleHideTerminateDialog = () => {
        dispatch(hideTerminateRuleCheck());
    }

    const handleCloseSaveConflictsSuccessDialog = (isGoToConflictList: boolean) => {
        dispatch(hideSaveConflictsSuccess());
        isGoToConflictList && goToConflicts();
    }

    //MENU
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useSetBreadcrumbs([
        {
            title: isMobile ? '' : tMenu('Access management'),
            path: PATH_LOCAL_ACCESS_MANAGEMENT
        },
        {
            title: tMenu('SOD ruleset'),
            path: PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET
        },
        {
            title: selectedRule?.name ?? tMenu('Loading...'),
            path: PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET_EXACT.replace(':id', id)
        }
    ]);


    return {
        selectedRule,
        goToRuleset,
        isLoadingExact,
        isLoadingUpdate,
        isLoadingRunRuleCheck,
        isLoadingUpdateException,
        isOpenExtendDialog,
        handleOpenEditRule,
        handleOpenDeleteRule,
        handleChangeIsActive,
        handleOpenRunRuleCheck,
        handleOpenTerminateDialog,
        handleHideTerminateDialog,
        handleOpenExtendException,
        handleOpenDeleteException,
        handleCloseSaveConflictsSuccessDialog,
        isOpenTerminate: terminateRuleCheck,
        isOpenSaveConflictsSuccess: saveConflictsSuccessDialog,
        handleDenyException,

        menu: {
            anchorEl,
            handleClose,
            handleClick,
            open,
        },
    }
}