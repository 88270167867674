import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {loadings} from "../../store/store";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_ASSET_MANAGEMENT} from "../../../../../newShared/constants";
import {
    PATH_LOCAL_ASSET_MANAGEMENT_SAAS,
    PATH_LOCAL_ASSET_MANAGEMENT_SAAS_SETTINGS,
    PATH_LOCAL_ASSET_MANAGEMENT_SAAS_SETTINGS_EXACT,
} from "../../constants";
import {TSaasAssetSettingsId} from "../../types";


export const useSaasAssetSettings = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const history = useHistory();
    const {isLoadingSettings} = useSelector(loadings);

    useSetBreadcrumbs([
        {
            title: tMenu('Asset Management'),
            path: PATH_LOCAL_ASSET_MANAGEMENT
        },
        {
            title: 'SaaS',
            path: PATH_LOCAL_ASSET_MANAGEMENT_SAAS
        },
        {
            title: 'Settings',
            path: PATH_LOCAL_ASSET_MANAGEMENT_SAAS_SETTINGS
        },
    ]);

    const handleGoToSettingById = (id: TSaasAssetSettingsId) => {
        history.push(PATH_LOCAL_ASSET_MANAGEMENT_SAAS_SETTINGS_EXACT.replace(':id', id));
    }

    const handleGoBack = () => {
        history.push(PATH_LOCAL_ASSET_MANAGEMENT_SAAS);
    }

    return {
       handleGoBack,
       isLoadingSettings,
       handleGoToSettingById
    }

}
