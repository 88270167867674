import React, {KeyboardEvent, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    addEvidenceDialog,
    evidencesSelector,
    fetchedEvidences,
    hideAddEvidence,
    isLoading,
    pageInfo
} from "../../../store/builder/slice";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {evidenceInputType, GET_EVIDENCES_REQUEST_TYPES} from "../../../types";
import {AddEvidence, DeattachEvidence, GetEvidencesWithFilter, LinkEvidence} from "../../../store/builder/actions";
import {useScrollPagination} from "../../../../../../newShared/hooks/useScrollPagination";
import {LABEL} from "../../../../../../newShared/constants";


export const useAddEvidence = () => {
    const dispatch = useDispatch();
    // const history = useHistory();

    const {currData} = UseManageWorkspacesAndOrganizations();
    const _isLoading = useSelector(isLoading);
    // const {frameworkId} = useParams<{frameworkId: string}>();
    const _evidences = useSelector(evidencesSelector);
    const _fetchedEvidences = useSelector(fetchedEvidences);
    const _pageInfo = useSelector(pageInfo);

    const [search, setSearch] = useState<string>('');
    const [isExisting, setIsExisting] = useState<boolean>(false);
    const [visibility, setVisibility] = useState<GET_EVIDENCES_REQUEST_TYPES>('ALL');
    const controller = new AbortController();
    const {organizationId} = UseManageWorkspacesAndOrganizations();
    const {isOpen, controlId} = useSelector(addEvidenceDialog);



    const emptyEvidence: evidenceInputType = {
        name: '',
        categories: [],
        description: '',
        id: null,
        origin: false,
        organizationId: currData.selectedOrganization?.id || '',
        visibility: 'PRIVATE',
        controlId: controlId,
        collectorId: null,
    }

    const categoryVariants: GET_EVIDENCES_REQUEST_TYPES[] = ['ALL', 'REGULAIT', 'PRIVATE', 'PUBLIC'];

    const [form, updateForm] = useState<evidenceInputType>(emptyEvidence);


    useEffect(() => {
        updateForm(emptyEvidence)
        //eslint-disable-next-line
    }, [isOpen]);

    //useExisting

    const getEvidence = (page: number, count: number, type: GET_EVIDENCES_REQUEST_TYPES, name: string, clean: boolean) => dispatch(GetEvidencesWithFilter({data:{page, count, name, type}, signal: controller.signal, clean}));
    const handleChangeCategory = (value: string) => {
        setVisibility(value as GET_EVIDENCES_REQUEST_TYPES);
    }

    const handleSetCreateOwnEvidence = () => {
        setIsExisting(false);
        updateForm(emptyEvidence);
    }

    const handleSetUseExisting = () => {
        setIsExisting(true);
        updateForm(emptyEvidence);
        setSearch('');
        setVisibility('ALL');
    }

    useEffect(() => {
        if(isOpen) updateForm(emptyEvidence);
        // eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
    }, [_evidences]);

    useEffect(() => {
        if(isOpen){
            setVisibility('ALL');
            getEvidence(0, 50, 'ALL', '', true);
        }
        if (!isOpen) {
            controller.abort();
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const handleClose = () => {
        dispatch(hideAddEvidence());
        updateForm(emptyEvidence);
    }


    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        updateForm((prevValues) => ({
            ...prevValues,
            [event.target.name]: event.target.value as string,
        }));
    };


    const validateForm = (): boolean => {
        return (
            form.name.trim().length > 0
            // && form.description.trim().length > 0
            // && form.categories.length > 0
            // && form.organizationId!.length > 0
        )
    }


    const handleSave = () => {
        if(validateForm()) {
            dispatch(AddEvidence({evidence: form, isEvidencesPage: true}));
            updateForm(emptyEvidence);
        }
    }


    const handleChangeSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearch(event.target.value as string);
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement> | {keyCode: number}) => {
        if (event.keyCode === 13)  getEvidence(0, 50, visibility, search, true)
    }

    const handleClearSearch = () => {
        setSearch('');
        getEvidence(0, 50, visibility, '', true)
    }

    useEffect(() => {
        if(isOpen){
            setSearch('');
            getEvidence(0, 50, visibility, '', true);
        }
        //eslint-disable-next-line
    }, [visibility]);

    const getTitle = (value: GET_EVIDENCES_REQUEST_TYPES) => {
        switch (value) {
            case ('ALL'): return 'All';
            case ('REGULAIT'): return LABEL;
            case ('PRIVATE'): return 'Private';
            case ('PUBLIC'): return 'Public';
            default:  return '';
        }
    }
    const handleChangeCategories = (event:React.ChangeEvent<HTMLInputElement>) => {
        updateForm((prevValues) => ({
            ...prevValues,
            categories: [event.target.value as string],
        }));
    }

    const handleDeattachEvidence = (e: any, id: string) => {
        e.stopPropagation();

        // const onSuccess = () => history.push(ROOT_BUILDER + `/framework/${frameworkId}/control/${controlId}`)
        controlId && dispatch(DeattachEvidence({controlId, evidenceId: id}));
    }

    const handleAddEvidence = (id: string) => {
        if(controlId && id.length > 0) dispatch(LinkEvidence({controlId, evidenceId: id}));
    }

    // pagination
    const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => {
            getEvidence(
                page, count, visibility, search, false
            )
        },
        arrayLength: _fetchedEvidences.length,
        isLoading: _isLoading,
        pageInfo: _pageInfo,
        cleanData: () => {},
    })


    return {
        isOpen,
        form,
        isExisting,
        setIsExisting,
        handleClose,
        validateForm,
        handleSave,
        category: visibility,
        categoryVariants,
        getTitle,
        handleChangeCategory,
        search,
        handleChangeSearch,
        handleKeyDown,
        handleClearSearch,
        _evidences,
        _fetchedEvidences,
        organizationId,
        handleSetCreateOwnEvidence,
        handleChange,
        handleSetUseExisting,
        isLoading: _isLoading,
        _pageInfo,
        handleAddEvidence,
        handleDeattachEvidence,
        handleChangeCategories,
        isShowingNoEvidences: !isLoading && _fetchedEvidences.length === 0 && !search.length,
        pagination: {
            bottomLoader: bottomLoader && _fetchedEvidences.length < _pageInfo.total,
            onScroll,
            scrollRef
        }
    }


}