import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    initialCreateTemplateForm,
    KYC_ROOT_PATH,
    KYC_TEMPLATES_CREATE_PATH,
    KYC_TEMPLATES_EXACT_PATH,
    KYC_TEMPLATES_LIST_PATH,
    PERSON_TARGET_TYPE,
    SCREENING_MAX_VALUE,
    SCREENING_MIN_VALUE
} from "../../../constants";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {
    createTemplateRequestType,
    TKycTargetType,
    TKycTemplateField,
    TKycTemplateFieldType,
    TKycTemplateScreeningSettingsCheck
} from "../../../types";
import {loadings, openPreviewTemplate, templateConfig} from "../../../store/slice";
import {filterType} from "../../../../../../newShared/components/genericFilter/types";
import {uuid} from "../../../../../../newShared/utils";
import React, {useCallback, useEffect, useState} from "react";
import {getOrderBySystemField, setOrderForFields} from "../../../helpers";
import {isPastDate} from "../../../../../../newShared/utils/dateTools";
import {CreateKycTemplate} from "../../../store/actions";

export const useTemplatesCreate = (isNewTemplate: boolean = false) => {
    const {tMenu, currentLanguage} = useMainTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    //selectors
    const config = useSelector(templateConfig);
    const isLoading = useSelector(loadings).createTemplate

    const handleGoBack = () => history.push(KYC_TEMPLATES_LIST_PATH);

    useSetBreadcrumbs([
        {
            title: tMenu('KYC Management'),
            path: KYC_ROOT_PATH
        },
        {
            title: tMenu('Templates'),
            path: KYC_TEMPLATES_CREATE_PATH
        }
    ]);

    const {form, setForm, handleChange} = useForm<createTemplateRequestType>({
        ...initialCreateTemplateForm,
        fields: config.systemFields
            .filter(e => e.requiredForTemplate && e.templateType === initialCreateTemplateForm.type)
            .map((e) => ({...e, system: true, order: getOrderBySystemField(e, e.templateType === PERSON_TARGET_TYPE)})),
        screeningSettings: {
            ...initialCreateTemplateForm.screeningSettings,
            checks: config.systemChecks.map(e => ({...e, matchingRatio: 30, apply: true}))
        },
        // nextScreeningDate: new Date(Date.now() + DAY_IN_MILLISECONDS).toISOString()
        nextScreeningDate: null
    });

    useEffect(() => {
        setForm({
            ...form,
            type: form.type,
            fields: config.systemFields
                .filter(e => e.requiredForTemplate && e.templateType === form.type)
                .map((e) => ({...e, system: true, order: getOrderBySystemField(e, e.templateType === PERSON_TARGET_TYPE)})),
        });
        //eslint-disable-next-line
    }, [form.type]);

    const [isInitRender, setIsInitRender] = useState<boolean>(!isNewTemplate);
    const [confirmDeleteFieldDialog, setConfirmDeleteFieldDialog] = useState<string | null>(null);

    const handleRemoveField = (id: string) => {
        if (isInitRender && !confirmDeleteFieldDialog) {
            setConfirmDeleteFieldDialog(id);
        } else {
            setForm((prevState) => ({...prevState, fields: prevState.fields.filter(e => e.id !== id)}));
            setConfirmDeleteFieldDialog(null);
            setIsInitRender(false);
        }
    }

    const handleToggleRequired = (id: string) => {
        setForm((prevState) => ({
            ...prevState,
            fields: prevState.fields.map(e => e.id === id ? {...e, required: !e.required} : e)
        }))
    }

    const handleSelectOption = (filter: filterType) => {
        const field = config.systemFields.find(e => e.id === filter.key);
        if (field) {
            setForm((prev) => {
                const newFields:TKycTemplateField[] = [
                    ...prev.fields,
                    {
                        id: field.id,
                        name: field.name,
                        type: field.type as TKycTemplateFieldType,
                        required: field.required,
                        system: true,
                        order: -1,
                        checkAlias: field.checkAlias,
                        requiredForTemplate: field.requiredForTemplate,
                        systemAlias: field.systemAlias,
                        dictionary: field.dictionary
                    }
                ];

                // console.log(`handleSelectOption newFields`, newFields);

                const ordered = setOrderForFields(newFields, config.systemFields.length, form.type === PERSON_TARGET_TYPE);

                return{
                    ...prev,
                    fields: ordered
                }
            })
        }

    }

    const handleDeselectOption = (filter: filterType) => {
        setForm((prev) => ({...prev, fields: prev.fields.filter(e => e.id !== filter.key)}))
    }

    const handleSelectCustomField = (type: TKycTemplateFieldType) => {
        setForm((prev) => {
            const newFields:TKycTemplateField[] = [
                ...prev.fields,
                {
                    id: uuid(),
                    name: '',
                    type,
                    required: true,
                    system: false,
                    order: config.systemFields.length + prev.fields.filter(e => (!e.system)).length,
                    checkAlias: [],
                    requiredForTemplate: false,
                    dictionary: null
                }
            ];

            const ordered = setOrderForFields(newFields, config.systemFields.length, form.type === PERSON_TARGET_TYPE);

            return{
                ...prev,
                fields: ordered
            }
        })
    }

    const handleChangeCustomField = (event: React.ChangeEvent<HTMLInputElement>) => {
        const id = event.currentTarget.name;
        const name = event.currentTarget.value;
        setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === id ? {...e, name} : e)}))
    }

    //DRAG_DROP
    const findField: (id: string) => {field: TKycTemplateField, index: number} | null = useCallback(
        (id: string) => {
            const field = form.fields.find(e => e.id === id);
            return field ? {
                field,
                index: form.fields.indexOf(field)
            } : null;
        },
        [form],
    )

    const moveField: (id: string, atIndex: number) => void = useCallback(
        (id: string, atIndex: number) => {
            const res = findField(id);
            if(res){
                let copyArr = JSON.parse(JSON.stringify(form.fields));
                copyArr.splice(res.index, 1) //remove
                copyArr.splice(atIndex, 0, res.field) //insert
                copyArr = setOrderForFields(copyArr, config.systemFields.length, form.type === PERSON_TARGET_TYPE);
                setForm((prev) => ({...prev, fields: copyArr}));
                // console.log(`moveColumn for ${id} - from ${res?.index} | to ${atIndex} \n new arr: ${JSON.stringify(copyArr)}`);
            }else{
                // console.log(`--Column with key ${id} not found!`);
            }
        },
        [findField, form, setForm, config],
    )

    //SCREENING
    const onSwitchToggle = (check: TKycTemplateScreeningSettingsCheck) => {
        setForm((prev) => (
            {...prev,
                screeningSettings: {
                    ...prev.screeningSettings,
                    checks: prev.screeningSettings.checks.map(e => e.id === check.id ? {...e, apply: !e.apply} : e)
                }
            }
        ));
    }

    const onRatioChange = (check: TKycTemplateScreeningSettingsCheck, ratio: number | number[]) => {
        if(Array.isArray(ratio)) return;
        let _ratio: number = ratio;
        const formCheck = form.screeningSettings.checks.find(e => e.id === check.id);
        if(formCheck){
            setForm((prev) => (
                {...prev,
                    screeningSettings: {
                        ...prev.screeningSettings,
                        checks: prev.screeningSettings.checks.map(e => e.id === check.id ? {...e, matchingRatio: _ratio} : e)
                    }
                }
            ));
        }else{
            setForm((prev) => (
                {...prev,
                    screeningSettings: {
                        ...prev.screeningSettings,
                        checks: [...prev.screeningSettings.checks, {...check, matchingRatio: _ratio}]
                    }
                }
            ));
        }
    }

    const isOk = () => {
        return form.name.trim().length > 0 &&
            (form.screeningSettings.repeat) >= SCREENING_MIN_VALUE &&
            (form.screeningSettings.repeat) <= SCREENING_MAX_VALUE &&
            form.fields.length > 0 &&
            !form.fields.some(e => e.name.trim().length === 0) &&
            !form.fields.some((e, id, arr) => arr.findIndex(as => as.name.trim().toLowerCase() === e.name.trim().toLowerCase()) !== id)
            // form.nextScreeningDate !== null &&
            // !isSameDay(form.nextScreeningDate, new Date().toISOString(), currentLanguage.momentLocale) &&
            // !isPastDate(form.nextScreeningDate, currentLanguage.momentLocale)
    }

    //TYPE DIALOG
    const [selectTypeDialog, setSelectTypeDialog] = useState<boolean>(true);

    const handleSave = () => {
        // console.log(`templpate ${form.fields.sort((a, b) => a.order - b.order).map(e => (`name: ${e.name}, order: ${e.order} \n`))}`);
        dispatch(CreateKycTemplate({template: form, onSuccess: handleGoToCreatedTemplate}));
    }

    const handleGoToCreatedTemplate = (id: string) => {
        history.push(KYC_TEMPLATES_EXACT_PATH.replace(':id', id));
    }

    const handlePreview = () => dispatch(openPreviewTemplate(form));

    const handleChangeNextScreeningDate = (date?: string | null) => {
        if(date !== null && date !== undefined){
            try {
                const parsed = new Date(Date.parse(date)).toISOString();
                const isPast = isPastDate(parsed, currentLanguage.momentLocale);
                if(isPast){
                    setForm((prev) => ({...prev, nextScreeningDate: null}));
                }else{
                    setForm((prev) => ({...prev, nextScreeningDate: parsed}));
                }
            }catch (ex){
                setForm((prev) => ({...prev, nextScreeningDate: null}));
                console.log(`useTemplatesCreate handleChangeNextScreeningDate ex`, ex)
            }
        }else{
            setForm((prev) => ({...prev, nextScreeningDate: null}));
        }
    }

    return {
        actions: {
            handleGoBack,
            handleSave,
            handlePreview,
        },
        isOk: isOk(),
        form,
        handleChange,
        fields: {
            handleSelectOption,
            handleDeselectOption,
            handleRemoveField,
            handleToggleRequired,
            handleSelectCustomField,
            config,
            handleChangeCustomField
        },
        dragNDrop: {
            findField,
            moveField
        },
        screening: {
            handleChange: (value: number) => {
                if(value >= SCREENING_MIN_VALUE && value <= SCREENING_MAX_VALUE){
                    setForm((prev) => ({...prev, screeningSettings: {...prev.screeningSettings, repeat: value}}));
                }
            },
            onRatioChange,
            onSwitchToggle,
            handleChangeNextScreeningDate
        },
        selectTypeDialog: {
            isOpen: selectTypeDialog,
            handleClose: () => setSelectTypeDialog(false),
            handleChooseType: (type:TKycTargetType) => {
                setForm((prev) => ({...prev, type}));
                setSelectTypeDialog(false);
            }
        },
        confirmDeleteFieldDialog: {
            isOpen: confirmDeleteFieldDialog !== null,
            handleClose: () => setConfirmDeleteFieldDialog(null),
            handleConfirm: () => confirmDeleteFieldDialog && handleRemoveField(confirmDeleteFieldDialog),
        },
        setForm,
        isLoading
    }
}