import {Handle, Position} from "react-flow-renderer";
import {ALLOWDRAG, mapper} from "../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {deleteStage, openStageSettings, selectedAutomation} from '../../../store/slice';
import {automationType} from "../../../types";
import React from "react";
import {Box, MenuItem, Popover, Typography} from "@mui/material";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {Typo} from "../../../../../../newShared/components/Typography";

type stageNodeProps = {
    type: string;
    eta: number;
    id: number;
    revDir: boolean
}

export const StageNode = ({data} : {data: stageNodeProps}) => {
    const {revDir} = data;
    // console.log(`${revDir} = STAGE NODE - ${JSON.stringify({left: !revDir ? '20%' : 'initial', right: revDir ? '20%' : 'initial'})}`)

    const dispatch = useDispatch();
    const auto: automationType | null = useSelector(selectedAutomation);

    const {t} = useMainTranslation('', {keyPrefix: 'automations'});

    const translateSeconds = (data: number): string => {
        if(data % mapper.Years === 0  ) return (data / mapper.Years) === 1 ? t('year') : (data / mapper.Years) + ` ${t('repeatEveryType.Years')}`;
        if(data % mapper.Months === 0  ) return (data / mapper.Months) === 1 ? t('month') : (data / mapper.Months) + ` ${t('repeatEveryType.Months')}`;
        if(data % mapper.Weeks === 0  ) return (data / mapper.Weeks) === 1 ? t('week') : (data / mapper.Weeks) + ` ${t('repeatEveryType.Weeks')}`;
        if(data % mapper.Days === 0  ) return  (data / mapper.Days) === 1 ? t('day') : (data / mapper.Days) + ` ${t('repeatEveryType.Days')}`;
        if(data % mapper.Hours === 0  ) return  (data / mapper.Hours) === 1 ? t('hour') : (data / mapper.Hours) + ` ${t('repeatEveryType.Hours')}`;
        return data + ' seconds';
    }

    const deleteSelf = () => {
        if(auto && auto.stages.length > 1){
            dispatch(deleteStage({stageId: data.id}));
            handlePopoverClose();
        }
    };

    const openEditDialog = () => {
        dispatch(openStageSettings({stageId: data.id}));
        handlePopoverClose();
    }

    const [anchorEl, setAnchorEl] = React.useState<{id: string | null, anchorEl: HTMLButtonElement | null}>({id: null, anchorEl: null});


    const handlePopoverOpen = (event: React.MouseEvent<SVGSVGElement>, id: string) => {
        setAnchorEl({
            id: id,
            anchorEl: event.target as HTMLButtonElement,
        });
    }

    const handlePopoverClose = () => {
        setAnchorEl({id: null, anchorEl: null});
    }

    return(
        <Flex
            minw={'140px'}
            h={'60px'}
            br={'11px'}
            background={'#E8E8E8'}
            // ai={'center'}
            // jc={'center'}
            direction={'column'}
            p={'0 10px'}
            className={ALLOWDRAG ? 'nodrag': ''}
            cursor={'pointer'}
        >
            <Handle
                type="target"
                position={!revDir ? Position.Left : Position.Right}
                id="doc-left-target"
                isConnectable={false}
                style={{opacity: '0'}}
            />

            {data.type === 'read' &&
            <Typo
                style={{position: 'relative', bottom: '30px', left: '0'}}
                fontWeight={400}
                fontSize={'14px'}
                align={'center'}
            >{t('Reader Confirmation')}</Typo>}

            {data.type === 'review' &&
            <Typo
                style={{position: 'relative', bottom: '40px', left: '0'}}
                fontWeight={400}
                fontSize={'14px'}
                align={'center'}
            >{t('Review')}</Typo>}

            {data.type === 'sign' &&
            <Typo
                style={{position: 'relative', bottom: '40px', left: '0'}}
                fontWeight={400}
                fontSize={'14px'}
                align={'center'}
            >{t('Sign Confirmation')}</Typo>}

            {/*{(auto && auto.stages.length > 1) &&*/}
            {/*<Flex style={{position: 'absolute', bottom: '40px', left: '120px'}} className={'nodrag'}>*/}
            {/*    <DeleteOutlinedIcon*/}
            {/*        style={{width: '18px', height: '18px', color: '#5D5D5D', cursor: 'pointer'}}*/}
            {/*        onClick={deleteSelf}*/}
            {/*    />*/}
            {/*</Flex>}*/}

            <Flex jc={'space-between'}  w={'95%'} ai={'center'}  style={{position: 'relative', bottom: '25px', left: '10px'}}>
                <Box>
                    <Typo fontWeight={400} fontSize={'14px'} sx={{lineHeight: 'normal'}}>{t(`stageTypes.${data.type}`)}</Typo>
                    <Typo fontWeight={400} fontSize={'10px'} margin={'5px 0 0 0'} sx={{lineHeight: 'normal'}}>{t('ETA')}: {translateSeconds(data.eta)}</Typo>
                </Box>
                <MoreVertOutlinedIcon
                    style={{color: '#FAC000'}}
                    onClick={(event) => handlePopoverOpen(event, data.id + '')}
                />
            </Flex>

            <Popover
                open={anchorEl.id === null ? false : anchorEl.id === data.id + ''}
                // open={false}
                anchorEl={anchorEl.anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={openEditDialog}>
                    <Typography>{t('Edit')}</Typography>
                </MenuItem>

                <MenuItem onClick={deleteSelf} disabled={!(auto && auto.stages.length > 1)}>
                    <Typography>{t('Delete')}</Typography>
                </MenuItem>

                {/*<Flex direction="column" w="90px" h="100px">*/}
                {/*    <Flex direction="column" p="16px 16px 0px 16px" >*/}
                {/*        <Box onClick={openEditDialog}>*/}
                {/*            <Typo fontSize="14px" fontWeight={400} letterSpacing="0.4px" margin="0 0 20px 0" cursor="pointer">{t('Edit')}</Typo>*/}
                {/*        </Box>*/}
                {/*        <Box onClick={deleteSelf}>*/}
                {/*            <Typo fontSize="14px" fontWeight={400} letterSpacing="0.4px" margin="0 0 20px 0" cursor={"pointer"} color={(auto && auto.stages.length > 1) ? 'black' : 'gray'}>{t('Delete')}</Typo>*/}
                {/*        </Box>*/}
                {/*    </Flex>*/}
                {/*</Flex>*/}
            </Popover>


            <Handle
                type="source"
                position={!revDir ? Position.Right : Position.Left}
                id="doc-right-source"
                style={{backgroundColor: 'inherit'}}
                isConnectable={false}
            />

            <Handle
                type="source"
                position={Position.Bottom}
                id={`stage-${data.id}-bottom`}
                style={{ left: !revDir ? '20%' : 'initial', right: revDir ? '20%' : 'initial', borderRadius: '50%' }}
                isConnectable={false}
            />
        </Flex>
    )
}