import {gql} from "@apollo/react-hooks";

export const mainVicaCategoryStepFragment = gql`
    fragment mainVicaCategoryStepFragment on MainVicaCategoryStepModel {
        id
        name
        description
        instruction
        evidenceId
        orderInCategory
        actions {
            title
            data
            type
        }
        frameworks {
            id
            logo
            name
            controls {
                id
                name
                evidenceName
            }
        }
        responsible {
            collaboratorId
            fullName
            email
        }
        status
        delegatedTasks {
            created
            performed
            approved
            declined
        }
    }
`

export const mainVicaDelegatedTaskModelFragment = gql`
    fragment mainVicaDelegatedTaskModelFragment on MainVicaDelegatedTaskModel {
        id
        workspaceId
        title
        description
        requestor{
            collaboratorId
            email
            fullName
        }
        createdDate
        requestorComment
        resolver{
            collaboratorId
            email
            fullName
        }
        resolveDate
        resolverComment
        performer{
            collaboratorId
            email
            fullName
        }
        performDate
        performerComment
        status
        type
        source
        vicaStepId
        frameworkId
        controlId
        evidenceId
        data {
            attachedFiles {
                fileName
                fileId
                addedToEvidence
            }
        }

    }
`

export const evidenceAutoFile = gql`
    fragment evidenceAutoFile on EvidenceAutoFileDto {
        id
        name
        lastCollected
        data
        type
    }
`
