import {print} from "graphql";
import {gql} from "@apollo/react-hooks";


export const ShortNewDocumentFragment = print(gql`
    fragment ShortNewDocumentFragment on ShortDocumentModel {
        id
        documentId
        type
        name
        folderId
        status
        origin
        version
        latest
        createdDate
        lastModified
        tags
        generatedFileId
        currentStepEndDate
        deletedDate
        recipients {
            eta
            role
            fields {
                id
                pageId
                type
                position {
                    x
                    y
                    metaPageHeight
                }
                size {
                    width
                    height
                }
                isRequired
            }
            order
            message
            actor {
                email
                firstName
                lastName
            }
        }
    }
`)

export const NewDocumentFragment = print(gql`
    fragment NewDocumentFragment on NewDocumentModel {
        id
        documentId
        workspaceId
        type
        name
        folderId
        status
        origin
        version
        latest
        createdDate
        tags
        lastModified
        generatedFileId
        currentStepEndDate
        recipients{
            eta
            role
            message
            order
            actor{
                email
                firstName
                lastName
            }
            fields{
                id
                pageId
                type
                position{
                    x
                    y
                    metaPageHeight
                }
                size{
                    width
                    height
                }
                isRequired
            }
        }
        visible
        creatorId
        deleted
        deletedDate
        accessRules {
            rule
        }
        editors {
            email
            firstName
            lastName
        }
        approvers {
            id
            order
            eta
            groups {
                id
                name
                isRequired
                message
                actors {
                    email
                    firstName
                    lastName
                }
            }
        }
        updater {
            email
            firstName
            lastName
        }
        data {
            innerHtml
            variables {
                id
                type
                title
                value
                order
                description
            }
            editor
            pdfFileId
        }
    }
`)


export const NewDocumentExecutorFragment = print(gql`
    fragment NewDocumentExecutorFragment on DocumentExecutorModel {
        id
        workspaceId
        documentId
        documentVersionId
        sender
        startDate
        status
        currentStepId
        approveSteps{
            id
            startDate
            endDate
            eta
            groups{
                id
                performedByEmail
                status
                isRequired
                actors{
                    email
                    firstName
                    lastName
                    actions{
                        type
                        date
                    }
                }
            }
            recipients{
                email
                firstName
                lastName
                status
                actions{
                    type
                    date
                    data{
                        fieldId
                        value
                    }
                }
            }
        }
        recipientSteps{
            id
            startDate
            endDate
            eta
            groups{
                id
                performedByEmail
                status
                isRequired
                actors{
                    email
                    firstName
                    lastName
                    actions{
                        type
                        date
                    }
                }
            }
            recipients{
                email
                firstName
                lastName
                status
                actions{
                    type
                    date
                    data{
                        fieldId
                        value
                    }
                }
            }
        }
    }
`)

