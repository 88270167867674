import React, {FC} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    FrameworksDelegatedTaskStatus,
    MainVicaDelegatedTaskModel, MainVicaStepStatus
} from "../../../../../newShared/GQLTypes";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import colors from "../../../../../newShared/theme/colors";
import {Flex} from "../../../../../newShared/components/Layouts";
import {useVicaStepExactPage} from "../../hooks/useVicaStepExactPage";
import {DelegatedTaskStatus} from "../../components/delegatedTaskStatus";
import {PageWrapper} from "../../../../../newShared/components/style";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {Box, Button, Menu, MenuItem, Skeleton, Typography} from "@mui/material";
import {LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {vicaStepStatusTitleMapper} from "../../constants";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ViewComfyOutlinedIcon from '@mui/icons-material/ViewComfyOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import {useStepDescriptionDialog} from "../../hooks/dialogs/useStepDescriptionDialog";
import {AvatarGroup} from "../../../../../newShared/components/AvatarGroup";
import {SelectTaskCollabsDialog} from "../../components/dialogs/selectTaskCollabsDialog";
import {VicaExactDelegatedTaskDialog} from "../../components/exactDelegatedTask";
import AddIcon from "@mui/icons-material/Add";
import {TaskActionUploadDialog} from "../../components/dialogs/taskActionUploadDialog";
import {TaskActionTemplateNewDialog} from "../../components/dialogs/taskActionTemplateNewDialog";
import {CreateDelegatedTaskDialog} from "../../components/dialogs/createDelegatedTaskDialog";
import {VicaCancelDelegatedTask} from "../../components/dialogs/cancelDelegatedTaskDialog";
import {useVicaCancelDelegatedTask} from "../../hooks/dialogs/useCancelDelegatedTask";
import {VicaStepDescriptionViewDialog} from "../../components/dialogs/vicaStepDescriptionViewDialog";
import {TaskFrameworksDialog} from "../../components/dialogs/taskFrameworksDialog";
import {getVicaStepStatusColor} from "../../helpers";
import EditorDialog from "../../../../../newShared/components/editorUnion";

export const VicaStepExactPage: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathVica.DelegatedTasks'});
    const { isMobile } = useMedia();

    const {
        selectedStep,
        tasks,
        handleOpenTask,
        handleGoBack,
        genericTable,
        actions,
        changeStatus,
        refreshTable,
        openAssignStepResponsibleDialog,
        handleOpenCreateDelegatedTaskDialog,
        handleOpenFrameworksDialog,
        doc: {selectedNewDocument, currentEmail, snack, loadings, docActions},
    } = useVicaStepExactPage();
    const {handleOpen: handleOpenDescription} = useStepDescriptionDialog();
    const {openCancelDelegatedTask} = useVicaCancelDelegatedTask();


    return (
        <PageWrapper>
            <PageTitleAndActions title={selectedStep?.name ?? <Skeleton width={'250px'} />}
                                 handleGoBack={handleGoBack}
                                 actions={[]} margin={'0 0 8px 0'} />

            <Box display={"flex"} alignItems={"center"} gap={'16px'} flexWrap={'wrap'} >
                <Button startIcon={<DescriptionOutlinedIcon />} disabled={!selectedStep} variant={'text'} size={"small"} sx={{color: colors.primary.blue}} onClick={() => selectedStep && handleOpenDescription(selectedStep)}>
                    {t('Description')}
                </Button>
                <Button startIcon={<ViewComfyOutlinedIcon />} disabled={!selectedStep} variant={'text'} size={"small"} sx={{color: colors.primary.blue}} onClick={handleOpenFrameworksDialog}>{t('Frameworks')}</Button>
                <>
                    <Button startIcon={<StickyNote2OutlinedIcon />} disabled={!selectedStep} variant={'text'} size={"small"} sx={{color: colors.primary.blue}} onClick={(e) => actions.handleOpen(e)}>{t('Actions')}</Button>
                    <Menu
                        id="long-menu"
                        MenuListProps={{'aria-labelledby': 'long-button',}}
                        anchorEl={actions.actionsAnchorEl}
                        open={actions.open}
                        onClose={actions.handleClose}
                        PaperProps={{
                            style: {
                                boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)',
                                borderRadius: '8px',
                                border: '1px solid #F2F4F7'
                            },
                        }}
                        sx={{
                            '& .MuiMenu-list': {
                                padding: '16px'
                            },
                            '& .MuiMenuItem-root': {
                                margin: '0 auto',
                                borderRadius: '4px',
                                '&:hover': {
                                    backgroundColor: '#F2F4F7'
                                }
                            }
                        }}
                    >
                        {selectedStep && selectedStep.actions.map(action => {
                            return(
                                <MenuItem key={action.title} onClick={() => actions.handleOpenActionDialog(action)}>
                                    <Typography variant={'body1'}>{action.title}</Typography>
                                </MenuItem>
                            )
                        })}
                    </Menu>
                </>



                <Box display={'flex'} alignItems={'center'} gap={'16px'}>
                    <Typography variant={"*bodyText2"}>{`${t("Collaborators")}: `}</Typography>
                    {selectedStep?.responsible && <AvatarGroup assignments={selectedStep.responsible.map(e => ({id: e.collaboratorId, name: e.fullName}))}
                                                               maxItems={5}
                                                               disabled={!selectedStep}
                                                               handleAddButton={openAssignStepResponsibleDialog} />
                    }
                </Box>

                <Typography variant={"*bodyText2"}>{`${t('Status')}: `}</Typography>

                <LoadingButton variant={"outlined"} onClick={changeStatus.handleClickStatus} loading={changeStatus?.isLoading} size={'small'}
                               endIcon={Boolean(changeStatus.anchorEl) ? <ExpandLessIcon /> :  <ExpandMoreIcon />} disabled={!selectedStep}
                               sx={{ gap: '10px', whiteSpace: 'nowrap', border: `1px solid ${getVicaStepStatusColor(selectedStep?.status)}`, color: getVicaStepStatusColor(selectedStep?.status)}}>
                    {selectedStep && vicaStepStatusTitleMapper[selectedStep.status]}
                </LoadingButton>

                <Menu open={Boolean(changeStatus.anchorEl)} anchorEl={changeStatus.anchorEl} onClose={() => changeStatus.setAnchorEl(null)}>
                    {Object.values(MainVicaStepStatus).map(status => (
                        <MenuItem key={status} onClick={() => {changeStatus.handleChangeStatus(status)}}
                                  sx={{minWidth: changeStatus.anchorEl?.offsetWidth}}>
                            <Typography variant={'*bodyText2'} sx={{color: getVicaStepStatusColor(status)}}>{vicaStepStatusTitleMapper[status]}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </Box>


            <Box display={'flex'} alignItems={'center'} marginTop={'6px'} gap={'26px'}>
                <Typography variant={'*subtitle1'} sx={{fontWeight: 400}}>{t('Delegated tasks')}</Typography>
                <Button startIcon={<AddIcon />} disabled={!selectedStep} variant={'text'} size={'small'} onClick={handleOpenCreateDelegatedTaskDialog}>{t('Create delegated task')}</Button>
            </Box>


            <GenericTable<MainVicaDelegatedTaskModel>
                id={"vicaDelegatedTasks"}
                columnsConfig={{
                    totalName: "Total tasks",
                    disableColumnsSettings: true,
                    forceShowCardsWhen: isMobile,
                }}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: tasks,
                    getRowUniqueId: (row) => row.id,
                    onRowClick: handleOpenTask,
                    customCellPaddings: '8px',
                    rowActionsConfig: [
                        {
                            title: t('Cancel task'),
                            onClick: (row) => {openCancelDelegatedTask(row)},
                            icon: <CancelScheduleSendIcon fontSize={'small'} style={{color: colors.primary.blue}}/>,
                            dontShow: (row) => row.status !== FrameworksDelegatedTaskStatus.Created
                        },
                    ],

                    columnsConfigs: [
                        {key: "id", name: t('Task id'), default: true, },
                        {key: "requestor", name: t('Requestor'), default: true, valueGetter: (row) => row.requestor.fullName },
                        {key: 'createdDate', name: t('Created date'), default: true, valueGetter: (row) => parseDateAuto(row.createdDate ?? '', undefined, undefined, undefined, '-')},
                        {key: "performer", name: t('Performer'), default: true, valueGetter: (row) => row.performer.fullName },
                        {key: 'performDate', name: t('Performed date'), default: true, valueGetter: (row) => parseDateAuto(row.performDate ?? '', undefined, undefined, undefined, '-')},
                        {key: "resolver", name: t('Resolver'), default: true, valueGetter: (row) => row.resolver?.fullName ?? '-' },
                        {key: 'resolveDate', name: t('Resolved date'), default: true, valueGetter: (row) => parseDateAuto(row.resolveDate ?? '', undefined, undefined, undefined, '-')},
                        {key: 'status', name: t('Status'), default: true, valueRenderer: (row) => <Flex>{DelegatedTaskStatus({status: row.status})}</Flex>},
                    ],
                }}
                filtersConfig={{
                    searchCustomFilterKeyName: "taskIdLike",
                    dontSendSearchIfEmpty: true,
                    genericFilterProps: {
                        configs: genericTable.filters.configs,
                        fetchResultWithSelectedFilters: () => null,
                        collapsable: false,
                    },
                }}
                emptyArrayImageProps={{type: "any", filterNotApplied: {text: t("You do not have any delegated tasks yet"), secondaryText: ""}}}
            />

            <VicaExactDelegatedTaskDialog refreshTable={refreshTable}/>
            <SelectTaskCollabsDialog />
            <TaskActionUploadDialog />
            <VicaStepDescriptionViewDialog />
            <TaskFrameworksDialog/>
            <TaskActionTemplateNewDialog />
            <CreateDelegatedTaskDialog />
            <VicaCancelDelegatedTask />
            {selectedNewDocument?.document?.status && (
                <EditorDialog isOpen={true}
                              documentTitle={selectedNewDocument.document.name}
                              content={selectedNewDocument.document.data?.editor || []}
                              variables={selectedNewDocument.document.data?.variables || []}
                              actors={{
                                  approvers: selectedNewDocument.document.approvers || [],
                                  recipients: selectedNewDocument.document.recipients || [],
                                  editors: selectedNewDocument.document.editors || [],
                              }}
                              pdfFileId={selectedNewDocument.document.data?.pdfFileId || null}

                              currentEmail={currentEmail}
                              currentFileStatus={selectedNewDocument.document.status}
                              execution={selectedNewDocument?.documentExecutor || undefined}

                              onSave={docActions.handleSave}
                              onSend={docActions.handleSend}
                              onPreviewBeforeSend={docActions.handlePreviewBeforeSend}
                              onInvalidate={docActions.handleInvalidate}
                              onSkipApprover={docActions.handleSkip}
                              onClose={docActions.handleCloseEditor}
                              onReturnToDraft={docActions.handleReturnToDraft}
                              onRejectSignStage={() => null}
                              onRejectApproveStage={() => null}
                              onApproveApproveStage={() => null}
                              onApproveSignStage={() => null}
                              onFillFields={() => null}
                              onChangeEta={() => null}

                              onResendInviteApprover={docActions.onResendInviteApprover}
                              onResendInviteRecipient={docActions.onResendInviteRecipient}
                              isLoading={{
                                  isPrev: false,
                                  isSave: loadings.isLoadingSaveDoc,
                                  isInvalidating: loadings.isLoadingInvalidate,
                                  isRejecting: false,
                                  isApproving: false,
                                  isReinviting: loadings.isLoadingRestartSignStageByStageIdAndGroupId || loadings.isLoadingRestartSignStageByStageIdAndRecipient,
                                  isSkipping: loadings.isLoadingSendToNextStep,
                                  isFillingField: false,
                                  isSending: loadings.isLoadingExact,
                                  isChangingEtaInProgress: false,
                              }}
                              snack={snack}

                              actualEnvironment={"isMain"}
                />
            )}
        </PageWrapper>
    )
}
