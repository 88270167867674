import {styled as styledMui} from "@mui/material/styles";
import {Box, IconButton} from "@mui/material";
import styled from "styled-components";
import colors from "../../../../../newShared/theme/colors";


export const StatusBox = styledMui(Box, {
    shouldForwardProp: (propName) => (
        !["bgColor"].includes(propName.toString())
    )})(({bgColor, marginTop}:{bgColor: string, marginTop?: string}) => ({
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6px 14px',
    borderRadius: '12px',
    backgroundColor: bgColor,
    marginTop,
}));

export const StatusTitle = styled('span')(({color}:{color: string}) => ({
    fontSize: '14px',
    fontWeight: 500,
    color,
}));

export const TrainingSectionBox = styledMui(Box, {
    shouldForwardProp: (propName) => (
        !["revDir"].includes(propName.toString())
    )})(({revDir}: {revDir: boolean}) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: revDir ? '16px 16px 16px 36px' : '16px 36px 16px 16px',
    gap: '20px',
    border: `1px solid ${colors.stroke.table}`,
    borderRadius: '6px',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'
}));

export const TrainingSectionContainer = styledMui(Box)(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
    border: `1px solid ${colors.stroke.table}`,
    borderRadius: '6px',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    gap: '12px'
}));


export const SaveChangeNameButton = styledMui(IconButton)(() => ({
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '3px',
    border: `0.818px solid ${colors.stroke.table}`,
    pointerEvents: 'auto',

    // boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
    // boxShadow: '3px 3px 5px 1px rgba(0,0,0,0.34)',
    boxShadow: '0px 4px 12px 0 rgba(60, 72, 97, 0.40)',

    backgroundColor: colors.backgrounds.white,
    '&:hover': {
        backgroundColor: colors.backgrounds.grey_light,
    },
}));

export const ExactPageContainer = styledMui(Box)(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    border: `1px solid ${colors.stroke.table}`,
    borderRadius :'8px',
    marginTop: '20px',
    overflow: 'hidden'
}));

export const ExactPageLeftContainer = styledMui(Box, {
    shouldForwardProp: (propName) => (
        !["isLessThen1050"].includes(propName.toString())
    )})(({isLessThen1050}: {isLessThen1050: boolean }) => ({
    width: isLessThen1050 ? '100%' : '427px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '12px',
    overflow: 'auto',
    flexShrink: 0,
}));

export const LevelAutocompleteBox = styledMui(Box, {
    shouldForwardProp: (propName) => (
        !["bgColor"].includes(propName.toString())
    )})(({bgColor, marginTop}:{bgColor: string, marginTop?: string}) => ({
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px 8px',
    borderRadius: '12px',
    backgroundColor: bgColor,
    marginTop,
}));

export const LevelAutocompleteTitle = styled('span')(({color}:{color: string}) => ({
    fontSize: '10px',
    fontWeight: 500,
    color,
}));


////
export const AssignmentExactHeader = styledMui(Box)(() => ({
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '20px',
    flexWrap: 'wrap',
    borderRadius: '8px',
    border: `1px solid ${colors.stroke.table}`,
    marginTop: '20px',
    gap: '20px'
}));

export const AssignmentExactHeaderElement = styledMui(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '6px'
}));

export const AssignmentExactContent = styledMui(Box)(() => ({
    width: '100%',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: '24px',
    gap: '16px',
    overflow: 'auto'
}));

export const AssignmentTrainingCard = styledMui(Box, {
    shouldForwardProp: (propName) => (
        !["isLessThen1050"].includes(propName.toString())
    )})(({isLessThen1050}: {isLessThen1050: boolean}) => ({
    width: isLessThen1050 ? '100%' : '49%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    border: `1px solid ${colors.stroke.table}`,
    padding: '16px',
    gap: '16px'
}))