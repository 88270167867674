import {CreateOrgStep, planTableRow} from "./types";

export const SETTING_ROOT_PATH = '/settings';

//ORGANIZATIONS
export const SETTINGS_ORGANIZATIONS_PATH = `${SETTING_ROOT_PATH}/organizations`;
export const SETTINGS_ORGANIZATIONS_CREATE_PATH = `${SETTINGS_ORGANIZATIONS_PATH}/new`;
export const SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH = `${SETTINGS_ORGANIZATIONS_PATH}/exact/:orgId`;
export const SETTINGS_ORGANIZATIONS_EXACT_ORG_USERS_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH}/users`;
// export const SETTINGS_ORGANIZATIONS_EXACT_ORG_ROLES_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH}/roles`;
export const SETTINGS_ORGANIZATIONS_EXACT_ORG_PLANS_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH}/plans`;
export const SETTINGS_ORGANIZATIONS_EXACT_ORG_NOTIFICATIONS_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH}/notifications`;
export const SETTINGS_ORGANIZATIONS_EXACT_ORG_BILLING_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH}/billing`;
export const SETTINGS_ORGANIZATIONS_EXACT_ORG_BILLING_HISTORY_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH}/billingHistory`;
export const SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH}/workspaces`;

export const SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH}/workspaces/exact/:wsId`;

export const SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_USERS_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_PATH}/collaborators`;
export const SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_PATH}/roles`;

export const SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_EDIT_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_PATH}/roles/edit/:roleId`;
export const SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_CREATE_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_PATH}/roles/create`;

export const SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_NOTIFICATIONS_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_PATH}/notifications`;

export const ACCEPT_INVITATION = '/invitation';
export const ACCEPT_INVITATION_PROTECTED = '/protected_invitation';
export const ACCEPT_INVITATION_UNPROTECTED = '/unprotected_invitation';

//INVITES
export const SETTINGS_INVITES_PATH = `${SETTING_ROOT_PATH}/invites`;
//TODO add invite selector logic

//PROFILE
export const SETTINGS_PROFILE_PATH = `${SETTING_ROOT_PATH}/profile`;

//LANGUAGE
export const SETTINGS_LANGUAGE_PATH = `${SETTING_ROOT_PATH}/language`;

//SECURITY
export const SETTINGS_SECURITY_PATH = `${SETTING_ROOT_PATH}/security`;

//SELECTOR
export const SETTINGS_SELECTOR_PATH = `${SETTING_ROOT_PATH}/selector/:orgId/:wsId`;

//OTHER
export const DEFAULT_ORGANIZATIONS_PAGING = 50;
export const DEFAULT_WORKSPACES_PAGING = 50;

export const SCROLL_FRINGE_TO_HIDE_ORG_OVERVIEW_WORKSPACES = 50;
export const SCROLL_FRINGE_TO_SHOW_ORG_OVERVIEW_WORKSPACES = 30;

export const SCROLL_FRINGE_TO_HIDE_ORG_OVERVIEW_USERS = 50;
export const SCROLL_FRINGE_TO_SHOW_ORG_OVERVIEW_USERS = 30;

export const SCROLL_FRINGE_TO_HIDE_ORG_OVERVIEW_PLANS = 50;
export const SCROLL_FRINGE_TO_SHOW_ORG_OVERVIEW_PLANS = 30;

export const SCROLL_FRINGE_TO_HIDE_WS_OVERVIEW_USERS = 50;
export const SCROLL_FRINGE_TO_SHOW_WS_OVERVIEW_USERS = 30;

export const SCROLL_FRINGE_TO_HIDE_WS_OVERVIEW_ROLES = 50;
export const SCROLL_FRINGE_TO_SHOW_WS_OVERVIEW_ROLES = 30;

//PLANS
export const BUSINESS_PLAN = 'SUBSCRIPTION_BUSINESS';
export const BUSINESS_PLUS_PLAN = 'SUBSCRIPTION_BUSINESS_PLUS';
export const ENTERPRISE_PLAN = 'SUBSCRIPTION_ENTERPRISE';
export const FREE_PLAN = 'SUBSCRIPTION_FREE';

export const DATE_FORMAT = 'D/M/YYYY';

//
export const ORGANIZATION_MEMBER = 'member';
export const ORGANIZATION_ADMIN = 'admin';
export const ORGANIZATION_OWNER = 'owner';
export const INVITE_USER_ROLES = [ORGANIZATION_MEMBER, ORGANIZATION_ADMIN];

export const DEFAULT_LOGO_URL = 'https://firebasestorage.googleapis.com/v0/b/regulait-dev.appspot.com/o/logos%2Fdefault_logos%2Forganization_default_logo.svg?alt=media&token=f716d380-c5a3-413d-a676-870deace147e';

export const ROLE_WORKSPACE_VIEWER = 'ROLE_WORKSPACE_VIEWER';
export const ROLE_ORG_ADMIN = 'admin';

// export const PATHS_AVAILABLE_ONLY_FOR_ENTERPRISE = [
//     //builder
// ];
// export const PATHS_AVAILABLE_ONLY_FOR_BUSINESS_PLUS = [
//     //analysis
// ];
// export const PATHS_AVAILABLE_ONLY_FOR_BUSINESS = [
//     //automation
// ];

// for free blocked paths: builder/analysis/collectors/automations/integrations


export const SAVE_WS_PREFIX = 'wsSelection#';
// export const SAVE_PATH_PREFIX = 'lastPath#';
export const SAVE_NEXT_PATH_PREFIX = 'nextPath#';
export const SAVE_TRANSACTION_DATA_PREFIX = 'transactionData#';

export const ROLE_WS_OWNER = 'ROLE_WORKSPACE_OWNER';
export const ROLE_WS_ADMIN = 'ROLE_WORKSPACE_ADMIN';
export const ROLE_PORTAL_MEMBER = 'ROLE_PORTAL_MEMBER';
export const ROLE_WS_MEMBER = 'ROLE_WORKSPACE_MEMBER';

//PLAN SELECTION
export const planBenefits: planTableRow[] = [
    // {
    //     rowName: 'Organization size',
    //     free: {
    //         type: 'icon',
    //         value: 'infinity'
    //     },
    //     business: {
    //         type: 'icon',
    //         value: 'infinity'
    //     },
    //     enterprise: {
    //         type: 'icon',
    //         value: 'infinity'
    //     }
    // },
    {
        rowName: 'Frameworks',
        // free: {
        //     type: 'icon',
        //     value: 'infinity'
        // },
        business: {
            type: 'icon',
            value: 'infinity'
        },
        enterprise: {
            type: 'icon',
            value: 'infinity'
        }
    },
    {
        rowName: 'Evidence management',
        // free: {
        //     type: 'text',
        //     value: 'infinity'
        // },
        business: {
            type: 'text',
            value: 'Automated'
        },
        enterprise: {
            type: 'text',
            value: 'Automated'
        }
    },
    {
        rowName: 'Reporting',
        // free: {
        //     type: 'icon',
        //     value: 'infinity'
        // },
        business: {
            type: 'icon',
            value: 'plus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },
    {
        rowName: 'Audit log',
        // free: {
        //     type: 'icon',
        //     value: 'infinity'
        // },
        business: {
            type: 'icon',
            value: 'plus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },
    {
        rowName: 'Integrations',
        // free: {
        //     type: 'text',
        //     value: 'Single'
        // },
        business: {
            type: 'icon',
            value: 'plus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },
    {
        rowName: 'Document lifecycle automation',
        // free: {
        //     type: 'text',
        //     value: 'Single'
        // },
        business: {
            type: 'icon',
            value: 'plus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },
    {
        rowName: 'Compliance management tool',
        business: {
            type: 'icon',
            value: 'plus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },
    {
        rowName: 'Access management',
        business: {
            type: 'icon',
            value: 'plus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },
    {
        rowName: 'Assets management',
        business: {
            type: 'icon',
            value: 'plus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },
    {
        rowName: 'Vendor management',
        business: {
            type: 'icon',
            value: 'plus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },

    {
        rowName: 'Risk management',
        business: {
            type: 'icon',
            value: 'plus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },
    {
        rowName: 'HR management',
        business: {
            type: 'icon',
            value: 'plus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },
    {
        rowName: 'KYC & AML',
        business: {
            type: 'icon',
            value: 'plus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },
    {
        rowName: 'Vulnerability scanner',
        business: {
            type: 'icon',
            value: 'plus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },
    {
        rowName: 'Trainings',
        business: {
            type: 'icon',
            value: 'plus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },
    {
        rowName: 'Virtual Compliance Assistant (ViCA)',
        business: {
            type: 'icon',
            value: 'plus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },
    {
        rowName: 'Employee portal',
        business: {
            type: 'icon',
            value: 'plus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },
    {
        rowName: 'Company workspaces',
        business: {
            type: 'text',
            value: '1'
        },
        enterprise: {
            type: 'icon',
            value: 'infinity'
        }
    },
    {
        rowName: 'Dedicated CSM',
        business: {
            type: 'icon',
            value: 'minus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },
    //
    {
        rowName: 'Framework builder',
        business: {
            type: 'icon',
            value: 'minus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },
    {
        rowName: 'Custom integrations',
        business: {
            type: 'icon',
            value: 'minus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },
    {
        rowName: 'Advanced KYC',
        business: {
            type: 'icon',
            value: 'minus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },
    {
        rowName: 'Custom analysis',
        business: {
            type: 'icon',
            value: 'minus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },
    {
        rowName: 'Advanced dashboards',
        business: {
            type: 'icon',
            value: 'minus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },
    {
        rowName: 'Live reporting',
        business: {
            type: 'icon',
            value: 'minus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },
    {
        rowName: 'AI assistant',
        business: {
            type: 'icon',
            value: 'minus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    },
    {
        rowName: 'SAML',
        business: {
            type: 'icon',
            value: 'minus'
        },
        enterprise: {
            type: 'icon',
            value: 'plus'
        }
    }
];


export const allStepsInitial:CreateOrgStep[] = [
    {
        title: 'Company details',
        description: 'Tell us about your company',
        isComplete: false,
        order: 0
    },
    {
        title: 'Choose subscription plan',
        description: 'Choose the most suitable plan for your company',
        isComplete: false,
        order: 1
    },
    // {
    //     title: 'Subscription details',
    //     description: 'Specify details for correct calculation of the subscription price and click the "Proceed to pay" button',
    //     isComplete: false,
    //     order: 2
    // },
    // {
    //     title: 'Billing Information',
    //     description: 'Provide a payment method',
    //     isComplete: false,
    //     order: 3
    // },
    // {
    //     title: 'Registration is successful',
    //     description: 'Start to save resources and gain operative effectiveness in achieving regulatory compliance requirements with Regulait',
    //     isComplete: false,
    //     order: 4
    // }
];

export const availableTeamSizeOptions = ['1-10 employees', '11-50 employees', '51-100 employees', '101-150 employees', '151-200 employees', '201+ employees']
export const availableBranchesOptions = ['single branch/office', 'multi branches/offices'];

//BILLING
export const ISRAEL_COUNTRY_NAME = 'Israel'; //for vat
export const YEAR_PERIOD = 'YEAR';
export const MONTH_PERIOD = 'MONTH';
export const BUSINESS_COLLABORATOR_MONTH_COST = 199;
export const BUSINESS_COLLABORATOR_YEAR_COST = 159;
export const ISRAEL_VAT_PERCENTAGE = 17;
export const CALCULATE_NUMBER_AFTER_COMMA = 2; //199.03232323 -> 199.03
export const YEAR_DISCOUNT_PERCENTAGE = 0.2; //20% discount for yearly plan

export const TRANSACTION_SUCCESS_STATUS = 'SUCCESS';
export const TRANSACTION_FAILED_STATUS = 'FAILED';
export const TRANSACTION_PENDING_STATUS = 'PENDING';
export const TRANSACTION_CHECK_SLEEP_BETWEEN = 3000;
export const TRANSACTION_CHECK_MAX_TRIES = 5;

export const ALL_PAYMENT_HISTORY = 'All';
export const FAILED_PAYMENT_HISTORY = 'Failed';
export const SUCCESS_PAYMENT_HISTORY = 'Success';

export const HISTORY_SUCCESS_STATUS = 'SUCCESS';
export const HISTORY_FAILED_STATUS = 'FAILED';
