import {useDispatch, useSelector} from "react-redux";
import {
    hideDeleteWorkspaceDialog,
    selectedOrganization,
    selectedWorkspace,
    workspacesDialogs,
    workspacesLoadings
} from "../../../../store/slice";
import {useMessageDialog} from "../../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {DeleteWorkspace} from "../../../../store/actions";
import {useHistory} from "react-router-dom";
import {SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH} from "../../../../constants";

export const useDeleteWorkspaceDialog = () => {
    //root
    const history = useHistory();
    const dispatch = useDispatch();
    const selected = useSelector(selectedWorkspace);
    const org = useSelector(selectedOrganization);
    const isOpen = useSelector(workspacesDialogs).deleteWorkspace;
    const isLoading = useSelector(workspacesLoadings)._delete;
    const {setMessage} = useMessageDialog();

    //actions
    const handleClose = () => {
        dispatch(hideDeleteWorkspaceDialog());
    }

    const handleSubmit = () => {
        if(selected){
            dispatch(DeleteWorkspace({
                workspaceId: selected.id,
                onSuccess
            }));
        }
    }

    const onSuccess = () => {
        selected && setMessage({
            title: 'Completed successfully!',
            message: `${selected.name} was deleted!`
        });

        org && history.push(SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH.replace(':orgId', org.id))
    }

    return{
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        selected,
    }
}