import {Skeleton} from "@mui/material";

export const SettingsLoading = () => {
    return (
        <>
            <Skeleton variant="rectangular" width={397} height={20} sx={{margin: '8px 0 0 0'}}/>
            <Skeleton variant="rectangular" width={712} height={44} sx={{margin: '32px 0 0 0'}}/>
            <Skeleton variant="rectangular" width={320} height={40} sx={{margin: '24px 0 0 0'}}/>
            <Skeleton variant="rectangular" width={1564} height={24} sx={{margin: '24px 0 0 0'}}/>
            <Skeleton variant="rectangular" width={1564} height={24} sx={{margin: '24px 0 0 0'}}/>
            <Skeleton variant="rectangular" width={1564} height={24} sx={{margin: '24px 0 0 0'}}/>
            <Skeleton variant="rectangular" width={1564} height={24} sx={{margin: '24px 0 0 0'}}/>
            <Skeleton variant="rectangular" width={1564} height={24} sx={{margin: '24px 0 0 0'}}/>
        </>
    )
}


