import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../../newShared/redux/rootReducer";
import {TCreateReportControl, TReport, TSetArchiveReportDialog} from "../../types";
import {TDialog, TIdNameLogo, TPageInfo} from "../../../../../newShared/types";
import {
    archiveReportAction,
    createReportAction,
    deleteReportAction,
    getArchivedReportsAction,
    getDataForCreateReportSnapshotAction,
    getFrameworksIdNameLogoAction,
    getGeneratedReportsAction
} from "./actions";
import {SEND_REPORT_STATUS_CHANGED_ACTION} from "../../actions";
import {minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {minMaxLoadedPageDefault} from "../../../../../newShared/components/genericTable/constants";
import {handlePagination} from "../../../../../newShared/components/genericTable/helpers";

export type TReportsSlice = {
    archivedReports: {
        reports: (TReport)[],
        isLoading: boolean,
        pageInfo: TPageInfo,
        minMaxLoadedPage: minMaxLoadedPageType;
    },
    generatedReports: {
        reports: TReport[],
        isLoading: boolean,
        pageInfo: TPageInfo,
        minMaxLoadedPage: minMaxLoadedPageType;
    },
    newReport: {
        frameworks: TIdNameLogo[],
        isLoadingFrameworks: boolean,
        isLoading: boolean,
        reportId: string | null,

        isLoadingControls: boolean,
        controls: TCreateReportControl[]
    },

    dialogs: {
        archiveReport: TDialog & {
            reportId: string | null,
            name: string | null,
        },
        deleteReport: TDialog & {
            reportId: string | null,
            name: string | null,
        },
    },
}

const initialState: TReportsSlice = {
    archivedReports: {
        reports: [],
        isLoading: false,
        pageInfo: {page: 0, count: 50, total: 0},
        minMaxLoadedPage: minMaxLoadedPageDefault
    },
    generatedReports: {
        reports: [],
        isLoading: false,
        pageInfo: {page: 0, count: 50, total: 0},
        minMaxLoadedPage: minMaxLoadedPageDefault
    },
    newReport: {
        frameworks: [],
        isLoadingFrameworks: false,
        isLoading: false,
        reportId: null,

        isLoadingControls: false,
        controls: [],
    },

    dialogs: {
        archiveReport: {
            isOpen: false,
            isLoading: false,
            reportId: null,
            name: null,
        },
        deleteReport: {
            isOpen: false,
            isLoading: false,
            reportId: null,
            name: null,
        },
    },
}

const Slice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        cleanAction(slice: TReportsSlice, {payload}: {payload: 'generatedReports' | 'archivedReports' | 'newReport' | 'dialogs' | undefined}) {
            if (payload) {
                if (payload === "generatedReports") slice.generatedReports = initialState.generatedReports;
                if (payload === "archivedReports") slice.archivedReports = initialState.archivedReports;
                if (payload === "newReport") slice.newReport = initialState.newReport;
                if (payload === "dialogs") slice.dialogs = initialState.dialogs;
            } else {
                slice.generatedReports = initialState.generatedReports;
                slice.archivedReports = initialState.archivedReports;
                slice.newReport = initialState.newReport;

                slice.dialogs = initialState.dialogs;
            }
        },

        
        setArchiveReportDialogAction(slice: TReportsSlice, {payload}: {payload: TSetArchiveReportDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.archiveReport.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.archiveReport.isLoading = payload.isLoading;
            if (payload.reportId !== undefined) slice.dialogs.archiveReport.reportId = payload.reportId;
            if (payload.name !== undefined) slice.dialogs.archiveReport.name = payload.name;

            if (payload.clear) slice.dialogs.archiveReport = initialState.dialogs.archiveReport;
        },
        setDeleteReportDialogAction(slice: TReportsSlice, {payload}: {payload: TSetArchiveReportDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.deleteReport.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.deleteReport.isLoading = payload.isLoading;
            if (payload.reportId !== undefined) slice.dialogs.deleteReport.reportId = payload.reportId;
            if (payload.name !== undefined) slice.dialogs.deleteReport.name = payload.name;

            if (payload.clear) slice.dialogs.deleteReport = initialState.dialogs.deleteReport;
        },

        setMinMaxLoadedPageGeneratedReports(slice, {payload}: {payload: minMaxLoadedPageType}){
            slice.generatedReports.minMaxLoadedPage = payload;
        },
        setMinMaxLoadedPageArchivedReports(slice, {payload}: {payload: minMaxLoadedPageType}){
            slice.archivedReports.minMaxLoadedPage = payload;
        }
    },
    extraReducers: builder => {
      builder
          .addCase(createReportAction.pending, (slice: TReportsSlice) => {
              slice.newReport.isLoading = true;
          })
          .addCase(createReportAction.rejected, (slice: TReportsSlice) => {
              slice.newReport.isLoading = false;
          })
          .addCase(createReportAction.fulfilled, (slice: TReportsSlice, {payload}) => {
              slice.newReport.isLoading = false;
              slice.newReport.reportId = payload.resp.id;
          })

          .addCase(getDataForCreateReportSnapshotAction.pending, (slice: TReportsSlice) => {
              slice.newReport.isLoadingControls = true;
              slice.newReport.controls = [];
          })
          .addCase(getDataForCreateReportSnapshotAction.rejected, (slice: TReportsSlice) => {
              slice.newReport.isLoadingControls = false;
          })
          .addCase(getDataForCreateReportSnapshotAction.fulfilled, (slice: TReportsSlice, {payload}) => {
              slice.newReport.isLoadingControls = false;
              slice.newReport.controls = payload.resp;
          })

          .addCase(getGeneratedReportsAction.pending, (slice: TReportsSlice, {meta:{arg}}) => {
              if (arg.clean) slice.generatedReports = {...initialState.generatedReports, isLoading: true}
              else slice.generatedReports.isLoading = true;
          })
          .addCase(getGeneratedReportsAction.rejected, (slice: TReportsSlice) => {
              slice.generatedReports.isLoading = false;
          })
          .addCase(getGeneratedReportsAction.fulfilled, (slice: TReportsSlice, {payload}) => {
              slice.generatedReports.isLoading = false;

              // const {array, pageInfo} = setPaginationInStore<TReport>(
              //     slice.generatedReports.pageInfo,
              //     slice.generatedReports.reports,
              //     payload.resp.pageInfo,
              //     payload.resp.reports,
              //     payload.isNewOnTop
              // );
              // slice.generatedReports.reports = array;
              // slice.generatedReports.pageInfo = pageInfo;
              const {results, maxLoadedPage, minLoadedPage} = handlePagination<TReport>(
                  slice.generatedReports.reports,
                  slice.generatedReports.pageInfo,
                  payload.resp.reports,
                  payload.resp.pageInfo,
                  slice.generatedReports.minMaxLoadedPage.minLoadedPage,
                  slice.generatedReports.minMaxLoadedPage.maxLoadedPage,
                  'id'
              );

              slice.generatedReports.reports = results;
              slice.generatedReports.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
              slice.generatedReports.pageInfo = payload.resp.pageInfo;
          })

          .addCase(getArchivedReportsAction.pending, (slice: TReportsSlice, {meta:{arg}}) => {
              if (arg.clean) slice.archivedReports = {...initialState.archivedReports, isLoading: true}
              else slice.archivedReports.isLoading = true;
          })
          .addCase(getArchivedReportsAction.rejected, (slice: TReportsSlice) => {
              slice.archivedReports.isLoading = false;
          })
          .addCase(getArchivedReportsAction.fulfilled, (slice: TReportsSlice, {payload}) => {
              slice.archivedReports.isLoading = false;

              // const {array, pageInfo} = setPaginationInStore<TReport>(
              //     slice.archivedReports.pageInfo,
              //     slice.archivedReports.reports,
              //     payload.resp.pageInfo,
              //     payload.resp.reports,
              //     payload.isNewOnTop
              // );
              // slice.archivedReports.reports = array;
              // slice.archivedReports.pageInfo = pageInfo;
              const {results, maxLoadedPage, minLoadedPage} = handlePagination<TReport>(
                  slice.archivedReports.reports,
                  slice.archivedReports.pageInfo,
                  payload.resp.reports,
                  payload.resp.pageInfo,
                  slice.archivedReports.minMaxLoadedPage.minLoadedPage,
                  slice.archivedReports.minMaxLoadedPage.maxLoadedPage,
                  'id'
              );

              slice.archivedReports.reports = results;
              slice.archivedReports.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
              slice.archivedReports.pageInfo = payload.resp.pageInfo;
          })

          .addCase(archiveReportAction.pending, (slice: TReportsSlice) => {
              slice.dialogs.archiveReport.isLoading = true;
          })
          .addCase(archiveReportAction.rejected, (slice: TReportsSlice) => {
              slice.dialogs.archiveReport.isLoading = false;
          })
          .addCase(archiveReportAction.fulfilled, (slice: TReportsSlice, {payload}) => {
              slice.dialogs.archiveReport = initialState.dialogs.archiveReport;
              slice.generatedReports.reports = slice.generatedReports.reports.filter(e => e?.id !== payload.data.reportId);
              if (slice.generatedReports.pageInfo.total > 0) slice.generatedReports.pageInfo.total -= 1;
          })

          .addCase(deleteReportAction.pending, (slice: TReportsSlice) => {
              slice.dialogs.deleteReport.isLoading = true;
          })
          .addCase(deleteReportAction.rejected, (slice: TReportsSlice) => {
              slice.dialogs.deleteReport.isLoading = false;
          })
          .addCase(deleteReportAction.fulfilled, (slice: TReportsSlice, {payload}) => {
              slice.dialogs.deleteReport = initialState.dialogs.deleteReport;
              slice.archivedReports.reports = slice.archivedReports.reports.filter(e => e?.id !== payload.data.id);
              if (slice.archivedReports.pageInfo.total > 0) slice.archivedReports.pageInfo.total -= 1;
          })

          .addCase(getFrameworksIdNameLogoAction.pending, (slice: TReportsSlice) => {
              slice.newReport.isLoadingFrameworks = true;
          })
          .addCase(getFrameworksIdNameLogoAction.rejected, (slice: TReportsSlice) => {
              slice.newReport.isLoadingFrameworks = false;
          })
          .addCase(getFrameworksIdNameLogoAction.fulfilled, (slice: TReportsSlice, {payload}) => {
              slice.newReport.isLoadingFrameworks = false;
              slice.newReport.frameworks = payload.resp
          })

          .addCase(SEND_REPORT_STATUS_CHANGED_ACTION.fulfilled, (slice: TReportsSlice, {payload}) => {
              const tmp = slice.generatedReports.reports;
              const index = tmp.findIndex(e => e?.id === payload.reportId);
              if (index > -1) {
                  tmp[index]!.reportStatus = payload.status;
                  slice.generatedReports.reports = tmp;
              }
          })
    }
});

export const reportsReducerReducer = Slice.reducer;

export const {
    cleanAction,

    setDeleteReportDialogAction,
    setArchiveReportDialogAction,
    setMinMaxLoadedPageGeneratedReports,
    setMinMaxLoadedPageArchivedReports
} = Slice.actions;

const selectSelf = (state: AppState): TReportsSlice => state.reports;

export const generatedReportsSelector = createSelector(selectSelf, state => state.generatedReports);
export const archivedReportsSelector = createSelector(selectSelf, state => state.archivedReports);
export const newReportSelector = createSelector(selectSelf, state => state.newReport);

export const reportsDialogsSelector = createSelector(selectSelf, state => state.dialogs);

