import {IconButton} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {IconButtonProps} from "@mui/material/IconButton/IconButton";
import {FC} from "react";
import {CSSProperties} from "@mui/styles";
import {TAvatarSizeButton} from "../../../types";
import colors from "../../../theme/colors";

type TAddAvatarButton = IconButtonProps & {
    btnSize?: TAvatarSizeButton,
}

export const AddAvatarButton: FC<TAddAvatarButton> = ({btnSize= 'd40', onMouseEnter, onMouseLeave, ...props}) => {

    const btnTypes: { [key: string]: CSSProperties} = {
        d16: {width : '16px ', height: '16px ', padding: '0', border: '1px solid ' + colors.text.grey_light_2},
        d24: {width : '24px ', height: '24px ', padding: '0', border: '1px solid ' + colors.text.grey_light_2},
        d28: {width : '28px ', height: '28px ', padding: '0', border: '1px solid ' + colors.text.grey_light_2},
        d32: {width : '30px ', height: '30px ', border: '2px solid ' + colors.text.grey_light_2},
        d40: {width : '40px ', height: '40px ', border: '2px solid ' + colors.text.grey_light_2},
        d48: {width : '48px ', height: '48px ', border: '2px solid ' + colors.text.grey_light_2},
        d56: {width : '56px ', height: '56px ', border: '2px solid ' + colors.text.grey_light_2},
        d72: {width : '72px ', height: '72px ', border: '2px solid ' + colors.text.grey_light_2},
        d100: {width : '100px ', height: '100px ', border: '2px solid ' + colors.text.grey_light_2},
    }

    const iconTypes: { [key: string]: CSSProperties} = {
        d16: {width : '12px', height: '12px', color: colors.text.grey_light_2},
        d24: {width : '14px', height: '14px', color: colors.text.grey_light_2},
        d28: {width : '18px', height: '18px', color: colors.text.grey_light_2},
        d32: {width : '20px', height: '20px', color: colors.text.grey_light_2},
        d40: {width : '22px', height: '22px', color: colors.text.grey_light_2},
        d48: {width : '24px', height: '24px', color: colors.text.grey_light_2},
        d56: {width : '26px', height: '26px', color: colors.text.grey_light_2},
        d72: {width : '28px', height: '28px', color: colors.text.grey_light_2},
        d100: {width : '32px', height: '32px', color: colors.text.grey_light_2},
    }

    return (
        <IconButton {...props}
                    sx={{...btnTypes[btnSize], ...props?.sx}}
        >
            <AddIcon sx={{...iconTypes[btnSize]}} />
        </IconButton>
    )
}
