import {useDispatch, useSelector} from "react-redux";
import {
    archiveReportAction,
    createReportAction,
    deleteReportAction,
    getArchivedReportsAction,
    getDataForCreateReportSnapshotAction,
    getFrameworksIdNameLogoAction,
    getGeneratedReportsAction
} from "../../store/reports/actions";
import {CreateReportMutationVariables, PaginationInput} from "../../../../../newShared/GQLTypes";
import {
    archivedReportsSelector,
    cleanAction,
    generatedReportsSelector,
    newReportSelector,
    TReportsSlice
} from "../../store/reports/store";
import {useHistory} from "react-router";
import {
    PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED,
    PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED,
    PATH_LOCAL_COMPLIANCE_REPORTS_NEW
} from "../../../../../newShared/constants";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TCreateReportControl} from "../../types";
import {DEFAULT_GENERIC_TABLE_SEARCH} from "../../../../../newShared/components/genericTable/constants";

type TResponse = {
    newReport: TReportsSlice["newReport"],
    generatedReports: TReportsSlice["generatedReports"],
    archivedReports: TReportsSlice["archivedReports"],

    goToCreateReport: () => void,
    goToReport: (id: string, archived: boolean) => void,
    goToReportApp: (reportId: string, appId: string, archived: boolean) => void,
    getFrameworksIdNameLogo: () => void,
    createReport: (data: CreateReportMutationVariables["report"], controls: TCreateReportControl[]) => void,
    getDataForCreateReport: (frameworkId: string, signal?: AbortSignal) => void,

    getGeneratedReports: (pageInfo: PaginationInput, clean: boolean, signal?: AbortSignal) => void,
    getArchivedReports: (pageInfo: PaginationInput, clean: boolean, signal?: AbortSignal) => void,

    archiveReport: (reportId: string, name: string) => void,
    deleteReport: (reportId: string, name: string) => void,

    cleanNewReport: () => void,
    cleanGeneratedReports: () => void,
    cleanArchivedReports: () => void,
}
export const useReports = (): TResponse => {
    const {t: tDialog} = useMainTranslation('', {keyPrefix: 'pathReports.Dialogs'});

    const dispatch = useDispatch();
    const history = useHistory();
    // const {filter} = useParameters()
    const {setMessage} = useMessageDialog();

    const newReport = useSelector(newReportSelector);
    const generatedReports = useSelector(generatedReportsSelector);
    const archivedReports = useSelector(archivedReportsSelector);

    const goToCreateReport: TResponse['goToCreateReport'] = () => {history.push(`${PATH_LOCAL_COMPLIANCE_REPORTS_NEW}`)};
    const goToReport: TResponse['goToReport'] = (id, archived) => {
        archived
            ? history.push({
                pathname: `${PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED}/${id}`,
                search: DEFAULT_GENERIC_TABLE_SEARCH
            })
            : history.push({
                pathname: `${PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED}/${id}`,
                search: DEFAULT_GENERIC_TABLE_SEARCH
            })
    };
    const goToReportApp: TResponse['goToReportApp'] = (reportId, appId, archived) => {
        archived
            ? history.push(`${PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED}/${reportId}/integrations/${appId}`)
            : history.push(`${PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED}/${reportId}/integrations/${appId}`)
    };
    const createReport: TResponse['createReport'] = (report, controls) => {dispatch(createReportAction({data: {
        workspaceId: '', organizationId: '', report, controls
        }}))};
    const getFrameworksIdNameLogo = () => {dispatch(getFrameworksIdNameLogoAction({data: {}}))};
    const getDataForCreateReport: TResponse['getDataForCreateReport'] = (frameworkId, signal) => {dispatch(getDataForCreateReportSnapshotAction({
        data: {frameworkId}, signal
    }))}

    const getGeneratedReports: TResponse["getGeneratedReports"] = (pageInfo, clean, signal) => {dispatch(getGeneratedReportsAction({
        data: {workspaceId: '', organizationId: '', pageInfo}, signal, clean,
    }))};
    const getArchivedReports: TResponse["getArchivedReports"] = (pageInfo, clean, signal) => {dispatch(getArchivedReportsAction({
        data: {workspaceId: '', organizationId: '', pageInfo}, signal, clean,
    }))};

    const archiveReport: TResponse["archiveReport"] = (reportId, name) => {dispatch(archiveReportAction({
        data: {workspaceId: '', reportId}, onSuccess: () => {
            history.push(PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED);
            setMessage({title: tDialog("Completed successfully"), message: tDialog("The report '{{name}}' has been archived.", {name})})
        }
    }))};
    const deleteReport: TResponse["deleteReport"] = (id, name) => {dispatch(deleteReportAction({
        data: {workspaceId: '', id}, onSuccess: () => {
            history.push(PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED);
            setMessage({title: tDialog("Completed successfully"), message: tDialog("The report '{{name}}' has been deleted.", {name})})
        }
    }))};

    const cleanNewReport = () => {dispatch(cleanAction('newReport'))};
    const cleanGeneratedReports = () => {dispatch(cleanAction('generatedReports'))};
    const cleanArchivedReports = () => {dispatch(cleanAction('archivedReports'))};
    return {
        newReport,
        generatedReports,
        archivedReports,

        goToCreateReport,
        goToReport,
        goToReportApp,
        getFrameworksIdNameLogo,
        createReport,
        getDataForCreateReport,

        getGeneratedReports,
        getArchivedReports,

        archiveReport,
        deleteReport,

        cleanNewReport,
        cleanGeneratedReports,
        cleanArchivedReports,
    }
}