import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {availableApps, installedApps, loadings, setSelectedApplication} from "../../store/slice";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_INTEGRATIONS} from "../../../../../newShared/constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {PATH_INTEGRATIONS_ADDED, PATH_INTEGRATIONS_ADDED_EXACT, PATH_INTEGRATIONS_AVAILABLE} from "../../constants";
import {useForm} from "../../../../../newShared/hooks/useForm";
import {installedAppType} from "../../types";
import {GetInitIntegrationsData} from "../../store/actions";
import {useEffect} from "react";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";

export const useAddedApps = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const controller = new AbortController();
    const {clearFilters} = useGenericFiltersStorage();
    //
    useEffect(() => {
        dispatch(GetInitIntegrationsData({data: {availableName: '', installedName: '', eraseBeforeFetch: true}, signal: controller.signal}));

        return () => {
            clearFilters();
            controller.abort();
        }
        //eslint-disable-next-line
    }, []);
    //bread
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    useSetBreadcrumbs([
        {
            title: tMenu('Integrations'),
            path: PATH_LOCAL_INTEGRATIONS
        },
        {
            title: tMenu('Installed integrations'),
            path: PATH_INTEGRATIONS_ADDED
        }
    ]);

    //selectors
    const installed = useSelector(installedApps);
    const available = useSelector(availableApps);
    const {getInstalledAndAvailableApps} = useSelector(loadings);

    //search
    const {form, setForm, handleChange} = useForm<{search: string, prevSearch: string}>({search: '', prevSearch: ''});

    const handleCleanSearch = () => {
        setForm({search: '', prevSearch: ''});
        dispatch(GetInitIntegrationsData({data: {
                installedName: '',
                availableName: '',
                eraseBeforeFetch: true
            }, signal: controller.signal}));
        // getData(0, DEFAULT_COUNT, newFilter, false, filter.direction === 'DESC');
    }

    const handleEnterKey = (e: any) => {
        if(e.keyCode === 13 && !form.search.trim().length){
            handleCleanSearch();
        }

        if(e.keyCode === 13 && form.search.trim().length > 0){
            setForm({...form, prevSearch: form.search});
            dispatch(GetInitIntegrationsData({data: {
                    installedName: form.search.trim(),
                    availableName: '',
                    eraseBeforeFetch: true
                }, signal: controller.signal}));
        }
    }

    //actions
    const handleGoToApp = (app: installedAppType) => {
        // dispatch(openAppInDevDialog());
        const currApp = available.find(e => e.id === app.detailsId);
        if(currApp){
            dispatch(setSelectedApplication({
                details: app,
                app: currApp,
            }));
            history.push(PATH_INTEGRATIONS_ADDED_EXACT.replace(':id', app.applicationId));
        }
    }

    const handleGoToCreateApp = () => history.push(PATH_INTEGRATIONS_AVAILABLE);

    const fetchByFilters = (search: string, filters: Record<string, string[]>, signal: AbortSignal) => {
        dispatch(GetInitIntegrationsData({data: {availableName: '', installedName: search, eraseBeforeFetch: true}}));
    }

    return{
        apps: installed,
        availables: available,
        isLoading: getInstalledAndAvailableApps,
        search: {
            form,
            handleChange,
            handleEnterKey,
            handleCleanSearch,
        },
        actions: {
            handleGoToApp,
            handleGoToCreateApp
        },
        isNoResult: !getInstalledAndAvailableApps && form.prevSearch.length > 0 && !installed.length,
        isNoApps: !getInstalledAndAvailableApps && !form.prevSearch.trim().length && !installed.length,
        fetchByFilters
    }
}