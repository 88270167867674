import {createSettingFormType, THrSettings, THrSettingsJobTitle} from "../../../types";
import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideAddSettingDialog, hrSettings, loadings} from "../../../store/slice";
import {Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import React from "react";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {isEqual} from "../../../helpers";
import * as S from '../../styled';
import {CreateHrEmployeeSetting, CreateHrSettingsJobTitle} from "../../../store/actions";
import {HrSettingsNormalizedNames} from "../../../constants";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export type AddSettingsDialogPropsType = {
    onSuccess: (type: keyof THrSettings, value: string, id: number) => void,
    onSuccessJobTitleAdd?: (value: THrSettingsJobTitle) => void,
}

const defaultForm:createSettingFormType = {
    name: '',
    jobType: null,
    requirements: '',
    description: ''
}

export const AddSettingsDialog = ({onSuccess, onSuccessJobTitleAdd}:AddSettingsDialogPropsType) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathHRManagement.dialogs'});
    const {tCommon} = useMainTranslation();

    //root
    const dispatch = useDispatch();
    const settings = useSelector(hrSettings);

    const {type, isOpen, id} = useSelector(dialogs).addSettingDialog;
    const isLoading = useSelector(loadings).createSetting;

    const handleClose = () => {
        dispatch(hideAddSettingDialog());
        setForm(defaultForm);
    }

    const handleSubmit = () => {
        if(isOk() && type){
            if(type === 'jobTitle'){
                dispatch(CreateHrSettingsJobTitle({jobTitle: form, onSuccess: finalActionJobTitle}))
            }else{
                dispatch(CreateHrEmployeeSetting({data: {field: type, value: form.name, onSuccess: finalAction}}))
            }
        }
    }

    const finalAction = () => {
        type && onSuccess(type, form.name, id)
        handleClose();
    }

    const finalActionJobTitle = (value: THrSettingsJobTitle) => {
        onSuccessJobTitleAdd && onSuccessJobTitleAdd(value);
        handleClose();
    }

    const {form, setForm, handleChange} = useForm<createSettingFormType>(defaultForm);

    const handleSetJobType = (jobType: string | null) => {setForm({...form, jobType})}

    const isOk = ():boolean => {
        if(type === 'jobTitle'){
            return form.name.trim().length > 0 &&
                form.description.trim().length > 0 &&
                form.requirements.trim().length > 0 &&
                form.jobType !== null && form.jobType.trim().length > 0 &&
                !isAlreadyExists()
        }else{
            return form.name.trim().length > 0 && !isAlreadyExists()
        }
    }

    const isAlreadyExists = (): boolean => {
        switch (type){
            case 'contactType': return settings.contactType.some(e => isEqual(form.name, e));
            case 'relationshipType': return Object.keys(settings.relationshipType).some(e => isEqual(form.name, e));
            case 'degree': return Object.keys(settings.degree).some(e => isEqual(form.name, e));
            case 'jobTitle': return settings.jobTitle.some(e => isEqual(form.name, e.name));
            case 'department': return Object.keys(settings.department).some(e => isEqual(form.name, e));
            case 'employmentType': return Object.keys(settings.employmentType).some(e => isEqual(form.name, e));
            case 'division': return Object.keys(settings.division).some(e => isEqual(form.name, e));
            case 'location': return Object.keys(settings.location).some(e => isEqual(form.name, e));
            case 'customFields': return Object.keys(settings.customFields).some(e => isEqual(form.name, e));
            default: return false;
        }
    }

    const _isAlreadyExists = isAlreadyExists();
    return(
        <Dialog open={isOpen} onClose={handleClose} scroll={'body'} >
            <DialogTitle>{`${tCommon('Add')} ${type && (HrSettingsNormalizedNames[type]?.length ?? 0) > 0 ? t(HrSettingsNormalizedNames[type]) : ''}`}</DialogTitle>

            <DialogContent>
                <S.DialogContentWrapper>
                    <TextField
                        value={form.name}
                        autoFocus
                        onChange={handleChange}
                        label={t('Name')}
                        name={'name'}
                        required
                        size={'small'}
                        error={_isAlreadyExists}
                        helperText={_isAlreadyExists ? t('{{type}} with such name already exists', {type}) : undefined}
                    />

                    {type === 'jobTitle' &&
                        <>
                            <Autocomplete
                                options={settings.jobType}
                                value={form.jobType}
                                onChange={(e, newValue) => handleSetJobType(newValue)}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label={t('Job type')}
                                    size={'small'}
                                    required
                                    sx={{margin: '16px 0 0 0'}}
                                />}
                            />

                            <TextField
                                value={form.requirements}
                                onChange={handleChange}
                                label={t('Requirements')}
                                name={'requirements'}
                                required
                                // size={'small'}
                                minRows={5}
                                multiline
                                sx={{margin: '16px 0 0 0'}}
                            />

                            <TextField
                                value={form.description}
                                onChange={handleChange}
                                label={t('Description')}
                                name={'description'}
                                required
                                // size={'small'}
                                minRows={5}
                                multiline
                                sx={{margin: '16px 0 0 0'}}
                            />
                        </>
                    }
                </S.DialogContentWrapper>
            </DialogContent>

            <DialogActions sx={{gap: '16px'}}>
                <Button onClick={handleClose} variant={'text'}>{tCommon('Close')}</Button>
                <LoadingButton
                    onClick={handleSubmit}
                    disabled={!isOk()}
                    loading={isLoading}
                >
                    {tCommon('Add')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}