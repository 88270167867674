import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    AddedFolderType,
    creatableDocumentFileForUpload,
    DocumentFile,
    DocumentFolder,
    DocVariable,
    TShortDocument
} from "../types";
import {
    autocompleteEmployeesEmailName,
    autocompleteRecipientsEmailNameApi,
    changeDocumentStageEtaApi,
    createFolder,
    createNewDoc,
    deleteFolder,
    deleteItems,
    deleteNewDocument,
    generatePdfByHTML,
    getDocumentByIdApi,
    getFilesByFolderId,
    getFolderTree,
    getFullDocumentWithExecutorApi,
    getNewDocumentByIdApi,
    getNewTemplateByIdApi,
    getShortDocumentsByFolderIdWithFilterPagingApi,
    handlePreviewBeforeSendApi,
    invalidateDocument,
    makeDocDraftCopy,
    moveItems,
    moveNewItems,
    renameItem,
    restartSignStageByStageIdAndGroupId,
    restartSignStageByStageIdAndRecipient,
    restoreNewItem,
    sendToNextStepByStageId,
    startNewDocumentExecution,
    updateDoc,
    updateFullDocument,
    uploadFile,
    uploadFileArray
} from "../api";
import {saveAs} from "file-saver";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {getActionsData} from "../../../../newShared/redux";
import {addInfoSnack, addSuccessfulSnack} from "../../../barsEnvironment/snack/store/slice";
import {b64toBlob} from "../../../../newShared/utils/base64/base64";
import {downloadEvidenceFileApi} from "../../frameworks/api";
import {makeFirstLetterUpper} from "../../../../newShared/utils/text";
import {TWithOptions} from "../../../../newShared/types";
import {
    AutocompleteEmployeesEmailNameQueryVariables,
    AutocompleteRecipientsEmailNameQueryVariables,
    CreateNewDocumentMutationVariables,
    DeleteFolderMutationVariables,
    DeleteNewDocumentMutationVariables,
    DocApproverInput,
    GenerateFileByHtmlInput,
    GetDocumentByIdQueryVariables,
    GetFullDocumentWithExecutorQueryVariables,
    GetNewDocumentByIdQueryVariables,
    GetNewTemplateByIdQueryVariables,
    GetOriginTemplateByIdQueryVariables,
    GetShortDocumentsByFolderIdWithFilterPagingQueryVariables,
    NewDocDataInput,
    NewDocumentActorInput,
    RecipientInput,
    SetDocumentAsEvidenceAssetMutation,
    SetDocumentAsEvidenceAssetMutationVariables
} from "../../../../newShared/GQLTypes";
import {getOriginTemplateByIdApi} from "../../vica/api";
import {setMessageDialogAction} from "../../../barsEnvironment/MessageDialog/store/store";
import {createEventCommonDialogOpen} from "../../../../newShared/components/editorUnion/helpers";
import {getDaysFromMs} from "../../../../newShared/utils/dateTools";
import {createCommonAsyncThunk} from "../../../../newShared/utils/asyncThunk";
import {setDocumentAsEvidenceAsset} from "../api/query";

export { };

//getFolderTree
export const GetFolderTree = createAsyncThunk(
    'Documents/getFolderTree',
    async (_, {getState}): Promise<DocumentFolder[]> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        try{
            return await getFolderTree(workspaceId || '');
        }catch (ex){
            return [];
        }
    }
);

//getFilesByFolderId
export const GetFilesByFolderId = createAsyncThunk(
    'Documents/getFilesByFolderId',
    async (data: {folderId: string}, {getState}) => {
        //when making copy of template - fetching selected folder files before creating - so adding one file at the end
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getFilesByFolderId(workspaceId || '', data.folderId);
    }
);

export const GetNewDocumentById = createAsyncThunk(
    'Documents/GetNewDocumentById',
    async (data: GetNewDocumentByIdQueryVariables, {getState}) => {
        //when making copy of template - fetching selected folder files before creating - so adding one file at the end
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getNewDocumentByIdApi({...data, workspaceId});
    }
);

export const GetNewTemplateById = createAsyncThunk(
    'Documents/GetNewTemplatesById',
    async (data: GetNewTemplateByIdQueryVariables, {getState}) => {
        //when making copy of template - fetching selected folder files before creating - so adding one file at the end
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getNewTemplateByIdApi({...data, workspaceId});
    }
);

export const GetShortDocumentsByFolderIdWithFilterPaging = createAsyncThunk(
    'Documents/GetShortDocumentsByFolderIdWithFilterPaging',
    async ({data, signal}: TWithOptions<GetShortDocumentsByFolderIdWithFilterPagingQueryVariables>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const folder = state.documents.selectedFolder;
        const resp = await getShortDocumentsByFolderIdWithFilterPagingApi({...data, workspaceId, folderId: folder?.id ?? ''}, signal);
        return {
            resp,
            isNewOnTop: false,
        }
    }
);

export const MoveNewItems = createAsyncThunk(
    'Documents/moveNewItems',
    async (data: {fileIds: string[], folderIds: string[], newFatherId: string}, {getState, dispatch}): Promise<{fileIds: string[], folderIds: string[], newFatherId: string}> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const message = await moveNewItems(workspaceId || '', data.fileIds, data.folderIds, data.newFatherId);
        dispatch(addInfoSnack(message));
        return data;
    }
);

export const RestoreNewItem = createAsyncThunk(
    'Documents/restoreNewItem',
    async (data: {fileId: string, newFatherId: string}, {getState, dispatch}): Promise<{fileId: string, newFatherId: string}> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const message = await restoreNewItem({workspaceId, fileId: data.fileId, newFatherId: data.newFatherId}); //workspaceId || '', data.fileId, data.newFatherId
        dispatch(addInfoSnack(message));
        return data;
    }
);

export const DeleteNewDocument = createAsyncThunk(
    'Documents/DeleteNewDocument',
    async (data: DeleteNewDocumentMutationVariables, {getState, dispatch}): Promise<{ docId: string }> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const message = await deleteNewDocument({...data, workspaceId});
        dispatch(addInfoSnack(message));
        return {docId: data.docId};
    }
);

export const DeleteFolder = createAsyncThunk(
    'Documents/DeleteFolder',
    async (data: DeleteFolderMutationVariables, {getState, dispatch}): Promise<{ folderId: string }> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const message = await deleteFolder({...data, workspaceId});
        dispatch(addInfoSnack(message));
        return {folderId: data.folderId };
    }
);

export const GetTemplatesVariants = createAsyncThunk(
    'Documents/GetTemplatesVariants',
    async (_, {getState}) => {
        //when making copy of template - fetching selected folder files before creating - so adding one file at the end
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getShortDocumentsByFolderIdWithFilterPagingApi({workspaceId, folderId: 'gallery', pageInfo: {page: 0, count: 0}});
    }
);


//createFolder
export const CreateFolder = createAsyncThunk(
    'Documents/createFolder',
    async (data: {fatherId: string, name: string}, {getState, dispatch}): Promise<AddedFolderType> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const id = await createFolder(workspaceId || '', data.fatherId, data.name);

        dispatch(addInfoSnack('Folder created'));
        return {...data, id};
    }
);

//deleteItems
export const DeleteItems = createAsyncThunk(
    'Documents/deleteItems',
    async (data: {fileIds: string[], folderIds: string[]}, {getState, dispatch}): Promise<{fileIds: string[], folderIds: string[]}> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const message = await deleteItems(workspaceId || '', data.fileIds, data.folderIds);
        dispatch(addInfoSnack(message));
        return data;
    }
);

export const MoveItems = createAsyncThunk(
    'Documents/moveItems',
    async (data: {fileIds: string[], folderIds: string[], newFatherId: string}, {getState, dispatch}): Promise<{fileIds: string[], folderIds: string[], newFatherId: string}> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const message = await moveItems(workspaceId || '', data.fileIds, data.folderIds, data.newFatherId);
        dispatch(addInfoSnack(message));
        return data;
    }
);

//uploadFileArray
export const UploadFileArray = createAsyncThunk(
    'Documents/uploadFileArray',
    async (data: {items: creatableDocumentFileForUpload[]}, {getState, dispatch}): Promise<DocumentFile[]> => {
        if(!data.items.length) return [];
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const docs = await uploadFileArray(workspaceId || '', data.items);
        dispatch(addInfoSnack(`${data.items.length} file${data.items.length > 1 ? 's' : ''} uploaded!`));
        return docs;
    }
);

export const UploadShortFileArray = createAsyncThunk(
    'Documents/UploadShortFileArray',
    async (data: {items: creatableDocumentFileForUpload[]}, {getState, dispatch}): Promise<TShortDocument[]> => {
        if(!data.items.length) return [];
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const docs = await uploadFileArray(workspaceId || '', data.items);
        dispatch(addInfoSnack(`${data.items.length} file${data.items.length > 1 ? 's' : ''} uploaded!`));
        return docs.map(d => ({
            id: d.id,
            documentId: d.documentId,
            folderId: d.folderId,
            type: d.type,
            name: d.name,
            status: d.status,
            origin: d.origin,
            version: d.version,
            latest: d.latest,
            createdDate: d.createdDate,
            tags: d.tags,
            lastModified: d.lastEditDate,
            recipients: [],
        }));
    }
);

export const UploadFile = createAsyncThunk(
    'Documents/UploadFile',
    async (data: {items: {file: string, fileName: string}[], folderId: string, snack: boolean, isTemplate: boolean}, {getState, dispatch}): Promise<void> => {
        if(!data.items.length) return;
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        data.snack && dispatch(addInfoSnack(`Uploading ${data.items.length} file${data.items.length > 1 ? 's' : ''}...`));
        //upload to files service
        await Promise.all(data.items.map(e =>
            uploadFile({workspaceId: workspaceId || '', file: e.file}).then((data) => {return {fileId: data, fileName: e.fileName}})
        )).then((createdFiles) => {
            const creatableFiles: creatableDocumentFileForUpload[] = [];

            createdFiles.forEach(e => {
                creatableFiles.push({
                    name: e.fileName,
                    documentId: e.fileId,
                    folderId: data.folderId,
                    type: data.isTemplate ? 'TEMPLATE' : 'FILE'
                });
            })
            //create file docs

            dispatch(UploadFileArray({items: creatableFiles}));
        });
    }
);

//renameItem
export const RenameItem = createAsyncThunk(
    'Documents/renameItem',
    async (data: {itemType: 'folder' | 'file', itemName: string, itemId: string}, {getState, dispatch}): Promise<{itemType: 'folder' | 'file', itemName: string, itemId: string}> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await renameItem(workspaceId || '', data.itemType, data.itemId, data.itemName);
        dispatch(addInfoSnack(`${makeFirstLetterUpper(data.itemType)} renamed!`));
        return data;
    }
);

export const DownloadFile = createAsyncThunk(
    'Documents/DownloadFile',
    async (data: {fileId: string, name: string}, {dispatch, getState, rejectWithValue}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await downloadEvidenceFileApi({fileId: data.fileId, fileName: data.name, workspaceId});
        saveAs(b64toBlob(resp.file), resp.filename)
        return {}
    }
);

//updateDoc
export const UpdateDoc = createAsyncThunk(
    'Documents/updateDoc',
    async (data: {documentId: string | null, id: string, type: string, name: string, folderId: string, innerHtml: string, editor: any, variables: DocVariable[]}, {dispatch, getState, rejectWithValue}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const id = await updateDoc(data.documentId,workspaceId ?? '', data.id, data.type, data.name, data.folderId, data.innerHtml, data.editor, data.variables);
        dispatch(addInfoSnack('Document updated!'));
        return {...data, newId: id};
    }
);

//createNewDoc
export const CreateNewDoc = createAsyncThunk(
    'Documents/CreateNewDoc',
    async (data: CreateNewDocumentMutationVariables, {dispatch, getState, rejectWithValue}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const id = await createNewDoc({...data, workspaceId});
        dispatch(addInfoSnack('Document created!'));
        dispatch(GetFullDocumentWithExecutor({documentId: id, workspaceId: ''}));
        return {...data, id};
    }
);

export const GeneratePdfByHTML = createAsyncThunk(
    'Documents/generatePdfByHTML',
    async (data: GenerateFileByHtmlInput, {dispatch, getState, rejectWithValue}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const id = await generatePdfByHTML({...data, organizationId: organizationId ?? ''}, workspaceId ?? '');

        dispatch(DownloadFile({fileId: id, name: `${data.name}.pdf`}));
    }
);

//EDITOR
export const getDocumentByIdAction = createAsyncThunk(
    'documents/editor/getDocumentById',
    async (data: TWithOptions<GetDocumentByIdQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await getDocumentByIdApi({...data.data, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);
export const getTemplateByIdAction = createAsyncThunk(
    'documents/editor/getTemplateById',
    async (data: TWithOptions<GetOriginTemplateByIdQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await getOriginTemplateByIdApi({...data.data, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const AutocompleteManagersEmailName = createAsyncThunk(
    'documents/autocompleteHrManagers',
    async (data: Omit<AutocompleteEmployeesEmailNameQueryVariables, 'workspaceId' | 'organizationId'>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await autocompleteEmployeesEmailName({...data, workspaceId, organizationId})
        // console.log(resp);
        return {
            data,
            resp
        }
    }
)

export const AutocompleteRecipientsEmailName = createAsyncThunk(
    'documents/AutocompleteRecipientsEmailName',
    async (data: Omit<AutocompleteRecipientsEmailNameQueryVariables, 'workspaceId' | 'organizationId'>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await autocompleteRecipientsEmailNameApi({...data, workspaceId});
        // console.log(resp);
        return {
            data,
            resp
        }
    }
)



export const GetFullDocumentWithExecutor = createAsyncThunk(
    'documents/GetFullDocumentWithExecutor',
    async (data: GetFullDocumentWithExecutorQueryVariables, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await getFullDocumentWithExecutorApi({...data, workspaceId})
        return {
            data,
            resp
        }
    }
)

//
export const RestartSignStageByStageIdAndRecipient = createAsyncThunk(
    'Documents/restartSignStageByStageIdAndRecipient',
    async (data: {documentId: string, stageId: string, recipientEmail: string}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const resp = await restartSignStageByStageIdAndRecipient(workspaceId, data.documentId, data.stageId, data.recipientEmail);
        createEventCommonDialogOpen({type: 'resendInviteRecipient', isOpen: false, data: []});
        dispatch(addInfoSnack('Stage restarted'));
        return resp;
    }
);

export const RestartSignStageByStageIdAndGroupId = createAsyncThunk(
    'Documents/restartSignStageByStageIdAndGroupId',
    async (data: {documentId: string, stageId: string, groupId: string}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await restartSignStageByStageIdAndGroupId(workspaceId, data.documentId, data.stageId, data.groupId);
        createEventCommonDialogOpen({type: 'resendInviteGroup', isOpen: false, data: []});
        dispatch(addInfoSnack('Stage restarted'));
        return resp;
    }
);

export const SendToNextStepByStageId = createAsyncThunk(
    'Documents/sendToNextStepByStageId',
    async ({stageId, documentId}: {stageId: string, documentId: string}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const response = await sendToNextStepByStageId({workspaceId, documentId, stageId});

        dispatch(addInfoSnack('Step skipped'));
        return response;
    }
);

export const InvalidateDocument = createAsyncThunk(
    'Documents/invalidateDocument',
    async (stageId: string, { getState, dispatch }) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const response = await invalidateDocument(workspaceId, stageId);
        createEventCommonDialogOpen({type: 'onInvalidate', isOpen: false, data: []});
        dispatch(addInfoSnack('Document invalidated'));
        return response;
    }
);

//updateFullDocument
export const UpdateFullDocument = createAsyncThunk(
    'Documents/updateFullDocument',
    async (data: {documentId: string, data: NewDocDataInput, name: string, recipients: RecipientInput[], editors: NewDocumentActorInput[], approvers: DocApproverInput[]}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const response = await updateFullDocument(workspaceId, data.documentId, data.data, data.name, data.recipients, data.editors, data.approvers);
        dispatch(addSuccessfulSnack('Document updated'));
        return response;
    }
);

export const handlePreviewBeforeSendAction = createAsyncThunk(
    'Documents/handlePreviewBeforeSendAction',
    async (data: { documentId: string, data: NewDocDataInput, name: string, recipients: RecipientInput[], editors: NewDocumentActorInput[], approvers: DocApproverInput[]}, { getState, dispatch }) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await handlePreviewBeforeSendApi(workspaceId, data.documentId, data.data, data.name, data.recipients, data.editors, data.approvers)
        dispatch(addSuccessfulSnack('Document updated'));
        return resp;
    }
);

//startNewDocumentExecution
export const StartNewDocumentExecution = createAsyncThunk(
    'Documents/StartNewDocumentExecution',
    async (data: { documentId: string, data: NewDocDataInput, name: string, recipients: RecipientInput[], editors: NewDocumentActorInput[], approvers: DocApproverInput[]}, { getState, dispatch }) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await startNewDocumentExecution(workspaceId, data.documentId, data.data, data.name, data.recipients, data.editors, data.approvers)
        dispatch(setMessageDialogAction({message: 'Document sent', title: 'Document'}));
        return resp;
    }
);

export const changeDocumentStageEtaAction = createAsyncThunk(
    'Documents/changeDocumentStageEta',
    async (data: { documentId: string, stageId: string, timeInMs: number}, { getState, dispatch }) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await changeDocumentStageEtaApi({workspaceId, stageId: data.stageId, documentId: data.documentId, timeInMs: data.timeInMs});
        dispatch(addInfoSnack(`ETA increased on ${getDaysFromMs(data.timeInMs)} days`));
        return resp;
    }
);

export const MakeDocDraftCopy = createAsyncThunk(
    'Documents/makeDocDraftCopy',
    async (docId: string, { getState, dispatch }) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const response = await makeDocDraftCopy(workspaceId, docId);
        dispatch(addInfoSnack('Document copy created'));
        return response;
    }
);

export const setDocumentAsEvidenceAssetAction =
    createCommonAsyncThunk<SetDocumentAsEvidenceAssetMutationVariables, SetDocumentAsEvidenceAssetMutation["setDocumentAsEvidenceAsset"]>(
        'Documents', 'setDocumentAsEvidenceAsset', true, false, setDocumentAsEvidenceAsset
    )
