import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import colors from "../../../../../../newShared/theme/colors";
import {useSegregationOfDuties} from "../../../hooks/useSegregationOfDuties";

export const ViewConflictDialog = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        selectedConflict: conflict,
        handleHideViewConflict: handleClose,
        handleOpenAllowConflict,
        handleOpenDenyConflict,

        goToRuleExact,
        dialogs: {viewConflict: isOpen},
    } = useSegregationOfDuties();

    const isRuleExist = conflict?.rule.name.toUpperCase() !== 'NOT EXIST';


    const handleGoToRule = () => {
        if (conflict && isRuleExist) {
            goToRuleExact(conflict.rule.id);
            handleClose();
        }
    }

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('View conflict')}</DialogTitle>
            <DialogContent>
                <Box width={'100%'} display={'flex'} flexDirection={'column'}  borderRadius={'8px'}
                     sx={{ mb: '16px', mt: '16px', p: '8px', boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02);'}}
                >
                    <Box display={'flex'} alignItems={'center'}>
                        <Typography variant={'body2'} sx={{color: colors.text.grey_dark, ml: revDir ? '8px' : undefined, mr: !revDir ? '8px' : undefined}}>{t('Account')}</Typography>
                        <Typography variant={'body2'} sx={{color: colors.text.dark}}>{conflict?.account}</Typography>
                    </Box>
                    <Box display={'flex'} sx={{mt: '8px'}} >
                        <Typography variant={'body2'} sx={{color: colors.text.grey_dark, ml: revDir ? '8px' : undefined, mr: !revDir ? '8px' : undefined}}>{t('Application')}</Typography>
                        <Typography variant={'body2'} sx={{color: colors.text.dark}}>{conflict?.applications.map(a => a.name).join(', ')}</Typography>
                    </Box>
                    <Box display={'flex'} sx={{mt: '8px'}} >
                        <Typography variant={'body2'} sx={{color: colors.text.grey_dark, ml: revDir ? '8px' : undefined, mr: !revDir ? '8px' : undefined}}>{t('Rule name')}</Typography>
                        <Typography variant={'body2'} sx={{color: isRuleExist ? colors.primary.blue : undefined, cursor: isRuleExist ? 'pointer' : undefined}} onClick={handleGoToRule}>{conflict?.rule.name}</Typography>
                    </Box>
                    <Box display={'flex'} sx={{mt: '8px'}}>
                        <Typography variant={'body2'} sx={{color: colors.text.grey_dark, ml: revDir ? '8px' : undefined, mr: !revDir ? '8px' : undefined}}>{t('Severity level')}</Typography>
                        <Typography variant={'body2'} sx={{color: colors.text.dark}}>{conflict?.severityLevel}</Typography>
                    </Box>
                </Box>


            </DialogContent>
            <DialogActions>
                <Button sx={{textTransform: 'none'}} onClick={handleClose} size={'medium'} variant={'text'}>{t('Close')}</Button>
                { isRuleExist &&
                    <>
                        <Button sx={{textTransform: 'none'}} onClick={handleOpenDenyConflict} size={'medium'} color={'secondary'}>{t('Deny')}</Button>
                        <Button sx={{textTransform: 'none'}} onClick={handleOpenAllowConflict} size={'medium'} >{t('Allow')}</Button>
                    </>
                }
            </DialogActions>
        </Dialog>
    )
}
