import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideRequestNotCompleted} from "../../../store/slice";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {getNormalizedStatusName} from "../../../helpers";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const RequestNotCompleted = () => {
    const dispatch = useDispatch();
    const {isOpen, request} = useSelector(dialogs).requestNotCompleted;
    const {t} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.dialogs'});

    const handleClose = () => dispatch(hideRequestNotCompleted());

    return(
        <>
            {request &&
                <Dialog open={isOpen} onClose={handleClose}>
                    <DialogTitle>{t(`Request in status "${getNormalizedStatusName(request.status)}"`)}</DialogTitle>

                    <DialogContent>
                        <Typography lineHeight={'normal'} variant={'body1'}>{t('{{sendTo}} did not fill form yet.', {sendTo: request.sendTo})}</Typography>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose} variant={'text'} size={'medium'}>{t('Close')}</Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    )
}