import React, {ChangeEventHandler, Component, createRef, MouseEventHandler, RefObject} from "react";
import {
    EditorComponentsMethods,
    EditorImageData,
    EditorTableData,
    getToolBarProps,
    MapDispatchToPropsObject,
    TEditorImageElementData
} from "../../types";
import {ToolBarProps} from "../editorToolBar";
import {EditorItemEmptyImageStyled, EditorItemImageStyled} from "./styled";
import {EditorItemBlock} from "../editorItemBlock";
import {EditorPopperButtonsProps} from "../commonComponents/editorPopperButtons";

import {EditorWorkZone} from "../editorWorkzone";
import {AddPhotoAlternate, AlignHorizontalCenter, AlignHorizontalLeft, AlignHorizontalRight} from "@mui/icons-material";
import {toBase64} from "../../../../utils/base64/base64";
import {CircularProgress} from "@mui/material";
import {FlexRow} from "../editorTitleWithActionsRow/styled";
import {
    downloadImageInEditorApi,
    downloadImageInEditorDocsApi,
    downloadImageInEditorPortalApi,
    uploadImageInEditorApi,
    uploadImageInEditorDocsApi,
    uploadImageInEditorPortalApi
} from "../../api";
import {ACCEPT_IMAGE_PROP_WITHOUT_SVG} from "../../../../constants";

interface Props extends getToolBarProps {
    block: TEditorImageElementData;

    parentRef: RefObject<EditorItemBlock>["current"];
    editorWorkZoneRef: RefObject<EditorWorkZone>["current"];
    setPopperButtons: (buttons: EditorPopperButtonsProps["buttons"]) => void;
}
export interface EditorItemImageState {
    id: string;
    type: 'image';
    data: TEditorImageElementData["data"];

    image: {
        loading: boolean;
        src: string | null;
    };

    resize: {
        position: 'tl' | 'tr' | 'br' | 'bl';
        startX: number;
        startWidth: number;
    } | null;
    isFocused?: boolean;

    wrapperRef: RefObject<HTMLDivElement>;
    refs: {
        image: RefObject<HTMLImageElement>;
        imageWrapper: RefObject<HTMLDivElement>;
    };
}

export class EditorItemImage extends Component<Props, EditorItemImageState> implements EditorComponentsMethods<EditorTableData> {
    constructor(props: EditorItemImage["props"]) {
        super(props);

        this.state = {
            id: props.block.id,
            type: 'image',
            data: {
                ...props.block.data,
                align: props.block.data.align || 'left',
            },

            image: {
                loading: !!props.block.data.src?.length,
                src: null,
            },

            resize: null,
            isFocused: false,

            wrapperRef: createRef<HTMLDivElement | HTMLImageElement>(),
            refs: {
                image: createRef<HTMLImageElement>(),
                imageWrapper: createRef<HTMLDivElement>(),
            },
        }
    }

    componentDidMount() {
        this.setButtons();

        const ref = this.state.refs['imageWrapper'].current;
        if (ref && this.state.data.widthPx) {
            ref.style.width = `min(${this.state.data.widthPx}px, ${this.state.wrapperRef.current?.getBoundingClientRect().width! - 10}px)`;
        }

        if (this.state.data.src?.length) {
            this.downloadImage(this.state.data.src);
        }
    }

    async downloadImage(fileId: string) {
        this.setState(prev => ({...prev, image: {loading: true, src: null}}));

        (
            this.props.status.isMain
                ? downloadImageInEditorApi({id: fileId, workspaceId: ''})
                : this.props.status.isPortal
                    ? downloadImageInEditorPortalApi({id: fileId})
                    : downloadImageInEditorDocsApi({id: fileId})
        )
            .then((result) => {
                this.setState(prev => ({...prev, image: {loading: false, src: result?.file || null}}))
            })
            .catch(() => {
                this.setState(prev => ({...prev, image: {loading: false, src: null}}))
            });
    }

    async uploadingImage(file: string) {
        this.setState(prev => ({...prev, image: {loading: true, src: null}}));
        (
            this.props.status.isMain
                ? uploadImageInEditorApi({file, workspaceId: ''})
                : this.props.status.isPortal
                    ? uploadImageInEditorPortalApi({file})
                    : uploadImageInEditorDocsApi({file})
        )
            .then((result) => {
                this.setState(prev => ({...prev, image: {loading: false, src: file.split(',')[1]}, data: {...prev.data, src: result?.message || null}}))
            })
            .catch(() => {
                this.setState(prev => ({...prev, image: {loading: false, src: null, data: {...prev.data, src: null}}}))
            });
    }

    setActiveBlock: EditorWorkZone["setActiveBlock"] = (ids) => {
        // console.log(`IMAGE setActiveBlock`, ids)
        this.props.parentRef?.setActiveBlock([this.state.id, ...ids]);
    };

    setFocus = () =>  {
        !this.state.isFocused && this.setState(prev => ({...prev, isFocused: true}));
    }
    onClickAway = () => {
        this.state.isFocused && this.setState(prev => ({...prev, isFocused: false}));
    }

    ///////ToolBar
    setToolBar: MapDispatchToPropsObject["setToolBar"] = (toolBar, ids) => {
        // this.props.parentRef?.setToolBar(toolBar, [this.state.id, ...ids])
    }
    setTextByToolBar: ToolBarProps["toolBarHandler"] = (action, payload, ids) => {
        // const nextId = ids[0];
        // if (nextId) {
        //     const ref = this.state.refs[nextId]?.current;
        //     if (ref) {
        //         ref.setTextByToolBar(action, payload, ids.slice(1))
        //     }
        // }
    }

    getData = (): Props["block"] => {
        let widthPx = this.state.data.widthPx || 0;
        let heightPx = this.state.data.heightPx || 0;
        const ref = this.state.refs['imageWrapper']?.current;

        if (ref) {
            widthPx = ref.offsetWidth + 6;
            heightPx = ref.offsetHeight + 6;
        }

        return {
            id: this.state.id,
            type: this.state.type,
            data: {
                ...this.state.data,
                widthPx,
                heightPx,
            },
        }
    }

    changeAlign = (align: EditorImageData["align"]) => {
        // console.log(align)
        this.setState(prev => ({
            ...prev, data: {...prev.data, align}
        }))
    }

    uploadImage: ChangeEventHandler<HTMLInputElement> = async (e) => {
        if (e.currentTarget.files?.[0]) {
            const src = await toBase64(e.currentTarget.files?.[0]);

            this.uploadingImage(src);
        }
    }

    setButtons = () => {
        const buttons: EditorPopperButtonsProps["buttons"] = [
            [
                {
                    title: 'Align left',
                    icon: AlignHorizontalLeft,
                    onClick: (e) => {
                        e.stopPropagation();
                        this.changeAlign('left');
                    },
                },
                {
                    title: 'Align center',
                    icon: AlignHorizontalCenter,
                    onClick: (e) => {
                        e.stopPropagation();
                        this.changeAlign('center');
                    },
                },
                {
                    title: 'Align right',
                    icon: AlignHorizontalRight,
                    onClick: (e) => {
                        e.stopPropagation();
                        this.changeAlign('right');
                    },
                },
            ]
        ]

        this.props.setPopperButtons(buttons);
    }

    onResizeParent = () => {
        const ref = this.state.refs['imageWrapper'].current;
        if (ref) {
            ref.style.width = `min(${this.state.data.widthPx}px, ${this.state.wrapperRef.current?.getBoundingClientRect().width! - 10}px)`;
        }
    }

    ///////////
    //Resizing
    getDataForResizing = () => {
        const maxWidth = this.state.wrapperRef.current?.clientWidth || 0;

        const resizableDiv = this.state.refs['imageWrapper'].current!;
        const isIncrease = this.state.resize?.position === 'tr' || this.state.resize?.position === 'br';
        // const resizer = resizableDiv.querySelector(`.${this.state.resize?.position}`) as HTMLDivElement;

        return {maxWidth, resizableDiv, isIncrease};
    };

    mouseDownHandler = (position: 'tl' | 'tr' | 'br' | 'bl'): MouseEventHandler<HTMLDivElement> => (e) => {
        const {
            resizableDiv
        } = this.getDataForResizing();

        const leftResize: EditorItemImageState["resize"] = {startX: e.clientX, startWidth: resizableDiv.offsetWidth, position};
        this.setState(prev => ({...prev, resize: leftResize}));

        document.body.style.cursor = resizableDiv.style.cursor;
        document.onmousemove = this.mouseMoveHandler;
        document.onmouseup = this.mouseUpHandler;

    };

    mouseMoveHandler = (e: MouseEvent) => {
        const {
            resizableDiv, maxWidth, isIncrease
        } = this.getDataForResizing();

        // How far the mouse has been moved
        const x = this.state.resize?.startX || 0;
        const dx = e.clientX - x;

        const leftWidth = this.state.resize;

        if (leftWidth) resizableDiv.style.width = `min(${leftWidth.startWidth + (isIncrease ? dx : -dx)}px, ${maxWidth}px)`;
    };
    mouseUpHandler = (e: MouseEvent) => {
        const {
            resizableDiv
        } = this.getDataForResizing();

        const width = resizableDiv.getBoundingClientRect().width;
        resizableDiv.style.width = `${width}px)`;

        this.setState(prev => ({...prev, resize: null, data: {...prev.data, widthPx: width}}));

        document.body.style.removeProperty('cursor');
        document.onmousemove = null;
        document.onmouseup = null;
    };

    render() {
        const image = this.state.image;

        const imgTag = image.loading ? (
            <FlexRow sx={{
                width: this.state.data.widthPx ? this.state.data.widthPx - 20 : '100%',
                height: this.state.data.heightPx ? this.state.data.heightPx - 20 : '100%',
                alignItems: 'center', justifyContent: 'center'
            }}>
                <CircularProgress/>
            </FlexRow>
        ) : image.src
            ? (
                <img ref={this.state.refs['image']} alt=""
                     src={'data:image/png;base64,' + image.src}/>
            ) : null;

        const shouldHideDots = this.props.readonly || !this.state.isFocused;
        return (
            <EditorItemImageStyled ref={this.state.wrapperRef} align={this.state.data.align} readonly={this.props.readonly} withFile={!!image.src || undefined}
                                   onClick={this.setFocus}>
                <input
                    id={!this.props.readonly ? "contained-button-file-" + this.state.id : 'disabled-' + this.state.id}
                    type="file"
                    style={{display: 'none'}}
                    accept={ACCEPT_IMAGE_PROP_WITHOUT_SVG}
                    onChange={!this.props.readonly ? this.uploadImage : undefined}
                />

                {this.state.data.src?.length ? (
                    <div className={'imageWrapper'}
                         ref={this.state.refs['imageWrapper']}
                         id={this.state.id + 'image'}
                         onClick={() => {

                         }}>

                        {!shouldHideDots ? (
                            <label id={"contained-button-file-label-" + this.state.id} htmlFor={"contained-button-file-" + this.state.id}>
                                {imgTag}
                            </label>
                        ) : imgTag}

                        <div className={'resizerDot tl' + (shouldHideDots ? ' hide' : '')}
                             onMouseDown={!shouldHideDots ? this.mouseDownHandler('tl') : undefined}
                             onClick={e => {e.stopPropagation()}}/>
                        <div className={'resizerDot tr' + (shouldHideDots ? ' hide' : '')}
                             onMouseDown={!shouldHideDots ? this.mouseDownHandler('tr') : undefined}
                             onClick={e => {e.stopPropagation()}}/>
                        <div className={'resizerDot br' + (shouldHideDots ? ' hide' : '')}
                             onMouseDown={!shouldHideDots ? this.mouseDownHandler('br') : undefined}
                             onClick={e => {e.stopPropagation()}}/>
                        <div className={'resizerDot bl' + (shouldHideDots ? ' hide' : '')}
                             onMouseDown={!shouldHideDots ? this.mouseDownHandler('bl') : undefined}
                             onClick={e => {e.stopPropagation()}}/>
                    </div>
                ) : (
                    <label id={"contained-button-file-label-" + this.state.id} htmlFor={"contained-button-file-" + this.state.id}>
                        <EditorItemEmptyImageStyled>
                            <AddPhotoAlternate/>
                            <span>{!this.props.readonly ? 'Click to upload an image' : 'Unable to add image in readonly mode'}</span>
                        </EditorItemEmptyImageStyled>
                    </label>
                )}

            </EditorItemImageStyled>
        );
    }
}