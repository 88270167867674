import React, {FC} from "react";
import {Button, Dialog, DialogActions, DialogTitle} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {CommonAvatar} from "../../../../../../newShared/components/Basic/CommonAvatar";
import * as S from "./styled";
import {useDocuments} from "../../../hooks/useDocuments";
import {TActorEmailName} from "../../../types";


export const RecipientsViewDialog: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage'});

    const {
        recipientsViewDialog: { isOpen, recipients },
        hideShowRecipientsDialog,
    } = useDocuments();

    // const [searchValue, setSearchValue] = useState<string>('');

    // useEffect(() => {
    //     setSearchValue('');
    // }, [isOpen]);

    return (
        <Dialog open={isOpen} onClose={hideShowRecipientsDialog}>
            <DialogTitle>{t("Recipients")}</DialogTitle>
            <S.RecipientsDialogContent>
                {/*<S.RecipientSearchField value={searchValue}*/}
                {/*             onChange={e => setSearchValue(e.target.value)}*/}
                {/*             onClear={() => setSearchValue('')} label={t('Enter email or name')} />*/}
                <S.RecipientsContainer >
                    {recipients && recipients
                        // .filter((e) => (e.actor?.email.toLowerCase().includes(searchValue)
                        //     || e.actor?.firstName?.toLowerCase().includes(searchValue.toLowerCase())
                        //     || e.actor?.lastName?.toLowerCase().includes(searchValue.toLowerCase())) && e.actor?.email.trim().length > 0
                        // )
                        .map((e, index) => e.actor && <RecipientRow actor={e.actor} key={e.actor?.email ?? index.toString()} isLast={index === recipients.length - 1}/>)
                    }

                </S.RecipientsContainer>
            </S.RecipientsDialogContent>
            <DialogActions>
                <Button variant={"contained"} onClick={hideShowRecipientsDialog}>Close</Button>
            </DialogActions>
        </Dialog>
    )

}

const RecipientRow: FC<{actor: TActorEmailName, isLast: boolean}> = ({actor, isLast}) => {
    // const {actor} = recipient;

    return (
        <S.RecipientRowContainer key={actor.email}>
            <S.RecipientRowBox >
                <CommonAvatar name={`${actor.firstName || actor.email} ${actor.lastName || actor.email}`} size={"d40"}/>
                <S.RecipientsDataContainer >
                    { actor.firstName && <S.TypographyName>{`${actor.firstName} ${actor.lastName ?? ''}`}</S.TypographyName>}
                    <S.TypographyEmail >{actor.email ?? ''}</S.TypographyEmail>
                </S.RecipientsDataContainer>
            </S.RecipientRowBox>

            {!isLast && <S.RecipientRowDivider/>}
        </S.RecipientRowContainer>
    )
}
