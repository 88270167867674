import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {PageWrapper} from "../../../../../newShared/components/style";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {MainHrTimeOffRequestModel} from "../../../../../newShared/GQLTypes";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {getTimeOffStatusChip} from "../../helpers";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {IncomingRequestViewDialog} from "../../components/dialogs/incomingRequestViewDialog";
import {useTimeOffRequests} from "../../hooks/useTimeOffRequests";
import {TimeOffRequestExactView} from "../timeOffExactView";
import {TimeOffHistoryDialog} from "../../components/dialogs/timeOffHistory";
import {RequestErrorDialog} from "../../components/dialogs/RequestErrorDialog";
import {SkipTimeOffRequest} from "../../components/dialogs/skipTimeOffRequest";

export const TimeOffRequests = () => {
  const { t } = useMainTranslation("", {
    keyPrefix: "pathHRManagement.pathTimeOff",
  });
  const { isMobile } = useMedia();
  const { requests, handleOpenTimeOff, genericTable } = useTimeOffRequests();

  return (
    <PageWrapper>
      <PageTitleAndActions title={t("Time off requests")} actions={[]} />

      <GenericTable<MainHrTimeOffRequestModel>
        id={"timeOffRequests"}
        columnsConfig={{
          totalName: "Total requests",
          disableColumnsSettings: true,
          forceShowCardsWhen: isMobile,
        }}
        paging={genericTable.paging}
        rowsConfig={{
          rows: requests,
          getRowUniqueId: (row) => row.id,
          onRowClick: handleOpenTimeOff,
          // rowActionsConfig: [],

          columnsConfigs: [
            {key: "id", name: t('ID'), default: true, },
            {key: "requestor", name: t('Requestor'), default: true, valueGetter: (row) => row.requestor.employeeFullName ?? row.requestor.collaboratorFullName },
            {key: 'startDate', name: t('Start date'), default: true, valueGetter: (row) => parseDateAuto(row.startDate, undefined, undefined, undefined, '-')},
            {key: 'endDate', name: t('End date'), default: true, valueGetter: (row) => parseDateAuto(row.endDate, undefined, undefined, undefined, '-')},
            {key: "type", name: t('Time off type'), default: true, },
            {key: 'status', name: t('Status'), default: true, valueRenderer: (row) => getTimeOffStatusChip(row.status)},
            {key: 'requestDate', name: t('Request date'), default: true, valueGetter: (row) => parseDateAuto(row.requestDate, undefined, undefined, undefined, '-')},
          ],
        }}
        filtersConfig={{
            searchCustomFilterKeyName: "timeOffIdLike",
            searchAsArray: true,
            dontSendSearchIfEmpty: true,
            genericFilterProps: {
              configs: genericTable.filters.configs,
              fetchResultWithSelectedFilters: () => null,
              isAddFilters: false,
          },
        }}
        emptyArrayImageProps={{type: "any", filterNotApplied: {text: t("You do not have any time off requests yet"), secondaryText: ""}}}
      />

      <IncomingRequestViewDialog />
      <TimeOffRequestExactView />
      <TimeOffHistoryDialog />
      <SkipTimeOffRequest/>
      <RequestErrorDialog />
    </PageWrapper>
  );
};
