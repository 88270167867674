import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideViewJustification} from "../../../../store/slice";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import colors from "../../../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const ViewJustificationDialog = () => {
    const dispatch = useDispatch();
    const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.CampaignReviewExecutorExact.dialogs'});

    const {
        isOpen,
        email,
        justification
    } = useSelector(dialogs).viewJustification;

    const handleClose = () => {
        dispatch(hideViewJustification());
    }

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t(`Justification for {{account}} account`, {account: email})}</DialogTitle>

            <DialogContent>
                <Typography variant={'body2'} color={colors.darkBlueText}>
                    {justification}
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{t('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}