import {MouseEventHandler, useState} from "react";
import {TFont, TToolBarHandlerAction, TToolBarHandlerPayload} from "../../../types";


export interface UseFontControlProps {
    initialFont?: TFont | null,
    anchorEl: null | HTMLElement,
    changeFontHandler: (action: TToolBarHandlerAction, payload: TToolBarHandlerPayload) => void;
}

export interface UseFontControlValue {
    open: boolean,
    // buttonTitle: string,
    anchorEl: null | HTMLElement,
    handleClose: (item?: TFont) => MouseEventHandler<HTMLLIElement>,
    handleClick: MouseEventHandler<HTMLButtonElement>,
}

export const useFontControl = ({initialFont, anchorEl, changeFontHandler}: UseFontControlProps): UseFontControlValue => {

    const [fontAnchorEl, setFontAnchorEl] = useState<null | HTMLElement>(() => anchorEl);
    // const [buttonTitle, setFontButtonTitle] = useState<string>(() => initialFont || FontsVariants[0]);

    const open = Boolean(fontAnchorEl);

    const handleClick: UseFontControlValue["handleClick"] = (event) => {
        event.stopPropagation();
        changeFontHandler('fontOpenClose', true);
        setFontAnchorEl(event.currentTarget);
    };

    const handleClose: UseFontControlValue["handleClose"] = (item) => (event) => {
        event.stopPropagation();

        setFontAnchorEl(null);
        // item && setFontButtonTitle(item);
        if(item){
            changeFontHandler("fontControl", item);
        }else{
            changeFontHandler('fontOpenClose', false);
        }
    };


    return {
        open,
        // buttonTitle,
        anchorEl: fontAnchorEl,
        handleClick,
        handleClose
    };
}
