import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../redux/rootReducer";
import {NewDocumentModel} from "../../../GQLTypes";
import {GetPreviewTemplateByIdAction} from "./actions";

type TSlice = {
    isOpen: boolean,
    isLoading: {
        isLoadingTemplate: boolean,
    },
    document?: NewDocumentModel,
}

const initialState: TSlice = {
    isOpen: false,
    isLoading: {
        isLoadingTemplate: false,
    },
    document: undefined,
}

const Slice = createSlice({
    name: 'templatePreviewSlice',
    initialState,
    reducers: {
        hidePreviewTemplateAction: (slice) => {
            slice.isOpen = initialState.isOpen;
            slice.document = initialState.document;
            slice.isLoading = initialState.isLoading;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(GetPreviewTemplateByIdAction.pending, (slice: TSlice) => {
                slice.isOpen = true;
                slice.isLoading.isLoadingTemplate = true;
            })
            .addCase(GetPreviewTemplateByIdAction.rejected, (slice: TSlice) => {
                slice.isOpen = initialState.isOpen;
                slice.document = initialState.document;
                slice.isLoading = initialState.isLoading;
            })
            .addCase(GetPreviewTemplateByIdAction.fulfilled, (slice: TSlice, {payload}) => {
                slice.isLoading.isLoadingTemplate = false;
                slice.document = payload;
            })

    }
})

export const templatePreviewReducer = Slice.reducer;
export const {
    hidePreviewTemplateAction
} = Slice.actions;

export const selectSelf = (state: AppState): TSlice => state.templatePreview;
export const templatePreviewSlice = createSelector(selectSelf, state => state);



