import {Box, Divider, Drawer, List, Toolbar} from "@mui/material";
import {FC, useEffect} from "react";
import {useLeftMenu} from "./hooks/useLeftMenu";
import {LeftMenuItem} from "./components/leftMenuItem";
import {useMainTranslation} from "../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const LeftMenu: FC = () => {

    const {
        items, pinnedItems, setPinnedItems,
        isOpen, setOpen,
    } = useLeftMenu();

    useEffect(() => {
        if (pinnedItems) {
            localStorage.setItem('pinnedMenuItems', JSON.stringify(pinnedItems))
        } else {
            setPinnedItems(JSON.parse(localStorage.getItem('pinnedMenuItems') || '[]') as string[])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pinnedItems]);

    const {revDir} = useMainTranslation('', {keyPrefix: 'CommonComponents'})

    return (
        <Drawer
            open={isOpen}
            anchor={!revDir ? 'left' : 'right'}
            SlideProps={{direction: revDir ? 'left' : 'right'}}
            onClose={() => {setOpen(false)}}
            variant={"temporary"}
            sx={{
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { minWidth: '300px' },
            }}
        >
            <Toolbar />
            <Box overflow={'auto'}>
                {pinnedItems && pinnedItems.filter(pin => items.some(i => i.title === pin)).length > 0 && (
                    <>
                        <List>
                            {pinnedItems.filter(e => items.some(item => item.title === e))
                                .map((text) => {
                                    const item = items.find(e => e.title === text);
                                    return item ? <LeftMenuItem key={text + ' ' + true} item={item} pinned/> : null
                                })}
                        </List>
                        <Divider variant={"middle"}/>
                    </>
                )}
                <List>
                    {items.map((item) => {
                        const pinned = pinnedItems?.includes(item.title) ?? false;
                        return item.dontShowInMenu ? null : <LeftMenuItem key={item.title + ' ' + pinned} item={item} pinned={pinned}/>
                    })}
                </List>
            </Box>
        </Drawer>
    )
}