import {AxiosResponse} from "axios";
import {print} from "graphql";
import axiosSync from "../../../../newShared/utils/axios/axios";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {PATH_SERVER} from "../../../../newShared/constants";


import {
    addComment,
    addControlAssignment,
    adoptFrameworkToWorkspace,
    deleteControlAssignment,
    downloadCollectorAssetPdf,
    downloadEvidenceFile,
    downloadReportCollectorAsset,
    getAdoptedFrameworks,
    getAnalysisHistoryByFrameworkIdControlId,
    getAnalysisHistoryByHistoryId,
    getAutoAssetData,
    getAvailableFrameworks,
    getComments,
    getControlAssignments,
    getControlByIdFrameworkId,
    getControlsByFramework,
    getEvidenceAssetById,
    getFrameworkZip,
    getPlaybookHistoryFrameworkDetails,
    mainFrameworksGetDelegatedTaskPerformerAutocomplete,
    mainFrameworksGetDelegatedTaskRequestorAutocomplete,
    mainFrameworksGetDelegatedTaskResolverAutocomplete,
    removeEvidenceAsset,
    removeFrameworkFromWorkspace,
    setEvidenceStatus,
    uploadEvidenceAsset
} from "./query";
import {
    AddCommentMutation,
    AddCommentMutationVariables,
    AddControlAssignmentMutation,
    AddControlAssignmentMutationVariables,
    AdoptFrameworkToWorkspaceMutation,
    AdoptFrameworkToWorkspaceMutationVariables,
    DeleteControlAssignmentMutation,
    DeleteControlAssignmentMutationVariables,
    DownloadCollectorAssetPdfQuery,
    DownloadCollectorAssetPdfQueryVariables,
    DownloadFileQuery,
    DownloadFileQueryVariables,
    DownloadReportCollectorAssetQuery,
    DownloadReportCollectorAssetQueryVariables,
    GetAdoptedFrameworksQuery,
    GetAdoptedFrameworksQueryVariables,
    GetAnalysisHistoryByFrameworkIdControlIdHistoryIdQuery,
    GetAnalysisHistoryByFrameworkIdControlIdHistoryIdQueryVariables,
    GetAnalysisHistoryByFrameworkIdControlIdQuery,
    GetAnalysisHistoryByFrameworkIdControlIdQueryVariables,
    GetAvailableFrameworksQuery,
    GetAvailableFrameworksQueryVariables,
    GetCollectorAssetDataQuery,
    GetCollectorAssetDataQueryVariables,
    GetCommentsQuery,
    GetCommentsQueryVariables,
    GetControlAssignmentsQuery,
    GetControlAssignmentsQueryVariables,
    GetControlByIdFrameworkIdQuery,
    GetControlByIdFrameworkIdQueryVariables,
    GetControlsByFrameworkQuery,
    GetControlsByFrameworkQueryVariables,
    GetEvidenceAssetByIdQuery,
    GetEvidenceAssetByIdQueryVariables,
    GetFrameworkZipQuery,
    GetFrameworkZipQueryVariables,
    GetPlaybookHistoryFrameworkDetailsQuery,
    GetPlaybookHistoryFrameworkDetailsQueryVariables,
    MainFrameworksGetDelegatedTaskPerformerAutocompleteQuery,
    MainFrameworksGetDelegatedTaskPerformerAutocompleteQueryVariables,
    MainFrameworksGetDelegatedTaskRequestorAutocompleteQuery,
    MainFrameworksGetDelegatedTaskRequestorAutocompleteQueryVariables,
    MainFrameworksGetDelegatedTaskResolverAutocompleteQuery,
    MainFrameworksGetDelegatedTaskResolverAutocompleteQueryVariables,
    RemoveEvidenceAssetMutation,
    RemoveEvidenceAssetMutationVariables,
    RemoveFrameworkFromWorkspaceMutation,
    RemoveFrameworkFromWorkspaceMutationVariables,
    SetEvidenceStatusMutation,
    SetEvidenceStatusMutationVariables,
    UploadEvidenceAssetMutation,
    UploadEvidenceAssetMutationVariables,
} from "../../../../newShared/GQLTypes";


export const getAdoptedFrameworksApi = async (data: GetAdoptedFrameworksQueryVariables, signal?: AbortSignal): Promise<GetAdoptedFrameworksQuery["getAdoptedFrameworks"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getAdoptedFrameworks),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAdoptedFrameworks
    })
)

export const getAvailableFrameworksApi = async (data: GetAvailableFrameworksQueryVariables, signal?: AbortSignal): Promise<GetAvailableFrameworksQuery["getAvailableFrameworks"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getAvailableFrameworks),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAvailableFrameworks
    })
)

export const adoptFrameworkToWorkspaceApi = async (data: AdoptFrameworkToWorkspaceMutationVariables, signal?: AbortSignal): Promise<AdoptFrameworkToWorkspaceMutation["adoptFrameworkToWorkspace"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(adoptFrameworkToWorkspace),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.adoptFrameworkToWorkspace
    })
)

export const removeFrameworkFromWorkspaceApi = async (data: RemoveFrameworkFromWorkspaceMutationVariables, signal?: AbortSignal): Promise<RemoveFrameworkFromWorkspaceMutation["removeFrameworkFromWorkspace"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(removeFrameworkFromWorkspace),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.removeFrameworkFromWorkspace
    })
)

export const getControlsByFrameworkApi = async (data: GetControlsByFrameworkQueryVariables, signal?: AbortSignal): Promise<GetControlsByFrameworkQuery["getControlsByFramework"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getControlsByFramework),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getControlsByFramework
    })
)

export const getControlByIdFrameworkIdApi = async (data: GetControlByIdFrameworkIdQueryVariables, signal?: AbortSignal): Promise<GetControlByIdFrameworkIdQuery["getControlByIdFrameworkId"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getControlByIdFrameworkId),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getControlByIdFrameworkId
    })
)

export const setEvidenceStatusApi = async (data: SetEvidenceStatusMutationVariables, signal?: AbortSignal): Promise<SetEvidenceStatusMutation["setEvidenceStatus"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(setEvidenceStatus),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.setEvidenceStatus
    })
)

export const downloadEvidenceFileApi = async (data: DownloadFileQueryVariables, signal?: AbortSignal): Promise<DownloadFileQuery["downloadEvidenceFile"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(downloadEvidenceFile),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.downloadEvidenceFile
    })
)
export const downloadReportCollectorAssetApi = async (data: DownloadReportCollectorAssetQueryVariables, signal?: AbortSignal): Promise<DownloadReportCollectorAssetQuery["downloadReportCollectorAsset"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(downloadReportCollectorAsset),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.downloadReportCollectorAsset
    })
)

export const uploadEvidenceAssetApi = async (data: UploadEvidenceAssetMutationVariables, signal?: AbortSignal): Promise<UploadEvidenceAssetMutation["uploadEvidenceAsset"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(uploadEvidenceAsset),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.uploadEvidenceAsset
    })
)

export const removeEvidenceAssetApi = async (data: RemoveEvidenceAssetMutationVariables, signal?: AbortSignal): Promise<RemoveEvidenceAssetMutation["removeEvidenceAsset"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(removeEvidenceAsset),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.removeEvidenceAsset
    })
)

export const getAutoAssetDataApi = async (data: GetCollectorAssetDataQueryVariables, signal?: AbortSignal): Promise<GetCollectorAssetDataQuery["getAutoAssetData"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getAutoAssetData),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAutoAssetData
    })
)

export const downloadCollectorAssetPdfApi = async (data: DownloadCollectorAssetPdfQueryVariables, signal?: AbortSignal): Promise<DownloadCollectorAssetPdfQuery["downloadCollectorAssetPdf"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(downloadCollectorAssetPdf),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.downloadCollectorAssetPdf
    })
)

export const getEvidenceAssetByIdApi = async (data: GetEvidenceAssetByIdQueryVariables, signal?: AbortSignal): Promise<GetEvidenceAssetByIdQuery["getEvidenceAssetById"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getEvidenceAssetById),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getEvidenceAssetById
    })
)

export const getFrameworkZipApi = async (data: GetFrameworkZipQueryVariables, signal?: AbortSignal): Promise<GetFrameworkZipQuery["getFrameworkZip"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getFrameworkZip),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getFrameworkZip
    })
)

export const getCommentsApi = async (data: GetCommentsQueryVariables, signal?: AbortSignal): Promise<GetCommentsQuery["getComments"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getComments),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getComments
    })
)

export const addCommentApi = async (data: AddCommentMutationVariables, signal?: AbortSignal): Promise<AddCommentMutation["addComment"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(addComment),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addComment
    })
)


export const getControlAssignmentsApi = async (data: GetControlAssignmentsQueryVariables, signal?: AbortSignal): Promise<GetControlAssignmentsQuery["getAssignmentsToControlStore"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getControlAssignments),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAssignmentsToControlStore
    })
)

export const addControlAssignmentApi = async (data: AddControlAssignmentMutationVariables, signal?: AbortSignal): Promise<AddControlAssignmentMutation["addAssignmentToControl"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(addControlAssignment),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addAssignmentToControl
    })
)

export const deleteControlAssignmentApi = async (data: DeleteControlAssignmentMutationVariables, signal?: AbortSignal): Promise<DeleteControlAssignmentMutation["deleteAssignmentToControl"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(deleteControlAssignment),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteAssignmentToControl
    })
)

export const getAnalysisHistoryFRApi = async (data: GetAnalysisHistoryByFrameworkIdControlIdQueryVariables, signal?: AbortSignal): Promise<GetAnalysisHistoryByFrameworkIdControlIdQuery["getAnalysisHistoryByFrameworkIdControlId"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getAnalysisHistoryByFrameworkIdControlId),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAnalysisHistoryByFrameworkIdControlId
    })
)

export const getAnalysisHistoryByHistoryIdApi = async (data: GetAnalysisHistoryByFrameworkIdControlIdHistoryIdQueryVariables, signal?: AbortSignal): Promise<GetAnalysisHistoryByFrameworkIdControlIdHistoryIdQuery["getAnalysisHistoryByFrameworkIdControlIdHistoryId"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getAnalysisHistoryByHistoryId),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAnalysisHistoryByFrameworkIdControlIdHistoryId
    })
)

export const getPlaybookHistoryDetailsFRApi = async (data: GetPlaybookHistoryFrameworkDetailsQueryVariables, signal?: AbortSignal): Promise<GetPlaybookHistoryFrameworkDetailsQuery["getPlaybookHistoryDetails"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getPlaybookHistoryFrameworkDetails),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getPlaybookHistoryDetails
    })
)


export const mainFrameworksGetDelegatedTaskPerformerAutocompleteApi = async (data: MainFrameworksGetDelegatedTaskPerformerAutocompleteQueryVariables, signal?: AbortSignal): Promise<MainFrameworksGetDelegatedTaskPerformerAutocompleteQuery["mainFrameworksGetDelegatedTaskPerformerAutocomplete"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(mainFrameworksGetDelegatedTaskPerformerAutocomplete),
                variables: data,
            },
            {
                headers: buildCommonHeader(),
                // signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.mainFrameworksGetDelegatedTaskPerformerAutocomplete;
    })
)

export const mainFrameworksGetDelegatedTaskResolverAutocompleteApi = async (data: MainFrameworksGetDelegatedTaskResolverAutocompleteQueryVariables, signal?: AbortSignal): Promise<MainFrameworksGetDelegatedTaskResolverAutocompleteQuery["mainFrameworksGetDelegatedTaskResolverAutocomplete"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(mainFrameworksGetDelegatedTaskResolverAutocomplete),
                variables: data,
            },
            {
                headers: buildCommonHeader(),
                // signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.mainFrameworksGetDelegatedTaskResolverAutocomplete;
    })
)

export const mainFrameworksGetDelegatedTaskRequestorAutocompleteApi = async (data: MainFrameworksGetDelegatedTaskRequestorAutocompleteQueryVariables, signal?: AbortSignal): Promise<MainFrameworksGetDelegatedTaskRequestorAutocompleteQuery["mainFrameworksGetDelegatedTaskRequestorAutocomplete"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(mainFrameworksGetDelegatedTaskRequestorAutocomplete),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                // signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.mainFrameworksGetDelegatedTaskRequestorAutocomplete;
    })
)
