import colors from "../../../newShared/theme/colors";
import {Config} from "./types";

export const getNormalizedStatus = (status: string): {status: string, color: string} => {
    switch (status) {
        case "COMPLETED":
            return {status: 'Completed', color: colors.text.success};
        case "FAILED":
            return {status: 'Failed', color: colors.text.critical};
        case "Inactive":
            return {status: 'Inactive', color: ''};
        case "IN_PROGRESS":
            return {status: 'In progress', color: colors.decorative.orange};
        //app history status
        case "SUCCESS": return {status: 'Success', color: colors.text.success};
        default: return {status: 'Status not found', color: 'black'}
    }
}

export const rawDataValueGetter = (object: { [key: string]: string }, config: Config | null | undefined, collectionAlias: string, index: number): string => {
    // console.log(` \n
    // rawDataValueGetter(object: ${JSON.stringify(object)} \n
    // config: ${config ?? 'Config is null'}
    // collectionAlias: ${collectionAlias}
    // index: ${index}
    // )`);

    if(!config || !config.collectionConfigs){
        // console.log(`Config or config.collectionConfigs is null`);
        return 'Representation not found! Config is null.';
    }
    const currConfig = config.collectionConfigs.find(e => e.alias === collectionAlias);
    // console.log(`currConfig: ${JSON.stringify(currConfig)}`)
    if(currConfig){
        let obj: any = object;
        let splittedGetter: string[];
        const splitted = currConfig.data[index]?.split('.') || [];
        if(splitted.length > 3){ //name1.name2.name3>.name4.name5
            splittedGetter = splitted.slice(0, 2); //name1,name2,name.3
            splittedGetter.push(splitted.slice(3).join('.')); //add name4.name5
        }else{
            splittedGetter = splitted;
        }
        // if(currConfig.data[index].split('.');)
        splittedGetter.forEach(e => {
            try{
                obj = obj[e];
                // console.log(`${e} => ${JSON.stringify(obj)}`);
            }catch (ex){
                console.log(`rawDataValueGetter ERROR: ${e} => ${JSON.stringify(obj)}`);
            }
        })
        return obj ?? 'Field not found.';
    }
    // console.log(`currConfig not found.`);
    return 'currConfig not found.';
    // const collectionAliasEntries: [string, string][] = Object.entries(config.collectionAliases); //some-roles: Some roles
    // // console.log(`collectionAliasEntries: ${JSON.stringify(collectionAliasEntries)}`);
    // const collectionName: [string, string] | undefined = collectionAliasEntries.find(e => e[1] === collectionAlias);
    // if(collectionName){
    //     const originCollectionName = collectionName[0];
    //     const getterEntries: [string, string][] = Object.entries(config.collectionRepresentationField);
    //     // console.log(`getterEntries: ${getterEntries}`);
    //     const neededGetter = getterEntries.find(e => e[0] === originCollectionName); //searching for getter by original collection name
    //     // console.log(`neededGetter: ${neededGetter}`);
    //     if(neededGetter){
    //         const getter = neededGetter[1]; // roles-collection: event[0].name => event[0].name
    //         // console.log(`getter: ${getter}`);
    //         const splittedGetter = getter.split('.');
    //         // console.log(`splittedGetter: ${splittedGetter}`);
    //         let obj: any = object;
    //         splittedGetter.forEach(e => {
    //             try{
    //                 obj = obj[e];
    //                 // console.log(`${e} => ${JSON.stringify(obj)}`);
    //             }catch (ex){
    //                 console.log(`ERROR: ${e} => ${JSON.stringify(obj)}`);
    //             }
    //         })
    //         return obj ?? 'Field not found.';
    //     }else{
    //         return 'neededGetter not found!';
    //     }
    // }else{
    //     return 'collectionName not found!';
    // }
}