import React, {FC} from "react";
import {
    Box,
    Button,
    IconButton,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {DeleteForever, Edit} from "@mui/icons-material";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import colors from "../../../../../newShared/theme/colors";
import {TableRowSkeleton} from "../../../../../newShared/components/tableRowSkeleton";
import {MinimizeInfoWrapper} from "../../../../../newShared/components/minimizeInfoWrapper";
import {PageWrapper} from "../../../../../newShared/components/Layouts";
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import {useControlExact} from "../../hooks/useControlExact";
import {EditControlDialog} from "../../components/dialogs/controls/editControlDialog";
import {DeleteControlDialog} from "../../components/dialogs/controls/deleteControlDialog";
import {AddEvidenceDialog} from "../../components/dialogs/evidences/addEvidenceDialog";
import EditIcon from "@mui/icons-material/Edit";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import {EditEvidenceDialog} from "../../components/dialogs/evidences/editEvidenceDialog";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {TableCardSkeleton} from "../../../../../newShared/components/tableCardSkeleton";
import {GenericFiltersArray} from "../../../../../newShared/components/genericFilter";

export const ControlExact: FC = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'builderPage'});
    const {isLessThen1050} = useMedia();

    const {
        isLoadingControls: isLoadingControl,
        controlId,
        control, evidences,
        handleGoBack,
        handleOpenAddControlEvidence,
        handleOpenDeleteControl,
        openEditFrameworkControlDialog,
        handleOpenEditEvidence,

        isEmptyEvidences,

        deattachEvidence,
        currentSearchForFetch
    } = useControlExact();

    // const {handleThreeDotsOpen, handleThreeDotsClose, anchorThreeDotsEl} = useThreeDotsMenu();

    // const {filter} = useParameters();

    //Pagination and filtering
    // const {value: searchValue, handleChange} = useSearchFieldURL({wait: 0});
    // const {setFilter, handleKeyDown} = useFilterUrl({
    //     getData: (filters) => {}
    // });

    return (
        <PageWrapper>

            <PageTitleAndActions
                title={control?.name}
                handleGoBack={handleGoBack}
                showMenuWhen={isLessThen1050}
                actions={[
                    {
                        title: t('Edit information'),
                        onClick: () => controlId && openEditFrameworkControlDialog(controlId),
                        variant: 'text',
                        icon: <Edit style={{margin: revDir ? '0 0 0 9px' : undefined}}/>,
                        disabled: isLoadingControl || !control
                    },
                    {
                        title: t('Delete control'),
                        onClick: () => controlId && handleOpenDeleteControl(controlId),
                        variant: 'text',
                        icon: <DeleteForever/>,
                        disabled: isLoadingControl || !control
                    },
                ]}
            />

            {/*<Breadcrumbs/>*/}
            {/*<Box display={"flex"} alignItems={"center"} sx={{mt: '8px'}}>*/}
            {/*    {(isLoadingControl || isLoading) && (!control || control?.id !== controlId)*/}
            {/*        ? (*/}
            {/*            <>*/}
            {/*                /!*<Skeleton variant={"rounded"} width={'40px'} height={'40px'}/>*!/*/}
            {/*                <Typography variant={!matches_1440Down ? "h1" : 'h3'} margin={!revDir ? '0 32px 0 8px' : '0 8px 0 32px'}><Skeleton width={'200px'}/></Typography>*/}
            {/*            </>*/}
            {/*        ) : (*/}
            {/*            <>*/}
            {/*                /!*{framework?.logo && <img src={framework.logo} alt={'logo'} style={{maxWidth: '40px', maxHeight: '40px'}}/>}*!/*/}
            {/*                <Typography variant={!matches_1440Down ? "h1" : 'h3'} margin={!revDir ? '0 32px 0 8px' : '0 8px 0 32px'}>{control?.name || ''}</Typography>*/}
            {/*            </>*/}
            {/*        )*/}
            {/*    }*/}
            {/*    {!isLessThen1050 ? (control?.id === controlId && !control?.origin) && (*/}
            {/*        <>*/}
            {/*            <Button sx={{margin: '0 8px'}} variant={"text"} startIcon={<Edit style={{margin: revDir ? '0 0 0 9px' : undefined}}/>} size={matches_1440Down ? 'medium' : undefined}*/}
            {/*                    onClick={(e) => controlId && openEditFrameworkControlDialog(controlId)}>*/}
            {/*                {t('Edit information')}*/}
            {/*            </Button>*/}
            {/*            <Button sx={{margin: '0 8px'}} variant={"text"} startIcon={<DeleteForever/>} size={matches_1440Down ? 'medium' : undefined}*/}
            {/*                    onClick={(e) => controlId && handleOpenDeleteControl(controlId)}>*/}
            {/*                {t('Delete control')}*/}
            {/*            </Button>*/}
            {/*        </>*/}
            {/*    ) : (control?.id === controlId && !control?.origin) && (*/}
            {/*        <>*/}
            {/*            <IconButton*/}
            {/*                onClick={handleThreeDotsOpen}*/}
            {/*                size={"large"}*/}
            {/*                sx={{*/}
            {/*                    color: Boolean(anchorThreeDotsEl) ? colors.primary.blue : colors.text.grey_dark,*/}
            {/*                    margin: !revDir ? '0 0 0 auto' : '0 auto 0 0'*/}
            {/*                }}*/}
            {/*            >*/}
            {/*                <MoreVert/>*/}
            {/*            </IconButton>*/}
            {/*            <Menu*/}
            {/*                keepMounted*/}
            {/*                anchorEl={anchorThreeDotsEl}*/}
            {/*                anchorOrigin={{*/}
            {/*                    vertical: 'bottom',*/}
            {/*                    horizontal: 'right',*/}
            {/*                }}*/}
            {/*                transformOrigin={{*/}
            {/*                    vertical: 'top',*/}
            {/*                    horizontal: 'right',*/}
            {/*                }}*/}
            {/*                open={Boolean(anchorThreeDotsEl)}*/}
            {/*                onClose={handleThreeDotsClose}*/}
            {/*            >*/}
            {/*                <MenuItem onClick={(e) => controlId && openEditFrameworkControlDialog(controlId)}>*/}
            {/*                    {t('Edit information')}*/}
            {/*                </MenuItem>*/}
            {/*                <MenuItem onClick={(e) => {controlId && handleOpenDeleteControl(controlId)}} >*/}
            {/*                    {t('Delete control')}*/}
            {/*                </MenuItem>*/}
            {/*            </Menu>*/}
            {/*        </>*/}
            {/*    )}*/}
            {/*</Box>*/}

            <MinimizeInfoWrapper margin={'16px 0 0 0'}>
                {isLoadingControl && (!control || control.id !== controlId) ? (
                    <>
                        <Typography variant={"body1"} color={colors.text.grey_dark} mt={'16px'}>
                            <Skeleton width={'100px'}/>
                        </Typography>
                        <Typography variant={"body1"} color={colors.text.grey_dark} mt={'16px'}>
                            <Skeleton width={'100px'}/>
                        </Typography>
                        <Typography variant={"body1"} color={colors.text.grey_dark} mt={'16px'}>
                            <Skeleton width={'100px'}/>
                        </Typography>
                        <Typography variant={"body2"} color={colors.text.grey_dark} maxWidth={'800px'} margin={'6px 0 8px'}>
                            <Skeleton/>
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography variant={"body1"} color={colors.text.grey_dark} mt={'16px'}>
                            {t('Category')}
                            <Typography variant={"body1"} color={colors.text.dark} m={'0 8px'} component={"span"}>
                                {control ? control.category : ''}
                            </Typography>
                        </Typography>
                        <Typography variant={"body1"} color={colors.text.grey_dark} mt={'16px'}>
                            {t('Evidences')}
                            <Typography variant={"body1"} color={colors.text.dark} m={'0 8px'} component={"span"}>
                                {control ? control.evidences?.length : ''}
                            </Typography>
                        </Typography>
                        <Typography variant={"body2"} color={colors.text.grey_dark} maxWidth={'800px'} margin={'6px 0 8px'} lineHeight={'22px'}>
                            {control ? control.description : ''}
                        </Typography>
                    </>
                )}
            </MinimizeInfoWrapper>

            <GenericFiltersArray
                configs={[]}
                fetchResultWithSelectedFilters={() => null}
                isAddFilters={false}
            />
            {/*<SearchField onClear={() => {setFilter('input'); handleChange({target: {value: ''}})}}*/}
            {/*             value={searchValue} onKeyDown={handleKeyDown} onChange={handleChange} disabled={isLoadingControl || isLoading}*/}
            {/*             sx={{marginTop: '16px', maxWidth: !isMobile ? '320px' : undefined}}/>*/}

            {!control?.origin && (
                <Button startIcon={<LibraryAddOutlinedIcon/>} variant={'text'} size={'small'}
                        sx={{mt: '16px', alignSelf: 'start'}}
                        onClick={(e) => controlId && handleOpenAddControlEvidence(e, controlId)}
                >
                    {t('Add evidence')}
                </Button>
            )}

            <TableContainer>
                <Table stickyHeader>
                    {isLessThen1050 ?
                        <TableBody>
                            { evidences
                                ?.filter(e =>
                                    e?.name.trim().toLowerCase().includes(currentSearchForFetch.trim().toLowerCase())
                                    || e?.categories?.some(e => e.trim().toLowerCase().includes(currentSearchForFetch.trim().toLowerCase()))
                                )
                                .map((ev) => control && ev && (
                                    <TableRow key={ev.id}>
                                        <TableCell style={{ padding: 0, borderBottom: 'none' }}>
                                            <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} sx={{borderRadius: '8px', m: '6px', p: '8px', boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)'}}>
                                                <Box display={'flex'} >
                                                    <Typography variant={'body2'} sx={{color: colors.lightGrayText, whiteSpace: 'nowrap'}}>{t('Name')}</Typography>
                                                    <Typography variant={'body2'} sx={{color: colors.darkText, ml: '6px', mr: '6px'}}>{ev.name}</Typography>
                                                </Box>
                                                <Box display={'flex'} >
                                                    <Typography variant={'body2'} sx={{color: colors.lightGrayText, whiteSpace: 'nowrap'}}>{t('Category')}</Typography>
                                                    <Typography variant={'body2'} sx={{color: colors.darkText, ml: '6px', mr: '6px'}}>{ev?.categories?.[0] || ''}</Typography>
                                                </Box>
                                                <Box display={'flex'} >
                                                    <Typography variant={'body2'} sx={{color: colors.lightGrayText, whiteSpace: 'nowrap'}}>{'Description'}</Typography>
                                                    <Typography variant={'body2'} sx={{color: colors.darkText, ml: '6px', mr: '6px'}}>{ev.description}</Typography>
                                                </Box>
                                                {!control?.origin &&
                                                    <Box display={"flex"} alignSelf={'flex-end'}>
                                                        <Button size={'small'} variant={'text'} startIcon={<EditIcon/>} sx={{textTransform: 'none', ml: '6px', mr: '6px'}} onClick={() => ev?.id && handleOpenEditEvidence(ev.id, ev)} >{t('Edit')}</Button>
                                                        <Button size={'small'} variant={'text'} startIcon={<LinkOffIcon/>} sx={{textTransform: 'none'}} onClick={() => control?.id && ev?.id && deattachEvidence(control.id, ev.id)} >{t('Detach')}</Button>
                                                    </Box>
                                                }
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }

                            {isLoadingControl && <TableCardSkeleton />}

                            {isEmptyEvidences && <Typography sx={{margin: '16px'}} variant={'body1'}>{currentSearchForFetch.trim().length > 0 ? ('Nothing was found for your query') : t('You have not added any evidences yet.')}</Typography> }
                        </TableBody>
                        :
                        <>
                            <TableHead>
                                <TableRow>
                                    <TableCell align={revDir ? "right" : 'left'}>{t('Evidence name')}</TableCell>
                                    <TableCell align={revDir ? "right" : 'left'}>{t('Category')}</TableCell>
                                    <TableCell align={revDir ? "right" : 'left'}>{t('Evidence description')}</TableCell>
                                    {!control?.origin && <TableCell/>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {evidences
                                    ?.filter(e =>
                                        e?.name.trim().toLowerCase().includes(currentSearchForFetch.trim().toLowerCase())
                                        || e?.categories?.some(e => e.toLowerCase().trim().includes(currentSearchForFetch.trim().toLowerCase()))
                                    )
                                    .map((ev) => control && ev && (
                                        <TableRow key={ev.id} >
                                            <TableCell align={revDir ? "right" : 'left'}>{ev.name}</TableCell>
                                            <TableCell align={revDir ? "right" : 'left'}>{ev?.categories?.[0] || ''}</TableCell>
                                            <TableCell align={revDir ? "right" : 'left'}>{ev.description}</TableCell>
                                            {!control?.origin && (
                                                <TableCell sx={{padding: 0}}>
                                                    <Box display={"flex"} alignItems={"center"}>
                                                        <IconButton size={"medium"} onClick={() => ev?.id && handleOpenEditEvidence(ev.id, ev)} >
                                                            <EditIcon/>
                                                        </IconButton>
                                                        <IconButton size={"medium"} onClick={() => control?.id && ev?.id && deattachEvidence(control.id, ev.id)}>
                                                            <LinkOffIcon/>
                                                        </IconButton>
                                                    </Box>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}

                                {isEmptyEvidences && <Typography sx={{margin: '16px'}} variant={'body1'}>{currentSearchForFetch.trim().length > 0 ? ('Nothing was found for your query') : t('You have not added any evidences yet.')}</Typography> }

                                {isLoadingControl && <TableRowSkeleton columns={4}/>}
                            </TableBody>
                        </>
                    }
                </Table>
            </TableContainer>

            <AddEvidenceDialog />
            <DeleteControlDialog />
            <EditControlDialog />
            <EditEvidenceDialog />
        </PageWrapper>
    )
}