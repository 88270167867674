import {useDispatch, useSelector} from "react-redux";
import {
    dialogs,
    hideShowFullReport,
    kycDictionaries,
    loadings,
    openCreateException,
    openDeleteTargetException,
    screeningsSelector
} from "../../../store/slice";
import {useEffect, useState} from "react";
import {
    CreateTargetException,
    GetKycOtsFullResultByEntityId,
    GetKycScreeningFullResultByEntityId
} from "../../../store/actions";
import {OpenSanctionsObjectType} from "../../../types";
import {saveAs} from "file-saver";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {
    CORPORATE_TARGET_TYPE,
    CRYPTOWALLET_TARGET_TYPE,
    PERSON_TARGET_TYPE,
    topicsDictionary
} from "../../../constants";
import {getTargetName} from "../../../helpers";
// Define the types of tabs that can be shown in the full report
// Each type corresponds to a different section of the report
// The available tabs vary depending on whether the subject is a person, a corporation, or a crypto wallet

export type showFullReportTabs =
    'Crypto wallet info' |
    'Crypto wallet datasets' | 'Holder info' |
    'General info' | 'Position' |
    'Name and aliases' | 'Addresses' |
    'Notes' | 'Sanctions' |
    'Directorship' | 'Education' |
    'Family' | 'Associates' |
    'Owners' | 'Subsidiaries' |
    'Clients' |
    'Securities' | 'Datasets' | 'Links' | 'Identification' | 'Crypto wallets';
;

export const availableTabsPerson: showFullReportTabs[] = [
    'General info', 'Position',
    'Name and aliases', 'Identification', 'Addresses',
    'Notes', 'Sanctions',
    'Directorship',
    'Education', 'Family', 'Associates', 'Datasets', 'Links', 'Crypto wallets'
]

export const availableTabsCorporate: showFullReportTabs[] = [
    'General info',
    'Name and aliases', 'Addresses',
    'Notes', 'Sanctions',
    'Owners', 'Subsidiaries', 'Clients',
    'Securities', 'Datasets', 'Links', 'Crypto wallets'
]

export const availableTabsCryptoWallet: showFullReportTabs[] = [
    'Crypto wallet info',
    'Crypto wallet datasets', 'Holder info',
    'Name and aliases', 'Identification', 'Addresses',
    'Notes', 'Sanctions',
    'Directorship',
    'Education', 'Family', 'Associates', 'Datasets', 'Links'
]


export const useShowFullReport = () => {
    const dispatch = useDispatch();

    const kycDicts = useSelector(kycDictionaries);
    const {isOpen, entityId, otsResponse} = useSelector(dialogs).showFullReportDialog;
    const {createException, getScreeningResultExact} = useSelector(loadings);
    const {selectedScreeningSelectedResult, selected, selectedScreeningResults} = useSelector(screeningsSelector);
    const result = selectedScreeningSelectedResult;

    useEffect(() => {
        if(isOpen && entityId && selected){
            dispatch(GetKycScreeningFullResultByEntityId({data: {entityId, screeningId: selected.screening.id}}));
            switch (selected.target.targetType){
                case CRYPTOWALLET_TARGET_TYPE: {
                    setTab('Crypto wallet info');
                    return;
                }
                default: setTab('General info');
            }
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const handleClose = () => {
        dispatch(hideShowFullReport());
        setTab('General info');
    }

    const [tab, setTab] = useState<showFullReportTabs>('General info');

    const getResult = (): OpenSanctionsObjectType | null => {
        if(result){
            try{
                return JSON.parse(result) as OpenSanctionsObjectType
            }catch (ex){
                return null;
            }
        }
        return null;
    }

    const handleDownload = () => {
        const res = getResult();
        if(res && result && selected){
            const bytes = new TextEncoder().encode(result); //json result
            const blob = new Blob([bytes], {
                type: "application/json;charset=utf-8"
            });
            const fileName = `${res.caption}_${parseDateAuto(selected.screening.startDate, true)}.json`
            saveAs(blob, fileName);
        } else if(otsResponse && entityId){
             dispatch(GetKycOtsFullResultByEntityId({data: {entityId, fileName: `${otsResponse.results?.find(e => e.entityId === entityId)?.entityCaption ?? 'one_time_screening_result'}_${parseDateAuto(otsResponse.startDate, true)}.json`}}));
        }
    }

    const currentEntity = selectedScreeningResults.find(e => e.entityId === entityId);
    const currentEntityScore = currentEntity?.score ? currentEntity?.score : otsResponse?.matchingRatio ? otsResponse?.matchingRatio : 0;

    const createQuickException = () => {
        const res = currentEntity;
        if(res){
            (selected && res) &&  dispatch(CreateTargetException({
                data: {
                    targetId: selected.screening.targetId,
                    checkId: selected.screening.checkId,
                    screeningId: selected.screening.id,
                    entityId: res.entityId,
                    entityCaption: res.entityCaption,
                    score: currentEntityScore,
                    reason: '',
                    limited: false,
                    till: null,
                },
            }));
        }
    }

    const createCustomException = () => {
        const res = getResult();
        if(res && entityId){
            dispatch(openCreateException({entityId, entityCaption: res.caption}));
        }
    }

    const handleDeleteException = () => {
        selected && currentEntity && currentEntity.exceptionId && dispatch(openDeleteTargetException({targetId: selected.screening.targetId, exceptionId: currentEntity.exceptionId}));
    }

    const isPerson = selected ? selected.target.targetType === PERSON_TARGET_TYPE : (otsResponse?.targetType === PERSON_TARGET_TYPE ?? false);
    const isCorporate = selected ? selected.target.targetType === CORPORATE_TARGET_TYPE : (otsResponse?.targetType === CORPORATE_TARGET_TYPE ?? false);
    const isCryptoWallet = selected ? selected.target.targetType === CRYPTOWALLET_TARGET_TYPE : (otsResponse?.targetType === CRYPTOWALLET_TARGET_TYPE ?? false);

    const getCurrentTabs = ():showFullReportTabs[] => {
        if(isPerson) return availableTabsPerson;
        if(isCorporate) return availableTabsCorporate;
        if(isCryptoWallet) return availableTabsCryptoWallet;
        return availableTabsPerson;
    }

    const getClientNameTitle = (): string => {
        if(isCryptoWallet) return `Crypto wallet public key`;
        return `Client name`;
    }

    const _result = getResult();

    return{
        isOpen,
        isOneTimeScreening: !selected && !!otsResponse,
        isLoading: getScreeningResultExact,
        result: _result,
        currentEntityScore,
        handleClose,
        tab,
        setTab,
        handleDownload,
        currentEntity,
        createQuickException,
        createCustomException,
        handleDeleteException,
        isLoadingQuickException: createException,
        exception: currentEntity ? selected?.exceptions?.find(e => e.id === currentEntity.exceptionId) : null,
        isPerson,
        isCorporate,
        isCryptoWallet,
        countryDict: kycDicts.country,
        currentTabsArray: getCurrentTabs(),
        clientNameTitle: getClientNameTitle(),
        targetName: selected ? getTargetName(selected.target) : (otsResponse?.targetName ?? ''),
        topics: _result ? (_result.properties.topics ?? []).map(e => topicsDictionary.find(f => f.key === e)?.value ?? e) : []
    }
}
