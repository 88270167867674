import {MainTrainingsQuestionsShortDataForImportResponseModel} from "../../../../../newShared/GQLTypes"
import {useSelector, useDispatch} from "react-redux";
import {questionsToAddSelector, deselectExistingQuestion, selectExistingQuestion} from "../../store/slice";
import React, {useState} from "react";

export const useQuestionsBlock = (questionBlock: MainTrainingsQuestionsShortDataForImportResponseModel) => {
    const dispatch = useDispatch();
    const {selectedQuestions} = useSelector(questionsToAddSelector);
    const [isExpanded, setIsExpanded] = useState(false);
    const isCheckboxChecked = questionBlock.questions?.every(e => selectedQuestions.some(a => e.questionId === a.questionId));
    const isCheckboxUnchecked = !questionBlock.questions?.some(e => selectedQuestions.some(a => e.questionId === a.questionId));
    const isCheckboxIndeterminate = !isCheckboxUnchecked && !isCheckboxChecked;
    const handleCheckboxSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsExpanded(true);
        if(questionBlock.questions?.every(e => selectedQuestions.some(a => a.questionId === e.questionId))) {
            questionBlock.questions?.forEach(e => dispatch(deselectExistingQuestion(e)));
        } else {
            questionBlock.questions?.forEach(e => dispatch(selectExistingQuestion(e)));
        }

    }

    return {
        isCheckboxChecked,
        isCheckboxIndeterminate,
        handleCheckboxSelect,
        isExpanded,
        setIsExpanded,
    }
}