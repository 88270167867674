import {SHOW_LOGGER} from "../../constants";

export const Logging = {
    log: (...obj: any[]) => {
        console.log('----------------------------------------------')
        if (SHOW_LOGGER) {
            console.log(`LOGGER: [${new Date().toISOString()}]`, ...obj);
            console.log('----------------------------------------------')
        }
    },
    error: (obj: any) => {
        if (SHOW_LOGGER) {
            console.error(`LOGGER: [${new Date().toISOString()}]`, ...obj);
        }
    }
}
