import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    ACCOUNT_DELETE_STATUS,
    ACCOUNT_KEEP_STATUS,
    ACCOUNT_REVOKE_STATUS,
    COMPLETE_STATUS,
    defaultExpands,
    IN_PROGRESS_STATUS,
    PATH_ACCESS_MANAGEMENT_REVIEW_EXECUTORS_EXACT,
    PATH_ACCESS_MANAGEMENT_REVIEW_EXECUTORS_LIST,
    PATH_LOCAL_ACCESS_MANAGEMENT,
    REVIEW_CAMPAIGN_STEP_EXECUTOR,
    REVIEW_CAMPAIGN_STEP_MANAGER,
    REVIEW_CAMPAIGN_STEP_SIGN,
    REVIEW_CAMPAIGN_STEP_VENDOR,
    REVIEW_CAMPAIGN_STEP_VERIFY,
    STAGE_SEQUENCE,
    TERMINATED_STATUS
} from "../../../../constants";
import {
    clearSelectedCampaignReviewExecutor,
    loadings,
    openAddOrEditJustificationDialog,
    openDeclineVerifyStepDialog,
    openShowPermissions,
    openShowVerifyApplicationRevokeReason,
    openTerminateDialog,
    openViewJustification,
    selectedExecutor
} from "../../../../store/slice";
import React, {useEffect, useState} from "react";
import {
    applicationAccessDataWithoutAccounts,
    campaignReviewExecutorAccountFillableType,
    campaignReviewExecutorStageType,
    campaignReviewExecutorType,
    expandsType,
    REVIEW_CAMPAIGN_STEPS
} from "../../../../types";
import {calculateCurrentStage, mapAppsByAccountIds} from "../../../../helpers";
import {
    AssignStageToMainControl,
    DownloadReport,
    GetFullCampaignReviewExecutorById,
    UpdateCampaignReviewRows,
    VerifyActorChanges
} from "../../../../store/actions";
import {usePDFView} from "../../../../../../../newShared/components/docViewer/hooks/usePDFView";

export const useCampaignReviewExecutorExact = () => {
    //root
    const dispatch = useDispatch();
    const history = useHistory();
    const {id} = useParams<{id: string}>();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const {handleOpen} = usePDFView();
    //controller
    const controller = new AbortController();
    //selectors
    const executor: campaignReviewExecutorType | null = useSelector(selectedExecutor);
    const {
        campaignReviewExecutorsExact,
        campaignReviewUpdatingRowsWithIds,
        campaignReviewAssignToMeEmails,
        campaignReviewVerifyChangesForEmails
    } = useSelector(loadings);


    //useEffect
    useEffect(() => {
        dispatch(GetFullCampaignReviewExecutorById({data: {id}, signal: controller.signal}));

        return () => {
            dispatch(clearSelectedCampaignReviewExecutor());
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!campaignReviewExecutorsExact && executor){
            setStep(calculateCurrentStage(executor.stages) as REVIEW_CAMPAIGN_STEPS);
            //todo scroll to the end if mobile ?
        }
        //eslint-disable-next-line
    }, [campaignReviewExecutorsExact]);

    useSetBreadcrumbs([
        {
            title: tMenu('Access management'),
            path: PATH_LOCAL_ACCESS_MANAGEMENT
        },
        {
            title: tMenu('Review campaign'),
            path: PATH_ACCESS_MANAGEMENT_REVIEW_EXECUTORS_LIST
        },
        {
            title: executor ? executor.name : tMenu('Loading...'),
            path: PATH_ACCESS_MANAGEMENT_REVIEW_EXECUTORS_EXACT.replace(':id', id)
        }
    ]);

    //MENU
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //common actions
    const handleGoBack = () => history.push(PATH_ACCESS_MANAGEMENT_REVIEW_EXECUTORS_LIST);

    //stepper
    const [step, setStep] = useState<REVIEW_CAMPAIGN_STEPS>('MANAGER');

    //stages
    const managersStep = executor?.stages.find(e => e.type === REVIEW_CAMPAIGN_STEP_MANAGER);
    const vendorsStep = executor?.stages.find(e => e.type === REVIEW_CAMPAIGN_STEP_VENDOR);
    const executorStep = executor?.stages.find(e => e.type === REVIEW_CAMPAIGN_STEP_EXECUTOR);
    const verifyStep = executor?.stages.find(e => e.type === REVIEW_CAMPAIGN_STEP_VERIFY);
    const signStep = executor?.stages.find(e => e.type === REVIEW_CAMPAIGN_STEP_SIGN);

    // const handleSetStep = (step: REVIEW_CAMPAIGN_STEPS) => {
    //     //make scroll?
    //     setStep(step);
    // }

    const calculateIfDeadlinePassed = (stage?: campaignReviewExecutorStageType): boolean => {
        if(stage && stage.status === IN_PROGRESS_STATUS){
            return Date.parse(stage.expirationDate!) < Date.now()
        }
        return false;
    }

    const onRoleClick = (roleName: string, rolePermissions: string[]) => {
        rolePermissions.length > 0 && dispatch(openShowPermissions({rolePermissions, roleName}));
    }

    //MANAGERS
    const [selectedManagerAccounts, setSelectedManagersAccounts] = useState<string[]>([]);
    const [selectingAccountsOfActorEmail, setSelectingAccountsOfActorEmail] = useState<string | null>(null);

    const handleSelectManagerAccount = (accountId: string, actorEmail: string) => {
        setSelectingAccountsOfActorEmail(actorEmail); //to define which actor is working on now (to prevent on selecting accounts for another actor (""))
        setSelectedManagersAccounts(prev => {return [...prev, accountId]});
    }

    const handleDeselectManagerAccount = (accountId: string, actorEmail: string) => {
        setSelectedManagersAccounts(prev => {return prev.filter(e => e !== accountId)});
        if(!selectedManagerAccounts.length) setSelectingAccountsOfActorEmail(null); //MAYBE BAG HERE
    }

    const handleSelectAllAccounts = (email: string) => {
        setSelectingAccountsOfActorEmail(email); //to define which actor is working on now (to prevent on selecting accounts for another actor (""))
        if(managersStep){
            const act = managersStep.actors.find(e => e.email === email);
            if(act){
                setSelectedManagersAccounts((act.appsData ?? []).flatMap(e => e.data).map(e => e.id));
            }
        }
    }

    const handleDeselectAllAccounts = () => {
        setSelectedManagersAccounts([]);
        setSelectingAccountsOfActorEmail(null);
    }

    const isSelectedManagerAccount = (accountId: string): boolean => {
        return selectedManagerAccounts.some(e => e === accountId);
    }

    //VENDORS
    const [selectedVendorsAccounts, setSelectedVendorsAccounts] = useState<string[]>([]);
    const [selectingVendorsAccountsOfActorEmail, setSelectingVendorsAccountsOfActorEmail] = useState<string | null>(null);

    const handleSelectVendorAccount = (accountId: string, actorEmail: string) => {
        setSelectingVendorsAccountsOfActorEmail(actorEmail); //to define which actor is working on now (to prevent on selecting accounts for another actor (""))
        setSelectedVendorsAccounts(prev => {return [...prev, accountId]});
    }

    const handleDeselectVendorAccount = (accountId: string, actorEmail: string) => {
        setSelectedVendorsAccounts(prev => {return prev.filter(e => e !== accountId)});
        if(!selectedVendorsAccounts.length) setSelectingVendorsAccountsOfActorEmail(null); //MAYBE BAG HERE
    }

    const handleSelectAllVendorAccounts = (email: string) => {
        setSelectingVendorsAccountsOfActorEmail(email); //to define which actor is working on now (to prevent on selecting accounts for another actor (""))
        if(vendorsStep){
            const act = vendorsStep.actors.find(e => e.email === email);
            if(act){
                setSelectedVendorsAccounts((act.appsData ?? []).flatMap(e => e.data).map(e => e.id));
            }
        }
    }

    const handleDeselectAllVendorAccounts = () => {
        setSelectedVendorsAccounts([]);
        setSelectingVendorsAccountsOfActorEmail(null);
    }

    const isSelectedVendorAccount = (accountId: string): boolean => {
        return selectedVendorsAccounts.some(e => e === accountId);
    }

    //-----------------------FINISH

    const [_expands, setExpands] = useState<expandsType>(defaultExpands);

    const toggleExpand = (expand: keyof typeof _expands) => {
        setExpands({..._expands, [expand]: !_expands[expand]});
    }

    return{
        executor,
        commonActions: {
            handleGoBack,
            handleTerminateReviewCampaign: () => {
                dispatch(openTerminateDialog());
                handleClose(); //menu
            },
            onRoleClick
        },
        commonSelectors: {
            step,
            stepNumberId: STAGE_SEQUENCE.indexOf(step) ?? 0,
            isLoadingExact: campaignReviewExecutorsExact,
            loadingIds: campaignReviewUpdatingRowsWithIds,
            loadingAssignActorEmail: campaignReviewAssignToMeEmails,
            loadingVerifyActorEmails:campaignReviewVerifyChangesForEmails
        },
        stageManagers: {
            isDeadlinePassed: calculateIfDeadlinePassed(managersStep),
            isComplete: managersStep?.status === COMPLETE_STATUS,
            isTerminated: managersStep?.status === TERMINATED_STATUS,
            isNotStarted: managersStep?.status === null,
            stage: managersStep,
            handleAssignSubStepToMe: (fromActorEmail: string) => {
                managersStep && executor && dispatch(AssignStageToMainControl({
                    data: {
                        email: fromActorEmail,
                        stageId: managersStep.id,
                        executorId: executor.id
                    }
                }));
            },
            handleVerifyActorChanges: (fromActorEmail: string) => {
                if(managersStep && executor){
                    //searching for actor with status not compelete (except current one, its inProgress since the button was clicked)
                    const isLastVerify: boolean = !managersStep.actors.filter(e => e.email !== fromActorEmail).some(e => e.status !== COMPLETE_STATUS);
                    dispatch(VerifyActorChanges({
                        data: {
                            executorId: executor.id,
                            email: fromActorEmail,
                            verifyComment: null, //stage managers - set null
                            stageId: managersStep.id,
                            isLastVerify
                        }
                    }));
                }
            },
            viewJustification: (email: string, justification: string | null) => {
                justification && dispatch(openViewJustification({email, justification}));
            },
            editJustification: (app: applicationAccessDataWithoutAccounts, account: campaignReviewExecutorAccountFillableType, stageId: string, actorEmail: string) => {
                dispatch(openAddOrEditJustificationDialog({
                    app,
                    account,
                    stageId,
                    actorEmail,
                    isManager: true
                }));
            },
            handleChangeSingleStatus: (app: applicationAccessDataWithoutAccounts, account: campaignReviewExecutorAccountFillableType, stageId: string, actorEmail: string, status: string) => {
                executor && dispatch(UpdateCampaignReviewRows({
                    data: {
                        apps: [
                            {...app, data: [{...account, statusManager: status}]}
                        ],
                        stageId,
                        email: actorEmail,
                        executorId: executor.id
                    }
                }))
            },
            handleKeepAllSelectedManagerAccounts: () => {
                // console.log(`handleKeepAllSelectedManagerAccounts: managersStep: ${managersStep !== null} - selectingAccountsOfActorEmail: ${JSON.stringify(selectingAccountsOfActorEmail)}`);
                if(managersStep && selectingAccountsOfActorEmail !== null){
                    const actor = managersStep.actors.find(e => e.email === selectingAccountsOfActorEmail);
                    // console.log(`handleKeepAllSelectedManagerAccounts: actor: ${JSON.stringify(actor)}`);
                    if(actor){
                        const apps = mapAppsByAccountIds(selectedManagerAccounts, actor, ACCOUNT_KEEP_STATUS, true);
                        executor && dispatch(UpdateCampaignReviewRows({
                            data: {
                                apps,
                                stageId: managersStep.id,
                                email: selectingAccountsOfActorEmail,
                                executorId: executor.id,
                                onSuccess: () => {
                                    handleDeselectAllAccounts(); //removing selections
                                    setSelectingAccountsOfActorEmail(null); //giving ability to select other actor accounts
                                }
                            }
                        }))
                    }
                }
            },
            handleRevokeAllSelectedManagerAccounts: () => {
                if(managersStep && selectingAccountsOfActorEmail !== null){
                    const actor = managersStep.actors.find(e => e.email === selectingAccountsOfActorEmail);
                    if(actor){
                        const apps = mapAppsByAccountIds(selectedManagerAccounts, actor, ACCOUNT_REVOKE_STATUS, true);
                        executor && dispatch(UpdateCampaignReviewRows({
                            data: {
                                apps,
                                stageId: managersStep.id,
                                email: selectingAccountsOfActorEmail,
                                executorId: executor.id,
                                onSuccess: () => {
                                    handleDeselectAllAccounts(); //removing selections
                                    setSelectingAccountsOfActorEmail(null); //giving ability to select other actor accounts
                                }
                            }
                        }))
                    }
                }
            },
            handleDeleteAllSelectedManagerAccounts: () => {
                if(managersStep && selectingAccountsOfActorEmail !== null){
                    const actor = managersStep.actors.find(e => e.email === selectingAccountsOfActorEmail);
                    if(actor){
                        const apps = mapAppsByAccountIds(selectedManagerAccounts, actor, ACCOUNT_DELETE_STATUS, true);
                        executor && dispatch(UpdateCampaignReviewRows({
                            data: {
                                apps,
                                stageId: managersStep.id,
                                email: selectingAccountsOfActorEmail,
                                executorId: executor.id,
                                onSuccess: () => {
                                    handleDeselectAllAccounts(); //removing selections
                                    setSelectingAccountsOfActorEmail(null); //giving ability to select other actor accounts
                                }
                            }
                        }))
                    }
                }
            },
            //
            selectingAccountsOfActorEmail,
            selectedManagerAccounts,
            handleSelectManagerAccount,
            handleDeselectManagerAccount,
            handleSelectAllAccounts,
            handleDeselectAllAccounts,
            isSelectedManagerAccount,
            isSelectedAllManagersAccounts: (email: string): boolean => {
                if(managersStep){
                    const act = managersStep.actors.find(e => e.email === email);
                    if(act){
                        return (act.appsData ?? []).flatMap(e => e.data).length === selectedManagerAccounts.length
                    }
                }
                return false;
            }

        },
        stageVendors: {
            isDeadlinePassed: calculateIfDeadlinePassed(vendorsStep),
            isComplete: vendorsStep?.status === COMPLETE_STATUS,
            isTerminated: vendorsStep?.status === TERMINATED_STATUS,
            isNotStarted: vendorsStep?.status === null,
            stage: vendorsStep,
            //
            handleVerifyActorChanges: (fromActorEmail: string) => {
                if(vendorsStep && executor){
                    //searching for actor with status not compelete (except current one, its inProgress since the button was clicked)
                    const isLastVerify: boolean = !vendorsStep.actors.filter(e => e.email !== fromActorEmail).some(e => e.status !== COMPLETE_STATUS);
                    dispatch(VerifyActorChanges({
                        data: {
                            executorId: executor.id,
                            email: fromActorEmail,
                            verifyComment: null, //stage managers - set null
                            stageId: vendorsStep.id,
                            isLastVerify
                        }
                    }));
                }
            },
            handleAssignSubStepToMe: (fromActorEmail: string) => {
                vendorsStep && executor && dispatch(AssignStageToMainControl({
                    data: {
                        email: fromActorEmail,
                        stageId: vendorsStep.id,
                        executorId: executor.id
                    }
                }));
            },
            viewJustification: (email: string, justification: string | null) => {
                justification && dispatch(openViewJustification({email, justification}));
            },
            editJustification: (app: applicationAccessDataWithoutAccounts, account: campaignReviewExecutorAccountFillableType, actorEmail: string) => {
                vendorsStep && dispatch(openAddOrEditJustificationDialog({
                    app,
                    account,
                    stageId: vendorsStep.id,
                    actorEmail,
                    isManager: false
                }));
            },
            handleChangeSingleStatus: (app: applicationAccessDataWithoutAccounts, account: campaignReviewExecutorAccountFillableType, actorEmail: string, status: string) => {
                vendorsStep && executor && dispatch(UpdateCampaignReviewRows({
                    data: {
                        apps: [
                            {...app, data: [{...account, statusVendor: status}]}
                        ],
                        stageId: vendorsStep.id,
                        email: actorEmail,
                        executorId: executor.id
                    }
                }))
            },
            handleKeepAllSelectedVendorAccounts: () => {
                if(vendorsStep && selectingVendorsAccountsOfActorEmail !== null){
                    const actor = vendorsStep.actors.find(e => e.email === selectingVendorsAccountsOfActorEmail);
                    if(actor){
                        const apps = mapAppsByAccountIds(selectedVendorsAccounts, actor, ACCOUNT_KEEP_STATUS, false);
                        executor && dispatch(UpdateCampaignReviewRows({
                            data: {
                                apps,
                                stageId: vendorsStep.id,
                                email: selectingVendorsAccountsOfActorEmail,
                                executorId: executor.id,
                                onSuccess: () => {
                                    handleDeselectAllVendorAccounts(); //removing selections
                                    setSelectingVendorsAccountsOfActorEmail(null); //giving ability to select other actor accounts
                                }
                            }
                        }))
                    }
                }
            },
            handleRevokeAllSelectedVendorAccounts: () => {
                if(vendorsStep && selectingVendorsAccountsOfActorEmail !== null){
                    const actor = vendorsStep.actors.find(e => e.email === selectingVendorsAccountsOfActorEmail);
                    if(actor){
                        const apps = mapAppsByAccountIds(selectedVendorsAccounts, actor, ACCOUNT_REVOKE_STATUS, false);
                        executor && dispatch(UpdateCampaignReviewRows({
                            data: {
                                apps,
                                stageId: vendorsStep.id,
                                email: selectingVendorsAccountsOfActorEmail,
                                executorId: executor.id,
                                onSuccess: () => {
                                    handleDeselectAllVendorAccounts(); //removing selections
                                    setSelectingVendorsAccountsOfActorEmail(null); //giving ability to select other actor accounts
                                }
                            }
                        }))
                    }
                }
            },
            handleDeleteAllSelectedVendorAccounts: () => {
                if(vendorsStep && selectingVendorsAccountsOfActorEmail !== null){
                    const actor = vendorsStep.actors.find(e => e.email === selectingVendorsAccountsOfActorEmail);
                    if(actor){
                        const apps = mapAppsByAccountIds(selectedVendorsAccounts, actor, ACCOUNT_DELETE_STATUS, false);
                        executor && dispatch(UpdateCampaignReviewRows({
                            data: {
                                apps,
                                stageId: vendorsStep.id,
                                email: selectingVendorsAccountsOfActorEmail,
                                executorId: executor.id,
                                onSuccess: () => {
                                    handleDeselectAllVendorAccounts(); //removing selections
                                    setSelectingVendorsAccountsOfActorEmail(null); //giving ability to select other actor accounts
                                }
                            }
                        }))
                    }
                }
            },
            //
            selectingVendorsAccountsOfActorEmail,
            selectedVendorsAccounts,
            handleSelectVendorAccount,
            handleDeselectVendorAccount,
            handleSelectAllVendorAccounts,
            handleDeselectAllVendorAccounts,
            isSelectedVendorAccount,
            isSelectedAllVendorsAccounts: (email: string): boolean => {
                if(vendorsStep){
                    const act = vendorsStep.actors.find(e => e.email === email);
                    if(act){
                        return (act.appsData ?? []).flatMap(e => e.data).length === selectedVendorsAccounts.length
                    }
                }
                return false;
            }
        },
        stageExecutor: {
            isDeadlinePassed: calculateIfDeadlinePassed(executorStep),
            isComplete: executorStep?.status === COMPLETE_STATUS,
            isTerminated: executorStep?.status === TERMINATED_STATUS,
            isNotStarted: executorStep?.status === null,
            stage: executorStep,
            viewJustification: (email: string, justification: string | null) => {
                justification && dispatch(openViewJustification({email, justification}));
            },
            hoursFromLastIntegrationRun: new Date(Date.now()).getUTCHours(),
            handleFinishExecutionStage: () => {
                executorStep && executor && dispatch(VerifyActorChanges({
                    data: {
                        executorId: executor.id,
                        email: '',
                        verifyComment: null, //stage managers - set null
                        stageId: executorStep.id,
                        isLastVerify: true
                    }
                }));
            },
            getVerifyApplicationRevokeReason: (appAccessId: string): {leavedBy: string, comment: string | null} | null => {
                if(verifyStep){
                    const actor = verifyStep.actors.find(e => (e.appsData ?? []).some(a => a.applicationAccessId === appAccessId));
                    if(actor){
                        return {leavedBy: actor.email, comment: actor.verifyRevokeComment};
                    }
                }
                return null;
            },
            openShowVerifyApplicationRevokeReason: (reasonLeavedByEmail: string, reason: string) => {
                dispatch(openShowVerifyApplicationRevokeReason({leavedBy: reasonLeavedByEmail, comment: reason}));
            }
        },
        stageVerify: {
            isDeadlinePassed: calculateIfDeadlinePassed(verifyStep),
            isComplete: verifyStep?.status === COMPLETE_STATUS,
            isTerminated: verifyStep?.status === TERMINATED_STATUS,
            isNotStarted: verifyStep?.status === null,
            stage: verifyStep,
            handleAssignSubStepToMe: (fromActorEmail: string) => {
                verifyStep && executor && dispatch(AssignStageToMainControl({
                    data: {
                        email: fromActorEmail,
                        stageId: verifyStep.id,
                        executorId: executor.id
                    }
                }));
            },
            handleVerifyChanges: (email: string) => {
                if(verifyStep && executor){
                    //searching for actor with status not compelete (except current one, its inProgress since the button was clicked)
                    const isLastVerify: boolean = !verifyStep.actors.filter(e => e.email !== email).some(e => e.status !== COMPLETE_STATUS);
                    dispatch(VerifyActorChanges({
                        data: {
                            executorId: executor.id,
                            email: email,
                            verifyComment: null, //stage managers - set null
                            stageId: verifyStep.id,
                            isLastVerify
                        }
                    }));
                }
            },
            handleDeclineChanges: (email: string) => {
                if(verifyStep){
                    const isLastVerify: boolean = !verifyStep.actors.filter(e => e.email !== email).some(e => e.status !== COMPLETE_STATUS);
                    dispatch(openDeclineVerifyStepDialog({
                        email,
                        stageId: verifyStep.id,
                        isLastVerify
                    }))
                }
            }
        },
        stageSign: {
            isDeadlinePassed: calculateIfDeadlinePassed(signStep),
            isComplete: signStep?.status === COMPLETE_STATUS,
            isTerminated: signStep?.status === TERMINATED_STATUS,
            isNotStarted: signStep?.status === null,
            stage: signStep,
            handleViewDocument: () => {
                if(executor && executor.finalApprover.documentId){
                    handleOpen({fileId: executor.finalApprover.documentId, fileName: 'Campaign-review.pdf'});
                }
            },
        },
        stageFinish: {
            toggleExpand,
            _expands,
            handleViewDocument: () => {
                if(executor && executor.finalApprover.documentId){
                    handleOpen({fileId: executor.finalApprover.documentId, fileName: 'Campaign-review.pdf'});
                }
            },
            handleViewPipelineDetails: () => {
                if(executor && executor.finalApprover.signId){
                    handleOpen({fileId: executor.finalApprover.signId, fileName: 'Campaign-review-pipeline-details.pdf'});
                }
            },
            handleDownloadDocument: () => {
                if(executor && executor.finalApprover.documentId) {
                    dispatch(DownloadReport({
                        fileId: executor.finalApprover.documentId,
                        name: `${executor.name}_reviewCampaign.pdf`,
                        scanId: ''
                    }));
                }
            },
            handleDownloadPipelineDetails: () => {
                if(executor && executor.finalApprover.signId) {
                    dispatch(DownloadReport({
                        fileId: executor.finalApprover.signId,
                        name: `${executor.name}_reviewCampaign_pipelineDetails.pdf`,
                        scanId: ''
                    }));
                }
            }
        },
        //
        menu: {
            anchorEl,
            handleClose,
            handleClick,
            open,
        },
    }
}