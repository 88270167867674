import React from "react";
import {PageWrapper} from "../../../../../newShared/components/Layouts";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Box, Divider, Skeleton, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {MicroTableWithoutBorder} from "../../../../../newShared/components/microTableWithoutBorder";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {getTrainingExamStatusChip} from "../../helpers";
import {examLevelTitleMapper, PATH_TRAININGS_EXAMS,} from "../../constants";
import {MainTrainingExamStatus} from "../../../../../newShared/GQLTypes";
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useExamExactPage} from "../../hooks/useExamExact";
import * as S from "../../components/styled";
import {useTrainingsDialogs} from "../../hooks/useTrainingsDialogs";
import {ExamsCreateExamDialog} from "../../components/dialogs/examsCreateExamDialog";
import {ExamQuestionsBlock} from "../../components/examQuestions";
import {CoverImageView} from "../../components/trainingCoverComponent/CoverImageView";
import {CreateAssignmentDialog} from "../../components/dialogs/createAssignment";


export const ExamExactPage = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.pathExams'})
    const {isLessThen1050} = useMedia();
    const {
        exam,
        openedSection,
        isLoadingExact,
        isLoadingChangeStatus,
        handleToggleSection,
        handleOpenAssign,
        handleMoveToArchive,
        handleRestoreFromArchive,
    } = useExamExactPage();

    const {handleSet} = useTrainingsDialogs().createExam;

    return (
        <PageWrapper>
            <PageTitleAndActions
                title={exam?.name}
                handleGoBack={PATH_TRAININGS_EXAMS}
                actions={[
                    {
                        title: t('Assign'),
                        onClick: handleOpenAssign,
                        icon: <HowToRegOutlinedIcon />,
                        variant: 'contained',
                        size: 'small',
                    },
                    {
                        title: t('Edit'),
                        onClick: () => handleSet({isOpen: true}),
                        icon: <HowToRegOutlinedIcon />,
                        variant: 'outlined',
                        size: 'small',
                        disabled: !exam,
                    },
                    {
                        title:  t('Move to archive'),
                        onClick: handleMoveToArchive,
                        icon: <ArchiveOutlinedIcon />,
                        variant: 'outlined',
                        size: 'small',
                        loading: isLoadingChangeStatus,
                        dontShowWhen:  exam?.status !== MainTrainingExamStatus.Active
                    },
                    {
                        title:  t('Restore from archive'),
                        onClick: handleRestoreFromArchive,
                        icon:  <UnarchiveOutlinedIcon />,
                        variant: 'outlined',
                        size: 'small',
                        loading: isLoadingChangeStatus,
                        dontShowWhen:  exam?.status !== MainTrainingExamStatus.Archived
                    },
                ]}

            />

            <S.ExactPageContainer>
                <S.ExactPageLeftContainer isLessThen1050={isLessThen1050}>
                    {exam?.coverImage && <CoverImageView cover={exam.coverImage} size={'exact'}/> }
                    {/*{exam?.coverImage?.image &&*/}
                    {/*    <Box maxWidth={'400px'}>*/}
                    {/*        <img src={exam?.coverImage?.image} style={{borderRadius: '6px'}} width={'400px'} alt={exam?.coverImage?.imageName}/>*/}
                    {/*    </Box>*/}
                    {/*}*/}

                    <MicroTableWithoutBorder
                        isLoading={isLoadingExact}
                        noWrap
                        rows={[
                            {
                                label: `${t('Status')}:`,
                                text: exam ? getTrainingExamStatusChip(exam.status) : '-',
                            },
                            {
                                label: `${t('Last update')}:`,
                                text: exam?.updatedDate ? parseDateAuto(exam.updatedDate) : '-',
                            },
                            {
                                label: `${t('Category')}:`,
                                text: exam?.category || '-',
                            },
                            {
                                label: `${t('Level')}:`,
                                text: exam ? examLevelTitleMapper[exam.level] : '-',
                            },
                            {
                                label: `${t('Time to pass exam')}:`,
                                text: exam?.timeToPass || '-',
                            },
                            {
                                label: `${t('Total points')}:`,
                                text: exam?.totalPoints.toString() || '-',
                            },
                            {
                                label: `${t('Exam passing points')}:`,
                                text: exam?.examPassingPoints.toString() || '-',
                            }
                        ]}
                    />
                    <Divider flexItem sx={{bgcolor: colors.stroke.table, flexShrink: 0}}/>

                    <Typography variant={'*bodyText2_semibold'}>{`${t('Exam description')}:`}</Typography>
                    {isLoadingExact ? <Skeleton width={'50%'} /> : <Typography variant={'*bodyText2'} sx={{whiteSpace: 'break-spaces'}}>{exam?.description || '-'}</Typography>}

                    {isLessThen1050 &&
                        <>
                            <Divider flexItem sx={{flexShrink: 0}}/>
                            {exam && <ExamQuestionsBlock questions={exam.questions} toggleOpen={handleToggleSection} openedSection={openedSection} />}
                        </>
                    }


                </S.ExactPageLeftContainer>
                {!isLessThen1050 &&
                    <>
                        <Divider flexItem orientation={'vertical'} sx={{flexShrink: 0}}/>

                        <Box display={'flex'} flexDirection={'column'} gap={'10px'} p={'20px'} flexGrow={1} height={'100%'} overflow={'auto'}>
                            {exam && <ExamQuestionsBlock questions={exam.questions} toggleOpen={handleToggleSection} openedSection={openedSection} />}
                        </Box>
                    </>
                }

            </S.ExactPageContainer>

            <CreateAssignmentDialog />
            <ExamsCreateExamDialog/>
        </PageWrapper>
    )
}
