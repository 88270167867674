import {FC, MouseEventHandler} from "react";
import {useDrop} from "react-dnd";
import {
    EditorFillableFieldItemType,
    TAddFillableFieldEventData,
    TEditorDragElementFillableFieldData,
    TEditorFillableFieldItemType
} from "../../types";
import {EditorDropZoneForFillableFieldStyled} from "./styled";
import {createEventAddFillableField} from "../../helpers";

type TProps = {
    onMouseDown?: MouseEventHandler;
    pageId: string;
};
export const EditorDropZoneForFillableField: FC<TProps> = ({onMouseDown, pageId, children}) => {
    const onDropHandler = (data: TAddFillableFieldEventData) => {
        createEventAddFillableField(data)
    }

    const [{isOver, canDrop}, drop] = useDrop(
        () => ({
            accept: Object.values(EditorFillableFieldItemType),
            drop: (item: TEditorDragElementFillableFieldData, monitor) => {
                const {x, y} = monitor.getSourceClientOffset() ?? {x: 0, y: 0};
                onDropHandler({
                    ...item,
                    type: item.type as TEditorFillableFieldItemType[keyof TEditorFillableFieldItemType],
                    position: {x, y},
                    size: {
                        width: item.size?.width || 115,
                        height: item.size?.height || 52
                    },
                    pageId,
                })

            },
            collect: (monitor) => {
                return {
                    isOver: !!monitor.isOver(),
                    canDrop: !!monitor.canDrop(),
                }
            }
        }),
        []
    )

    return (
        <EditorDropZoneForFillableFieldStyled ref={drop} isOver={isOver} canDrop={canDrop}
                                              onMouseDown={onMouseDown} >
            {children}
        </EditorDropZoneForFillableFieldStyled>
    )
}