import React, {Component, createRef, Fragment, RefObject} from "react";
import {
    EditorRowData,
    FunctionsForToolBar,
    getToolBarProps,
    MapDispatchToPropsObject,
    TChangeTypeOfBlock,
    TEditorBlockElementData,
    TEditorColumnElementData,
    TEditorRowElementData,
    TResize
} from "../../types";
import {EditorItemRow} from "../editorItemRow";
import {EditorItemBlockStyled} from "../editorItemBlock/styled";
import {EditorDropZoneForBlockItem} from "../editorDropZoneForBlockItem";
import {ToolBarProps} from "../editorToolBar";
import {EditorWorkZone} from "../editorWorkzone";
import {
    changeOldIdToNewId,
    createEventBlockCreatedByDrop,
    createEventDeleteBlock,
    isRefColumn,
    isRefImage,
    isRefRow
} from "../../helpers";
import {uuid} from "../../../../utils";

interface Props extends getToolBarProps {
    block: TEditorColumnElementData;

    onDelete: () => void;
    changeTypeOfBlock: (newBlock: TChangeTypeOfBlock) => void;

    parentRef: RefObject<EditorItemRow>["current"];
    editorWorkZoneRef: RefObject<EditorWorkZone>["current"];
    disabledFocusOnMount?: boolean;
    isDisabledDuplicate?: boolean;
}
export interface ColumnState {
    id: string;
    type: string;
    data: TEditorColumnElementData["data"];

    //for resizing
    resize: TResize,

    wrapperRef: RefObject<HTMLDivElement>;
    refs: Record<string, RefObject<EditorItemRow>>;
}

export class EditorItemColumn extends Component<Props, ColumnState> implements FunctionsForToolBar {
    constructor(props: EditorItemColumn["props"]) {
        super(props);

        const refs: ColumnState["refs"] = {};
        props.block.data.content?.forEach(el => {
            refs[el.id] = createRef<EditorItemRow>();
        });

        this.state = {
            id: props.block.id,
            type: 'column',
            data: props.block.data,

            resize: {x: 0, px: 0, percent: 0},

            wrapperRef: createRef<HTMLDivElement>(),
            refs,
        }
    }

    getFlexBasis = (): number => {
        const ref = this.state.wrapperRef.current;
        if (ref) {
            const flexBasis = window.getComputedStyle(ref, null).getPropertyValue("flex-basis");
            return Number(flexBasis?.replace('%', ''));
        }
        return 0;
    };
    setFlexBasis = (value: number) => {
        const ref = this.state.wrapperRef.current;
        if (ref) {
            ref.style.flexBasis = value + '%';
        }
    };

    onResizeParent = () => {
        this.state.data.content.forEach(e => {
            const ref = this.state.refs[e.id]?.current;
            if (isRefImage(ref) || isRefRow(ref) || isRefColumn(ref)) {
                ref.onResizeParent();
            }
        });
    };

    handleAddBlockTopBottom = (index: number, type: string, data?: TEditorBlockElementData) => {
        const newData = data ? changeOldIdToNewId(JSON.parse(JSON.stringify(data))) : undefined;

        const newBlock: TEditorBlockElementData = data ? {
            ...newData,
            data: {
                ...newData.data,
                width: 100,
            }
        } : {
            id: 'block' + uuid(),
            type: 'block',
            data: {
                width: 100,
                content: [{id: type + uuid(), type: type, data: {}}],
            }
        }

        const newRow: TEditorRowElementData = {
            id: 'row' + uuid(),
            type: 'row',
            data: {
                content: [newBlock],
            },
        }

        this.setState(prev => {
            //condition for avoiding setState twice firing in StrictMode
            const index = prev.data.content.findIndex(el => el.id === newRow.id)
            if (index === -1) {
                prev.data.content.splice(index, 0, newRow)
                prev.refs[newRow.id] = createRef<EditorItemRow>()
            }

            return prev
        })

        createEventBlockCreatedByDrop({
            ids: [newBlock.id],
        });
        data && createEventDeleteBlock({id: data.id});
    }

    addCopiedBlock = (block: TEditorBlockElementData, oldId: string) => {
        const index = this.state.data.content.findIndex(e => e.data.content.some(e => e.id === oldId));
        this.handleAddBlockTopBottom(index + 1, block.type, block)
    }

    handleDeleteRow = (index: number, id: string) => () => {
        //remove full column
        if (this.state.data.content.length === 1) {
            this.props.onDelete();
            return;
        }

        //recalculate width of blocks in row to next unwrapping in Row component
        if (this.state.data.content.length === 2) {
            const row = this.state.data.content.filter(block => block.id !== id)[0];
            const rowContent = row.data.content;
            const rowRef = this.state.refs[row.id!].current!;

            const parentWidth = this.getFlexBasis();
            const blocks = rowContent.map(e => {
                const actualBlock = rowRef.state.refs[e.id!].current!;
                const oldWidth = actualBlock.getFlexBasis();

                return {
                    ...e,
                    data: {
                        ...e.data,
                        content: actualBlock.state.data.content,
                        width: parentWidth * oldWidth / 100
                    }
                }
            });

            this.props.changeTypeOfBlock({
                type: 'row',
                data: {
                    content: blocks as TEditorRowElementData["data"]["content"],
                },
            })

            return;
        }

        //remove row
        this.setState(prev => {
            const index = prev.data.content.findIndex(block => block.id === id);
            index > -1 && prev.data.content.splice(index, 1);
            delete prev.refs[id];

            return prev
        });
    }

    ///////ToolBar
    setToolBar: MapDispatchToPropsObject["setToolBar"] = (toolBar, ids) => {
        this.props.parentRef?.setToolBar(toolBar, [this.state.id, ...ids])
    }
    setTextByToolBar: ToolBarProps["toolBarHandler"] = (action, payload, ids) => {
        const nextId = ids[0];
        if (nextId) {
            const ref = this.state.refs[nextId]?.current;
            if (ref) {
                ref.setTextByToolBar(action, payload, ids.slice(1))
            }
        }
    }

    setActiveBlock: FunctionsForToolBar["setActiveBlock"] = (ids) => {
        this.props.parentRef?.setActiveBlock([this.state.id, ...ids])
    }

    handleReplaceRow = (id: string, data: EditorRowData[]) => {
        const newData = data.map((e): TEditorRowElementData => ({
            id: 'row' + uuid(), type: 'row', data: e
        }))

        this.setState(prev => {
            const index = prev.data.content.findIndex(block => block.id === id);
            index > -1 && prev.data.content.splice(index, 1, ...newData);

            delete prev.refs[id];
            newData.forEach(el => {
                prev.refs[el.id] = createRef<EditorItemRow>();
            })

            return prev
        });

        // setTimeout(() => {
        //     createEventUnfocusAllBlocks({});
        // }, 150)
    };

    render() {
        // console.log('render column\n', this.state.data.content)

        return (
            <EditorItemBlockStyled ref={this.state.wrapperRef}
                                   width={this.state.data.width}
            >
                {!this.props.readonly && (
                    <EditorDropZoneForBlockItem orientation={"horizontal"}
                                                onDrop={(type, block) => this.handleAddBlockTopBottom(0, type, block as TEditorBlockElementData)}
                                                readonly={this.props.readonly}/>
                )}

                <div className={'EditorItemColumn'}>
                    {this.state.data.content.map((block, index, array) => (
                        <Fragment key={block.id + ' fragment'}>
                            <EditorItemRow ref={this.state.refs[block.id!]}
                                           block={block} onDelete={this.handleDeleteRow(index, block.id!)}
                                           parentRef={this}
                                           editorWorkZoneRef={this.props.editorWorkZoneRef}
                                           getToolBarState={this.props.getToolBarState}
                                           disabledFocusOnMount={this.props.disabledFocusOnMount}
                                           isDisabledDuplicate={this.props.isDisabledDuplicate}

                                           status={this.props.status}
                                           readonly={this.props.readonly}
                                           isLoading={this.props.isLoading}
                            />

                            {/*{(index < array.length - 1) && (*/}
                            {/*    <EditorDropZoneForBlockItem orientation={"horizontal"}*/}
                            {/*                                onDrop={(type) => this.handleAddBlockTopBottom(index + 1, type)}*/}
                            {/*                                readonly={this.props.readonly}/>*/}
                            {/*)}*/}

                            <EditorDropZoneForBlockItem orientation={"horizontal"}
                                                        onDrop={(type, block) => this.handleAddBlockTopBottom(index + 1, type, block as TEditorBlockElementData)}
                                                        readonly={this.props.readonly}/>
                        </Fragment>
                    ))}
                </div>

                {/*{(*/}
                {/*    <EditorDropZoneForBlockItem orientation={"horizontal"}*/}
                {/*                                onDrop={(type) => this.handleAddBlockTopBottom(this.state.data.content.length, type)}*/}
                {/*                                readonly={this.props.readonly}/>*/}
                {/*)}*/}
            </EditorItemBlockStyled>
        );
    }
}