import {useAppDispatch} from "../../../../../../newShared/redux";
import {useSelector} from "react-redux";
import {releaseVendorsAction,} from "../../../store/actions";
import {hideReleaseDialog, vendorsDialogsSelector} from "../../../store/slice";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TReleaseDialogEmployee} from "../../../../../../newShared/components/releaseDialog/types";


export const useVendorsRelease = () => {
    const dispatch = useAppDispatch();
    const {tCommon} = useMainTranslation();
    const {release} = useSelector(vendorsDialogsSelector);

    const {setMessage} = useMessageDialog();


    const handleClose = () => {
        dispatch(hideReleaseDialog());
    }

    const onSuccess = () => {
        setMessage({title: tCommon('Completed successfully'), message: tCommon('Automation created!')});
    }

    const handleRelease = ({frameworkId, controlId, evidenceId, readers, reviewers, signers}: {
        frameworkId: string, controlId: string, evidenceId: string,
        reviewers: TReleaseDialogEmployee[], readers: TReleaseDialogEmployee[], signers: TReleaseDialogEmployee[]
    }) => {
        dispatch(releaseVendorsAction({
            data: {
                workspaceId: '',
                organizationId: '',
                frameworkId,
                controlId,
                evidenceId,
                readers,
                reviewers,
                signers,
            },
            onSuccess
        }))
    }

    return {
        handleClose,
        handleRelease,
        isOpen: release.isOpen,
        isLoadingRelease: release.isLoading || false
    }
}