import {riskRegistryInitialState, TRisk, TRiskModel, TRiskRegistry} from "../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {
    changeRisksModelAction,
    createRiskAction,
    createRiskRegistryAction,
    createRisksModelsAction,
    deleteRiskByIdApiAction,
    deleteRiskRegistryAction,
    deleteRisksModelAction,
    exportCsvRisksAction,
    getCsvFieldsRisksAction,
    getRiskRegistersAction,
    getRiskRegistyByIdAction,
    GetRisksAvailableFieldsForExport,
    getRisksByRegistryAction,
    getRisksModelsAction,
    GetShortEmployees,
    importCsvTemplateRisksAction,
    performCsvRisksAction,
    releaseRiskRegistryAction,
    updateRiskRegistryAction,
    updateRisksByRegistryIdAction,
    updateRisksModelAction
} from "./actions";
import {REGISTRY_RISKS_DEFAULT_COUNT} from "../constants";
import {
    addImportCsvActionsToBuilder,
    initialImportCsvDialogState,
    setImportCsvDialog
} from "../../../../newShared/components/csvImportDialog/constants";
import {minMaxLoadedPageDefault} from "../../../../newShared/components/genericTable/constants";
import {handlePagination} from "../../../../newShared/components/genericTable/helpers";
import {minMaxLoadedPageType} from "../../../../newShared/components/genericTable/types";

export const initialState: riskRegistryInitialState = {
    registries: [],
    registriesMinMaxLoadedPage: minMaxLoadedPageDefault,
    registriesPageInfo: {
        page: 0,
        count: 10,
        sort: '',
        total: 0,
    },
    selectedRegistry: null,

    registryRisks: [],
    registryRisksMinMaxLoadedPage: minMaxLoadedPageDefault,
    registryRisksPageInfo: {
        page: 0,
        count: 10,
        sort: '',
        total: 0,
    },
    selectedRisk: null,

    models: [],
    selectedModel: null,

    dialogs: {
        createRisk: false,
        editRisk: false,

        deleteRisk: {
            risk: null,
            isOpen: false,
        },
        addControlOwner: {
            risk: null,
            isOpen: false,
        },

        releaseRegistry: false,
        editRegistry: false,
        deleteRegistry: false,

        deleteModel: false,

        saveDialog: {
            isOpen: false,
            onSuccessGoToRisk: null,
            isRiskEdited: false
        },
        exportCsv: {
            isOpen: false,
            fields: [],
        },

        ...initialImportCsvDialogState,
    },

    loadings: {
        risksListEdit: false,
        registriesList: false,
        registryExact: false,
        registryRelease: false,
        registryEdit: false,
        registryDelete: false,
        registryCreate: false,
        registryExport: false,

        risksList: false,
        riskExact: false,
        riskCreate: false,
        riskEdit: false,
        riskDelete: false,

        modelsList: false,
        modelsDelete: false,
        modelsUpdate: false,
        modelsCreate: false,

        releaseData: false,
        employeeList: false,
        isLoadingFields: false,
    },

    risksViewTypeBlock: true, // 'block' | 'table'
    risksQuantitative: localStorage.getItem('risksQuantitative') === '1',
    isTableNeedsUpdate: false,

    employees: [],
}

export const RiskManagementSlice = createSlice({
    name: 'riskManagement',
    initialState,
    reducers: {
        replaceRegistriesMinMaxLoadedPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {slice.registriesMinMaxLoadedPage = payload},
        replaceRegistryRisksMinMaxLoadedPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {slice.registryRisksMinMaxLoadedPage = payload},
        cleanRegistries: (slice) => {slice.registries = []; slice.registriesPageInfo = initialState.registryRisksPageInfo;},
        cleanRegistryRisks: (slice) => {slice.registryRisks = []; slice.registryRisksPageInfo = initialState.registryRisksPageInfo;},

        openCreateRisk: (slice) => {slice.dialogs.createRisk = true},
        hideCreateRisk: (slice) => {slice.dialogs.createRisk = false},

        openEditRisk: (slice) => {slice.dialogs.editRisk = true},
        hideEditRisk: (slice) => {slice.dialogs.editRisk = false},

        openDeleteRisk: (slice, {payload}: {payload: TRisk}) => {slice.dialogs.deleteRisk = {isOpen: true, risk: payload}},
        hideDeleteRisk: (slice) => {slice.dialogs.deleteRisk = {isOpen: false, risk: null}},

        setRiskEdited: (slice, {payload}: {payload: boolean}) => {slice.dialogs.saveDialog.isRiskEdited = payload},
        openSaveRisk: (slice, {payload}: {payload: TRisk | null}) => {slice.dialogs.saveDialog = {...slice.dialogs.saveDialog, isOpen: true, onSuccessGoToRisk: payload}},
        hideSaveRisk: (slice) => {slice.dialogs.saveDialog = {isOpen: false, onSuccessGoToRisk: null, isRiskEdited: false}},

        openAddControlActions: (slice, {payload}: {payload: TRisk}) => {slice.dialogs.addControlOwner = {isOpen: true, risk: payload}},
        hideAddControlActions: (slice) => {slice.dialogs.deleteRisk = {isOpen: false, risk: null}},

        openReleaseRegistry: (slice) => {slice.dialogs.releaseRegistry = true},
        hideReleaseRegistry: (slice) => {slice.dialogs.releaseRegistry = false},

        openEditRegistry: (slice) => {slice.dialogs.editRegistry = true},
        hideEditRegistry: (slice) => {slice.dialogs.editRegistry = false},

        openDeleteRegistry: (slice) => {slice.dialogs.deleteRegistry = true},
        hideDeleteRegistry: (slice) => {slice.dialogs.deleteRegistry = false},

        openDeleteModel: (slice) => {slice.dialogs.deleteModel = true},
        hideDeleteModel: (slice) => {slice.dialogs.deleteModel = false},

        openAddActionOwner: (slice, {payload}: {payload: TRisk}) => {slice.dialogs.addControlOwner = {isOpen: true, risk: payload}},
        hideAddActionOwner: (slice) => {slice.dialogs.addControlOwner = {isOpen: false, risk: null}},

        toggleRisksViewTypeBlock: (slice) => {
            slice.risksViewTypeBlock = !slice.risksViewTypeBlock;
            if(!slice.risksViewTypeBlock){
                //if risksViewTypeBlock = true - it will become false - need to hide right side bar
                slice.selectedRisk = null;
            }
        },
        toggleRisksQuantitative: (slice) => {
            const newValue = slice.risksQuantitative
            slice.risksQuantitative = !newValue;
            localStorage.setItem('risksQuantitative', !newValue ? '1' : '0');

        },

        setSelectedModel: (slice, {payload}: {payload: TRiskModel}) => {slice.selectedModel = payload},
        deselectedModel: (slice) => {slice.selectedModel = null},
        setSelectedRegistry: (slice, {payload}: {payload: TRiskRegistry}) => {slice.selectedRegistry = payload},
        setSelectedRisk: (slice, {payload}: {payload: TRisk | null}) => {
            slice.selectedRisk = payload;
            slice.dialogs.saveDialog = { //if this action fired -> old risk was saved in saveDialog or saveDialog was not opened at all because it was saved before -> we can close it
                isOpen: false,
                onSuccessGoToRisk: null,
                isRiskEdited: false
            }
        },

        deselectedRegistry: (slice) => {
            slice.selectedRegistry = null;
            slice.selectedRisk = null;
            slice.registryRisks = [];
            slice.registryRisksPageInfo = {
                page: 0,
                count: REGISTRY_RISKS_DEFAULT_COUNT,
                sort: '',
                total: 0,
            };
            slice.risksViewTypeBlock = true;
        },
        deselectedRisk: (slice) => {slice.selectedRisk = null},

        eraseRegistries: (slice) => {slice.registries = []},
        eraseRisks: (slice) => {
            slice.registryRisks = [];
            slice.registryRisksPageInfo = {
                page: 0,
                count: REGISTRY_RISKS_DEFAULT_COUNT,
                total: 0,
            }
        },

        setTableNeedsSave: (slice, {payload}: {payload: boolean}) => {slice.isTableNeedsUpdate = payload},

        cleanUp: () => initialState,

        setImportCsvDialogAction: setImportCsvDialog,
        openExportCsv: (slice) => {
            slice.dialogs.exportCsv.isOpen = true;
        },
        hideExportCsv: (slice) => {
            slice.dialogs.exportCsv = initialState.dialogs.exportCsv;
        },
    },
    extraReducers: (builder) => {
        addImportCsvActionsToBuilder<riskRegistryInitialState, {registryId: string}, {registryId: string}>(
            builder, getCsvFieldsRisksAction, performCsvRisksAction,
            {afterPerform: (slice) => {slice.selectedRisk = null}}
        );

        builder
        //getRisksModelsAction
            .addCase(getRisksModelsAction.pending, (slice) => {
                slice.loadings.modelsList = true;
            })
            .addCase(getRisksModelsAction.rejected, (slice) => {
                slice.loadings.modelsList = false;
            })
            .addCase(getRisksModelsAction.fulfilled, (slice, {payload}) => {
                slice.loadings.modelsList = false;
                slice.models = payload;
            })
        //changeRisksModelAction
            .addCase(changeRisksModelAction.pending, (slice) => {
                // slice.loadings.modelsUpdate = true;
            })
            .addCase(changeRisksModelAction.rejected, (slice) => {
                // slice.loadings.modelsUpdate = false;
            })
            .addCase(changeRisksModelAction.fulfilled, (slice, {payload}) => {
                // slice.loadings.modelsUpdate = false;
                // slice.models = slice.models.map(e => e.id === payload.)
            })
        //updateRisksModelAction
            .addCase(updateRisksModelAction.pending, (slice) => {
                slice.loadings.modelsUpdate = true;
            })
            .addCase(updateRisksModelAction.rejected, (slice) => {
                slice.loadings.modelsUpdate = false;
            })
            .addCase(updateRisksModelAction.fulfilled, (slice, {payload}) => {
                slice.loadings.modelsUpdate = false;
                slice.models = slice.models.map(e => e.id === payload.data.riskModel.id ? payload.data.riskModel : e);
                if(slice.selectedModel){
                    slice.selectedModel = payload.data.riskModel;
                }
            })
        //deleteRisksModelAction
            .addCase(deleteRisksModelAction.pending, (slice) => {
                slice.loadings.modelsDelete = true;
            })
            .addCase(deleteRisksModelAction.rejected, (slice) => {
                slice.loadings.modelsDelete = false;
            })
            .addCase(deleteRisksModelAction.fulfilled, (slice, {payload}) => {
                slice.loadings.modelsDelete = false;
                slice.dialogs.deleteModel = false;
                slice.models = slice.models.filter(e => e.id !== payload.riskModelId);
                if(slice.selectedModel && slice.selectedModel.id === payload.riskModelId){
                    slice.selectedModel = null;
                }
            })
        //createRisksModelsAction
            .addCase(createRisksModelsAction.pending, (slice) => {
                slice.loadings.modelsCreate = true;
            })
            .addCase(createRisksModelsAction.rejected, (slice) => {
                slice.loadings.modelsCreate = false;
            })
            .addCase(createRisksModelsAction.fulfilled, (slice, {payload}) => {
                slice.loadings.modelsCreate = false;
                slice.models.push(payload);
                slice.selectedModel = payload;
            })
        //getRiskRegistersAction
            .addCase(getRiskRegistersAction.pending, (slice) => {
                slice.loadings.registriesList = true;
            })
            .addCase(getRiskRegistersAction.rejected, (slice) => {
                slice.loadings.registriesList = false;
            })
            .addCase(getRiskRegistersAction.fulfilled, (slice, {payload}) => {
                slice.loadings.registriesList = false;

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<TRiskRegistry>(
                    slice.registries,
                    slice.registriesPageInfo,
                    payload.data,
                    payload.pageInfo,
                    slice.registriesMinMaxLoadedPage.minLoadedPage,
                    slice.registriesMinMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.registries = results;
                slice.registriesMinMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.registriesPageInfo = payload.pageInfo;

                // const {pageInfo, array} = setPaginationInStore<TRiskRegistry>(
                //     slice.registriesPageInfo,
                //     slice.registries,
                //     payload.pageInfo,
                //     payload.data,
                // )
                //
                // slice.registries = array;
                // slice.registriesPageInfo = pageInfo;

                // if (payload.pageInfo.page === 0) {
                //     slice.registries = payload.data;
                // } else {
                //     if (slice.registriesPageInfo.page < payload.pageInfo.page) {
                //         slice.registries = [...slice.registries, ...payload.data];
                //     } else {
                //         slice.registries = [...payload.data, ...slice.registries];
                //     }
                // }
                // slice.registriesPageInfo = payload.pageInfo;
            })
        //getRiskRegistyByIdAction
            .addCase(getRiskRegistyByIdAction.pending, (slice) => {
                slice.loadings.registryExact = true;
            })
            .addCase(getRiskRegistyByIdAction.rejected, (slice) => {
                slice.loadings.registryExact = false;
            })
            .addCase(getRiskRegistyByIdAction.fulfilled, (slice, {payload}) => {
                slice.loadings.registryExact = false;
                slice.selectedRegistry = payload.response;
            })
        //getRisksByRegistryAction
            .addCase(getRisksByRegistryAction.pending, (slice) => {
                slice.loadings.risksList = true;
            })
            .addCase(getRisksByRegistryAction.rejected, (slice) => {
                slice.loadings.risksList = false;
            })
            .addCase(getRisksByRegistryAction.fulfilled, (slice, {payload}) => {
                slice.loadings.risksList = false;

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<TRisk>(
                    slice.registryRisks,
                    slice.registryRisksPageInfo,
                    payload.response.data,
                    payload.response.pageInfo,
                    slice.registryRisksMinMaxLoadedPage.minLoadedPage,
                    slice.registryRisksMinMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.registryRisks = results;
                slice.registryRisksMinMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.registryRisksPageInfo = payload.response.pageInfo;
                slice.selectedRegistry = payload.response.registry;

                // const {pageInfo, array} = setPaginationInStore<TRisk>(
                //     slice.registryRisksPageInfo,
                //     slice.registryRisks,
                //     payload.response.pageInfo,
                //     payload.response.data,
                // )
                //
                // slice.registryRisks = array;
                // slice.registryRisksPageInfo = pageInfo;
                // slice.selectedRegistry = payload.response.registry;

                // if (payload.response.pageInfo.page === 0) {
                //     slice.registryRisks = payload.response.data;
                // } else {
                //     if (slice.registryRisksPageInfo.page < payload.response.pageInfo.page) {
                //         slice.registryRisks = [...slice.registryRisks, ...payload.response.data];
                //     } else {
                //         slice.registryRisks = [...payload.response.data, ...slice.registryRisks];
                //     }
                // }
                // slice.registryRisksPageInfo = payload.response.pageInfo;
                // slice.selectedRegistry = payload.response.registry;
            })
        //updateRisksByRegistryIdAction
            .addCase(updateRisksByRegistryIdAction.pending, (slice) => {
                slice.loadings.riskEdit = true;
                slice.loadings.risksListEdit = true;
            })
            .addCase(updateRisksByRegistryIdAction.rejected, (slice) => {
                slice.loadings.riskEdit = false;
                slice.loadings.risksListEdit = false;
            })
            .addCase(updateRisksByRegistryIdAction.fulfilled, (slice, {payload}) => {
                slice.loadings.riskEdit = false;
                slice.loadings.risksListEdit = false;
                slice.dialogs.editRisk = false;

                slice.selectedRegistry = payload.response.registry;
                slice.registryRisks = slice.registryRisks
                    .filter(e => !payload.data.deleteIds.includes(e?.id || ''))
                    .map(e => {
                        const newRisk = payload.response.data.find(r => r.id === e?.id);
                        return newRisk || e;
                    });

                slice.registryRisksPageInfo.total = slice.registryRisksPageInfo.total - payload.data.deleteIds.length;
                slice.registryRisksPageInfo.page = Math.floor(slice.registryRisks.length / slice.registryRisksPageInfo.count);


                // //this endpoint returns risks by pageInfo - when risk will be updated have to replace them all - so fetching all
                // if(!payload.disableReplaceAll){
                //     slice.registryRisks = payload.response.data;
                //     slice.registryRisksPageInfo = payload.response.pageInfo;
                // }else{
                //     //if disabled replace - we replace all risks from request to apply changes for tables
                //     if(Array.isArray(payload.data.risks)){
                //         slice.registryRisks = slice.registryRisks.map(e => {
                //             return (payload.data.risks as RiskInput[]).find(r => r.id === e?.id) ?? e;
                //         })
                //     }else{
                //         const currRisk = payload.data.risks as RiskInput;
                //         slice.registryRisks = slice.registryRisks.map(e => e?.id === currRisk.id ? currRisk : e);
                //     }
                // }

                const selected = payload.response.data.find(e => e.id === slice.selectedRisk?.id);
                if (slice.selectedRisk && selected) {
                    slice.selectedRisk = {...slice.selectedRisk, ...selected};
                }

                // if(slice.selectedRisk && Array.isArray(payload.data.risks)){
                //     slice.selectedRisk = payload.data.risks.find(e => e.id === slice.selectedRisk!.id) ?? slice.selectedRisk;
                // }else if(slice.selectedRisk && !Array.isArray(payload.data.risks)){
                //     slice.selectedRisk = payload.data.risks;
                // }
            })
        //deleteRiskRegistryAction
            .addCase(deleteRiskRegistryAction.pending, (slice) => {
                slice.loadings.registryDelete = true;
            })
            .addCase(deleteRiskRegistryAction.rejected, (slice) => {
                slice.loadings.registryDelete = false;
            })
            .addCase(deleteRiskRegistryAction.fulfilled, (slice, {payload}) => {
                slice.loadings.registryDelete = false;
                slice.dialogs.deleteRegistry = false;

                slice.registryRisks = [];
                if(slice.selectedRisk){
                    slice.selectedRisk = null;
                    slice.registryRisksPageInfo = {
                        page: 0,
                        count: REGISTRY_RISKS_DEFAULT_COUNT,
                        total: 0,
                    }
                }
                if(slice.selectedRegistry){
                    slice.selectedRegistry = null;
                    slice.risksViewTypeBlock = true;
                }
                slice.registries = slice.registries.filter(e => e?.id !== payload.registryId);
                slice.registriesPageInfo = {
                    ...slice.registriesPageInfo,
                    total: slice.registriesPageInfo.total - 1
                }
            })
        //deleteRiskByIdApiAction
            .addCase(deleteRiskByIdApiAction.pending, (slice) => {
                slice.loadings.riskDelete = true;
            })
            .addCase(deleteRiskByIdApiAction.rejected, (slice) => {
                slice.loadings.riskDelete = false;
            })
            .addCase(deleteRiskByIdApiAction.fulfilled, (slice, {payload}) => {
                slice.loadings.riskDelete = false;

                slice.dialogs.deleteRisk = {
                    isOpen: false,
                    risk: null,
                }

                if(slice.selectedRisk){
                    slice.selectedRisk = null;
                }
                slice.registryRisks = slice.registryRisks.filter(e => e?.id !== payload.riskId);

                if(slice.selectedRegistry){
                    slice.selectedRegistry.totalRisks = slice.selectedRegistry.totalRisks - 1;
                }
            })
        //createRiskRegistryAction
            .addCase(createRiskRegistryAction.pending, (slice) => {
                slice.loadings.registryCreate = true;
            })
            .addCase(createRiskRegistryAction.rejected, (slice) => {
                slice.loadings.registryCreate = false;
            })
            .addCase(createRiskRegistryAction.fulfilled, (slice, {payload}) => {
                slice.loadings.registryCreate = false;
                slice.selectedRegistry = payload;
            })
        //createRiskAction
            .addCase(createRiskAction.pending, (slice) => {
                slice.loadings.riskCreate = true;
            })
            .addCase(createRiskAction.rejected, (slice) => {
                slice.loadings.riskCreate = false;
            })
            .addCase(createRiskAction.fulfilled, (slice, {payload}) => {
                slice.loadings.riskCreate = false;
                slice.dialogs.createRisk = false;
                slice.registryRisks = [...slice.registryRisks, payload];
                if(slice.selectedRegistry){
                    slice.selectedRegistry.totalRisks = slice.selectedRegistry.totalRisks + 1;
                }
                if(slice.selectedRisk && slice.risksViewTypeBlock){
                    slice.selectedRisk = payload;
                }
            })
        //updateRiskRegistryAction
            .addCase(updateRiskRegistryAction.pending, (slice) => {
                slice.loadings.registryEdit = true;
            })
            .addCase(updateRiskRegistryAction.rejected, (slice) => {
                slice.loadings.registryEdit = false;
            })
            .addCase(updateRiskRegistryAction.fulfilled, (slice, {payload}) => {
                slice.loadings.registryEdit = false;
                slice.dialogs.editRegistry = false;
                slice.selectedRisk = null;
                slice.selectedRegistry = payload;
            })
        //importCsvTemplateRisksAction
            .addCase(importCsvTemplateRisksAction.pending, (slice) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = true;
            })
            .addCase(importCsvTemplateRisksAction.rejected, (slice) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = false;
            })
            .addCase(importCsvTemplateRisksAction.fulfilled, (slice, {payload}) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = false;
            })
        //exportCsvRisksAction
            .addCase(exportCsvRisksAction.pending, (slice) => {
                slice.loadings.registryExport = true;
            })
            .addCase(exportCsvRisksAction.rejected, (slice) => {
                slice.loadings.registryExport = false;
            })
            .addCase(exportCsvRisksAction.fulfilled, (slice, {payload}) => {
                slice.loadings.registryExport = false;
                slice.dialogs.exportCsv = initialState.dialogs.exportCsv;
            })

            .addCase(GetShortEmployees.pending, (slice) => {
                slice.loadings.employeeList = true;
            })
            .addCase(GetShortEmployees.rejected, (slice) => {
                slice.loadings.employeeList = false;
            })
            .addCase(GetShortEmployees.fulfilled, (slice, {payload}) => {
                slice.loadings.employeeList = false;
                slice.employees = payload;
            })
        //releaseRiskRegistryAction
            .addCase(releaseRiskRegistryAction.pending, (slice) => {
                slice.loadings.registryRelease = true;
            })
            .addCase(releaseRiskRegistryAction.rejected, (slice) => {
                slice.loadings.registryRelease = false;
            })
            .addCase(releaseRiskRegistryAction.fulfilled, (slice, {payload}) => {
                slice.loadings.registryRelease = false;
                slice.dialogs.releaseRegistry = false;
            })

        //AvailableFieldsForExport
            .addCase(GetRisksAvailableFieldsForExport.pending, (slice) => {
                slice.loadings.isLoadingFields = true;
            })
            .addCase(GetRisksAvailableFieldsForExport.rejected, (slice) => {
                slice.loadings.isLoadingFields = false;

            })
            .addCase(GetRisksAvailableFieldsForExport.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingFields = false;
                slice.dialogs.exportCsv.fields = payload.fields;
            })

        //releaseData
    }
});

export const RiskManagementReducer = RiskManagementSlice.reducer;

export const {
    openCreateRisk,
    hideCreateRisk,

    openEditRisk,
    hideEditRisk,

    openDeleteRisk,
    hideDeleteRisk,

    openEditRegistry,
    hideEditRegistry,
    openDeleteRegistry,
    hideDeleteRegistry,

    openReleaseRegistry,
    hideReleaseRegistry,

    openDeleteModel,
    hideDeleteModel,

    toggleRisksQuantitative,
    toggleRisksViewTypeBlock,

    openAddActionOwner,
    hideAddActionOwner,

    setSelectedModel,
    deselectedModel,
    setSelectedRegistry,
    setSelectedRisk,
    deselectedRegistry,
    deselectedRisk,

    eraseRegistries,
    eraseRisks,
    setTableNeedsSave,
    cleanUp,

    openSaveRisk,
    hideSaveRisk,
    setRiskEdited,
    setImportCsvDialogAction,
    openExportCsv,
    hideExportCsv,

    replaceRegistriesMinMaxLoadedPage,
    replaceRegistryRisksMinMaxLoadedPage,
    cleanRegistries,
    cleanRegistryRisks
} = RiskManagementSlice.actions;

const selectSelf = (state: AppState):riskRegistryInitialState => state.RiskManagement as riskRegistryInitialState;

export const models = createSelector(selectSelf, state => state.models);
export const selectedModel = createSelector(selectSelf, state => state.selectedModel);


export const registryRisksPageInfo = createSelector(selectSelf, state => state.registryRisksPageInfo);
export const selectedRisk = createSelector(selectSelf, state => state.selectedRisk);
export const registryRisks = createSelector(selectSelf, state => state.registryRisks);

export const loadings = createSelector(selectSelf, state => state.loadings);
export const dialogs = createSelector(selectSelf, state => state.dialogs);

export const registriesPageInfo = createSelector(selectSelf, state => state.registriesPageInfo);
export const registries = createSelector(selectSelf, state => state.registries);
export const selectedRegistry = createSelector(selectSelf, state => state.selectedRegistry);

export const risksViewTypeBlock = createSelector(selectSelf, state => state.risksViewTypeBlock);
export const risksQuantitative = createSelector(selectSelf, state => state.risksQuantitative);
export const isTableNeedsUpdate = createSelector(selectSelf, state => state.isTableNeedsUpdate);

export const employees = createSelector(selectSelf, state => state.employees);

export const registriesMinMaxLoadedPage = createSelector(selectSelf, state => state.registriesMinMaxLoadedPage);
export const registryRisksMinMaxLoadedPage = createSelector(selectSelf, state => state.registryRisksMinMaxLoadedPage);



