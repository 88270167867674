import * as S from '../styled';
import {
    IconButton,
    Menu,
    MenuItem,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import React from "react";
import {TableRowSkeleton} from "../../../../../newShared/components/tableRowSkeleton";
import {useSelector} from "react-redux";
import {employeeHistoryPageInfo, loadings, selectedEmployee} from "../../store/slice";
import {TEmployeeJobHistory} from "../../types";
import {useHistoryRow} from "../../hooks/useHistoryRow";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {TLanguage} from "../../../../../newShared/hooks/useMainTranslationHooks/types";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
//icons
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {Flex} from '../../../../../newShared/components/Layouts';
import {useMedia} from "../../../../../newShared/hooks/useMedia";

export const JobHistoryTable = () => {
    //job history will be updated directly from dialogs into redux
    //employeesExact has a useEffect to watch after redux employee value
    //so this table only to show and dispatch actions + load more
    const {isTablet} = useMedia();
    const {t, revDir, currentLanguage} = useMainTranslation('', {keyPrefix: 'pathHRManagement.exact'});
    const jobHistory = useSelector(selectedEmployee)?.jobInfos ?? []; 
    const _pageInfo = useSelector(employeeHistoryPageInfo);
    const isLoading = useSelector(loadings).getEmployeeHistory;
    const hasMore = _pageInfo.total > ((_pageInfo.page + 1) * _pageInfo.count) || isLoading;
    //GetHrEmployeeJobInfosById

    return(
        <>
            {isTablet ?
                <S.EmployeesJobHistoryCardsContainer>
                    {jobHistory.map((e) => <JobHistoryCard
                        key={e.id}
                        history={e}
                        currentLanguage={currentLanguage}
                        deleteDisabled={jobHistory.length === 1}
                        revDir={revDir}/>
                    )}
                    {hasMore && <JobHistorySkeleton />}
                </S.EmployeesJobHistoryCardsContainer>
                :
                <S.EmployeesJobHistoryTableContainer>
                    <TableContainer
                        // onScroll={scroll.onScroll}
                        // ref={scroll.scrollRef}
                        sx={{
                            width: '100%',
                            boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                            borderRadius: '8px !important',
                            border: `1px solid #EAECF0`,
                            // height: '100%',
                            // flexGrow: '1',
                            minWidth: '950px'
                        }}
                    >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead sx={{backgroundColor: colors.backgrounds.grey_light}}>
                                <TableRow sx={{'& .MuiTableCell-root': {backgroundColor: colors.backgrounds.grey_light}}}>
                                    <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                                        <Typography variant={'body2'}
                                                    color={colors.grayText}>{t('Effective date')}</Typography>
                                    </TableCell>

                                    <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                                        <Typography variant={'body2'} color={colors.grayText}>{t('Department')}</Typography>
                                    </TableCell>

                                    <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                                        <Typography variant={'body2'}
                                                    color={colors.grayText}>{t('Employment type')}</Typography>
                                    </TableCell>

                                    <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                                        <Typography variant={'body2'} color={colors.grayText}>{t('Job title')}</Typography>
                                    </TableCell>

                                    <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                                        <Typography variant={'body2'} color={colors.grayText}>{t('Manager')}</Typography>
                                    </TableCell>

                                    <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                                        <Typography variant={'body2'} color={colors.grayText}>{t('Division')}</Typography>
                                    </TableCell>

                                    <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                                        <Typography variant={'body2'} color={colors.grayText}>{t('Location')}</Typography>
                                    </TableCell>

                                    <TableCell>
                                        <Typography variant={'body2'} color={colors.primary.blue}>{t('Actions')}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody sx={{
                                '& .MuiTableRow-root:last-child td': {
                                    borderBottom: `1px solid ${colors.primary.disable}`
                                }
                            }}>
                                {jobHistory.map((e) => <JobHistoryRow key={e.id} history={e}
                                                                      currentLanguage={currentLanguage}
                                                                      deleteDisabled={jobHistory.length === 1}
                                                                      revDir={revDir}/>)}
                                {(hasMore) && <TableRowSkeleton columns={8}/>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </S.EmployeesJobHistoryTableContainer>
            }
        </>
    )
}

export const JobHistoryRow = ({history, currentLanguage, revDir, deleteDisabled = false}: {history: TEmployeeJobHistory, currentLanguage: TLanguage, revDir: boolean, deleteDisabled?: boolean}) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathHRManagement.exact'});

    const {menu, handleDeleteRow, handleUpdateRow} = useHistoryRow({history});

    return(
        <TableRow sx={{
            backgroundColor: history.current ? colors.backgrounds.blue_light_4 : undefined
        }}>
            <TableCell>
                <Typography variant={'subtitle1'} color={colors.grayText}>{parseDateAuto(history.startDate)}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant={'subtitle1'} color={colors.grayText}>{history.department}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant={'subtitle1'} color={colors.grayText}>{history.employmentType}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant={'subtitle1'} color={colors.grayText}>{history.jobTitle.name}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant={'subtitle1'} color={colors.grayText}>{history.manager?.name ??  (history.manager?.id ? t('Deleted employee') : '-')}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant={'subtitle1'} color={colors.grayText}>{history.division}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant={'subtitle1'} color={colors.grayText}>{history.location}</Typography>
            </TableCell>

            <TableCell>
                <>
                    <IconButton
                        size={'small'}
                        sx={{margin: !revDir ? '0 0 0 16px' : '0 16px 0 0'}}
                        aria-label="more"
                        id="long-button"
                        aria-controls={menu.open ? 'long-menu' : undefined}
                        aria-expanded={menu.open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {
                            if(menu.open){
                                menu.handleClose()
                            }else{
                                e.stopPropagation()
                                menu.handleClick(e)
                            }
                        }}
                        color={"primary"}
                    >
                        {<MoreHorizIcon />}
                    </IconButton>

                    <Menu
                        id="long-menu"
                        MenuListProps={{'aria-labelledby': 'long-button',}}
                        anchorEl={menu.anchorEl}
                        open={menu.open}
                        onClose={menu.handleClose}
                        onBackdropClick={(e) => {
                            e.stopPropagation()
                            menu.handleClose();
                        }}
                        PaperProps={{
                            style: {
                                boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)',
                                borderRadius: '8px',
                                border: '1px solid #F2F4F7'
                            },
                        }}
                        sx={{
                            '& .MuiMenu-list': {
                                padding: '16px'
                            },
                            '& .MuiMenuItem-root': {
                                margin: '0 auto',
                                borderRadius: '4px',
                                '&:hover': {
                                    backgroundColor: '#F2F4F7'
                                }
                            }
                        }}
                    >
                        <MenuItem onClick={handleUpdateRow} sx={{gap: '8px'}}>
                            <EditIcon style={{color: colors.text.grey_dark}}/>
                            <Typography variant={'body2'} color={colors.grayText}>{t('Edit row')}</Typography>
                        </MenuItem>

                        <MenuItem onClick={handleDeleteRow} sx={{gap: '8px'}} disabled={deleteDisabled}>
                            <DeleteIcon style={{color: colors.text.critical}}/>
                            <Typography variant={'body2'} color={colors.text.critical}>{t('Delete row')}</Typography>
                        </MenuItem>
                    </Menu>
                </>
            </TableCell>
        </TableRow>
    )
}


export const JobHistoryCard = ({history, currentLanguage, revDir, deleteDisabled = false}: {history: TEmployeeJobHistory, currentLanguage: TLanguage, revDir: boolean, deleteDisabled?: boolean}) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathHRManagement.exact'});
    const {menu, handleDeleteRow, handleUpdateRow} = useHistoryRow({history});

    return(
        <S.EmployeeHistoryCardContainer style={{backgroundColor: history.current ? colors.backgrounds.blue_light_4 : undefined}}>
            <Flex jc={'flex-end'} w={'100%'}>
                <>
                    <IconButton
                        size={'small'}
                        sx={{margin: !revDir ? '0 0 0 16px' : '0 16px 0 0'}}
                        aria-label="more"
                        id="long-button"
                        aria-controls={menu.open ? 'long-menu' : undefined}
                        aria-expanded={menu.open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {
                            if(menu.open){
                                menu.handleClose()
                            }else{
                                e.stopPropagation()
                                menu.handleClick(e)
                            }
                        }}
                        color={"primary"}
                    >
                        {<MoreHorizIcon />}
                    </IconButton>

                    <Menu
                        id="long-menu"
                        MenuListProps={{'aria-labelledby': 'long-button',}}
                        anchorEl={menu.anchorEl}
                        open={menu.open}
                        onClose={menu.handleClose}
                        onBackdropClick={(e) => {
                            e.stopPropagation()
                            menu.handleClose();
                        }}
                        PaperProps={{
                            style: {
                                boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)',
                                borderRadius: '8px',
                                border: '1px solid #F2F4F7'
                            },
                        }}
                        sx={{
                            '& .MuiMenu-list': {
                                padding: '16px'
                            },
                            '& .MuiMenuItem-root': {
                                margin: '0 auto',
                                borderRadius: '4px',
                                '&:hover': {
                                    backgroundColor: '#F2F4F7'
                                }
                            }
                        }}
                    >
                        <MenuItem onClick={handleUpdateRow} sx={{gap: '8px'}}>
                            <EditIcon style={{color: colors.text.grey_dark}}/>
                            <Typography variant={'body2'} color={colors.grayText}>{t('Edit row')}</Typography>
                        </MenuItem>

                        <MenuItem onClick={handleDeleteRow} sx={{gap: '8px'}} disabled={deleteDisabled}>
                            <DeleteIcon style={{color: colors.text.critical}}/>
                            <Typography variant={'body2'} color={colors.text.critical}>{t('Delete row')}</Typography>
                        </MenuItem>
                    </Menu>
                </>
            </Flex>

            <KeyValue _key={t('Effective date')} value={parseDateAuto(history.startDate)}/>
            <KeyValue _key={t('Department')} value={history.department}/>
            <KeyValue _key={t('Job title')} value={history.jobTitle.name}/>
            <KeyValue _key={t('Manager')} value={history.manager?.name}/>
            <KeyValue _key={t('Employment Type')} value={history.employmentType}/>
            <KeyValue _key={t('Division')} value={history.division}/>
            <KeyValue _key={t('Location')} value={history.location}/>
        </S.EmployeeHistoryCardContainer>
    )
}

export const JobHistorySkeleton = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathHRManagement.exact'});
    return(
        <S.EmployeeHistoryCardContainer>
            <Flex jc={'flex-end'} w={'100%'}>
                <>
                    <IconButton
                        size={'small'}
                        sx={{margin: !revDir ? '0 0 0 16px' : '0 16px 0 0'}}
                        aria-label="more"
                        id="long-button"
                        aria-haspopup="true"
                        color={"primary"}
                        disabled
                    >
                        {<MoreHorizIcon />}
                    </IconButton>
                </>
            </Flex>

            <KeyValue _key={t('Effective date')} value={'SKELETON'}/>
            <KeyValue _key={t('Department')} value={'SKELETON'}/>
            <KeyValue _key={t('Job title')} value={'SKELETON'}/>
            <KeyValue _key={t('Manager')} value={'SKELETON'}/>
            <KeyValue _key={t('Employment Type')} value={'SKELETON'}/>
            <KeyValue _key={t('Division')} value={'SKELETON'}/>
            <KeyValue _key={t('Location')} value={'SKELETON'}/>
        </S.EmployeeHistoryCardContainer>
    )
}

export const KeyValue = ({_key, value, margin = '16px 0 0 0'}: {_key: string, value: string | undefined | null, margin?: string}) => {
    return(
        <>
            <Flex ai={'center'} gap={'16px'} m={margin} w={'100%'}>
                <Typography variant={'subtitle1'} fontWeight={700} color={colors.grayText} sx={{lineHeight: 'normal'}}>{_key}</Typography>
                <Typography variant={'body2'} color={colors.grayText} sx={{lineHeight: 'normal'}}>{(value && value.length > 0) ? (value === 'SKELETON' ? <Skeleton width={50}/> : value) : '-'}</Typography>
            </Flex>
        </>
    )
}