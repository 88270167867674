import {useDispatch, useSelector} from "react-redux";
import {hidePreviewRoleDialog, workspacesDialogs} from "../../../../store/slice";

export const usePreviewRoleDialog = () => {
    //root
    const dispatch = useDispatch();
    const {previewRole} = useSelector(workspacesDialogs);

    //actions
    const handleClose = () => {
        dispatch(hidePreviewRoleDialog());
    }

    return{
        isOpen: previewRole.isOpen,
        handleClose,
        role: previewRole.role
    }
}