//core
//redux

//material
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from "@mui/material";
import {TextArea} from "../../../../../../../newShared/components/Inputs";
import {useEditFramework} from "../../../../hooks/dialogs/useEditFramework";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {UploadFileBTN} from "../../../UploadFileBTN";
import {ACCEPT_IMAGE_PROP} from "../../../../../../../newShared/constants";
import colors from "../../../../../../../newShared/theme/colors";
import {LoadingButton} from "@mui/lab";
import {ReactComponent as DefaultLogoIcon} from '../../../../../../../newShared/images/logo/frameworkDefaultLogo.svg'
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";

//types

export const EditFrameworkDialog = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'builderPage.dialogs'});

    const {
        fileName,
        isOpen,
        tmpFramework,
        handleSave,
        handleClose,
        handleChange,
        // handleChangeName,
        // handleChangeType,
        // handleChangeDescription,
        handleGenerateLogo,
        handleDeleteLogo,
        setLogo,
        errorMessage,
        isLoading,
        validate,
    } = useEditFramework();


    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Edit framework')}</DialogTitle>
            <DialogContent>
                <Box display={'flex'} width={'100%'} alignItems={'center'}>
                    { (tmpFramework && (fileName === 'logo' || fileName !== '')) ? (
                        <Box display={'flex'} alignItems={'center'} width={'100%'}>
                            <Avatar
                                src={(tmpFramework?.logo && tmpFramework.logo.length > 0) ? tmpFramework.logo : undefined}
                                sx={{width: '35px', height: '35px', mr: !revDir ? '6px' : undefined, ml: revDir ? '6px' : undefined, backgroundColor: 'inherit'}}
                            />
                            {/*<Typography fontSize={'12px'} fontWeight={'400'} lineHeight={'20px'}>{fileName}</Typography>*/}
                            <DeleteIcon sx={{width: '18px', height: '20px', ml: '8px', mr: '8px', color: colors.text.grey, cursor: 'pointer'}} onClick={handleDeleteLogo} />
                        </Box>
                    ) : (
                        <>
                            <UploadFileBTN accept={ACCEPT_IMAGE_PROP} id={'frameLogo'}
                                           onChange={(e) => setLogo(e.currentTarget.files![0])}
                                           variant={'text'}
                                           text={t('Upload your logo')}
                                           sx={{width: 'max-content', '&.MuiButton-text': {textTransform: 'none', pl: '0px', pr: '0px'}}}/>

                            <Typography variant={'body2'} sx={{ml: '8px', mr: '8px'}}>{t('or')}</Typography>

                            <Avatar
                                src={(tmpFramework?.logo && tmpFramework.logo.length > 0) ? tmpFramework.logo : undefined}
                                sx={{width: '35px', height: '35px', mr: !revDir ? '6px' : undefined, ml: revDir ? '6px' : undefined, backgroundColor: 'inherit'}}
                                children={<DefaultLogoIcon style={{width: '32px', height: '32px', color: 'inherit'}}/>}
                            />

                            <Button variant={'text'} sx={{'&.MuiButton-text': {textTransform: 'none', pl: '0px', pr: '0px'}}} onClick={handleGenerateLogo}>{t('Generate logo')}</Button>
                        </>
                    )}
                </Box>
                {errorMessage ?
                    <Typography variant={'body2'} color={colors.text.critical}>{errorMessage}</Typography>
                    :
                    <>
                        {fileName === '' && <Typography variant={'body2'} >{t('Logo (rectangle image up to 1 MB)')} </Typography>}
                    </>

                }


                <TextField
                    required
                    sx={{width: '100%', mt: '16px', '& label': {top: '0 !important'}}}
                    size={'small'}
                    // margin="dense"
                    id="name"
                    label={t("Name")}
                    type="text"
                    value={tmpFramework?.name || ''}
                    name={'name'}
                    onChange={handleChange}
                />


                <TextField
                    sx={{width: '100%', mt: '16px', '& label': {top: '0 !important'}}}
                    size={'small'}
                    // margin="dense"
                    id="name"
                    label={t("Type")}
                    type="text"
                    value={tmpFramework?.type || ''}
                    name={'type'}
                    onChange={handleChange}
                />

                <TextArea
                    sx={{mt: '16px'}}
                    // margin="dense"
                    id="name"
                    label={t("Description")}
                    type="text"
                    value={tmpFramework?.description || ''}
                    name={'description'}
                    onChange={handleChange}
                    rows={5}
                    minRows={5}
                />


            </DialogContent>
            <DialogActions>
                <Button variant={'text'} sx={{'&.MuiButton-text': {textTransform: 'none'}}} onClick={handleClose} >{t('Cancel')}</Button>

                <LoadingButton size={'medium'}
                               variant={'contained'}
                               disabled={!validate() || isLoading}
                               onClick={handleSave} loading={isLoading }
                               loadingPosition={isLoading ? 'end' : undefined}
                               endIcon={isLoading ? <div style={{width: '10px', height: '10px', marginLeft: revDir ? '12px' : undefined, marginRight: !revDir ? '12px' : undefined }}/> : undefined}
                >
                    {t('Save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
