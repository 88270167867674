import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import colors from "../../../../../../../newShared/theme/colors";
import {LoadingButton} from "@mui/lab";
import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideTerminateDialog, loadings, selectedExecutor} from "../../../../store/slice";
import {TerminateExecutor} from "../../../../store/actions";

export const TerminateExecutorDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.CampaignReviewExecutorExact.dialogs'});

    const dispatch = useDispatch();
    const isOpen = useSelector(dialogs).terminateExecutor;
    const isLoading = useSelector(loadings).isLoadingTerminate;

    const executor = useSelector(selectedExecutor);

    const handleClose = () => {
        dispatch(hideTerminateDialog());
    }

    const handleSubmit = () => {
        executor && dispatch(TerminateExecutor({data: {instructionId: executor.instructionId}}));
    }

    const {revDir} = useMainTranslation();

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t(`Terminate {{name}} campaign review?`, {name: executor?.name ?? ''})}</DialogTitle>

            <DialogContent>
                <Typography variant={'body2'} color={colors.darkBlueText}>
                    {t(`All progress of this campaign review will be lost`)}
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                >
                    {t('Terminate')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}