import {
    AccessManagementApp,
    AccessManagementAppAccount,
    accessManagementInitialState,
    applicationAccessDataWithoutAccounts,
    campaignReviewExecutorAccountFillableType,
    campaignReviewExecutorType,
    campaignReviewInstructionType,
    smallCampaignReviewExecutorType,
    TAppDuties,
    TConflictException,
    TDutyRule,
    TRulesConflict
} from "../types";

import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {
    addRulesConflictsAction,
    allowConflictCustomAction,
    allowConflictStandardAction,
    AssignStageToMainControl,
    ChangeAccessManagementStatus,
    CreateAccessManagementAppByVendor,
    createAppDutyAction,
    CreateCampaignReviewInstruction,
    createDutyRuleAction,
    CreateNewAccessManagementApp,
    DeleteAccessManagementCustomAppById,
    deleteAppDutyAction,
    DeleteCampaignReviewInstruction,
    deleteConflictExceptionAction,
    deleteDutyRuleAction,
    denyConflictAction,
    EditAccessManagementApp,
    ExportCsvAccessManagementApp,
    ExportCsvRulesetAction,
    GetAccessManagementAppById,
    GetAccessManagementApps,
    GetAccessManagementAvailableFieldsForExport,
    GetAppDutiesAction,
    GetCampaignReviewInstructionById,
    GetCampaignReviewInstructionWithPaging,
    getCsvFieldsAccessManagementAction,
    getDataForAllowedConflictAction,
    GetDataForCreateApp,
    getDataForCreateDutyAction,
    getDataForCreateRuleAction,
    getDataForDenyConflictAction,
    GetDataForRelease,
    GetDutiesRulesetAction,
    GetDutyRuleByIdAction,
    GetEmployeesNameEmail,
    GetFullCampaignReviewExecutorById,
    getRoleNamesAction,
    getRulesConflictsAction,
    GetSmallCampaignReviewExecutors,
    GetSodSchedulerDataAction,
    ImportCsvAccessManagementAppTemplate,
    ImportCsvRulesetAction,
    ImportCsvRulesetTemplateAction,
    performCsvAccessManagementAction,
    ReleaseAccessManagementApp,
    RunCampaignReviewInstruction,
    runRuleCheckAction,
    TerminateExecutor,
    ToggleCampaignReviewInstructionStatus,
    UpdateAccessManagementAppNameAndVendor,
    updateAppDutyAction,
    UpdateCampaignReviewInstruction,
    UpdateCampaignReviewRows,
    updateConflictExceptionAction,
    updateDutyRuleAction,
    UpdateNonCustomAccessManagementAppRow,
    VerifyActorChanges
} from "./actions";
import {defaultPageInfo} from "../../../authWorkspacesCookies/settings/store/slice";
import {COMPLETE_STATUS, IN_PROGRESS_STATUS, TERMINATED_STATUS} from "../constants";
import {setPaginationInStore} from "../../../../newShared/hooks/useScroll/helpers";
import {minMaxLoadedPageDefault} from "../../../../newShared/components/genericTable/constants";
import {handlePagination} from "../../../../newShared/components/genericTable/helpers";
import {minMaxLoadedPageType} from "../../../../newShared/components/genericTable/types";
import {
    addImportCsvActionsToBuilder,
    initialImportCsvDialogState,
    setImportCsvDialog
} from "../../../../newShared/components/csvImportDialog/constants";

export const initialState: accessManagementInitialState = {
    loadings: {
        list: false,
        exact: false,
        create: false,
        update: false,
        delete: false,
        exportCsv: false,
        reviewToggle: {
            id: null,
            isLoading: false,
        },
        vendorsAndEmployeesForCreate: false,
        deleteCustom: false,
        editNameAndVendor: false,
        dataForRelease: false,
        releaseFetch: false,
        updateNonCustomOneRow: false,
        //
        campaignReviewInstructionsList: false,
        campaignReviewInstructionsExact: false,
        campaignReviewInstructionsNew: false,
        campaignReviewExecutorsList: false,
        campaignReviewExecutorsExact: false,
        campaignReviewInstructionUpdate: false,
        //
        campaignReviewInstructionRun: false,
        campaignReviewInstructionDelete: false,
        campaignReviewInstructionActiveToggle: false,
        campaignReviewUpdatingRowsWithIds: [],
        campaignReviewAssignToMeEmails: [],
        campaignReviewVerifyChangesForEmails: [],
        isLoadingTerminate: false,
        getEmployees: false,
        //segregation of duties
        dataForDialog: false,
        runRuleCheck: false,
        addRulesConflicts: false,
        denyConflict: false,
        allowConflict: false,
        sodSchedulerData: false,
        updateException: false,
        deleteException: false,
        isLoadingFields: false,
    },
    dialogs: {
        deleteApp: false,
        editApp: false,
        addNewApp: false,
        deleteRowDialog: {
            row: null,
            isOpen: false,
        },

        createRole: false,
        editRole: false,
        deleteRole: false,
        showPermissions: {
            isOpen: false,
            roleName: null,
            rolePermissions: [],
        },
        nonCustomAppAccountMapDialog: {
            isOpen: false,
            row: null
        },
        release: false,
        selectEvidenceForCreateOrEditInstruction: false,
        startCampaignWarning: false,
        deleteCampaignReviewDialog: false,

        showVerifyApplicationRevokeReason: {
            isOpen: false,
            leavedBy: null,
            comment: null,
        },
        terminateExecutor: false,
        addOrEditJustificationDialog: {
            isOpen: false,
            isManager: false,
            app: null,
            account: null,
            stageId: null,
            actorEmail: null,
        },
        viewJustification: {
            isOpen: false,
            email: null,
            justification: null,
        },

        declineVerifyStepDialog: {
            isOpen: false,
            email: null,
            stageId: null,
            isLastVerify: false,
        },

        createDuty: false,
        editDuty: {
            isOpen: false,
            selectedDuty: null,
        },
        deleteDuty: {
            isOpen: false,
            selectedDuty: null,
        },

        editDutyRule: {
            isOpen: false,
            selectedDutyRule: null,
        },
        deleteDutyRule: {
            isOpen: false,
            selectedDutyRule: null,
        },
        runRuleCheck: {
            isOpen: false,
            selectedDutyRule: null,
        },
        terminateRuleCheck: false,
        saveConflictsSuccessDialog: false,
        viewConflict: false,
        allowConflict: false,
        denyConflict: false,
        allowedConflictData: {
            isOpen: false,
            ruleId: null,
            conflictException: null,
        },
        deniedConflictData: {
            isOpen: false,
            conflict: null,
        },
        extendException: {
            isOpen: false,
            exception: null,
            ruleId: null,
        },
        deleteException: {
            isOpen: false,
            exception: null,
            ruleId: null,
        },
        exportCsv: {
            isOpen: false,
            fields: [],
        },

        ...initialImportCsvDialogState,
    },
    apps: [],
    appsMinMaxLoadedPage: minMaxLoadedPageDefault,
    appDuties: [],

    categories: [],
    conflicts: [],
    conflictsMinMaxLoadedPage: minMaxLoadedPageDefault,
    runRuleCheckConflicts: [],
    ruleset: [],
    rulesetMinMaxLoadedPageDefault: minMaxLoadedPageDefault,
    sodScheduler: {
        data: null,
        history: [],
    },
    pageInfo: {
        page: 0,
        count: 0,
        total: 0
    },
    selectedAppOrigin: null,
    selectedAppEditable: null,
    selectedRule: null,
    selectedConflict: null,
    //
    vendors: [],
    vendorSettings: {
        risk: [],
        type: [],
        customFields: [],
        serviceProvided: [],
    },
    employees: [],

    releaseData: {
        frameworks: [],
        controls: [],
        evidences: [],
        // collaborators: [],
    },
    //data for dialogs
    shortApplications: [],
    shortDuties: [],
    shortCollaborators: [],
    shortRoles: [],

    //campaign review
    campaignReviewInstructions: [],
    campaignReviewInstructionsPageInfo: defaultPageInfo,
    campaignReviewExecutorsMinMaxLoadedPage: minMaxLoadedPageDefault,

    campaignReviewExecutors: [],
    campaignReviewExecutorsPageInfo: defaultPageInfo,
    campaignReviewInstructionsMinMaxLoadedPage: minMaxLoadedPageDefault,

    selectedExecutor: null,
    selectedInstruction: null,

    employeesIdName: []

}

export const AccessManagementSlice = createSlice({
    name: 'accessManagement',
    initialState,
    reducers: {
        replaceMinMaxLoadedPageExecutor: (slice, {payload}: {payload: minMaxLoadedPageType}) => {slice.campaignReviewExecutorsMinMaxLoadedPage = payload},
        replaceMinMaxLoadedPageInstruction: (slice, {payload}: {payload: minMaxLoadedPageType}) => {slice.campaignReviewInstructionsMinMaxLoadedPage = payload},
        replaceMinMaxLoadedPageConflicts: (slice, {payload}: {payload: minMaxLoadedPageType}) => {slice.conflictsMinMaxLoadedPage = payload},
        clearCampaignReviewExecutors: (slice) => {
            slice.campaignReviewExecutors = [];
            slice.campaignReviewExecutorsPageInfo = initialState.campaignReviewExecutorsPageInfo;
        },
        clearCampaignReviewInstructions: (slice) => {
            slice.campaignReviewInstructions = [];
            slice.campaignReviewInstructionsPageInfo = initialState.campaignReviewInstructionsPageInfo;
        },
        clearSelectedCampaignReviewExecutor: (slice) => {
            slice.selectedExecutor = null;
        },

        openAddNewApp: (slice) => {slice.dialogs.addNewApp = true},
        hideAddNewApp: (slice) => {slice.dialogs.addNewApp = false},

        openDeleteApp: (slice) => {slice.dialogs.deleteApp = true},
        hideDeleteApp: (slice) => {slice.dialogs.deleteApp = false},

        openEditApp: (slice) => {slice.dialogs.editApp = true},
        hideEditApp: (slice) => {slice.dialogs.editApp = false},

        setImportCsvDialogAction: setImportCsvDialog,

        openExportCsv: (slice) => {
            slice.dialogs.exportCsv.isOpen = true;
        },
        hideExportCsv: (slice) => {
            slice.dialogs.exportCsv = initialState.dialogs.exportCsv;
        },

        openDeleteRow: (slice, {payload}: {payload: AccessManagementAppAccount}) => {slice.dialogs.deleteRowDialog = {row: payload, isOpen: true}},
        hideDeleteRow: (slice) => {slice.dialogs.deleteRowDialog = {row: null, isOpen: false}},

        openCreateRole: (slice) => {slice.dialogs.createRole = true},
        hideCreateRole: (slice) => {slice.dialogs.createRole = false},

        openEditRole: (slice) => {slice.dialogs.editRole = true},
        hideEditRole: (slice) => {slice.dialogs.editRole = false},

        openDeleteRole: (slice) => {slice.dialogs.deleteRole = true},
        hideDeleteRole: (slice) => {slice.dialogs.deleteRole = false},

        openReleaseDialog: (slice) => {slice.dialogs.release = true},
        hideReleaseDialog: (slice) => {slice.dialogs.release = false},

        openShowPermissions: (slice, {payload}: {payload: {roleName: string, rolePermissions: string[]}}) => {slice.dialogs.showPermissions = {...payload, isOpen: true}},
        hideShowPermissions: (slice) => {slice.dialogs.showPermissions = {roleName: null, rolePermissions: [], isOpen: false}},

        openCreateDuty: (slice) => {slice.dialogs.createDuty = true},
        hideCreateDuty: (slice) => {slice.dialogs.createDuty = false},

        openAllowedConflictData: (slice, { payload }: {payload: { ruleId: string }}) => {
            if(payload.ruleId !== undefined) {
                // slice.dialogs.allowedConflictData.isOpen = true;
                slice.dialogs.allowedConflictData.ruleId = payload.ruleId;
            }
        },
        hideAllowedConflictData: (slice) => {slice.dialogs.allowedConflictData = initialState.dialogs.allowedConflictData},
        openDeniedConflictData: (slice, { payload }: {payload: { conflict: TRulesConflict }}) => {
            if(payload.conflict !== undefined) {
                slice.dialogs.deniedConflictData.isOpen = true;
                slice.dialogs.deniedConflictData.conflict = payload.conflict;
            }
        },
        hideDeniedConflictData: (slice) => {slice.dialogs.deniedConflictData = initialState.dialogs.deniedConflictData},

        cleanAppDuties: (slice) => {slice.appDuties = initialState.appDuties},
        cleanRuleset: (slice) => {slice.ruleset = []; slice.pageInfo = initialState.pageInfo},
        cleanConflicts: (slice) => {slice.conflicts = initialState.conflicts; slice.pageInfo = initialState.pageInfo},
        cleanScheduler: (slice) => {slice.sodScheduler = initialState.sodScheduler},

        openEditDuty: (slice, {payload}) => {
            slice.dialogs.editDuty.isOpen = true;
            if (payload.selectedDuty !== undefined) slice.dialogs.editDuty.selectedDuty = payload.selectedDuty;
        },
        hideEditDuty: (slice) => {
            slice.dialogs.editDuty.isOpen = false;
            slice.dialogs.editDuty.selectedDuty = null;
        },

        openDeleteDuty: (slice, {payload}) => {
            slice.dialogs.deleteDuty.isOpen = true;
            if (payload.selectedDuty !== undefined) slice.dialogs.deleteDuty.selectedDuty = payload.selectedDuty;
        },
        hideDeleteDuty: (slice) => {
            slice.dialogs.deleteDuty.isOpen = false;
            slice.dialogs.deleteDuty.selectedDuty = null;
        },

        openEditDutyRule: (slice, {payload}: {payload: {selectedRule: TDutyRule}}) => {
            slice.dialogs.editDutyRule.isOpen = true;
            if (payload.selectedRule !== undefined) slice.dialogs.editDutyRule.selectedDutyRule = payload.selectedRule;
        },
        hideEditDutyRule: (slice) => {
            slice.dialogs.editDutyRule.isOpen = false;
            slice.dialogs.editDutyRule.selectedDutyRule = null;
        },

        openDeleteDutyRule: (slice, {payload}: {payload: {selectedRule: TDutyRule}}) => {
            slice.dialogs.deleteDutyRule.isOpen = true;
            if (payload.selectedRule !== undefined) slice.dialogs.deleteDutyRule.selectedDutyRule = payload.selectedRule;
        },
        hideDeleteDutyRule: (slice) => {
            slice.dialogs.deleteDutyRule.isOpen = false;
            slice.dialogs.deleteDutyRule.selectedDutyRule = null;
        },
        openRunRuleCheck: (slice, {payload}) => {
            slice.dialogs.runRuleCheck.isOpen = true;
            if (payload.selectedDutyRule !== undefined) slice.dialogs.runRuleCheck.selectedDutyRule = payload.selectedDutyRule;
        },
        hideRunRuleCheck: (slice) => {
            slice.dialogs.runRuleCheck.isOpen = false;
            slice.dialogs.runRuleCheck.selectedDutyRule = null;
            slice.conflicts = initialState.conflicts;
        },
        openTerminateRuleCheck: (slice) => {slice.dialogs.terminateRuleCheck = true},
        hideTerminateRuleCheck: (slice) => {slice.dialogs.terminateRuleCheck = false},

        openSaveConflictsSuccess: (slice) => {slice.dialogs.saveConflictsSuccessDialog = true},
        hideSaveConflictsSuccess: (slice) => {slice.dialogs.saveConflictsSuccessDialog = false},

        openViewConflict: (slice) => {slice.dialogs.viewConflict = true},
        hideViewConflict: (slice) => {slice.dialogs.viewConflict = false},

        openAllowConflict: (slice) => {slice.dialogs.allowConflict = true},
        hideAllowConflict: (slice) => {
            slice.dialogs.allowConflict = false;
            slice.selectedConflict = null;
        },
        openDenyConflict: (slice) => {slice.dialogs.denyConflict = true},
        hideDenyConflict: (slice) => {
            slice.dialogs.denyConflict = false;
            slice.selectedConflict = null;
        },
        openExtendException: (slice, {payload}: {payload: {exception: TConflictException, ruleId: string}}) => {
            if (payload !== undefined) {
                slice.dialogs.extendException.isOpen = true;
                slice.dialogs.extendException.exception = payload.exception;
                slice.dialogs.extendException.ruleId = payload.ruleId;
            }

        },
        hideExtendException: (slice) => {
            slice.dialogs.extendException.isOpen = false;
            slice.dialogs.extendException.exception = null;
            slice.dialogs.extendException.ruleId = null;
        },

        openDeleteException: (slice, {payload}: {payload: {exception: TConflictException, ruleId: string}}) => {
            if (payload !== undefined) {
                slice.dialogs.deleteException.isOpen = true;
                slice.dialogs.deleteException.exception = payload.exception;
                slice.dialogs.deleteException.ruleId = payload.ruleId;
            }

        },
        hideDeleteException: (slice) => {
            slice.dialogs.deleteException.isOpen = false;
            slice.dialogs.deleteException.exception = null;
            slice.dialogs.deleteException.ruleId = null;
        },

        cleanUp: () => initialState,

        //row actions
        deleteRow: (slice, {payload}: {payload: AccessManagementAppAccount}) => {
            if(slice.selectedAppEditable && slice.selectedAppOrigin){
                slice.selectedAppEditable.dataTable = slice.selectedAppEditable.dataTable.filter(e => e.account !== payload.account);
                slice.dialogs.deleteRowDialog = {row: null, isOpen: false};
            }
        },


        createRow: (slice, {payload}: {payload: AccessManagementAppAccount}) => {
            if(slice.selectedAppEditable && slice.selectedAppOrigin) {
                slice.selectedAppEditable.dataTable.push(payload);
            }
        },

        editRow: (slice, {payload}: {payload: AccessManagementAppAccount}) => {
            if(slice.selectedAppEditable && slice.selectedAppOrigin) {
                slice.selectedAppEditable.dataTable = slice.selectedAppEditable.dataTable.map(e => e.account === payload.account ? payload : e);
                slice.dialogs.nonCustomAppAccountMapDialog = {isOpen: false, row: null};
            }
        },

        editApp: (slice, {payload}: {payload: {name: string, vendorId: string, idName: { id: string; name: string; } | null}}) => {
            if(slice.selectedAppEditable && slice.selectedAppOrigin){
                slice.selectedAppEditable.name = payload.name;
                slice.selectedAppEditable.vendorId = payload.vendorId;
                slice.selectedAppEditable.vendorOwner = payload.idName;
            }
            slice.dialogs.editApp = false;
        },

        //other
        setSelectedApp: (slice, {payload}: {payload: AccessManagementApp}) => {
            slice.selectedAppEditable = payload;
            slice.selectedAppOrigin = payload;
        },

        eraseSelectedApp: (slice) => {
            slice.selectedAppOrigin = null;
            slice.selectedAppEditable = null;
            slice.pageInfo = {
                page: 0,
                total: 0,
                count: 0
            }
            slice.apps = [];
        },

        selectRule: (slice, {payload}: {payload: TDutyRule}) => {
            if(payload !== undefined) slice.selectedRule = payload;
        },
        deselectRule: (slice) => {
            slice.selectedRule = null;
        },

        selectConflict: (slice, {payload}: {payload: TRulesConflict}) => {
            if(payload !== undefined) slice.selectedConflict = payload;
        },
        deselectConflict: (slice) => {slice.selectedConflict = null},

        revertChanges: (slice) => {
            slice.selectedAppEditable = slice.selectedAppOrigin;
        },

        openNonCustomAppAccountMapDialog: (slice, {payload}: {payload: AccessManagementAppAccount}) => {
            slice.dialogs.nonCustomAppAccountMapDialog = {
                isOpen: true,
                row: payload,
            }
        },
        hideNonCustomAppAccountMapDialog: (slice) => {
            slice.dialogs.nonCustomAppAccountMapDialog = {
                isOpen: false,
                row: null,
            }
        },

//---------------------------------campaignReview

        selectInstruction: (slice, {payload}: {payload: campaignReviewInstructionType}) => {
            slice.selectedInstruction = payload;
        },
        selectExecutor: (slice, {payload}: {payload: campaignReviewExecutorType}) => {
            slice.selectedExecutor = payload;
        },

        openShowVerifyApplicationRevokeReason: (slice, {payload}: {payload: {leavedBy: string, comment: string}}) => {
            slice.dialogs.showVerifyApplicationRevokeReason = {
                isOpen: true,
                ...payload,
            };
        },
        hideShowVerifyApplicationRevokeReason: (slice) => {
            slice.dialogs.showVerifyApplicationRevokeReason = {
                isOpen: false,
                comment: null,
                leavedBy: null
            };
        },

        openTerminateDialog: (slice) => {slice.dialogs.terminateExecutor = true},
        hideTerminateDialog: (slice) => {slice.dialogs.terminateExecutor = false},

        openSelectEvidence: (slice) => {slice.dialogs.selectEvidenceForCreateOrEditInstruction = true},
        hideSelectEvidence: (slice) => {slice.dialogs.selectEvidenceForCreateOrEditInstruction = false},

        openRunReviewWarning: (slice) => {slice.dialogs.startCampaignWarning = true},
        hideRunReviewWarning: (slice) => {slice.dialogs.startCampaignWarning = false},

        openDeleteInstructionDialog: (slice) => {slice.dialogs.deleteCampaignReviewDialog = true},
        hideDeleteInstructionDialog: (slice) => {slice.dialogs.deleteCampaignReviewDialog = false},

        openAddOrEditJustificationDialog: (slice, {payload}: {payload: {app: applicationAccessDataWithoutAccounts, account: campaignReviewExecutorAccountFillableType, stageId: string, actorEmail: string, isManager: boolean}}) => {
            slice.dialogs.addOrEditJustificationDialog = {
                isOpen: true,
                isManager: payload.isManager,
                app: payload.app,
                account: payload.account,
                stageId: payload.stageId,
                actorEmail: payload.actorEmail,
            }
        },
        hideAddOrEditJustificationDialog: (slice) => {
            slice.dialogs.addOrEditJustificationDialog = {
                isOpen: false,
                isManager: true,
                app: null,
                account: null,
                stageId: null,
                actorEmail: null,
            }
        },

        openViewJustification: (slice, {payload}: {payload: {email: string, justification: string}}) => {
            slice.dialogs.viewJustification = {
                isOpen: true,
                ...payload,
            };
        },
        hideViewJustification: (slice) => {
            slice.dialogs.viewJustification = {
                isOpen: false,
                email: null,
                justification: null
            };
        },

        openDeclineVerifyStepDialog: (slice, {payload}: {payload: {email: string, stageId: string, isLastVerify: boolean}}) => {
            slice.dialogs.declineVerifyStepDialog = {
                isOpen: true,
                ...payload,
            };
        },
        hideDeclineVerifyStepDialog: (slice) => {
            slice.dialogs.declineVerifyStepDialog = {
                isOpen: false,
                email: null,
                stageId: null,
                isLastVerify: false
            };
        },
        replaceAppsMinMaxLoadedPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.appsMinMaxLoadedPage = payload;
        },
        replaceRulesetsMinMaxLoadedPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.rulesetMinMaxLoadedPageDefault = payload;
        },
        cleanUpApps: (slice) => {
            slice.apps = [];
            slice.pageInfo = initialState.pageInfo;
        },
    },
    extraReducers: (builder) => {
        addImportCsvActionsToBuilder<accessManagementInitialState, {}, {id: string}>(builder, getCsvFieldsAccessManagementAction, performCsvAccessManagementAction);

        builder
            .addCase(GetAccessManagementApps.pending, (slice, {meta}) => {
                slice.loadings.list = true;
            })
            .addCase(GetAccessManagementApps.rejected, (slice) => {
                slice.loadings.list = false;
            })
            .addCase(GetAccessManagementApps.fulfilled, (slice, {payload}) => {
                slice.loadings.list = false;

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<AccessManagementApp>(
                    slice.apps,
                    slice.pageInfo,
                    payload.apps,
                    payload.pageInfo,
                    slice.appsMinMaxLoadedPage.minLoadedPage,
                    slice.appsMinMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.apps = results;
                slice.appsMinMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.pageInfo = payload.pageInfo;
            })
        //ChangeAccessManagementStatus
            .addCase(ChangeAccessManagementStatus.pending, (slice, {meta}) => {
                slice.loadings.reviewToggle = {
                    isLoading: true,
                    id: meta.arg.data.appId
                }
            })
            .addCase(ChangeAccessManagementStatus.rejected, (slice) => {
                slice.loadings.reviewToggle = {isLoading: false, id: null};
            })
            .addCase(ChangeAccessManagementStatus.fulfilled, (slice, {payload}) => {
                slice.loadings.reviewToggle = {isLoading: false, id: null};
                slice.apps = slice.apps.map(e => e.id === payload.appId ? {...e, reviewToggle: !e.includeReview} : e);
            })
        //GetDataForCreateApp
            .addCase(GetDataForCreateApp.pending, (slice, {meta}) => {
                slice.loadings.vendorsAndEmployeesForCreate = true;
            })
            .addCase(GetDataForCreateApp.rejected, (slice) => {
                slice.loadings.vendorsAndEmployeesForCreate = false;
            })
            .addCase(GetDataForCreateApp.fulfilled, (slice, {payload}) => {
                slice.loadings.vendorsAndEmployeesForCreate = false;
                slice.employees = payload.employees;
                slice.vendors = payload.vendors;
                slice.vendorSettings = payload.vendorSettings;
            })
        //CreateNewAccessManagementApp
            .addCase(CreateNewAccessManagementApp.pending, (slice, {meta}) => {
                slice.loadings.create = true;
            })
            .addCase(CreateNewAccessManagementApp.rejected, (slice) => {
                slice.loadings.create = false;
            })
            .addCase(CreateNewAccessManagementApp.fulfilled, (slice, {payload}) => {
                slice.loadings.create = false;
                slice.dialogs.addNewApp = false;
                //after return to list page - it will refetch the list and app will be shown twice if add it here
                // slice.apps.push(payload);
                slice.selectedAppOrigin = payload;
                slice.selectedAppEditable = payload;
            })
        //CreateAccessManagementAppByVendor
            .addCase(CreateAccessManagementAppByVendor.pending, (slice, {meta}) => {
                slice.loadings.create = true;
            })
            .addCase(CreateAccessManagementAppByVendor.rejected, (slice) => {
                slice.loadings.create = false;
            })
            .addCase(CreateAccessManagementAppByVendor.fulfilled, (slice, {payload}) => {
                slice.loadings.create = false;
                slice.dialogs.addNewApp = false;
                //after return to list page - it will refetch the list and app will be shown twice if add it here
                // slice.apps.push(payload);
                slice.selectedAppOrigin = payload;
                slice.selectedAppEditable = payload;
            })
        //GetAccessManagementAppById
            .addCase(GetAccessManagementAppById.pending, (slice, {meta}) => {
                slice.loadings.exact = true;
            })
            .addCase(GetAccessManagementAppById.rejected, (slice) => {
                slice.loadings.exact = false;
            })
            .addCase(GetAccessManagementAppById.fulfilled, (slice, {payload}) => {
                slice.loadings.exact = false;
                slice.apps.push(payload);
                slice.selectedAppOrigin = payload;
                slice.selectedAppEditable = payload;
            })
        //DeleteAccessManagementCustomAppById
            .addCase(DeleteAccessManagementCustomAppById.pending, (slice, {meta}) => {
                slice.loadings.deleteCustom = true;
            })
            .addCase(DeleteAccessManagementCustomAppById.rejected, (slice) => {
                slice.loadings.deleteCustom = false;
            })
            .addCase(DeleteAccessManagementCustomAppById.fulfilled, (slice, {payload}) => {
                slice.loadings.deleteCustom = false;
                slice.dialogs.deleteApp = false;
                slice.apps = slice.apps.filter(e => e.id !== payload);
                slice.selectedAppOrigin = null;
                slice.selectedAppEditable = null;
            })
        //EditAccessManagementApp
            .addCase(EditAccessManagementApp.pending, (slice, {meta}) => {
                slice.loadings.update = true;
            })
            .addCase(EditAccessManagementApp.rejected, (slice) => {
                slice.loadings.update = false;
            })
            .addCase(EditAccessManagementApp.fulfilled, (slice, {payload}) => {
                slice.loadings.update = false;
                slice.apps = slice.apps.map(e => e.id === payload.id ? payload: e);
                slice.selectedAppOrigin = payload;
                slice.selectedAppEditable = payload;
            })
        //ImportCsvAccessManagementAppTemplate
            .addCase(ImportCsvAccessManagementAppTemplate.pending, (slice, {meta}) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = true;
            })
            .addCase(ImportCsvAccessManagementAppTemplate.rejected, (slice) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = false;
            })
            .addCase(ImportCsvAccessManagementAppTemplate.fulfilled, (slice, {payload}) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = false;
            })
        //UpdateAccessManagementAppNameAndVendor
            .addCase(UpdateAccessManagementAppNameAndVendor.pending, (slice, {meta}) => {
                slice.loadings.editNameAndVendor = true;
            })
            .addCase(UpdateAccessManagementAppNameAndVendor.rejected, (slice) => {
                slice.loadings.editNameAndVendor = false;
            })
            .addCase(UpdateAccessManagementAppNameAndVendor.fulfilled, (slice, {payload}) => {
                slice.loadings.editNameAndVendor = false;
                slice.dialogs.editApp = false;
                if(slice.selectedAppEditable && slice.selectedAppOrigin){
                    slice.selectedAppEditable = {...slice.selectedAppEditable, ...payload};
                    slice.selectedAppOrigin = {...slice.selectedAppOrigin, ...payload};
                }
                slice.apps = slice.apps.map(e => e.id === payload.id ? {...e, ...payload} : e);
            })
        //ExportCsvAccessManagementApp
            .addCase(ExportCsvAccessManagementApp.pending, (slice, {meta}) => {
                slice.loadings.exportCsv = true;
            })
            .addCase(ExportCsvAccessManagementApp.rejected, (slice) => {
                slice.loadings.exportCsv = false;
            })
            .addCase(ExportCsvAccessManagementApp.fulfilled, (slice, {payload}) => {
                slice.loadings.exportCsv = false;
                slice.dialogs.exportCsv = initialState.dialogs.exportCsv;
            })
            //GetDataForRelease
            .addCase(GetDataForRelease.pending, (slice) => {
                slice.loadings.dataForRelease = true;
            })
            .addCase(GetDataForRelease.rejected, (slice) => {
                slice.loadings.dataForRelease = false;
            })
            .addCase(GetDataForRelease.fulfilled, (slice, {payload}) => {
                slice.loadings.dataForRelease = false;
                slice.releaseData.frameworks = payload.frameworks;
                slice.releaseData.controls = payload.controls;
                slice.releaseData.evidences = payload.evidences;
                // slice.releaseData.collaborators = payload.collaborators;
            })
        //ReleaseAccessManagementApp
            .addCase(ReleaseAccessManagementApp.pending, (slice) => {
                slice.loadings.releaseFetch = true;
            })
            .addCase(ReleaseAccessManagementApp.rejected, (slice) => {
                slice.loadings.releaseFetch = false;
            })
            .addCase(ReleaseAccessManagementApp.fulfilled, (slice, {payload}) => {
                slice.loadings.releaseFetch = false;
                slice.dialogs.release = false;
            })
    //CAMPAIGN REVIEW ENDPOINTS
        //GetCampaignReviewInstructionWithPaging
            .addCase(GetCampaignReviewInstructionWithPaging.pending, (slice) => {
                slice.loadings.campaignReviewInstructionsList = true;
            })
            .addCase(GetCampaignReviewInstructionWithPaging.rejected, (slice) => {
                slice.loadings.campaignReviewInstructionsList = false;
            })
            .addCase(GetCampaignReviewInstructionWithPaging.fulfilled, (slice, {payload}) => {
                slice.loadings.campaignReviewInstructionsList = false;

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<campaignReviewInstructionType>(
                    slice.campaignReviewInstructions,
                    slice.campaignReviewInstructionsPageInfo,
                    payload.instructions,
                    payload.pageInfo,
                    slice.campaignReviewInstructionsMinMaxLoadedPage.minLoadedPage,
                    slice.campaignReviewInstructionsMinMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.campaignReviewInstructions = results;
                slice.campaignReviewInstructionsMinMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.campaignReviewInstructionsPageInfo = payload.pageInfo;
            })
        //GetCampaignReviewInstructionById
            .addCase(GetCampaignReviewInstructionById.pending, (slice) => {
                slice.loadings.campaignReviewInstructionsExact = true;
            })
            .addCase(GetCampaignReviewInstructionById.rejected, (slice) => {
                slice.loadings.campaignReviewInstructionsExact = false;
            })
            .addCase(GetCampaignReviewInstructionById.fulfilled, (slice, {payload}) => {
                slice.loadings.campaignReviewInstructionsExact = false;
                slice.selectedInstruction = payload;
            })
        //CreateCampaignReviewInstruction
            .addCase(CreateCampaignReviewInstruction.pending, (slice) => {
                slice.loadings.campaignReviewInstructionsNew = true;
            })
            .addCase(CreateCampaignReviewInstruction.rejected, (slice) => {
                slice.loadings.campaignReviewInstructionsNew = false;
            })
            .addCase(CreateCampaignReviewInstruction.fulfilled, (slice, {payload}) => {
                slice.loadings.campaignReviewInstructionsNew = false;
                slice.selectedInstruction = payload;
            })
        //UpdateCampaignReviewInstruction
            .addCase(UpdateCampaignReviewInstruction.pending, (slice) => {
                slice.loadings.campaignReviewInstructionUpdate = true;
            })
            .addCase(UpdateCampaignReviewInstruction.rejected, (slice) => {
                slice.loadings.campaignReviewInstructionUpdate = false;
            })
            .addCase(UpdateCampaignReviewInstruction.fulfilled, (slice, {payload}) => {
                slice.loadings.campaignReviewInstructionUpdate = false;
                slice.selectedInstruction = payload;
                slice.campaignReviewInstructions = slice.campaignReviewInstructions.map(e => e.id === payload.id ? payload : e);
            })
        //DeleteCampaignReviewInstruction
            .addCase(DeleteCampaignReviewInstruction.pending, (slice) => {
                slice.loadings.campaignReviewInstructionDelete = true;
            })
            .addCase(DeleteCampaignReviewInstruction.rejected, (slice) => {
                slice.loadings.campaignReviewInstructionDelete = false;
            })
            .addCase(DeleteCampaignReviewInstruction.fulfilled, (slice, {payload}) => {
                slice.loadings.campaignReviewInstructionDelete = false;
                slice.dialogs.deleteCampaignReviewDialog = false;
                slice.selectedInstruction = null;
                slice.campaignReviewInstructionsPageInfo.total = slice.campaignReviewInstructionsPageInfo.total - 1;
                slice.campaignReviewInstructions = slice.campaignReviewInstructions.filter(e => e.id !== payload);
            })
        //RunCampaignReviewInstruction
            .addCase(RunCampaignReviewInstruction.pending, (slice) => {
                slice.loadings.campaignReviewInstructionRun = true;
            })
            .addCase(RunCampaignReviewInstruction.rejected, (slice) => {
                slice.loadings.campaignReviewInstructionRun = false;
            })
            .addCase(RunCampaignReviewInstruction.fulfilled, (slice, {payload}) => {
                slice.loadings.campaignReviewInstructionRun = false;
                slice.dialogs.startCampaignWarning = false;
                const updatedData = {
                    lastExecutorId: payload.lastExecutorId,
                    lastRunStatus: IN_PROGRESS_STATUS,
                    lastRunningDate: new Date(Date.now()).toISOString(),
                }
                if(slice.selectedInstruction){
                    slice.selectedInstruction = {...slice.selectedInstruction, ...updatedData};
                }
                slice.campaignReviewInstructions = slice.campaignReviewInstructions.map(e => e.id === payload.instructionId ? {...e, ...updatedData} : e);

            })
        //ToggleCampaignReviewInstructionStatus
            .addCase(ToggleCampaignReviewInstructionStatus.pending, (slice) => {
                slice.loadings.campaignReviewInstructionActiveToggle = true;
            })
            .addCase(ToggleCampaignReviewInstructionStatus.rejected, (slice) => {
                slice.loadings.campaignReviewInstructionActiveToggle = false;
            })
            .addCase(ToggleCampaignReviewInstructionStatus.fulfilled, (slice, {payload}) => {
                slice.loadings.campaignReviewInstructionActiveToggle = false;
                if(slice.selectedInstruction){
                    slice.selectedInstruction = {...slice.selectedInstruction, active: payload.active};
                }
                slice.campaignReviewInstructions = slice.campaignReviewInstructions.map(e => e.id === payload.instructionId ? {...e, active: payload.active} : e);

            })
        //UpdateNonCustomAccessManagementAppRow
            .addCase(UpdateNonCustomAccessManagementAppRow.pending, (slice) => {
                slice.loadings.updateNonCustomOneRow = true;
            })
            .addCase(UpdateNonCustomAccessManagementAppRow.rejected, (slice) => {
                slice.loadings.updateNonCustomOneRow = false;
            })
            .addCase(UpdateNonCustomAccessManagementAppRow.fulfilled, (slice, {payload}) => {
                slice.loadings.updateNonCustomOneRow = false;
                if(slice.selectedAppEditable && slice.selectedAppOrigin){
                    const updatedArray = slice.selectedAppEditable.dataTable.map(e => (e.accountId === payload.accountId && e.account === payload.account) ? payload : e);
                    slice.selectedAppEditable.dataTable = updatedArray;
                    slice.selectedAppOrigin.dataTable = updatedArray;
                    slice.dialogs.nonCustomAppAccountMapDialog = {
                        isOpen: false,
                        row: null
                    }
                }
            })
//----------GetSmallCampaignReviewExecutors
            .addCase(GetSmallCampaignReviewExecutors.pending, (slice, {meta}) => {
                slice.loadings.campaignReviewExecutorsList = true;
            })
            .addCase(GetSmallCampaignReviewExecutors.rejected, (slice) => {
                slice.loadings.campaignReviewExecutorsList = false;
            })
            .addCase(GetSmallCampaignReviewExecutors.fulfilled, (slice, {payload}) => {
                slice.loadings.campaignReviewExecutorsList = false;

                // if (payload.pageInfo.page === 0) {
                //     slice.campaignReviewExecutors = payload.executors;
                // } else {
                //     if (slice.campaignReviewExecutorsPageInfo.page < payload.pageInfo.page) {
                //         slice.campaignReviewExecutors = [...slice.campaignReviewExecutors, ...payload.executors];
                //     } else {
                //         slice.campaignReviewExecutors = [...payload.executors, ...slice.campaignReviewExecutors];
                //     }
                // }
                const {results, maxLoadedPage, minLoadedPage} = handlePagination<smallCampaignReviewExecutorType>(
                    slice.campaignReviewExecutors,
                    slice.pageInfo,
                    payload.executors,
                    payload.pageInfo,
                    slice.campaignReviewExecutorsMinMaxLoadedPage.minLoadedPage,
                    slice.campaignReviewExecutorsMinMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.campaignReviewExecutors = results;
                slice.campaignReviewExecutorsMinMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.campaignReviewExecutorsPageInfo = payload.pageInfo;
            })
        // GetFullCampaignReviewExecutorById
            .addCase(GetFullCampaignReviewExecutorById.pending, (slice, {meta}) => {
                slice.loadings.campaignReviewExecutorsExact = true;
            })
            .addCase(GetFullCampaignReviewExecutorById.rejected, (slice) => {
                slice.loadings.campaignReviewExecutorsExact = false;
            })
            .addCase(GetFullCampaignReviewExecutorById.fulfilled, (slice, {payload}) => {
                slice.loadings.campaignReviewExecutorsExact = false;
                slice.selectedExecutor = payload;
            })
        //UpdateCampaignReviewRows
            .addCase(UpdateCampaignReviewRows.pending, (slice, {meta}) => {
                const loadingIds = meta.arg.data.apps.flatMap(e => e.data).map(e => e.id);
                slice.loadings.campaignReviewUpdatingRowsWithIds = Array.from(new Set([...slice.loadings.campaignReviewUpdatingRowsWithIds, ...loadingIds]));
            })
            .addCase(UpdateCampaignReviewRows.rejected, (slice, {meta}) => {
                const rejectedLoadingIds = meta.arg.data.apps.flatMap(e => e.data).map(e => e.id);
                //removing ids of fields than not loading anymore
                slice.loadings.campaignReviewUpdatingRowsWithIds = slice.loadings.campaignReviewUpdatingRowsWithIds.filter(e => !rejectedLoadingIds.some(r => r === e));
            })
            .addCase(UpdateCampaignReviewRows.fulfilled, (slice, {payload}) => {
                const finishedLoadingIds = payload.apps.flatMap(e => e.data).map(e => e.id);

                if(finishedLoadingIds.some(e => e === slice.dialogs.addOrEditJustificationDialog.account?.id)){
                    //if user added justification - and loading for this row ended - closing add/edit justification dialog
                    slice.dialogs.addOrEditJustificationDialog = {
                        isOpen: false,
                        isManager: true,
                        app: null,
                        account: null,
                        stageId: null,
                        actorEmail: null,
                    }
                }
                //removing ids of fields than not loading anymore
                slice.loadings.campaignReviewUpdatingRowsWithIds = slice.loadings.campaignReviewUpdatingRowsWithIds.filter(e => !finishedLoadingIds.some(r => r === e));
                if(slice.selectedExecutor){
                    //searching for needed stage
                    slice.selectedExecutor.stages = slice.selectedExecutor.stages.map(e => e.id === payload.stageId ? {
                            ...e,
                            actors: e.actors.map(a => {
                                //searching for needed actor
                                if(a.email === payload.email){
                                    //searching for needed apps
                                    a.appsData = !a.appsData ? null : a.appsData.map(app => {
                                        const updatedApp = payload.apps.find(pApp => pApp.applicationAccessId === app.applicationAccessId);
                                        if(updatedApp){
                                            return {
                                                ...app,
                                                //searching for needed accounts
                                                data: app.data.map(dat => {
                                                    const replacementDat = updatedApp.data.find(upDat => upDat.id === dat.id);
                                                    if(replacementDat){
                                                        //returning updated account if it was found in updated upp
                                                        return replacementDat;
                                                    }
                                                    return dat;
                                                })
                                            }
                                        }
                                        return app;
                                    })
                                }

                                return a;
                            })
                        }
                     : e)
                }
            })

        //AssignStageToMainControl
            .addCase(AssignStageToMainControl.pending, (slice, {meta}) => {
                slice.loadings.campaignReviewAssignToMeEmails.push(meta.arg.data.email);
            })
            .addCase(AssignStageToMainControl.rejected, (slice, {meta}) => {
                slice.loadings.campaignReviewAssignToMeEmails = slice.loadings.campaignReviewAssignToMeEmails.filter(e => e !== meta.arg.data.email);
            })
            .addCase(AssignStageToMainControl.fulfilled, (slice, {payload}) => {
                slice.loadings.campaignReviewAssignToMeEmails = slice.loadings.campaignReviewAssignToMeEmails.filter(e => e !== payload.email);
                if(slice.selectedExecutor){
                    slice.selectedExecutor.stages = slice.selectedExecutor.stages.map(e => {
                        if(e.id === payload.stageId){
                            e.actors = e.actors.map(a => a.email === payload.email ? {...a, mainControl: true} : a);
                        }
                        return e;
                    })
                }
            })
        //VerifyActorChanges
            .addCase(VerifyActorChanges.pending, (slice, {meta}) => {
                slice.loadings.campaignReviewVerifyChangesForEmails.push(meta.arg.data.email);
            })
            .addCase(VerifyActorChanges.rejected, (slice, {meta}) => {
                slice.loadings.campaignReviewVerifyChangesForEmails = slice.loadings.campaignReviewVerifyChangesForEmails.filter(e => e !== meta.arg.data.email);
            })
            .addCase(VerifyActorChanges.fulfilled, (slice, {payload}) => {
                slice.dialogs.declineVerifyStepDialog = {
                    isOpen: false,
                    email: null,
                    isLastVerify: false,
                    stageId: null,
                }
                slice.loadings.campaignReviewVerifyChangesForEmails = slice.loadings.campaignReviewVerifyChangesForEmails.filter(e => e !== payload.email);
                if(slice.selectedExecutor){
                    slice.selectedExecutor.stages = slice.selectedExecutor.stages.map(e => {
                        if(e.id === payload.stageId){
                            e.actors = e.actors.map(a => a.email === payload.email ? {
                                ...a,
                                status: COMPLETE_STATUS,
                                statusChangedDate: new Date(Date.now()).toISOString(),
                            }
                                :
                                a
                            );
                        }
                        return e;
                    })
                }
            })
        //TerminateExecutor
            .addCase(TerminateExecutor.pending, (slice, {meta}) => {
                slice.loadings.isLoadingTerminate = true;
            })
            .addCase(TerminateExecutor.rejected, (slice, {meta}) => {
                slice.loadings.isLoadingTerminate = false;
            })
            .addCase(TerminateExecutor.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingTerminate = false;
                slice.dialogs.terminateExecutor = false;
                if(slice.selectedExecutor){
                    const updatableData = {
                        statusChangedDate: new Date(Date.now()).toISOString(),
                        status: TERMINATED_STATUS
                    }
                    slice.selectedExecutor = {...slice.selectedExecutor, ...updatableData};
                    slice.selectedExecutor.stages = slice.selectedExecutor.stages.map(e => e.status === IN_PROGRESS_STATUS ?
                        {...e, ...updatableData}
                        :
                        e
                    );
                }
            })
        //GetEmployeesNameEmail
            .addCase(GetEmployeesNameEmail.pending, (slice) => {
                slice.loadings.getEmployees = true;
            })
            .addCase(GetEmployeesNameEmail.rejected, (slice) => {
                slice.loadings.getEmployees = false;
            })
            .addCase(GetEmployeesNameEmail.fulfilled, (slice, {payload}) => {
                slice.loadings.getEmployees = false;
                slice.employeesIdName = payload;
            })

        //Segregation of duties
            // getDataForCreateDutyAction
            .addCase(getDataForCreateDutyAction.pending, (slice, {meta}) => {
                slice.loadings.dataForDialog = true;
            })
            .addCase(getDataForCreateDutyAction.rejected, (slice) => {
                slice.loadings.dataForDialog = false;
            })
            .addCase(getDataForCreateDutyAction.fulfilled, (slice, {payload}) => {
                slice.loadings.dataForDialog = false;
                slice.shortApplications = payload.shortApplications;
            })
            .addCase(GetAppDutiesAction.pending, (slice, {meta}) => {
                slice.loadings.list = true;
            })
            .addCase(GetAppDutiesAction.rejected, (slice) => {
                slice.loadings.list = false;
            })
            .addCase(GetAppDutiesAction.fulfilled, (slice, {payload}) => {
                slice.loadings.list = false;

                const {array, pageInfo} = setPaginationInStore<TAppDuties>(
                    slice.pageInfo,
                    slice.appDuties,
                    payload.pageInfo,
                    payload.data,
                    payload.isNewOnTop
                );

                slice.appDuties = array;
                slice.categories = payload.categories;
                slice.pageInfo = pageInfo;
            })
            .addCase(createAppDutyAction.pending, (slice, {meta}) => {
                slice.loadings.create = true;
            })
            .addCase(createAppDutyAction.rejected, (slice) => {
                slice.loadings.create = false;
            })
            .addCase(createAppDutyAction.fulfilled, (slice, {payload}) => {
                slice.loadings.create = false;
                slice.dialogs.createDuty = false;
                if (payload !== undefined) {
                    const index = slice.appDuties.findIndex(e => e?.id === payload.application.applicationAccessId);
                    if (index > -1) slice.appDuties[index]?.duties.push(payload);
                    else slice.appDuties = [...slice.appDuties, {id: payload.application.applicationAccessId, name: payload.application.name, duties: [payload]}];
                }
            })
            .addCase(updateAppDutyAction.pending, (slice, {meta}) => {
                slice.loadings.update = true;
            })
            .addCase(updateAppDutyAction.rejected, (slice) => {
                slice.loadings.update = false;
            })
            .addCase(updateAppDutyAction.fulfilled, (slice, {payload}) => {
                slice.loadings.update = false;
                slice.dialogs.editDuty.isOpen = false;
                slice.dialogs.editDuty.selectedDuty = null;
                const index = slice.appDuties.findIndex(e => e?.id === payload.application.applicationAccessId);
                if (index > -1 && slice.appDuties[index]) slice.appDuties[index]!.duties = slice.appDuties[index]!.duties.map(e => e.id === payload.id ? payload : e);


            })
            .addCase(deleteAppDutyAction.pending, (slice, {meta}) => {
                slice.loadings.delete = true;
            })
            .addCase(deleteAppDutyAction.rejected, (slice, {meta}) => {
                slice.loadings.delete = false;
                // slice.dialogs.deleteDuty = initialState.dialogs.deleteDuty;
            })
            .addCase(deleteAppDutyAction.fulfilled, (slice, {payload}) => {
                slice.loadings.delete = false;
                slice.dialogs.deleteDuty = initialState.dialogs.deleteDuty;
                const index = slice.appDuties.findIndex(e => e?.id === payload.appId);
                if (index > -1 && slice.appDuties[index]) {
                    slice.appDuties[index] = {
                        ...slice.appDuties[index]!,
                        duties: slice.appDuties[index]?.duties.filter(e => e.id !== payload.id) || []
                    } ;

                    if ((slice.appDuties[index]?.duties.length || 0) === 0) slice.appDuties = slice.appDuties.filter((app) => app?.id !== slice.appDuties[index]?.id)
                }
            })

            //Ruleset
            .addCase(GetDutiesRulesetAction.pending, (slice, {meta}) => {
                slice.loadings.list = true;
            })
            .addCase(GetDutiesRulesetAction.rejected, (slice) => {
                slice.loadings.list = false;
            })
            .addCase(GetDutiesRulesetAction.fulfilled, (slice, {payload}) => {
                slice.loadings.list = false;

                // const {array, pageInfo} = setPaginationInStore<TDutyRule>(
                //     slice.pageInfo,
                //     slice.ruleset,
                //     payload.resp.pageInfo,
                //     payload.resp.data,
                //     payload.isNewOnTop
                // );
                //
                // slice.ruleset = array || [];
                // slice.pageInfo = pageInfo;

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<TDutyRule>(
                    slice.ruleset,
                    slice.pageInfo,
                    payload.resp.data,
                    payload.resp.pageInfo,
                    slice.rulesetMinMaxLoadedPageDefault.minLoadedPage,
                    slice.rulesetMinMaxLoadedPageDefault.maxLoadedPage,
                    'id'
                );

                slice.ruleset = results;
                slice.rulesetMinMaxLoadedPageDefault = {minLoadedPage, maxLoadedPage};
                slice.pageInfo = payload.resp.pageInfo;

            })
            .addCase(GetDutyRuleByIdAction.pending, (slice, {meta}) => {
                slice.loadings.exact = true;
            })
            .addCase(GetDutyRuleByIdAction.rejected, (slice) => {
                slice.loadings.exact = false;
            })
            .addCase(GetDutyRuleByIdAction.fulfilled, (slice, {payload}) => {
                slice.loadings.exact = false;
                slice.selectedRule = payload;
            })
            // getDataForCreateRuleAction
            .addCase(getDataForCreateRuleAction.pending, (slice, {meta}) => {
                slice.loadings.dataForDialog = true;
            })
            .addCase(getDataForCreateRuleAction.rejected, (slice) => {
                slice.loadings.dataForDialog = false;
            })
            .addCase(getDataForCreateRuleAction.fulfilled, (slice, {payload}) => {
                slice.loadings.dataForDialog = false;
                slice.shortDuties = payload.duties;
            })

            .addCase(createDutyRuleAction.pending, (slice, {meta}) => {
                slice.loadings.create = true;
            })
            .addCase(createDutyRuleAction.rejected, (slice) => {
                slice.loadings.create = false;
            })
            .addCase(createDutyRuleAction.fulfilled, (slice, {payload}) => {
                slice.loadings.create = false;
                slice.selectedRule = payload;
                slice.ruleset.push(payload);
            })
            .addCase(updateDutyRuleAction.pending, (slice, {meta}) => {
                slice.loadings.update = true;
            })
            .addCase(updateDutyRuleAction.rejected, (slice) => {
                slice.loadings.update = false;
            })
            .addCase(updateDutyRuleAction.fulfilled, (slice, {payload}) => {
                slice.loadings.update = false;
                slice.dialogs.editDutyRule.isOpen = false;
                slice.dialogs.editDutyRule.selectedDutyRule = null;
                slice.selectedRule = payload;
                const index = slice.ruleset.findIndex(e => e?.id === payload.id);
                if (index > -1) slice.ruleset[index] = payload;
            })
            .addCase(deleteDutyRuleAction.pending, (slice, {meta}) => {
                slice.loadings.delete = true;
            })
            .addCase(deleteDutyRuleAction.rejected, (slice) => {
                slice.loadings.delete = false;
            })
            .addCase(deleteDutyRuleAction.fulfilled, (slice, {payload}) => {
                slice.loadings.delete = false;
                slice.dialogs.deleteDutyRule.isOpen = false;
                slice.dialogs.deleteDutyRule.selectedDutyRule = null;
                slice.ruleset.filter(e => e?.id !== payload.id);
            })
            //ImportCsvRulesetTemplate
            .addCase(ImportCsvRulesetTemplateAction.pending, (slice) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = true;
            })
            .addCase(ImportCsvRulesetTemplateAction.rejected, (slice) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = false;
            })
            .addCase(ImportCsvRulesetTemplateAction.fulfilled, (slice, {payload}) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = false;
            })

            //ExportCsvRulesetAction
            .addCase(ExportCsvRulesetAction.pending, (slice) => {
                slice.loadings.exportCsv = true;
            })
            .addCase(ExportCsvRulesetAction.rejected, (slice) => {
                slice.loadings.exportCsv = false;
            })
            .addCase(ExportCsvRulesetAction.fulfilled, (slice, {payload}) => {
                slice.loadings.exportCsv = false;
            })
            //ImportCsvRulesetAction
            .addCase(ImportCsvRulesetAction.pending, (slice) => {
                slice.dialogs.importCsvDialog.isLoading.isPerformingCsv = true;
            })
            .addCase(ImportCsvRulesetAction.rejected, (slice) => {
                slice.dialogs.importCsvDialog.isLoading.isPerformingCsv = false;
            })
            .addCase(ImportCsvRulesetAction.fulfilled, (slice, {payload}) => {
                slice.dialogs.importCsvDialog = initialState.dialogs.importCsvDialog;
            })
            .addCase(runRuleCheckAction.pending, (slice) => {
                slice.loadings.runRuleCheck = true;
            })
            .addCase(runRuleCheckAction.rejected, (slice) => {
                slice.loadings.runRuleCheck = false;
            })
            .addCase(runRuleCheckAction.fulfilled, (slice, {payload}) => {
                slice.loadings.runRuleCheck = false;
                if (payload.resp !== undefined) slice.runRuleCheckConflicts = payload.resp.map((c, index) => ({...c, id: `id_${index}`}));
            })
            .addCase(addRulesConflictsAction.pending, (slice) => {
                slice.loadings.addRulesConflicts = true;
            })
            .addCase(addRulesConflictsAction.rejected, (slice) => {
                slice.loadings.addRulesConflicts = false;
            })
            .addCase(addRulesConflictsAction.fulfilled, (slice, {payload}) => {
                slice.loadings.addRulesConflicts = false;
                slice.dialogs.runRuleCheck = initialState.dialogs.runRuleCheck;
            })
            //Conflicts
            .addCase(getRulesConflictsAction.pending, (slice) => {
                slice.loadings.list = true;
            })
            .addCase(getRulesConflictsAction.rejected, (slice) => {
                slice.loadings.list = false;
            })
            .addCase(getRulesConflictsAction.fulfilled, (slice, {payload}) => {
                slice.loadings.list = false;

                // if (payload.pageInfo.page === 0) {
                //     slice.conflicts = payload.data;
                // } else {
                //     if (slice.pageInfo.page < payload.pageInfo.page) {
                //         slice.conflicts = [...slice.conflicts, ...payload.data];
                //     } else {
                //         slice.conflicts = [...payload.data, ...slice.conflicts];
                //     }
                // }
                // slice.pageInfo = payload.pageInfo;

                // const {array, pageInfo} = setPaginationInStore<TRulesConflict>(
                //     slice.pageInfo,
                //     slice.conflicts,
                //     payload.pageInfo,
                //     payload.data,
                //     payload.isNewOnTop
                // );
                //
                // slice.conflicts = array;
                // slice.pageInfo = pageInfo;

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<TRulesConflict>(
                    slice.conflicts,
                    slice.pageInfo,
                    payload.data,
                    payload.pageInfo,
                    slice.conflictsMinMaxLoadedPage.minLoadedPage,
                    slice.conflictsMinMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.conflicts = results;
                slice.conflictsMinMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.pageInfo = payload.pageInfo;
            })
            //Deny Conflict
            .addCase(getDataForDenyConflictAction.pending, (slice) => {
                slice.loadings.dataForDialog = true;
            })
            .addCase(getDataForDenyConflictAction.rejected, (slice) => {
                slice.loadings.dataForDialog = false;
            })
            .addCase(getDataForDenyConflictAction.fulfilled, (slice, {payload}) => {
                slice.loadings.dataForDialog = false;
                slice.shortCollaborators = payload.collaborators;
            })
            .addCase(denyConflictAction.pending, (slice) => {
                slice.loadings.denyConflict = true;
            })
            .addCase(denyConflictAction.rejected, (slice) => {
                slice.loadings.denyConflict = false;
            })
            .addCase(denyConflictAction.fulfilled, (slice, {payload}) => {
                slice.loadings.denyConflict = false;
                slice.dialogs.denyConflict = initialState.dialogs.denyConflict;
                slice.conflicts = slice.conflicts.map((c) => c?.id === payload.id ? payload : c);
            })
            //allow conflict
            .addCase(allowConflictStandardAction.pending, (slice) => {
                slice.loadings.allowConflict = true;
            })
            .addCase(allowConflictStandardAction.rejected, (slice) => {
                slice.loadings.allowConflict = false;
            })
            .addCase(allowConflictStandardAction.fulfilled, (slice, {payload}) => {
                slice.loadings.allowConflict = false;
                slice.dialogs.allowConflict = initialState.dialogs.allowConflict;
                slice.selectedConflict = null;
                slice.conflicts = slice.conflicts.map((c) => c && c.id === payload.data.conflict.id ? ({...c, status: "ALLOWED"}) : c);
            })
             //getDataForAllowedConflict
            .addCase(getDataForAllowedConflictAction.pending, (slice) => {
                slice.loadings.dataForDialog = true;
            })
            .addCase(getDataForAllowedConflictAction.rejected, (slice) => {
                slice.loadings.dataForDialog = false;
            })
            .addCase(getDataForAllowedConflictAction.fulfilled, (slice, {payload}) => {
                slice.loadings.dataForDialog = false;
                slice.dialogs.allowedConflictData.isOpen = true;
                if (payload.conflictException !== undefined) slice.dialogs.allowedConflictData.conflictException = payload.conflictException;
            })

            //getRoleNamesAction
            .addCase(getRoleNamesAction.pending, (slice) => {
                slice.loadings.dataForDialog = true;
            })
            .addCase(getRoleNamesAction.rejected, (slice) => {
                slice.loadings.dataForDialog = false;
            })
            .addCase(getRoleNamesAction.fulfilled, (slice, {payload}) => {
                slice.loadings.dataForDialog = false;
                if (payload.roles !== undefined) slice.shortRoles = payload.roles;
            })

            .addCase(allowConflictCustomAction.pending, (slice) => {
                slice.loadings.allowConflict = true;
            })
            .addCase(allowConflictCustomAction.rejected, (slice) => {
                slice.loadings.allowConflict = false;
            })
            .addCase(allowConflictCustomAction.fulfilled, (slice, {payload}) => {
                slice.loadings.allowConflict = false;
                slice.dialogs.allowConflict = initialState.dialogs.allowConflict;
            })
            //updateConflictExceptionAction
            .addCase(updateConflictExceptionAction.pending, (slice) => {
                slice.loadings.updateException = true;
            })
            .addCase(updateConflictExceptionAction.rejected, (slice) => {
                slice.loadings.updateException = false;
            })
            .addCase(updateConflictExceptionAction.fulfilled, (slice, {payload}) => {
                slice.loadings.updateException = false;
                slice.selectedRule = payload;
                slice.dialogs.extendException = initialState.dialogs.extendException;
            })
            .addCase(deleteConflictExceptionAction.pending, (slice) => {
                slice.loadings.deleteException = true;
            })
            .addCase(deleteConflictExceptionAction.rejected, (slice) => {
                slice.loadings.deleteException = false;
            })
            .addCase(deleteConflictExceptionAction.fulfilled, (slice, {payload}) => {
                slice.loadings.deleteException = false;
                slice.selectedRule = payload;
                slice.dialogs.deleteException = initialState.dialogs.deleteException;
            })
            //scheduler
            .addCase(GetSodSchedulerDataAction.pending, (slice) => {
                slice.loadings.sodSchedulerData = true;
            })
            .addCase(GetSodSchedulerDataAction.rejected, (slice) => {
                slice.loadings.sodSchedulerData = false;
            })
            .addCase(GetSodSchedulerDataAction.fulfilled, (slice, {payload}) => {
                slice.loadings.sodSchedulerData = false;

                if (payload.pageInfo.page === 0) {
                    slice.sodScheduler = {
                        data: payload.data,
                        history: payload.history,
                    } ;
                } else {
                    if (slice.pageInfo.page < payload.pageInfo.page) {
                        slice.sodScheduler.history = [...slice.sodScheduler.history, ...payload.history];
                    } else {
                        slice.sodScheduler.history = [...payload.history, ...slice.sodScheduler.history];
                    }
                }
                slice.pageInfo = payload.pageInfo;
            })

            //AvailableFieldsForExport
            .addCase(GetAccessManagementAvailableFieldsForExport.pending, (slice) => {
                slice.loadings.isLoadingFields = true;
            })
            .addCase(GetAccessManagementAvailableFieldsForExport.rejected, (slice) => {
                slice.loadings.isLoadingFields = false;

            })
            .addCase(GetAccessManagementAvailableFieldsForExport.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingFields = false;
                slice.dialogs.exportCsv.fields = payload.fields;
            })
    }
});

export const AccessManagementReducer = AccessManagementSlice.reducer;

export const {
    openAddNewApp,
    hideAddNewApp,

    openDeleteApp,
    hideDeleteApp,

    openEditApp,
    hideEditApp,

    setImportCsvDialogAction,

    openDeleteRow,
    hideDeleteRow,

    openEditRole,
    hideEditRole,

    openDeleteRole,
    hideDeleteRole,

    openCreateRole,
    hideCreateRole,

    //
    deleteRow,
    createRow,
    editRow,
    editApp,
    //
    setSelectedApp,
    eraseSelectedApp,
    revertChanges,

    openShowPermissions,
    hideShowPermissions,

    openReleaseDialog,
    hideReleaseDialog,

    openCreateDuty,
    hideCreateDuty,

    openEditDuty,
    hideEditDuty,

    openDeleteDuty,
    hideDeleteDuty,

    openEditDutyRule,
    hideEditDutyRule,

    openDeleteDutyRule,
    hideDeleteDutyRule,

    openNonCustomAppAccountMapDialog,
    hideNonCustomAppAccountMapDialog,
//
    selectExecutor,
    selectInstruction,

    openSelectEvidence,
    hideSelectEvidence,

    openRunReviewWarning,
    hideRunReviewWarning,

    openDeleteInstructionDialog,
    hideDeleteInstructionDialog,

    openShowVerifyApplicationRevokeReason,
    hideShowVerifyApplicationRevokeReason,

    openTerminateDialog,
    hideTerminateDialog,

    openAddOrEditJustificationDialog,
    hideAddOrEditJustificationDialog,

    openViewJustification,
    hideViewJustification,

    openDeclineVerifyStepDialog,
    hideDeclineVerifyStepDialog,

    openRunRuleCheck,
    hideRunRuleCheck,

    selectRule,
    deselectRule,
    selectConflict,
    deselectConflict,

    openTerminateRuleCheck,
    hideTerminateRuleCheck,

    openSaveConflictsSuccess,
    hideSaveConflictsSuccess,
    openAllowedConflictData,
    hideAllowedConflictData,
    openDeniedConflictData,
    hideDeniedConflictData,

    openViewConflict,
    hideViewConflict,

    openAllowConflict,
    hideAllowConflict,
    openDenyConflict,
    hideDenyConflict,
    openExtendException,
    hideExtendException,

    openDeleteException,
    hideDeleteException,

    cleanAppDuties,
    cleanRuleset,
    cleanConflicts,
    cleanScheduler,
    cleanUp,
    replaceAppsMinMaxLoadedPage,
    replaceMinMaxLoadedPageInstruction,
    replaceMinMaxLoadedPageExecutor,
    clearCampaignReviewInstructions,
    clearCampaignReviewExecutors,
    clearSelectedCampaignReviewExecutor,
    replaceRulesetsMinMaxLoadedPage,
    replaceMinMaxLoadedPageConflicts,
    openExportCsv,
    hideExportCsv,
    cleanUpApps
} = AccessManagementSlice.actions;

const selectSelf = (state: AppState):accessManagementInitialState => state.AccessManagement as accessManagementInitialState;

export const loadings = createSelector(selectSelf, state => state.loadings);
export const dialogs = createSelector(selectSelf, state => state.dialogs);
export const apps = createSelector(selectSelf, state => state.apps);
export const appsMinMaxLoadedPage = createSelector(selectSelf, state => state.appsMinMaxLoadedPage);
export const appDuties = createSelector(selectSelf, state => state.appDuties);
export const ruleset = createSelector(selectSelf, state => state.ruleset);
export const conflicts = createSelector(selectSelf, state => state.conflicts);
export const runRuleCheckConflicts = createSelector(selectSelf, state => state.runRuleCheckConflicts);
export const categories = createSelector(selectSelf, state => state.categories);
export const selectedAppEditable = createSelector(selectSelf, state => state.selectedAppEditable);
export const selectedAppOrigin = createSelector(selectSelf, state => state.selectedAppOrigin);
export const selectedRule = createSelector(selectSelf, state => state.selectedRule);
export const selectedConflict = createSelector(selectSelf, state => state.selectedConflict);
export const pageInfo = createSelector(selectSelf, state => state.pageInfo);
export const vendors = createSelector(selectSelf, state => state.vendors);
export const vendorSettings = createSelector(selectSelf, state => state.vendorSettings);
export const employees = createSelector(selectSelf, state => state.employees);
export const releaseData = createSelector(selectSelf, state => state.releaseData);
export const shortApplications = createSelector(selectSelf, state => state.shortApplications);
export const shortDuties = createSelector(selectSelf, state => state.shortDuties);
export const shortCollaborators = createSelector(selectSelf, state => state.shortCollaborators);
export const shortRoles = createSelector(selectSelf, state => state.shortRoles);
export const sodScheduler = createSelector(selectSelf, state => state.sodScheduler);

export const campaignReviewInstructions = createSelector(selectSelf, state => state.campaignReviewInstructions);
export const campaignReviewInstructionsPageInfo = createSelector(selectSelf, state => state.campaignReviewInstructionsPageInfo);
export const campaignReviewExecutors = createSelector(selectSelf, state => state.campaignReviewExecutors);
export const campaignReviewExecutorsPageInfo = createSelector(selectSelf, state => state.campaignReviewExecutorsPageInfo);

export const selectedExecutor = createSelector(selectSelf, state => state.selectedExecutor);
export const selectedInstruction = createSelector(selectSelf, state => state.selectedInstruction);

export const employeesIdName = createSelector(selectSelf, state => state.employeesIdName);
export const campaignReviewInstructionsMinMaxLoadedPage = createSelector(selectSelf, state => state.campaignReviewInstructionsMinMaxLoadedPage);
export const campaignReviewExecutorsMinMaxLoadedPage = createSelector(selectSelf, state => state.campaignReviewExecutorsMinMaxLoadedPage);

export const ruleSetMinMaxLoadedPage = createSelector(selectSelf, state => state.rulesetMinMaxLoadedPageDefault);
export const conflictsMinMaxLoadedPage = createSelector(selectSelf, state => state.conflictsMinMaxLoadedPage);



