import {
    addCommentApi,
    addControlAssignmentApi,
    deleteControlAssignmentApi,
    downloadCollectorAssetPdfApi,
    downloadEvidenceFileApi,
    getAnalysisHistoryByHistoryIdApi,
    getAnalysisHistoryFRApi,
    getAutoAssetDataApi,
    getCommentsApi,
    getControlAssignmentsApi,
    getControlByIdFrameworkIdApi,
    getEvidenceAssetByIdApi,
    getPlaybookHistoryDetailsFRApi,
    removeEvidenceAssetApi,
    setEvidenceStatusApi,
    uploadEvidenceAssetApi
} from "../../api";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    AddCommentMutationVariables,
    AddControlAssignmentMutationVariables,
    DeleteControlAssignmentMutationVariables,
    DownloadFileQueryVariables,
    GetAnalysisHistoryByFrameworkIdControlIdHistoryIdQueryVariables,
    GetAnalysisHistoryByFrameworkIdControlIdQueryVariables,
    GetCollectorAssetDataQueryVariables,
    GetCommentsQueryVariables,
    GetControlAssignmentsQueryVariables,
    GetControlByIdFrameworkIdQueryVariables,
    GetPlaybookHistoryFrameworkDetailsQueryVariables,
    MainFrameworksApproveTaskByIdMutation,
    MainFrameworksApproveTaskByIdMutationVariables,
    MainFrameworksCancelTaskByIdMutation,
    MainFrameworksCancelTaskByIdMutationVariables,
    MainFrameworksCreateDelegatedTaskMutation,
    MainFrameworksCreateDelegatedTaskMutationVariables,
    MainFrameworksDeclineTaskByIdMutation,
    MainFrameworksDeclineTaskByIdMutationVariables,
    MainFrameworksGetDelegatedTaskByIdQuery,
    MainFrameworksGetDelegatedTaskByIdQueryVariables,
    MainFrameworksGetDelegatedTasksWithFilterPaginationQuery,
    MainFrameworksGetDelegatedTasksWithFilterPaginationQueryVariables,
    RemoveEvidenceAssetMutationVariables,
    SetEvidenceStatusMutationVariables,
    UploadEvidenceAssetMutationVariables
} from "../../../../../newShared/GQLTypes";
import {AppState} from "../../../../../newShared/redux/rootReducer";
import {getActionsData} from "../../../../../newShared/redux";
import {saveAs} from "file-saver";
import {b64toBlob} from "../../../../../newShared/utils/base64/base64";
import {TWithOptions} from "../../../../../newShared/types";
import {createCommonAsyncThunk} from "../../../../../newShared/utils/asyncThunk";
import {
    mainFrameworksApproveTaskById,
    mainFrameworksCancelTaskById,
    mainFrameworksCreateDelegatedTask,
    mainFrameworksDeclineTaskById,
    mainFrameworksGetDelegatedTaskById,
    mainFrameworksGetDelegatedTasksWithFilterPagination
} from "../../api/query";

export const getControlByIdFrameworkIdAction = createAsyncThunk(
    'Control/getControlByIdFrameworkId',
    async (data: TWithOptions<GetControlByIdFrameworkIdQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId, language} = getActionsData(state);

        const resp = await getControlByIdFrameworkIdApi({...data.data, organizationId, workspaceId, language}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const setEvidenceStatusAction = createAsyncThunk(
    'Control/setEvidenceStatus',
    async (data: TWithOptions<SetEvidenceStatusMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await setEvidenceStatusApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const downloadEvidenceFileAction = createAsyncThunk(
    'Control/downloadEvidenceFile',
    async (data: TWithOptions<DownloadFileQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await downloadEvidenceFileApi({...data.data, workspaceId}, data.signal);
        saveAs(b64toBlob(resp.file), resp.filename);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const downloadCollectorAssetPdfAction = createAsyncThunk(
    'Control/downloadCollectorAssetPdf',
    async (data: TWithOptions<{assetId: string}>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {organizationId, workspaceId} = getActionsData(state);

        const resp = await downloadCollectorAssetPdfApi({...data.data, organizationId, workspaceId}, data.signal);
        saveAs(b64toBlob(resp.file), resp.filename);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const uploadEvidenceAssetAction = createAsyncThunk(
    'Control/uploadEvidenceAsset',
    async (data: TWithOptions<UploadEvidenceAssetMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await uploadEvidenceAssetApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const removeEvidenceAssetAction = createAsyncThunk(
    'Control/removeEvidenceAsset',
    async (data: TWithOptions<RemoveEvidenceAssetMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await removeEvidenceAssetApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const getAutoAssetDataAction = createAsyncThunk(
    'Control/getAutoAssetData',
    async (data: TWithOptions<GetCollectorAssetDataQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await getAutoAssetDataApi({...data.data, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const getStatementByIdAction = createAsyncThunk(
    'Control/getStatementById ',
    async (data: TWithOptions<{ id: string }>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await getEvidenceAssetByIdApi({id: data.data.id, workspaceId, organizationId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const getCommentsAction = createAsyncThunk(
    'Control/getComments',
    async (data: TWithOptions<GetCommentsQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await getCommentsApi({...data.data, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp,
            isNewOnTop: true
        }
    },
);

export const addCommentAction = createAsyncThunk(
    'Control/addComment',
    async (data: TWithOptions<AddCommentMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await addCommentApi({...data.data, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);


export const getControlAssignmentsAction = createAsyncThunk(
    'Control/getControlAssignments',
    async (data: TWithOptions<GetControlAssignmentsQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await getControlAssignmentsApi({...data.data, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const addControlAssignmentAction = createAsyncThunk(
    'Control/addControlAssignment',
    async (data: TWithOptions<AddControlAssignmentMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await addControlAssignmentApi({...data.data, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const deleteControlAssignmentAction = createAsyncThunk(
    'Control/deleteControlAssignment',
    async (data: TWithOptions<DeleteControlAssignmentMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await deleteControlAssignmentApi({...data.data, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

//ANALYSIS
export const getAnalysisHistoryFRAction = createAsyncThunk(
    'Control/getAnalysisHistoryFR',
    async (data: TWithOptions<GetAnalysisHistoryByFrameworkIdControlIdQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await getAnalysisHistoryFRApi({...data.data, workspaceId, organizationId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp,
            isNewOnTop: true
        }
    },
);

export const getAnalysisHistoryByHistoryIdAction = createAsyncThunk(
    'Control/getAnalysisHistoryByHistoryId',
    async (data: TWithOptions<GetAnalysisHistoryByFrameworkIdControlIdHistoryIdQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await getAnalysisHistoryByHistoryIdApi({...data.data, workspaceId, organizationId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const getAnalysisHistoryDetailsFRAction = createAsyncThunk(
    'Control/getAnalysisHistoryDetailsFR',
    async (data: TWithOptions<GetPlaybookHistoryFrameworkDetailsQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await getPlaybookHistoryDetailsFRApi({...data.data, workspaceId, organizationId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);


//frameworkGetDelegatedTaskById
export const frameworkGetDelegatedTaskById =
    createCommonAsyncThunk<MainFrameworksGetDelegatedTaskByIdQueryVariables, MainFrameworksGetDelegatedTaskByIdQuery["mainFrameworksGetDelegatedTaskById"]>(
        "FrameworkControl", 'mainFrameworksGetDelegatedTaskById', true, false, mainFrameworksGetDelegatedTaskById
    );

export const mainFrameworksGetDelegatedTasksWithFilterPaginationAction =
    createCommonAsyncThunk<MainFrameworksGetDelegatedTasksWithFilterPaginationQueryVariables, MainFrameworksGetDelegatedTasksWithFilterPaginationQuery["mainFrameworksGetDelegatedTasksWithFilterPagination"]>(
        'FrameworkControl', 'mainFrameworksGetDelegatedTasksWithFilterPagination', true, false, mainFrameworksGetDelegatedTasksWithFilterPagination
    )



//mainFrameworksCancelTaskById
export const frameworksCancelTaskById =
    createCommonAsyncThunk<MainFrameworksCancelTaskByIdMutationVariables, MainFrameworksCancelTaskByIdMutation['mainFrameworksCancelTaskById']>(
        "FrameworkControl", 'mainFrameworksCancelTaskById', true, false, mainFrameworksCancelTaskById
    );

//mainFrameworksDeclineTaskById
export const frameworksDeclineTaskById =
    createCommonAsyncThunk<MainFrameworksDeclineTaskByIdMutationVariables, MainFrameworksDeclineTaskByIdMutation['mainFrameworksDeclineTaskById']>(
        "FrameworkControl", 'mainFrameworksDeclineTaskById', true, false, mainFrameworksDeclineTaskById
    );

//mainFrameworksApproveTaskById
export const frameworksApproveTaskById =
    createCommonAsyncThunk<MainFrameworksApproveTaskByIdMutationVariables, MainFrameworksApproveTaskByIdMutation['mainFrameworksApproveTaskById']>(
        "FrameworkControl", 'mainFrameworksApproveTaskById', true, false, mainFrameworksApproveTaskById
    );


export const mainFrameworksCreateDelegatedTaskAction =
    createCommonAsyncThunk<MainFrameworksCreateDelegatedTaskMutationVariables, MainFrameworksCreateDelegatedTaskMutation['mainFrameworksCreateDelegatedTask']>(
        'FrameworkControl', 'mainFrameworksCreateDelegatedTask', true, false, mainFrameworksCreateDelegatedTask
    );