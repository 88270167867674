import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useReports} from "../../../hooks/useReports";
import {useReportDialogs} from "../../../hooks/useReportDialogs";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_COMPLIANCE_REPORTS} from "../../../../../../newShared/constants";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../../newShared/components/genericTable/types";
import {useDispatch} from "react-redux";
import {cleanAction, setMinMaxLoadedPageGeneratedReports} from "../../../store/reports/store";

export const useGeneratedReportsPage = () => {
    const dispatch = useDispatch();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    const {
        generatedReports: {reports, isLoading, pageInfo, minMaxLoadedPage},
        goToCreateReport, goToReport,
        getGeneratedReports,

    } = useReports();
    const {
        archiveReport: {setArchiveReportDialog},
        deleteReport: {setDeleteReportDialog},
    } = useReportDialogs();
    // const {filter} = useParameters();

    //Controller and first loadData
    const controller = new AbortController();

    //Breadcrumbs
    useSetBreadcrumbs([
        {
            title: tMenu('Reports'),
            path: PATH_LOCAL_COMPLIANCE_REPORTS,
        },
        {
            title: tMenu('Generated reports'),
        },
    ]);

    //Pagination and filtering
    // const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
    //     getData: ({page, count}) => {
    //         getGeneratedReports({
    //             page, count, filters: filter
    //         }, false, controller.signal)
    //     },
    //     arrayLength: reports.length,
    //     isLoading: isLoading,
    //     pageInfo: pageInfo,
    //     cleanData: cleanGeneratedReports,
    //     controller: controller,
    //     isOnTop: true,
    //     // isNoMoreThanTotal: true,
    //     // customDependencies: [],
    //     // is0PageAddictive: fr !== framework?.id
    // })

    // const {value, handleChange} = useSearchFieldURL({wait: 0});
    // const {setFilter, handleKeyDown} = useFilterUrl({
    //     getData: (filters) => {getGeneratedReports({page: 0, count: pageInfo.count, filters}, true, controller.signal)}
    // });

    return {
        goToCreateReport, goToReport,
        setArchiveReportDialog, setDeleteReportDialog,
        isLoading,
        reports,
        // filter,
        // value, handleChange, handleKeyDown, setFilter,
        // onScroll, scrollRef, bottomLoader,
        genericTable: {
            paging: {
                pageInfo,
                isLoading,
                minLoadedPage: minMaxLoadedPage.minLoadedPage,
                maxLoadedPage: minMaxLoadedPage.maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(setMinMaxLoadedPageGeneratedReports(data)),
                clearRows: () => dispatch(cleanAction('generatedReports')),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    getGeneratedReports({page, count, filters}, false, controller.signal)
                },
            }
        }
    }
}