import React from "react";
import {Flex} from "../../../../../newShared/components/Layouts";
import {Table, TableBody, TableCell, TableContainer, TableHead} from "@mui/material";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {SettingsTypes} from "../../types";
import {CUSTOM_FIELDS_ID, RISK_ID, SERVICE_PROVIDED_ID, TYPE_ID} from "../../constants";
import {SettingsTableRow} from "../../components/settingsTableRow";
import {useVendorsSettings} from "../../hooks/useVendorsSettings";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";

export const VendorsSettings = () =>{
    const {t} = useMainTranslation('',{keyPrefix:'pathVendors.VendorsSettings'});
    const settings: SettingsTypes[] = [RISK_ID, TYPE_ID, SERVICE_PROVIDED_ID, CUSTOM_FIELDS_ID];

    const {
        handleGoToSettingById
    } = useVendorsSettings();

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('Settings')}
                actions={[]}
            />

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableCell>
                            {t('Title')}
                        </TableCell>
                    </TableHead>

                    <TableBody>
                        {settings.map(e => <SettingsTableRow type={e} key={e} handleGoToSettingById={handleGoToSettingById}/>)}
                    </TableBody>
                </Table>
            </TableContainer>

            {/*<S.SettingsHeaderWrapper>*/}
            {/*    <Typography sx={{fontSize: isDesktop ? '16px' : '14px', fontWeight: 700, color: colors.grayText, padding: revDir ? '0 5px 0 0' : '0 0 0 5px'}}>*/}
            {/*        {t('Title')}</Typography>*/}
            {/*</S.SettingsHeaderWrapper>*/}

            {/*<S.SettingsListWrapper>*/}
            {/*    {settings.map(e => <SettingsTableRow type={e} key={e} handleGoToSettingById={handleGoToSettingById}/>)}*/}
            {/*</S.SettingsListWrapper>*/}
        </Flex>
    )


}