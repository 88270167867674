import {Box, Button, styled, Table, TableBody, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {
    TypographyBody2,
    TypographyBodySmall,
    TypographyBodySmallSemiBold
} from "../../../../../newShared/components/Inputs/styled";


export const PageContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
    height: '100%'
}));

export const FiltersContainer = styled(Box)(() => ({
    display: 'flex',
    // alignItems: 'center',
    flexDirection: 'column',
    marginTop: '16px',
    flexGrow: 1,
    overflow: 'hidden'
}));

//Table
export const DocumentsTableContainer = styled(TableContainer)(() => ({
    margin: '16px 0',
    width: '100%',
    boxShadow: '0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06)',
    borderRadius: '8px !important',
    border: '1px solid #EAECF0',
    flexGrow: 1,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column'
}));

export const StickyHeaderTable = styled(Table)``;
StickyHeaderTable.defaultProps = {
    stickyHeader: true,
}

export const DocumentsTableHead = styled(TableHead)(() => ({
    backgroundColor: colors.backgrounds.grey_light,
}));


export const DocumentsTableRow = styled(TableRow)(() => ({
    '& .MuiTableCell-root': {
        backgroundColor: colors.backgrounds.grey_light,
    }
}));

export const DocumentsHeaderTableTypography = styled(TypographyBody2)(() => ({
    color: colors.text.grey_dark,
}));

export const DocumentsTableBody = styled(TableBody)(() => ({
    ' & .MuiTableRow-root': {
        'last-child td' :  {
            borderBottom: `1px solid ${colors.primary.disable}`,
        }}

}));

export const DocNameFlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap:'wrap',
    gap: '10px'
}));

export const DocNameTypography = styled(TypographyBodySmallSemiBold)(() => ({
    margin: '0 16px'
}));

export const TextSmallButton = styled(Button)(() => ({
    textTransform: 'none',
    width: 'max-content',
}));
TextSmallButton.defaultProps = {
    size: 'small',
    variant: 'text',

}

//Status
export const StatusTableCellContainer = styled(Box)(() => ({
    display: 'flex',
    alignItems: "center",
}));
export const StatusRoundBox = styled(Box, {
    shouldForwardProp: (propName) => (
        !["revDir"].includes(propName.toString())
    )})(({revDir, color}: {revDir?: true, color?: string}) => ({
    width: '4px',
    height: "4px",
    backgroundColor: color || colors.primary.blue,
    marginRight: !revDir ? "4px" : undefined,
    marginLeft: revDir ? "4px" : undefined,
}));

export const StatusTypography = styled(TypographyBodySmall)(({color}: {color?: string}) => ({
    color: color || colors.primary.blue,
    fontWeight: 500,
}))

StatusTypography.defaultProps = {
    noWrap: true,
}

export const DragAreaWrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(255,255,255,0.7)',
    zIndex: 1000,
    borderRadius: '12px',
    border: '1px dotted #FAC000',
}));


export const DragAreaTypo = styled(Typography)(() => ({
    fontWeight: 400,
}));

DragAreaTypo.defaultProps = {
    variant: 'h4'
}

