// import styled from 'styled-components';
import colors from "../../../../../newShared/theme/colors";
import {device} from "../../../../../newShared/constants";
import {Autocomplete, styled, TextField, Typography} from "@mui/material";

export const TableHeaderWrapper = styled('div')`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0 24px 0;
    ${device.tablet} {
        width: 100%;
        margin: 25px 0 24px 0;
    }
`;

export const TableHeaderItemWrapper = styled('div')<{ width?: string}>`
    width: ${props => props.width ?? '24%'};
    display: flex;
    align-items: center;
`;

export const DocumentsTableHeaderItemWrapper = styled('div')<{ width?: string, ai?: string}>`
    width: ${props => props.width ?? '24%'};
    display: flex;
    align-items: ${props => props.ai ?? 'center'};
    & p {
        text-overflow: ellipsis; 
        display: block; 
        overflow: hidden; 
        line-height: normal;
    }
`;
export const TableRowItemWrapper = styled('div')<{width?: string}>`
    width: ${props => props.width ?? '24%'};
    display: flex;
    align-items: center;
    // justify-content: center;
    //padding: 24px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
export const FilterContainer = styled('div')`
    display: flex;
    width: 60%;
    margin: 30px 0 0 0;
    & div{
        margin: 0 10px 0 0;
    }
    ${device.tablet} {
        width: 100%;
    }
`;

export const MainTableRowWrapper = styled('div')<{disableHover?: boolean, width?: string, minWidth?: string}>`
    ${props => props.minWidth && `min-width: ${props.minWidth};`}
    width: ${props => props.width ?? '100%'};
    ${props => props.disableHover ?'' : `
        &:hover{
            background-color: ${colors.stroke.grey}
        }
    `}
`;
export const TableRowWrapper = styled('div')<{disableHover?: boolean, jc?: string, cursor?:string}>`
    width: 100%;
    cursor: ${props => props.cursor ?? "auto"};
    display: flex;
    justify-content: ${props => props.jc ?? 'space-between'};
    align-items: center;
    ${props => props.disableHover ?'' : `
        &:hover{
            background-color: ${colors.stroke.grey}
        }
         `}
`;


// export const DocumentsTableRowItemWrapper = styled('div')<{width?: string, cursor?: string}>`
//     width: ${props => props.width ?? '24%'};
//     cursor: ${props => props.cursor ?? 'auto'};
//     display: flex;
//     align-items: center;
//     // justify-content: center;
//     padding: 12px 4px;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     & p {
//         text-overflow: ellipsis;
//         display: block;
//         overflow: hidden;
//         line-height: normal;
//     }
// `;
// export const CreateVendorLoaderWrapper = styled('div')`
//     width: 30%;
//     margin: 32px 0 0 0;
//     ${device.generateMax(958)}{
//         width: 60%;
//     }
//     ${device.mobileL} {
//         width: 90%;
//     }
// `;
export const CreateVendorInputsWrapper = styled('div')<{disableOverflowAuto?: boolean}>`
    display: flex;
  flex-direction: column;
  overflow: auto;
    margin: 20px 0 0 0;
  & input{
    max-width: 320px;
  }
`;
export const MainTableScrollWrapper = styled('div')<{disableHover?: boolean}>`
    overflow: auto;
    ${props => props.disableHover ?'' : `
        &:hover{
            background-color: ${colors.stroke.grey}
        }
    `}

`;

export const SearchSettingWrapper = styled('div')`
    width: 30%;
    margin: 20px 0 0 0;
    ${device.generateMax(958)} {
        width: 60%;
    }
    ${device.mobileL} {
        width: 90%;
    }
`;

export const TextFieldCustom = styled(TextField)`
  width: min(100%, 300px);
  
  & .MuiInputBase-input {
    height: 16px;
  }
  & label{
    top: 0 !important;
  }
`;
export const ListPageTopButtonContainer = styled('div')`
    display: flex;
    align-items: center;
    ${device.generateMax(1049)} {
        width: 100%;
        justify-content: space-between;
    }
    ${device.generateMax(501)} {
        margin: 11px 0 0 0;
    }
`;

export const VendorOverviewSubWrapper = styled('div')<{width?: string, margin?:string}>`
    width: ${props => props.width ?? '50%'};
    flex-direction: column;
    display: flex;
    //
    margin: ${props => props.margin ?? '0 0 0 0'};
    ${device.desktopNarrow} {
        width: 100%;
    }
`;

export const FieldTitleText = styled(Typography)({

});
FieldTitleText.defaultProps = {
    variant: 'body1',
    color: colors.text.grey,
    marginTop: '20px'
}

export const FieldValueText = styled('span', {
    shouldForwardProp: (propName) => (
        !["textColor"].includes(propName.toString())
    )}
)(({textColor}: {textColor?: string}) => ({
    color: textColor ? textColor : colors.grayText
}));


// export const TabPanelInnerWrapper = styled('div')<{width?: string}>`
//     width: ${props => props.width ?? '70%'};
//
// `;

export const DocumentsTableHeaderWrapper = styled('div')<{minWidth?: string, jc?: string}>`
    ${props => props.minWidth && `min-width: ${props.minWidth};`}
    display: flex;
    width: 90%;
    overflow: auto;
    justify-content: ${props => props.jc ?? 'space-between'};
    align-items: center;
    margin: 0 0 24px 0;
    ${device.tablet} {
        width: 100%;
        margin: 0 0 24px 0;
    }
`;

export const SettingsHeaderWrapper = styled('div')<{widthDesktopM?: string}>`
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 32px 0 0 0;
    ${device.desktopM} {
        width: ${props => props.widthDesktopM ?? '100%'};
    }
`;

export const ExactSettingHeaderWrapper = styled('div')`
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 32px 0 0 0;
    ${device.desktopNarrow}{
        width: 55%;
    }
`;

export const SettingsListWrapper = styled('div')`
    width: 100%;
    overflow: auto;
    margin: 16px 0 0 0;
`;

export const SettingsRowWrapper = styled('div')<{disableHover?: boolean, padding?: string}>`
    width: 100%;
    padding: ${props => props.padding ?? '16px 0'};
    ${props => props.disableHover ? '' : `
    &: hover{
        background-color: ${colors.stroke.grey}
    }
    `}
`;

export const SettingsRowDataWrapper = styled('div')<{width?: string, disableHover?: boolean}>`
    width: ${props => props.width ?? '45%'};
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${props => props.disableHover ? '' : `
    &: hover{
        background-color: ${colors.stroke.grey}
    }
    `}
    ${device.desktopNarrow} {
        width: 100%;
        padding: 0 5px;
    }
`;
export const AutoComplete = styled(Autocomplete)`
  width: min(100%, 300px);
  
  & .MuiInputBase-input {
    height: 24px;
  }
  & label{
    top: 0 !important;
  }
`;

