import {Box, styled, TextField} from "@mui/material";


export const FlexRow = styled(Box)`
    display: flex;
    flex-direction: row;
`
export const FlexColumn = styled(Box)`
    display: flex;
    flex-direction: column;
`


export const EditorTitleInput = styled(TextField)`
  flex-grow: 1;
  
  & input.MuiInputBase-input {
    font-size: 16px !important;
    font-weight: 600 !important;
    padding-inline: 0 !important;
  };
  
  &:before {
    display: none;
  }
`
EditorTitleInput.defaultProps = {
    variant: "standard",
    placeholder: "Document title",
    size: 'small',
    InputProps: {
        disableUnderline: true
    }
}

export const EditorTitleWithActionsRowStyled = styled(FlexRow)`
  gap: 64px;
  padding: 6px 20px;
  align-items: center;
  
  & span.statusTitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.15px;
  }
`