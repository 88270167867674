import {frameworkColorMapper, TVicaTaskFramework, TVicaTaskStatus} from "./types";
import {Flex} from "../../../newShared/components/Layouts";
import colors from "../../../newShared/theme/colors";
import {Typography} from "@mui/material";
// @ts-ignore
import {getPastelColor} from "pastel-color";
import {useMainTranslation} from "../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const TaskStatus = ({status, margin} : {status: TVicaTaskStatus, margin?: string}) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathVica.TasksList'});
    switch (status){
        case 'NOT_STARTED': return <Flex background={'#F2F4F7'} br={'15px'} p={'6px'} m={margin}>
            <Typography fontSize={'12px'} fontWeight={400} color={'#344054'}>{t('Not started')}</Typography>
        </Flex>;
        case 'IN_PROGRESS': return <Flex background={colors.backgrounds.orange} br={'15px'} p={'6px'} m={margin}>
            <Typography fontSize={'12px'} fontWeight={400} color={colors.decorative.orange}>{t('In progress')}</Typography>
        </Flex>;
        case 'COMPLETE': return <Flex background={'#ECFDF3'} br={'15px'} p={'6px'} m={margin}>
            <Typography fontSize={'12px'} fontWeight={400} color={'#027A48'}>{t('Complete')}</Typography>
        </Flex>;
    }
}

type backAndFontColorType = {background: string, fontColor: string};

export const generateColorsForFrameworks = (existingMapper: frameworkColorMapper[], frameworkIds: TVicaTaskFramework[]): frameworkColorMapper[] => {
    //copy array
    const mapper = [...existingMapper];
    //searching for new framework
    const neededGenerate = frameworkIds.filter(e => !existingMapper.some(m => m.id === e.id));
    //generating color for each
    neededGenerate.forEach(e => {
        const color = randomHSLA(`${e.name}#${e.id}`);
        mapper.push({id: e.id, ...color});
    })
    return mapper;
}

export function randomHSLA(name: string): backAndFontColorType{
    const background: string | number[] = getPastelColor(`SOME_TEXT6669_${name}`, {alpha: '1'}).hslRaw; //hslRaw: [ 288, '70%', '70%' ]
    const hue: number = background[0] as number;
    // const r = ~~(360 * Math.random());
    return {
        background: `hsl(${hue}, 76%, 96%)`,
        fontColor: `hsl(${hue}, 47%, 68%)`
    }
}