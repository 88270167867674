import React, {FC} from "react";
import {Button, Menu, MenuItem} from "@mui/material";
import {EditorSideMenuState} from "../../index";
import {useMenu} from "../../../../../../hooks/useMenu";
import {ArrowDropDownRounded, ArrowDropUpRounded} from "@mui/icons-material";

interface Props  {
    active: EditorSideMenuState["showVariables"];
    onSelect: (value: EditorSideMenuState["showVariables"]) => void;
}
export const VariablesUsedUnusedDropdown: FC<Props> = ({active, onSelect}) => {

    const {open, handleClick, handleClose, anchorEl} = useMenu();

    return (
        <>
            <Button variant={"outlined"} onClick={handleClick}
                    endIcon={open ? <ArrowDropUpRounded/> : <ArrowDropDownRounded/>}
                    sx={{
                        width: 'fit-content',
                        alignSelf: 'end'
                    }}>
                {active === "all" && "All variables"}
                {active === "used" && "Used variables"}
                {active === "unused" && "Not used variables"}
            </Button>

            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem sx={{minWidth: anchorEl?.offsetWidth}} onClick={() => {onSelect("all"); handleClose()}}>{'All variables'}</MenuItem>
                <MenuItem sx={{minWidth: anchorEl?.offsetWidth}} onClick={() => {onSelect("used"); handleClose()}}>{'Used variables'}</MenuItem>
                <MenuItem sx={{minWidth: anchorEl?.offsetWidth}} onClick={() => {onSelect("unused"); handleClose()}}>{'Not used variables'}</MenuItem>
            </Menu>
        </>
    )
};