import {useDispatch, useSelector} from "react-redux";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    CLIENTS_DEFAULT_PAGING,
    CORPORATE_TARGET_TYPE,
    CRYPTOWALLET_TARGET_TYPE,
    KYC_ROOT_PATH,
    PERSON_TARGET_TYPE
} from "../../../constants";
import {
    archiveSelector,
    eraseArchive,
    eraseIsDataOutdated,
    isDataOutdated,
    loadings as loadingsSelector,
    openDeleteArchivedTarget,
    openRestoreClientFromArchiveDialog,
    replaceArchiveMinMaxLoadedPage
} from "../../../store/slice";
import {GetKycArchiveWithFilterAndPaging} from "../../../store/actions";
import {KycArchivedTargetModel} from "../../../../../../newShared/GQLTypes";
import {useLocation} from "react-router";
import {keyConfig} from "../../../../../../newShared/components/genericFilter/types";
import {
    useGenericFiltersStorage
} from "../../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {getNormalizedTargetType} from "../../../helpers";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../../newShared/components/genericTable/types";


export const useKycArchive = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.archive'});

    //root
    const dispatch = useDispatch();
    // const history = useHistory();
    const pathname = useLocation().pathname
    const {currentFiltersForFetch, currentSearchForFetch} = useGenericFiltersStorage();

    //selectors
    const {tMenu} = useMainTranslation();
    const {clients, pageInfo, minMaxLoadedPage: {minLoadedPage, maxLoadedPage}} = useSelector(archiveSelector);
    const loadings = useSelector(loadingsSelector);
    const isOutdated = useSelector(isDataOutdated).archiveList;

    //useEffects
    useSetBreadcrumbs([
        {
            title: tMenu('KYC Management'),
            path: KYC_ROOT_PATH
        },
        {
            title: tMenu('Archive'),
            path: pathname
        }
    ]);

    //open dialogs
    const handleOpenRestoreClientFromArchive = (selectedClient: KycArchivedTargetModel) => {
        dispatch(openRestoreClientFromArchiveDialog({selectedClient}));
    }

    const handleOpenDeleteArchivedTarget = (target: KycArchivedTargetModel) => {
        dispatch(openDeleteArchivedTarget({target}));
    }

    //config filters
    const archiveDate: keyConfig<string> = {
        type: 'date',
        key: 'archivedDate',
        name: t('Archive date'),
        default: true,
    }

    // const riskConfig: keyConfig<string> = {
    //     type: 'filter',
    //     key: 'risk',
    //     name: t('Risk'),
    //     options: [TARGET_LOW_RISK, TARGET_MEDIUM_RISK, TARGET_HIGH_RISK],
    //     OptionRenderer: (option, color) => getTargetRisk(option),
    //     // getOptionLabel: (o) => getNormalizedClientRiskName(o),
    //     default: true
    // }


    //config filters
    // const targetType: keyConfig<{type: TKycClientPage, title: string}> = {
    //     type: 'filter',
    //     key: 'targetType',
    //     name: t('Type'),
    //     options: [{type: 'INDIVIDUAL', title: 'Individual'}, {type: 'CORPORATE', title: 'Corporate'}],
    //     isOptionEqual: (option, value) => option.type === value.type,
    //     getFilterValue: (a) => a.type,
    //     getOptionLabel: (e) => e.title,
    //     default: true
    // };

    const targetType: keyConfig<string> = {
        type: 'filter',
        key: 'targetType',
        name: t('Type'),
        options: [PERSON_TARGET_TYPE, CORPORATE_TARGET_TYPE, CRYPTOWALLET_TARGET_TYPE],
        getOptionLabel: (e) => getNormalizedTargetType(e),
        default: true
    };



    //scroll
    // const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
    //     getData: ({page, count}) => {
    //         dispatch(GetKycArchiveWithFilterAndPaging({data: { pageInfo: {page, count: CLIENTS_DEFAULT_PAGING, filters: {...currentFiltersForFetch, nameLike: currentSearchForFetch}}}, signal: controller.signal}))
    //     },
    //     arrayLength: clients.length,
    //     isLoading: loadings.isLoadingList,
    //     pageInfo: pageInfo,
    //     // cleanData: () => dispatch(eraseCategories()),
    //     // controller: controller,
    //     // isOnTop: true,
    //     isNoMoreThanTotal: true,
    //     customDependencies: [],
    //     is0PageAddictive: false,
    //     disableInitFetch: true,
    // })

    const handleReloadData = () => {
        const filters = currentFiltersForFetch;
        const search = currentSearchForFetch;

        dispatch(eraseArchive());
        dispatch(eraseIsDataOutdated());
        dispatch(GetKycArchiveWithFilterAndPaging({data: { pageInfo: {page: 0, count: CLIENTS_DEFAULT_PAGING, filters: {...filters, nameLike: search}}}}))
    }

    return {
        clients,
        pageInfo,
        loadings,
        filter: {
            configs: [
                archiveDate,
                targetType,
                // riskConfig
            ],
        },
        //open dialogs
        handleOpenRestoreClientFromArchive,
        handleOpenDeleteArchivedTarget,
        // isNoArchiveClientsFound: !loadings.isLoadingList && !clients.length && !isInitial,

        // scroll: {
        //     bottomLoader,
        //     onScroll,
        //     scrollRef,
        //     hasMore: pageInfo.total > ((pageInfo.page + 1) * pageInfo.count),
        // },
        isOutdated,
        handleReloadData,
        genericTable: {
            paging: {
                pageInfo,
                isLoading: loadings.isLoadingList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceArchiveMinMaxLoadedPage(data)),
                clearRows: () => dispatch(eraseArchive()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    dispatch(GetKycArchiveWithFilterAndPaging({data: { pageInfo: {page, count, filters}}}));
                },
            },
            handleRestoreFromArchive: (row: KycArchivedTargetModel) => {
                handleOpenRestoreClientFromArchive(row);
            },
            handleDeleteArchivedTarget: (row: KycArchivedTargetModel) => {
                handleOpenDeleteArchivedTarget(row);
            }
        },
    }

}
