import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {addNewPipeline, loadings, openChangeName, prevSelected, selectedAutomation} from "../../store/slice";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    AUTOMATIONS_CREATE_PATH,
    AUTOMATIONS_EXACT_PATH,
    AUTOMATIONS_LIST_PATH,
    columnGap,
    firstRowYDefault,
    PATH_LOCAL_AUTOMATIONS,
    rowGapX,
    rowGapY
} from "../../constants";
import {getInitAutomation} from "../../helpers";
import {useEffect, useState} from "react";
import {automationType, FlowConnection, FlowElement} from "../../types";
import {AddAutomation} from "../../store/actions";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";

export const useAutomationsCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const _revDir = useMainTranslation();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const {workspaceId, currData} = UseManageWorkspacesAndOrganizations();

    //selectors
    const selected = useSelector(selectedAutomation);
    const prev = useSelector(prevSelected);

    //
    const {createAutomation} = useSelector(loadings);

    //
    //bread
    useSetBreadcrumbs([
        {
            title: tMenu('Automations'),
            path: PATH_LOCAL_AUTOMATIONS
        },
        {
            title: tMenu('Created automations'),
            path: AUTOMATIONS_LIST_PATH
        },
        {
            title: tMenu('Create automation'),
            path: AUTOMATIONS_CREATE_PATH
        }
    ]);

    useEffect(() => {
        dispatch(addNewPipeline(getInitAutomation('', currData?.selectedWorkspace?.name ?? '', workspaceId ?? '')));
        dispatch(openChangeName());
        //eslint-disable-next-line
    }, []);

    const [elements, setElements] = useState<FlowElement[]>([]);
    const [connections, setConnections] = useState<FlowConnection[]>([]);
    const [isGenereting, setIsGenerating] = useState<boolean>(false);

    useEffect(() => {
        const {elements, connections} = generateFlow(selected, _revDir.revDir);
        setElements(elements);
        setConnections(connections);
        // test.fitView();
        // if(instance) instance.fitView();

        // return cleanUp;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, _revDir.revDir]);

    const generateFlow = (automation : automationType | null, revDir: boolean): {elements: FlowElement[], connections: FlowConnection[]} => {
        if(!automation) return {elements: [], connections: []};
        setIsGenerating(true);
        // console.log('GENERETING!!!');

        const _columnGap = !revDir ? columnGap : -columnGap;
        const _rowGapX = !revDir ? rowGapX : -rowGapX - 25;
        const revDirPlusNodeShift = 60;

        let genElements: FlowElement[] = [];
        let genConnections: FlowConnection[] = [];
        genElements.push({ id: 'start', type: 'start', data: {revDir}, position: { x: 50, y: 5 }});
        //TRIGGER
        genElements.push({id: 'trigger', type: 'trigger', data: { type: automation.trigger.type, revDir}, position: { x: _columnGap, y: firstRowYDefault }});
        genConnections.push({id: 'start-trigger', source: 'start', type: 'smoothstep', target: 'trigger'});
        if(automation.trigger.type === 'schedule'){
            genElements.push({id: 'trigger-date', type: 'date', data: { date: automation.trigger.start, revDir}, position: { x: _columnGap + _rowGapX, y: firstRowYDefault + rowGapY }});
            genElements.push({id: 'trigger-repeat', type: 'repeat', data: { repeat: automation.trigger.repeat, revDir}, position: { x: _columnGap + _rowGapX, y: firstRowYDefault + rowGapY * 2 }});
            genConnections.push({id: 'trigger-to-date', source: 'trigger', sourceHandle: 'trigger-bottom-source', type: 'smoothstep', target: 'trigger-date'});
            genConnections.push({id: 'trigger-to-repeat', source: 'trigger', sourceHandle: 'trigger-bottom-source', type: 'smoothstep', target: 'trigger-repeat'});
        }
        //DOC
        genElements.push({id: 'document', type: 'doc', data: {type: automation.documents.type, revDir}, position: { x: _columnGap * 2, y: firstRowYDefault}});
        genConnections.push({id: 'trigger-document', source: 'trigger', type: 'smoothstep', target: 'document'});
        automation.documents.links.forEach((doc, id, arr) => {
            genElements.push({id: `doc-child-${id}`, type: 'docChild', data: {text: doc.fileName, id: doc.link, revDir}, position: {x: _columnGap * 2 + _rowGapX, y: firstRowYDefault + rowGapY * (id + 1)}});
            genConnections.push({id: `doc-to-docChild-${id}`, source: 'document', sourceHandle: 'doc-bottom-source', type: 'smoothstep', target: `doc-child-${id}`});
            //if last child have to add + button
            if(id === arr.length - 1){
                genElements.push({id: `doc-plus`, type: 'plus', data: {type: 'document', revDir}, position: {x: _columnGap * 2 + (revDir ? revDirPlusNodeShift : _rowGapX), y: firstRowYDefault + rowGapY * (id + 2)}});
                genConnections.push({id: 'doc-to-plus', source: 'document', sourceHandle: 'doc-bottom-source', type: 'smoothstep', target: 'doc-plus'});
            }
        });
        if(automation.documents.links.length === 0){
            genElements.push({id: `doc-plus`, type: 'plus', data: {type: 'document', revDir}, position: {x: _columnGap * 2 + (revDir ? revDirPlusNodeShift : _rowGapX), y: firstRowYDefault + rowGapY}});
            genConnections.push({id: 'doc-to-plus', source: 'document', sourceHandle: 'doc-bottom-source', type: 'smoothstep', target: 'doc-plus'});
        }
        //STAGES
        automation.stages.forEach((el, id) => {
            genElements.push({id: `stage-${id}`, type: 'stage', data: {type: el.type, id, eta: el.eta, revDir}, position: {x: _columnGap * (id + 3), y: firstRowYDefault}});
            if(id === 0){
                //if it is first stage connecting to document element
                genConnections.push({id: `document-to-stage-0`, source: 'document', type: 'plus', target: 'stage-0'});
            }else{
                //making connection with previous stage
                genConnections.push({id: `stage${id - 1}-to-stage${id}`, source: `stage-${id - 1}`, type: 'plus', target: `stage-${id}`});
            }
            el.actors.forEach((actor, emailId, arr) => {
                genElements.push({id: `stage${id}-child${emailId}`, type: 'email', data: {email: actor.email, stageId: id, revDir}, position: {x: _columnGap * (id + 3) + _rowGapX, y: firstRowYDefault + rowGapY * (emailId + 1)}});
                genConnections.push({id: `stage${id}-to-child${emailId}`, source: `stage-${id}`, sourceHandle: `stage-${id}-bottom`, type: 'smoothstep', target: `stage${id}-child${emailId}`});
                if(emailId === arr.length - 1){
                    genElements.push({id: `stage${id}-plus`, type: 'plus', data: {type: 'actor', stageId: id, revDir}, position: {x: _columnGap * (id + 3) + (revDir ? revDirPlusNodeShift : _rowGapX), y: firstRowYDefault + rowGapY * (emailId + 2)}});
                    genConnections.push({id: `stage${id}-to-plus`, source: `stage-${id}`, sourceHandle: `stage-${id}-bottom`, type: 'smoothstep', target: `stage${id}-plus`});
                }
            });
            if(el.actors.length < 1){
                genElements.push({id: `stage${id}-plus`, type: 'plus', data: {type: 'actor', stageId: id, revDir}, position: {x: _columnGap * (id + 3) + (revDir ? revDirPlusNodeShift : _rowGapX), y: firstRowYDefault + rowGapY}});
                genConnections.push({id: `stage${id}-to-plus`, source: `stage-${id}`, sourceHandle: `stage-${id}-bottom`, type: 'smoothstep', target: `stage${id}-plus`});
            }
        });
        //SAVE
        // automation.save.type
        genElements.push({id: 'save', type: 'save', data: {type: automation.evidence.type, revDir}, position: {x: _columnGap * (automation.stages.length + 3), y: firstRowYDefault}});
        genConnections.push({id: `stage-${automation.stages.length - 1}-to-save`, source: `stage-${automation.stages.length - 1}`, type: 'plus', target: 'save'});
        automation.evidence.data.forEach((el, id, arr) => {
            genElements.push({id: `save-child-${id}`, type: 'saveChild', data: {text: el.name, id: el.id, revDir}, position: {x: _columnGap * (automation.stages.length + 3) + (revDir ? -revDirPlusNodeShift : _rowGapX), y: firstRowYDefault + (rowGapY * (id + 1))}});
            genConnections.push({id: `save-to-child${id}`, target: `save-child-${id}`, type: 'smoothstep', source: 'save', sourceHandle: 'save-bottom-source'});
            if(id === arr.length - 1){
                genElements.push({id: `save-plus`, type: 'plus', data: {type: automation.evidence.type, revDir}, position: {x: _columnGap * (automation.stages.length + 3) + (revDir ? revDirPlusNodeShift : _rowGapX), y: firstRowYDefault + rowGapY * (id + 2)}});
                genConnections.push({id: `save-to-plus`, source: `save`, sourceHandle: `save-bottom-source`, type: 'smoothstep', target: `save-plus`});
            }
        });
        if(automation.evidence.data.length < 1){
            genElements.push({id: `save-plus`, type: 'plus', data: {type: automation.evidence.type, revDir}, position: {x: _columnGap * (automation.stages.length + 3) + (revDir ? revDirPlusNodeShift : _rowGapX), y: firstRowYDefault + rowGapY}});
            genConnections.push({id: `save-to-plus`, source: `save`, sourceHandle: `save-bottom-source`, type: 'smoothstep', target: `save-plus`});
        }
        //FINISH
        genElements.push({id: 'finish', type: 'finish', data: {revDir}, position: {x: _columnGap * (automation.stages.length + 4), y: 5}});
        genConnections.push({id: 'save-finish', source: 'save', type: 'smoothstep', target: 'finish'});
        setIsGenerating(false);
        return {
            elements: genElements,
            connections: genConnections,
        }
    }

    const onLoad = async (reactFlowInstance:any) => {
        // reactFlowInstance.fitView();
        setTimeout(() => reactFlowInstance.fitView(), 50);
    };

    //
    const handleSave = () => {
        if(selected){
            dispatch(AddAutomation({
                automation: selected,
                onSuccess
            }));
        }
    }

    const onSuccess = (id: string) => {
        history.push(AUTOMATIONS_EXACT_PATH.replace(':autoId', id))
    }

    const handleOpenChangeNameDialog = () => {
        dispatch(openChangeName());
        // handleClose();
    }

    const handleGoBack = () => {
        history.push(AUTOMATIONS_LIST_PATH);
    }


    return{
        isLoadingCreate: createAutomation,
        auto: selected,
        allElements: [...elements, ...connections],
        onLoad,
        actions: {
            handleSave,
            handleGoBack,
            handleOpenChangeNameDialog
        },
        prev,
        isGenereting
    }
}