import {useSecurity} from "../../hooks/security/useSecurity";
import * as S from "../../components/styled";
import {Box, Button, Tab, Tabs, TextField, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import React, {useState} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {Flex} from "../../../../../newShared/components/Layouts";
import {TabContext, TabPanel} from "@mui/lab";
import {IconTextButton, LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import SecurityIcon from '@mui/icons-material/Security';
import {PasswordField} from "../../../../../newShared/components/Inputs";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {useSnack} from "../../../../barsEnvironment/snack/hooks/useSnack";
import {UseManageWorkspacesAndOrganizations} from "../../../cookies/hooks/useManageWorkspacesAndOrganizations";
import {validatePassword} from "../../../../../newShared/utils/text";
import {Typo} from "../../../../../newShared/components/Typography";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";


export const Security = () => {
    const {user} = UseManageWorkspacesAndOrganizations();
    const {
        tab,
        setTab,
        passwordTab,
        mfaTab
    } = useSecurity();
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'pathSettings.Security'});
    const {isMobile} = useMedia(858);
    const snack = useSnack();

    const [touched, setTouched] = useState<string[]>([]);
    const handleTouch = (field: string) => {setTouched(prev => !prev.includes(field) ? [field, ...prev] : prev)};
    const [activeField, setActiveField] = useState<string>('');
    const handleFocus = (field: string) => () => {
        handleTouch(field);
        setActiveField(field);
    };

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('Security')}
                actions={[]}
            />

            <TabContext value={tab}>
                <Box sx={{ margin: !isMobile ? '30px 0 0 0' : '0' }}>
                    <Tabs variant={'scrollable'} value={tab} onChange={(e, newValue: 'mfa' | 'password') => setTab(newValue)}>
                        <Tab label={t("Password")} value={'password'} sx={{paddingLeft: '0'}}/>
                        <Tab label={t("Authorization")} value={'mfa'} />
                    </Tabs>
                </Box>

                <S.ProfileSettingWrapper>
                    <TabPanel value={'password'} sx={{padding: '0'}}>
                        {passwordTab.isEditMode ?
                            <Flex direction={'column'}>
                                <Flex ai={'center'}>
                                    <LoadingButton
                                        loading={passwordTab.isLoading}
                                        // disabled={!passwordTab.isOk}
                                        disabled={passwordTab.form.prevPassword.trim() === '' || passwordTab.form.newPassword.trim() === '' || passwordTab.form.confirmPassword.trim() === ''}
                                        sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                        variant={"contained"}
                                        onClick={passwordTab.handleSave}
                                    >{t('Submit')}</LoadingButton>
                                    <Button disabled={passwordTab.isLoading} variant={'outlined'} size={isMobile ? 'small' : 'medium'} onClick={passwordTab.cancelChangePassword} >{t('Cancel')}</Button>
                                </Flex>

                                <PasswordField
                                    onChange={passwordTab.handleChange}
                                    value={passwordTab.form.prevPassword}
                                    name={'prevPassword'}
                                    label={t('Input previous password')}
                                    m={'16px 0 0 0'}
                                    error={touched.includes('prevPassword') && activeField !== 'prevPassword' && !validatePassword(passwordTab.form.prevPassword)}
                                    onFocus={handleFocus('prevPassword')}
                                    onBlur={handleFocus('')}
                                />

                                <PasswordField
                                    onChange={passwordTab.handleChange}
                                    value={passwordTab.form.newPassword}
                                    name={'newPassword'}
                                    label={t('New password')}
                                    m={'16px 0 0 0'}
                                    error={touched.includes('newPassword') && activeField !== 'newPassword' && !validatePassword(passwordTab.form.newPassword)}
                                    onFocus={handleFocus('newPassword')}
                                    onBlur={handleFocus('')}
                                />

                                <PasswordField
                                    onChange={passwordTab.handleChange}
                                    value={passwordTab.form.confirmPassword}
                                    name={'confirmPassword'}
                                    label={t('Confirm new password')}
                                    m={'16px 0 0 0'}
                                    error={touched.includes('confirmPassword') && activeField !== 'confirmPassword' && passwordTab.form.newPassword !== passwordTab.form.confirmPassword}
                                    onFocus={handleFocus('confirmPassword')}
                                    onBlur={handleFocus('')}
                                />
                            </Flex>
                            :
                            <>
                                <IconTextButton
                                    variant={'text'}
                                    icon={<SecurityIcon fontSize="small"/>}
                                    onClick={passwordTab.handleChangePassword}
                                    sx={{maxWidth: '200px', paddingLeft: !revDir ? '0' : 'initial', paddingRight: !revDir ? 'initial' : '0'}}
                                >{t('Change password')}
                                </IconTextButton>

                                <Flex w={'100%'} ai={'center'} m={'16px 0 0 0'}>
                                    <Typography variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} color={colors.lightGrayText}>{t('Email')}</Typography>
                                    <Typography variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} color={colors.grayText}>{user?.email ?? t('Unknown')}</Typography>
                                </Flex>

                                <Flex w={'100%'} ai={'center'} m={'20px 0 0 0'}>
                                    <Typography variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} color={colors.lightGrayText}>{t('Password')}</Typography>
                                    <Typography variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} color={colors.grayText}>********</Typography>
                                </Flex>
                            </>
                        }
                    </TabPanel>
                </S.ProfileSettingWrapper>

                <S.ProfileSettingWrapper>
                    <TabPanel value={'mfa'} sx={{padding: '0', overflowY: 'auto'}}>
                        {mfaTab.step === 'alreadyConfigured' &&
                            <>
                                <Typography variant={'h4'} color={colors.backgrounds.dark}>{t('Setup authenticator app')}</Typography>
                                <Typography variant={'body2'} color={colors.backgrounds.dark} sx={{margin: '8px 0 0 0'}}>{t('2FA via APP configured successfully.')}</Typography>
                                <Flex ai={'center'} m={'32px 0 0 0'}>
                                    <LoadingButton
                                        loading={mfaTab.loadings.isLoadingDisable}
                                        disabled={mfaTab.loadings.isLoadingInitiateReconfigure}
                                        sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px', paddingLeft: '35px'}}
                                        variant={"contained"}
                                        onClick={mfaTab.disableGoogleMfa}
                                        color={'error'}
                                    >{t('Disable')}</LoadingButton>

                                    <LoadingButton
                                        loading={mfaTab.loadings.isLoadingInitiateReconfigure}
                                        disabled={mfaTab.loadings.isLoadingDisable}
                                        sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px', paddingLeft: '35px', }}
                                        variant={"contained"}
                                        onClick={mfaTab.startConfigure}
                                    >{t('Reconfigure')}</LoadingButton>
                                </Flex>
                            </>
                        }

                        {mfaTab.step === 'startConfigure' &&
                            <>
                                <Typography variant={'h4'} color={colors.backgrounds.dark}>{t('Setup authenticator app')}</Typography>
                                <Typography variant={'body2'} color={colors.backgrounds.dark} sx={{margin: '8px 0 0 0'}}>{t('Use a phone app like 1Password, Aulthy,  LastPass Authenticator, etc. to get 2FA codes when prompted during sign-in.')}</Typography>
                                <Flex ai={'center'} m={'32px 0 0 0'} jc={'flex-end'}>
                                    <LoadingButton
                                        loading={mfaTab.loadings.isLoadingInitiateReconfigure}
                                        sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px', paddingLeft: '35px', }}
                                        variant={"contained"}
                                        onClick={mfaTab.startConfigure}
                                    >{t('Configure')}</LoadingButton>
                                </Flex>
                            </>
                        }

                        {mfaTab.step === 'configuration' &&
                            <>
                                <Typography variant={'h4'} color={colors.backgrounds.dark}>{t('Setup authenticator app')}</Typography>
                                <Typography variant={'body2'} color={colors.backgrounds.dark} sx={{margin: '8px 0 0 0'}}>{t('Use an authenticator app from your phone to scan.')}</Typography>

                                <S.QrCodeWrapper>
                                    <img src={mfaTab.qrCode ?? ''} alt="qr code" width={'100%'}/>
                                </S.QrCodeWrapper>

                                <Typography variant={'body2'} color={colors.backgrounds.dark} sx={{margin: '16px 0 0 0'}}>{t('If you are unable to scan, enter this text code instead.')}</Typography>

                                <S.SecretRevealWrapper padding={'16px 0 0 0'}>
                                    <Typography variant={'body1'}>
                                        {
                                            mfaTab.showSecret ?
                                                (mfaTab.secret && mfaTab.secret.split('').map((e, id) => (id === 10 || id === 20) ? `${e}\n` : e).join(''))
                                                :
                                                (mfaTab.secret ? mfaTab.secret.split('').slice(0, 10).map((e, id) => id > 4 ? '*' : e).join('')  : '**********')
                                        }
                                    </Typography>

                                    <IconTextButton
                                        size={'small'}
                                        variant={'text'}
                                        disabled={mfaTab.secret === null || (mfaTab.secret.length === 0)}
                                        icon={mfaTab.showSecret ? <VisibilityOffIcon fontSize="small"/> : <VisibilityIcon fontSize="small"/>}
                                        onClick={() => mfaTab.setShowSecret(!mfaTab.showSecret)}
                                    >
                                        <Typography fontWeight={600}>{(!mfaTab.showSecret) ? t('Show') : t('Hide')}</Typography>
                                    </IconTextButton>

                                    <IconTextButton
                                        size={'small'}
                                        variant={'text'}
                                        disabled={mfaTab.secret === null || (mfaTab.secret.length === 0)}
                                        icon={<ContentCopyIcon fontSize="small"/>}
                                        onClick={() => {navigator.clipboard.writeText(mfaTab.secret ?? '')
                                            .then(() => {snack(t('Secret copied to clipboard'), "info")})}
                                        }
                                    >
                                        <Typography fontWeight={600}>{t('Copy')}</Typography>
                                    </IconTextButton>
                                </S.SecretRevealWrapper>

                                <TextField
                                    sx={{margin: '10px 0 0 0'}}
                                    dir={'ltr'}
                                    name={'code'}
                                    value={mfaTab.code}
                                    label={t('Type code')}
                                    placeholder={t('Code')}
                                    onChange={mfaTab.handleChange}
                                />

                                <Flex jc={'flex-end'} w={'100%'} m={'16px 0 0 0'}>
                                    <LoadingButton loading={mfaTab.loadings.isLoadingConfigure}
                                                   disabled={!mfaTab.code.trim().length}
                                                   onClick={mfaTab.validateConfig}
                                                   variant={"contained"}
                                    >
                                        {t('Next')}
                                    </LoadingButton>
                                </Flex>
                            </>
                        }

                        {mfaTab.step === 'recoveryCodes' &&
                            <>
                                <Typography variant={'h4'} color={colors.backgrounds.dark}>{t('Download your recovery codes')}</Typography>
                                <Typography variant={'body2'} color={colors.backgrounds.dark} sx={{margin: '8px 0 0 0'}}>{t('Here are the recovery codes you can use to sign in if you lose or can not access your mobile device.')}</Typography>

                                <Flex w={'100%'} jc={'space-between'} ai={'center'} m={'16px 0 0 0'}>
                                    <Flex w={'100%'} direction={'column'}>
                                        {mfaTab.recoveryCodes.slice(0, 8).map(e => {
                                            return(
                                                <Flex w={'100%'} jc={'space-between'} key={e}>
                                                    <Typo fontSize={'12px'} fontWeight={300} sx={{lineHeight: 'normal'}}>{e}</Typo>
                                                </Flex>
                                            )
                                        })}
                                    </Flex>

                                    <Flex w={'100%'} direction={'column'}>
                                        {mfaTab.recoveryCodes.slice(8, 16).map(e => {
                                            return(
                                                <Flex w={'100%'} jc={'space-between'} key={e}>
                                                    <Typo fontSize={'12px'} fontWeight={300} sx={{lineHeight: 'normal'}}>{e}</Typo>
                                                </Flex>
                                            )
                                        })}
                                    </Flex>
                                </Flex>

                                <Flex w={'100%'} jc={!revDir ? 'flex-end' : 'flex-start'}>
                                    <Button variant={'text'}
                                            size={'medium'}
                                            onClick={mfaTab.handleDownLoadRecoveryCodes}
                                    >{t('Download')}</Button>
                                </Flex>

                                <Typography sx={{margin: '32px 0 0 0'}} variant={'body2'} color={colors.backgrounds.dark}>{t('Each recovery code can only be used once, but you can regenerate a new set of 10 codes at any time. Store your recovery codes in a safe place.')}</Typography>


                                <Flex ai={'center'} jc={'flex-end'} m={'32px 0 0 0'}>
                                    <Button
                                            size={'small'}
                                            variant={"contained"}
                                            onClick={mfaTab.doneWithRecoveryCodes}
                                    >{t('Done')}</Button>
                                </Flex>
                            </>
                        }
                    </TabPanel>
                </S.ProfileSettingWrapper>
            </TabContext>
        </Flex>
    )
}