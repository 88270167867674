import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {LoadingButton} from "../../../../../../../newShared/components/Basic/CommonButtons";
import {useAddEvidence} from "../../../../hooks/dialogs/useAddEvidence";


export const AddCustomEvidenceDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'builderPage.dialogs'});

    const {
        isOpen,
        form,
        isExisting,
        handleClose,
        validateForm,
        handleSave,
        handleChange,
        isLoading,
        handleChangeCategories,


    } = useAddEvidence();

    return(
        <Dialog
            open={isOpen}
            onClose={handleClose}
        >
            <DialogTitle>{t('Create evidence')}</DialogTitle>
            <DialogContent>
                <TextField id="filled-basic" label={t("Name")}
                           required
                           size={'small'}
                           variant="outlined" sx={{mt: '12px', '& label': {top: '0 !important'}}}
                           name={'name'} value={form.name} onChange={handleChange}/>

                <TextField id="filled-basic" label={t("Category")}
                           size={'small'}
                           variant="outlined" sx={{mt: '12px', '& label': {top: '0 !important'}}}
                           name={'categories'} value={form.categories[0] || ''}
                           onChange={handleChangeCategories}/>

                <TextField id="filled-basic" label={t("Description")}
                           variant="outlined" multiline minRows={5} sx={{mt: '12px'}}
                           name={'description'} value={form.description} onChange={handleChange}/>

            </DialogContent>

            <DialogActions>
                <Button variant={'text'} sx={{'&.MuiButton-text': {textTransform: 'none'}}} onClick={handleClose}>{isExisting ? t('Close') : t('Cancel')}</Button>
                {!isExisting &&
                    <LoadingButton size={'small'} disabled={!validateForm() || isLoading || isLoading}
                                   loadingPosition={isLoading ? 'start' : undefined}
                                   onClick={handleSave} loading={isLoading} >
                        {t('Save')}
                    </LoadingButton>
                }
            </DialogActions>
        </Dialog>
    )
}
