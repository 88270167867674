import React, {FC} from 'react';
import {Routes} from "./routes";
import {LeftSecondMenu} from "../../barsEnvironment/leftMenu/components/leftSecondMenu";
import {useMain} from "./hooks/useMain";


export const Builder:FC = () => {

    useMain();

    return (
        <LeftSecondMenu>
            <Routes/>
        </LeftSecondMenu>
    )
}
