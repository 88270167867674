import * as S from '../../components/styled';
import {Skeleton, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";

export const AppHistoryTableRowSkeleton = () => {
    return(
        <S.AppHistoryTableHeaderWrapper hoverable={false}>
            <S.AppHistoryTableHeaderItemWrapper width={'33%'}>
                <Typography sx={{fontSize: '14px', fontWeight: 400, color: colors.grayText}}><Skeleton width={100}/></Typography>
            </S.AppHistoryTableHeaderItemWrapper>

            <S.AppHistoryTableHeaderItemWrapper width={'33%'}>
                <Typography sx={{fontSize: '14px', fontWeight: 400}}><Skeleton width={100}/></Typography>
            </S.AppHistoryTableHeaderItemWrapper>

            <S.AppHistoryTableHeaderItemWrapper width={'33%'}>
                <Typography sx={{fontSize: '14px', fontWeight: 400, color: colors.grayText}}><Skeleton width={100}/></Typography>
            </S.AppHistoryTableHeaderItemWrapper>
        </S.AppHistoryTableHeaderWrapper>
    )
}