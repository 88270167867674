import React, {FC, useEffect, useState} from "react";
import {Button, Typography} from "@mui/material";
import {SmallSizeDialogStyled} from "../manageActorsDialog/styled";
import {
    AddVariableDialogActionsStyled,
    AddVariableDialogContentStyled,
    AddVariableDialogTitleStyled
} from "../addVariableDialog/styled";
import {FlexColumn} from "../../editorTitleWithActionsRow/styled";
import {createEventCommonDialogOpen} from "../../../helpers";
import {TCommonDialogOpenEventData} from "../../../types";
import {commonDialogOpenEventName} from "../../../constants";
import {LoadingButton} from "../../../../Basic/CommonButtons";

interface IProps {
    onConfirm: () => void;
    isLoading: boolean;
}
export const ResendInviteToGroupDialog: FC<IProps> = ({onConfirm, isLoading}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [approvers, setApprovers] = useState<string[]>([]);

    const type: TCommonDialogOpenEventData["type"] = "resendInviteGroup";
    const text = `The invitation to approve document to ${approvers.length === 1 ? 'this email' : 'those emails'} will be resent.`;
    const title = 'Resend invite';

    const handleClose = () => {
        createEventCommonDialogOpen({type, isOpen: false, data: []});
    };

    const handleEvent = (ev: Event) => {
        const {detail} = ev as CustomEvent<TCommonDialogOpenEventData>;
        if (detail.type === type) {
            setIsOpen(detail.isOpen);
            setApprovers(detail.data);
        }
    }

    useEffect(() => {
        document.addEventListener(commonDialogOpenEventName, handleEvent);
        return () => {
            document.removeEventListener(commonDialogOpenEventName, handleEvent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <SmallSizeDialogStyled open={isOpen} onClose={handleClose}>
            <AddVariableDialogTitleStyled>
                {title}
            </AddVariableDialogTitleStyled>

            <AddVariableDialogContentStyled>
                <Typography>{text}</Typography>

                <FlexColumn sx={{gap: '8px' }}>
                    {approvers.map((approver, index) => (
                        <Typography key={index} variant={"subtitle1"}>{approver}</Typography>
                    ))}
                </FlexColumn>
            </AddVariableDialogContentStyled>

            <AddVariableDialogActionsStyled>
                <Button variant={"text"} onClick={handleClose}>{'Cancel'}</Button>
                <LoadingButton loading={isLoading} variant={"contained"} onClick={onConfirm}>{'Resend invite'}</LoadingButton>
            </AddVariableDialogActionsStyled>
        </SmallSizeDialogStyled>
    )
}
