import React, {useEffect, useState} from "react";
import {addFilterComponentPropsType, filterType} from "../../types";

export const useAddFilters = ({options, selectedOptions, disabledChangeOptions}: addFilterComponentPropsType) => {
    // const [isOpen, setIsOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'add-filters' : undefined;

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const [search, setSearch] = useState<string>('');
    const [filteredOptions, setFilteredOptions] = useState<filterType[]>(options);

    useEffect(() => {
        setFilteredOptions(options);
    }, [options]);

    const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value as string;
        setSearch(value);
        filterBySearch(value);
    }

    const filterBySearch = (searchValue: string) => {
        let filtered:filterType[];
        filtered = options.filter(e => e.name.trim().toLowerCase().includes(searchValue.trim().toLowerCase()));
        setFilteredOptions(filtered);
    }

    //chip click
    // const ref = React.createRef<HTMLButtonElement>();
    //
    // const onChipClick = () => {
    //     if(!op && ref.current){
    //         ref.current.click();
    //     }
    // };

    return{
        // ref,
        // onChipClick,
        isOpen: open,
        handleOpen,
        handleClose,
        anchorEl,
        id,
        search: {
            value: search,
            onChange: handleChangeSearch,
        },
        options: filteredOptions,
        //checking if selected some options excluding default values
        isSelectedSomeOptions: selectedOptions.filter(e => !disabledChangeOptions.some(d => d.key === e.key)).length > 0
    }
}