import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,} from "@mui/material";
import {AddEditorSectionDialogPropsType} from "../../../../../types";
import {Flex} from "../../../../../../../../newShared/components/Layouts";
import {useAddEditorSectionDialog} from "../../../../../hooks/dialogHooks/useAddEditorSectionDialog";
import {TinyMceEditor} from "../../../../../../../../newShared/components/tinyMceEditor";
import React, {useCallback} from "react";
import {useMedia} from "../../../../../../../../newShared/hooks/useMedia";
import {Editor} from "@tinymce/tinymce-react";
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {getDataForSaveFromEditor} from "../../../../../../../../newShared/components/tinyMceEditor/helpers";

export const AddEditorSectionDialog = (props: AddEditorSectionDialogPropsType) => {
    const {isOpen, handleClose, formik, isOk}  = useAddEditorSectionDialog(props);
    const {t} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.dialogs'})
    const {isMobile, isLessThen1050} = useMedia();

    const handleSelectionChange = useCallback((editor: Editor | null) => {
        formik.setFieldValue('data.innerHtml', editor ? getDataForSaveFromEditor(editor.editor) : '');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <Dialog open={isOpen} onClose={handleClose}
                sx={{'.MuiPaper-root': {maxWidth: isLessThen1050 ? '100%' : '1144px', width: '100%', height: isMobile ? undefined : '804px', overflow: 'hidden'}}}
        >
            <DialogTitle>{t('Create new section by video upload')}</DialogTitle>

            <DialogContent>
                <Flex direction={'column'} gap={'20px'}>
                    <TextField
                        required
                        label={t('Section name')}
                        name={'name'}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        onBlur={formik.handleBlur}
                        error={Boolean(formik.touched.name && formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        size={'small'}
                    />

                    <Box sx={{width: '100%', minHeight: '600px', overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
                        <TinyMceEditor hideSave initialContent={formik.initialValues.data.innerHtml ?? ''} onSave={(e) => formik.setFieldValue('data.innerHtml', e)} handleSelectionChange={handleSelectionChange}/>
                    </Box>

                </Flex>
            </DialogContent>

            <DialogActions>
                <Button variant={'text'} onClick={handleClose} size={'small'}>{('Close')}</Button>
                <Button variant={'text'} onClick={formik.submitForm} size={'small'} disabled={!isOk}>{('Save')}</Button>
            </DialogActions>
        </Dialog>
    )
}
