import React from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useTrainingPreview} from "../../../hooks/dialogs/useTrainingPreview";
import colors from "../../../../../../newShared/theme/colors";
import {TrainingSection} from "../../trainingSection";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {CoverImageView} from "../../trainingCoverComponent/CoverImageView";


export const TrainingPreviewDialog = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.dialogs'});
    const {isMobile, isLessThen1050} = useMedia();

    const {
        isOpen,
        training,
        handleClose,
        handleToggleSection,
        openedSection
    } = useTrainingPreview();

    return (
        <Dialog open={isOpen} onClose={handleClose}
                sx={{'.MuiPaper-root': {minWidth: isLessThen1050 ? '90%' : '1144px', height: isMobile ? undefined : '804px'}}} >
            <DialogTitle>{t('Training preview')}</DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <Box display={'flex'} width={'100%'} gap={'20px'}>
                    {training?.coverImage && <CoverImageView cover={training.coverImage} size={'preview'}/> }

                    <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} gap={'20px'} m={training?.coverImage?.image ? '0 20px' : 'undefined'}>
                        {/*<Typography variant={'*h3'}>{training?.name ?? ''}</Typography>*/}
                        <Typography variant={'*subtitle1'} sx={{fontWeight: 400}}>{training?.name ?? ''}</Typography>
                        <Typography variant={'*bodyText1'} sx={{whiteSpace: 'brake-spaces'}}>{training?.description ?? ''}</Typography>

                    </Box>
                </Box>
                <Divider flexItem sx={{bgcolor: colors.stroke.grey, flexShrink: 0}}/>

                {/*Training section*/}
                <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} gap={'10px'} width={'100%'}>
                    <Typography variant={'*bodyText1_semibold'}>{t('Training sections')}</Typography>

                    {training?.content && [...training.content]
                        .sort((a, b) => a.order - b.order)
                        .map((e) => <TrainingSection key={e.sectionId} section={e} toggleOpen={handleToggleSection} order={e.order + 1} isOpen={openedSection === e.sectionId}/>
                    )}
                </Box>


            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} size={'small'} variant={'text'}>{tCommon('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}


