import {FC} from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {DeleteDialogButton} from "../../../../../../newShared/components/deleteDialogButton";
import {useDeleteRule} from "../../../hooks/dialogHooks/useDeleteRule";

export const DeleteRuleDialog: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        isOpen,
        handleDelete,
        handleCancel,
        isLoadingDelete,
    } = useDeleteRule();

    return (
        <DeleteDialogButton isOpen={isOpen} isLoading={isLoadingDelete}
                            title={t('Delete rule')}
                            message={t('The changes made will not affect those already running rule checks. Delete this rule anyway?')}
                            handleConfirm={handleDelete}
                            handleClose={handleCancel}
        />
    )
}
