import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {loadings, models, openDeleteModel, selectedModel, setSelectedModel} from "../../store/slice";
import React, {useEffect, useState} from "react";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_RISK_MANAGEMENT} from "../../../../../newShared/constants";
import {
    PATH_RISK_MANAGEMENT_MODELS_ALL,
    PATH_RISK_MANAGEMENT_MODELS_EXACT,
    PATH_RISK_MANAGEMENT_MODELS_EXACT_EDIT,
    PATH_RISK_MANAGEMENT_MODELS_NEW
} from "../../constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const useModelExact = () => {
    const id = useParams<{id: string}>().id;

    const dispatch = useDispatch();
    const history = useHistory();

    const selected = useSelector(selectedModel);
    const _models = useSelector(models);
    const isLoading = useSelector(loadings).modelsList;

    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    useSetBreadcrumbs([
        {
            title: tMenu('Risk Management'),
            path: PATH_LOCAL_RISK_MANAGEMENT
        },
        {
            title: tMenu('Models'),
            path: PATH_RISK_MANAGEMENT_MODELS_ALL
        },
        {
            title: selected?.name ?? 'Loading model...',
            path: PATH_RISK_MANAGEMENT_MODELS_EXACT.replace('id', id)
        }
    ]);

    useEffect(() => {
        if(!selected && id && _models.length > 0){
            const currModel = _models.find(e => e.id === id);
            currModel && dispatch(setSelectedModel(currModel));
        }else if(!id){
            history.push(PATH_RISK_MANAGEMENT_MODELS_ALL);
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!isLoading && !selected && id && _models.length > 0){
            const currModel = _models.find(e => e.id === id);
            currModel && dispatch(setSelectedModel(currModel));
        }
        //eslint-disable-next-line
    }, [isLoading]);

    const handleGoBack = () => {
        history.push(PATH_RISK_MANAGEMENT_MODELS_ALL);
    }

    const handleUseAsTemplate = () => {
        history.push(PATH_RISK_MANAGEMENT_MODELS_NEW);
        handleClose();
    }

    const handleDeleteModel = () => {
        dispatch(openDeleteModel());
        handleClose();
    }

    //MENU
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleGoToEdit = () => {
        history.push(PATH_RISK_MANAGEMENT_MODELS_EXACT_EDIT.replace(':id', id));
        handleClose();
    }

    return {
        handleGoBack,
        selected,
        handleDeleteModel,
        handleUseAsTemplate,
        menu: {
            anchorEl,
            handleClose,
            handleClick,
            open,
        },
        isLoading,
        handleGoToEdit
    }
}