import {
    AddKycTargetToBlackListMutationVariables,
    AutocompleteKycTemplatesByMatchesQuery,
    AutocompleteKycTemplatesByMatchesQueryVariables,
    AutocompleteKycTemplatesByScreeningsQuery,
    AutocompleteKycTemplatesByScreeningsQueryVariables,
    AutocompleteKycTemplatesByTargetsQuery,
    AutocompleteKycTemplatesByTargetsQueryVariables,
    CreateTargetExceptionDto,
    DeleteKycArchivedTargetMutationVariables,
    DownloadEvidenceFileQuery,
    DownloadEvidenceFileQueryVariables,
    ExportClientsByTemplateQueryVariables,
    ExportCsvTargetsQueryVariables,
    GetKycArchiveTargetsPagingResponseModel,
    GetKycArchiveWithFilterAndPagingQueryVariables,
    GetKycAvailableFieldsForExportQuery,
    GetKycAvailableFieldsForExportQueryVariables,
    GetKycAvailableSystemFieldsForExportQuery,
    GetKycAvailableSystemFieldsForExportQueryVariables,
    GetKycClientsFieldsByWorkspaceIdQueryVariables,
    GetKycMatchesPagingModel,
    GetKycOtsFullResultByEntityIdQueryVariables,
    GetKycRequestsPagingModel,
    GetKycScreeningByIdQueryVariables,
    GetKycScreeningFullInfoByIdQueryVariables,
    GetKycTargetsFieldsResponseModel,
    GetKycTargetsPagingResponseModel,
    GetKycTemplateHistoryPagingModel,
    GetKycTemplatesPagingModel,
    GetTargetExceptionResponseModel,
    GetTargetHistoryResponseModel,
    GetTemplateConfigResponseModel,
    IdMessage,
    KycCsvFieldWithAliasModel,
    KycRefactorRequestModel,
    KycRefactorTemplateModel,
    KycRefactorUpdateTargetInput,
    KycScreeningFullInfoModel,
    KycScreeningModel,
    KycScreeningPagingResponseModel,
    KycScreeningResultPagingModel,
    MutationMoveKycTargetToArchiveArgs,
    MutationRestoreKycTargetFromArchiveArgs,
    MutationRestoreKycTargetFromBlackListArgs,
    PaginationInputAnyFilter,
    QueryGetKycClientsWithFilterAndPagingArgs,
    QueryGetKycScreeningsWithFilterAndPagingArgs,
    QueryGetKycTargetExceptionsArgs,
    SetKycTargetVerifiedMutationVariables,
    StartOneTimeScreeningMutation,
    StartOneTimeScreeningMutationVariables,
    TKycTargetModel,
    UpdateKycTargetRiskMutationVariables,
    UploadCsvFileResponseModel,
    UploadFileMutation,
    UploadFileMutationVariables
} from "../../../../newShared/GQLTypes";

import axiosSync from "../../../../newShared/utils/axios/axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {print} from "graphql";
import {gql} from "@apollo/react-hooks";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {AxiosResponse} from "axios";
import {
    KycClientFieldFragment,
    KycClientFragment,
    KycExceptionModelFragment,
    KycRefactorRequestModelFragment,
    KycRefactorTemplateModelFragment,
    KycScreeningFragment,
    KycScreeningFullInfoFragment
} from "./fragments";
import {createTargetRequestType, createTemplateRequestType, KycDictionaryType} from "../types";

export const getKycTemplates = async (workspaceId: string, pageInfo: PaginationInputAnyFilter): Promise<GetKycTemplatesPagingModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getKycTemplates($workspaceId:String!, $pageInfo:PaginationInputAnyFilter!){
                        getKycTemplates(workspaceId:$workspaceId, pageInfo:$pageInfo){
                            templates{...KycRefactorTemplateModelFragment}
                            pageInfo{
                                page
                                count
                                total
                                sort
                            }
                        }
                    }
                    ${KycRefactorTemplateModelFragment}
                `),
                variables: {
                    workspaceId,
                    pageInfo
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycTemplates;
    })
)


export const getKycTemplateConfig = async (workspaceId: string): Promise<GetTemplateConfigResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getKycTemplateConfig($workspaceId:String!){
                        getKycTemplateConfig(workspaceId:$workspaceId){
                            systemFields{
                                id
                                name
                                required
                                type
                                templateType
                                checkAlias{
                                    alias
                                    checkId
                                }
                                systemAlias
                                requiredForTemplate
                                dictionary
                            }
                            systemChecks{
                                id
                                name
                            }
                        }
                    }
                `),
                variables: {
                    workspaceId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycTemplateConfig;
    })
)


export const createKycTemplate = async (workspaceId: string, template: createTemplateRequestType): Promise<KycRefactorTemplateModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation createKycTemplate($workspaceId:String!, $template: KycRefactorTemplateModelInput!){
                        createKycTemplate(workspaceId:$workspaceId, template: $template){
                            ...KycRefactorTemplateModelFragment
                        }
                    }
                    ${KycRefactorTemplateModelFragment} 
                `),
                variables: {
                    workspaceId,
                    template
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createKycTemplate;
    })
)

export const uploadFile = async (data: UploadFileMutationVariables): Promise<UploadFileMutation["uploadFile"]["message"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation uploadFile($workspaceId:String!, $file:String!){
                        uploadFile(workspaceId:$workspaceId, file:$file){
                            message
                        }
                    }

                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.uploadFile.message;
    })
)


export const createTarget = async (workspaceId: string, data: createTargetRequestType): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation createTarget($workspaceId:String!, $data:CreateTargetDto!){
                        createTarget(workspaceId:$workspaceId, data:$data){
                            message
                        }
                    }

                `),
                variables: {data, workspaceId}
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createTarget.message;
    })
)

//startOneTimeScreening
export const startOneTimeScreening = async (data: StartOneTimeScreeningMutationVariables): Promise<StartOneTimeScreeningMutation["startOneTimeScreening"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation startOneTimeScreening($workspaceId:String!, $data:CreateTargetDto!, $matchingRatio:Float!){
                        startOneTimeScreening(workspaceId:$workspaceId, data:$data, matchingRatio: $matchingRatio){
                            startDate
                            endDate
                            status
                            targetName
                            targetType
                            templateName
                            targetStatus
                            checkId
                            checkName
                            matchingRatio
                            results{
                                entityId
                                score
                                entityCaption
                            }
                        }
                    }

                `),
                variables: {...data}
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.startOneTimeScreening;
    })
)


export const sendTemplateToClient = async (workspaceId: string, organizationId: string, templateId: string, emails: string[]): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation sendTemplateToClient($workspaceId:String!, $organizationId:String!, $templateId:String!, $emails:[String!]!){
                        sendTemplateToClient(workspaceId:$workspaceId, organizationId:$organizationId, templateId:$templateId, emails:$emails){
                            message
                        }
                    }

                `),
                variables: {workspaceId, organizationId, templateId, emails}
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.sendTemplateToClient.message;
    })
)

export const getKycRequests = async (workspaceId: string, pageInfo: PaginationInputAnyFilter): Promise<GetKycRequestsPagingModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getKycRequestsPaging($workspaceId:String!, $pageInfo:PaginationInputAnyFilter!){
                        getKycRequestsPaging(workspaceId:$workspaceId, pageInfo:$pageInfo){
                            requests{...KycRefactorRequestModelFragment}
                            pageInfo{
                                page
                                count
                                total
                                sort
                            }
                        }
                    }
                    ${KycRefactorRequestModelFragment}
                `),
                variables: {
                    workspaceId,
                    pageInfo
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycRequestsPaging;
    })
)

export const downloadEvidenceFileAPI = async (data: DownloadEvidenceFileQueryVariables): Promise<DownloadEvidenceFileQuery["downloadEvidenceFile"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query downloadEvidenceFile($fileId: String!, $workspaceId: String!, $fileName: String!) {
                        downloadEvidenceFile (fileId: $fileId, fileName: $fileName, workspaceId: $workspaceId) {
                            filename
                            filetype
                            file
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.downloadEvidenceFile as {file: string, filename: string, filetype: string};
    })
)

export const getKycRequestById = async (workspaceId: string, requestId: string): Promise<KycRefactorRequestModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getKycRequestById($workspaceId:String!, $requestId: String!){
                        getKycRequestById(workspaceId:$workspaceId, requestId:$requestId){
                            ...KycRefactorRequestModelFragment
                        }
                    }
                    ${KycRefactorRequestModelFragment}
                `),
                variables: {
                    workspaceId,
                    requestId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycRequestById;
    })
)

export const getKycTemplateById = async (workspaceId: string, templateId: string): Promise<KycRefactorTemplateModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getKycTemplateById($workspaceId:String!, $templateId: String!){
                        getKycTemplateById(workspaceId:$workspaceId, templateId:$templateId){
                            ...KycRefactorTemplateModelFragment
                        }
                    }
                    ${KycRefactorTemplateModelFragment}
                `),
                variables: {
                    workspaceId,
                    templateId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycTemplateById;
    })
)

export const updateKycTemplate = async (workspaceId: string, template: createTemplateRequestType): Promise<KycRefactorTemplateModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation updateKycTemplate($workspaceId:String!, $template: KycRefactorTemplateModelInput!){
                        updateKycTemplate(workspaceId:$workspaceId, template: $template){
                            ...KycRefactorTemplateModelFragment
                        }
                    }
                    ${KycRefactorTemplateModelFragment} 
                `),
                variables: {
                    workspaceId,
                    template
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateKycTemplate;
    })
)

export const cancelRequest = async (workspaceId: string, requestId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation cancelRequest($workspaceId:String!, $requestId: String!){
                        cancelRequest(workspaceId:$workspaceId,  requestId:  $requestId){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    requestId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.cancelRequest.message;
    })
)


export const getKycClientsWithFilterAndPaging = async (data: QueryGetKycClientsWithFilterAndPagingArgs, signal?: AbortSignal): Promise<GetKycTargetsPagingResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getKycClientsWithFilterAndPaging($workspaceId:String!, $pageInfo:PaginationInputAnyFilter!, $clientType:String!){
                        getKycClientsWithFilterAndPaging(workspaceId:$workspaceId, pageInfo:$pageInfo, clientType:$clientType){
                            clients{...KycClientFragment}
                            pageInfo{
                                page
                                count
                                total
                                sort
                            }
                        }
                    }
                    ${KycClientFragment}
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader(),
                // signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycClientsWithFilterAndPaging;
    })
);

export const getKycArchiveWithFilterAndPaging = async (data: GetKycArchiveWithFilterAndPagingQueryVariables, signal?: AbortSignal): Promise<GetKycArchiveTargetsPagingResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getKycArchiveWithFilterAndPaging($workspaceId:String!, $pageInfo:PaginationInputAnyFilter!){
                        getKycArchiveWithFilterAndPaging(workspaceId:$workspaceId, pageInfo:$pageInfo){
                            archive{
                                id
                                archivedDate
                                archivedReason
                                targetType
                                risk
                                fields {
                                    id
                                    name
                                    type
                                    required
                                    system
                                    order
                                    value
                                    requiredForTemplate
                                    systemAlias
                                    fileName
                                    checkAlias {
                                        checkId
                                        alias
                                    }
                                    dictionary
                                }
                            }
                            pageInfo{
                                page
                                count
                                total
                                sort
                            }
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader(),
                // signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycArchiveWithFilterAndPaging;
    })
);

export const getKycClientsFieldsByWorkspaceId = async (data: GetKycClientsFieldsByWorkspaceIdQueryVariables): Promise<GetKycTargetsFieldsResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getKycClientsFieldsByWorkspaceId($workspaceId:String!, $clientType:String!){
                        getKycClientsFieldsByWorkspaceId(workspaceId:$workspaceId, clientType:$clientType){
                            fields {...KycClientFieldFragment}
                        }
                    }
                    ${KycClientFieldFragment}
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycClientsFieldsByWorkspaceId;
    })
);

export const getKycAvailableFieldsForExport = async (data: GetKycAvailableFieldsForExportQueryVariables): Promise<GetKycAvailableFieldsForExportQuery["getKycAvailableFieldsForExport"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getKycAvailableFieldsForExport($workspaceId:String!, $templateId:String!){
                        getKycAvailableFieldsForExport(workspaceId:$workspaceId, templateId:$templateId){
                            fields {
                                fieldKey
                                fieldTitle
                            }
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycAvailableFieldsForExport;
    })
);

export const getKycAvailableSystemFieldsForExport = async (data: GetKycAvailableSystemFieldsForExportQueryVariables): Promise<GetKycAvailableSystemFieldsForExportQuery["getKycAvailableSystemFieldsForExport"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getKycAvailableSystemFieldsForExport($workspaceId:String!, $clientType:String!){
                        getKycAvailableSystemFieldsForExport(workspaceId:$workspaceId, clientType:$clientType){
                            fields {
                                fieldKey
                                fieldTitle
                            }
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycAvailableSystemFieldsForExport;
    })
);

export const updateKycTargetValues = async (workspaceId: string, data: KycRefactorUpdateTargetInput ): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation updateKycTargetValues($workspaceId:String!, $data:KycRefactorUpdateTargetInput!){
                        updateKycTargetValues(workspaceId:$workspaceId, data:$data){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    data
                }
            },
            {
                headers: buildCommonHeader(),
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateKycTargetValues.message;
    })
)

export const updateKycTargetRisk = async (data: UpdateKycTargetRiskMutationVariables ): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation updateKycTargetRisk($workspaceId:String!, $targetId:String!, $risk:String!){
                        updateKycTargetRisk(workspaceId:$workspaceId, targetId:$targetId, risk:$risk){
                            message
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader(),
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateKycTargetRisk.message;
    })
)

export const moveKycTargetToArchive = async (data: MutationMoveKycTargetToArchiveArgs ): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation moveKycTargetToArchive($workspaceId:String!, $targetId:String!, $description: String!){
                        moveKycTargetToArchive(workspaceId:$workspaceId, targetId:$targetId, description: $description){
                            message
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader(),
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.moveKycTargetToArchive.message;
    })
);

export const addKycTargetToBlackList = async (data: AddKycTargetToBlackListMutationVariables): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation addKycTargetToBlackList($workspaceId:String!, $targetId:String!, $description: String!){
                        addKycTargetToBlackList(workspaceId:$workspaceId, targetId:$targetId, description: $description){
                            message
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader(),
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addKycTargetToBlackList.message;
    })
);

export const setKycTargetVerified = async (data: SetKycTargetVerifiedMutationVariables): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation setKycTargetVerified($workspaceId:String!, $targetId:String!, $screeningId:String!){
                        setKycTargetVerified(workspaceId:$workspaceId, targetId:$targetId, screeningId:$screeningId){
                            message
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader(),
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.setKycTargetVerified.message;
    })
);

export const restoreKycTargetFromArchive = async (data: MutationRestoreKycTargetFromArchiveArgs): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation restoreKycTargetFromArchive($workspaceId:String!, $targetId:String!, $description: String!){
                        restoreKycTargetFromArchive(workspaceId:$workspaceId, targetId:$targetId, description: $description){
                            message
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader(),
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.restoreKycTargetFromArchive.message;
    })
)

export const restoreKycTargetFromBlackList = async (data: MutationRestoreKycTargetFromBlackListArgs): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation restoreKycTargetFromBlackList($workspaceId:String!, $targetId:String!, $description: String!){
                        restoreKycTargetFromBlackList(workspaceId:$workspaceId, targetId:$targetId, description: $description){
                            message
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader(),
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.restoreKycTargetFromBlackList.message;
    })
);

export const deleteKycArchivedTarget = async (data: DeleteKycArchivedTargetMutationVariables): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deleteKycArchivedTarget($workspaceId:String!, $targetId: String!){
                        deleteKycArchivedTarget(workspaceId:$workspaceId, targetId: $targetId){
                            message
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader(),
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteKycArchivedTarget.message;
    })
);

export const getKycScreeningsWithFilterAndPaging = async (data: QueryGetKycScreeningsWithFilterAndPagingArgs, signal?: AbortSignal): Promise<KycScreeningPagingResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getKycScreeningsWithFilterAndPaging($workspaceId:String!, $pageInfo:PaginationInputAnyFilter!){
                        getKycScreeningsWithFilterAndPaging(workspaceId:$workspaceId, pageInfo:$pageInfo){
                            screenings{...KycScreeningFragment}
                            pageInfo{
                                page
                                count
                                total
                                sort
                            }
                        }
                    }
                    ${KycScreeningFragment}
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader(),
                // signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycScreeningsWithFilterAndPaging;
    })
);

export const getKycScreeningById = async (data: GetKycScreeningByIdQueryVariables, signal?: AbortSignal): Promise<KycScreeningModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getKycScreeningById($workspaceId:String!, $screeningId: String!){
                        getKycScreeningById(workspaceId:$workspaceId, screeningId:$screeningId){
                            ...KycScreeningFragment
                        }
                    }
                    ${KycScreeningFragment}
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycScreeningById;
    })
);

export const getKycScreeningFullInfoById = async (data: GetKycScreeningFullInfoByIdQueryVariables, signal?: AbortSignal): Promise<KycScreeningFullInfoModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getKycScreeningFullInfoById($workspaceId:String!, $screeningId: String!){
                        getKycScreeningFullInfoById(workspaceId:$workspaceId, screeningId:$screeningId){
                            ...KycScreeningFullInfoFragment
                        }
                    }
                    ${KycScreeningFullInfoFragment}
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycScreeningFullInfoById;
    })
);


export const getKycTargetById = async (workspaceId: string, targetId: string, signal?: AbortSignal): Promise<TKycTargetModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getKycTargetById($workspaceId:String!, $targetId:String!){
                        getKycTargetById(workspaceId:$workspaceId, targetId:$targetId){
                            ...KycClientFragment
                        }
                    }
                    ${KycClientFragment}
                `),
                variables: {
                    workspaceId, targetId
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycTargetById;
    })
);

export const getKycExceptionsByTargetId = async (data: QueryGetKycTargetExceptionsArgs, signal?: AbortSignal): Promise<GetTargetExceptionResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getKycTargetExceptions($workspaceId:String!, $pageInfo:PaginationInputAnyFilter!, $targetId: String!){
                        getKycTargetExceptions(workspaceId:$workspaceId, pageInfo:$pageInfo, targetId: $targetId){
                            exceptions{...KycExceptionModelFragment}
                            pageInfo{
                                page
                                count
                                total
                                sort
                            }
                        }
                    }
                    ${KycExceptionModelFragment}
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycTargetExceptions;
    })
);

export const createKycTargetException = async (workspaceId: string, data: CreateTargetExceptionDto, signal?: AbortSignal): Promise<KycScreeningFullInfoModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation createKycTargetException($workspaceId:String!, $data: CreateTargetExceptionDto!){
                        createKycTargetException(workspaceId:$workspaceId, data:$data){
                            ...KycScreeningFullInfoFragment
                        }
                    }
                    ${KycScreeningFullInfoFragment}
                `),
                variables: {
                    workspaceId,
                    data
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createKycTargetException;
    })
);

export const deleteTargetException = async (workspaceId: string, targetId: string, exceptionId: string, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deleteTargetException($workspaceId:String!, $targetId: String!, $exceptionId: String!){
                        deleteTargetException(workspaceId:$workspaceId, targetId: $targetId, exceptionId: $exceptionId){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    targetId,
                    exceptionId
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteTargetException.message;
    })
);

export const startTargetScreening = async (workspaceId: string, targetId: string, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation startTargetScreening($workspaceId:String!, $targetId: String!){
                        startTargetScreening(workspaceId:$workspaceId, targetId: $targetId){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    targetId,
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.startTargetScreening.message;
    })
);


export const getTargetHistory = async (workspaceId: string, targetId: string, signal?: AbortSignal): Promise<GetTargetHistoryResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getTargetHistory($workspaceId:String!, $targetId: String!){
                        getTargetHistory(workspaceId:$workspaceId, targetId: $targetId){
                            historyLogs{
                                id
                                targetId
                                date
                                action
                                value
                            }
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    targetId,
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getTargetHistory;
    })
);

export const uploadKycCsvFile = async (workspaceId: string, file: string, templateId: string, fileName: string): Promise<UploadCsvFileResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation uploadKycCsvFile($workspaceId:String!, $file:String!, $templateId: String!, $fileName: String!){
                        uploadKycCsvFile(workspaceId:$workspaceId, file:$file, templateId: $templateId, fileName: $fileName){
                            status
                            fileId
                            fields{
                                templateField
                                csvField
                            }
                        }
                    }

                `),
                variables: {workspaceId, file, templateId, fileName}
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.uploadKycCsvFile;
    })
)

export const performCsvByFileId = async (workspaceId: string, fileId: string, config: KycCsvFieldWithAliasModel[]): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation performCsvByFileId($workspaceId:String!, $fileId:String!, $config: [KycCsvFieldWithAliasModel!]!) {
                        performCsvByFileId(workspaceId:$workspaceId, fileId:$fileId, config:$config){
                            message
                        }
                    }

                `),
                variables: {workspaceId, fileId, config}
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.performCsvByFileId.message;
    })
)

export const getKycMatches = async (workspaceId: string, pageInfo: PaginationInputAnyFilter): Promise<GetKycMatchesPagingModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getKycMatches($workspaceId:String!, $pageInfo:PaginationInputAnyFilter!){
                        getKycMatches(workspaceId:$workspaceId, pageInfo:$pageInfo){
                            matches{
                                id
                                targetId
                                targetName
                                templateId
                                templateName
                                templateType
                                screeningId
                                status
                                checkName
                                createdDate
                            }
                            pageInfo{
                                page
                                count
                                total
                                sort
                            }
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    pageInfo
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycMatches;
    })
)

export const getKycTemplateHistory = async (workspaceId: string, templateId: string, pageInfo: PaginationInputAnyFilter): Promise<GetKycTemplateHistoryPagingModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getKycTemplateHistory($templateId:String!, $workspaceId:String!, $pageInfo:PaginationInputAnyFilter!){
                      getKycTemplateHistory(templateId:$templateId, workspaceId:$workspaceId, pageInfo:$pageInfo){
                        histories{
                          id
                          fileId
                          fileName
                          status
                          createdBy
                          createdDate
                          createdByName
                          templateId
                          result
                        }
                        pageInfo{
                          page
                          count
                          total
                          sort
                        }
                      }
                    }
                `),
                variables: {
                    workspaceId,
                    pageInfo,
                    templateId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycTemplateHistory;
    })
)

export const exportCsvTargets = async (data: ExportCsvTargetsQueryVariables): Promise<IdMessage> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query exportCsvTargets($workspaceId:String!, $targetType:String!, $fieldKeys:[String!]!){
                        exportCsvTargets(workspaceId:$workspaceId, targetType:$targetType, fieldKeys:$fieldKeys){
                            id
                            message
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader(),
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.exportCsvTargets;
    })
)

export const getKycScreeningResults = async (workspaceId: string, screeningId: string, pageInfo: PaginationInputAnyFilter): Promise<KycScreeningResultPagingModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getKycScreeningResults($screeningId:String!, $workspaceId:String!, $pageInfo:PaginationInputAnyFilter!){
                      getKycScreeningResults(screeningId:$screeningId, workspaceId:$workspaceId, pageInfo:$pageInfo){
                        result{
                          entityId
                          score
                          entityCaption
                          matchedObject
                          exceptionId
                        }
                        pageInfo{
                          page
                          count
                          total
                          sort
                        }
                      }
                    }
                `),
                variables: {
                    workspaceId,
                    pageInfo,
                    screeningId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycScreeningResults;
    })
)

export const getKycOtsFullResultByEntityId = async (data: GetKycOtsFullResultByEntityIdQueryVariables): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getKycOtsFullResultByEntityId($entityId:String!, $workspaceId:String!){
                        getKycOtsFullResultByEntityId(entityId:$entityId, workspaceId:$workspaceId){
                            result
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycOtsFullResultByEntityId.result;
    })
)

export const getKycScreeningFullResultByEntityId = async (workspaceId: string, screeningId: string, entityId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getKycScreeningFullResultByEntityId($entityId:String!, $screeningId:String!, $workspaceId:String!){
                      getKycScreeningFullResultByEntityId(entityId:$entityId, screeningId:$screeningId, workspaceId:$workspaceId){
                        result
                      }
                    }
                `),
                variables: {
                    workspaceId,
                    screeningId,
                    entityId,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycScreeningFullResultByEntityId.result;
    })
)

//getKycDictionaries
export const getKycDictionaries = async (): Promise<KycDictionaryType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getKycDictionaries{
                      getKycDictionaries{
                        country{
                            key
                            value
                            order
                        }
                      }
                    }
                `),
                variables: {
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycDictionaries;
    })
)
export const autocompleteKycTemplatesByScreenings = async (data: AutocompleteKycTemplatesByScreeningsQueryVariables ): Promise<AutocompleteKycTemplatesByScreeningsQuery["autocompleteKycTemplatesByScreenings"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query autocompleteKycTemplatesByScreenings($workspaceId: String!, $search: String!, $page: Float!){
                        autocompleteKycTemplatesByScreenings(workspaceId: $workspaceId, search: $search, page: $page) {
                            templates {
                                id
                                name
                            }
                            pageInfo {
                                page
                                count
                                total
                                sort
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.autocompleteKycTemplatesByScreenings;
    })
);

export const autocompleteKycTemplatesByTargets = async (data: AutocompleteKycTemplatesByTargetsQueryVariables ): Promise<AutocompleteKycTemplatesByTargetsQuery["autocompleteKycTemplatesByTargets"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query autocompleteKycTemplatesByTargets($workspaceId: String!, $targetType: String!, $search: String!, $page: Float!){
                        autocompleteKycTemplatesByTargets(workspaceId: $workspaceId, targetType: $targetType, search: $search, page: $page) {
                            templates {
                                id
                                name
                            }
                            pageInfo {
                                page
                                count
                                total
                                sort
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.autocompleteKycTemplatesByTargets;
    })
);

export const autocompleteKycTemplatesByMatches = async (data: AutocompleteKycTemplatesByMatchesQueryVariables ): Promise<AutocompleteKycTemplatesByMatchesQuery["autocompleteKycTemplatesByMatches"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query autocompleteKycTemplatesByMatches($workspaceId: String!, $search: String!, $page: Float!){
                        autocompleteKycTemplatesByMatches(workspaceId: $workspaceId, search: $search, page: $page) {
                            templates {
                                id
                                name
                            }
                            pageInfo {
                                page
                                count
                                total
                                sort
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.autocompleteKycTemplatesByMatches;
    })
);




//getDeleteTemplateData
export const getDeleteTemplateData = async (workspaceId: string, templateId: string): Promise<{clientsCount: number; archivedClientsCount: number;}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getDeleteTemplateData($workspaceId: String!, $templateId: String!){
                      getDeleteTemplateData(workspaceId: $workspaceId, templateId: $templateId){
                        clientsCount
                        archivedClientsCount
                      }
                    }
                `),
                variables: {
                    workspaceId,
                    templateId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getDeleteTemplateData;
    })
);


export const exportClientsByTemplate = async (data: ExportClientsByTemplateQueryVariables): Promise<{id: string, message: string}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query exportClientsByTemplate($workspaceId: String!, $templateId: String!, $fieldKeys: [String!]!,  $isDownload: Boolean!){
                      exportClientsByTemplate(workspaceId: $workspaceId, templateId: $templateId, fieldKeys:$fieldKeys, isDownload: $isDownload){
                        message
                        id
                      }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.exportClientsByTemplate;
    })
)

export const deleteKycTemplate = async (workspaceId: string, templateId: string, isDeleteTargets: boolean): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deleteKycTemplate($workspaceId: String!, $templateId: String!, $isDeleteTargets: Boolean!){
                      deleteKycTemplate(workspaceId: $workspaceId, templateId: $templateId, isDeleteTargets: $isDeleteTargets){
                        message
                      }
                    }
                `),
                variables: {
                    workspaceId,
                    templateId,
                    isDeleteTargets
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteKycTemplate.message;
    })
)
