import {Flex} from "../../../../../newShared/components/Layouts";
import {QuestionShortDataModel} from "../../../../../newShared/GQLTypes";
import colors from "../../../../../newShared/theme/colors";
import {IconButton, Typography} from "@mui/material";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import {deselectExistingQuestion} from "../../store/slice";
import {useDispatch} from "react-redux";
import {questionTypeImageMapper} from "../../helpers";

export const QuestionToConfirmRow = ({question}: {question: QuestionShortDataModel}) => {
    const dispatch = useDispatch();
    const questionTitleImage = question.questionType ? questionTypeImageMapper(question.questionType) : {questionTitle: '-', questionImage: '-'};

    const handleDeselectQuestion = () => {
        dispatch(deselectExistingQuestion(question));
    }
    return (
        <Flex jc={'space-between'} ai={'center'} style={{padding: '10px 20px', borderRadius: '4px', backgroundColor: colors.backgrounds.grey_light, width: '100%'}}>
            <Typography variant={'*bodyText2'}>{question.questionName}</Typography>
            <Flex gap={'30px'} jc={'space-between'} ai={'center'}>
                <Flex ai={'center'} jc={'flex-start'} gap={'8px'} style={{padding: '0 80px 0 16px', height: '100%', width: '200px', borderLeft: `solid 1px ${colors.text.grey_light_2}`, borderRight: `solid 1px ${colors.text.grey_light_2}`}}>
                    {questionTitleImage?.questionImage ?? '-'}
                    {questionTitleImage?.questionTitle ?? '-'}
                </Flex>
                <IconButton sx={{cursor: 'pointer'}} size={'small'} onClick={handleDeselectQuestion}>
                    <ClearOutlinedIcon/>
                </IconButton>
            </Flex>

        </Flex>
    )
}