import React, {FC, useEffect, useState} from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideEditHrSettingDialog, hrSettings, loadings} from "../../../store/slice";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import {
    DEPARTMENT_ID,
    DIVISION_ID,
    EMPLOYMENT_TYPE_ID,
    HrSettingsNormalizedNames,
    LOCATION_ID
} from "../../../constants";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {UpdateHrEmployeeSetting} from "../../../store/actions";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {CommonRadio} from "../../../../../../newShared/components/Basic/CommonRadio";


export const EditSettingDialog: FC = () => {

    const {t} = useMainTranslation('', {keyPrefix: 'pathHRManagement.dialogs'});
    const {tCommon} = useMainTranslation();

    const dispatch = useDispatch();
    const settings = useSelector(hrSettings);
    const {isOpen, field, value} = useSelector(dialogs).editHRSetting;
    const isLoading = useSelector(loadings).updateSetting;

    const [newValue, setNewValue] = useState<string>(value || '');
    const [updateHistory, setUpdateHistory] = useState<boolean>(false);



    const handleClose = () => {
        dispatch(hideEditHrSettingDialog());
        setNewValue('');
    }

    const handleSubmit = () => {
        field && value && dispatch(UpdateHrEmployeeSetting({data: {field, value, newValue, updateHistory}}));
    }

    const isAlreadyExists = (): boolean => {
        switch (field){
            case 'department': return Object.keys(settings.department).filter(e => e !== value).some(e => e.toLowerCase() === newValue.toLowerCase().trim());
            case 'location': return Object.keys(settings.location).filter(e => e !== value).some(e => e.toLowerCase() === newValue.toLowerCase().trim());
            case 'division': return Object.keys(settings.division).filter(e => e !== value).some(e => e.toLowerCase() === newValue.toLowerCase().trim());
            case 'degree': return Object.keys(settings.degree).filter(e => e !== value).some(e => e.toLowerCase() === newValue.toLowerCase().trim());
            case 'relationshipType': return Object.keys(settings.relationshipType).filter(e => e !== value).some(e => e.toLowerCase() === newValue.toLowerCase().trim());
            case 'employmentType': return Object.keys(settings.employmentType).filter(e => e !== value).some(e => e.toLowerCase() === newValue.toLowerCase().trim());
            case 'customFields': return Object.keys(settings.customFields).filter(e => e !== value).some(e => e.toLowerCase() === newValue.toLowerCase().trim());
            default: return false;
        }
    }

    const isExist: boolean = isAlreadyExists();

    const isOk = () => {
        return value !== newValue.trim()
            && newValue.trim().length > 0
            && !isExist;
    }


    useEffect(() => {
        if (isOpen) value && setNewValue(value) ;
        setUpdateHistory(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);


    return (
        <Dialog open={isOpen} onClose={handleClose} sx={{'& .MuiPaper-root': {width: '600px'}}}>
            <DialogTitle>{`${tCommon('Edit')} ${field ? HrSettingsNormalizedNames[field] : ''}`}</DialogTitle>
            <DialogContent>
                <TextField
                    value={newValue}
                    disabled={isLoading}
                    // autoFocus
                    onChange={(e) => setNewValue(e.target.value)}
                    label={t('Name')}
                    sx={{mt: '8px'}}
                    required
                    size={'small'}
                    error={isExist}
                    helperText={isExist ? t(`Already exists`) : undefined}
                />

                {(field === EMPLOYMENT_TYPE_ID || field === LOCATION_ID || field ===  DEPARTMENT_ID || field === DIVISION_ID) &&
                    <>
                        <Typography variant={'body2'}
                                    sx={{marginTop: '16px'}}>{t('How should this change occur?')}</Typography>
                        <Flex ai={"center"} w={'100%'} m={'16px 0 0 0'}>
                            <CommonRadio size={"small"} checked={!updateHistory} sx={{marginRight: '9px'}}
                                         onClick={() => {setUpdateHistory(false)}}/>
                            <Typography variant={'body1'}>{t('Change anywhere it is used, without history')}</Typography>
                        </Flex>
                        <Flex ai={"center"} w={'100%'}>
                            <CommonRadio size={"small"} checked={updateHistory} sx={{marginRight: '9px'}}
                                         onClick={() => {setUpdateHistory(true)}}/>
                            <Typography variant={'body1'}>{t('Change anywhere it is used, including history')}</Typography>
                        </Flex>
                    </>
                }

            </DialogContent>

            <DialogActions sx={{pt: '16px'}}>
                <Button onClick={handleClose} variant={'text'} sx={{textTransform: 'none'}}>{tCommon('Close')}</Button>
                <LoadingButton
                    onClick={handleSubmit}
                    disabled={isLoading || !isOk()}
                    loading={isLoading}
                >
                    {tCommon('Confirm')}
                </LoadingButton>
            </DialogActions>

        </Dialog>
    )
}