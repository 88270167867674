import {TypographyBody2} from "../../../../../../newShared/components/Inputs/styled";
import colors from "../../../../../../newShared/theme/colors";
import {styled} from "@mui/material";

export const ValueGetterTypography = styled(TypographyBody2,  {
    shouldForwardProp: (propName) => (
        !["isDisabled", "revDir", "color"].includes(propName.toString())
    )}
)(({isDisabled, revDir, color}:{isDisabled?: true, revDir?: true, color?: string}) => ({
    color: isDisabled ? colors.text.grey : color ?? colors.grayText,
    marginLeft: !revDir ? '8px' : undefined,
    marginRight: revDir ? '8px' : undefined,
}));

ValueGetterTypography.defaultProps = {
    noWrap: true,
};

export const RiskCircle = styled('div', {
    shouldForwardProp: (propName) => (
        !["color", "revDir"].includes(propName.toString())
    )})(({color, revDir}: {color: string, revDir?: true}) => ({
    backgroundColor: color,
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    marginRight: !revDir ? '8px' : undefined,
    marginLeft: revDir ? '8px' : undefined,
}))
