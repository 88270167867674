import {FC} from "react";
import {Box, Button, Card, Typography} from "@mui/material";
import {TFramework} from "../../types";
import colors from "../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useFrameworks} from "../../hooks/useFrameworks";
import {TypographyNew} from "../../../../../newShared/components/TypographyNew";

type TProps = {
    framework: TFramework
}
export const FrameworkAdoptCard: FC<TProps> = ({framework}) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathFrameworks.FrameworkCard'});

    const {openFramework, generateReport} = useFrameworks();

    return (
        <Card sx={{
            padding: '8px', height: '100%',
            display: 'flex', flexDirection: 'column',
        }}>
            <Box display={"flex"} alignItems={"center"}>
                {framework.logo && <img src={framework.logo} alt={framework.name} style={{maxHeight: '40px', maxWidth: '40px'}}/>}
                <Typography variant={"h4"} margin={'0 4px'}>{framework.name}</Typography>
            </Box>

            <Box width={'100%'} height={'38px'} overflow={"hidden"} mt={'8px'}>
                <TypographyNew variant={"body2"} fontSize={'12px'} color={colors.text.grey_dark}
                            sx={{
                                overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', maxHeight: '100%',
                                '-webkit-box-orient': 'vertical', "-webkit-line-clamp": '2', display: '-webkit-box',
                                // whiteSpace: 'nowrap',
                            }}>
                    {framework.description}
                </TypographyNew>
            </Box>

            <Box width={'100%'} flexGrow={1}/>

            <Typography variant={"body1"} color={colors.text.grey_dark} mt={'8px'}>
                {`${t('Created by')} ${framework.createdBy}`}
            </Typography>
            <Typography variant={"body1"} color={colors.text.grey_dark} mt={'8px'}>
                {t('Type')}
                <Typography variant={"body1"} color={colors.text.dark} m={'0 8px'} component={"span"}>{framework.type}</Typography>
            </Typography>
            <Typography variant={"body1"} color={colors.text.grey_dark} mt={'8px'}>
                {t('Controls')}
                <Typography variant={"body1"} color={colors.text.dark} m={'0 8px'} component={"span"}>{framework.controls}</Typography>
            </Typography>
            <Typography variant={"body1"} color={colors.text.grey_dark} mt={'8px'} mb={'16px'}>
                {t('Evidences')}
                <Typography variant={"body1"} color={colors.text.dark} m={'0 8px'} component={"span"}>{`${framework.evidencesFinished.length}/${framework.evidences}`}</Typography>
            </Typography>

            <Box display={"flex"} justifyContent={"flex-end"}>
                <Button variant={"outlined"} sx={{margin: '0 16px'}} onClick={() => {openFramework(framework.id)}}>
                    {t('Open')}
                </Button>
                <Button onClick={() => {generateReport(framework.id)}}>
                    {t('Generate report')}
                </Button>
            </Box>
        </Card>
    )
}