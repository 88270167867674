import {Flex} from "../../../../../newShared/components/Layouts";
import {useSettingsJobTitle} from "../../hooks/useSettingsJobTitle";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import React from "react";
import AddIcon from '@mui/icons-material/Add';
import {IconButton, ListItemIcon, Menu, MenuItem, Skeleton, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {THrSettingsJobTitle} from "../../types";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useJobTitleRow} from "../../hooks/useJobTitleRow";

import PreviewIcon from '@mui/icons-material/Preview';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {EmptyArrayImage} from "../../../../../newShared/components/EmptyArrayImage";
import {PreviewJobTitleDialog} from "../../components/dialogs/jobTitleDialogs/previewJobTitleDialog";
import {EditJobTitleDialog} from "../../components/dialogs/jobTitleDialogs/editJobTitleDialog";
import {DeleteJobTitleDialog} from "../../components/dialogs/jobTitleDialogs/deleteJobTitleDialog";
import {AddSettingsDialog} from "../../components/dialogs/addSettingsDialog";
import {GenericFiltersArray} from "../../../../../newShared/components/genericFilter";
import {FixedSizeList as List} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import Divider from "@mui/material/Divider";

export const SettingsJobTitle = () => {
    const {isLoading, handleOpenAddJobTitle, isNoJobTitles, handleSearch, searchResult} = useSettingsJobTitle();
    const {t} = useMainTranslation('', {keyPrefix: 'pathHRManagement.settings'});


    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('Job title')}
                actions={[
                    {
                        variant: 'text',
                        title: t('Add job title'),
                        onClick: handleOpenAddJobTitle,
                        disabled: isLoading,
                        icon: <AddIcon />
                    }
                ]}
                margin={'0 0 16px 0'}
            />

            <GenericFiltersArray
                isSearchDisabled={isLoading}
                isAddFilters={false}
                configs={[]}
                fetchResultWithSelectedFilters={(search) => handleSearch(search)}
            />

            {isNoJobTitles ?
                <EmptyArrayImage
                    type={'hrManagement'}
                    onClick={handleOpenAddJobTitle}
                    text={t('No job titles')}
                    secondaryText={t('Create new one')}
                    isShowing={true}
                />
                :
                <Flex w={'100%'} h={'100%'} m={'16px 0'} direction={'column'} border={`solid 1px ${colors.backgrounds.grey_dark}`} br={'8px'} overflow={"hidden"}>

                    <Flex direction={"column"}>
                        <Flex w={'100%'} background={colors.backgrounds.grey_light} p={'6px 16px'} br={'8px 8px 0 0'}>
                            <Typography variant={'subtitle1'} color={colors.text.grey}>{`${t('Total job titles')}: ${isLoading ? 'loading' : searchResult.length}`}</Typography>
                        </Flex>

                        <Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable}}/>

                        <Flex w={'100%'} jc={'space-between'} ai={'center'} background={colors.backgrounds.grey_light}>
                            <Flex w={'60%'} background={colors.backgrounds.grey_light} p={'16px'}>
                                <Typography variant={'body2'} color={colors.grayText}>{t('Title')}</Typography>
                            </Flex>

                            <Flex w={'20%'} background={colors.backgrounds.grey_light} p={'16px'}>
                                <Typography variant={'body2'} color={colors.grayText}>{t('Employees')}</Typography>
                            </Flex>

                            <Flex w={'20%'} background={colors.backgrounds.grey_light} p={'16px'}>
                                <Typography variant={'body2'} color={colors.grayText}>{t('Actions')}</Typography>
                            </Flex>
                        </Flex>
                    </Flex>

                    {isLoading && <JobTitleRowSkeleton />}

                    <AutoSizer>
                        {({height, width}: {height: number, width: number}) => (
                            <List
                                height={height - 86 ?? 0} //not all items were displayed - so added compensation of 86px to fit all elements
                                itemCount={searchResult.length}
                                itemSize={66}
                                width={width ?? 0}
                            >
                                {({index, ...props} ) => <JobTitleRow jobTitle={searchResult[index]} key={searchResult[index].id} {...props} />}
                            </List>
                        )}
                    </AutoSizer>
                </Flex>
            }


            <PreviewJobTitleDialog />
            <EditJobTitleDialog />
            <DeleteJobTitleDialog />
            <AddSettingsDialog onSuccess={() => null}/>
        </Flex>
    )
}

export const JobTitleRow = ({jobTitle, style}: { jobTitle: THrSettingsJobTitle, style: any }) => {
    const {handleDelete, handleEdit, handlePreview, menu} = useJobTitleRow({jobTitle});
    const {t} = useMainTranslation('', {keyPrefix: 'pathHRManagement.settings'});

    return(
        <Flex direction={'column'} style={style}>
            <Flex w={'100%'} jc={'space-between'} ai={'center'}>
                <Flex w={'60%'} p={'16px'}>
                    <Typography variant={'subtitle1'} color={colors.grayText} noWrap sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{jobTitle.name}</Typography>
                </Flex>

                <Flex w={'20%'} p={'16px'}>
                    <Typography variant={'subtitle1'} color={colors.grayText}>{jobTitle.count}</Typography>
                </Flex>


                <Flex w={'20%'} p={'16px'}>
                    <IconButton
                        size={'small'}
                        // sx={{margin: !revDir ? '0 0 0 16px' : '0 16px 0 0'}}
                        aria-label="more"
                        id="long-button"
                        aria-controls={menu.open ? 'long-menu' : undefined}
                        aria-expanded={menu.open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {
                            if(menu.open){
                                menu.handleClose()
                            }else{
                                e.stopPropagation()
                                menu.handleClick(e)
                            }
                        }}
                        color={"primary"}
                    >
                        {<MoreHorizIcon />}
                    </IconButton>

                    <Menu
                        id="long-menu"
                        MenuListProps={{'aria-labelledby': 'long-button',}}
                        anchorEl={menu.anchorEl}
                        open={menu.open}
                        onClose={menu.handleClose}
                        onBackdropClick={(e) => {
                            e.stopPropagation()
                            menu.handleClose();
                        }}
                        PaperProps={{
                            style: {
                                boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)',
                                borderRadius: '8px',
                                border: '1px solid #F2F4F7'
                            },
                        }}
                        sx={{
                            '& .MuiMenu-list': {
                                padding: '16px'
                            },
                            '& .MuiMenuItem-root': {
                                margin: '0 auto',
                                borderRadius: '4px',
                                '&:hover': {
                                    backgroundColor: '#F2F4F7'
                                }
                            }
                        }}
                    >
                        <MenuItem onClick={handlePreview}>
                            <ListItemIcon><PreviewIcon /></ListItemIcon>
                            <Typography variant={'body2'} color={colors.grayText}>{t('Preview')}</Typography>
                        </MenuItem>

                        <MenuItem onClick={handleEdit}>
                            <ListItemIcon><EditIcon /></ListItemIcon>
                            <Typography variant={'body2'} color={colors.grayText}>{t('Edit')}</Typography>
                        </MenuItem>

                        <MenuItem onClick={handleDelete} color={'error'}>
                            <ListItemIcon><DeleteIcon style={{color: colors.text.critical}}/></ListItemIcon>
                            <Typography variant={'body2'} color={colors.text.critical}>{t('Delete')}</Typography>
                        </MenuItem>
                    </Menu>
                </Flex>
            </Flex>
            <Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable}}/>
        </Flex>
    )
}


export const JobTitleRowSkeleton = () => {
    return(
        <Flex direction={'column'}>
            <Flex w={'100%'} jc={'space-between'} ai={'center'}>
                <Flex w={'60%'} p={'16px'}>
                    <Typography variant={'subtitle1'} color={colors.grayText} noWrap sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        <Skeleton width={50}/>
                    </Typography>
                </Flex>

                <Flex w={'20%'} p={'16px'}>
                    <Typography variant={'subtitle1'} color={colors.grayText}>
                        <Skeleton width={50}/>
                    </Typography>
                </Flex>


                <Flex w={'20%'} p={'16px'}>
                    <IconButton
                        size={'small'}
                        disabled
                        aria-label="more"
                        id="long-button"
                        aria-haspopup="true"
                        color={"primary"}
                    >
                        {<MoreHorizIcon />}
                    </IconButton>
                </Flex>
            </Flex>
            <Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable}}/>
        </Flex>
    )
}