import {controlType, evidenceType, frameworkType} from "../types";


export const getControlStatus = (control: controlType, organizationId: string | null): string => {
    if(!organizationId) return 'Created by another user';
    if(control.origin) return 'Created by {{companyName}}'; //will be translated outside
    if(!control.origin){
        if(control.organizationId === organizationId){
            if(control.visibility === 'PRIVATE') return 'Private';
            if(control.visibility === 'PUBLIC') return 'Public';
        }else{
            return 'Created by another user'
        }
    }
    return '';
}

export const getEvidenceStatus = (evidence: evidenceType, organizationId: string | null): string => {
    if(!organizationId) return 'Created by another user';
    if(evidence.origin) return 'Created by {{companyName}}'; //will be translated outside
    if(!evidence.origin){
        if(evidence.organizationId === organizationId){
            if(evidence.visibility === 'PRIVATE') return 'Private';
            if(evidence.visibility === 'PUBLIC') return 'Public';
        }else{
            return 'Created by another user'
        }
    }
    return '';
}

export const recalculateFrameworkWhenControlLinkedOrUnlinked = (framework: frameworkType | undefined, controls: controlType[], type: 'LINK' | 'UNLINK',  addedControl?: controlType, deletedControlId?: string): frameworkType => {
    if(!framework) throw new Error('recalculateFrameworkWhenControlLinked - Framework not found!');
    const evidencesArr: evidenceType[] = [];
    let currControls = [...controls];

    if(type === "LINK" && addedControl){
        currControls.push(addedControl);
    }else if(type === "UNLINK" && deletedControlId){
        currControls = currControls.filter(e => e.id !== deletedControlId);
    }

    currControls.forEach((c) => {
        c.evidences?.forEach((ev) => {
            if(!evidencesArr.some((evA) => evA.id === ev.id)) evidencesArr.push(ev);
        })
    })
    // console.log(evidencesArr);
    return {...framework, controls: currControls.length, evidences: evidencesArr.length};
}

export const recalculateFrameworkWhenEvidenceLinkedOrUnlinked = (controls: controlType[], type: 'LINK' | 'UNLINK', controlIdAction: string, framework?: frameworkType, addedEvidence?: evidenceType, deletedEvidence?: string): frameworkType => {
    if(!framework) throw new Error('recalculateFrameworkWhenControlLinked - Framework not found!');
    const evidencesArr: evidenceType[] = [];
    let currControls = [...controls];

    if(type === "LINK" && addedEvidence){
        currControls = currControls.map(e => e.id === controlIdAction ? {...e, evidences: [...(e.evidences || []), addedEvidence]} : e);
    }else if(type === 'UNLINK' && deletedEvidence){
        currControls = currControls.map(e => e.id === controlIdAction ? {...e, evidences: [...(e.evidences?.filter(ev => ev.id !== deletedEvidence) || [])]} : e);
    }

    currControls.forEach((c) => {
        c.evidences?.forEach((ev) =>  {
            if(!evidencesArr.some((evA) => evA.id === ev.id)) evidencesArr.push(ev);
        })
    })

    return {...framework, controls: controls.length, evidences: evidencesArr.length};
}
