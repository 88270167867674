import {Box, styled, TableCell} from "@mui/material";


export const TemplateNameTableCell = styled(TableCell)(() => ({
    width: '500px',
}));
export const TemplateTagsTableCell = styled(TableCell)(() => ({
    width: '500px',
}));

export const TemplateActionTableCell = styled(TableCell)(() => ({
    width: '140px',
}));

export const TemplatesTagsFlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    flexWrap: 'nowrap',
    maxWidth: '500px',
    overflow: 'hidden'
}));
