import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../newShared/redux/rootReducer";
import {changeCurrentUserInfoAction, changeCurrentUserPassAction, getCurrentUserInfoAction} from "./actions";
import {sendEmailToResetPassAction, sendPassToResetPassAction} from "../signIn/store/actions";

type TSlice = {
    info: {
        firstName: string,
        lastName: string,
        email: string,
        publicId: string | null
    }

    message?: string,
    isLoading: boolean,
    isError: string | false
    isResetMailSanded: boolean
}

const initialState: TSlice = {
    info: {
        firstName: '',
        lastName: '',
        email: '',
        publicId: null
    },

    isLoading: false,
    isError: false,
    isResetMailSanded: false,
}

const slice = createSlice({
    name: 'SettingsProfile',
    initialState,
    reducers: {
        cleanDataAction () {
            return initialState;
        },
        cleanMessageAction (slice: TSlice) {
            slice.message = undefined;
        },
        cleanUpSettingsProfile(){ return initialState},
    },
    extraReducers: (builder => {
        builder
            .addCase(getCurrentUserInfoAction.pending, (slice: TSlice) => {
                slice.isLoading = true;
                slice.isError = false;
                slice.message = undefined
            })
            .addCase(getCurrentUserInfoAction.fulfilled, (slice: TSlice, {payload}) => {
                slice.isLoading = false;
                slice.info = payload
            })
            .addCase(getCurrentUserInfoAction.rejected, (slice: TSlice) => {
                slice.isLoading = false;
                slice.isError = 'ERROR';
            })

            .addCase(changeCurrentUserInfoAction.pending, (slice: TSlice) => {
                slice.isLoading = true;
                slice.isError = false;
                slice.message = undefined
            })
            .addCase(changeCurrentUserInfoAction.fulfilled, (slice: TSlice, {payload}) => {
                slice.isLoading = false;
                slice.message = payload.message
            })
            .addCase(changeCurrentUserInfoAction.rejected, (slice: TSlice) => {
                slice.isLoading = false;
                slice.isError = 'ERROR';
            })

            .addCase(changeCurrentUserPassAction.pending, (slice: TSlice) => {
                slice.isLoading = true;
                slice.isError = false;
                slice.message = undefined
            })
            .addCase(changeCurrentUserPassAction.fulfilled, (slice: TSlice, {payload}) => {
                slice.isLoading = false;
                slice.message = payload.message
            })
            .addCase(changeCurrentUserPassAction.rejected, (slice: TSlice) => {
                slice.isLoading = false;
                slice.isError = 'ERROR';
            })

            .addCase(sendEmailToResetPassAction.pending, (slice: TSlice) => {
                slice.isLoading = true;
                slice.isError = false;
                slice.message = undefined
            })
            .addCase(sendEmailToResetPassAction.fulfilled, (slice: TSlice, {payload}) => {
                slice.isLoading = false;
                slice.isResetMailSanded = true;
            })
            .addCase(sendEmailToResetPassAction.rejected, (slice: TSlice) => {
                slice.isLoading = false;
                slice.isError = 'ERROR';
            })

            .addCase(sendPassToResetPassAction.pending, (slice: TSlice) => {
                slice.isLoading = true;
                slice.isError = false;
                slice.message = undefined
            })
            .addCase(sendPassToResetPassAction.fulfilled, (slice: TSlice, {payload}) => {
                slice.isLoading = false;
                slice.message = payload.message
            })
            .addCase(sendPassToResetPassAction.rejected, (slice: TSlice) => {
                slice.isLoading = false;
                slice.isError = 'ERROR';
            })
    })
})

export const settingsProfileReducer = slice.reducer;
export const {cleanDataAction, cleanMessageAction, cleanUpSettingsProfile} = slice.actions;

export const selectSelf = (state: AppState): TSlice => state.settingsProfile;
export const isLoadingSelector = createSelector(selectSelf, state => state.isLoading);
export const isErrorSelector = createSelector(selectSelf, state => state.isError);
export const isResetMailSandedSelector = createSelector(selectSelf, state => state.isResetMailSanded);
export const messageSelector = createSelector(selectSelf, state => state.message);

export const profileUserInfoSelector = createSelector(selectSelf, state => state.info);
