import {createRoleDialogPropsTypes} from "../../../types";
import {useAddRoleDialog} from "../../../hooks/dialogHooks/useAddRoleDialog";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import React from "react";
import {DialogContentWrapper} from "../../../../newIntegrations/components/styled";
import {TextFieldCustom} from "../../styled";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const AddRoleDialog = (props: createRoleDialogPropsTypes) => {
    const {
        isOpen,
        handleClose,
        handleSubmit,
        handleChange,
        form,
        isOk,
        isAlreadyExists
    } = useAddRoleDialog(props);
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.CreateRow'});

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Add role')}</DialogTitle>
            <DialogContent>
                <DialogContentWrapper>
                    <TextFieldCustom
                        required
                        value={form.roleName}
                        name={'roleName'}
                        onChange={handleChange}
                        placeholder={('Name')}
                        label={t('Role name')}
                        size={'small'}
                        sx={{margin: '8px 0 0 0'}}
                        error={isAlreadyExists}
                        helperText={isAlreadyExists && t('Role with such name already exists.')}
                    />

                    <TextField
                        label={t('Role permissions list separated with comma')}
                        value={form.rolePermissions}
                        name={'rolePermissions'}
                        onChange={handleChange}
                        multiline
                        minRows={5}
                        sx={{margin: '8px 0 0 0'}}
                    />
                </DialogContentWrapper>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={'outlined'}>{t('Cancel')}</Button>
                <Button
                    onClick={handleSubmit}
                    disabled={!isOk}
                    variant={'contained'}
                    sx={{margin: !revDir ? '0 0 0 16px' : '0 16px 0 0'}}
                >{t('Save')}</Button>
            </DialogActions>
        </Dialog>
    )
}