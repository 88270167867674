import * as Yup from "yup";
import {MainTrainingAssignmentPostExamAnswerStatusForValidation} from "../../../../../../newShared/GQLTypes";

export const useValidationSchema = () => {

    const questionSchema = Yup.object({
        validation: Yup.object({
            validationStatus: Yup.string()
                .required()
                .oneOf(Object.values(MainTrainingAssignmentPostExamAnswerStatusForValidation)),
            validationComment: Yup.object({
                points: Yup.number(),
                correctAnswer: Yup.array().of(Yup.string()),
            }),
        }),
    })

    return {
        questionSchema,
        questionsSchema: Yup.object({
            questions: Yup.array().of(questionSchema)
        }),
    };
}