import {AddFilterButton} from "../../../../../newShared/components/genericFilter/components/styled";
import colors from "../../../../../newShared/theme/colors";
import {Flex} from "../../../../../newShared/components/Layouts";
import {IconButton, Typography} from "@mui/material";
import React from "react";
import AddIcon from '@mui/icons-material/Add';

export const AddCustomFileFieldComponent = ({onClick, title}: {onClick: () => void, title: string}) => {
    return(
        <AddFilterButton
            onClick={onClick}
            css={`
                background-color: ${colors.backgrounds.grey_light};
                color: ${colors.grayText};
                cursor:pointer;
                & button svg{
                  fill: ${colors.grayText};
                }
                &:hover{
                      background-color: ${colors.chip.hover}; 
                      & p, & button svg{
                        fill: ${colors.grayText} !important;
                        color: ${colors.grayText} !important;
                      }
                }
            `}
        >
            <>
                <Flex>
                    <Typography variant={'body2'} noWrap>{title}</Typography>
                </Flex>
                <IconButton size={'small'} sx={{padding: '0'}}>
                    <AddIcon />
                </IconButton>
            </>
        </AddFilterButton>
    )
}