// import {useEffect} from "react";
import {EmployeeType, TEmployeesInitialState} from "../../types";
import {useDispatch, useSelector} from "react-redux";
import {
    employeesTree,
    loadings,
    openDeleteEmployeeDialog,
    openEditEmployeesTreeNode,
    selectedEmployee,
    setTreeAction,
} from "../../store/slice";
import {getEmployeesTreeAction, updateEmployeesTreeAction} from "../../store/actions";
import {useHistory} from "react-router-dom";
import {PATH_LOCAL_EMPLOYEES_ID} from "../../constants";
import {UpdateEmployeesTreeDto} from "../../../../../newShared/GQLTypes";
import {Node, SetCenter} from "reactflow";
import {employeeTreeNodeHeight, employeeTreeNodeWidth} from "../../helpers";
import {addErrorSnack} from "../../../../barsEnvironment/snack/store/slice";


type TResponse = {
    selected: EmployeeType | null,
    isLoading: TEmployeesInitialState["loadings"],
    employees: TEmployeesInitialState["tree"]["employees"],
    deleted: TEmployeesInitialState["tree"]["deleted"],
    direction: TEmployeesInitialState["tree"]["direction"],
    isEditMode: TEmployeesInitialState["tree"]["isEditMode"],
    fullView: TEmployeesInitialState["tree"]["fullView"],

    getTree: (signal?: AbortSignal) => void;
    updateTree: (employees: UpdateEmployeesTreeDto[], deleted: string[]) => void;
    goToId: (id: string) => void;
    handleDeleteEmployee: (id: string, name: string) => void;
    handleEditEmployee: (id: string) => void;

    setTree: (data: Partial<TEmployeesInitialState["tree"] & {clear?: boolean}>) => void;

    focusNode: (nodes: Node[], id: string, setCenter: SetCenter, other?: {x: number, y: number, zoom: number}) => void;
    cycleDetectedError: () => void;
}

export const useEmployeesTree = (): TResponse => {
    const history = useHistory();
    const dispatch = useDispatch();

    const isLoading = useSelector(loadings);
    const selected = useSelector(selectedEmployee);
    const {employees, deleted, isEditMode, fullView, direction} = useSelector(employeesTree);

    const controller = new AbortController();

    const goToId = (id: string) => {
        history.push(`${PATH_LOCAL_EMPLOYEES_ID.replace(':id', id)}`);
    };

    const getTree: TResponse["getTree"] = (signal) => {dispatch(getEmployeesTreeAction({data: {}, signal}))};
    const updateTree: TResponse["updateTree"] = (employees, deleted: string[]) => {dispatch(updateEmployeesTreeAction(
        {data: {employees, deleted}, signal: controller.signal}
    ))};

    const setTree: TResponse["setTree"] = (data) => {
        dispatch(setTreeAction(data))
    }

    const handleDeleteEmployee = (id: string, name: string) => {
        dispatch(openDeleteEmployeeDialog({id, name}));
    }

    // const handleGoBack = () => {
    //     history.push(PATH_LOCAL_EMPLOYEES_ALL);
    // }

    //GetEmployeeById
    const handleEditEmployee = (id: string) => {
        dispatch(openEditEmployeesTreeNode({id}));
        // dispatch(GetHrEmployeeById({data: {id, onReject: handleGoBack}, signal: controller.signal}));
    };

    // useEffect(() => {
    //
    //     return () => {
    //         dispatch(setTreeAction({clear: true}))
    //         controller.abort();
    //     }
    //     //eslint-disable-next-line
    // }, []);

    const focusNode: TResponse['focusNode'] = (nodes, id, setCenter, {x: xx, y: yy, zoom} = {x: 2, y: 2, zoom: 1.65}) => {
        if (nodes.length > 0) {
            const node = nodes.find(e => e.id === id);
            if (node) {
                const x = node.position.x + (node.width ?? employeeTreeNodeWidth) / xx;
                const y = node.position.y + (node.height ?? employeeTreeNodeHeight) / yy;

                setCenter(x, y, { zoom, duration: 1000 });
            }
        }
    };

    const cycleDetectedError = () => {
        dispatch(addErrorSnack('Employees Cycle Detected'))
    }

    return {
        selected,
        isLoading,
        employees,
        deleted,
        direction,
        isEditMode,
        fullView,

        getTree,
        setTree,
        updateTree,
        goToId,

        handleDeleteEmployee,
        handleEditEmployee,

        focusNode,
        cycleDetectedError
    }
}
