import axiosAsync from "../../../../newShared/utils/axios/axios";
import {AxiosResponse} from "axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {buildCommonHeader} from "../../../../newShared/utils/builders";

export const getToken = async (): Promise<string> => {
    return axiosAsync.then((axios) => axios.post(PATH_SERVER, {
            query: `
                query getToken{
                  getToken{
                    message
                  }
                }
                `,
            variables: {
            }
        }, {
            headers: buildCommonHeader()
        })
    ).then((result: AxiosResponse<any>) => {
        return result?.data?.data?.getToken.message;
    })
}