import {MouseEvent, MouseEventHandler, useState} from "react";
import {TFontStyle, TToolBarHandlerAction, TToolBarHandlerPayload} from "../../../types";

export interface UseStylesControlProps {
    initialStyle?: TFontStyle | null; // 'Normal text' | 'Heading 1' | 'Heading 2' | 'Heading 3' | 'Heading 4' | 'Heading 5',
    anchorEl: null | HTMLElement,
    changeStyleHandler: (action: TToolBarHandlerAction, payload: TToolBarHandlerPayload) => void;
}

export interface UseStylesControlValue {
    open: boolean,
    // buttonTitle: string,
    anchorEl: null | HTMLElement,
    handleClose: (item?: TFontStyle) => (e: MouseEvent<HTMLLIElement> | KeyboardEvent) => void,
    handleClick: MouseEventHandler<HTMLButtonElement>;
}

export const useStylesControl = ({initialStyle, anchorEl, changeStyleHandler}: UseStylesControlProps): UseStylesControlValue => {

    const [stylesAnchorEl, setAnchorEl] = useState<null | HTMLElement>(() => anchorEl);

    // const [buttonTitle, setButtonTitle] = useState<string>(() => initialStyle || 'Normal text');

    const open = Boolean(stylesAnchorEl);

    const handleClick: UseStylesControlValue["handleClick"] = (event) => {
        event.stopPropagation();
        changeStyleHandler('fontStylesOpenClose', true);
        setAnchorEl(event.currentTarget);
    };

    const handleClose: UseStylesControlValue["handleClose"] = (item) => (event) => {
        event?.stopPropagation();
        
        setAnchorEl(null);
        // item && setButtonTitle(item);
        if(item){
            changeStyleHandler("styledControl", item);
        }else{
            changeStyleHandler('fontStylesOpenClose', false);
        }
    };


    return {
        open,
        anchorEl: stylesAnchorEl,
        // buttonTitle,
        handleClick,
        handleClose
    };
}
