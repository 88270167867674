import axiosSync from "../../../../newShared/utils/axios/axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {AxiosResponse} from "axios";
import {fetchedWsCollabType, TVicaTask, TVicaTaskCategory} from "../types";
import {TPageInfo} from "../../../../newShared/types";
import {
    CreateByTemplateTaskMutation,
    CreateByTemplateTaskMutationVariables,
    GetOriginTemplateByIdQuery,
    GetOriginTemplateByIdQueryVariables,
    IdNameModel,
    UploadEvidenceAssetMutation,
    UploadEvidenceAssetMutationVariables
} from "../../../../newShared/GQLTypes";
import {print} from "graphql";
import {uploadEvidenceAsset} from "../../frameworks/api/query";
import {gql} from "graphql.macro";
import {createByTemplateTask, getOriginTemplateById} from "./query";

export const getWorkspaceCollabs = async (workspaceId: string): Promise<fetchedWsCollabType[]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getWorkspaceUsersById($workspaceId:String!){
                  getWorkspaceUsersById(workspaceId:$workspaceId){
                    collabs{
                      email
                      firstName
                      lastName
                      publicId
                    }
                  }
                }
                `,
                variables: {
                    workspaceId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getWorkspaceUsersById?.collabs ?? [];
    })
)


export const getVicaCategoriesWithPaging = async (workspaceId: string, page: number, count: number, search: string, filters: Record<string, string[]>, signal?: AbortSignal): Promise<{pageInfo: TPageInfo, categories: TVicaTaskCategory[]}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getVicaCategoriesWithPaging($workspaceId:String!, $search:String!, $pageInfo:PaginationInputAnyFilter!){
                  getVicaCategoriesWithPaging(workspaceId: $workspaceId, search:$search, pageInfo:$pageInfo){
                    pageInfo{
                      page
                      count
                      total
                      sort
                    }
                    categories{
                      id
                      name
                      description
                      order
                      progress
                      collaborators{
                        publicId
                        firstName
                        lastName
                        email
                      }
                      responsible{
                        publicId
                        firstName
                        lastName
                        email
                      }
                      tasks{
                        id
                      }
                    }
                  }
                }
                `,
                variables: {
                    workspaceId,
                    pageInfo: {page, count, filters},
                    search
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getVicaCategoriesWithPaging;
    })
)


export const getVicaTasksByCategory = async (workspaceId: string, categoryId: string, search: string, filters: Record<string, string[]>, signal?: AbortSignal): Promise<{tasks: TVicaTask[]}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getVicaTasksByCategory($workspaceId:String!, $categoryId:String!, $search:String!, $filtersInput: InputAnyFilter!){
                  getVicaTasksByCategory(workspaceId:$workspaceId, categoryId:$categoryId, search:$search, filtersInput: $filtersInput){
                    tasks{
                      id
                      name
                      order
                      status
                      frameworkNames{
                        id
                        name
                      }
                      evidences{
                        id
                        name
                      }
                      collaborators{
                        firstName
                        lastName
                        publicId
                        email
                      }
                      actions{
                        title
                        type
                        data
                      }
                    }
                  }
                }
                `,
                variables: {
                    workspaceId,
                    categoryId,
                    search,
                    filtersInput: {
                        filters
                    }
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getVicaTasksByCategory;
    })
)

export const setTaskCategoryResponsible = async (workspaceId: string, categoryId: string, responsiblePublicId: string | null, signal?: AbortSignal): Promise<string> => (

    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation setTaskCategoryResponsible($workspaceId:String!, $categoryId:String!, $responsiblePublicId:String){
                  setTaskCategoryResponsible(workspaceId:$workspaceId, categoryId:$categoryId, responsiblePublicId:$responsiblePublicId){
                    message
                  }
                }
                `,
                variables: {
                    workspaceId,
                    categoryId,
                    responsiblePublicId
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.setTaskCategoryResponsible.message;
    })
)

export const setTaskCollabs = async (workspaceId: string, taskId: string, collabIds: string[], signal?: AbortSignal): Promise<string> => (

    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                mutation setTaskCollabs($workspaceId:String!, $taskId:String!, $collabIds:SetTaskCollabsInput!){
                  setTaskCollabs(workspaceId:$workspaceId, taskId:$taskId, collabIds:$collabIds){
                    message
                  }
                }
                `),
                variables: {
                    workspaceId,
                    taskId,
                    collabIds: {
                        publicIds: collabIds
                    }
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.setTaskCollabs.message;
    })
)

export const uploadEvidenceAssetApi = async (data: UploadEvidenceAssetMutationVariables, signal?: AbortSignal): Promise<UploadEvidenceAssetMutation["uploadEvidenceAsset"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(uploadEvidenceAsset),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.uploadEvidenceAsset
    })
)

export const getOriginTemplateByIdApi = async (data: GetOriginTemplateByIdQueryVariables, signal?: AbortSignal): Promise<GetOriginTemplateByIdQuery["getOriginTemplateById"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getOriginTemplateById),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getOriginTemplateById
    })
)

export const createByTemplateTaskApi = async (data: CreateByTemplateTaskMutationVariables, signal?: AbortSignal): Promise<CreateByTemplateTaskMutation["createByTemplateTask"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(createByTemplateTask),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createByTemplateTask
    })
)

//AUTOCOMPLETE
export const getVicaResponsibleAutocomplete = async (workspaceId: string, data: {search: string, page: number, count: number}, signal?: AbortSignal): Promise<{values: IdNameModel[], pageInfo: TPageInfo}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getVicaResponsibleAutocomplete($workspaceId:String!, $data:GetAutocompleteValues!){
                  getVicaResponsibleAutocomplete(workspaceId:$workspaceId, data:$data){
                    values{
                        id
                        name
                    }
                    pageInfo{
                      page
                      count
                      total
                    }
                  }
                }
                `,
                variables: {
                    workspaceId,
                    data
                }
            },
            {
                headers: buildCommonHeader(),
                // signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getVicaResponsibleAutocomplete;
    })
)

export const getVicaTaskCollabAutocomplete = async (workspaceId: string, data: {search: string, page: number, count: number}, signal?: AbortSignal): Promise<{values: IdNameModel[], pageInfo: TPageInfo}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getVicaTaskCollabAutocomplete($workspaceId:String!, $data:GetAutocompleteValues!){
                  getVicaTaskCollabAutocomplete(workspaceId:$workspaceId, data:$data){
                    values{
                        id
                        name
                    }
                    pageInfo{
                      page
                      count
                      total
                    }
                  }
                }
                `,
                variables: {
                    workspaceId,
                    data
                }
            },
            {
                headers: buildCommonHeader(),
                // signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getVicaTaskCollabAutocomplete;
    })
)

export const getVicaTaskFrameworksAutocomplete = async (workspaceId: string, data: {search: string, page: number, count: number}, signal?: AbortSignal): Promise<{values: IdNameModel[], pageInfo: TPageInfo}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getVicaTaskFrameworksAutocomplete($workspaceId:String!, $data:GetAutocompleteValues!){
                  getVicaTaskFrameworksAutocomplete(workspaceId:$workspaceId, data:$data){
                    values{
                        id
                        name
                    }
                    pageInfo{
                      page
                      count
                      total
                    }
                  }
                }
                `,
                variables: {
                    workspaceId,
                    data
                }
            },
            {
                headers: buildCommonHeader(),
                // signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getVicaTaskFrameworksAutocomplete;
    })
);
