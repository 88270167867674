import {createTargetRequestType, scanTypes, updateTargetRequestType} from "../types";
import {NMAP_SCAN_TYPE, OPENVAS_SCAN_TYPE, OWASP_SCAN_TYPE, SSL_SCAN_TYPE} from "../constants";
import colors from "../../../../newShared/theme/colors";


export const parseTargetStatus = (status: string): {text: string, color: string} => {
    //COMPLETED, QUEUED, IN_PROGRESS, REPORTING, FAILED
    switch (status){
        case 'COMPLETED': return {text: 'Completed', color: '#3BCC89'};
        case 'QUEUED': return {text: 'In progress', color: '#FAC000'};
        case 'IN_PROGRESS': return {text: 'In progress', color: '#FAC000'};
        case 'REPORTING': return {text: 'In progress', color: '#FAC000'};
        case 'FAILED': return {text: 'Failed', color: '#FF5959'};
        default: return {text: '', color: '#fff'};
    }
}

export const parseTargetHistoryStatus = (status: string | null): {text: string, color: string} => {
    //COMPLETED, QUEUED, IN_PROGRESS, REPORTING, FAILED
    switch (status){
        case 'SUCCEEDED': return {text: 'Completed', color: colors.warningActions.green};
        case 'QUED': return {text: 'In progress', color: colors.yellow};
        case 'FAILED': return {text: 'Failed', color: colors.warningActions.red};
        default: return {text: '', color: '#fff'};
    }
}

export const getDefaultAddTargetForm = ():createTargetRequestType => {
    return {
        name: '',
        target: '',
        configTarget: {
            availableScanTypes: [OPENVAS_SCAN_TYPE, OWASP_SCAN_TYPE, NMAP_SCAN_TYPE, SSL_SCAN_TYPE]
        }
    }
}

export const getDefaultUpdateTargetForm = ():updateTargetRequestType => {
    return {
        id: '',
        name: '',
        target: '',
        configTarget: {
            availableScanTypes: [OPENVAS_SCAN_TYPE, OWASP_SCAN_TYPE, NMAP_SCAN_TYPE, SSL_SCAN_TYPE]
        }
    }
}

export const normalizeScanTypeName = (scanType: scanTypes | null) => {
    switch (scanType){
        case "NMAP": return 'NMap';
        case "OPENVAS": return 'OpenVAS';
        case "OWASP_ZAP_ACTIVE": return 'OWASP';
        case "SSLYZE": return 'SSL/TLS';
        default: return 'Unknown scan type';
    }
}