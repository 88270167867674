import {ChangeEvent, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {sendSupportMessageAPI} from "../../api";
import {addSuccessfulSnack} from "../../../../barsEnvironment/snack/store/slice";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";

export const useContactUs = () => {
    const dispatch = useDispatch();

    const {currData: {selectedWorkspace}} = UseManageWorkspacesAndOrganizations();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [type, setType] = useState<string>('');
    const handleChangeType = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {setType(e.target.value)}
    const [subject, setSubject] = useState<string>('');
    const handleChangeSubject = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {setSubject(e.target.value)}
    const [message, setMessage] = useState<string>('');
    const handleChangeMessage = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {setMessage(e.target.value)}

    const handleClean = () => {
        setType('');
        setSubject('');
        setMessage('');
    }

    const handleSubmit = () => {
        setIsLoading(true);
        sendSupportMessageAPI({data: {type, message, subject}, wid: selectedWorkspace?.id ?? 'Not selected'})
            .then(res => {
                dispatch(addSuccessfulSnack('Message sent'));
                handleClean();
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    useEffect(() => {
        return () => {
            handleClean();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        type, handleChangeType,
        subject, handleChangeSubject,
        message, handleChangeMessage,

        handleSubmit,
        isLoading,
    }
}