import React, {FC, useEffect, useState} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TinyMceEditor} from "../../../../../newShared/components/tinyMceEditor";
import * as S from "../styled";
import {Box, Collapse, Typography} from "@mui/material";
import {getFile} from "../../../../../newShared/components/docViewer/api";
import {DEFAULT_ZOOM} from "../../../../../newShared/components/docViewer/constants";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {LocalSpinner} from "../../../../../newShared/components/Spinner";
import {Typo} from "../../../../../newShared/components/Typography";
import {Flex} from "../../../../../newShared/components/Layouts";
import {Document, Page} from "react-pdf";
import {PDFDocumentProxy} from "pdfjs-dist/types/src/display/api";
import {TypographyNew} from "../../../../../newShared/components/TypographyNew";
import colors from "../../../../../newShared/theme/colors";
import {MainTrainingContentType, TrainingContentNewModel} from "../../../../../newShared/GQLTypes";
import {VideoFilePreview} from "../dialogs/createEditTrainingDialog/components/preview/videoFilePreview";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import PictureInPictureIcon from "@mui/icons-material/PictureInPicture";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import {FilePreview} from "../dialogs/createEditTrainingDialog/components/preview/filePreview";
import {SectionArrow} from "../../../../../newShared/components/SectionArrow";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {VideoLinkPreview} from "../dialogs/createEditTrainingDialog/components/preview/videoLinkPreview";


type TTrainingSectionProps = {
    isOpen: boolean,
    section: TrainingContentNewModel,
    toggleOpen: (sectionId: string) => void,
    order?: number,
    isExactPage?: boolean
}

export const TrainingSection: FC<TTrainingSectionProps> = ({section, isOpen, toggleOpen, isExactPage, order}) => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.dialogs'});
    const {isMobile} = useMedia();
    const workspaceId = UseManageWorkspacesAndOrganizations().workspaceId || '';

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [file, setFile] = useState<string>('');
    const [zoom, setZoom] = React.useState<number>(DEFAULT_ZOOM);
    const [totalPages, setTotalPages] = useState<number | null>(null);
    // const [currentPage, setCurrentPage] = useState<number>(1);

    const getFileById = async(fileId: string, fileName: string, workspaceId: string) => {
        setIsLoading(true);
        try{
            const {file} = await getFile(fileId, fileName, workspaceId);
            setFile(file);
            setIsLoading(false);
        }catch (ex){
            setIsLoading(false);
        }
    }

    const getDocumentFile = () => `data:application/pdf;base64,${file}`;

    async function onDocumentLoadSuccess({ numPages, getViewerPreferences }: PDFDocumentProxy) {
        setTotalPages(numPages);
    }


    //SCROLL PAGES
    // const pagesRef = useRef<HTMLDivElement>(null);

    const [refs, setRefs] = useState<{[key: number]: HTMLDivElement}>({});

    const handleAppendRef = (index: number, ref?: HTMLDivElement | null) => {
        ref && refs[index] === undefined && setRefs((prev) => {return {...prev, [index]: ref}});
    }


    useEffect(() => {
        if (isOpen && section.type === MainTrainingContentType.File && section.data.fileId && section.data.name) {
            getFileById(section.data.fileId, section.data.name, workspaceId);
        }

        return () => {
            setZoom(DEFAULT_ZOOM);
            // setCurrentPage(1);
            setRefs({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <>
            <S.TrainingSectionBox revDir={revDir} onClick={() => toggleOpen(section.sectionId)}>
                <Box display={'flex'} alignItems={'center'} width={isMobile ? '100%' : '70%'} gap={'10px'}>
                    <SectionArrow isOpen={isOpen} />
                    <Typography variant={'*bodyText2'} sx={{whiteSpace: 'nowrap'}}>{`${t('Section')} ${order ?? section.order}`}</Typography>
                    <TypographyNew variant={'*bodyText2_semibold'} sx={{flexGrow: 1}} color={colors.grayText} noWrap>{section.name}</TypographyNew>
                </Box>


                {!isMobile && isExactPage && (section.type === MainTrainingContentType.VideoLink || section.type === MainTrainingContentType.VideoFile) &&
                    <Box display={'flex'} alignItems={'center'} gap={'8px'} m={'0 8px'} whiteSpace={'nowrap'}>
                        <SlowMotionVideoIcon fontSize={'small'} sx={{color: colors.text.grey_dark}}/>
                        <TypographyNew variant={'*bodyText2'} color={colors.grayText} noWrap>{t('Video')}</TypographyNew>
                    </Box>
                }

                {!isMobile && isExactPage && section.type === MainTrainingContentType.File &&
                    <Box display={'flex'} alignItems={'center'} gap={'8px'} m={'0 8px'} whiteSpace={'nowrap'}>
                        <PictureInPictureIcon fontSize={'small'} sx={{color: colors.text.grey_dark}}/>
                        <TypographyNew variant={'*bodyText2'} color={colors.grayText} noWrap>{'File'}</TypographyNew>
                    </Box>
                }

                {!isMobile && isExactPage && section.type === MainTrainingContentType.Editor &&
                    <Box display={'flex'} alignItems={'center'} gap={'8px'} m={'0 8px'} whiteSpace={'nowrap'}>
                        <TextFieldsIcon fontSize={'small'} sx={{color: colors.text.grey_dark}}/>
                        <TypographyNew variant={'*bodyText2'} color={colors.grayText} noWrap>{'Editor'}</TypographyNew>
                    </Box>
                }
            </S.TrainingSectionBox>

            <Collapse in={isOpen} sx={{flexShrink: 0, width: '100%'}} unmountOnExit mountOnEnter>
                { section.type === MainTrainingContentType.File &&
                    <>
                        {isExactPage ?
                            <FilePreview section={section} />
                            :
                            <S.TrainingSectionContainer>
                                {isLoading && <LocalSpinner />}
                                {!isLoading && (!section.data.fileId || !section.data.name) && <Typo fontSize={'16px'} fontWeight={400}>File not found!</Typo>}
                                {!isLoading && section.data.fileId && file.length > 0 &&
                                    <Flex minw={'fit-content'} jc={'center'} >
                                        <Document
                                            file={getDocumentFile()}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                        >
                                            {(totalPages ?? 0) > 0 && new Array(totalPages).fill(1).map((_, i) => (
                                                <Flex m={'16px'} ai={'center'} jc={'center'} key={i+1} ref={(ref) => {handleAppendRef(i+1, ref)}}>
                                                    <Page pageNumber={i+1} scale={zoom}/>
                                                </Flex>
                                            ))}
                                        </Document>
                                    </Flex>
                                }
                            </S.TrainingSectionContainer>
                        }
                    </>

                }

                {section.type === MainTrainingContentType.Editor && section.data.innerHtml && (
                    <S.TrainingSectionContainer>
                        <Box sx={{width: '100%', minHeight: '500px', overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
                            <TinyMceEditor key={section.data.innerHtml || ''} readonly hideMenu initialContent={section.data.innerHtml} onSave={() => null} />
                        </Box>
                    </S.TrainingSectionContainer>
                )}

                {(section.type === MainTrainingContentType.VideoFile) &&
                    <S.TrainingSectionContainer>
                        <VideoFilePreview section={section}/>
                    </S.TrainingSectionContainer>
                }

                {(section.type === MainTrainingContentType.VideoLink) &&
                    <S.TrainingSectionContainer>
                        <VideoLinkPreview section={section}/>
                    </S.TrainingSectionContainer>
                }
            </Collapse>
        </>
    )

}
