import React, {FC, useEffect} from "react";
import {ConnectionLineType, Edge, Node, ReactFlowState, useEdges, useReactFlow, useStore} from "reactflow";
import {employeeTreeNodeHeight, employeeTreeNodeWidth, getLayoutedElements, TEmployeeNodeData} from "../../helpers";
import {Box, Button, IconButton, Menu, MenuItem, Typography} from "@mui/material";
import {useEmployeesTree} from "../../hooks/useEmployeesTree";
import {IconTextButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {UpdateEmployeesTreeDto} from "../../../../../newShared/GQLTypes";
import dagre, {graphlib} from "dagre";
import GetAppIcon from "@mui/icons-material/GetApp";
import UploadIcon from "@mui/icons-material/Upload";
import {useCommonDialogs} from "../../hooks/dialogHooks/useCommonDialogs";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {LoadingButton} from "@mui/lab";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import colors from "../../../../../newShared/theme/colors";


export const EmployeesBarUnderOrgTree: FC = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathHRManagement.tree'});
    const {tCommon} = useMainTranslation();

    const {isMobile, matches_770Up} = useMedia();
    const {
        employees, isLoading: {isLoadingTree, isUpdatingTree, exportCsv},
        isEditMode, deleted, setTree, updateTree, direction,
        cycleDetectedError
    } = useEmployeesTree();

    const {menu, handleExportCSV, handleImportCsv, handleRelease} = useCommonDialogs();

    const {
        setNodes, setEdges,
    } = useReactFlow<TEmployeeNodeData>();
    const edges = useEdges();

    useEffect(() => {
        init(new dagre.graphlib.Graph({compound: true}).setDefaultEdgeLabel(() => ({})))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employees, direction]);


    const someEditEdgeSelector = (state: ReactFlowState) => state.edges.some(e => e.id.startsWith('EDIT'));
    const someEditEdge = useStore(someEditEdgeSelector);
    useEffect(() => {
        setTree({isEditMode: someEditEdge});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [someEditEdge]);

    useEffect(() => {
        if (isEditMode) {
            setEdges(edges.map(e => ({...e, id: `EDIT::${e.source}::${e.target}`, animated: true, type: 'edit'})))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditMode]);

    const init = (_dagreGraph: graphlib.Graph<{}>) => {
        setTree({isEditMode: false, deleted: []});
        const edges: Edge[] = [];
        employees.forEach(employee => {
            if (employee.manager && employee.manager.trim() !== '') {
                edges.push(({
                    id: `STATIC::${employee.id}::${employee.manager}`, source: employee.manager, target: employee.id,
                    type: ConnectionLineType.Step
                }))
            }
        })

        const nodes: Node<TEmployeeNodeData>[] = [];
        employees.forEach(employee => {
            nodes.push(({
                id: employee.id,
                data: {
                    id: employee.id,
                    name: employee.name,
                    logo: null,

                    jobTitle: employee.jobTitle,
                    department: 'Department',
                    manager: employee.manager,

                    workEmail: employee.workEmail,
                    workMobilePhone: employee.workMobilePhone,

                    isDeleted: false,
                },
                height: employeeTreeNodeHeight, width: employeeTreeNodeWidth, position: {x: 0, y: 0},
                type: 'employeeTreeNode',
            }));
        });

        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
            _dagreGraph,
            nodes,
            edges,
            direction,
        );

        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
    }

    const cycleDetect = (employees:  UpdateEmployeesTreeDto[]): boolean => {
        const employeesForCheck = employees.filter(({managerId}) => managerId && managerId.trim() !== '');

        const emplTree: {[key: string]: string[]} = {}
        employeesForCheck.forEach(({employeeId}) => {
            emplTree[employeeId] = [];
            employeesForCheck.filter(e => e.managerId === employeeId).forEach((e) => {
                emplTree[employeeId].push(e.employeeId);
            });
        })

        Object.keys(emplTree).forEach((key) => {
            emplTree[key].length === 0 && delete emplTree[key];
        })

        Object.keys(emplTree).forEach((key) => {
            let arrLength: number = emplTree[key].length;
            emplTree[key].forEach(e => emplTree?.[e] && emplTree[key].push(...emplTree[e]));

            while((emplTree[key].length !== arrLength)) {
                arrLength = emplTree[key].length;
                emplTree[key].forEach(e => emplTree?.[e] && emplTree[key].push(...emplTree[e]));
                const tmp = [...new Set(emplTree[key])]
                emplTree[key] = tmp;

            }
        })

        let tmp: number[] = [];
        Object.keys(emplTree).forEach((key) => {
            emplTree[key].forEach((e) => {
                emplTree[e] && tmp.push(emplTree[e].findIndex(empl => empl === key));
            })
        })

        return tmp.some(n => n > -1);
    }

    return (
        <Box display={"flex"} alignItems={"center"} width={'100%'}>

            {isEditMode ? (
                <>
                    <LoadingButton loading={isUpdatingTree}
                                   size={!matches_770Up ? 'small' : undefined}
                                   disabled={
                                       isLoadingTree || exportCsv || isUpdatingTree
                                       || edges.some((edge, index, array) => array.filter(e => e.target === edge.target).length > 1)
                                   }
                                   onClick={() => {
                                       const array: UpdateEmployeesTreeDto[] = [];
                                       const newEmployees: UpdateEmployeesTreeDto[] = [];

                                       employees.filter(e => e.manager && e.manager.trim() !== '' && !deleted.includes(e.id)).forEach(emp => {
                                           if (!edges.some(e => e.target === emp.id)) {
                                               array.push({employeeId: emp.id, managerId: null})
                                           }
                                       })

                                       edges.filter(e => !deleted.includes(e.target) && !deleted.includes(e.source)).forEach(edge => {
                                           if (!employees.some(emp => emp.id === edge.target && emp.manager === edge.source)) {
                                               array.push({employeeId: edge.target, managerId: edge.source})
                                           }
                                       })

                                       employees.forEach( empl => {
                                           const tmp = array.find(e => empl.id === e.employeeId)
                                           newEmployees.push(tmp  ? ({employeeId: tmp.employeeId, managerId: tmp.managerId}) : ({employeeId: empl.id, managerId: empl.manager}))
                                       })

                                       if (cycleDetect(newEmployees)) {
                                           cycleDetectedError();
                                       } else updateTree(array, deleted);
                                   }}>
                        {'Save'}
                    </LoadingButton>
                    <Box width={'16px'} height={'16px'}/>
                    <Button size={!matches_770Up ? 'small' : undefined} variant={"outlined"} onClick={() => init(new dagre.graphlib.Graph({compound: true}).setDefaultEdgeLabel(() => ({})))}>{'Cancel'}</Button>
                </>
            ) : (
                <Button size={!matches_770Up ? 'small' : undefined}  onClick={() => {setTree({isEditMode: true})}}>{tCommon('Edit')}</Button>
            )}

            {!matches_770Up ?
                <>
                    <IconButton
                        sx={{ml: 'auto'}}
                        aria-label="more"
                        id="long-button"
                        aria-controls={menu.open ? 'long-menu' : undefined}
                        aria-expanded={menu.open ? 'true' : undefined}
                        aria-haspopup="true"
                        disabled={isLoadingTree || exportCsv || isUpdatingTree}
                        onClick={menu.handleClick}
                    >
                        <MoreVertIcon sx={{color: isLoadingTree || exportCsv || isUpdatingTree ? colors.primary.disable : colors.primary.blue}}/>
                    </IconButton>
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={menu.anchorEl}
                        open={menu.open}
                        onClose={menu.handleClose}
                        PaperProps={{
                            style: {
                                maxHeight: 48 * 4.5,
                                width: '20ch',
                            },
                        }}
                    >

                        <MenuItem onClick={handleRelease}
                                  disabled
                                  // disabled={isLoadingTree || isLoadingExport || isUpdatingTree}
                        >
                            <Typography>{t('Release')}</Typography>
                        </MenuItem>

                        <MenuItem onClick={handleExportCSV}
                                  disabled
                                  // disabled={isLoadingTree || isLoadingExport || isUpdatingTree}
                        >
                            <Typography>{t('Export CSV')}</Typography>
                        </MenuItem>

                        <MenuItem onClick={handleImportCsv}>
                            <Typography>{t('Import CSV')}</Typography>
                        </MenuItem>
                    </Menu>
                </>
                :
                <>
                    <Button variant={'contained'} disabled={isLoadingTree || isUpdatingTree || exportCsv} color={'secondary'} size={isMobile ? 'small' : 'medium'} onClick={handleRelease} sx={{mr: revDir ? '16px' : undefined, ml: !revDir ? '16px' : undefined, }}>{t('Release')}</Button>

                    <LoadingButton variant={"text"} size={"large"} component={"label"}
                                   // disabled={isLoadingTree || isUpdatingTree || isLoadingExport}
                        disabled
                                   onClick={handleExportCSV} loadingPosition={exportCsv ? 'start' : undefined} loading={exportCsv}
                                   startIcon={exportCsv ? <div style={{width: '10px', height: '10px', marginLeft: revDir ? '9px' : undefined }}/> : <GetAppIcon style={{marginLeft: revDir ? '9px' : undefined }}/>}
                                   sx={{margin: revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    >
                        {t('Export CSV')}
                    </LoadingButton>
                    <IconTextButton
                        variant={'text'}
                        // disabled={isLoadingTree || isUpdatingTree || isLoadingExport}
                        disabled
                        icon={<UploadIcon />}
                        onClick={handleImportCsv}
                        sx={{'& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}}}
                    >{t('Import CSV')}</IconTextButton>
                </>
            }


        </Box>
    );
}