import {useProfile} from "../../hooks/profile/useProfile";
import * as S from '../../components/styled';
import {TextFieldCustom} from '../../components/styled';
import {Button, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import React from "react";
import {Flex} from "../../../../../newShared/components/Layouts";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {LoadingButton} from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import {IconTextButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";

export const Profile = () => {
    const {
        origin,
        form,
        handleChange,
        isLoading,
        isChangeMode,
        handleEdit,
        isOk,
        handleSave,
        handleCancelEdit
    } = useProfile();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathSettings.Profile'});
    const {isMobile} = useMedia(858);


    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('Profile')}
                actions={[]}
            />

            <S.ProfileSettingWrapper>
                {isChangeMode ?
                    <>
                        <Flex ai={'center'}>
                            <LoadingButton
                                loading={isLoading}
                                disabled={!isOk}
                                sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px', paddingLeft: '35px', }}
                                variant={"contained"}
                                onClick={handleSave}
                            >{t('Save')}</LoadingButton>
                            <Button disabled={isLoading} variant={'outlined'} size={isMobile ? 'small' : 'medium'} onClick={handleCancelEdit} >{t('Cancel')}</Button>
                        </Flex>

                        <TextFieldCustom required size={'small'} value={form.firstName} onChange={handleChange} name={'firstName'} label={t('First name')} sx={{margin: '16px 0 0 0'}}/>
                        <TextFieldCustom required size={'small'} value={form.lastName} onChange={handleChange} name={'lastName'} label={t('Last name')} sx={{margin: '16px 0 0 0'}}/>
                    </>
                    :
                    <>
                        <IconTextButton
                            variant={'text'}
                            icon={<EditIcon fontSize="small"/>}
                            onClick={handleEdit}
                            sx={{maxWidth: '160px', paddingLeft: !revDir ? '0' : 'initial', paddingRight: !revDir ? 'initial' : '0'}}
                        >{t('Change info')}
                        </IconTextButton>

                        <Flex w={'100%'} ai={'center'} m={'16px 0 0 0'}>
                            <Typography variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} color={colors.lightGrayText}>{t('First name')}</Typography>
                            <Typography variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} color={colors.grayText}>{origin.firstName}</Typography>
                        </Flex>

                        <Flex w={'100%'} ai={'center'} m={'20px 0 0 0'}>
                            <Typography variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} color={colors.lightGrayText}>{t('Last name')}</Typography>
                            <Typography variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} color={colors.grayText}>{origin.lastName}</Typography>
                        </Flex>
                    </>
                }
            </S.ProfileSettingWrapper>
        </Flex>
    )
}
