import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
    emptyContact,
    emptyEducation,
    emptyEmergencyContact,
    mockEmployee,
    PATH_LOCAL_EMPLOYEES_ALL,
    PATH_LOCAL_EMPLOYEES_ID,
    PATH_LOCAL_EMPLOYEES_NEW,
    PHONE_NUMBER_CONTACT_TYPE
} from "../../constants";
import {hrSettings, loadings, openAddSettingDialog} from "../../store/slice";
import {ChangeEvent} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_EMPLOYEES} from "../../../../../newShared/constants";
import {useForm} from "../../../../../newShared/hooks/useForm";
import {
    EmployeeType,
    TEmployeeStatus,
    TEmployeeVeteranStatus,
    THrSettings,
    THrSettingsJobTitle,
    TManager
} from "../../types";
import {CreateHrEmployee} from "../../store/actions";
import {validateEmail} from "../../../../../newShared/utils/text";
import {isFutureDate, removeTimeZoneDate} from "../../../../../newShared/utils/dateTools";

export const useEmployeesCreate = () => {
    //root
    const {t: tMenu, currentLanguage} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const dispatch = useDispatch();
    const history = useHistory();

    //selectors
    const settings = useSelector(hrSettings);
    const {createEmployee} = useSelector(loadings);

    //useEffects
    useSetBreadcrumbs([
        {
            title: tMenu('HR Management'),
            path: PATH_LOCAL_EMPLOYEES
        },
        {
            title: tMenu('Employees'),
            path: PATH_LOCAL_EMPLOYEES_ALL
        },
        {
            title: tMenu('New employee'),
            path: PATH_LOCAL_EMPLOYEES_NEW
        }
    ]);

    const handleSubmit = () => {
        if(isOk()){
            dispatch(CreateHrEmployee({data: {employee: {...form, hireDate: removeTimeZoneDate(form.hireDate)}, onSuccess}}));
        }
    }

    const onSuccess = (id: string) => {
        history.push(PATH_LOCAL_EMPLOYEES_ID.replace(':id', id));
    }

    const isOk = (): boolean => {
        const jobRecord = form.jobInfos.find(e => e.current);
        if(!jobRecord) return false;
        if(isFutureDate(form.hireDate, currentLanguage.momentLocale)) return false;
        if(jobRecord.jobTitle === null ||
            !jobRecord.employmentType.trim().length ||
            !jobRecord.location.trim().length ||
            !jobRecord.division.trim().length ||
            !jobRecord.department.trim().length ||
            jobRecord.startDate === null
        ) return false;
        return form.firstName.trim().length > 0 &&
            form.lastName.trim().length > 0 &&
            form.gender !== null &&
            form.maritalStatus !== null &&
            validateEmail(form.workEmail) &&
            form.status !== null &&
            !form.personalData.education.find(e => (!e.schoolName.trim().length || !e.degree?.trim().length)) && //schoolName and degree are required in every education
            form.contactInfo.address.country !== null && form.contactInfo.address.country?.trim().length > 0 &&
            form.contactInfo.address.city?.trim().length > 0 &&
            form.contactInfo.contacts.some(e => e.type === PHONE_NUMBER_CONTACT_TYPE) && //has to have phone number
            !form.contactInfo.contacts.some(e => (!e.type || !e.value.trim().length)) && //every contact has non-empty value
            !form.contactInfo.emergencyContacts.some(e => (!e.relationType || !e.address.trim().length || !e.email.trim().length || !e.phone.trim().length) || !e.fullName.trim().length)
    }

    //form
    const {form, setForm, handleChange} = useForm<EmployeeType>(mockEmployee);

    return{
        topBarActions: {
            handleGoBack: () => history.push(PATH_LOCAL_EMPLOYEES_ALL),
            handleSubmit,
        },
        isOk,
        isLoading: createEmployee,
        settings,
        form,
        setForm,
        handleChange,
        actions: {
            handleSetBirthday: (birthDay: string | null) => {setForm({...form, birthDay})},
            handleSetGender: (gender: string | null) => {gender && setForm({...form, gender})},
            handleSetMartialStatus: (maritalStatus: string | null) => {maritalStatus && setForm({...form, maritalStatus})},
            handleSetEthnicity: (ethnicity: string | null) => {ethnicity && setForm({...form, ethnicity})},
            handleDeleteEducation: (id: number) => {
                setForm({...form, personalData: {
                        ...form.personalData,
                        education: form.personalData.education.filter((e, _id) => _id !== id)
                    }})
            },
            handleAddEducation: () => {
                setForm({...form, personalData: {
                        ...form.personalData,
                        education: [emptyEducation, ...form.personalData.education]
                    }})
            },
            handleChangeEducation: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
                setForm((prevValues):EmployeeType => ({
                    ...prevValues,
                    personalData: {
                        ...prevValues.personalData,
                        education: [
                            ...prevValues
                                .personalData
                                .education
                                .map((e, _id) => _id === id ? {...e, [event.target.name]: event.target.value} : e)
                        ]
                    }
                }));
            },
            handleChangeEducationDegree: (newValue: string | null, id: number) => {
                setForm((prevValues):EmployeeType => ({
                    ...prevValues,
                    personalData: {
                        ...prevValues.personalData,
                        education: [
                            ...prevValues
                                .personalData
                                .education
                                .map((e, _id) => _id === id ? {...e, degree: newValue} : e)
                        ]
                    }
                }));
            },
            handleSetEducationEndDate: (newValue: string | null, id: number) => {
                setForm((prevValues):EmployeeType => ({
                    ...prevValues,
                    personalData: {
                        ...prevValues.personalData,
                        education: [
                            ...prevValues
                                .personalData
                                .education
                                .map((e, _id) => _id === id ? {...e, endDate: newValue} : e)
                        ]
                    }
                }));
            },
            handleSetEducationStartDate: (newValue: string | null, id: number) => {
                setForm((prevValues):EmployeeType => ({
                    ...prevValues,
                    personalData: {
                        ...prevValues.personalData,
                        education: [
                            ...prevValues
                                .personalData
                                .education
                                .map((e, _id) => _id === id ? {...e, startDate: newValue} : e)
                        ]
                    }
                }));
            },
            handleSetAddressCountry: (country: string | null) => {
                setForm((prevValues):EmployeeType => ({
                    ...prevValues,
                    contactInfo: {
                        ...prevValues.contactInfo,
                        address: {
                            ...prevValues.contactInfo.address,
                            country
                        }
                    }
                }));
            },
            handleChangeAddress: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setForm({...form, contactInfo: {
                        ...form.contactInfo,
                        address: {...form.contactInfo.address, [event.target.name]: event.target.value}
                    }})
            },
            handleAddContact: () => {
                setForm({...form, contactInfo: {
                        ...form.contactInfo,
                        contacts: [emptyContact, ...form.contactInfo.contacts]
                    }})
            },
            handleDeleteContact: (id: number) => {
                setForm({...form, contactInfo: {
                        ...form.contactInfo,
                        contacts: form.contactInfo.contacts.filter((e, _id) => _id !== id)
                    }})
            },
            handleSetContactType: (id: number, type: string | null) => {
                setForm({...form, contactInfo: {
                        ...form.contactInfo,
                        contacts: form.contactInfo.contacts.map((e, _id) => _id === id ? {...e, type} : e)
                    }})
            },
            handleChangeContactValue: (id: number, value: string) => {
                setForm({...form, contactInfo: {
                        ...form.contactInfo,
                        contacts: form.contactInfo.contacts.map((e, _id) => _id === id ? {...e, value} : e)
                    }})
            },
            handleAddEmergencyContact: () => {
                setForm({...form, contactInfo: {
                        ...form.contactInfo,
                        emergencyContacts: [emptyEmergencyContact, ...form.contactInfo.emergencyContacts]
                    }})
            },
            handleDeleteEmergencyContact: (id: number) => {
                setForm({...form, contactInfo: {
                        ...form.contactInfo,
                        emergencyContacts: form.contactInfo.emergencyContacts.filter((e, _id) => _id !== id)
                    }})
            },
            handleChangeEmergencyContact: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
                setForm((prevValues): EmployeeType => ({
                    ...prevValues,
                    contactInfo: {
                        ...prevValues.contactInfo,
                        emergencyContacts: [
                            ...prevValues
                                .contactInfo
                                .emergencyContacts
                                .map((e, _id) => _id === id ? {...e, [event.target.name]: event.target.value} : e)
                        ]
                    }
                }));
            },
            handleSetEmergencyContactType: (id: number, relationType: string | null) => {
                setForm((prevValues):EmployeeType => ({
                    ...prevValues,
                    contactInfo: {
                        ...prevValues.contactInfo,
                        emergencyContacts: [
                            ...prevValues
                                .contactInfo
                                .emergencyContacts
                                .map((e, _id) => _id === id ? {...e, relationType} : e)
                        ]
                    }
                }));
            },
            handleAddCustomField: () => {
                dispatch(openAddSettingDialog({type: 'customFields'}));
            },
            openAddContactType: (index: number) => {
                dispatch(openAddSettingDialog({type: 'contactType', id: index}));
            },
            openAddRelationshipType: (index: number) => {
                dispatch(openAddSettingDialog({type: 'relationshipType', id: index}));
            },
            openAddDegree: (index: number) => {
                // console.log(`openAddDegree: ${index}`)
                dispatch(openAddSettingDialog({type: 'degree', id: index}));
            },
            openAddJobTitle: () => {
                dispatch(openAddSettingDialog({type: 'jobTitle'}));
            },
            openAddEmploymentType: () => {
                dispatch(openAddSettingDialog({type: 'employmentType'}));
            },
            openAddDepartment: () => {
                dispatch(openAddSettingDialog({type: 'department'}));
            },
            openAddLocation: () => {
                dispatch(openAddSettingDialog({type: 'location'}));
            },
            openAddDivision: () => {
                dispatch(openAddSettingDialog({type: 'division'}));
            },
            handleChangeVeteranStatus: (key: keyof TEmployeeVeteranStatus, checked: boolean) => {
                setForm({...form, personalData: {...form.personalData, veteranStatus: {...form.personalData.veteranStatus, [key]: checked}}})
            },
            handleSetHireDate: (newValue: string | null) => {
                const jobRecord = form.jobInfos.find(e => e.current);
                if(jobRecord){
                    setForm({...form, jobInfos: [{...jobRecord, startDate: newValue}], hireDate: newValue})
                }
            },
            handleJobTitle: (newValue: THrSettingsJobTitle | null) => {
                const jobRecord = form.jobInfos.find(e => e.current);
                if(jobRecord && newValue){
                    setForm({...form, jobInfos: [{...jobRecord, jobTitle: newValue}]})
                }
            },
            handleSetEmployeeType: (employmentType: string | null) => {
                const jobRecord = form.jobInfos.find(e => e.current);
                if(jobRecord && employmentType){
                    setForm({...form, jobInfos: [{...jobRecord, employmentType}]})
                }
            },
            handleSetDepartment: (department: string | null) => {
                const jobRecord = form.jobInfos.find(e => e.current);
                if(jobRecord && department){
                    setForm({...form, jobInfos: [{...jobRecord, department}]})
                }
            },
            handleSetLocation: (location: string | null) => {
                const jobRecord = form.jobInfos.find(e => e.current);
                if(jobRecord && location){
                    setForm({...form, jobInfos: [{...jobRecord, location}]})
                }
            },
            handleSetDivision: (division: string | null) => {
                const jobRecord = form.jobInfos.find(e => e.current);
                if(jobRecord && division){
                    setForm({...form, jobInfos: [{...jobRecord, division}]})
                }
            },
            handleSetManager: (manager: TManager | null) => {
                const jobRecord = form.jobInfos.find(e => e.current);
                if(jobRecord){
                    setForm({...form, jobInfos: [{...jobRecord, manager}]})
                }
            },
            handleChangeStatus: (newValue: TEmployeeStatus | null) => {
                newValue && setForm({...form, status: newValue})
            },
            handleJobTitleAdd: (job: THrSettingsJobTitle) => {
                const jobRecord = form.jobInfos.find(e => e.current);
                if(jobRecord && job){
                    setForm({...form, jobInfos: [{...jobRecord, jobTitle: job}]})
                }
            },
            handleSettingAdd: (type: keyof THrSettings, value: string, id?: number) => {
                switch (type){
                    case "employmentType": {
                        const jobRecord = form.jobInfos.find(e => e.current);
                        if(jobRecord){
                            setForm({...form, jobInfos: [{...jobRecord, employmentType: value}]})
                        }
                        return;
                    }
                    case "department": {
                        const jobRecord = form.jobInfos.find(e => e.current);
                        if(jobRecord){
                            setForm({...form, jobInfos: [{...jobRecord, department: value}]})
                        }
                        return;
                    }
                    case "location": {
                        const jobRecord = form.jobInfos.find(e => e.current);
                        if(jobRecord){
                            setForm({...form, jobInfos: [{...jobRecord, location: value}]})
                        }
                        return;
                    }
                    case "division": {
                        const jobRecord = form.jobInfos.find(e => e.current);
                        if(jobRecord){
                            setForm({...form, jobInfos: [{...jobRecord, division: value}]})
                        }
                        return;
                    }
                    case "contactType": {
                        setForm({
                            ...form,
                            contactInfo: {
                                ...form.contactInfo,
                                contacts: form.contactInfo.contacts.map((e, _id) => _id === id ? {...e, type: value} : e)
                            }
                        });
                        return;
                    }
                    case "degree": {
                        setForm({
                            ...form,
                            personalData: {
                                ...form.personalData,
                                education: form.personalData.education.map((e, _id) => _id === id ? {...e, degree: value} : e),
                            }
                        });
                        return;
                    }
                    case "relationshipType": {
                        setForm({
                            ...form,
                            contactInfo: {
                                ...form.contactInfo,
                                emergencyContacts: form.contactInfo.emergencyContacts.map((e, _id) => _id === id ? {...e, relationType: value} : e)
                            }
                        });
                        return;
                    }
                }
            }
        },
        currentJobInfo: form.jobInfos.find(e => e.current),
    }
}