import {FC, MouseEventHandler} from "react";
import {EditorItemTableCellResizerStyled} from "../../styled";

interface EditorItemTableCellResizerProps {
    handleMouseDown: MouseEventHandler;
    readonly?: boolean;
    // handleMouseMove: (e: MouseEvent) => void;
    // handleMouseUp: (e: MouseEvent) => void;
}
export const EditorItemTableCellResizer: FC<EditorItemTableCellResizerProps> = ({handleMouseDown, readonly}) => {

    // const [isDragging, setDragging] = useState<boolean>(false)

    // useEffect(() => {
    //     if (isDragging) {
    //         // Attach the listeners to `document`
    //         document.onmousemove = handleMouseMove;
    //         document.onmouseup = handleMouseUp;
    //     } else {
    //
    //     }
    //     // eslint-disable-next-line
    // }, [isDragging])
    return (
        <EditorItemTableCellResizerStyled
            onMouseDown={!readonly ? handleMouseDown : undefined}
            readonly={readonly}
            // onClick={e => {e.stopPropagation()}}
            // onMouseDown={(e) => {
            //     handleMouseDown(e)
            //     setDragging(true)
            // }}
            // onMouseUp={(e) => {
            //     setDragging(false)
            // }}
        />
    )
}