import React, {FC, useEffect, useState} from "react";
import {DPI, PAGE_SIZES, setPagePropertiesMenuVariableEventName} from "../../../../constants";
import {TPageOrientation, TPageSizeTitle, TSetPagePropertiesMenuEventData} from "../../../../types";
import {createEventSetPagePropertiesMenu} from "../../../../helpers";
import {
    EditorSideMenuDescriptionStyled,
    EditorSideMenuDividerStyled,
    EditorSideMenuTitleStyled
} from "../../../editorSideMenu/styled";
import {
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select
} from "@mui/material";
import {Close} from "@mui/icons-material";
import {
    PagePropertiesSideMenuMarginRowStyled,
    PagePropertiesSideMenuMarginTextFieldStyled,
    PagePropertiesSideMenuTitleWithExitStyled
} from "./styled";

export const PagePropertiesSideMenu: FC = () => {

    const [properties, setProperties] = useState<TSetPagePropertiesMenuEventData>(undefined);

    const setFromEvent = (ev: Event) => {
        const {detail} = ev as CustomEvent<TSetPagePropertiesMenuEventData>;
        setProperties(detail);
        // setProperties(detail ? {
        //     ...detail,
        //     data: {
        //         ...detail.data,
        //         footerHeight: Math.round((detail.data.footerHeight + Number.EPSILON) * 100) / 100,
        //         headerHeight: Math.round((detail.data.headerHeight + Number.EPSILON) * 100) / 100,
        //         marginLeft: Math.round((detail.data.marginLeft + Number.EPSILON) * 100) / 100,
        //         marginRight: Math.round((detail.data.marginRight + Number.EPSILON) * 100) / 100,
        //     }
        // } : undefined);
    }

    useEffect(() => {
        properties && createEventSetPagePropertiesMenu(properties);
    }, [properties])

    useEffect(() => {
        document.addEventListener(setPagePropertiesMenuVariableEventName, setFromEvent);

        return () => {
            document.removeEventListener(setPagePropertiesMenuVariableEventName, setFromEvent);
        }
    }, [])

    const onChange = (name: 'marginTop' | 'marginBottom' | 'marginLeft' | 'marginRight') => (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value: _value} = event.target;
        const dotIndex = _value.indexOf('.');
        const value = dotIndex > -1 ? _value.slice(0, dotIndex + 3) : _value;

        const number = value.length > 0 && properties
            ? !isNaN(Number(value))
                ? Number(value)
                : properties?.data[name] / DPI
            : 0;

        properties && setProperties({
            ...properties,
            data: {...properties.data, [name]: number * DPI}
        })
    }

    const handleClose = () => {
        createEventSetPagePropertiesMenu(undefined);
    }

    const getRoundedValue = (value: number) => {
        return Math.round((value + Number.EPSILON) * 100) / 100;
    }

    return properties ? (
        // <ClickAwayListener onClickAway={handleClose} mouseEvent={'onMouseDown'}>
            <>
                <PagePropertiesSideMenuTitleWithExitStyled justifyContent={'space-between'} alignItems={'center'}>
                    <EditorSideMenuTitleStyled>{'Page properties'}</EditorSideMenuTitleStyled>
                    <IconButton onClick={handleClose}>
                        <Close/>
                    </IconButton>
                </PagePropertiesSideMenuTitleWithExitStyled>

                <EditorSideMenuDividerStyled/>

                <EditorSideMenuDescriptionStyled>{'Orientation'}</EditorSideMenuDescriptionStyled>
                <RadioGroup value={properties.data.orientation} onChange={(event, value) => {
                    setProperties({
                        ...properties,
                        data: {...properties.data, orientation: value as TPageOrientation}
                    })
                }}>
                    <FormControlLabel value={'portrait'} control={<Radio />} label={"Portrait"} />
                    <FormControlLabel value={'landscape'} control={<Radio />} label={"Landscape"} />
                </RadioGroup>

                <EditorSideMenuDividerStyled/>

                <EditorSideMenuDescriptionStyled>{'Page size'}</EditorSideMenuDescriptionStyled>
                <FormControl fullWidth>
                    <InputLabel id={properties.id + 'size'}>Page size</InputLabel>
                    <Select size={"small"} label={"Page size"}
                            labelId={properties.id + 'size'}
                            value={properties.data.size}
                            onChange={(event) => {
                                setProperties({
                                    ...properties,
                                    data: {...properties.data, size: event.target.value as TPageSizeTitle}
                                })
                            }}
                    >
                        {Object.keys(PAGE_SIZES).map((key) => (
                            <MenuItem key={key} value={key}>{`${key} (${PAGE_SIZES[key as TPageSizeTitle][0]}" × ${PAGE_SIZES[key as TPageSizeTitle][1]}")`}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <EditorSideMenuDividerStyled/>

                <EditorSideMenuDescriptionStyled>{'Margins (inches)'}</EditorSideMenuDescriptionStyled>
                <PagePropertiesSideMenuMarginRowStyled>
                    <PagePropertiesSideMenuMarginTextFieldStyled label={'Margin top'} value={getRoundedValue(properties.data.marginTop / DPI)} onChange={onChange("marginTop")}/>
                    <PagePropertiesSideMenuMarginTextFieldStyled label={'Margin bottom'} value={getRoundedValue(properties.data.marginBottom / DPI)} onChange={onChange("marginBottom")}/>
                </PagePropertiesSideMenuMarginRowStyled>
                <PagePropertiesSideMenuMarginRowStyled>
                    <PagePropertiesSideMenuMarginTextFieldStyled label={'Margin left'} value={getRoundedValue(properties.data.marginLeft / DPI)} onChange={onChange("marginLeft")}/>
                    <PagePropertiesSideMenuMarginTextFieldStyled label={'Margin right'} value={getRoundedValue(properties.data.marginRight / DPI)} onChange={onChange("marginRight")}/>
                </PagePropertiesSideMenuMarginRowStyled>
            </>
        // </ClickAwayListener>
    ) : null
}