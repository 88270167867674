import {genericPageGenericRowPropsType} from "../../types";
import {useMenu} from "../../../../hooks/useMenu";
import {
    Button,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import {DEBUG, EMPTY_VALUE_REPLACER, MENU_ITEM_SPINNER_SIZE} from "../../constants";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import colors from "../../../../theme/colors";
import React, {MouseEventHandler} from "react";
import {LocalSpinner} from "../../../Spinner";
import {GenericTableTooltipWithoutArrow} from "../styled";

export function DynamicRow<T extends Object>({row, configs, onRowClick, isSelected, isPageBreak, page, index, isAlreadyExists, getRowId, rowActionsConfig, customCellPaddings, markRow}: genericPageGenericRowPropsType<T> & {isPageBreak: boolean, page: number, index: number, isAlreadyExists: boolean}){
    // console.log(`ROW INIT`, new Date().toISOString());
    const rowId = getRowId(row);
    const menu = useMenu();

    return(
        <TableRow selected={isSelected} hover onClick={(e) => {onRowClick(row)}} data-row={`row-${index}`} id={rowId} sx={{position: 'relative'}}>
            {/*{addCustomCellsBefore && addCustomCellsBefore.ColumnsRenderer(row)}*/}
            {/*{isPageBreak &&*/}
            {/*    <Flex zi={'1000'} position={'absolute'} background={colors.stroke.table}>*/}
            {/*        <Typography fontSize={'10px'} fontWeight={400}>Page - {page}</Typography>*/}
            {/*    </Flex>*/}
            {/*}*/}

            {configs.map(e => {
                let valueGetterValue = e.valueGetter && e.valueGetter(row, e.key, true);
                if(!valueGetterValue || !valueGetterValue.trim().length) valueGetterValue = EMPTY_VALUE_REPLACER;

                let fallBackValue = EMPTY_VALUE_REPLACER;
                if(!e.valueRenderer && !e.valueGetter){
                    const entry = Object.entries(row).find(f => f[0] === e.key);
                    if(entry && entry[1] && entry[1] !== undefined && entry[1] !== null){
                        if(typeof entry[1] === 'string'){
                            fallBackValue = entry[1].trim().length > 0 ? entry[1] : fallBackValue;
                        }
                        fallBackValue = String(entry[1]);
                    }
                }

                return(
                    <TableCell key={String(e.key)} sx={{backgroundColor: (DEBUG && isPageBreak) ? 'red' : undefined, padding: customCellPaddings, whiteSpace: !e.disableNoWrapTable ? 'nowrap' : undefined}}>
                        {DEBUG && e.valueGetter && <Typography variant={'subtitle1'} noWrap sx={{overflow: 'hidden', textOverflow: 'ellipsis'}} color={DEBUG && isAlreadyExists ? 'purple' : colors.grayText}>
                            {isPageBreak ?
                                <b style={{color: 'red !important'}}>{page} {' .PAGE '}</b>
                                :
                                <b style={{color: 'blue !important'}}>{index} {' .INDEX '}</b>
                            }
                        </Typography>}

                        {e.valueGetter && valueGetterValue}
                        {e.valueRenderer && e.valueRenderer(row, e.key, true, markRow)}
                        {!e.valueRenderer && !e.valueGetter && fallBackValue}
                    </TableCell>
                )
            })}
            {/*{addCustomCellsAfter && addCustomCellsAfter.ColumnsRenderer(row)}*/}

            {rowActionsConfig && rowActionsConfig.length === 1 &&
                <TableCell sx={{padding: customCellPaddings}}>
                    {/*{rowActionsConfig.filter(e => !e.dontShow || (e.dontShow && !e.dontShow(row))).map(e => {*/}
                    {/*    const isDisabled = e.disabled ? e.disabled(row) : false;*/}
                    {/*    const onClick: MouseEventHandler = (event) => {*/}
                    {/*        event.stopPropagation();*/}
                    {/*        if(!isDisabled){*/}
                    {/*            markRow(row);*/}
                    {/*            e.onClick(row);*/}
                    {/*        }*/}
                    {/*    };*/}

                    {/*    if(e.icon){*/}
                    {/*        return <GenericTableTooltipWithoutArrow title={e.title}>*/}
                    {/*            <span onClick={onClick}>*/}
                    {/*                <IconButton size={'small'} disabled={e.disabled ? e.disabled(row) : false} onClick={onClick}>{e.icon}</IconButton>*/}
                    {/*            </span>*/}
                    {/*        </GenericTableTooltipWithoutArrow>*/}
                    {/*    }else{*/}
                    {/*        return <Button variant={e.buttonProps?.variant ?? 'text'} color={e.buttonProps?.color ?? 'primary'} size={'small'} onClick={onClick}>{e.title}</Button>*/}
                    {/*    }*/}
                    {/*})}*/}

                    {rowActionsConfig.map(e => {
                        if (e.dontShow && e.dontShow(row)) return '';
                        else {
                            const isDisabled = e.disabled ? e.disabled(row) : false;
                            const onClick: MouseEventHandler = (event) => {
                                event.stopPropagation();
                                if(!isDisabled){
                                    markRow(row);
                                    e.onClick(row);
                                }
                            };

                            if(e.icon){
                                return <GenericTableTooltipWithoutArrow key={e.title} title={e.title}>
                                <span onClick={onClick}>
                                    <IconButton size={'small'} disabled={e.disabled ? e.disabled(row) : false} onClick={onClick}>{e.icon}</IconButton>
                                </span>
                                </GenericTableTooltipWithoutArrow>
                            }else{
                                return <Button key={e.title} variant={e.buttonProps?.variant ?? 'text'} color={e.buttonProps?.color ?? 'primary'} size={'small'} onClick={onClick}>{e.title}</Button>
                            }
                        }
                    })}
                </TableCell>
            }
            
            {rowActionsConfig && rowActionsConfig.length > 1 &&
                <TableCell sx={{padding: customCellPaddings}}>
                    { (rowActionsConfig.every(e => e.dontShow && e.dontShow(row))) ?
                        <></>
                        :
                        <>
                            <IconButton
                                size={'small'}
                                aria-label="more"
                                id="long-button"
                                aria-controls={menu.open ? 'long-menu' : undefined}
                                aria-expanded={menu.open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={(e) => {
                                    if(menu.open){
                                        menu.handleClose();
                                    }else{
                                        e.stopPropagation()
                                        menu.handleClick(e);
                                        markRow(row);
                                    }
                                }}
                                color={"primary"}
                            >
                                {<MoreHorizIcon />}
                            </IconButton>

                            <Menu
                                id="long-menu"
                                MenuListProps={{'aria-labelledby': 'long-button',}}
                                anchorEl={menu.anchorEl}
                                open={menu.open}
                                onClose={menu.handleClose}
                                onBackdropClick={(e) => {
                                    e.stopPropagation()
                                    menu.handleClose();
                                }}
                                PaperProps={{
                                    style: {
                                        boxShadow: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1)',
                                        borderRadius: '6px',
                                        border: `1px solid ${ colors.stroke.table}`
                                    },
                                }}
                                sx={{
                                    '& .MuiMenu-list': {
                                        padding: '4px 0'
                                    },
                                    '& .MuiMenuItem-root': {
                                        margin: '0 auto',
                                        '&:hover': {
                                            backgroundColor: colors.backgrounds.grey_light
                                        }
                                    }
                                }}
                            >
                                {rowActionsConfig.map(e => {
                                    const isDisabled = e.disabled ? e.disabled(row) : false;
                                    const isLoading = e.loading ? e.loading(row) : false;
                                    const isDontShow = e.dontShow ? e.dontShow(row) : false;

                                    return(
                                        isDontShow ? <></> :
                                            <MenuItem disabled={isDisabled || isLoading} key={e.title} onClick={(event) => {
                                                event.stopPropagation();
                                                markRow(row);
                                                e.onClick(row);
                                                menu.handleClose();
                                            }}>
                                                {e.icon &&
                                                    <ListItemIcon>
                                                        {isLoading ? <LocalSpinner width={MENU_ITEM_SPINNER_SIZE} height={MENU_ITEM_SPINNER_SIZE} /> : e.icon}
                                                    </ListItemIcon>
                                                }
                                                <ListItemText>
                                                    <Typography variant={'body2'} color={colors.grayText}>{e.title}</Typography>
                                                </ListItemText>
                                            </MenuItem>
                                    )
                                })}
                            </Menu>
                        </>
                    }

                </TableCell>
            }
        </TableRow>
    )
}
