import {DocumentExecutorGroupModel, DocumentExecutorStepModel} from "../../../../../../GQLTypes";
import {useApproverStep} from "./hook";
import {
    ActionsContainer,
    ActorEmailTypography,
    CollapsableContainer,
    GroupContainer,
    GroupNameText,
    RequiredTypography,
    StatusRoundBox,
    StatusTableCellContainer,
    StatusTypography,
    StepContainer,
    StepText
} from "./styled";
import {Button, IconButton, Typography} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
    IN_PROGRESS_STATUS,
    IStatusProps,
    REJECTED_STATUS,
    TDocumentStatus,
    TEditorActorsConfigFromDB
} from "../../../../types";
import {useMainTranslation} from "../../../../../../hooks/useMainTranslationHooks/useMainTranslation";
import {getExpiredTitleColor, getStatusColor} from "../../../../helpers";
import {LoadingButton} from "@mui/lab";
import {diffBetweenDateAndNow} from "../../../../../../utils/dateTools";
import {FlexRow} from "../../../editorTitleWithActionsRow/styled";

export type TApproverStepProps = {
    actorsConfig: TEditorActorsConfigFromDB;
    step: DocumentExecutorStepModel;
    id: number,
    isAbleToResendInvite: boolean,
    isAbleToSkip: boolean,
    handleResendInvite: (step: DocumentExecutorStepModel, group: DocumentExecutorGroupModel) => void;
    handleSkip: (step: DocumentExecutorStepModel) => void;
    onChangeEta: () => void;
    isLoading: IStatusProps["isLoading"],
    isMain: boolean,
}
export const ApproverStep = (props: TApproverStepProps) => {
    const {revDir, currentLanguage: {momentLocale}} = useMainTranslation();
    const {step, isAbleToResendInvite, handleResendInvite, handleSkip, isAbleToSkip} = props;
    const {collapsed, toggleCollapse, getGroupName, isSkipDisabled, isExpired} = useApproverStep(props);

    const inProgress = step.groups?.some(e => e.status === IN_PROGRESS_STATUS);
    const expiredTitleColor = getExpiredTitleColor(diffBetweenDateAndNow(step.endDate || '', momentLocale));

    return(
        <StepContainer>
            <CollapsableContainer>
                <StepText>{('Step ') + (props.id + 1)}</StepText>
                <IconButton size={'small'} onClick={toggleCollapse}>
                    {collapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>

                {!isSkipDisabled() && isAbleToSkip && <Button
                    variant={'text'}
                    size={'small'}
                    onClick={() => handleSkip(props.step)}
                >{('Skip')}</Button>}

                {props.isMain && inProgress && isExpired && <LoadingButton
                    variant={"contained"}
                    loading={props.isLoading.isChangingEtaInProgress}
                    size={'small'}
                    onClick={props.onChangeEta}
                >{('Add ETA')}</LoadingButton>}
            </CollapsableContainer>

            {!collapsed && inProgress && (
                <FlexRow alignItems={'center'} mt={'4px'}>
                    <Typography variant={'body2'}>
                        {'ETA: '}
                        <span style={{color: expiredTitleColor.color}}>{expiredTitleColor.title}</span>
                    </Typography>
                </FlexRow>
            )}

            {!collapsed && step.groups?.map(e => {
                const statusColor = getStatusColor(e.status as TDocumentStatus);
                const groupName = getGroupName(e.id);
                return(
                    <GroupContainer key={e.id} color={statusColor}>
                        {groupName && <GroupNameText>{groupName}</GroupNameText>}

                        <StatusTableCellContainer>
                            <StatusRoundBox color={statusColor} revDir={revDir}/>
                            <StatusTypography color={statusColor}>{e.status?.toUpperCase().replaceAll('_', ' ')}</StatusTypography>
                        </StatusTableCellContainer>

                        {e.isRequired ? <RequiredTypography>{('(Required)')}</RequiredTypography> :
                            <RequiredTypography>{('(Not required)')}</RequiredTypography>
                        }

                        {e.actors.map(e => (<ActorEmailTypography key={'actor' + JSON.stringify(e)}>{e.email}</ActorEmailTypography>))}

                        <ActionsContainer>
                            {e.status === REJECTED_STATUS && isAbleToResendInvite && <LoadingButton
                                variant={"contained"}
                                loading={props.isLoading.isReinviting}
                                size={'small'}
                                onClick={() => handleResendInvite(props.step, e)}
                            >{('Resend')}</LoadingButton>}
                        </ActionsContainer>
                    </GroupContainer>
                )
            })}
        </StepContainer>
    )
}