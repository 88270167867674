import * as S from '../styled';
import {useSelector} from "react-redux";
import {dialogs, hideShowClientDetail, kycDictionaries, loadings} from "../../../store/slice";
import {useAppDispatch} from "../../../../../../newShared/redux";
import React from "react";
import {Button, DialogActions, DialogContent, DialogTitle, Table, TableCell, TableRow} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {CommonTableContainer} from "../../styled/index";
import {
    BackTextButton,
    CommonTableBody,
    CommonTableHead,
    CommonTableRow,
    TypographyBody2Grey
} from "../../../../../../newShared/components/style";
import {TypographyBody2} from "../../../../../../newShared/components/Inputs/styled";
import {TableRowSkeleton} from "../../../../../../newShared/components/tableRowSkeleton";
import {ClientDetailsTableCell} from "./style";
import {getFieldValue} from '../../../helpers';


export const ShowClientDetailDialog = () => {

    const {t, tCommon, revDir} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.dialogs'});

    const dispatch = useAppDispatch();
    const {isOpen, fields, isOTS} = useSelector(dialogs).showClientDetail;
    const {isLoadingExact} = useSelector(loadings);
    const dicts = useSelector(kycDictionaries);
    const handleClose = () => {
        dispatch(hideShowClientDetail());
    }


    return (
        <S.CommonDialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Client details')}</DialogTitle>
            <DialogContent>
                <CommonTableContainer>
                    <Table stickyHeader>
                        <CommonTableHead>
                            <CommonTableRow>
                                <TableCell>
                                    <TypographyBody2Grey>{t('Field name')}</TypographyBody2Grey>
                                </TableCell>
                                <TableCell>
                                    <TypographyBody2Grey>{t('Value')}</TypographyBody2Grey>
                                </TableCell>
                            </CommonTableRow>
                        </CommonTableHead>
                        <CommonTableBody>
                            {fields && fields
                                .slice()
                                .sort((a, b) => a.order - b.order)
                                .map(e => (
                                <TableRow key={e.id}>
                                    <ClientDetailsTableCell>
                                        <TypographyBody2>{e.name}</TypographyBody2>
                                    </ClientDetailsTableCell>
                                    <ClientDetailsTableCell>
                                        <TypographyBody2>
                                            {getFieldValue(e, dicts)}
                                        </TypographyBody2>
                                    </ClientDetailsTableCell>
                                </TableRow>
                            ))}
                            {isLoadingExact && <TableRowSkeleton columns={2} />}
                        </CommonTableBody>
                    </Table>
                </CommonTableContainer>

            </DialogContent>
            <DialogActions>
                {isOTS ?
                    <BackTextButton revDir={revDir || undefined} onClick={handleClose}>{t('Back to screening')}</BackTextButton>
                    :
                    <Button onClick={handleClose} variant={'text'} sx={{textTransform: 'none'}}>{tCommon('Close')}</Button>
                }

            </DialogActions>
        </S.CommonDialog>
    )
}
