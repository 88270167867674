import {useDispatch, useSelector} from "react-redux";
import {loadings, managers} from "../../store/slice";
import {AutocompleteManagers} from "../../store/actions";
import {useEffect} from "react";

export const useManagersAutocomplete = () => {
    const dispatch = useDispatch();
    const isLoadingManagers = useSelector(loadings).getManagers;

    useEffect(() => {
        dispatch(AutocompleteManagers({workspaceId: '', organizationId: '', text: '', onlyActive: false}));
        //eslint-disable-next-line
    }, []);

    return{
        managers: useSelector(managers),
        isLoadingManagers,
        getManagers: (text: string) => {dispatch(AutocompleteManagers({workspaceId: '', organizationId: '', text, onlyActive: false}))},
    }
}