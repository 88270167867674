import React, {FC, useCallback} from "react";
import {ItemTypes, TEditorRecipient, TEditorStageApprover} from "../../../../../types";
import {useMainTranslation} from "../../../../../../../hooks/useMainTranslationHooks/useMainTranslation";
import {useDrop} from "react-dnd";
import * as S from "../../styled";
import {EditorManageActorsDialogStyled} from "../../styled";
import {
    AddVariableDialogActionsStyled,
    AddVariableDialogContentStyled,
    AddVariableDialogTitleStyled
} from "../../../addVariableDialog/styled";
import CommonSwitch from "../../../../../../Basic/CommonSwitch";
import {ApproversBlockViewEditor} from "./approversBlockView";
import {TypographyBody2, TypographySubtitle1} from "../../../../../../Inputs/styled";
import {Button, Checkbox} from "@mui/material";
import {ReactComponent as AddRecipientIcon} from "../../../../../../../../newShared/images/documents/add-recipient.svg";
import {RecipientItemEditor} from "./recipientItem";
import {useManageActorsDialogStaff} from "../../../../../hooks/useManageActorsDialogStaff";
import {EditorSideMenu} from "../../../../editorSideMenu";

export type TManageActorsDialogNormalProps = {
    parentRef: React.RefObject<EditorSideMenu>["current"];
    isStaff?: boolean;
}

export type TIdEditorRecipient = TEditorRecipient & {
    id: string;
    sourceEmailRole: string
}

export type TForm = {
    approvers: TEditorStageApprover[],
    recipients:  TIdEditorRecipient[],
}

export const ManageActorsDialogStaff: FC<TManageActorsDialogNormalProps> = (props) => {
    const {isStaff} = props;
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});

    const {
        isOpen,
        form,
        setForm,
        isApproversBlock,
        setIsApproversBlock,
        isRecipientsCustomOrder,
        isApproversCustomOrder,
        setIsApproversCustomOrder,
        // isLoadingEmployees,
        // employees,
        //
        // getEmployees,
        handleAddRecipient,
        handleOpenAddMessage,
        handleToggleRecipientOrder,
        handleDeleteRecipient,
        handleSetActorRecipient,
        // handleSetIsOpenByEvent,
        handleClose,
        handleSave,
        isDisabledSave,
        // handleSetGroupMessage,
        // handleHideAddGroupMessage,
    } = useManageActorsDialogStaff(props)

    //DND
    const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));
    const setRecipients = useCallback(
        (cols: TIdEditorRecipient[]) => {
            setForm({
                ...form,
                recipients: cols.map((e, i) => ({...e, order: i})),
            })
            //eslint-disable-next-line
        }, [form]
    )
    const findColumn: (id: string) => {column: TIdEditorRecipient, index: number} | null = useCallback(
        (id: string) => {
            const column = form.recipients.find(e => e.id === id);
            // console.log(`findColumn for ${key} - ${JSON.stringify(column)} | ${column ? settings.indexOf(column) : null}`);
            return column ? {
                column,
                index: form.recipients.indexOf(column)
            } : null;
        },
        [form.recipients],
    )

    const moveColumn: (key: string, atIndex: number) => void = useCallback(
        (key: string, atIndex: number) => {
            const res = findColumn(key);
            if(res){
                //res - {column: lastName, index: 1}
                //atIndex - 0
                const copyArr = JSON.parse(JSON.stringify(form.recipients));
                // console.log(`copyArr: ${JSON.stringify(copyArr)}`);
                copyArr.splice(res.index, 1) //remove
                copyArr.splice(atIndex, 0, res.column) //insert
                // const reordered = settings.splice(res.index, 1).splice(atIndex, 0, res.column);
                // console.log(`moveColumn for ${key} settings.splice(${res.index}, 1).splice(${atIndex}, 0, ${JSON.stringify(res.column)}) | \n newArr: new arr: ${JSON.stringify(copyArr)}`)
                setRecipients(copyArr);
                // console.log(`moveColumn for ${key} - from ${res?.index} | to ${atIndex} \n new arr: ${JSON.stringify(reordered)}`);
            }else{
                // console.log(`--Column with key ${key} not found!`);
            }
        },
        [findColumn, form.recipients, setRecipients],
    )



    return(
        <EditorManageActorsDialogStyled open={isOpen} onClose={handleClose}>
            <AddVariableDialogTitleStyled>
                Manage recipients and approvers
            </AddVariableDialogTitleStyled>

            <AddVariableDialogContentStyled>
                <>
                    <S.InfoViewContainer>
                        {/*APPROVERS BLOCK*/}
                        <S.FlexBox>
                            <CommonSwitch disabled={isStaff} label={t('Set approval workflow')} checked={isApproversBlock} onChange={() => setIsApproversBlock(!isApproversBlock)} />
                        </S.FlexBox>

                        {isApproversBlock &&
                            <ApproversBlockViewEditor
                                employees={[]}
                                isLoadingEmployees={false}
                                getEmployees={() => null}
                                form={form}
                                setForm={setForm}
                                handleOpenAddGroupMessage={handleOpenAddMessage}
                                isApproversCustomOrder={isApproversCustomOrder}
                                setIsApproversCustomOrder={setIsApproversCustomOrder}
                                isStaff={isStaff}
                            />}

                        <S.DocDivider />


                        {/*RECIPIENTS BLOCK*/}
                        <TypographySubtitle1>{t('Add recipients who need to fill out and (or) sign this document')}</TypographySubtitle1>
                        <S.FlexBox>
                            <Checkbox disabled={isStaff} checked={isRecipientsCustomOrder} onChange={() => handleToggleRecipientOrder(!isRecipientsCustomOrder)} />
                            <TypographyBody2>{t('Custom recipients order')}</TypographyBody2>
                        </S.FlexBox>
                        <div ref={!isStaff ? drop : undefined} style={{width: '100%'}}>
                            {form.recipients.map((r, index) => (
                                <RecipientItemEditor
                                    key={r.id}
                                    index={index}
                                    form={form}
                                    moveColumn={moveColumn}
                                    findColumn={findColumn}
                                    isRecipientsCustomOrder={isRecipientsCustomOrder}
                                    recipient={r}
                                    handleDelete={handleDeleteRecipient}
                                    handleSetActorRecipient={handleSetActorRecipient}
                                    isLoadingEmployees={false}
                                    employees={[]}
                                    getEmployees={() => null}
                                    handleOpenAddMessage={handleOpenAddMessage}
                                    isStaff={isStaff}
                                />
                            ))}

                        </div>

                        {!isStaff &&
                            <Button variant={'text'} sx={{textTransform: 'none', mt: '16px'}}
                                    startIcon={<AddRecipientIcon/>}
                                    onClick={handleAddRecipient}>{t('Add Recipient')}</Button>
                        }
                    </S.InfoViewContainer>

                </>


            </AddVariableDialogContentStyled>

            <AddVariableDialogActionsStyled>
                <Button variant={"text"} onClick={handleClose}>{'Cancel'}</Button>
                <Button variant={"contained"} onClick={handleSave} disabled={isDisabledSave || isStaff}>{'Save'}</Button>
            </AddVariableDialogActionsStyled>
            {/*<AddGroupMessageDialog handleSetGroupMessage={handleSetGroupMessage} handleClose={handleHideAddGroupMessage} />*/}
        </EditorManageActorsDialogStyled>
    )
}