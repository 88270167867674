import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDispatch, useSelector} from "react-redux";
import {editFrameworkDialog, hideEditFramework, isLoading} from "../../../store/builder/slice";
import React, {useEffect, useState} from "react";
import {frameworkType} from "../../../types";
import {UpdateCustomFramework} from "../../../store/builder/actions";
import GIXI from "../../../helpers/gixi";
import {LOGO_MAX_SIZE} from "../../../constants";
import {toBase64} from "../../../../../../newShared/utils/base64/base64";
import {DEFAULT_LOGO_URL} from "../../../../../authWorkspacesCookies/settings/constants";


export const useEditFramework = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'builderPage.dialogs'});
    const dispatch = useDispatch();
    const {isOpen, framework} = useSelector(editFrameworkDialog);

    // const [name, setName] = useState<string>('');
    const [tmpFramework, setTmpFramework] = useState<frameworkType | null>(framework);
    const [fileName, setFileName] = useState<string>('logo');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        if(!isOpen) {
            setTmpFramework(null);
            setErrorMessage(null);
            setTmpFramework(null);
        }
        if (isOpen) {
            setFileName('logo');
            setTmpFramework(framework);

        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const handleSave = () => {
        if(validate()){
            tmpFramework && dispatch(UpdateCustomFramework({framework: tmpFramework}));
        }
    }

    // const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
    //     if (reason !== 'backdropClick') {
    //         dispatch(hideEditFramework());
    //         setTmpFramework(null);
    //     }
    // };

    const handleClose = () => {
        dispatch(hideEditFramework());
        setTmpFramework(null);
    };

    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setTmpFramework((prev) => prev && ({
            ...prev,
            [event.target.name]: event.target.value as string,
        }));
    };

    // const handleChangeName = (value: string) => {
    //     setTmpFramework(prev => prev && ({...prev, name: value}));
    // }
    // const handleChangeType = (value: string) => {
    //     setTmpFramework(prev => prev && ({...prev, type: value}));
    // }
    //
    // const handleChangeDescription = (value: string) => {
    //     setTmpFramework(prev => prev && ({...prev, description: value}));
    // }

    const validate = () => {
        return (
            tmpFramework && framework && tmpFramework.logo
            && JSON.stringify(tmpFramework) !== JSON.stringify(framework)
            && tmpFramework.name.trim().length > 0
            && tmpFramework.logo.trim().length > 0
        )
    }

    const handleGenerateLogo = () => {
        const pixLogo = new GIXI(128).getImage();
        setTmpFramework(prev => prev && ({...prev, logo: pixLogo}));
        setFileName('generated logo' );
    }

    const handleDeleteLogo = () => {
        setTmpFramework(prev => prev && ({...prev, logo: DEFAULT_LOGO_URL}))
        setFileName('');
    }

    const setLogo = async(file: File) => {
        if(file && file.size > LOGO_MAX_SIZE){
            // dispatch(addErrorSnack(errorMessage));
            setErrorMessage(t('The size of your file is more than 1 MB. Try uploading another file up to 1 MB in size'))
            return;
        }
        setErrorMessage(null);
        setFileName(file.name);
        const fileBase64: string = await toBase64(file) as string;
        setTmpFramework((prev) => prev && ({...prev, logo: fileBase64}));
    }

    return {
        isOpen,
        tmpFramework,
        handleSave,
        handleClose,
        handleChange,
        // handleChangeName,
        // handleChangeType,
        // handleChangeDescription,
        fileName,
        handleGenerateLogo,
        handleDeleteLogo,
        setLogo,
        errorMessage,
        validate,
        isLoading: useSelector(isLoading),
    }
}