import {Box, Divider, List, styled, Typography} from "@mui/material";
import {TREE_NESTED_SHIFT_PX} from "../../../constants";
import colors from "../../../../../../newShared/theme/colors";


export const LeftList = styled(List)(() => ({
    overflow: 'auto',
    marginTop: '16px',
    maxWidth: '310px'
}));
export const FolderItemContainer = styled(Box, {
    shouldForwardProp: (propName) => (
        !["revDir", "pathLength"].includes(propName.toString())
    )})(({revDir, pathLength}: {revDir?: true, pathLength: number}) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: !revDir ? `0 0 0 ${pathLength > 1 ? TREE_NESTED_SHIFT_PX : 0}px` : `0 ${pathLength > 1 ? TREE_NESTED_SHIFT_PX : 0}px 0 0`,
    width: `calc(100% - ${pathLength > 1 ? TREE_NESTED_SHIFT_PX : 0}px)`
}));

export const FolderFlexBox = styled(Box, {
    shouldForwardProp: (propName) => (
        !["isSelected", "isDisabled"].includes(propName.toString())
    )})(({isDisabled, isSelected}: {isDisabled?: true, isSelected?: true}) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    backgroundColor: isSelected ? colors.backgrounds.blue_light_1 : 'initial',
    "&:hover": {
        backgroundColor: !isDisabled && !isSelected ? colors.backgrounds.grey_light : undefined,
    }
}));

export const LeftMenuDivider = styled(Divider)(() => ({
    color: colors.text.grey_light_2,
    margin: '16px 0',
}));

export const LeftTitleDocument = styled(Typography, {
    shouldForwardProp: (propName) => (
        !["revDir", "isDisabled"].includes(propName.toString())
    )})(({revDir, isDisabled}: {revDir?: true, isDisabled?: true}) => ({
    margin: !revDir ? '0 0 0 10px' : '0 10px 0 0',
    cursor: !isDisabled ? 'pointer' : undefined,
}));

LeftTitleDocument.defaultProps = {
    variant: 'body1'
}

export const LeftTitleInnerDocument = styled(Typography, {
    shouldForwardProp: (propName) => (
        !["revDir", "isDisabled"].includes(propName.toString())
    )})(({revDir, isDisabled}: {revDir?: true, isDisabled?: true}) => ({
    margin: !revDir ? '0 0 0 10px' : '0 10px 0 0',
    cursor: !isDisabled ? 'pointer' : undefined,
}));

LeftTitleInnerDocument.defaultProps = {
    variant: 'bodySmall'
}
