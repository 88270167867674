import {Flex} from "../../../../../newShared/components/Layouts";
import {tControl, TRisk, TRiskModel, TRiskModelProbability} from "../../types";
import {Avatar, AvatarGroup, Button, Divider, IconButton, TextField, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import React from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {Breadcrumbs} from "../../../../barsEnvironment/breadcrumbs";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import CommonSwitch from "../../../../../newShared/components/Basic/CommonSwitch";
import EditIcon from "@mui/icons-material/Edit";
import {IconTextButton} from "../../../../../newShared/components/Basic/CommonButtons";
import DeleteIcon from "@mui/icons-material/Delete";
import * as S from '../styled';
import {AutoComplete, RightSideContainerCustomDatePicker, TextFieldCustom} from '../styled';
import {useExactRegistryRightSide} from "../../hooks/useExactRegistryRightSide";

import {LoadingButton} from "@mui/lab";
import {controls, firstNoneObject} from "../../constants";
import {
    getInitialGradeProbabilityLabel,
    getResidualGradeImpactValue,
    getResidualGradeProbabilityValue,
    isNullObject
} from "../../helpers";
import {AddControlOwner} from "../dialogs/addControlOwner";
import EastIcon from "@mui/icons-material/East";
import {getAvatarInitials} from "../../../../../newShared/utils/text";
import {SaveRisk} from "../dialogs/saveRisk";
import {AddAvatarButton} from "../../../../../newShared/components/Basic/AddAvatarButton";
import CloseIcon from "@mui/icons-material/Close";
import {DEFAULT_DATE_FORMAT} from "../../../../../newShared/utils/dateTools";


export const ExactRegistryRightSide = ({risk, model}: {risk: TRisk | null, model?: TRiskModel}) => {
    const {isMobile, isTablet, matches_1513Down} = useMedia();
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'risks'});

    const {
        actions,
        editableRisk,
        loadings,
        riskRate,
        riskRateColor,
        isQuantitative,
        isEditMode,
        residualRiskRate,
        residualRiskRateColor,

        initialGradeProbabilityValue,
        initialGradeImpactValue,
        riskTreatmentControlValue,
    } = useExactRegistryRightSide({risk, model});


    return(
        (!editableRisk || !risk || !model) ? <>NO RISK</>
            :
            <>
                {!isTablet && !isMobile && <Divider orientation="vertical" flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable}} />}
                <Flex direction={'column'} w={(!isTablet && !isMobile) ? '95%' : '100%'} overflowy={'auto'}>
                    {isTablet &&
                        <>
                            <Breadcrumbs/>
                            <Flex w={'100%'} m={'11px 0 0 0'} ai={'center'}>
                                {!revDir ?
                                    <KeyboardBackspaceIcon sx={{color: colors.text.blue, cursor: 'pointer'}} onClick={actions.handleClose}/>
                                    :
                                    <EastIcon sx={{color: colors.text.blue, cursor: 'pointer'}} onClick={actions.handleClose}/>
                                }
                                <Flex maxw={'40%'}>
                                    <Typography variant={isTablet ?  'h3' : "h1"}  sx={{margin: !revDir ? '0 0 0 11px' : '0', color: colors.backgrounds.dark, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{risk.name}</Typography>
                                </Flex>
                                <Typography variant={"body2"} sx={{color: colors.text.grey_dark, margin: !revDir ? '0 0 0 16px' : '0 16px 0 0'}}>{t('Type')}</Typography>
                                <Typography variant={"body2"} sx={{color: colors.grayText, margin: !revDir ? '0 0 0 8px' : '0 8px 0 0'}}>{risk.type}</Typography>
                            </Flex>
                        </>
                    }

                    {!isTablet &&
                        <Flex ai={'center'} jc={'space-between'}>
                            <Flex ai={'center'}>
                                <Typography variant={"subtitle2"} sx={{color: colors.text.grey_dark}}>{risk.name}</Typography>
                                <Typography variant={"body2"} sx={{color: colors.text.grey_dark, margin: !revDir ? '0 0 0 16px' : '0 16px 0 0'}}>{t('Type')}</Typography>
                                <Typography variant={"body2"} sx={{color: colors.grayText, margin: !revDir ? '0 0 0 16px' : '0 16px 0 0'}}>{risk.type}</Typography>
                            </Flex>

                            <IconButton onClick={actions.handleClose}>
                                <CloseIcon/>
                            </IconButton>
                        </Flex>
                    }

                    <Typography variant={"body2"} sx={{color: colors.grayText, margin: '16px 0 0 0'}}>{risk.description}</Typography>

                    <S.ExactRegistryRightSideSwitchContainer>
                        <Typography variant={"body2"} sx={{color: colors.text.grey_dark}}>{t('You can represent all values as numbers')}</Typography>
                        <CommonSwitch label={t('Quantitative')} checked={isQuantitative} onChange={actions.handleChangeRisksQuantitative} />
                    </S.ExactRegistryRightSideSwitchContainer>

                    <Flex ai={'center'}>
                        <IconTextButton
                            variant={'text'}
                            disabled={!risk}
                            icon={<EditIcon fontSize="small"/>}
                            onClick={actions.handleEditRisk}
                            sx={{
                                '& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}
                            }}
                        >
                            <Typography fontWeight={600} fontSize={matches_1513Down ? '13px' : '15px'}>{t('Edit')}</Typography>
                        </IconTextButton>

                        <IconTextButton
                            variant={'text'}
                            disabled={!risk}
                            icon={<DeleteIcon fontSize="small"/>}
                            onClick={actions.handleDeleteRisk}
                            sx={{
                                '& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}
                            }}
                        >
                            <Typography fontWeight={600} fontSize={matches_1513Down ? '13px' : '15px'}>{t('Delete')}</Typography>
                        </IconTextButton>
                    </Flex>

                    <Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable, margin: '16px 0 0 0'}}/>


                    {isEditMode &&
                        <Flex ai={'center'} m={'32px 0 5px 0'}>
                            {loadings.riskEdit ?
                                <LoadingButton
                                    loading
                                    sx={{paddingLeft: '35px', margin: !revDir ? '0 16px 0 0' : '0 0 0 16px', height: '36px'}}
                                    variant="outlined"
                                >{t('Save')}</LoadingButton>
                                :
                                <Button
                                    variant={'contained'}
                                    size={isMobile ? 'small' : 'medium'}
                                    onClick={actions.handleUpdateRisk}
                                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                >{t('Save')}</Button>
                            }
                            <Button disabled={loadings.riskEdit} variant={'outlined'} size={isMobile ? 'small' : 'medium'} onClick={actions.handleCancelUpdateRisk} >{t('Cancel')}</Button>
                        </Flex>
                    }
                    <S.ExactRegistryRightSideInputsWrapper>
                        <Typography variant={'subtitle2'} color={colors.text.grey_dark}>{t('Initial grade')}</Typography>
                        <AutoComplete
                            disabled={loadings.modelsList} //loading
                            id="combo-box-demo"
                            fullWidth
                            options={[firstNoneObject, ...model.probabilities] as TRiskModelProbability[]}
                            getOptionLabel={(option) => isNullObject(option) ? t('None') : getInitialGradeProbabilityLabel(option, isQuantitative)}
                            value={initialGradeProbabilityValue}
                            disableClearable
                            dir={'ltr'}
                            onChange={(e, newValue) => actions.handleSetInitialGradeProbability(newValue as TRiskModelProbability | null)}
                            sx={{margin: '16px 0 0 0'}}
                            renderInput={(params: any) => <TextField {...params} size="small" label={t("Probability")} />}
                        />

                        <AutoComplete
                            disabled={loadings.modelsList} //loading
                            id="combo-box-demo"
                            fullWidth
                            options={[firstNoneObject, ...model.impacts] as TRiskModelProbability[]}
                            getOptionLabel={(option) => isNullObject(option) ? t('None') : getInitialGradeProbabilityLabel(option, isQuantitative)}
                            value={initialGradeImpactValue}
                            disableClearable
                            dir={'ltr'}
                            onChange={(e, newValue) => actions.handleSetInitialGradeImpact(newValue as TRiskModelProbability | null)}
                            sx={{margin: '16px 0 0 0'}}
                            renderInput={(params: any) => <TextField {...params} size="small" label={t("Impact")} />}
                        />

                        <Flex ai={'center'} m={'16px 0 0 0'}>
                            <Typography variant={"body2"} sx={{color: colors.text.grey_dark, margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}>{t('Rating')}</Typography>
                            <Typography variant={"body2"} sx={{color: riskRateColor}}>{isQuantitative ? ((editableRisk?.initialGrade?.probability ?? 0) * (editableRisk?.initialGrade?.impact ?? 0)) : t(riskRate)}</Typography>
                        </Flex>
                    </S.ExactRegistryRightSideInputsWrapper>

                    <Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable, margin: '16px 0 0 0'}}/>

                    <S.ExactRegistryRightSideInputsWrapper>
                        <Typography variant={'subtitle2'} color={colors.text.grey_dark} sx={{margin: '16px 0 0 0'}}>{t('Risk treatment')}</Typography>
                        <AutoComplete
                            disabled={loadings.modelsList} //loading
                            id="combo-box-demo"
                            fullWidth
                            options={controls as tControl[]}
                            getOptionLabel={(option) => t((option as tControl).type)}
                            value={riskTreatmentControlValue}
                            disableClearable
                            dir={'ltr'}
                            onChange={(e, newValue) => actions.handleSetControlType(newValue as tControl | null)}
                            isOptionEqualToValue={(e, newValue) => (e as tControl).type === (newValue as tControl).type}
                            sx={{margin: '16px 0 0 0'}}
                            renderInput={(params: any) => <TextField {...params} size="small" label={t("Control")} />}
                        />
                        {(riskTreatmentControlValue.type ?? '') !== 'None' &&
                            <>
                                <RightSideContainerCustomDatePicker
                                    label={t('Scheduler')}
                                    inputFormat={DEFAULT_DATE_FORMAT}
                                    value={editableRisk.control.scheduler}
                                    onChange={(newValue) => newValue && actions.handleSetControlScheduler(newValue)}
                                    renderInput={(params) => <TextFieldCustom {...params} sx={{margin: '18px 0 0 0'}} dir={'ltr'}/>}
                                />

                                <Flex w={'100%'} ai={'center'} m={'16px 0 0 0'}>
                                    <Typography variant={"body2"} sx={{color: colors.text.grey_dark, margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}>{t('Owners')}</Typography>
                                    {/*<AvatarGroup*/}
                                    {/*    max={4}*/}
                                    {/*    sx={{*/}
                                    {/*        '& .MuiAvatar-root': {*/}
                                    {/*            maxWidth: '24px', maxHeight: '24px',*/}
                                    {/*            fontSize: '10px',*/}
                                    {/*        }*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    {editableRisk.control.owners.map((e, id, arr) => {*/}
                                    {/*        return(*/}
                                    {/*            <Avatar key={e} sx={{ width: 24, height: 24, backgroundColor: ((id % 2) !== 0) ? colors.primary.blue : colors.text.success}}>*/}
                                    {/*                <Typography color={colors.text.white} fontSize={'10px'} fontWeight={400}>{getAvatarInitials(e)}</Typography>*/}
                                    {/*            </Avatar>*/}
                                    {/*        )*/}
                                    {/*    })}*/}
                                    {/*    <Avatar*/}
                                    {/*        sx={{ width: 24, height: 24, backgroundColor: 'transparent', border: `1px solid ${colors.text.grey_light_2} !important`, cursor: 'pointer'}}*/}
                                    {/*        onClick={actions.handleOpenAddOwners}*/}

                                    {/*    >*/}
                                    {/*        <Typography color={colors.text.grey_light_2} fontSize={'20px'} fontWeight={400}>+</Typography>*/}
                                    {/*    </Avatar>*/}
                                    {/*</AvatarGroup>*/}

                                    {/*{risk.control.owners.length > 3 &&*/}
                                    {/*    <Avatar*/}
                                    {/*        sx={{ width: 24, height: 24, backgroundColor: 'transparent', border: `1px solid ${colors.text.grey_light_2} !important`}}*/}
                                    {/*        onClick={actions.handleOpenAddOwners}*/}
                                    {/*    >*/}
                                    {/*        <Typography color={colors.text.grey_light_2} fontSize={'20px'} fontWeight={400}>+</Typography>*/}
                                    {/*    </Avatar>*/}
                                    {/*}*/}

                                    <>
                                        <AvatarGroup
                                            max={4}
                                            componentsProps={{
                                                additionalAvatar: {
                                                    onClick: (e) => {
                                                        e.stopPropagation();
                                                        actions.handleOpenAddOwners();
                                                    }
                                                }
                                            }}
                                            sx={{
                                                '& .MuiAvatar-root': {
                                                    maxWidth: '24px', maxHeight: '24px',
                                                    fontSize: '10px',
                                                },
                                                '& .MuiAvatar-root:hover': {
                                                    cursor: 'pointer'
                                                },
                                                '& .MuiAvatar-root:last-child': {
                                                    marginLeft: !revDir ? undefined : '-8px'
                                                },
                                            }}
                                        >

                                            {editableRisk.control.owners.map((e, id, arr) => {
                                                return(
                                                    <Avatar key={e} sx={{ width: 24, height: 24, backgroundColor: ((id % 2) !== 0) ? colors.primary.blue : colors.text.success}}>
                                                        <Typography color={colors.text.white} fontSize={'10px'} fontWeight={400}>{getAvatarInitials(e)}</Typography>
                                                    </Avatar> 
                                                )
                                            })}
                                        </AvatarGroup>

                                        <AddAvatarButton onClick={actions.handleOpenAddOwners}
                                                         btnSize={'d24'}
                                                         sx={{marginRight: !revDir ? undefined : '8px'}}
                                        />
                                    </>
                                </Flex>

                                <Typography variant={"body2"} sx={{color: colors.text.grey_dark, margin: !revDir ? '16px 0 0 0' : '16px 0 0 0'}}>{t('Actions')}</Typography>
                                <TextFieldCustom
                                    value={editableRisk.control.actions}
                                    name={'Actions'}
                                    onChange={actions.handleChangeActions}
                                    multiline
                                    dir={'ltr'}
                                    minRows={5}
                                    // size={'small'}
                                    sx={{margin: '8px 0 0 0'}}
                                />
                            </>
                        }
                    </S.ExactRegistryRightSideInputsWrapper>

                    <Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable, margin: '16px 0 0 0'}}/>

                    <S.ExactRegistryRightSideInputsWrapper>
                        <Typography variant={'subtitle2'} color={colors.text.grey_dark}>{t('Residual grade')}</Typography>
                        <AutoComplete
                            disabled={loadings.modelsList} //loading
                            id="combo-box-demo"
                            fullWidth
                            dir={'ltr'}
                            options={[firstNoneObject, ...model.probabilities] as TRiskModelProbability[]}
                            getOptionLabel={(option) => isNullObject(option) ? t('None') : getInitialGradeProbabilityLabel(option, isQuantitative)}
                            value={getResidualGradeProbabilityValue(editableRisk, model)}
                            disableClearable
                            onChange={(e, newValue) => actions.handleSetResidualGradeProbability(newValue as TRiskModelProbability | null)}
                            sx={{margin: '16px 0 0 0'}}
                            renderInput={(params: any) => <TextField {...params} size="small" label={t("Probability")} />}
                        />

                        <AutoComplete
                            disabled={loadings.modelsList} //loading
                            id="combo-box-demo"
                            fullWidth
                            dir={'ltr'}
                            options={[firstNoneObject, ...model.impacts] as TRiskModelProbability[]}
                            getOptionLabel={(option) => isNullObject(option) ? t('None') : getInitialGradeProbabilityLabel(option, isQuantitative)}
                            value={getResidualGradeImpactValue(editableRisk, model)}
                            disableClearable
                            onChange={(e, newValue) => actions.handleSetResidualGradeImpact(newValue as TRiskModelProbability | null)}
                            sx={{margin: '16px 0 0 0'}}
                            renderInput={(params: any) => <TextField {...params} size="small" label={t("Impact")} />}
                        />

                        <Flex ai={'center'} m={'16px 0 0 0'}>
                            <Typography variant={"body2"} sx={{color: colors.text.grey_dark, margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}>{t('Rating')}</Typography>
                            <Typography variant={"body2"} sx={{color: residualRiskRateColor}}>{isQuantitative ? ((editableRisk?.residualGrade?.probability ?? 0) * (editableRisk?.residualGrade?.impact ?? 0)) : t(residualRiskRate)}</Typography>
                        </Flex>
                    </S.ExactRegistryRightSideInputsWrapper>
                </Flex>

                {/*DIALOGS*/}
                <AddControlOwner onSuccess={actions.setControlOwners} risk={editableRisk}/>
                <SaveRisk handleSave={actions.handleUpdateRisk}/>
            </>
    )
}