import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideRunReviewWarning, loadings, selectedInstruction} from "../../../../store/slice";
import {RunCampaignReviewInstruction} from "../../../../store/actions";
import {useHistory} from "react-router-dom";
import {PATH_ACCESS_MANAGEMENT_REVIEW_EXECUTORS_EXACT} from "../../../../constants";


export const useRunReviewWarningDialog = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isOpen = useSelector(dialogs).startCampaignWarning;
    const isLoading = useSelector(loadings).campaignReviewInstructionRun;
    const instruction = useSelector(selectedInstruction);

    const handleClose = () => {
        dispatch(hideRunReviewWarning());
    }

    const handleSubmit = () => {
        instruction && dispatch(RunCampaignReviewInstruction({data: {id: instruction.id, onSuccess}}));
    }

    const onSuccess = (lastExecutorId: string) => {
        history.push(PATH_ACCESS_MANAGEMENT_REVIEW_EXECUTORS_EXACT.replace(':id', lastExecutorId));
    }

    return{
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        instruction,
    }
}