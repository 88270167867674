import {createAsyncThunk} from "@reduxjs/toolkit";
import {AppState} from "../../../../../newShared/redux/rootReducer";
import {getActionsData} from "../../../../../newShared/redux";
import {adoptFrameworkToWorkspaceApi, getAdoptedFrameworksApi, removeFrameworkFromWorkspaceApi} from "../../api";
import {getAvailableFrameworks} from "../../api/query";
import {
    GetAvailableFrameworksQuery,
    GetAvailableFrameworksQueryVariables,
} from "../../../../../newShared/GQLTypes";
import {createCommonAsyncThunk} from "../../../../../newShared/utils/asyncThunk";

export const getAdoptedFrameworksAction = createAsyncThunk(
    'Frameworks/getAdoptedFrameworks',
    async (data: {filter: string}, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await getAdoptedFrameworksApi({organizationId, workspaceId})
            .then(
                result => data.filter.trim() === ''
                    ? result
                    : result.filter(e => (
                        e.name?.toLowerCase().includes(data.filter.toLowerCase())
                        // || e.description?.toLowerCase().includes(data.filter.toLowerCase())
                        // || e.type?.toLowerCase().includes(data.filter.toLowerCase())
                        // || e.createdBy?.toLowerCase().includes(data.filter.toLowerCase())
                    ))
            )
        return {
            data,
            resp
        }
    },
);

// export const getAvailableFrameworksAction = createAsyncThunk(
//     'Frameworks/getAvailableFrameworks',
//     async (data: TWithOptions<{pageInfo: PaginationInput}>, {getState}) => {
//         const state = getState() as AppState;
//         const {workspaceId, organizationId} = getActionsData(state);
//
//         const resp = await getAvailableFrameworksApi({...data.data, organizationId, workspaceId}, data.signal)
//         return {
//             data: data.data,
//             resp,
//             isNewOnTop: false
//         }
//     },
// );

export const getAvailableFrameworksAction =
    createCommonAsyncThunk<GetAvailableFrameworksQueryVariables, GetAvailableFrameworksQuery["getAvailableFrameworks"]>(
    'Frameworks', 'getAvailableFrameworks', true, true,
    getAvailableFrameworks
)

export const adoptFrameworkToWorkspaceAction = createAsyncThunk(
    'Frameworks/adoptFrameworkToWorkspace',
    async (data: {frameworkId: string}, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await adoptFrameworkToWorkspaceApi({...data, organizationId, workspaceId})
        return {
            data,
            resp
        }
    },
);

export const removeFrameworkFromWorkspaceAction = createAsyncThunk(
    'Frameworks/removeFrameworkFromWorkspace',
    async (data: {wfId: string, endFunc: () => void}, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await removeFrameworkFromWorkspaceApi({wfId: data.wfId, workspaceId});
        data.endFunc();
        return {
            data,
            resp
        }
    },
);
