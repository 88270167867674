import {Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React, {FC} from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {LoadingButton} from "@mui/lab";
import {useRunRuleCheckDialog} from "../../../hooks/dialogHooks/useRunRuleCheck";
import {TRulesConflict} from "../../../types";
import colors from "../../../../../../newShared/theme/colors";

export const RunRuleCheckDialog = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        isOk,
        isOpen,
        conflicts,
        conflictsToAdd,
        handleClose,
        handleSave,
        isLoadingAddConflicts,
        handleSelectAll,
        isAllSelected,
        toggleCheckConflict,
    } = useRunRuleCheckDialog();


    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Found conflicts')}</DialogTitle>
            <DialogContent>
                {conflicts?.length > 1 &&
                    <Box display={'flex'} alignItems={'center'}>
                        <Checkbox size={'small'} onChange={() => handleSelectAll(!isAllSelected)} checked={isAllSelected} />
                        <Typography variant={'body2'} sx={{ml: !revDir ? '8px' : undefined, mr: revDir ? '8px' : undefined}}>{'Select all'}</Typography>
                    </Box>
                }
                {conflicts?.length > 0 && conflicts.map(e => (
                    <ConflictRow key={e.id}
                                 isOne={conflicts.length === 1}
                                 conflict={e}
                                 checked={conflictsToAdd.findIndex(c => c.id === e.id) > -1}
                                 onCheck={toggleCheckConflict}
                    />
                ))}
                {conflicts.length === 0 &&
                    t('No conflicts found')
                }

            </DialogContent>
            <DialogActions>
                <Button sx={{textTransform: 'none'}} onClick={handleClose} size={'medium'} variant={'text'}>{t('Close')}</Button>
                {conflicts.length > 0 &&
                    <LoadingButton size={'medium'} variant={'contained'}
                                   disabled={!isOk || isLoadingAddConflicts}
                                   onClick={handleSave} loading={isLoadingAddConflicts}
                                   endIcon={isLoadingAddConflicts ? <div style={{width: '10px', height: '10px', marginLeft: revDir ? '12px' : undefined, marginRight: !revDir ? '12px' : undefined }}/> : undefined}
                                   loadingPosition={isLoadingAddConflicts ? 'end' : undefined}
                    >
                        {t('Save to conflict list')}
                    </LoadingButton>
                }
            </DialogActions>
        </Dialog>
    )
}
type TProps = {
    isOne: boolean;
    checked: boolean;
    conflict: TRulesConflict;
    onCheck: (conflict: TRulesConflict) => void;
}

const ConflictRow: FC<TProps> = ({conflict, checked, onCheck, isOne}) => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});


    return (
        <Box width={'100%'} display={'flex'} flexDirection={'column'}  borderRadius={'8px'}
             sx={{ mb: '16px', mt: isOne ? '8px' : undefined, p: '8px', boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02);'}}
        >
            <Box display={'flex'} alignItems={'center'}>
                {!isOne && <Checkbox size={'small'} checked={checked} onChange={() => onCheck(conflict)}/>}
                <Typography variant={'body2'} sx={{color: colors.text.grey_dark, mr: isOne ? '12px' : '8px', ml: isOne ? '12px' : '8px'}}>{t('Account')}</Typography>
                <Typography variant={'body2'} sx={{color: colors.text.dark}}>{conflict.account}</Typography>
            </Box>
            <Box display={'flex'} sx={{ml: !revDir ? '12px' : undefined, mr: revDir ? '12px' : undefined, mt: isOne ? '8px' : undefined}} >
                <Typography variant={'body2'} sx={{color: colors.text.grey_dark, ml: revDir ? '8px' : undefined, mr: !revDir ? '8px' : undefined}}>{t('Application')}</Typography>
                <Typography variant={'body2'} sx={{color: colors.text.dark}}>{conflict.applications.map(a => a.name).join(', ')}</Typography>
            </Box>
            <Box display={'flex'} sx={{mt: '8px', ml: !revDir ? '12px' : undefined, mr: revDir ? '12px' : undefined}}>
                <Typography variant={'body2'} sx={{color: colors.text.grey_dark, ml: revDir ? '8px' : undefined, mr: !revDir ? '8px' : undefined}}>{t('Severity level')}</Typography>
                <Typography variant={'body2'} sx={{color: colors.text.dark}}>{conflict.severityLevel}</Typography>
            </Box>
        </Box>
    )
}