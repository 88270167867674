import {TAsset, TSettingStatus} from "./types";
import {PATH_LOCAL_ASSET_MANAGEMENT} from "../../../newShared/constants";
import {AssetsRequestType} from "../../../newShared/GQLTypes";

//paths
export const PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS = `${PATH_LOCAL_ASSET_MANAGEMENT}/physical_assets`;
export const PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS = `${PATH_LOCAL_ASSET_MANAGEMENT}/virtual_assets`;
export const PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE = `${PATH_LOCAL_ASSET_MANAGEMENT}/software`;
export const PATH_LOCAL_ASSET_MANAGEMENT_SAAS = `${PATH_LOCAL_ASSET_MANAGEMENT}/saas`;
export const PATH_LOCAL_ASSET_MANAGEMENT_CLOUDFLARE = `${PATH_LOCAL_ASSET_MANAGEMENT}/cloudflare`;
export const PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATES = `${PATH_LOCAL_ASSET_MANAGEMENT}/certificates`;
export const PATH_LOCAL_ASSETS_INCOMING_REQUESTS = `${PATH_LOCAL_ASSET_MANAGEMENT}/requests`;
export const PATH_LOCAL_ASSETS_INCOMING_REQUESTS_EXACT = `${PATH_LOCAL_ASSETS_INCOMING_REQUESTS}/:id`;

export const PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_NEW = `${PATH_LOCAL_ASSET_MANAGEMENT}/physical_assets/new`;
export const PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS_NEW = `${PATH_LOCAL_ASSET_MANAGEMENT}/virtual_assets/new`;
export const PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_NEW = `${PATH_LOCAL_ASSET_MANAGEMENT}/software/new`;
export const PATH_LOCAL_ASSET_MANAGEMENT_SAAS_NEW = `${PATH_LOCAL_ASSET_MANAGEMENT}/saas/new`;
export const PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATES_NEW = `${PATH_LOCAL_ASSET_MANAGEMENT}/certificates/new`;
export const PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_ID = `${PATH_LOCAL_ASSET_MANAGEMENT}/physical_assets/exact/:id`;
export const PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS_ID = `${PATH_LOCAL_ASSET_MANAGEMENT}/virtual_assets/exact/:id`;
export const PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_ID = `${PATH_LOCAL_ASSET_MANAGEMENT}/software/exact/:id`;
export const PATH_LOCAL_ASSET_MANAGEMENT_SAAS_ID = `${PATH_LOCAL_ASSET_MANAGEMENT}/saas/exact/:id`;
export const PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATES_ID = `${PATH_LOCAL_ASSET_MANAGEMENT}/certificates/exact/:id`;
export const PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_SETTINGS = `${PATH_LOCAL_ASSET_MANAGEMENT}/physical_assets/settings`;
export const PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS_SETTINGS = `${PATH_LOCAL_ASSET_MANAGEMENT}/virtual_assets/settings`;
export const PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_SETTINGS = `${PATH_LOCAL_ASSET_MANAGEMENT}/software/settings`;
export const PATH_LOCAL_ASSET_MANAGEMENT_SAAS_SETTINGS = `${PATH_LOCAL_ASSET_MANAGEMENT}/saas/settings`;
export const PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATE_SETTINGS = `${PATH_LOCAL_ASSET_MANAGEMENT}/certificate/settings`;
export const PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_SETTINGS_EXACT = `${PATH_LOCAL_ASSET_MANAGEMENT}/physical_assets/settings/exact/:id`;
export const PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS_SETTINGS_EXACT = `${PATH_LOCAL_ASSET_MANAGEMENT}/virtual_assets/settings/exact/:id`;
export const PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_SETTINGS_EXACT = `${PATH_LOCAL_ASSET_MANAGEMENT}/software/settings/exact/:id`;
export const PATH_LOCAL_ASSET_MANAGEMENT_SAAS_SETTINGS_EXACT = `${PATH_LOCAL_ASSET_MANAGEMENT}/saas/settings/exact/:id`;
export const PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATE_SETTINGS_EXACT = `${PATH_LOCAL_ASSET_MANAGEMENT}/certificate/settings/exact/:id`;
export const PATH_LOCAL_PHYSICAL_ASSETS_SETTINGS_NEW = `${PATH_LOCAL_ASSET_MANAGEMENT}/physical_assets/settings/:id/new`;
export const PATH_LOCAL_VIRTUAL_ASSETS_SETTINGS_NEW = `${PATH_LOCAL_ASSET_MANAGEMENT}/virtual_assets/settings/:id/new`;
export const PATH_LOCAL_SOFTWARE_ASSETS_SETTINGS_NEW = `${PATH_LOCAL_ASSET_MANAGEMENT}/software/settings/:id/new`;
export const PATH_LOCAL_SAAS_ASSETS_SETTINGS_NEW = `${PATH_LOCAL_ASSET_MANAGEMENT}/saas/settings/:id/new`;
export const PATH_LOCAL_CERTIFICATE_SETTINGS_NEW = `${PATH_LOCAL_ASSET_MANAGEMENT}/certificate/settings/:id/new`;

export const MODEL_ID = 'model';
export const STATUS_ID = 'status';
export const MANUFACTURE_ID = 'manufacture';
export const CATEGORY_ID = 'category';
export const CUSTOM_FIELDS_ID = 'customFields';
export const TYPE_ID = 'type';
export const BILLING_CYCLE_ID = 'billingCycle';
export const RENEWAL_ID = 'renewal';

export const DEFAULT_COUNT = 25;

export const IMPORT_CSV_FILE_SUCCESS_MESSAGE = 'Assets were added';
export const IMPORT_VIRTUAL_CSV_FILE_SUCCESS_MESSAGE = 'Virtual Assets added successfully';
export const IMPORT_SOFTWARE_CSV_FILE_SUCCESS_MESSAGE = 'Software Assets added successfully';
export const IMPORT_SAAS_CSV_FILE_SUCCESS_MESSAGE = 'Saas Assets added successfully';
export const IMPORT_CERTIFICATE_CSV_FILE_SUCCESS_MESSAGE = 'Certificate Assets added successfully';

export const defaultAsset: TAsset = {
    id: '',
    assetId: '',
    serial: '',
    model: '',
    category: '',
    manufacture: '',
    added: '',
    assigned: '',
    returned: '',
    customFields: {},
    assignedTo: null,
    status: {name: 'New', status: 'Undeployable'},
    statuses: [],
}


export const statusVariants: TSettingStatus[] = ["UNDEPLOYABLE", "PENDING", "DEPLOYABLE", "DEPLOYED", "ARCHIVED"];

export const assetRequestTypeMapper: Record<AssetsRequestType, string> = {
    ADD_ASSET: 'Add asset',
    REMOVE: 'Remove asset',
    REQUEST: 'Request asset'
}


