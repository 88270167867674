import {useExactSettings} from "../../hooks/useExactSettings";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../../../newShared/components/Layouts";
import AddIcon from "@mui/icons-material/Add";
import colors from "../../../../../newShared/theme/colors";
import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {getStatusColor} from "../../helpers";
import React from "react";
import {SearchField} from "../../../../../newShared/components/Basic/CommonInputs";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {StatusDescription} from "../../components/statusDescription";
import {EditSettingDialog} from "../../components/Dialogs/EditSettingDialog";
import {DeleteSettingDialog} from "../../components/Dialogs/DeleteSetting";
import {CreateSettingRowDialog} from "../../components/Dialogs/CreateSettingRowDialog";
import {TableRowSkeleton} from "../../../../../newShared/components/tableRowSkeleton";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";


export const ExactSettings = () => {
    const {isMobile, isTablet, matches_1920Down, isLessThen1050} = useMedia();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});

    const {
        settingsView,
        id,
        isLoadingSettings,
        settingTitle,
        handleCreateNewItem,
        handleGoBack,


        search,
        setSearch,
        handleCleanSearch,
        handleEnterKey,
        setEditSettingRowDialog,
        setDeleteSettingRowDialog
    } = useExactSettings();

    return (
        <Flex w={'100%'} h={'100%'} direction={'column'} overflowy={(isMobile || isTablet) ? "scroll" : undefined}>
            <PageTitleAndActions
                title={t(settingTitle)}
                handleGoBack={handleGoBack}
                showMenuWhen={isMobile}
                actions={[
                    {
                        title: t('Add new item'),
                        onClick: () => handleCreateNewItem(id),
                        variant: 'text',
                        icon: <AddIcon style={{marginLeft: revDir ? '8px' : undefined}}/>,
                        disabled: isLoadingSettings
                    },
                ]}
            />

            {/*<Breadcrumbs/>*/}
            {/*<Flex ai={'center'} m={'10px 0 0 0'}>*/}
            {/*    {isLoadingSettings ? <Skeleton width={'170px'} height={'40px'} /> :*/}
            {/*        <>*/}
            {/*            {!isMobile &&*/}
            {/*                <IconButton sx={{color: colors.text.blue, padding: '0', margin: !revDir ? `${!isLessThen1050 ? 11 : 4}px 12px ${!isLessThen1050 ? 4 : 2}px 0` : `${!isLessThen1050 ? 11 : 4}px 0 ${!isLessThen1050 ? 4 : 2}px 12px`}} onClick={handleGoBack} size={isLessThen1050 ? 'small' : undefined}>*/}
            {/*                    {!revDir ? <ArrowBack/> : <ArrowForward/>}*/}
            {/*                </IconButton>*/}
            {/*            }*/}
            {/*            <Typography variant={isMobile ? 'h3' : "h1"} sx={{*/}
            {/*                margin: !revDir ? '0 22px 0 11px' : '0 11px 0 22px',*/}
            {/*                color: colors.backgrounds.dark*/}
            {/*            }}>{t(settingTitle)}</Typography>*/}
            {/*        </>*/}
            {/*    }*/}

            {/*    <Button startIcon={<AddIcon style={{marginLeft: revDir ? '8px' : undefined}}*/}
            {/*            fontSize={'small'}/>} variant="text" size={'large'}*/}
            {/*            disabled={isLoadingSettings}*/}
            {/*            onClick={() => handleCreateNewItem(id)} >*/}
            {/*        {t('Add new item')}*/}
            {/*    </Button>*/}
            {/*</Flex>*/}

            {/*STATUS DESCRIPTION*/}
            {id === 'status' && <StatusDescription />}
            <SearchField
                size={'small'}
                sx={{margin: revDir ? '30px 0 0 10px' : '30px 10px 0 0', width: '320px'}}
                variant={'outlined'}
                placeholder={t('Search')}
                onClear={handleCleanSearch}
                onChange={(e) => {setSearch(e.target.value)}}
                value={search}
                disabled={isLoadingSettings}
                onKeyDown={handleEnterKey}
            />

            {!isLessThen1050 ?
                <TableContainer sx={{marginTop: '10px'}}>
                    <Table stickyHeader>
                        <TableHead>
                            {id !== 'status' &&
                                <TableRow>
                                    { id !== 'customFields' ?
                                        <TableCell width={isMobile ? 150 : isTablet ? 320 : 520} align={revDir ? 'right' : 'left'}>{t('Title')}</TableCell>
                                        :
                                        <TableCell width={isMobile ? '80%' : '50%'} align={revDir ? 'right' : 'left'}>{t('Title')}</TableCell>
                                    }
                                    {id !== 'customFields' && <TableCell width={isMobile ? 60 : 200} align={revDir ? 'right' : 'left'}>{t('Asset')}</TableCell>}
                                    <TableCell width={40} align={revDir ? 'right' : 'left'} />
                                    <TableCell align={revDir ? 'right' : 'left'}/>
                                </TableRow>
                            }
                            {id === 'status' &&
                                <TableRow >
                                    <TableCell width={isMobile ? 70 : isTablet ? 170 : matches_1920Down ? 190 : 490} style={{padding: isMobile ? '16px 0' : undefined}} align={revDir ? 'right' : 'left'}>{t('Title')}</TableCell>
                                    <TableCell width={isMobile ? 100 : isTablet ? 170 : 270} style={{padding: isMobile ? '16px 8px' : undefined}} align={revDir ? 'right' : 'left'}>{t('Status')}</TableCell>
                                    <TableCell width={isMobile ? undefined : isTablet ? 100 : 200} style={{padding: isMobile ? '0' : undefined}} align={isMobile ? 'center' : revDir ? 'right' : 'left'}>{t('Asset')}</TableCell>
                                    {!isMobile &&
                                        <>
                                            <TableCell width={40} align={revDir ? 'right' : 'left'}/>
                                            <TableCell align={revDir ? 'right' : 'left'}/>
                                        </>
                                    }
                                </TableRow>
                            }
                        </TableHead>
                        <TableBody>
                            { (settingsView && id !== 'status' && id !== 'customFields') && [...settingsView[id]].map(({name, count}) => (
                                <TableRow key={name} >
                                    <TableCell align={revDir ? 'right' : 'left'}>{name}</TableCell>
                                    <TableCell sx={{padding: !isMobile ? '16px : 32px' : undefined}} align={isMobile ? 'center' : revDir ? 'right' : 'left'}>{count}</TableCell>
                                    <TableCell onClick={() => setEditSettingRowDialog({isOpen: true, field: id, oldTitle: name, assetType: 'physical'})} align={revDir ? 'right' : 'left'}>
                                        <IconButton
                                            sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}
                                            size={isLessThen1050 ? 'small' : 'large'}>
                                            <EditIcon/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align={revDir ? 'right' : 'left'}>
                                        <IconButton
                                            sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}
                                            onClick={() => setDeleteSettingRowDialog({isOpen: true, field: id, oldTitle: name, assetType: 'physical'})}
                                            size={isLessThen1050 ? 'small' : 'large'}>
                                            <DeleteForeverIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>

                            ))}

                            { (settingsView && id === 'customFields') && [...settingsView[id]].map((name) => (
                                <TableRow key={name} >
                                    <TableCell align={revDir ? 'right' : 'left'}>{name}</TableCell>
                                    <TableCell onClick={() => setEditSettingRowDialog({isOpen: true, field: id, oldTitle: name, assetType: 'physical'})} align={revDir ? 'right' : 'left'}>
                                        <IconButton
                                            sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}
                                            size={isLessThen1050 ? 'small' : 'large'}>
                                            <EditIcon/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align={revDir ? 'right' : 'left'}>
                                        <IconButton
                                            sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}
                                            onClick={() => setDeleteSettingRowDialog({isOpen: true, field: id, oldTitle: name, assetType: 'physical'})}
                                            size={isLessThen1050 ? 'small' : 'large'}>
                                            <DeleteForeverIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>

                            ))}

                            { (settingsView && id === 'status') && [...settingsView.status].map(({name, count, status}) => (
                                <TableRow key={name} >
                                    <TableCell style={{padding: isMobile ? 0 : undefined}} align={revDir ? 'right' : 'left'}>{name}</TableCell>
                                    <TableCell sx={{padding: isMobile ? '16px 8px' : undefined, color: getStatusColor(status)}} align={revDir ? 'right' : 'left'}>{status}</TableCell>
                                    <TableCell  style={{padding: isMobile ? 0 : '16px 32px'}} align={isMobile ? 'center' : revDir ? 'right' : 'left'}>{count}</TableCell>
                                    {!isMobile &&
                                        <>
                                            <TableCell onClick={() => setEditSettingRowDialog({isOpen: true, field: id, status: {name, status, count}, oldTitle: name, assetType: 'physical'})} align={revDir ? 'right' : 'left'}>
                                                <IconButton
                                                    sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}
                                                    size={isLessThen1050 ? 'small' : 'large'}>
                                                    <EditIcon/>
                                                </IconButton>
                                            </TableCell>
                                            <TableCell align={revDir ? 'right' : 'left'}>
                                                <IconButton
                                                    sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}
                                                    onClick={() => setDeleteSettingRowDialog({isOpen: true, field: id, oldTitle: name, assetType: 'physical'})}
                                                    size={isLessThen1050 ? 'small' : 'large'}>
                                                    <DeleteForeverIcon/>
                                                </IconButton>
                                            </TableCell>
                                        </>
                                    }

                                </TableRow>

                            ))}
                            {isLoadingSettings && id === 'status' && <TableRowSkeleton columns={3}/>}
                            {isLoadingSettings && id === 'customFields' && <TableRowSkeleton columns={1}/>}
                            {isLoadingSettings && id !== 'customFields' && id !== 'status' && <TableRowSkeleton columns={2}/>}
                        </TableBody>
                    </Table>
                </TableContainer>

                :
                <Table>
                    <TableHead>
                        {id !== 'status' &&
                            <TableRow >
                                { id !== 'customFields' ?
                                    <TableCell width={isMobile ? 150 : isTablet ? 320 : 520} align={revDir ? 'right' : 'left'}>{t('Title')}</TableCell>
                                    :
                                    <TableCell width={isMobile ? '80%' : '50%'} align={revDir ? 'right' : 'left'}>{t('Title')}</TableCell>
                                }
                                {id !== 'customFields' && <TableCell width={isMobile ? 60 : 200} align={revDir ? 'right' : 'left'}>{t('Asset')}</TableCell>}
                                <TableCell width={40} align={revDir ? 'right' : 'left'}/>
                                <TableCell align={revDir ? 'right' : 'left'} />
                            </TableRow>
                        }
                        {id === 'status' &&
                            <TableRow >
                                <TableCell width={isMobile ? 70 : isTablet ? 170 : matches_1920Down ? 190 : 490} style={{padding: isMobile ? '16px 0' : undefined}} align={revDir ? 'right' : 'left'}>{t('Title')}</TableCell>
                                <TableCell width={isMobile ? 100 : isTablet ? 170 : 270} style={{padding: isMobile ? '16px 8px' : undefined}} align={revDir ? 'right' : 'left'}>{t('Status')}</TableCell>
                                <TableCell width={isMobile ? undefined : isTablet ? 100 : 200} style={{padding: isMobile ? '0' : undefined}} align={isMobile ? 'center' : revDir ? 'right' : 'left'}>{t('Asset')}</TableCell>
                                {!isMobile &&
                                    <>
                                        <TableCell width={40} align={revDir ? 'right' : 'left'}/>
                                        <TableCell align={revDir ? 'right' : 'left'}/>
                                    </>
                                }
                            </TableRow>
                        }
                    </TableHead>
                    <TableBody>
                        { (settingsView && id !== 'status' && id !== 'customFields') && [...settingsView[id]].map(({name, count}) => (
                            <TableRow key={name}>
                                <TableCell align={revDir ? 'right' : 'left'}>{name}</TableCell>
                                <TableCell sx={{padding: !isMobile ? '16px : 32px' : undefined}} align={isMobile ? 'center' : revDir ? 'right' : 'left'}>{count}</TableCell>
                                <TableCell onClick={() => setEditSettingRowDialog({isOpen: true, field: id, oldTitle: name, assetType: 'physical'})} align={revDir ? 'right' : 'left'}>
                                    <IconButton
                                        sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}
                                        size={isLessThen1050 ? 'small' : 'large'}>
                                        <EditIcon/>
                                    </IconButton>
                                </TableCell>
                                <TableCell align={revDir ? 'right' : 'left'}>
                                    <IconButton
                                        sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}
                                        onClick={() => setDeleteSettingRowDialog({isOpen: true, field: id, oldTitle: name, assetType: 'physical'})}
                                        size={isLessThen1050 ? 'small' : 'large'}>
                                        <DeleteForeverIcon/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>

                        ))}

                        { (settingsView && id === 'customFields') && [...settingsView[id]].map((name) => (
                            <TableRow key={name} >
                                <TableCell align={revDir ? 'right' : 'left'} >{name}</TableCell>
                                <TableCell onClick={() => setEditSettingRowDialog({isOpen: true, field: id, oldTitle: name, assetType: 'physical'})} align={revDir ? 'right' : 'left'}>
                                    <IconButton
                                        sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}
                                        size={isLessThen1050 ? 'small' : 'large'}>
                                        <EditIcon/>
                                    </IconButton>
                                </TableCell>
                                <TableCell align={revDir ? 'right' : 'left'}>
                                    <IconButton
                                        sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}
                                        onClick={() => setDeleteSettingRowDialog({isOpen: true, field: id, oldTitle: name, assetType: 'physical'})}
                                        size={isLessThen1050 ? 'small' : 'large'}>
                                        <DeleteForeverIcon/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>

                        ))}

                        { (settingsView && id === 'status') && [...settingsView.status].map(({name, count, status}) => (
                            <TableRow key={name} >
                                <TableCell style={{padding: isMobile ? 0 : undefined}} align={revDir ? 'right' : 'left'}>{name}</TableCell>
                                <TableCell sx={{padding: isMobile ? '16px 8px' : undefined, color: getStatusColor(status)}} align={revDir ? 'right' : 'left'}>{status}</TableCell>
                                <TableCell  style={{padding: isMobile ? 0 : '16px 32px'}} align={isMobile ? 'center' : revDir ? 'right' : 'left'}>{count}</TableCell>
                                {!isMobile &&
                                    <>
                                        <TableCell onClick={() => setEditSettingRowDialog({isOpen: true, field: id, status: {name, status, count}, oldTitle: name, assetType: 'physical'})} align={revDir ? 'right' : 'left'}>
                                            <IconButton
                                                sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}
                                                size={isLessThen1050 ? 'small' : 'large'}>
                                                <EditIcon/>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align={revDir ? 'right' : 'left'}>
                                            <IconButton
                                                sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}
                                                onClick={() => setDeleteSettingRowDialog({isOpen: true, field: id, oldTitle: name, assetType: 'physical'})}
                                                size={isLessThen1050 ? 'small' : 'large'}>
                                                <DeleteForeverIcon/>
                                            </IconButton>
                                        </TableCell>
                                    </>
                                }

                            </TableRow>

                        ))}
                    </TableBody>
                </Table>
            }

            <EditSettingDialog />
            <CreateSettingRowDialog />
            <DeleteSettingDialog />

        </Flex>
    )
}