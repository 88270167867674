import {useOrganizationsList} from "../../../hooks/organizations/useOrganizationsList";
import React from "react";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Avatar, Typography} from "@mui/material";
import colors from "../../../../../../newShared/theme/colors";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";
import {isExpired, parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {GenericTable} from "../../../../../../newShared/components/genericTable";
import {TOrganization} from "../../../types";
import {DEFAULT_LOGO_URL} from "../../../constants";
import {getAvatarInitials, makeFirstLetterUpper} from "../../../../../../newShared/utils/text";
import {getSub} from "../../../helpers";
import {UseManageWorkspacesAndOrganizations} from "../../../../cookies/hooks/useManageWorkspacesAndOrganizations";


export const OrganizationsList = () => {
    const {
        handleGoToCreateOrg,
        organizations,
        onOrganizationClick,
         genericTable
    } = useOrganizationsList();
    const {isMobile} = useMedia(858);
    const {t, currentLanguage} = useMainTranslation('', {keyPrefix: 'pathSettings.OrganizationsList'});
    const {user} = UseManageWorkspacesAndOrganizations();


    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('Organizations')}
                actions={[
                    {
                        onClick: handleGoToCreateOrg,
                        title: t('Create new organization'),
                    }
                ]}
            />

            <GenericTable<TOrganization>
                id={'OrganizationsList'}
                columnsConfig={{totalName: 'Total organizations', forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: organizations,
                    customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id,
                    onRowClick: onOrganizationClick,
                    columnsConfigs: [
                        {name: t('Name'), key: 'name', default: true, valueRenderer: (row) => {
                            return(
                                <Flex w={'100%'} ai={'center'} gap={'8px'}>
                                    <Avatar src={row.logo ?? DEFAULT_LOGO_URL} sx={{width: '25px', height: '25px', backgroundColor: row?.logo !== null ? 'inherit' : colors.primary.blue}}>
                                        <Typography fontSize={'10px'} fontWeight={300}>{getAvatarInitials(row.name)}</Typography>
                                    </Avatar>
                                    <Typography
                                        variant={'*bodyText2'}
                                        color={colors.darkText}
                                        sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}
                                    >
                                        {row.name}
                                    </Typography>
                                </Flex>
                            )
                            }},
                        {name: t('Plan'), key: 'plan', default: true, valueGetter: (row) => t(`subs.${getSub(row.subscription)}`)},
                        {name: t('Role'), key: 'role', default: true, valueRenderer: (row) => {
                                const currRole = row.collaborators.find(e => e.publicId === user?.publicId ?? '');
                                return(
                                <Typography variant={'*bodyText2'}>{currRole ? t(`orgRoles.${makeFirstLetterUpper(currRole.role)}`) : t('Collaborator')}</Typography>
                            )
                            }},
                        {name: t('ID'), key: 'id', default: true},
                        {name: t('Expires on'), key: 'ExpiresOn', default: true, valueRenderer: (row) => {
                                const _isExpired = isExpired(row.subscriptionExpiry, currentLanguage.momentLocale);
                                return(
                                    <Typography
                                        variant={'*bodyText2'} color={ _isExpired ? colors.warningActions.red : colors.grayText}
                                    >
                                        {parseDateAuto(row.subscriptionExpiry)}
                                    </Typography>
                                )
                            }},
                        {name: t('Workspaces'), key: 'workspaces', default: true, valueGetter: (row) => `${row.workspaceCount} ${t('of')} ${row.configuration.availableWorkspaceCount}`},
                        {name: t('Collaborators per workspace'), key: 'collabs', default: true, valueGetter: (row) => row.configuration.availableCollaboratorsCount.toString()},
                        {name: '', key: 'blocked', default: true, valueRenderer: (row) => (
                            <Typography variant={'*bodyText2'} color={colors.text.critical}>{row.blocked ? t('Blocked') : ''}</Typography>
                            )},
                    ],
                }}
                filtersConfig={{
                    genericFilterProps: {
                        configs: [],
                        fetchResultWithSelectedFilters: () => null,
                        isAddFilters: false
                    }
                }}
                emptyArrayImageProps={{
                    type: 'any',
                    filterNotApplied: {text: 'No requests to display', secondaryText: 'Create an organization', onClick: handleGoToCreateOrg}
                }}
            />
        </Flex>
    )
}
