import {useDispatch, useSelector} from "react-redux";
import {openCreateSettingRowDialog, vendorsDialogsSelector} from "../../store/slice";
import {SettingsTypes} from "../../types";
import React, {FC} from "react";
import {Paper} from "@mui/material";
import {AddMenuItem} from "../../../../../newShared/components/Basic/AddMenuItem";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const useAddNewSetting = () => {
    const {t} = useMainTranslation('',{keyPrefix:'pathVendors.AddVendor'});

    const dispatch = useDispatch();
    const {createSettingRow: {type: field}} = useSelector(vendorsDialogsSelector);
    const handleCreateNewItem = (customId: SettingsTypes) => {
        dispatch(openCreateSettingRowDialog({
            type: customId,
        }))
    }

    const TypeBlock: FC = ({ children, ...other }) => (
        <Paper {...other}>
            <AddMenuItem text={t('Add new')} onMouseDown={() => handleCreateNewItem('type')} />
            {children}
        </Paper>
    );
    const RiskBlock: FC = ({ children, ...other }) => (
        <Paper {...other}>
            <AddMenuItem text={t('Add new')} onMouseDown={() => handleCreateNewItem('risk')} />
            {children}
        </Paper>
    );
    const ServiceProvidedBlock: FC = ({ children, ...other }) => (
        <Paper {...other}>
            <AddMenuItem text={t('Add new')} onMouseDown={() => handleCreateNewItem('serviceProvided')} />
            {children}
        </Paper>
    );

    return {
        TypeBlock, RiskBlock, ServiceProvidedBlock,
        field,
    }
}