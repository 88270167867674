import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {forbiddenWrapper, hideSelectWorkspaceDialog} from "../../store/slice";
import {GetSmallOrgsAndWorkspacesForSelect} from "../../store/actions";
import {ForbiddenWrapperOrganization, TSmallWorkspace} from "../../types";
import {useForm} from "../../../../../newShared/hooks/useForm";
import {useHistory} from "react-router-dom";
import {UseManageWorkspacesAndOrganizations} from "../../../cookies/hooks/useManageWorkspacesAndOrganizations";
import {SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_PATH} from "../../constants";

export const useSelectWorkspaceDialog = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {dialog, isLoading, organizations, lastPath} = useSelector(forbiddenWrapper);
    const {workspaceId, organizationId, setCurrentWorkspaceAndOrganization} = UseManageWorkspacesAndOrganizations();

    const [isLoadingSelect, setIsLoadingSelect] = useState<boolean>(false);

    useEffect(() => {
        if(dialog){ //init
            if(!organizations.length){
                dispatch(GetSmallOrgsAndWorkspacesForSelect({}));

            }else{
                const org = organizations.find(e => e.id === organizationId);
                const ws = org?.workspaces.find(e => e.id === workspaceId);
                if(org && ws){
                    setSelect({organization: org, workspace: ws});
                    setFilteredWorkspaces(org.workspaces);
                }
            }
        }

        return function cleanUp(){
            setSelect({organization: null, workspace: null});
            usersForm.setForm({search: ''});
        }
        //eslint-disable-next-line
    }, [dialog]);

    useEffect(() => {
        if(!isLoading && organizations.length > 0){
            const org = organizations.find(e => e.id === organizationId);
            const ws = org?.workspaces.find(e => e.id === workspaceId);
            if(org && ws){
                setSelect({organization: org, workspace: ws});
                setFilteredWorkspaces(org.workspaces);
            }
        }
        //eslint-disable-next-line
    }, [isLoading]);

    const handleClose = () => {
        dispatch(hideSelectWorkspaceDialog());
    }

    // const getCurrentPathForRedirect = () => {
    //
    // }

    const handleSubmit = async() => {
        if(select.organization && select.workspace){
            try{
                setIsLoadingSelect(true);
                await setCurrentWorkspaceAndOrganization(select.workspace, select.organization)
                setIsLoadingSelect(false);
                handleClose();
                history.push(lastPath.length > 0 ? lastPath : '');
            }catch (ex){
                setIsLoadingSelect(false);
            }
        }
    }

    const [select, setSelect] = useState<{organization: ForbiddenWrapperOrganization | null, workspace: TSmallWorkspace | null}>({organization: null, workspace: null});

    const handleSelectWorkspace = (workspace: TSmallWorkspace) => {
        setSelect({...select, workspace});
    }

    const handleSelectOrganization = (organization: ForbiddenWrapperOrganization) => {
        setSelect({...select, organization});
        setFilteredWorkspaces(organization.workspaces);
        usersForm.setForm({search: ''});
    }

    //
    const usersForm = useForm<{search: string}>({search: ''});
    const [filteredWorkspaces, setFilteredWorkspaces] = useState<TSmallWorkspace[]>([]);

    const handleCleanSearch = () => {
        if(select.organization){
            usersForm.setForm({search: ''});
            setFilteredWorkspaces(select.organization?.workspaces);
        }
    }

    const handleChangeLocal = (e: any) => {
        const search = e.target.value.trim().toLowerCase();
        if(select.organization){
            setFilteredWorkspaces(select.organization?.workspaces.filter(e => e.name.trim().toLowerCase().includes(search)));
        }
        usersForm.handleChange(e);
    }

    const isOk = select.organization !== null && select.workspace !== null && workspaceId !== select.workspace.id

    const handleGoToManageWorkspace = (wsId: string) => {
        select.organization && window.open(
            SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_PATH
                .replace(':orgId', select.organization.id)
                .replace(':wsId', wsId)
            , '_blank', 'noreferrer');
        // select.organization && history.push(SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_PATH
        //     .replace(':orgId', select.organization.id)
        //     .replace(':wsId', wsId)
        // );
        // handleClose();
    }

    return{
        isOpen: dialog,
        isLoading,
        organizations,
        handleClose,
        isLoadingSelectWorkspace: isLoadingSelect,
        handleSubmit,
        selectedWorkspace: select.workspace,
        selectedOrganization: select.organization,
        handleSelectOrganization,
        handleSelectWorkspace,
        workspaces: filteredWorkspaces,
        handleCleanSearch,
        search: usersForm.form.search,
        handleChange: handleChangeLocal,
        isOk,
        handleGoToManageWorkspace
    }
} 