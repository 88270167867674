import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useSegregationOfDuties} from "../../useSegregationOfDuties";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDispatch} from "react-redux";
import {deleteAppDutyAction} from "../../../store/actions";
import {hideDeleteDuty} from "../../../store/slice";


export const useDeleteDuty = () => {
    const dispatch = useDispatch();
    const {setMessage} = useMessageDialog();
    const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        goToDutiesList,
        loadings: {delete: isLoadingDelete},
        dialogs: {deleteDuty: {isOpen, selectedDuty}}
    } = useSegregationOfDuties();


    const onSuccess = () => {
        setMessage({title: t("Completed successfully"), message: t('The duty {{name}} has been deleted.', {name: selectedDuty?.name || ''})});
        goToDutiesList();
    }

    const onError = () => {
        setMessage({title: t("Delete duty"), message: t('You cannot delete this duty because it is used in the rule.')});
    }
    const handleDelete = () =>  {
        selectedDuty && dispatch(deleteAppDutyAction({data: {workspaceId: '', organizationId: '', appId: selectedDuty.application.applicationAccessId,  id: selectedDuty.id}, onSuccess, onError}));
    }

    const handleCancel = () => {
        dispatch(hideDeleteDuty());
    }

    return {
        isOpen,
        selectedDuty,
        handleDelete,
        handleCancel,
        isLoadingDelete,
    }
}