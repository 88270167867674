import {Item, TActorEmailName, TCreateFormDoc, TIdApproverStage} from "../../../../../types";
import React, {FC, useCallback, useEffect, useState} from "react";
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {v4 as uuid} from "uuid";
import * as S from "../styled";
import {Box, Button, Checkbox, IconButton, InputAdornment, TextField} from "@mui/material";
import {TypographyBody2, TypographyBodySmall} from "../../../../../../../../newShared/components/Inputs/styled";
import {CommonAvatar} from "../../../../../../../../newShared/components/Basic/CommonAvatar";
import {getName, validateEmail} from "../../../../../../../../newShared/utils/text";
import {ReactComponent as MessageIcon} from "../../../../../../../../newShared/images/documents/message-icon.svg";
import {
    ReactComponent as CompleteMessageIcon
} from "../../../../../../../../newShared/images/documents/complete-message-icon.svg";
import {ReactComponent as GroupIcon} from "../../../../../../../../newShared/images/documents/group-icon.svg";
import {ConnectableElement, DragSourceMonitor, useDrag, useDrop} from "react-dnd";
import {ItemTypes} from "../index";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {isOkEmail} from "../../../../../constants";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {getMsFromDays} from "../../../../../../../../newShared/utils/dateTools";
import {AutocompleteCollaborators} from "../../../../../../../../newShared/components/AutocompleteCollaborators";
import {optionIsTCollaborator} from "../../../../../../../../newShared/components/AutocompleteCollaborators/types";
import {defaultGetOptionDisabled} from "../../../../../../../../newShared/components/AutocompleteCollaborators/helpers";


interface ApproversBlockViewProps {
    form: TCreateFormDoc;
    setForm:  (form: TCreateFormDoc) => void;
    handleOpenAddGroupMessage: (data: {approverId?: string, groupId?: string, oldMessage: string}) => void;
}

export const ApproversBlockView: FC<ApproversBlockViewProps> = (props) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});
    const {
        form,
        setForm,
        handleOpenAddGroupMessage
    } = props;

    const [isApproverCustomOrder, setIsApproverCustomOrder] = useState<boolean>(true);
    const [apprGroupIds, setApprGroupIds] = useState<string[]>([]);

    const [uniqueInputId, setUniqueInputId] = useState<string>(uuid());
    const [newApprover, setNewApprover] = useState<TActorEmailName | null>(null);
    const [error, setError] = useState<string | null>(null);

    const addedApprovers: string[] = form.approvers.flatMap(e => e.groups.flatMap(e => e.actors.map(e => e.email)));

    const isOk = (email: string) => {
        if (!validateEmail(email)) {
            setError('Invalid email');
            return false;
        }
        else if (addedApprovers.includes(email)) {
            setError('Email already exists');
            return false;
        } else {
            error && setError(null);
            return true;
        }
    }

    const handleAdd = () => {
        if (!newApprover) return;

        const newApprovers = [
            ...form.approvers,
            {
                id: uuid(),
                order: isApproverCustomOrder ? form.approvers.length + 1 :  0,
                eta: getMsFromDays(7),
                groups: [{
                    id: uuid(),
                    actors: [newApprover],
                    name: '',
                    isRequired: true,
                    message: '',
                }]
            }
        ] satisfies TIdApproverStage[];

        if (isOk(newApprover.email)) {
            setForm({
                ...form,
                approvers: newApprovers
            });
            setNewApprover(null);
            setUniqueInputId(uuid());
        }
    }

    const handleAddGroup = () => {
        const approverId = uuid();
        const groupId = uuid();
        const newApprovers = [...form.approvers, {
            id: approverId,
            order: isApproverCustomOrder ? form.approvers.length + 1 :  0,
            eta: getMsFromDays(7),
            groups: [{
                id: groupId,
                actors: [],
                name: '',
                isRequired: true,
                message: '',
            }]
        }] satisfies TIdApproverStage[]

        setForm({...form, approvers: newApprovers});
        setApprGroupIds(prev => prev && [...prev, groupId]);
    };

    const handleAddGroupActor = (approverId: string, groupId: string, actor: TActorEmailName ) => {
        setForm({...form, approvers: form.approvers.map(appr => appr.id === approverId ? ({
                ...appr,
                groups: appr.groups.map(gr => gr.id === groupId ? ({...gr, actors: [...gr.actors, actor]}) : gr)
            }) : appr)});
    }

    const handleDeleteGroupActor = (approverId: string, groupId: string, email: string) => {
        setForm({...form, approvers: form.approvers.map(appr => appr.id === approverId ? ({
                ...appr,
                groups: appr.groups.map(gr => gr.id === groupId ? ({
                    ...gr,
                    actors: gr.actors.filter(actor => actor.email !== email)
                }) : gr)
            }) : appr)});
    }

    const handleToggleOrdered = (isOrdered: boolean) => {
        setForm({...form, approvers: form.approvers.map((appr, index) => ({
                ...appr,
                order: isOrdered ? index + 1 : 0,
            }))});

        setIsApproverCustomOrder(isOrdered);
    }

    const toggleIsRequired = (approverId: string, groupId: string ) => {
        setForm({...form, approvers: form.approvers.map(appr => appr.id === approverId ? ({
                ...appr,
                groups: appr.groups.map(gr => gr.id === groupId ? ({...gr, isRequired: !gr.isRequired}) : gr)
            }) : appr)});
    }

    const handleDeleteGroup = (approveId: string) => {
        setForm({
            ...form,
            approvers: form.approvers.filter(appr => appr.id !== approveId),
        });
    }

    const handleSetNameGroup = (approveId: string, groupId: string, name: string) => {
        setForm({
            ...form,
            approvers: form.approvers.map(appr => appr.id === approveId ? ({
                ...appr,
                groups: appr.groups.map(gr => gr.id === groupId ? ({...gr, name: name}) : gr)
            }) : appr)
        });
    }

    useEffect(() => {
        error && setError(null);

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newApprover?.email]);



    //DND APPROVERS
    const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));
    const setApprovers = useCallback(
        (cols: TCreateFormDoc['approvers']) => {
            setForm({
                ...form,
                approvers: cols.map((e, i) => ({...e, order: i})),
            })
            //eslint-disable-next-line
        }, [form]
    )
    const findColumn: (id: string) => {column: TCreateFormDoc['approvers'][number], index: number} | null = useCallback(
        (id: string) => {
            const column = form.approvers.find(e => e.id === id);

            // console.log(`findColumn for ${key} - ${JSON.stringify(column)} | ${column ? settings.indexOf(column) : null}`);
            return column ? {
                column,
                index: form.approvers.indexOf(column)
            } : null;
        },
        [form.approvers],
    )

    const moveColumn: (key: string, atIndex: number) => void = useCallback(
        (key: string, atIndex: number) => {
            const res = findColumn(key);
            if(res){
                //res - {column: lastName, index: 1}
                //atIndex - 0
                const copyArr = JSON.parse(JSON.stringify(form.approvers));
                // console.log(`copyArr: ${JSON.stringify(copyArr)}`);
                copyArr.splice(res.index, 1) //remove
                copyArr.splice(atIndex, 0, res.column) //insert
                // const reordered = settings.splice(res.index, 1).splice(atIndex, 0, res.column);
                // console.log(`moveColumn for ${key} settings.splice(${res.index}, 1).splice(${atIndex}, 0, ${JSON.stringify(res.column)}) | \n newArr: new arr: ${JSON.stringify(copyArr)}`)
                setApprovers(copyArr);
                // console.log(`moveColumn for ${key} - from ${res?.index} | to ${atIndex} \n new arr: ${JSON.stringify(reordered)}`);
            }else{
                // console.log(`--Column with key ${key} not found!`);
            }
        },
        [findColumn, form.approvers, setApprovers],
    )


    return (
        <>
            <S.FlexBox>
                <Checkbox checked={isApproverCustomOrder} onChange={() => handleToggleOrdered(!isApproverCustomOrder)} />
                <TypographyBody2>{t('Custom approval order')}</TypographyBody2>
            </S.FlexBox>

            <S.AutocompleteBox>
                <AutocompleteCollaborators
                    helperText={error || undefined}
                    key={uniqueInputId}
                    getOptionLabelCustom={"name+email"}
                    freeSoloType={"email"}
                    freeSolo
                    defaultValue={''}
                    onChange={value => {
                        if (!optionIsTCollaborator(value)) {
                            setNewApprover({email: value as string, firstName: '', lastName: ''});
                        } else {
                            setNewApprover({email: value?.email || '', firstName: value?.firstName || '', lastName: value?.lastName || ''});
                        }
                    }}
                    getOptionDisabled={defaultGetOptionDisabled(addedApprovers)}

                    sx={{width: '572px', '& label': {top: '0px !important'}}}
                />
                <Button size={'small'} sx={{marginLeft: '16px', mt: '2px'}}
                        disabled={!newApprover || !validateEmail(newApprover.email) || addedApprovers.includes(newApprover.email)}
                        onClick={handleAdd}>{t('Add')}</Button>
            </S.AutocompleteBox>

            <div ref={drop} style={{width: '100%'}}>
                {form.approvers.map((approver, index) => (
                    <ApproverGroup
                        key={approver.id}
                        index={index}
                        moveColumn={moveColumn}
                        findColumn={findColumn}
                        isApproverCustomOrder={isApproverCustomOrder}
                        approver={approver}
                        addedApprovers={addedApprovers}
                        apprGroupIds={apprGroupIds}
                        handleDeleteGroup={handleDeleteGroup}
                        handleSetNameGroup={handleSetNameGroup}
                        toggleIsRequired={toggleIsRequired}
                        handleAddGroupActor={handleAddGroupActor}
                        handleDeleteGroupActor={handleDeleteGroupActor}
                        handleOpenAddGroupMessage={handleOpenAddGroupMessage}
                    />))}

            </div>


            <Button variant={'text'} sx={{textTransform: 'none', mt: '16px'}} startIcon={<GroupIcon />} onClick={handleAddGroup}>{t('Create approver group')}</Button>

        </>
    )
}

interface ApproversGroupProps {
    approver: TIdApproverStage,
    addedApprovers: string[],
    index: number,
    isApproverCustomOrder: boolean;
    moveColumn: (key: string, atIndex: number) => void,
    findColumn: (key: string) => {column: TCreateFormDoc['approvers'][number], index: number} | null
    apprGroupIds: string[];
    handleDeleteGroup: (approveId: string) => void;
    handleSetNameGroup: (approveId: string, groupId: string, name: string) => void;
    toggleIsRequired: (approverId: string, groupId: string ) => void;
    handleAddGroupActor: (approverId: string, groupId: string, actor: TActorEmailName ) => void;
    handleDeleteGroupActor: (approverId: string, groupId: string, email: string) => void;
    handleOpenAddGroupMessage: (data: {approverId: string, groupId: string, oldMessage: string}) => void;
}
export const ApproverGroup: FC<ApproversGroupProps> = (props) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});
    const {
        approver,
        index,
        apprGroupIds,
        handleDeleteGroup,
        handleSetNameGroup,
        toggleIsRequired,
        handleAddGroupActor,
        handleDeleteGroupActor,
        isApproverCustomOrder,
        addedApprovers,
        //dnd
        handleOpenAddGroupMessage,
        moveColumn,
        findColumn,
    } = props;

    const [uniqueInputId, setUniqueInputId] = useState<string>(uuid());
    const [newApprover, setNewApprover] = useState<TActorEmailName | null>(null);
    const [error, setError] = useState<string | null>(null);

    const isOk = (email: string) => {
        if (!isOkEmail(email)) {
            setError('Invalid email');
            return false;
        }
        else if (addedApprovers.some(a => a === email)) {
            setError('Email already exists');
            return false;
        } else {
            error && setError(null);
            return true;
        }
    }


    const handleAdd = (groupId: string) => {
        if (!newApprover) return;
        const tmpAppr: TActorEmailName = {email: newApprover.email, firstName: newApprover.firstName, lastName: newApprover.lastName};

        if (isOk(tmpAppr.email)) {
            approver.id && handleAddGroupActor(approver.id, groupId, tmpAppr);
            setNewApprover(null);
            setUniqueInputId(uuid());
        }
    }

    useEffect(() => {
        error && setError(null);

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newApprover?.email]);

    //DND
    const searchedColumn = findColumn(approver.id);
    // console.log(`----COL searchedColumn: ${JSON.stringify(searchedColumn)}`);
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.CARD,
            item: {key: approver.id, index: searchedColumn?.index ?? -1},
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const { key: droppedKey, index: originalIndex } = item;

                // console.log(`ROW useDrag END with item ${JSON.stringify(item)}`);
                const didDrop = monitor.didDrop();
                if (!didDrop && item) {
                    moveColumn(droppedKey ?? '', originalIndex);
                    // console.log(`ROW useDrag - moveColumn exec: ${droppedKey} | ${originalIndex}`);
                }
            },
        }),
        [approver.id, searchedColumn, moveColumn],
    )

    const [, drop] = useDrop(
        () => ({
            accept: ItemTypes.CARD,
            hover({ key: draggedKey }: Item) {
                // console.log(`Card useDrop draggedKey:${draggedKey} in cardId ${column.key}`);
                if (draggedKey !== approver.id) {
                    const col = findColumn(approver.id ?? '')
                    if(col){
                        // console.log(`ROW useDrop - moveColumn exec: ${draggedKey} | ${col.index}`);
                        moveColumn(draggedKey, col.index);
                    }else{
                        // console.log(`ROW useDrop - col not found by key ${column.key}`)
                    }
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
                isActive: monitor.canDrop() && monitor.isOver(),
            }),
        }),
        [findColumn, moveColumn],
    );

    return (
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} marginTop={'16px'} width={'100%'} >
            {isApproverCustomOrder && <S.OrderBox>{index + 1}</S.OrderBox>}

            <Box marginLeft={isApproverCustomOrder ? '16px' : 0} width={'100%'}>
                { approver.groups.map((group, index) => (

                    <S.InfoActorContainer
                        key={group.id}
                        isOneApprover={!apprGroupIds.find(grId => grId === group.id) ? true : undefined}
                        isDragging={isDragging ? true : undefined}
                        ref={(node: ConnectableElement) => drag(drop(node))}
                    >
                        {isApproverCustomOrder &&  <DragIndicatorIcon style={{marginRight: '6px'}}/>}

                        { apprGroupIds.find(grId => grId === group.id) ?
                            <Box display={"flex"} alignItems={'center'} width={'100%'} flexDirection={'column'} >
                                <S.InfoActorFlexBox >

                                    <TextField variant={'standard'}
                                               size={"small"}
                                               value={group.name} placeholder={t('Name this group')}
                                               onChange={(e) => approver.id && group.id &&  handleSetNameGroup(approver.id, group.id, e.target.value)}
                                               InputProps={{
                                                   startAdornment: (
                                                       <InputAdornment position="start">
                                                           <GroupIcon />
                                                       </InputAdornment>
                                                   ),
                                               }}
                                    />

                                    <Box sx={{display: 'flex', alignItems: 'center', marginRight: '8px'}}>
                                        <Checkbox checked={group.isRequired} onChange={() => approver.id && group.id && toggleIsRequired(approver.id, group.id)} />
                                        <TypographyBodySmall>{t('Required')}</TypographyBodySmall>
                                    </Box>

                                    <S.VerticalDivider />
                                    <IconButton size={'small'} onClick={() => approver.id && group.id && handleOpenAddGroupMessage({approverId: approver.id, groupId: group.id, oldMessage: group.message || ''})}>
                                        {group.message  ?  <CompleteMessageIcon /> :  <MessageIcon />}
                                    </IconButton>

                                    <S.VerticalDivider />
                                    <IconButton onClick={() => approver.id && handleDeleteGroup(approver.id)}>
                                        <DeleteOutlineIcon />
                                    </IconButton>

                                </S.InfoActorFlexBox>
                                <Box display={'flex'} alignItems={'flex-start'} width={'100%'} marginTop={'8px'}>
                                    <S.AutocompleteBox>
                                        <AutocompleteCollaborators
                                            helperText={error || undefined}
                                            key={uniqueInputId}
                                            getOptionLabelCustom={"name+email"}
                                            freeSoloType={"email"}
                                            freeSolo
                                            defaultValue={''}
                                            onChange={value => {
                                                if (!optionIsTCollaborator(value)) {
                                                    setNewApprover({email: value as string, firstName: '', lastName: ''});
                                                } else {
                                                    setNewApprover({email: value?.email || '', firstName: value?.firstName || '', lastName: value?.lastName || ''});
                                                }
                                            }}
                                            getOptionDisabled={defaultGetOptionDisabled(addedApprovers)}

                                            sx={{width: '430px', '& label': {top: '0px !important'}}}
                                        />

                                        <Button size={'small'} sx={{marginLeft: '16px', mt: '2px'}}
                                                disabled={!newApprover || !validateEmail(newApprover.email) || addedApprovers.includes(newApprover.email)}
                                                onClick={() => group.id && handleAdd(group.id)}>{t('Add')}</Button>
                                    </S.AutocompleteBox>
                                </Box>

                                {group.actors.map(actor => (
                                    <S.InfoActorBox key={actor.email} h={'43px'} mt={'12px'}>
                                        <CommonAvatar name={getName(actor.firstName || actor.email, actor.lastName || '')} size={'d28'} />
                                        <S.ApproverTitle>{actor.email}</S.ApproverTitle>
                                        <DeleteOutlineIcon onClick={() => approver.id && group.id && handleDeleteGroupActor(approver.id, group.id, actor.email) } style={{margin: '0 8px'}} />
                                    </S.InfoActorBox>
                                ))}
                            </Box>
                            :
                            <>
                                <CommonAvatar size={'d28'} name={getName(group.actors[0].firstName || group.actors[0].email, group.actors[0].lastName || group.actors[0].email)}/>
                                <S.ApproverTitle>{group.actors[0].email}</S.ApproverTitle>

                                <S.VerticalDivider/>
                                <Box sx={{display: 'flex', alignItems: 'center', marginRight: '8px'}}>
                                    <Checkbox checked={group.isRequired} onChange={() => approver.id && group.id && toggleIsRequired(approver.id, group.id)} />
                                    <TypographyBodySmall>{t('Required')}</TypographyBodySmall>
                                </Box>

                                <S.VerticalDivider />
                                <IconButton size={'small'} onClick={() => approver.id && group.id && handleOpenAddGroupMessage({approverId: approver.id, groupId: group.id, oldMessage: group.message || ''})} >
                                       {group.message  ?  <CompleteMessageIcon /> :  <MessageIcon />}
                                </IconButton>

                                <S.VerticalDivider />
                                <IconButton onClick={() => approver.id && handleDeleteGroup(approver.id)}>
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </>
                        }



                    </S.InfoActorContainer>


                ))}

            </Box>
        </Box>
    )
}

