import {Box, Dialog, styled, TableCell} from "@mui/material";
import colors from "../../../../../../../newShared/theme/colors";


export const OTSDialog = styled(Dialog)(() => ({
    '.MuiPaper-root': {
        minWidth: '678px',
        maxHeight: '800px'
    },
}));

export const ContentBox = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    maxWidth: '528px',
    flexDirection: 'column',
    alignItems: "flex-start",
    gap: '16px',
    margin: '0 auto'

}))


export const ResultContentBox = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: "flex-start",
    gap: '8px',
    margin: '0 auto',
    overflow: 'hidden',
}));


export const TargetDataBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '12px'
}));

export const TemplateNameSpan = styled('span')({
    color: colors.text.dark,
    fontWeight: 600,
});

export const FileDropContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
});

export const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
}));


export const DialogActionsBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
}));

export const RightActionsBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
}));

export const NameTableCell = styled(TableCell)(() => ({
    maxWidth: '350px',
    padding: '12px'
}));

export const CommonTableCell = styled(TableCell)(() => ({
    minWidth: '100px',
    padding: '12px'
}));

