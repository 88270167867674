import React, {FC} from 'react';
import {LeftSideTree} from './components/LeftSideTree';
import {Routes} from "./routes";
import {useDocumentsMain} from "./hooks/useDocumentsMain";
import {LeftSecondMenu} from "../../barsEnvironment/leftMenu/components/leftSecondMenu";


export const DocumentsRefactor: FC = () => {
    const {hideTreeComponent} = useDocumentsMain();

    return (
        <LeftSecondMenu customChild={!hideTreeComponent ? <LeftSideTree /> : <></>} customIsOpenOnLoad>
            <Routes />
        </LeftSecondMenu>
    )
}
