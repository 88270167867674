import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect} from "react";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {useCreateAndAssignNewAsset} from "../../../hooks/dialogs/useCreateAndAssignNewAsset";

export const CreateAndAssignNewAssetDialog = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets.dialogs'})
    const {
        isOpen,
        formik,
        selected,
        customFields,
        setCustomFields,
        settings,
        handleClose,
        handleConfirm,
        isLoadingApprove,
        handleChangeCustomField,

        getSettings,
    } = useCreateAndAssignNewAsset();

    useEffect(() => {
        if (isOpen && selected?.request) {
            settings && setCustomFields(settings.customFields.reduce((accumulator, value) => {
                return {...accumulator, [value]: ''}
            }, {}));
            formik.setValues({
                assetId: '',
                serial: selected?.request.data.find(e => e.fieldName === 'serial')?.fieldValue ?? '',
                model: selected?.request.data.find(e => e.fieldName === 'model')?.fieldValue ?? '',
                category: selected?.request.data.find(e => e.fieldName === 'category')?.fieldValue ?? '',
                manufacture: selected?.request.data.find(e => e.fieldName === 'manufacture')?.fieldValue ?? '',
                resolution: ''
            });
        }
        else {
            setCustomFields({});
            formik.resetForm();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, selected?.request]);

    useEffect(() => {
        if(!settings) {
            getSettings();
        }
        settings && setCustomFields(settings.customFields.reduce((accumulator, value) => {return {...accumulator, [value]: ''}}, {}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);


    return (
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{t('Create and assign new asset')}</DialogTitle>
            <DialogContent style={{display: 'flex', gap: '16px', flexDirection: 'column'}}>
                <Typography variant={'*bodyText2'}>{t('Fill the additional fields to create and assign a new asset.')}</Typography>

                <TextField
                    label={t('ID')}
                    size={'small'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.assetId}
                    name={'assetId'}
                    error={formik.touched.assetId && Boolean(formik.errors.assetId)}
                    helperText={formik.touched.assetId && formik.errors.assetId}
                />

                <TextField
                    label={t('Serial')}
                    required disabled
                    size={'small'}
                    // onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.serial}
                    name={'serial'}
                    error={formik.touched.serial && Boolean(formik.errors.serial)}
                    helperText={formik.touched.serial && formik.errors.serial}
                />

                <Autocomplete<string>
                    size={"small"}
                    options={[]} disabled
                    value={formik.values.category || null}
                    // onChange={(e, selected) => formik.setFieldValue('category', selected)}
                    renderInput={(params) => (
                        <TextField {...params} onBlur={formik.handleBlur}
                                   label={t('Select category')} helperText={formik.touched.category && formik.errors.category} error={formik.touched.category && Boolean(formik.errors.category)} name={'category'} required/>
                    )}
                />

                <Autocomplete<string>
                    size={"small"}
                    options={[]} disabled
                    value={formik.values.model || null}
                    // onChange={(e, selected) => formik.setFieldValue('model', selected)}
                    renderInput={(params) => (
                        <TextField {...params} onBlur={formik.handleBlur}
                                   label={t('Select model')} helperText={formik.touched.model && formik.errors.model} error={formik.touched.model && Boolean(formik.errors.model)} name={'model'} required/>
                    )}
                />

                <Autocomplete<string>
                    size={"small"}
                    options={[]} disabled
                    value={formik.values.manufacture || null}
                    onChange={e => {
                        formik.setFieldTouched('manufacture');
                        formik.setFieldValue('manufacture', selected)
                    }}
                    renderInput={(params) => (
                        <TextField {...params} onBlur={formik.handleBlur}
                                   label={t('Select manufacturer')} helperText={formik.touched.manufacture && formik.errors.manufacture} error={formik.touched.manufacture && Boolean(formik.errors.manufacture)} name={'manufacture'} required/>
                    )}
                />

                {(Object.keys(customFields).length > 0) &&
                    Object.keys(customFields).map((e) =>
                        <TextField key={e} size={'small'} label={e} value={customFields[e] || ''} name={e}
                                   onChange={handleChangeCustomField} sx={{'& label': {top: '0px !important'}}} />
                    )}

                <TextField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.resolution}
                    name={'resolution'}
                    multiline
                    label={'Comment'}
                    rows={4}
                    error={formik.touched.resolution && Boolean(formik.errors.resolution)}
                    helperText={formik.touched.resolution && formik.errors.resolution}
                />

            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{tCommon('Cancel')}</Button>
                <LoadingButton
                    onClick={handleConfirm} size={'small'}
                    disabled={!formik.isValid}
                    loading={isLoadingApprove}
                >
                    {t('Create & Assign')}
                </LoadingButton>
            </DialogActions>
        </Dialog>

    )
}
