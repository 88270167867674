import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import React, {FC, useCallback, useEffect, useState} from "react";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import {DragSourceMonitor, useDrag, useDrop} from "react-dnd";
import {ColumnRowBox, SubTitle} from "./styled";
import {ColumnConfig} from "../../types";
import {useMainTranslation} from "../../../../hooks/useMainTranslationHooks/useMainTranslation";
import {LoadingButton} from "@mui/lab";
import CommonCheckbox from "../../../Basic/CommonCheckBox";


export type ViewSettingsDialogPropsType =  {
    isOpen: boolean,
    selectedColumns: ColumnConfig[];
    columnVariants:  ColumnConfig[];
    handleSave: (selectedColumns:  ColumnConfig[]) => void;
    handleClose: () => void,
}

export const ItemTypes = {
    CARD: 'card',
}

export const ViewSettingsDialog: FC<ViewSettingsDialogPropsType> = (props) => {
    const {
        isOpen,
        selectedColumns,
        columnVariants,
        handleSave,
        handleClose,
    } = props;

    const {tCommon} = useMainTranslation();
    const [selected, _setSelected] = useState<ColumnConfig[]>([]);
    const [settings, _setSettings] = useState<ColumnConfig[]>([]);

    const setSelected = useCallback(
        (cols: ColumnConfig[]) => {
            _setSelected(cols);
        }, []
    )

    const setSettings = useCallback(
        (cols: ColumnConfig[]) => {
            _setSettings(cols);
        }, []
    )

    useEffect(() => {
        if(isOpen){
            setSelected(selectedColumns);
        }
        //eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        isOpen && setSettings(columnVariants.filter(e => !selected.some(c => c.key === e.key)));

        //eslint-disable-next-line
    }, [selected]);

    const handleToggleSettingRow = (_column: ColumnConfig) => {
        // settings.some(e => e.id === _column.id) ? setSettings(settings.filter(e => e.id !== _column.id)) : setSettings([...settings, _column])
        if (selected.some(e => e.key === _column.key))  {
            setSelected(selected.filter(e => e.key !== _column.key));
            setSettings([_column, ...settings]);

        } else {
            setSelected([...selected, _column]);
            setSettings(settings.filter(e => e.key !== _column.key));
        }
    }

    const isOk = JSON.stringify(selectedColumns) !== JSON.stringify(selected) && selected.length > 0;

    const handleSaveSettings = () => {
        isOk && handleSave(selected);
    }

    const findColumnSelected: (key: string) => {column: ColumnConfig, index: number} | null = useCallback(
        (key: string) => {
            const column = selected.find(e => e.key === key);
            // console.log(`findColumn for ${key} - ${JSON.stringify(column)} | ${column ? selected.indexOf(column) : null}`);
            return column ? {
                column,
                index: selected.indexOf(column)
            } : null;
        },
        [selected],
    )

    const moveColumnSelected: (key: string, atIndex: number) => void = useCallback(
        (key: string, atIndex: number) => {
            const res = findColumnSelected(key);
            if(res){
                const copyArr = JSON.parse(JSON.stringify(selected));
                // console.log(`copyArr: ${JSON.stringify(copyArr)}`);
                copyArr.splice(res.index, 1) //remove
                copyArr.splice(atIndex, 0, res.column) //insert
                setSelected(copyArr);


                // console.log(`moveColumn for ${key} - from ${res?.index} | to ${atIndex} \n new arr: ${JSON.stringify(reordered)}`);
            }else{
                // console.log(`--Column with key ${key} not found!`);
            }
        },
        [findColumnSelected, selected, setSelected],
    )

    const findColumnSettings: (key: string) => {column: ColumnConfig, index: number} | null = useCallback(
        (key: string) => {
            const column = settings.find(e => e.key === key);
            // console.log(`findColumn for ${key} - ${JSON.stringify(column)} | ${column ? settings.indexOf(column) : null}`);
            return column ? {
                column,
                index: settings.indexOf(column)
            } : null;
        },
        [settings],
    )

    const moveColumnSettings: (key: string, atIndex: number) => void = useCallback(
        (key: string, atIndex: number) => {
            const res = findColumnSettings(key);
            if(res){
                handleToggleSettingRow(res.column);
                const copyArr = JSON.parse(JSON.stringify(settings));
                copyArr.splice(res.index, 1) //remove
                copyArr.splice(atIndex, 0, res.column) //insert
                setSettings(copyArr);
            }else{
                // console.log(`--Column with key ${key} not found!`);
            }
        },
        //eslint-disable-next-line
        [findColumnSettings, settings, setSettings],
    )

    const [, selectedDrop] = useDrop(() => ({ accept: ItemTypes.CARD }))
    const [, settingsDrop] = useDrop(() => ({ accept: ItemTypes.CARD }))

    return(
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{tCommon('View settings')}</DialogTitle>

            <DialogContent>
                <SubTitle>{tCommon('Here you can add new columns and change their order.')}</SubTitle>

                <div ref={selectedDrop}>
                    {selected.map(e => <ColumnDraggableRow
                            column={e}
                            key={`selected_${e.key}`}
                            checked={selected.some(c => c.key === e.key)}
                            handleToggleSettingRow={handleToggleSettingRow}
                            disabled={selected.length < 2}
                            moveColumn={moveColumnSelected}
                            findColumn={findColumnSelected}
                        />
                    )}
                </div>

                <div ref={settingsDrop}>
                    {settings.map(e => <ColumnDraggableRow
                            column={e}
                            key={`variants_${e.key}`}
                            checked={selected.some(c => c.key === e.key)}
                            handleToggleSettingRow={handleToggleSettingRow}
                            disabled={settings.length < 2}
                            moveColumn={moveColumnSettings}
                            findColumn={findColumnSettings}
                        />
                    )}
                </div>
            </DialogContent>

            <DialogActions sx={{gap: '12px'}}>
                <Button size={'small'} onClick={handleClose} variant={'text'}>{tCommon('Close')}</Button>
                <LoadingButton
                    // sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    size={'small'}
                    onClick={handleSaveSettings}
                    disabled={!isOk}
                >
                    {tCommon('Save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}


interface Item {
    key: string;
    index: number;
}

export const ColumnDraggableRow = (
    {column, checked, findColumn, moveColumn, handleToggleSettingRow}: {
        column: ColumnConfig,
        handleToggleSettingRow: (column: ColumnConfig) => void,
        disabled: boolean,
        checked: boolean,
        moveColumn: (key: string, atIndex: number) => void,
        findColumn: (key: string) => {column: ColumnConfig, index: number} | null,
    }) => {

    const searchedColumn = findColumn(column.key);
    // console.log(`----COL searchedColumn: ${JSON.stringify(searchedColumn)}`);
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.CARD,
            item: {key: column.key, index: searchedColumn?.index ?? -1},
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const { key: droppedKey, index: originalIndex } = item;

                // console.log(`ROW useDrag END with item ${JSON.stringify(item)}`);
                const didDrop = monitor.didDrop()
                if (!didDrop && item) {
                    moveColumn(droppedKey, originalIndex);
                    // console.log(`ROW useDrag - moveColumn exec: ${droppedKey} | ${originalIndex}`);
                }
            },
        }),
        [column.key, searchedColumn, moveColumn],
    )

    const [, drop] = useDrop(
        () => ({
            accept: ItemTypes.CARD,
            hover({ key: draggedKey }: Item) {
                // console.log(`Card useDrop draggedKey:${draggedKey} in cardId ${column.key}`);
                if (draggedKey !== column.key) {
                    const col = findColumn(column.key)
                    if(col){
                        // console.log(`ROW useDrop - moveColumn exec: ${draggedKey} | ${col.index}`);
                        moveColumn(draggedKey, col.index);
                    }else{
                        // console.log(`ROW useDrop - col not found by key ${column.key}`)
                    }
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
                isActive: monitor.canDrop() && monitor.isOver(),
            }),
        }),
        [findColumn, moveColumn],
    )

    return(
        <ColumnRowBox ref={(node) => drag(drop(node))} isDragging={isDragging || undefined} >
            <IconButton>
                <DragIndicatorIcon />
            </IconButton>
            <CommonCheckbox disableRipple label={column.name} checked={checked} onChange={() => handleToggleSettingRow(column)} />
        </ColumnRowBox>
    )
}
