import {useEffect} from "react";

import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    BUILDER_WITH_FRAME,
    BUILDER_WITH_FRAME_AND_CONTROL,
    CONTROLS,
    ROOT_BUILDER,
    ROOT_CONTROLS,
    ROOT_FRAMEWORKS
} from "../../constants";
import {useBuilderFrameworks} from "../new/useBuilderFrameworks";
import {useRoutes} from "../new/useRoutes";
import {useDialogs} from "../new/useDialogs";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";

export const useControlExact = () => {
    const {clearFilters, currentSearchForFetch} = useGenericFiltersStorage();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    const controller = new AbortController();

    const {
        _frameworks, framework, _controls, control, evidences,
        _isLoading, isLoadingControls,
        getFrameworkById, getPrivateControls,
        deattachEvidence,
    } = useBuilderFrameworks();

    const {
        frameworkId, controlId,
        goToControls, goToFramework,
    } = useRoutes();

    const handleGoBack = () => {
        if (frameworkId) {
            goToFramework(frameworkId)
        } else {
            goToControls()
        }
    };

    const {
        openEditFrameworkControlDialog,
        handleOpenAddControlEvidence,
        handleOpenDeleteControl,
        handleOpenEditEvidence,
    } = useDialogs();

    useSetBreadcrumbs([
        {
            title: tMenu('Framework builder'),
            path: ROOT_BUILDER,
        },
        {
            title: frameworkId ? tMenu('Created frameworks') : tMenu('Created controls'),
            path: frameworkId ? ROOT_FRAMEWORKS : ROOT_CONTROLS,
        },
        {
            title: framework ? framework.name : 'Loading...',
            path: BUILDER_WITH_FRAME.replace(':frameworkId', frameworkId || ''),
            skip: !frameworkId,
        },
        {
            title: control ? control.name : '',
            path: frameworkId
                ? BUILDER_WITH_FRAME_AND_CONTROL.replace(':frameworkId', frameworkId || '').replace(':controlId', controlId || '')
                : CONTROLS.replace(':controlId', controlId || ''),
        }
    ]);


    useEffect(() => {
        if (frameworkId) {
            !_frameworks.length && getFrameworkById(frameworkId);
        } else {
            !_controls.length && getPrivateControls();
        }


        return () => {
            clearFilters();
            controller.abort();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return{
        controlId,
        handleGoBack,
        isLoading: _isLoading,
        isLoadingControls,
        control, evidences,

        handleOpenAddControlEvidence,
        handleOpenDeleteControl,
        openEditFrameworkControlDialog,
        handleOpenEditEvidence,

        isEmptyEvidences: !isLoadingControls && !_isLoading && (control && control.evidences && control.evidences
            ?.filter(e =>
                e?.name.trim().toLowerCase().includes(currentSearchForFetch.trim().toLowerCase())
                || e?.categories?.some(e => e.trim().toLowerCase().includes(currentSearchForFetch.trim().toLowerCase()))
            ).length === 0),

        deattachEvidence,
        currentSearchForFetch
    }
}