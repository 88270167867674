import {EditIntegrationCommonPropsType} from "../../../../types";
import {Flex} from "../../../../../../../newShared/components/Layouts";
import * as S from '../../../styled';
import React from "react";
import {LoadingButton} from "../../../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useEditConfluence} from "../../../../hooks/integrationsManagement/editIntegrationComponents/confluence";
import {useDispatch} from "react-redux";
import {useOAuth2} from "../../../../../../../newShared/components/oauth";
import {ATLASSIAN_URL, CONFLUENCE_CLIENTID, CONFLUENCE_REDIRECT_URI, CONFLUENCE_SCOPE} from "../../../../constants";
import {addErrorSnack} from "../../../../../../barsEnvironment/snack/store/slice";
import {Button, Icon} from "@mui/material";

export const EditIntegrationConfluence = (props: EditIntegrationCommonPropsType) => {
    const {
        form,
        actions,
        isOk,
        isLoading
    } = useEditConfluence(props);
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'pathIntegrations.AvailableAppsList.dialogs'});

    const dispatch = useDispatch();
    const atlassian = useOAuth2<{code: string, state: string}>({
        authorizeUrl: ATLASSIAN_URL,
        clientId: CONFLUENCE_CLIENTID,
        redirectUri: CONFLUENCE_REDIRECT_URI,
        scope: CONFLUENCE_SCOPE,
        responseType: "code",
        exchangeCodeForTokenServerURL: "",
        // exchangeCodeForTokenMethod: "POST",
        // extraQueryParameters:{
        //     audience: "https://api.atlassian.com",
        //     prompt:"consent"
        // },

        onSuccess: (res) => {
            actions.setForm(prev => ({...prev, integration: {...prev.integration, authorization_code: res.code}}));
        },
        onError: (error_) => dispatch(addErrorSnack(`Error while connecting to atlassian: ${error_}`))
    });

    return(
        <S.AppConfigContainer>
            <S.TextFieldCustom
                variant={'outlined'}
                name={'name'}
                label={t('Application name')}
                value={form.name}
                onChange={actions.handleChange}
                size={'small'}
            />

            {/*<S.TextFieldCustom*/}
            {/*    variant={'outlined'}*/}
            {/*    name={'organizationName'}*/}
            {/*    label={'Organization name'}*/}
            {/*    disabled*/}
            {/*    value={form.integration.organizationName}*/}
            {/*    onChange={actions.handleChangeIntegration}*/}
            {/*    sx={{margin: '16px 0 0 0'}}*/}
            {/*    size={'small'}*/}
            {/*/>*/}

            <Button variant={"outlined"} style={{margin: '16px 0 0 0'}} color={form.integration.authorization_code.trim().length > 0 ? 'success' : undefined}
                    startIcon={<Icon><img src={'https://cdn.svgporn.com/logos/atlassian.svg?response-content-disposition=attachment%3Bfilename%3Datlassian.svg'} alt={'Atlassian icon'} style={{maxHeight: '100%', maxWidth: '100%'}}/></Icon>}
                    onClick={() => atlassian.getAuth()}>
                {t('Sign in with Atlassian')}
            </Button>

            <Flex w={'100%'} jc={'flex-end'} m={'16px 0 0 0'}>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={actions.handleSubmit}
                    disabled={!isOk}
                >
                    {t('Save')}
                </LoadingButton>
            </Flex>
        </S.AppConfigContainer>
    )
}