import {useEffect, useState} from "react";
import {getToken} from "../../api";

export const useGetToken = () => {
    const [token, setToken] = useState<string | null>(null);

    useEffect(() => {
        getTokenAsync();
    }, []);

    const getTokenAsync = async() => {
        await getToken().then((data) => setToken(data));
    }

    return token;
}