import {useDispatch, useSelector} from "react-redux";
import {
    hideDeleteWSCollaboratorDialog,
    selectedWorkspace,
    workspacesDialogs,
    workspacesLoadings
} from "../../../../store/slice";
import {useMessageDialog} from "../../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {DeleteWorkspaceCollaborator} from "../../../../store/actions";

export const useDeleteWorkspaceCollabDialog = () => {
    //root
    const dispatch = useDispatch();
    const selected = useSelector(selectedWorkspace);
    const {isOpen, collab} = useSelector(workspacesDialogs).deleteCollaboratorDialog;
    const isLoading = useSelector(workspacesLoadings).deleteCollab;
    const {setMessage} = useMessageDialog();

    //actions
    const handleClose = () => {
        dispatch(hideDeleteWSCollaboratorDialog());
    }

    const handleSubmit = () => {
        if(selected && collab){
            dispatch(DeleteWorkspaceCollaborator({
                workspaceId: selected.id,
                publicId: collab.publicId,
                onSuccess
            }));
        }
    }

    const onSuccess = () => {
        collab && setMessage({
            title: 'Completed successfully!',
            message: `${collab.email} was deleted!`
        })
    }

    return{
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        collab,
        selected
    }
}