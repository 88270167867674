import {useDispatch, useSelector} from "react-redux";
import {
    useGenericFiltersStorage
} from "../../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {
    eraseIsDataOutdated,
    eraseMatches,
    isDataOutdated,
    loadings,
    matchesSelector,
    replaceMatchesMinMaxLoadedPage
} from "../../../store/slice";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    CORPORATE_TARGET_TYPE,
    CRYPTOWALLET_TARGET_TYPE,
    KYC_MATCHES_LIST_PATH,
    KYC_ROOT_PATH,
    KYC_SCREENING_EXACT_PATH,
    MATCHES_DEFAULT_PAGING,
    PERSON_TARGET_TYPE
} from "../../../constants";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useEffect} from "react";
import {GetKycMatches} from "../../../store/actions";
import {keyConfig} from "../../../../../../newShared/components/genericFilter/types";
import {TIdName} from "../../../../../../newShared/types";
import {autocompleteKycTemplatesByMatches} from "../../../api";
import {getNormalizedTargetType} from "../../../helpers";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../../newShared/components/genericTable/types";
import {KycMatchModel} from "../../../../../../newShared/GQLTypes";
import {useHistory} from "react-router";

export const useMatchesList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {tMenu} = useMainTranslation();

    const {currentSearchForFetch, clearFilters, currentFiltersForFetch} = useGenericFiltersStorage();

    const {list, pageInfo, minMaxLoadedPage: {minLoadedPage, maxLoadedPage}} = useSelector(matchesSelector);
    const isOutdated = useSelector(isDataOutdated).matchesList;
    const isLoading = useSelector(loadings).getMatchesList;
    // const [isInitial, setIsInitial] = useState<boolean>(true);

    //useEffects
    useSetBreadcrumbs([
        {
            title: tMenu('KYC Management'),
            path: KYC_ROOT_PATH
        },
        {
            title: tMenu('Matches'),
            path: KYC_MATCHES_LIST_PATH
        }
    ]);

    useEffect(() => {
        // dispatch(GetKycMatches({pageInfo: {page: 0, count: MATCHES_DEFAULT_PAGING}}));
        // setIsInitial(false);

        return () => {
            dispatch(eraseMatches());
            clearFilters();
        }
        //eslint-disable-next-line
    }, []);

    const handleReloadData = () => {
        const filters = currentFiltersForFetch;
        const search = currentSearchForFetch;

        dispatch(eraseMatches());
        dispatch(eraseIsDataOutdated());
        dispatch(GetKycMatches({pageInfo: {page: 0, count: MATCHES_DEFAULT_PAGING, filters: {...filters, ...(search.trim().length > 0 ? {nameLike: search} : {})}}}));
    }

    //filters config
    const templatesConfig: keyConfig<TIdName> = {
        type: 'filter',
        key: 'templateId',
        name: 'Template',
        fetchOptions: (workspaceId, data, signal) => {
            return autocompleteKycTemplatesByMatches({...data, workspaceId})
                .then(resp => ({values: resp.templates, pageInfo: resp.pageInfo}))
        },
        // options: _recipientVariants?.recipients ? _recipientVariants?.recipients : [],
        getFilterValue: (a) => a.id,
        getOptionLabel: (e) => e.name,
        isOptionEqual: (a, b) => a.id === b.id,
        isOptionEqualToSearch: (a, search) => a.name.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: true,
    }

    const createdDate: keyConfig<string> = {
        type: 'date',
        key: 'createdDate',
        name: 'Match date',
        default: true,
    }

    const templateType: keyConfig<string> = {
        type: 'filter',
        key: 'templateType',
        name: 'Type',
        options: [PERSON_TARGET_TYPE, CORPORATE_TARGET_TYPE, CRYPTOWALLET_TARGET_TYPE],
        getOptionLabel: (e) => getNormalizedTargetType(e),
        default: true
    };

    // const {} = useSelector(minMax)

    return{
        matches: list,
        pageInfo,
        isLoading,
        configs: [
            templatesConfig,
            templateType,
            createdDate
        ],
        handleReloadData,
        isOutdated,
        genericTable: {
            paging: {
                pageInfo,
                isLoading,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceMatchesMinMaxLoadedPage(data)),
                clearRows: () => dispatch(eraseMatches()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    dispatch(GetKycMatches({pageInfo: {page, count, filters}}));
                },
            },
        },
        handleViewResult: (match: KycMatchModel) => {
            history.push(KYC_SCREENING_EXACT_PATH.replace(':id', match.screeningId));
        }
    }
}
