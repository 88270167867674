import {withStyles} from "@mui/styles";
import {MenuItem} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
export const MenuItemNoHover = withStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
})(MenuItem);

export const MenuItemHoverBlue = withStyles({
    root: {
        '&:hover': {
            backgroundColor: colors.backgrounds.optional,
        },
    },
})(MenuItem);