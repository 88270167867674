import {Typography} from "@mui/material";

import React from "react";
import {KycTargetFieldModel, TKycTargetModel,} from "../../../../../../newShared/GQLTypes";
import colors from "../../../../../../newShared/theme/colors";
import {ValueGetterTypography} from "../style";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {CommonChips} from "../../../../../../newShared/components/CommonChips";
import {getFieldValue, getStatusTitleAndColor, getTargetRisk} from "../../../helpers";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {useSelector} from "react-redux";
import {kycDictionaries} from "../../../store/slice";


export const TableCellValueGetter = ({field, client, isDisabled}: {field: KycTargetFieldModel, client?: TKycTargetModel | null | undefined, isDisabled?: boolean}) => {
    const {revDir} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.clients'});
    const dicts = useSelector(kycDictionaries);
    // keyof KycClients
    const neededClientField = client?.fields?.find(e => e.id === field.id);

    if (!client && !neededClientField) {
        return <Typography variant={'subtitle1'} color={isDisabled ? colors.text.grey : colors.grayText}
                           noWrap>{('N/A')}</Typography>
    } else {
        // TEXT | DATE | FILE | NUMBER
        if (field.systemAlias === 'status' && client) return <CommonChips settings={getStatusTitleAndColor(client?.status)}  />;
        else if (field.systemAlias === 'templateName') return <ValueGetterTypography revDir={revDir || undefined} isDisabled={isDisabled || undefined}>{client?.templateName ?? '-'}</ValueGetterTypography>;
        else if (field.systemAlias === 'risk') return getTargetRisk(client?.risk ?? '');
        // else if (field.systemAlias === 'risk') return <ValueGetterTypography revDir={revDir || undefined} isDisabled={isDisabled || undefined}>{client?.risk ?? '-'}</ValueGetterTypography>;
        else if (field.systemAlias === 'lastModified') return <ValueGetterTypography revDir={revDir || undefined} isDisabled={isDisabled || undefined}>{parseDateAuto(client?.lastModified)}</ValueGetterTypography>;
        else if (field.type === 'DATE') return <ValueGetterTypography revDir={revDir || undefined} isDisabled={isDisabled || undefined}>{parseDateAuto(neededClientField?.value, false, true)}</ValueGetterTypography>;
        else if (field.type === 'NUMBER') return <ValueGetterTypography revDir={revDir || undefined} isDisabled={isDisabled || undefined}>{neededClientField?.value?.toString() ?? '-'}</ValueGetterTypography>;
        else if (field.type === 'TEXT') return <ValueGetterTypography revDir={revDir || undefined} isDisabled={isDisabled || undefined}>{neededClientField?.value ?? '-'}</ValueGetterTypography>;
        else if (field.type === 'SELECT') return <ValueGetterTypography revDir={revDir || undefined} isDisabled={isDisabled || undefined}>{neededClientField ? getFieldValue(neededClientField, dicts) : field.value}</ValueGetterTypography>;
        // else if (field.type === 'FILE') return <ValueGetterTypography isDisabled={isDisabled ? true : undefined}>{'field includes some file'}</ValueGetterTypography>;

        else return <ValueGetterTypography revDir={revDir ? true : undefined}>{getFieldValue(field, dicts)}</ValueGetterTypography>;
    }

}

