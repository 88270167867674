import axiosSync from "../../../../newShared/utils/axios/axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {AxiosResponse} from "axios";
import {
    checkPaymentResponse,
    cookieShortOrganization,
    cookieShortWorkspace,
    countryJsonType,
    createOrganizationType,
    createUpdateRoleRequestInput,
    ForbiddenWrapperOrganization,
    getWorkspaceCollabsResponseType,
    InvitationSignUpFormType,
    TBilling,
    TBillingInfo,
    TChangeUserInfo,
    TChangeUserPass,
    TInvitationPageInviteType,
    TNotCompletedOrganization,
    TOrganization,
    TSmallWorkspace,
    TWorkspaceInvite,
    TWorkspaceWithShortCollabs
} from "../types";
import {changeUserProfilePassGQL} from "../../settingsProfile/api/queries";
import {
    acceptInviteGQL,
    addOrganizationBusinessWithBillingGQL,
    addOrganizationBusinessWithoutPaymentGQL,
    addOrganizationEnterpriseGQL,
    addOrganizationFreeGQL,
    cancelWorkspaceInviteGQL,
    changeUserProfileInfoGQL,
    checkTransactionStatusGQL,
    createAccountByInvitationGQL,
    createRoleGQL,
    createWorkspaceGQL,
    declineInviteGQL,
    deleteNotCompletedOrganizationGQL,
    deleteRoleGQL,
    deleteWorkspaceCollaboratorGQL,
    deleteWorkspaceGQL,
    disableGoogleAuth,
    getCreateOrganizationDataGQL,
    getInviteByIdForAcceptGQL,
    getInvitesGQL,
    getOrganizationBillingGQL,
    getOrganizationByIdGQL,
    getOrganizationsGQL,
    getSmallOrgsAndWorkspacesForSelectGQL,
    getWorkspaceByIdGQL,
    getWorkspaceCollabsGQL,
    getWorkspacesGQL,
    importCsvTemplateCollaborators,
    inviteMemberIntoWorkspaceGQL,
    reconfigureGoogleAuthInSettings,
    resendWorkspaceInviteGQL,
    sendSupportMessageGQL,
    transferOrganizationOwnershipGQL,
    updateOrganizationBillingInfoGQL,
    updateOrganizationLogoGQL,
    updateOrganizationNameGQL,
    updateRoleGQL,
    updateUserRoleGQL,
    updateWorkspaceNameGQL,
    validate2faCode
} from "./query";
import {
    AddOrganizationBusinessWithBillingMutation,
    CreateOrganizationDto,
    DisableGoogleAuthMutation,
    DisableGoogleAuthMutationVariables,
    ReconfigureGoogleAuthInSettingsMutation,
    ReconfigureGoogleAuthInSettingsMutationVariables,
    SendSupportMessageMutation,
    SendSupportMessageMutationVariables,
    Validate2faCodeMutation,
    Validate2faCodeMutationVariables
} from "../../../../newShared/GQLTypes";
import {TPageInfo} from "../../../../newShared/types";
import {print} from "graphql";


export const getInvites = async (): Promise<TWorkspaceInvite[]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getInvitesGQL),
                variables: {
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getInvites;
    })
)

export const acceptInvite = async (inviteId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(acceptInviteGQL),
                variables: {
                    inviteId,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.acceptInvite.message;
    })
)

export const declineInvite = async (inviteId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(declineInviteGQL),
                variables: {
                    inviteId,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.declineInvite.message;
    })
)

//user profile

export const changeCurrentUserInfo = async (data: TChangeUserInfo) => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(changeUserProfileInfoGQL),
                variables: {
                    data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.changeName as {message: string}
    })
)


//SECURITY
export const changeCurrentUserPass = async (data: TChangeUserPass) => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(changeUserProfilePassGQL),
                variables: {
                    data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.changePassword as {message: string}
    })
)

export const validate2faCodeApi = async (data: Validate2faCodeMutationVariables): Promise<Validate2faCodeMutation["initialValidateGoogleAuthorization"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(validate2faCode),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.initialValidateGoogleAuthorization
    })
)

export const reconfigureGoogleAuthApi = async (data: ReconfigureGoogleAuthInSettingsMutationVariables): Promise<ReconfigureGoogleAuthInSettingsMutation["reconfigureGoogleAuth"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(reconfigureGoogleAuthInSettings),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.reconfigureGoogleAuth
    })
)

export const disableGoogleAuthApi = async (data: DisableGoogleAuthMutationVariables): Promise<DisableGoogleAuthMutation["disableGoogleAuth"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(disableGoogleAuth),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.disableGoogleAuth
    })
)

export const getOrganizations = async (page: number, count: number, name: string): Promise<{organizations: TOrganization[], pageInfo: TPageInfo}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getOrganizationsGQL),
                variables: {
                    pageInfo: {
                        page,
                        count,
                        filters: {
                            name: name.trim().length > 0 ? [name] : []
                        }
                    }
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getOrganizations
    })
)

export const getOrganizationById = async (id: string): Promise<TOrganization> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getOrganizationByIdGQL),
                variables: {
                    id
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getOrganizationById
    })
)


export const getWorkspaces = async (page: number, count: number, name: string, id: string): Promise<{workspaces: TSmallWorkspace[], pageInfo: TPageInfo}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getWorkspacesGQL),
                variables: {
                    id,
                    pageInfo: {
                        page,
                        count,
                        filters: {
                            name: name.trim().length > 0 ? [name] : []
                        }
                    }
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getWorkspaces
    })
)

// export const updateOrganizationByLicenseCode = async (organizationId: string, licenseCode: string): Promise<updateOrganizationByLicenseCodeResponse> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                 mutation updateOrganizationByLicenseCode($licenseCode:String!, $organizationId:String!){
//                   updateOrganizationByLicenseCode(licenseCode:$licenseCode, organizationId:$organizationId){
//                     subscription
//                     subscriptionExpiry
//                     configuration{
//                       availableWizards
//                       availableWorkspaceCount
//                       availableCollaboratorsCount
//                     }
//                     id
//                   }
//                 }
//                 `,
//                 variables: {
//                     organizationId,
//                     licenseCode
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.updateOrganizationByLicenseCode;
//     })
// )

export const sendSupportMessageAPI = async (variables: SendSupportMessageMutationVariables): Promise<SendSupportMessageMutation["sendSupportMessage"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(sendSupportMessageGQL),
                variables,
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.sendSupportMessage;
    })
)


// export const getOrganizationCollaborators = async (id: string): Promise<{ collaborators: TExtendedCollaborator[]}> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                 query getOrganizationCollaborators($id:String!){
//                   getOrganizationCollaborators(id:$id){
//                     collaborators{
//                       createDate
//                       lastName
//                       firstName
//                       lastLoginDate
//                       status
//                       email
//                       role
//                       publicId
//                     }
//                   }
//                 }
//                 `,
//                 variables: {
//                     id
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.getOrganizationCollaborators;
//     })
// )

// export const cancelOrganizationInvite = async (organizationId: string, email: string, inviteId: string): Promise<string> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                 mutation cancelOrganizationInvite($organizationId: String!, $email:String!, $inviteId: String!){
//                   cancelOrganizationInvite(organizationId:$organizationId, email:$email, inviteId:$inviteId){
//                     message
//                   }
//                 }
//                 `,
//                 variables: {
//                     organizationId,
//                     email,
//                     inviteId
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.cancelOrganizationInvite.message;
//     })
// )

// export const resendOrganizationInvite = async (organizationId: string, email: string, inviteId: string): Promise<string> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                 mutation resendOrganizationInvite($organizationId: String!, $email:String!, $inviteId: String!){
//                   resendOrganizationInvite(organizationId:$organizationId, email:$email, inviteId:$inviteId){
//                     message
//                   }
//                 }
//                 `,
//                 variables: {
//                     organizationId,
//                     email,
//                     inviteId
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.resendOrganizationInvite.message;
//     })
// )

// export const updateInviteRole = async (organizationId: string, email: string, newRole: string, inviteId: string): Promise<string> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                 mutation changeInviteRole($organizationId:String!, $email:String!, $newRole:String!, $inviteId:String!){
//                   updateInviteRole(organizationId:$organizationId, email:$email, newRole:$newRole, inviteId:$inviteId){
//                     message
//                   }
//                 }
//                 `,
//                 variables: {
//                     organizationId,
//                     email,
//                     newRole,
//                     inviteId
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.updateInviteRole.message;
//     })
// )


// export const updateCollabRole = async (organizationId: string, publicId: string, newRole: string): Promise<string> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: print(gql`
//                 mutation changeCollabRole($organizationId:String!, $publicId:String!, $newRole:String!){
//                   updateCollaboratorRole(organizationId:$organizationId, publicId:$publicId, newRole:$newRole){
//                     message
//                   }
//                 }
//                 `),
//                 variables: {
//                     organizationId,
//                     publicId,
//                     newRole
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.updateCollaboratorRole.message;
//     })
// )

export const createWorkspace = async (organizationId: string, workspaceName: string): Promise<TWorkspaceWithShortCollabs> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(createWorkspaceGQL),
                variables: {
                    organizationId,
                    workspaceName,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addWorkspace;
    })
)


export const updateOrganizationName = async (organizationId: string, newName: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(updateOrganizationNameGQL),
                variables: {
                    organizationId,
                    newName
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateOrganizationName.message;
    })
)

export const updateOrganizationLogo = async (organizationId: string, newLogo: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(updateOrganizationLogoGQL),
                variables: {
                    organizationId,
                    newLogo
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateOrganizationLogo.message;
    })
)

// export const inviteMemberIntoOrg = async (organizationId: string, email: string, role: string, senderPublicId: string): Promise<OrganizationInviteType> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: print(gql`
//                     mutation inviteMemberIntoOrg($organizationId:String!, $email:String!, $senderPublicId:String!, $role:String!){
//                         inviteMemberIntoOrg(organizationId:$organizationId, email:$email, senderPublicId:$senderPublicId, role:$role){
//                             id
//                             emailAddress
//                             expirationDate
//                             activationDate
//                             role
//                             sender{
//                                 role
//                                 publicId
//                             }
//                             inviteStatus
//
//                         }
//                     }
//                 `),
//                 variables: {
//                     organizationId,
//                     email,
//                     role,
//                     senderPublicId,
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.inviteMemberIntoOrg;
//     })
// )

export const transferOrganizationOwnership = async (organizationId: string, transferToPublicId: string, transferFromPublicId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(transferOrganizationOwnershipGQL),
                variables: {
                    organizationId,
                    transferToPublicId,
                    transferFromPublicId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.transferOrganizationOwnership.message;
    })
)

// export const deleteOrganizationCollaborator = async (organizationId: string, publicId: string): Promise<string> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                 mutation deleteOrganizationCollab($publicId:String!,$organizationId:String!){
//                   deleteOrganizationCollaborator(publicId:$publicId, organizationId:$organizationId){
//                     message
//                   }
//                 }
//                 `,
//                 variables: {
//                     organizationId,
//                     publicId
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.deleteOrganizationCollaborator.message;
//     })
// )


export const getWorkspaceById = async (organizationId: string, workspaceId: string): Promise<{workspace: TSmallWorkspace, organization: TOrganization}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getWorkspaceByIdGQL),
                variables: {
                    organizationId,
                    workspaceId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getRedesignWorkspaceById;
    })
)

export const getWorkspaceCollabs = async (workspaceId: string): Promise<getWorkspaceCollabsResponseType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getWorkspaceCollabsGQL),
                variables: {
                    workspaceId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getWorkspaceUsersById;
    })
)

export const deleteWorkspaceCollaborator = async (workspaceId: string, publicId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(deleteWorkspaceCollaboratorGQL),
                variables: {
                    workspaceId,
                    publicId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteWorkspaceCollaborator.message;
    })
)

export const updateWorkspaceName = async (workspaceId: string, newName: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(updateWorkspaceNameGQL),
                variables: {
                    workspaceId,
                    newName
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateWorkspaceName.message;
    })
)

export const deleteWorkspace = async (workspaceId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(deleteWorkspaceGQL),
                variables: {
                    workspaceId,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteWorkspace.message;
    })
)

export const inviteMemberIntoWorkspace = async (workspaceId: string, email: string, roles: string[], senderPublicId: string): Promise<getWorkspaceCollabsResponseType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(inviteMemberIntoWorkspaceGQL),
                variables: {
                    workspaceId,
                    email,
                    roles: {roles},
                    senderPublicId,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        try{
            return result.data.data.inviteMemberIntoWorkspace;
        }catch (e){
            throw new Error(result.data.errors[0].message);
        }
    })
)

export const updateUserRole = async (workspaceId: string, publicId: string, roles: string[]): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(updateUserRoleGQL),
                variables: {
                    workspaceId,
                    publicId,
                    roles: {roles}
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateUserRole.message;
    })
)

export const resendWorkspaceInvite = async (workspaceId: string, email: string, inviteId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(resendWorkspaceInviteGQL),
                variables: {
                    workspaceId,
                    email,
                    inviteId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.resendWorkspaceInvite.message;
    })
)

export const cancelWorkspaceInvite = async (workspaceId: string, email: string, inviteId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(cancelWorkspaceInviteGQL),
                variables: {
                    workspaceId,
                    email,
                    inviteId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteWorkspaceInvite.message;
    })
)

// export const changeWSInviteRole = async (workspaceId: string, email: string, newRole: string, inviteId: string): Promise<string> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                 mutation changeWSInviteRole($workspaceId:String!, $email:String!, $newRole:String!, $inviteId:String!){
//                     updateWorkspaceInviteRole(workspaceId:$workspaceId, email:$email, newRole:$newRole, inviteId:$inviteId){
//                         message
//                     }
//                 }
//                 `,
//                 variables: {
//                     workspaceId,
//                     email,
//                     newRole,
//                     inviteId
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.updateWorkspaceInviteRole.message;
//     })
// )


export const createRole = async (data: createUpdateRoleRequestInput): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(createRoleGQL),
                variables: {
                    data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createRole.message;
    })
)




export const deleteRole = async (workspaceId: string, roleId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(deleteRoleGQL),
                variables: {
                    workspaceId,
                    roleId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteRole.message;
    })
)


export const updateRole = async (data: createUpdateRoleRequestInput): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(updateRoleGQL),
                variables: {
                    data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.editRole.message;
    })
)

export const getInviteByIdForAccept = async (inviteId: string): Promise<TInvitationPageInviteType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getInviteByIdForAcceptGQL),
                variables: {
                    inviteId,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getInviteByIdForAccept;
    })
)

export const createAccountByInvitation = async (data: InvitationSignUpFormType): Promise<{message: boolean, qrCode: string, secret: string}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(createAccountByInvitationGQL),
                variables: {
                    data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createAccountByInvitation;
    })
)


export const getSmallOrgsAndWorkspacesForSelect = async (): Promise<{organizations: ForbiddenWrapperOrganization[], portalAccess: boolean}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getSmallOrgsAndWorkspacesForSelectGQL),
                variables: {
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getSmallOrgsAndWorkspacesForSelect;
    })
)

//used in automations
// export const getOrganizationMembers = async (organizationId: string): Promise<TExtendedCollaborator[]> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: print(gql`
//                     query getOrganizationMembers($organizationId:String!){
//                         getProfilesOfOrganizationMembers(organizationId: $organizationId){
//                             firstName
//                             lastName
//                             email
//                             publicId
//                             role
//                             status
//                             lastLoginDate
//                             createDate
//                         }
//                     }
//                 `),
//                 variables: {
//                     organizationId
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.getProfilesOfOrganizationMembers;
//     })
// )

// export const joinWorkspaceByOrgAdmin = async (workspaceId: string): Promise<string> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: print(gql`
//                     mutation joinWorkspaceByOrgAdmin($workspaceId:String!){
//                         joinWorkspaceByOrgAdmin(workspaceId:$workspaceId){
//                             message
//                         }
//                     }
//                 `),
//                 variables: {
//                     workspaceId
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.joinWorkspaceByOrgAdmin.message;
//     })
// )

export const getCreateOrganizationData = async (): Promise<{notCompletedOrganization: TNotCompletedOrganization | null, countries: countryJsonType[], industries: string[]}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getCreateOrganizationDataGQL),
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getCreateOrganizationData;
    })
)

export const addOrganizationFree = async (data: createOrganizationType): Promise<{workspace: cookieShortWorkspace, organization: cookieShortOrganization}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(addOrganizationFreeGQL),
                variables: {
                    data: {...data, name: data.name.trim()},
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addOrganizationFree;
    })
)

export const addOrganizationEnterprise = async (data: createOrganizationType): Promise<{workspace: cookieShortWorkspace, organization: cookieShortOrganization}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(addOrganizationEnterpriseGQL),
                variables: {
                    data: {...data, name: data.name.trim()},
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addOrganizationEnterprise;
    })
)

export const addOrganizationBusinessWithoutPayment = async (data: createOrganizationType): Promise<{workspace: cookieShortWorkspace, organization: cookieShortOrganization}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(addOrganizationBusinessWithoutPaymentGQL),
                variables: {
                    data: {...data, name: data.name.trim()},
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addOrganizationBusinessWithoutPayment;
    })
)

export const addOrganizationBusinessWithBilling = async (data: CreateOrganizationDto, calculatedTotal: number, billingData: TBillingInfo): Promise<AddOrganizationBusinessWithBillingMutation["addOrganizationBusinessWithBilling"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(addOrganizationBusinessWithBillingGQL),
                variables: {
                    data: {...data, name: data.name.trim()},
                    billingData,
                    calculatedTotal
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addOrganizationBusinessWithBilling;
    })
)

//
export const deleteNotCompletedOrganization = async (): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(deleteNotCompletedOrganizationGQL)
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteNotCompletedOrganization.message;
    })
)


export const checkTransactionStatus = async (transactionNumber: string): Promise<checkPaymentResponse> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(checkTransactionStatusGQL),
                variables: {
                    transactionNumber
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.checkTransactionStatus;
    })
)

export const getOrganizationBilling = async (organizationId: string): Promise<TBilling> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getOrganizationBillingGQL),
                variables: {
                    organizationId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getOrganizationBilling;
    })
)

export const updateOrganizationBillingInfo = async (orgId: string, billingData: TBillingInfo): Promise<TBilling> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(updateOrganizationBillingInfoGQL),
                variables: {
                    orgId,
                    billingData
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateOrganizationBillingInfo;
    })
)

export const importCsvTemplateCollaboratorsApi = async (data: {workspaceId: string}): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(importCsvTemplateCollaborators),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.importCsvTemplateCollaborators;
    })
)

