import React from "react";
import {PageWrapper} from "../../../../../newShared/components/style";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {MainTrainingShortAssignmentModel} from "../../../../../newShared/GQLTypes";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {getTrainingAssignmentStatusChip} from "../../helpers";
import {Add} from "@mui/icons-material";
import {useAssignmentsListPage} from "../../hooks/useAssignmentsListPage";
import {CreateAssignmentDialog} from "../../components/dialogs/createAssignment";


export const AssignmentsList = () => {

    const {t} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.pathAssignments'});
    const {isMobile} = useMedia();

    const {
        assignments,
        handleOpenExact,
        handleOpenCreate,
        genericTable,
    } = useAssignmentsListPage();

    return (
        <PageWrapper>
            <PageTitleAndActions title={t('Assignments')} actions={[
                {title: t('New assignment'), onClick: handleOpenCreate, icon: <Add/>, size: "small"},
            ]} margin={'0 0 8px 0'} />

            <GenericTable<MainTrainingShortAssignmentModel>
                id={'assignmentsList'}
                columnsConfig={{totalName: 'Total assignments', disableColumnsSettings: true, forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: assignments,
                    customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id,
                    onRowClick: handleOpenExact,

                    columnsConfigs: [
                        {key: "id", name: t('ID'), default: true, },
                        {key: 'requestor', name: t('Requestor'), default: true},
                        {key: 'recipient', name: t('Recipient'), default: true},
                        {key: 'trainingName', name: t('Training name'), default: true, valueGetter: (row) => row.trainingName || '-'},
                        {key: 'examName', name: t('Exam name'), default: true, valueGetter: (row) => row.examName || '-'},
                        {key: 'status', name: t('Status'), default: true, valueRenderer: (row) => getTrainingAssignmentStatusChip(row.status)},
                        {key: 'startDate', name: t('Start date'), default: true, valueGetter: (row) => parseDateAuto(row.startDate)},
                        {key: 'endDate', name: t('Due date'), default: true, valueGetter: (row) => parseDateAuto(row.endDate)},
                    ],
                }}
                filtersConfig={{
                    searchCustomFilterKeyName: 'assignmentIdLike',
                    // searchAsArray: true,
                    dontSendSearchIfEmpty: true,
                    genericFilterProps: {
                        configs: genericTable.filters.configs,
                        fetchResultWithSelectedFilters: () => null,
                    }
                }}
                emptyArrayImageProps={{type: 'any', filterNotApplied: {text: t('You do not have any assignments yet'), secondaryText: ''}}}
            />


            <CreateAssignmentDialog />
        </PageWrapper>
    )
}
