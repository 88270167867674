import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {usePreviewErrorReason} from "../../../hooks/dialogHooks/usePreviewErrorReason";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const PreviewErrorReason = () => {
    const {
        isOpen,
        handleClose,
        history
    } = usePreviewErrorReason();
    const {t} = useMainTranslation('', {keyPrefix: 'pathIntegrations.ExactApp.dialogs'});

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t(`Error reason`)}</DialogTitle>

            <DialogContent>
                <DialogContentText>
                    <Typography dir={'ltr'} variant={'body2'} sx={{margin: '8px 0 0 0'}}>{history?.errorReason ?? t('No errors found.')}</Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{t('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}