import {useDispatch, useSelector} from "react-redux";
import {
    assetsDialogsSelector,
    incomingRequestsSelector,
    loadings,
    setRejectingDialogAction
} from "../../../store/store";
import {mainAssetsRequestsRejectAction} from "../../../store/actions";
import * as yup from "yup";
import {useFormik} from "formik";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {getName} from "../../../../../../newShared/utils/text";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useHistory, useLocation} from "react-router";
import {PATH_LOCAL_ASSETS_INCOMING_REQUESTS} from "../../../constants";
import {usePhysicalAssetsRequestsExact} from "../../usePhysicalAssetsRequestsExact";
import React from "react";

type TResponse = {
    isOpen: boolean,
    isLoading: boolean,

    handleOpen: () => void,
    handleClose: () => void,

    handleReject: (resolution: string) => void,
} & Record<string, any>;

export const useRejectAssetsRequest = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets.pathIncomingRequests'});
    const {setMessage} = useMessageDialog();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const {selected} = useSelector(incomingRequestsSelector);
    const requestId = selected?.request?.id;

    const {rejectRequest: {isOpen}} = useSelector(assetsDialogsSelector);
    const {incomingRequestRejecting} = useSelector(loadings);

    const handleOpen = () => {
        dispatch(setRejectingDialogAction({isOpen: true}))
    }
    const handleClose = () => {
        dispatch(setRejectingDialogAction({clean: true}))
    }

    const {getRequestById} = usePhysicalAssetsRequestsExact();
    const {currData: {currentUser}} = UseManageWorkspacesAndOrganizations();
    const handleReject = (resolution: string) => {
        requestId && dispatch(mainAssetsRequestsRejectAction({
            data: {data: {requestId: requestId, resolution}},
            addictiveData: {resolver: {id: currentUser?.publicId || '', name: getName(currentUser?.firstName || '', currentUser?.lastName || '')}},
            onError: (request, _error, addictiveData) => {
                const errors = _error.e409;
                if (errors?.length) {
                    getRequestById(requestId);
                }
            },
            onSuccess: (request, response) => {
                setMessage({
                    title: t('Completed successfully'),
                    message: t(`Request from {{fullName}} was rejected`, {fullName: selected.request.requestor.fullName} )
                });
                history.push(`${PATH_LOCAL_ASSETS_INCOMING_REQUESTS}${location.search}`);
            },
        }))
    }

    const validationSchema = yup.object({
        comment: yup.string()
            .required(t('Comment field is required'))
            .max(1000, t('Comment should be less than 1000 symbols'))
    });

    const formik = useFormik({
        initialValues: {comment: ''},
        validationSchema,
        onSubmit: (values) => {
            handleReject(values.comment);
        },
        isInitialValid: false
    })
    const formikOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldTouched(e.target.name, true, false);
        formik.handleChange(e);
    };

    return {
        isOpen,
        isLoading: incomingRequestRejecting,

        handleOpen,
        handleClose,

        handleReject,

        formik: {...formik, handleChange: formikOnChange},
    } satisfies TResponse;
};
