import {useDispatch, useSelector} from "react-redux";
import {
    assetsDialogsSelector,
    cleanSaas,
    replaceMinMaxLoadedPage,
    setImportCsvDialogAction
} from "../../../store/store";
import {
    getCsvFieldsSAASAction,
    getSaasAssetsAction,
    importCsvTemplateSaasAssetsAction,
    performCsvSAASAction
} from "../../../store/actions";
import {TImportCsvDialogProps} from "../../../../../../newShared/components/csvImportDialog";
import {resetImportCsvDialog} from "../../../../../../newShared/components/csvImportDialog/constants";
import {DEFAULT_PAGING} from "../../../../../../newShared/components/genericTable/constants";
import {
    useGenericFiltersStorage
} from "../../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";

export const useImportCsvSAAS = (): TImportCsvDialogProps => {
    const dispatch = useDispatch();
    const {importCsvDialog: {
        isOpen,
        isLoading: {
            isGettingFields,
            isPerformingCsv,
            isLoadingTemplate,
        },
        fields,
        fileId,
        response,
    }} = useSelector(assetsDialogsSelector);

    const setImportDialog: TImportCsvDialogProps["setImportDialog"] = (data) => dispatch(setImportCsvDialogAction(data));
    const downloadTemplate = () => {dispatch(importCsvTemplateSaasAssetsAction())};

    const getFields: TImportCsvDialogProps["getFields"] = (file) => {dispatch(getCsvFieldsSAASAction({
        data: {...file},
        onError: resetImportCsvDialog,
    }))};

    const {clearFilters} = useGenericFiltersStorage();

    const performCsv: TImportCsvDialogProps["performCsv"] = (fields) => {fileId && dispatch(performCsvSAASAction({
        data: {fields, fileId},
        // snack: "success",
        // onError: (request, error, addictiveData) => {dispatch(setImportCsvDialogAction({error: error[0]?.message || 'Unknown error'}))},
        onSuccess: (_, response) => {
            clearFilters();
            dispatch(cleanSaas());
            dispatch(replaceMinMaxLoadedPage({minLoadedPage: -1, maxLoadedPage: -1}))
            dispatch(getSaasAssetsAction({data: {page: 0, count: DEFAULT_PAGING, filters: {}}}))
        },
    }))};

    return {
        isOpen,
        isLoadingTemplate, isPerformingCsv, isGettingFields,
        fields,
        setImportDialog,
        downloadTemplate,
        getFields,
        performCsv,
        response,
        fieldsMapperKey: "assets"
    }
}
