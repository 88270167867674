import {useDispatch, useSelector} from "react-redux";
import {
    assetsDialogsSelector,
    assetsSettingsSelector,
    incomingRequestsSelector,
    loadings as loadingsSelector,
    setCreateAndAssignRequestAction
} from "../../../store/store";
import * as yup from "yup";
import {useFormik} from "formik";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import React, {useState} from "react";
import {getPhysicalAssetsSettingsAction, mainAssetsCreateAndAssignAssetAction} from "../../../store/actions";
import {useHistory, useLocation} from "react-router";
import {PATH_LOCAL_ASSETS_INCOMING_REQUESTS} from "../../../constants";
import {getName} from "../../../../../../newShared/utils/text";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {usePhysicalAssetsRequestsExact} from "../../usePhysicalAssetsRequestsExact";


export const useCreateAndAssignNewAsset = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const {t} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets.dialogs'})
    const settings = useSelector(assetsSettingsSelector);
    const {createAndAssign: {isOpen}} = useSelector(assetsDialogsSelector);
    const {isLoadingSettings, approveRequest: isLoadingApprove} = useSelector(loadingsSelector);
    const {selected} = useSelector(incomingRequestsSelector);
    const {setMessage} = useMessageDialog();

    const [customFields, setCustomFields] = useState<Record<string, string>>({})

    const {getRequestById} = usePhysicalAssetsRequestsExact();
    const {currData: {currentUser}} = UseManageWorkspacesAndOrganizations();
    const handleConfirm = () => {
        selected && dispatch(mainAssetsCreateAndAssignAssetAction({
            data: {
                data: {
                    requestId: selected.request.id,
                    assetId: formik.values.assetId,
                    resolution: formik.values.resolution,
                    customFields: Object.keys(customFields)?.length ? customFields : {},
                }},
            addictiveData: {resolver: {id: currentUser?.publicId || '', name: getName(currentUser?.firstName || '', currentUser?.lastName || '')}},
            onError: (request, _error, addictiveData) => {
                const errors = _error.e409;
                if (errors?.length) {
                    getRequestById(selected.request.id);
                }
            },
            onSuccess: (request, response) => {
                setMessage({
                    title: t('Completed successfully'),
                    message: t(`Asset with id {{assetId}} was created and assigned to {{fullName}}`, {assetId: response.id, fullName: selected.request.requestor.fullName} )
                });
                history.push(`${PATH_LOCAL_ASSETS_INCOMING_REQUESTS}${location.search}`);
            },
        }))

    }

    const handleClose = () => {
        dispatch(setCreateAndAssignRequestAction({clear: true}))
    }

    const handleChangeCustomField = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setCustomFields((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }));
    };

    const getSettings = () => dispatch(getPhysicalAssetsSettingsAction({data: {organizationId: '', workspaceId: ''}}));

    const openCreateAndAssignDialog = () => dispatch(setCreateAndAssignRequestAction({isOpen: true}));

    let validationSchema = yup.object({
        assetId: yup.string()
            .min(3, t('Serial should be more than 3 symbols'))
            .matches(/^[a-zA-Z0-9-_]{3,50}$/, 'Alphanumeric characters allowed, along with \'-\' and \'_\'')
            .max(50, t('ID should be less than 50 symbols')),
        serial: yup.string()
            .required(t('Serial field is required'))
            .min(3, t('Serial should be more than 3 symbols'))
            .matches(/^[a-zA-Z0-9-_]{3,50}$/, 'Alphanumeric characters allowed, along with \'-\' and \'_\'')
            .max(50, t('Serial should be less than 50 symbols')),
        model: yup.string()
            .nullable()
            .oneOf(settings ? settings.model.map(e => e.name) : [], 'Should be in settings')
            .required(t('Model field is required')),
        category: yup.string()
            .nullable()
            .oneOf(settings ? settings.category.map(e => e.name) : [], 'Should be in settings')
            .required(t('Category field is required')),
        manufacture: yup.string()
            .nullable()
            .oneOf(settings ? settings.manufacture.map(e => e.name) : [], 'Should be in settings')
            .required(t('Manufacturer field is required')),
        resolution: yup.string()
            .nullable()
            .max(1000, t('Comment should be less than 1000 symbols')),
    });

    const formik = useFormik({
        initialValues: {assetId: '', serial: '', model: '', category: '', manufacture: '', resolution: ''},
        validationSchema,
        onSubmit: handleConfirm,
        isInitialValid: false
    });
    const formikOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldTouched(e.target.name, true, false);
        formik.handleChange(e);
    };

    return {
        isOpen,
        selected,
        formik: {...formik, handleChange: formikOnChange},
        settings,
        customFields,
        handleChangeCustomField,
        handleClose,
        handleConfirm,
        isLoadingSettings,
        isLoadingApprove,

        openCreateAndAssignDialog,
        getSettings,
        setCustomFields,
    }
}
