import styled from "styled-components";
import {device} from "../../../../../newShared/constants";
import colors from "../../../../../newShared/theme/colors";
import {TextField} from "@mui/material";

export const AutomationsTableHeaderWrapper = styled.div<{minWidth?: string, width?: string, p?: string}>`
    ${props => props.minWidth && `min-width: ${props.minWidth};`}
    display: flex;
    width: ${props => props.width ? props.width : '90%'};
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: space-between;
    align-items: center;
    padding: ${props => props.p ? props.p : '25px 0 24px 0'};
    ${device.tablet} {
        width: 100%;
        margin: 25px 0 24px 0;
    }
`;

export const TableHeaderItemWrapper = styled.div<{ width?: string, ai?: string}>`
    width: ${props => props.width ?? '24%'};
    display: flex;
    align-items: ${props => props.ai ?? 'center'};
    & p {
        text-overflow: ellipsis; 
        display: block; 
        overflow: hidden; 
        line-height: normal;
    }
`;

export const MainTableScrollWrapper = styled.div`
    overflow: auto;
    height: 100%;
    width: 100%;
    
    ${device.generateMax(858)}{
        display: flex;
        flex-wrap: wrap;
        margin: 16px 0 0 0;
        padding: 0 5px;
        height: initial;
    }
`;

export const MainTableRowWrapper = styled.div<{disableHover?: boolean, width?: string, minWidth?: string}>`
    ${props => props.minWidth && `min-width: ${props.minWidth};`}
    width: ${props => props.width ?? '100%'};
   
    ${props => props.disableHover ?'' : `
        &:hover{
            background-color: ${colors.backgrounds.grey_light};
            cursor: pointer;
        }
    `}
`;

export const TableRowWrapper = styled.div<{disableHover?: boolean, jc?: string, width?: string}>`
    width: ${props => props.width ?? '90%'};
    display: flex;
    justify-content: ${props => props.jc ?? 'space-between'};
    align-items: center;
    ${props => props.disableHover ?'' : `
        &:hover{
            background-color: ${colors.backgrounds.grey_light};
            cursor: pointer;
        }
    `}
   
    ${device.tablet} {
        width: 100%;
    }
`;

export const TableRowItemWrapper = styled.div<{width?: string}>`
    width: ${props => props.width ?? '24%'};
    display: flex;
    align-items: center;
    // justify-content: center;
    padding: 12px 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    & p {
        text-overflow: ellipsis; 
        display: block; 
        overflow: hidden; 
        line-height: normal;
    }
`;

export const UserCardWrapper = styled.div<{revDir: boolean, width?: string, p?: string, fr?: string}>`
    margin: 0 auto;
    width: ${props => props.width ?? 'calc(100% - 32px)'};
    min-width: 330px;
    // border: 1px solid black;
    border-radius: 8px;
    ${device.desktopNarrow}{
        width: 100%;
    }
    padding: ${props => props.p ?? '10px 20px'};
    margin: ${props => !props.revDir ? '0 16px 16px 0' : '0 16px 16px 0'};
    
    display: flex;
    flex-direction: ${props => props.fr ?? 'column'};
    box-shadow: 0px 0px 1px 0px rgba(125, 151, 174, 0.02), 0px 2px 6px 0px rgba(125, 151, 174, 0.02), 0px 8px 16px 0px rgba(125, 151, 174, 0.08);
`;

export const DialogContentWrapper = styled.div<{margin?: string}>`
    width: 50%;
    ${props => props.margin && `margin: ${props.margin};`}

    ${device.tablet}{
        width: 60%;
    }
    ${device.desktopNarrow}{
        width: 100%;
    }
`;

export const TextFieldCustom = styled(TextField)`
  & .MuiInputBase-input {
    height: 16px;
  }
  & label{
    top: 0 !important;
  }
`;

export const ListPageTopButtonContainer = styled.div`
    display: flex;
    margin: 8px 0 0 0;
    align-items: center;
    ${device.generateMax(1049)} {
        width: 100%;
        justify-content: space-between;
    }
    ${device.generateMax(501)} {
        justify-content: space-between;
        margin: 11px 0 0 0;
    }
`;

export const AccessManagementAppOverviewWrapper = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 16px 0 0 0;
    ${device.desktopNarrow} {
        width: 100%;
        flex-direction: column;
    }
`;

export const CreateRowBlockWrapper = styled.div`
    width: 326px;
    ${device.desktopNarrow} {
        width: 100%;
    }
`;

export const CreateInstructionWrapper = styled.div`
    overflow: auto;
    padding: 0 16px 0 0;
    width: 40%;
    ${device.generateMax(1000)}{
        width: 60%;
    }
    ${device.tablet}{
        width: 80%;
    }
    ${device.desktopNarrow} {
        width: 100%;
    }
`;

export const ExactInstructionWrapper = styled.div`
    overflow: auto;
    padding: 0 16px 0 0;
    width: 40%;
    ${device.generateMax(1000)}{
        width: 60%;
    }
    ${device.tablet}{
        width: 80%;
    }
    ${device.desktopNarrow} {
        width: 100%;
    }
`;


export const SelectedEvidenceCardWrapper = styled.div<{revDir: boolean}>`
    margin: 0 auto;
    width: 100%;
    min-width: 330px;
    // border: 1px solid black;
    border-radius: 8px;
    padding: ${props => !props.revDir ? '8px 0 8px 8px' : '8px 8px 8px 0'};
    margin: 16px 0 0 0;
    
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 1px 0px rgba(125, 151, 174, 0.02), 0px 2px 6px 0px rgba(125, 151, 174, 0.02), 0px 8px 16px 0px rgba(125, 151, 174, 0.08);
`;

export const StepperWrapper = styled.div`
    width: 100%;
    // padding: 16px 0;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
`;

export const CampaignReviewArrayWrapper = styled.div`
    width: 100%;
    height: 100%;
    flex-shrink: 1;
    overflow: auto;
`;


//
