//core
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

//redux
import {changeName, hideChangeName, selectedAutomation, setNewName} from "../../../store/slice";

//material
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,} from "@mui/material";

//types
import {automationType} from "../../../types";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const ChangeNameDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'automations'});

    const dispatch = useDispatch();
    const auto: automationType | null = useSelector(selectedAutomation);
    const {isOpen} = useSelector(changeName);

    const [name, setName] = useState<string>('');

    useEffect(() => {
        if(!isOpen) {
            setName('');
        }else{
            if(auto) setName(auto.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const handleSelect = () => {
        if(validate()){
            dispatch(setNewName(name));
        }
    }

    const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
        if (reason !== 'backdropClick') {
            dispatch(hideChangeName());
            setName('');
        }
    };

    const validate = () => {
        return auto && (name.trim().toLowerCase() !== auto.name.trim().toLowerCase() && name.trim().length > 0)
    }

    return (auto &&
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Change automation name')}</DialogTitle>
            <DialogContent  sx={{width: '300px'}}>
                {/* <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    label="Pipline Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={name}
                    onChange={(e) => {setName(e.target.value as string)}}
                /> */}
                <TextField
                    required
                   margin="dense"
                   id="name"
                   label={t("Automation name")}
                   value={name}
                    onChange={(e) => {setName(e.target.value as string)}}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t('Cancel')}</Button>
                <Button onClick={handleSelect} disabled={!validate()}>{t('Ok')}</Button>
            </DialogActions>
        </Dialog>
    );
}