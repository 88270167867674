import React, {FC} from "react";
import {Breadcrumbs} from "../../../../barsEnvironment/breadcrumbs";
import {Box, IconButton, Skeleton, Tab, Tabs, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {ArrowBack, ArrowForward} from "@mui/icons-material";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import ReactMarkdown from "react-markdown";
import styles from "../../../frameworks/pages/controlView/styles.module.css";
import {EvidencesReportControl} from "../evidencesReportControl";
import {NcrReport} from "../ncrReport";
import {useControlViewPage} from "./useControlViewPage";
import {useReportsParams} from "../../hooks/useReportsParams";


export const ReportControlView: FC = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathReports.View'});
    const {isLessThen1050} = useMedia();

    const {
        report, selectedControl,
        isLoadingControl,
        goToReport,
        tabs, tab,
    } = useControlViewPage()
    const {reportId} = useReportsParams();

    return (
        <Box flexDirection={"column"} height={'100%'} display={"flex"} overflow={'hidden'}>
            <Breadcrumbs/>

            <Box display={"flex"} alignItems={"center"} mt={'12px'} flexWrap={"wrap"}>
                <Typography variant={"h3"} margin={!revDir ? '0 32px 0 8px' : '0 8px 0 32px'}>
                    {reportId && (
                        <IconButton sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}
                                    onClick={() => {reportId && goToReport(reportId, !!report?.archived)}}>
                            {!revDir ? <ArrowBack/> : <ArrowForward/>}
                        </IconButton>
                    )}

                    {selectedControl ? selectedControl.name : <Skeleton width={'300px'} sx={{display: 'inline-flex'}}/>}
                </Typography>
            </Box>

            <Tabs value={tab} sx={{marginBottom: '16px'}} variant={"scrollable"}>
                {tabs.map(tab => !tab.hide && (
                    <Tab key={tab.title} label={t(tab.title)} value={tab.title} onClick={tab.handleClick}
                         disabled={isLoadingControl || (tab.title === 'NCR' && !selectedControl?.ncr.some(e => e.status))}/>
                ))}
            </Tabs>

            {tab === 'NCR' && (
                <NcrReport/>
            )}

            {tab === 'Evidences' && (
                <EvidencesReportControl/>
            )}

            {tab === 'Description' && (
                selectedControl ? selectedControl?.description.map(description => (
                    <ReactMarkdown className={styles.FrameworkControlDescription} key={description}>
                        {description}
                    </ReactMarkdown>
                )) : (
                    <>
                        <Typography variant={"body2"} color={colors.text.grey_dark} maxWidth={'800px'} margin={'4px'}>{<Skeleton/>}</Typography>
                        <Typography variant={"body2"} color={colors.text.grey_dark} maxWidth={'800px'} margin={'4px'}>{<Skeleton/>}</Typography>
                        <Typography variant={"body2"} color={colors.text.grey_dark} maxWidth={'800px'} margin={'4px'}>{<Skeleton/>}</Typography>
                    </>
                )
            )}

            {/*<StatementDialog/>*/}
        </Box>
    )
}