import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {LoadingButton} from "@mui/lab";
import {useEditRuleDialog} from "../../../hooks/dialogHooks/useEditRuleDialog";

export const EditRuleDialog = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        isOpen,
        shortDuties,
        isLoadingUpdate,
        isLoadingData,
        handleClose,
        handleSubmit,
        handleSetSeverity,
        // toggleActive,
        handleSetFirstDuty,
        handleSetSecondDuty,

        handleChange,
        form,
        isOk,
    } = useEditRuleDialog();

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Edit rule')}</DialogTitle>
            <DialogContent>
                <Typography variant={'body2'}>{t('The changes made will not affect those already running rule checks.')}</Typography>

                <TextField label={t('Rule name')} value={form?.name} size={'small'}
                           required name={'name'} sx={{mt: '16px', '& label': {top: '0 !important'}}}
                           onChange={handleChange} />

                {/*<Box display={'flex'} sx={{mt: '8px'}} alignItems={'center'}>*/}
                {/*    <Typography variant={'body2'}>{t('Include this rule in the SoD scheduler')}</Typography>*/}
                {/*    <CommonSwitch sx={{ml: !revDir ? '16px' : undefined, mr: revDir ? '16px' : undefined}} disabled={!form || isLoadingData || isLoadingUpdate}*/}
                {/*                  onChange={() => toggleActive(!form?.active)} checked={form?.active} label={form?.active ? t('Active') : t('Inactive')} />*/}

                {/*</Box>*/}

                <Autocomplete
                    size={"small"}
                    sx={{mt: '16px'}}
                    options={shortDuties.filter(e => e.id !== form?.firstDuty.id && e.id !== form?.secondDuty.id)}
                    value={form?.firstDuty}
                    onChange={(e, value) => value && handleSetFirstDuty(value)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params}
                                   required
                                   size={"small"}
                                   sx={{'& label': {top: '0 !important'}}}
                                   label={t('Add first duty')}  />
                    )}
                />

                <Autocomplete
                    size={"small"}
                    sx={{mt: '16px'}}
                    options={shortDuties.filter(e => e.id !== form?.firstDuty.id && e.id !== form?.secondDuty.id)}
                    value={form?.secondDuty}
                    onChange={(e, value) => value && handleSetSecondDuty(value)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params}
                                   required
                                   size={"small"}
                                   sx={{'& label': {top: '0 !important'}}}
                                   label={t('Add second duty')}  />
                    )}
                />

                <Autocomplete
                    size={"small"}
                    sx={{mt: '16px'}}
                    options={['LOW', 'MEDIUM', 'HIGH']}
                    value={form?.severityLevel}
                    onChange={(e, value) => value && handleSetSeverity(value)}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                        <TextField {...params}
                                   required
                                   size={"small"}
                                   sx={{'& label': {top: '0 !important'}}}
                                   label={t('Set severity level')}  />
                    )}
                />

                <TextField name={'securityRisk'} label={t("Add security risk")}  variant="outlined"
                           multiline minRows={5} sx={{mt: '16px'}} value={form?.securityRisk} onChange={handleChange}/>

                <TextField name={'securityMitigation'} label={t("Add security mitigation")}  variant="outlined"
                           multiline minRows={5} sx={{mt: '16px'}} value={form?.securityMitigation} onChange={handleChange}/>

            </DialogContent>
            <DialogActions>
                <Button sx={{textTransform: 'none'}} onClick={handleClose} size={'medium'} variant={'text'}>{t('Close')}</Button>
                <LoadingButton size={'medium'} variant={'contained'}
                               disabled={!isOk || isLoadingUpdate || isLoadingData}
                               onClick={handleSubmit} loading={isLoadingUpdate}
                               endIcon={isLoadingUpdate ? <div style={{width: '10px', height: '10px', marginLeft: revDir ? '12px' : undefined, marginRight: !revDir ? '12px' : undefined }}/> : undefined}
                               loadingPosition={isLoadingUpdate ? 'end' : undefined}
                >
                    {t('Save')}
                </LoadingButton>

            </DialogActions>
        </Dialog>
    )
}