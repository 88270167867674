import {useDispatch, useSelector} from "react-redux";
import {addPathnameBeforeAction, pathHistorySelector} from "../../store/slice";
import {useHistory} from "react-router";
import {useEffect} from "react";


export const usePathHistory = () => {
    const dispatch = useDispatch();

    const pathHistory = useSelector(pathHistorySelector);
    const addPath = (path: string, search: string = '') => {dispatch(addPathnameBeforeAction({path, search}))};

    return {
        pathHistory,
        addPath,
    }
}

export const useGoInPast = ({path, search = ''}: {path: string, search?: string}) => {
    const history = useHistory();
    const pathHistory = useSelector(pathHistorySelector);
    const goInPast = () => {
        if (pathHistory[0]?.path === path) history.push(path + pathHistory[0].search)
        else history.push(path + search)
    }

    return goInPast
}

export const useSetPathHistory = () => {
    const {location: {pathname, search}} = useHistory();
    const {addPath} = usePathHistory();

    useEffect(() => {
        return () => {
            addPath(pathname, search)
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}