import {Button, Menu, MenuItem} from "@mui/material";
import React from "react";
import {useDispatch} from "react-redux";
import {openAddDocumentDialog, openAddFolderDialog,} from "../../store/slice";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import AddIcon from "@mui/icons-material/Add";

export const AddActionButtons = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage'});
    const dispatch = useDispatch();
    // const _selectedFiles = useSelector(selectedFiles);
    // const _selectedFolders = useSelector(selectedFolders);
    // const _files = useSelector(files);
    // const isLoadingDownload = useSelector(isLoading).isLoadingDownload;
    // const _selectedFolder = useSelector(selectedFolder);
    // const path = useSelector(foldersPath);
    // const history = useHistory();

    //Menu logic
    // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    // const open = Boolean(anchorEl);
    // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };
    const handleClose = () => {
        // setAnchorEl(null);
    };


    //actions logic
    const [actionsAnchorEl, actionsSetAnchorEl] = React.useState<null | HTMLElement>(null);
    const actionsOpen = Boolean(actionsAnchorEl);

    const handleOpenActionsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        actionsSetAnchorEl(event.currentTarget);
        handleClose();
    };
    const handleCloseActionsMenu = () => {
        actionsSetAnchorEl(null);
    };

    const handleCreateDocument = () => {
        dispatch(openAddDocumentDialog({isTemplate: false}));
        // dispatch(openExplorerCreateDocEditorDialog());  //null - to create new doc
        handleCloseActionsMenu();
    }

    const handleCreateFolder = () => {
        dispatch(openAddFolderDialog());
        handleCloseActionsMenu();
    }

    // const handleUploadFile = () => {
    //     dispatch(openUploadFileDialog());
    //     handleCloseActionsMenu();
    // }


    return(
        <>
            <Button size={"medium"} startIcon={<AddIcon />} onClick={handleOpenActionsMenu}>{t('New')}</Button>

            <Menu
                anchorEl={actionsAnchorEl}
                open={actionsOpen}
                onClose={handleCloseActionsMenu}
                MenuListProps={{
                    'aria-labelledby': 'action-menu',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem onClick={handleCreateDocument}>{t('Create Document')}</MenuItem>
                <MenuItem onClick={handleCreateFolder}>{t('Create Folder')}</MenuItem>
                {/*<MenuItem onClick={handleUploadFile}>{t('Upload File')}</MenuItem>*/}
            </Menu>
        </>
    )
}
