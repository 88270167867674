import {useForbiddenWrapper} from "../../hooks/useForbiddenWrapper";
import React from "react";
import {Flex} from "../../../../../newShared/components/Layouts";
import {Autocomplete, Box, Button, Divider, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {isExpired} from "../../../../../newShared/utils/dateTools";
import {ForbiddenWrapperOrganization} from "../../types";
import {
    ForbiddenWrapperContentContainer,
    ForbiddenWrapperSelectWorkspaceContainer,
    TextFieldCustom
} from "../../components/styled";
import {CustomSearchField} from "../../components/customSearch";
import {LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {Spinner} from "../../../../../newShared/components/Spinner";
import {WelcomeOnBoardPage} from "./welcomeOnBoardPage";
import Lines from "../../../../../newShared/images/login/lines.svg";
import {SelectWorkspaceFakeDialogCard} from "./components/styled";

type Props = {
    path: string, //PATH_LOCAL_FRAMEWORKS
    pathName: string,  //Frameworks
    isOnlyForEnterprise?: boolean,
    isOnlyForBusiness?: boolean,
    isOnlyForBusinessPlus?: boolean,
    children: JSX.Element
};
export const ForbiddenWrapper: React.FC<Props> = ({path, pathName, children, isOnlyForEnterprise= false, isOnlyForBusiness = false, isOnlyForBusinessPlus = false}) => {
    const {revDir, currentLanguage, t} = useMainTranslation('', {keyPrefix: 'forbiddenWrapper'});
    const {
        isWorkspaceSelected,
        isLoading,
        organizations,
        isLoadingSelectWorkspace,
        handleSubmit,
        selectedOrganization,
        selectedWorkspace,
        handleSelectOrganization,
        handleCleanSearch,
        search,
        handleChange,
        workspaces,
        is403,
        handleGoToOrgPlans,
        subscription,
        message,
        handleGoBack,
        selectingWsId,
        alreadyFetched,
        handleGoToManageWorkspace
    } = useForbiddenWrapper({path});

    const getOptionLabelOrg = (option: ForbiddenWrapperOrganization) => `${option.name} ${option.blocked ? `(${t('blocked')})` : ''} ${isExpired(option.subscriptionExpiry, currentLanguage.momentLocale) ? `(${t('expired')})` : '' }`;

    return(
        <>
            {(isLoading || isLoadingSelectWorkspace) && <Spinner />}
            {(!isLoading && !isLoadingSelectWorkspace && alreadyFetched) &&
                <>
                    {isWorkspaceSelected ?
                        <>
                            {(is403 ||
                                (isOnlyForEnterprise && !subscription.isEnterprise) ||
                                (isOnlyForBusinessPlus && !subscription.isBusinessPlus && !subscription.isEnterprise) ||
                                (isOnlyForBusiness && !subscription.isBusiness && !subscription.isBusinessPlus && !subscription.isEnterprise)) ?
                                <>
                                    {is403 &&
                                        <ForbiddenWrapperContentContainer>
                                            <Typography variant={'h1'}>{pathName}</Typography>
                                            <Typography variant={'subtitle2'} sx={{margin: '32px 0 0 0'}}>{t('Error 403 - Forbidden')}</Typography>
                                            <Typography variant={'body2'} sx={{margin: '8px 0 0 0'}}>{message ?? t('You do not have permission to access this page.')}</Typography>
                                            <Button onClick={handleGoBack} variant={'outlined'} sx={{margin: '16px 0 0 0', width: '200px'}}>{t('Go to previous page')}</Button>
                                        </ForbiddenWrapperContentContainer>
                                    }
                                    {(isOnlyForEnterprise && !subscription.isEnterprise)  &&
                                        <ForbiddenWrapperContentContainer>
                                            <Typography variant={'h1'}>{pathName}</Typography>
                                            <Typography>{t('{{pathName}} is available only for Enterprise subscription.', {pathName})}</Typography>
                                            <Button onClick={handleGoToOrgPlans} color={'secondary'} sx={{margin: '16px 0 0 0', width: '300px'}}>{t('Show available plans')}</Button>
                                        </ForbiddenWrapperContentContainer>
                                    }
                                    {(isOnlyForBusinessPlus && !subscription.isBusinessPlus && !subscription.isEnterprise) &&
                                        <ForbiddenWrapperContentContainer>
                                            <Typography variant={'h1'}>{pathName}</Typography>
                                            <Typography>{t('{{pathName}} is available only for Business Plus subscription.', {pathName})}</Typography>
                                            <Button onClick={handleGoToOrgPlans} color={'secondary'} sx={{margin: '16px 0 0 0', width: '300px'}}>{t('Show available plans')}</Button>
                                        </ForbiddenWrapperContentContainer>
                                    }
                                    {(isOnlyForBusiness && !subscription.isBusiness && !subscription.isBusinessPlus && !subscription.isEnterprise) &&
                                        <ForbiddenWrapperContentContainer>
                                            <Typography variant={'h1'}>{pathName}</Typography>
                                            <Typography>{t('{{pathName}} is available only for Business subscription.', {pathName})}</Typography>
                                            <Button onClick={handleGoToOrgPlans} color={'secondary'} sx={{margin: '16px 0 0 0', width: '300px'}}>{t('Show available plans')}</Button>
                                        </ForbiddenWrapperContentContainer>
                                    }
                                </>
                                :
                                <>
                                    {children}
                                </>
                            }
                        </>
                        :
                        <>
                            <Box display={"flex"} alignItems={"center"} flexDirection={"column"} overflow={'hidden'}
                                 position={"relative"} width={'100%'} height={'100%'}
                                 sx={{
                                     background: 'linear-gradient(107.56deg, #FFFFFF 0%, #F0F6FB 54.69%, #FFFFFF 100%)',
                                 }}>

                                <img src={Lines} alt={'background'} style={{
                                    position: 'absolute',  top: '100px', zIndex: 0,
                                    ...(document.body.offsetWidth > document.body.offsetHeight ? {width: '100vw'} : {height: '100%', left: '0'}),
                                }}/>



                                {organizations.length > 0 ?
                                    <SelectWorkspaceFakeDialogCard>
                                        <Typography variant={"*h1"} sx={{margin: !revDir ? '0 32px 0 0' : '0 0 0 32px', color: colors.backgrounds.dark}}>{t('Select workspace to continue')}</Typography>
                                        <ForbiddenWrapperSelectWorkspaceContainer>
                                            <Flex w={'100%'} m={'16px 0 0 0'} ai={'center'} jc={'space-between'}>
                                                <Typography variant={'*bodyText1_semibold'} noWrap color={colors.grayText}>{t('Select workspace from')}</Typography>
                                                <Autocomplete
                                                    options={organizations}
                                                    getOptionLabel={getOptionLabelOrg}
                                                    renderOption={(props, option) => (<li {...props} key={option.id}>{getOptionLabelOrg(option)}</li>)}
                                                    loading={isLoading}
                                                    value={selectedOrganization}
                                                    sx={{width: '70%'}}
                                                    onChange={(e, newValue: ForbiddenWrapperOrganization | null) => newValue && handleSelectOrganization(newValue)}
                                                    renderInput={(params) => <TextFieldCustom {...params} size={'small'} label={t("Organization")}/>}
                                                />
                                            </Flex>

                                            <CustomSearchField
                                                variant={'outlined'}
                                                onChange={handleChange}
                                                name={'search'}
                                                value={search}
                                                onClear={handleCleanSearch}
                                                disabled={!selectedOrganization}
                                                label={t('Search by workspace name')}
                                                dir={'ltr'}
                                                placeholder={t('Workspace name')}
                                                size="small"
                                                fullWidth
                                                sx={{margin: '16px 0 0 0'}}
                                            />

                                            {<Divider flexItem sx={{
                                                bgcolor: colors.primary.disable,
                                                borderColor: colors.primary.disable,
                                                margin: '8px 0 0 0'
                                            }}/>}

                                            <Flex
                                                w={'100%'}
                                                h={'100%'}
                                                overflowy={'auto'}
                                                direction={'column'}
                                            >
                                                {!workspaces.length && <Flex p={'16px 0 0 0'} w={'100%'} ai={'center'}>
                                                    <Typography variant={'*bodyText2'} color={colors.grayText}>Nothing was found for your query</Typography>
                                                </Flex>}
                                                {workspaces.map(e => {
                                                    return (
                                                        <div key={e.id}>
                                                            <Flex w={'100%'} ai={'center'} jc={'space-between'} p={'16px'}>
                                                                <Flex direction={'column'} jc={'space-between'}>
                                                                    <Typography variant={'*bodyText1'}
                                                                                color={colors.primary.blue_dark}
                                                                                onClick={() => handleGoToManageWorkspace(e.id)}
                                                                                sx={{cursor: 'pointer', textDecoration: 'underline'}}
                                                                    >{e.name}</Typography>
                                                                    <Typography variant={'*bodyText2'}
                                                                                color={colors.text.grey_dark}>{t('{{collaboratorsCount}} collaborators', {collaboratorsCount: e.collaboratorsCount})}</Typography>
                                                                </Flex>
                                                                {e.id === selectedWorkspace?.id ?
                                                                    <Button variant={'outlined'}
                                                                            disabled>{t('Selected')}</Button>
                                                                    :
                                                                    <LoadingButton
                                                                        variant={'outlined'}
                                                                        loading={isLoadingSelectWorkspace && selectingWsId === e.id}
                                                                        onClick={() => handleSubmit(e)}
                                                                        disabled={isLoading || selectedOrganization?.blocked || isExpired(selectedOrganization?.subscriptionExpiry ?? '', currentLanguage.momentLocale)}
                                                                    >
                                                                        {t('Select')}
                                                                    </LoadingButton>
                                                                    // <Button color={'secondary'} onClick={() => handleSubmit(e)}  variant={'contained'}>{('Select')}</Button>
                                                                }
                                                            </Flex>
                                                            {<Divider flexItem sx={{
                                                                bgcolor: colors.primary.disable,
                                                                borderColor: colors.primary.disable
                                                            }}/>}
                                                        </div>
                                                    )
                                                })}
                                            </Flex>
                                        </ForbiddenWrapperSelectWorkspaceContainer>
                                    </SelectWorkspaceFakeDialogCard>
                                    :
                                    <WelcomeOnBoardPage />
                                }
                            </Box>
                        </>
                    }
                </>
            }
        </>
    )
}