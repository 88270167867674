import {MouseEvent, useState} from "react";
import {DecorationControlProps} from "../index";

interface UseDecorationControlProps {
    menuAnchorEl: null | HTMLElement,
    colorAnchorEl: null | HTMLElement,
    color: string,
    changeDecorationControlHandler: DecorationControlProps["changeDecorationControlHandler"]
}

interface UseDecorationControlValue {
    openMenu: boolean,
    menuAnchorEl: null | HTMLElement,
    menuHandleClick: (event: MouseEvent<HTMLButtonElement>) => void,
    menuHandleClose: () => void,
    openColor: boolean,
    colorAnchorEl: null | HTMLElement,
    colorHandleClick: (event: MouseEvent<HTMLButtonElement>) => void,
    colorHandleClose: () => void,
    // internalColor: string,
    changeColorHookHandler: (color: string) => void,
}

export const useDecorationControl = ({menuAnchorEl, colorAnchorEl, color, changeDecorationControlHandler}: UseDecorationControlProps): UseDecorationControlValue => {

    const [menuDecorAnchorEl, setMenuDecorAnchorEl] = useState<null | HTMLElement>(() => menuAnchorEl);
    const [colorDecorAnchorEl, setColorDecorAnchorEl] = useState<null | HTMLElement>(() => colorAnchorEl);
    // const [currentColor, setCurrentColor] = useState<string>(() => color);

    const openMenu = Boolean(menuDecorAnchorEl);
    const openColor = Boolean(colorDecorAnchorEl);

    const menuHandleClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setMenuDecorAnchorEl(event.currentTarget);
    };

    const menuHandleClose = () => {
        setMenuDecorAnchorEl(null);
    };

    const colorHandleClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setColorDecorAnchorEl(event.currentTarget);
        changeDecorationControlHandler('colorPickerOpenClose', true);
        // changeFontSizeHandler('fontSizeOpenClose', true);
    };

    const colorHandleClose = () => {
        setColorDecorAnchorEl(null);
        changeDecorationControlHandler('colorPickerOpenClose', false);
    };

    const changeColorHookHandler = (color: string) => {
        changeDecorationControlHandler('color', color);
        setColorDecorAnchorEl(null);
        // setCurrentColor(color);
    };


    return {
        openMenu,
        menuAnchorEl: menuDecorAnchorEl,
        menuHandleClick,
        menuHandleClose,
        openColor,
        colorAnchorEl: colorDecorAnchorEl,
        colorHandleClick,
        colorHandleClose,
        // internalColor: currentColor,
        changeColorHookHandler
    };
}
