import {useDispatch, useSelector} from "react-redux";
import {
    controls,
    evidencesSelector,
    frameworks,
    isLoading,
    isLoadingControlAndEvidencesByFrameworkId,
    isLoadingEvidences
} from "../../../store/builder/slice";
import {useEffect} from "react";
import {
    DeattachControl,
    DeattachEvidence,
    GetBuilderData,
    GetControls,
    GetPrivateControls,
    GetPrivateEvidences
} from "../../../store/builder/actions";
import {useRoutes} from "../useRoutes";


export const useBuilderFrameworks = () => {

    const dispatch = useDispatch();
    const controller = new AbortController();

    const {
        frameworkId, controlId,
        goToFrameworks
    } = useRoutes();

    const _frameworks = useSelector(frameworks);
    // const framework = useSelector(activeFramework);
    const _controls = useSelector(controls);
    const _evidences = useSelector(evidencesSelector);

    const _isLoading = useSelector(isLoading);
    const isLoadingControls = useSelector(isLoadingControlAndEvidencesByFrameworkId);
    const _isLoadingEvidence = useSelector(isLoadingEvidences);

    const getFrameworks = () => {
        dispatch(GetBuilderData(controller.signal))
    }
    const getFrameworkById = (frameworkId?: string) => {
        !_frameworks.some(e => e.id === frameworkId) && getFrameworks();
        frameworkId && dispatch(GetControls({data: {frameworkId, onReject: goToFrameworks}, signal: controller.signal}))
    }

    const deattachControl = (frameworkId: string, controlId: string) => {
        dispatch(DeattachControl({frameworkId, controlId}));
    }
    const deattachEvidence = (controlId: string, evidenceId: string) => {
        dispatch(DeattachEvidence({controlId, evidenceId}));
    }

    const getPrivateControls = () => {
        dispatch(GetPrivateControls({data: {page: 0, count: 9999999}, signal: controller.signal}))
    };
    const getPrivateEvidences = () => {
        dispatch(GetPrivateEvidences({data:{page: 0, count: 999999}, signal: controller.signal}))
    };

    useEffect(() => {
        return () => {
            controller.abort();
        }
        //eslint-disable-next-line
    }, [])

    const framework = _frameworks.find(e => e.id === frameworkId);
    const control = _controls.find(e => e.id === controlId);
    const evidences = control?.evidences || [];
    return {
        _frameworks,
        _controls,
        _evidences,
        framework,
        control,
        evidences,

        _isLoading,
        isLoadingControls,
        _isLoadingEvidence,

        getFrameworks,
        getFrameworkById,
        getPrivateControls,
        getPrivateEvidences,

        deattachControl,
        deattachEvidence,
    }
}