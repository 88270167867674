import {Avatar} from "@mui/material";
import {AvatarProps} from "@mui/material/Avatar/Avatar";
import {FC} from "react";
import {CSSProperties} from "@mui/styles";
import {TAvatarSizeButton} from "../../../types";
import {getAvatarInitials} from "../../../utils/text";

type TAvatar = AvatarProps & {
    size?: TAvatarSizeButton,
    name: string,
}

export const CommonAvatar: FC<TAvatar> = ({name, size= 'd40', sx, ...props}) => {

    const sizeTypes: { [key: string]: CSSProperties } = {
        d16: {width : '16px', height: '16px', fontSize: '8px', lineHeight: '20px', fontWeight: '400'},
        d24: {width : '24px', height: '24px', fontSize: '10px', lineHeight: '20px', fontWeight: '400'},
        d28: {width : '28px', height: '28px', fontSize: '14px', lineHeight: '20px', fontWeight: '400', letterSpacing: '0.15px'},
        d32: {width : '32px', height: '32px', fontSize: '16px', lineHeight: '16px', fontWeight: '400', letterSpacing: '0.15px'},
        d40: {width : '40px', height: '40px', fontSize: '16px', lineHeight: '20px', fontWeight: '600'},
        d48: {width : '48px', height: '48px', fontSize: '18px', lineHeight: '24px', fontWeight: '600'},
        d56: {width : '56px', height: '56px', fontSize: '20px', lineHeight: '28px', fontWeight: '400'},
        d72: {width : '72px', height: '72px', fontSize: '24px', lineHeight: '36px', fontWeight: '500'},
        d100: {width : '100px', height: '100px', fontSize: '32px', lineHeight: '44px', fontWeight: '700'},
    }

    return (
        <Avatar
            {...props}
            sx={{...sizeTypes[size], ...sx}}
            children={getAvatarInitials(name)}
        />
    )
}