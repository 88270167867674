import {Button, styled} from "@mui/material";

export const FontSizeButton = styled(Button)`
  text-transform:capitalize !important;
  color: #707070 !important;
  width:60px !important;
  overflow: hidden !important;
  display: inline-block !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  justify-content: flex-start !important;
  text-align: left !important;
  font-weight: 500;
`;
FontSizeButton.defaultProps = {

    variant: "text",
};
