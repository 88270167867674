import React, {FC, useCallback, useEffect, useState} from "react";
import * as S from "./styled";

import {Box, Button, Checkbox, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {HTML5Backend} from "react-dnd-html5-backend";
import {ConnectableElement, DndProvider, DragSourceMonitor, useDrag, useDrop} from "react-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {ReactComponent as AddRecipientIcon} from "../../../../../../newShared/images/documents/add-recipient.svg";
import {ReactComponent as RecipientIcon} from "../../../../../../newShared/images/documents/recipient-icon.svg";
import {ReactComponent as MessageIcon} from "../../../../../../newShared/images/documents/message-icon.svg";
import {
    ReactComponent as CompleteMessageIcon
} from "../../../../../../newShared/images/documents/complete-message-icon.svg";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useTaskActionTemplateNewDialog} from "../../../hooks/dialogs/useTaskActionTemplateNewDialog";
import {Item, TActorEmailName, TCreateFormDoc, TIdRecipient} from "../../../types";
import {
    TypographyBody2,
    TypographyBodySmall,
    TypographyBodySmallSemiBold,
    TypographySubtitle1
} from "../../../../../../newShared/components/Inputs/styled";
import {SelectFolderTree} from "../../../../documentsRefactor/components/selectFolderTree";
import CommonSwitch from "../../../../../../newShared/components/Basic/CommonSwitch";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {validateEmail} from "../../../../../../newShared/utils/text";
import {LightTooltip} from "../../../../../../newShared/components/editorUnion/components/editorSideMenu/styled";
import {AutocompleteCollaborators} from "../../../../../../newShared/components/AutocompleteCollaborators";
import {optionIsTCollaborator} from "../../../../../../newShared/components/AutocompleteCollaborators/types";
import {defaultGetOptionDisabled} from "../../../../../../newShared/components/AutocompleteCollaborators/helpers";
import {
    ApproversBlockView
} from "../../../../documentsRefactor/components/dialogs/addDocument/fillInfoView/approversBlockView";

export const ItemTypes = {
    CARD: 'card',
}

export const TaskActionTemplateNewDialog = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathVica.TasksList.dialogs'});
    const {
        isOpen,
        handleClose,
        action,
        rootFolders,
        loadings: {isLoadingSaveDoc},
        path,
        form,
        setForm,
        isApproversBlock,
        setIsApproversBlock,
        isRecipientsCustomOrder,
        selectedFolder,
        touchedField,
        setTouchedField,
        errorActiveFields,
        setErrorActiveFields,

        handleSetDocName,
        handleSetFolder,
        handleAddRecipient,
        handleOpenAddMessage,
        handleToggleRecipientOrder,
        handleDeleteRecipient,
        handleSetActorRecipient,
        handleSave,
    } = useTaskActionTemplateNewDialog();

    //DND
    const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));
    const setRecipients = useCallback(
        (cols: TCreateFormDoc['recipients']) => {
            setForm({
                ...form,
                recipients: cols.map((e, i) => ({...e, order: i})),
            })
            //eslint-disable-next-line
        }, [form]
    )
    const findColumn: (id: string) => {column: TCreateFormDoc['recipients'][number], index: number} | null = useCallback(
        (id: string) => {
            const column = form.recipients.find(e => e.id === id);
            // console.log(`findColumn for ${key} - ${JSON.stringify(column)} | ${column ? settings.indexOf(column) : null}`);
            return column ? {
                column,
                index: form.recipients.indexOf(column)
            } : null;
        },
        [form.recipients],
    )

    const moveColumn: (key: string, atIndex: number) => void = useCallback(
        (key: string, atIndex: number) => {
            const res = findColumn(key);
            if(res){
                //res - {column: lastName, index: 1}
                //atIndex - 0
                const copyArr = JSON.parse(JSON.stringify(form.recipients));
                // console.log(`copyArr: ${JSON.stringify(copyArr)}`);
                copyArr.splice(res.index, 1) //remove
                copyArr.splice(atIndex, 0, res.column) //insert
                // const reordered = settings.splice(res.index, 1).splice(atIndex, 0, res.column);
                // console.log(`moveColumn for ${key} settings.splice(${res.index}, 1).splice(${atIndex}, 0, ${JSON.stringify(res.column)}) | \n newArr: new arr: ${JSON.stringify(copyArr)}`)
                setRecipients(copyArr);
                // console.log(`moveColumn for ${key} - from ${res?.index} | to ${atIndex} \n new arr: ${JSON.stringify(reordered)}`);
            }else{
                // console.log(`--Column with key ${key} not found!`);
            }
        },
        [findColumn, form.recipients, setRecipients],
    )

    const [recipientsError, _setRecipientsError] = useState<Record<string, boolean>>({});
    const setRecipientsError = (value: Record<string, boolean>) => {
        _setRecipientsError(prev => ({...prev, ...value}));
    }

    useEffect(() => {
        _setRecipientsError({})
    }, [isOpen]);

    return (
        <S.AddFileDialog open={isOpen} onClose={handleClose}>
            <S.TitleBox>
                <TypographySubtitle1>{`${action?.title}`}</TypographySubtitle1>
                <IconButton onClick={handleClose}><CloseIcon /></IconButton>
            </S.TitleBox>

            <S.AddDocumentDialogContent>
                <DndProvider backend={HTML5Backend}>
                    <S.InfoViewContainer>
                        <S.DocNameTextField placeholder={t('Document name')} value={form.name} onChange={(e) => handleSetDocName(e.target.value)} />
                        <S.DocDivider />
                        <TypographyBodySmallSemiBold>{t('Save in folder')}</TypographyBodySmallSemiBold>
                        <SelectFolderTree t={t} folders={rootFolders} path={path} selectedFolder={selectedFolder} setSelectedFolder={handleSetFolder}/>
                        <S.DocDivider />
                        {/*APPROVERS BLOCK*/}
                        <S.FlexBox>
                            <CommonSwitch label={t('Set approval workflow')} checked={isApproversBlock} onChange={() => setIsApproversBlock(!isApproversBlock)} />
                            {/*<S.SwitchInfoBox>{'i'}</S.SwitchInfoBox>*/}
                        </S.FlexBox>

                        {/*{isApproversBlock &&*/}
                        {/*    <ApproversBlockView*/}
                        {/*        employees={employees}*/}
                        {/*        isLoadingEmployees={isLoadingEmployees}*/}
                        {/*        getEmployees={getEmployees}*/}
                        {/*        form={form}*/}
                        {/*        setForm={setForm}*/}
                        {/*        handleOpenAddGroupMessage={handleOpenAddMessage}*/}
                        {/*        isApproversCustomOrder={isApproversCustomOrder}*/}
                        {/*        setIsApproversCustomOrder={setIsApproversCustomOrder}*/}
                        {/*    />}*/}

                        {isApproversBlock &&
                            <ApproversBlockView
                                form={form}
                                setForm={setForm}
                                handleOpenAddGroupMessage={handleOpenAddMessage}
                            />}

                        <S.DocDivider />

                        {/*RECIPIENTS BLOCK*/}
                        <TypographySubtitle1>{t('Add recipients who need to fill out and (or) sign this document')}</TypographySubtitle1>
                        <S.FlexBox>
                            <Checkbox checked={isRecipientsCustomOrder} onChange={() => handleToggleRecipientOrder(!isRecipientsCustomOrder)} />
                            <TypographyBody2>{t('Custom recipients order')}</TypographyBody2>
                        </S.FlexBox>
                        <div ref={drop} style={{width: '100%'}}>
                            {form.recipients.map((r, index) => (
                                <RecipientItem
                                    key={r.id}
                                    moveColumn={moveColumn}
                                    findColumn={findColumn}
                                    index={index}
                                    form={form}
                                    recipient={r}
                                    isRecipientsCustomOrder={isRecipientsCustomOrder}
                                    handleDelete={handleDeleteRecipient}
                                    handleOpenAddMessage={handleOpenAddMessage}
                                    handleSetActorRecipient={handleSetActorRecipient}
                                    touchedField={touchedField}
                                    setTouchedField={setTouchedField}
                                    errorActiveFields={errorActiveFields}
                                    setErrorActiveFields={setErrorActiveFields}
                                    setRecipientsError={setRecipientsError}
                                />
                            ))}
                        </div>

                        <Button variant={'text'} sx={{textTransform: 'none', mt: '16px'}} startIcon={<AddRecipientIcon />} onClick={handleAddRecipient}>{t('Add Recipient')}</Button>
                    </S.InfoViewContainer>
                </DndProvider>
            </S.AddDocumentDialogContent>
            <S.AddDocumentsDialogActions >
                <LoadingButton size={'small'} sx={{ml: 'auto'}} variant={'contained'}
                               disabled={!form.folderId.length || isLoadingSaveDoc || Object.values(recipientsError).some((item) => item)}
                               onClick={handleSave} loading={isLoadingSaveDoc }
                               loadingPosition={isLoadingSaveDoc ? 'end' : undefined}
                               endIcon={isLoadingSaveDoc ? <div style={{width: '10px', height: '10px', marginLeft: revDir ? '12px' : undefined, marginRight: !revDir ? '12px' : undefined }}/> : undefined}
                >
                    {'Create'}
                </LoadingButton>

            </S.AddDocumentsDialogActions>
        </S.AddFileDialog>
    )
}

interface RecipientItemProps {
    form: TCreateFormDoc,
    isRecipientsCustomOrder: boolean,
    index: number,
    moveColumn: (key: string, atIndex: number) => void,
    findColumn: (key: string) => {column: TCreateFormDoc['recipients'][number], index: number} | null
    recipient: TIdRecipient;
    handleDelete: (id: string) => void;
    handleOpenAddMessage: (data: {recipientId: string, oldMessage: string}) => void;
    handleSetActorRecipient: (id: string, actor: TActorEmailName) => void;
    touchedField: string | null;
    setTouchedField: (id: string | null) => void;
    errorActiveFields: string[];
    setErrorActiveFields: (ids: string[]) => void;
    setRecipientsError: (data: Record<string, boolean>) => void;
}
const RecipientItem: FC<RecipientItemProps> = (props) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});

    const [newRecipient, setNewRecipient] = useState<TActorEmailName | null>(null);
    const [error, setError] = useState<string | null>(null);

    const {
        form,
        index,
        recipient,
        isRecipientsCustomOrder,
        handleDelete,
        handleOpenAddMessage,

        findColumn,
        moveColumn,

        errorActiveFields,
        setErrorActiveFields,
        handleSetActorRecipient,

        // setTouchedField,
        touchedField,

        setRecipientsError
    } = props;

    useEffect(() => {
        if(newRecipient){
            handleSetActorRecipient(recipient.id, newRecipient);
            handleIsOkRecipientEmail({id: recipient.id, email: newRecipient.email});
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newRecipient]);

    const searchedColumn = findColumn(recipient.id);
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.CARD,
            item: {key: recipient.id, index: searchedColumn?.index ?? -1},
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const { key: droppedKey, index: originalIndex } = item;

                // console.log(`ROW useDrag END with item ${JSON.stringify(item)}`);
                const didDrop = monitor.didDrop();
                if (!didDrop && item) {
                    moveColumn(droppedKey, originalIndex);
                    // console.log(`ROW useDrag - moveColumn exec: ${droppedKey} | ${originalIndex}`);
                }
            },
        }),
        [recipient.id, searchedColumn, moveColumn],
    );

    const [, drop] = useDrop(
        () => ({
            accept: ItemTypes.CARD,
            hover({ key: draggedKey }: Item) {
                // console.log(`Card useDrop draggedKey:${draggedKey} in cardId ${column.key}`);
                if (draggedKey !== recipient.id) {
                    const col = findColumn(recipient.id)
                    if(col){
                        // console.log(`ROW useDrop - moveColumn exec: ${draggedKey} | ${col.index}`);
                        moveColumn(draggedKey, col.index);
                    }else{
                        // console.log(`ROW useDrop - col not found by key ${column.key}`)
                    }
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
                isActive: monitor.canDrop() && monitor.isOver(),
            }),
        }),
        [findColumn, moveColumn],
    );

    const handleIsOkRecipientEmail = ({id, email}:{id: string, email: string}): boolean => {
        if(email?.length === 0) {
            setErrorActiveFields(errorActiveFields.filter(e => e !== id));
            return true;
        }
        else if (!validateEmail(email)) {
            setError('Invalid email');
            setErrorActiveFields([...errorActiveFields, id]);
            return false;
        }
        else if (form.recipients.filter(r => r.id !== id ).some(r =>  r.actor.email === email)) {
            setError('Email already exists');
            setErrorActiveFields([...errorActiveFields, id]);
            return false;
        }

        setErrorActiveFields(errorActiveFields.filter(e => e !== id));
        return true; //!error
    }

    useEffect(() => {
        handleIsOkRecipientEmail({id: recipient.id, email: recipient.actor?.email || ''})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipient?.actor?.email]);

    return (
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} marginTop={'12px'} width={'100%'} >
            {isRecipientsCustomOrder && <S.OrderBox>{index+1}</S.OrderBox>}

            <S.RecipientsContainer
                isDragging={isDragging ? true : undefined}
                isOrdered={isRecipientsCustomOrder ? true : undefined}
                ref={(node: ConnectableElement) => drag(drop(node))}
            >
                {isRecipientsCustomOrder &&  <DragIndicatorIcon/>}

                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                    <S.InfoActorFlexBox>
                        <RecipientIcon />
                        <TypographyBodySmall sx={{flexGrow: 1}} >{recipient.role || `${t('Recipient')} ${index+1}`}</TypographyBodySmall>


                        <IconButton size={'small'} onClick={() => handleOpenAddMessage({recipientId: recipient.id, oldMessage: recipient.message || ''})}>
                            {recipient.message  ?  <CompleteMessageIcon /> :  <MessageIcon />}
                        </IconButton>

                        <S.VerticalDivider />
                        {recipient.fields?.length ? (
                            <LightTooltip title={"Unable to remove recipient with assigned fields"}>
                                <span>
                                    <IconButton disabled>
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </span>
                            </LightTooltip>
                        ) : (
                            <IconButton onClick={() => handleDelete(recipient.id)}>
                                <DeleteOutlineIcon />
                            </IconButton>
                        )}
                    </S.InfoActorFlexBox>

                    <AutocompleteCollaborators
                        getOptionLabelCustom={"name+email"}
                        freeSoloType={"email"}
                        freeSolo
                        defaultValue={''}
                        helperText={(touchedField === recipient.id && error) || undefined}
                        onChange={value => {
                            if (!optionIsTCollaborator(value)) {
                                setNewRecipient({email: value as string, firstName: '', lastName: ''});
                            } else {
                                setNewRecipient({email: value?.email || '', firstName: value?.firstName || '', lastName: value?.lastName || ''});
                            }
                        }}
                        getOptionDisabled={defaultGetOptionDisabled(form.recipients.map(e => e.actor.email).filter(e => e !== recipient.actor.email))}

                        sx={{width: '100%', '& label': {top: '0px !important'}}}

                        onFocus={() => {
                            // setTouchedField(recipient.id);
                            // setError(null);
                        }}
                        onBlur={() => {
                            // setTouchedField(null);
                            handleIsOkRecipientEmail({id: recipient?.id, email: recipient.actor.email})
                        }}

                        onError={(error) => {
                            setRecipientsError({[recipient.id]: error});
                        }}
                    />
                </Box>
            </S.RecipientsContainer>
        </Box>
    )
}
