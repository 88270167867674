import {useCreateRole} from "../../../hooks/organizations/useCreateRole";
import React from "react";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {Button, Typography} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import * as S from '../../../components/styled';
import {TextFieldCustom} from '../../../components/styled';
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {LoadingButton} from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import {IconTextButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {ManagePermissionsDialog} from "../../../components/organizations/exactWorkspace/dialogs/managePermissions";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";

export const RolesCreate = () => {
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'pathSettings.RolesCreate'});
    const {isMobile} = useMedia();
    const {
        handleGoBack,
        form,
        handleChange,
        selectedPermissions,
        isOk,
        isLoading,
        handleOpenManagePermissionDialog,
        availablePermissions,
        selectPermission,
        selectAllPermission,
        deselectAllPermissions,
        deselectPermission,
        isManagePermissionButtonDisabled,
        handleSubmit
    } = useCreateRole();

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('Create role')}
                handleGoBack={handleGoBack}
                actions={[]}
            />

            <Flex ai={'center'} m={'32px 0 0 0'}>
                <LoadingButton
                    variant={'contained'}
                    loading={isLoading}
                    disabled={!isOk}
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    onClick={handleSubmit}
                >{t('Save')}</LoadingButton>

                <Button disabled={isLoading} variant={'outlined'} size={isMobile ? 'small' : 'medium'} onClick={handleGoBack} >{t('Cancel')}</Button>
            </Flex>

            <S.ExactOrganizationWorkspacesTabSearchWrapper>
                <TextFieldCustom
                    value={form.name}
                    name={'name'}
                    onChange={handleChange}
                    placeholder={t('Name')}
                    label={t('Role name')}
                    size={'small'}
                    sx={{margin: '8px 0 0 0'}}
                />

                <Typography variant={'body1'} sx={{margin: '16px 0 0 0'}}>{t('Permissions')}</Typography>
                <IconTextButton
                    variant={'text'}
                    icon={<AddIcon fontSize="small"/>}
                    onClick={handleOpenManagePermissionDialog}
                    disabled={isManagePermissionButtonDisabled}
                    sx={{margin: '8px 0 0 0'}}
                >{t('Manage permissions')}
                </IconTextButton>
                {!selectedPermissions.length && <Typography variant={'body2'} sx={{margin: '8px 0 0 0'}}>{t('No permissions selected')}</Typography>}
                <Flex overflowy={'auto'} w={'100%'} h={'100%'} maxh={'300px'} direction={'column'}>
                    {selectedPermissions.slice().sort().map(e => <Typography key={e} variant={'body2'} sx={{margin: '8px 0 0 0'}}>{e}</Typography>)}
                </Flex>
            </S.ExactOrganizationWorkspacesTabSearchWrapper>

            <ManagePermissionsDialog
                availablePermissions={availablePermissions}
                selectedPermissions={selectedPermissions}
                deselectPermission={deselectPermission}
                selectPermission={selectPermission}
                roleName={form.name}
                selectAllPermission={selectAllPermission}
                deselectAllPermissions={deselectAllPermissions}
            />
        </Flex>
    )
}