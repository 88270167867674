import {FC, useRef, useState} from "react";
import {Typography} from "@mui/material";
import {TypographyProps} from "@mui/material/Typography/Typography";
import colors from "../../theme/colors";
import {TooltipWithoutArrow} from "../editorUnion/components/editorSideMenu/styled";

type TProps = TypographyProps;
export const TypographyNew: FC<TProps> = ({...props}) => {

    const [isHovered, setHovered] = useState<boolean>(false);
    const ref = useRef<HTMLSpanElement>(null);

    return (
        <TooltipWithoutArrow title={<>{props.children}</>}
                 open={isHovered && (
                     ((ref.current?.scrollWidth || 0) > (ref.current?.offsetWidth || 0)) ||
                     ((ref.current?.scrollHeight || 0) > (ref.current?.offsetHeight || 0))
                 )}
                 PopperProps={{sx: {'& div': {backgroundColor: colors.backgrounds.white}}}}>
            <Typography {...props} ref={ref}
                        onMouseOver={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
            />
        </TooltipWithoutArrow>
    )
}