import {Handle, Position} from "react-flow-renderer";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {ALLOWDRAG} from "../../../constants";
import {Typo} from "../../../../../../newShared/components/Typography";

type finishNodeProps = {
    revDir: boolean
}
export const FinishNode = ({data} : {data: finishNodeProps}) => {
    const {revDir} = data;
    const {t} = useMainTranslation('', {keyPrefix: 'automations'});

    return(
        <Flex w={'1.5rem'} h={'1.5rem'} br={'50%'} background={'#C4C4C4'} className={ALLOWDRAG ? 'nodrag': ''} cursor={'default'}>
            <Typo
                style={{position: 'relative', bottom: '40px', right: '5px'}}
                fontWeight={400}
                fontSize={'14px'}
            >{t('Finish')}</Typo>
            <Handle
                type="source"
                position={!revDir ? Position.Left : Position.Right}
                id="a"
                isConnectable={false}
                style={{backgroundColor: 'inherit'}}
            />
        </Flex>
    )
}