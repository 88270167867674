import {useAppDispatch} from "../../../../../newShared/redux";
import {useSelector} from "react-redux";
import {
    assetsDialogsSelector,
    assetsSettingsSelector,
    certificateAssetsSettingsSelector,
    loadings,
    saasAssetsSettingsSelector,
    setCreateSettingRowDialogAction,
    setDeleteSettingRowDialogAction,
    softwareAssetsSettingsSelector,
    virtualAssetsSettingsSelector
} from "../../store/store";
import {
    deleteCertificateAssetsSettingRowAction,
    deletePhysicalAssetsSettingRowAction,
    deleteSaasAssetsSettingRowAction,
    deleteSoftwareAssetsSettingRowAction,
    deleteVirtualAssetsSettingRowAction
} from "../../store/actions";
import {TSetCreateSettingRowDialog, TSettingsId} from "../../types";
import {useEffect, useState} from "react";
import {normalizeSettingIdName} from "../../helpers";
import {useParams} from "react-router-dom";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";


export const useDeleteSettingDialog = () => {
    const dispatch = useAppDispatch();
    const settings = useSelector(assetsSettingsSelector);
    const virtualSettings = useSelector(virtualAssetsSettingsSelector);
    const softwareSettings = useSelector(softwareAssetsSettingsSelector);
    const saasSettings = useSelector(saasAssetsSettingsSelector);
    const certificateSettings = useSelector(certificateAssetsSettingsSelector);
    const {deleteSettingRow: {isOpen, field, oldTitle, response: deleteResponse, assetType}} = useSelector(assetsDialogsSelector);
    const id = useParams<{id: TSettingsId}>().id as TSettingsId;
    const {deleteSettingLoading, isLoadingSettings} = useSelector(loadings);
    const settingTitle = normalizeSettingIdName(id as TSettingsId);
    const {setMessage} = useMessageDialog();

    const [withHistoryChange, setWithHistoryChange] = useState<boolean>(false);
    const [title, setTitle] = useState<string | null>(null);
    const [newStatus, setNewStatus] = useState<string | null>(null);


    const onSuccess = () => {
        setMessage({title: 'Completed successfully', message: `${settingTitle} has been deleted successfully.`});
    }

    const handleDeleteSettingRow = () => {
        switch (assetType) {
            case 'physical':
                if ((field === 'model' || field === 'manufacture' || field === 'category') && oldTitle && title) dispatch(deletePhysicalAssetsSettingRowAction({data: {organizationId: '', workspaceId: '', field, oldTitle, title}, onSuccess}));
                else if (field === "status") (field && oldTitle && title) && dispatch(deletePhysicalAssetsSettingRowAction({data: {organizationId: '', workspaceId: '', field, oldTitle, title, withHistoryChange, status: newStatus}, onSuccess}));
                else if (field === "customFields") (field && oldTitle) && dispatch(deletePhysicalAssetsSettingRowAction({data: {organizationId: '', workspaceId: '', field, oldTitle, title: ''}, onSuccess}));
                break;

            case 'virtual':
                (field === 'type' && oldTitle && title) && dispatch(deleteVirtualAssetsSettingRowAction({data: {organizationId: '', workspaceId: '', field, oldTitle, title}, onSuccess}));
                (field === 'customFields' && oldTitle) && dispatch(deleteVirtualAssetsSettingRowAction({data: {organizationId: '', workspaceId: '', field, oldTitle, title: ''}, onSuccess}));
                break;

            case 'software':
                (field && oldTitle) && dispatch(deleteSoftwareAssetsSettingRowAction({data: {organizationId: '', workspaceId: '', field, oldTitle, title: ''}, onSuccess}));
                break;

            case 'saas':
                (field === 'billingCycle' && oldTitle && title) && dispatch(deleteSaasAssetsSettingRowAction({data: {organizationId: '', workspaceId: '', field, oldTitle, title}, onSuccess}));
                (field === 'customFields' && oldTitle) && dispatch(deleteSaasAssetsSettingRowAction({data: {organizationId: '', workspaceId: '', field, oldTitle, title: ''}, onSuccess}));
                break;

            case 'certificate':
                (field === 'renewal' && oldTitle && title) && dispatch(deleteCertificateAssetsSettingRowAction({data: {organizationId: '', workspaceId: '', field, oldTitle, title}, onSuccess}));
                (field === 'customFields' && oldTitle) && dispatch(deleteCertificateAssetsSettingRowAction({data: {organizationId: '', workspaceId: '', field, oldTitle, title: ''}, onSuccess}));
                break;

            default: break;

        }
        // if (assetType === 'physical') {
        //     if ((field === 'model' || field === 'manufacture' || field === 'category') && oldTitle && title) dispatch(deletePhysicalAssetsSettingRowAction({data: {organizationId: '', workspaceId: '', field, oldTitle, title}, onSuccess}));
        //     if (field === "status") (field && oldTitle && title) && dispatch(deletePhysicalAssetsSettingRowAction({data: {organizationId: '', workspaceId: '', field, oldTitle, title, withHistoryChange, status: newStatus}, onSuccess}));
        //     if (field === "customFields") (field && oldTitle && title) && dispatch(deletePhysicalAssetsSettingRowAction({data: {organizationId: '', workspaceId: '', field, oldTitle, title: title ?? ''}, onSuccess}));
        // }

        // if (assetType === 'virtual') {
        //     (field === 'type' && oldTitle && title) && dispatch(deleteVirtualAssetsSettingRowAction({data: {organizationId: '', workspaceId: '', field, oldTitle, title}, onSuccess}));
        //     (field === 'customFields' && oldTitle) && dispatch(deleteVirtualAssetsSettingRowAction({data: {organizationId: '', workspaceId: '', field, oldTitle, title: ''}, onSuccess}));
        // }
        // if (assetType === 'software') (field && oldTitle) && dispatch(deleteSoftwareAssetsSettingRowAction({data: {organizationId: '', workspaceId: '', field, oldTitle, title: ''}, onSuccess}));

        // if (assetType === 'saas') {
        //     (field === 'billingCycle' && oldTitle && title) && dispatch(deleteSaasAssetsSettingRowAction({data: {organizationId: '', workspaceId: '', field, oldTitle, title}, onSuccess}));
        //     (field === 'customFields' && oldTitle) && dispatch(deleteSaasAssetsSettingRowAction({data: {organizationId: '', workspaceId: '', field, oldTitle, title: ''}, onSuccess}));
        // }
        // if (assetType === 'certificate') {
        //     (field === 'renewal' && oldTitle && title) && dispatch(deleteCertificateAssetsSettingRowAction({data: {organizationId: '', workspaceId: '', field, oldTitle, title}, onSuccess}));
        //     (field === 'customFields' && oldTitle) && dispatch(deleteCertificateAssetsSettingRowAction({data: {organizationId: '', workspaceId: '', field, oldTitle, title: ''}, onSuccess}));
        // }
    };

    const handleClose = () => {
        dispatch(setDeleteSettingRowDialogAction({clear: true}))
    }
    const handleSetTitle = (e:React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
        if (id === 'status') setNewStatus(settings?.status.find((el) => el.name === e.target.value)?.status || null)
    }

    const isOkConfirm = () => {
        if (field === 'customFields') return !deleteSettingLoading && !isLoadingSettings;
        else return Boolean(title && !deleteSettingLoading && !isLoadingSettings && title !== oldTitle && title.trim().length);
    }

    const openCreateSettingRowDialog = (data: TSetCreateSettingRowDialog) => {dispatch(setCreateSettingRowDialogAction(data))};


    useEffect(() => {
        setTitle(null);
        setWithHistoryChange(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, oldTitle]);


    return {
        assetType,
        settingTitle,
        isOpen,
        isOkConfirm: isOkConfirm(),
        field, oldTitle,
        settings,
        virtualSettings,
        softwareSettings,
        saasSettings,
        certificateSettings,
        withHistoryChange, setWithHistoryChange,
        title, setTitle,

        handleSetTitle,
        handleClose,
        handleDeleteSettingRow,
        openCreateSettingRowDialog,
        deleteResponse,

        // isLoadingSettings,
        deleteSettingLoading,
    }
}
