import {useDispatch, useSelector} from "react-redux";
import {docViewer, hideDocViewer, openDocViewer, openEndUserAgreementViewer, openLicenseViewer} from "../store/slice";
import {useState} from "react";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../newDomain/authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";

export const usePDFView = () => { 

    const dispatch = useDispatch();

    const handleClose = () => {dispatch(hideDocViewer())};
    const handleOpen = (payload: {fileId: string, fileName: string, size?: "dialog" | "fullscreen"}) => {dispatch(openDocViewer(payload))};
    const handleOpenLicenseView = () => {dispatch(openLicenseViewer())};
    const openEndUserAgreementView = () => dispatch(openEndUserAgreementViewer());

    const workspaceId = UseManageWorkspacesAndOrganizations().workspaceId || '';

    const [file, setFile] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState<number>(1);

    return {
        handleClose,
        handleOpen,
        handleOpenLicenseView,
        openEndUserAgreementView,

        file, setFile,
        isLoading, setIsLoading,
        numPages, setNumPages,
        pageNumber, setPageNumber,

        workspaceId,
        PDFDialog: useSelector(docViewer)
    }
}