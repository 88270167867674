import React, {Component, createRef, RefObject} from "react";
import {
    FunctionsForToolBar,
    getToolBarProps,
    MapDispatchToPropsObject,
    TAddFillableFieldEventData,
    TEditorActor,
    TEditorFillableBlockData,
    TEditorPdfPageElementData,
    TUpdateDataInFillableFieldEventData,
    TUpdateRecipientEventData
} from "../../types";
import {EditorItemRow} from "../editorItemRow";
import {
    addFillableFieldVariableEventName,
    updateDataInFillableFieldEventName,
    updateRecipientEventName
} from "../../constants";
import {EditorFillableBlock} from "../editorFillableBlock";
import {EditorWorkZone} from "../editorWorkzone";
import {ToolBarProps} from "../editorToolBar";
import {uuid} from "../../../../utils";
import {EditorItemPdfPageStyled} from "./styled";
import {Page} from "react-pdf";
import {EditorDropZoneForFillableField} from "../editorDropZoneForFillableField";

interface Props extends getToolBarProps {
    block: TEditorPdfPageElementData;
    fillableFields: TEditorFillableBlockData[];

    parentRef: RefObject<EditorWorkZone>["current"];
    editorWorkZoneRef: RefObject<EditorWorkZone>["current"];

    handleSubmitSign: (id: string, data: string, insertEverywhere: boolean) => void;
    handleSubmitText: (id: string, data: string) => void;
}
export interface PdfPageState {
    id: string;
    type: 'pdfPage';
    data: TEditorPdfPageElementData["data"];

    fillableFields: TEditorFillableBlockData[];

    refs: Record<string, RefObject<EditorItemRow>>;
    wrapperRef: RefObject<HTMLDivElement>;
}

export class EditorItemPdfPage extends Component<Props, PdfPageState> implements FunctionsForToolBar {
    constructor(props: EditorItemPdfPage["props"]) {
        super(props);

        const refs: PdfPageState["refs"] = {};

        this.state = {
            id: props.block.id,
            type: 'pdfPage',
            data: {
                content: props.block.data.content ?? [],
                index: props.block.data.index ?? -1,
                pdfFileId: props.block.data.pdfFileId ?? '',
            },

            fillableFields: props.fillableFields || [],

            refs,
            wrapperRef: createRef<HTMLDivElement>(),
        }
    };

    componentDidMount() {
        // this.refreshFillableFieldsPosition();

        document.addEventListener(addFillableFieldVariableEventName, this.handleAddFillableFieldByEvent);
        document.addEventListener(updateRecipientEventName, this.handleUpdateRecipientByEvent);
        document.addEventListener(updateDataInFillableFieldEventName, this.handleUpdateDataInFillableFieldsByEvent);
    }
    componentWillUnmount() {
        document.removeEventListener(addFillableFieldVariableEventName, this.handleAddFillableFieldByEvent);
        document.removeEventListener(updateRecipientEventName, this.handleUpdateRecipientByEvent);
        document.removeEventListener(updateDataInFillableFieldEventName, this.handleUpdateDataInFillableFieldsByEvent);
    }

    handleUpdateDataInFillableFieldsByEvent = (ev: Event) => {
        const {detail} = ev as CustomEvent<TUpdateDataInFillableFieldEventData>;

        this.setState(prev => {
            detail.forEach(el => {
                const index = prev.fillableFields.findIndex(fillableField => fillableField.id === el.fieldId);
                if (index > -1) {
                    prev.fillableFields[index].data = el.value;
                }
            })

            return prev
        })
    };

    handleAddFillableFieldByEvent = (ev: Event) => {
        const {detail} = ev as CustomEvent<TAddFillableFieldEventData>;
        this.handleAddFillableField(detail)
    };

    handleUpdateRecipientByEvent = (ev: Event) => {
        const {detail} = ev as CustomEvent<TUpdateRecipientEventData>;
        this.handleUpdateRecipient(detail)
    };

    handleUpdateRecipient = (data: TUpdateRecipientEventData) => {
        const oldFields = this.state.fillableFields.filter(el => data.some(e => e.sourceEmailRole === el.sourceEmailRole));

        if (oldFields.length > 0) {
            this.setState(prev => {
                oldFields.forEach(el => {
                    const element = data.find(e => e.sourceEmailRole === el.sourceEmailRole);
                    const index = prev.fillableFields.findIndex(field => field.id === el.id);
                    if (element && index > -1) {
                        prev.fillableFields[index].actor = element.actor;
                        prev.fillableFields[index].role = element.role;
                        prev.fillableFields[index].sourceEmailRole = '' + element.actor.email + element.role;
                    }
                })

                return prev
            })
        }
    };

    ///////ToolBar
    setToolBar: MapDispatchToPropsObject["setToolBar"] = (toolBar, ids) => {
        // console.log(`setToolBar`, ids);
        this.props.parentRef?.setToolBar(toolBar, [this.state.id, ...ids])
    }
    setTextByToolBar: ToolBarProps["toolBarHandler"] = (action, payload, ids) => {
        const nextId = ids[0];
        if (nextId) {
            const ref = this.state.refs[nextId]?.current;
            if (ref) {
                ref.setTextByToolBar(action, payload, ids.slice(1))
            }
        }
    }

    setActiveBlock: FunctionsForToolBar["setActiveBlock"] = (ids) => {
        this.props.parentRef?.setActiveBlock([this.state.id, ...ids])
    }

    handleAddFillableField = ({position: {x, y}, size, pageId, type, id, actor, sourceEmailRole, role, isRequired, withoutDxDy}: TAddFillableFieldEventData) => {
        const result = {
            position: !withoutDxDy ? {
                x: x - this.state.wrapperRef.current!.offsetLeft + (this.props.parentRef?.state.scrollRef.current?.scrollLeft || 0),
                y: y - this.state.wrapperRef.current!.offsetTop + (this.props.parentRef?.state.scrollRef.current?.scrollTop || 0),
            } : {x, y},
            size: {width: size.width || 115, height: size.height || 52},
            type,
            pageId: pageId || this.state.id,
            id: id || (type + uuid()),
            actor,
            data: '',
            isRequired,
            sourceEmailRole: sourceEmailRole,
            role: role,
        } satisfies TEditorFillableBlockData;

        if (pageId === this.state.id) {
            this.setState(prev => {
                const index = prev.fillableFields.findIndex(e => e.id === result.id);
                if (index > -1) {
                    prev.fillableFields[index] = result
                } else {
                    prev.fillableFields.push(result)
                }

                return prev;
            })
        } else {
            this.handleRemoveFillableField(result.id)
        }
    }

    handleRemoveFillableField = (id: string) => {
        if (this.state.fillableFields.some(e => e.id === id)) {
            this.setState(prev => ({
                ...prev,
                fillableFields: prev.fillableFields.filter(e => e.id !== id)
            }))
        }
    };

    handleResizeFillableField = (id: string) => (size: { width: number, height: number }, position: {x: number, y: number}) => {
        this.setState(prev => {
            const index = prev.fillableFields.findIndex(e => e.id === id);
            if (index > -1) {
                prev.fillableFields[index].size = size;
                prev.fillableFields[index].position = position;
            }

            return prev;
        });
    }

    onChangeEmail = (id: string) => (actor: TEditorActor, role: string) => {
        this.setState(prev => {
            const index = prev.fillableFields.findIndex(e => e.id === id);
            if (index > -1) {
                prev.fillableFields[index].actor = actor;
                prev.fillableFields[index].role = role;
                prev.fillableFields[index].sourceEmailRole = '' + actor.email + role;
            }

            return prev;
        });
    }

    refreshFillableFieldsPosition = () => {
        if (
            this.state.fillableFields.some(e => e.position.metaPageHeight && e.position.metaPageHeight !== this.state.wrapperRef.current?.offsetHeight)
        ) {
            this.setState(prev => {
                    const fillableFields = JSON.parse(JSON.stringify(prev.fillableFields)) as TEditorFillableBlockData[];
                    fillableFields.forEach(e => {
                        if (e.position.metaPageHeight && e.position.metaPageHeight !== this.state.wrapperRef.current?.offsetHeight) {
                            e.position.y = e.position.y / e.position.metaPageHeight * this.state.wrapperRef.current!.offsetHeight;
                            e.position.metaPageHeight = 0;
                        } else {
                            return e
                        }
                    })

                    return {...prev, fillableFields};
                }
            )
        }
    }

    render() {

        return (
            <EditorItemPdfPageStyled ref={this.state.wrapperRef} id={this.state.id}>

                {this.state.fillableFields.map(e => (
                    <EditorFillableBlock key={`${e.id} + ${e.position.x} + ${e.position.y}`}
                                         element={{...e, isRequired: (e.isRequired === null || e.isRequired === undefined) || e.isRequired}}
                                         onResize={this.handleResizeFillableField(e.id)}
                                         parentRef={this} parentDiv={this.state.wrapperRef}
                                         onChangeEmail={this.onChangeEmail(e.id)}

                                         handleSubmitSign={(sign, insertEverywhere) => this.props.handleSubmitSign(e.id, sign, insertEverywhere)}
                                         handleSubmitText={(text) => this.props.handleSubmitText(e.id, text)}

                                         status={this.props.status}
                                         readonly={this.props.readonly}
                                         isLoading={this.props.isLoading}
                    />
                ))}

                <EditorDropZoneForFillableField pageId={this.state.id}>

                    {this.state.data.index > -1 && (
                        <Page pageNumber={this.state.data.index} scale={1}/>
                    )}

                </EditorDropZoneForFillableField>

            </EditorItemPdfPageStyled>
        );
    }
}