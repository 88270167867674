//core
import React from 'react';

//types
import {FlowHistory} from "../../../types";

//Material
import {Button, IconButton, Paper, Table, TableBody, TableCell, TableRow} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import BlockIcon from '@mui/icons-material/Block';

//Layouts
import {useDispatch, useSelector} from "react-redux";
import {selectedAutomation} from "../../../store/slice";
import {StopAutomation} from "../../../store/actions";
import {Flex} from '../../../../../../newShared/components/Layouts';
import {Typo} from "../../../../../../newShared/components/Typography";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {parseDateAuto} from '../../../../../../newShared/utils/dateTools';

const getStatus = (status: string | null | undefined) => {
    switch(status){
        case 'completed': return <CheckCircleOutlineOutlinedIcon style={{color: '#3BCC89'}}/>;
        case 'inProgress': return <TimerOutlinedIcon style={{color: '#FFA000'}}/>
        case 'failed': return <BlockIcon style={{color: '#FF5959'}}/>
        case (null || undefined): return <Flex w={'20px'} h={'20px'} br={'50%'} border={'1px solid #A0A2B1'} />
    }
}

const ExpandableTableRow = ({history, stopHandler}: { history:FlowHistory, stopHandler: () => void }) => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'automations'});
    const {isMobile} = useMedia();

    const [isExpanded, setIsExpanded] = React.useState(false);
    const [expandInner, setExpandInner] = React.useState<number>(-1);


    return (
        <>
            <TableRow style={{width: '100%', overflow: 'auto'}} key={history.id}>
                <TableCell padding="checkbox" style={{width: '100%'}} >
                    <Flex ai={'center'} m={!revDir ? '0 0 0 15px' : '0 15px 0 0'}>
                        {getStatus(history.status || null)}
                        <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                        <Typo fontSize={'14px'} fontWeight={300}>{parseDateAuto(history.runDate)}</Typo>
                        {history.status === 'inProgress' &&
                            // <Flex
                            //     border={'1px solid red'}
                            //     br={'5px'}
                            //     p={' 0 5px'}
                            //     m={'0 0 0 10px'}
                            //     cursor={'pointer'}
                            //     onClick={stopHandler}>
                            //     <Typo cursor={'pointer'} color={'red'} fontSize={'12px'} fontWeight={300}>STOP</Typo>
                            // </Flex>
                            <Button
                                variant={'outlined'}
                                color={'error'}
                                onClick={stopHandler}
                                size={'small'}
                                sx={!revDir ? {marginLeft: '10px'} : {marginRight: '10px'}}
                            >{t('Stop')}</Button>
                        }
                    </Flex>

                </TableCell>
            </TableRow>
            {isExpanded && (
                history.stages.map((stage, stageId, arr) => {
                    return(
                        <>
                            <TableRow style={{width: '100%'}} key={stageId}>
                                <TableCell padding="checkbox" style={{width: '100%'}}>
                                    <Flex ai={'center'} p={!revDir ? '0 0 0 48px' : '0 48px 0 0'}>
                                        {getStatus(stage.status)}
                                        <IconButton onClick={() => setExpandInner(expandInner === stageId ? -1 : stageId)}>
                                            {expandInner === stageId ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                        <Typo fontSize={'14px'} fontWeight={300}>{t(`stageTypes.${stage.type}`)}</Typo>
                                    </Flex>
                                </TableCell>
                            </TableRow>
                            {(isExpanded && expandInner === stageId) && (
                                stage.actors.map((actor, actorId) => {

                                    return(
                                        <TableRow style={{width: '100%', height: '40px'}} key={`stageId-${stageId}-actorId-${actorId}`}>
                                            <TableCell padding="checkbox" style={{width: '100%'}}>
                                                <Flex ai={'center'} p={!revDir ? (!isMobile ? '0 0 0 78px' : '0 32px 0 0') : (!isMobile ? '0 78px 0 0' : '0 32px 0 0')} wrap={'wrap'} direction={isMobile ? 'column' : 'row'}>
                                                    {getStatus(actor.status)}
                                                    <Typo fontSize={'14px'} fontWeight={300} margin={'0 10px 0 10px'}>{actor.email}</Typo>
                                                    {actor.status === 'completed' && actor?.statusChangedDate && actor.statusChangedDate.length > 0 && (
                                                        <Typo fontSize={'14px'} fontWeight={300}>{t('| Completed at')}{parseDateAuto(actor.statusChangedDate)}</Typo>
                                                    )}
                                                </Flex>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            )}
                            {arr.length -1 === stageId &&
                            <TableRow style={{width: '100%', height: '40px'}} key={'EVIDENCE'}>
                                <TableCell padding="checkbox" style={{width: '100%'}}>
                                    <Flex ai={'center'} p={!revDir ? '0 0 0 48px' : '0 48px 0 0'}>
                                        {getStatus(history.evidence.status)}
                                        <Typo fontSize={'14px'} fontWeight={300} margin={'0 10px 0 10px'}>{t(`evidenceTypes.${history.evidence.type}`)}</Typo>
                                    </Flex>
                                </TableCell>
                            </TableRow>}
                        </>

                    )
                }

            ))}
        </>
    );
};

export const AutomationHistoryTable = ({histories, isOnlyHistory}: { histories: FlowHistory[], isOnlyHistory: boolean }) => {
    const dispatch = useDispatch();
    const selected = useSelector(selectedAutomation);

    const stopAuto = () => dispatch(StopAutomation(selected?.id || ''));
    return(
        <Paper style={{
            width: '100%',
            margin: '20px 0 20px 0',
            borderRadius: '25px',
            boxShadow: '0px 0px 25px 4px rgba(0, 0, 0, 0.05)',
            // minWidth: '670px',
            // maxHeight: isOnlyHistory ? '400px' : '220px',
            overflow: 'auto'
        }}>
            <Table aria-label="simple table" style={{width: '100%'}}>
                <TableBody style={{width: '100%'}}>
                    {histories.map((row: FlowHistory) => <ExpandableTableRow key={row.id} history={row} stopHandler={stopAuto} />)}
                </TableBody>
            </Table>
        </Paper>
    )
}