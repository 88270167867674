import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {useSelector} from "react-redux";
import {forbiddenWrapper} from "../../../../authWorkspacesCookies/settings/store/slice";
import {useHistory, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {searchStringToFilter} from "../../../../../newShared/utils/builders";
import {SAVE_NEXT_PATH_PREFIX} from "../../../../authWorkspacesCookies/settings/constants";

export const useNextPathOnLoad = () => {
    const {setCurrentWorkspaceAndOrganization, workspaceId: selectedWorkspaceId, currData: {currentUser}} = UseManageWorkspacesAndOrganizations();
    const {organizations} = useSelector(forbiddenWrapper);

    const history = useHistory();
    const {search} = useLocation();

    const [nextPath, setNextPath] = useState<string | undefined>(undefined);
    const [nextWorkspaceId, setNextWorkspaceId] = useState<string | undefined>(undefined);

    useEffect(() => {
        //     ?nextPath=/documents/myDocuments/d62ca0c9-0afd-433b-91cf-15cf005f0bc5&workspaceId=c2d4616c-b6d6-4dd4-a623-e065c946ec51
        if (search) {
            const filterParams = searchStringToFilter(search);

            const nextPath = filterParams['nextPath']?.[0] || null;
            const workspaceId = filterParams['workspaceId']?.[0] || null;

            if (nextPath) setNextPath(nextPath);
            if (workspaceId) setNextWorkspaceId(workspaceId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        if (currentUser?.publicId && nextPath && nextWorkspaceId) {
            localStorage.setItem(`${SAVE_NEXT_PATH_PREFIX}${currentUser.publicId}#${nextWorkspaceId}`, nextPath);
            // setNextPath(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!!currentUser?.publicId]);

    useEffect(() => {
        if (organizations.length) {
            if (nextWorkspaceId && nextWorkspaceId !== selectedWorkspaceId) {
                const organization = organizations.find(e => e.workspaces.some(e => e.id === nextWorkspaceId));
                const workspace = organization?.workspaces.find(e => e.id === nextWorkspaceId);

                if (organization && workspace) {
                    setCurrentWorkspaceAndOrganization(workspace, organization, currentUser || undefined)
                }
            }
            setNextWorkspaceId(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizations]);

    // for disabling menu items for users without access
    // const {getMyHrProfile} = useHrProfile();
    useEffect(() => {
        // if (currentUser?.publicId && selectedWorkspaceId && organizations.length) {
        //     // check if user has access to myHr
        //     getMyHrProfile(true);
        // }




        if (currentUser?.publicId && nextPath && selectedWorkspaceId) {
            const path = localStorage.getItem(`${SAVE_NEXT_PATH_PREFIX}${currentUser.publicId}#${selectedWorkspaceId}`);
            if (nextPath === path) {
                history.push(nextPath);
                localStorage.removeItem(`${SAVE_NEXT_PATH_PREFIX}${currentUser.publicId}#${selectedWorkspaceId}`);
                setNextPath(undefined);
            }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!!currentUser?.publicId, selectedWorkspaceId, organizations]);
};