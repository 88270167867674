import {FC} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useMainTranslation} from "../../hooks/useMainTranslationHooks/useMainTranslation";
import {LoadingButton} from "../Basic/CommonButtons";

type TProps = {
    isOpen: boolean,
    isLoading: boolean,

    title: string,
    message: string,
    handleConfirm: () => void,
    handleClose: () => void,
}
export const DeleteDialogButton: FC<TProps> = ({title, handleConfirm, isLoading, message, isOpen, handleClose}) => {
    const {tCommon} = useMainTranslation();

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{message}</DialogContent>
            <DialogActions>
                <Button variant={"text"} onClick={handleClose} disabled={isLoading}>{tCommon('Cancel')}</Button>
                <LoadingButton loading={isLoading} onClick={handleConfirm}>
                    {tCommon('Confirm')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

