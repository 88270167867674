import {Button, Skeleton} from '@mui/material';
import * as S from '../styled';
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import React from "react";

export const TemplateCardSkeleton = () => {
    const {isTablet} = useMedia();

    return(
        <S.TemplateCardContainer isMobile={isTablet}>
            <S.TemplateCardTitleTypeMenuContainer>
                <S.TemplateCardTitleTypeContainer>
                    <S.TitleTypo><Skeleton width={150}/></S.TitleTypo>
                    <Skeleton width={96} height={28} variant={'rounded'}/>
                </S.TemplateCardTitleTypeContainer>

                <S.MenuButton disabled>
                    <S.MenuButtonIcon />
                </S.MenuButton>
            </S.TemplateCardTitleTypeMenuContainer>

            <S.TemplateDescriptionText><Skeleton width={400}/></S.TemplateDescriptionText>
            <S.TemplateDescriptionText><Skeleton width={400}/></S.TemplateDescriptionText>

            <S.TemplateCardActionsRow>
                <Button disabled size={'small'}>{'Create client'}</Button>
                <Button disabled variant={'outlined'} size={'small'}>{'Send to client'}</Button>
                <Button disabled variant={'outlined'} size={'small'}>{'OTS'}</Button>
                <Button disabled variant={'outlined'} size={'small'}>{'Import'}</Button>
            </S.TemplateCardActionsRow>
        </S.TemplateCardContainer>
    )
}
