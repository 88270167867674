import React from "react";
import {useEditSetting} from "../../../hooks/useVendorsDialogs/useEditSetting";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle,} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {normalizeSettingIdName, normalizeSettingIdNameToLowerCase} from "../../../helpers";
import {TextFieldCustom} from "../../styled";


export const EditDialog = () => {
    const {
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        handleChange,
        form,
        isOk,
        isExist,
        type
    } = useEditSetting()

    const {revDir, t} = useMainTranslation('',{keyPrefix:'pathVendors.VendorsSettings'});
    const {isMobile} = useMedia();

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            {type &&
                <>
                    <DialogTitle>{t(`Edit {{name}}`,{name: t(normalizeSettingIdNameToLowerCase(type))})}</DialogTitle>

                    <DialogContent>
                <TextFieldCustom
                size="small"
                label={t(normalizeSettingIdName(type))}
                fullWidth
                sx={{height: '40px', margin: '8px 0 0 0'}}
                value={form.newName}
                name={'newName'}
                required
                autoFocus
                disabled={isLoading}
                onChange={handleChange}
                error={isExist}
                helperText={isExist ? t(`Such {{name}} already exists.`,{name:t(normalizeSettingIdNameToLowerCase(type))}) : ''}
                />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{t('Cancel')}</Button>
                    {isLoading ?
                        <LoadingButton
                            loading
                            sx={{paddingLeft: '35px', margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                            variant="outlined"
                        >{t('Confirm')}</LoadingButton>
                        :
                        <Button
                            variant={'contained'}
                            disabled={!isOk ||isExist }
                            size={isMobile ? 'small' : 'medium'}
                            onClick={handleSubmit}
                            sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                        >{t('Confirm')}</Button>

                    }
                </DialogActions>
                </>
            }

        </Dialog>
    )
}