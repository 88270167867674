import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideCreateRisk, loadings, selectedRegistry} from "../../../store/slice";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {createRiskAction} from "../../../store/actions";

export const useCreateRisk = () => {
    const dispatch = useDispatch();
    const {createRisk} = useSelector(dialogs);
    const {riskCreate} = useSelector(loadings);
    const reg = useSelector(selectedRegistry);

    const handleClose = () => {
        dispatch(hideCreateRisk());
        setForm({name: '', type: '', description: ''});
    }

    const {form, setForm, handleChange} = useForm<{name: string, type: string, description: string}>({name: '', type: '', description: ''});

    const handleSubmit = () => {
        reg && dispatch(createRiskAction({
           workspaceId: '',
           organizationId: '',
           risk: {
               control: {
                   type: null,
                   actions: '',
                   owners: [],
                   scheduler: null
               },
               description: form.description,
               id: '',
               initialGrade: {
                   probability: 0,
                   impact: 0
               },
               name: form.name,
               residualGrade: {
                   probability: 0,
                   impact: 0
               },
               type: form.type,
           },
           registryId: reg.id
       }));
        setForm({name: '', type: '', description: ''});
    }

    const isOk = () => {
        return(
            (form.name.trim().length > 0 &&
                form.type.trim().length > 0 &&
                form.description.trim().length > 0)
        )
    }

    return{
        form,
        handleChange,
        handleClose,
        isOpen: createRisk,
        isLoading: riskCreate,
        isOk,
        handleSubmit,

    }
}
