import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useHistory} from "react-router";
import {useReport} from "../../../hooks/useReport";
import {useReportDialogs} from "../../../hooks/useReportDialogs";
import {useThreeDotsMenu} from "../../../../../../newShared/hooks/useThreeDotsMenu";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    PATH_LOCAL_COMPLIANCE_REPORTS,
    PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED,
    PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED
} from "../../../../../../newShared/constants";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../../newShared/components/genericTable/types";
import {setMinMaxLoadedPageGeneratedReports} from "../../../store/reports/store";
import {useDispatch} from "react-redux";
import {cleanReportControlsAction} from "../../../store/reportView/store";
import {useReportsParams} from "../../../hooks/useReportsParams";

export const useReportViewPage = () => {
    const dispatch = useDispatch();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    const {reportId, controlId} = useReportsParams();
    const history = useHistory();
    // const {filter} = useParameters();

    const {
        report: {report, NCRs}, controls: {controls, pageInfo, selectedControl, minMaxLoadedPage},
        isLoading: {isLoadingControls, isSubmittingReport},
        // getReport,
        getControls,
        changeReportStatus,

        goToArchived, goToGenerated, goToControl, goToIntegrationDetails,
        // cleanReport,
    } = useReport();
    const {
        preview: {setPreviewDialog},
        deleteReport: {setDeleteReportDialog},
        archiveReport: {setArchiveReportDialog},
    } = useReportDialogs();

    const IN_REVIEW = report?.reportStatus === 'IN_REVIEW';
    const PUBLISHED = report?.reportStatus === 'PUBLISHED';
    const RESPONSE = report?.reportStatus === 'RESPONSE';
    const COMPLETED = report?.reportStatus === 'COMPLETED';
    const REJECTED = report?.reportStatus === 'REJECTED';
    const ARCHIVED = report?.archived;
    const ALL_NCR_ANSWERED = NCRs.every(e => e.status !== 'OPEN' && e.status !== 'REJECTED');

    const {
        anchorThreeDotsEl, handleThreeDotsOpen, handleThreeDotsClose,
    } = useThreeDotsMenu();

    //Controller and first loadData
    // const controller = new AbortController();
    // useEffect(() => {
    //     reportId && reportId !== report?.id && getReport(reportId, controller.signal);
    //     return () => {
    //         // controller.abort();
    //         // cleanReport();
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [reportId]);

    const {isLessThen1050} = useMedia();

    //Breadcrumbs
    useSetBreadcrumbs([
        {
            title: tMenu('Reports'),
            path: PATH_LOCAL_COMPLIANCE_REPORTS,
        },
        {
            title: tMenu('Generated reports'),
            path: PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED,
            skip: ARCHIVED ?? !history.location.pathname.includes(PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED),
        },
        {
            title: tMenu('Archived reports'),
            path: PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED,
            skip: !ARCHIVED ?? !history.location.pathname.includes(PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED),
        },
        {
            title: report?.name || 'Loading report...',
            path: report && selectedControl ? `${report.archived ? PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED : PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED}/${report.id}` : undefined,
        },
        {
            title: selectedControl?.name || 'Loading control...',
            skip: !(selectedControl && isLessThen1050)
        },
    ]);

    // pagination
    // const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
    //     getData: ({page, count}) => {
    //         reportId && getControls(reportId, {
    //             page, count, filters: filter
    //         }, reportId !== report?.id, controller.signal)
    //     },
    //     arrayLength: controls.length,
    //     isLoading: isLoadingControls,
    //     pageInfo: {page, count, total},
    //     // cleanData: cleanReport,
    //     controller: controller,
    //     // isOnTop: true,
    //     isNoMoreThanTotal: true,
    //     customDependencies: [reportId],
    // })

    return {
        reportId, controlId,
        // filter,
        report, controls, selectedControl,
        changeReportStatus,
        IN_REVIEW, PUBLISHED, RESPONSE, COMPLETED, REJECTED, ARCHIVED, ALL_NCR_ANSWERED,
        goToArchived, goToGenerated, goToControl, goToIntegrationDetails,
        isLoading: {isSubmittingReport, isLoadingControls, },
        setPreviewDialog, setArchiveReportDialog, setDeleteReportDialog,
        handleThreeDotsOpen, anchorThreeDotsEl, handleThreeDotsClose,
        // onScroll, scrollRef, bottomLoader: bottomLoader && ((controls.length < total) || (controls.length === total && total === 0)),
        // value, handleChange, setFilter, handleKeyDown,
        archivedPage: history.location.pathname.includes(PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED),
        genericTable: {
            paging: {
                pageInfo,
                isLoading: isLoadingControls,
                minLoadedPage: minMaxLoadedPage.minLoadedPage,
                maxLoadedPage: minMaxLoadedPage.maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(setMinMaxLoadedPageGeneratedReports(data)),
                clearRows: () => dispatch(cleanReportControlsAction()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    reportId && getControls(reportId, {
                                page, count, filters
                            }, false);
                },
            }
        }
    }
}