import {useDispatch, useSelector} from "react-redux";
import {
    dialogs as dialogsSelector,
    employees,
    employeesPageInfo,
    hideExportCsv,
    loadings,
    openDeleteEmployeeDialog,
    openExportCsv,
    openReleaseDialog,
    setImportCsvDialogAction
} from "../../store/slice";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {
    availableColumns,
    PATH_LOCAL_EMPLOYEES_ALL,
    PATH_LOCAL_EMPLOYEES_NEW,
    PATH_LOCAL_EMPLOYEES_TREE,
    PATH_LOCAL_EMPLOYEES_TREE_EXACT
} from "../../constants";
import {ColumnType, EmployeeType} from "../../types";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_EMPLOYEES} from "../../../../../newShared/constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {getEmployeesColumnsSettingsLocalStorage, setEmployeesColumnsSettingsLocalStorage} from "../../helpers";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {ExportCsvEmployees, GetEmployeesAvailableFieldsForExport} from "../../store/actions";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {TIdName} from "../../../../../newShared/types";
import {getName} from "../../../../../newShared/utils/text";

export const useEmployeesList = () => {
    //root
    const dispatch = useDispatch();
    const history = useHistory();
    const {workspaceId, user} = UseManageWorkspacesAndOrganizations();
    const {currentFiltersForFetch, currentSearchForFetch, isSearchAndFilterEmpty} = useGenericFiltersStorage();

    // useEffect(() => {
    //     console.log(`SAVE IN SEARCH`, currentFiltersForFetch);
    //     history.push({
    //         pathname: history.location.pathname,
    //         search: JSON.stringify({filters: currentFiltersForFetch})
    //     });
    // }, [currentFiltersForFetch]);
    //
    // useEffect(() => {
    //     console.log(`HISTORY`, history.location.search);
    // }, [history.location.search]);

    //selectors
    const _employees = useSelector(employees);
    const pageInfo = useSelector(employeesPageInfo);
    const {getEmployeesList, exportCsv, importCsv, isLoadingFields} = useSelector(loadings);
    const dialogs = useSelector(dialogsSelector);


    //controlled
    const controller = new AbortController();

    //useEffects
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    useSetBreadcrumbs([
        {
            title: tMenu('HR Management'),
            path: PATH_LOCAL_EMPLOYEES
        },
        {
            title: tMenu('Employees'),
            path: PATH_LOCAL_EMPLOYEES_ALL
        }
    ]);

    useEffect(() => {
        if(user && workspaceId){
            const settings = getEmployeesColumnsSettingsLocalStorage(user.publicId, workspaceId);
            setColumnSettings(settings);
            // !_employees.length && dispatch(GetEmployees({
            //     data: {
            //         pageInfo: {
            //             page: 0,
            //             count: EMPLOYEES_LIST_DEFAULT_PAGING,
            //             filters: currentFiltersForFetch
            //         },
            //         search: currentSearchForFetch
            //     },
            //     signal: controller.signal
            // }))
        }

        return () => {
            controller.abort();
        }
        //eslint-disable-next-line
    }, []);

    //states
    const [isTableView, setIsTableView] = useState<boolean>(true);
    const [columnsSettings, setColumnSettings] = useState<ColumnType[]>(availableColumns);
    const [columnSettingsDialog, setColumnSettingsDialog] = useState<boolean>(false);


    const handleSubmitViewSettings = (selectedColumns: TIdName[]) => {
        if(user && workspaceId){
            const newSelected: ColumnType[] = [];
            selectedColumns.forEach(e => {
                const column = availableColumns.find(c => c.key === e.id);
                column && newSelected.push(column);
            })
            setEmployeesColumnsSettingsLocalStorage(user.publicId, workspaceId, newSelected);
            setColumnSettings(newSelected);
            setColumnSettingsDialog(false);
        }
    }

    const handleOpenExportCsv = () => {
        dispatch(openExportCsv());
        dispatch(GetEmployeesAvailableFieldsForExport());
    }

    const handleCloseExportCsv = () => {
        dispatch(hideExportCsv());
    }

    const handleExportCsv = (fieldKeys: string[]) => {
        const config = getEmployeesColumnsSettingsLocalStorage(user?.publicId ?? '', workspaceId ?? '');
        dispatch(ExportCsvEmployees({
            data: {
                search: currentSearchForFetch,
                filters: currentFiltersForFetch,
                columnConfig: config.map(e => e.key)
            },
            fieldKeys,
            isDownload: pageInfo.total < 10000,
        }));
    }

    return{
        isLoadingExportCsv: exportCsv,
        isLoadingImportCsv: importCsv,
        isLoadingFields,
        exportCsv: {
            isOpen: dialogs.exportCsv.isOpen,
            availableFields: dialogs.exportCsv.fields,
            handleExportCsv,
            handleClose: handleCloseExportCsv,
        },
        isTableView,
        toggleTableView: () => setIsTableView(!isTableView),
        topBarActions: {
            handleAddEmployee: () => history.push(PATH_LOCAL_EMPLOYEES_NEW),
            handleReleaseEmployees: () => dispatch(openReleaseDialog()),
            handleOpenExportCsv,
            // handleExportCsv: (fieldKeys?: string[]) => {
            //     const config = getEmployeesColumnsSettingsLocalStorage(user?.publicId ?? '', workspaceId ?? '');
            //     dispatch(ExportCsvEmployees({
            //         data: {
            //             search: currentSearchForFetch,
            //             filters: currentFiltersForFetch,
            //             columnConfig: config.map(e => e.key)
            //         },
            //         fieldKeys: []}));
            // },
            handleImportCsv: () => dispatch(setImportCsvDialogAction({isOpen: true})),
            goToOrgTree: () => history.push(PATH_LOCAL_EMPLOYEES_TREE)
        },
        isShowingNoEmployees: !getEmployeesList && !_employees.length && isSearchAndFilterEmpty,
        isShowingNoEmployeesResult: !getEmployeesList && !_employees.length && !isSearchAndFilterEmpty,
        columnSettings: {
            settings: columnsSettings,
            handleSave: handleSubmitViewSettings,
            columnVariants: availableColumns,
            // updateSettings: (columns: ColumnType[]) => {
            //     setColumnSettings(columns);
            //     setColumnSettingsDialog(false);
            // },
            isOpenDialog: columnSettingsDialog,
            handleClose: () => setColumnSettingsDialog(false),
            handleOpen: () => setColumnSettingsDialog(true)
        },
        signal:  controller.signal,
        handleGoToTree: (row: EmployeeType) => history.push(PATH_LOCAL_EMPLOYEES_TREE_EXACT.replace(':employeeId', row.id)),
        handleDelete: (row: EmployeeType) => dispatch(openDeleteEmployeeDialog({id: row.id, name: getName(row.firstName, row.lastName)})),
    }
}
