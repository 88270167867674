import {useDispatch} from "react-redux";
import {THrSettingsJobTitle} from "../../types";
import {openDeleteJobTitleDialog, openEditJobTitleDialog, openPreviewJobTitleDialog} from "../../store/slice";
import {useMenu} from "../../../../../newShared/hooks/useMenu";

export const useJobTitleRow = ({jobTitle}: { jobTitle: THrSettingsJobTitle }) => {
    const dispatch = useDispatch();
    const menu = useMenu();

    return{
        handlePreview: () => {
            dispatch(openPreviewJobTitleDialog(jobTitle));
            menu.handleClose();
        },
        handleEdit: () => {
            dispatch(openEditJobTitleDialog(jobTitle));
            menu.handleClose();
        },
        handleDelete: () => {
            dispatch(openDeleteJobTitleDialog(jobTitle));
            menu.handleClose();
        },
        menu
    }
}