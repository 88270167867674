import axiosSync from "../../../../newShared/utils/axios/axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {AxiosResponse} from "axios";
import {
    AddVendorFileMutation,
    AddVendorFileMutationVariables,
    CreateVendorMutation,
    CreateVendorMutationVariables,
    CreateVendorsSettingRowMutation,
    CreateVendorsSettingRowMutationVariables,
    DeleteVendorFileMutation,
    DeleteVendorFileMutationVariables,
    DeleteVendorMutation,
    DeleteVendorMutationVariables,
    DeleteVendorsSettingRowMutation,
    DeleteVendorsSettingRowMutationVariables,
    DownloadEvidenceFileQuery,
    DownloadEvidenceFileQueryVariables,
    EditVendorsSettingRowMutation,
    EditVendorsSettingRowMutationVariables,
    ExportCsvVendorsQuery,
    ExportCsvVendorsQueryVariables,
    GetVendorByIdQuery,
    GetVendorsAvailableFieldsForExportQuery,
    GetVendorsAvailableFieldsForExportQueryVariables,
    GetVendorsQuery,
    GetVendorsSettingsQuery,
    GetVendorsSettingsQueryVariables,
    PaginationInput,
    ReleaseVendorsMutation,
    ReleaseVendorsMutationVariables,
    UpdateVendorMutation,
    UpdateVendorMutationVariables,
} from "../../../../newShared/GQLTypes";
import {
    addVendorFile,
    createVendor,
    createVendorsSettingRow,
    deleteVendor,
    deleteVendorFile,
    deleteVendorsSettingRow,
    downloadEvidenceFile,
    editVendorsSettingRow,
    exportCsvVendors,
    getVendors,
    getVendorsSettings,
    importCsvTemplateVendors,
    releaseVendors,
    updateVendor
} from './query'
import {print} from "graphql";
import {gql} from "@apollo/react-hooks";


export const downloadEvidenceFileAPI = async (data: DownloadEvidenceFileQueryVariables): Promise<DownloadEvidenceFileQuery["downloadEvidenceFile"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: downloadEvidenceFile,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.downloadEvidenceFile as {file: string, filename: string, filetype: string};
    })
)

export const getVendorsApi = async (workspaceId: string, organizationId: string, pageInfo: PaginationInput): Promise<GetVendorsQuery["getVendors"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getVendors,
                variables: {
                    pageInfo,
                    workspaceId,
                    organizationId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getVendors;
    })
)

export const createVendorApi = async (data: CreateVendorMutationVariables): Promise<CreateVendorMutation["createVendor"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: createVendor,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createVendor;
    })
)

export const updateVendorApi = async (data: UpdateVendorMutationVariables): Promise<UpdateVendorMutation["updateVendor"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: updateVendor,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateVendor;
    })
)

export const deleteVendorApi = async (data: DeleteVendorMutationVariables): Promise<DeleteVendorMutation["deleteVendor"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: deleteVendor,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteVendor;
    })
)

// export const importCsvVendorsApi = async (data: ImportCsvVendorsMutationVariables): Promise<ImportCsvVendorsMutation["importCsvVendors"]> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: importCsvVendors,
//                 variables: data
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.importCsvVendors;
//     })
// )
export const importCsvTemplateVendorsApi = async (data: {workspaceId: string, organizationId: string}): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: importCsvTemplateVendors,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.importCsvTemplateVendors;
    })
)

export const exportCsvVendorsApi = async (data: ExportCsvVendorsQueryVariables): Promise<ExportCsvVendorsQuery["exportCsvVendors"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: exportCsvVendors,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.exportCsvVendors;
    })
)

export const getVendorsAvailableFieldsForExport = async (data: GetVendorsAvailableFieldsForExportQueryVariables): Promise<GetVendorsAvailableFieldsForExportQuery["getVendorsAvailableFieldsForExport"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getVendorsAvailableFieldsForExport($workspaceId:String!){
                        getVendorsAvailableFieldsForExport(workspaceId:$workspaceId){
                            fields {
                                fieldKey
                                fieldTitle
                            }
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getVendorsAvailableFieldsForExport;
    })
);


// SETTINGS
export const getVendorsSettingsApi = async (data: GetVendorsSettingsQueryVariables): Promise<GetVendorsSettingsQuery["getVendorsSettings"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getVendorsSettings,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getVendorsSettings;
    })
)

export const createVendorsSettingRowApi = async (data: CreateVendorsSettingRowMutationVariables): Promise<CreateVendorsSettingRowMutation["createVendorsSettingRow"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: createVendorsSettingRow,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createVendorsSettingRow;
    })
)

export const editVendorsSettingRowApi = async (data: EditVendorsSettingRowMutationVariables): Promise<EditVendorsSettingRowMutation["editVendorsSettingRow"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: editVendorsSettingRow,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.editVendorsSettingRow;
    })
)

export const deleteVendorsSettingRowApi = async (data: DeleteVendorsSettingRowMutationVariables): Promise<DeleteVendorsSettingRowMutation["deleteVendorsSettingRow"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: deleteVendorsSettingRow,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteVendorsSettingRow;
    })
)

export const addVendorFileApi = async (data: AddVendorFileMutationVariables): Promise<AddVendorFileMutation["addVendorFile"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: addVendorFile,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addVendorFile;
    })
)

export const deleteVendorFileApi = async (data: DeleteVendorFileMutationVariables): Promise<DeleteVendorFileMutation["deleteVendorFile"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: deleteVendorFile,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteVendorFile;
    })
)

export const releaseVendorsApi = async (data: ReleaseVendorsMutationVariables): Promise<ReleaseVendorsMutation["releaseVendors"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: releaseVendors,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.releaseVendors;
    })
)


export const getVendorById = async (workspaceId: string, id: string): Promise<GetVendorByIdQuery["getVendorById"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getVendorById($workspaceId:String!, $id:String!){
                        getVendorById(workspaceId:$workspaceId, id:$id){
                            id
                            name
                            link
                            type
                            serviceProvided
                            risk
                            owner {
                                publicId
                                firstName
                                lastName
                                email
                            }
                            contactPersons {
                                email
                                name
                                phone
                            }
                            securityReviewDate
                            documentStatus
                            documents {
                                id
                                name
                                type
                            }
                            evaluation {
                                evaluator {
                                    publicId
                                    firstName
                                    lastName
                                    email
                                }
                                price
                                quality
                                service
                                notesAndRecommendations
                            }
                            customFields
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    id
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getVendorById;
    })
)

export const deleteVendorById = async (workspaceId: string, id: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deleteVendorById($workspaceId:String!, $id:String!){
                        deleteVendorById(workspaceId:$workspaceId, id:$id){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    id
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteVendorById.message;
    })
)

