import {Box, Skeleton, TableCell, TableRow} from "@mui/material";
import React, {FC} from "react";

type TProps = {
    rows?: number;
    withButtons?: boolean
}

export const TableCardSkeleton: FC<TProps> = ({rows = 3, withButtons = true}) => {

    return (
        <TableRow>
            <TableCell style={{ padding: 0, borderBottom: 'none' }}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} sx={{borderRadius: '8px', m: '6px', p: '8px', boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)'}}>
                    {Array(rows).fill(1).map((_, index) => (
                        <Skeleton key={rows + ' ' + index} width={'60%'}/>
                    ))}
                    {withButtons &&
                        <Box display={"flex"} alignSelf={"flex-end"}>
                            <Skeleton width={'50px'} height={'30px'} sx={{ml: '8px', mr: '8px'}} />
                            <Skeleton width={'50px'} height={'30px'} />
                        </Box>
                    }
                </Box>
            </TableCell>
        </TableRow>
    )

}