import {TEmployeeJobHistory} from "../../types";
import {useDispatch} from "react-redux";
import React, {useState} from "react";
import {openChangeHistoryRowDialog, openDeleteHistoryRowDialog} from "../../store/slice";


export const useHistoryRow = ({history}: {history: TEmployeeJobHistory}) => {

    const dispatch = useDispatch();

    const handleDeleteRow = () => {
        dispatch(openDeleteHistoryRowDialog(history));
        handleClose()
    }

    const handleUpdateRow = () => {
        dispatch(openChangeHistoryRowDialog(history));
        handleClose()
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return{
        handleDeleteRow,
        handleUpdateRow,
        menu: {
            anchorEl,
            handleClose,
            handleClick,
            open,
        },
    }
}