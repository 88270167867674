import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    apps,
    employeesIdName,
    loadings,
    openDeleteInstructionDialog,
    openRunReviewWarning,
    openSelectEvidence,
    releaseData,
    selectedInstruction
} from "../../../../store/slice";
import React, {useEffect, useState} from "react";
import {
    GetAccessManagementApps,
    GetCampaignReviewInstructionById,
    GetDataForRelease,
    GetEmployeesNameEmail,
    RunCampaignReviewInstruction,
    ToggleCampaignReviewInstructionStatus,
    UpdateCampaignReviewInstruction
} from "../../../../store/actions";
import {
    defaultExpands,
    GET_ALL_ACCESS_MANAGE_APPS_FOR_CREATE_AND_EDIT,
    IN_PROGRESS_STATUS,
    PATH_ACCESS_MANAGEMENT_REVIEW_EXECUTORS_EXACT,
    PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_EXACT,
    PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_LIST,
    PATH_LOCAL_ACCESS_MANAGEMENT,
    SCHEDULE_TRIGGER_TYPE
} from "../../../../constants";
import {useForm} from "../../../../../../../newShared/hooks/useForm";
import {campaignReviewInstructionType, evidenceWithNames, expandsType, onlyActor} from "../../../../types";
import {calculateNextStart, getEtaConfigByInstruction, getInitialInstruction, mapper} from "../../../../helpers";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {translateText} from "../../../../../automations/helpers";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {validateEmail} from "../../../../../../../newShared/utils/text";

export const useCampaignReviewInstructionExact = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {id} = useParams<{id: string}>();
    const {currData} = UseManageWorkspacesAndOrganizations();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    //controller
    const controller = new AbortController();
    //selectors
    const exact = useSelector(selectedInstruction);
    const {campaignReviewInstructionsExact, dataForRelease, list, campaignReviewInstructionUpdate, campaignReviewInstructionRun, getEmployees} = useSelector(loadings);
    const _apps = useSelector(apps);
    const releaseDT = useSelector(releaseData);
    const empls = useSelector(employeesIdName);


    //useEffects
    useEffect(() => {
        !empls.length && dispatch(GetEmployeesNameEmail());
        dispatch(GetCampaignReviewInstructionById({data: {id, onReject: handleGoBack}, signal: controller.signal}));
        !_apps.length && dispatch(GetAccessManagementApps({data: {page: 0, count: GET_ALL_ACCESS_MANAGE_APPS_FOR_CREATE_AND_EDIT}, signal: controller.signal})); //gettings all apps
        (!releaseDT.frameworks.length) && dispatch(GetDataForRelease(controller.signal)); //getting frameworks/controls/evidences and employees
        setExpands(defaultExpands);
        //eslint-disable-next-line
    }, []);

    //inits on this page - waiting for loading finish
    useEffect(() => {
        if(!campaignReviewInstructionsExact && exact){
            const translateText1 = translateText(form.trigger.repeat);
            setScheduleSelect({value: translateText1.timeValue, num: translateText1.factor});
            const etaConfig = getEtaConfigByInstruction(exact.etaConfig);
            setForm({...exact, etaConfig});
            setFinalApproverSelectionType(((exact.finalApprover.firstName ?? '').length > 0 && (exact.finalApprover.lastName ?? '').length > 0) ? 'hr' : 'email');
        }
        //eslint-disable-next-line
    }, [campaignReviewInstructionsExact]);

    useSetBreadcrumbs([
        {
            title: tMenu('Access management'),
            path: PATH_LOCAL_ACCESS_MANAGEMENT
        },
        {
            title: tMenu('Review campaign config'),
            path: PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_LIST
        },
        {
            title: exact ? exact.name : tMenu('Loading...'),
            path: PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_EXACT.replace(':id', id)
        }
    ]);


    //form
    const {form, setForm, handleChange} = useForm<campaignReviewInstructionType>(getInitialInstruction());

    const handleChangeEtaConfig = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setForm((prevValues) => ({
            ...prevValues,
            etaConfig: {
                ...prevValues.etaConfig,
                [event.target.name]: event.target.value,
            }
        }));
    };

    //actions
    const handleGoBack = () => history.push(PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_LIST);

    const handleSubmit = () => {
        if(isOk()){
            // eta filled by number of days -> translating to ms
            const requestForm: campaignReviewInstructionType = {
                ...form,
                etaConfig: {
                    etaManager: form.etaConfig.etaManager * mapper.Days,
                    etaVendor: form.etaConfig.etaVendor * mapper.Days,
                    etaExecution: form.etaConfig.etaExecution * mapper.Days,
                    etaVerify: form.etaConfig.etaVerify * mapper.Days,
                    etaSign: form.etaConfig.etaSign * mapper.Days,
                },
                workspaceId: currData?.selectedWorkspace?.id ?? '',
                workspaceName: currData?.selectedWorkspace?.name ?? '',
                evidence: {
                    type: 'evidence',
                    data: form.evidences.map(e => {return {id: e.evidenceId, name: e.evidenceName ?? '', controlId: e.controlId, frameworkId: e.frameworkId}})
                },
                applicationAccessIds: form.applications.map(e => e.id)
            }

            dispatch(UpdateCampaignReviewInstruction({data: {instruction: requestForm, onSuccess: onSuccessUpdate}}));
        }
    }

    const onSuccessUpdate = () => {
        setIsEditMode(false);
    }

    const isOk = (): boolean => {
        return (
            form.name.trim().length > 0 &&
                form.etaConfig.etaManager > 0 &&
                form.etaConfig.etaVendor > 0 &&
                form.etaConfig.etaExecution > 0 &&
                form.etaConfig.etaVerify > 0 &&
                form.etaConfig.etaSign > 0 &&
                form.evidences.length > 0 &&
                form.applications.length > 0 &&
                !isCommonExecutionTimeIsBiggerThanRepeat() &&
                validateEmail(form.finalApprover.email)
        )
    }

    //expands
    const [_expands, setExpands] = useState<expandsType>(defaultExpands);

    const toggleExpand = (expand: keyof typeof _expands) => {
        setExpands({..._expands, [expand]: !_expands[expand]});
    }

    const handleDeleteEvidence = (id: string) => {
        setForm({...form, evidences: [...form.evidences.filter(e => e.evidenceId !== id)]});
    }

    const handleOpenAddEvidence = () => {
        dispatch(openSelectEvidence());
    }

    const handleSelectEvidence = (evidence: evidenceWithNames) => {
        setForm({...form, evidences: [evidence, ...form.evidences]})
    }

    const handleAddApp = (id: string, name: string) => {
        setForm({...form, applications: [...form.applications, {id, name}]});
    }

    const handleDeleteApp = (id: string) => {
        setForm({...form, applications: [...form.applications.filter(e => e.id !== id)]});
    }

    const handleSetScheduleType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({...form, trigger: {...form.trigger, type: (event.target as HTMLInputElement).value}})
    }

    const [scheduleSelect, setScheduleSelect] = useState<{value: string, num: number}>({value: 'Years', num: 1});

    const handleSelectRepeatNum = (num: number) => {
        const inSeconds = num*mapper[scheduleSelect.value]; //translated to miliseconds already
        setForm({...form, trigger: {...form.trigger, repeat: inSeconds}});
        setScheduleSelect({...scheduleSelect, num});
    }

    const handleSelectRepeatValue = (value: string) => {
        const inSeconds = scheduleSelect.num*mapper[value]; //translated to miliseconds already
        setForm({...form, trigger: {...form.trigger, repeat: inSeconds}});
        setScheduleSelect({...scheduleSelect, value});

    }

    const handleSetStartTrigger = (value: string | null) => {
        if(value){
            const normalizedValue = new Date(value).toISOString();
            // console.log(`handleSetStartTrigger: ${value} -> ${normalizedValue}`);
            value && setForm({...form, trigger: {...form.trigger, start: normalizedValue}});
        }
    }

    const handleSetFinalApprover = (actor: onlyActor) => {
        setForm({...form, finalApprover: {
                firstName: actor.firstName,
                lastName: actor.lastName,
                publicId: actor.publicId,
                email: actor.email ?? ''
            }});
    }

    const handleChangeFinalApprover = (email: string) => {
        setForm({...form, finalApprover: {email, lastName: null, firstName: null, publicId: '' }});
    }

    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    //MENU
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //
    const onSuccessRun = (lastExecutorId: string) => {
        history.push(PATH_ACCESS_MANAGEMENT_REVIEW_EXECUTORS_EXACT.replace(':id', lastExecutorId));
    }

    const isCommonExecutionTimeIsBiggerThanRepeat = (): boolean => {
        return (form.trigger.type === SCHEDULE_TRIGGER_TYPE) ? form.trigger.repeat < (Object.values(form.etaConfig).reduce((a, b) => a + b, 0) * mapper.Days) : false;
    }

    //
    const [finalApproverSelectionType, setFinalApproverSelectionType] = useState<'email' | 'hr'>('hr');


    return{
        releaseDT,
        _apps,
        loading: {
            isLoadingExact: campaignReviewInstructionsExact,
            isLoadingDataForCreate: dataForRelease,
            isLoadingApps: list,
            isLoadingUpdate: campaignReviewInstructionUpdate,
            isLoadingRun: campaignReviewInstructionRun,
        },
        actions: {
            setFinalApproverSelectionType: (type: string) => {
                setFinalApproverSelectionType(type as ("email" | "hr"));
                setForm({...form, finalApprover: {email: '', publicId: null, firstName: null, lastName: null}});
            },
            handleChangeFinalApprover,

            handleGoBack,
            handleSubmit, //save
            isOk,
            handleDeleteEvidence,
            handleOpenAddEvidence,
            handleSelectEvidence,

            handleAddApp,
            handleDeleteApp,
            handleSetScheduleType,
            handleSetStartTrigger,
            handleSetFinalApprover,

            handleEditCampaign: () => {
                setIsEditMode(true);
                handleClose();
            },
            handleDeleteCampaign: () => {
                dispatch(openDeleteInstructionDialog());
                handleClose();
            },
            handleStartCampaign: () => {
                if(exact && exact.lastRunStatus === IN_PROGRESS_STATUS){
                    dispatch(openRunReviewWarning());
                }else if(exact){
                    dispatch(RunCampaignReviewInstruction({data: {id: exact.id, onSuccess: onSuccessRun}}));
                }
                handleClose();
            },
            handleToggleActivity: () => {
                exact && dispatch(ToggleCampaignReviewInstructionStatus({data: {id: exact.id, active: !exact.active}}));
                handleClose();
            },
            handleCancelEdit: () => {
                if(exact){
                    const translateText1 = translateText(exact.trigger.repeat);
                    setScheduleSelect({value: translateText1.timeValue, num: translateText1.factor});
                    const etaConfig = getEtaConfigByInstruction(exact.etaConfig);
                    setForm({...exact, etaConfig});
                    setIsEditMode(false);
                }
            }
        },
        finalApproverSelectionType,
        form,
        handleChange,
        handleChangeEtaConfig,
        _expands,
        toggleExpand,
        translatedRepeat: scheduleSelect,
        handleSelectRepeatNum,
        handleSelectRepeatValue,
        //
        isEditMode,
        instruction: exact,
        //
        menu: {
            anchorEl,
            handleClose,
            handleClick,
            open,
        },
        nextStart: exact ? calculateNextStart(exact) : null,
        isCommonExecutionTimeIsBiggerThanRepeat: isCommonExecutionTimeIsBiggerThanRepeat(),
        employees: empls,
        isLoadingEmployees: getEmployees,
    }
}
