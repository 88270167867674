import {useRegistriesList} from "../../../hooks/useRegistriesList";
import {Flex} from "../../../../../../newShared/components/Layouts";
import React from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";
import {GenericTable} from "../../../../../../newShared/components/genericTable";
import {TRiskRegistry} from "../../../types";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";


export const RegistriesList = () => {
    const {
        handleGoToRegistry,
        handleGoToCreateRegistry,
        registries,
        models,
        genericTable
    } = useRegistriesList();

    const {t} = useMainTranslation('', {keyPrefix: 'risks'});
    const {isMobile} = useMedia();


    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('Risk registries')}
                actions={[
                    {
                        title: t('Create new registry'),
                        onClick: handleGoToCreateRegistry,
                        size: 'small'
                    }
                ]}
            />

            <GenericTable<TRiskRegistry>
                id={'RegistriesList'}
                columnsConfig={{totalName: 'Total registries', disableColumnsSettings: true, forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: registries,
                    customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id,
                    onRowClick: handleGoToRegistry,
                    columnsConfigs: [
                        {key: "name", name: t('Title'), default: true},
                        {key: 'totalRisks', name: t('Risks'), default: true},
                        {key: 'lastUpdate', name: t('Last update'), default: true, valueGetter: (row) => parseDateAuto(row.lastUpdate.date)},
                        {key: 'model', name: t('Model'), default: true, valueGetter: (row) => models.find(e => e.id === row.model)?.name},
                    ],
                }}
                filtersConfig={{
                    searchCustomFilterKeyName: 'name',
                    searchAsArray: true,
                    dontSendSearchIfEmpty: true,
                    genericFilterProps: {
                        configs: [],
                        isAddFilters: false,
                        fetchResultWithSelectedFilters: () => null
                    }
                }}
                emptyArrayImageProps={{type: 'risks', filterNotApplied: {secondaryText: 'You have not added any registries yet.', text: 'No registries found', onClick: handleGoToCreateRegistry}}}
            />

            {/*<S.FilterContainer>*/}
            {/*    <CustomSearchField*/}
            {/*        variant={'outlined'}*/}
            {/*        onChange={handleChange}*/}
            {/*        value={search}*/}
            {/*        onClear={handleCleanSearch}*/}
            {/*        disabled={isLoading}*/}
            {/*        onKeyDown={handleEnterKey}*/}
            {/*        size="small"*/}
            {/*        fullWidth*/}
            {/*        name={'search'}*/}
            {/*        label={t('Search')}*/}
            {/*        dir={'ltr'}*/}
            {/*        placeholder={t('Search')}*/}
            {/*    />*/}
            {/*</S.FilterContainer>*/}
            {/*{!(!isLoading && !registries.length && !form.prevSearch.length) && <RegistriesTableHeader />}*/}

            {/*{!isLoading && !registries.length && form.prevSearch.length > 0 && <Typography color={colors.darkBlueText} sx={{margin: '8px 0 0 0'}}>{t('Nothing was found for your query. Try changing the query.')}</Typography>}*/}
            {/*/!*{!isLoading && !registries.length && !search.length && <Typography color={colors.darkBlueText} sx={{margin: '8px 0 0 0'}}>{t('Risk registries will be displayed here.')}</Typography>}*!/*/}
            {/*<S.MainTableScrollWrapper>*/}
            {/*    <InfiniteScroll*/}
            {/*        pageStart={0}*/}
            {/*        loadMore={(page) => handleLoadMore(page)}*/}
            {/*        hasMore={hasMore}*/}
            {/*        initialLoad={false}*/}
            {/*        threshold={50}*/}
            {/*        useWindow={false}*/}
            {/*    >*/}
            {/*        {registries.map(e => e && <RegistryTableRow reg={e} key={e.id} onClick={handleGoToRegistry} models={models} isLoading={isLoading}/>)}*/}
            {/*        {isLoading && <TableRowSkeleton columns={4} />}*/}
            {/*    </InfiniteScroll>*/}
            {/*</S.MainTableScrollWrapper>*/}

            {/*<EmptyArrayImage*/}
            {/*    type={'risks'}*/}
            {/*    isShowing={!isLoading && !registries.length && !form.prevSearch.length}*/}
            {/*    text={'You have not added any registries yet.'}*/}
            {/*    onClick={handleGoToCreateRegistry}*/}
            {/*/>*/}
        </Flex>
    )
}