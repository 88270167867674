import React, {ComponentType} from 'react';
import 'reactflow/dist/style.css';
import {ConnectionLineComponentProps} from "@reactflow/core/dist/esm/types/edges";
import {getBezierPath} from "reactflow";

export const EmployeeTreeConnectionLine: ComponentType<ConnectionLineComponentProps>= (props) => {

    const {
        fromX,
        fromY,
        fromPosition,
        toX,
        toY,
        toPosition,
    } = props;

    const [edgePath] = getBezierPath({
        sourceX: fromX,
        sourceY: fromY,
        sourcePosition: fromPosition,
        targetX: toX,
        targetY: toY,
        targetPosition: toPosition,
    });

    return (
        <g>
            <path
                fill="none"
                stroke="#222"
                strokeWidth={1.5}
                className="animated"
                d={edgePath}
            />
            <circle cx={toX} cy={toY} fill={"#fff"} r={3} stroke="#222" strokeWidth={1.5} />
        </g>
    );
};
