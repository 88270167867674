//root
import {createSelector, createSlice} from "@reduxjs/toolkit";

//types
import {AppState} from "../../../../newShared/redux/rootReducer";
import {SetDeleteVendorDialog, SettingsTypes, Vendor, VendorsInitialState} from "../types";

//actions
import {
    addVendorFileAction,
    createVendorAction,
    createVendorsSettingsRowAction,
    deleteVendorAction,
    DeleteVendorById,
    deleteVendorFileAction,
    deleteVendorsSettingsRowAction,
    downloadVendorFileAction,
    editVendorsSettingsRowAction,
    exportCsvVendorsAction,
    GetCsvFieldsVendorsAction,
    GetVendorById,
    getVendorsAction,
    GetVendorsAvailableFieldsForExport,
    getVendorsSettingsAction,
    PerformCsvVendorsAction,
    releaseVendorsAction,
    updateVendorAction
} from "./actions";
import {NameCountModel} from "../../../../newShared/GQLTypes";
import {minMaxLoadedPageDefault} from "../../../../newShared/components/genericTable/constants";
import {minMaxLoadedPageType} from "../../../../newShared/components/genericTable/types";
import {handlePagination} from "../../../../newShared/components/genericTable/helpers";

import {
    addImportCsvActionsToBuilder,
    initialImportCsvDialogState,
    setImportCsvDialog
} from "../../../../newShared/components/csvImportDialog/constants";

export const initialState: VendorsInitialState = {
    vendors: [],
    minMaxLoadedPage: minMaxLoadedPageDefault,
    pageInfo: {
        count: 10,
        page: 0,
        sort: "name",
        total: 0
    },

    selectedVendor: null,

    loadings: {
        isSettingsLoading: false,
        isLoadingAddVendor: false,
        isLoadingUpdateVendor: false,
        isLoadingCSVExport: false,
        isLoadingFields: false,
        exact: false,
        deleteVendor: false,
    },

    settings: {
        type: [],
        risk: [],
        serviceProvided: [],
        customFields: []
    },

    isFileDeleting: false,
    isFileDownloading: false,
    isLoading: false,
    isRejected: false,

    historyPageInfo: {
        page: 0,
        count: 5,
        total: 0,
        sort: ''
    },

    dialogs: {

        deleteVendor: {
            isOpen: false,
            isLoading: false,
        },

        editSettingRow: {
            isOpen: false,
            isLoading: false,
            type: null,
            itemToEdit: null,
        },
        deleteSettingRow: {
            isOpen: false,
            isLoading: false,
            type: null,
            itemToDelete: null,
        },
       createSettingRow: {
            isOpen: false,
            isLoading: false,
            type: null,
        },

        release: {
            isOpen: false,
            isLoading: false,
            isLoadingDataForRelease: false,
        },

        addFileDialog: {
            isOpen: false,
            isLoading: false,
        },
        exportCsv: {
            isOpen: false,
            fields: [],
        },

        ...initialImportCsvDialogState,

    },

}

const slice = createSlice({
    name: 'Vendors',
    initialState,
    reducers: {
        replaceMinMaxPage(slice, {payload}: {payload: minMaxLoadedPageType}){
            slice.minMaxLoadedPage = payload;
        },
        cleanUpAction(slice: VendorsInitialState) {
            slice.vendors = initialState.vendors;
            slice.selectedVendor = initialState.selectedVendor;
            slice.pageInfo = initialState.pageInfo;
            slice.dialogs = initialState.dialogs;
        },
        cleanVendors: (slice) => {
            slice.vendors = [];
            slice.pageInfo = initialState.pageInfo;
        },

        setSelectedVendorAction(slice: VendorsInitialState, {payload}: {payload: Vendor | null}) {
            slice.selectedVendor = payload
        },

        setDeleteVendorDialogAction(slice: VendorsInitialState, {payload}: {payload: SetDeleteVendorDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.deleteVendor.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.deleteVendor.isLoading = payload.isLoading;
            if (payload.clear) slice.dialogs.deleteVendor = initialState.dialogs.deleteVendor;
        },

        openAddFileDialog: (slice: VendorsInitialState) => {slice.dialogs.addFileDialog.isOpen = true},
        hideAddFileDialog: (slice: VendorsInitialState) => {slice.dialogs.addFileDialog = initialState.dialogs.addFileDialog},

        openExportCsv: (slice) => {
            slice.dialogs.exportCsv.isOpen = true;
        },
        hideExportCsv: (slice) => {
            slice.dialogs.exportCsv = initialState.dialogs.exportCsv;
        },

        //openImportCsv: (slice: VendorsInitialState) => {slice.dialogs.importCSVDialog.isOpen = true},
        //hideImportCsv: (slice: VendorsInitialState) => {slice.dialogs.importCSVDialog.isOpen = false},

        openReleaseDialog: (slice: VendorsInitialState) => {slice.dialogs.release.isOpen = true},
        hideReleaseDialog: (slice: VendorsInitialState) => {slice.dialogs.release.isOpen = false},

        openDeleteDialog: (slice: VendorsInitialState) => {slice.dialogs.deleteVendor.isOpen = true},
        hideDeleteDialog: (slice: VendorsInitialState) => {slice.dialogs.deleteVendor.isOpen = false},


        openEditSettingRowDialog: (slice: VendorsInitialState, {payload}: {payload: {type:SettingsTypes, itemToEdit:NameCountModel | null}}) => {slice.dialogs.editSettingRow = {
            isOpen: true,
            type: payload.type,
            itemToEdit: payload.itemToEdit,
        }},

        hideEditSettingRowDialog: (slice: VendorsInitialState) => {slice.dialogs.editSettingRow = initialState.dialogs.editSettingRow},

        openDeleteSettingRowDialog: (slice: VendorsInitialState, {payload}: {payload: {type:SettingsTypes, itemToDelete:NameCountModel | null}}) => {slice.dialogs.deleteSettingRow = {
            isOpen: true,
            type: payload.type,
            itemToDelete: payload.itemToDelete,
        }},

        hideDeleteSettingRowDialog: (slice: VendorsInitialState) => {slice.dialogs.deleteSettingRow = initialState.dialogs.deleteSettingRow},

        openCreateSettingRowDialog: (slice: VendorsInitialState, {payload}: {payload: {type:SettingsTypes}}) => {slice.dialogs.createSettingRow = {
            isOpen: true,
            type: payload.type,
        }},

        hideCreateSettingRowDialog: (slice: VendorsInitialState) => {slice.dialogs.createSettingRow = initialState.dialogs.createSettingRow},
        setImportCsvDialogAction: setImportCsvDialog,
    },
    extraReducers: (builder) => {
        addImportCsvActionsToBuilder<VendorsInitialState>(builder, GetCsvFieldsVendorsAction, PerformCsvVendorsAction);

        builder
            .addCase(getVendorsAction.pending, (slice: VendorsInitialState) => {
                slice.isLoading = true;
            })
            .addCase(getVendorsAction.rejected, (slice: VendorsInitialState) => {
                slice.isLoading = false;
                slice.isRejected = true;
            })
            .addCase(getVendorsAction.fulfilled, (slice: VendorsInitialState, { payload }) => {
                slice.isLoading = false;
                // if (payload.pageInfo.page === 0) {
                //     slice.vendors = payload.vendors;
                // } else {
                //     if (slice.pageInfo.page < payload.pageInfo.page) {
                //         slice.vendors = [...slice.vendors, ...payload.vendors];
                //     } else {
                //         slice.vendors = [...payload.vendors, ...slice.vendors];
                //     }
                // }
                // slice.pageInfo = payload.pageInfo;
                const {results, maxLoadedPage, minLoadedPage} = handlePagination<Vendor>(
                    slice.vendors,
                    slice.pageInfo,
                    payload.vendors,
                    payload.pageInfo,
                    slice.minMaxLoadedPage.minLoadedPage,
                    slice.minMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.vendors = results;
                slice.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.pageInfo = payload.pageInfo;
            })

            .addCase(createVendorAction.pending, (slice: VendorsInitialState) => {
                slice.loadings.isLoadingAddVendor = true;
            })
            .addCase(createVendorAction.rejected, (slice: VendorsInitialState) => {
                slice.loadings.isLoadingAddVendor = false;
            })
            .addCase(createVendorAction.fulfilled, (slice: VendorsInitialState, { payload: {resp} }) => {
                slice.loadings.isLoadingAddVendor = false;
                slice.selectedVendor = resp;
                //slice.loadings.isLoadingAddVendor = false; <- !!!
            })

            .addCase(updateVendorAction.pending, (slice: VendorsInitialState) => {
                slice.loadings.isLoadingUpdateVendor = true;
            })
            .addCase(updateVendorAction.rejected, (slice: VendorsInitialState) => {
                slice.loadings.isLoadingUpdateVendor = false;
            })
            .addCase(updateVendorAction.fulfilled, (slice: VendorsInitialState, { payload: {resp} }) => {
                slice.loadings.isLoadingUpdateVendor = false;

                const tmp = slice.vendors;
                const index = tmp.findIndex(e => e.id === resp.id);
                if (index > -1) {
                    tmp[index] = resp;
                    slice.vendors = tmp;
                }

                slice.selectedVendor = resp;
            })

            .addCase(deleteVendorAction.pending, (slice: VendorsInitialState) => {
                slice.dialogs.deleteVendor.isLoading = true;
            })
            .addCase(deleteVendorAction.rejected, (slice: VendorsInitialState) => {
                slice.dialogs.deleteVendor.isLoading = false;
            })
            .addCase(deleteVendorAction.fulfilled, (slice: VendorsInitialState, { payload: {data} }) => {
                slice.vendors = slice.vendors.filter(e => e.id !== data.employeeId);

                slice.selectedVendor = null;
                slice.dialogs.deleteVendor = initialState.dialogs.deleteVendor;
            })

            /// SETTINGS
            .addCase(getVendorsSettingsAction.pending, (slice: VendorsInitialState) => {
                slice.loadings.isSettingsLoading = true;
            })
            .addCase(getVendorsSettingsAction.rejected, (slice: VendorsInitialState) => {
                slice.loadings.isSettingsLoading = false;
                slice.isRejected = true;
            })
            .addCase(getVendorsSettingsAction.fulfilled, (slice: VendorsInitialState, { payload: {resp} }) => {
                slice.loadings.isSettingsLoading = false
                slice.settings = resp;
            })

            .addCase(createVendorsSettingsRowAction.pending, (slice: VendorsInitialState) => {
                slice.dialogs.createSettingRow.isLoading = true;
            })
            .addCase(createVendorsSettingsRowAction.rejected, (slice: VendorsInitialState) => {
                slice.dialogs.createSettingRow.isLoading = false;
            })
            .addCase(createVendorsSettingsRowAction.fulfilled, (slice: VendorsInitialState, { payload: { resp} }) => {
                slice.settings = resp;
                slice.dialogs.createSettingRow = initialState.dialogs.createSettingRow;
            })

            .addCase(editVendorsSettingsRowAction.pending, (slice: VendorsInitialState) => {
                slice.dialogs.editSettingRow.isLoading = true;
            })
            .addCase(editVendorsSettingsRowAction.rejected, (slice: VendorsInitialState) => {
                slice.dialogs.editSettingRow.isLoading = false;
            })
            .addCase(editVendorsSettingsRowAction.fulfilled, (slice: VendorsInitialState, { payload: { resp} }) => {
                slice.settings = resp;
                slice.dialogs.editSettingRow = initialState.dialogs.editSettingRow;
            })

            .addCase(deleteVendorsSettingsRowAction.pending, (slice: VendorsInitialState) => {
                slice.dialogs.deleteSettingRow.isLoading = true;
            })
            .addCase(deleteVendorsSettingsRowAction.rejected, (slice: VendorsInitialState) => {
                slice.dialogs.deleteSettingRow.isLoading = false;
            })
            .addCase(deleteVendorsSettingsRowAction.fulfilled, (slice: VendorsInitialState, { payload: {resp} }) => {
                slice.settings = resp;
                slice.dialogs.deleteSettingRow = initialState.dialogs.deleteSettingRow;
                })


            //CSV
            .addCase(exportCsvVendorsAction.pending, (slice: VendorsInitialState) => {
                slice.loadings.isLoadingCSVExport = true;
            })
            .addCase(exportCsvVendorsAction.rejected, (slice: VendorsInitialState) => {
                slice.loadings.isLoadingCSVExport = false;
            })
            .addCase(exportCsvVendorsAction.fulfilled, (slice: VendorsInitialState) => {
                slice.loadings.isLoadingCSVExport = false;
                slice.dialogs.exportCsv = initialState.dialogs.exportCsv;
            })


            //documents
            .addCase(addVendorFileAction.pending, (slice: VendorsInitialState) => {
                slice.dialogs.addFileDialog.isLoading = true;
            })
            .addCase(addVendorFileAction.rejected, (slice: VendorsInitialState) => {
                slice.dialogs.addFileDialog.isLoading = false;
            })
            .addCase(addVendorFileAction.fulfilled, (slice: VendorsInitialState, {payload}) => {
                slice.dialogs.addFileDialog.isLoading = false;
                slice.selectedVendor?.documents.push(payload.resp);
                const index = slice.vendors?.findIndex(e => e.id === payload.data.vendorId) || -1
                if (index > -1) {
                    const tmp = slice.vendors;
                    tmp[index].documents.push(payload.resp);
                    slice.vendors = tmp;
                }
            })

            .addCase(deleteVendorFileAction.pending, (slice: VendorsInitialState) => {
                slice.isFileDeleting = true;
            })
            .addCase(deleteVendorFileAction.rejected, (slice: VendorsInitialState) => {
                slice.isFileDeleting = false;
            })
            .addCase(deleteVendorFileAction.fulfilled, (slice: VendorsInitialState, {payload}) => {
                slice.isFileDeleting = false;
                if (slice.selectedVendor) {
                    slice.selectedVendor.documents = slice.selectedVendor.documents.filter(e => e.id === payload.data.fileId);
                }

                const index = slice.vendors?.findIndex(e => e.id === payload.data.vendorId) || -1
                if (index > -1) {
                    const tmp = slice.vendors;
                    tmp[index].documents = tmp[index].documents.filter(e => e.id === payload.data.fileId);
                    slice.vendors = tmp;
                }
            })

            .addCase(downloadVendorFileAction.pending, (slice: VendorsInitialState) => {
                slice.isFileDownloading = true;
            })
            .addCase(downloadVendorFileAction.rejected, (slice: VendorsInitialState) => {
                slice.isFileDownloading = false;
            })
            .addCase(downloadVendorFileAction.fulfilled, (slice: VendorsInitialState) => {
                slice.isFileDownloading = false;
            })

            .addCase(releaseVendorsAction.pending, (slice: VendorsInitialState) => {
                slice.dialogs.release.isLoading = true;
            })
            .addCase(releaseVendorsAction.rejected, (slice: VendorsInitialState) => {
                slice.dialogs.release.isLoading = false;
            })
            .addCase(releaseVendorsAction.fulfilled, (slice: VendorsInitialState) => {
                slice.dialogs.release = initialState.dialogs.release;
            })
        //GetVendorById
            .addCase(GetVendorById.pending, (slice: VendorsInitialState) => {
                slice.loadings.exact = true;
            })
            .addCase(GetVendorById.rejected, (slice: VendorsInitialState) => {
                slice.loadings.exact = false;
            })
            .addCase(GetVendorById.fulfilled, (slice: VendorsInitialState, {payload}) => {
                slice.loadings.exact = false;
                slice.selectedVendor = payload;
            })
        //DeleteVendorById
            .addCase(DeleteVendorById.pending, (slice: VendorsInitialState) => {
                slice.loadings.deleteVendor = true;
            })
            .addCase(DeleteVendorById.rejected, (slice: VendorsInitialState) => {
                slice.loadings.deleteVendor = false;
            })
            .addCase(DeleteVendorById.fulfilled, (slice: VendorsInitialState, {payload}) => {
                slice.loadings.deleteVendor = false;
                slice.dialogs.deleteVendor.isOpen = false;
                slice.selectedVendor = null;
                slice.vendors = slice.vendors.filter(e => e.id !== payload);
            })

            //AvailableFieldsForExport
            .addCase(GetVendorsAvailableFieldsForExport.pending, (slice) => {
                slice.loadings.isLoadingFields = true;
            })
            .addCase(GetVendorsAvailableFieldsForExport.rejected, (slice) => {
                slice.loadings.isLoadingFields = false;

            })
            .addCase(GetVendorsAvailableFieldsForExport.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingFields = false;
                slice.dialogs.exportCsv.fields = payload.fields;
            })

    }
});

//Reducer
export const VendorsReducer = slice.reducer;

//actions
export const {
    cleanUpAction,
    setSelectedVendorAction,

    openReleaseDialog,
    hideReleaseDialog,

    openAddFileDialog,
    hideAddFileDialog,
    setImportCsvDialogAction,

    openEditSettingRowDialog,
    hideEditSettingRowDialog,
    openDeleteSettingRowDialog,
    hideDeleteSettingRowDialog,
    openCreateSettingRowDialog,
    hideCreateSettingRowDialog,

    openDeleteDialog,
    hideDeleteDialog,
    replaceMinMaxPage,
    openExportCsv,
    hideExportCsv,
    cleanVendors

} = slice.actions;

//selectors
const selectSelf = (state: AppState): VendorsInitialState => state.vendors as VendorsInitialState;
export const vendorsDialogsSelector = createSelector(selectSelf, state => state.dialogs);
export const dialogs = createSelector(selectSelf, state => state.dialogs);

export const isLoadingSelector = createSelector(selectSelf, state => state.isLoading);
export const loadingsSelector = createSelector(selectSelf, state => state.loadings);

export const isLoadingUpdateVendor = createSelector(selectSelf, state => state.loadings.isLoadingUpdateVendor);
export const isSettingsLoadingSelector = createSelector(selectSelf, state => state.loadings.isSettingsLoading);
export const isLoadingAddVendorSelector = createSelector(selectSelf, state => state.loadings.isLoadingAddVendor);
export const isLoadingCSVExportSelector = createSelector(selectSelf, state => state.loadings.isLoadingCSVExport);
export const isLoadingExact = createSelector(selectSelf, state => state.loadings.exact);
export const isLoadingDelete = createSelector(selectSelf, state => state.loadings.deleteVendor);

export const isFileDownloadingSelector = createSelector(selectSelf, state => state.isFileDownloading);
export const isFileDeletingSelector = createSelector(selectSelf, state => state.isFileDeleting);

// export const isRejectedSelector = createSelector(selectSelf, state => state.isRejected);
export const historyPageInfo = createSelector(selectSelf, state => state.historyPageInfo);



export const vendorsSettingsSelector = createSelector(selectSelf, state => state.settings);
export const selectedVendorSelector = createSelector(selectSelf, state => state.selectedVendor);
export const vendorsSelector = createSelector(selectSelf, state => state.vendors);
export const pageInfoSelector = createSelector(selectSelf, state => state.pageInfo);
export const minMaxLoadedPage = createSelector(selectSelf, state => state.minMaxLoadedPage);





