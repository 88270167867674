import {useDispatch, useSelector} from "react-redux";
import {
    cleanRegistries,
    deselectedRegistry,
    loadings,
    models,
    registries,
    registriesMinMaxLoadedPage,
    registriesPageInfo,
    replaceRegistriesMinMaxLoadedPage,
    setSelectedRegistry
} from "../../store/slice";
import {TRiskRegistry} from "../../types";
import {useHistory} from "react-router-dom";
import {
    PATH_RISK_MANAGEMENT_RISK_REGISTRIES_ALL,
    PATH_RISK_MANAGEMENT_RISK_REGISTRIES_EXACT,
    PATH_RISK_MANAGEMENT_RISK_REGISTRIES_NEW
} from "../../constants";
import {useEffect} from "react";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_RISK_MANAGEMENT,} from "../../../../../newShared/constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {getRiskRegistersAction} from "../../store/actions";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";

export const useRegistriesList = () => {
    const history = useHistory();
    const dispatch= useDispatch();
    const {registriesList} = useSelector(loadings);
    const regs = useSelector(registries);
    const _pageInfo = useSelector(registriesPageInfo);
    const _models = useSelector(models);


    const handleGoToRegistry = (registry: TRiskRegistry) => {
        dispatch(setSelectedRegistry(registry));
        history.push(PATH_RISK_MANAGEMENT_RISK_REGISTRIES_EXACT.replace(':id', registry.id));
    }

    const handleGoToCreateRegistry = () => {
        history.push(PATH_RISK_MANAGEMENT_RISK_REGISTRIES_NEW);
    }

    useEffect(() => {
        dispatch(deselectedRegistry());
        // getData(0, REGISTRIES_DEFAULT_COUNT, '');

        return () => {
        }
        //eslint-disable-next-line
    }, []);

    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    useSetBreadcrumbs([
        {
            title: tMenu('Risk Management'),
            path: PATH_LOCAL_RISK_MANAGEMENT
        },
        {
            title: tMenu('Risk registries'),
            path: PATH_RISK_MANAGEMENT_RISK_REGISTRIES_ALL
        }
    ]);

    //search
    // const {form, handleChange, setForm} = useForm<{search: string, prevSearch: string}>({search: '', prevSearch: ''});

    // const handleCleanSearch = () => {
    //     setForm({search: '', prevSearch: ''});
    //     dispatch(eraseRegistries()); //erasing because of loader positioned on bottom
    //     getData(0, REGISTRIES_DEFAULT_COUNT, '');
    // }
    //
    // const handleEnterKey = (e: any) => {
    //     if(e.keyCode === 13 && !form.search.trim().length){
    //         setForm({search: '', prevSearch: ''});
    //         dispatch(eraseRegistries()); //erasing because of loader positioned on bottom
    //         getData(0, REGISTRIES_DEFAULT_COUNT, '');
    //     }
    //     if(e.keyCode === 13 && form.search.trim().length > 0){
    //         setForm({...form, prevSearch: form.search});
    //         dispatch(eraseRegistries()); //erasing because of loader positioned on bottom
    //         getData(0, DEFAULT_COUNT, form.search.trim());
    //     }
    // }
    //
    // const handleLoadMore = (page: number) => {
    //     !registriesList && getData(_pageInfo.page + 1, DEFAULT_COUNT, form.search);
    // }

    const {minLoadedPage, maxLoadedPage} = useSelector(registriesMinMaxLoadedPage);

    return{
        isLoading: registriesList,
        handleGoToRegistry,
        handleGoToCreateRegistry,
        registries: regs,
        // search: form.search,
        // handleChange,
        // handleCleanSearch,
        // handleEnterKey,
        hasMore: _pageInfo.total > ((_pageInfo.page + 1) * _pageInfo.count),
        // handleLoadMore,
        models: _models,
        // form,
        genericTable: {
            paging: {
                pageInfo: _pageInfo,
                isLoading: registriesList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceRegistriesMinMaxLoadedPage(data)),
                clearRows: () => dispatch(cleanRegistries()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    dispatch(getRiskRegistersAction({data: {
                            page,
                            count,
                            filters
                        }}));
                },
            },
        }
    }
}