import {IconButton, styled} from "@mui/material";

interface LinkIconButtonProps {
    active?: boolean;
}

export const LinkIconButton = styled(IconButton, {shouldForwardProp: (propName) => (
        !['type'].includes(propName.toString())
    )})<LinkIconButtonProps>`
  border-radius: 2px !important;
  background-color: ${props => props.active ? 'rgba(0, 0, 0, 0.1) !important' : 'transparent'};
  margin-right: 5px !important;
`;
