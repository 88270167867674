import {Button} from '@mui/material';
import styled from 'styled-components';


type TButtonProps = {
    variant?: 'outlined' | 'contained',
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' 
            | 'lightPrimary' | 'lightError' | 'lightInfo' | 'lightSuccess' | 'lightSecondary' | 'accent' | 'accentDark',
    disabled?: boolean,
    size?: 'small' | 'medium' | 'large',
    // active?: boolean,
    component?: 'span' | 'button' | 'label',
    revDir?: boolean,
    fontColor?: string,
}

export const CustomButton = styled(Button)<TButtonProps>`
    letter-spacing: 1.25px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500; 
    ${({size}) => size !== "small" ? 'min-width: 100px !important;' : ''}
    &.MuiButton-contained {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    }
    &.MuiButton-contained:hover {
       box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
    };
    &.MuiButton-containedPrimary.Mui-disabled {
        background-color: rgba(158, 158, 158, 1);
        color: #FFFFFF80;
    };
    &.MuiButton-containedSizeSmall {
        text-transform: none;
        height: 24px;
    };
    & span.MuiButton-startIcon {
        margin: 0 5px !important;
    }
`
