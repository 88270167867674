import * as S from '../../../components/styled/index';
import {useExactTarget} from "../../../hooks/clients/useExactTarget";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";
import {
    Box,
    Button,
    Menu,
    MenuItem,
    Skeleton,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tabs,
    Typography
} from "@mui/material";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import DvrIcon from '@mui/icons-material/Dvr';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import ArchiveIcon from '@mui/icons-material/Archive';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {exactTargetTabType, TScreeningStatus} from "../../../types";
import React from "react";
import TabContext from "@mui/lab/TabContext/TabContext";
import {TabPanel} from "@mui/lab";
import {COMPLETE_STATUS, FILE_FIELD_TYPE, INDIVIDUAL_CLIENTS_PAGE_TYPE} from "../../../constants";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {
    ExactRequestContainer,
    ExactRequestContainerTitle,
    FieldTitleText,
    FieldValueText
} from "../../requests/exact/styled";
import colors from "../../../../../../newShared/theme/colors";
import {FileBlock} from "../../requests/exact";
import {DeleteTargetExceptionDialog} from "../../../components/target/deleteTargetExceptionDialog";
import {AddToBlackListDialog} from "../../../components/dialogs/addToBlackList";
import {ArchiveClientDialog} from "../../../components/dialogs/archiveClient";
import RestoreIcon from '@mui/icons-material/Restore';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import {RestoreClientFromArchiveDialog} from "../../../components/dialogs/restoreFromArchive";
import {RestoreClientFromBlackListDialog} from "../../../components/dialogs/restoreFromBlackList";
import {
    getFieldValue,
    getScreeningStatus,
    getStatusTitleAndColor,
    getTargetRisk,
    getTargetTypeForPath
} from "../../../helpers";
import {TargetHistoryTable} from "../../../components/target/targetHistoryTable";
import {UpdateClientDataDialog} from "../../../components/dialogs/updateClientDataDialog";
import {ShowFullReportDialog} from "../../../components/dialogs/showFullReportDialog";
import {EmptyArrayImage} from "../../../../../../newShared/components/EmptyArrayImage";
import {FlexBox} from "../../../../../../newShared/components/editorUnion/components/dialogs/manageActorsDialog/styled";
import {
    OverflowHiddenTextBodySmall,
    TypographyBody1,
    TypographyBody2
} from "../../../../../../newShared/components/Inputs/styled";
import {CommonChips} from '../../../../../../newShared/components/CommonChips';
import {SelectRisk} from "../style";
import {SpanLink} from "../../screening/exact/style";
import {KycExceptionModel, KycScreeningModel} from "../../../../../../newShared/GQLTypes";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {GenericTable} from "../../../../../../newShared/components/genericTable";
import {LightTooltip} from "../../../../../../newShared/components/editorUnion/components/editorSideMenu/styled";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const ExactTarget = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.clients'});
    const {
        target,
        targetName,
        actions,
        isLoading,
        menu,
        tab,
        setTab,
        isBlacklisted,
        isArchived,
        otherLoadings,
        filterConfig,
        isNoHistory,
        updateRisk,
        riskVariants,
        isDataOutdated,
        handleReloadData,
        dicts,
        handleGoToExactTemplate,
        genericTableScreenings,
        genericTableResolutions
    } = useExactTarget();
    const {isMobile} = useMedia();

    return(
        <S.PageContainer>
            <PageTitleAndActions
                title={targetName}
                handleGoBack={actions.handleGoBack}
                showDataHasBeenUpdated={(isDataOutdated.exactTargetExceptionsTab && tab === 'resolutions') ||
                    (isDataOutdated.exactTargetScreeningTab && tab === 'screening') || isDataOutdated.exactTargetEditMode}
                onReloadData={handleReloadData}
                isChildrenOnRight
                showMenuWhen={isMobile}
                firstElement={<CommonChips settings={getStatusTitleAndColor(target?.status)}/>}
                actions={[
                    {
                        title: t('Start screening'),
                        variant: 'contained',
                        size: 'small',
                        onClick: actions.handleStartScreening,
                        dontShowWhen: isMobile,
                        loading: otherLoadings.startTargetScreening
                    },
                    {
                        title: !isBlacklisted ? t('Add to black list') : t('Remove from black list'),
                        // disabled: otherLoadings.moveToBlacklist || otherLoadings.moveFromBlacklist,
                        variant: 'contained',
                        size: 'small',
                        onClick: !isBlacklisted ? actions.handleAddToBlackList : actions.handleRestoreFromBlackList,
                        dontShowWhen: isMobile,
                        loading: otherLoadings.moveToBlacklist || otherLoadings.moveFromBlacklist
                    },
                    {
                        title: t('Start screening'),
                        variant: 'contained',
                        size: 'small',
                        icon: <DvrIcon />,
                        onClick: actions.handleStartScreening,
                        dontShowWhen: !isMobile,
                        loading: otherLoadings.startTargetScreening
                    },
                    {
                        title: t('Edit client info'),
                        variant: 'contained',
                        size: 'small',
                        icon: <EditIcon />,
                        onClick: actions.handleEditInfo,
                        dontShowWhen: !isMobile,
                    },
                    {
                        title: !isBlacklisted ? t('Add to black list') : t('Remove from black list'),
                        variant: 'contained',
                        size: 'small',
                        icon: !isBlacklisted ? <RestoreIcon/> : <BlockIcon />,
                        onClick: !isBlacklisted ? actions.handleAddToBlackList : actions.handleRestoreFromBlackList,
                        dontShowWhen: !isMobile,
                        loading: otherLoadings.moveToBlacklist || otherLoadings.moveFromBlacklist
                    },
                    {
                        title: !isArchived ? t('Archive client') : t('Restore from archive'),
                        variant: 'contained',
                        size: 'small',
                        icon: !isArchived ? <ArchiveIcon /> : <UnarchiveIcon />,
                        onClick: !isArchived ? actions.handleArchiveClient : actions.handleUnArchive,
                        dontShowWhen: !isMobile,
                        loading: otherLoadings.moveToArchive || otherLoadings.moveFromArchive
                    },
                ]}
            >
                {!isMobile &&
                    <div>
                        <Button
                            size={'small'}
                            id="basic-button"
                            aria-controls={menu.open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={menu.open ? 'true' : undefined}
                            onClick={menu.handleClick}
                            endIcon={menu.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            variant={'text'}
                        >
                            {t('Other actions')}
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={menu.anchorEl}
                            open={menu.open}
                            onClose={menu.handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={actions.handleEditInfo}>
                                <ListItemIcon>
                                    <EditIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>{t('Edit client info')}</ListItemText>
                            </MenuItem>

                            {/*<MenuItem*/}
                            {/*    onClick={!isBlacklisted ? actions.handleAddToBlackList : actions.handleRestoreFromBlackList}*/}
                            {/*    disabled={otherLoadings.moveToBlacklist || otherLoadings.moveFromBlacklist}*/}
                            {/*>*/}
                            {/*    <ListItemIcon>*/}
                            {/*        {!isBlacklisted ? <RestoreIcon/> : <BlockIcon />}*/}
                            {/*    </ListItemIcon>*/}
                            {/*    <ListItemText>{!isBlacklisted ? 'Add to black list' : 'Remove from black list'}</ListItemText>*/}
                            {/*</MenuItem>*/}

                            <MenuItem
                                onClick={!isArchived ? actions.handleArchiveClient : actions.handleUnArchive}
                                disabled={otherLoadings.moveToArchive || otherLoadings.moveFromArchive}
                            >
                                <ListItemIcon>
                                    {!isArchived ? <ArchiveIcon /> : <UnarchiveIcon />}
                                </ListItemIcon>
                                <ListItemText>{!isArchived ? t('Archive client') : t('Restore from archive')}</ListItemText>
                            </MenuItem>
                        </Menu>
                    </div>
                }
            </PageTitleAndActions>

            {target ?
                <TypographyBody2 sx={{mt: '16px', color: colors.text.grey}}>
                    {`${t('Template name')}: `}
                    <SpanLink onClick={handleGoToExactTemplate}>{`${target.templateName ?? ''}`}</SpanLink>
                </TypographyBody2> : <Skeleton width={'200px'} />}

            <FlexBox sx={{mt: '16px'}}>
                <TypographyBody1>{t('Risk')}</TypographyBody1>
                <SelectRisk disabled={otherLoadings.updateTargetRisk}
                            value={target?.risk ?? 'None'}
                            onChange={(e) => updateRisk(e.target.value)}
                >
                    {riskVariants.map(risk => <MenuItem sx={{height: '32px'}} key={risk} value={risk}>{getTargetRisk(risk)}</MenuItem>)}
                </SelectRisk>
            </FlexBox>


            <TabContext value={tab}>
                <Box sx={{ margin: '16px 0 0 0'}}>
                    <Tabs variant={'scrollable'} value={tab} onChange={(e, newValue: exactTargetTabType) => {
                        // console.log('new tab', newValue);
                        setTab(newValue)
                    }}>
                        <Tab label={t("Fields")} value={'fields'}/>
                        <Tab label={t("Screening")} value={'screening'}/>
                        <Tab label={t("Resolutions")} value={'resolutions'} />
                        <Tab label={t("History")} value={'history'}/>
                    </Tabs>
                </Box>

                {tab === 'fields' &&
                    <TabPanel value={'fields'} sx={{padding: '16px 0', flexGrow: 1, overflow: 'auto', display: 'flex', flexDirection: 'column'}}>
                        <ExactRequestContainer margin={'16px 0 0 0'}>
                            <ExactRequestContainerTitle>{t('Client data')}</ExactRequestContainerTitle>

                            {isLoading && <Skeleton width={300} />}
                            <Flex ai={'center'} m={'20px 0 0 0'} gap={'32px'}>

                                {target && (
                                    <Table sx={{width: 'auto'}} size={"small"}>
                                        <TableBody>
                                            {/*<TableRow>*/}
                                            {/*    <TableCell sx={{borderBottom: 'none', paddingInlineStart: 0}}>*/}
                                            {/*        <FieldTitleText sx={{margin: '8px 0'}}>{('Status')}:</FieldTitleText>*/}
                                            {/*    </TableCell>*/}
                                            {/*    <TableCell sx={{width: '20px', borderBottom: 'none'}}/>*/}
                                            {/*    <TableCell sx={{borderBottom: 'none', paddingInlineStart: 0}}>*/}
                                            {/*        <CommonChips settings={getStatusTitleAndColor(target?.status)}/>*/}
                                            {/*    </TableCell>*/}
                                            {/*</TableRow>*/}

                                            {target.fields
                                                .filter(e => e.type !== FILE_FIELD_TYPE && e.system)
                                                .slice()
                                                .sort((a, b) => a.order - b.order)
                                                .map(e => {
                                                    return (
                                                        <TableRow key={e.id}>
                                                            <TableCell sx={{borderBottom: 'none', paddingInlineStart: 0}}>
                                                                <FieldTitleText>{e.name}:</FieldTitleText>
                                                            </TableCell>
                                                            <TableCell sx={{width: '20px', borderBottom: 'none'}}/>
                                                            <TableCell sx={{borderBottom: 'none', paddingInlineStart: 0}}>
                                                                <FieldValueText>{getFieldValue(e, dicts)}</FieldValueText>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                        </TableBody>
                                    </Table>
                                )}

                                {/*<ExactRequestTextUnionContainer>*/}
                                {/*    <FieldTitleText sx={{margin: '8px 0'}}>{('Status')}:</FieldTitleText>*/}
                                {/*    {target && target.fields*/}
                                {/*        .filter(e => e.type !== FILE_FIELD_TYPE && e.system)*/}
                                {/*        .slice()*/}
                                {/*        .sort((a, b) => a.order - b.order)*/}
                                {/*        .map(e => {*/}
                                {/*        return  <FieldTitleText>{e.name}:</FieldTitleText>*/}
                                {/*    })}*/}
                                {/*</ExactRequestTextUnionContainer>*/}

                                {/*<ExactRequestTextUnionContainer>*/}
                                {/*    {target && <CommonChips settings={getStatusTitleAndColor(target?.status)}/>}*/}
                                {/*    {target && target.fields*/}
                                {/*        .filter(e => e.type !== FILE_FIELD_TYPE && e.system)*/}
                                {/*        .slice()*/}
                                {/*        .sort((a, b) => a.order - b.order)*/}
                                {/*        .map(e => {*/}
                                {/*        return  <FieldValueText>{e.type === 'DATE' ? parseDate(e.value, currentLanguage.momentLocale) : ((e.value?.length ?? 0) > 0 ? e.value : '-')}</FieldValueText>*/}
                                {/*    })}*/}
                                {/*</ExactRequestTextUnionContainer>*/}
                            </Flex>
                        </ExactRequestContainer>

                        {target && target.fields.filter(e => e.type !== FILE_FIELD_TYPE && !e.system).length > 0 &&
                            <ExactRequestContainer margin={'16px 0'}>
                                <ExactRequestContainerTitle>{t('Additional fields')}</ExactRequestContainerTitle>

                                <Flex ai={'center'} m={'20px 0 0 0'} gap={'32px'}>

                                    <Table sx={{width: 'auto'}}>
                                        <TableBody>
                                            {target && target.fields
                                                .filter(e => e.type !== FILE_FIELD_TYPE && !e.system)
                                                .slice()
                                                .sort((a, b) => a.order - b.order)
                                                .map(e => {
                                                    return (
                                                        <TableRow key={e.id}>
                                                            <TableCell sx={{paddingInlineStart: 0}}>
                                                                <FieldTitleText>{e.name}:</FieldTitleText>
                                                            </TableCell>
                                                            <TableCell sx={{width: '20px', borderBottom: 'none'}}/>
                                                            <TableCell sx={{paddingInlineStart: 0}}>
                                                                <FieldValueText>{getFieldValue(e, dicts)}</FieldValueText>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                        </TableBody>
                                    </Table>
                                </Flex>
                            </ExactRequestContainer>
                        }

                        {target && target.fields.filter(e => e.type === FILE_FIELD_TYPE).length > 0 &&
                            <ExactRequestContainer margin={'10px 0 0 0'}>
                                <ExactRequestContainerTitle>{t('Documents')}</ExactRequestContainerTitle>

                                <Flex m={'20px 0 0 0'} gap={'16px'} direction={'column'}>
                                    {target && target.fields
                                        .filter(e => e.type === FILE_FIELD_TYPE)
                                        .slice()
                                        .sort((a, b) => a.order - b.order)
                                        .map(e => {
                                        return  <Flex ai={'center'} gap={'32px'}>
                                            <FieldTitleText>{e.name}:</FieldTitleText>
                                            {e.fileName !== null && e.fileName !== undefined ?
                                                <FileBlock fileName={e.fileName} fileId={e.value ?? ''} onDownload={actions.handleDownloadFile}/>
                                                :
                                                <Typography variant={'body1'} color={colors.text.grey_dark}>{t('No file uploaded')}</Typography>}
                                        </Flex>
                                    })}
                                </Flex>
                            </ExactRequestContainer>
                        }
                    </TabPanel>
                }

                {tab === 'screening' &&
                    <TabPanel value={'screening'} sx={{padding: '0', margin: '16px 0 0 0', flexGrow: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
                        <GenericTable<KycScreeningModel>
                            id={'ExactTarget-screening'}
                            columnsConfig={{totalName: t('Total screenings'), forceShowCardsWhen: isMobile}}
                            paging={genericTableScreenings.paging}
                            rowsConfig={{
                                rows: genericTableScreenings.screenings,
                                customCellPaddings: '8px',
                                getRowUniqueId: (row) => row.id,
                                onRowClick: () => null,
                                columnsConfigs: [
                                    {name: t('Scan date'), key: 'scanDate', default: true, valueGetter: (row) => parseDateAuto(row.startDate, true)},
                                    {name: t('Scan status'), key: 'scanStatus', default: true, valueRenderer: (row) => getScreeningStatus(row.status as TScreeningStatus)},
                                    {name: t('Report'), key: 'report', default: true, valueRenderer: (row) => {
                                        return(
                                            <>
                                                {row.status === COMPLETE_STATUS &&
                                                    <Button variant={'outlined'} size={'small'} onClick={() => genericTableScreenings.onViewFullReport(row)}>
                                                        {t('Open screening')}
                                                    </Button>
                                                }
                                            </>
                                        )
                                        }},
                                ]
                            }}
                            filtersConfig={{
                                dontSendSearchIfEmpty: true,
                                searchCustomFilterKeyName: 'nameLike',
                                searchAsArray: true,
                                genericFilterProps: {
                                    configs: filterConfig,
                                    fetchResultWithSelectedFilters: () => null,
                                    isAddFilters: false,
                                    disableSearch: true
                                }
                            }}
                            emptyArrayImageProps={{
                                type: 'kycTemplates',
                                filterNotApplied: {text: t('No screenings found.')}
                            }}
                        />

                        {/*<GenericFiltersArray*/}
                        {/*    isAddFilters={false}*/}
                        {/*    dateFilterFormat={KYC_DATE_FORMAT}*/}
                        {/*    configs={filterConfig}*/}
                        {/*    fetchResultWithSelectedFilters={applyFilter}*/}
                        {/*    disableSearch*/}
                        {/*/>*/}

                        {/*{ !isNoScreenings && <TargetScreeningTable/>}*/}

                        {/*<EmptyArrayImage*/}
                        {/*    type={'kycTemplates'}*/}
                        {/*    isShowing={isNoScreenings}*/}
                        {/*    text={('No screenings found.')}*/}
                        {/*    withoutAdd*/}
                        {/*/>*/}
                    </TabPanel>
                }


                {tab === 'resolutions' &&
                    <TabPanel value={'resolutions'} sx={{padding: '0', height: '100%', flexGrow: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
                        {target &&
                            <GenericTable<KycExceptionModel>
                                id={'ExactTarget-resolutions'}
                                columnsConfig={{totalName: t('Total resolutions'), forceShowCardsWhen: isMobile}}
                                paging={genericTableResolutions.paging}
                                rowsConfig={{
                                    rows: genericTableResolutions.resolutions,
                                    customCellPaddings: '8px',
                                    getRowUniqueId: (row) => row.id,
                                    onRowClick: () => null,
                                    columnsConfigs: [
                                        {name: getTargetTypeForPath(target).pathName === INDIVIDUAL_CLIENTS_PAGE_TYPE ? t('Person matched') : t('Organization matched'), key: 'scanDate', default: true, valueGetter: (row) => parseDateAuto(row.createdDate, true)},
                                        {name: t('Created date'), key: 'entityCaption', default: true, valueGetter: (row) => parseDateAuto(row.createdDate)},
                                        {name: t('Score'), key: 'scanStatus', default: true, valueGetter: (row) => row.score.toString().substring(0, 4)},
                                        {name: t('Resolutions'), key: 'report', default: true, valueRenderer: (row) => {
                                                return(
                                                    <LightTooltip title={row?.reason ?? ''}>
                                                        <Flex direction={'column'} maxw={'250px'}>
                                                            <Typography fontSize={'12px'} fontWeight={400} color={colors.text.grey}>{t('Resolution score limit')}: <span color={colors.grayText}>{row.score.toString().substring(0, 4) ?? '-'}</span></Typography>
                                                            <OverflowHiddenTextBodySmall color={colors.text.grey}>{t('Resolution reason')}: <span color={colors.grayText}>{(row.reason && row.reason.length > 0) ? row.reason : '-'}</span></OverflowHiddenTextBodySmall>
                                                        </Flex>
                                                    </LightTooltip>
                                                )
                                            }},
                                    ],
                                    rowActionsConfig: [
                                        {title: t('Delete resolution'), icon: <DeleteOutlineIcon />, onClick: genericTableResolutions.handleDeleteRow}
                                    ]
                                }}
                                filtersConfig={{}}
                                emptyArrayImageProps={{
                                    type: 'kycTemplates',
                                    filterNotApplied: {text: t('No resolutions found')}
                                }}
                            />
                        }

                        {/*{!isNoExceptions && <TargetExceptionsTable/> }*/}
                        {/*<EmptyArrayImage*/}
                        {/*    type={'kycTemplates'}*/}
                        {/*    isShowing={isNoExceptions}*/}
                        {/*    text={('No resolutions found.')}*/}
                        {/*    withoutAdd*/}
                        {/*/>*/}
                    </TabPanel>
                }

                {tab === 'history' &&
                    <TabPanel value={'history'} sx={{padding: '0', height: '100%', flexGrow: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
                        {!isNoHistory && <TargetHistoryTable />}
                        <EmptyArrayImage
                            type={'kycTemplates'}
                            isShowing={isNoHistory}
                            text={t('Nothing found.')}
                            withoutAdd
                        />

                    </TabPanel>
                }
            </TabContext>

            <DeleteTargetExceptionDialog />
            <AddToBlackListDialog />
            <ArchiveClientDialog />
            <RestoreClientFromArchiveDialog />
            <RestoreClientFromBlackListDialog />
            <UpdateClientDataDialog />
            <ShowFullReportDialog />
        </S.PageContainer>
    )
}
