import React, {useCallback, useEffect, useState} from "react";
import {TEditorActor, TOpenDialogEventData} from "../../types";
import {DEFAULT_ACTOR, openManageActorsDialogEventName} from "../../constants";
import {EditorSideMenu} from "../../components/editorSideMenu";
import {v4 as uuid} from "uuid";
import {TForm} from "../../components/dialogs/manageActorsDialog/components/staff";
import {getMsFromDays} from "../../../../utils/dateTools";

export const useManageActorsDialogStaff = (props?: {
    parentRef: React.RefObject<EditorSideMenu>["current"];
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [form, setForm] = useState<TForm>({approvers: [], recipients: []});
    const [isApproversBlock, setIsApproversBlock] = useState<boolean>(false);
    const [isRecipientsCustomOrder, setIsRecipientsCustomOrder] = useState<boolean>(true);
    const [isApproversCustomOrder, setIsApproversCustomOrder] = useState<boolean>(true);
    const isLoadingEmployees = false;
    const _employees: any[] = [];


    const getEmployees = (value: string) => null;

    // useEffect(() => {
    //     console.log('form', form);
    //
    //     // eslint-disable-next-line
    // }, [form])


    useEffect(() => {
        if (isOpen) {
            const config = props?.parentRef?.getActorsData();
            const orderedApprovers = config && (config.approvers.length > 1) && (config.approvers[0].order !== config.approvers[1].order);
            const orderedRecipients = config && (config.recipients.length > 1) && (config.recipients[0].order !== config.recipients[1].order);

            orderedApprovers && setIsApproversCustomOrder(orderedApprovers);
            orderedRecipients && setIsRecipientsCustomOrder(orderedRecipients);
            setForm({
                recipients: config?.recipients.map(r => ({
                    ...r,
                    id: uuid(),
                    sourceEmailRole: r.actor.email + r.role,
                })) ?? [],
                approvers: config?.approvers ?? []
            });


            setIsApproversBlock(Boolean(config && config?.approvers.length > 0));
            setIsRecipientsCustomOrder(true);
            setIsApproversCustomOrder(true);
        }
        // eslint-disable-next-line
    }, [isOpen])

    const handleAddRecipient = () => {
        setForm({
            ...form,
            recipients: [
                ...form.recipients,
                {   id: uuid(),
                    fields: null,
                    actor: DEFAULT_ACTOR,
                    order: isRecipientsCustomOrder ? form.recipients.length + 1 : 0, message: '',
                    sourceEmailRole: '' + undefined,
                }
            ],
        });

    }
    const handleOpenAddMessage = (data: {approverId?: string, groupId?: string, oldMessage?: string, recipientId?: string}) => {
        // dispatch(openAddGroupMessage(data))
    };

    const handleHideAddGroupMessage = () => {
        // dispatch(hideAddGroupMessage());
    };

    const handleSetGroupMessage = useCallback((data: {approverId?: string, groupId?: string, recipientId?: string, message: string,}) => {

        if ( data.approverId && data.groupId) {
            setForm({
                ...form,
                approvers: form.approvers.map(appr => appr.id === data.approverId ? ({
                    ...appr,
                    groups: appr.groups.map(gr => gr.id === data.groupId ? ({
                        ...gr,
                        message: data.message,
                    }) : gr)
                }) : appr)
            });

        } else if ( data.recipientId) {
            setForm({
                ...form,
                recipients: form.recipients.map((r) => r.id === data.recipientId ? ({
                    ...r,
                    message: data.message,
                }) : r)
            });

        }
        handleHideAddGroupMessage();
        //eslint-disable-next-line
    }, [form]);

    const handleToggleRecipientOrder = useCallback((isOrdered: boolean) => {
        setForm({
            ...form,
            recipients: form.recipients.map((r, index) => ({
                ...r,
                order: isOrdered ? index + 1 : 0,
            })),
        });

        setIsRecipientsCustomOrder(isOrdered);

        //eslint-disable-next-line
    }, [form]);

    const handleDeleteRecipient = useCallback((recipientId: string) => {
        setForm({
            ...form,
            recipients: form.recipients.filter((r ) => r.id !== recipientId),
        });
        //eslint-disable-next-line
    }, [form])

    const handleSetActorRecipient = useCallback((recipientId: string, actor: TEditorActor) => {
        setForm({
            ...form,
            recipients: form.recipients.map((r)=> r.id === recipientId ? ({ ...r, actor}) : r),
        });
        //eslint-disable-next-line
    }, [form])

    const handleSetIsOpenByEvent = (ev: Event) => {
        const {detail} = ev as CustomEvent<TOpenDialogEventData>;
        setIsOpen(detail.isOpen);
    }

    const handleClose = () => {
        setIsOpen(false);
    }

    const handleSave = () => {
        props?.parentRef?.setActorsConfig({
            recipients: form.recipients
                .filter(r => r.actor.email.length > 0 || (r.role?.trim().length || 0) > 0)
                .map(r => ({
                    fields: r.fields,
                    actor: r.actor,
                    order: r.order,
                    message: r.message,
                    sourceEmailRole: r.actor.email + r.role,
                    role: r.role || '',
                    eta: r.eta ?? getMsFromDays(7),
                })),
            approvers: form.approvers
                .filter(appr => appr.groups.some(gr => gr.actors.some(a => a.email.length > 0)))
                .map(appr => ({
                    id: appr.id?.includes('index') ? null : appr.id ,
                    order: appr.order,
                    eta: appr.eta ?? getMsFromDays(7),
                    groups: appr.groups
                        .map(gr => ({
                            id: gr.id?.includes('index') ? null : gr.id,
                            actors: gr.actors,
                            name: gr.name,
                            isRequired: gr.isRequired,
                            message: gr.message,
                        }))
                })),

        });

        handleClose();

    }

    const isDisabledSave = false;

    useEffect(() => {
        document.addEventListener(openManageActorsDialogEventName, handleSetIsOpenByEvent);
        return () => {
            document.removeEventListener(openManageActorsDialogEventName, handleSetIsOpenByEvent);
        }
        //eslint-disable-next-line
    }, []);



    return {
        isOpen,
        form,
        setForm,
        isRecipientsCustomOrder,
        isApproversCustomOrder,
        setIsApproversCustomOrder,
        setIsApproversBlock,
        isApproversBlock,
        isLoadingEmployees,
        employees: _employees,

        getEmployees,
        handleAddRecipient,
        handleOpenAddMessage,
        handleHideAddGroupMessage,
        handleSetGroupMessage,
        handleToggleRecipientOrder,
        handleDeleteRecipient,
        handleSetActorRecipient,
        handleSetIsOpenByEvent,
        handleClose,
        handleSave,
        isDisabledSave,
    }
}
