import {FC, useState} from "react";
import {useReport} from "../../hooks/useReport";
import {Box} from "@mui/material";
import {NcrBlock} from "../../components/ncrBlock";

export const NcrReport: FC = () => {
    const {
        controls: {selectedControl},
    } = useReport();

    const [selectedNcr, setSelectedNcr] = useState<string>('');

    return (
        <Box width={'100%'} flexGrow={1} flexDirection={"column"} overflow={"auto"}>

            {selectedControl?.ncr.filter(e => e.status).length === 1 ? (
                <NcrBlock ncr={selectedControl.ncr[0]} open onlyOne/>
            ) : (
                <>
                    {selectedControl?.ncr.filter(e => e.status).map(ncr => (
                        <NcrBlock key={ncr.id} ncr={ncr}
                                  open={selectedNcr === ncr.id}
                                  handleOpen={() => {setSelectedNcr(prevState => ncr.id === prevState ? '' : ncr.id)}}/>
                    ))}
                </>
            )}


        </Box>
    )
}