import {Box, MenuItem, styled} from "@mui/material";
import colors from "../../../../../theme/colors";

const basicPx = 2;

interface EditorDropZoneForBlockItemProps {
    isVertical?: boolean;
    isHover?: boolean;
    isOver?: boolean;
    canDrop?: boolean;
    isDisabled?: boolean;
    scalePower: { height: number, width: number };
    isLast?: boolean;
    inTableCell?: boolean;
    readonly?: boolean;
}
export const EditorDropZoneForBlockItemStyled = styled(Box, {
    shouldForwardProp: (propName) => (
        !["isVertical", "isHover", "isOver", "canDrop", "scalePower", "isDisabled", 'isLast', 'inTableCell', 'readonly'].includes(propName.toString())
    )})<EditorDropZoneForBlockItemProps>(({isHover, onMouseDown, isOver, isVertical, canDrop, isDisabled, scalePower, isLast, inTableCell, readonly}) => ({
    cursor: isOver && canDrop ? 'pointer' : undefined,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: isVertical ? 'column': 'row',
    zIndex: 100,

    flexGrow: isLast ? '1' : undefined,
    flexShrink: 0,

    minWidth: '2.5px',
    minHeight: isLast ? '2.5px' : undefined,
    position: 'relative',

    '& > div': {
        height: isVertical ? `calc(100% - ${basicPx}px)`: `${basicPx * scalePower.height}px`, // 20px
        width: isVertical ? `${basicPx * scalePower.width}px`: `calc(100% - ${basicPx}px)`,
        marginBlock: isVertical ? 'margin-block: auto': '',
        borderRadius: '1px',

        cursor: !readonly ? !isDisabled && isOver && canDrop ? 'pointer' : onMouseDown ? 'col-resize' : undefined : undefined,
        display: 'flex',
        flexDirection: isVertical ? 'column': 'row',
        alignItems: 'center',
        zIndex: 100,

        '& hr': {
            marginInline: isVertical ? 'auto': undefined,
            height: isVertical ? '100%': undefined,
        },

        '& > div.indicator': {
            height: isVertical ? `calc(100% - ${basicPx}px)`: `${basicPx}px`,
            width: isVertical ? `${basicPx}px`: `calc(100% - ${basicPx}px)`,
            backgroundColor: (!isDisabled && isOver) || isHover
                ? colors.primary.hovered
                : !isDisabled && canDrop
                    ? colors.backgrounds.blue_light_1
                    : undefined,
        },

        position: 'absolute',
        top: !isVertical ? `-${basicPx * scalePower.height / 2}px` : 0,
        left: !isVertical ? 0 : `-${basicPx * scalePower.width / 2 - 1.5}px`,
    },

    height: inTableCell ? '100%$' : undefined,


    '& div.EditorDropZoneForBlockItemWithIcon': {
        height: `${basicPx * scalePower.height}px`,
    },
}));

interface EditorDropZoneForBlockItemWithIconProps {
    isHover?: boolean;
}
export const EditorDropZoneForBlockItemWithIcon = styled(Box, {
    shouldForwardProp: (propName) => (
        !["isHover"].includes(propName.toString())
    )})<EditorDropZoneForBlockItemWithIconProps>`
  cursor: ${props => props.isHover ? 'pointer' : 'inherit'};
  border-radius: 8px;
  width: 90%;
  margin-inline: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

    // background-color: ${colors.backgrounds.blue_light_1};
  //background-color: green;

  color: ${colors.stroke.dark_grey};

  opacity: ${props => props.isHover ? 1 : 0};
  transition: opacity 0.2s ease-in-out;

& > div.thinRow {
  width: 100%;
  height: 4px;
  background-color: ${colors.backgrounds.blue_light_1};
  display: flex;
  justify-content: center;
  align-items: center;
  
  & > div.circle {
    //top: -2px;
    position: absolute;
    border-radius: 100%;
    background-color: ${colors.backgrounds.blue_light_1};
    width: 24px;
    height: 24px;
    opacity: 1;
  }
}
  
  position: absolute;
  //top: 0;
  left: 5%;
  //height: 20px;
`

export const EditorDropZoneMenuItem = styled(MenuItem)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
}))