import {Divider} from "@mui/material";
import React from "react";
import {Skeleton} from "@mui/lab";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {Flex} from "../../../../../../newShared/components/Layouts";
import colors from "../../../../../../newShared/theme/colors";


export const CollaboratorRowSkeleton = () => {
    const {isMobile} = useMedia();

    return (
        <Flex direction={'column'} w={'100%'}>
            <Flex  w={'100%'} ai={'center'}>
                <Skeleton width={'36px'} height={'36px'} />

                <Flex direction={'column'} w={isMobile ? '50%' : '70%'} jc={'flex-start'} style={{marginLeft: '16px', marginRight: '16px'}}>
                    <Skeleton width={'100%'} />
                    <Skeleton width={'100%'} />
                </Flex>

                <Skeleton width={isMobile ? '50px' : '70px'} sx={{marginLeft: 'auto'}} />

            </Flex>
            <Divider variant={'fullWidth'} textAlign={'center'} sx={{color: colors.divider, marginTop: '12px', marginBottom: '12px'}} />
        </Flex>
    )
}