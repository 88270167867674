import React, {FC, useEffect, useState} from "react";
import {Button, Typography} from "@mui/material";
import {SmallSizeDialogStyled} from "../manageActorsDialog/styled";
import {
    AddVariableDialogActionsStyled,
    AddVariableDialogContentStyled,
    AddVariableDialogTitleStyled
} from "../addVariableDialog/styled";
import {createEventCommonDialogOpen} from "../../../helpers";
import {TCommonDialogOpenEventData} from "../../../types";
import {commonDialogOpenEventName} from "../../../constants";

export const NotAssignedRoleDialog: FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const type: TCommonDialogOpenEventData["type"] = "notAssignedRole";
    const text = 'You have recipients with role and without email. Please, add email to all recipients or remove them.';
    const title = 'Not assigned role';

    const handleClose = () => {
        createEventCommonDialogOpen({type, isOpen: false, data: []});
    };

    const handleEvent = (ev: Event) => {
        const {detail} = ev as CustomEvent<TCommonDialogOpenEventData>;
        if (detail.type === type) {
            setIsOpen(detail.isOpen);
        }
    }

    useEffect(() => {
        document.addEventListener(commonDialogOpenEventName, handleEvent);
        return () => {
            document.removeEventListener(commonDialogOpenEventName, handleEvent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <SmallSizeDialogStyled open={isOpen} onClose={handleClose}>
            <AddVariableDialogTitleStyled>
                {title}
            </AddVariableDialogTitleStyled>

            <AddVariableDialogContentStyled>
                <Typography>{text}</Typography>
            </AddVariableDialogContentStyled>

            <AddVariableDialogActionsStyled>
                <Button variant={"text"} onClick={handleClose}>{'Close'}</Button>
                {/*<Button variant={"contained"} onClick={onConfirm}>{'Save'}</Button>*/}
            </AddVariableDialogActionsStyled>
        </SmallSizeDialogStyled>
    )
}
