import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {useApproveReassignAsset} from "../../../hooks/dialogs/useApproveReassignAsset";

export const ApproveReassignAssetDialog = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets.dialogs'})
    const {
        isOpen,
        formik,
        selected,
        handleClose,
        handleConfirm,
        isLoadingApprove,
    } = useApproveReassignAsset();

    useEffect(() => {
        !isOpen && formik.resetForm();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{t('Reassign asset')}</DialogTitle>
            <DialogContent style={{display: 'flex', gap: '16px', flexDirection: 'column'}}>
                <Typography variant={'*bodyText2'}>{t('You are now reassigning the asset with id {{assetId}} from {{actorFullName}} to {{fullName}}. Please proceed to confirm the reassignment.',
                    {assetId: selected?.asset?.assetId ?? '', fullName: selected?.request.requestor.fullName ?? '', actorFullName: selected?.asset?.assignedTo ?? ''})}</Typography>

                <TextField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.resolution}
                    name={'resolution'}
                    multiline
                    label={'Comment'}
                    rows={4}
                    error={formik.touched.resolution && Boolean(formik.errors.resolution)}
                    helperText={formik.touched.resolution && formik.errors.resolution}
                />


            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{tCommon('Cancel')}</Button>
                <LoadingButton
                    onClick={handleConfirm} size={'small'}
                    disabled={!formik.isValid}
                    loading={isLoadingApprove}
                >
                    {t('Reassign')}
                </LoadingButton>
            </DialogActions>
        </Dialog>

    )
}
