import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";
import {PATH_VICA_TASKS} from "../../constants";
import {usePDFView} from "../../../../../newShared/components/docViewer/hooks/usePDFView";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {
    mainVicaApproveDelegatedTaskByIdAction,
    mainVicaCancelDelegatedTaskByIdAction,
    mainVicaDeclineDelegatedTaskByIdAction,
    mainVicaGetDelegatedTaskByIdAction
} from "../../store/actions";
import {
    Exact,
    FrameworksDelegatedTaskDataAttachedFilesFileModel,
    FrameworksDelegatedTaskDataFilesListInput,
    MainVicaApproveDelegatedTaskByIdMutationVariables,
    MainVicaCancelDelegatedTaskByIdMutationVariables
} from "../../../../../newShared/GQLTypes";
import {TApolloErrors} from "../../../../../newShared/utils/asyncThunk/types";
import {
    eraseSelectedDelegatedTaskAction,
    loadings,
    openStepFrameworksDialog,
    vicaDelegatedTasksSelector
} from "../../store/slice";
import {downloadFileAction} from "../../../../../newShared/actions/downloadFile";

export const useVicaExactDelegatedTaskPage = ({refreshTable}: {refreshTable: () => void}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {handleOpen} = usePDFView();

    const {stepId, taskId} = useParams<{stepId?: string, taskId?: string}>();
    const {setMessage, isOpenMessageDialog} = useMessageDialog();

    //selectors
    const {selectedTask: exactDelegatedTask, selectedStep} = useSelector(vicaDelegatedTasksSelector);
    const isLoading = useSelector(loadings);

    const id = taskId;
    const isOpen = !!id;

    //useEffects
    useEffect(() => {
        if (id) {
            getTaskById(id);
        } else {
            dispatch(eraseSelectedDelegatedTaskAction())
        }
        //eslint-disable-next-line
    }, [id]);

    //stores
    const [cancelTaskDialog, setCancelTaskDialog] = useState<boolean>(false);
    const [declineTaskDialog, setDeclineTaskDialog] = useState<boolean>(false);
    const [allDocsPreviewDialog, setAllDocsPreviewDialog] = useState<boolean>(false);
    const [evidenceDocsPreviewDialog, setEvidenceDocsPreviewDialog] = useState<boolean>(false);
    const [approveTaskDialog, setApproveTaskDialog] = useState<boolean>(false);

    //actions
    const getTaskById = (_id: string) => {
        dispatch(mainVicaGetDelegatedTaskByIdAction({data: {taskId: _id}, clean: true, onError: handleClose}));
    }
    const handleClose = () => {
        history.replace({
            pathname: PATH_VICA_TASKS + '/' + stepId,
            search: history.location.search
        });
        setCancelTaskDialog(false);
        setDeclineTaskDialog(false);
        setApproveTaskDialog(false);
        setEvidenceDocsPreviewDialog(false);
        setAllDocsPreviewDialog(false);
    }

    const handleOpenCancelTask = () => setCancelTaskDialog(true);

    const handleCancelTask = (reason: string) => {
        const onSuccess = (request: (Omit<MainVicaCancelDelegatedTaskByIdMutationVariables, "workspaceId" | "organizationId"> & {workspaceId?: string | undefined, organizationId?: string | undefined})) => {
            setMessage({title: 'Completed successfully', message: `Delegated task ${id} has been cancelled.`});
            handleClose();
            setCancelTaskDialog(false);
        }

        const onError = (request: (Omit<Exact<{workspaceId: string, taskId: string, reason: string}>, "workspaceId" | "organizationId"> & {workspaceId?: string | undefined, organizationId?: string | undefined}), error: TApolloErrors) => {
            const error409 = error.e409?.[0];
            const error404 = error.e404?.[0];
            if(error409?.type === 'STATUS_CHANGED'){
                setMessage({title: 'Action conflict error', message: `Action not applicable! Task status was changed`});
                setCancelTaskDialog(false);
                id && getTaskById(id);
            }
            if(error404){
                setMessage({title: `Delegated task ${id}`, message: `Delegated task with id ${id} not found`});
                setCancelTaskDialog(false);
                id && getTaskById(id);
                refreshTable();
                handleClose();
            }
        }


        id && dispatch(mainVicaCancelDelegatedTaskByIdAction({data: {taskId: id, reason}, onSuccess, onError}));
    }

    const handlePreviewFile = (file: FrameworksDelegatedTaskDataAttachedFilesFileModel) => {
        handleOpen({...file});
    }

    const handleDownloadFile = ({fileId, fileName}: FrameworksDelegatedTaskDataAttachedFilesFileModel) => {
        dispatch(downloadFileAction({data: {
                fileId, fileName
            }}));
    }

    const openPreviewAllFiles = () => setAllDocsPreviewDialog(true);

    const openPreviewAllAddedToEvidenceFiles = () => setEvidenceDocsPreviewDialog(true);

    const handleOpenDeclineTask = () => setDeclineTaskDialog(true);
    const handleDeclineTask = (reason: string) => {
        const onSuccess = () => {
            setMessage({title: 'Completed successfully', message: `Delegated task ${id} has been declined.`});
            handleClose();
            setDeclineTaskDialog(false);
        }

        const onError = (request: (Omit<Exact<{workspaceId: string, taskId: string, reason: string}>, "workspaceId" | "organizationId"> & {workspaceId?: string | undefined, organizationId?: string | undefined}), error: TApolloErrors) => {
            const error409 = error.e409?.[0];
            const error404 = error.e404?.[0];
            if(error409?.type === 'STATUS_CHANGED'){
                setMessage({title: 'Action conflict error', message: `Action not applicable! Task status was changed`});
                setDeclineTaskDialog(false);
                id && getTaskById(id);
            }
            if(error404){
                setMessage({title: `Delegated task ${id}`, message: `Delegated task with id ${id} not found`});
                setDeclineTaskDialog(false);
                id && getTaskById(id);
                refreshTable();
                handleClose();
            }
        }

        id && dispatch(mainVicaDeclineDelegatedTaskByIdAction({data: {taskId: id, reason}, onSuccess, onError}));
    }

    const handleOpenApproveTask = () => setApproveTaskDialog(true);

    const handleApproveTask = (files: FrameworksDelegatedTaskDataAttachedFilesFileModel[]) => {
        const onSuccess = (request: (Omit<MainVicaApproveDelegatedTaskByIdMutationVariables, "workspaceId" | "organizationId"> & {workspaceId?: string | undefined, organizationId?: string | undefined})) => {
            setMessage({title: 'Completed successfully', message: `Delegated task ${id} has been approved. All selected files have been added to evidence.`});
            handleClose();
            setApproveTaskDialog(false);
        }

        const onError = (request: Omit<Exact<{workspaceId: string, taskId: string, selectedAttachments: FrameworksDelegatedTaskDataFilesListInput}>, "workspaceId" | "organizationId">, error: TApolloErrors) => {
            const error409 = error.e409?.[0];
            const error404 = error.e404?.[0];
            if(error409?.type === 'STATUS_CHANGED'){
                setMessage({title: 'Action conflict error', message: `Action not applicable! Task status was changed`});
                setApproveTaskDialog(false);
                id && getTaskById(id);
            }
            if(error404){
                setMessage({title: `Delegated task ${id}`, message: `Delegated task with id ${id} not found`});
                setApproveTaskDialog(false);
                id && getTaskById(id);
                refreshTable();
                handleClose();
            }
        }

        id && dispatch(mainVicaApproveDelegatedTaskByIdAction({data: {taskId: id, selectedAttachments: {files}}, onSuccess, onError}));
    }

    const handleOpenEvidences = () => {
        selectedStep && dispatch(openStepFrameworksDialog({step: selectedStep}));
    }

    return{
        isOpen: isOpen && !isOpenMessageDialog &&
            !cancelTaskDialog && !declineTaskDialog &&
            !allDocsPreviewDialog && !approveTaskDialog && !evidenceDocsPreviewDialog
        ,
        id,
        exactDelegatedTask,
        isLoadingExact: isLoading.selectedTask,
        isLoadingFile: (id: string) => isLoading.isDownloadingFile.includes(id),

        actions: {
            handleOpenCancelTask,
            handleClose,
            handleDownloadFile,
            handlePreviewFile,
            openPreviewAllFiles,
            handleOpenApproveTask,
            handleOpenDeclineTask,
            openPreviewAllAddedToEvidenceFiles,
            handleOpenEvidences
        },
        cancelTaskDialog:{
            isOpen: cancelTaskDialog && !isOpenMessageDialog,
            handleClose: () => setCancelTaskDialog(false),
            handleCancelTask,
            isLoading: isLoading.cancelDelegateTask
        },
        declineTaskDialog: {
            isOpen: declineTaskDialog && !isOpenMessageDialog,
            handleClose: () => setDeclineTaskDialog(false),
            handleDeclineTask,
            isLoading: isLoading.declineDelegateTask
        },
        previewAllDocsDialog: {
            isOpen: allDocsPreviewDialog || evidenceDocsPreviewDialog,
            handleClose: () => {
                setAllDocsPreviewDialog(false);
                setEvidenceDocsPreviewDialog(false);
            },
            attachments: exactDelegatedTask?.data?.attachedFiles ?? [],
            isEvidenceAttachments: evidenceDocsPreviewDialog,
        },
        approveTaskDialog: {
            isOpen: approveTaskDialog && !isOpenMessageDialog,
            handleClose: () => setApproveTaskDialog(false),
            handleApproveTask,
            isLoading: isLoading.approveDelegateTask
        },
    }
}
