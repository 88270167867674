import React, {ChangeEventHandler, FC, Fragment, MouseEventHandler, useEffect, useState} from "react";
import {TIdName, TIdNameLogo} from "../../types";
import {useMainTranslation} from "../../hooks/useMainTranslationHooks/useMainTranslation";
import {
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Icon,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import colors from "../../theme/colors";
import {ExpandMore, NavigateNext} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import {ReactComponent as DotIcon} from "./components/dot.svg";
import {useHistory} from "react-router-dom";
import {PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED} from "../../constants";

type TProps = {
    frameworks: ({
        controls: ({
            evidences: TIdName[]
        } & TIdName)[]
    } & TIdNameLogo)[],

    handleClose: () => void,
}
export const FrameworksControlsEvidencesDialog: FC<TProps> = ({frameworks, handleClose}) => {
    const {t, tCommon} = useMainTranslation('');
    const history = useHistory();
    const isOpen = !!frameworks.length;

    const [search, setSearch] = useState<string>('');
    const filteredFrameworks = frameworks.filter(framework => framework.name.toLowerCase().includes(search.trim().toLowerCase()));

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearch(e.target.value);
    };
    const handleClear: MouseEventHandler<HTMLButtonElement> = (e) => {
        setSearch('');
    };

    const [opened, setOpened] = useState<{frameworkId: string, controlId: string}>({frameworkId: '', controlId: ''});
    const handleFrameworkClick = (frameworkId: string) => () => {
        if (opened.frameworkId === frameworkId) setOpened({frameworkId: '', controlId: ''})
        else setOpened({frameworkId, controlId: ''});
    };
    const handleControlClick = (controlId: string) => () => {
        if (opened.controlId === controlId) setOpened({frameworkId: opened.frameworkId, controlId: ''})
        else setOpened({frameworkId: opened.frameworkId, controlId});
    };

    const handleRouteToFramework = (frameworkId: string): MouseEventHandler => (e) => {
        e.stopPropagation();
        history.push(`${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/${frameworkId}`)
    };
    const handleRouteToControl = (frameworkId: string, controlId: string): MouseEventHandler => (e) => {
        e.stopPropagation();
        history.push(`${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/${frameworkId}/control/${controlId}`)
    };
    const handleRouteToEvidence = (frameworkId: string, controlId: string, evidenceId: string): MouseEventHandler => (e) => {
        e.stopPropagation();
        history.push(`${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/${frameworkId}/control/${controlId}/evidences/${evidenceId}`)
    };
    
    useEffect(() => {
        setSearch('');
        setOpened({frameworkId: '', controlId: ''});
    }, [isOpen]);

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Frameworks')}</DialogTitle>
            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '20px', overflow: 'hidden'}}>
                <TextField
                    label={t('Search')}
                    size={'small'}
                    value={search}
                    onChange={handleChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position={'end'}>
                                {search.length === 0 ? <SearchIcon /> : (
                                    <IconButton sx={{padding: '0'}} onClick={handleClear}><ClearIcon /></IconButton>
                                )}
                            </InputAdornment>
                        )}}
                />

                <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px', overflow: 'auto'}}>
                    {/*<Typography color={colors.text.grey_dark} variant={'*bodySmall'} sx={{flexShrink: 0}}>*/}
                    {/*    {`${t('Total frameworks')}: ${frameworks.length}`}*/}
                    {/*</Typography>*/}

                    {filteredFrameworks.map(framework => (
                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', flexShrink: 0,
                            borderRadius: '8px',
                            border: `1px solid ${colors.stroke.grey}`,
                        }}>
                            <Box key={framework.id} onClick={handleFrameworkClick(framework.id)}
                                 sx={{
                                     display: 'flex', alignItems: 'center', width: '100%', overflow: 'hidden', gap: '12px',
                                     backgroundColor: colors.backgrounds.grey_light2,
                                     padding: '10px 16px',
                                     cursor: 'pointer',
                                     '&:hover': {backgroundColor: colors.backgrounds.optional},
                                 }}>
                                {framework.id !== opened.frameworkId ? <NavigateNext /> : <ExpandMore />}
                                <Box key={framework.id} sx={{display: 'flex', alignItems: 'center', width: '100%', overflow: 'hidden', gap: '8px'}}>
                                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '26px', height: '26px'}}>
                                        {framework.logo && <img src={framework.logo} alt={framework.name} style={{maxHeight: '26px', maxWidth: '26px'}}/>}
                                    </Box>
                                    <Typography variant={'*bodyText2'} color={colors.text.dark}
                                                onClick={handleRouteToFramework(framework.id)}
                                                sx={{
                                                    '&:hover': {
                                                        color: colors.primary.blue,
                                                        cursor: 'pointer',
                                                    },
                                                }}>
                                        {framework.name}
                                    </Typography>
                                </Box>
                            </Box>

                            <Collapse in={opened.frameworkId === framework.id} sx={{flexShrink: 0}}>
                                {framework.controls.map(control => (
                                    <Fragment key={control.id}>
                                        <Divider flexItem />

                                        <Box onClick={handleControlClick(control.id)}
                                             sx={{
                                                 display: 'flex', alignItems: 'center', width: '100%', overflow: 'hidden', gap: '12px',
                                                 padding: '8px 16px',
                                                 cursor: 'pointer',
                                                 '&:hover': {backgroundColor: colors.backgrounds.optional},
                                             }}>
                                            <div style={{width: '24px', height: '24px'}}/>
                                            {control.id !== opened.controlId ? <NavigateNext /> : <ExpandMore />}
                                            <Typography variant={'*bodyText2'} color={colors.text.dark}
                                                        onClick={handleRouteToControl(framework.id, control.id)}
                                                        sx={{
                                                            '&:hover': {
                                                                color: colors.primary.blue,
                                                                cursor: 'pointer',
                                                            },
                                                        }}>
                                                {control.name}
                                            </Typography>
                                        </Box>

                                        <Collapse in={opened.controlId === control.id} sx={{flexShrink: 0}}>
                                            {control.evidences.map(evidence => (
                                                <Box key={evidence.id} onClick={handleRouteToEvidence(framework.id, control.id, evidence.id)}
                                                     sx={{
                                                         display: 'flex', alignItems: 'center', width: '100%', overflow: 'hidden', gap: '2px',
                                                         padding: '6px 16px',
                                                         cursor: 'pointer',
                                                         '&:hover': {
                                                             backgroundColor: colors.backgrounds.optional,
                                                             '& > *': {color: colors.primary.blue + ' !important'},
                                                         },
                                                     }}>
                                                    <div style={{width: '48px', height: '24px'}}/>
                                                    <Icon><DotIcon/></Icon>
                                                    <Typography variant={'*bodyText2'} color={colors.text.dark}>
                                                        {evidence.name}
                                                    </Typography>
                                                </Box>
                                            ))}
                                        </Collapse>
                                    </Fragment>
                                ))}
                            </Collapse>
                        </Box>
                    ))}
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{tCommon('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
};