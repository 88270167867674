import React, {FC} from "react";
import {Box, Skeleton, Typography} from "@mui/material";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Archive, Delete} from "@mui/icons-material";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {getReportStatus, getReportType} from "../../helpers";
import {useGeneratedReportsPage} from "./hooks/useGeneratedReportsPage";
import {ArchiveReportDialog} from "../../components/dialogs/archiveReportDialog";
import {DeleteReportDialog} from "../../components/dialogs/deleteReportDialog";
import {PageWrapper} from "../../../../../newShared/components/Layouts";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {useReportsFilter} from "../../hooks/useReportsFilter";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {TReport} from "../../types";
import {useMedia} from "../../../../../newShared/hooks/useMedia";


export const GeneratedReports: FC = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathReports.Table'});
    const {isMobile} = useMedia();
    // const {isLessThen1050, isDesktop} = useMedia();

    const {
        goToCreateReport, goToReport,
        setArchiveReportDialog, setDeleteReportDialog, reports,
        // value, handleChange, handleKeyDown, setFilter, filter,
        // bottomLoader, onScroll, scrollRef,
        genericTable
    } = useGeneratedReportsPage();

    const {configs} = useReportsFilter(false);

    return (
        <PageWrapper>
            <PageTitleAndActions
                title={t('Generated reports')}
                actions={[
                    {
                        onClick: goToCreateReport,
                        title: t('Generate report'),
                    }
                ]}
            />

            <GenericTable<TReport> 
                id={'GeneratedReports'}
                columnsConfig={{totalName: 'Total reports', forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: reports,
                    customCellPaddings: '8px',
                    // rowActionsConfig: [
                    //     {title: 'View in Org tree', onClick: handleGoToTree},
                    //     {title: 'Delete employee', onClick: handleDelete}
                    // ],
                    getRowUniqueId: (row) => row.id,
                    onRowClick: (row) => {goToReport(row.id, row.archived)},
                    columnsConfigs: [
                        {key: "name", name: 'Report name', default: true},
                        {key: 'auditorEmail', name: 'Auditor', default: true, valueGetter: (row) => row.auditor?.email},
                        {key: 'status', name: 'Report status', default: true, valueRenderer: (row) => {
                                const {status, color} = getReportStatus(t, row.reportStatus);
                                return <Typography variant={'*bodyText2'} color={color}>{status}</Typography>
                            }
                        },
                        {key: 'type', name: 'Report type', default: true, valueRenderer: (row) => getReportType(t, row.reportType)},
                        {key: 'generated', name: 'Generated', default: true, valueGetter: (row) => parseDateAuto(row.generated)},
                        {key: 'framework', name: 'Framework', default: true, valueRenderer: (row) => {
                            return(
                                <Box display={"flex"} alignItems={"center"}>
                                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} width={'40px'} height={'40px'} m={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>
                                        {row.framework.logo && <img src={row.framework.logo} alt={''} style={{maxWidth: '40px', maxHeight: '40px'}}/>}
                                    </Box>
                                    {row.framework.name || ''}
                                </Box>
                            )
                        }, customSkeleton: <Skeleton height={40} width={80}/>},
                    ],
                    rowActionsConfig: [
                        {
                            title: 'Archive',
                            icon: <Archive/>,
                            onClick: (row) => row && setArchiveReportDialog({isOpen: true, reportId: row.id, name: row.name}),
                            disabled: (row) => !(row.reportStatus === 'COMPLETED' || row.reportStatus === 'REJECTED'),
                            dontShow: (row) => row.archived,
                        },
                        {
                            title: 'Delete',
                            icon: <Delete/>,
                            onClick: (row) => row && setDeleteReportDialog({isOpen: true, reportId: row.id, name: row.name}),
                            disabled: (row) => !(row.reportStatus === 'COMPLETED' || row.reportStatus === 'REJECTED'),
                            dontShow: (row) => !row.archived,
                        },
                    ],
                }}
                filtersConfig={{
                    searchAsArray: true,
                    searchCustomFilterKeyName: 'input',
                    genericFilterProps: {
                        configs,
                        fetchResultWithSelectedFilters: () => null,
                        isAddFilters: false
                    }
                }}
                emptyArrayImageProps={{type: 'reports', filterNotApplied: {onClick: goToCreateReport, text: 'You have not created any reports'}}}
            />

            {/*<GenericFiltersArray*/}
            {/*    configs={configs}*/}
            {/*    fetchResultWithSelectedFilters={applyFilter}*/}
            {/*    isAddFilters={false}*/}
            {/*/>*/}

            {/*{(reports.length > 0 || isLoading) && (*/}
            {/*    <TableContainer onScroll={onScroll} ref={scrollRef} sx={{marginTop: '24px', minHeight: '60px'}}>*/}
            {/*        <Table stickyHeader size={"small"}>*/}
            {/*            <TableHead>*/}
            {/*                <TableRow>*/}
            {/*                    <TableCell>{t('Report name')}</TableCell>*/}
            {/*                    <TableCell>{t('Auditor')}</TableCell>*/}
            {/*                    <TableCell>{t('Report status')}</TableCell>*/}
            {/*                    {isDesktop && <TableCell>{t('Report type')}</TableCell>}*/}
            {/*                    {!isLessThen1050 && <TableCell>{t('Generated')}</TableCell>}*/}
            {/*                    {!isLessThen1050 && <TableCell>{t('Framework')}</TableCell>}*/}
            {/*                    {isDesktop && <TableCell></TableCell>}*/}
            {/*                </TableRow>*/}
            {/*            </TableHead>*/}
            {/*            <TableBody>*/}
            {/*                {reports.map((report, index) => report ? (*/}
            {/*                    <TableRow key={report.id} onClick={() => {goToReport(report.id, report.archived)}} hover*/}
            {/*                              sx={{filter: report.archived ? 'opacity(0.5) grayscale(1)' : undefined}}>*/}
            {/*                        <TableCell>{report.name}</TableCell>*/}
            {/*                        <TableCell>{report.auditor?.email || ''}</TableCell>*/}
            {/*                        <TableCell style={{color: getReportStatus(t, report.reportStatus).color}}>{getReportStatus(t, report.reportStatus).status}</TableCell>*/}
            {/*                        {isDesktop && <TableCell>{getReportType(t, report.reportType)}</TableCell>}*/}
            {/*                        {!isLessThen1050 && <TableCell>{parseDateAuto(report.generated)}</TableCell>}*/}
            {/*                        {!isLessThen1050 && (*/}
            {/*                            <TableCell>*/}
            {/*                                <Box display={"flex"} alignItems={"center"}>*/}
            {/*                                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} width={'40px'} height={'40px'} m={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>*/}
            {/*                                        {report.framework.logo && <img src={report.framework.logo} alt={''} style={{maxWidth: '40px', maxHeight: '40px'}}/>}*/}
            {/*                                    </Box>*/}
            {/*                                    {report.framework.name || ''}*/}
            {/*                                </Box>*/}
            {/*                            </TableCell>*/}
            {/*                        )}*/}
            {/*                        {isDesktop && !report.archived && (*/}
            {/*                            <TableCell>*/}
            {/*                                <IconButton onClick={(e) => {*/}
            {/*                                    e.stopPropagation();*/}
            {/*                                    report && setArchiveReportDialog({isOpen: true, reportId: report.id, name: report.name})*/}
            {/*                                }} disabled={!(report.reportStatus === 'COMPLETED' || report.reportStatus === 'REJECTED')}>*/}
            {/*                                    <Archive/>*/}
            {/*                                </IconButton>*/}
            {/*                            </TableCell>*/}
            {/*                        )}*/}
            {/*                        {isDesktop && report.archived && (*/}
            {/*                            <TableCell>*/}
            {/*                                <IconButton onClick={(e) => {*/}
            {/*                                    e.stopPropagation();*/}
            {/*                                    report && setDeleteReportDialog({isOpen: true, reportId: report.id, name: report.name})*/}
            {/*                                }}>*/}
            {/*                                    <Delete/>*/}
            {/*                                </IconButton>*/}
            {/*                            </TableCell>*/}
            {/*                        )}*/}
            {/*                    </TableRow>*/}
            {/*                ) : <TableRowSkeleton key={'loader' + index} columns={!isLessThen1050 ? 7 : isDesktop ? 5 : 3}/>)}*/}

            {/*                {(bottomLoader || isLoading) && <TableRowSkeleton columns={!isLessThen1050 ? 7 : isDesktop ? 5 : 3}/>}*/}
            {/*            </TableBody>*/}
            {/*        </Table>*/}
            {/*    </TableContainer>*/}
            {/*)}*/}

            {/*<EmptyArrayImage type={"reports"} isShowing={reports.length === 0 && !isLoading} text={'You have not created any reports'} onClick={goToCreateReport}/>*/}

            <ArchiveReportDialog/>
            <DeleteReportDialog/>
        </PageWrapper>
    )
}