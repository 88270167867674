import React from "react";
import {useReleaseAccessManagementApp} from "../../../hooks/dialogHooks/useReleaseAccessManagementApp";
import {ReleaseDialog} from "../../../../../../newShared/components/releaseDialog";

export const ReleaseAccessManagementApp = () => {
    // const {isMobile} = useMedia();
    // const {t, revDir} = useMainTranslation('', {keyPrefix: 'releaseDialog'});
    const {
        isOpen,
        handleClose,
        isLoadingRelease,
        handleRelease,
    } = useReleaseAccessManagementApp();

    return (
        <ReleaseDialog isOpen={isOpen} handleClose={handleClose} isSubmittingLoading={isLoadingRelease}
                       handleSubmit={({evidence, review, sign, read}) => {
                           handleRelease({
                               ...evidence,
                               readers: read,
                               reviewers: review,
                               signers: sign
                           })
                       }}
        />
    )
}