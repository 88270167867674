import {IKeyIndex, ItemTypes, TEditorActor, TEditorStageApprover} from "../../../../../../types";
import React, {FC, useEffect, useState} from "react";
import {useMainTranslation} from "../../../../../../../../hooks/useMainTranslationHooks/useMainTranslation";
import {ConnectableElement, DragSourceMonitor, useDrag, useDrop} from "react-dnd";
import {ReactComponent as GroupIcon} from "../../../../../../../../../newShared/images/documents/group-icon.svg";
import {Box, Button, Checkbox, IconButton, InputAdornment, TextField} from "@mui/material";
import * as S from "../../../styled";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {TypographyBodySmall} from "../../../../../../../Inputs/styled";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {CommonAvatar} from "../../../../../../../Basic/CommonAvatar";
import {getName, validateEmail} from "../../../../../../../../utils/text";

import {ReactComponent as MessageIcon} from "../../../../../../../../../newShared/images/documents/message-icon.svg";
import {
    ReactComponent as CompleteMessageIcon
} from "../../../../../../../../../newShared/images/documents/complete-message-icon.svg";
import {v4 as uuid} from "uuid";
import {TActorEmailName} from "../../../../../../../../../newDomain/components/documentsRefactor/types";
import {optionIsTCollaborator} from "../../../../../../../AutocompleteCollaborators/types";
import {AutocompleteCollaborators} from "../../../../../../../AutocompleteCollaborators";
import {defaultGetOptionDisabled} from "../../../../../../../AutocompleteCollaborators/helpers";

interface ApproversGroupProps {
    approver: TEditorStageApprover,
    addedApprovers: string[],
    index: number,
    moveColumn: (key: string, atIndex: number) => void,
    findColumn: (key: string) => {column: TEditorStageApprover, index: number} | null
    isApproverCustomOrder: boolean;
    apprGroupIds: string[];
    handleDeleteGroup: (approveId: string) => void;
    handleSetNameGroup: (approveId: string, groupId: string, name: string) => void;
    toggleIsRequired: (approverId: string, groupId: string ) => void;
    handleAddGroupActor: (approverId: string, groupId: string, actor: TEditorActor ) => void;
    handleDeleteGroupActor: (approverId: string, groupId: string, email: string) => void;
    handleOpenAddGroupMessage: (data: {approverId: string, groupId: string, oldMessage: string}) => void;
}
export const ApproverGroupEditor: FC<ApproversGroupProps> = (props) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});
    const {
        approver,
        addedApprovers,
        index,
        apprGroupIds,
        handleDeleteGroup,
        handleSetNameGroup,
        toggleIsRequired,
        handleAddGroupActor,
        handleDeleteGroupActor,
        isApproverCustomOrder,
        //dnd
        handleOpenAddGroupMessage,
        moveColumn,
        findColumn,
    } = props;

    const [uniqueInputId, setUniqueInputId] = useState<string>(uuid());
    const [newApprover, setNewApprover] = useState<TEditorActor | null>(null);
    const [error, setError] = useState<string | null>(null);

    const isOk = (email: string) => {
        if (!validateEmail(email)) {
            setError('Invalid email');
            return false;
        }
        else if (addedApprovers.some(a => a === email)) {
            setError('Email already exists');
            return false;
        } else {
            error && setError(null);
            return true;
        }
    }

    const handleAdd = (groupId: string) => {
        if (!newApprover) return;
        const tmpAppr: TActorEmailName = {email: newApprover.email, firstName: newApprover.firstName, lastName: newApprover.lastName};

        if (isOk(tmpAppr.email)) {
            approver.id && handleAddGroupActor(approver.id, groupId, tmpAppr);
            setNewApprover(null);
            setUniqueInputId(uuid());
        }
    }

    useEffect(() => {
        error && setError(null);

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newApprover?.email]);

    //DND
    const searchedColumn = findColumn(approver.id ?? '');
    // console.log(`----COL searchedColumn: ${JSON.stringify(searchedColumn)}`);
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.CARD,
            item: {key: approver.id, index: searchedColumn?.index ?? -1},
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const { key: droppedKey, index: originalIndex } = item;

                // console.log(`ROW useDrag END with item ${JSON.stringify(item)}`);
                const didDrop = monitor.didDrop();
                if (!didDrop && item) {
                    moveColumn(droppedKey ?? '', originalIndex);
                    // console.log(`ROW useDrag - moveColumn exec: ${droppedKey} | ${originalIndex}`);
                }
            },
        }),
        [approver.id, searchedColumn, moveColumn],
    )

    const [, drop] = useDrop(
        () => ({
            accept: ItemTypes.CARD,
            hover({ key: draggedKey }: IKeyIndex) {
                // console.log(`Card useDrop draggedKey:${draggedKey} in cardId ${column.key}`);
                if (draggedKey !== approver.id) {
                    const col = findColumn(approver.id ?? '')
                    if(col){
                        // console.log(`ROW useDrop - moveColumn exec: ${draggedKey} | ${col.index}`);
                        moveColumn(draggedKey, col.index);
                    }else{
                        // console.log(`ROW useDrop - col not found by key ${column.key}`)
                    }
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
                isActive: monitor.canDrop() && monitor.isOver(),
            }),
        }),
        [findColumn, moveColumn],
    );


    return (
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} marginTop={'16px'} width={'100%'} >
            {isApproverCustomOrder && <S.OrderBox>{index + 1}</S.OrderBox>}

            <Box marginLeft={isApproverCustomOrder ? '16px' : 0} width={'100%'} >
                { approver.groups.map((group) => (

                    <S.InfoActorContainer
                        key={group.id}
                        isOneApprover={!apprGroupIds.find(grId => grId === group.id) ? true : undefined}
                        isDragging={isDragging ? true : undefined}
                        ref={(node: ConnectableElement) => drag(drop(node))}
                        isOrdered={isApproverCustomOrder ? true : undefined}
                    >
                        <DragIndicatorIcon sx={{opacity: isApproverCustomOrder ? 0 : 1, marginRight: '6px'}}/>

                        { apprGroupIds.find(grId => grId === group.id) ?
                            <Box display={"flex"} alignItems={'center'} width={'100%'} flexDirection={'column'} >
                                <S.InfoActorFlexBox >

                                    <TextField variant={'standard'}
                                               size={"small"}
                                               value={group.name} placeholder={t('Name this group')}
                                               onChange={(e) => approver.id && group.id &&  handleSetNameGroup(approver.id, group.id, e.target.value)}
                                               InputProps={{
                                                   startAdornment: (
                                                       <InputAdornment position="start">
                                                           <GroupIcon />
                                                       </InputAdornment>
                                                   ),
                                               }}
                                    />

                                    <Box sx={{display: 'flex', alignItems: 'center', marginRight: '8px'}}>
                                        <Checkbox checked={group.isRequired} onChange={() => approver.id && group.id && toggleIsRequired(approver.id, group.id)} />
                                        <TypographyBodySmall>{t('Required')}</TypographyBodySmall>
                                    </Box>

                                    <S.VerticalDivider />
                                    <IconButton size={'small'} onClick={() => approver.id && group.id && handleOpenAddGroupMessage({approverId: approver.id, groupId: group.id, oldMessage: group.message || ''})}>
                                        {group.message  ?  <CompleteMessageIcon /> :  <MessageIcon />}
                                    </IconButton>

                                    <S.VerticalDivider />
                                    <IconButton onClick={() => approver.id && handleDeleteGroup(approver.id)}>
                                        <DeleteOutlineIcon />
                                    </IconButton>

                                </S.InfoActorFlexBox>
                                <Box display={'flex'} alignItems={'flex-start'} width={'100%'} marginTop={'8px'}>
                                    <S.AutocompleteBox>
                                        <AutocompleteCollaborators
                                            helperText={error || undefined}
                                            key={uniqueInputId}
                                            getOptionLabelCustom={"name+email"}
                                            freeSoloType={"email"}
                                            freeSolo
                                            defaultValue={''}
                                            onChange={value => {
                                                if (!optionIsTCollaborator(value)) {
                                                    setNewApprover({email: value as string, firstName: '', lastName: ''});
                                                } else {
                                                    setNewApprover({email: value?.email || '', firstName: value?.firstName || '', lastName: value?.lastName || ''});
                                                }
                                            }}
                                            getOptionDisabled={defaultGetOptionDisabled(addedApprovers)}

                                            sx={{width: '430px', '& label': {top: '0px !important'}}}
                                        />
                                        <Button size={'small'} sx={{marginLeft: '16px', alignSelf: 'flex-start', mt: '2px'}}
                                                disabled={!newApprover || !validateEmail(newApprover.email) || addedApprovers.includes(newApprover.email)}
                                                onClick={() => group.id && handleAdd(group.id)}>{t('Add')}</Button>
                                    </S.AutocompleteBox>
                                </Box>

                                {group.actors.map((actor, index) => (
                                    <S.InfoActorBox key={`actor ${index}` + actor.email} h={'43px'} mt={'12px'}>
                                        <CommonAvatar name={getName(actor.firstName || actor.email, actor.lastName || '')} size={'d28'} />
                                        <S.ApproverTitle>{actor.email}</S.ApproverTitle>
                                        <DeleteOutlineIcon onClick={() => approver.id && group.id && handleDeleteGroupActor(approver.id, group.id, actor.email) } style={{margin: '0 8px'}} />
                                    </S.InfoActorBox>
                                ))}
                            </Box>
                            :
                            <>
                                <CommonAvatar size={'d28'} name={getName(group.actors[0].firstName || group.actors[0].email, group.actors[0].lastName || group.actors[0].email)}/>
                                <S.ApproverTitle>{group.actors[0].email}</S.ApproverTitle>

                                <S.VerticalDivider/>
                                <Box sx={{display: 'flex', alignItems: 'center', marginRight: '8px'}}>
                                    <Checkbox checked={group.isRequired} onChange={() => approver.id && group.id && toggleIsRequired(approver.id, group.id)} />
                                    <TypographyBodySmall>{t('Required')}</TypographyBodySmall>
                                </Box>

                                <S.VerticalDivider />
                                <IconButton size={'small'} onClick={() => approver.id && group.id && handleOpenAddGroupMessage({approverId: approver.id, groupId: group.id, oldMessage: group.message || ''})} >
                                    {group.message  ?  <CompleteMessageIcon /> :  <MessageIcon />}
                                </IconButton>

                                <S.VerticalDivider />
                                <IconButton onClick={() => approver.id && handleDeleteGroup(approver.id)}>
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </>
                        }
                    </S.InfoActorContainer>
                ))}
            </Box>
        </Box>
    )
}