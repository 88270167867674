import * as Yup from 'yup';
import {
    MainTrainingCoverImageType,
    MainTrainingExamStatus,
    TrainingExamLevel,
    TrainingExamQuestionType
} from "../../../../../../newShared/GQLTypes";
import {MainTrainingExamFormik, MainTrainingExamFormikQuestionOption} from "../../../types";

const optionSchema = Yup.object({
    optionId: Yup.string(),
    option: Yup.string()
        .trim()
        .lowercase()
        .required('Option is required')
        .min(1, 'Option is required')
        .max(160, 'Option cannot be longer than 160 characters')
        .test('option', 'Option name must be unique', function (value) {
            const exam = this.options.context as MainTrainingExamFormik | undefined;
            const option = this.parent as MainTrainingExamFormikQuestionOption | undefined;
            const currentQuestion = exam?.questions?.find(e => e.options.some(e => e.optionId === option?.optionId))

            return currentQuestion?.options
                ?.filter(e => e.option?.trim().toLowerCase() === value?.trim().toLowerCase()).length === 1;
        }),
    correct: Yup.boolean()
        .test('correct', 'At least one correct answer is required', function (value) {
            if (value) return true;

            const exam = this.options.context as MainTrainingExamFormik | undefined;
            const option = this.parent as MainTrainingExamFormikQuestionOption | undefined;
            const currentQuestion = exam?.questions?.find(e => e.options.some(e => e.optionId === option?.optionId))

            return currentQuestion?.options.some(e => e.correct) ?? true;
        }),
    points: Yup.number()
        .test('points', 'Points are required for correct answers', function (value) {
            if (typeof value !== "number") return false;

            const exam = this.options.context as MainTrainingExamFormik | undefined;
            const option = this.parent as MainTrainingExamFormikQuestionOption | undefined;
            const currentQuestion = exam?.questions?.find(e => e.options.some(e => e.optionId === option?.optionId))

            return !currentQuestion?.pointsAutoCount || (option?.correct ? value > 0 : true);
        }),
    order: Yup.number(),
});

const questionSchema = Yup.object({
    questionId: Yup.string(),
    question: Yup.string()
        .trim()
        .lowercase()
        .required('Question is required')
        .max(120, 'Question cannot be longer than 120 characters')
        .test('question', 'Question name must be unique', function (value) {
            const exam = this.options.context as MainTrainingExamFormik | undefined;
            return exam?.questions.filter(e => e.question?.trim().toLowerCase() === value?.trim().toLowerCase()).length === 1;
        }),

    description: Yup.string()
        .trim()
        .lowercase()
        .max(1000, 'Description cannot be longer than 1000 characters'),
    type: Yup.string()
        .oneOf(Object.values(TrainingExamQuestionType))
        .required('Question type is required'),
    points: Yup.number()
        .required('Points are required')
        .min(1, 'Add at least one correct answer'),
    pointsAutoCount: Yup.boolean(),
    order: Yup.number(),
    options: Yup.array()
        .of(optionSchema)
        .when('type', {
            is: (type: TrainingExamQuestionType) => type === TrainingExamQuestionType.Checkboxes || type === TrainingExamQuestionType.MultipleChoice,
            then: Yup.array()
                .min(1, 'At least one option is required')
        }),
});

export const questionsSchema = Yup.object({
    questions: Yup.array()
        .of(questionSchema)
        .min(1, 'At least one question is required'),
});

const createExamCoverImageSchema = Yup.object({
    fileType: Yup.string().nullable(),
    image: Yup.string(),
    imageName: Yup.string(),
    thumbnail: Yup.string(),
    type: Yup.string()
        .oneOf(Object.values(MainTrainingCoverImageType)),
});

export const createExamNameSchema = Yup.object({
    name: Yup.string()
        .trim()
        .lowercase()
        .required('Exam name is required')
        .max(120, 'Exam name cannot be longer than 120 characters'),
    description: Yup.string()
        .trim()
        .lowercase()
        .max(1000, 'Description cannot be longer than 1000 characters'),
    category: Yup.string()
        .typeError('Exam category is required')
        .required('Exam category is required')
        .trim()
        .lowercase()
        .min(1, 'Exam category is required')
    ,

    coverImage: createExamCoverImageSchema
        .nullable(),
});

export const createExamPassPointsSchema = Yup.object({
    timeToPass: Yup.number()
        .required('Time to pass the exam is required')
        .min(0, 'Time to pass the exam must be more positive number'),
    totalPoints: Yup.number()
        .min(1, 'Total points must be at least 1'),
    examPassingPoints: Yup.number()
        .required('Exam passing points are required')
        .min(1, 'Passing points must be at least 1')
        .max(Yup.ref('totalPoints'), 'Passing points cannot be greater than total points'),
});

export const createExamSchema = Yup.object({
    status: Yup.string()
        .required('Status is required')
        .oneOf(Object.values(MainTrainingExamStatus)),

    level: Yup.string()
        .required('Exam level is required')
        .oneOf(Object.values(TrainingExamLevel)),

    ...createExamNameSchema.fields,

    ...createExamPassPointsSchema.fields,

    ...questionsSchema.fields,

    version: Yup.number(),
    latest: Yup.boolean(),

    createdDate: Yup.string(),
    updatedDate: Yup.string(),

    creator: Yup.object(),
    editor: Yup.object().nullable(),

    id: Yup.string(),
    examId: Yup.string(),
    workspaceId: Yup.string(),
});
