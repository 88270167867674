import {Handle, Position} from "react-flow-renderer";
import {ALLOWDRAG} from "../../../constants";
import {useDispatch, useSelector} from "react-redux";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {deleteEvidence, selectedAutomation} from '../../../store/slice';
import {shortString} from "../../../helpers";
import {automationType} from "../../../types";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {Typo} from "../../../../../../newShared/components/Typography";

type saveChildNodeProps = {
    text: string,
    id: string,
    revDir: boolean,
}

export const SaveChildNode = ({data} : {data: saveChildNodeProps}) => {
    const {revDir} = data;
    const dispatch = useDispatch();

    const auto: automationType | null = useSelector(selectedAutomation);

    const deleteSelf = () => {
        if(auto && auto.evidence.data.length > 1){
            dispatch(deleteEvidence(data.id));
        }
    }

    return(
        <>
            <Flex
                w={'140px'}
                h={'60px'}
                br={'11px'}
                background={'#3BCC89'}
                box_shadow={'0px 0px 5.853034973144531px 0px rgba(0, 0, 0, 0.12)'}
                // p={'5px 30px'}
                ai={'center'}
                jc={'center'}
                className={ALLOWDRAG ? 'nodrag': ''}
                cursor={'pointer'}
                overflow={'hidden'}
            >
                <Handle
                    type="target"
                    position={!revDir ? Position.Left : Position.Right}
                    id="doc-left-target"
                    isConnectable={false}
                    style={{opacity: '0'}}
                />

                <Typo fontWeight={400} fontSize={'12px'}  align={'center'}>{shortString(data.text, 40)}</Typo>
            </Flex>
            {(auto && auto.evidence.data.length > 1) &&
            <Flex style={{position: 'relative', bottom: '40px', left: !revDir ? '145px' : '-35px'}} className={'nodrag'}>
                <DeleteOutlinedIcon
                    style={{width: '20px', height: '20px', color: '#5D5D5D', cursor: 'pointer'}}
                    onClick={deleteSelf}
                />
            </Flex>
            }
        </>
    )
}