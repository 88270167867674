import axiosSync from "../../../../newShared/utils/axios/axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {AxiosResponse} from "axios";
import {print} from "graphql";
import {gql} from "@apollo/react-hooks";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {
    AddBuilderFrameworkMutation,
    AddBuilderFrameworkMutationVariables,
    AddControlMutation,
    AddControlMutationVariables,
    AddEvidenceMutation,
    AddEvidenceMutationVariables,
    ChangeFrameworkNameMutation,
    ChangeFrameworkNameMutationVariables,
    ChangeFrameworkVisibilityMutation,
    ChangeFrameworkVisibilityMutationVariables,
    DeattachControlMutation,
    DeattachControlMutationVariables,
    DeattachEvidenceMutation,
    DeattachEvidenceMutationVariables,
    DeleteControlMutation,
    DeleteControlMutationVariables,
    DeleteEvidenceMutation,
    DeleteEvidenceMutationVariables,
    DeleteFrameworkMutation,
    DeleteFrameworkMutationVariables,
    EditControlMutation,
    EditControlMutationVariables,
    EditEvidenceMutation,
    EditEvidenceMutationVariables,
    GetAllControlsDialogDataQuery,
    GetAllControlsDialogDataQueryVariables,
    GetAllEvidecnesDialogDataQuery,
    GetAllEvidecnesDialogDataQueryVariables,
    GetBuilderDataQuery,
    GetCompanyControlsDialogDataQuery,
    GetCompanyControlsDialogDataQueryVariables,
    GetCompanyEvidencesDialogDataQuery,
    GetCompanyEvidencesDialogDataQueryVariables,
    GetControlsByFrameworkIdQuery,
    GetControlsByFrameworkIdQueryVariables,
    GetPrivateControlsDialogDataQuery,
    GetPrivateControlsDialogDataQueryVariables,
    GetPrivateEvidencesDialogDataQuery,
    GetPrivateEvidencesDialogDataQueryVariables,
    GetPublicAndPrivateControlsDialogDataQueryVariables,
    GetPublicAndPrivateEvidencesDialogDataQuery,
    GetPublicAndPrivateEvidencesDialogDataQueryVariables,
    GetPublicControlsDialogDataQuery,
    GetPublicControlsDialogDataQueryVariables,
    GetPublicEvidencesDialogDataQuery,
    GetPublicEvidencesDialogDataQueryVariables,
    LinkControlMutation,
    LinkControlMutationVariables,
    LinkEvidenceMutation,
    LinkEvidenceMutationVariables,
    UpdateCustomFrameworkMutation,
    UpdateCustomFrameworkMutationVariables
} from "../../../../newShared/GQLTypes";

export const getBuilderData = async (organizationId: string, signal?: AbortSignal): Promise<GetBuilderDataQuery["getBuilderData"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getBuilderData($organizationId: String!){
                        getBuilderData(organizationId: $organizationId){
                            frameworks{
                                id
                                name
                                logo
                                type
                                description
                                origin
                                visibility
                                createdBy
                                organizationId
                                controls
                                evidences
                            }
                            allControlsCounter
                            allEvidencesCounter
                            allPoliciesCounter
                        }
                    }
                `),
                variables: {
                    organizationId
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getBuilderData;
    })
)
export const updateCustomFramework = async (data: UpdateCustomFrameworkMutationVariables, signal?: AbortSignal): Promise<UpdateCustomFrameworkMutation["updateCustomFramework"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation updateCustomFramework($organizationId: String!, $workspaceId: String!, $framework: BuilderFrameworkInput!){
                        updateCustomFramework(organizationId: $organizationId, workspaceId: $workspaceId, framework: $framework){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal,
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateCustomFramework;
    })
)

export const changeFrameworkName = async (data: ChangeFrameworkNameMutationVariables, signal?: AbortSignal): Promise<ChangeFrameworkNameMutation["changeFrameworkName"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation changeFrameworkName($organizationId: String!, $data: ChangeFrameworkNameInput!){
                        changeFrameworkName(organizationId: $organizationId, data: $data){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal,
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.changeFrameworkName;
    })
)

export const changeFrameworkVisibility = async (data: ChangeFrameworkVisibilityMutationVariables, signal?: AbortSignal): Promise<ChangeFrameworkVisibilityMutation["changeFrameworkVisibility"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation changeFrameworkVisibility($organizationId: String!, $data: ChangeFrameworkVisibilityInput!){
                        changeFrameworkVisibility(organizationId: $organizationId, data: $data){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal,
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.changeFrameworkVisibility;
    })
)

export const deleteFramework = async (data: DeleteFrameworkMutationVariables, signal?: AbortSignal): Promise<DeleteFrameworkMutation["deleteFramework"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deleteFramework($organizationId:String!, $data:String!){
                        deleteFramework(organizationId:$organizationId, data:$data){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal,
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteFramework;
    })
)

export const getControls = async (data: GetControlsByFrameworkIdQueryVariables, signal?: AbortSignal): Promise<GetControlsByFrameworkIdQuery["getControlsByFrameworkId"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getControlsByFrameworkId($organizationId:String!, $data:String!){
                        getControlsByFrameworkId(organizationId:$organizationId, data:$data){
                            name
                            category
                            description
                            origin
                            organizationId
                            visibility
                            group
                            id
                            evidences{
                                categories
                                collectorId
                                description
                                id
                                name
                                origin
                                organizationId
                                visibility
                                foundInControlsCounter
                            }
                            foundInFrameworksCounter
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getControlsByFrameworkId;
    })
)

export const addControl = async (data: AddControlMutationVariables, signal?: AbortSignal): Promise<AddControlMutation["addBuilderControl"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation addControl($organizationId:String!, $data:ControlInput!){
                        addBuilderControl(organizationId:$organizationId, data:$data){
                            name
                            category
                            description
                            origin
                            organizationId
                            visibility
                            group
                            id
                            foundInFrameworksCounter
                            evidences{
                                categories
                                collectorId
                                description
                                id
                                name
                                origin
                                organizationId
                                visibility
                                foundInControlsCounter
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addBuilderControl;
    })
)

export const editControl = async (data: EditControlMutationVariables, signal?: AbortSignal): Promise<EditControlMutation["editBuilderControl"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation editControl($organizationId:String!, $data:EditControlInput!){
                        editBuilderControl(organizationId:$organizationId, data:$data){
                            name
                            category
                            description
                            origin
                            organizationId
                            visibility
                            group
                            id
                            foundInFrameworksCounter
                            evidences{
                                categories
                                collectorId
                                description
                                id
                                name
                                origin
                                organizationId
                                visibility
                                foundInControlsCounter
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal,
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.editBuilderControl;
    })
)

export const deattachControl = async (data: DeattachControlMutationVariables, signal?: AbortSignal): Promise<DeattachControlMutation["unlinkControl"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deattachControl($organizationId:String!, $data: UnlinkControlInput!){
                        unlinkControl(organizationId:$organizationId, data:$data){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal,
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.unlinkControl;
    })
)

export const addEvidence = async (data: AddEvidenceMutationVariables, signal?: AbortSignal): Promise<AddEvidenceMutation["addBuilderEvidence"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation addEvidence($organizationId:String!, $data:AddEvidenceInput!){
                        addBuilderEvidence(organizationId:$organizationId, data:$data){
                            categories
                            collectorId
                            description
                            id
                            name
                            origin
                            organizationId
                            visibility
                            foundInControlsCounter
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal,
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addBuilderEvidence;
    })
)


export const editEvidence = async (data: EditEvidenceMutationVariables, signal?: AbortSignal): Promise<EditEvidenceMutation["editBuilderEvidence"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation editEvidence($organizationId:String!, $data:EvidenceInput!){
                        editBuilderEvidence(organizationId:$organizationId, data:$data){
                            categories
                            collectorId
                            description
                            id
                            name
                            origin
                            organizationId
                            visibility
                            foundInControlsCounter
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal,
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.editBuilderEvidence;
    })
)

export const deattachEvidence = async (data: DeattachEvidenceMutationVariables, signal?: AbortSignal): Promise<DeattachEvidenceMutation["unlinkEvidence"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deattachEvidence($organizationId:String!, $data: UnlinkEvidenceInput!){
                        unlinkEvidence(organizationId:$organizationId, data:$data){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal,
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.unlinkEvidence;
    })
)

export const getAllControlsDialogData = async (data: GetAllControlsDialogDataQueryVariables, signal?: AbortSignal): Promise<GetAllControlsDialogDataQuery["getAllControlsDialogData"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getAllControlsDialogData($organizationId:String!, $data:PaginationInput!, $name:String!){
                        getAllControlsDialogData(organizationId:$organizationId, data:$data, name:$name){
                            controls{
                                category
                                description
                                group
                                id
                                name
                                origin
                                organizationId
                                visibility
                                foundInFrameworksCounter
                                evidences{
                                    categories
                                    collectorId
                                    description
                                    id
                                    name
                                    origin
                                    organizationId
                                    visibility
                                    foundInControlsCounter
                                }
                            }
                            pageInfo{
                                page
                                count
                                total
                                sort
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAllControlsDialogData;
    })
);

export const getRegulaitControlsDialogData = async (data: GetCompanyControlsDialogDataQueryVariables, signal?: AbortSignal): Promise<GetCompanyControlsDialogDataQuery["getCompanyControlsDialogData"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getCompanyControlsDialogData($organizationId:String!, $data:PaginationInput!, $name:String!){
                        getCompanyControlsDialogData(organizationId:$organizationId, data:$data, name:$name){
                            controls{
                                category
                                description
                                group
                                id
                                name
                                origin
                                organizationId
                                visibility
                                foundInFrameworksCounter
                                evidences{
                                    categories
                                    collectorId
                                    description
                                    id
                                    name
                                    origin
                                    organizationId
                                    visibility
                                    foundInControlsCounter
                                }
                            }
                            pageInfo{
                                page
                                count
                                total
                                sort
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getRegulaitControlsDialogData;
    })
);

export const getPrivateControlsDialogData = async (data: GetPrivateControlsDialogDataQueryVariables, signal?: AbortSignal): Promise<GetPrivateControlsDialogDataQuery["getPrivateControlsDialogData"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getPrivateControlsDialogData($organizationId:String!, $data:PaginationInput!, $name:String!){
                        getPrivateControlsDialogData(organizationId:$organizationId, data:$data, name:$name){
                            controls{
                                category
                                description
                                group
                                id
                                name
                                origin
                                organizationId
                                visibility
                                foundInFrameworksCounter
                                evidences{
                                    categories
                                    collectorId
                                    description
                                    id
                                    name
                                    origin
                                    organizationId
                                    visibility
                                    foundInControlsCounter
                                }
                            }
                            pageInfo{
                                page
                                count
                                total
                                sort
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getPrivateControlsDialogData;
    })
);

export const getPublicControlsDialogData = async (data: GetPublicControlsDialogDataQueryVariables, signal?: AbortSignal): Promise<GetPublicControlsDialogDataQuery["getPublicControlsDialogData"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getPublicControlsDialogData($organizationId:String!, $data:PaginationInput!, $name:String!){
                        getPublicControlsDialogData(organizationId:$organizationId, data:$data, name:$name){
                            controls{
                                category
                                description
                                group
                                id
                                name
                                origin
                                organizationId
                                visibility
                                foundInFrameworksCounter
                                evidences{
                                    categories
                                    collectorId
                                    description
                                    id
                                    name
                                    origin
                                    organizationId
                                    visibility
                                    foundInControlsCounter
                                }
                            }
                            pageInfo{
                                page
                                count
                                total
                                sort
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getPublicControlsDialogData;
    })
);

export const getPublicAndPrivateControlsDialogData = async (data: GetPublicAndPrivateControlsDialogDataQueryVariables, signal?: AbortSignal): Promise<GetPublicControlsDialogDataQuery["getPublicControlsDialogData"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getPublicAndPrivateControlsDialogData($organizationId:String!, $data:PaginationInput!, $name:String!){
                        getPublicAndPrivateControlsDialogData(organizationId:$organizationId, data:$data, name:$name){
                            controls{
                                category
                                description
                                group
                                id
                                name
                                origin
                                organizationId
                                visibility
                                foundInFrameworksCounter
                                evidences{
                                    categories
                                    collectorId
                                    description
                                    id
                                    name
                                    origin
                                    organizationId
                                    visibility
                                    foundInControlsCounter
                                }
                            }
                            pageInfo{
                                page
                                count
                                total
                                sort
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getPublicAndPrivateControlsDialogData;
    })
);

export const linkControl = async (data: LinkControlMutationVariables, signal?: AbortSignal): Promise<LinkControlMutation["linkControl"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation linkControl($organizationId:String!, $data:UnlinkControlInput!){
                        linkControl(organizationId:$organizationId, data:$data){
                            name
                            category
                            description
                            origin
                            organizationId
                            visibility
                            group
                            id
                            foundInFrameworksCounter
                            evidences{
                                categories
                                collectorId
                                description
                                id
                                name
                                origin
                                organizationId
                                visibility
                                foundInControlsCounter
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal,
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.linkControl;
    })
);


export const getAllEvidecnesDialogData = async (data: GetAllEvidecnesDialogDataQueryVariables, signal?: AbortSignal): Promise<GetAllEvidecnesDialogDataQuery["getAllEvidencesDialogData"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getAllEvidecnesDialogData($organizationId:String!, $data:PaginationInput!, $name:String!){
                        getAllEvidencesDialogData(organizationId:$organizationId, data:$data, name:$name){
                            evidences{
                                categories
                                collectorId
                                description
                                id
                                name
                                origin
                                organizationId
                                visibility
                                foundInControlsCounter
                            }
                            pageInfo{
                                page
                                total
                                sort
                                count
                            }
                        }
                    }

                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAllEvidencesDialogData;
    })
);


export const getRegulaitEvidencesDialogData = async (data: GetCompanyEvidencesDialogDataQueryVariables, signal?: AbortSignal): Promise<GetCompanyEvidencesDialogDataQuery["getCompanyEvidencesDialogData"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getCompanyEvidencesDialogData($organizationId:String!, $data:PaginationInput!, $name:String!){
                        getCompanyEvidencesDialogData(organizationId:$organizationId, data:$data, name:$name){
                            evidences{
                                categories
                                collectorId
                                description
                                id
                                name
                                origin
                                organizationId
                                visibility
                                foundInControlsCounter
                            }
                            pageInfo{
                                page
                                total
                                sort
                                count
                            }
                        }
                    }

                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getRegulaitEvidencesDialogData;
    })
);

export const getPrivateEvidencesDialogData = async (data: GetPrivateEvidencesDialogDataQueryVariables, signal?: AbortSignal): Promise<GetPrivateEvidencesDialogDataQuery["getPrivateEvidencesDialogData"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getPrivateEvidencesDialogData($organizationId:String!, $data:PaginationInput!, $name:String!){
                        getPrivateEvidencesDialogData(organizationId:$organizationId, data:$data, name:$name){
                            evidences{
                                categories
                                collectorId
                                description
                                id
                                name
                                origin
                                organizationId
                                visibility
                                foundInControlsCounter
                            }
                            pageInfo{
                                page
                                total
                                sort
                                count
                            }
                        }
                    }

                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getPrivateEvidencesDialogData;
    })
);

export const getPublicEvidencesDialogData = async (data: GetPublicEvidencesDialogDataQueryVariables, signal?: AbortSignal): Promise<GetPublicEvidencesDialogDataQuery["getPublicEvidencesDialogData"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getPublicEvidencesDialogData($organizationId:String!, $data:PaginationInput!, $name:String!){
                        getPublicEvidencesDialogData(organizationId:$organizationId, data:$data, name:$name){
                            evidences{
                                categories
                                collectorId
                                description
                                id
                                name
                                origin
                                organizationId
                                visibility
                                foundInControlsCounter
                            }
                            pageInfo{
                                page
                                total
                                sort
                                count
                            }
                        }
                    }

                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getPublicEvidencesDialogData;
    })
);

export const getPublicAndPrivateEvidencesDialogData = async (data: GetPublicAndPrivateEvidencesDialogDataQueryVariables, signal?: AbortSignal): Promise<GetPublicAndPrivateEvidencesDialogDataQuery["getPublicAndPrivateEvidencesDialogData"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getPublicAndPrivateEvidencesDialogData($organizationId:String!, $data:PaginationInput!, $name:String!){
                        getPublicAndPrivateEvidencesDialogData(organizationId:$organizationId, data:$data, name:$name){
                            evidences{
                                categories
                                collectorId
                                description
                                id
                                name
                                origin
                                organizationId
                                visibility
                                foundInControlsCounter
                            }
                            pageInfo{
                                page
                                total
                                sort
                                count
                            }
                        }
                    }

                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getPublicAndPrivateEvidencesDialogData;
    })
);

export const linkEvidence = async (data: LinkEvidenceMutationVariables, signal?: AbortSignal): Promise<LinkEvidenceMutation["linkEvidence"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation linkEvidence($organizationId:String!, $data:UnlinkEvidenceInput!){
                        linkEvidence(organizationId:$organizationId, data:$data){
                            categories
                            collectorId
                            description
                            id
                            name
                            origin
                            organizationId
                            visibility
                            foundInControlsCounter
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal,
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.linkEvidence;
    })
);

export const addFramework = async (data: AddBuilderFrameworkMutationVariables, signal?: AbortSignal): Promise<AddBuilderFrameworkMutation["addFramework"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation addBuilderFramework($organizationId:String!, $data:AddFrameworkInput!){
                        addFramework(organizationId:$organizationId, data:$data){
                            id
                            name
                            description
                            type
                            origin
                            logo
                            organizationId
                            controls
                            evidences
                            visibility
                            createdBy
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal,
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addFramework;
    })
);

export const deleteControl = async (data: DeleteControlMutationVariables, signal?: AbortSignal): Promise<DeleteControlMutation["deleteControl"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deleteControl($organizationId:String!, $data:String!){
                        deleteControl(organizationId:$organizationId, data:$data){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal,
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteControl;
    })
);

export const deleteEvidence = async (data: DeleteEvidenceMutationVariables, signal?: AbortSignal): Promise<DeleteEvidenceMutation["deleteEvidence"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deleteEvidence($organizationId:String!, $data:String!){
                        deleteEvidence(organizationId:$organizationId, data:$data){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal,
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteEvidence;
    })
);

// export const getPrivatePolicies = async (data: GetPrivatePoliciesQueryVariables, signal?: AbortSignal): Promise<GetPrivatePoliciesQuery["getPrivatePolicies"]> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: print(gql`
//                     query getPrivatePolicies($organizationId:String!, $data:PaginationInput!){
//                         getPrivatePolicies(organizationId:$organizationId, data:$data){
//                             policies{
//                                 name
//                                 description
//                                 templates{
//                                     fileId
//                                     fileName
//                                 }
//                                 editableTemplates {
//                                     name
//                                     id
//                                     innerHTML
//                                 }
//                                 id
//                                 tag
//                                 origin
//                                 organizationId
//                                 visibility
//                                 pipeLinesCounter
//                                 frameworks {
//                                     name
//                                     logo
//                                 }
//                             }
//                             pageInfo{
//                                 page
//                                 count
//                                 total
//                                 sort
//                             }
//                         }
//                     }
//                 `),
//                 variables: data
//             },
//             {
//                 headers: buildCommonHeader(),
//                 signal
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.getPrivatePolicies;
//     })
// );
//
//
//
// export const addPolicy = async (data: AddCustomPolicyMutationVariables, signal?: AbortSignal): Promise<AddCustomPolicyMutation["addPolicy"]> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: print(gql`
//                     mutation addCustomPolicy($organizationId:String!, $data:AddPolicyInput!){
//                         addPolicy(organizationId:$organizationId, data:$data){
//                             name
//                             description
//                             id
//                             templates{
//                                 fileId
//                                 fileName
//                             }
//                             editableTemplates {
//                                 name
//                                 id
//                                 innerHTML
//                             }
//                             organizationId
//                             origin
//                             tag
//                             visibility
//                             pipeLinesCounter
//                             configuration
//                             frameworks {
//                                 name
//                                 logo
//                             }
//                         }
//                     }
//                 `),
//                 variables: data
//             },
//             {
//                 headers: buildCommonHeader(),
//                 signal,
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.addPolicy;
//     })
// );
//
//
// export const updatePolicy = async (data: UpdateCustomPolicyMutationVariables, signal?: AbortSignal): Promise<UpdateCustomPolicyMutation["updatePolicy"]> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: print(gql`
//                     mutation updateCustomPolicy($organizationId:String!, $data:PolicyInput!){
//                         updatePolicy(organizationId:$organizationId, data:$data){
//                             name
//                             description
//                             id
//                             templates{
//                                 fileId
//                                 fileName
//                             }
//                             editableTemplates {
//                                 name
//                                 id
//                                 innerHTML
//                             }
//                             organizationId
//                             origin
//                             tag
//                             visibility
//                             pipeLinesCounter
//                             configuration
//                             frameworks {
//                                 name
//                                 logo
//                             }
//                         }
//                     }
//                 `),
//                 variables: data
//             },
//             {
//                 headers: buildCommonHeader(),
//                 signal,
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.updatePolicy;
//     })
// );
//
//
// export const deletePolicy = async (data: DeletePolicyMutationVariables, signal?: AbortSignal): Promise<DeletePolicyMutation["deletePolicy"]> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: print(gql`
//                     mutation deletePolicy($organizationId:String!, $data:String!){
//                         deletePolicy(organizationId:$organizationId, data:$data){
//                             message
//                         }
//                     }
//                 `),
//                 variables: data
//             },
//             {
//                 headers: buildCommonHeader(),
//                 signal,
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.deletePolicy;
//     })
// );
//
// export const uploadFile = async (data: UploadFileOrgMutationVariables, signal?: AbortSignal): Promise<UploadFileOrgMutation["uploadFileORG"]> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: print(gql`
//                     mutation uploadFileORG($organizationId:String!, $file:String!){
//                         uploadFileORG(organizationId:$organizationId, file:$file){
//                             message
//                         }
//                     }
//
//                 `),
//                 variables: data
//             },
//             {
//                 headers: buildCommonHeader(),
//                 signal,
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.uploadFileORG;
//     })
// )
//
// export const deleteAllFiles = async (data: DeleteAllFileOrgMutationVariables, signal?: AbortSignal): Promise<DeleteAllFileOrgMutation["deleteAllFileORG"]> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: print(gql`
//                     mutation deleteAllFileORG($organizationId:String!, $fileIds:DeleteAllFilesInput!){
//                         deleteAllFileORG(fileIds:$fileIds, organizationId:$organizationId){
//                             message
//                         }
//                     }
//                 `),
//                 variables: data
//             },
//             {
//                 headers: buildCommonHeader(),
//                 signal,
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return {message: 'done'};
//     })
// )
