import {useDeleteTemplate} from "../../../hooks/dialogs/useDeleteTemplate";
import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {LoadingButton} from "@mui/lab";
import DownloadIcon from '@mui/icons-material/Download';
import {LocalSpinner} from "../../../../../../newShared/components/Spinner";
import FormControlLabel from "@mui/material/FormControlLabel";
import colors from "../../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const DeleteTemplateDialog = () => {
    const {isOpen, handleClose, template,
        clientsCount, step, isOk, handleDeleteTemplate,
        isLoadingDelete,
        isLoadingDownloadCsv, isLoadingDeleteData,
        handleOpenExportCsv,
        getDialogTitle,
        isDisabledDelete, setIsDisabledDelete
    } = useDeleteTemplate();
    const {t} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.dialogs'});


    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle variant={'*bodyText1_semibold'}>{getDialogTitle()}</DialogTitle>
            <DialogContent>
                {isLoadingDeleteData ?
                    <LocalSpinner />
                    :
                    <>
                        {(step === 'noClientsOrNoArchivedClients' || step === 'allTargetsArchived') &&
                            <Flex direction={'column'} gap={'8px'}>
                                <Typography variant={'*bodyText2'}>
                                    {t(`Are you sure you want to delete template `)}
                                    <b style={{fontWeight: 600}}>{template?.name}</b>
                                    {(` ?`)}
                                </Typography>

                                {step === 'allTargetsArchived' &&
                                    <Typography variant={'*bodyText2'}>
                                        {t(`Deleting it will result in the permanent removal of all archived clients associated with this template.`)}
                                    </Typography>
                                }
                            </Flex>
                        }

                        {step === 'containsNonArchivedClients' &&
                            <Flex direction={'column'} gap={'8px'}>
                                <Typography variant={'*bodyText2'}>
                                    {t(`Template`)}
                                    <b style={{fontWeight: 600}}>{` ${template?.name} `}</b>
                                    {t(`contains`)}
                                    <b style={{fontWeight: 600}}>{` ${clientsCount} clients. `}</b>
                                    {t('Deleting the template will result in the permanent deletion of all associated clients.')}
                                </Typography>

                                {clientsCount < 10000 ?
                                    <Typography variant={'*bodyText2_semibold'}>{t('We recommend exporting a CSV file before proceeding with template deletion. This file will allow you to import the data using another template in the future.')}</Typography>
                                    :
                                    <Typography variant={'*bodyText2_semibold'}>{t('We recommend exporting a CSV file before proceeding with template deletion. Upon exporting the file, it will be sent to your email address. This file will enable you to import the data using another template in the future.')}</Typography>
                                }

                                    <FormControlLabel
                                    control={<Checkbox checked={!isDisabledDelete} />}
                                    onClick={() => setIsDisabledDelete(!isDisabledDelete)}
                                    label={<Typography color={colors.grayText} variant={'*bodyText2'}>{t('I understand that deleting the template will permanently remove all associated clients.')}</Typography>}
                                />
                            </Flex>
                        }
                    </>
                }
            </DialogContent>

            <DialogActions>
                {(step === 'noClientsOrNoArchivedClients' || step === 'allTargetsArchived') &&
                    <>
                        <Button onClick={handleClose} size={'small'} variant={'text'} sx={{textTransform: 'none'}}>{t('Cancel')}</Button>
                        <LoadingButton disabled={!isOk()}
                                       size={'small'}
                                       variant={'contained'}
                                       onClick={handleDeleteTemplate}
                                       loading={isLoadingDelete }
                                       loadingPosition={isLoadingDelete ? 'end' : undefined}
                        >{t('Delete')}</LoadingButton>
                    </>
                }

                {step === 'containsNonArchivedClients' &&
                    <>
                        <Button onClick={handleClose} size={'small'} variant={'text'} sx={{textTransform: 'none'}}>{t('Cancel')}</Button>

                        <LoadingButton
                            variant={'text'}
                            onClick={handleOpenExportCsv}
                            loading={isLoadingDownloadCsv}
                            startIcon={<DownloadIcon />}
                            sx={{textTransform: 'none'}}
                            size={'small'}
                        >
                            {t('Export CSV')}
                        </LoadingButton>

                        <LoadingButton disabled={!isOk()}
                                       size={'small'}
                                       variant={'contained'}
                                       onClick={handleDeleteTemplate}
                                       loading={isLoadingDelete}
                                       loadingPosition={isLoadingDelete ? 'end' : undefined}
                        >{t('Delete')}</LoadingButton>
                    </>
                }
                </DialogActions>
        </Dialog>
    )
}
