import {Box, styled} from "@mui/material";
import colors from "../../../../../theme/colors";

type EditorDragCreateBlockItemStyledProps = {
    isDragging?: boolean;
}
export const EditorDragCreateBlockItemStyled = styled(Box, {shouldForwardProp: (propName) => (
    !['isDragging'].includes(propName.toString())
)})<EditorDragCreateBlockItemStyledProps>(({isDragging}) => ({
    flexShrink: 0,
    overflow: 'hidden',
    backgroundColor: colors.backgrounds.grey_light2,
    boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)',
    borderRadius: '8px',
    border: `1px solid ${colors.stroke.grey}`,
    opacity: isDragging ? 0.5 : 1,
    gap: '8px',
    height: 'fit-content',
    display: 'flex', alignItems: 'center',
    padding: '10px',
    minWidth: 'min(130px, 100%)',
    color: colors.text.grey_dark,
    '&:hover': {
        color: colors.primary.blue,
        boxShadow: '0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13)'
    },

    '& span': {
        fontWeight: 600,
        fontSize: '13px',
        letterSpacing: '0.46px',
        color: colors.text.dark,
    },
}))