import {styled, TextField} from "@mui/material";
import {TypographyBody1} from "../../../../../../../newShared/components/Inputs/styled";

export const TextDialog = styled(TypographyBody1)(() => ({
    marginTop: '16px'
}));

export const ExceptionReasonTextField = styled(TextField)(() => ({
    marginTop: '16px',
}));

ExceptionReasonTextField.defaultProps = {
    multiline: true,
    minRows: 3,
}

export const ScoreLimitTextField = styled(TextField)(() => ({
    width: '80px',
    margin: '0 10px',
}));

ScoreLimitTextField.defaultProps = {
    size: 'small',
    type: 'number',
    InputProps: {inputProps: {type: 'number',  min: 0, max: 1, step: 0.01 }},
}

