import React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {Flex} from "../../../Layouts";
import colors from "../../../../theme/colors";
import {Popper} from "@mui/material";

export const MiniDialog = ({onClose, isOpen, children, id, elAnchor}: {
    onClose: () => void,
    isOpen: boolean,
    children: React.ReactNode | React.ReactNode[],
    id: string | undefined,
    elAnchor: HTMLElement | null
}) => {

    return(
        <>
            {isOpen &&
                <ClickAwayListener onClickAway={onClose}>
                    <Popper id={id} open={isOpen} anchorEl={elAnchor} sx={{zIndex: 1005}} placement="bottom-start">
                        <Flex
                            onClick={(e) => e.stopPropagation()}
                            // top={'37px'}
                            // left={getLeft()}
                            // right={getRight()}
                            // position={'absolute'}
                            w={'270px'}
                            border={`1px solid ${colors.stroke.grey}`}
                            br={'4px'}
                            style={{filter: `drop-shadow(0px 0.6px 1.8px rgba(0, 0, 0, 0.1)) drop-shadow(0px 3.2px 7.2px rgba(0, 0, 0, 0.13))`}}
                            background={colors.backgrounds.white}
                            direction={'column'}
                            zi={'1005'}
                            p={'8px'}
                            css={`& input::placeholder{ font-size: 14px; color: ${colors.text.grey_dark}; font-weight: 400; }`}
                            gap={'8px'}
                        >
                            {children}
                        </Flex>
                    </Popper>
                </ClickAwayListener>
            }
        </>
    )
}
