import React from "react";
import {PageWrapper} from "../../../../../newShared/components/style";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useAssignmentExactPage} from "../../hooks/useAssignmentExactPage";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {Box, Divider, Skeleton, Typography} from "@mui/material";
import * as S from "../../components/styled";
import {AssignmentTrainingCard} from "../../components/styled";
import {getAssignmentTrainingAndExamStatusChip, getTrainingAssignmentStatusChip} from "../../helpers";
import colors from "../../../../../newShared/theme/colors";
import {AccessTime, AllInclusive, CheckCircleOutlined, HelpOutline} from "@mui/icons-material";
import {PATH_TRAININGS_ASSIGNMENTS} from "../../constants";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {
    MainTrainingProgressStatus,
    TrainingAssignmentObjectStatus,
    TrainingAssignmentStatus
} from "../../../../../newShared/GQLTypes";
import {CancelAssignmentDialog} from "../../components/dialogs/cancelAssignment";
import {ExamPreview} from "../../components/dialogs/examPreview";
import {TrainingPreviewDialog} from "../../components/dialogs/trainingPreview";
import {AssignmentHistory} from "../../components/dialogs/assignmentHistory";
import {CoverImageView} from "../../components/trainingCoverComponent/CoverImageView";
import {ExamResultDialog} from "../../components/dialogs/examResultDialog";
import {ValidateExamAnswersDialog} from "../../components/dialogs/validateExamAnswersDialog";


export const AssignmentExact = () => {

    const {t} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.pathAssignments'});
    const {isLessThen1050, isTablet} = useMedia();

    const {
        isLoadingExact,
        assignment,
        actions,
        refreshAssignment
    } = useAssignmentExactPage();

    const getValidationPoints = () => {
        let totalPoints: number = 0;
        assignment?.postExam?.questions && assignment.postExam.questions.forEach(e => {
            if (e.validation && e.validation.points) totalPoints += e.validation.points || 0
        });
        return totalPoints;
    }


    return (
        <PageWrapper>
            <PageTitleAndActions title={assignment ? `${t('Assignment ID')} ${assignment.id}` : <Skeleton width={'200px'} />}
                                 handleGoBack={PATH_TRAININGS_ASSIGNMENTS}
                                 actions={[
                                     {title: t('Cancel assignment'), onClick: actions.handleOpenCancel, size: "small", variant: 'outlined', dontShowWhen: isLoadingExact || (assignment?.status !== TrainingAssignmentStatus.Assigned && assignment?.status !== TrainingAssignmentStatus.Scheduled)},
                                     {title: t('View assignment history'), onClick: actions.handleViewHistory, size: "small", variant: 'outlined', dontShowWhen: isLoadingExact || assignment?.status === TrainingAssignmentStatus.Assigned || assignment?.status === TrainingAssignmentStatus.Scheduled},
                                     {title: t('View training'), onClick: actions.handleViewTraining, size: "small", variant: 'outlined', dontShowWhen: isLoadingExact || !assignment?.training},
                                     // {title: t('View exam'), onClick: actions.handleViewExam, size: "small", variant: 'outlined', dontShowWhen: isLoadingExact || !assignment?.postExam || assignment?.status === TrainingAssignmentStatus.Validation || assignment?.status === TrainingAssignmentStatus.Failed || assignment?.status === TrainingAssignmentStatus.Passed},
                                     {title: t('Validate'), onClick: actions.handleValidate, size: "small", variant: 'outlined', dontShowWhen: isLoadingExact || assignment?.status !== TrainingAssignmentStatus.Validation || assignment?.postExam?.status !== TrainingAssignmentObjectStatus.Validation},
                                     {title: t('Exam result'), onClick: actions.handleViewExamResult, size: "small", variant: 'outlined', dontShowWhen: isLoadingExact || !(assignment?.status === TrainingAssignmentStatus.Passed || assignment?.status === TrainingAssignmentStatus.Failed)},
                                 ]} />

            <S.AssignmentExactHeader>
                <S.AssignmentExactHeaderElement>
                    {assignment ? <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{t('Requestor')}</Typography> : <Skeleton width={'150px'} />}
                    {assignment ? <Typography variant={'subtitle2'}>{assignment.requestor.fullName}</Typography> : <Skeleton width={'200px'} /> }
                </S.AssignmentExactHeaderElement>

                {assignment &&
                    <>
                        <S.AssignmentExactHeaderElement>
                            <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{t('Recipient')}</Typography>
                            <Typography variant={'subtitle2'}>{assignment.recipient.fullName}</Typography>
                        </S.AssignmentExactHeaderElement>

                        <S.AssignmentExactHeaderElement>
                            <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{t('Start date')}</Typography>
                            <Typography variant={'subtitle2'}>{assignment.startDate}</Typography>
                        </S.AssignmentExactHeaderElement>

                        <S.AssignmentExactHeaderElement>
                            <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{t('Due date')}</Typography>
                            <Typography variant={'subtitle2'}>{assignment.endDate ?? '-'}</Typography>
                        </S.AssignmentExactHeaderElement>

                        <S.AssignmentExactHeaderElement>
                            <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{t('Status')}</Typography>
                            <Typography variant={'subtitle2'}>{getTrainingAssignmentStatusChip(assignment.status)}</Typography>
                        </S.AssignmentExactHeaderElement>

                        {!!assignment.postExam &&
                            <S.AssignmentExactHeaderElement>
                                <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{t('Passing points')}</Typography>
                                <Typography variant={'subtitle2'}>{`${assignment.postExam.examPassingPoints}/${assignment.postExam.totalPoints ?? 0}`}</Typography>
                            </S.AssignmentExactHeaderElement>
                        }

                        {!!assignment.postExam?.questions?.[0]?.validation &&
                            <S.AssignmentExactHeaderElement>
                                <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{t('Scored points')}</Typography>
                                <Typography variant={'subtitle2'}>{getValidationPoints()}</Typography>
                            </S.AssignmentExactHeaderElement>
                        }
                    </>
                }

            </S.AssignmentExactHeader>

            <S.AssignmentExactContent>
                {assignment?.training &&
                    <AssignmentTrainingCard isLessThen1050={isLessThen1050}>
                        <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant={'*h3'} >{t('Training')}</Typography>
                            {getAssignmentTrainingAndExamStatusChip({assignmentStatus: assignment.status, trainingStatus: assignment.training.status}).trainingChip}
                        </Box>

                        <Divider flexItem variant={"fullWidth"} orientation={"horizontal"}/>

                        <Box width={'100%'} display={'flex'} alignItems={'center'} gap={'16px'}>
                            {assignment.training?.trainingCover && <CoverImageView cover={assignment.training?.trainingCover} size={isTablet ? 'list' : 'preview'}/>}
                            {/*{assignment.training.trainingCover &&*/}
                            {/*    <Box maxWidth={'200px'}>*/}
                            {/*        <img src={assignment.training.trainingCover?.image} style={{borderRadius: '6px'}}*/}
                            {/*             width={'200px'} alt={assignment.training.trainingCover?.imageName}/>*/}
                            {/*    </Box>*/}
                            {/*}*/}
                            <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} gap={'12px'} height={'100%'} >
                                <Typography variant={'*bodyText1_semibold'}>{assignment.training.trainingName}</Typography>
                                <Typography sx={{whiteSpace: 'break-spaces'}} variant={'*bodyText2'}>{assignment.training.trainingDescription}</Typography>
                            </Box>

                        </Box>

                        <Divider flexItem variant={"fullWidth"} orientation={"horizontal"} sx={{margin: 'auto 0 0 0'}}/>

                        <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={'16px'}>
                            <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} padding={'6px'} sx={{backgroundColor: colors.backgrounds.optional}} borderRadius={'50%'}>
                                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} padding={'6px'} sx={{backgroundColor: colors.backgrounds.blue_light_1}} borderRadius={'50%'}>
                                        <CheckCircleOutlined style={{color: colors.primary.hovered}} />
                                    </Box>
                                </Box>

                                <Typography variant={'*bodyText2_semibold'} sx={{whiteSpace: 'nowrap'}}>
                                    {`${t('Progress')} ${assignment.training.content.filter(e => e.status === MainTrainingProgressStatus.Done).length}/${assignment.training.content.length} ${t('section')}`}
                                </Typography>

                            </Box>

                            {assignment.training.limitMinDuration > 0 &&
                                <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} padding={'6px'}
                                         sx={{backgroundColor: colors.backgrounds.optional}} borderRadius={'50%'}>
                                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}
                                             padding={'6px'} sx={{backgroundColor: colors.backgrounds.blue_light_1}}
                                             borderRadius={'50%'}>
                                            <AccessTime style={{color: colors.primary.hovered}}/>
                                        </Box>
                                    </Box>

                                    <Typography variant={'*bodyText2_semibold'} sx={{whiteSpace: 'nowrap'}}>
                                        {`${assignment.training.limitMinDuration} ${t('hour minimum duration')}`}
                                    </Typography>

                                </Box>
                            }
                        </Box>
                    </AssignmentTrainingCard>
                }


                {assignment?.postExam &&
                    <AssignmentTrainingCard isLessThen1050={isLessThen1050}>
                        <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant={'*h3'} >{t('Exam')}</Typography>
                            {getAssignmentTrainingAndExamStatusChip({assignmentStatus: assignment.status, trainingStatus: assignment.training?.status, examStatus: assignment.postExam.status }).examChip}
                        </Box>

                        <Divider flexItem variant={"fullWidth"} orientation={"horizontal"}/>

                        <Box width={'100%'} display={'flex'} alignItems={'center'} gap={'16px'}>
                            {assignment.postExam.examCover && <CoverImageView cover={assignment.postExam.examCover} size={isTablet ? 'list' : 'preview'}/> }
                            {/*{assignment.postExam.examCover &&*/}
                            {/*    <Box maxWidth={'200px'}>*/}
                            {/*        <img src={assignment.postExam.examCover.image} style={{borderRadius: '6px'}}*/}
                            {/*             width={'200px'} alt={assignment.postExam.examCover.imageName}/>*/}
                            {/*    </Box>*/}
                            {/*}*/}

                            <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} gap={'12px'} height={'100%'}>
                                <Typography variant={'*bodyText1_semibold'}>{assignment.postExam.examName}</Typography>
                                <Typography sx={{whiteSpace: 'break-spaces'}} variant={'*bodyText2'}>{assignment.postExam.examDescription}</Typography>
                            </Box>

                        </Box>

                        <Divider flexItem variant={"fullWidth"} orientation={"horizontal"} sx={{margin: 'auto 0 0 0'}}/>

                        <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={'16px'} flexWrap={'wrap'}>

                            <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} padding={'6px'} sx={{backgroundColor: colors.backgrounds.optional}} borderRadius={'50%'}>
                                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} padding={'6px'} sx={{backgroundColor: colors.backgrounds.blue_light_1}} borderRadius={'50%'}>
                                        {assignment.postExam.timeToPass > 0 ? <AccessTime style={{color: colors.primary.hovered}}/> : <AllInclusive style={{color: colors.primary.hovered}}/>}
                                    </Box>
                                </Box>

                                <Typography variant={'*bodyText2_semibold'} sx={{whiteSpace: 'nowrap'}}>
                                    {assignment.postExam.timeToPass > 0 ? t('{{timeToPass}} minutes maximum duration', {timeToPass: assignment.postExam.timeToPass}) : t('Does not have limits for time to pass')}
                                </Typography>

                            </Box>

                            <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} padding={'6px'}
                                     sx={{backgroundColor: colors.backgrounds.optional}} borderRadius={'50%'}>
                                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}
                                         padding={'6px'} sx={{backgroundColor: colors.backgrounds.blue_light_1}}
                                         borderRadius={'50%'}>
                                        <HelpOutline style={{color: colors.primary.hovered}}/>
                                    </Box>
                                </Box>

                                <Typography variant={'*bodyText2_semibold'} sx={{whiteSpace: 'nowrap'}}>{t('{{totalQuestions}} questions', {totalQuestions: assignment.postExam.totalQuestions})}</Typography>

                            </Box>

                        </Box>


                    </AssignmentTrainingCard>
                }

            </S.AssignmentExactContent>


            <CancelAssignmentDialog />
            <ValidateExamAnswersDialog refreshAssignment={refreshAssignment}/>
            <TrainingPreviewDialog />
            <ExamPreview />
            <AssignmentHistory />
            <ExamResultDialog />
        </PageWrapper>
    )
}
