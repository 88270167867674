import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getRisksModelsAction} from "../../store/actions";
import {cleanUp, loadings} from "../../store/slice";

export const useInit = () => {
    const dispatch = useDispatch();
    const {modelsList} = useSelector(loadings);

    const controller = new AbortController();

    useEffect(() => {
        dispatch(getRisksModelsAction({data: {workspaceId: '', organizationId: ''}, signal: controller.signal}));

        return () => {
            dispatch(cleanUp());
        }
        //eslint-disable-next-line
    }, []);

    return modelsList;
}