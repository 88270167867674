import {
    AutomationControl,
    AutomationEvidence,
    AutomationFramework,
    selectEvidenceDialogPropsTypes
} from "../../../../types";
import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideSelectEvidence, loadings, releaseData} from "../../../../store/slice";
import {useState} from "react";


export const useSelectEvidenceDialogs = ({handleSelectEvidence, addedEvidenceIds}: selectEvidenceDialogPropsTypes) => {
    const dispatch = useDispatch();
    const data = useSelector(releaseData);
    const {dataForRelease} = useSelector(loadings);
    const {selectEvidenceForCreateOrEditInstruction} = useSelector(dialogs);


    const [selectedFramework, setSelectedFramework] = useState<AutomationFramework | null>(null);
    const [selectedControl, setSelectedControl] = useState<AutomationControl | null>(null);
    const [selectedEvidence, setSelectedEvidence] = useState<AutomationEvidence | null>(null);

    const handleChangeFramework = (newValue: AutomationFramework | null) => {
        if(!newValue) return;
        setSelectedFramework(newValue);
        setSelectedControl(null);
        setSelectedEvidence(null);
    }

    const handleChangeControl = (newValue: AutomationControl | null) => {
        if(!newValue) return;
        setSelectedControl(newValue);
        setSelectedEvidence(null);
    }

    const handleChangeEvidence = (newValue: AutomationEvidence | null) => {
        if(!newValue) return;
        setSelectedEvidence(newValue);
    }

    const handleClose = () => {
        dispatch(hideSelectEvidence());
        cleanUp();
    }

    const handleSubmit = () => {
        if(selectedFramework && selectedControl && selectedEvidence){
            handleSelectEvidence({
                frameworkId: selectedFramework.id,
                frameworkName: selectedFramework.name,
                controlName: selectedControl.name,
                controlId: selectedControl.id,
                evidenceName: selectedEvidence.name,
                evidenceId: selectedEvidence.id
            });
            handleClose();
        }
    }

    const cleanUp = () => {
        setSelectedFramework(null);
        setSelectedControl(null);
        setSelectedEvidence(null);
    }

    return{
        frameworks: data.frameworks,
        controls: data.controls,
        evidences: data.evidences,

        isLoading: dataForRelease,
        isOpen: selectEvidenceForCreateOrEditInstruction,
        isOk: Boolean(selectedFramework && selectedControl && selectedEvidence),

        selectedFramework,
        selectedControl,
        selectedEvidence,
        handleChangeFramework,
        handleChangeControl,
        handleChangeEvidence,
        actions: {
            handleClose,
            handleSubmit
        },
    }
}