import {Breadcrumbs} from "../../../../barsEnvironment/breadcrumbs";
import {Flex} from "../../../../../newShared/components/Layouts";
import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import colors from "../../../../../newShared/theme/colors";
import {Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useTargetsExact} from "../../hooks/useTargetsExact";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import * as S from '../../components/styled';
import {NMAP_SCAN_TYPE, OPENVAS_SCAN_TYPE, OWASP_SCAN_TYPE, SSL_SCAN_TYPE} from "../../constants";
import {normalizeScanTypeName} from "../../helpers";
import {TargetHistoryRow} from "../../components/targetHistoryRow";
import {DeleteTargetDialog} from "../../components/deleteTarget";
import {ReleaseTargetDialog} from "../../components/releaseTarget";
import {Skeleton} from "@mui/lab";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";

export const ExactTarget = () => {
    const {
        handleGoBack,
        target,
        isLoading,
        handleSelectScanType,
        scanType,
        handleDeselectScanType,
        history,
        handleEditTarget,
        openDeleteTargetDialog,
        runTarget,
        releaseTarget,
        historyScroll,
        status
    } = useTargetsExact();

    const {isMobile, isTablet, isLessThen1050} = useMedia();
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'vulnerabilityScanner'});

    return(
        <Flex w={'100%'} jc={'space-between'} overflow={'hidden'} h={'100%'} gap={'16px'}>
            <S.ExactTargetLeftContainer isRightSideOpen={scanType !== null}>
                <PageTitleAndActions
                    title={target?.name}
                    handleGoBack={handleGoBack}
                    showMenuWhen={isLessThen1050}
                    showParticularMenuAfterElementIndex={isLessThen1050 ? 0 : undefined}
                    actions={[
                        {
                            title: t('Release'),
                            onClick: releaseTarget,
                            disabled: !target,
                            size: 'small'
                        },
                        {
                            title: t('Run'),
                            variant: 'text',
                            onClick: runTarget,
                            disabled: !target,
                            icon: <PlayArrowIcon />,
                            size: 'small'
                        },
                        {
                            title: t('Edit'),
                            variant: 'text',
                            onClick: handleEditTarget,
                            disabled: !target,
                            icon: <ModeEditOutlineIcon />,
                            size: 'small'
                        },
                        {
                            title: t('Delete'),
                            variant: 'text',
                            onClick: openDeleteTargetDialog,
                            disabled: !target,
                            icon: <DeleteForeverIcon />,
                            size: 'small'
                        }
                    ]}
                />

                <Flex m={'8px 0 0 0'} direction={'column'}>
                    {isLoading ? <Skeleton width={200} /> : <Typography sx={{color: colors.lightGrayText}} variant={'*bodyText2'}>{t('Status')}
                        <b style={{color: status.color}}> {t(status.text)}</b>
                    </Typography>}

                    {isLoading ? <Skeleton width={200} sx={{marginTop: '10px'}} /> : <Typography sx={{color: colors.lightGrayText, margin: '10px 0 0 0'}} variant={'*bodyText2'}>{t('Last status changed')}
                        <b style={{color: colors.grayText}}> {parseDateAuto(target?.statusChangedDate, true)}</b>
                    </Typography>}

                    {isLoading ? <Skeleton width={200} sx={{marginTop: '10px'}} /> : <Typography sx={{color: colors.lightGrayText, margin: '10px 0 0 0'}} variant={'*bodyText2'}>{t('Target')}
                        <b style={{color: colors.grayText}}> {target?.target}</b>
                    </Typography>}
                </Flex>

                <TableContainer sx={{width: '100%'}}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('Name')}</TableCell>
                                <TableCell>{t('Scan time')}</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {isLoading &&
                                <TableRow hover selected={scanType === OPENVAS_SCAN_TYPE} onClick={() => handleSelectScanType(OPENVAS_SCAN_TYPE)}>
                                    <TableCell><Skeleton width={100} height={30}/></TableCell>
                                    <TableCell><Skeleton width={90} height={30}/></TableCell>
                                </TableRow>
                            }

                            {target?.configTarget.availableScanTypes.includes(OPENVAS_SCAN_TYPE) &&
                                <TableRow hover selected={scanType === OPENVAS_SCAN_TYPE} onClick={() => handleSelectScanType(OPENVAS_SCAN_TYPE)}>
                                    <TableCell>{t('{{scanType}} scan', {scanType: t(normalizeScanTypeName(OPENVAS_SCAN_TYPE))})}</TableCell>
                                    <TableCell>{parseDateAuto(target?.lastRuns.openVas, true)}</TableCell>
                                </TableRow>
                            }

                            {target?.configTarget.availableScanTypes.includes(OWASP_SCAN_TYPE) &&
                                <TableRow hover selected={scanType === OWASP_SCAN_TYPE} onClick={() => handleSelectScanType(OWASP_SCAN_TYPE)}>
                                    <TableCell>{t('{{scanType}} scan', {scanType: t(normalizeScanTypeName(OWASP_SCAN_TYPE))})}</TableCell>
                                    <TableCell>{parseDateAuto(target?.lastRuns.openVas, true)}</TableCell>
                                </TableRow>
                            }

                            {target?.configTarget.availableScanTypes.includes(NMAP_SCAN_TYPE) &&
                                <TableRow hover selected={scanType === NMAP_SCAN_TYPE} onClick={() => handleSelectScanType(NMAP_SCAN_TYPE)}>
                                    <TableCell>{t('{{scanType}} scan', {scanType: t(normalizeScanTypeName(NMAP_SCAN_TYPE))})}</TableCell>
                                    <TableCell>{parseDateAuto(target?.lastRuns.openVas, true)}</TableCell>
                                </TableRow>
                            }

                            {target?.configTarget.availableScanTypes.includes(SSL_SCAN_TYPE) &&
                                <TableRow hover selected={scanType === SSL_SCAN_TYPE} onClick={() => handleSelectScanType(SSL_SCAN_TYPE)}>
                                    <TableCell>{t('{{scanType}} scan', {scanType: t(normalizeScanTypeName(SSL_SCAN_TYPE))})}</TableCell>
                                    <TableCell>{parseDateAuto(target?.lastRuns.openVas, true)}</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </S.ExactTargetLeftContainer>

            {!isTablet && !isMobile && <Divider orientation="vertical" flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable}} />}

            <S.ExactTargetRightContainer isRightSideOpen={scanType !== null}>
                <Flex overflow={'hidden'} h={'100%'} direction={'column'} w={'100%'}>
                    <Flex direction={'column'}>
                        {isTablet &&
                            <>
                                <Breadcrumbs/>
                                <Flex m={'11px 0 0 0'} ai={'center'}>
                                    <KeyboardBackspaceIcon sx={{color: colors.text.blue, cursor: 'pointer'}} onClick={handleDeselectScanType}/>
                                    <Typography variant={isTablet ?  'h3' : "h1"}  sx={{margin: !revDir ? '0 32px 0 11px' : '0 32px 0 0', color: colors.backgrounds.dark}}>{t('{{scanType}} Scan history', {scanType: t(normalizeScanTypeName(scanType))})}</Typography>
                                </Flex>
                            </>
                        }
                        <Typography variant={'subtitle2'} sx={{margin: isTablet ? '20px 0 0 0' : ''}}>{t('{{scanType}} Scan history', {scanType: t(normalizeScanTypeName(scanType))})}</Typography>
                    </Flex>

                    <TableContainer ref={historyScroll.scrollRef} onScroll={historyScroll.onScroll}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('Actions')}</TableCell>
                                    <TableCell>{t('Start')}</TableCell>
                                    <TableCell>{t('Finish')}</TableCell>
                                    <TableCell>{t('Status')}</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {!history.histories.length && !history.isLoading && <Typography color={colors.darkBlueText}>{t('No history found for this scan type')}</Typography> }
                                {history.histories.map(e => <TargetHistoryRow key={e.id} history={e}/>)}
                                {(historyScroll.isLoading || historyScroll.hasMore) &&
                                    <TableRow>
                                        <TableCell>
                                            <Skeleton width={100} height={30}/>
                                        </TableCell>

                                        <TableCell>
                                            <Skeleton width={100} height={30}/>
                                        </TableCell>

                                        <TableCell>
                                            <Skeleton width={100} height={30}/>
                                        </TableCell>

                                        <TableCell>
                                            <Skeleton width={100} height={30}/>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Flex>
            </S.ExactTargetRightContainer>

            <DeleteTargetDialog />
            <ReleaseTargetDialog />
        </Flex>
    )
}