import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useRoutes} from "../new/useRoutes";
import {useDialogs} from "../new/useDialogs";
import {useBuilderFrameworks} from "../new/useBuilderFrameworks";
import {useEffect} from "react";
import {useSearchFieldURL} from "../../../../../newShared/hooks/useSearchFieldURL";
import {useFilterUrl} from "../../../../../newShared/hooks/useFilterUrl";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {ROOT_BUILDER, ROOT_FRAMEWORKS} from "../../constants";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";

export const useFrameworkList = () => {
    const {clearFilters, currentSearchForFetch} = useGenericFiltersStorage();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    const {
        filter,
        goToFramework,
    } = useRoutes();

    const {
        openAddFrameworkDialog,
    } = useDialogs();

    const {
        _frameworks, _isLoading,
        getFrameworks,
    } = useBuilderFrameworks();

    useEffect(() => {
        getFrameworks()

        return () => {
            clearFilters();
        }
        //eslint-disable-next-line
    }, [])

    //Pagination and filtering
    const {value: searchValue, handleChange} = useSearchFieldURL({wait: 0});
    const {setFilter, handleKeyDown} = useFilterUrl({
        getData: (filters) => {}
    });

    useSetBreadcrumbs([
        {
            title: tMenu('Framework builder'),
            path: ROOT_BUILDER
        },
        {
            title: tMenu('Created frameworks'),
            path: ROOT_FRAMEWORKS
        }
    ]);

    // const fetchByFilters = (search: string, filters: Record<string, string[]>, signal: AbortSignal) => {
    //     dispatch(GetInitIntegrationsData({data: {availableName: '', installedName: search, eraseBeforeFetch: true}}));
    // }

    return {
        _frameworks, _isLoading, filter,
        searchValue, setFilter, handleChange, handleKeyDown,
        goToFramework,
        openAddFrameworkDialog,
        currentSearchForFetch
    }
}