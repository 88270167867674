import FileUploadIcon from "@mui/icons-material/FileUpload";
import {Typo} from "../Typography";
import colors from "../../theme/colors";
import React, {ReactNode} from "react";
import * as S from './styled';
import InsertDriveFileSharpIcon from "@mui/icons-material/InsertDriveFileSharp";
import {IconButton} from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {FileNameTypography} from "../../../newDomain/components/kycRefactor/components/dialogs/createClient/styled";

export type FileDropZoneProps = {
    inputRef: React.LegacyRef<HTMLInputElement>,
    multiple?: boolean,
    fileHandler: (files: FileList | null) => void,
    customIcon?: ReactNode,
    customTitle?: ReactNode,
    accept: string,
    uploadedFiles?: File[],
    initialFileNames?: string[],
    handleDelete?: () => void;
};
export const FileDropZone = ({inputRef, multiple = false, fileHandler, customIcon, customTitle, accept, uploadedFiles, initialFileNames, handleDelete}:FileDropZoneProps) => {
    return(
        <S.CommonContainer>
            {(!uploadedFiles?.length || multiple) &&
                <S.DropZoneContainer>
                    <S.DropZoneInnerContainer>
                        <S.UploadIconContainer>
                            {customIcon ?? <FileUploadIcon />}
                        </S.UploadIconContainer>
                        {customTitle ??
                            <Typo cursor={'pointer'}>
                                {('Drop file here or')}
                                <b style={{color: colors.primary.blue, fontWeight: 400}}> {('click to upload')}</b>
                            </Typo>
                        }
                    </S.DropZoneInnerContainer>

                    <input
                        id={'input'}
                        accept={accept}
                        value=""
                        style={{ visibility: "hidden", opacity: 0 }}
                        ref={inputRef}
                        multiple={multiple}
                        type="file"
                        onChange={(e) => fileHandler(e.target.files)}
                    />
                </S.DropZoneContainer>
            }
            {(uploadedFiles ?? initialFileNames ?? []).map(e => (<S.FileRowContainer>
                <InsertDriveFileSharpIcon sx={{fill: colors.primary.blue_light}}/>
                <FileNameTypography>{typeof e === 'string' ? e : e.name}</FileNameTypography>

                <IconButton onClick={handleDelete}>
                    <DeleteOutlineIcon />
                </IconButton>
            </S.FileRowContainer>))}

        </S.CommonContainer>
    )
}
