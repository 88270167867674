import {Handle, Position} from "react-flow-renderer";
import {ALLOWDRAG} from "../../../constants";
import React from "react";
import {Box, Flex} from "../../../../../../newShared/components/Layouts";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Typo} from "../../../../../../newShared/components/Typography";

type saveNodeProps = {
    type: string;
    revDir: boolean;
}

export const SaveNode = ({data} : {data: saveNodeProps}) => {
    const {revDir} = data;
    const {t} = useMainTranslation('', {keyPrefix: 'automations'});

    // console.log(`${revDir} = SAVE NODE - ${JSON.stringify({left: !revDir ? '20%' : 'initial', right: revDir ? '20%' : 'initial'})}`)
    return(
        <Box
            w={'140px'}
            h={'60px'}
            br={'11px'}
            background={'#3BCC89'}
            box_shadow={'0px 0px 5.853034973144531px 0px rgba(0, 0, 0, 0.12)'}
            p={'5px 30px'}
            className={ALLOWDRAG ? 'nodrag': ''}
            cursor={'pointer'}
        >
            <Handle
                type="target"
                position={!revDir ? Position.Left : Position.Right}
                id="save-left-target"
                isConnectable={false}
                style={{opacity: '0'}}
            />
            <Typo
                style={{position: 'relative', bottom: '40px', left: '0'}}
                fontWeight={400}
                fontSize={'14px'}
                align={'center'}
            >{t('Save')}</Typo>

            <Flex jc={'space-between'}  w={'95%'} ai={'center'}  style={{position: 'relative', bottom: '20px', left: '10px'}}>
                <Typo fontWeight={400} fontSize={'14px'} align={'center'} sx={{lineHeight: 'normal'}}>{t(`evidenceTypes.${data.type}`)}</Typo>
            </Flex>
            {/*<Typo fontWeight={400} fontSize={'10px'} margin={'5px 0 0 0'}>{data.type}</Typo>*/}

            <Handle
                type="source"
                position={Position.Bottom}
                id="save-bottom-source"
                style={{ left: !revDir ? '20%' : 'initial', right: revDir ? '20%' : 'initial', borderRadius: '50%' }}
                isConnectable={false}
            />
        </Box>
    )
}