import {
    useCampaignReviewInstructionExact
} from "../../../../hooks/campaignReviewHooks/instructions/useCampaignReviewInstructionExact";
import {Flex} from "../../../../../../../newShared/components/Layouts";
import colors from "../../../../../../../newShared/theme/colors";
import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    MenuItem,
    OutlinedInput,
    RadioGroup,
    Select,
    Skeleton,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import * as S from '../../../../components/styled';
import {TextFieldCustom} from '../../../../components/styled';
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../../../newShared/hooks/useMedia";
import {IconTextButton, LoadingButton} from "../../../../../../../newShared/components/Basic/CommonButtons";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {SCHEDULE_TRIGGER_TYPE} from "../../../../constants";
import CommonSwitch from "../../../../../../../newShared/components/Basic/CommonSwitch";
import {ExpandableContainer} from "../../../../components/campaignReview/ExpandableContainer";
import {DEFAULT_DATE_FORMAT, parseDateAuto} from "../../../../../../../newShared/utils/dateTools";
import Divider from "@mui/material/Divider";
import {SelectedEvidenceCard} from "../../../../components/campaignReview/SelectedEvidenceCard";
import {SelectEvidenceDialog} from "../../../../components/campaignReview/dialogs/selectEvidenceDialog";
import Radio from "@mui/material/Radio";
import {timeValues} from "../../../../helpers";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import AddIcon from "@mui/icons-material/Add";
import {RunReviewWarningDialog} from "../../../../components/campaignReview/dialogs/runReviewWarningDialog";
import {
    DeleteCampaignReviewInstructionDialog
} from "../../../../components/campaignReview/dialogs/deleteCampaignReviewInstructionDialog";
import {validateEmail} from "../../../../../../../newShared/utils/text";
import {PageTitleAndActions} from "../../../../../../../newShared/components/pageTitleAndActions";
import {AutocompleteCollaborators} from "../../../../../../../newShared/components/AutocompleteCollaborators";
import {uuid} from "../../../../../../../newShared/utils";
import {optionIsTCollaborator} from "../../../../../../../newShared/components/AutocompleteCollaborators/types";

export const CampaignReviewExact = () => {
    const {
        instruction,
        actions,
        loading,
        _expands,
        toggleExpand,
        isEditMode,
        translatedRepeat,
        nextStart,
        form, //includes normalized eta,
        handleChangeEtaConfig,
        handleChange,
        handleSelectRepeatNum,
        handleSelectRepeatValue,
        _apps,
        isCommonExecutionTimeIsBiggerThanRepeat,
    } = useCampaignReviewInstructionExact();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.CampaignReviewInstructionCreate'});
    const {isLessThen1050, isMobile} = useMedia(858);

    const isEmailValid = form.finalApprover.email.length > 0 ? validateEmail(form.finalApprover.email) : true;

    const [uniqueInputId, setUniqueInputId] = useState<string>(uuid());
    useEffect(() => {
        setUniqueInputId(uuid())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(form.finalApprover)]);

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={instruction?.name}
                handleGoBack={actions.handleGoBack}
                showMenuWhen={isLessThen1050}
                actions={[
                    {
                        variant: 'text',
                        disabled: !instruction || isEditMode,
                        onClick: actions.handleEditCampaign,
                        icon: <EditIcon />,
                        title: t('Edit'),
                    },
                    {
                        variant: 'text',
                        disabled: !instruction || isEditMode,
                        onClick: actions.handleStartCampaign,
                        icon: <PlayArrowIcon />,
                        title: t('Run'),
                        loading: loading.isLoadingRun,
                    },
                    {
                        variant: 'text',
                        disabled: !instruction,
                        onClick: actions.handleDeleteCampaign,
                        icon: <DeleteIcon />,
                        title: t('Delete'),
                    }

                ]}
            >
                {instruction && instruction.trigger.type === SCHEDULE_TRIGGER_TYPE &&
                    <CommonSwitch
                        sx={{margin: !revDir ? '0 8px' : '0 8px'}}
                        label={instruction.active ? t('Active') :t('Inactive')}
                        // value={true}
                        checked={instruction.active}
                        disabled={isEditMode}
                        onChange={(e, checked) => actions.handleToggleActivity()}
                    />
                }
            </PageTitleAndActions>

            <S.ExactInstructionWrapper>
                {isEditMode ?
                    <>
                        <Flex ai={'center'} m={'16px 0 0 0'}>
                            <LoadingButton
                                loading={loading.isLoadingUpdate}
                                sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                variant="contained"
                                size={isMobile ? 'small' : 'medium'}
                                onClick={actions.handleSubmit}
                                disabled={!actions.isOk()}
                            >{t('Save')}</LoadingButton>
                            <Button disabled={loading.isLoadingUpdate} variant={'outlined'} size={isMobile ? 'small' : 'medium'} onClick={actions.handleCancelEdit} >{t('Cancel')}</Button>
                        </Flex>

                        <ExpandableContainer isOpen={_expands.info} toggle={() => toggleExpand('info')} text={t('Information*')}>
                            <TextFieldCustom
                                required
                                value={form.name}
                                name={'name'}
                                onChange={handleChange}
                                label={t('Review campaign name')}
                                size={'small'}
                                sx={{margin: '16px 0 0 0'}}
                            />

                            <TextFieldCustom
                                // required
                                value={form.description}
                                multiline
                                minRows={5}
                                name={'description'}
                                onChange={handleChange}
                                label={t('Description')}
                                // size={'small'}
                                sx={{margin: '16px 0 0 0'}}
                            />
                        </ExpandableContainer>

                        <ExpandableContainer isOpen={_expands.schedule} toggle={() => toggleExpand('schedule')} text={t('Scheduler')}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">{t('Choose trigger type')}</FormLabel>
                                <RadioGroup row onChange={actions.handleSetScheduleType} value={form.trigger.type}>
                                    <FormControlLabel value="schedule" control={<Radio />} label={t("Schedule")} />
                                    <FormControlLabel value="manual" control={<Radio />} label={t("Manual")} />
                                </RadioGroup>
                            </FormControl>
                            {form.trigger.type === 'schedule' ?
                                <>
                                    <FormLabel id="demo-row-radio-buttons-group-label">{t('Choose repeat value')}</FormLabel>
                                    <Flex w={'100%'} jc={'space-between'} ai={'center'} m={'8px 0 0 0'}>
                                        <TextField
                                            id="filled-number"
                                            label={t("Number")}
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            value={translatedRepeat.num}
                                            onChange={(e) => {handleSelectRepeatNum(parseInt(e.target.value) || 1)}}
                                        />
                                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="demo-dialog-select-label">{t('Select')}</InputLabel>
                                            <Select
                                                labelId="demo-dialog-select-label"
                                                id="demo-dialog-select"
                                                value={translatedRepeat.value}
                                                onChange={(e) => {handleSelectRepeatValue(e.target.value as string)}}
                                                input={<OutlinedInput label={t('Select')} />}
                                            >
                                                {timeValues.filter(e => e !== 'Hours' && e !== 'Days').map(e => <MenuItem  key={e} value={e}>{t(`timeValue.${e}`)}</MenuItem>) }
                                            </Select>
                                        </FormControl>
                                    </Flex>
                                    {/*<FormLabel id="demo-row-radio-buttons-group-label">Choose when to start</FormLabel>*/}
                                    <Flex w={'98%'}>
                                        <DesktopDatePicker
                                            label={t("Choose scheduler start date")}
                                            inputFormat={DEFAULT_DATE_FORMAT}
                                            value={form.trigger.start}
                                            // disablePast
                                            onChange={actions.handleSetStartTrigger}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Flex>
                                </>
                                :
                                <>

                                </>
                            }
                        </ExpandableContainer>

                        <ExpandableContainer isOpen={_expands.apps} toggle={() => toggleExpand('apps')} text={t('Included applications*')}>
                            <Flex w={'98%'} jc={'space-between'} ai={'center'}>
                                <Typography fontSize={'14px'} fontWeight={700} color={colors.grayText}>{t('Applications for review')}</Typography>
                                <Typography fontSize={'14px'} fontWeight={700} color={colors.grayText}>{t('Include')}</Typography>
                            </Flex>

                            <>
                                {_apps.map(e => {
                                    const isAdded = Boolean(form.applications.some(app => app.id === e.id));
                                    return(
                                        <>
                                            <Flex w={'95%'} jc={'space-between'} ai={'center'} p={'8px 0'} m={'8px 0 0 0'}>
                                                <Flex w={'50%'}>
                                                    <Typography variant={'body1'} color={colors.grayText}>{e.name}</Typography>
                                                </Flex>
                                                <Flex ai={'center'} w={'10%'}>
                                                    <CommonSwitch
                                                        // value={isAdded}
                                                        checked={isAdded}
                                                        onChange={() => {
                                                            isAdded ? actions.handleDeleteApp(e.id) : actions.handleAddApp(e.id, e.name)
                                                        }}
                                                    />
                                                    <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 0 0 8px' : '0 8px 0 0'}}>{(isAdded ? t('Yes') : t('No'))}</Typography>
                                                </Flex>
                                            </Flex>
                                            <Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable}}/>
                                        </>
                                    )
                                })}
                            </>
                        </ExpandableContainer>

                        <ExpandableContainer isOpen={_expands.eta} toggle={() => toggleExpand('eta')} text={t('Estimating time*')} error={isCommonExecutionTimeIsBiggerThanRepeat}>
                            {isCommonExecutionTimeIsBiggerThanRepeat ? <Typography variant={'body1'} color={colors.text.critical}>{t('The repeating time and the estimating time of stages do not match. Choose a different number of days.')}</Typography> : <></>}

                            <Typography variant={'body1'} color={colors.grayText} sx={{margin: '8px 0 0 0'}}>{t('Select estimating time for Stage Managers')}</Typography>
                            <TextFieldCustom
                                required
                                value={form.etaConfig.etaManager}
                                type={'number'}
                                InputProps={{ inputProps: { min: 1 } }}
                                name={'etaManager'}
                                onChange={handleChangeEtaConfig}
                                label={t('Number in days')}
                                size={'small'}
                                sx={{margin: '12px 0 0 0'}}
                            />

                            <Typography variant={'body1'} color={colors.grayText} sx={{margin: '16px 0 0 0'}}>{t('Select estimating time for Stage Vendor owners')}</Typography>
                            <TextFieldCustom
                                required
                                value={form.etaConfig.etaVendor}
                                type={'number'}
                                InputProps={{ inputProps: { min: 1 } }}
                                name={'etaVendor'}
                                onChange={handleChangeEtaConfig}
                                label={t('Number in days')}
                                size={'small'}
                                sx={{margin: '12px 0 0 0'}}
                            />

                            <Typography variant={'body1'} color={colors.grayText} sx={{margin: '16px 0 0 0'}}>{t('Select estimating time for Stage Execution')}</Typography>
                            <TextFieldCustom
                                required
                                value={form.etaConfig.etaExecution}
                                type={'number'}
                                InputProps={{ inputProps: { min: 1 } }}
                                name={'etaExecution'}
                                onChange={handleChangeEtaConfig}
                                label={t('Number in days')}
                                size={'small'}
                                sx={{margin: '12px 0 0 0'}}
                            />

                            <Typography variant={'body1'} color={colors.grayText} sx={{margin: '16px 0 0 0'}}>{t('Select estimating time for Stage Verify')}</Typography>
                            <TextFieldCustom
                                required
                                value={form.etaConfig.etaVerify}
                                type={'number'}
                                InputProps={{ inputProps: { min: 1 } }}
                                name={'etaVerify'}
                                onChange={handleChangeEtaConfig}
                                label={t('Number in days')}
                                size={'small'}
                                sx={{margin: '12px 0 0 0'}}
                            />

                            <Typography variant={'body1'} color={colors.grayText} sx={{margin: '16px 0 0 0'}}>{t('Select estimating time for Stage Sign')}</Typography>
                            <TextFieldCustom
                                required
                                value={form.etaConfig.etaSign}
                                type={'number'}
                                InputProps={{ inputProps: { min: 1 } }}
                                name={'etaSign'}
                                onChange={handleChangeEtaConfig}
                                label={t('Number in days')}
                                size={'small'}
                                sx={{margin: '12px 0 0 0'}}
                            />
                        </ExpandableContainer>

                        <ExpandableContainer isOpen={_expands.finalApprover} toggle={() => toggleExpand('finalApprover')} text={t('Final approver*')}>
                            <AutocompleteCollaborators<false, false, true>
                                key={uniqueInputId}
                                freeSolo
                                freeSoloType={'email'}
                                getOptionLabelCustom={"name+email"}
                                getOptionLabel={(option) => optionIsTCollaborator(option) ? option.email : option}
                                label={t("Select employee")}
                                required

                                helperText={isEmailValid ? undefined : t('Email is invalid')}
                                sx={{margin: '8px 0 0 0'}}

                                shouldForwardErrorValue
                                defaultValue={form.finalApprover?.email || ''}
                                onChange={value => {
                                    actions.handleSetFinalApprover(
                                        optionIsTCollaborator(value)
                                            ? value
                                            : {email: value as string, publicId: null, firstName: null, lastName: null}
                                    )
                                }}
                            />
                        </ExpandableContainer>

                        <ExpandableContainer isOpen={_expands.evidence} toggle={() => toggleExpand('evidence')} text={t('Saving*')}>
                            <Flex w={'100%'}>
                                <IconTextButton icon={<AddIcon />} variant={'text'} onClick={actions.handleOpenAddEvidence}>
                                    {t('Add evidence')}
                                </IconTextButton>
                            </Flex>

                            <Flex w={'100%'} direction={'column'}>
                                {form.evidences.map(el => <SelectedEvidenceCard key={el.evidenceId} evidence={el} onDelete={actions.handleDeleteEvidence}/>)}
                            </Flex>

                            {!form.evidences.length ? <Typography variant={'body1'} color={colors.grayText} sx={{margin: '16px 0 0 0'}}>{t('No evidences selected')}</Typography> : <></>}
                        </ExpandableContainer>
                    </>
                    :
                    <>
                        {loading.isLoadingExact ?
                            <>
                                <ExpandableContainer isOpen={_expands.info} toggle={() => toggleExpand('info')} text={t('Information')} disabled>
                                    <Typography variant={'body1'} color={colors.lightGrayText}><Skeleton width={300} /></Typography>
                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '8px 0 0 0'}}><Skeleton width={50} /></Typography>
                                    <Typography variant={'body1'} color={colors.grayText}><Skeleton width={200} /></Typography>
                                </ExpandableContainer>

                                <ExpandableContainer isOpen={_expands.schedule} toggle={() => toggleExpand('schedule')} text={t('Scheduler')} disabled>
                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '8px 0 0 0'}}><Skeleton width={150} /></Typography>
                                </ExpandableContainer>

                                <ExpandableContainer isOpen={_expands.apps} toggle={() => toggleExpand('apps')} text={t('Included applications')} disabled>
                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '8px 0 0 0'}}><Skeleton width={150} /></Typography>
                                </ExpandableContainer>

                                <ExpandableContainer isOpen={_expands.eta} toggle={() => toggleExpand('eta')} text={t('Estimating time')} disabled>
                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '8px 0 0 0'}}><Skeleton width={150} /></Typography>
                                </ExpandableContainer>

                                <ExpandableContainer isOpen={_expands.finalApprover} toggle={() => toggleExpand('finalApprover')} text={t('Final approver')} disabled>
                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '8px 0 0 0'}}><Skeleton width={150} /></Typography>
                                </ExpandableContainer>

                                <ExpandableContainer isOpen={_expands.evidence} toggle={() => toggleExpand('evidence')} text={t('Saving')} disabled>
                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '8px 0 0 0'}}><Skeleton width={150} /></Typography>
                                </ExpandableContainer>
                            </>
                            :
                            instruction &&
                            <>
                                <ExpandableContainer isOpen={_expands.info} toggle={() => toggleExpand('info')} text={t('Information')}>
                                    <Typography variant={'body1'} color={colors.lightGrayText}>{t('Review campaign name')} <i style={{color: colors.grayText}}>{instruction.name}</i></Typography>
                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '8px 0 0 0'}}>{t('Description')}</Typography>
                                    <Typography variant={'body1'} color={colors.grayText}>{instruction.description ?? '- - -'}</Typography>
                                </ExpandableContainer>

                                <ExpandableContainer isOpen={_expands.schedule} toggle={() => toggleExpand('schedule')} text={t('Scheduler')} disabled={instruction.trigger.type !== SCHEDULE_TRIGGER_TYPE}>
                                    <Typography variant={'body1'} color={colors.lightGrayText}>{t('Start date')} <b color={colors.grayText}>{parseDateAuto(instruction.trigger.start)}</b></Typography>
                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '8px 0 0 0'}}>{t('Repeat every')} <b color={colors.grayText}>{translatedRepeat.num} {t(`timeValue.${translatedRepeat.value}`)}</b></Typography>
                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '8px 0 0 0'}}>{t('Next start')} <b color={colors.grayText}>{parseDateAuto(nextStart)}</b></Typography>
                                </ExpandableContainer>

                                <ExpandableContainer isOpen={_expands.apps} toggle={() => toggleExpand('apps')} text={t('Included applications')}>
                                    {instruction.applications.map(e => {
                                        return(
                                            <>
                                                <Flex w={'95%'} jc={'space-between'} ai={'center'} p={'8px 0'} m={'8px 0 0 0'}>
                                                    <Typography variant={'body1'} color={colors.grayText}>{e.name}</Typography>
                                                </Flex>
                                                <Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable}}/>
                                            </>
                                        )
                                    })}
                                </ExpandableContainer>

                                <ExpandableContainer isOpen={_expands.eta} toggle={() => toggleExpand('eta')} text={t('Estimating time')}>
                                    <Typography variant={'body1'} color={colors.lightGrayText}>{t('Deadline for Stage Managers')}: <i style={{color: colors.grayText}}>{form.etaConfig.etaManager} {t('days')}</i></Typography>
                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '8px 0 0 0'}}>{t('Deadline for Stage Vendor owners')}: <i style={{color: colors.grayText}}>{form.etaConfig.etaVerify} {t('days')}</i></Typography>
                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '8px 0 0 0'}}>{t('Deadline for Stage Execution')}: <i style={{color: colors.grayText}}>{form.etaConfig.etaExecution} {t('days')}</i></Typography>
                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '8px 0 0 0'}}>{t('Deadline for Stage Verify')}: <i style={{color: colors.grayText}}>{form.etaConfig.etaVerify} {t('days')}</i></Typography>
                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '8px 0 0 0'}}>{t('Deadline for Stage Sign')}: <i style={{color: colors.grayText}}>{form.etaConfig.etaSign} {t('days')}</i></Typography>
                                </ExpandableContainer>

                                <ExpandableContainer isOpen={_expands.finalApprover} toggle={() => toggleExpand('finalApprover')} text={t('Final approver')}>
                                    {/*<Typography variant={'body1'} color={colors.grayText} sx={{margin: '8px 0 0 0'}}>{`${instruction.finalApprover.firstName} ${instruction.finalApprover.lastName}`}</Typography>*/}
                                    <Typography variant={'body1'} color={colors.grayText}>{`${instruction.finalApprover.email}`}</Typography>
                                </ExpandableContainer>

                                <ExpandableContainer isOpen={_expands.evidence} toggle={() => toggleExpand('evidence')} text={t('Saving')}>
                                    <Flex w={'100%'} direction={'column'}>
                                        {instruction.evidences.map(el => <SelectedEvidenceCard key={el.evidenceId} evidence={el}/>)}
                                    </Flex>
                                </ExpandableContainer>
                            </>
                        }
                    </>
                }
            </S.ExactInstructionWrapper>

            <SelectEvidenceDialog
                handleSelectEvidence={actions.handleSelectEvidence}
                addedEvidenceIds={form.evidences.map(e => e.evidenceId)}
            />

            <RunReviewWarningDialog />
            <DeleteCampaignReviewInstructionDialog />
        </Flex>
    )
}