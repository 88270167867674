import {useControl} from "../../../hooks/useControl";
import {useFrameworksPathParams} from "../../../hooks/useFrameworksPathParams";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {PATH_VICA_STEP_EXACT} from "../../../../vicaRefactor/constants";

export const useEvidenceViewPage = () => {
    const history = useHistory();
    const {
        currentEvidence, evidences, isLoading,
        downloadFile, uploadFileAsset, downloadTable,

        setEvidenceStatus,

        goToEvidences,
    } = useControl();
    const {evidenceId} = useFrameworksPathParams();

    const [tab, setTab] = useState<string>('Files');
    const tabs: {title: string, handleClick: () => void, count: number | null}[] = [
        {
            title: 'Files',
            handleClick: () => {setTab('Files')},
            count: currentEvidence?.files.length ?? null,
        },
        {
            title: 'Automation',
            handleClick: () => {setTab('Automation')},
            count: currentEvidence?.automations.length ?? null,
        },
        {
            title: 'Statements',
            handleClick: () => {setTab('Statements')},
            count: currentEvidence?.statements.length ?? null,
        },
        {
            title: 'Description',
            handleClick: () => {setTab('Description')},
            count: null,
        },
    ]

    const goToViceStep = (stepId: string)=> () => {
        history.push(PATH_VICA_STEP_EXACT.replace(':stepId', stepId))
    }

    useEffect(() => {
        setTab('Files')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evidenceId]);

    return {
        goToEvidences,
        evidences, currentEvidence,
        uploadFileAsset, downloadFile, downloadTable,
        setEvidenceStatus,
        tab, tabs,
        isLoading,

        goToViceStep,
    }
}