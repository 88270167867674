import React, {FC} from "react";
import {Typography} from "@mui/material";
import {ExamQuestionSection} from "../examSection";
import {TrainingExamQuestionModel} from "../../../../../newShared/GQLTypes";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

type TExamQuestionsProps = {
    questions: TrainingExamQuestionModel[],
    toggleOpen: (id: string) => void,
    openedSection: string,
}
export const ExamQuestionsBlock: FC<TExamQuestionsProps> = ({questions, toggleOpen, openedSection}) => {

    const {t} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.pathExams'})


    return (
        <>
            <Typography variant={'*bodyText1_semibold'}>{t('Questions')}</Typography>
            {[...questions]
                .sort((a, b) => a.order - b.order)
                .map((e) => <ExamQuestionSection key={e.questionId} question={e} toggleOpen={toggleOpen} isOpen={openedSection === e.questionId} />)}
        </>
    )
}
