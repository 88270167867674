import React from "react";

import {Typography} from "@mui/material";
import {Download} from "@mui/icons-material";
import UploadIcon from "@mui/icons-material/Upload";
import {Flex} from "../../../../../newShared/components/Layouts";

import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useVendorsMain} from "../../hooks/useVendorsMain";

import {TableRowItemWrapper} from '../../components/styled';
import {ReleaseVendors} from "../../components/dialogs/releaseVendors";
import {ImportCsvDialog} from "../../../../../newShared/components/csvImportDialog";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {Vendor} from "../../types";
import {parseDocumentStatus} from "../../helpers";
import {useImportCsv} from "../../hooks/useVendorsDialogs/importCSV";
import {ExportCsvDialog} from "../../../../../newShared/components/csvExportDialog";
import {getName} from "../../../../../newShared/utils/text";

export const VendorsMain = () => {
    const {
        handleGoToAdd,
        isLoading,
        isLoadingFields,
        isLoadingExportCsv,
        vendors,
        // isEmpty,
        // handleLoadMore,
        handleRelease,
        handleImportCSV,
        // setSearch,
        // filter,
        handleOpenExportCsv,
        exportCsv,
        goToId,
        genericTable,
    } = useVendorsMain()
    const importCsv = useImportCsv();

    const {t} = useMainTranslation('',{keyPrefix:'pathVendors.VendorsMain'});
    const {isLessThenCustomQuery, isMobile} = useMedia(800)


    return (
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={ t('Vendors')}
                showMenuWhen={isLessThenCustomQuery}
                actions={[
                    {
                        title: t('Add new vendor'),
                        onClick: handleGoToAdd,
                        disabled: isLoading
                    },
                    {
                        title: t('Release'),
                        color: 'secondary',
                        onClick: handleRelease,
                        disabled: isLoading
                    },
                    {
                        title: t('Export CSV'),
                        onClick: handleOpenExportCsv,
                        disabled: isLoading,
                        // loading: isLoadingCSVExport,
                        variant: 'text',
                        icon: <Download />,
                    },
                    {
                        title: t('Import CSV'),
                        onClick: handleImportCSV,
                        disabled: isLoading,
                        variant: 'text',
                        icon: <UploadIcon />,
                    }
                ]}
            />

            <GenericTable<Vendor>
                id={'VendorsMain'}
                columnsConfig={{totalName: 'Total vendors', disableColumnsSettings: true, forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: vendors,
                    // customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id,
                    onRowClick: goToId,
                    columnsConfigs: [
                        {key: "name", name: t('Name'), default: true},
                        {key: 'owner', name: t('Owner'), default: true, valueGetter: (row) => row.owner ? getName(row.owner.firstName || '', row.owner.lastName || '') : ''},
                        {key: 'serviceProvided', name: t('Service provided'), default: true},
                        {key: 'docStatus', name: t('Document status'), default: true, valueRenderer: (row) => {
                                const {statusText, circle} = parseDocumentStatus(row.documentStatus)
                                return (
                                    <TableRowItemWrapper>
                                        {circle}
                                        <Typography variant={'*bodyText2'}>{statusText}</Typography>
                                    </TableRowItemWrapper>
                                )
                            }},
                        {key: 'securityReviewDate', name: t('Security review data'), default: true},
                        {key: 'riskLevel', name: t('Risk Level'), default: true, valueGetter: (row) => row.risk}
                    ],
                }}
                filtersConfig={{
                    searchCustomFilterKeyName: 'name',
                    searchAsArray: true,
                    dontSendSearchIfEmpty: true,
                    genericFilterProps: {
                        configs: genericTable.filters.configs,
                        isAddFilters: false,
                        fetchResultWithSelectedFilters: () => null,
                    }
                }}
                emptyArrayImageProps={{type: 'vendors', filterNotApplied: {secondaryText: 'You have not added any vendors yet', text: 'No vendors found', onClick: handleGoToAdd}}}
            />


            {/*<EmptyArrayImage*/}
            {/*    type={'vendors'}*/}
            {/*    isShowing={!isLoading && isEmpty}*/}
            {/*    withoutAdd*/}
            {/*    text={t('No Vendors')}*/}
            {/*/>*/}

            {/*{(!isEmpty || isLoading) &&*/}
            {/*    <>*/}
            {/*        <S.FilterContainer>*/}
            {/*            <SearchField*/}
            {/*                variant={'outlined'}*/}
            {/*                onChange={(e) => setSearch(e.currentTarget.value)}*/}
            {/*                value={filter.filter.search}*/}
            {/*                onClear={filter.handleCleanSearch}*/}
            {/*                disabled={isLoading}*/}
            {/*                label={t('Search')}*/}
            {/*                placeholder={t('Search')}*/}
            {/*                onKeyDown={filter.handleEnterKey}*/}
            {/*            />*/}
            {/*            <TextField*/}
            {/*                id="outlined-select-currency"*/}
            {/*                select*/}
            {/*                disabled={isLoading || isSettingsLoading}*/}
            {/*                label={t("Risk level")}*/}
            {/*                placeholder={t("Risk level")}*/}
            {/*                value={filter.filter.risk}*/}
            {/*                onChange={(e) =>*/}
            {/*                    // {e.persist();*/}
            {/*                    filter.handleRiskFilterChange(e.target.value as string)}*/}

            {/*            >*/}
            {/*                {[ALL_FILTER_SELECT, ...settings.risk.map(e => e.name)].map(e =>(*/}
            {/*                    <MenuItem key={e} value={e}>*/}
            {/*                        {e}*/}
            {/*                    </MenuItem>*/}
            {/*                ))}*/}
            {/*            </TextField>*/}

            {/*        </S.FilterContainer>*/}
            {/*        <TableHeader/>*/}
            {/*        {(!isLoading || !isEmpty) &&*/}
            {/*            <>*/}
            {/*                {(filter.filter.prevSearch || filter.filter.risk?.length) && !isLoading && !vendors.length*/}
            {/*                    && <Typography color={colors.darkBlueText}>*/}
            {/*                        {t('Nothing was found for your query. Try changing the query.')}*/}
            {/*                    </Typography>}*/}

            {/*                <S.MainTableScrollWrapper disableHover>*/}
            {/*                    <InfiniteScroll*/}
            {/*                        pageStart={0}*/}
            {/*                        loadMore={(page) => handleLoadMore(page)}*/}
            {/*                        hasMore={hasMore}*/}
            {/*                        initialLoad={false}*/}
            {/*                        threshold={50}*/}
            {/*                        useWindow={false}*/}
            {/*                        width={'100%'}*/}
            {/*                    >*/}
            {/*                        {vendors.map(e => <VendorsTableRow vendor={e} goToId={goToId}*/}
            {/*                                                           key={e.id}/>)}*/}
            {/*                    </InfiniteScroll>*/}
            {/*                </S.MainTableScrollWrapper>*/}
            {/*            </>*/}
            {/*        }*/}
            {/*        {isLoading &&*/}
            {/*        <MainPageLoading/>*/}
            {/*        }*/}
            {/*    </>*/}
            {/*}*/}
            <ReleaseVendors/>

            <ExportCsvDialog
                isOpen={exportCsv.isOpen}
                isLoadingFields={isLoadingFields}
                isLoadingExport={isLoadingExportCsv}
                availableFields={exportCsv.availableFields}
                handleExportCsv={exportCsv.handleExportCsv}
                handleClose={exportCsv.handleClose}
                fieldsMapperKey={"vendors"}
            />

            <ImportCsvDialog
                isOpen={importCsv.isOpen}
                fields={importCsv.fields}
                response={importCsv.response}
                setImportDialog={importCsv.setImportDialog}

                isGettingFields={importCsv.isGettingFields}
                isPerformingCsv={importCsv.isPerformingCsv}
                isLoadingTemplate={importCsv.isLoadingTemplate}

                getFields={importCsv.getFields}
                performCsv={importCsv.performCsv}
                downloadTemplate={importCsv.downloadTemplate}
                fieldsMapperKey={importCsv.fieldsMapperKey}

                fileLimitInMB={5}
            />
        </Flex>
    )
}

