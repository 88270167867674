import {IconButton, styled} from "@mui/material";

interface ListTypeIconButtonProps {
    active?: boolean;
}

export const ListTypeIconButton = styled(IconButton, {shouldForwardProp: (propName) => (
        !['active'].includes(propName.toString())
    )})<ListTypeIconButtonProps>`
  border-radius: 2px !important;
  background-color: ${props => props.active ? 'rgba(0, 0, 0, 0.1) !important' : 'transparent'};
  margin-right: 5px !important;
`;
