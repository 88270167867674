import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useEffect, useState} from "react";
import {GetAccessManagementAppById, GetDataForCreateApp} from "../../store/actions";
import {
    editRow,
    employees,
    loadings,
    openCreateRole,
    openDeleteRole,
    openEditRole,
    selectedAppEditable
} from "../../store/slice";
import {
    CUSTOM_APP_TYPE,
    EXTERNAL_TYPE,
    ORHAN_TYPE,
    PATH_ACCESS_MANAGEMENT_APPS_EXACT,
    PATH_ACCESS_MANAGEMENT_APPS_EXACT_EDIT_ROW,
    PATH_ACCESS_MANAGEMENT_APPS_LIST,
    PATH_LOCAL_ACCESS_MANAGEMENT
} from "../../constants";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {useForm} from "../../../../../newShared/hooks/useForm";
import {AccessManagementAppAccount, AccessManagementAppAccountRole} from "../../types";
import {addErrorSnack} from "../../../../barsEnvironment/snack/store/slice";
import {TShortEmployee} from "../../../employeesRefactor/types";

export const useEditRow = () => {
    //root
    const dispatch = useDispatch();
    const history = useHistory();
    const {id, account} = useParams<{id: string, account: string}>();

    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const controller = new AbortController();
    const editableApp = useSelector(selectedAppEditable);
    const _employees = useSelector(employees);
    const {exact, vendorsAndEmployeesForCreate} = useSelector(loadings);

    const [selectedEmployee, selectEmployee] = useState<TShortEmployee | null>(null);

    useEffect(() => {
        if(!exact && !editableApp && id){
            dispatch(GetAccessManagementAppById({data: {id}, signal: controller.signal}));
        }else if(editableApp){
          if(editableApp.type !== CUSTOM_APP_TYPE){
              dispatch(addErrorSnack(`Access management app ${editableApp.name} is not editable!`));
              history.push(PATH_ACCESS_MANAGEMENT_APPS_EXACT.replace(':id', id));
          }else{
              const row = editableApp.dataTable.find(e => e.account === account);
              if(row){
                  setForm(row);
                  const empl = _employees.find(e => e.id === row.employee);
                  empl && selectEmployee(empl);
              }else{
                  dispatch(addErrorSnack(`Access management app ${editableApp.name} has no rows with account ${account}!`));
              }
          }
        }

        if((!_employees.length)){
            dispatch(GetDataForCreateApp());
        }

        return () => {
            controller.abort();
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!exact && editableApp && editableApp.type !== CUSTOM_APP_TYPE){
            dispatch(addErrorSnack(`Access management app ${editableApp.name} is not editable!`));
            history.push(PATH_ACCESS_MANAGEMENT_APPS_EXACT.replace(':id', id));
        }else if(editableApp){
            const row = editableApp.dataTable.find(e => e.account === account);
            if(row){
                setForm(row);
                const empl = _employees.find(e => e.id === row.employeeId);
                empl && selectEmployee(empl);
            }else{
                dispatch(addErrorSnack(`Access management app ${editableApp.name} has no rows with account ${account}!`));
            }
        }
        //eslint-disable-next-line
    }, [exact, vendorsAndEmployeesForCreate]);

    useSetBreadcrumbs([
        {
            title: tMenu('Access management'),
            path: PATH_LOCAL_ACCESS_MANAGEMENT
        },
        {
            title: tMenu('Applications'),
            path: PATH_ACCESS_MANAGEMENT_APPS_LIST
        },
        {
            title: editableApp?.name ?? tMenu('Loading...'),
            path: PATH_ACCESS_MANAGEMENT_APPS_EXACT.replace(':id', id)
        },
        {
            title: tMenu('Edit row'),
            path: PATH_ACCESS_MANAGEMENT_APPS_EXACT_EDIT_ROW.replace(':id', id).replace(':account', account)
        }
    ]);

    //actions
    const handleGoBack = () => {
        history.push(PATH_ACCESS_MANAGEMENT_APPS_EXACT.replace(':id', id));
    }

    const handleSubmit = () => {
        //check for type - ttake employee or row text
        if(form.accountType === EXTERNAL_TYPE || form.accountType === ORHAN_TYPE){
            //external - employee: 'First Last', employeeId: null
            //orphan - employee: null, employeeId: null
            dispatch(editRow(form));
        }else{
            //service or mapped - user selected employee
            dispatch(editRow({...form, employee: selectedEmployee? `${selectedEmployee.firstName} ${selectedEmployee.lastName}` : null, employeeId: selectedEmployee?.id ?? null}));
        }
        history.push(PATH_ACCESS_MANAGEMENT_APPS_EXACT.replace(':id', id));
    }

    const handleSetType = (type: string | null) => {
        if(type === EXTERNAL_TYPE) {
            setForm({...form, accountType: type, employee: '', employeeId: null});
        }else if(type === ORHAN_TYPE){
            setForm({...form, accountType: type, employee: '', employeeId: null});
        }else{
            //mapped or service - user have to select employee
            setForm({...form, accountType: type, employee: null, employeeId: null});
        }
    }

    const handleSetEmployee = (empl: TShortEmployee | null) => {
        empl && selectEmployee(empl);
    }

    const handleSetStatus = (status: string | null) => {
        status && setForm({...form, status});
    }

    const handleOpenAddRole = () => {
        dispatch(openCreateRole());
    }

    const [editableRole, setEditableRole] = useState<AccessManagementAppAccountRole | null>(null);
    const handleOpenDeleteRole = (role: AccessManagementAppAccountRole) => {
        setEditableRole(role);
        dispatch(openDeleteRole());
    }

    const handleOpenEditRole = (role: AccessManagementAppAccountRole) => {
        setEditableRole(role);
        dispatch(openEditRole());
    }

    //
    const handleDeleteRole = (roleName: string) => {
        setForm({...form, roles: [...form.roles.filter(e => e.roleName !== roleName)]});
        setEditableRole(null);
    }

    const handleEditRole = (roleName: string, rolePermissions: string[]) => {
        setForm({...form, roles: [...form.roles.map(e => e.roleName === roleName ? {...e, rolePermissions} : e)]});
        setEditableRole(null);
    }

    const handleAddRole = (roleName: string, rolePermissions: string[]) => {
        setForm({...form, roles: [...form.roles, {roleName, rolePermissions}]});
    }

    //form
    const {form, setForm, handleChange} = useForm<AccessManagementAppAccount>({
        employee: null,
        account: '',
        accountType: null,
        status: '',
        roles: [],
        employeeId: null,
        accountId: null
    });

    const isOk = () => {
        if(form.accountType === ORHAN_TYPE){
            return form.account.trim().length > 0 &&
                form.status.trim().length > 0 &&
                form.roles.length > 0
        }else if(form.accountType === EXTERNAL_TYPE){
            return form.account.trim().length > 0 &&
                form.status.trim().length > 0 &&
                form.roles.length > 0 &&
                (form.employee ?? '').length > 0
        }else{
            //mapped or service - user have to select employee
            return selectedEmployee !== null && form.account.trim().length > 0 &&
                form.accountType !== null && form.status.trim().length > 0 &&
                form.roles.length > 0
        }
    }

    return{
        app: editableApp,
        isLoadingExact: exact,
        isLoadingEmployees: vendorsAndEmployeesForCreate,
        actions: {
            handleGoBack,
            handleSubmit,
            handleSetType,
            handleSetEmployee,
            handleSetStatus,
            //
            handleOpenAddRole,
            handleOpenDeleteRole,
            handleOpenEditRole,
            //
            handleDeleteRole,
            handleEditRole,
            handleAddRole,
            editableRole,
        },
        isOk,
        form,
        handleChange,
        employees: _employees,
        selectedEmployee
    }
}