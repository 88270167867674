import {Handle, Position} from "react-flow-renderer";
import {ALLOWDRAG} from "../../../constants";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Box, Flex} from "../../../../../../newShared/components/Layouts";
import {Typo} from "../../../../../../newShared/components/Typography";

type triggerNodeProps = {
    type: string,
    revDir: boolean
}

export const DocNode = ({data} : {data: triggerNodeProps}) => {
    const {type, revDir} = data;
    // const dispatch = useDispatch();
    const {t} = useMainTranslation('', {keyPrefix: 'automations'});

    // const handleSettingButton = () => {dispatch(openDoc())};

    return(
        <Box
            w={'140px'}
            h={'60px'}
            br={'11px'}
            background={'#FAC000'}
            box_shadow={'0px 0px 5.853034973144531px 0px rgba(0, 0, 0, 0.12)'}
            p={'5px 10px'}
            className={ALLOWDRAG ? 'nodrag': ''}
            cursor={'pointer'}
        >
            <Handle
                type="target"
                position={Position.Left}
                id="doc-left-target"
                isConnectable={false}
                style={{opacity: '0'}}
            />
            <Typo
                style={{position: 'relative', bottom: '40px', left: '0'}}
                fontWeight={400}
                fontSize={'14px'}
                align={'center'}
            >{t('Select Document')}</Typo>

            <Flex ai={'center'} jc={'space-between'} w={'100%'}  style={{position: 'relative', bottom: '30px', left: '0'}}>
                <Typo fontWeight={400} fontSize={'14px'} align={'center'} margin={'0 5px 0 0'}>{t(`documentTypes.${type}`)}</Typo>
                {/*<SettingsApplicationsIcon style={{width: '15px', height: '15px'}} onClick={handleSettingButton}/>*/}
            </Flex>

            <Handle
                type="source"
                position={!revDir ? Position.Right : Position.Left}
                id="doc-right-source"
                style={{backgroundColor: 'inherit'}}
                isConnectable={false}
            />

            <Handle
                type="source"
                position={Position.Bottom}
                id="doc-bottom-source"
                style={{ left: !revDir ? '20%' : 'initial', right: revDir ? '20%' : 'initial', borderRadius: '50%' }}
                isConnectable={false}
            />
        </Box>
    )
}
