import {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {
    PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATE_SETTINGS,
    PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATE_SETTINGS_EXACT,
    PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATES,
    PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATES_ID,
    PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATES_NEW,
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS,
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_ID,
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_NEW,
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_SETTINGS,
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_SETTINGS_EXACT,
    PATH_LOCAL_ASSET_MANAGEMENT_SAAS,
    PATH_LOCAL_ASSET_MANAGEMENT_SAAS_ID,
    PATH_LOCAL_ASSET_MANAGEMENT_SAAS_NEW,
    PATH_LOCAL_ASSET_MANAGEMENT_SAAS_SETTINGS,
    PATH_LOCAL_ASSET_MANAGEMENT_SAAS_SETTINGS_EXACT,
    PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE,
    PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_ID,
    PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_NEW,
    PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_SETTINGS,
    PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_SETTINGS_EXACT,
    PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS,
    PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS_ID,
    PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS_NEW,
    PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS_SETTINGS,
    PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS_SETTINGS_EXACT,
    PATH_LOCAL_ASSETS_INCOMING_REQUESTS,
    PATH_LOCAL_ASSETS_INCOMING_REQUESTS_EXACT,
    PATH_LOCAL_CERTIFICATE_SETTINGS_NEW,
    PATH_LOCAL_PHYSICAL_ASSETS_SETTINGS_NEW,
    PATH_LOCAL_SAAS_ASSETS_SETTINGS_NEW,
    PATH_LOCAL_SOFTWARE_ASSETS_SETTINGS_NEW,
    PATH_LOCAL_VIRTUAL_ASSETS_SETTINGS_NEW,
} from "./constants";
import {AssetsList} from "./pages/phAssertsList";
import {PhysicalAssetExact} from "./pages/phAssertsExactAsset";
import {AssetNew} from "./pages/newAsset";
import {PhysicalAssetsSettings} from "./pages/phAssertsSettings";
import {ExactSettings} from "./pages/exactSettings";
import {NewSetting} from "./pages/newSetting";
import {VirtualAssetsList} from "./pages/virtualAssetsList";
import {VirtualAssetExact} from "./pages/virtualAssetExact";
import {VirtualAssetNew} from "./pages/newVirtualAsset";
import {VirtualAssetsSettings} from "./pages/VirtualAssetSettings";
import {SoftwareAssetsList} from "./pages/softwareAssetsList";
import {SoftwareAssetNew} from "./pages/newSoftwareAsset";
import {SaasList} from "./pages/saasList";
import {SoftwareAssetExact} from "./pages/softwareExact";
import {SaasAssetNew} from "./pages/newSaasAsset";
import {SaasAssetExact} from "./pages/saasExact";
import {ExactVirtualSettings} from "./pages/exactVirtualSettings";
import {NewVirtualSetting} from "./pages/newVirtualSetting";
import {NewSoftwareSetting} from "./pages/newSoftwareSetting";
import {NewSaasSetting} from "./pages/newSaasSetting";
import {SoftwareAssetsSettings} from "./pages/softwareAssetSettings";
import {SaasAssetsSettings} from "./pages/saasAssetSettings";
import {ExactSoftwareSettings} from "./pages/exactSoftwareSettings";
import {ExactSaasSettings} from "./pages/exactSaasSettings";
import {CertificateAssetsList} from "./pages/certificateAssetsList";
import {CertificateAssetNew} from "./pages/newCertificateAsset";
import {CertificateAssetExact} from "./pages/certificateAssetExact";
import {ExactCertificateSettings} from "./pages/exactCertificateSettings";
import {CertificateAssetsSettings} from "./pages/certificateSettings";
import {NewCertificateSetting} from "./pages/newCertificateSetting";
import {AssetsIncomingRequestsList} from "./pages/requests/list";


export const Routes: FC = () => {

    return (
        <Switch>
            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS} component={AssetsList}/>
            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS} component={VirtualAssetsList}/>
            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE} component={SoftwareAssetsList}/>
            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_SAAS} component={SaasList}/>
            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATES} component={CertificateAssetsList}/>

            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_ID} component={PhysicalAssetExact}/>
            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS_ID} component={VirtualAssetExact}/>
            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_ID} component={SoftwareAssetExact}/>
            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_SAAS_ID} component={SaasAssetExact}/>
            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATES_ID} component={CertificateAssetExact}/>

            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_NEW} component={AssetNew}/>
            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS_NEW} component={VirtualAssetNew}/>
            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_NEW} component={SoftwareAssetNew}/>
            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_SAAS_NEW} component={SaasAssetNew}/>
            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATES_NEW} component={CertificateAssetNew}/>

            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_SETTINGS} component={PhysicalAssetsSettings}/>
            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS_SETTINGS} component={VirtualAssetsSettings}/>
            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_SETTINGS} component={SoftwareAssetsSettings}/>
            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_SAAS_SETTINGS} component={SaasAssetsSettings}/>
            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATE_SETTINGS} component={CertificateAssetsSettings}/>

            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_SETTINGS_EXACT} component={ExactSettings}/>
            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS_SETTINGS_EXACT} component={ExactVirtualSettings}/>
            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_SETTINGS_EXACT} component={ExactSoftwareSettings}/>
            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_SAAS_SETTINGS_EXACT} component={ExactSaasSettings}/>
            <Route exact path={PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATE_SETTINGS_EXACT} component={ExactCertificateSettings}/>

            <Route exact path={PATH_LOCAL_PHYSICAL_ASSETS_SETTINGS_NEW} component={NewSetting}/>
            <Route exact path={PATH_LOCAL_VIRTUAL_ASSETS_SETTINGS_NEW} component={NewVirtualSetting}/>
            <Route exact path={PATH_LOCAL_SOFTWARE_ASSETS_SETTINGS_NEW} component={NewSoftwareSetting}/>
            <Route exact path={PATH_LOCAL_SAAS_ASSETS_SETTINGS_NEW} component={NewSaasSetting}/>
            <Route exact path={PATH_LOCAL_CERTIFICATE_SETTINGS_NEW} component={NewCertificateSetting}/>

            {/*REQUESTS*/}
            <Route exact path={[PATH_LOCAL_ASSETS_INCOMING_REQUESTS, PATH_LOCAL_ASSETS_INCOMING_REQUESTS_EXACT]} component={AssetsIncomingRequestsList}/>

            <Redirect to={PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS}/>
        </Switch>
    )

}
