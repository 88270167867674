import React, {FC} from "react";
import {Grid, Skeleton} from "@mui/material";
import {EmptyArrayImage} from "../../../../../newShared/components/EmptyArrayImage";
import {PageWrapper} from "../../../../../newShared/components/Layouts";
import {getName} from "../../../../../newShared/utils/text";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDashboardVicaPage} from "../../hooks/useDashboardVicaPage";
import {FrameworkWidget} from "../../components/frameworkWidget";

export const Dashboard: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathVica.pathDashboard'});
    const {user} = UseManageWorkspacesAndOrganizations();

    const {
        // searchValue, handleChange, handleKeyDown, setFilter,
        frameworks, isLoading,
    } = useDashboardVicaPage();

    return (
        <PageWrapper>
            <PageTitleAndActions
                title={user ? (
                    `${t('Hello')}, ${getName(user.firstName, user.lastName)}! 👋`
                ) : <Skeleton width={'200px'}/>
                }
                actions={[]}
            />

            <Grid container spacing={3} mt={'0px'} overflow={"auto"}>
                {!isLoading && frameworks.map(framework => (
                    <Grid item key={framework.id} c390={12} c770={6} c1336={3} c1440={4} c1920={3} c2300={2}>
                        <FrameworkWidget key={framework.id} framework={{
                            id: framework.id,
                            name: framework.name, description: framework.description, logo: framework.logo || null,
                            controls: framework.controls, complete: framework.controlsCompleted,
                            evidences: framework.evidences, evidencesFinished: framework.evidencesFinished.length,
                        }}/>
                    </Grid>
                ))}

                {isLoading && (
                    <>
                        <Grid item c390={12} c770={6} c1336={3} c1440={4} c1920={3} c2300={2}>
                            <FrameworkWidget framework={null}/>
                        </Grid>
                        <Grid item c390={12} c770={6} c1336={3} c1440={4} c1920={3} c2300={2}>
                            <FrameworkWidget framework={null}/>
                        </Grid>
                        <Grid item c390={12} c770={6} c1336={3} c1440={4} c1920={3} c2300={2}>
                            <FrameworkWidget framework={null}/>
                        </Grid>
                    </>
                )}
            </Grid>

            <EmptyArrayImage type={"frameworks"} isShowing={frameworks.length === 0 && !isLoading} text={t('You have not adopt any frameworks')} withoutAdd/>
        </PageWrapper>
    )
}
