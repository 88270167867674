import React, {FC} from "react";
import {useReportDialogs} from "../../../hooks/useReportDialogs";
import {useReport} from "../../../hooks/useReport";
import {
    Dialog,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {TableRowSkeleton} from "../../../../../../newShared/components/tableRowSkeleton";
import colors from "../../../../../../newShared/theme/colors";
import {useScrollPagination} from "../../../../../../newShared/hooks/useScrollPagination";

export const ignoreCells: string[] = ['id', 'indexKey'];
export const prettyNames: {[key: string]: string} = {
    name: 'Name',
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    id: 'ID',
    account: 'Account',
    email: 'Email',
    date: 'Date',
    createDate: 'Create Date',
    user: 'User',
    identity: 'Identity',
    hostname: 'Hostname',
    model: 'Model',
    serialNumber: 'Serial Number',
    mfa: 'MFA',
    enforcedMfa: 'Enforced MFA',
    applicationName: 'Application',
    applicationStatus: 'Application Status',
    applicationOwnerEmail: 'Application Owner Email',
    roleName: 'Role',
    roleDescription: 'Role Description',
    roleType: 'Role Type',
    assetName: 'Asset Name',
    assetType: 'Asset Type',
    assetOriginId: 'Asset Origin Id',
    assetPath: 'Asset Path',
    assetRoot: 'Asset Root',

    type: 'Type',
    description: 'Description',

    initialProbabilityQuantitative: 'Initial probability (quantitative)',
    initialProbabilityQuality: 'Initial probability quality',
    initialProbabilityDescription: 'Initial probability description',
    initialImpactQuantitative: 'Initial impact (quantitative)',
    initialImpactQuality: 'Initial impact quality',
    initialImpactDescription: 'Initial impact description',
    initialRating: 'Initial rating',

    controlType: 'Control Type',
    controlOwners: 'Control Owners',
    controlActions: 'Control Actions',
    controlScheduler: 'Control Scheduler',

    residualProbabilityQuantitative: 'Residual probability (quantitative)',
    residualProbabilityQuality: 'Residual probability quality',
    residualProbabilityDescription: 'Residual probability description',
    residualImpactQuantitative: 'Residual impact (quantitative)',
    residualImpactQuality: 'Residual impact quality',
    residualImpactDescription: 'Residual impact description',
    residualRating: 'Residual rating',

    workEmail: 'Work email',
    personalEmail: 'Personal Email',

    birthDay: 'Birthday',
    hireDate: 'Hire date',
    activeStatus: 'Active status',

    addressCountry: 'Country',
    addressState: 'State',
    addressCity: 'City',
    addressStreet: 'Street',
    addressZipcode: 'Zipcode',

    phone: 'Phone',
    employmentStatus: 'Employment status',
    jobTitle: 'Job title',
    department: 'Department',
    manager: 'Manager',

    documentStatus: 'Document status',
    serviceProvided: 'Service provided',
    owner: 'Owner',
    contactPersons: 'Contact Persons',
    link: 'Link',
    riskLevel: 'Risk level',
    evaluator: 'Evaluator',
    qualityLevel: 'Quality level',
    priceLevel: 'Price level',
    serviceLevel: 'Service level',
    averageLevel: 'Average level',
    notesAndRecommendations: 'Notes and recommendations',
};

// type TTransformProps = {name: string, logo?: string};
// const AppCell: FC<TTransformProps> = ({name, logo}: TTransformProps) => {
//     return (
//         <Flex ai={"center"}>
//             {logo && <Flex ai={"center"} jc={"center"} w={'24px'} h={'24px'}>
//                 <img style={{maxHeight: 20, maxWidth: 20}} src={logo} alt={name}/>
//             </Flex>}
//             <Typo fontSize={'16px'} fontWeight={300} style={{marginLeft: 5}}>{name}</Typo>
//         </Flex>
//     )
// }

export const strToPrettyName = (str: string): string => {
    return prettyNames[str] || str
}

const toStr = (str: string | string[]): string => {
    return Array.isArray(str) ? str.join(', ') : str
}

export const ReportCollectorTableDialog: FC = () => {

    const {
        collectorView: {isOpen, isLoading, setCollectorView, ids, data, keys, pageInfo: {total, page, count}},
    } = useReportDialogs();
    const {getCollectorData} = useReport();

    //Controller and first loadData
    const controller = new AbortController();

    //Pagination and filtering
    const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => {
            isOpen && ids && getCollectorData(
                ids.reportId, ids.evidenceId, ids.assetId, {
                    page, count,
                },
                controller.signal
            )
        },
        arrayLength: data.length || 0,
        isLoading: isLoading,
        pageInfo: {page, count, total},
        cleanData: () => {},
        controller: controller,
        // isOnTop: true,
        isNoMoreThanTotal: true,
        customDependencies: [ids, isOpen],
        // is0PageAddictive: data?.id !== ids?.assetId
    })

    return (
        <Dialog open={isOpen} onClose={() => {setCollectorView({clear: true})}}
                PaperProps={{sx: {p: '10px 0', maxWidth: '90vw', minWidth: '90vw', maxHeight: '90vh'}}}>
            <DialogContent>
                {data && (
                    <TableContainer onScroll={onScroll} ref={scrollRef}>
                        <Table size={"small"} stickyHeader
                               sx={{
                                   '& .MuiTableCell-root': {
                                       borderRight: "1px solid rgba(224, 224, 224, 1)"
                                   }}}
                        >
                            <TableHead>
                                <TableRow>
                                    {keys.filter(key => !ignoreCells.includes(key)).map(key => (
                                        <TableCell key={key} sx={{borderBottom: `2px solid ${colors.yellow}`}}>
                                            <Typography variant={'h4'}>{strToPrettyName(key)}</Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, index) => row && (
                                    <TableRow key={JSON.stringify(row)}>
                                        {keys.map(key => (
                                            <TableCell key={key + ' body'}
                                                       style={{
                                                           minWidth: (toStr(row[key] || '').length > 30 ? Math.floor(toStr(row[key] || '').length * 16 / 8) : Math.floor(toStr(row[key] || '').length * 16)) + 'px',
                                                       }}>
                                                {row[key] || ''}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}

                                {(bottomLoader || isLoading) && <TableRowSkeleton columns={data.keys.length || 3}/>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </DialogContent>
        </Dialog>
    )
}