import {MouseEventHandler, useState} from "react";
import {TFontSize, TToolBarHandlerAction, TToolBarHandlerPayload} from "../../../types";
import {FontSizesVariants} from "../../../constants";

interface UseFontSizeControlProps {
    initialFontSize: TFontSize | null,
    anchorEl: null | HTMLElement,
    changeFontSizeHandler: (action: TToolBarHandlerAction, payload: TToolBarHandlerPayload) => void;
}

interface UseFontSizeControlValue {
    fontSizes: TFontSize[],
    open: boolean,
    // buttonTitle: string,
    anchorEl: null | HTMLElement,
    handleClose: (size?: TFontSize) => MouseEventHandler<HTMLLIElement>,
    handleClick: MouseEventHandler<HTMLButtonElement>,
}

export const useFontSizeControl = ({ initialFontSize, anchorEl, changeFontSizeHandler}: UseFontSizeControlProps): UseFontSizeControlValue => {

    const [fontSizeAnchorEl, setFontSizeAnchorEl] = useState<null | HTMLElement>(() => anchorEl);

    const open = Boolean(fontSizeAnchorEl);

    const handleClick: UseFontSizeControlValue["handleClick"] = (event) => {
        event.stopPropagation();
        changeFontSizeHandler('fontSizeOpenClose', true);
        setFontSizeAnchorEl(event.currentTarget);
    };

    const handleClose: UseFontSizeControlValue["handleClose"] = (item) => (event) => {
        event.stopPropagation();

        setFontSizeAnchorEl(null);
        // item && setFontButtonTitle(item.toString());
        item && changeFontSizeHandler("fontSizeControl", item);
        if(item){
            changeFontSizeHandler("fontSizeControl", item);
        }else{
            changeFontSizeHandler('fontSizeOpenClose', false);
        }
    };


    return {
        fontSizes: FontSizesVariants,
        open,
        anchorEl: fontSizeAnchorEl,
        handleClick,
        handleClose
    };
}
