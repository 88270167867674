import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    initialValidateGoogleAuthorization,
    loginApi,
    logoutApi,
    resendEmailVerificationCodeApi,
    sendEmailSupportApi,
    sendEmailToResetPass,
    sendPassToResetPass,
    signUpApi,
    verifyEmailApi
} from "../api";
import {LoginMutationVariables} from "../../../../newShared/GQLTypes";
import {addInfoSnack, addSuccessfulSnack} from "../../../barsEnvironment/snack/store/slice";
import {TAuthObj} from "../types";
import {createAccountByInvitation} from "../../settings/api";

export const loginAction = createAsyncThunk(
    'auth/signIn',
    async (data: {data: LoginMutationVariables["data"], onSuccess: (isAppConfigured: boolean, isEmailVerified: boolean) => void}, {rejectWithValue}) => {

        try {
            const resp = await loginApi({...data.data});
            data.onSuccess && data.onSuccess(resp.isGoogleAuthConfigComplete, resp.isEmailVerified); //going to 2fa

            return {
                email: data.data.username
            }
        } catch (err: unknown) {
            if (
                err && typeof err === "object" &&
                'message' in err && err.message && typeof err.message === "string"
            ) rejectWithValue(err.message)
        }


    }
);

export const logoutAction = createAsyncThunk(
    'auth/logout',
    async (_, {dispatch}) => {
        logoutApi()
    }
)


export const SendEmailSupportAction = createAsyncThunk(
    'auth/sendEmail',
    async ({data, onSuccess}: {data: {email: string, name: string, message: string}, onSuccess: () => void}, {dispatch}) => {
        await sendEmailSupportApi(data);
        onSuccess();
        return 'OK';
    }
)


export const sendEmailToResetPassAction = createAsyncThunk(
    'userProfile/sendEmailToResetPass',
    async (data: {email: string}, {dispatch, getState}) => {

        // dispatch(addSuccessfulSnack('We have sent a link to restore your password to your email.'))
        const resp = await sendEmailToResetPass(data);
        dispatch(addSuccessfulSnack(resp.message))
        return resp
    },
);

export const sendPassToResetPassAction = createAsyncThunk(
    'userProfile/sendHashToResetPass',
    async (data: {password: string, hash: string, email: string, onSuccess: () => void}, {dispatch, getState}) => {
        const resp = await sendPassToResetPass({password: data.password, email: data.email, hash: data.hash});
        resp && data.onSuccess();
        return resp
    },
);

export const verifyEmailAction = createAsyncThunk(
    'auth/verifyEmail',
    async (data: {email: string, verificationCode: string, onSuccess: (skip: boolean) => void, signal?: AbortSignal}, {dispatch, getState}) => {
        const resp = await verifyEmailApi({verificationCode: data.verificationCode, email: data.email}, data.signal);
        dispatch(addSuccessfulSnack('Email verified successfully'))
        data.onSuccess(!resp.secret);
        return {
            data: {email: data.email},
            resp
        }
    },
);

export const resendEmailVerificationCodeAction = createAsyncThunk(
    'auth/resendEmailVerificationCode',
    async (data: {email: string, signal?: AbortSignal}, {dispatch, getState}) => {
        const resp = await resendEmailVerificationCodeApi({email: data.email}, data.signal);
        dispatch(addInfoSnack(resp.message))
        return {
            data: {email: data.email},
            resp
        }
    },
);

export const initialValidateGoogleAuthorizationAction = createAsyncThunk(
    'auth/initialValidateGoogleAuthorization',
    async (data: {secret: string, code: string, signal?: AbortSignal}, {dispatch, getState}) => {
        const resp = await initialValidateGoogleAuthorization(data.secret, data.code);
        dispatch(addInfoSnack('Application connected'))
        return {
            // data: {email: data.email},
            resp
        }
    },
);

export const signUpAction = createAsyncThunk(
    'signUp/signUpAction',
    async ({firstName, lastName, username, password, onSuccess, organizationName, license, invitationCode}: TAuthObj & {onSuccess: () => void}, { rejectWithValue }) => {
        if(invitationCode?.trim().length > 0){
            return await createAccountByInvitation({firstName, lastName, email: username, password, organizationName, license, invitationCode}).then((data) => {
                onSuccess();
                localStorage.setItem('organizationName', organizationName);
                //its set here to use it in workspaces page - when user will create organization it will be places in org name field as init
                return data;
            }).catch((err) => {
                // console.log(err);
                return rejectWithValue(err.message);
            })
        }else{
            return await signUpApi({firstName, lastName, username, password, organizationName, license, invitationCode}).then((data) => {
                onSuccess();
                localStorage.setItem('organizationName', organizationName);
                //its set here to use it in workspaces page - when user will create organization it will be places in org name field as init
                return data;
            }).catch((err) => {
                // console.log(err);
                return rejectWithValue(err.message);
            })
        }
        // const resp = await invitationCode?.trim().length > 0
        //     ? createAccountByInvitation({firstName, lastName, email: username, password, organizationName, license, invitationCode}).then((data) => {
        //         onSuccess();
        //         localStorage.setItem('organizationName', organizationName);
        //         //its set here to use it in workspaces page - when user will create organization it will be places in org name field as init
        //         return data;
        //     }).catch((err) => {
        //         // console.log(err);
        //         return rejectWithValue(err.message);
        //     })
        //     : signUpApi({firstName, lastName, username, password, organizationName, license, invitationCode}).then((data) => {
        //         onSuccess();
        //         localStorage.setItem('organizationName', organizationName);
        //         //its set here to use it in workspaces page - when user will create organization it will be places in org name field as init
        //         return data;
        //     }).catch((err) => {
        //         // console.log(err);
        //         return rejectWithValue(err.message);
        //     })

        // return {
        //     resp
        // }
    },
);
