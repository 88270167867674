import {Flex} from "../../../../../../../newShared/components/Layouts"
import {
    useCampaignReviewInstructionCreate
} from "../../../../hooks/campaignReviewHooks/instructions/useCampaignReviewInstructionCreate";
import {useMedia} from "../../../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    MenuItem,
    OutlinedInput,
    RadioGroup,
    Select,
    TextField,
    Typography
} from "@mui/material";
import colors from "../../../../../../../newShared/theme/colors";
import React, {useEffect, useState} from "react";
import * as S from "../../../../components/styled";
import {TextFieldCustom} from "../../../../components/styled";
import {LoadingButton} from "@mui/lab";
import AddIcon from '@mui/icons-material/Add';

import {IconTextButton} from "../../../../../../../newShared/components/Basic/CommonButtons";
import {SelectEvidenceDialog} from "../../../../components/campaignReview/dialogs/selectEvidenceDialog";
import CommonSwitch from "../../../../../../../newShared/components/Basic/CommonSwitch";
import Divider from "@mui/material/Divider";
import Radio from "@mui/material/Radio";
import {timeValues} from "../../../../helpers";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {ExpandableContainer} from "../../../../components/campaignReview/ExpandableContainer";
import {SelectedEvidenceCard} from "../../../../components/campaignReview/SelectedEvidenceCard";
import {validateEmail} from "../../../../../../../newShared/utils/text";
import {PageTitleAndActions} from "../../../../../../../newShared/components/pageTitleAndActions";
import {DEFAULT_DATE_FORMAT} from "../../../../../../../newShared/utils/dateTools";
import {AutocompleteCollaborators} from "../../../../../../../newShared/components/AutocompleteCollaborators";
import {optionIsTCollaborator} from "../../../../../../../newShared/components/AutocompleteCollaborators/types";
import {uuid} from "../../../../../../../newShared/utils";

export const CampaignReviewInstructionCreate = () => {
    const {
        _apps,
        loading,
        actions,
        form,
        handleChange,
        handleChangeEtaConfig,
        _expands,
        toggleExpand,
        translatedRepeat,
        handleSelectRepeatValue,
        handleSelectRepeatNum,
        isCommonExecutionTimeIsBiggerThanRepeat,
    } = useCampaignReviewInstructionCreate();
    const {isMobile} = useMedia(858);
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.CampaignReviewInstructionCreate'});

    // const {openFramework} = useFrameworks();
    // const {openControl} = useCurrentFramework();
    // const {goToEvidences} = useControl();

    const isEmailValid = form.finalApprover.email.length > 0 ? validateEmail(form.finalApprover.email) : true;

    const [uniqueInputId, setUniqueInputId] = useState<string>(uuid());
    useEffect(() => {
        setUniqueInputId(uuid())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(form.finalApprover)]);

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('Add configuration')}
                handleGoBack={actions.handleGoBack}
                actions={[]}
            />

            <Flex ai={'center'} m={'16px 0 0 0'}>
                <LoadingButton
                    loading={loading.isLoadingCreate}
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    variant="contained"
                    size={isMobile ? 'small' : 'medium'}
                    onClick={actions.handleSubmit}
                    disabled={!actions.isOk()}
                >{t('Save')}</LoadingButton>
                <Button disabled={loading.isLoadingCreate} variant={'outlined'} size={isMobile ? 'small' : 'medium'} onClick={actions.handleGoBack} >{t('Cancel')}</Button>
            </Flex>
            
            <S.CreateInstructionWrapper>
                <ExpandableContainer isOpen={_expands.info} toggle={() => toggleExpand('info')} text={t('Information*')}>
                    <TextFieldCustom
                        required
                        value={form.name}
                        name={'name'}
                        onChange={handleChange}
                        label={t('Review campaign name')}
                        size={'small'}
                        sx={{margin: '16px 0 0 0'}}
                    />

                    <TextFieldCustom
                        // required
                        value={form.description}
                        multiline
                        minRows={5}
                        name={'description'}
                        onChange={handleChange}
                        label={t('Description')}
                        // size={'small'}
                        sx={{margin: '16px 0 0 0'}}
                    />
                </ExpandableContainer>

                <ExpandableContainer isOpen={_expands.schedule} toggle={() => toggleExpand('schedule')} text={t('Scheduler')}>
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">{t('Choose trigger type')}</FormLabel>
                        <RadioGroup row onChange={actions.handleSetScheduleType} value={form.trigger.type}>
                            <FormControlLabel value="schedule" control={<Radio />} label={t("Schedule")} />
                            <FormControlLabel value="manual" control={<Radio />} label={t("Manual")} />
                        </RadioGroup>
                    </FormControl>
                    {form.trigger.type === 'schedule' ?
                        <>
                            <FormLabel id="demo-row-radio-buttons-group-label">{t('Choose repeat value')}</FormLabel>
                            <Flex w={'100%'} jc={'space-between'} ai={'center'} m={'8px 0 0 0'}>
                                <TextField
                                    id="filled-number"
                                    label={t("Number")}
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={translatedRepeat.num}
                                    onChange={(e) => {handleSelectRepeatNum(parseInt(e.target.value) || 1)}}
                                />
                                <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="demo-dialog-select-label">{t('Select')}</InputLabel>
                                    <Select
                                        labelId="demo-dialog-select-label"
                                        id="demo-dialog-select"
                                        value={translatedRepeat.value}
                                        onChange={(e) => {handleSelectRepeatValue(e.target.value as string)}}
                                        input={<OutlinedInput label={t('Select')} />}
                                    >
                                        {timeValues.filter(e => e !== 'Hours' && e !== 'Days').map(e => <MenuItem  key={e} value={e}>{t(`timeValue.${e}`)}</MenuItem>) }
                                    </Select>
                                </FormControl>
                            </Flex>
                            {/*<FormLabel id="demo-row-radio-buttons-group-label">Choose when to start</FormLabel>*/}
                            <Flex w={'98%'}>
                                <DesktopDatePicker
                                    label={t("Choose scheduler start date")}
                                    inputFormat={DEFAULT_DATE_FORMAT}
                                    value={form.trigger.start}
                                    disablePast
                                    onChange={actions.handleSetStartTrigger}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Flex>
                        </>
                        :
                        <>

                        </>
                    }
                </ExpandableContainer>

                <ExpandableContainer isOpen={_expands.apps} toggle={() => toggleExpand('apps')} text={t('Included applications*')}>
                    <Flex w={'98%'} jc={'space-between'} ai={'center'}>
                        <Typography fontSize={'14px'} fontWeight={700} color={colors.grayText}>{t('Applications for review')}</Typography>
                        <Typography fontSize={'14px'} fontWeight={700} color={colors.grayText}>{t('Include')}</Typography>
                    </Flex>

                    <>
                        {_apps.map(e => {
                            const isAdded = Boolean(form.applications.some(app => app.id === e.id));
                            return(
                                <>
                                    <Flex w={'95%'} jc={'space-between'} ai={'center'} p={'8px 0'} m={'8px 0 0 0'}>
                                        <Flex w={'50%'}>
                                            <Typography variant={'body1'} color={colors.grayText}>{e.name}</Typography>
                                        </Flex>
                                        <Flex ai={'center'} w={'10%'}>
                                            <CommonSwitch
                                                value={isAdded}
                                                onChange={() => {
                                                    isAdded ? actions.handleDeleteApp(e.id) : actions.handleAddApp(e.id, e.name)
                                                }}
                                            />
                                            <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 0 0 8px' : '0 8px 0 0'}}>{(isAdded ? t('Yes') : t('No'))}</Typography>
                                        </Flex>
                                    </Flex>
                                    <Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable}}/>
                                </>
                            )
                        })}
                    </>
                </ExpandableContainer>

                <ExpandableContainer isOpen={_expands.eta} toggle={() => toggleExpand('eta')} text={t('Estimating time*')} error={isCommonExecutionTimeIsBiggerThanRepeat}>
                    {/*{''.length > 0 && <h1>asda</h1>}*/}
                    {/*{isCommonExecutionTimeIsBiggerThanRepeat && <h1>hello</h1>}*/}

                    {isCommonExecutionTimeIsBiggerThanRepeat ? <Typography variant={'body1'} color={colors.text.critical}>{t('The repeating time and the estimating time of stages do not match. Choose a different number of days.')}</Typography> : <></>}

                    <Typography variant={'body1'} color={colors.grayText} sx={{margin: '8px 0 0 0'}}>{t('Select estimating time for Stage Managers')}</Typography>
                    <TextFieldCustom
                        required
                        value={form.etaConfig.etaManager}
                        type={'number'}
                        InputProps={{ inputProps: { min: 1 } }}
                        name={'etaManager'}
                        onChange={handleChangeEtaConfig}
                        label={t('Number in days')}
                        size={'small'}
                        sx={{margin: '12px 0 0 0'}}
                    />

                    <Typography variant={'body1'} color={colors.grayText} sx={{margin: '16px 0 0 0'}}>{t('Select estimating time for Stage Vendor owners')}</Typography>
                    <TextFieldCustom
                        required
                        value={form.etaConfig.etaVendor}
                        type={'number'}
                        InputProps={{ inputProps: { min: 1 } }}
                        name={'etaVendor'}
                        onChange={handleChangeEtaConfig}
                        label={t('Number in days')}
                        size={'small'}
                        sx={{margin: '12px 0 0 0'}}
                    />

                    <Typography variant={'body1'} color={colors.grayText} sx={{margin: '16px 0 0 0'}}>{t('Select estimating time for Stage Execution')}</Typography>
                    <TextFieldCustom
                        required
                        value={form.etaConfig.etaExecution}
                        type={'number'}
                        InputProps={{ inputProps: { min: 1 } }}
                        name={'etaExecution'}
                        onChange={handleChangeEtaConfig}
                        label={t('Number in days')}
                        size={'small'}
                        sx={{margin: '12px 0 0 0'}}
                    />

                    <Typography variant={'body1'} color={colors.grayText} sx={{margin: '16px 0 0 0'}}>{t('Select estimating time for Stage Verify')}</Typography>
                    <TextFieldCustom
                        required
                        value={form.etaConfig.etaVerify}
                        type={'number'}
                        InputProps={{ inputProps: { min: 1 } }}
                        name={'etaVerify'}
                        onChange={handleChangeEtaConfig}
                        label={t('Number in days')}
                        size={'small'}
                        sx={{margin: '12px 0 0 0'}}
                    />

                    <Typography variant={'body1'} color={colors.grayText} sx={{margin: '16px 0 0 0'}}>{t('Select estimating time for Stage Sign')}</Typography>
                    <TextFieldCustom
                        required
                        value={form.etaConfig.etaSign}
                        type={'number'}
                        InputProps={{ inputProps: { min: 1 } }}
                        name={'etaSign'}
                        onChange={handleChangeEtaConfig}
                        label={t('Number in days')}
                        size={'small'}
                        sx={{margin: '12px 0 0 0'}}
                    />
                </ExpandableContainer>

                <ExpandableContainer isOpen={_expands.finalApprover} toggle={() => toggleExpand('finalApprover')} text={t('Final approver*')}>
                    <AutocompleteCollaborators<false, false, true>
                        key={uniqueInputId}
                        freeSolo
                        freeSoloType={'email'}
                        getOptionLabelCustom={"name+email"}
                        getOptionLabel={(option) => optionIsTCollaborator(option) ? option.email : option}
                        label={t("Select employee")}
                        required

                        helperText={isEmailValid ? undefined : t('Email is invalid')}
                        sx={{margin: '8px 0 0 0'}}

                        shouldForwardErrorValue
                        defaultValue={form.finalApprover?.email || ''}
                        onChange={value => {
                            actions.handleSetFinalApprover(
                                optionIsTCollaborator(value)
                                    ? value
                                    : {email: value as string, publicId: null, firstName: null, lastName: null}
                            )
                        }}
                    />
                </ExpandableContainer>

                <ExpandableContainer isOpen={_expands.evidence} toggle={() => toggleExpand('evidence')} text={t('Saving*')}>
                    <Flex w={'100%'}>
                        <IconTextButton icon={<AddIcon />} variant={'text'} onClick={actions.handleOpenAddEvidence}>
                            {t('Add evidence')}
                        </IconTextButton>
                    </Flex>

                    <Flex w={'100%'} direction={'column'}>
                        {form.evidences.map(el => <SelectedEvidenceCard key={el.evidenceId} evidence={el} onDelete={actions.handleDeleteEvidence}/>)}
                    </Flex>

                    {!form.evidences.length ? <Typography variant={'body1'} color={colors.grayText} sx={{margin: '16px 0 0 0'}}>{t('No evidences selected')}</Typography> : <></>}
                </ExpandableContainer>
            </S.CreateInstructionWrapper>

            <SelectEvidenceDialog
                handleSelectEvidence={actions.handleSelectEvidence}
                addedEvidenceIds={form.evidences.map(e => e.evidenceId)}
            />
        </Flex>
        )
}