import {createSelector, createSlice} from "@reduxjs/toolkit";
import {
    DataVariableModel,
    DelegatedTaskModel,
    GetOriginTemplateByIdQuery,
    MainVicaCategoryCollaboratorModel,
    MainVicaCategoryStepModel,
    MainVicaDelegatedTaskModel
} from "../../../../newShared/GQLTypes";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {
    mainVicaApproveDelegatedTaskByIdAction,
    mainVicaCancelDelegatedTaskByIdAction,
    mainVicaChangeStepStatusAction,
    MainVicaCreateDelegatedTaskAction,
    mainVicaDeclineDelegatedTaskByIdAction,
    mainVicaGetCategoriesWithFilterAction,
    mainVicaGetCategoryStepByIdAction,
    mainVicaGetCategoryStepsWithFilterAction,
    mainVicaGetDelegatedTaskByIdAction,
    mainVicaGetDelegatedTasksWithFilterPaginationAction,
    mainVicaGetWSCollaboratorsAction,
    mainVicaUpdateCategorySupervisorAction,
    mainVicaUpdateStepResponsibleCollaboratorsAction
} from "./actions";
import {MainVicaCategoryLocalModel, TCreateFormDoc, vicaTaskAction} from "../types";
import {convertVicaCategoryToMainVicaCategoryLocalModel} from "../helpers";
import {
    DocumentFolder,
    TEditorFillableBlockData,
    TNewDocument,
    TNewDocumentExecutor
} from "../../documentsRefactor/types";
import {TFileContent} from "../../../../newShared/components/editorUnion/types";
import {handlePagination} from "../../../../newShared/components/genericTable/helpers";
import {minMaxLoadedPageDefault} from "../../../../newShared/components/genericTable/constants";
import {TPageInfo} from "../../../../newShared/types";
import {minMaxLoadedPageType} from "../../../../newShared/components/genericTable/types";
import {addDownloadFileActionCases} from "../../../../newShared/actions/downloadFile";
import {addSetDialogAction} from "../../../../newShared/actions/setDialogAction";
import {defaultFormCreateDoc, defaultPageInfo} from "../constants";

import {
    CreateNewDoc,
    GetFolderTree,
    GetFullDocumentWithExecutor,
    GetNewDocumentById,
    GetNewTemplateById,
    InvalidateDocument,
    RestartSignStageByStageIdAndGroupId,
    RestartSignStageByStageIdAndRecipient,
    SendToNextStepByStageId
} from "../../documentsRefactor/store/actions";
import {sortFolders, sortRootFolders} from "../../documentsRefactor/helpers";
import {uuid} from "../../../../newShared/utils";
import {getMsFromDays} from "../../../../newShared/utils/dateTools";
import {uploadEvidenceAssetAction} from "../../vica/store/tasks/actions";


type vicaInitialState = {
    documentsFolders: DocumentFolder[];
    selectedNewDocument: {
        document: TNewDocument | null;
        documentExecutor: TNewDocumentExecutor | null;
    } | null
    selectedNewTemplate: TNewDocument | null;

     vicaTasks: {
         categories: MainVicaCategoryLocalModel[];
         // steps: MainVicaCategoryStepModel[];
     },

     delegatedTasks: {
         tasks: MainVicaDelegatedTaskModel[];
         pageInfo: TPageInfo;
         minMaxLoadedPage: minMaxLoadedPageType;

         selectedStep: MainVicaCategoryStepModel | null;
         selectedTask: MainVicaDelegatedTaskModel | null;
     },

    wsCollaborators: MainVicaCategoryCollaboratorModel[];

    loadings: {
        isLoadingCreate: boolean;
        categoriesList: boolean;
        taskList: string[];
        delegatedTaskList: boolean;
        delegatedTaskExact: boolean;
        selectedStep: boolean;
        selectedTask: boolean;
        changeVicaStepStatus: boolean;
        updateCategorySupervisor: boolean;
        getCollaborators: boolean;
        updateStepResponsibleCollaborators: boolean;
        isLoadingUploadFile: boolean;
        isLoadingSaveDoc: boolean;
        isLoadingSetResponsible: boolean;
        cancelDelegateTask: boolean;
        declineDelegateTask: boolean;
        approveDelegateTask: boolean;
        isDownloadingFile: string[];

        isLoadingInvalidate: boolean;
        isLoadingRestartSignStageByStageIdAndGroupId: boolean;
        isLoadingRestartSignStageByStageIdAndRecipient: boolean;
        isLoadingSendToNextStep: boolean;
        isLoadingExact: boolean;
        isLoadingTree: boolean;
        isLoadingTemplate: boolean;
    }

    dialogs: {
        createDelegatedTask: {
            vicaStepId: string | null,
        },
        stepFrameworksDialog: {
            step: MainVicaCategoryStepModel | null,
            isOpen: boolean,
        }
         stepDescription: {
             step: MainVicaCategoryStepModel | null;
         };
        actionUploadDialog: {
            isOpen: boolean,
            categoryId: string,
            task: MainVicaCategoryStepModel | null,
            action: vicaTaskAction | null,
        },
        actionTemplateDialog: {
            isOpen: boolean,
            categoryId: string,
            task: MainVicaCategoryStepModel | null,
            action: vicaTaskAction | null,
            form: TCreateFormDoc;

            template: {
                id: string,
                name: string,
                config: DataVariableModel[],
                innerHtml: string,
            } | null,

            newTemplate: GetOriginTemplateByIdQuery["getOriginTemplateById"] | null,
            docId: string | null,
        }

        addGroupMessage: {
            isOpen: boolean,
            approverId: string | null,
            groupId: string | null,
            recipientId: string | null,
            oldMessage: string | null,
        },
        selectCategorySupervisor: {
            isOpen: boolean;
            category: MainVicaCategoryLocalModel | null;
        },

        selectStepResponsible: {
            isOpen: boolean;
            step: MainVicaCategoryStepModel | null;
            categoryName: string | null;
        };
        cancelDelegatedTask: {
            isOpen: boolean,
            task: MainVicaDelegatedTaskModel | null,
        }
    }
}

export const initialState: vicaInitialState = {
    selectedNewDocument: null,
    selectedNewTemplate: null,
    documentsFolders: [],

    vicaTasks: {
        categories: [],
        // steps: [],
    },

    delegatedTasks: {
        tasks: [],
        pageInfo: defaultPageInfo,
        minMaxLoadedPage: minMaxLoadedPageDefault,

        selectedStep: null,
        selectedTask: null,
    },

    wsCollaborators: [],

    loadings: {
        isLoadingCreate: false,
        categoriesList: false,
        taskList: [],
        selectedStep: false,
        delegatedTaskList: false,
        delegatedTaskExact: false,
        changeVicaStepStatus: false,
        updateCategorySupervisor: false,
        getCollaborators: false,
        updateStepResponsibleCollaborators: false,

        selectedTask: false,
        cancelDelegateTask: false,
        declineDelegateTask: false,
        approveDelegateTask: false,

        isDownloadingFile: [],
        isLoadingUploadFile: false,
        isLoadingSaveDoc: false,
        isLoadingSetResponsible: false,

        isLoadingInvalidate: false,
        isLoadingRestartSignStageByStageIdAndGroupId: false,
        isLoadingRestartSignStageByStageIdAndRecipient: false,
        isLoadingSendToNextStep: false,
        isLoadingExact: false,
        isLoadingTree: false,
        isLoadingTemplate: false
    },

    dialogs: {
        createDelegatedTask: {
            vicaStepId: null,
        },
        stepFrameworksDialog: {
            isOpen: false,
            step: null,
        },
        stepDescription: {
            step: null,
        },
        actionUploadDialog: {
            isOpen: false,
            categoryId: '',
            task: null,
            action: null,
        },
        actionTemplateDialog: {
            isOpen: false,
            categoryId: '',
            task: null,
            action: null,
            form: defaultFormCreateDoc,

            template: null,
            newTemplate: null,
            docId: null,
        },
        addGroupMessage: {
            isOpen: false,
            approverId: null,
            groupId: null,
            recipientId: null,
            oldMessage: null,
        },
        selectCategorySupervisor: {
            isOpen: false,
            category: null
        },

        selectStepResponsible: {
            isOpen: false,
            step: null,
            categoryName: null
        },
        cancelDelegatedTask: {
            isOpen: false,
            task: null,
        }
    },
}

export const vicaTasksSlice = createSlice({
    name: 'vicaTasks',
    initialState,
    reducers: {
        ...addSetDialogAction('createDelegatedTask', initialState),
        ...addSetDialogAction('stepDescription', initialState),
        ...addSetDialogAction('cancelDelegatedTask', initialState),

        replaceDelegatedTasksMinMaxLoadedPage: (slice, { payload }: { payload: minMaxLoadedPageType }) => {
            slice.delegatedTasks.minMaxLoadedPage = payload;
        },
        eraseDelegatedTasks: (slice) => {
            slice.delegatedTasks.tasks = [];
            slice.delegatedTasks.pageInfo = defaultPageInfo;
        },
        eraseSelectedDelegatedTaskAction: (slice) => {
            slice.delegatedTasks.selectedTask = null;
        },
        setSelectStepResponsible: (slice, {payload}: {payload: Partial<vicaInitialState["dialogs"]["selectStepResponsible"]> & {clear?: boolean}}) => {
            if(payload.isOpen !== undefined) slice.dialogs.selectStepResponsible.isOpen = payload.isOpen;
            if(payload.step !== undefined) slice.dialogs.selectStepResponsible.step = payload.step;
            if(payload.categoryName !== undefined) slice.dialogs.selectStepResponsible.categoryName = payload.categoryName;
            if(payload.clear !== undefined) slice.dialogs.selectStepResponsible = initialState.dialogs.selectStepResponsible;
        },
        toggleVicaStep: (slice, {payload}: {payload: {category: string, isOpen: boolean}}) => {
            slice.vicaTasks.categories = slice.vicaTasks.categories.map(e => e.name === payload.category ? ({...e, isOpen: payload.isOpen}) : e);
        },

        setFormCreateDoc: (state, {payload}: {payload: { form?: TCreateFormDoc }}) => {
            if(payload.form !== undefined) state.dialogs.actionTemplateDialog.form = payload.form;
        },
        openActionDialog: (slice, {payload}: {payload: {categoryId?: string, task: MainVicaCategoryStepModel, action: vicaTaskAction}}) => {
            switch (payload.action.type){
                case 'TEMPLATE':{
                    slice.dialogs.actionTemplateDialog = {categoryId: payload.categoryId ?? '', action: payload.action, task: payload.task, isOpen: true, template: null, newTemplate: null, docId: null, form: defaultFormCreateDoc};
                    return;
                }
                case 'UPLOAD': {
                    slice.dialogs.actionUploadDialog = {categoryId: payload.categoryId ?? '', action: payload.action, task: payload.task, isOpen: true};
                }
            }
        },
        hideActionDialog: (slice, {payload}: {payload: ('actionTemplateDialog' | 'actionUploadDialog')[]}) => {
            if (payload.includes('actionTemplateDialog') || !payload.length) {
                slice.dialogs.actionTemplateDialog = initialState.dialogs.actionTemplateDialog;
            }
            if (payload.includes('actionUploadDialog') || !payload.length) {
                slice.dialogs.actionUploadDialog = {categoryId: '', task: null, action: null, isOpen: false}
            }
        },
        openAddGroupMessage: (slice, {payload}: {payload: {approverId?: string, groupId?: string, oldMessage?: string, recipientId?: string}}) => {
            if(payload.approverId !== undefined && payload.groupId !== undefined) {
                slice.dialogs.addGroupMessage.isOpen = true;
                slice.dialogs.addGroupMessage.approverId = payload.approverId;
                slice.dialogs.addGroupMessage.groupId = payload.groupId;
            }
            if(payload.recipientId !== undefined) {
                slice.dialogs.addGroupMessage.isOpen = true;
                slice.dialogs.addGroupMessage.recipientId = payload.recipientId;
            }
            if(payload.oldMessage !== undefined) slice.dialogs.addGroupMessage.oldMessage = payload.oldMessage;
        },
        hideAddGroupMessage: (slice) => {
            slice.dialogs.addGroupMessage = initialState.dialogs.addGroupMessage;
        },
        deselectDocument: (slice) => {
            slice.selectedNewDocument = null;
        },
        handleReturnToDraftAction: (slice) => {
            if (slice.selectedNewDocument?.document?.data) {
                slice.selectedNewDocument.document.recipients = slice.selectedNewDocument.document.recipients!.map(e => ({...e, fields: e.fields?.filter(e => !e.pageId.startsWith('pdfPage'))}));

                slice.selectedNewDocument.document.data.editor = slice.selectedNewDocument.document.data.editor.filter((e: TFileContent[number]) => e.type !== 'pdfPage');

                slice.selectedNewDocument.document.data.pdfFileId = null;
            }
        },

        openSelectCategorySupervisor: (slice, {payload}: {payload: {category: MainVicaCategoryLocalModel}}) => {slice.dialogs.selectCategorySupervisor = {category: payload.category, isOpen: true}},
        hideSelectCategorySupervisor: (slice) => {slice.dialogs.selectCategorySupervisor = {category: null, isOpen: false}},

        openStepFrameworksDialog: (slice, {payload}: {payload: {step: MainVicaCategoryStepModel}}) => {slice.dialogs.stepFrameworksDialog = {step: payload.step, isOpen: true}},
        hideStepFrameworksDialog: (slice) => {slice.dialogs.stepFrameworksDialog = {step: null, isOpen: false}},

        cleanUp: (slice) => initialState,
    },
    extraReducers: (builder) => {
        addDownloadFileActionCases(builder);

        builder
            // mainVicaGetCategoriesWithFilterAction
            .addCase(mainVicaGetCategoriesWithFilterAction.pending, (slice, {meta}) => {
                slice.loadings.categoriesList = true;
            })
            .addCase(mainVicaGetCategoriesWithFilterAction.rejected, (slice) => {
                slice.loadings.categoriesList = false;
            })
            .addCase(mainVicaGetCategoriesWithFilterAction.fulfilled, (slice, {payload, meta}) => {
                slice.loadings.categoriesList = false;
                slice.vicaTasks.categories = convertVicaCategoryToMainVicaCategoryLocalModel(payload.result, slice.vicaTasks.categories, meta.arg.addictiveData?.previouslyOpenedCategories);
            })

            //mainVicaGetCategoryStepsWithFilterAction
            .addCase(mainVicaGetCategoryStepsWithFilterAction.pending, (slice, {meta}) => {
                slice.loadings.taskList = [...slice.loadings.taskList, meta.arg.data.category];
            })
            .addCase(mainVicaGetCategoryStepsWithFilterAction.rejected, (slice, {meta}) => {
                slice.loadings.taskList = slice.loadings.taskList.filter(e => e !== meta.arg.data.category);
                slice.vicaTasks.categories = slice.vicaTasks.categories.map(e => e.name === meta.arg.data.category ? ({...e, stepsLocal: [], isOpen: false}) : e);
            })
            .addCase(mainVicaGetCategoryStepsWithFilterAction.fulfilled, (slice, {payload, meta}) => {
                slice.loadings.taskList = slice.loadings.taskList.filter(e => e !== meta.arg.data.category);
                slice.vicaTasks.categories = slice.vicaTasks.categories.map(e => e.name === meta.arg.data.category ? ({...e, stepsLocal: payload.result, isOpen: e.isOpen, fetchedNoResult: !payload.result.length}) : e);
            })

            //mainVicaGetCategoryStepByIdAction
            .addCase(mainVicaGetCategoryStepByIdAction.pending, (slice, {meta}) => {
                slice.loadings.selectedStep = true;
            })
            .addCase(mainVicaGetCategoryStepByIdAction.rejected, (slice) => {
                slice.loadings.selectedStep = false;
            })
            .addCase(mainVicaGetCategoryStepByIdAction.fulfilled, (slice, {payload}) => {
                slice.loadings.selectedStep = false;
                slice.delegatedTasks.selectedStep = payload;
            })

            //mainVicaUpdateCategorySupervisorAction
            .addCase(mainVicaUpdateCategorySupervisorAction.pending, (slice, {meta}) => {
                slice.loadings.updateCategorySupervisor = true;
            })
            .addCase(mainVicaUpdateCategorySupervisorAction.rejected, (slice) => {
                slice.loadings.updateCategorySupervisor = false;
            })
            .addCase(mainVicaUpdateCategorySupervisorAction.fulfilled, (slice, {payload, meta}) => {
                slice.loadings.updateCategorySupervisor = false;
                slice.dialogs.selectCategorySupervisor = {isOpen: false, category: null};
                slice.vicaTasks.categories = slice.vicaTasks.categories.map(e => e.name === meta.arg.data.categoryId ? {...e, supervisor: meta.arg.data.supervisor} : e);
            })
            //mainVicaChangeStepStatusAction
            .addCase(mainVicaChangeStepStatusAction.pending, (slice, {meta}) => {
                slice.loadings.changeVicaStepStatus = true;
            })
            .addCase(mainVicaChangeStepStatusAction.rejected, (slice) => {
                slice.loadings.changeVicaStepStatus = false;
            })
            .addCase(mainVicaChangeStepStatusAction.fulfilled, (slice, {payload, meta}) => {
                slice.loadings.changeVicaStepStatus = false;
                if (slice.delegatedTasks.selectedStep) {
                    slice.delegatedTasks.selectedStep = {...slice.delegatedTasks.selectedStep, status: meta.arg.data.status}
                }
                //no need as status changes on exact page - does not affect list of tasks
                // slice.vicaTasks.categories.map(e => e.id === meta.arg.data.stepId ? ({...e, status: meta.arg.data.status}) : e);
            })

            //mainVicaUpdateStepResponsibleCollaboratorsAction
            .addCase(mainVicaUpdateStepResponsibleCollaboratorsAction.pending, (slice, {meta}) => {
                slice.loadings.updateStepResponsibleCollaborators = true;
            })
            .addCase(mainVicaUpdateStepResponsibleCollaboratorsAction.rejected, (slice) => {
                slice.loadings.updateStepResponsibleCollaborators = false;
            })
            .addCase(mainVicaUpdateStepResponsibleCollaboratorsAction.fulfilled, (slice, {payload, meta}) => {
                slice.loadings.updateStepResponsibleCollaborators = false;
                if (slice.delegatedTasks.selectedStep){
                    slice.delegatedTasks.selectedStep.responsible = Array.isArray(meta.arg.data.responsible) ? meta.arg.data.responsible : [meta.arg.data.responsible];
                }
                if(slice.dialogs.selectStepResponsible.categoryName){
                    slice.vicaTasks.categories = slice.vicaTasks.categories.map(e => e.name === slice.dialogs.selectStepResponsible.categoryName ?
                        {
                            ...e,
                            stepsLocal: e.stepsLocal.map(s => s.id === meta.arg.data.stepId ? {...s, responsible: Array.isArray(meta.arg.data.responsible) ? meta.arg.data.responsible : [meta.arg.data.responsible]} : s)
                        } : e
                    );
                }
                slice.dialogs.selectStepResponsible = initialState.dialogs.selectStepResponsible;
            })

        //mainVicaGetDelegatedTasksWithFilterPaginationAction
            .addCase(mainVicaGetDelegatedTasksWithFilterPaginationAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.delegatedTaskList = true;

                if (clean) {
                    slice.delegatedTasks.tasks = initialState.delegatedTasks.tasks;
                    slice.delegatedTasks.pageInfo = initialState.delegatedTasks.pageInfo;
                    slice.delegatedTasks.minMaxLoadedPage = initialState.delegatedTasks.minMaxLoadedPage;
                }
            })
            .addCase(mainVicaGetDelegatedTasksWithFilterPaginationAction.rejected,
                (slice) => {
                    slice.loadings.delegatedTaskList = false;
                })
            .addCase(mainVicaGetDelegatedTasksWithFilterPaginationAction.fulfilled,(slice, { payload }) => {
                slice.loadings.delegatedTaskList = false;

                const { results, maxLoadedPage, minLoadedPage } =
                    handlePagination<DelegatedTaskModel>(
                        slice.delegatedTasks.tasks,
                        slice.delegatedTasks.pageInfo,
                        payload.result,
                        payload.pageInfo,
                        slice.delegatedTasks.minMaxLoadedPage.minLoadedPage,
                        slice.delegatedTasks.minMaxLoadedPage.maxLoadedPage,
                        "id"
                    );

                slice.delegatedTasks.tasks = results;
                slice.delegatedTasks.minMaxLoadedPage = {
                    minLoadedPage,
                    maxLoadedPage,
                };
                slice.delegatedTasks.pageInfo = payload.pageInfo;
            })

            //mainVicaGetDelegatedTaskByIdAction
            .addCase(mainVicaGetDelegatedTaskByIdAction.pending, (slice: vicaInitialState, {meta}) => {
                slice.loadings.delegatedTaskExact = true;
            })
            .addCase(mainVicaGetDelegatedTaskByIdAction.rejected, (slice: vicaInitialState, payload) => {
                slice.loadings.delegatedTaskExact = false;
            })
            .addCase(mainVicaGetDelegatedTaskByIdAction.fulfilled, (slice: vicaInitialState, {payload}) => {
                slice.loadings.delegatedTaskExact = false;
                slice.delegatedTasks.selectedTask = payload;
                slice.delegatedTasks.tasks = slice.delegatedTasks.tasks.map(e => e.id === payload.id ? payload : e);
            })

            .addCase(mainVicaApproveDelegatedTaskByIdAction.pending, (slice: vicaInitialState, {meta}) => {
                slice.loadings.approveDelegateTask = true;
            })
            .addCase(mainVicaApproveDelegatedTaskByIdAction.rejected, (slice: vicaInitialState, payload) => {
                slice.loadings.approveDelegateTask = false;
            })
            .addCase(mainVicaApproveDelegatedTaskByIdAction.fulfilled, (slice: vicaInitialState, {payload}) => {
                slice.loadings.approveDelegateTask = false;
                slice.delegatedTasks.tasks = slice.delegatedTasks.tasks.map(e => e.id === payload.id ? payload : e);
            })

            .addCase(mainVicaCancelDelegatedTaskByIdAction.pending, (slice: vicaInitialState, {meta}) => {
                slice.loadings.cancelDelegateTask = true;
            })
            .addCase(mainVicaCancelDelegatedTaskByIdAction.rejected, (slice: vicaInitialState, payload) => {
                slice.loadings.cancelDelegateTask = false;
            })
            .addCase(mainVicaCancelDelegatedTaskByIdAction.fulfilled, (slice: vicaInitialState, {payload}) => {
                slice.loadings.cancelDelegateTask = false;
                slice.delegatedTasks.tasks = slice.delegatedTasks.tasks.map(e => e.id === payload.id ? payload : e);
            })

            .addCase(mainVicaDeclineDelegatedTaskByIdAction.pending, (slice: vicaInitialState, {meta}) => {
                slice.loadings.declineDelegateTask = true;
            })
            .addCase(mainVicaDeclineDelegatedTaskByIdAction.rejected, (slice: vicaInitialState, payload) => {
                slice.loadings.declineDelegateTask = false;
            })
            .addCase(mainVicaDeclineDelegatedTaskByIdAction.fulfilled, (slice: vicaInitialState, {payload}) => {
                slice.loadings.declineDelegateTask = false;
                slice.delegatedTasks.tasks = slice.delegatedTasks.tasks.map(e => e.id === payload.id ? payload : e);
            })


        //mainVicaGetWSCollaboratorsAction
            .addCase(mainVicaGetWSCollaboratorsAction.pending, (slice, {meta}) => {
                slice.loadings.getCollaborators = true;
            })
            .addCase(mainVicaGetWSCollaboratorsAction.rejected, (slice) => {
                slice.loadings.getCollaborators = false;
            })
            .addCase(mainVicaGetWSCollaboratorsAction.fulfilled, (slice, {payload}) => {
                slice.loadings.getCollaborators = false;
                slice.wsCollaborators = payload.result;
            })
            .addCase(MainVicaCreateDelegatedTaskAction.pending, (slice) => {
                slice.loadings.isLoadingCreate = true;
            })
            .addCase(MainVicaCreateDelegatedTaskAction.rejected, (slice) => {
                slice.loadings.isLoadingCreate = false;
            })
            .addCase(MainVicaCreateDelegatedTaskAction.fulfilled, (slice) => {
                slice.loadings.isLoadingCreate = false;
                slice.dialogs.createDelegatedTask = initialState.dialogs.createDelegatedTask;
            })

        //---------docs
            .addCase(CreateNewDoc.pending, (slice) => {
                slice.loadings.isLoadingSaveDoc = true;
            })
            .addCase(CreateNewDoc.rejected, (slice) => {
                slice.loadings.isLoadingSaveDoc = false;

            })
            .addCase(CreateNewDoc.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingSaveDoc = false;
                slice.dialogs.actionTemplateDialog = initialState.dialogs.actionTemplateDialog;
                // slice.dialogs.actionTemplateDialog.docId = payload.id;
            })

            .addCase(InvalidateDocument.pending, (slice) => {
                slice.loadings.isLoadingInvalidate = true;
            })
            .addCase(InvalidateDocument.rejected, (slice) => {
                slice.loadings.isLoadingInvalidate = false;
            })
            .addCase(InvalidateDocument.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingInvalidate = false;
                if(payload.file){
                    slice.selectedNewDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                }
            })

            .addCase(RestartSignStageByStageIdAndGroupId.pending, (slice) => {
                slice.loadings.isLoadingRestartSignStageByStageIdAndGroupId = true;
            })
            .addCase(RestartSignStageByStageIdAndGroupId.rejected, (slice) => {
                slice.loadings.isLoadingRestartSignStageByStageIdAndGroupId = false;
            })
            .addCase(RestartSignStageByStageIdAndGroupId.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingRestartSignStageByStageIdAndGroupId = false;
                if(payload.file){
                    slice.selectedNewDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                }
            })

            .addCase(RestartSignStageByStageIdAndRecipient.pending, (slice) => {
                slice.loadings.isLoadingRestartSignStageByStageIdAndRecipient = true;
            })
            .addCase(RestartSignStageByStageIdAndRecipient.rejected, (slice) => {
                slice.loadings.isLoadingRestartSignStageByStageIdAndRecipient = false;
            })
            .addCase(RestartSignStageByStageIdAndRecipient.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingRestartSignStageByStageIdAndRecipient = false;
                if(payload.file){
                    slice.selectedNewDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                }
            })

            .addCase(SendToNextStepByStageId.pending, (slice) => {
                slice.loadings.isLoadingSendToNextStep = true;
            })
            .addCase(SendToNextStepByStageId.rejected, (slice) => {
                slice.loadings.isLoadingSendToNextStep = false;
            })
            .addCase(SendToNextStepByStageId.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingSendToNextStep = false;
                if(payload.file){
                    slice.selectedNewDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                }
            })

            .addCase(GetNewDocumentById.pending, (slice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(GetNewDocumentById.rejected, (slice) => {
                slice.loadings.isLoadingExact = false;

            })
            .addCase(GetNewDocumentById.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingExact = false;
                slice.selectedNewDocument = { document: payload, documentExecutor: null};
            })

            .addCase(GetFolderTree.pending, (slice) => {
                slice.loadings.isLoadingTree = true;
            })
            .addCase(GetFolderTree.rejected, (slice) => {
                slice.loadings.isLoadingTree = false;

            })
            .addCase(GetFolderTree.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingTree = false;
                let newFolders = [...payload]
                    .map(e => e.name === 'Trash' ? ({...e, id: 'trash'}) : e);
                const sortMap = sortRootFolders(newFolders);
                newFolders = newFolders.sort((a, b) => sortFolders(a.id, b.id, sortMap));
                slice.documentsFolders = newFolders;
            })

            .addCase(GetFullDocumentWithExecutor.pending, (slice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(GetFullDocumentWithExecutor.rejected, (slice) => {
                slice.loadings.isLoadingExact = false;
            })
            .addCase(GetFullDocumentWithExecutor.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingExact = false;
                if(payload.resp.file !== undefined && payload.resp.file !== null){
                    slice.selectedNewDocument = {
                        document: payload.resp.file,
                        documentExecutor: payload.resp.executor ?? null
                    }
                }
            })

            .addCase(GetNewTemplateById.pending, (slice) => {
                slice.loadings.isLoadingTemplate = true;
            })
            .addCase(GetNewTemplateById.rejected, (slice) => {
                slice.loadings.isLoadingTemplate = false;

            })
            .addCase(GetNewTemplateById.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingTemplate = false;
                slice.selectedNewTemplate = payload;

                if (slice.dialogs.actionTemplateDialog.isOpen) {
                    slice.dialogs.actionTemplateDialog.form.name = payload.name;
                    slice.dialogs.actionTemplateDialog.form.recipients = payload.recipients?.map(e => ({
                        id: uuid(),
                        role: e.role,
                        message: e.message || '',
                        actor: e.actor,
                        fields: e.fields?.map(f => ({...f, type: f.type as TEditorFillableBlockData['type']})) || [],
                        order: e.order,
                        eta: e.eta ?? getMsFromDays(7),
                    })) || [];
                }
            })

        //upload file action
            .addCase(uploadEvidenceAssetAction.pending, (slice, {meta}) => {
                slice.loadings.isLoadingUploadFile = true;
            })
            .addCase(uploadEvidenceAssetAction.rejected, (slice, {meta}) => {
                slice.loadings.isLoadingUploadFile = false;
            })
            .addCase(uploadEvidenceAssetAction.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingUploadFile = false;
                slice.dialogs.actionUploadDialog = {categoryId: '', task: null, action: null, isOpen: false}
            })
    }
});

export const {
    setStepDescriptionAction,
    toggleVicaStep,

    cleanUp,

    setFormCreateDoc,
    deselectDocument,
    handleReturnToDraftAction,

    openAddGroupMessage,
    hideAddGroupMessage,
    openActionDialog,
    hideActionDialog,

    openSelectCategorySupervisor,
    hideSelectCategorySupervisor,
    replaceDelegatedTasksMinMaxLoadedPage,
    eraseDelegatedTasks,
    eraseSelectedDelegatedTaskAction,
    setSelectStepResponsible,
    openStepFrameworksDialog,
    hideStepFrameworksDialog,
    setCreateDelegatedTaskAction,
    setCancelDelegatedTaskAction,
} = vicaTasksSlice.actions;

export const vicaRefactorTasksReducer = vicaTasksSlice.reducer;

const selectSelf = (state: AppState): vicaInitialState => state.vicaTasksRefactor;

export const dialogs = createSelector(selectSelf, state => state.dialogs);
export const loadings = createSelector(selectSelf, state => state.loadings);
export const vicaTasksSelector = createSelector(selectSelf, state => state.vicaTasks);
export const vicaDelegatedTasksSelector = createSelector(selectSelf, state => state.delegatedTasks);
export const wsCollaboratorsSelector = createSelector(selectSelf, state => state.wsCollaborators);

export const selectedNewTemplateSelector = createSelector(selectSelf, state => state.selectedNewTemplate);
export const selectedNewDocument = createSelector(selectSelf, state => state.selectedNewDocument);
export const folders = createSelector(selectSelf, state => state.documentsFolders);


