//Core
import React, {FC} from "react";

//Router
import {Redirect, Route, Switch} from 'react-router';
import {
    PATH_LOCAL_2FA,
    PATH_LOCAL_ASSET_MANAGEMENT,
    PATH_LOCAL_COLLECTORS,
    PATH_LOCAL_COMPLIANCE_FRAMEWORKS,
    PATH_LOCAL_COMPLIANCE_REPORTS,
    PATH_LOCAL_DOCUMENTS,
    PATH_LOCAL_EMPLOYEES,
    PATH_LOCAL_HELP,
    PATH_LOCAL_INITIAL_MFA_CONFIGURE,
    PATH_LOCAL_INTEGRATIONS,
    PATH_LOCAL_LOGIN,
    PATH_LOCAL_LOGIN_CONTACT_US,
    PATH_LOCAL_LOGOUT,
    PATH_LOCAL_MFA_RESET,
    PATH_LOCAL_PASSWORD_RESET,
    PATH_LOCAL_RISK_MANAGEMENT,
    PATH_LOCAL_SIGNUP,
    PATH_LOCAL_VENDORS_MANAGEMENT,
    PATH_LOCAL_VERIFY_EMAIL,
    PATH_LOCAL_VULNERABILITY
} from "../../../newShared/constants";
import {ContactUs} from "../../components/contactUs";
import {Frameworks} from "../../components/frameworks";
import {VulnerabilityScanner} from "../../components/vulnerabilityScanner";
import {Employees} from "../../components/employeesRefactor";
import {Reports} from "../../components/reports";
import {PhysicalAssets} from "../../components/physicalAssets";
import {RiskManagement} from "../../components/riskManagement";
import {Settings} from "../../authWorkspacesCookies/settings";
import {
    ACCEPT_INVITATION,
    ACCEPT_INVITATION_PROTECTED,
    ACCEPT_INVITATION_UNPROTECTED,
    SETTING_ROOT_PATH
} from "../../authWorkspacesCookies/settings/constants";
import {InvitationAcceptSelector} from "../../authWorkspacesCookies/settings/pages/acceptInvitationSelector";
import {AcceptInvite} from "../../authWorkspacesCookies/settings/pages/acceptInvite";
import {ForbiddenWrapper} from "../../authWorkspacesCookies/settings/pages/forbiddenWrapper";
import {PATH_LOCAL_AUTOMATIONS} from "../../components/automations/constants";
import {Automations} from "../../components/automations";
// import {Documents} from "../../components/documents";
import {Vendors} from "../../components/vendors";
import {NewIntegrations} from "../../components/newIntegrations";
import {ROOT_BUILDER} from "../../components/builder/constants";
// import {Kyc} from "../../components/kyc";
import {Builder} from "../../components/builder";
import {PATH_LOCAL_ACCESS_MANAGEMENT} from "../../components/accessManagement/constants";
import {AccessManagement} from "../../components/accessManagement";
import {Trainings} from "../../components/Trainings";
import {TRAININGS_PATH} from "../../components/Trainings/constants";
import {Collectors} from "../../components/collectors";
import {SignIn} from "../../authWorkspacesCookies/signIn";
import {PATH_LOCAL_VICA} from "../../components/vica/constants";
import {DocumentsRefactor} from "../../components/documentsRefactor";
import {KycRefactor} from "../../components/kycRefactor";
import {KYC_ROOT_PATH} from "../../components/kycRefactor/constants";
import {VicaRefactor} from "../../components/vicaRefactor";


export const Routes: FC = () => {
    return <Switch>
        <Route path="/" exact>
            <Redirect to={PATH_LOCAL_VICA}/>
        </Route>

    {/*INIT*/}
        {/*    Login*/}
        <Route exact path={[
            PATH_LOCAL_LOGIN,
            PATH_LOCAL_2FA,

            PATH_LOCAL_MFA_RESET,
            PATH_LOCAL_PASSWORD_RESET,

            PATH_LOCAL_VERIFY_EMAIL,
            PATH_LOCAL_SIGNUP,
            PATH_LOCAL_INITIAL_MFA_CONFIGURE,

            PATH_LOCAL_LOGIN_CONTACT_US,

            ACCEPT_INVITATION_UNPROTECTED,
        ]} component={SignIn}/>

        {/*<Route exact path={PATH_LOCAL_LOGIN} component={SignInOld}/>*/}
        {/*<Route exact path={PATH_LOCAL_2FA} component={SignIn2FA}/>*/}
        {/*<Route exact path={PATH_LOCAL_PASSWORD_RESET} component={SignRestorePass}/>*/}
        {/*<Route exact path={PATH_LOCAL_SIGNUP} component={SignUp}/>*/}

        {/*<Route path={PATH_LOCAL_NEW_WORKSPACES} component={NewWorkspaces}/>*/}

        <Route exact path={ACCEPT_INVITATION} component={InvitationAcceptSelector} />

        <Route exact path={ACCEPT_INVITATION_PROTECTED} component={AcceptInvite} />
        {/*<Route exact path={ACCEPT_INVITATION_UNPROTECTED} component={AcceptInvite} />*/}

        <Route path={SETTING_ROOT_PATH}>
            {/*<ForbiddenWrapper path={SETTING_ROOT_PATH} pathName={'Settings'}>*/}
            {/*    <Settings />*/}
            {/*</ForbiddenWrapper>*/}
            <Settings />
        </Route>
        {/*<Route path={SETTING_ROOT_PATH} component={Settings}/>*/}

        <Route exact path={PATH_LOCAL_LOGOUT}>
            <Redirect to={PATH_LOCAL_LOGIN}/>
        </Route>

    {/*Settings*/}
        <Route exact path={PATH_LOCAL_HELP} component={ContactUs}/>

    {/*INNER PAGES*/}
        <Route path={PATH_LOCAL_COMPLIANCE_FRAMEWORKS}>
            <ForbiddenWrapper path={PATH_LOCAL_COMPLIANCE_FRAMEWORKS} pathName={'Frameworks'}>
                <Frameworks />
            </ForbiddenWrapper>
        </Route>
        <Route path={PATH_LOCAL_COMPLIANCE_REPORTS}>
            <ForbiddenWrapper path={PATH_LOCAL_COMPLIANCE_REPORTS} pathName={'Reports'}>
                <Reports />
            </ForbiddenWrapper>
        </Route>

        <Route path={PATH_LOCAL_VULNERABILITY}>
            <ForbiddenWrapper path={PATH_LOCAL_VULNERABILITY} pathName={'Vulnerability scanner'}>
                <VulnerabilityScanner />
            </ForbiddenWrapper>
        </Route>

        <Route path={PATH_LOCAL_ASSET_MANAGEMENT}>
            <ForbiddenWrapper path={PATH_LOCAL_ASSET_MANAGEMENT} pathName={'Physical assets'}>
                <PhysicalAssets />
            </ForbiddenWrapper>
        </Route>

        <Route path={PATH_LOCAL_EMPLOYEES}>
            <ForbiddenWrapper path={PATH_LOCAL_EMPLOYEES} pathName={'Employees'}>
                <Employees />
            </ForbiddenWrapper>
        </Route>

        <Route path={PATH_LOCAL_RISK_MANAGEMENT}>
            <ForbiddenWrapper path={PATH_LOCAL_RISK_MANAGEMENT} pathName={'Employees'}>
                <RiskManagement />
            </ForbiddenWrapper>
        </Route>

        <Route path={PATH_LOCAL_AUTOMATIONS} >
            <ForbiddenWrapper path={PATH_LOCAL_AUTOMATIONS} pathName={'Automations'} isOnlyForBusiness>
                <Automations />
            </ForbiddenWrapper>
        </Route>

        <Route path={PATH_LOCAL_DOCUMENTS}>
            <ForbiddenWrapper path={PATH_LOCAL_DOCUMENTS} pathName={'Documents'}>
                <DocumentsRefactor />
                {/*<Documents />*/}
            </ForbiddenWrapper>
        </Route>

        <Route path={PATH_LOCAL_INTEGRATIONS} component={
            () => (
                <ForbiddenWrapper path={PATH_LOCAL_INTEGRATIONS} pathName={'Integrations'} isOnlyForBusiness>
                    <NewIntegrations />
                </ForbiddenWrapper>
            )
        }/>

        <Route path={PATH_LOCAL_VENDORS_MANAGEMENT}>
            <ForbiddenWrapper path={PATH_LOCAL_VENDORS_MANAGEMENT} pathName={'Vendors'}>
                <Vendors />
            </ForbiddenWrapper>
        </Route>

        <Route path={ROOT_BUILDER}>
            <ForbiddenWrapper path={ROOT_BUILDER} pathName={'Framework builder'} isOnlyForEnterprise>
                <Builder />
            </ForbiddenWrapper>
        </Route>

        {/*<Route path={PATH_LOCAL_KYC}>*/}
        {/*    <ForbiddenWrapper path={PATH_LOCAL_KYC} pathName={'KYC'}>*/}
        {/*        <Kyc />*/}
        {/*    </ForbiddenWrapper>*/}
        {/*</Route>*/}

        <Route path={KYC_ROOT_PATH}>
            <ForbiddenWrapper path={KYC_ROOT_PATH} pathName={'KYC'}>
                <KycRefactor />
            </ForbiddenWrapper>
        </Route>


        <Route path={PATH_LOCAL_ACCESS_MANAGEMENT}>
            <ForbiddenWrapper path={PATH_LOCAL_ACCESS_MANAGEMENT} pathName={'Access Management'} isOnlyForBusiness>
                <AccessManagement />
            </ForbiddenWrapper>
        </Route>

        <Route path={TRAININGS_PATH}>
            <ForbiddenWrapper path={TRAININGS_PATH} pathName={'Trainings'}>
                <Trainings />
            </ForbiddenWrapper>
        </Route>

        <Route path={PATH_LOCAL_COLLECTORS}>
            <ForbiddenWrapper path={PATH_LOCAL_COLLECTORS} pathName={'Collectors'} isOnlyForBusiness>
                <Collectors />
            </ForbiddenWrapper>
        </Route>

        <Route path={PATH_LOCAL_VICA}>
            <ForbiddenWrapper path={PATH_LOCAL_VICA} pathName={'VICA'} isOnlyForBusiness>
                <VicaRefactor />
            </ForbiddenWrapper>
        </Route>

        {/*<Route path={'/tiny'}>*/}
        {/*    <Box sx={{width: '100%', height: '100%', overflow: 'hidden', padding: '16px'}}>*/}
        {/*        <TinyMceEditor initialContent={localStorage.getItem('content') || ''} onSave={(e => console.log(e))}/>*/}
        {/*    </Box>*/}
        {/*</Route>*/}

        {/*<Route path={PATH_LOCAL_VICA}>*/}
        {/*    <ForbiddenWrapper path={PATH_LOCAL_VICA} pathName={'VICA'} isOnlyForBusiness>*/}
        {/*        <Vica />*/}
        {/*    </ForbiddenWrapper>*/}
        {/*</Route>*/}

        {/*REDIRECT*/}
        <Redirect to={PATH_LOCAL_VICA}/>
    </Switch>;
}
