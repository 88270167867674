import {useDispatch, useSelector} from "react-redux";
import {MainVicaCategoryStepModel} from "../../../../../../newShared/GQLTypes";
import {dialogs, setStepDescriptionAction} from "../../../store/slice";

export const useStepDescriptionDialog = () => {
    const dispatch = useDispatch();

    const {step} = useSelector(dialogs).stepDescription

    const handleOpen = (step: MainVicaCategoryStepModel) => {
        dispatch(setStepDescriptionAction({step}));
    };
    const handleClose = () => {
        dispatch(setStepDescriptionAction({clear: true}));
    };

    return {
        isOpen: !!step,
        step,

        handleClose,
        handleOpen,
    }
};