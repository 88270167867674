import {useTransferOwnershipDialog} from "../../../../hooks/organizations/dialogs/useTransferOwnershipDialog";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {LoadingButton} from "../../../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {AutocompleteCollaborators} from "../../../../../../../newShared/components/AutocompleteCollaborators";
import colors from "../../../../../../../newShared/theme/colors";
import {Flex} from "../../../../../../../newShared/components/Layouts";

export const TransferOwnership = () => {
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'pathSettings.dialogs'});
    const {
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        isOk,
        handleSelectCollab,
        organizationId,
        currentUserEmail
    } = useTransferOwnershipDialog();

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Transfer ownership')}</DialogTitle>

            <DialogContent>
                <Flex direction={'column'}>
                    <Typography variant={'*bodyText2'} color={colors.grayText}>{t('Ownership of organization will be transferred. You will not be able to manage workspaces and subscriptions in organization. Your role in organization\'s workspaces will be changed to "viewer". Make sure you have 1 additional seat in every workspace for transfer.')}</Typography>

                    <Typography variant={'*bodyText2'} sx={{margin: '16px 0'}} color={colors.grayText}>{t(`Select collaborator from one of organization's workspaces you want to promote to "owner".`)}</Typography>

                    <AutocompleteCollaborators
                        required
                        defaultValue={null}
                        hideCount
                        label={'Select collaborator'}
                        byAllWorkspaces
                        organizationId={organizationId}
                        getOptionLabelCustom={'name+email'}
                        onChange={handleSelectCollab}
                        getOptionDisabled={(option) => option.email === currentUserEmail}
                    />
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                    disabled={!isOk}
                >
                    {t('Transfer')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
