//core
import {useDispatch, useSelector} from "react-redux";

//redux
import {
    addedFilesArray,
    deletedFilesArray,
    openAddPipeline,
    saveChanges,
    selectedAutomation
} from "../../../store/slice";

//material
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

//types
import {automationType} from "../../../types";
import {DeleteAllFile, GetAutomationById, UpdateAutomation} from "../../../store/actions";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const SaveChangesDialog = () => {
    const dispatch = useDispatch();
    const {t} = useMainTranslation('', {keyPrefix: 'automationsPage'});

    const auto: automationType | null = useSelector(selectedAutomation);
    const uploaded: string[] = useSelector(addedFilesArray);
    const deleted: string[] = useSelector(deletedFilesArray);
    const {isOpen, transiteTo, isAdd} = useSelector(saveChanges);

    const handleSelect = () => {
        if(auto?.id && auto?.id.length > 0){
            //if user uploaded some files and then deleted them the will appear in deletedArray
            deleted.length > 0 && dispatch(DeleteAllFile({fileIds: deleted, autoId: ''}));

            dispatch(UpdateAutomation(auto));

        }else if(auto && !auto?.id.length){
            //ADD AUTOMATION
            //if user made some changes on page with new automation (not pushed) if he clicks on another row shows SaveChanges dialog and if select true we need to do add and not update
            //if user uploaded some files and then deleted them the will appear in deletedArray
            //need to delete them all (already uploaded files and not deleted already in cloud)
            dispatch(DeleteAllFile({fileIds: deleted, autoId: ''}));
            // dispatch(AddAutomation(auto));
        }

        //TRANSITE
        if(isAdd){
            dispatch(openAddPipeline());
        }else{
            dispatch(GetAutomationById({data: {id: transiteTo}}));
        }
    }

    const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
        if (reason !== 'backdropClick') {
            //DONT SAVE -> DELETE ALL UPLOADED FILES
            if(uploaded.length > 0){
                dispatch(DeleteAllFile({fileIds: uploaded, autoId: auto?.id || ''}));
            }

            if(isAdd){
                dispatch(openAddPipeline());
            }else{
                dispatch(GetAutomationById({data: {id: transiteTo}}));
                // dispatch(selectAutomation({data: {id: transiteTo}}));
            }
            // dispatch(selectAutomation({data: {id: transiteTo}}));
        }
    };


    return (auto &&
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{t('Save or not to save')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {t('You made some changes in automation. Do you wish to save them?')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t('No, thanks')}</Button>
                <Button onClick={handleSelect}>{t('Save')}</Button>
            </DialogActions>
        </Dialog>
    );
}

//NEW PIPE
// add file -> upload and put in pipeline without save
// delete file -> delete file
// docType change -> deleteAllFiles without save
// save -> (already uploaded) just save
// dont save -> deleteAllFiles in doc.links if type file

//ADDED PIPE
//add file -> upload file and add to pipe without save + add to array of Added
//delete file -> add to array of deleted -> deleteAll on save (if it in added array,
//docType change -> add to array of deleted -> deleteAll on save
//save  -> deleteAll from deletedarray
//dontSave -> deleteAll from added array