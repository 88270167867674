import {useCreateDelegatedTaskDialog} from "../../../hooks/useCreateDelegatedTaskDialog";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {AutocompleteCollaborators} from "../../../../../../newShared/components/AutocompleteCollaborators";
import React from "react";
import {TEvidence} from "../../../types";
import {optionIsTCollaborator} from "../../../../../../newShared/components/AutocompleteCollaborators/types";
import {getName} from "../../../../../../newShared/utils/text";
import {CollaboratorRole, WorkspaceCollaboratorWithRolesModel} from "../../../../../../newShared/GQLTypes";

export const CreateDelegatedTaskDialog = (props: {refreshTable: () => void, delegatedTasksOpen: boolean}) => {
    const {isOpen,
        formik,
        evidences,
        handleCreate,
        handleClose,
        isLoadingCreate,
        isLoadingControl,
        evidenceDescription,
        setEvidenceDescription} = useCreateDelegatedTaskDialog(props);
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathFrameworks.pathDelegatedTasks'})
    const isOptionDisabled = (value: WorkspaceCollaboratorWithRolesModel): boolean => {
        return !value.roles.includes(CollaboratorRole.RolePortalMember);
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Create a delegated task')}</DialogTitle>
            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                <Autocomplete<TEvidence>
                    size={"small"}
                    options={evidences || []}
                    value={evidences.find(e => e.id === formik.values.evidenceId) || null}
                    getOptionLabel={e => e.name}
                    loading={isLoadingControl}
                    onChange={(e, selected) => {
                        formik.setFieldTouched('evidenceId');
                        formik.setFieldValue(
                            'evidenceId', selected ? selected.id : null
                        );
                        selected ? setEvidenceDescription(selected.description) : setEvidenceDescription('');
                    }
                    }
                    renderInput={(params) => (
                        <TextField {...params} label={t('Select an evidence')}
                                   helperText={formik.touched.evidenceId && formik.errors.evidenceId}
                                   error={formik.touched.evidenceId && Boolean(formik.errors.evidenceId)}
                                   name={'evidenceId'}
                                   onBlur={formik.handleBlur}

                        />
                    )}
                />
                <Typography variant={'*bodyText2'} mt={evidenceDescription ? '0' : '-20px'}>{evidenceDescription}</Typography>
                <Typography variant={'*bodySmall'}>{t('Only a collaborator with Portal Access can be selected')}</Typography>

                <AutocompleteCollaborators<false, false, false>
                    sx={{marginTop: '-15px'}}
                    getOptionLabelCustom={"name+email"}
                    getOptionLabel={(option) => optionIsTCollaborator(option)
                        ? getName(option.firstName || '', option.lastName || '')
                        : option}
                    defaultValue={null}
                    inputOnBlur={formik.handleBlur}
                    inputName={'collaborator'}
                    onChange={value => {
                        formik.setFieldTouched('collaborator');
                        formik.setFieldValue('collaborator', value ? {
                            collaboratorId: value.publicId, fullName: getName(value.firstName || '', value.lastName || ''), email: value.email,
                        } : null);
                    }}
                    label={t('Select a collaborator')}
                    helperText={formik.touched.collaborator ? formik.errors.collaborator : undefined}
                    getOptionDisabled={value => isOptionDisabled(value)}
                />
                <TextField
                    multiline
                    rows={4}
                    label={t('Enter comment')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.comment}
                    name={'comment'}
                    error={formik.touched.comment && Boolean(formik.errors.comment)}
                    helperText={formik.touched.comment && formik.errors.comment}
                    />


            </DialogContent>
            <DialogActions>
                <Button size={'small'}
                    variant={'text'}
                    onClick={handleClose}
                >{tCommon('Cancel')}</Button>
                <LoadingButton
                    size={'small'}
                    variant={'contained'}
                    onClick={handleCreate}
                    loading={isLoadingCreate}
                    disabled={!formik.isValid}
                >{tCommon('Create')}</LoadingButton>

            </DialogActions>
        </Dialog>
    )
}