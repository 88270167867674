import {useAutomationsCreate} from "../../hooks/useAutomationsCreate";
import {Flex} from "../../../../../newShared/components/Layouts";
import colors from "../../../../../newShared/theme/colors";
import {Typography} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import React from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {isNeededETCTooBigWarning, needSave} from "../../helpers";
import {Typo} from "../../../../../newShared/components/Typography";
import {Pipeline} from "../../components/pipeLine";
import {ChangeNameDialog} from "../../components/dialogs/changeName";
import {DeletePipelineDialog} from "../../components/dialogs/deletePipeline";
import {DocFileSelectionDialog} from "../../components/dialogs/docFileSelection";
import {DocLinkSelectionDialog} from "../../components/dialogs/docLinkSelection";
import {DocPolicySelectionDialog} from "../../components/dialogs/docPolicySelection";
import {DocDialog} from "../../components/dialogs/docTypeSelection";
import {SaveChangesDialog} from "../../components/dialogs/saveChanges";
import {SaveEvidenceSelectionDialog} from "../../components/dialogs/saveEvidenceSelection";
import {StageActorDialog} from "../../components/dialogs/stageActor";
import {StageSettings} from "../../components/dialogs/stageSettings";
import {TriggerDateDialog} from "../../components/dialogs/triggerDate";
import {TriggerRepeatDialog} from "../../components/dialogs/triggerRepeat";
import {TriggerDialog} from "../../components/dialogs/triggerSelection";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";

export const AutomationsCreate = () => {
    const {
        isLoadingCreate,
        auto,
        allElements,
        onLoad,
        actions,
        prev,
        isGenereting
    } = useAutomationsCreate();
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'automations'});

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('Create automation')}
                handleGoBack={actions.handleGoBack}
                actions={[
                    {
                        title: t('Change name'),
                        onClick: actions.handleOpenChangeNameDialog,
                        variant: 'text',
                        icon: <EditIcon />
                    }
                ]}
            />

            <Flex ai={'center'} m={'8px 0 0 0'}>
                <Typography variant={"body1"}  sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', color: auto && !auto.name.trim().length ? colors.warningActions.red : colors.lightGrayText}}>{t('Name')}</Typography>
                <Typography variant={"body1"}  sx={{color: colors.grayText}}>{auto?.name}</Typography>
            </Flex>

            <Flex w={'100%'} ai={'center'} m={'16px 0 0 0'}>
                <LoadingButton
                    loading={isLoadingCreate}
                    onClick={actions.handleSave}
                    disabled={!auto || !needSave(auto, prev) || !auto.name.trim().length}
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                >
                    {t('Create')}
                </LoadingButton>
            </Flex>

            {auto && isNeededETCTooBigWarning(auto.trigger.type, auto.trigger.repeat, auto.stages.map(e => e.eta)) &&
                <Typo fontWeight={300} fontSize={'14px'} color={'#FF5959'} sx={{margin: '16px 0 0 0', lineHeight: 'normal'}}>{('Warning: Common execution time of stages is bigger then trigger repeat variable.')}</Typo>
            }

            <Pipeline
                isLoading={false}
                isGenereting={isGenereting}
                preferPipeline={true} //does nothing
                onLoad={onLoad}
                setPreferPipeLine={() => null}
                allElements={allElements}
            />


            <ChangeNameDialog />
            <DeletePipelineDialog />
            <DocFileSelectionDialog />
            <DocLinkSelectionDialog />
            <DocPolicySelectionDialog />
            <DocDialog />
            <SaveChangesDialog/>
            <SaveEvidenceSelectionDialog />
            <StageActorDialog />
            <StageSettings />
            <TriggerDateDialog />
            <TriggerRepeatDialog />
            <TriggerDialog />
        </Flex>
    )
}