import {useDispatch, useSelector} from "react-redux";
import {
    cleanRegistryRisks,
    dialogs,
    replaceRegistryRisksMinMaxLoadedPage,
    setImportCsvDialogAction
} from "../../../store/slice";
import {
    getCsvFieldsRisksAction,
    getRisksByRegistryAction,
    importCsvTemplateRisksAction,
    performCsvRisksAction
} from "../../../store/actions";
import {TImportCsvDialogProps} from "../../../../../../newShared/components/csvImportDialog";
import {resetImportCsvDialog} from "../../../../../../newShared/components/csvImportDialog/constants";
import {DEFAULT_PAGING} from "../../../../../../newShared/components/genericTable/constants";

export const useImportCsv = (): TImportCsvDialogProps => {
    const dispatch = useDispatch();
    const {importCsvDialog: {
        isOpen,
        isLoading: {
            isGettingFields,
            isPerformingCsv,
            isLoadingTemplate,
        },
        fields,
        fileId,
        data,
        response
    }} = useSelector(dialogs);

    const setImportDialog: TImportCsvDialogProps["setImportDialog"] = (data) => dispatch(setImportCsvDialogAction(data));
    const downloadTemplate = () => {dispatch(importCsvTemplateRisksAction())};

    const getFields: TImportCsvDialogProps["getFields"] = (file) => {data.registryId && dispatch(getCsvFieldsRisksAction({
        data: {...file, registryId: data.registryId},
        onError: resetImportCsvDialog
    }))};

    const performCsv: TImportCsvDialogProps["performCsv"] = (fields) => {data.registryId && fileId && dispatch(performCsvRisksAction({
        data: {fields, fileId, registryId: data.registryId},
        snack: "success",
        // onError: (request, error, addictiveData) => {dispatch(setImportCsvDialogAction({error: error[0]?.message || 'Unknown error'}))},
        onSuccess: (request) => {
            dispatch(cleanRegistryRisks());
            dispatch(replaceRegistryRisksMinMaxLoadedPage({minLoadedPage: -1, maxLoadedPage: -1}))
            dispatch(getRisksByRegistryAction({data: {
                    workspaceId: '',
                    organizationId: '',
                    registryId: request.registryId,
                    pageInfo: {page: 0, count: DEFAULT_PAGING, filters: {}}
                }}));
        },
    }))};

    return {
        isOpen: isOpen && !!data.registryId,
        isLoadingTemplate, isPerformingCsv, isGettingFields,
        fields,
        setImportDialog,
        downloadTemplate,
        getFields,
        performCsv,
        response,
        fieldsMapperKey: "risks"
    }
}
