import React, {FC, Fragment} from "react";
import {
    Box,
    CircularProgress,
    IconButton,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {TAssetDataAUTOMATION, TAssetDataFILE} from "../../../frameworks/types";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useReport} from "../../hooks/useReport";
import {useReportDialogs} from "../../hooks/useReportDialogs";
import {usePDFView} from "../../../../../newShared/components/docViewer/hooks/usePDFView";
import {ReportCollectorTableDialog} from "../../components/dialogs/collectorTableDialog";
import {StatementReportDialog} from "../../components/dialogs/statementReportDialog";

export const EvidencesReportControl: FC = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathReports.View'});

    const {
        report: {report}, controls: {selectedControl},
        isLoading: {isLoadingControl, isLoadingFiles, isLoadingReport},
        downloadFile, downloadTable,
    } = useReport();
    const {
        collectorView: {setCollectorView},
        statement: {setStatementDialog}
    } = useReportDialogs();

    const {handleOpen} = usePDFView();
    return (
        <Box width={'100%'} display={"flex"} flexDirection={"column"} overflow={"auto"}>
            <Typography variant={"subtitle2"} color={colors.text.dark}>{t('Automation')}</Typography>
            {isLoadingControl || isLoadingReport || !selectedControl ? (
                <>
                    <Typography variant={"body2"} color={colors.text.dark} maxWidth={'800px'} margin={'4px'}>{<Skeleton/>}</Typography>
                    <Typography variant={"body2"} color={colors.text.dark} maxWidth={'800px'} margin={'4px'}>{<Skeleton/>}</Typography>
                </>
            ) : (
                <>
                    <TableContainer style={{overflow: 'auto'}} className={'withoutBorder'}>
                        <Table size={'micro'}>
                            <TableBody>
                                {selectedControl?.evidences?.automations && selectedControl?.evidences?.automations.length === 0 && (
                                    <Typography variant={"body1"} color={colors.text.dark} marginTop={'6px'}>
                                        {t('The user has not published any automations.')}
                                    </Typography>
                                )}

                                {selectedControl?.evidences?.automations.map(asset => (
                                    <Fragment key={asset.id}>
                                        {asset.type === 'COLLECTOR' && (
                                            <TableRow sx={{'& td': {borderBottom: 'none'}}}>
                                                <TableCell width={1000} style={{paddingLeft: !revDir ? 0 : undefined, paddingRight: !revDir ? undefined : 0}}>
                                                    <Box display={"flex"} alignItems={"center"}>
                                                        <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                    disabled={isLoadingFiles.includes(asset.id)}
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        report && downloadTable(report.id, asset.id, asset.evidenceId)
                                                                    }}>
                                                            {isLoadingFiles.includes(asset.id)
                                                                ? <CircularProgress size={24}/>
                                                                : <DownloadIcon style={{color: colors.text.blue}}/>}
                                                        </IconButton>

                                                        <IconButton size={'small'} sx={{marginRight: '10px'}}
                                                                    onClick={() => {
                                                                        report && selectedControl &&
                                                                        setCollectorView({
                                                                            ids: {reportId: report.id, controlId: selectedControl.id, assetId: asset.id, evidenceId: asset.evidenceId},
                                                                            isOpen: true
                                                                        })}}>
                                                            <ArticleOutlinedIcon style={{color: colors.text.blue}}/>
                                                        </IconButton>
                                                        {asset.name}
                                                    </Box>
                                                </TableCell>
                                                <TableCell align={"center"} sx={{whiteSpace: 'nowrap'}}>{parseDateAuto(asset.lastCollected)}</TableCell>
                                            </TableRow>
                                        )}

                                        {asset.type === 'AUTOMATION' && (asset.data as TAssetDataAUTOMATION)
                                            .reduce((prev, curr) => {
                                                const array: TAssetDataFILE = [];
                                                Object.keys(curr).forEach((value) => {
                                                    array.push(...curr[value]);
                                                })
                                                return [...prev, ...array]
                                            }, [] as TAssetDataFILE)
                                            .map((file, index, array) => (
                                                <TableRow key={file.fileId} sx={{'& td': {borderBottom: 'none'}}}>
                                                    <TableCell width={1000} style={{paddingLeft: !revDir ? 0 : undefined, paddingRight: !revDir ? undefined : 0}}>
                                                        <Box display={"flex"} alignItems={"center"}>
                                                            <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                        disabled={isLoadingFiles.includes(file.fileId)}
                                                                        onClick={(event) => {
                                                                            event.stopPropagation();
                                                                            downloadFile(file.fileId as string || '', file.name as string || '')
                                                                        }}>
                                                                {isLoadingFiles.includes(file.fileId)
                                                                    ? <CircularProgress size={24}/>
                                                                    : <DownloadIcon style={{color: colors.text.blue}}/>}
                                                            </IconButton>

                                                            {file.name.endsWith('.pdf') && (
                                                                <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                            onClick={(event) => {
                                                                                event.stopPropagation();
                                                                                handleOpen({fileId: file.fileId as string || '', fileName: file.name as string || ''})
                                                                            }}>
                                                                    <VisibilityIcon style={{color: colors.text.blue}}/>
                                                                </IconButton>
                                                            )}

                                                            {file.name}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align={"center"} sx={{whiteSpace: 'nowrap'}}>{parseDateAuto(file.lastUpdate)}</TableCell>
                                                </TableRow>
                                            ))}
                                    </Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}

            <Typography variant={"subtitle2"} color={colors.text.dark} marginTop={'20px'}>{t('Manual files')}</Typography>
            {isLoadingControl || isLoadingReport || !selectedControl ? (
                <>
                    <Typography variant={"body2"} color={colors.text.dark} maxWidth={'800px'} margin={'4px'}>{<Skeleton/>}</Typography>
                    <Typography variant={"body2"} color={colors.text.dark} maxWidth={'800px'} margin={'4px'}>{<Skeleton/>}</Typography>
                </>
            ) : (
                <>
                    <TableContainer style={{overflow: "auto"}} className={'withoutBorder'}>
                        <Table size={"micro"}>
                            <TableBody>
                                {selectedControl?.evidences?.files && selectedControl?.evidences?.files.length === 0 && (
                                    <Typography variant={"body1"} color={colors.text.dark} marginTop={'6px'}>
                                        {t('The user has not published any manual files.')}
                                    </Typography>
                                )}

                                {selectedControl?.evidences?.files.map(asset => (
                                    <TableRow sx={{'& td': {borderBottom: 'none'}}} key={asset.id}>
                                        <TableCell width={1000} style={{paddingLeft: !revDir ? 0 : undefined, paddingRight: !revDir ? undefined : 0}}>
                                            <Box display={"flex"} alignItems={"center"}>
                                                <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                            disabled={isLoadingFiles.includes(asset.data[0]?.fileId)}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                downloadFile(asset.data[0]?.fileId as string || '', asset.data[0]?.name as string || '')
                                                            }}>
                                                    {isLoadingFiles.includes(asset.data[0]?.fileId)
                                                        ? <CircularProgress size={24}/>
                                                        : <DownloadIcon style={{color: colors.text.blue}}/>}
                                                </IconButton>

                                                {asset.name.endsWith('.pdf') && (
                                                    <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    handleOpen({fileId: asset.data[0]?.fileId as string || '', fileName: asset.data[0]?.name as string || ''})
                                                                }}>
                                                        <VisibilityIcon style={{color: colors.text.blue}}/>
                                                    </IconButton>
                                                )}

                                                {asset.name}
                                            </Box>
                                        </TableCell>
                                        <TableCell align={"center"} sx={{whiteSpace: 'nowrap'}}>{parseDateAuto(asset.lastCollected)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}

            <Typography variant={"subtitle2"} color={colors.text.dark} marginTop={'20px'}>{t('Statements')}</Typography>
            {isLoadingControl || isLoadingReport || !selectedControl ? (
                <>
                    <Typography variant={"body2"} color={colors.text.dark} maxWidth={'800px'} margin={'4px'}>{<Skeleton/>}</Typography>
                    <Typography variant={"body2"} color={colors.text.dark} maxWidth={'800px'} margin={'4px'}>{<Skeleton/>}</Typography>
                </>
            ) : (
                <>
                    <TableContainer style={{overflow: "auto"}} className={'withoutBorder'}>
                        <Table size={'micro'}>
                            <TableBody>
                                {selectedControl?.evidences?.statements && selectedControl?.evidences?.statements.length === 0 && (
                                    <Typography variant={"body1"} color={colors.text.dark} marginTop={'6px'}>
                                        {t('The user has not published any statements.')}
                                    </Typography>
                                )}

                                {selectedControl?.evidences?.statements.map(asset => (
                                    <TableRow sx={{'& td': {borderBottom: 'none'}}} key={asset.id}>
                                        <TableCell width={1000} style={{paddingLeft: !revDir ? 0 : undefined, paddingRight: !revDir ? undefined : 0}}>
                                            <Box display={"flex"} alignItems={"center"}>
                                                <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                setStatementDialog({data: asset.data, isOpen: true})
                                                            }}>
                                                    <VisibilityIcon style={{color: colors.text.blue}}/>
                                                </IconButton>

                                                {asset.data?.[0]?.name || ''}
                                            </Box>
                                        </TableCell>
                                        <TableCell align={"center"} sx={{whiteSpace: 'nowrap'}}>{parseDateAuto(asset.lastCollected)}</TableCell>
                                    </TableRow>
                                ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}

            <ReportCollectorTableDialog/>
            <StatementReportDialog/>
        </Box>
    )
}