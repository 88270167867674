import {FC} from "react";
import {LinkIconButton} from "./styled";
import {TToolBarHandlerAction, TToolBarHandlerPayload} from "../../types";
import FormatClearIcon from '@mui/icons-material/FormatClear';
import {withShrinkZero} from "../../../hoc/withShrinkZero";
import {EditorTooltip} from "../../../commonComponents/editorTooltip";

interface LinkControlProps {
    isLinkSet?: boolean;
    linkSetClickHandler?: (action: TToolBarHandlerAction, payload: TToolBarHandlerPayload) => void;
    isDisabled: boolean
}

const RemoveFormattingControl: FC<LinkControlProps> = ({linkSetClickHandler = () => {}, isDisabled}) => {

    return <EditorTooltip title="Remove formatting">
        <span>
            <LinkIconButton
                disabled={isDisabled}
                size="medium"
                edge={undefined}
                onClick={(e) => {
                    e.stopPropagation();
                    linkSetClickHandler("removeFormatting", true)
                }}>
            <FormatClearIcon/>
        </LinkIconButton>
        </span>
    </EditorTooltip>;
}

const wrapper = withShrinkZero<LinkControlProps>(RemoveFormattingControl);

export {wrapper as RemoveFormattingControl};
