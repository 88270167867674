import {FC} from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {DeleteDialogButton} from "../../../../../../newShared/components/deleteDialogButton";
import {useDeleteDuty} from "../../../hooks/dialogHooks/useDeleteDuty";

export const DeleteDutyDialog: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        isOpen,
        isLoadingDelete,
        handleDelete,
        handleCancel
    } = useDeleteDuty();

    return (
        <DeleteDialogButton isOpen={isOpen} isLoading={isLoadingDelete}
                            title={t('Delete duty')}
                            message={t('The changes made will not affect those already running rule checks. Delete this duty anyway?')}
                            handleConfirm={handleDelete}
                            handleClose={handleCancel}
        />
    )
}
