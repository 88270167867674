import * as yup from "yup";

export {};

export const validationSchemaRegistration = yup.object({
    firstName: yup
        .string()
        .required('First name is required'),
    lastName: yup
        .string()
        .required('Last name is required'),
    organizationName: yup
        .string()
        .min(1)
        .required('Organization name is required'),
    email: yup
        .string()
        .email('Not valid email')
        .required('Email is required'),
    password: yup
        .string()
        .min(8, 'Password should be of minimum 8 characters length')
        .matches(
            /(?=.*[A-Z])/,
            "Must Contain one Uppercase character"
        )
        .matches(
            /(?=.*[a-z])/,
            "Must Contain one Lowercase character"
        )
        .matches(
            /(?=.*[0-9])/,
            "Must Contain one Number character"
        )
        .matches(
            /(?=.*[!@#$%^&*])/,
            "Must contain one Special case character"
        )
        .required('New password is required'),
    passwordConfirm: yup
        .string()
        .oneOf([yup.ref("password"), null], "Password does not match")
        .required('Confirm password is required'),
    licence: yup
        .boolean()
        .isTrue('Need agree the licence')
});