import colors from "../../../../newShared/theme/colors";
import {Circle} from "../../../../newShared/components/Layouts"
import {CUSTOM_FIELDS_ID, RISK_ID, SERVICE_PROVIDED_ID, TYPE_ID} from "../constants";
import {VendorEvaluationInput} from "../../../../newShared/GQLTypes";
import {SettingsTypes} from "../types";

export const parseDocumentStatus = (status: string | null | undefined): {statusText: string, circle?: any } =>{
    //APPROVED, ACTIVE, PENDING
    switch (status){
        case 'Approved': return {statusText: status, circle: Circle({color:colors.primary.blue, size:'18px'})};
        case 'Active': return {statusText: status, circle: Circle({color:colors.text.success, size:'18px'})};
        case 'Pending': return {statusText: status, circle: Circle({color:colors.decorative.orange, size:'18px'})};
        default: return typeof status === 'string' ?
        {statusText: status} : {statusText: '-'};

    }
}


export const averageEvaluation = (evaluation : VendorEvaluationInput | null): number | null => {

    const arrayFromObj = !evaluation ? null : Object.entries(evaluation);
    const res = !arrayFromObj ? null : arrayFromObj.reduce(
        (hlpArray, item) => {
            if (typeof item[1] === "number") {
                hlpArray.count++;
                hlpArray.sum += item[1];
            }
            return hlpArray;
        },
        { sum: 0, count: 0 }
    );

    return res? res.sum / res.count : null;
}

// export const capitalize = (str:string|null|undefined) : string|null|undefined => {
//     if (typeof str != "string" || str === '') return str
//     else return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
// }

export const normalizeSettingIdName = (value: SettingsTypes | string): string => {
    switch (value){
        case CUSTOM_FIELDS_ID: return 'Custom fields';
        case RISK_ID: return 'Risk';
        case TYPE_ID: return 'Type';
        case SERVICE_PROVIDED_ID: return 'Service provided'
        default: return '';
    }
}
export const normalizeSettingIdNameToLowerCase = (value: SettingsTypes): string => {
    switch (value){
        case CUSTOM_FIELDS_ID: return 'custom fields';
        case RISK_ID: return 'risk';
        case TYPE_ID: return 'type';
        case SERVICE_PROVIDED_ID: return 'service provided'
        default: return '';
    }
}
export const parseForSettingDelete = (value: SettingsTypes): string => {
    switch (value){
        case CUSTOM_FIELDS_ID: return 'The custom field has been deleted successfully';
        case RISK_ID: return 'The risk has been deleted successfully';
        case TYPE_ID: return 'The type has been deleted successfully';
        case SERVICE_PROVIDED_ID: return 'The service provided has been deleted successfully'
        default: return '';
    }
}
export const parseForSettingEdit = (value: SettingsTypes): string => {
    switch (value){
        case CUSTOM_FIELDS_ID: return 'The custom field has been edited successfully';
        case RISK_ID: return 'The risk has been edited successfully';
        case TYPE_ID: return 'The type has been edited successfully';
        case SERVICE_PROVIDED_ID: return 'The service provided has been edited successfully'
        default: return '';
    }
}

