import React, {FC} from "react";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../../../newShared/components/Layouts";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {ReleasePhysicalAssets} from "../../components/Dialogs/ReleaseDialog";
import {useVirtualAssetsList} from "../../hooks/useVirtualAssetsList";
import AddIcon from "@mui/icons-material/Add";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {ExportCsvDialog} from "../../../../../newShared/components/csvExportDialog";
import {TVirtualAsset} from "../../types";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {ImportCsvDialog} from "../../../../../newShared/components/csvImportDialog";
import {useImportCsvVirtual} from "../../hooks/dialogs/useImportCsvVirtual";


export const VirtualAssetsList:FC = () => {
    const {isLessThen1050} = useMedia();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});

    const {
        virtualAssets,
        // settings,
        // filter,
        // setSearch,
        // isShowingNoAssets,
        goToAsset,
        goToAddAsset,
        goToSettings,
        exportCsv,
        handleImportCSV,
        isLoadingList,
        isLoadingExport,
        isLoadingFields,
        // isLoadingSettings,
        handleOpenExportCsv,
        handleOpenRelease,

        // pagination: {
        //     bottomLoader,
        //     onScroll,
        //     scrollRef
        // },
        genericTable
    } = useVirtualAssetsList();

    const importCsv = useImportCsvVirtual();

    return (
        <Flex w={'100%'} h={'100%'} direction={'column'} >
            <PageTitleAndActions
                title={t('Virtual assets')}
                showMenuWhen={isLessThen1050}
                actions={[
                    {
                        title: t('Add'),
                        onClick: goToAddAsset,
                        variant: 'text',
                        icon: <AddIcon />,
                        disabled: isLoadingList || isLoadingExport,
                        size: 'small'
                    },
                    {
                        title: t('Settings'),
                        onClick: goToSettings,
                        // variant: 'text',
                        disabled: isLoadingList || isLoadingExport,
                        size: 'small'
                    },
                    {
                        title: t('Release'),
                        onClick: handleOpenRelease,
                        disabled: isLoadingList || isLoadingExport,
                        color: 'secondary',
                        size: 'small'
                    },
                    {
                        title: t('Export CSV'),
                        onClick: handleOpenExportCsv,
                        variant: 'text',
                        icon: <FileDownloadIcon style={{marginLeft: revDir ? '9px' : undefined }}/>,
                        loading: isLoadingExport,
                        disabled: isLoadingExport || isLoadingList,
                        size: 'small'
                    },
                    {
                        title: t('Import CSV'),
                        icon: <FileUploadIcon style={{marginLeft: revDir ? '9px' : undefined }}/>,
                        onClick: handleImportCSV,
                        variant: 'text',
                        disabled: isLoadingList || isLoadingExport,
                        size: 'small'
                    },
                ]}
            />

            <GenericTable<TVirtualAsset>
                id={'AssetsList'}
                columnsConfig={{totalName: 'Total assets', disableColumnsSettings: true, disableShowCards: true}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: virtualAssets,
                    // customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id,
                    onRowClick: goToAsset,
                    columnsConfigs: [
                        {key: "name", name: t('Name'), default: true},
                        {key: 'type', name: t('Type'), default: true},
                    ],
                }}
                filtersConfig={{
                    searchCustomFilterKeyName: 'nameLike',
                    searchAsArray: true,
                    genericFilterProps: {
                        configs: [],
                        fetchResultWithSelectedFilters: () => null,
                        isAddFilters: false
                    }
                }}
                emptyArrayImageProps={{type: 'physicalAssets', filterNotApplied: {secondaryText: t('You have not added any asset yet.'), text: t('Create asset')}}}
            />

            {/*<Flex w={'100%'} m={'30px 0 0 0'} ai={'center'}>*/}
            {/*    <SearchField*/}
            {/*        size={'small'}*/}
            {/*        sx={{margin: revDir ? '0 0 0 10px' : '0 10px 0 0', width: '320px'}}*/}
            {/*        variant={'outlined'}*/}
            {/*        placeholder={t('Search')}*/}
            {/*        onChange={(e) => {setSearch(e.currentTarget.value)}}*/}
            {/*        value={filter.filter.nameLike}*/}
            {/*        disabled={isLoadingList}*/}
            {/*        onClear={filter.handleCleanSearch}*/}
            {/*        onKeyDown={filter.handleEnterKey}*/}
            {/*    />*/}

            {/*    /!*{ !isMobile && (*!/*/}
            {/*    /!*    <TextField label={t('Type')} select size={'small'}*!/*/}
            {/*    /!*               onChange={(e) => {filter.handleOnChangeType(e.target.value)}}*!/*/}
            {/*    /!*               value={filter.filter.type ?? null}*!/*/}
            {/*    /!*               sx={{margin: revDir ? '0 0 0 10px' : '0 10px 0 0', width: '320px', '& label': {top: '0 !important'}}}*!/*/}
            {/*    /!*               disabled={isLoadingList || isLoadingSettings}*!/*/}
            {/*    /!*    >*!/*/}
            {/*    /!*        <MenuItem key={'Any'} value={'Any'}>{'Any'}</MenuItem>*!/*/}
            {/*    /!*        {settings && (Object.keys(settings.type)).map((type) => type && (<MenuItem key={type} value={type}>{type}</MenuItem>))}*!/*/}
            {/*    /!*    </TextField>*!/*/}
            {/*    /!*)}*!/*/}
            {/*</Flex>*/}

            {/*<TableContainer onScroll={onScroll} ref={scrollRef} sx={{overflowY: isShowingNoAssets ? 'hidden' : undefined}}>*/}
            {/*    <Table stickyHeader>*/}
            {/*        <TableHead>*/}
            {/*            <TableRow>*/}
            {/*                <TableCell width={isMobile ? '60%' : '320px'} align={revDir ? 'right' : 'left'}> {t('Name')}</TableCell>*/}
            {/*                <TableCell align={revDir ? 'right' : 'left'}> {t('Type')}</TableCell>*/}
            {/*            </TableRow>*/}
            {/*        </TableHead>*/}

            {/*        {(!isShowingNoAssets) &&*/}
            {/*            <TableBody>*/}
            {/*                {virtualAssets.map((a) => a && (*/}
            {/*                    <TableRow*/}
            {/*                        hover*/}
            {/*                        key={a?.id}*/}
            {/*                        onClick={() => {goToAsset(a)}}*/}
            {/*                    >*/}
            {/*                        <TableCell width={isMobile ? '60%' : '320px'} align={revDir ? 'right' : 'left'}  >{a.name}</TableCell>*/}
            {/*                        <TableCell align={revDir ? 'right' : 'left'}  >{a.type || '- - -'}</TableCell>*/}
            {/*                    </TableRow>*/}
            {/*                ))}*/}
            {/*                {(bottomLoader || isLoadingList) && <TableRowSkeleton columns={2} />}*/}
            {/*            </TableBody>*/}
            {/*        }*/}

            {/*    </Table>*/}
            {/*</TableContainer>*/}

            {/*{filter.filter.prevSearch && !isLoadingList && !virtualAssets.length &&*/}
            {/*    <Typography color={colors.darkBlueText}>{t('Nothing was found for your query. Try changing the query.')}</Typography>}*/}

            {/*<EmptyArrayImage*/}
            {/*    type={'physicalAssets'}*/}
            {/*    isShowing={isShowingNoAssets}*/}
            {/*    withoutAdd*/}
            {/*    text={t('Create asset')}*/}
            {/*    secondaryText={t('You have not added any asset yet.')}*/}
            {/*/>*/}

            {/*DIALOGS*/}
            <ExportCsvDialog
                isOpen={exportCsv.isOpen}
                isLoadingFields={isLoadingFields}
                isLoadingExport={isLoadingExport}
                availableFields={exportCsv.availableFields}
                handleExportCsv={exportCsv.handleExportCsv}
                handleClose={exportCsv.handleClose}
                fieldsMapperKey={"assets"}
            />
            <ReleasePhysicalAssets />

            <ImportCsvDialog
                isOpen={importCsv.isOpen}
                fields={importCsv.fields}
                response={importCsv.response}
                setImportDialog={importCsv.setImportDialog}

                isGettingFields={importCsv.isGettingFields}
                isPerformingCsv={importCsv.isPerformingCsv}
                isLoadingTemplate={importCsv.isLoadingTemplate}

                getFields={importCsv.getFields}
                performCsv={importCsv.performCsv}
                downloadTemplate={importCsv.downloadTemplate}
                fieldsMapperKey={importCsv.fieldsMapperKey}

                fileLimitInMB={5}
            />

        </Flex>
    )

}
