import styled from 'styled-components';
import {device} from "../../../../../newShared/constants";
import {Box, styled as styledMui, TextField, tooltipClasses, TooltipProps, Typography} from "@mui/material";
import {Autocomplete} from "@mui/lab";
import colors from "../../../../../newShared/theme/colors";
import Tooltip from "@mui/material/Tooltip";

export const FilterContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 30px 0 0 0;
  & div{
    margin: 0 10px 0 0;
  }
  ${device.tablet} {
    width: 100%;
  }
`;

export const TextFieldCustom = styled(TextField)`
  
  & .MuiInputBase-input {
    height: 16px;
    width: 320px;
    
  }
  & label{
    top: 0 !important;
  }
`;

export const AssetAutocomplete = styled(Autocomplete)`
  & .MuiInputBase-input {
    height: 24px;
  }
  & label{
    top: 0 !important;
  }
`;

export const CustomSpan = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-right: 8px;
`;

export const FieldTitleText = styled(Typography)({

})
FieldTitleText.defaultProps = {
  variant: 'body1',
  color: colors.text.grey,
  marginTop: '16px'
}

export const FieldValueText = styled('span')(({textColor}: {textColor?: string}) => ({
  color: textColor ? textColor : colors.grayText
}));

export const EmptyRequiredFieldsTooltip = styledMui(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#F7F7F7",
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[0],
    fontSize: 11,
    fontWeight: 400,
    maxWidth: 114,
    padding: 8,
    textAlign: "center",
  },
}));

export const StatusBox = styledMui(Box, {
  shouldForwardProp: (propName) => (
      !["bgColor"].includes(propName.toString())
  )})(({bgColor, marginTop}:{bgColor: string, marginTop?: string}) => ({
  minWidth: '104px',
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '4px 8px',
  borderRadius: '12px',
  backgroundColor: bgColor,
  marginTop,
}));

export const StatusTitle = styled('span')(({color}:{color: string}) => ({
  fontSize: '14px',
  fontWeight: 500,
  color,
}));


