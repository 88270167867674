import {useDispatch, useSelector} from "react-redux";
import {availableApps, loadings, openAddIntegrationDialog} from "../../store/slice";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_INTEGRATIONS} from "../../../../../newShared/constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {PATH_INTEGRATIONS_ADDED} from "../../constants";
import {useForm} from "../../../../../newShared/hooks/useForm";
import {availableAppType} from "../../types";
import {GetDataForCreateApp, GetInitIntegrationsData} from "../../store/actions";
import {useEffect} from "react";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";

export const useAvailableApps = () => {
    const dispatch = useDispatch();
    const controller = new AbortController();
    const {clearFilters} = useGenericFiltersStorage();

    //
    useEffect(() => {
        dispatch(GetInitIntegrationsData({data: {availableName: '', installedName: '', eraseBeforeFetch: true}, signal: controller.signal}));
        // dispatch(GetInitIntegrationsData({availableName: '', installedName: '', eraseBeforeFetch: true}));
        dispatch(GetDataForCreateApp());
        return () => {
            clearFilters();
            controller.abort();
        }
        //eslint-disable-next-line
    }, []);
    //bread
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    useSetBreadcrumbs([
        {
            title: tMenu('Integrations'),
            path: PATH_LOCAL_INTEGRATIONS
        },
        {
            title: tMenu('Installed integrations'),
            path: PATH_INTEGRATIONS_ADDED
        }
    ]);

    //selectors
    const available = useSelector(availableApps);
    const {getInstalledAndAvailableApps} = useSelector(loadings);

    //search
    const {form, setForm, handleChange} = useForm<{search: string, prevSearch: string}>({search: '', prevSearch: ''});

    const handleCleanSearch = () => {
        setForm({search: '', prevSearch: ''});
        dispatch(GetInitIntegrationsData({data: {
                installedName: '',
                availableName: '',
                eraseBeforeFetch: true
            },
            signal: controller.signal
        }));
    }

    const handleEnterKey = (e: any) => {
        if(e.keyCode === 13 && !form.search.trim().length){
            handleCleanSearch();
        }

        if(e.keyCode === 13 && form.search.trim().length > 0){
            setForm({...form, prevSearch: form.search});
            dispatch(GetInitIntegrationsData({data: {
                    installedName: '',
                    availableName: form.search.trim(),
                    eraseBeforeFetch: true
                },
                signal: controller.signal,
            }));
        }
    }

    //actions
    const openAddIntegration = (app: availableAppType) => {
        // dispatch(openAppInDevDialog());
        dispatch(openAddIntegrationDialog(app.endpointMappingName));
    }

    const fetchByFilters = (search: string, filters: Record<string, string[]>, signal: AbortSignal) => {
        dispatch(GetInitIntegrationsData({data: {availableName: search, installedName: '', eraseBeforeFetch: true}}));
    }

    return{
        apps: available,
        isLoading: getInstalledAndAvailableApps,
        search: {
            form,
            handleChange,
            handleEnterKey,
            handleCleanSearch,
        },
        actions: {
            openAddIntegration,
        },
        isNoResult: !getInstalledAndAvailableApps && form.prevSearch.length > 0 && !available.length,
        isNoApps: !getInstalledAndAvailableApps && !form.prevSearch.trim().length && !available.length,
        fetchByFilters
    }
}