import React, {FC} from "react";
import {Skeleton, Typography} from "@mui/material";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {DeleteForever, Download} from "@mui/icons-material";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import colors from "../../../../../newShared/theme/colors";
import {RemoveFrameworkDialog} from "../../components/dialogs/removeFrameworkDialog";
import {AvatarGroup} from "../../../../../newShared/components/AvatarGroup";

import {useFrameworkViewPage} from "./hooks/useFrameworkViewPage";
import {MinimizeInfoWrapper} from "../../../../../newShared/components/minimizeInfoWrapper";
import {Flex, PageWrapper} from "../../../../../newShared/components/Layouts";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {forDescription} from "../../../../../newShared/components/Typography";
import {PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED} from "../../../../../newShared/constants";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {TControl} from "../../types";
import {StatusesToolTip} from "../../../vicaRefactor/pages/tasks/list/components/vicaTaskTableRow";
import {DelegatedTaskCounterChip} from "../../components/delegatedTaskCounterChip";

export const FrameworkView: FC = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathFrameworks.FrameworkCard'});
    const {t: tTable} = useMainTranslation('', {keyPrefix: 'pathFrameworks.pathFrameworkControls'});
    const {isLessThen1050, matches_1440Down} = useMedia();

    const {
        frId,
        framework, controls,
        openControl, generateReport,
        getFrameworkZip,
        setRemoveFrameworkDialog,
        isControlsLoading, isFrameworkZipDownloading,
        // searchValue, handleChange, handleKeyDown, setFilter,
        // handleThreeDotsOpen, handleThreeDotsClose, anchorThreeDotsEl,
        // bottomLoader, onScroll, scrollRef,
        // fetchByFilters,
        genericTable
    } = useFrameworkViewPage();

    return (
        <PageWrapper>
            <PageTitleAndActions
                title={framework?.name}
                icon={framework?.logo ? <img src={framework.logo} alt={'logo'} style={{maxWidth: '40px', maxHeight: '40px'}}/> : undefined}
                showMenuWhen={isLessThen1050}
                handleGoBack={PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}
                actions={[
                    {
                        title: t('Generate report'),
                        onClick: () => {framework && generateReport(framework.id)},
                        size: matches_1440Down ? 'medium' : undefined,

                    },
                    {
                        title: t('Export framework'),
                        onClick: () => {framework && getFrameworkZip(framework.id, framework.name)},
                        size: matches_1440Down ? 'medium' : undefined,
                        icon: <Download/>,
                        variant: 'text',
                        loading: isFrameworkZipDownloading

                    },
                    {
                        title: t('Remove framework'),
                        onClick: () => {framework && setRemoveFrameworkDialog({isOpen: true, id: framework?.id})},
                        size: matches_1440Down ? 'medium' : undefined,
                        icon: <DeleteForever/>,
                        variant: 'text',

                    }
                ]}
            />
            {/*<Breadcrumbs/>*/}

            {/*<Box display={"flex"} alignItems={"center"} mt={'12px'}>*/}
            {/*    {isControlsLoading && (!framework || framework.id !== frId)*/}
            {/*        ? (*/}
            {/*            <>*/}
            {/*                <Skeleton variant={"rounded"} width={'40px'} height={'40px'}/>*/}
            {/*                <Typography variant={!matches_1440Down ? "h1" : 'h3'} margin={!revDir ? '0 32px 0 8px' : '0 8px 0 32px'}><Skeleton width={'200px'}/></Typography>*/}
            {/*            </>*/}
            {/*        ) : (*/}
            {/*            <>*/}
            {/*                {framework?.logo && <img src={framework.logo} alt={'logo'} style={{maxWidth: '40px', maxHeight: '40px'}}/>}*/}
            {/*                <Typography variant={!matches_1440Down ? "h1" : 'h3'} margin={!revDir ? '0 32px 0 8px' : '0 8px 0 32px'}>{framework?.name || ''}</Typography>*/}
            {/*            </>*/}
            {/*        )*/}
            {/*    }*/}

            {/*    {!isLessThen1050 ? framework && framework.id === frId && (*/}
            {/*        <>*/}
            {/*            <Button sx={{margin: '0 8px'}} onClick={() => {framework && generateReport(framework.id)}} size={matches_1440Down ? 'medium' : undefined}>*/}
            {/*                {t('Generate report')}*/}
            {/*            </Button>*/}
            {/*            <LoadingButton sx={{margin: '0 8px'}} variant={"text"} startIcon={<Download/>} size={matches_1440Down ? 'medium' : undefined}*/}
            {/*                           loading={isFrameworkZipDownloading} onClick={() => {framework && getFrameworkZip(framework.id, framework.name)}}>*/}
            {/*                {t('Export framework')}*/}
            {/*            </LoadingButton>*/}
            {/*            <Button sx={{margin: '0 8px'}} variant={"text"} startIcon={<DeleteForever/>} size={matches_1440Down ? 'medium' : undefined}*/}
            {/*                    onClick={() => {framework && setRemoveFrameworkDialog({isOpen: true, id: framework?.id})}}>*/}
            {/*                {t('Remove framework')}*/}
            {/*            </Button>*/}
            {/*        </>*/}
            {/*    ) : framework && framework.id === frId && (*/}
            {/*        <>*/}
            {/*            <IconButton*/}
            {/*                onClick={handleThreeDotsOpen}*/}
            {/*                size={"large"}*/}
            {/*                sx={{*/}
            {/*                    color: Boolean(anchorThreeDotsEl) ? colors.primary.blue : colors.text.grey_dark,*/}
            {/*                    margin: !revDir ? '0 0 0 auto' : '0 auto 0 0'*/}
            {/*                }}*/}
            {/*            >*/}
            {/*                <MoreVert/>*/}
            {/*            </IconButton>*/}
            {/*            <Menu*/}
            {/*                keepMounted*/}
            {/*                anchorEl={anchorThreeDotsEl}*/}
            {/*                anchorOrigin={{*/}
            {/*                    vertical: 'bottom',*/}
            {/*                    horizontal: 'right',*/}
            {/*                }}*/}
            {/*                transformOrigin={{*/}
            {/*                    vertical: 'top',*/}
            {/*                    horizontal: 'right',*/}
            {/*                }}*/}
            {/*                open={Boolean(anchorThreeDotsEl)}*/}
            {/*                onClose={handleThreeDotsClose}*/}
            {/*            >*/}
            {/*                <MenuItem onClick={() => {framework && generateReport(framework.id)}}>*/}
            {/*                    {t('Generate report')}*/}
            {/*                </MenuItem>*/}
            {/*                <MenuItem onClick={() => {framework && getFrameworkZip(framework.id, framework.name)}} disabled={isFrameworkZipDownloading}>*/}
            {/*                    {t('Export framework')}*/}
            {/*                </MenuItem>*/}
            {/*                <MenuItem onClick={() => {framework && setRemoveFrameworkDialog({isOpen: true, id: framework?.id})}}>*/}
            {/*                    {t('Remove framework')}*/}
            {/*                </MenuItem>*/}
            {/*            </Menu>*/}
            {/*        </>*/}
            {/*    )}*/}
            {/*</Box>*/}

            <MinimizeInfoWrapper margin={'16px'}>
                {isControlsLoading && (!framework || framework.id !== frId) ? (<>
                    <Typography variant={"body2"} color={colors.text.grey_dark} maxWidth={'800px'} margin={'6px 0 8px'}>
                        <Skeleton/>
                    </Typography>

                    <Typography variant={"body1"} color={colors.text.grey_dark} mt={'16px'}>
                        <Skeleton width={'100px'}/>
                    </Typography>
                    <Typography variant={"body1"} color={colors.text.grey_dark} mt={'16px'}>
                        <Skeleton width={'100px'}/>
                    </Typography>
                    <Typography variant={"body1"} color={colors.text.grey_dark} mt={'16px'}>
                        <Skeleton width={'100px'}/>
                    </Typography>
                </>) : (<>
                    <Typography variant={"body2"} color={colors.text.grey_dark} maxWidth={'800px'} margin={'6px 0 8px'} lineHeight={'22px'} {...forDescription(revDir)}>
                        {framework ? framework.description : ''}
                    </Typography>

                    <Typography variant={"body1"} color={colors.text.grey_dark} mt={'16px'}>
                        {t('Type')}
                        <Typography variant={"body1"} color={colors.text.dark} m={'0 8px'} component={"span"}>
                            {framework ? framework.type : ''}
                        </Typography>
                    </Typography>
                    <Typography variant={"body1"} color={colors.text.grey_dark} mt={'16px'}>
                        {t('Controls')}
                        <Typography variant={"body1"} color={colors.text.dark} m={'0 8px'} component={"span"}>
                            {framework ? framework.controls : ''}
                        </Typography>
                    </Typography>
                    <Typography variant={"body1"} color={colors.text.grey_dark} mt={'16px'}>
                        {t('Evidences')}
                        <Typography variant={"body1"} color={colors.text.dark} m={'0 8px'} component={"span"}>
                            {framework ? `${framework.evidencesFinished.length}/${framework.evidences}` : ''}
                        </Typography>
                    </Typography>
                </>)}
            </MinimizeInfoWrapper>

            {/*<SearchField onClear={() => {setFilter('input'); handleChange({target: {value: ''}})}}*/}
            {/*             value={searchValue} onChange={handleChange} onKeyDown={handleKeyDown} sx={{marginTop: '16px', maxWidth: !isMobile ? '300px' : undefined}}*/}
            {/*             disabled={isControlsLoading && (!framework || framework.id !== frId)}/>*/}

            <GenericTable<TControl>
                id={'frameworkView'}
                columnsConfig={{totalName: 'Total controls', disableColumnsSettings: true, disableShowCards: true}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: controls,
                    customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id,
                    onRowClick: (row) => {openControl(row.id)},
                    columnsConfigs: [
                        {key: "name", name: tTable('Name'), disableNoWrapTable: true, default: true},
                        {key: 'count', name: tTable('Evidences'), default: true, valueGetter: (row) => `${row.evidencesFinished.length}/${row.evidenceTotal}`},
                        {key: 'delegatedTasks', name: tTable('Tasks'), default: true, valueRenderer: (row) => (
                                <StatusesToolTip title={
                                    <Flex ai={'center'} gap={'4px'}>
                                        <Typography variant={'bodySmall'} color={colors.grayText}>{'Created:'}</Typography>
                                        <DelegatedTaskCounterChip type={'created'} count={row.delegatedTasks?.created ?? 0}/>
                                        <Typography variant={'bodySmall'} color={colors.grayText}>{'Performed:'}</Typography>
                                        <DelegatedTaskCounterChip type={'performed'} count={row.delegatedTasks?.performed ?? 0}/>
                                        <Typography variant={'bodySmall'} color={colors.grayText}>{'Approved:'}</Typography>
                                        <DelegatedTaskCounterChip type={'approved'} count={row.delegatedTasks?.approved ?? 0}/>
                                        <Typography variant={'bodySmall'} color={colors.grayText}>{'Declined:'}</Typography>
                                        <DelegatedTaskCounterChip type={'declined'} count={row.delegatedTasks?.declined ?? 0}/>
                                    </Flex>
                                }>
                                    <Flex ai={'center'} jc={'space-between'} gap={'9px'} >
                                        <DelegatedTaskCounterChip type={'created'} count={row.delegatedTasks?.created ?? 0}/>
                                        <DelegatedTaskCounterChip type={'performed'} count={row.delegatedTasks?.performed ?? 0}/>
                                        <DelegatedTaskCounterChip type={'approved'} count={row.delegatedTasks?.approved ?? 0}/>
                                        <DelegatedTaskCounterChip type={'declined'} count={row.delegatedTasks?.declined ?? 0}/>
                                    </Flex>
                                </StatusesToolTip>

                            )},
                        {key: 'collabs', name: tTable('Collaborators'), default: true, valueRenderer: (row) => <AvatarGroup assignments={row.collaborators}/>},
                    ]
                }}
                filtersConfig={{
                    dontIncludeSearchInFilter: true,
                    genericFilterProps: {
                        configs: [],
                        fetchResultWithSelectedFilters: () => null,
                        isAddFilters: false
                    }
                }}
            />

            {/*<GenericFiltersArray*/}
            {/*    configs={[]}*/}
            {/*    fetchResultWithSelectedFilters={fetchByFilters}*/}
            {/*    isAddFilters={false}*/}
            {/*/>*/}

            {/*<TableContainer onScroll={onScroll} ref={scrollRef}>*/}
            {/*    <Table stickyHeader>*/}
            {/*        <TableHead>*/}
            {/*            <TableRow>*/}
            {/*                <TableCell>{tTable('Name')}</TableCell>*/}
            {/*                <TableCell>{tTable('Number of evidence')}</TableCell>*/}
            {/*                <TableCell>{tTable('Collaborators')}</TableCell>*/}
            {/*            </TableRow>*/}
            {/*        </TableHead>*/}
            {/*        <TableBody>*/}
            {/*            {controls.map((control, index) => control ? (*/}
            {/*                <TableRow key={control.id} onClick={() => {openControl(control.id)}} hover>*/}
            {/*                    <TableCell>{control.name}</TableCell>*/}
            {/*                    <TableCell>{`${control.evidencesFinished.length}/${control.evidenceTotal}`}</TableCell>*/}
            {/*                    <TableCell sx={{padding: '10px 16px'}}>*/}
            {/*                        <AvatarGroup assignments={control.collaborators}/>*/}
            {/*                    </TableCell>*/}
            {/*                </TableRow>*/}
            {/*            ) : <TableRowSkeleton key={'loader' + index}/>)}*/}

            {/*            {(bottomLoader || isControlsLoading) && <TableRowSkeleton/>}*/}
            {/*        </TableBody>*/}
            {/*    </Table>*/}
            {/*</TableContainer>*/}

            {/*<GenericTable*/}
            {/*    id={'frameworkView'}*/}
            {/*    columnsConfig={{totalName: 'Total controls'}}*/}
            {/*    paging={{*/}

            {/*    }}*/}
            {/*    // rowsConfig={{*/}
            {/*    //     rows:*/}
            {/*    // }}*/}
            {/*    filtersConfig={}*/}
            {/*/>*/}

            <RemoveFrameworkDialog/>
        </PageWrapper>
    )
}
