import {useEmployeesCreate} from "../../hooks/useEmployeesCreate";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import React, {FC, Fragment, useEffect} from "react";
import {Flex} from "../../../../../newShared/components/Layouts";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {CategoryTitle} from "../../components/categoryTitle";
import colors from "../../../../../newShared/theme/colors";
import {
    Autocomplete,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {CategoryFieldsContainer} from "../../components/categoryFieldsContainer";

//icons
import PersonIcon from "@mui/icons-material/Person";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AddIcon from "@mui/icons-material/Add";
import HomeIcon from "@mui/icons-material/Home";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import {PHONE_NUMBER_CONTACT_TYPE} from "../../constants";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import WorkIcon from '@mui/icons-material/Work';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import {AddSettingsDialog} from "../../components/dialogs/addSettingsDialog";
import {PaperTypeMap} from "@mui/material/Paper/Paper";
import debounce from "lodash.debounce";
import {useManagersAutocomplete} from "../../hooks/useManagersAutocomplete";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TManager} from "../../types";
import {AutoComplete} from "../../components/styled";
import {DEFAULT_DATE_FORMAT} from "../../../../../newShared/utils/dateTools";
import {FlexRow} from "../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import {AutocompleteCollaborators} from "../../../../../newShared/components/AutocompleteCollaborators";
import {optionIsTCollaborator} from "../../../../../newShared/components/AutocompleteCollaborators/types";
import {useLazyQuery} from "@apollo/client";
import {
    CheckCollaboratorIsNotAssignedQuery,
    CheckCollaboratorIsNotAssignedQueryVariables
} from "../../../../../newShared/GQLTypes";
import {checkCollaboratorIsNotAssigned} from "../../api/query";
import {getName, validateEmail} from "../../../../../newShared/utils/text";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {Done, InfoOutlined, WarningAmberOutlined} from "@mui/icons-material";


export const EmployeesCreate = () => {
    const {workspaceId} = UseManageWorkspacesAndOrganizations();
    const {topBarActions, isLoading, isOk, form, handleChange, setForm, settings, actions, currentJobInfo} = useEmployeesCreate();
    const {isLoadingManagers, managers, getManagers} = useManagersAutocomplete();
    const {isLessThen1050, isMobile, isTablet} = useMedia();
    const inputWidth = isMobile ? '100%' : (isTablet ? '49%' : '32%');
    const inputBackgroundColor = colors.backgrounds.white;

    const {t} = useMainTranslation('', {keyPrefix: 'pathHRManagement.exact'});
    const {tCommon} = useMainTranslation();

    const [checkWorkEmail, {loading: isCheckingEmail, data: checkEmailData}] =
        useLazyQuery<CheckCollaboratorIsNotAssignedQuery, CheckCollaboratorIsNotAssignedQueryVariables>(checkCollaboratorIsNotAssigned, {
            fetchPolicy: "network-only",
        });
    useEffect(() => {
        if (validateEmail(form.workEmail)) {
            checkWorkEmail({
                variables: {
                    workspaceId: workspaceId || '',
                    workEmail: form.workEmail
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.workEmail]);

    const AddContactTypeLink: FC<PaperTypeMap["props"] & {index: number}> = ({ children, index, ...other }: any) => (
        <Paper {...other}>
            <Button onMouseDown={() => actions.openAddContactType(index)} variant="text" startIcon={<AddIcon fontSize={'small'}/>} sx={{color: colors.primary.blue, width: '100%'}}>
                {t('Add new')}
            </Button>
            {children}
        </Paper>
    );

    const AddRelationshipTypeLink: FC<PaperTypeMap["props"] & {index: number}> = ({ children, index, ...other }: any) => (
        <Paper {...other}>
            <Button onMouseDown={() => actions.openAddRelationshipType(index)} variant="text" startIcon={<AddIcon fontSize={'small'}/>} sx={{color: colors.primary.blue, width: '100%'}}>
                {t('Add new')}
            </Button>
            {children}
        </Paper>
    );

    const AddDegreeLink: FC<PaperTypeMap["props"] & {index: number}> = ({ children, index, ...other }) => (
        <Paper {...other}>
            <Button onMouseDown={() => actions.openAddDegree(index)} variant="text" startIcon={<AddIcon fontSize={'small'}/>} sx={{color: colors.primary.blue, width: '100%'}}>
                {t('Add new')}
            </Button>
            {children}
        </Paper>
    );

    const AddJobTitleLink = ({ children, ...other }: any) => (
        <Paper {...other}>
            <Button onMouseDown={actions.openAddJobTitle} variant="text" startIcon={<AddIcon fontSize={'small'}/>} sx={{color: colors.primary.blue, width: '100%'}}>
                {t('Add new')}
            </Button>
            {children}
        </Paper>
    );

    const AddDepartmentLink = ({ children, ...other }: any) => (
        <Paper {...other}>
            <Button onMouseDown={actions.openAddDepartment} variant="text" startIcon={<AddIcon fontSize={'small'}/>} sx={{color: colors.primary.blue, width: '100%'}}>
                {t('Add new')}
            </Button>
            {children}
        </Paper>
    );

    const AddEmploymentTypeLink = ({ children, ...other }: any) => (
        <Paper {...other}>
            <Button onMouseDown={actions.openAddEmploymentType} variant="text" startIcon={<AddIcon fontSize={'small'}/>} sx={{color: colors.primary.blue, width: '100%'}}>
                {t('Add new')}
            </Button>
            {children}
        </Paper>
    )

    const AddDivisionLink = ({ children, ...other }: any) => (
        <Paper {...other}>
            <Button onMouseDown={actions.openAddDivision} variant="text" startIcon={<AddIcon fontSize={'small'}/>} sx={{color: colors.primary.blue, width: '100%'}}>
                {t('Add new')}
            </Button>
            {children}
        </Paper>
    )

    const AddLocationLink = ({ children, ...other }: any) => (
        <Paper {...other}>
            <Button onMouseDown={actions.openAddLocation} variant="text" startIcon={<AddIcon fontSize={'small'}/>} sx={{color: colors.primary.blue, width: '100%'}}>
                {t('Add new')}
            </Button>
            {children}
        </Paper>
    )

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('New employee')}
                handleGoBack={topBarActions.handleGoBack}
                showMenuWhen={isLessThen1050}
                actions={[
                    {
                        variant: 'contained',
                        title: t('Save information'),
                        onClick: topBarActions.handleSubmit,
                        loading: isLoading,
                        disabled: !isOk() || isCheckingEmail || (checkEmailData?.checkCollaboratorIsNotAssigned?.isEmployeeExist),
                    },
                    {
                        variant: 'outlined',
                        title: tCommon('Cancel'),
                        onClick: topBarActions.handleGoBack,
                        disabled: isLoading,
                    }
                ]}
            />

            <Flex w={'100%'} h={'100%'} overflowy={'auto'} direction={'column'}>
                <CategoryTitle
                    disableDivider
                    name={t('Personal information')}
                    icon={<PersonIcon style={{color: colors.primary.blue_dark}}/>}
                    margin={'30px 0 0 0'}
                />

                <CategoryFieldsContainer>
                    <Flex w={'100%'} wrap={'wrap'} gap={'16px'}>
                        <TextField
                            value={form.firstName}
                            onChange={handleChange}
                            label={t('First name')}
                            name={'firstName'}
                            required
                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                            size={'small'}
                        />

                        <TextField
                            value={form.middleName}
                            onChange={handleChange}
                            label={t('Middle name')}
                            name={'middleName'}
                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                            size={'small'}
                        />

                        <TextField
                            value={form.lastName}
                            onChange={handleChange}
                            label={t('Last name')}
                            name={'lastName'}
                            required
                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                            size={'small'}
                        />

                        <TextField
                            value={form.nickname}
                            onChange={handleChange}
                            label={t('Nickname')}
                            name={'nickname'}
                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                            size={'small'}
                        />

                        <DatePicker
                            label={t('Birth date')}
                            inputFormat={DEFAULT_DATE_FORMAT}
                            value={form.birthDay}
                            onChange={(newValue) => {actions.handleSetBirthday(newValue)}}
                            renderInput={(params) => <TextField {...params} sx={{width: inputWidth, backgroundColor: inputBackgroundColor}} size={'small'} />}
                        />

                        <Autocomplete
                            options={settings.gender}
                            value={form.gender}
                            onChange={(e, newValue) => actions.handleSetGender(newValue)}
                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                            renderInput={(params) => <TextField {...params} label={t('Gender')} size={'small'} required/>}
                        />

                        <TextField
                            value={form.employeeId}
                            onChange={handleChange}
                            label={t('ID')}
                            name={'employeeId'}
                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                            size={'small'}
                        />

                        <Autocomplete
                            options={settings.maritalStatus}
                            value={form.maritalStatus}
                            onChange={(e, newValue) => actions.handleSetMartialStatus(newValue)}
                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                            renderInput={(params) => <TextField {...params} label={t('Marital status')} size={'small'} required/>}
                        />

                        <Autocomplete
                            options={settings.ethnicity}
                            value={form.ethnicity}
                            onChange={(e, newValue) => actions.handleSetEthnicity(newValue)}
                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                            renderInput={(params) => <TextField {...params} label={t('Ethnicity')} size={'small'}/>}
                        />

                        <AutocompleteCollaborators<false, true, true>
                            label={t('Work email')}
                            getOptionLabelCustom={"name+email"}
                            freeSolo
                            freeSoloType={'email'}
                            defaultValue={''}
                            sx={{
                                width: inputWidth,
                                '* .MuiInputBase-root': { backgroundColor: inputBackgroundColor },
                            }}
                            required
                            size={'small'}
                            shouldForwardErrorValue
                            onChange={value => setForm(prev => ({
                                ...prev,
                                workEmail: optionIsTCollaborator(value) ? value.email : (value || '')
                            }))}
                        />

                        {validateEmail(form.workEmail) && (
                            <FlexRow sx={{alignItems: 'center', gap: '8px', flexShrink: 1}}>
                                {isCheckingEmail ? <CircularProgress size={20}/> : (
                                    checkEmailData?.checkCollaboratorIsNotAssigned?.isEmployeeExist ? (
                                        <>
                                            <WarningAmberOutlined sx={{color: colors.text.critical}}/>

                                            <Typography variant={'*bodyText2'} color={colors.text.critical}>
                                                {t(`Email “{{email}}” is already assigned to another collaborator in the workspace. Please select another email.`, {
                                                    email: form.workEmail
                                                })}
                                            </Typography>
                                        </>
                                    ) : (
                                        checkEmailData?.checkCollaboratorIsNotAssigned?.collaborator ? (
                                            <>
                                                <Done sx={{color: colors.text.success}}/>

                                                <Typography variant={'*bodyText2'} color={colors.text.success}>
                                                    {t('{{name}} connected successfully.', {
                                                        name: `${getName(checkEmailData?.checkCollaboratorIsNotAssigned?.collaborator.firstName || '', checkEmailData?.checkCollaboratorIsNotAssigned?.collaborator.lastName || '')} (${checkEmailData?.checkCollaboratorIsNotAssigned?.collaborator.email})`
                                                    })}
                                                </Typography>
                                            </>
                                        ) : (
                                            <>
                                                <InfoOutlined sx={{color: colors.text.warning}}/>

                                                <Typography variant={'*bodyText2'} color={colors.text.warning}>
                                                    {t('Collaborator not found for the provided work email. Some services may be unavailable. Verify the email or add a new collaborator in workspace settings.')}
                                                </Typography>
                                            </>
                                        )
                                    )
                                )}
                            </FlexRow>
                        )}
                    </Flex>
                </CategoryFieldsContainer>

                <CategoryTitle
                    disableDivider
                    name={t('Education')}
                    icon={<MenuBookIcon style={{color: colors.primary.blue_dark}}/>}
                    button={{text: t('Add Education'), onClick: actions.handleAddEducation, icon: <AddIcon />  }}/>

                {!form.personalData.education.length &&
                    <CategoryFieldsContainer disableRequiredFieldWarning>
                        <Typography color={colors.text.grey} fontSize={'13px'} fontWeight={400}>{t('No education records')}</Typography>
                    </CategoryFieldsContainer>
                }
                {form.personalData.education.map((e, id) => {
                    return(
                        <CategoryFieldsContainer onDelete={() => actions.handleDeleteEducation(id)}>
                            <Flex w={'100%'} wrap={'wrap'} gap={'16px'}>
                                <TextField
                                    value={e.schoolName}
                                    onChange={(event) => actions.handleChangeEducation(event, id)}
                                    label={t('School name')}
                                    name={'schoolName'}
                                    required
                                    sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                    size={'small'}
                                />

                                <Autocomplete
                                    options={Object.keys(settings.degree)}
                                    value={e.degree}
                                    onChange={(e, newValue) => actions.handleChangeEducationDegree(newValue, id)}
                                    sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                    renderInput={(params) => <TextField {...params} label={t('Degree')} size={'small'} required/>}
                                    PaperComponent={(props) => <AddDegreeLink {...props} index={id}/>}
                                />

                                <TextField
                                    value={e.fieldOfStudy}
                                    onChange={(event) => actions.handleChangeEducation(event, id)}
                                    label={t('Field of study')}
                                    name={'fieldOfStudy'}
                                    sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                    size={'small'}
                                />

                                <DatePicker
                                    label={t('Start date')}
                                    inputFormat={DEFAULT_DATE_FORMAT}
                                    value={e.startDate}
                                    onChange={(newValue) => {actions.handleSetEducationStartDate(newValue, id)}}
                                    renderInput={(params) => <TextField {...params} sx={{width: inputWidth, backgroundColor: inputBackgroundColor}} size={'small'} />}
                                />

                                <DatePicker
                                    label={t('End date')}
                                    inputFormat={DEFAULT_DATE_FORMAT}
                                    value={e.endDate}
                                    onChange={(newValue) => {actions.handleSetEducationEndDate(newValue, id)}}
                                    renderInput={(params) => <TextField {...params} sx={{width: inputWidth, backgroundColor: inputBackgroundColor}} size={'small'} />}
                                />
                            </Flex>
                        </CategoryFieldsContainer>
                    )
                })}

                <CategoryTitle
                    name={t('Address')}
                    icon={<HomeIcon style={{color: colors.primary.blue_dark}}/>}
                />

                <CategoryFieldsContainer>
                    <Flex>
                        <Autocomplete
                            options={settings.country}
                            value={form.contactInfo.address.country}
                            onChange={(e, newValue) => actions.handleSetAddressCountry(newValue)}
                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                            renderInput={(params) => <TextField {...params} label={t('Country')} size={'small'} required/>}
                        />
                    </Flex>

                    <Flex w={'100%'} wrap={'wrap'} gap={'16px'} m={'16px 0 0 0'}>
                        <TextField
                            value={form.contactInfo.address.city}
                            onChange={actions.handleChangeAddress}
                            label={t('City')}
                            name={'city'}
                            required
                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                            size={'small'}
                        />

                        <TextField
                            value={form.contactInfo.address.street}
                            onChange={actions.handleChangeAddress}
                            label={t('Street')}
                            name={'street'}
                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                            size={'small'}
                        />

                        <TextField
                            value={form.contactInfo.address.zip}
                            onChange={actions.handleChangeAddress}
                            label={t('ZIP code')}
                            name={'zip'}
                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                            size={'small'}
                        />
                    </Flex>
                </CategoryFieldsContainer>

                <CategoryTitle
                    name={t('Contacts')}
                    icon={<LocalPhoneIcon style={{color: colors.primary.blue_dark}}/>}
                    button={{text: t('Add contact'), onClick: actions.handleAddContact, icon: <AddIcon />}}
                />

                {form.contactInfo.contacts.map((e, id) => {
                    return(
                        <CategoryFieldsContainer onDelete={e.type === PHONE_NUMBER_CONTACT_TYPE ? undefined : () => actions.handleDeleteContact(id)}>
                            <Flex w={'100%'} wrap={'wrap'} gap={'16px'}>
                                <Autocomplete
                                    disabled={e.type === PHONE_NUMBER_CONTACT_TYPE}
                                    options={settings.contactType}
                                    getOptionDisabled={(o) => form.contactInfo.contacts.some(e => e.type === o)}
                                    value={e.type}
                                    onChange={(e, newValue) => actions.handleSetContactType(id, newValue)}
                                    sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                    renderInput={(params) => <TextField {...params} label={t('Contact type')} size={'small'} required/>}
                                    PaperComponent={(props) => <AddContactTypeLink {...props} index={id}/>}
                                />

                                <TextField
                                    value={e.value}
                                    onChange={(e) => actions.handleChangeContactValue(id, e.currentTarget.value)}
                                    label={t('Value')}
                                    required
                                    sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                    size={'small'}
                                />
                            </Flex>
                        </CategoryFieldsContainer>
                    )
                })}

                <CategoryTitle
                    name={t('Emergency contacts')}
                    icon={<ContactPhoneIcon style={{color: colors.primary.blue_dark}}/>}
                    button={{text: t('Add contact'), onClick: actions.handleAddEmergencyContact, icon: <AddIcon />}}/>

                {!form.contactInfo.emergencyContacts.length &&
                    <CategoryFieldsContainer disableRequiredFieldWarning>
                        <Typography color={colors.text.grey} fontSize={'13px'} fontWeight={400}>{t('No emergency contacts')}</Typography>
                    </CategoryFieldsContainer>
                }

                {form.contactInfo.emergencyContacts.map((e, id) => {
                    return(
                        <CategoryFieldsContainer onDelete={() => actions.handleDeleteEmergencyContact(id)}>
                            <Flex w={'100%'} wrap={'wrap'} gap={'16px'}>
                                <TextField
                                    value={e.fullName}
                                    onChange={(e) => actions.handleChangeEmergencyContact(e, id)}
                                    label={t('Full name')}
                                    name={'fullName'}
                                    required
                                    sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                    size={'small'}
                                />

                                <Autocomplete
                                    options={Object.keys(settings.relationshipType)}
                                    value={e.relationType}
                                    onChange={(e, newValue) => actions.handleSetEmergencyContactType(id, newValue)}
                                    sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                    renderInput={(params) => <TextField {...params} label={t('Relationship to employee')} size={'small'} required/>}
                                    PaperComponent={(props) => <AddRelationshipTypeLink {...props} index={id}/>}
                                />

                                <TextField
                                    value={e.phone}
                                    onChange={(e) => actions.handleChangeEmergencyContact(e, id)}
                                    label={t('Phone')}
                                    name={'phone'}
                                    required
                                    sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                    size={'small'}
                                />

                                <TextField
                                    value={e.email}
                                    onChange={(e) => actions.handleChangeEmergencyContact(e, id)}
                                    label={t('Email')}
                                    name={'email'}
                                    required
                                    sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                    size={'small'}
                                />

                                <TextField
                                    value={e.address}
                                    onChange={(e) => actions.handleChangeEmergencyContact(e, id)}
                                    label={t('Address')}
                                    name={'address'}
                                    required
                                    sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                    size={'small'}
                                />
                            </Flex>
                        </CategoryFieldsContainer>
                    )
                })}

                {/*ADD STATUS + JOB*/}
                <CategoryTitle
                    name={t('Job description')}
                    icon={<WorkIcon style={{color: colors.primary.blue_dark}}/>}
                />

                <CategoryFieldsContainer>
                    {currentJobInfo &&
                        <Flex w={'100%'} wrap={'wrap'} gap={'16px'}>
                            <DatePicker
                                label={t('Hire date')}
                                value={form.hireDate}
                                inputFormat={DEFAULT_DATE_FORMAT}
                                disableFuture
                                onChange={(newValue) => {
                                    actions.handleSetHireDate(newValue)
                                }}
                                renderInput={(params) => <TextField
                                    {...params}
                                    sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                    size={'small'}
                                    required
                                />}
                            />

                            <Autocomplete
                                options={settings.jobTitle}
                                getOptionLabel={(o) => o.name}
                                groupBy={(o) => o.jobType!} //jobType nullable only for create - by default non-empty
                                value={currentJobInfo.jobTitle ? {...currentJobInfo.jobTitle, workspaceId: '', count: 0, id: ''} : null}
                                onChange={(e, newValue) => actions.handleJobTitle(newValue)}
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                renderInput={(params) => <TextField {...params} label={t('Job title')} size={'small'} required/>}
                                PaperComponent={AddJobTitleLink}
                            />

                            <Autocomplete
                                options={Object.keys(settings.employmentType)}
                                value={currentJobInfo.employmentType}
                                onChange={(e, newValue) => actions.handleSetEmployeeType(newValue)}
                                sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                renderInput={(params) => <TextField {...params} label={t('Employment type')} size={'small'} required/>}
                                PaperComponent={AddEmploymentTypeLink}
                            />

                            <Autocomplete
                                options={Object.keys(settings.department)}
                                value={currentJobInfo.department}
                                onChange={(e, newValue) => actions.handleSetDepartment(newValue)}
                                sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                renderInput={(params) => <TextField {...params} label={t('Department')} size={'small'} required/>}
                                PaperComponent={AddDepartmentLink}
                            />

                            <Autocomplete
                                options={Object.keys(settings.location)}
                                value={currentJobInfo.location}
                                onChange={(e, newValue) => actions.handleSetLocation(newValue)}
                                sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                renderInput={(params) => <TextField {...params} label={t('Location')} size={'small'} required/>}
                                PaperComponent={AddLocationLink}
                            />

                            <Autocomplete
                                options={Object.keys(settings.division)}
                                value={currentJobInfo.division}
                                onChange={(e, newValue) => actions.handleSetDivision(newValue)}
                                sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                renderInput={(params) => <TextField {...params} label={t('Division')} size={'small'} required/>}
                                PaperComponent={AddDivisionLink}
                            />

                            <AutoComplete
                                options={managers}
                                getOptionLabel={(e: unknown) => (e as TManager).name}
                                value={currentJobInfo.manager}
                                onChange={(event, value) => actions.handleSetManager(value as TManager)}
                                onInputChange={debounce((e, value: string) => {getManagers(value)}, 500)}
                                loading={isLoadingManagers}
                                sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                renderInput={(params) => (
                                    <TextField {...params} size={'small'} label={t('Manager')}
                                                     InputProps={{
                                                         ...params.InputProps,
                                                         endAdornment: (
                                                             <Fragment>
                                                                 {isLoadingManagers ? <CircularProgress color="inherit" size={20} /> : null}
                                                                 {params.InputProps.endAdornment}
                                                             </Fragment>
                                                         ),
                                                     }}/>
                                )}
                            />
                        </Flex>
                    }
                </CategoryFieldsContainer>

                <CategoryTitle
                    name={t('Employee status')}
                    icon={<PrivacyTipIcon style={{color: colors.primary.blue_dark}}/>}
                />

                <CategoryFieldsContainer>
                    <Flex>
                        <Autocomplete
                            options={settings.status}
                            getOptionLabel={(o) => o.name}
                            value={form.status}
                            onChange={(e, newValue) => actions.handleChangeStatus(newValue)}
                            isOptionEqualToValue={(option, value) => option.name === value.name}
                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                            renderInput={(params) => <TextField {...params} label={t('Employee status')} size={'small'} required/>}
                        />
                    </Flex>
                </CategoryFieldsContainer>

                <CategoryTitle
                    name={t('Veteran status')}
                    icon={<MilitaryTechIcon style={{color: colors.primary.blue_dark}}/>}
                />

                <CategoryFieldsContainer disableRequiredFieldWarning>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox
                                checked={form.personalData.veteranStatus.activeDutyWartimeOrCampaignBadgeVeteran}
                                onChange={(e) => actions.handleChangeVeteranStatus("activeDutyWartimeOrCampaignBadgeVeteran", e.target.checked)}
                            />}
                            label={t('Active duty wartime or campaign badge veteran')}
                        />
                        <FormControlLabel
                            control={<Checkbox
                                checked={form.personalData.veteranStatus.armedForcesServiceMedalVeteran}
                                onChange={(e) => actions.handleChangeVeteranStatus("armedForcesServiceMedalVeteran", e.target.checked)}
                            />}
                            label={t('Armed Forces service medal veteran')}
                        />
                        <FormControlLabel
                            control={<Checkbox
                                checked={form.personalData.veteranStatus.disabledVeteran}
                                onChange={(e) => actions.handleChangeVeteranStatus("disabledVeteran", e.target.checked)}
                            />}
                            label={t('Disabled veteran')}
                        />
                        <FormControlLabel
                            control={<Checkbox
                                checked={form.personalData.veteranStatus.recentlySeparatedVeteran}
                                onChange={(e) => actions.handleChangeVeteranStatus("recentlySeparatedVeteran", e.target.checked)}
                            />}
                            label={t('Recently separated veteran')}
                        />
                    </FormGroup>
                </CategoryFieldsContainer>

                <CategoryTitle
                    name={t('Custom fields')}
                    icon={<PlaylistAddIcon style={{color: colors.primary.blue_dark}}/>}
                    button={{text: t('Add Custom Field'), onClick: actions.handleAddCustomField, icon: <AddIcon />}}
                />

                <CategoryFieldsContainer disableRequiredFieldWarning margin={'18px 0'}>
                    {Object.keys(settings.customFields).length > 0 ?
                        <Flex w={'100%'} wrap={'wrap'} gap={'16px'}>
                            {Object.keys(settings.customFields).map(e => {
                                return (
                                    <TextField
                                        key={e}
                                        value={form.customFields[e]}
                                        onChange={handleChange}
                                        label={e}
                                        name={e}
                                        id={'.customFields'}
                                        sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                        size={'small'}
                                    />
                                )
                            })}
                        </Flex>
                        :
                        <Typography color={colors.text.grey} fontSize={'13px'} fontWeight={400}>{t('No custom field created. Add fields to specify any additional information.')}</Typography>
                    }
                </CategoryFieldsContainer>
            </Flex>

            <AddSettingsDialog
                onSuccess={actions.handleSettingAdd}
                onSuccessJobTitleAdd={actions.handleJobTitleAdd}
            />
        </Flex>
    )
}