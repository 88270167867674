import {TReleaseDialogStep} from "./types";

export const getReleaseDialogTitleByStep = (step: TReleaseDialogStep, onlyEvidence?: boolean) => {
  switch (step) {
    case 'review':
      return 'Step 1.1. Add reviewers';
    case 'sign':
      return 'Step 1.2. Add signers';
    case 'read':
      return 'Step 1.3. Add readers';
    case 'evidence':
      return !onlyEvidence ? 'Step 2. Choose evidence to release' : 'Choose evidence to release';
    default:
      return '';
  }
}

export const getReleaseDialogDescriptionByStep = (step: TReleaseDialogStep) => {
  switch (step) {
    case 'review':
      return 'You must add at least one reviewer who can read and edit the documents. You should invite collaborators from the list of workspace collaborators or send an invitation to external recipient by email.';
    case 'sign':
      return 'You must add at least one signer who can read, edit, and sign the documents. You should invite collaborators from the list of workspace collaborators or send an invitation to an external recipient by email.';
    case 'read':
      return 'You can add readers who will be able to read the documents. You can invite collaborators from the list of workspace collaborators or send an invitation to a external recipient by email.';
    default:
      return '';
  }
}