import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useIncomingRequests} from "../../hooks/useIncomingRequests";
import {PageWrapper} from "../../../../../newShared/components/style";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {MainHrRequestModel} from "../../../../../newShared/GQLTypes";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {getHrRequestStatusChip} from "../../helpers";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {IncomingRequestViewDialog} from "../../components/dialogs/incomingRequestViewDialog";
import {statusTypeTitleMapper} from "../../constants";


export const IncomingRequests = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathHRManagement.pathIncomingRequests'});
    const {isMobile} = useMedia();

    const {
        requests,
        handleOpenRequest,
        genericTable,
    } = useIncomingRequests();


    return (
        <PageWrapper>
            <PageTitleAndActions title={t('Incoming requests')} actions={[]} />

            <GenericTable<MainHrRequestModel>
                id={'incomingRequests'}
                columnsConfig={{totalName: 'Total requests', disableColumnsSettings: true, forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: requests,
                    // customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id,
                    onRowClick: handleOpenRequest,

                    columnsConfigs: [
                        {key: "id", name: t('Request ID'), default: true, },
                        {key: 'type', name: t('Request type'), default: true, valueGetter: (row) => statusTypeTitleMapper[row.type] ?? row.type},
                        {key: 'requestDate', name: t('Request date'), default: true, valueGetter: (row) => parseDateAuto(row.requestDate)},
                        {key: 'requestor', name: t('Requestor'), default: true, valueGetter: (row) => row.requestor.fullName},
                        {key: 'status', name: t('Status'), default: true, valueRenderer: (row) => getHrRequestStatusChip(row.status)},
                        {key: 'resolver', name: t('Resolver'), default: true, valueGetter: (row) => row.resolver ? row.resolver.employeeFullName ?? row.resolver.collaboratorFullName : '-'},
                    ],
                }}
                filtersConfig={{
                    searchCustomFilterKeyName: 'requestIdLike',
                    searchAsArray: true,
                    dontSendSearchIfEmpty: true,
                    genericFilterProps: {
                        configs: genericTable.filters.configs,
                        fetchResultWithSelectedFilters: () => null,
                        isAddFilters: false
                    }
                }}
                emptyArrayImageProps={{type: 'any', filterNotApplied: {text: t('No requests to display')}}}
            />
            <IncomingRequestViewDialog/>
        </PageWrapper>
    )

}
