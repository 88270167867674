import {Handle, Position} from "react-flow-renderer";
import {ALLOWDRAG, mapper} from "../../../constants";
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import {useDispatch} from "react-redux";
import {openTriggerRepeat} from '../../../store/slice';
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Typo} from "../../../../../../newShared/components/Typography";
import {Box, Flex} from "../../../../../../newShared/components/Layouts";

type repeatNodeProps = {
    repeat: number;
    revDir: boolean
}

export const RepeatNode = ({data} : {data: repeatNodeProps}) => {
    const {repeat, revDir} = data;
    const {t} = useMainTranslation('', {keyPrefix: 'automations'});

    const translateSeconds = (data: number): string => {
        if(data % mapper.Years === 0  ) return (data / mapper.Years) === 1 ? t('year') : (data / mapper.Years) + ` ${t('repeatEveryType.Years')}`;
        if(data % mapper.Months === 0  ) return (data / mapper.Months) === 1 ? t('month') : (data / mapper.Months) + ` ${t('repeatEveryType.Months')}`;
        if(data % mapper.Weeks === 0  ) return (data / mapper.Weeks) === 1 ? t('week') : (data / mapper.Weeks) + ` ${t('repeatEveryType.Weeks')}`;
        if(data % mapper.Days === 0  ) return  (data / mapper.Days) === 1 ? t('day') : (data / mapper.Days) + ` ${t('repeatEveryType.Days')}`;
        if(data % mapper.Hours === 0  ) return  (data / mapper.Hours) === 1 ? t('hour') : (data / mapper.Hours) + ` ${t('repeatEveryType.Hours')}`;
        return data + ' seconds';
    }

    const dispatch = useDispatch();

    const handleSetting = () => {dispatch(openTriggerRepeat())};

    return(
        <Box
            w={'165px'}
            h={'50px'}
            br={'11px'}
            background={'#3BCC89'}
            box_shadow={'0px 0px 5.853034973144531px 0px rgba(0, 0, 0, 0.12)'}
            // ai={'center'}
            // jc={'center'}
            p={'5px 10px'}
            className={ALLOWDRAG ? 'nodrag': ''}
            cursor={'pointer'}
        >
            <Handle
                type="target"
                position={!revDir ? Position.Left : Position.Right}
                id="a"
                isConnectable={false}
                style={{opacity: '0'}}
            />

            <Flex ai={'center'} direction={'column'} jc={'space-evenly'} style={{position: 'relative', bottom: '0', left: '0'}}>
                <Typo fontWeight={400} fontSize={'12px'} margin={'0 0 5px'} sx={{lineHeight: 'normal'}}>{t('Repeat every')}</Typo>
                <Typo fontWeight={400} fontSize={'12px'} sx={{lineHeight: 'normal'}}>{translateSeconds(repeat)}</Typo>
            </Flex>

            <Flex style={{position: 'relative', bottom: '20px', right: !revDir ? '-165px' : '35px'}}>
                <ScheduleOutlinedIcon
                    style={{width: '20px', height: '20px', color: '#5D5D5D', cursor: 'pointer'}}
                    onClick={handleSetting}
                />
            </Flex>
        </Box>
    )
}