import {FlexColumn} from "../../editorTitleWithActionsRow/styled";
import {styled} from "@mui/material";

export const EditorItemPageStyled = styled(FlexColumn, {shouldForwardProp: propName => (
      !['width', 'height', 'isTemplate'].includes(propName.toString())
  )})<{width: number, height: number, isTemplate?: boolean}>(({width, height, isTemplate}) => ({
  width: isTemplate ? `min(${width}px, 95%)` : `${width}px`,
  minHeight: `${height}px`,

  position: 'relative',

  backgroundColor: 'white',
  boxShadow: '0px 0.586351px 1.75905px rgba(0, 0, 0, 0.1), 0px 3.12721px 7.03621px rgba(0, 0, 0, 0.13)',

  flexShrink: 0,
}));


type TEditorItemPageContentStyled = {
  // headerHeight?: number, footerHeight?: number,
  showHeaderLine?: boolean, showFooterLine?: boolean,
  marginLeft: number, marginRight: number,
  marginTop?: number, marginBottom?: number,
  isOpacity?: boolean
};
export const EditorItemPageContentStyled = styled(FlexColumn, {shouldForwardProp: propName => (
      !['headerHeight', 'footerHeight', 'showHeaderLine', 'showFooterLine', 'marginTop', 'marginBottom', 'marginLeft', 'marginRight', 'isOpacity'].includes(propName.toString())
  )})<TEditorItemPageContentStyled>(({marginLeft, marginRight, marginTop, marginBottom, showHeaderLine, showFooterLine, isOpacity}) => ({
  width: '100%',
  paddingLeft: `${marginLeft}px`,
  paddingRight: `${marginRight}px`,
  gap: '2px',
  paddingBlock: '8px',
  paddingTop: marginTop ?? '8px',
  paddingBottom: marginBottom ?? '8px',
  flexGrow: marginBottom === undefined && marginTop === undefined ? 1 : undefined,

  // minHeight: headerHeight ?? footerHeight ?? 0,

  borderBottom: marginTop !== undefined ? (showHeaderLine ? '1px dashed grey' : '1px dashed transparent') : undefined,
  borderTop: marginBottom !== undefined ? (showFooterLine ? '1px dashed grey' : '1px dashed transparent') : undefined,

  '&:hover': {
    borderBottom: marginTop ? '1px dashed lightblue' : undefined,
    borderTop: marginBottom ? '1px dashed lightblue' : undefined,
  },

  opacity: isOpacity ? 0.5 : undefined,
  cursor: isOpacity ? 'pointer' : undefined,

  position: 'relative',
  '&::before': {
    display: isOpacity ? 'block' : 'none',
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
  },
  '& *': {
    pointerEvents: isOpacity ? 'none' : undefined,
  },

}));
