import React, {Component, createRef, RefObject} from "react";
import {
    EditorComponentsMethods,
    EditorListData,
    getToolBarProps,
    MapDispatchToPropsObject,
    TBlockWithHtml,
    TEditorListElementData,
    TEditorListLiElementData
} from "../../types";
import {ToolBarProps} from "../editorToolBar";
import {EditorItemListStyled} from "./styled";
import {EditorItemBlock} from "../editorItemBlock";
import {EditorPopperButtonsProps} from "../commonComponents/editorPopperButtons";

import {EditorWorkZone} from "../editorWorkzone";
import {EditorItemListLi} from "./components/editorItemListLi";
import {createEventSetListElementActive} from "../../helpers";
import {concatListItemsIntoBlock} from "../TextComponent/helpers";
import {TListType} from "../editorToolBar/types";
import {uuid} from "../../../../utils";

interface Props extends getToolBarProps {
    block: TEditorListElementData;

    parentRef: RefObject<EditorItemBlock>["current"];
    editorWorkZoneRef: RefObject<EditorWorkZone>["current"];
    setPopperButtons: (buttons: EditorPopperButtonsProps["buttons"]) => void;
}
export interface ListState {
    id: string;
    type: 'list';
    data: EditorListData;

    wrapperRef: RefObject<HTMLUListElement>;
    refs: Record<string, RefObject<EditorItemListLi>>;

    onCreate: boolean;
}

export class EditorItemList extends Component<Props, ListState> implements EditorComponentsMethods<EditorListData> {
    constructor(props: EditorItemList["props"]) {
        super(props);

        const listContent = props.block.data.content || [];
        const onCreate = listContent.length === 0;

        if (listContent.length === 0) {
            listContent.push({
                id: 'listElement' + uuid(),
                type: 'listElement',
                data: {
                    content: [],
                    textBlock: {
                        id: 'text' + uuid(),
                        type: 'text',
                        data: {
                            content: [],
                            innerHtml: '',
                            alignText: 'left',
                        },
                    }
                },
            });
        }

        const refs: ListState["refs"] = {};
        listContent.forEach(row => {
            refs[row.id] = createRef<EditorItemListLi>()
        });

        const listType = props.block.data.type || 'ordered';

        this.state = {
            id: props.block.id,
            type: 'list',
            data: {
                ...props.block.data,
                type: listType,
                listStyleType: props.block.data.type
                    ? (
                        props.block.data.listStyleType ||
                        (listType === "ordered"
                            ? 'decimal'
                            : 'circle')
                    )
                    : 'decimal',
                content: listContent,
            },

            onCreate,

            wrapperRef: createRef<HTMLUListElement>(),
            refs,
        }
    }

    componentDidMount() {
        this.setButtons();

        if (this.state.onCreate) {
            setTimeout(() => createEventSetListElementActive({id: this.state.data.content[0].id}), 100);
            this.setState((prev) => ({...prev, onCreate: false}));
        }
    }

    setFocus = () => {

    };

    setActiveBlock: EditorWorkZone["setActiveBlock"] = (ids) => {
        // console.log(`LIST setActiveBlock`, ids)
        this.props.parentRef?.setActiveBlock([this.state.id, ...ids]);
    };

    ///////ToolBar
    setToolBar: MapDispatchToPropsObject["setToolBar"] = (toolBar, ids) => {
        toolBar.listTypeControl = {
            isDisabled: false,
            isShow: true,
            value: `list-${this.state.data.type}-${this.state.data.listStyleType}` as TListType,

        }

        console.log(`setToolBar TABLE`, toolBar)
        this.props.parentRef?.setToolBar(toolBar, [this.state.id, ...ids])
    }
    setTextByToolBar: ToolBarProps["toolBarHandler"] = (action, payload, ids) => {
        if (action === 'listTypeControl') {
            payload && this.setState({
                data: {
                    ...this.state.data,
                    type: ('' + payload).split('-')[1] as EditorListData["type"],
                    listStyleType: ('' + payload).split('-')[2] as EditorListData["listStyleType"],
                }
            })

            if (!payload) {
                const data: string[] = [];
                this.state.data.content.forEach(row => {
                    const ref = this.state.refs[row.id]?.current;
                    if (ref) {
                        data.push(ref.getData().data.textBlock?.data.innerHtml || '');
                    }
                });

                const results = concatListItemsIntoBlock(data);
                this.props.parentRef?.changeToText(results);
            }
        }

        const nextId = ids[0];
        if (nextId) {
            const ref = this.state.refs[nextId]?.current;
            if (ref) {
                ref.setTextByToolBar(action, payload, ids.slice(1))
            }
        }
    }

    // Get data
    getData = (): Props["block"] => {
        const contentListData: EditorListData["content"] = JSON.parse(JSON.stringify(this.state.data.content));

        contentListData.forEach(row => {
            const ref = this.state.refs[row.id]?.current;
            if (ref) {
                row.data = ref.getData().data;
            }
        });

        return {
            id: this.state.id,
            type: this.state.type,
            data: {
                ...this.state.data,
                content: contentListData,
            },
        }
    }

    onClickAway = () => {
        // console.log(`onClickAway TABLE`)
        // this.setSelection(undefined);
        // createEventSetListElementActive({id: null});
    }

    setButtons = () => {
        // console.log(`setButtons TABLE`, selected)
        const buttons: EditorPopperButtonsProps["buttons"] = [
            // [
            //     {
            //         title: 'Add row above',
            //         icon: AddRowBefore,
            //         onClick: (e) => {
            //             e.stopPropagation();
            //             this.addRow();
            //         },
            //         isDisabled: !selected,
            //     },
            //     {
            //         title: 'Add row below',
            //         icon: AddRowAfter,
            //         onClick: (e) => {
            //             e.stopPropagation();
            //             this.addRow(true);
            //         },
            //         isDisabled: !selected,
            //     },
            //     {
            //         title: 'Add column before',
            //         icon: AddColumnBefore,
            //         onClick: (e) => {
            //             e.stopPropagation();
            //             this.addColumn();
            //         },
            //         isDisabled: !selected,
            //     },
            //     {
            //         title: 'Add column after',
            //         icon: AddColumnAfter,
            //         onClick: (e) => {
            //             e.stopPropagation();
            //             this.addColumn(true);
            //         },
            //         isDisabled: !selected,
            //     },
            // ],[
            //     {
            //         title: 'Remove row',
            //         icon: RemoveRow,
            //         onClick: (e) => {
            //             e.stopPropagation();
            //             this.deleteRow();
            //         },
            //         isDisabled: !selected,
            //     },
            //     {
            //         title: 'Remove column',
            //         icon: RemoveColumn,
            //         onClick: (e) => {
            //             e.stopPropagation();
            //             this.deleteColumn();
            //         },
            //         isDisabled: !selected,
            //     },
            // ],
        ]

        this.props.setPopperButtons(buttons);
    }

    addElement = (afterId: string, html?: string) => {
        const index = this.state.data.content.findIndex(row => row.id === afterId);
        const newElement: TEditorListLiElementData = {
            id: 'listElement' + uuid(),
            type: 'listElement',
            data: {
                content: [],
                textBlock: {
                    id: 'text' + uuid(),
                    type: 'text',
                    data: {
                        content: [],
                        innerHtml: html ?? '',
                        alignText: 'left',
                    },
                },
            },
        };

        this.setState(prev => {
            if (!prev.data.content.some(row => row.id === newElement.id)) {
                prev.refs[newElement.id] = createRef<EditorItemListLi>()
                prev.data.content.splice(index + 1, 0, newElement);
            }

            return prev;
        });
        setTimeout(() => createEventSetListElementActive({id: newElement.id}), 1)
    }

    removeElement = (id: string, deletedInnerHtml: string) => {
        const index = this.state.data.content.findIndex(row => row.id === id);
        index > 0 && this.setState(prev => {
            const index = prev.data.content.findIndex(row => row.id === id);
            index > -1 && prev.data.content.splice(index, 1);
            return prev;
        });

        const elementBefore = this.state.data.content[index - 1];
        elementBefore && createEventSetListElementActive({id: elementBefore.id, byBackspace: true, insertHtml: deletedInnerHtml});
    }

    handleDivideToBlocks = (data: TBlockWithHtml[], type: TListType, id?: string) => {
        // console.log(`handleDivideToBlocks2`)
        this.props.parentRef?.handleDivideToBlocks(data, type);
    }

    render() {
        return (
            <EditorItemListStyled ref={this.state.wrapperRef} listStyle={this.state.data.listStyleType} isRoot
                                  total={this.state.data.content.length}>
                {this.state.data.content.map(row => (
                    <EditorItemListLi key={row.id} ref={this.state.refs[row.id]}
                                      // type={this.state.data.listStyleType}
                                      parentRef={this}
                                      block={row}
                                      getToolBarState={this.props.getToolBarState}
                                      editorWorkZoneRef={this.props.editorWorkZoneRef}
                                      setPopperButtons={this.props.setPopperButtons}

                                      status={this.props.status}
                                      readonly={this.props.readonly}
                                      isLoading={this.props.isLoading}
                    />
                ))}
            </EditorItemListStyled>
        );
    }
}