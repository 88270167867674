import {createAsyncThunk} from "@reduxjs/toolkit";
import {TWithOptions} from "../../../../../newShared/types";
import {
    KycCreateTemplateMutationVariables,
    KycDeleteTemplateMutationVariables,
    KycGenerateTokenByTemplateIdMutationVariables,
    KycGetTemplateByIdQueryVariables,
    KycGetTemplateFieldsQueryVariables,
    KycGetTemplatesQueryVariables,
    KycGetTokenByTemplateIdQueryVariables,
    KycSendRequestsToEmailsMutationVariables,
    KycUpdateTemplateMutationVariables
} from "../../../../../newShared/GQLTypes";
import {AppState} from "../../../../../newShared/redux/rootReducer";
import {getActionsData} from "../../../../../newShared/redux";
import {
    kycCreateTemplateApi,
    kycDeleteTemplateApi,
    kycGenerateTokenByTemplateIdApi,
    kycGetTemplateByIdApi,
    kycGetTemplateFieldsApi,
    kycGetTemplatesApi,
    kycGetTokenByTemplateIdApi,
    kycSendRequestsToEmailsApi,
    kycUpdateTemplateApi
} from "../../api";


export const kycGetTemplatesAction = createAsyncThunk(
    'KYC/templates/kycGetTemplates',
    async (data: TWithOptions<KycGetTemplatesQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await kycGetTemplatesApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const kycGetTemplateByIdAction = createAsyncThunk(
    'KYC/templates/kycGetTemplateById',
    async (data: TWithOptions<KycGetTemplateByIdQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await kycGetTemplateByIdApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const kycGetTokenByTemplateIdAction = createAsyncThunk(
    'KYC/templates/kycGetTokenByTemplateId',
    async (data: TWithOptions<KycGetTokenByTemplateIdQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await kycGetTokenByTemplateIdApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const kycGenerateTokenByTemplateIdAction = createAsyncThunk(
    'KYC/templates/kycGenerateTokenByTemplateId',
    async (data: TWithOptions<KycGenerateTokenByTemplateIdMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await kycGenerateTokenByTemplateIdApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const kycUpdateTemplateAction = createAsyncThunk(
    'KYC/templates/kycUpdateTemplate',
    async (data: TWithOptions<KycUpdateTemplateMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await kycUpdateTemplateApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const kycCreateTemplateAction = createAsyncThunk(
    'KYC/templates/kycCreateTemplate',
    async (data: TWithOptions<KycCreateTemplateMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await kycCreateTemplateApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const kycDeleteTemplateAction = createAsyncThunk(
    'KYC/templates/kycDeleteTemplate',
    async (data: TWithOptions<KycDeleteTemplateMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await kycDeleteTemplateApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const kycGetTemplateFieldsAction = createAsyncThunk(
    'KYC/templates/kycGetTemplateFields',
    async (data: TWithOptions<KycGetTemplateFieldsQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await kycGetTemplateFieldsApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const kycSendRequestsToEmailsAction = createAsyncThunk(
    'KYC/templates/kycSendRequestsToEmails',
    async (data: TWithOptions<KycSendRequestsToEmailsMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await kycSendRequestsToEmailsApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        // dispatch(addInfoSnack(resp.message))
        return {
            data: data.data,
            resp
        }
    },
);

