import {useEffect, useState} from "react";
import {TImportCsvDraggableRowProps} from "../../components/importCsvDraggableRow";
import {useFileDropZone} from "../../../../hooks/useSingleFileDropZone";
import {TImportCsvField} from "../../types";
import {TImportCsvDialogProps} from "../../index";

export const useImportCsvDialog = ({getFields, fileLimitInMB = 0}: {getFields: TImportCsvDialogProps["getFields"], fileLimitInMB?: number}) => {

    const {fileInBase64, filePicker, fileHandler, ref, handleDeleteFile} = useFileDropZone(fileLimitInMB);
    const [mismatchFields, setMismatchFields] = useState<TImportCsvField[]>([]);

    const onDrop = (fieldKey: string): TImportCsvDraggableRowProps["onDrop"] => (csvField) => {
        setMismatchFields(prev => {

            const newPrev = [
                ...prev
                    .map(item => item.csvField === csvField && item.fieldKey !== fieldKey ? {...item, csvField: ''} : item)
                    .map(item => item.fieldKey === fieldKey ? {...item, csvField} : item)
                    .filter(item => !(
                        (item.csvField === csvField && !item.fieldKey?.length) ||
                        (!item.fieldKey?.length && !item.csvField?.length)
                    )),
            ];

            return newPrev;
        });
    };

    const onChange = (fieldKey: string): TImportCsvDraggableRowProps["onChange"] => (isKey) => {
        setMismatchFields(prev => prev.map(item => item.fieldKey === fieldKey ? {...item, isKey} : item));
    };

    const onUnassign = (fieldKey: string, csvField: string): TImportCsvDraggableRowProps["onUnassign"] => () => {
        setMismatchFields(prev => [
            ...prev
                .map(item => item.fieldKey === fieldKey ? {...item, csvField: ''} : item)
                .filter(item => item.csvField !== csvField),
            {fieldKey: '', csvField}
        ]);
    };

    useEffect(() => {
        fileInBase64 && getFields(fileInBase64);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileInBase64]);

    return {
        onDrop, onChange, onUnassign,
        mismatchFields, setMismatchFields,

        fileInBase64, filePicker, fileHandler, ref, handleDeleteFile,
    }
}