import {ConfluenceIntergationDetailsType, EditIntegrationCommonPropsType} from "../../../../types";
import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {useForm} from "../../../../../../../newShared/hooks/useForm";
import {CONFLUENCE_ENDPOINT_MAPPING_NAME} from "../../../../constants";
import {UpdateIntegration} from "../../../../store/actions";
import {loadings} from "../../../../store/slice";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";

export const useEditConfluence = (props: EditIntegrationCommonPropsType) => {
    const dispatch = useDispatch();
    const {workspaceId} = UseManageWorkspacesAndOrganizations();
    const {updateIntegration} = useSelector(loadings);

    // const [fileName, setFileName] = useState<String>("");
    const {form, setForm, handleChange} = useForm<ConfluenceIntergationDetailsType>({
        applicationId: props.details.applicationId,
        workspaceId: workspaceId ?? '',
        status: '',
        integration: {
            authorization_code: '',
        },
        identity: props.details.identity,
        hris: props.details.hris,
        endpointMappingName: CONFLUENCE_ENDPOINT_MAPPING_NAME,
        detailsId: props.details.detailsId,
        //additional:
        name: props.details.name,
    });
    const handleChangeIntegration = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm(prev => ({...prev, integration: {...prev.integration, [e.target.name]: e.target.value}}));
    }

    const isOk = form.integration.authorization_code.length > 0 && form.name.trim().length > 0;

    const handleSubmit = () => {
        if(isOk){
            dispatch(UpdateIntegration({integrationDetails: form, name: form.name, detailsId: form.detailsId}));
        }
    }

    //other

    //
    const ref = React.createRef<HTMLInputElement>();
    const filePicker = () => {
        ref.current && ref.current.click();
    };

    return{
        form,
        isOk,
        ref,
        actions: {
            handleSubmit,
            setForm,
            handleChangeIntegration,
            handleChange,
            filePicker
        },
        // fileName,
        isLoading: updateIntegration
    }
}