import {
    frameworkColorMapper,
    tasksInitialState,
    TCreateFormDoc,
    TVicaTask,
    TVicaTaskCategory,
    TVicaTaskStatus,
    vicaCollab,
    vicaTaskAction
} from "../../types";
import {TPageInfo} from "../../../../../newShared/types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../../newShared/redux/rootReducer";
import {
    createByTemplateTaskAction,
    getOriginTemplateByIdAction,
    GetVicaCategoriesWithPaging,
    GetVicaTasksByCategory,
    GetWorkspaceCollabs,
    MainVicaCreateDelegatedTaskAction,
    SetTaskCategoryResponsible,
    SetTaskCollabs,
    uploadEvidenceAssetAction
} from "./actions";
import {setPaginationInStore} from "../../../../../newShared/hooks/useScroll/helpers";
import {generateColorsForFrameworks} from "../../helpers";
import {COMPLETE_STATUS, defaultFormCreateDoc, IN_PROGRESS_STATUS} from "../../constants";
import {
    AutocompleteManagersEmailName,
    CreateNewDoc,
    GetFolderTree,
    GetFullDocumentWithExecutor,
    GetNewDocumentById,
    GetNewTemplateById,
    handlePreviewBeforeSendAction,
    InvalidateDocument,
    RestartSignStageByStageIdAndGroupId,
    RestartSignStageByStageIdAndRecipient,
    SendToNextStepByStageId,
    StartNewDocumentExecution,
    UpdateFullDocument
} from "../../../documentsRefactor/store/actions";
import {sortFolders, sortRootFolders} from "../../../documentsRefactor/helpers";
import {TFileContent} from "../../../../../newShared/components/editorUnion/types";
import {uuid} from "../../../../../newShared/utils";
import {TEditorFillableBlockData} from "../../../documentsRefactor/types";
import {getMsFromDays} from "../../../../../newShared/utils/dateTools";
import {addSetDialogAction} from "../../../../../newShared/actions/setDialogAction";

const defaultPageInfo: TPageInfo = {
    page: 0,
    count: 10, //used to show loader (shows by hasMore) on init, before resolving first fetch
    total: 11 //it will be replaced by resolved data
}

export const initialState: tasksInitialState = {
    categories: [],

    frameworkColors: [],

    categoriesPageInfo: defaultPageInfo,
    selectedNewDocument: null,
    selectedNewTemplate: null,
    employees: [],
    documentsFolders: [],
    // foldersPath: [],

    loadings: {
        isLoadingCreate: false,
        isLoadingCategories: false,
        isLoadingTasksForCategoryId: [],
        isLoadingSetResponsible: false,
        isLoadingUpdateTaskCollaborators: false,
        isLoadingWsCollabs: false,
        isLoadingUploadFile: false,
        isLoadingTemplate: false,
        isLoadingEmployees: false,
        isLoadingReleaseTemplate: false,
        isLoadingTree: false,
        isLoadingSaveDoc: false,
        isLoadingExact: false,
        isLoadingRestartSignStageByStageIdAndRecipient: false,
        isLoadingRestartSignStageByStageIdAndGroupId: false,
        isLoadingSendToNextStep: false,
        isLoadingInvalidate: false,
        isLoadingSave: false,
    },

    dialogs: {
        createDelegatedTask: {
            vicaStepId: null,
        },
        selectTaskCollaborators: {
            isOpen: false,
            task: null,
            categoryId: null
        },
        selectCategoryResponsible: {
            isOpen: false,
            category: null,
        },
        showMoreFrameworkCards: {
            isOpen: false,
            task: null,
        },
        showMoreDesc: {
            isOpen: false,
            description: '',
            categoryName: ''
        },
        actionUploadDialog: {
            isOpen: false,
            categoryId: '',
            task: null,
            action: null,
        },
        actionTemplateDialog: {
            isOpen: false,
            categoryId: '',
            task: null,
            action: null,
            form: defaultFormCreateDoc,

            template: null,
            newTemplate: null,
            docId: null,
        },
        addGroupMessage: {
            isOpen: false,
            approverId: null,
            groupId: null,
            recipientId: null,
            oldMessage: null,
        },
        //action dialog
    },

    collaborators: [],
}

export const vicaTasksSlice = createSlice({
    name: 'vicaTasks',
    initialState,
    reducers: {
        ...addSetDialogAction('createDelegatedTask', initialState),

        openSelectTaskCollabs: (slice, {payload}: {payload: {task: TVicaTask, categoryId: string}}) => {slice.dialogs.selectTaskCollaborators = {task: payload.task, categoryId: payload.categoryId, isOpen: true}},
        hideSelectTaskCollabs: (slice) => {slice.dialogs.selectTaskCollaborators = {task: null, categoryId: null, isOpen: false}},

        openShowMoreFrameworkCards: (slice, {payload}: {payload: {task: TVicaTask}}) => {slice.dialogs.showMoreFrameworkCards = {task: payload.task, isOpen: true}},
        hideShowMoreFrameworkCards: (slice) => {slice.dialogs.showMoreFrameworkCards = {task: null, isOpen: false}},

        openShowMoreDesc: (slice, {payload}: {payload: {categoryName: string, desc: string}}) => {slice.dialogs.showMoreDesc = {description: payload.desc, categoryName: payload.categoryName, isOpen: true}},
        hideShowMoreDesc: (slice) => {slice.dialogs.showMoreDesc = {description: '', categoryName: '', isOpen: false}},

        openSelectCategoryResponsible: (slice, {payload}: {payload: {category: TVicaTaskCategory}}) => {slice.dialogs.selectCategoryResponsible = {category: payload.category, isOpen: true}},
        hideSelectCategoryResponsible: (slice) => {slice.dialogs.selectCategoryResponsible = {category: null, isOpen: false}},

        setFormCreateDoc: (state, {payload}: {payload: { form?: TCreateFormDoc }}) => {
            if(payload.form !== undefined) state.dialogs.actionTemplateDialog.form = payload.form;
        },
        openActionDialog: (slice, {payload}: {payload: {categoryId: string, task: TVicaTask, action: vicaTaskAction}}) => {
            switch (payload.action.type){
                case 'TEMPLATE':{
                    slice.dialogs.actionTemplateDialog = {categoryId: payload.categoryId, action: payload.action, task: payload.task, isOpen: true, template: null, newTemplate: null, docId: null, form: defaultFormCreateDoc};
                    return;
                }
                case 'UPLOAD': {
                    slice.dialogs.actionUploadDialog = {categoryId: payload.categoryId, action: payload.action, task: payload.task, isOpen: true};
                }
            }
        },
        hideActionDialog: (slice, {payload}: {payload: ('actionTemplateDialog' | 'actionUploadDialog')[]}) => {
            if (payload.includes('actionTemplateDialog') || !payload.length) {
                slice.dialogs.actionTemplateDialog = initialState.dialogs.actionTemplateDialog;
            }
            if (payload.includes('actionUploadDialog') || !payload.length) {
                slice.dialogs.actionUploadDialog = {categoryId: '', task: null, action: null, isOpen: false}
            }
        },
        openAddGroupMessage: (slice, {payload}: {payload: {approverId?: string, groupId?: string, oldMessage?: string, recipientId?: string}}) => {
            if(payload.approverId !== undefined && payload.groupId !== undefined) {
                slice.dialogs.addGroupMessage.isOpen = true;
                slice.dialogs.addGroupMessage.approverId = payload.approverId;
                slice.dialogs.addGroupMessage.groupId = payload.groupId;
            }
            if(payload.recipientId !== undefined) {
                slice.dialogs.addGroupMessage.isOpen = true;
                slice.dialogs.addGroupMessage.recipientId = payload.recipientId;
            }
            if(payload.oldMessage !== undefined) slice.dialogs.addGroupMessage.oldMessage = payload.oldMessage;
        },
        hideAddGroupMessage: (slice) => {
            slice.dialogs.addGroupMessage = initialState.dialogs.addGroupMessage;
        },
        deselectDocument: (slice) => {
            slice.selectedNewDocument = null;
        },
        handleReturnToDraftAction: (slice) => {
            if (slice.selectedNewDocument?.document?.data) {
                slice.selectedNewDocument.document.recipients = slice.selectedNewDocument.document.recipients!.map(e => ({...e, fields: e.fields?.filter(e => !e.pageId.startsWith('pdfPage'))}));

                slice.selectedNewDocument.document.data.editor = slice.selectedNewDocument.document.data.editor.filter((e: TFileContent[number]) => e.type !== 'pdfPage');

                slice.selectedNewDocument.document.data.pdfFileId = null;
            }
        },
        eraseCategories: (slice) => {slice.categories = []; slice.categoriesPageInfo = initialState.categoriesPageInfo},

        setFrameworkColors: (slice, {payload}: {payload: frameworkColorMapper[]}) => {
            slice.frameworkColors = payload;
        },

        cleanUp: (slice) => initialState,
    },
    extraReducers: (builder) => {
        builder
        // GetWorkspaceCollabs
            .addCase(GetWorkspaceCollabs.pending, (slice, {meta}) => {
                slice.loadings.isLoadingWsCollabs = true;
            })
            .addCase(GetWorkspaceCollabs.rejected, (slice) => {
                slice.loadings.isLoadingWsCollabs = false;
            })
            .addCase(GetWorkspaceCollabs.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingWsCollabs = false;
                slice.collaborators = payload;
            })
        //GetVicaCategoriesWithPaging
            .addCase(GetVicaCategoriesWithPaging.pending, (slice, {meta}) => {
                slice.loadings.isLoadingCategories = true;
            })
            .addCase(GetVicaCategoriesWithPaging.rejected, (slice) => {
                slice.loadings.isLoadingCategories = false;
            })
            .addCase(GetVicaCategoriesWithPaging.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingCategories = false;

                const {array, pageInfo} = setPaginationInStore<TVicaTaskCategory>(
                    slice.categoriesPageInfo,
                    slice.categories,
                    payload.pageInfo,
                    payload.categories,
                    false
                );
                slice.categories = array.filter(e => e !== null) as TVicaTaskCategory[];
                slice.categoriesPageInfo = pageInfo;
            })
        //GetVicaTasksByCategory
            .addCase(GetVicaTasksByCategory.pending, (slice, {meta}) => {
                slice.loadings.isLoadingTasksForCategoryId = [...slice.loadings.isLoadingTasksForCategoryId, meta.arg.data.categoryId];
            })
            .addCase(GetVicaTasksByCategory.rejected, (slice, {meta}) => {
                slice.loadings.isLoadingTasksForCategoryId = slice.loadings.isLoadingTasksForCategoryId.filter(e => e !== meta.arg.data.categoryId);
            })
            .addCase(GetVicaTasksByCategory.fulfilled, (slice, {payload}) => {
                //removing categorry id from loading ids arr
                slice.loadings.isLoadingTasksForCategoryId = slice.loadings.isLoadingTasksForCategoryId.filter(e => e !== payload.categoryId);
                //put tasks in category
                slice.categories = slice.categories.map(e => e.id === payload.categoryId ? {...e, tasks: payload.tasks} : e);
                //generate and assign framework colors
                const frameworkIds = slice.categories.flatMap(e => e.tasks).flatMap(e => e.frameworkNames);
                slice.frameworkColors = generateColorsForFrameworks(slice.frameworkColors, frameworkIds);
            })
        //SetTaskCategoryResponsible
            .addCase(SetTaskCategoryResponsible.pending, (slice, {meta}) => {
                slice.loadings.isLoadingSetResponsible = true;
            })
            .addCase(SetTaskCategoryResponsible.rejected, (slice, {meta}) => {
                slice.loadings.isLoadingSetResponsible = false;
            })
            .addCase(SetTaskCategoryResponsible.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingSetResponsible = false;
                //replacing responsible in updated category
                slice.categories = slice.categories.map(e => e.id === payload.categoryId ? {
                    ...e,
                    responsible: payload.collab as vicaCollab
                } : e);
                //closing dialog
                slice.dialogs.selectCategoryResponsible = {isOpen: false, category: null};
            })
        //SetTaskCollabs
            .addCase(SetTaskCollabs.pending, (slice, {meta}) => {
                slice.loadings.isLoadingUpdateTaskCollaborators = true;
            })
            .addCase(SetTaskCollabs.rejected, (slice, {meta}) => {
                slice.loadings.isLoadingUpdateTaskCollaborators = false;
            })
            .addCase(SetTaskCollabs.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingUpdateTaskCollaborators = false;

                const currCategory = slice.categories.find(e => e.id === payload.categoryId);
                if(currCategory) {
                    //updating task collabs
                    currCategory.tasks = [...currCategory.tasks.map(t => t.id === payload.taskId ? {
                        ...t,
                        collaborators: payload.collabs
                    } : t)];
                    //calculating distinct collaborators arr for category
                    const allCollabs = currCategory.tasks.flatMap(e => e.collaborators);
                    //using new Set by publicId array because if put whole object - it will keep duplicates
                    const distinctPublicIds = Array.from(new Set(allCollabs.map(e => e.publicId)));
                    //mapping distinct publicId to whole object + removing undefined (there will be none just type check ebotnya)
                    currCategory.collaborators = distinctPublicIds
                        .map(e => allCollabs.find(ac => ac.publicId === e))
                        .filter(e => e !== undefined) as vicaCollab[];

                    //updating category
                    slice.categories = slice.categories.map(e => e.id === payload.categoryId ? currCategory : e);
                }
                //closing dialog
                slice.dialogs.selectTaskCollaborators = {isOpen: false, task: null, categoryId: ''};
            })
        //uploadEvidenceAssetAction
            .addCase(uploadEvidenceAssetAction.pending, (slice, {meta}) => {
                slice.loadings.isLoadingUploadFile = true;
            })
            .addCase(uploadEvidenceAssetAction.rejected, (slice, {meta}) => {
                slice.loadings.isLoadingUploadFile = false;
            })
            .addCase(uploadEvidenceAssetAction.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingUploadFile = false;
                slice.categories = slice.categories.map(e => {
                    if (e.id !== payload.data.categoryId) return e;
                    const tasks = [...e.tasks.map(t => t.id === payload.data.taskId ? {...t, status: COMPLETE_STATUS as TVicaTaskStatus} : t)]

                    return e.id === payload.data.categoryId ? {
                        ...e,
                        tasks,
                        progress: Math.floor(tasks.filter(e => e.status === COMPLETE_STATUS).length / e.tasks.length * 100)
                    } : e
                });
                slice.dialogs.actionUploadDialog = {categoryId: '', task: null, action: null, isOpen: false}
            })

        //createByTemplateTaskAction
            .addCase(getOriginTemplateByIdAction.pending, (slice, {meta}) => {
                slice.loadings.isLoadingTemplate = true;
            })
            .addCase(getOriginTemplateByIdAction.rejected, (slice, {meta}) => {
                slice.loadings.isLoadingTemplate = false;
            })
            .addCase(getOriginTemplateByIdAction.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingTemplate = false;
                if (payload.resp.data?.innerHtml) {
                    slice.dialogs.actionTemplateDialog = {
                        ...slice.dialogs.actionTemplateDialog,
                        template: {
                            id: payload.resp.id,
                            name: payload.resp.name,
                            config: payload.resp.data.variables || [],
                            innerHtml: payload.resp.data.innerHtml,
                        },
                        newTemplate: payload.resp
                    }
                }
            })

        //createByTemplateTaskAction
            .addCase(createByTemplateTaskAction.pending, (slice, {meta}) => {
                slice.loadings.isLoadingReleaseTemplate = true;
            })
            .addCase(createByTemplateTaskAction.rejected, (slice, {meta}) => {
                slice.loadings.isLoadingReleaseTemplate = false;
            })
            .addCase(createByTemplateTaskAction.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingReleaseTemplate = false;
                if(slice.dialogs.actionTemplateDialog.task !== null){
                    slice.categories = slice.categories.map(e => e.id === slice.dialogs.actionTemplateDialog.categoryId ? {
                        ...e,
                        tasks: [...e.tasks.map(t => t.id === (slice.dialogs.actionTemplateDialog.task?.id ?? '') ? {...t, status: IN_PROGRESS_STATUS as TVicaTaskStatus} : t)],
                    } : e);
                }
                slice.dialogs.actionTemplateDialog = initialState.dialogs.actionTemplateDialog;
            })
            //GetNewDocById /Template
            .addCase(GetNewDocumentById.pending, (slice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(GetNewDocumentById.rejected, (slice) => {
                slice.loadings.isLoadingExact = false;

            })
            .addCase(GetNewDocumentById.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingExact = false;
                slice.selectedNewDocument = { document: payload, documentExecutor: null};
            })
            .addCase(GetNewTemplateById.pending, (slice) => {
                slice.loadings.isLoadingTemplate = true;
            })
            .addCase(GetNewTemplateById.rejected, (slice) => {
                slice.loadings.isLoadingTemplate = false;

            })
            .addCase(GetNewTemplateById.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingTemplate = false;
                slice.selectedNewTemplate = payload;

                if (slice.dialogs.actionTemplateDialog.isOpen) {
                    slice.dialogs.actionTemplateDialog.form.name = payload.name;
                    slice.dialogs.actionTemplateDialog.form.recipients = payload.recipients?.map(e => ({
                        id: uuid(),
                        role: e.role,
                        message: e.message || '',
                        actor: e.actor,
                        fields: e.fields?.map(f => ({...f, type: f.type as TEditorFillableBlockData['type']})) || [],
                        order: e.order,
                        eta: e.eta ?? getMsFromDays(7),
                    })) || [];
                }
            })
            // AutocompleteManagersEmailName
            .addCase(AutocompleteManagersEmailName.pending, (slice) => {
                slice.loadings.isLoadingEmployees = true;
            })
            .addCase(AutocompleteManagersEmailName.rejected, (slice) => {
                slice.loadings.isLoadingEmployees = false;

            })
            .addCase(AutocompleteManagersEmailName.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingEmployees = false;
                slice.employees = payload.resp;
            })
        //getFolderTree
            .addCase(GetFolderTree.pending, (slice) => {
                slice.loadings.isLoadingTree = true;
            })
            .addCase(GetFolderTree.rejected, (slice) => {
                slice.loadings.isLoadingTree = false;

            })
            .addCase(GetFolderTree.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingTree = false;
                let newFolders = [...payload]
                    .map(e => e.name === 'Trash' ? ({...e, id: 'trash'}) : e);
                const sortMap = sortRootFolders(newFolders);
                newFolders = newFolders.sort((a, b) => sortFolders(a.id, b.id, sortMap));
                slice.documentsFolders = newFolders;
            })
        //createDoc
            .addCase(CreateNewDoc.pending, (slice) => {
                slice.loadings.isLoadingSaveDoc = true;
            })
            .addCase(CreateNewDoc.rejected, (slice) => {
                slice.loadings.isLoadingSaveDoc = false;

            })
            .addCase(CreateNewDoc.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingSaveDoc = false;
                slice.dialogs.actionTemplateDialog = initialState.dialogs.actionTemplateDialog;
                // slice.dialogs.actionTemplateDialog.docId = payload.id;
            })
            //GetFullDocumentWithExecutorForStaff
            .addCase(GetFullDocumentWithExecutor.pending, (slice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(GetFullDocumentWithExecutor.rejected, (slice) => {
                slice.loadings.isLoadingExact = false;
            })
            .addCase(GetFullDocumentWithExecutor.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingExact = false;
                if(payload.resp.file !== undefined && payload.resp.file !== null){
                    slice.selectedNewDocument = {
                        document: payload.resp.file,
                        documentExecutor: payload.resp.executor ?? null
                    }
                }
            })
            //handlePreviewBeforeSendAction
            .addCase(handlePreviewBeforeSendAction.pending, (slice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(handlePreviewBeforeSendAction.rejected, (slice) => {
                slice.loadings.isLoadingExact = false;
            })
            .addCase(handlePreviewBeforeSendAction.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingExact = false;
                console.log(payload)
                if(payload.file){
                    slice.selectedNewDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                }
            })
            //StartNewDocumentExecution
            .addCase(StartNewDocumentExecution.pending, (slice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(StartNewDocumentExecution.rejected, (slice) => {
                slice.loadings.isLoadingExact = false;
            })
            .addCase(StartNewDocumentExecution.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingExact = false;
                if(payload.file){
                    slice.selectedNewDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                }
            })
            //RestartSignStageByStageIdAndRecipient
            .addCase(RestartSignStageByStageIdAndRecipient.pending, (slice) => {
                slice.loadings.isLoadingRestartSignStageByStageIdAndRecipient = true;
            })
            .addCase(RestartSignStageByStageIdAndRecipient.rejected, (slice) => {
                slice.loadings.isLoadingRestartSignStageByStageIdAndRecipient = false;
            })
            .addCase(RestartSignStageByStageIdAndRecipient.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingRestartSignStageByStageIdAndRecipient = false;
                if(payload.file){
                    slice.selectedNewDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                }
            })
            //RestartSignStageByStageIdAndGroupId
            .addCase(RestartSignStageByStageIdAndGroupId.pending, (slice) => {
                slice.loadings.isLoadingRestartSignStageByStageIdAndGroupId = true;
            })
            .addCase(RestartSignStageByStageIdAndGroupId.rejected, (slice) => {
                slice.loadings.isLoadingRestartSignStageByStageIdAndGroupId = false;
            })
            .addCase(RestartSignStageByStageIdAndGroupId.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingRestartSignStageByStageIdAndGroupId = false;
                if(payload.file){
                    slice.selectedNewDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                }
            })
            //SendToNextStepByStageId
            .addCase(SendToNextStepByStageId.pending, (slice) => {
                slice.loadings.isLoadingSendToNextStep = true;
            })
            .addCase(SendToNextStepByStageId.rejected, (slice) => {
                slice.loadings.isLoadingSendToNextStep = false;
            })
            .addCase(SendToNextStepByStageId.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingSendToNextStep = false;
                if(payload.file){
                    slice.selectedNewDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                }
            })
            //InvalidateDocument
            .addCase(InvalidateDocument.pending, (slice) => {
                slice.loadings.isLoadingInvalidate = true;
            })
            .addCase(InvalidateDocument.rejected, (slice) => {
                slice.loadings.isLoadingInvalidate = false;
            })
            .addCase(InvalidateDocument.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingInvalidate = false;
                if(payload.file){
                    slice.selectedNewDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                }
            })
            //UpdateFullDocument
            .addCase(UpdateFullDocument.pending, (slice) => {
                slice.loadings.isLoadingSave = true;
            })
            .addCase(UpdateFullDocument.rejected, (slice) => {
                slice.loadings.isLoadingSave = false;
            })
            .addCase(UpdateFullDocument.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingSave = false;
                if(payload.file){
                    slice.selectedNewDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                }
            })
            .addCase(MainVicaCreateDelegatedTaskAction.pending, (slice) => {
                slice.loadings.isLoadingCreate = true;
            })
            .addCase(MainVicaCreateDelegatedTaskAction.rejected, (slice) => {
                slice.loadings.isLoadingCreate = false;
            })
            .addCase(MainVicaCreateDelegatedTaskAction.fulfilled, (slice) => {
                slice.loadings.isLoadingCreate = false;
                slice.dialogs.createDelegatedTask = initialState.dialogs.createDelegatedTask;
            })
    }
});

export const {
    openSelectTaskCollabs,
    hideSelectTaskCollabs,

    openSelectCategoryResponsible,
    hideSelectCategoryResponsible,

    openShowMoreFrameworkCards,
    hideShowMoreFrameworkCards,

    openShowMoreDesc,
    hideShowMoreDesc,

    openActionDialog,
    hideActionDialog,

    setFormCreateDoc,
    deselectDocument,
    handleReturnToDraftAction,

    openAddGroupMessage,
    hideAddGroupMessage,

    eraseCategories,
    setFrameworkColors,

    cleanUp,
    setCreateDelegatedTaskAction,
} = vicaTasksSlice.actions;

export const vicaTasksReducer = vicaTasksSlice.reducer;

const selectSelf = (state: AppState): tasksInitialState => state.vicaTasks as tasksInitialState;

export const dialogs = createSelector(selectSelf, state => state.dialogs);
export const loadings = createSelector(selectSelf, state => state.loadings);
export const categories = createSelector(selectSelf, state => state.categories);
export const categoriesPageInfo = createSelector(selectSelf, state => state.categoriesPageInfo);
export const frameworkColors = createSelector(selectSelf, state => state.frameworkColors);
export const collaborators = createSelector(selectSelf, state => state.collaborators);
export const employeesSelector = createSelector(selectSelf, state => state.employees);
export const selectedNewDocument = createSelector(selectSelf, state => state.selectedNewDocument);
export const selectedNewTemplateSelector = createSelector(selectSelf, state => state.selectedNewTemplate);
export const folders = createSelector(selectSelf, state => state.documentsFolders);



