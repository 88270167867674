import {useDispatch, useSelector} from "react-redux";
import {deleteControlDialog, hideDeleteControl, isLoading} from "../../../store/builder/slice";
import {DeleteControl} from "../../../store/builder/actions";
import {useParams} from "react-router-dom";
import {useHistory} from "react-router";
import {ROOT_BUILDER} from "../../../constants";


export const useDeleteControl = () => {
    const dispatch = useDispatch();
    const {frameworkId} = useParams<{frameworkId: string}>();
    const history = useHistory();
    const {isOpen, controlId} = useSelector(deleteControlDialog);
    // const _controls = useSelector(controls);
    const _isLoading = useSelector(isLoading);

    // const [currentControl, setCurrentControl] = useState<controlType | null>(null);
    // const [field, updateField] = useState<string>('');

    // useEffect(() => {
    //     updateField('');
    //     if(controlId){
    //         const currControl = _controls.find(e => e.id === controlId);
    //         if(currControl) setCurrentControl(currControl);
    //     }else{
    //         setCurrentControl(null);
    //     }
    //     //eslint-disable-next-line
    // }, [isOpen]);


    const handleClose = () => {
        dispatch(hideDeleteControl());
    }

    const handleConfirm = () => {
        controlId && dispatch(DeleteControl(controlId));
        frameworkId && history.push(`${ROOT_BUILDER}/framework/${frameworkId}`);
    }


    return {
        isOpen,
        isLoading: _isLoading,
        handleClose,
        handleConfirm,
    }



}