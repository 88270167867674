import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useControl} from "../../../hooks/useControl";
import {useFrameworks} from "../../../hooks/useFrameworks";
import {useFrameworksPathParams} from "../../../hooks/useFrameworksPathParams";
import {useFrameworksDialog} from "../../../hooks/useFrameworksDialog";
import {useEffect} from "react";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    PATH_LOCAL_COMPLIANCE_FRAMEWORKS,
    PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED
} from "../../../../../../newShared/constants";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {useDispatch} from "react-redux";
import {openCreateDelegatedTaskDialog} from "../../../store/control/store";

export const useControlViewPage = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'})
    const {isLessThen1050} = useMedia();
    const dispatch = useDispatch();

    const openCreateDelegatedTask = () => {
        dispatch(openCreateDelegatedTaskDialog());
    }

    const {
        control, framework, getData, clean,
        currentEvidence, currentAnalysis,

        goToEvidences, goToComments, goToDelegatedTasks,
        evidencesOpen, commentsOpen, analysisOpen, delegatedTasksOpen,
    } = useControl();

    const {openFramework} = useFrameworks();
    const {frId, controlId, evidenceId, analysisId, historyId} = useFrameworksPathParams();

    const {
        collaborators: {setCollaboratorsDialog}
    } = useFrameworksDialog();

    //Controller and first loadData
    const controller = new AbortController();
    useEffect(() => {
        frId && controlId && getData({frameworkId: frId, controlId}, controller.signal);
        return () => {
            clean()
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [frId, controlId]);

    useEffect(() => {
        !evidencesOpen && !commentsOpen && !analysisOpen && !delegatedTasksOpen && goToEvidences({}, true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evidencesOpen, commentsOpen, analysisOpen, delegatedTasksOpen])

    const tabs: {title: string, handleClick: () => void}[] = [
        {
            title: 'Evidences',
            handleClick: () => {goToEvidences({})}
        },
        {
            title: 'Comments',
            handleClick: () => {goToComments({})}
        },
        // {
        //     title: 'Analysis',
        //     handleClick: () => {goToAnalysis({})}
        // },
        {
            title: 'Delegated tasks',
            handleClick: () => {goToDelegatedTasks({})}
        },
    ]

    //Breadcrumbs
    useSetBreadcrumbs([
        {
            title: tMenu('Frameworks'),
            path: PATH_LOCAL_COMPLIANCE_FRAMEWORKS
        },
        {
            title: tMenu('Adopted frameworks'),
            path: PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED
        },
        {
            title: framework?.name || 'Loading framework...',
            path: `${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/${frId}`
        },
        {
            title: control?.name || 'Loading control...',
            path: `${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/${frId}/control/${controlId}`
        },
        {
            title: currentEvidence?.name || 'Loading evidence...',
            skip: !isLessThen1050 || !evidenceId,
        },
        {
            title: currentAnalysis?.auditName || 'Loading pipeline...',
            skip: !isLessThen1050 || !(analysisId && historyId),
        },
    ], isLessThen1050 ? 2 : undefined);

    return {
        evidenceId, analysisId, historyId, frId,
        evidencesOpen, commentsOpen, analysisOpen, delegatedTasksOpen,
        openFramework,
        control,
        setCollaboratorsDialog,
        tabs,
        openCreateDelegatedTask,
    }
}
