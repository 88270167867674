import {filterType, genericFilterInitialStateType} from "../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../redux/rootReducer";

export const initialState: genericFilterInitialStateType = {
    currentFilter: {
        search: '',
        originalSelectedValues: {},
        filters: {}
    },
    visibleFilters: []
}

export const genericFiltersSlice = createSlice({
    name: 'genericFilters',
    initialState,
    reducers: {
        handleSelectVisibleFilterAction: (slice: genericFilterInitialStateType, {payload}: { payload: filterType }) => {
            slice.visibleFilters = [payload, ...slice.visibleFilters];
        },
        handleDeselectVisibleFilterAction: (slice: genericFilterInitialStateType, {payload}: { payload: filterType }) => {
            slice.visibleFilters = slice.visibleFilters.filter(e => e.key !== payload.key);
            slice.currentFilter.filters[payload.key] = [];
            slice.currentFilter.originalSelectedValues[payload.key] = [];
        },
        handleReplaceFilters: (slice: genericFilterInitialStateType, {payload}: { payload: filterType[] }) => {
            slice.visibleFilters = payload;
        },
        handleSetSearch: (slice: genericFilterInitialStateType, {payload}: { payload: string }) => {
            slice.currentFilter.search = payload;
        },
        handleSetCurrentFiltersForFetch: (slice: genericFilterInitialStateType, {payload}: { payload: Record<string, string[]> }) => {
            slice.currentFilter.filters = payload;
        },
        handleSetOriginalFiltersByKey: (slice: genericFilterInitialStateType, {payload}: { payload: {key: string, values: any[]} }) => {
            slice.currentFilter.originalSelectedValues[payload.key] = payload.values;
        },
        handleReplaceStore: (slice: genericFilterInitialStateType, {payload}: {payload: genericFilterInitialStateType}) => {
            slice.currentFilter = payload.currentFilter;
            slice.visibleFilters = payload.visibleFilters;
        },
        erase: () => initialState,
    }
})

export const genericFiltersReducer = genericFiltersSlice.reducer;

export const {
    handleSelectVisibleFilterAction,
    handleDeselectVisibleFilterAction,
    handleReplaceFilters,
    handleSetSearch,
    handleSetCurrentFiltersForFetch,
    handleSetOriginalFiltersByKey,
    erase,
    handleReplaceStore
} = genericFiltersSlice.actions;

const selectSelf = (state: AppState):genericFilterInitialStateType => state.genericFilters as genericFilterInitialStateType;

export const visibleFiltersSelector = createSelector(selectSelf, state => state.visibleFilters);
export const currentFilterSelector = createSelector(selectSelf, state => state.currentFilter);


