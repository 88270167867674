import {Flex} from "../../../../../newShared/components/Layouts";
import {Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import React from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useAvailableApps} from "../../hooks/useAvailableApps";
import * as S from "../../components/styled";
import {AvailableAppCard} from "../../components/availableAppCard";
import {AppCardSkeleton} from "../../components/appCardSkeleton";
import {EmptyArrayImage} from "../../../../../newShared/components/EmptyArrayImage";
import {AddIntegrationGworkspaceDialog} from "../../components/integrationsManagement/addIntegrationDialogs/gworkspace";
import {AppInDevDialog} from "../../components/dialogs/appInDevDialog";
import {AddIntegrationGithubDialog} from "../../components/integrationsManagement/addIntegrationDialogs/github";
import {AddIntegrationConfluenceDialog} from "../../components/integrationsManagement/addIntegrationDialogs/confluence";
import {AddIntegrationJiraDialog} from "../../components/integrationsManagement/addIntegrationDialogs/jira";
import {
    AddIntegrationAwsSecurityDialog
} from "../../components/integrationsManagement/addIntegrationDialogs/aws_security";
import {AddIntegrationAzureAdDialog} from "../../components/integrationsManagement/addIntegrationDialogs/azuread";
import {AddIntegrationIntuneDialog} from "../../components/integrationsManagement/addIntegrationDialogs/intune";
import {AddIntegrationAwsConfigDialog} from "../../components/integrationsManagement/addIntegrationDialogs/aws_config";
import {
    AddIntegrationAwsCloudTrailDialog
} from "../../components/integrationsManagement/addIntegrationDialogs/aws_cloud_trail";
import {AddIntegrationAwsKMSDialog} from "../../components/integrationsManagement/addIntegrationDialogs/aws_kms";
import {AddIntegrationAwsIAMDialog} from "../../components/integrationsManagement/addIntegrationDialogs/aws_iam";
import {AddIntegrationAwsEC2Dialog} from "../../components/integrationsManagement/addIntegrationDialogs/aws_ec2";
import {AddIntegrationAwsRDSDialog} from "../../components/integrationsManagement/addIntegrationDialogs/aws_rds";
import {AddIntegrationBamboohrDialog} from "../../components/integrationsManagement/addIntegrationDialogs/bamboohr";
import {AddIntegrationZendeskDialog} from "../../components/integrationsManagement/addIntegrationDialogs/zendesk";
import {AddIntegrationOneloginDialog} from "../../components/integrationsManagement/addIntegrationDialogs/onelogin";
import {AddIntegrationCloudflareDialog} from "../../components/integrationsManagement/addIntegrationDialogs/cloudflare";
import {AddIntegrationAwsS3Dialog} from "../../components/integrationsManagement/addIntegrationDialogs/aws_s3";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {AddIntegrationSalesforceDialog} from "../../components/integrationsManagement/addIntegrationDialogs/salesforce";
import {GenericFiltersArray} from "../../../../../newShared/components/genericFilter";

export const AvailableAppsList = () => {
    const {
        apps,
        isLoading,
        actions,
        isNoResult,
        isNoApps,
        fetchByFilters
    } = useAvailableApps();
    const {t} = useMainTranslation('', {keyPrefix: 'pathIntegrations.AvailableAppsList'});

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('Available integrations')}
                actions={[]}
            />

            <GenericFiltersArray
                configs={[]}
                fetchResultWithSelectedFilters={fetchByFilters}
                isAddFilters={false}
            />

            {/*<S.FilterContainer>*/}
            {/*    <CustomSearchField*/}
            {/*        variant={'outlined'}*/}
            {/*        onChange={search.handleChange}*/}
            {/*        value={search.form.search}*/}
            {/*        onClear={search.handleCleanSearch}*/}
            {/*        disabled={isLoading}*/}
            {/*        onKeyDown={search.handleEnterKey}*/}
            {/*        size="small"*/}
            {/*        fullWidth*/}
            {/*        name={'search'}*/}
            {/*        label={t('Search')}*/}
            {/*        dir={'ltr'}*/}
            {/*        placeholder={t('Search')}*/}
            {/*    />*/}
            {/*</S.FilterContainer>*/}

            {isNoResult && <Typography color={colors.darkBlueText} sx={{margin: '8px 0 0 0'}}>{t('Nothing was found for your query. Try changing the query.')}</Typography>}

            <EmptyArrayImage
                type={'availableApps'}
                text={t('No available apps found.')}
                withoutAdd
                isShowing={isNoApps}
            />

            <S.IntegrationCardsContainer>
                {isLoading && <AppCardSkeleton />}
                {apps
                    .filter(e => e.status === 'available')
                    .map(e => <AvailableAppCard
                    key={e.id}
                    app={e}
                    onClick={actions.openAddIntegration}
                />)}
            </S.IntegrationCardsContainer>

            {/*DIALOGS*/}
            <AddIntegrationGworkspaceDialog />
            <AddIntegrationGithubDialog />
            <AddIntegrationConfluenceDialog />
            <AddIntegrationJiraDialog />
            <AddIntegrationSalesforceDialog />
            <AddIntegrationAzureAdDialog />
            <AddIntegrationIntuneDialog />
            <AddIntegrationBamboohrDialog />
            <AddIntegrationZendeskDialog />
            <AddIntegrationOneloginDialog />
            <AddIntegrationCloudflareDialog />

            <AddIntegrationAwsSecurityDialog />
            <AddIntegrationAwsConfigDialog />
            <AddIntegrationAwsCloudTrailDialog />
            <AddIntegrationAwsKMSDialog />
            <AddIntegrationAwsIAMDialog />
            <AddIntegrationAwsEC2Dialog />
            <AddIntegrationAwsS3Dialog />
            <AddIntegrationAwsRDSDialog />

            <AppInDevDialog />
        </Flex>
    )
}