import React, {FC} from "react";
import {
    Dialog,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {TableRowSkeleton} from "../../../../../../newShared/components/tableRowSkeleton";
import colors from "../../../../../../newShared/theme/colors";
import {useScrollPagination} from "../../../../../../newShared/hooks/useScrollPagination";
import {useFrameworksDialog} from "../../../hooks/useFrameworksDialog";
import {ignoreCells, strToPrettyName} from "../../../../reports/components/dialogs/collectorTableDialog";
import {useControl} from "../../../hooks/useControl";
//
// const toStr = (str: string | string[]): string => {
//     return Array.isArray(str) ? str.join(', ') : str
// }

export const FrameworkCollectorTableDialog: FC = () => {

    const {
        collectorView: {isOpen, isLoading, setCollectorViewDialog, assetId, keys, data, pageInfo: {total, page, count}},
    } = useFrameworksDialog();
    const {getCollectorData} = useControl();

    //Controller and first loadData
    const controller = new AbortController();

    //Pagination and filtering
    const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => {
            isOpen && assetId && getCollectorData(
                assetId, {
                    page, count,
                },
                controller.signal
            )
        },
        arrayLength: data.length || 0,
        isLoading: isLoading,
        pageInfo: {page, count, total},
        cleanData: () => {},
        controller: controller,
        // isOnTop: true,
        isNoMoreThanTotal: true,
        customDependencies: [isOpen],
        // is0PageAddictive: data?.id !== ids?.assetId
    })

    return (
        <Dialog open={isOpen} onClose={() => {setCollectorViewDialog({clear: true})}}
                PaperProps={{sx: {p: '10px 0', maxWidth: '90vw', minWidth: '90vw', maxHeight: '90vh'}}}>
            <DialogContent sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                {data && (
                    <TableContainer onScroll={onScroll} ref={scrollRef}>
                        <Table size={"small"} stickyHeader
                               sx={{
                                   '& .MuiTableCell-root': {
                                       borderRight: "1px solid rgba(224, 224, 224, 1)",
                                       maxWidth: `90vw`,
                                       wordWrap: 'break-word'
                                       // width: '90vw',
                                       // minWidth: '90vw'
                                   }}}
                        >
                            <TableHead>
                                <TableRow>
                                    {keys.filter(key => !ignoreCells.includes(key)).map(key => (
                                        <TableCell key={key} sx={{borderBottom: `2px solid ${colors.yellow}`}}>
                                            <Typography variant={'h4'}>{strToPrettyName(key)}</Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, index) => row && (
                                    <TableRow key={JSON.stringify(row)}>
                                        {keys.map(key => (
                                            <TableCell key={key + ' body'}
                                                       style={{
                                                           // minWidth: (toStr(row[key] || '').length > 30 ? Math.floor(toStr(row[key] || '').length * 16 / 8) : Math.floor(toStr(row[key] || '').length * 16)) + 'px',
                                                           // maxWidth: `90vw`,
                                                           // width: '90vw'
                                                       }}>
                                                {String(row[key]) || ''}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}

                                {(bottomLoader || isLoading) && <TableRowSkeleton columns={keys.length || 3}/>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </DialogContent>
        </Dialog>
    )
}