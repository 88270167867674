import {
    getColorByRiskRate,
    getInitialControlValue,
    getInitialGradeImpactValue,
    getInitialGradeProbabilityValue,
    getResidualGradeImpactValue,
    getResidualGradeProbabilityValue,
    getRiskRate
} from "../../helpers";
import {useSelector} from "react-redux";
import {loadings, risksQuantitative} from "../../store/slice";
import {RiskTableRowProps} from "../../types";

export const useRiskTableRow = ({risk, model, actions}: RiskTableRowProps) => {
    const riskRate = (risk && model) ? getRiskRate(risk.initialGrade.probability ?? 0, risk.initialGrade.impact ?? 0, model) : 'Unknown';
    const residualRiskRate = (risk && model) ? getRiskRate(risk.residualGrade.probability ?? 0, risk.residualGrade.impact ?? 0, model) : 'Unknown';

    return{
        riskRateColor: getColorByRiskRate(riskRate),
        residualRiskRateColor: getColorByRiskRate(residualRiskRate),

        initialGradeProbabilityValue: getInitialGradeProbabilityValue(risk, model),
        initialGradeImpactValue: getInitialGradeImpactValue(risk, model),

        riskTreatmentControlValue: getInitialControlValue(risk),

        residualGradeProbabilityValue: getResidualGradeProbabilityValue(risk, model),
        residualGradeImpactValue: getResidualGradeImpactValue(risk, model),
        modelsList: useSelector(loadings).modelsList,
        isQuantitative: useSelector(risksQuantitative),
        riskRate,
        residualRiskRate,
    }
}