import {AvatarGroup, styled, Typography} from "@mui/material";
import {CommonAvatar} from "../../../../Basic/CommonAvatar";
import colors from "../../../../../theme/colors";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

interface IDocumentAvatarGroupProps {
    revDir: boolean;
}
export const DocumentAvatarGroup = styled(AvatarGroup, {shouldForwardProp: (propName) => (
        !['revDir'].includes(propName.toString())
    )})<IDocumentAvatarGroupProps>(({revDir}) => ({
    justifyContent: 'flex-end',
    '& .MuiAvatar-root': {
        maxWidth: '24px',
        maxHeight: '24px',
        fontSize: '12px',
    },
    '& .MuiAvatar-root:hover': {
        cursor: 'pointer'
    },
    '& .MuiAvatar-root:last-child': {
        marginLeft: !revDir ? undefined : '-8px'
    },
}));

interface IAvatarsContainerProps {
    revDir: boolean;
}
export const AvatarsContainer = styled('div', {shouldForwardProp: (propName) => (
    !['revDir'].includes(propName.toString())
)})<IAvatarsContainerProps>(({revDir}) => ({
    // margin: revDir ? '0 64px 0 0' : '0 0 0 64px',
    display: 'flex',
    alignItems: "center",
    gap: '12px'
}))

export const DocumentAvatar = styled(CommonAvatar)(({index}: {index: number}) => ({
    backgroundColor: index === 0 ? colors.primary.blue
        : index === 1 ? colors.decorative.lilac
            : index === 2 ? colors.decorative.orange
                : index === 3 ? colors.decorative.violet
                    : 'transparent',
}));
DocumentAvatar.defaultProps = {
    size: "d32",
}

export const EditorsText = styled(Typography)({
    fontSize: '14px',
    fontWeight: 400,
    color: colors.text.grey_dark
})

export const InviteButtonContainer = styled('div')({
    padding: '3px 9px',
    gap: '6px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '12px',
    backgroundColor: colors.backgrounds.grey_light,
    cursor: 'pointer'
})

export const ButtonTitle = styled(Typography)({
    fontSize: '12px',
    fontWeight: 600,
    color: colors.text.grey_dark
})


export const ButtonIcon = styled(ManageAccountsIcon)({
    color: colors.text.grey_dark
})