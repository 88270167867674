import {MainVicaCategoryLocalModel} from "../../types";
import {loadings, openSelectCategorySupervisor, toggleVicaStep} from "../../store/slice";
import {useDispatch, useSelector} from "react-redux";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {mainVicaGetCategoryStepsWithFilterAction} from "../../store/actions";

export const useVicaCategory = ({category}: {category: MainVicaCategoryLocalModel}) => {
    const dispatch = useDispatch();
    const {taskList} = useSelector(loadings);
    const {currentFiltersForFetch} = useGenericFiltersStorage();
    const isLoading = taskList.includes(category.name);

    const handleToggleCategory = () => {
        dispatch(toggleVicaStep({category: category.name, isOpen: !category.isOpen}));
        if(!isLoading && !category.fetchedNoResult && !category.isOpen && !category.stepsLocal.length) { //category just opened and there is no steps
            getVicaSteps();
        }
    }

    const getVicaSteps = () => {
        dispatch(mainVicaGetCategoryStepsWithFilterAction({
            data: {
                filters: currentFiltersForFetch,
                category: category.name
            },
            // onError: handleToggleCategory
        }))
    }

    if(category.isOpen && !category.stepsLocal.length && !isLoading && !category.fetchedNoResult){
        getVicaSteps(); //on filter reload - category will be opened initially - checking and fetching
    }

    const handleOpenSupervisorDialog = () => {
        dispatch(openSelectCategorySupervisor({category}));
    }

    return{
        handleToggleCategory,
        handleOpenSupervisorDialog,
        progress: Math.floor(category.progress.completedSteps / (category.progress.totalSteps / 100)),
        isLoading,
        fetchedNoResult: category.fetchedNoResult
    }
}