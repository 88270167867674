import React, {FC} from "react";
import {Flex} from "../../../../../newShared/components/Layouts";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";

//icons
//other
import {Button, MenuItem, Skeleton, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {CreateSettingRowDialog} from "../../components/Dialogs/CreateSettingRowDialog";
import {AddMenuItem} from "../../../../../newShared/components/Basic/AddMenuItem";
import {useSaasNew} from "../../hooks/useSaasAssetNew";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";


export const SaasAssetNew: FC = () => {
    const {isMobile} = useMedia();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});

    const {
        settings,
        form,
        handleChange,

        handleSave,
        handleGoBack,

        handleSetBillingCycle,
        handleChangeCustomField,

        isLoadingSettings,
        isLoadingCreateAsset,

        openCreateSettingRowDialog
    } = useSaasNew();


    return (
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('New Saas')}
                handleGoBack={handleGoBack}
                actions={[]}
            />
            {/*<Breadcrumbs/>*/}
            {/*<Flex ai={'center'}>*/}
            {/*    {!isMobile &&*/}
            {/*        <IconButton sx={{color: colors.text.blue, margin: !revDir ? `${!isLessThen1050 ? 11 : 4}px 12px ${!isLessThen1050 ? 4 : 2}px 0` : `${!isLessThen1050 ? 11 : 4}px 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}*/}
            {/*                    onClick={handleGoBack} size={isLessThen1050 ? 'small' : undefined}>*/}
            {/*            {!revDir ? <ArrowBack/> : <ArrowForward/>}*/}
            {/*        </IconButton>}*/}
            {/*    <Typography variant={isMobile ?  'h3' : "h1"}  sx={{color: colors.backgrounds.dark}}>{'New Saas'}</Typography>*/}
            {/*</Flex>*/}

            <Flex ai={'center'} m={'20px 0 0 0'}>

                <LoadingButton size={"large"} component={"label"}
                               variant={'contained'}
                               disabled={isLoadingCreateAsset || !form.name.trim().length}
                               onClick={handleSave} loadingPosition={isLoadingCreateAsset ? 'start' : undefined} loading={isLoadingCreateAsset}
                               startIcon={isLoadingCreateAsset ? <div style={{width: '10px', height: '10px', }}/> : undefined}
                               sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                >
                    {t('Save')}
                </LoadingButton>

                <Button disabled={isLoadingCreateAsset} variant={'outlined'} size={isMobile ? 'small' : 'medium'} onClick={handleGoBack} >
                    {t('Cancel')}
                </Button>
            </Flex>

            <Flex direction={"column"}  w={'100%'} overflowy={'auto'} m={'10px 0 0 0'} >
                {isLoadingSettings ?
                    <>
                        <Skeleton width={'300px'} height={'40px'} />
                        <Skeleton width={'300px'} height={'40px'} />
                        <Skeleton width={'300px'} height={'40px'} />
                        <Skeleton width={'300px'} height={'40px'} />
                        <Skeleton width={'300px'} height={'40px'} />
                        <Skeleton width={'300px'} height={'40px'} />
                        <Skeleton width={'300px'} height={'40px'} />

                    </>
                    :
                    <>
                        <TextField label={t('Name')} size={"small"}
                                   required
                                   sx={{marginTop: '6px', width: '320px', '& label': {top: '0 !important'}}}
                                   value={form.name} onChange={handleChange} name={'name'} />

                        <TextField label={t('Owner')} value={form.owner} size={'small'}
                                   sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                   onChange={handleChange} name={'owner'} />

                        <TextField label={('URL')} value={form.url} size={'small'}
                                   sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                   onChange={handleChange} name={'url'} />

                        {/*BillingCycle*/}
                        <TextField select fullWidth label={t('Billing cycle')} value={form.billingCycle} size={'small'}
                                   sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                   onChange={(e) => handleSetBillingCycle(e.target.value)}
                        >
                            <AddMenuItem text={t('Add new')} onClick={() => openCreateSettingRowDialog({isOpen: true, field: 'billingCycle', assetType: 'saas'})} />
                            { settings && Object.keys(settings.billingCycle) && Object.keys(settings.billingCycle).map((b) => <MenuItem key={b} value={b}>{b}</MenuItem>)}
                        </TextField>

                        <TextField label={t('Seats')} value={form.seeds} size={'small'}
                                   sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                   onChange={handleChange} name={'seeds'} />

                        <TextField label={t('Licenses')} value={form.licenses} size={'small'}
                                   sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                   onChange={handleChange} name={'licenses'} />

                        {/*<TextField label={'Integration'} value={form.integration} size={'small'}*/}
                        {/*           sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}*/}
                        {/*           onChange={handleChange} name={'integration'} />*/}

                        {(settings && Object.keys(settings.customFields).length > 0) &&
                            Object.keys(settings.customFields).map((e) =>
                                <TextField key={e} size={'small'} label={e} value={form.customFields[e] || ''} name={e}
                                           onChange={handleChangeCustomField} sx={{marginTop: '16px', width: '320px', '& label': {top: '0px !important'}}}
                                />
                            )}
                    </>
                }

            </Flex>

            <CreateSettingRowDialog onSuccess={handleSetBillingCycle}/>
        </Flex>
    )
}