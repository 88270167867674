import React, {Component, createRef, Fragment, MouseEventHandler, RefObject} from "react";
import {
    EditorBlockData,
    EditorBlockItemType,
    EditorComponentsMethods,
    FunctionsForToolBar,
    getToolBarProps,
    MapDispatchToPropsObject,
    TBlockCreatedByDropEventData,
    TBlockWithHtml,
    TChangeTypeOfBlock,
    TDeleteBlockEventData,
    TEditorBlockElementData,
    TEditorBlockItemType,
    TEditorImageElementData,
    TEditorListElementData,
    TEditorRowElementData,
    TEditorTableElementData,
    TEditorTextElementData,
    TResize,
    TSetForceHighlightedBlocksEventData,
    ValueOf
} from "../../types";
import {EditorItemRow} from "../editorItemRow";
import {EditorItemBlockStyled} from "./styled";
import {EditorDropZoneForBlockItem} from "../editorDropZoneForBlockItem";
import {ContentCopy, Delete, DragIndicatorOutlined} from "@mui/icons-material";
import {EditorItemColumn} from "../editorItemColumn";
import {ToolBarProps} from "../editorToolBar";
import {EditorPopperButtons, EditorPopperButtonsProps} from "../commonComponents/editorPopperButtons";
import {TextComponent} from "../TextComponent";
import {EditorItemTable} from "../editorItemTable";
import {
    changeOldIdToNewId,
    createEventBlockCreatedByDrop,
    createEventDeleteBlock,
    createEventSetActiveBlock,
    createEventUnfocusAllBlocks,
    isRefColumn,
    isRefImage,
    isRefList,
    isRefRow,
    isRefTable,
    isRefText
} from "../../helpers";
import {initialEditorToolBarState} from "../editorToolBar/constants";
import {EditorWorkZone} from "../editorWorkzone";
import {
    blockCreatedByDropEventName,
    deleteBlockEventName,
    setActiveBlockEventName,
    setForceHighlightedEventName,
    unfocusAllBlocksEventName
} from "../../constants";
import {EditorItemImage} from "../editorItemImage";
import {EditorItemList} from "../editorItemList";
import {TListType} from "../editorToolBar/types";
import {uuid} from "../../../../utils";

interface Props extends getToolBarProps {
    block: TEditorBlockElementData;

    onDelete: () => void;
    changeTypeOfBlock: (newBlock: TChangeTypeOfBlock) => void;
    addCopiedBlock?: (block: TEditorBlockElementData) => void;

    isNotOneInParent: boolean;

    parentRef: RefObject<EditorItemRow>["current"] | RefObject<EditorItemColumn>["current"];
    editorWorkZoneRef: RefObject<EditorWorkZone>["current"];
    isDisabledDuplicate?: boolean;
}
export interface BlockState {
    id: string;
    type: 'block';
    data: TEditorBlockElementData["data"];

    //for resizing
    resize: TResize,

    popper: {open: boolean, anchorEl: HTMLDivElement | null, buttons: EditorPopperButtonsProps["buttons"]},
    isForceHighlighted: boolean;

    wrapperRef: RefObject<HTMLDivElement>;
    refs: Record<string, RefObject<TextComponent> | RefObject<EditorItemTable> | RefObject<EditorItemImage> | RefObject<EditorItemList>>;
}

export class EditorItemBlock extends Component<Props, BlockState> implements EditorComponentsMethods<EditorBlockData> {
    constructor(props: EditorItemBlock["props"]) {
        super(props);

        const refs: BlockState["refs"] = {};
        props.block.data.content?.forEach(el => {
            refs[el.id] = createRef<TextComponent>();
        });

        this.state = {
            id: props.block.id,
            type: 'block',
            data: props.block.data,

            resize: {x: 0, px: 0, percent: 0},
            popper: {open: false, anchorEl: null, buttons: []},
            isForceHighlighted: false,

            wrapperRef: createRef<HTMLDivElement>(),
            refs,
        }
    }

    componentDidMount() {
        this.setState((prev) => ({...prev, popper: {...prev.popper, anchorEl: prev.wrapperRef.current}}));

        document.addEventListener(setForceHighlightedEventName, this.setForceHighlightedByEvent)
        document.addEventListener(unfocusAllBlocksEventName, this.setUnfocusByEvent)
        document.addEventListener(blockCreatedByDropEventName, this.setFocusByDropByEvent)
        document.addEventListener(setActiveBlockEventName, this.setFocusBySetActiveEvent)
        document.addEventListener(deleteBlockEventName, this.deleteByEvent)
        // if (!this.props.disabledFocusOnMount) this.setFocus();
    }

    componentWillUnmount() {
        document.removeEventListener(setForceHighlightedEventName, this.setForceHighlightedByEvent)
        document.removeEventListener(unfocusAllBlocksEventName, this.setUnfocusByEvent)
        document.removeEventListener(blockCreatedByDropEventName, this.setFocusByDropByEvent)
        document.removeEventListener(setActiveBlockEventName, this.setFocusBySetActiveEvent)
        document.removeEventListener(deleteBlockEventName, this.deleteByEvent)
    }

    deleteByEvent = (ev: Event) => {
        const event = ev as CustomEvent<TDeleteBlockEventData>;
        if (event.detail.id === this.state.id) this.props.onDelete();
    }

    setFocusByEvent = (fromEvent: string) => (ev: Event) => {
        const event = ev as CustomEvent<TBlockCreatedByDropEventData>;
        const currentIsCreatedId = event.detail.ids.includes(this.state.id);
        if (!this.props.readonly && currentIsCreatedId && !this.state.popper.open) this.setFocus()
        else if (!this.props.readonly && !currentIsCreatedId && this.state.popper.open) this.onClickAway(!!event?.detail.ids.length);
    }

    setFocusByDropByEvent = this.setFocusByEvent(blockCreatedByDropEventName);
    setFocusBySetActiveEvent = this.setFocusByEvent(setActiveBlockEventName);

    setUnfocusByEvent = (ev: Event) => {
        // const event = ev as CustomEvent<TSetForceHighlightedBlocksEventData>;
        // const isForceHighlighted = event.detail.ids.includes(this.state.id);
        this.onClickAway();
    }

    setForceHighlightedByEvent = (ev: Event) => {
        const event = ev as CustomEvent<TSetForceHighlightedBlocksEventData>;
        const isForceHighlighted = event.detail.ids.includes(this.state.id);
        if (isForceHighlighted !== this.state.isForceHighlighted) {
            this.setState((prev) => ({...prev, isForceHighlighted}))
        }
    }

    setActiveBlock: FunctionsForToolBar["setActiveBlock"] = (ids) => {
        // this.props.parentRef?.setActiveBlock([this.state.id, ...ids])
        setTimeout(() => {createEventSetActiveBlock({ids: [this.state.id]})}, 10)
    }

    setFocus = () => {
        // console.log(`EditorItemBlock setFocus`, !this.state.popper.open)
        if (!this.state.popper.open) {
            this.setPopper(true);
            this.state.refs[this.state.data.content[0].id].current?.setFocus();
            // this.setActiveBlock([])
        }
    }

    checkIsFocused: (id: string) => boolean = (id) => {
        return this.state.popper.open;
    }

    getFlexBasis = (): number => {
        const ref = this.state.wrapperRef.current;
        if (ref) {
            const flexBasis = window.getComputedStyle(ref, null).getPropertyValue("flex-basis");
            return Number(flexBasis?.replace('%', ''));
        }
        return 0;
    };
    setFlexBasis = (value: number) => {
        const ref = this.state.wrapperRef.current;
        if (ref) {
            ref.style.flexBasis = value + '%';
        }
    };

    onResizeParent = () => {
        this.state.data.content.forEach(e => {
            const ref = this.state.refs[e.id]?.current;
            if (isRefImage(ref) || isRefRow(ref) || isRefColumn(ref)) {
                ref.onResizeParent();
            }
        });
    };

    getData: EditorComponentsMethods<EditorBlockData>["getData"] = () => {
        const blockContentData = this.state.data.content[0]?.id;
        const blockContentRef = blockContentData ? this.state.refs[blockContentData]?.current : undefined;
        const oldContent = blockContentData && blockContentRef ? [
            blockContentRef.getData()
        ] : [];

        return {
            id: this.state.id,
            type: this.state.type,
            data: {width: this.getFlexBasis(), content: oldContent},
        }
    };

    addCopiedBlock = () => {
        const data = JSON.parse(JSON.stringify(this.getData()));
        const updatedData = changeOldIdToNewId<TEditorBlockElementData>(data);
        updatedData.data.width = 100;

        if (this.props.addCopiedBlock) {
            this.props.addCopiedBlock({...updatedData, type: "block"});
        } else {
            this.handleAddBlockTopBottom(1, EditorBlockItemType.COLUMN, {...updatedData, type: "block"});
        }
    };

    handleAddBlockTopBottom = (index: number, type: ValueOf<TEditorBlockItemType>, data?: TEditorBlockElementData) => {
        const oldBlockData = this.getData().data;
        const oldRow: TEditorRowElementData = {
            id: 'row' + uuid(),
            type: 'row',
            data: {
                content: [{
                    id: 'block' + uuid(),
                    type: 'block',
                    data: {...oldBlockData, width: 100},
                }]
            },
        };

        const newData = data ? changeOldIdToNewId(JSON.parse(JSON.stringify(data))) : undefined;

        const newBlock: TEditorBlockElementData = data ? {
            ...newData,
            data: {
                ...newData.data,
                width: 100,
            }
        } : {
            id: 'block' + uuid(),
            type: 'block',
            data: {
                width: 100,
                content: [{
                    id: type + uuid(),
                    type: type,
                    data: {

                    },
                }],
            },
        };
        const newRow: TEditorRowElementData = {
            id: 'row' + uuid(),
            type: 'row',
            data: {
                content: [newBlock]
            },
        };

        const newContent = [oldRow];
        newContent.splice(index, 0, newRow);

        this.props.changeTypeOfBlock({
            type: EditorBlockItemType.COLUMN,
            data: {
                width: this.getFlexBasis(),
                content: newContent,
            }
        });

        setTimeout(() => {
            createEventBlockCreatedByDrop({
                ids: [newBlock.id],
            });
            data && createEventDeleteBlock({id: data.id});
        }, 100);
    }

    setPopper = (open: boolean) => {
        if (open === this.state.popper.open) return;
        this.setState(prev => ({
            ...prev,
            popper: {
                ...prev.popper,
                open: open,
                // anchorEl: anchorEl,
                buttons: prev.popper.buttons
            },
        }));
    }
    setPopperButtons = (buttons: EditorPopperButtonsProps["buttons"]) => {
        this.setState(prev => ({
            ...prev,
            popper: {
                ...prev.popper,
                buttons: buttons,
            },
        }));
    }

    ///////ToolBar
    setToolBar: MapDispatchToPropsObject["setToolBar"] = (toolBar, ids) => {
        this.props.parentRef?.setToolBar(toolBar, [this.state.id, ...ids])
    }
    setTextByToolBar: ToolBarProps["toolBarHandler"] = (action, payload, ids) => {
        const nextId = ids[0];
        if (nextId) {
            const ref = this.state.refs[nextId]?.current;
            if (ref) {
                ref.setTextByToolBar(action, payload, ids.slice(1))
            }
        }
    }

    onClickAway = (withoutDefault: boolean = false) => {
        if (this.state.popper.open) {
            this.setPopper(false);

            this.state.data.content.forEach(el => {
                const ref = this.state.refs[el.id]?.current;
                if (isRefTable(ref) || isRefText(ref) || isRefImage(ref) || isRefList(ref)) {
                    ref.onClickAway()
                    // console.log(`onClickAway`)
                }
            });
            !withoutDefault && this.setToolBar(initialEditorToolBarState, []);
        }
    }

    onClickEditorItemBlockStyled: MouseEventHandler<HTMLDivElement> = (e) => {
        e.stopPropagation();
        if(!this.state.popper.open){
            setTimeout(() => {createEventSetActiveBlock({ids: [this.state.id]})}, 10)
        }
        this.setFocus();
        // this.setPopper(true)
    }

    handleDivideToBlocks = (data: TBlockWithHtml[], type: TListType) => {
        let firstBlock: TEditorBlockElementData | undefined;
        const listTextItems: TEditorTextElementData[] = [];
        let lastBlock: TEditorBlockElementData | undefined;
        // console.log(`handleDivideToBlocks data`, data)
        data.forEach((el, i, array) => {
            if (el.type === "block") {
                const block: TEditorBlockElementData = {
                    id: 'block' + uuid(),
                    type: 'block',
                    data: {
                        width: 100,
                        content: [{
                            id: 'text' + uuid(),
                            type: 'text',
                            data: {
                                innerHtml: el.html,
                            },
                        }],
                    },
                };
                if (i === 0) firstBlock = block;
                if (i !== 0) lastBlock = block;
            } else {
                const text: TEditorTextElementData = {
                    id: 'text' + uuid(),
                    type: 'text',
                    data: {
                        innerHtml: el.html,
                        alignText: 'left',
                        content: []
                    },
                };
                listTextItems.push(text);
            }
        });

        const listBlock: TEditorBlockElementData = {
            id: 'block' + uuid(),
            type: 'block',
            data: {
                width: 100,
                content: [{
                    id: 'list' + uuid(),
                    type: 'list',
                    data: {
                        content: listTextItems.map(el => ({
                            id: 'listElement' + uuid(),
                            type: 'listElement',
                            data: {
                                content: [],
                                textBlock: el,
                            }
                        })),
                        listStyleType: type.split('-')[2],
                        type: type.split('-')[1],
                    }
                }],
            },
        }
        const columnData: TEditorRowElementData[] = [];
        if (firstBlock) columnData.push({
            id: 'row' + uuid(), type: 'row',
            data: { content: [firstBlock] },
        });
        if (listBlock) columnData.push({
            id: 'row' + uuid(), type: 'row',
            data: { content: [listBlock] },
        });
        if (lastBlock) columnData.push({
            id: 'row' + uuid(), type: 'row',
            data: { content: [lastBlock] },
        });

        if (this.props.isNotOneInParent) {
            this.props.changeTypeOfBlock({type: EditorBlockItemType.COLUMN, data: {
                width: this.getFlexBasis(),
                content: columnData,
                }});
        } else {
            this.props.changeTypeOfBlock({type: 'rowArray', data: columnData.map(e => e.data)})
        }
    }

    changeToText = (innerHtml: string) => {
        this.setState(prev => {
            const id = 'text' + uuid();
            prev.data.content = [{
                id: id,
                type: 'text',
                data: {
                    innerHtml: innerHtml,
                },
            }];

            prev.refs[id] = React.createRef<TextComponent>();

            return prev
        })

        const childId = this.state.data.content[0].id;
        this.setToolBar(initialEditorToolBarState, childId ? [childId] : []);
        setTimeout(() => {
            createEventUnfocusAllBlocks({});
            setTimeout(() => {
                this.setFocus();
            }, 50)
        }, 50)
    }

    render() {

        return (
            <EditorItemBlockStyled ref={this.state.wrapperRef} readonly={this.props.readonly} id={this.state.id}
                                   isFocused={this.state.popper.open || undefined}
                                   isForceHighlighted={this.state.isForceHighlighted || undefined}
                                   width={this.state.data.width}
                                   onMouseDown={this.onClickEditorItemBlockStyled}
            >
                {this.props.isNotOneInParent && (
                    <EditorDropZoneForBlockItem orientation={"horizontal"}
                                                onDrop={(type, block) => this.handleAddBlockTopBottom(0, type, block as TEditorBlockElementData)}
                                                readonly={this.props.readonly}/>
                )}

                <div className={'EditorItemBlock'}>
                    <div className={'borderForEditorItemBlock'}/>

                    {this.state.data.content.map((el) => (
                        <Fragment key={el.id + 'fragment'}>

                            {el.type === EditorBlockItemType.TEXT && (
                                <TextComponent getToolBarState={this.props.getToolBarState}
                                               ref={this.state.refs[el.id] as RefObject<TextComponent>}
                                               block={el as TEditorTextElementData}
                                               parentRef={this}
                                               editorWorkZoneRef={this.props.editorWorkZoneRef}

                                               status={this.props.status}
                                               readonly={this.props.readonly}
                                               isLoading={this.props.isLoading}
                                />
                            )}

                            {el.type === EditorBlockItemType.TABLE && (
                                <EditorItemTable getToolBarState={this.props.getToolBarState}
                                                 ref={this.state.refs[el.id] as RefObject<EditorItemTable>}
                                                 block={el as TEditorTableElementData}
                                                 parentRef={this}
                                                 editorWorkZoneRef={this.props.editorWorkZoneRef}
                                                 setPopperButtons={this.setPopperButtons}

                                                 status={this.props.status}
                                                 readonly={this.props.readonly}
                                                 isLoading={this.props.isLoading}
                                />
                            )}

                            {el.type === EditorBlockItemType.IMAGE && (
                                <EditorItemImage getToolBarState={this.props.getToolBarState}
                                                 ref={this.state.refs[el.id] as RefObject<EditorItemImage>}
                                                 block={el as TEditorImageElementData}
                                                 parentRef={this}
                                                 editorWorkZoneRef={this.props.editorWorkZoneRef}
                                                 setPopperButtons={this.setPopperButtons}

                                                 status={this.props.status}
                                                 readonly={this.props.readonly}
                                                 isLoading={this.props.isLoading}
                                />
                            )}

                            {el.type === EditorBlockItemType.LIST && (
                                <EditorItemList  getToolBarState={this.props.getToolBarState}
                                                 ref={this.state.refs[el.id] as RefObject<EditorItemList>}
                                                 block={el as TEditorListElementData}
                                                 parentRef={this}
                                                 editorWorkZoneRef={this.props.editorWorkZoneRef}
                                                 setPopperButtons={this.setPopperButtons}

                                                 status={this.props.status}
                                                 readonly={this.props.readonly}
                                                 isLoading={this.props.isLoading}
                                />
                            )}

                        </Fragment>
                    ))}
                </div>

                {this.props.isNotOneInParent && (
                    <EditorDropZoneForBlockItem orientation={"horizontal"}
                                                onDrop={(type, block) => this.handleAddBlockTopBottom(1, type, block as TEditorBlockElementData)}
                                                readonly={this.props.readonly}/>
                )}

                {!this.props.readonly && (
                    <EditorPopperButtons popperId={this.state.id + ' popper'}
                                         open={this.state.popper.open} anchorEl={this.state.wrapperRef}
                                         buttons={[...this.state.popper.buttons, [
                                             ...(!this.props.isDisabledDuplicate ? [{title: 'Duplicate', icon: ContentCopy, onClick: this.addCopiedBlock}] : []),
                                             {title: 'Delete', icon: Delete, onClick: this.props.onDelete},
                                             {title: 'Drag and move', icon: DragIndicatorOutlined, onClick: () => {}, isDragAndMove: true},
                                         ]]}
                                         dragData={{
                                             getData: this.getData,
                                         }}/>
                )}
            </EditorItemBlockStyled>
        );
    }
}