import {gql} from "@apollo/react-hooks";
import {print} from "graphql";
import {VendorFragment, VendorSettingsFragment} from './fragments';
import {importCsvFragmentFields, performCsvFragment} from "../../../../newShared/graphQl/fragments";


export const downloadEvidenceFile = print(gql`
    query downloadEvidenceFile($fileId: String!, $workspaceId: String!, $fileName: String!) {
        downloadEvidenceFile (fileId: $fileId, fileName: $fileName, workspaceId: $workspaceId) {
            filename
            filetype
            file
        }
    }
`)

export const getVendors = print(gql`
    query getVendors($organizationId: String!, $workspaceId: String!, $pageInfo: PaginationInput!){
        getVendors(pageInfo: $pageInfo, organizationId: $organizationId, workspaceId: $workspaceId) {
            vendors {
                ...VendorFragment
            }
            pageInfo {
                page
                count
                total
                sort
                groupBy
            }
        }
    }
    ${VendorFragment}
`)

export const createVendor = print(gql`
    mutation createVendor($organizationId: String!, $workspaceId: String!, $employee: VendorInput!){
        createVendor(employee: $employee, organizationId: $organizationId, workspaceId: $workspaceId) {
            ...VendorFragment
        }
    }
    ${VendorFragment}
`)

export const updateVendor = print(gql`
    mutation updateVendor($organizationId: String!, $workspaceId: String!, $employee: VendorInput!){
        updateVendor(employee: $employee, organizationId: $organizationId, workspaceId: $workspaceId) {
            ...VendorFragment
        }
    }
    ${VendorFragment}
`)

export const deleteVendor = print(gql`
    mutation deleteVendor($organizationId: String!, $workspaceId: String!, $employeeId: String!){
        deleteVendor(employeeId: $employeeId, organizationId: $organizationId, workspaceId: $workspaceId) {
            message
        }
    }
`)

/// SETTINGS
export const getVendorsSettings = print(gql`
    query getVendorsSettings($organizationId: String!, $workspaceId: String!){
        getVendorsSettings(organizationId: $organizationId, workspaceId: $workspaceId,) {
            ...VendorSettingsFragment
        }
    }
    ${VendorSettingsFragment}
`)

export const createVendorsSettingRow = print(gql`
    mutation createVendorsSettingRow($organizationId: String!, $workspaceId: String!, $field: String!, $title: String!){
        createVendorsSettingRow(organizationId: $organizationId, workspaceId: $workspaceId, field: $field, title: $title) {
            ...VendorSettingsFragment
        }
    }
    ${VendorSettingsFragment}
`)

export const editVendorsSettingRow = print(gql`
    mutation editVendorsSettingRow($organizationId: String!, $workspaceId: String!, $field: String!, $title: String!, $oldTitle: String!){
        editVendorsSettingRow(organizationId: $organizationId, workspaceId: $workspaceId, field: $field, title: $title, oldTitle: $oldTitle) {
            ...VendorSettingsFragment
        }
    }
    ${VendorSettingsFragment}
`)

export const deleteVendorsSettingRow = print(gql`
    mutation deleteVendorsSettingRow($organizationId: String!, $workspaceId: String!, $field: String!, $title: String!, $oldTitle: String!){
        deleteVendorsSettingRow(organizationId: $organizationId, workspaceId: $workspaceId, field: $field, title: $title, oldTitle: $oldTitle) {
            ...VendorSettingsFragment
        }
    }
    ${VendorSettingsFragment}
`)

export const exportCsvVendors = print(gql`
    query exportCsvVendors($organizationId: String!, $workspaceId: String!, $fieldKeys: [String!]!, $isDownload: Boolean!){
        exportCsvVendors(workspaceId: $workspaceId, organizationId: $organizationId, fieldKeys: $fieldKeys, isDownload: $isDownload )
    }
`)

// export const importCsvVendors = print(gql`
//     mutation importCsvVendors($organizationId: String!, $workspaceId: String!, $file: String!){
//         importCsvVendors(workspaceId: $workspaceId, organizationId: $organizationId, file: $file) {
//             message
//         }
//     }
// `)
export const importCsvTemplateVendors = print(gql`
    query importCsvTemplateVendors($organizationId: String!, $workspaceId: String!){
        importCsvTemplateVendors(workspaceId: $workspaceId, organizationId: $organizationId)
    }
`)

export const addVendorFile = print(gql`
    mutation addVendorFile($organizationId: String!, $workspaceId: String!, $file: String!, $fileName: String!, $type: String!, $vendorId: String!){
        addVendorFile(workspaceId: $workspaceId, organizationId: $organizationId, file: $file, fileName: $fileName, type: $type, vendorId: $vendorId) {
            id
            name
            type
        }
    }
`)

export const deleteVendorFile = print(gql`
    mutation deleteVendorFile($organizationId: String!, $workspaceId: String!, $fileId: String!, $vendorId: String!){
        deleteVendorFile(workspaceId: $workspaceId, organizationId: $organizationId, fileId: $fileId, vendorId: $vendorId) {
            message
        }
    }
`)

export const releaseVendors = print(gql`
    mutation releaseVendors ($workspaceId: String!, $organizationId: String!, $frameworkId: String!, $controlId: String!, $evidenceId: String!, $reviewers: [ReleaseActorInput!]!, $readers: [ReleaseActorInput!]!, $signers: [ReleaseActorInput!]!){
        releaseVendors(workspaceId: $workspaceId, organizationId: $organizationId, frameworkId: $frameworkId, controlId: $controlId, evidenceId: $evidenceId, reviewers: $reviewers, readers: $readers, signers: $signers){
            message
        }
    }
`)

export const getCsvFieldsVendors = gql`
    mutation getCsvFieldsVendors($workspaceId: String!, $file: String!, $name: String!){
        getCsvFieldsVendors(workspaceId: $workspaceId, file: $file, name: $name) {
            fileId
            fields {
                ...importCsvFragmentFields
            }
        }
    }
    ${importCsvFragmentFields}
`

export const performCsvVendors = gql`
    mutation performCsvVendors($workspaceId: String!, $fileId: String!, $fields: [ImportCsvFieldInput!]!){
        performCsvVendors(workspaceId: $workspaceId, fileId: $fileId, fields: $fields) {
            ...performCsvFragment
        }
    }
    ${performCsvFragment}
`


