import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDenyConflict} from "../../../hooks/dialogHooks/useDenyConflict";
import {TDenyDialogTabs} from "../../../types";
import {LoadingButton, TabContext} from "@mui/lab";
import {SearchField} from "../../../../../../newShared/components/Basic/CommonInputs";
import {CollaboratorRowSkeleton} from "../collaboratorRowSkeleton";
import {CollaboratorRow} from "../collaboratorRow";

export const DenyConflictDialog = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        isOpen,
        tab, setTab,
        search, setSearch,
        filteredCollaborators,
        isLoadingData,
        isLoadingDeny,
        handleHideDenyConflict: handleClose,
        addedCollaborators,
        handleSave,
        handleCleanSearch,
        handleSetCollaborator,
        handleDeleteCollaborator,
        errorMessage,


        emails,
        handleDeleteEmail,
        handleBlur,
        handleKeyDown,
        handleChange,
        text
    } = useDenyConflict();


    return(
        <Dialog open={isOpen} onClose={handleClose} >
            <DialogTitle>{t('Send notification')}</DialogTitle>
            <DialogContent sx={{display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
                <Typography variant={'body2'}>{t('Select a collaborator from the list (use the search) or type email')}</Typography>

                <TabContext value={tab}>
                    <Tabs variant={'scrollable'} value={tab}
                          aria-label="basic tabs example"
                          onChange={(e, newValue: TDenyDialogTabs) => setTab(newValue)} >
                        <Tab disabled={isLoadingData || isLoadingDeny} label={("Add collaborators")} value={'collaborators'} />
                        <Tab disabled={isLoadingData || isLoadingDeny} label={("By email")} value={'emails'} sx={{paddingLeft: '0'}}/>
                    </Tabs>

                    {/*Add collaborators*/}
                    {tab === 'collaborators' &&
                        <Box display={'flex'} flexDirection={'column'} sx={{mt: '10px', flexGrow: 1, overflow: 'hidden'}}>
                            <SearchField
                                size={'small'}
                                variant={'outlined'}
                                placeholder={t('Enter email or name')}
                                disabled={isLoadingData || isLoadingDeny}
                                onClear={handleCleanSearch}
                                onChange={(e) => {setSearch(e.target.value)}}
                                value={search}
                            />

                            <Box display={'flex'} flexDirection={'column'} sx={{mt: '16px', overflow: 'auto'}}>
                                { isLoadingData ?
                                    <CollaboratorRowSkeleton />
                                    :
                                    filteredCollaborators.map((c) => (
                                        <CollaboratorRow key={c.publicId}
                                                         collaborator={c}
                                                         selectedCollaborators={addedCollaborators}
                                                         onDelete={() => handleDeleteCollaborator(c.publicId)}
                                                         onSelect={() =>handleSetCollaborator(c)} />
                                    ))

                                }

                            </Box>
                        </Box>

                    }

                    {/*By email*/}
                    {tab === 'emails' &&
                        <Box display={'flex'} flexDirection={'column'} >
                            <Typography sx={{mt: '11px'}} variant={'body2'}>{('Please type email. You can send the form to several recipients, enter their emails separated by commas.')}</Typography>

                            <TextField multiline fullWidth label={t('Email')}
                                       error={Boolean(errorMessage)}
                                       helperText={errorMessage}
                                       sx={{
                                           mt: '14px',
                                           '& > div.MuiInputBase-root' :{
                                               display: 'flex',
                                               flexWrap: 'wrap',
                                           },
                                           '& > label': {top: '0 !important'},
                                           '& textarea': {
                                               margin: '4px',

                                               minWidth: '30px',
                                               width: 'auto',
                                               flexGrow: 1,
                                               textOverflow: 'ellipsis',
                                               alignSelf: 'center',
                                           },
                                       }}
                                       InputProps={{
                                           startAdornment: emails.map(email => (
                                               <Chip
                                                   size={'small'}
                                                   sx={{margin: '4px', borderRadius: '10px', backgroundColor: '#F7F7F7', pl: !revDir ? 'undefined' : '6px'}}
                                                   key={email}
                                                   tabIndex={-1}
                                                   label={email}
                                                   onDelete={() => {handleDeleteEmail(email)}}
                                               />
                                           )),
                                       }}
                                       onBlur={handleBlur}
                                       onKeyDown={handleKeyDown}
                                       onChange={handleChange} value={text}/>
                        </Box>
                    }

                </TabContext>




            </DialogContent>
            <DialogActions>
                <Button sx={{textTransform: 'none'}} onClick={handleClose} size={'medium'} variant={'text'}>{t('Close')}</Button>
                <LoadingButton size={'medium'} variant={'contained'} sx={{textTransform: 'none'}}
                               disabled={isLoadingDeny || isLoadingData}
                               onClick={handleSave} loading={isLoadingDeny}
                               endIcon={isLoadingDeny ? <div style={{width: '10px', height: '10px', marginLeft: revDir ? '12px' : undefined, marginRight: !revDir ? '12px' : undefined }}/> : undefined}
                               loadingPosition={isLoadingDeny ? 'end' : undefined}
                >
                    {t('Send')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
