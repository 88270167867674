import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../../newShared/redux/rootReducer";
import {TControl, TFramework, TSetRemoveFrameworkDialog} from "../../types";
import {TDialog, TPageInfo} from "../../../../../newShared/types";
import {getControlsByFrameworkAction, getFrameworkZipAction} from "./actions";
import {removeFrameworkFromWorkspaceAction} from "../frameworks/actions";
import {handlePagination} from "../../../../../newShared/components/genericTable/helpers";


export type TCurrentFrameworkSlice = {
    framework: TFramework | null,

    controls: (TControl)[],
    pageInfo: TPageInfo,
    minLoadedPage: number,
    maxLoadedPage: number,
    isLoading: {
        isControlsLoading: boolean,
        isFrameworkZipDownloading: boolean,
    },

    dialogs: {
        removeFramework: TDialog & {
            id: string | null,
        },
    },
}

const initialState: TCurrentFrameworkSlice = {
    framework: null,

    controls: [],
    pageInfo: {page: 0, count: 50, total: 0},
    minLoadedPage: -1,
    maxLoadedPage: -1,
    isLoading: {
        isControlsLoading: false,
        isFrameworkZipDownloading: false,
    },

    dialogs: {
        removeFramework: {
            id: null,
            isOpen: false,
            isLoading: false,
        }
    },
}

const Slice = createSlice({
    name: 'currentFramework',
    initialState,
    reducers: {
        cleanAction(slice: TCurrentFrameworkSlice) {
            slice.framework = initialState.framework;
            slice.controls = initialState.controls;
            slice.pageInfo = initialState.pageInfo;
            slice.dialogs = initialState.dialogs;
        },
        cleanArrayAction(slice: TCurrentFrameworkSlice) {
            slice.controls = initialState.controls;
        },

        setRemoveFrameworkDialogAction(slice: TCurrentFrameworkSlice, {payload}: {payload: TSetRemoveFrameworkDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.removeFramework.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.removeFramework.isLoading = payload.isLoading;
            if (payload.id !== undefined) slice.dialogs.removeFramework.id = payload.id;

            if (payload.clear) slice.dialogs.removeFramework = initialState.dialogs.removeFramework;
        },
        replaceMinMaxLoadedPage(slice: TCurrentFrameworkSlice, {payload}: {payload: {minLoadedPage: number, maxLoadedPage: number}}) {
            slice.minLoadedPage = payload.minLoadedPage;
            slice.maxLoadedPage = payload.maxLoadedPage;
        },
    },
    extraReducers: builder => {
      builder
          .addCase(getControlsByFrameworkAction.pending, (slice: TCurrentFrameworkSlice, payload) => {
              slice.isLoading.isControlsLoading = true;
              if (slice.framework?.id !== payload.meta.arg.frameworkId) {
                  slice.controls = [];
              }
          })
          .addCase(getControlsByFrameworkAction.rejected, (slice: TCurrentFrameworkSlice, payload) => {
              slice.isLoading.isControlsLoading = false;
          })
          .addCase(getControlsByFrameworkAction.fulfilled, (slice: TCurrentFrameworkSlice, {payload}) => {
              slice.isLoading.isControlsLoading = false;
              slice.framework = payload.resp.framework;

              const {results, maxLoadedPage, minLoadedPage} = handlePagination<TControl>(
                  slice.controls,
                  slice.pageInfo,
                  payload.resp.controls,
                  payload.resp.pageInfo,
                  slice.minLoadedPage,
                  slice.maxLoadedPage,
                  'id'
              );

              // const {array, pageInfo} = setPaginationInStore<TControl>(
              //     slice.pageInfo,
              //     slice.controls,
              //     payload.resp.pageInfo,
              //     payload.resp.controls,
              //     payload.isNewOnTop
              // );
              slice.controls = results;
              slice.pageInfo = payload.resp.pageInfo;
              slice.minLoadedPage = minLoadedPage;
              slice.maxLoadedPage = maxLoadedPage;
          })

          .addCase(removeFrameworkFromWorkspaceAction.pending, (slice: TCurrentFrameworkSlice) => {
              slice.dialogs.removeFramework.isLoading = true;
          })
          .addCase(removeFrameworkFromWorkspaceAction.rejected, (slice: TCurrentFrameworkSlice) => {
              slice.dialogs.removeFramework.isLoading = false;
          })
          .addCase(removeFrameworkFromWorkspaceAction.fulfilled, (slice: TCurrentFrameworkSlice) => {
              slice.dialogs.removeFramework = initialState.dialogs.removeFramework;
          })

          .addCase(getFrameworkZipAction.pending, (slice: TCurrentFrameworkSlice, payload) => {
              slice.isLoading.isFrameworkZipDownloading = true;
          })
          .addCase(getFrameworkZipAction.rejected, (slice: TCurrentFrameworkSlice, payload) => {
              slice.isLoading.isFrameworkZipDownloading = false;
          })
          .addCase(getFrameworkZipAction.fulfilled, (slice: TCurrentFrameworkSlice, {payload}) => {
              slice.isLoading.isFrameworkZipDownloading = false;
          })
    }
});

export const currentFrameworkReducerReducer = Slice.reducer;

export const {
    cleanAction,
    cleanArrayAction,

    setRemoveFrameworkDialogAction,
    replaceMinMaxLoadedPage
} = Slice.actions;

const selectSelf = (state: AppState): TCurrentFrameworkSlice => state.currentFramework;

export const currentFrameworkSelector = createSelector(selectSelf, state => state.framework);
export const currentFrameworkControlsSelector = createSelector(selectSelf, state => state.controls);
export const currentFrameworkPageInfoSelector = createSelector(selectSelf, state => state.pageInfo);
export const currentFrameworkIsLoadingSelector = createSelector(selectSelf, state => state.isLoading);

export const currentFrameworkDialogsSelector = createSelector(selectSelf, state => state.dialogs);
export const controlsMinLoadedPage = createSelector(selectSelf, state => state.minLoadedPage);
export const controlsMaxLoadedPage = createSelector(selectSelf, state => state.maxLoadedPage);
