import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Skeleton, Typography} from "@mui/material";
import React, {FC, KeyboardEvent, useEffect, useState} from "react";
import {useFrameworksDialog} from "../../../hooks/useFrameworksDialog";
import {useControl} from "../../../hooks/useControl";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {CommonAvatar} from "../../../../../../newShared/components/Basic/CommonAvatar";
import colors from "../../../../../../newShared/theme/colors";

import {Add, Delete} from "@mui/icons-material";
import {SearchField} from "../../../../../../newShared/components/Basic/CommonInputs";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";


export const ControlAssignmentsDialog: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathFrameworks.Dialogs'});
    const {tCommon} = useMainTranslation();

    const {collaborators: {
        isLoading, isOpen, setCollaboratorsDialog, collaborators, isAddingIds, isDeletingIds
    }} = useFrameworksDialog();
    const {framework, control, getAssignments, removeAssignment, addAssignment} = useControl();
    const handleClose = () => {setCollaboratorsDialog({clear: true})};
    
    const [search, setSearch] = useState<string>('');
    useEffect(() => {
        isOpen && framework && control && getAssignments({frameworkId: framework.id, controlId: control.id, text: search});
        !isOpen && setSearch('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement> | {keyCode: number}) => {
        event.keyCode === 13 && framework && control &&
        getAssignments({frameworkId: framework.id, controlId: control.id, text: search})
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>

            <DialogTitle>{t('Add new collaborator')}</DialogTitle>

            <DialogContent>
                <Typography variant={"body2"}>{t('Select a collaborator from the list.')}</Typography>

                <SearchField
                    sx={{marginTop: '14px', maxWidth: '300px'}}
                    onClear={() => {
                        setSearch('')
                        framework && control && getAssignments({frameworkId: framework.id, controlId: control.id, text: ''})
                    }}
                    value={search} onChange={e => {setSearch(e.target.value)}}
                    placeholder={tCommon('Enter email or name')}
                    onKeyDown={handleKeyDown}
                />

                {collaborators.map(user => (
                    <Box key={user.id} display={"flex"} alignItems={"center"} p={'12px 0'} borderBottom={'1px solid ' + colors.divider}>
                        <CommonAvatar name={user.name} size={"d40"} sx={{margin: 'auto 16px'}}/>
                        <Box display={"flex"} flexGrow={1}
                             flexDirection={"column"} justifyContent={"space-around"} alignItems={"flex-start"} overflow={"hidden"}>
                            <Typography variant={"body2"} color={colors.text.dark}>{user.name}</Typography>
                            <Typography variant={"body2"} color={colors.text.grey_dark} noWrap maxWidth={'100%'}>{user.email}</Typography>
                        </Box>

                        {!user.added && (
                            <LoadingButton variant={"text"} startIcon={<Add/>} loading={isAddingIds.includes(user.id)}
                                           sx={{color: colors.text.blue}}
                                           onClick={() => {framework && control && addAssignment({frameworkId: framework.id, controlId: control.id, userId: user.id})}}>
                                {tCommon('Add')}
                            </LoadingButton>
                        )}

                        {user.added && (
                            <LoadingButton variant={"text"} startIcon={<Delete/>} loading={isDeletingIds.includes(user.added)}
                                           sx={{color: colors.text.grey}}
                                           onClick={() => {user.added && removeAssignment(user.added)}}>
                                {tCommon('Delete')}
                            </LoadingButton>
                        )}
                    </Box>
                ))}

                {isLoading && (
                    <>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                    </>
                )}
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={"text"}>
                    {tCommon('Cancel')}
                </Button>
            </DialogActions>

        </Dialog>
    )
}