import {addFilterComponentPropsType} from "../../types";
import {Button, Checkbox, Dialog, IconButton, InputAdornment, OutlinedInput, Typography} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {TransitionUp} from "../dialogTransaction";
import SearchIcon from "@mui/icons-material/Search";
import {MiniDialog} from "../miniDialog";
import React from "react";
import {useAddFilters} from "../../hooks/useAddFilters";
import {useMedia} from "../../../../hooks/useMedia";
import {Flex} from "../../../Layouts";
import colors from "../../../../theme/colors";
import {sortAddFiltersList} from "../../helpers";
import {useMainTranslation} from "../../../../hooks/useMainTranslationHooks/useMainTranslation";
import {AddFilterButton} from "../styled";

export function AddFiltersComponent(config: addFilterComponentPropsType){
    const {t} = useMainTranslation('', {keyPrefix: 'genericFilter'});
    const {isOpen, handleOpen, handleClose, anchorEl, id, search, options, isSelectedSomeOptions} = useAddFilters(config);
    const {isMobile} = useMedia(834);

    return(
        <AddFilterButton
            onClick={handleOpen}
            css={`
                background-color: ${isOpen ? colors.chip.pressed : colors.backgrounds.grey_light};
                color: ${isOpen ? colors.text.white : colors.grayText};
                & button svg{
                  fill: ${isOpen ? colors.text.white : colors.grayText};
                }
                ${!isOpen && `
                    &:hover{
                      background-color: ${colors.chip.hover}; 
                      & p, & button svg{
                        fill: ${colors.grayText} !important;
                        color: ${colors.grayText} !important;
                      }
                    }
                `}
            `}
        >
            <>
                <Flex>
                    <Typography variant={'body2'} noWrap>{t('Add filters')}</Typography>
                </Flex>
                <IconButton size={'small'} sx={{padding: '0'}}>
                    {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon/>}
                </IconButton>

                <Dialog
                    open={isOpen && isMobile}
                    onClose={handleClose}
                    fullScreen
                    TransitionComponent={TransitionUp}
                    sx={{'& .MuiPaper-root': {
                            borderRadius: '40px 40px 0 0',
                            height: '60vh',
                            bottom: 0,
                            position: 'absolute'
                        }}}
                >
                    <Flex direction={'column'} onClick={(e) => e.stopPropagation()} grow={'1'}>
                        <OutlinedInput
                            placeholder={t('Search')}
                            value={search.value}
                            onChange={search.onChange}
                            fullWidth
                            sx={{
                                margin: '16px auto 0',
                                padding: '0',
                                height: '40px',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                '& .MuiButtonBase-root': {
                                    padding: '13px 10px',
                                }
                            }}
                            inputProps={{
                                style: {
                                    width: '88%',
                                    padding: '16px 10px',
                                    height: '14px'
                                }}
                            }
                            size={'small'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <SearchIcon sx={{width: '16px', height: '16px', color: colors.text.grey_dark}}/>
                                </InputAdornment>
                            }
                        />

                        <Flex jc={'flex-end'} p={'0 16px'} style={{borderTop: `1px solid ${colors.stroke.grey}`}}>
                            <Button variant={'text'} size={'large'} sx={{padding: '8px 2px', minWidth: '0'}} onClick={config.handleReset} disabled={!isSelectedSomeOptions}>{'Reset'}</Button>
                        </Flex>

                        <Flex
                            w={'100%'}
                            p={'16px 20px'}
                            style={{borderTop: `1px solid ${colors.stroke.grey}`}}
                            grow={'1'}
                            overflowy={'auto'}
                            direction={'column'}
                        >
                            {options
                                .sort((a, b) => sortAddFiltersList(a, b, config.disabledChangeOptions.map(e => e.key)))
                                .map((e, id) => {
                                        const isSelected = config.selectedOptions.some(s => s.key === e.key);
                                        const isDisabled = config.disabledChangeOptions.some(d => d.key === e.key);
                                        return(
                                            <Flex key={e.key} w={'100%'} ai={'center'} gap={'19px'} m={(id > 0) ? '8px 0 0 0' : undefined}>
                                                <Checkbox
                                                    disabled={isDisabled}
                                                    sx={{width: '24px', height: '24px'}}
                                                    checked={isSelected}
                                                    onClick={() => isSelected ? config.handleDeselectOption(e) : config.handleSelectOption(e)}
                                                />
                                                {<Typography variant={'body2'} color={isDisabled ? colors.text.grey : colors.grayText} noWrap>{e.name}</Typography>}
                                            </Flex>
                                        )
                                    }
                                )}
                            {!options.length && <Typography variant={'body2'} color={colors.grayText} noWrap>{t('No results')}</Typography>}
                        </Flex>
                    </Flex>
                </Dialog>

                <MiniDialog isOpen={isOpen && !isMobile} onClose={handleClose} elAnchor={anchorEl} id={id}>
                    <OutlinedInput
                        placeholder={t('Search')}
                        value={search.value}
                        onChange={search.onChange}
                        sx={{
                            padding: '0',
                            height: '38px',
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                            '& .MuiButtonBase-root': {
                                padding: '13px 10px',
                            }
                        }}
                        inputProps={{
                            style: {
                                width: '82%',
                                padding: '10px 8px',
                                height: '14px'
                            }}
                        }
                        size={'small'}
                        endAdornment={
                            <InputAdornment position="end">
                                <SearchIcon sx={{width: '16px', height: '16px', color: colors.text.grey_dark}}/>
                            </InputAdornment>
                        }
                    />

                    <Flex
                        w={'100%'}
                        p={'7px 5px'}
                        style={{borderTop: `1px solid ${colors.stroke.grey}`}}
                        maxh={'294px'}
                        overflowy={'auto'}
                        direction={'column'}
                    >
                        {options
                            .sort((a, b) => sortAddFiltersList(a, b, config.selectedOptions.map(e => e.key)))
                            .map((e, id) => {
                            const isSelected = config.selectedOptions.some(s => s.key === e.key);
                            const isDisabled = config.disabledChangeOptions.some(d => d.key === e.key);
                                return(
                                    <Flex key={e.key} w={'100%'} ai={'center'} gap={'19px'} m={(id > 0) ? '8px 0 0 0' : undefined}>
                                        <Checkbox
                                            disabled={isDisabled}
                                            sx={{width: '24px', height: '24px'}}
                                            checked={isSelected}
                                            onClick={() => isSelected ? config.handleDeselectOption(e) : config.handleSelectOption(e)}
                                        />
                                        {<Typography variant={'body2'} color={isDisabled ? colors.text.grey : colors.grayText} noWrap>{e.name}</Typography>}
                                    </Flex>
                                )
                            }
                        )}
                        {!options.length && <Typography variant={'body2'} color={colors.grayText} noWrap>{t('No results')}</Typography>}
                    </Flex>

                    <Flex w={'100%'} p={'8px'} style={{borderTop: `1px solid ${colors.stroke.grey}`}} ai={'center'} jc={'space-between'}>
                        <Button variant={'text'} size={'large'} sx={{padding: '8px 2px', minWidth: '0'}} onClick={config.handleReset} disabled={!isSelectedSomeOptions}>{t('Reset')}</Button>
                    </Flex>
                </MiniDialog>
            </>
        </AddFilterButton>
    )
}
