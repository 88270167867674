import {
    archiveReportByUserApi,
    createReportApi,
    deleteReportApi,
    getDataForCreateReportSnapshotApi,
    getFrameworksIdNameLogoApi,
    getReportsApi
} from "../../api";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    ArchiveReportByUserMutationVariables,
    CreateReportMutationVariables,
    DeleteReportMutationVariables,
    GetReportsQueryVariables
} from "../../../../../newShared/GQLTypes";
import {AppState} from "../../../../../newShared/redux/rootReducer";
import {getActionsData} from "../../../../../newShared/redux";
import {TWithOptions} from "../../../../../newShared/types";

export const createReportAction = createAsyncThunk(
    'Reports/createReport',
    async (data: TWithOptions<CreateReportMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await createReportApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp,
        }
    },
);

export const getDataForCreateReportSnapshotAction = createAsyncThunk(
    'Reports/createReport/getData',
    async (data: TWithOptions<{frameworkId: string}>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await getDataForCreateReportSnapshotApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp,
        }
    },
);

export const getGeneratedReportsAction = createAsyncThunk(
    'Reports/getGeneratedReports',
    async (data: TWithOptions<GetReportsQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await getReportsApi({...data.data, pageInfo: {...data.data.pageInfo, filters: {...data.data.pageInfo.filters, archived: ['false']}},
            organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp,
            isNewOnTop: true
        }
    },
);

export const getArchivedReportsAction = createAsyncThunk(
    'Reports/getArchivedReports',
    async (data: TWithOptions<GetReportsQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await getReportsApi({...data.data, pageInfo: {...data.data.pageInfo, filters: {...data.data.pageInfo.filters, archived: ['true']}},
            organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp,
            isNewOnTop: true
        }
    },
);

export const archiveReportAction = createAsyncThunk(
    'Reports/archiveReport',
    async (data: TWithOptions<ArchiveReportByUserMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await archiveReportByUserApi({...data.data, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const deleteReportAction = createAsyncThunk(
    'Reports/deleteReport',
    async (data: TWithOptions<DeleteReportMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await deleteReportApi({...data.data, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const getFrameworksIdNameLogoAction = createAsyncThunk(
    'Reports/getFrameworksIdNameLogo',
    async (data: TWithOptions<{}>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await getFrameworksIdNameLogoApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

