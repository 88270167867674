import {FC} from "react";
import {useDeleteControl} from "../../../../hooks/dialogs/useDeleteControl";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {DeleteDialogButton} from "../../../../../../../newShared/components/deleteDialogButton";

export const DeleteControlDialog: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'builderPage.dialogs'});

    const {
        isOpen,
        isLoading,
        handleClose,
        handleConfirm,
    } = useDeleteControl()


    return (
        <DeleteDialogButton isOpen={isOpen} isLoading={isLoading}
                            title={t('Delete control')}
                            message={t('Any changes will be effects all frameworks that the control has attached. Effected on 2 frameworks.\n' +
                                'Do you want to delete anyway?')}
                            handleConfirm={handleConfirm}
                            handleClose={handleClose}
        />
    )
}
