import React, {ChangeEventHandler, FC} from "react";
import {useDrag, useDrop} from "react-dnd";
import {Box, Button, Checkbox, TableCell, TableRow, Typography} from "@mui/material";
import {DragIndicator} from "@mui/icons-material";
import {TKycCsvFieldWithAlias} from "../../../../../types";
import colors from "../../../../../../../../newShared/theme/colors";

interface Props {
    field: TKycCsvFieldWithAlias;
    isFile: boolean;

    isDisabled?: boolean;
    onDrop: (field: Props["field"]) => void;
    onChange: (field: Props["field"]) => void;
    onUnassign: () => void;
}
export const KycCsvDraggableRow: FC<Props> = ({field, onDrop, onChange, onUnassign, isDisabled: _isDisabled, isFile}) => {
    const isDisabled = _isDisabled || isFile;

    //Template drop
    const [{isOver}, drop] = useDrop(
        () => ({
            accept: [acceptItemType],
            drop: (item: TKycCsvFieldWithAlias) => {
                onDrop(item)
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            })
        }),
        []
    );

    const [, drag] = useDrag(() => ({
        type: acceptItemType,
        item: field satisfies TKycCsvFieldWithAlias,
        collect: (monitor) => {
            return {
                isDragging: !!monitor.isDragging(),
            }
        }
    }))

    const handleCheck: (fieldName: 'skip' | 'isKey') => ChangeEventHandler<HTMLInputElement> = (fieldName) => (event) => {
        onChange?.({...field, [fieldName]: event.target.checked})
    }

    const showUnassign = field.csvField !== '' && field.mappedField !== '' && !isDisabled;
    const showDrag = field.csvField !== '' && !isDisabled;

    return (
        <TableRow sx={{
            position: 'relative', backgroundColor: isOver ? colors.backgrounds.blue_light_1 : undefined,
            opacity: isFile ? 0.5 : undefined
        }}
                  id={field.mappedField === '' ? field.csvField.replaceAll(' ', '_') : undefined}
                  ref={!isDisabled && field.mappedField !== '' ? drop : undefined}>

            <TableCell>
                <Typography variant={'body2'}>{field.mappedField}</Typography>
            </TableCell>

            <TableCell ref={showDrag ? drag : undefined} sx={{
                '& svg': {opacity: showDrag ? 1 : 0},
                '& button': {opacity: showDrag ? 1 : 0},

                ':hover': {
                    '& svg': {color: colors.primary.blue},
                },
            }}>
                <Box sx={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                    {isFile && (
                        <Typography variant={'body2'} sx={{marginLeft: '6px'}} noWrap>
                            {'Assign to FILE not supported'}
                        </Typography>
                    )}

                    <DragIndicator/>
                    <Typography variant={'body2'} sx={{flexGrow: 1}}>{field.csvField}</Typography>
                    {showUnassign && (
                        <Button variant={'text'} onClick={onUnassign} disableRipple sx={{textTransform: 'none'}} size={"small"}>
                            {('Unassign')}
                        </Button>
                    )}
                </Box>
            </TableCell>

            <TableCell sx={{textAlign: 'center'}}>
                <Checkbox checked={field.mappedField === ''} disableRipple size={"small"} disabled/>
            </TableCell>

            <TableCell sx={{textAlign: 'center'}}>
                <Checkbox checked={!field.skip && field.isKey} disableRipple size={"small"} disabled={field.csvField === '' || !!field.skip}
                          onChange={handleCheck("isKey")}/>
            </TableCell>
        </TableRow>
    )
}

export const acceptItemType = 'CSVField';