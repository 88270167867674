import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {Fragment} from "react";
import {useRequestAssetError} from "../../../hooks/dialogs/useRequestAssetError";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useParams} from "react-router-dom";


export const RequestAssetErrorDialog = () => {
    const {tCommon} = useMainTranslation()
    const {isOpen, title, message, handleClose} = useRequestAssetError();
    const {id} = useParams<{id?: string}>();

    return (
        <Dialog open={isOpen} onClose={handleClose} >
            <DialogTitle>{title}</DialogTitle>

            <DialogContent>
                {message.map((message, index, array) => (
                    <Fragment key={index + message}>
                        {message}
                        {index < array.length - 1 && <br/>}
                    </Fragment>
                ))}
            </DialogContent>

            <DialogActions>
                <Button size={"small"} variant={"text"} onClick={handleClose}>
                    {id ? tCommon('Cancel') : tCommon('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
