import {useDispatch, useSelector} from "react-redux";
import {
    adoptedFrameworksSelector,
    cleanAction,
    cleanArrayAction,
    galleryFrameworksSelector,
    isLoadingFrameworksSelector,
    TFrameworkSlice
} from "../../store/frameworks/store";
import {useHistory} from "react-router";
import {
    PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED,
    PATH_LOCAL_COMPLIANCE_REPORTS_NEW
} from "../../../../../newShared/constants";
import {PaginationInput} from "../../../../../newShared/GQLTypes";
import {
    adoptFrameworkToWorkspaceAction,
    getAdoptedFrameworksAction,
    getAvailableFrameworksAction
} from "../../store/frameworks/actions";
import {TypeRequestFilter} from "../../../../../newShared/utils";


type TResponse = {
    adopted: TFrameworkSlice["adopted"] & {
        getData: (filter: TypeRequestFilter, clean?: boolean) => void,
        cleanArray: () => void,
    },
    available: TFrameworkSlice["gallery"] & {
        getData: (pageInfo: PaginationInput, signal?: AbortSignal, clean?: boolean) => void,
        cleanArray: () => void,
    },
    isLoading: TFrameworkSlice["isLoading"],

    openFramework: (frId: string) => void,
    adoptFramework: (frId: string) => void,
    generateReport: (frId: string) => void,

    clearFrameworks: () => void,
}
export const useFrameworks = (): TResponse => {
    const dispatch = useDispatch();
    const history = useHistory();

    const adoptedFrameworks = useSelector(adoptedFrameworksSelector);
    const availableFrameworks = useSelector(galleryFrameworksSelector);
    const isLoading = useSelector(isLoadingFrameworksSelector);

    const loadAdopted: TResponse["adopted"]["getData"] = (filter, clean) => {
        clean && dispatch(cleanArrayAction('adopted'));
        dispatch(getAdoptedFrameworksAction({filter: filter?.input?.[0] || ''}));
    };

    const loadAvailable: TResponse["available"]["getData"] = (pageInfo, signal, clean) => {
        clean && dispatch(cleanArrayAction('gallery'));
        dispatch(getAvailableFrameworksAction({data: {pageInfo}, signal}))
    };
    const cleanArrayAvailable: TResponse["available"]["cleanArray"] = () => {dispatch(cleanArrayAction('gallery'))};
    const cleanArrayAdopted: TResponse["adopted"]["cleanArray"]  = () => {dispatch(cleanArrayAction('adopted'))};


    const openFramework: TResponse["openFramework"] = (frId) => {history.push(PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED + `/${frId}`)}
    const adoptFramework: TResponse["adoptFramework"] = (frameworkId) => {dispatch(adoptFrameworkToWorkspaceAction({frameworkId}))}

    const clearFrameworks: TResponse["clearFrameworks"] = () => {
        dispatch(cleanAction());
    };

    const generateReport: TResponse["generateReport"] = (frameworkId) => {
        history.push(PATH_LOCAL_COMPLIANCE_REPORTS_NEW + `?frameworkId=${frameworkId}`)
    }

    return {
        adopted: {
            ...adoptedFrameworks,
            getData: loadAdopted,
            cleanArray: cleanArrayAdopted,
        },

        available: {
            ...availableFrameworks,
            getData: loadAvailable,
            cleanArray: cleanArrayAvailable,
        },

        isLoading,

        openFramework,
        adoptFramework,
        generateReport,

        clearFrameworks,
    }
}