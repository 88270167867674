import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {downloadAsTXT, validatePassword} from "../../../../../../newShared/utils/text";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {SETTING_ROOT_PATH, SETTINGS_SECURITY_PATH} from "../../../constants";
import {
    ChangeCurrentUserPassword,
    disableGoogleAuthAction,
    reconfigureGoogleAuthAction,
    validate2faCodeAction
} from "../../../store/actions";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {qrCode, recoveryCodes, securityLoadings, securitySecret} from "../../../store/slice";
import {UseManageWorkspacesAndOrganizations} from "../../../../cookies/hooks/useManageWorkspacesAndOrganizations";

export const useSecurity = () => {
    const dispatch = useDispatch();
    const {user, isGoogleAuthConfigComplete, setGoogle2FaNotComplete} = UseManageWorkspacesAndOrganizations();
    const {setMessage} = useMessageDialog();
    const {isLoadingChangePassword} = useSelector(securityLoadings);
    const secret = useSelector(securitySecret);
    const _recoveryCodes = useSelector(recoveryCodes);

    const [tab, setTab] = useState<'password' | 'mfa'>('password');

    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    useSetBreadcrumbs([
        {
            title: tMenu('Settings'),
            path: SETTING_ROOT_PATH
        },
        {
            title: tMenu('Security'),
            path: SETTINGS_SECURITY_PATH
        }
    ]);

    //PASSWORD
    const passwordForm = useForm<{prevPassword: string, newPassword: string, confirmPassword: string}>({prevPassword: '', newPassword: '', confirmPassword: ''});
    const [isEditPassword, setIsEditPassword] = useState<boolean>(false);

    const isPasswordFormOk = () => {
        return passwordForm.form.prevPassword.trim().length > 0 &&
            validatePassword(passwordForm.form.newPassword) &&
            passwordForm.form.newPassword === passwordForm.form.confirmPassword &&
            passwordForm.form.prevPassword.trim().toLowerCase() !== passwordForm.form.newPassword.trim().toLowerCase()
    }

    const handleSaveChangePassword = () => {
        if (!validatePassword(passwordForm.form.prevPassword)) {
            setMessage({title: 'Previous password is not valid', message: [
                    'Password must be of minimum 8 characters length',
                    'Password must contain at least one Uppercase character',
                    'Password must contain at least one Lowercase character',
                    'Password must contain at least one Number character',
                    'Password must contain at least one Special case character',
                ]});
            return;
        }

        if (!validatePassword(passwordForm.form.newPassword)) {
            setMessage({title: 'New password is not valid', message: [
                    'Password must be of minimum 8 characters length',
                    'Password must contain at least one Uppercase character',
                    'Password must contain at least one Lowercase character',
                    'Password must contain at least one Number character',
                    'Password must contain at least one Special case character',
                ]});
            return;
        }

        if (passwordForm.form.newPassword !== passwordForm.form.confirmPassword) {
            setMessage({title: 'Confirm password field is not valid', message: 'Passwords should be equal'});
            return;
        }

        if (passwordForm.form.prevPassword === passwordForm.form.newPassword) {
            setMessage({title: 'New and old passwords are equal', message: 'New password should not be equal to previous.'});
            return;
        }

        if(isPasswordFormOk() && user){
            dispatch(ChangeCurrentUserPassword({
                data: {
                    oldPass: passwordForm.form.prevPassword,
                    newPass: passwordForm.form.newPassword,
                    publicId: user.publicId,
                },
                onSuccess: onSuccessPasswordChange
            }))
        }
    }

    const onSuccessPasswordChange = () => {
        setMessage({
            title: 'Completed Successfully!',
            message: 'Your password has been changed.'
        });
        cancelChangePassword(); //reset form and setIsEditPassword false
    }

    const cancelChangePassword = () => {
        setIsEditPassword(false);
        passwordForm.setForm({prevPassword: '', newPassword: '', confirmPassword: ''});
    }

    //MFA
    const [step, setStep] = useState<'startConfigure' | 'configuration' | 'recoveryCodes' | 'alreadyConfigured'>('startConfigure');

    useEffect(() => {
        isGoogleAuthConfigComplete && setStep('alreadyConfigured');
        //eslint-disable-next-line
    }, []);

    const disableGoogleMfa = () => {
        dispatch(disableGoogleAuthAction({
            onSuccess: () => {
                setGoogle2FaNotComplete(false); //updating cookies
                setStep('startConfigure');
            }
        }))
    }

    const startConfigure = () => {
        dispatch(reconfigureGoogleAuthAction({
            onSuccess: () => {
                setStep('configuration');
            }
        }))
    }

    const mfaConfigurationForm = useForm<{code: string}>({code: ''});

    const validateConfig = () => {
        mfaConfigurationForm.form.code.trim().length > 0 && secret && dispatch(validate2faCodeAction({
            code: mfaConfigurationForm.form.code,
            secret: secret,
            onSuccess: () => {
                setStep('recoveryCodes');
                setGoogle2FaNotComplete(true); //google 2fa complete; updating cookies
            }
        }))
    }

    const [showSecret, setShowSecret] = useState<boolean>(false);

    const doneWithRecoveryCodes = () => {
        setStep('alreadyConfigured');
    }

    const handleDownLoadRecoveryCodes = () => {
        downloadAsTXT('Regulait recovery codes.txt', _recoveryCodes.join(`\n`));
    }

    return{
        tab,
        setTab,
        passwordTab: {
            form: passwordForm.form,
            handleChange: passwordForm.handleChange,
            isOk: isPasswordFormOk(),
            isEditMode: isEditPassword,
            handleChangePassword: () => setIsEditPassword(true),
            cancelChangePassword,
            handleSave: handleSaveChangePassword,
            isLoading: isLoadingChangePassword
        },
        mfaTab: {
            step,
            setStep,
            disableGoogleMfa,
            startConfigure,
            code: mfaConfigurationForm.form.code,
            handleChange: mfaConfigurationForm.handleChange,
            secret: useSelector(securitySecret),
            qrCode: useSelector(qrCode),
            recoveryCodes: _recoveryCodes,
            loadings: useSelector(securityLoadings),
            validateConfig,
            showSecret,
            setShowSecret,
            doneWithRecoveryCodes,
            handleDownLoadRecoveryCodes
        }
    }
}