import React, {createRef, FC, MouseEvent, PropsWithChildren, ReactElement, useEffect, useState} from "react";
import {useHistory} from "react-router";
import {useLeftMenu} from "../../hooks/useLeftMenu";
import {Box, Divider, Drawer, List, Menu, MenuItem, Toolbar} from "@mui/material";
import {LeftMenuItem} from "../leftMenuItem";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {DropdownButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import colors from "../../../../../newShared/theme/colors";
import {Loader} from "../../../../../newShared/components/Spinner";

type TProps = {
    customChild?: ReactElement,
    customIsOpenOnLoad?: boolean,
    width?: number
}
export const LeftSecondMenu: FC<PropsWithChildren<TProps>> = ({children, customChild, customIsOpenOnLoad, width: customWidth = 308}) => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const history = useHistory();

    const {location: {pathname}} = useHistory();
    const {
        items: allItems, isOpen: isMainOpen,
        isOpenCustom, setOpenCustom,
        setSecondMenuWidth,
        checkItems: {checkedItems, isChecking}
    } = useLeftMenu();
    const parent = allItems.find(({path}) => pathname.startsWith(path));
    const child = parent?.child || []

    const {matches_1440Up, matches_1440Down} = useMedia();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleOpen = (event: MouseEvent<HTMLElement>) => {setAnchorEl(event.currentTarget);};
    const handleClose = () => {setAnchorEl(null)};

    const [isFirst, setIsFirst] = useState<boolean>(true);
    useEffect(() => {
        setOpenCustom(isFirst ? !!customIsOpenOnLoad : false);
        setIsFirst(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matches_1440Up, isMainOpen]);

    const paperRef = createRef<HTMLDivElement>();
    const [width, setWidth] = useState(customWidth);
    const handleClick = () => {
        if ((paperRef?.current?.offsetWidth || 0) >= (customWidth) - 1) {
            setWidth(paperRef!.current!.offsetWidth);

        }
    }

    useEffect(() => {
        // console.log(paperRef?.current?.offsetWidth)
        paperRef?.current?.offsetWidth && setWidth(paperRef?.current?.offsetWidth)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paperRef?.current?.offsetWidth]);


    useEffect(() => {
        setSecondMenuWidth(matches_1440Up ? paperRef!.current!.offsetWidth : 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matches_1440Up, width, pathname]);

    const disabled = parent?.failedCheckText ? !checkedItems?.includes(parent?.title) : undefined;
    const isCheckingItem = isChecking.includes(parent?.title || '');

    return (
        <Box display={"flex"} width={'100%'} height={'100%'} overflow={"hidden"}
             id={'leftSecondMenu'}
             {...!matches_1440Up ? {p: '14px 20px 0', flexDirection: "column", alignItems: "flex-start"} : {}}
        >
            {(matches_1440Up || customChild) && (
                <Drawer
                    onClickCapture={() => {setTimeout(handleClick, 1)}}
                    anchor={!revDir ? 'left' : 'right'}
                    sx={{
                        flexShrink: 0,
                        width: width,
                        [`& .MuiDrawer-paper`]: {
                            minWidth: customWidth,
                            borderColor: colors.stroke.grey,
                        },
                    }}

                    variant={(matches_1440Up || !customChild) ? "permanent" : "temporary"}
                    {...!(matches_1440Up || !customChild) ? {
                        open: isOpenCustom,
                        onClose: () => {setOpenCustom(false)},
                        SlideProps: {direction: revDir ? 'left' : 'right'},
                    } : {}}
                >
                    <Toolbar />
                    {
                        <Box overflow={!customChild ? 'auto' : 'hidden'} ref={paperRef} {...customChild ? {
                            display: 'flex',
                            flexDirection: 'column'
                        } : {}}>
                            <List>
                                {allItems
                                    .filter(e => pathname.startsWith(e.path))
                                    .map((item) => (
                                        <LeftMenuItem key={item.title + ' second title'} item={item} secondTitle/>
                                    ))}
                            </List>
                            <Divider variant={"middle"} color={colors.stroke.grey}/>
                            {!disabled && (!customChild ? (
                                <List>
                                    {child.map((item) => <LeftMenuItem key={item.title} item={item} second/>)}
                                </List>
                            ) : (
                                customChild
                            ))}
                        </Box>
                    }
                </Drawer>
            )}

            {!matches_1440Up && (
                <>
                    {parent && (
                        <DropdownButton open={Boolean(anchorEl)} disabled={disabled} hideArrow={disabled}
                                        onClick={!customChild ? handleOpen : () => {setOpenCustom(true)}}
                                        variant={"text"}
                                        size={matches_1440Down ? 'small' : undefined}
                                        disableTouchRipple disableRipple disableElevation disableFocusRipple
                                        icon={parent.icon ? <parent.icon/> : undefined}
                                        sx={{
                                            '&, *': {
                                                color: colors.text.blue + ' !important',
                                                fontWeight: 700 + ' !important',
                                                fontSize: '16px !important',
                                                lineHeight: '20px !important',
                                            },
                                            '& .svg-fill path, & .svg-fill circle, & .svg-fill rect': {
                                                fill: colors.text.blue,
                                            },
                                            '& .svg-stroke path, & .svg-stroke circle, & .svg-stroke rect': {
                                                stroke: colors.text.blue,
                                            },
                                            padding: '5px',
                                            marginBottom: '0px'
                                        }}
                        >
                            {t(parent.title)}
                        </DropdownButton>
                    )}
                    <Menu
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {child.map(item => (
                            <MenuItem key={item.title} onClick={() => {
                                handleClose();
                                history.push(item.path);
                            }}>
                                {t(item.title)}
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            )}

            <Box width={`calc(100% - ${matches_1440Up ? paperRef.current?.scrollWidth ?? width : 0}px)`} height={'100%'} overflow={"hidden"}
                 onClickCapture={matches_1440Up ? () => {setTimeout(handleClick, 1)} : undefined}
                 p={matches_1440Up ? '24px 20px 0' : '14px 0 0'}>
                {isCheckingItem && disabled ? (
                    <Box sx={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Loader/>
                    </Box>
                ) : children}
            </Box>
        </Box>
    )
}