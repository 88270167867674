import {useDispatch, useSelector} from "react-redux";
import {
    apps,
    appsMinMaxLoadedPage,
    cleanUpApps,
    loadings,
    openAddNewApp,
    pageInfo,
    replaceAppsMinMaxLoadedPage,
    setSelectedApp
} from "../../store/slice";
import {
    PATH_ACCESS_MANAGEMENT_APPS_EXACT,
    PATH_ACCESS_MANAGEMENT_APPS_LIST,
    PATH_LOCAL_ACCESS_MANAGEMENT
} from "../../constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {GetAccessManagementApps} from "../../store/actions";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {AccessManagementApp} from "../../types";
import {useHistory} from "react-router";
import {DEFAULT_GENERIC_TABLE_SEARCH} from "../../../../../newShared/components/genericTable/constants";

export const useAccessManagementAppsList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    const _apps = useSelector(apps);
    const {list} = useSelector(loadings);
    const _pageInfo = useSelector(pageInfo);

    // const controller = new AbortController();

    // useEffect(() => {
    //     dispatch(GetAccessManagementApps({data: {page: 0, count: DEFAULT_APPS_PAGING}, signal: controller.signal}));
    //
    //     return () => {
    //         controller.abort();
    //     }
    //     //eslint-disable-next-line
    // }, []);

    useSetBreadcrumbs([
        {
            title: tMenu('Access management'),
            path: PATH_LOCAL_ACCESS_MANAGEMENT
        },
        {
            title: tMenu('Applications'),
            path: PATH_ACCESS_MANAGEMENT_APPS_LIST
        }
    ]);

    // const handleLoadMore = (page: number) => {
    //     !list && dispatch(GetAccessManagementApps({data: {page: _pageInfo.page + 1, count: DEFAULT_APPS_PAGING}, signal: controller.signal}));
    // }

    const minMaxLoadedPage = useSelector(appsMinMaxLoadedPage);

    return{
        apps: _apps,
        actions: {
            handleOpenAddApp: () => dispatch(openAddNewApp()),
            // handleLoadMore
        },
        isLoading: list,
        isNoAppsFound: list ? false : !_apps.length,
        hasMore: _pageInfo.total > ((_pageInfo.page + 1) * _pageInfo.count),
        genericTable: {
            paging: {
                pageInfo: _pageInfo,
                isLoading: list,
                minLoadedPage: minMaxLoadedPage.minLoadedPage,
                maxLoadedPage: minMaxLoadedPage.maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceAppsMinMaxLoadedPage(data)),
                clearRows: () => dispatch(cleanUpApps()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    dispatch(GetAccessManagementApps({data: {page, count}}));
                },
            },
            onRowClick: (row: AccessManagementApp) => {
                dispatch(setSelectedApp(row));
                history.push({
                    pathname: PATH_ACCESS_MANAGEMENT_APPS_EXACT.replace(':id', row.id),
                    search: DEFAULT_GENERIC_TABLE_SEARCH
                });
            }
        }
    }
}