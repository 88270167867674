import {scanTypes, smallTarget, TScanHistory, vulnerabilityScannerInitialState} from "../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {
    CreateTarget,
    DeleteTarget,
    DownloadReport,
    GetShortTargetById,
    GetShortTargets,
    GetTargetScanHistory,
    ReleaseTargetScans,
    RunTarget,
    UpdateTarget
} from "./actions";
import {NMAP_SCAN_TYPE, OPENVAS_SCAN_TYPE, OWASP_SCAN_TYPE, SSL_SCAN_TYPE} from "../constants";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {minMaxLoadedPageDefault} from "../../../../newShared/components/genericTable/constants";
import {minMaxLoadedPageType} from "../../../../newShared/components/genericTable/types";
import {handlePagination} from "../../../../newShared/components/genericTable/helpers";

export const initialState: vulnerabilityScannerInitialState = {
    smallTargets: [],
    targetsMinMaxLoadedPage: minMaxLoadedPageDefault,
    selectedTarget: null,
    selectedScanType: null,
    pageInfo: {
        page: 0,
        count: 5,
        total: 0,
        sort: ''
    },
    histories: [],
    historyPageInfo: {
        page: 0,
        count: 5,
        total: 0,
        sort: ''
    },

    frameworks: [],
    controls: [],
    evidences: [],

    dialogs: {
        addTarget: {
            isOpen: false,
        },
        deleteTarget: {
            isOpen: false,
            targetId: null,
            targetName: null,
        },
        changeTarget: {
            isOpen: false,
            target: null,
        },
        releaseTarget: {
            isOpen: false,
        }
    },
    loadings: {
        isLoadingSmallTargetById: false,
        isLoadingAddTarget: false,
        isLoadingDeleteTarget: false,
        isLoadingRunTarget: false,
        isLoadingSmallTargets: false,
        isLoadingHistory: false,
        isLoadingUpdateTarget: false,
        isLoadingDownloadReport: {
            isLoading: false,
            scanId: null,
        },
        isLoadingReleaseTarget: false,
        isLoadingEvidences: false,
    }
}

export const VulnerabilityScannerSlice = createSlice({
    name: 'VulnerabilityScanner',
    initialState,
    reducers: {
        replaceTargetsMinMaxLoadedPage: (state, {payload}: {payload: minMaxLoadedPageType}) => {state.targetsMinMaxLoadedPage = payload},
        openAddTarget: (state) => {state.dialogs.addTarget.isOpen = true},
        hideAddTarget: (state) => {state.dialogs.addTarget.isOpen = false},

        openDeleteTarget: (state, {payload}: {payload: {targetId: string, targetName: string}}) => {state.dialogs.deleteTarget = {isOpen: true, ...payload}},
        hideDeleteTarget: (state) => {state.dialogs.deleteTarget = {isOpen: false, targetId: null, targetName: null}},

        openChangeTarget: (state, {payload}: {payload: smallTarget}) => {state.dialogs.changeTarget = {isOpen: true, target: payload}},
        hideChangeTarget: (state) => {state.dialogs.changeTarget = {isOpen: false, target: null}},

        openReleaseTarget: (state) => {state.dialogs.releaseTarget.isOpen = true},
        hideReleaseTarget: (state) => {state.dialogs.releaseTarget.isOpen = false},

        selectTarget: (state, {payload}: {payload: smallTarget}) => {state.selectedTarget = payload; state.selectedScanType = null},
        selectScanType: (state, {payload}: {payload: scanTypes | null}) => {
            state.selectedScanType = payload;
            if(!payload){
                state.histories = [];
                state.historyPageInfo.page = 0;
            }
        },
        startDownloadFile: (state, {payload}) => {state.loadings.isLoadingDownloadReport = {isLoading: true, scanId: payload}},

        vulnerabilityHotUpdateHistory(state, {payload}: { payload: TScanHistory }) {
            // console.log('vulnerabilityHotUpdateHistory fired');
            //left menu update
            state.smallTargets = state.smallTargets.map((e: smallTarget) => {
                if(e.id === payload.targetId){
                    // console.log('vulnerabilityHotUpdateHistory found smallTarget to update');
                    return {
                        ...e,
                        status: payload.activeScans.some(scan => scan.status === 'FAILED') ? 'FAILED' : 'COMPLETED',
                        statusChangedDate: payload.endScanDate,
                        lastRuns: {
                            owasp: payload.activeScans.find(s => s.scanType === OWASP_SCAN_TYPE)?.finishScanDate ?? e.lastRuns.owasp,
                            openVas: payload.activeScans.find(s => s.scanType === OPENVAS_SCAN_TYPE)?.finishScanDate ?? e.lastRuns.owasp,
                            TLS_SSL: payload.activeScans.find(s => s.scanType === SSL_SCAN_TYPE)?.finishScanDate ?? e.lastRuns.owasp,
                            tcpPorts: payload.activeScans.find(s => s.scanType === NMAP_SCAN_TYPE)?.finishScanDate ?? e.lastRuns.owasp
                        }
                    }
                }
                return e;
            });
            //update selected target
            if(state.selectedTarget){
                // console.log('vulnerabilityHotUpdateHistory found selectedTarget to update');
                state.selectedTarget = {
                    ...state.selectedTarget,
                    status: payload.activeScans.some(scan => scan.status === 'FAILED') ? 'FAILED' : 'COMPLETED',
                    statusChangedDate: payload.endScanDate,
                    lastRuns: {
                        owasp: payload.activeScans.find(s => s.scanType === OWASP_SCAN_TYPE)?.finishScanDate ?? state.selectedTarget.lastRuns.owasp,
                        openVas: payload.activeScans.find(s => s.scanType === OPENVAS_SCAN_TYPE)?.finishScanDate ?? state.selectedTarget.lastRuns.owasp,
                        TLS_SSL: payload.activeScans.find(s => s.scanType === SSL_SCAN_TYPE)?.finishScanDate ?? state.selectedTarget.lastRuns.owasp,
                        tcpPorts: payload.activeScans.find(s => s.scanType === NMAP_SCAN_TYPE)?.finishScanDate ?? state.selectedTarget.lastRuns.owasp
                    }
                }
                //update selected target histories
                //update pageInfo
                if(state.historyPageInfo.page === 0){
                    // console.log('vulnerabilityHotUpdateHistory page - adding new history and updating paging:total');
                    //if it is 0 page - adding the new history (if 0+ page, no add, because the result will appear on page 0 for new res)
                    state.histories.unshift(payload);
                    if(state.historyPageInfo.total >= state.historyPageInfo.count){
                        // console.log('vulnerabilityHotUpdateHistory page - deleting last history');
                        //if its 0 page and contains all (for example) 5 results that were requested - deleting last history - its goes to page 1
                        state.histories.pop();
                    }
                    //updating total to make paging available (there is one more result on page n+1)
                    // state.historyPageInfo.total = state.historyPageInfo.total + 1;
                }
            }
        },

        cleanTargets: (state) => {
            state.smallTargets = [];
            state.pageInfo = initialState.pageInfo;
        },
        cleanUp: () => initialState,
    },

    extraReducers: (builder) => {
        builder
            //GetShortTargets
            .addCase(GetShortTargets.pending, (slice) => {
                slice.loadings.isLoadingSmallTargets = true;
            })
            .addCase(GetShortTargets.rejected, (slice) => {
                slice.loadings.isLoadingSmallTargets = false;
            })
            .addCase(GetShortTargets.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingSmallTargets = false;
                // if(payload.pageInfo.page === 0){
                //     slice.smallTargets = payload.targets;
                // }else{
                //     if(slice.pageInfo.page < payload.pageInfo.page){
                //         slice.smallTargets = [...slice.smallTargets, ...payload.targets];
                //     }else{
                //         slice.smallTargets = [ ...payload.targets, ...slice.smallTargets];
                //     }
                // }
                // slice.pageInfo = payload.pageInfo;
                const {results, maxLoadedPage, minLoadedPage} = handlePagination<smallTarget>(
                    slice.smallTargets,
                    slice.pageInfo,
                    payload.targets,
                    payload.pageInfo,
                    slice.targetsMinMaxLoadedPage.minLoadedPage,
                    slice.targetsMinMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.smallTargets = results;
                slice.targetsMinMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.pageInfo = payload.pageInfo;
            })
            //GetShortTargetById
            .addCase(GetShortTargetById.pending, (slice) => {
                slice.loadings.isLoadingSmallTargetById = true;
            })
            .addCase(GetShortTargetById.rejected, (slice) => {
                slice.loadings.isLoadingSmallTargetById = false;
            })
            .addCase(GetShortTargetById.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingSmallTargetById = false;
                if(payload){
                    slice.selectedTarget = payload;
                }
            })
            //CreateTarget
            .addCase(CreateTarget.pending, (slice) => {
                slice.loadings.isLoadingAddTarget = true;
            })
            .addCase(CreateTarget.rejected, (slice) => {
                slice.loadings.isLoadingAddTarget = false;
            })
            .addCase(CreateTarget.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingAddTarget = false;
                // slice.smallTargets = [payload, ...slice.smallTargets];
                // slice.pageInfo = {...slice.pageInfo, total: slice.pageInfo.total + 1};
                slice.selectedTarget = payload;
                slice.selectedScanType = null;
                // slice.dialogs.addTarget.isOpen = false;
            })
            //UpdateTarget
            .addCase(UpdateTarget.pending, (slice) => {
                slice.loadings.isLoadingUpdateTarget = true;
            })
            .addCase(UpdateTarget.rejected, (slice) => {
                slice.loadings.isLoadingUpdateTarget = false;
            })
            .addCase(UpdateTarget.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingUpdateTarget = false;
                slice.smallTargets = slice.smallTargets.map(e => e.id === payload.id ? payload : e);
                if(slice.selectedTarget && slice.selectedTarget.id === payload.id){
                    slice.selectedTarget = payload;
                }
                slice.dialogs.changeTarget = {isOpen: false, target: null};
            })
            //RunTarget
            .addCase(RunTarget.pending, (slice) => {
                slice.loadings.isLoadingRunTarget = true;
            })
            .addCase(RunTarget.rejected, (slice) => {
                slice.loadings.isLoadingRunTarget = false;
            })
            .addCase(RunTarget.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingRunTarget = false;
                slice.smallTargets = slice.smallTargets.map(e => e.id === payload ?
                    {
                        ...e,
                        statusChangedDate: new Date(Date.now()).toISOString(),
                        status: 'QUEUED'
                    }
                    :
                    e
                );
                if(slice.selectedTarget && slice.selectedTarget.id === payload){
                    slice.selectedTarget = {
                        ...slice.selectedTarget,
                        statusChangedDate: new Date(Date.now()).toISOString(),
                        status: 'QUEUED'
                    }
                }
            })
            //DeleteTarget
            .addCase(DeleteTarget.pending, (slice) => {
                slice.loadings.isLoadingDeleteTarget = true;
            })
            .addCase(DeleteTarget.rejected, (slice) => {
                slice.loadings.isLoadingDeleteTarget = false;
            })
            .addCase(DeleteTarget.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingDeleteTarget = false;
                slice.smallTargets = slice.smallTargets.filter(e => e.id !== payload);
                if(slice.selectedTarget && slice.selectedTarget.id === payload){
                    slice.selectedTarget = null;
                }
                slice.dialogs.deleteTarget = {isOpen: false, targetId: null, targetName: null};
            })
            //GetTargetScanHistory
            .addCase(GetTargetScanHistory.pending, (slice) => {
                slice.loadings.isLoadingHistory = true;
            })
            .addCase(GetTargetScanHistory.rejected, (slice) => {
                slice.loadings.isLoadingHistory = false;
            })
            .addCase(GetTargetScanHistory.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingHistory = false;
                if(payload.pageInfo.page === 0){
                    slice.histories = payload.histories;
                }else{
                    if(slice.historyPageInfo.page < payload.pageInfo.page){
                        slice.histories = [...slice.histories, ...payload.histories];
                    }else{
                        slice.histories = [ ...payload.histories, ...slice.histories];
                    }
                }
                slice.historyPageInfo = payload.pageInfo;
            })
        //DownloadReport
            .addCase(DownloadReport.pending, (slice) => {
                //
            })
            .addCase(DownloadReport.rejected, (slice) => {
                slice.loadings.isLoadingDownloadReport = {isLoading: false, scanId: null};
            })
            .addCase(DownloadReport.fulfilled, (slice) => {
                slice.loadings.isLoadingDownloadReport = {isLoading: false, scanId: null};
            })
        //ReleaseTargetScans
            .addCase(ReleaseTargetScans.pending, (slice) => {
                slice.loadings.isLoadingReleaseTarget = true;
            })
            .addCase(ReleaseTargetScans.rejected, (slice) => {
                slice.loadings.isLoadingReleaseTarget = false;
            })
            .addCase(ReleaseTargetScans.fulfilled, (slice) => {
                slice.loadings.isLoadingReleaseTarget = false;
                slice.dialogs.releaseTarget.isOpen = false;
            })
    }
});

export const {
    openAddTarget,
    hideAddTarget,

    openDeleteTarget,
    hideDeleteTarget,

    openChangeTarget,
    hideChangeTarget,

    openReleaseTarget,
    hideReleaseTarget,

    selectTarget,
    selectScanType,
    startDownloadFile,
    vulnerabilityHotUpdateHistory,
    replaceTargetsMinMaxLoadedPage,
    cleanUp,
    cleanTargets
} = VulnerabilityScannerSlice.actions;

export const VulnerabilityScannerReducer = VulnerabilityScannerSlice.reducer;

const selectSelf = (state: AppState):vulnerabilityScannerInitialState => state.VulnerabilityScanner as vulnerabilityScannerInitialState;

export const smallTargets = createSelector(selectSelf, state => state.smallTargets);
export const pageInfo = createSelector(selectSelf, state => state.pageInfo);

export const selectedTarget = createSelector(selectSelf, state => state.selectedTarget);

export const histories = createSelector(selectSelf, state => state.histories);
export const historyPageInfo = createSelector(selectSelf, state => state.historyPageInfo);

export const dialogs = createSelector(selectSelf, state => state.dialogs);
export const loadings = createSelector(selectSelf, state => state.loadings);

export const selectedScanType = createSelector(selectSelf, state => state.selectedScanType);

export const frameworks = createSelector(selectSelf, state => state.frameworks);
export const controls = createSelector(selectSelf, state => state.controls);
export const evidences = createSelector(selectSelf, state => state.evidences);

export const targetsMinMaxLoadedPage = createSelector(selectSelf, state => state.targetsMinMaxLoadedPage);


