import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    cleanWorkspaces,
    createOrgInitialData,
    deselectWorkspace,
    openCreateWorkspace,
    openDeleteOrganization,
    openEditOrganization,
    openTransferOrganization,
    organizationsLoadings,
    replaceWorkspacesMinMaxLoadedPage,
    selectedOrganization,
    selectedOrganizationData,
    setSelectedWorkspace,
    workspaces,
    workspacesLoadings,
    workspacesMinMaxLoadedPage,
    workspacesPageInfo
} from "../../../store/slice";
import {addErrorSnack} from "../../../../../barsEnvironment/snack/store/slice";
import {
    ALL_PAYMENT_HISTORY,
    BUSINESS_PLAN,
    ENTERPRISE_PLAN,
    FAILED_PAYMENT_HISTORY,
    FREE_PLAN,
    HISTORY_FAILED_STATUS,
    HISTORY_SUCCESS_STATUS,
    ORGANIZATION_OWNER,
    SETTING_ROOT_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_BILLING_HISTORY_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_BILLING_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_USERS_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_PLANS_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_USERS_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH,
    SETTINGS_ORGANIZATIONS_PATH,
    SUCCESS_PAYMENT_HISTORY
} from "../../../constants";
import {
    GetCreateOrganizationData,
    GetOrganizationBilling,
    GetOrganizationById,
    GetWorkspaces,
    SendSupportMessageAPI,
} from "../../../store/actions";
import {useRouteMatch} from "react-router";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {countryJsonType, organizationTabs, TPaymentHistory, TSmallWorkspace} from "../../../types";
import {TBreadcrumb} from "../../../../../barsEnvironment/breadcrumbs/types";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {isExpired, parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {UseManageWorkspacesAndOrganizations} from "../../../../cookies/hooks/useManageWorkspacesAndOrganizations";
import {calculateNextPayment} from "../../../helpers";
import {DownloadReport} from "../../../../../components/vulnerabilityScanner/store/actions";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../../newShared/components/genericTable/types";
import {DEFAULT_GENERIC_TABLE_SEARCH} from "../../../../../../newShared/components/genericTable/constants";

export const useOrganizationExact = () => {
    const {currentLanguage} = useMainTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const id = useParams<{orgId: string}>().orgId;
    const {setMessage} = useMessageDialog();
    const {user} = UseManageWorkspacesAndOrganizations();
    //selectors
    const selectedOrg = useSelector(selectedOrganization);
    const _workspaces = useSelector(workspaces);
    const _workspacesPageInfo = useSelector(workspacesPageInfo);
    const {minLoadedPage, maxLoadedPage} = useSelector(workspacesMinMaxLoadedPage);
    const selectedOrgData = useSelector(selectedOrganizationData);
    const {list} = useSelector(workspacesLoadings);
    const {
        updateOrganizationByLicenseCode,
        sendUpdateSubRequest,
        getBilling,
    } = useSelector(organizationsLoadings);
    const {isLoadingInitialData, countries} = useSelector(createOrgInitialData);

    //ROUTES
    const isWorkspaceTab = useRouteMatch(SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH);
    const isPlansTab = useRouteMatch(SETTINGS_ORGANIZATIONS_EXACT_ORG_PLANS_PATH);
    // const isUsersTab = useRouteMatch(SETTINGS_ORGANIZATIONS_EXACT_ORG_USERS_PATH);
    const isBillingHistoryTab = useRouteMatch(SETTINGS_ORGANIZATIONS_EXACT_ORG_BILLING_HISTORY_PATH);
    const isBillingTab = useRouteMatch(SETTINGS_ORGANIZATIONS_EXACT_ORG_BILLING_PATH);
    // const isNotificationsTab = useRouteMatch(SETTINGS_ORGANIZATIONS_EXACT_ORG_NOTIFICATIONS_PATH);

    //bread
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const [customTabBreadCrumb, setCustomTabBreadCrumb] = useState<TBreadcrumb | null>(null);

    useSetBreadcrumbs([
        {
            title: tMenu('Settings'),
            path: SETTING_ROOT_PATH
        },
        {
            title: tMenu('Organizations'),
            path: SETTINGS_ORGANIZATIONS_PATH.replace(':orgId', id)
        },
        {
            title: (selectedOrg?.name ?? 'Loading...'),
            path: SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH.replace(':orgId', id)
        },
        ...(customTabBreadCrumb ? [customTabBreadCrumb] : [])
    ]);

    //fetch
    const onReject = (snack: string) => {
        dispatch(addErrorSnack(snack));
        history.push(SETTINGS_ORGANIZATIONS_PATH);
    }

    useEffect(() => {
        if(isPlansTab && selectedOrg){
            const currUser = selectedOrg.collaborators.find(e => e.publicId === user?.publicId ?? '');
            if(currUser?.role !== ORGANIZATION_OWNER){
                dispatch(addErrorSnack(`Access denied`))
                setTab('workspaces');
                setCustomTabBreadCrumb({
                    title: tMenu('Workspaces'),
                    path: SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH.replace(':orgId', id)
                });
                history.replace(SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH.replace(':orgId', id));

            }
        }
        //eslint-disable-next-line
    }, [selectedOrg]);

    useEffect(() => {
        dispatch(deselectWorkspace());
        // console.log(`useOrganizationExact:log after deselectWorkspace - - - !selectedOrg: ${!selectedOrg} - - - ${JSON.stringify(selectedOrg)}`);
        //fetch
        dispatch(GetOrganizationById({id, onReject: () => onReject(`Organization with id ${id} not found!`)}));
        //here countries we need for mapping fieldKeys to their labels + for select country in change billing info
        dispatch(GetCreateOrganizationData());
        // if(!selectedOrg){
        //     if(!id){
        //         onReject(`No valid id found.`);
        //     }else{
        //         console.log(`useOrganizationExact: id found - fetching GetOrganizationById: ${id}`);
        //         dispatch(GetOrganizationById({id, onReject: () => onReject(`Organization with id ${id} not found!`)}));
        //     }
        // }
        //tabs
        if(isWorkspaceTab){
            setTab('workspaces');
            setCustomTabBreadCrumb({
                title: tMenu('Workspaces'),
                path: SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH.replace(':orgId', id)
            });
            // getWorkspaces(0, DEFAULT_WORKSPACES_PAGING, '', false);
        }
        if(isPlansTab){
            setTab('plans');
            setCustomTabBreadCrumb({
                title: tMenu('Plans'),
                path: SETTINGS_ORGANIZATIONS_EXACT_ORG_PLANS_PATH.replace(':orgId', id)
            });
        }
        // if(isUsersTab){
        //     setTab('users');
        //     setCustomTabBreadCrumb({
        //         title: tMenu('Users'),
        //         path: SETTINGS_ORGANIZATIONS_EXACT_ORG_USERS_PATH.replace(':orgId', id)
        //     });
        //     // dispatch(GetOrganizationCollaborators({id}));
        // }
        if(isBillingTab){
            setTab('billing');
            setCustomTabBreadCrumb({
                title: tMenu('Billing'),
                path: SETTINGS_ORGANIZATIONS_EXACT_ORG_BILLING_PATH.replace(':orgId', id)
            });
            dispatch(GetOrganizationBilling({organizationId: id}));
        }
        if(isBillingHistoryTab){
            setTab('billingHistory');
            setCustomTabBreadCrumb({
                title: tMenu('Billing history'),
                path: SETTINGS_ORGANIZATIONS_EXACT_ORG_BILLING_HISTORY_PATH.replace(':orgId', id)
            });
            dispatch(GetOrganizationBilling({organizationId: id}));
        }
        //eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //     if(!getUsers && (selectedOrgData.collabs.length > 0 || selectedOrgData.invites.length > 0)){
    //         //after fetch set invites/collabs into local filtered array (local search)
    //         setFilteredInvites(selectedOrgData.invites);
    //         setFilteredCollabs(selectedOrgData.collabs);
    //     }
    //     //eslint-disable-next-line
    // }, [getUsers]);

    // useEffect(() => {
    //     //if role was changed or invite/collab deleted - rerender list
    //     //todo when delete user/new invite
    //     if(!changeInviteRole.isLoading || !changeCollabRole.isLoading || !cancelInvite.isLoading || !inviteUser || !deleteCollab){
    //         handleFilter();
    //     }
    //     //eslint-disable-next-line
    // }, [changeInviteRole.isLoading, changeCollabRole.isLoading, cancelInvite.isLoading, inviteUser, deleteCollab]);

    //tabs
    const [tab, setTab] = useState<organizationTabs>('workspaces');

    const handleSetTab = (actionTab: organizationTabs) => {
        switch (actionTab){
            case 'workspaces': {
                setTab('workspaces');
                setCustomTabBreadCrumb({
                    title: tMenu('Workspaces'),
                    path: SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH.replace(':orgId', id)
                });
                history.replace(SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH.replace(':orgId', id));
                // if(!_workspaces.length){
                //     getWorkspaces(0, DEFAULT_WORKSPACES_PAGING, '', false);
                // }
                return;
            }
            case 'users': {
                setTab('users');
                setCustomTabBreadCrumb({
                    title: tMenu('Users'),
                    path: SETTINGS_ORGANIZATIONS_EXACT_ORG_USERS_PATH.replace(':orgId', id)
                });
                history.replace(SETTINGS_ORGANIZATIONS_EXACT_ORG_USERS_PATH.replace(':orgId', id));
                // if(!selectedOrgData.collabs.length && !selectedOrgData.invites.length){
                //     //fetching only if there was no result at all
                //     dispatch(GetOrganizationCollaborators({id}));
                // }
                return;
            }
            case "plans": {
                setTab('plans');
                setCustomTabBreadCrumb({
                    title: tMenu('Plans'),
                    path: SETTINGS_ORGANIZATIONS_EXACT_ORG_PLANS_PATH.replace(':orgId', id)
                });
                history.replace(SETTINGS_ORGANIZATIONS_EXACT_ORG_PLANS_PATH.replace(':orgId', id));
                // selectedOrg && selectPlan(selectedOrg.subscription);
                return;
            }
            case "billing": {
                setTab('billing');
                setCustomTabBreadCrumb({
                    title: tMenu('Billing'),
                    path: SETTINGS_ORGANIZATIONS_EXACT_ORG_BILLING_PATH.replace(':orgId', id)
                });
                history.replace(SETTINGS_ORGANIZATIONS_EXACT_ORG_BILLING_PATH.replace(':orgId', id));
                dispatch(GetOrganizationBilling({organizationId: id}));
                return;
            }
            case "billingHistory":{
                setTab('billingHistory');
                setCustomTabBreadCrumb({
                    title: tMenu('Billing history'),
                    path: SETTINGS_ORGANIZATIONS_EXACT_ORG_BILLING_HISTORY_PATH.replace(':orgId', id)
                });
                history.replace(SETTINGS_ORGANIZATIONS_EXACT_ORG_BILLING_HISTORY_PATH.replace(':orgId', id));
                dispatch(GetOrganizationBilling({organizationId: id}));
                return;
            }
            default: {
                setTab(actionTab);
            }
        }
    }

    //ACTIONS
    const handleDeleteOrg = () => {
        dispatch(openDeleteOrganization());
    }

    const handleTransferOwnership = () => {
        //in dialog will be user selection
        dispatch(openTransferOrganization());
    }

    const handleEditOrganization = () => {
        //update name and logo in one dialog
        dispatch(openEditOrganization());
    }

    const handleCreateWorkspace = () => {
        dispatch(openCreateWorkspace());
    }

    const handleGoBack = () => {
        history.push(SETTINGS_ORGANIZATIONS_PATH);
    }

    //WORKSPACES
    // const getWorkspaces = (page: number, count: number, name: string, removeBeforeFetch: boolean) => {
    //     dispatch(GetWorkspaces({
    //         page,
    //         count,
    //         id,
    //         name,
    //         removeBeforeFetch
    //     }));
    // }

    //search
    const workspacesSearch = useForm<{search: string, prevSearch: string}>({search: '', prevSearch: ''});

    // const handleCleanSearch = () => {
    //     workspacesSearch.setForm({search: '', prevSearch: ''});
    //     getWorkspaces(0, DEFAULT_ORGANIZATIONS_PAGING, '', true);
    // }

    // const handleEnterKey = (e: any) => {
    //     if(e.keyCode === 13 && !workspacesSearch.form.search.trim().length){
    //         workspacesSearch.setForm({...workspacesSearch.form, prevSearch: ''});
    //         getWorkspaces(0, DEFAULT_ORGANIZATIONS_PAGING, '', true);
    //     }
    //
    //     if(e.keyCode === 13 && workspacesSearch.form.search.trim().length > 0){
    //         workspacesSearch.setForm({...workspacesSearch.form, prevSearch: workspacesSearch.form.search});
    //         getWorkspaces(0, DEFAULT_ORGANIZATIONS_PAGING, workspacesSearch.form.search, true);
    //     }
    // }

    // const handleLoadMore = (page: number) => {
    //     !list && getWorkspaces(_workspacesPageInfo.page + 1, DEFAULT_WORKSPACES_PAGING, workspacesSearch.form.search, false);
    // }

    const onWorkspaceClick = (workspace: TSmallWorkspace) => {
        dispatch(setSelectedWorkspace(workspace));
        history.push({
            pathname: SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_USERS_PATH.replace(':orgId', id).replace(':wsId', workspace.id),
            search: DEFAULT_GENERIC_TABLE_SEARCH
        });
    }

    //USERS
    // const usersForm = useForm<{search: string, prevSearch: string}>({search: '', prevSearch: ''});
    // const [filteredCollabs, setFilteredCollabs] = useState<TExtendedCollaborator[]>([]);
    // const [filteredInvites, setFilteredInvites] = useState<TOrganizationInvite[]>([]);

    //
    // const handleCleanSearchUsers = () => {
    //     usersForm.setForm({search: '', prevSearch: ''});
    //     setFilteredInvites(selectedOrgData.invites);
    //     setFilteredCollabs(selectedOrgData.collabs);
    // }
    //
    // const handleEnterKeyUsers = (e: any) => {
    //     if(e.keyCode === 13 && !usersForm.form.search.trim().length){
    //         usersForm.setForm({search: '', prevSearch: ''});
    //         setFilteredInvites(selectedOrgData.invites);
    //         setFilteredCollabs(selectedOrgData.collabs);
    //     }
    //
    //     if(e.keyCode === 13 && usersForm.form.search.trim().length > 0){
    //         usersForm.setForm({...usersForm.form, prevSearch: usersForm.form.search});
    //         handleFilter();
    //     }
    // }

    // useEffect(() => {
    //     handleFilter();
    //     //eslint-disable-next-line
    // }, [currentSearchForFetch]);

    // const handleFilter = () => {
    //     setFilteredInvites(selectedOrgData.invites.filter(e => e.emailAddress.trim().toLowerCase().startsWith(currentSearchForFetch.trim().toLowerCase())));
    //     setFilteredCollabs(selectedOrgData.collabs.filter(e => e.email.trim().toLowerCase().startsWith(currentSearchForFetch.trim().toLowerCase())));
    // }

    // const handleOpenAddUser = () => {
    //     dispatch(openInviteUser());
    // }

    //PLANS
    const [plan, selectPlan] = useState<string | null>(null);
    const licenseCodeForm = useForm<{licenseCode: string}>({licenseCode: ''});

    const handleSendChangePlanRequest = () => {
        dispatch(SendSupportMessageAPI({
            organizationId: id,
            currSub: selectedOrg?.subscription ?? 'UNKNOWN',
            subscription: plan ?? 'Plan was not selected',
            onSuccess: () => {
                setMessage({title: 'Request sent!', message: 'Regulait team will contact you soon!'});
            }
        }));
    }

    // const handleUseLicenseCode = () => {
    //     licenseCodeForm.form.licenseCode.trim().length > 0 && dispatch(UpdateOrganizationByLicenseCode({
    //         organizationId: id,
    //         licenseCode: licenseCodeForm.form.licenseCode,
    //         onSuccess: () => {
    //             setMessage({title: 'Completed successfully!', message: 'Your organization was updated!'});
    //         }
    //     }));
    // }

    //checks
    const isAvailableTransfer: boolean = Boolean(selectedOrg && user && selectedOrg.collaborators.find(e => e.publicId === user.publicId)?.role === ORGANIZATION_OWNER);
    // const isMember: boolean = Boolean(selectedOrg && user && selectedOrg.collaborators.find(e => e.publicId === user.publicId)?.role === ORGANIZATION_MEMBER);
    // const isAvailableTransferAndEdit: boolean = Boolean(selectedOrg && user && (selectedOrg.collaborators.find(e => e.publicId === user.publicId)?.role === ORGANIZATION_OWNER || selectedOrg.collaborators.find(e => e.publicId === user.publicId)?.role === ORGANIZATION_ADMIN ));
    const isAvailableTransferAndEdit: boolean = Boolean(selectedOrg && user && (selectedOrg.collaborators.find(e => e.publicId === user.publicId)?.role === ORGANIZATION_OWNER));

    const _isExpired: boolean = isExpired(selectedOrg?.subscriptionExpiry ?? '', currentLanguage.momentLocale);

    const getNoWorkspaceFoundReason = ():string => {
        if(selectedOrg && !selectedOrg.workspaceCount){
            if(_isExpired) return 'Organization is expired';
            if(selectedOrg.blocked) return 'Organization is blocked';
            if(isAvailableTransferAndEdit)  return 'Create new one here.'; //user is owner or admin
            return 'Ask the owner or admin of the organization to provide you with access.';
        }else{
            return 'Ask the owner or admin of the organization to provide you with access.';
        }
    }

    const [searchHistoryAutocompleteValue, setSearchHistoryAutocompleteValue] = useState<string>(ALL_PAYMENT_HISTORY);

    const handleSetHistoryAutocomplete = (value: string | null) => {
        if(!value) {
            setSearchHistoryAutocompleteValue(ALL_PAYMENT_HISTORY);
            return;
        }
        setSearchHistoryAutocompleteValue(value);
    }

    const getBillingHistories = () => {
        if(searchHistoryAutocompleteValue === ALL_PAYMENT_HISTORY){
            return selectedOrgData.billing?.paymentHistory ?? [];
        }
        if(searchHistoryAutocompleteValue === SUCCESS_PAYMENT_HISTORY){
            return (selectedOrgData.billing?.paymentHistory ?? []).filter(e => e.status === HISTORY_SUCCESS_STATUS);
        }
        if(searchHistoryAutocompleteValue === FAILED_PAYMENT_HISTORY){
            return (selectedOrgData.billing?.paymentHistory ?? []).filter(e => e.status === HISTORY_FAILED_STATUS);
        }
        return [];
    }

    const handleDownloadInvoice = (history: TPaymentHistory) => {
        if(history.invoiceFileId){
            dispatch(DownloadReport({
                fileId: history.invoiceFileId || '',
                name: `Regulait invoice from ${parseDateAuto(history.statusChangedDate)}.pdf`,
                scanId: ''
            }));
        }
    }

    const handleDownloadReceipt = (history: TPaymentHistory) => {
        if(history.receiptFileId){
            dispatch(DownloadReport({
                fileId: history.receiptFileId || '',
                name: `Regulait receipt from ${parseDateAuto(history.statusChangedDate)}.pdf`,
                scanId: ''
            }));
        }
    }

    const getCurrentCountry = (): countryJsonType | null => {
        if(!selectedOrgData.billing || !countries.length){
            return null;
        }else{
            if(selectedOrgData.billing){
                return countries.find(e => e.name === selectedOrgData.billing!.billingInfo.country) ?? null
            }
        }
        return null;
    }

    const [editBillingInfoDialog, setBillingInfoDialog] = useState<boolean>(false);

    // const handleUpdateRoleOfCollab = (row: TExtendedCollaborator, role: string) => {
    //     if(selectedOrg && !changeCollabRole.isLoading){
    //         dispatch(setPrevCollabRole({prevRole: row.role, publicId: row.publicId, newRole: role}));
    //         dispatch(UpdateCollabRole({organizationId: selectedOrg.id, publicId: row.publicId, newRole: role}));
    //     }
    // }

    // const handleDelete = (row: TExtendedCollaborator) => {
    //     dispatch(openDeleteCollaboratorDialog({collab: row}));
    // }

    // const handleUpdateRoleOfInvited = (invite: TOrganizationInvite, role: string) => {
    //     if(selectedOrg && !changeInviteRole.isLoading){
    //         dispatch(setPrevInviteRole({prevRole: invite.role, email: invite.emailAddress, newRole: role}));
    //         dispatch(UpdateInviteRole({
    //             organizationId: selectedOrg.id,
    //             email: invite.emailAddress,
    //             newRole: role,
    //             inviteId: invite.id
    //         }));
    //     }
    // }

    // const handleDeleteCollab = (row: TExtendedCollaborator) => {
    //     dispatch(openDeleteCollaboratorDialog({collab: row}));
    // }


    return{
        organization: selectedOrg,
        additionalData: selectedOrgData,
        tab,
        handleSetTab,
        actions: {
            handleDeleteOrg,
            handleTransferOwnership,
            handleEditOrganization,
            handleGoBack,
            handleCreateWorkspace
        },
        workspaces: {
            workspaces: _workspaces,
            pageInfo: _workspacesPageInfo,
            hasMore: _workspacesPageInfo.total > ((_workspacesPageInfo.page + 1) * _workspacesPageInfo.count),
            isLoading: list,
            // handleLoadMore,
            // handleEnterKey,
            // handleCleanSearch,
            search: workspacesSearch.form.search,
            handleChange: workspacesSearch.handleChange,
            onWorkspaceClick,
            isShowNoWorkspaces: !list && !workspacesSearch.form.prevSearch.length && !_workspaces.length,
            showNoResult: !list && workspacesSearch.form.prevSearch.length > 0 && !_workspaces.length
        },
        // users: {
        //     search: usersForm.form.search,
        //     handleChange: usersForm.handleChange,
        //     collabs: filteredCollabs,
        //     invites: filteredInvites,
        //     // handleCleanSearchUsers,
        //     // handleEnterKeyUsers,
        //     isLoading: getUsers,
        //     showNoResult: usersForm.form.prevSearch.trim().length > 0 && !filteredInvites.length && !filteredCollabs.length,
        //     handleFilter,
        //     handleOpenAddUser,
        //
        //     handleUpdateRoleOfCollab,
        //     handleDelete,
        //     changeCollabRole,
        //     handleUpdateRoleOfInvited,
        //     handleDeleteCollab,
        //     handleCancelInvite,
        //     handleResendInvite
        // },
        plans: {
            plan,
            selectPlan,
            selectedPlan: {
                selectedFree: plan === FREE_PLAN,
                selectedBusiness: plan === BUSINESS_PLAN,
                selectedEnterprise: plan === ENTERPRISE_PLAN
            },
            isOk: plan !== null && plan !== selectedOrg?.subscription,
            handleSendChangePlanRequest,
            licenseCode: licenseCodeForm.form.licenseCode,
            handleChange: licenseCodeForm.handleChange,
            isOkUseLicenseCode: licenseCodeForm.form.licenseCode.trim().length > 0,
            // handleUseLicenseCode,
            isLoadingSendRequest: sendUpdateSubRequest,
            isLoadingUpdateOrgByLicenseCode: updateOrganizationByLicenseCode,
        },
        billing: {
            billing: selectedOrgData.billing,
            nextPaymentOn: calculateNextPayment(selectedOrgData.billing, currentLanguage.momentLocale),
            histories: getBillingHistories(),
            searchHistoryAutocompleteValue,
            handleSetHistoryAutocomplete,
            isLoading: getBilling || isLoadingInitialData,
            handleDownloadReceipt,
            handleDownloadInvoice,
            currCountry:  getCurrentCountry(),
            handleOpenChangeBillingInfo: () => setBillingInfoDialog(true),
            editBillingInfoDialog: {
                isOpen: editBillingInfoDialog,
                handleClose: () => setBillingInfoDialog(false),
                isLoadingUpdate: false,
                handleSubmit: () => {
                    //todo
                }
            }
        },
        isAvailableTransfer,
        isAvailableTransferAndEdit,
        _isExpired,
        getNoWorkspaceFoundReason,
        // isMember,
        workspacesPaging: {
            pageInfo: _workspacesPageInfo,
            isLoading: list,
            minLoadedPage,
            maxLoadedPage,
            setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceWorkspacesMinMaxLoadedPage(data)),
            clearRows: () => dispatch(cleanWorkspaces()),
            fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                dispatch(GetWorkspaces({
                    page,
                    count,
                    id,
                    name: search,
                    removeBeforeFetch: false
                }));
            },
        }
    }
}
