import React, {FC} from "react";
import {Divider, Menu, Typography} from "@mui/material";
import {useTopBar} from "../../hooks/useTopBar";
import {CommonAvatar} from "../../../../../newShared/components/Basic/CommonAvatar";
import {getName} from "../../../../../newShared/utils/text";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {SHOW_CHANGE_LANGUAGE_IN_TOPBAR} from "../../../../../newShared/constants";
import {ChangeLanguageDialog} from "../../../changeLanguageDialog/hook";
import {LanguageChangeDialog} from "../../../changeLanguageDialog";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {AvatarMenuTitle} from "../avatarMenuTitle";
import {useHistory} from "react-router";
import {SETTINGS_LANGUAGE_PATH, SETTINGS_PROFILE_PATH} from "../../../../authWorkspacesCookies/settings/constants";
import {MenuItemHoverBlue} from "../styled";
import colors from "../../../../../newShared/theme/colors";

export const TopBarAvatar: FC = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'TopBar.user'});
    const history = useHistory();
    const {currData: {currentUser}, logout} = UseManageWorkspacesAndOrganizations();
    const {
        anchorAvatarEl, handleAvatarOpen, handleAvatarClose,
    } = useTopBar();
    const handleGoToProfile = () => {
        history.push(SETTINGS_PROFILE_PATH);
        handleAvatarClose();
    }
    const handleGoToLanguageChange = () => {
        history.push(SETTINGS_LANGUAGE_PATH);
        handleAvatarClose();
    }

    const {toggleVisibility, isOpen} = ChangeLanguageDialog();


    return currentUser ? (
        <>
            <CommonAvatar name={getName(currentUser.firstName, currentUser.lastName)}
                          onClick={handleAvatarOpen}
                          size={'d28'} style={{margin: !revDir ? '0 0 0 8px' : '0 8px 0 0', cursor: 'pointer'}}/>
            <Menu
                keepMounted
                anchorEl={anchorAvatarEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                elevation={1}
                sx={{top: '19px'}}
                MenuListProps={{sx: {py: '0'}}}
                open={Boolean(anchorAvatarEl)}
                onClose={handleAvatarClose}
            >
                <AvatarMenuTitle name={getName(currentUser.firstName, currentUser.lastName)} email={currentUser.email}>
                    <CommonAvatar name={getName(currentUser.firstName, currentUser.lastName)}
                                  size={'d28'} style={{cursor: 'default'}}/>
                </AvatarMenuTitle>
                <Divider sx={{margin: '0 !important'}}/>
                <MenuItemHoverBlue onClick={handleGoToProfile}>
                    <Typography color={colors.text.dark} variant={'*bodyText2'}>{t('Profile')}</Typography>
                </MenuItemHoverBlue>

                <MenuItemHoverBlue onClick={handleGoToLanguageChange}>
                    <Typography color={colors.text.dark} variant={'*bodyText2'}>{t('Language')}</Typography>
                </MenuItemHoverBlue>
                {SHOW_CHANGE_LANGUAGE_IN_TOPBAR &&
                    <MenuItemHoverBlue onClick={() => {
                        toggleVisibility(true);
                        handleAvatarClose();
                    }}>
                        <Typography color={colors.text.dark} variant={'*bodyText2'}>{t('Change language')}</Typography>
                    </MenuItemHoverBlue>
                }
                <Divider sx={{margin: '0 !important'}}/>
                <MenuItemHoverBlue onClick={() => {logout()}}>
                    <Typography color={colors.text.dark} variant={'*bodyText2'}>{t('Logout')}</Typography>
                </MenuItemHoverBlue>
            </Menu>

            <LanguageChangeDialog isOpen={isOpen} handleClose={toggleVisibility}/>
        </>
    ) : null
}