import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {loadings} from "../../store/store";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_ASSET_MANAGEMENT} from "../../../../../newShared/constants";
import {
    PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS,
    PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS_SETTINGS,
    PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS_SETTINGS_EXACT
} from "../../constants";
import {TVirtualAssetSettingsId} from "../../types";


export const useVirtualAssetSettings = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const history = useHistory();
    const {isLoadingSettings} = useSelector(loadings);

    useSetBreadcrumbs([
        {
            title: tMenu('Asset Management'),
            path: PATH_LOCAL_ASSET_MANAGEMENT
        },
        {
            title: 'Virtual assets',
            path: PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS
        },
        {
            title: 'Settings',
            path: PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS_SETTINGS
        },
    ]);

    const handleGoToSettingById = (id: TVirtualAssetSettingsId) => {
        history.push(PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS_SETTINGS_EXACT.replace(':id', id));
    }

    const handleGoBack = () => {
        history.push(PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS);
    }

    return {
        handleGoBack,
        isLoadingSettings,
        handleGoToSettingById,
    }

}
