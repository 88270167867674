import React, {FC} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField} from "@mui/material";
import {TManageActorsDialogNormalProps} from "../staff";
import {useManageActorsDialogAdmin} from "../../../../../hooks/useManageActorsDialogAdmin";
import {Delete} from "@mui/icons-material";
import {Flex} from "../../../../../../Layouts";

export const ManageActorsDialogAdmin: FC<TManageActorsDialogNormalProps> = (props) => {
    const {
        isOpen,
        form,
        handleAddRecipient,
        handleDeleteRecipient,
        handleSetRoleRecipient,
        handleClose,
        handleSave,
        isDisabledSave,
    } = useManageActorsDialogAdmin(props)

    return (
        <Dialog open={isOpen} sx={{
            '& .MuiDialog-paper': {
                minWidth: '400px',
                maxWidth: '400px',
                width: '400px',
            },
        }}>
            <DialogTitle sx={{fontWeight: 500, fontSize: '16px'}}>Manage recipient groups</DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', pt: '6px !important', gap: '12px'}}>
                {form.recipients.map((recipient, index) => (
                    <Flex key={recipient.id}>
                        <TextField value={recipient.role || ''} sx={{flexGrow: '1'}} size={"small"}
                                   onChange={e => handleSetRoleRecipient(recipient.id, e.target.value)}/>
                        <IconButton size={"small"} onClick={() => handleDeleteRecipient(recipient.id)}>
                            <Delete/>
                        </IconButton>
                    </Flex>
                ))}

                <Button variant={"contained"} onClick={handleAddRecipient} size={"small"}>Add recipient</Button>
            </DialogContent>

            <DialogActions>
                <Button variant={"text"} onClick={handleClose}>{'Cancel'}</Button>
                <Button variant={"contained"} onClick={handleSave} disabled={isDisabledSave}>{'Save'}</Button>
            </DialogActions>
        </Dialog>
    )
}