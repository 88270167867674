import React, {useState} from "react";
import {
    isLoading,
    openChangeNameDialog,
    openDeleteDialog,
    openMoveToDialog,
    setAsEvidenceDialogAction
} from "../../store/slice";
import {useAppDispatch} from "../../../../../newShared/redux";
import {DocumentFolder, TShortDocument} from "../../types";
import {DownloadFile, MakeDocDraftCopy} from "../../store/actions";
import {useSelector} from "react-redux";

export const useDocumentRow = () => {
    const dispatch = useAppDispatch();
    const {isLoadingMakeDraftCopy} = useSelector(isLoading);

    const handleOpenRenameDialog = (folder: DocumentFolder) => {
        dispatch(openChangeNameDialog({folder}));
        handleClose();
    }

    const handleOpenMoveToDialog = (data: {folder?: DocumentFolder, file?: TShortDocument}) => {
        dispatch(openMoveToDialog(data));
        handleClose();
    }

    const handleOpenDeleteDialog = (data: {folder?: DocumentFolder, file?: TShortDocument}) => {
        dispatch(openDeleteDialog(data));
        handleClose();
    }

    const handleDownload = (file: TShortDocument) => {
        if(file.type === 'DOCUMENT' && file.generatedFileId ){
            dispatch(DownloadFile({fileId: file.generatedFileId, name: `${file.name}.pdf`}));

        }else if(file.type === 'FILE' && file.documentId){
            dispatch(DownloadFile({fileId: file.documentId, name: file.name}));
        }
        handleClose();
    }

    const handleMakeDraftCopy = (file: TShortDocument) => {
        // console.log(`handleMakeDraftCopy`, file)
        file.documentId && dispatch(MakeDocDraftCopy(file.documentId));
        handleClose();
    }


    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSetAsEvidence = (documentId: string) => {
        dispatch(setAsEvidenceDialogAction({documentId}));
    }

    return{
        handleOpenRenameDialog,
        handleOpenMoveToDialog,
        handleOpenDeleteDialog,
        handleDownload,
        handleMakeDraftCopy,
        handleSetAsEvidence,

        menu: {
            anchorEl,
            handleClose,
            handleClick,
            open,
            isLoadingMakeDraftCopy
        },
    }
}
