import {useRegistriesNew} from "../../../hooks/useRegistriesNew";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {Button, TextField} from "@mui/material";
import React from "react";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {LoadingButton} from "@mui/lab";
import {AutoComplete, TextFieldCustom} from "../../../components/styled";
import {TRiskModel} from "../../../types";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";

export const RegistriesNew = () => {
    const {
        isLoading,
        handleGoBack,
        form,
        handleChange,
        models,
        isOk,
        handleSubmit,
        isLoadingModels,
        setSelectedModel,
        selectedModel
    } = useRegistriesNew();
    const {isMobile, isTablet} = useMedia();
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'risks'});

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('New registry')}
                handleGoBack={handleGoBack}
                actions={[]}
            />

            <Flex ai={'center'} m={'32px 0 5px 0'}>
                {isLoading ?
                    <LoadingButton
                        loading
                        sx={{paddingLeft: '35px', margin: !revDir ? '0 16px 0 0' : '0 0 0 16px', height: '36px'}}
                        variant="outlined"
                    >{t('Create')}</LoadingButton>
                    :
                    <Button
                        disabled={!isOk()}
                        variant={'contained'}
                        size={isMobile ? 'small' : 'medium'}
                        onClick={handleSubmit}
                        sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    >{t('Create')}</Button>
                }
                <Button disabled={isLoading} variant={'outlined'} size={isMobile ? 'small' : 'medium'} onClick={handleGoBack} >{t('Cancel')}</Button>
            </Flex>

            <TextFieldCustom
                required
                value={form.name}
                label={t('Name')}
                name={'name'}
                size={'small'}
                dir={'ltr'}
                onChange={handleChange}
                sx={{margin: '16px 0 0 0', width: isTablet ? '100%' : '30%'}}
            />

            <TextFieldCustom
                required
                value={form.context}
                label={t('Context')}
                name={'context'}
                dir={'ltr'}
                size={'small'}
                onChange={handleChange}
                sx={{margin: '16px 0 0 0', width: isTablet ? '100%' : '30%'}}
            />

            <AutoComplete
                disabled={isLoadingModels} //loading
                id="combo-box-demo"
                fullWidth
                options={models}
                dir={'ltr'}
                getOptionLabel={(option) => (option as TRiskModel).name}
                value={selectedModel ?? null}
                disableClearable
                onChange={(e, newValue) => setSelectedModel(newValue as TRiskModel | null)}
                sx={{margin: '16px 0 0 0', width: isTablet ? '100%' : '30%'}}
                renderInput={(params: any) => <TextField {...params} size="small" label={t("Model")} required/>}
            />
        </Flex>
    )
}