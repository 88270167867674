import {print} from "graphql";
import {gql} from "@apollo/react-hooks";

export const AssetFragment = print(gql`
    fragment AssetFragment on PhysicalAssetModel {
        id
        assetId
        serial
        model 
        category 
        manufacture 
        added
        assigned
        assignedTo {
            publicId
            email
            firstName
            lastName
        }
        returned
        status {
            name
            status
        }
        customFields 
        statuses {
            date
            status {
                status
                name
            }
            action {
                name
                employee {
                    id
                    name
                }
            }
            actor {
                id
                name
            }
        }
    }
`)

export const PhysicalAssetSettingsFragment = print(gql`
    fragment PhysicalAssetSettingsFragment on PhysicalAssetSettingsModel {
        model {
            name
            count
        }
        category {
            name
            count
        }
        manufacture {
            name
            count
        }
        customFields 
        status {
            name
            status
            count
        }
    }
`)

export const VirtualAssetFragment = print(gql`
    fragment VirtualAssetFragment on VirtualAssetModel {
        id
        name
        type
        virtualMachineName
        virtualMachineIp
        ip
        owner
        customFields
    }
`)

export const VirtualAssetSettingsFragment = print(gql`
    fragment VirtualAssetSettingsFragment on VirtualAssetSettingsModel {
        type
        customFields 
    }
`)

//Software
export const SoftwareAssetFragment = print(gql`
    fragment SoftwareAssetFragment on SoftwareAssetModel {
        id
        name
        version
        installed
        installationThreshold
        physicalAssets {
            id
            assetId
        }
        customFields
    }
`)

export const SoftwareAssetSettingsFragment = print(gql`
    fragment SoftwareAssetSettingsFragment on SoftwareAssetSettingsModel {
        customFields 
    }
`)

//Saas
export const SaasAssetFragment = print(gql`
    fragment SaasAssetFragment on SaasAssetModel {
        id
        name
        owner
        url
        seeds
        licenses
        integration
        billingCycle
        customFields
    }
`)

export const SaasAssetSettingsFragment = print(gql`
    fragment SaasAssetSettingsFragment on SaasAssetSettingsModel {
        billingCycle
        customFields 
    }
`)

export const CertificateAssetFragment = print(gql`
    fragment CertificateAssetFragment on CertificateAssetModel {
        id
        name
        owner
        fileId
        fileName
        renewal
        customFields
    }
`)

export const CertificateAssetSettingsFragment = print(gql`
    fragment CertificateAssetSettingsFragment on CertificateAssetSettingsModel {
        renewal
        customFields 
    }
`)

export const mainAssetsRequestFragment =  gql`
    fragment mainAssetsRequestFragment on MainAssetsRequestModel {
        id
        workspaceId
        requestDate
        status
        type
        resolver {
            publicId
            fullName
        }
        resolveDate
        resolution
        assetType
        comment
        requestor {
            publicId
            fullName
        }
        data {
            fieldName
            fieldValue
        }
    }
`

export const mainAssetsRequestAssetFragment =  gql`
    fragment mainAssetsRequestAssetFragment on MainAssetsRequestsAssetModel {
        ... on MainAssetsRequestsPhysicalAssetModel {
            id
            workspaceId
            assetId
            serial
            model
            manufacture
            category
            assigned
            customFields
            
            assetType
            assignedTo
            status {
                name
                status
            }
        }
#        ... on MainAssetsRequestsSoftwareAssetModel {
#            id
#            workspaceId
#            assetId
#            serial
#            model
#            manufacture
#            category
#            assigned
#            customFields
#
#            assignedTo
#            status
#        }
    }
`
