import {PageWrapper} from "../../../../../../newShared/components/style";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useAssetsIncomingRequestsPage} from "../../../hooks/useAssetsIncomingRequestsPage";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";
import {MainAssetsRequestModel} from "../../../../../../newShared/GQLTypes";
import {GenericTable} from "../../../../../../newShared/components/genericTable";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {assetRequestTypeMapper} from "../../../constants";
import {getAssetsRequestStatusChip} from "../../../helpers";
import {ApproveRequestRequestAssetDialog} from "../../../components/Dialogs/approveRequestRequestAssetDialog";
import {PhysicalAssetsRequestsExact} from "../exact";
import {RejectAssetRequestDialog} from "../../../components/Dialogs/rejectAssetRequestDialog";
import {CreateAndAssignNewAssetDialog} from "../../../components/Dialogs/CreateAndAssignNewAssetDialog";
import {ApproveAssignNotAssignedAssetDialog} from "../../../components/Dialogs/ApproveAssignNotAssignedAssetDialog";
import {RequestAssetErrorDialog} from "../../../components/Dialogs/RequestAssetErrorDialog";
import {ApproveRequestRemoveAssetDialog} from "../../../components/Dialogs/approveRequestRemoveAssetDialog";
import {ApproveReassignAssetDialog} from "../../../components/Dialogs/ApproveReasiignAssetDialog";


export const AssetsIncomingRequestsList = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets.pathIncomingRequests'});
    const {isMobile} = useMedia();

    const {
        requests,
        handleOpenRequest,
        genericTable,
    } = useAssetsIncomingRequestsPage();


    return (
        <PageWrapper>

            <PageTitleAndActions title={t('Incoming requests')} actions={[]} margin={'0 0 8px 0'} />

            <GenericTable<MainAssetsRequestModel>
                id={'assetsIncomingRequests'}
                columnsConfig={{totalName: 'Total requests', disableColumnsSettings: true, forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: requests,
                    customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id,
                    onRowClick: handleOpenRequest,

                    columnsConfigs: [
                        {key: "id", name: t('Request id'), default: true, },
                        {key: 'type', name: t('Request type'), default: true, valueGetter: (row) => assetRequestTypeMapper[row.type] ?? row.type},
                        {key: 'requestor', name: t('Requestor'), default: true, valueGetter: (row) => row.requestor.fullName},
                        {key: 'requestDate', name: t('Request date'), default: true, valueGetter: (row) => parseDateAuto(row.requestDate)},
                        {key: 'status', name: t('Status'), default: true, valueRenderer: (row) => getAssetsRequestStatusChip(row.status)},
                        {key: 'resolver', name: t('Resolver'), default: true, valueGetter: (row) => row.resolver ? row.resolver.fullName : '-'},
                        {key: 'resolveDate', name: t('Resolved date'), default: true, valueGetter: (row) => parseDateAuto(row.resolveDate)},
                    ],
                }}
                filtersConfig={{
                    searchCustomFilterKeyName: 'requestIdLike',
                    searchAsArray: true,
                    dontSendSearchIfEmpty: true,
                    genericFilterProps: {
                        configs: genericTable.filters.configs,
                        fetchResultWithSelectedFilters: () => null,
                        isAddFilters: false
                    }
                }}
                emptyArrayImageProps={{type: 'any', filterNotApplied: {text: t('You do not have any asset requests yet'), secondaryText: ''}}}
            />

            {/*Dialogs*/}
            <ApproveRequestRequestAssetDialog/>
            <PhysicalAssetsRequestsExact/>
            <RejectAssetRequestDialog/>
            <CreateAndAssignNewAssetDialog />
            <ApproveAssignNotAssignedAssetDialog />
            <ApproveRequestRemoveAssetDialog />
            <RequestAssetErrorDialog />
            <ApproveReassignAssetDialog />
        </PageWrapper>
    )

}
