import {print} from "graphql";
import {gql} from "@apollo/react-hooks";
import {RiskFragment, RiskModelFragment, RiskRegistryFragment} from "./fragments";
import {importCsvFragmentFields, performCsvFragment} from "../../../../newShared/graphQl/fragments";

//
//
export const getRiskRegistyByIdQuery = print(gql`
    query getRiskRegisteryById ($workspaceId: String!, $organizationId: String!, $id:String!){
        getRiskRegisteryById(id: $id, workspaceId: $workspaceId, organizationId: $organizationId){
           ...RiskRegistryFragment
        }
    }
    ${RiskRegistryFragment}
`)

// Registries
export const getRiskRegisters = print(gql`
    query getRiskRegisters ($workspaceId: String!, $organizationId: String!, $pageInfo:PaginationInput!){
        getRiskRegisters(pageInfo: $pageInfo, workspaceId: $workspaceId, organizationId: $organizationId){
            data {...RiskRegistryFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${RiskRegistryFragment}
`)

export const createRiskRegistry = print(gql`
    mutation createRiskRegistry ($workspaceId: String!, $organizationId: String!, $registry: RiskRegistryInput!){
        createRiskRegistry(registry: $registry, workspaceId: $workspaceId, organizationId: $organizationId){
            ...RiskRegistryFragment
        }
    }
    ${RiskRegistryFragment}
`)

export const updateRiskRegistry = print(gql`
    mutation updateRiskRegistry ($workspaceId: String!, $organizationId: String!, $registry: RiskRegistryInput!){
        updateRiskRegistry(registry: $registry, workspaceId: $workspaceId, organizationId: $organizationId){
            ...RiskRegistryFragment
        }
    }
    ${RiskRegistryFragment}
`)

export const deleteRiskRegistry = print(gql`
    mutation deleteRiskRegistry ($workspaceId: String!, $organizationId: String!, $registryId: String!){
        deleteRiskRegistry(registryId: $registryId, workspaceId: $workspaceId, organizationId: $organizationId){
            message
        }
    }
`)

export const releaseRiskRegistry = print(gql`
    mutation releaseRiskRegistry ($workspaceId: String!, $organizationId: String!, $registryId: String!, $frameworkId: String!, $controlId: String!, $evidenceId: String!, $reviewers: [ReleaseActorInput!]!, $readers: [ReleaseActorInput!]!, $signers: [ReleaseActorInput!]!){
        releaseRiskRegistry(registryId: $registryId, workspaceId: $workspaceId, organizationId: $organizationId, frameworkId: $frameworkId, controlId: $controlId, evidenceId: $evidenceId, reviewers: $reviewers, readers: $readers, signers: $signers){
            message
        }
    }
`)

//
//
// Risks
export const getRisksByRegistry = print(gql`
    query getRisksByRegistry($workspaceId: String!, $registryId: String!, $organizationId: String!, $pageInfo: PaginationInput!) {
        getRisksByRegistry(workspaceId: $workspaceId, registryId: $registryId, organizationId: $organizationId, pageInfo: $pageInfo) {
            data {
                ...RiskFragment
            }
            
            registry {...RiskRegistryFragment}
            
            frameworks {
                id
                name
                logo
                controls {
                    id
                    name
                    evidences {
                        id
                        name
                    }
                }
            }
            
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${RiskFragment}
    ${RiskRegistryFragment}
`)

export const getRiskByIdAndRegistryId = print(gql`
    query getRiskByIdAndRegistryId($workspaceId: String!, $registryId: String!, $organizationId: String!, $riskId: String!) {
        getRiskByIdAndRegistryId(workspaceId: $workspaceId, registryId: $registryId, organizationId: $organizationId, riskId: $riskId) {
            risk {...RiskFragment}
            registry {...RiskRegistryFragment}

            frameworks {
                id
                name
                logo
                controls {
                    id
                    name
                    evidences {
                        id
                        name
                    }
                }
            }
        }
    }
    ${RiskFragment}
    ${RiskRegistryFragment}
`)

export const updateRisksByRegistryId = print(gql`
    mutation updateRisksByRegistryId($workspaceId: String!, $registryId: String!, $organizationId: String!, $risks: [RiskInput!]!, $deleteIds: [String!]!) {
        updateRisksByRegistryId(workspaceId: $workspaceId, registryId: $registryId, organizationId: $organizationId, risks: $risks, deleteIds: $deleteIds) {
            data {
                ...RiskFragment
            }

            registry {
                ...RiskRegistryFragment
            }
            
        }
    }
    ${RiskFragment}
    ${RiskRegistryFragment}
`)

export const deleteRiskById = print(gql`
    mutation deleteRiskById($workspaceId: String!, $registryId: String!, $organizationId: String!, $riskId: String!) {
        deleteRiskById(workspaceId: $workspaceId, registryId: $registryId, organizationId: $organizationId, riskId: $riskId) {
            message
        }
    }
`)

export const createRisk = print(gql`
    mutation createRisk ($workspaceId: String!, $organizationId: String!, $registryId: String!, $risk: RiskInput!){
       createRisk(workspaceId: $workspaceId, organizationId: $organizationId, registryId: $registryId, risk: $risk) {
           ...RiskFragment
       }
    }
    ${RiskFragment}
`)

//
//
// Models
export const getRisksModels = print(gql`
    query getRisksModels($workspaceId: String!, $organizationId: String!) {
        getRisksModels(workspaceId: $workspaceId, organizationId: $organizationId) {
            ...RiskModelFragment
        }
    }
    ${RiskModelFragment}
`)

export const getRisksModelById = print(gql`
    query getRisksModelById($workspaceId: String!, $organizationId: String!, $riskModelId: String!) {
        getRisksModelById(workspaceId: $workspaceId, organizationId: $organizationId, riskModelId: $riskModelId) {
            ...RiskModelFragment
        }
    }
    ${RiskModelFragment}
`)

export const createRisksModels = print(gql`
    mutation createRisksModels($workspaceId: String!, $organizationId: String!, $riskModel: RiskModelInput!) {
        createRisksModels(workspaceId: $workspaceId, organizationId: $organizationId, riskModel: $riskModel) {
            ...RiskModelFragment
        }
    }
    ${RiskModelFragment}
`)

export const changeRisksModel = print(gql`
    mutation changeRisksModel($workspaceId: String!, $organizationId: String!, $modelId: String!, $registryId: String!, $pageInfo: PaginationInput!) {
        changeRisksModel(workspaceId: $workspaceId, organizationId: $organizationId, modelId: $modelId, registryId: $registryId, pageInfo: $pageInfo) {
            data {
                ...RiskFragment
            }

            registry {...RiskRegistryFragment}

            frameworks {
                id
                name
                logo
                controls {
                    id
                    name
                    evidences {
                        id
                        name
                    }
                }
            }

            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${RiskRegistryFragment}
    ${RiskFragment}
`)

export const updateRisksModel = print(gql`
    mutation updateRisksModel($workspaceId: String!, $organizationId: String!, $riskModel: RiskModelInput!) {
        updateRisksModel(workspaceId: $workspaceId, organizationId: $organizationId, riskModel: $riskModel) {
            ...RiskModelFragment
        }
    }
    ${RiskModelFragment}
`)

export const deleteRisksModel = print(gql`
    mutation deleteRisksModel($workspaceId: String!, $organizationId: String!, $riskModelId: String!) {
        deleteRisksModel(workspaceId: $workspaceId, organizationId: $organizationId, riskModelId: $riskModelId) {
            message
        }
    }
`)


export const exportCsvRisks = print(gql`
    query exportCsvRisks($organizationId: String!, $workspaceId: String!, $registryId: String!, $fieldKeys: [String!]!, $isDownload: Boolean!){
        exportCsvRisks(workspaceId: $workspaceId, organizationId: $organizationId, registryId: $registryId, fieldKeys: $fieldKeys, isDownload: $isDownload)
    }
`)

export const getCsvFieldsRisks = gql`
    mutation getCsvFieldsRisks($workspaceId: String!, $registryId: String!, $file: String!, $name: String!){
        getCsvFieldsRisks(workspaceId: $workspaceId, registryId: $registryId, file: $file, name: $name) {
            fields {
                ...importCsvFragmentFields
            }
            fileId
        }
    }
    ${importCsvFragmentFields}
`

export const performCsvRisks = gql`
    mutation performCsvRisks($workspaceId: String!, $registryId: String!, $fileId: String!, $fields: [ImportCsvFieldInput!]!){
        performCsvRisks(workspaceId: $workspaceId, registryId: $registryId, fileId: $fileId, fields: $fields) {
            ...performCsvFragment
        }
    }
    ${performCsvFragment}
`


export const importCsvTemplateRisks = print(gql`
    query importCsvTemplateRisks($organizationId: String!, $workspaceId: String!){
        importCsvTemplateRisks(workspaceId: $workspaceId, organizationId: $organizationId)
    }
`)
