import {
  AutocompleteHrManagersQuery,
  AutocompleteHrManagersQueryVariables,
  ExportCsvEmployeesQuery,
  ExportCsvEmployeesQueryVariables,
  GetEmployeesAvailableFieldsForExportQuery,
  GetEmployeesAvailableFieldsForExportQueryVariables,
  GetEmployeesTreeQuery,
  GetEmployeesTreeQueryVariables,
  GetEmployeesUsedCitiesQuery,
  GetEmployeesUsedStreetsAutocompleteQuery,
  GetImportCsvHrManagementTemplateQuery,
  GetImportCsvHrManagementTemplateQueryVariables,
  GetShortDocumentsByWorkEmailWithFilterPagingQuery,
  GetShortDocumentsByWorkEmailWithFilterPagingQueryVariables,
  GetShortEmployeesQuery,
  GetShortEmployeesQueryVariables,
  MainHrIssuesOffendersAutocompleteQuery,
  MainHrIssuesReportersAutocompleteQuery,
  MainHrRequestorAutocompleteModel, MainHrTimeOffEmployeesAutocompleteModel,
  PaginationInput,
  ReleaseEmployeesMutation,
  ReleaseEmployeesMutationVariables,
  UpdateEmployeesTreeMutation,
  UpdateEmployeesTreeMutationVariables
} from "../../../../newShared/GQLTypes";
import {TPageInfo} from "../../../../newShared/types";
import axiosSync from "../../../../newShared/utils/axios/axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {AxiosResponse} from "axios";
import {
    createSettingFormType,
    EmployeeType,
    TEmployeeJobHistory,
    TEmployeeStatus,
    THrSettings,
    THrSettingsJobTitle,
    usedManagerAutocompleteValue
} from "../types";
import {gql} from "graphql.macro";
import {print} from "graphql";
import * as Q from "./query";
import {
  mainHrGetRequestRequestorsAutocomplete, mainHrGetTimeOffEmployeesAutocomplete,
  mainHrIssuesOffendersAutocomplete,
  mainHrIssuesReportersAutocomplete
} from "./query";
import {employeeFragment} from "./fragments";

export const getEmployees = async (data: {workspaceId: string, pageInfo: PaginationInput, search: string}, signal?: AbortSignal): Promise<{employees: EmployeeType[], pageInfo: TPageInfo}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getHrEmployeesList($workspaceId:String!, $pageInfo:PaginationInputAnyFilter!, $search: String!){
                        getHrEmployeesList(workspaceId:$workspaceId, pageInfo:$pageInfo, search: $search){
                            pageInfo{
                                page
                                count
                                total
                                sort
                            }
                            employees {
                                ...employeeFragment
                            }
                        }
                        ${employeeFragment}
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        // console.log(`getHrEmployeesList result: ${JSON.stringify(result)}`);
        return result.data.data.getHrEmployeesList;
    })
)

export const getHrEmployeeById = async (data: {workspaceId: string, id: string}, signal?: AbortSignal): Promise<EmployeeType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getHrEmployeeById($workspaceId:String!, $id:String!){
                        getHrEmployeeById(workspaceId:$workspaceId, id:$id) {
                            ...employeeFragment
                        }
                    }
                    ${employeeFragment}
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getHrEmployeeById;
    })
)

export const getHrEmployeesSettings = async (data: {workspaceId: string}, signal?: AbortSignal): Promise<THrSettings> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getHrEmployeesSettings($workspaceId:String!){
                        getHrEmployeesSettings(workspaceId:$workspaceId){
                            workspaceId
                            terminationType
                            terminationReason
                            eligibleForRehire
                            gender
                            maritalStatus
                            ethnicity
                            country
                            contactType
                            jobType
                            location
                            department
                            division
                            relationshipType
                            degree
                            employmentType
                            customFields
                            timeOffType
                            status{
                                id
                                name
                                textColor
                                backgroundColor
                            }
                            jobTitle{
                                id
                                jobType
                                name
                                requirements
                                workspaceId
                                description
                                count
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getHrEmployeesSettings;
    })
)

export const createHrEmployeeSetting = async (data: {workspaceId: string, field: keyof THrSettings, value: string}, signal?: AbortSignal): Promise<{message: string}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation createHrEmployeeSetting($workspaceId:String!, $field:String!, $value:String!){
                        createHrEmployeeSetting(workspaceId:$workspaceId, field:$field, value:$value){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createHrEmployeeSetting;
    })
)

export const updateHrEmployeeSetting = async (data: {workspaceId: string, field: keyof THrSettings, value: string, newValue: string, updateHistory: boolean}, signal?: AbortSignal): Promise<{message: string}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation updateHrEmployeeSetting($workspaceId:String!, $field:String!, $value:String!, $newValue: String!, $updateHistory: Boolean!){
                        updateHrEmployeeSetting(workspaceId:$workspaceId, field:$field, value:$value, newValue:$newValue, updateHistory:$updateHistory){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateHrEmployeeSetting;
    })
)

export const deleteHrEmployeeSetting = async (data: {workspaceId: string, field: keyof THrSettings, value: string, newValue: string, updateHistory: boolean}, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deleteHrEmployeeSetting($workspaceId:String!, $field:String!, $value: String!, $newValue: String!, $updateHistory: Boolean!){
                        deleteHrEmployeeSetting(workspaceId:$workspaceId, field:$field, value:$value, newValue:$newValue, updateHistory:$updateHistory){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteHrEmployeeSetting.message;
    })
)


export const createHrEmployee = async (data: {workspaceId: string, employee: EmployeeType}, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation createHrEmployee($workspaceId:String!, $employee:HrEmployeeCreateModel!){
                        createHrEmployee(workspaceId:$workspaceId, employee:$employee){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createHrEmployee.message;
    })
)


export const getHrEmployeeJobInfosById = async (data: {workspaceId: string, id: string, pageInfo: PaginationInput}, signal?: AbortSignal): Promise<{jobInfos: TEmployeeJobHistory[], pageInfo: TPageInfo}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getHrEmployeeJobInfosById($workspaceId:String!, $pageInfo:PaginationInput!, $id:String!){
                        getHrEmployeeJobInfosById(id:$id, workspaceId:$workspaceId, pageInfo:$pageInfo){
                            pageInfo{
                                total
                                count
                                sort
                                page
                            }
                            jobInfos{
                                id
                                jobTitle{
                                    jobType
                                    name
                                    description
                                    requirements
                                }
                                employmentType
                                location
                                department
                                division
                                manager{
                                    name
                                    id
                                }
                                startDate
                                current
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getHrEmployeeJobInfosById;
    })
)


export const updateEmployeeHireDate = async (data: {workspaceId: string, id: string, hireDate: string}, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation updateEmployeeHireDate($workspaceId:String!, $id:String!, $hireDate:String!){
                        updateEmployeeHireDate(id:$id, workspaceId:$workspaceId, hireDate:$hireDate){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateEmployeeHireDate.message;
    })
)

export const updateEmployeeStatus = async (data: {workspaceId: string, id: string, status: TEmployeeStatus}, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation updateEmployeeStatus($workspaceId:String!, $id:String!, $status:HrEmployeeStatusModelInput!){
                        updateEmployeeStatus(id:$id, workspaceId:$workspaceId, status:$status){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateEmployeeStatus.message;
    })
)

export const addJobRecord = async (data: {workspaceId: string, id: string, jobRecord: TEmployeeJobHistory}, signal?: AbortSignal): Promise<TEmployeeJobHistory> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation addJobRecord($workspaceId:String!, $id:String!, $jobRecord:HrEmployeeJobInfoInputCreateModel!){
                        addJobRecord(id:$id, workspaceId:$workspaceId, jobRecord:$jobRecord){
                            id
                            jobTitle{
                                jobType
                                name
                                description
                                requirements
                            }
                            employmentType
                            location
                            department
                            division
                            manager{
                                name
                                id
                            }
                            startDate
                            current
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addJobRecord;
    })
)

export const updateJobRecord = async (data: {workspaceId: string, id: string, jobRecord: TEmployeeJobHistory}, signal?: AbortSignal): Promise<TEmployeeJobHistory> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation updateJobRecord($workspaceId:String!, $id:String!, $jobRecord:HrEmployeeJobInfoModelInput!){
                        updateJobRecord(id:$id, workspaceId:$workspaceId, jobRecord:$jobRecord){
                            id
                            jobTitle{
                                jobType
                                name
                                description
                                requirements
                            }
                            employmentType
                            location
                            department
                            division
                            manager{
                                name
                                id
                            }
                            startDate
                            current
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateJobRecord;
    })
)

export const deleteJobRecord = async (data: {workspaceId: string, id: string, jobRecordId: string}, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deleteJobRecord($workspaceId:String!, $id:String!, $jobRecordId:String!){
                        deleteJobRecord(id:$id, workspaceId:$workspaceId, jobRecordId:$jobRecordId){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteJobRecord.message;
    })
)

//deleteHrEmployeeById

export const deleteHrEmployeeById = async (data: {workspaceId: string, id: string}, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deleteHrEmployeeById($workspaceId:String!, $id:String!){
                        deleteHrEmployeeById(id:$id, workspaceId:$workspaceId){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteHrEmployeeById.message;
    })
)

export const releaseEmployeesApi = async (data: ReleaseEmployeesMutationVariables): Promise<ReleaseEmployeesMutation["releaseHrEmployees"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                mutation releaseEmployees ($workspaceId: String!, $organizationId: String!, $frameworkId: String!, $controlId: String!, $evidenceId: String!, $reviewers: [ReleaseActorInput!]!, $readers: [ReleaseActorInput!]!, $signers: [ReleaseActorInput!]!){
                    releaseHrEmployees(workspaceId: $workspaceId, organizationId: $organizationId, frameworkId: $frameworkId, controlId: $controlId, evidenceId: $evidenceId, reviewers: $reviewers, readers: $readers, signers: $signers){
                        message
                    }
                }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.releaseHrEmployees;
    })
)

export const updateHrEmployee = async (data: {workspaceId: string, employee: EmployeeType}, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation updateHrEmployee($workspaceId:String!, $employee:HrEmployeeModelInput!){
                        updateHrEmployee(workspaceId:$workspaceId, employee:$employee){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateHrEmployee.message;
    })
)

export const autocompleteHrManagers = async (data: AutocompleteHrManagersQueryVariables): Promise<AutocompleteHrManagersQuery["autocompleteHrManagers"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                query autocompleteHrManagers($organizationId: String!, $workspaceId: String!, $text: String!, $onlyActive: Boolean!){
                    autocompleteHrManagers(text: $text, workspaceId: $workspaceId, organizationId: $organizationId, onlyActive: $onlyActive) {
                        id
                        name
                    }
                }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.autocompleteHrManagers;
    })
)

export const createHrSettingsJobTitle = async (data: {workspaceId: string, jobTitle: createSettingFormType}): Promise<THrSettingsJobTitle> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation createHrSettingsJobTitle($workspaceId:String!, $jobTitle:CreateJobTitleInput!){
                        createHrSettingsJobTitle(workspaceId:$workspaceId, jobTitle:$jobTitle){
                            id
                            jobType
                            name
                            description
                            requirements
                            workspaceId
                            count
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createHrSettingsJobTitle;
    })
)

export const editHrSettingsJobTitle = async (data: {workspaceId: string, jobTitle: THrSettingsJobTitle, history: boolean}): Promise<THrSettingsJobTitle> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation editHrSettingsJobTitle($workspaceId:String!, $jobTitle:HrEmployeeJobTitleModelInput!, $history: Boolean!){
                        editHrSettingsJobTitle(workspaceId:$workspaceId, jobTitle:$jobTitle, history: $history){
                            id
                            jobType
                            name
                            description
                            requirements
                            workspaceId
                            count
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.editHrSettingsJobTitle;
    })
)

export const deleteSettingsJobTitle = async (data: {workspaceId: string, jobTitle: THrSettingsJobTitle, history: boolean, replaceJobTitleId: string}): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deleteSettingsJobTitle($workspaceId:String!, $jobTitle:HrEmployeeJobTitleModelInput!, $history:Boolean!, $replaceJobTitleId:String!){
                        deleteSettingsJobTitle(workspaceId:$workspaceId, jobTitle:$jobTitle, history:$history, replaceJobTitleId:$replaceJobTitleId){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteSettingsJobTitle.message;
    })
)

export const getEmployeesUsedManagers = async (workspaceId: string, data: {search: string, page: number, count: number}, signal?: AbortSignal): Promise<{values: usedManagerAutocompleteValue[], pageInfo: TPageInfo}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getEmployeesUsedManagers($workspaceId:String!, $data:GetAutocompleteValues!){
                        getEmployeesUsedManagers(workspaceId:$workspaceId, data:$data){
                            values{
                                name
                                id
                            }
                            pageInfo{
                                page
                                count
                                total
                            }
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    data
                }
            },
            {
                headers: buildCommonHeader(),
                // signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getEmployeesUsedManagers;
    })
)


export const getEmployeesUsedCities = async (workspaceId: string, data: {search: string, page: number, count: number, folderId?: string}, signal?: AbortSignal): Promise<GetEmployeesUsedCitiesQuery["getEmployeesUsedCities"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getEmployeesUsedCities($workspaceId:String!, $data:GetAutocompleteValues!){
                        getEmployeesUsedCities(workspaceId:$workspaceId, data:$data){
                            values
                            pageInfo{
                                page
                                count
                                total
                            }
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    data
                }
            },
            {
                headers: buildCommonHeader(),
                // signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getEmployeesUsedCities;
    })
)

export const getEmployeesUsedStreetsAutocomplete = async (workspaceId: string, data: {search: string, page: number, count: number, folderId?: string}, signal?: AbortSignal): Promise<GetEmployeesUsedStreetsAutocompleteQuery['getEmployeesUsedStreetsAutocomplete']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getEmployeesUsedStreetsAutocomplete($workspaceId:String!, $data:GetAutocompleteValues!){
                        getEmployeesUsedStreetsAutocomplete(workspaceId:$workspaceId, data:$data){
                            values
                            pageInfo{
                                page
                                count
                                total
                            }
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    data
                }
            },
            {
                headers: buildCommonHeader(),
                // signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getEmployeesUsedStreetsAutocomplete;
    })
)

export const getShortEmployees = async (data: GetShortEmployeesQueryVariables, signal?: AbortSignal): Promise<GetShortEmployeesQuery['getShortEmployees']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getShortEmployees($workspaceId:String!){
                        getShortEmployees(workspaceId:$workspaceId){
                            firstName
                            lastName
                            id
                            jobTitle
                            manager
                            workEmail
                            status
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getShortEmployees;
    })
)

export const getEmployeesTree = async (data: GetEmployeesTreeQueryVariables, signal?: AbortSignal): Promise<GetEmployeesTreeQuery["getEmployeesTree"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(Q.getEmployeesTree),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getEmployeesTree;
    })
)

export const updateEmployeesTree = async (data: UpdateEmployeesTreeMutationVariables, signal?: AbortSignal): Promise<UpdateEmployeesTreeMutation["updateEmployeesTree"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(Q.updateEmployeesTree),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateEmployeesTree;
    })
)

export const getImportCsvHrManagementTemplate = async (variables: GetImportCsvHrManagementTemplateQueryVariables, signal?: AbortSignal): Promise<GetImportCsvHrManagementTemplateQuery["getImportCsvHrManagementTemplate"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getImportCsvHrManagementTemplate($workspaceId:String!){
                        getImportCsvHrManagementTemplate(workspaceId:$workspaceId)
                    }
                `),
                variables: variables
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getImportCsvHrManagementTemplate;
    })
)

// export const importCsvHrManagement = async (variables: ImportCsvHrManagementMutationVariables, signal?: AbortSignal): Promise<ImportCsvHrManagementMutation["importCsvHrManagement"]["message"]> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: print(gql`
//                     mutation importCsvHrManagement($workspaceId:String!, $file:String!){
//                         importCsvHrManagement(workspaceId:$workspaceId, file:$file){
//                             message
//                         }
//                     }
//                 `),
//                 variables: variables
//             },
//             {
//                 headers: buildCommonHeader(),
//                 signal
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.importCsvHrManagement.message;
//     })
// )

export const exportCsvEmployees = async (variables: ExportCsvEmployeesQueryVariables, signal?: AbortSignal): Promise<ExportCsvEmployeesQuery['exportCsvEmployees']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query exportCsvEmployees($workspaceId:String!, $data:ExportEmployeesCsvInputDto!, $fieldKeys:[String!]!, $isDownload: Boolean!){
                      exportCsvEmployees(workspaceId:$workspaceId, data:$data, fieldKeys: $fieldKeys, isDownload: $isDownload)
                    }
                `),
                variables: variables
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.exportCsvEmployees;
    })
)

export const getEmployeesAvailableFieldsForExport = async (data: GetEmployeesAvailableFieldsForExportQueryVariables): Promise<GetEmployeesAvailableFieldsForExportQuery["getEmployeesAvailableFieldsForExport"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getEmployeesAvailableFieldsForExport($workspaceId:String!){
                        getEmployeesAvailableFieldsForExport(workspaceId:$workspaceId){
                            fields {
                                fieldKey
                                fieldTitle
                            }
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getEmployeesAvailableFieldsForExport;
    })
);

export const getShortDocumentsByWorkEmailWithFilterPagingApi = async (data: GetShortDocumentsByWorkEmailWithFilterPagingQueryVariables, signal?: AbortSignal): Promise<GetShortDocumentsByWorkEmailWithFilterPagingQuery["getShortDocumentsByWorkEmailWithFilterPaging"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getShortDocumentsByWorkEmailWithFilterPaging($workspaceId:String!, $workEmail:String!, $pageInfo:PaginationInputAnyFilter!, $search: String!){
                        getShortDocumentsByWorkEmailWithFilterPaging(workspaceId:$workspaceId, workEmail:$workEmail, pageInfo:$pageInfo, search: $search){
                            files {
                                id
                                documentId
                                type
                                name
                                folderId
                                status
                                origin
                                version
                                latest
                                createdDate
                                lastModified
                                tags
                                generatedFileId
                                currentStepEndDate
                                deletedDate
                                recipients {
                                    eta
                                    role
                                    fields {
                                        id
                                        pageId
                                        type
                                        position {
                                            x
                                            y
                                            metaPageHeight
                                        }
                                        size {
                                            width
                                            height
                                        }
                                        isRequired
                                    }
                                    order
                                    message
                                    actor {
                                        email
                                        firstName
                                        lastName
                                    }
                                }
                            }
                            pageInfo {
                                page
                                count
                                total
                                sort
                            }

                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getShortDocumentsByWorkEmailWithFilterPaging;
    })
)

export const getHrRequestRequestorsAutocompleteApi = async (workspaceId: string, {page, count, search}: {search: string, page: number, count: number}, signal?: AbortSignal): Promise<MainHrRequestorAutocompleteModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(mainHrGetRequestRequestorsAutocomplete),
                variables: {
                    workspaceId,
                    pageRequest: {page, count},
                    search
                }
            },
            {
                headers: buildCommonHeader(),
                // signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.mainHrGetRequestRequestorsAutocomplete;
    })
)

export const mainHrIssuesReportersAutocompleteApi = async (workspaceId: string, {page, count, search}: {search: string, page: number, count: number}, signal?: AbortSignal): Promise<MainHrIssuesReportersAutocompleteQuery["mainHrIssuesReportersAutocomplete"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(mainHrIssuesReportersAutocomplete),
                variables: {
                    workspaceId,
                    pageRequest: {page, count},
                    search
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.mainHrIssuesReportersAutocomplete;
    })
)

export const mainHrIssuesOffendersAutocompleteApi = async (workspaceId: string, {page, count, search}: {search: string, page: number, count: number}, signal?: AbortSignal): Promise<MainHrIssuesOffendersAutocompleteQuery["mainHrIssuesOffendersAutocomplete"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(mainHrIssuesOffendersAutocomplete),
                variables: {
                    workspaceId,
                    pageRequest: {page, count},
                    search
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.mainHrIssuesOffendersAutocomplete;
    })
)

export const mainHrGetTimeOffEmployeesAutocompleteApi = async (
    workspaceId: string,
    { page, count, search }: { search: string; page: number; count: number },
    signal?: AbortSignal
): Promise<MainHrTimeOffEmployeesAutocompleteModel> =>
    await axiosSync
        .then((axios) =>
            axios.post(
                PATH_SERVER,
                {
                  query: print(mainHrGetTimeOffEmployeesAutocomplete),
                  variables: {
                    workspaceId,
                    pageRequest: { page, count },
                    search,
                  },
                },
                {
                  headers: buildCommonHeader(),
                  // signal
                }
            )
        )
        .then((result: AxiosResponse<any>) => {
          return result.data.data.mainHrGetTimeOffEmployeesAutocomplete;
        });
