import {gql} from "@apollo/react-hooks";

export const frameworkFragment = gql`
    fragment frameworkFragment on NewFrameworkModel {
        id
        wfId
        name
        description
        logo
        
        gap
        controls
        controlsCompleted
        evidences
        evidencesFinished
        
        type
        createdBy
        isAdded
    }
`

export const controlFragment = gql`
    fragment controlFragment on NewControlModel {
        id
        name
        description
        
        evidencesFinished
        evidenceTotal
        
        collaborators {
            id
            name
        }
        delegatedTasks {
            created
            approved
            performed
            declined
        }
    }
`

export const evidenceAutoFile = gql`
    fragment evidenceAutoFile on EvidenceAutoFileDto {
        id
        name
        lastCollected
        data
        type
    }
`

export const evidenceFragment = gql`
    fragment evidenceFragment on NewEvidenceModel {
        id
        name
        description
        status
        
        files {
            ...evidenceAutoFile
        }
        automations {
            ...evidenceAutoFile
        }
        statements {
            ...evidenceAutoFile
        }
        
        vicaStep {
            stepId
            stepName
        }
    }
    ${evidenceAutoFile}
`

export const commentFragment = gql`
    fragment commentFragment on Comment {
        id
        text
        author {
            id
            name
            logo
        }
        date
        frameworkId
        controlId
        workspaceId
    }
`

export const historyFRFragment = gql`
    fragment historyFRFragment on AnalyseHistoryFrameworkModel {
        historyId
        auditId
        auditName

        date

        solution
        status
        findingsCount

        findings
    }
`


export const DelegatedTaskModelFragment = gql`
    fragment DelegatedTaskModelFragment on DelegatedTaskModel {
        id
        workspaceId
        title
        description
        requestor{
            collaboratorId
            email
            fullName
        }
        createdDate
        requestorComment
        resolver{
            collaboratorId
            email
            fullName
        }
        resolveDate
        resolverComment
        performer{
            collaboratorId
            email
            fullName
        }
        performDate
        performerComment
        status
        type
        source
        vicaStepId
        frameworkId
        controlId
        evidenceId
        data {
            attachedFiles {
                fileName
                fileId
                addedToEvidence
            }
        }
       
    }
`
