import {
    deselectDocument,
    files,
    handleReturnToDraftAction,
    hideGalleryEditorDialog,
    isLoading as isLoadingSelector,
    openAddDocumentDialog,
    selectedNewDocument,
    setFormCreateDoc,
    tagColors
} from "../../store/slice";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    GetNewTemplateById,
    GetShortDocumentsByFolderIdWithFilterPaging,
    handlePreviewBeforeSendAction,
    InvalidateDocument,
    RestartSignStageByStageIdAndGroupId,
    RestartSignStageByStageIdAndRecipient,
    SendToNextStepByStageId,
    StartNewDocumentExecution,
    UpdateFullDocument
} from "../../store/actions";
import {GALLERY_LOCAL_ID, TREE_GALLERY_PATH, TREE_PATH} from "../../constants";
import {TShortDocument} from "../../types";
import {
    DocumentExecutorGroupModel,
    DocumentExecutorRecipientModel,
    DocumentExecutorStepModel,
    NewDocDataVariableModel
} from "../../../../../newShared/GQLTypes";
import {TEditorActorsConfigFromDB, TEditorElementData} from "../../../../../newShared/components/editorUnion/types";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {useSnack} from "../../../../barsEnvironment/snack/hooks/useSnack";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {useTemplatePreview} from "../../../../../newShared/components/docTemplateViewer/hooks/useTemplatePreview";

export const useGallery = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.GalleryPage'});
    const dispatch = useDispatch();
    const snack = useSnack();
    const _files = useSelector(files);
    const _isLoading = useSelector(isLoadingSelector).isLoadingFiles;
    const loadings = useSelector(isLoadingSelector);
    const _tagColors = useSelector(tagColors);
    const [filtered, setFiltered] = useState<(TShortDocument | null)[]>(_files ?? []);
    const _selectedDoc = useSelector(selectedNewDocument);
    const {user} = UseManageWorkspacesAndOrganizations();


    useSetBreadcrumbs([
        {title: t('Documents'), path: TREE_PATH},
        {title: t('Template gallery'), path: TREE_GALLERY_PATH}
    ])

    const getTemplates = () => dispatch(GetShortDocumentsByFolderIdWithFilterPaging({data: {workspaceId: '', folderId: GALLERY_LOCAL_ID, pageInfo: {page: 0, count: 10000}}}));
    // const eraseFilesHandler = () => {dispatch(eraseFiles())};

    const tagsVariants: string[] = [];
    _files.forEach(e => e?.tags?.forEach(tag => tagsVariants.push(tag)));


    //Filter configs
    const tagsConfig: keyConfig<string> = {
        type: 'filter',
        key: 'tags',
        name: t('Tags'),
        options: [...new Set(tagsVariants)],
        default: true,
    }

    function filterTemplates(search: string, filters: Record<string, string[]>){
        const tags = filters['tags'] ?? [];
        if(!tags.length && !search.trim().length) {
            setFiltered(_files);
            return;
        }
        const newFs = _files.filter(file => {
            return (search.trim().length > 0 && file?.name.toLowerCase().includes(search.toLowerCase())) ||
                file?.tags?.some(tag => tags.includes(tag))
        })
        setFiltered(newFs);
    }

    useEffect(() => {
        _files && setFiltered(_files);
        //eslint-disable-next-line
    }, [_files])

    useEffect(() => {
        _files && setFiltered(_files);

        return () => {
            dispatch(hideGalleryEditorDialog());
        }
        //eslint-disable-next-line
    }, [])

    const {handleClose: handleClosePreview} = useTemplatePreview();
    const handleUseTemplate = (templateId: string) => {
        handleClosePreview();
        dispatch(openAddDocumentDialog({isTemplate: true}));
        dispatch(GetNewTemplateById({workspaceId: '', fileId: templateId}));
        dispatch(setFormCreateDoc({docVariant: "TEMPLATE"}));
    }

    const docId = _selectedDoc?.document?.documentId
    return{
        files: filtered,
        tagColors: _tagColors,
        getTemplates,
        filterTemplates,
        _isLoading,
        // eraseFilesHandler,
        handleUseTemplate,

        configs: [
            tagsConfig,
        ],
        doc: {
            selectedNewDocument: JSON.parse(JSON.stringify(_selectedDoc)),
            currentEmail: user?.email ?? '',
            snack,
            loadings,
            actions: {
                handleCloseEditor: () => {
                    dispatch(deselectDocument())
                },
                handleReturnToDraft: () => {
                    dispatch(handleReturnToDraftAction())
                },
                onResendInviteApprover: (step: DocumentExecutorStepModel, group: DocumentExecutorGroupModel) => {
                    docId && dispatch(RestartSignStageByStageIdAndGroupId({documentId: docId, stageId: step.id, groupId: group.id}));
                },
                onResendInviteRecipient: (step: DocumentExecutorStepModel, recipient: DocumentExecutorRecipientModel) => {
                    docId && dispatch(RestartSignStageByStageIdAndRecipient({documentId: docId, stageId: step.id, recipientEmail: recipient.email}));
                },
                handleSkip: (step: DocumentExecutorStepModel) => {
                    docId && dispatch(SendToNextStepByStageId({documentId: docId, stageId: step.id}));
                },
                handleInvalidate: () => {
                    if(_selectedDoc && _selectedDoc.document){
                        docId && dispatch(InvalidateDocument(docId));
                    }
                },
                handleSave: (data: TEditorElementData<{}>[], variables: NewDocDataVariableModel[], docTitle: string, actors: TEditorActorsConfigFromDB) => {
                    if(_selectedDoc && _selectedDoc.document) {
                        dispatch(UpdateFullDocument({
                            documentId: _selectedDoc.document.documentId ?? '',
                            name: docTitle,
                            data: {
                                editor: data,
                                variables,
                                pdfFileId: _selectedDoc.document.data?.pdfFileId || null,
                            },
                            recipients: actors.recipients,
                            approvers: actors.approvers,
                            editors: actors.editors
                        }))
                    }
                },
                handleSend: (data: TEditorElementData<{}>[], variables: NewDocDataVariableModel[], docTitle: string, actors: TEditorActorsConfigFromDB) => {
                    if(_selectedDoc && _selectedDoc.document) {
                        dispatch(StartNewDocumentExecution({
                            documentId: _selectedDoc.document.documentId ?? '',
                            name: docTitle,
                            data: {
                                editor: data,
                                variables,
                                pdfFileId: _selectedDoc.document.data?.pdfFileId || null,
                            },
                            recipients: actors.recipients,
                            approvers: actors.approvers,
                            editors: actors.editors
                        }))
                    }
                },
                handlePreviewBeforeSend: (data: TEditorElementData<{}>[], variables: NewDocDataVariableModel[], docTitle: string, actors: TEditorActorsConfigFromDB) => {
                    if(_selectedDoc && _selectedDoc.document) {
                        dispatch(handlePreviewBeforeSendAction({
                            documentId: _selectedDoc.document.documentId ?? '',
                            name: docTitle,
                            data: {
                                editor: data,
                                variables,
                                pdfFileId: _selectedDoc.document.data?.pdfFileId || null,
                            },
                            recipients: actors.recipients,
                            approvers: actors.approvers,
                            editors: actors.editors
                        }))
                    }
                }
            },
        }
    }
}
