import React, {FC, useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogTitle} from "@mui/material";
import {useMainTranslation} from "../../../../../hooks/useMainTranslationHooks/useMainTranslation";
import * as S from "./styled";
import {useSelector} from "react-redux";
import {dialogs} from "../../../../../../newDomain/components/documentsRefactor/store/slice";

interface AddGroupMessageDialogProps {
    handleSetGroupMessage: (data: {approverId?: string, groupId?: string, message: string, recipientId?: string}) => void,
    handleClose: () => void
}
export const AddGroupMessageDialog: FC<AddGroupMessageDialogProps> = (props) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});
    const [message, setMessage] = useState<string>('');
    const  {isOpen, approverId, groupId, recipientId, oldMessage} = useSelector(dialogs).addGroupMessage;
    const {
        handleSetGroupMessage,
        handleClose,
    } = props;


    useEffect(() => {
        if (isOpen) {
            oldMessage !== null && setMessage(oldMessage ?? "");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    const handleAdd = () => {
       if (approverId && groupId) handleSetGroupMessage({approverId, groupId, message});
       else if (recipientId) handleSetGroupMessage({recipientId, message});
    }


    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t("Private message")}</DialogTitle>
            <S.AddMessageDialogContent>
                <S.MessageField value={message} onChange={e => setMessage(e.target.value)} placeholder={t('Start typing a private message..')} />
            </S.AddMessageDialogContent>
            <DialogActions>
                <Button variant={"contained"} onClick={handleClose}>{'Close'}</Button>
                <Button onClick={handleAdd}>{'Save'}</Button>
            </DialogActions>
        </Dialog>
    )

}
