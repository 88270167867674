import {addAppTabs, useAddAppDialog} from "../../../hooks/dialogHooks/useAddAppDialog";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs} from "@mui/material";
import React from "react";
import {DialogContentWrapper, TextFieldCustom} from "../../styled";
import {Autocomplete, TabContext, TabPanel} from "@mui/lab";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Vendor} from "../../../types";
import {AutocompleteCollaborators} from "../../../../../../newShared/components/AutocompleteCollaborators";
import {optionIsTCollaborator} from "../../../../../../newShared/components/AutocompleteCollaborators/types";
import {getName} from "../../../../../../newShared/utils/text";

export const AddApplicationDialog = () => {
    const {
        //dialog
        isOpen,
        isLoading,
        isLoadingInitData,
        handleClose,
        handleSubmit,
        //other
        tab,
        setTab,
        createNew,
        createByVendor,
        isOk
    } = useAddAppDialog();

    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.AccessManagementAppsList.dialogs'});

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Add application')}</DialogTitle>

            <DialogContent>
                <TabContext value={tab} >
                    <Box>
                        <Tabs variant={'scrollable'} value={tab} onChange={(e, newValue: addAppTabs) => setTab(newValue)}>
                            <Tab label={t("Create new application")} value={'addNew'} disabled={isLoadingInitData || isLoading} sx={{paddingLeft: '0'}}/>
                            <Tab label={t("Create by vendor")} value={'selectVendor'} disabled={isLoadingInitData || isLoading} />
                        </Tabs>
                    </Box>

                    <DialogContentWrapper>
                        <TabPanel value={'addNew'} sx={{padding: '16px 0 0 0', overflowY: 'auto'}}>
                            <TextFieldCustom
                                required
                                value={createNew.form.name}
                                name={'name'}
                                onChange={createNew.handleChange}
                                placeholder={t('Name')}
                                label={t('Application name')}
                                size={'small'}
                            />

                            <AutocompleteCollaborators
                                required
                                sx={{margin: '8px 0 0 0'}}
                                label={t("Select owner")}

                                getOptionLabelCustom={"name+email"}
                                getOptionLabel={(option) => optionIsTCollaborator(option) ? getName(option.firstName || '', option.lastName || '') : option}
                                defaultValue={null}
                                onChange={(value) => {
                                    optionIsTCollaborator(value) && createNew.handleSetOwner(value)
                                }}
                            />

                            <Autocomplete
                                id="combo-box-demo"
                                options={createNew.vendorsSettings.type.map(e => e.name)}
                                loading={isLoadingInitData}
                                value={createNew.form.type}
                                onChange={(e, newValue: string | null) => newValue && createNew.handleSetType(newValue)}
                                renderInput={(params) => <TextFieldCustom {...params} label={t("Select type")} size={'small'} required/>}
                                sx={{margin: '8px 0 0 0'}}
                            />

                            <Autocomplete
                                id="combo-box-demo"
                                options={createNew.vendorsSettings.serviceProvided.map(e => e.name)}
                                loading={isLoadingInitData}
                                value={createNew.form.providedService}
                                onChange={(e, newValue: string | null) => newValue && createNew.handleSetProvidedService(newValue)}
                                renderInput={(params) => <TextFieldCustom {...params} label={t("Select provided service")} size={'small'} required/>}
                                sx={{margin: '8px 0 0 0'}}
                            />

                            <Autocomplete
                                id="combo-box-demo"
                                options={createNew.vendorsSettings.risk.map(e => e.name)}
                                loading={isLoadingInitData}
                                value={createNew.form.riskLevel}
                                onChange={(e, newValue: string | null) => newValue && createNew.handleSetRiskLevel(newValue)}
                                renderInput={(params) => <TextFieldCustom {...params} label={t("Select risk level")} size={'small'} required/>}
                                sx={{margin: '8px 0 0 0'}}
                            />
                        </TabPanel>

                        <TabPanel value={'selectVendor'} sx={{padding: '16px 0 0 0', overflowY: 'auto'}}>
                            <Autocomplete
                                id="combo-box-demo"
                                options={createByVendor.vendors.filter(e => e.owner !== null)}
                                getOptionLabel={(option) => option.name}
                                loading={isLoadingInitData}
                                value={createByVendor.form.vendor}
                                onChange={(e, newValue: Vendor | null) => newValue && createByVendor.handleSetVendor(newValue)}
                                renderInput={(params) => <TextFieldCustom {...params} label={t("Select vendor")} size={'small'} required/>}
                            />

                            <TextFieldCustom
                                required
                                value={createByVendor.form.name}
                                name={'name'}
                                onChange={createByVendor.handleChange}
                                placeholder={t('Name')}
                                label={t('Application name')}
                                size={'small'}
                                sx={{margin: '8px 0 0 0'}}
                            />
                        </TabPanel>
                    </DialogContentWrapper>
                </TabContext>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                    disabled={!isOk}
                >
                    {t('Add')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}