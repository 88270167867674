import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {additionalDataForCreateIntegration, dialogs, hideAddIntegrationDialog, loadings} from "../../../../store/slice";
import {AZUREAD_ENDPOINT_MAPPING_NAME, initialVendorForm, PATH_INTEGRATIONS_ADDED_EXACT} from "../../../../constants";
import {useForm} from "../../../../../../../newShared/hooks/useForm";
import React, {useEffect, useState} from "react";
import {AddIntegration} from "../../../../store/actions";
import {useMessageDialog} from "../../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {addAppVendorManage, AzureAdIntegrationDetailsType} from "../../../../types";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSelectVendorInIntegration} from "../../../useSelectVendorInIntegration";

export const useAddIntegrationAzureAdDialog = () => {
    //root
    const dispatch = useDispatch();
    const history = useHistory();
    const {workspaceId} = UseManageWorkspacesAndOrganizations();
    const {setMessage} = useMessageDialog();
    //selectors
    //TODO on init -> get available apps -> find detailsId by endpointMappingName -> put in form
    const {addDialogs} = useSelector(dialogs);
    const {addIntegration, vendorsAndEmployeesForCreate} = useSelector(loadings);
    const isOpen = addDialogs[AZUREAD_ENDPOINT_MAPPING_NAME].isOpen;
    const additionalDataForCreate = useSelector(additionalDataForCreateIntegration);
    //actions
    const handleClose = () => {
        dispatch(hideAddIntegrationDialog(AZUREAD_ENDPOINT_MAPPING_NAME));
    };

    const handleSubmit = () => {
        const detailsId = addDialogs[AZUREAD_ENDPOINT_MAPPING_NAME].app?.id;
        if (!detailsId) return;
        if(tab === 'selectVendor'){
            vendorForm.form.selectedVendor && dispatch(AddIntegration({
                integrationDetails: {...form, detailsId},
                createVendor: null,
                vendorId: vendorForm.form.selectedVendor.id,
                onSuccess
            }));
        }else{
            //createVendor
            if(vendorForm.form.create.type && vendorForm.form.create.riskLevel && vendorForm.form.create.providedService){
                dispatch(AddIntegration({
                    integrationDetails: {...form, detailsId},
                    createVendor: {
                        owner: vendorForm.form.create.owner?.publicId ? {
                            id: vendorForm.form.create.owner?.publicId,
                            name: '',
                        } : null,
                        riskLevel: vendorForm.form.create.riskLevel,
                        providedService: vendorForm.form.create.providedService,
                        type: vendorForm.form.create.type,
                    },
                    vendorId: null,
                    onSuccess
                }));
            }
        }
    }

    const {t} = useMainTranslation('', {keyPrefix: 'pathIntegrations.AvailableAppsList.dialogs'});
    const onSuccess = (id: string) => {
        setMessage({title: t('Completed successfully!'), message: t(`Integration created! The integration is in 'In progress' status. Wait for completion.`)});
        handleClose();
        history.push(PATH_INTEGRATIONS_ADDED_EXACT.replace(':id', id));
    }

    //useEffects
    useEffect(() => {
        if(!isOpen) cleanUp();
        //eslint-disable-next-line
    }, [isOpen])

    //form
    const initialForm: AzureAdIntegrationDetailsType = {
        applicationId: '',
        workspaceId: workspaceId ?? '',
        status: '',
        integration: {
            clientId: '',
            clientSecret: '',
            tenantId: '',
        },
        identity: false,
        hris: false,
        endpointMappingName: AZUREAD_ENDPOINT_MAPPING_NAME,
        detailsId: '',
        //additional:
        name: '',
    };

    // const [fileName, setFileName] = useState<String>("");
    const {form, setForm, handleChange} = useForm<AzureAdIntegrationDetailsType>(initialForm);
    const handleChangeIntegration = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm(prev => ({...prev, integration: {...prev.integration, [e.target.name]: e.target.value}}));
    }

    //other

    //
    const ref = React.createRef<HTMLInputElement>();
    const filePicker = () => {
        ref.current && ref.current.click();
    };

    const cleanUp = () => {
        setForm(initialForm);
        vendorForm.setForm(initialVendorForm);
        // setFileName('');
        setStep('app');
        setTab('selectVendor');
    }

    //stepper
    const [step, setStep] = useState<'app' | 'vendor'>('app');
    const [tab, setTab] = useState<'selectVendor' | 'createVendor'>('selectVendor');

    const vendorForm = useForm<addAppVendorManage>(initialVendorForm);

    const {
        handleSetOwner, handleSetProvidedService, handleSetRiskLevel, handleSetType, handleSetVendor
    } = useSelectVendorInIntegration(vendorForm.form, vendorForm.setForm);

    return{
        isOpen,
        app: addDialogs[AZUREAD_ENDPOINT_MAPPING_NAME].app,
        handleClose,
        isLoading: addIntegration,
        isOk: form.integration.clientId?.trim().length > 0 && form.integration.clientSecret.trim().length > 0 && form.integration.tenantId.trim().length > 0 && form.name.trim().length > 0,
        isOkVendorsStep: () => {
            if(tab === 'createVendor'){
                return (vendorForm.form.create.riskLevel ?? '').length > 0 && (vendorForm.form.create.type ?? '').length > 0
                    && (vendorForm.form.create.providedService ?? '').length > 0
            }else if(tab === 'selectVendor'){
                return vendorForm.form.selectedVendor !== null;
            }
        },
        actions: {
            handleClose,
            handleSubmit,
            handleChange,
            setForm,
            handleChangeIntegration,
            filePicker
        },
        ref,
        form,
        // fileName,
        stepper: {
            step,
            setStep,
            tab,
            setTab,
            form: vendorForm.form,
            handleSetOwner,
            handleSetProvidedService,
            handleSetRiskLevel,
            handleSetType,
            handleSetVendor,
            additionalDataForCreate,
            isLoadingAdditionalData: vendorsAndEmployeesForCreate,
        }
    }
}
