import {gql} from "graphql.macro";
import {pageInfoFragment} from "../../../graphQl/fragments";

export const autocompleteCollaborators = gql`
    query autocompleteCollaborators($organizationId: String!, $workspaceId: String!, $autocompleteInput: AutocompleteInput!, $byAllWorkspaces: Boolean, $roles: [CollaboratorRole!]) {
        autocompleteCollaborators(organizationId: $organizationId, workspaceId: $workspaceId, autocompleteInput: $autocompleteInput, byAllWorkspaces: $byAllWorkspaces, roles: $roles) {
            result {
                publicId
                firstName
                lastName
                email
                roles
            }
            pageInfo {
                ...pageInfoFragment
            }
        }
    }
    ${pageInfoFragment}
`