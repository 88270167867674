import {useHistory} from "react-router";
import {
    PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED,
    PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED
} from "../../../../../newShared/constants";
import {
    cleanAction,
    cleanReportAction,
    cleanReportControlsAction,
    cleanSelectedControlAction,
    isLoadingSelector,
    reportControlsSelector,
    reportUsersSelector,
    reportViewSelector,
    TReportViewSlice
} from "../../store/reportView/store";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteAssetFromNCRByUserAction,
    downloadEvidenceFileAction,
    downloadReportCollectorAssetAction,
    getFullNcrReportPreviewByUserAction,
    getReportAction,
    getReportAssetCollectorDataByUserAction,
    getReportControlByIdAction,
    getReportControlsAction,
    leaveResponseToNCRByUserAction,
    setSnapshotReportResponseByUserAction,
    uploadAssetsToNCRByUserAction
} from "../../store/reportView/actions";
import {
    DeleteAssetFromNcrByUserMutationVariables,
    LeaveResponseToNcrByUserMutationVariables,
    PaginationInput,
    UploadAssetsToNcrByUserMutationVariables
} from "../../../../../newShared/GQLTypes";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {useEffect} from "react";

type TResponse = {
    report: TReportViewSlice["report"],
    controls: TReportViewSlice["controls"],
    users: TReportViewSlice["users"],
    isLoading: TReportViewSlice["isLoading"],

    getReport: (reportId: string, signal?: AbortSignal) => void,
    getControls: (reportId: string, pageInfo: PaginationInput, clear: boolean, signal?: AbortSignal) => void,
    getControlById: (reportId: string, controlId: string, signal?: AbortSignal) => void,

    downloadFile: (fileId: string, fileName: string) => void,
    downloadTable: (reportId: string, assetId: string, evidenceId: string) => void,
    getCollectorData: (reportId: string, evidenceId: string, assetId: string, pageInfo: PaginationInput, signal?: AbortSignal) => void,

    leaveResponseToNCR: (data: LeaveResponseToNcrByUserMutationVariables, finalFunc: () => void) => void,
    uploadAssetsToNCR: (data: UploadAssetsToNcrByUserMutationVariables) => void,
    deleteAsset: (data: DeleteAssetFromNcrByUserMutationVariables) => void,

    changeReportStatus: (reportId: string) => void,
    getPreviewData: (reportId: string) => void,

    goToGenerated: () => void,
    goToArchived: () => void,
    goToControl: (reportId: string, controlId: string, archived: boolean) => void,
    goToIntegrationDetails: (reportId: string, archived: boolean) => void,

    cleanReport: () => void,
    cleanReportReport: () => void,
    cleanReportControlsReport: () => void,
    cleanSelectedControl: () => void,
}
export const useReport = (): TResponse => {

    const dispatch = useDispatch();
    const history = useHistory();

    const report = useSelector(reportViewSelector);
    const controls = useSelector(reportControlsSelector);
    const users = useSelector(reportUsersSelector);
    const isLoading = useSelector(isLoadingSelector);
    const {clearFilters} = useGenericFiltersStorage();

    const getReport: TResponse["getReport"] = (reportId, signal) => {dispatch(getReportAction({
        data: {workspaceId: '', organizationId: '', reportId}, signal,
    }))};
    const getControls: TResponse["getControls"] = (reportId, pageInfo, clean, signal) => {dispatch(getReportControlsAction({
        data: {workspaceId: '', organizationId: '', reportId, pageInfo}, signal, clean,
    }))};
    const getControlById: TResponse["getControlById"] = (reportId, controlId, signal) => {dispatch(getReportControlByIdAction({
        data: {workspaceId: '', organizationId: '', reportId, controlId}, signal,
    }))};

    const downloadFile: TResponse["downloadFile"] = (fileId, fileName) => {dispatch(downloadEvidenceFileAction({data: {workspaceId: '', fileId, fileName}}))};
    const downloadTable: TResponse["downloadTable"] = (reportId, assetId, evidenceId) => {dispatch(downloadReportCollectorAssetAction({data: {reportId, assetId, evidenceId}}))};
    const getCollectorData: TResponse["getCollectorData"] = (reportId, evidenceId, assetId, pageInfo, signal) => {
        dispatch(getReportAssetCollectorDataByUserAction({data: {workspaceId: '', organizationId: '', reportId, evidenceId, assetId, pageInfo}, signal}))
    };

    const leaveResponseToNCR: TResponse["leaveResponseToNCR"] = (data, onSuccess) => {dispatch(leaveResponseToNCRByUserAction({data, onSuccess}))};
    const uploadAssetsToNCR: TResponse["uploadAssetsToNCR"] = (data) => {dispatch(uploadAssetsToNCRByUserAction({data}))};
    const deleteAsset: TResponse["deleteAsset"] = (data) => {dispatch(deleteAssetFromNCRByUserAction({data}))};

    const changeReportStatus: TResponse["changeReportStatus"] = (reportId) => {dispatch(setSnapshotReportResponseByUserAction({data: {reportId, workspaceId: ''}}))};
    const getPreviewData: TResponse["getPreviewData"] = (reportId) => {dispatch(getFullNcrReportPreviewByUserAction({data: {reportId, workspaceId: '', organizationId: ''}}))};

    const goToGenerated = () => {history.push(PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED)};
    const goToArchived = () => {history.push(PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED)};
    const goToControl: TResponse["goToControl"] = (reportId, controlId, archived) => {
        !archived
            ? history.push(`${PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED}/${reportId}/${controlId}` + history.location.search)
            : history.push(`${PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED}/${reportId}/${controlId}` + history.location.search)
    };
    const goToIntegrationDetails: TResponse["goToIntegrationDetails"] = (reportId, archived) => {
        !archived
            ? history.push(`${PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED}/${reportId}/integrations`)
            : history.push(`${PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED}/${reportId}/integrations`)
    };

    const cleanReport = () => {dispatch(cleanAction())};
    const cleanReportReport = () => {dispatch(cleanReportAction())};
    const cleanReportControlsReport = () => {dispatch(cleanReportControlsAction())};
    const cleanSelectedControl = () => {dispatch(cleanSelectedControlAction())};

    useEffect(() => {
        return () => clearFilters();
        //eslint-disable-next-line
    }, []);

    return {
        report,
        controls,
        users,
        isLoading,

        getReport,
        getControls,
        getControlById,

        downloadFile,
        downloadTable,
        getCollectorData,

        changeReportStatus,
        getPreviewData,

        leaveResponseToNCR,
        uploadAssetsToNCR,
        deleteAsset,

        goToGenerated,
        goToArchived,
        goToControl,
        goToIntegrationDetails,

        cleanReport,
        cleanReportReport,
        cleanSelectedControl,
        cleanReportControlsReport,
    }
}