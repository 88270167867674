import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {useDeleteInstructionDialog} from "../../../../hooks/campaignReviewHooks/dialogs/useDeleteInstructionDialog";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import colors from "../../../../../../../newShared/theme/colors";
import {LoadingButton} from "../../../../../../../newShared/components/Basic/CommonButtons";

export const DeleteCampaignReviewInstructionDialog = () => {
    const {isOpen, handleSubmit, handleClose, instruction, isLoading} = useDeleteInstructionDialog();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.CampaignReviewInstructionCreate.dialogs'});

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t(`Delete {{name}} configuration`, {name: instruction?.name ?? ''})}</DialogTitle>

            <DialogContent>
                <Typography variant={'body2'} color={colors.darkBlueText}>
                    {t(`If you delete this configuration, you will not be able to restore it and all running campaign reviews will be terminated. Are you sure?`)}
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                >
                    {t('Delete')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}