import {useDispatch, useSelector} from "react-redux";
import {
    cookieStoreType,
    ForbiddenWrapperOrganizationCuttedForCookies,
    TSmallWorkspaceCuttedForCookies,
    TUserInfo,
} from "../../types";
import {
    allMultiStoreData,
    eraseData,
    setSharedWorkspacesAndOrganizationsData
} from "../../store/sharedWorkspacesAndOrganizations/slice";

import {useHistory} from "react-router-dom";
import {useCookies} from "../../../../../newShared/hooks/useCookies";
import {MAIN_DATA_COOKIE_NAME, PATH_LOCAL_LOGIN} from "../../../../../newShared/constants";
import {SAVE_WS_PREFIX} from "../../../settings/constants";
import {generateNewToken} from "../../../api";
import {logoutAction} from "../../../signIn/store/actions";
import {cleanUpForbiddenWrapperData} from "../../../settings/store/slice";
import {Logging} from "../../../../../newShared/utils/logs/log";

export const UseManageWorkspacesAndOrganizations = () => {
    //this hooks is user to manage workspace/organizations/user data -> applies to another webs
    const dispatch = useDispatch();
    const history = useHistory();


    const {setCookieByName, getCookieByName, deleteCookieByName} = useCookies();

    const AllMultiStoreData = useSelector(allMultiStoreData) as cookieStoreType;

    const handleSaveToLocalStorage = (publicId: string, workspaceId: string, organizationId: string) => {
        localStorage.setItem(`${SAVE_WS_PREFIX}${publicId}`, JSON.stringify({workspaceId, organizationId}));
    }

    const setCurrentWorkspaceAndOrganization = async(workspace: TSmallWorkspaceCuttedForCookies, organization: ForbiddenWrapperOrganizationCuttedForCookies, user?: TUserInfo) => {
        // console.log(`setCurrentWorkspaceAndOrganization was called (generating new token + setting cookies/store if success`);
        try {
            await generateNewToken(organization.id, workspace.id);
            AllMultiStoreData.currentUser && handleSaveToLocalStorage(AllMultiStoreData.currentUser?.publicId, workspace.id, organization.id);
            const currData = {
                ...AllMultiStoreData,
                currentUser: user ? user : AllMultiStoreData.currentUser,
                selectedWorkspace: workspace,
                selectedOrganization: {
                    id: organization.id,
                    name: organization.name,
                    subscription: organization.subscription,
                    subscriptionExpiry: organization.subscriptionExpiry,
                    configuration: organization.configuration,
                    blocked: organization.blocked
                }, //cookies can handle up to 4096bytes
            };

            setCookieData(currData);
            dispatch(setSharedWorkspacesAndOrganizationsData(currData));
        }catch (ex: any){
            console.log(`setCurrentWorkspaceAndOrganization: error while generateNewToken -> not setting new cookies.`)
            return;
        }
    }

    const setCurrentWorkspaceAndOrganizationAfterOrganizationCreate = (workspace: TSmallWorkspaceCuttedForCookies, organization: ForbiddenWrapperOrganizationCuttedForCookies) => {
        // console.log(`setCurrentWorkspaceAndOrganizationAfterOrganizationCreate was called (setting cookies/store`);
        try {
            AllMultiStoreData.currentUser && handleSaveToLocalStorage(AllMultiStoreData.currentUser?.publicId, workspace.id, organization.id);
            const currData = {
                ...AllMultiStoreData,
                selectedWorkspace: workspace,
                selectedOrganization: {
                    id: organization.id,
                    name: organization.name,
                    subscription: organization.subscription,
                    subscriptionExpiry: organization.subscriptionExpiry,
                    configuration: organization.configuration,
                    blocked: organization.blocked
                }, //cookies can handle up to 4096bytes
            };

            setCookieData(currData);
            dispatch(setSharedWorkspacesAndOrganizationsData(currData));
        }catch (ex: any){
            console.log(`setCurrentWorkspaceAndOrganization: error while generateNewToken -> not setting new cookies.`)
            return;
        }
    }

    const setUser = (user: TUserInfo) => {
        const currData = {...AllMultiStoreData, currentUser: user, is2FaAuthComplete: true, isAuthComplete: true};
        //getting user on 2fa page - so setting user and is2FaAuthComplete
        //if user updated - user already signed in - so is2FaAuthComplete already completed
        setCookieData(currData);
        dispatch(setSharedWorkspacesAndOrganizationsData(currData));
    }

    const setCookieData = (value: cookieStoreType) => {
        // console.log(`setCookieData: ${value.selectedOrganization?.name} | ${value.selectedWorkspace?.name}`);
        setCookieByName(MAIN_DATA_COOKIE_NAME, JSON.stringify(value));
    }

    const getCookieData = (): cookieStoreType | null => {
        const res = getCookieByName(MAIN_DATA_COOKIE_NAME);
        if(res){
            return JSON.parse(res) as cookieStoreType;
        }else{
            return null;
        }
    }

    const logout = () => {
        Logging.log(`UseManageWorkspacesAndOrganizations - logout() fired (user logout)`);
        dispatch(cleanUpForbiddenWrapperData());
        dispatch(eraseData()); //clean inner store
        deleteCookieByName(MAIN_DATA_COOKIE_NAME); //clean cookies
        dispatch(logoutAction()); //send request to back to logout and clean auth cookie
        history.push(PATH_LOCAL_LOGIN); //go to login
        Logging.log(`UseManageWorkspacesAndOrganizations - logout() complete`);
    }

    const set2FaComplete = () => {
        const newData = {...AllMultiStoreData, is2FaAuthComplete: true};
        setCookieData(newData); //updating cookies
        dispatch(setSharedWorkspacesAndOrganizationsData(newData)); //updating inner store
    }

    const setAuthComplete = (isGoogleAuthConfigComplete: boolean, isEmailVerified: boolean) => {
        // console.log(`setAuthComplete -> isGoogleAuthConfigComplete: ${isGoogleAuthConfigComplete}`);
        const newData:cookieStoreType = {...AllMultiStoreData, isAuthComplete: true, is2FaAuthComplete: false, isGoogleAuthConfigComplete, isEmailVerified};
        setCookieData(newData); //updating cookies
        dispatch(setSharedWorkspacesAndOrganizationsData(newData)); //updating inner store
    }

    const setGoogle2FaNotComplete = (flag: boolean) => {
        const newData:cookieStoreType = {...AllMultiStoreData, isAuthComplete: true, is2FaAuthComplete: true, isGoogleAuthConfigComplete: flag};
        setCookieData(newData); //updating cookies
        dispatch(setSharedWorkspacesAndOrganizationsData(newData)); //updating inner store
    }

    const eraseSelectedWorkspaceAndOrgInCookiesAndStore = () => {
        const currData = getCookieData();
        if(currData){
            const newData = {...currData, selectedOrganization: null, selectedWorkspace: null};
            setCookieData(newData);
            dispatch(setSharedWorkspacesAndOrganizationsData(newData));
        }
    }

    return{
        setCurrentWorkspaceAndOrganization,
        setCurrentWorkspaceAndOrganizationAfterOrganizationCreate,
        setUser,
        currData: AllMultiStoreData,
        getCookieData,
        logout,
        setCookieData,
        user: AllMultiStoreData.currentUser,
        set2FaComplete,
        setAuthComplete,
        deleteCookieByName,
        workspaceId: AllMultiStoreData.selectedWorkspace?.id || null,
        organizationId: AllMultiStoreData.selectedOrganization?.id || null,
        subscription: {
            isFree: AllMultiStoreData.selectedOrganization?.subscription === 'SUBSCRIPTION_FREE' || false,
            isBusiness: AllMultiStoreData.selectedOrganization?.subscription === 'SUBSCRIPTION_BUSINESS' || false,
            isBusinessPlus: AllMultiStoreData.selectedOrganization?.subscription === 'SUBSCRIPTION_BUSINESS_PLUS' || false,
            isEnterprise: AllMultiStoreData.selectedOrganization?.subscription === 'SUBSCRIPTION_ENTERPRISE' || false,
            isConsultant: AllMultiStoreData.selectedOrganization?.subscription === 'SUBSCRIPTION_CONSULTANT' || false,
            isAuditor: AllMultiStoreData.selectedOrganization?.subscription === 'SUBSCRIPTION_AUDITOR' || false,
        },
        setGoogle2FaNotComplete,
        isGoogleAuthConfigComplete: AllMultiStoreData.isGoogleAuthConfigComplete,
        isEmailVerified: AllMultiStoreData.isEmailVerified,
        handleSaveToLocalStorage,
        eraseSelectedWorkspaceAndOrgInCookiesAndStore
    }
}