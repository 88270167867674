import {PATH_SERVER} from "../../../constants";
import {print} from "graphql";
import {gql} from "@apollo/react-hooks";
import {AxiosResponse} from "axios";
import axiosSync from "../../../utils/axios/axios"
import {buildCommonHeader} from "../../../utils/builders";

export const getFile = async (fileId: string, fileName: string, workspaceId: string) => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query downloadEvidenceFile($fileId: String!, $workspaceId: String!, $fileName: String!) {
                        downloadEvidenceFile (fileId: $fileId, fileName: $fileName, workspaceId: $workspaceId) {
                            filename
                            filetype
                            file
                        }
                    }
                `),
                variables: {
                    fileId,
                    fileName,
                    workspaceId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.downloadEvidenceFile as {file: string, filename: string, filetype: string};
    })
)