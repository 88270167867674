import {PageWrapper} from "../../../../../newShared/components/Layouts";
import React from "react";
import {TemplateCard} from "../../components/templateCard";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {GenericFiltersArray} from "../../../../../newShared/components/genericFilter";
import {useTemplatesList} from "../../hooks/useTemplatesList";
import {Box, Typography} from "@mui/material";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TrainingPreviewDialog} from "../../components/dialogs/trainingPreview";
import {CreateEditTrainingDialog} from "../../components/dialogs/createEditTrainingDialog";

export const TemplatesList = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.pathTemplates'});

    const {
        templates,
        fetchPaging,
        onScroll,
        scrollRef,
        filtersConfig,
        isEmptyResultByFilter,
        handleOpenPreview,
        useTemplateDialog,
        isLoadingTemplatePreview,
        isLoadingUseTemplate,
        isLoadingList,
    } = useTemplatesList();

    return (
        <PageWrapper>

            <PageTitleAndActions title={('Templates')} actions={[]} margin={'0 0 8px 0'} />

            <GenericFiltersArray
                configs={filtersConfig}
                fetchResultWithSelectedFilters={(search, filters, signal) => fetchPaging(0, 10, filters, search, true)}
                isAddFilters={false}
            />

            {isEmptyResultByFilter && <Typography sx={{mt: '16px'}} variant={'*bodyText1'}>{t('No results were found for your search query.')}</Typography>}

            <Box onScroll={onScroll} ref={scrollRef}
                 sx={({breakpoints}) => ({
                     mt: '16px', mb: '16px', overflow: 'auto', flexGrow: 1, display: 'grid', gridTemplateColumns: 'repeat(3, auto)', gridAutoRows: 'minmax(278px, max-content)', gap: '16px', alignItems: 'stretch',
                     [breakpoints.down(1100)]: {gridTemplateColumns: 'repeat(2, auto)'},
                     [breakpoints.down(750)]: {gridTemplateColumns: 'repeat(1, auto)'},
                 })}>

                {templates.map((e, i) => (
                    <TemplateCard key={e.id} template={e}
                                  isLoadingPreview={isLoadingTemplatePreview === e.id}
                                  isLoadingUse={isLoadingUseTemplate === e.id}
                                  handlePreview={() => handleOpenPreview(e.id)}
                                  handleUseTemplate={() => useTemplateDialog.handleOpen(e.id)}
                    />
                ))}
                {isLoadingList && <TemplateCard key={'loading'} template={null} handleUseTemplate={() => null} handlePreview={() => null} />}

            </Box>


            <TrainingPreviewDialog />
            <CreateEditTrainingDialog isOpen={useTemplateDialog.isOpen} handleClose={useTemplateDialog.handleClose} />
        </PageWrapper>
    )
}