//CORE
import React, {FC} from "react";
import {Redirect, Route, Switch} from "react-router";

//CONSTS
import {
    BUILDER_WITH_FRAME,
    BUILDER_WITH_FRAME_AND_CONTROL,
    CONTROLS,
    ROOT_CONTROLS,
    ROOT_EVIDENCES,
    ROOT_FRAMEWORKS
} from "./constants";
import {FrameworksList} from "./pages/frameworskList";
import {FrameworkExact} from "./pages/frameworkExact";
import {ControlExact} from "./pages/controlExact";
import {ControlsList} from "./pages/controlsList";
import {EvidencesList} from "./pages/evidencesList";

//COMPONENTS
// import {Evidences} from "./pages/Evidences";

export const Routes: FC = () => {
    return (
        <Switch>
            <Route exact path={ROOT_FRAMEWORKS} component={FrameworksList}/>
            <Route exact path={BUILDER_WITH_FRAME} component={FrameworkExact}/>
            <Route exact path={BUILDER_WITH_FRAME_AND_CONTROL} component={ControlExact}/>

            <Route exact path={ROOT_CONTROLS} component={ControlsList}/>
            <Route exact path={CONTROLS} component={ControlExact}/>

            <Route exact path={ROOT_EVIDENCES} component={EvidencesList}/>
            {/*<Route path={ROOT_POLICIES} exact component={Policies}/>*/}

            {/*<Route path={ROOT_COLLECTORS} exact component={Collectors}/>*/}

            {/*<Route path={ROOT_COLLECTORS_CREATE} exact component={CreateCollector}/>*/}

            <Redirect to={ROOT_FRAMEWORKS}/>
        </Switch>
    );
}