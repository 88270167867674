import React from "react";
import {useReleaseRiskRegistry} from "../../../hooks/dialogsHooks/useReleaseRiskRegistry";
import {ReleaseDialog} from "../../../../../../newShared/components/releaseDialog";

export const ReleaseRisks = () => {
    const {
        isOpen,
        handleClose,
        isLoadingRelease,
        handleRelease,
    } = useReleaseRiskRegistry();

    return (
        <ReleaseDialog isOpen={isOpen} handleClose={handleClose} isSubmittingLoading={isLoadingRelease}
                       handleSubmit={({evidence, review, sign, read}) => {
                           handleRelease({...evidence, readers: read, reviewers: review, signers: sign})
                       }}
        />
    )
}