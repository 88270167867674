import {PATH_LOCAL_VULNERABILITY, PATH_LOCAL_VULNERABILITY_SCANNED} from "../../../newShared/constants";

export const OPENVAS_SCAN_TYPE = 'OPENVAS';
export const OWASP_SCAN_TYPE = 'OWASP_ZAP_ACTIVE';
export const NMAP_SCAN_TYPE = 'NMAP';
export const SSL_SCAN_TYPE = 'SSLYZE';

//paths
export const PATH_LOCAL_VULNERABILITY_NEW = `${PATH_LOCAL_VULNERABILITY}/new`;
export const PATH_LOCAL_VULNERABILITY_ID = `${PATH_LOCAL_VULNERABILITY_SCANNED}/:id`;
export const PATH_LOCAL_VULNERABILITY_EDIT = `${PATH_LOCAL_VULNERABILITY_SCANNED}/edit/:id`;

//utils

//eslint-disable-next-line
export const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
//eslint-disable-next-line
export const nakedDomainRegex = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.([a-z]{2,6}).+/i;

export const DEFAULT_COUNT = 15;
export const DATE_FORMAT_WITH_TIME = 'D/M/YYYY hh:mm';
