import {Skeleton} from "@mui/material";


export const ExactVendorLoading = () => {
    return(
        <>
            <Skeleton variant="rectangular" width={180} height={30} sx={{margin: '24px 0 0 0'}}/>
            <Skeleton variant="rectangular" width={400} height={24} sx={{margin: '32px 0 0 0'}}/>
            <Skeleton variant="rectangular" width={400} height={24} sx={{margin: '24px 0 0 0'}}/>
            <Skeleton variant="rectangular" width={400} height={24} sx={{margin: '24px 0 0 0'}}/>
            <Skeleton variant="rectangular" width={400} height={24} sx={{margin: '24px 0 0 0'}}/>
            <Skeleton variant="rectangular" width={400} height={24} sx={{margin: '24px 0 0 0'}}/>

        </>
    )
}