import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {minMaxLoadedPageDefault} from "../../../../newShared/components/genericTable/constants";
import {TPageInfo} from "../../../../newShared/types";
import {minMaxLoadedPageType} from "../../../../newShared/components/genericTable/types";
import {defaultPageInfo} from "../constants";
import {
    createTrainingAction,
    mainGetSystemCoversAction,
    mainTrainingsAcceptValidationReviewByAssignmentIdAndExamTypeAction,
    mainTrainingsCancelTrainingAssignmentAction,
    mainTrainingsChangeExamNameAction,
    mainTrainingsChangeTrainingNameAction,
    mainTrainingsChangeTrainingStatusAction,
    mainTrainingsCreateExamAction,
    mainTrainingsCreateTrainingAssignmentAction,
    mainTrainingsGetAssignmentDetailsAction,
    mainTrainingsGetAssignmentsWithFilterPaginationAction,
    mainTrainingsGetExamByIdAndVersionAction,
    mainTrainingsGetExamCategoriesAutocompleteAction,
    mainTrainingsGetExamsAutocompleteAction,
    mainTrainingsGetExamsWithFilterPaginationAction,
    mainTrainingsGetQuestionsFullDataListForImportAction,
    mainTrainingsGetQuestionsShortDataListForImportWithFilterPaginationAction,
    mainTrainingsGetSectionsFullDataListForImportAction,
    mainTrainingsGetSectionsShortDataListForImportWithFilterPaginationAction,
    mainTrainingsGetTrainingByIdAndVersionAction,
    mainTrainingsGetTrainingsAutocompleteAction,
    mainTrainingsGetTrainingsCategoriesAutocompleteAction,
    mainTrainingsGetTrainingsWithFilterPaginationAction,
    mainTrainingsGetTrainingTemplateByIdAction,
    mainTrainingsGetTrainingTemplatesWithFilterPaginationAction,
    mainTrainingsMoveExamToArchiveAction,
    mainTrainingsRestoreExamFromArchiveAction,
    mainTrainingsUpdateExamAction,
    mainTrainingUploadFileAction,
    mainUploadCoverImageAction,
    updateTrainingAction
} from "./actions";

import {handlePagination} from "../../../../newShared/components/genericTable/helpers";
import {addSetAllDialogActions} from "../../../../newShared/actions/setDialogAction";
import {
    MainTrainingAssignmentModel,
    MainTrainingExamModel,
    MainTrainingExamStatus,
    MainTrainingModel,
    MainTrainingsGetExamsAutocomplete,
    MainTrainingsGetTrainingsAutocomplete,
    MainTrainingShortAssignmentModel,
    MainTrainingShortTemplateModel,
    MainTrainingsQuestionsShortDataForImportResponseModel,
    MainTrainingsSectionsShortDataForImportResponseModel,
    MainTrainingTemplateModel,
    QuestionShortDataModel,
    SectionShortDataModel,
    TrainingAssignmentStatus
} from "../../../../newShared/GQLTypes";
import {addDownloadFileActionCases} from "../../../../newShared/actions/downloadFile";
import {MainTrainingExamFormik} from "../types";
import {setPaginationInStore} from "../../../../newShared/hooks/useScroll/helpers";


export type trainingsInitialState = {
    trainings: {
        trainings: MainTrainingModel[];
        pageInfo: TPageInfo;
        minMaxLoadedPage: minMaxLoadedPageType;
        selectedTraining: MainTrainingModel | null;
    },

    templates: {
        templates: MainTrainingShortTemplateModel[];
        pageInfo: TPageInfo;
        selectedTemplate: MainTrainingTemplateModel | null;
    },

    sectionsToAdd: {
        shortDataSections: MainTrainingsSectionsShortDataForImportResponseModel[],
        pageInfo: TPageInfo;
        selectedSections: SectionShortDataModel[],
    },
    questionsToAdd: {
        shortDataQuestions: MainTrainingsQuestionsShortDataForImportResponseModel[],
        pageInfo: TPageInfo;
        selectedQuestions: QuestionShortDataModel[],
    },

    exams: {
        exams: MainTrainingExamModel[];
        pageInfo: TPageInfo;
        minMaxLoadedPage: minMaxLoadedPageType;
        selectedExam: MainTrainingExamModel | null;
    },

    assignments: {
        assignments: MainTrainingShortAssignmentModel[],
        pageInfo: TPageInfo;
        minMaxLoadedPage: minMaxLoadedPageType;

        selectedAssignment: MainTrainingAssignmentModel | null,
    }


    loadings: {
        trainingsList: boolean;
        trainingExact: boolean;
        trainingCreate: boolean;
        trainingUpdate: boolean;
        getTrainingCategories: boolean;
        examsList: boolean;
        examExact: boolean;
        examCreate: boolean;
        examUpdate: boolean;
        changeTrainingName: boolean;
        changeTrainingStatus: boolean;
        changeExamName: boolean;
        changeExamStatus: boolean;
        fileUpload: boolean;
        isDownloadingFile: string[];
        getExamCategories: boolean;
        getSystemCovers: boolean;
        uploadCoverImage: boolean;

        assignmentsList: boolean;
        assignmentsExact: boolean;
        assignmentCreate: boolean;
        assignmentCancel: boolean;
        sectionsToAdd: boolean,
        fullDataSections: boolean,
        questionsToAdd: boolean,
        fullDataQuestions: boolean,
        getTrainingsAutocomplete: boolean;
        getExamsAutocomplete: boolean;
        validateExamAnswers: boolean;

        templateList: boolean;
        templateExact: boolean;
    },

    autocompleteStorage: {
        trainingCategories: string[],
        examCategories: string[],
        exams: MainTrainingsGetExamsAutocomplete[],
        trainings: MainTrainingsGetTrainingsAutocomplete[],
    },

    dialogs: {
        // createEditTrainingDialog: {
        //     isOpen: boolean,
        //     initialTraining: MainTrainingModel | null,
        // },
        createExam: {
            isOpen: boolean;
        },
        trainingPreview: {
            isOpen: boolean,
            training: Partial<MainTrainingModel> | null,
        },
        addExistingSections: {
            isOpen: boolean,
        },
        addExistingQuestions: {
            isOpen: boolean,
        },
        examPreview: {
            exam: MainTrainingExamFormik | null,
        },
        createAssignment: {
            isOpen: boolean;
            training: MainTrainingModel | null,
            exam: MainTrainingExamModel | null,
        },
        cancelAssignment: {
            isOpen: boolean,
        },
        examResultDialog: {
            assignment: MainTrainingAssignmentModel | null,
        },
        assignmentHistory: {
            isOpen: boolean
        }

        validateExamAnswers: {
            isOpen: boolean;
        },
        templatePreview: {
            isOpen: boolean;
            templateId: string | null,
        },
        useTemplate: {
            isOpen: boolean;
            templateId: string | null,
        },
    }

}

export const initialState: trainingsInitialState = {

    trainings: {
        trainings: [],
        pageInfo: defaultPageInfo,
        minMaxLoadedPage: minMaxLoadedPageDefault,
        selectedTraining: null,
    },

    exams: {
        exams: [],
        pageInfo: defaultPageInfo,
        minMaxLoadedPage: minMaxLoadedPageDefault,
        selectedExam: null,
    },

    assignments: {
        assignments: [],
        pageInfo: defaultPageInfo,
        minMaxLoadedPage: minMaxLoadedPageDefault,

        selectedAssignment: null,
    },

    templates: {
        templates: [],
        pageInfo: defaultPageInfo,
        selectedTemplate: null,
    },

    sectionsToAdd: {
        shortDataSections: [],
        pageInfo: defaultPageInfo,
        selectedSections: [],
    },
    questionsToAdd: {
        shortDataQuestions: [],
        pageInfo: defaultPageInfo,
        selectedQuestions: [],
    },

    loadings: {
        trainingsList: false,
        trainingExact: false,
        trainingCreate: false,
        trainingUpdate: false,
        getTrainingCategories: false,
        examsList: false,
        examExact: false,
        examCreate: false,
        examUpdate: false,
        changeTrainingName: false,
        changeTrainingStatus: false,
        changeExamName: false,
        changeExamStatus: false,
        fileUpload: false,
        isDownloadingFile: [],
        getExamCategories: false,
        getSystemCovers: false,
        uploadCoverImage: false,
        assignmentsList: false,
        assignmentsExact: false,
        assignmentCreate: false,
        assignmentCancel: false,
        sectionsToAdd: false,
        fullDataSections: false,
        questionsToAdd: false,
        fullDataQuestions: false,
        getTrainingsAutocomplete: false,
        getExamsAutocomplete: false,
        validateExamAnswers: false,
        templateList: false,
        templateExact: false,
    },

    autocompleteStorage: {
        trainingCategories: [],
        examCategories: [],
        exams: [],
        trainings: [],
    },

    dialogs: {
        // createEditTrainingDialog: {
        //     isOpen: false,
        //     initialTraining: null,
        // },
        createExam: {
            isOpen: false,
        },
        trainingPreview: {
            isOpen: false,
            training: null,
        },
        examPreview: {
            exam: null,
        },
        createAssignment: {
            isOpen: false,
            training: null,
            exam: null,
        },
        cancelAssignment: {
            isOpen: false,
        },
        addExistingSections: {
            isOpen: false,
        },
        addExistingQuestions: {
            isOpen: false,
        },
        examResultDialog: {
            assignment: null
        },
        assignmentHistory: {
            isOpen: false
        },
        validateExamAnswers: {
            isOpen: false,
        },
        templatePreview: {
            isOpen: false,
            templateId: null,
        },
        useTemplate: {
            isOpen: false,
            templateId: null,
        },
    },
}


export const trainingsSlice = createSlice({
    name: 'trainings',
    initialState,
    reducers: {
        ...addSetAllDialogActions(initialState),
        selectExistingSection: (slice, { payload }: {payload: SectionShortDataModel}) => {
            if(!slice.sectionsToAdd.selectedSections.some(e => e.sectionId === payload.sectionId)) {
                slice.sectionsToAdd.selectedSections.push(payload);
            }
        },
        deselectExistingSection: (slice, { payload }: {payload: SectionShortDataModel}) => {
            slice.sectionsToAdd.selectedSections = slice.sectionsToAdd.selectedSections.filter(item => item.sectionId !== payload.sectionId);

        },
        eraseSections: (slice) => {
            slice.sectionsToAdd.shortDataSections = [];
            slice.sectionsToAdd.selectedSections = [];
            slice.sectionsToAdd.pageInfo = defaultPageInfo;
        },
        selectExistingQuestion: (slice, { payload }: {payload: QuestionShortDataModel}) => {
            if(!slice.questionsToAdd.selectedQuestions.some(e => e.questionId === payload.questionId)) {
                slice.questionsToAdd.selectedQuestions.push(payload);
            }
        },
        deselectExistingQuestion: (slice, { payload }: {payload: QuestionShortDataModel}) => {
            slice.questionsToAdd.selectedQuestions = slice.questionsToAdd.selectedQuestions.filter(item => item.questionId !== payload.questionId);

        },
        eraseQuestions: (slice) => {
            slice.questionsToAdd.shortDataQuestions = [];
            slice.questionsToAdd.selectedQuestions = [];
            slice.questionsToAdd.pageInfo = defaultPageInfo;
        },
        replaceTrainingsMinMaxLoadedPage: (slice, { payload }: { payload: minMaxLoadedPageType }) => {
            slice.trainings.minMaxLoadedPage = payload;
        },
        eraseTrainings: (slice) => {
            slice.trainings.trainings = [];
            slice.trainings.pageInfo = defaultPageInfo;
        },
        eraseSelectedTrainingsAction: (slice) => {
            slice.trainings.selectedTraining = null;
        },
        replaceExamsMinMaxLoadedPage: (slice, { payload }: { payload: minMaxLoadedPageType }) => {
            slice.exams.minMaxLoadedPage = payload;
        },
        replaceAssignmentsMinMaxLoadedPage: (slice, { payload }: { payload: minMaxLoadedPageType }) => {
            slice.assignments.minMaxLoadedPage = payload;
        },
        eraseExams: (slice) => {
            slice.exams.exams = [];
            slice.exams.pageInfo = defaultPageInfo;
        },
        eraseSelectedExamAction: (slice) => {
            slice.exams.selectedExam = null;
        },

        eraseAssignments: (slice) => {
            slice.assignments.assignments = [];
            slice.assignments.pageInfo = defaultPageInfo;
        },
        eraseSelectedAssignmentAction: (slice) => {
            slice.assignments.selectedAssignment = null;
        },
        eraseTemplates: (slice) => {
            slice.templates.templates = [];
            slice.templates.pageInfo = defaultPageInfo;
        },
        eraseSelectedTemplate: (slice) => {
            slice.templates.selectedTemplate = null;
        },

        cleanUp: (slice) => initialState,
    },
    extraReducers: (builder) => {
        addDownloadFileActionCases(builder);

        builder
            //mainTrainingsGetTrainingsWithFilterPaginationAction
            .addCase(mainTrainingsGetTrainingsWithFilterPaginationAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.trainingsList = true;

                if (clean) {
                    slice.trainings.trainings = initialState.trainings.trainings;
                    slice.trainings.pageInfo = initialState.trainings.pageInfo;
                    slice.trainings.minMaxLoadedPage = initialState.trainings.minMaxLoadedPage;
                }
            })
            .addCase(mainTrainingsGetTrainingsWithFilterPaginationAction.rejected, (slice) => {
                    slice.loadings.trainingsList = false;
            })
            .addCase(mainTrainingsGetTrainingsWithFilterPaginationAction.fulfilled,(slice, { payload }) => {
                slice.loadings.trainingsList = false;

                const { results, maxLoadedPage, minLoadedPage } =
                    handlePagination<MainTrainingModel>(
                        slice.trainings.trainings,
                        slice.trainings.pageInfo,
                        payload.result,
                        payload.pageInfo,
                        slice.trainings.minMaxLoadedPage.minLoadedPage,
                        slice.trainings.minMaxLoadedPage.maxLoadedPage,
                        "id"
                    );

                slice.trainings.trainings = results;
                slice.trainings.minMaxLoadedPage = {
                    minLoadedPage,
                    maxLoadedPage,
                };
                slice.trainings.pageInfo = payload.pageInfo;
            })

            //mainTrainingsGetTrainingByIdAndVersionAction
            .addCase(mainTrainingsGetTrainingByIdAndVersionAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.trainingExact = true;
            })
            .addCase(mainTrainingsGetTrainingByIdAndVersionAction.rejected, (slice) => {
                slice.loadings.trainingExact = false;
            })
            .addCase(mainTrainingsGetTrainingByIdAndVersionAction.fulfilled,(slice, { payload }) => {
                slice.loadings.trainingExact = false;
                slice.trainings.selectedTraining = payload;
            })

        //mainTrainingsGetExamsWithFilterPaginationAction
            .addCase(mainTrainingsGetExamsWithFilterPaginationAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.examsList = true;

                if (clean) {
                    slice.exams.exams = initialState.exams.exams;
                    slice.exams.pageInfo = initialState.exams.pageInfo;
                    slice.exams.minMaxLoadedPage = initialState.exams.minMaxLoadedPage;
                }
            })
            .addCase(mainTrainingsGetExamsWithFilterPaginationAction.rejected, (slice) => {
                slice.loadings.examsList = false;
            })
            .addCase(mainTrainingsGetExamsWithFilterPaginationAction.fulfilled,(slice, { payload }) => {
                slice.loadings.examsList = false;

                const { results, maxLoadedPage, minLoadedPage } =
                    handlePagination<MainTrainingExamModel>(
                        slice.exams.exams,
                        slice.exams.pageInfo,
                        payload.result,
                        payload.pageInfo,
                        slice.exams.minMaxLoadedPage.minLoadedPage,
                        slice.exams.minMaxLoadedPage.maxLoadedPage,
                        "id"
                    );

                slice.exams.exams = results;
                slice.exams.minMaxLoadedPage = {
                    minLoadedPage,
                    maxLoadedPage,
                };
                slice.exams.pageInfo = payload.pageInfo;
            })
        //mainTrainingsGetExamByIdAndVersionAction
            .addCase(mainTrainingsGetExamByIdAndVersionAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.examExact = true;
            })
            .addCase(mainTrainingsGetExamByIdAndVersionAction.rejected, (slice) => {
                slice.loadings.examExact = false;
            })
            .addCase(mainTrainingsGetExamByIdAndVersionAction.fulfilled,(slice, { payload }) => {
                slice.loadings.examExact = false;
                slice.exams.selectedExam = payload;
            })

        //mainTrainingsCreateExamAction
            .addCase(mainTrainingsCreateExamAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.examCreate = true;
            })
            .addCase(mainTrainingsCreateExamAction.rejected, (slice) => {
                slice.loadings.examCreate = false;
            })
            .addCase(mainTrainingsCreateExamAction.fulfilled,(slice, { payload }) => {
                slice.loadings.examCreate = false;
                slice.dialogs.createExam = initialState.dialogs.createExam;
            })

        //mainTrainingsUpdateExamAction
            .addCase(mainTrainingsUpdateExamAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.examUpdate = true;
            })
            .addCase(mainTrainingsUpdateExamAction.rejected, (slice) => {
                slice.loadings.examUpdate = false;
            })
            .addCase(mainTrainingsUpdateExamAction.fulfilled,(slice, { payload }) => {
                slice.loadings.examUpdate = false;
                slice.exams.selectedExam = payload;
                slice.dialogs.createExam = initialState.dialogs.createExam;
            })

        //mainTrainingsGetTrainingsCategoriesAutocompleteAction
            .addCase(mainTrainingsGetTrainingsCategoriesAutocompleteAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.getTrainingCategories = true;
                if(clean){
                    slice.autocompleteStorage.trainingCategories = [];
                }
            })
            .addCase(mainTrainingsGetTrainingsCategoriesAutocompleteAction.rejected, (slice) => {
                slice.loadings.getTrainingCategories = false;
            })
            .addCase(mainTrainingsGetTrainingsCategoriesAutocompleteAction.fulfilled,(slice, { payload }) => {
                slice.loadings.getTrainingCategories = false;
                slice.autocompleteStorage.trainingCategories = payload.result;
            })

            //mainTrainingsChangeTrainingNameAction
            .addCase(mainTrainingsChangeTrainingNameAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.changeTrainingName = true;
            })
            .addCase(mainTrainingsChangeTrainingNameAction.rejected, (slice) => {
                slice.loadings.changeTrainingName = false;
            })
            .addCase(mainTrainingsChangeTrainingNameAction.fulfilled,(slice, { payload, meta }) => {
                slice.loadings.changeTrainingName = false;
                if (slice.trainings.selectedTraining) {
                    slice.trainings.selectedTraining.name = meta.arg.data.name;
                }
            })

            //mainTrainingsChangeTrainingStatusAction
            .addCase(mainTrainingsChangeTrainingStatusAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.changeTrainingStatus = true;
            })
            .addCase(mainTrainingsChangeTrainingStatusAction.rejected, (slice) => {
                slice.loadings.changeTrainingStatus = false;
            })
            .addCase(mainTrainingsChangeTrainingStatusAction.fulfilled,(slice, { payload, meta }) => {
                slice.loadings.changeTrainingStatus = false;
                if (slice.trainings.selectedTraining) {
                    slice.trainings.selectedTraining.status = meta.arg.data.status;
                }
            })

            //mainTrainingsChangeExamNameAction
            .addCase(mainTrainingsChangeExamNameAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.changeExamName = true;
            })
            .addCase(mainTrainingsChangeExamNameAction.rejected, (slice) => {
                slice.loadings.changeExamName = false;
            })
            .addCase(mainTrainingsChangeExamNameAction.fulfilled,(slice, { payload, meta }) => {
                slice.loadings.changeExamName = false;
                if (slice.exams.selectedExam) {
                    slice.exams.selectedExam.name = meta.arg.data.name
                }
            })
        //mainTrainingUploadFileAction
            .addCase(mainTrainingUploadFileAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.fileUpload = true;
            })
            .addCase(mainTrainingUploadFileAction.rejected, (slice) => {
                slice.loadings.fileUpload = false;
            })
            .addCase(mainTrainingUploadFileAction.fulfilled,(slice, { payload, meta }) => {
                slice.loadings.fileUpload = false;
            })
        //createTrainingAction
            .addCase(createTrainingAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.trainingCreate = true;
            })
            .addCase(createTrainingAction.rejected, (slice) => {
                slice.loadings.trainingCreate = false;
            })
            .addCase(createTrainingAction.fulfilled,(slice, { payload, meta }) => {
                slice.loadings.trainingCreate = false;
            })

            //mainTrainingsGetExamCategoriesAutocompleteAction
            .addCase(mainTrainingsGetExamCategoriesAutocompleteAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.getExamCategories = true;
                if(clean){
                    slice.autocompleteStorage.examCategories = [];
                }
            })
            .addCase(mainTrainingsGetExamCategoriesAutocompleteAction.rejected, (slice) => {
                slice.loadings.getExamCategories = false;
            })
            .addCase(mainTrainingsGetExamCategoriesAutocompleteAction.fulfilled,(slice, { payload }) => {
                slice.loadings.getExamCategories = false;
                slice.autocompleteStorage.examCategories = payload.result;
            })
        //updateTrainingAction
            .addCase(updateTrainingAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.trainingUpdate = true;
            })
            .addCase(updateTrainingAction.rejected, (slice) => {
                slice.loadings.trainingUpdate = false;
            })
            .addCase(updateTrainingAction.fulfilled,(slice, { payload }) => {
                slice.loadings.trainingUpdate = false;
                slice.trainings.selectedTraining = payload;
            })
        //
            //mainTrainingsMoveExamToArchiveAction
            .addCase(mainTrainingsMoveExamToArchiveAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.changeExamStatus = true;
            })
            .addCase(mainTrainingsMoveExamToArchiveAction.rejected, (slice) => {
                slice.loadings.changeExamStatus = false;
            })
            .addCase(mainTrainingsMoveExamToArchiveAction.fulfilled,(slice, { payload, meta }) => {
                slice.loadings.changeExamStatus = false;
                if (slice.exams.selectedExam) {
                    slice.exams.selectedExam.status = MainTrainingExamStatus.Archived;
                }
            })

            // mainTrainingsRestoreExamFromArchiveAction
            .addCase(mainTrainingsRestoreExamFromArchiveAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.changeExamStatus = true;
            })
            .addCase(mainTrainingsRestoreExamFromArchiveAction.rejected, (slice) => {
                slice.loadings.changeExamStatus = false;
            })
            .addCase(mainTrainingsRestoreExamFromArchiveAction.fulfilled,(slice, { payload, meta }) => {
                slice.loadings.changeExamStatus = false;
                if (slice.exams.selectedExam) {
                    slice.exams.selectedExam.status = MainTrainingExamStatus.Active;
                }
            })

        //mainTrainingsGetAssignmentsWithFilterPaginationAction
            .addCase(mainTrainingsGetAssignmentsWithFilterPaginationAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.assignmentsList = true;

                if (clean) {
                    slice.assignments.assignments = initialState.assignments.assignments;
                    slice.assignments.pageInfo = initialState.assignments.pageInfo;
                    slice.assignments.minMaxLoadedPage = initialState.assignments.minMaxLoadedPage;
                }
            })
            .addCase(mainTrainingsGetAssignmentsWithFilterPaginationAction.rejected, (slice) => {
                slice.loadings.assignmentsList = false;
            })
            .addCase(mainTrainingsGetAssignmentsWithFilterPaginationAction.fulfilled,(slice, { payload }) => {
                slice.loadings.assignmentsList = false;

                const { results, maxLoadedPage, minLoadedPage } =
                    handlePagination<MainTrainingShortAssignmentModel>(
                        slice.assignments.assignments,
                        slice.assignments.pageInfo,
                        payload.result,
                        payload.pageInfo,
                        slice.assignments.minMaxLoadedPage.minLoadedPage,
                        slice.assignments.minMaxLoadedPage.maxLoadedPage,
                        "id"
                    );

                slice.assignments.assignments = results;
                slice.assignments.minMaxLoadedPage = {
                    minLoadedPage,
                    maxLoadedPage,
                };
                slice.assignments.pageInfo = payload.pageInfo;
            })
        // mainTrainingsGetAssignmentDetailsAction
            .addCase(mainTrainingsGetAssignmentDetailsAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.assignmentsExact = true;
            })
            .addCase(mainTrainingsGetAssignmentDetailsAction.rejected, (slice) => {
                slice.loadings.assignmentsExact = false;
            })
            .addCase(mainTrainingsGetAssignmentDetailsAction.fulfilled,(slice, { payload }) => {
                slice.loadings.assignmentsExact = false;
                slice.assignments.selectedAssignment = payload;
            })

            //mainTrainingsCreateTrainingAssignmentAction
            .addCase(mainTrainingsCreateTrainingAssignmentAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.assignmentCreate = true;
            })
            .addCase(mainTrainingsCreateTrainingAssignmentAction.rejected, (slice) => {
                slice.loadings.assignmentCreate = false;
                slice.dialogs.createAssignment = initialState.dialogs.createAssignment
            })
            .addCase(mainTrainingsCreateTrainingAssignmentAction.fulfilled,(slice, { payload }) => {
                slice.loadings.assignmentCreate = false;
                slice.dialogs.createAssignment = initialState.dialogs.createAssignment
            })
            //mainTrainingsCancelTrainingAssignmentAction
            .addCase(mainTrainingsCancelTrainingAssignmentAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.assignmentCancel = true;
            })
            .addCase(mainTrainingsCancelTrainingAssignmentAction.rejected, (slice) => {
                slice.loadings.assignmentCancel = false;
                slice.dialogs.cancelAssignment = initialState.dialogs.cancelAssignment;
            })
            .addCase(mainTrainingsCancelTrainingAssignmentAction.fulfilled,(slice, { payload }) => {
                slice.loadings.assignmentCancel = false;
                slice.dialogs.cancelAssignment = initialState.dialogs.cancelAssignment;
                if (slice.assignments.selectedAssignment) slice.assignments.selectedAssignment.status = TrainingAssignmentStatus.Cancelled;
            })

            //Sections short data
        //mainGetSystemCoversAction
            .addCase(mainGetSystemCoversAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.getSystemCovers = true;
            })
            .addCase(mainGetSystemCoversAction.rejected, (slice) => {
                slice.loadings.getSystemCovers = false;
            })
            .addCase(mainGetSystemCoversAction.fulfilled,(slice, { payload, meta }) => {
                slice.loadings.getSystemCovers = false;
            })
        //mainUploadCoverImageAction
            .addCase(mainUploadCoverImageAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.uploadCoverImage = true;
            })
            .addCase(mainUploadCoverImageAction.rejected, (slice) => {
                slice.loadings.uploadCoverImage = false;
            })
            .addCase(mainUploadCoverImageAction.fulfilled,(slice, { payload, meta }) => {
                slice.loadings.uploadCoverImage = false;
            })

            .addCase(mainTrainingsGetSectionsShortDataListForImportWithFilterPaginationAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.sectionsToAdd = true;

                if (clean) {
                    slice.sectionsToAdd.shortDataSections = initialState.sectionsToAdd.shortDataSections;
                    slice.sectionsToAdd.pageInfo = initialState.sectionsToAdd.pageInfo;
                }
            })
            .addCase(mainTrainingsGetSectionsShortDataListForImportWithFilterPaginationAction.rejected, (slice, {meta}) => {
                slice.loadings.sectionsToAdd = false;
            })
            .addCase(mainTrainingsGetSectionsShortDataListForImportWithFilterPaginationAction.fulfilled,(slice, { payload }) => {
                slice.loadings.sectionsToAdd = false;

                const {array, pageInfo} = setPaginationInStore<MainTrainingsSectionsShortDataForImportResponseModel>(
                    slice.sectionsToAdd.pageInfo,
                    slice.sectionsToAdd.shortDataSections,
                    payload.pageInfo,
                    payload.result,
                    false
                );
                slice.sectionsToAdd.shortDataSections = array.filter(e => e !== null) as MainTrainingsSectionsShortDataForImportResponseModel[];
                slice.sectionsToAdd.pageInfo = pageInfo;
            })
            //Sections full data
            .addCase(mainTrainingsGetSectionsFullDataListForImportAction.pending, (slice) => {
                slice.loadings.fullDataSections = true;
            })
            .addCase(mainTrainingsGetSectionsFullDataListForImportAction.rejected, (slice) => {
                slice.loadings.fullDataSections = false;
            })
            .addCase(mainTrainingsGetSectionsFullDataListForImportAction.fulfilled, (slice, {payload}) => {
                slice.loadings.fullDataSections = false;
            })
            //Questions full data
            .addCase(mainTrainingsGetQuestionsFullDataListForImportAction.pending, (slice) => {
                slice.loadings.fullDataQuestions = true;
            })
            .addCase(mainTrainingsGetQuestionsFullDataListForImportAction.rejected, (slice) => {
                slice.loadings.fullDataQuestions = false;
            })
            .addCase(mainTrainingsGetQuestionsFullDataListForImportAction.fulfilled, (slice, {payload}) => {
                slice.loadings.fullDataQuestions = false;
            })
            //Questions short data
            .addCase(mainTrainingsGetQuestionsShortDataListForImportWithFilterPaginationAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.questionsToAdd = true;

                if (clean) {
                    slice.questionsToAdd.shortDataQuestions = initialState.questionsToAdd.shortDataQuestions;
                    slice.questionsToAdd.pageInfo = initialState.questionsToAdd.pageInfo;
                }
            })
            .addCase(mainTrainingsGetQuestionsShortDataListForImportWithFilterPaginationAction.rejected, (slice, {meta}) => {
                slice.loadings.questionsToAdd = false;
            })
            .addCase(mainTrainingsGetQuestionsShortDataListForImportWithFilterPaginationAction.fulfilled,(slice, { payload }) => {
                slice.loadings.questionsToAdd = false;

                const {array, pageInfo} = setPaginationInStore<MainTrainingsQuestionsShortDataForImportResponseModel>(
                    slice.questionsToAdd.pageInfo,
                    slice.questionsToAdd.shortDataQuestions,
                    payload.pageInfo,
                    payload.result,
                    false
                );
                slice.questionsToAdd.shortDataQuestions = array.filter(e => e !== null) as MainTrainingsQuestionsShortDataForImportResponseModel[];
                slice.questionsToAdd.pageInfo = pageInfo;
            })

        //mainTrainingsGetExamsAutocompleteAction
            .addCase(mainTrainingsGetExamsAutocompleteAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.getExamsAutocomplete = true;
            })
            .addCase(mainTrainingsGetExamsAutocompleteAction.rejected, (slice) => {
                slice.loadings.getExamsAutocomplete = false;
            })
            .addCase(mainTrainingsGetExamsAutocompleteAction.fulfilled,(slice, { payload, meta }) => {
                slice.loadings.getExamsAutocomplete = false;
                slice.autocompleteStorage.exams = payload.result;
            })

            //mainTrainingsGetTrainingsAutocompleteAction
            .addCase(mainTrainingsGetTrainingsAutocompleteAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.getTrainingsAutocomplete = true;
            })
            .addCase(mainTrainingsGetTrainingsAutocompleteAction.rejected, (slice) => {
                slice.loadings.getTrainingsAutocomplete = false;
            })
            .addCase(mainTrainingsGetTrainingsAutocompleteAction.fulfilled,(slice, { payload, meta }) => {
                slice.loadings.getTrainingsAutocomplete = false;
                slice.autocompleteStorage.trainings = payload.result;
            })
            .addCase(mainTrainingsAcceptValidationReviewByAssignmentIdAndExamTypeAction.pending, (slice) => {
                slice.loadings.validateExamAnswers = true;
            })
            .addCase(mainTrainingsAcceptValidationReviewByAssignmentIdAndExamTypeAction.rejected, (slice) => {
                slice.loadings.validateExamAnswers = false;
            })
            .addCase(mainTrainingsAcceptValidationReviewByAssignmentIdAndExamTypeAction.fulfilled,(slice) => {
                slice.loadings.validateExamAnswers = false;
            })

        //mainTrainingsGetTrainingTemplatesWithFilterPaginationAction
            .addCase(mainTrainingsGetTrainingTemplatesWithFilterPaginationAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.templateList = true;

                if (clean) {
                    slice.templates.templates = initialState.templates.templates;
                    slice.templates.pageInfo = initialState.templates.pageInfo;
                }
            })
            .addCase(mainTrainingsGetTrainingTemplatesWithFilterPaginationAction.rejected, (slice) => {
                slice.loadings.templateList = false;
            })
            .addCase(mainTrainingsGetTrainingTemplatesWithFilterPaginationAction.fulfilled,(slice, { payload }) => {
                slice.loadings.templateList = false;

                const {array, pageInfo} = setPaginationInStore<MainTrainingShortTemplateModel>(
                    slice.templates.pageInfo,
                    slice.templates.templates,
                    payload.pageInfo,
                    payload.result,
                    false
                );
                slice.templates.templates = array.filter(e => e !== null) as MainTrainingShortTemplateModel[];
                slice.templates.pageInfo = pageInfo;

            })

        //mainTrainingsGetTrainingTemplateByIdAction
            .addCase(mainTrainingsGetTrainingTemplateByIdAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.templateExact = true;
            })
            .addCase(mainTrainingsGetTrainingTemplateByIdAction.rejected, (slice) => {
                slice.loadings.templateExact = false;
                slice.dialogs.templatePreview.templateId = null;
                slice.dialogs.useTemplate.templateId = null;
            })
            .addCase(mainTrainingsGetTrainingTemplateByIdAction.fulfilled,(slice, { payload, meta: {arg: {addictiveData}} }) => {
                slice.loadings.templateExact = false;
                slice.templates.selectedTemplate = payload;
                slice.dialogs.templatePreview.templateId = null;
                slice.dialogs.useTemplate.templateId = null;
                if (addictiveData?.type === 'PREVIEW') slice.dialogs.templatePreview.isOpen = true;
                if (addictiveData?.type === 'USE') slice.dialogs.useTemplate.isOpen = true;
            })

    }
});

export const {
    replaceTrainingsMinMaxLoadedPage,
    eraseTrainings,
    eraseSelectedTrainingsAction,
    replaceExamsMinMaxLoadedPage,
    eraseExams,
    replaceAssignmentsMinMaxLoadedPage,
    eraseAssignments,
    eraseSelectedAssignmentAction,
    eraseSelectedExamAction,
    setTrainingPreviewAction,
    setTemplatePreviewAction,
    setUseTemplateAction,
    cleanUp,

    setCreateExamAction,
    // setCreateEditTrainingDialogAction,
    setExamPreviewAction,
    setCreateAssignmentAction,
    setAddExistingSectionsAction,
    eraseSections,
    selectExistingSection,
    deselectExistingSection,
    setAddExistingQuestionsAction,
    deselectExistingQuestion,
    selectExistingQuestion,
    eraseQuestions,
    eraseTemplates,
    eraseSelectedTemplate,
    setExamResultDialogAction
} = trainingsSlice.actions;

export const trainingsReducer = trainingsSlice.reducer;

const selectSelf = (state: AppState): trainingsInitialState => state.trainings;

export const dialogs = createSelector(selectSelf, state => state.dialogs);
export const loadings = createSelector(selectSelf, state => state.loadings);
export const trainingsSelector = createSelector(selectSelf, state => state.trainings);
export const templatesSelector = createSelector(selectSelf, state => state.templates);
export const autocompleteStorage = createSelector(selectSelf, state => state.autocompleteStorage);

export const examsSelector = createSelector(selectSelf, state => state.exams);
export const assignmentsSelector = createSelector(selectSelf, state => state.assignments);
export const sectionsToAddSelector = createSelector(selectSelf, state => state.sectionsToAdd);
export const questionsToAddSelector = createSelector(selectSelf, state => state.questionsToAdd);
