import {Flex} from "../../../../../newShared/components/Layouts";
import {useEmployeesList} from "../../hooks/useEmployeesList";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import React from "react";
import {useMedia} from "../../../../../newShared/hooks/useMedia";

//icons
import GetAppIcon from "@mui/icons-material/GetApp";
import UploadIcon from "@mui/icons-material/Upload";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import {DeleteEmployeeDialog} from "../../components/dialogs/deleteEmployeeDialog";
import {ReleaseEmployees} from "../../components/dialogs/releaseEmployees";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
//
import {useGetEmployeesFilters} from "../../hooks/useGetEmployeesFilters";
import {GetEmployees} from "../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import {
    employees,
    employeesMaxLoadedPage,
    employeesMinLoadedPage,
    employeesPageInfo,
    eraseEmployees,
    loadings,
    replaceEmployeesMinMaxPage,
    setSelectedEmployee
} from "../../store/slice";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {EmployeeType} from "../../types";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {useHistory} from "react-router";
import {
    CITY_COLUMN_KEY,
    COUNTRY_COLUMN_KEY,
    DEP_COLUMN_KEY,
    DIVISION_COLUMN_KEY,
    EMP_TYPE_COLUMN_KEY,
    JOB_TITLE_COLUMN_KEY,
    LAST_JOB_START_DATE_COLUMN_KEY,
    LOCATION_COLUMN_KEY,
    MANAGER_COLUMN_KEY,
    PATH_LOCAL_EMPLOYEES_ID,
    PHONE_COLUMN_KEY,
    PHONE_NUMBER_CONTACT_TYPE,
    STREET_COLUMN_KEY,
    ZIP_COLUMN_KEY
} from "../../constants";
import {StatusChip} from "../../components/valueGetter";
import {Skeleton, Typography} from "@mui/material";
import {useImportCsv} from "../../hooks/dialogHooks/useImportCsv";
import {ImportCsvDialog} from "../../../../../newShared/components/csvImportDialog";
import {ExportCsvDialog} from "../../../../../newShared/components/csvExportDialog";
import colors from "../../../../../newShared/theme/colors";

export const EmployeesList = () => {
    const dispatch = useDispatch();
    const {t} = useMainTranslation('', {keyPrefix: 'pathHRManagement.list'});

    const {
        isLoadingImportCsv,
        isLoadingExportCsv,
        isLoadingFields,
        topBarActions,
        isShowingNoEmployees,
        isShowingNoEmployeesResult,
        handleGoToTree,
        handleDelete,
        exportCsv,
    } = useEmployeesList();
    const {isLessThen1050, isMobile} = useMedia();
    const {configs} = useGetEmployeesFilters();

    // useEffect(() => {
    //     dispatch(GetEmployees({data: {pageInfo: {page: 0, count: EMPLOYEES_LIST_DEFAULT_PAGING, filters: {}}, search: ''}}));
    // }, []);

    const history = useHistory();
    const _employees = useSelector(employees);
    const pageInfo = useSelector(employeesPageInfo);
    const isLoading = useSelector(loadings).getEmployeesList;
    const clearRows = () => dispatch(eraseEmployees());
    const minLoadedPage = useSelector(employeesMinLoadedPage);
    const maxLoadedPage = useSelector(employeesMaxLoadedPage);
    const setMinMaxLoadedPage = (data: minMaxLoadedPageType) => {dispatch(replaceEmployeesMinMaxPage(data));}

    // type additionalSearchPropsType = {isFromRene: string};
    // const {additionalSearchProps} = useGenericFiltersStorage<additionalSearchPropsType>();
    // additionalSearchProps && additionalSearchProps.isFromRene

    const importCsv = useImportCsv();

    return (
        <Flex w={'100%'} h={'100%'} direction={'column'} overflow={'hidden'}>
            <PageTitleAndActions
                title={t('Employees')}
                showMenuWhen={isLessThen1050}
                actions={[
                    {
                        title: t('Add new employee'),
                        onClick: topBarActions.handleAddEmployee,
                    },
                    {
                        title: t('Release'),
                        color: 'secondary',
                        disabled: isShowingNoEmployees,
                        onClick: topBarActions.handleReleaseEmployees,
                    },
                    {
                        title: t('Export CSV'),
                        variant: 'text',
                        disabled: isShowingNoEmployees  || isShowingNoEmployeesResult,
                        onClick: topBarActions.handleOpenExportCsv,
                        loading: isLoadingExportCsv,
                        icon: <GetAppIcon />,
                    },
                    {
                        title: t('Import CSV'),
                        variant: 'text',
                        onClick: topBarActions.handleImportCsv,
                        loading: isLoadingImportCsv,
                        icon: <UploadIcon />,
                    },
                    {
                        title: t('Org tree'),
                        variant: 'text',
                        onClick: topBarActions.goToOrgTree,
                        icon: <AccountTreeIcon />,
                    }
                ]}
            />

            <GenericTable<EmployeeType>
                id={'employeesList_'}
                columnsConfig={{totalName: 'Total employees', forceShowCardsWhen: isMobile}}
                paging={{
                    // additionalSearchProps: {isFromRene: 'hello'},
                    pageInfo,
                    isLoading,
                    minLoadedPage,
                    maxLoadedPage,
                    setMinMaxLoadedPage,
                    clearRows,
                    fetchPaging: (page: number, count: number, filters: CommonFilterType, search) => {
                        dispatch(GetEmployees({data: {pageInfo: {page, count, filters}, search}}));
                    }
                }}
                rowsConfig={{
                    rows: _employees,
                    customCellPaddings: '8px',
                    rowActionsConfig: [
                        {title: 'View in Org tree', onClick: handleGoToTree},
                        {title: 'Delete employee', onClick: handleDelete}
                    ],
                    getRowUniqueId: (row) => row.id,
                    onRowClick: (row) => {
                        dispatch(setSelectedEmployee(row));
                        history.push(PATH_LOCAL_EMPLOYEES_ID.replace(':id', row.id));
                    },
                    columnsConfigs: [
                        {key: "firstName", name: 'First name', default: true},
                        {key: 'lastName', name: 'Last name', default: true},
                        {key: 'employeeId', name: 'ID', default: true},
                        {key: JOB_TITLE_COLUMN_KEY, name: 'Job title', default: true, valueGetter: (row) => row.jobInfos.find(e => e.current)?.jobTitle.name},
                        {key: DEP_COLUMN_KEY, name: 'Department', default: true, valueGetter: (row) => row.jobInfos.find(e => e.current)?.department},
                        {key: PHONE_COLUMN_KEY, name: 'Phone', default: true, valueGetter: (row) => row.contactInfo.contacts.find(e => e.type === PHONE_NUMBER_CONTACT_TYPE)?.value},
                        {key: 'workEmail', name: 'Email', default: true},
                        {key: EMP_TYPE_COLUMN_KEY, name: 'Employment type', default: true, valueGetter: (row) => row.jobInfos.find(e => e.current)?.employmentType},
                        {key: COUNTRY_COLUMN_KEY, name: 'Country', valueGetter: (row) => row.contactInfo.address.country},
                        {key: CITY_COLUMN_KEY, name: 'City', valueGetter: (row) => row.contactInfo.address.city},
                        {key: STREET_COLUMN_KEY, name: 'Street', valueGetter: (row) => row.contactInfo.address.street},
                        {key: ZIP_COLUMN_KEY, name: 'ZIP', valueGetter: (row) => row.contactInfo.address.zip},
                        {key: LOCATION_COLUMN_KEY, name: 'Location', default: true, valueGetter: (row) => row.jobInfos.find(e => e.current)?.location},
                        {key: DIVISION_COLUMN_KEY, name: 'Division', valueGetter: (row) => row.jobInfos.find(e => e.current)?.division},
                        {key: MANAGER_COLUMN_KEY, name: 'Manager', default: true, valueGetter: (row) => row.jobInfos.find(e => e.current)?.manager?.name},
                        {key: LAST_JOB_START_DATE_COLUMN_KEY, name: 'Job start date', default: true, valueGetter: (row) => parseDateAuto(row.jobInfos.find(e => e.current)?.startDate)},
                        {key: 'hireDate', name: 'Hire date', default: true, valueGetter: (row) => parseDateAuto(row.hireDate)},
                        {key: 'status', name: 'Status', default: true, valueRenderer: (row) => <StatusChip status={row.status}/>, customSkeleton: <Skeleton width={94} height={32} variant={'rounded'}/>},
                        {key: 'collaborator', name: 'Collaborator', default: true, valueRenderer: (row) => row.collaborator?.publicId ? <Typography variant={'*bodyText2'} color={colors.text.success}>{'Connected'}</Typography> : '-'},
                        {key: 'birthDay', name: 'Birth day', valueGetter: (row) => parseDateAuto(row.birthDay)},
                        {key: 'gender', name: 'Gender'},
                        {key: 'maritalStatus', name: 'Marital status'},
                        {key: 'ethnicity', name: 'Ethnicity'}
                    ]
                }}
                filtersConfig={{
                    dontIncludeSearchInFilter: true,
                    genericFilterProps: {
                        configs,
                        collapsable: true,
                        fetchResultWithSelectedFilters: () => null,
                    }
                }}
            />

            <DeleteEmployeeDialog isOnTree={false} />
            <ReleaseEmployees />
            <ExportCsvDialog
                isOpen={exportCsv.isOpen}
                isLoadingFields={isLoadingFields}
                isLoadingExport={isLoadingExportCsv}
                availableFields={exportCsv.availableFields}
                handleExportCsv={exportCsv.handleExportCsv}
                handleClose={exportCsv.handleClose}
                fieldsMapperKey={"employees"}
            />

            <ImportCsvDialog
                isOpen={importCsv.isOpen}
                fields={importCsv.fields}
                response={importCsv.response}
                setImportDialog={importCsv.setImportDialog}

                isGettingFields={importCsv.isGettingFields}
                isPerformingCsv={importCsv.isPerformingCsv}
                isLoadingTemplate={importCsv.isLoadingTemplate}

                getFields={importCsv.getFields}
                performCsv={importCsv.performCsv}
                downloadTemplate={importCsv.downloadTemplate}
                fieldsMapperKey={importCsv.fieldsMapperKey}

                fileLimitInMB={5}
            />
        </Flex>
    )
}
