import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {dialogs, hideDeleteInstructionDialog, loadings, selectedInstruction} from "../../../../store/slice";
import {PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_LIST} from "../../../../constants";
import {DeleteCampaignReviewInstruction} from "../../../../store/actions";

export const useDeleteInstructionDialog = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isOpen = useSelector(dialogs).deleteCampaignReviewDialog;
    const isLoading = useSelector(loadings).campaignReviewInstructionDelete;
    const instruction = useSelector(selectedInstruction);

    const handleClose = () => {
        dispatch(hideDeleteInstructionDialog());
    }

    const handleSubmit = () => {
        instruction && dispatch(DeleteCampaignReviewInstruction({data: {id: instruction.id, onSuccess}}));
    }

    const onSuccess = () => {
        history.push(PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_LIST);
    }

    return{
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        instruction,
    }
}