import {FC} from "react";
import {Menu} from "@mui/material";
import {useTopBar} from "../../hooks/useTopBar";
import {DropdownButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";

export const TopBarWorkspace: FC = () => {

    const {currData: {selectedWorkspace}} = UseManageWorkspacesAndOrganizations();
    const {
        anchorWorkspaceEl,
        // handleWorkspacesOpen,
        handleWorkspacesClose,
        handleOpenSelectWorkspaceDialog
    } = useTopBar();

    // const { loading, data } = useQuery(getAllWorkspacesByOrganizationId, {
    //     variables: { organizationId: selectedOrganization?.id || ''},
    // });



    return (
        <>
            <DropdownButton variant={"text"} sx={{padding: '8px 10px', textTransform: 'none'}}
                            open={Boolean(anchorWorkspaceEl)}
                            onClick={handleOpenSelectWorkspaceDialog}
            >
                {selectedWorkspace?.name}
            </DropdownButton>
            <Menu
                anchorEl={anchorWorkspaceEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorWorkspaceEl)}
                onClose={handleWorkspacesClose}
                PaperProps={{style: {minWidth: '120px'}}} // for loader
            >
                {/*{loading ? (*/}
                {/*    <MenuItem key={'loader'}>*/}
                {/*        <CommonLoading/>*/}
                {/*    </MenuItem>*/}
                {/*) : (data as GetAllWorkspacesByOrganizationIdQuery)?.getAllWorkspacesByOrganizationId.map(workspace => (*/}
                {/*    <MenuItem key={workspace.id} onClick={async() => {*/}
                {/*        selectedOrganization && await setCurrentWorkspaceAndOrganization(*/}
                {/*            workspace as TWorkspaceWithShortCollabsAndRequester,*/}
                {/*            selectedOrganization as TOrganizationWithWorkspaces*/}
                {/*        );*/}
                {/*        handleWorkspacesClose();*/}
                {/*        history.push('/');*/}
                {/*    }}>*/}
                {/*        {workspace.name}*/}
                {/*    </MenuItem>*/}
                {/*))}*/}
            </Menu>
        </>
    )
}