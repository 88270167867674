import * as S from '../../../../components/newIntegrations/components/styled';
import {Divider, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import React from "react";
import {useDispatch} from "react-redux";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {TReportIntegrationLog} from "../../types";
import {deselectLogFetchResultAction, selectLogAction} from "../../store/reportView/store";
import { MAX_LOG_SIZE_PREVIEW} from "../../../newIntegrations/constants";
import {useReportsIntegrations} from "../../hooks/useReportsIntegrations";

export const ReportAppLogsTableRow = ({log, selectedLog}: {log: TReportIntegrationLog, selectedLog: TReportIntegrationLog | null}) => {
    const dispatch = useDispatch();
    const {downloadPreviewLog} = useReportsIntegrations();
    const onClick = () => {
        dispatch(deselectLogFetchResultAction()); //deleting prev log (its too big to just keep it)
        dispatch(selectLogAction(log));
        if(log.size < MAX_LOG_SIZE_PREVIEW){
            downloadPreviewLog({fileId: log.fileId})
        }
    };
    const isSelected = log.id === selectedLog?.id;
    return(
        <>
            <S.ExactAppLogsTableRowWrapper hoverable={!isSelected} onClick={onClick} isRightSideOpened={selectedLog !== null} selected={isSelected}>
                <S.AppHistoryTableHeaderItemWrapper width={'50%'}>
                    <Typography sx={{fontSize: '14px', fontWeight: 400, color: colors.grayText}}>{parseDateAuto(log.timestamp, true)}</Typography>
                </S.AppHistoryTableHeaderItemWrapper>

                <S.AppHistoryTableHeaderItemWrapper width={'50%'}>
                    <Typography sx={{fontSize: '14px', fontWeight: 400, color: colors.grayText}}>{log.id}</Typography>
                </S.AppHistoryTableHeaderItemWrapper>
            </S.ExactAppLogsTableRowWrapper>
            {<Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable, margin: '4px 0 0 0'}}/>}
        </>
    )
}