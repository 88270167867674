import {getControlsByFrameworkApi, getFrameworkZipApi} from "../../api";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {GetControlsByFrameworkQueryVariables} from "../../../../../newShared/GQLTypes";
import {AppState} from "../../../../../newShared/redux/rootReducer";
import {getActionsData} from "../../../../../newShared/redux";
import {addInfoSnack} from "../../../../barsEnvironment/snack/store/slice";


export const getControlsByFrameworkAction = createAsyncThunk(
    'CurrentFrameworks/getControls',
    async (data: GetControlsByFrameworkQueryVariables, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId, language} = getActionsData(state);

        const resp = await getControlsByFrameworkApi({...data, organizationId, workspaceId, language})
        return {
            data,
            resp,
            isNewOnTop: false
        }
    },
);

export const getFrameworkZipAction = createAsyncThunk(
    'CurrentFrameworks/getFrameworkZip',
    async ({frameworkName, frameworkId}: {frameworkId: string, frameworkName: string}, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await getFrameworkZipApi({frameworkId, workspaceId, organizationId});
        dispatch(addInfoSnack(resp.message));
        return {
            // data,
            resp
        }
    },
);
