import {useDispatch, useSelector} from "react-redux";
import {
    eraseIsDataOutdated,
    eraseRequests,
    isDataOutdated,
    loadings,
    openCancelRequest,
    openRequestNotCompleted,
    replaceRequestsMinMaxLoadedPage,
    requestMinMaxPage,
    requests,
    requestsPageInfo,
    setSelectedRequest
} from "../../../store/slice";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    CORPORATE_TARGET_TYPE,
    KYC_REQUESTS_EXACT_PATH,
    KYC_REQUESTS_LIST_PATH,
    KYC_ROOT_PATH,
    PERSON_TARGET_TYPE,
    REQUESTS_DEFAULT_PAGING
} from "../../../constants";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {GetKycRequests} from "../../../store/actions";
import {useEffect} from "react";
import {keyConfig} from "../../../../../../newShared/components/genericFilter/types";
import {RequestStatus} from "../../../components/requests/requestStatus";
import {
    useGenericFiltersStorage
} from "../../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {getNormalizedStatusName, getNormalizedTemplateTypeName} from "../../../helpers";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../../newShared/components/genericTable/types";
import {addInfoSnack} from "../../../../../barsEnvironment/snack/store/slice";
import {KycRefactorRequestModel} from "../../../../../../newShared/GQLTypes";
import {useHistory} from "react-router";


export const useRequestsList = () => {
    const {tMenu} = useMainTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const {currentFiltersForFetch, currentSearchForFetch, clearFilters} = useGenericFiltersStorage();

    const _requests = useSelector(requests);
    const pageInfo = useSelector(requestsPageInfo);
    const isLoading = useSelector(loadings).getRequestsList;
    const _isDataOutdated = useSelector(isDataOutdated).requestsList;

    //useEffects
    useSetBreadcrumbs([
        {
            title: tMenu('KYC Management'),
            path: KYC_ROOT_PATH
        },
        {
            title: tMenu('Requests'),
            path: KYC_REQUESTS_LIST_PATH
        }
    ]);

    useEffect(() => {
        // dispatch(GetKycRequests({pageInfo: {page: 0, count: REQUESTS_DEFAULT_PAGING}}));
        // setIsInitial(false);

        return () => {
            dispatch(eraseRequests());
            clearFilters();
        }
        //eslint-disable-next-line
    }, []);

    //scroll
    // const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
    //     getData: ({page, count}) => {
    //         dispatch(GetKycRequests({pageInfo: {page, count: REQUESTS_DEFAULT_PAGING, filters: {...currentFiltersForFetch, ...(currentSearchForFetch.trim().length > 0 ? {nameLike: currentSearchForFetch} : {})}}}));
    //     },
    //     arrayLength: _requests.length,
    //     isLoading,
    //     pageInfo: pageInfo,
    //     // cleanData: () => dispatch(eraseCategories()),
    //     // controller: controller,
    //     // isOnTop: true,
    //     isNoMoreThanTotal: true,
    //     customDependencies: [],
    //     is0PageAddictive: false,
    //     disableInitFetch: true,
    // })

    //filter
    const typeConfig: keyConfig<string> = {
        type: 'filter',
        key: 'templateType',
        name: ('Type'),
        options: [PERSON_TARGET_TYPE, CORPORATE_TARGET_TYPE],
        getOptionLabel: (o) => getNormalizedTemplateTypeName(o),
        default: true
    }

    const sendDateConfig: keyConfig<string> = {
        type: 'date',
        key: 'sendDate',
        name: ('Send date'),
        default: true
    }

    const responseDateConfig: keyConfig<string> = {
        type: 'date',
        key: 'responseDate',
        name: ('Response date'),
        default: true
    }

    const statusConfig: keyConfig<string> = {
        type: 'filter',
        key: 'status',
        name: ('Status'),
        options: ['COMPLETED', 'IN_PROGRESS', 'REJECTED', 'REJECTED_BY_USER'],
        OptionRenderer: (option, color) => <RequestStatus status={option} margin={'8px 0 0 0'}/>,
        getOptionLabel: (o) => getNormalizedStatusName(o),
        default: true
    }

    const handleReloadData = () => {
        const filters = currentFiltersForFetch;
        const search = currentSearchForFetch;
        dispatch(eraseRequests());
        dispatch(eraseIsDataOutdated());
        dispatch(GetKycRequests({pageInfo: {page: 0, count: REQUESTS_DEFAULT_PAGING, filters: {...filters, ...(search.trim().length > 0 ? {nameLike: search} : {})}}}));
    }

    const {maxLoadedPage, minLoadedPage} = useSelector(requestMinMaxPage);

    return{
        requests: _requests,
        pageInfo,
        isLoading,
        // isNoRequestsFound: !isLoading && !_requests.length  && !isInitial,
        // scroll: {
        //     bottomLoader,
        //     onScroll,
        //     scrollRef,
        //     hasMore: pageInfo.total > ((pageInfo.page + 1) * pageInfo.count),
        // },
        filter: {
            configs: [typeConfig, sendDateConfig, responseDateConfig, statusConfig],
            applyFilter: (search: string, filters: Record<string, string[]>, signal: AbortSignal) => {
                dispatch(GetKycRequests({pageInfo: {page: 0, count: REQUESTS_DEFAULT_PAGING, filters: {...filters, ...(search.trim().length > 0 ? {nameLike: search} : {})}}}));
            }
        },
        handleReloadData,
        isDataOutdated: _isDataOutdated,
        genericTable: {
            paging: {
                pageInfo,
                isLoading,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceRequestsMinMaxLoadedPage(data)),
                clearRows: () => dispatch(eraseRequests()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    dispatch(GetKycRequests({pageInfo: {page, count, filters}}));
                },
            },
            onRowClick: (row: KycRefactorRequestModel) => {
                const isCompleted = row.status === 'COMPLETED';
                const isInProgress = row.status === 'IN_PROGRESS';

                if(isCompleted){
                    history.push(KYC_REQUESTS_EXACT_PATH.replace(':id', row.id));
                    dispatch(setSelectedRequest(row));
                }else if(isInProgress){
                    dispatch(openRequestNotCompleted(row));
                }else{
                    dispatch(addInfoSnack(`Request is not completed`));
                }
            },
            onCancelRequest: (row: KycRefactorRequestModel) => {
                dispatch(openCancelRequest(row));

            }
        },
    }
}
