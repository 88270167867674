import {AutocompleteFreeSoloValueMapping, AutocompleteProps} from "@mui/material";
import {AutocompleteValue} from "@mui/base/useAutocomplete/useAutocomplete";
import {WorkspaceCollaboratorModel, WorkspaceCollaboratorWithRolesModel} from "../../GQLTypes";
import {InputBaseProps} from "@mui/material/InputBase";

export type TCollaborator = WorkspaceCollaboratorModel;
export type TCollaboratorWithRole = WorkspaceCollaboratorWithRolesModel;

export type TAutocompleteCollaboratorsProps<
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
> = {
    defaultValue: AutocompleteValue<TCollaborator | TCollaboratorWithRole | AutocompleteFreeSoloValueMapping<FreeSolo>, Multiple, DisableClearable, FreeSolo>;
    onChange: (value: AutocompleteValue<TCollaborator | AutocompleteFreeSoloValueMapping<FreeSolo>, Multiple, DisableClearable, FreeSolo>) => void;
    byAllWorkspaces?: boolean;
    label?: string;
    freeSoloType?: 'email' | 'string';
    hideCount?: boolean;
    getOptionLabelCustom?: 'email' | 'name' | 'name+email' | 'custom';
    helperText?: string | null;
    required?: boolean;

    workspaceId?: string;
    organizationId?: string;

    onError?: (error: boolean) => void;
    shouldForwardErrorValue?: boolean;

    inputName?: string;
    inputOnBlur?: InputBaseProps['onBlur'];
} & Omit<
    AutocompleteProps<TCollaboratorWithRole | AutocompleteFreeSoloValueMapping<FreeSolo>, Multiple, DisableClearable, FreeSolo>,
    'options' | 'renderInput' | 'onChange' | 'defaultValue' | 'ref' | 'onError'
>;

export const optionIsTCollaborator = (option: unknown): option is TCollaborator | TCollaboratorWithRole => {
    return typeof option === 'object' && option !== null && 'publicId' in option && 'email' in option;
}

