import {styled} from "@mui/material";
import {FlexColumn} from "../../editorTitleWithActionsRow/styled";

interface EditorItemBlockStyledProps {
    width: number;
    isFocused?: boolean;
    isForceHighlighted?: boolean;
    readonly?: boolean;
}
export const EditorItemBlockStyled = styled(FlexColumn, {shouldForwardProp: (propName) => (
        !['width', 'isFocused', 'isForceHighlighted', 'readonly'].includes(propName.toString())
    )})<EditorItemBlockStyledProps>(({width, isFocused, isForceHighlighted, readonly}) => ({
    flexBasis: width + '%',
    overflow: 'hidden',

    gap: '4px',

    '& > div.EditorItemBlock, & > div.EditorItemColumn': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',

        position: 'relative',

        gap: '4px',
    },

    '& > div.EditorItemBlock > div.borderForEditorItemBlock': {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        borderRadius: '2px',
        pointerEvents: 'none',

        border: `1px solid ${(isFocused || isForceHighlighted) && !readonly ? 'rgba(0, 0, 0, 0.1)' : 'transparent'}`,
        transition: 'border 0.2s ease-in-out',
    },

    '&:hover': {
        '& > div.EditorItemBlock > div.borderForEditorItemBlock': {
            border: `1px solid ${!readonly ? 'rgba(0, 0, 0, 0.1)' : 'transparent'}`,
        },
    },
}));