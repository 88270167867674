import {TEnvConfig} from "../types";
import {LABEL} from "../constants";

declare global {
    interface Window { config?: TEnvConfig; }
}

export const initLabels = () => {
    // const LABEL = window.config?.REACT_APP_LABEL || 'Regulait';

    //never be null
    let appleTouchIconEl = document.querySelector("link[rel~='apple-touch-icon']");
    let icon32El = document.querySelector("link[rel~='icon'][sizes='32x32']");
    let icon16El = document.querySelector("link[rel~='icon'][sizes='16x16']");
    let manifestEl = document.querySelector("link[rel~='manifest']");

    if (LABEL === 'Complico') {
        // @ts-ignore
        appleTouchIconEl.href = '/complico-apple-touch-icon-180x180.png';
        // @ts-ignore
        icon32El.href = '/complico-favicon-32x32.png';
        // @ts-ignore
        icon16El.href = '/complico-favicon-16x16.png';
        // @ts-ignore
        manifestEl.href = '/site-complico.webmanifest';
    } else {
        // @ts-ignore
        appleTouchIconEl.href = '/apple-touch-icon-180x180.png';
        // @ts-ignore
        icon32El.href = '/favicon-32x32.png';
        // @ts-ignore
        icon16El.href = '/favicon-16x16.png';
        // @ts-ignore
        manifestEl.href = '/site.webmanifest';
    }
    document.title = LABEL;
}