import {styled, Typography} from "@mui/material";
import colors from "../../../../../theme/colors";

export const ColumnRowBox = styled('div', {
    shouldForwardProp: (propName) => (
        !["isDragging"].includes(propName.toString())
    )})(({isDragging}:{isDragging?: true}) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    opacity: isDragging ? 0 : 1,
    border: `1px solid ${colors.stroke.grey}`,
    borderRadius: '4px',
    marginBottom: '10px',
    background: colors.backgrounds.white,
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    overflow: 'hidden'
}));

export const SubTitle = styled(Typography)(() => ({
    color: colors.text.grey,
    marginBottom: '16px'
}));
SubTitle.defaultProps = {
    variant: 'body2',
}
