import {useTemplatesExact} from "../../../hooks/templates/useTemplatesExact";
import * as S from '../../../components/styled/index';
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";
import EditIcon from '@mui/icons-material/Edit';
import PreviewSharpIcon from "@mui/icons-material/PreviewSharp";
import React, {FC} from "react";
import {Box, Button, Dialog, DialogActions, DialogTitle, Tab, Tabs, TextField, Typography} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import {exactTemplateTabType} from "../../../types";
import {TemplatesChecksTable} from "../../../components/templatesChecksTable";
import {PreviewTemplateDialog} from "../../../components/dialogs/previewTemplate";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {CRYPTOWALLET_TARGET_TYPE, FILE_FIELD_TYPE, SCREENING_MAX_VALUE, SCREENING_MIN_VALUE} from "../../../constants";
import {AddSystemFieldComponent} from "../../../components/addSystemFieldComponent";
import {AddCustomFieldComponent} from "../../../components/addCustomFieldComponent";
import {AddCustomFileFieldComponent} from "../../../components/addCustomFileFieldComponent";
import colors from "../../../../../../newShared/theme/colors";
import {TemplatesChecksMobileList} from "../../../components/templatesChecksMobileList";
import {CreateTemplateSubContainer} from "../../../components/templatesComponents/CreateTemplateSubContainer";
import {useTemplatesCreate} from "../../../hooks/templates/useTemplatesCreate";
import {TemplateField} from "../../../components/templatesComponents/templateField";
import {useDrop} from "react-dnd";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {TemplateCsvHistoryTable} from "../../../components/templateCsvHistoryTable";
import {EmptyArrayImage} from "../../../../../../newShared/components/EmptyArrayImage";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {DialogContent} from "../../../../../../newShared/components/editorUnion/components/editorDialog/styled";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const TemplatesExact = () => {
    const {isOk, form, fields, handleChange, dragNDrop, screening, setForm, confirmDeleteFieldDialog} = useTemplatesCreate();
    const {editMode, actions, template, tab, setTab, isLoading, config, isOkToSave, isLoadingSave, isNoHistoriesFound} = useTemplatesExact({setForm, isOk, form});
    const [, drop] = useDrop(() => ({ accept: 'field' }));
    const {t} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.templates'});
    const {isMobile} = useMedia();
    return(
        <S.PageContainer>
            <PageTitleAndActions
                handleGoBack={actions.handleGoBack}
                title={template?.name}
                showMenuWhen={isMobile}
                actions={[
                    {
                        title: t('Edit template'),
                        variant: 'text',
                        onClick: actions.handleEditTemplate,
                        size: 'small',
                        icon: <EditIcon />,
                        dontShowWhen: editMode
                    },
                    {
                        title: t('Save changes'),
                        variant: 'contained',
                        onClick: actions.handleSaveChanges,
                        size: 'small',
                        dontShowWhen: !editMode,
                        disabled: !isOkToSave,
                        loading: isLoadingSave
                    },
                    {
                        title: t('Preview'),
                        onClick: actions.handlePreview,
                        icon: <PreviewSharpIcon />,
                        disabled: editMode ? !isOk : false,
                        variant: 'text',
                        size: 'small'
                    },
                    {
                        title: t('Cancel'),
                        onClick: actions.handleCancelChanges,
                        variant: 'text',
                        size: 'small',
                        dontShowWhen: !editMode
                    }
                ]}
            />

            {!editMode ?
                <>
                    <TabContext value={tab}>
                        <Box sx={{ margin: '8px 0 0 0'}}>
                            <Tabs variant={'scrollable'} value={tab} onChange={(e, newValue: exactTemplateTabType) => setTab(newValue)}>
                                <Tab disabled={isLoading} label={t("Fields")} value={'fields'}/>
                                <Tab disabled={isLoading} label={t("Screening settings")} value={'screening'}/>
                                <Tab disabled={isLoading} label={t("CSV upload")} value={'csv'}/>
                            </Tabs>
                        </Box>

                        <TabPanel value={'fields'} sx={{padding: '33px 0 0 0', overflow: 'auto', height: '100%'}}>
                            <Flex gap={'10px'} direction={'column'} ref={drop} maxw={'650px'}>
                                {template && template.fields
                                    .slice()
                                    .sort((a, b) => a.order - b.order)
                                    .map(e => {
                                        return(
                                            <TemplateField
                                                withoutDelete
                                                disabled
                                                key={e.id}
                                                field={e}
                                                handleChange={() => null}
                                                handleDelete={() => null}
                                                handleToggleRequired={() => null}
                                                findField={() => null}
                                                moveField={() => null}
                                            />
                                        )
                                    })}
                            </Flex>
                        </TabPanel>

                        <TabPanel value={'screening'} sx={{margin: '16px 0', overflow: 'auto', height: '100%', padding: '0'}}>
                            <Flex direction={'column'}>
                                <Flex ai={'center'} gap={'8px'}>
                                    <Typography variant={'body2'} color={colors.text.grey_dark}>{t(`Last screening date: `)}</Typography>
                                    <Typography variant={'body2'} color={colors.grayText}>{parseDateAuto(template?.lastScreeningDate, true)}</Typography>
                                </Flex>

                                <Flex ai={'center'} gap={'10px'} m={'0 0 16px 0'}>
                                    <Flex ai={'center'} gap={'8px'}>
                                        <Typography variant={'body2'} color={colors.text.grey_dark}>{t(`Next screening date: `)}</Typography>
                                        <Typography variant={'body2'} color={colors.grayText}>{parseDateAuto(template?.nextScreeningDate, true)}</Typography>
                                    </Flex>

                                    <Typography variant={'body2'} color={colors.grayText}>{t('Repeats every ')}</Typography>

                                    <Flex w={'70px'}>
                                        <TextField
                                            disabled
                                            value={form.screeningSettings.repeat}
                                            onChange={(e) => screening.handleChange(parseInt(e.currentTarget.value))}
                                            type={'number'}
                                            InputProps={{
                                                type: 'number',
                                                inputProps: {
                                                    "aria-valuemin": SCREENING_MIN_VALUE,
                                                    "aria-valuemax": SCREENING_MAX_VALUE,
                                                },
                                            }}
                                            inputProps={{
                                                style: {
                                                    padding: '10px 8px',
                                                    height: '14px'
                                                }}
                                            }
                                            size={'small'}
                                        />
                                    </Flex>

                                    <Typography variant={'body2'} color={colors.grayText}>{(' days')}</Typography>
                                </Flex>
                            </Flex>

                            {isMobile ?
                                <TemplatesChecksMobileList
                                    disabled
                                    // checks={config.systemChecks}
                                    formChecks={template?.screeningSettings.checks ?? []}
                                    onSwitchToggle={() => null}
                                    onRatioChange={() => null}
                                />
                                :
                                <TemplatesChecksTable
                                    disabled
                                    // checks={config.systemChecks}
                                    formChecks={template?.screeningSettings.checks ?? []}
                                    onSwitchToggle={() => null}
                                    onRatioChange={() => null}
                                />
                            }
                        </TabPanel>

                        <TabPanel value={'csv'} sx={{padding: '0', overflow: 'auto', height: '100%'}}>
                            {isNoHistoriesFound ?
                                <EmptyArrayImage
                                    type={'kycTemplatesCsvHistory'}
                                    isShowing
                                    withoutAdd
                                    text={t('No CSV history found')}
                                />
                                :
                                <TemplateCsvHistoryTable />
                            }
                        </TabPanel>
                    </TabContext>
                </>
                :
                <S.CreateTemplateMainContainer>
                    <CreateTemplateSubContainer title={t('Description')} description={t('Name your template here, the description is optional.')}>
                        <S.InputTitle>{t('Template name')}<S.RequiredSpan>{" *"}</S.RequiredSpan></S.InputTitle>
                        <S.SubRightContainerInput
                            _margin={'8px 0 0 0'}
                            value={form.name}
                            onChange={handleChange}
                            name={'name'}
                            placeholder={t('Type here...')}
                        />

                        <S.InputTitle _margin={'10px 0 0 0'}>{t('Template description')}</S.InputTitle>
                        <S.SubRightContainerMultilineInput
                            _margin={'8px 0 0 0'}
                            value={form.description}
                            onChange={handleChange}
                            name={'description'}
                            placeholder={t('Type here...')}
                        />
                    </CreateTemplateSubContainer>

                    <CreateTemplateSubContainer title={('Fields')} description={t('Some fields are required in templates and can not be removed. You can add Custom or System fields, the order of the System fields can not be reorder.')}>
                        <Flex gap={'10px'} direction={'column'} ref={drop}>
                            {form.fields
                                .filter(e => e.type !== FILE_FIELD_TYPE)
                                .sort((a, b) => a.order - b.order)
                                .map(e => {
                                    return(
                                        <TemplateField
                                            error={form.fields.filter(f => f.name.trim().toLowerCase() === e.name.trim().toLowerCase()).length > 1}
                                            key={e.id}
                                            field={e}
                                            handleChange={fields.handleChangeCustomField}
                                            handleDelete={fields.handleRemoveField}
                                            handleToggleRequired={fields.handleToggleRequired}
                                            findField={dragNDrop.findField}
                                            moveField={dragNDrop.moveField}
                                        />
                                    )
                                })}
                            <Flex ai={'center'} gap={'20px'}>
                                <AddSystemFieldComponent
                                    title={t('Add system field')}
                                    selectedOptions={form.fields.map(e => ({key: e.id, name: e.name}))}
                                    options={fields.config.systemFields.filter(e => e.type !== FILE_FIELD_TYPE && e.templateType === form.type).map(e => ({key: e.id, name: e.name}))}
                                    disabledChangeOptions={config.systemFields.filter(e => e.requiredForTemplate).map(e => {
                                        return {name: e.name, key: e.id}
                                    })}
                                    handleSelectOption={fields.handleSelectOption}
                                    handleDeselectOption={fields.handleDeselectOption}
                                    handleReset={() => null}
                                />

                                <AddCustomFieldComponent
                                    title={t('Add custom field')}
                                    onSelect={fields.handleSelectCustomField}
                                />
                            </Flex>
                        </Flex>
                    </CreateTemplateSubContainer>

                    <CreateTemplateSubContainer title={t('Documents')} description={t('Assign document fields that are essential for this KYC template structure.')}>
                        <Flex gap={'10px'} direction={'column'} ref={drop}>
                            {form.fields
                                .filter(e => e.type === FILE_FIELD_TYPE)
                                .sort((a, b) => a.order - b.order)
                                .map(e => {
                                    return(
                                        <TemplateField
                                            error={form.fields.filter(f => f.name.trim().toLowerCase() === e.name.trim().toLowerCase()).length > 1}
                                            key={e.id}
                                            field={e}
                                            handleChange={fields.handleChangeCustomField}
                                            handleDelete={fields.handleRemoveField}
                                            handleToggleRequired={fields.handleToggleRequired}
                                            findField={dragNDrop.findField}
                                            moveField={dragNDrop.moveField}
                                        />
                                    )
                                })}
                            <Flex ai={'center'} gap={'20px'}>
                                <AddSystemFieldComponent
                                    title={t('Add system field')}
                                    selectedOptions={form.fields.filter(e => e.type === FILE_FIELD_TYPE).map(e => ({key: e.id, name: e.name}))}
                                    options={fields.config.systemFields.filter(e => e.type === FILE_FIELD_TYPE && e.templateType === form.type).map(e => ({key: e.id, name: e.name}))}
                                    disabledChangeOptions={config.systemFields.filter(e => e.requiredForTemplate).map(e => {
                                        return {name: e.name, key: e.id}
                                    })}
                                    handleSelectOption={fields.handleSelectOption}
                                    handleDeselectOption={fields.handleDeselectOption}
                                    handleReset={() => null}
                                />

                                <AddCustomFileFieldComponent
                                    title={t('Add custom field')}
                                    onClick={() => fields.handleSelectCustomField('FILE')}
                                />
                            </Flex>
                        </Flex>
                    </CreateTemplateSubContainer>

                    <CreateTemplateSubContainer disableDivider title={t('Template screening setting')} description={t('Select in how many days the screening will repeat, apply the available checks and matching ratio.')}>
                        <Flex direction={'column'} gap={'16px'}>
                            {/*<Flex ai={'center'} gap={'8px'}>*/}
                            {/*    <Typography variant={'body2'} color={colors.text.grey_dark}>{(`Last screening date: `)}</Typography>*/}
                            {/*    <Typography variant={'body2'} color={colors.grayText}>{parseDate(template?.lastScreeningDate, currentLanguage.momentLocale)}</Typography>*/}
                            {/*</Flex>*/}

                            <Flex ai={'center'} gap={'10px'}>
                                {/*<Typography variant={'body2'} color={colors.text.grey_dark}>{('Next screening date: ')}</Typography>*/}

                                {/*<Flex>*/}
                                {/*    <DatePicker*/}
                                {/*        value={form.nextScreeningDate}*/}
                                {/*        disablePast*/}
                                {/*        shouldDisableDate={(day) => isSameDay(day, new Date().toISOString(), currentLanguage.momentLocale)}*/}
                                {/*        onChange={(newValue) => screening.handleChangeNextScreeningDate(newValue)}*/}
                                {/*        renderInput={(params) => <TextField*/}
                                {/*            {...params}*/}
                                {/*            error={!form.nextScreeningDate}*/}
                                {/*            size={'small'}*/}
                                {/*        />}*/}
                                {/*    />*/}
                                {/*</Flex>*/}

                                <Typography variant={'body2'} color={colors.grayText}>{t('Repeats every ')}</Typography>

                                <Flex w={'70px'}>
                                    <TextField
                                        value={form.screeningSettings.repeat}
                                        onChange={(e) => screening.handleChange(parseInt(e.currentTarget.value))}
                                        type={'number'}
                                        InputProps={{
                                            type: 'number',
                                            inputProps: {
                                                "aria-valuemin": SCREENING_MIN_VALUE,
                                                "aria-valuemax": SCREENING_MAX_VALUE,
                                            },
                                        }}
                                        inputProps={{
                                            style: {
                                                padding: '10px 8px',
                                                height: '14px'
                                            }}
                                        }
                                        size={'small'}
                                    />
                                </Flex>

                                <Typography variant={'body2'} color={colors.grayText}>{t(' days')}</Typography>
                            </Flex>
                        </Flex>
                    </CreateTemplateSubContainer>

                    {!isMobile ?
                        <TemplatesChecksTable
                            disabledMatchingRatio={form.type === CRYPTOWALLET_TARGET_TYPE}
                            // checks={fields.config.systemChecks}
                            formChecks={form.screeningSettings.checks}
                            onSwitchToggle={screening.onSwitchToggle}
                            onRatioChange={screening.onRatioChange}
                        />
                        :
                        <TemplatesChecksMobileList
                            disabledMatchingRatio={form.type === CRYPTOWALLET_TARGET_TYPE}
                            // checks={fields.config.systemChecks}
                            formChecks={form.screeningSettings.checks}
                            onSwitchToggle={screening.onSwitchToggle}
                            onRatioChange={screening.onRatioChange}
                        />
                    }
                </S.CreateTemplateMainContainer>
            }

            <ConfirmDeleteFieldDialog
                isOpen={confirmDeleteFieldDialog.isOpen}
                handleClose={confirmDeleteFieldDialog.handleClose}
                handleSubmit={confirmDeleteFieldDialog.handleConfirm}
            />

            <PreviewTemplateDialog />
        </S.PageContainer>
    )
}

const ConfirmDeleteFieldDialog: FC<{isOpen: boolean, handleClose: () => void, handleSubmit: () => void}> = ({isOpen, handleSubmit, handleClose}) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.dialogs'});

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Delete field')}</DialogTitle>

            <DialogContent sx={{padding: '20px 16px 0', gap: '10px'}}>
                <Typography>{t('Please note that deleting fields from the template will also result in the removal of these fields in the associated clients.')}</Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'} size={'medium'}>{t('Cancel')}</Button>
                <Button onClick={handleSubmit} size={'medium'}>{t('Delete')}</Button>
            </DialogActions>
        </Dialog>
    )
}
