import {useDispatch, useSelector} from "react-redux";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {assignmentsSelector, eraseSelectedAssignmentAction, loadings,} from "../../store/slice";
import {PATH_TRAININGS_ASSIGNMENTS, TRAININGS_PATH} from "../../constants";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {mainTrainingsGetAssignmentDetailsAction} from "../../store/actions";
import {useTrainingsDialogs} from "../useTrainingsDialogs";
import {mapToMainTrainingType} from "../../helpers";
import {useHistory} from "react-router";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";

export const useAssignmentExactPage = () => {
    //root
    const { tMenu} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.pathAssignments'});
    const dispatch = useDispatch();
    const {assignmentId} = useParams<{assignmentId: string}>();
    const {
        cancelAssignment: {handleSet: handleSetCancel},
        trainingPreview: {handleSet: handleSetTrainingPreview},
        // examPreview: {handleSet: handleSetExamPreview},
        assignmentHistory: {handleSet: handleSetHistory},
        examResultDialog: {handleSet: handleSetExamResult},
        validateExamAnswers: {handleSet: handleSetValidateExamAnswers},
    } = useTrainingsDialogs();
    const history = useHistory();
    const {setMessage} = useMessageDialog();

    //selectors
    const {selectedAssignment: assignment} = useSelector(assignmentsSelector);
    const {assignmentsExact: isLoadingExact} = useSelector(loadings);

    const getAssignmentById = (assignmentId: string) => {
        dispatch(mainTrainingsGetAssignmentDetailsAction({
            data: {assignmentId},
            onError: (request, errors, addictiveData) => {
                const e404 = errors.e404?.[0];

                if (e404) {
                    setMessage({title: `Assignment ${assignmentId}`, message: 'Assignment not found.'});
                    history.push(PATH_TRAININGS_ASSIGNMENTS);
                }
            }
        }))
    };

    //useEffects
    useSetBreadcrumbs([
        {
            title: tMenu('Training management'),
            path: TRAININGS_PATH
        },
        {
            title: tMenu('Assignments'),
            path: PATH_TRAININGS_ASSIGNMENTS
        },
        {
            title:  assignment ? assignment.id : 'Loading...',
            path: PATH_TRAININGS_ASSIGNMENTS
        }
    ]);

    useEffect(() => {
        if (assignmentId) getAssignmentById(assignmentId);

        return () => {
            dispatch(eraseSelectedAssignmentAction());
        }
        // eslint-disable-next-line
    }, [assignmentId]);


    //actions
    const handleValidate = () => assignment && handleSetValidateExamAnswers({isOpen: true});
    const handleViewExamResult = () => assignment && handleSetExamResult({assignment});


    return{
        assignment,
        isLoadingExact,
        actions: {
            handleOpenCancel: () => assignment && handleSetCancel({isOpen: true}),
            handleViewTraining: () => assignment?.training && handleSetTrainingPreview({isOpen: true, training: mapToMainTrainingType(assignment.training)}),
            // handleViewExam: () => assignment?.postExam && handleSetExamPreview({exam: mapToMainExamType(assignment.postExam)}),
            handleViewHistory: () => assignment && handleSetHistory({isOpen: true}),
            handleValidate,
            handleViewExamResult,
        },
        refreshAssignment: () => assignmentId && getAssignmentById(assignmentId),
    }
}


