import {useDispatch, useSelector} from "react-redux";
import {dialogs, setImportCsvDialogAction} from "../../../store/slice";
import {
    GetAccessManagementAppById,
    getCsvFieldsAccessManagementAction,
    ImportCsvAccessManagementAppTemplate,
    performCsvAccessManagementAction
} from "../../../store/actions";
import {TImportCsvDialogProps} from "../../../../../../newShared/components/csvImportDialog";
import {resetImportCsvDialog} from "../../../../../../newShared/components/csvImportDialog/constants";
import {
    useGenericFiltersStorage
} from "../../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";

export const useImportCsvAccessManagement = (): TImportCsvDialogProps => {
    const dispatch = useDispatch();
    const {importCsvDialog: {
        isOpen,
        isLoading: {
            isGettingFields,
            isPerformingCsv,
            isLoadingTemplate,
        },
        fields,
        fileId,
        data,
    }} = useSelector(dialogs);

    const setImportDialog: TImportCsvDialogProps["setImportDialog"] = (data) => dispatch(setImportCsvDialogAction(data));
    const downloadTemplate = () => {data.applicationAccessId && dispatch(ImportCsvAccessManagementAppTemplate({id: data.applicationAccessId}))};

    const getFields: TImportCsvDialogProps["getFields"] = (file) => {dispatch(getCsvFieldsAccessManagementAction({
        data: {...file},
        onError: resetImportCsvDialog,
        onSuccess: () => data.applicationAccessId && dispatch(GetAccessManagementAppById({data: {id: data.applicationAccessId}})),
    }))};

    const {clearFilters} = useGenericFiltersStorage();

    const performCsv: TImportCsvDialogProps["performCsv"] = (fields) => {fileId && data.applicationAccessId && dispatch(performCsvAccessManagementAction({
        data: {fields, fileId, id: data.applicationAccessId},
        snack: "success",
        // onError: (request, error, addictiveData) => {dispatch(setImportCsvDialogAction({error: error[0]?.message || 'Unknown error'}))},
        onSuccess: (request, response, addictiveData) => {
            clearFilters();
            dispatch(GetAccessManagementAppById({data: {id: request.id}}));
        }
    }))};

    return {
        isOpen: !!data.applicationAccessId && isOpen,
        isLoadingTemplate, isPerformingCsv, isGettingFields,
        fields,
        setImportDialog,
        downloadTemplate,
        getFields,
        performCsv,
        fieldsMapperKey: "accessManagement",
    }
}
