import {useHistory, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    assetsDialogsSelector,
    deselectSoftwareAsset,
    loadings,
    selectedSoftware,
    setCreateSettingRowDialogAction,
    setDeleteAssetDialogAction,
    shortPhysicalAssetsSelector,
    softwareAssetsSettingsSelector
} from "../../store/store";
import {PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE, PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_ID,} from "../../constants";
import {
    GetShortPhysicalAssets,
    GetSoftwareAssetById,
    getSoftwareAssetsSettingsAction,
    updateSoftwareAssetAction,
} from "../../store/actions";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_ASSET_MANAGEMENT} from "../../../../../newShared/constants";
import {TExactAssetTabs, TSetCreateSettingRowDialog, TSetDeleteAssetDialog, TSoftwareAsset} from "../../types";
import {useForm} from "../../../../../newShared/hooks/useForm";
import {SoftwarePhysicalAssetModel} from "../../../../../newShared/GQLTypes";


export const useSoftwareAssetExact = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const dispatch = useDispatch();
    const history = useHistory();
    const id = useParams<{id: string}>().id;
    const _selected = useSelector(selectedSoftware);
    const settings = useSelector(softwareAssetsSettingsSelector);
    const shortPhysicalAssets = useSelector(shortPhysicalAssetsSelector);
    const {isLoadingExact, isLoadingSettings, isLoadingShortPhysicalAssets} = useSelector(loadings);
    const {deleteAsset: {response: responseDelete}} = useSelector(assetsDialogsSelector);


    const handleGoBack = () => {
        history.push(PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE);
    }

    const controller = new AbortController();

    const [editInfoMode, setEditInfoMode] = useState<boolean>(false);
    const [oldId, setOldId] = useState<string>('');


    //form
    const {form, setForm, handleChange} = useForm<TSoftwareAsset | null>(null);


    useEffect(() => {
        if(!_selected){
            dispatch(GetSoftwareAssetById({data: {id, onError: handleGoBack}, signal: controller.signal}));
        }
        if(!settings) {
            dispatch(getSoftwareAssetsSettingsAction({data: {organizationId: '', workspaceId: ''}, signal: controller.signal}))
        }

        if(_selected) setForm(_selected);

        return () => {
            dispatch(deselectSoftwareAsset());
            setForm(null);
            setOldId('');
            setEditInfoMode(false);
            controller.abort();
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(settings && settings.customFields){
            const customFields: { [key: string]: string } = {};
            Object.keys(settings.customFields).length && Object.keys(settings.customFields).forEach((key) => form?.customFields?.[key] ? form.customFields?.[key] : customFields[key] = '');
            setForm(prev => prev && ({...prev, customFields}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    useEffect(() => {
        if (_selected && _selected?.id !== id) history.push(`${PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_ID.replace(':id', _selected?.id)}`);

        //eslint-disable-next-line
    }, [_selected?.id]);

    useSetBreadcrumbs([
        {
            title: tMenu('Asset Management'),
            path: PATH_LOCAL_ASSET_MANAGEMENT
        },
        {
            title: tMenu('Software'),
            path: PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE
        },
        {
            title: _selected ? _selected.name : 'Loading...',
            path: _selected ? PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_ID.replace(':id', _selected.id) : undefined
        },
    ]);

    //MENU
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const isOk = () => {
        return (form && form.name.trim().length > 0 && form.id.length)
    }

    //tabs
    const [tab, setTab] = useState<TExactAssetTabs>('information');

    //actions
    const handleSaveInfo = () => {
        form && dispatch(updateSoftwareAssetAction({
            workspaceId: '',
            organizationId: '',
            asset: {...form, installed: Number(form.installed), installationThreshold: Number(form.installationThreshold)},
            assetId: oldId
        }));
        setEditInfoMode(false);
    }

    const handleCancelEditInfo = () => {
        setForm(null);
        setEditInfoMode(false)
    }

    const handleEditInfoBtn = () => {
        setEditInfoMode(true);
        dispatch(GetShortPhysicalAssets(controller.signal));
        if (_selected) {
            setOldId(_selected.id);
            setForm({..._selected, customFields: _selected.customFields ? _selected.customFields : {}});
        }
    }

    // const handleAddPhysicalAsset = (pa: SoftwarePhysicalAssetModel) => {
    //     setForm(prev => prev && ({...prev, physicalAssets: prev.physicalAssets ? [...prev.physicalAssets, pa] : [id]}));
    // }

    const handleDeletePhysicalAsset = (id: string) => {
        setForm(prev => prev && ({...prev, physicalAssets: prev.physicalAssets?.filter( e => e.id !== id )}));
    }

    //Dialogs
    const setDeleteAssetDialog = (data: TSetDeleteAssetDialog) => {dispatch(setDeleteAssetDialogAction(data))};

    const handleSetPhysicalAsset = (pa: SoftwarePhysicalAssetModel) => {
        let tmp: SoftwarePhysicalAssetModel[] = (form && form.physicalAssets && form?.physicalAssets?.length > 0) ? form.physicalAssets : []
        setForm(prev => prev && ({...prev, physicalAssets: [...tmp , pa]}));
    }

    const setCreateSettingRowDialog = (data: TSetCreateSettingRowDialog) => {dispatch(setCreateSettingRowDialogAction(data))};

    const handleChangeCustomField = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setForm((prev) => prev && ({
            ...prev,
            customFields: {
                ...prev.customFields,
                [event.target.name]: event.target.value,
            },
        }));
    };

    return {
        isOk,
        asset: _selected,
        settings,
        shortPhysicalAssets,
        menu: {
            anchorEl,
            handleClose,
            handleClick,
            open,
        },
        tab,
        setTab,
        handleGoBack,

        isLoadingExact,
        isLoadingSettings,
        isLoadingShortPhysicalAssets,

        editInfoMode,
        setEditInfoMode,
        form,
        handleChange,
        handleSetPhysicalAsset,
        handleDeletePhysicalAsset,
        // handleAddPhysicalAsset,
        oldId,
        setOldId,
        handleSaveInfo,
        handleCancelEditInfo,
        handleEditInfoBtn,
        setCreateSettingRowDialog,
        handleChangeCustomField,
        deleteAssetDialog: {setDeleteAssetDialog, responseDelete},

    }

}
