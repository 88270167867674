import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideStepFrameworksDialog} from "../../../store/slice";
import {
    FrameworksControlsEvidencesDialog
} from "../../../../../../newShared/components/frameworksControlsEvidencesDialog";

export const TaskFrameworksDialog = () => {
    const dispatch = useDispatch();
    
    const {step} = useSelector(dialogs).stepFrameworksDialog;
    const handleClose = () => dispatch(hideStepFrameworksDialog());

    return(
        <FrameworksControlsEvidencesDialog
            frameworks={step?.frameworks.map(e => ({
                ...e,
                controls: (e.controls ?? []).map(c => ({
                    ...c, evidences: [{id: step.evidenceId, name: c.evidenceName}]})
                )
            })) ?? []}
            handleClose={handleClose}
        />
    )
}