import {MiniMap} from "react-flow-renderer";
import React from "react";

export const Minimap = () => {
    return <MiniMap
        // style={{width: isMobile ? '100px' : '200px', height: isMobile ? '100px' : '200px'}}
        nodeStrokeColor={(n:any): string => {
            // if (n.style?.background) return n.style.background;
            if (n.type === 'start') return 'white';
            if (n.type === 'finish') return 'white';
            if (n.type === 'plus') return 'white';
            if(n.type === 'stage') return 'blue';
            if(n.type === 'email') return 'green';
            if(n.type === 'docChild') return 'green';
            if(n.type === 'saveChild') return 'green';
            // if (n.type === 'trigger') return 'blue';
            // if (n.type === 'doc') return 'gray';
            // if(n.type === 'read') return 'purple';

            return 'red';
        }}
        nodeColor={(n: any) => {
            if (n.style?.background) return n.style.background;

            return '#fff';
        }}
        nodeBorderRadius={2}
    />
}