import {useSegregationOfDuties} from "../../useSegregationOfDuties";
import {KeyboardEventHandler, useEffect, useState} from "react";
import {TDenyDialogTabs, TRulesConflict, TShortCollaborator} from "../../../types";
import {InputBaseProps} from "@mui/material/InputBase";
import {InputProps as StandardInputProps} from "@mui/material/Input/Input";


export const useDenyConflict = () => {

    const {
        selectedConflict,
        shortCollaborators: collaborators,
        handleHideDenyConflict,
        handleSaveDenyConflict,
        loadings: {dataForDialog: isLoadingData, denyConflict: isLoadingDeny},
        dialogs: {denyConflict: isOpen, }

    } = useSegregationOfDuties();

    const [tab, setTab] = useState<TDenyDialogTabs>('collaborators');
    const [addedCollaborators, setAddedCollaborators] = useState<TShortCollaborator[]>([]);
    const [emails, setEmails] = useState<string[]>([]);
    const [text, setText] = useState<string>('');
    const [errorMessage, setError] = useState<string>('');

    //filter
    const [search, setSearch] = useState<string>('');

    const getRecipients = (): TRulesConflict['recipients'] => {
        const emailRecipients: TRulesConflict['recipients'] = emails.length > 0 ? emails.map(e => ({publicId: null, email: e, firstName: null, lastName: null})) : [];
        const collabsRecipients: TRulesConflict['recipients'] = addedCollaborators.length > 0 ? addedCollaborators.map(e => ({publicId: e.publicId, email: e.email, firstName: e.firstName, lastName: e.lastName})) : [];
        return [...collabsRecipients, ...emailRecipients];
    }


    const handleSave = () => {
        selectedConflict && handleSaveDenyConflict({...selectedConflict, recipients: getRecipients(), status: 'DENIED'});
    }

    const handleCleanSearch = () => {
        setSearch('');
    };

    const handleSetCollaborator = (collab: TShortCollaborator) => {
        setAddedCollaborators(prev => prev && [...prev, collab]);
    }

    const handleDeleteCollaborator = (id: string) => {
        setAddedCollaborators(prev => prev && prev.filter(e => e.publicId !== id));
    }

    //byEmail
    const handleAddEmail = (email: string) => {setEmails(prev => [...prev, email])};
    const handleDeleteEmail = (email: string) => {setEmails(prev => prev.filter(e => e !== email))};

    const handleBlur: InputBaseProps['onBlur'] = () => {
        if (text.trim().match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/) && !emails.includes(text.trim())) {
            handleAddEmail(text.trim());
            setText('')
        }
    }

    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (event.key === 'Backspace') {
            text.length === 0 && emails.length > 0 && handleDeleteEmail(emails[emails.length - 1]);
        }
    }

    const handleChange: StandardInputProps['onChange'] = (event) => {
        const array = event.target.value.split(/[ ,;:/\n]+/g);
        if (array.length > 1) {
            setText(array[array.length - 1]);
            [...new Set(array
                .slice(0, array.length - 1)
                .filter(e => e.length > 0
                    && !emails.includes(e)
                    && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(e)
                )
            )].forEach(email => {handleAddEmail(email)})
        } else {
            setText(event.target.value)
        }
    }

    // const isOkEmail = () => {
    //     const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     return emails.every(e => re.test(e));
    // }

    // const handleEnterKey = (e: any) => {
    //     if(e.keyCode === 13){
    //         if (isOkEmail()) {
    //            setError('');
    //         } else setError (t('This email address is not valid'))
    //     }
    // }

    useEffect(() => {
        setEmails([]);
        setError('');
        setText('')
        setTab('collaborators');

        //eslint-disable-next-line
    }, [isOpen]);



    return {
        isOpen,
        tab, setTab,
        search, setSearch,
        filteredCollaborators: collaborators.filter(e => e.firstName.toLowerCase().includes(search.toLowerCase()) || e.lastName.toLowerCase().includes(search.toLowerCase()) || e.email.toLowerCase().includes(search.toLowerCase())),
        isLoadingData,
        isLoadingDeny,
        handleHideDenyConflict,
        addedCollaborators,
        handleSave,
        handleCleanSearch,
        handleSetCollaborator,
        handleDeleteCollaborator,
        errorMessage,

        //by email
        emails,
        handleDeleteEmail,
        handleBlur,
        handleKeyDown,
        handleChange,
        text
    }
}