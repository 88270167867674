import {FileDrop} from "react-file-drop";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {IconButton, MenuItem, TableCell, TableRow, Typography} from "@mui/material";
import {DocumentFolder, TDocumentStatus, TShortDocument} from "../../types";
import {
    ACCEPT_UPLOAD_FILES,
    APPROVAL_STATUS,
    COMPLETED_STATUS,
    DRAFT_STATUS,
    SHARED_DRAFT_STATUS,
    SIGNING_STATUS,
    TERMINATED_STATUS
} from "../../constants";
import React, {FC} from "react";
import {useDocuments} from "../../hooks/useDocuments";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import * as S from "./styled";
import {getStatusColor, getStatusTitle} from "../../helpers";
import {TableRowSkeleton} from "../../../../../newShared/components/tableRowSkeleton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {useDocumentRow} from "../../hooks/useDocumentRow";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {ReactComponent as FolderIcon} from "../../../../../newShared/images/menu/folder.svg";
// import {
//     ReactComponent as DocumentDetailIcon
// } from "../../../../../newShared/images/documents/document-detail-and-history.svg";
import {
    DocNameFlexBox,
    DocumentsHeaderTableTypography,
    DocumentsTableBody,
    DocumentsTableContainer,
    DocumentsTableHead,
    DocumentsTableRow,
    DragAreaTypo,
    DragAreaWrapper,
    StatusRoundBox,
    StatusTableCellContainer,
    StatusTypography,
    StickyHeaderTable
} from "../../pages/styled";
import {ContentCopy, DeleteOutline, Download, DriveFileMoveOutlined, Edit, Widgets} from "@mui/icons-material";
import {diffBetweenDateAndNow} from "../../../../../newShared/utils/dateTools";
import {getExpiredTitleColor} from "../../../../../newShared/components/editorUnion/helpers";


export const DocumentsTable: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage'});

    const {
        files,
        isLoadingFiles,
        selectedFolder,
        fileHandler,
        isDrag,
        setDrag,
        isAbleToDrop,
        handleOpenFolder,
        handleOpenFile,
        openShowRecipientsDialog,
        scroll,
    } = useDocuments();



    return (
        <S.DocumentsTableWrapper >
            <FileDrop
                onDrop={(f) => isAbleToDrop && fileHandler(f)}
                onFrameDragEnter={() => isAbleToDrop && setDrag(true)}
                onFrameDragLeave={() => setDrag(false)}
                onFrameDrop={() => setDrag(false)}
                // className={"file-drop-target"}
            >
                {/*DRAGGABLE AREA*/}
                {isDrag && isAbleToDrop &&
                    <DragAreaWrapper>
                        <UploadFileIcon fontSize="large" color={'primary'}/>
                        <DragAreaTypo>{t('Drop file here')}</DragAreaTypo>
                    </DragAreaWrapper>
                }

                <DocumentsTableContainer onScroll={scroll.onScroll} ref={scroll.scrollRef} >
                    <StickyHeaderTable>
                        <DocumentsTableHead>
                            <DocumentsTableRow>
                                <S.DocNameTableCell>
                                    <DocumentsHeaderTableTypography>{t('Name')}</DocumentsHeaderTableTypography>
                                </S.DocNameTableCell>
                                <S.DocLastModifiedTableCell>
                                    <DocumentsHeaderTableTypography>{t('Last modified')}</DocumentsHeaderTableTypography>
                                </S.DocLastModifiedTableCell>
                                <S.DocRecipientsTableCell>
                                    <DocumentsHeaderTableTypography>{t('Recipients')}</DocumentsHeaderTableTypography>
                                </S.DocRecipientsTableCell>
                                <S.DocStatusTableCell>
                                    <DocumentsHeaderTableTypography>{t('Status')}</DocumentsHeaderTableTypography>
                                </S.DocStatusTableCell>
                                <S.DocActionsTableCell>
                                    <DocumentsHeaderTableTypography>{t('Current step ETA')}</DocumentsHeaderTableTypography>
                                </S.DocActionsTableCell>
                                <S.DocActionsTableCell>
                                    <DocumentsHeaderTableTypography>{t('Actions')}</DocumentsHeaderTableTypography>
                                </S.DocActionsTableCell>

                            </DocumentsTableRow>
                        </DocumentsTableHead>

                        <DocumentsTableBody>
                            {selectedFolder && selectedFolder.children.map((e: DocumentFolder) => <FolderTableRow key={e.id} folder={e} openFolder={handleOpenFolder}  />)}
                            {files.map((e) => e && <DocumentTableRow key={e.id} file={e} openFile={handleOpenFile}  openRecipientsDialog={openShowRecipientsDialog}/>)}
                            {(isLoadingFiles) && <TableRowSkeleton columns={6} />}
                            {!isLoadingFiles && !files.length && !selectedFolder?.children?.length &&
                                <TableRow><TableCell colSpan={6}>{t('No Document in this folder.')}</TableCell></TableRow>
                            }
                        </DocumentsTableBody>

                    </StickyHeaderTable>
                </DocumentsTableContainer>


                <input
                    accept={ACCEPT_UPLOAD_FILES}
                    value=""
                    style={{ visibility: "hidden", opacity: 0, border: '1px solid red' }}
                    multiple
                    type="file"
                    onChange={(e) => fileHandler(e.target.files)}
                />
            </FileDrop>


            {/*LOADING FILES AND EMPTY ARRAY IMAGE*/}
            {/*{!selectedFolder && <Flex w={'100%'} ai={'center'} jc={'center'}><Typo fontSize={'18px'} fontWeight={400}>{t('Select Folder')}</Typo></Flex>}*/}
            {/*{isLoadingFiles && <LocalSpinner />}*/}
            {/*<EmptyArrayImage*/}
            {/*    type={'documents'}*/}
            {/*    isShowing={!isLoadingFiles && (selectedFolder ? !selectedFolder.children.length : false) && !files.length}*/}
            {/*    text={t('No Document in this folder.')}*/}
            {/*    withoutAdd*/}
            {/*/>*/}
        </S.DocumentsTableWrapper>
    )

}

//File
interface DocumentTableRowProps {
    file: TShortDocument,
    openFile: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, file: TShortDocument) => void,
    openRecipientsDialog: (recipients: TShortDocument["recipients"]) => void
}
export const DocumentTableRow: FC<DocumentTableRowProps> = ({file, openRecipientsDialog, openFile}) => {
    const {t, revDir, currentLanguage: {momentLocale}} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage'});
    const {
        handleOpenMoveToDialog,
        handleOpenDeleteDialog,
        handleDownload,
        handleMakeDraftCopy,
        handleSetAsEvidence,
        menu
    } = useDocumentRow();

    const statusColor = getStatusColor(file.status as TDocumentStatus);
    const isDeleteDisabled = file.status !== DRAFT_STATUS && file.status !== SHARED_DRAFT_STATUS && file.status !== TERMINATED_STATUS;

    const diff = diffBetweenDateAndNow(file.currentStepEndDate || '', momentLocale);

    const expiredText =
        (file.status === APPROVAL_STATUS || file.status === SIGNING_STATUS)
            ? getExpiredTitleColor(diff, t).title
            : '-'

    return(
        <TableRow hover onClick={(e) => openFile(e, file)}>
            <S.DocNameTableCell width={'200px'}>
                <Typography variant={'*bodyText2'} noWrap>{file.name}</Typography>
            </S.DocNameTableCell>
            <S.DocLastModifiedTableCell>
                <Typography variant={'*bodyText2'}>{file.lastModified?.split("T")[0] ?? file.createdDate?.split("T")[0]}</Typography>
            </S.DocLastModifiedTableCell>
            <S.DocRecipientsTableCell align={"center"}>
                {!!file.recipients?.length && (
                    <S.DocumentAvatarGroup onClick={() => openRecipientsDialog(file.recipients)}
                                           revDir={revDir ? true : undefined} max={4} total={file.recipients.filter(e => e.actor.email.trim().length > 0).length} >
                        {file.recipients.filter(e => e.actor.email.trim().length > 0).map((e, index) => (
                            e && <S.DocumentAvatar key={e.actor?.email} index={index} name={`${e.actor?.firstName || e.actor?.email} ${e.actor?.lastName || e.actor?.email}`} alt={e.actor?.email ?? ''} />
                        ))}
                    </S.DocumentAvatarGroup>
                )}
            </S.DocRecipientsTableCell>

            <S.DocStatusTableCell>
                <StatusTableCellContainer>
                    <StatusRoundBox color={statusColor} revDir={revDir ? true : undefined}/>
                    <StatusTypography color={statusColor}>{getStatusTitle(file.status as TDocumentStatus).toUpperCase()}</StatusTypography>
                </StatusTableCellContainer>
            </S.DocStatusTableCell>

            <S.DocExpiredTableCell color={getExpiredTitleColor(diff).color}>
                <Typography variant={'*bodyText2'}>{expiredText}</Typography>
            </S.DocExpiredTableCell>

            <S.DocActionsTableCell sx={{padding: '10.5px 8px'}}>
                <>
                    <IconButton
                        size={'small'}
                        sx={{margin: !revDir ? '0 0 0 16px' : '0 16px 0 0'}}
                        aria-label="more"
                        id="long-button"
                        aria-controls={menu.open ? 'long-menu' : undefined}
                        aria-expanded={menu.open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {
                            if(menu.open){
                                menu.handleClose()
                            }else{
                                e.stopPropagation()
                                menu.handleClick(e)
                            }
                        }}
                        color={"primary"}
                    >
                        {<MoreHorizIcon />}
                    </IconButton>

                    <S.DocumentRowActionMenu
                        anchorEl={menu.anchorEl}
                        open={menu.open}
                        onClose={menu.handleClose}
                        // onBackdropClick={(e) => {
                        //     e.stopPropagation()
                        //     menu.handleClose();
                        // }}
                    >
                        <MenuItem onClick={() => handleDownload(file)} disabled={file.status !== COMPLETED_STATUS || !file.generatedFileId}>
                            <S.MenuItemBox>
                                <Download />
                                <S.MenuItemText>{t('Download')}</S.MenuItemText>
                            </S.MenuItemBox>
                        </MenuItem>

                        {file.documentId && (
                            <MenuItem onClick={() => handleSetAsEvidence(file.documentId!)} disabled={file.status !== COMPLETED_STATUS || !file.generatedFileId}>
                                <S.MenuItemBox>
                                    <Widgets />
                                    <S.MenuItemText>{t('Save to evidence')}</S.MenuItemText>
                                </S.MenuItemBox>
                            </MenuItem>
                        )}

                        {/*<MenuItem >*/}
                        {/*    <S.MenuItemBox>*/}
                        {/*        <DocumentDetailIcon />*/}
                        {/*        <S.MenuItemText>{t('Document detail and history')}</S.MenuItemText>*/}
                        {/*    </S.MenuItemBox>*/}
                        {/*</MenuItem>*/}

                        <MenuItem onClick={() => handleMakeDraftCopy(file)} disabled={menu.isLoadingMakeDraftCopy}>
                            <S.MenuItemBox>
                                <ContentCopy />
                                <S.MenuItemText>{('Make draft copy')}</S.MenuItemText>
                            </S.MenuItemBox>
                        </MenuItem>

                        <MenuItem onClick={() => handleOpenMoveToDialog({file})}>
                            <S.MenuItemBox>
                                <DriveFileMoveOutlined />
                                <S.MenuItemText>{t('Move to folder')}</S.MenuItemText>
                            </S.MenuItemBox>
                        </MenuItem>

                        <MenuItem disabled={isDeleteDisabled}
                                  onClick={() => handleOpenDeleteDialog({file})}>
                            <S.MenuItemBox>
                                <DeleteOutline />
                                <S.MenuItemText>{t('Delete')}</S.MenuItemText>
                            </S.MenuItemBox>
                        </MenuItem>
                    </S.DocumentRowActionMenu>
                </>
            </S.DocActionsTableCell>
        </TableRow>
    )
}


//Folder
interface FolderTableRowProps {
    folder: DocumentFolder,
    openFolder: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, folder: DocumentFolder) => void,
}
export const FolderTableRow: FC<FolderTableRowProps> = ({folder, openFolder }) => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage'});

    const {
        handleOpenRenameDialog,
        handleOpenMoveToDialog,
        handleOpenDeleteDialog,
        menu
    } = useDocumentRow();


    return(
        <TableRow hover onClick={(e) => openFolder(e, folder)}>
            <S.DocNameTableCell width={'200px'}>
                <DocNameFlexBox>
                    <FolderIcon />
                    <Typography variant={'*bodyText2'} noWrap>{folder.name}</Typography>
                </DocNameFlexBox>
            </S.DocNameTableCell>
            <S.DocLastModifiedTableCell />
            <S.DocRecipientsTableCell />
            <S.DocStatusTableCell />
            <S.DocExpiredTableCell />


            <S.DocActionsTableCell sx={{padding: '10.5px 8px'}}>
                <>
                    <IconButton
                        size={'small'}
                        sx={{margin: !revDir ? '0 0 0 16px' : '0 16px 0 0'}}
                        aria-label="more"
                        id="long-button"
                        aria-controls={menu.open ? 'long-menu' : undefined}
                        aria-expanded={menu.open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {
                            if(menu.open){
                                menu.handleClose()
                            }else{
                                e.stopPropagation()
                                menu.handleClick(e)
                            }
                        }}
                        color={"primary"}
                    >
                        {<MoreHorizIcon />}
                    </IconButton>

                    <S.DocumentRowActionMenu
                        anchorEl={menu.anchorEl}
                        open={menu.open}
                        onClose={menu.handleClose}
                        // onBackdropClick={(e) => {
                        //     e.stopPropagation()
                        //     menu.handleClose();
                        // }}
                    >
                        <MenuItem onClick={() => handleOpenRenameDialog(folder)}>
                            <S.MenuItemBox>
                                <Edit />
                                <S.MenuItemText>{t('Rename folder')}</S.MenuItemText>
                            </S.MenuItemBox>
                        </MenuItem>

                        <MenuItem onClick={() => handleOpenMoveToDialog({folder})}>
                            <S.MenuItemBox>
                                <DriveFileMoveOutlined />
                                <S.MenuItemText>{t('Move to folder')}</S.MenuItemText>
                            </S.MenuItemBox>
                        </MenuItem>

                        <MenuItem onClick={() => handleOpenDeleteDialog({folder})}>
                            <S.MenuItemBox>
                                <DeleteOutlineIcon />
                                <S.MenuItemText >{t('Delete')}</S.MenuItemText>
                            </S.MenuItemBox>
                        </MenuItem>

                    </S.DocumentRowActionMenu>
                </>
            </S.DocActionsTableCell>
        </TableRow>
    )
};
