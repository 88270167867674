import {MainTrainingLevel, MainTrainingModel, MainTrainingStatus} from "../../../../../newShared/GQLTypes";
import {eraseTrainings, loadings, replaceTrainingsMinMaxLoadedPage, trainingsSelector} from "../../store/slice";
import {useDispatch, useSelector} from "react-redux";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {mainTrainingsGetTrainingsWithFilterPaginationAction} from "../../store/actions";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    defaultPageInfo,
    PATH_TRAININGS_CREATE,
    PATH_TRAININGS_EXACT,
    PATH_TRAININGS_LIST,
    trainingLevelTitleMapper,
    TRAININGS_PATH
} from "../../constants";
import {useHistory} from "react-router";
import {getTrainingStatusChip} from '../../helpers';
import {mainTrainingsGetTrainingsCategoriesAutocompleteApi} from "../../api";

export const useTrainingsList = () => {
    const {trainingsList: isLoadingList} = useSelector(loadings);
    const history = useHistory();
    const dispatch = useDispatch();
    const {t, tMenu} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.pathTrainings'});

    const createTraining = () => {
        history.push(PATH_TRAININGS_CREATE);
    }
    const handleOpenTraining = (selected: MainTrainingModel) => {
        history.push(PATH_TRAININGS_EXACT.replace(':trainingId', selected.trainingId));
        // dispatch(mainTrainingsGetTrainingByIdAndVersionAction({data: {trainingId: selected.trainingId, version: selected.version.toString()}}));
    }
    const setMinMaxLoadedPage = (data: minMaxLoadedPageType) => dispatch(replaceTrainingsMinMaxLoadedPage(data));
    const {trainings, pageInfo, minMaxLoadedPage: {minLoadedPage, maxLoadedPage}} = useSelector(trainingsSelector);

    const levelConfig: keyConfig<MainTrainingLevel> = {
        type: 'filter',
        key: 'level',
        name: t('Level'),
        options: Object.values(MainTrainingLevel),
        getOptionLabel: (e) => trainingLevelTitleMapper[e],
        default: true,
    }

    const statusConfig: keyConfig<MainTrainingStatus> = {
        type: 'filter',
        key: 'status',
        name: t('Status'),
        options: Object.values(MainTrainingStatus),
        OptionRenderer: (option) => getTrainingStatusChip(option, true),
        default: true,
    }

    const categoryConfig: keyConfig<string> = {
        type: 'filter',
        key: 'category',
        name: t('Category'),
        getOptionLabel: (a) => a,
        getFilterValue: (a) => a,
        isOptionEqualToSearch: (a, search) => a.trim().toLowerCase().includes(search.trim().toLowerCase()),
        fetchOptions: (workspaceId, {page, count, search}) => {
            return mainTrainingsGetTrainingsCategoriesAutocompleteApi({ workspaceId, pageRequest: {page, count}, data: search})
                .then(({result, pageInfo}) => ({values: result, pageInfo}))
        },
        default: true,
    }

    const createdDateConfig: keyConfig<string> = {
        type: 'date',
        key: 'createdDate',
        name: t('Created date'),
        default: true,
    }

    const lastUpdatedConfig: keyConfig<string> = {
        type: 'date',
        key: 'lastUpdate',
        name: t('Last update'),
        default: true,
    }

    useSetBreadcrumbs([
        {
            title: tMenu('Training management'),
            path: TRAININGS_PATH,
        },
        {
            title: tMenu('Trainings'),
            path: PATH_TRAININGS_LIST
        }
    ]);

    return {
        createTraining,
        handleOpenTraining,
        trainings,
        genericTable: {
            paging: {
                pageInfo,
                isLoading: isLoadingList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage,
                clearRows: () => dispatch(eraseTrainings()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, trainingNameLike: string) => {
                    dispatch(mainTrainingsGetTrainingsWithFilterPaginationAction({data: {pageRequest: {page, count: count || defaultPageInfo.count}, filter: {...filters, trainingNameLike}}}))
                }
            },
            filters: {
                configs: [
                    categoryConfig,
                    levelConfig,
                    statusConfig,
                    createdDateConfig,
                    lastUpdatedConfig

                ]
            }
        },


    }

}
