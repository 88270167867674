import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_ACCESS_MANAGEMENT_APPS_LIST, PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET} from "../../constants";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {ExportCsvRulesetAction} from "../../store/actions";
import {
    cleanRuleset,
    replaceRulesetsMinMaxLoadedPage,
    ruleSetMinMaxLoadedPage,
    setImportCsvDialogAction
} from "../../store/slice";
import {useSegregationOfDuties} from "../useSegregationOfDuties";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {TDutyRule} from "../../types";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";


export const useRulesetList = () => {

    // const {filter} = useParameters();
    const dispatch = useDispatch();

    const {
        getRuleset,
        ruleset,
        pageInfo: {page, count, total},
        loadings: {list: isLoadingList, update: isLoadingUpdate, exportCsv: isLoadingExport},
        clean,

        handleOpenEditRule,
        handleOpenDeleteRule,
        handleChangeIsActive,
        goToRuleExact,
        goToRuleNew,
        handleSelectRule,
    } = useSegregationOfDuties()
    const {minLoadedPage, maxLoadedPage} = useSelector(ruleSetMinMaxLoadedPage);


    // const controller = new AbortController();

    //MENU
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const exportCsv = () => {
        handleClose();
        dispatch(ExportCsvRulesetAction({workspaceId: ""}))
    };

    const handleOpenImportCsv = () => {
        handleClose();
        dispatch(setImportCsvDialogAction({isOpen: true}));
    }

    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    useSetBreadcrumbs([
        {
            title: tMenu('Access management'),
            path: PATH_ACCESS_MANAGEMENT_APPS_LIST
        },
        {
            title: tMenu('SOD ruleset'),
            path: PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET
        },
    ]);


    useEffect(() => {
        // ruleset.length === 0 && getRuleset({page, count: PAGE_COUNT, filters: filter}, controller.signal);

        return () => {
            // controller.abort();
            clean();
        }
        //eslint-disable-next-line
    }, []);

    // const {value, handleChange} = useSearchFieldURL({wait: 0});
    // const {setFilter, handleKeyDown} = useFilterUrl({
    //     getData: (filters) => {getRuleset({page: 0, count: PAGE_COUNT, filters}, controller.signal, true)}
    // });

    // pagination
    // const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
    //     getData: ({page, count}) => { getRuleset({page, count: PAGE_COUNT, filters: filter}, controller.signal)},
    //     arrayLength: ruleset.length,
    //     isLoading: isLoadingList,
    //     pageInfo: {page, count, total},
    //     cleanData: () => {},
    // })

    const severityLevelConfig: keyConfig<string> = {
        type: 'filter',
        key: 'severityLevel',
        name: ('Severity level'),
        options: ["Low", "High", "Medium"],
        getFilterValue: (v) => v.toUpperCase(),
        default: true
    }

    const statusConfig: keyConfig<string> = {
        type: 'filter',
        key: 'status',
        name: ('Activity status'),
        options: ['Active', 'Inactive'],
        getFilterValue: (v) => v.toLowerCase(),
        default: true
    }

    return {
        ruleset,
        isLoadingList,
        isLoadingUpdate,
        isLoadingExport,
        // isNoRulesFound: !isLoadingList && !ruleset.length && !!filter?.input?.length && !!filter?.severityLevel?.length && !!filter?.status?.length,
        handleOpenEditRule,
        handleOpenDeleteRule,
        handleOpenImportCsv,
        handleChangeIsActive,
        handleSelectRule,
        goToRuleExact,
        goToRuleNew,
        exportCsv,

        // filter,
        // value, handleChange, handleKeyDown, setFilter,

        menu: {
            anchorEl,
            handleClose,
            handleClick,
            open,
        },

        // pagination: {
        //     bottomLoader: bottomLoader && ((ruleset.length < total) || (ruleset.length === total && total === 0)),
        //     onScroll,
        //     scrollRef
        // },
        genericTable: {
            paging: {
                pageInfo: {page, count, total},
                isLoading: isLoadingList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceRulesetsMinMaxLoadedPage(data)),
                clearRows: () => dispatch(cleanRuleset()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    getRuleset({page, count, filters: {...filters, input: [search]}})
                },
            },
            onRowClick: (row: TDutyRule) => {
                goToRuleExact(row.id);
                handleSelectRule(row);
            },
            filters: {
                configs: [severityLevelConfig, statusConfig]
            }
        }
    }
}