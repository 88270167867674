import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import colors from "../../../../../../newShared/theme/colors";
import {useSegregationOfDuties} from "../../../hooks/useSegregationOfDuties";

export const AllowedConflictDataDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        loadings: {dataForDialog: isLoadingData},
        dialogs: {allowedConflictData: {isOpen, conflictException, ruleId}},
        handleHideAllowedConflictData: handleClose,
        goToRuleExact,
    } = useSegregationOfDuties();

    const handleGoToRuleExact = () => {
        ruleId && goToRuleExact(ruleId);
        handleClose();
    }

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Allowed conflict')}</DialogTitle>
            <DialogContent>
                {conflictException &&
                    <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} sx={{borderRadius: '8px', mt: '12px', mb: '12px', p: '8px', boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)'}}>
                        <Typography variant={'body1'}>{t('Exception added')}</Typography>
                        <Box display={'flex'} sx={{mt: '8px'}}>
                            <Typography variant={'body2'} sx={{color: colors.text.grey_dark, whiteSpace: 'nowrap'}}>{t('Account')}</Typography>
                            <Typography variant={'body2'} sx={{ml: '6px', mr: '6px'}}>{conflictException.account}</Typography>
                        </Box>
                        <Box display={'flex'} sx={{mt: '8px'}}>
                            <Typography variant={'body2'} sx={{color: colors.text.grey_dark, whiteSpace: 'nowrap'}}>{t('Specify resolution')}</Typography>
                            <Typography variant={'body2'} sx={{ml: '6px', mr: '6px'}} >{conflictException.resolution}</Typography>
                        </Box>
                        <Box display={'flex'} sx={{mt: '8px'}}>
                            <Typography variant={'body2'} sx={{color: colors.text.grey_dark, whiteSpace: 'nowrap'}}>{t('Specify reason')}</Typography>
                            <Typography variant={'body2'} sx={{ml: '6px', mr: '6px'}}>{conflictException.reason}</Typography>
                        </Box>
                        <Box display={'flex'} sx={{mt: '8px'}}>
                            <Typography variant={'body2'} sx={{color: colors.text.grey_dark}}>{t('Allow till')}</Typography>
                            <Typography variant={'body2'} sx={{ml: '6px', mr: '6px'}}>{conflictException.allowedDate.split('T')[0]}</Typography>
                        </Box>

                    </Box>
                }
            </DialogContent>
            <DialogActions>
                <Button sx={{textTransform: 'none', ml: '6px', mr: '6px'}} onClick={handleClose} size={'medium'} variant={'text'}>{t('Close')}</Button>
                <Button disabled={isLoadingData || !ruleId || !conflictException} sx={{textTransform: 'none'}} onClick={handleGoToRuleExact}>{t('View rule')}</Button>
            </DialogActions>
        </Dialog>
    )
}
