import {useHistory} from "react-router";
import {
    BUILDER_WITH_FRAME,
    BUILDER_WITH_FRAME_AND_CONTROL,
    CONTROLS,
    ROOT_CONTROLS,
    ROOT_EVIDENCES,
    ROOT_FRAMEWORKS
} from "../../../constants";
import {useParams} from "react-router-dom";
import {useParameters} from "../../../../../../newShared/hooks/useParameters";

export const useRoutes = () => {

    const {frameworkId, controlId, evidenceId} = useParams<{frameworkId?: string, controlId?: string, evidenceId?: string}>();
    const {filter} = useParameters();
    const history = useHistory();

    const goToFrameworks = () => {history.push(ROOT_FRAMEWORKS)};
    const goToFramework = (frId: string) => {history.push(BUILDER_WITH_FRAME.replace(':frameworkId', frId))};
    const goToFrameworkControl = (frId: string, coId: string) => {history.push(BUILDER_WITH_FRAME_AND_CONTROL.replace(':frameworkId', frId).replace(':controlId', coId))};


    const goToControls = () => {history.push(ROOT_CONTROLS)};
    const goToControl = (coId: string) => {history.push(CONTROLS.replace(':controlId', coId))};

    const goToEvidences = () => {history.push(ROOT_EVIDENCES)};

    return {
        frameworkId,
        controlId,
        evidenceId,
        filter,

        goToFrameworks,
        goToFramework,
        goToFrameworkControl,

        goToControls,
        goToControl,

        goToEvidences,
    }
}