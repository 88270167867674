import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideSaveRisk, loadings, setSelectedRisk} from "../../../store/slice";


export const useSaveRisk = ({handleSave}: {handleSave: () => void}) => {
    const dispatch = useDispatch();
    const {saveDialog: {onSuccessGoToRisk, isOpen}} = useSelector(dialogs);
    const {riskEdit} = useSelector(loadings);


    const handleClose = () => {
        dispatch(hideSaveRisk());
    }

    const handleSubmit = async () => {
        //save current risk
        await handleSave();
        //select onSuccessGoToRisk
        dispatch(setSelectedRisk(onSuccessGoToRisk));
        //dialog will be closed by setSelectedRisk action
    }

    const handleDiscardChanges = () => {
        dispatch(setSelectedRisk(onSuccessGoToRisk));
        //dialog will be closed by setSelectedRisk action
    }

    return{
        isOpen,
        handleClose,
        isLoading: riskEdit,
        handleSubmit,
        handleDiscardChanges
    }
}