import {
    Autocomplete,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {LoadingButton} from "@mui/lab";
import {useEditDutyDialog} from "../../../hooks/dialogHooks/useEditDutyDialog";

export const EditDutyDialog = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        isOpen,
        shortApplications,
        categories,
        rolesVariants,
        handleClose,
        handleSubmit,
        handleSetCategory,
        handleSetApplication,
        handleSetRole,

        handleChange,
        form,
        isOk,
        isAlreadyExists,

        isLoadingUpdate,
        isLoadingDialogData,
    } = useEditDutyDialog();

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Edit duty')}</DialogTitle>
            <DialogContent>

                <TextField label={t('Duty name')} value={form?.name} size={'small'}
                           required name={'name'} sx={{mt: '8px', '& label': {top: '0 !important'}}}
                           onChange={handleChange} error={isAlreadyExists}
                           helperText={isAlreadyExists && 'Duty with such name is already exists.'}
                />

                <Autocomplete
                    size={"small"}
                    sx={{mt: '16px'}}
                    options={categories}
                    value={form?.category}
                    onChange={(e, value) => value && handleSetCategory(value)}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                        <TextField {...params}
                                   required
                                   size={"small"}
                                   sx={{'& label': {top: '0 !important'}}}
                                   label={t('Duty category')}  />
                    )}
                />

                <TextField name={'description'} label={t("Enter duty description")}  variant="outlined"
                           multiline minRows={5} sx={{mt: '16px'}} value={form?.description} onChange={handleChange}/>

                <Typography variant={'body2'} sx={{mt: '32px'}}>{t('You can add multiple roles within the same application.')}</Typography>

                <Autocomplete
                    size={"small"}
                    sx={{mt: '8px'}}
                    options={shortApplications}
                    value={form?.application}
                    onChange={(e, value) => value && handleSetApplication(value)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params}
                                   required
                                   size={"small"}
                                   sx={{'& label': {top: '0 !important'}}}
                                   label={t('Select application')}  />
                    )}
                />

                <Autocomplete
                    sx={{mt: '16px'}}
                    multiple
                    options={rolesVariants.filter(role => !form?.roles.some((el) => el.role === role))}
                    getOptionLabel={(option) => option}
                    onChange={(e, value) => {handleSetRole(value)}}
                    value={form?.roles.map(e => e.role) || []}
                    renderTags={(value: typeof rolesVariants , getTagProps, ownerState) =>
                        value.map((option, index) => (
                            <Chip
                                variant="outlined"
                                label={option}
                                size={"small"}
                                sx={{pl: !revDir ? 'undefined' : '6px'}}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size={form?.roles.length === 0 ? "small" : undefined }
                            required
                            sx={{'& label': {top: '0 !important'}}}
                            label={t("Select role")}
                        />
                    )}
                />

            </DialogContent>
            <DialogActions>
                <Button sx={{textTransform: 'none'}} onClick={handleClose} size={'medium'} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton size={'medium'} variant={'contained'}
                               disabled={!isOk || isLoadingUpdate || isLoadingDialogData}
                               onClick={handleSubmit} loading={isLoadingUpdate}
                               endIcon={isLoadingUpdate ? <div style={{width: '10px', height: '10px', marginLeft: revDir ? '12px' : undefined, marginRight: !revDir ? '12px' : undefined }}/> : undefined}
                               loadingPosition={isLoadingUpdate ? 'end' : undefined}
                >
                    {t('Save')}
                </LoadingButton>

            </DialogActions>
        </Dialog>
    )
}