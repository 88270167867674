import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Box, Button, Typography} from "@mui/material";
import React from "react";
import {useConflictList} from "../../../hooks/useConflictList";
import colors from "../../../../../../newShared/theme/colors";
import {ViewConflictDialog} from "../../../components/dialogs/viewConflict";
import {DenyConflictDialog} from "../../../components/dialogs/denyConflict";
import {AllowConflictDialog} from "../../../components/dialogs/allowConflict";
import {AllowedConflictDataDialog} from "../../../components/dialogs/allowedConflictData";
import {DeniedConflictDataDialog} from "../../../components/dialogs/deniedConflictData";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";
import {GenericTable} from "../../../../../../newShared/components/genericTable";
import {TRulesConflict} from "../../../types";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";


export const ConflictList = () => {
    const {isMobile} = useMedia();
    const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        conflicts,
        isLoadingData,
        goToRuleExact,
        handleOpenAllowedConflictData,
        handleOpenDeniedConflictData,

        // filter,
        // value, handleChange, handleKeyDown,
        // setFilter,
        handleOpenViewConflict,

        // pagination: {
        //     bottomLoader,
        //     onScroll,
        //     scrollRef
        // },
        genericTable
    } = useConflictList();



    return (
        <Box width={'100%'} height={'100%'} display={'flex'} flexDirection={'column'}>

            <PageTitleAndActions
                title={t('Conflict list')}
                actions={[]}
            />

            <GenericTable<TRulesConflict>
                id={'AccessManagementAppsList'}
                columnsConfig={{totalName: 'Total conflicts', disableColumnsSettings: true, forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: conflicts,
                    customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id ?? row.account,
                    onRowClick: () => null,
                    columnsConfigs: [
                        {key: "status", name: t('Conflict status'), default: true},
                        {key: 'lastVerf', name: t('Last verification date'), default: true, valueGetter: (row) => parseDateAuto(row.verifiedDate)},
                        {key: 'account', name: t('Account'), default: true},
                        {key: 'app', name: t('Application'), default: true, valueGetter: (row) => row.applications.map(a => a.name).join(', ')},
                        {key: 'rule', name: t('Rule name'), default: true, valueRenderer: (row) => {
                            if(row.rule.name.toUpperCase() === 'NOT EXIST' || row.applications.some(e => e.name.toLowerCase() === 'removed')){
                                return <Typography variant={'body2'} sx={{whiteSpace: 'nowrap'}}>{row.rule.name}</Typography>
                            }else{
                                return <Typography variant={'body2'} sx={{color: colors.primary.blue, cursor: 'pointer', whiteSpace: 'nowrap'}} onClick={() => goToRuleExact(row.rule.id)}>{row.rule.name}</Typography>
                            }
                        }},
                        {key: 'severityLevel', name: t('Severity level'), default: true},
                        {key: 'switch', name: '', default: true, valueRenderer: (row, key, isTable) => (
                                <>
                                    {row.status.toUpperCase() === "UNRESOLVED" && <Button variant={'text'} disabled={row.applications.some(e => e.name.toLowerCase() === 'removed')} size={'small'} onClick={(event) => {handleOpenViewConflict(event, row)}} sx={{textTransform: 'none', width: 'maxContent', pl: 0, pr: 0}}>{t('View conflict')}</Button>}
                                    {row.status.toUpperCase() === "ALLOWED" && <Button variant={'text'} disabled={isLoadingData || row.rule.name.toUpperCase() === 'NOT EXIST' || row.applications.some(e => e.name.toLowerCase() === 'removed')} size={'small'} onClick={(event) => {handleOpenAllowedConflictData(event, row.rule.id, row.account)}} sx={{textTransform: 'none', width: 'maxContent', pl: 0, pr: 0, textAlign: 'start'}}>{row.rule.name.toUpperCase() === 'NOT EXIST' ? t('Exception not available') : t('Exception added')}</Button>}
                                    {row.status.toUpperCase() === "DENIED" && <Button variant={'text'} disabled={row.applications.some(e => e.name.toLowerCase() === 'removed')} size={'small'} onClick={(event) => {handleOpenDeniedConflictData(event, row)}} sx={{textTransform: 'none', width: 'maxContent', pl: 0, pr: 0}}>{t('Notification sent')}</Button>}
                                </>
                        )},
                    ],
                }}
                filtersConfig={{
                    searchCustomFilterKeyName: 'input',
                    searchAsArray: true,
                    dontIncludeSearchInFilter: true,
                    genericFilterProps: {
                        configs: genericTable.filters.configs,
                        fetchResultWithSelectedFilters: () => null,
                        isAddFilters: false,
                        disableSearch: true
                    }
                }}
                // emptyArrayImageProps={{type: 'accessManagement', filterNotApplied: {secondaryText: 'You have not added any rule yet. Create new one.', text: 'No duties found', onClick: goToRuleNew}}}
            />


            {/*<Breadcrumbs/>*/}
            {/*<Typography variant={isMobile ?  'h3' : "h1"}  sx={{mr: !revDir ? '32px' : undefined, ml: revDir ? '32px' : undefined, color: colors.backgrounds.dark}}>*/}
            {/*    {t('Conflict list')}*/}
            {/*</Typography>*/}

            {/*<Box display={'flex'} alignItems={'center'} flexWrap={'wrap'} sx={{flexDirection: isMobile ? 'column' : 'row', mt: isMobile ? '16px' : '20px'}}>*/}
            {/*    /!*<SearchField*!/*/}
            {/*    /!*    size={'small'}*!/*/}
            {/*    /!*    sx={{ml: revDir && !isMobile ? '10px' : undefined, mr: !revDir && !isMobile ? '10px' : undefined, width: isMobile ? '100%' : isTablet ? '45%' : '320px'}}*!/*/}
            {/*    /!*    variant={'outlined'}*!/*/}
            {/*    /!*    placeholder={t('Search')}*!/*/}
            {/*    /!*    onClear={() => {setFilter('input'); handleChange({target: {value: ''}})}}*!/*/}
            {/*    /!*    value={value}*!/*/}
            {/*    /!*    disabled={isLoadingList}*!/*/}
            {/*    /!*    onChange={handleChange}*!/*/}
            {/*    /!*    onKeyDown={handleKeyDown}*!/*/}
            {/*    /!*//*/}

            {/*    <TextField label={t('Sort by conflict status')} select size={'small'}*/}
            {/*               value={filter.status?.[0] ?? 'Any'}*/}
            {/*               sx={{ml: revDir && !isMobile ? '10px' : undefined, mr: !revDir && !isMobile ? '10px' : undefined, mt: isMobile ? '10px' : undefined,  width: isMobile ? '100%' : isTablet ? '45%' : '320px', '& label': {top: '0 !important'}}}*/}
            {/*               disabled={isLoadingList}*/}
            {/*    >*/}
            {/*        <MenuItem key={'Any'} value={'Any'} onClick={() => {setFilter('status')}}>{t('Any')}</MenuItem>*/}
            {/*        {["UNRESOLVED", "ALLOWED", 'DENIED'].map((e) => (<MenuItem key={e} value={e.toUpperCase()} onClick={() => {setFilter('status', e.toUpperCase())}}>{e}</MenuItem>))}*/}
            {/*    </TextField>*/}

            {/*    <TextField label={t('Sort by severity level')} select size={'small'}*/}
            {/*               value={filter.severityLevel?.[0] ?? 'Any'}*/}
            {/*               sx={{width: isMobile ? '100%' : isTablet ? '45%' : '320px', mt: isMobile || isTablet ? '10px' : undefined, '& label': {top: '0 !important'}}}*/}
            {/*               disabled={isLoadingList}*/}
            {/*    >*/}
            {/*        <MenuItem key={'Any'} value={'Any'} onClick={() => {setFilter('severityLevel')}}>{t('Any')}</MenuItem>*/}
            {/*        {["HIGH", "MEDIUM" , "LOW"].map((e) => (<MenuItem key={e} value={e} onClick={() => {setFilter('severityLevel', e)}}>{e}</MenuItem>))}*/}
            {/*    </TextField>*/}
            {/*</Box>*/}

            {/*<TableContainer onScroll={onScroll} ref={scrollRef} sx={{mt: '22px', minHeight: '60px'}} className={'withoutBorder'}>*/}
            {/*    <Table stickyHeader={!isLessThen1050}>*/}
            {/*        {isLessThen1050 ?*/}
            {/*            <TableBody>*/}
            {/*                {isNoConflictsFound ? <TableRow><TableCell>{t('Nothing found')}</TableCell></TableRow>*/}
            {/*                    :*/}
            {/*                    conflicts.map((e) => (*/}
            {/*                    <TableRow key={e?.id}>*/}
            {/*                        <TableCell style={{ padding: 0, borderBottom: 'none' }}>*/}
            {/*                            <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} sx={{borderRadius: '8px', m: '6px', p: '8px', boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)'}}>*/}
            {/*                                <Box display={'flex'} >*/}
            {/*                                    <Typography variant={'body2'} sx={{color: colors.lightGrayText, whiteSpace: 'nowrap'}}>{t('Conflict status')}</Typography>*/}
            {/*                                    <Typography variant={'body2'} sx={{color: colors.darkText, ml: !revDir ? '6px' : undefined, mr: revDir ? '6px' : undefined}}>{e?.status}</Typography>*/}
            {/*                                </Box>*/}
            {/*                                <Box display={'flex'}>*/}
            {/*                                    <Typography variant={'body2'} sx={{color: colors.lightGrayText, whiteSpace: 'nowrap'}}>{t('Last verification date')}</Typography>*/}
            {/*                                    <Typography variant={'body2'} sx={{color: colors.darkText, ml: !revDir ? '6px' : undefined, mr: revDir ? '6px' : undefined}}>{e?.verifiedDate?.split('T')[0]}</Typography>*/}
            {/*                                </Box>*/}
            {/*                                <Box display={'flex'}>*/}
            {/*                                    <Typography variant={'body2'} sx={{color: colors.lightGrayText, whiteSpace: 'nowrap'}}>{t('Account')}</Typography>*/}
            {/*                                    <Typography variant={'body2'} sx={{color: colors.darkText, ml: !revDir ? '6px' : undefined, mr: revDir ? '6px' : undefined}}>{e?.account}</Typography>*/}
            {/*                                </Box>*/}
            {/*                                <Box display={'flex'} alignItems={'center'}>*/}
            {/*                                    <Typography variant={'body2'} sx={{color: colors.lightGrayText, whiteSpace: 'nowrap'}}>{t('Application')}</Typography>*/}
            {/*                                    <Typography variant={'body2'} sx={{color: colors.darkText, ml: !revDir ? '6px' : undefined, mr: revDir ? '6px' : undefined}}>{e?.applications.map(a => a.name).join(", ")}</Typography>*/}
            {/*                                </Box>*/}
            {/*                                <Box display={'flex'} alignItems={'center'}>*/}
            {/*                                    <Typography variant={'body2'} sx={{color: colors.lightGrayText, whiteSpace: 'nowrap'}}>{t('Rule name')}</Typography>*/}
            {/*                                    {(e?.rule.name.toUpperCase() === 'NOT EXIST' || e?.applications.some(e => e.name.toLowerCase() === 'removed')) ?*/}
            {/*                                        <Typography variant={'body2'} sx={{whiteSpace: 'nowrap',  ml: '6px', mr: '6px'}}>{e?.rule.name || 'NOT EXIST'}</Typography>*/}
            {/*                                        :*/}
            {/*                                        <Typography variant={'body2'} sx={{color: colors.primary.blue, cursor: 'pointer', whiteSpace: 'nowrap', ml: '6px', mr: '6px'}}>{e?.rule.name}</Typography>*/}
            {/*                                    }*/}

            {/*                                </Box>*/}
            {/*                                {e?.status.toUpperCase() === "UNRESOLVED" && <Button variant={'text'} size={'small'} disabled={e.applications.some(e => e.name.toLowerCase() === 'removed')} onClick={(event) => {e && handleOpenViewConflict(event, e)}} sx={{textTransform: 'none', width: 'maxContent', pl: 0, pr: 0}}>{t('View conflict')}</Button>}*/}
            {/*                                {e?.status.toUpperCase() === "ALLOWED" && <Button variant={'text'} size={'small'} disabled={isLoadingData || e?.rule.name.toUpperCase() === 'NOT EXIST' || e.applications.some(e => e.name.toLowerCase() === 'removed')} onClick={(event) => { e && handleOpenAllowedConflictData(event, e.rule.id, e.account)}} sx={{textTransform: 'none', width: 'maxContent', pl: 0, pr: 0}}>{e?.rule.name.toUpperCase() === 'NOT EXIST' ? t('Not available') : t('Exception added')}</Button>}*/}
            {/*                                {e?.status.toUpperCase() === "DENIED" && <Button variant={'text'} size={'small'} disabled={e.applications.some(e => e.name.toLowerCase() === 'removed')} onClick={(event) => {e && handleOpenDeniedConflictData(event, e)}} sx={{textTransform: 'none', width: 'maxContent', pl: 0, pr: 0}}>{t('Notification sent')}</Button>}*/}
            {/*                            </Box>*/}
            {/*                        </TableCell>*/}
            {/*                    </TableRow>*/}
            {/*                ))}*/}
            {/*                {isLessThen1050 && <TableCardSkeleton rows={5} withButtons={false} /> }*/}
            {/*            </TableBody>*/}
            {/*            :*/}
            {/*            <>*/}
            {/*                <TableHead>*/}
            {/*                    <TableRow>*/}
            {/*                        <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {t('Conflict status')}</TableCell>*/}
            {/*                        <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {t('Last verification date')}</TableCell>*/}
            {/*                        <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {t('Account')}</TableCell>*/}
            {/*                        <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {t('Application')}</TableCell>*/}
            {/*                        <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'} >{t('Rule name')}</TableCell>*/}
            {/*                        <TableCell sx={{maxWidth: '120px'}} align={revDir ? 'right' : 'left'} >{t('Severity level')}</TableCell>*/}
            {/*                        <TableCell sx={{maxWidth: '120px'}} />*/}
            {/*                    </TableRow>*/}
            {/*                </TableHead>*/}

            {/*                <TableBody>*/}
            {/*                    { isNoConflictsFound ? <TableRow><TableCell>{t('Nothing found')}</TableCell></TableRow>*/}
            {/*                        :*/}
            {/*                        conflicts.map((e) => (*/}
            {/*                        <TableRow key={e?.id}>*/}
            {/*                            <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {e?.status}</TableCell>*/}
            {/*                            <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {e?.verifiedDate?.split('T')[0]}</TableCell>*/}
            {/*                            <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {e?.account}</TableCell>*/}
            {/*                            <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {e?.applications.map(a => a.name).join(', ')}</TableCell>*/}
            {/*                            <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}>*/}
            {/*                                {(e?.rule.name.toUpperCase() === 'NOT EXIST' || e?.applications.some(e => e.name.toLowerCase() === 'removed')) ?*/}
            {/*                                    <Typography variant={'body2'} sx={{whiteSpace: 'nowrap'}}>{e?.rule.name}</Typography>*/}
            {/*                                    :*/}
            {/*                                    <Typography variant={'body2'} sx={{color: colors.primary.blue, cursor: 'pointer', whiteSpace: 'nowrap'}} onClick={() => e && goToRuleExact(e?.rule.id)}>{e?.rule.name}</Typography>*/}
            {/*                                }*/}
            {/*                            </TableCell>*/}
            {/*                            <TableCell sx={{maxWidth: '120px'}} align={revDir ? 'right' : 'left'}> {e?.severityLevel}</TableCell>*/}
            {/*                            <TableCell sx={{maxWidth: '120px'}} align={revDir ? 'right' : 'left'}>*/}
            {/*                                {e?.status.toUpperCase() === "UNRESOLVED" && <Button variant={'text'} disabled={e.applications.some(e => e.name.toLowerCase() === 'removed')} size={'small'} onClick={(event) => {e && handleOpenViewConflict(event, e)}} sx={{textTransform: 'none', width: 'maxContent', pl: 0, pr: 0}}>{t('View conflict')}</Button>}*/}
            {/*                                {e?.status.toUpperCase() === "ALLOWED" && <Button variant={'text'} disabled={isLoadingData || e?.rule.name.toUpperCase() === 'NOT EXIST' || e.applications.some(e => e.name.toLowerCase() === 'removed')} size={'small'} onClick={(event) => {e && handleOpenAllowedConflictData(event, e.rule.id, e.account)}} sx={{textTransform: 'none', width: 'maxContent', pl: 0, pr: 0, textAlign: 'start'}}>{e?.rule.name.toUpperCase() === 'NOT EXIST' ? t('Exception not available') : t('Exception added')}</Button>}*/}
            {/*                                {e?.status.toUpperCase() === "DENIED" && <Button variant={'text'} disabled={e.applications.some(e => e.name.toLowerCase() === 'removed')} size={'small'} onClick={(event) => {e && handleOpenDeniedConflictData(event, e)}} sx={{textTransform: 'none', width: 'maxContent', pl: 0, pr: 0}}>{t('Notification sent')}</Button>}*/}
            {/*                            </TableCell>*/}
            {/*                        </TableRow>*/}
            {/*                    ))}*/}
            {/*                    {(bottomLoader || isLoadingList) && <TableRowSkeleton columns={6} />}*/}
            {/*                </TableBody>*/}
            {/*            </>*/}
            {/*        }*/}
            {/*    </Table>*/}
            {/*</TableContainer>*/}

            <AllowedConflictDataDialog />
            <DeniedConflictDataDialog />
            <ViewConflictDialog />
            <DenyConflictDialog />
            <AllowConflictDialog />

        </Box>
    )
}