import {useEffect, useState} from "react";
import {useAppDispatch} from "../../../../../../newShared/redux";
import {useSelector} from "react-redux";
import {
    hideCreateSettingRowDialog,
    isSettingsLoadingSelector,
    vendorsDialogsSelector,
    vendorsSettingsSelector
} from "../../../store/slice";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {createVendorsSettingsRowAction} from "../../../store/actions";
import {useHistory} from "react-router-dom";
import {PATH_LOCAL_VENDORS_SETTINGS_EXACT} from "../../../constants";

export const useCreateSetting = (onSuccessDialog?: (str: string) => void) => {
    const dispatch = useAppDispatch();
    const {createSettingRow} = useSelector(vendorsDialogsSelector);
    const isSettingsLoading = useSelector(isSettingsLoadingSelector);
    const settings = useSelector(vendorsSettingsSelector);
    const history = useHistory();

    const handleClose = () => {
        setForm({
            newName: ''
        });
        setIsExists(false);
        dispatch(hideCreateSettingRowDialog());
    }

    const {form, setForm, handleChange} = useForm<{ newName: string}>({
        newName: ''
    });
    const [isExist, setIsExists] = useState<boolean>(false);
    const isOk = createSettingRow.type === null? false : form.newName.trim().length && settings && !settings[createSettingRow.type].some(e => e.name.trim().toLowerCase() === form.newName.trim().toLowerCase())
    // console.log(`
    //         const isOk = createSettingRow.type === null? false : form.newName.trim().length && settings && settings[createSettingRow.type].some(e => e.name.trim().toLowerCase() !== form.newName.trim().toLowerCase())
    //         createSettingRow.type === null: ${createSettingRow.type === null}
    //         form.newName.trim().length: ${Boolean(form.newName.trim().length)} && settings ${Boolean(settings)} && settings[createSettingRow.type].some(e => e.name.trim().toLowerCase() !== form.newName.trim().toLowerCase() ${Boolean(settings[createSettingRow.type!].some(e => e.name.trim().toLowerCase() !== form.newName.trim().toLowerCase()))}: ${form.newName.trim().length && settings && (createSettingRow.type) && settings[createSettingRow.type].some(e => e.name.trim().toLowerCase() !== form.newName.trim().toLowerCase())}
    //
    // `)

    const onSuccess = () => {
        createSettingRow.type && history.push(PATH_LOCAL_VENDORS_SETTINGS_EXACT.replace(':id',createSettingRow.type))
    };

    const handleSubmit = () => {
        createSettingRow.type &&
        (form.newName && settings && !isExist) && dispatch(createVendorsSettingsRowAction({
            data: {
                workspaceId: '',
                organizationId: '',
                field: createSettingRow.type,
                title: form.newName
            },
            onSuccess: onSuccessDialog ? () => onSuccessDialog(form.newName) : onSuccess,
        }));

    }

    useEffect(() => {
        if (createSettingRow.isOpen){
            setForm({
                newName: '',
            });
        }
        //eslint-disable-next-line
    },[createSettingRow.isOpen])

    useEffect(() => {
        if (settings && createSettingRow.type!==null) {
               const cond = !settings[createSettingRow.type].some(e => e.name.toLowerCase().trim() === form.newName.toLowerCase().trim());
                setIsExists(!cond);
            }
        //eslint-disable-next-line
        }, [form.newName]);


    return{
        type: createSettingRow.type,
        isOpen: createSettingRow.isOpen,
        isLoading: createSettingRow.isLoading,
        isSettingsLoading,
        handleClose,
        handleSubmit,
        handleChange,
        form,
        isOk,
        isExist,
    }
}