import {TRulesConflict} from "../../../types";
import {useDispatch} from "react-redux";
import {hideRunRuleCheck, openSaveConflictsSuccess} from "../../../store/slice";
import {useSegregationOfDuties} from "../../useSegregationOfDuties";
import {addRulesConflictsAction} from "../../../store/actions";
import {useEffect, useState} from "react";

export const useRunRuleCheckDialog = () => {
    const dispatch = useDispatch();

    const {
        runRuleCheckConflicts: conflicts,
        loadings: {runRuleCheck: isLoadingRunRuleCheck, addRulesConflicts: isLoadingAddConflicts},
        dialogs: {runRuleCheck: {isOpen}},
    } = useSegregationOfDuties();


    const [conflictsToAdd, setConflictsToAdd] = useState<TRulesConflict[]>([]);
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

    const handleClose = () => {
        dispatch(hideRunRuleCheck());
    }

    const toggleCheckConflict = (conflict: TRulesConflict) => {
        if (conflictsToAdd.findIndex(e => e.id === conflict.id) > -1) {
            setConflictsToAdd(prev => prev && prev.filter(e => e.id !== conflict.id));
            setIsAllSelected(false);
        }
        else {
            setConflictsToAdd(prev => prev && [conflict, ...prev]);
            // conflictsToAdd.length === conflicts?.length && setIsAllSelected(true);
        }
    }

    const onSuccess = () => {
        dispatch(openSaveConflictsSuccess());
    }

    const handleSave = () => {
        conflictsToAdd.length > 0 && dispatch(addRulesConflictsAction({data: {conflicts: conflictsToAdd, workspaceId: '', organizationId: ''}, onSuccess}));
    }


    const handleSelectAll = (isAll: boolean) => {
        if(isAll) {
            setConflictsToAdd( conflicts);
            setIsAllSelected(true);
        } else {
            setConflictsToAdd([]);
            setIsAllSelected(false);
        }
    }

    const isOk = () => {
        return conflicts && conflicts.length > 0 && conflictsToAdd.length > 0;
    }

    useEffect(() => {
        if (isOpen) {
            setConflictsToAdd([]);
            setIsAllSelected(false);
        }


        //eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        conflictsToAdd.length === conflicts.length && setIsAllSelected(true);


        //eslint-disable-next-line
    }, [conflictsToAdd]);

    useEffect(() => {
        (conflicts && conflicts.length === 1) && setConflictsToAdd(conflicts);

        //eslint-disable-next-line
    }, [conflicts]);




    return{
        isOpen,
        conflicts,
        handleClose,
        handleSave,
        isLoadingRunRuleCheck,
        isLoadingAddConflicts,
        handleSelectAll,
        isAllSelected,
        conflictsToAdd,
        toggleCheckConflict,

        isOk: isOk(),
    }
}