import {FC, useEffect} from 'react';
import {LeftSecondMenu} from "../../barsEnvironment/leftMenu/components/leftSecondMenu";
import {Routes} from "./routes";
import {useReport} from "./hooks/useReport";


export const Reports: FC = () => {


    const {cleanReport, cleanSelectedControl} = useReport();

    useEffect(() => {
        return () => {
            cleanReport();
            cleanSelectedControl();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <LeftSecondMenu>
            <Routes/>
        </LeftSecondMenu>
    )
}
