import {TAccountShort} from "../../types";
import * as S from "../styled";
import {Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import Divider from "@mui/material/Divider";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const EmployeeServiceRow = ({service}: {service: TAccountShort}) => {
    const {revDir} = useMainTranslation();
    return(
        <>
            <S.MainTableRowWrapper disableHover>
                <S.TableRowWrapper disableHover jc={'flex-start'}>
                    <S.TableServicesItemWrapper>
                        <img src={service.app.logo ?? ''} alt={service.app.name} width={'28px'}/>
                        <Typography variant={'body1'} sx={{color: colors.darkText, margin: !revDir ? '0 0 0 8px' : '0 8px 0 0'}}>{service.app.name}</Typography>
                    </S.TableServicesItemWrapper>

                    <S.TableServicesItemWrapper>
                        <Typography variant={'body1'} sx={{color: colors.darkText}}>{service.name}</Typography>
                    </S.TableServicesItemWrapper>
                </S.TableRowWrapper>
            </S.MainTableRowWrapper>
            <Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable, margin: '16px 0 0 0'}}/>
        </>
    )
}