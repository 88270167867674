import React, {FC} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import * as S from "../styled";
import {Box, Checkbox, Collapse, FormControlLabel, Radio, TextField, Typography} from "@mui/material";
import {TypographyNew} from "../../../../../newShared/components/TypographyNew";
import colors from "../../../../../newShared/theme/colors";
import {TrainingExamQuestionModel, TrainingExamQuestionType} from "../../../../../newShared/GQLTypes";
import {SectionArrow} from "../../../../../newShared/components/SectionArrow";
import {CheckBox, Notes, RadioButtonChecked, Title} from "@mui/icons-material";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {
    FlexColumn,
    FlexRow
} from "../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";


type TExamQuestionSectionProps = {
    isOpen: boolean,
    question: TrainingExamQuestionModel,
    toggleOpen: (questionId: string) => void
}

export const ExamQuestionSection: FC<TExamQuestionSectionProps> = ({question, isOpen, toggleOpen}) => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.pathExams'});
    const {isMobile} = useMedia();


    return (
        <>
            <S.TrainingSectionBox revDir={revDir} onClick={() => toggleOpen(question.questionId)}>
                <Box alignItems={'center'} display={'flex'} width={isMobile ? '100%' : '70%'} gap={'10px'}>
                    <SectionArrow isOpen={isOpen} />
                    <Typography variant={'*bodyText2'} sx={{whiteSpace: 'nowrap'}}>{`${t('Question')} ${question.order + 1}`}</Typography>
                    <TypographyNew variant={'*bodyText2_semibold'} sx={{flexGrow: 1}} color={colors.grayText} noWrap>{question.question}</TypographyNew>
                </Box>

                {!isMobile && question.type === TrainingExamQuestionType.Checkboxes &&
                    <Box display={'flex'} alignItems={'center'} gap={'8px'} m={'0 8px'} whiteSpace={'nowrap'}>
                        <CheckBox fontSize={'small'} sx={{color: colors.text.grey_dark}}/>
                        <TypographyNew variant={'*bodyText2'} color={colors.grayText} >{t('Checkboxes')}</TypographyNew>
                    </Box>
                }

                {!isMobile && question.type === TrainingExamQuestionType.MultipleChoice &&
                    <Box display={'flex'} alignItems={'center'} gap={'8px'} m={'0 8px'} whiteSpace={'nowrap'}>
                        <RadioButtonChecked fontSize={'small'} sx={{color: colors.text.grey_dark}}/>
                        <TypographyNew variant={'*bodyText2'} color={colors.grayText} >{t('Multiple choice')}</TypographyNew>
                    </Box>
                }
                {!isMobile && question.type === TrainingExamQuestionType.Paragraph &&
                    <Box display={'flex'} alignItems={'center'} gap={'8px'} m={'0 8px'} whiteSpace={'nowrap'} >
                        <Notes fontSize={'small'} sx={{color: colors.text.grey_dark}}/>
                        <TypographyNew variant={'*bodyText2'} color={colors.grayText} >{t('Paragraph')}</TypographyNew>
                    </Box>
                }
                {!isMobile && question.type === TrainingExamQuestionType.ShortAnswer &&
                    <Box display={'flex'} alignItems={'center'} gap={'8px'} m={'0 8px'} whiteSpace={'nowrap'} >
                        <Title fontSize={'small'} sx={{color: colors.text.grey_dark}}/>
                        <TypographyNew variant={'*bodyText2'} color={colors.grayText} >{t('Short answer')}</TypographyNew>
                    </Box>
                }

            </S.TrainingSectionBox>

            <Collapse in={isOpen} sx={{flexShrink: 0, width: '100%'}} unmountOnExit mountOnEnter>
                <S.TrainingSectionContainer>
                    <Typography variant={'*bodyText1'} sx={{whiteSpace: 'break-spaces'}}>{question.description}</Typography>
                    <Typography variant={'*bodyText2_semibold'}>{question.question}</Typography>

                    { question.type === TrainingExamQuestionType.ShortAnswer && <TextField disabled placeholder={t('Type your answer')} fullWidth size={'small'} /> }

                    { question.type === TrainingExamQuestionType.Paragraph &&<TextField disabled placeholder={t('Type your answer')} fullWidth multiline rows={4} /> }

                    { (question.type === TrainingExamQuestionType.MultipleChoice || question.type === TrainingExamQuestionType.Checkboxes) &&
                        <FlexColumn sx={{width: '100%'}}>
                            {question.options && [...question.options]
                                .sort((a, b) => a.order - b.order)
                                .map(e =>
                                    <FormControlLabel checked={e.correct || false} key={e.option}
                                                      control={question.type === TrainingExamQuestionType.Checkboxes
                                                          ? <Checkbox disabled size={"small"}/>
                                                          : <Radio disabled size={"small"}/>
                                                      }
                                                      label={
                                                          <FlexRow sx={{gap: '16px'}}>
                                                              <Typography variant={'*bodyText1'} sx={{filter: 'grayscale(1)'}}>{e.option}</Typography>
                                                              {e.correct && <Typography variant={'*bodyText1'} sx={{color: colors.text.success}}>{t('Correct')}</Typography>}
                                                          </FlexRow>
                                                      }
                                    />
                                )}
                        </FlexColumn>
                    }
                </S.TrainingSectionContainer>
            </Collapse>
        </>
    )
}
