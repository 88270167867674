import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideFileNotSupportedDialog} from "../../../store/slice";
import {DownloadFile} from "../../../store/actions";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React from "react";
import {Typo} from "../../../../../../newShared/components/Typography";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const FileNotSupportedDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});
    const dispatch = useDispatch();
    const {isOpen, file} = useSelector(dialogs).fileNotSupportedDialog;

    const handleClose = () => {
        dispatch(hideFileNotSupportedDialog());
    }

    const download = () => {
        (file && file.documentId) && dispatch(DownloadFile({fileId: file.documentId, name: file.name}));
    }

    return(
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth={'xs'}>
            <DialogTitle>{file?.name ?? ''}</DialogTitle>
            <DialogContent>
                <Typo margin={'5px'}>{file ? t(`File type {{fileType}} is not supported`, {fileType: file.name.split('.')[1].toUpperCase()}) : t('This file type is not supported')}</Typo>
                <Typo margin={'5px'}>{t('You can download this file.')}</Typo>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t('Cancel')}</Button>
                <Button onClick={download}>{t('Download')}</Button>
            </DialogActions>
        </Dialog>
    )
}