import {useCallback, useState} from "react";
import {useLazyQuery} from "@apollo/client";
import {AutocompleteCollaboratorsQuery, AutocompleteCollaboratorsQueryVariables} from "../../../../GQLTypes";
import {autocompleteCollaborators} from "../../api/query";
import debounce from "lodash.debounce";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../newDomain/authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {TAutocompleteCollaboratorsProps} from "../../types";

export const useAutocompleteCollaboratorsFetchMore = <M extends boolean | undefined, D extends boolean | undefined, F extends boolean | undefined>(props: TAutocompleteCollaboratorsProps<M, D, F>) => {
    const {workspaceId: defaultWorkspaceId, organizationId: defaultOrganizationId} = UseManageWorkspacesAndOrganizations();

    const workspaceId = props.workspaceId || defaultWorkspaceId || '';
    const organizationId = props.organizationId || defaultOrganizationId || '';

    const [fetchMoreLoading, setFetchMoreLoading] = useState<boolean>(false);

    const [
        getData,
        {loading: _loading, data: _data, fetchMore: _fetchMore, variables}
    ] = useLazyQuery<AutocompleteCollaboratorsQuery, AutocompleteCollaboratorsQueryVariables>(autocompleteCollaborators, {
        variables: {
            workspaceId: workspaceId,
            organizationId: organizationId,
            autocompleteInput: {page: 0, count: 10, search: ''},
            byAllWorkspaces: props.byAllWorkspaces
        },
        fetchPolicy: 'cache-and-network',
    });
    const data =_data?.autocompleteCollaborators;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetch0 = useCallback(
        debounce((search: string) => getData({variables: {
                workspaceId: workspaceId,
                organizationId: organizationId,
                byAllWorkspaces: props.byAllWorkspaces,
                autocompleteInput: {page: 0, count: 10, search}
            }}), 300), [getData, workspaceId, organizationId, props.byAllWorkspaces]
    );

    const fetchMore = () => {
        if (!data || !hasMore) return;

        setFetchMoreLoading(true);
        _fetchMore({
            variables: {autocompleteInput: {...variables?.autocompleteInput, page: data?.pageInfo.page + 1 || 0}},
            updateQuery: (prev, {fetchMoreResult}) => {
                return {
                    autocompleteCollaborators: {
                        result: [
                            ...prev?.autocompleteCollaborators?.result,
                            ...fetchMoreResult?.autocompleteCollaborators?.result || [],
                        ],
                        pageInfo: fetchMoreResult?.autocompleteCollaborators?.pageInfo,
                    },
                };
            },
        }).finally(() => {
            setFetchMoreLoading(false);
        })
    }

    const hasMore = data && data.pageInfo.total > data.result.length;

    return {
        data,

        fetch0,
        fetchMore,

        loading: _loading || fetchMoreLoading,
        hasMore,
    };
};