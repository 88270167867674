import {useDispatch, useSelector} from "react-redux";
import {
    dialogs,
    employees,
    hideNonCustomAppAccountMapDialog,
    loadings,
    selectedAppEditable
} from "../../../store/slice";
import {useEffect, useState} from "react";
import {EXTERNAL_TYPE, MAPPED_TYPE, ORHAN_TYPE} from "../../../constants";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {AccessManagementAppAccount} from "../../../types";
import {UpdateNonCustomAccessManagementAppRow} from "../../../store/actions";
import {TShortEmployee} from "../../../../employeesRefactor/types";

export const useNonCustomAppAccountMapDialog = () => {
    const dispatch = useDispatch();
    const {isOpen, row} = useSelector(dialogs).nonCustomAppAccountMapDialog;
    const _employees = useSelector(employees);
    const app = useSelector(selectedAppEditable);
    const {updateNonCustomOneRow} = useSelector(loadings);

    //form
    const {form, setForm, handleChange} = useForm<AccessManagementAppAccount>({
        employee: null,
        account: '',
        accountType: null,
        status: '',
        roles: [],
        employeeId: '',
        accountId: null
    });
    const [selectedEmployee, selectEmployee] = useState<TShortEmployee | null>(null);


    useEffect(() => {
        if(isOpen && row){
            setForm(row);
            const empl = _employees.find(e => e.id === row.employeeId);
            empl && selectEmployee(empl);
        }
        if(!isOpen){
            cleanUp();
        }

        //eslint-disable-next-line
    }, [isOpen]);

    //actions
    const handleClose = () => {
        dispatch(hideNonCustomAppAccountMapDialog());
    }

    const handleSubmit = () => {
        if(isOk() && app && app.integrationTypeConfig){
            dispatch(UpdateNonCustomAccessManagementAppRow({
                data: {
                    account: (form.accountType === EXTERNAL_TYPE || form.accountType === ORHAN_TYPE) ? {...form, employeeId: null} : {...form, employee: selectedEmployee? `${selectedEmployee.firstName} ${selectedEmployee.lastName}` : null, employeeId: selectedEmployee?.id ?? null},
                    applicationId: app.integrationTypeConfig.applicationId,
                    accessManagementAppId: app.id
                }
            }))
        }
    }

    const isOk = () => {
        if(form.accountType === ORHAN_TYPE){
            return form.account.trim().length > 0 && form.employeeId === null
        }else if(form.accountType === EXTERNAL_TYPE){
            //todo make sure employeeId is null
            return form.account.trim().length > 0 &&
                (form.employee ?? '').length > 0 &&
                form.employeeId === null
        }else{
            //mapped or service
            return selectedEmployee !== null && form.accountType !== null
        }
    }

    const handleSetType = (type: string | null) => {
        if(type === EXTERNAL_TYPE) {
            setForm({...form, accountType: type, employee: '', employeeId: null});
        }else if(type === ORHAN_TYPE){
            setForm({...form, accountType: type, employee: '', employeeId: null});
        }else{
            setForm({...form, accountType: type ?? MAPPED_TYPE, employee: null, employeeId: null});
        }
    }

    const handleSetEmployee = (empl: TShortEmployee | null) => {
        empl && selectEmployee(empl);
    }

    const cleanUp = () => {
        selectEmployee(null);
        setForm({
            employee: null,
            account: '',
            accountType: null,
            status: '',
            roles: [],
            employeeId: '',
            accountId: null
        });
    }

    return{
        isOpen,
        handleClose,
        handleSubmit,
        handleSetType,
        handleSetEmployee,
        isOk: isOk(),
        handleChange,
        form,
        row,
        employees: _employees,
        selectedEmployee,
        isLoading: updateNonCustomOneRow
    }
}