import * as S from "./styled";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useAddDocumentDialog} from "../../../../hooks/dialogs/useAddDocumentDialog";
import {
    TypographyBody2,
    TypographyBodySmall,
    TypographyBodySmallSemiBold,
    TypographySubtitle1
} from "../../../../../../../newShared/components/Inputs/styled";
import {v4 as uuid} from "uuid";
import {SelectFolderTree} from "../../../selectFolderTree";
import CommonSwitch from "../../../../../../../newShared/components/Basic/CommonSwitch";
import React, {FC, useCallback, useEffect, useState} from "react";
import {ApproversBlockView} from "./approversBlockView";
import {Box, Button, Checkbox, IconButton} from "@mui/material";
import {Item, TActorEmailName, TCreateFormDoc, TIdRecipient} from "../../../../types";
import debounce from "lodash.debounce";
import {ReactComponent as RecipientIcon} from "../../../../../../../newShared/images/documents/recipient-icon.svg";
import {ReactComponent as MessageIcon} from "../../../../../../../newShared/images/documents/message-icon.svg";
import {
    ReactComponent as CompleteMessageIcon
} from "../../../../../../../newShared/images/documents/complete-message-icon.svg";

import {ReactComponent as AddRecipientIcon} from "../../../../../../../newShared/images/documents/add-recipient.svg";
import {ConnectableElement, DragSourceMonitor, useDrag, useDrop} from "react-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {LightTooltip} from "../../../../../../../newShared/components/editorUnion/components/editorSideMenu/styled";
import {getMsFromDays} from "../../../../../../../newShared/utils/dateTools";
import {validateEmail} from "../../../../../../../newShared/utils/text";
import {optionIsTCollaborator} from "../../../../../../../newShared/components/AutocompleteCollaborators/types";
import {AutocompleteCollaborators} from "../../../../../../../newShared/components/AutocompleteCollaborators";
import {defaultGetOptionDisabled} from "../../../../../../../newShared/components/AutocompleteCollaborators/helpers";


export const ItemTypes = {
    CARD: 'card',
}

interface Props {
    isTemplatePage: boolean,
    setRecipientsError: (state: Record<string, boolean>) => void
}

export const FillInfoView: FC<Props> = (props) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});
    const {isTemplatePage, setRecipientsError} = props;
    const {
        rootFolders,
        templatesFolders,
        path,
        templatesPath,
        formActors: {
            form,
            setForm,
        },

        //doc
        handleSetDocName,
        selectedFolder,
        // setSelectedFolder,
        handleSetFolder,
        handleOpenAddMessage,

        errorActiveFields,
        setErrorActiveFields,
    } = useAddDocumentDialog();

    const [isApprovalsBlock, setIsApprovalsBlock] = useState<boolean>(false);
    const [isRecipientsCustomOrder, setIsRecipientsCustomOrder] = useState<boolean>(true);
    const [docName, setDocName] = useState<string>(form.name);

    const [touchedField, setTouchedField] = useState<string | null>(null);


    const handleAddRecipient = () => {
        setForm({
            ...form,
            recipients: [
                ...form.recipients,
                {
                    id: uuid(),
                    fields: null,
                    actor: { email: '', lastName: '', firstName: '' },
                    order: isRecipientsCustomOrder ? form.recipients.length + 1 : 0, message: '',
                    eta: getMsFromDays(7),
                }
            ],
        });

    }

    const handleToggleRecipientOrder = (isOrdered: boolean) => {
        setForm({
            ...form,
            recipients: form.recipients.map((r, index) => ({
                ...r,
                order: isOrdered ? index + 1 : 0,
            })),
        });

        setIsRecipientsCustomOrder(isOrdered);
    }

    const handleDeleteRecipient = (recipientId: string) => {
        setForm({
            ...form,
            recipients: form.recipients.filter(r => r.id !== recipientId),
        });
    }

    const handleSetActorRecipient = (recipientId: string, actor: TActorEmailName) => {
        setForm({
            ...form,
            recipients: form.recipients.map((r)=> r.id === recipientId ? ({ ...r, actor}) : r),
        });
    }

    //eslint-disable-next-line
    useEffect(debounce(() => {
        form.name !== docName && handleSetDocName(docName);

        //eslint-disable-next-line
    }, 500), [docName]);

    // useEffect(() => {
    //     setDocName(_selectedNewTemplate?.name || '');
    //     handleSetDocRecipients(_selectedNewTemplate?.recipients || []);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [_selectedNewTemplate]);

//DND
    const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));
    const setRecipients = useCallback(
        (cols: TCreateFormDoc['recipients']) => {
            setForm({
                ...form,
                recipients: cols.map((e, i) => ({...e, order: i})),
            })
            //eslint-disable-next-line
        }, [form]
    )
    const findColumn: (id: string) => {column: TCreateFormDoc['recipients'][number], index: number} | null = useCallback(
        (id: string) => {
            const column = form.recipients.find(e => e.id === id);
            // console.log(`findColumn for ${key} - ${JSON.stringify(column)} | ${column ? settings.indexOf(column) : null}`);
            return column ? {
                column,
                index: form.recipients.indexOf(column)
            } : null;
        },
        [form.recipients],
    )

    const moveColumn: (key: string, atIndex: number) => void = useCallback(
        (key: string, atIndex: number) => {
            const res = findColumn(key);
            if(res){
                //res - {column: lastName, index: 1}
                //atIndex - 0
                const copyArr = JSON.parse(JSON.stringify(form.recipients));
                // console.log(`copyArr: ${JSON.stringify(copyArr)}`);
                copyArr.splice(res.index, 1) //remove
                copyArr.splice(atIndex, 0, res.column) //insert
                // const reordered = settings.splice(res.index, 1).splice(atIndex, 0, res.column);
                // console.log(`moveColumn for ${key} settings.splice(${res.index}, 1).splice(${atIndex}, 0, ${JSON.stringify(res.column)}) | \n newArr: new arr: ${JSON.stringify(copyArr)}`)
                setRecipients(copyArr);
                // console.log(`moveColumn for ${key} - from ${res?.index} | to ${atIndex} \n new arr: ${JSON.stringify(reordered)}`);
            }else{
                // console.log(`--Column with key ${key} not found!`);
            }
        },
        [findColumn, form.recipients, setRecipients],
    )


    return (
        <S.InfoViewContainer>
            <S.DocNameTextField placeholder={t('Document name')} value={docName} onChange={(e) => setDocName(e.target.value)} />
            <S.DocDivider />
            <TypographyBodySmallSemiBold>{t('Save in folder')}</TypographyBodySmallSemiBold>
            <SelectFolderTree t={t} folders={isTemplatePage ? templatesFolders : rootFolders} path={isTemplatePage ? templatesPath : path} selectedFolder={selectedFolder} setSelectedFolder={handleSetFolder}/>
            <S.DocDivider />

            {/*APPROVERS BLOCK*/}
            <S.FlexBox>
                <CommonSwitch label={t('Set approval workflow')} checked={isApprovalsBlock} onChange={() => setIsApprovalsBlock(!isApprovalsBlock)} />
            </S.FlexBox>

            {isApprovalsBlock &&
                <ApproversBlockView
                    form={form}
                    setForm={setForm}
                    handleOpenAddGroupMessage={handleOpenAddMessage}
                />}

            <S.DocDivider />

            {/*RECIPIENTS BLOCK*/}
            <TypographySubtitle1>{t('Add recipients who need to fill out and (or) sign this document')}</TypographySubtitle1>
            <S.FlexBox>
                <Checkbox checked={isRecipientsCustomOrder} onChange={() => handleToggleRecipientOrder(!isRecipientsCustomOrder)} />
                <TypographyBody2>{t('Custom recipients order')}</TypographyBody2>
            </S.FlexBox>
            <div ref={drop} style={{width: '100%'}}>
                {form.recipients.map((r, index) => (
                    <RecipientItem
                        key={r.id}
                        moveColumn={moveColumn}
                        findColumn={findColumn}
                        index={index}
                        form={form}
                        recipient={r}
                        isRecipientsCustomOrder={isRecipientsCustomOrder}
                        handleDelete={handleDeleteRecipient}
                        handleOpenAddMessage={handleOpenAddMessage}
                        handleSetActorRecipient={handleSetActorRecipient}
                        touchedField={touchedField}
                        setTouchedField={setTouchedField}
                        errorActiveFields={errorActiveFields}
                        setErrorActiveFields={setErrorActiveFields}
                        setRecipientsError={setRecipientsError}
                    />
                ))}
            </div>

            <Button variant={'text'} sx={{textTransform: 'none', mt: '16px'}} startIcon={<AddRecipientIcon />} onClick={handleAddRecipient}>{t('Add Recipient')}</Button>
        </S.InfoViewContainer>
    )
}

interface RecipientItemProps {
    form: TCreateFormDoc,
    isRecipientsCustomOrder: boolean,
    index: number,
    moveColumn: (key: string, atIndex: number) => void,
    findColumn: (key: string) => {column: TCreateFormDoc['recipients'][number], index: number} | null
    recipient: TIdRecipient;
    handleDelete: (id: string) => void;
    handleOpenAddMessage: (data: {recipientId: string, oldMessage: string}) => void;
    handleSetActorRecipient: (id: string, actor: TActorEmailName) => void;
    touchedField: string | null;
    setTouchedField: (id: string | null) => void;
    errorActiveFields: string[];
    setErrorActiveFields: (ids: string[]) => void;
    setRecipientsError: (data: Record<string, boolean>) => void;
}
const RecipientItem: FC<RecipientItemProps> = (props) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});

    const [newRecipient, setNewRecipient] = useState<TActorEmailName | null>(null);
    const [error, setError] = useState<string | null>(null);

    const {
        form,
        index,
        recipient,
        isRecipientsCustomOrder,
        handleDelete,
        handleOpenAddMessage,

        findColumn,
        moveColumn,

        errorActiveFields,
        setErrorActiveFields,
        handleSetActorRecipient,

        setTouchedField,
        touchedField,

        setRecipientsError,
    } = props;

    useEffect(() => {
        if(newRecipient){
            handleSetActorRecipient(recipient.id, newRecipient);
            handleIsOkRecipientEmail({id: recipient.id, email: newRecipient.email});
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newRecipient]);

    const searchedColumn = findColumn(recipient.id);
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.CARD,
            item: {key: recipient.id, index: searchedColumn?.index ?? -1},
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const { key: droppedKey, index: originalIndex } = item;

                // console.log(`ROW useDrag END with item ${JSON.stringify(item)}`);
                const didDrop = monitor.didDrop();
                if (!didDrop && item) {
                    moveColumn(droppedKey, originalIndex);
                    // console.log(`ROW useDrag - moveColumn exec: ${droppedKey} | ${originalIndex}`);
                }
            },
        }),
        [recipient.id, searchedColumn, moveColumn],
    );

    const [, drop] = useDrop(
        () => ({
            accept: ItemTypes.CARD,
            hover({ key: draggedKey }: Item) {
                // console.log(`Card useDrop draggedKey:${draggedKey} in cardId ${column.key}`);
                if (draggedKey !== recipient.id) {
                    const col = findColumn(recipient.id)
                    if(col){
                        // console.log(`ROW useDrop - moveColumn exec: ${draggedKey} | ${col.index}`);
                        moveColumn(draggedKey, col.index);
                    }else{
                        // console.log(`ROW useDrop - col not found by key ${column.key}`)
                    }
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
                isActive: monitor.canDrop() && monitor.isOver(),
            }),
        }),
        [findColumn, moveColumn],
    );

    const handleIsOkRecipientEmail = ({id, email}:{id: string, email: string}): boolean => {
        if(!email?.length) {
            setErrorActiveFields(errorActiveFields.filter(e => e !== id));
            return true;
        }
        else if (!validateEmail(email)) {
            setError('Invalid email');
            setErrorActiveFields([...errorActiveFields, id]);
            return false;
        }
        else if (form.recipients.filter(r => r.id !== id ).some(r =>  r.actor.email === email)) {
            setError('Email already exists');
            setErrorActiveFields([...errorActiveFields, id]);
            return false;
        }

        setErrorActiveFields(errorActiveFields.filter(e => e !== id));
        return true; //!error
    }

    return (
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} marginTop={'12px'} width={'100%'} >
            {isRecipientsCustomOrder && <S.OrderBox>{index+1}</S.OrderBox>}

            <S.RecipientsContainer
                isDragging={isDragging ? true : undefined}
                isOrdered={isRecipientsCustomOrder ? true : undefined}
                ref={(node: ConnectableElement) => drag(drop(node))}
            >
                {isRecipientsCustomOrder &&  <DragIndicatorIcon/>}

                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                    <S.InfoActorFlexBox>
                        <RecipientIcon />
                        <TypographyBodySmall sx={{flexGrow: 1}} >{recipient.role || `${t('Recipient')} ${index+1}`}</TypographyBodySmall>


                        <IconButton size={'small'} onClick={() => handleOpenAddMessage({recipientId: recipient.id, oldMessage: recipient.message || ''})}>
                            {recipient.message  ?  <CompleteMessageIcon /> :  <MessageIcon />}
                        </IconButton>

                        <S.VerticalDivider />
                        {recipient.fields?.length ? (
                            <LightTooltip title={"Unable to remove recipient with assigned fields"}>
                                <span>
                                    <IconButton disabled>
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </span>
                            </LightTooltip>
                        ) : (
                            <IconButton onClick={() => handleDelete(recipient.id)}>
                                <DeleteOutlineIcon />
                            </IconButton>
                        )}
                    </S.InfoActorFlexBox>

                    <AutocompleteCollaborators
                        getOptionLabelCustom={"name+email"}
                        freeSoloType={"email"}
                        freeSolo
                        defaultValue={''}
                        helperText={(touchedField === recipient.id && error) || undefined}
                        onChange={value => {
                            if (!optionIsTCollaborator(value)) {
                                setNewRecipient({email: value as string, firstName: '', lastName: ''});
                            } else {
                                setNewRecipient({email: value?.email || '', firstName: value?.firstName || '', lastName: value?.lastName || ''});
                            }
                        }}
                        getOptionDisabled={defaultGetOptionDisabled(form.recipients.map(e => e.actor.email).filter(e => e !== recipient.actor.email))}

                        sx={{width: '100%', '& label': {top: '0px !important'}}}

                        onFocus={() => {
                            setTouchedField(recipient.id);
                            setError(null);
                        }}
                        onBlur={() => {
                            setTouchedField(null);
                            handleIsOkRecipientEmail({id: recipient?.id, email: recipient.actor.email})
                        }}

                        onError={(error) => {
                            setRecipientsError({[recipient.id]: error});
                        }}
                    />
                </Box>
            </S.RecipientsContainer>
        </Box>
    )

}



