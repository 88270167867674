import {createCommonAsyncThunk} from "../../../../newShared/utils/asyncThunk";

import {
    mainGetSystemCovers,
    mainTrainingsAcceptValidationReviewByAssignmentIdAndExamType,
    mainTrainingsCancelTrainingAssignment,
    mainTrainingsChangeExamName,
    mainTrainingsChangeTrainingName,
    mainTrainingsChangeTrainingStatus,
    mainTrainingsCreateExam,
    mainTrainingsCreateTraining,
    mainTrainingsCreateTrainingAssignment,
    mainTrainingsGetAssignmentDetails,
    mainTrainingsGetAssignmentsWithFilterPagination,
    mainTrainingsGetExamByIdAndVersion,
    mainTrainingsGetExamCategoriesAutocomplete,
    mainTrainingsGetExamsAutocomplete,
    mainTrainingsGetExamsWithFilterPagination,
    mainTrainingsGetQuestionsFullDataListForImport,
    mainTrainingsGetQuestionsShortDataListForImportWithFilterPagination,
    mainTrainingsGetSectionsFullDataListForImport,
    mainTrainingsGetSectionsShortDataListForImportWithFilterPagination,
    mainTrainingsGetTemplatesCategoriesAutocomplete,
    mainTrainingsGetTrainingByIdAndVersion,
    mainTrainingsGetTrainingsAutocomplete,
    mainTrainingsGetTrainingsCategoriesAutocomplete,
    mainTrainingsGetTrainingsWithFilterPagination,
    mainTrainingsGetTrainingTemplateById,
    mainTrainingsGetTrainingTemplatesWithFilterPagination,
    mainTrainingsMoveExamToArchive,
    mainTrainingsRestoreExamFromArchive,
    mainTrainingsUpdateExam,
    mainTrainingsUpdateTraining,
    mainTrainingUploadFile,
    mainUploadCoverImage
} from "../api/query";


import {
    MainGetSystemCoversQuery,
    MainGetSystemCoversQueryVariables,
    MainTrainingsAcceptValidationReviewByAssignmentIdAndExamTypeMutation,
    MainTrainingsAcceptValidationReviewByAssignmentIdAndExamTypeMutationVariables,
    MainTrainingsCancelTrainingAssignmentMutation,
    MainTrainingsCancelTrainingAssignmentMutationVariables,
    MainTrainingsChangeExamNameMutation,
    MainTrainingsChangeExamNameMutationVariables,
    MainTrainingsChangeTrainingNameMutation,
    MainTrainingsChangeTrainingNameMutationVariables,
    MainTrainingsChangeTrainingStatusMutation,
    MainTrainingsChangeTrainingStatusMutationVariables,
    MainTrainingsCreateExamMutation,
    MainTrainingsCreateExamMutationVariables,
    MainTrainingsCreateTrainingAssignmentMutation,
    MainTrainingsCreateTrainingAssignmentMutationVariables,
    MainTrainingsCreateTrainingMutation,
    MainTrainingsCreateTrainingMutationVariables,
    MainTrainingsGetAssignmentDetailsQuery,
    MainTrainingsGetAssignmentDetailsQueryVariables,
    MainTrainingsGetAssignmentsWithFilterPaginationQuery,
    MainTrainingsGetAssignmentsWithFilterPaginationQueryVariables,
    MainTrainingsGetExamByIdAndVersionQuery,
    MainTrainingsGetExamByIdAndVersionQueryVariables,
    MainTrainingsGetExamCategoriesAutocompleteQuery,
    MainTrainingsGetExamCategoriesAutocompleteQueryVariables,
    MainTrainingsGetExamsAutocompleteQuery,
    MainTrainingsGetExamsAutocompleteQueryVariables,
    MainTrainingsGetExamsWithFilterPaginationQuery,
    MainTrainingsGetExamsWithFilterPaginationQueryVariables,
    MainTrainingsGetQuestionsFullDataListForImportQuery,
    MainTrainingsGetQuestionsFullDataListForImportQueryVariables,
    MainTrainingsGetQuestionsShortDataListForImportWithFilterPaginationQuery,
    MainTrainingsGetQuestionsShortDataListForImportWithFilterPaginationQueryVariables,
    MainTrainingsGetSectionsFullDataListForImportQuery,
    MainTrainingsGetSectionsFullDataListForImportQueryVariables,
    MainTrainingsGetSectionsShortDataListForImportWithFilterPaginationQuery,
    MainTrainingsGetSectionsShortDataListForImportWithFilterPaginationQueryVariables,
    MainTrainingsGetTemplatesCategoriesAutocompleteQuery,
    MainTrainingsGetTemplatesCategoriesAutocompleteQueryVariables,
    MainTrainingsGetTrainingByIdAndVersionQuery,
    MainTrainingsGetTrainingByIdAndVersionQueryVariables,
    MainTrainingsGetTrainingsAutocompleteQuery,
    MainTrainingsGetTrainingsAutocompleteQueryVariables,
    MainTrainingsGetTrainingsCategoriesAutocompleteQuery,
    MainTrainingsGetTrainingsCategoriesAutocompleteQueryVariables,
    MainTrainingsGetTrainingsWithFilterPaginationQuery,
    MainTrainingsGetTrainingsWithFilterPaginationQueryVariables,
    MainTrainingsGetTrainingTemplateByIdQuery,
    MainTrainingsGetTrainingTemplateByIdQueryVariables,
    MainTrainingsGetTrainingTemplatesWithFilterPaginationQuery,
    MainTrainingsGetTrainingTemplatesWithFilterPaginationQueryVariables,
    MainTrainingsMoveExamToArchiveMutation,
    MainTrainingsMoveExamToArchiveMutationVariables,
    MainTrainingsRestoreExamFromArchiveMutation,
    MainTrainingsRestoreExamFromArchiveMutationVariables,
    MainTrainingsUpdateExamMutation,
    MainTrainingsUpdateExamMutationVariables,
    MainTrainingsUpdateTrainingMutation,
    MainTrainingsUpdateTrainingMutationVariables,
    MainTrainingUploadFileMutation,
    MainTrainingUploadFileMutationVariables,
    MainUploadCoverImageMutation,
    MainUploadCoverImageMutationVariables,
} from "../../../../newShared/GQLTypes";

export const mainTrainingsGetTrainingsWithFilterPaginationAction =
    createCommonAsyncThunk<MainTrainingsGetTrainingsWithFilterPaginationQueryVariables, MainTrainingsGetTrainingsWithFilterPaginationQuery["mainTrainingsGetTrainingsWithFilterPagination"]>(
        'trainings', 'mainTrainingsGetTrainingsWithFilterPagination', true, false, mainTrainingsGetTrainingsWithFilterPagination
    )

export const mainTrainingsGetTrainingByIdAndVersionAction =
    createCommonAsyncThunk<MainTrainingsGetTrainingByIdAndVersionQueryVariables, MainTrainingsGetTrainingByIdAndVersionQuery["mainTrainingsGetTrainingByIdAndVersion"]>(
        'trainings', 'mainTrainingsGetTrainingByIdAndVersion', true, false, mainTrainingsGetTrainingByIdAndVersion
    )

export const mainTrainingsGetExamsWithFilterPaginationAction =
    createCommonAsyncThunk<MainTrainingsGetExamsWithFilterPaginationQueryVariables, MainTrainingsGetExamsWithFilterPaginationQuery["mainTrainingsGetExamsWithFilterPagination"]>(
        'trainings', 'mainTrainingsGetExamsWithFilterPagination', true, false, mainTrainingsGetExamsWithFilterPagination
    )

export const mainTrainingsGetTrainingsCategoriesAutocompleteAction =
    createCommonAsyncThunk<MainTrainingsGetTrainingsCategoriesAutocompleteQueryVariables, MainTrainingsGetTrainingsCategoriesAutocompleteQuery["mainTrainingsGetTrainingsCategoriesAutocomplete"]>(
        'trainings', 'mainTrainingsGetTrainingsCategoriesAutocomplete', true, false, mainTrainingsGetTrainingsCategoriesAutocomplete
    )

export const mainTrainingsGetExamCategoriesAutocompleteAction =
    createCommonAsyncThunk<MainTrainingsGetExamCategoriesAutocompleteQueryVariables, MainTrainingsGetExamCategoriesAutocompleteQuery['mainTrainingsGetExamCategoriesAutocomplete']>(
        'trainings', 'mainTrainingsGetExamCategoriesAutocomplete', true, false, mainTrainingsGetExamCategoriesAutocomplete
    )

// export const mainTrainingsGetTrainingsCategoriesAutocompleteAction =
//     createCommonAsyncThunk<MainTrainingsGetTrainingsCategoriesAutocompleteQueryVariables, MainTrainingsGetTrainingsCategoriesAutocompleteQuery['mainTrainingsGetTrainingsCategoriesAutocomplete']>(
//         'trainings', 'mainTrainingsGetTrainingsCategoriesAutocomplete', true, false, mainTrainingsGetTrainingsCategoriesAutocomplete
//     )

export const mainTrainingsGetExamByIdAndVersionAction =
    createCommonAsyncThunk<MainTrainingsGetExamByIdAndVersionQueryVariables, MainTrainingsGetExamByIdAndVersionQuery["mainTrainingsGetExamByIdAndVersion"]>(
        'trainings', 'mainTrainingsGetExamByIdAndVersion', true, false, mainTrainingsGetExamByIdAndVersion
    )


export const mainTrainingsCreateExamAction =
    createCommonAsyncThunk<MainTrainingsCreateExamMutationVariables, MainTrainingsCreateExamMutation["mainTrainingsCreateExam"]>(
        'trainings', 'mainTrainingsCreateExam', true, false, mainTrainingsCreateExam
    )

export const mainTrainingsUpdateExamAction =
    createCommonAsyncThunk<MainTrainingsUpdateExamMutationVariables, MainTrainingsUpdateExamMutation["mainTrainingsUpdateExam"]>(
        'trainings', 'mainTrainingsUpdateExam', true, false, mainTrainingsUpdateExam
    )

export const mainTrainingsChangeTrainingNameAction =
    createCommonAsyncThunk<MainTrainingsChangeTrainingNameMutationVariables, MainTrainingsChangeTrainingNameMutation["mainTrainingsChangeTrainingName"]>(
        'trainings', 'mainTrainingsChangeTrainingName', true, false, mainTrainingsChangeTrainingName
    )


export const mainTrainingsChangeTrainingStatusAction =
    createCommonAsyncThunk<MainTrainingsChangeTrainingStatusMutationVariables, MainTrainingsChangeTrainingStatusMutation["mainTrainingsChangeTrainingStatus"]>(
        'trainings', 'mainTrainingsChangeTrainingStatus', true, false, mainTrainingsChangeTrainingStatus
    )


export const mainTrainingsChangeExamNameAction =
    createCommonAsyncThunk<MainTrainingsChangeExamNameMutationVariables, MainTrainingsChangeExamNameMutation["mainTrainingsChangeExamName"]>(
        'trainings', 'mainTrainingsChangeExamName', true, false, mainTrainingsChangeExamName
    )

export const mainTrainingsGetSectionsShortDataListForImportWithFilterPaginationAction =
    createCommonAsyncThunk<MainTrainingsGetSectionsShortDataListForImportWithFilterPaginationQueryVariables, MainTrainingsGetSectionsShortDataListForImportWithFilterPaginationQuery['mainTrainingsGetSectionsShortDataListForImportWithFilterPagination']>(
        'trainings', 'mainTrainingsGetSectionsShortDataListForImportWithFilterPagination', true, false, mainTrainingsGetSectionsShortDataListForImportWithFilterPagination
    )


export const mainTrainingUploadFileAction =
    createCommonAsyncThunk<MainTrainingUploadFileMutationVariables, MainTrainingUploadFileMutation["mainTrainingUploadFile"]>(
        'trainings', 'mainTrainingUploadFile', true, false, mainTrainingUploadFile
    )

export const createTrainingAction =
    createCommonAsyncThunk<MainTrainingsCreateTrainingMutationVariables, MainTrainingsCreateTrainingMutation["mainTrainingsCreateTraining"]>(
        'trainings', 'mainTrainingsCreateTraining', true, false, mainTrainingsCreateTraining
    )

export const mainTrainingsMoveExamToArchiveAction =
    createCommonAsyncThunk<MainTrainingsMoveExamToArchiveMutationVariables, MainTrainingsMoveExamToArchiveMutation["mainTrainingsMoveExamToArchive"]>(
        'trainings', 'mainTrainingsMoveExamToArchive', true, false, mainTrainingsMoveExamToArchive
    )

export const mainTrainingsRestoreExamFromArchiveAction =
    createCommonAsyncThunk<MainTrainingsRestoreExamFromArchiveMutationVariables, MainTrainingsRestoreExamFromArchiveMutation["mainTrainingsRestoreExamFromArchive"]>(
        'trainings', 'mainTrainingsRestoreExamFromArchive', true, false, mainTrainingsRestoreExamFromArchive
    )


export const updateTrainingAction =
    createCommonAsyncThunk<MainTrainingsUpdateTrainingMutationVariables, MainTrainingsUpdateTrainingMutation["mainTrainingsUpdateTraining"]>(
        'trainings', 'mainTrainingsUpdateTraining', true, false, mainTrainingsUpdateTraining
    )

//mainGetSystemCovers
export const mainGetSystemCoversAction =
    createCommonAsyncThunk<MainGetSystemCoversQueryVariables, MainGetSystemCoversQuery["mainGetSystemCovers"]>(
        'trainings', 'mainGetSystemCovers', true, false, mainGetSystemCovers
    )

//mainUploadCoverImage
export const mainUploadCoverImageAction =
    createCommonAsyncThunk<MainUploadCoverImageMutationVariables, MainUploadCoverImageMutation["mainUploadCoverImage"]>(
        'trainings', 'mainUploadCoverImage', true, false, mainUploadCoverImage
    )

export const mainTrainingsGetAssignmentsWithFilterPaginationAction =
    createCommonAsyncThunk<MainTrainingsGetAssignmentsWithFilterPaginationQueryVariables, MainTrainingsGetAssignmentsWithFilterPaginationQuery["mainTrainingsGetAssignmentsWithFilterPagination"]>(
        'trainings', 'mainTrainingsGetAssignmentsWithFilterPagination', true, false, mainTrainingsGetAssignmentsWithFilterPagination
    )

export const mainTrainingsGetAssignmentDetailsAction =
    createCommonAsyncThunk<MainTrainingsGetAssignmentDetailsQueryVariables, MainTrainingsGetAssignmentDetailsQuery["mainTrainingsGetAssignmentDetails"]>(
        'trainings', 'mainTrainingsGetAssignmentDetails', true, false, mainTrainingsGetAssignmentDetails
    )

export const mainTrainingsCreateTrainingAssignmentAction =
    createCommonAsyncThunk<MainTrainingsCreateTrainingAssignmentMutationVariables, MainTrainingsCreateTrainingAssignmentMutation["mainTrainingsCreateTrainingAssignment"]>(
        'trainings', 'mainTrainingsCreateTrainingAssignment', true, false, mainTrainingsCreateTrainingAssignment
    )

export const mainTrainingsCancelTrainingAssignmentAction =
    createCommonAsyncThunk<MainTrainingsCancelTrainingAssignmentMutationVariables, MainTrainingsCancelTrainingAssignmentMutation["mainTrainingsCancelTrainingAssignment"]>(
        'trainings', 'mainTrainingsCancelTrainingAssignment', true, false, mainTrainingsCancelTrainingAssignment
    )


export const mainTrainingsGetSectionsFullDataListForImportAction =
    createCommonAsyncThunk<MainTrainingsGetSectionsFullDataListForImportQueryVariables, MainTrainingsGetSectionsFullDataListForImportQuery["mainTrainingsGetSectionsFullDataListForImport"]>(
        'trainings', 'mainTrainingsGetSectionsFullDataListForImport', true, false, mainTrainingsGetSectionsFullDataListForImport
    )

export const mainTrainingsGetQuestionsShortDataListForImportWithFilterPaginationAction =
    createCommonAsyncThunk<MainTrainingsGetQuestionsShortDataListForImportWithFilterPaginationQueryVariables, MainTrainingsGetQuestionsShortDataListForImportWithFilterPaginationQuery["mainTrainingsGetQuestionsShortDataListForImportWithFilterPagination"]>(
        'trainings', 'mainTrainingsGetQuestionsShortDataListForImportWithFilterPagination', true, false, mainTrainingsGetQuestionsShortDataListForImportWithFilterPagination
    )

export const mainTrainingsGetQuestionsFullDataListForImportAction =
    createCommonAsyncThunk<MainTrainingsGetQuestionsFullDataListForImportQueryVariables, MainTrainingsGetQuestionsFullDataListForImportQuery["mainTrainingsGetQuestionsFullDataListForImport"]>(
        'trainings', 'mainTrainingsGetQuestionsFullDataListForImport', true, false, mainTrainingsGetQuestionsFullDataListForImport
    )

export const mainTrainingsGetExamsAutocompleteAction =
    createCommonAsyncThunk<MainTrainingsGetExamsAutocompleteQueryVariables, MainTrainingsGetExamsAutocompleteQuery["mainTrainingsGetExamsAutocomplete"]>(
        'trainings', 'mainTrainingsGetExamsAutocomplete', true, false, mainTrainingsGetExamsAutocomplete
    )

export const mainTrainingsGetTrainingsAutocompleteAction =
    createCommonAsyncThunk<MainTrainingsGetTrainingsAutocompleteQueryVariables, MainTrainingsGetTrainingsAutocompleteQuery["mainTrainingsGetTrainingsAutocomplete"]>(
        'trainings', 'mainTrainingsGetTrainingsAutocomplete', true, false, mainTrainingsGetTrainingsAutocomplete
    )
export const mainTrainingsAcceptValidationReviewByAssignmentIdAndExamTypeAction =
    createCommonAsyncThunk<MainTrainingsAcceptValidationReviewByAssignmentIdAndExamTypeMutationVariables, MainTrainingsAcceptValidationReviewByAssignmentIdAndExamTypeMutation['mainTrainingsAcceptValidationReviewByAssignmentIdAndExamType']>(
        'trainings', 'mainTrainingsAcceptValidationReviewByAssignmentIdAndExamType', true, false, mainTrainingsAcceptValidationReviewByAssignmentIdAndExamType
    )

export const mainTrainingsGetTrainingTemplatesWithFilterPaginationAction =
    createCommonAsyncThunk<MainTrainingsGetTrainingTemplatesWithFilterPaginationQueryVariables, MainTrainingsGetTrainingTemplatesWithFilterPaginationQuery["mainTrainingsGetTrainingTemplatesWithFilterPagination"]>(
        'trainings', 'mainTrainingsGetTrainingTemplatesWithFilterPagination', false, false, mainTrainingsGetTrainingTemplatesWithFilterPagination
    )

export const mainTrainingsGetTrainingTemplateByIdAction =
    createCommonAsyncThunk<MainTrainingsGetTrainingTemplateByIdQueryVariables, MainTrainingsGetTrainingTemplateByIdQuery["mainTrainingsGetTrainingTemplateById"], {type: 'PREVIEW' | 'USE'}>(
        'trainings', 'mainTrainingsGetTrainingTemplateById', false, false, mainTrainingsGetTrainingTemplateById
    )

export const mainTrainingsGetTemplatesCategoriesAutocompleteAction =
    createCommonAsyncThunk<MainTrainingsGetTemplatesCategoriesAutocompleteQueryVariables, MainTrainingsGetTemplatesCategoriesAutocompleteQuery["mainTrainingsGetTemplatesCategoriesAutocomplete"]>(
        'trainings', 'mainTrainingsGetTemplatesCategoriesAutocomplete', false, false, mainTrainingsGetTemplatesCategoriesAutocomplete
    )
