import {Dialog, DialogActions, DialogContent, DialogTitle, styled} from "@mui/material";
import colors from "../../../../../../theme/colors";

export const AddVariableDialogStyled = styled(Dialog)(() => ({
    '& .MuiDialog-paper': {
        width: '450px',
    }
}))

export const AddVariableDialogTitleStyled = styled(DialogTitle)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '14px',
    paddingBlock: '16px',

    color: colors.text.dark,
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px',

    backgroundColor: colors.backgrounds.grey_light,
    borderBottom: `1px solid ${colors.stroke.grey}`,

    '& svg': {
        color: colors.text.blue
    },
}))

export const AddVariableDialogActionsStyled = styled(DialogActions)(() => ({
    backgroundColor: colors.backgrounds.grey_light,
    borderTop: `1px solid ${colors.stroke.grey}`,
    paddingBlock: '8px',
}))

export const AddVariableDialogContentStyled = styled(DialogContent)(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',

    paddingTop: '16px !important',
    paddingBlock: '16px',
    // overflow: 'hidden',
    // display: "flex",
    // width: '100%',
    // '&.MuiDialogContent-root': {
    //     padding: 0,
    // }
}))

// export const AutocompleteBox = styled(Box)(() => ({
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-between',
// }));
