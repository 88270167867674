import {
    eraseRequestList,
    incomingRequests,
    loadings,
    openIncomingRequestViewDialog,
    replaceRequestMinMaxLoadedPage
} from "../../store/slice";
import {useDispatch, useSelector} from "react-redux";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_EMPLOYEES} from "../../../../../newShared/constants";
import {PATH_LOCAL_EMPLOYEES_INCOMING_REQUESTS} from "../../constants";
import {HrRequestRequestorModel, MainHrRequestModel, MainHrRequestStatus} from "../../../../../newShared/GQLTypes";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {getHrRequestStatusChip} from "../../helpers";
import {getHrRequestRequestorsAutocompleteApi} from "../../api";
import {resultToValues} from "../../../../../newShared/components/genericFilter/helpers";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {getHrRequestsWithFilterPaginationAction} from "../../store/actions";


export const useIncomingRequests = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const {t} = useMainTranslation('', {keyPrefix: 'pathHRManagement.pathIncomingRequests'});

    const dispatch = useDispatch();
    const {requests, pageInfo, minMaxLoadedPage} = useSelector(incomingRequests);
    const {incomingRequests: isLoadingList} = useSelector(loadings);
    const {minLoadedPage, maxLoadedPage} = minMaxLoadedPage;


    useSetBreadcrumbs([
        {
            title: tMenu('HR Management'),
            path: PATH_LOCAL_EMPLOYEES
        },
        {
            title: tMenu('Incoming requests'),
            path: PATH_LOCAL_EMPLOYEES_INCOMING_REQUESTS
        },
    ]);

    const handleOpenRequest = (selected: MainHrRequestModel) => {
        dispatch(openIncomingRequestViewDialog(selected.id));
    }

    //filters
    const requestDateConfig: keyConfig<string> = {
        type: 'date',
        key: 'requestDate',
        name: t('Request date'),
        default: true
    }
    const statusConfig: keyConfig<MainHrRequestStatus> = {
        type: 'filter',
        key: 'status',
        name: t('Status'),
        options: [MainHrRequestStatus.Created, MainHrRequestStatus.Approved, MainHrRequestStatus.Rejected],
        OptionRenderer: (a) => getHrRequestStatusChip(a, true),
        // getOptionLabel: (a) => a,
        default: true,
    }


    const requestorConfig: keyConfig<HrRequestRequestorModel> = {
        type: 'filter',
        key: 'requestorId',
        name: t('Requestor'),
        fetchOptions: resultToValues<HrRequestRequestorModel>(getHrRequestRequestorsAutocompleteApi),
        getOptionLabel: (a) => a.fullName,
        getFilterValue: (a) => a.employeeId,
        isOptionEqual: (a, b) => a.employeeId === b.employeeId,
        isOptionEqualToSearch: (a, search) => a.fullName.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: true,
    }



    return {
        requests,
        handleOpenRequest,

        genericTable: {
            paging: {
                pageInfo,
                isLoading: isLoadingList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceRequestMinMaxLoadedPage(data)),
                clearRows: () => dispatch(eraseRequestList()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, requestIdLike: string) => {
                    dispatch(getHrRequestsWithFilterPaginationAction({data: {pageRequest: {page, count}, filter: {...filters, requestIdLike}}}));
                },
            },
            filters: {
                configs: [requestDateConfig, requestorConfig, statusConfig]
            }
        }

    }
}
