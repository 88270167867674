import {useAutomationsList} from "../../hooks/useAutomationsList";
import {Flex} from "../../../../../newShared/components/Layouts";
import React from "react";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import AddIcon from "@mui/icons-material/Add";
import {GetTextByStatus} from "../../components/styled";
import {DeletePipelineDialog} from "../../components/dialogs/deletePipeline";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {shortAutomation} from "../../types";
import colors from "../../../../../newShared/theme/colors";
import {Typography} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

export const AutomationsList = () => {
    const {
        allAutomations,
        handleGoToCreateAutomation,
        paging,
        onRowClick,
        isLoadingRun,
        isLoadingDelete,
        handleRunAuto,
        handleDeleteAuto
    } = useAutomationsList();
    const {t} = useMainTranslation('', {keyPrefix: 'automations'});
    const {isMobile} = useMedia();

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('Created automations')}
                actions={[
                    {
                        title: t('Add automation'),
                        onClick: handleGoToCreateAutomation,
                        variant: 'text',
                        icon: <AddIcon />
                    }
                ]}
            />

            <GenericTable<shortAutomation>
                id={'AutomationsList'}
                columnsConfig={{totalName: 'Total automations', forceShowCardsWhen: isMobile, disableColumnsSettings: true}}
                paging={paging}
                rowsConfig={{
                    rows: allAutomations,
                    customCellPaddings: '8px',
                    rowActionsConfig: [
                        {title: 'Run', onClick: handleRunAuto, icon: <PlayArrowIcon fontSize={'small'}/>, loading: (row) => row.id === isLoadingRun.autoId, disabled: (row) => row.lastRunStatus === 'inProgress'},
                        {title: 'Delete', onClick: handleDeleteAuto, icon: <DeleteOutlineOutlinedIcon fontSize={'small'}/>, loading: () => isLoadingDelete, disabled: (row) => row.lastRunStatus === 'inProgress'}
                    ],
                    getRowUniqueId: (row) => row.id,
                    onRowClick,
                    columnsConfigs: [
                        {key: "name", name: 'Name', default: true},
                        {key: 'lastRun', name: 'Last run', default: true, valueGetter: (row) => parseDateAuto(row.lastRunningDate)},
                        {key: 'runStatus', name: 'Run status', default: true, valueRenderer: (row) => GetTextByStatus(row.lastRunStatus ?? '')},
                        {key: 'status', name: 'Status', default: true, valueRenderer: (row) => <Typography variant={'*bodyText2'} sx={{color: row.active ? colors.warningActions.green : colors.warningActions.red}}>{row.active ? t('Active') : t('Inactive')}</Typography>},
                    ]
                }}
                filtersConfig={{}}
                emptyArrayImageProps={{type: 'automations', filterNotApplied: {secondaryText: t('Create automations'), text: t('You have not added any automations yet.'), onClick: handleGoToCreateAutomation}}}
            />
            <DeletePipelineDialog />
        </Flex>
    )
}