import React, {Component, createRef, Fragment, MouseEvent, RefObject} from "react";
import {
    EditorBlockItemType,
    EditorRowData,
    FunctionsForToolBar,
    getToolBarProps,
    MapDispatchToPropsObject,
    TAddFillableFieldEventData,
    TEditorActor,
    TEditorBlockElementData,
    TEditorBlockItemType,
    TEditorFillableBlockData,
    TEditorPageElementData,
    TEditorRowElementData,
    TSetPagePropertiesMenuEventData,
    TUpdateDataInFillableFieldEventData,
    TUpdateRecipientEventData,
    ValueOf
} from "../../types";
import {EditorItemRow} from "../editorItemRow";
import {EditorItemPageContentStyled, EditorItemPageStyled} from "./styled";
import {
    addFillableFieldVariableEventName,
    DEFAULT_PAGE_DATA,
    getScalesByOrientation,
    setPagePropertiesMenuVariableEventName,
    updateDataInFillableFieldEventName,
    updateRecipientEventName
} from "../../constants";
import {EditorFillableBlock} from "../editorFillableBlock";
import {EditorWorkZone, EditorWorkZoneState} from "../editorWorkzone";
import {EditorDropZoneForBlockItem} from "../editorDropZoneForBlockItem";
import {Box, IconButton} from "@mui/material";
import {ToolBarProps} from "../editorToolBar";
import {EditorDropZoneForFillableField} from "../editorDropZoneForFillableField";
import {Delete, MoreHoriz} from "@mui/icons-material";
import {
    changeOldIdToNewId,
    createEventBlockCreatedByDrop,
    createEventDeleteBlock,
    createEventSetPagePropertiesMenu
} from "../../helpers";
import {uuid} from "../../../../utils";
import {EditorTooltip} from "../commonComponents/editorTooltip";

interface Props extends getToolBarProps {
    block: TEditorPageElementData;
    fillableFields: TEditorFillableBlockData[];

    onDelete: (e: MouseEvent<HTMLButtonElement>) => void;

    activeZone: EditorWorkZoneState["activeZone"];
    setActiveZone: (activeZone: EditorWorkZoneState["activeZone"]) => void;

    parentRef: RefObject<EditorWorkZone>["current"];
    editorWorkZoneRef: RefObject<EditorWorkZone>["current"];

    handleSubmitSign: (id: string, data: string, insertEverywhere: boolean) => void;
    handleSubmitText: (id: string, data: string) => void;
}
export interface PageState {
    id: string;
    type: string;
    data: TEditorPageElementData["data"];

    fillableFields: TEditorFillableBlockData[];

    size: {width: number, height: number};

    refs: Record<string, RefObject<EditorItemRow>>;
    wrapperRef: RefObject<HTMLDivElement>;
    headerRef: RefObject<HTMLDivElement>;
    contentRef: RefObject<HTMLDivElement>;
    footerRef: RefObject<HTMLDivElement>;
}

export class EditorItemPage extends Component<Props, PageState> implements FunctionsForToolBar {
    constructor(props: EditorItemPage["props"]) {
        super(props);

        const refs: PageState["refs"] = {};
        [
            ...props.block?.data.content || [],
            ...props.block?.data.header || [],
            ...props.block?.data.footer || [],
        ]?.forEach(el => {
            refs[el.id] = createRef<EditorItemRow>();
        })

        this.state = {
            id: props.block.id,
            type: 'page',
            data: {
                orientation: props.block.data?.orientation ?? 'portrait',
                size: props.block.data?.size ?? 'A4',

                header: props.block.data.header ?? [],
                footer: props.block.data.footer ?? [],
                content: props.block.data.content ?? [],

                headerHeight: props.block?.data.headerHeight || DEFAULT_PAGE_DATA.headerHeight,
                footerHeight: props.block?.data.footerHeight || DEFAULT_PAGE_DATA.footerHeight,

                marginTop: props.block?.data.marginTop || DEFAULT_PAGE_DATA.marginTop,
                marginBottom: props.block?.data.marginBottom || DEFAULT_PAGE_DATA.marginBottom,
                marginLeft: props.block?.data.marginRight || DEFAULT_PAGE_DATA.marginLeft,
                marginRight: props.block?.data.marginRight || DEFAULT_PAGE_DATA.marginRight,
            },

            fillableFields: props.fillableFields || [],

            size: getScalesByOrientation(props.block.data?.size || 'A4', props.block.data?.orientation ?? 'portrait'),

            refs,
            wrapperRef: createRef<HTMLDivElement>(),
            headerRef: createRef<HTMLDivElement>(),
            contentRef: createRef<HTMLDivElement>(),
            footerRef: createRef<HTMLDivElement>(),
        }
    };

    componentDidMount() {
        this.refreshFillableFieldsPosition();

        document.addEventListener(addFillableFieldVariableEventName, this.handleAddFillableFieldByEvent);
        document.addEventListener(setPagePropertiesMenuVariableEventName, this.handleSetPropertiesByEvent);
        document.addEventListener(updateRecipientEventName, this.handleUpdateRecipientByEvent);
        document.addEventListener(updateDataInFillableFieldEventName, this.handleUpdateDataInFillableFieldsByEvent);
    }
    componentWillUnmount() {
        document.removeEventListener(addFillableFieldVariableEventName, this.handleAddFillableFieldByEvent);
        document.removeEventListener(setPagePropertiesMenuVariableEventName, this.handleSetPropertiesByEvent);
        document.removeEventListener(updateRecipientEventName, this.handleUpdateRecipientByEvent);
        document.removeEventListener(updateDataInFillableFieldEventName, this.handleUpdateDataInFillableFieldsByEvent);
    }

    handleUpdateDataInFillableFieldsByEvent = (ev: Event) => {
        const {detail} = ev as CustomEvent<TUpdateDataInFillableFieldEventData>;

        this.setState(prev => {
            detail.forEach(el => {
                const index = prev.fillableFields.findIndex(fillableField => fillableField.id === el.fieldId);
                if (index > -1) {
                    prev.fillableFields[index].data = el.value;
                }
            })

            return prev
        })
    };

    handleAddFillableFieldByEvent = (ev: Event) => {
        const {detail} = ev as CustomEvent<TAddFillableFieldEventData>;
        this.handleAddFillableField(detail)
    };

    handleSetPropertiesByEvent = (ev: Event) => {
        const {detail} = ev as CustomEvent<TSetPagePropertiesMenuEventData>;
        this.handleSetProperties(detail)
    };

    handleUpdateRecipientByEvent = (ev: Event) => {
        const {detail} = ev as CustomEvent<TUpdateRecipientEventData>;
        this.handleUpdateRecipient(detail)
    };

    handleUpdateRecipient = (data: TUpdateRecipientEventData) => {
        const oldFields = this.state.fillableFields.filter(el => data.some(e => e.sourceEmailRole === el.sourceEmailRole));

        if (oldFields.length > 0) {
            this.setState(prev => {
                oldFields.forEach(el => {
                    const element = data.find(e => e.sourceEmailRole === el.sourceEmailRole);
                    const index = prev.fillableFields.findIndex(field => field.id === el.id);
                    if (element && index > -1) {
                        prev.fillableFields[index].actor = element.actor;
                        prev.fillableFields[index].role = element.role;
                        prev.fillableFields[index].sourceEmailRole = '' + element.actor.email + element.role;
                    }
                })

                return prev
            })
        }
    };

    handleReplaceRow = (id: string, data: EditorRowData[]) => {
        const newData = data.map((e): TEditorRowElementData => ({
            id: 'row' + uuid(), type: 'row', data: e
        }))

        this.setState(prev => {
            const index = prev.data.content.findIndex(block => block.id === id);
            index > -1 && prev.data.content.splice(index, 1, ...newData);

            delete prev.refs[id];
            newData.forEach(el => {
                prev.refs[el.id] = createRef<EditorItemRow>();
            })

            return prev
        });

        // setTimeout(() => {
        //     createEventUnfocusAllBlocks({});
        // }, 150)
    };

    handleSetProperties = (properties: TSetPagePropertiesMenuEventData) => {
        if (properties?.id !== this.state.id) return;
        const {data} = properties;

        let needUpdate = false;
        const {
            size, orientation,
            marginTop, marginBottom,
            marginLeft, marginRight
        } = {...this.state.data};
        let sizeScales = {...this.state.size};

        if (data.size !== size || data.orientation !== orientation) {
            sizeScales = getScalesByOrientation(data.size, data.orientation);
            needUpdate = true;
        }

        if (marginTop !== data.marginTop || marginBottom !== data.marginBottom || marginLeft !== data.marginLeft || marginRight !== data.marginRight) {
            needUpdate = true;
        }

        needUpdate && this.setState(prev => ({...prev, data: {...prev.data, ...data}, size: sizeScales}))
    };

    handleAddRow = (position: 'header' | 'content' | 'footer', index: number, type?: ValueOf<TEditorBlockItemType>, block?: TEditorBlockElementData) => {
        //decide what to add: row or row with block
        const data: EditorRowData = {content: []};
        switch (type) {
            case 'pagebreak': {
                data.content.push({
                    id: 'pagebreak' + uuid(),
                    type: 'pagebreak',
                    data: {
                        // width: 100,
                        positionTop: 0,
                        content: []
                    }
                });
                break;
            }
            default: {
                if (block) {
                    const newData = changeOldIdToNewId(JSON.parse(JSON.stringify(block)));
                    data.content.push({
                        ...newData,
                        data: {
                            ...newData.data,
                            width: 100
                        }
                    })
                } else {
                    type && data.content.push({
                        id: 'block' + uuid(),
                        type: 'block',
                        data: {
                            width: 100,
                            content: [{id: type + uuid(), type, data: {}}]
                        }
                    })
                }
            }
        }

        const rowId = 'row' + uuid();
        this.setState(prev => {
            //condition for avoiding setState twice firing in StrictMode
            if (!prev.data[position].some(e => e.id === rowId) && !prev.refs[rowId]) {
                prev.data[position].splice(index, 0, {type: 'row', id: rowId, data});
                prev.refs[rowId] = createRef<EditorItemRow>();
            }

            return prev
        });

        createEventBlockCreatedByDrop({
            ids: [data.content[0].id],
        });
        block && createEventDeleteBlock({id: block.id});
    };

    handleDeleteRow = (position: 'header' | 'content' | 'footer', index: number, id: string) => () => {
        this.setState(prev => {
            const index = prev.data[position].findIndex(block => block.id === id);
            index > -1 && prev.data[position].splice(index, 1);
            delete prev.refs[id];

            return prev
        });
    };

    isOpacity = (zone: 'header' | 'footer' | 'middle'): true | undefined => {
        if (this.props.readonly) return undefined;
        const activeZone = this.props.activeZone;

        switch (zone) {
            case 'header': {
                return !activeZone || (activeZone.pageId !== this.state.id || activeZone.zone !== zone) || undefined;
            }
            case 'footer': {
                return !activeZone || (activeZone.pageId !== this.state.id || activeZone.zone !== zone) || undefined;
            }
            case "middle": {
                return !!activeZone || undefined
            }
        }

        return undefined;
    };

    ///////ToolBar
    setToolBar: MapDispatchToPropsObject["setToolBar"] = (toolBar, ids) => {
        // console.log(`setToolBar`, ids);
        this.props.parentRef?.setToolBar(toolBar, [this.state.id, ...ids])
    }
    setTextByToolBar: ToolBarProps["toolBarHandler"] = (action, payload, ids) => {
        const nextId = ids[0];
        if (nextId) {
            const ref = this.state.refs[nextId]?.current;
            if (ref) {
                ref.setTextByToolBar(action, payload, ids.slice(1))
            }
        }
    }

    setActiveBlock: FunctionsForToolBar["setActiveBlock"] = (ids) => {
        this.props.parentRef?.setActiveBlock([this.state.id, ...ids])
    }

    addCopiedBlock = (block: TEditorBlockElementData, oldId: string) => {
        const position = this.props.activeZone?.zone || 'content';
        const index = this.state.data[position].findIndex(e => e.data.content.some(e => e.id === oldId));
        this.handleAddRow(position, index + 1, block.type, block);
    }

    handleAddFillableField = ({position: {x, y}, size, pageId, type, id, actor, sourceEmailRole, role, isRequired, withoutDxDy}: TAddFillableFieldEventData) => {
        const result = {
            position: !withoutDxDy ? {
                x: x - this.state.wrapperRef.current!.offsetLeft + (this.props.parentRef?.state.scrollRef.current?.scrollLeft || 0),
                y: y - this.state.wrapperRef.current!.offsetTop + (this.props.parentRef?.state.scrollRef.current?.scrollTop || 0),
            } : {x, y},
            size: {width: size.width || 115, height: size.height || 52},
            type,
            pageId: pageId || this.state.id,
            id: id || (type + uuid()),
            actor,
            data: '',
            isRequired,
            sourceEmailRole: sourceEmailRole,
            role: role,
        } satisfies TEditorFillableBlockData;

        if (pageId === this.state.id) {
            this.setState(prev => {
                const index = prev.fillableFields.findIndex(e => e.id === result.id);
                if (index > -1) {
                    prev.fillableFields[index] = result
                } else {
                    prev.fillableFields.push(result)
                }

                return prev;
            })
        } else {
            this.handleRemoveFillableField(result.id)
        }
    }
    
    handleRemoveFillableField = (id: string) => {
        if (this.state.fillableFields.some(e => e.id === id)) {
            this.setState(prev => ({
                ...prev,
                fillableFields: prev.fillableFields.filter(e => e.id !== id)
            }))
        }
    };

    openPagePropertiesMenu = () => {
        createEventSetPagePropertiesMenu({
            id: this.state.id,
            data: {
                size: this.state.data.size,
                orientation: this.state.data.orientation,
                marginTop: this.state.data.marginTop,
                marginBottom: this.state.data.marginBottom,
                marginLeft: this.state.data.marginLeft,
                marginRight: this.state.data.marginRight,
            }
        })
    }

    handleResizeFillableField = (id: string) => (size: { width: number, height: number }, position: {x: number, y: number}) => {
        this.setState(prev => {
            const index = prev.fillableFields.findIndex(e => e.id === id);
            if (index > -1) {
                prev.fillableFields[index].size = size;
                prev.fillableFields[index].position = position;
            }

            return prev;
        });
    }

    onChangeEmail = (id: string) => (actor: TEditorActor, role: string) => {
        this.setState(prev => {
            const index = prev.fillableFields.findIndex(e => e.id === id);
            if (index > -1) {
                prev.fillableFields[index].actor = actor;
                prev.fillableFields[index].role = role;
                prev.fillableFields[index].sourceEmailRole = '' + actor.email + role;
            }

            return prev;
        });
    }

    refreshFillableFieldsPosition = () => {
        if (
            this.state.fillableFields.some(e => e.position.metaPageHeight && e.position.metaPageHeight !== this.state.wrapperRef.current?.offsetHeight)
        ) {
            this.setState(prev => {
                    const fillableFields = JSON.parse(JSON.stringify(prev.fillableFields)) as TEditorFillableBlockData[];
                    fillableFields.forEach(e => {
                        if (e.position.metaPageHeight && e.position.metaPageHeight !== this.state.wrapperRef.current?.offsetHeight) {
                            e.position.y = e.position.y / e.position.metaPageHeight * this.state.wrapperRef.current!.offsetHeight;
                            e.position.metaPageHeight = 0;
                        } else {
                            return e
                        }
                    })

                    return {...prev, fillableFields};
                }
            )
        }
    }

    render() {
        const isOpacityHeader = this.isOpacity('header');
        const isOpacityContent = this.isOpacity('middle');
        const isOpacityFooter = this.isOpacity('footer');

        return (
            <EditorItemPageStyled ref={this.state.wrapperRef} id={this.state.id}
                                  width={this.state.size.width} height={this.state.size.height}
                                  isTemplate={this.props.status.isTemplate}
            >

                {this.state.fillableFields.map(e => (
                    <EditorFillableBlock key={`${e.id} + ${e.position.x} + ${e.position.y}`}
                                         element={{...e, isRequired: (e.isRequired === null || e.isRequired === undefined) || e.isRequired}}
                                         onResize={this.handleResizeFillableField(e.id)}
                                         parentRef={this} parentDiv={this.state.wrapperRef}
                                         onChangeEmail={this.onChangeEmail(e.id)}

                                         handleSubmitSign={(sign, insertEverywhere) => this.props.handleSubmitSign(e.id, sign, insertEverywhere)}
                                         handleSubmitText={(text) => this.props.handleSubmitText(e.id, text)}

                                         status={this.props.status}
                                         readonly={this.props.readonly}
                                         isLoading={this.props.isLoading}
                    />
                ))}

                {!this.props.readonly && (
                    <Box display={"flex"} alignItems={"center"} gap={'0px'} position={'absolute'} top={'-36px'} right={'0px'}>
                        <IconButton size={"small"} onClick={this.props.onDelete}>
                            <Delete/>
                        </IconButton>
                        <IconButton size={"small"} onClick={this.openPagePropertiesMenu}>
                            <MoreHoriz/>
                        </IconButton>
                    </Box>
                )}

                {/*Header*/}
                <EditorTooltip title={isOpacityHeader ? 'Tap twice to edit header' : ''}>
                    <EditorItemPageContentStyled ref={this.state.headerRef}
                                                 isOpacity={isOpacityHeader}
                                                 marginLeft={this.state.data.marginLeft} marginRight={this.state.data.marginRight}
                                                 marginTop={this.state.data.marginTop} showHeaderLine={this.state.data.header.length > 0 || !isOpacityHeader}
                                                 onDoubleClick={() => this.props.setActiveZone({pageId: this.state.id, zone: 'header'})}
                    >
                        {(
                            <EditorDropZoneForBlockItem orientation={"horizontal"} onDrop={(type, block) => this.handleAddRow("header", 0, type, block as TEditorBlockElementData)}
                                                        isDisabled={isOpacityHeader} allowPushAdd isLast={this.state.data.header.length === 0}
                                                        readonly={this.props.readonly}/>
                        )}

                        {this.state.data.header.map((block, index, array) => (
                            <Fragment key={block.id}>
                                <EditorItemRow ref={this.state.refs[block.id!]}
                                               block={block} onDelete={this.handleDeleteRow("header", index, block.id!)}
                                               parentRef={this}
                                               editorWorkZoneRef={this.props.editorWorkZoneRef}
                                               getToolBarState={this.props.getToolBarState}

                                               status={this.props.status}
                                               readonly={this.props.readonly}
                                               isLoading={this.props.isLoading}
                                />

                                {(
                                    <EditorDropZoneForBlockItem key={block.id + 'index' + index}
                                                                orientation={"horizontal"} onDrop={(type, block) => this.handleAddRow("header", index + 1, type, block as TEditorBlockElementData)}
                                                                isDisabled={isOpacityHeader} allowPushAdd isLast={array.length - 1 === index}
                                                                readonly={this.props.readonly}/>
                                )}
                            </Fragment>
                        ))}
                    </EditorItemPageContentStyled>
                </EditorTooltip>

                {/*Middle content*/}
                <EditorDropZoneForFillableField pageId={this.state.id}>
                    <EditorTooltip title={isOpacityContent ? `Tap twice to exit from editing ${this.props.activeZone?.zone}` : ''} followCursor>
                        <EditorItemPageContentStyled ref={this.state.contentRef}
                                                     isOpacity={isOpacityContent}
                                                     marginLeft={this.state.data.marginLeft} marginRight={this.state.data.marginRight}
                                                     onDoubleClick={() => this.props.setActiveZone(undefined)}
                        >
                            {(
                                <EditorDropZoneForBlockItem orientation={"horizontal"} onDrop={(type, block) => this.handleAddRow("content", 0, type, block as TEditorBlockElementData)}
                                                            isDisabled={isOpacityContent} allowPushAdd isLast={this.state.data.content.length === 0}
                                                            readonly={this.props.readonly}/>
                            )}

                            {this.state.data.content.map((block, index, array) => (
                                <Fragment key={block.id}>
                                    <EditorItemRow ref={this.state.refs[block.id!]}
                                                   block={block} onDelete={this.handleDeleteRow("content", index, block.id!)}
                                                   parentRef={this}
                                                   editorWorkZoneRef={this.props.editorWorkZoneRef}
                                                   getToolBarState={this.props.getToolBarState}

                                                   status={this.props.status}
                                                   readonly={this.props.readonly}
                                                   isLoading={this.props.isLoading}
                                    />

                                    {(
                                        <EditorDropZoneForBlockItem key={block.id + 'index' + index} accept={[EditorBlockItemType.TEXT, EditorBlockItemType.TABLE, EditorBlockItemType.IMAGE, EditorBlockItemType.LIST, EditorBlockItemType.PAGEBREAK]}
                                                                    orientation={"horizontal"} onDrop={(type, block) => this.handleAddRow("content", index + 1, type, block as TEditorBlockElementData)}
                                                                    isDisabled={isOpacityContent} allowPushAdd isLast={array.length - 1 === index}
                                                                    readonly={this.props.readonly}/>
                                    )}
                                </Fragment>
                            ))}
                        </EditorItemPageContentStyled>
                    </EditorTooltip>
                </EditorDropZoneForFillableField>

                {/*Footer*/}
                <EditorTooltip title={isOpacityFooter ? 'Tap twice to edit footer' : ''}>
                    <EditorItemPageContentStyled ref={this.state.footerRef}
                                                 isOpacity={isOpacityFooter}
                                                 marginLeft={this.state.data.marginLeft} marginRight={this.state.data.marginRight}
                                                 marginBottom={this.state.data.marginBottom} showFooterLine={this.state.data.footer.length > 0 || !isOpacityFooter}
                                                 onDoubleClick={() => this.props.setActiveZone({pageId: this.state.id, zone: 'footer'})}
                    >
                        {(
                            <EditorDropZoneForBlockItem orientation={"horizontal"} onDrop={(type, block) => this.handleAddRow("footer", 0, type, block as TEditorBlockElementData)}
                                                        isDisabled={isOpacityFooter} allowPushAdd isLast={this.state.data.footer.length === 0}
                                                        readonly={this.props.readonly}/>
                        )}

                        {this.state.data.footer.map((block, index, array) => (
                            <Fragment key={block.id}>
                                <EditorItemRow ref={this.state.refs[block.id!]}
                                               block={block} onDelete={this.handleDeleteRow("footer", index, block.id!)}
                                               parentRef={this}
                                               editorWorkZoneRef={this.props.editorWorkZoneRef}
                                               getToolBarState={this.props.getToolBarState}

                                               status={this.props.status}
                                               readonly={this.props.readonly}
                                               isLoading={this.props.isLoading}
                                />

                                {(
                                    <EditorDropZoneForBlockItem key={block.id + 'index' + index}
                                                                orientation={"horizontal"} onDrop={(type, block) => this.handleAddRow("footer", index + 1, type, block as TEditorBlockElementData)}
                                                                isDisabled={isOpacityFooter} allowPushAdd isLast={array.length - 1 === index}
                                                                readonly={this.props.readonly}/>
                                )}
                            </Fragment>
                        ))}
                    </EditorItemPageContentStyled>
                </EditorTooltip>

            </EditorItemPageStyled>
        );
    }
}