//core
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

//redux
import {hideStageActor, selectedAutomation, setStageActor, stageActor} from "../../../store/slice";

//material
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";

//types
import {automationType} from "../../../types";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
//import {useForm} from "../../../../../../newShared/hooks/useForm";
import {validateEmail} from "../../../../../../newShared/utils/text";
import {AutocompleteCollaborators} from "../../../../../../newShared/components/AutocompleteCollaborators";
import colors from "../../../../../../newShared/theme/colors";
import {WorkspaceCollaboratorModel} from "../../../../../../newShared/GQLTypes";
import {TCollaborator} from "../../../../../../newShared/components/AutocompleteCollaborators/types";

export const StageActorDialog = () => {
    const dispatch = useDispatch();
    const {t} = useMainTranslation('', {keyPrefix: 'automations'});
    const [selectedCollaborator, setSelectedCollaborator] = useState<string | WorkspaceCollaboratorModel | null>(null);
    const auto: automationType | null = useSelector(selectedAutomation);
    const {isOpen, stageId} = useSelector(stageActor);
    // const isLoading = useSelector(loadings).getEmployees;
    // const empls = useSelector(employees);


    // useEffect(() => {
    //     if(isOpen){
    //         if(!empls.length){
    //             dispatch(GetEmployeesNameEmail());
    //         }
    //         setType('hr');
    //         setSelectedEmployee(null);
    //         setForm({email: ''});
    //     }
    //     //eslint-disable-next-line
    // }, [isOpen]);

    const handleSelect = () => {
        if(!isOk()) return;
        let payload;
        if(typeof selectedCollaborator === "string"){
            payload = {email: selectedCollaborator};
        } else {
            payload = {email: selectedCollaborator?.email ?? '', firstName: selectedCollaborator?.firstName ?? '', lastName: selectedCollaborator?.lastName ?? ''};
        }
        dispatch(setStageActor(payload));
        handleClose();
    }

    const handleClose = () => {
        dispatch(hideStageActor());
    };

    const validateEmailAlreadyAdded = (email: string): boolean => {
        return (auto ? (auto.stages[stageId] !== null && auto.stages[stageId]?.actors.some(e => e.email === email)) : false);
    }

    //
    //const [type, setType] = useState<'hr' | 'email'>('hr');
    //const {setForm, form, handleChange} = useForm<{email: string}>({email: ''});

    // const isEmailValid = validateEmail(form.email);
    // const isEmailAlreadyAdded = validateEmailAlreadyAdded(form.email);

    const isOk = (): boolean => {
        if(!selectedCollaborator) return false;
        if(typeof selectedCollaborator === "string"){
            return validateEmail(selectedCollaborator) && !validateEmailAlreadyAdded(selectedCollaborator);
        } else {
            return !validateEmailAlreadyAdded(selectedCollaborator?.email ?? '')
        }
    }

    const isOptionDisabled = (option: string | TCollaborator): boolean => {
        if(typeof option === 'string') {
            return validateEmailAlreadyAdded(option);
        }
        return validateEmailAlreadyAdded(option.email);

    }

    return (auto &&
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth={'xs'}>
            <DialogTitle>{t("Add a collaborator to {{stageType}} stage", {stageType: (auto && t(`stageTypes.${auto.stages[stageId]?.type}`)) || ''})}</DialogTitle>

            <DialogContent>
                {/*<RadioGroup row value={type} sx={{flexWrap: 'nowrap'}} onChange={(e, value) => setType(value as ("email" | "hr"))}>*/}
                {/*    <FormControlLabel value={'hr'} control={<Radio size={'small'} />} label={<Typography variant={'body2'}>{'Select employee'}</Typography>} />*/}
                {/*    <FormControlLabel value={'email'} control={<Radio size={'small'} />} label={<Typography variant={'body2'}>{'Email'}</Typography>} />*/}

                {/*</RadioGroup>*/}
                <Typography variant={'body2'} color={colors.darkBlueText}>{t('Select an actor from the collaborators list or add manually')}</Typography>

                <AutocompleteCollaborators
                    hideCount
                    freeSolo
                    //multiple
                    getOptionLabelCustom={"name+email"}
                    defaultValue={null}
                    onChange={value => setSelectedCollaborator(value as WorkspaceCollaboratorModel | string)}
                    getOptionDisabled={value => isOptionDisabled(value)}

                />


                {/*{type === 'hr' ?*/}
                {/*    <Autocomplete*/}
                {/*        id="combo-box-demo"*/}
                {/*        options={empls}*/}
                {/*        loading={isLoading}*/}
                {/*        getOptionLabel={(option) => `${option.workEmail} (${option.firstName} ${option.lastName})`}*/}
                {/*        getOptionDisabled={(option) => validateEmailAlreadyAdded(option.workEmail ?? '')}*/}
                {/*        value={selectedEmployee}*/}
                {/*        onChange={(e, newValue:  TShortEmployee | null) => newValue && setSelectedEmployee(newValue)}*/}
                {/*        renderInput={(params) => <S.TextFieldCustom {...params} label={("Select employee")} size={'small'} required/>}*/}
                {/*        sx={{margin: '8px 0 0 0'}}*/}
                {/*    />*/}
                {/*    :*/}
                {/*    <S.TextFieldCustom*/}
                {/*        label={'Email'}*/}
                {/*        size={'small'}*/}
                {/*        required*/}
                {/*        name={'email'}*/}
                {/*        type={'email'}*/}
                {/*        onChange={handleChange}*/}
                {/*        value={form.email}*/}
                {/*        error={form.email.trim().length > 0 && (!isEmailValid || isEmailAlreadyAdded)}*/}
                {/*        helperText={*/}
                {/*        form.email.trim().length > 0 ?*/}
                {/*            (isEmailValid ? (isEmailAlreadyAdded ? 'This email already added' : '') : 'Email is invalid')*/}
                {/*            :*/}
                {/*        false*/}
                {/*        }*/}
                {/*    />*/}

            </DialogContent>
            {/*<Flex p={'0 20px'} ai={'center'} jc={'center'} w={'100%'} direction={'column'} maxh={'400px'} overflow={'auto'} m={'10px 0 0 0'}>*/}
            {/*    {isLoadingMembers && <LocalSpinner />}*/}
            {/*    {!isLoadingMembers && !members.length && <Typo>{t('No users found...')}</Typo>}*/}
            {/*    {!isLoadingMembers && members.length > 0 && members.map((e, id, arr) => {*/}
            {/*        const isAdded = validateEmailAlreadyAdded(e.email);*/}
            {/*        return(*/}
            {/*            <Flex h={'50px'} key={e.publicId} w={'90%'} ai={'center'} jc={'space-between'} minw={'300'} maxw={'400px'} m={id === arr.length - 1 ? '0' : '0 0 15px 0'}>*/}
            {/*                <Flex ai={'center'} w={'70%'}>*/}
            {/*                    <Avatar sx={{width: '30px', height: '30px', fontSize: '12px', margin: (!revDir ? '0 10px 0 0' : '0 0 0 10px')}}>{e.firstName[0]} {e.lastName[0]}</Avatar>*/}
            {/*                    <Flex direction={'column'}>*/}
            {/*                        <Typo margin={'0 0 5px 0'} color={isAdded ? '#9E9E9E' : 'black'} fontSize={'14px'} fontWeight={500} letterSpacing={'0.1px'} sx={{lineHeight: 'normal'}}>{e.firstName} {e.lastName}</Typo>*/}
            {/*                        <Typo color={isAdded ? '#9E9E9E' : 'black'} fontSize={'14px'} fontWeight={500} letterSpacing={'0.1px'} sx={{lineHeight: 'normal'}}>{e.email}</Typo>*/}
            {/*                    </Flex>*/}
            {/*                </Flex>*/}
            {/*                <Button disabled={isAdded} size={'small'} variant={'contained'} onClick={() => handleSelect(e.email)}>{isAdded ? t('Selected') : t('Select')}</Button>*/}
            {/*            </Flex>*/}
            {/*        )*/}
            {/*    })}*/}
            {/*</Flex>*/}
            <DialogActions>
                <Button variant={'contained'} onClick={handleClose}>{t('Close')}</Button>
                <Button variant={'contained'} onClick={handleSelect} disabled={!isOk()}>{('Add')}</Button>
            </DialogActions>
        </Dialog>
    );
}