import {mapper, timeValues} from "../constants";
import {automationType} from "../types";
//MOMENT
import 'moment/locale/he';
import 'moment/locale/en-au';


export const translateText = (data: number): {factor: number, timeValue: string} => {
    if(data === mapper.Years) return {factor: 1, timeValue: timeValues[4]};
    if(data === mapper.Months) return {factor: 1, timeValue: timeValues[3]};
    if(data === mapper.Weeks) return {factor: 1, timeValue: timeValues[2]};
    if(data === mapper.Days) return {factor: 1, timeValue: timeValues[1]};
    if(data === mapper.Hours) return {factor: 1, timeValue: timeValues[0]};

    if(data % mapper.Years === 0  ) return {factor: data / mapper.Years, timeValue: timeValues[4]};
    if(data % mapper.Months === 0) return {factor: data / mapper.Months, timeValue: timeValues[3]};
    if(data % mapper.Weeks === 0) return {factor: data / mapper.Weeks, timeValue: timeValues[2]};
    if(data % mapper.Days === 0) return {factor: data / mapper.Days, timeValue: timeValues[1]};
    if(data % mapper.Hours === 0) return {factor: data / mapper.Hours, timeValue: timeValues[0]};

    return {factor: -1, timeValue: ' error'};
}

// export const parseDate = (date: string | null, locale: string, format?: string): string => {
//     // console.log(locale);
//     if(!date || !date.length) return '- - -';
//     const parsedDate = new Date(date.endsWith('Z') ? date : `${date}Z`);
//     return moment(parsedDate).locale(locale).format(format ?? 'LLL');
//     // dateFns
//     // return parsedDate.toLocaleDateString() + ' ' + parsedDate.toLocaleTimeString(undefined, { hour12: false})
// }

export const needSave = (auto: automationType | null, prev: automationType | null): boolean => {
    if(auto && isNeededETCTooBigWarning(auto.trigger.type, auto.trigger.repeat, auto.stages.map(e => e.eta))) return false;
    if(auto && !auto.id) return (auto.documents.links.length > 0 && auto.stages.filter(e => !e.actors.length).length === 0) && auto.evidence.data.length > 0;
    if(!auto || !prev) return false;
    if(auto && (auto.evidence.data.length < 1 || auto.stages.length < 1 || auto.stages.some(e => e.actors.length < 1) || auto.documents.links.length < 1) ) return false;
    return JSON.stringify({...auto, active: true}) !== JSON.stringify({...prev, active: true})
}

export const getInitAutomation = (name: string, workspaceName: string, workspaceId: string): automationType => {
    return {
        name,
        id: '',
        workspaceId,
        workspaceName,
        active: true,
        trigger: {
            type: 'schedule',
            start: getIsoDateTimeNowWithZeroMinutes(),
            repeat: 604800*1000
        },
        documents: {
            type: 'file',
            links: [],
        },
        stages: [
            {
                id: 'randomFirstStage',
                type: 'read',
                actors: [],
                eta: 86400*1000,
            },
        ],
        evidence: {
            type: 'evidence',
            data: [],
        },
        lastRunningDate: '',
        lastExecutorId: '',
        createDate: getIsoDateTimeNow(),
        history: [],
        pageInfo: {
            total: 0,
            page: 0,
            count: 0,
            sort: 'default',
        }
    };
}

export const getIsoDateTimeNowWithZeroMinutes = () => new Date(new Date(Date.now()).setMinutes(0, 0, 0)).toISOString();
export const getIsoDateTimeNow = () => new Date(Date.now()).toISOString();


export const makeFirstLetterUpper = (text: string) => text.split('').map((e, id) => id === 0 ? e.toUpperCase() : e).join('');

export const isNeededETCTooBigWarning = (triggerType: string, repeat: number, stages: number[]) => {
    if(triggerType === 'manual') return false;
    return repeat < stages.reduce((prev, curr) => prev + curr);
}

export const shortString = (text: string, howMuch: number) => text.split('').length >= howMuch ? text.split('').slice(0, howMuch).concat('.', '.', '.').join('') : text;