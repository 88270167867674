import {Box, Typography} from "@mui/material";
import React, {FC, useEffect} from "react";
import {PageWrapper} from "../../../../../newShared/components/Layouts";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    PATH_LOCAL_COMPLIANCE_REPORTS,
    PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED,
    PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED
} from "../../../../../newShared/constants";
import {useHistory} from "react-router";
import colors from "../../../../../newShared/theme/colors";
import {useReports} from "../../hooks/useReports";
import {useReportsIntegrations} from "../../hooks/useReportsIntegrations";
import {AppCardSkeleton} from "../../../newIntegrations/components/appCardSkeleton";
import * as S from "../../../newIntegrations/components/styled";
import {useReportsParams} from "../../hooks/useReportsParams";
import {useReport} from "../../hooks/useReport";
import {ReportIntegrationCard} from "../../components/ReportIntegrationCard";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";

export const ReportIntegrationsDetails: FC = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const {t} = useMainTranslation('', {keyPrefix: 'pathReports.View'});

    const history = useHistory();

    const {
        integrations: {integrations, loading: {isAppListLoading}},
        getReportIntegrations,
    } = useReportsIntegrations();
    const {goToReport} = useReports();
    const {reportId} = useReportsParams();
    const {
        report: {report},
    } = useReport();

    const ARCHIVED = report?.archived;

    //Breadcrumbs
    useSetBreadcrumbs([
        {
            title: tMenu('Reports'),
            path: PATH_LOCAL_COMPLIANCE_REPORTS,
        },
        {
            title: tMenu('Generated reports'),
            path: PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED,
            skip: ARCHIVED ?? !history.location.pathname.includes(PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED),
        },
        {
            title: tMenu('Archived reports'),
            path: PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED,
            skip: !ARCHIVED ?? !history.location.pathname.includes(PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED),
        },
        {
            title: report?.name || 'Loading report...',
            path: report ? `${report.archived ? PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED : PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED}/${report.id}` : undefined,
        },
        {
            title: t('Integration details'),
        },
    ]);

    useEffect(() => {
        reportId && getReportIntegrations({workspaceId: '', reportId: reportId})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportId])

    return (
        <Box display={"flex"} width={'100%'} height={'100%'} overflow={"hidden"}>
            <PageWrapper>
                <PageTitleAndActions
                    handleGoBack={() => report && goToReport(report.id, !!ARCHIVED)}
                    title={report?.name}
                    actions={[]}
                />

                {/*<Breadcrumbs/>*/}

                {/*<Box display={"flex"} alignItems={"center"} mt={'12px'} mb={'16px'} >*/}
                {/*    <Typography variant={!matches_1440Down ? "h1" : 'h3'} component={"span"} margin={!revDir ? '0 32px 0 0' : '0 0 0 32px'}*/}
                {/*                overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"}>*/}
                {/*        {report && (*/}
                {/*            <IconButton sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!matches_1440Down ? 4 : 2}px 0` : `0 0 ${!matches_1440Down ? 4 : 2}px 12px`, padding: '0'}}*/}
                {/*                        onClick={() => report && goToReport(report.id, !!ARCHIVED)} size={matches_1440Down ? 'small' : undefined}>*/}
                {/*                {!revDir ? <ArrowBack/> : <ArrowForward/>}*/}
                {/*            </IconButton>*/}
                {/*        )}*/}

                {/*        {report ? report.name : <Skeleton width={'300px'}/>}*/}
                {/*    </Typography>*/}
                {/*</Box>*/}



                {integrations.length === 0 && !isAppListLoading && <Typography color={colors.darkBlueText} sx={{margin: '8px 0 0 0'}}>{('Integrations not found.')}</Typography>}

                <S.IntegrationCardsContainer>
                    {isAppListLoading && <AppCardSkeleton />}
                    {integrations.map(app => (
                        <ReportIntegrationCard key={app.id} app={app}/>
                    ))}
                </S.IntegrationCardsContainer>
            </PageWrapper>

        </Box>
    )
}