import {
    dialogs,
    eraseTemplates,
    loadings,
    setTemplatePreviewAction,
    setUseTemplateAction,
    templatesSelector
} from "../../store/slice";
import {useDispatch, useSelector} from "react-redux";
import {CommonFilterType} from "../../../../../newShared/components/genericTable/types";
import {
    mainTrainingsGetTrainingTemplateByIdAction,
    mainTrainingsGetTrainingTemplatesWithFilterPaginationAction
} from "../../store/actions";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {defaultPageInfo, PATH_TRAININGS_LIST, TRAININGS_PATH} from "../../constants";
import {mainTrainingsGetTemplatesCategoriesAutocompleteApi} from "../../api";
import {useScrollPagination} from "../../../../../newShared/hooks/useScrollPagination";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {useEffect} from "react";

export const useTemplatesList = () => {
    const {t, tMenu} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.pathTrainings'});

    const dispatch = useDispatch();
    // const history = useHistory();

    const {templates, pageInfo} = useSelector(templatesSelector);
    const {templateList: isLoadingList} = useSelector(loadings);
    const {
        templatePreview: {templateId: isLoadingTemplatePreview},
        useTemplate: {isOpen: isOpenUseTemplate, templateId: isLoadingUseTemplate},
    } = useSelector(dialogs);



    const handleOpenPreview = (templateId: string) => {
        dispatch(setTemplatePreviewAction({templateId}));
        dispatch(mainTrainingsGetTrainingTemplateByIdAction({data: {templateId}, addictiveData: {type: 'PREVIEW'}}))
    }

    const handleOpenUseTemplate = (templateId: string) => {
        dispatch(setUseTemplateAction({templateId}));
        dispatch(mainTrainingsGetTrainingTemplateByIdAction({data: {templateId}, addictiveData: {type: 'USE'}}))
    }

    const handleCloseUseTemplate = () => {
        dispatch(setUseTemplateAction({clear: true}));
    }

    const {currentFiltersForFetch, currentSearchForFetch, clearFilters, isSearchAndFilterEmpty} = useGenericFiltersStorage();

    const fetchPaging = (page: number, count: number, filters: CommonFilterType, templateNameLike: string, clean?: boolean) => {
        dispatch(mainTrainingsGetTrainingTemplatesWithFilterPaginationAction({data: {pageRequest: {page, count: count || defaultPageInfo.count}, filter: {...filters, templateNameLike}}, clean}))
    }

    const {onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => {
            fetchPaging(page, count, currentFiltersForFetch, currentSearchForFetch);
        },
        arrayLength: templates.length,
        isLoading: isLoadingList,
        pageInfo,
        cleanData: () => dispatch(eraseTemplates()),
        isOnTop: false,
        isNoMoreThanTotal: true,
    })

    const categoryConfig: keyConfig<string> = {
        type: 'filter',
        key: 'category',
        name: t('Category'),
        getOptionLabel: (a) => a,
        getFilterValue: (a) => a,
        isOptionEqualToSearch: (a, search) => a.trim().toLowerCase().includes(search.trim().toLowerCase()),
        fetchOptions: (workspaceId, {page, count, search}) => {
            return mainTrainingsGetTemplatesCategoriesAutocompleteApi({ pageRequest: {page, count}, search})
                .then(({result, pageInfo}) => ({values: result, pageInfo}))
        },
        default: true,
    }

    useSetBreadcrumbs([
        {
            title: tMenu('Training management'),
            path: TRAININGS_PATH,
        },
        {
            title: tMenu('Templates'),
            path: PATH_TRAININGS_LIST
        }
    ]);

    useEffect(() => {

        return () => {
            clearFilters();
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return {
        templates,
        fetchPaging,
        onScroll,
        scrollRef,
        isLoadingList,
        handleOpenPreview,
        useTemplateDialog: {
            isOpen: isOpenUseTemplate,
            handleOpen: handleOpenUseTemplate,
            handleClose: handleCloseUseTemplate,
        },
        isLoadingTemplatePreview,
        isLoadingUseTemplate,
        isEmptyResultByFilter: !isSearchAndFilterEmpty && !isLoadingList && !templates.length,
        filtersConfig: [
            categoryConfig,
        ],
    }
}
