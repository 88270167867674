import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {cleanUp} from "../../../store/slice";

export const useSettingMain = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(cleanUp());
        //eslint-disable-next-line
    }, []);
}