import {MainVicaStepStatus} from "../../../newShared/GQLTypes";
import {TPageInfo} from "../../../newShared/types";
import { TCreateFormDoc } from "./types";


export const PATH_LOCAL_VICA = '/vica';

export const PATH_VICA_DASHBOARD = `${PATH_LOCAL_VICA}/dashboard`;
export const PATH_VICA_TASKS = `${PATH_LOCAL_VICA}/tasks`;
export const PATH_VICA_STEP_EXACT = `${PATH_VICA_TASKS}/:stepId`;
export const PATH_VICA_STEP_EXACT_TASK_EXACT = `${PATH_VICA_STEP_EXACT}/:taskId`;
export const PATH_VICA_FINDINGS = `${PATH_LOCAL_VICA}/findings`;

export const ACTION_LINK_TYPE = 'LINK';
export const ACTION_UPLOAD_TYPE = 'UPLOAD';
export const ACTION_TEMPLATE_TYPE = 'TEMPLATE';
export const EVIDENCE_UPLOAD_TYPE = 'FILE';

export const vicaStepStatusTitleMapper: Record<MainVicaStepStatus, string> = {
    "NOT_STARTED": 'Not started',
    "IN_PROGRESS": 'In progress',
    "COMPLETED": 'Completed',
}

export const defaultPageInfo: TPageInfo = {
    page: 0,
    count: 10, //used to show loader (shows by hasMore) on init, before resolving first fetch
    total: 0 //it will be replaced by resolved data
}


export const defaultFormCreateDoc = {
    workspaceId: '',
    name: '',
    data: null,
    folderId: '',
    tags: [],
    editors: [],
    approvers: [],
    recipients: [],
} satisfies TCreateFormDoc;