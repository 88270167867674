import React, {ChangeEventHandler, FC, useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {EditorSideMenuETAFieldStyled} from "../../styled";
import colors from "../../../../../../theme/colors";
import {FlexRow} from "../../../editorTitleWithActionsRow/styled";

interface SetEtaDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm: (eta: number) => void;
}
export const SetEtaDialog: FC<SetEtaDialogProps> = ({open, onConfirm, onClose}) => {

    const [eta, setEta] = useState<number>(0);
    useEffect(() => {
        setEta(2);
    }, [open]);

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = parseInt(e.target.value);
        setEta(value);
    };

    const handleConfirm = () => {
        onConfirm(eta);
        onClose();
    }

    return (
        <Dialog open={open} onClose={onClose} sx={{
            '& .MuiDialog-paper': {
                width: '300px',
            }
        }}>
            <DialogTitle>{('Add ETA')}</DialogTitle>

            <DialogContent>
                <FlexRow alignItems={'center'} gap={'10px'}>
                    <Typography variant={"body2"} sx={{color: colors.text.dark}}>{'Add'}</Typography>

                    <EditorSideMenuETAFieldStyled
                        value={eta}
                        onChange={handleChange}/>

                    <Typography variant={"body2"} sx={{color: colors.text.dark}}>{'days to ETA'}</Typography>
                </FlexRow>
            </DialogContent>

            <DialogActions>
                <Button variant={"text"} onClick={onClose}>{('Cancel')}</Button>
                <Button disabled={eta < 1} onClick={handleConfirm}>
                    {('Confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}