import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_VULNERABILITY, PATH_LOCAL_VULNERABILITY_SCANNED} from "../../../../../newShared/constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {ipRegex, nakedDomainRegex, PATH_LOCAL_VULNERABILITY_NEW} from "../../constants";
import {useDispatch, useSelector} from "react-redux";
import {loadings} from "../../store/slice";
import React, {useState} from "react";
import {createTargetRequestType} from "../../types";
import {getDefaultAddTargetForm} from "../../helpers/index";
import {useHistory} from "react-router-dom";
import {CreateTarget} from "../../store/actions";

export const useTargetsAdd = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {t: tMenu, revDir} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    useSetBreadcrumbs([
        {
            title: tMenu('Vulnerability Scanner'),
            path: PATH_LOCAL_VULNERABILITY_SCANNED
        },
        {
            title: tMenu('Scanned items'),
            path: PATH_LOCAL_VULNERABILITY
        },
        {
            title: tMenu('New target'),
            path: PATH_LOCAL_VULNERABILITY_NEW
        }
    ]);

    const [form, setForm] = useState<createTargetRequestType>(getDefaultAddTargetForm());

    const handleChange = (e: React.ChangeEvent<{name?:string | undefined; value?:unknown}>) => {
        e.target.name && setForm({...form, [e.target.name]: e.target.value as string});
    }

    const isOk = () => {
        if(!form.name.length || !form.target.length) return false;
        if(form.target.startsWith('http://')){
            return nakedDomainRegex.test(form.target.substring('http://'.length));
        }
        if(form.target.startsWith('https://')){
            return nakedDomainRegex.test(form.target.substring('https://'.length));
        }
        return ipRegex.test(form.target) || nakedDomainRegex.test(form.target);
    }

    const handleCreateTarget = () => {
        isOk() && dispatch(CreateTarget({target: form, onSuccess: goToId}));
    }

    const goToId = (id: string) => {
        history.push(`${PATH_LOCAL_VULNERABILITY_SCANNED}/${id}`);
    }

    return{
        isLoading: useSelector(loadings).isLoadingAddTarget,
        revDir,
        form,
        handleChange,
        isOk,
        handleGoBack: () => history.push(PATH_LOCAL_VULNERABILITY_SCANNED),
        handleCreateTarget
    }
}