//core
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

//redux
import {docLink, hideDocLink, selectedAutomation, setDocument} from "../../../store/slice";

//material
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,} from "@mui/material";

//types
import {automationType} from "../../../types";
import {addInfoSnack} from "../../../../../barsEnvironment/snack/store/slice";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../../../../newShared/components/Layouts";

//components

export const DocLinkSelectionDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'automations'});

    const dispatch = useDispatch();
    const auto: automationType | null = useSelector(selectedAutomation);
    const {isOpen} = useSelector(docLink);

    const [name, setName] = useState<string>('');
    const [link, setLink] = useState<string>('');

    const handleSelect = () => {
        if(!link.length || !name.length) return;
        if(auto?.documents.links.find(e => e.fileName === name) === undefined){
            dispatch(setDocument(
                {
                    fileName: name,
                    link: link,
                    lastUpdate: new Date(Date.now()).toISOString(),
                }
            ));
            setName('');
            setLink('');
        }else{
            dispatch(addInfoSnack(t('Link {{name}} already added', {name})));
        }
    }

    const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
        if (reason !== 'backdropClick') {
            dispatch(hideDocLink());
            setName('');
            setLink('');
        }
    };

    return (auto &&

        <div>
            <Dialog disableEscapeKeyDown open={isOpen} onClose={handleClose} maxWidth={'xl'}
                    sx={{'& .MuiPaper-root': {minWidth: '450px'}}}
            >
                <DialogTitle>Select Link File</DialogTitle>
                <DialogContent style={{width: '30vw', height: '20vh', minWidth: '450px', minHeight: '180px'}}>
                    <Flex ai={'center'} jc={'center'} m={'10px 0 0 0'} direction={'column'}>
                        <TextField id="outlined-name" label="Name" variant="outlined" value={name} onChange={(e) => {setName(e.target.value as string)}}/>
                        <TextField style={{margin: '15px 0 0 0'}} id="outlined-link" label="Link" variant="outlined" value={link} onChange={(e) => {setLink(e.target.value as string)}}/>
                    </Flex>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>{t('Cancel')}</Button>
                    <Button onClick={handleSelect} disabled={!link.length || !name.length}>{t('Ok')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}