import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    hrSettings,
    loadings,
    openAddSettingDialog,
    openDeleteHrSettingDialog,
    openEditHrSettingDialog,
} from "../../store/slice";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_EMPLOYEES} from "../../../../../newShared/constants";
import {PATH_LOCAL_EMPLOYEES_SETTINGS, PATH_LOCAL_EMPLOYEES_SETTINGS_EXACT} from "../../constants";
import {normalizeSettingIdName} from "../../helpers";
import {SettingsTypes, THrSettings} from "../../types";

export const useExactHRSetting = () => {
    const dispatch = useDispatch();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const {t} = useMainTranslation('', {keyPrefix: 'employees'});
    const id = useParams<{id: string}>().id;
    const normalizedName = normalizeSettingIdName(id as SettingsTypes);

    const settings = useSelector(hrSettings);
    const {getHrSettings: isLoadingHrSettings} = useSelector(loadings);

    const history = useHistory();


    useSetBreadcrumbs([
        {
            title: tMenu('HR Management'),
            path: PATH_LOCAL_EMPLOYEES
        },
        {
            title: tMenu('Settings'),
            path: PATH_LOCAL_EMPLOYEES_SETTINGS
        },
        {
            title: t(normalizedName),
            path: PATH_LOCAL_EMPLOYEES_SETTINGS_EXACT.replace(':id', id)
        }
    ]);

    const handleCreateNewItem = (type: keyof THrSettings) => {
        dispatch(openAddSettingDialog({type}));
    }

    const handleGoBack = () => history.push(PATH_LOCAL_EMPLOYEES_SETTINGS);

    const handleOpenEditSetting = (value: string, field: SettingsTypes) => {
        dispatch(openEditHrSettingDialog({value, field}));
    }

    const handleOpenDeleteSetting = (value: string, field: SettingsTypes) => {
        dispatch(openDeleteHrSettingDialog({value, field}));
    }

    return{
        settings,
        id: id as SettingsTypes,
        isLoadingHrSettings,
        normalizedName,
        handleCreateNewItem,
        handleGoBack,
        handleOpenEditSetting,
        handleOpenDeleteSetting,
    }
}