import {gql} from "@apollo/react-hooks";
import {importCsvFragmentFields, performCsvFragment} from "../../../../newShared/graphQl/fragments";

export const changeUserProfileInfoGQL = gql`
    mutation changeUserProfileInfoGQL($data: ChangeNameInput!) {
        changeName(data: $data) {
            message
        }
    }
`;

export const validate2faCode = gql`
    mutation validate2faCode($code: String!, $secret: String!) {
        initialValidateGoogleAuthorization(code: $code, secret: $secret)
    }
`;

export const reconfigureGoogleAuthInSettings = gql`
    mutation reconfigureGoogleAuthInSettings {
        reconfigureGoogleAuth {
            qrCode
            secret
        }
    }
`;

export const disableGoogleAuth = gql`
    mutation disableGoogleAuth {
        disableGoogleAuth {
            message
        }
    }
`;

export const importCsvTemplateCollaborators = gql`
    query importCsvTemplatecollaborators($workspaceId: String!){
        importCsvTemplateCollaborators(workspaceId: $workspaceId)
    }
`;

export const getCsvFieldsCollaborators = gql`
    mutation getCsvFieldsCollaborators($workspaceId: String!, $file: String!, $name: String!){
        getCsvFieldsCollaborators(workspaceId: $workspaceId, file: $file, name: $name) {
            fileId
            fields {
                ...importCsvFragmentFields
            }
        }
    }
    ${importCsvFragmentFields}
`

export const performCsvCollaborators = gql`
    mutation performCsvCollaborators($workspaceId: String!, $fileId: String!, $fields: [ImportCsvFieldInput!]!){
        performCsvCollaborators(workspaceId: $workspaceId, fileId: $fileId, fields: $fields) {
            ...performCsvFragment
        }
    }
    ${performCsvFragment}
`

export const getInvitesGQL = gql`
    query getInvites{
        getInvites{
            id
            inviteStatus
            roles
            expirationDate
            activationDate
            creationDate
            organizationName
            emailAddress
            sender{
                firstName
                lastName
                email
            }
            workspaceId
            workspaceName
            organizationId
        }
    }
`;
export const acceptInviteGQL = gql`
    mutation acceptInvite($inviteId:String!){
        acceptInvite(inviteId:$inviteId){
            message
        }
    }
`;
export const declineInviteGQL = gql`
    mutation declineInvite($inviteId:String!){
        declineInvite(inviteId:$inviteId){
            message
        }
    }
`;
export const getOrganizationsGQL = gql`
    query getOrganizations($pageInfo:PaginationInput!){
        getOrganizations(pageInfo:$pageInfo){
            organizations{
                id
                name
                logo
                collaborators{
                    publicId
                    role
                }
                subscription
                subscriptionExpiry
                trialUsed
                configuration{
                    availableWizards
                    availableWorkspaceCount
                    availableCollaboratorsCount
                    availableCollaboratorsRules{
                        email
                        type
                        count
                    }
                    availableTargetsVulnerabilityScan
                    availableKycVerificationCount
                }
                blocked
                blockedReason
                workspaceCount
            }
            pageInfo{
                page
                count
                total
                sort
            }
        }
    }
`;
export const getOrganizationByIdGQL = gql`
    query getOrganizationById($id:String!){
        getOrganizationById(id:$id){
            id
            name
            logo
            collaborators{
                publicId
                role
            }
            subscription
            subscriptionExpiry
            trialUsed
            configuration{
                availableWizards
                availableWorkspaceCount
                availableCollaboratorsCount
                availableCollaboratorsRules{
                    email
                    type
                    count
                }
                availableTargetsVulnerabilityScan
                availableKycVerificationCount
            }
            blocked
            blockedReason
            workspaceCount
        }
    }
`;
export const getWorkspacesGQL = gql`
    query getWorkspaces($pageInfo:PaginationInput!, $id:String!){
        getWorkspaces(pageInfo:$pageInfo, id:$id){
            workspaces{
                id
                name
                collaboratorsCount
                enabledKycLimit
            }
            pageInfo{
                page
                count
                total
                sort
            }
        }
    }
`;
export const sendSupportMessageGQL = gql`
    mutation sendSupportMessage($data:SupportMessageInput!, $wid: String!){
        sendSupportMessage(data: $data, workspaceId: $wid){
            isSent
        }
    }
`;
export const createWorkspaceGQL = gql`
    mutation createWS($workspaceName:String!, $organizationId: String!){
        addWorkspace(organizationId:$organizationId, workspaceName: $workspaceName){
            id
            name
            organizationId
            status
            subscription
            collaborators{
                publicId
                role
            }
        }
    }
`;
export const updateOrganizationNameGQL = gql`
    mutation updateOrganizationName($organizationId:String!, $newName:String!){
        updateOrganizationName(organizationId:$organizationId, newName:$newName){
            message
        }
    }
`;
export const updateOrganizationLogoGQL = gql`
    mutation updateOrganizationLogo($organizationId:String!, $newLogo:String!){
        updateOrganizationLogo(organizationId:$organizationId, newLogo:$newLogo){
            message
        }
    }
`;
export const transferOrganizationOwnershipGQL = gql`
    mutation transferOrganizationOwnership($transferToPublicId:String!,$organizationId:String!, $transferFromPublicId:String!){
        transferOrganizationOwnership(transferToPublicId:$transferToPublicId, organizationId:$organizationId, transferFromPublicId:$transferFromPublicId){
            message
        }
    }
`;
export const getWorkspaceByIdGQL = gql`
    query getRedesignWorkspaceById($workspaceId:String!, $organizationId:String!){
        getRedesignWorkspaceById(workspaceId:$workspaceId, organizationId:$organizationId){
            workspace{
                id
                name
                collaboratorsCount
                enabledKycLimit
            }
            organization{
                id
                name
                logo
                collaborators{
                    publicId
                    role
                }
                subscription
                subscriptionExpiry
                trialUsed
                configuration{
                    availableWizards
                    availableWorkspaceCount
                    availableCollaboratorsCount
                    availableCollaboratorsRules{
                        email
                        type
                        count
                    }
                    availableTargetsVulnerabilityScan
                }
                blocked
                blockedReason
                workspaceCount
            }

        }
    }
`;
export const getWorkspaceCollabsGQL = gql`
    query getWorkspaceUsersById($workspaceId:String!){
        getWorkspaceUsersById(workspaceId:$workspaceId){
            workspaceRoles{
                id
                name
                workspaceId
                permissions
                createDate
                lastUpdateDate
                system
                origin
            }
            collabs{
                createDate
                lastLoginDate
                email
                firstName
                lastName
                status
                publicId
                roles{
                    id
                    name
                    workspaceId
                    permissions
                    createDate
                    lastUpdateDate
                    origin
                    system
                }
            }
            invite{
                activationDate
                creationDate
                emailAddress
                expirationDate
                id
                inviteStatus
                sender{
                    firstName
                    lastName
                    email
                }
                roles
                workspaceId
                workspaceName
                organizationId
                organizationName
            }
            availablePermissions
        }
    }
`;
export const deleteWorkspaceCollaboratorGQL = gql`
    mutation deleteWSCollab($workspaceId: String!, $publicId:String!){
        deleteWorkspaceCollaborator(workspaceId:$workspaceId, publicId:$publicId){
            message
        }
    }
`;
export const updateWorkspaceNameGQL = gql`
    mutation updateWorkspaceName($workspaceId:String!, $newName:String!){
        updateWorkspaceName(workspaceId:$workspaceId, newName:$newName){
            message
        }
    }
`;
export const deleteWorkspaceGQL = gql`
    mutation deleteWS($workspaceId: String!,){
        deleteWorkspace(workspaceId:$workspaceId){
            message
        }
    }
`;
export const inviteMemberIntoWorkspaceGQL = gql`
    mutation inviteMemberIntoWorkspace($workspaceId:String!, $email:String!, $senderPublicId:String!, $roles:RolesArrayInput!){
        inviteMemberIntoWorkspace(workspaceId:$workspaceId, email:$email, senderPublicId:$senderPublicId, roles:$roles){
            workspaceRoles{
                id
                name
                workspaceId
                permissions
                createDate
                lastUpdateDate
                system
                origin
            }
            collabs{
                createDate
                lastLoginDate
                email
                firstName
                lastName
                status
                publicId
                roles{
                    id
                    name
                    workspaceId
                    permissions
                    createDate
                    lastUpdateDate
                    origin
                    system
                }
            }
            invite{
                activationDate
                creationDate
                emailAddress
                expirationDate
                id
                inviteStatus
                sender{
                    firstName
                    lastName
                    email
                }
                roles
                workspaceId
                workspaceName
                organizationId
                organizationName
            }
            availablePermissions
        }
    }
`;
export const updateUserRoleGQL = gql`
    mutation updateUserRole($publicId:String!, $workspaceId:String!, $roles:RolesArrayInput!){
        updateUserRole(publicId:$publicId, workspaceId:$workspaceId, roles:$roles){
            message
        }
    }
`;
export const resendWorkspaceInviteGQL = gql`
    mutation resendWSInvite($workspaceId: String!, $email:String!, $inviteId: String!){
        resendWorkspaceInvite(workspaceId:$workspaceId, email:$email, inviteId:$inviteId){
            message
        }
    }
`;
export const cancelWorkspaceInviteGQL = gql`
    mutation cancelOrganizationInvite($workspaceId: String!, $email:String!, $inviteId: String!){
        deleteWorkspaceInvite(workspaceId:$workspaceId, email:$email, inviteId:$inviteId){
            message
        }
    }
`;
export const createRoleGQL = gql`
    mutation createRole($data:createUpdateRoleRequestInput!){
        createRole(data:$data){
            message
        }
    }
`;
export const deleteRoleGQL = gql`
    mutation deleteRole($roleId:String!, $workspaceId:String!){
        deleteRole(roleId:$roleId, workspaceId:$workspaceId){
            message
        }
    }
`;
export const updateRoleGQL = gql`
    mutation editRole($data:createUpdateRoleRequestInput!){
        editRole(data:$data){
            message
        }
    }
`;
export const getInviteByIdForAcceptGQL = gql`
    query getInviteByIdForAccept($inviteId:String!){
        getInviteByIdForAccept(inviteId:$inviteId){
            id
            inviteStatus
            sender{
                firstName
                lastName
                email
            }
            roles
            creationDate
            expirationDate
            activationDate
            emailAddress
            isUserExists
            workspaceId
            workspaceName
            organizationId
            organizationName
        }
    }
`;
export const createAccountByInvitationGQL = gql`
    mutation createAccountByInvitation($data:CreateAccountByInviteDto!){
        createAccountByInvitation(data:$data){
            message
        }
    }
`;
export const getSmallOrgsAndWorkspacesForSelectGQL = gql`
    query getSmallOrgsAndWorkspacesForSelect{
        getSmallOrgsAndWorkspacesForSelect{
            organizations{
                workspaces{
                    id
                    collaboratorsCount
                    name
                    enabledKycLimit
                }
                id
                name
                logo
                subscription
                subscriptionExpiry
                blocked
                configuration{
                    availableWizards
                    availableWorkspaceCount
                    availableCollaboratorsCount
                    availableTargetsVulnerabilityScan
                }
            }
            portalAccess
        }
    }
`;
export const getCreateOrganizationDataGQL = gql`
    query getCreateOrganizationData{
        getCreateOrganizationData{
            countries{
                name
                code
                fields{
                    fieldKey
                    fieldLabel
                    fieldPlaceholder
                }
            }
            industries
            notCompletedOrganization{
                name
                logo
                subscription
                organizationDetails
                billing{
                    transactionNumber
                    currentTear{
                        variant
                        vatPercentage
                        discountPercentage
                        items{
                            name
                            quantity
                            cost
                            type
                            subscriptionType
                            currency
                        }
                    }
                    billingInfo{
                        firstName
                        lastName
                        email
                        address
                        zip
                        country
                        additionalData
                    }
                }
            }
        }
    }
`;
export const addOrganizationFreeGQL = gql`
    mutation addOrganizationFree($data:CreateOrganizationDto!){
        addOrganizationFree(data: $data){
            workspace{
                id
                name
                organizationId
            }
            organization{
                id
                name
                logo
                subscription
                subscriptionExpiry
                blocked
                blockedReason
            }
        }
    }
`;

export const addOrganizationEnterpriseGQL = gql`
    mutation addOrganizationEnterprise($data:CreateOrganizationDto!){
        addOrganizationEnterprise(data: $data){
            workspace{
                id
                name
                organizationId
            }
            organization{
                id
                name
                logo
                subscription
                subscriptionExpiry
                blocked
                blockedReason
            }
        }
    }
`;
export const addOrganizationBusinessWithoutPaymentGQL = gql`
    mutation addOrganizationBusinessWithoutPayment($data:CreateOrganizationDto!){
        addOrganizationBusinessWithoutPayment(data: $data){
            workspace{
                id
                name
                organizationId
            }
            organization{
                id
                name
                logo
                subscription
                subscriptionExpiry
                blocked
                blockedReason
            }
        }
    }
`;
export const addOrganizationBusinessWithBillingGQL = gql`
    mutation addOrganizationBusinessWithBilling($data:CreateOrganizationDto!, $billingData:CreateOrganizationBillingInfoInput!, $calculatedTotal: Float!){
        addOrganizationBusinessWithBilling(data:$data, billingData:$billingData, calculatedTotal:$calculatedTotal){
            name
            logo
            subscription
            organizationDetails
            billing{
                transactionNumber
                currentTear{
                    variant
                    vatPercentage
                    discountPercentage
                    items{
                        name
                        quantity
                        cost
                        type
                        subscriptionType
                        currency
                    }
                }
                billingInfo{
                    firstName
                    lastName
                    email
                    address
                    zip
                    country
                    additionalData
                }
            }
        }
    }
`;
export const deleteNotCompletedOrganizationGQL = gql`
    mutation deleteNotCompletedOrganization{
        deleteNotCompletedOrganization{
            message
        }
    }
`;
export const checkTransactionStatusGQL = gql`
    query checkTransactionStatus($transactionNumber:String!){
        checkTransactionStatus(transactionNumber:$transactionNumber){
            status
            organizationId
            workspaceId
        }
    }
`;
export const getOrganizationBillingGQL = gql`
    query getOrganizationBilling($organizationId:String!){
        getOrganizationBilling(organizationId:$organizationId){
            transactionNumber
            paymentPeriodStart
            currentTear{
                variant
                items{
                    name
                    type
                    subscriptionType
                    quantity
                    currency
                    cost
                }
                vatPercentage
                discountPercentage
            }
            paymentMethod{
                lastFourDigits
                paymentMethodName
                cardExpiration
                cardMask
                cardType
            }
            billingInfo{
                firstName
                lastName
                address
                country
                zip
                email
                additionalData
            }
            futurePayments{
                items{
                    name
                    type
                    subscriptionType
                    quantity
                    currency
                    cost
                }
                futurePaymentDate
                retryCount
                vatPercentage
                discountPercentage
                variant
                periodStart
                periodEnd
            }
            paymentHistory{
                items{
                    name
                    type
                    subscriptionType
                    quantity
                    currency
                    cost
                }
                futurePaymentDate
                retryCount
                vatPercentage
                discountPercentage
                variant
                periodStart
                periodEnd
                status
                statusChangedDate
                transactionNumber
                receiptFileId
                invoiceFileId
            }
        }
    }
`;
export const updateOrganizationBillingInfoGQL = gql`
    mutation updateOrganizationBillingInfo($orgId:String!, $billingData:CreateOrganizationBillingInfoInput!){
        updateOrganizationBillingInfo(orgId:$orgId, billingData:$billingData){
            transactionNumber
            paymentPeriodStart
            currentTear{
                variant
                items{
                    name
                    type
                    subscriptionType
                    quantity
                    currency
                    cost
                }
                vatPercentage
                discountPercentage
            }
            paymentMethod{
                lastFourDigits
                paymentMethodName
                cardMask
                cardExpiration
                cardType
            }
            billingInfo{
                firstName
                lastName
                address
                country
                zip
                email
                additionalData
            }
            futurePayments{
                items{
                    name
                    type
                    subscriptionType
                    quantity
                    currency
                    cost
                }
                futurePaymentDate
                retryCount
                vatPercentage
                discountPercentage
                variant
                periodStart
                periodEnd
            }
            paymentHistory{
                items{
                    name
                    type
                    subscriptionType
                    quantity
                    currency
                    cost
                }
                futurePaymentDate
                retryCount
                vatPercentage
                discountPercentage
                variant
                periodStart
                periodEnd
                status
                statusChangedDate
                transactionNumber
                receiptFileId
                invoiceFileId
            }
        }
    }
`;


