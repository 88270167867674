import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {useEditRegistry} from "../../../hooks/dialogsHooks/useEditRegistry";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import React from "react";
import {LoadingButton} from "@mui/lab";
import {AutoComplete, TextFieldCustom} from "../../styled";
import {TRiskModel} from "../../../types";
import {Flex} from "../../../../../../newShared/components/Layouts";

export const EditRegistry = () => {
    const {
        isOpen,
        handleClose,
        isLoading,
        handleSubmit,
        selectedModel,
        models,
        isLoadingModels,
        form,
        handleChange,
        isOk,
        handleSetModel
    } = useEditRegistry();

    const {revDir, t} = useMainTranslation('', {keyPrefix: 'risks'});
    const {isMobile, isTablet} = useMedia();

    return(
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{t('Edit registry')}</DialogTitle>

            <DialogContent>
                <Flex direction={'column'} w={'100%'}>
                    <TextFieldCustom
                        required
                        value={form.name}
                        label={t('Name')}
                        name={'name'}
                        size={'small'}
                        dir={'ltr'}
                        onChange={handleChange}
                        sx={{margin: '16px 0 0 0', width: isTablet ? '100%' : '60%'}}
                    />

                    <TextFieldCustom
                        required
                        value={form.context}
                        label={t('Context')}
                        name={'context'}
                        size={'small'}
                        dir={'ltr'}
                        onChange={handleChange}
                        sx={{margin: '16px 0 0 0', width: isTablet ? '100%' : '60%'}}
                    />

                    <AutoComplete
                        disabled={isLoadingModels} //loading
                        id="combo-box-demo"
                        fullWidth
                        options={models}
                        getOptionLabel={(option) => (option as TRiskModel).name}
                        value={selectedModel}
                        disableClearable
                        dir={'ltr'}
                        onChange={(e, newValue) => handleSetModel(newValue as TRiskModel | null)}
                        sx={{margin: '16px 0 0 0', width: isTablet ? '100%' : '60%'}}
                        renderInput={(params: any) => <TextField {...params} size="small" label={t("Model")} required/>}
                    />
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{t('Cancel')}</Button>
                {isLoading ?
                    <LoadingButton
                        loading
                        sx={{paddingLeft: '35px', margin: !revDir ? '0 16px 0 0' : '0 0 0 16px', height: '36px'}}
                        variant="outlined"
                    >{t('Save')}</LoadingButton>
                    :
                    <Button
                        variant={'contained'}
                        size={isMobile ? 'small' : 'medium'}
                        disabled={!isOk()}
                        onClick={handleSubmit}
                        sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    >{t('Save')}</Button>
                }
            </DialogActions>
        </Dialog>
    )
}