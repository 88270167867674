import {useEffect} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {BUILDER_WITH_FRAME, ROOT_BUILDER, ROOT_FRAMEWORKS} from "../../constants";
import {useThreeDotsMenu} from "../../../../../newShared/hooks/useThreeDotsMenu";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {useRoutes} from "../new/useRoutes";
import {useBuilderFrameworks} from "../new/useBuilderFrameworks";
import {useDialogs} from "../new/useDialogs";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";

export const useFrameworkExact = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const {organizationId} = UseManageWorkspacesAndOrganizations();
    const {clearFilters, currentSearchForFetch} = useGenericFiltersStorage();

    const {
        frameworkId, filter,
        goToFrameworkControl,
        goToFrameworks,
    } = useRoutes();

    const {
        _frameworks, framework, _controls,
        _isLoading, isLoadingControls,
        getFrameworkById,

        deattachControl,
    } = useBuilderFrameworks();

    const isOwn = (id: string) => id === organizationId;

    const {
        anchorThreeDotsEl, handleThreeDotsOpen, handleThreeDotsClose,
    } = useThreeDotsMenu();

    //Pagination and filtering
    // const {value: searchValue, handleChange} = useSearchFieldURL({wait: 0});
    // const {setFilter, handleKeyDown} = useFilterUrl({
    //     getData: (filters) => {}
    // });

    useSetBreadcrumbs([
        {
            title: tMenu('Framework builder'),
            path: ROOT_BUILDER
        },
        {
            title: tMenu('Created frameworks'),
            path: ROOT_FRAMEWORKS
        },
        {
            title: framework ? framework.name : 'Loading...',
            path: BUILDER_WITH_FRAME.replace(':/frameworkId', frameworkId || '')
        }
    ]);

    useEffect(() => {
        return () => {
            clearFilters();
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        frameworkId && getFrameworkById(frameworkId);

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [frameworkId]);

    const handleDeattachControl = (e: any, controlId: string) => {
        e.stopPropagation();
        frameworkId && deattachControl(frameworkId, controlId);
    }

    const {
        openEditFrameworkControlDialog, handleOpenAddFrameworkControlDialog, handleOpenEditFrameworkDialog, handleOpenDeleteFramework,
    } = useDialogs();

    return{
        frameworkId,
        _frameworks,
        framework,
        _controls,

        filter,
        isLoading: _isLoading,

        isOwn,

        // handleKeyDown, handleChange, searchValue, setFilter,

        goToFrameworkControl,
        goToFrameworks,

        handleOpenEditFrameworkDialog,
        handleOpenDeleteFramework,
        openEditFrameworkControlDialog,
        handleOpenAddFrameworkControlDialog,

        handleDeattachControl,

        isLoadingControls,
        isEmpty: !_controls.filter(e => e.name.trim().toLowerCase().includes(currentSearchForFetch.trim().toLowerCase()) || e.category.trim().toLowerCase().includes(currentSearchForFetch.trim().toLowerCase())).length && !isLoadingControls && !_isLoading,
        handleThreeDotsOpen, handleThreeDotsClose, anchorThreeDotsEl,
        currentSearchForFetch
    }
}