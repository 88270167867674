import {Button, styled} from "@mui/material";


export const StylesButton = styled(Button)`
  text-transform: capitalize !important;
  color: #707070 !important;
  font-weight: 500;
`;
StylesButton.defaultProps = {
    variant: "text",
}

export const NormalText = styled('span')`
  font-size: 16px;
`;

export const Heading1 = styled('h1')`
  font-size: 30px;
  font-weight: 600;
`;
export const Heading2 = styled('h2')`
  font-size: 24px;
  font-weight: 600;
`;
export const Heading3 = styled('h3')`
  font-size: 20px;
  font-weight: 600;
`;
export const Heading4 = styled('h4')`
  font-size: 18px;
  font-weight: 600;
`;
export const Heading5 = styled('h5')`
  font-size: 16px;
  font-weight: 600;
`;

