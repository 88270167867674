import {useDispatch, useSelector} from "react-redux";
import {dialogs, hrSettings, loadings, openEditEmployeesTreeNode, selectedEmployee} from "../../../store/slice";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {EmployeeType} from "../../../types";
import {GetHrEmployeeById, UpdateHrEmployee,} from "../../../store/actions";
import {useEffect} from "react";
import {mockEmployee, PATH_LOCAL_EMPLOYEES_ID} from "../../../constants";
import {useHistory} from "react-router-dom";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {validateEmail} from "../../../../../../newShared/utils/text";
// import {initEmployee} from "../../../helpers";


export const useUpdateEmployeeTreeNode = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const {setMessage} = useMessageDialog();

    const selected: EmployeeType | null = useSelector(selectedEmployee);
    const settings = useSelector(hrSettings);

    const {getEmployee, getHrSettings, updateEmployee} = useSelector(loadings);
    const {editEmployeesTreeNode: {isOpen, id}} = useSelector(dialogs);

    const {form, setForm, handleChange} = useForm<EmployeeType>(mockEmployee);

    const handleClose = () => dispatch(openEditEmployeesTreeNode({id: null}));




    const isOkGeneralInfoUpdate = (): boolean => {
        return form.firstName.trim().length > 0 &&
            form.lastName.trim().length > 0 &&
            form.gender !== null &&
            form.maritalStatus !== null &&
            validateEmail(form.workEmail) &&
            // !form.personalData.education.find(e => (!e.schoolName.trim().length || !e.degree?.trim().length)) && //schoolName and degree are required in every education
            // form.contactInfo.address.country !== null && form.contactInfo.address.country?.trim().length > 0 &&
            // form.contactInfo.address.city?.trim().length > 0 &&
            // form.contactInfo.contacts.some(e => e.type === PHONE_NUMBER_CONTACT_TYPE) && //has to have phone number
            // !form.contactInfo.contacts.some(e => (!e.type || !e.value.trim().length)) && //every contact has non-empty value
            // !form.contactInfo.emergencyContacts.some(e => (!e.relationType || !e.address.trim().length || !e.email.trim().length || !e.phone.trim().length) || !e.fullName.trim().length) &&
            JSON.stringify(form) !== JSON.stringify(selected)
    }

    const handleSave = () => {
        // console.log(`UPDATE WITH FORM: ${JSON.stringify(personalForm.form)}`);
        if(form){
            dispatch(UpdateHrEmployee({
                data: {
                    // organizationId: '',
                    // workspaceId: '',
                    employee: {
                        ...form
                    },
                    onSuccess,
                },
            }))
        }
    }

    const onSuccess = () => {
        setMessage({title: 'Completed successfully', message: `The employee ${form && form.firstName} ${form && form.lastName} has been updated`});
        // dispatch(getEmployeesTreeAction({data: {}}))
    }

    const handleGoBack = () => {
        handleClose()
    }

    const controller = new AbortController();

    useEffect(() => {
        if(isOpen && !selected){
            id && dispatch(GetHrEmployeeById({data: {id, onReject: handleGoBack}, signal: controller.signal}));
        }

        if (!isOpen) {
            controller.abort();
            setForm(mockEmployee);
        }
        //eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        selected && setForm(selected);

        //eslint-disable-next-line
    }, [selected]);

    const goToEditPersonalPage = (id: string) => {
        history.push(`${PATH_LOCAL_EMPLOYEES_ID.replace(':id', id)}?edit=true`);
        dispatch(openEditEmployeesTreeNode({id: null}));
    }



    return {
        selected,
        settings,
        isOk: isOkGeneralInfoUpdate(),
        isOpen,
        form,

        handleChange,
        handleClose,
        handleSave,
        isLoadingExact: getEmployee,
        isLoadingHrSettings: getHrSettings,
        isLoadingEditPersonalInfo: updateEmployee,
        goToEditPersonalPage,

        generalInfo: {
            isLoadingUpdate: updateEmployee,
            isOk: isOkGeneralInfoUpdate(),

            handleSetBirthday: (birthDay: string | null) => {setForm({...form, birthDay})},
            handleSetGender: (gender: string | null) => {gender && setForm({...form, gender})},
            handleSetMartialStatus: (maritalStatus: string | null) => {maritalStatus && setForm({...form, maritalStatus})},
            handleSetEthnicity: (ethnicity: string | null) => {ethnicity && setForm({...form, ethnicity})},
            // handleDeleteEducation: (id: number) => {
            //         setForm({...form, personalData: {
            //                 ...form.personalData,
            //                 education: form.personalData.education.filter((e, _id) => _id !== id)
            //             }})
            //     },
            // handleAddEducation: () => {
            //     setForm({...form, personalData: {
            //             ...form.personalData,
            //             education: [emptyEducation, ...form.personalData.education]
            //         }})
            // },
            // handleChangeEducation: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
            //     setForm((prevValues) => ({
            //         ...prevValues,
            //         personalData: {
            //             ...prevValues.personalData,
            //             education: [
            //                 ...prevValues
            //                     .personalData
            //                     .education
            //                     .map((e, _id) => _id === id ? {...e, [event.target.name]: event.target.value} : e)
            //             ]
            //         }
            //     }));
            // },
            // handleChangeEducationDegree: (degree: string | null, id: number) => {
            //     setForm((prevValues) => ({
            //         ...prevValues,
            //         personalData: {
            //             ...prevValues.personalData,
            //             education: [
            //                 ...prevValues
            //                     .personalData
            //                     .education
            //                     .map((e, _id) => _id === id ? {...e, degree} : e)
            //             ]
            //         }
            //     }));
            // },
            // handleSetEducationEndDate: (newValue: string | null, id: number) => {
            //     setForm((prevValues) => ({
            //         ...prevValues,
            //         personalData: {
            //             ...prevValues.personalData,
            //             education: [
            //                 ...prevValues
            //                     .personalData
            //                     .education
            //                     .map((e, _id) => _id === id ? {...e, endDate: newValue} : e)
            //             ]
            //         }
            //     }));
            // },
            // handleSetEducationStartDate: (newValue: string | null, id: number) => {
            //     setForm((prevValues) => ({
            //         ...prevValues,
            //         personalData: {
            //             ...prevValues.personalData,
            //             education: [
            //                 ...prevValues
            //                     .personalData
            //                     .education
            //                     .map((e, _id) => _id === id ? {...e, startDate: newValue} : e)
            //             ]
            //         }
            //     }));
            // },
            // handleSetAddressCountry: (country: string | null) => {
            //     setForm((prevValues) => ({
            //         ...prevValues,
            //         contactInfo: {
            //             ...prevValues.contactInfo,
            //             address: {
            //                 ...prevValues.contactInfo.address,
            //                 country
            //             }
            //         }
            //     }));
            // },
            // handleChangeAddress: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            //     setForm({...form, contactInfo: {
            //             ...form.contactInfo,
            //             address: {...form.contactInfo.address, [event.target.name]: event.target.value}
            //         }})
            // },
            // handleAddContact: () => {
            //     setForm({...form, contactInfo: {
            //             ...form.contactInfo,
            //             contacts: [emptyContact, ...form.contactInfo.contacts]
            //         }})
            // },
            // handleDeleteContact: (id: number) => {
            //     setForm({...form, contactInfo: {
            //             ...form.contactInfo,
            //             contacts: form.contactInfo.contacts.filter((e, _id) => _id !== id)
            //         }})
            // },
            // handleSetContactType: (id: number, type: string | null) => {
            //     setForm({...form, contactInfo: {
            //             ...form.contactInfo,
            //             contacts: form.contactInfo.contacts.map((e, _id) => _id === id ? {...e, type} : e)
            //         }})
            // },
            // handleChangeContactValue: (id: number, value: string) => {
            //     setForm({...form, contactInfo: {
            //             ...form.contactInfo,
            //             contacts: form.contactInfo.contacts.map((e, _id) => _id === id ? {...e, value} : e)
            //         }})
            // },
            // handleAddEmergencyContact: () => {
            //     setForm({...form, contactInfo: {
            //             ...form.contactInfo,
            //             emergencyContacts: [emptyEmergencyContact, ...form.contactInfo.emergencyContacts]
            //         }})
            // },
            // handleDeleteEmergencyContact: (id: number) => {
            //     setForm({...form, contactInfo: {
            //             ...form.contactInfo,
            //             emergencyContacts: form.contactInfo.emergencyContacts.filter((e, _id) => _id !== id)
            //         }})
            // },
            // handleChangeEmergencyContact: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
            //     setForm((prevValues) => ({
            //         ...prevValues,
            //         contactInfo: {
            //             ...prevValues.contactInfo,
            //             emergencyContact: [
            //                 ...prevValues
            //                     .contactInfo
            //                     .emergencyContacts
            //                     .map((e, _id) => _id === id ? {...e, [event.target.name]: event.target.value} : e)
            //             ]
            //         }
            //     }));
            // },
            // handleSetEmergencyContactType: (id: number, relationType: string | null) => {
            //     setForm((prevValues) => ({
            //         ...prevValues,
            //         contactInfo: {
            //             ...prevValues.contactInfo,
            //             emergencyContact: [
            //                 ...prevValues
            //                     .contactInfo
            //                     .emergencyContacts
            //                     .map((e, _id) => _id === id ? {...e, relationType} : e)
            //             ]
            //         }
            //     }));
            // },
            // handleAddCustomField: () => {
            //     dispatch(openAddSettingDialog({type: 'customFields'}));
            // },
            // openAddContactType: (index: number) => {
            //     dispatch(openAddSettingDialog({type: 'contactType', id: index}));
            // },
            // openAddRelationshipType: (index: number) => {
            //     dispatch(openAddSettingDialog({type: 'relationshipType', id: index}));
            // },
            // openAddDegree: (index: number) => {
            //     dispatch(openAddSettingDialog({type: 'degree', id: index}));
            // },
            // handleChangeVeteranStatus: (key: keyof TEmployeeVeteranStatus, checked: boolean) => {
            //     setForm({...form, personalData: {...form.personalData, veteranStatus: {...form.personalData.veteranStatus, [key]: checked}}})
            // },
            // handleSettingAdd: (type: keyof THrSettings, value: string, id?: number) => {
            //     //job info has its own handler inside createJobRecord/updateJobRecord
            //     //so no need to handle it here
            //     // if(tab === "jobInfo") return;
            //     switch (type){
            //         case "jobTitle": {
            //             const jobRecord = form.jobInfos.find(e => e.current);
            //             const currJob = settings.jobTitle.find(e => e.name === value);
            //             if(jobRecord && currJob){
            //                 setForm({...form, jobInfos: [{...jobRecord, jobTitle: currJob}]})
            //             }
            //             return;
            //         }
            //         case "employmentType": {
            //             const jobRecord = form.jobInfos.find(e => e.current);
            //             if(jobRecord){
            //                 setForm({...form, jobInfos: [{...jobRecord, employmentType: value}]})
            //             }
            //             return;
            //         }
            //         case "department": {
            //             const jobRecord = form.jobInfos.find(e => e.current);
            //             if(jobRecord){
            //                 setForm({...form, jobInfos: [{...jobRecord, department: value}]})
            //             }
            //             return;
            //         }
            //         case "location": {
            //             const jobRecord = form.jobInfos.find(e => e.current);
            //             if(jobRecord){
            //                 setForm({...form, jobInfos: [{...jobRecord, location: value}]})
            //             }
            //             return;
            //         }
            //         case "division": {
            //             const jobRecord = form.jobInfos.find(e => e.current);
            //             if(jobRecord){
            //                 setForm({...form, jobInfos: [{...jobRecord, division: value}]})
            //             }
            //             return;
            //         }
            //         case "contactType": {
            //             setForm({
            //                 ...form,
            //                 contactInfo: {
            //                     ...form.contactInfo,
            //                     contacts: form.contactInfo.contacts.map((e, _id) => _id === id ? {...e, type: value} : e)
            //                 }
            //             });
            //             return;
            //         }
            //         case "degree": {
            //             setForm({
            //                 ...form,
            //                 personalData: {
            //                     ...form.personalData,
            //                     education: form.personalData.education.map((e, _id) => _id === id ? {...e, degree: value} : e),
            //                 }
            //             });
            //             return;
            //         }
            //         case "relationshipType": {
            //             setForm({
            //                 ...form,
            //                 contactInfo: {
            //                     ...form.contactInfo,
            //                     emergencyContacts: form.contactInfo.emergencyContacts.map((e, _id) => _id === id ? {...e, relationType: value} : e)
            //                 }
            //             });
            //             return;
            //         }
            //     }
            // }
        },
    }
}