import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {hideTriggerDate, selectedAutomation, setTriggerDate, triggerDate} from "../../../store/slice";
import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import React, {useEffect} from "react";
import {TextFieldProps as MuiTextFieldPropsType} from "@mui/material/TextField/TextField";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DEFAULT_DATE_WITH_TIME_FORMAT} from "../../../../../../newShared/utils/dateTools";

// const values = ['Schedule', 'One Time'];

export const TriggerDateDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'automations'});
    const dispatch = useDispatch();
    const auto = useSelector(selectedAutomation);
    const {isOpen} = useSelector(triggerDate);
    const [clearedDate, setClearedDate] = React.useState<Date | null>(new Date(auto?.trigger.start || Date.now()) || null);
    const [opened, setOpened] = React.useState<boolean>(false);

    useEffect(() => {
        if(opened){
            //delete this
        }
        if(isOpen && auto){
            setClearedDate(new Date(auto.trigger.start));
        }
        //eslint-disable-next-line
    }, [isOpen, auto]);

    const handleClose = () => {
        setOpened(false);
        dispatch(hideTriggerDate());
    };

    const handleSelect = () => {
        if(clearedDate){
            // console.log(clearedDate.toISOString());
            dispatch(setTriggerDate(clearedDate?.toISOString()));
            setOpened(false);
        }
    }

    return (auto &&
        <Dialog  onClose={handleClose} open={isOpen}>
            <DialogTitle>{t('Select Trigger Start')}</DialogTitle>

            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                        // clearable
                        inputFormat={DEFAULT_DATE_WITH_TIME_FORMAT}
                        onOpen={() => setOpened(true)}
                        hideTabs
                        // showTodayButton
                        views={['year', 'month', 'day', 'hours', 'minutes']}
                        // minutesStep={30}
                        value={clearedDate}
                        onChange={(newValue: any) => setClearedDate(newValue)}
                        renderInput={(props: MuiTextFieldPropsType) => <TextField required {...props} label={t('Trigger start date')} sx={{mt: '10px'}}/>}
                        onAccept={handleSelect}
                        onClose={handleSelect}
                    />
                </LocalizationProvider>
            </DialogContent>

            <DialogActions>
                <Button color={'secondary'} onClick={handleClose}>{t('Close')}</Button>
            </DialogActions>
        </Dialog>
    );
}
