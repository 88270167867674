import axiosSync from "../../../../newShared/utils/axios/axios";
import {createTargetRequestType, smallTarget, TScanHistory, updateTargetRequestType} from "../types";
import {AxiosResponse} from "axios";
import {
    DownloadEvidenceFileQuery,
    DownloadEvidenceFileQueryVariables,
    QueryGetSmallTargetByIdArgs,
    QueryGetSmallTargetsArgs,
    QueryGetTargetScanHistoryArgs
} from "../../../../newShared/GQLTypes";
import {TPageInfo} from "../../../../newShared/types";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {print} from "graphql";
import {gql} from "@apollo/react-hooks";
import {PATH_SERVER} from "../../../../newShared/constants";


export const getShortTargets = async (data: QueryGetSmallTargetsArgs, signal?: AbortSignal): Promise<{targets: smallTarget[], pageInfo: TPageInfo}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getSmallTargetVul($workspaceId:String!, $pageInfo:PaginationInput!){
                        getSmallTargets(workspaceId:$workspaceId, pageInfo:$pageInfo){
                            targets{
                                id
                                name
                                target
                                status
                                statusChangedDate
                                lastRuns{
                                    owasp
                                    openVas
                                    TLS_SSL
                                    tcpPorts
                                }
                                configTarget{
                                    availableScanTypes
                                }
                            }
                            pageInfo{
                                page
                                count
                                total
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getSmallTargets;
    })
)

export const getShortTargetById = async (data: QueryGetSmallTargetByIdArgs, signal?: AbortSignal): Promise<smallTarget> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getSmallTargetById($workspaceId:String!, $id:String!){
                        getSmallTargetById(workspaceId:$workspaceId, id:$id){
                            id
                            name
                            target
                            status
                            statusChangedDate
                            lastRuns{
                                owasp
                                openVas
                                TLS_SSL
                                tcpPorts
                            }
                            configTarget{
                                availableScanTypes
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getSmallTargetById;
    })
)


export const createTarget = async (workspaceId: string, organizationId: string, target: createTargetRequestType): Promise<smallTarget> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation addTargetVul($workspaceId:String!, $target:CreateTargetInput!, $organizationId: String!){
                        createTargetVulnerability(workspaceId:$workspaceId, target:$target, organizationId:$organizationId){
                            id
                            name
                            target
                            status
                            statusChangedDate
                            lastRuns{
                                owasp
                                openVas
                                TLS_SSL
                                tcpPorts
                            }
                            configTarget{
                                availableScanTypes
                            }
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    organizationId,
                    target,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createTargetVulnerability;
    })
)

export const updateTarget = async (workspaceId: string, target: updateTargetRequestType): Promise<smallTarget> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation updateTarget($workspaceId:String!, $target:UpdateTargetInput!){
                        updateTarget(workspaceId:$workspaceId, target:$target){
                            id
                            name
                            target
                            status
                            statusChangedDate
                            lastRuns{
                                owasp
                                openVas
                                TLS_SSL
                                tcpPorts
                            }
                            configTarget{
                                availableScanTypes
                            }
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    target,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateTarget;
    })
)


export const runTarget = async (workspaceId: string, targetId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation runTargetVul($workspaceId:String!, $targetId:String!){
                        runTarget(workspaceId:$workspaceId, targetId:$targetId){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    targetId,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.runTarget.message;
    })
)

export const deleteTarget = async (workspaceId: string, targetId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation delTargetVul($workspaceId:String!, $targetId:String!){
                        deleteTarget(workspaceId:$workspaceId, targetId:$targetId){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    targetId,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteTarget.message;
    })
)

export const getTargetScanHistory = async (data: QueryGetTargetScanHistoryArgs, signal?: AbortSignal): Promise<{histories: TScanHistory[], pageInfo: TPageInfo}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getTargetScanHistory($pageInfo:PaginationInput!, $workspaceId:String!, $scanType:String!, $targetId:String!){
                        getTargetScanHistory(pageInfo:$pageInfo, workspaceId:$workspaceId, targetId:$targetId, scanType:$scanType){
                            histories{
                                originTargetId
                                startScanDate
                                endScanDate
                                workspaceId
                                organizationId
                                targetId
                                id
                                target
                                activeScans{
                                    status
                                    scanType
                                    scanId
                                    startScanDate
                                    finishScanDate
                                    scanFailedReason
                                    scanResult{
                                        id
                                        type
                                        state
                                        progress
                                        risks{
                                            new_open{
                                                risk_id
                                            }
                                            still_open{
                                                risk_id
                                            }
                                            closed{
                                                risk_id
                                            }
                                        }
                                        results{
                                            resultId
                                            contentType
                                            result_id
                                            content_type
                                            originReportFileId
                                            companyReportFileId
                                        }
                                        requested_targets{
                                            target
                                            target_id
                                            label
                                        }
                                        resolved_targets{
                                            target
                                            target_id
                                            resolved_target
                                            label
                                        }
                                        skipped_targets{
                                            target
                                            target_id
                                            skipped_reason
                                            label
                                        }
                                    }
                                }
                                risks{
                                    id
                                    tags
                                    target
                                    target_id
                                    status
                                    is_accepted
                                    accepted_by
                                    first_detected_at
                                    last_detected_at
                                    risk_definition{
                                        scan_type
                                        title
                                        threat_level
                                        additional_info{
                                            title
                                            details
                                        }
                                    }
                                }
                            }
                            pageInfo{
                                page
                                count
                                total
                                sort
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getTargetScanHistory;
    })
)

export const releaseTargetScans = async (workspaceId: string, targetId: string, evidenceId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation releaseTargetScans($workspaceId:String!, $evidenceId:String!,$targetId:String!){
                        releaseTargetScans(workspaceId:$workspaceId, evidenceId:$evidenceId, targetId:$targetId){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    targetId,
                    evidenceId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.releaseTargetScans.message;
    })
)

export const downloadEvidenceFileAPI = async (data: DownloadEvidenceFileQueryVariables): Promise<DownloadEvidenceFileQuery["downloadEvidenceFile"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query downloadEvidenceFile($fileId: String!, $workspaceId: String!, $fileName: String!) {
                        downloadEvidenceFile (fileId: $fileId, fileName: $fileName, workspaceId: $workspaceId) {
                            filename
                            filetype
                            file
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.downloadEvidenceFile as {file: string, filename: string, filetype: string};
    })
)