import {FC, PropsWithChildren, ReactNode, useRef} from "react";
import {Typography} from "@mui/material";
import {
    FlexColumn,
    FlexRow
} from "../../../../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import colors from "../../../../../../../../newShared/theme/colors";
import {useMedia} from "../../../../../../../../newShared/hooks/useMedia";

type ExamViewBlockRowProps = {
    title: string,
    description: string,

    isLastElement?: true;
    titleChild?: ReactNode;

    gapChild?: string;

    stickyLeft?: boolean;
}
export const ExamViewBlockRow: FC<PropsWithChildren<ExamViewBlockRowProps>> = ({stickyLeft, title, description, isLastElement, titleChild, gapChild, children}) => {

    const ref = useRef<HTMLDivElement>();
    const {isLessThenCustomQuery} = useMedia(900);

    return (
        <FlexRow ref={ref} sx={{
            margin: '0 auto 25px',
            // overflow: 'hidden',
            flexShrink: 0,

            width: 'min(1550px, 90%)',

            borderBottom: isLastElement ? undefined : `1px solid ${colors.stroke.grey}`,
            paddingBottom: '25px',

            ...(isLessThenCustomQuery ? {
                flexDirection: 'column',
                gap: '20px',
            } : {
                gap: '40px',
            })
        }}>
            <FlexColumn sx={{width: isLessThenCustomQuery ? '100%' : `calc(25% - 20px)`, minWidth: '275px'}}>
                <FlexColumn sx={{gap: '12px', paddingTop: '10px', position: stickyLeft ? 'sticky' : undefined, top: 0}}>
                    <Typography variant={'*h3'} color={colors.text.dark}>{title}</Typography>
                    <Typography variant={'*bodyText2'} color={colors.text.dark}>{description}</Typography>
                    {titleChild &&
                        <FlexColumn sx={{flexBasis: '100%'}}>
                            {titleChild}
                        </FlexColumn>
                    }
                </FlexColumn>
            </FlexColumn>

            <FlexColumn sx={{width: isLessThenCustomQuery ? '100%' : `calc(75% - 20px)`, gap: gapChild}}>
                {children}
            </FlexColumn>
        </FlexRow>
    )
}