import {gql} from "@apollo/react-hooks";

export const evidenceAutoFile = gql`
    fragment evidenceAutoFile on EvidenceAutoFileDto {
        id
        name
        lastCollected
        data
        type
    }
`