import {MainVicaStepStatus} from "../../../../../../../../newShared/GQLTypes";
import {Flex} from "../../../../../../../../newShared/components/Layouts";
import colors from "../../../../../../../../newShared/theme/colors";
import {Typography} from "@mui/material";

export const VicaStepStatusNode = ({status}:{status: MainVicaStepStatus}) => {
    switch (status){
        case MainVicaStepStatus.Completed: return <Flex
            p={'6px 14px'}
            br={'12px'}
            ai={'center'}
            jc={'center'}
            maxw={'150px'}
            background={colors.decorative.background_3}
        >
            <Typography variant={'*bodyText2'} color={colors.text.success} noWrap>{('Completed')}</Typography>
        </Flex>;

        case MainVicaStepStatus.InProgress: return <Flex
            p={'6px 14px'}
            br={'12px'}
            ai={'center'}
            jc={'center'}
            maxw={'150px'}
            background={colors.status.violet_1}
        >
            <Typography variant={'*bodyText2'} color={colors.text.blue} noWrap>{('In progress')}</Typography>
        </Flex>;

        case MainVicaStepStatus.NotStarted: return <Flex
            p={'6px 14px'}
            br={'12px'}
            ai={'center'}
            jc={'center'}
            maxw={'150px'}
            background={colors.status.grey_2}
        >
            <Typography variant={'*bodyText2'} color={colors.text.grey_dark} noWrap>{('Not started')}</Typography>
        </Flex>;

        default: return <Flex
            p={'6px 14px'}
            br={'12px'}
            ai={'center'}
            jc={'center'}
            maxw={'150px'}
            background={colors.status.violet_1}
        >
            <Typography variant={'*bodyText2'} color={colors.text.grey_dark} noWrap>{status}</Typography>
        </Flex>;
    }
}