import {editRoleDialogPropsTypes} from "../../../types";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import React from "react";
import {DialogContentWrapper} from "../../../../newIntegrations/components/styled";
import {TextFieldCustom} from "../../styled";
import {useEditRoleDialog} from "../../../hooks/dialogHooks/useEditRoleDialog";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const EditRoleDialog = (props: editRoleDialogPropsTypes) => {
    const {
        isOpen,
        handleClose,
        handleSubmit,
        handleChange,
        form,
        isOk,
    } = useEditRoleDialog(props);
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.CreateRow'});

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t(`Edit {{roleName}} role`, {roleName: props.role?.roleName})}</DialogTitle>
            <DialogContent>
                <DialogContentWrapper>
                    <TextFieldCustom
                        required
                        value={form.roleName}
                        name={'roleName'}
                        onChange={handleChange}
                        placeholder={t('Name')}
                        label={t('Role name')}
                        size={'small'}
                        sx={{margin: '8px 0 0 0'}}
                        disabled
                    />

                    <TextField
                        label={t('Role permissions list separated with comma')}
                        value={form.rolePermissions}
                        name={'rolePermissions'}
                        onChange={handleChange}
                        multiline
                        minRows={5}
                        sx={{margin: '8px 0 0 0'}}
                    />
                </DialogContentWrapper>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={'outlined'}>{t('Cancel')}</Button>
                <Button onClick={handleSubmit} disabled={!isOk} variant={'contained'} sx={{margin: !revDir ? '0 0 0 16px' : '0 16px 0 0'}}>{t('Save')}</Button>
            </DialogActions>
        </Dialog>
    )
}