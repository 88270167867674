import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET,
    PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET_NEW,
    PATH_LOCAL_ACCESS_MANAGEMENT
} from "../../constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSegregationOfDuties} from "../useSegregationOfDuties";
import {createDutyRuleAction} from "../../store/actions";
import {useForm} from "../../../../../newShared/hooks/useForm";
import {TDutyRule, TShortDuty} from "../../types";


export const useRuleNew = () => {

    const dispatch = useDispatch();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    const {
        shortDuties,
        goToRuleset,
        goToRuleExact,
        getDataForCreateRule,
        loadings: {create: isLoadingCreate, dataForDialog: isLoadingData},
    } = useSegregationOfDuties();


    const initRule: TDutyRule = {
        id: '',
        name: '',
        severityLevel: '',
        securityRisk: '',
        securityMitigation: '',
        active: false,
        firstDuty: {id: '', name: ''},
        secondDuty: {id: '', name: ''},
        conflictExceptions: null,
    }

    //form
    const {form, setForm, handleChange} = useForm<TDutyRule>(initRule);

    const onSuccess = (id: string) => {
        goToRuleExact(id);
    }

    const handleSave = () => {
        isOk() && dispatch(createDutyRuleAction({data: {rule: form, workspaceId: '', organizationId: ''}, onSuccess}));
    }

    const handleSetSeverity = (severity: string) => {
        setForm(prev => prev && ({...prev, severityLevel: severity}));
    }

    const toggleActive = (active: boolean) => {
        setForm(prev => prev && ({...prev, active}))
    }

    const handleSetFirstDuty = (duty: TShortDuty) => {
        setForm(prev => prev && {...prev, firstDuty: duty});
    }

    const handleSetSecondDuty = (duty: TShortDuty) => {
        setForm(prev => prev && {...prev, secondDuty: duty});
    }

    const isOk = () => {
        return form && form.name.trim().length > 0 && form.firstDuty.id.length > 0 && form.secondDuty.id.trim().length > 0 && form.severityLevel.length > 0
    }


    useEffect(() => {
        getDataForCreateRule();
        setForm(initRule);


        //eslint-disable-next-line
    }, []);


    useSetBreadcrumbs([
        {
            title: tMenu('Access management'),
            path: PATH_LOCAL_ACCESS_MANAGEMENT
        },
        {
            title: tMenu('SOD ruleset'),
            path: PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET
        },
        {
            title: tMenu('Create rule'),
            path: PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET_NEW
        }
    ]);


    return {
        isOk: isOk(),
        form, handleChange,
        shortDuties,
        handleSave,
        goToRuleset,
        isLoadingCreate,
        isLoadingData,

        toggleActive,
        handleSetSeverity,
        handleSetFirstDuty,
        handleSetSecondDuty,
    }
}