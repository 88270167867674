import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {CommonRadio} from "../../../../../../../newShared/components/Basic/CommonRadio";
import {SearchField} from "../../../../../../../newShared/components/Basic/CommonInputs";
import {getEvidenceStatus} from "../../../../helpers";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import colors from "../../../../../../../newShared/theme/colors";
import {LoadingButton} from "../../../../../../../newShared/components/Basic/CommonButtons";
import {TableRowSkeleton} from "../../../../../../../newShared/components/tableRowSkeleton";
import {useAddEvidence} from "../../../../hooks/dialogs/useAddEvidence";
import {useBuilderFrameworks} from "../../../../hooks/new/useBuilderFrameworks";
import {LABEL} from "../../../../../../../newShared/constants";


export const AddEvidenceDialog = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'builderPage.dialogs'});

    const {
        isOpen,
        form,
        isExisting,
        // setIsExisting,
        handleClose,
        validateForm,
        handleSave,
        category,
        categoryVariants,
        getTitle,
        handleChangeCategory,
        search,
        handleChangeSearch,
        handleKeyDown,
        handleClearSearch,
        // _evidences,
        _fetchedEvidences,
        organizationId,
        handleSetCreateOwnEvidence,
        handleChange,
        handleSetUseExisting,
        isLoading,
        handleAddEvidence,
        handleDeattachEvidence,
        handleChangeCategories,
        // isShowingNoEvidences,
        pagination: {
            bottomLoader,
            onScroll,
            scrollRef
        }

    } = useAddEvidence();

    const {evidences: _evidences} = useBuilderFrameworks()

    return(
        <Dialog
            open={isOpen}
            onClose={handleClose} sx={{overflow: 'hidden'}}
        >
            <DialogTitle>{t('Add evidence')}</DialogTitle>
            <DialogContent sx={{overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
               <Typography variant={'body2'} >{t('What type of evidence do you want to add?')}</Typography>
               <Box display={'flex'} width={'100%'} alignItems={'center'} sx={{ml: '-8px'}}>
                   <CommonRadio size={"small"} checked={!isExisting}  onClick={handleSetCreateOwnEvidence}/>
                   <Typography variant={'body2'} >{t('Create your own')}</Typography>
                   <CommonRadio size={"small"} checked={isExisting} onClick={handleSetUseExisting}/>
                   <Typography variant={'body2'} >{t('Use existing')}</Typography>
               </Box>

                { isExisting ?
                    <>
                        <TextField select fullWidth
                                   size={'small'}
                                   value={category}
                                   label={t('Control category')}
                                   sx={{mt: '8px', '& label': {top: '0 !important'}}}
                                   onChange={(e) => handleChangeCategory(e.target.value)}
                        >
                            {categoryVariants.map(e => <MenuItem key={e} value={e}>{getTitle(e)}</MenuItem>)}
                        </TextField>

                        <SearchField
                            sx={{mt: '12px', '& label': {top: '0 !important'}}}
                            fullWidth
                            size={'small'}
                            value={search}
                            onClear={handleClearSearch}
                            label={t('Search')}
                            onChange={handleChangeSearch}
                            onKeyDown={handleKeyDown}
                        />

                        <Typography variant={'body2'} sx={{mt: '12px'}} >{t('You can add one or more evidences.')}</Typography>

                        <TableContainer onScroll={onScroll} ref={scrollRef} className={'withoutBorder'}>
                            <Table >
                                <TableBody>
                                    {_fetchedEvidences.map((e) => e && (
                                        <TableRow key={e.id} >
                                            <TableCell align={revDir ? 'right' : 'left'} sx={{maxWidth: '400px'}} >
                                                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                                                    <Typography noWrap variant={'body1'}>{e.name}</Typography>
                                                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} sx={{mt: '8px'}}>
                                                        <Typography fontWeight={'400'} fontSize={'12px'}>{t(getEvidenceStatus(e, organizationId), {companyName: LABEL})} </Typography>
                                                        <Typography fontWeight={'400'} fontSize={'12px'}>{`${t('Category')} ${e.categories[0]}`} </Typography>
                                                    </Box>

                                                </Box>
                                            </TableCell>
                                            <TableCell width={'140px'}  align={'center'}>
                                                {_evidences.some(c => c.id === e.id) ? //isAdded
                                                    <Button size={'small'} disabled={isLoading} variant={'text'} sx={{color: colors.text.grey}} onClick={(event) => handleDeattachEvidence(event,e.id || '')} endIcon={<DeleteIcon sx={{color: colors.text.grey}} />}>{t('Added')}</Button>
                                                    :
                                                    <Button size={'small'} disabled={isLoading} variant={'text'} onClick={() => handleAddEvidence(e.id || '')} startIcon={<AddIcon />}>{t('Add')}</Button>
                                                }

                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {(bottomLoader || isLoading) && <TableRowSkeleton columns={2} />}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                    :
                    <>
                        <TextField id="filled-basic" label={t("Name")}
                                   size={'small'}
                                   variant="outlined" sx={{mt: '12px', '& label': {top: '0 !important'}}}
                                   name={'name'} value={form.name} onChange={handleChange}/>

                        <TextField id="filled-basic" label={t("Category")}
                                   size={'small'}
                                   variant="outlined" sx={{mt: '12px', '& label': {top: '0 !important'}}}
                                   name={'categories'} value={form.categories[0] || ''}
                                   onChange={handleChangeCategories}/>

                        <TextField id="filled-basic" label={t("Description")}
                                   variant="outlined" multiline minRows={5} sx={{mt: '12px'}}
                                   name={'description'} value={form.description} onChange={handleChange}/>
                    </>
                }
            </DialogContent>

            <DialogActions>
                <Button variant={'text'} sx={{'&.MuiButton-text': {textTransform: 'none'}}} onClick={handleClose}>{isExisting ? t('Close') : t('Cancel')}</Button>
                {!isExisting &&
                    <LoadingButton size={'small'} disabled={!validateForm() || isLoading || isLoading}
                                   loadingPosition={isLoading ? 'start' : undefined}
                                   onClick={handleSave} loading={isLoading} >
                        {t('Save')}
                    </LoadingButton>
                }

            </DialogActions>
        </Dialog>
    )
}