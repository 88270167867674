import {cookieStoreType, multiStoreDataType} from "../../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../../newShared/redux/rootReducer";
import {Logging} from "../../../../../newShared/utils/logs/log";

export const initialState: multiStoreDataType = {
    store: {
        selectedOrganization: null,
        selectedWorkspace: null,

        currentUser: null,

        isAuthComplete: false, // - when user reloads page - and this value is false = throwing him out ( auth not complete) even if auth - yes complete (because steel did not put value from cookies inside of store)
        is2FaAuthComplete: false,
        isGoogleAuthConfigComplete: false,
        isEmailVerified: false,
    }
}

export const SharedWorkspacesAndOrganizationsSlice = createSlice({
    name: 'SharedWorkspacesAndOrganizations',
    initialState,
    reducers: {
        setSharedWorkspacesAndOrganizationsData: (state, {payload}: {payload: cookieStoreType}) => {
            // console.log('COOKIE DATA SET');
            state.store = payload;
        },
        setAuthNotComplete: (state) => {state.store.is2FaAuthComplete = true; state.store.isAuthComplete = false},
        setNullToSelectedWorkspaceAndOrg: (state) => {
            // console.log('setNullToSelectedWorkspaceAndOrg');
            state.store.selectedOrganization = null;
            state.store.selectedWorkspace = null;
            // state.roles = {
            //     isOrgOwner: false,
            //     isOrgAdmin: false,
            //     isOrgMember: false,
            // };
        },
        setFirstNameAndLastName: (state, {payload}: {payload: {firstName: string, lastName: string}}) => {
            if(state.store.currentUser){
                state.store.currentUser.firstName = payload.firstName;
                state.store.currentUser.lastName = payload.lastName;
            }
        },
        eraseData: () => {
            Logging.log(`---SharedWorkspacesAndOrganizationsSlice (cookies redux storage) cleanUp`);
            return initialState;
        },
    }
});

export const SharedWorkspacesAndOrganizationsReducer = SharedWorkspacesAndOrganizationsSlice.reducer;

export const {
    setSharedWorkspacesAndOrganizationsData,
    eraseData,
    setAuthNotComplete,
    setNullToSelectedWorkspaceAndOrg,
    setFirstNameAndLastName
} = SharedWorkspacesAndOrganizationsSlice.actions;

const selectSelf = (state: AppState): multiStoreDataType => state.SharedWorkspacesAndOrganizations as multiStoreDataType;

export const selectedWorkspace = createSelector(selectSelf, state => state.store.selectedWorkspace);
export const selectedOrganization = createSelector(selectSelf, state => state.store.selectedOrganization);
// export const roles = createSelector(selectSelf, state => state.roles);
export const user = createSelector(selectSelf, state => state.store.currentUser);

export const isAuthComplete = createSelector(selectSelf, state => state.store.isAuthComplete);
export const is2FaAuthComplete = createSelector(selectSelf, state => state.store.is2FaAuthComplete);

export const allMultiStoreData = createSelector(selectSelf, state => state.store);








