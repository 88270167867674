import {FC} from "react";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import {TextFieldProps} from "@mui/material/TextField/TextField";

type TCommonTextField = TextFieldProps & {
    errorMessage?: string;
}

type TSearchField = TCommonTextField & {
    onClear: () => void
}

export const CustomSearchField: FC<TSearchField> = ({onClear, children, ...props}) => {

    return (
        <TextField sx={{'& .MuiInputBase-input': {height: '16px'}, '& label': {top: '0 !important'}}}
            placeholder={'Search'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position={'end'}>
                        {(props.value === '' || props.value === null || props.value === undefined) ? <SearchIcon /> : <IconButton sx={{padding: '0'}} onClick={() => {
                            onClear()
                        }}><ClearIcon /></IconButton>}
                    </InputAdornment>
                )
            }}
            {...props}
        >{children}</TextField>
    )
}
