import {createAsyncThunk} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {getActionsData} from "../../../../newShared/redux";
import {TPageInfo, TWithOptions} from "../../../../newShared/types";
import {
    AccessManagementApp,
    AccessManagementAppAccount,
    campaignReviewExecutorStageActorAppData,
    campaignReviewExecutorType,
    campaignReviewInstructionType,
    createAppByVendorFormType,
    createAppByVendorRequestType,
    createNewAppFormType,
    createNewAppRequestType,
    smallCampaignReviewExecutorType,
    Vendor,
    VendorsSettings
} from "../types";
import {
    addConflictsApi,
    allowConflictCustomApi,
    allowConflictStandardApi,
    assignStageToMainControl,
    changeAccessManagementStatus,
    createAccessManagementAppByVendor,
    createAppDutyApi,
    createCampaignReviewInstruction,
    createDutyRuleApi,
    createNewAccessManagementApp,
    deleteAccessManagementCustomAppById,
    deleteAppDutyApi,
    deleteCampaignReviewInstruction,
    deleteConflictExceptionApi,
    deleteDutyRuleApi,
    denyConflictApi,
    downloadEvidenceFileAPI,
    editAccessManagementApp,
    exportCsvAccessManagementApp,
    exportCsvRulesetApi,
    getAccessManagementAppById,
    getAccessManagementApps,
    getAccessManagementAvailableFieldsForExport,
    getAppDutiesApi,
    getCampaignReviewInstructionById,
    getCampaignReviewInstructionWithPaging,
    getDataForAllowedConflictApi,
    getDataForCreateDutyApi,
    getDataForCreateRuleApi,
    getDataForDenyConflictApi,
    getDataForRelease,
    getDutiesRulesetApi,
    getDutyRuleByIdApi,
    getFullCampaignReviewExecutorById,
    getRoleNamesApi,
    getRulesConflictsApi,
    getSmallCampaignReviewExecutors,
    getSodSchedulerDataApi,
    getVendorsApi,
    getVendorsSettingsApi,
    importCsvAccessManagementAppTemplate,
    importCsvRuleset,
    importCsvRulesetTemplateApi,
    releaseAccessManagementApp,
    runCampaignReviewInstruction,
    runRuleCheckApi,
    terminateExecutor,
    toggleCampaignReviewInstructionStatus,
    updateAccessManagementAppNameAndVendor,
    updateAppDutyApi,
    updateCampaignReviewInstruction,
    updateCampaignReviewRows,
    updateConflictExceptionApi,
    updateDutyRuleApi,
    updateNonCustomAccessManagementAppRow,
    updateSodSchedulerDataApi,
    verifyActorChanges
} from "../api";
import {CREATE_APP_EMPLOYEES_AND_VENDORS_PAGING} from "../constants";
import {saveAs} from "file-saver";
import {b64toBlob} from "../../../../newShared/utils/base64/base64";
// import {ReleaseAccessManagementApp1MutationVariables} from "../../../../newShared/GQLTypes";
import {
    AddConflictsMutationVariables,
    AllowConflictCustomMutationVariables,
    AllowConflictStandardMutationVariables,
    CreateAppDutyMutationVariables,
    CreateDutyRuleMutationVariables,
    DeleteAppDutyMutationVariables,
    DeleteConflictExceptionMutationVariables,
    DeleteDutyRuleMutationVariables,
    DenyConflictMutationVariables,
    EditAppDutyMutationVariables,
    EditConflictExceptionMutationVariables,
    EditDutyRuleMutationVariables,
    EditSodSchedulerMutationVariables,
    GetCsvFieldsAccessManagementMutation,
    GetCsvFieldsAccessManagementMutationVariables,
    GetDataForAllowedConflictQueryVariables,
    GetDutyRuleByIdQueryVariables,
    GetRoleNamesByAppAccessIdQueryVariables,
    ImportCsvRulesetMutation,
    ImportCsvRulesetMutationVariables,
    PaginationInput,
    PerformCsvAccessManagementMutation,
    PerformCsvAccessManagementMutationVariables,
    ReleaseAccessManagementApp1MutationVariables,
    RunRuleCheckMutationVariables,
} from "../../../../newShared/GQLTypes";
import {getShortEmployees} from "../../employeesRefactor/api";
import {TShortEmployee} from "../../employeesRefactor/types";
import {createCommonAsyncThunk} from "../../../../newShared/utils/asyncThunk";
import {getCsvFieldsAccessManagement, performCsvAccessManagement} from "../api/query";
import moment from "moment";
import {addSuccessfulSnack} from "../../../barsEnvironment/snack/store/slice";


export const GetAccessManagementApps = createAsyncThunk(
    'accessManagement/getAccessManagementApps',
    async ({data, signal}: TWithOptions<{page: number, count: number}>, {getState}): Promise<{pageInfo: TPageInfo, apps: AccessManagementApp[]}> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getAccessManagementApps(workspaceId, data.page, data.count, signal);
    }
);

export const ChangeAccessManagementStatus = createAsyncThunk(
    'accessManagement/changeAccessManagementStatus',
    async ({data, signal}: TWithOptions<{appId: string, newStatus: boolean}>, {getState}): Promise<{appId: string, newStatus: boolean}> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await changeAccessManagementStatus(workspaceId, data.appId, data.newStatus, signal);
        return data;
    }
);


export const GetDataForCreateApp = createAsyncThunk(
    'accessManagement/GetDataForCreateApp',
    async (_, {getState}): Promise<{employees: TShortEmployee[], vendors: Vendor[], vendorSettings: VendorsSettings}> => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const res = await Promise.all([
            getShortEmployees({workspaceId}),
            getVendorsApi(workspaceId,
                organizationId,
                {page: 0, count: CREATE_APP_EMPLOYEES_AND_VENDORS_PAGING, filters: {}}
            ),
            getVendorsSettingsApi({workspaceId, organizationId})
        ]);

        return {
            employees: res[0],
            vendors: res[1].vendors,
            vendorSettings: res[2]
        }
    }
);

export const CreateNewAccessManagementApp = createAsyncThunk(
    'accessManagement/createNewAccessManagementApp',
    async ({data, signal}: TWithOptions<{app: createNewAppFormType, onSuccess: (id: string) => void}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const request: createNewAppRequestType = {
            name: data.app.name,
            riskLevel: data.app.riskLevel ?? '',
            providedService: data.app.providedService ?? '',
            type: data.app.type ?? '',
            owner: {
                id: data.app.owner?.publicId ?? '',
                name: `${data.app.owner?.firstName} ${data.app.owner?.lastName}` //<-- this field is not used on middle for crate
            }
        }

        const res = await createNewAccessManagementApp(workspaceId, request, signal);

        if(res && res.id){
            data.onSuccess(res.id);
        }

        return res;
    }
);
//createAccessManagementAppByVendor

export const CreateAccessManagementAppByVendor = createAsyncThunk(
    'accessManagement/createAccessManagementAppByVendor',
    async ({data, signal}: TWithOptions<{app: createAppByVendorFormType, onSuccess: (id: string) => void}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const request: createAppByVendorRequestType = {
            name: data.app.name,
            vendorId: data.app.vendor?.id ?? ''
        }

        const res = await createAccessManagementAppByVendor(workspaceId, request, signal);

        if(res && res.id){
            data.onSuccess(res.id);
        }

        return res;
    }
);

//getAccessManagementAppById
export const GetAccessManagementAppById = createAsyncThunk(
    'accessManagement/getAccessManagementAppById',
    async ({data, signal}: TWithOptions<{id: string}>, {getState}): Promise<AccessManagementApp> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getAccessManagementAppById(workspaceId, data.id, signal);
    }
);


//deleteAccessManagementCustomAppById
export const DeleteAccessManagementCustomAppById = createAsyncThunk(
    'accessManagement/deleteAccessManagementCustomAppById',
    async ({data, signal}: TWithOptions<{id: string, onSuccess: () => void}>, {getState}): Promise<string> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await deleteAccessManagementCustomAppById(workspaceId, data.id, signal);
        data.onSuccess();
        return data.id;
    }
);

//editAccessManagementApp
export const EditAccessManagementApp = createAsyncThunk(
    'accessManagement/editAccessManagementApp',
    async ({data, signal}: TWithOptions<{app: AccessManagementApp}>, {getState}): Promise<AccessManagementApp> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await editAccessManagementApp(workspaceId, data.app, signal);
    }
);

//importCsvAccessManagementAppTemplate

export const ImportCsvAccessManagementAppTemplate = createAsyncThunk(
    'accessManagement/importCsvAccessManagementAppTemplate',
    async ({id}: {id: string}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await importCsvAccessManagementAppTemplate(workspaceId, id); //id to be sure user has at least 1 app
        resp && saveAs(b64toBlob(resp as string), 'accessManagement-template.csv');
        return resp
    }
)

//updateAccessManagementAppNameAndVendor

export const UpdateAccessManagementAppNameAndVendor = createAsyncThunk(
    'accessManagement/updateAccessManagementAppNameAndVendor',
    async ({id, name, vendorId, vendorOwner}: {id: string, name: string, vendorId: string, vendorOwner: { name: string, id: string }}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        await updateAccessManagementAppNameAndVendor(workspaceId, id, name, vendorId); //id to be sure user has at least 1 app
        return{
            name,
            vendorId,
            id,
            vendorOwner
        }
    }
)

//exportCsvAccessManagementApp
export const ExportCsvAccessManagementApp = createAsyncThunk(
    'accessManagement/exportCsvAccessManagementApp',
    async (data: {id: string, name: string, fieldKeys: string[], isDownload: boolean}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await exportCsvAccessManagementApp({ workspaceId, id: data.id, fieldKeys: data.fieldKeys, isDownload: data.isDownload });

        if (data.isDownload) {
            const date = moment().format("yyyy-MM-DD-hh-mm-ss");
            saveAs(b64toBlob(resp as string), `${data.name}_access-management_${date}.csv`);
        } else {
            dispatch(addSuccessfulSnack(`You will receive email with a link to download CSV`));
        }

        return resp
    }
)


export const GetDataForRelease = createAsyncThunk(
    'accessManagement/GetDataForRelease',
    async (signal: AbortSignal, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        return await getDataForRelease({organizationId, workspaceId}, signal );
    }
);


//releaseEmployeesApi
export const ReleaseAccessManagementApp = createAsyncThunk(
    'accessManagement/releaseAccessManagementApp',
    async ({data, onSuccess}:{data: ReleaseAccessManagementApp1MutationVariables, onSuccess: () => void }, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        await releaseAccessManagementApp({...data, workspaceId, organizationId});
        onSuccess();
    }
)

//CAMPAIGN REVIEW

export const GetCampaignReviewInstructionWithPaging = createAsyncThunk(
    'accessManagement/getCampaignReviewInstructionWithPaging',
    async ({data, signal}: TWithOptions<{page: number, count: number}>, {getState}): Promise<{pageInfo: TPageInfo, instructions: campaignReviewInstructionType[]}> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getCampaignReviewInstructionWithPaging(workspaceId, data.page, data.count, signal);
    }
);


//getCampaignReviewInstructionById

export const GetCampaignReviewInstructionById = createAsyncThunk(
    'accessManagement/getCampaignReviewInstructionById',
    async ({data, signal}: TWithOptions<{id: string, onReject: () => void}>, {getState}): Promise<campaignReviewInstructionType> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        try{
            const res = await getCampaignReviewInstructionById(workspaceId, data.id, signal);
            return res;
        }catch (ex: any){
            data.onReject();
            throw new Error(ex.message);
        }
    }
);

//createCampaignReviewInstruction
export const CreateCampaignReviewInstruction = createAsyncThunk(
    'accessManagement/createCampaignReviewInstruction',
    async ({data, signal}: TWithOptions<{instruction: campaignReviewInstructionType, onSuccess: (id: string) => void}>, {getState}): Promise<campaignReviewInstructionType> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const id = await createCampaignReviewInstruction(workspaceId, data.instruction, signal);
        data.onSuccess(id);
        return {...data.instruction, id};
    }
);

//updateCampaignReviewInstruction
export const UpdateCampaignReviewInstruction = createAsyncThunk(
    'accessManagement/updateCampaignReviewInstruction',
    async ({data, signal}: TWithOptions<{instruction: campaignReviewInstructionType, onSuccess: () => void}>, {getState}): Promise<campaignReviewInstructionType> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await updateCampaignReviewInstruction(workspaceId, data.instruction, signal);
        data.onSuccess();
        return data.instruction;
    }
);

//deleteCampaignReviewInstruction
export const DeleteCampaignReviewInstruction = createAsyncThunk(
    'accessManagement/deleteCampaignReviewInstruction',
    async ({data, signal}: TWithOptions<{id: string, onSuccess: () => void}>, {getState}): Promise<string> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await deleteCampaignReviewInstruction(workspaceId, data.id, signal);
        data.onSuccess();
        return data.id;
    }
);

//runCampaignReviewInstruction
export const RunCampaignReviewInstruction = createAsyncThunk(
    'accessManagement/runCampaignReviewInstruction',
    async ({data, signal}: TWithOptions<{id: string, onSuccess: (executorId: string) => void}>, {getState}): Promise<{instructionId: string, lastExecutorId: string}> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const lastExecutorId = await runCampaignReviewInstruction(workspaceId, data.id, signal);
        data.onSuccess(lastExecutorId);
        return {instructionId: data.id, lastExecutorId};
    }
);

//toggleCampaignReviewInstructionStatus

export const ToggleCampaignReviewInstructionStatus = createAsyncThunk(
    'accessManagement/toggleCampaignReviewInstructionStatus',
    async ({data, signal}: TWithOptions<{id: string, active: boolean}>, {getState}): Promise<{instructionId: string, active: boolean}> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await toggleCampaignReviewInstructionStatus(workspaceId, data.id, data.active, signal);
        // data.onSuccess(lastExecutorId);
        return {instructionId: data.id, active: data.active};
    }
);

export const UpdateNonCustomAccessManagementAppRow = createAsyncThunk(
    'accessManagement/updateNonCustomAccessManagementAppRow',
    async ({data, signal}: TWithOptions<{accessManagementAppId: string, applicationId: string, account: AccessManagementAppAccount}>, {getState}): Promise<AccessManagementAppAccount> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await updateNonCustomAccessManagementAppRow(workspaceId, data.accessManagementAppId, data.applicationId, data.account, signal);
        // data.onSuccess(lastExecutorId);
        return data.account;
    }
);


//EXECUTORS
export const GetSmallCampaignReviewExecutors = createAsyncThunk(
    'accessManagement/getSmallCampaignReviewExecutors',
    async ({data, signal}: TWithOptions<{page: number, count: number}>, {getState}): Promise<{pageInfo: TPageInfo, executors: smallCampaignReviewExecutorType[]}> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getSmallCampaignReviewExecutors(workspaceId, data.page, data.count, signal);
    }
);

//getFullCampaignReviewExecutorById
export const GetFullCampaignReviewExecutorById = createAsyncThunk(
    'accessManagement/getFullCampaignReviewExecutorById',
    async ({data, signal}: TWithOptions<{id: string}>, {getState}): Promise<campaignReviewExecutorType> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getFullCampaignReviewExecutorById(workspaceId, data.id, signal);
    }
);

//updateCampaignReviewRows
export const UpdateCampaignReviewRows = createAsyncThunk(
    'accessManagement/updateCampaignReviewRows',
    async ({data, signal}: TWithOptions<{email: string, stageId: string, apps: campaignReviewExecutorStageActorAppData[], executorId: string, onSuccess?: () => void}>, {getState}): Promise<{email: string, stageId: string, apps: campaignReviewExecutorStageActorAppData[]}> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await updateCampaignReviewRows(workspaceId, data.email, data.stageId, data.apps, data.executorId, signal);
        data.onSuccess && data.onSuccess();
        return data;
    }
);
//assignStageToMainControl
export const AssignStageToMainControl = createAsyncThunk(
    'accessManagement/assignStageToMainControl',
    async ({data, signal}: TWithOptions<{email: string, stageId: string, executorId: string}>, {getState}): Promise<{email: string, stageId: string, }> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await assignStageToMainControl(workspaceId, data.email, data.stageId, data.executorId, signal);
        return data;
    }
);
//verifyActorChanges
export const VerifyActorChanges = createAsyncThunk(
    'accessManagement/verifyActorChanges',
    async ({data, signal}: TWithOptions<{executorId: string, email: string, stageId: string, verifyComment: string | null, isLastVerify: boolean}>, {getState, dispatch}): Promise<{email: string, stageId: string, }> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await verifyActorChanges(workspaceId, data.email, data.stageId, data.verifyComment, signal);
        if(data.isLastVerify){
            //changing stage - have to fetch updates created after last verify
            dispatch(GetFullCampaignReviewExecutorById({data: {id: data.executorId}}));
        }
        return data;
    }
);

//terminateExecutor
export const TerminateExecutor = createAsyncThunk(
    'accessManagement/terminateExecutor',
    async ({data, signal}: TWithOptions<{instructionId: string}>, {getState, dispatch}): Promise<void> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await terminateExecutor(workspaceId, data.instructionId, signal);
    }
);

export const DownloadReport = createAsyncThunk(
    'accessManagement/downloadReport',
    async (data: { fileId: string, name: string, scanId: string }, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        // dispatch(startDownloadFile(data.scanId));

        const resp = await downloadEvidenceFileAPI({fileId: data.fileId, fileName: data.name, workspaceId});
        saveAs(b64toBlob(resp.file), resp.filename)
        return {}
    }
);

export const GetEmployeesNameEmail = createAsyncThunk(
    'accessManagement/getEmployeesNameEmail',
    async (_, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getShortEmployees({workspaceId});
    }
);


//Segregation of duties
export const GetAppDutiesAction = createAsyncThunk(
    'accessManagement/getAppDuties',
    async (data: TWithOptions<{pageInfo: PaginationInput}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await getAppDutiesApi({...data.data, workspaceId, organizationId}, data.signal);

        return {
            ...resp,
            isNewOnTop: false
        }

    }
);

export const createAppDutyAction = createAsyncThunk(
    'accessManagement/createAppDuty',
    async ({data, onSuccess} : {data: CreateAppDutyMutationVariables, onSuccess?: (id: string) => void}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await createAppDutyApi({...data, workspaceId, organizationId});
        onSuccess && onSuccess(resp.id);
        return {
            ...resp
        }
    }
)

export const updateAppDutyAction = createAsyncThunk(
    'accessManagement/updateAppDuty',
    async (data: EditAppDutyMutationVariables, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await updateAppDutyApi({...data, workspaceId, organizationId});

        return {
            ...resp
        }
    }
)

export const deleteAppDutyAction = createAsyncThunk(
    'accessManagement/deleteAppDuty',
    async ({data, onSuccess, onError} : {data: DeleteAppDutyMutationVariables, onSuccess: Function, onError: Function }, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        try {
            await deleteAppDutyApi({...data, workspaceId, organizationId});
            onSuccess();
        }
        catch {
            onError();
        }
        // const resp = await deleteAppDutyApi({...data, workspaceId, organizationId});
        // else onSuccess();

        // dispatch(addSuccessfulSnack(resp.message))

        return {
            ...data,
        }
    }
)

//Ruleset
export const GetDutiesRulesetAction = createAsyncThunk(
    'accessManagement/GetDutiesRulesetAction',
    async (data: TWithOptions<{pageInfo: PaginationInput}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await getDutiesRulesetApi({workspaceId, organizationId, ...data.data}, data.signal);
        return {
            resp,
            isNewOnTop: false,
        }
    }
);

export const GetDutyRuleByIdAction = createAsyncThunk(
    'accessManagement/GetDutyRuleById',
    async ({data, signal}: TWithOptions<GetDutyRuleByIdQueryVariables>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        return await getDutyRuleByIdApi({...data, workspaceId, organizationId}, signal);
    }
);

export const createDutyRuleAction = createAsyncThunk(
    'accessManagement/createDutyRule',
    async ({data, onSuccess} : {data: CreateDutyRuleMutationVariables, onSuccess?: (id: string) => void}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await createDutyRuleApi({...data, workspaceId, organizationId});
        // console.log('resp.id ', resp.id);
        onSuccess && onSuccess(resp.id);
        return {
            ...resp
        }
    }
)

export const updateDutyRuleAction = createAsyncThunk(
    'accessManagement/updateDutyRule',
    async ({data, onSuccess}: TWithOptions<EditDutyRuleMutationVariables>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await updateDutyRuleApi({...data, workspaceId, organizationId});
        onSuccess && onSuccess(data, resp);

        return {
            ...resp
        }
    }
)

export const deleteDutyRuleAction = createAsyncThunk(
    'accessManagement/deleteDutyRule',
    async ({data, onSuccess} : { data: DeleteDutyRuleMutationVariables, onSuccess: Function }, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await deleteDutyRuleApi({...data, workspaceId, organizationId});

        // dispatch(addSuccessfulSnack(resp.message))
        onSuccess();
        return {
            ...data,
            resp
        }
    }
)

export const ImportCsvRulesetTemplateAction = createAsyncThunk(
    'accessManagement/importCsvRulesetTemplate',
    async (_, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await importCsvRulesetTemplateApi(workspaceId); //id to be sure user has at least 1 app
        resp && saveAs(b64toBlob(resp as string), 'ruleset-template.csv');
        return resp
    }
)


//exportCsvAccessManagementApp
export const ExportCsvRulesetAction = createAsyncThunk(
    'accessManagement/exportRuleset',
    async (data: {workspaceId: string}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await exportCsvRulesetApi(workspaceId);
        saveAs(b64toBlob(resp as string), `ruleset.csv`);
        return resp
    }
)

//importCsvRuleset

export const ImportCsvRulesetAction =
    createCommonAsyncThunk<ImportCsvRulesetMutationVariables, ImportCsvRulesetMutation["importCsvRuleset"]>(
        'accessManagement', 'importCsvRuleset', true, false, importCsvRuleset,
    );

export const runRuleCheckAction = createAsyncThunk(
    'accessManagement/runRuleCheck',
    async ({data, signal, onSuccess}: TWithOptions<RunRuleCheckMutationVariables>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await runRuleCheckApi({...data, workspaceId, organizationId}, signal);
        onSuccess && onSuccess(data, resp);

        return {
            resp
        }
    }
)

export const getRulesConflictsAction = createAsyncThunk(
    'accessManagement/getRulesConflicts',
    async (data: TWithOptions<{pageInfo: PaginationInput}>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await getRulesConflictsApi({...data.data, workspaceId, organizationId}, data.signal);

        return {
            ...resp,
            isNewOnTop: false,
        }
    }
)

export const addRulesConflictsAction = createAsyncThunk(
    'accessManagement/addRulesConflicts',
    async ({data, onSuccess}: {data: AddConflictsMutationVariables, onSuccess: Function }, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await addConflictsApi({...data, workspaceId, organizationId});

        onSuccess();

        return {
            ...resp
        }
    }
)

export const getDataForCreateDutyAction = createAsyncThunk(
    'accessManagement/getDataForCreateDuty',
    async (_, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await getDataForCreateDutyApi({workspaceId, organizationId});

        return {
            ...resp
        }
    }
)

export const getDataForAllowedConflictAction = createAsyncThunk(
    'accessManagement/getDataForAllowedConflict',
    async (data: GetDataForAllowedConflictQueryVariables , {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await getDataForAllowedConflictApi({...data, workspaceId, organizationId});

        return {
            ...resp
        }
    }
)


export const getDataForCreateRuleAction = createAsyncThunk(
    'accessManagement/getDataForCreateRule',
    async (_, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await getDataForCreateRuleApi({workspaceId, organizationId});

        return {
            ...resp
        }
    }
)

export const getDataForDenyConflictAction = createAsyncThunk(
    'accessManagement/getDataForDenyConflict',
    async (_, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await getDataForDenyConflictApi({workspaceId, organizationId});

        return {
            ...resp
        }
    }
)

//getRoleNamesApi
export const getRoleNamesAction = createAsyncThunk(
    'accessManagement/getRoleNames',
    async ( data: GetRoleNamesByAppAccessIdQueryVariables, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await getRoleNamesApi({...data, workspaceId, organizationId});

        return {
            ...resp
        }
    }
)

export const allowConflictStandardAction = createAsyncThunk(
    'accessManagement/allowConflictStandard',
    async ({data, onSuccess}: TWithOptions<AllowConflictStandardMutationVariables>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await allowConflictStandardApi({...data, workspaceId, organizationId});
        onSuccess && onSuccess(data, resp);

        return {
            ...resp,
            data
        }
    }
)

export const allowConflictCustomAction = createAsyncThunk(
    'accessManagement/allowConflictCustom',
    async ({data, onSuccess}: TWithOptions<AllowConflictCustomMutationVariables>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await allowConflictCustomApi({...data, workspaceId, organizationId});
        onSuccess && onSuccess(data, resp);

        return {
            ...resp
        }
    }
)

export const denyConflictAction = createAsyncThunk(
    'accessManagement/denyConflict',
    async ({data, onSuccess}: TWithOptions<DenyConflictMutationVariables>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await denyConflictApi({...data, workspaceId, organizationId});
        onSuccess && onSuccess(data, resp);

        return {
            ...resp
        }
    }
)

export const updateConflictExceptionAction = createAsyncThunk(
    'accessManagement/updateConflictException',
    async ({data, onSuccess}: TWithOptions<EditConflictExceptionMutationVariables>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await updateConflictExceptionApi({...data, workspaceId, organizationId});
        onSuccess && onSuccess(data, resp);

        return {
            ...resp
        }
    }
)

export const deleteConflictExceptionAction = createAsyncThunk(
    'accessManagement/deleteConflictException',
    async ({data, onSuccess}: TWithOptions<DeleteConflictExceptionMutationVariables>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await deleteConflictExceptionApi({...data, workspaceId, organizationId});
        onSuccess && onSuccess(data, resp);

        return {
            ...resp
        }
    }
)

export const GetSodSchedulerDataAction = createAsyncThunk(
    'accessManagement/GetSodSchedulerDAtaAction',
    async (data: TWithOptions<{pageInfo: PaginationInput}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        return await getSodSchedulerDataApi({workspaceId, organizationId, ...data.data}, data.signal);
    }
);

export const updateSodSchedulerDataAction = createAsyncThunk(
    'accessManagement/updateSodSchedulerData',
    async ({data, onSuccess}: TWithOptions<EditSodSchedulerMutationVariables>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await updateSodSchedulerDataApi({...data, workspaceId, organizationId});
        onSuccess && onSuccess(data, resp);

        return {
            ...resp
        }
    }
);

export const getCsvFieldsAccessManagementAction =
    createCommonAsyncThunk<GetCsvFieldsAccessManagementMutationVariables, GetCsvFieldsAccessManagementMutation["getCsvFieldsAccessManagement"]>(
        'accessManagement', 'getCsvFieldsAccessManagement', true, false, getCsvFieldsAccessManagement
    )
export const performCsvAccessManagementAction =
    createCommonAsyncThunk<PerformCsvAccessManagementMutationVariables, PerformCsvAccessManagementMutation["performCsvAccessManagement"]>(
        'accessManagement', 'performCsvAccessManagement', true, false, performCsvAccessManagement,
    )


export const GetAccessManagementAvailableFieldsForExport = createAsyncThunk(
    'accessManagement/GetAccessManagementAvailableFieldsForExport',
    async (_, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const {fields} = await getAccessManagementAvailableFieldsForExport({workspaceId});
        return {
            fields,
        };
    }
);
