import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useAllowConflict} from "../../../hooks/dialogHooks/useAllowConflict";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {TAddCollaboratorsTab} from "../../../types";
import {LoadingButton, TabContext} from "@mui/lab";
import {SearchField} from "../../../../../../newShared/components/Basic/CommonInputs";
import {CollaboratorRowSkeleton} from "../collaboratorRowSkeleton";
import {CollaboratorRow} from "../collaboratorRow";
import {DEFAULT_DATE_FORMAT} from "../../../../../../newShared/utils/dateTools";

export const AllowConflictDialog = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});
    // const {isMobile} = useMedia();

    const {
        isOk,
        isOpen,
        isLoadingData,
        isLoadingAllow,
        handleHideAllowConflict: handleClose,
        form, handleChange,
        tab, setTab,
        isCustomApproach,
        // setIsCustomApproach,
        search, setSearch,
        filteredCollaborators: collaborators,

        handleSave,
        handleCleanSearch,
        handleSetCollaborator,
        handleDeleteCollaborator,
        handleSetAllowTill,

    } = useAllowConflict();


    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Allow conflict')}</DialogTitle>
            <DialogContent sx={{display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
                {/*<Box display={'flex'} alignItems={'center'}>*/}
                {/*    <Box display={'flex'} sx={{cursor: 'pointer'}} alignItems={'center'} onClick={() => setIsCustomApproach(false)}>*/}
                {/*        <CommonRadio checked={!isCustomApproach} />*/}
                {/*        <Typography variant={'body1'}>{t('Standard approach')}</Typography>*/}
                {/*    </Box>*/}
                {/*    <Box display={'flex'} sx={{cursor: 'pointer', ml: !revDir ? '12px' : undefined, mr: revDir ? '8px' : undefined}} alignItems={'center'} onClick={() => setIsCustomApproach(true)}>*/}
                {/*        <CommonRadio checked={isCustomApproach} />*/}
                {/*        <Typography variant={'body1'}>{t('Create custom automation')}</Typography>*/}
                {/*    </Box>*/}
                {/*</Box>*/}
                <Typography variant={'body2'} >{t('Please fill the resolution and the reason for allowing the conflict')}</Typography>

                <TextField required label={t('Specify resolution')} value={form.resolution} multiline minRows={5} name={'resolution'} sx={{mt: '8px'}} onChange={handleChange} />
                <TextField required label={t('Specify reason')} value={form.reason} multiline minRows={5} name={'reason'} sx={{mt: '8px'}} onChange={handleChange} />

                <DatePicker
                    label={t('Allow till')}
                    inputFormat={DEFAULT_DATE_FORMAT}
                    value={form.allowedDate || null}
                    disabled={isLoadingData}
                    disablePast
                    onChange={(newValue) => {
                        if(newValue){
                            try{
                                const date = new Date(Date.parse(newValue)).toISOString();
                                handleSetAllowTill(date);
                            }catch (ex){
                                console.log(`Allow till date: invalid date`)
                            }
                        }
                    }}
                    renderInput={(params) => <TextField required size={'small'} {...params} sx={{mt: '8px', '& label': {top: '0 !important'}}}/>}
                />

                {isCustomApproach &&
                    <>
                        <Typography sx={{mt: '8px'}} variant={'body2'}>{t('Select an actor from the list or use the search')}</Typography>

                        <TabContext value={tab}>
                            <Tabs value={tab} onChange={(e, newValue: TAddCollaboratorsTab) => setTab(newValue)} >
                                <Tab disabled={isLoadingAllow || isLoadingData} label={("Readers")} value={'readers'}  />
                                <Tab disabled={isLoadingAllow || isLoadingData} label={("Reviewers*")} value={'reviewers'} />
                                <Tab disabled={isLoadingAllow || isLoadingData} label={("Signers*")} value={'signers'}  />
                            </Tabs>

                            {/*READERS*/}
                            {tab === 'readers' &&
                                <Box display={'flex'} flexDirection={'column'} sx={{mt: '10px', flexGrow: 1, overflow: 'hidden'}}>
                                    <SearchField
                                        size={'small'}
                                        variant={'outlined'}
                                        placeholder={t('Enter email or name')}
                                        disabled={isLoadingAllow || isLoadingData}
                                        onClear={handleCleanSearch}
                                        onChange={(e) => {setSearch(e.target.value)}}
                                        value={search}
                                    />

                                    <Box display={'flex'} flexDirection={'column'} sx={{mt: '16px', overflow: 'auto'}}>
                                        { isLoadingData ?
                                            <CollaboratorRowSkeleton />
                                            :
                                            collaborators.map((e) => (
                                                <CollaboratorRow key={e.publicId}
                                                                 collaborator={e}
                                                                 selectedCollaborators={form.readers}
                                                                 onDelete={() => handleDeleteCollaborator(e.publicId, 'readers')}
                                                                 onSelect={() => handleSetCollaborator(e, 'readers')} />
                                            ))
                                        }

                                    </Box>

                                </Box>

                            }

                            {/*REVIEWERS*/}
                            {tab === 'reviewers' &&
                                <Box display={'flex'} flexDirection={'column'} sx={{mt: '10px', flexGrow: 1, overflow: 'hidden'}}>
                                    <SearchField
                                        size={'small'}
                                        variant={'outlined'}
                                        placeholder={t('Enter email or name')}
                                        disabled={isLoadingAllow || isLoadingData}
                                        onClear={handleCleanSearch}
                                        onChange={(e) => {setSearch(e.target.value)}}
                                        value={search}
                                    />

                                    <Box display={'flex'} flexDirection={'column'} sx={{mt: '16px', overflow: 'auto'}}>
                                        { isLoadingData ?
                                            <CollaboratorRowSkeleton />
                                            :
                                            collaborators.map((e) => (
                                                <CollaboratorRow key={e.publicId}
                                                                 collaborator={e}
                                                                 selectedCollaborators={form.reviewers}
                                                                 onDelete={() => handleDeleteCollaborator(e.publicId, 'reviewers')}
                                                                 onSelect={() => handleSetCollaborator(e, 'reviewers')} />
                                            ))
                                        }

                                    </Box>

                                </Box>

                            }

                            {/*SIGNERS*/}
                            {tab === 'signers' &&
                                <Box display={'flex'} flexDirection={'column'} sx={{mt: '10px', flexGrow: 1, overflow: 'hidden'}}>
                                    <SearchField
                                        size={'small'}
                                        variant={'outlined'}
                                        placeholder={t('Enter email or name')}
                                        disabled={isLoadingAllow || isLoadingData}
                                        onClear={handleCleanSearch}
                                        onChange={(e) => {setSearch(e.target.value)}}
                                        value={search}
                                    />

                                    <Box display={'flex'} flexDirection={'column'} sx={{mt: '16px', overflow: 'auto'}}>
                                        { isLoadingData ?
                                            <CollaboratorRowSkeleton />
                                            :
                                            collaborators.map((e) => (
                                                <CollaboratorRow key={e.publicId}
                                                                 collaborator={e}
                                                                 selectedCollaborators={form.signers}
                                                                 onDelete={() => handleDeleteCollaborator(e.publicId, 'signers')}
                                                                 onSelect={() => handleSetCollaborator(e, 'signers')} />
                                            ))
                                        }

                                    </Box>

                                </Box>

                            }
                        </TabContext>
                    </>
                }


            </DialogContent>
            <DialogActions>
                <Button sx={{textTransform: 'none'}} onClick={handleClose} size={'medium'} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton size={'medium'} variant={'contained'} sx={{textTransform: 'none'}}
                               disabled={!isOk || isLoadingAllow || isLoadingData}
                               onClick={handleSave} loading={isLoadingAllow}
                               endIcon={isLoadingAllow ? <div style={{width: '10px', height: '10px', marginLeft: revDir ? '12px' : undefined, marginRight: !revDir ? '12px' : undefined }}/> : undefined}
                               loadingPosition={isLoadingAllow ? 'end' : undefined}
                >
                    {t('Save')}
                </LoadingButton>

            </DialogActions>
        </Dialog>
    )
}
