import {ColumnType, EmployeeType, SettingsTypes, TEmployeeEducation} from "./types";
import {defaultColumnsConfig, SAVE_COLUMNS_CONFIG_PREFIX} from "./constants";
import dagre, {graphlib} from "dagre";
import {Edge, Node, Position} from "reactflow";
import {HrIssuesStatus, HrTimeOffStatus, HrTimeOffStepsStatus, MainHrRequestStatus} from "../../../newShared/GQLTypes";
import {StatusBox, StatusTitle} from "./components/styled";
import colors from "../../../newShared/theme/colors";
import React from "react";


export const getKey = (workspaceId: string, publicId: string): string => `${SAVE_COLUMNS_CONFIG_PREFIX}${workspaceId}#${publicId}`;

export const getEmployeesColumnsSettingsLocalStorage = (publicId: string, workspaceId: string): ColumnType[] => {
    const storage = localStorage.getItem(getKey(workspaceId, publicId));
    return storage ? JSON.parse(storage) as ColumnType[] : defaultColumnsConfig;
}

export const setEmployeesColumnsSettingsLocalStorage = (publicId: string, workspaceId: string, columns: ColumnType[]): void => {
    localStorage.setItem(getKey(workspaceId, publicId), JSON.stringify(columns));
}

export const sortEducation = (a: TEmployeeEducation, b: TEmployeeEducation): number => {
    if(a.endDate && b.endDate){
        return Date.parse(b.endDate) - Date.parse(a.endDate)
    }else{
        if (a.schoolName < b.schoolName) {
            return -1;
        }
        if (a.schoolName > b.schoolName) {
            return 1;
        }
    }
    return 0;
}

export const isEqual = (a: string, b: string): boolean => {
    return a.toLowerCase().trim() === b.toLowerCase().trim()
}

export const normalizeSettingIdName = (value: SettingsTypes): string => {
    switch (value){
        case "customFields": return 'Custom fields';
        case "department": return 'Department';
        case "degree": return 'Degree';
        case "division": return 'Division';
        case "employmentType": return 'Employment type';
        case "location": return 'Location';
        case "relationshipType": return 'Relationship type';
        default: return '';
    }
}

export const isGeneralInfoChanged = (prev: EmployeeType | null, next: EmployeeType): boolean => {
    if(!prev) return false;
    return JSON.stringify([
            prev.firstName,
            prev.lastName,
            prev.middleName,
            prev.nickname,
            prev.workEmail,
            prev.personalData,
            prev.birthDay,
            prev.gender,
            prev.maritalStatus,
            prev.ethnicity,
            prev.contactInfo,
            prev.customFields
        ]) !==
        JSON.stringify([
            next.firstName,
            next.lastName,
            next.middleName,
            next.nickname,
            next.workEmail,
            next.personalData,
            next.birthDay,
            next.gender,
            next.maritalStatus,
            next.ethnicity,
            next.contactInfo,
            next.customFields
        ])
}

//Tree:

export type TEmployeeNodeData = {
    id: string;
    name: string;
    logo?: string | null;
    manager?: string | null;

    jobTitle?: string | null;
    department?: string | null;

    workMobilePhone?: string | null;
    workEmail?: string | null;

    isDeleted: boolean;
}

export const employeeTreeNodeWidth = 260;
export const employeeTreeNodeHeight = 109;
export const getLayoutedElements = (dagreGraph: graphlib.Graph<{}>, nodes: Node<TEmployeeNodeData>[], edges: Edge[], direction = 'TB'): {nodes: Node<TEmployeeNodeData>[], edges: Edge[]} => {
    const isHorizontal = direction === 'LR' || direction === 'RL';
    dagreGraph.setGraph({
        rankdir: direction,
        edgesep: 10,
        nodesep: 20,
        ranksep: 35,
    });

    nodes.forEach((node, index, array) => {
        dagreGraph.setNode(node.id, { width: employeeTreeNodeWidth, height: employeeTreeNodeHeight });
        dagreGraph.setParent(node.id, node.id + ' Group');
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
        dagreGraph.setParent(edge.target + ' Group', edge.source + ' Group');
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        // node.targetPosition = isHorizontal ? direction === 'LR' ? Position.Left : Position.Right : Position.Top;
        // node.sourcePosition = isHorizontal ? direction === 'RL' ? Position.Right : Position.Left : Position.Bottom;
        node.targetPosition = isHorizontal ? direction === 'LR' ? Position.Left : Position.Right : Position.Top;
        node.sourcePosition = isHorizontal ? direction === 'LR' ? Position.Right : Position.Left : Position.Bottom;

        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        node.position = {
            x: nodeWithPosition.x - employeeTreeNodeWidth / 2,
            y: nodeWithPosition.y - employeeTreeNodeHeight / 2,
        };

        return node;
    });

    return { nodes, edges };
};


export const getHrRequestStatusChip = (status: MainHrRequestStatus, isFilter?: boolean) => {
    switch (status) {
        case MainHrRequestStatus.Created: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.decorative.background_5}><StatusTitle color={colors.decorative.lilac}>{'Created'}</StatusTitle></StatusBox>;
        case MainHrRequestStatus.Approved: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.decorative.background_3}><StatusTitle color={colors.text.success}>{'Approved'}</StatusTitle></StatusBox>;
        case MainHrRequestStatus.Rejected: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.decorative.background_2}><StatusTitle color={colors.text.critical}>{'Rejected'}</StatusTitle></StatusBox>;
        default:  return <StatusBox margin={isFilter ? '8px 0 0 0' : undefined} bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.text.grey}>{status}</StatusTitle></StatusBox>;
    }
}

export const getHrIssueStatusChip = (status: HrIssuesStatus, isFilter?: boolean) => {
    switch (status) {
        case HrIssuesStatus.Reported: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.decorative.background_5}><StatusTitle color={colors.decorative.lilac}>{'Reported'}</StatusTitle></StatusBox>;
        case HrIssuesStatus.InProgress: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.decorative.background_1}><StatusTitle color={colors.decorative.violet}>{'In progress'}</StatusTitle></StatusBox>;
        case HrIssuesStatus.Resolved: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.decorative.background_3}><StatusTitle color={colors.text.success}>{'Resolved'}</StatusTitle></StatusBox>;
        default:  return <StatusBox margin={isFilter ? '8px 0 0 0' : undefined} bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.text.grey}>{status}</StatusTitle></StatusBox>;
    }
}

export const getTimeOffStatusChip = (status: HrTimeOffStatus, isFilter?: boolean) => {
    switch (status) {
        case HrTimeOffStatus.Created: return ( <StatusBox margin={isFilter ? "8px 0 0 0" : undefined} bgColor={colors.decorative.background_1}><StatusTitle color={colors.text.grey_dark}>{"Created"}</StatusTitle></StatusBox>);
        case HrTimeOffStatus.Rejected: return (<StatusBox margin={isFilter ? "8px 0 0 0" : undefined} bgColor={"#FEE4E2"}><StatusTitle color={"#B42318"}>{"Rejected"}</StatusTitle></StatusBox>);
        case HrTimeOffStatus.Approval:return (<StatusBox margin={isFilter ? "8px 0 0 0" : undefined} bgColor={"#E3F2FD"}><StatusTitle color={colors.text.success}>{"Approval"}</StatusTitle></StatusBox>);
        case HrTimeOffStatus.Approved: return (<StatusBox margin={isFilter ? "8px 0 0 0" : undefined}  bgColor={"#D1FADF"} > <StatusTitle color={colors.text.success}>{"Approved"}</StatusTitle> </StatusBox> );
        // case HrTimeOffStatus.Cancelled: return <StatusBox margin={isFilter ? '8px 0 0 0' : undefined} bgColor={'#EDEBE9'}><StatusTitle color={'#B42318'}>{'Cancelled'}</StatusTitle></StatusBox>;
        default: return (<StatusBox margin={isFilter ? "8px 0 0 0" : undefined} bgColor={colors.decorative.background_4}><StatusTitle color={colors.text.dark}>{status}</StatusTitle></StatusBox>);
    }
};

export const getTimeOffStepStatusChip = (status: HrTimeOffStepsStatus, isFilter?: boolean) => {
    switch (status) {
        case HrTimeOffStepsStatus.Created: return <StatusBox margin={isFilter ? '8px 0 0 0' : undefined} bgColor={colors.status.violet_1}><StatusTitle color={colors.textStatus.neutral}>{'Created'}</StatusTitle></StatusBox>;
        case HrTimeOffStepsStatus.Rejected: return <StatusBox margin={isFilter ? '8px 0 0 0' : undefined} bgColor={colors.status.red_1}><StatusTitle color={colors.textStatus.negative}>{'Rejected'}</StatusTitle></StatusBox>;
        case HrTimeOffStepsStatus.Approved: return <StatusBox margin={isFilter ? '8px 0 0 0' : undefined} bgColor={colors.status.green_3}><StatusTitle color={colors.textStatus.positive}>{'Approved'}</StatusTitle></StatusBox>;
        case HrTimeOffStepsStatus.Skipped: return <StatusBox margin={isFilter ? '8px 0 0 0' : undefined} bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{'Skipped'}</StatusTitle></StatusBox>;
        case HrTimeOffStepsStatus.InProgress: return <StatusBox margin={isFilter ? '8px 0 0 0' : undefined} bgColor={colors.status.blue_2}><StatusTitle color={colors.textStatus.neutral}>{'In progress'}</StatusTitle></StatusBox>;
        case HrTimeOffStepsStatus.Pending: return <StatusBox margin={isFilter ? '8px 0 0 0' : undefined} bgColor={colors.status.grey_2}><StatusTitle color={colors.textStatus.neutral}>{'Pending'}</StatusTitle></StatusBox>;
        default:  return <StatusBox margin={isFilter ? '8px 0 0 0' : undefined} bgColor={colors.decorative.background_4}><StatusTitle color={colors.text.dark}>{status}</StatusTitle></StatusBox>;
    }
}

