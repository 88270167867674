import {Button, styled, TextField} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";


export const ViewSettingsButton = styled(Button)(() => ({
    marginTop: '16px',
    alignSelf: "flex-start",
}));

ViewSettingsButton.defaultProps = {
    variant: 'text',
    size: 'small',
    startIcon: <SettingsIcon />
}

export const SelectRisk = styled(TextField)(() => ({
    width: '240px',
    height: '32px',
    marginLeft: '16px',
    marginRight: '16px',
    '& .MuiInputBase-root': {height: '32px'} ,
    '& label': {top: '0 !important'}
}));

SelectRisk.defaultProps = {
    select: true,
}
