import {FC} from "react";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import {ListTypeIconButton} from "./styled";
import {TListType, TToolBarHandlerAction, TToolBarHandlerPayload} from "../../types";
import {withShrinkZero} from "../../../hoc/withShrinkZero";
import {EditorTooltip} from "../../../commonComponents/editorTooltip";

interface ListTypeControlProps {
    initialListType?: TListType | null; // 'none' | 'bullet' | 'number';
    changeListTypeHandler?: (action: TToolBarHandlerAction, payload: TToolBarHandlerPayload) => void;
    isDisabled: boolean;
}

const ListTypeControl: FC<ListTypeControlProps> = ({ initialListType = null, changeListTypeHandler = () => {}, isDisabled}) => {
    const isOrdered = initialListType?.startsWith('list-ordered');
    const isUnordered = initialListType?.startsWith('list-unordered');

    return <>
        <EditorTooltip title="Bulleted list">
            <span>
                <ListTypeIconButton active={isUnordered || undefined}
                                    disabled={isDisabled}
                                    size="medium"
                                    edge={undefined}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        changeListTypeHandler("listTypeControl", isUnordered ? null : 'list-unordered-circle')
                                    }}>
                <FormatListBulletedIcon/>
            </ListTypeIconButton>
            </span>
        </EditorTooltip>
        <EditorTooltip title="Numbered list">
            <span>
                <ListTypeIconButton active={isOrdered || undefined}
                                    disabled={isDisabled}
                                    size="medium"
                                    edge={undefined}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        changeListTypeHandler("listTypeControl", isOrdered ? null : "list-ordered-decimal")
                                    }}>
                <FormatListNumberedIcon/>
            </ListTypeIconButton>
            </span>
        </EditorTooltip>
    </>;
}

const wrapper = withShrinkZero<ListTypeControlProps>(ListTypeControl);

export {wrapper as ListTypeControl};
