import React, {forwardRef, HTMLProps, useCallback, useEffect, useState} from "react";
import {Autocomplete, AutocompleteFreeSoloValueMapping, Button, CircularProgress, TextField} from "@mui/material";
import {optionIsTCollaborator, TAutocompleteCollaboratorsProps, TCollaborator, TCollaboratorWithRole} from "./types";
import {AutocompleteValue} from "@mui/base/useAutocomplete/useAutocomplete";
import {getName, validateEmail} from "../../utils/text";
import {FlexRow} from "../editorUnion/components/editorTitleWithActionsRow/styled";
import {isArray} from "../../utils";
import {getOptionLabel, modifyCollaborator} from "./helpers";
import {AutocompleteCollaboratorOption} from "./components/AutocompleteCollaboratorOption";
import {useAutocompleteCollaboratorsFetchMore} from "./hooks/useAutocompleteCollaboratorsFetchMore";
import {getStartAdornment} from "./components/autocompleteCollaboratorsStartAbornment";

export const AutocompleteCollaborators = <M extends boolean | undefined, D extends boolean | undefined, F extends boolean = false>(props: Omit<TAutocompleteCollaboratorsProps<M, D, F>, 'ref'>) => {

    const {
        multiple ,
        freeSoloType = 'string',
        hideCount,

        defaultValue,

        label,
        byAllWorkspaces,

        getOptionLabelCustom = 'email',

        onChange,
        helperText,
        required,

        workspaceId: customWorkspaceId,
        organizationId: customOrganizationId,

        onError,
        shouldForwardErrorValue,

        inputName, inputOnBlur,

        ...otherProps
    } = props;

    const [value, setValue] = useState<AutocompleteValue<TCollaboratorWithRole | AutocompleteFreeSoloValueMapping<F>, M, D, F>>(JSON.parse(JSON.stringify(defaultValue)));

    const freeSoloOptions = (props.freeSolo && multiple && Array.isArray(value) && (value.filter(e => typeof e === "string") as string[])) || [];
    const valuesWithoutFreeSoloOptions = (multiple && Array.isArray(value) && (value.filter(e => typeof e !== "string") as TCollaborator[])) || [];

    const {
        data,
        loading, hasMore,
        fetchMore, fetch0,
    } = useAutocompleteCollaboratorsFetchMore<M, D, F>(props);

    const filterOptions = useCallback((options: (TCollaborator | TCollaboratorWithRole | AutocompleteFreeSoloValueMapping<F>)[], {inputValue}: {inputValue: string}) => {
        const string = inputValue.toLowerCase().trim();

        const array: (TCollaborator | string)[] = [];
        if (props.freeSolo) {
            if (
                !freeSoloOptions.some(e => e.toLowerCase().trim() === string) &&
                !data?.result.some(option =>
                    option.email.toLowerCase().trim() === string ||
                    getName(option.firstName || '', option.lastName || '').toLowerCase().trim() === string ||
                    getName(option.lastName || '', option.firstName || '').toLowerCase().trim() === string
                ) &&
                string.length > 0
            ) {
                if (freeSoloType === 'email') validateEmail(string) && array.push(string)
                else array.push(inputValue.trim());
            }

            if (!multiple && value && typeof value === "string" && value.toLowerCase().includes(string)) {
                array.push(value);
            }
        }

        if (isArray(value)) {
            valuesWithoutFreeSoloOptions
                .filter(e => !options.some(o => optionIsTCollaborator(o) && o.publicId === e.publicId))
                .filter(option =>
                    option.email.toLowerCase().trim().includes(string) ||
                    getName(option.firstName || '', option.lastName || '').toLowerCase().trim().includes(string) ||
                    getName(option.lastName || '', option.firstName || '').toLowerCase().trim().includes(string)
                )
                .forEach(e => {array.push(e)})
        }

        return [
            ...array,
            ...freeSoloOptions.filter(e => e.toLowerCase().includes(string)),
            ...inputValue.trim() !== ''
                ? options
                : options.sort((a, b) => {
                    if (multiple && Array.isArray(valuesWithoutFreeSoloOptions) && typeof a !== "string" && typeof b !== "string") {
                        if (valuesWithoutFreeSoloOptions.some(e => e.email === a.email) && !valuesWithoutFreeSoloOptions.some(e => e.email === b.email)) {
                            return -1;
                        }
                        if (!valuesWithoutFreeSoloOptions.some(e => e.email === a.email) && valuesWithoutFreeSoloOptions.some(e => e.email === b.email)) {
                            return 1;
                        }
                    }

                    return 0;
                })
        ] as (TCollaboratorWithRole | AutocompleteFreeSoloValueMapping<F>)[];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [freeSoloOptions, data?.result, multiple, value]);

    const [error, setError] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>(typeof defaultValue === "string" ? JSON.parse(JSON.stringify(defaultValue)) : '');

    const [init, setInit] = useState(true);
    useEffect(() => {
        !init && onChange(modifyCollaborator<M, D, F>(value));
        setInit(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(value)]);

    useEffect(() => {
        error && setError(false);
        fetch0(inputValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(inputValue)]);

    useEffect(() => {
        if (data?.result.some(e => e.email === value)) {
            setValue(data?.result.find(e => e.email === value) as AutocompleteValue<TCollaboratorWithRole | AutocompleteFreeSoloValueMapping<F>, M, D, F>);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(data?.result)]);

    useEffect(() => {
        return () => {
            fetch0.cancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isError = Boolean(helperText ?? error);
    useEffect(() => {
        onError?.(isError)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError]);

    return (
        <Autocomplete<TCollaboratorWithRole | AutocompleteFreeSoloValueMapping<F>, M, D, F>
            {...otherProps}

            size={'small'}
            selectOnFocus={false}
            disableCloseOnSelect={multiple}
            clearOnBlur={false}
            openOnFocus

            multiple={multiple}
            loading={loading}

            onBlur={(e) => {
                if (props.freeSolo && !multiple && !isArray(value) && (!value || typeof value === "string" || (value as TCollaborator).email !== inputValue)) {
                    if (freeSoloType === 'email') {
                        if (validateEmail(inputValue)) {
                            setValue(inputValue as AutocompleteValue<TCollaboratorWithRole | AutocompleteFreeSoloValueMapping<F>, M, D, F>);
                        }
                        else {
                            inputValue.length > 0 && setError(true);
                            shouldForwardErrorValue && setValue(inputValue as AutocompleteValue<TCollaboratorWithRole | AutocompleteFreeSoloValueMapping<F>, M, D, F>);
                        }
                    } else {
                        setValue(inputValue as AutocompleteValue<TCollaboratorWithRole | AutocompleteFreeSoloValueMapping<F>, M, D, F>)
                    }
                }
                if (!props.freeSolo || multiple) {
                    setInputValue('');
                }
            }}

            getOptionLabel={props.getOptionLabel || getOptionLabel(props.getOptionLabelCustom)}
            renderOption={props.renderOption || AutocompleteCollaboratorOption({getOptionLabelCustom, getOptionLabel: props.getOptionLabel})}

            renderInput={({...params}) =>
                <TextField {...params} label={label}
                           required={required}
                           error={isError}
                           fullWidth
                           InputProps={{
                               ...params.InputProps,
                               endAdornment: (
                                   <>
                                       {loading && <CircularProgress color="inherit" size={20}/>}
                                       {params.InputProps.endAdornment}
                                   </>
                               ),
                               startAdornment: !hideCount ? getStartAdornment(value, multiple) : undefined,
                           }}

                           name={inputName}
                           onBlur={e => {
                               inputOnBlur?.(e)
                           }}
                           helperText={helperText ?? (isError ? 'Invalid email' : undefined)}
                />
            }
            ListboxComponent={forwardRef<HTMLUListElement, HTMLProps<HTMLUListElement>>(({children, ...props}, ref) => (
                <ul {...props} ref={ref}>
                    {children}

                    {(hasMore || loading) && (
                        <FlexRow sx={{alignItems: 'center', justifyContent: 'center', height: '34px'}}>
                            {loading ? (
                                <CircularProgress size={20}/>
                            ) : (
                                hasMore && <Button fullWidth variant={"text"} size={"small"} onClick={fetchMore}>{'Load more'}</Button>
                            )}
                        </FlexRow>
                    )}
                </ul>
            ))}

            options={data?.result || []}
            value={value}
            inputValue={inputValue}
            onChange={(_, value) => {
                if (props.freeSolo && freeSoloType === "email") {
                    if (Array.isArray(value)) {
                        if (value.some(e => typeof e === "string" && !validateEmail(e))) {
                            setError(true);
                        }
                        else setValue(value)
                    } else if (typeof value !== "string" || validateEmail(value)) {
                        setValue(value);
                        setInputValue(typeof value === "string" ? value : ((value as TCollaborator)?.email || ''));
                    } else if (shouldForwardErrorValue) {
                        // console.log('shouldForwardErrorValue', value)
                        setValue(value);
                    }
                } else setValue(value);
            }}
            onInputChange={(_, newInputValue, reason) => {
                if (props.freeSolo && freeSoloType === "email" && reason === 'reset') {
                    return;
                }
                setInputValue(newInputValue);
            }}

            isOptionEqualToValue={(option, value) => {
                if (typeof option === "string") return typeof value === "string" ? option === value : option === value.email;
                else return typeof value === "string" ? option.email === value : option.email === value.email;
            }}

            filterOptions={filterOptions}
        />
    )
};