import React, {FC} from "react";
import {Divider, IconButton, Menu, Typography} from "@mui/material";
import {useTopBar} from "../../hooks/useTopBar";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useHistory} from "react-router";
import {LABEL, PATH_LOCAL_SETTINGS, SHOW_CHANGE_LANGUAGE_IN_TOPBAR} from "../../../../../newShared/constants";
import colors from "../../../../../newShared/theme/colors";
import {MoreVert} from "@mui/icons-material";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {TopBarMenuItem} from "../topBarMenuItem";
import {ReactComponent as PortalIcon} from "../../../../../newShared/images/other/portalIcon.svg";
import {ReactComponent as ReportsIcon} from "../../../../../newShared/images/other/reportsIcon.svg";
import {AvatarMenuTitle} from "../avatarMenuTitle";
import {getName} from "../../../../../newShared/utils/text";
import {CommonAvatar} from "../../../../../newShared/components/Basic/CommonAvatar";
import {MenuItemHoverBlue} from "../styled";
import {SETTINGS_LANGUAGE_PATH, SETTINGS_PROFILE_PATH} from "../../../../authWorkspacesCookies/settings/constants";
import {ChangeLanguageDialog} from "../../../changeLanguageDialog/hook";
import {LanguageChangeDialog} from "../../../changeLanguageDialog";
import {MobileMenuDialog} from "../mobileMenuDialog";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useDispatch} from "react-redux";
import {ContactUsDialog} from "../../../../components/contactUs/contactUsDialog";
import {setContactUsDialogAction} from "../../../../components/contactUs/store/slice";


export const ThreeDotsTopBar: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'TopBar.threeDots'});
    const history = useHistory();
    const {currData: {currentUser}, logout} = UseManageWorkspacesAndOrganizations();
    const {toggleVisibility, isOpen} = ChangeLanguageDialog();
    const dispatch = useDispatch();
    const openContactUsDialog = () => {
        dispatch(setContactUsDialogAction({isOpen: true}))
        handleThreeDotsClose();
    }
    const {
        anchorThreeDotsEl, handleThreeDotsOpen, handleThreeDotsClose,
        // handleGoToMain,
        handleGoToReports,
        handleGoToPortal,
        handleOpenSelectWorkspaceDialog
    } = useTopBar();

    const {isMobile} = useMedia();

    const handleSelect = (path: string) => {
        history.push(path);
        handleThreeDotsClose()
    }

    return currentUser ? (
        <>
            <IconButton
                onClick={handleThreeDotsOpen}
                size={"large"}
                sx={{color: Boolean(anchorThreeDotsEl) ? colors.primary.blue : colors.text.grey_dark}}
            >
                <MoreVert/>
            </IconButton>
            <Menu
                elevation={1}
                keepMounted
                anchorEl={anchorThreeDotsEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorThreeDotsEl) && !isMobile}
                onClose={handleThreeDotsClose}
                style={{top: '6px'}}
                MenuListProps={{sx: {py: '0'}}}
            >
                {/*<MenuItem onClick={handleGoToMain}>*/}
                {/*    {t('Go to Main app')}*/}
                {/*</MenuItem>*/}
                <AvatarMenuTitle name={getName(currentUser.firstName, currentUser.lastName)} email={currentUser.email} isDesktop={false} handleClose={handleThreeDotsClose}>
                    <CommonAvatar name={getName(currentUser.firstName, currentUser.lastName)}
                                  size={'d28'} style={{cursor: 'default'}}/>
                </AvatarMenuTitle>
                <Divider sx={{margin: '0 !important'}}/>
                <TopBarMenuItem isDesktop={false} title={`${LABEL} Portal`} text={t('A centralized hub for employees')} onClick={() => {handleGoToPortal(); handleThreeDotsClose();}}>
                    <PortalIcon width={'26px'}/>
                </TopBarMenuItem>
                <TopBarMenuItem isDesktop={false} title={t('Audit Reports')} text={t('A centralized hub for auditors')} onClick={() => {handleGoToReports(); handleThreeDotsClose();}}>
                    <ReportsIcon width={'26px'}/>
                </TopBarMenuItem>
                <Divider sx={{margin: '0 !important'}}/>
                <MenuItemHoverBlue onClick={() => {handleOpenSelectWorkspaceDialog(); handleThreeDotsClose();}}>
                    <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('Change workspace')}</Typography>
                </MenuItemHoverBlue>
                {/*<MenuItem onClick={() => {handleSelect(PATH_LOCAL_USER_PROFILE)}}>*/}
                {/*    {t('Profile')}*/}
                {/*</MenuItem>*/}
                {/*<MenuItem onClick={() => {handleSelect(PATH_LOCAL_NOTIFICATIONS)}}>*/}
                {/*    {t('Notifications')}*/}
                {/*</MenuItem>*/}
                <MenuItemHoverBlue onClick={() => {handleSelect(PATH_LOCAL_SETTINGS)}}>
                    <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('Settings')}</Typography>
                </MenuItemHoverBlue>
                <MenuItemHoverBlue onClick={openContactUsDialog}>
                    <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('Contact us')}</Typography>
                </MenuItemHoverBlue>
                <MenuItemHoverBlue onClick={() => {handleSelect(SETTINGS_PROFILE_PATH)}}>
                    <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('Profile')}</Typography>
                </MenuItemHoverBlue>
                <MenuItemHoverBlue onClick={() => {handleSelect(SETTINGS_LANGUAGE_PATH)}}>
                    <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('Language')}</Typography>
                </MenuItemHoverBlue>
                {SHOW_CHANGE_LANGUAGE_IN_TOPBAR &&
                    <MenuItemHoverBlue onClick={() => {toggleVisibility(true); handleThreeDotsClose();}}>
                        <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('Change language')}</Typography>
                    </MenuItemHoverBlue>
                }

                <Divider sx={{margin: '0 !important'}}/>

                <MenuItemHoverBlue onClick={() => {handleThreeDotsClose(); logout()}}>
                    <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('Logout')}</Typography>
                </MenuItemHoverBlue>
            </Menu>
            <LanguageChangeDialog isOpen={isOpen} handleClose={toggleVisibility}/>
            <MobileMenuDialog isOpen={Boolean(anchorThreeDotsEl) && isMobile} handleClose={handleThreeDotsClose}/>
            <ContactUsDialog/>
        </>
    ) : null
}