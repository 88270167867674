import {useAccessManagementAppsExact} from "../../hooks/useAccessManagementAppsExact";
import React from "react";
import * as S from '../../components/styled';
import {Flex} from "../../../../../newShared/components/Layouts";
import colors from "../../../../../newShared/theme/colors";
import {Button, Skeleton, Typography} from "@mui/material";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {LoadingButton} from "@mui/lab";
import UploadIcon from "@mui/icons-material/Upload";
import {DeleteRowDialog} from "../../components/dialogs/deleteRow";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {DeleteAppDialog} from "../../components/dialogs/deleteApp";
import {EditAppDialog} from "../../components/dialogs/editApp";
import {PreviewRoleDialog} from "../../components/dialogs/previewRoleDialog";
import {ReleaseAccessManagementApp} from "../../components/dialogs/releaseAccessManagementApp";
import {NonCustomAppAccountMap} from "../../components/dialogs/nonCustomAppAccountMap";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import GetAppIcon from "@mui/icons-material/GetApp";
import {AccessManagementAppAccount} from "../../types";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {getEmployeeText} from "../../helpers";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {useImportCsvAccessManagement} from "../../hooks/dialogHooks/useImportCsv";
import {ImportCsvDialog} from "../../../../../newShared/components/csvImportDialog";
import {ExportCsvDialog} from "../../../../../newShared/components/csvExportDialog";


export const  AccessManagementAppsExact = () => {
    const {
        app,
        isLoading,
        isLoadingFields,
        isEditable,
        actions,
        neededSave,
        isShowingNoRows,
        isLoadingExportCsv,
        isLoadingSave,
        genericTable,
        exportCsv,
    } = useAccessManagementAppsExact();
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.AccessManagementAppsExact'});
    const {isLessThen1050, isMobile} = useMedia();

    const importCsv = useImportCsvAccessManagement();

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={app?.name}
                handleGoBack={actions.handleGoBack}
                showMenuWhen={isLessThen1050}
                actions={[
                    {
                        title: t('Release'),
                        variant: 'contained',
                        color: 'secondary',
                        onClick: actions.handleOpenRelease,
                        disabled: isLoading || neededSave || (app ? !app.dataTable.length : false)
                    },
                    {
                        dontShowWhen: !isEditable,
                        title: t('Add account'),
                        variant: 'contained',
                        onClick: actions.openAddRow,
                        disabled: isLoading || !app,
                    },
                    {
                        title: t('Edit'),
                        variant: 'text',
                        onClick: actions.handleEditApp,
                        disabled: !app,
                        icon: <EditIcon />,
                    },
                    {
                        title: t('Export CSV'),
                        variant: 'text',
                        onClick: actions.handleOpenExportCsv,
                        disabled: isShowingNoRows || neededSave || isLoadingExportCsv,
                        icon: <GetAppIcon />,
                        loading: isLoadingExportCsv,
                    },
                    {
                        dontShowWhen: !isEditable,
                        title: t('Import CSV'),
                        variant: 'text',
                        onClick: actions.handleOpenImportCsv,
                        disabled: !app,
                        icon: <UploadIcon />,
                    },
                    {
                        dontShowWhen: !isEditable,
                        title: t('Delete'),
                        variant: 'text',
                        onClick: actions.handleDeleteApp,
                        disabled: !app,
                        icon: <DeleteIcon />,
                    },
                ]}
            />

            <S.AccessManagementAppOverviewWrapper>
                <Flex cursor={'pointer'} onClick={() => app?.vendorOwner ? actions.handleGoToVendorOwner(app?.vendorOwner.id) : actions.handleEditApp()}>
                    {isLoading ? <Skeleton width={'200px'}/> :
                        <>
                            <Typography variant={"*bodyText2"}  sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', color: colors.lightGrayText}}>{t('Vendor owner')}</Typography>
                            <Typography variant={"*bodyText2"}  sx={{color: app?.vendorOwner ? colors.grayText : colors.errorText}}>{app?.vendorOwner?.name ?? t('Unknown')}</Typography>
                        </>
                    }
                </Flex>

                <Flex m={'8px 0 0 0'}>
                    {isLoading ? <Skeleton width={'200px'}/> :
                        <>
                            <Typography variant={"*bodyText2"}  sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', color: colors.lightGrayText}}>{t('Last update')}</Typography>
                            <Typography variant={"*bodyText2"}  sx={{color: colors.grayText}}>{(parseDateAuto(app?.lastUpdateDate))}</Typography>
                        </>
                    }
                </Flex>

                <Flex m={'8px 0 0 0'}>
                    {isLoading ? <Skeleton width={'200px'}/> :
                        <>
                            <Typography variant={"*bodyText2"}  sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', color: colors.lightGrayText}}>{t('Last review')}</Typography>
                            <Typography variant={"*bodyText2"}  sx={{color: colors.grayText}}>{(parseDateAuto(app?.lastReviewDate))}</Typography>
                        </>
                    }
                </Flex>
            </S.AccessManagementAppOverviewWrapper>

            {neededSave &&
                <Flex w={'100%'} ai={'center'} m={'16px 0 0 0'}>
                    {/*<Button size={isMobile ? 'small' : 'medium'} onClick={actions.handleSave} variant={'contained'}>{('Save changes')}</Button>*/}
                    <LoadingButton
                        variant={'contained'}
                        sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                        loading={isLoadingSave}
                        onClick={actions.handleSave}
                        size={isMobile ? 'small' : 'medium'}
                    >
                        {t('Save')}
                    </LoadingButton>
                    <Button size={isMobile ? 'small' : 'medium'} sx={{margin: !revDir ? '0 0 0 16px' : '0 16px 0 0 '}} onClick={actions.handleRevertChanges} variant={'outlined'}>{('Cancel')}</Button>
                </Flex>
            }

            <GenericTable<AccessManagementAppAccount>
                disableSkeletons
                id={'AccessManagementAppsExact'}
                columnsConfig={{totalName: 'Total accounts', disableColumnsSettings: true, forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: app?.dataTable ?? [],
                    customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.account,
                    onRowClick: () => null,
                    columnsConfigs: [
                        {key: "name", name: t('Employee'), default: true, valueGetter: getEmployeeText},
                        {key: 'accounts', name: t('Account'), default: true, valueGetter: (row) => row.account},
                        {key: 'accountType', name: t('Account type'), default: true, valueGetter: (row) => row.accountType},
                        {key: 'status', name: t('Status'), default: true, valueGetter: (row) => row.status},
                        {key: 'role', name: t('Role'), default: true, valueRenderer: (row) => {
                            return <Flex w={'90%'} direction={'column'}>
                                {row.roles.map(e => {
                                    const moreThen0Roles = e.rolePermissions.length > 0;
                                    return(
                                        <Flex key={e.roleName} w={'100%'} ai={'center'} jc={'space-between'}>
                                            <Typography sx={{color: colors.darkText, cursor: 'pointer', overflow: 'hidden', textOverflow: 'ellipsis'}} variant={'*bodyText2'}>{e.roleName}</Typography>
                                            <Typography
                                                sx={{color: moreThen0Roles ? colors.primary.blue : colors.darkText, cursor: moreThen0Roles ? 'pointer' : 'default', textDecoration: moreThen0Roles ? 'underline' : ''}}
                                                variant={'*bodyText2'}
                                                onClick={() => genericTable.onRoleClick(e.roleName, e.rolePermissions)}
                                            >{e.rolePermissions.length} {t('permissions')}</Typography>
                                        </Flex>
                                    )
                                })}
                            </Flex>
                        }},
                    ],
                    rowActionsConfig: [
                        {title: 'Edit', icon: <EditIcon />, onClick: genericTable.handleEditRow},
                        {title: 'Delete', icon: <DeleteForeverIcon />, onClick: genericTable.handleDeleteRow, dontShow: () => !isEditable}
                    ],
                }}
                filtersConfig={{}}
                emptyArrayImageProps={{type: 'accessManagement', filterNotApplied: {secondaryText: isEditable ? t('Add account') : undefined, text: 'Accounts will appear here', onClick: isEditable ? actions.openAddRow : undefined}}}
            />

            {/*<EmptyArrayImage*/}
            {/*    type={'hrManagement'}*/}
            {/*    isShowing={isShowingNoRows}*/}
            {/*    text={t('Accounts will appear here')}*/}
            {/*    secondaryText={isEditable ? t('Add account') : undefined}*/}
            {/*    onClick={actions.openAddRow}*/}
            {/*    withoutAdd={!isEditable}*/}
            {/*/>*/}

            {/*{!isShowingNoRows &&*/}
            {/*    <>*/}
            {/*        {!isLessThenCustomQuery && <ApplicationRowsTableHeader/>}*/}
            {/*        <S.MainTableScrollWrapper>*/}
            {/*            {*/}
            {/*                isLessThenCustomQuery ?*/}
            {/*                    <>*/}
            {/*                        {app?.dataTable.map(e => <ApplicationAccountRowMobileCard key={e.account} row={e} isEditable={isEditable} currAppId={app?.id ?? ''}/>)}*/}
            {/*                    </>*/}
            {/*                    :*/}
            {/*                    <>*/}
            {/*                        {app?.dataTable.map(e => <ApplicationRowTableRow key={e.account} row={e} isEditable={isEditable} currAppId={app?.id ?? ''}/>)}*/}
            {/*                    </>*/}
            {/*            }*/}
            {/*            {isLoading && (isLessThenCustomQuery ? <ApplicationAccountRowMobileCardSkeleton /> : <ApplicationRowTableRowSkeleton />)}*/}
            {/*        </S.MainTableScrollWrapper>*/}
            {/*    </>*/}
            {/*}*/}

            {/*DIALOGS*/}
            <DeleteRowDialog/>
            <DeleteAppDialog />
            <EditAppDialog />
            <PreviewRoleDialog />
            <ReleaseAccessManagementApp />
            <NonCustomAppAccountMap />

            <ExportCsvDialog
                isOpen={exportCsv.isOpen}
                isLoadingFields={isLoadingFields}
                isLoadingExport={isLoadingExportCsv}
                availableFields={exportCsv.availableFields}
                handleExportCsv={exportCsv.handleExportCsv}
                handleClose={exportCsv.handleClose}
                fieldsMapperKey={"accessManagement"}
            />

            <ImportCsvDialog
                isOpen={importCsv.isOpen}
                fields={importCsv.fields}
                response={importCsv.response}
                setImportDialog={importCsv.setImportDialog}

                isGettingFields={importCsv.isGettingFields}
                isPerformingCsv={importCsv.isPerformingCsv}
                isLoadingTemplate={importCsv.isLoadingTemplate}

                getFields={importCsv.getFields}
                performCsv={importCsv.performCsv}
                downloadTemplate={importCsv.downloadTemplate}
                fieldsMapperKey={importCsv.fieldsMapperKey}

                fileLimitInMB={5}
            />
        </Flex>
    )
}
