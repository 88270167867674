import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import {AddVideoSectionDialogPropsType} from "../../../../../types";
import {Flex} from "../../../../../../../../newShared/components/Layouts";
import {useAddVideoSectionDialog} from "../../../../../hooks/dialogHooks/useAddVideoSectionDialog";
import {MainTrainingContentType} from "../../../../../../../../newShared/GQLTypes";
import {sectionTypeTitleMapper, VIDEO_FILE_DROP_ZONE_ACCEPT} from "../../../../../constants";
import {FileDropZone} from "../../../../../../../../newShared/components/fileDropZone";
import {FileDrop} from "react-file-drop";
import React from "react";
import {LocalSpinner} from "../../../../../../../../newShared/components/Spinner";
import DeleteIcon from '@mui/icons-material/Delete';
import {ReactComponent as TemplateIcon} from '../../../../../../../../newShared/images/documents/template-file.svg';
import colors from "../../../../../../../../newShared/theme/colors";
import {TypographyNew} from "../../../../../../../../newShared/components/TypographyNew";
import {VideoLinkPreview} from "../preview/videoLinkPreview";
import {VideoFilePreview} from "../preview/videoFilePreview";

export const AddVideoSectionDialog = (props: AddVideoSectionDialogPropsType) => {
    const {
        isOpen,
        handleClose,
        formik,
        handleChooseVideoType,
        isOk,
        fileDropZone,
        isLoadingUpload,
        fileActions,
        isUpdate,
        testUri,
        setVideoPlayerError,
    }  = useAddVideoSectionDialog(props);

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{('Create new section by video upload')}</DialogTitle>

            <DialogContent>
                <Flex direction={'column'} gap={'20px'}>
                    <TextField
                        required
                        label={('Section name')}
                        name={'name'}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        onBlur={formik.handleBlur}
                        error={Boolean((formik.touched.name || isUpdate) && formik.errors.name)}
                        helperText={(formik.touched.name || isUpdate) && formik.errors.name}
                        size={'small'}
                    />

                    <Autocomplete
                        options={[MainTrainingContentType.VideoLink, MainTrainingContentType.VideoFile]}
                        getOptionLabel={option => sectionTypeTitleMapper[option]}
                        fullWidth
                        onChange={(e, value) => value && handleChooseVideoType(value)}
                        disabled={isLoadingUpload}
                        value={formik.values.type}
                        onBlur={formik.handleBlur}
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                required
                                label={('Video upload method')}
                                name={'type'}
                                size={'small'}
                            />
                        )}
                    />

                    {formik.values.type === MainTrainingContentType.VideoLink ?
                        <>
                            <TextField
                                required
                                label={('Link to MP4 or WebM file')}
                                name={'data.url'}
                                onChange={formik.handleChange}
                                value={formik.values.data.url ?? ''}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.data?.url && formik.errors.data?.url)}
                                helperText={formik.touched.data?.url && formik.errors.data?.url}
                                size={'small'}
                            />

                            {formik.values.data.url && testUri(formik.values.data.url) && <VideoLinkPreview section={formik.values} onError={(isError) => setVideoPlayerError(isError)}/>}
                        </>
                        :
                        <>
                            {!formik.values.data.fileId?.length && !isLoadingUpload &&
                                <Flex direction={'column'} gap={'8px'}>
                                    <Typography variant={'*bodyText2_semibold'} color={colors.grayText}>{('Training content file')}</Typography>
                                    <Typography variant={'*bodyText2'} color={colors.text.grey}>{('You can add .mp4 or .webm file\'s format. Make sure attached file does not exceed 50 MB.')}</Typography>

                                    <FileDrop onTargetClick={fileDropZone.filePicker} onDrop={fileDropZone.fileHandler}>
                                        <FileDropZone
                                            accept={VIDEO_FILE_DROP_ZONE_ACCEPT}
                                            inputRef={fileDropZone.ref}
                                            fileHandler={fileDropZone.fileHandler}
                                        />
                                    </FileDrop>
                                </Flex>
                            }

                            {isLoadingUpload ? <LocalSpinner />
                                :
                                <>
                                    {formik.values.data.fileId && formik.values.data.name && formik.values.data.fileId.length > 0 &&
                                        <>
                                            <Flex ai={'center'} jc={'space-between'} w={'100%'} border={`1px solid ${colors.stroke.grey}`} br={'6px'} p={'10px 20px 10px 10px'}>
                                                <Flex ai={'center'} gap={'10px'}>
                                                    <TemplateIcon />
                                                    <TypographyNew variant={'*bodyText2'} color={colors.grayText} noWrap>{formik.values.data.name}</TypographyNew>
                                                </Flex>

                                                <IconButton onClick={fileActions.handleDeleteFile} size={'small'} color={'primary'}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Flex>

                                            <VideoFilePreview section={formik.values}/>
                                        </>

                                    }
                                </>
                            }

                        </>
                    }
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button variant={'text'} onClick={handleClose} size={'small'}>{('Close')}</Button>
                <Button variant={'text'} onClick={formik.submitForm} size={'small'} disabled={!isOk}>{('Save')}</Button>
            </DialogActions>
        </Dialog>
    )
}