import * as S from '../styled';
import {Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const EmployeeServicesHistoryHeaderRow = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'employees'});

    return (
        <S.TrainingsTableHeaderWrapper jc={'flex-start'}>
            <S.TableServicesHeaderItemWrapper >
                <Typography variant={'subtitle2'} sx={{color: colors.grayText}}>{t('Application')}</Typography>
            </S.TableServicesHeaderItemWrapper>

            <S.TableServicesHeaderItemWrapper >
                <Typography variant={'subtitle2'} sx={{ color: colors.grayText}}>{t('Email')}</Typography>
            </S.TableServicesHeaderItemWrapper>
        </S.TrainingsTableHeaderWrapper>
    )
}