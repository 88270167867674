import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Skeleton,
    TextField,
    Typography
} from "@mui/material";
import React, {FC, useEffect, useState} from "react";
import {TAssetDataCUSTOM} from "../../../types";
import {useFrameworksDialog} from "../../../hooks/useFrameworksDialog";
import {useControl} from "../../../hooks/useControl";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";


export const StatementDialog: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathFrameworks.pathEvidence'});
    const {tCommon} = useMainTranslation();

    const {statement: {
        isLoading, isOpen, setStatementDialog, evidenceId, assetId, asset,
    }} = useFrameworksDialog();
    const {uploadStatement, getStatement} = useControl();
    const handleClose = () => {setStatementDialog({clear: true})};
    
    const [customText, setText] = useState<string>('');

    useEffect(() => {
        isOpen && evidenceId && assetId && getStatement(assetId);
        !isOpen && setText('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, assetId]);

    return (
        <Dialog open={isOpen} onClose={handleClose}>

            <DialogTitle>
                {assetId
                    ? isLoading && !asset
                        ? <Skeleton/>
                        : asset?.data?.[0]?.name || t('Statement')
                    : t('Add new statement')
                }
            </DialogTitle>

            <DialogContent>
                {!assetId ? (
                    <TextField multiline fullWidth minRows={3} maxRows={5} label={tCommon('Enter text')}
                               sx={{marginTop: '10px'}}
                               onChange={e => {setText(e.target.value)}} value={customText}/>
                ) : (
                    <Typography variant={"body2"}>
                        {isLoading && !asset ? <Skeleton/> : (asset?.data as TAssetDataCUSTOM | undefined)?.[0]?.text || ''}
                    </Typography>
                )}
            </DialogContent>

            <DialogActions>
                <Button onClick={() => {handleClose()}} variant={"text"}>
                    {tCommon('Cancel')}
                </Button>

                {!assetId && (
                    <LoadingButton loading={isLoading} disabled={customText.length === 0}
                                   onClick={() => {customText !== '' && evidenceId && uploadStatement(customText, evidenceId)}}>
                        {tCommon('Save')}
                    </LoadingButton>
                )}
            </DialogActions>

        </Dialog>
    )
}