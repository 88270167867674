import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {dialogs, hideDeleteIntegrationDialog, loadings, selectedApp} from "../../../store/slice";
import {PATH_INTEGRATIONS_ADDED} from "../../../constants";
import {DeleteIntegration} from "../../../store/actions";

export const useDeleteIntegration = () => {
    //root
    const dispatch = useDispatch();
    const history = useHistory();
    //selectors
    const {details, app} = useSelector(selectedApp);
    const {deleteIntegration} = useSelector(dialogs);
    const {isLoadingDeleteIntegration} = useSelector(loadings);

    const handleClose = () => {
        dispatch(hideDeleteIntegrationDialog());
    }

    const handleSubmit = () => {
        if(details && app){
            dispatch(DeleteIntegration({
                appId: details.applicationId, //installed app - application id
                endpointMappingName: app.endpointMappingName,
                onSuccess
            }));
        }
    }

    const onSuccess = () => history.push(PATH_INTEGRATIONS_ADDED);

    return{
        isLoading: isLoadingDeleteIntegration,
        isOpen: deleteIntegration,
        handleClose,
        handleSubmit,
        details
    }
}