//core
import React from "react";
import {useDispatch, useSelector} from "react-redux";


//material
import {smallTarget} from "../../types";
import {dialogs, hideReleaseTarget, loadings, selectedTarget} from "../../store/slice";
import {ReleaseTargetScans} from "../../store/actions";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {ReleaseDialog} from "../../../../../newShared/components/releaseDialog";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

//types

//components

export const ReleaseTargetDialog = () => {
    const {tCommon} = useMainTranslation();
    const {setMessage} = useMessageDialog();
    

    const dispatch = useDispatch();
    const target: smallTarget | null = useSelector(selectedTarget);

    const {isOpen} = useSelector(dialogs).releaseTarget;
    const {isLoadingReleaseTarget} = useSelector(loadings);

    const onSuccess = () => {
        setMessage({title: tCommon('Completed successfully'), message: tCommon('Automation created!')});
    }

    const handleClose = () => {
        dispatch(hideReleaseTarget());
    };

    return target && (
        <ReleaseDialog isOpen={isOpen} handleClose={handleClose} isSubmittingLoading={isLoadingReleaseTarget} onlyEvidence
                       handleSubmit={({evidence}) => {
                           if (target) {
                               dispatch(ReleaseTargetScans({targetId: target.id, evidenceId: evidence.evidenceId, onSuccess}))
                           }
                       }}
        />
    )
}