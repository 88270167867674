import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import {DelegatedTaskAttachedFilesNode} from "./components/delegatedTaskAttachedFilesNode";
import React from "react";
import {ApproveTaskDialog} from "./components/approveTaskDialog";
import {PreviewAllFilesDialog} from "./components/previewAllFilesDialog";
import {DeclineDelegatedTaskDialog} from "./components/declineDelegatedTaskDialog";
import {CancelDelegatedTaskDialog} from "./components/cancelDelegatedTaskDialog";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {LocalSpinner} from "../../../../../newShared/components/Spinner";
import {Flex} from "../../../../../newShared/components/Layouts";
import colors from "../../../../../newShared/theme/colors";
import {MicroTableWithoutBorder} from "../../../../../newShared/components/microTableWithoutBorder";
import {DelegatedTaskStatus} from "../delegatedTaskStatus";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {FrameworksDelegatedTaskStatus} from "../../../../../newShared/GQLTypes";
import {useVicaExactDelegatedTaskPage} from "../../hooks/useVicaExactDelegatedTask";

export const VicaExactDelegatedTaskDialog = (props: {refreshTable: () => void}) => {
    const {tCommon} = useMainTranslation();
    const {
        isOpen,
        isLoadingExact,
        exactDelegatedTask,
        actions,
        id,
        cancelTaskDialog,
        declineTaskDialog,
        previewAllDocsDialog,
        approveTaskDialog,
    } = useVicaExactDelegatedTaskPage(props);

    return (
        <>
            <Dialog open={isOpen} onClose={actions.handleClose}>
                <DialogTitle>{(`Delegated task ${id}`)}</DialogTitle>

                <DialogContent>
                    {isLoadingExact || !exactDelegatedTask ? <LocalSpinner /> :
                        <>
                            <Flex direction={'column'}>
                                <Typography variant={'*bodyText2_semibold'} color={colors.grayText}>{exactDelegatedTask.title}</Typography>
                                <Typography variant={'*bodyText2'} color={colors.grayText} margin={'6px 0 0 0'}>{exactDelegatedTask.description}</Typography>
                                <Divider sx={{margin: '16px 0'}} />

                                <MicroTableWithoutBorder
                                    isLoading={false}
                                    rows={[
                                        {
                                            label: 'Performer:',
                                            text: exactDelegatedTask.performer.fullName,
                                        },
                                        {
                                            label: 'Status:',
                                            text: <Flex><DelegatedTaskStatus status={exactDelegatedTask.status}/></Flex>,
                                        },
                                        {
                                            label: 'Create date:',
                                            text: parseDateAuto(exactDelegatedTask.createdDate),
                                        },
                                        {
                                            label: 'Requester:',
                                            text: exactDelegatedTask.requestor.fullName,
                                        },
                                        {
                                            label: 'Requester comment:',
                                            text: exactDelegatedTask.requestorComment,
                                        }
                                    ]}
                                />
                            </Flex>

                            {(exactDelegatedTask?.status === FrameworksDelegatedTaskStatus.Performed ||
                                    exactDelegatedTask?.status === FrameworksDelegatedTaskStatus.Declined ||
                                    exactDelegatedTask?.status === FrameworksDelegatedTaskStatus.Approved) &&
                                <>
                                    <Flex overflow={'hidden'}>
                                        <MicroTableWithoutBorder
                                            isLoading={false}
                                            rows={[
                                                {
                                                    label: 'Performed date:',
                                                    text: parseDateAuto(exactDelegatedTask?.performDate),
                                                },
                                                {
                                                    label: 'Attached files:',
                                                    text: <DelegatedTaskAttachedFilesNode
                                                        attachments={exactDelegatedTask.data?.attachedFiles ?? []}
                                                        handleDownloadFile={actions.handleDownloadFile}
                                                        handlePreviewFile={actions.handlePreviewFile}
                                                        handlePreviewAll={actions.openPreviewAllFiles}
                                                    />,
                                                },
                                                {
                                                    label: 'Performer comment:',
                                                    text: exactDelegatedTask.performerComment,
                                                }
                                            ]}
                                        />
                                    </Flex>
                                    {exactDelegatedTask.data?.attachedFiles && exactDelegatedTask.data.attachedFiles.length > 0 &&
                                        exactDelegatedTask?.status === FrameworksDelegatedTaskStatus.Performed &&
                                        <>
                                            <Divider sx={{margin: '16px 0'}} />
                                            <Typography variant={'*bodyText2'} color={colors.grayText}>{'By clicking "Approve" you will need to choose which files will be attached to the evidence.'}</Typography>
                                        </>
                                    }
                                </>
                            }

                            {exactDelegatedTask?.status === FrameworksDelegatedTaskStatus.Cancelled &&
                                <>
                                    <Divider sx={{margin: '16px 0'}} />
                                    <MicroTableWithoutBorder
                                        isLoading={false}
                                        rows={[
                                            {
                                                label: 'Resolver:',
                                                text: exactDelegatedTask.resolver?.fullName ?? 'N/A',
                                            },
                                            {
                                                label: 'Resolved date:',
                                                text: parseDateAuto(exactDelegatedTask.resolveDate),
                                            },
                                            {
                                                label: 'Cancel reason:',
                                                text: exactDelegatedTask.resolverComment,
                                            }
                                        ]}
                                    />
                                </>
                            }

                            {exactDelegatedTask?.status === FrameworksDelegatedTaskStatus.Declined &&
                                <>
                                    <Divider sx={{margin: '16px 0'}} />
                                    <MicroTableWithoutBorder
                                        isLoading={false}
                                        rows={[
                                            {
                                                label: 'Resolver:',
                                                text: exactDelegatedTask.resolver?.fullName ?? 'N/A',
                                            },
                                            {
                                                label: 'Resolved date:',
                                                text: parseDateAuto(exactDelegatedTask.resolveDate),
                                            },
                                            {
                                                label: 'Decline reason:',
                                                text: exactDelegatedTask.resolverComment,
                                            }
                                        ]}
                                    />
                                </>
                            }

                            {exactDelegatedTask?.status === FrameworksDelegatedTaskStatus.Approved &&
                                <>
                                    <Divider sx={{margin: '16px 0'}} />
                                    <MicroTableWithoutBorder
                                        isLoading={false}
                                        rows={[
                                            {
                                                label: 'Resolver:',
                                                text: exactDelegatedTask.resolver?.fullName ?? 'N/A',
                                            },
                                            {
                                                label: 'Resolved date:',
                                                text: parseDateAuto(exactDelegatedTask.resolveDate),
                                            },
                                            {
                                                label: 'Files added to evidence:',
                                                text: <DelegatedTaskAttachedFilesNode
                                                    attachments={exactDelegatedTask.data?.attachedFiles?.filter(e => e.addedToEvidence) ?? []}
                                                    handleDownloadFile={actions.handleDownloadFile}
                                                    handlePreviewFile={actions.handlePreviewFile}
                                                    handlePreviewAll={actions.openPreviewAllAddedToEvidenceFiles}
                                                />,
                                            }
                                        ]}
                                    />

                                    <Flex>
                                        <Button
                                            variant={'text'}
                                            size={'small'}
                                            onClick={actions.handleOpenEvidences}
                                        >
                                            {'Uploaded to evidences'}
                                        </Button>
                                    </Flex>
                                </>
                            }
                        </>
                    }
                </DialogContent>

                <DialogActions>
                    <Button variant={'text'} size={'small'} onClick={actions.handleClose}>{tCommon('Close')}</Button>

                    {exactDelegatedTask?.status === FrameworksDelegatedTaskStatus.Created &&
                        <Button size={'small'} onClick={actions.handleOpenCancelTask}>{('Cancel task')}</Button>
                    }

                    {exactDelegatedTask?.status === FrameworksDelegatedTaskStatus.Performed &&
                        <Button size={'small'} variant={'outlined'} onClick={actions.handleOpenDeclineTask}>{('Decline')}</Button>
                    }

                    {exactDelegatedTask?.status === FrameworksDelegatedTaskStatus.Performed &&
                        <Button size={'small'} onClick={actions.handleOpenApproveTask} disabled={Boolean(exactDelegatedTask.data && !exactDelegatedTask.data?.attachedFiles?.length)}>{('Approve')}</Button>
                    }
                </DialogActions>
            </Dialog>

            <CancelDelegatedTaskDialog
                task={exactDelegatedTask}
                {...cancelTaskDialog}
            />

            <DeclineDelegatedTaskDialog
                task={exactDelegatedTask}
                {...declineTaskDialog}
            />

            <PreviewAllFilesDialog
                task={exactDelegatedTask}
                isOpen={previewAllDocsDialog.isOpen}
                attachments={previewAllDocsDialog.attachments}
                handleClose={previewAllDocsDialog.handleClose}
                handlePreviewFile={actions.handlePreviewFile}
                handleDownloadFile={actions.handleDownloadFile}
                isEvidenceAttachments={previewAllDocsDialog.isEvidenceAttachments}
            />

            <ApproveTaskDialog
                task={exactDelegatedTask}
                isOpen={approveTaskDialog.isOpen}
                handleClose={approveTaskDialog.handleClose}
                handlePreviewFile={actions.handlePreviewFile}
                handleDownloadFile={actions.handleDownloadFile}
                handleSubmit={approveTaskDialog.handleApproveTask}
                isLoading={approveTaskDialog.isLoading}
            />
        </>
    )
}
