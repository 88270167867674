import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {usePDFView} from "../../../../../../newShared/components/docViewer/hooks/usePDFView";
import {cleanUpForbiddenWrapperData, createOrgInitialData, organizationsLoadings} from "../../../store/slice";
import React, {useEffect, useState} from "react";
import {
    checkPaymentResponse,
    cookieShortOrganization,
    cookieShortWorkspace,
    createOrganizationType,
    CreateOrgStep,
    TBillingInfo
} from "../../../types";
import {
    allStepsInitial,
    availableBranchesOptions,
    availableTeamSizeOptions,
    BUSINESS_PLAN,
    ENTERPRISE_PLAN,
    FREE_PLAN,
    ISRAEL_COUNTRY_NAME,
    TRANSACTION_CHECK_MAX_TRIES,
    TRANSACTION_CHECK_SLEEP_BETWEEN,
    TRANSACTION_FAILED_STATUS,
    TRANSACTION_PENDING_STATUS,
    TRANSACTION_SUCCESS_STATUS,
    YEAR_PERIOD
} from "../../../constants";
import {UseManageWorkspacesAndOrganizations} from "../../../../cookies/hooks/useManageWorkspacesAndOrganizations";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {validateEmail} from "../../../../../../newShared/utils/text";
import {
    AddOrganizationBusinessWithBilling,
    AddOrganizationBusinessWithoutPayment,
    AddOrganizationEnterprise,
    AddOrganizationFree,
    DeleteNotCompletedOrganization,
    GetCreateOrganizationData
} from "../../../store/actions";
import {PATH_LOCAL_COMPLIANCE_FRAMEWORKS_AVAILABLE} from "../../../../../../newShared/constants";
import {toBase64} from "../../../../../../newShared/utils/base64/base64";
import {calculateDue} from "../../../helpers";
import {Logging} from "../../../../../../newShared/utils/logs/log";
import {checkTransactionStatus} from "../../../api";
import {
    setSharedWorkspacesAndOrganizationsData
} from "../../../../cookies/store/sharedWorkspacesAndOrganizations/slice";

export const useNewOrganizationCreateRefactor = () => {
//init
    const dispatch = useDispatch();
    const history = useHistory();
    const {openEndUserAgreementView} = usePDFView();
    const {
        setCurrentWorkspaceAndOrganizationAfterOrganizationCreate,
        user,
        handleSaveToLocalStorage,
        currData,
        setCookieData
    } = UseManageWorkspacesAndOrganizations();
//selectors
    const isLoadingCreate = useSelector(organizationsLoadings).create;
    const {isLoadingInitialData, notCompletedOrganization, countries, industries, isLoadingDeleteNotCompletedOrg} = useSelector(createOrgInitialData);
//other
    const transactionNumber = notCompletedOrganization?.billing?.transactionNumber ?? null;
//useEffects
    //fetch not completed org and initial data (countries + niches)
    useEffect(() => {
        dispatch(GetCreateOrganizationData());

        if(user){
            //prefilling billing info with user info
            billingForm.setForm({
                ...billingForm.form,
                billingPrimaryInfo: {
                    ...billingForm.form.billingPrimaryInfo,
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName
                }
            })
        }
        // eslint-disable-next-line
    }, []);

    //not completed org received
    useEffect(() => {
        if(!isLoadingInitialData){
            setUnusedChips(industries);
            if(notCompletedOrganization !== null){
                //not completed organization presented - filling forms and going to step 2 (cart)
                const step = allSteps.find(e => e.order === 2); // billing info step to make user regenerate transaction
                if(step){
                    setStep(step);
                    //setting all steps as as completed except finish and payment page
                    setAllSteps([...allSteps.map(e => {return (e.order === 4 || e.order === 3) ? e : {...e, isComplete: true}})]);
                    setForm({
                        // ...notCompletedOrganization,
                        name: notCompletedOrganization.name,
                        logo: notCompletedOrganization.logo,
                        subscription: notCompletedOrganization.subscription,
                        organizationDetails: notCompletedOrganization.organizationDetails,
                        licenseCode: notCompletedOrganization.licenseCode,
                        configuration: {
                            availableCollaboratorsCount: notCompletedOrganization.configuration?.availableCollaboratorsCount ?? 1, //by default creating free org - then on admin this config will be updated
                            availableWorkspaceCount: notCompletedOrganization.configuration?.availableWorkspaceCount ?? 1,//by default creating free org - then on admin this config will be updated
                            availableWizards: notCompletedOrganization.configuration?.availableWizards ?? [] //by default creating free org - then on admin this config will be updated
                        },
                    });
                    if(notCompletedOrganization.billing?.currentTear){
                        billingForm.setForm({
                            period: notCompletedOrganization.billing.currentTear.variant,
                            collaboratorsCount: notCompletedOrganization.billing.currentTear.items.find(e => e.type === 'COLLABORATOR')?.quantity ?? 1,
                            isVat: notCompletedOrganization.billing.currentTear.vatPercentage > 0,
                            billingPrimaryInfo: {
                                firstName: notCompletedOrganization.billing.billingInfo.firstName,
                                lastName: notCompletedOrganization.billing.billingInfo.lastName,
                                address: notCompletedOrganization.billing.billingInfo.address,
                                zip: notCompletedOrganization.billing.billingInfo.zip,
                                country: notCompletedOrganization.billing.billingInfo.country,
                                email: notCompletedOrganization.billing.billingInfo.email,
                            },
                            additionalBillingInfo: notCompletedOrganization.billing.billingInfo.additionalData
                        })
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [isLoadingInitialData]);

    //payment
    useEffect(() => {
        window.addEventListener('message', paymentEventListener);

        return () => {
            window.removeEventListener('message', paymentEventListener);
        }
        //eslint-disable-next-line
    }, [transactionNumber]);

//STATES
    const [allSteps, setAllSteps] = useState<CreateOrgStep[]>(allStepsInitial);
    const [step, setStep] = useState<CreateOrgStep>(allStepsInitial[0]);
    //for enterprise only
    const [warningDialog, setWarningDialog] = useState<boolean>(false);
    const [discardChangesDialog, setDiscardChangesDialog] = useState<boolean>(false);

//COMMON ACTIONS
    const handleClose = () => {
        if(notCompletedOrganization !== null){
            setDiscardChangesDialog(true);
        }else{
            history.push('/');
        }
    }

    const isOk = (stageOrder: number): boolean => {
        switch (stageOrder) {
            case 0:
                return form.name.trim().length > 0 &&
                    form.logo.trim().length > 0 &&
                    form.organizationDetails.country.trim().length > 0 &&
                    form.organizationDetails.address.trim().length > 0 &&
                    form.organizationDetails.website.trim().length > 0
            case 1:
                return form.subscription !== null && isLicenseAgreed;
            case 2:
                if(additionalFields.length > 0){
                    for(let i=0; i < additionalFields.length; i++){
                        if(!(billingForm.form.additionalBillingInfo[additionalFields[i].fieldKey] ?? '').trim().length){
                            return false;
                        }
                    }
                }
                return billingForm.form.collaboratorsCount > 0 &&
                    billingForm.form.collaboratorsCount <= 5 &&
                    billingForm.form.billingPrimaryInfo.country !== null &&
                    billingForm.form.billingPrimaryInfo.zip.trim().length > 0 &&
                    billingForm.form.billingPrimaryInfo.address.trim().length > 0 &&
                    validateEmail(billingForm.form.billingPrimaryInfo.email) &&
                    billingForm.form.billingPrimaryInfo.lastName.trim().length > 0 &&
                    billingForm.form.billingPrimaryInfo.firstName.trim().length > 0
            case 3: return true; //going to finish page only after success payment
            default: return false;
        }
    }

    const goToNextStep = () => {
        //just step movement
        if(isOk(step.order)){
            setAllSteps([...allSteps.map(e => e.order === step.order ? {...e, isComplete: true} : e)]);
            const nextStep = allSteps.find(e => e.order === step.order + 1);
            if(nextStep){
                setStep(nextStep);
            }
        }
    }

    const goToPrevStep = () => {
        if(step.order === 0){
            //on step 0 button close instead of prev
            handleClose();
        }else{
            const prevStep = allSteps.find(e => e.order === step.order - 1);
            if(prevStep){
                setStep(prevStep);
            }
        }
    }

//FORMS
    const {form, setForm, handleChange} = useForm<createOrganizationType>({
        name: localStorage.getItem('signUpOrganizationName') || '', //When user sign ups and fills up the org name - we're saving that in localStorage
        subscription: null,
        logo: '',
        configuration: {
            availableCollaboratorsCount: 1, //by default creating free org - then on admin this config will be updated
            availableWorkspaceCount: 1,//by default creating free org - then on admin this config will be updated
            availableWizards: [] //by default creating free org - then on admin this config will be updated
        },
        licenseCode: '',
        organizationDetails: {
            country: '',
            address: '',
            website: '',
            providingServices: [],
            howBigIsCompany: availableTeamSizeOptions[0],
            isSingleBranch: availableBranchesOptions[0],
            howMuchBranches: '',
            offsetWidth: ''
        }
    });
    //BILLING
    const [isLicenseAgreed, setIsLicenseAgreed] = useState<boolean>(false);
    const billingForm = useForm<TBillingInfo>({
        period: YEAR_PERIOD,
        collaboratorsCount: 1,
        isVat: true,
        billingPrimaryInfo: {
            firstName: user?.firstName ?? '',
            lastName: user?.lastName ?? '',
            address: '',
            country: null,
            zip: '',
            email: user?.email ?? ''
        },
        additionalBillingInfo: {}
    });

//Initial form Actions
    //Country
    const handleSetCountry = (country: string | null) => {
        setForm({...form, organizationDetails: {...form.organizationDetails, country: country ?? ''}});
        billingForm.setForm({
            ...billingForm.form,
            billingPrimaryInfo: {...billingForm.form.billingPrimaryInfo, country},
            isVat: country === ISRAEL_COUNTRY_NAME
        });
    }
    //LOGO PICKUP
    const setFile = async(file: any) => {
        const fileBase64: string = await toBase64(file) as string;
        setForm({...form, logo: fileBase64});
    }
    const ref = React.createRef<HTMLInputElement>();
    const [fileName, setFileName] = useState<string>('');

    const fileHandler = (files: FileList | null) => {
        if(files){
            setFile(files[0]);
            setFileName(files[0].name);
        }
    };

    const filePicker = () => {
        ref.current && ref.current.click();
    };

    const handleDeleteLogo = () => {
        setForm({...form, logo: ''});
        setFileName('');
    }

    //CHIPS
    const [unusedChips, setUnusedChips] = useState<string[]>([]);

    const handleAddChip = (chip: string) => {
        setUnusedChips(unusedChips.filter(e => e !== chip));
        setForm({...form, organizationDetails: {...form.organizationDetails, providingServices: [chip, ...form.organizationDetails.providingServices]}});
    }

    const handleDeleteChip = (chip: string) => {
        setUnusedChips([...unusedChips, chip])
        setForm({...form, organizationDetails: {...form.organizationDetails, providingServices: form.organizationDetails.providingServices.filter(e => e !== chip)}});
    }

    //OTHER
    const handleSetHowBigIsCompany = (howBigIsCompany: string) => {
        setForm({...form, organizationDetails: {...form.organizationDetails, howBigIsCompany}});
    }

    const handleIsSingleBranch = (isSingleBranch: string) => {
        setForm({...form, organizationDetails: {...form.organizationDetails, isSingleBranch}});
    }

    //STEP 2
    const handleSelectPlan = (plan: typeof FREE_PLAN | typeof BUSINESS_PLAN | typeof ENTERPRISE_PLAN) => {
        setForm({...form, subscription: plan});
    }

//Billing form Actions
    const handleSetBillingCountry = (country: string) => {
        billingForm.setForm({
            ...billingForm.form,
            isVat: country === ISRAEL_COUNTRY_NAME,
            billingPrimaryInfo: {...billingForm.form.billingPrimaryInfo, country},
            additionalBillingInfo: {}
        });
    }

    const handleSelectAnnualType = (value: string) => {
        billingForm.setForm({...billingForm.form, period: value});
    }

    const handleIncreaseCollabsCount = () => {
        billingForm.form.collaboratorsCount < 5 && billingForm.setForm(prev => {return {...prev, collaboratorsCount: prev.collaboratorsCount + 1}});
    }

    const handleDecreaseCollabsCount = () => {
        billingForm.form.collaboratorsCount > 1 && billingForm.setForm(prev => {return {...prev, collaboratorsCount: prev.collaboratorsCount - 1}});
    }

    const paymentEventListener = (message: MessageEvent<any>) => {
        if(message.data.type === 'success' && message.data.payload?.success){
            Logging.log(`IFRAME CARD SUCCESS`);
            transactionNumber && checkTransaction(transactionNumber);
        }
    }
//Submit actions
    //submit button on step 1 - choose
    const commonHandleSubmitOnStep1 = () => {
        switch (form.subscription) {
            case FREE_PLAN: handleCreateFreeOrganization(); return;
            case ENTERPRISE_PLAN: handleCreateEnterpriseOrganization(); return;
            case BUSINESS_PLAN: handleCreateBusinessOrganizationWithoutPayment(); return;
            // case BUSINESS_PLAN: goToNextStep(); return;
        }
    }
    const handleCreateFreeOrganization = () => {
        //just creating usual organization
        //after create we have already generated new token - setting cookies/redux data about selection
        //and going to available frameworks
        if(isOk(1)){
            dispatch(AddOrganizationFree({
                    data: {...form, name: form.name.trim()},
                    onSuccess: (workspace: cookieShortWorkspace, organization: cookieShortOrganization) => {
                        setCurrentWorkspaceAndOrganizationAfterOrganizationCreate(
                            {...workspace, collaboratorsCount: 0},
                            {...organization,
                                configuration: {
                                    ...organization.configuration,
                                    availableCollaboratorsRules: []}
                            }
                        );
                        history.push(PATH_LOCAL_COMPLIANCE_FRAMEWORKS_AVAILABLE);
                    }
                })
            );
        }
    }

    const handleCreateEnterpriseOrganization = () => {
        //on first click (Send request on step 1) - opens dialog
        //in the dialog continue button fires this action and we dispatch
        //after create we have already generated new token - setting cookies/redux data about selection
        //and going to available frameworks
        if(isOk(1)){
            if(!warningDialog){
                setWarningDialog(true);
                return;
            }else{
                //warning dialog already opened and this action fired from there - making the thing
                dispatch(AddOrganizationEnterprise({
                        data: {...form, name: form.name.trim()},
                        onSuccess: (workspace: cookieShortWorkspace, organization: cookieShortOrganization) => {
                            setCurrentWorkspaceAndOrganizationAfterOrganizationCreate(
                                {...workspace, collaboratorsCount: 0},
                                {...organization,
                                    configuration: {
                                        ...organization.configuration,
                                        availableCollaboratorsRules: []}
                                }
                            );
                            history.push(PATH_LOCAL_COMPLIANCE_FRAMEWORKS_AVAILABLE);
                        }
                    })
                );
            }
        }
    }

    const handleCreateBusinessOrganizationWithoutPayment = () => {
        //on first click (Send request on step 1) - opens dialog
        //in the dialog continue button fires this action and we dispatch
        //after create we have already generated new token - setting cookies/redux data about selection
        //and going to available frameworks
        if(isOk(1)){
            if(!warningDialog){
                setWarningDialog(true);
                return;
            }else{
                //warning dialog already opened and this action fired from there - making the thing
                dispatch(AddOrganizationBusinessWithoutPayment({
                        data: {...form, name: form.name.trim()},
                        onSuccess: (workspace: cookieShortWorkspace, organization: cookieShortOrganization) => {
                            setCurrentWorkspaceAndOrganizationAfterOrganizationCreate(
                                {...workspace, collaboratorsCount: 0},
                                {...organization,
                                    configuration: {
                                        ...organization.configuration,
                                        availableCollaboratorsRules: []}
                                }
                            );
                            history.push(PATH_LOCAL_COMPLIANCE_FRAMEWORKS_AVAILABLE);
                        }
                    })
                );
            }
        }
    }

    const handleCreateBusinessOrganization = () => {
        //fires by proceed to pay button on step 2
        if(isOk(2) && form.subscription){ //cart and billing info step
            //if not completed organization exists - it will be replaced with new data and transaction recreated
            //if not completed org does not exist - it will be created and transaction too
            //new transactionNumber will be showed in notCompletedOrganization?.billingInfo.transactionNumber after fetch
            //after success - going to step 3 where iframe with gotten transactionNumber will be shown
            dispatch(AddOrganizationBusinessWithBilling({
                data: {...form, subscription: form.subscription!},
                billing: billingForm.form,
                calculatedTotal: due.total,
                onSuccess: goToNextStep
            }))
        }
    }
//OTHER BILLING
    const due = calculateDue(billingForm.form);
    const additionalFields = countries.find(e => e.name === billingForm.form.billingPrimaryInfo.country)?.fields ?? [];
    //PENDING FOR TRANSACTION STATUS
    const [transactionLoading, setTransactionLoading] = useState<boolean>(false);
    const [unsuccessfulPaymentDialog, setUnsuccessfulPaymentDialog] = useState<boolean>(false);
    const [checkPaymentResponseStorage, setCheckPaymentResponseStorage] = useState<checkPaymentResponse | null>(null);
    let checkCounter = 0;

    const checkTransaction = async(_transactionNumber: string) => {
        console.log(`checkTransactionStatus: ${checkCounter} | transactionNumber: ${transactionNumber}`);
        if(_transactionNumber && checkCounter < TRANSACTION_CHECK_MAX_TRIES){
            setTransactionLoading(true);
            checkTransactionStatus(_transactionNumber).then(e => {
                if(e){
                    setCheckPaymentResponseStorage(e);
                    console.log(`RECEIVED: ${JSON.stringify(e)}`);
                    if(e.status !== TRANSACTION_PENDING_STATUS){
                        handleTransactionStatus(e.status, e.workspaceId, e.organizationId);
                    }else{
                        new Promise((resolve) => {
                            setTimeout(() => {
                                resolve('');
                            }, TRANSACTION_CHECK_SLEEP_BETWEEN);
                        }).then(tp => {
                            console.log(`GOT TRANSACTION_FAILED_STATUS - RETRY ${checkCounter}`);
                            checkTransaction(_transactionNumber);
                        })
                    }
                }
                checkCounter++;
            })
        }else{
            //regenerating transaction + show unsuccessful dialog
            handleTransactionStatus(TRANSACTION_FAILED_STATUS, null, null);
        }
    }

    const handleTransactionStatus = (status: string, workspaceId: string | null, organizationId: string | null) => {
        console.log('handleTransactionStatus');
        if(status === TRANSACTION_FAILED_STATUS){
            console.log('TRANSACTION_FAILED_STATUS');
            //regenerating transaction + show unsuccessful dialog
            setTransactionLoading(false);
            checkCounter = 0;
            //regenerate transaction
            handleCreateBusinessOrganization();
            //open fail dialog
            setUnsuccessfulPaymentDialog(true);
        }else if(status === TRANSACTION_SUCCESS_STATUS){
            console.log('TRANSACTION_SUCCESS_STATUS');
            setTransactionLoading(false);
            checkCounter = 0;
            goToNextStep();
        }
    }

    const handleGoToFrameworksAfterSuccessPayment = () => {
        if(checkPaymentResponseStorage){
            setTransactionLoading(false);
            //cleaning forbiddenWrapper data - thus will be refetched in forbiddenWrapper's useEffect
            dispatch(cleanUpForbiddenWrapperData());
            //remove old org and ws from store and cookies
            const newCookies = {...currData, selectedWorkspace: null, selectedOrganization: null};
            setCookieData(newCookies);
            dispatch(setSharedWorkspacesAndOrganizationsData(newCookies));
            //set this data in local storage so forbidden will select those on init on PATH_LOCAL_COMPLIANCE_FRAMEWORKS_AVAILABLE
            user && checkPaymentResponseStorage.workspaceId && checkPaymentResponseStorage.organizationId && handleSaveToLocalStorage(user.publicId, checkPaymentResponseStorage.workspaceId, checkPaymentResponseStorage.organizationId);
            //set lastPath so forbidden wrapper will go here after select workspace
            // user && localStorage.setItem(`${SAVE_PATH_PREFIX}${user.publicId}#${checkPaymentResponseStorage.workspaceId}`, PATH_LOCAL_COMPLIANCE_FRAMEWORKS_AVAILABLE);
            //go to /frameworks/available
            history.push(PATH_LOCAL_COMPLIANCE_FRAMEWORKS_AVAILABLE);
        }
    }

    return{
        commonData: {
            isLoadingCreate,
            isLoadingInitialData,
            isLoadingDeleteNotCompletedOrg,
            allSteps,
            step,
            countries,
            discardChangesDialog: {
                isOpen: discardChangesDialog,
                handleClose: () => setDiscardChangesDialog(false)
            }
        },
        commonActions: {
            handleClose,
            isOk,
            commonHandleSubmitOnStep1,
            handleCreateEnterpriseOrganization,
            handleCreateBusinessOrganization,
            setStep: (_step: CreateOrgStep) => {
                //can not jump directly to payment - have to click proceed to pay to generate transaction (3)
                //ca not jump back on success step (4)
                if(step.order !== 3 && step.order !== 4){
                    setStep(_step);
                }
            },
            goToNextStep,
            goToPrevStep,
            handleDiscardChanges: () => {
                dispatch(DeleteNotCompletedOrganization({
                    onSuccess: () => history.push('/')
                }))
            }
        },
        initialForm: {
            form,
            handleChange,
            handleSetCountry,
            ref,
            fileHandler,
            filePicker,
            fileName,
            handleDeleteLogo,
            unusedChips,
            handleAddChip,
            handleDeleteChip,
            handleSetHowBigIsCompany,
            handleIsSingleBranch,
            //plans
            selectedPlan: {
                selectedFree: form.subscription === FREE_PLAN,
                selectedBusiness: form.subscription === BUSINESS_PLAN,
                selectedEnterprise: form.subscription === ENTERPRISE_PLAN
            },
            handleSelectPlan,
            isLicenseAgreed,
            setIsLicenseAgreed,
            handleOpenEndUserLicenseAgreement:() => dispatch(openEndUserAgreementView()),
            warningDialog,
            setWarningDialog,
            industries
        },
        billing: {
            form: billingForm.form,
            isShowErrorInBillingEmail: (billingForm.form.billingPrimaryInfo.email.trim().length > 0 && !validateEmail(billingForm.form.billingPrimaryInfo.email)),
            handleChange: billingForm.handleChange,
            additionalFields,
            handleSetBillingCountry,
            handleSelectAnnualType,
            handleIncreaseCollabsCount,
            handleDecreaseCollabsCount,
            due,
            paymentEventListener,
            transactionNumber,

            transactionLoading,
            setUnsuccessfulPaymentDialog,
            unsuccessfulPaymentDialog,
            handleGoToFrameworksAfterSuccessPayment
        }
    }
}