import {
    organizationSubInitialState,
    settingsInitialState,
    TNotCompletedOrganization,
    TOrganization,
    TSmallWorkspace,
    TWorkspaceCollaborator,
    TWorkspaceInvite,
    TWorkspaceRole,
} from "../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {
    AcceptInvite,
    AddOrganizationBusinessWithBilling,
    AddOrganizationBusinessWithoutPayment,
    AddOrganizationEnterprise,
    AddOrganizationFree,
    CancelWorkspaceInvite,
    ChangeCurrentUserInfo,
    ChangeCurrentUserPassword,
    CreateRole,
    CreateWorkspace,
    DeclineInvite,
    DeleteNotCompletedOrganization,
    DeleteRole,
    DeleteWorkspace,
    DeleteWorkspaceCollaborator,
    disableGoogleAuthAction,
    GetCreateOrganizationData,
    getCsvFieldsCollaboratorsAction,
    GetInvites,
    GetOrganizationBilling,
    GetOrganizationById,
    GetOrganizations,
    GetSmallOrgsAndWorkspacesForSelect,
    GetWorkspaceById,
    GetWorkspaceCollabs,
    GetWorkspaces,
    importCsvTemplateCollaboratorsAction,
    InviteMemberIntoWorkspace,
    kycSetLimitAction,
    performCsvCollaboratorsAction,
    reconfigureGoogleAuthAction,
    ResendWorkspaceInvite,
    SendSupportMessageAPI,
    TransferOrganizationOwnership,
    UpdateOrganizationBillingInfo,
    UpdateOrganizationLogo,
    UpdateOrganizationName,
    UpdateRole,
    UpdateUserRole,
    UpdateWorkspaceName,
    validate2faCodeAction
} from "./actions";
import {Logging} from "../../../../newShared/utils/logs/log";
import {minMaxLoadedPageDefault} from "../../../../newShared/components/genericTable/constants";
import {minMaxLoadedPageType} from "../../../../newShared/components/genericTable/types";
import {handlePagination} from "../../../../newShared/components/genericTable/helpers";
import {
    addImportCsvActionsToBuilder,
    initialImportCsvDialogState,
    setImportCsvDialog
} from "../../../../newShared/components/csvImportDialog/constants";

export const defaultPageInfo = {
    page: 0,
    count: 0,
    total: 0
}
//state.organizations.dialogs.workspaces

export const initialSubOrganizationsState: organizationSubInitialState = {
    loadings: {
        organizations: {
            exact: false,
            list: false,
            create: false,
            _delete: false,
            changeName: false,
            changeLogo: false,
            //
            getUsers: false,
            getRoles: false,
            getWorkspaces: false,
            updateOrganizationByLicenseCode: false,
            sendUpdateSubRequest: false,
            getBilling: false,
            updateBillingInfo: false,
            //
            inviteUser: false,
            changeCollabRole: {
                isLoading: false,
                prevRole: null,
                publicId: null,
            },
            changeInviteRole: {
                isLoading: false,
                prevRole: null,
                email: null,
            },
            resendInvite: {
                isLoading: false,
                email: null
            },
            cancelInvite: {
                isLoading: false,
                email: null
            },
            sendInvite: false,
            deleteCollab: false,
            transferOwnership: false,
            createWorkspace: false,
        },
        workspaces: {
            list: false,
            exact: false,
            _delete: false,
            changeName: false,
            //
            getUsers: false,
            //
            inviteUser: false,
            changeCollabRole: {
                isLoading: false,
                prevRole: null,
                publicId: null,
            },
            changeInviteRole: {
                isLoading: false,
                prevRole: null,
                email: null,
            },
            resendInvite: {
                isLoading: false,
                email: null
            },
            cancelInvite: {
                isLoading: false,
                email: null
            },
            sendInvite: false,
            deleteCollab: false,
            //
            deleteRole: false,
            addRole: false,
            editRole: false,
            //
            changeKycLimit: false,
            //
            joinWorkspaceByAdmin: false
        }
    },
    dialogs: {
        organizations: {
            editOrganization: false,
            deleteCollaboratorDialog: {
                collab: null,
                isOpen: false,
            },
            transferOwnershipDialog: {
                isOpen: false,
            },
            deleteOrganization: false,
            cancelInvite: false,
            inviteUser: false,
            createWorkspace: false,
        },
        workspaces: {
            userNotFound: {
                isOpen: false,
                email: null,
            },
            deleteCollaboratorDialog: {
                collab: null,
                isOpen: false,
            },
            deleteWorkspace: false,
            cancelInvite: false,
            inviteUser: false,
            changeName: false,
            //
            previewRole: {
                isOpen: false,
                role: null,
            },
            deleteRole: {
                isOpen: false,
                role: null,
            },
            manageCollabRole: {
                isOpen: false,
                collab: null
            },
            managePermission: false,
        },
    },
    organizations: [],
    organizationsPageInfo: defaultPageInfo,
    organizationsMinMaxLoadedPage: minMaxLoadedPageDefault,
    selectedOrganization: null,
    workspaces: [],
    workspacesPageInfo: defaultPageInfo,
    workspacesMinMaxLoadedPage: minMaxLoadedPageDefault,
    selectedWorkspace: null,
    selectedOrganizationData: {
        collabs: [],
        // invites: [],
        billing: null,
    },
    selectedWorkspaceData: {
        collabs: [],
        invites: [],
        workspaceRoles: [],
        availablePermissions: []
    },

}

export const initialState: settingsInitialState = {
    invitesCounter: 0,
    selectWorkspaceDialog: false,
    organizations: initialSubOrganizationsState,
    invites: {
        loading: {
            list: false,
            accept: {
                isLoading: false,
                inviteId: null
            },
            decline: {
                isLoading: false,
                inviteId: null
            },
        },
        invites: [],
        dialogs: {
            declineInvite: {
                isOpen: false,
                invite: null
            },
            acceptInviteSuccessDialog: {
                isOpen: false,
                invite: null
            },
        }
    },
    profile: {
        loadings: {
            isLoadingUpdateProfile: false,
        }
    },
    security: {
        loadings: {
            isLoadingInitiateReconfigure: false, // returns secret
            isLoadingInitiateConfigure: false, // returns secret
            isLoadingConfigure: false, //returns recovery codes,
            isLoadingChangePassword: false,
            isLoadingDisable: false
        },
        recoveryCodes: [], //saving after fetch
        secret: null, //saving after fetch,
        qrCode: null
    },
    language: {
        //for future fetch update language
    },
    forbiddenWrapper: {
        organizations: [],
        is403: false, //when axios will receive 403 in marked endpoint - we will show 403 page instead of current page
        is401: false, //handling 401 to save current path in localStorage
        isLoading: false, //loading short workspaces/organizations
        dialog: false,
        lastPath: '',
        message: '',
        portalAccess: false
    },
    cookieData: null, //data stored in cookies (pulling on init + updating by selecting workspace),
    createOrgInitialData: {
        isLoadingInitialData: false,
        notCompletedOrganization: null,
        countries: [],
        industries: [],
        isLoadingDeleteNotCompletedOrg: false,
    },
    dialogs: {
        ...initialImportCsvDialogState,
    }
}

export const SettingSlice = createSlice({
    name: 'Settings',
    initialState,
    reducers: {
        //organizations
        replaceOrganizationsMinMaxLoadedPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.organizations.organizationsMinMaxLoadedPage = payload;
        },
        replaceWorkspacesMinMaxLoadedPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.organizations.workspacesMinMaxLoadedPage = payload;
        },
        setSelectedOrganization: (slice: settingsInitialState, {payload}: {payload: TOrganization}) => {
            slice.organizations.selectedOrganization = payload;
            slice.organizations.selectedOrganizationData = {
                billing: null,
                collabs: [],
            }
        },
        cleanOrganizations: (slice) => {
            slice.organizations.organizations = [];
            slice.organizations.organizationsPageInfo = defaultPageInfo;
        },
        cleanWorkspaces: (slice) => {
            slice.organizations.workspaces = [];
            slice.organizations.workspacesPageInfo = defaultPageInfo;
        },
        openDeleteOrganization: (slice ) => {slice.organizations.dialogs.organizations.deleteOrganization = true},
        hideDeleteOrganization: (slice ) => {slice.organizations.dialogs.organizations.deleteOrganization = false},

        openTransferOrganization: (slice ) => {slice.organizations.dialogs.organizations.transferOwnershipDialog.isOpen = true},
        hideTransferOrganization: (slice ) => {slice.organizations.dialogs.organizations.transferOwnershipDialog.isOpen = false},

        openEditOrganization: (slice ) => {slice.organizations.dialogs.organizations.editOrganization = true},
        hideEditOrganization: (slice ) => {slice.organizations.dialogs.organizations.editOrganization = false},

        openCreateWorkspace: (slice ) => {slice.organizations.dialogs.organizations.createWorkspace = true},
        hideCreateWorkspace: (slice ) => {slice.organizations.dialogs.organizations.createWorkspace = false},

        // openDeleteCollaboratorDialog: (slice, {payload} : {payload: {collab: TExtendedCollaborator}}) => {
        //     slice.organizations.dialogs.organizations.deleteCollaboratorDialog = {
        //         isOpen: true,
        //         collab: payload.collab,
        //     }
        // },
        // hideDeleteCollaboratorDialog: (slice) => {
        //     slice.organizations.dialogs.organizations.deleteCollaboratorDialog = {
        //         isOpen: false,
        //         collab: null,
        //     }
        // },

        // setIsLoadingResendInvite: (slice, {payload}: {payload: {email: string}}) => {
        //     slice.organizations.loadings.organizations.resendInvite = {isLoading: true, email: payload.email};
        // },
        // setIsLoadingCancelInvite: (slice, {payload}: {payload: {email: string}}) => {
        //     slice.organizations.loadings.organizations.cancelInvite = {isLoading: true, email: payload.email};
        // },

        // setPrevInviteRole: (slice, {payload}: {payload: {prevRole: string, email: string, newRole: string}}) => {
        //     //actions to set it before changing invite role
        //     //to change it on ui and silently send request
        //     //if reqeust fails - return back old role
        //     slice.organizations.selectedOrganizationData.invites = slice.organizations.selectedOrganizationData.invites.map(e => e.emailAddress === payload.email ? {...e, role: payload.newRole} : e);
        //     slice.organizations.loadings.organizations.changeInviteRole = {
        //         isLoading: false,
        //         email: payload.email,
        //         prevRole: payload.prevRole
        //     }
        // },
        // prevInviteRoleRollback: (slice) => {
        //     if(slice.organizations.loadings.organizations.changeInviteRole.email && slice.organizations.loadings.organizations.changeInviteRole.prevRole){
        //         slice.organizations.selectedOrganizationData.invites = slice.organizations.selectedOrganizationData.invites.map(e => e.emailAddress === slice.organizations.loadings.organizations.changeInviteRole.email  ? {...e, role: slice.organizations.loadings.organizations.changeInviteRole.prevRole! } : e);
        //         slice.organizations.loadings.organizations.changeInviteRole = {
        //             isLoading: false,
        //             email: null,
        //             prevRole: null
        //         }
        //     }
        // },

        // setPrevCollabRole: (slice, {payload}: {payload: {prevRole: string, publicId: string, newRole: string}}) => {
        //     //actions to set it before changing invite role
        //     //to change it on ui and silently send request
        //     //if reqeust fails - return back old role
        //     slice.organizations.selectedOrganizationData.collabs = slice.organizations.selectedOrganizationData.collabs.map(e => e.publicId === payload.publicId ? {...e, role: payload.newRole} : e);
        //     slice.organizations.loadings.organizations.changeCollabRole = {
        //         isLoading: false,
        //         publicId: payload.publicId,
        //         prevRole: payload.prevRole
        //     }
        // },
        // prevCollabRoleRollback: (slice) => {
        //     if(slice.organizations.loadings.organizations.changeCollabRole.publicId && slice.organizations.loadings.organizations.changeCollabRole.prevRole){
        //         slice.organizations.selectedOrganizationData.collabs = slice.organizations.selectedOrganizationData.collabs.map(e => e.publicId === slice.organizations.loadings.organizations.changeCollabRole.publicId  ? {...e, role: slice.organizations.loadings.organizations.changeCollabRole.prevRole! } : e);
        //         slice.organizations.loadings.organizations.changeCollabRole = {
        //             isLoading: false,
        //             publicId: null,
        //             prevRole: null
        //         }
        //     }
        // },

        // openInviteUser: (slice) => {slice.organizations.dialogs.organizations.inviteUser = true},
        // hideInviteUser: (slice) => {slice.organizations.dialogs.organizations.inviteUser = false},

        setSelectedWorkspace: (slice, {payload}: {payload: TSmallWorkspace}) => {
            slice.organizations.selectedWorkspace = payload;
        },

        deselectWorkspace: (slice) => {
            slice.organizations.selectedWorkspace = null;
            slice.organizations.selectedWorkspaceData = {
                invites: [],
                collabs: [],
                workspaceRoles: [],
                availablePermissions: []
            }
        },

        openAcceptInviteSuccessDialog: (slice) => {slice.invites.dialogs.acceptInviteSuccessDialog.isOpen = true},
        hideAcceptInviteSuccessDialog: (slice) => {slice.invites.dialogs.acceptInviteSuccessDialog.isOpen = false},

        openDeleteWorkspaceDialog: (slice) => {slice.organizations.dialogs.workspaces.deleteWorkspace = true},
        hideDeleteWorkspaceDialog: (slice) => {slice.organizations.dialogs.workspaces.deleteWorkspace = false},

        openInviteWorkspaceUserDialog: (slice) => {slice.organizations.dialogs.workspaces.inviteUser = true},
        hideInviteWorkspaceUserDialog: (slice) => {slice.organizations.dialogs.workspaces.inviteUser = false},

        openChangeWorkspaceNameDialog: (slice) => {slice.organizations.dialogs.workspaces.changeName = true},
        hideChangeWorkspaceNameDialog: (slice) => {slice.organizations.dialogs.workspaces.changeName = false},

        openDeleteRoleDialog: (slice, {payload}: {payload: TWorkspaceRole}) => {slice.organizations.dialogs.workspaces.deleteRole = {role: payload, isOpen: true}},
        hideDeleteRoleDialog: (slice) => {slice.organizations.dialogs.workspaces.deleteRole = {role: null, isOpen: false}},

        openPreviewRoleDialog: (slice, {payload}: {payload: TWorkspaceRole}) => {slice.organizations.dialogs.workspaces.previewRole = {role: payload, isOpen: true}},
        hidePreviewRoleDialog: (slice) => {slice.organizations.dialogs.workspaces.previewRole = {role: null, isOpen: false}},

        openDeleteWSCollaboratorDialog: (slice, {payload} : {payload: {collab: TWorkspaceCollaborator}}) => {
            slice.organizations.dialogs.workspaces.deleteCollaboratorDialog = {
                isOpen: true,
                collab: payload.collab,
            }
        },
        hideDeleteWSCollaboratorDialog: (slice) => {
            slice.organizations.dialogs.workspaces.deleteCollaboratorDialog = {
                isOpen: false,
                collab: null,
            }
        },

        openManageCollabAccess: (slice, {payload} : {payload: {collab: TWorkspaceCollaborator}}) => {
            slice.organizations.dialogs.workspaces.manageCollabRole = {
                isOpen: true,
                collab: payload.collab,
            }
        },
        hideManageCollabAccess: (slice) => {
            slice.organizations.dialogs.workspaces.manageCollabRole = {
                isOpen: false,
                collab: null,
            }
        },

        openUserNotExistsDialog: (slice, {payload} : {payload: {organizationId: string, email: string}}) => {
            slice.organizations.dialogs.workspaces.userNotFound = {isOpen: true, email: payload.email};
        },
        hideUserNotExistsDialog: (slice) => {slice.organizations.dialogs.workspaces.userNotFound = {isOpen: false, email: null}},

        openManagePermissionsDialog: (slice) => {slice.organizations.dialogs.workspaces.managePermission = true},
        hideManagePermissionsDialog: (slice) => {slice.organizations.dialogs.workspaces.managePermission = false},

        setIsLoadingCancelWSInvite: (slice, {payload}: {payload: {email: string}}) => {
            slice.organizations.loadings.workspaces.cancelInvite = {isLoading: true, email: payload.email};
        },
        setIsLoadingResendWSInvite: (slice, {payload}: {payload: {email: string}}) => {
            slice.organizations.loadings.workspaces.resendInvite = {isLoading: true, email: payload.email};
        },

        setPrevWSInviteRole: (slice, {payload}: {payload: {prevRole: TWorkspaceRole, email: string, newRole: TWorkspaceRole}}) => {
            //actions to set it before changing invite role
            //to change it on ui and silently send request
            //if reqeust fails - return back old role
            slice.organizations.selectedWorkspaceData.invites = slice.organizations.selectedWorkspaceData.invites.map(e => e.emailAddress === payload.email ? {...e, role: payload.newRole} : e);
            slice.organizations.loadings.workspaces.changeInviteRole = {
                isLoading: false,
                email: payload.email,
                prevRole: payload.prevRole
            }
        },
        prevWSInviteRoleRollback: (slice) => {
            if(slice.organizations.loadings.workspaces.changeInviteRole.email && slice.organizations.loadings.workspaces.changeInviteRole.prevRole){
                slice.organizations.selectedWorkspaceData.invites = slice.organizations.selectedWorkspaceData.invites.map(e => e.emailAddress === slice.organizations.loadings.workspaces.changeInviteRole.email  ? {...e, role: slice.organizations.loadings.workspaces.changeInviteRole.prevRole! } : e);
                slice.organizations.loadings.workspaces.changeInviteRole = {
                    isLoading: false,
                    email: null,
                    prevRole: null
                }
            }
        },

        //invites
        openDeclineInviteDialog: (slice, {payload}: {payload: TWorkspaceInvite}) => {
            slice.invites.dialogs.declineInvite = {invite: payload, isOpen: true};
        },
        hideDeclineInviteDialog: (slice) => {slice.invites.dialogs.declineInvite = {invite: null, isOpen: false}},

        eraseDataAfterReturnToOrgsList: (slice) => {
            slice.organizations.selectedOrganizationData.collabs = [];
            // slice.organizations.selectedOrganizationData.invites = [];
            slice.organizations.selectedOrganization = null;
            slice.organizations.selectedWorkspace = null;
            slice.organizations.workspaces = [];
            // slice.organizations.selectedOrganizationData.invites = [];
            slice.organizations.workspacesPageInfo = defaultPageInfo;
        },

        //wrapper
        openSelectWorkspaceDialog: (slice) => {slice.forbiddenWrapper.dialog = true},
        hideSelectWorkspaceDialog: (slice) => {slice.forbiddenWrapper.dialog = false},
        handleSetLastPathForRedirectAfterWSSelect: (slice, {payload}: {payload: string}) => {
            slice.forbiddenWrapper.lastPath = payload;
        },
        setGot403: (slice, {payload}: {payload: {subscription: boolean, permission: boolean, message: string}}) => {
            slice.forbiddenWrapper.is403 = true;
            slice.forbiddenWrapper.message = payload.message;
        },
        reset403: (slice) => {
            slice.forbiddenWrapper.is403 = false;
        },

        cleanUpForbiddenWrapperData: (slice) => {
            slice.forbiddenWrapper = {
                organizations: [],
                is403: false,
                is401: false,
                isLoading: false,
                dialog: false,
                lastPath: '',
                message: '',
                portalAccess: false
            };
            Logging.log(`---forbiddenWrapper cleanUp`);
        },

        updateWorkspaceName: (slice, {payload}: {payload: {organizationId: string, workspaceId: string, workspaceName: string}}) => {
            slice.forbiddenWrapper.organizations = slice.forbiddenWrapper.organizations.map(e => e.id === payload.organizationId ? {
                ...e,
                workspaces: e.workspaces.map(w => w.id === payload.workspaceId ? {...w, name: payload.workspaceName} : w)
            } : e);
        },

        setImportCsvDialogAction: setImportCsvDialog,

        cleanUp: () => initialState,

    },
    extraReducers: builder => {
        addImportCsvActionsToBuilder<settingsInitialState>(builder, getCsvFieldsCollaboratorsAction, performCsvCollaboratorsAction);
        builder
            .addCase(GetInvites.pending, (slice) => {
                slice.invites.loading.list = true;
            })
            .addCase(GetInvites.rejected, (slice) => {
                slice.invites.loading.list = false;
            })
            .addCase(GetInvites.fulfilled, (slice, {payload}) => {
                // console.log(`GetInvites.fulfilled`);
                slice.invites.loading.list = false;
                // console.log(`PAYLOAD: ${JSON.stringify(payload)}`);
                slice.invites.invites = payload;
            })
        //AcceptInvite
            .addCase(AcceptInvite.pending, (slice) => {
                slice.invites.loading.accept.isLoading = true;
            })
            .addCase(AcceptInvite.rejected, (slice) => {
                slice.invites.loading.accept.isLoading = false;
                slice.invites.loading.accept.inviteId = null;
            })
            .addCase(AcceptInvite.fulfilled, (slice, {payload}) => {
                slice.invites.loading.accept.isLoading = false;
                slice.invites.loading.accept.inviteId = null;
                slice.invites.invites = slice.invites.invites.filter(e => e.id !== payload.id);
                slice.invites.dialogs.acceptInviteSuccessDialog.isOpen = true;
                slice.invites.dialogs.acceptInviteSuccessDialog.invite = payload;
            })
        //DeclineInvite
            .addCase(DeclineInvite.pending, (slice) => {
                slice.invites.loading.decline.isLoading = true;
            })
            .addCase(DeclineInvite.rejected, (slice) => {
                slice.invites.loading.decline.isLoading = false;
                slice.invites.loading.accept.inviteId = null;
            })
            .addCase(DeclineInvite.fulfilled, (slice, {payload}) => {
                slice.invites.loading.decline.isLoading = false;
                slice.invites.loading.accept.inviteId = null;
                slice.invites.dialogs.declineInvite = {isOpen: false, invite: null};
                slice.invites.invites = slice.invites.invites.filter(e => e.id !== payload);
            })
        //ChangeCurrentUserInfo
            .addCase(ChangeCurrentUserInfo.pending, (slice) => {
                slice.profile.loadings.isLoadingUpdateProfile = true;
            })
            .addCase(ChangeCurrentUserInfo.rejected, (slice) => {
                slice.profile.loadings.isLoadingUpdateProfile = false;
            })
            .addCase(ChangeCurrentUserInfo.fulfilled, (slice, {payload}) => {
                slice.profile.loadings.isLoadingUpdateProfile = false;
            })
        //ChangeCurrentUserPassword
            .addCase(ChangeCurrentUserPassword.pending, (slice) => {
                slice.security.loadings.isLoadingChangePassword = true;
            })
            .addCase(ChangeCurrentUserPassword.rejected, (slice) => {
                slice.security.loadings.isLoadingChangePassword = false;
            })
            .addCase(ChangeCurrentUserPassword.fulfilled, (slice, {payload}) => {
                slice.security.loadings.isLoadingChangePassword = false;
            })
        //
            .addCase(reconfigureGoogleAuthAction.pending, (slice) => {
                slice.security.loadings.isLoadingInitiateReconfigure = true;
                // slice.app2fa.isGettingSecret = true;
            })
            .addCase(reconfigureGoogleAuthAction.rejected, (slice) => {
                // slice.app2fa.isGettingSecret = false;
                slice.security.loadings.isLoadingInitiateReconfigure = false;

            })
            .addCase(reconfigureGoogleAuthAction.fulfilled, (slice, {payload}) => {
                slice.security.loadings.isLoadingInitiateReconfigure = false;
                slice.security.secret = payload.secret;
                slice.security.qrCode = payload.qrCode;
            })
        //validate2faCodeAction
            .addCase(validate2faCodeAction.pending, (slice) => {
                slice.security.loadings.isLoadingConfigure = true;
            })
            .addCase(validate2faCodeAction.rejected, (slice) => {
                slice.security.loadings.isLoadingConfigure = false;
            })
            .addCase(validate2faCodeAction.fulfilled, (slice, {payload}) => {
                slice.security.loadings.isLoadingConfigure = false;
                slice.security.recoveryCodes = payload;
            })

            .addCase(disableGoogleAuthAction.pending, (slice) => {
                slice.security.loadings.isLoadingDisable = true;
            })
            .addCase(disableGoogleAuthAction.rejected, (slice) => {
                slice.security.loadings.isLoadingDisable = false;
            })
            .addCase(disableGoogleAuthAction.fulfilled, (slice, {payload}) => {
                slice.security.loadings.isLoadingDisable = false;
            })
        //WORKSPACES & ORGS
        //GetOrganizations
            .addCase(GetOrganizations.pending, (slice) => {
                slice.organizations.loadings.organizations.list = true;
            })
            .addCase(GetOrganizations.rejected, (slice) => {
                slice.organizations.loadings.organizations.list = false;
            })
            .addCase(GetOrganizations.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.organizations.list = false;

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<TOrganization>(
                    slice.organizations.organizations,
                    slice.organizations.organizationsPageInfo,
                    payload.organizations,
                    payload.pageInfo,
                    slice.organizations.organizationsMinMaxLoadedPage.minLoadedPage,
                    slice.organizations.organizationsMinMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.organizations.organizations = results;
                slice.organizations.organizationsMinMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.organizations.organizationsPageInfo = payload.pageInfo;
            })
        //GetOrganizationById
            .addCase(GetOrganizationById.pending, (slice) => {
                slice.organizations.loadings.organizations.exact = true;
            })
            .addCase(GetOrganizationById.rejected, (slice) => {
                slice.organizations.loadings.organizations.exact = false;
            })
            .addCase(GetOrganizationById.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.organizations.exact = false;
                slice.organizations.selectedOrganization = payload;
            })
        //GetWorkspaces
            .addCase(GetWorkspaces.pending, (slice) => {
                slice.organizations.loadings.workspaces.list = true;
            })
            .addCase(GetWorkspaces.rejected, (slice) => {
                slice.organizations.loadings.workspaces.list = false;
            })
            .addCase(GetWorkspaces.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.list = false;

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<TSmallWorkspace>(
                    slice.organizations.workspaces,
                    slice.organizations.workspacesPageInfo,
                    payload.workspaces,
                    payload.pageInfo,
                    slice.organizations.workspacesMinMaxLoadedPage.minLoadedPage,
                    slice.organizations.workspacesMinMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.organizations.workspaces = results;
                slice.organizations.workspacesMinMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.organizations.workspacesPageInfo = payload.pageInfo;
                // if (payload.pageInfo.page === 0) {
                //     slice.organizations.workspaces = payload.workspaces;
                // } else {
                //     if (slice.organizations.workspacesPageInfo.page < payload.pageInfo.page) {
                //         slice.organizations.workspaces = [...slice.organizations.workspaces, ...payload.workspaces];
                //     } else {
                //         slice.organizations.workspaces = [...payload.workspaces, ...slice.organizations.workspaces];
                //     }
                // }
                // slice.organizations.workspacesPageInfo = payload.pageInfo;
            })
        //UpdateOrganizationByLicenseCode
        //     .addCase(UpdateOrganizationByLicenseCode.pending, (slice) => {
        //         slice.organizations.loadings.organizations.updateOrganizationByLicenseCode = true;
        //     })
        //     .addCase(UpdateOrganizationByLicenseCode.rejected, (slice) => {
        //         slice.organizations.loadings.organizations.updateOrganizationByLicenseCode = false;
        //     })
        //     .addCase(UpdateOrganizationByLicenseCode.fulfilled, (slice, {payload}) => {
        //         slice.organizations.loadings.organizations.updateOrganizationByLicenseCode = false;
        //         if(slice.organizations.selectedOrganization){
        //             slice.organizations.selectedOrganization = {
        //                 ...slice.organizations.selectedOrganization,
        //                 ...payload
        //             }
        //         }
        //     })
        //SendSupportMessageAPI
            .addCase(SendSupportMessageAPI.pending, (slice) => {
                slice.organizations.loadings.organizations.sendUpdateSubRequest = true;
            })
            .addCase(SendSupportMessageAPI.rejected, (slice) => {
                slice.organizations.loadings.organizations.sendUpdateSubRequest = false;
            })
            .addCase(SendSupportMessageAPI.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.organizations.sendUpdateSubRequest = false;
            })
        //GetOrganizationCollaborators
        //     .addCase(GetOrganizationCollaborators.pending, (slice) => {
        //         slice.organizations.loadings.organizations.getUsers = true;
        //     })
        //     .addCase(GetOrganizationCollaborators.rejected, (slice) => {
        //         slice.organizations.loadings.organizations.getUsers = false;
        //     })
        //     .addCase(GetOrganizationCollaborators.fulfilled, (slice, {payload}) => {
        //         slice.organizations.loadings.organizations.getUsers = false;
        //         slice.organizations.selectedOrganizationData = {
        //             billing: slice.organizations.selectedOrganizationData.billing,
        //             collabs: payload.collaborators,
        //             // invites: payload.invites,
        //         }
        //     })
        //CancelOrganizationInvite
        //     .addCase(CancelOrganizationInvite.pending, (slice) => {
        //         slice.organizations.loadings.organizations.cancelInvite.isLoading = true;
        //     })
        //     .addCase(CancelOrganizationInvite.rejected, (slice) => {
        //         slice.organizations.loadings.organizations.cancelInvite.isLoading = false;
        //     })
        //     .addCase(CancelOrganizationInvite.fulfilled, (slice, {payload}) => {
        //         slice.organizations.loadings.organizations.cancelInvite = {
        //             isLoading: false,
        //             email: null
        //         }
        //         slice.organizations.selectedOrganizationData.invites = slice.organizations.selectedOrganizationData.invites.filter(e => e.id !== payload.inviteId);
        //     })
        // //ResendOrganizationInvite
        //     .addCase(ResendOrganizationInvite.pending, (slice) => {
        //         slice.organizations.loadings.organizations.resendInvite.isLoading = true;
        //     })
        //     .addCase(ResendOrganizationInvite.rejected, (slice, {payload}) => {
        //         slice.organizations.loadings.organizations.resendInvite.isLoading = false;
        //     })
        //     .addCase(ResendOrganizationInvite.fulfilled, (slice, {payload}) => {
        //         slice.organizations.loadings.organizations.resendInvite = {
        //             isLoading: false,
        //             email: null,
        //         };
        //         //TODO update invite (if needed) - now sentDate is not visible so not updating
        //     })
        //UpdateInviteRole
        //     .addCase(UpdateInviteRole.pending, (slice) => {
        //         slice.organizations.loadings.organizations.changeInviteRole.isLoading = true;
        //     })
        //     .addCase(UpdateInviteRole.rejected, (slice, {payload}) => {
        //         slice.organizations.loadings.organizations.changeInviteRole.isLoading = false;
        //     })
        //     .addCase(UpdateInviteRole.fulfilled, (slice, {payload}) => {
        //         slice.organizations.loadings.organizations.changeInviteRole = {
        //             isLoading: false,
        //             email: null,
        //             prevRole: null
        //         };
        //         if(payload){
        //             //updating role in invites array
        //             slice.organizations.selectedOrganizationData.invites = slice.organizations.selectedOrganizationData.invites.map(e => e.emailAddress === payload.email ? {...e, role: payload.newRole} : e);
        //         }
        //     })
        //UpdateCollabRole
        //     .addCase(UpdateCollabRole.pending, (slice) => {
        //         slice.organizations.loadings.organizations.changeCollabRole.isLoading = true;
        //     })
        //     .addCase(UpdateCollabRole.rejected, (slice, {payload}) => {
        //         slice.organizations.loadings.organizations.changeCollabRole.isLoading = false;
        //     })
        //     .addCase(UpdateCollabRole.fulfilled, (slice, {payload}) => {
        //         slice.organizations.loadings.organizations.changeCollabRole = {
        //             isLoading: false,
        //             publicId: null,
        //             prevRole: null
        //         };
        //         if(payload){
        //             //updating role in invites array
        //             slice.organizations.selectedOrganizationData.collabs = slice.organizations.selectedOrganizationData.collabs.map(e => e.publicId === payload.publicId ? {...e, role: payload.newRole} : e);
        //         }
        //     })
        //CreateWorkspace
            .addCase(CreateWorkspace.pending, (slice) => {
                slice.organizations.loadings.organizations.createWorkspace = true;
            })
            .addCase(CreateWorkspace.rejected, (slice, {payload}) => {
                slice.organizations.loadings.organizations.createWorkspace = false;
            })
            .addCase(CreateWorkspace.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.organizations.createWorkspace = false;
                slice.organizations.dialogs.organizations.createWorkspace = false;
                if(slice.organizations.selectedOrganization){
                    slice.organizations.selectedOrganization.workspaceCount = slice.organizations.selectedOrganization.workspaceCount + 1;
                }
                slice.organizations.workspaces.push({
                    id: payload.id,
                    name: payload.name,
                    collaboratorsCount: 1,
                    enabledKycLimit: false,
                })
            })
        //UpdateOrganizationName
            .addCase(UpdateOrganizationName.pending, (slice) => {
                slice.organizations.loadings.organizations.changeName = true;
            })
            .addCase(UpdateOrganizationName.rejected, (slice, {payload}) => {
                slice.organizations.loadings.organizations.changeName = false;
            })
            .addCase(UpdateOrganizationName.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.organizations.changeName = false;
                slice.organizations.dialogs.organizations.editOrganization = false;
                if(slice.organizations.selectedOrganization){
                    slice.organizations.selectedOrganization.name = payload.newName;
                }
            })
        //UpdateOrganizationLogo
            .addCase(UpdateOrganizationLogo.pending, (slice) => {
                slice.organizations.loadings.organizations.changeLogo = true;
            })
            .addCase(UpdateOrganizationLogo.rejected, (slice, {payload}) => {
                slice.organizations.loadings.organizations.changeLogo = false;
            })
            .addCase(UpdateOrganizationLogo.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.organizations.changeLogo = false;
                slice.organizations.dialogs.organizations.editOrganization = false;
                if(slice.organizations.selectedOrganization){
                    slice.organizations.selectedOrganization.logo = payload.newLogo;
                }
            })
        //InviteMemberIntoOrg
        //     .addCase(InviteMemberIntoOrg.pending, (slice) => {
        //         slice.organizations.loadings.organizations.inviteUser = true;
        //     })
        //     .addCase(InviteMemberIntoOrg.rejected, (slice, {payload}) => {
        //         slice.organizations.loadings.organizations.inviteUser = false;
        //     })
        //     .addCase(InviteMemberIntoOrg.fulfilled, (slice, {payload}) => {
        //         slice.organizations.loadings.organizations.inviteUser = false;
        //         slice.organizations.dialogs.organizations.inviteUser = false;
        //         if(slice.organizations.selectedOrganization){
        //             slice.organizations.selectedOrganizationData.invites.push(payload);
        //         }
        //     })
        //TransferOrganizationOwnership
            .addCase(TransferOrganizationOwnership.pending, (slice) => {
                slice.organizations.loadings.organizations.transferOwnership = true;
            })
            .addCase(TransferOrganizationOwnership.rejected, (slice, {payload}) => {
                slice.organizations.loadings.organizations.transferOwnership = false;
            })
            .addCase(TransferOrganizationOwnership.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.organizations.transferOwnership = false;
                slice.organizations.dialogs.organizations.transferOwnershipDialog.isOpen = false;
            })
        //DeleteOrganizationCollaborator
        //     .addCase(DeleteOrganizationCollaborator.pending, (slice) => {
        //         slice.organizations.loadings.organizations.deleteCollab = true;
        //     })
        //     .addCase(DeleteOrganizationCollaborator.rejected, (slice, {payload}) => {
        //         slice.organizations.loadings.organizations.deleteCollab = false;
        //     })
        //     .addCase(DeleteOrganizationCollaborator.fulfilled, (slice, {payload}) => {
        //         slice.organizations.loadings.organizations.deleteCollab = false;
        //         slice.organizations.dialogs.organizations.deleteCollaboratorDialog = {
        //             isOpen: false,
        //             collab: null
        //         };
        //         if(slice.organizations.selectedOrganization){
        //             slice.organizations.selectedOrganizationData.collabs = slice.organizations.selectedOrganizationData.collabs.filter(e => e.publicId !== payload.publicId);
        //         }
        //     })
        //GetWorkspaceById
            .addCase(GetWorkspaceById.pending, (slice) => {
                slice.organizations.loadings.workspaces.exact = true;
            })
            .addCase(GetWorkspaceById.rejected, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.exact = false;
            })
            .addCase(GetWorkspaceById.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.exact = false;
                if(payload){
                    slice.organizations.selectedWorkspace = payload.workspace;
                    slice.organizations.selectedOrganization = payload.organization;
                }
            })
        //
            .addCase(kycSetLimitAction.pending, (slice: settingsInitialState) => {
                slice.organizations.loadings.workspaces.changeKycLimit = true;
            })
            .addCase(kycSetLimitAction.rejected, (slice: settingsInitialState, {payload}) => {
                slice.organizations.loadings.workspaces.changeKycLimit = false;
            })
            .addCase(kycSetLimitAction.fulfilled, (slice: settingsInitialState, {payload: {data, resp}}) => {
                slice.organizations.loadings.workspaces.changeKycLimit = false;

                {
                    const tmp = slice.organizations.workspaces;
                    const index = tmp.findIndex(e => e.id === data.workspaceId);
                    if (index > -1) {
                        tmp[index].enabledKycLimit = resp.isLimitEnabled;
                        slice.organizations.workspaces = tmp;
                    }
                }

                if (slice.organizations.selectedWorkspace && slice.organizations.selectedWorkspace.id === data.workspaceId) {
                    slice.organizations.selectedWorkspace.enabledKycLimit = resp.isLimitEnabled
                }
            })
        //GetWorkspaceCollabs
            .addCase(GetWorkspaceCollabs.pending, (slice) => {
                slice.organizations.loadings.workspaces.getUsers = true;
            })
            .addCase(GetWorkspaceCollabs.rejected, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.getUsers = false;
            })
            .addCase(GetWorkspaceCollabs.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.getUsers = false;
                if(payload){
                    slice.organizations.selectedWorkspaceData = {
                        invites: payload.invite,
                        collabs: payload.collabs,
                        workspaceRoles: payload.workspaceRoles,
                        availablePermissions: payload.availablePermissions
                    };
                }
            })
        //DeleteWorkspaceCollaborator
            .addCase(DeleteWorkspaceCollaborator.pending, (slice) => {
                slice.organizations.loadings.workspaces.deleteCollab = true;
            })
            .addCase(DeleteWorkspaceCollaborator.rejected, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.deleteCollab = false;
            })
            .addCase(DeleteWorkspaceCollaborator.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.deleteCollab = false;
                slice.organizations.dialogs.workspaces.deleteCollaboratorDialog = {
                    isOpen: false,
                    collab: null
                }
                if(slice.organizations.selectedWorkspace){
                    slice.organizations.selectedWorkspace.collaboratorsCount = slice.organizations.selectedWorkspace.collaboratorsCount - 1;
                }
                slice.organizations.selectedWorkspaceData.collabs = slice.organizations.selectedWorkspaceData.collabs.filter(e => e.publicId !== payload);

            })
        //UpdateWorkspaceName
            .addCase(UpdateWorkspaceName.pending, (slice) => {
                slice.organizations.loadings.workspaces.changeName = true;
            })
            .addCase(UpdateWorkspaceName.rejected, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.changeName = false;
            })
            .addCase(UpdateWorkspaceName.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.changeName = false;
                slice.organizations.dialogs.workspaces.changeName = false;
                if(slice.organizations.selectedWorkspace){
                    slice.organizations.selectedWorkspace.name = payload.newName;
                }
            })
        //DeleteWorkspace
            .addCase(DeleteWorkspace.pending, (slice) => {
                slice.organizations.loadings.workspaces._delete = true;
            })
            .addCase(DeleteWorkspace.rejected, (slice, {payload}) => {
                slice.organizations.loadings.workspaces._delete = false;
            })
            .addCase(DeleteWorkspace.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.workspaces._delete = false;
                slice.organizations.dialogs.workspaces.deleteWorkspace = false;
                if(slice.organizations.selectedWorkspace){
                    slice.organizations.selectedWorkspace = null;
                    slice.organizations.selectedWorkspaceData = {
                        collabs: [],
                        invites: [],
                        workspaceRoles: [],
                        availablePermissions: []
                    }
                }
                if(slice.organizations.selectedOrganization){
                    slice.organizations.selectedOrganization.workspaceCount = slice.organizations.selectedOrganization.workspaceCount - 1;
                }
            })
        //InviteMemberIntoWorkspace
            .addCase(InviteMemberIntoWorkspace.pending, (slice) => {
                slice.organizations.loadings.workspaces.inviteUser = true;
            })
            .addCase(InviteMemberIntoWorkspace.rejected, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.inviteUser = false;
            })
            .addCase(InviteMemberIntoWorkspace.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.inviteUser = false;
                slice.organizations.dialogs.workspaces.inviteUser = false;
                // slice.organizations.dialogs.workspaces.userNotFound = {isOpen: false, email: null};
                if(payload){
                    slice.organizations.selectedWorkspaceData = {
                        invites: payload.invite,
                        collabs: payload.collabs,
                        workspaceRoles: payload.workspaceRoles,
                        availablePermissions: payload.availablePermissions
                    };
                }
            })
        //UpdateUserRole
            .addCase(UpdateUserRole.pending, (slice) => {
                slice.organizations.loadings.workspaces.changeCollabRole.isLoading = true;
            })
            .addCase(UpdateUserRole.rejected, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.changeCollabRole.isLoading = false;
            })
            .addCase(UpdateUserRole.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.changeCollabRole.isLoading = false;
                slice.organizations.dialogs.workspaces.manageCollabRole.isOpen = false;
                if(slice.organizations.selectedWorkspace){
                    slice.organizations.selectedWorkspaceData.collabs = slice.organizations.selectedWorkspaceData.collabs.map(e => e.publicId === payload.publicId ? {...e, roles: payload.roles} : e);
                }
            })
        //ResendWorkspaceInvite
            .addCase(ResendWorkspaceInvite.pending, (slice) => {
                slice.organizations.loadings.workspaces.resendInvite.isLoading = true;
            })
            .addCase(ResendWorkspaceInvite.rejected, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.resendInvite.isLoading = false;
            })
            .addCase(ResendWorkspaceInvite.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.resendInvite = {
                    isLoading: false,
                    email: null
                }
            })
        //CancelWorkspaceInvite
            .addCase(CancelWorkspaceInvite.pending, (slice) => {
                slice.organizations.loadings.workspaces.cancelInvite.isLoading = true;
            })
            .addCase(CancelWorkspaceInvite.rejected, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.cancelInvite.isLoading = false;
            })
            .addCase(CancelWorkspaceInvite.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.cancelInvite = {
                    isLoading: false,
                    email: null
                }
                slice.organizations.selectedWorkspaceData.invites = slice.organizations.selectedWorkspaceData.invites.filter(e => e.id !== payload.inviteId);
            })
        //ChangeWSInviteRole
        //     .addCase(ChangeWSInviteRole.pending, (slice) => {
        //         slice.organizations.loadings.workspaces.changeInviteRole.isLoading = true;
        //     })
        //     .addCase(ChangeWSInviteRole.rejected, (slice, {payload}) => {
        //         slice.organizations.loadings.workspaces.changeInviteRole.isLoading = false;
        //     })
        //     .addCase(ChangeWSInviteRole.fulfilled, (slice, {payload}) => {
        //         slice.organizations.loadings.workspaces.changeInviteRole = {
        //             isLoading: false,
        //             email: null,
        //             prevRole: null,
        //         }
        //     })
        //CreateRole
            .addCase(CreateRole.pending, (slice) => {
                slice.organizations.loadings.workspaces.addRole = true;
            })
            .addCase(CreateRole.rejected, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.addRole = false;
            })
            .addCase(CreateRole.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.addRole = false;
                if(payload){
                    slice.organizations.selectedWorkspaceData.workspaceRoles.push(payload);
                }
            })
        //DeleteRole
            .addCase(DeleteRole.pending, (slice) => {
                slice.organizations.loadings.workspaces.deleteRole = true;
            })
            .addCase(DeleteRole.rejected, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.deleteRole = false;
            })
            .addCase(DeleteRole.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.deleteRole = false;
                slice.organizations.dialogs.workspaces.deleteRole = {
                    isOpen: false,
                    role: null
                }
                if(payload){
                    slice.organizations.selectedWorkspaceData.workspaceRoles = slice.organizations.selectedWorkspaceData.workspaceRoles.filter(e => e.id !== payload.id)
                }
            })
        //UpdateRole
            .addCase(UpdateRole.pending, (slice) => {
                slice.organizations.loadings.workspaces.editRole = true;
            })
            .addCase(UpdateRole.rejected, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.editRole = false;
            })
            .addCase(UpdateRole.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.workspaces.editRole = false;
                if(payload){
                    //updating roles array
                    slice.organizations.selectedWorkspaceData.workspaceRoles = slice.organizations.selectedWorkspaceData.workspaceRoles.map(e => e.id === payload.id ? payload : e);
                    //updating user roles
                    slice.organizations.selectedWorkspaceData.collabs = slice.organizations.selectedWorkspaceData.collabs.map(e => {
                        return {
                            ...e,
                            roles: e.roles.map(r => r.id === payload.id ? payload : r)
                        }
                    })
                }
            })
        //GetSmallOrgsAndWorkspacesForSelect
            .addCase(GetSmallOrgsAndWorkspacesForSelect.pending, (slice) => {
                slice.forbiddenWrapper.isLoading = true;
            })
            .addCase(GetSmallOrgsAndWorkspacesForSelect.rejected, (slice, {payload}) => {
                slice.forbiddenWrapper.isLoading = false;
            })
            .addCase(GetSmallOrgsAndWorkspacesForSelect.fulfilled, (slice, {payload}) => {
                slice.forbiddenWrapper.isLoading = false;
                slice.forbiddenWrapper.organizations = payload.organizations;
                slice.forbiddenWrapper.portalAccess = payload.portalAccess;
            })
        //JoinWorkspaceByOrgAdmin
        //     .addCase(JoinWorkspaceByOrgAdmin.pending, (slice) => {
        //         slice.organizations.loadings.workspaces.joinWorkspaceByAdmin = true;
        //     })
        //     .addCase(JoinWorkspaceByOrgAdmin.rejected, (slice, {payload}) => {
        //         slice.organizations.loadings.workspaces.joinWorkspaceByAdmin = false;
        //
        //     })
        //     .addCase(JoinWorkspaceByOrgAdmin.fulfilled, (slice, {payload}) => {
        //         slice.organizations.loadings.workspaces.joinWorkspaceByAdmin = false;
        //         slice.organizations.selectedWorkspaceData.collabs.push(payload);
        //
        //         if(slice.organizations.selectedWorkspace){
        //             slice.organizations.selectedWorkspace.collaboratorsCount = slice.organizations.selectedWorkspace.collaboratorsCount + 1;
        //         }
        //     })
        //GetCreateOrganizationData
            .addCase(GetCreateOrganizationData.pending, (slice) => {
                slice.createOrgInitialData.isLoadingInitialData = true;
            })
            .addCase(GetCreateOrganizationData.rejected, (slice, {payload}) => {
                slice.createOrgInitialData.isLoadingInitialData = false;
            })
            .addCase(GetCreateOrganizationData.fulfilled, (slice, {payload}) => {
                slice.createOrgInitialData = {
                    isLoadingInitialData: false,
                    isLoadingDeleteNotCompletedOrg: false,
                    ...payload
                }
            })
        //AddOrganizationFree
            .addCase(AddOrganizationFree.pending, (slice) => {
                slice.organizations.loadings.organizations.create = true;
            })
            .addCase(AddOrganizationFree.rejected, (slice) => {
                slice.organizations.loadings.organizations.create = false;
            })
            .addCase(AddOrganizationFree.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.organizations.create = false;
            })
        //AddOrganizationEnterprise
            .addCase(AddOrganizationEnterprise.pending, (slice) => {
                slice.organizations.loadings.organizations.create = true;
            })
            .addCase(AddOrganizationEnterprise.rejected, (slice) => {
                slice.organizations.loadings.organizations.create = false;
            })
            .addCase(AddOrganizationEnterprise.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.organizations.create = false;
            })
        //AddOrganizationBusinessWithoutPayment
            .addCase(AddOrganizationBusinessWithoutPayment.pending, (slice) => {
                slice.organizations.loadings.organizations.create = true;
            })
            .addCase(AddOrganizationBusinessWithoutPayment.rejected, (slice) => {
                slice.organizations.loadings.organizations.create = false;
            })
            .addCase(AddOrganizationBusinessWithoutPayment.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.organizations.create = false;
            })
        //AddOrganizationBusinessWithBilling
            .addCase(AddOrganizationBusinessWithBilling.pending, (slice) => {
                slice.organizations.loadings.organizations.create = true;
            })
            .addCase(AddOrganizationBusinessWithBilling.rejected, (slice) => {
                slice.organizations.loadings.organizations.create = false;
            })
            .addCase(AddOrganizationBusinessWithBilling.fulfilled, (slice, {payload}) => {
                slice.organizations.loadings.organizations.create = false;
                if (slice.createOrgInitialData.notCompletedOrganization){
                    slice.createOrgInitialData.notCompletedOrganization.billing = {
                        ...slice.createOrgInitialData.notCompletedOrganization.billing,
                        transactionNumber: payload.billing.transactionNumber,
                    };
                }else{
                    //user first time clicked proceed to pay - there is slice.createOrgInitialData.notCompletedOrganization is null
                    //and we have to put new transactionNumber only to open iframe - all other info is already in states of useNewOrganizationCreateRefactor
                    const newOrg: TNotCompletedOrganization = {
                        ...payload,
                        configuration: {
                            availableCollaboratorsCount: 0,
                            availableWorkspaceCount: 0,
                            availableWizards: []
                        },
                        licenseCode: null,
                        billing: {
                            transactionNumber: payload.billing.transactionNumber,
                            billingInfo: payload.billing.billingInfo,
                            paymentHistory: [],
                            paymentMethod: null,
                            paymentPeriodStart: '',
                            futurePayments: [],
                            currentTear: null
                        }
                    }
                    slice.createOrgInitialData.notCompletedOrganization = newOrg;
                }
            })
        //DeleteNotCompletedOrganization
            .addCase(DeleteNotCompletedOrganization.pending, (slice) => {
                slice.createOrgInitialData.isLoadingDeleteNotCompletedOrg = true;
            })
            .addCase(DeleteNotCompletedOrganization.rejected, (slice) => {
                slice.createOrgInitialData.isLoadingDeleteNotCompletedOrg = false;
            })
            .addCase(DeleteNotCompletedOrganization.fulfilled, (slice, {payload}) => {
                slice.createOrgInitialData = {
                    ...slice.createOrgInitialData,
                    isLoadingDeleteNotCompletedOrg: false,
                    notCompletedOrganization: null,
                    isLoadingInitialData: false,
                }
            })
        //GetOrganizationBilling
            .addCase(GetOrganizationBilling.pending, (slice: settingsInitialState) => {
                slice.organizations.loadings.organizations.getBilling = true;
            })
            .addCase(GetOrganizationBilling.rejected, (slice:settingsInitialState) => {
                slice.organizations.loadings.organizations.getBilling = false;
            })
            .addCase(GetOrganizationBilling.fulfilled, (slice: settingsInitialState, {payload}) => {
                slice.organizations.loadings.organizations.getBilling = false;
                slice.organizations.selectedOrganizationData.billing = payload;
            })
        //UpdateOrganizationBillingInfo
            .addCase(UpdateOrganizationBillingInfo.pending, (slice: settingsInitialState) => {
                slice.organizations.loadings.organizations.updateBillingInfo = true;
            })
            .addCase(UpdateOrganizationBillingInfo.rejected, (slice:settingsInitialState) => {
                slice.organizations.loadings.organizations.updateBillingInfo = false;
            })
            .addCase(UpdateOrganizationBillingInfo.fulfilled, (slice: settingsInitialState, {payload}) => {
                slice.organizations.loadings.organizations.updateBillingInfo = false;
                slice.organizations.selectedOrganizationData.billing = payload;
            })

        // //importCsvTemplateCollaboratorsAction
            .addCase(importCsvTemplateCollaboratorsAction.pending, (slice: settingsInitialState) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = true;
            })
            .addCase(importCsvTemplateCollaboratorsAction.rejected, (slice: settingsInitialState) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = false;
            })
            .addCase(importCsvTemplateCollaboratorsAction.fulfilled, (slice: settingsInitialState, {payload}) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = false;
            })


    }
});

export const SettingsReducer = SettingSlice.reducer;

export const {
    //orgs
    setSelectedOrganization,
    cleanOrganizations,
    cleanWorkspaces,

    openDeleteOrganization,
    hideDeleteOrganization,

    openTransferOrganization,
    hideTransferOrganization,

    openEditOrganization,
    hideEditOrganization,

    openCreateWorkspace,
    hideCreateWorkspace,

    openInviteWorkspaceUserDialog,
    hideInviteWorkspaceUserDialog,

    openDeleteWorkspaceDialog,
    hideDeleteWorkspaceDialog,

    openChangeWorkspaceNameDialog,
    hideChangeWorkspaceNameDialog,

    openPreviewRoleDialog,
    hidePreviewRoleDialog,

    openDeleteRoleDialog,
    hideDeleteRoleDialog,


    //invites
    openDeclineInviteDialog,
    hideDeclineInviteDialog,

    // openDeleteCollaboratorDialog,
    // hideDeleteCollaboratorDialog,

    // setIsLoadingResendInvite,
    // setIsLoadingCancelInvite,
    //
    // setPrevInviteRole,
    // prevInviteRoleRollback,
    //
    // setPrevCollabRole,
    // prevCollabRoleRollback,

    // openInviteUser,
    // hideInviteUser,
    eraseDataAfterReturnToOrgsList,

    setSelectedWorkspace,
    deselectWorkspace,

    openDeleteWSCollaboratorDialog,
    hideDeleteWSCollaboratorDialog,

    openManageCollabAccess,
    hideManageCollabAccess,

    // openUserNotExistsDialog,
    hideUserNotExistsDialog,

    openManagePermissionsDialog,
    hideManagePermissionsDialog,

    setIsLoadingCancelWSInvite,
    setIsLoadingResendWSInvite,

    // setPrevWSInviteRole,
    // prevWSInviteRoleRollback,
    setPrevWSInviteRole,
    prevWSInviteRoleRollback,
    setImportCsvDialogAction,
    //language

    //security

    //wrapper
    openSelectWorkspaceDialog,
    hideSelectWorkspaceDialog,
    handleSetLastPathForRedirectAfterWSSelect,
    setGot403,
    reset403,

    cleanUpForbiddenWrapperData,

    updateWorkspaceName,
    cleanUp,

    openAcceptInviteSuccessDialog,
    hideAcceptInviteSuccessDialog,

    replaceWorkspacesMinMaxLoadedPage,
    replaceOrganizationsMinMaxLoadedPage
} = SettingSlice.actions;

const selectSelf = (state: AppState):settingsInitialState => state.Settings as settingsInitialState;

export const invitesCounter = createSelector(selectSelf, state => state.invitesCounter);
export const selectWorkspaceDialog = createSelector(selectSelf, state => state.selectWorkspaceDialog);

//orgs
export const organizationsDialogs = createSelector(selectSelf, state => state.organizations.dialogs.organizations);
export const organizationsLoadings = createSelector(selectSelf, state => state.organizations.loadings.organizations);
export const organizationPageInfo = createSelector(selectSelf, state => state.organizations.organizationsPageInfo);
export const organizations = createSelector(selectSelf, state => state.organizations.organizations);
export const selectedOrganization = createSelector(selectSelf, state => state.organizations.selectedOrganization);
export const selectedOrganizationData = createSelector(selectSelf, state => state.organizations.selectedOrganizationData);



export const workspacesDialogs = createSelector(selectSelf, state => state.organizations.dialogs.workspaces);
export const workspacesLoadings = createSelector(selectSelf, state => state.organizations.loadings.workspaces);
export const workspacesPageInfo = createSelector(selectSelf, state => state.organizations.workspacesPageInfo);
export const workspaces = createSelector(selectSelf, state => state.organizations.workspaces);
export const selectedWorkspace = createSelector(selectSelf, state => state.organizations.selectedWorkspace);
export const selectedWorkspaceData = createSelector(selectSelf, state => state.organizations.selectedWorkspaceData);


//invites
export const invites = createSelector(selectSelf, state => state.invites.invites);
export const invitesDialogs = createSelector(selectSelf, state => state.invites.dialogs);
export const invitesLoadings = createSelector(selectSelf, state => state.invites.loading);
export const acceptInviteSuccessDialog = createSelector(selectSelf, state => state.invites.dialogs.acceptInviteSuccessDialog);

//profile
export const profileLoadings = createSelector(selectSelf, state => state.profile.loadings);

//security
export const securityLoadings = createSelector(selectSelf, state => state.security.loadings);
export const securitySecret = createSelector(selectSelf, state => state.security.secret);
export const qrCode = createSelector(selectSelf, state => state.security.qrCode);
export const recoveryCodes = createSelector(selectSelf, state => state.security.recoveryCodes);

//forbidden wrapper
export const forbiddenWrapper = createSelector(selectSelf, state => state.forbiddenWrapper);
export const forbiddenWrapperOrganizations = createSelector(selectSelf, state => state.forbiddenWrapper.organizations);
export const forbiddenWrapperIsLoading = createSelector(selectSelf, state => state.forbiddenWrapper.isLoading);
export const is403 = createSelector(selectSelf, state => state.forbiddenWrapper.is403);
export const is401 = createSelector(selectSelf, state => state.forbiddenWrapper.is401);

//create org data
export const createOrgInitialData = createSelector(selectSelf, state => state.createOrgInitialData);

export const organizationsMinMaxLoadedPage = createSelector(selectSelf, state => state.organizations.organizationsMinMaxLoadedPage);
export const workspacesMinMaxLoadedPage = createSelector(selectSelf, state => state.organizations.workspacesMinMaxLoadedPage);

export const dialogsSelector = createSelector(selectSelf, state => state.dialogs);