import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {GetHrEmployeesSettings} from "../../store/actions";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {cleanUp} from "../../store/slice";

export const useGetHrSettings = () => {
    const dispatch = useDispatch();
    const {clearFilters} = useGenericFiltersStorage();

    const controller = new AbortController();

    useEffect(() => {
        dispatch(GetHrEmployeesSettings({signal: controller.signal, data: {}}));

        return () => {
            controller.abort();
            clearFilters();
            dispatch(cleanUp());
        }
        //eslint-disable-next-line
    }, []);
}