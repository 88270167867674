import {useDispatch, useSelector} from "react-redux";
import {
    dialogs,
    hideAddHistoryRowDialog,
    hrSettings,
    loadings,
    openAddSettingDialog,
    selectedEmployee
} from "../../../store/slice";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {
    TEmployeeCreateJobHistory,
    TEmployeeJobHistory,
    THrSettings,
    THrSettingsJobTitle,
    TManager
} from "../../../types";
import {
    Autocomplete,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    TextField
} from "@mui/material";
import {AutoComplete, DialogContentWrapper} from "../../styled";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import React, {Fragment, useEffect} from "react";
import {defaultJobHistoryForm} from "../../../constants";
import AddIcon from "@mui/icons-material/Add";
import colors from "../../../../../../newShared/theme/colors";
import {AddSettingsDialog} from "../addSettingsDialog";
import {
    DEFAULT_DATE_FORMAT,
    isFutureDate,
    isSameDay,
    removeTimeZoneDate
} from "../../../../../../newShared/utils/dateTools";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {AddJobRecord} from "../../../store/actions";
import debounce from "lodash.debounce";
import {useManagersAutocomplete} from "../../../hooks/useManagersAutocomplete";


export const AddJobRecordDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathHRManagement.dialogs'});
    const {tCommon} = useMainTranslation();

    const dispatch = useDispatch();
    const {currentLanguage} = useMainTranslation();
    const {isLoadingManagers, managers, getManagers} = useManagersAutocomplete();
    //sel
    const isOpen = useSelector(dialogs).addHistoryRowDialog;
    const isLoading = useSelector(loadings).createJobRecord;
    const empl = useSelector(selectedEmployee);
    const {division, location, employmentType, jobTitle, department} = useSelector(hrSettings);

    const handleClose = () => {
        dispatch(hideAddHistoryRowDialog());
    }

    const handleSubmit = () => {
        if(isOk()){
            dispatch(AddJobRecord({
                data: {
                    id: empl!.id, //checked in isOk,
                    jobRecord: {...form, id: '', current: false, startDate: removeTimeZoneDate(form.startDate)} as TEmployeeJobHistory //because of isOk() - there are no nulls in object, so its equals toTEmployeeJobHistory
                }
            }))
        }
    }

    const {form, setValue, setForm} = useForm<TEmployeeCreateJobHistory>(defaultJobHistoryForm);

    const isOk = ():boolean => {
        return empl !== null && form.startDate !== null &&
            form.division !== null &&
            form.location !== null &&
            form.employmentType !== null &&
            form.jobTitle !== null &&
            form.department !== null &&
            !isAlreadyExists() &&
            !isFutureDate(form.startDate, currentLanguage.momentLocale)
    }

    const isAlreadyExists = ():boolean => {
        return empl !== null && empl.jobInfos.some(e => isSameDay(e.startDate, form.startDate, currentLanguage.momentLocale))
    }
    //

    useEffect(() => {
        if(isOpen) {
            setForm(defaultJobHistoryForm);
        }
        //eslint-disable-next-line
    }, [isOpen])

    //
    const openAddJobTitle = () => {
        dispatch(openAddSettingDialog({type: 'jobTitle'}));
    }
    const openAddEmploymentType = () => {
        dispatch(openAddSettingDialog({type: 'employmentType'}));
    }
    const openAddDepartment = () => {
        dispatch(openAddSettingDialog({type: 'department'}));
    }
    const openAddLocation = () => {
        dispatch(openAddSettingDialog({type: 'location'}));
    }
    const openAddDivision = () => {
        dispatch(openAddSettingDialog({type: 'division'}));
    }

    const handleJobTitleAdd = (job: THrSettingsJobTitle) => {
        setForm({...form, jobTitle: job})
    }

    const handleSettingAdd = (type: keyof THrSettings, value: string, id?: number) => {
        switch (type){
            case "jobTitle": {
                const currJob = jobTitle.find(e => e.name === value);
                if(currJob){
                    setForm({...form, jobTitle: currJob})
                }
                return;
            }
            case "employmentType": {
                setForm({...form, employmentType: value})
                return;
            }
            case "department": {
                setForm({...form, department: value})
                return;
            }
            case "location": {
                setForm({...form, location: value})
                return;
            }
            case "division": {
                setForm({...form, division: value})
                return;
            }
        }
    }

    //

    const AddJobTitleLink = ({ children, ...other }: any) => (
        <Paper {...other}>
            <Button onMouseDown={openAddJobTitle} variant="text" startIcon={<AddIcon fontSize={'small'}/>} sx={{color: colors.primary.blue, width: '100%'}}>
                {t('Add new')}
            </Button>
            {children}
        </Paper>
    );

    const AddDepartmentLink = ({ children, ...other }: any) => (
        <Paper {...other}>
            <Button onMouseDown={openAddDepartment} variant="text" startIcon={<AddIcon fontSize={'small'}/>} sx={{color: colors.primary.blue, width: '100%'}}>
                {t('Add new')}
            </Button>
            {children}
        </Paper>
    );

    const AddEmploymentTypeLink = ({ children, ...other }: any) => (
        <Paper {...other}>
            <Button onMouseDown={openAddEmploymentType} variant="text" startIcon={<AddIcon fontSize={'small'}/>} sx={{color: colors.primary.blue, width: '100%'}}>
                {t('Add new')}
            </Button>
            {children}
        </Paper>
    )

    const AddDivisionLink = ({ children, ...other }: any) => (
        <Paper {...other}>
            <Button onMouseDown={openAddDivision} variant="text" startIcon={<AddIcon fontSize={'small'}/>} sx={{color: colors.primary.blue, width: '100%'}}>
                {t('Add new')}
            </Button>
            {children}
        </Paper>
    )

    const AddLocationLink = ({ children, ...other }: any) => (
        <Paper {...other}>
            <Button onMouseDown={openAddLocation} variant="text" startIcon={<AddIcon fontSize={'small'}/>} sx={{color: colors.primary.blue, width: '100%'}}>
                {t('Add new')}
            </Button>
            {children}
        </Paper>
    )

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Add job record')}</DialogTitle>

            <DialogContent>
                <DialogContentWrapper>
                    <DatePicker
                        label={t('Affective date')}
                        value={form.startDate}
                        inputFormat={DEFAULT_DATE_FORMAT}
                        disableFuture
                        onChange={(newValue) => {setValue('startDate', newValue)}}
                        shouldDisableDate={(_date) => empl !== null && empl.jobInfos.some(e => isSameDay(e.startDate, _date, currentLanguage.momentLocale))}
                        renderInput={(params) => <TextField
                            {...params}
                            size={'small'}
                            required
                            // error={_isAlreadyExists}
                            // helperText={_isAlreadyExists ? 'Job record with such affective date already exists' : undefined}
                        />}
                    />

                    <Autocomplete
                        options={jobTitle}
                        getOptionLabel={(o) => o.name}
                        groupBy={(o) => o.jobType!} //jobType nullable only for create - by default non-empty
                        value={form.jobTitle}
                        onChange={(e, newValue) => setValue('jobTitle', newValue)}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        sx={{margin: '16px 0 0 0'}}
                        renderInput={(params) => <TextField {...params} label={t('Job title')} size={'small'} required/>}
                        PaperComponent={AddJobTitleLink}
                    />

                    <Autocomplete
                        options={Object.keys(employmentType)}
                        value={form.employmentType}
                        onChange={(e, newValue) => setValue('employmentType', newValue)}
                        sx={{margin: '16px 0 0 0'}}
                        renderInput={(params) => <TextField {...params} label={t('Employment type')} size={'small'} required/>}
                        PaperComponent={AddEmploymentTypeLink}
                    />

                    <Autocomplete
                        options={Object.keys(department)}
                        value={form.department}
                        onChange={(e, newValue) => setValue('department', newValue)}
                        sx={{margin: '16px 0 0 0'}}
                        renderInput={(params) => <TextField {...params} label={t('Department')} size={'small'} required/>}
                        PaperComponent={AddDepartmentLink}
                    />

                    <Autocomplete
                        options={Object.keys(location)}
                        value={form.location}
                        onChange={(e, newValue) => setValue('location', newValue)}
                        sx={{margin: '16px 0 0 0'}}
                        renderInput={(params) => <TextField {...params} label={t('Location')} size={'small'} required/>}
                        PaperComponent={AddLocationLink}
                    />

                    <Autocomplete
                        options={Object.keys(division)}
                        value={form.division}
                        onChange={(e, newValue) => setValue('division', newValue)}
                        sx={{margin: '16px 0 0 0'}}
                        renderInput={(params) => <TextField {...params} label={t('Division')} size={'small'} required/>}
                        PaperComponent={AddDivisionLink}
                    />

                    <AutoComplete
                        options={managers}
                        getOptionLabel={(e: unknown) => (e as TManager).name}
                        value={form.manager}
                        onChange={(event, value) => setValue("manager", value as TManager)}
                        onInputChange={debounce((e, value: string) => {getManagers(value)}, 500)}
                        loading={isLoadingManagers}
                        sx={{margin: '16px 0 0 0'}}
                        renderInput={(params) => (
                            <TextField {...params} size={'small'} label={t('Manager')}
                                       InputProps={{
                                           ...params.InputProps,
                                           endAdornment: (
                                               <Fragment>
                                                   {isLoadingManagers ? <CircularProgress color="inherit" size={20} /> : null}
                                                   {params.InputProps.endAdornment}
                                               </Fragment>
                                           ),
                                       }}/>
                        )}
                    />
                </DialogContentWrapper>
            </DialogContent>

            <DialogActions sx={{gap: '16px'}}>
                <Button onClick={handleClose} variant={'text'}>{tCommon('Close')}</Button>
                <LoadingButton
                    onClick={handleSubmit}
                    disabled={!isOk()}
                    loading={isLoading}
                >
                    {tCommon('Add')}
                </LoadingButton>
            </DialogActions>

            <AddSettingsDialog
                onSuccess={handleSettingAdd}
                onSuccessJobTitleAdd={handleJobTitleAdd}
            />
        </Dialog>
    )
}