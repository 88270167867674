import React from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import colors from "../../../../../../newShared/theme/colors";
import {normalizeSettingIdNameToLowerCase} from "../../../helpers";
import {useDeleteSetting} from "../../../hooks/useVendorsDialogs/DeleteSetting";

export const DeleteDialog = () => {
    const {
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        type
    } = useDeleteSetting()
    const {revDir, t} = useMainTranslation('',{keyPrefix:'pathVendors.VendorsSettings'});
    const {isMobile} = useMedia();

    return(
        <Dialog open={isOpen} onClose={handleClose}>
        {type &&
    <>
        <DialogTitle>{t(`Delete {{name}}`,{name: t(normalizeSettingIdNameToLowerCase(type))})}</DialogTitle>

        <DialogContent>
            <Typography variant={'body2'} color={colors.darkBlueText}>
                {t(`If you delete information about an item, you will not be able to restore it.`)}
            </Typography>
        </DialogContent>

        <DialogActions>

            <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{t('Cancel')}</Button>
            {isLoading ?
                <LoadingButton
                    loading
                    sx={{paddingLeft: '35px', margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    variant="outlined"
                >{t('Confirm')}</LoadingButton>
                :
                <Button
                    variant={'contained'}
                    size={isMobile ? 'small' : 'medium'}
                    onClick={handleSubmit}
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                >{t('Confirm')}</Button>

            }
    </DialogActions>
    </>
}
    </Dialog>
    )
}