//core
import axiosSync from "../../../../newShared/utils/axios/axios";

//configs
import {PATH_SERVER} from '../../../../newShared/constants';
import {profilesMappingSchemaType} from "../types";
import {AxiosResponse} from "axios";
import {buildCommonHeader} from "../../../../newShared/utils/builders";

//types


export const getConfig = async (workspaceId: string) => (
    await axiosSync.then(
        (axios) => axios.post(
          PATH_SERVER,
            {
                query: `
                query($workspaceId: String!){
                  getConfig(workspaceId:$workspaceId){
                    config{
                      accountsMappingSchema
                      profilesMappingSchema{
                        appName
                        groupsName
                      }
                    }
                    configValues{
                      accountsMappingSchema
                      profilesMappingSchema{
                        appName
                        groupsName
                      }
                    }
                  }
                }
                `,
                variables: {
                  workspaceId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getConfig;
    })
)

export const updateConfig = async (workspaceId: string, config: {accountsMappingSchema: string, profilesMappingSchema: profilesMappingSchemaType[]}) => (
    await axiosSync.then(
        (axios) => axios.post(
          PATH_SERVER,
            {
                query: `
                mutation($workspaceId: String!, $data: ConfigInput!){
                  updateConfig(workspaceId:$workspaceId, data:$data){
                    message
                  }
                }
                `,
                variables: {
                    workspaceId,
                    data: {
                        accountsMappingSchema: config.accountsMappingSchema,
                        profilesMappingSchema: config.profilesMappingSchema
                    }
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateConfig;
    })
)

