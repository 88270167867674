import {dueType, futurePayment, TBilling, TBillingInfo, TPaymentHistory} from "./types";
import {
    BUSINESS_COLLABORATOR_MONTH_COST,
    CALCULATE_NUMBER_AFTER_COMMA,
    ISRAEL_VAT_PERCENTAGE,
    YEAR_DISCOUNT_PERCENTAGE,
    YEAR_PERIOD
} from "./constants";
import {parseDateAuto} from "../../../newShared/utils/dateTools";
import moment from 'moment';


export const getSub = (subscription: string) => {
    switch(subscription){
        case 'SUBSCRIPTION_ENTERPRISE': return 'Enterprise';
        case 'SUBSCRIPTION_BUSINESS': return 'Business';
        case 'SUBSCRIPTION_BUSINESS_PLUS': return 'Business+';
        case 'SUBSCRIPTION_CONSULTANT': return 'Consultant';
        case 'SUBSCRIPTION_AUDITOR': return 'Auditor';
        case 'SUBSCRIPTION_FREE': return 'Free';
        default: return 'Subscription is not defined...'
    }
}

export const calculateDue = (billingInfo: TBillingInfo): dueType => {
    //getting full amount (if payment per month - full price, if per year - full prise * 12)
    const amount: number = billingInfo.period === YEAR_PERIOD ?
        BUSINESS_COLLABORATOR_MONTH_COST * billingInfo.collaboratorsCount * 12 //full year
        :
        BUSINESS_COLLABORATOR_MONTH_COST * billingInfo.collaboratorsCount; //month
    //get discount -
    const yearPlanDiscount: number = billingInfo.period === YEAR_PERIOD ? parseFloat((amount * YEAR_DISCOUNT_PERCENTAGE).toFixed(2)) : 0; //discount if year tear
    const subtotal: number = amount - yearPlanDiscount; //full amount - discount if year plan
    //calculating vat from subtotal (price after discount * 0.17)
    const vat: number = billingInfo.isVat ? parseFloat((subtotal * (ISRAEL_VAT_PERCENTAGE / 100)).toFixed(CALCULATE_NUMBER_AFTER_COMMA)) : 0; //vat in israel only
    //calculating total - subTotal + vat
    const total: number = parseFloat((subtotal + vat).toFixed(2));
    return {
        amount,
        yearPlanDiscount,
        subtotal,
        vat,
        total
    }
}

export const calculateNextPayment = (billing: TBilling | null, momentLocale: string): string => {
    if(!billing) return 'N/A';
    const sorted = billing.futurePayments.map(e => e.futurePaymentDate).sort((a, b) => {
        if(!moment.isDate(a) || !moment.isDate(b)) return 0;
        return moment(a).isBefore(moment(b)) ? -1 : 1
    });
    return sorted.length > 0 ? parseDateAuto(sorted[0]) : 'N/A';
}

export const calculateFullAmountByItems = (futurePayment: futurePayment | TPaymentHistory): number => {
    const amount = futurePayment.items.map(e => e.cost * e.quantity).reduce((a, b) => a + b, 0);
    const yearPlanDiscount: number = futurePayment.variant === YEAR_PERIOD ? parseFloat((amount * YEAR_DISCOUNT_PERCENTAGE).toFixed(2)) : 0; //discount if year tear
    const subtotal: number = amount - yearPlanDiscount; //full amount - discount if year plan
    const vat: number = futurePayment.vatPercentage > 0 ? parseFloat((subtotal * (ISRAEL_VAT_PERCENTAGE / 100)).toFixed(CALCULATE_NUMBER_AFTER_COMMA)) : 0; //vat in israel only
    return parseFloat((subtotal + vat).toFixed(2));
}

export const getCardExpiry = (expiry: string | null | undefined): string => {
    if(!expiry) return 'N/A';
    return `${expiry.slice(0, 2)}/${expiry.slice(2, 4)}`
}