import {useMainTranslation} from "../../hooks/useMainTranslationHooks/useMainTranslation";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import {ComponentProps, FC} from "react";
import { SvgIcon } from '@mui/material';


type TSectionArrow = ComponentProps<typeof SvgIcon> & {
    isOpen: boolean
}


export const SectionArrow: FC<TSectionArrow> = ({isOpen, ...props}) => {

    const {revDir} = useMainTranslation('');

    return isOpen ? <ArrowDropDownIcon {...props}/> : !revDir ? <ArrowRightIcon {...props} /> : <ArrowLeftIcon {...props} />
}
