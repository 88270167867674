import {Flex} from "../../../../../newShared/components/Layouts";
import React from "react";
import {Button, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {useTargetsAdd} from "../../hooks/useTargetsAdd";
import {CustomInput} from "../../../../../newShared/components/Inputs";
import * as S from '../../components/styled';
import CommonLoading from "../../../../../newShared/components/Basic/CommonLoading";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";

export const AddTarget = () => {
    const {
        isLoading,
        revDir,
        form,
        isOk,
        handleChange,
        handleGoBack,
        handleCreateTarget
    } = useTargetsAdd();
    const {currData} = UseManageWorkspacesAndOrganizations();
    const {t} = useMainTranslation('', {keyPrefix: 'vulnerabilityScanner'});


    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('New target')}
                handleGoBack={handleGoBack}
                actions={[]}
            />

            {isLoading ?
                <S.CreateTargetLoaderWrapper>
                    <CommonLoading/>
                </S.CreateTargetLoaderWrapper>
                :
                <Flex ai={'center'} m={'32px 0 0 0'}>
                    <Button variant={'contained'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} disabled={!isOk()} onClick={handleCreateTarget}>{t('Save')}</Button>
                    <Button variant={'outlined'} onClick={handleGoBack} disabled={isLoading}>{t('Cancel')}</Button>
                </Flex>
            }

            <Typography sx={{color: colors.grayText, margin: '16px 0 0 0'}}>{t('You can add up to {{counter}} targets for scan.', {counter: currData.selectedOrganization?.configuration.availableTargetsVulnerabilityScan ?? 0})}</Typography>

            <S.CreateTargetInputsWrapper>
                <CustomInput
                    required
                    label={t('Target Name')}
                    value={form.name}
                    onChange={(e) => handleChange(e)}
                    name="name"
                    disabled={isLoading}
                />

                <CustomInput
                    required
                    label={t('Target destination')}
                    sx={{margin: '16px 0 0 0'}}
                    value={form.target}
                    helperText={<Typography sx={{fontSize: '10px', fontWeight: 400, color: colors.text.grey}}>{t('Domain or IP address. For example http//domain.com or https//domain.com or 142.250.185.174')}</Typography>}
                    onChange={(e) => handleChange(e)}
                    name="target"
                    disabled={isLoading}
                />
            </S.CreateTargetInputsWrapper>
        </Flex>
    )
}