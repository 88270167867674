import {Flex} from "../../../../../newShared/components/Layouts";
import {IconButton, TableCell, TableRow} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import React from "react";
import {NameCountModel} from "../../../../../newShared/GQLTypes";


export const ExactSettingTableRow = ({setting, handleDelete, handleChange}: {setting:NameCountModel, handleDelete: (value: NameCountModel) => void, handleChange: (value: NameCountModel) => void, }) => {

    return(
        <TableRow>
            <TableCell>{setting.name}</TableCell>
            <TableCell>{setting.count}</TableCell>
            <TableCell>
                <Flex ai={'center'} gap={'4px'}>
                    <IconButton onClick={() => handleChange(setting)} size={'small'} color={'primary'}>
                        <EditIcon/>
                    </IconButton>

                    <IconButton onClick={() => handleDelete(setting)} size={'small'} color={'primary'}>
                        <DeleteForeverIcon/>
                    </IconButton>
                </Flex>
            </TableCell>
        </TableRow>
    )
}