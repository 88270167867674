import {
    useCampaignReviewExecutorsList
} from "../../../../hooks/campaignReviewHooks/executors/useCampaignReviewExecutorsList";
import {Flex} from "../../../../../../../newShared/components/Layouts";
import React from "react";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../../../newShared/hooks/useMedia";
import {PageTitleAndActions} from "../../../../../../../newShared/components/pageTitleAndActions";
import {smallCampaignReviewExecutorType} from "../../../../types";
import {calculateCurrentStage, getColorAndTextByStage, getColorAndTextByStatus} from "../../../../helpers";
import {Typography} from "@mui/material";
import {GenericTable} from "../../../../../../../newShared/components/genericTable";
import {parseDateAuto} from "../../../../../../../newShared/utils/dateTools";

export const CampaignReviewExecutorsList = () => {
    const {selectors, actions, genericTable} = useCampaignReviewExecutorsList();
    const {isMobile} = useMedia(858);
    const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.CampaignReviewExecutorsList'});

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('Review campaign')}
                actions={[]}
            />

            <GenericTable<smallCampaignReviewExecutorType>
                id={'CampaignReviewExecutorsList'}
                columnsConfig={{totalName: 'Total review campaigns', disableColumnsSettings: true, forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: selectors.executors,
                    // customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id,
                    onRowClick: genericTable.onRowClick,
                    columnsConfigs: [
                        {key: "name", name: t('Name'), default: true},
                        {key: 'startDate', name: t('Start date'), default: true, valueGetter: (row) => parseDateAuto(row.runDate)},
                        {key: 'status', name: t('Status'), default: true, valueRenderer: (row) => {
                                const status = getColorAndTextByStatus(row.status);
                                return(
                                    <Typography color={status.color} variant={'*bodyText2'}>{t(`statuses.${status.text}`)}</Typography>
                                )
                            }},
                        {key: 'stage', name: t('Stage'), default: true, valueRenderer: (row) => {
                                const status = getColorAndTextByStage(calculateCurrentStage(row.stages));
                                return(
                                    <Typography color={status.color} variant={'*bodyText2'}>{t(`stages.${status.text}`)}</Typography>
                                )
                            }},
                    ],
                }}
                filtersConfig={{}}
                emptyArrayImageProps={
                    {type: 'campaignReviewExecutors',
                        filterNotApplied: {
                                secondaryText: 'You have not added or run any configurations yet. Create new by clicking add button or run created one',
                                text: 'No configurations found',
                                onClick: actions.handleGoToCreateInstruction
                        }
                    }
                }
            />

            {/*<EmptyArrayImage*/}
            {/*    type={'campaignReviewExecutors'}*/}
            {/*    isShowing={selectors.isNoExecutorsFound}*/}
            {/*    text={t('No configurations found')}*/}
            {/*    secondaryText={t('You have not added or run any configurations yet. Create new by clicking add button or run created one')}*/}
            {/*    onClick={actions.handleGoToCreateInstruction}*/}
            {/*/>*/}

            {/*{!selectors.isNoExecutorsFound &&*/}
            {/*    <>*/}
            {/*        <CustomTableHeader*/}
            {/*            hideIf={isLessThenCustomQuery}*/}
            {/*            columns={[{name: t('Name')}, {name: t('Start date')}, {name: t('Status')}, {name: t('Stage')}]}*/}
            {/*        />*/}
            {/*        <S.MainTableScrollWrapper>*/}
            {/*            <InfiniteScroll*/}
            {/*                pageStart={0}*/}
            {/*                loadMore={(page) => actions.handleLoadMore(page)}*/}
            {/*                hasMore={selectors.hasMore}*/}
            {/*                initialLoad={false}*/}
            {/*                threshold={50}*/}
            {/*                useWindow={false}*/}
            {/*                style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}*/}
            {/*            >*/}
            {/*                {*/}
            {/*                    isLessThenCustomQuery ?*/}
            {/*                        <>*/}
            {/*                            {selectors.executors.map(e => <ExecutorMobileCard key={e.id} executor={e}/>)}*/}
            {/*                        </>*/}
            {/*                        :*/}
            {/*                        <>*/}
            {/*                            {selectors.executors.map(e => <ExecutorTableRow key={e.id} executor={e}/>)}*/}
            {/*                        </>*/}
            {/*                }*/}
            {/*                {selectors.isLoadingList && (isLessThenCustomQuery ? <ExecutorMobileCardSkeleton /> : <ExecutorTableRowSkeleton />)}*/}
            {/*            </InfiniteScroll>*/}
            {/*        </S.MainTableScrollWrapper>*/}
            {/*    </>*/}
            {/*}*/}
        </Flex>
    )
}