import React, {FC} from 'react';
import {PageWrapper} from "../../../../../newShared/components/Layouts";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_COLLECTORS} from "../../../../../newShared/constants";
import {useScrollPagination} from "../../../../../newShared/hooks/useScrollPagination";
import {useCollectors} from "../../hooks/useCollectors";
import {TableRowSkeleton} from "../../../../../newShared/components/tableRowSkeleton";
import {useRoutes} from "../../hooks/useRoutes";
import {EmptyArrayImage} from "../../../../../newShared/components/EmptyArrayImage";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import colors from "../../../../../newShared/theme/colors";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";


export const CollectorsList: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathCollectors'});
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    const {goToCollector} = useRoutes();

    // const {filter} = useParameters();
    const {
        collectors: {
            collectors,
            pageInfo: {page, count, total},
            isLoading
        },
        getCollectors,
        controller,
        clean,
    } = useCollectors();

    //First loadData
    // useEffect(() => {
    //     getCollectors({page, count})
    //     return () => {
    //         // clean(['array'])
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    //Breadcrumbs
    useSetBreadcrumbs([
        {
            title: tMenu('Collectors'),
            path: PATH_LOCAL_COLLECTORS
        },
    ]);

    //Pagination and filtering
    const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => {
            getCollectors({
                page,
                count,
                // filters: filter
            })
        },
        arrayLength: collectors.length,
        isLoading: isLoading,
        pageInfo: {page, count, total},
        cleanData: () => {clean(['collectors'])},
        controller: controller,
        isOnTop: false,
        isNoMoreThanTotal: true,
        // customDependencies: [frId],
        // is0PageAddictive: frId !== framework?.id,
    })

    // const {value: searchValue, handleChange} = useSearchFieldURL({wait: 0});
    // const {setFilter, handleKeyDown} = useFilterUrl({
    //     getData: (filters) => {getCollectors({page: 0, count, filters})}
    // });

    return (
        <PageWrapper>
            <PageTitleAndActions
                title={t('Collectors')}
                actions={[]}
            />

            <TableContainer onScroll={onScroll} ref={scrollRef}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Name')}</TableCell>
                            <TableCell>{t('Last run status')}</TableCell>
                            <TableCell>{t('Last run')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {collectors.map((collector) => collector && (
                            <TableRow key={collector.id} onClick={() => {goToCollector(collector.id)}} hover>
                                <TableCell>{collector.name || '-'}</TableCell>
                                <TableCell sx={{color: collector.lastRunStatus === 'SUCCESS' ? colors.warningActions.green : colors.warningActions.red}}>
                                    {collector.lastRunStatus ? collector.lastRunStatus?.toUpperCase() : 'NEVER'}</TableCell>
                                <TableCell>{parseDateAuto(collector.lastRun)}</TableCell>
                            </TableRow>
                        ))}

                        {(bottomLoader || isLoading) && <TableRowSkeleton/>}
                    </TableBody>
                </Table>
            </TableContainer>

            <EmptyArrayImage type={"Collectors"} isShowing={collectors.length === 0 && !isLoading} text={"Collectors doesn't exists"} withoutAdd/>
        </PageWrapper>
    )
}
