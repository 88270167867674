import {
    useMainTranslation
} from "../../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import React, {useEffect} from "react";
import * as yup from "yup";
import {useFormik} from "formik";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import {Flex} from "../../../../../../../../../newShared/components/Layouts";
import {LoadingButton} from "../../../../../../../../../newShared/components/Basic/CommonButtons";
import {DelegatedTaskModel} from "../../../../../../../../../newShared/GQLTypes";

export const DeclineDelegatedTaskDialog = ({task, isOpen, handleDeclineTask, handleClose, isLoading}:
                                               {
                                                   task: DelegatedTaskModel | null,
                                                   isOpen: boolean,
                                                   isLoading: boolean,
                                                   handleClose: () => void,
                                                   handleDeclineTask: (reason: string) => void}) => {
    const {tCommon} = useMainTranslation();

    useEffect(() => {
        if(isOpen) formik.resetForm();
        //eslint-disable-next-line
    }, [isOpen]);

    let validationSchema = yup.object({
        reason: yup.string()
            .required('Comment is required')
            .max(1000, ('Comment should be less than 1000 symbols')),
    });

    const formik = useFormik<{reason: string}>({
        initialValues: {reason: ''},
        isInitialValid: false,
        validationSchema,
        onSubmit: (values) => handleDeclineTask(values.reason),
    });
    const formikOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldTouched(e.target.name, true, false);
        formik.handleChange(e);
    };

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{('Decline reason')}</DialogTitle>
            <DialogContent>
                <Flex direction={'column'} gap={'16px'}>
                    <Typography>
                        {('All files will not be added to evidence!')}
                        <br/>
                        {('Are you sure you want to decline task ')}<b>{task?.id}</b>{'?'}
                    </Typography>
                    <Typography>{('Please provide decline reason:')}</Typography>

                    <TextField
                        required
                        onChange={formikOnChange}
                        onBlur={formik.handleBlur}
                        label={('Comment')}
                        name={'reason'}
                        multiline
                        minRows={5}
                        value={formik.values.reason}
                        error={formik.touched.reason && Boolean(formik.errors.reason)}
                        helperText={formik.touched.reason && formik.errors.reason}
                    />
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button variant={'text'} size={'small'} onClick={handleClose}>{tCommon('Close')}</Button>
                <LoadingButton
                    onClick={formik.submitForm}
                    size={'small'}
                    disabled={!formik.isValid}
                    loading={isLoading}
                >
                    {('Decline')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}