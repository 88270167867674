import React, {FC, useCallback, useEffect} from "react";
import {
    FlexRow
} from "../../../../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import {Autocomplete, Checkbox, MenuItem, TextField, Typography} from "@mui/material";
import {ExamViewBlockRow} from "../examViewRow";
import {useCustomFormik} from "../../../../../../../../newShared/hooks/useCustomFormik";
import {MainTrainingExamFormik} from "../../../../../types";
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    mainTrainingsChangeExamNameAction,
    mainTrainingsGetExamCategoriesAutocompleteAction
} from "../../../../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import {autocompleteStorage, loadings} from "../../../../../store/slice";
import {createEditTrainingCategoriesAutocompleteCount} from "../../../../../constants";
import debounce from "lodash.debounce";
import {
    MainCoverImageType,
    TrainingCoverImageModel,
    TrainingExamLevel
} from "../../../../../../../../newShared/GQLTypes";
import {ChangeNameField} from "../../../../changeNameField";
import {useMessageDialog} from "../../../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {handleMinMaxStep} from "../../../../../helpers";
import FormControlLabel from "@mui/material/FormControlLabel";
import {TrainingCoverComponent} from "../../../../trainingCoverComponent";
import colors from "../../../../../../../../newShared/theme/colors";
import {createExamNameSchema} from "../../../../../hooks/dialogs/useCreateExamDialogg/validationSchemas";

type CreateNewExamBlockNameProps = {
    masterFormik: ReturnType<typeof useCustomFormik<MainTrainingExamFormik>>;
}
export const CreateNewExamBlockName: FC<CreateNewExamBlockNameProps> = React.memo(({masterFormik: formik}) => {
    const {t} = useMainTranslation('', {keyPrefix: ''});
    const dispatch = useDispatch();

    const {setMessage} = useMessageDialog();

    const {examCategories} = useSelector(autocompleteStorage);
    const {getExamCategories: isLoading, changeExamName: changeExamNameLoading} = useSelector(loadings);

    const getData = (value: string) => dispatch(mainTrainingsGetExamCategoriesAutocompleteAction({data: {pageRequest: {page: 0, count: createEditTrainingCategoriesAutocompleteCount}, search: value}}));
    const changeName = (value: string) => {
        dispatch(mainTrainingsChangeExamNameAction({
            data: {
                name: value.trim(),
                examId: formik.values.examId,
            },
            onSuccess: () => formik.setFieldValue('name', value.trim()),
            onError: (request, error, addictiveData) => {
                const e409 = error.e409?.[0];
                const e404 = error.e404?.[0];

                if (e409?.type === 'DUPLICATE_NAME') {
                    setMessage({title: t('Change name error'), message: `Exam with name '${value.trim()}' already exists.`});
                }

                if (e404) {
                    setMessage({title: t('Change name error'), message: `Exam with id '${formik.values.examId}' not found.`});
                }

                document.getElementById('change-name-field')?.focus();
            }
        }))
    }

    const onChangeCoverImage = (cover: TrainingCoverImageModel) => {
        formik.setFieldValue('coverImage', cover);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getDataDebounced = useCallback(debounce(getData, 200), []);

    useEffect(() => {
        getDataDebounced(formik.values.category?.trim() || '')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.category?.trim() || ''])

    return (
        <ExamViewBlockRow title={t('Create an exam structure')}
                          description={t('Provide the title of the exam and a brief description.')}
                          gapChild={'20px'}
                          titleChild={
                              <TrainingCoverComponent
                                  margin={'20px 0 0 0'}
                                  currentCover={formik.values.coverImage}
                                  onChange={onChangeCoverImage}
                                  type={MainCoverImageType.Exam}
                                  customText={'Exam cover'}
                              />
                          }
        >
            {formik.values.examId !== 'new' ?
                <ChangeNameField initialValue={formik.values.name}
                                 schema={createExamNameSchema.fields.name}

                                 onSave={changeName}
                                 isLoading={changeExamNameLoading}
                />
                :
                <TextField name={'name'}
                           label={t('Name')}
                           variant={'outlined'}
                           size={"small"}
                           fullWidth
                           value={formik.values.name}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           error={formik.touched.name && !!formik.errors.name}
                           helperText={formik.touched.name && formik.errors.name}
                           required

                           sx={{mt: '10px'}}
                />
            }

            <FlexRow sx={{gap: '20px'}}>
                <Autocomplete<string, false, true, true>
                    freeSolo
                    disableClearable
                    options={examCategories}
                    loading={isLoading}
                    fullWidth
                    onChange={formik.handleChangeAutocomplete('category')}
                    onInputChange={formik.handleChangeAutocomplete('category')}
                    value={formik.values.category}
                    renderInput={(props) => (
                        <TextField
                            {...props}
                            label={t('Category')}
                            name={'category'}
                            size={'small'}
                            error={Boolean(formik.touched.category && formik.errors.category)}
                            helperText={formik.touched.category && formik.errors.category}
                            onBlur={formik.handleBlur}
                            required
                        />
                    )}
                />

                <TextField name={'level'}
                           label={t('Level')}
                           variant={'outlined'}
                           size={"small"}
                           fullWidth
                           value={formik.values.level}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           error={formik.touched.level && !!formik.errors.level}
                           helperText={formik.touched.level && formik.errors.level}
                           select
                >
                    <MenuItem value={TrainingExamLevel.Basic}>{t('Basic')}</MenuItem>
                    <MenuItem value={TrainingExamLevel.Medium}>{t('Medium')}</MenuItem>
                    <MenuItem value={TrainingExamLevel.High}>{t('High')}</MenuItem>
                </TextField>
            </FlexRow>

            <TextField name={'description'}
                       label={t('Description')}
                       variant={'outlined'}
                       fullWidth
                       value={formik.values.description}
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       error={formik.touched.description && !!formik.errors.description}
                       helperText={formik.touched.description && formik.errors.description}
                       multiline rows={4}
            />

            <FlexRow sx={{alignItems: 'center', gap: '10px'}}>
                <FormControlLabel
                    sx={{marginRight: '0'}}
                    checked={formik.values.timeToPass !== 0}
                    onChange={(_, checked) => {
                        if (checked) formik.setFieldValue('timeToPass', 45)
                        else formik.setFieldValue('timeToPass', 0);
                    }}
                    control={<Checkbox/>}
                    label={<Typography variant={'*bodyText2'} color={colors.grayText}
                                       noWrap>{"Limit time to pass exam"}</Typography>}
                />

                {!!formik.values.timeToPass &&
                    <>
                        <TextField name={'timeToPass'}
                                   variant={'outlined'}
                                   size={"small"}
                                   fullWidth
                                   value={formik.values.timeToPass}
                                   onChange={handleMinMaxStep(1, undefined, 1, formik.handleChange)}
                                   onBlur={formik.handleBlur}
                                   error={formik.touched.timeToPass && !!formik.errors.timeToPass}
                            // helperText={formik.touched.timeToPass && formik.errors.timeToPass}
                                   type={"number"}
                                   InputProps={{inputProps: {min: 1}}}
                                   sx={{width: '100px'}}
                        />
                        <Typography variant={'*bodyText2'} color={colors.grayText}>{t('minutes')}</Typography>
                    </>
                }
            </FlexRow>
        </ExamViewBlockRow>
    )}, (prevProps, nextProps) => (
    JSON.stringify(prevProps.masterFormik.values.name) === JSON.stringify(nextProps.masterFormik.values.name)
    && JSON.stringify(prevProps.masterFormik.values.category) === JSON.stringify(nextProps.masterFormik.values.category)
    && JSON.stringify(prevProps.masterFormik.values.description) === JSON.stringify(nextProps.masterFormik.values.description)
    && JSON.stringify(prevProps.masterFormik.values.level) === JSON.stringify(nextProps.masterFormik.values.level)
    && JSON.stringify(prevProps.masterFormik.values.coverImage) === JSON.stringify(nextProps.masterFormik.values.coverImage)
    && JSON.stringify(prevProps.masterFormik.values.timeToPass) === JSON.stringify(nextProps.masterFormik.values.timeToPass)

    && JSON.stringify(prevProps.masterFormik.touched.name) === JSON.stringify(nextProps.masterFormik.touched.name)
    && JSON.stringify(prevProps.masterFormik.touched.category) === JSON.stringify(nextProps.masterFormik.touched.category)
    && JSON.stringify(prevProps.masterFormik.touched.description) === JSON.stringify(nextProps.masterFormik.touched.description)
    && JSON.stringify(prevProps.masterFormik.touched.level) === JSON.stringify(nextProps.masterFormik.touched.level)
    && JSON.stringify(prevProps.masterFormik.touched.coverImage) === JSON.stringify(nextProps.masterFormik.touched.coverImage)
    && JSON.stringify(prevProps.masterFormik.touched.timeToPass) === JSON.stringify(nextProps.masterFormik.touched.timeToPass)

    && JSON.stringify(prevProps.masterFormik.errors.name) === JSON.stringify(nextProps.masterFormik.errors.name)
    && JSON.stringify(prevProps.masterFormik.errors.category) === JSON.stringify(nextProps.masterFormik.errors.category)
    && JSON.stringify(prevProps.masterFormik.errors.description) === JSON.stringify(nextProps.masterFormik.errors.description)
    && JSON.stringify(prevProps.masterFormik.errors.level) === JSON.stringify(nextProps.masterFormik.errors.level)
    && JSON.stringify(prevProps.masterFormik.errors.coverImage) === JSON.stringify(nextProps.masterFormik.errors.coverImage)
    && JSON.stringify(prevProps.masterFormik.errors.timeToPass) === JSON.stringify(nextProps.masterFormik.errors.timeToPass)
))