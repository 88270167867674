import colors from "../../../../../../newShared/theme/colors";
import {IconButton, Menu, MenuItem, styled, Typography} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

export const TemplateCardContainer = styled('div')(({isMobile}: {isMobile: boolean}) => ({
    display: 'flex',
    padding: '32px 20px',
    flexDirection: 'column',
    gap: '20px',
    width: isMobile ? '100%' : 'calc(50% - 10px)',
    cursor: 'pointer',
    border: `1px solid ${colors.stroke.grey}`,
    borderRadius: '8px',
    backgroundColor: colors.backgrounds.white,
    boxShadow: '0px 0px 1px 0px rgba(125, 151, 174, 0.02), 0px 2px 6px 0px rgba(125, 151, 174, 0.02), 0px 8px 16px 0px rgba(125, 151, 174, 0.08)'
}))

export const TemplateCardTitleTypeMenuContainer = styled('div')({
    width: '100%',
    display: "flex",
    alignItems: 'center',
    justifyContent: 'space-between'
});

export const TemplateCardTitleTypeContainer = styled('div')({
    display: "flex",
    alignItems: 'center',
    gap: '16px'
});

export const TitleTypo = styled(Typography)({
    color: colors.grayText,
    fontSize: '18px',
    fontWeight: 600
});

export const MenuButton = styled(IconButton)({

})

MenuButton.defaultProps = {
    size: 'small',
}

export const MenuButtonIcon = styled(MoreHorizIcon)({
    color: colors.primary.blue
})

export const CardMenu = styled(Menu)({
    '& .MuiMenu-list': {
        padding: '8px'
    },
    '& .MuiMenuItem-root': {
        margin: '0 auto',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: '#F2F4F7'
        }
    }
})

CardMenu.defaultProps = {
    PaperProps: {
        style: {
            boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)',
            borderRadius: '8px',
            border: '1px solid #F2F4F7'
        },
    },
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
    }
}

export const MenuItemStyled = styled(MenuItem)({
    padding: '8px'
})

export const ListItemText = styled(Typography)({
    color: colors.grayText
})

ListItemText.defaultProps = {variant: 'body1'}


export const TemplateDescriptionText = styled(Typography)({
    color: colors.text.grey_dark,
    flexGrow: 1
})

ListItemText.defaultProps = {variant: 'body2'}

export const TemplateCardActionsRow = styled('div')({
    display: "flex",
    alignItems: 'center',
    gap: '16px',
});