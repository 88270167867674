import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {PageWrapper} from "../../../../../newShared/components/Layouts";
import {Box, Grid} from "@mui/material";
import React from "react";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {FrameworkSkeletonCard} from "../../../frameworks/components/frameworkSkeletonCard";
import {EmptyArrayImage} from "../../../../../newShared/components/EmptyArrayImage";
import {FrameworkCard} from "../../components/frameworkCard";
import {AddFrameworkDialog} from "../../components/dialogs/frameworks/addFramework";
import {useFrameworkList} from "../../hooks/useFrameworkList";
import AddIcon from "@mui/icons-material/Add";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {GenericFiltersArray} from "../../../../../newShared/components/genericFilter";

export const FrameworksList = () => {
    const {isMobile, matches_2300Up} = useMedia();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'builderPage'});

    const {
        _frameworks, _isLoading,
        goToFramework,
        openAddFrameworkDialog,
        currentSearchForFetch
    } = useFrameworkList();

    return (
        <PageWrapper>
            <PageTitleAndActions
                title={t('Created frameworks')}
                showMenuWhen={isMobile}
                actions={[
                    {
                        title: t('Create Framework'),
                        onClick: openAddFrameworkDialog,
                        variant: 'text',
                        icon: <AddIcon />
                    }
                ]}
            />

            <GenericFiltersArray
                configs={[]}
                fetchResultWithSelectedFilters={() => null}
                isAddFilters={false}
            />

            {/*<Breadcrumbs/>*/}
            {/*<Box width={'100%'} display={'flex'}>*/}
            {/*    <Typography variant={!matches_1440Down ? "h1" : "h3"}>{t('Created frameworks')}</Typography>*/}
            {/*    <Button size={isMobile ? 'small' : undefined} sx={{ml: !revDir ? '32px' : undefined, mr: revDir ? '32px' : undefined}} onClick={openAddFrameworkDialog}>{t('Create Framework')}</Button>*/}
            {/*</Box>*/}


            <Box width={'100%'} display={"flex"} flexDirection={"column"} flexGrow={1} overflow={'auto'} padding={!revDir ? '0 10px 0 0' : '0 0 0 10px'}>
                <Grid container spacing={2} mt={'16px'} columns={matches_2300Up ? 10 : undefined} sx={{'& > .MuiGrid-item': !revDir ? {paddingLeft: '17px'} : {paddingRight: '17px'}}}>
                    {_frameworks.filter(e => e.name?.trim().toLowerCase().includes(currentSearchForFetch.trim().toLowerCase() || '')).map(framework => (
                        <Grid item key={framework.id} c390={12} c770={6} c1336={3} c1440={4} c1920={3} c2300={2}>
                            <FrameworkCard onClick={() => goToFramework(framework.id)}
                                           framework={framework}
                            />
                        </Grid>
                    ))}

                    {_isLoading && (
                        <Grid item c390={12} c770={6} c1336={3} c1440={4} c1920={3} c2300={2}>
                            <FrameworkSkeletonCard/>
                        </Grid>
                    )}
                </Grid>

                <EmptyArrayImage
                    type={'builder'}
                    isShowing={!_frameworks.filter(e => e.name?.trim().toLowerCase().includes(currentSearchForFetch.trim().toLowerCase() || '')).length && !_isLoading}
                    text={currentSearchForFetch.trim().length > 0 ? 'Nothing was found for your query' : 'No custom framework here. Add new.'}
                    onClick={openAddFrameworkDialog}
                    withoutAdd={currentSearchForFetch.trim().length > 0}
                />
            </Box>

            <AddFrameworkDialog />
        </PageWrapper>
    )
}