import {OnSubscriptionDataOptions, useSubscription} from "@apollo/client";
import {subscriptionMessage} from "../../types";
import {useDispatch, useSelector} from "react-redux";
import {WORKSPACE_SUBSCRIPTION_GQL} from "../../gql";
import {Logging} from "../../../../../newShared/utils/logs/log";
import {TScanHistory} from "../../../../components/vulnerabilityScanner/types";
import {vulnerabilityHotUpdateHistory} from "../../../../components/vulnerabilityScanner/store/slice";
import {addInfoSnack} from "../../../snack/store/slice";
import {
    SEND_CREATE_UPDATE_NCR_CONFIG_ACTION,
    SEND_DELETE_AUDITOR_ACTION,
    SEND_REPORT_STATUS_CHANGED_ACTION,
    SEND_SHARED_FOR_AUDITORS_REPORT_ACTION,
    SEND_UPDATE_NCR_REPORT_ACTION,
    SEND_UPDATE_REVIEWED_CONTROL_ACTION
} from "../../../../components/reports/actions";
import {
    SEND_CREATE_UPDATE_NCR_CONFIG_payload,
    SEND_DELETE_AUDITOR_payload,
    SEND_REPORT_STATUS_CHANGED_payload,
    SEND_SHARED_FOR_AUDITORS_REPORT_payload,
    SEND_UPDATE_NCR_REPORT_payload,
    SEND_UPDATE_REVIEWED_CONTROL_payload
} from "../../../../components/reports/types";
import {useHistory} from "react-router";
import {PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED} from "../../../../../newShared/constants";
import {automationHistoryHotReload} from "../../../../components/automations/types";
import {automationsHotReloadHistory} from "../../../../components/automations/store/slice";
import {
    KYC_ARCHIVED_TARGET_DELETED_payload,
    KYC_EXCEPTION_DELETED_payload,
    KYC_SCREENING_STATUS_CHANGED_payload
} from "../../../../components/kycRefactor/types";
import {
    clientsSelector,
    KYC_ARCHIVED_TARGET_DELETED_action,
    KYC_CLIENT_ADDED_action,
    KYC_CLIENT_ARCHIVED_action,
    KYC_CLIENT_UNARCHIVED_action,
    KYC_EXCEPTION_ADDED_action,
    KYC_EXCEPTION_DELETED_action,
    KYC_MATCH_CREATED_action,
    KYC_REQUEST_ADDED_action,
    KYC_REQUEST_STATUS_CHANGED_action,
    KYC_SCREENING_CREATED_action,
    KYC_SCREENING_STATUS_CHANGED_action,
    KYC_TARGET_STATUS_CHANGED_action,
    KYC_TARGET_UPDATED_action
} from "../../../../components/kycRefactor/store/slice";
import {
    KycExceptionModel,
    KycMatchModel,
    KycRefactorRequestModel,
    KycScreeningModel,
    TKycTargetModel
} from "../../../../../newShared/GQLTypes";
import {getTargetName, getTargetTypeForPath} from "../../../../components/kycRefactor/helpers";
import {KYC_CLIENTS_LIST_PATH} from "../../../../components/kycRefactor/constants";
// import {automationHistoryHotReload} from "../../../automations/types";
// import {TScanHistory} from "../../../vulnerabilityScanner/types";


//actions
// import {automationsHotReloadHistory} from "../../../automations/store/slice";
// import {vulnerabilityHotUpdateHistory} from "../../../vulnerabilityScanner/store/slice";
// import {integrationsBackgroundUpdate} from "../../../Integrations/store/slice";
// import {sendReportStatusChanged, sendUpdateNcrReport} from "../../../complianceReports/store/view/store";
// import {sendReportStatusChangedAction} from "../../../complianceReports/store/rowsData/store";

export const useWorkspaceSubscription = (workspaceId: string, token: string, publicId: string, organizationId: string) => {
    const dispatch = useDispatch();
    const {location: {pathname}, push} = useHistory();
    const selectedKycTarget = useSelector(clientsSelector).selected;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onNewDataReceived = (data: OnSubscriptionDataOptions<{ subscribeToWorkspace: subscriptionMessage }>) => {
        const newData: subscriptionMessage | undefined = data?.subscriptionData?.data?.subscribeToWorkspace;
        if(newData){
            switch (newData.type){
                case "SEND_CHANGED_EXECUTOR_AUTOMATION_PIPELINE": {
                    try{
                        if(newData){
                            const payload = JSON.parse(newData.payload) as automationHistoryHotReload;
                            dispatch(automationsHotReloadHistory(payload));
                        }
                        return;
                    }catch (ex){
                        Logging.log('SEND_CHANGED_EXECUTOR_AUTOMATION_PIPELINE error: ', ex);
                        return;
                    }
                }
                case "SEND_CHANGED_VULNERABILITY_SCAN_TARGET_HISTORY": {
                    // return;
                    // console.log(`GOT MESSAGE SEND_CHANGED_VULNERABILITY_SCAN_TARGET_HISTORY`);
                    try {
                        const payload = JSON.parse(newData.payload) as TScanHistory;
                        dispatch(vulnerabilityHotUpdateHistory(payload));
                        dispatch(addInfoSnack(`Scanning for ${payload.target} ended!`));
                        // console.log('SEND_CHANGED_VULNERABILITY_SCAN_TARGET_HISTORY dispatched');
                        return;
                    }catch (ex){
                        Logging.log('SEND_CHANGED_VULNERABILITY_SCAN_TARGET_HISTORY error: ', ex);
                        return;
                    }
                }
                case "SEND_DISCOVERY_APP_STATUS_CHANGED": {
                    return;
                    // try{
                    //     const payload = JSON.parse(newData.payload) as SEND_DISCOVERY_APP_STATUS_CHANGED_payload;
                    //     dispatch(integrationsBackgroundUpdate(payload));
                    //     return;
                    // }catch (ex){
                    //     Logging.log('SEND_DISCOVERY_APP_STATUS_CHANGED error: ', ex);
                    //     return;
                    // }
                }
                //Reports
                case "SEND_SHARED_FOR_AUDITORS_REPORT": {
                    if (pathname !== PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED) return;
                    try{
                        const payload = JSON.parse(newData.payload) as SEND_SHARED_FOR_AUDITORS_REPORT_payload;
                        dispatch(SEND_SHARED_FOR_AUDITORS_REPORT_ACTION(payload));
                        return;
                    }catch (ex){
                        Logging.log('SEND_SHARED_FOR_AUDITORS_REPORT error: ', ex);
                        return;
                    }
                }
                case "SEND_CREATE_UPDATE_NCR_CONFIG": {
                    try{
                        const payload = JSON.parse(newData.payload) as SEND_CREATE_UPDATE_NCR_CONFIG_payload;
                        dispatch(SEND_CREATE_UPDATE_NCR_CONFIG_ACTION(payload));
                        return;
                    }catch (ex){
                        Logging.log('SEND_CREATE_UPDATE_NCR_CONFIG error: ', ex);
                        return;
                    }
                }
                case "SEND_DELETE_AUDITOR": {
                    try{
                        const payload = JSON.parse(newData.payload) as SEND_DELETE_AUDITOR_payload;
                        dispatch(SEND_DELETE_AUDITOR_ACTION(payload));
                        return;
                    }catch (ex){
                        Logging.log('SEND_DELETE_AUDITOR error: ', ex);
                        return;
                    }
                }
                case "SEND_UPDATE_NCR_REPORT": {
                    try{
                        const payload = JSON.parse(newData.payload) as SEND_UPDATE_NCR_REPORT_payload;
                        dispatch(SEND_UPDATE_NCR_REPORT_ACTION(payload));
                        return;
                    }catch (ex){
                        Logging.log('SEND_UPDATE_NCR_REPORT error: ', ex);
                        return;
                    }
                }
                case "SEND_REPORT_STATUS_CHANGED": {
                    try{
                        const payload = JSON.parse(newData.payload) as SEND_REPORT_STATUS_CHANGED_payload;
                        dispatch(SEND_REPORT_STATUS_CHANGED_ACTION(payload));
                        return;
                    }catch (ex){
                        Logging.log('SEND_REPORT_STATUS_CHANGED error: ', ex);
                        return;
                    }
                }
                case 'SEND_UPDATE_REVIEWED_CONTROL': {
                    try{
                        const payload = JSON.parse(newData.payload) as SEND_UPDATE_REVIEWED_CONTROL_payload;
                        dispatch(SEND_UPDATE_REVIEWED_CONTROL_ACTION(payload));
                        return;
                    }catch (ex){
                        Logging.log('SEND_UPDATE_REVIEWED_CONTROL error: ', ex);
                        return;
                    }
                }
                //KYC
                case 'KYC_SCREENING_STATUS_CHANGED': {
                    try{
                        const payload = JSON.parse(newData.payload) as KYC_SCREENING_STATUS_CHANGED_payload;
                        dispatch(KYC_SCREENING_STATUS_CHANGED_action(payload));
                        return;
                    }catch (ex){
                        Logging.log('KYC_SCREENING_STATUS_CHANGED error: ', ex);
                        return;
                    }
                }
                case 'KYC_SCREENING_CREATED': {
                    try{
                        const payload = JSON.parse(newData.payload) as KycScreeningModel;
                        dispatch(KYC_SCREENING_CREATED_action(payload));
                        return;
                    }catch (ex){
                        Logging.log('KYC_SCREENING_CREATED error: ', ex);
                        return;
                    }
                }
                case 'KYC_MATCH_CREATED': {
                    try{
                        const payload = JSON.parse(newData.payload) as KycMatchModel;
                        dispatch(KYC_MATCH_CREATED_action(payload));
                        return;
                    }catch (ex){
                        Logging.log('KYC_MATCH_CREATED error: ', ex);
                        return;
                    }
                }
                case 'KYC_CLIENT_ARCHIVED': {
                    try{
                        const payload = JSON.parse(newData.payload) as TKycTargetModel;
                        if(selectedKycTarget && selectedKycTarget.id === payload.id){
                            //check if current target is selected
                            const path = getTargetTypeForPath(selectedKycTarget);
                            const clientName = getTargetName(selectedKycTarget);
                            push(KYC_CLIENTS_LIST_PATH.replace(':type', path.pathName));
                            dispatch(addInfoSnack(`Client ${clientName} was moved to archive`));
                        }else{
                            dispatch(KYC_CLIENT_ARCHIVED_action(payload));
                        }
                        return;
                    }catch (ex){
                        Logging.log('KYC_CLIENT_ARCHIVED error: ', ex);
                        return;
                    }
                }
                case 'KYC_CLIENT_UNARCHIVED': {
                    try{
                        const payload = JSON.parse(newData.payload) as TKycTargetModel;
                        dispatch(KYC_CLIENT_UNARCHIVED_action(payload));
                        return;
                    }catch (ex){
                        Logging.log('KYC_CLIENT_UNARCHIVED_action error: ', ex);
                        return;
                    }
                }
                case 'KYC_CLIENT_ADDED': {
                    try{
                        const payload = JSON.parse(newData.payload) as TKycTargetModel;
                        dispatch(KYC_CLIENT_ADDED_action(payload));
                        return;
                    }catch (ex){
                        Logging.log('KYC_CLIENT_ADDED_action error: ', ex);
                        return;
                    }
                }
                case 'KYC_TARGET_STATUS_CHANGED': {
                    try{
                        const payload = JSON.parse(newData.payload) as TKycTargetModel;
                        dispatch(KYC_TARGET_STATUS_CHANGED_action(payload));
                        return;
                    }catch (ex){
                        Logging.log('KYC_TARGET_STATUS_CHANGED_action error: ', ex);
                        return;
                    }
                }
                case 'KYC_REQUEST_STATUS_CHANGED': {
                    try{
                        const payload = JSON.parse(newData.payload) as KycRefactorRequestModel;
                        dispatch(KYC_REQUEST_STATUS_CHANGED_action(payload));
                        return;
                    }catch (ex){
                        Logging.log('KYC_REQUEST_STATUS_CHANGED_action error: ', ex);
                        return;
                    }
                }
                case 'KYC_REQUEST_ADDED': {
                    try{
                        const payload = JSON.parse(newData.payload) as KycRefactorRequestModel;
                        dispatch(KYC_REQUEST_ADDED_action(payload));
                        return;
                    }catch (ex){
                        Logging.log('KYC_REQUEST_ADDED_action error: ', ex);
                        return;
                    }
                }
                case 'KYC_EXCEPTION_ADDED': {
                    try{
                        const payload = JSON.parse(newData.payload) as KycExceptionModel;
                        dispatch(KYC_EXCEPTION_ADDED_action(payload));
                        return;
                    }catch (ex){
                        Logging.log('KYC_EXCEPTION_ADDED_action error: ', ex);
                        return;
                    }
                }
                case 'KYC_EXCEPTION_DELETED': {
                    try{
                        const payload = JSON.parse(newData.payload) as KYC_EXCEPTION_DELETED_payload;
                        dispatch(KYC_EXCEPTION_DELETED_action(payload));
                        return;
                    }catch (ex){
                        Logging.log('KYC_EXCEPTION_DELETED_action error: ', ex);
                        return;
                    }
                }
                case 'KYC_ARCHIVED_TARGET_DELETED': {
                    try{
                        const payload = JSON.parse(newData.payload) as KYC_ARCHIVED_TARGET_DELETED_payload;
                        dispatch(KYC_ARCHIVED_TARGET_DELETED_action(payload));
                        return;
                    }catch (ex){
                        Logging.log('KYC_ARCHIVED_TARGET_DELETED error: ', ex);
                        return;
                    }
                }
                case 'KYC_TARGET_UPDATED': {
                    try{
                        const payload = JSON.parse(newData.payload) as TKycTargetModel;
                        dispatch(KYC_TARGET_UPDATED_action(payload));
                        return;
                    }catch (ex){
                        Logging.log('KYC_TARGET_UPDATED_action error: ', ex);
                        return;
                    }
                }
                default: {
                    Logging.log(`useWorkspaceSubscription: ${newData.type} type is not defined!`);
                }

            }
        }
        // console.log(JSON.stringify(newData));
    }

    // useSubscription<{ subscribeToWorkspace: subscriptionMessage }>(WORKSPACE_SUBSCRIPTION_GQL, {onSubscriptionData: onNewDataReceived, variables: {workspaceId, token, publicId, organizationId}, fetchPolicy: 'no-cache'});
    //Gregory Sheygam asked on 9.01.24 to close any pubsub messages - disabled received data handling
    useSubscription<{ subscribeToWorkspace: subscriptionMessage }>(WORKSPACE_SUBSCRIPTION_GQL, {onSubscriptionData: () => null, variables: {workspaceId, token, publicId, organizationId}, fetchPolicy: 'no-cache'});
}
