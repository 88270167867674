import {useDispatch, useSelector} from "react-redux";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {dialogs, hideDeleteRisk, loadings, selectedRegistry} from "../../../store/slice";
import {deleteRiskByIdApiAction} from "../../../store/actions";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const useDeleteRisk = () => {
    const dispatch = useDispatch();
    const {deleteRisk: {isOpen, risk}} = useSelector(dialogs);
    const {riskDelete} = useSelector(loadings);
    const {setMessage} = useMessageDialog();
    const reg = useSelector(selectedRegistry);
    const {t} = useMainTranslation('', {keyPrefix: 'risks'});


    const handleClose = () => {
        dispatch(hideDeleteRisk());
    }

    const handleSubmit = () => {
        if(risk && reg){
            dispatch(deleteRiskByIdApiAction({
                data: {
                    workspaceId: '',
                    organizationId: '',
                    riskId: risk.id,
                    registryId: reg.id
                },
                onSuccess
            }));
        }
    }

    const onSuccess = () => {
        setMessage({title: t('Completed successfully'), message: t('Risk {{name}} has been deleted.', {name: risk!.name})});
    }

    return{
        isOpen,
        handleClose,
        isLoading: riskDelete,
        handleSubmit,
        risk
    }
}