import {FC} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import {LoadingButton} from "../../../../../../../newShared/components/Basic/CommonButtons";
import {useRemoveFramework} from "../../../../hooks/dialogs/useRemoveFramework";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const RemoveFrameworkDialog: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'builderPage.dialogs'});
    const {
        isOpen,
        field,
        handleDelete,
        handleChange,
        handleClose,
        isLoading,
    } = useRemoveFramework();

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Remove framework')}</DialogTitle>
            <DialogContent>
                <Typography variant={'body2'}>{t('Please type "confirm" to delete')}</Typography>
                <TextField
                    size={'small'}
                    sx={{width: '320px', mt: '8px', '& label': {top: '0 !important'}}}
                    label={t('Type text')}
                    value={field}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button variant={"text"} onClick={handleClose} disabled={isLoading}>{t('Cancel')}</Button>
                <LoadingButton disabled={field.toLowerCase() !== 'confirm'} loading={isLoading} onClick={handleDelete}>
                    {t('Remove')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
