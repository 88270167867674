import * as S from "./styled";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {moveToFoldersFilter} from "../../helpers";
import {Flex} from "../../../../../newShared/components/Layouts";
import React, {FC, MouseEvent, useState} from "react";
import {DocumentFolder} from "../../types";
import {TIdName} from "../../../../../newShared/types";
import {DOCUMENTS_FOLDER_NAME, TRASH_FOLDER_NAME} from "../../constants";
import {Popover} from "@mui/material";
import {GetFolderIconByName, GetFolderName} from "../LeftSideTree";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {FlexBox} from "../dialogs/addDocument/fillInfoView/styled";
import {Namespace, TFunction} from "react-i18next";
import {ReactComponent as FolderIcon} from "../../../../../newShared/images/menu/folder.svg";
import {ReactComponent as AllDocumentsIcon} from "../../../../../newShared/images/menu/all-documents.svg"; // fill

interface SelectFolderTreeProps {
    t:  TFunction<Namespace<string>, string>;
    selectedFolder: TIdName | null,
    setSelectedFolder: (folder: TIdName) => void,
    folders: DocumentFolder[],
    path:  TIdName[],
    maxWidth?: string,
}
export const SelectFolderTree: FC<SelectFolderTreeProps> = (props) => {
    // const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});
    const {t, folders, path, selectedFolder, setSelectedFolder, maxWidth = '650px' } = props;
    const rootFolder = folders.find(e => e.name === DOCUMENTS_FOLDER_NAME);
    const [openedFolders, setOpenedFolders] = useState<string[]>([folders.find(p => p.name === DOCUMENTS_FOLDER_NAME)?.id ?? '']);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleOpen = (event: MouseEvent<HTMLElement>) => {setAnchorEl(event.currentTarget);};
    const handleClose = () => {setAnchorEl(null);};

    const handleSelectFolder = (id: string, name: string) => {
        setSelectedFolder({id, name});
        handleClose();
    }

    return (
        <S.FolderSelectContainer>
            <S.FolderSelectBox isActive={Boolean(anchorEl) ? true : undefined} onClick={handleOpen}>
                {selectedFolder ? (
                    <FlexBox>
                        {rootFolder?.id === selectedFolder.id ? <AllDocumentsIcon /> : <FolderIcon />}
                        <S.TypographyBody2Grey >{rootFolder?.id === selectedFolder.id ? GetFolderName(selectedFolder.name) : selectedFolder.name}</S.TypographyBody2Grey>
                    </FlexBox>
                ) :  <S.TypographyBody2Grey isFirst={true}>{t('Folder name')}</S.TypographyBody2Grey>
                }

            </S.FolderSelectBox>
            <Popover open={Boolean(anchorEl)}
                     anchorEl={anchorEl}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'center',
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'center',
                     }}
                     sx={{'& .MuiPopover-paper': {padding: '6px', borderRadius: '8px',maxWidth, width: '100%'}}}
                     onClick={e => {e.stopPropagation()}}
                     onClose={() => {handleClose()}}
            >
                <S.SelectPopperArea >
                    {folders.filter(e => moveToFoldersFilter(e, path)).map((e) => (
                        <FolderMenuItems
                            key={e.id}
                            folder={e}
                            selectedFolderId={selectedFolder}
                            onSelectFolder={handleSelectFolder}
                            openedFolders={openedFolders}
                            openFolder={(id: string) => setOpenedFolders([...openedFolders, id])}
                            hideFolder={(id: string) => setOpenedFolders([...openedFolders.filter(e => e !== id)])}
                            currPath={[{id: e.id, name: e.name}]}
                        />
                    ))}
                </S.SelectPopperArea>
            </Popover>

        </S.FolderSelectContainer>
    )
}


export const FolderMenuItems = (props: {
    folder: DocumentFolder,
    selectedFolderId: {id: string, name: string} | null,
    onSelectFolder: (id: string, name: string) => void,
    openedFolders: string[],
    openFolder: (id: string) => void,
    hideFolder: (id: string) => void,
    currPath: {name: string, id: string}[],
    onSelectFolderReturnFolderAndPath?: (folder: DocumentFolder, path: {name: string, id: string}[]) => void
}) => {
    const {folder, selectedFolderId, onSelectFolder, openedFolders, openFolder, hideFolder, currPath, onSelectFolderReturnFolderAndPath} = props;
    const isSelected = selectedFolderId?.id === folder.id;
    const isOpened = openedFolders.some(e => e === folder.id);
    const {revDir} = useMainTranslation();

    // console.log(`${selectedFolderId?.id} and curr: ${folder.id}`);


    const handleExpandMoreFolder = (e: any) => {
        e.stopPropagation();
        openFolder(folder.id);
    }

    const handleExpandLessFolder = (e: any) => {
        e.stopPropagation();
        hideFolder(folder.id);
    }

    const handleSelectFolder = (e: any) => {
        e.stopPropagation();
        //check if not selected parent folder
        onSelectFolder(folder.id, folder.name);
        onSelectFolderReturnFolderAndPath && onSelectFolderReturnFolderAndPath(folder, currPath)
    }

    return (

        <S.SelectTreeMenuItem >
            <S.TreeItemBox currPathLength={currPath.length} revDir={revDir} isSelected={isSelected} onClick={(e) => handleSelectFolder(e)} >
                {folder.name !== TRASH_FOLDER_NAME &&
                    <S.TreeIconButton
                        onClick={(e) => isOpened ? handleExpandLessFolder(e) : handleExpandMoreFolder(e)}
                        disabled={!folder.children.length}
                    >
                        {isOpened ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
                    </S.TreeIconButton>
                }

                {GetFolderIconByName(folder.name, currPath.length === 1)}
                <S.TreeItemTitle>
                    { currPath.length === 1 && folder.name === DOCUMENTS_FOLDER_NAME ? GetFolderName(folder.name) : folder.name }
                </S.TreeItemTitle>

            </S.TreeItemBox>
            {isOpened && <Flex w={'100%'} direction={'column'} overflowy={'auto'}>
                {folder.children.map(f => <FolderMenuItems
                    folder={f}
                    key={f.id}
                    currPath={[...currPath, {name: f.name, id: f.id}]}
                    selectedFolderId={selectedFolderId}
                    hideFolder={hideFolder}
                    openedFolders={openedFolders}
                    openFolder={openFolder}
                    onSelectFolder={onSelectFolder}
                    onSelectFolderReturnFolderAndPath={onSelectFolderReturnFolderAndPath}
                />)}
            </Flex>}
        </S.SelectTreeMenuItem>

    )
}
