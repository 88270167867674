import {
    Box,
    Button,
    MenuItem,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import colors from "../../../../../newShared/theme/colors";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSodScheduler} from "../../hooks/useSodScheduler";
import {TSodSchedulerTabs} from "../../types";
import {LoadingButton, TabContext} from "@mui/lab";
import {Breadcrumbs} from "../../../../barsEnvironment/breadcrumbs";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {TextFieldProps as MuiTextFieldPropsType} from "@mui/material/TextField/TextField";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import CommonSwitch from "../../../../../newShared/components/Basic/CommonSwitch";
import {SearchField} from "../../../../../newShared/components/Basic/CommonInputs";
import {TableRowSkeleton} from "../../../../../newShared/components/tableRowSkeleton";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DEFAULT_DATE_FORMAT} from "../../../../../newShared/utils/dateTools";

export const SodScheduler = () => {
    const {isMobile} = useMedia();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        isOk,
        isLoadingUpdate,
        isLoadingData,
        form, handleChange,
        tab, setTab,
        scheduler,
        history,
        isEdit,
        handleStartDate,
        handleIsAllActiveRules,
        handleEndBy,
        handleRepeatTime,
        handleAlerts,
        handleClose,
        handleEdit,
        handleSave,

        filter,
        value, handleChangeValue, handleKeyDown, setFilter,

        pagination: {
            bottomLoader,
            onScroll,
            scrollRef
        }
    } = useSodScheduler();

    const getAlertsString = (alerts: {isEnded: boolean, isError: boolean, isCanceled: boolean}) => {
        const arr: string[] = [];
        if (alerts.isEnded) arr.push('ended');
        if (alerts.isError) arr.push('error');
        if (alerts.isCanceled) arr.push('canceled');
        return `${arr.join(', ')} verification`
    }

    const getRepeatTimeString = (repeatNumber: number, repeatTime: string) => {
        switch (repeatTime) {
            case 'weeks': return repeatNumber === 1 ?  `${t('week')}` : `${repeatNumber} ${t('weeks')}`;
            case 'years': return repeatNumber === 1 ?  `${t('year')}` : `${repeatNumber} ${t('years')}`;
            case 'days': return repeatNumber === 1 ?  `${t('day')}` : `${repeatNumber} ${t('days')}`;
            case 'months': return repeatNumber === 1 ?  `${t('month')}` : `${repeatNumber} ${t('months')}`;
            case 'hours': return repeatNumber === 1 ?  `${t('hour')}` : `${repeatNumber} ${t('hours')}`;
            default: return '';
        }

    }



    return (
        <Box width={'100%'} height={'100%'} display={'flex'} flexDirection={'column'}>
            <Breadcrumbs/>

            <Typography variant={isMobile ?  'h3' : "h1"}  sx={{mr: !revDir ? '32px' : undefined, ml: revDir ? '32px' : undefined, color: colors.backgrounds.dark}}>
                {t('Segregation of duties scheduler')}
            </Typography>

            <TabContext value={tab}>
                <Tabs variant={'scrollable'} value={tab}
                      aria-label="basic tabs example"
                      onChange={(e, newValue: TSodSchedulerTabs) => setTab(newValue)} >
                    <Tab disabled={isLoadingData || isLoadingUpdate} label={t("Scheduler and alerts")} value={'scheduler'} />
                    <Tab disabled={isLoadingData || isLoadingUpdate} label={t("History of running")} value={'history'} sx={{paddingLeft: '0'}}/>
                </Tabs>


            </TabContext>

            {tab === 'scheduler' &&
                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                    <Typography variant={'body1'} sx={{mt: '11px'}}>{t('Verify compliance of user-role assignments with all rules for SoD.')}</Typography>

                    {isEdit ?
                        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} width={'578px'}>
                            <Typography variant={'body1'} sx={{mt: '20px'}}>{t('Select start date and time')}</Typography>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DateTimePicker
                                    // clearable
                                    ampm={false}
                                    label={t('Start date')}
                                    inputFormat={DEFAULT_DATE_FORMAT}
                                    disabled={isLoadingUpdate || isLoadingData}
                                    disablePast
                                    hideTabs
                                    // showTodayButton
                                    views={['year', 'month', 'day', 'hours', 'minutes']}
                                    // minutesStep={30}
                                    value={form?.startDate || null}
                                    onChange={handleStartDate}
                                    renderInput={(props: MuiTextFieldPropsType) => <TextField {...props} required size={'small'} sx={{mt: '16px', '& label': {top: '0 !important'}}}  />}
                                    // onAccept={handleStartDate}
                                    // onClose={handleSelect}
                                    // onOpen={() => setOpened(true)}
                                />
                            </LocalizationProvider>

                            <Typography variant={'body1'} sx={{mt: '30px'}}>{t('Select the rules that you want to include in the next running check')}</Typography>

                            <TextField select fullWidth label={t('Include to running check')} value={form?.isAllActiveRules ? t('All active rules') : t('All rules')} size={'small'}
                                       sx={{mt: '16px', '& label': {top: '0 !important'}}} disabled={isLoadingUpdate || isLoadingData}
                            >
                                <MenuItem value={'All rules'} onClick={() => handleIsAllActiveRules(false)} >{t('All rules')}</MenuItem>
                                <MenuItem value={'All active rules'} onClick={() => handleIsAllActiveRules(true)} >{t('All active rules')}</MenuItem>
                            </TextField>

                            <Box display={'flex'} width={'100%'} sx={{mt: '16px'}}>
                                <TextField
                                    required
                                    label={t("Number")}
                                    size={'small'}
                                    type="number"
                                    sx={{'& label': {top: '0 !important'}}}
                                    disabled={isLoadingUpdate || isLoadingData}
                                    value={form?.repeatNumber || 0}
                                    name={'repeatNumber'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                />

                                <TextField required select fullWidth label={t('Time')} value={form?.repeatTime || ''} size={'small'}
                                           sx={{width: '150px', '& label': {top: '0 !important'}, ml: !revDir ? '8px' : undefined, mr: revDir ? '8px' : undefined}}
                                           onChange={(e) => handleRepeatTime(e.target.value)} disabled={isLoadingUpdate || isLoadingData}
                                >
                                    <MenuItem value={'years'} >{t('Years')}</MenuItem>
                                    <MenuItem value={'months'} >{t('Months')}</MenuItem>
                                    <MenuItem value={'weeks'} >{t('Weeks')}</MenuItem>
                                    <MenuItem value={'days'} >{t('Days')}</MenuItem>
                                    <MenuItem value={'hours'} >{t('Hours')}</MenuItem>
                                </TextField>
                            </Box>

                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label={t('End date')}
                                    inputFormat={DEFAULT_DATE_FORMAT}
                                    disablePast
                                    disabled={isLoadingUpdate || isLoadingData}
                                    value={form?.endDate || null}
                                    renderInput={(props: MuiTextFieldPropsType) => <TextField {...props} required size={'small'} sx={{mt: '20px', '& label': {top: '0 !important'}}}  />}
                                    onChange={handleEndBy}
                                />

                            </LocalizationProvider>

                            <Typography variant={'body1'} sx={{mt: '20px'}}>{t('Choose the type of alerts.')}</Typography>
                            <CommonSwitch sx={{mt: '6px'}} label={t('Ended')} checked={form?.alerts?.isEnded} onChange={() => handleAlerts(!form?.alerts.isEnded, 'isEnded') }/>
                            <CommonSwitch  label={t('Error')} checked={form?.alerts?.isError} onChange={() => handleAlerts(!form?.alerts.isError, 'isError') }/>
                            <CommonSwitch  label={t('Canceled')} checked={form?.alerts?.isCanceled} onChange={() => handleAlerts(!form?.alerts.isCanceled, 'isCanceled') }/>

                            <TextField label={t('Send by email')} value={form?.email || ''} size={'small'}
                                       disabled={isLoadingUpdate || isLoadingData}
                                       required
                                       sx={{mt: '16px', '& label': {top: '0 !important'}}}
                                       onChange={handleChange} name={'email'} />
                            <Box display={'flex'} alignItems={'center'} sx={{mt: '20px'}}>
                                <Button variant={'text'} sx={{textTransform: 'none'}} onClick={handleClose}>{t('Close')}</Button>
                                <LoadingButton size={isMobile ? 'small' : 'medium'}
                                               variant={'contained'}
                                               sx={{ml: !revDir ? '6px' : undefined, mr: revDir ? '6px' : undefined}}
                                               disabled={!isOk || isLoadingUpdate || isLoadingData}
                                               onClick={handleSave} loading={isLoadingUpdate }
                                               loadingPosition={isLoadingUpdate ? 'end' : undefined}
                                               endIcon={isLoadingUpdate ? <div style={{width: '10px', height: '10px', marginLeft: revDir ? '12px' : undefined, marginRight: !revDir ? '12px' : undefined }}/> : undefined}
                                >
                                    {t('Save')}
                                </LoadingButton>
                            </Box>

                        </Box>
                        :
                        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} width={'578px'}>
                            <Box display={'flex'} alignItems={'center'} sx={{mt: '20px'}} width={'100%'}>
                                <Typography variant={'body1'} sx={{color: colors.lightGrayText}}>{t('Start date')}</Typography>
                                <Typography variant={'body1'} sx={{color: colors.grayText, ml: !revDir ? '8px' : undefined, mr: revDir ? '8px' : undefined}}>{scheduler?.startDate}</Typography>
                            </Box>
                            <Box display={'flex'} alignItems={'center'} sx={{mt: '16px'}} width={'100%'}>
                                <Typography variant={'body1'} sx={{color: colors.lightGrayText}}>{t('Include to running check')}</Typography>
                                <Typography variant={'body1'} sx={{color: colors.grayText, ml: !revDir ? '8px' : undefined, mr: revDir ? '8px' : undefined}}>{scheduler?.isAllActiveRules ? t('All active rules') : t('All rules')}</Typography>
                            </Box>
                            <Box display={'flex'} alignItems={'center'} sx={{mt: '16px'}} width={'100%'}>
                                <Typography variant={'body1'} sx={{color: colors.lightGrayText}}>{t('Repeat')}</Typography>
                                <Typography variant={'body1'} sx={{color: colors.grayText, ml: !revDir ? '8px' : undefined, mr: revDir ? '8px' : undefined}}>
                                    {`${t('Repeat every')} ${scheduler && getRepeatTimeString(scheduler.repeatNumber, scheduler.repeatTime)}`}
                                </Typography>
                            </Box>
                            <Box display={'flex'} alignItems={'center'} sx={{mt: '16px'}} width={'100%'}>
                                <Typography variant={'body1'} sx={{color: colors.lightGrayText}}>{t('End date')}</Typography>
                                <Typography variant={'body1'} sx={{color: colors.grayText, ml: !revDir ? '8px' : undefined, mr: revDir ? '8px' : undefined}}>{scheduler?.endDate}</Typography>
                            </Box>
                            <Box display={'flex'} alignItems={'center'} sx={{mt: '16px'}} width={'100%'}>
                                <Typography variant={'body1'} sx={{color: colors.lightGrayText}}>{t('Alerts')}</Typography>
                                <Typography variant={'body1'} sx={{color: colors.grayText, ml: !revDir ? '8px' : undefined, mr: revDir ? '8px' : undefined}}>
                                    {scheduler?.alerts ? getAlertsString(scheduler.alerts) : ''}
                                </Typography>
                            </Box>
                            <Box display={'flex'} alignItems={'center'} sx={{mt: '16px'}} width={'100%'}>
                                <Typography variant={'body1'} sx={{color: colors.lightGrayText}}>{t('Email')}</Typography>
                                <Typography variant={'body1'} sx={{color: colors.grayText, ml: !revDir ? '8px' : undefined, mr: revDir ? '8px' : undefined}}>
                                    {scheduler?.email}
                                </Typography>
                            </Box>

                            <Button variant={'outlined'} sx={{mt: '32px', width: 'fitContent'}} disabled={isLoadingData} onClick={handleEdit}>{t('Edit')}</Button>
                        </Box>
                    }

                </Box>
            }

            {tab === 'history' &&
                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                    <Box display={'flex'} alignItems={'center'} marginTop={'11px'}>
                        <SearchField
                            size={'small'}
                            sx={{ml: revDir ? '10px' : undefined, mr: !revDir ? '10px' : undefined, width: '320px'}}
                            variant={'outlined'}
                            placeholder={t('Search')}
                            onClear={() => {setFilter('input'); handleChangeValue({target: {value: ''}})}}
                            value={value}
                            disabled={isLoadingData}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                        />

                        <TextField label={t('Sort by last checking date')} select size={'small'}
                                   value={filter.date?.[0] ?? 'Any'}
                                   sx={{ml: revDir ? '10px' : undefined, mr: !revDir ? '10px' : undefined, width: '320px', '& label': {top: '0 !important'}}}
                                   disabled={isLoadingData}
                        >
                            <MenuItem key={'Any'} value={'Any'} onClick={() => {setFilter('date')}}>{t('Any')}</MenuItem>
                            {/*{["Low", "High", "Medium"].map((e) => (<MenuItem key={e} value={e} onClick={() => {setFilter('date', e)}}>{e}</MenuItem>))}*/}
                        </TextField>

                        <TextField label={t('Sort by severity level')} select size={'small'}
                                   value={filter.severity?.[0] ?? 'Any'}
                                   sx={{ml: revDir ? '10px' : undefined, mr: !revDir ? '10px' : undefined, width: '320px', '& label': {top: '0 !important'}}}
                                   disabled={isLoadingData}
                        >
                            <MenuItem key={'Any'} value={'Any'} onClick={() => {setFilter('severity')}}>{t('Any')}</MenuItem>
                            {["Low", "High", "Medium"].map((e) => (<MenuItem key={e} value={e} onClick={() => {setFilter('severity', e)}}>{e}</MenuItem>))}
                        </TextField>
                    </Box>

                    <TableContainer onScroll={onScroll} ref={scrollRef} sx={{mt: '22px', minHeight: '60px'}} className={'withoutBorder'}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {t('Last checking date')}</TableCell>
                                    <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {t('Rule name')}</TableCell>
                                    <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {t('Severity level')}</TableCell>
                                    <TableCell sx={{maxWidth: '300px'}} align={revDir ? 'right' : 'left'}> {t('Security risk')}</TableCell>
                                    <TableCell sx={{maxWidth: '250px'}} align={revDir ? 'right' : 'left'}> {t('Security mitigation')}</TableCell>
                                    <TableCell align={revDir ? 'right' : 'left'} >{t('Found conflicts')}</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {history.map((e) => (
                                    <TableRow key={e?.rule.id}>
                                        <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {e?.lastCheckingDate}</TableCell>
                                        <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {e?.rule.name}</TableCell>
                                        <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {e.severityLevel}</TableCell>
                                        <TableCell sx={{maxWidth: '300px'}} align={revDir ? 'right' : 'left'}> {e.securityRisk}</TableCell>
                                        <TableCell sx={{maxWidth: '250px'}} align={revDir ? 'right' : 'left'}> {e.mitigation}</TableCell>
                                        <TableCell align={revDir ? 'right' : 'left'} >
                                            {e.conflicts.length > 0 ?
                                                <Button variant={'text'} sx={{}}>{`${e.conflicts.length} ${t('conflicts')}`}</Button>
                                                : t('No conflicts found')
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {(bottomLoader || isLoadingData) && <TableRowSkeleton columns={6} />}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Box>
            }
        </Box>
    )
}