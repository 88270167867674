import React, {FC, MouseEvent, useEffect, useState} from "react";
import {TIdNameLogo} from "../../types";
import {CommonAvatar} from "../Basic/CommonAvatar";
import {AvatarGroup as MuiAvatarGroup, Box, IconButton, Popover, Typography} from "@mui/material";
import {Clear} from "@mui/icons-material";
import {AddAvatarButton} from "../Basic/AddAvatarButton";
import {useMainTranslation} from "../../hooks/useMainTranslationHooks/useMainTranslation";
import colors from "../../theme/colors";


type TProps = {
    assignments: TIdNameLogo[],

    handleDelete?: (person: TIdNameLogo) => void,
    handleAddButton?: () => void,
    maxItems?: number,
    disabled?: boolean,
}
export const AvatarGroup: FC<TProps> = ({assignments, handleDelete, handleAddButton, maxItems = 3, disabled = false}) => {
    const {revDir} = useMainTranslation('', {keyPrefix: 'CommonComponents'});

    const [ids, setIds] = useState<string[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleOpen = (event: MouseEvent<HTMLElement>) => {setAnchorEl(event.currentTarget);};
    const handleClose = () => {setAnchorEl(null);};

    useEffect(() => {
        setIds(prev => prev.filter(value => assignments.some(a => a.id === value)))
    }, [assignments])

    useEffect(() => {
        ids.length === 0 && handleClose()
    }, [ids])

    return (
        <Box display={"flex"} alignItems={"center"} flexShrink={1}>
            <MuiAvatarGroup max={maxItems}
                            total={assignments.length}
                            componentsProps={{
                                additionalAvatar: {
                                    onClick: (e) => {
                                            e.stopPropagation();
                                            setIds([...assignments.slice(3, assignments.length).map(el => el.id)])
                                            handleOpen(e);
                                        }
                                }
                            }}
                            sx={{
                                '& .MuiAvatar-root': {
                                    maxWidth: '24px', maxHeight: '24px',
                                    fontSize: '12px',
                                },
                                '& .MuiAvatar-root:hover': {
                                    cursor: 'pointer'
                                },
                                '& .MuiAvatar-root:last-child': {
                                    marginLeft: !revDir ? undefined : '-8px'
                                },
                            }}>
                {assignments.map((assignment, id) => (
                    <CommonAvatar key={assignment.id}
                                  name={assignment.name}
                                  size={"d24"}
                                  alt={assignment.name}
                                  src={assignment.logo || undefined}
                                  sx={{backgroundColor: ((id % 2) !== 0) ? colors.primary.blue : colors.text.success}}
                                  onClick={(e: any) => {
                                      e.stopPropagation();
                                      setIds([assignment.id]);
                                      handleOpen(e);
                                  }}
                    />
                ))}
            </MuiAvatarGroup>

            {handleAddButton && (
                <AddAvatarButton onClick={handleAddButton} btnSize={'d24'} disabled={disabled}
                                 sx={{
                                     marginRight: !revDir ? undefined : '8px',
                                 }}/>
            )}

            {anchorEl && (
                <Popover open={Boolean(anchorEl)}
                         anchorEl={anchorEl}
                         anchorOrigin={{
                             horizontal: "left",
                             vertical: "bottom"
                         }}
                         sx={{'& .MuiPopover-paper': {padding: '6px', borderRadius: '8px', minWidth: '120px'}}}
                         onClick={e => {e.stopPropagation()}}
                         onClose={() => {setIds([]); handleClose()}}
                >
                    {assignments.filter(e => ids.includes(e.id)).map((person, index) => (
                        <Box display={"flex"} alignItems={'center'} justifyContent={"space-between"} key={person.id}
                             m={index !== 0 ? '4px 0 0 0' : undefined}>
                            <Typography variant={"subtitle1"} margin={'0 5px 0 0'}>
                                {person.name || ''}
                            </Typography>
                            {handleDelete && (
                                <IconButton color={"error"} sx={{width: '20px', height: '20px', marginTop: '3px'}}
                                            onClick={() => {handleDelete(person)}}>
                                    <Clear sx={{width: '16px', height: '16px'}}/>
                                </IconButton>
                            )}
                        </Box>
                    ))}
                </Popover>
            )}
        </Box>

    )
}
