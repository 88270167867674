import React, {FC, useEffect, useState} from "react";
import {Button, Typography} from "@mui/material";
import {SmallSizeDialogStyled} from "../manageActorsDialog/styled";
import {
    AddVariableDialogActionsStyled,
    AddVariableDialogContentStyled,
    AddVariableDialogTitleStyled
} from "../addVariableDialog/styled";
import {FlexColumn} from "../../editorTitleWithActionsRow/styled";
import {createEventCommonDialogOpen} from "../../../helpers";
import {TCommonDialogOpenEventData} from "../../../types";
import {commonDialogOpenEventName} from "../../../constants";

export const RecipientWithNoFieldsDialog: FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [recipients, setRecipients] = useState<string[]>([]);

    const type: TCommonDialogOpenEventData["type"] = "emptyRecipient";
    const text = `There is no assigned fillable fields for ${recipients.length === 1 ? 'the recipient' : 'those recipients'}. Please assign at least one to continue.`;
    const title = 'Recipient with no fillable fields';

    const handleClose = () => {
        createEventCommonDialogOpen({type, isOpen: false, data: []});
    };

    const handleEvent = (ev: Event) => {
        const {detail} = ev as CustomEvent<TCommonDialogOpenEventData>;
        if (detail.type === type) {
            setIsOpen(detail.isOpen);
            setRecipients(detail.data);
        }
    }

    useEffect(() => {
        document.addEventListener(commonDialogOpenEventName, handleEvent);
        return () => {
            document.removeEventListener(commonDialogOpenEventName, handleEvent);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <SmallSizeDialogStyled open={isOpen} onClose={handleClose}>
            <AddVariableDialogTitleStyled>
                {title}
            </AddVariableDialogTitleStyled>

            <AddVariableDialogContentStyled>
                <Typography>{text}</Typography>

                <FlexColumn sx={{gap: '8px' }}>
                    {recipients.map((recipient, index) => (
                        <Typography key={index} variant={"subtitle1"}>{recipient}</Typography>
                    ))}
                </FlexColumn>
            </AddVariableDialogContentStyled>

            <AddVariableDialogActionsStyled>
                <Button variant={"text"} onClick={handleClose}>{'Close'}</Button>
                {/*<Button variant={"contained"} onClick={onConfirm}>{'Save'}</Button>*/}
            </AddVariableDialogActionsStyled>
        </SmallSizeDialogStyled>
    )
}

