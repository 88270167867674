import * as S from '../../components/styled';
import {Divider, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {integrationLogType} from "../../types";
import React from "react";
import {useDispatch} from "react-redux";
import {deselectLogFetchResult, selectLog} from "../../store/slice";
import {DownloadLogForPreview} from "../../store/actions";
import {MAX_LOG_SIZE_PREVIEW} from "../../constants";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";

export const AppLogsTableRow = ({log, selectedLog}: {log: integrationLogType, selectedLog: integrationLogType | null}) => {
    const dispatch = useDispatch();
    const onClick = () => {
        dispatch(deselectLogFetchResult()); //deleting prev log (its too big to just keep it)
        dispatch(selectLog(log));
        if(log.size < MAX_LOG_SIZE_PREVIEW){
            dispatch(DownloadLogForPreview({fileId: log.fileId}));
        }
    };
    const isSelected = log.id === selectedLog?.id;
    return(
        <>
            <S.ExactAppLogsTableRowWrapper hoverable={!isSelected} onClick={onClick} isRightSideOpened={selectedLog !== null} selected={isSelected}>
                <S.AppHistoryTableHeaderItemWrapper width={'50%'}>
                    <Typography sx={{fontSize: '14px', fontWeight: 400, color: colors.grayText}}>{parseDateAuto(log.timestamp, true)}</Typography>
                </S.AppHistoryTableHeaderItemWrapper>

                <S.AppHistoryTableHeaderItemWrapper width={'50%'}>
                    <Typography sx={{fontSize: '14px', fontWeight: 400, color: colors.grayText}}>{log.id}</Typography>
                </S.AppHistoryTableHeaderItemWrapper>
            </S.ExactAppLogsTableRowWrapper>
            {<Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable, margin: '4px 0 0 0'}}/>}
        </>
    )
}