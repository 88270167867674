import React, {FC} from "react";
import {Flex} from "../../Layouts";
import {Add} from "@mui/icons-material";
import {MenuItem, Typography} from "@mui/material";
import {MenuItemProps} from "@mui/material/MenuItem/MenuItem";
import {useMainTranslation} from "../../../hooks/useMainTranslationHooks/useMainTranslation";
import colors from "../../../theme/colors";

type TAddMenuItem = MenuItemProps & {
    text: string,
}

export const AddMenuItem: FC<TAddMenuItem> = ({text, ...props}) => {
    const {revDir} = useMainTranslation('', {keyPrefix: ''});
    return (
        <MenuItem {...props}>
            <Flex ai={"center"}>
                <Add style={{
                    margin: !revDir ? '0 5px 0 0' : '0 0 0 5px',
                    color: colors.primary.blue,
                    maxWidth: '20px',
                    maxHeight: '20px'
                }}/>
                <Typography color={colors.primary.blue}
                            sx={{lineHeight: '16px', fontWeight: 600, fontSize: '16px'}}>
                    {text}
                </Typography>
            </Flex>
        </MenuItem>
    )
}
