import {SettingsTypes} from "../../types";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_VENDORS_SETTINGS, PATH_LOCAL_VENDORS_SETTINGS_EXACT} from "../../constants";
import {useHistory} from "react-router-dom";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";


export const useVendorsSettings = () =>{
    const history = useHistory();

    const handleGoToSettingById = (id: SettingsTypes) => {
        history.push(PATH_LOCAL_VENDORS_SETTINGS_EXACT.replace(':id', id));
    }

    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    useSetBreadcrumbs([
        {
            title: tMenu('Settings'),
            path: PATH_LOCAL_VENDORS_SETTINGS
        }
    ]);

    return{

        handleGoToSettingById,

    }

}