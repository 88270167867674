import {UpdateLinkPopperTypes} from "../../types";
import React, {useEffect, useState} from "react";

export const useUpdateLinkPopper = (props: UpdateLinkPopperTypes) => {
    const isOpen = Boolean(props.element) && props.isFocused;

    const [form, setForm] = useState<{href: string, title: string}>({href: '', title: ''});

    useEffect(() => {
        if(isOpen){
            setForm({href: props.href ?? '', title: props.title ?? ''})
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const isDomainAlike = (input: string): boolean => {
        // Regular expression to match the domain pattern
        //@ts-ignore
        const domainRegex = /^(?:https?:\/\/)?(?:www\.)?([a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,6})(?:\/[^?#\s]*)?(?:\?[^#\s]*)?(?:#[^\s]*)?$/i;

        return domainRegex.test(input);
    };

    const isDisabled = !isDomainAlike(form.href) || !form.title.trim().length;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm((prevValues) => ({
            ...prevValues,
            [event.target.name]: event.target.value,
        }));
    }

    const handleSubmit = () => {
        if(!isDisabled){
            props.handleUpdateLink(form.href, form.title, props.from, props.to);
            props.handleClose();
        }
    }

    const handleUnlink = () => {
        props.handleRemoveLink(props.from, props.to);
        props.handleClose();
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && !isDisabled) {
            props.handleUpdateLink(form.href, form.title, props.from, props.to);
            props.handleClose();
        }
    };

    return{
        form,
        isDisabled,
        handleChange,
        handleSubmit,
        handleUnlink,
        handleKeyDown
    }
}