import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {LoadingButton} from "../../../../../../../newShared/components/Basic/CommonButtons";
import {useEditControl} from "../../../../hooks/dialogs/useEditControl";
import SaveIcon from '@mui/icons-material/Save';


export const EditControlDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'builderPage.dialogs'});

    const {
        isOpen,
        isLoading,
        form,
        handleChange,
        handleClose,
        handleChangeDesc,
        validateForm,
        onSave,
    } = useEditControl();

    return(
        <Dialog open={isOpen}  onClose={handleClose} >
            <DialogTitle>{t('Edit information')}</DialogTitle>
            <DialogContent>
                <TextField id="filled-basic" required label={t("Name")} size={'small'} variant="outlined" sx={{mt: '8px', '& label': {top: '0 !important'}}} name={'name'} value={form.name} onChange={handleChange}/>
                <TextField id="filled-basic" label={t("Group")} size={'small'} variant="outlined" sx={{mt: '12px', '& label': {top: '0 !important'}}} name={'group'} value={form.group} onChange={handleChange}/>
                <TextField id="filled-basic" label={t("Category")} size={'small'}  variant="outlined" sx={{mt: '12px', '& label': {top: '0 !important'}}} name={'category'} value={form.category} onChange={handleChange}/>
                <TextField id="filled-basic" label={t("Description")} variant="outlined" multiline minRows={5} sx={{mt: '12px'}} value={form.description[0] || ''} onChange={handleChangeDesc}/>
            </DialogContent>

            <DialogActions>
                <Button variant={'text'} sx={{'&.MuiButton-text': {textTransform: 'none'}}} onClick={handleClose}>{t('Close')}</Button>
                <LoadingButton size={'small'} disabled={!validateForm() || isLoading }
                               loadingPosition={isLoading ? 'start' : undefined}
                               startIcon={isLoading ? <SaveIcon /> : undefined}
                               onClick={onSave} loading={isLoading} >
                    {t('Save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
