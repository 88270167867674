import {useDispatch, useSelector} from "react-redux";
import {hideAcceptInviteSuccessDialog, acceptInviteSuccessDialog} from "../../store/slice";
import {useTopBar} from "../../../../barsEnvironment/topBar/hooks/useTopBar";
import {ROLE_PORTAL_MEMBER, ROLE_WS_ADMIN, ROLE_WS_MEMBER} from "../../constants";

export const useAcceptInviteSuccessDialog = () => {
    const dispatch = useDispatch();
    const {isOpen, invite} = useSelector(acceptInviteSuccessDialog);
    const {handleGoToPortal} = useTopBar();
    const workspaceName = invite?.workspaceName;
    const handleClose = () => {dispatch(hideAcceptInviteSuccessDialog())};
    const portalAccess = invite?.roles.some(role => role === ROLE_PORTAL_MEMBER || role === ROLE_WS_ADMIN);
    const mainAccess = invite?.roles.some(role => role !== ROLE_PORTAL_MEMBER && role !== ROLE_WS_MEMBER);

    return {
        isOpen,
        handleClose,
        handleGoToPortal,
        portalAccess,
        mainAccess,
        workspaceName
    }


}