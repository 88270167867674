import {useDispatch, useSelector} from "react-redux";
import {dialogs, editApp, employees, hideEditApp, loadings, selectedAppEditable, vendors} from "../../../store/slice";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {Vendor} from "../../../types";
import {useEffect} from "react";
import {GetDataForCreateApp, UpdateAccessManagementAppNameAndVendor} from "../../../store/actions";
import {CUSTOM_APP_TYPE} from "../../../constants";

export const useEditAppDialog = () => {
    const dispatch = useDispatch();

    const isOpen = useSelector(dialogs).editApp;
    const _vendors = useSelector(vendors);
    const empls = useSelector(employees);
    const app = useSelector(selectedAppEditable);
    const {vendorsAndEmployeesForCreate, editNameAndVendor} = useSelector(loadings);

    useEffect(() => {
        if(!vendorsAndEmployeesForCreate && !_vendors.length){
            dispatch(GetDataForCreateApp());
        }

        if(isOpen && app && !vendorsAndEmployeesForCreate){
            const vendor = _vendors.filter(e => e.owner !== null).find(e => app.vendorId === e.id);
            setForm({name: app.name, vendor: vendor ?? null});
        }
        //eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        //if not fetched before - on dialog open there will be no data - fetching in first useEffect - setting vendor from res here
        if(!vendorsAndEmployeesForCreate && _vendors.length > 0 && app){
            const vendor = _vendors.filter(e => e.owner !== null).find(e => app.vendorId === e.id);
            setForm({name: app.name, vendor: vendor ?? null});
        }
        //eslint-disable-next-line
    }, [vendorsAndEmployeesForCreate])

    const handleClose = () => {
        dispatch(hideEditApp());
        cleanUp();
    }
    const cleanUp = () => {
        setForm({name: '', vendor: null});
    }

    //form
    const {form, setForm, handleChange} = useForm<{name: string, vendor: Vendor | null}>({name: '', vendor: null});

    const handleSetVendor = (vendor: Vendor) => {
        setForm({...form, vendor});
    }

    const isOk = () => {
        return form.name.trim().length > 0 && form.vendor !== null &&
            (form.name.trim() !== app?.name || form.vendor.id !== app?.vendorId)
    }

    const handleSubmit = () => {
        if(isOk() && form.vendor && form.vendor.owner && app){
            if(Boolean(app.type === CUSTOM_APP_TYPE)){
                const empl = empls.find(e => e.id === form.vendor!.owner!.publicId);
                dispatch(editApp({name: form.name, vendorId: form.vendor?.id, idName: empl ? {name: `${empl.firstName} ${empl.lastName}`, id: empl.id} : null}));
            }else{
                //APP IS NOT CUSTOM
                //FETCHING UPDATE
                dispatch(UpdateAccessManagementAppNameAndVendor({id: app.id, name: form.name, vendorId: form.vendor.id, vendorOwner: {id: form.vendor.owner.publicId, name: `${form.vendor.owner.firstName} ${form.vendor.owner.lastName}`}}));
            }
        }
    }

    return{
        isOpen,
        handleClose,
        vendors: _vendors,
        form,
        handleChange,
        handleSetVendor,
        isOk: isOk(),
        handleSubmit,
        app,
        isLoadingVendors: vendorsAndEmployeesForCreate,
        isLoadingEditNameAndVendor: editNameAndVendor,
    }
}