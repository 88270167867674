import {FC} from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useRuleExact} from "../../../hooks/useRuleExact";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

export const TerminateRuleCheckDialog: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        isOpenTerminate: isOpen,
        handleHideTerminateDialog: handleClose,
    } = useRuleExact();

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{'Terminate rule check'}</DialogTitle>
            <DialogContent>{'You can terminate the rule checking process. The system has not detected any conflicts yet. Terminate checking anyway?'}</DialogContent>
            <DialogActions>
                <Button variant={"text"} sx={{textTransform: 'none'}} onClick={handleClose} >{t('Cancel')}</Button>
                {/*<Button sx={{textTransform: 'none'}} onClick={handleTerminate}>{t('Terminate')}</Button>*/}
            </DialogActions>
        </Dialog>
    )
}
