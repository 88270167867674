import {Box, IconButton, styled, TextField} from "@mui/material";
import colors from "../../../../../../newShared/theme/colors";
import {TREE_NESTED_SHIFT_PX} from "../../../constants";
import {TypographyBody2, TypographyBodySmall} from "../../../../../../newShared/components/Inputs/styled";


export const FolderNameTextField = styled(TextField)(() => ({
    '& .MuiInputBase-root': {
        height: '40px',
    },
    marginTop: '10px',
}));
FolderNameTextField.defaultProps = {
    size: 'small'
}

export const SelectPopperArea = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    borderTop: `1px solid ${colors.stroke.grey}`,
    maxHeight: '294px',
    overflow: 'auto',
    flexDirection: 'column',
}));

export const SelectTreeMenuItem = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%'
}));

export const TreeItemBox = styled(Box, {
    shouldForwardProp: (propName) => (
        !["revDir", "isSelected", "currPathLength"].includes(propName.toString())
    )})(({isSelected, revDir, currPathLength}: {isSelected: boolean, currPathLength: number, revDir: boolean}) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    backgroundColor:  isSelected ? colors.navButtonActive : 'initial',
    borderRadius: '5px',
    // width: `calc(100% - ${currPathLength > 1 ? currPathLength * TREE_NESTED_SHIFT_PX : 0}px)`,
    paddingRight: revDir ? currPathLength * TREE_NESTED_SHIFT_PX : 0,
    paddingLeft: !revDir ? currPathLength * TREE_NESTED_SHIFT_PX : 0,

    "&: hover": {
        backgroundColor: colors.backgrounds.grey_light
    }
}));

export const FolderSelectContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
}))


export const FolderSelectBox = styled(Box, {
    shouldForwardProp: (propName) => (
        !["isActive"].includes(propName.toString())
    )})(({isActive}: {isActive?: true}) => ({
    display: 'flex',
    marginTop: '10px',
    width: '100%',
    height: '40px',
    alignItems: 'center',
    border: `1px solid ${isActive ? colors.primary.blue : colors.stroke.grey}`,
    borderRadius: '4px',
    padding: '8px 16px'
}))

export const TreeItemTitle = styled(TypographyBodySmall)(() => ({
    marginLeft: '10px',
    marginRight: '10px',
    cursor: 'pointer'
}))
export const TreeIconButton = styled(IconButton)(() => ({
    // marginLeft: 'auto'
}));
TreeIconButton.defaultProps = {
    size: 'medium'
}

export const TypographyBody2Grey = styled(TypographyBody2, {
    shouldForwardProp: (propName) => (
        !["isFirst"].includes(propName.toString())
    )})(({isFirst}: {isFirst?: true}) => ({
    color: colors.text.grey,
    marginLeft: isFirst ? undefined : '8px',
    marginRight: isFirst ? undefined : '8px',
}));




