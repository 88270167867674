import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../../../newShared/components/Layouts";
import AddIcon from "@mui/icons-material/Add";
import colors from "../../../../../newShared/theme/colors";
import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import React from "react";
// import {SearchField} from "../../../../../newShared/components/Basic/CommonInputs";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {EditSettingDialog} from "../../components/Dialogs/EditSettingDialog";
import {DeleteSettingDialog} from "../../components/Dialogs/DeleteSetting";
import {CreateSettingRowDialog} from "../../components/Dialogs/CreateSettingRowDialog";
import {TableRowSkeleton} from "../../../../../newShared/components/tableRowSkeleton";
import {useExactVirtualSettings} from "../../hooks/useExactVirtualSettings";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";


export const ExactVirtualSettings = () => {
    const {isMobile, isTablet, isLessThen1050} = useMedia();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});

    const {
        settingsView,
        id,
        isLoadingSettings,
        settingTitle,
        handleCreateNewItem,
        handleGoBack,

        // search,
        // setSearch,
        // handleCleanSearch,
        // handleEnterKey,
        setEditSettingRowDialog,
        setDeleteSettingRowDialog
    } = useExactVirtualSettings();

    return (
        <Flex w={'100%'} h={'100%'} direction={'column'} overflowy={(isMobile || isTablet) ? "scroll" : undefined}>
            <PageTitleAndActions
                title={t(settingTitle)}
                handleGoBack={handleGoBack}
                showMenuWhen={isMobile}
                actions={[
                    {
                        title: t('Add new item'),
                        onClick: () => handleCreateNewItem(id),
                        variant: 'text',
                        icon: <AddIcon style={{marginLeft: revDir ? '8px' : undefined}}/>,
                        disabled: isLoadingSettings
                    },
                ]}
            />

            {!isLessThen1050 ?
                <TableContainer sx={{marginTop: '10px'}}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell width={isMobile ? 150 : isTablet ? 320 : 520} align={revDir ? 'right' : 'left'}>{t('Title')}</TableCell>
                                {id !== 'customFields' && <TableCell width={isMobile ? 60 : 200} align={'center'}>{t('Assets')}</TableCell>}
                                <TableCell width={40} align={revDir ? 'right' : 'left'}/>
                                <TableCell align={revDir ? 'right' : 'left'} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { settingsView?.[id] && Object.keys(settingsView[id]).map((key) => (
                                <TableRow key={key} >
                                    <TableCell align={revDir ? 'right' : 'left'}>{key}</TableCell>
                                    {id !== 'customFields' && <TableCell align={'center'}>{settingsView[id][key]}</TableCell>}
                                    <TableCell onClick={() => setEditSettingRowDialog({isOpen: true, field: id, oldTitle: key, assetType: 'virtual'})} align={revDir ? 'right' : 'left'}>
                                        <IconButton
                                            sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}
                                            size={isLessThen1050 ? 'small' : 'large'}>
                                            <EditIcon/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align={revDir ? 'right' : 'left'}>
                                        <IconButton
                                            sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}
                                            onClick={() => setDeleteSettingRowDialog({isOpen: true, field: id, oldTitle: key, assetType: 'virtual'})}
                                            size={isLessThen1050 ? 'small' : 'large'}>
                                            <DeleteForeverIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>

                            ))}

                            {isLoadingSettings && id === 'customFields' && <TableRowSkeleton columns={2}/>}
                        </TableBody>
                    </Table>
                </TableContainer>

                :
                <Table>
                    <TableHead>
                        <TableRow >
                            <TableCell width={isMobile ? 150 : isTablet ? 320 : 520} align={revDir ? 'right' : 'left'}>{t('Title')}</TableCell>
                            {id !== 'customFields' && <TableCell width={isMobile ? 60 : 200} align={'center'}>{t('Assets')}</TableCell>}
                            <TableCell width={40} align={revDir ? 'right' : 'left'}/>
                            <TableCell align={revDir ? 'right' : 'left'} />
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        { settingsView?.[id] && Object.keys(settingsView[id]).map((key) =>  key && (
                            <TableRow key={key} >
                                <TableCell align={revDir ? 'right' : 'left'} >{key}</TableCell>
                                {id !== 'customFields' && <TableCell align={revDir ? 'right' : 'left'}>{settingsView[id][key]}</TableCell>}
                                <TableCell onClick={() => setEditSettingRowDialog({isOpen: true, field: id, oldTitle: key, assetType: 'virtual'})} align={revDir ? 'right' : 'left'}>
                                    <IconButton
                                        sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}
                                        size={isLessThen1050 ? 'small' : 'large'}>
                                        <EditIcon/>
                                    </IconButton>
                                </TableCell>
                                <TableCell align={revDir ? 'right' : 'left'}>
                                    <IconButton
                                        sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}
                                        onClick={() => setDeleteSettingRowDialog({isOpen: true, field: id, oldTitle: key, assetType: 'virtual'})}
                                        size={isLessThen1050 ? 'small' : 'large'}>
                                        <DeleteForeverIcon/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>

                        ))}

                    </TableBody>
                </Table>
            }

            <EditSettingDialog />
            <CreateSettingRowDialog />
            <DeleteSettingDialog />

        </Flex>
    )
}
