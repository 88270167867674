import {AvatarGroup, Box, Button, IconButton, Menu, styled, TableCell} from "@mui/material";
import colors from "../../../../../../newShared/theme/colors";
import {TypographyBody2} from "../../../../../../newShared/components/Inputs/styled";
import {CommonAvatar} from "../../../../../../newShared/components/Basic/CommonAvatar";


export const DocumentsTableWrapper = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '15px',
    flexGrow: 1,
    overflow: 'hidden',
    position: 'relative',
    '& .file-drop': {
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,

        '& .file-drop-target': {
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
    },
}));



export const DocNameTableCell = styled(TableCell)(() => ({
    width: '560px',
}));

export const DocLastModifiedTableCell = styled(TableCell)(() => ({
    width: '220px',
}));

export const DocRecipientsTableCell = styled(TableCell)(() => ({
    width: '250px',

}));

export const DocStatusTableCell = styled(TableCell)(() => ({
    width: '300px',

}));

export const DocExpiredTableCell = styled(TableCell)<{color?: string}>(({color}) => ({
    width: '200px',
    color: color,
}));

export const DocActionsTableCell = styled(TableCell)(() => ({
    width: '100px',

}));

//TableBody

// export const ConfigTableCell = styled(TableCell)(() => ({
//     padding: '10.5px 16px',
// }));

export const ActionIconButton = styled(IconButton, {
    shouldForwardProp: (propName) => (
        !["revDir"].includes(propName.toString())
    )})(({revDir}: {revDir?: true}) => ({
    margin: !revDir ? '0 0 0 16px' : '0 16px 0 0',
}));
ActionIconButton.defaultProps = {
    size: 'small',
    color: 'primary',
}

export const DocumentRowActionMenu = styled(Menu)(() => ({
    '& .MuiMenu-list': {
        padding: '10px 0'
    },
    '& .MuiMenuItem-root': {
        margin: '0 auto',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: '#F2F4F7'
        }
    }
}));

DocumentRowActionMenu.defaultProps = {
    MenuListProps: {'aria-labelledby': 'long-button'},
    PaperProps: {
        style: {
            boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)',
            borderRadius: '8px',
            border: '1px solid #F2F4F7'
        },
    }
}

export const DocumentAvatarGroup = styled(AvatarGroup, {
    shouldForwardProp: (propName) => (
        !["revDir"].includes(propName.toString())
    )})(({revDir}:{revDir?: true}) => ({
    justifyContent: 'flex-end',
    '& .MuiAvatar-root': {
        maxWidth: '24px',
        maxHeight: '24px',
        fontSize: '12px',
    },
    '& .MuiAvatar-root:hover': {
        cursor: 'pointer'
    },
    '& .MuiAvatar-root:last-child': {
        marginLeft: !revDir ? undefined : '-8px'
    },
}));

export const DocumentAvatar = styled(CommonAvatar)(({index}: {index: number}) => ({
    backgroundColor: index === 0 ? colors.primary.blue
        : index === 1 ? colors.decorative.lilac
            : index === 2 ? colors.decorative.orange
                : index === 3 ? colors.decorative.violet
                    : 'transparent',
}));
DocumentAvatar.defaultProps = {
    size: "d32",
}

export const ActionButton = styled(Button)(() => ({
    borderRadius: "2px",
    padding: "6px 23.5px"
}));

ActionButton.defaultProps = {
    size: 'small',
    variant: 'contained'
}

export const MenuItemBox = styled(Box)(() => ({
    padding: '0 10px',
    display: 'flex',
    alignItems: 'center',

    '& svg': {
        color: colors.text.grey_dark
    }
}));

export const MenuItemText = styled(TypographyBody2)(() => ({
    marginLeft: '16px',
    color: colors.text.dark,
}));


