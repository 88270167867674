import {useHistory} from "react-router";
import {PATH_LOCAL_COLLECTORS} from "../../../../../newShared/constants";
import {useParams} from "react-router-dom";

export const useRoutes = () => {

    const history = useHistory();
    const {collectorId} = useParams<{collectorId?: string}>();

    const goToCollectors = () => {
        history.push(PATH_LOCAL_COLLECTORS)
    }
    const goToCollector = (id: string) => {
        history.push(`${PATH_LOCAL_COLLECTORS}/${id}`)
    }

    return {
        collectorId,

        goToCollectors,
        goToCollector
    }
}