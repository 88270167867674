import {TableCell, TableHead, TableRow, Typography} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const TasksTableHeader = () => {
    const {revDir} = useMainTranslation('', {keyPrefix: 'todo'});
    const textColor = '#667085';

    return(
        <TableHead>
            <TableRow sx={{
                '& .MuiTableCell-root': {padding: '8px'}
            }}>
                <TableCell sx={{ width: '45%'}} align={!revDir ? 'left' : 'right'}>
                    <Typography variant={'body2'} color={textColor}>{('Steps')}</Typography>
                </TableCell>

                <TableCell sx={{ width: '10%'}} align={!revDir ? 'left' : 'right'}>
                    <Typography variant={'body2'} color={textColor}>{('Status')}</Typography>
                </TableCell>

                <TableCell sx={{ width: '15%'}}>
                    <Typography variant={'body2'} color={textColor}>{('Responsible')}</Typography>
                </TableCell>

                <TableCell sx={{ width: '15%'}} align={!revDir ? 'left' : 'right'}>
                    <Typography variant={'body2'} color={textColor}>{('Tasks')}</Typography>
                </TableCell>

                <TableCell sx={{ width: '15%'}} align={!revDir ? 'left' : 'right'}>
                    <Typography variant={'body2'} color={textColor}>{('Frameworks')}</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
}