import {Dialog, styled} from "@mui/material";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";


export const CommonDialog = styled(Dialog)(() => ({
    '.MuiPaper-root': {
        minWidth: '678px',
        maxHeight: '800px'
    },
}));

export const DialogLoadingButton = styled(LoadingButton)(() => ({

}));

DialogLoadingButton.defaultProps = {
    size: 'small',
    variant: 'contained',
}

export const NameSpan = styled('span')(() => ({
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
}));


