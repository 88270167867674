//core
import {createSelector, createSlice} from "@reduxjs/toolkit";

//types
import {configInitialState} from '../types';
import {AppState} from "../../../../newShared/redux/rootReducer";

//actions
import {GetConfig, UpdateConfig} from "./actions";

const initialState: configInitialState = {
    accountsMappedSchema:{
        choosedVariant: null,
        userChoose: null,
        variants: null,
    },
    profilesMappingSchema: {
        choosedVariants: [],
        userChooses: [],
        variants: []
    },
    isLoading: true,
    isChanged: false
}

const ConfigSlice = createSlice({
    name: "config",
    initialState,
    reducers:{
        updateUserChoose(state, {payload}){
            state.accountsMappedSchema.userChoose = payload.accountsMappedSchema;
        },
        updateProfileChoose(state, {payload}){
            state.profilesMappingSchema.userChooses = payload;
        },
        resetChooses(state){
            state.accountsMappedSchema.userChoose = state.accountsMappedSchema.choosedVariant;
            state.profilesMappingSchema.userChooses = state.profilesMappingSchema.choosedVariants;
            state.isChanged = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetConfig.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(GetConfig.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(GetConfig.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.accountsMappedSchema = {
                    choosedVariant: payload.config.accountsMappingSchema,
                    userChoose: payload.config.accountsMappingSchema,
                    variants: payload.configValues.accountsMappingSchema
                }
                slice.profilesMappingSchema = {
                    choosedVariants: payload.config.profilesMappingSchema,
                    userChooses: payload.config.profilesMappingSchema,
                    variants: payload.configValues.profilesMappingSchema
                }

            })

            .addCase(UpdateConfig.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(UpdateConfig.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(UpdateConfig.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.accountsMappedSchema.userChoose = payload.accountsMappingSchema;
                slice.accountsMappedSchema.choosedVariant = payload.accountsMappingSchema;

                slice.profilesMappingSchema.choosedVariants = payload.profilesMappingSchema;
                slice.profilesMappingSchema.userChooses = payload.profilesMappingSchema;
                slice.isChanged = false;
            })
    }
});

export const ConfigReducer = ConfigSlice.reducer;

//actions
export const {updateUserChoose, updateProfileChoose, resetChooses} = ConfigSlice.actions;

// selectors
const selectSelf = (state: AppState):configInitialState => state.Config as configInitialState;

export const isLoading = createSelector(selectSelf, state => state.isLoading);
export const accountsMappedSchema = createSelector(selectSelf, state => state.accountsMappedSchema);
export const profilesMappingSchema = createSelector(selectSelf, state => state.profilesMappingSchema);
// export const isAddApp = createSelector(selectSelf, state => state.isAddApp);
