import styled from "styled-components";
import {FC} from "react";

const WithOutShrink = styled.div`
  flex-shrink: 0;
`;

type TWithShrinkZeroResponse = <T>(Component: FC<T>) => FC<T>;

export const withShrinkZero: TWithShrinkZeroResponse = (Component) => {
    return (props) => {
        return <WithOutShrink><Component {...props}/></WithOutShrink>
    }
}



