import React from "react";
import {Flex} from '../../../../../../newShared/components/Layouts';
import {useWorkspacesExact} from "../../../hooks/organizations/useWorkspacesExact";
import colors from "../../../../../../newShared/theme/colors";
import {Box, Tab, Tabs, Typography} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import CheckIcon from '@mui/icons-material/Check';

//icons
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from "@mui/icons-material/Edit";

import {TWorkspaceCollaborator, TWorkspaceInvite, TWorkspaceRole, workspacesTabs} from "../../../types";
import {TabContext} from "@mui/lab";
import {PreviewRoleDialog} from "../../../components/organizations/exactWorkspace/dialogs/previewRoleDialog";
import {DeleteWorkspaceUser} from "../../../components/organizations/exactWorkspace/dialogs/deleteWorkspaceUser";
import {DeleteWorkspaceRole} from "../../../components/organizations/exactWorkspace/dialogs/deleteWorkspaceRole";
import {ChangeWorkspaceName} from "../../../components/organizations/exactWorkspace/dialogs/changeWorkspaceName";
import {DeleteWorkspaceDialogs} from "../../../components/organizations/exactWorkspace/dialogs/deleteWorkspace";
import {
    InviteToWorkspaceDialog
} from "../../../components/organizations/exactWorkspace/dialogs/inviteToWorkspaceDialog";
import {ManageUserAccessDialog} from "../../../components/organizations/exactWorkspace/dialogs/manageUserAccessDialog";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";
import {makeFirstLetterUpper} from "../../../../../../newShared/utils/text";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import CancelIcon from "@mui/icons-material/Cancel";
import EmailIcon from "@mui/icons-material/Email";
import {GenericTable} from "../../../../../../newShared/components/genericTable";
import {UseManageWorkspacesAndOrganizations} from "../../../../cookies/hooks/useManageWorkspacesAndOrganizations";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import {Spinner} from "../../../../../../newShared/components/Spinner";
import PreviewIcon from "@mui/icons-material/Preview";
import {IconTextButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import AddIcon from '@mui/icons-material/Add';
import {ImportCsvDialog} from "../../../../../../newShared/components/csvImportDialog";
import {useImportCsvCollaborators} from "../../../hooks/organizations/dialogs/useInportCsvCollaborators";
import {ROLE_PORTAL_MEMBER, ROLE_WS_OWNER} from "../../../constants";

export const WorkspacesExact = () => {
    const {
        workspace,
        actions,
        tab,
        handleSetTab,
        users,
        isLoadingUsers,
        roles,
        // isOrgAdminAndNotMemberOfWS,
        // isNoPlace,
        // workspaceRoles,
        // handleUpdateRoleOfInvited,
        // isLoadingChangeInviteRole,
        handleCancelInvite,
        handleResendInvite,
        handleDeleteUser,
        handleOpenRoleManage,
        isLoadingResendInvite,
        isLoadingCancelInvite,
        handlePreviewRole,
        handleEditRole,
        handleDeleteRole,
        isWorkspaceOwner,
        isWorkspaceAdmin
    } = useWorkspacesExact();
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'pathSettings.WorkspacesExact'});
    const {isLessThen1050, isMobile, isLessThenCustomQuery} = useMedia(858);
    const {user} = UseManageWorkspacesAndOrganizations();
    const importCsv = useImportCsvCollaborators();

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={workspace?.name}
                handleGoBack={actions.handleGoBack}
                showMenuWhen={isLessThen1050}
                actions={[
                    {
                        title: t('Change name'),
                        variant: 'text',
                        disabled: !workspace || (!isWorkspaceOwner && !isWorkspaceAdmin),
                        icon: <EditIcon />,
                        onClick: actions.handleOpenChangeNameDialog,
                    },
                    {
                        title: t('Delete workspace'),
                        variant: 'text',
                        disabled: !workspace || (!isWorkspaceOwner && !isWorkspaceAdmin),
                        icon: <DeleteForeverIcon />,
                        onClick: actions.handleOpenDeleteWorkspaceDialog,
                    }
                ]}
            />

            <TabContext value={tab}>
                <Box sx={{ margin: '8px 0 0 0'}}>
                    <Tabs variant={'scrollable'} value={tab} onChange={(e, newValue: workspacesTabs) => handleSetTab(newValue)} aria-label="basic tabs example">
                        <Tab label={t("Collaborators")} value={'users'} sx={{paddingLeft: '8px'}}/>
                        <Tab label={t("Roles")} value={'roles'}/>
                        <Tab label={t("Notifications")} value={'notifications'} disabled/>
                        {/*<Tab label={t("KYC")} value={'kyc'}/>*/}
                    </Tabs>
                </Box>

                {tab === 'users' &&
                    <>
                            <Flex>
                                <IconTextButton
                                    variant={'text'}
                                    icon={<AddIcon fontSize="small"/>}
                                    onClick={actions.handleOpenAddUser}
                                    disabled={!workspace || (!isWorkspaceOwner && !isWorkspaceAdmin)}
                                    size={'small'}
                                >{isLessThenCustomQuery ? t('Add') : t('Invite collaborator')}
                                </IconTextButton>
                            </Flex>

                        {isLoadingUsers ? <Spinner /> :
                            <GenericTable<{user?: TWorkspaceCollaborator, invite?: TWorkspaceInvite}>
                                id={'WorkspaceExact-users'}
                                columnsConfig={{totalName: 'Total collaborators', forceShowCardsWhen: isMobile}}
                                disableSkeletons
                                paging={{
                                    pageInfo: {page: 0, total: users.collabs.length ?? 0, count: users.collabs.length ?? 0},
                                    isLoading: isLoadingUsers,
                                    minLoadedPage: 0,
                                    maxLoadedPage: 0,
                                    setMinMaxLoadedPage: () => null,
                                    clearRows: () => null,
                                    fetchPaging: () => null,
                                }}
                                rowsConfig={{
                                    rows: [...users.collabs.map(e => ({user: e, invite: undefined})), ...users.invites.map(e => ({user: undefined, invite: e}))],
                                    customCellPaddings: '8px',
                                    getRowUniqueId: (row) => row.user?.publicId ?? row.invite?.id ?? 'row',
                                    onRowClick: () => null,
                                    columnsConfigs: [
                                        {name: t('Name'), key: 'name', default: true, valueRenderer: (row) => {
                                                const isCurrentUser = row.user?.publicId === user?.publicId;
                                                return(
                                                    <>
                                                        {row.user !== undefined ?
                                                            <Flex w={'100%'} ai={'center'}>
                                                                {isCurrentUser &&
                                                                    <Flex w={'38px'} ai={'center'} jc={'center'} background={colors.decorative.orange} br={'4px'} m={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>
                                                                        <Typography variant={'*bodyText2'} color={colors.text.white} sx={{boxSizing: 'border-box'}}>{t('YOU')}</Typography>
                                                                    </Flex>
                                                                }
                                                                <Typography variant={'*bodyText2'} color={colors.darkText}>{row.user.firstName} {row.user.lastName}</Typography>
                                                            </Flex>
                                                            :
                                                            <Typography variant={'*bodyText2'}>{t('Pending for activation')}</Typography>
                                                        }
                                                    </>
                                                )
                                            }},
                                        {name: t('Email'), key: 'email', default: true, valueGetter: (row) => row.user?.email ?? row.invite?.emailAddress},
                                        {name: t('Roles'), key: 'role', default: true, valueRenderer: (row) => {
                                                if(row.user){
                                                    return(
                                                        <Flex w={'100%'} direction={'column'}>
                                                            {!row.user.roles.length && <Typography variant={'*bodyText2'}>{t('No roles')}</Typography>}
                                                            {row.user.roles.filter(e => e.name !== ROLE_PORTAL_MEMBER).map((e, id) => <Typography key={e.id} variant={'*bodyText2'} sx={{margin: !id ? '0' : '12px 0 0 0'}}>{e.name}</Typography>)}
                                                        </Flex>
                                                    )
                                                }else if(row.invite){
                                                    return(
                                                        <Flex w={'100%'} direction={'column'}>
                                                            {!row.invite.roles.length && <Typography variant={'*bodyText2'}>{t('No roles')}</Typography>}
                                                            {row.invite.roles.filter(e => e !== ROLE_PORTAL_MEMBER).map((e, id) => <Typography key={e} variant={'*bodyText2'} sx={{margin: !id ? '0' : '12px 0 0 0'}}>{e}</Typography>)}
                                                        </Flex>
                                                    )
                                                }
                                                return <></>;
                                            }},
                                        {name: t('Portal access'), key: 'portalAccess', default: true, valueRenderer: (row) => {
                                                if(row.user){
                                                    return(
                                                        <Flex w={'100%'} direction={'column'}>
                                                            {row.user.roles.some(e => e.name === ROLE_PORTAL_MEMBER) ? <CheckIcon style={{color: colors.text.success}} /> : <Typography variant={'*bodyText2'}>{'-'}</Typography>}
                                                        </Flex>
                                                    )
                                                }else if(row.invite){
                                                    return(
                                                        <Flex w={'100%'} direction={'column'}>
                                                            {row.invite.roles.some(e => e === ROLE_PORTAL_MEMBER) ? <CheckIcon style={{color: colors.text.success}} /> : <Typography variant={'*bodyText2'}>{'-'}</Typography>}
                                                        </Flex>
                                                    )
                                                }
                                                return <></>;
                                            }},
                                        {name: t('ID'), key: 'id', default: true, valueGetter: row => row.user?.publicId ?? row.invite?.id },
                                        {name: t('Date created'), key: 'dateCreated', default: true, valueGetter: row => row.user ? parseDateAuto(row.user.createDate) : undefined},
                                        {name: t('Last login'), key: 'lastLogin', default: true, valueGetter: row => row.user ? parseDateAuto(row.user.lastLoginDate) : undefined},
                                        {name: t('Status'), key: 'status', default: true, valueGetter: row => row.user ? makeFirstLetterUpper(row.user.status) : makeFirstLetterUpper(row.invite!.inviteStatus.toLowerCase())},
                                    ],
                                    rowActionsConfig: [
                                        {
                                            title: 'Delete collaborator',
                                            onClick: (row) => handleDeleteUser(row.user!),
                                            icon: <DeleteForeverIcon />,
                                            disabled: (row) => Boolean(row.user?.publicId === user?.publicId || !workspace || (!isWorkspaceOwner && !isWorkspaceAdmin) || row.user?.roles.some(e => e.name === ROLE_WS_OWNER)),
                                            dontShow: (row) => !row.user,
                                        },
                                        {
                                            title: 'Manage access',
                                            onClick: (row) => handleOpenRoleManage(row.user!),
                                            icon: <ManageAccountsIcon />,
                                            disabled: (row) => Boolean(row.user?.publicId === user?.publicId || !workspace || (!isWorkspaceOwner && !isWorkspaceAdmin) || row.user?.roles.some(e => e.name === ROLE_WS_OWNER)),
                                            dontShow: (row) => !row.user,
                                        },
                                        {
                                            title: 'Cancel invite',
                                            onClick: (row) => handleCancelInvite(row.invite!),
                                            icon: <CancelIcon />,
                                            disabled: (row) => (isLoadingResendInvite.isLoading && row.invite?.emailAddress === isLoadingResendInvite.email) || !workspace || (!isWorkspaceOwner && !isWorkspaceAdmin),
                                            dontShow: (row) => !row.invite,
                                        },
                                        {
                                            title: 'Resend invite',
                                            onClick: (row) => handleResendInvite(row.invite!),
                                            icon: <EmailIcon />,
                                            disabled: (row) => (isLoadingCancelInvite.isLoading && row.invite?.emailAddress === isLoadingCancelInvite.email) || !workspace || (!isWorkspaceOwner && !isWorkspaceAdmin),
                                            dontShow: (row) => !row.invite,
                                        }
                                    ]
                                }}
                                filtersConfig={{
                                    genericFilterProps: {
                                        configs: [],
                                        fetchResultWithSelectedFilters: () => null,
                                        isAddFilters: false
                                    }
                                }}
                                emptyArrayImageProps={{
                                    type: 'any',
                                    filterNotApplied: {
                                        text: ('No users found'),
                                    },
                                }}
                            />
                        }
                    </>
                }

                {tab === 'roles' &&
                    <>
                        <GenericTable<TWorkspaceRole>
                            id={'WorkspaceExact-roles'}
                            columnsConfig={{totalName: 'Total roles', forceShowCardsWhen: isMobile}}
                            disableSkeletons
                            paging={{
                                pageInfo: {page: 0, total: roles.roles.length ?? 0, count: roles.roles.length ?? 0},
                                isLoading: isLoadingUsers,
                                minLoadedPage: 0,
                                maxLoadedPage: 0,
                                setMinMaxLoadedPage: () => null,
                                clearRows: () => null,
                                fetchPaging: () => null,
                            }}
                            rowsConfig={{
                                rows: roles.roles.filter(e => e.name !== ROLE_PORTAL_MEMBER),
                                customCellPaddings: '8px',
                                getRowUniqueId: (row) => row.id,
                                onRowClick: () => null,
                                columnsConfigs: [
                                    {name: t('Name'), key: 'name', default: true},
                                    {name: t('Permissions'), key: 'permissions', default: true, valueGetter: (row) => row.permissions.length.toString()},
                                    {name: t('Last update'), key: 'lastUpdate', default: true, valueGetter: (row) => parseDateAuto(row.lastUpdateDate)},
                                    {name: t('Date created'), key: 'dateCreated', default: true, valueGetter: row => parseDateAuto(row.createDate)},
                                    {name: t('ID'), key: 'id', default: true},
                                ],
                                rowActionsConfig: [
                                    {
                                        title: 'Preview role',
                                        onClick: handlePreviewRole,
                                        icon: <PreviewIcon />,
                                        disabled: () => !workspace || (!isWorkspaceOwner && !isWorkspaceAdmin)
                                    },
                                    {
                                        title: 'Edit role',
                                        onClick: handleEditRole,
                                        icon: <EditIcon />,
                                        disabled: (row) => row.origin || !workspace || (!isWorkspaceOwner),
                                    },
                                    {
                                        title: 'Delete role',
                                        onClick: handleDeleteRole,
                                        icon: <DeleteForeverIcon/>,
                                        disabled: (row) => row.origin || !workspace || (!isWorkspaceOwner),
                                    }
                                ]
                            }}
                            filtersConfig={{
                                genericFilterProps: {
                                    configs: [],
                                    fetchResultWithSelectedFilters: () => null,
                                    isAddFilters: false
                                }
                            }}
                            emptyArrayImageProps={{
                                type: 'any',
                                filterNotApplied: {
                                    text: ('No users found'),
                                },
                            }}
                        />

                    </>
                }
            </TabContext>

            {/*DIALOGS*/}
            <PreviewRoleDialog />
            <DeleteWorkspaceUser />
            <DeleteWorkspaceRole />
            <ChangeWorkspaceName />
            <DeleteWorkspaceDialogs/>
            <InviteToWorkspaceDialog />
            <ManageUserAccessDialog />
            <ImportCsvDialog
                isOpen={importCsv.isOpen}
                fields={importCsv.fields}
                response={importCsv.response}
                setImportDialog={importCsv.setImportDialog}

                isGettingFields={importCsv.isGettingFields}
                isPerformingCsv={importCsv.isPerformingCsv}
                isLoadingTemplate={importCsv.isLoadingTemplate}

                getFields={importCsv.getFields}
                performCsv={importCsv.performCsv}
                downloadTemplate={importCsv.downloadTemplate}
                fieldsMapperKey={importCsv.fieldsMapperKey}

                fileLimitInMB={5}
            />
        </Flex>
    )
}
