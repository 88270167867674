import React, {FC, useCallback, useEffect, useState} from "react";
import {useMainTranslation} from "../../../../../../../../hooks/useMainTranslationHooks/useMainTranslation";
import {uuid} from "../../../../../../../../utils";
import {useDrop} from "react-dnd";
import {ItemTypes, TEditorActor, TEditorStageApprover} from "../../../../../../types";
import * as S from "../../../styled";
import {Button, Checkbox} from "@mui/material";
import {TypographyBody2} from "../../../../../../../Inputs/styled";
import {ReactComponent as GroupIcon} from "../../../../../../../../../newShared/images/documents/group-icon.svg";
import {validateEmail} from "../../../../../../../../utils/text";
import {ApproverGroupEditor} from "../approverGroup";
import {TForm} from "../../staff";
import {getMsFromDays} from "../../../../../../../../utils/dateTools";
import {optionIsTCollaborator} from "../../../../../../../AutocompleteCollaborators/types";
import {AutocompleteCollaborators} from "../../../../../../../AutocompleteCollaborators";
import {defaultGetOptionDisabled} from "../../../../../../../AutocompleteCollaborators/helpers";

interface ApproversBlockViewProps {
    form: TForm;
    setForm:  (form: TForm) => void;
    handleOpenAddGroupMessage: (data: {approverId?: string, groupId?: string, oldMessage: string}) => void;
    isApproversCustomOrder: boolean;
    setIsApproversCustomOrder: (isOrdered: boolean) => void;
}

export const ApproversBlockViewEditor: FC<ApproversBlockViewProps> = (props) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});
    const {
        form,
        setForm,
        handleOpenAddGroupMessage,
        isApproversCustomOrder,
        setIsApproversCustomOrder,
    } = props;

    const [apprGroupIds, setApprGroupIds] = useState<string[]>([]);

    const [uniqueInputId, setUniqueInputId] = useState<string>(uuid());
    const [newApprover, setNewApprover] = useState<TEditorActor | null>(null);
    const [error, setError] = useState<string | null>(null);

    const addedApprovers: string[] = form.approvers.flatMap(e => e.groups.flatMap(e => e.actors.map(e => e.email)));

    const isOk = (email: string) => {
        if (!validateEmail(email)) {
            setError('Invalid email');
            return false;
        }
        else if (addedApprovers.includes(email)) {
            setError('Email already exists');
            return false;
        } else {
            error && setError(null);
            return true;
        }
    }

    useEffect(() => {
        const groupIds: string[] = [];
        form.approvers.forEach(appr =>  appr.groups.forEach(gr => (gr.actors.length > 1 || gr.name) && groupIds.push(gr.id ?? '')))

        setApprGroupIds(groupIds)
        // eslint-disable-next-line
    }, [])

    const handleAdd = () => {

        const newApprovers = [
            ...form.approvers,
            {
                id: `index` + uuid(),
                order: isApproversCustomOrder ? form.approvers.length + 1 :  0,
                eta: getMsFromDays(7),
                groups: [{
                    id: `index group` + uuid(),
                    actors: [{email: newApprover?.email || '', firstName: newApprover?.firstName || '', lastName: newApprover?.lastName || ''}],
                    name: '',
                    isRequired: true,
                    message: '',
                }]
            }
        ] satisfies TEditorStageApprover[];

        if (isOk(newApprover?.email || '')) {
            setForm({
                ...form,
                approvers: newApprovers
            });
            setUniqueInputId(uuid());
            setNewApprover(null);
        }
    }

    const handleAddGroup = () => {

        const groupId = `index group ${form.approvers.length + 1}`

        const newApprovers = [...form.approvers, {
            id: `index ${form.approvers.length + 1}`,
            order: isApproversCustomOrder ? form.approvers.length + 1 : 0,
            eta: getMsFromDays(7),
            groups: [{
                id: groupId,
                actors: [],
                name: '',
                isRequired: true,
                message: '',
            }]
        }] satisfies TEditorStageApprover[]

        setForm({...form, approvers: newApprovers});
        setApprGroupIds(prev => prev && [...prev, groupId]);
    };

    const handleAddGroupActor = (approverId: string, groupId: string, actor: TEditorActor ) => {

        setForm({...form, approvers: form.approvers.map(appr => appr.id === approverId ? ({
                ...appr,
                groups: appr.groups.map(gr => gr.id === groupId ? ({...gr, actors: [...gr.actors, actor]}) : gr)
            }) : appr)});
    }

    const handleDeleteGroupActor = (approverId: string, groupId: string, email: string) => {
        setForm({...form, approvers: form.approvers.map(appr => appr.id === approverId ? ({
                ...appr,
                groups: appr.groups.map(gr => gr.id === groupId ? ({
                    ...gr,
                    actors: gr.actors.filter(actor => actor.email !== email)
                }) : gr)
            }) : appr)});
    }

    const handleToggleOrdered = (isOrdered: boolean) => {
        setForm({...form, approvers: form.approvers.map((appr, index) => ({
                ...appr,
                order: isOrdered ? index + 1 : 0,
            }))});

        setIsApproversCustomOrder(isOrdered);
    }

    const toggleIsRequired = (approverId: string, groupId: string ) => {
        setForm({...form, approvers: form.approvers.map(appr => appr.id === approverId ? ({
                ...appr,
                groups: appr.groups.map(gr => gr.id === groupId ? ({...gr, isRequired: !gr.isRequired}) : gr)
            }) : appr)});
    }

    const handleDeleteGroup = (approveId: string) => {
        setForm({
            ...form,
            approvers: form.approvers.filter(appr => appr.id !== approveId),
        });
    }

    const handleSetNameGroup = (approveId: string, groupId: string, name: string) => {
        setForm({
            ...form,
            approvers: form.approvers.map(appr => appr.id === approveId ? ({
                ...appr,
                groups: appr.groups.map(gr => gr.id === groupId ? ({...gr, name: name}) : gr)
            }) : appr)
        });
    }

    useEffect(() => {
        error && setError(null);

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newApprover]);

    //DND APPROVERS
    const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));
    const setApprovers = useCallback(
        (cols: TEditorStageApprover[]) => {
            setForm({
                ...form,
                approvers: cols.map((e, i) => ({...e, order: i})),
            })
            //eslint-disable-next-line
        }, [form]
    )
    const findColumn: (id: string) => {column: TEditorStageApprover, index: number} | null = useCallback(
        (id: string) => {
            const column = form.approvers.find(e => e.id === id);

            // console.log(`findColumn for ${key} - ${JSON.stringify(column)} | ${column ? settings.indexOf(column) : null}`);
            return column ? {
                column,
                index: form.approvers.indexOf(column)
            } : null;
        },
        [form.approvers],
    )

    const moveColumn: (key: string, atIndex: number) => void = useCallback(
        (key: string, atIndex: number) => {
            const res = findColumn(key);
            if(res){
                //res - {column: lastName, index: 1}
                //atIndex - 0
                const copyArr = JSON.parse(JSON.stringify(form.approvers));
                // console.log(`copyArr: ${JSON.stringify(copyArr)}`);
                copyArr.splice(res.index, 1) //remove
                copyArr.splice(atIndex, 0, res.column) //insert
                // const reordered = settings.splice(res.index, 1).splice(atIndex, 0, res.column);
                // console.log(`moveColumn for ${key} settings.splice(${res.index}, 1).splice(${atIndex}, 0, ${JSON.stringify(res.column)}) | \n newArr: new arr: ${JSON.stringify(copyArr)}`)
                setApprovers(copyArr);
                // console.log(`moveColumn for ${key} - from ${res?.index} | to ${atIndex} \n new arr: ${JSON.stringify(reordered)}`);
            }else{
                // console.log(`--Column with key ${key} not found!`);
            }
        },
        [findColumn, form.approvers, setApprovers],
    )


    return (
        <>
            <S.FlexBox>
                <Checkbox checked={isApproversCustomOrder} onChange={() => handleToggleOrdered(!isApproversCustomOrder)} />
                <TypographyBody2>{t('Custom approval order')}</TypographyBody2>
            </S.FlexBox>
            <S.AutocompleteBox>
                <AutocompleteCollaborators
                    helperText={error || undefined}
                    key={uniqueInputId}
                    getOptionLabelCustom={"name+email"}
                    freeSoloType={"email"}
                    freeSolo
                    defaultValue={''}
                    onChange={value => {
                        if (!optionIsTCollaborator(value)) {
                            setNewApprover({email: value as string, firstName: '', lastName: ''});
                        } else {
                            setNewApprover({email: value?.email || '', firstName: value?.firstName || '', lastName: value?.lastName || ''});
                        }
                    }}
                    getOptionDisabled={defaultGetOptionDisabled(addedApprovers)}

                    sx={{width: '572px', '& label': {top: '0px !important'}}}
                />

                <Button size={'small'} sx={{marginLeft: '16px', mt: '2px'}}
                        disabled={!newApprover || !validateEmail(newApprover.email) || addedApprovers.includes(newApprover.email)}
                        onClick={handleAdd}>{t('Add')}</Button>
            </S.AutocompleteBox>


            <div ref={drop} style={{width: '100%'}}>
                {form.approvers.map((approver, index) => (
                    <ApproverGroupEditor
                        key={approver.id}
                        index={index}
                        isApproverCustomOrder={isApproversCustomOrder}
                        approver={approver}
                        addedApprovers={addedApprovers}
                        apprGroupIds={apprGroupIds}
                        handleDeleteGroup={handleDeleteGroup}
                        handleSetNameGroup={handleSetNameGroup}
                        toggleIsRequired={toggleIsRequired}
                        handleAddGroupActor={handleAddGroupActor}
                        handleDeleteGroupActor={handleDeleteGroupActor}
                        handleOpenAddGroupMessage={handleOpenAddGroupMessage}

                        moveColumn={moveColumn}
                        findColumn={findColumn}
                    />))}

            </div>

            <Button variant={'text'} sx={{textTransform: 'none', mt: '16px'}} startIcon={<GroupIcon />} onClick={handleAddGroup}>{t('Create approver group')}</Button>

        </>
    )
}