import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {addFrameworkDialog, hideAddFramework, isLoading} from "../../../store/builder/slice";
import React, {useEffect, useState} from "react";
import {frameworkInputType} from "../../../types";
import GIXI from "../../../helpers/gixi";
import {LOGO_MAX_SIZE, ROOT_BUILDER} from "../../../constants";
import {AddFramework} from "../../../store/builder/actions";
import {toBase64} from "../../../../../../newShared/utils/base64/base64";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {DEFAULT_LOGO_URL} from "../../../../../authWorkspacesCookies/settings/constants";


export const useAddFramework = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'builderPage.dialogs'});
    const dispatch = useDispatch();
    const history = useHistory();
    const {isOpen} = useSelector(addFrameworkDialog);

    const initialFramework: frameworkInputType = {
        name: '',
        description: '',
        logo: DEFAULT_LOGO_URL,
        origin: false,
        type: '',
        visibility: 'PRIVATE'
    };


    const [framework, setFramework] = useState<frameworkInputType>(initialFramework);
    const [fileName, setFileName] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        if(!isOpen) {
            // const pixLogo = new GIXI(128).getImage();
            // console.log(pixLogo);
            setFramework({...initialFramework});
            setFileName('');
            setErrorMessage(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const handleGenerateLogo = () => {
        const pixLogo = new GIXI(128).getImage();
        setFramework({...framework, logo: pixLogo});
        setFileName('generated logo' );
    }

    const handleSelect = () => {
        if(validate()){
            const onSuccess = (id: string) => history.push(ROOT_BUILDER + `/framework/${id}`);
            dispatch(AddFramework({framework, onSuccess}));
        }
    }

    const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
        if (reason !== 'backdropClick') {
            dispatch(hideAddFramework());
        }
    };

    const handleDeleteLogo = () => {
        setFramework(prev => prev && ({...prev, logo: DEFAULT_LOGO_URL}))
        setFileName('');
    }

    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setFramework((prevValues) => ({
            ...prevValues,
            [event.target.name]: event.target.value as string,
        }));
    };

    const validate = (): boolean => {
        return (
            framework.name.trim().length > 0
            && framework.logo.trim().length > 0
            // && framework.description.trim().length > 0
            // && framework.type.trim().length > 0
            // && framework.visibility.trim().length > 0
        )
    }

    // const handleSelectVisibility = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    //     setFramework((prevValues) => ({
    //         ...prevValues, visibility: value,
    //     }));
    // }

    const setLogo = async(file: File) => {
        if(file && file.size > LOGO_MAX_SIZE){
            // dispatch(addErrorSnack(errorMessage));
            setErrorMessage(t('The size of your file is more than 1 MB. Try uploading another file up to 1 MB in size'))
            return;
        }
        setErrorMessage(null);
        setFileName(file.name);
        const fileBase64: string = await toBase64(file) as string;
        setFramework((prev) => {return {...prev, logo: fileBase64}});
    }


    return {
        fileName,
        isOpen,
        framework,
        errorMessage,
        handleGenerateLogo,
        handleSelect,
        handleClose,
        handleChange,
        handleDeleteLogo,
        setLogo,
        validate,
        isLoading: useSelector(isLoading),
    }


}