import React, {CSSProperties} from 'react';
import {getBezierPath, getEdgeCenter, getMarkerEnd,} from 'react-flow-renderer';

import {Position} from "react-flow-renderer/dist/types";
import AddIcon from "@mui/icons-material/Add";
import {MenuItem, Popover, Typography} from "@mui/material";
import {useDispatch} from "react-redux";
import {createStage} from '../../../store/slice';
import {useMainTranslation} from '../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation';
import {Flex} from '../../../../../../newShared/components/Layouts';

const foreignObjectSize = 24;

type plusEdgeProps = {
    sourceX: number;
    sourceY: number;
    sourcePosition?: Position;
    targetX: number;
    targetY: number;
    targetPosition?: Position;
    id: string;
    style: CSSProperties;
    data: any;
    arrowHeadType: any;
    markerEndId: any;
}


export const PlusEdge = ({
                             id,
                             sourceX,
                             sourceY,
                             targetX,
                             targetY,
                             sourcePosition,
                             targetPosition,
                             style = {},
                             data,
                             arrowHeadType,
                             markerEndId}: plusEdgeProps)  => {
    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });

    const [anchorEl, setAnchorEl] = React.useState<{id: string | null, anchorEl: HTMLButtonElement | null}>({id: null, anchorEl: null});


    const handlePopoverOpen = (event: React.MouseEvent<SVGSVGElement>, id: string) => {
        setAnchorEl({
            id: id,
            anchorEl: event.target as HTMLButtonElement,
        });
    }

    const handlePopoverClose = () => {
        setAnchorEl({id: null, anchorEl: null});
    }

    const dispatch = useDispatch();

    const handleChoose = (type: string) => {
        dispatch(createStage({edgeId: id, type}));
        handlePopoverClose();
    }
    const {t} = useMainTranslation('', {keyPrefix: 'automations'});


    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={edgeCenterX - foreignObjectSize / 2}
                y={edgeCenterY - foreignObjectSize / 2}
                className="edgebutton-foreignobject"
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                <Flex
                    w={'1.5rem'}
                    h={'1.5rem'}
                    br={'50%'}
                    background={'white'}
                    // border={'1px solid black'}
                    ai={'center'}
                    jc={'center'}
                    cursor={'pointer'}
                    className={'nodrag'}
                >
                    <AddIcon
                        style={{color: '#FAC000'}}
                        onClick={(event) => handlePopoverOpen(event, id)}
                    />

                    <Popover
                        open={anchorEl.id === null ? false : anchorEl.id === id}
                        // open={false}
                        anchorEl={anchorEl.anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem onClick={() => handleChoose('read')}>
                            <Typography>{t('stageTypes.read')}</Typography>
                        </MenuItem>

                        <MenuItem onClick={() => handleChoose('review')}>
                            <Typography>{t('stageTypes.review')}</Typography>
                        </MenuItem>

                        <MenuItem onClick={() => handleChoose('sign')}>
                            <Typography>{t('stageTypes.sign')}</Typography>
                        </MenuItem>

                        {/*<Flex direction="column" w="90px" h="140px">*/}
                        {/*    <Flex direction="column" p="25px 16px 0px 16px" >*/}
                        {/*        <Box onClick={() => handleChoose('read')}>*/}
                        {/*            <Typo fontSize="14px" fontWeight={400} letterSpacing="0.4px" margin="0 0 20px 0" cursor="pointer">{t('stageTypes.read')}</Typo>*/}
                        {/*        </Box>*/}
                        {/*        <Box onClick={() => handleChoose('review')}>*/}
                        {/*            <Typo fontSize="14px" fontWeight={400} letterSpacing="0.4px" margin="0 0 20px 0" cursor={"pointer"}>{t('stageTypes.review')}</Typo>*/}
                        {/*        </Box>*/}
                        {/*        <Box onClick={() => handleChoose('sign')}>*/}
                        {/*            <Typo fontSize="14px" fontWeight={400} letterSpacing="0.4px" margin="0 0 20px 0" cursor={"pointer"}>{t('stageTypes.sign')}</Typo>*/}
                        {/*        </Box>*/}
                        {/*    </Flex>*/}
                        {/*</Flex>*/}
                    </Popover>
                </Flex>
            </foreignObject>
        </>
    );
}