import axiosAsync from "../../../../newShared/utils/axios/axios";
import axiosSync from "../../../../newShared/utils/axios/axios";
// import {setTokenExist} from "../../signUp/store/apollo/local";
import {AxiosResponse} from "axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {
    EmailVerifyMutation,
    InitialValidateGoogleAuthorizationMutation,
    LoginByRecoveryCodeMutation,
    LoginMutation,
    LoginMutationVariables,
    RegistrationMutation,
    ResendEmailVerificationCodeMutation,
    ResendTwoFactorEmailMutation,
    SendEmailMutation,
    SendEmailMutationVariables,
    SendEmailToResetPassGqlMutation,
    TwoFactorLoginMutation
} from "../../../../newShared/GQLTypes";
import {sendEmailToResetPassGQL, sendPassToResetPassGQL} from "./query";
import {print} from "graphql";
import {gql} from "graphql.macro";
import {TAuthObj} from "../types";


export const loginApi = async (data: LoginMutationVariables["data"]): Promise<LoginMutation["login"]> => (
    axiosAsync.then((axios) => axios.post(PATH_SERVER, {
            query: print(gql`
                mutation login($data: LoginInputRequest!){
                    login(data: $data){
                        isGoogleAuthConfigComplete
                        isEmailVerified
                    }
                }
            `),
            variables: {
                data
            }
        }, {
            headers: buildCommonHeader()
        })
    ).then((result: AxiosResponse<any>) => {
        try {
            return result.data.data.login;
        } catch (ex: any) {
            // console.log(JSON.stringify(result));
            throw new Error(result.data.errors.length > 0 ? result.data.errors[0].message : 'Something went wrong!');
        }
    })
);

export const logoutApi = async (): Promise<{message: string}> => {
    return axiosAsync.then((axios) => axios.post(PATH_SERVER, {
            query: `
                mutation logout {
                    logout {
                        message
                    }
                }`,
        }, {
            headers: buildCommonHeader()
        })
    ).then((result: AxiosResponse<any>) => {
        return result?.data?.data?.logout;
    })
}

export const sendEmailSupportApi = async (data: SendEmailMutationVariables["data"]): Promise<SendEmailMutation["sendMessageToSupportMainLayout"]["isSent"]> => {
    return axiosAsync.then((axios) => axios.post(PATH_SERVER,
        {
            query: print(gql`
                mutation sendEmail($data: MainLayoutSupportMessageInput!){
                    sendMessageToSupportMainLayout(data: $data){
                        isSent
                    }
                }
            `),
            variables: {
                data
            }
        })
    ).then((result: AxiosResponse<any>) => {
            if (result?.data?.data?.sendMessageToSupportMainLayout) {
                return result?.data?.data?.sendMessageToSupportMainLayout.isSent;
            } else {
                throw new Error(result.data.errors[0]?.message ?? 'Something went wrong. Try again.');
            }
        });
}



export const sendEmailToResetPass = async (data: {email: string}): Promise<SendEmailToResetPassGqlMutation['resetPassword']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: sendEmailToResetPassGQL,
                variables: {
                    data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.resetPassword
    })
)

export const sendPassToResetPass = async (data: {password: string, hash: string, email: string}) => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: sendPassToResetPassGQL,
                variables: {
                    data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updatePassword as {message: string}
    })
)


export const validateCode = async (code: string): Promise<TwoFactorLoginMutation["twoFactorLogin"]> => (
    axiosAsync.then((axios) => axios.post(PATH_SERVER, {
            query: print(gql`
                mutation twoFactorLogin($TwoFactorCode:String!){
                    twoFactorLogin(TwoFactorCode:$TwoFactorCode){
                        organizations{
                            collaborators{
                                role
                                publicId
                            }
                            id
                            name
                            logo
                            subscription
                            subscriptionExpiry
                            configuration{
                                availableWizards
                                availableWorkspaceCount
                                availableCollaboratorsCount
                            }
                            pageInfo{
                                total
                                sort
                                page
                                count
                            }
                            workspaces{
                                id
                                name
                                subscription
                                status
                                collaborators{
                                    publicId
                                    role
                                }
                                organizationId
                            }
                            blocked
                            blockedReason
                            trialUsed
                            requester{
                                outsideCollaborator
                                publicId
                                role
                            }
                        }
                        currentUser{
                            publicId
                            email
                            firstName
                            lastName
                            creationDate
                            lastLoginDate
                            status
                        }
                    }
                }
            `),
            variables: {
                TwoFactorCode: code
            }
        }, {
            headers: buildCommonHeader()
        })
    ).then((result: AxiosResponse<any>) => {
        try{
            return result.data.data.twoFactorLogin;
        }catch (e){
            throw new Error(result.data.errors[0].message);
        }
    })
)


export const resendCode = async (): Promise<ResendTwoFactorEmailMutation["twoFactorResendEmail"]["message"]> => (
    axiosAsync.then((axios) => axios.post(PATH_SERVER, {
            query: print(gql`
                mutation resendTwoFactorEmail{
                    twoFactorResendEmail{
                        message
                    }
                }
            `),
            variables: {
            }
        }, {
            headers: buildCommonHeader()
        })
    ).then((result: AxiosResponse<any>) => {
        try{
            return result.data.data.twoFactorResendEmail.message;
        }catch (e){
            return result.data.errors[0];
        }
    })
)



export const loginByRecoveryCode = async (recoveryCode: string): Promise<LoginByRecoveryCodeMutation["loginByRecoveryCode"]> => (
    axiosAsync.then((axios) => axios.post(PATH_SERVER, {
            query: print(gql`
                mutation loginByRecoveryCode($recoveryCode: String!){
                    loginByRecoveryCode(recoveryCode: $recoveryCode){
                        organizations{
                            collaborators{
                                role
                                publicId
                            }
                            id
                            name
                            logo
                            subscription
                            subscriptionExpiry
                            configuration{
                                availableWizards
                                availableWorkspaceCount
                                availableCollaboratorsCount
                            }
                            pageInfo{
                                total
                                sort
                                page
                                count
                            }
                            workspaces{
                                id
                                name
                                subscription
                                status
                                collaborators{
                                    publicId
                                    role
                                }
                                organizationId
                            }
                            blocked
                            blockedReason
                            trialUsed
                            requester{
                                outsideCollaborator
                                publicId
                                role
                            }
                        }
                        currentUser{
                            publicId
                            email
                            firstName
                            lastName
                            creationDate
                            lastLoginDate
                            status
                        }
                    }
                }
            `),
            variables: {
                recoveryCode
            }
        }, {
            headers: buildCommonHeader()
        })
    ).then((result: AxiosResponse<any>) => {
        try{
            return result.data.data.loginByRecoveryCode;
        }catch (e){
            throw new Error(result.data.errors[0] ?? 'Something went wrong!')
        }
    })
)

export const verifyEmailApi = async (data: {email: string, verificationCode: string}, signal?: AbortSignal): Promise<EmailVerifyMutation["emailVerify"]> => (
    axiosAsync.then((axios) => axios.post(PATH_SERVER, {
            query: print(gql`
                mutation emailVerify($email: String!, $verificationCode: String!){
                    emailVerify(email: $email, verificationCode: $verificationCode){
                        message
                        secret
                        qrCode
                    }
                }
            `),
            variables: data
        }, {
            headers: buildCommonHeader(),
            signal
        })
    ).then((result: AxiosResponse<any>) => {
        try{
            return result.data.data.emailVerify;
        }catch (e){
            throw new Error(result.data.errors[0] ?? 'Something went wrong!')
        }
    })
)
export const resendEmailVerificationCodeApi = async (data: {email: string}, signal?: AbortSignal): Promise<ResendEmailVerificationCodeMutation["resendEmailVerificationCode"]> => (
    axiosAsync.then((axios) => axios.post(PATH_SERVER, {
            query: print(gql`
                mutation resendEmailVerificationCode($email: String!){
                    resendEmailVerificationCode(email: $email){
                        message
                    }
                }
            `),
            variables: data
        }, {
            headers: buildCommonHeader(),
            signal
        })
    ).then((result: AxiosResponse<any>) => {
        try{
            return result.data.data.resendEmailVerificationCode;
        }catch (e){
            throw new Error(result.data.errors[0] ?? 'Something went wrong!')
        }
    })
)

export const signUpApi = async (params: TAuthObj): Promise<[RegistrationMutation["registration"]]> => (
    axiosAsync.then((axios) => axios.post(PATH_SERVER, {
            query: print(gql`
                mutation Registration($data: RegistrationInputRequest!) {
                    registration(data: $data) {
                        message
                    }
                }
            `),
            variables: {
                data: {
                    ...params
                }
            }
        }, {
            headers: buildCommonHeader()
        })
    ).then((result: AxiosResponse<any>) => result).then(res => res.data.data.registration)
);


export const initialValidateGoogleAuthorization = async (secret: string, code: string): Promise<InitialValidateGoogleAuthorizationMutation["initialValidateGoogleAuthorization"]> => (
    axiosAsync.then((axios) => axios.post(PATH_SERVER, {
            query: print(gql`
                mutation initialValidateGoogleAuthorization($secret:String!, $code:String!){
                    initialValidateGoogleAuthorization(secret: $secret, code:$code)
                }
            `),
            variables: {
                secret,
                code
            }
        }, {
            headers: buildCommonHeader()
        })
    ).then((result: AxiosResponse<any>) => result).then(res => res.data.data.initialValidateGoogleAuthorization)
);
