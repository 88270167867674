import {Button, IconButton, Typography} from "@mui/material";
import {ReactComponent as FileIcon} from "../../../../../../../newShared/images/documents/document-icon.svg";
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import React from "react";
import {TypographyNew} from "../../../../../../../newShared/components/TypographyNew";
import {Flex} from "../../../../../../../newShared/components/Layouts";
import colors from "../../../../../../../newShared/theme/colors";
import {FrameworksDelegatedTaskDataAttachedFilesFileModel} from "../../../../../../../newShared/GQLTypes";

export const DelegatedTaskAttachedFilesNode = ({attachments, handleDownloadFile, handlePreviewFile, handlePreviewAll}: {attachments: FrameworksDelegatedTaskDataAttachedFilesFileModel[], handlePreviewFile: (file: FrameworksDelegatedTaskDataAttachedFilesFileModel) => void, handleDownloadFile:(file: FrameworksDelegatedTaskDataAttachedFilesFileModel) => void, handlePreviewAll: () => void}) => {
    if(!attachments.length) return <Typography variant={'*bodyText2'} color={colors.grayText}>-</Typography>
    else return (
        <Flex direction={'column'}>
            <Flex ai={'center'} jc={'space-between'} w={'100%'}>
                <Flex gap={'10px'} ai={'center'} overflow={'hidden'}>
                    <FileIcon style={{flexShrink: 0}}/>
                    <TypographyNew variant={'body2'} color={colors.grayText} noWrap>{attachments[0]?.fileName ?? 'N/A'}</TypographyNew>
                </Flex>

                <Flex gap={'12px'} ai={'center'} shrink={'0'}>
                    <IconButton size={'small'} onClick={() => handlePreviewFile(attachments[0])} color={'primary'}>
                        <VisibilityIcon/>
                    </IconButton>

                    <IconButton size={'small'} onClick={() => handleDownloadFile(attachments[0])} color={'primary'}>
                        <FileDownloadIcon />
                    </IconButton>
                </Flex>
            </Flex>

            {attachments.length > 1 &&
                <Flex>
                    <Button
                        variant={'text'}
                        size={'small'}
                        onClick={handlePreviewAll}
                    >
                        {`${attachments.length - 1} more file${attachments.length - 1 > 1 ? 's' : ''}`}
                    </Button>
                </Flex>
            }
        </Flex>
    )
}
