import {
    DownloadImageInEditorDocsQuery,
    DownloadImageInEditorDocsQueryVariables,
    DownloadImageInEditorPortalQuery,
    DownloadImageInEditorPortalQueryVariables,
    DownloadImageInEditorQuery,
    DownloadImageInEditorQueryVariables,
    UploadImageInEditorDocsMutation,
    UploadImageInEditorDocsMutationVariables,
    UploadImageInEditorMutation,
    UploadImageInEditorMutationVariables,
    UploadImageInEditorPortalMutation,
    UploadImageInEditorPortalMutationVariables
} from "../../../GQLTypes";
import axiosSync from "../../../utils/axios/axios";
import {PATH_SERVER} from "../../../constants";
import {print} from "graphql";
import {buildCommonHeader} from "../../../utils/builders";
import {AxiosResponse} from "axios";
import {
    downloadImageInEditor,
    downloadImageInEditorDocs,
    downloadImageInEditorPortal,
    uploadImageInEditor,
    uploadImageInEditorDocs,
    uploadImageInEditorPortal
} from "./query";

export const downloadImageInEditorApi = async (data: DownloadImageInEditorQueryVariables, signal?: AbortSignal): Promise<DownloadImageInEditorQuery["downloadFile"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(downloadImageInEditor),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.downloadFile
    })
)

export const uploadImageInEditorApi = async (data: UploadImageInEditorMutationVariables, signal?: AbortSignal): Promise<UploadImageInEditorMutation["uploadFile"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(uploadImageInEditor),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.uploadFile
    })
)

export const downloadImageInEditorDocsApi = async (data: DownloadImageInEditorDocsQueryVariables, signal?: AbortSignal): Promise<DownloadImageInEditorDocsQuery["workerDownloadFile"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(downloadImageInEditorDocs),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.workerDownloadFile
    })
)

export const uploadImageInEditorDocsApi = async (data: UploadImageInEditorDocsMutationVariables, signal?: AbortSignal): Promise<UploadImageInEditorDocsMutation["workerUploadFile"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(uploadImageInEditorDocs),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.workerUploadFile
    })
)

export const downloadImageInEditorPortalApi = async (data: DownloadImageInEditorPortalQueryVariables, signal?: AbortSignal): Promise<DownloadImageInEditorPortalQuery["portalDownloadFile"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(downloadImageInEditorPortal),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.portalDownloadFile
    })
)

export const uploadImageInEditorPortalApi = async (data: UploadImageInEditorPortalMutationVariables, signal?: AbortSignal): Promise<UploadImageInEditorPortalMutation["portalUploadFile"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(uploadImageInEditorPortal),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.portalUploadFile
    })
)