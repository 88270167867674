import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    PATH_RISK_MANAGEMENT_MODELS_EXACT,
    PATH_RISK_MANAGEMENT_RISK_REGISTRIES_ALL,
    PATH_RISK_MANAGEMENT_RISK_REGISTRIES_EXACT,
    REGISTRY_RISKS_DEFAULT_COUNT
} from "../../constants";
import {
    cleanRegistryRisks,
    dialogs,
    hideExportCsv,
    loadings,
    models,
    openCreateRisk,
    openDeleteRegistry,
    openEditRegistry,
    openExportCsv,
    openReleaseRegistry,
    openSaveRisk,
    registryRisks,
    registryRisksMinMaxLoadedPage,
    registryRisksPageInfo,
    replaceRegistryRisksMinMaxLoadedPage,
    risksViewTypeBlock,
    selectedRegistry,
    selectedRisk,
    setImportCsvDialogAction,
    setSelectedRisk,
    toggleRisksViewTypeBlock
} from "../../store/slice";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_RISK_MANAGEMENT} from "../../../../../newShared/constants";
import React, {useEffect, useState} from "react";
import {exportCsvRisksAction, GetRisksAvailableFieldsForExport, getRisksByRegistryAction} from "../../store/actions";
import {TRisk} from "../../types";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";


export const useRegistriesExact = () => {
    const id = useParams<{id: string}>().id;
    const dispatch = useDispatch();
    const history = useHistory();
    const selected = useSelector(selectedRegistry);
    const {registryExact, risksList, isLoadingFields, registryExport} = useSelector(loadings);
    const _models = useSelector(models);
    const _pageInfo = useSelector(registryRisksPageInfo);
    const _risks = useSelector(registryRisks);
    const _selectedRisk = useSelector(selectedRisk);
    const reg = useSelector(selectedRegistry);
    const {
        saveDialog: {isRiskEdited},
        exportCsv: {isOpen, fields},
    } = useSelector(dialogs);
    const {getSearchProps} = useGenericFiltersStorage();

    //nav
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    useSetBreadcrumbs([
        {
            title: tMenu('Risk Management'),
            path: PATH_LOCAL_RISK_MANAGEMENT
        },
        {
            title: tMenu('Risk registries'),
            path: PATH_RISK_MANAGEMENT_RISK_REGISTRIES_ALL
        },
        {
            title: selected?.name ?? tMenu('Loading registry...'),
            path: PATH_RISK_MANAGEMENT_RISK_REGISTRIES_EXACT.replace('id', id)
        }
    ]);

    useEffect(() => {
        const lastSelectedRiskId = getSearchProps().lastId;
        if(!risksList && lastSelectedRiskId && lastSelectedRiskId.trim().length > 0){
            const risk = _risks.find(e => e.id === lastSelectedRiskId);
            risk && handleOpenRisk(risk);
        }
        //eslint-disable-next-line
    }, [risksList])

    //actions
    const handleGoBack = () => history.push(PATH_RISK_MANAGEMENT_RISK_REGISTRIES_ALL);


    const handleOpenRelease = () => {
        dispatch(openReleaseRegistry());
        handleClose();
    }

    const handleOpenAddRisk = () => {
        dispatch(openCreateRisk());
        handleClose();
    }

    const handleToggleViewMode = () => {
        dispatch(toggleRisksViewTypeBlock());
        handleClose();
    }

    const handleOpenEditRegistry = () => {
        dispatch(openEditRegistry());
        handleClose();
    }

    const handleOpenImportCsv = () => {
        dispatch(setImportCsvDialogAction({isOpen: true, data: {registryId: id}}));
        handleClose();
    }

    const handleExportCsv = (fieldKeys: string[]) => {
        reg && dispatch(exportCsvRisksAction({registryId: reg.id, fieldKeys, isDownload: _pageInfo.total < 10000}))
        handleClose();
    }

    const handleOpenExportCsv = () => {
        dispatch(openExportCsv());
        dispatch(GetRisksAvailableFieldsForExport());
    }

    const handleCloseExportCsv = () => {
        dispatch(hideExportCsv());
    }

    const handleDeleteRegistry = () => {
        dispatch(openDeleteRegistry());
        handleClose();
    }

    const handleOpenModelInNewTab = () => {
        selected &&  window.open(PATH_RISK_MANAGEMENT_MODELS_EXACT.replace(':id', selected.model), "_blank")?.focus()
    }

    const handleOpenRisk = (risk: TRisk) => {
        if(isRiskEdited){
            dispatch(openSaveRisk(risk)); //if risk in rightSide was changed -> opening save dialog -> then after save/discard current risk will be opened
        }else{
            dispatch(setSelectedRisk(risk)); //risk in rightSide was not edited -> just open current risk
        }
    }

    //MENU
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //
    const getData = (page: number, count: number) => {
        id && dispatch(getRisksByRegistryAction({data: {
                workspaceId: '',
                organizationId: '',
                registryId: id,
                pageInfo: {
                    page,
                    count,
                    filters: {}
                }
            }}))
    }

    // useEffect(() => {
    //     getData(0, REGISTRY_RISKS_DEFAULT_COUNT);
    //
    //     return () => {
    //         controller.abort();
    //     }
    //     //eslint-disable-next-line
    // }, []);

    const handleLoadMore = (page: number) => {
        !risksList && getData(_pageInfo.page + 1, REGISTRY_RISKS_DEFAULT_COUNT);
    }

    //TABLE

    const {minLoadedPage, maxLoadedPage} = useSelector(registryRisksMinMaxLoadedPage)

    return{
        isLoading: registryExact,
        isLoadingFields,
        isLoadingExportCsv: registryExport,
        selected,
        actions: {
            handleGoBack,
            handleOpenRelease,
            handleOpenAddRisk,
            handleToggleViewMode,
            handleOpenEditRegistry,
            handleOpenImportCsv,
            handleExportCsv: handleOpenExportCsv,
            handleDeleteRegistry,
            handleOpenModelInNewTab,
            handleOpenRisk
        },
        menu: {
            anchorEl,
            handleClose,
            handleClick,
            open,
        },
        exportCsv: {
            isOpen,
            availableFields: fields,
            handleExportCsv,
            handleClose: handleCloseExportCsv,
        },
        isBlockView: useSelector(risksViewTypeBlock),
        currModel: _models.find(e => e.id === selected?.model ?? ''),
        isLoadingRisks: risksList,
        handleLoadMore,
        hasMore: _pageInfo.total > ((_pageInfo.page + 1) * _pageInfo.count),
        risks: _risks,
        pageInfo: _pageInfo,
        selectedRisk: _selectedRisk,
        isRightSideOpen: _selectedRisk !== null,
        genericTable: {
            paging: {
                pageInfo: _pageInfo,
                isLoading: risksList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceRegistryRisksMinMaxLoadedPage(data)),
                clearRows: () => dispatch(cleanRegistryRisks()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    getData(page, count);
                },
                disableCleanUp: true
            },
            // onRowClick: (row: AccessManagementApp) => {
            //     dispatch(setSelectedApp(row));
            //     history.push(PATH_ACCESS_MANAGEMENT_APPS_EXACT.replace(':id', row.id));
            // }
        }
    }
}
