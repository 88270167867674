import {Dialog, DialogTitle, List, ListItem, ListItemText} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {hideTrigger, selectedAutomation, setTriggerType, trigger} from "../../../store/slice";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

const values = ['schedule', 'manual'];

export const TriggerDialog = () => {
    const dispatch = useDispatch();
    const {t} = useMainTranslation('', {keyPrefix: 'automations'});
    const auto = useSelector(selectedAutomation);
    const {isOpen} = useSelector(trigger);

    const handleClose = () => {
        dispatch(hideTrigger());
    };

    const handleSelect = (value: string) => {
        if(auto?.trigger.type === value) {
            handleClose();
            return;
        }
        dispatch(setTriggerType(value));
    }

    return (auto &&
        <Dialog onClose={handleClose} open={isOpen}>
            <DialogTitle>{t('Select Trigger Type')}</DialogTitle>
            <List sx={{ pt: 0 }}>
                {values.map((val) => (
                    <ListItem selected={val === auto?.trigger.type} button onClick={() => {
                        handleSelect(val);
                    }} key={val}>
                        <ListItemText primary={t(`triggerTypes.${val}`)} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}