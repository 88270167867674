import {useEffect, useState} from "react";
import {RisksTableProps, tControl, TRisk, TRiskModelProbability} from "../../types";
import {controls, firstNoneObject} from "../../constants";
import {useDispatch, useSelector} from "react-redux";
import {
    loadings,
    openAddActionOwner,
    openDeleteRisk,
    risksQuantitative,
    selectedRegistry,
    setTableNeedsSave,
    toggleRisksQuantitative
} from "../../store/slice";
import {updateRisksByRegistryIdAction} from "../../store/actions";

export const useRisksTable = ({risks, handleSetNeedUpdate}: RisksTableProps) => {
    const dispatch = useDispatch();
    const reg = useSelector(selectedRegistry);
    // const risksPageInfo = useSelector(registryRisksPageInfo);
    const [localRisks, setLocalRisks] = useState<(TRisk | null)[]>(risks);

    const findLocalRisk = (id: string): TRisk | undefined => localRisks.find(e => e?.id === id) || undefined;

    useEffect(() => {
        // console.log(`useRisksTable`, localRisks);
    }, [localRisks]);

    useEffect(() => {
        setLocalRisks(risks);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        risks.forEach(e => {
            if(!localRisks.some(l => l?.id === e?.id)) setLocalRisks(prev => {return [...prev, e]});
            //because new page of risks will just erase all changes - finding only new risks and adding then to array
        });
        localRisks.forEach(e => {
            //need to find which risks were deleted and remove them locally as well
            if(!risks.some(l => l?.id === e?.id)){
                setLocalRisks(prev => {return [...prev.filter(p => p?.id !== e?.id)]});
            }
        })
        //eslint-disable-next-line
    }, [risks]);

    //ACTIONS
    const handleChangeName = (id: string, value: string) => {
        let currRisk = findLocalRisk(id);
        if(currRisk){
            setLocalRisks(prev => {
                return [...prev.map(e => e?.id === id ? {...currRisk!, name: value} : e)]
            });
        }
    }

    const handleChangeType = (id: string, value: string) => {
        let currRisk = findLocalRisk(id);
        currRisk && setLocalRisks(prev => {
            return [...prev.map(e => e?.id === id ? {...currRisk!, type: value} : e)]
        });
    }

    const handleChangeDescription = (id: string, value: string) => {
        let currRisk = findLocalRisk(id);
        currRisk && setLocalRisks(prev => {
            return [...prev.map(e => e?.id === id ? {...currRisk!, description: value} : e)]
        });
    }

    const handleChangeControlActions = (id: string, value: string) => {
        let currRisk = findLocalRisk(id);
        currRisk && setLocalRisks(prev => {
            return [...prev.map(e => e?.id === id ? {
                ...currRisk!,
                control: {
                    ...currRisk!.control,
                    actions: value,
                    type: currRisk!.control.type ?? controls[0].type
                }
            } : e)]
        });
    }

    const handleSetInitialGradeProbability = (id: string, prob: TRiskModelProbability | null) => {
        if(!prob) return;
        if(prob.quantitative === firstNoneObject.quantitative){
            //default none object - setting null
            let currRisk = findLocalRisk(id);
            currRisk && setLocalRisks(prev => {
                return [...prev.map(e => e?.id === id ? {...currRisk!, initialGrade: {...currRisk!.initialGrade, probability: null}} : e)]
            });
        }else{
            let currRisk = findLocalRisk(id);
            currRisk && setLocalRisks(prev => {
                return [...prev.map(e => e?.id === id ? {...currRisk!, initialGrade: {...currRisk!.initialGrade, probability: prob.quantitative}} : e)]
            });
        }
    }

    const handleSetInitialGradeImpact = (id: string, prob: TRiskModelProbability | null) => {
        if(!prob) return;
        if(prob.quantitative === firstNoneObject.quantitative){
            //default none object - setting null
            let currRisk = findLocalRisk(id);
            currRisk && setLocalRisks(prev => {
                return [...prev.map(e => e?.id === id ? {...currRisk!, initialGrade: {...currRisk!.initialGrade, impact: null}} : e)]
            });
        }else{
            let currRisk = findLocalRisk(id);
            currRisk && setLocalRisks(prev => {
                return [...prev.map(e => e?.id === id ? {...currRisk!, initialGrade: {...currRisk!.initialGrade, impact: prob.quantitative}} : e)]
            });
        }
    }

    const handleSetControlType = (id: string, value: tControl | null) => {
        let currRisk = findLocalRisk(id);
        currRisk && value && setLocalRisks(prev => {
            return [...prev.map(e => e?.id === id ? {...currRisk!, control: {...currRisk!.control, type: value.type}} : e)]
        });
    }

    const handleSetControlScheduler = (id: string, date: unknown) => {
        try{
            const parsedDate = new Date(new Date(date as string).setHours(12)).toISOString();
            const normalizedForSave = parsedDate.slice(0, '2022-11-09'.length);
            let currRisk = findLocalRisk(id);
            if(normalizedForSave && currRisk){
                setLocalRisks(prev => {
                    return [...prev.map(e => e?.id === id ? {...currRisk!, control: {...currRisk!.control, scheduler: normalizedForSave, type: currRisk!.control.type ?? controls[0].type}} : e)]
                });
            }
        }catch (ex: any){
            console.log(`useRisksTable: handleSetControlScheduler: ${ex.message}`);
        }
        // currRisk && date && setLocalRisks(prev => {
        //     return [...prev.map(e => e.id === id ? {...currRisk!, control: {...currRisk!.control, scheduler: date, type: currRisk!.control.type ?? controls[0].type}} : e)]
        // });
    }

    const handleDeleteOwner = (id: string, owner: string) => {
        let currRisk = findLocalRisk(id);
        currRisk && setLocalRisks(prev => {
            return [...prev.map(e => e?.id === id ? {...currRisk!, control: {...currRisk!.control, owners: [...currRisk!.control.owners.filter(e => e !== owner)]}} : e)]
        });
    }

    const handleOpenAddOwners = (id: string) => {
        let currRisk = findLocalRisk(id);
        currRisk&& dispatch(openAddActionOwner(currRisk));
    }

    const handleSetResidualGradeProbability = (id: string, prob: TRiskModelProbability | null) => {
        if(!prob) return;
        if(prob.quantitative === firstNoneObject.quantitative){
            //default none object - setting null
            let currRisk = findLocalRisk(id);
            currRisk && setLocalRisks(prev => {
                return [...prev.map(e => e?.id === id ? {...currRisk!, residualGrade: {...currRisk!.residualGrade, probability: null}} : e)]
            });
        }else{
            let currRisk = findLocalRisk(id);
            currRisk && setLocalRisks(prev => {
                return [...prev.map(e => e?.id === id ? {...currRisk!, residualGrade: {...currRisk!.residualGrade, probability: prob.quantitative}} : e)]
            });
        }
    }

    const handleSetResidualGradeImpact = (id: string, prob: TRiskModelProbability | null) => {
        if(!prob) return;
        if(prob.quantitative === firstNoneObject.quantitative){
            //default none object - setting null
            let currRisk = findLocalRisk(id);
            currRisk && setLocalRisks(prev => {
                return [...prev.map(e => e?.id === id ? {...currRisk!, residualGrade: {...currRisk!.residualGrade, impact: null}} : e)]
            });
        }else{
            let currRisk = findLocalRisk(id);
            currRisk && setLocalRisks(prev => {
                return [...prev.map(e => e?.id === id ? {...currRisk!, residualGrade: {...currRisk!.residualGrade, impact: prob.quantitative}} : e)]
            });
        }
    }

    const handleDeleteRisk = (id: string) => {
        let currRisk = findLocalRisk(id);
        if(currRisk){
            dispatch(openDeleteRisk(currRisk));
            // setLocalRisks(prev => {return [...prev.filter(e => e.id !== id)]});
        }
    }

    //

    const neededSave = (): boolean => {
        const res = JSON.stringify(risks) !== JSON.stringify(localRisks);
        if(res){
            handleSetNeedUpdate(true);
            // dispatch(setTableNeedsSave(true));
            return res;
        }else{
            handleSetNeedUpdate(false);
            // dispatch(setTableNeedsSave(false));
            return res;
        }
    }

    const isOk = (): boolean => {
        if(!reg) return false;
        for(let risk of localRisks){
            if( !risk?.name.trim().length || !risk?.type.trim().length){
                return false;
            }
        }
        return true;
    }

    const handleSaveChanges = () => {
        if(isOk()){
            dispatch(updateRisksByRegistryIdAction(
                {
                    data: {
                        workspaceId: '',
                        registryId: reg!.id,
                        organizationId: '',
                        risks: localRisks.filter(e => e) as TRisk[],
                        deleteIds: [],
                        // pageInfo: {
                        //     page: 0,
                        //     count: risksPageInfo.page === 0 ? REGISTRY_RISKS_DEFAULT_COUNT : (risksPageInfo.page + 1) * risksPageInfo.count,
                        //     filters: {}
                        //     //if 0 -> 10 = 10
                        //     //if 1 -> (1 + 1) * 10 = 20
                        //     //...
                        // }
                    },
                    onSuccess: (risks: TRisk[]) => {setLocalRisks(risks)},
                    disableReplaceAll: false
                }
            ))
        }
    }

    const handleGoBackToOrigins = () => setLocalRisks(risks);

    const handleChangeRisksQuantitative = () => dispatch(toggleRisksQuantitative());

    const setControlOwners = (risk: TRisk) => {
        const currRisk = findLocalRisk(risk.id);
        if(currRisk){
            setLocalRisks(prev => {
                return [...prev.map(e => e?.id === risk.id ?
                    {
                        ...currRisk,
                        control: {
                            ...currRisk.control,
                            owners: risk.control.owners
                        }
                    }
                    :
                    e
                )];
            });
        }
    }


    const neededSaveRes = neededSave();

    useEffect(() => {
        dispatch(setTableNeedsSave(neededSaveRes));
        //eslint-disable-next-line
    }, [neededSaveRes]);

    return{
        localRisks,
        actions: {
            handleChangeDescription,
            handleChangeName,
            handleChangeType,
            isOk,
            handleSaveChanges,
            handleSetInitialGradeProbability,
            handleSetInitialGradeImpact,
            handleSetControlType,
            handleSetControlScheduler,
            handleDeleteOwner,
            handleOpenAddOwners,
            handleSetResidualGradeImpact,
            handleSetResidualGradeProbability,
            handleDeleteRisk,
            handleGoBackToOrigins,
            handleChangeRisksQuantitative,
            handleChangeControlActions,
            setControlOwners
        },
        neededSave: neededSaveRes,
        isLoadingUpdate: useSelector(loadings).risksListEdit,
        isQuantitative: useSelector(risksQuantitative),
    }
}