import React, {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {
    PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED,
    PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED,
    PATH_LOCAL_COMPLIANCE_REPORTS_NEW
} from "../../../newShared/constants";
import {GeneratedReports} from "./pages/generatedReports";
import {NewReport} from "./pages/newReport";
import {ReportView} from "./pages/reportView";
import {ArchivedReports} from "./pages/archivedReports";
import {ReportIntegrationsDetails} from "./pages/reportIntegrationsDetails";
import {ReportIntegrationExactDetails} from "./pages/reportIntegrationExactDetails";
import {ReportControlView} from "./pages/reportControlView";

export const Routes: FC = () => {

    return <Switch>
        <Route exact path={PATH_LOCAL_COMPLIANCE_REPORTS_NEW} component={NewReport}/>
        <Route exact path={PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED} component={GeneratedReports}/>
        <Route exact path={PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED} component={ArchivedReports}/>

        <Route exact path={[
            `${PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED}/:reportId/integrations`,
            `${PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED}/:reportId/integrations`,
        ]} component={ReportIntegrationsDetails}/>
        <Route exact path={[
            `${PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED}/:reportId/integrations/:appId`,
            `${PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED}/:reportId/integrations/:appId`,
        ]} component={ReportIntegrationExactDetails}/>

        <Route exact path={[
            `${PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED}/:reportId`,
            `${PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED}/:reportId`,
        ]} component={ReportView}/>

        <Route exact path={[
            `${PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED}/:reportId/:controlId`,
            `${PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED}/:reportId/:controlId`,
        ]} component={ReportControlView}/>

        <Redirect to={PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED}/>
    </Switch>
}
