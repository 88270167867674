import {Component, createRef, RefObject} from "react";
import {
    APPROVAL_STATUS,
    COMPLETED_STATUS,
    DRAFT_STATUS,
    IN_PROGRESS_STATUS,
    IStatusProps,
    REMARKS_STATUS,
    SHARED_DRAFT_STATUS,
    SIGNING_STATUS,
    TEditorActorsConfigFromDB,
    TEditorElementData,
    TEditorFillableBlockData,
    TEditorIsLoading,
    TERMINATED_STATUS,
    TFileContent,
    TUpdateDataInFillableFieldEventData,
} from "../../types";

import {DialogContent, EditorDialogStyled} from "./styled";
import {EditorTitleWithActionsRow} from "../editorTitleWithActionsRow";
import {EditorWorkZone} from "../editorWorkzone";
import {DocumentExecutorModel, NewDocDataVariableInput, NewDocDataVariableModel} from "../../../../GQLTypes";
import {TRecipientStepProps} from "../editorSideMenu/components/recipientStep";
import {TApproverStepProps} from "../editorSideMenu/components/approverStep";
import {createEventCommonDialogOpen, createEventSetPagePropertiesMenu} from "../../helpers";
import {TSnack} from "../../../../../newDomain/barsEnvironment/snack/types";
import {Button, DialogActions, DialogTitle} from "@mui/material";

export interface EditorFullProps {
    isOpen: boolean;

    isLoading: TEditorIsLoading;

    content: TFileContent;
    variables: NewDocDataVariableModel[];
    actors: TEditorActorsConfigFromDB;
    documentTitle: string;
    pdfFileId?: string | null;

    currentEmail: string;
    currentFileStatus: string;
    execution: DocumentExecutorModel | undefined;

    onSave: (data: TEditorElementData[], variables: NewDocDataVariableInput[], docTitle: string, actors: TEditorActorsConfigFromDB) => void;
    onSend: EditorFullProps["onSave"];
    onPreviewBeforeSend: EditorFullProps["onSave"];
    onInvalidate: () => void;
    onApproveSignStage: (stageId: string) => void;
    onApproveApproveStage: (stageId: string) => void;
    onRejectSignStage: (stageId: string) => void;
    onRejectApproveStage: (stageId: string) => void;
    onClose: () => void;
    onReturnToDraft: () => void;
    onFillFields: (stepId: string, data: TUpdateDataInFillableFieldEventData) => void;

    onResendInviteApprover: TApproverStepProps["handleResendInvite"];
    onSkipApprover: TApproverStepProps["handleSkip"];
    onResendInviteRecipient: TRecipientStepProps["handleResendInvite"];
    onChangeEta: (stepId: string, timeInMs: number) => void;
    onSaveToEvidence?: () => void;

    handleUseTemplate?: () => void;

    snack: (message: string, variant?: TSnack) => void;

    actualEnvironment: 'isMain' | 'isStaff' | 'isAdmin' | 'isPortal';
}

interface State{
    // refs: Record<string, RefObject<Page>>,
    editorTitleWithActionsRef: RefObject<EditorTitleWithActionsRow>;
    workzoneRef: RefObject<EditorWorkZone>;

    fillableFieldsWithValues: TEditorFillableBlockData[];

    isVerifyUploadedPdf?: {data: TEditorElementData[], variables: NewDocDataVariableInput[], docTitle: string, actors: TEditorActorsConfigFromDB};
}


export class EditorDialog extends Component<EditorFullProps, State> {
    constructor(props: EditorDialog["props"]) {
        super(props);

        const isNotDraft = props.currentFileStatus !== DRAFT_STATUS && props.currentFileStatus !== SHARED_DRAFT_STATUS;

        const fields: TEditorFillableBlockData[] = [];
        props.actors.recipients.forEach(recipient => {
            recipient.fields?.forEach(field => {
                const updatedField: TEditorFillableBlockData = {
                    ...field,
                    actor: recipient.actor,
                    sourceEmailRole: recipient.actor.email + recipient.role,
                    role: recipient.role || '',
                    data: '', 
                    type: field.type as TEditorFillableBlockData["type"],
                };
                isNotDraft && props.execution?.recipientSteps.forEach(step => {
                    step.recipients?.forEach(recipient => {
                        recipient.actions.forEach(action => {
                            if (updatedField.id === action.data?.fieldId) {
                                updatedField.data = action.data?.value;
                            }
                        });
                    });
                });
                fields.push(updatedField);
            });
        });

        this.state = {
            editorTitleWithActionsRef: createRef<EditorTitleWithActionsRow>(),
            workzoneRef: createRef<EditorWorkZone>(),

            fillableFieldsWithValues: fields,

            isVerifyUploadedPdf: undefined,
        };
    };

    getDataForSave = (): {
        content: TEditorElementData[], variables: NewDocDataVariableModel[], docTitle: string,
        actors: TEditorActorsConfigFromDB
    } => {
        const docTitle = this.state.editorTitleWithActionsRef.current?.getTitle() ?? "Not found title element's ref";
        const {
            content,
            variables,
            actors
        } = this.state.workzoneRef.current?.getDataForSave() ?? {
            content: [], variables: [], editors: [],
            actors: {approvers: [], recipients: [], editors: []}
        };

        return {content, variables, docTitle, actors}
    };

    onSend = (needPreview: boolean) => {
        const {content, variables, docTitle, actors} = this.getDataForSave();

        if (docTitle.trim().length === 0) {
            createEventCommonDialogOpen({type: 'emptyTitle', isOpen: true, data: []})
            return;
        }

        if (actors.recipients.length === 0) {
            createEventCommonDialogOpen({type: 'noRecipients', isOpen: true, data: []})
            return;
        }

        {
            const array = actors.recipients.filter(e => e.actor.email === '' && e.role?.length)
            if (array.length > 0) {
                createEventCommonDialogOpen({type: 'notAssignedRole', isOpen: true, data: array.map(e => e.actor.email)})
                return;
            }
        }

        {
            const array = actors.recipients.filter(e => e.actor.email === '' && e.fields?.length);
            if (array.length > 0) {
                createEventCommonDialogOpen({type: 'noAttachedField', isOpen: true, data: array.map(e => e.actor.email)})
                return;
            }
        }

        {
            const array = actors.recipients.filter(e => e.fields?.length === 0 && e.actor.email !== '');
            if (array.length > 0) {
                createEventCommonDialogOpen({type: 'emptyRecipient', isOpen: true, data: array.map(e => e.actor.email)})
                return;
            }
        }

        createEventSetPagePropertiesMenu(undefined);
        if (needPreview) {
            if (this.getStatus().status.createdFromPdf) {
                this.setState((prev): State => ({
                    ...prev,
                    isVerifyUploadedPdf: JSON.parse(JSON.stringify({data: content, variables, docTitle, actors})),
                }));
            } else {
                this.props.onPreviewBeforeSend(content, variables, docTitle, actors);
            }
        } else {
            this.props.onSend(content, variables, docTitle, actors);
        }
    }

    onSave = () => {
        const {content, variables, docTitle, actors} = this.getDataForSave();

        if (docTitle.trim().length === 0) {
            // this.props.snack("Название документа не может быть пустым", "error");
            createEventCommonDialogOpen({type: 'emptyTitle', isOpen: true, data: []})
            return;
        }

        this.props.onSave(content, variables, docTitle, actors);
    };

    onInvalidate = () => {
        this.props.onInvalidate();
    };

    onReturnToDraft = () => {
        if (this.state.isVerifyUploadedPdf) {
            this.setState((prev): State => ({
                ...prev,
                isVerifyUploadedPdf: undefined,
            }));
        } else {
            this.props.onReturnToDraft();
        }
    };

    getStatus = ():IStatusProps => {
        const isDraft = this.props.currentFileStatus === DRAFT_STATUS || this.props.currentFileStatus === SHARED_DRAFT_STATUS;
        const isApproval = this.props.currentFileStatus === APPROVAL_STATUS;
        const isRemarks = this.props.currentFileStatus === REMARKS_STATUS;
        const isSigning = this.props.currentFileStatus === SIGNING_STATUS;
        const isTerminated = this.props.currentFileStatus === TERMINATED_STATUS;
        const isCompleted = this.props.currentFileStatus === COMPLETED_STATUS;
        const isTemplate = this.props.currentFileStatus === 'TEMPLATE';

        //
        const status: IStatusProps["status"] =  {
            currentEmail: this.props.currentEmail,
            currentFileStatus: this.props.currentFileStatus,
            currentExecutorStatus: this.props.execution?.status || null,

            isUserCurrentActor: false,      // defines later
            isUserRecipient: this.props.actors.recipients.some(e => e.actor.email === this.props.currentEmail),
            isUserApprover: this.props.actors.approvers.some(e => e.groups.some(e => e.actors.some(e => e.email === this.props.currentEmail))),
            isUserEditor: this.props.actors.editors.some(e => e.email === this.props.currentEmail),

            isAbleToApproveDocument: false, // defines later
            isAbleToRejectDocument: false,  // defines later
            isAbleToSendDocument: false,    // defines later
            isAbleToResendInvite: false,    // defines later
            isAbleToSkip: false,            // defines later
            isAbleToInvalidate: false,      // defines later

            isMain: this.props.actualEnvironment === 'isMain',
            isStaff: this.props.actualEnvironment === 'isStaff',
            isAdmin: this.props.actualEnvironment === 'isAdmin',
            isPortal: this.props.actualEnvironment === 'isPortal',

            isSigning,
            isApproval,
            isDraft,
            isCompleted,
            isTemplate,

            isPdf: !!this.props.pdfFileId,
            createdFromPdf: !!this.props.pdfFileId && !this.props.content.some(e => e.type === 'page'),

            isVerifyEditablePdf: false,     // defines later
            isVerifyUploadedPdf: !!this.state.isVerifyUploadedPdf,
            isVerifyPdf: false,     // defines later
        };

        status.isVerifyEditablePdf = status.isPdf && !status.createdFromPdf;
        status.isVerifyPdf = status.isVerifyEditablePdf || status.isVerifyUploadedPdf;

        const currentStepId = this.props.execution?.currentStepId;
        if (this.props.execution && currentStepId) {
            {
                const currentStep = this.props.execution.approveSteps.find(e => e.id === currentStepId);
                if (currentStep) {
                    status.isUserCurrentActor = (currentStep.groups?.some(e => e.actors
                            .some(e => e.email === this.props.currentEmail)
                        && e.status === IN_PROGRESS_STATUS) && status.isApproval) ?? false;
                }
            }
            {
                const currentStep = this.props.execution.recipientSteps.find(e => e.id === currentStepId);
                if (currentStep) {
                    const actor = currentStep.recipients?.find(e => e.email === this.props.currentEmail && e.status === IN_PROGRESS_STATUS);
                    status.isUserCurrentActor = (actor?.status === IN_PROGRESS_STATUS) && status.isSigning;
                }
            }
        }

        status.isAbleToSendDocument = status.isMain && isDraft;
        status.isAbleToResendInvite = status.isMain && (isTerminated || isRemarks);
        status.isAbleToSkip = status.isMain && isApproval;
        status.isAbleToInvalidate = status.isMain && (isApproval || isRemarks || isSigning || isTerminated);
        status.isAbleToRejectDocument = (status.isStaff || status.isPortal) && status.isUserCurrentActor && (isApproval || isSigning) && (status.isUserApprover || status.isUserRecipient);
        status.isAbleToApproveDocument = status.isAbleToRejectDocument;

        const canEdit = (!status.isUserCurrentActor && ((status.isMain && status.isDraft) || ((status.isStaff || status.isPortal) && status.isDraft && this.props.actors.editors.some(e => e.email === this.props.currentEmail)))) || status.isAdmin;

        return {status, readonly: !canEdit, isLoading: this.props.isLoading, execution: this.props.execution};
    }

    render() {
        let {status, readonly} = this.getStatus();

        return (
            <EditorDialogStyled open={this.props.isOpen} fullScreen={!status.isTemplate} fullWidth={!status.isTemplate} isTemplate={status.isTemplate}>
                {status.isTemplate && (
                    <DialogTitle sx={{margin: 0}}>
                        {this.props.documentTitle}
                    </DialogTitle>
                )}

                <DialogContent sx={{paddingTop: '0px !important'}}>
                    {!status.isTemplate && (
                        <EditorTitleWithActionsRow ref={this.state.editorTitleWithActionsRef}
                                                   actors={this.state.isVerifyUploadedPdf?.actors ?? this.props.actors}
                                                   onSave={this.onSave}
                                                   onReject={() => {
                                                       status.isApproval && this.props.onRejectApproveStage(this.props.execution?.currentStepId ?? '');
                                                       status.isSigning && this.props.onRejectSignStage(this.props.execution?.currentStepId ?? '');
                                                   }}
                                                   onInvalidate={this.props.onInvalidate}
                                                   onClose={this.props.onClose}
                                                   onApprove={() => {
                                                       status.isApproval && this.props.onApproveApproveStage(this.props.execution?.currentStepId ?? '');
                                                       status.isSigning && this.props.onApproveSignStage(this.props.execution?.currentStepId ?? '');
                                                   }}
                                                   onSend={this.onSend}
                                                   onReturnToDraft={status.isVerifyPdf ? this.onReturnToDraft : undefined}
                                                   onSaveToEvidence={this.props.onSaveToEvidence}

                                                   initialDocumentTitle={this.props.documentTitle}

                                                   status={status}
                                                   readonly={readonly}
                                                   isLoading={this.props.isLoading}

                                                   activeUserFields={this.state.fillableFieldsWithValues
                                                       .filter(e => e.actor?.email === this.props.currentEmail)
                                                       .filter(e => e.pageId.startsWith('pdfPage'))
                                                       .map(e => ({
                                                           fieldId: e.id,
                                                           value: e.data,
                                                           isRequired: (e.isRequired === null || e.isRequired === undefined) || e.isRequired
                                                       }))}

                                                   parentRef={this}
                        />
                    )}

                    <EditorWorkZone ref={this.state.workzoneRef}
                                    content={this.state.isVerifyUploadedPdf?.data as TFileContent ?? this.props.content}
                                    variables={this.state.isVerifyUploadedPdf?.variables ?? this.props.variables}
                                    actors={this.state.isVerifyUploadedPdf?.actors ?? this.props.actors}
                                    pdfFileId={this.props.pdfFileId}
                                    fillableFieldsWithValues={this.state.fillableFieldsWithValues}

                                    onFillFields={this.props.onFillFields}

                                    onResendInviteApprover={this.props.onResendInviteApprover}
                                    onResendInviteRecipient={this.props.onResendInviteRecipient}
                                    onSkipApprover={this.props.onSkipApprover}
                                    onChangeEta={this.props.onChangeEta}

                                    execution={this.props.execution}
                                    status={status}
                                    readonly={readonly}
                                    isLoading={this.props.isLoading}
                    />
                </DialogContent>

                {status.isTemplate && (
                    <DialogActions sx={{margin: 0}}>
                        <Button onClick={this.props.onClose} variant={"text"}>{'Cancel'}</Button>
                        <Button onClick={this.props.handleUseTemplate}>{'Use template'}</Button>
                    </DialogActions>
                )}
            </EditorDialogStyled>
        );
    }
}