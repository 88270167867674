import {galleryImageType} from "../../../../types";
import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {Flex} from "../../../../../../../newShared/components/Layouts";
import colors from "../../../../../../../newShared/theme/colors";

type CoverGalleryDialogPropsType = {
    isOpen: boolean;
    handleClose: () => void;
    onSelect: (image: galleryImageType) => void,
    images: galleryImageType[],
}

export const CoverGalleryDialog = ({isOpen, handleClose, onSelect, images}: CoverGalleryDialogPropsType) => {
    const [selectedImage, setSelectedImage] = useState<galleryImageType | null>(null);

    useEffect(() => {
        if(isOpen) setSelectedImage(null);
    }, [isOpen]);

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{('Select image')}</DialogTitle>

            <DialogContent>
                <Flex w={'100%'} wrap={'wrap'} gap={'10px'} maxh={'424px'}>
                    {images.map(e => (
                        <Flex key={e.id} br={'6px'} ai={'center'} jc={'center'} overflow={'hidden'}
                              cursor={'pointer'}
                              onClick={() => setSelectedImage(e)}
                              border={selectedImage?.id === e.id ? `2px solid ${colors.primary.blue_dark}` : `2px solid ${colors.backgrounds.white}`}
                        >
                            <img src={e.image} width={'173px'} height={'128px'} alt={e.id}/>
                        </Flex>
                    ))}
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button variant={'text'} size={'small'} onClick={handleClose}>{('Cancel')}</Button>
                <Button size={'small'} onClick={() => selectedImage && onSelect(selectedImage)} disabled={!selectedImage}>{('Set new image')}</Button>
            </DialogActions>
        </Dialog>
    );
}