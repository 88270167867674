import {useCreateEditTraining} from "../../../hooks/dialogHooks/useCreateEditTraining";
import React from "react";

import {Autocomplete, Box, Button, Dialog, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import {TrainingModuleWrapper} from "./components/trainingModuleWrapper";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {MainCoverImageType, MainTrainingContentType, MainTrainingLevel} from "../../../../../../newShared/GQLTypes";
import colors from "../../../../../../newShared/theme/colors";
import {SectionButton} from "./components/sectionButton";
import {IconTextButton, LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {AddSectionDialog} from "./components/addSectionDialog";
import {AddFileSectionDialog} from "./components/addFileSectionDialog";
import {examLevelTitleMapper} from "../../../constants";
import {AddVideoSectionDialog} from "./components/addVideoSectionDialog";
import {TrainingCollapsableSection} from "./components/trainingCollapsableSection";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TableRowsIcon from '@mui/icons-material/TableRows';
import AddTaskIcon from '@mui/icons-material/AddTask';
import {LeaveDialog} from "./components/leaveDialog";
import {DeleteSectionDialog} from "./components/deleteSectionDialog";
import {VideoFilePreview} from "./components/preview/videoFilePreview";
import {FilePreview} from "./components/preview/filePreview";
import {TrainingPreviewDialog} from "../trainingPreview";
import {AddEditorSectionDialog} from "./components/addEditorSectionDialog";
import {TinyMceEditor} from "../../../../../../newShared/components/tinyMceEditor";
import {VideoLinkPreview} from "./components/preview/videoLinkPreview";
import {ChangeNameField} from "../../changeNameField";
import {AddExistingSectionDialog} from "../addExistingSectionDialog";
import {TrainingCoverComponent} from "../../trainingCoverComponent";
import {LightTooltip} from "../../../../../../newShared/components/pageTitleAndActions";

export const CreateEditTrainingDialog = ({isOpen, handleClose}: {isOpen: boolean, handleClose: () => void}) => {
    const {
        actions,
        formik,
        categoriesAutocomplete,
        // trainingDurationLimit,
        sections,
        addSectionDialog,
        addFileSectionDialog,
        addVideoSectionDialog,
        addEditorSectionDialog,
        leaveDialog,
        deleteSectionDialog,
        dragNDrop,
        isLoadingSubmit,
        isCreateMode,
        validationSchema,
        changeTrainingName,
        trainingCover,
        addSectionByUseSectionDialog,
        isOkCommon
    } = useCreateEditTraining({handleClose, isOpen});

    return (
        <Dialog open={isOpen} onClose={actions.handleClose} fullScreen fullWidth
                sx={{'.MuiPaper-root': {height: '100vh', width: '100vw'}}}>
            <DialogTitle>
                <Flex w={'100%'} jc={'space-between'} ai={'center'}>
                    <Typography variant={'*h3'} color={colors.grayText}>{isCreateMode ? ('New training') : ('Edit training')}</Typography>

                    <Flex jc={'space-between'} ai={'center'} gap={'16px'}>
                        <LightTooltip title={!formik.dirty ? ('There are no changes in training') : ''}>
                            <span>
                            <LoadingButton onClick={() => formik.submitForm()} startIcon={<AddTaskIcon />} variant={'contained'} size={'small'} loading={isLoadingSubmit} disabled={!isOkCommon}>
                                    {isCreateMode ? ('Create') : ('Save')}
                                </LoadingButton>
                            </span>
                        </LightTooltip>

                        <IconTextButton variant={'outlined'} size={'small'} icon={<RemoveRedEyeIcon />} onClick={actions.handlePreviewTraining}>
                            {('Preview')}
                        </IconTextButton>

                        <Button variant={'outlined'} size={'small'} onClick={actions.handleCancel}>
                            {('Cancel')}
                        </Button>
                    </Flex>
                </Flex>
            </DialogTitle>

            <DialogContent sx={{paddingTop: '0 !important'}}>
                <Flex w={'80%'} m={'20px auto 0 auto'} direction={'column'}>
                    <TrainingModuleWrapper
                        childGap={'20px'}
                        margin={'40px 0 0 0'}
                        description={(
                            <Flex direction={'column'} gap={'12px'}>
                                <Typography variant={'*h3'} color={colors.grayText}>{('Create a training structure')}</Typography>
                                <Typography variant={'*bodyText2'} color={colors.grayText}>{('Provide the title of the training and a brief description. Divide the training into modules or sections, each addressing a specific topic.')}</Typography>

                                <TrainingCoverComponent {...trainingCover} type={MainCoverImageType.Training} margin={'20px 0 0 0'}/>
                            </Flex>
                        )}
                    >
                        {isCreateMode ?
                            <TextField
                                required
                                label={('Training name')}
                                name={'name'}
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.name && formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                size={'small'}
                            />
                            :
                            <ChangeNameField initialValue={formik.values.name}
                                             schema={validationSchema.fields.name}

                                             onSave={changeTrainingName.updateName}
                                             isLoading={changeTrainingName.isLoading}
                            />
                        }

                        <Flex w={'100%'} gap={'20px'}>
                            <Autocomplete
                                freeSolo
                                options={categoriesAutocomplete.trainingCategories}
                                loading={categoriesAutocomplete.isLoading}
                                onOpen={() => categoriesAutocomplete.getData('')}
                                fullWidth
                                onChange={formik.handleChangeAutocomplete('category')}
                                onInputChange={formik.handleChangeAutocomplete('category')}
                                value={formik.values.category}
                                onBlur={formik.handleBlur}
                                renderInput={(props) => (
                                    <TextField
                                        {...props}
                                        required
                                        label={('Training category')}
                                        name={'category'}
                                        size={'small'}
                                        error={Boolean(formik.touched.category && formik.errors.category)}
                                        helperText={formik.touched.category && formik.errors.category}
                                    />
                                )}
                            />

                            <Autocomplete
                                options={[MainTrainingLevel.Basic, MainTrainingLevel.Medium, MainTrainingLevel.High]}
                                getOptionLabel={option => examLevelTitleMapper[option]}
                                fullWidth
                                onChange={(e, value) => value && formik.setFieldValue('level', value)}
                                value={formik.values.level}
                                onBlur={formik.handleBlur}
                                renderInput={(props) => (
                                    <TextField
                                        {...props}
                                        required
                                        label={('Level')}
                                        name={'level'}
                                        size={'small'}
                                        error={Boolean(formik.touched.level && formik.errors.level)}
                                        helperText={formik.touched.level && formik.errors.level}
                                    />
                                )}
                            />
                        </Flex>

                        <TextField
                            label={('Training description')}
                            name={'description'}
                            onChange={formik.handleChange}
                            value={formik.values.description}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.description && formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                            multiline
                            minRows={5}
                        />

                        {/*<Divider/>*/}

                        {/*<Flex ai={'center'} jc={'space-between'}>*/}
                        {/*    <Flex ai={'center'} w={'48%'} gap={'8px'}>*/}
                        {/*        <FormControlLabel*/}
                        {/*            sx={{marginRight: '0'}}*/}
                        {/*            onClick={trainingDurationLimit.onLimitMinimumTrainingDurationCheckboxClick}*/}
                        {/*            control={<Checkbox checked={formik.values.limitMinDuration !== 0}/>}*/}
                        {/*            label={<Typography variant={'*bodyText2'} color={colors.grayText}*/}
                        {/*                               noWrap>{"Limit minimum training duration"}</Typography>}*/}
                        {/*        />*/}

                        {/*        {formik.values.limitMinDuration !== 0 &&*/}
                        {/*            <>*/}
                        {/*                <TextField*/}
                        {/*                    name={'limitMinDuration'}*/}
                        {/*                    onChange={formik.handleChange}*/}
                        {/*                    value={formik.values.limitMinDuration}*/}
                        {/*                    size={'small'}*/}
                        {/*                    type={'number'}*/}
                        {/*                    sx={{width: '60px'}}*/}
                        {/*                    InputProps={{ inputProps: { min: 1 }}}*/}
                        {/*                />*/}
                        {/*                <Typography variant={'*bodyText2'} color={colors.grayText}>{'hours'}</Typography>*/}
                        {/*            </>*/}
                        {/*        }*/}
                        {/*    </Flex>*/}

                            {/*<Flex ai={'center'} w={'48%'} gap={'8px'}>*/}
                            {/*    <FormControlLabel*/}
                            {/*        sx={{marginRight: '0'}}*/}
                            {/*        onClick={trainingDurationLimit.onLimitMaximumTrainingDurationCheckboxClick}*/}
                            {/*        control={<Checkbox checked={formik.values.limitMaxDuration !== 0}/>}*/}
                            {/*        label={<Typography variant={'*bodyText2'} color={colors.grayText}*/}
                            {/*                           noWrap>{"Limit maximum training duration"}</Typography>}*/}
                            {/*    />*/}

                        {/*        {formik.values.limitMaxDuration !== 0 &&*/}
                        {/*            <>*/}
                        {/*                <TextField*/}
                        {/*                    name={'limitMaxDuration'}*/}
                        {/*                    onChange={formik.handleChange}*/}
                        {/*                    value={formik.values.limitMaxDuration}*/}
                        {/*                    size={'small'}*/}
                        {/*                    type={'number'}*/}
                        {/*                    sx={{width: '60px'}}*/}
                        {/*                    InputProps={{ inputProps: { min: 1 }}}*/}
                        {/*                />*/}
                        {/*                <Typography variant={'*bodyText2'} color={colors.grayText}>{'days'}</Typography>*/}
                        {/*            </>*/}
                        {/*        }*/}
                        {/*    </Flex>*/}
                        {/*</Flex>*/}
                    </TrainingModuleWrapper>

                    <TrainingModuleWrapper
                        margin={'20px 0 0 0'}
                        childGap={'20px'}
                        description={(
                            <Flex p={'20px 0 0 0'} direction={'column'} gap={'10px'} position={'sticky'} top={'0'} background={colors.backgrounds.white} zi={'2'}>
                                <Typography variant={'*h3'} color={colors.grayText}>{'Add content to your training'}</Typography>

                                <Typography variant={'*bodyText2'} color={colors.grayText}>{'You can create sections for your training by utilizing pre-designed section templates or by crafting your own.'}</Typography>
                                <Typography variant={'*bodyText2'} color={colors.grayText}>{'Choose the type of section you wish to include:'}</Typography>

                                <ul style={{listStyle: 'decimal', listStylePosition: 'inside'}}>
                                    <li>{('Generate a section via an editor, enabling you to input appropriate content.')}</li>
                                    <li>{('Upload your completed file in .pdf, .docx, .xlsx or .pptx format.')}</li>
                                    <li>{('Upload a video or provide a link to it.')}</li>
                                </ul>
                            </Flex>
                        )}
                    >
                        {formik.errors.content && formik.touched.content && typeof formik.errors.content === 'string' && (
                            <Typography variant={'*bodyText2'} color={colors.text.critical}>
                                {formik.errors.content}
                            </Typography>

                        )}

                        {formik.values.content.length > 0 ?
                            <Flex w={'100%'} jc={'space-between'} gap={'20px'} direction={'column'}>
                                <Flex p={'20px 0 0 0'} direction={'column'} position={'sticky'} top={'0'} background={colors.backgrounds.white} zi={'2'}>
                                    <Flex w={'100%'} ai={'center'} gap={'16px'}>
                                        <IconTextButton
                                            variant={'text'}
                                            icon={<AddIcon />}
                                            size={"small"}
                                            onClick={sections.handleCreateNewSection
                                        }>
                                            {('Add section')}
                                        </IconTextButton>

                                        <IconTextButton
                                            variant={'text'}
                                            icon={<ContentCopyIcon />}
                                            size={"small"}
                                            onClick={sections.handleUseSection
                                        }>
                                            {('Use section')}
                                        </IconTextButton>

                                        <IconTextButton
                                            variant={'text'}
                                            icon={<FormatListBulletedIcon />}
                                            size={"small"}
                                            onClick={sections.hideAllSections}
                                            disabled={sections.isCollapseAllDisabled}
                                        >
                                            {('Collapse all')}
                                        </IconTextButton>

                                        <IconTextButton
                                            variant={'text'}
                                            icon={<TableRowsIcon />}
                                            size={"small"}
                                            onClick={sections.openAllSections}
                                            disabled={sections.isExpandAllDisabled}
                                        >
                                            {('Expand all')}
                                        </IconTextButton>
                                    </Flex>

                                    <Divider />
                                </Flex>

                                <Flex w={'100%'} gap={'20px'} overflow={'auto'} direction={'column'}>
                                    {[...formik.values.content]
                                        .sort((a, b) => a.order - b.order)
                                        .map(e => {
                                            return(
                                                <TrainingCollapsableSection
                                                    key={e.sectionId}
                                                    section={e}
                                                    isOpen={sections.openedSections.some(s => s === e.sectionId)}
                                                    toggleOpen={sections.toggleSection}
                                                    handleDeleteSection={sections.handleDeleteSection}
                                                    handleEditSection={sections.handleEditSection}
                                                    sameNameSectionExists={sections.isSameNameSectionExists(e)}
                                                    findSection={dragNDrop.findSection}
                                                    moveSection={dragNDrop.moveSection}
                                                >
                                                    {e.type === MainTrainingContentType.VideoLink && <VideoLinkPreview section={e}/>}
                                                    {e.type === MainTrainingContentType.VideoFile && <VideoFilePreview section={e}/>}
                                                    {e.type === MainTrainingContentType.File && <FilePreview section={e} />}
                                                    {e.type === MainTrainingContentType.Editor &&
                                                      <Box sx={{width: '100%', minHeight: '600px', overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
                                                        <TinyMceEditor key={e.data.innerHtml || ''} readonly initialContent={e.data.innerHtml ?? ''} onSave={() => null} hideMenu/>
                                                      </Box>
                                                    }
                                                </TrainingCollapsableSection>
                                            )
                                        })}
                                </Flex>

                            </Flex>
                            :
                            <Flex w={'100%'} jc={'space-between'} gap={'16px'}>
                                <SectionButton title={'Create new section'} onClick={sections.handleCreateNewSection}/>
                                <SectionButton title={'Use existing section'} onClick={sections.handleUseSection}/>
                            </Flex>
                        }
                    </TrainingModuleWrapper>
                </Flex>
            </DialogContent>

            <AddSectionDialog {...addSectionDialog} />
            <AddFileSectionDialog {...addFileSectionDialog}/>
            <AddVideoSectionDialog {...addVideoSectionDialog}/>
            <AddEditorSectionDialog {...addEditorSectionDialog}/>
            <LeaveDialog {...leaveDialog}/>
            <DeleteSectionDialog {...deleteSectionDialog}/>
            <TrainingPreviewDialog />
            <AddExistingSectionDialog
                onAddSections={addSectionByUseSectionDialog}
                excludeTrainingId={formik.values.trainingId || undefined}
            />
        </Dialog>
    );
}
