import React from "react";
import {Flex} from "../../../../../newShared/components/Layouts"
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Box, Button, MenuItem, Skeleton, Tab, Tabs, TextField} from "@mui/material";
import {Edit} from "@mui/icons-material";
import {TabContext, TabPanel} from "@mui/lab";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {TExactAssetTabs} from "../../types";
import {AddMenuItem} from "../../../../../newShared/components/Basic/AddMenuItem";
import {useVirtualAssetExact} from "../../hooks/useVirtualAssetExact";
import {DeleteAssetDialog} from "../../components/Dialogs/DeleteAssetDialog";
import {CreateSettingRowDialog} from "../../components/Dialogs/CreateSettingRowDialog";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import * as S from "../../components/styled";


export const VirtualAssetExact = () => {
    const {isLessThen1050} = useMedia();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});

    const {
        isOk,
        asset,
        settings,
        tab,
        setTab,
        handleGoBack,
        editInfoMode,
        form,
        handleChange,
        handleSetType,
        isLoadingExact,
        isLoadingSettings,

        handleSaveInfo,
        handleCancelEditInfo,
        handleEditInfoBtn,
        setCreateSettingRowDialog,
        handleChangeCustomField,

        deleteAssetDialog: {setDeleteAssetDialog, responseDelete},
    } = useVirtualAssetExact();

    return (
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={asset?.name}
                handleGoBack={handleGoBack}
                showMenuWhen={isLessThen1050}
                actions={[
                    {
                        title: t('Delete asset'),
                        onClick: () => {setDeleteAssetDialog({isOpen: true, assetType: 'virtual'})},
                        variant: 'text',
                        icon: <DeleteForeverIcon/>,
                        disabled: !asset || isLoadingExact
                    },
                ]}
            />



            {/*TABS*/}
            <TabContext value={tab}>
                <Box sx={{ margin: '30px 0 0 0' }}>
                    <Tabs variant={'scrollable'} value={tab}
                          aria-label="basic tabs example"
                          onChange={(e, newValue: TExactAssetTabs) => setTab(newValue)} >
                        <Tab disabled={isLoadingExact} label={t("Information")} value={'information'} sx={{paddingLeft: '0'}}/>
                        {/*<Tab disabled={isLoadingExact} label={t("History")} value={'history'} />*/}
                        {/*<Tab disabled={true} label={t("Services detected")} value={'servicesDetected'} />*/}
                    </Tabs>
                </Box>

                {/*INFO*/}
                <TabPanel value={'information'} sx={{padding: '16px 0', overflowY: 'auto'}}>
                    { editInfoMode ?
                        <Flex w={'100%'} direction={'column'} ai={'flex-start'}  overflow={'hidden'} >
                            <Flex ai={'center'} p={'6px 0'}  w={'100%'} >
                                <Button size={'large'}
                                        disabled={!isOk() || isLoadingExact}
                                        onClick={handleSaveInfo} >
                                    {t('Save')}
                                </Button>
                                <Button variant={'outlined'} size={'large'}
                                        disabled={isLoadingExact}
                                        sx={{marginLeft: revDir ? undefined : '8px', marginRight: revDir ? '8px' : undefined}}
                                        onClick={handleCancelEditInfo} >{t('Cancel')}</Button>
                            </Flex>

                            <Flex direction={'column'} w={'100%'} ai={'flex-start'} m={'10px 0 0 0'}  >

                                <TextField label={t('Name')} size={"small"}
                                           required
                                           sx={{marginTop: '6px', width: '320px', '& label': {top: '0 !important'}}}
                                           value={form?.name} onChange={handleChange} name={'name'} />

                                {/*Type*/}
                                <TextField select fullWidth label={t('Type')} value={form?.type} size={'small'}
                                           required
                                           sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                           onChange={(e) => handleSetType(e.target.value)}
                                >
                                    <AddMenuItem text={t('Add new')} onClick={() => setCreateSettingRowDialog({isOpen: true, field: 'type', assetType: 'virtual'})} />
                                    { settings && settings.type && Object.keys(settings.type).map((type) => <MenuItem key={type} value={type}>{type}</MenuItem>)}
                                </TextField>


                                <TextField label={t('Virtual machine name')} value={form?.virtualMachineName} size={'small'}
                                           sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                           onChange={handleChange} name={'virtualMachineName'} />

                                <TextField label={t('Virtual machine IP')} value={form?.virtualMachineIp} size={'small'}
                                           sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                           onChange={handleChange} name={'virtualMachineIp'} />

                                <TextField label={t('IP')} value={form?.ip} size={'small'}
                                           sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                           onChange={handleChange} name={'ip'} />

                                <TextField label={t('Owner')} value={form?.owner} size={'small'}
                                           sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                           onChange={handleChange} name={'owner'} />

                                {(settings && settings.customFields && Object.keys(settings.customFields).length > 0) &&
                                    Object.keys(settings.customFields).map((e) =>
                                        <TextField key={e} size={'small'} label={e} value={form?.customFields[e] || ''} name={e}
                                                   onChange={handleChangeCustomField} sx={{marginTop: '16px', width: '320px', '& label': {top: '0px !important'}}}
                                        />
                                    )}

                            </Flex>
                        </Flex>
                        :
                        <Flex w={'100%'} direction={'column'} ai={'flex-start'} style={{opacity: responseDelete ? 0 : 'undefined'}}>
                            <Button variant={'text'} startIcon={<Edit style={{margin: revDir ? '0 0 0 9px' : undefined}}/>}
                                    sx={{marginTop: '10px', paddingLeft: !revDir ? '3px' : undefined, paddingRight: revDir ? '3px' : undefined}}
                                    disabled={isLoadingExact || isLoadingSettings}
                                    onClick={handleEditInfoBtn}>
                                {t('Edit information')}
                            </Button>

                            {isLoadingExact ? <Skeleton width={'250px'} sx={{mt: '16px'}}/> : <S.FieldTitleText> {`${t('Name')}: `}<S.FieldValueText>{asset?.name ?? ''}</S.FieldValueText></S.FieldTitleText>}
                            {isLoadingExact ? <Skeleton width={'250px'} sx={{mt: '16px'}}/> : <S.FieldTitleText> {`${t('Type')}: `}<S.FieldValueText>{asset?.type ?? ''}</S.FieldValueText></S.FieldTitleText>}
                            {isLoadingExact ? <Skeleton width={'250px'} sx={{mt: '16px'}}/> : <S.FieldTitleText> {`${t('Virtual machine name')}: `}<S.FieldValueText>{asset?.virtualMachineName ?? ''}</S.FieldValueText></S.FieldTitleText>}
                            {isLoadingExact ? <Skeleton width={'250px'} sx={{mt: '16px'}}/> : <S.FieldTitleText> {`${t('Virtual machine IP')}: `}<S.FieldValueText>{asset?.virtualMachineIp ?? ''}</S.FieldValueText></S.FieldTitleText>}
                            {isLoadingExact ? <Skeleton width={'250px'} sx={{mt: '16px'}}/> : <S.FieldTitleText> {`${t('IP')}: `}<S.FieldValueText>{asset?.ip ?? ''}</S.FieldValueText></S.FieldTitleText>}
                            {isLoadingExact ? <Skeleton width={'250px'} sx={{mt: '16px'}}/> : <S.FieldTitleText> {`${t('Owner')}: `}<S.FieldValueText>{asset?.owner ?? ''}</S.FieldValueText></S.FieldTitleText>}

                            {!isLoadingExact && settings && Object.keys(settings?.customFields ?? {}).map((key) => asset?.customFields?.[key] && (
                                <S.FieldTitleText key={key}> {`${key}: `} <S.FieldValueText>{asset?.customFields[key] ?? ''}</S.FieldValueText> </S.FieldTitleText>
                            )) }
                        </Flex>
                    }
                </TabPanel>

            </TabContext>

            <DeleteAssetDialog />
            <CreateSettingRowDialog onSuccess={handleSetType}/>
        </Flex>
    )
}
