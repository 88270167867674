import {useOrganizationExact} from "../../../hooks/organizations/useOrganizationExact";
import React, {useEffect} from "react";
import {Flex} from "../../../../../../newShared/components/Layouts";
import * as S from '../../../components/styled';
import colors from "../../../../../../newShared/theme/colors";
import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {getAvatarInitials, makeFirstLetterUpper, validateEmail} from "../../../../../../newShared/utils/text";
import {IconTextButton, LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
//icons
import EditIcon from '@mui/icons-material/Edit';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AddIcon from '@mui/icons-material/Add';


import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {calculateFullAmountByItems, getCardExpiry, getSub} from "../../../helpers";
import {Typo} from "../../../../../../newShared/components/Typography";
import {TabContext} from "@mui/lab";
import {organizationTabs, TBillingInfo, TSmallWorkspace} from "../../../types";
import {
    ALL_PAYMENT_HISTORY,
    BUSINESS_PLAN,
    DEFAULT_LOGO_URL,
    ENTERPRISE_PLAN,
    FAILED_PAYMENT_HISTORY,
    ISRAEL_COUNTRY_NAME,
    ISRAEL_VAT_PERCENTAGE,
    planBenefits,
    SUCCESS_PAYMENT_HISTORY,
    YEAR_PERIOD
} from "../../../constants";
import {CreateWorkspace} from "../../../components/organizations/exactWorkspace/dialogs/createWorkspace";
import {EditOrganization} from "../../../components/organizations/dialogs/editOrganization";
import {TransferOwnership} from "../../../components/organizations/dialogs/transferOwnership";
// import {InviteOrganizationUser} from "../../../components/organizations/dialogs/inviteOrganizationUser";
import {UseManageWorkspacesAndOrganizations} from "../../../../cookies/hooks/useManageWorkspacesAndOrganizations";
import {GetInner} from "../newOrganizationCreate";
import {MinimizeInfoWrapper} from "../../../../../../newShared/components/minimizeInfoWrapper";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";
import {LocalSpinner} from "../../../../../../newShared/components/Spinner";

//card icons
import {
    ReactComponent as AmericanExpressIcon
} from '../../../../../../newShared/images/paymentCardIcons/americanExpress.svg';
import {ReactComponent as VisaIcon} from '../../../../../../newShared/images/paymentCardIcons/visa.svg';
import {ReactComponent as MastercardIcon} from '../../../../../../newShared/images/paymentCardIcons/mastercard.svg';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import {useDispatch, useSelector} from "react-redux";
import {
    createOrgInitialData,
    organizationsLoadings,
    selectedOrganization,
    selectedOrganizationData
} from "../../../store/slice";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {UpdateOrganizationBillingInfo} from "../../../store/actions";
import {EmptyArrayImage} from "../../../../../../newShared/components/EmptyArrayImage";
import {GenericTable} from "../../../../../../newShared/components/genericTable";

export const OrganizationExact = () => {
    const {
        organization,
        actions,
        tab,
        handleSetTab,
        workspaces,
        plans,
        // users,
        isAvailableTransfer,
        isAvailableTransferAndEdit,
        _isExpired,
        getNoWorkspaceFoundReason,
        // isMember,
        billing,
        workspacesPaging
    } = useOrganizationExact();
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'pathSettings.OrganizationExact'});
    const {isLessThen1050, isMobile, isLessThenCustomQuery, isTablet} = useMedia(858);
    const {user} = UseManageWorkspacesAndOrganizations();
    const currRole = organization?.collaborators.find(e => e.publicId === user?.publicId ?? '');
    const plansCurrentPlanColor = '#25769F';
    const plansCurrentPlanTextColor = '#fff';

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={organization?.name}
                titleMaxWidth={'300px'}
                handleGoBack={actions.handleGoBack}
                icon={
                    <Avatar src={organization?.logo ?? DEFAULT_LOGO_URL} sx={{width: '45px', height: '45px', backgroundColor: organization?.logo !== null ? 'inherit' : colors.primary.blue, margin: '0 8px'}}>
                        <Typography fontSize={'16px'} fontWeight={300}>{getAvatarInitials(organization?.name ?? 'NA')}</Typography>
                    </Avatar>
                }
                showMenuWhen={isLessThen1050}
                actions={[
                    {
                        variant: 'text',
                        disabled: !organization || !isAvailableTransferAndEdit || organization.blocked || _isExpired,
                        icon: <AddIcon />,
                        onClick: actions.handleCreateWorkspace,
                        title: t('Create workspace')
                    },
                    {
                        variant: 'text',
                        disabled: !organization || !isAvailableTransferAndEdit,
                        icon: <EditIcon />,
                        onClick: actions.handleEditOrganization,
                        title: t('Edit')
                    },
                    {
                        variant: 'text',
                        disabled: !organization || !isAvailableTransfer || organization.blocked || _isExpired,
                        icon: <SwapHorizIcon />,
                        onClick: actions.handleTransferOwnership,
                        title: t('Transfer ownership')
                    }
                ]}
            />


            {organization && <Typography variant={"subtitle2"}  sx={{margin: '8px 0 0 0', color: colors.grayText}}>{t('Role')}: {currRole ? t(`orgRoles.${makeFirstLetterUpper(currRole.role)}`) : t('Collaborator')}</Typography>}

            {organization &&
                <MinimizeInfoWrapper margin={'16px 0 0 0'}>
                    <S.ExactOrganizationOverviewWrapper>
                        <S.ExactOrgOverviewSubWrapper>
                            <Flex>
                                <Typography variant={"body1"} sx={{
                                    margin: !revDir ? '0 8px 0 0' : '0 0 0 8px',
                                    color: colors.lightGrayText
                                }}>
                                    {t('Subscription')}
                                </Typography>
                                <Typography variant={"body1"} sx={{color: colors.grayText}}>
                                    {t(`subs.${getSub(organization.subscription)}`)}
                                </Typography>
                            </Flex>

                            <Flex  m={'20px 0 0 0'}>
                                <Typography variant={"body1"} sx={{
                                    margin: !revDir ? '0 8px 0 0' : '0 0 0 8px',
                                    color: _isExpired ? colors.warningActions.red : colors.lightGrayText
                                }}>
                                    {t('Expires on')}
                                </Typography>
                                <Typography variant={"body1"} sx={{color: colors.grayText}}>
                                    {parseDateAuto(organization.subscriptionExpiry)}
                                </Typography>
                            </Flex>

                            <Flex m={'20px 0 0 0'}>
                                <Typography variant={"body1"} sx={{
                                    margin: !revDir ? '0 8px 0 0' : '0 0 0 8px',
                                    color: colors.lightGrayText
                                }}>
                                    {t('ID')}
                                </Typography>
                                <Typography variant={"body1"} sx={{color: colors.grayText, fontWeight: 400, fontSize: '14px'}}>
                                    {organization.id}
                                </Typography>
                            </Flex>
                        </S.ExactOrgOverviewSubWrapper>

                        <S.ExactOrgOverviewSubWrapper>
                            <Flex m={isMobile ? '20px 0 0 0' : '0'}>
                                <Typography variant={"body1"} sx={{
                                    margin: !revDir ? '0 8px 0 0' : '0 0 0 8px',
                                    color: colors.lightGrayText
                                }}>
                                    {t('Available workspaces')}
                                </Typography>
                                <Typography variant={"body1"} sx={{color: colors.grayText}}>
                                    {organization.workspaceCount} {t('of')} {organization.configuration.availableWorkspaceCount}
                                </Typography>
                            </Flex>

                            <Flex  m={'20px 0 0 0'}>
                                <Typography variant={"body1"} sx={{
                                    margin: !revDir ? '0 8px 0 0' : '0 0 0 8px',
                                    color: colors.lightGrayText
                                }}>
                                    {t('Collaborators per workspace')}
                                </Typography>
                                <Typography variant={"body1"} sx={{color: colors.grayText}}>
                                    {organization.configuration.availableCollaboratorsCount}
                                </Typography>
                            </Flex>

                            <Flex  m={'20px 0 0 0'}>
                                <Typography variant={"body1"} sx={{
                                    margin: !revDir ? '0 8px 0 0' : '0 0 0 8px',
                                    color: colors.lightGrayText
                                }}>
                                    {t('Available target scans')}
                                </Typography>
                                <Typography variant={"body1"} sx={{color: colors.grayText}}>
                                    {organization.configuration.availableTargetsVulnerabilityScan}
                                </Typography>
                            </Flex>

                            <Flex  m={'20px 0 0 0'}>
                                <Typography variant={"body1"} sx={{
                                    margin: !revDir ? '0 8px 0 0' : '0 0 0 8px',
                                    color: colors.lightGrayText
                                }}>
                                    {t('KYC scans per workspace')}
                                </Typography>
                                <Typography variant={"body1"} sx={{color: colors.grayText}}>
                                    {organization.configuration.availableKycVerificationCount}
                                </Typography>
                            </Flex>
                        </S.ExactOrgOverviewSubWrapper>
                    </S.ExactOrganizationOverviewWrapper>

                    {organization.blocked &&
                        <Typo
                            color={'red'}
                            margin={'16px 0 0 0'}
                            fontSize={'14px'}
                            fontWeight={400}
                            letterSpacing={'0.5px'}
                        >
                            <i style={{fontWeight: 600}}>{t('Organization blocked')}: </i>
                            {organization.blockedReason ?? t('Unknown reason')}
                        </Typo>
                    }
                </MinimizeInfoWrapper>
            }
            {/*{!organization && <OrganizationOverviewSkeleton />}*/}
            {/*{organization && (isMobile ? isShowOverview : true) &&*/}
            {/*    <>*/}
            {/*        */}
            {/*    </>*/}
            {/*}*/}

            <TabContext value={tab}>
                <Box sx={{ margin: '8px 0 0 0'}}>
                    <Tabs variant={'scrollable'} value={tab} onChange={(e, newValue: organizationTabs) => handleSetTab(newValue)} aria-label="basic tabs example">
                        <Tab label={t("Workspaces")} value={'workspaces'} sx={{paddingLeft: '8px'}}/>
                        {/*<Tab label={t("Users")} value={'users'} disabled={isMember}/>*/}
                        <Tab label={t("Plans")} value={'plans'} disabled={!currRole}/>
                        <Tab label={t("Billing")} value={'billing'} disabled/>
                        <Tab label={t("Billing history")} value={'billingHistory'} disabled/>
                        <Tab label={t("Notifications")} value={'notifications'} disabled/>
                    </Tabs>
                </Box>

                {tab === 'workspaces' &&
                    <GenericTable<TSmallWorkspace>
                        id={'OrganizationExact-workspaces'}
                        columnsConfig={{totalName: 'Total workspaces', forceShowCardsWhen: isMobile}}
                        paging={workspacesPaging}
                        rowsConfig={{
                            rows: workspaces.workspaces,
                            customCellPaddings: '8px',
                            getRowUniqueId: (row) => row.id,
                            onRowClick: workspaces.onWorkspaceClick,
                            columnsConfigs: [
                                {name: t('Name'), key: 'name', default: true},
                                {name: t('Collaborators'), key: 'collabs', default: true, valueGetter: (row) => `${row.collaboratorsCount} ${t('of')} ${organization?.configuration.availableCollaboratorsCount ?? 0}`},
                                {name: t('ID'), key: 'id', default: true},
                            ],
                        }}
                        filtersConfig={{
                            genericFilterProps: {
                                configs: [],
                                fetchResultWithSelectedFilters: () => null,
                                isAddFilters: false
                            }
                        }}
                        emptyArrayImageProps={{
                            type: 'any',
                            filterNotApplied: {
                                text: t('No workspaces found'),
                                secondaryText: t(`noWorkspaceFoundReason.${getNoWorkspaceFoundReason()}`),
                                onClick: (organization && isAvailableTransferAndEdit && !organization.blocked && !_isExpired) ? actions.handleCreateWorkspace : undefined
                            }
                        }}
                    />
                }

                {tab === 'plans' &&
                    <>
                        {organization &&
                            <S.SelectPlanContainer>
                                <Flex m={'0 0 16px 0'} ai={'center'}>
                                    <LoadingButton
                                        color={'secondary'}
                                        loading={plans.isLoadingSendRequest}
                                        disabled={!plans.isOk}
                                        onClick={plans.handleSendChangePlanRequest}
                                        size={'small'}
                                        sx={{minWidth: '120px'}}
                                    >
                                        {t('Send request')}
                                    </LoadingButton>

                                    <Typography variant={isMobile ? 'body2' : 'body1'} sx={{margin: !revDir ? '0 0 0 16px' : '0 16px 0 0'}} color={colors.grayText}>{t('You can request a plan upgrade by selecting another plan down below')}</Typography>
                                </Flex>

                                <TableContainer
                                    sx={{
                                        width: '100%',
                                        // overflow: 'hidden',
                                        boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                                        borderRadius: '8px !important',
                                        border: `1px solid #EAECF0`,
                                        backgroundColor: '#fff',
                                        zIndex: '1000',
                                        flexGrow: '1',
                                        // maxHeight: '70vh',
                                        // minHeight: '990px'
                                        // minHeight: '600px',
                                    }}
                                >
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ minWidth: isLessThenCustomQuery ? '20%' : '34%'}}>
                                                    {''}
                                                </TableCell>

                                                {/*<TableCell*/}
                                                {/*    sx={{*/}
                                                {/*        backgroundColor: plans.selectedPlan.selectedFree ? colors.primary.blue_dark : (organization.subscription === FREE_PLAN ? plansCurrentPlanColor : undefined),*/}
                                                {/*        '& div *' : {color: (plans.selectedPlan.selectedFree) ? '#fff !important' : (organization.subscription === FREE_PLAN ? `${plansCurrentPlanTextColor} !important` : undefined)},*/}
                                                {/*        padding: isLessThenCustomQuery ? '12px' : undefined,*/}
                                                {/*        cursor: organization.subscription !== FREE_PLAN ? 'pointer' : undefined*/}
                                                {/*    }}*/}
                                                {/*    onClick={() => organization.subscription !== FREE_PLAN && plans.selectPlan(FREE_PLAN)}*/}
                                                {/*>*/}
                                                {/*    <Flex w={'100%'} ai={'center'} jc={'center'} talign={'center'}>*/}
                                                {/*        {isLessThenCustomQuery ?*/}
                                                {/*            <Typography fontSize={'12px'} fontWeight={700} color={colors.grayText}>*/}
                                                {/*                {t('FREE')}*/}
                                                {/*                <br />*/}
                                                {/*                <span style={{fontSize: '18px', fontWeight: 500, color: colors.text.grey_dark}}>$0</span>*/}
                                                {/*                <span style={{fontSize: '12px', fontWeight: 400, color: colors.text.grey_dark}}>{t('/mo')}</span>*/}
                                                {/*            </Typography>*/}
                                                {/*            :*/}
                                                {/*            <Typography variant={'h4'} color={colors.grayText}>*/}
                                                {/*                {t('FREE')}*/}
                                                {/*                <br />*/}
                                                {/*                <span style={{fontSize: '24px', fontWeight: 500, color: colors.text.grey_dark}}>$0</span>*/}
                                                {/*                <span style={{fontSize: '14px', fontWeight: 400, color: colors.text.grey_dark}}>{t('/mo')}</span>*/}
                                                {/*            </Typography>*/}
                                                {/*        }*/}
                                                {/*    </Flex>*/}
                                                {/*</TableCell>*/}

                                                <TableCell
                                                    sx={{
                                                        backgroundColor: plans.selectedPlan.selectedBusiness ? colors.primary.blue_dark : (organization.subscription === BUSINESS_PLAN ? plansCurrentPlanColor : undefined),
                                                        '& div *' : {color: (plans.selectedPlan.selectedBusiness) ? '#fff !important' : (organization.subscription === BUSINESS_PLAN ? `${plansCurrentPlanTextColor} !important` : undefined)},
                                                        padding: isLessThenCustomQuery ? '10px' : undefined,
                                                        cursor: organization.subscription !== BUSINESS_PLAN ? 'pointer' : undefined
                                                    }}
                                                    onClick={() => organization.subscription !== BUSINESS_PLAN && plans.selectPlan(BUSINESS_PLAN)}
                                                >
                                                    <Flex w={'100%'} ai={'center'} jc={'center'} talign={'center'}>
                                                        {isLessThenCustomQuery ?
                                                            <Typography fontSize={'12px'} fontWeight={700} color={colors.grayText}>
                                                                {t('BUSINESS')}
                                                                {/*<br />*/}
                                                                {/*<span style={{fontSize: '18px', fontWeight: 500, color: colors.text.grey_dark}}>$199</span>*/}
                                                                {/*<span style={{fontSize: '12px', fontWeight: 400, color: colors.text.grey_dark}}>{t('/mo')}</span>*/}
                                                            </Typography>
                                                            :
                                                            <Typography variant={'h4'} color={colors.grayText}>
                                                                {t('BUSINESS')}
                                                                {/*<br />*/}
                                                                {/*<span style={{fontSize: '24px', fontWeight: 500, color: colors.text.grey_dark}}>$199</span>*/}
                                                                {/*<span style={{fontSize: '14px', fontWeight: 400, color: colors.text.grey_dark}}>{t('/mo')}</span>*/}
                                                            </Typography>
                                                        }
                                                    </Flex>
                                                </TableCell>

                                                <TableCell
                                                    sx={{
                                                        backgroundColor: plans.selectedPlan.selectedEnterprise ? colors.primary.blue_dark : (organization.subscription === ENTERPRISE_PLAN ? plansCurrentPlanColor : undefined),
                                                        '& div *' : {color: (plans.selectedPlan.selectedEnterprise) ? '#fff !important' : (organization.subscription === ENTERPRISE_PLAN ? `${plansCurrentPlanTextColor} !important` : undefined)},
                                                        padding: isLessThenCustomQuery ? '7px' : undefined,
                                                        cursor: organization.subscription !== ENTERPRISE_PLAN ? 'pointer' : undefined
                                                    }}
                                                    onClick={() => organization.subscription !== ENTERPRISE_PLAN && plans.selectPlan(ENTERPRISE_PLAN)}
                                                >
                                                    <Flex w={'100%'} ai={'center'} jc={'center'}>
                                                        {isLessThenCustomQuery ?
                                                            <Typography fontSize={'12px'} fontWeight={700} color={colors.grayText}>
                                                                {t('ENTERPRISE')}
                                                                {/*<br />*/}
                                                                {/*<span style={{fontSize: '18px', fontWeight: 500, color: colors.text.grey_dark}}>$500</span>*/}
                                                                {/*<span style={{fontSize: '12px', fontWeight: 400, color: colors.text.grey_dark}}>{t('/mo')}</span>*/}
                                                            </Typography>
                                                            :
                                                            <Typography variant={'h4'} color={colors.grayText}>
                                                                {t('ENTERPRISE')}
                                                                {/*<br />*/}
                                                                {/*<span style={{fontSize: '24px', fontWeight: 500, color: colors.text.grey_dark}}>$500</span>*/}
                                                                {/*<span style={{fontSize: '14px', fontWeight: 400, color: colors.text.grey_dark}}>{t('/mo')}</span>*/}
                                                            </Typography>
                                                        }
                                                    </Flex>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {planBenefits.map((e, id, arr) => {
                                                // const isCurrentlyOrgPlanFree = organization.subscription === FREE_PLAN;
                                                const isCurrentlyOrgPlanBusiness = organization.subscription === BUSINESS_PLAN;
                                                const isCurrentlyOrgPlanEnterprise = organization.subscription === ENTERPRISE_PLAN;
                                                return(
                                                    <>
                                                        <TableRow id={e.rowName} sx={{'&:nth-of-type(odd)' : {backgroundColor: '#F9FAFB'}}}>
                                                            <TableCell
                                                                sx={{
                                                                    paddingLeft: isLessThenCustomQuery ? '8px' : undefined,
                                                                    paddingRight: isLessThenCustomQuery ? '8px' : undefined,
                                                                    borderTop: id === 0 ? `1px solid ${colors.primary.disable} !important` : undefined
                                                                }}
                                                            >
                                                                <GetInner data={{type: 'text', value: e.rowName}} />
                                                            </TableCell>

                                                            {/*<TableCell*/}
                                                            {/*    sx={{*/}
                                                            {/*        backgroundColor: plans.selectedPlan.selectedFree ? colors.primary.blue_dark : (isCurrentlyOrgPlanFree ? plansCurrentPlanColor : undefined),*/}
                                                            {/*        '& div *' : {color: (plans.selectedPlan.selectedFree) ? '#fff !important' : (isCurrentlyOrgPlanFree ? plansCurrentPlanTextColor : undefined)},*/}
                                                            {/*        borderBottomColor: plans.selectedPlan.selectedFree ? colors.primary.blue_dark : (isCurrentlyOrgPlanFree ? plansCurrentPlanColor : undefined),*/}
                                                            {/*        padding: isLessThenCustomQuery ? '12px' : undefined,*/}
                                                            {/*        borderTop: plans.selectedPlan.selectedFree ? undefined : (id === 0 ? (isCurrentlyOrgPlanFree ? `1px solid ${plansCurrentPlanColor} !important` : `1px solid ${colors.primary.disable} !important`) : undefined)*/}
                                                            {/*    }}*/}
                                                            {/*>*/}
                                                            {/*    <Flex w={'100%'} ai={'center'} jc={'center'} talign={'center'}>*/}
                                                            {/*        <GetInner data={e.free}/>*/}
                                                            {/*    </Flex>*/}
                                                            {/*</TableCell>*/}

                                                            <TableCell
                                                                sx={{
                                                                    backgroundColor: plans.selectedPlan.selectedBusiness ? colors.primary.blue_dark : (isCurrentlyOrgPlanBusiness ? plansCurrentPlanColor : undefined),
                                                                    '& div *' : {color: (plans.selectedPlan.selectedBusiness || isCurrentlyOrgPlanBusiness) ? '#fff !important' : (isCurrentlyOrgPlanBusiness ? plansCurrentPlanTextColor : undefined)},
                                                                    borderBottomColor: plans.selectedPlan.selectedBusiness ? colors.primary.blue_dark : (isCurrentlyOrgPlanBusiness ? plansCurrentPlanColor : undefined),
                                                                    padding: isLessThenCustomQuery ? '10px' : undefined,
                                                                    borderTop: plans.selectedPlan.selectedBusiness ? undefined : (id === 0 ? (isCurrentlyOrgPlanBusiness ? `1px solid ${plansCurrentPlanColor} !important` : `1px solid ${colors.primary.disable} !important`) : undefined)
                                                                }}
                                                            >
                                                                <Flex w={'100%'} ai={'center'} jc={'center'} talign={'center'}>
                                                                    <GetInner data={e.business}/>
                                                                </Flex>
                                                            </TableCell>

                                                            <TableCell
                                                                sx={{
                                                                    backgroundColor: plans.selectedPlan.selectedEnterprise ? colors.primary.blue_dark : (isCurrentlyOrgPlanEnterprise ? plansCurrentPlanColor : undefined),
                                                                    '& div *' : {color: (plans.selectedPlan.selectedEnterprise || isCurrentlyOrgPlanEnterprise) ? '#fff !important' : (isCurrentlyOrgPlanEnterprise ? plansCurrentPlanTextColor : undefined)},
                                                                    borderBottomColor: plans.selectedPlan.selectedEnterprise ? colors.primary.blue_dark : (isCurrentlyOrgPlanEnterprise ? plansCurrentPlanColor : undefined),
                                                                    padding: isLessThenCustomQuery ? '7px' : undefined,
                                                                    borderTop: plans.selectedPlan.selectedEnterprise ? undefined : (id === 0 ? (isCurrentlyOrgPlanEnterprise ? `1px solid ${plansCurrentPlanColor} !important` : `1px solid ${colors.primary.disable} !important`) : undefined)
                                                                }}
                                                            >
                                                                <Flex w={'100%'} ai={'center'} jc={'center'} talign={'center'}>
                                                                    <GetInner data={e.enterprise}/>
                                                                </Flex>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </S.SelectPlanContainer>
                        }
                    </>
                }

                {tab === 'billing' &&
                    <S.OrganizationBillingTabContainer>
                        <EmptyArrayImage
                            isShowing={!billing.isLoading && !billing.billing}
                            type={'billing'}
                            withoutAdd
                            text={'No billing data provided'}
                        />
                        {billing.isLoading && <LocalSpinner />}
                        {billing.billing &&
                            <S.OrganizationBillingInfoAndPaymentMethodContainer>
                                {/*BILLING INFO*/}
                                <S.BillingOrPaymentContainer>
                                    <Flex w={'100%'} ai={'center'} jc={'space-between'}>
                                        <Typography variant={'h4'}>{t('Billing information')}</Typography>
                                        <IconTextButton icon={<EditIcon/>} size={'small'} onClick={billing.handleOpenChangeBillingInfo}>{t('Edit information')}</IconTextButton>
                                    </Flex>

                                    <Flex ai={'center'} m={'24px 0 0 0'}>
                                        <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}>{t('First name')}</Typography>
                                        <Typography variant={'body1'} color={colors.grayText}>{billing.billing.billingInfo.firstName}</Typography>
                                    </Flex>

                                    <Flex ai={'center'} m={'12px 0 0 0'}>
                                        <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}>{t('Last name')}</Typography>
                                        <Typography variant={'body1'} color={colors.grayText}>{billing.billing.billingInfo.lastName}</Typography>
                                    </Flex>

                                    <Flex ai={'center'} m={'12px 0 0 0'}>
                                        <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}>{t('Email')}</Typography>
                                        <Typography variant={'body1'} color={colors.grayText}>{billing.billing.billingInfo.email}</Typography>
                                    </Flex>

                                    <Flex ai={'center'} m={'12px 0 0 0'}>
                                        <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}>{t('Address')}</Typography>
                                        <Typography variant={'body1'} color={colors.grayText}>{billing.billing.billingInfo.address}</Typography>
                                    </Flex>

                                    <Flex ai={'center'} m={'12px 0 0 0'}>
                                        <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}>{t('Zip code')}</Typography>
                                        <Typography variant={'body1'} color={colors.grayText}>{billing.billing.billingInfo.zip}</Typography>
                                    </Flex>

                                    <Flex ai={'center'} m={'12px 0 0 0'}>
                                        <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}>{t('Country')}</Typography>
                                        <Typography variant={'body1'} color={colors.grayText}>{billing.billing.billingInfo.country}</Typography>
                                    </Flex>

                                    {Object.entries(billing.billing.billingInfo.additionalData).map((entry, id) => {
                                        const fields = billing.currCountry?.fields ?? [];

                                        return(
                                            <Flex key={id} ai={'center'} m={'12px 0 0 0'}>
                                                <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}>{fields.find(e => e.fieldKey === entry[0])?.fieldLabel  ?? entry[0]}</Typography>
                                                <Typography variant={'body1'} color={colors.grayText}>{entry[1]}</Typography>
                                            </Flex>
                                        )
                                    })}
                                </S.BillingOrPaymentContainer>

                                <S.BillingOrPaymentContainer>
                                    <Flex w={'100%'} ai={'center'} jc={'space-between'}>
                                        <Typography variant={'h4'}>{t('Payment method')}</Typography>
                                        <IconTextButton icon={<EditIcon/>} size={'small'} disabled>{t('Edit method')}</IconTextButton>
                                    </Flex>

                                    <Flex
                                        w={'100%'}
                                        maxw={'300px'}
                                        direction={'column'}
                                        p={'16px'}
                                        br={'10px'}
                                        box_shadow={'0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13)'}
                                        m={'20px 0 0 0'}
                                    >
                                        <Flex ai={'center'}>
                                            <GetCardIcon cardType={billing.billing.paymentMethod?.cardType ?? ''}/>
                                            <Flex direction={'column'} m={!revDir ? '0 0 0 16px' : '0 16px 0 0'}>
                                                <Typography variant={'body1'} color={colors.grayText}>{billing.billing.paymentMethod?.cardMask}</Typography>
                                                <Typography variant={'body1'} sx={{margin: '8px 0 0 0'}} color={colors.text.grey}>{billing.billing.paymentMethod?.paymentMethodName} - {t('expires on')} {getCardExpiry(billing.billing.paymentMethod?.cardExpiration)}</Typography>
                                            </Flex>
                                        </Flex>
                                        <Flex ai={'center'} m={'10px 0 0 0'}>
                                            <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}>{t('Next billing on')}</Typography>
                                            <Typography variant={'body1'} color={colors.grayText}>{billing.nextPaymentOn}</Typography>
                                        </Flex>
                                    </Flex>
                                </S.BillingOrPaymentContainer>
                            </S.OrganizationBillingInfoAndPaymentMethodContainer>
                        }

                        {billing.billing &&
                            <>
                                <Typography variant={'h4'} sx={{margin: '32px 0 0 0'}}>{t('Upcoming payments')}</Typography>

                                {isTablet ?
                                    <>
                                        {billing.billing.futurePayments.slice(0, 2).map((row) => {
                                            return(
                                                <Flex
                                                    w={'100%'}
                                                    m={'16px 0 0 0'}
                                                    direction={'column'}
                                                    p={'16px'}
                                                    br={'10px'}
                                                    box_shadow={'0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13)'}
                                                >
                                                    <Typography variant={'body1'} color={colors.lightGrayText}>
                                                        {t('Description')}
                                                        <span style={{color: colors.grayText, margin: !revDir ? '0 0 0 8px' : '0 0 0 8px'}}>{row.items.map(e => e.name).join(', ')}</span>
                                                    </Typography>

                                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '24px 0 0 0'}}>
                                                        {t('Period')}
                                                        <span style={{color: colors.grayText, margin: !revDir ? '0 0 0 8px' : '0 8px 0 0'}}>
                                                            {parseDateAuto(row.periodStart)} /
                                                            {parseDateAuto(row.periodEnd)}
                                                        </span>
                                                    </Typography>

                                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '24px 0 0 0'}}>
                                                        {t('Amount')}
                                                        <span style={{color: colors.grayText, margin: !revDir ? '0 0 0 8px' : '0 8px 0 0'}}>
                                                            {calculateFullAmountByItems(row)}$
                                                        </span>
                                                    </Typography>

                                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '24px 0 0 0'}}>
                                                        {t('Date')}
                                                        <span style={{color: colors.grayText, margin: !revDir ? '0 0 0 8px' : '0 8px 0 0'}}>
                                                            {parseDateAuto(row.futurePaymentDate)}
                                                        </span>
                                                    </Typography>
                                                </Flex>
                                            )
                                        })}
                                    </>
                                    :
                                    <TableContainer sx={{'overflow': 'unset', margin: '16px 0 0 0'}}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>{t('Description')}</TableCell>
                                                    <TableCell>{t('Period')}</TableCell>
                                                    <TableCell>{t('Amount')}</TableCell>
                                                    <TableCell>{t('Date')}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {billing.billing.futurePayments.slice(0, 2).map((row, id) => (
                                                    <TableRow key={row.periodStart}>
                                                        <TableCell sx={{borderTop: !id ? `1px solid ${colors.primary.disable}`: undefined}}>
                                                            <Typography variant={'body2'} color={colors.grayText}>{row.items.map(e => e.name).join(', ')}</Typography>
                                                        </TableCell>

                                                        <TableCell sx={{borderTop: !id ? `1px solid ${colors.primary.disable}`: undefined}}>
                                                            <Typography variant={'body2'} color={colors.grayText}>
                                                                {parseDateAuto(row.periodStart)} /
                                                                <br/>
                                                                {parseDateAuto(row.periodEnd)}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell sx={{borderTop: !id ? `1px solid ${colors.primary.disable}`: undefined}}>
                                                            <Typography variant={'body2'} color={colors.grayText}>
                                                                {calculateFullAmountByItems(row)}$
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell sx={{borderTop: !id ? `1px solid ${colors.primary.disable}`: undefined}}>
                                                            <Typography variant={'body2'} color={colors.grayText}>
                                                                {parseDateAuto(row.futurePaymentDate)}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </>
                        }

                    </S.OrganizationBillingTabContainer>
                }

                {tab === "billingHistory" &&
                    <S.OrganizationBillingHistoryTabContainer>
                        <S.ExactOrganizationBillingHistoryStatusSelect>
                            <Autocomplete
                                options={[ALL_PAYMENT_HISTORY, SUCCESS_PAYMENT_HISTORY, FAILED_PAYMENT_HISTORY]}
                                getOptionLabel={(option) => t(option)}
                                onChange={(e, newValue) => billing.handleSetHistoryAutocomplete(newValue)}
                                renderInput={(props) => <TextField {...props} placeholder={t('Payment status')} size={'small'} fullWidth/>}
                                value={billing.searchHistoryAutocompleteValue}
                            />
                        </S.ExactOrganizationBillingHistoryStatusSelect>

                        {billing.isLoading && <LocalSpinner />}

                        <EmptyArrayImage
                            isShowing={!billing.isLoading && !billing.billing}
                            type={'billing'}
                            withoutAdd
                            text={t('No billing data provided')}
                        />

                        {billing.searchHistoryAutocompleteValue !== ALL_PAYMENT_HISTORY && !billing.histories.length && <Typography color={colors.darkBlueText} sx={{margin: '16px 0 0 0'}}>{t('Nothing was found for your query. Try changing the query.')}</Typography>}

                        {billing.histories.length > 0 &&
                            <>
                                {isTablet ?
                                    <>
                                        {billing.histories.map((row) => {
                                            return(
                                                <Flex
                                                    w={'100%'}
                                                    m={'16px 0'}
                                                    direction={'column'}
                                                    p={'16px'}
                                                    br={'10px'}
                                                    box_shadow={'0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13)'}
                                                >
                                                    <Flex ai={'center'}>
                                                        <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}>{t('Description')}</Typography>
                                                        <Typography variant={'body1'} color={colors.grayText}>{row.items.map(e => e.name).join(', ')}</Typography>
                                                    </Flex>

                                                    <Flex ai={'center'} m={'12px 0 0 0'}>
                                                        <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}>{t('Period')}</Typography>
                                                        <Typography variant={'body1'} color={colors.grayText}>
                                                            {parseDateAuto(row.periodStart)} /
                                                            {parseDateAuto(row.periodEnd)}
                                                        </Typography>
                                                    </Flex>

                                                    <Flex ai={'center'} m={'12px 0 0 0'}>
                                                        <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}>{t('Amount')}</Typography>
                                                        <Typography variant={'body1'} color={colors.grayText}>{calculateFullAmountByItems(row)}$</Typography>
                                                    </Flex>

                                                    <Flex ai={'center'} m={'12px 0 0 0'}>
                                                        <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}>{t('Date')}</Typography>
                                                        <Typography variant={'body1'} color={colors.grayText}>{parseDateAuto(row.futurePaymentDate)}</Typography>
                                                    </Flex>

                                                    <Flex ai={'center'} m={'12px 0 0 0'}>
                                                        <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}>{t('Status')}</Typography>
                                                        <GetHistoryPaymentStatus status={row.status}/>
                                                    </Flex>

                                                    <Flex jc={'space-between'} ai={'center'} m={'12px 0 0 0'}>
                                                        <Flex w={'45%'} direction={'column'} ai={'center'}>
                                                            <Typography variant={'body1'} color={colors.lightGrayText}>{t('Invoice')}</Typography>
                                                            <IconButton disabled onClick={() => billing.handleDownloadInvoice(row)}>
                                                                <DownloadIcon />
                                                            </IconButton>
                                                        </Flex>

                                                        <Divider orientation={'vertical'} sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable}}/>

                                                        <Flex w={'45%'} direction={'column'} ai={'center'}>
                                                            <Typography variant={'body1'} color={colors.lightGrayText}>{t('Receipt')}</Typography>
                                                            <IconButton disabled={!row.receiptFileId} onClick={() => billing.handleDownloadReceipt(row)}>
                                                                <DownloadIcon />
                                                            </IconButton>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                            )
                                        })}
                                    </>
                                    :
                                    <TableContainer sx={{'overflow': 'unset', margin: '16px 0 0 0'}}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>{t('Description')}</TableCell>
                                                    <TableCell>{t('Period')}</TableCell>
                                                    <TableCell>{t('Amount')}</TableCell>
                                                    <TableCell>{t('Date')}</TableCell>
                                                    <TableCell>{t('Status')}</TableCell>
                                                    <TableCell>{t('Invoice')}</TableCell>
                                                    <TableCell>{t('Receipt')}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {billing.histories.map((row, id) => (
                                                    <TableRow key={row.periodStart}>
                                                        <TableCell sx={{borderTop: !id ? `1px solid ${colors.primary.disable}`: undefined}}>
                                                            <Typography variant={'body2'} color={colors.grayText}>{row.items.map(e => e.name).join(', ')}</Typography>
                                                        </TableCell>

                                                        <TableCell sx={{borderTop: !id ? `1px solid ${colors.primary.disable}`: undefined}}>
                                                            <Typography variant={'body2'} color={colors.grayText}>
                                                                {parseDateAuto(row.periodStart)} /
                                                                {parseDateAuto(row.periodEnd)}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell sx={{borderTop: !id ? `1px solid ${colors.primary.disable}`: undefined}}>
                                                            <Typography variant={'body2'} color={colors.grayText}>
                                                                {calculateFullAmountByItems(row)}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell sx={{borderTop: !id ? `1px solid ${colors.primary.disable}`: undefined}}>
                                                            <Typography variant={'body2'} color={colors.grayText}>
                                                                {parseDateAuto(row.statusChangedDate)}
                                                            </Typography>
                                                        </TableCell>

                                                        {/*Status*/}
                                                        <TableCell sx={{borderTop: !id ? `1px solid ${colors.primary.disable}`: undefined}}>
                                                            <GetHistoryPaymentStatus status={row.status}/>
                                                        </TableCell>

                                                        <TableCell sx={{borderTop: !id ? `1px solid ${colors.primary.disable}`: undefined}}>
                                                            <IconButton disabled={!row.invoiceFileId} onClick={() => billing.handleDownloadInvoice(row)}>
                                                                <DownloadIcon />
                                                            </IconButton>
                                                        </TableCell>

                                                        <TableCell sx={{borderTop: !id ? `1px solid ${colors.primary.disable}`: undefined}}>
                                                            <IconButton disabled={!row.receiptFileId} onClick={() => billing.handleDownloadReceipt(row)}>
                                                                <DownloadIcon />
                                                            </IconButton>
                                                        </TableCell>


                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </>
                        }
                    </S.OrganizationBillingHistoryTabContainer>
                }
            </TabContext>

            {/*DIALOGS*/}
            <CreateWorkspace handleChangeTab={handleSetTab}/>
            <EditOrganization />
            <TransferOwnership />

            <UpdateBillingInfo
                isOpen={billing.editBillingInfoDialog.isOpen}
                handleClose={billing.editBillingInfoDialog.handleClose}
            />
        </Flex>
    )
}

const GetCardIcon = ({cardType}: { cardType: string }) => {
    switch (cardType){
        //51x34
        case 'MC': return <MastercardIcon style={{width: '51px', height: '34px'}}/>;
        case 'VISA': return <VisaIcon style={{width: '51px', height: '34px'}}/>;
        case 'Amex': return <AmericanExpressIcon style={{width: '51px', height: '34px'}} />;
        default: return <CreditCardIcon sx={{width: '51px', height: '34px'}} />;
    }
}


const GetHistoryPaymentStatus = ({status}: { status: string }) => {
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'pathSettings.OrganizationExact'});
    switch (status){
        case 'SUCCESS': return <Flex background={'#ECFDF3'} p={'8px'} br={'30px'}>
            <Flex ai={'center'} jc={'center'} background={'#89E779'} m={!revDir ? '0 12px 0 0' : '0 0 0 12px'} br={'50%'}>
                <CheckIcon fontSize={'small'} sx={{color: colors.text.white}}/>
            </Flex>

            <Typography variant={'subtitle1'} color={colors.grayText}>{t('Success')}</Typography>
        </Flex>;
        case 'FAILED': return <Flex background={'#FFE8E8'} p={'8px'} br={'30px'}>
            <Flex ai={'center'} jc={'center'} background={'#E16F6F'} m={!revDir ? '0 12px 0 0' : '0 0 0 12px'} br={'50%'}>
                <CloseIcon fontSize={'small'} sx={{color: colors.text.white}}/>
            </Flex>

            <Typography variant={'subtitle1'} color={colors.grayText}>{t('Failed')}</Typography>
        </Flex>;
        default: return <></>;
    }
}

export const UpdateBillingInfo = ({isOpen, handleClose}: {isOpen: boolean, handleClose: () => void}) => {
    const dispatch = useDispatch();
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'pathSettings.OrganizationExact'});
    const {countries} = useSelector(createOrgInitialData);
    const {billing} = useSelector(selectedOrganizationData);
    const isLoading = useSelector(organizationsLoadings).updateBillingInfo;
    const org = useSelector(selectedOrganization);

    const defaultForm = {
        period: YEAR_PERIOD,
        collaboratorsCount: 0,
        isVat: false,
        billingPrimaryInfo: {
            firstName: '',
            lastName: '',
            address: '',
            country: '',
            zip: '',
            email: '',
        },
        additionalBillingInfo: {}
    }

    const {form, setForm, handleChange} = useForm<TBillingInfo>(defaultForm);
    const isShowErrorInBillingEmail = (form.billingPrimaryInfo.email.trim().length > 0 && !validateEmail(form.billingPrimaryInfo.email));
    const additionalFields = countries.find(e => e.name === form.billingPrimaryInfo.country)?.fields ?? [];

    const handleSubmit = () => {
        if(org && isOk()){
            dispatch(UpdateOrganizationBillingInfo({
                organizationId: org.id,
                billing: form,
                onSuccess
            }))
        }
    }

    const onSuccess = () => {
        setForm(defaultForm);
        handleClose();
        //set message
    }

    useEffect(() => {
        if(isOpen && countries.length && billing && billing.currentTear){
            setForm({
                period: billing.currentTear.variant,
                collaboratorsCount: billing.currentTear.items.find(e => e.type === 'COLLABORATOR')?.quantity ?? 1,
                isVat: billing.currentTear.vatPercentage > 0,
                billingPrimaryInfo: {
                    firstName: billing.billingInfo.firstName,
                    lastName: billing.billingInfo.lastName,
                    address: billing.billingInfo.address,
                    zip: billing.billingInfo.zip,
                    country: billing.billingInfo.country,
                    email: billing.billingInfo.email,
                },
                additionalBillingInfo: billing.billingInfo.additionalData
            })
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const handleSetBillingCountry = (country: string) => {
        setForm({
            ...form,
            isVat: country === ISRAEL_COUNTRY_NAME,
            billingPrimaryInfo: {...form.billingPrimaryInfo, country},
            additionalBillingInfo: {}
        });
    }

    const isOk = (): boolean => {
        if(additionalFields.length > 0){
            for(let i=0; i < additionalFields.length; i++){
                if(!(form.additionalBillingInfo[additionalFields[i].fieldKey] ?? '').trim().length){
                    return false;
                }
            }
        }
        return form.collaboratorsCount > 0 &&
            form.collaboratorsCount <= 5 &&
            form.billingPrimaryInfo.country !== null &&
            form.billingPrimaryInfo.zip.trim().length > 0 &&
            form.billingPrimaryInfo.address.trim().length > 0 &&
            validateEmail(form.billingPrimaryInfo.email) &&
            form.billingPrimaryInfo.lastName.trim().length > 0 &&
            form.billingPrimaryInfo.firstName.trim().length > 0
    }

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Billing information')}</DialogTitle>

            <DialogContent>
                <Flex w={'100%'} direction={'column'} m={'24px 0 0 0'}>
                    <Flex w={'100%'} ai={'center'} gap={'14px'}>
                        <TextField
                            variant={'outlined'}
                            label={t('First name')}
                            required
                            value={form.billingPrimaryInfo.firstName}
                            onChange={handleChange}
                            id={'.billingPrimaryInfo'}
                            name={'firstName'}
                            size={'small'}
                        />

                        <TextField
                            variant={'outlined'}
                            label={t('Last name')}
                            required
                            value={form.billingPrimaryInfo.lastName}
                            onChange={handleChange}
                            id={'.billingPrimaryInfo'}
                            name={'lastName'}
                            size={'small'}
                        />
                    </Flex>

                    <TextField
                        variant={'outlined'}
                        label={t('Address')}
                        required
                        value={form.billingPrimaryInfo.address}
                        onChange={handleChange}
                        name={'address'}
                        id={'.billingPrimaryInfo'}
                        size={'small'}
                        sx={{margin: '14px 0 0 0'}}
                    />

                    <TextField
                        variant={'outlined'}
                        label={t('Billing email')}
                        required
                        value={form.billingPrimaryInfo.email}
                        onChange={handleChange}
                        name={'email'}
                        id={'.billingPrimaryInfo'}
                        size={'small'}
                        sx={{margin: '14px 0 0 0'}}
                        error={isShowErrorInBillingEmail}
                        helperText={isShowErrorInBillingEmail ? t('Email is not valid') : undefined}
                    />

                    <Flex w={'100%'} ai={'center'} gap={'14px'} m={'14px 0 0 0'}>
                        <Autocomplete
                            sx={{width: '50%'}}
                            options={(countries).map(e => e.name).slice().sort()}
                            renderInput={(params) => <TextField
                                {...params}
                                required
                                placeholder={t('Country')}
                                size={'small'}
                            />}
                            value={form.billingPrimaryInfo.country}
                            onChange={(e, value) => value && handleSetBillingCountry(value)}
                        />

                        <TextField
                            variant={'outlined'}
                            label={t('Postal / Zip code')}
                            required
                            value={form.billingPrimaryInfo.zip}
                            onChange={handleChange}
                            name={'zip'}
                            id={'.billingPrimaryInfo'}
                            sx={{width: '50%'}}
                            size={'small'}
                        />
                    </Flex>

                    {
                        additionalFields.map((f) => {
                            return(
                                <TextField
                                    key={'add field ' + f.fieldKey}
                                    required
                                    variant={'outlined'}
                                    label={(f.fieldLabel)}
                                    value={form.additionalBillingInfo[f.fieldKey] ?? ''}
                                    onChange={handleChange}
                                    name={f.fieldKey}
                                    placeholder={f.fieldPlaceholder}
                                    id={'.additionalBillingInfo'}
                                    size={'small'}
                                    sx={{margin: '14px 0 0 0'}}
                                />
                            )
                        })
                    }

                    {form.billingPrimaryInfo.country === ISRAEL_COUNTRY_NAME && billing && billing.billingInfo.country !== ISRAEL_COUNTRY_NAME &&
                        <Typography variant={'body1'} sx={{margin: '16px 0 0 0', lineHeight: 'normal'}}>{ISRAEL_VAT_PERCENTAGE}% {t('VAT will be charged')}</Typography>
                    }
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                    disabled={!isOk()}
                >
                    {t('Continue')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}