import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {deselectRequest, kycDictionaries, loadings, selectedRequest} from "../../../store/slice";
import {useEffect} from "react";
import {KYC_REQUESTS_LIST_PATH, KYC_ROOT_PATH} from "../../../constants";
import {useParams} from "react-router";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {DownloadFile, GetKycRequestById} from "../../../store/actions";
import {addErrorSnack} from "../../../../../barsEnvironment/snack/store/slice";

export const useRequestExact = () => {
    const {tMenu} = useMainTranslation();

    const dispatch = useDispatch();
    const history = useHistory();
    const isLoading = useSelector(loadings).getRequestExact;
    const dicts = useSelector(kycDictionaries);

    const {id} = useParams<{id: string}>();
    const selected = useSelector(selectedRequest);

    //useEffects
    useEffect(() => {
        if(!selected && id){
            dispatch(GetKycRequestById({requestId: id, onReject: handleGoBack}))
        }

        if(selected && selected.status !== 'COMPLETED'){
            handleGoBack();
            dispatch(addErrorSnack(`Request ${id} is not completed!`));
        }

        return () => {
            dispatch(deselectRequest());
        }
        //eslint-disable-next-line
    }, []);

    useSetBreadcrumbs([
        {
            title: tMenu('KYC Management'),
            path: KYC_ROOT_PATH
        },
        {
            title: tMenu('Requests'),
            path: KYC_REQUESTS_LIST_PATH
        },
        {
            title: selected ? `Request ${selected.id}` : tMenu('Loading...'),
            path: KYC_REQUESTS_LIST_PATH
        }
    ]);

    //actions
    const handleGoBack = () => history.push(KYC_REQUESTS_LIST_PATH);

    const handleDownloadFile = (fileId: string, name: string) => {
        dispatch(DownloadFile({fileId, name}));
    }
    return{
        request: selected,
        isLoading,
        actions: {
            handleGoBack,
            handleDownloadFile
        },
        dicts
    }
}