import {TDutyRule, TShortDuty} from "../../../types";
import {useDispatch} from "react-redux";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {useSegregationOfDuties} from "../../useSegregationOfDuties";
import {updateDutyRuleAction} from "../../../store/actions";
import {useEffect} from "react";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const useEditRuleDialog = () => {
    const dispatch = useDispatch();
    const {setMessage} = useMessageDialog();
    const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        shortDuties,
        handleHideEditRule: handleClose,
        loadings: {update: isLoadingUpdate, dataForDialog: isLoadingData},
        dialogs: {editDutyRule: {isOpen, selectedDutyRule: selected}},
    } = useSegregationOfDuties();


    //form
    const {form, setForm, handleChange} = useForm<TDutyRule | null>(selected);

    const handleSetSeverity = (severity: string) => {
        setForm(prev => prev && ({...prev, severityLevel: severity}));
    }

    const toggleActive = (active: boolean) => {
        setForm(prev => prev && ({...prev, active}))
    }

    const handleSetFirstDuty = (duty: TShortDuty) => {
        setForm(prev => prev && {...prev, firstDuty: duty});
    }

    const handleSetSecondDuty = (duty: TShortDuty) => {
        setForm(prev => prev && {...prev, secondDuty: duty});
    }

    const onSuccess = () => {
        setMessage({title: t('Completed successfully'), message: t('The rule {{name}} has been changed.', {name: form?.name || ''})});
    }
    
    const handleSubmit = () => {
        if(isOk() && form){
            dispatch(updateDutyRuleAction({data: {rule: form, organizationId: '', workspaceId: ''}, onSuccess: onSuccess}));
        }
    }

    const isOk = () => {
        return form && form.name.trim().length > 0 && form.firstDuty.id.length > 0 && form.secondDuty.id.trim().length > 0 && form.severityLevel.length > 0
            &&  JSON.stringify(selected) !== JSON.stringify(form);
    }

    useEffect(() => {
        if(isOpen && selected){
            setForm(selected);
        }
        //eslint-disable-next-line
    }, [isOpen]);

    return{
        isOpen,
        isLoadingUpdate,
        isLoadingData,

        shortDuties,
        handleClose,
        handleSubmit,
        handleSetSeverity,
        toggleActive,
        handleSetFirstDuty,
        handleSetSecondDuty,

        handleChange,
        form,
        setForm,
        isOk: isOk(),
    }
}