import {useDispatch, useSelector} from "react-redux";
import {assetsDialogsSelector, setRequestAssetErrorAction} from "../../../store/store";


export const useRequestAssetError = () => {
    const dispatch = useDispatch();
    const {requestAssetError: {message, title}} = useSelector(assetsDialogsSelector);
    const handleClose = () => {
        dispatch(setRequestAssetErrorAction({clear: true}));
    }

    return {
        isOpen: !!message.length,
        title,
        message,
        handleClose,
    }
}
