import {AxiosResponse} from "axios";
import {print} from "graphql";
import axiosSync from "../../../../newShared/utils/axios/axios";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {PATH_SERVER} from "../../../../newShared/constants";

import {
    kycAnalyseRestart,
    kycCancelRequestById,
    kycChangeRequestStatus,
    kycCreateTemplate,
    kycDeleteTemplate,
    kycDownloadRequestById,
    kycGenerateTokenByTemplateId,
    kycGetFormByToken,
    kycGetQuotes,
    kycGetRequestById,
    kycGetRequests,
    kycGetTemplateById,
    kycGetTemplateFields,
    kycGetTemplates,
    kycGetTokenByTemplateId,
    kycRejectFormByToken,
    kycSendRequestsToEmails,
    kycSetLimit,
    kycSubmitFormByToken,
    kycUpdateTemplate,
} from "./query";
import {
    KycAnalyseRestartMutation,
    KycAnalyseRestartMutationVariables,
    KycCancelRequestByIdMutation,
    KycCancelRequestByIdMutationVariables,
    KycChangeRequestStatusMutation,
    KycChangeRequestStatusMutationVariables,
    KycCreateTemplateMutation,
    KycCreateTemplateMutationVariables,
    KycDeleteTemplateMutation,
    KycDeleteTemplateMutationVariables,
    KycDownloadRequestByIdQuery,
    KycDownloadRequestByIdQueryVariables,
    KycGenerateTokenByTemplateIdMutation,
    KycGenerateTokenByTemplateIdMutationVariables,
    KycGetFormByTokenQuery,
    KycGetFormByTokenQueryVariables,
    KycGetQuotesQuery,
    KycGetQuotesQueryVariables,
    KycGetRequestByIdQuery,
    KycGetRequestByIdQueryVariables,
    KycGetRequestsQuery,
    KycGetRequestsQueryVariables,
    KycGetTemplateByIdQuery,
    KycGetTemplateByIdQueryVariables,
    KycGetTemplateFieldsQuery,
    KycGetTemplateFieldsQueryVariables,
    KycGetTemplatesQuery,
    KycGetTemplatesQueryVariables,
    KycGetTokenByTemplateIdQuery,
    KycGetTokenByTemplateIdQueryVariables,
    KycRejectFormByTokenMutation,
    KycRejectFormByTokenMutationVariables,
    KycSendRequestsToEmailsMutation,
    KycSendRequestsToEmailsMutationVariables,
    KycSetLimitMutation,
    KycSetLimitMutationVariables,
    KycSubmitFormByTokenMutation,
    KycSubmitFormByTokenMutationVariables,
    KycUpdateTemplateMutation,
    KycUpdateTemplateMutationVariables,
} from "../../../../newShared/GQLTypes";


export const kycGetTemplatesApi = async (data: KycGetTemplatesQueryVariables, signal?: AbortSignal): Promise<KycGetTemplatesQuery["kycGetTemplates"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(kycGetTemplates),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.kycGetTemplates
    })
)

export const kycGetTemplateByIdApi = async (data: KycGetTemplateByIdQueryVariables, signal?: AbortSignal): Promise<KycGetTemplateByIdQuery["kycGetTemplateById"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(kycGetTemplateById),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.kycGetTemplateById
    })
)

export const kycGetTokenByTemplateIdApi = async (data: KycGetTokenByTemplateIdQueryVariables, signal?: AbortSignal): Promise<KycGetTokenByTemplateIdQuery["kycGetTokenByTemplateId"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(kycGetTokenByTemplateId),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.kycGetTokenByTemplateId
    })
)

export const kycGenerateTokenByTemplateIdApi = async (data: KycGenerateTokenByTemplateIdMutationVariables, signal?: AbortSignal): Promise<KycGenerateTokenByTemplateIdMutation["kycGenerateTokenByTemplateId"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(kycGenerateTokenByTemplateId),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.kycGenerateTokenByTemplateId
    })
)

export const kycUpdateTemplateApi = async (data: KycUpdateTemplateMutationVariables, signal?: AbortSignal): Promise<KycUpdateTemplateMutation["kycUpdateTemplate"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(kycUpdateTemplate),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.kycUpdateTemplate
    })
)

export const kycCreateTemplateApi = async (data: KycCreateTemplateMutationVariables, signal?: AbortSignal): Promise<KycCreateTemplateMutation["kycCreateTemplate"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(kycCreateTemplate),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.kycCreateTemplate
    })
)

export const kycDeleteTemplateApi = async (data: KycDeleteTemplateMutationVariables, signal?: AbortSignal): Promise<KycDeleteTemplateMutation["kycDeleteTemplate"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(kycDeleteTemplate),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.kycDeleteTemplate
    })
)

export const kycGetTemplateFieldsApi = async (data: KycGetTemplateFieldsQueryVariables, signal?: AbortSignal): Promise<KycGetTemplateFieldsQuery["kycGetTemplateFields"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(kycGetTemplateFields),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.kycGetTemplateFields
    })
)

export const kycSendRequestsToEmailsApi = async (data: KycSendRequestsToEmailsMutationVariables, signal?: AbortSignal): Promise<KycSendRequestsToEmailsMutation["kycSendRequestsToEmails"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(kycSendRequestsToEmails),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.kycSendRequestsToEmails
    })
)

export const kycGetRequestsApi = async (data: KycGetRequestsQueryVariables, signal?: AbortSignal): Promise<KycGetRequestsQuery["kycGetRequests"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(kycGetRequests),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.kycGetRequests
    })
)

export const kycGetRequestByIdApi = async (data: KycGetRequestByIdQueryVariables, signal?: AbortSignal): Promise<KycGetRequestByIdQuery["kycGetRequestById"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(kycGetRequestById),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.kycGetRequestById
    })
)

export const kycChangeRequestStatusApi = async (data: KycChangeRequestStatusMutationVariables, signal?: AbortSignal): Promise<KycChangeRequestStatusMutation["kycChangeRequestStatus"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(kycChangeRequestStatus),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.kycChangeRequestStatus
    })
)

export const kycCancelRequestByIdApi = async (data: KycCancelRequestByIdMutationVariables, signal?: AbortSignal): Promise<KycCancelRequestByIdMutation["kycCancelRequestById"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(kycCancelRequestById),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.kycCancelRequestById
    })
)

export const kycAnalyseRestartApi = async (data: KycAnalyseRestartMutationVariables, signal?: AbortSignal): Promise<KycAnalyseRestartMutation["kycAnalyseRestart"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(kycAnalyseRestart),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.kycAnalyseRestart
    })
)

export const kycDownloadRequestByIdApi = async (data: KycDownloadRequestByIdQueryVariables, signal?: AbortSignal): Promise<KycDownloadRequestByIdQuery["kycDownloadRequestById"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(kycDownloadRequestById),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.kycDownloadRequestById
    })
)

export const kycGetQuotesApi = async (data: KycGetQuotesQueryVariables, signal?: AbortSignal): Promise<KycGetQuotesQuery["kycGetQuotes"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(kycGetQuotes),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.kycGetQuotes
    })
)

export const kycSetLimitApi = async (data: KycSetLimitMutationVariables, signal?: AbortSignal): Promise<KycSetLimitMutation["kycSetLimit"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(kycSetLimit),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.kycSetLimit
    })
)

export const kycGetFormByTokenApi = async (data: KycGetFormByTokenQueryVariables, signal?: AbortSignal): Promise<KycGetFormByTokenQuery["kycGetFormByToken"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(kycGetFormByToken),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.kycGetFormByToken
    })
)

export const kycSubmitFormByTokenApi = async (data: KycSubmitFormByTokenMutationVariables, signal?: AbortSignal): Promise<KycSubmitFormByTokenMutation["kycSubmitFormByToken"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(kycSubmitFormByToken),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.kycSubmitFormByToken
    })
)

export const kycRejectFormByTokenApi = async (data: KycRejectFormByTokenMutationVariables, signal?: AbortSignal): Promise<KycRejectFormByTokenMutation["kycRejectFormByToken"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(kycRejectFormByToken),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.kycRejectFormByToken
    })
)

