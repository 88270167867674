import * as S from './styled';
import {TKycTemplateScreeningSettingsCheck} from "../../types";
import {Stack, Typography} from "@mui/material";
import React from "react";
import {Flex} from '../../../../../newShared/components/Layouts';
import CommonSwitch from "../../../../../newShared/components/Basic/CommonSwitch";
import colors from "../../../../../newShared/theme/colors";

type TemplatesChecksTableProps = {
    // checks: SystemCheckModel[];
    formChecks: TKycTemplateScreeningSettingsCheck[];
    onSwitchToggle: (check: TKycTemplateScreeningSettingsCheck) => void,
    onRatioChange: (check: TKycTemplateScreeningSettingsCheck, ratio: number | number[]) => void,
    disabled?: boolean,
    disabledMatchingRatio?: boolean
}
export const TemplatesChecksMobileList = ({onRatioChange, onSwitchToggle, formChecks, disabled, disabledMatchingRatio}: TemplatesChecksTableProps) => {
    return(
        <S.ChecksTableContainer>
            {formChecks.map(e => {
                return(
                    <>
                        <Flex w={'100%'} direction={'column'} p={'8px'} gap={'8px'}>
                            <Typography variant={'body2'} color={disabled ? colors.text.grey_dark : colors.grayText}>{(e.name)}</Typography>

                            <Flex ai={'center'} gap={'8px'}>
                                <Typography variant={'body2'} color={disabled ? colors.text.grey_dark : colors.grayText}>{('Apply')}:</Typography>
                                <CommonSwitch
                                    disabled={disabled}
                                    size={'small'}
                                    checked={e?.apply ?? false}
                                    onChange={(event, value) => onSwitchToggle({...e!, apply: value})}
                                />
                            </Flex>

                            <Typography variant={'body2'} color={disabled ? colors.text.grey_dark : colors.grayText}>{('Matching ratio')}:</Typography>

                            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                <Typography color={disabled ? colors.text.grey_dark : colors.grayText} variant={'body2'}>{('0%')}</Typography>
                                <S.CustomSlider
                                    disabled={!e.apply || disabled || disabledMatchingRatio}
                                    size={'small'}
                                    min={0}
                                    max={100}
                                    value={e.matchingRatio}
                                    valueLabelDisplay={(e.apply) ? 'on' : undefined}
                                    onChange={(event, value) => onRatioChange(e!, value)}
                                    valueLabelFormat={(a) => `${a}%`}
                                />
                                <Typography color={disabled ? colors.text.grey_dark : colors.grayText} variant={'body2'}>{('100%')}</Typography>
                            </Stack>
                        </Flex>
                        <S.ContainerDivider/>
                    </>
                )
            })}
        </S.ChecksTableContainer>
    )
}