import {useHistory, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    assetsDialogsSelector,
    certificateAssetsSettingsSelector,
    deselectCertificateAsset,
    loadings,
    selectedCertificate,
    setCreateSettingRowDialogAction,
    setDeleteAssetDialogAction
} from "../../store/store";
import {PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATES, PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATES_ID} from "../../constants";
import {
    GetCertificateAssetById,
    getCertificateAssetsSettingsAction,
    updateCertificateAssetAction
} from "../../store/actions";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_ASSET_MANAGEMENT} from "../../../../../newShared/constants";
import {TCertificateAsset, TExactAssetTabs, TSetCreateSettingRowDialog, TSetDeleteAssetDialog,} from "../../types";
import {useForm} from "../../../../../newShared/hooks/useForm";
import {toBase64} from "../../../../../newShared/utils/base64/base64";


export const useCertificateAssetExact = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const dispatch = useDispatch();
    const history = useHistory();
    const id = useParams<{id: string}>().id;
    const _selected = useSelector(selectedCertificate);
    const settings = useSelector(certificateAssetsSettingsSelector);
    const {isLoadingExact, isLoadingSettings} = useSelector(loadings);
    const {deleteAsset: {response: responseDelete}} = useSelector(assetsDialogsSelector);


    const handleGoBack = () => {
        history.push(PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATES);
    }

    const controller = new AbortController();

    const [editInfoMode, setEditInfoMode] = useState<boolean>(false);
    const [oldId, setOldId] = useState<string>('');

    const [file, setFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState<string>('');


    //form
    const {form, setForm, handleChange} = useForm<TCertificateAsset | null>(null);


    useEffect(() => {
        if(!_selected){
            dispatch(GetCertificateAssetById({data: {id, onError: handleGoBack}, signal: controller.signal}));
        }
        if(_selected) setForm(_selected);

        if(!settings) {
            dispatch(getCertificateAssetsSettingsAction({data: {organizationId: '', workspaceId: ''}, signal: controller.signal}))
        }

        setFile(null);
        setFileName('');

        return () => {
            dispatch(deselectCertificateAsset());
            setForm(null);
            setOldId('');
            setEditInfoMode(false);
            controller.abort();
            setFile(null);
            setFileName('');
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(settings && settings.customFields){
            const customFields: { [key: string]: string } = {};
            Object.keys(settings.customFields).length && Object.keys(settings.customFields).forEach((key) => form?.customFields?.[key] ? form.customFields?.[key] : customFields[key] = '');
            setForm(prev => prev && ({...prev, customFields}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    useEffect(() => {
        if (_selected && _selected?.id !== id) history.push(`${PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATES_ID.replace(':id', _selected?.id)}`);

        //eslint-disable-next-line
    }, [_selected?.id]);

    useSetBreadcrumbs([
        {
            title: tMenu('Asset Management'),
            path: PATH_LOCAL_ASSET_MANAGEMENT
        },
        {
            title: 'Certificates',
            path: PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATES
        },
        {
            title: _selected ? _selected.name : 'Loading...',
            path: _selected ? PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATES_ID.replace(':id', _selected.id) : undefined
        },
    ]);



    const handleDeleteFile = () => {
        setFileName('');
        setFile(null);
    }

    const ref = React.createRef<HTMLInputElement>();

    const filePicker = () => {
        ref.current && ref.current.click();
    };

    const fileHandler = (files: FileList | null) => {
        if(files && files?.length > 0){
            setFile(files[0]);
            setFileName(files[0].name)
        }
    };

    //MENU
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const isOk = () => {
        return (form && form.name.trim().length && form.id.length)
    }

    //tabs
    const [tab, setTab] = useState<TExactAssetTabs>('information');

    //actions
    const handleSaveInfo = async() => {
        if (file) {
            const fileBase64: string = await toBase64(file) as string;
            form && dispatch(updateCertificateAssetAction({workspaceId: '', organizationId: '', asset: form, assetId: oldId, file: fileBase64, fileName}));
            setEditInfoMode(false);
        } else {
            form && dispatch(updateCertificateAssetAction({workspaceId: '', organizationId: '', asset: form, assetId: oldId}));
            setEditInfoMode(false);
        }
    }

    const handleCancelEditInfo = () => {
        _selected && setForm(null);
        setEditInfoMode(false)
    }

    const handleEditInfoBtn = () => {
        setEditInfoMode(true);
        if (_selected) {
            setOldId(_selected.id);
            setForm({..._selected, customFields: _selected.customFields ? _selected.customFields : {}});
        }
    }

    //Dialogs
    const setDeleteAssetDialog = (data: TSetDeleteAssetDialog) => {dispatch(setDeleteAssetDialogAction(data))};

    // e:React.ChangeEvent<HTMLInputElement>
    const handleSetRenewal = (title: string) => {
        setForm(prev => prev && ({...prev, renewal: title}));
    }
    const openCreateSettingRowDialog = (data: TSetCreateSettingRowDialog) => {dispatch(setCreateSettingRowDialogAction(data))};


    const setCreateSettingRowDialog = (data: TSetCreateSettingRowDialog) => {dispatch(setCreateSettingRowDialogAction(data))};

    const handleChangeCustomField = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setForm((prev) => prev && ({
            ...prev,
            customFields: {
                ...prev.customFields,
                [event.target.name]: event.target.value,
            },
        }));
    };

    return {
        isOk,
        asset: _selected,
        settings,
        menu: {
            anchorEl,
            handleClose,
            handleClick,
            open,
        },
        tab,
        setTab,
        handleGoBack,

        isLoadingExact,
        isLoadingSettings,

        editInfoMode,
        setEditInfoMode,
        form,
        file,
        fileName,
        handleDeleteFile,
        ref,
        filePicker,
        fileHandler,
        handleChange,
        handleSetRenewal,
        openCreateSettingRowDialog,
        oldId,
        setOldId,
        handleSaveInfo,
        handleCancelEditInfo,
        handleEditInfoBtn,
        setCreateSettingRowDialog,
        handleChangeCustomField,
        deleteAssetDialog: {setDeleteAssetDialog, responseDelete},

    }

}
