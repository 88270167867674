import React, {FC} from 'react';
import {LeftSecondMenu} from "../../barsEnvironment/leftMenu/components/leftSecondMenu";
import {Routes} from "./routes";
import {useKycMain} from "./hooks/useKycMain";
import {Spinner} from "../../../newShared/components/Spinner";


export const KycRefactor:FC = () => {
    const isLoading = useKycMain();
    return (
        <LeftSecondMenu>
            {isLoading ? <Spinner /> : <Routes />}
        </LeftSecondMenu>
    )
}
