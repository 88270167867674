import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import colors from "../../../../../../newShared/theme/colors";
import {LoadingButton} from "@mui/lab";
import React from "react";
import {useDeleteRisk} from "../../../hooks/dialogsHooks/useDeleteRisk";

export const DeleteRisk = () => {
    const {
        isOpen,
        handleClose,
        isLoading,
        handleSubmit,
        risk
    } = useDeleteRisk();

    const {revDir, t} = useMainTranslation('', {keyPrefix: 'risks'});
    const {isMobile} = useMedia();

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Delete item')}</DialogTitle>

            <DialogContent>
                <Typography variant={'body2'} color={colors.darkBlueText}>
                    {t('If you delete information about a risk {{name}}, you will not be able to restore it.', {name: risk?.name ?? ''})}
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{t('Cancel')}</Button>
                {isLoading ?
                    <LoadingButton
                        loading
                        sx={{paddingLeft: '35px', margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                        variant="outlined"
                    >{t('Delete')}</LoadingButton>
                    :
                    <Button
                        variant={'contained'}
                        size={isMobile ? 'small' : 'medium'}
                        onClick={handleSubmit}
                        sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    >{t('Delete')}</Button>
                }
            </DialogActions>
        </Dialog>
    )
}