import {FC} from "react";
import {Box, Card, Skeleton, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";

export const FrameworkSkeletonCard: FC = () => {

    return (
        <Card sx={{
            padding: '8px', height: '100%',
            display: 'flex', flexDirection: 'column',
        }}>
            <Box display={"flex"} alignItems={"center"}>
                <Skeleton variant={"circular"} width={40} height={40} />
                <Typography variant={"h4"} margin={'0 4px'} width={'100px'}><Skeleton/></Typography>
            </Box>

            <Typography variant={"body2"} fontSize={'12px'} color={colors.text.grey_dark} mt={'8px'}><Skeleton/></Typography>
            <Typography variant={"body2"} fontSize={'12px'} color={colors.text.grey_dark} mt={'8px'}><Skeleton/></Typography>
            <Typography variant={"body2"} fontSize={'12px'} color={colors.text.grey_dark} mt={'8px'}><Skeleton/></Typography>

            <Box width={'100%'} flexGrow={1}/>

            <Typography variant={"body1"} color={colors.text.grey_dark} mt={'8px'}>
                <Skeleton/>
            </Typography>
            <Typography variant={"body1"} color={colors.text.grey_dark} mt={'8px'}>
                <Skeleton/>
            </Typography>
            <Typography variant={"body1"} color={colors.text.grey_dark} mt={'8px'}>
                <Skeleton/>
            </Typography>
            <Typography variant={"body1"} color={colors.text.grey_dark} mt={'8px'} mb={'16px'}>
                <Skeleton/>
            </Typography>

            <Box display={"flex"} justifyContent={"flex-end"}>
                <Skeleton variant="rounded" width={100} height={40} />
            </Box>
        </Card>
    )
}