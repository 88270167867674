import {useDispatch, useSelector} from "react-redux";
import {
    loadings,
    selectedTemplate,
    selectedTemplateCsvHistory,
    selectedTemplateCsvHistoryPageInfo
} from "../../../store/slice";
import {TEMPLATE_CSV_PAGING} from "../../../constants";
import {useScrollPagination} from "../../../../../../newShared/hooks/useScrollPagination";
import {GetKycTemplateHistory} from "../../../store/actions";

export const useTemplateCsvTable = () => {
    //root
    const dispatch = useDispatch();
    //selectors
    const histories = useSelector(selectedTemplateCsvHistory);
    const {getTemplateHistory} = useSelector(loadings);
    const pageInfo = useSelector(selectedTemplateCsvHistoryPageInfo);
    const selected = useSelector(selectedTemplate);

    //scroll
    const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => {
            selected && dispatch(GetKycTemplateHistory({data: {pageInfo: {page, count: TEMPLATE_CSV_PAGING}, templateId: selected.id}}));
        },
        arrayLength: histories.length,
        isLoading: getTemplateHistory,
        pageInfo: pageInfo,
        // cleanData: () => dispatch(eraseCategories()),
        // controller: controller,
        // isOnTop: true,
        isNoMoreThanTotal: true,
        customDependencies: [],
        is0PageAddictive: false,
        disableInitFetch: true,
    })

    return{
        histories,
        isLoading: getTemplateHistory,
        isNoHistoriesFound: !getTemplateHistory && !pageInfo.total,

        scroll: {
            isLoading: getTemplateHistory,
            bottomLoader,
            onScroll,
            scrollRef,
            hasMore: pageInfo.total > ((pageInfo.page + 1) * pageInfo.count),
        }
    }
}
