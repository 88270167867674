import {createRoleDialogPropsTypes} from "../../../types";
import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideCreateRole} from "../../../store/slice";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {getPermissionArray} from "../../../helpers";

export const useAddRoleDialog = (props: createRoleDialogPropsTypes) => {
    const dispatch = useDispatch();
    const isOpen = useSelector(dialogs).createRole;

    const handleClose = () => {
        dispatch(hideCreateRole());
        cleanUp();
    }

    //form
    const {form, setForm, handleChange} = useForm<{roleName: string, rolePermissions: string}>({roleName: '', rolePermissions: ''});
    const cleanUp = () => setForm({roleName: '', rolePermissions: ''});

    const handleSubmit = () => {
        if(isOk()){
            props.handleAddRole(form.roleName, getPermissionArray(form.rolePermissions));
            handleClose();
        }
    }

    const isAlreadyExists = props.addedRoles.some(e => e.roleName === form.roleName);

    const isOk = () => {
        return form.roleName.trim().length > 0 && !isAlreadyExists;
    }

    return{
        isOpen,
        handleClose,
        handleSubmit,
        handleChange,
        form,
        setForm,
        isOk: isOk(),
        isAlreadyExists,
    }
}