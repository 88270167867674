import {styled} from "@mui/material";
import {FlexRow} from "../../editorTitleWithActionsRow/styled";

interface EditorItemRowStyledProps {
    isChildOfTable?: boolean;
}
export const EditorItemRowStyled = styled(FlexRow, {shouldForwardProp: (propName) => (
    !['isChildOfTable'].includes(propName.toString())
)})<EditorItemRowStyledProps>(({isChildOfTable}) => ({
    alignItems: 'stretch',
    gap: '4px',

    height: isChildOfTable ? '100%' : undefined,
}));