import {FC, useEffect} from "react";
import {Menu, MenuItem} from "@mui/material";
import {Heading1, Heading2, Heading3, Heading4, Heading5, NormalText, StylesButton} from "./styled";
import {useStylesControl} from "./hooks";
import {TFontStyle, TToolBarHandlerAction, TToolBarHandlerPayload} from "../../types";
import {withShrinkZero} from "../../../hoc/withShrinkZero";
import {EditorTooltip} from "../../../commonComponents/editorTooltip";


export interface StyleControlProps {
    initialStyle: TFontStyle | null; //'Normal text' | 'Heading 1' | 'Heading 2' | 'Heading 3' | 'Heading 4' | 'Heading 5',
    changeStyleHandler: (action: TToolBarHandlerAction, payload: TToolBarHandlerPayload) => void;
    isDisabled: boolean;
}

const StyleControl: FC<StyleControlProps> = ({initialStyle, changeStyleHandler, isDisabled}) => {
    const {open, anchorEl, handleClick, handleClose} = useStylesControl({
        initialStyle,
        anchorEl: null,
        changeStyleHandler
    });

    const isMac = navigator.userAgent.toUpperCase().indexOf('MAC') >= 0;

    function shortCutHandler (e: KeyboardEvent) {
        if (isDisabled || !(isMac ? e.metaKey : e.ctrlKey) || !e.altKey) return;

        switch (e.code) {
            case "Digit0": {
                e.preventDefault();
                handleClose('Normal text')(e);
                break;
            }
            case 'Digit1': {
                e.preventDefault();
                handleClose(`Heading 1`)(e);
                break;
            }
            case 'Digit2': {
                e.preventDefault();
                handleClose(`Heading 2`)(e);
                break;
            }
            case 'Digit3': {
                e.preventDefault();
                handleClose(`Heading 3`)(e);
                break;
            }
            case 'Digit4': {
                e.preventDefault();
                handleClose(`Heading 4`)(e);
                break;
            }
            case 'Digit5': {
                e.preventDefault();
                handleClose(`Heading 5`)(e);
                break;
            }
        }
    }

    useEffect(() => {
        !isDisabled && document.addEventListener("keydown", shortCutHandler);
        return () => {
            document.removeEventListener("keydown", shortCutHandler);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDisabled, changeStyleHandler, initialStyle]);

    return <>
        <EditorTooltip title="Styles">
            <span>
                <StylesButton id="style-button"
                              disabled={isDisabled}
                              size="medium"
                              aria-controls={open ? 'style-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                              onClick={handleClick}>{initialStyle ?? '-'}</StylesButton>
            </span>
        </EditorTooltip>
        <Menu
            id="style-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose()}
            MenuListProps={{
                'aria-labelledby': 'style-button',
            }}
        >
            <MenuItem onClick={handleClose('Normal text')}><NormalText>Normal text</NormalText></MenuItem>
            <MenuItem onClick={handleClose('Heading 1')}><Heading1>Heading 1</Heading1></MenuItem>
            <MenuItem onClick={handleClose('Heading 2')}><Heading2>Heading 2</Heading2></MenuItem>
            <MenuItem onClick={handleClose('Heading 3')}><Heading3>Heading 3</Heading3></MenuItem>
            <MenuItem onClick={handleClose('Heading 4')}><Heading4>Heading 4</Heading4></MenuItem>
            <MenuItem onClick={handleClose('Heading 5')}><Heading5>Heading 5</Heading5></MenuItem>
        </Menu>
    </>
}

const wrapper = withShrinkZero<StyleControlProps>(StyleControl);

export {wrapper as StyleControl};
