import {useMainTranslation} from "../../../../hooks/useMainTranslationHooks/useMainTranslation";
import * as S from './styled';
import {ButtonIcon, ButtonTitle, InviteButtonContainer} from './styled';
import {TEditorActor} from "../../types";

export type TEditorEditorsAvatarsProps = {
    actors: TEditorActor[];
    handleOpenEditorsDialog: () => void;
}

export const EditorEditorsAvatars = ({actors, handleOpenEditorsDialog}: TEditorEditorsAvatarsProps) => {
    const {revDir} = useMainTranslation();
    return(
        <S.AvatarsContainer>
            <S.EditorsText>{'Editors:'}</S.EditorsText>
            <S.DocumentAvatarGroup onClick={handleOpenEditorsDialog} revDir={revDir} max={4} total={actors.length} >
                {actors.map((e, index) => (
                    <S.DocumentAvatar key={e.email} index={index} name={`${e.firstName || e.email} ${e.lastName || e.email}`} alt={e.email ?? ''} />
                ))}
            </S.DocumentAvatarGroup>

            <InviteButtonContainer onClick={handleOpenEditorsDialog}>
                <ButtonIcon />
                <ButtonTitle>{('Invite')}</ButtonTitle>
            </InviteButtonContainer>
        </S.AvatarsContainer>
    )
}