import ReactFlow, {Controls, Elements, ReactFlowProvider} from "react-flow-renderer";
import {edgeTypes, nodeTypes} from "../../constants";
import {Minimap} from "../miniMap";
import React from "react";
import {Flex} from "../../../../../newShared/components/Layouts";
import {useMedia} from "../../../../../newShared/hooks/useMedia";

type pipelineProps = {
    preferPipeline: boolean;
    setPreferPipeLine: (status: boolean) => void;
    onLoad: (reactFlowInstance: any) => void;
    allElements: Elements;
    isLoading: boolean;
    isGenereting: boolean;
}

export const Pipeline = ({preferPipeline, setPreferPipeLine, onLoad, allElements, isGenereting, isLoading}: pipelineProps) => {
    // const {t} = useMainTranslation('', {keyPrefix: 'automationsPage'});
    const {isMobile} = useMedia();
    return(
        <Flex m={'20px auto'} w={'100%'} h={'100%'} box_shadow={'0px 0px 19px -10px rgba(34, 60, 80, 0.38)'} br={'5px'}>
            {(!isLoading && !isGenereting) &&
                <ReactFlowProvider>
                    <ReactFlow
                        dir={'ltr'}
                        nodeTypes={nodeTypes}
                        edgeTypes={edgeTypes}
                        elements={allElements as Elements}
                        onLoad={onLoad}
                        selectNodesOnDrag={false}
                        snapToGrid={true}
                    >
                        {!isMobile && <Minimap/>}
                        <Controls/>
                        {/*<Background color="#aaa" /> */}
                    </ReactFlow>
                </ReactFlowProvider>
            }
        </Flex>
    )
}