import {FC} from "react";
import {Menu, MenuItem} from "@mui/material";
import {Arial, FontButton, Georgia, Helvetica, Monospace, TimesNewRoman} from "./styled";
import {useFontControl} from "./hooks";
import {withShrinkZero} from "../../../hoc/withShrinkZero";
import {TFont, TToolBarHandlerAction, TToolBarHandlerPayload} from "../../types";
import {EditorTooltip} from "../../../commonComponents/editorTooltip";


export interface FontControlProps {
    initialFont: TFont | null; // 'Arial' | 'Georgia' | 'Helvetica' | 'Monospace' | 'Times New Roman',
    changeFontHandler: (action: TToolBarHandlerAction, payload: TToolBarHandlerPayload) => void;
    isDisabled: boolean;
}

const FontControl: FC<FontControlProps> = ({initialFont, changeFontHandler, isDisabled}) => {

    const {open, anchorEl, handleClose, handleClick,} = useFontControl({
        initialFont,
        anchorEl: null,
        changeFontHandler
    });

    return <>
        <EditorTooltip title="Font">
            <span>
                <FontButton id="font-button"
                            disabled={isDisabled}
                            size="medium"
                            aria-controls={open ? 'font-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}>{initialFont ?? '-'}</FontButton>
            </span>
        </EditorTooltip>
        <Menu
            id="font-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose()}
            MenuListProps={{'aria-labelledby': 'font-button'}}
        >
            <MenuItem onClick={handleClose("Arial")}><Arial>Arial</Arial></MenuItem>
            <MenuItem onClick={handleClose("Georgia")}><Georgia>Georgia</Georgia></MenuItem>
            <MenuItem onClick={handleClose("Helvetica")}><Helvetica>Helvetica</Helvetica></MenuItem>
            <MenuItem onClick={handleClose("Monospace")}><Monospace>Monospace</Monospace></MenuItem>
            <MenuItem onClick={handleClose("Times New Roman")}>
                <TimesNewRoman>Times New Roman</TimesNewRoman>
            </MenuItem>
        </Menu>
    </>
}

const wrapper = withShrinkZero<FontControlProps>(FontControl);

export {wrapper as FontControl};
