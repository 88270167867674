import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../../newShared/redux/rootReducer";
import {TDialog, TPageInfo} from "../../../../../newShared/types";
import {TKYCRequest, TSetCancelRequestDialog, TSetResendAnalyseDialog} from "../../types";
import {
    downloadFileAction,
    kycAnalyseRestartAction,
    kycCancelRequestByIdAction,
    kycChangeRequestStatusAction,
    kycDownloadRequestByIdAction,
    kycGetNotAnalysedRequestsAction,
    kycGetRequestByIdAction,
    kycGetRequestsAction
} from "./actions";
import {setPaginationInStore} from "../../../../../newShared/hooks/useScroll/helpers";

export type TKYCRequestsSlice = {
    requests: {
        requests: (TKYCRequest | null)[],
        pageInfo: TPageInfo,
    },
    selected: TKYCRequest | null,

    isLoading: {
        isLoadingRequests: boolean,
        isLoadingRequest: boolean,
        isChangingStatus: string | null,
        isDownloadingRequest: boolean,
        isLoadingFiles: string[],
    },

    dialogs: {
        resendAnalyse: TDialog & {
            requests: TKYCRequest[],
            isLoadingRequests: boolean
        },
        cancelRequest: TDialog & {
            id: string | null
        },
    },
}

const initialState: TKYCRequestsSlice = {
    requests: {
        requests: [],
        pageInfo: {page: 0, count: 50, total: 0, sort: ''},
    },

    selected: null,

    isLoading: {
        isLoadingRequests: false,
        isLoadingRequest: false,
        isChangingStatus: null,
        isDownloadingRequest: false,
        isLoadingFiles: [],
    },

    dialogs: {
        resendAnalyse: {
            isLoadingRequests: false,
            isLoading: false,
            isOpen: false,
            requests: [],
        },
        cancelRequest: {
            isLoading: false,
            isOpen: false,
            id: null,
        },
    },
}

const Slice = createSlice({
    name: 'kycRequests',
    initialState,
    reducers: {
        cleanAction(slice: TKYCRequestsSlice, {payload}: {payload: string | undefined}) {
            switch (payload) {
                case 'array': {
                    slice.requests = initialState.requests;
                    break;
                }
                case 'selected': {
                    slice.selected = initialState.selected;
                    break;
                }
                case undefined: {
                    slice.requests = initialState.requests;
                    slice.selected = initialState.selected;
                    slice.isLoading = initialState.isLoading;

                    slice.dialogs = initialState.dialogs;
                }
            }
        },

        setResendAnalyseDialogAction(slice: TKYCRequestsSlice, {payload}: {payload: TSetResendAnalyseDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.resendAnalyse.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.resendAnalyse.isLoading = payload.isLoading;
            if (payload.requests !== undefined) slice.dialogs.resendAnalyse.requests = payload.requests;

            if (payload.clear) slice.dialogs.resendAnalyse = initialState.dialogs.resendAnalyse;
        },

        setCancelRequestDialogAction(slice: TKYCRequestsSlice, {payload}: {payload: TSetCancelRequestDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.cancelRequest.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.cancelRequest.isLoading = payload.isLoading;
            if (payload.id !== undefined) slice.dialogs.cancelRequest.id = payload.id;

            if (payload.clear) slice.dialogs.cancelRequest = initialState.dialogs.cancelRequest;
        },
    },
    extraReducers: builder => {
      builder
          .addCase(kycGetRequestsAction.pending, (slice: TKYCRequestsSlice, {meta: {arg: {data, clean}}}) => {
              slice.isLoading.isLoadingRequests = true;
              if (clean) {slice.requests = initialState.requests}
          })
          .addCase(kycGetRequestsAction.rejected, (slice: TKYCRequestsSlice) => {
              slice.isLoading.isLoadingRequests = false;
          })
          .addCase(kycGetRequestsAction.fulfilled, (slice: TKYCRequestsSlice, {payload: {data, resp}}) => {
              slice.isLoading.isLoadingRequests = false;

              const {array, pageInfo} = setPaginationInStore<TKYCRequest>(
                  slice.requests.pageInfo,
                  slice.requests.requests,
                  resp.pageInfo,
                  resp.requests,
                  true
              );
              slice.requests.requests = array;
              slice.requests.pageInfo = pageInfo;
          })

          .addCase(kycGetRequestByIdAction.pending, (slice: TKYCRequestsSlice, {meta: {arg: {data}}}) => {
              slice.isLoading.isLoadingRequest = true;
          })
          .addCase(kycGetRequestByIdAction.rejected, (slice: TKYCRequestsSlice) => {
              slice.isLoading.isLoadingRequest = false;
          })
          .addCase(kycGetRequestByIdAction.fulfilled, (slice: TKYCRequestsSlice, {payload: {data, resp}}) => {
              slice.isLoading.isLoadingRequest = false;

              slice.selected = resp
          })

          .addCase(kycChangeRequestStatusAction.pending, (slice: TKYCRequestsSlice, {meta: {arg: {data}}}) => {
              slice.isLoading.isChangingStatus = data.status;
          })
          .addCase(kycChangeRequestStatusAction.rejected, (slice: TKYCRequestsSlice) => {
              slice.isLoading.isChangingStatus = null;
          })
          .addCase(kycChangeRequestStatusAction.fulfilled, (slice: TKYCRequestsSlice, {payload: {data, resp}}) => {
              slice.isLoading.isChangingStatus = null;
              
              if (slice.selected?.id === data.id) {
                  slice.selected = resp
              }
          })

          .addCase(kycDownloadRequestByIdAction.pending, (slice: TKYCRequestsSlice, {meta: {arg: {data}}}) => {
              slice.isLoading.isDownloadingRequest = true;
          })
          .addCase(kycDownloadRequestByIdAction.rejected, (slice: TKYCRequestsSlice) => {
              slice.isLoading.isDownloadingRequest = false;
          })
          .addCase(kycDownloadRequestByIdAction.fulfilled, (slice: TKYCRequestsSlice, {payload: {data, resp}}) => {
              slice.isLoading.isDownloadingRequest = false;
          })

          .addCase(kycCancelRequestByIdAction.pending, (slice: TKYCRequestsSlice, {meta: {arg: {data}}}) => {
              slice.dialogs.cancelRequest.isLoading = true;
          })
          .addCase(kycCancelRequestByIdAction.rejected, (slice: TKYCRequestsSlice) => {
              slice.dialogs.cancelRequest.isLoading = false;
          })
          .addCase(kycCancelRequestByIdAction.fulfilled, (slice: TKYCRequestsSlice, {payload: {data, resp}}) => {
              slice.dialogs.cancelRequest.isLoading = false;
              slice.dialogs.cancelRequest = initialState.dialogs.cancelRequest;

              if (slice.requests.requests.some(e => e?.id === data.id)) {
                  slice.requests.requests = slice.requests.requests.filter(e => data.id !== e?.id);
                  slice.requests.pageInfo = {
                      page: slice.requests.pageInfo.page,
                      total: slice.requests.pageInfo.total - 1,
                      sort: slice.requests.pageInfo.sort,
                      count: slice.requests.pageInfo.count
                  }
              }
          })

          .addCase(kycAnalyseRestartAction.pending, (slice: TKYCRequestsSlice, {meta: {arg: {data}}}) => {
              slice.dialogs.resendAnalyse.isLoading = true;
          })
          .addCase(kycAnalyseRestartAction.rejected, (slice: TKYCRequestsSlice) => {
              slice.dialogs.resendAnalyse.isLoading = false;
          })
          .addCase(kycAnalyseRestartAction.fulfilled, (slice: TKYCRequestsSlice, {payload: {data, resp}}) => {
              slice.dialogs.resendAnalyse.isLoading = false;
              slice.dialogs.resendAnalyse = initialState.dialogs.resendAnalyse;
          })

          .addCase(kycGetNotAnalysedRequestsAction.pending, (slice: TKYCRequestsSlice, {meta: {arg: {data}}}) => {
              slice.dialogs.resendAnalyse.isLoadingRequests = true;
          })
          .addCase(kycGetNotAnalysedRequestsAction.rejected, (slice: TKYCRequestsSlice) => {
              slice.dialogs.resendAnalyse.isLoadingRequests = false;
          })
          .addCase(kycGetNotAnalysedRequestsAction.fulfilled, (slice: TKYCRequestsSlice, {payload: {data, resp}}) => {
              slice.dialogs.resendAnalyse.isLoadingRequests = false;
              slice.dialogs.resendAnalyse.requests = resp.requests;
          })

          .addCase(downloadFileAction.pending, (slice: TKYCRequestsSlice, {meta: {arg: {data}}}) => {
              slice.isLoading.isLoadingFiles.push(data.fileId);
          })
          .addCase(downloadFileAction.rejected, (slice: TKYCRequestsSlice, {meta: {arg: {data}}}) => {
              slice.isLoading.isLoadingFiles = slice.isLoading.isLoadingFiles.filter(e => e !== data.fileId);
          })
          .addCase(downloadFileAction.fulfilled, (slice: TKYCRequestsSlice, {payload: {data, resp}}) => {
              slice.isLoading.isLoadingFiles = slice.isLoading.isLoadingFiles.filter(e => e !== data.fileId);
          })
    }
});

export const kycRequestsReducer = Slice.reducer;

export const {
    cleanAction,
    setResendAnalyseDialogAction,
    setCancelRequestDialogAction,
} = Slice.actions;

const selectSelf = (state: AppState): TKYCRequestsSlice => state.kycRequests;

export const kycRequestsSelector = createSelector(selectSelf, state => state.requests);
export const kycRequestSelector = createSelector(selectSelf, state => state.selected);
export const isLoadingSelector = createSelector(selectSelf, state => state.isLoading);

export const kycRequestsDialogsSelector = createSelector(selectSelf, state => state.dialogs);
