import {FC, Fragment} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useMessageDialog} from "./hooks/useMessageDialog";
import {useMainTranslation} from "../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const MessageDialog: FC = () => {

    const {tCommon} = useMainTranslation()
    const {message, handleClose} = useMessageDialog();

    return message ? (
        <Dialog open={Boolean(message)} onClose={handleClose}>
            <DialogTitle>{message.title}</DialogTitle>

            <DialogContent>
                {typeof message.message === "string" ? message.message : (
                    message.message.map((message, index, array) => (
                        <Fragment key={index + message}>
                            {message}
                            {index < array.length - 1 && <br/>}
                        </Fragment>
                    ))
                )}
            </DialogContent>

            <DialogActions>
                <Button variant={"text"} onClick={handleClose}>
                    {tCommon('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    ) : null
}
