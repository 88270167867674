import {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {PATH_LOCAL_VULNERABILITY_SCANNED} from "../../../newShared/constants";
import {VulnerabilityScannerMain} from "./pages/main";
import {AddTarget} from "./pages/addTarget";
import {PATH_LOCAL_VULNERABILITY_EDIT, PATH_LOCAL_VULNERABILITY_ID, PATH_LOCAL_VULNERABILITY_NEW} from "./constants";
import {ExactTarget} from "./pages/exactTarget";
import {EditTarget} from "./pages/editTarget";

export const Routes: FC = () => {
    return (
        <Switch>
            <Route exact path={PATH_LOCAL_VULNERABILITY_SCANNED} component={VulnerabilityScannerMain}/>
            <Route exact path={PATH_LOCAL_VULNERABILITY_ID} component={ExactTarget}/>
            <Route exact path={PATH_LOCAL_VULNERABILITY_NEW} component={AddTarget}/>
            <Route exact path={PATH_LOCAL_VULNERABILITY_EDIT} component={EditTarget}/>
            <Redirect to={PATH_LOCAL_VULNERABILITY_SCANNED}/>
        </Switch>
    )
}