import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideDeleteEmployeeDialog, loadings} from "../../../store/slice";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import React from "react";
import {useHistory} from "react-router-dom";
import {DeleteHrEmployeeById} from "../../../store/actions";
import {PATH_LOCAL_EMPLOYEES_ALL} from "../../../constants";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const DeleteEmployeeDialog = (props: {isOnTree: boolean}) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathHRManagement.dialogs'})
    // const {t: tCommon} = useMainTranslation('', {keyPrefix: 'CommonComponents'})
    
    const dispatch = useDispatch();
    const history = useHistory();
    //sel
    const {isOpen, employee} = useSelector(dialogs).deleteEmployee;
    const isLoading = useSelector(loadings).deleteEmployee;

    const handleClose = () => {
        dispatch(hideDeleteEmployeeDialog())
    }

    const handleSubmit = () => {
        employee && dispatch(DeleteHrEmployeeById({data: {id: employee.id, onSuccess}}))
    }

    const onSuccess = () => {
        if(!props.isOnTree){
            history.push(PATH_LOCAL_EMPLOYEES_ALL)
        }
    }

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Delete employee')}</DialogTitle>

            <DialogContent>
                <DialogContentText>
                    <Typography  sx={{lineHeight: 'normal'}}>{t(`Are you sure you want to delete {{name}}? All subordinates will be left without a manager. You will not be able to restore it.`, {name: employee?.name || ''})}</Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions sx={{gap: '16px'}}>
                <Button onClick={handleClose} variant={'text'}>{t('No, keep')}</Button>
                <LoadingButton onClick={handleSubmit} loading={isLoading} color={'error'}>
                    {t('Yes, delete')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}