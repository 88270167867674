import {useDispatch, useSelector} from "react-redux";
import {hidePreviewTemplateAction, templatePreviewSlice} from "../store/slice";
import {GetPreviewTemplateByIdAction} from "../store/actions";

export const useTemplatePreview = () => {

    const dispatch = useDispatch();

    const {
        isOpen,
        isLoading,
        document,
    } = useSelector(templatePreviewSlice);

    const controller = new AbortController();

    const handleClose = () => {dispatch(hidePreviewTemplateAction())};
    const handleOpen = (templateId: string) => {
        dispatch(GetPreviewTemplateByIdAction({
            data: {fileId: templateId}, signal: controller.signal
        }))
    };

    return {
        isOpen,
        isLoading,
        document,

        handleClose,
        handleOpen,

        controller,
    }
}