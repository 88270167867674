import {Flex} from "../../../../../../../newShared/components/Layouts";
import {
    useCampaignReviewInstructionsList
} from "../../../../hooks/campaignReviewHooks/instructions/useCampaignReviewInstructionsList";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import {useMedia} from "../../../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {PageTitleAndActions} from "../../../../../../../newShared/components/pageTitleAndActions";
import {campaignReviewInstructionType} from "../../../../types";
import {campaignReviewInstructionGetAppsText, getColorAndTextByStatus} from "../../../../helpers";
import {Typography} from "@mui/material";
import {GenericTable} from "../../../../../../../newShared/components/genericTable";

export const CampaignReviewInstructionsList = () => {
    const {actions, selectors, genericTable} = useCampaignReviewInstructionsList();
    const {isMobile} = useMedia(858);
    const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.CampaignReviewInstructionsList'});

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('Campaign review configuration')}
                actions={[
                    {
                        title: isMobile ? t('Add') : t('Add configuration'),
                        onClick: actions.handleGoToCreateInstruction,
                        icon: <AddIcon />,
                        variant: 'text'
                    }
                ]}
            />

            <GenericTable<campaignReviewInstructionType>
                id={'CampaignReviewInstructionsList'}
                columnsConfig={{totalName: 'Total instructions', disableColumnsSettings: true, forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: selectors.instructions,
                    // customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id,
                    onRowClick: genericTable.onRowClick,
                    columnsConfigs: [
                        {key: "name", name: t('Name'), default: true},
                        {key: 'apps', name:  t('Applications'), default: true, valueGetter: campaignReviewInstructionGetAppsText},
                        {key: 'status', name: t('Status'), default: true, valueRenderer: (row) => {
                                const status = getColorAndTextByStatus(row.lastRunStatus)
                                return(
                                    <Typography color={status.color} variant={'*bodyText2'}>{t(`statuses.${status.text}`)}</Typography>
                                )
                            }},
                        {key: 'signer', name: t('Signer'), default: true, valueGetter: (row) => row.finalApprover.email},
                    ],
                }}
                filtersConfig={{}}
                emptyArrayImageProps={
                    {type: 'campaignReviewInstructions',
                        filterNotApplied: {
                            secondaryText: 'You have not added any configurations yet. Create new one',
                            text: 'No configurations found',
                            onClick: actions.handleGoToCreateInstruction
                        }
                    }
                }
            />

            {/*<EmptyArrayImage*/}
            {/*    type={'campaignReviewInstructions'}*/}
            {/*    isShowing={selectors.isNoInstructionsFound}*/}
            {/*    text={t('No configurations found')}*/}
            {/*    secondaryText={t('You have not added any configurations yet. Create new one')}*/}
            {/*    onClick={actions.handleGoToCreateInstruction}*/}
            {/*/>*/}

            {/*{!selectors.isNoInstructionsFound &&*/}
            {/*    <>*/}
            {/*        <CustomTableHeader*/}
            {/*            hideIf={isLessThenCustomQuery}*/}
            {/*            columns={[{name: t('Name'), width: '25%'}, {name: t('Applications'), width: '35%'}, {name: t('Status'), width: '15%'}, {name: t('Signer'), width: '25%'}]}*/}
            {/*            width={'90%'}*/}
            {/*        />*/}
            {/*        <S.MainTableScrollWrapper>*/}
            {/*            <InfiniteScroll*/}
            {/*                pageStart={0}*/}
            {/*                loadMore={(page) => actions.handleLoadMore(page)}*/}
            {/*                hasMore={selectors.hasMore}*/}
            {/*                initialLoad={false}*/}
            {/*                threshold={50}*/}
            {/*                useWindow={false}*/}
            {/*                style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}*/}
            {/*            >*/}
            {/*                {*/}
            {/*                    isLessThenCustomQuery ?*/}
            {/*                        <>*/}
            {/*                            {selectors.instructions.map(e => <InstructionMobileCard key={e.id} instruction={e}/>)}*/}
            {/*                        </>*/}
            {/*                        :*/}
            {/*                        <>*/}
            {/*                            {selectors.instructions.map(e => <InstructionTableRow key={e.id} instruction={e}/>)}*/}
            {/*                        </>*/}
            {/*                }*/}
            {/*                {selectors.isLoadingList && (isLessThenCustomQuery ? <InstructionMobileCardSkeleton /> : <InstructionTableRowSkeleton />)}*/}
            {/*            </InfiniteScroll>*/}
            {/*        </S.MainTableScrollWrapper>*/}
            {/*    </>*/}
            {/*}*/}
        </Flex>
    )
}