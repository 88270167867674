import {useModelCreate} from "../../../hooks/useModelCreate";
import {Flex} from "../../../../../../newShared/components/Layouts";
import colors from "../../../../../../newShared/theme/colors";
import {Button, IconButton, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import * as S from "../../../components/styled";
import {TextFieldCustom} from "../../../components/styled";
import {CustomSlider} from "../../../components/customSlider";
import {ModelTable} from "../../../components/modelTable";
import React from "react";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {IconTextButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import {getLow, getMax} from "../../../helpers";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";

export const ModelsNew = () => {
    const {
        isLoading,
        handleGoBack,
        model,
        handleSubmit,
        isOk,
        handleChangeImpact,
        handleChangeProbability,
        setLeftRight,
        handleSetName,
        handleAddProbability,
        handleAddImpact,
        handleDeleteProbability,
        handleDeleteImpact,
        //
        isCanBeApplied,
        handleApplyChanges,
        isOkToApply,
        displayModel
    } = useModelCreate();

    const {isMobile} = useMedia();
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'risks'});

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('Create model')}
                handleGoBack={handleGoBack}
                actions={[]}
            />

            <Typography variant={'body2'} color={colors.text.grey_dark} sx={{margin: '8px 0 0 0'}}>{t('Used count_doubleDot {{counter}}', {counter: 0})}</Typography>

            <Flex ai={'center'} m={'32px 0 5px 0'}>
                {isLoading ?
                    <LoadingButton
                        loading
                        sx={{width: '190px', paddingLeft: '30px', margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                        variant="outlined"
                    >{t('Save')}</LoadingButton>
                    :
                    <Button
                        disabled={!isOk() || isCanBeApplied}
                        variant={'contained'}
                        size={isMobile ? 'small' : 'medium'}
                        onClick={handleSubmit}
                        sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    >{t('Save')}</Button>
                }
                <Button disabled={isLoading} variant={'outlined'} size={isMobile ? 'small' : 'medium'} onClick={handleGoBack} >{t('Cancel')}</Button>
            </Flex>


            {model &&
                <S.EditModelWrapper>
                    <TextFieldCustom
                        value={displayModel?.name ?? ''}
                        onChange={(e) => handleSetName(e)}
                        name={'name'}
                        size={'small'}
                        label={t('Name')}
                        dir={'ltr'}
                        placeholder={t('Name')}
                        sx={{width: isMobile ? '100%' : '21%', margin: '16px 0'}}
                    />
                    {/*//INPUTS*/}
                    <S.EditModelInputsContainer>
                        <S.EditModeInputWrapper>
                            <Typography variant={'subtitle2'} color={colors.text.grey_dark} sx={{margin: '8px 0'}}>{t('Probability scale')}</Typography>
                            {model.probabilities.map((e, id) => {
                                return(
                                    <Flex ai={'center'} m={'0 0 8px 0'} key={`Probability-${id}`} jc={isMobile ? 'space-between' : 'center'}>
                                        <TextFieldCustom
                                            value={e.quality}
                                            label={t('Quality')}
                                            onChange={(e) => handleChangeProbability(e, id)}
                                            name={'quality'}
                                            size={'small'}
                                            dir={'ltr'}
                                            error={!e.quality.trim().length}
                                            sx={{margin: !revDir ? '0 8px 0 0': '0 0 0 16px'}}
                                        />

                                        <TextFieldCustom
                                            value={e.quantitative}
                                            onChange={(e) => handleChangeProbability(e, id)}
                                            name={'quantitative'}
                                            type={'number'}
                                            dir={'ltr'}
                                            InputProps={{inputProps: {min: 1}}}
                                            label={t('Quantitative')}
                                        />

                                        {model.probabilities.length > 1 &&
                                            <IconButton onClick={() => handleDeleteProbability(id)}>
                                                <DeleteForeverRoundedIcon fontSize={'small'} />
                                            </IconButton>
                                        }
                                    </Flex>
                                )
                            })}

                            <Flex>
                                <IconTextButton
                                    variant={'text'}
                                    icon={<AddIcon fontSize="small"/>}
                                    onClick={handleAddProbability}
                                    sx={{
                                        '& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}
                                    }}
                                >{t('Add probability')}
                                </IconTextButton>
                            </Flex>
                        </S.EditModeInputWrapper>

                        <S.EditModeInputWrapper>
                            <Typography variant={'subtitle2'} color={colors.text.grey_dark} sx={{margin: '8px 0'}}>{t('Impact scale')}</Typography>
                            {model.impacts.map((e, id) => {
                                return(
                                    <Flex ai={'center'} m={'0 0 8px 0'} key={`Impact-${id}`} jc={isMobile ? 'space-between' : 'center'}>
                                        <TextFieldCustom
                                            value={e.quality}
                                            label={t('Quality')}
                                            dir={'ltr'}
                                            placeholder={t('Quality')}
                                            name={'quality'}
                                            size={'small'}
                                            error={!e.quality.trim().length}
                                            onChange={(event) => handleChangeImpact(event, id)}
                                            sx={{margin: !revDir ? '0 8px 0 0': '0 0 0 16px'}}
                                        />

                                        <TextFieldCustom
                                            value={e.quantitative}
                                            type={'number'}
                                            size={'small'}
                                            name={'quantitative'}
                                            label={t('Quantitative')}
                                            dir={'ltr'}
                                            placeholder={t('Quantitative')}
                                            InputProps={{inputProps: {min: 1}}}
                                            onChange={(event) => handleChangeImpact(event, id)}
                                        />

                                        {model.impacts.length > 1 &&
                                            <IconButton onClick={() => handleDeleteImpact(id)}>
                                                <DeleteForeverRoundedIcon fontSize={'small'} />
                                            </IconButton>
                                        }
                                    </Flex>
                                )
                            })}
                            <Flex>
                                <IconTextButton
                                    variant={'text'}
                                    icon={<AddIcon fontSize="small"/>}
                                    onClick={handleAddImpact}
                                    sx={{
                                        '& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}
                                    }}
                                >{t('Add impact')}
                                </IconTextButton>
                            </Flex>
                        </S.EditModeInputWrapper>
                    </S.EditModelInputsContainer>

                    <Button
                        disabled={!isCanBeApplied || !isOkToApply}
                        sx={{margin: '16px 0 0 0'}}
                        onClick={handleApplyChanges}
                    >{'Apply changes'}</Button>

                    {/*//TABLE*/}
                    <S.SliderAndModelViewWrapper>
                        {displayModel && <>
                            <CustomSlider model={displayModel} max={getMax(displayModel)} min={getLow(displayModel)} editMode={true} setLeftRight={setLeftRight}/>
                            <ModelTable model={displayModel}/>
                        </>}
                    </S.SliderAndModelViewWrapper>
                </S.EditModelWrapper>
            }
        </Flex>
    )
}