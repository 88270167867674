import {print} from "graphql";
import {gql} from "@apollo/react-hooks";
import {
    DutyFragment,
    DutyRuleFragment,
    RulesConflictFragment,
    SodSchedulerDataFragment,
    VendorSettingsFragment
} from "./fragments";
import {importCsvFragmentFields, performCsvFragment} from "../../../../newShared/graphQl/fragments";
import {VendorFragment} from "../../vendors/api/fragments";

export const getVendors = print(gql`
    query getVendors($organizationId: String!, $workspaceId: String!, $pageInfo: PaginationInput!){
        getVendors(pageInfo: $pageInfo, organizationId: $organizationId, workspaceId: $workspaceId) {
            vendors {
                ...VendorFragment
            }
            pageInfo {
                page
                count
                total
                sort
                groupBy
            }
        }
    }
    ${VendorFragment}
`)


/// SETTINGS
export const getVendorsSettings = print(gql`
    query getVendorsSettings($organizationId: String!, $workspaceId: String!){
        getVendorsSettings(organizationId: $organizationId, workspaceId: $workspaceId,) {
            ...VendorSettingsFragment
        }
    }
    ${VendorSettingsFragment}
`)

//segregation of duties
export const getAppDuties = print(gql`
    query getAppDuties ($workspaceId: String!, $organizationId: String!, $pageInfo:PaginationInput!){
        getAppDuties(pageInfo: $pageInfo, workspaceId: $workspaceId, organizationId: $organizationId){
            data {
                id
                name
                duties {...DutyFragment}
            }
            categories
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${DutyFragment}
`)

export const createAppDuty = print(gql`
    mutation createAppDuty ($workspaceId: String!, $organizationId: String!, $duty: DutyInput!){
        createAppDuty(duty: $duty, workspaceId: $workspaceId, organizationId: $organizationId){
            ...DutyFragment
        }
    }
    ${DutyFragment}
`)

export const editAppDuty = print(gql`
    mutation editAppDuty ($workspaceId: String!, $organizationId: String!, $duty: DutyInput!){
        editAppDuty(duty: $duty, workspaceId: $workspaceId, organizationId: $organizationId){
            ...DutyFragment
        }
    }
    ${DutyFragment}
`)

export const deleteAppDuty = print(gql`
    mutation deleteAppDuty ($workspaceId: String!, $organizationId: String!, $appId: String!, $id: String!){
        deleteAppDuty(id: $id, appId: $appId, workspaceId: $workspaceId, organizationId: $organizationId){
            message
        }
    }
`)

// rules
export const getDutiesRuleset = print(gql`
    query getDutiesRuleset ($workspaceId: String!, $organizationId: String!, $pageInfo:PaginationInput!){
        getDutiesRuleset(pageInfo: $pageInfo, workspaceId: $workspaceId, organizationId: $organizationId){
            data {...DutyRuleFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${DutyRuleFragment}
`)

export const getDutyRuleById = print(gql`
    query getDutyRuleById($organizationId: String!, $workspaceId: String!, $id: String!){
        getDutyRuleById(id: $id, organizationId: $organizationId, workspaceId: $workspaceId) {
            ...DutyRuleFragment
        }
    }
    ${DutyRuleFragment}
`)

export const createDutyRule = print(gql`
    mutation createDutyRule ($workspaceId: String!, $organizationId: String!, $rule: DutyRuleInput!){
        createDutyRule(rule: $rule, workspaceId: $workspaceId, organizationId: $organizationId){
            ...DutyRuleFragment
        }
    }
    ${DutyRuleFragment}
`)

export const editDutyRule = print(gql`
    mutation editDutyRule ($workspaceId: String!, $organizationId: String!, $rule: DutyRuleInput!){
        editDutyRule(rule: $rule, workspaceId: $workspaceId, organizationId: $organizationId){
            ...DutyRuleFragment
        }
    }
    ${DutyRuleFragment}
`)

export const deleteDutyRule = print(gql`
    mutation deleteDutyRule ($workspaceId: String!, $organizationId: String!, $id: String!){
        deleteDutyRule(id: $id, workspaceId: $workspaceId, organizationId: $organizationId){
            message
        }
    }
`)


export const runRuleCheck = print(gql`
    mutation runRuleCheck ($workspaceId: String!, $organizationId: String!, $id: String!){
        runRuleCheck(id: $id, workspaceId: $workspaceId, organizationId: $organizationId){
            ...RulesConflictFragment
        }
    }
    ${RulesConflictFragment}
`)


// //conflicts
export const getRulesConflicts = print(gql`
    query getRulesConflicts ($workspaceId: String!, $organizationId: String!, $pageInfo:PaginationInput!){
        getRulesConflicts(pageInfo: $pageInfo, workspaceId: $workspaceId, organizationId: $organizationId){
            data {...RulesConflictFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${RulesConflictFragment}
`)

export const addConflicts = print(gql`
    mutation addConflicts ($workspaceId: String!, $organizationId: String!, $conflicts: [RulesConflictInput!]!){
        addConflicts(conflicts: $conflicts, workspaceId: $workspaceId, organizationId: $organizationId){
            message
        }
    }
`)

export const getDataForCreateDuty = print(gql`
    query getDataForCreateDuty ($workspaceId: String!, $organizationId: String!){
        getDataForCreateDuty(workspaceId: $workspaceId, organizationId: $organizationId){
            shortApplications {
                applicationAccessId
                applicationId
                type
                name
            }
        }
    }
`)

export const getDataForAllowedConflict = print(gql`
    query getDataForAllowedConflict ($workspaceId: String!, $organizationId: String!, $ruleId: String!, $account: String!){
        getDataForAllowedConflict(workspaceId: $workspaceId, organizationId: $organizationId, ruleId: $ruleId, account: $account){
            conflictException {
                reason
                resolution
                account
                allowedDate
                active
                deniedBy {
                    id
                    name
                }
            }
        }
    }
`)

export const getDataForCreateRule = print(gql`
    query getDataForCreateRule ($workspaceId: String!, $organizationId: String!){
        getDataForCreateRule(workspaceId: $workspaceId, organizationId: $organizationId){
            duties {
                id
                name
            }
        }
    }
`)


export const getDataForDenyConflict = print(gql`
    query getDataForDenyConflict ($workspaceId: String!, $organizationId: String!){
        getDataForDenyConflict(workspaceId: $workspaceId, organizationId: $organizationId){
            collaborators {
                publicId
                firstName
                lastName
                email
            }
        }
    }
`)

export const getRoleNamesByAccessId = print(gql`
    query getRoleNamesByAppAccessId ($workspaceId: String!, $organizationId: String!, $appAccessId: String!){
        getRoleNamesByAppAccessId(workspaceId: $workspaceId, organizationId: $organizationId, appAccessId: $appAccessId){
            roles
        }
    }
`)

export const denyConflict = print(gql`
    mutation denyConflict($workspaceId: String!, $organizationId: String!, $conflict: RulesConflictInput!){
        denyConflict(workspaceId: $workspaceId, organizationId: $organizationId, conflict: $conflict){
            ...RulesConflictFragment
        }
    }
    ${RulesConflictFragment}
`)

export const allowConflictStandard = print(gql`
    mutation allowConflictStandard($workspaceId: String!, $organizationId: String!, $conflict: RulesConflictInput!, $data: ConflictExceptionInput!){
        allowConflictStandard(workspaceId: $workspaceId, organizationId: $organizationId, conflict: $conflict, data: $data){
            message
        }
    }
`)

export const allowConflictCustom = print(gql`
    mutation allowConflictCustom($workspaceId: String!, $organizationId: String!, $conflict: RulesConflictInput!, $data: UpdateConflictExceptionInput!, $reviewers: [ReleaseActorInput!]!, $readers: [ReleaseActorInput!]!, $signers: [ReleaseActorInput!]!){
        allowConflictCustom(workspaceId: $workspaceId, organizationId: $organizationId, conflict: $conflict, data: $data, reviewers: $reviewers, readers: $readers, signers: $signers){
            message
        }
    }
`)

export const editConflictException = print(gql`
    mutation editConflictException ($workspaceId: String!, $organizationId: String!, $ruleId: String!, $exception: ConflictExceptionInput!){
        editConflictException(ruleId: $ruleId, exception: $exception, workspaceId: $workspaceId, organizationId: $organizationId){
            ...DutyRuleFragment
        }
    }
    ${DutyRuleFragment}
`)

export const deleteConflictException = print(gql`
    mutation deleteConflictException ($workspaceId: String!, $organizationId: String!, $ruleId: String!, $exception: ConflictExceptionInput!){
        deleteConflictException(ruleId: $ruleId, exception: $exception, workspaceId: $workspaceId, organizationId: $organizationId){
            ...DutyRuleFragment
        }
    }
    ${DutyRuleFragment}
`)



export const getSodSchedulerData = print(gql`
    query getSodSchedulerData ($workspaceId: String!, $organizationId: String!, $pageInfo:PaginationInput!){
        getSodSchedulerData(pageInfo: $pageInfo, workspaceId: $workspaceId, organizationId: $organizationId){
            data {...SodSchedulerDataFragment}
            history {
                lastCheckingDate
                rule {
                    id
                    name 
                }
                severityLevel
                securityRisk
                mitigation
                conflicts
            }
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${SodSchedulerDataFragment}
`)


export const editSodSchedulerData = print(gql`
    mutation editSodScheduler ($workspaceId: String!, $organizationId: String!, $schedulerData: SodSchedulerInput!){
        editSodScheduler(schedulerData: $schedulerData, workspaceId: $workspaceId, organizationId: $organizationId){
            ...SodSchedulerDataFragment
        }
    }
    ${SodSchedulerDataFragment}
`)

export const getCsvFieldsAccessManagement = gql`
    mutation getCsvFieldsAccessManagement($workspaceId: String!, $file: String!, $name: String!){
        getCsvFieldsAccessManagement(workspaceId: $workspaceId, file: $file, name: $name) {
            fileId
            fields {
                ...importCsvFragmentFields
            }
        }
    }
    ${importCsvFragmentFields}
`

export const performCsvAccessManagement = gql`
    mutation performCsvAccessManagement($workspaceId: String!, $fileId: String!, $fields: [ImportCsvFieldInput!]!, $id: String!){
        performCsvAccessManagement(workspaceId: $workspaceId, fileId: $fileId, fields: $fields, id: $id) {
            ...performCsvFragment
        }
    }
    ${performCsvFragment}
`