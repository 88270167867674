import {useDispatch, useSelector} from "react-redux";
import {clearSelectedIncomingRequestAction, incomingRequestsSelector, loadings, TSlice} from "../../store/store";
import {
    mainAssetsGetAssetRequestByIdAction,
    mainAssetsGetRequestsWithFilterPaginationAction
} from "../../store/actions";
import {useHistory, useLocation} from "react-router";
import {PATH_LOCAL_ASSETS_INCOMING_REQUESTS} from "../../constants";
import {AssetsRequestStatus} from "../../../../../newShared/GQLTypes";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {GENERIC_FILTER_OPTIONS_DEFAULT_PAGING} from "../../../../../newShared/components/genericFilter/constants";

type TResponse = {
    selected: TSlice['incomingRequests']['selected'],
    isLoading: TSlice['loadings'],

} & Record<string, any>;

export const usePhysicalAssetsRequestsExact = () => {
    // const {t} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets.pathIncomingRequests'});

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const isLoading = useSelector(loadings);
    const {selected} = useSelector(incomingRequestsSelector);

    const {pageInfo: {count}} = useSelector(incomingRequestsSelector);
    const {currentFiltersForFetch, currentSearchForFetch, getCurrentPage} = useGenericFiltersStorage();
    const refreshTable = () => {
        dispatch(mainAssetsGetRequestsWithFilterPaginationAction({data: {pageRequest: {page: getCurrentPage(), count: count || GENERIC_FILTER_OPTIONS_DEFAULT_PAGING}, filter: {...currentFiltersForFetch, requestIdLike: currentSearchForFetch}}, clean: true}));
    }

    const getRequestById = (id: string) => {
        dispatch(mainAssetsGetAssetRequestByIdAction({
            data: {id: id},
            onError: (request, _error, addictiveData) => {
                // const errors = _error.e409;
                setTimeout(() => {handleClose()}, 300)
                refreshTable();
            },
            onSuccess: (request, _response, addictiveData) => {
                if (_response.request.status === AssetsRequestStatus.Cancelled) {
                    setTimeout(() => {handleClose()}, 300)
                    refreshTable();
                }
            },
        }))
    };

    const handleClose = () => {
        history.push(`${PATH_LOCAL_ASSETS_INCOMING_REQUESTS}${location.search}`);
    }

    const clearSelected = () => {
        dispatch(clearSelectedIncomingRequestAction());
    }

    return {
        selected,
        isLoading,

        getRequestById,
        handleClose,

        clearSelected,
    } satisfies TResponse;
};