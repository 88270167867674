import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {useValidateExamAnswersDialog} from "../../../hooks/dialogs/useValidateExamAnswersDialog";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {QuestionToValidate} from "../../questionToValidate";
import colors from "../../../../../../newShared/theme/colors";
import {
    FlexColumn
} from "../../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import {LightTooltip} from "../../../../../../newShared/components/pageTitleAndActions";
import {FC} from "react";

export const ValidateExamAnswersDialog: FC<{refreshAssignment: () => void}> = ({refreshAssignment}) => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.dialogs'})

    const {
        isOpen,
        handleClose,

        examName,
        recipientFullName,
        isLoadingConfirm,

        formik,
    } = useValidateExamAnswersDialog({refreshAssignment});

    return (
        <Dialog open={isOpen} onClose={handleClose} sx={{
            "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "1200px",
                minHeight: "500px"
            },
        }}>
            <DialogTitle>{t('Validate exam answers')}</DialogTitle>
            <DialogContent sx={{backgroundColor: colors.backgrounds.grey_light2}}>
                <FlexColumn sx={{gap: '20px'}}>
                    <Typography variant={'*h3'} sx={{color: colors.text.dark}}>{examName}</Typography>
                    <Typography variant={'*bodyText2_semibold'} sx={{color: colors.text.grey_dark}}>{`${t('Recipient')}:`}</Typography>
                    <Typography variant={'*bodyText1_semibold'} sx={{color: colors.text.dark, marginTop: '-10px'}}>{recipientFullName}</Typography>
                </FlexColumn>

                <Divider sx={{marginBlock: '30px'}}/>

                <FlexColumn sx={{alignItems: 'flex-start', gap: '8px', width: '100%'}}>
                    {/*<Typography sx={{color: colors.text.dark}} variant={'*bodyText1_semibold'}>{t('Training sections')}</Typography>*/}

                    {formik.values.questions?.map((question, questionIndex) =>
                        <QuestionToValidate key={question.questionId} masterFormik={formik} questionIndex={questionIndex}/>)}
                </FlexColumn>
            </DialogContent>

            <DialogActions>
                <Button size={'small'} onClick={handleClose} variant={'text'}>{tCommon('Cancel')}</Button>
                <LightTooltip title={!formik.isValid ? 'Not all of answers are rated' : null}>
                    <span>
                        <LoadingButton variant={'contained'} onClick={formik.handleSubmit} disabled={!formik.isValid} loading={isLoadingConfirm}>
                            {t('Confirm')}
                        </LoadingButton>
                    </span>
                </LightTooltip>
            </DialogActions>
        </Dialog>
    )
}