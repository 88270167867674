import axiosSync from "../../../../newShared/utils/axios/axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {changeUserProfileInfoGQL, changeUserProfilePassGQL, getUserProfileGQL} from "./queries";
import {TChangeUserInfo, TChangeUserPass, TUserInfo} from "../types";
import {AxiosResponse} from "axios";

export const loadCurrentUserInfo = async () => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getUserProfileGQL,
                variables: {

                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getCurrentProfile as TUserInfo
    })
)

export const changeCurrentUserInfo = async (data: TChangeUserInfo) => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: changeUserProfileInfoGQL,
                variables: {
                    data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.changeName as {message: string}
    })
)

export const changeCurrentUserPass = async (data: TChangeUserPass) => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: changeUserProfilePassGQL,
                variables: {
                    data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.changePassword as {message: string}
    })
)
