import {createAsyncThunk} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {getActionsData} from "../../../../newShared/redux";
import moment from 'moment';


import {saveAs} from "file-saver";
import {b64toBlob} from "../../../../newShared/utils/base64/base64";
import {
    CreateTargetExceptionDto,
    DeleteKycArchivedTargetMutationVariables,
    GetKycAvailableFieldsForExportQueryVariables,
    GetKycAvailableSystemFieldsForExportQueryVariables,
    KycCsvFieldWithAliasModel,
    KycRefactorTemplateModel,
    MutationAddKycTargetToBlackListArgs,
    MutationMoveKycTargetToArchiveArgs,
    MutationRestoreKycTargetFromArchiveArgs,
    PaginationInputAnyFilter,
    QueryExportCsvTargetsArgs,
    RestoreKycTargetFromArchiveMutationVariables,
    SetKycTargetVerifiedMutationVariables,
    StartOneTimeScreeningMutationVariables,
    UpdateKycTargetRiskMutationVariables
} from "../../../../newShared/GQLTypes";
import {
    addKycTargetToBlackList,
    cancelRequest,
    createKycTargetException,
    createKycTemplate,
    createTarget,
    deleteKycArchivedTarget,
    deleteKycTemplate,
    deleteTargetException,
    downloadEvidenceFileAPI,
    exportClientsByTemplate,
    exportCsvTargets,
    getDeleteTemplateData,
    getKycArchiveWithFilterAndPaging,
    getKycAvailableFieldsForExport,
    getKycAvailableSystemFieldsForExport,
    getKycClientsFieldsByWorkspaceId,
    getKycClientsWithFilterAndPaging,
    getKycDictionaries,
    getKycExceptionsByTargetId,
    getKycMatches,
    getKycOtsFullResultByEntityId,
    getKycRequestById,
    getKycRequests,
    getKycScreeningById,
    getKycScreeningFullInfoById,
    getKycScreeningFullResultByEntityId,
    getKycScreeningResults,
    getKycScreeningsWithFilterAndPaging,
    getKycTargetById,
    getKycTemplateById,
    getKycTemplateConfig,
    getKycTemplateHistory,
    getKycTemplates,
    getTargetHistory,
    moveKycTargetToArchive,
    performCsvByFileId,
    restoreKycTargetFromArchive,
    restoreKycTargetFromBlackList,
    sendTemplateToClient,
    setKycTargetVerified,
    startOneTimeScreening,
    startTargetScreening,
    updateKycTargetRisk,
    updateKycTargetValues,
    updateKycTemplate,
    uploadKycCsvFile
} from "../api";
import {TWithOptions} from "../../../../newShared/types";
import {
    createTargetRequestType,
    createTemplateRequestType,
    TKycClientPage,
    TKycTemplateField,
    updateTargetRequestType
} from "../types";
import {addInfoSnack, addSuccessfulSnack} from "../../../barsEnvironment/snack/store/slice";

//getKycTemplates
export const GetKycTemplates = createAsyncThunk(
    'KycRefactor/getKycTemplates',
    async ({data, onSuccess}: {data: {pageInfo: PaginationInputAnyFilter}, onSuccess?: () => void}, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const res = await getKycTemplates(workspaceId || '', data.pageInfo);
        onSuccess?.();
        return res;

    }
);


export const GetKycTemplateConfig = createAsyncThunk(
    'KycRefactor/getKycTemplateConfig',
    async (_, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getKycTemplateConfig(workspaceId || '');
    }
);


export const CreateKycTemplate = createAsyncThunk(
    'KycRefactor/createKycTemplate',
    async ({template, onSuccess}: {template: createTemplateRequestType, onSuccess: (id: string) => void}, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const req = {...template};
        req.fields = req.fields.map((e):TKycTemplateField => ({
            id: e.id,
            name: e.name,
            type: e.type,
            required: e.required,
            system: e.system,
            order: e.order,
            checkAlias: e.checkAlias,
            requiredForTemplate: e.requiredForTemplate,
            systemAlias: e.systemAlias,
            dictionary: e.dictionary
        }))
        // console.log(`createKycTemplate`)
        const temp = await createKycTemplate(workspaceId || '', req);
        onSuccess(temp.id);
        return temp;
    }
);

export const CreateTarget = createAsyncThunk(
    'KycRefactor/createTarget',
    async ({data, onSuccess}: {data: createTargetRequestType, onSuccess: (id: string) => void}, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const res = await createTarget(workspaceId || '', data);
        onSuccess(res);
    }
);

export const StartOneTimeScreening = createAsyncThunk(
    'KycRefactor/startOneTimeScreening',
    async ({data, onSuccess}: {data: Omit<StartOneTimeScreeningMutationVariables, 'workspaceId'>, onSuccess: () => void }, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const resp = await startOneTimeScreening({...data, workspaceId});
        onSuccess();
        return resp;
    }
);



export const SendTemplateToClient = createAsyncThunk(
    'KycRefactor/sendTemplateToClient',
    async ({templateId, emails, onSuccess}: {templateId: string, emails: string[], onSuccess: () => void}, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        await sendTemplateToClient(workspaceId || '', organizationId || '', templateId, emails);
        onSuccess();
    }
);

//getKycTemplates
export const GetKycRequests = createAsyncThunk(
    'KycRefactor/getKycRequests',
    async (data: {pageInfo: PaginationInputAnyFilter}, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getKycRequests(workspaceId || '', data.pageInfo);
    }
);


export const DownloadFile = createAsyncThunk(
    'KycRefactor/download',
    async (data: { fileId: string, name: string}, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const resp = await downloadEvidenceFileAPI({fileId: data.fileId, fileName: data.name, workspaceId});
        saveAs(b64toBlob(resp.file), resp.filename)
        return {}
    }
);

export const GetKycRequestById = createAsyncThunk(
    'KycRefactor/getKycRequestById',
    async (data: {requestId: string, onReject: () => void}, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        try{
            return await getKycRequestById(workspaceId || '', data.requestId);
        }catch (ex: any){
            data.onReject();
            throw new Error(ex.message);
        }
    }
);


//getKycTemplateById
export const GetKycTemplateById = createAsyncThunk(
    'KycRefactor/getKycTemplateById',
    async (data: {templateId: string, onReject: () => void}, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        try{
            return await getKycTemplateById(workspaceId || '', data.templateId);
        }catch (ex: any){
            data.onReject();
            throw new Error(ex.message);
        }
    }
);

export const UpdateKycTemplate = createAsyncThunk(
    'KycRefactor/updateKycTemplate',
    async ({template, onSuccess}: {template: createTemplateRequestType, onSuccess: (template: KycRefactorTemplateModel) => void}, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const req = {...template};
        req.fields = req.fields.map((e):TKycTemplateField => ({
            id: e.id,
            name: e.name,
            type: e.type,
            required: e.required,
            system: e.system,
            order: e.order,
            checkAlias: e.checkAlias,
            requiredForTemplate: e.requiredForTemplate,
            systemAlias: e.systemAlias,
            dictionary: e.dictionary
        }))
        const temp = await updateKycTemplate(workspaceId || '', template);
        onSuccess(temp);
        return temp;
    }
);

//cancelRequest
export const CancelRequest = createAsyncThunk(
    'KycRefactor/cancelRequest',
    async ({requestId}: {requestId: string}, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await cancelRequest(workspaceId || '', requestId);
        return requestId;
    }
);


export const GetKycClientsWithFilterAndPaging = createAsyncThunk(
    'KycRefactor/GetKycClientsWithFilterAndPaging',
    async ({data, signal}: TWithOptions<{pageInfo: PaginationInputAnyFilter, clientType: string}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getKycClientsWithFilterAndPaging({workspaceId: workspaceId || '', pageInfo: data.pageInfo, clientType: data.clientType}, signal);
    }
);

export const GetKycArchiveWithFilterAndPaging = createAsyncThunk(
    'KycRefactor/GetKycArchiveWithFilterAndPaging',
    async ({data, signal}: TWithOptions<{pageInfo: PaginationInputAnyFilter}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getKycArchiveWithFilterAndPaging({workspaceId: workspaceId || '', pageInfo: data.pageInfo}, signal);
    }
);

export const GetKycClientsFields = createAsyncThunk(
    'KycRefactor/GetKycClientsFields',
    async (clientType: TKycClientPage, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const resp = await getKycClientsFieldsByWorkspaceId({workspaceId: workspaceId || '', clientType})
        return {
            fields: resp.fields,
            clientType
        };
    }
);

export const GetKycAvailableFieldsForExport = createAsyncThunk(
    'KycRefactor/GetKycAvailableFieldsForExport',
    async (data: Omit<GetKycAvailableFieldsForExportQueryVariables, 'workspaceId'>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const {fields} = await getKycAvailableFieldsForExport({workspaceId: workspaceId || '', ...data});
        return {
            fields,
        };
    }
);

export const GetKycAvailableSystemFieldsForExport = createAsyncThunk(
    'KycRefactor/GetKycAvailableSystemFieldsForExport',
    async (data: Omit<GetKycAvailableSystemFieldsForExportQueryVariables, 'workspaceId'>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const {fields} = await getKycAvailableSystemFieldsForExport({workspaceId: workspaceId || '', ...data});
        return {
            fields,
        };
    }
);

export const UpdateKycClientsFields = createAsyncThunk(
    'KycRefactor/UpdateKycClientsFields',
    async (data: updateTargetRequestType, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await updateKycTargetValues(workspaceId || '', {id: data.targetId, fields: data.fields.map(e => ({id: e.id, fileName: e.fileName, value: e.value ?? ''}))})
        dispatch(addSuccessfulSnack(`Client field's values updated`));
        return {
            data
        }
    }
);

export const UpdateKycTargetRisk = createAsyncThunk(
    'KycRefactor/UpdateKycClientsRisk',
    async (data: UpdateKycTargetRiskMutationVariables, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const resp = await updateKycTargetRisk({...data, workspaceId})
        dispatch(addSuccessfulSnack(resp))
        return {
            data
        }
    }
);

export const MoveKycTargetToArchive = createAsyncThunk(
    'KycRefactor/MoveKycTargetToArchive',
    async (data: MutationMoveKycTargetToArchiveArgs & {onSuccess?: () => void}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await moveKycTargetToArchive({...data, workspaceId})
        dispatch(addSuccessfulSnack(`Client archived`))
        data.onSuccess && data.onSuccess();
        return {
            targetId: data.targetId
        }
    }
);


export const RestoreKycTargetFromArchive = createAsyncThunk(
    'KycRefactor/RestoreKycTargetFromArchive',
    async (data: MutationRestoreKycTargetFromArchiveArgs, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await restoreKycTargetFromArchive({...data, workspaceId})
        dispatch(addSuccessfulSnack(`Client restored from archive`))
        return {
            targetId: data.targetId
        }
    }
);

export const RestoreKycTargetFromBlackList = createAsyncThunk(
    'KycRefactor/RestoreKycTargetFromBlackList',
    async (data: RestoreKycTargetFromArchiveMutationVariables, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await restoreKycTargetFromBlackList({...data, workspaceId})
        dispatch(addSuccessfulSnack(`Client was restored from black list`));
        return {
            targetId: data.targetId
        }
    }
);

export const SetKycTargetVerified = createAsyncThunk(
    'KycRefactor/SetKycTargetVerified',
    async (data: SetKycTargetVerifiedMutationVariables, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await setKycTargetVerified({...data, workspaceId})
        dispatch(addSuccessfulSnack('Client moved to verified'));
        return {
            targetId: data.targetId
        }
    }
);

export const AddKycTargetToBlackList = createAsyncThunk(
    'KycRefactor/AddKycTargetToBlackList',
    async (data: MutationAddKycTargetToBlackListArgs, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await addKycTargetToBlackList({...data, workspaceId})
        dispatch(addSuccessfulSnack(`Client was added to black list`));
        return {
            targetId: data.targetId
        }
    }
);

export const DeleteKycArchivedTarget = createAsyncThunk(
    'KycRefactor/deleteKycArchivedTarget',
    async (data: DeleteKycArchivedTargetMutationVariables, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const resp = await deleteKycArchivedTarget({workspaceId, targetId: data.targetId});
        dispatch(addSuccessfulSnack(`Client deleted`));
        return {
            resp,
            targetId: data.targetId,
        }
    }
);

//Screenings
export const GetKycScreeningsWithFilterAndPaging = createAsyncThunk(
    'KycRefactor/GetKycScreeningsWithFilterAndPaging',
    async ({data, signal}: TWithOptions<{pageInfo: PaginationInputAnyFilter}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getKycScreeningsWithFilterAndPaging({workspaceId: workspaceId || '', pageInfo: data.pageInfo}, signal);
    }
);

export const GetKycScreeningById = createAsyncThunk(
    'KycRefactor/GetKycScreeningById',
    async ({data, signal}: TWithOptions<{screeningId: string}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getKycScreeningById({workspaceId: workspaceId || '', screeningId: data.screeningId}, signal);
    }
);
export const GetKycScreeningFullInfoById = createAsyncThunk(
    'KycRefactor/GetKycScreeningFullInfoById',
    async ({data, signal}: TWithOptions<{screeningId: string}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getKycScreeningFullInfoById({workspaceId: workspaceId || '', screeningId: data.screeningId}, signal);
    }
);

export const GetKycTargetById = createAsyncThunk(
    'KycRefactor/getKycTargetById',
    async ({data, signal}: TWithOptions<{targetId: string}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getKycTargetById(workspaceId, data.targetId, signal);
    }
);

//getKycExceptionsByTargetId
export const GetKycExceptionsByTargetId = createAsyncThunk(
    'KycRefactor/getKycExceptionsByTargetId',
    async ({data, signal}: TWithOptions<{pageInfo: PaginationInputAnyFilter, targetId: string}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getKycExceptionsByTargetId({workspaceId: workspaceId || '', pageInfo: data.pageInfo, targetId: data.targetId}, signal);
    }
);

export const CreateTargetException = createAsyncThunk(
    'KycRefactor/CreateTargetException',
    async ({data, signal, onSuccess}: TWithOptions<CreateTargetExceptionDto>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const resp = await createKycTargetException(workspaceId, data);
        onSuccess?.(data, resp);
        return {
            resp,
            data
        };
    }
);


//deleteTargetException
export const DeleteTargetException = createAsyncThunk(
    'KycRefactor/deleteTargetException',
    async ({data, signal}: TWithOptions<{targetId: string, exceptionId: string}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const resp = await deleteTargetException(workspaceId, data.targetId, data.exceptionId);
        return {
            resp,
            ...data,
        }
    }
);

//-----
//startTargetScreening
export const StartTargetScreening = createAsyncThunk(
    'KycRefactor/startTargetScreening',
    async ({data, signal}: TWithOptions<{targetId: string}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await startTargetScreening(workspaceId, data.targetId);
    }
);


//getTargetHistory
export const GetTargetHistory = createAsyncThunk(
    'KycRefactor/getTargetHistory',
    async ({data, signal}: TWithOptions<{targetId: string}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getTargetHistory(workspaceId, data.targetId);
    }
);

//uploadKycCsvFile
export const UploadKycCsvFile = createAsyncThunk(
    'KycRefactor/uploadKycCsvFile',
    async ({data, signal, onSuccess}: TWithOptions<{templateId: string, file: string, fileName: string}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const resp = await uploadKycCsvFile(workspaceId, data.file, data.templateId, data.fileName);
        onSuccess?.(data, resp);
        return resp;
    }
);


//performCsvByFileId
export const PerformCsvByFileId = createAsyncThunk(
    'KycRefactor/performCsvByFileId',
    async ({data, signal}: TWithOptions<{fileId: string, config: KycCsvFieldWithAliasModel[], onSuccess: (message: string) => void}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const message = await performCsvByFileId(workspaceId, data.fileId, data.config);
        data.onSuccess(message);
        return data.fileId;
    }
);


export const GetKycMatches = createAsyncThunk(
    'KycRefactor/getKycMatches',
    async (data: {pageInfo: PaginationInputAnyFilter}, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getKycMatches(workspaceId || '', data.pageInfo);
    }
);

//getKycTemplateHistory
export const GetKycTemplateHistory = createAsyncThunk(
    'KycRefactor/getKycTemplateHistory',
    async ({data, signal}: TWithOptions<{pageInfo: PaginationInputAnyFilter, templateId: string}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getKycTemplateHistory(workspaceId || '', data.templateId, data.pageInfo);
    }
);

//ExportCsvTargets
export const ExportCsvTargetsWithFilters = createAsyncThunk(
    'KycRefactor/ExportCsvTargetsWithFilters',
    async (data: Omit<QueryExportCsvTargetsArgs, 'workspaceId'>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await exportCsvTargets({...data, workspaceId});
        if (resp.message.length > 0) {
            dispatch(addSuccessfulSnack(`You will receive email with a link to download CSV`));
        }else {
            const date = moment().format("yyyy-MM-DD-hh-mm-ss");
            saveAs(b64toBlob(resp.id as string), `kyc_${data.targetType.toLowerCase()}_${date}.csv`);
        }
        return resp
    }
)

//getKycTemplateHistory
export const GetKycScreeningResults = createAsyncThunk(
    'KycRefactor/getKycScreeningResults',
    async ({data, signal}: TWithOptions<{pageInfo: PaginationInputAnyFilter, screeningId: string}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getKycScreeningResults(workspaceId || '', data.screeningId, data.pageInfo);
    }
);


//download full report
export const GetKycOtsFullResultByEntityId = createAsyncThunk(
    'KycRefactor/getKycOtsFullResultByEntityId',
    async ({data, signal}: TWithOptions<{entityId: string, fileName?: string}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const res = await getKycOtsFullResultByEntityId({workspaceId, entityId: data.entityId});

        if(data.fileName){
            const bytes = new TextEncoder().encode(res);
            const blob = new Blob([bytes], {
                type: "application/json;charset=utf-8"
            });
            saveAs(blob, data.fileName);
        }

        return res;
    }
);

///getKycScreeningFullResultByEntityId
export const GetKycScreeningFullResultByEntityId = createAsyncThunk(
    'KycRefactor/getKycScreeningFullResultByEntityId',
    async ({data, signal}: TWithOptions<{entityId: string, screeningId: string, fileName?: string}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const res = await getKycScreeningFullResultByEntityId(workspaceId || '', data.screeningId, data.entityId);

        if(data.fileName){
            const bytes = new TextEncoder().encode(res);
            const blob = new Blob([bytes], {
                type: "application/json;charset=utf-8"
            });
            saveAs(blob, data.fileName);
        }

        return res;
    }
);


//getKycDictionaries
export const GetKycDictionaries = createAsyncThunk(
    'KycRefactor/getKycDictionaries',
    async () => {
        return await getKycDictionaries();
    }
);


//getDeleteTemplateData
export const GetDeleteTemplateData = createAsyncThunk(
    'KycRefactor/getDeleteTemplateData',
    async ({data, signal}: TWithOptions<{templateId: string}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return getDeleteTemplateData(workspaceId || '', data.templateId);
    }
);

//exportClientsByTemplate
export const ExportClientsByTemplate = createAsyncThunk(
    'KycRefactor/exportClientsByTemplate',
    async (data: {templateId: string, templateName: string, isDownload: boolean, fieldKeys: string[]}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await exportClientsByTemplate({workspaceId, templateId: data.templateId, isDownload: data.isDownload, fieldKeys: data.fieldKeys});

        if (resp.message.length > 0) {
            dispatch(addSuccessfulSnack(`You will receive email with a link to download CSV`));
        }else {
            const date = moment().format("yyyy-MM-DD-hh-mm-ss");
            saveAs(b64toBlob(resp.id as string), `kyc_${data.templateName}_${date}.csv`);
        }
        return resp
    }
)

//deleteKycTemplate
export const DeleteKycTemplate = createAsyncThunk(
    'KycRefactor/DeleteKycTemplate',
    async (data: {templateId: string, isDeleteTargets: boolean, onSuccess?: () => void}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        await deleteKycTemplate(workspaceId, data.templateId, data.isDeleteTargets);
        return data.templateId;
    }
)

//exportClientsByTemplate
export const ExportClientsByTemplateAutoDownload = createAsyncThunk(
    'KycRefactor/exportClientsByTemplate',
    async (data: {templateId: string, templateName: string, fieldKeys: string[]}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const counters = await getDeleteTemplateData(workspaceId, data.templateId);
        if(counters.clientsCount === 0){
            dispatch(addInfoSnack(`Template ${data.templateName} doesn't contain any clients.`))
        }else{
            const resp = await exportClientsByTemplate({workspaceId, templateId: data.templateId, isDownload: counters.clientsCount < 10000, fieldKeys: data.fieldKeys});
            if (resp.message.length > 0){
                dispatch(addSuccessfulSnack(`You will receive email with a link to download CSV`));
            } else {
                const date = moment().format("yyyy-MM-DD-hh-mm-ss");
                saveAs(b64toBlob(resp.id as string), `kyc_${data.templateName}_${date}.csv`);
            }
            return resp
        }

        return '';
    }
)
