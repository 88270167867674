import {SettingsTypes} from "../../types";
import {TableCell, TableRow} from "@mui/material";
import {normalizeSettingIdName} from "../../helpers";
import React from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";


export const SettingsTableRow = ({type, handleGoToSettingById}: {type:SettingsTypes, handleGoToSettingById: (type: SettingsTypes) => void}) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathVendors.VendorsSettings'});
    return(
        <TableRow hover onClick={() => handleGoToSettingById(type)}>
            <TableCell>
                {t(normalizeSettingIdName(type))}
            </TableCell>
        </TableRow>
    )
}