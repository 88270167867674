import React, {FC} from "react";
import {Box, Button, Skeleton, Typography} from "@mui/material";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {CommentSendCard} from "./components/commentSendCard";
import {AddComment} from "@mui/icons-material";
import {NewCommentDialog} from "../dialogs/newCommentDialog";
import {useTabCommentsPage} from "./hooks/useTabCommentsPage";

export const TabComments: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathFrameworks.pathComments'})

    const {
        comments,
        setNewCommentDialog,
        isLoading,
        bottomLoader, onScroll, scrollRef,
    } = useTabCommentsPage();

    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} flexGrow={1} overflow={"auto"} onScroll={onScroll} ref={scrollRef}>

            <Button startIcon={<AddComment/>} variant={"text"} sx={{marginTop: '18px'}}
                    onClick={() => {setNewCommentDialog({isOpen: true})}}>
                {t('Add new comment')}
            </Button>

            {comments.map(comment => comment ? (
                <CommentSendCard comment={comment} key={comment.id}/>
            ) : (
                <Box display={"flex"} flexDirection={"column"} maxWidth={'800px'} minWidth={'320px'} marginTop={'16px'}>
                    <Typography variant={"subtitle1"}><Skeleton width={'70%'}/></Typography>

                    <Typography variant={"body2"}><Skeleton sx={{marginTop: '4px'}}/></Typography>
                    <Typography variant={"body2"}><Skeleton/></Typography>
                    <Typography variant={"body2"}><Skeleton/></Typography>
                    <Typography variant={"body2"}><Skeleton/></Typography>
                    <Typography variant={"body2"}><Skeleton/></Typography>
                    <Typography variant={"body2"}><Skeleton/></Typography>
                    <Typography variant={"body2"}><Skeleton/></Typography>
                    <Typography variant={"body2"}><Skeleton/></Typography>
                    <Typography variant={"body2"}><Skeleton/></Typography>
                </Box>
            ))}

            {(isLoading || bottomLoader) && (
                <Box display={"flex"} flexDirection={"column"} maxWidth={'800px'} minWidth={'320px'} marginTop={'16px'}>
                    <Typography variant={"subtitle1"}><Skeleton width={'70%'}/></Typography>

                    <Typography variant={"body2"}><Skeleton sx={{marginTop: '4px'}}/></Typography>
                    <Typography variant={"body2"}><Skeleton/></Typography>
                    <Typography variant={"body2"}><Skeleton/></Typography>
                    <Typography variant={"body2"}><Skeleton/></Typography>
                    <Typography variant={"body2"}><Skeleton/></Typography>
                    <Typography variant={"body2"}><Skeleton/></Typography>
                    <Typography variant={"body2"}><Skeleton/></Typography>
                    <Typography variant={"body2"}><Skeleton/></Typography>
                    <Typography variant={"body2"}><Skeleton/></Typography>
                </Box>
            )}

            <NewCommentDialog/>
        </Box>
    )
}