import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../../newShared/redux/rootReducer";
import {TKYCForm} from "../../types";
import {kycGetFormByTokenAction, kycRejectFormByTokenAction, kycSubmitFormByTokenAction} from "./actions";
import {TIdNameLogo} from "../../../../../newShared/types";

export type TKYCFormSlice = {
    form: TKYCForm | null;

    result: {
        organization: TIdNameLogo;
        action: 'submit' | 'reject';
        redirectUrl?: string | null;
    } | null,

    isLoading: {
        isLoadingForm: boolean,
        isSubmittingForm: boolean,
        isRejectingForm: boolean,
    },

    dialogs: {

    },
}

const initialState: TKYCFormSlice = {
    form: null,

    result: null,

    isLoading: {
        isLoadingForm: false,
        isSubmittingForm: false,
        isRejectingForm: false,
    },

    dialogs: {

    },
}

const Slice = createSlice({
    name: 'kycForm',
    initialState,
    reducers: {
        cleanAction(slice: TKYCFormSlice) {
            slice.form = initialState.form;
            slice.result = initialState.result;
            slice.isLoading = initialState.isLoading;

            slice.dialogs = initialState.dialogs;
        },
    },
    extraReducers: builder => {
      builder
          .addCase(kycGetFormByTokenAction.pending, (slice: TKYCFormSlice) => {
              slice.isLoading.isLoadingForm = true;
              slice.form = initialState.form;
          })
          .addCase(kycGetFormByTokenAction.rejected, (slice: TKYCFormSlice) => {
              slice.isLoading.isLoadingForm = false;
          })
          .addCase(kycGetFormByTokenAction.fulfilled, (slice: TKYCFormSlice, {payload: {data, resp}}) => {
              slice.isLoading.isLoadingForm = false;
              slice.form = resp;
          })

          .addCase(kycSubmitFormByTokenAction.pending, (slice: TKYCFormSlice) => {
              slice.isLoading.isSubmittingForm = true;
          })
          .addCase(kycSubmitFormByTokenAction.rejected, (slice: TKYCFormSlice) => {
              slice.isLoading.isSubmittingForm = false;
          })
          .addCase(kycSubmitFormByTokenAction.fulfilled, (slice: TKYCFormSlice, {payload: {data, resp}}) => {
              slice.isLoading.isSubmittingForm = false;
              if (slice.form) {
                  slice.result = {organization: slice.form.organization, action: 'submit', redirectUrl: resp.redirectUrl};
              }

              slice.form = initialState.form;
          })

          .addCase(kycRejectFormByTokenAction.pending, (slice: TKYCFormSlice) => {
              slice.isLoading.isRejectingForm = true;
          })
          .addCase(kycRejectFormByTokenAction.rejected, (slice: TKYCFormSlice) => {
              slice.isLoading.isRejectingForm = false;
          })
          .addCase(kycRejectFormByTokenAction.fulfilled, (slice: TKYCFormSlice, {payload: {data, resp}}) => {
              slice.isLoading.isRejectingForm = false;
              if (slice.form) {
                  slice.result = {organization: slice.form.organization, action: 'reject', redirectUrl: null};
              }

              slice.form = initialState.form;
          })
    }
});

export const kycFormReducer = Slice.reducer;

export const {
    cleanAction,
} = Slice.actions;

const selectSelf = (state: AppState): TKYCFormSlice => state.kycForm;

export const kycFormSelector = createSelector(selectSelf, state => state.form);
export const kycFormResultSelector = createSelector(selectSelf, state => state.result);
export const isLoadingSelector = createSelector(selectSelf, state => state.isLoading);

export const kycFormDialogsSelector = createSelector(selectSelf, state => state.dialogs);
