import {TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import React from "react";
import {Flex} from "../../../../../newShared/components/Layouts";

export const SectionsTableHeader = ({isSections, totalCount, selectedCount}: {
    isSections: boolean,
    totalCount: number,
    selectedCount: number
}) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.pathTrainings'})
    return (
        <TableHead>
            <TableRow>
                <TableCell colSpan={4}>
                    <Flex gap={'20px'}>
                        <Typography
                            variant={'*bodyText2'}>{isSections? t('Total sections') : t('Total questions')}: {totalCount}</Typography>
                        <Typography
                            variant={'*bodyText2'}>{isSections ? t('Selected sections') : t('Selected questions')}: {selectedCount}</Typography>
                    </Flex>
                </TableCell>
            </TableRow>
            <TableRow sx={{'& .MuiTableCell-root': {top: '45px'}}}>
                <TableCell sx={{width: '40%'}}>
                    <Typography variant={'*bodyText2'}>{isSections ? t('Training name') : t('Question')}</Typography>
                </TableCell>

                <TableCell sx={{width: '20%'}}>
                    <Typography variant={'*bodyText2'}>{t('Category')}</Typography>
                </TableCell>

                <TableCell sx={{width: '20%'}}>
                    <Typography variant={'*bodyText2'}>{t('Level')}</Typography>
                </TableCell>

                <TableCell sx={{width: '20%'}}>
                    <Typography variant={'*bodyText2'}>{t('Type')}</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
}