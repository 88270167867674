import React, {FC} from "react";
import {Box, Grid} from "@mui/material";
import {FrameworkAdoptCard} from "../../components/frameworkAdoptCard";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {FrameworkSkeletonCard} from "../../components/frameworkSkeletonCard";
import {useAdoptedFrameworksPage} from "./hooks/useAdoptedFrameworksPage";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {EmptyArrayImage} from "../../../../../newShared/components/EmptyArrayImage";
import {PageWrapper} from "../../../../../newShared/components/Layouts";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {GenericFiltersArray} from "../../../../../newShared/components/genericFilter";

export const AdoptedFrameworks: FC = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathFrameworks'});
    const {matches_2300Up} = useMedia();

    const {
        frameworks, isLoading,
        fetchByFilters
    } = useAdoptedFrameworksPage();

    return (
        <PageWrapper sx={{paddingBottom: '10px'}}>
            <PageTitleAndActions
                title={t('Frameworks')}
                actions={[]}
            />
            {/*<Breadcrumbs/>*/}
            {/*<Typography variant={!matches_1440Down ? "h1" : "h3"}>{t('Frameworks')}</Typography>*/}

            {/*<SearchField onClear={() => {setFilter('input'); handleChange({target: {value: ''}})}}*/}
            {/*             value={searchValue} onKeyDown={handleKeyDown} onChange={handleChange} disabled={isLoading}*/}
            {/*             sx={{marginTop: '16px', maxWidth: !isMobile ? '320px' : undefined}}/>*/}

            <GenericFiltersArray
                configs={[]}
                fetchResultWithSelectedFilters={fetchByFilters}
                isAddFilters={false}
            />

            <Box width={'100%'} display={"flex"} flexDirection={"column"} flexGrow={1} overflow={'auto'} padding={!revDir ? '0 10px 0 0' : '0 0 0 10px'}>
                {(frameworks.length || isLoading) && (
                    <Grid container spacing={2} mt={'16px'} columns={matches_2300Up ? 10 : undefined} sx={{'&>.MuiGrid-item': !revDir ? {paddingLeft: '17px'} : {paddingRight: '17px'}}}>
                        {frameworks.map(framework => (
                            <Grid item key={framework.id} c390={12} c770={6} c1336={3} c1440={4} c1920={3} c2300={2}>
                                <FrameworkAdoptCard framework={framework}/>
                            </Grid>
                        ))}

                        {isLoading && (
                            <Grid item c390={12} c770={6} c1336={3} c1440={4} c1920={3} c2300={2}>
                                <FrameworkSkeletonCard/>
                            </Grid>
                        )}
                    </Grid>
                )}

                <EmptyArrayImage type={"frameworks"} isShowing={frameworks.length === 0 && !isLoading} text={t('You have not adopt any frameworks')} withoutAdd/>
            </Box>
        </PageWrapper>
    )
}