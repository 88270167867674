//core
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

//redux
import {
    addEvidence,
    controls,
    evidences,
    frameworks,
    hideAddEvidence,
    isLoadingEvidences,
    selectedAutomation,
    setEvidence
} from "../../../store/slice";
import {GetAutomationEvidences} from "../../../store/actions";
//material
import {Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,} from "@mui/material";

//types
import {AutomationControl, AutomationEvidence, AutomationFramework, automationType,} from "../../../types";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {addInfoSnack} from "../../../../../barsEnvironment/snack/store/slice";
import {Flex} from "../../../../../../newShared/components/Layouts";

//components

export const SaveEvidenceSelectionDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'automations'});

    const dispatch = useDispatch();
    const auto: automationType | null = useSelector(selectedAutomation);

    const _evidences:AutomationEvidence[] =  useSelector(evidences);
    const _controls:AutomationControl[] =  useSelector(controls);
    const _frameworks:AutomationFramework[] =  useSelector(frameworks);

    const {isOpen} = useSelector(addEvidence);
    const _isLoading = useSelector(isLoadingEvidences);

    useEffect(() => {
        if(isOpen && _frameworks.length === 0){
           dispatch(GetAutomationEvidences());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const [selectedFramework, setSelectedFramework] = useState<AutomationFramework | null>(null);
    const [selectedControl, setSelectedControl] = useState<AutomationControl | null>(null);
    const [selectedEvidence, setSelectedEvidence] = useState<AutomationEvidence | null>(null);

    const handleSelect = () => {
        if(auto && selectedEvidence && selectedControl && selectedFramework && !auto.evidence.data.some(e => e.id === selectedEvidence.id)){
            dispatch(setEvidence(
                {
                    name: selectedEvidence.name,
                    id: selectedEvidence.id,
                    controlId: selectedControl.id,
                    frameworkId: selectedFramework.id,
                }
            ));
            setSelectedEvidence(null);
            setSelectedControl(null);
            setSelectedFramework(null);
        }else if(auto && selectedEvidence && auto.evidence.data.some(e => e.id === selectedEvidence.id)){
                dispatch(addInfoSnack(t('Evidence {{name}} already added', {name: selectedEvidence.name})));
        }
    }

    const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
        if (reason !== 'backdropClick') {
            dispatch(hideAddEvidence());
            setSelectedEvidence(null);
            setSelectedControl(null);
            setSelectedFramework(null);
        }
    };

    return (auto &&

        <div>
            <Dialog disableEscapeKeyDown fullWidth open={isOpen} onClose={handleClose} maxWidth={'xs'} >
                <DialogTitle>{t('Select Evidence')}</DialogTitle>
                <DialogContent>
                    <Flex ai={'center'} jc={'center'} m={'10px 0 0 0'} direction={'column'}>
                        <Autocomplete
                            id="combo-box-demo"
                            options={_frameworks}
                            getOptionLabel={(option) => option.name}
                            loading={_isLoading}
                            value={selectedFramework}
                            onChange={(e, newValue: AutomationFramework | null) => {
                                if(!newValue) return;
                                setSelectedFramework(newValue);
                                setSelectedControl(null);
                                setSelectedEvidence(null);
                            }}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField required {...params} label={t("Framework")} />}
                        />
                        <Autocomplete
                            style={{margin: '20px 0 0 0'}}
                            id="combo-box-demo"
                            options={selectedFramework ? _controls.filter(e => e.frameworkIds.includes(selectedFramework.id)).sort() : []}
                            getOptionLabel={(option) => option.name}
                            disabled={!selectedFramework}
                            // groupBy={(e) => e.category}
                            loading={_isLoading}
                            value={selectedControl}
                            onChange={(e, newValue) => {
                                setSelectedControl(newValue);
                                setSelectedEvidence(null);
                            }}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField required {...params} label={t("Control")} />}
                        />

                        <Autocomplete
                            style={{margin: '20px 0 0 0'}}
                            id="combo-box-demo"
                            options={selectedControl ? _evidences.filter(e => e.controlIds.includes(selectedControl.id)) : []}
                            getOptionLabel={(option) => option.name}
                            disabled={!selectedControl}
                            loading={_isLoading}
                            value={selectedEvidence}
                            onChange={(e, newValue) => {setSelectedEvidence(newValue)}}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField required {...params} label="Evidence" />}
                        />
                    </Flex>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>{t('Cancel')}</Button>
                    <Button onClick={handleSelect} disabled={!selectedEvidence}>{t('Ok')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
