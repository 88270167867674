import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    CLIENTS_DEFAULT_PAGING,
    CORPORATE_CLIENTS_PAGE_TYPE,
    CRYPTOWALLET_CLIENTS_PAGE_TYPE,
    INDIVIDUAL_CLIENTS_PAGE_TYPE,
    KYC_CLIENTS_ID_FIELDS_PATH,
    KYC_ROOT_PATH,
    TARGET_BLACKLIST_STATUS,
    TARGET_CREATED_STATUS,
    TARGET_HIGH_RISK,
    TARGET_LOW_RISK,
    TARGET_MATCHED_STATUS,
    TARGET_MEDIUM_RISK,
    TARGET_MODIFIED_STATUS,
    TARGET_NONE_RISK,
    TARGET_VERIFIED_STATUS
} from "../../../constants";
import {
    clientsSelector,
    dialogs as dialogsSelector,
    eraseClientsList,
    eraseClientsListFields,
    eraseIsDataOutdated,
    hideExportCsv,
    isDataOutdated,
    loadings as loadingsSelector,
    openAddToBlackListDialog,
    openArchiveClientDialog,
    openCreateClientDialog,
    openEditClientDialog,
    openExportCsv,
    openRemoveFromBlackListDialog,
    replaceTargetsMinMaxLoadedPage,
    setSelectedTarget
} from "../../../store/slice";
import {
    ExportCsvTargetsWithFilters,
    GetKycAvailableSystemFieldsForExport,
    GetKycClientsFields,
    GetKycClientsWithFilterAndPaging,
    GetKycTemplates,
    StartTargetScreening
} from "../../../store/actions";
import React, {useEffect, useState} from "react";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {
    getBreadcrumbsClientType,
    getDefaultClientsColumns,
    getNormalizedClientRiskName,
    getNormalizedClientStatusName,
    getStatusTitleAndColor,
    getTargetName,
    getTargetRisk,
    getTargetTypeForPath,
    setKycArchiveClientsColumnsSettingsLocalStorage,
    setKycCorporateClientsColumnsSettingsLocalStorage,
    setKycCryptowalletsClientsColumnsSettingsLocalStorage,
    setKycIndividualClientsColumnsSettingsLocalStorage
} from "../../../helpers";
import {KycTargetFieldModel, TKycTargetModel} from "../../../../../../newShared/GQLTypes";
import {useLocation} from "react-router";
import {TKycClientPage} from "../../../types";
import {keyConfig} from "../../../../../../newShared/components/genericFilter/types";
import {
    useGenericFiltersStorage
} from "../../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {CommonChips} from "../../../../../../newShared/components/CommonChips";
import {TIdName} from "../../../../../../newShared/types";
import {autocompleteKycTemplatesByTargets} from "../../../api";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../../newShared/components/genericTable/types";


export const useKycClientsList = () => {

    //root
    const dispatch = useDispatch();
    const history = useHistory();
    const pathname = useLocation().pathname
    const {workspaceId, user} = UseManageWorkspacesAndOrganizations();
    const {currentSearchForFetch, currentFiltersForFetch} = useGenericFiltersStorage();

    //selectors
    const {t, tMenu} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.clients'});

    const isOutdated = useSelector(isDataOutdated).clientList;
    const {clients, pageInfo, fields, minMaxLoadedPage: {minLoadedPage, maxLoadedPage}} = useSelector(clientsSelector);
    const clientPage = useParams<{type: string}>().type;
    const loadings = useSelector(loadingsSelector);
    const dialogs = useSelector(dialogsSelector);

    const [columnsSettings, setColumnSettings] = useState<KycTargetFieldModel[]>(getDefaultClientsColumns(fields, clientPage.toUpperCase()));
    const [columnSettingsDialog, setColumnSettingsDialog] = useState<boolean>(false);
    const [selectedTargetId, setSelectedTargetId] = useState<string | null>(null);

    // const [path, setPath] = useState<string>('');
    // const [isInitial, setIsInitial] = useState<boolean>(true);

    //controlled
    // const controller = new AbortController();


    //useEffects
    useSetBreadcrumbs([
        {
            title: tMenu('KYC Management'),
            path: KYC_ROOT_PATH
        },
        {
            title: tMenu(getBreadcrumbsClientType(clientPage)),
            path: pathname
        }
    ]);

    // useEffect(() => {
    //
    //     return () => {
    //         controller.abort();
    //         setPath('');
    //         clearFilters();
    //         setSelectedTargetId(null);
    //     }
    //     //eslint-disable-next-line
    // }, []);

    useEffect(() => {

        // if (path !== clientPage) {
        //     dispatch(eraseClientsList());
        //     dispatch(eraseClientsListFields());
        //     clearFilters();
        //     replaceMinMaxPage({minLoadedPage: -1, maxLoadedPage: -1});
        //     setPath(clientPage);
        // }


        // dispatch(GetKycClientsWithFilterAndPaging({
        //     data: {
        //         clientType: clientPage.toUpperCase(),
        //         pageInfo: {page: 0, count: CLIENTS_DEFAULT_PAGING}
        //     },
        //     signal: controller.signal
        // }));
        dispatch(GetKycClientsFields(clientPage.toUpperCase() as TKycClientPage));
        // setIsInitial(false);

        return () => {
            dispatch(eraseClientsListFields());
        }
        //eslint-disable-next-line
    }, [clientPage]);

    // useEffect(() => {
    //     if(user && workspaceId && fields.length > 0) {
    //         if (clientPage === INDIVIDUAL_CLIENTS_PAGE_TYPE) {
    //             const settings = getKycIndividualClientsColumnsSettingsLocalStorage(user.publicId, workspaceId, fields);
    //             setColumnSettings(settings);
    //         } else if (clientPage === CORPORATE_CLIENTS_PAGE_TYPE){
    //             const settings = getKycCorporateClientsColumnsSettingsLocalStorage(user.publicId, workspaceId, fields);
    //             setColumnSettings(settings);
    //         } else if (clientPage === CRYPTOWALLET_CLIENTS_PAGE_TYPE){
    //             const settings = getKycCryptowalletsClientsColumnsSettingsLocalStorage(user.publicId, workspaceId, fields);
    //             setColumnSettings(settings);
    //         }
    //     }
    //     //eslint-disable-next-line
    // }, [fields]);

    const goToExactClient = (client: TKycTargetModel) => {
        dispatch(setSelectedTarget(client));
        history.push(KYC_CLIENTS_ID_FIELDS_PATH.replace(':id', client.id).replace(':type', getTargetTypeForPath(client).pathName));

        // history.push(KYC_CLIENTS_ID_PATH.replace(':id', client.id).replace(':type', getTargetTypeForPath(client).pathName));
    }

    const handleExportCsv = (fieldKeys: string[]) => {
        (columnsSettings && clientPage) && dispatch(ExportCsvTargetsWithFilters({
            targetType: clientPage.toUpperCase(),
            fieldKeys
        }));
    }


    const onSuccess = () => dispatch(openCreateClientDialog({isOpen: true}));

    //open dialogs
    const handleOpenCreateClient = () => {
        dispatch(GetKycTemplates({data: {pageInfo: {page: 0, count: 99999}}, onSuccess}));
    }

    const handleOpenStartScreening = (selectedClient: TKycTargetModel) => {
        dispatch(StartTargetScreening({data: {targetId: selectedClient.id}}))
    }

    const handleOpenEditClientInfo = (selectedClient: TKycTargetModel) => {
        // e.stopPropagation();
        dispatch((openEditClientDialog({selectedClient})));
        setSelectedTargetId(selectedClient.id);
    }

    const handleOpenAddToBlackList = (selectedClient: TKycTargetModel) => {
        const clientName = getTargetName(selectedClient);
        dispatch(openAddToBlackListDialog({clientId: selectedClient.id, clientName}));
        setSelectedTargetId(selectedClient.id);
    }

    const handleOpenArchiveClient = (selectedClient: TKycTargetModel) => {
        dispatch(openArchiveClientDialog({selectedClient, isFromTargetListPage: true}));
        setSelectedTargetId(selectedClient.id);
    }
    const handleOpenRemoveFromBlackList = ( selectedClient: TKycTargetModel) => {
        dispatch(openRemoveFromBlackListDialog({selectedClient}));
        setSelectedTargetId(selectedClient.id);
    }

    const handleOpenExportCsv = () => {
        dispatch(openExportCsv({}));
        dispatch(GetKycAvailableSystemFieldsForExport({clientType: clientPage.toUpperCase()}));
    }

    const handleCloseExportCsv = () => {
        dispatch(hideExportCsv());
    }


        //scroll
    /*const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => {

            dispatch(GetKycClientsWithFilterAndPaging({data: {
                clientType: clientPage.toUpperCase(),
                pageInfo: {page, count: CLIENTS_DEFAULT_PAGING, filters: {...currentFiltersForFetch, ...(currentSearchForFetch.trim().length > 0 ? {nameLike: currentSearchForFetch} : {})}}},
                signal: controller.signal
            }))
        },
        arrayLength: clients.length,
        isLoading: loadings.isLoadingList,
        pageInfo: pageInfo,
        // cleanData: () => dispatch(eraseCategories()),
        // controller: controller,
        // isOnTop: true,
        isNoMoreThanTotal: true,
        customDependencies: [],
        is0PageAddictive: false,
        disableInitFetch: true,
    })*/

    //filters config
    const statusConfig: keyConfig<string> = {
        type: 'filter',
        key: 'status',
        name: t('Status'),
        options: [TARGET_CREATED_STATUS, TARGET_VERIFIED_STATUS, TARGET_MATCHED_STATUS, TARGET_MODIFIED_STATUS, TARGET_BLACKLIST_STATUS],
        OptionRenderer: (option, color) => <CommonChips settings={{...getStatusTitleAndColor(option), margin: '8px 0 0 0'}}/>,
        getOptionLabel: (o) => getNormalizedClientStatusName(o),
        default: true
    }

    const riskConfig: keyConfig<string> = {
        type: 'filter',
        key: 'risk',
        name: t('Risk'),
        options: [TARGET_NONE_RISK, TARGET_LOW_RISK, TARGET_MEDIUM_RISK, TARGET_HIGH_RISK],
        OptionRenderer: (option, color) => getTargetRisk(option),
        getOptionLabel: (o) => getNormalizedClientRiskName(o),
        default: true
    }

    const missingDataConfig: keyConfig<TIdName> = {
        type: 'filter',
        key: 'onlyWithMissingData',
        name: t('Complete data'),
        options: [{id: 'false', name: 'Yes'}, {id: 'true', name: 'No'}],
        getOptionLabel: (e) => e.name,
        getFilterValue: (e) => e.id,
        isOptionEqual: (a, b) => a.id === b.id,
        isOptionEqualToSearch: (a, search) => a.name.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: true
    }

    const lastModified: keyConfig<string> = {
        type: 'date',
        key: 'lastModified',
        name: t('Last modified'),
        default: true,
    }

    const templatesConfig: keyConfig<TIdName> = {
        type: 'filter',
        key: 'templateId',
        name: t('Template'),
        fetchOptions: (workspaceId, data, signal) => {
            return autocompleteKycTemplatesByTargets({...data, targetType: clientPage.toUpperCase(), workspaceId})
                .then(resp => ({values: resp.templates, pageInfo: resp.pageInfo}))
        },
        // options: _recipientVariants?.recipients ? _recipientVariants?.recipients : [],
        getFilterValue: (a) => a.id,
        getOptionLabel: (e) => e.name,
        isOptionEqual: (a, b) => a.id === b.id,
        isOptionEqualToSearch: (a, search) => a.name.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: true,
    }

    const handleReloadData = () => {
        const filters = currentFiltersForFetch;
        const search = currentSearchForFetch;
        dispatch(eraseClientsList());
        dispatch(eraseIsDataOutdated());
        dispatch(GetKycClientsWithFilterAndPaging({data: {
                clientType: clientPage.toUpperCase(),
                pageInfo: {page: 0, count: CLIENTS_DEFAULT_PAGING, filters: {...filters, ...(search.trim().length > 0 ? {nameLike: search} : {})}}}
        }))
    }

    const hasEmptyRequiredField = (client: TKycTargetModel):boolean => {
        return client.fields.some(field => field.required && !field.value?.trim());
    }

    const handleSaveColumnsSettingsDialog = (selectedColumns: TIdName[]) => {
        if(user && workspaceId){
            const newSelectedColumns: KycTargetFieldModel[] = [];
            selectedColumns.forEach(e => {
                const column = fields.find(c => c.id === e.id);
                column && newSelectedColumns.push(column);
            })

            if(clientPage === INDIVIDUAL_CLIENTS_PAGE_TYPE) setKycIndividualClientsColumnsSettingsLocalStorage(user.publicId, workspaceId, newSelectedColumns);
            else if (clientPage === CORPORATE_CLIENTS_PAGE_TYPE) setKycCorporateClientsColumnsSettingsLocalStorage(user.publicId, workspaceId, newSelectedColumns);
            else if (clientPage === CRYPTOWALLET_CLIENTS_PAGE_TYPE) setKycCryptowalletsClientsColumnsSettingsLocalStorage(user.publicId, workspaceId, newSelectedColumns);
            else setKycArchiveClientsColumnsSettingsLocalStorage(user.publicId, workspaceId, newSelectedColumns);
            setColumnSettings(newSelectedColumns);
            setColumnSettingsDialog(false);
        }
    }

    return {
        clients,
        fields,
        selectedTargetId,
        hasEmptyRequiredField,
        clientPage,
        pageTitle: getBreadcrumbsClientType(clientPage),
        pageInfo,
        loadings,
        configs: [
            templatesConfig,
            statusConfig,
            lastModified,
            riskConfig,
            missingDataConfig
        ],
        columnSettings: {
            settings: columnsSettings,
            columnVariants: fields,
            handleSave: handleSaveColumnsSettingsDialog,
            isOpenDialog: columnSettingsDialog,
            handleClose: () => setColumnSettingsDialog(false),
            handleOpen: () => setColumnSettingsDialog(true)
        },

        goToExactClient,

        exportCsv: {
            isOpen: dialogs.exportCsv.isOpen,
            availableFields: dialogs.exportCsv.fields,
            handleExportCsv,
            handleClose: handleCloseExportCsv,
        },


        //open dialogs
        handleOpenCreateClient,
        handleOpenStartScreening,
        handleOpenEditClientInfo,
        handleOpenAddToBlackList,
        handleOpenArchiveClient,
        handleOpenRemoveFromBlackList,
        handleOpenExportCsv,

        // isNoClientsFound: !loadings.isLoadingList && !clients.length && !isInitial,

        /*scroll: {
            bottomLoader,
            onScroll,
            scrollRef,
            hasMore: pageInfo.total > ((pageInfo.page + 1) * pageInfo.count),
        },*/
        isOutdated,
        handleReloadData,
        genericTable: {
            paging: {
                pageInfo,
                isLoading: loadings.isLoadingList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceTargetsMinMaxLoadedPage(data)),
                clearRows: () => dispatch(eraseClientsList()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    dispatch(GetKycClientsWithFilterAndPaging({data: {
                            clientType: clientPage.toUpperCase(),
                            pageInfo: {page, count, filters}}}
                    ));
                },
            },
            defaultClientsColumns: getDefaultClientsColumns(fields, clientPage.toUpperCase()).map(e => e.systemAlias).filter(e => e !== null),
            // onRowClick: (row: AccessManagementApp) => {
            //     dispatch(setSelectedApp(row));
            //     history.push(PATH_ACCESS_MANAGEMENT_APPS_EXACT.replace(':id', row.id));
            // }
        }
    }

}
