import {useDispatch, useSelector} from "react-redux";
import {loadings, models} from "../../store/slice";
import {useHistory} from "react-router";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_RISK_MANAGEMENT} from "../../../../../newShared/constants";
import {
    PATH_RISK_MANAGEMENT_RISK_REGISTRIES_ALL,
    PATH_RISK_MANAGEMENT_RISK_REGISTRIES_EXACT,
    PATH_RISK_MANAGEMENT_RISK_REGISTRIES_NEW
} from "../../constants";
import {useForm} from "../../../../../newShared/hooks/useForm";
import {TRiskModel, TRiskRegistry} from "../../types";
import {useEffect, useState} from "react";
import {createRiskRegistryAction} from "../../store/actions";

export const useRegistriesNew = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {registryCreate, modelsList} = useSelector(loadings);
    const _models = useSelector(models);

    const handleGoBack = () => history.push(PATH_RISK_MANAGEMENT_RISK_REGISTRIES_ALL);

    //nav
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    useSetBreadcrumbs([
        {
            title: tMenu('Risk Management'),
            path: PATH_LOCAL_RISK_MANAGEMENT
        },
        {
            title: tMenu('Risk registries'),
            path: PATH_RISK_MANAGEMENT_RISK_REGISTRIES_ALL
        },
        {
            title: tMenu('New registry'),
            path: PATH_RISK_MANAGEMENT_RISK_REGISTRIES_NEW
        }
    ]);

    const {form, handleChange} = useForm<TRiskRegistry>({
        name: '',
        context: '',
        model: '',
        totalRisks: 0,
        lastUpdate: {
            date: null,
            publicId: ''
        },
        id: ''
    });

    const [selectedModel, setSelectedModel] = useState<TRiskModel | null>();


    const isOk = () => {
        return (form.name.trim().length > 0 && form.context.trim().length > 0 && selectedModel !== null);
    }

    const onSuccess = (id: string) => {
        history.push(PATH_RISK_MANAGEMENT_RISK_REGISTRIES_EXACT.replace(':id', id));
    }

    const handleSubmit = () => {
        if(isOk() && selectedModel){
            dispatch(createRiskRegistryAction({
                data: {
                    workspaceId: '',
                    organizationId: '',
                    registry: {...form, model: selectedModel.id}
                },
                onSuccess
            }))
        }
    }

    useEffect(() => {
        if(_models && _models.length > 0){
            setSelectedModel(_models[0]);
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!modelsList && !selectedModel && _models && _models.length > 0){
            setSelectedModel(_models[0]);
        }
        //eslint-disable-next-line
    }, [modelsList]);

    return{
        isLoading: registryCreate,
        handleGoBack,
        form,
        handleChange,
        models: _models,
        isOk,
        handleSubmit,
        setSelectedModel,
        selectedModel,
        isLoadingModels: modelsList
    }
}