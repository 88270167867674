import React, {FC, useEffect, useState} from "react";
import {Button, Typography} from "@mui/material";
import {SmallSizeDialogStyled} from "../manageActorsDialog/styled";
import {
    AddVariableDialogActionsStyled,
    AddVariableDialogContentStyled,
    AddVariableDialogTitleStyled
} from "../addVariableDialog/styled";
import {createEventCommonDialogOpen} from "../../../helpers";
import {TCommonDialogOpenEventData} from "../../../types";
import {commonDialogOpenEventName} from "../../../constants";
import {LoadingButton} from "../../../../Basic/CommonButtons";

interface IProps {
    onConfirm: () => void;
    isLoading: boolean;
}
export const ResendInviteToRecipientDialog: FC<IProps> = ({onConfirm, isLoading}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [recipient, setRecipient] = useState<string>('');

    const type: TCommonDialogOpenEventData["type"] = "resendInviteRecipient";
    const text = `The invitation to fill out and sign document to ${recipient} will be resent.`;
    const title = 'Resend invite';

    const handleClose = () => {
        createEventCommonDialogOpen({type, isOpen: false, data: []});
    };

    const handleEvent = (ev: Event) => {
        const {detail} = ev as CustomEvent<TCommonDialogOpenEventData>;
        if (detail.type === type) {
            setIsOpen(detail.isOpen);
            setRecipient(detail.data[0] || '');
        }
    }

    useEffect(() => {
        document.addEventListener(commonDialogOpenEventName, handleEvent);
        return () => {
            document.removeEventListener(commonDialogOpenEventName, handleEvent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <SmallSizeDialogStyled open={isOpen} onClose={handleClose}>
            <AddVariableDialogTitleStyled>
                {title}
            </AddVariableDialogTitleStyled>

            <AddVariableDialogContentStyled>
                <Typography>{text}</Typography>
            </AddVariableDialogContentStyled>

            <AddVariableDialogActionsStyled>
                <Button variant={"text"} onClick={handleClose}>{'Cancel'}</Button>
                <LoadingButton loading={isLoading} variant={"contained"} onClick={onConfirm}>{'Resend invite'}</LoadingButton>
            </AddVariableDialogActionsStyled>
        </SmallSizeDialogStyled>
    )
}
