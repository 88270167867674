import {styled} from "@mui/styles";

export const PreviewTemplateContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    // marginTop: '16px',
    alignItems: 'center',
    maxWidth: '420px',
    margin: '0 auto'
})

export const FileDropContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
})
