import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideChangeEmploymentStatusDialog, hrSettings, loadings, selectedEmployee} from "../../../store/slice";
import React, {useEffect, useState} from "react";
import {TEmployeeStatus} from "../../../types";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import {DialogContentWrapper} from "../../styled";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {UpdateEmployeeStatus} from "../../../store/actions";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../../../../newShared/components/Layouts";

export const ChangeEmploymentStatusDialog = () => {
    const dispatch = useDispatch();

    //sel
    const isOpen = useSelector(dialogs).changeEmploymentStatusDialog;
    const isLoading = useSelector(loadings).updateStatus;
    const empl = useSelector(selectedEmployee);
    const {status} = useSelector(hrSettings);

    const handleClose = () => {
        dispatch(hideChangeEmploymentStatusDialog())
    }

    const [selectedStatus, setSelectedStatus] = useState<TEmployeeStatus | null>(null);

    const handleSubmit = () => {
        if(isOk()){
            dispatch(UpdateEmployeeStatus({data: {id: empl!.id, status: selectedStatus!}}));
        }
    }

    useEffect(() => {
        if(isOpen && empl){
            setSelectedStatus(empl.status);
        }else if(!isOpen){
            setSelectedStatus(null)
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const isOk = (): boolean => {
        return selectedStatus !== null && empl !== null && selectedStatus.id !== empl.status?.id;
    }

    const {t} = useMainTranslation('', {keyPrefix: 'pathHRManagement.dialogs'});
    const {tCommon} = useMainTranslation();

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Change employment status')}</DialogTitle>

            <DialogContent>
                <DialogContentWrapper>
                    <Autocomplete
                        options={status}
                        getOptionLabel={(o) => o.name ?? ''}
                        value={selectedStatus}
                        onChange={(e, newValue) => setSelectedStatus(newValue)}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        renderInput={(params) => <TextField {...params} label={t('Employment status')} size={'small'} required/>}
                    />
                </DialogContentWrapper>

                {selectedStatus && empl && selectedStatus.name === 'Terminated' && selectedStatus.id !== empl.status?.id &&
                    <Flex m={'16px 0 0 0'}>
                        <Typography variant={'*bodyText1'}>{'All subordinates will be left without a manager'}</Typography>
                    </Flex>
                }
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{tCommon('Close')}</Button>
                <LoadingButton
                    onClick={handleSubmit}
                    loading={isLoading}
                    disabled={!isOk()}
                >
                    {tCommon('Change')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}