import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideErrorReasonDialog} from "../../../store/slice";

export const usePreviewErrorReason = () => {
    //root
    const dispatch = useDispatch();
    //selectors
    const {errorReason} = useSelector(dialogs);

    //actions
    const handleClose = () => {
        dispatch(hideErrorReasonDialog())
    }

    return{
        isOpen: errorReason.isOpen,
        history: errorReason.history,
        handleClose
    }
}