import React, {FC, useEffect, useState} from "react";
import {Flex} from "../../../../../newShared/components/Layouts";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useAssetNew} from "../../hooks/useAssetNew";

//icons
//other
import {Button, MenuItem, Skeleton, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {CreateSettingRowDialog} from "../../components/Dialogs/CreateSettingRowDialog";
import {AddMenuItem} from "../../../../../newShared/components/Basic/AddMenuItem";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {TCreateField} from "../../types";
import {AutocompleteCollaborators} from "../../../../../newShared/components/AutocompleteCollaborators";
import {optionIsTCollaborator} from "../../../../../newShared/components/AutocompleteCollaborators/types";
import {getName} from "../../../../../newShared/utils/text";
import {uuid} from "../../../../../newShared/utils";


export const AssetNew: FC = () => {
    const {isMobile} = useMedia();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});

    const {
        isOk,
        isOkId,
        settings,
        form,
        handleChange,

        handleSave,
        handleGoBack,
        handleSetModel,
        handleSetCategory,
        handleSetManufacture,
        handleSetStatus,
        handleSetManager,
        handleChangeCustomField,
        onSuccessCreateSetting,

        isLoadingSettings,
        isLoadingCreateAsset,

        openCreateSettingRowDialog
    } = useAssetNew();

    const [uniqueInputId, setUniqueInputId] = useState<string>(uuid());
    useEffect(() => {
        setUniqueInputId(uuid())
    }, [form.status.status]);

    return (
        <Flex w={'100%'} h={'100%'} direction={'column'}>

            <PageTitleAndActions
                title={t('New physical asset')}
                handleGoBack={handleGoBack}
                actions={[]}
            />


            <Flex ai={'center'} m={'20px 0 0 0'}>

                <LoadingButton size={"large"} component={"label"}
                               variant={'contained'}
                               disabled={isLoadingCreateAsset || !isOkId() || !isOk()}
                               onClick={handleSave} loadingPosition={isLoadingCreateAsset ? 'start' : undefined} loading={isLoadingCreateAsset}
                               startIcon={isLoadingCreateAsset ? <div style={{width: '10px', height: '10px', }}/> : undefined}
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                >
                    {t('Save')}
                </LoadingButton>

                <Button disabled={isLoadingCreateAsset} variant={'outlined'} size={isMobile ? 'small' : 'medium'} onClick={handleGoBack} >
                    {t('Cancel')}
                </Button>
            </Flex>

            <Flex direction={"column"}  w={'100%'} overflowy={'auto'} m={'10px 0 0 0'} >
                {isLoadingSettings ?
                    <>
                        <Skeleton width={'300px'} height={'40px'} />
                        <Skeleton width={'300px'} height={'40px'} />
                        <Skeleton width={'300px'} height={'40px'} />
                        <Skeleton width={'300px'} height={'40px'} />
                        <Skeleton width={'300px'} height={'40px'} />
                        <Skeleton width={'300px'} height={'40px'} />
                        <Skeleton width={'300px'} height={'40px'} />

                    </>
                    :
                    <>
                        <TextField label={t('ID')} size={"small"} error={!isOkId()}
                                   helperText={!isOkId() ? 'cannot contain special characters or spaces' : undefined}
                                   sx={{mt: '6px', width: '320px', '& label': {top: '0 !important'}}} value={form.assetId} onChange={handleChange} name={'assetId'} />

                        <TextField label={t('Serial')} value={form.serial} size={'small'} required
                                   sx={{mt: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                   onChange={handleChange} name={'serial'} />

                        {/*/!*MODEL*!/*/}
                        <TextField select fullWidth label={t('Model')} value={form.model} size={'small'} required
                                   sx={{mt: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                   onChange={(e) => handleSetModel(e.target.value)}
                        >
                            <AddMenuItem text={t('Add new')} onClick={() => openCreateSettingRowDialog({isOpen: true, field: 'model', assetType: 'physical'})} />
                            { settings && settings.model.map(({name}) => <MenuItem key={name} value={name}>{name}</MenuItem>)}
                        </TextField>

                        {/*/!*Category*!/*/}
                        <TextField select fullWidth label={t('Category')} value={form.category} size={'small'}
                                   required
                                   sx={{mt: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                   onChange={(e) => handleSetCategory(e.target.value)}
                        >
                            <AddMenuItem text={t('Add new')} onClick={() => openCreateSettingRowDialog({isOpen: true, field: 'category', assetType: 'physical'})} />
                            { settings && settings.category.map(({name}) => <MenuItem key={name} value={name}>{name}</MenuItem>)}
                        </TextField>

                        {/*/!*Manufacture*!/*/}
                        <TextField select fullWidth label={t('Manufacture')} value={form.manufacture} size={'small'}
                                   required
                                   sx={{mt: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                   onChange={(e) => handleSetManufacture(e.target.value)}
                        >
                            <AddMenuItem text={t('Add new')} onClick={() => openCreateSettingRowDialog({isOpen: true, field: 'manufacture', assetType: 'physical'})} />
                            { settings && settings.manufacture.map(({name}) => <MenuItem key={name} value={name}>{name}</MenuItem>)}
                        </TextField>

                        {/*/!*Status*!/*/}
                        <TextField select fullWidth label={t('Status')} value={form.status.name} size={'small'}
                                   required
                                   sx={{mt: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                   onChange={(e) => handleSetStatus(e.target.value)}
                        >
                            <AddMenuItem text={t('Add new')} onClick={() => openCreateSettingRowDialog({isOpen: true, field: 'status', assetType: 'physical'})} />
                            { settings && settings.status.map(({name}) => <MenuItem key={name} value={name}>{name}</MenuItem>)}
                        </TextField>

                        <AutocompleteCollaborators
                            key={uniqueInputId}
                            label={t('Manager')}
                            getOptionLabelCustom={"name+email"}
                            getOptionLabel={(option) => optionIsTCollaborator(option) ? getName(option.firstName || '', option.lastName || '') : option}
                            sx={{marginTop: '18px', width: '320px', '& label': {top: '0px !important'}}}

                            disabled={settings?.status.find(e => e.name === form.status.name)?.status.toLowerCase() !== 'deployed'}
                            defaultValue={null}
                            onChange={value => {
                                if (!value) handleSetManager(null)
                                else if (optionIsTCollaborator(value)) {
                                    handleSetManager(value)
                                }
                            }}
                        />

                        {(settings && settings.customFields.length > 0) &&
                            settings.customFields.map((e) =>
                                <TextField key={e} size={'small'} label={e} value={form.customFields[e] || ''} name={e}
                                           onChange={handleChangeCustomField} sx={{marginTop: '16px', width: '320px', '& label': {top: '0px !important'}}}
                                />
                        )}
                    </>
                }

            </Flex>

            <CreateSettingRowDialog onSuccess={(title: string, field?: TCreateField) => onSuccessCreateSetting(title, field)} />

        </Flex>
    )
}
