import {useDispatch, useSelector} from "react-redux";
import {assetsDialogsSelector, hideReleaseDialog, loadings} from "../../store/store";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    releaseCertificateAssetsAction,
    releasePhysicalAssetsAction,
    releaseSaasAssetsAction,
    releaseSoftwareAssetsAction,
    releaseVirtualAssetsAction
} from "../../store/actions";
import {TReleaseDialogEmployee} from "../../../../../newShared/components/releaseDialog/types";


export const useReleasePhysicalAssets = () => {
    const dispatch = useDispatch();
    const {setMessage} = useMessageDialog();
    const {tCommon} = useMainTranslation();
    const {releaseDialog: {isOpen, assetType}} = useSelector(assetsDialogsSelector);
    const {releasePhysicalAssets: isLoadingRelease} = useSelector(loadings);

    const handleRelease = ({frameworkId, controlId, evidenceId, readers, reviewers, signers}: {
        frameworkId: string, controlId: string, evidenceId: string,
        reviewers: TReleaseDialogEmployee[], readers: TReleaseDialogEmployee[], signers: TReleaseDialogEmployee[]
    }) => {
        assetType === 'physical' && dispatch(releasePhysicalAssetsAction({data: {workspaceId: '', organizationId: '', frameworkId, controlId, evidenceId, readers, reviewers, signers}, onSuccess}));
        assetType === 'software' && dispatch(releaseSoftwareAssetsAction({data: {workspaceId: '', organizationId: '', frameworkId, controlId, evidenceId, readers, reviewers, signers}, onSuccess}));
        assetType === 'virtual' && dispatch(releaseVirtualAssetsAction({data: {workspaceId: '', organizationId: '', frameworkId, controlId, evidenceId, readers, reviewers, signers}, onSuccess}));
        assetType === 'saas' && dispatch(releaseSaasAssetsAction({data: {workspaceId: '', organizationId: '', frameworkId, controlId, evidenceId, readers, reviewers, signers}, onSuccess}));
        assetType === 'certificate' && dispatch(releaseCertificateAssetsAction({data: {workspaceId: '', organizationId: '', frameworkId, controlId, evidenceId, readers, reviewers, signers}, onSuccess}));
    }

    const onSuccess = () => {
        setMessage({title: tCommon('Completed successfully'), message: tCommon('Automation created!')});
    }

    const handleClose = () => {
        dispatch(hideReleaseDialog());
    }


    return{
        isOpen,
        isLoadingRelease,
        handleClose,
        handleRelease,
    }
}