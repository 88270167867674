import {useDispatch, useSelector} from "react-redux";
import {deleteEvidenceDialog, hideDeleteEvidence, isLoading} from "../../../store/builder/slice";
import {DeleteEvidence} from "../../../store/builder/actions";


export const useDeleteEvidence = () => {
    const dispatch = useDispatch();
    const {isOpen, evidenceId} = useSelector(deleteEvidenceDialog);
    const _isLoading = useSelector(isLoading);


    const handleClose = () => {
        dispatch(hideDeleteEvidence());
    }

    const handleConfirm = () => {
        evidenceId && dispatch(DeleteEvidence(evidenceId));
    }

    return {
        isOpen,
        isLoading: _isLoading,
        handleClose,
        handleConfirm,
    }

}