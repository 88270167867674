import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useCurrentFramework} from "../../../hooks/useCurrentFramework";
import {useFrameworksDialog} from "../../../hooks/useFrameworksDialog";
import {useThreeDotsMenu} from "../../../../../../newShared/hooks/useThreeDotsMenu";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    PATH_LOCAL_COMPLIANCE_FRAMEWORKS,
    PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED
} from "../../../../../../newShared/constants";
import {useFrameworks} from "../../../hooks/useFrameworks";
import {
    useGenericFiltersStorage
} from "../../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    cleanArrayAction,
    controlsMaxLoadedPage,
    controlsMinLoadedPage,
    replaceMinMaxLoadedPage
} from "../../../store/currentFramework/store";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../../newShared/components/genericTable/types";

export const useFrameworkViewPage = () => {
    const dispatch = useDispatch();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    const {
        frId, framework, controls,
        pageInfo: {page, count, total},
        isLoading: {isControlsLoading, isFrameworkZipDownloading},
        getFrameworkZip,
        getData, openControl,
    } = useCurrentFramework();
    const {generateReport} = useFrameworks();
    const {clearFilters} = useGenericFiltersStorage();
    const _minLoadedPage = useSelector(controlsMinLoadedPage);
    const _maxLoadedPage = useSelector(controlsMaxLoadedPage);


    useEffect(() => {
        return () => {
            clearFilters();
        }
        //eslint-disable-next-line
    }, [])
    // const {filter} = useParameters();

    const {
        removeFramework: {setRemoveFrameworkDialog}
    } = useFrameworksDialog();


    ///////
    const {
        anchorThreeDotsEl, handleThreeDotsOpen, handleThreeDotsClose,
    } = useThreeDotsMenu();

    //Breadcrumbs
    useSetBreadcrumbs([
        {
            title: tMenu('Frameworks'),
            path: PATH_LOCAL_COMPLIANCE_FRAMEWORKS
        },
        {
            title: tMenu('Adopted frameworks'),
            path: PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED
        },
        {
            title: framework?.name || 'Loading framework...',
        },
    ]);

    //Pagination and filtering
    // const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
    //     getData: ({page, count}) => {
    //         frId && getData(frId, {
    //             page, count, filters: {}
    //         }, frId !== framework?.id)
    //     },
    //     arrayLength: controls.length,
    //     isLoading: isControlsLoading,
    //     pageInfo: {page, count, total},
    //     cleanData: clean,
    //     // controller: controller,
    //     // isOnTop: true,
    //     isNoMoreThanTotal: true,
    //     customDependencies: [frId],
    //     is0PageAddictive: frId !== framework?.id,
    // })

    // const {value: searchValue, handleChange} = useSearchFieldURL({wait: 0});
    // const {setFilter, handleKeyDown} = useFilterUrl({
    //     getData: (filters) => {frId && getData(frId, {page: 0, count, filters}, true)}
    // });
    //
    // const fetchByFilters = (search: string) => {
    //     frId && getData(frId, {page: 0, count: 10, filters: {input: [search]}}, true);
    // }

    // const genericTableConfig: GenericPageBeautyPropsType<TControl, undefined> = {
    //     id: 'frameworkView',
    //     columnsConfig: {totalName: 'Total controls'},
    //     paging: {
    //         pageInfo: {page, count, total},
    //         isLoading: isControlsLoading,
    //
    //     }
    // }

    return {
        frId,
        framework, controls,
        openControl, generateReport,
        getFrameworkZip,
        setRemoveFrameworkDialog,
        isControlsLoading, isFrameworkZipDownloading,
        // searchValue, handleChange, handleKeyDown, setFilter,
        handleThreeDotsOpen, handleThreeDotsClose, anchorThreeDotsEl,
        // onScroll, scrollRef, bottomLoader: bottomLoader && ((controls.length < total) || (controls.length === total && total === 0)),
        // fetchByFilters,
        genericTable: {
            paging: {
                pageInfo: {total, page, count},
                isLoading: isControlsLoading,
                minLoadedPage: _minLoadedPage,
                maxLoadedPage: _maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceMinMaxLoadedPage(data)),
                clearRows: () => dispatch(cleanArrayAction()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    frId && getData(frId, {page, count, filters: {input: [search]}}, false);
                    // dispatch(GetEmployees({data: {pageInfo: {page, count, filters}, search}}));
                }
            }
        }
    }
}