import React, {FC, Fragment, RefObject, useCallback, useEffect, useRef, useState} from "react";
import {TrainingExamQuestionInput, TrainingExamQuestionType} from "../../../../../../../../newShared/GQLTypes";
import {
    FlexColumn,
    FlexRow
} from "../../../../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import {Collapse, Divider, Icon, IconButton, MenuItem, TextField, Typography} from "@mui/material";
import {
    CheckBox,
    ContentCopy,
    Delete,
    DragIndicator,
    InfoOutlined,
    Notes,
    RadioButtonChecked,
    Title
} from "@mui/icons-material";
import colors from "../../../../../../../../newShared/theme/colors";
import {FormikErrors, move} from "formik";
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {collapseAllQuestionsEventName, expandAllQuestionsEventName, handleMinMaxStep} from "../../../../../helpers";
import {
    MainTrainingExamFormik,
    MainTrainingExamFormikQuestion,
    MainTrainingExamFormikQuestionOption
} from "../../../../../types";
import {ExamViewQuestionCheckboxesRows} from "../examViewQuestionCheckboxesRows";
import {ExamViewQuestionRadioRows} from "../examViewQuestionRadioRows";
import {TextFieldWithHelperPopover} from "../../../../../../../../newShared/components/textFieldWithPopover";
import {useCustomFormik} from "../../../../../../../../newShared/hooks/useCustomFormik";
import {DragSourceMonitor, useDrag, useDrop} from "react-dnd";
import {createExamQuestionOption, QUESTION_DND_TYPE} from "../../../../../constants";
import {SectionArrow} from "../../../../../../../../newShared/components/SectionArrow";

type ExamViewQuestionRowProps = {
    questionIndex: number;
    masterFormik: ReturnType<typeof useCustomFormik<MainTrainingExamFormik>>;

    handleDeleteQuestionRef: RefObject<(questionId: string) => void>;
    moveSectionRef: RefObject<(id: string, toId: string) => void>;
    handleCopyQuestion: () => void;
}
export const ExamViewQuestionRow: FC<ExamViewQuestionRowProps> = React.memo(({masterFormik: formik, questionIndex, handleCopyQuestion, moveSectionRef, handleDeleteQuestionRef}) => {
    const {t} = useMainTranslation('', {keyPrefix: ''});

    const [open, setOpen] = useState(formik.values.questions[questionIndex]?.questionId.startsWith('new'));

    const row = formik.values.questions[questionIndex];
    const errors = formik.errors.questions?.[questionIndex];
    const touched = formik.touched.questions?.[questionIndex];

    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: QUESTION_DND_TYPE,
            item: {dragId: row.questionId},
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [row.questionId, moveSectionRef],
    )

    const [{canDrop}, drop] = useDrop(
        () => ({
            accept: QUESTION_DND_TYPE,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                isActive: monitor.canDrop() && monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
            hover: ({dragId}: { dragId: string }) => {
                moveSectionRef.current?.(dragId, row.questionId);
            },
        }),
        [moveSectionRef],
    )

    const mainError = errors;
    const getErrorField = (field: keyof FormikErrors<TrainingExamQuestionInput>) => {
        const error = errors;
        if (typeof error === 'object') {
            return error?.[field];
        } else {
            return undefined;
        }
    };

    const handleTypeChangeType = (type: TrainingExamQuestionType) => {
        if (type === row.type) return;

        formik.setFieldValue(`questions[${questionIndex}]`, {
            type,
            options: type === TrainingExamQuestionType.Checkboxes || type === TrainingExamQuestionType.MultipleChoice ? row.options.map((opt, index, array) => {
                return {
                    optionId: opt.optionId,
                    option: opt.option,
                    order: index,
                    points: type === TrainingExamQuestionType.Checkboxes && opt.correct ? 5 : 0,
                    correct: opt.correct && array.findIndex(e => e.correct) === index,
                }
            }) : [],

            question: row.question,
            description: row.description,
            points: row.points || 5,
            pointsAutoCount: type === TrainingExamQuestionType.Checkboxes,

            questionId: row.questionId,
            order: row.order,
        } satisfies MainTrainingExamFormikQuestion);
    };

    // const setPartiallyChecked = (countPartially: boolean) => {
    //     formik.setFieldValue(`questions[${questionIndex}]`, {
    //         ...row,
    //         pointsAutoCount: countPartially,
    //         points: countPartially ? 0 : 5,
    //         options: row.options.map(opt => ({
    //             ...opt,
    //             points: opt.correct && countPartially ? 5 : 0,
    //         })),
    //     })
    // };

    const handleAddOption = () => {
        const newIndex = row.options.length;
        formik.setFieldValue(`questions[${questionIndex}]options[${newIndex}]`, createExamQuestionOption(newIndex), true);
        setTimeout(() => {
            const el = document.getElementById(`questions[${questionIndex}]options[${newIndex}]option`);
            el?.focus();
        }, 50);
    };

    const moveOption = useCallback((dragId: string, toId: string) => {
        const from = row.options.findIndex((option) => option.optionId === dragId);
        const to = row.options.findIndex((option) => option.optionId === toId);

        if (from === -1 || to === -1) return;

        const newArray = move(row.options, from, to) as MainTrainingExamFormikQuestionOption[];
        formik.setFieldValue(`questions[${questionIndex}]options`, newArray);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [row.options, questionIndex]);

    const handleRemoveOption = useCallback((optionId: string) => {
        formik.setFieldValue(`questions[${questionIndex}]options`, row.options.filter((option) => option.optionId !== optionId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [row.options, questionIndex]);

    const sumPoints = Number(row.pointsAutoCount) && row.options.reduce((acc, option) => acc + (option.correct ? option.points : 0), 0);
    useEffect(() => {
        if (row.pointsAutoCount) {
            formik.setFieldValue(`questions[${questionIndex}]points`, sumPoints)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sumPoints, row.pointsAutoCount]);

    useEffect(() => {
        const expandAll = (ev: Event) => {
            const {detail: {indexes}} = ev as CustomEvent<{indexes: number[]}>
            setOpen(!indexes.length || indexes.includes(questionIndex))
        };
        const collapseAll = (ev: Event) => {setOpen(false)};

        document.addEventListener(expandAllQuestionsEventName, expandAll);
        document.addEventListener(collapseAllQuestionsEventName, collapseAll);

        return () => {
            document.removeEventListener(expandAllQuestionsEventName, expandAll);
            document.removeEventListener(collapseAllQuestionsEventName, collapseAll);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionIndex]);

    const opacity = isDragging ? 0 : 1;

    const moveOptionRef = useRef(moveOption);
    useEffect(() => {
        moveOptionRef.current = moveOption;
    }, [moveOption]);

    const handleRemoveOptionRef = useRef(handleRemoveOption);
    useEffect(() => {
        handleRemoveOptionRef.current = handleRemoveOption;
    }, [handleRemoveOption]);

    return row && (
        <FlexColumn sx={{
            width: '100%', overflow: 'hidden', border: `1px solid ${colors.stroke.grey}`, borderRadius: '4px', flexShrink: 0,
            opacity: opacity,
        }}
                    id={`question-${row.questionId}`}
                    ref={drop}
        >
            <FlexRow onClick={() => setOpen(prev => !prev)}
                     sx={{
                         alignItems: 'center',
                         backgroundColor: colors.backgrounds.grey_light,
                         cursor: 'pointer',
                         padding: '10px',
                         gap: '8px',
                         overflow: 'hidden',
                     }}
                     ref={preview}
            >
                <SectionArrow isOpen={open} />

                <Typography variant={'*bodyText1_semibold'} color={colors.text.dark}>
                    {`Question`}
                </Typography>
                <Typography variant={'*bodyText2'} color={colors.text.grey_dark} sx={{flexGrow: 1}} noWrap>
                    {row.question || ``}
                </Typography>

                {mainError &&
                    <InfoOutlined sx={{width:20, height: 20, color: colors.text.critical}} />
                }

                {[
                    {key: 'copy', onClick: handleCopyQuestion, icon: <ContentCopy/>},
                    {key: 'delete', onClick: () => handleDeleteQuestionRef.current?.(row.questionId), icon: <Delete/>},
                ].map((action) => (
                    <Fragment key={action.key}>
                        <Divider flexItem variant={"middle"} orientation={"vertical"}/>
                        <IconButton size={'small'} onClick={e => {e.stopPropagation(); action.onClick()}}>
                            {action.icon}
                        </IconButton>
                    </Fragment>
                ))}

                <Divider flexItem variant={"middle"} orientation={"vertical"}/>
                <Icon ref={drag}>
                    <DragIndicator/>
                </Icon>
            </FlexRow>

            <Collapse in={open && !canDrop} sx={{flexShrink: 0}} unmountOnExit mountOnEnter>
                <FlexColumn sx={{width: '100%', padding: '16px', borderTop: `1px solid ${colors.stroke.grey}`, gap: '16px'}}>
                    <TextField name={`questions[${questionIndex}]description`}
                               label={t('Description')}
                               variant={'outlined'}
                               fullWidth
                               value={row.description}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               error={touched?.description && !!getErrorField('description')}
                               helperText={touched?.description && getErrorField('description')}
                               multiline
                               rows={4}
                    />

                    <FlexRow sx={{gap: '16px', flexShrink: 0}}>
                        <TextField name={`questions[${questionIndex}]question`}
                                   label={t('Question')}
                                   variant={'outlined'}
                                   size={"small"}
                                   value={row.question}
                                   onChange={formik.handleChange}
                                   onBlur={formik.handleBlur}
                                   error={touched?.question && !!getErrorField('question')}
                                   helperText={touched?.question && getErrorField('question')}
                                   required
                                   sx={{flexGrow: 1}}
                                   fullWidth={false}
                        />

                        <TextField name={`questions[${questionIndex}]type`}
                                   variant={'outlined'}
                                   size={"small"}
                                   value={row.type}
                                   onChange={e => handleTypeChangeType(e.target.value as TrainingExamQuestionType)}
                                   onBlur={formik.handleBlur}
                                   error={touched?.type && !!getErrorField('type')}
                                   helperText={touched?.type && getErrorField('type')}
                                   fullWidth={false}
                                   sx={{
                                       '& .MuiOutlinedInput-root': {
                                           width: 'fit-content'
                                       },
                                   }}
                                   // sx={{flexBasis: '25%'}}
                                   select
                        >
                            {[
                                {value: TrainingExamQuestionType.Checkboxes, title: t('Checkboxes'), Icon: CheckBox},
                                {value: TrainingExamQuestionType.MultipleChoice, title: t('Multiple choice'), Icon: RadioButtonChecked},
                                {value: TrainingExamQuestionType.Paragraph, title: t('Paragraph'), Icon: Notes},
                                {value: TrainingExamQuestionType.ShortAnswer, title: t('Short answer'), Icon: Title},
                            ].map(({value, title, Icon}) => (
                                <MenuItem key={value} value={value} sx={{paddingBlock: '8px'}}>
                                    <FlexRow gap={'8px'} alignItems={'center'}>
                                        <Icon htmlColor={colors.text.grey_dark}/>
                                        {title}
                                    </FlexRow>
                                </MenuItem>
                            ))}
                        </TextField>
                    </FlexRow>

                    <FlexRow sx={{flexShrink: 0, alignItems: 'center', gap: '8px'}}>
                        <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>
                            {t('Number of points for the correct answer')}
                        </Typography>
                        <TextFieldWithHelperPopover name={`questions[${questionIndex}]points`}
                                                    variant={'outlined'}
                                                    size={"small"}
                                                    value={row.points}
                                                    onChange={handleMinMaxStep(1, undefined, 1, formik.handleChange)}
                                                    onBlur={formik.handleBlur}
                                                    error={touched?.points && !!getErrorField('points')}
                                                    helperText={touched?.points && getErrorField('points')}
                                                    sx={{width: '70px'}}
                                                    type={'number'}
                                                    disabled={row.pointsAutoCount}
                                                    InputProps={{inputProps: {min: 5, step: 5}}}
                        />
                        <Typography variant={'*bodyText2'} color={colors.text.dark}>
                            {t('points')}
                        </Typography>
                    </FlexRow>

                    {/*{row.type === TrainingExamQuestionType.Checkboxes &&*/}
                    {/*    <FlexRow sx={{flexShrink: 0, alignItems: 'center', gap: '8px', mt: '-8px'}}>*/}
                    {/*        <FormControlLabel*/}
                    {/*            checked={row.pointsAutoCount}*/}
                    {/*            control={<Checkbox disableRipple sx={{p: '0 9px'}}/>}*/}
                    {/*            onChange={(_, checked) => setPartiallyChecked(checked)}*/}
                    {/*            label={*/}
                    {/*                <Typography variant={'*bodyText2'} color={colors.text.dark}>*/}
                    {/*                    {t('Count partially')}*/}
                    {/*                </Typography>*/}
                    {/*            }*/}
                    {/*        />*/}
                    {/*    </FlexRow>*/}
                    {/*}*/}

                    <Divider flexItem/>

                    {(row.type === TrainingExamQuestionType.ShortAnswer &&
                        <Typography variant={'*bodyText2'} color={colors.text.grey_dark}>
                            {t('By choosing answer type “Short text” students will see an input, where they are expected to provide a short response to your question.')}
                        </Typography>
                    )}

                    {(row.type === TrainingExamQuestionType.Paragraph &&
                        <Typography variant={'*bodyText2'} color={colors.text.grey_dark}>
                            {t('By choosing answer type “Long text” students will see a text box, where they are expected to provide a detailed response to your question. ')}
                        </Typography>
                    )}

                    {(row.type === TrainingExamQuestionType.Checkboxes &&
                        <ExamViewQuestionCheckboxesRows questionIndex={questionIndex} masterFormik={formik} handleAddOption={handleAddOption} moveOptionRef={moveOptionRef} handleRemoveOptionRef={handleRemoveOptionRef}/>
                    )}

                    {(row.type === TrainingExamQuestionType.MultipleChoice &&
                        <ExamViewQuestionRadioRows questionIndex={questionIndex} masterFormik={formik} handleAddOption={handleAddOption} moveOptionRef={moveOptionRef} handleRemoveOptionRef={handleRemoveOptionRef}/>
                    )}
                </FlexColumn>
            </Collapse>
        </FlexColumn>
    )}, (prevProps, nextProps) => (
    prevProps.questionIndex === nextProps.questionIndex
    && prevProps.handleDeleteQuestionRef === nextProps.handleDeleteQuestionRef
    && prevProps.moveSectionRef === nextProps.moveSectionRef

    && JSON.stringify(prevProps.masterFormik.values.questions[prevProps.questionIndex]) === JSON.stringify(nextProps.masterFormik.values.questions[nextProps.questionIndex])
    && JSON.stringify(prevProps.masterFormik.errors.questions?.[prevProps.questionIndex]) === JSON.stringify(nextProps.masterFormik.errors.questions?.[nextProps.questionIndex])
    && JSON.stringify(prevProps.masterFormik.touched.questions?.[prevProps.questionIndex]) === JSON.stringify(nextProps.masterFormik.touched.questions?.[nextProps.questionIndex])

))
