import {useDispatch, useSelector} from "react-redux";
import {
    dialogs,
    eraseSelectedTemplate,
    setTemplatePreviewAction,
    setTrainingPreviewAction,
    templatesSelector
} from "../../../store/slice";
import {useEffect, useState} from "react";
import {mapTemplateToTrainingType} from "../../../helpers";

export const useTrainingPreview = () => {
    //root
    // const {t} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.dialogs'});
    const dispatch = useDispatch();

    const {
        trainingPreview: {isOpen, training},
        templatePreview,
    } = useSelector(dialogs);
    // const {templateExact} = useSelector(loadings);
    const  {selectedTemplate} = useSelector(templatesSelector);
    const [openedSection, setOpenedSection] = useState<string>('');

    //actions
    const handleClose = () => {
        dispatch(setTrainingPreviewAction({clear: true}));
        dispatch(setTemplatePreviewAction({clear: true}));
    }

    const handleToggleSection = (sectionId: string) => {
        if (openedSection === sectionId ) { setOpenedSection('')}
        else setOpenedSection(sectionId);

    }

    useEffect(() => {
        isOpen && setOpenedSection('');

        return () => {
            dispatch(eraseSelectedTemplate());
        }
        //eslint-disable-next-line
    }, [isOpen]);


    return{
        isOpen: isOpen || templatePreview.isOpen,
        training: training ? training : selectedTemplate ? mapTemplateToTrainingType(selectedTemplate) : null,
        openedSection,
        handleToggleSection,
        handleClose,
    }
}
