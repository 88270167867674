import {
    DelegatedTaskModel,
    FrameworksDelegatedTaskDataAttachedFilesFileModel,
} from "../../../../../../../../../newShared/GQLTypes";
import {
    useMainTranslation
} from "../../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, Typography} from "@mui/material";
import colors from "../../../../../../../../../newShared/theme/colors";
import {Flex} from "../../../../../../../../../newShared/components/Layouts";
import {ReactComponent as FileIcon} from "../../../../../../../../../newShared/images/documents/document-icon.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Divider from "@mui/material/Divider";
import React from "react";
import {TypographyNew} from "../../../../../../../../../newShared/components/TypographyNew";


export const PreviewAllFilesDialog = ({task, handlePreviewFile, handleDownloadFile, handleClose, isOpen, attachments, isEvidenceAttachments}: {
    task: DelegatedTaskModel | null,
    isOpen: boolean,
    handleClose: () => void,
    attachments: FrameworksDelegatedTaskDataAttachedFilesFileModel[],
    handlePreviewFile: (file: FrameworksDelegatedTaskDataAttachedFilesFileModel) => void,
    handleDownloadFile:(file: FrameworksDelegatedTaskDataAttachedFilesFileModel) => void,
    isEvidenceAttachments: boolean
}) => {
    const {tCommon} = useMainTranslation();
    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{(`Delegated task ${task?.id}`)}</DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column'}}>
                <Typography variant={'*bodyText2_semibold'} color={colors.grayText} margin={'0 0 8px 0'}>{
                    isEvidenceAttachments ? ('Files added to the evidence') : ('Attached files')
                }</Typography>

                <Flex direction={'column'} overflow={'auto'} grow={'1'}>
                    {(isEvidenceAttachments ? attachments.filter(e => e.addedToEvidence) : attachments).map((e, id, arr) => {
                        return(
                            <>
                                <Flex key={e.fileId} ai={'center'} jc={'space-between'} w={'100%'} p={'4px 0'}>
                                    <Flex gap={'10px'} ai={'center'} overflow={'hidden'}>
                                        <FileIcon style={{flexShrink: 0}}/>
                                        <TypographyNew variant={'body2'} noWrap>{e.fileName}</TypographyNew>
                                    </Flex>

                                    <Flex gap={'12px'} ai={'center'} shrink={'0'}>
                                        {e.fileName.endsWith('.pdf') ? (
                                            <IconButton size={'small'} onClick={() => handlePreviewFile(attachments[0])} color={'primary'}>
                                                <VisibilityIcon/>
                                            </IconButton>
                                        ) : (
                                            <Icon/>
                                        )}

                                        <IconButton size={'small'} onClick={() => handleDownloadFile(e)} color={'primary'}>
                                            <FileDownloadIcon />
                                        </IconButton>
                                    </Flex>
                                </Flex>
                                {id !== arr.length - 1 && <Divider />}
                            </>
                        )
                    })}
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button variant={'text'} size={'small'} onClick={handleClose}>{tCommon('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}