import {useTrainingsDialogs} from "../../useTrainingsDialogs";
import {useDispatch, useSelector} from "react-redux";
import {examsSelector, loadings} from "../../../store/slice";
import {useCustomFormik} from "../../../../../../newShared/hooks/useCustomFormik";
import {
    MainTrainingExamInput,
    MainTrainingExamStatus,
    TrainingExamLevel,
    TrainingExamQuestionType
} from "../../../../../../newShared/GQLTypes";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {getName} from "../../../../../../newShared/utils/text";
import {createExamSchema} from "./validationSchemas";
import {MainTrainingExamFormik} from "../../../types";
import {uuid} from "../../../../../../newShared/utils";
import {useHistory} from "react-router";
import {PATH_TRAININGS_EXAMS} from "../../../constants";
import {useState} from "react";
import {mainTrainingsCreateExamAction, mainTrainingsUpdateExamAction} from "../../../store/actions";
import {useParams} from "react-router-dom";
import {ActionErrorType} from "../../../../../../newShared/utils/asyncThunk/types";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {createEventExpandAllQuestions} from "../../../helpers";

export const useCreateExamDialog = () => {
    // const {t, tCommon} = useMainTranslation();
    const {currData: {currentUser}} = UseManageWorkspacesAndOrganizations();

    const dispatch = useDispatch();
    const history = useHistory();
    // const snack = useSnack();
    const {setMessage} = useMessageDialog();
    const {examId} = useParams<{examId?: string}>();

    const {isOpen, handleClose: handleCloseDialog} = useTrainingsDialogs().createExam;
    const {selectedExam} = useSelector(examsSelector);
    const {examCreate: isCreating, examUpdate: isUpdating} = useSelector(loadings);

    const [dontSaveDialog, setDontSaveDialog] = useState<boolean>(false);

    const goToExams = () => history.push(PATH_TRAININGS_EXAMS);

    const confirmDontSaveDialog = () => {
        setDontSaveDialog(false);
        handleCloseDialog();
        !examId && goToExams();
    }

    const handleClose = () => {
        if (!formik.dirty) confirmDontSaveDialog()
        else setDontSaveDialog(true);
    };

    const {handleSet: handleSetExamPreview} = useTrainingsDialogs().examPreview;
    const handlePreview = () => {handleSetExamPreview({exam: formik.values})};

    const onError: ActionErrorType<typeof mainTrainingsCreateExamAction | typeof mainTrainingsUpdateExamAction> = (request, error, addictiveData) => {
        const e404 = error.e404?.[0];
        const e409 = error.e409?.[0];

        if (e404) {
            setMessage({title: 'Not found', message: `Exam with id "${request.exam.examId}" not found.`});
            goToExams();
            return;
        }

        switch (e409?.type) {
            case 'DUPLICATE_NAME': {
                // const id = e409?.values.find(e => e.parameter === 'id')?.value || '';
                const name = e409?.values.find(e => e.parameter === 'name')?.value || '';

                setMessage({title: 'Duplicate error', message: `Exam with name "${name}" already exists.`});
                formik.setFieldError('name', `Exam with this name already exists.`);
                return;
            }
            case 'DUPLICATE_QUESTIONS': {
                const question = e409?.values.find(e => e.parameter === 'question')?.value || '';

                setMessage({title: 'Duplicate error', message: `Duplicate questions '${question}'`});
                return;
            }
            case 'EMPTY_OPTIONS': {
                const question = e409?.values.find(e => e.parameter === 'question')?.value || '';

                setMessage({title: 'Empty options', message: `Question '${question}' has no options`});
                return;
            }
            case 'OPTIONS_WITHOUT_CORRECT': {
                const question = e409?.values.find(e => e.parameter === 'question')?.value || '';

                setMessage({title: 'Empty correct options', message: `Question '${question}' has no correct options`});
                return;
            }
            case 'MULTI_CORRECT': {
                const question = e409?.values.find(e => e.parameter === 'question')?.value || '';

                setMessage({title: 'Multiple choice error', message: `Question '${question}' has more than one correct answer`});
                return;
            }
        }
    }

    const createExam = (exam: MainTrainingExamInput) => {
        dispatch(mainTrainingsCreateExamAction({
            data: {exam},
            onSuccess: (request, response) => {
                setMessage({title: 'Completed successfully', message: `Exam '${response.name}' successfully created.`});
                goToExams();
            },
            onError: onError,
        }));
    };

    const updateExam = (exam: MainTrainingExamInput) => {
        dispatch(mainTrainingsUpdateExamAction({
            data: {exam},
            onError: onError,
            onSuccess: (request, response) => {
                setMessage({title: 'Completed successfully', message: `Exam '${response.name}' successfully updated.`});
            },
        }));
    };

    const formik = useCustomFormik<MainTrainingExamFormik>(isOpen, {
        initialValues: {
            name: '',
            category: '',
            description: '',

            coverImage: null,

            timeToPass: 0,
            examPassingPoints: 0,
            totalPoints: 0,

            level: TrainingExamLevel.Basic,

            creator: {
                collaboratorId: currentUser?.publicId || '',
                email: currentUser?.email || '',
                fullName: getName(currentUser?.firstName|| '', currentUser?.lastName|| ''),
            },

            id: 'new',
            examId: 'new',
            workspaceId: '',
            createdDate: new Date().toISOString(),
            updatedDate: '',
            latest: true,
            status: MainTrainingExamStatus.Active,
            version: 1,

            ...selectedExam,
            questions: selectedExam?.questions.map((question) => ({
                ...question,
                options: question.options.map((option) => ({...option, optionId: uuid(), correct: option.correct || false, points: option.points || 0})),
            })).slice().sort((a, b) => a.order - b.order) || [],
        },
        validationSchema: createExamSchema,
        onSubmitValidationFailed: () => {
            const questionsErrors = formik.errors.questions;
            if (Array.isArray(questionsErrors) && questionsErrors.length) {
                createEventExpandAllQuestions((questionsErrors as any[]).reduce((prev, curr, currentIndex) => {
                    if (curr) prev.push(currentIndex);
                    return prev;
                }, [] as number[]))
            }
            // snack('Fill all required fields', 'error')
        },
        onSubmit: (values, formikHelpers, ) => {
            const exam = {
                ...values,
                questions: values.questions.map((question, index) => ({
                    ...question,
                    order: index,
                    options: question.options.map((option, index) => ({
                        ...option,
                        order: index,
                        optionId: undefined,
                        points: question.type === TrainingExamQuestionType.MultipleChoice && option.correct ? question.points : option.points,
                    }))
                }))
            };

            if (examId && exam.coverImage) updateExam({...exam, coverImage: exam.coverImage})
            else if(exam.coverImage) createExam({...exam, coverImage: exam.coverImage});
        },
    }, {
        // 'totalPoints': ['examPassingPoints', 'questions'],
    })

    return {
        isOpen, handleClose,
        isLoading: isUpdating || isCreating,

        handlePreview,
        formik,

        leaveDialog: {
            isOpen: dontSaveDialog,
            handleClose: () => setDontSaveDialog(false),
            onSuccess: confirmDontSaveDialog,
            isCreateMode: formik.values.examId === 'new',
        },
    }
}