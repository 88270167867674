import {useDispatch, useSelector} from "react-redux";
import {
    hideEditOrganization,
    organizationsDialogs,
    organizationsLoadings,
    selectedOrganization
} from "../../../../store/slice";
import {useForm} from "../../../../../../../newShared/hooks/useForm";
import {toBase64} from "../../../../../../../newShared/utils/base64/base64";
import React, {useEffect} from "react";
import {DEFAULT_LOGO_URL} from "../../../../constants";
import {UpdateOrganizationLogo, UpdateOrganizationName} from "../../../../store/actions";

export const useEditOrganizationDialog = () => {
    //root
    const dispatch = useDispatch();
    const selected = useSelector(selectedOrganization);
    const isOpen = useSelector(organizationsDialogs).editOrganization;
    const {changeName, changeLogo} = useSelector(organizationsLoadings);

    //form
    const {form, setForm, handleChange} = useForm<{name: string, logo: string}>({name: '', logo: DEFAULT_LOGO_URL});

    useEffect(() => {
        if(isOpen && selected){
            setForm({name: selected.name, logo: selected.logo ?? DEFAULT_LOGO_URL});
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const cleanUp = () => {
        setForm({name: '', logo: DEFAULT_LOGO_URL});
    }

    //actions
    const handleClose = () => {
        dispatch(hideEditOrganization());
        cleanUp();
    }

    const handleSubmit = () => {
        if(isOk && selected){
            dispatch(UpdateOrganizationName({organizationId: selected.id, newName: form.name}));
            dispatch(UpdateOrganizationLogo({organizationId: selected.id, newLogo: form.logo}));
        }
    }

    //
    // console.log(`log useEditOrganizationDialog: \n
    //     form.name: ${form.name}
    //     selected.name: ${selected?.name ?? 'selected is undefined'}
    //     form.logo: ${form.logo}
    //     selected.logo
    //     selected: ${selected} &&
    //     (form.name.trim().toLowerCase() !== selected.name.trim().toLowerCase() || (form.logo && form.logo.trim().toLowerCase() !== selected.logo.trim().toLowerCase()));
    //
    // `)
    const isOk = (selected && form.name.trim().length > 0 && form.name.toLowerCase() !== selected.name.trim().toLowerCase()) ||
            (selected && form.logo.trim().length > 0 && form.logo.toLowerCase() !== (selected.logo ?? '').trim().toLowerCase())

    const setFile = async(file: any) => {
        const fileBase64: string = await toBase64(file) as string;
        setForm({...form, logo: fileBase64});
    }

    const ref = React.createRef<HTMLInputElement>();

    const filePicker = () => {
        ref.current && ref.current.click();
    };

    return{
        isOpen,
        isLoading: changeName || changeLogo,
        handleClose,
        handleSubmit,
        form: form,
        handleChange,
        isOk,
        setFile,
        filePicker,
        ref,
    }
}