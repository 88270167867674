import {
    Autocomplete,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import {useAddDutyDialog} from "../../../hooks/dialogHooks/useAddDutyDialog";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {LoadingButton} from "@mui/lab";

export const AddDutyDialog = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        isOpen,
        shortApplications,
        categories,
        rolesVariants,
        handleClose,
        handleSubmit,
        handleSetCategory,
        handleSetApplication,
        handleSetRole,

        handleChange,
        form,
        isOk,
        isAlreadyExists,

        isLoadingCreate,
        isLoadingDialogData,
    } = useAddDutyDialog();

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Create duty')}</DialogTitle>
            <DialogContent>

                <TextField label={t('Duty name')} value={form.name} size={'small'}
                           required name={'name'} sx={{mt: '8px', '& label': {top: '0 !important'}}}
                           onChange={handleChange} error={isAlreadyExists}
                           helperText={isAlreadyExists && 'Duty with such name is already exists.'}
                />

                {/*<TextField select fullWidth label={t('Duty category')} value={form.category} size={'small'}*/}
                {/*           required*/}
                {/*           sx={{mt: '16px', '& label': {top: '0 !important'}}}*/}
                {/*           onChange={handleSetCategory}*/}
                {/*>*/}
                {/*    { categories.map((e) => <MenuItem key={e} value={e}>{e}</MenuItem>)}*/}
                {/*</TextField>*/}

                <Autocomplete
                    size={"small"}
                    sx={{mt: '16px'}}
                    options={categories}
                    value={form.category}
                    onChange={(e, value) => value && handleSetCategory(value)}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                        <TextField {...params}
                                   required
                                   size={"small"}
                                   sx={{'& label': {top: '0 !important'}}}
                                   label={t('Duty category')}  />
                    )}
                />

                <TextField name={'description'} label={t("Enter duty description")}  variant="outlined"
                           multiline minRows={5} sx={{mt: '16px'}} value={form.description} onChange={handleChange}/>

                <Typography variant={'body2'} sx={{mt: '32px'}}>{t('You can add multiple roles within the same application.')}</Typography>

                <Autocomplete
                    size={"small"}
                    sx={{mt: '8px'}}
                    value={form.application}
                    options={shortApplications}
                    onChange={(e, value) => value && handleSetApplication(value)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params}
                                   required
                                   size={"small"}
                                   sx={{'& label': {top: '0 !important'}}}
                                   label={t('Select application')}  />
                    )}
                />

                <Autocomplete
                    sx={{mt: '16px'}}
                    multiple
                    value={form?.roles.map(e => e.role) || []}
                    options={rolesVariants.filter((role) => !form.roles.some((el) => el.role === role))}
                    getOptionLabel={(option) => option}
                    onChange={(e, value) => {handleSetRole(value)}}
                    renderTags={(value: typeof rolesVariants , getTagProps, ownerState) =>
                        value.map((option, index) => (
                            <Chip
                                variant="outlined"
                                label={option}
                                size={"small"}
                                sx={{pl: !revDir ? 'undefined' : '6px'}}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            size={form?.roles.length === 0 ? "small" : undefined }
                            sx={{'& label': {top: '0 !important'}}}
                            label={t("Select role")}
                        />
                    )}
                />

                {/*<Autocomplete*/}
                {/*    multiple*/}
                {/*    sx={{mt: '16px'}}*/}
                {/*    id="size-small-outlined-multi"*/}
                {/*    size="small"*/}
                {/*    options={rolesVariants.filter((role) => !form.roles.some((el) => el.id === role.id))}*/}
                {/*    getOptionLabel={(option) => option.name}*/}
                {/*    onChange={(e, value, ) => {handleSetRole(value)}}*/}
                {/*    renderInput={(params) => (*/}
                {/*        <TextField {...params} size={"small"}*/}
                {/*                   sx={{'& label': {top: '0 !important'}}}*/}
                {/*                   label={t("Select role")} />*/}
                {/*    )}*/}
                {/*/>*/}

            </DialogContent>
            <DialogActions>
                <Button sx={{textTransform: 'none'}} onClick={handleClose} size={'medium'} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton size={'medium'} variant={'contained'}
                               disabled={!isOk || isLoadingCreate || isLoadingDialogData}
                               onClick={handleSubmit} loading={isLoadingCreate}
                               endIcon={isLoadingCreate ? <div style={{width: '10px', height: '10px', marginLeft: revDir ? '12px' : undefined, marginRight: !revDir ? '12px' : undefined }}/> : undefined}
                               loadingPosition={isLoadingCreate ? 'end' : undefined}
                >
                    {t('Save')}
                </LoadingButton>

            </DialogActions>
        </Dialog>
    )
}