import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {useDeleteIntegration} from "../../../hooks/dialogHooks/useDeleteIntegration";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import colors from "../../../../../../newShared/theme/colors";

export const DeleteIntegrationDialog = () => {
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'pathIntegrations.ExactApp.dialogs'});

    const {
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        details
    } = useDeleteIntegration();
    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t(`Delete {{name}} integration`, {name: details?.name})}</DialogTitle>

            <DialogContent>
                <Typography variant={'body2'} color={colors.darkBlueText}>
                    {t(`Access management application connected to this integration will be deleted from all campaign review configurations it was used in, and scheduled campaign review configurations containing only access management application connected to this integration - will be stopped.`)}
                </Typography>

                <Typography variant={'body2'} color={colors.darkBlueText}>
                    {t(`Are you sure you want to delete this integration?`)}
                </Typography>

                {/*<DialogContentText>*/}
                {/*    <Typography variant={'body2'} sx={{margin: '8px 0 0 0'}}>{('Are you sure you want to delete this integration?')}</Typography>*/}
                {/*</DialogContentText>*/}
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                >
                    {t('Delete')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}