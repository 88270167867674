import {useFormik} from "formik";
import * as yup from 'yup';
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDispatch, useSelector} from "react-redux";
import {
    assetsDialogsSelector,
    incomingRequestsSelector,
    loadings,
    setApproveRequestRequestDialogAction
} from "../../../store/store";
import {mainAssetsApproveRequestRequestAssetAction} from "../../../store/actions";
import {getName} from "../../../../../../newShared/utils/text";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useHistory, useLocation} from "react-router";
import {PATH_LOCAL_ASSETS_INCOMING_REQUESTS} from "../../../constants";
import {usePhysicalAssetsRequestsExact} from "../../usePhysicalAssetsRequestsExact";
import React from "react";

export const useApproveRequestRequestAssetDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets.pathIncomingRequests'});
    const {setMessage} = useMessageDialog();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const {selected} = useSelector(incomingRequestsSelector);
    const requestId = selected?.request?.id;

    const {approveRequestRequest: {isOpen}} = useSelector(assetsDialogsSelector);
    const {approveRequest} = useSelector(loadings);

    const handleOpen = () => {
        dispatch(setApproveRequestRequestDialogAction({isOpen: true}))
    }

    const handleClose = () => {
        dispatch(setApproveRequestRequestDialogAction({clean: true}))
    }

    const {getRequestById} = usePhysicalAssetsRequestsExact();
    const {currData: {currentUser}} = UseManageWorkspacesAndOrganizations();
    const handleApprove = (resolution: string) => {
        requestId && dispatch(mainAssetsApproveRequestRequestAssetAction({
            data: {data: {requestId, resolution}},
            addictiveData: {resolver: {id: currentUser?.publicId || '', name: getName(currentUser?.firstName || '', currentUser?.lastName || '')}},
            onError: (request, _error, addictiveData) => {
                const errors = _error.e409;
                if (errors?.length) {
                    getRequestById(requestId);
                }
            },
            onSuccess: (request, response) => {
                setMessage({
                    title: t('Completed successfully'),
                    message: t(`Request from {{fullName}} was approved`, {fullName: selected.request.requestor.fullName} )
                });
                history.push(`${PATH_LOCAL_ASSETS_INCOMING_REQUESTS}${location.search}`);
            },
        }))
    }

    const validationSchema = yup.object({
        comment: yup.string()
            .required('Comment field is required')
            .max(1000, t('Comment should be less than 1000 symbols'))
    });

    const formik = useFormik({
        initialValues: {comment: ''},
        validationSchema,
        onSubmit: (values) => {
            handleApprove(values.comment)
        },
        isInitialValid: false
    })
    const formikOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldTouched(e.target.name, true, false);
        formik.handleChange(e);
    };

    return {
        handleApprove,
        handleOpen,
        handleClose,
        isOpen,
        isLoadingApprove: approveRequest,
        formik: {...formik, handleChange: formikOnChange},
    }
}
