import {editRoleDialogPropsTypes} from "../../../types";
import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideEditRole} from "../../../store/slice";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {useEffect} from "react";
import {getPermissionArray} from "../../../helpers";

export const useEditRoleDialog = (props: editRoleDialogPropsTypes) => {
    const dispatch = useDispatch();
    const isOpen = useSelector(dialogs).editRole;

    const handleClose = () => {
        dispatch(hideEditRole());
        cleanUp();
    }

    useEffect(() => {
        if(isOpen && props.role){
            //dialog opened - setting form
            setForm({roleName: props.role.roleName, rolePermissions: props.role.rolePermissions.join(', ')});
        }
        //eslint-disable-next-line
    }, [isOpen]);
    //form
    const {form, setForm, handleChange} = useForm<{roleName: string, rolePermissions: string}>({roleName: '', rolePermissions: ''});
    const cleanUp = () => setForm({roleName: '', rolePermissions: ''});

    const handleSubmit = () => {
        if(isOk()){
            props.handleEditRole(form.roleName, getPermissionArray(form.rolePermissions));
            handleClose();
        }
    }

    const isOk = () => {
        return form.roleName.trim().length > 0 &&
            JSON.stringify({roleName:form.roleName.trim(), rolePermissions: form.rolePermissions.split(', ')}) !== JSON.stringify(props.role);
    }

    return{
        isOpen,
        handleClose,
        handleSubmit,
        handleChange,
        form,
        setForm,
        isOk: isOk(),
    }
}