import * as S from '../../components/styled';
import {Divider, Skeleton, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import React from "react";

export const AppLogsTableRowSkeleton = ({isRightSideOpened}: {isRightSideOpened: boolean}) => {
    return(
        <>
            <S.ExactAppLogsTableRowWrapper hoverable={false} isRightSideOpened={isRightSideOpened} selected={false}>
                <S.AppHistoryTableHeaderItemWrapper width={'50%'}>
                    <Typography sx={{fontSize: '14px', fontWeight: 400, color: colors.grayText}}><Skeleton width={150}/></Typography>
                </S.AppHistoryTableHeaderItemWrapper>

                <S.AppHistoryTableHeaderItemWrapper width={'50%'}>
                    <Typography sx={{fontSize: '14px', fontWeight: 400, color: colors.grayText}}><Skeleton width={150}/></Typography>
                </S.AppHistoryTableHeaderItemWrapper>
            </S.ExactAppLogsTableRowWrapper>
            {<Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable, margin: '4px 0 0 0'}}/>}
        </>
    )
}