import {FC} from 'react';
import {LeftSecondMenu} from "../../barsEnvironment/leftMenu/components/leftSecondMenu";
import {Routes} from "./routes";
import {useGetHrSettings} from "./hooks/useGetHrSettings";


export const Employees:FC = () => {
    useGetHrSettings();
    return(
        <LeftSecondMenu>
            <Routes/>
        </LeftSecondMenu>
    )
}
