import React, {FC, useState} from "react";
import {TAppDuty} from "../../types";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    Box,
    Button,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import colors from "../../../../../newShared/theme/colors";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {useMedia} from "../../../../../newShared/hooks/useMedia";


type TProps = {
    name: string;
    duties: TAppDuty[];
    onEdit: (duty: TAppDuty) => void;
    onDelete: (duty: TAppDuty) => void;
    onRoleClick: (roleName: string, rolePermissions: string[]) => void;
}
export const AppDutiesRow: FC<TProps> = ({name, duties, onEdit, onDelete, onRoleClick}) => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});
    const {isLessThen1050} = useMedia();

    const [open, setOpen] = useState(false);
    const isAppRemoved: boolean = name.toLowerCase() === 'removed';

    return (
        <>
            <TableRow sx={{backgroundColor: colors.backgrounds.grey_light, border: 'none'}} onClick={() => setOpen(!open)}>
                {isLessThen1050 ?
                    <TableCell component="th" scope="row" sx={{border: 'none', padding: '8px' }}>
                        <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'} >
                            <Typography variant={'h4'}>
                                {`${t('Application')} `}<span style={{fontWeight: 400, fontSize: '16px', lineHeight: '20px', letterSpacing: '0.15px'}} >{name}</span>
                            </Typography>
                            <IconButton size="small" >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </Box>
                    </TableCell>

                    :
                    <>
                        <TableCell component="th" align={!revDir ? 'left' : 'right'} scope="row" sx={{border: 'none', padding: '8px' }}>
                            <Typography variant={'h4'}>
                                {`${t('Application')} `}<span style={{fontWeight: 400, fontSize: '16px', lineHeight: '20px', letterSpacing: '0.15px'}} >{name}</span>
                            </Typography>
                        </TableCell >

                        <TableCell component="th" scope="row" sx={{border: 'none', padding: '8px', textAlign: 'end' }}  >
                            <IconButton size="small" >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                    </>

                }
            </TableRow>
            {isLessThen1050 ?
                <TableRow >
                    <TableCell style={{ padding: '0 0 16px 0', borderBottom: 'none' }}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            {duties.map((duty) => (
                                <Box key={duty.id} display={'flex'} flexDirection={'column'} alignItems={'flex-start'} sx={{borderRadius: '8px', m: '8px 6px', p: '8px', boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)'}}>
                                    <Typography variant={'body2'}>{duty.name}</Typography>
                                    <Box display={'flex'}>
                                        <Typography variant={'body2'} sx={{color: colors.text.grey_dark}}>{t('Role')}</Typography>
                                        <Box display={'flex'} flexDirection={'column'} sx={{ml: '8px', mr: '8px', overflow: 'hidden'}}>
                                            {duty.roles.map(role => (<Typography key={`role_${role.role}`} sx={{whiteSpace: 'nowrap', maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden'}} variant={'body2'} >{role.role}</Typography>))}
                                        </Box>
                                        <Box display={'flex'} flexDirection={'column'} sx={{ml: '6px', mr: '8p6'}}>
                                            {duty.roles.map(role => (<Typography key={`permission_${role.role}`} variant={'body2'} sx={{color: colors.primary.blue, whiteSpace: 'nowrap', textDecoration: !!role.permissions?.length ? 'underline' : undefined, cursor: !!role.permissions?.length ? 'pointer': undefined}} onClick={()=> !!role.permissions?.length && onRoleClick(role.role, role.permissions)}>{(role.permissions && role.permissions?.length > 0) ? `${role.permissions?.length} ${t('permissions')}` : ''}</Typography>))}
                                        </Box>
                                    </Box>
                                    <Box display={'flex'}>
                                        <Typography variant={'body2'} sx={{color: colors.text.grey_dark}}>{t('Duty description')}</Typography>
                                        <Typography variant={'body2'} sx={{ml: '8px', mr: '8px'}}>{duty.description}</Typography>
                                    </Box>
                                    <Box display={'flex'}>
                                        <Typography variant={'body2'} sx={{color: colors.text.grey_dark}}>{t('Category')}</Typography>
                                        <Typography variant={'body2'} sx={{ml: '8px', mr: '8px'}}>{duty.category}</Typography>
                                    </Box>
                                    <Box display={'flex'} alignSelf={'flex-end'}>
                                        <Button startIcon={<EditIcon />} size={'small'} variant={'text'} disabled={isAppRemoved} onClick={() => onEdit(duty)} sx={{textTransform: 'none', ml: '6px', mr: '6px'}}>{t('Edit')}</Button>
                                        <Button startIcon={<DeleteIcon />} size={'small'} variant={'text'} onClick={() => onDelete(duty)} sx={{textTransform: 'none'}}>{t('Delete')}</Button>
                                    </Box>

                                </Box>
                            ))}
                        </Collapse>
                    </TableCell>

                </TableRow>
                :
                <TableRow >
                    <TableCell style={{ paddingBottom: '16px', paddingTop: 0, borderBottom: 'none' }}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ mt: 1 }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{width: '200px'}} align={revDir ? 'right' : 'left'}> {t('Duty name')}</TableCell>
                                            <TableCell sx={{width: '220px'}} align={revDir ? 'right' : 'left'}> {t('Role')}</TableCell>
                                            <TableCell sx={{width: '600px'}} align={revDir ? 'right' : 'left'}> {t('Duty description')}</TableCell>
                                            <TableCell sx={{width: '200px'}} align={revDir ? 'right' : 'left'}> {t('Category')}</TableCell>
                                            <TableCell sx={{width: '50px'}} align={revDir ? 'right' : 'left'}> {t('Actions')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {duties.map((duty) => (
                                            <TableRow key={duty.id} sx={{borderBottom: '1px solid #E0E0E0',}}>
                                                <TableCell sx={{width: '200px'}} align={revDir ? 'right' : 'left'}>{duty.name}</TableCell>
                                                <TableCell sx={{width: '350px', maxWidth: '350px', overflow: 'hidden'}} align={revDir ? 'right' : 'left'}>
                                                    {duty.roles.map((role) => (
                                                        <Box key={role.role} display={'flex'} justifyContent={'space-between'} sx={{overflow: 'hidden', mt: '8px', mb: '8px'}} >
                                                            <Typography variant={'body1'} sx={{whiteSpace: 'nowrap', maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden'}}>{role.role}</Typography>
                                                            <Typography variant={'body2'} sx={{color: colors.primary.blue, ml: '6px', mr: '6px', whiteSpace: 'nowrap', textDecoration: !!role.permissions?.length ? 'underline' : undefined, cursor: !!role.permissions?.length ? 'pointer': undefined}} onClick={()=> !!role.permissions?.length && onRoleClick(role.role, role.permissions)}>{(role.permissions && role.permissions?.length > 0) ? `${role.permissions?.length} ${t('permissions')}` : ''}</Typography>
                                                        </Box>
                                                    ))}

                                                </TableCell>
                                                <TableCell sx={{width: '600px'}} align={revDir ? 'right' : 'left'}> {duty.description}</TableCell>
                                                <TableCell sx={{width: '350px'}} align={revDir ? 'right' : 'left'}> {duty.category}</TableCell>
                                                <TableCell sx={{width: '50px'}} align={revDir ? 'right' : 'left'}>
                                                    <Box display={'flex'} alignItems={'center'}>
                                                        <Button startIcon={<EditIcon />} size={'small'} variant={'text'} disabled={isAppRemoved} onClick={() => onEdit(duty)} sx={{textTransform: 'none'}}>{t('Edit')}</Button>
                                                        <Button startIcon={<DeleteIcon />} size={'small'} variant={'text'} onClick={() => onDelete(duty)} sx={{ml: !revDir ? '6px': undefined, mr: revDir ? '6px' : undefined,  textTransform: 'none'}}>{t('Delete')}</Button>
                                                    </Box>

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>

                        </Collapse>
                    </TableCell>

                </TableRow>
            }
        </>
    )
}