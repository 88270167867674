import colors from "../../../newShared/theme/colors";
import React from "react";
import {StatusBox, StatusTitle} from "./components/styled";
import {debounce} from "lodash";
import {createEvent} from "../../../newShared/components/editorUnion/helpers";
import moment from 'moment';
import {
    MainTrainingAssignmentPostExamModel,
    MainTrainingAssignmentTrainingModel,
    MainTrainingContentTypeSectionAddDialog,
    MainTrainingCoverImageType,
    MainTrainingExamStatus,
    MainTrainingLevel,
    MainTrainingModel,
    MainTrainingProgressStatus,
    MainTrainingStatus,
    MainTrainingTemplateModel,
    TrainingAssignmentObjectStatus,
    TrainingAssignmentStatus,
    TrainingExamLevel,
    TrainingExamQuestionType,
} from "../../../newShared/GQLTypes";

import {FormikHandlers} from 'formik';
import FormatSizeOutlinedIcon from "@mui/icons-material/FormatSizeOutlined";
import SlowMotionVideoOutlinedIcon from "@mui/icons-material/SlowMotionVideoOutlined";
import PictureInPictureRoundedIcon from '@mui/icons-material/PictureInPictureRounded';
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import TitleOutlinedIcon from "@mui/icons-material/TitleOutlined";
import {MainTrainingExamFormik} from "./types";
import {uuid} from "../../../newShared/utils";

export const getTrainingExamStatusChip = (status: MainTrainingExamStatus, isFilter?: boolean) => {
    switch (status) {
        case MainTrainingExamStatus.Active: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.status.green_1}><StatusTitle color={colors.textStatus.positive}>{'Active'}</StatusTitle></StatusBox>;
        case MainTrainingExamStatus.Archived: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{'Archived'}</StatusTitle></StatusBox>;
        default:  return <StatusBox margin={isFilter ? '8px 0 0 0' : undefined} bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{status}</StatusTitle></StatusBox>;
    }
}

export const getTrainingStatusChip = (status: MainTrainingStatus, isFilter?: boolean) => {
    switch (status) {
        case MainTrainingStatus.Active: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.status.green_1}><StatusTitle color={colors.textStatus.positive}>{'Active'}</StatusTitle></StatusBox>;
        case MainTrainingStatus.Archived: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{'Archived'}</StatusTitle></StatusBox>;
        default:  return <StatusBox margin={isFilter ? '8px 0 0 0' : undefined} bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{status}</StatusTitle></StatusBox>;
    }
}


export const getCommonStatusChip = (status?: TrainingAssignmentObjectStatus | string) => {
    switch (status) {
        case (TrainingAssignmentObjectStatus.InProgress): return <StatusBox bgColor={colors.status.blue_2}><StatusTitle color={colors.textStatus.neutral}>{'In progress'}</StatusTitle></StatusBox>;
        case (TrainingAssignmentObjectStatus.Abandoned): return <StatusBox bgColor={colors.backgrounds.warning}><StatusTitle color={colors.textStatus.negative}>{'Abandoned'}</StatusTitle></StatusBox>;
        case (TrainingAssignmentObjectStatus.Done): return <StatusBox bgColor={colors.status.green_1}><StatusTitle color={colors.textStatus.positive}>{'Completed'}</StatusTitle></StatusBox>;
        case (TrainingAssignmentObjectStatus.Expired): return <StatusBox bgColor={colors.status.orange_1}><StatusTitle color={colors.textStatus.negative}>{'Expired'}</StatusTitle></StatusBox>;
        case (TrainingAssignmentObjectStatus.Failed): return <StatusBox bgColor={colors.decorative.background_2}><StatusTitle color={colors.textStatus.negative}>{'Failed'}</StatusTitle></StatusBox>;
        case (TrainingAssignmentObjectStatus.Passed): return <StatusBox bgColor={colors.status.green_2}><StatusTitle color={colors.textStatus.positive}>{'Passed'}</StatusTitle></StatusBox>;
        // case (TrainingAssignmentObjectStatus.SkippedBySystem): return <StatusBox bgColor={colors.status.blue_2}><StatusTitle color={colors.textStatus.neutral}>{'SkippedBySystem'}</StatusTitle></StatusBox>
        case (TrainingAssignmentObjectStatus.Todo): return <StatusBox bgColor={colors.status.blue_1}><StatusTitle color={colors.textStatus.neutral}>{'Todo'}</StatusTitle></StatusBox>
        case (TrainingAssignmentObjectStatus.Validation): return <StatusBox bgColor={colors.status.grey_2}><StatusTitle color={colors.textStatus.neutral}>{'Validation'}</StatusTitle></StatusBox>;

        default: return <StatusBox bgColor={colors.status.grey_1}><StatusTitle color={colors.textStatus.neutral}>{status ?? '---'}</StatusTitle></StatusBox>;
    }
}

export const getAssignmentTrainingAndExamStatusChip = ({assignmentStatus, trainingStatus, examStatus}:{assignmentStatus: TrainingAssignmentStatus, trainingStatus?: TrainingAssignmentObjectStatus, examStatus?: TrainingAssignmentObjectStatus}) => {

    if (assignmentStatus === TrainingAssignmentStatus.Assigned) {

        return ({
            trainingChip: getCommonStatusChip(trainingStatus),
            examChip:!trainingStatus ?
                <StatusBox bgColor={colors.status.blue_1}><StatusTitle color={colors.textStatus.neutral}>{'Todo'}</StatusTitle></StatusBox>
                :
                <StatusBox bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{'Waiting'}</StatusTitle></StatusBox>,
        })
    }

    if (assignmentStatus === TrainingAssignmentStatus.Scheduled) {
        return ({
            trainingChip:  <StatusBox bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{'Waiting'}</StatusTitle></StatusBox>,
            examChip: <StatusBox bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{'Waiting'}</StatusTitle></StatusBox>,
        })
    }

    if (assignmentStatus === TrainingAssignmentStatus.Cancelled) {
        return ({
            trainingChip: <StatusBox bgColor={colors.status.grey_1}><StatusTitle color={colors.textStatus.neutral}>{'Not started'}</StatusTitle></StatusBox>,
            examChip: <StatusBox bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{'Not started'}</StatusTitle></StatusBox>,
        })
    }


    if (assignmentStatus === TrainingAssignmentStatus.InProgress) {
        return ({
            trainingChip:  getCommonStatusChip(trainingStatus),
            examChip: trainingStatus === TrainingAssignmentObjectStatus.InProgress ?
                <StatusBox bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{'Waiting'}</StatusTitle></StatusBox>
                :
                getCommonStatusChip(examStatus),
        })
    }

    if (assignmentStatus === TrainingAssignmentStatus.Validation || assignmentStatus === TrainingAssignmentStatus.Completed || assignmentStatus === TrainingAssignmentStatus.Failed || assignmentStatus === TrainingAssignmentStatus.Passed) {
        return ({
            trainingChip:  getCommonStatusChip(trainingStatus),
            examChip: getCommonStatusChip(examStatus),
        })
    }

    if (assignmentStatus === TrainingAssignmentStatus.Expired) {
        return ({
            trainingChip:  getCommonStatusChip(trainingStatus),
            examChip: trainingStatus === TrainingAssignmentObjectStatus.Expired ? <StatusBox bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{'Not started'}</StatusTitle></StatusBox>  : getCommonStatusChip(examStatus),
        })
    }
    else return ({
        trainingChip:  getCommonStatusChip(trainingStatus),
        examChip: getCommonStatusChip(examStatus),
    })

}

export const getTrainingLevelChip = (status: MainTrainingLevel | TrainingExamLevel, isFilter?: boolean) => {
    switch (status) {
        case (MainTrainingLevel.Basic || TrainingExamLevel.Basic): return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.status.green_1}><StatusTitle color={colors.textStatus.positive}>{'Active'}</StatusTitle></StatusBox>;
        case (MainTrainingLevel.Medium || TrainingExamLevel.Medium): return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{'Archived'}</StatusTitle></StatusBox>;
        case (MainTrainingLevel.High || TrainingExamLevel.High): return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{'Archived'}</StatusTitle></StatusBox>;
        default:  return <StatusBox margin={isFilter ? '8px 0 0 0' : undefined} bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{status}</StatusTitle></StatusBox>;
    }
}

export const getTrainingStatusChipColor = (status: MainTrainingStatus) => {
    switch (status) {
        case MainTrainingStatus.Active: return colors.status.green_1;
        case MainTrainingStatus.Archived: return colors.backgrounds.grey_light;
        default:  return colors.backgrounds.grey_light;
    }
}

export const getTrainingAssignmentStatusChip = (status: TrainingAssignmentStatus, isFilter?: boolean) => {
    switch (status) {
        case TrainingAssignmentStatus.Scheduled: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.status.blue_1}><StatusTitle color={colors.textStatus.neutral}>{'Scheduled'}</StatusTitle></StatusBox>;
        case TrainingAssignmentStatus.Completed: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.status.green_1}><StatusTitle color={colors.textStatus.positive}>{'Completed'}</StatusTitle></StatusBox>;
        case TrainingAssignmentStatus.Passed: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.status.green_2}><StatusTitle color={colors.textStatus.positive}>{'Passed'}</StatusTitle></StatusBox>;
        case TrainingAssignmentStatus.Failed: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.decorative.background_2}><StatusTitle color={colors.textStatus.negative}>{'Failed'}</StatusTitle></StatusBox>;
        case TrainingAssignmentStatus.Assigned: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.status.violet_1}><StatusTitle color={colors.textStatus.neutral}>{'Assigned'}</StatusTitle></StatusBox>;
        case TrainingAssignmentStatus.InProgress: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.status.blue_2}><StatusTitle color={colors.textStatus.neutral}>{'In progress'}</StatusTitle></StatusBox>;
        case TrainingAssignmentStatus.Cancelled: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.status.violet_1}><StatusTitle color={colors.textStatus.negative}>{'Cancelled'}</StatusTitle></StatusBox>;
        case TrainingAssignmentStatus.Expired: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.status.orange_1}><StatusTitle color={colors.textStatus.negative}>{'Expired'}</StatusTitle></StatusBox>;
        case TrainingAssignmentStatus.Validation: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.status.grey_2}><StatusTitle color={colors.textStatus.neutral}>{'Validation'}</StatusTitle></StatusBox>;
        default:  return <StatusBox margin={isFilter ? '8px 0 0 0' : undefined} bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{status}</StatusTitle></StatusBox>;
    }
}

export const getAssignmentTrainingProgressStatusChip = (status: MainTrainingProgressStatus) => {
    switch (status) {
        case MainTrainingProgressStatus.Todo: return <></>;
        case MainTrainingProgressStatus.InProgress: return <StatusBox bgColor={colors.status.blue_2}><StatusTitle color={colors.textStatus.neutral}>{'In progress'}</StatusTitle></StatusBox>;
        case MainTrainingProgressStatus.Done: return <StatusBox bgColor={colors.status.green_1}><StatusTitle color={colors.textStatus.positive}>{'Completed'}</StatusTitle></StatusBox>;
        default:  return <StatusBox bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{status}</StatusTitle></StatusBox>;
    }
}


export const handleMinMaxStep = (minValue: number | undefined, maxValue: number | undefined, step: number, formikChange: FormikHandlers['handleChange']) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputVal = event.target.value;

        if (inputVal === '-') {
            // Temporary allow "-" for further input
            return;
        }

        let newValue = parseFloat(inputVal);

        if (isNaN(newValue)) {
            event.target.value = minValue?.toString() ?? maxValue?.toString() ?? event.target.value;
        } else {
            // Adjust the value to be a multiple of the step and not more than the maximum value
            if (maxValue !== undefined) {
                newValue = Math.min(maxValue, Math.round(newValue / step) * step)
            }

            // Adjust the value to be a multiple of the step and not less than the minimum value
            if (minValue !== undefined) {
                newValue = Math.max(minValue, Math.round(newValue / step) * step);
            }
            
            event.target.value = newValue.toString();
        }

        // Call Formik's change handler
        formikChange(event);
    };
};

export const expandAllQuestionsEventName = 'expandAllQuestions';
export const createEventExpandAllQuestions =  debounce((indexes: number[] = []) => {
    // window.config?.NODE_ENV === 'development' && console.log('createEventExpandAllQuestions');
    document.dispatchEvent(
        createEvent(expandAllQuestionsEventName, {indexes})
    )
}, 1);

export const collapseAllQuestionsEventName = 'collapseAllQuestions';
export const createEventCollapseAllQuestions =  debounce(() => {
    // window.config?.NODE_ENV === 'development' && console.log('createEventCollapseAllQuestions');
    document.dispatchEvent(
        createEvent(collapseAllQuestionsEventName, {})
    )
}, 1);

export const sectionTypeImageMapper = (sectionType: MainTrainingContentTypeSectionAddDialog) => {
    switch (sectionType) {
        case MainTrainingContentTypeSectionAddDialog.Editor:
            return {sectionImage: <FormatSizeOutlinedIcon/>, sectionTitle: 'Editor'};
        case MainTrainingContentTypeSectionAddDialog.File:
            return {sectionImage: <PictureInPictureRoundedIcon/>, sectionTitle: 'File'};
        case MainTrainingContentTypeSectionAddDialog.Video:
            return {sectionImage: <SlowMotionVideoOutlinedIcon/>, sectionTitle: 'Video'};
    }
}

export const questionTypeImageMapper = (questionType: TrainingExamQuestionType) => {
    switch (questionType) {
        case TrainingExamQuestionType.Checkboxes:
            return {questionImage: <CheckBoxIcon/>, questionTitle: 'Checkboxes'};
        case TrainingExamQuestionType.MultipleChoice:
            return {questionImage: <RadioButtonCheckedIcon/>, questionTitle: 'Multiple choice'};
        case TrainingExamQuestionType.Paragraph:
            return {questionImage: <NotesOutlinedIcon/>, questionTitle: 'Paragraph'};
        case TrainingExamQuestionType.ShortAnswer:
            return {questionImage: <TitleOutlinedIcon/>, questionTitle: 'Short answer'};
    }
}

export const getDiffInMinutes = (startDate: string, endDate: string) => {
    moment.duration(moment(endDate).diff(moment(startDate))).asMinutes();
}

export const mapToMainTrainingType = (training: MainTrainingAssignmentTrainingModel): MainTrainingModel => {

    return {
        name: training.trainingName,
        category: training.trainingCategory,
        content: training.content.map(e => ({
            data: e.data,
            name: e.sectionName,
            order: e.order,
            sectionId: e.sectionId,
            type: e.type,
        })),
        coverImage: training.trainingCover,
        createdDate: '---',
        creator: {collaboratorId: '', fullName: '', email: ''},
        description: training.trainingDescription,
        editor: null,
        exam: null,
        id: '---',
        latest: false,
        level: training.trainingLevel,
        limitMaxDuration: training.limitMaxDuration,
        limitMinDuration: training.limitMinDuration,

        status: MainTrainingStatus.Active,
        trainingId: '---',
        updatedDate: '---',
        version: 1,
        workspaceId: '---',

    }
}
export const mapTemplateToTrainingType = (template:  MainTrainingTemplateModel | null): MainTrainingModel | null => {
    if (!template) return null;

    return {
        // name: template.name,
        // category: template.category,
        // content: template.content.map(e => ({
        //     data: e.data,
        //     name: e.name,
        //     order: e.order,
        //     sectionId: e.sectionId,
        //     type: e.type,
        // })),
        // level: template.level,
        // coverImage: template.coverImage,
        // description: template.description,
        // limitMaxDuration: template.limitMaxDuration,
        // limitMinDuration: template.limitMinDuration,

        ...template,
        creator: {collaboratorId: '', fullName: '', email: ''},
        createdDate: '---',
        editor: null,
        exam: null,
        id: '',
        latest: false,
        status: MainTrainingStatus.Active,
        trainingId: '---',
        updatedDate: '---',
        version: 1,
        workspaceId: '---',
    }
}


export const mapToMainExamType = (exam: MainTrainingAssignmentPostExamModel): MainTrainingExamFormik => {

    return {
        category: exam.examCategory,
        coverImage: exam.examCover ?? {image: '', imageName: '', thumbnail: '', type: MainTrainingCoverImageType.Url},
        createdDate: '---',
        creator: {collaboratorId: '', fullName: '', email: ''},
        description: exam.examDescription,
        editor: null,
        examId: '---',
        examPassingPoints: exam.examPassingPoints || 0,
        id: '---',
        latest: false,
        level: exam.examLevel,
        name: exam.examName,
        questions: exam.questions?.map((e, index) => ({
            description: e.description,
            options: e.options?.map(option => ({...option, optionId: uuid(), correct: option.correct || false, points: option.points || 0})) || [],
            order: index,
            points: 0,
            pointsAutoCount: e.type === TrainingExamQuestionType.Checkboxes,
            question: e.question,
            questionId: e.questionId,
            type: e.type,
        })) ?? [],
        status: MainTrainingExamStatus.Active,
        timeToPass: exam.timeToPass,
        totalPoints: 0,
        updatedDate: '---',
        version: 1,
        workspaceId: '',
    }
}
