import {
    useCampaignReviewExecutorExact
} from "../../../../hooks/campaignReviewHooks/executors/useCampaignReviewExecutorExact";
import React from "react";
import {Flex} from "../../../../../../../newShared/components/Layouts";
import colors from "../../../../../../../newShared/theme/colors";
import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    IconButton,
    RadioGroup,
    Step,
    StepLabel,
    Stepper,
    Typography
} from "@mui/material";
import * as S from '../../../../components/styled';
import {ExactInstructionWrapper} from '../../../../components/styled';
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../../../newShared/hooks/useMedia";
import {LoadingButton} from "../../../../../../../newShared/components/Basic/CommonButtons";

import DoDisturbIcon from '@mui/icons-material/DoDisturb';

//stepper
import {REVIEW_CAMPAIGN_STEPS} from "../../../../types";
import {
    getColorAndTextByAccountStatus,
    getColorAndTextByStage,
    groupByEmployee,
    mapCampaignReviewExecutorAccountFillableTypeWithApplicationForSingleUpdateRequest
} from "../../../../helpers";

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {PreviewRoleDialog} from "../../../../components/dialogs/previewRoleDialog";
import {CustomTableRow} from "../../../../components/customTableRow";
import {CustomTableHeader} from "../../../../components/customTableHeader";
import {
    ACCOUNT_DELETE_STATUS,
    ACCOUNT_KEEP_STATUS,
    ACCOUNT_REVOKE_STATUS,
    COMPLETE_STATUS,
    IN_PROGRESS_STATUS,
    REVIEW_CAMPAIGN_FINISH_STEP_CUSTOM,
    REVIEW_CAMPAIGN_STEP_EXECUTOR,
    REVIEW_CAMPAIGN_STEP_MANAGER,
    REVIEW_CAMPAIGN_STEP_SIGN,
    REVIEW_CAMPAIGN_STEP_VENDOR,
    REVIEW_CAMPAIGN_STEP_VERIFY,
    TERMINATED_STATUS
} from "../../../../constants";
import {parseDateAuto} from "../../../../../../../newShared/utils/dateTools";
import Radio from "@mui/material/Radio";

import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import {ExpandableContainer} from "../../../../components/campaignReview/ExpandableContainer";
import {
    ShowVerifyApplicationRevokeReasonDialog
} from "../../../../components/campaignReview/dialogs/showVerifyApplicationRevokeReasonDialog";
import {TerminateExecutorDialog} from "../../../../components/campaignReview/dialogs/terminateExecutorDialog";
import {
    ViewOrEditJustificationDialog
} from "../../../../components/campaignReview/dialogs/viewOrEditJustificationDialog";
import {ViewJustificationDialog} from "../../../../components/campaignReview/dialogs/viewJustificationDialog";
import {DeclineVerifyStepDialog} from "../../../../components/campaignReview/dialogs/declineVerifyStepDialog";
import {PageTitleAndActions} from "../../../../../../../newShared/components/pageTitleAndActions";
import {SelectedEvidenceCard} from "../../../../components/campaignReview/SelectedEvidenceCard";

const CampaignReviewStep = ({isDeadlined, isCompleted, isTerminated, isNotStarted, isDisabled, stepType, index}: {isDeadlined: boolean, isCompleted: boolean, isTerminated: boolean, isNotStarted: boolean, isDisabled: boolean, stepType: REVIEW_CAMPAIGN_STEPS, index: number}) => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.CampaignReviewExecutorExact'});
    const normalizedName = getColorAndTextByStage(stepType).text;
    if(isDeadlined){
        return(
            <Step index={index} active>
                <StepLabel
                    // StepIconComponent={ErrorOutlineIcon}
                    StepIconProps={{sx: {fill: colors.decorative.orange, paddingRight: !revDir ? undefined: 0, paddingLeft: !revDir ? undefined: '8px'}}}
                >
                    <Typography variant={'h4'} color={colors.decorative.orange}>{t(`stages.${normalizedName}`)}</Typography>
                </StepLabel>
            </Step>
        )
    }
    if(isDisabled){
        return (
            <Step index={index} active>
                <StepLabel StepIconProps={{sx: {'&.Mui-active': {color: colors.text.grey, paddingRight: !revDir ? undefined: 0, paddingLeft: !revDir ? undefined: '8px'}}}}>
                    <Typography variant={'h4'} color={colors.text.grey_dark}>{t(`stages.${normalizedName}`)}</Typography>
                </StepLabel>
            </Step>
        )
    }

    if(isNotStarted){
        return(
            <Step index={index} active>
                <StepLabel StepIconProps={{sx: {'&.Mui-active': {color: colors.primary.blue, paddingRight: !revDir ? undefined: 0, paddingLeft: !revDir ? undefined: '8px'}}}}>
                    <Typography variant={'h4'} color={colors.grayText}>{t(`stages.${normalizedName}`)}</Typography>
                </StepLabel>
            </Step>
        )
    }
    if(isCompleted){
        return (
            <Step index={index} completed>
                <StepLabel StepIconProps={{sx: {'&.Mui-completed': {color: colors.warningActions.green, paddingRight: !revDir ? undefined: 0, paddingLeft: !revDir ? undefined: '8px'}}}}>
                    <Typography variant={'h4'} color={colors.warningActions.green}>{t(`stages.${normalizedName}`)}</Typography>
                </StepLabel>
            </Step>
        )
    }
    if(isTerminated){
        return (
            <Step index={index} active>
                <StepLabel StepIconProps={{sx: {'&.Mui-active': {color: colors.grayText, paddingRight: !revDir ? undefined: 0, paddingLeft: !revDir ? undefined: '8px'}}}}>
                    <Typography variant={'h4'} color={colors.grayText}>{t(`stages.${normalizedName}`)}</Typography>
                </StepLabel>
            </Step>
        )
    }
    if(!isDeadlined && !isCompleted && !isTerminated && !isNotStarted && !isDisabled){
        //IN PROGRESS
        return (
            <Step index={index} active>
                <StepLabel StepIconProps={{sx: {'&.Mui-active': {color: colors.backgrounds.dark_blue, paddingRight: !revDir ? undefined: 0, paddingLeft: !revDir ? undefined: '8px'}}}}>
                    <Typography variant={'h4'} color={colors.backgrounds.dark_blue}>{t(`stages.${normalizedName}`)}</Typography>
                </StepLabel>
            </Step>
        )
    }

    return <></>;
}

export const CampaignReviewExecutorExact = () => {
    const {
        executor,
        commonSelectors,
        commonActions,
        stageManagers,
        stageVendors,
        stageVerify,
        stageExecutor,
        stageSign,
        stageFinish,
    } = useCampaignReviewExecutorExact();
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.CampaignReviewExecutorExact'});
    const {matches_1440Up, matches_1920Up, matches_1336Up, isLessThen1050, isMobile, isLessThenCustomQuery} = useMedia(1030); //1366  - breakpoint when swap to cards view

    const getColumnWidths = (): {name: string, width: string}[] => {
        if(matches_1920Up){
            //>=1920
            return [{name: t('Application'), width: '20%'}, {name: t('Account'), width: '20%'}, {name: t('Permissions'), width: '20%'}, {name: t('Status'), width: '40%'}]
        }
        if(matches_1440Up){
            //>=1440<1920
            return [{name: t('Application'), width: '15%'}, {name: t('Account'), width: '19%'}, {name: t('Permissions'), width: '23%'}, {name: t('Status'), width: '43%'}]
        }
        if(matches_1336Up){
            return [{name: t('Application'), width: '20%'}, {name: t('Account'), width: '20%'}, {name: t('Permissions'), width: '20%'}, {name: t('Status'), width: '40%'}]
        }
        if(isLessThen1050){
            return [{name: t('Application'), width: '18%'}, {name: t('Account'), width: '18%'}, {name: t('Permissions'), width: '19%'}, {name: t('Status'), width: '45%'}]
        }
        //1030 - show cards

        return [{name: t('Application'), width: '20%'}, {name: t('Account'), width: '20%'}, {name: t('Permissions'), width: '20%'}, {name: t('Status'), width: '40%'}]
    }

    const getVendorsColumnWidths = (): {name: string, width: string}[] => {
        if(matches_1920Up){
            //>=1920
            return [{name: t('Employee'), width: '20%'}, {name: t('Account'), width: '20%'}, {name: t('Permissions'), width: '20%'}, {name: t('Status'), width: '40%'}]
        }
        if(matches_1440Up){
            //>=1440<1920
            return [{name: t('Employee'), width: '15%'}, {name: t('Account'), width: '19%'}, {name: t('Permissions'), width: '23%'}, {name: t('Status'), width: '43%'}]
        }
        if(matches_1336Up){
            return [{name: t('Employee'), width: '20%'}, {name: t('Account'), width: '20%'}, {name: t('Permissions'), width: '20%'}, {name: t('Status'), width: '40%'}]
        }
        if(isLessThen1050){
            return [{name: t('Employee'), width: '18%'}, {name: t('Account'), width: '18%'}, {name: t('Permissions'), width: '19%'}, {name: t('Status'), width: '45%'}]
        }
        //1030 - show cards

        return [{name: t('Employee'), width: '20%'}, {name: t('Account'), width: '20%'}, {name: t('Permissions'), width: '20%'}, {name: t('Status'), width: '40%'}]
    }

    const columnWidths = getColumnWidths();
    const vendorColumnWidths = getVendorsColumnWidths();

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={executor?.name}
                handleGoBack={commonActions.handleGoBack}
                actions={[
                    {
                        title: t('Terminate'),
                        onClick: commonActions.handleTerminateReviewCampaign,
                        disabled: !executor || (executor && executor.status === TERMINATED_STATUS) || (executor && executor.status === COMPLETE_STATUS),
                        icon: <DoDisturbIcon />,
                        variant: 'text'
                    }
                ]}
            />

            <S.CampaignReviewArrayWrapper>
                <S.StepperWrapper>
                    <Stepper activeStep={commonSelectors.stepNumberId} sx={{margin: '16px 0 0 0', minWidth: '1000px', maxWidth: '1212px'}}>
                        <CampaignReviewStep
                            isDeadlined={stageManagers.isDeadlinePassed}
                            isCompleted={stageManagers.isComplete}
                            isTerminated={stageManagers.isTerminated}
                            isNotStarted={stageManagers.isNotStarted}
                            stepType={'MANAGER'}
                            index={0}
                            isDisabled={false}
                        />

                        <CampaignReviewStep
                            isDeadlined={stageVendors.isDeadlinePassed}
                            isCompleted={stageVendors.isComplete}
                            isTerminated={stageVendors.isTerminated}
                            isNotStarted={stageVendors.isNotStarted}
                            stepType={'VENDOR'}
                            index={1}
                            isDisabled={stageManagers.isTerminated}
                        />

                        <CampaignReviewStep
                            isDeadlined={stageExecutor.isDeadlinePassed}
                            isCompleted={stageExecutor.isComplete}
                            isTerminated={stageExecutor.isTerminated}
                            isNotStarted={stageExecutor.isNotStarted}
                            stepType={'EXECUTION'}
                            index={2}
                            isDisabled={stageManagers.isTerminated || stageVendors.isTerminated}
                        />

                        <CampaignReviewStep
                            isDeadlined={stageVerify.isDeadlinePassed}
                            isCompleted={stageVerify.isComplete}
                            isTerminated={stageVerify.isTerminated}
                            isNotStarted={stageVerify.isNotStarted}
                            stepType={'VERIFY'}
                            isDisabled={stageManagers.isTerminated || stageVendors.isTerminated || stageExecutor.isTerminated}
                            index={3}
                        />

                        <CampaignReviewStep
                            isDeadlined={stageSign.isDeadlinePassed}
                            isCompleted={stageSign.isComplete}
                            isTerminated={stageSign.isTerminated}
                            isNotStarted={stageSign.isNotStarted}
                            stepType={'SIGN'}
                            isDisabled={stageManagers.isTerminated || stageVendors.isTerminated || stageExecutor.isTerminated || stageVerify.isTerminated}
                            index={4}
                        />

                        <CampaignReviewStep
                            isDeadlined={false}
                            isCompleted={stageSign.isComplete}
                            isTerminated={false}
                            isNotStarted={stageSign.isNotStarted}
                            stepType={'finish'}
                            isDisabled={stageManagers.isTerminated || stageVendors.isTerminated || stageExecutor.isTerminated || stageVerify.isTerminated || stageSign.isTerminated}
                            index={4}
                        />
                    </Stepper>
                </S.StepperWrapper>

                {commonSelectors.step === REVIEW_CAMPAIGN_STEP_MANAGER && stageManagers.stage &&
                    <>
                        {stageManagers.stage.status === TERMINATED_STATUS ?
                            <>
                                <Flex m={'16px 0 0 0'}>
                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{t('Stage managers was terminated on')} </Typography>
                                    <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{parseDateAuto(stageManagers.stage.statusChangedDate)}</Typography>
                                </Flex>
                            </>
                            :
                            <>
                                {isLessThenCustomQuery ?
                                    <>
                                        <Flex w={'100%'} m={'20px 0'} direction={'column'}>
                                            <Flex ai={'center'}>
                                                <Typography variant={'body1'} color={colors.lightGrayText} sx={{lineHeight: 'normal'}}>{t('Deadline for Stage Managers')}</Typography>
                                                <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{parseDateAuto(stageManagers.stage.expirationDate)}</Typography>
                                            </Flex>
                                            {stageManagers.isDeadlinePassed && <Typography variant={'body1'} color={colors.decorative.orange} sx={{margin: '8px 0 0 0'}}>{t('The deadline has arrived')}</Typography>}
                                        </Flex>

                                        {stageManagers.stage.actors.map((actor, id) => {
                                            const isStageCanBeAssigned = actor.status === IN_PROGRESS_STATUS && !actor.mainControl;
                                            const isEditable: boolean = actor.status === IN_PROGRESS_STATUS && actor.mainControl;
                                            const isStageCanBeVerified = !((actor.appsData ?? []).flatMap(e => e.data).some(e => e.statusManager === null)) && isEditable;

                                            return(
                                                // MANAGER ARRAY
                                                <Flex w={'100%'} maxw={'1400px'} direction={'column'} m={id > 0 ? '20px 0 0 0' : ''}>
                                                    {/*TOP*/}
                                                    <Flex w={'100%'} ai={'center'} background={colors.backgrounds.grey_light} p={isStageCanBeAssigned ? '8px 2px' : '16px 2px'}>
                                                        <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0'}} fontWeight={600}>{t('Manager')}</Typography>
                                                        <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 32px 0 0' : '0 0 32px 0'}}>{actor.firstName} {actor.lastName}</Typography>
                                                        {isStageCanBeAssigned &&
                                                            <LoadingButton
                                                                variant={'contained'}
                                                                size={'small'}
                                                                sx={{margin: revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                                                onClick={() => stageManagers.handleAssignSubStepToMe(actor.email)}
                                                                loading={commonSelectors.loadingAssignActorEmail.some(e => e === actor.email)}
                                                                // sx={{'& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px' : '0 -4px 0 8px',}}}
                                                            >{t('Assign to me')}
                                                            </LoadingButton>
                                                        }
                                                        {isStageCanBeVerified &&
                                                            <LoadingButton
                                                                variant={'contained'}
                                                                size={'small'}
                                                                sx={{margin: revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                                                onClick={() => stageManagers.handleVerifyActorChanges(actor.email)}
                                                                loading={commonSelectors.loadingVerifyActorEmails.some(e => e === actor.email)} //is loading verify changes for current user
                                                            >{t('Verify changes')}
                                                            </LoadingButton>
                                                        }
                                                    </Flex>

                                                    {isEditable &&
                                                        <Flex ai={'center'} m={'16px 0'}>
                                                            <FormGroup
                                                                sx={{margin: !revDir ? '0 16px' : '0 16px'}}
                                                            >
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            disabled={(stageManagers.selectingAccountsOfActorEmail !== null && stageManagers.selectingAccountsOfActorEmail !== actor.email)}
                                                                            size={'small'}
                                                                            checked={stageManagers.isSelectedAllManagersAccounts(actor.email)}
                                                                            onChange={(e) => e.target.checked ? stageManagers.handleSelectAllAccounts(actor.email) : stageManagers.handleDeselectAllAccounts()}
                                                                        />}
                                                                    label={t("Select all")}
                                                                />
                                                            </FormGroup>

                                                            <Button
                                                                variant={'outlined'}
                                                                sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                                                size={'small'}
                                                                onClick={stageManagers.handleKeepAllSelectedManagerAccounts}
                                                                disabled={!stageManagers.selectedManagerAccounts.length || (stageManagers.selectingAccountsOfActorEmail !== null && stageManagers.selectingAccountsOfActorEmail !== actor.email) || commonSelectors.loadingIds.length > 0}
                                                            >
                                                                {`${t('Keep all')} ${stageManagers.selectedManagerAccounts.length ?? ''}`}
                                                            </Button>

                                                            <Button
                                                                variant={'outlined'}
                                                                sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                                                size={'small'}
                                                                onClick={stageManagers.handleRevokeAllSelectedManagerAccounts}
                                                                disabled={!stageManagers.selectedManagerAccounts.length || (stageManagers.selectingAccountsOfActorEmail !== null && stageManagers.selectingAccountsOfActorEmail !== actor.email) || commonSelectors.loadingIds.length > 0}
                                                            >
                                                                {`${t('Revoke all')} ${stageManagers.selectedManagerAccounts.length ?? ''}`}
                                                            </Button>

                                                            <Button
                                                                variant={'outlined'}
                                                                sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                                                size={'small'}
                                                                onClick={stageManagers.handleDeleteAllSelectedManagerAccounts}
                                                                disabled={!stageManagers.selectedManagerAccounts.length || (stageManagers.selectingAccountsOfActorEmail !== null && stageManagers.selectingAccountsOfActorEmail !== actor.email) || commonSelectors.loadingIds.length > 0}
                                                            >
                                                                {`${t('Delete all')} ${stageManagers.selectedManagerAccounts.length ?? ''}`}
                                                            </Button>
                                                        </Flex>
                                                    }

                                                    {groupByEmployee(actor.appsData ?? []).map(employee => {
                                                        return(
                                                            <>
                                                                {employee.accounts.map(account => {
                                                                    const status = getColorAndTextByAccountStatus(account.statusManager);
                                                                    const isLoading = commonSelectors.loadingIds.some(e => e === account.id);
                                                                    return(
                                                                        <S.UserCardWrapper revDir={revDir} p={'10px 8px'}>
                                                                            <Flex ai={'center'}>
                                                                                {isEditable &&
                                                                                    <Checkbox
                                                                                        disabled={(stageManagers.selectingAccountsOfActorEmail !== null && stageManagers.selectingAccountsOfActorEmail !== actor.email)}
                                                                                        size={'small'}
                                                                                        checked={stageManagers.isSelectedManagerAccount(account.id)}
                                                                                        onChange={(e) => e.target.checked ? stageManagers.handleSelectManagerAccount(account.id, actor.email) : stageManagers.handleDeselectManagerAccount(account.id, actor.email)}
                                                                                        sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}
                                                                                    />
                                                                                }
                                                                                <Typography sx={{color: colors.darkText}} variant={'body2'}>{account.account ?? '- - -'}</Typography>
                                                                            </Flex>

                                                                            <Flex ai={'center'} m={'8px 0 0 0'}>
                                                                                <Typography fontWeight={400} noWrap={true} sx={{lineHeight: 'normal', fontSize: '14px', margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} color={colors.lightGrayText}>{t(`Employee`)}:</Typography>
                                                                                <Typography variant={'body2'} color={colors.grayText}>{account.employee ?? t('Not mapped account')}</Typography>
                                                                            </Flex>

                                                                            <Flex ai={'center'} m={'8px 0 0 0'}>
                                                                                <Typography fontWeight={400} noWrap={true} sx={{lineHeight: 'normal', fontSize: '14px', margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} color={colors.lightGrayText}>{t(`Application`)}:</Typography>
                                                                                <Typography variant={'body2'} color={colors.grayText}>{account.applicationAccessName}</Typography>
                                                                            </Flex>

                                                                            <Flex direction={'column'} m={'8px 0 0 0'}>
                                                                                <Typography fontWeight={400} noWrap={true} sx={{lineHeight: 'normal', fontSize: '14px'}} color={colors.lightGrayText}>{t(`Permissions`)}:</Typography>
                                                                                <Flex w={isMobile ? '90%' : '50%'} direction={'column'}>
                                                                                    {account.roles.map(e => {
                                                                                        const moreThen0Roles = e.rolePermissions.length > 0;
                                                                                        return(
                                                                                            <Flex key={e.roleName} w={'100%'} ai={'center'} jc={'space-between'}>
                                                                                                <Typography sx={{color: colors.darkText, width: '50%',  cursor: 'pointer'}} variant={matches_1920Up ? 'body1' : 'body2'}>{e.roleName}</Typography>
                                                                                                <Flex w={'50%'}>
                                                                                                    <Typography
                                                                                                        sx={{color: moreThen0Roles ? colors.primary.blue : colors.darkText, cursor: moreThen0Roles ? 'pointer' : 'default', textDecoration: moreThen0Roles ? 'underline' : ''}}
                                                                                                        variant={matches_1920Up ? 'body1' : 'body2'}
                                                                                                        onClick={() => commonActions.onRoleClick(e.roleName, e.rolePermissions)}
                                                                                                    >{e.rolePermissions.length} {t('permissions')}</Typography>
                                                                                                </Flex>
                                                                                            </Flex>
                                                                                        )
                                                                                    })}
                                                                                </Flex>
                                                                            </Flex>

                                                                            <Flex direction={'column'} m={'8px 0 0 0'}>
                                                                                <Typography fontWeight={400} noWrap={true} sx={{lineHeight: 'normal', fontSize: '14px'}} color={colors.lightGrayText}>{t(`Status`)}:</Typography>
                                                                                {isEditable ?
                                                                                    <RadioGroup
                                                                                        row
                                                                                        value={account.statusManager ?? ''}
                                                                                        sx={{flexWrap: 'nowrap'}}
                                                                                        onChange={(e, value) => {
                                                                                            const data = mapCampaignReviewExecutorAccountFillableTypeWithApplicationForSingleUpdateRequest(account);
                                                                                            stageManagers.handleChangeSingleStatus(data.app, data.account, stageManagers.stage!.id, actor.email, value)
                                                                                        }}
                                                                                    >
                                                                                        <FormControlLabel disabled={isLoading} value={ACCOUNT_KEEP_STATUS} control={<Radio size={'small'} />} label={<Typography variant={'body2'}>{t('Keep')}</Typography>} />
                                                                                        <FormControlLabel disabled={isLoading} value={ACCOUNT_REVOKE_STATUS} control={<Radio size={'small'} />} label={<Typography variant={'body2'}>{t('Revoke')}</Typography>} />
                                                                                        <FormControlLabel disabled={isLoading} value={ACCOUNT_DELETE_STATUS} control={<Radio size={'small'} />} label={<Typography variant={'body2'}>{t('Delete account')}</Typography>} />
                                                                                    </RadioGroup>
                                                                                    :
                                                                                    <Typography color={status.color} variant={'body2'}>{t(status.text)}</Typography>
                                                                                }

                                                                                <Flex>
                                                                                    {isEditable ?
                                                                                        <Button
                                                                                            variant={'text'}
                                                                                            size={'small'}
                                                                                            disabled={account.statusManager === null || isLoading}
                                                                                            onClick={() => {
                                                                                                const data = mapCampaignReviewExecutorAccountFillableTypeWithApplicationForSingleUpdateRequest(account);
                                                                                                stageManagers.editJustification(data.app, data.account, stageManagers.stage!.id, actor.email);
                                                                                            }}
                                                                                        >
                                                                                            {(account.commentManager ?? '').length > 0 ? t('View justification') : t('Add justification')}
                                                                                        </Button>
                                                                                        :
                                                                                        (account.commentManager ?? '').length > 0 && <Typography
                                                                                            color={colors.primary.blue}
                                                                                            variant={'body2'}
                                                                                            sx={{margin: '8px 0 0 0', cursor: 'pointer'}}
                                                                                            onClick={() => stageManagers.viewJustification(actor.email, account.commentManager)}
                                                                                        >{t('View justification')}</Typography>
                                                                                    }
                                                                                </Flex>
                                                                            </Flex>
                                                                        </S.UserCardWrapper>
                                                                    )
                                                                })}
                                                            </>
                                                        )
                                                    })
                                                    }
                                                </Flex>
                                            )
                                        })}
                                    </>
                                    :
                                    <Flex w={'100%'} direction={'column'} m={'0 0 16px 0'}>
                                        <Flex w={'100%'} ai={'center'} m={'20px 0'}>
                                            <Typography variant={'body1'} color={colors.lightGrayText} sx={{ lineHeight: 'normal'}}>{t('Deadline for Stage Managers')}</Typography>
                                            <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 0 0 8px' : '0 8px 0 0', lineHeight: 'normal'}}>{parseDateAuto(stageManagers.stage.expirationDate)}</Typography>
                                            {stageManagers.isDeadlinePassed && <Typography variant={'body1'} color={colors.decorative.orange}>{t('The deadline has arrived')}</Typography>}
                                        </Flex>

                                        {stageManagers.stage.actors.map((actor, id) => {
                                            const isStageCanBeAssigned = actor.status === IN_PROGRESS_STATUS && !actor.mainControl;
                                            const isEditable: boolean = actor.status === IN_PROGRESS_STATUS && actor.mainControl;
                                            const isStageCanBeVerified = !((actor.appsData ?? []).flatMap(e => e.data).some(e => e.statusManager === null)) && isEditable;

                                            return(
                                                // MANAGER ARRAY
                                                <Flex w={'100%'} maxw={'1400px'} direction={'column'} m={id > 0 ? '20px 0 0 0' : ''}>
                                                    {/*TOP*/}
                                                    <Flex gap={'8px'} w={'100%'} ai={'center'} background={colors.backgrounds.grey_light} p={isStageCanBeAssigned ? '8px 2px' : '16px 2px'}>
                                                        <Typography color={colors.grayText} variant={'body1'} fontWeight={600}>{t('Manager')}</Typography>
                                                        <Typography color={colors.grayText} variant={'body1'}>{actor.firstName} {actor.lastName} {actor.email}</Typography>
                                                        {isStageCanBeAssigned &&
                                                            <LoadingButton
                                                                variant={'contained'}
                                                                size={'small'}
                                                                // sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                                                onClick={() => stageManagers.handleAssignSubStepToMe(actor.email)}
                                                                loading={commonSelectors.loadingAssignActorEmail.some(e => e === actor.email)}
                                                                // sx={{'& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px' : '0 -4px 0 8px',}}}
                                                            >{t('Assign to me')}
                                                            </LoadingButton>
                                                        }
                                                        {isStageCanBeVerified &&
                                                            <LoadingButton
                                                                variant={'contained'}
                                                                size={'small'}
                                                                // sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                                                onClick={() => stageManagers.handleVerifyActorChanges(actor.email)}
                                                                loading={commonSelectors.loadingVerifyActorEmails.some(e => e === actor.email)} //is loading verify changes for current user
                                                            >{t('Verify changes')}
                                                            </LoadingButton>
                                                        }
                                                    </Flex>

                                                    {isEditable &&
                                                        <Flex ai={'center'} m={'16px 0'}>
                                                            <FormGroup
                                                                sx={{margin: !revDir ? '0 16px' : '0 8px 0 16px'}}
                                                            >
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            disabled={(stageManagers.selectingAccountsOfActorEmail !== null && stageManagers.selectingAccountsOfActorEmail !== actor.email)}
                                                                            size={'small'}
                                                                            checked={stageManagers.isSelectedAllManagersAccounts(actor.email)}
                                                                            onChange={(e) => e.target.checked ? stageManagers.handleSelectAllAccounts(actor.email) : stageManagers.handleDeselectAllAccounts()}
                                                                        />}
                                                                    label={t("Select all")}
                                                                />
                                                            </FormGroup>

                                                            <Button
                                                                variant={'outlined'}
                                                                sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                                                size={'small'}
                                                                onClick={stageManagers.handleKeepAllSelectedManagerAccounts}
                                                                disabled={!stageManagers.selectedManagerAccounts.length || (stageManagers.selectingAccountsOfActorEmail !== null && stageManagers.selectingAccountsOfActorEmail !== actor.email) || commonSelectors.loadingIds.length > 0}
                                                            >
                                                                {`${t('Keep all')} ${stageManagers.selectedManagerAccounts.length ?? ''}`}
                                                            </Button>

                                                            <Button
                                                                variant={'outlined'}
                                                                sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                                                size={'small'}
                                                                onClick={stageManagers.handleRevokeAllSelectedManagerAccounts}
                                                                disabled={!stageManagers.selectedManagerAccounts.length || (stageManagers.selectingAccountsOfActorEmail !== null && stageManagers.selectingAccountsOfActorEmail !== actor.email) || commonSelectors.loadingIds.length > 0}
                                                            >
                                                                {`${t('Revoke all')} ${stageManagers.selectedManagerAccounts.length ?? ''}`}
                                                            </Button>

                                                            <Button
                                                                variant={'outlined'}
                                                                sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                                                size={'small'}
                                                                onClick={stageManagers.handleDeleteAllSelectedManagerAccounts}
                                                                disabled={!stageManagers.selectedManagerAccounts.length || (stageManagers.selectingAccountsOfActorEmail !== null && stageManagers.selectingAccountsOfActorEmail !== actor.email) || commonSelectors.loadingIds.length > 0}
                                                            >
                                                                {`${t('Delete all')} ${stageManagers.selectedManagerAccounts.length ?? ''}`}
                                                            </Button>
                                                        </Flex>
                                                    }

                                                    {groupByEmployee(actor.appsData ?? [], t('Not mapped accounts')).map(employee => {
                                                        return(
                                                            <Flex direction={'column'} m={!revDir ? '16px 0 0 16px' : '16px 16px 0 0'}>
                                                                <Flex w={'100%'} ai={'center'} background={colors.backgrounds.grey_light} p={isStageCanBeAssigned ? '8px 2px' : '16px 2px'}>
                                                                    <Typography color={colors.grayText} variant={'body1'} sx={{margin: revDir ? '0 8px 0 0' : '0 0 0 8px'}} fontWeight={600}>{t('Employee')}</Typography>
                                                                    <Typography color={colors.grayText} variant={'body1'} sx={{margin: revDir ? '0 32px 0 0' : '0 0 0 32px'}}>{employee.employee}</Typography>
                                                                </Flex>

                                                                <CustomTableHeader
                                                                    columns={columnWidths}
                                                                    hideIf={isLessThenCustomQuery}
                                                                    padding={'8px 0 12px 0 '}
                                                                    width={'100%'}
                                                                />

                                                                {employee.accounts.map(account => {
                                                                    const status = getColorAndTextByAccountStatus(account.statusManager);
                                                                    const isLoading = commonSelectors.loadingIds.some(e => e === account.id);

                                                                    return(
                                                                        <CustomTableRow divider disableHover innerWrapperWidth={'100%'}>
                                                                            <S.TableRowItemWrapper width={columnWidths[0]?.width ?? '25%'}>
                                                                                {isEditable &&
                                                                                    <Checkbox
                                                                                        disabled={(stageManagers.selectingAccountsOfActorEmail !== null && stageManagers.selectingAccountsOfActorEmail !== actor.email)}
                                                                                        size={'small'}
                                                                                        checked={stageManagers.isSelectedManagerAccount(account.id)}
                                                                                        onChange={(e) => e.target.checked ? stageManagers.handleSelectManagerAccount(account.id, actor.email) : stageManagers.handleDeselectManagerAccount(account.id, actor.email)}
                                                                                        sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}
                                                                                    />
                                                                                }
                                                                                <Typography sx={{color: colors.darkText}} variant={matches_1920Up ? 'body1' : 'body2'}>{account.applicationAccessName ?? '- - -'}</Typography>
                                                                            </S.TableRowItemWrapper>

                                                                            <S.TableRowItemWrapper width={columnWidths[1]?.width ?? '25%'}>
                                                                                <Typography sx={{color: colors.darkText}} variant={matches_1920Up ? 'body1' : 'body2'}>{account.account}</Typography>
                                                                            </S.TableRowItemWrapper>

                                                                            <S.TableRowItemWrapper width={columnWidths[2]?.width ?? '25%'}>
                                                                                <Flex w={'90%'} direction={'column'}>
                                                                                    {account.roles.map(e => {
                                                                                        const moreThen0Roles = e.rolePermissions.length > 0;
                                                                                        return(
                                                                                            <Flex key={e.roleName} w={'100%'} ai={'center'} jc={'space-between'}>
                                                                                                <Typography sx={{color: colors.darkText, width: '50%',  cursor: 'pointer'}} variant={matches_1920Up ? 'body1' : 'body2'}>{e.roleName}</Typography>
                                                                                                <Typography
                                                                                                    sx={{color: moreThen0Roles ? colors.primary.blue : colors.darkText, cursor: moreThen0Roles ? 'pointer' : 'default', textDecoration: moreThen0Roles ? 'underline' : ''}}
                                                                                                    variant={matches_1920Up ? 'body1' : 'body2'}
                                                                                                    onClick={() => commonActions.onRoleClick(e.roleName, e.rolePermissions)}
                                                                                                >{e.rolePermissions.length} {t('permissions')}</Typography>
                                                                                            </Flex>
                                                                                        )
                                                                                    })}
                                                                                </Flex>
                                                                            </S.TableRowItemWrapper>

                                                                            <S.TableRowItemWrapper width={columnWidths[3]?.width ?? '25%'}>
                                                                                {isEditable ?
                                                                                    <Flex w={'100%'}>
                                                                                        <RadioGroup
                                                                                            row
                                                                                            value={account.statusManager ?? ''}
                                                                                            sx={{flexWrap: 'nowrap'}}
                                                                                            onChange={(e, value) => {
                                                                                                const data = mapCampaignReviewExecutorAccountFillableTypeWithApplicationForSingleUpdateRequest(account);
                                                                                                stageManagers.handleChangeSingleStatus(data.app, data.account, stageManagers.stage!.id, actor.email, value)
                                                                                            }}
                                                                                        >
                                                                                            <FormControlLabel disabled={isLoading} value={ACCOUNT_KEEP_STATUS} control={<Radio size={'small'} />} label={<Typography variant={matches_1920Up ? 'body1' : 'body2'}>{t('Keep')}</Typography>} />
                                                                                            <FormControlLabel disabled={isLoading} value={ACCOUNT_REVOKE_STATUS} control={<Radio size={'small'} />} label={<Typography variant={matches_1920Up ? 'body1' : 'body2'}>{t('Revoke')}</Typography>} />
                                                                                            <FormControlLabel disabled={isLoading} value={ACCOUNT_DELETE_STATUS} control={<Radio size={'small'} />} label={<Typography variant={matches_1920Up ? 'body1' : 'body2'}>{t('Delete account')}</Typography>} />
                                                                                        </RadioGroup>

                                                                                        <Button
                                                                                            variant={'text'}
                                                                                            size={'small'}
                                                                                            disabled={account.statusManager === null}
                                                                                            sx={{margin: revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                                                                            onClick={() => {
                                                                                                const data = mapCampaignReviewExecutorAccountFillableTypeWithApplicationForSingleUpdateRequest(account);
                                                                                                stageManagers.editJustification(data.app, data.account, stageManagers.stage!.id, actor.email);
                                                                                            }}
                                                                                        >
                                                                                            {(account.commentManager ?? '').length > 0 ? t('View justification') : t('Add justification')}
                                                                                        </Button>
                                                                                    </Flex>
                                                                                    :
                                                                                    <Flex w={'100%'} direction={'column'}>
                                                                                        <Typography color={status.color} variant={'body2'}>{status.text}</Typography>
                                                                                        {(account.commentManager ?? '').length > 0 && <Typography
                                                                                            color={colors.primary.blue}
                                                                                            variant={'body2'}
                                                                                            sx={{margin: '8px 0 0 0', cursor: 'pointer'}}
                                                                                            onClick={() => stageManagers.viewJustification(actor.email, account.commentManager)}
                                                                                        >{t('View justification')}</Typography>
                                                                                        }
                                                                                    </Flex>
                                                                                }
                                                                            </S.TableRowItemWrapper>
                                                                        </CustomTableRow>
                                                                    )
                                                                })}
                                                            </Flex>
                                                        )
                                                    })}
                                                </Flex>
                                            )
                                        })}
                                    </Flex>
                                }
                            </>
                        }
                    </>
                }

                {commonSelectors.step === REVIEW_CAMPAIGN_STEP_VENDOR && stageVendors.stage &&
                    <>
                        {stageVendors.stage.status === TERMINATED_STATUS ?
                            <>
                                <Flex m={'16px 0 0 0'}>
                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{t('Stage vendors was terminated on')} </Typography>
                                    <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{parseDateAuto(stageVendors.stage.statusChangedDate)}</Typography>
                                </Flex>
                            </>
                            :
                            <>
                                {isLessThenCustomQuery ?
                                    <>
                                        <Flex w={'100%'} m={'20px 0'} direction={'column'}>
                                            <Flex ai={'center'}>
                                                <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0', lineHeight: 'normal'}}>{t('Deadline for Stage Vendor owners')}</Typography>
                                                <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0', lineHeight: 'normal'}}>{parseDateAuto(stageVendors.stage.expirationDate)}</Typography>
                                            </Flex>
                                            {stageVendors.isDeadlinePassed && <Typography variant={'body1'} color={colors.decorative.orange} sx={{margin: '8px 0 0 0'}}>{t('The deadline has arrived')}</Typography>}
                                        </Flex>

                                        {stageVendors.stage.actors.map((actor, id) => {
                                            const isStageCanBeAssigned = actor.status === IN_PROGRESS_STATUS && !actor.mainControl;
                                            const isEditable: boolean = actor.status === IN_PROGRESS_STATUS && actor.mainControl;
                                            const isStageCanBeVerified = !((actor.appsData ?? []).flatMap(e => e.data).some(e => e.statusVendor === null)) && isEditable;

                                            return(
                                                // MANAGER ARRAY
                                                <Flex w={'100%'} maxw={'1400px'} direction={'column'} m={id > 0 ? '20px 0 0 0' : ''}>
                                                    {/*TOP*/}
                                                    <>
                                                        {actor.appsData && actor.appsData.map(app => {
                                                            return(
                                                                <>
                                                                    <Flex w={'100%'} direction={'column'} background={colors.backgrounds.grey_light} p={isStageCanBeAssigned ? '8px 2px' : '16px 2px'}>
                                                                        <Flex ai={'center'} m={'8px 0 0 0'}>
                                                                            <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} fontWeight={600}>{t('Vendor')}</Typography>
                                                                            <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 32px 0 0' : '0 0 0 32px'}}>{app.vendorId}</Typography>
                                                                        </Flex>

                                                                        <Flex ai={'center'} m={'8px 0 0 0'}>
                                                                            <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} fontWeight={600}>{t('Owner')}</Typography>
                                                                            <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 32px 0 0' : '0 0 0 32px'}}>{actor.firstName} {actor.lastName}</Typography>
                                                                        </Flex>

                                                                        <Flex ai={'center'} m={'8px 0 0 0'}>
                                                                            {isStageCanBeAssigned &&
                                                                                <LoadingButton
                                                                                    variant={'contained'}
                                                                                    size={'small'}
                                                                                    onClick={() => stageManagers.handleAssignSubStepToMe(actor.email)}
                                                                                    loading={commonSelectors.loadingAssignActorEmail.some(e => e === actor.email)}
                                                                                    // sx={{'& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px' : '0 -4px 0 8px',}}}
                                                                                >{t('Assign to me')}
                                                                                </LoadingButton>
                                                                            }
                                                                            {isStageCanBeVerified &&
                                                                                <LoadingButton
                                                                                    variant={'contained'}
                                                                                    size={'small'}
                                                                                    onClick={() => stageManagers.handleVerifyActorChanges(actor.email)}
                                                                                    loading={commonSelectors.loadingVerifyActorEmails.some(e => e === actor.email)} //is loading verify changes for current user
                                                                                >{t('Verify changes')}
                                                                                </LoadingButton>
                                                                            }
                                                                        </Flex>
                                                                    </Flex>

                                                                    {isEditable &&
                                                                        <Flex ai={'center'} m={'16px 0'}>
                                                                            <FormGroup
                                                                                sx={{margin: !revDir ? '0 16px' : '0 8px 0 16px'}}
                                                                            >
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Checkbox
                                                                                            disabled={(stageVendors.selectingVendorsAccountsOfActorEmail !== null && stageVendors.selectingVendorsAccountsOfActorEmail !== actor.email)}
                                                                                            size={'small'}
                                                                                            checked={stageVendors.isSelectedAllVendorsAccounts(actor.email)}
                                                                                            onChange={(e) => e.target.checked ? stageVendors.handleSelectAllVendorAccounts(actor.email) : stageVendors.handleDeselectAllVendorAccounts()}
                                                                                        />}
                                                                                    label={t("Select all")}
                                                                                />
                                                                            </FormGroup>

                                                                            <Button
                                                                                variant={'outlined'}
                                                                                sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                                                                size={'small'}
                                                                                onClick={stageVendors.handleKeepAllSelectedVendorAccounts}
                                                                                disabled={!stageVendors.selectedVendorsAccounts.length || (stageVendors.selectingVendorsAccountsOfActorEmail !== null && stageVendors.selectingVendorsAccountsOfActorEmail !== actor.email) || commonSelectors.loadingIds.length > 0}
                                                                            >
                                                                                {`${t('Keep all')} ${stageVendors.selectedVendorsAccounts.length ?? ''}`}
                                                                            </Button>

                                                                            <Button
                                                                                variant={'outlined'}
                                                                                sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                                                                size={'small'}
                                                                                onClick={stageVendors.handleRevokeAllSelectedVendorAccounts}
                                                                                disabled={!stageVendors.selectedVendorsAccounts.length || (stageVendors.selectingVendorsAccountsOfActorEmail !== null && stageVendors.selectingVendorsAccountsOfActorEmail !== actor.email) || commonSelectors.loadingIds.length > 0}
                                                                            >
                                                                                {`${t('Revoke all')} ${stageVendors.selectedVendorsAccounts.length ?? ''}`}
                                                                            </Button>

                                                                            <Button
                                                                                variant={'outlined'}
                                                                                size={'small'}
                                                                                sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                                                                onClick={stageVendors.handleDeleteAllSelectedVendorAccounts}
                                                                                disabled={!stageVendors.selectedVendorsAccounts.length || (stageVendors.selectingVendorsAccountsOfActorEmail !== null && stageVendors.selectingVendorsAccountsOfActorEmail !== actor.email) || commonSelectors.loadingIds.length > 0}
                                                                            >
                                                                                {`${t('Delete all')} ${stageVendors.selectedVendorsAccounts.length ?? ''}`}
                                                                            </Button>
                                                                        </Flex>
                                                                    }


                                                                    {app.data.map(account => {
                                                                        const status = getColorAndTextByAccountStatus(account.statusVendor);
                                                                        const isLoading = commonSelectors.loadingIds.some(e => e === account.id);

                                                                        return(
                                                                            <S.UserCardWrapper revDir={revDir} p={'10px 8px'}>
                                                                                <Flex ai={'center'}>
                                                                                    {isEditable &&
                                                                                        <Checkbox
                                                                                            disabled={(stageVendors.selectingVendorsAccountsOfActorEmail !== null && stageVendors.selectingVendorsAccountsOfActorEmail !== actor.email)}
                                                                                            size={'small'}
                                                                                            checked={stageVendors.isSelectedVendorAccount(account.id)}
                                                                                            onChange={(e) => e.target.checked ? stageVendors.handleSelectVendorAccount(account.id, actor.email) : stageVendors.handleDeselectVendorAccount(account.id, actor.email)}
                                                                                            sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}
                                                                                        />
                                                                                    }
                                                                                    <Typography sx={{color: colors.darkText}} variant={'body2'}>{account.account ?? '- - -'}</Typography>
                                                                                </Flex>

                                                                                <Flex ai={'center'} m={'8px 0 0 0'}>
                                                                                    <Typography fontWeight={400} noWrap={true} sx={{lineHeight: 'normal', fontSize: '14px', margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} color={colors.lightGrayText}>{t(`Employee`)}:</Typography>
                                                                                    <Typography variant={'body2'} color={colors.grayText}>{account.employee}</Typography>
                                                                                </Flex>

                                                                                <Flex direction={'column'} m={'8px 0 0 0'}>
                                                                                    <Typography fontWeight={400} noWrap={true} sx={{lineHeight: 'normal', fontSize: '14px'}} color={colors.lightGrayText}>{t(`Permissions`)}:</Typography>
                                                                                    <Flex w={isMobile ? '90%' : '50%'} direction={'column'}>
                                                                                        {account.roles.map(e => {
                                                                                            const moreThen0Roles = e.rolePermissions.length > 0;
                                                                                            return(
                                                                                                <Flex key={e.roleName} w={'100%'} ai={'center'} jc={'space-between'}>
                                                                                                    <Typography sx={{color: colors.darkText, width: '50%',  cursor: 'pointer'}} variant={matches_1920Up ? 'body1' : 'body2'}>{e.roleName}</Typography>
                                                                                                    <Flex w={'50%'}>
                                                                                                        <Typography
                                                                                                            sx={{color: moreThen0Roles ? colors.primary.blue : colors.darkText, cursor: moreThen0Roles ? 'pointer' : 'default', textDecoration: moreThen0Roles ? 'underline' : ''}}
                                                                                                            variant={matches_1920Up ? 'body1' : 'body2'}
                                                                                                            onClick={() => commonActions.onRoleClick(e.roleName, e.rolePermissions)}
                                                                                                        >{e.rolePermissions.length} {t('permissions')}</Typography>
                                                                                                    </Flex>
                                                                                                </Flex>
                                                                                            )
                                                                                        })}
                                                                                    </Flex>
                                                                                </Flex>

                                                                                <Flex direction={'column'} m={'8px 0 0 0'}>
                                                                                    <Typography fontWeight={400} noWrap={true} sx={{lineHeight: 'normal', fontSize: '14px'}} color={colors.lightGrayText}>{t(`Status`)}:</Typography>
                                                                                    {isEditable ?
                                                                                        <RadioGroup
                                                                                            row
                                                                                            value={account.statusVendor ?? ''}
                                                                                            sx={{flexWrap: 'nowrap'}}
                                                                                            onChange={(e, value) => {
                                                                                                stageVendors.handleChangeSingleStatus(
                                                                                                    {
                                                                                                        applicationAccessId: app.applicationAccessId,
                                                                                                        applicationAccessName: app.applicationAccessName,
                                                                                                        applicationId: app.applicationId,
                                                                                                        vendorId: app.vendorId,
                                                                                                        vendorName: app.vendorName,
                                                                                                    },
                                                                                                    account, actor.email, value);
                                                                                            }}
                                                                                        >
                                                                                            <FormControlLabel disabled={isLoading} value={ACCOUNT_KEEP_STATUS} control={<Radio size={'small'} />} label={<Typography variant={'body2'}>{t('Keep')}</Typography>} />
                                                                                            <FormControlLabel disabled={isLoading} value={ACCOUNT_REVOKE_STATUS} control={<Radio size={'small'} />} label={<Typography variant={'body2'}>{t('Revoke')}</Typography>} />
                                                                                            <FormControlLabel disabled={isLoading} value={ACCOUNT_DELETE_STATUS} control={<Radio size={'small'} />} label={<Typography variant={'body2'}>{t('Delete account')}</Typography>} />
                                                                                        </RadioGroup>
                                                                                        :
                                                                                        <Typography color={status.color} variant={'body2'}>{t(status.text)}</Typography>
                                                                                    }

                                                                                    <Flex>
                                                                                        {isEditable ?
                                                                                            <Button
                                                                                                variant={'text'}
                                                                                                size={'small'}
                                                                                                disabled={account.statusVendor === null}
                                                                                                onClick={() => {
                                                                                                    stageVendors.editJustification(
                                                                                                        {
                                                                                                            applicationAccessId: app.applicationAccessId,
                                                                                                            applicationAccessName: app.applicationAccessName,
                                                                                                            applicationId: app.applicationId,
                                                                                                            vendorId: app.vendorId,
                                                                                                            vendorName: app.vendorName,
                                                                                                        }, account, actor.email)
                                                                                                }}
                                                                                            >
                                                                                                {(account.commentVendor ?? '').length > 0 ? t('Edit justification') : t('Add justification')}
                                                                                            </Button>
                                                                                            :
                                                                                            (account.commentVendor ?? '').length > 0 && <Typography
                                                                                                color={colors.primary.blue}
                                                                                                variant={'body2'}
                                                                                                sx={{margin: '8px 0 0 0', cursor: 'pointer'}}
                                                                                                onClick={() => stageVendors.viewJustification(actor.email, account.commentVendor)}
                                                                                            >{t('View justification')}</Typography>
                                                                                        }
                                                                                    </Flex>
                                                                                </Flex>
                                                                            </S.UserCardWrapper>
                                                                        )
                                                                    })}


                                                                </>
                                                            )
                                                        })
                                                        }
                                                    </>
                                                </Flex>
                                            )

                                        })
                                        }
                                    </>
                                    :
                                    <>
                                        <Flex w={'100%'} ai={'center'} m={'20px 0'}>
                                            <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{t('Deadline for Vendor owners')}</Typography>
                                            <Typography variant={'body1'} color={colors.grayText} sx={{ lineHeight: 'normal'}}>{parseDateAuto(stageVendors.stage.expirationDate)}</Typography>
                                            {stageVendors.isDeadlinePassed && <Typography variant={'body1'} color={colors.decorative.orange}>{t('The deadline has arrived')}</Typography>}
                                        </Flex>

                                        {stageVendors.stage.actors.map((actor, id) => {
                                            const isStageCanBeAssigned = actor.status === IN_PROGRESS_STATUS && !actor.mainControl;
                                            const isEditable: boolean = actor.status === IN_PROGRESS_STATUS && actor.mainControl;
                                            const isStageCanBeVerified = !((actor.appsData ?? []).flatMap(e => e.data).some(e => e.statusVendor === null)) && isEditable;

                                            return(
                                                <Flex w={'100%'} maxw={'1400px'} direction={'column'} m={id > 0 ? '20px 0 0 0' : ''}>
                                                    {actor.appsData && actor.appsData.map(app => {
                                                        return(
                                                            <Flex direction={'column'} m={'16px 0 0 0'}>
                                                                <Flex w={'100%'} ai={'center'} background={colors.backgrounds.grey_light} p={isStageCanBeAssigned ? '8px 2px' : '16px 2px'}>
                                                                    <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} fontWeight={600}>{t('Vendor')}</Typography>
                                                                    <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 32px 0 0' : '0 0 0 32px'}}>{app.vendorName}</Typography>

                                                                    <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} fontWeight={600}>{t('Owner')}</Typography>
                                                                    <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 32px 0 0' : '0 0 0 32px'}}>{actor.firstName} {actor.lastName}</Typography>

                                                                    {isStageCanBeAssigned &&
                                                                        <LoadingButton
                                                                            variant={'contained'}
                                                                            size={'small'}
                                                                            onClick={() => stageVendors.handleAssignSubStepToMe(actor.email)}
                                                                            loading={commonSelectors.loadingAssignActorEmail.some(e => e === actor.email)}
                                                                            // sx={{'& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px' : '0 -4px 0 8px',}}}
                                                                        >{t('Assign to me')}
                                                                        </LoadingButton>
                                                                    }

                                                                    {isStageCanBeVerified &&
                                                                        <LoadingButton
                                                                            variant={'contained'}
                                                                            size={'small'}
                                                                            onClick={() => stageVendors.handleVerifyActorChanges(actor.email)}
                                                                            loading={commonSelectors.loadingVerifyActorEmails.some(e => e === actor.email)} //is loading verify changes for current user
                                                                        >{t('Verify changes')}
                                                                        </LoadingButton>
                                                                    }
                                                                </Flex>

                                                                {isEditable &&
                                                                    <Flex ai={'center'} m={'16px 0'}>
                                                                        <FormGroup
                                                                            sx={{margin: !revDir ? '0 16px' : '0 8px 0 16px'}}
                                                                        >
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        disabled={(stageVendors.selectingVendorsAccountsOfActorEmail !== null && stageVendors.selectingVendorsAccountsOfActorEmail !== actor.email)}
                                                                                        size={'small'}
                                                                                        checked={stageVendors.isSelectedAllVendorsAccounts(actor.email)}
                                                                                        onChange={(e) => e.target.checked ? stageVendors.handleSelectAllVendorAccounts(actor.email) : stageVendors.handleDeselectAllVendorAccounts()}
                                                                                    />}
                                                                                label={t("Select all")}
                                                                            />
                                                                        </FormGroup>

                                                                        <Button
                                                                            variant={'outlined'}
                                                                            sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                                                            size={'small'}
                                                                            onClick={stageVendors.handleKeepAllSelectedVendorAccounts}
                                                                            disabled={!stageVendors.selectedVendorsAccounts.length || (stageVendors.selectingVendorsAccountsOfActorEmail !== null && stageVendors.selectingVendorsAccountsOfActorEmail !== actor.email) || commonSelectors.loadingIds.length > 0}
                                                                        >
                                                                            {`${t('Keep all')} ${stageVendors.selectedVendorsAccounts.length ?? ''}`}
                                                                        </Button>

                                                                        <Button
                                                                            variant={'outlined'}
                                                                            sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                                                            size={'small'}
                                                                            onClick={stageVendors.handleRevokeAllSelectedVendorAccounts}
                                                                            disabled={!stageVendors.selectedVendorsAccounts.length || (stageVendors.selectingVendorsAccountsOfActorEmail !== null && stageVendors.selectingVendorsAccountsOfActorEmail !== actor.email) || commonSelectors.loadingIds.length > 0}
                                                                        >
                                                                            {`${t('Revoke all')} ${stageVendors.selectedVendorsAccounts.length ?? ''}`}
                                                                        </Button>

                                                                        <Button
                                                                            variant={'outlined'}
                                                                            sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                                                            size={'small'}
                                                                            onClick={stageVendors.handleDeleteAllSelectedVendorAccounts}
                                                                            disabled={!stageVendors.selectedVendorsAccounts.length || (stageVendors.selectingVendorsAccountsOfActorEmail !== null && stageVendors.selectingVendorsAccountsOfActorEmail !== actor.email) || commonSelectors.loadingIds.length > 0}
                                                                        >
                                                                            {`${t('Delete all')} ${stageVendors.selectedVendorsAccounts.length ?? ''}`}
                                                                        </Button>
                                                                    </Flex>
                                                                }

                                                                <CustomTableHeader
                                                                    columns={vendorColumnWidths}
                                                                    hideIf={isLessThenCustomQuery}
                                                                    padding={'8px 0 12px 0 '}
                                                                    width={'100%'}
                                                                />

                                                                {app.data.map(data => {
                                                                    const status = getColorAndTextByAccountStatus(data.statusVendor);
                                                                    const isLoading = commonSelectors.loadingIds.some(e => e === data.id);
                                                                    return(
                                                                        <CustomTableRow divider disableHover innerWrapperWidth={'100%'}>
                                                                            <S.TableRowItemWrapper width={columnWidths[0]?.width ?? '25%'}>
                                                                                {isEditable &&
                                                                                    <Checkbox
                                                                                        disabled={(stageVendors.selectingVendorsAccountsOfActorEmail !== null && stageVendors.selectingVendorsAccountsOfActorEmail !== actor.email)}
                                                                                        size={'small'}
                                                                                        checked={stageVendors.isSelectedVendorAccount(data.id)}
                                                                                        onChange={(e) => e.target.checked ? stageVendors.handleSelectVendorAccount(data.id, actor.email) : stageVendors.handleDeselectVendorAccount(data.id, actor.email)}
                                                                                        sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}
                                                                                    />
                                                                                }
                                                                                <Typography sx={{color: colors.darkText}} variant={matches_1920Up ? 'body1' : 'body2'}>{data.employee ?? '- - -'}</Typography>
                                                                            </S.TableRowItemWrapper>

                                                                            <S.TableRowItemWrapper width={columnWidths[1]?.width ?? '25%'}>
                                                                                <Typography sx={{color: colors.darkText}} variant={matches_1920Up ? 'body1' : 'body2'}>{data.account}</Typography>
                                                                            </S.TableRowItemWrapper>

                                                                            <S.TableRowItemWrapper width={columnWidths[2]?.width ?? '25%'}>
                                                                                <Flex w={'90%'} direction={'column'}>
                                                                                    {data.roles.map(e => {
                                                                                        const moreThen0Roles = e.rolePermissions.length > 0;
                                                                                        return(
                                                                                            <Flex key={e.roleName} w={'100%'} ai={'center'} jc={'space-between'}>
                                                                                                <Typography sx={{color: colors.darkText, width: '50%',  cursor: 'pointer'}} variant={matches_1920Up ? 'body1' : 'body2'}>{e.roleName}</Typography>
                                                                                                <Typography
                                                                                                    sx={{color: moreThen0Roles ? colors.primary.blue : colors.darkText, cursor: moreThen0Roles ? 'pointer' : 'default', textDecoration: moreThen0Roles ? 'underline' : ''}}
                                                                                                    variant={matches_1920Up ? 'body1' : 'body2'}
                                                                                                    onClick={() => commonActions.onRoleClick(e.roleName, e.rolePermissions)}
                                                                                                >{e.rolePermissions.length} {t('permissions')}</Typography>
                                                                                            </Flex>
                                                                                        )
                                                                                    })}
                                                                                </Flex>
                                                                            </S.TableRowItemWrapper>

                                                                            <S.TableRowItemWrapper width={columnWidths[3]?.width ?? '25%'}>
                                                                                {isEditable ?
                                                                                    <Flex w={'100%'}>
                                                                                        <RadioGroup
                                                                                            row
                                                                                            value={data.statusVendor ?? ''}
                                                                                            sx={{flexWrap: 'nowrap'}}
                                                                                            onChange={(e, value) => {
                                                                                                stageVendors.handleChangeSingleStatus(
                                                                                                    {
                                                                                                        applicationAccessId: app.applicationAccessId,
                                                                                                        applicationAccessName: app.applicationAccessName,
                                                                                                        applicationId: app.applicationId,
                                                                                                        vendorId: app.vendorId,
                                                                                                        vendorName: app.vendorName,
                                                                                                    },
                                                                                                    data, actor.email, value);
                                                                                            }}
                                                                                        >
                                                                                            <FormControlLabel disabled={isLoading} value={ACCOUNT_KEEP_STATUS} control={<Radio size={'small'} />} label={<Typography variant={matches_1920Up ? 'body1' : 'body2'}>{t('Keep')}</Typography>} />
                                                                                            <FormControlLabel disabled={isLoading} value={ACCOUNT_REVOKE_STATUS} control={<Radio size={'small'} />} label={<Typography variant={matches_1920Up ? 'body1' : 'body2'}>{t('Revoke')}</Typography>} />
                                                                                            <FormControlLabel disabled={isLoading} value={ACCOUNT_DELETE_STATUS} control={<Radio size={'small'} />} label={<Typography variant={matches_1920Up ? 'body1' : 'body2'}>{t('Delete account')}</Typography>} />
                                                                                        </RadioGroup>

                                                                                        <Button
                                                                                            variant={'text'}
                                                                                            size={'small'}
                                                                                            disabled={data.statusVendor === null}
                                                                                            onClick={() => {
                                                                                                stageVendors.editJustification(
                                                                                                    {
                                                                                                        applicationAccessId: app.applicationAccessId,
                                                                                                        applicationAccessName: app.applicationAccessName,
                                                                                                        applicationId: app.applicationId,
                                                                                                        vendorId: app.vendorId,
                                                                                                        vendorName: app.vendorName,
                                                                                                    }, data, actor.email)
                                                                                            }}
                                                                                        >
                                                                                            {(data.commentVendor ?? '').length > 0 ? t('Edit justification') : t('Add justification')}
                                                                                        </Button>
                                                                                    </Flex>
                                                                                    :
                                                                                    <Flex w={'100%'} direction={'column'}>
                                                                                        <Typography color={status.color} variant={'body2'}>{t(status.text)}</Typography>
                                                                                        {(data.commentVendor ?? '').length > 0 && <Typography
                                                                                            color={colors.primary.blue}
                                                                                            variant={'body2'}
                                                                                            sx={{margin: '8px 0 0 0', cursor: 'pointer'}}
                                                                                            onClick={() => stageVendors.viewJustification(actor.email, data.commentVendor)}
                                                                                        >{t('View justification')}</Typography>
                                                                                        }
                                                                                    </Flex>
                                                                                }
                                                                            </S.TableRowItemWrapper>
                                                                        </CustomTableRow>
                                                                    )
                                                                })}
                                                            </Flex>
                                                        )
                                                    })}
                                                </Flex>
                                            )

                                        })
                                        }
                                    </>
                                }
                            </>
                        }
                    </>
                }

                {commonSelectors.step === REVIEW_CAMPAIGN_STEP_EXECUTOR && stageExecutor.stage &&
                    <>
                        {stageExecutor.stage.status === TERMINATED_STATUS ?
                            <>
                                <Flex m={'16px 0 0 0'}>
                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{t('Stage executor was terminated on')} </Typography>
                                    <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{parseDateAuto(stageExecutor.stage.statusChangedDate)}</Typography>
                                </Flex>
                            </>
                            :
                            <>
                                {isLessThenCustomQuery ?
                                    <>
                                        <Flex w={'100%'} m={'20px 0'} direction={'column'}>
                                            {stageExecutor.hoursFromLastIntegrationRun > 0 &&
                                                <Flex ai={'center'} m={'8px 0 0 0'}>
                                                    <RunningWithErrorsIcon color={'primary'}/>
                                                    <Typography sx={{margin: !revDir ? '0 0 0 8px' : '0 8px 0 0'}} variant={'body1'} color={colors.grayText}>{t(`Any changes made in last {{hoursFromLastIntegrationRun}} hours will not be included in the review campaign.`, {hoursFromLastIntegrationRun: stageExecutor.hoursFromLastIntegrationRun})}</Typography>
                                                </Flex>
                                            }

                                            <Flex ai={'center'} m={'8px 0'}>
                                                <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{t('Deadline for Stage execution')}</Typography>
                                                <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{parseDateAuto(stageExecutor.stage.expirationDate)}</Typography>
                                            </Flex>
                                            {stageExecutor.isDeadlinePassed && <Typography variant={'body1'} color={colors.decorative.orange} sx={{margin: '8px 0'}}>{t('The deadline has arrived')}</Typography>}

                                            <Flex m={!stageExecutor.isDeadlinePassed ? '8px 0 0 0' : ''}>
                                                <LoadingButton
                                                    variant={'contained'}
                                                    size={'small'}
                                                    onClick={() => stageExecutor.handleFinishExecutionStage()}
                                                    loading={commonSelectors.loadingVerifyActorEmails.some(e => e === '')} //is loading verify changes for current user
                                                    // sx={{'& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px' : '0 -4px 0 8px',}}}
                                                >{t('Execution complete')}
                                                </LoadingButton>
                                            </Flex>

                                            {stageVendors.stage && stageVendors.stage.actors.map((actor, id) => {

                                                return(
                                                    <Flex w={'100%'} maxw={'1400px'} direction={'column'} m={id > 0 ? '20px 0 0 0' : ''}>
                                                        {actor.appsData && actor.appsData.map(app => {
                                                            const revokeReason = stageExecutor.getVerifyApplicationRevokeReason(app.applicationAccessId);

                                                            // const [status, setStatus] = useState<{revoked: boolean, kept: boolean, deleted: boolean}>({revoked: true, kept: true, deleted: true});
                                                            // const revokedAccounts = app.data.filter(e => e.)
                                                            return(
                                                                <Flex direction={'column'} m={'16px 0 0 0'}>
                                                                    <Flex w={'100%'} ai={'center'} background={colors.backgrounds.grey_light} p={'16px 2px'}>
                                                                        <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} fontWeight={600}>{t('Application')}</Typography>
                                                                        <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 32px 0 0' : '0 0 0 32px'}}>{app.applicationAccessName}</Typography>
                                                                        {revokeReason && revokeReason.comment && <Button
                                                                            onClick={() => stageExecutor.openShowVerifyApplicationRevokeReason(revokeReason.leavedBy, revokeReason.comment!)}
                                                                            size={'small'}
                                                                        >{t('Show revoke reason')}</Button>
                                                                        }
                                                                    </Flex>

                                                                    {app.data.map(data => {
                                                                        const managerStatus = getColorAndTextByAccountStatus(data.statusManager);
                                                                        const vendorStatus = getColorAndTextByAccountStatus(data.statusVendor);

                                                                        return(
                                                                            <S.UserCardWrapper revDir={revDir} p={'10px 8px'}>
                                                                                <Flex ai={'center'} m={'8px 0 0 0'}>
                                                                                    <Typography fontWeight={400} noWrap={true} sx={{lineHeight: 'normal', fontSize: '14px', margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} color={colors.lightGrayText}>{t(`Account`)}:</Typography>
                                                                                    <Typography variant={'body2'} color={colors.grayText}>{data.account ?? '- - -'}</Typography>
                                                                                </Flex>

                                                                                <Flex ai={'center'} m={'8px 0 0 0'}>
                                                                                    <Typography fontWeight={400} noWrap={true} sx={{lineHeight: 'normal', fontSize: '14px', margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} color={colors.lightGrayText}>{t(`Employee`)}:</Typography>
                                                                                    <Typography variant={'body2'} color={colors.grayText}>{data.employee}</Typography>
                                                                                </Flex>

                                                                                <Flex direction={'column'} m={'8px 0 0 0'}>
                                                                                    <Typography fontWeight={400} noWrap={true} sx={{lineHeight: 'normal', fontSize: '14px'}} color={colors.lightGrayText}>{t(`Permissions`)}:</Typography>
                                                                                    <Flex w={isMobile ? '90%' : '50%'} direction={'column'}>
                                                                                        {data.roles.map(e => {
                                                                                            const moreThen0Roles = e.rolePermissions.length > 0;
                                                                                            return(
                                                                                                <Flex key={e.roleName} w={'100%'} ai={'center'} jc={'space-between'}>
                                                                                                    <Typography sx={{color: colors.darkText, width: '50%',  cursor: 'pointer'}} variant={matches_1920Up ? 'body1' : 'body2'}>{e.roleName}</Typography>
                                                                                                    <Flex w={'50%'}>
                                                                                                        <Typography
                                                                                                            sx={{color: moreThen0Roles ? colors.primary.blue : colors.darkText, cursor: moreThen0Roles ? 'pointer' : 'default', textDecoration: moreThen0Roles ? 'underline' : ''}}
                                                                                                            variant={matches_1920Up ? 'body1' : 'body2'}
                                                                                                            onClick={() => commonActions.onRoleClick(e.roleName, e.rolePermissions)}
                                                                                                        >{e.rolePermissions.length} {t('permissions')}</Typography>
                                                                                                    </Flex>
                                                                                                </Flex>
                                                                                            )
                                                                                        })}
                                                                                    </Flex>
                                                                                </Flex>

                                                                                <Flex ai={'center'} m={'8px 0 0 0'}>
                                                                                    <Typography fontWeight={400} noWrap={true} sx={{lineHeight: 'normal', fontSize: '14px', margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} color={colors.lightGrayText}>{t(`Manager status`)}:</Typography>
                                                                                    <Typography color={managerStatus.color} variant={'body2'}>{t(managerStatus.text)}</Typography>
                                                                                </Flex>

                                                                                {(data.commentManager ?? '').length > 0 &&
                                                                                    <Typography
                                                                                        color={colors.primary.blue}
                                                                                        variant={'body2'}
                                                                                        sx={{
                                                                                            margin: '8px 0 0 0',
                                                                                            cursor: 'pointer'
                                                                                        }}
                                                                                        onClick={() => stageExecutor.viewJustification(actor.email, data.commentManager)}
                                                                                    >{t('View manager justification')}</Typography>
                                                                                }

                                                                                <Flex ai={'center'} m={'8px 0 0 0'}>
                                                                                    <Typography fontWeight={400} noWrap={true} sx={{lineHeight: 'normal', fontSize: '14px', margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} color={colors.lightGrayText}>{t(`Vendor owner status`)}:</Typography>
                                                                                    <Typography color={vendorStatus.color} variant={'body2'}>{t(vendorStatus.text)}</Typography>
                                                                                </Flex>

                                                                                {(data.commentVendor ?? '').length > 0 &&
                                                                                    <Typography
                                                                                        color={colors.primary.blue}
                                                                                        variant={'body2'}
                                                                                        sx={{
                                                                                            margin: '8px 0 0 0',
                                                                                            cursor: 'pointer'
                                                                                        }}
                                                                                        onClick={() => stageExecutor.viewJustification(actor.email, data.commentVendor)}
                                                                                    >{t('View vendor owner justification')}</Typography>
                                                                                }
                                                                            </S.UserCardWrapper>
                                                                        )
                                                                    })}

                                                                </Flex>
                                                            )
                                                        })}
                                                    </Flex>
                                                )

                                            })
                                            }

                                        </Flex>
                                    </>
                                    :
                                    <>
                                        {stageExecutor.hoursFromLastIntegrationRun > 0 &&
                                            <Flex ai={'center'} m={'20px 0 0 0'}>
                                                <RunningWithErrorsIcon color={'primary'}/>
                                                <Typography sx={{margin: !revDir ? '0 0 0 8px' : '0 8px 0 0'}} variant={'body1'} color={colors.grayText}>{t(`Any changes made in last {{hoursFromLastIntegrationRun}} hours will not be included in the review campaign.`, {hoursFromLastIntegrationRun: stageExecutor.hoursFromLastIntegrationRun})}</Typography>
                                            </Flex>
                                        }
                                        <Flex w={'100%'} ai={'center'} m={'16px 0'}>
                                            <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{t('Deadline for execution')}</Typography>
                                            <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{parseDateAuto(stageExecutor.stage.expirationDate)}</Typography>
                                            {stageExecutor.isDeadlinePassed && <Typography variant={'body1'} color={colors.decorative.orange}>{t('The deadline has arrived')}</Typography>}
                                        </Flex>

                                        <LoadingButton
                                            variant={'contained'}
                                            size={'small'}
                                            onClick={() => stageExecutor.handleFinishExecutionStage()}
                                            loading={commonSelectors.loadingVerifyActorEmails.some(e => e === '')} //is loading verify changes for current user
                                            // sx={{'& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px' : '0 -4px 0 8px',}}}
                                        >{t('Execution complete')}
                                        </LoadingButton>

                                        {stageVendors.stage && stageVendors.stage.actors.map((actor, id) => {
                                            return(
                                                <Flex w={'100%'} maxw={'1400px'} direction={'column'} m={id > 0 ? '20px 0 0 0' : ''}>
                                                    {actor.appsData && actor.appsData.map(app => {
                                                        const revokeReason = stageExecutor.getVerifyApplicationRevokeReason(app.applicationAccessId);

                                                        // const [status, setStatus] = useState<{revoked: boolean, kept: boolean, deleted: boolean}>({revoked: true, kept: true, deleted: true});
                                                        // const revokedAccounts = app.data.filter(e => e.)
                                                        return(
                                                            <Flex direction={'column'} m={'16px 0 0 0'}>
                                                                <Flex w={'100%'} ai={'center'} background={colors.backgrounds.grey_light} p={'16px 2px'}>
                                                                    <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} fontWeight={600}>{t('Application')}</Typography>
                                                                    <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 32px 0 0' : '0 0 0 32px'}}>{app.applicationAccessName}</Typography>
                                                                    {revokeReason && revokeReason.comment && <Button
                                                                        onClick={() => stageExecutor.openShowVerifyApplicationRevokeReason(revokeReason.leavedBy, revokeReason.comment!)}
                                                                        size={'small'}
                                                                        >{t('Show revoke reason')}</Button>
                                                                    }
                                                                </Flex>

                                                                <CustomTableHeader
                                                                    columns={[{name: t('Employee'), width: '20%'}, {name: t('Account'), width: '20%'}, {name: t('Permissions'), width: '20%'}, {name: t('Manager status'), width: '20%'}, {name: t('Vendor owner status'), width: '20%'}]}
                                                                    hideIf={isLessThenCustomQuery}
                                                                    padding={'8px 0 12px 0 '}
                                                                    width={'100%'}
                                                                />

                                                                {app.data.map(data => {
                                                                    const managerStatus = getColorAndTextByAccountStatus(data.statusManager);
                                                                    const vendorStatus = getColorAndTextByAccountStatus(data.statusVendor);

                                                                    return(
                                                                        <CustomTableRow divider disableHover innerWrapperWidth={'100%'}>
                                                                            <S.TableRowItemWrapper width={'20%'}>
                                                                                <Typography sx={{color: colors.darkText}} variant={matches_1920Up ? 'body1' : 'body2'}>{data.employee ?? '- - -'}</Typography>
                                                                            </S.TableRowItemWrapper>

                                                                            <S.TableRowItemWrapper width={'20%'}>
                                                                                <Typography sx={{color: colors.darkText}} variant={matches_1920Up ? 'body1' : 'body2'}>{data.account}</Typography>
                                                                            </S.TableRowItemWrapper>

                                                                            <S.TableRowItemWrapper width={'20%'}>
                                                                                <Flex w={'90%'} direction={'column'}>
                                                                                    {data.roles.map(e => {
                                                                                        const moreThen0Roles = e.rolePermissions.length > 0;
                                                                                        return(
                                                                                            <Flex key={e.roleName} w={'100%'} ai={'center'} jc={'space-between'}>
                                                                                                <Typography sx={{color: colors.darkText, width: '50%',  cursor: 'pointer'}} variant={matches_1920Up ? 'body1' : 'body2'}>{e.roleName}</Typography>
                                                                                                <Typography
                                                                                                    sx={{color: moreThen0Roles ? colors.primary.blue : colors.darkText, cursor: moreThen0Roles ? 'pointer' : 'default', textDecoration: moreThen0Roles ? 'underline' : ''}}
                                                                                                    variant={matches_1920Up ? 'body1' : 'body2'}
                                                                                                    onClick={() => commonActions.onRoleClick(e.roleName, e.rolePermissions)}
                                                                                                >{e.rolePermissions.length} {t('permissions')}</Typography>
                                                                                            </Flex>
                                                                                        )
                                                                                    })}
                                                                                </Flex>
                                                                            </S.TableRowItemWrapper>

                                                                            <S.TableRowItemWrapper width={'20%'}>
                                                                                <Flex w={'100%'} direction={'column'}>
                                                                                    <Typography color={managerStatus.color} variant={'body2'}>{t(managerStatus.text)}</Typography>
                                                                                    {(data.commentManager ?? '').length > 0 && <Typography
                                                                                        color={colors.primary.blue}
                                                                                        variant={'body2'}
                                                                                        sx={{margin: '8px 0 0 0', cursor: 'pointer'}}
                                                                                        onClick={() => stageExecutor.viewJustification(actor.email, data.commentManager)}
                                                                                    >{t('View justification')}</Typography>
                                                                                    }
                                                                                </Flex>
                                                                            </S.TableRowItemWrapper>

                                                                            <S.TableRowItemWrapper width={'20%'}>
                                                                                <Flex w={'100%'} direction={'column'}>
                                                                                    <Typography color={vendorStatus.color} variant={'body2'}>{t(vendorStatus.text)}</Typography>
                                                                                    {(data.commentVendor ?? '').length > 0 && <Typography
                                                                                        color={colors.primary.blue}
                                                                                        variant={'body2'}
                                                                                        sx={{margin: '8px 0 0 0', cursor: 'pointer'}}
                                                                                        onClick={() => stageExecutor.viewJustification(actor.email, data.commentVendor)}
                                                                                    >{t('View justification')}</Typography>
                                                                                    }
                                                                                </Flex>
                                                                            </S.TableRowItemWrapper>
                                                                        </CustomTableRow>
                                                                    )
                                                                })}
                                                            </Flex>
                                                        )
                                                    })}
                                                </Flex>
                                            )

                                        })
                                        }

                                    </>
                                }
                            </>
                        }
                    </>
                }

                {commonSelectors.step === REVIEW_CAMPAIGN_STEP_VERIFY && stageVerify.stage &&
                    <>
                        {stageVerify.stage.status === TERMINATED_STATUS ?
                            <>
                                <Flex m={'16px 0 0 0'}>
                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{t('Stage verify was terminated on')} </Typography>
                                    <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{parseDateAuto(stageVerify.stage.statusChangedDate)}</Typography>
                                </Flex>
                            </>
                            :
                            <>
                                {isLessThenCustomQuery ?
                                    <>
                                        <Flex ai={'center'} m={'8px 0'}>
                                            <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{t('Deadline for stage verify')}</Typography>
                                            <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{parseDateAuto(stageVerify.stage.expirationDate)}</Typography>
                                        </Flex>
                                        {stageVerify.isDeadlinePassed && <Typography variant={'body1'} color={colors.decorative.orange} sx={{margin: '8px 0 0 0'}}>{t('The deadline has arrived')}</Typography>}

                                        {/*<Tabs variant={'scrollable'} value={stageVerify.isNow ? 'now' : 'before'} onChange={(e, newValue: string) => stageVerify.setIsNow(newValue === 'now')}>*/}
                                        {/*    <Tab label={("Before")} value={'before'} />*/}
                                        {/*    <Tab label={("Now")} value={'now'}/>*/}
                                        {/*</Tabs>*/}

                                        {stageVerify.stage.actors.map((actor, id) => {
                                            const isStageCanBeAssigned = actor.status === IN_PROGRESS_STATUS && !actor.mainControl;
                                            const isEditable: boolean = actor.status === IN_PROGRESS_STATUS && actor.mainControl;

                                            return(
                                                // MANAGER ARRAY
                                                <Flex w={'100%'} maxw={'1400px'} direction={'column'} m={id > 0 ? '20px 0 0 0' : ''}>
                                                    {/*TOP*/}
                                                    <>
                                                        {actor.appsData && actor.appsData.map(app => {
                                                            return(
                                                                <>
                                                                    <Flex w={'100%'} direction={'column'} background={colors.backgrounds.grey_light} p={isStageCanBeAssigned ? '8px 2px' : '16px 2px'}>
                                                                        <Flex ai={'center'} m={'8px 0 0 0'}>
                                                                            <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} fontWeight={600}>{t('Vendor')}</Typography>
                                                                            <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 32px 0 0' : '0 0 0 32px'}}>{app.vendorName}</Typography>
                                                                        </Flex>

                                                                        <Flex ai={'center'} m={'8px 0 0 0'}>
                                                                            <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} fontWeight={600}>{t('Owner')}</Typography>
                                                                            <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 32px 0 0' : '0 0 0 32px'}}>{actor.firstName} {actor.lastName}</Typography>
                                                                        </Flex>

                                                                        <Flex ai={'center'} m={'8px 0 0 0'}>
                                                                            {isStageCanBeAssigned &&
                                                                                <LoadingButton
                                                                                    variant={'contained'}
                                                                                    size={'small'}
                                                                                    onClick={() => stageVerify.handleAssignSubStepToMe(actor.email)}
                                                                                    loading={commonSelectors.loadingAssignActorEmail.some(e => e === actor.email)}
                                                                                    // sx={{'& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px' : '0 -4px 0 8px',}}}
                                                                                >{t('Assign to me')}
                                                                                </LoadingButton>
                                                                            }

                                                                            {isEditable && <LoadingButton
                                                                                variant={'contained'}
                                                                                size={'small'}
                                                                                onClick={() => stageVerify.handleVerifyChanges(actor.email)}
                                                                                loading={commonSelectors.loadingVerifyActorEmails.some(e => e === actor.email)} //is loading verify changes for current user
                                                                                sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                                                            >{t('Verify changes')}
                                                                            </LoadingButton>}

                                                                            {isEditable && <LoadingButton
                                                                                variant={'contained'}
                                                                                size={'small'}
                                                                                onClick={() => stageVerify.handleDeclineChanges(actor.email)}
                                                                                loading={commonSelectors.loadingVerifyActorEmails.some(e => e === actor.email)} //is loading verify changes for current user
                                                                            >{t('Decline changes')}
                                                                            </LoadingButton>}
                                                                        </Flex>
                                                                    </Flex>

                                                                    {app.data.map(account => {
                                                                        return(
                                                                            <S.UserCardWrapper revDir={revDir} p={'10px 8px'}>
                                                                                <Flex ai={'center'} m={'8px 0 0 0'}>
                                                                                    <Typography fontWeight={400} noWrap={true} sx={{lineHeight: 'normal', fontSize: '14px', margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} color={colors.lightGrayText}>{(`Account`)}:</Typography>
                                                                                    <Typography variant={'body2'} color={colors.grayText}>{account.account ?? '- - -'}</Typography>
                                                                                </Flex>

                                                                                <Flex ai={'center'} m={'8px 0 0 0'}>
                                                                                    <Typography fontWeight={400} noWrap={true} sx={{lineHeight: 'normal', fontSize: '14px', margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} color={colors.lightGrayText}>{(`Employee`)}:</Typography>
                                                                                    <Typography variant={'body2'} color={colors.grayText}>{account.employee}</Typography>
                                                                                </Flex>

                                                                                <Flex direction={'column'} m={'8px 0 0 0'}>
                                                                                    <Typography fontWeight={400} noWrap={true} sx={{lineHeight: 'normal', fontSize: '14px'}} color={colors.lightGrayText}>{t(`Permissions`)}:</Typography>
                                                                                    <Flex w={isMobile ? '90%' : '50%'} direction={'column'}>
                                                                                        {account.roles.map(e => {
                                                                                            const moreThen0Roles = e.rolePermissions.length > 0;
                                                                                            return(
                                                                                                <Flex key={e.roleName} w={'100%'} ai={'center'} jc={'space-between'}>
                                                                                                    <Typography sx={{color: colors.darkText, width: '50%',  cursor: 'pointer'}} variant={matches_1920Up ? 'body1' : 'body2'}>{e.roleName}</Typography>
                                                                                                    <Flex w={'50%'}>
                                                                                                        <Typography
                                                                                                            sx={{color: moreThen0Roles ? colors.primary.blue : colors.darkText, cursor: moreThen0Roles ? 'pointer' : 'default', textDecoration: moreThen0Roles ? 'underline' : ''}}
                                                                                                            variant={matches_1920Up ? 'body1' : 'body2'}
                                                                                                            onClick={() => commonActions.onRoleClick(e.roleName, e.rolePermissions)}
                                                                                                        >{e.rolePermissions.length} {t('permissions')}</Typography>
                                                                                                    </Flex>
                                                                                                </Flex>
                                                                                            )
                                                                                        })}
                                                                                    </Flex>
                                                                                </Flex>
                                                                            </S.UserCardWrapper>
                                                                        )
                                                                    })}


                                                                </>
                                                            )
                                                        })
                                                        }
                                                    </>
                                                </Flex>
                                            )

                                        })
                                        }
                                    </>
                                    :
                                    <>
                                        <Flex w={'100%'} ai={'center'} m={'16px 0'}>
                                            <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{t('Deadline for stage verify')}</Typography>
                                            <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{parseDateAuto(stageVerify.stage.expirationDate)}</Typography>
                                            {stageVerify.isDeadlinePassed && <Typography variant={'body1'} color={colors.decorative.orange}>{t('The deadline has arrived')}</Typography>}
                                        </Flex>

                                        {/*<Tabs variant={'scrollable'} value={stageVerify.isNow ? 'now' : 'before'} onChange={(e, newValue: string) => stageVerify.setIsNow(newValue === 'now')}>*/}
                                        {/*    <Tab label={("Before")} value={'before'} />*/}
                                        {/*    <Tab label={("Now")} value={'now'}/>*/}
                                        {/*</Tabs>*/}

                                        {stageVerify.stage.actors.map((actor, id) => {
                                            const isStageCanBeAssigned = actor.status === IN_PROGRESS_STATUS && !actor.mainControl;
                                            const isEditable: boolean = actor.status === IN_PROGRESS_STATUS && actor.mainControl;

                                            return(
                                                <Flex w={'100%'} maxw={'1400px'} direction={'column'} m={id > 0 ? '20px 0 0 0' : ''}>
                                                    {actor.appsData && actor.appsData.map(app => {
                                                        return(
                                                            <Flex direction={'column'} m={'16px 0 0 0'}>
                                                                <Flex w={'100%'} ai={'center'} background={colors.backgrounds.grey_light} p={isStageCanBeAssigned ? '8px 2px' : '16px 2px'}>
                                                                    <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} fontWeight={600}>{t('Vendor')}</Typography>
                                                                    <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 32px 0 0' : '0 0 0 32px'}}>{app.vendorName}</Typography>

                                                                    <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} fontWeight={600}>{t('Owner')}</Typography>
                                                                    <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 32px 0 0' : '0 0 0 32px'}}>{actor.firstName} {actor.lastName}</Typography>

                                                                    {isStageCanBeAssigned &&
                                                                        <LoadingButton
                                                                            variant={'contained'}
                                                                            size={'small'}
                                                                            onClick={() => stageVerify.handleAssignSubStepToMe(actor.email)}
                                                                            loading={commonSelectors.loadingAssignActorEmail.some(e => e === actor.email)}
                                                                            // sx={{'& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px' : '0 -4px 0 8px',}}}
                                                                        >{t('Assign to me')}
                                                                        </LoadingButton>
                                                                    }

                                                                    {isEditable && <LoadingButton
                                                                        variant={'contained'}
                                                                        size={'small'}
                                                                        onClick={() => stageVerify.handleVerifyChanges(actor.email)}
                                                                        loading={commonSelectors.loadingVerifyActorEmails.some(e => e === actor.email)} //is loading verify changes for current user
                                                                        sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                                                    >{t('Verify changes')}
                                                                    </LoadingButton>}

                                                                    {isEditable && <LoadingButton
                                                                        variant={'contained'}
                                                                        size={'small'}
                                                                        onClick={() => stageVerify.handleDeclineChanges(actor.email)}
                                                                        loading={commonSelectors.loadingVerifyActorEmails.some(e => e === actor.email)} //is loading verify changes for current user
                                                                    >{t('Decline changes')}
                                                                    </LoadingButton>}
                                                                </Flex>

                                                                <CustomTableHeader
                                                                    columns={[{name: t('Employee'), width: '30%'}, {name: t('Account'), width: '30%'}, {name: t('Permission'), width: '40%'}]}
                                                                    hideIf={isLessThenCustomQuery}
                                                                    padding={'8px 0 12px 0 '}
                                                                    width={'100%'}
                                                                />

                                                                {app.data.map(data => {
                                                                    return(
                                                                        <CustomTableRow divider disableHover innerWrapperWidth={'100%'}>
                                                                            <S.TableRowItemWrapper width={'30%'}>
                                                                                <Typography sx={{color: colors.darkText}} variant={matches_1920Up ? 'body1' : 'body2'}>{data.employee ?? '- - -'}</Typography>
                                                                            </S.TableRowItemWrapper>

                                                                            <S.TableRowItemWrapper width={'30%'}>
                                                                                <Typography sx={{color: colors.darkText}} variant={matches_1920Up ? 'body1' : 'body2'}>{data.account}</Typography>
                                                                            </S.TableRowItemWrapper>

                                                                            <S.TableRowItemWrapper width={'40%'}>
                                                                                <Flex w={'90%'} direction={'column'}>
                                                                                    {data.roles.map(e => {
                                                                                        const moreThen0Roles = e.rolePermissions.length > 0;
                                                                                        return(
                                                                                            <Flex key={e.roleName} w={'100%'} ai={'center'} jc={'space-between'}>
                                                                                                <Typography sx={{color: colors.darkText, width: '50%',  cursor: 'pointer'}} variant={matches_1920Up ? 'body1' : 'body2'}>{e.roleName}</Typography>
                                                                                                <Typography
                                                                                                    sx={{color: moreThen0Roles ? colors.primary.blue : colors.darkText, cursor: moreThen0Roles ? 'pointer' : 'default', textDecoration: moreThen0Roles ? 'underline' : ''}}
                                                                                                    variant={matches_1920Up ? 'body1' : 'body2'}
                                                                                                    onClick={() => commonActions.onRoleClick(e.roleName, e.rolePermissions)}
                                                                                                >{e.rolePermissions.length} {t('permissions')}</Typography>
                                                                                            </Flex>
                                                                                        )
                                                                                    })}
                                                                                </Flex>
                                                                            </S.TableRowItemWrapper>
                                                                        </CustomTableRow>
                                                                    )
                                                                })}
                                                            </Flex>
                                                        )
                                                    })}
                                                </Flex>
                                            )

                                        })
                                        }
                                    </>
                                }
                            </>
                        }
                    </>
                }

                {commonSelectors.step === REVIEW_CAMPAIGN_STEP_SIGN && stageSign.stage &&
                    <>
                        {stageSign.stage.status === TERMINATED_STATUS ?
                            <>
                                <Flex m={'16px 0 0 0'}>
                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{t('Stage sign was terminated on')} </Typography>
                                    <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{parseDateAuto(stageSign.stage.statusChangedDate)}</Typography>
                                </Flex>
                            </>
                            :
                            <>
                                <Flex w={'100%'} ai={'center'} m={'16px 0'}>
                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{t('Deadline for stage sign')}</Typography>
                                    <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{parseDateAuto(stageSign.stage.expirationDate)}</Typography>
                                    {stageSign.isDeadlinePassed && <Typography variant={'body1'} color={colors.decorative.orange}>{t('The deadline has arrived')}</Typography>}
                                </Flex>

                                <Flex w={'100%'} ai={'center'} m={'16px 0 8px 0'}>
                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{t('Signer')}</Typography>
                                    <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', lineHeight: 'normal'}}>{executor?.finalApprover.email}</Typography>
                                </Flex>

                                <Flex w={'100%'} ai={'center'}>
                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{lineHeight: 'normal'}}>{t('Document to sign')}</Typography>

                                    {executor && <Flex ai={'center'}>
                                        <IconButton onClick={stageSign.handleViewDocument}>
                                            <VisibilityIcon fontSize={'small'} color={'primary'}/>
                                        </IconButton>
                                        <Typography variant={'body1'} color={colors.grayText}
                                                    sx={{lineHeight: 'normal'}}>{`${executor.name}_reviewCampaign.pdf`}</Typography>
                                    </Flex>}
                                </Flex>
                            </>
                        }
                    </>
                }

                {commonSelectors.step === REVIEW_CAMPAIGN_FINISH_STEP_CUSTOM && executor &&
                    <ExactInstructionWrapper>
                        <Flex ai={'center'} m={'16px 0 0 0'}>
                            <IconButton onClick={stageFinish.handleViewDocument}>
                                <VisibilityIcon fontSize={'small'} color={'primary'}/>
                            </IconButton>
                            <IconButton onClick={stageFinish.handleDownloadDocument}>
                                <DownloadIcon fontSize={'small'} color={'primary'} />
                            </IconButton>
                            <Typography variant={'body1'} color={colors.grayText} sx={{lineHeight: 'normal'}}>{`${executor.name}_reviewCampaign.pdf`}</Typography>
                        </Flex>

                        <Flex ai={'center'} m={'16px 0 0 0'}>
                            <IconButton onClick={stageFinish.handleViewPipelineDetails}>
                                <VisibilityIcon fontSize={'small'} color={'primary'}/>
                            </IconButton>
                            <IconButton onClick={stageFinish.handleDownloadPipelineDetails}>
                                <DownloadIcon fontSize={'small'} color={'primary'} />
                            </IconButton>
                            <Typography variant={'body1'} color={colors.grayText} sx={{lineHeight: 'normal'}}>{`${executor.name}_reviewCampaign_pipelineDetails.pdf`}</Typography>
                        </Flex>

                        <ExpandableContainer margin={'0'} isOpen={stageFinish._expands.info} toggle={() => stageFinish.toggleExpand('info')} text={t('Information')}>
                            <Typography variant={'body1'} color={colors.lightGrayText}>{t('Review campaign name')} <i style={{color: colors.grayText}}>{executor.name}</i></Typography>
                            <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '8px 0 0 0'}}>{t('Description')}</Typography>
                            <Typography variant={'body1'} color={colors.grayText}>{executor.description ?? '- - -'}</Typography>
                        </ExpandableContainer>

                        <ExpandableContainer isOpen={stageFinish._expands.schedule} toggle={() => stageFinish.toggleExpand('schedule')} text={t('Scheduler')}>
                            <Typography variant={'body1'} color={colors.lightGrayText}>{('Start date')} <b color={colors.grayText}>{parseDateAuto(executor.runDate)}</b></Typography>
                            <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '8px 0 0 0'}}>{t('Finish date')} <b color={colors.grayText}>{parseDateAuto(stageSign.stage?.statusChangedDate)}</b></Typography>
                            {/*<Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '8px 0 0 0'}}>{('Repeat every')} <b color={colors.grayText}>{translatedRepeat.num} {translatedRepeat.value}</b></Typography>*/}
                            {/*<Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '8px 0 0 0'}}>{('Next start')} <b color={colors.grayText}>{nextStart ? parseDate(nextStart, currentLanguage.momentLocale, EXACT_INSTRUCTION_DATE_FORMAT) : '- - -'}</b></Typography>*/}
                        </ExpandableContainer>

                        <ExpandableContainer isOpen={stageFinish._expands.apps} toggle={() => stageFinish.toggleExpand('apps')} text={t('Included applications')}>
                            {executor.applications.map(e => {
                                return(
                                    <>
                                        <Flex w={'95%'} jc={'space-between'} ai={'center'} p={'8px 0'} m={'8px 0 0 0'}>
                                            <Typography variant={'body1'} color={colors.grayText}>{e.name ?? e.id}</Typography>
                                        </Flex>
                                        <Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable}}/>
                                    </>
                                )
                            })}
                        </ExpandableContainer>

                        <ExpandableContainer isOpen={stageFinish._expands.eta} toggle={() => stageFinish.toggleExpand('eta')} text={t('Estimating time')}>
                            <Typography variant={'body1'} color={colors.lightGrayText}>{('Deadline for Stage Managers')}: <i style={{color: colors.grayText}}>{parseDateAuto(stageManagers.stage?.expirationDate)}</i></Typography>
                            <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '8px 0 0 0'}}>{t('Deadline for Stage Vendor owners')}: <i style={{color: colors.grayText}}>{parseDateAuto(stageVendors.stage?.statusChangedDate)}</i></Typography>
                            <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '8px 0 0 0'}}>{t('Deadline for Stage Execution')}: <i style={{color: colors.grayText}}>{parseDateAuto(stageExecutor.stage?.statusChangedDate)}</i></Typography>
                            <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '8px 0 0 0'}}>{t('Deadline for Stage Verify')}: <i style={{color: colors.grayText}}>{parseDateAuto(stageVerify.stage?.statusChangedDate)}</i></Typography>
                            <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: '8px 0 0 0'}}>{t('Deadline for Stage Sign')}: <i style={{color: colors.grayText}}>{parseDateAuto(stageSign.stage?.statusChangedDate)}</i></Typography>
                        </ExpandableContainer>

                        <ExpandableContainer isOpen={stageFinish._expands.finalApprover} toggle={() => stageFinish.toggleExpand('finalApprover')} text={t('Final approver')}>
                            <Typography variant={'body1'} color={colors.grayText}>{executor.finalApprover.email}</Typography>
                        </ExpandableContainer>

                        <ExpandableContainer isOpen={stageFinish._expands.evidence} toggle={() => stageFinish.toggleExpand('evidence')} text={t('Saving')}>
                            <Flex w={'100%'} direction={'column'}>
                                {executor.evidences.map(el => <SelectedEvidenceCard key={el.evidenceId} evidence={el}/>)}
                            </Flex>
                        </ExpandableContainer>
                    </ExactInstructionWrapper>
                }
            </S.CampaignReviewArrayWrapper>

            {/*DIALOGS*/}
            <PreviewRoleDialog />
            <ShowVerifyApplicationRevokeReasonDialog />
            <TerminateExecutorDialog />
            <ViewOrEditJustificationDialog />
            <ViewJustificationDialog />
            <DeclineVerifyStepDialog />
        </Flex>

    )
}
