import React, {FC} from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {normalizeSettingIdNameToLowerCase} from "../../../helpers";
import {TextFieldCustom} from "../../styled";
import {useCreateSetting} from "../../../hooks/useVendorsDialogs/createSettings";
import {LoadingButton} from "@mui/lab";

export const CreateSettingDialog: FC<{onSuccess?: (str: string) => void}> = ({onSuccess}) => {
    const {
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        handleChange,
        form,
        isOk,
        isExist,
        type
    } = useCreateSetting(onSuccess);
    const {revDir, t} = useMainTranslation('',{keyPrefix:'pathVendors.VendorsSettings'});
    const {isMobile} = useMedia();

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            {type &&
                <>
                    <DialogTitle>{t('Add new item')}</DialogTitle>

                    <DialogContent>
                        <TextFieldCustom
                            size={'small'}
                            label={t("Item name")}
                            sx={{margin: '5px 0 0 0', width: isMobile ? '100%' : '98%'}}
                            value={form.newName}
                            name={'newName'}
                            required
                            autoFocus
                            disabled={isLoading}
                            onChange={handleChange}
                            error={isExist}
                            helperText={isExist ? t(`Such {{name}} already exists.`,{name:t(normalizeSettingIdNameToLowerCase(type))}) : ''}
                        />

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{t('Cancel')}</Button>
                        {isLoading ? (
                            <LoadingButton loading
                                           sx={{paddingLeft: '35px', margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                           variant="outlined"
                            >
                                {t('Add')}
                            </LoadingButton>
                            ) : (
                            <Button
                                variant={'contained'}
                                disabled={!isOk ||isExist }
                                size={isMobile ? 'small' : 'medium'}
                                onClick={handleSubmit}
                                sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                            >
                                {t('Add')}
                            </Button>
                        )}
                    </DialogActions>
                </>
            }
        </Dialog>
    )
}