import * as S from './styled';
import {TKycTemplateScreeningSettingsCheck} from "../../types";
import {Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import colors from '../../../../../newShared/theme/colors';
import CommonSwitch from "../../../../../newShared/components/Basic/CommonSwitch";

type TemplatesChecksTableProps = {
    // checks: SystemCheckModel[];
    formChecks: TKycTemplateScreeningSettingsCheck[];
    onSwitchToggle: (check: TKycTemplateScreeningSettingsCheck) => void,
    onRatioChange: (check: TKycTemplateScreeningSettingsCheck, ratio: number | number[]) => void,
    disabled?: boolean,
    disabledMatchingRatio?: boolean
}
export const TemplatesChecksTable = ({onRatioChange, onSwitchToggle, formChecks, disabled, disabledMatchingRatio}: TemplatesChecksTableProps) => {
    const {revDir} = useMainTranslation();
    return(
        <S.ChecksTableContainer>
            <Table>
                <TableHead>
                    <TableRow sx={{
                        '& .MuiTableCell-root': {
                            padding: '16px 8px',
                            backgroundColor: colors.backgrounds.grey_light
                        }
                    }}>
                        <TableCell sx={{width: '50%'}} align={!revDir ? 'left' : 'right'}>
                            <Typography variant={'body2'} color={colors.text.grey_dark}>{('Check')}</Typography>
                        </TableCell>

                        <TableCell sx={{width: '20%'}} align={!revDir ? 'left' : 'right'}>
                            <Typography variant={'body2'} color={colors.text.grey_dark}>{('Apply')}</Typography>
                        </TableCell>

                        <TableCell sx={{width: '30%'}} align={!revDir ? 'left' : 'right'}>
                            <Typography variant={'body2'} color={colors.text.grey_dark}>{('Matching ratio')}</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {formChecks.map(e => {
                        return(
                            <TableRow key={e.id} sx={{
                                '& .MuiTableCell-root': {
                                    padding: '16px 8px',
                                }
                            }}>
                                <TableCell sx={{width: '50%'}}>
                                    <Typography color={disabled ? colors.text.grey_dark : colors.grayText} variant={'body2'}>{e.name}</Typography>
                                </TableCell>

                                <TableCell sx={{width: '20%'}}>
                                    <CommonSwitch
                                        disabled={disabled}
                                        size={'small'}
                                        checked={e?.apply ?? false}
                                        onChange={(event, value) => onSwitchToggle({...e!, apply: value})}
                                    />
                                </TableCell>

                                <TableCell sx={{width: '30%'}}>
                                    <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                        <Typography color={disabled ? colors.text.grey_dark : colors.grayText} variant={'body2'}>{('0')}</Typography>
                                        <S.CustomSlider
                                            disabled={!e.apply || disabled || disabledMatchingRatio}
                                            size={'small'}
                                            min={0}
                                            max={100}
                                            value={e.matchingRatio}
                                            valueLabelDisplay={(e.apply) ? 'on' : undefined}
                                            onChange={(event, value) => onRatioChange(e!, value)}
                                            valueLabelFormat={(a) => `${a / 100}`}
                                        />
                                        <Typography color={disabled ? colors.text.grey_dark : colors.grayText} variant={'body2'}>{('1')}</Typography>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </S.ChecksTableContainer>
    )
}