import {print} from "graphql";
import {gql} from "@apollo/react-hooks";

export const KycClientFragment = print(gql`
    fragment KycClientFragment on TKycTargetModel {
        id
        status
        createdType
        lastModified
        risk
        templateId
        templateName
        archived
        targetType
        createdDate
        fields {
            id
            name
            type
            required
            system
            order
            value
            fileName
            requiredForTemplate
            checkAlias {
                checkId
                alias
            }
            systemAlias
            dictionary
        }
    }
`)

export const KycRefactorTemplateModelFragment = print(gql`
    fragment KycRefactorTemplateModelFragment on KycRefactorTemplateModel {
        id
        name
        description
        type
        fields{
            id
            name
            type
            required
            system
            order
            checkAlias{
                checkId
                alias
            }
            requiredForTemplate
            systemAlias
            dictionary
        }
        screeningSettings{
            repeat
            checks{
                id
                name
                matchingRatio
                apply
            }
        }
        lastScreeningDate
        nextScreeningDate
    }
`)

export const KycClientFieldFragment = print(gql`
    fragment KycClientFieldFragment on KycTargetFieldModel {
        id
        name
        type
        required
        system
        order
        value
        fileName
        requiredForTemplate
        checkAlias {
            checkId
            alias
        }
        systemAlias
        dictionary
    }
`)

export const KycScreeningFragment = print(gql`
    fragment KycScreeningFragment on KycScreeningModel {
        id
        startDate
        endDate
        status
        targetType
        targetName
        targetId
        status
        templateId
        templateName
        result {
            entityId
            score
            matchedObject
            exceptionId
            entityCaption
        }
        checkId
        checkName
    }
`)

export const KycScreeningFullInfoFragment = print(gql`
    fragment KycScreeningFullInfoFragment on KycScreeningFullInfoModel {
        screening {
            id
            startDate
            endDate
            status
            targetType
            targetName
            targetId
            templateName
            exceptionCount
            matchingRatio
            latestScreeningId
            result {
                entityId
                score
                matchedObject
                exceptionId
                entityCaption
            }
            checkId
            checkName
        }
        exceptions {
            id
            targetId
            createdDate
            creator
            checkId
            reason
            limited
            till
            deleted
            deletedBy
            deletedDate
            entityId
            score
            entityCaption
            
        }
        target {
            id
            status
            createdType
            templateId
            templateName
            archived
            targetType
            createdDate
            fields {
                id
                name
                type
                required
                system
                order
                value
                fileName
                requiredForTemplate
                checkAlias {
                    checkId
                    alias
                }
                systemAlias
                dictionary
            }
        }
    }
`)


export const KycRefactorRequestModelFragment = print(gql`
    fragment KycRefactorRequestModelFragment on KycRefactorRequestModel {
        id
        requestType
        status
        templateId
        templateType
        sendDate
        responseDate
        createdTargetId
        token
        sendTo
        filledFields{
            id
            name
            type
            required
            system
            order
            checkAlias{
                checkId
                alias
            }
            value
            requiredForTemplate
            fileName
            systemAlias
            dictionary
        }
    }
`)

export const KycExceptionModelFragment = print(gql`
    fragment KycExceptionModelFragment on KycExceptionModel {
        id
        targetId
        createdDate
        creator
        checkId
        reason
        limited
        till
        deleted
        deletedBy
        deletedDate
        entityId
        score
        entityCaption
    }
`)
