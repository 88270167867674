import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import React, {useEffect, useState} from "react";
import {
    dialogs,
    eraseSelectedApp,
    hideExportCsv,
    loadings,
    openDeleteApp,
    openDeleteRow,
    openEditApp,
    openExportCsv,
    openNonCustomAppAccountMapDialog,
    openReleaseDialog,
    openShowPermissions,
    revertChanges,
    selectedAppEditable,
    selectedAppOrigin,
    setImportCsvDialogAction
} from "../../store/slice";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    CUSTOM_APP_TYPE,
    PATH_ACCESS_MANAGEMENT_APPS_EXACT,
    PATH_ACCESS_MANAGEMENT_APPS_EXACT_CREATE_ROW,
    PATH_ACCESS_MANAGEMENT_APPS_EXACT_EDIT_ROW,
    PATH_ACCESS_MANAGEMENT_APPS_LIST,
    PATH_LOCAL_ACCESS_MANAGEMENT
} from "../../constants";
import {
    EditAccessManagementApp,
    ExportCsvAccessManagementApp,
    GetAccessManagementAppById,
    GetAccessManagementAvailableFieldsForExport
} from "../../store/actions";
import {PATH_LOCAL_EMPLOYEES_ID} from "../../../employeesRefactor/constants";
import {AccessManagementAppAccount} from "../../types";

export const useAccessManagementAppsExact = () => {
    //root
    const dispatch = useDispatch();
    const history = useHistory();
    const {id} = useParams<{id: string}>();
    //selectors
    const editableApp = useSelector(selectedAppEditable);
    const originApp = useSelector(selectedAppOrigin);
    const {exact, exportCsv, update, isLoadingFields} = useSelector(loadings);
    const {isOpen, fields} = useSelector(dialogs).exportCsv;
    //other
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const controller = new AbortController();

    useEffect(() => {
        if(!exact && !editableApp && id){
            dispatch(GetAccessManagementAppById({data: {id}, signal: controller.signal}));
        }

        return () => {
            controller.abort();
            // dispatch(eraseSelectedApp());
        }
        //eslint-disable-next-line
    }, []);

    useSetBreadcrumbs([
        {
            title: tMenu('Access management'),
            path: PATH_LOCAL_ACCESS_MANAGEMENT
        },
        {
            title: tMenu('Applications'),
            path: PATH_ACCESS_MANAGEMENT_APPS_LIST
        },
        {
            title: editableApp?.name ?? tMenu('Loading...'),
            path: PATH_ACCESS_MANAGEMENT_APPS_EXACT.replace(':id', id)
        }
    ]);

    //actions
    const handleGoBack = () => {
        history.push(PATH_ACCESS_MANAGEMENT_APPS_LIST);
        dispatch(eraseSelectedApp());
    }

    const handleOpenImportCsv = () => {
        editableApp?.id && dispatch(setImportCsvDialogAction({isOpen: true, data: {applicationAccessId: editableApp.id}}));
    }

    const handleExportCsv = (fieldKeys: string[]) => {
        originApp && dispatch(ExportCsvAccessManagementApp({
            id: originApp.id,
            name: originApp.name,
            fieldKeys,
            isDownload: originApp.dataTable.length < 10000
        }));
    }

    const handleOpenExportCsv = () => {
        dispatch(openExportCsv());
        dispatch(GetAccessManagementAvailableFieldsForExport());
    }

    const handleCloseExportCsv = () => {
        dispatch(hideExportCsv());
    }

    const openAddRow = () => {
        //check if editable
        history.push(PATH_ACCESS_MANAGEMENT_APPS_EXACT_CREATE_ROW.replace(':id', id))
    }

    const handleSave = () => {
        editableApp && dispatch(EditAccessManagementApp({data: {app: editableApp}}))
    }

    //MENU
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const isEditable = Boolean(originApp?.type === CUSTOM_APP_TYPE);

    return{
        app: editableApp,
        isLoading: exact,
        isLoadingExportCsv: exportCsv,
        isLoadingSave: update,
        isLoadingFields,
        actions: {
            handleOpenRelease: () => {
                dispatch(openReleaseDialog());
                handleClose();
            },
            handleGoBack,
            handleOpenImportCsv,
            handleOpenExportCsv,
            openAddRow,
            handleSave,
            handleRevertChanges: () => dispatch(revertChanges()),
            handleGoToVendorOwner: (id: string) => history.push(PATH_LOCAL_EMPLOYEES_ID.replace(':id', id)),
            handleDeleteApp: () => dispatch(openDeleteApp()),
            handleEditApp: () => dispatch(openEditApp()),
        },

        exportCsv: {
            isOpen,
            availableFields: fields,
            handleExportCsv,
            handleClose: handleCloseExportCsv,
        },
        neededSave: JSON.stringify(originApp) !== JSON.stringify(editableApp),
        menu: {
            anchorEl,
            handleClose,
            handleClick,
            open,
        },
        isShowingNoRows: !exact && !editableApp?.dataTable.length,
        isEditable,
        genericTable: {
            paging: {
                pageInfo: {page: 0, total: editableApp?.dataTable.length ?? 0, count: editableApp?.dataTable.length ?? 0},
                isLoading: exact,
                minLoadedPage: 0,
                maxLoadedPage: 0,
                setMinMaxLoadedPage: () => null,
                clearRows: () => null,
                fetchPaging: () => null,
            },
            onRoleClick: (roleName: string, rolePermissions: string[]) => {
                rolePermissions.length > 0 && dispatch(openShowPermissions({rolePermissions, roleName}));
            },
            handleEditRow: (row: AccessManagementAppAccount) => {
                if(isEditable){
                    history.push(PATH_ACCESS_MANAGEMENT_APPS_EXACT_EDIT_ROW.replace(':id', id).replace(':account', row.account));
                }else{
                    dispatch(openNonCustomAppAccountMapDialog(row));
                }
            },
            handleDeleteRow: (row: AccessManagementAppAccount) => isEditable && dispatch(openDeleteRow(row)),
        }
    }

}
