import {useDispatch, useSelector} from "react-redux";
import {docViewer, hideDocViewer} from "../store/slice";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../newDomain/authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import React, {useEffect, useRef, useState} from "react";
import {getFile} from "../api";

//LICENCES
// @ts-ignore
import USLicense from "../licensePdfs/US_License/US.pdf";
// @ts-ignore
import USUserAgreementLicense from "../licensePdfs/US_EnduserAgreement/US_UserAgreement.pdf";

// @ts-ignore
import CompliCoEULA from "../licensePdfs/Bahrein/CompliCoEULA.pdf";

// @ts-ignore
import CompliCoTermsOfService from "../licensePdfs/Bahrein/CompliCoTermsOfService.pdf";

import {PDFDocumentProxy} from "pdfjs-dist/types/src/display/api";
import {DEFAULT_ZOOM, MINIMUM_ZOOM, ZOOM_STEP} from "../constants";
import {useMedia} from "../../../hooks/useMedia";
import {saveAs} from "file-saver";
import {b64toBlob} from "../../../utils/base64/base64";
import {useMainTranslation} from "../../../hooks/useMainTranslationHooks/useMainTranslation";
import {IS_REGULAIT, LABEL} from "../../../constants";

//
export const usePdfViewer = () => {
    //root
    const {t} = useMainTranslation('pathSettings.NewOrganizationCreate');
    const dispatch = useDispatch();
    const workspaceId = UseManageWorkspacesAndOrganizations().workspaceId || '';
    const {isMobile, matches_1336Up, matches_1440Up} = useMedia();

    //selectors
    const {isOpen, isEndUserAgreementLicense, fileId, isLicense, fileName, size} = useSelector(docViewer);

    //stores
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [file, setFile] = useState<string>('');
    const [zoom, setZoom] = React.useState<number>(DEFAULT_ZOOM);
    const [totalPages, setTotalPages] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);

    //common dialog actions
    const handleClose = () => {dispatch(hideDocViewer())};

    const getFileById = async(fileId: string, fileName: string, workspaceId: string) => {
        setIsLoading(true);
        try{
            const {file} = await getFile(fileId, fileName, workspaceId);
            setFile(file);
            setIsLoading(false);
        }catch (ex){
            setIsLoading(false);
            handleClose();
        }
    }


    //useEffects
    useEffect(() => {
        if(fileId && fileName && workspaceId) getFileById(fileId, fileName, workspaceId);
        if(!isOpen){
            // setIsAlreadySetReadForThisDoc(false);
        }

        return () => {
            setZoom(DEFAULT_ZOOM);
            setCurrentPage(1);
            setRefs({});
        }
        // eslint-disable-next-line
    }, [isOpen]);

    //FILE GETTER
    const getLicense = () => {
        // if(currentLanguage.countryCode === 'IL'){
        //     return ILLicense;
        // }
        // on 13.03.23 George gave as pack of docs where was only english license and only it was replaces
        if(IS_REGULAIT){
            return USLicense;
        }else{
            return CompliCoTermsOfService;
        }
    }

    const getUserAgreementLicense = () => {
        if(IS_REGULAIT){
            return USUserAgreementLicense;
        }else{
            return CompliCoEULA;
        }
    }

    const getDocumentFile = () => isLicense ? getLicense() : (isEndUserAgreementLicense ? getUserAgreementLicense() : `data:application/pdf;base64,${file}`);

    const getDocumentName = ():string => {
        return fileName || (isLicense ? t('{{companyName}} terms of service', {companyName: LABEL}) : (isEndUserAgreementLicense ? t('{{companyName}} End-user license agreement', {companyName: LABEL}) : 'Unknown Name'))
    }
    async function onDocumentLoadSuccess({ numPages, getViewerPreferences }: PDFDocumentProxy) {
        // const res = await getViewerPreferences();
        // console.log(`getViewerPreferences: ${res}`)
        setTotalPages(numPages);
        // setPageNumber(1);
        // if(numPages && numPages === 1 && onRead && !isAlreadySetReadForThisDoc) {
        //     onRead(fileId, fileName);
        //     setIsAlreadySetReadForThisDoc(true);
        // } //if doc has 1 page its seems to be read
    }

    //ZOOM
    const handleDecreaseZoom = () => {
        zoom > MINIMUM_ZOOM && setZoom(zoom - ZOOM_STEP);
    }

    const handleIncreaseZoom = () => {
        setZoom(zoom + ZOOM_STEP)
    }

    const getAdaptiveZoomCorrection = (): number => {
        //zoom correction for different medias
        //for example - default scale 1.0 will show good at 1512, but on phone it will take more then 100vw
        //so making correction of 0.5 (1.0 - 0.5) to stay in viewport
        //but the zoom shown in topBar will look normalized (100%) in every situation because we provide zoomText which has no correction
        //and only Page component receives zoom with correction
        if(matches_1440Up){
            return -0.5;
        }
        if(matches_1336Up){
            return -0.25;
        }
        if(isMobile){
            return 0.5;
        }
        return 0;
    }

    //SCROLL PAGES
    const pagesRef = useRef<HTMLDivElement>(null);

    const [refs, setRefs] = useState<{[key: number]: HTMLDivElement}>({});

    const handleAppendRef = (index: number, ref?: HTMLDivElement | null) => {
        ref && refs[index] === undefined && setRefs((prev) => {return {...prev, [index]: ref}});
    }

    const onScroll = () => {
        if(pagesRef.current){
            Object.entries(refs).forEach(([key, e]) => {
                const top = e.getBoundingClientRect().top ?? 0;
                if(pagesRef.current) {
                    if(top > 0 && top < pagesRef.current.offsetHeight){
                        setCurrentPage(parseInt(key));
                        // handleSetPage(parseInt(key));
                    }
                }
            })
        }
    }

    //DOWNLOAD
    const handleDownloadFile = () => {
        if(file){
            saveAs(b64toBlob(file as string), getDocumentName());
        }
    }

    return{
        dialogCommon: {
            isOpen,
            isEndUserAgreementLicense,
            fileId,
            isLicense,
            fileName,
            handleClose,
            isLoading,
            size,
        },
        file: {
            getDocumentFile,
            onDocumentLoadSuccess,
            getDocumentName,
            isFileNotFound: !isLoading && (!fileId || !fileName) && !isLicense && !isEndUserAgreementLicense,
            isFileExists: !isLoading && ((fileId && file.length > 0) || isLicense || isEndUserAgreementLicense),
            isDisabledDownload: !file || isLicense || isEndUserAgreementLicense,
            handleDownloadFile
        },
        zoom: {
            zoomText: zoom * 100, //will show 100% even with correction
            zoom: zoom - getAdaptiveZoomCorrection(), //goes into scale prop of Page
            handleDecreaseZoom,
            handleIncreaseZoom,
            isDisabledDecreaseZoom: zoom <= MINIMUM_ZOOM
        },
        pages: {
            currentPage: currentPage,
            totalPages: totalPages,
            handleAppendRef,
            onScroll,
            pagesRef
        }
    }
}