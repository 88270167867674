import {Typography} from "@mui/material";
import {MainFrameworkControlDelegatedTasksCounterModel} from "../../../../../newShared/GQLTypes";
import colors from "../../../../../newShared/theme/colors";
import {Flex} from "../../../../../newShared/components/Layouts";


export const DelegatedTaskCounterChip = ({count, type}: {count: number, type: keyof MainFrameworkControlDelegatedTasksCounterModel}) => {
    const getChipColor = () => {
        switch (type){
            case "created": return colors.status.violet_1;
            case "performed": return colors.status.turquoise_1;
            case "approved": return colors.status.green_3;
            case "declined": return colors.status.red_1;
        }
    }

    return (
        <Flex w={'26px'} p={'1px 6px'} ai={'center'} jc={'center'} background={getChipColor()} br={'10px'}>
            <Typography variant={'bodySmall'} color={colors.text.grey_dark}>{count}</Typography>
        </Flex>
    )
}
