import React, {useEffect, useState} from "react";
import {Button, IconButton, Typography} from "@mui/material";
import {openManageEditorsDialogEventName} from "../../../constants";
import {TEditorActor, TEditorActorsConfigFromDB, TOpenDialogEventData} from "../../../types";
import {AutocompleteBox, EditorManageActorsDialogStyled} from "../manageActorsDialog/styled";
import {
    AddVariableDialogActionsStyled,
    AddVariableDialogContentStyled,
    AddVariableDialogTitleStyled
} from "../addVariableDialog/styled";
import colors from "../../../../../theme/colors";
import {Flex} from "../../../../Layouts";
import {useDispatch} from "react-redux";
import {DocumentAvatar} from "../../editorEditorsAvatars/styled";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {AutocompleteManagersEmailName} from "../../../../../../newDomain/components/documentsRefactor/store/actions";
import {validateEmail} from "../../../../../utils/text";
import {v4 as uuid} from "uuid";
import {optionIsTCollaborator} from "../../../../AutocompleteCollaborators/types";
import {AutocompleteCollaborators} from "../../../../AutocompleteCollaborators";
import {defaultGetOptionDisabled} from "../../../../AutocompleteCollaborators/helpers";

export type TManageEditorsDialogProps = {
    actors: TEditorActorsConfigFromDB,
    handleSubmit: (editors: TEditorActor[]) => void
}

export const ManageEditorsDialog = ({actors, handleSubmit}: TManageEditorsDialogProps) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleClose = () => {
        setIsOpen(false);
    }

    const handleSetIsOpenByEvent = (ev: Event) => {
        const {detail} = ev as CustomEvent<TOpenDialogEventData>;
        setIsOpen(detail.isOpen);
    }

    useEffect(() => {
        document.addEventListener(openManageEditorsDialogEventName, handleSetIsOpenByEvent);
        return () => {
            document.removeEventListener(openManageEditorsDialogEventName, handleSetIsOpenByEvent);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(isOpen){
            setSelectedEmpls(actors.editors);
            dispatch(AutocompleteManagersEmailName({ text: '', onlyActive: false }));
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const handleSave = () => {
        handleSubmit(selectedEmpls);
        handleClose();
    }

    const [uniqueInputId, setUniqueInputId] = useState<string>(uuid());
    const [selectedEmployee, setSelectedEmployee] = useState<TEditorActor | null>(null);
    const [selectedEmpls, setSelectedEmpls] = useState<TEditorActor[]>([]);

    const isDisabledSave = JSON.stringify(selectedEmpls) === JSON.stringify(actors.editors);

    const handleAdd = () => {
        if (selectedEmployee && !selectedEmpls.some(e => e.email === selectedEmployee?.email)) {
            setSelectedEmpls((prev) => ([...prev, selectedEmployee]));
            setSelectedEmployee(null);
            setUniqueInputId(uuid());
        } else {
            if (validateEmail(selectedEmployee?.email || '') && !selectedEmpls.some(e => e.email === selectedEmployee?.email)) {
                setSelectedEmpls((prev) => ([...prev, {email: selectedEmployee?.email || '', firstName: selectedEmployee?.firstName || '', lastName: selectedEmployee?.lastName || ''}]));
                setSelectedEmployee(null);
                setUniqueInputId(uuid());
            }
        }
    }

    const handleDelete = (email: string) => {
        setSelectedEmpls((prev) => (prev.filter(e => e.email !== email)));
    }

    return(
        <EditorManageActorsDialogStyled open={isOpen} onClose={handleClose} maxWidth={'xl'} fullWidth>
            <AddVariableDialogTitleStyled>{'Share draft with editors'}</AddVariableDialogTitleStyled>

            <AddVariableDialogContentStyled sx={{margin: '0 auto', paddingLeft: '125px'}}>
                <Typography color={colors.grayText} fontSize={'16px'} fontWeight={700}>{'Invite editors to this document'}</Typography>
                <Typography color={colors.text.grey} fontSize={'12px'} fontWeight={400}>{'Possibility to access document created by the owner. Editing in the document is carried out by one.'}</Typography>

                <Flex ai={'center'}>
                    <AutocompleteBox>
                        <AutocompleteCollaborators
                            key={uniqueInputId}
                            getOptionLabelCustom={"name+email"}
                            freeSoloType={"email"}
                            freeSolo
                            defaultValue={''}
                            onChange={value => {
                                if (!optionIsTCollaborator(value)) {
                                    setSelectedEmployee({email: value as string, firstName: '', lastName: ''});
                                } else {
                                    setSelectedEmployee({email: value?.email || '', firstName: value?.firstName || '', lastName: value?.lastName || ''});
                                }
                            }}
                            getOptionDisabled={defaultGetOptionDisabled(selectedEmpls.map(e => e.email))}

                            sx={{width: '572px', '& label': {top: '0px !important'}}}
                        />

                        <Button size={'small'} sx={{marginLeft: '16px'}} onClick={handleAdd}
                                disabled={!selectedEmployee?.email?.trim().length || selectedEmpls.some(e => e.email === selectedEmployee.email)}
                        >{('Add')}</Button>
                    </AutocompleteBox>
                </Flex>

                {selectedEmpls.map((e) => {

                    return (
                        <Flex w={'100%'} ai={'center'} jc={'space-between'} key={JSON.stringify(e)}>
                            <Flex ai={'center'} gap={'16px'}>
                                <DocumentAvatar index={0} name={`${e.firstName || e.email} ${e.lastName || e.email}`} alt={e.email ?? ''}/>
                                <Typography color={colors.primary.blue} fontSize={'14px'} fontWeight={400}>{e.email}</Typography>
                            </Flex>

                            <IconButton size={'small'} onClick={() => handleDelete(e.email)}>
                                <DeleteOutlineIcon />
                            </IconButton>
                        </Flex>
                    )
                })}
            </AddVariableDialogContentStyled>

            <AddVariableDialogActionsStyled>
                <Button variant={"text"} onClick={handleClose}>{'Cancel'}</Button>
                <Button variant={"contained"} onClick={handleSave} disabled={isDisabledSave}>{'Save'}</Button>
            </AddVariableDialogActionsStyled>
        </EditorManageActorsDialogStyled>
    )
}
