import React, {FC} from "react";
import {Flex} from "../../../../../newShared/components/Layouts";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";

//icons
//other
import {Box, Button, Chip, MenuItem, Skeleton, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {CreateSettingRowDialog} from "../../components/Dialogs/CreateSettingRowDialog";
import {useSoftwareAssetNew} from "../../hooks/useSoftwareNew";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";


export const SoftwareAssetNew: FC = () => {
    const {isMobile} = useMedia();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});

    const {
        settings,
        form,
        handleChange,

        handleSave,
        handleGoBack,

        handleChangeCustomField,

        isLoadingSettings,
        isLoadingCreateAsset,

        isLoadingShortPhysicalAssets,
        handleSetPhysicalAsset,
        shortPhysicalAssets,
        handleDeletePhysicalAsset

    } = useSoftwareAssetNew();


    return (
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('New software')}
                handleGoBack={handleGoBack}
                actions={[]}
            />

            {/*<Breadcrumbs/>*/}
            {/*<Flex ai={'center'}>*/}
            {/*    {!isMobile &&*/}
            {/*        <IconButton sx={{*/}
            {/*            color: colors.text.blue,*/}
            {/*            margin: !revDir ? `${!isLessThen1050 ? 11 : 4}px 12px ${!isLessThen1050 ? 4 : 2}px 0` : `${!isLessThen1050 ? 11 : 4}px 0 ${!isLessThen1050 ? 4 : 2}px 12px`,*/}
            {/*            padding: '0'*/}
            {/*        }}*/}
            {/*                    onClick={handleGoBack} size={isLessThen1050 ? 'small' : undefined}>*/}
            {/*            {!revDir ? <ArrowBack/> : <ArrowForward/>}*/}
            {/*        </IconButton>}*/}
            {/*    <Typography variant={isMobile ?  'h3' : "h1"}  sx={{color: colors.backgrounds.dark}}>{'New software'}</Typography>*/}
            {/*</Flex>*/}

            <Flex ai={'center'} m={'20px 0 0 0'}>

                <LoadingButton size={"large"} component={"label"}
                               variant={'contained'}
                               disabled={isLoadingCreateAsset || !form.name.trim().length}
                               onClick={handleSave} loadingPosition={isLoadingCreateAsset ? 'start' : undefined} loading={isLoadingCreateAsset}
                               startIcon={isLoadingCreateAsset ? <div style={{width: '10px', height: '10px', }}/> : undefined}
                               sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                >
                    {t('Save')}
                </LoadingButton>

                <Button disabled={isLoadingCreateAsset} variant={'outlined'} size={isMobile ? 'small' : 'medium'} onClick={handleGoBack} >
                    {t('Cancel')}
                </Button>
            </Flex>

            <Flex direction={"column"}  w={'100%'} overflowy={'auto'} m={'10px 0 0 0'} >
                {isLoadingSettings ?
                    <>
                        <Skeleton width={'300px'} height={'40px'} />
                        <Skeleton width={'300px'} height={'40px'} />
                        <Skeleton width={'300px'} height={'40px'} />
                        <Skeleton width={'300px'} height={'40px'} />
                        <Skeleton width={'300px'} height={'40px'} />
                        <Skeleton width={'300px'} height={'40px'} />
                        <Skeleton width={'300px'} height={'40px'} />

                    </>
                    :
                    <>
                        <TextField label={t('Name')} size={"small"}
                                   required
                                   sx={{marginTop: '6px', width: '320px', '& label': {top: '0 !important'}}}
                                   value={form.name} onChange={handleChange} name={'name'} />

                        <TextField label={t('Version')} value={form.version} size={'small'}
                                   sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                   onChange={handleChange} name={'version'} />

                        <TextField label={t('Installed')} value={form.installed} size={'small'}
                                   sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                   onChange={handleChange} name={'installed'} />

                        <TextField label={t('Number of licenses')} value={form.installationThreshold} size={'small'}
                                   type={'number'}
                                   InputProps={{ inputProps: { min: 0 } }}
                                   sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                   onChange={handleChange} name={'installationThreshold'} />

                        <TextField select fullWidth label={t('Add Physical Assets')}
                                   size={'small'}
                                   disabled={isLoadingShortPhysicalAssets}
                                   sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                        >
                            {shortPhysicalAssets.filter((e) => !form?.physicalAssets?.find(pa => pa === e)).map((e) => <MenuItem key={e.id} onClick={()=>handleSetPhysicalAsset(e)} value={e.id}>{e.assetId}</MenuItem>)}
                        </TextField>
                        <Box display={'flex'} width={'350px'} maxHeight={'300px'} sx={{mt: '12px', overflowY: 'scroll', p: '5px', ml: !revDir? '-10px' : undefined, mr: revDir ? '-10px' : undefined}} flexWrap={'wrap'} >
                            {form?.physicalAssets?.map( e => (
                                <Chip key={e.id} sx={{width: '150px', mt: '6px', ml: !revDir ? '9px' : undefined, display: 'flex', justifyContent: 'space-between'}} label={e.assetId} onDelete={() => handleDeletePhysicalAsset(e.id)} />
                            ))
                            }
                        </Box>


                        {(settings && Object.keys(settings.customFields).length > 0) &&
                            Object.keys(settings.customFields).map((e) =>
                                <TextField key={e} size={'small'} label={e} value={form.customFields[e] || ''} name={e}
                                           onChange={handleChangeCustomField} sx={{marginTop: '16px', width: '320px', '& label': {top: '0px !important'}}}
                                />
                            )}
                    </>
                }

            </Flex>

            <CreateSettingRowDialog />
        </Flex>
    )
}