import React, {FC, useEffect} from "react";
import {Button, Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TaskAlt, Visibility} from "@mui/icons-material";
import {
    FlexColumn,
    FlexRow
} from "../../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import {useCreateExamDialog} from "../../../hooks/dialogs/useCreateExamDialogg";
import {CreateNewExamBlockName} from "./components/createNewExamBlockName";
import {CreateNewExamBlockQuestions} from "./components/createNewExamBlockQuestions";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {LeaveDialog} from "../createEditTrainingDialog/components/leaveDialog";
import {ExamPreview} from "../examPreview";
import {useParams} from "react-router-dom";
import {useTrainingsDialogs} from "../../../hooks/useTrainingsDialogs";
import {AddExistingQuestionDialog} from "../addExistingQuestionDialog";
import {MainTrainingsQuestionsFullDataForImportModel} from "../../../../../../newShared/GQLTypes";
import {MainTrainingExamFormikQuestion} from "../../../types";
import {uuid} from "../../../../../../newShared/utils";

export const ExamsCreateExamDialog: FC = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: ''});

    const {examId} = useParams<{examId?: string}>();

    const {
        isLoading, isOpen, formik, handlePreview, handleClose,
        leaveDialog
    } = useCreateExamDialog();

    const addExistingQuestions = useTrainingsDialogs().addExistingQuestions;

    const handleUseQuestion = () => {
        addExistingQuestions.setIsOpen(true);
    };
    const onAddQuestions = (questions: MainTrainingsQuestionsFullDataForImportModel[]) => {
        if (!questions.length) return;
        const newQuestions = questions.map((question): MainTrainingExamFormikQuestion => ({
            ...question,
            questionId: 'new' + uuid(),
            options: question.options.map(option => ({
                ...option,
                optionId: 'new' + uuid()
            }))
        })).slice().sort((a, b) => a.order - b.order);

        formik.setFieldValue('questions', [...formik.values.questions, ...newQuestions]);
        setTimeout(() => {
            const el = document.getElementById(`question-${newQuestions[0].questionId}`);
            el?.scrollIntoView({behavior: 'smooth', block: 'start'});
        }, 50);
    }

    const totalPoints = formik.values.questions.reduce((acc, curr) => acc + curr.points, 0);
    useEffect(() => {
        const oldTotalPoints = formik.values.totalPoints;
        const oldExamPassingPoints = formik.values.examPassingPoints;
        if (totalPoints !== oldTotalPoints) {
            formik.setFieldValue('totalPoints', totalPoints);
            if (oldExamPassingPoints > totalPoints || oldExamPassingPoints === oldTotalPoints) {
                formik.setFieldValue('examPassingPoints', totalPoints);
                setTimeout(() => {formik.validateForm()}, 0);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalPoints]);

    const handleCloseDialog = useTrainingsDialogs().createExam.handleClose;
    useEffect(() => {
        return () => {
            handleCloseDialog();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log(formik.errors.examPassingPoints)
    }, [formik.errors.examPassingPoints]);

    return (
        <Dialog open={isOpen} fullScreen sx={{'.MuiPaper-root': {width: '100%'}}}>
            <DialogTitle component={FlexRow} sx={{gap: '12px', alignItems: 'center'}}>
                <Typography variant={'*h3'} sx={{flexGrow: 1, fontWeight: 600}}>{!examId ? t('New exam') : t('Edit exam')}</Typography>

                <LoadingButton onClick={() => formik.submitForm()} startIcon={<TaskAlt/>} variant={'contained'} size={'small'} loading={isLoading}>{!examId ? tCommon('Create') : tCommon('Save')}</LoadingButton>
                {/*<LightTooltip title={!formik.dirty ? t('There are no changes in exam') : ''}>*/}
                {/*    <span>*/}
                {/*        <LoadingButton onClick={() => formik.submitForm()} startIcon={<TaskAlt/>} variant={'contained'} size={'small'} loading={isLoading} disabled={!formik.dirty}>{!examId ? tCommon('Create') : tCommon('Save')}</LoadingButton>*/}
                {/*    </span>*/}
                {/*</LightTooltip>*/}

                <Button onClick={() => handlePreview()} variant={'outlined'} startIcon={<Visibility/>} size={'small'}>{tCommon('Preview')}</Button>
                <Button onClick={() => handleClose()} variant={'outlined'} size={'small'}>{tCommon('Cancel')}</Button>
            </DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%'}}>
                <FlexColumn width={'100%'} gap={'30px'} overflow={'auto'}>
                    <CreateNewExamBlockName masterFormik={formik}/>

                    {/*<CreateNewExamBlockPassPoints masterFormik={formik}/>*/}

                    <CreateNewExamBlockQuestions masterFormik={formik} handleUseQuestion={handleUseQuestion}/>
                </FlexColumn>
            </DialogContent>
            <AddExistingQuestionDialog
                onAddQuestions={onAddQuestions}
                excludeExamId={formik.values.examId || undefined}
            />
            <LeaveDialog {...leaveDialog}/>
            <ExamPreview/>
        </Dialog>
    )
}