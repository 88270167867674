import React, {FC} from "react";
import {PageWrapper} from "../../../../../../newShared/components/style";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";
import {useVicaCategoriesList} from "../../../hooks/useVicaCategoriesList";
import {GenericFiltersArray} from "../../../../../../newShared/components/genericFilter";
import {Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@mui/material";
import {TasksTableHeader} from "./components/vicaTasksTableHeader";
import {VicaCategoryTableRow} from "./components/vicaCategoryTableRow";
import {VicaCategorySkeletonRow} from "./components/vicaCategorySkeletonRow";
import {VicaStepDescriptionViewDialog} from "../../../components/dialogs/vicaStepDescriptionViewDialog";
import {TaskActionUploadDialog} from "../../../components/dialogs/taskActionUploadDialog";
import {TaskActionTemplateNewDialog} from "../../../components/dialogs/taskActionTemplateNewDialog";
import {SelectSupervisorDialog} from "../../../components/dialogs/selectSupervisorDialog";
import {TaskFrameworksDialog} from "../../../components/dialogs/taskFrameworksDialog";
import {EmptyArrayImage} from "../../../../../../newShared/components/EmptyArrayImage";
import EditorDialog from "../../../../../../newShared/components/editorUnion";
import {AddGroupMessageDialog} from "../../../components/dialogs/addGroupMessage";
import colors from "../../../../../../newShared/theme/colors";
import {SelectTaskCollabsDialog} from "../../../components/dialogs/selectTaskCollabsDialog";


export const VicaTasksList: FC = () => {
    const {
        categories,
        isLoading,
        configs,
        actions,
        isShowingNoCategoriesResult,
        handleGoToAdoptFramework,
        doc: {selectedNewDocument, currentEmail, snack, loadings, docActions},
    } = useVicaCategoriesList();

    return(
        <PageWrapper>
            <PageTitleAndActions
                title={'Task management'}
                actions={[]}
            />

            <GenericFiltersArray
                configs={configs}
                disableSearch
                isAddFilters={false}
                fetchResultWithSelectedFilters={(search, filters) => actions.fetchCategories(filters)}
            />

            {isShowingNoCategoriesResult ?
                <EmptyArrayImage
                    type={'vicaTasks'}
                    text={('No tasks found')}
                    secondaryText={('Adopt a framework to start')}
                    onClick={handleGoToAdoptFramework}
                    isShowing={true}
                />
                :
                <>


                    <TableContainer>
                        <Table stickyHeader>
                            <TasksTableHeader />

                            <TableBody>
                                {isLoading ?
                                    <VicaCategorySkeletonRow />
                                    :
                                    <>
                                        {!categories.length ?
                                            <TableRow>
                                                <TableCell colSpan={5}>
                                                    <Typography variant={'*bodyText2'} color={colors.grayText}>{('Nothing was found for your query')}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            :
                                            categories.map(e => <VicaCategoryTableRow key={e.name} category={e}/>)
                                        }
                                    </>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            }

            <VicaStepDescriptionViewDialog />
            <TaskActionUploadDialog />
            <TaskActionTemplateNewDialog />
            <SelectSupervisorDialog />
            <TaskFrameworksDialog />
            <AddGroupMessageDialog />
            <SelectTaskCollabsDialog />
            {selectedNewDocument?.document?.status && (
                <EditorDialog isOpen={true}
                              documentTitle={selectedNewDocument.document.name}
                              content={selectedNewDocument.document.data?.editor || []}
                              variables={selectedNewDocument.document.data?.variables || []}
                              actors={{
                                  approvers: selectedNewDocument.document.approvers || [],
                                  recipients: selectedNewDocument.document.recipients || [],
                                  editors: selectedNewDocument.document.editors || [],
                              }}
                              pdfFileId={selectedNewDocument.document.data?.pdfFileId || null}

                              currentEmail={currentEmail}
                              currentFileStatus={selectedNewDocument.document.status}
                              execution={selectedNewDocument?.documentExecutor || undefined}

                              onSave={docActions.handleSave}
                              onSend={docActions.handleSend}
                              onPreviewBeforeSend={docActions.handlePreviewBeforeSend}
                              onInvalidate={docActions.handleInvalidate}
                              onSkipApprover={docActions.handleSkip}
                              onClose={docActions.handleCloseEditor}
                              onReturnToDraft={docActions.handleReturnToDraft}
                              onRejectSignStage={() => null}
                              onRejectApproveStage={() => null}
                              onApproveApproveStage={() => null}
                              onApproveSignStage={() => null}
                              onFillFields={() => null}
                              onChangeEta={() => null}

                              onResendInviteApprover={docActions.onResendInviteApprover}
                              onResendInviteRecipient={docActions.onResendInviteRecipient}
                              isLoading={{
                                  isPrev: false,
                                  isSave: loadings.isLoadingSaveDoc,
                                  isInvalidating: loadings.isLoadingInvalidate,
                                  isRejecting: false,
                                  isApproving: false,
                                  isReinviting: loadings.isLoadingRestartSignStageByStageIdAndGroupId || loadings.isLoadingRestartSignStageByStageIdAndRecipient,
                                  isSkipping: loadings.isLoadingSendToNextStep,
                                  isFillingField: false,
                                  isSending: loadings.isLoadingExact,
                                  isChangingEtaInProgress: false,
                              }}
                              snack={snack}

                              actualEnvironment={"isMain"}
                />
            )}
        </PageWrapper>
    )
}
