import {genericFiltersArrayPropsTypes, keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

type TResponse = {
    // applyFilter: genericFiltersArrayPropsTypes['fetchResultWithSelectedFilters'];
    configs: genericFiltersArrayPropsTypes['configs'];
}
export const useReportFilter = (): TResponse => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathReports.View'});

    // const {report: {report}, getControls, controls} = useReport();

    const status: keyConfig<{id: string, title: string}> = {
        type: 'filter',
        key: 'status',
        name: t('NCR status'),
        options: [
            {id: 'OPEN', title: t('Open')},
            {id: 'REVIEW', title: t('Review')},
            {id: 'REVOKE', title: t('Revoked')},
            {id: 'APPROVED', title: t('Approved')},
            {id: 'REJECTED', title: t('Rejected')},
            {id: 'WITHOUT', title: t('Without NCR')},
        ],
        isOptionEqualToSearch: (option, search) => option.title.trim().toLowerCase().includes(search.trim().toLowerCase()),
        isOptionEqual: (a, b) => a.id === b.id,
        getFilterValue: (a) => a.id,
        getOptionLabel: (e) => e.title,
        default: true,
    };

    const reviewed: keyConfig<{id: string, title: string, value: boolean}> = {
        type: 'filter',
        key: 'reviewed',
        name: t('Review status'),
        options: [
            {id: 'reviewed', title: t('Reviewed'), value: true},
            {id: 'unreviewed', title: t('Unreviewed'), value: false},
        ],
        isOptionEqualToSearch: (option, search) => option.title.trim().toLowerCase().includes(search.trim().toLowerCase()),
        isOptionEqual: (a, b) => a.id === b.id,
        getFilterValue: (a) => String(a.value),
        getOptionLabel: (e) => e.title,
        default: true,
    };

    // const applyFilter: TResponse["applyFilter"] = (search, filters) => {
    //     report?.id && getControls(report?.id, {page: 0, count: controls.pageInfo.count, filters: {...filters, input: [search]}}, true);
    // }

    return {
        configs: [
            status,
            reviewed,
        ],
        // applyFilter
    };
};