import {AppState} from "../../../../newShared/redux/rootReducer";
import {
    TAsset,
    TAssetType,
    TCertificateAsset,
    TCertificateAssetSettings,
    TPhysicalAssetSettings,
    TSaasAsset,
    TSaasAssetSettings,
    TSetChangeStatusDialog,
    TSetCreateAssetDialog,
    TSetCreateSettingRowDialog,
    TSetDeleteAssetDialog,
    TSetDeleteSettingRowDialog,
    TSetEditAssetDialog,
    TSetEditSettingRowDialog,
    TSetReleaseDialog,
    TSoftwareAsset,
    TSoftwareAssetSettings,
    TVirtualAsset,
    TVirtualAssetSettings
} from "../types";
import {TExportCsvField, TPageInfo} from "../../../../newShared/types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {
    changeAssetStatusAction,
    createCertificateAssetAction,
    createCertificateAssetsSettingRowAction,
    createPhysicalAssetAction,
    createPhysicalAssetsSettingRowAction,
    createSaasAssetAction,
    createSaasAssetsSettingRowAction,
    createSoftwareAssetAction,
    createSoftwareAssetsSettingRowAction,
    createVirtualAssetAction,
    createVirtualAssetsSettingRowAction,
    deleteCertificateAssetAction,
    deleteCertificateAssetsSettingRowAction,
    deletePhysicalAssetAction,
    deletePhysicalAssetsSettingRowAction,
    deleteSaasAssetAction,
    deleteSaasAssetsSettingRowAction,
    deleteSoftwareAssetAction,
    deleteSoftwareAssetsSettingRowAction,
    deleteVirtualAssetAction,
    deleteVirtualAssetsSettingRowAction,
    editCertificateAssetsSettingRowAction,
    editPhysicalAssetsSettingRowAction,
    editSaasAssetsSettingRowAction,
    editSoftwareAssetsSettingRowAction,
    editVirtualAssetsSettingRowAction,
    exportCsvCertificateAssetsAction,
    exportCsvPhysicalAssetsAction,
    exportCsvSaasAssetsAction,
    exportCsvSoftwareAssetsAction,
    exportCsvVirtualAssetsAction,
    GetCertificateAssetById,
    getCertificateAssetsAction,
    getCertificateAssetsSettingsAction,
    GetCertificatesAvailableFieldsForExport,
    getCsvFieldsCertificateAction,
    getCsvFieldsPhysicalAssetsAction,
    getCsvFieldsSAASAction,
    getCsvFieldsSoftwareAction,
    getCsvFieldsVirtualAction,
    GetPhysicalAssetById,
    getPhysicalAssetsAction,
    GetPhysicalAssetsAvailableFieldsForExport,
    getPhysicalAssetsSettingsAction,
    GetSaasAssetById,
    getSaasAssetsAction,
    getSaasAssetsSettingsAction,
    GetSaasAvailableFieldsForExport,
    GetShortPhysicalAssets,
    GetSoftwareAssetById,
    getSoftwareAssetsAction,
    GetSoftwareAssetsAvailableFieldsForExport,
    getSoftwareAssetsSettingsAction,
    GetVirtualAssetById,
    getVirtualAssetsAction,
    GetVirtualAssetsAvailableFieldsForExport,
    getVirtualAssetsSettingsAction,
    importCsvTemplateCertificateAssetsAction,
    importCsvTemplatePhysicalAssetsAction,
    importCsvTemplateSaasAssetsAction,
    importCsvTemplateSoftwareAssetsAction,
    importCsvTemplateVirtualAssetsAction,
    mainAssetsApproveRequestRemoveAssetAction,
    mainAssetsApproveRequestRequestAssetAction,
    mainAssetsAssignNotAssignedAssetAction,
    mainAssetsCreateAndAssignAssetAction,
    mainAssetsGetAssetRequestByIdAction,
    mainAssetsGetRequestsWithFilterPaginationAction,
    mainAssetsReassignAssetAction,
    mainAssetsRequestsRejectAction,
    performCsvCertificateAction,
    performCsvPhysicalAssetsAction,
    performCsvSAASAction,
    performCsvSoftwareAction,
    performCsvVirtualAction,
    releaseCertificateAssetsAction,
    releasePhysicalAssetsAction,
    releaseSaasAssetsAction,
    releaseSoftwareAssetsAction,
    releaseVirtualAssetsAction,
    updateCertificateAssetAction,
    updatePhysicalAssetAction,
    updateSaasAssetAction,
    updateSoftwareAssetAction,
    updateVirtualAssetAction
} from "./actions";
import {
    AssetsRequestStatus,
    MainAssetsRequestModel,
    MainAssetsRequestWithAssetModel,
    SoftwarePhysicalAssetModel
} from "../../../../newShared/GQLTypes";
import {minMaxLoadedPageType} from "../../../../newShared/components/genericTable/types";
import {minMaxLoadedPageDefault} from "../../../../newShared/components/genericTable/constants";
import {handlePagination} from "../../../../newShared/components/genericTable/helpers";
import {TImportCsvDialogForSlice} from "../../../../newShared/components/csvImportDialog/types";
import {
    addImportCsvActionsToBuilder,
    initialImportCsvDialogState,
    setImportCsvDialog
} from "../../../../newShared/components/csvImportDialog/constants";
import {defaultPageInfo} from "../../../authWorkspacesCookies/settings/store/slice";
import {getErrorsByCode} from "../../../../newShared/utils/asyncThunk/helpers";

export type TSlice = {
    assets: TAsset[],
    virtualAssets: TVirtualAsset[],
    softwareAssets: TSoftwareAsset[],
    saasAssets: TSaasAsset[],
    certificateAssets: TCertificateAsset[],
    pageInfo: TPageInfo,
    minMaxLoadedPage: minMaxLoadedPageType;

    selectedAsset: TAsset | null,
    selectedVirtualAsset: TVirtualAsset | null,
    selectedSoftwareAsset: TSoftwareAsset | null,
    selectedSaasAsset: TSaasAsset | null,
    selectedCertificateAsset: TCertificateAsset | null,
    settings: TPhysicalAssetSettings | null;
    virtualSettings: TVirtualAssetSettings | null;
    softwareSettings: TSoftwareAssetSettings | null;
    saasSettings: TSaasAssetSettings | null;
    certificateSettings: TCertificateAssetSettings | null;
    shortPhysicalAssets: SoftwarePhysicalAssetModel[];

    incomingRequests: {
        requests: MainAssetsRequestModel[];
        pageInfo: TPageInfo;
        selected: MainAssetsRequestWithAssetModel | null;
        minMaxLoadedPage: minMaxLoadedPageType;
    }

    dialogs: {
        createAsset: {
            isOpen: boolean,
        },
        deleteAsset: {
            isOpen: boolean,
            response: string | null,
            assetType: TAssetType | null;
        },
        changeStatus: {
            isOpen: boolean,
            response: string | null
        },
        editAsset: {
            isOpen: boolean,
            response: string | null,
            assetType: TAssetType | null;
        },

        createSettingRow: {
            isOpen: boolean,
            field: string | null,
            title: string,
            assetType: TAssetType | null
            status?: TPhysicalAssetSettings['status'][number] | null,
        },
        editSettingRow: {
            isOpen: boolean,
            field: string | null,
            oldTitle: string,
            assetType: TAssetType | null,
            status?: TPhysicalAssetSettings['status'][number] | null,
            response: string | null
        },
        deleteSettingRow: {
            isOpen: boolean,
            field: string | null,
            oldTitle: string,
            status?: string,
            assetType: TAssetType | null,
            response: string | null
        },
        releaseDialog: {
            isOpen: boolean,
            assetType: TAssetType | null,
        },
        exportCsv: {
            isOpen: boolean,
            fields: TExportCsvField[],
        },
        rejectRequest: {
            isOpen: boolean,
        },
        createAndAssign: {
            isOpen: boolean,
        },
        approveAssignNotAssigned: {
            isOpen: boolean,
        },
        requestAssetError: {
            title: string;
            message: string[];
        },
        approveRequestRequest: {
            isOpen: boolean,
        },
        approveRequestRemove: {
            isOpen: boolean,
        },
        approveReassignAsset: {
            isOpen: boolean,
        },
    } & TImportCsvDialogForSlice,

    loadings: {
        isLoadingList: boolean,
        isLoadingExact: boolean,
        isLoadingSettings: boolean,
        isLoadingCreateAsset: boolean,
        isLoadingExport: boolean,
        addSettingLoading: boolean,
        editSettingLoading: boolean,
        deleteSettingLoading: boolean,
        isFileDownloading: boolean,
        releaseData: boolean,
        releasePhysicalAssets: boolean,
        isLoadingShortPhysicalAssets: boolean,
        isLoadingFields: boolean;
        incomingRequests: boolean;
        incomingRequestsExact: boolean;
        incomingRequestRejecting: boolean;
        approveRequest: boolean;
    }

    isError: boolean,
}

const initialState: TSlice = {
    assets: [],
    virtualAssets: [],
    softwareAssets: [],
    saasAssets: [],
    certificateAssets: [],
    minMaxLoadedPage: minMaxLoadedPageDefault,
    pageInfo: {
        count: 25,
        page: 0,
        total: 0,
        sort: ''
    },
    selectedAsset: null,
    selectedVirtualAsset: null,
    selectedSoftwareAsset: null,
    selectedSaasAsset: null,
    selectedCertificateAsset: null,

    settings: null,
    virtualSettings: null,
    softwareSettings: null,
    saasSettings: null,
    certificateSettings: null,

    shortPhysicalAssets: [],

    incomingRequests: {
        requests: [],
        pageInfo: defaultPageInfo,
        selected: null,
        minMaxLoadedPage: minMaxLoadedPageDefault,
    },

    dialogs: {
        createAsset: {
            isOpen: false
        },
        deleteAsset:{
            isOpen: false,
            response: null,
            assetType: null,
        },
        changeStatus: {
            isOpen: false,
            response: null,
        },
        editAsset: {
            isOpen: false,
            response: null,
            assetType: null,
        },

        createSettingRow: {
            isOpen: false,
            field: null,
            title: '',
            status: null,
            assetType: null,
        },
        editSettingRow: {
            isOpen: false,
            field: null,
            oldTitle: '',
            status: null,
            response: null,
            assetType: null,
        },
        deleteSettingRow: {
            isOpen: false,
            field: null,
            oldTitle: '',
            response: null,
            assetType: null,
        },
        releaseDialog: {
            isOpen: false,
            assetType: null
        },
        exportCsv: {
            isOpen: false,
            fields: [],
        },
        createAndAssign: {
            isOpen: false
        },
        approveAssignNotAssigned: {
            isOpen: false
        },
        requestAssetError: {
            title: '',
            message: [],
        },

        rejectRequest: {
            isOpen: false,
        },
        approveRequestRequest: {
            isOpen: false,
        },
        approveRequestRemove: {
            isOpen: false,
        },
        approveReassignAsset: {
            isOpen: false,
        },

        ...initialImportCsvDialogState,
    },

    loadings: {
        isLoadingList: false,
        isLoadingExact: false,
        isLoadingCreateAsset: false,
        isLoadingSettings: false,
        isLoadingExport: false,
        addSettingLoading: false,
        editSettingLoading: false,
        deleteSettingLoading: false,
        isFileDownloading: false,
        releaseData: false,
        releasePhysicalAssets: false,
        isLoadingShortPhysicalAssets: false,
        isLoadingFields: false,
        incomingRequests: false,
        incomingRequestsExact: false,
        incomingRequestRejecting: false,
        approveRequest: false,
    },

    isError: false,
}

const PhysicalAssetsSlice = createSlice({
    name: 'PhysicalAssets',
    initialState,
    reducers: {
        cleanUp: () => initialState,
        replaceMinMaxLoadedPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.minMaxLoadedPage = payload;
        },
        cleanPhysicalAssets: (slice) => {slice.assets = [];slice.pageInfo = initialState.pageInfo;},
        cleanVirtualAssets: (slice) => {slice.virtualAssets = [];slice.pageInfo = initialState.pageInfo;},
        cleanSoftware: (slice) => {slice.softwareAssets = [];slice.pageInfo = initialState.pageInfo;},
        cleanSaas: (slice) => {slice.saasAssets = [];slice.pageInfo = initialState.pageInfo;},
        cleanCertificates: (slice) => {slice.certificateAssets = [];slice.pageInfo = initialState.pageInfo;},

        selectAsset(slice: TSlice, {payload}:{payload: TAsset | null}){
            slice.selectedAsset = payload
        },
        deselectAsset(slice: TSlice){
            slice.selectedAsset = null
        },
        selectVirtualAsset(slice: TSlice, {payload}:{payload: TVirtualAsset | null}){
            slice.selectedVirtualAsset = payload
        },
        deselectVirtualAsset(slice: TSlice){
            slice.selectedVirtualAsset = null
        },
        selectSoftwareAsset(slice: TSlice, {payload}:{payload: TSoftwareAsset | null}){
            slice.selectedSoftwareAsset = payload
        },
        deselectSoftwareAsset(slice: TSlice){
            slice.selectedSoftwareAsset = null
        },
        selectSaasAsset(slice: TSlice, {payload}:{payload: TSaasAsset | null}){
            slice.selectedSaasAsset = payload
        },
        deselectSaasAsset(slice: TSlice){
            slice.selectedSaasAsset = null
        },
        selectCertificateAsset(slice: TSlice, {payload}:{payload: TCertificateAsset | null}){
            slice.selectedCertificateAsset = payload
        },
        deselectCertificateAsset(slice: TSlice){
            slice.selectedCertificateAsset = null
        },

        openExportCsv: (slice) => {
            slice.dialogs.exportCsv.isOpen = true;
        },
        hideExportCsv: (slice) => {
            slice.dialogs.exportCsv = initialState.dialogs.exportCsv;
        },

        openReleaseDialog: (slice: TSlice,  {payload}: {payload: TSetReleaseDialog}) => {
            slice.dialogs.releaseDialog.isOpen = true;
            if (payload.assetType !== undefined) slice.dialogs.releaseDialog.assetType = payload.assetType
        },
        hideReleaseDialog: (slice) => {slice.dialogs.releaseDialog = initialState.dialogs.releaseDialog},

        //Dialogs
        setCreateAssetDialogAction(slice: TSlice, {payload}: {payload: TSetCreateAssetDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.createAsset.isOpen = payload.isOpen;

            if (payload.clear) {
                slice.dialogs.createAsset = initialState.dialogs.createAsset;
                slice.loadings.isLoadingCreateAsset = false;
            }

        },
        setEditAssetDialogAction(slice: TSlice, {payload}: {payload: TSetEditAssetDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.editAsset.isOpen = payload.isOpen;
            if (payload.assetType !== undefined) slice.dialogs.editAsset.assetType = payload.assetType;

            if (payload.clear) {
                slice.dialogs.editAsset = initialState.dialogs.editAsset;
                slice.loadings.isLoadingExact = false;
            }
        },
        setChangeStatusDialogAction(slice: TSlice, {payload}: {payload: TSetChangeStatusDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.changeStatus.isOpen = payload.isOpen;

            if (payload.clear) {
                slice.dialogs.changeStatus = initialState.dialogs.changeStatus;
                slice.loadings.isLoadingExact = false;
            }

        },
        setDeleteAssetDialogAction(slice: TSlice, {payload}: {payload: TSetDeleteAssetDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.deleteAsset.isOpen = payload.isOpen;
            if (payload.assetType !== undefined) slice.dialogs.deleteAsset.assetType = payload.assetType;

            if (payload.clear) {
                slice.dialogs.deleteAsset = initialState.dialogs.deleteAsset;
                slice.loadings.isLoadingExact = false;
            }
        },
        setCreateSettingRowDialogAction(slice: TSlice, {payload}: {payload: TSetCreateSettingRowDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.createSettingRow.isOpen = payload.isOpen;
            if (payload.field !== undefined) slice.dialogs.createSettingRow.field = payload.field;
            if (payload.status !== undefined) slice.dialogs.createSettingRow.status = payload.status;
            if (payload.assetType !== undefined) slice.dialogs.createSettingRow.assetType = payload.assetType;

            if (payload.clear) {
                slice.dialogs.createSettingRow = initialState.dialogs.createSettingRow;
                slice.loadings.addSettingLoading = false;
            }
        },
        setEditSettingRowDialogAction(slice: TSlice, {payload}: {payload: TSetEditSettingRowDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.editSettingRow.isOpen = payload.isOpen;
            if (payload.field !== undefined) slice.dialogs.editSettingRow.field = payload.field;
            if (payload.oldTitle !== undefined) slice.dialogs.editSettingRow.oldTitle = payload.oldTitle;
            if (payload.status !== undefined) slice.dialogs.editSettingRow.status = payload.status;
            if (payload.assetType !== undefined) slice.dialogs.editSettingRow.assetType = payload.assetType;


            if (payload.clear) {
                slice.dialogs.editSettingRow = initialState.dialogs.editSettingRow;
                slice.loadings.editSettingLoading = false;
            }
        },
        setDeleteSettingRowDialogAction(slice: TSlice, {payload}: {payload: TSetDeleteSettingRowDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.deleteSettingRow.isOpen = payload.isOpen;
            if (payload.field !== undefined) slice.dialogs.deleteSettingRow.field = payload.field;
            if (payload.oldTitle !== undefined) slice.dialogs.deleteSettingRow.oldTitle = payload.oldTitle;
            if (payload.assetType !== undefined) slice.dialogs.deleteSettingRow.assetType = payload.assetType;


            if (payload.clear) {
                slice.dialogs.deleteSettingRow = initialState.dialogs.deleteSettingRow;
                slice.loadings.deleteSettingLoading = false;
            }
        },
        setCreateAndAssignRequestAction: (slice, {payload}: {payload: Partial<TSlice["dialogs"]["createAndAssign"]> & {clear?: boolean}}) => {
            if (payload.isOpen) slice.dialogs.createAndAssign.isOpen = payload.isOpen;
            if (payload.clear) slice.dialogs.createAndAssign = initialState.dialogs.createAndAssign;
        },
        setApproveAssignNotAssignedAction: (slice, {payload}: {payload: Partial<TSlice["dialogs"]["approveAssignNotAssigned"]> & {clear?: boolean}}) => {
            if (payload.isOpen) slice.dialogs.approveAssignNotAssigned.isOpen = payload.isOpen;
            if (payload.clear) slice.dialogs.approveAssignNotAssigned = initialState.dialogs.approveAssignNotAssigned;
        },
        setApproveReassignAssetAction: (slice, {payload}: {payload: Partial<TSlice["dialogs"]["approveReassignAsset"]> & {clear?: boolean}}) => {
            if (payload.isOpen) slice.dialogs.approveReassignAsset.isOpen = payload.isOpen;
            if (payload.clear) slice.dialogs.approveReassignAsset = initialState.dialogs.approveReassignAsset;
        },

        setRequestAssetErrorAction: (slice, {payload}: {payload: Partial<TSlice["dialogs"]["requestAssetError"]> & {clear?: boolean}}) => {
            if (payload.message !== undefined) slice.dialogs.requestAssetError.message = payload.message;
            if (payload.title !== undefined) slice.dialogs.requestAssetError.title = payload.title;

            if (payload.clear) slice.dialogs.requestAssetError = initialState.dialogs.requestAssetError;
        },
        replaceRequestMinMaxLoadedPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.incomingRequests.minMaxLoadedPage = payload;
        },
        eraseRequestList: (slice) => {
            slice.incomingRequests.requests = [];
            slice.incomingRequests.pageInfo = defaultPageInfo;
        },

        setRejectingDialogAction: (slice: TSlice, {payload}: {payload: Partial<TSlice["dialogs"]["rejectRequest"]> & {clean?: boolean}}) => {
            if (payload.isOpen !== undefined) slice.dialogs.rejectRequest.isOpen = payload.isOpen;

            if (payload.clean) {
                slice.dialogs.rejectRequest = initialState.dialogs.rejectRequest;
            }
        },

        setApproveRequestRequestDialogAction: (slice: TSlice, {payload}: {payload: Partial<TSlice["dialogs"]["approveRequestRequest"]> & {clean?: boolean}}) => {
            if (payload.isOpen !== undefined) slice.dialogs.approveRequestRequest.isOpen = payload.isOpen;

            if (payload.clean) {
                slice.dialogs.approveRequestRequest = initialState.dialogs.approveRequestRequest;
            }
        },

        setApproveRequestRemoveDialogAction: (slice: TSlice, {payload}: {payload: Partial<TSlice["dialogs"]["approveRequestRemove"]> & {clean?: boolean}}) => {
            if (payload.isOpen !== undefined) slice.dialogs.approveRequestRemove.isOpen = payload.isOpen;

            if (payload.clean) {
                slice.dialogs.approveRequestRemove = initialState.dialogs.approveRequestRemove;
            }
        },

        clearSelectedIncomingRequestAction: (slice) => {
            slice.incomingRequests.selected = null;
        },


        setImportCsvDialogAction: setImportCsvDialog,
    },

    extraReducers: (builder) => {
        addImportCsvActionsToBuilder<TSlice>(builder, getCsvFieldsPhysicalAssetsAction, performCsvPhysicalAssetsAction);
        addImportCsvActionsToBuilder<TSlice>(builder, getCsvFieldsSAASAction, performCsvSAASAction);
        addImportCsvActionsToBuilder<TSlice>(builder, getCsvFieldsSoftwareAction, performCsvSoftwareAction);
        addImportCsvActionsToBuilder<TSlice>(builder, getCsvFieldsVirtualAction, performCsvVirtualAction);
        addImportCsvActionsToBuilder<TSlice>(builder, getCsvFieldsCertificateAction, performCsvCertificateAction);

        builder
            .addCase(getPhysicalAssetsAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingList = true;
                slice.isError = false;
            })
            .addCase(getPhysicalAssetsAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingList = false;
                slice.isError = true;
            })
            .addCase(getPhysicalAssetsAction.fulfilled, (slice: TSlice, {payload}) => {
                slice.loadings.isLoadingList = false;

                // const {array, pageInfo} = setPaginationInStore<TAsset>(
                //     slice.pageInfo,
                //     slice.assets,
                //     payload.resp.pageInfo,
                //     payload.resp.data,
                //     payload.isNewOnTop
                // );
                //
                // slice.assets = array;
                // slice.pageInfo = pageInfo;

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<TAsset>(
                    slice.assets,
                    slice.pageInfo,
                    payload.resp.data,
                    payload.resp.pageInfo,
                    slice.minMaxLoadedPage.minLoadedPage,
                    slice.minMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.assets = results;
                slice.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.pageInfo = payload.resp.pageInfo;
            })

            //GetAssetById
            .addCase(GetPhysicalAssetById.pending, (slice: TSlice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(GetPhysicalAssetById.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingExact = false;
            })
            .addCase(GetPhysicalAssetById.fulfilled, (slice: TSlice, {payload}) => {
                slice.loadings.isLoadingExact = false;
                slice.selectedAsset = payload;
            })

            //CreateAsset
            .addCase(createPhysicalAssetAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingCreateAsset = true;
            })
            .addCase(createPhysicalAssetAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingCreateAsset = false;
                slice.isError = true;
            })
            .addCase(createPhysicalAssetAction.fulfilled, (slice: TSlice, {payload: {resp}}) => {
                slice.dialogs.createAsset = initialState.dialogs.createAsset;

                slice.loadings.isLoadingCreateAsset = false;
                slice.selectedAsset = resp;
            })
            .addCase(updatePhysicalAssetAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(updatePhysicalAssetAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingExact = false;
            })
            .addCase(updatePhysicalAssetAction.fulfilled, (slice: TSlice, {payload: {resp}}) => {
                slice.dialogs.editAsset = initialState.dialogs.editAsset;

                slice.loadings.isLoadingExact = false;

                const tmp = slice.assets;
                const index = tmp.findIndex(e => e?.id === resp.id);
                if (index > -1) {
                    tmp[index] = resp;
                    slice.assets = tmp;
                }

                slice.selectedAsset = resp;
            })
            .addCase(deletePhysicalAssetAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(deletePhysicalAssetAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingExact = false;
                slice.isError = true;
            })
            .addCase(deletePhysicalAssetAction.fulfilled, (slice: TSlice, {payload: {data, resp}}) => {
                const tmp = slice.assets.filter(e => e?.id !== data.assetId);
                slice.assets = tmp;

                if (resp) slice.dialogs.deleteAsset.response = resp.message;
                slice.loadings.isLoadingExact = false;
            })
            .addCase(changeAssetStatusAction.pending, (slice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(changeAssetStatusAction.rejected, (slice) => {
                slice.loadings.isLoadingExact = false;
            })
            .addCase(changeAssetStatusAction.fulfilled, (slice:TSlice, { payload: {data, resp} }) => {
                slice.loadings.isLoadingExact = false;
                slice.dialogs.changeStatus = initialState.dialogs.changeStatus;
                const tmp = slice.assets;
                const index = tmp.findIndex(asset => asset?.id === data.assetId)
                if (index > -1) {
                    tmp[index] = resp;
                    slice.assets = tmp;
                }
                slice.selectedAsset = resp;
                // slice.dialogs.changeStatus.response = 'The status has been changed successfully.'
            })
            //Release
            .addCase(releasePhysicalAssetsAction.pending, (slice) => {
                slice.loadings.releasePhysicalAssets = true;
            })
            .addCase(releasePhysicalAssetsAction.rejected, (slice) => {
                slice.loadings.releasePhysicalAssets = false;
            })
            .addCase(releasePhysicalAssetsAction.fulfilled, (slice) => {
                slice.loadings.releasePhysicalAssets = false;
                slice.dialogs.releaseDialog = initialState.dialogs.releaseDialog;
            })

            //SETTINGS
            .addCase(getPhysicalAssetsSettingsAction.pending, (slice) => {
                slice.loadings.isLoadingSettings = true;
            })
            .addCase(getPhysicalAssetsSettingsAction.rejected, (slice) => {
                slice.loadings.isLoadingSettings = false;
                slice.isError = true;
            })
            .addCase(getPhysicalAssetsSettingsAction.fulfilled, (slice:TSlice, { payload: {data, resp} }) => {
                slice.loadings.isLoadingSettings = false
                slice.settings = resp;
            })

            .addCase(createPhysicalAssetsSettingRowAction.pending, (slice) => {
                slice.loadings.addSettingLoading = true;
            })
            .addCase(createPhysicalAssetsSettingRowAction.rejected, (slice) => {
                slice.loadings.addSettingLoading = false;
            })
            .addCase(createPhysicalAssetsSettingRowAction.fulfilled, (slice:TSlice, { payload: {data, resp} }) => {
                slice.settings = resp;
                slice.loadings.addSettingLoading = false;
                slice.dialogs.createSettingRow = initialState.dialogs.createSettingRow;
            })

            .addCase(editPhysicalAssetsSettingRowAction.pending, (slice) => {
                slice.loadings.editSettingLoading = true;
            })
            .addCase(editPhysicalAssetsSettingRowAction.rejected, (slice) => {
                slice.loadings.editSettingLoading = false;
            })
            .addCase(editPhysicalAssetsSettingRowAction.fulfilled, (slice:TSlice, { payload: {data, resp} }) => {
                slice.settings = resp;
                slice.loadings.editSettingLoading = false;
                slice.dialogs.editSettingRow.response = `has been changed successfully.`;
            })

            .addCase(deletePhysicalAssetsSettingRowAction.pending, (slice) => {
                slice.loadings.deleteSettingLoading = true;
            })
            .addCase(deletePhysicalAssetsSettingRowAction.rejected, (slice) => {
                slice.loadings.deleteSettingLoading = false;
            })
            .addCase(deletePhysicalAssetsSettingRowAction.fulfilled, (slice:TSlice, { payload: {data, resp} }) => {
                slice.settings = resp;
                slice.loadings.deleteSettingLoading = false;
                slice.dialogs.deleteSettingRow = initialState.dialogs.deleteSettingRow;
            })
            .addCase(exportCsvPhysicalAssetsAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingExport = true;
            })
            .addCase(exportCsvPhysicalAssetsAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingExport = false;
            })
            .addCase(exportCsvPhysicalAssetsAction.fulfilled, (slice: TSlice) => {
                slice.loadings.isLoadingExport = false;
                slice.dialogs.exportCsv = initialState.dialogs.exportCsv;
            })

            //importCsvTemplatePhysicalAssetsAction
            .addCase(importCsvTemplatePhysicalAssetsAction.pending, (slice: TSlice) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = true;
            })
            .addCase(importCsvTemplatePhysicalAssetsAction.rejected, (slice: TSlice) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = false;
            })
            .addCase(importCsvTemplatePhysicalAssetsAction.fulfilled, (slice: TSlice, {payload}) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = false;
            })

            //VIRTUAL ASSET
            .addCase(getVirtualAssetsAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingList = true;
                slice.isError = false;
            })
            .addCase(getVirtualAssetsAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingList = false;
                slice.isError = true;
            })
            .addCase(getVirtualAssetsAction.fulfilled, (slice: TSlice, {payload}) => {
                slice.loadings.isLoadingList = false;

                // const {array, pageInfo} = setPaginationInStore<TVirtualAsset>(
                //     slice.pageInfo,
                //     slice.virtualAssets,
                //     payload.resp.pageInfo,
                //     payload.resp.data,
                //     payload.isNewOnTop
                // );
                //
                // slice.virtualAssets = array;
                // slice.pageInfo = pageInfo;

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<TVirtualAsset>(
                    slice.virtualAssets,
                    slice.pageInfo,
                    payload.resp.data,
                    payload.resp.pageInfo,
                    slice.minMaxLoadedPage.minLoadedPage,
                    slice.minMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.virtualAssets = results;
                slice.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.pageInfo = payload.resp.pageInfo;
            })

            //GetAssetById
            .addCase(GetVirtualAssetById.pending, (slice: TSlice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(GetVirtualAssetById.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingExact = false;
            })
            .addCase(GetVirtualAssetById.fulfilled, (slice: TSlice, {payload}) => {
                slice.loadings.isLoadingExact = false;
                slice.selectedVirtualAsset = payload;
            })

            //CreateAsset
            .addCase(createVirtualAssetAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingCreateAsset = true;
            })
            .addCase(createVirtualAssetAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingCreateAsset = false;
                slice.isError = true;
            })
            .addCase(createVirtualAssetAction.fulfilled, (slice: TSlice, {payload: {resp}}) => {
                slice.dialogs.createAsset = initialState.dialogs.createAsset;

                slice.loadings.isLoadingCreateAsset = false;
                slice.selectedVirtualAsset = resp;
            })
            .addCase(updateVirtualAssetAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(updateVirtualAssetAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingExact = false;
            })
            .addCase(updateVirtualAssetAction.fulfilled, (slice: TSlice, {payload: {resp}}) => {
                slice.dialogs.editAsset = initialState.dialogs.editAsset;

                slice.loadings.isLoadingExact = false;

                const tmp = slice.virtualAssets;
                const index = tmp.findIndex(e => e?.id === resp.id);
                if (index > -1) {
                    tmp[index] = resp;
                    slice.virtualAssets = tmp;
                }

                slice.selectedVirtualAsset = resp;
            })
            .addCase(deleteVirtualAssetAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(deleteVirtualAssetAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingExact = false;
                slice.isError = true;
            })
            .addCase(deleteVirtualAssetAction.fulfilled, (slice: TSlice, {payload: {data, resp}}) => {
                const tmp = slice.virtualAssets.filter(e => e?.id !== data.assetId);
                slice.virtualAssets = tmp;

                if (resp) slice.dialogs.deleteAsset.response = resp.message;
                slice.loadings.isLoadingExact = false;
            })

            //Release
            .addCase(releaseVirtualAssetsAction.pending, (slice) => {
                slice.loadings.releasePhysicalAssets = true;
            })
            .addCase(releaseVirtualAssetsAction.rejected, (slice) => {
                slice.loadings.releasePhysicalAssets = false;
            })
            .addCase(releaseVirtualAssetsAction.fulfilled, (slice) => {
                slice.loadings.releasePhysicalAssets = false;
                slice.dialogs.releaseDialog = initialState.dialogs.releaseDialog;
            })
            //SETTINGS
            .addCase(getVirtualAssetsSettingsAction.pending, (slice) => {
                slice.loadings.isLoadingSettings = true;
            })
            .addCase(getVirtualAssetsSettingsAction.rejected, (slice) => {
                slice.loadings.isLoadingSettings = false;
                slice.isError = true;
            })
            .addCase(getVirtualAssetsSettingsAction.fulfilled, (slice:TSlice, { payload: {data, resp} }) => {
                slice.loadings.isLoadingSettings = false
                slice.virtualSettings = resp;
            })

            .addCase(createVirtualAssetsSettingRowAction.pending, (slice) => {
                slice.loadings.addSettingLoading = true;
            })
            .addCase(createVirtualAssetsSettingRowAction.rejected, (slice) => {
                slice.loadings.addSettingLoading = false;
            })
            .addCase(createVirtualAssetsSettingRowAction.fulfilled, (slice:TSlice, { payload: {data, resp} }) => {
                slice.virtualSettings = resp;
                slice.loadings.addSettingLoading = false;
                slice.dialogs.createSettingRow = initialState.dialogs.createSettingRow;
            })

            .addCase(editVirtualAssetsSettingRowAction.pending, (slice) => {
                slice.loadings.editSettingLoading = true;
            })
            .addCase(editVirtualAssetsSettingRowAction.rejected, (slice) => {
                slice.loadings.editSettingLoading = false;
            })
            .addCase(editVirtualAssetsSettingRowAction.fulfilled, (slice:TSlice, { payload: {data, resp} }) => {
                slice.virtualSettings = resp;
                slice.loadings.editSettingLoading = false;
                slice.dialogs.editSettingRow.response = `has been changed successfully.`;
            })

            .addCase(deleteVirtualAssetsSettingRowAction.pending, (slice) => {
                slice.loadings.deleteSettingLoading = true;
            })
            .addCase(deleteVirtualAssetsSettingRowAction.rejected, (slice) => {
                slice.loadings.deleteSettingLoading = false;
            })
            .addCase(deleteVirtualAssetsSettingRowAction.fulfilled, (slice:TSlice, { payload: {data, resp} }) => {
                slice.virtualSettings = resp;
                slice.loadings.deleteSettingLoading = false;
                slice.dialogs.deleteSettingRow = initialState.dialogs.deleteSettingRow;
            })
            .addCase(exportCsvVirtualAssetsAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingExport = true;
            })
            .addCase(exportCsvVirtualAssetsAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingExport = false;
            })
            .addCase(exportCsvVirtualAssetsAction.fulfilled, (slice: TSlice) => {
                slice.loadings.isLoadingExport = false;
                slice.dialogs.exportCsv = initialState.dialogs.exportCsv;
            })

            //importCsvTemplateVirtualAssetsAction
            .addCase(importCsvTemplateVirtualAssetsAction.pending, (slice: TSlice) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = true;
            })
            .addCase(importCsvTemplateVirtualAssetsAction.rejected, (slice: TSlice) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = false;
            })
            .addCase(importCsvTemplateVirtualAssetsAction.fulfilled, (slice: TSlice, {payload}) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = false;
            })

            //SOFTWARE
            .addCase(getSoftwareAssetsAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingList = true;
                slice.isError = false;
            })
            .addCase(getSoftwareAssetsAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingList = false;
                slice.isError = true;
            })
            .addCase(getSoftwareAssetsAction.fulfilled, (slice: TSlice, {payload}) => {
                slice.loadings.isLoadingList = false;

                // const {array, pageInfo} = setPaginationInStore<TSoftwareAsset>(
                //     slice.pageInfo,
                //     slice.softwareAssets,
                //     payload.resp.pageInfo,
                //     payload.resp.data,
                //     payload.isNewOnTop
                // );
                //
                // slice.softwareAssets = array;
                // slice.pageInfo = pageInfo;
                const {results, maxLoadedPage, minLoadedPage} = handlePagination<TSoftwareAsset>(
                    slice.softwareAssets,
                    slice.pageInfo,
                    payload.resp.data,
                    payload.resp.pageInfo,
                    slice.minMaxLoadedPage.minLoadedPage,
                    slice.minMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.softwareAssets = results;
                slice.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.pageInfo = payload.resp.pageInfo;
            })

            //GetAssetById
            .addCase(GetSoftwareAssetById.pending, (slice: TSlice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(GetSoftwareAssetById.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingExact = false;
            })
            .addCase(GetSoftwareAssetById.fulfilled, (slice: TSlice, {payload}) => {
                slice.loadings.isLoadingExact = false;
                slice.selectedSoftwareAsset = payload;
            })

            //CreateAsset
            .addCase(createSoftwareAssetAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingCreateAsset = true;
            })
            .addCase(createSoftwareAssetAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingCreateAsset = false;
                slice.isError = true;
            })
            .addCase(createSoftwareAssetAction.fulfilled, (slice: TSlice, {payload: {resp}}) => {
                slice.dialogs.createAsset = initialState.dialogs.createAsset;

                slice.loadings.isLoadingCreateAsset = false;
                slice.selectedSoftwareAsset = resp;
            })
            .addCase(updateSoftwareAssetAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(updateSoftwareAssetAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingExact = false;
            })
            .addCase(updateSoftwareAssetAction.fulfilled, (slice: TSlice, {payload: {resp}}) => {
                slice.dialogs.editAsset = initialState.dialogs.editAsset;

                slice.loadings.isLoadingExact = false;

                const tmp = slice.softwareAssets;
                const index = tmp.findIndex(e => e?.id === resp.id);
                if (index > -1) {
                    tmp[index] = resp;
                    slice.softwareAssets = tmp;
                }

                slice.selectedSoftwareAsset = resp;
            })
            .addCase(deleteSoftwareAssetAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(deleteSoftwareAssetAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingExact = false;
                slice.isError = true;
            })
            .addCase(deleteSoftwareAssetAction.fulfilled, (slice: TSlice, {payload: {data, resp}}) => {
                const tmp = slice.softwareAssets.filter(e => e?.id !== data.assetId);
                slice.softwareAssets = tmp;

                if (resp) slice.dialogs.deleteAsset.response = resp.message;
                slice.loadings.isLoadingExact = false;
            })

            //Release
            .addCase(releaseSoftwareAssetsAction.pending, (slice) => {
                slice.loadings.releasePhysicalAssets = true;
            })
            .addCase(releaseSoftwareAssetsAction.rejected, (slice) => {
                slice.loadings.releasePhysicalAssets = false;
            })
            .addCase(releaseSoftwareAssetsAction.fulfilled, (slice) => {
                slice.loadings.releasePhysicalAssets = false;
                slice.dialogs.releaseDialog = initialState.dialogs.releaseDialog;
            })
            //SETTINGS
            .addCase(getSoftwareAssetsSettingsAction.pending, (slice) => {
                slice.loadings.isLoadingSettings = true;
            })
            .addCase(getSoftwareAssetsSettingsAction.rejected, (slice) => {
                slice.loadings.isLoadingSettings = false;
                slice.isError = true;
            })
            .addCase(getSoftwareAssetsSettingsAction.fulfilled, (slice:TSlice, { payload: {data, resp} }) => {
                slice.loadings.isLoadingSettings = false
                slice.softwareSettings = resp;
            })

            .addCase(createSoftwareAssetsSettingRowAction.pending, (slice) => {
                slice.loadings.addSettingLoading = true;
            })
            .addCase(createSoftwareAssetsSettingRowAction.rejected, (slice) => {
                slice.loadings.addSettingLoading = false;
            })
            .addCase(createSoftwareAssetsSettingRowAction.fulfilled, (slice:TSlice, { payload: {data, resp} }) => {
                slice.softwareSettings = resp;
                slice.loadings.addSettingLoading = false;
                slice.dialogs.createSettingRow = initialState.dialogs.createSettingRow;
            })

            .addCase(editSoftwareAssetsSettingRowAction.pending, (slice) => {
                slice.loadings.editSettingLoading = true;
            })
            .addCase(editSoftwareAssetsSettingRowAction.rejected, (slice) => {
                slice.loadings.editSettingLoading = false;
            })
            .addCase(editSoftwareAssetsSettingRowAction.fulfilled, (slice:TSlice, { payload: {data, resp} }) => {
                slice.softwareSettings = resp;
                slice.loadings.editSettingLoading = false;
                slice.dialogs.editSettingRow.response = `has been changed successfully.`;
            })

            .addCase(deleteSoftwareAssetsSettingRowAction.pending, (slice) => {
                slice.loadings.deleteSettingLoading = true;
            })
            .addCase(deleteSoftwareAssetsSettingRowAction.rejected, (slice) => {
                slice.loadings.deleteSettingLoading = false;
            })
            .addCase(deleteSoftwareAssetsSettingRowAction.fulfilled, (slice:TSlice, { payload: {data, resp} }) => {
                slice.softwareSettings = resp;
                slice.loadings.deleteSettingLoading = false;
                slice.dialogs.deleteSettingRow = initialState.dialogs.deleteSettingRow;
            })
            .addCase(exportCsvSoftwareAssetsAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingExport = true;
            })
            .addCase(exportCsvSoftwareAssetsAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingExport = false;
            })
            .addCase(exportCsvSoftwareAssetsAction.fulfilled, (slice: TSlice) => {
                slice.loadings.isLoadingExport = false;
                slice.dialogs.exportCsv = initialState.dialogs.exportCsv
            })

            //importCsvTemplateSoftwareAssetsAction
            .addCase(importCsvTemplateSoftwareAssetsAction.pending, (slice: TSlice) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = true;
            })
            .addCase(importCsvTemplateSoftwareAssetsAction.rejected, (slice: TSlice) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = false;
            })
            .addCase(importCsvTemplateSoftwareAssetsAction.fulfilled, (slice: TSlice, {payload}) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = false;
            })

            //SAAS
            .addCase(getSaasAssetsAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingList = true;
                slice.isError = false;
            })
            .addCase(getSaasAssetsAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingList = false;
                slice.isError = true;
            })
            .addCase(getSaasAssetsAction.fulfilled, (slice: TSlice, {payload}) => {
                slice.loadings.isLoadingList = false;

                // const {array, pageInfo} = setPaginationInStore<TSaasAsset>(
                //     slice.pageInfo,
                //     slice.saasAssets,
                //     payload.resp.pageInfo,
                //     payload.resp.data,
                //     payload.isNewOnTop
                // );
                //
                // slice.saasAssets = array;
                // slice.pageInfo = pageInfo;
                const {results, maxLoadedPage, minLoadedPage} = handlePagination<TSaasAsset>(
                    slice.saasAssets,
                    slice.pageInfo,
                    payload.resp.data,
                    payload.resp.pageInfo,
                    slice.minMaxLoadedPage.minLoadedPage,
                    slice.minMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.saasAssets = results;
                slice.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.pageInfo = payload.resp.pageInfo;
            })

            //GetAssetById
            .addCase(GetSaasAssetById.pending, (slice: TSlice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(GetSaasAssetById.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingExact = false;
            })
            .addCase(GetSaasAssetById.fulfilled, (slice: TSlice, {payload}) => {
                slice.loadings.isLoadingExact = false;
                slice.selectedSaasAsset = payload;
            })

            //CreateAsset
            .addCase(createSaasAssetAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingCreateAsset = true;
            })
            .addCase(createSaasAssetAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingCreateAsset = false;
                slice.isError = true;
            })
            .addCase(createSaasAssetAction.fulfilled, (slice: TSlice, {payload: {resp}}) => {
                slice.dialogs.createAsset = initialState.dialogs.createAsset;

                slice.loadings.isLoadingCreateAsset = false;
                slice.selectedSaasAsset = resp;
            })
            .addCase(updateSaasAssetAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(updateSaasAssetAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingExact = false;
            })
            .addCase(updateSaasAssetAction.fulfilled, (slice: TSlice, {payload: {resp}}) => {
                slice.dialogs.editAsset = initialState.dialogs.editAsset;

                slice.loadings.isLoadingExact = false;

                const tmp = slice.saasAssets;
                const index = tmp.findIndex(e => e?.id === resp.id);
                if (index > -1) {
                    tmp[index] = resp;
                    slice.saasAssets = tmp;
                }

                slice.selectedSaasAsset = resp;
            })
            .addCase(deleteSaasAssetAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(deleteSaasAssetAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingExact = false;
                slice.isError = true;
            })
            .addCase(deleteSaasAssetAction.fulfilled, (slice: TSlice, {payload: {data, resp}}) => {
                const tmp = slice.saasAssets.filter(e => e?.id !== data.assetId);
                slice.saasAssets = tmp;

                if (resp) slice.dialogs.deleteAsset.response = resp.message;
                slice.loadings.isLoadingExact = false;
            })

            //Release
            .addCase(releaseSaasAssetsAction.pending, (slice) => {
                slice.loadings.releasePhysicalAssets = true;
            })
            .addCase(releaseSaasAssetsAction.rejected, (slice) => {
                slice.loadings.releasePhysicalAssets = false;
            })
            .addCase(releaseSaasAssetsAction.fulfilled, (slice) => {
                slice.loadings.releasePhysicalAssets = false;
                slice.dialogs.releaseDialog = initialState.dialogs.releaseDialog;
            })
            //SETTINGS
            .addCase(getSaasAssetsSettingsAction.pending, (slice) => {
                slice.loadings.isLoadingSettings = true;
            })
            .addCase(getSaasAssetsSettingsAction.rejected, (slice) => {
                slice.loadings.isLoadingSettings = false;
                slice.isError = true;
            })
            .addCase(getSaasAssetsSettingsAction.fulfilled, (slice:TSlice, { payload: {data, resp} }) => {
                slice.loadings.isLoadingSettings = false
                slice.saasSettings = resp;
            })

            .addCase(createSaasAssetsSettingRowAction.pending, (slice) => {
                slice.loadings.addSettingLoading = true;
            })
            .addCase(createSaasAssetsSettingRowAction.rejected, (slice) => {
                slice.loadings.addSettingLoading = false;
            })
            .addCase(createSaasAssetsSettingRowAction.fulfilled, (slice:TSlice, { payload: {data, resp} }) => {
                slice.saasSettings = resp;
                slice.loadings.addSettingLoading = false;
                slice.dialogs.createSettingRow = initialState.dialogs.createSettingRow;
            })

            .addCase(editSaasAssetsSettingRowAction.pending, (slice) => {
                slice.loadings.editSettingLoading = true;
            })
            .addCase(editSaasAssetsSettingRowAction.rejected, (slice) => {
                slice.loadings.editSettingLoading = false;
            })
            .addCase(editSaasAssetsSettingRowAction.fulfilled, (slice:TSlice, { payload: {data, resp} }) => {
                slice.saasSettings = resp;
                slice.loadings.editSettingLoading = false;
                slice.dialogs.editSettingRow.response = `has been changed successfully.`;
            })

            .addCase(deleteSaasAssetsSettingRowAction.pending, (slice) => {
                slice.loadings.deleteSettingLoading = true;
            })
            .addCase(deleteSaasAssetsSettingRowAction.rejected, (slice) => {
                slice.loadings.deleteSettingLoading = false;
            })
            .addCase(deleteSaasAssetsSettingRowAction.fulfilled, (slice:TSlice, { payload: {data, resp} }) => {
                slice.saasSettings = resp;
                slice.loadings.deleteSettingLoading = false;
                slice.dialogs.deleteSettingRow = initialState.dialogs.deleteSettingRow;
            })
            .addCase(exportCsvSaasAssetsAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingExport = true;
            })
            .addCase(exportCsvSaasAssetsAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingExport = false;
            })
            .addCase(exportCsvSaasAssetsAction.fulfilled, (slice: TSlice) => {
                slice.loadings.isLoadingExport = false;
                slice.dialogs.exportCsv = initialState.dialogs.exportCsv
            })

            //importCsvTemplateSaasAssetsAction
            .addCase(importCsvTemplateSaasAssetsAction.pending, (slice: TSlice) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = true;
            })
            .addCase(importCsvTemplateSaasAssetsAction.rejected, (slice: TSlice) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = false;
            })
            .addCase(importCsvTemplateSaasAssetsAction.fulfilled, (slice: TSlice, {payload}) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = false;
            })
            //Certificate
            .addCase(getCertificateAssetsAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingList = true;
                slice.isError = false;
            })
            .addCase(getCertificateAssetsAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingList = false;
                slice.isError = true;
            })
            .addCase(getCertificateAssetsAction.fulfilled, (slice: TSlice, {payload}) => {
                slice.loadings.isLoadingList = false;

                // const {array, pageInfo} = setPaginationInStore<TCertificateAsset>(
                //     slice.pageInfo,
                //     slice.certificateAssets,
                //     payload.resp.pageInfo,
                //     payload.resp.data,
                //     payload.isNewOnTop
                // );
                //
                // slice.certificateAssets = array;
                // slice.pageInfo = pageInfo;
                const {results, maxLoadedPage, minLoadedPage} = handlePagination<TCertificateAsset>(
                    slice.certificateAssets,
                    slice.pageInfo,
                    payload.resp.data,
                    payload.resp.pageInfo,
                    slice.minMaxLoadedPage.minLoadedPage,
                    slice.minMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.certificateAssets = results;
                slice.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.pageInfo = payload.resp.pageInfo;
            })

            //GetAssetById
            .addCase(GetCertificateAssetById.pending, (slice: TSlice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(GetCertificateAssetById.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingExact = false;
            })
            .addCase(GetCertificateAssetById.fulfilled, (slice: TSlice, {payload}) => {
                slice.loadings.isLoadingExact = false;
                slice.selectedCertificateAsset = payload;
            })

            //CreateAsset
            .addCase(createCertificateAssetAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingCreateAsset = true;
            })
            .addCase(createCertificateAssetAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingCreateAsset = false;
                slice.isError = true;
            })
            .addCase(createCertificateAssetAction.fulfilled, (slice: TSlice, {payload: {resp}}) => {
                slice.dialogs.createAsset = initialState.dialogs.createAsset;

                slice.loadings.isLoadingCreateAsset = false;
                slice.selectedCertificateAsset = resp;
            })
            .addCase(updateCertificateAssetAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(updateCertificateAssetAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingExact = false;
            })
            .addCase(updateCertificateAssetAction.fulfilled, (slice: TSlice, {payload: {resp}}) => {
                slice.dialogs.editAsset = initialState.dialogs.editAsset;

                slice.loadings.isLoadingExact = false;

                const tmp = slice.certificateAssets;
                const index = tmp.findIndex(e => e?.id === resp.id);
                if (index > -1) {
                    tmp[index] = resp;
                    slice.certificateAssets = tmp;
                }

                slice.selectedCertificateAsset = resp;
            })
            .addCase(deleteCertificateAssetAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(deleteCertificateAssetAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingExact = false;
                slice.isError = true;
            })
            .addCase(deleteCertificateAssetAction.fulfilled, (slice: TSlice, {payload: {data, resp}}) => {
                const tmp = slice.certificateAssets.filter(e => e?.id !== data.assetId);
                slice.certificateAssets = tmp;

                if (resp) slice.dialogs.deleteAsset.response = resp.message;
                slice.loadings.isLoadingExact = false;
            })

            //Release
            .addCase(releaseCertificateAssetsAction.pending, (slice) => {
                slice.loadings.releasePhysicalAssets = true;
            })
            .addCase(releaseCertificateAssetsAction.rejected, (slice) => {
                slice.loadings.releasePhysicalAssets = false;
            })
            .addCase(releaseCertificateAssetsAction.fulfilled, (slice) => {
                slice.loadings.releasePhysicalAssets = false;
                slice.dialogs.releaseDialog = initialState.dialogs.releaseDialog;
            })
            //SETTINGS
            .addCase(getCertificateAssetsSettingsAction.pending, (slice) => {
                slice.loadings.isLoadingSettings = true;
            })
            .addCase(getCertificateAssetsSettingsAction.rejected, (slice) => {
                slice.loadings.isLoadingSettings = false;
                slice.isError = true;
            })
            .addCase(getCertificateAssetsSettingsAction.fulfilled, (slice:TSlice, { payload: {data, resp} }) => {
                slice.loadings.isLoadingSettings = false
                slice.certificateSettings = resp;
            })

            .addCase(createCertificateAssetsSettingRowAction.pending, (slice) => {
                slice.loadings.addSettingLoading = true;
            })
            .addCase(createCertificateAssetsSettingRowAction.rejected, (slice) => {
                slice.loadings.addSettingLoading = false;
            })
            .addCase(createCertificateAssetsSettingRowAction.fulfilled, (slice:TSlice, { payload: {data, resp} }) => {
                slice.certificateSettings = resp;
                slice.loadings.addSettingLoading = false;
                slice.dialogs.createSettingRow = initialState.dialogs.createSettingRow;
            })

            .addCase(editCertificateAssetsSettingRowAction.pending, (slice) => {
                slice.loadings.editSettingLoading = true;
            })
            .addCase(editCertificateAssetsSettingRowAction.rejected, (slice) => {
                slice.loadings.editSettingLoading = false;
            })
            .addCase(editCertificateAssetsSettingRowAction.fulfilled, (slice:TSlice, { payload: {data, resp} }) => {
                slice.certificateSettings = resp;
                slice.loadings.editSettingLoading = false;
                slice.dialogs.editSettingRow.response = `has been changed successfully.`;
            })

            .addCase(deleteCertificateAssetsSettingRowAction.pending, (slice) => {
                slice.loadings.deleteSettingLoading = true;
            })
            .addCase(deleteCertificateAssetsSettingRowAction.rejected, (slice) => {
                slice.loadings.deleteSettingLoading = false;
            })
            .addCase(deleteCertificateAssetsSettingRowAction.fulfilled, (slice:TSlice, { payload: {data, resp} }) => {
                slice.certificateSettings = resp;
                slice.loadings.deleteSettingLoading = false;
                slice.dialogs.deleteSettingRow = initialState.dialogs.deleteSettingRow;
            })
            .addCase(exportCsvCertificateAssetsAction.pending, (slice: TSlice) => {
                slice.loadings.isLoadingExport = true;
            })
            .addCase(exportCsvCertificateAssetsAction.rejected, (slice: TSlice) => {
                slice.loadings.isLoadingExport = false;
            })
            .addCase(exportCsvCertificateAssetsAction.fulfilled, (slice: TSlice) => {
                slice.loadings.isLoadingExport = false;
                slice.dialogs.exportCsv = initialState.dialogs.exportCsv;
            })

            //importCsvTemplateCertificateAssetsAction
            .addCase(importCsvTemplateCertificateAssetsAction.pending, (slice: TSlice) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = true;
            })
            .addCase(importCsvTemplateCertificateAssetsAction.rejected, (slice: TSlice) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = false;
            })
            .addCase(importCsvTemplateCertificateAssetsAction.fulfilled, (slice: TSlice, {payload}) => {
                slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = false;
            })


            //GetShortPhysicalAssets
            .addCase(GetShortPhysicalAssets.pending, (slice) => {
                slice.loadings.isLoadingShortPhysicalAssets = true;
            })
            .addCase(GetShortPhysicalAssets.rejected, (slice) => {
                slice.loadings.isLoadingShortPhysicalAssets = false;
            })
            .addCase(GetShortPhysicalAssets.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingShortPhysicalAssets = false;
                slice.shortPhysicalAssets = payload.shortPhysicalAssets;
            })

            //GetAvailableFieldsForExport
            .addCase(GetPhysicalAssetsAvailableFieldsForExport.pending, (slice) => {
                slice.loadings.isLoadingFields = true;
            })
            .addCase(GetPhysicalAssetsAvailableFieldsForExport.rejected, (slice) => {
                slice.loadings.isLoadingFields = false;
            })
            .addCase(GetPhysicalAssetsAvailableFieldsForExport.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingFields = false;
                slice.dialogs.exportCsv.fields = payload.fields;
            })
            .addCase(GetVirtualAssetsAvailableFieldsForExport.pending, (slice) => {
                slice.loadings.isLoadingFields = true;
            })
            .addCase(GetVirtualAssetsAvailableFieldsForExport.rejected, (slice) => {
                slice.loadings.isLoadingFields = false;
            })
            .addCase(GetVirtualAssetsAvailableFieldsForExport.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingFields = false;
                slice.dialogs.exportCsv.fields = payload.fields;
            })
            .addCase(GetSoftwareAssetsAvailableFieldsForExport.pending, (slice) => {
                slice.loadings.isLoadingFields = true;
            })
            .addCase(GetSoftwareAssetsAvailableFieldsForExport.rejected, (slice) => {
                slice.loadings.isLoadingFields = false;
            })
            .addCase(GetSoftwareAssetsAvailableFieldsForExport.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingFields = false;
                slice.dialogs.exportCsv.fields = payload.fields;
            })
            .addCase(GetSaasAvailableFieldsForExport.pending, (slice) => {
                slice.loadings.isLoadingFields = true;
            })
            .addCase(GetSaasAvailableFieldsForExport.rejected, (slice) => {
                slice.loadings.isLoadingFields = false;
            })
            .addCase(GetSaasAvailableFieldsForExport.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingFields = false;
                slice.dialogs.exportCsv.fields = payload.fields;
            })
            .addCase(GetCertificatesAvailableFieldsForExport.pending, (slice) => {
                slice.loadings.isLoadingFields = true;
            })
            .addCase(GetCertificatesAvailableFieldsForExport.rejected, (slice) => {
                slice.loadings.isLoadingFields = false;
            })
            .addCase(GetCertificatesAvailableFieldsForExport.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingFields = false;
                slice.dialogs.exportCsv.fields = payload.fields;
            })
        //Requests
            .addCase(mainAssetsGetRequestsWithFilterPaginationAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.incomingRequests = true;

                if (clean) {
                    slice.incomingRequests.requests = initialState.incomingRequests.requests;
                    slice.incomingRequests.pageInfo = initialState.incomingRequests.pageInfo;
                    slice.incomingRequests.minMaxLoadedPage = initialState.incomingRequests.minMaxLoadedPage;
                }
            })
            .addCase(mainAssetsGetRequestsWithFilterPaginationAction.rejected, (slice) => {
                slice.loadings.incomingRequests = false;
            })
            .addCase(mainAssetsGetRequestsWithFilterPaginationAction.fulfilled, (slice, {payload}) => {
                slice.loadings.incomingRequests = false;

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<MainAssetsRequestModel>(
                    slice.incomingRequests.requests,
                    slice.incomingRequests.pageInfo,
                    payload.result,
                    payload.pageInfo,
                    slice.incomingRequests.minMaxLoadedPage.minLoadedPage,
                    slice.incomingRequests.minMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.incomingRequests.requests = results;
                slice.incomingRequests.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.incomingRequests.pageInfo = payload.pageInfo;
            })

            .addCase(mainAssetsGetAssetRequestByIdAction.pending, (slice) => {
                slice.loadings.incomingRequestsExact = true;
            })
            .addCase(mainAssetsGetAssetRequestByIdAction.rejected, (slice, {payload}) => {
                // const errors409 = getErrorsByCode<string>(409, payload?.all || []);
                // if (errors409.length > 0) {
                //     slice.dialogs.requestAssetError.message = errors409;
                //     slice.dialogs.requestAssetError.title = 'Request not found';
                // }

                slice.loadings.incomingRequestsExact = false;
            })
            .addCase(mainAssetsGetAssetRequestByIdAction.fulfilled, (slice, {payload, meta}) => {
                slice.loadings.incomingRequestsExact = false;

                if (payload.request.status === AssetsRequestStatus.Cancelled) {
                    if (!slice.incomingRequests.selected) {
                        slice.dialogs.requestAssetError.message = ['Request was cancelled'];
                        slice.dialogs.requestAssetError.title = `Request ${payload.request.id}`;
                    }

                    // slice.incomingRequests.requests = slice.incomingRequests.requests.filter(e => e.id !== payload.request.id);
                } else {
                    slice.incomingRequests.selected = payload;

                    // slice.incomingRequests.requests = slice.incomingRequests.requests.map(e => e.id === payload.request.id ? ({
                    //     ...e,
                    //     ...payload.request,
                    // }) : e)
                }

                slice.incomingRequests.requests = slice.incomingRequests.requests.map(e => e.id === payload.request.id ? ({
                    ...e,
                    ...payload.request,
                }) : e)
            })

            .addCase(mainAssetsRequestsRejectAction.pending, (slice: TSlice) => {
                slice.loadings.incomingRequestRejecting = true;
            })
            .addCase(mainAssetsRequestsRejectAction.rejected, (slice, {payload}) => {
                const errors409 = getErrorsByCode<string>(409, payload?.all || []);
                if (errors409.length > 0) {
                    slice.dialogs.requestAssetError.message = errors409;
                    slice.dialogs.requestAssetError.title = 'Reject request error';
                    slice.dialogs.rejectRequest = initialState.dialogs.rejectRequest;
                }

                slice.loadings.incomingRequestRejecting = false;
            })
            .addCase(mainAssetsRequestsRejectAction.fulfilled, (slice: TSlice, {payload, meta}) => {
                slice.loadings.incomingRequestRejecting = false;
                slice.dialogs.rejectRequest = initialState.dialogs.rejectRequest;

                slice.incomingRequests.requests = slice.incomingRequests.requests.map(e => e.id === meta.arg.data.data.requestId ? ({
                    ...e,
                    status: AssetsRequestStatus.Rejected,
                    resolver: {
                        publicId: meta.arg.addictiveData?.resolver.id || '',
                        fullName: meta.arg.addictiveData?.resolver.name || '',
                    },
                    resolveDate: new Date().toISOString()
                }) : e)
            })

            .addCase(mainAssetsApproveRequestRequestAssetAction.pending, (slice: TSlice) => {
                slice.loadings.approveRequest = true;
            })
            .addCase(mainAssetsApproveRequestRequestAssetAction.rejected, (slice, {payload}) => {
                const errors409 = getErrorsByCode<string>(409, payload?.all || []);
                if (errors409.length > 0) {
                    // slice.dialogs.requestAssetError.message = ['Request was changed. Please, try again.'];
                    slice.dialogs.requestAssetError.message = errors409;
                    slice.dialogs.requestAssetError.title = 'Approve request error';
                    slice.dialogs.approveRequestRequest = initialState.dialogs.approveRequestRequest;
                }

                slice.loadings.approveRequest = false;
            })
            .addCase(mainAssetsApproveRequestRequestAssetAction.fulfilled, (slice: TSlice, {payload, meta}) => {
                slice.loadings.approveRequest = false;
                slice.dialogs.approveRequestRequest = initialState.dialogs.approveRequestRequest;

                slice.incomingRequests.requests = slice.incomingRequests.requests.map(e => e.id === meta.arg.data.data.requestId ? ({
                    ...e,
                    status: AssetsRequestStatus.Approved,
                    resolver: {
                        publicId: meta.arg.addictiveData?.resolver.id || '',
                        fullName: meta.arg.addictiveData?.resolver.name || '',
                    },
                    resolveDate: new Date().toISOString()
                }) : e)
            })

            .addCase(mainAssetsCreateAndAssignAssetAction.pending, (slice) => {
                slice.loadings.approveRequest = true;
            })
            .addCase(mainAssetsCreateAndAssignAssetAction.rejected, (slice, {payload}) => {
                const errors409 = getErrorsByCode<string>(409, payload?.all || []);
                if (errors409.length > 0) {
                    // slice.dialogs.requestAssetError.message = ['Request was changed. Please, try again.'];
                    slice.dialogs.requestAssetError.message = errors409;
                    slice.dialogs.requestAssetError.title = 'Create and assign asset error';
                    slice.dialogs.createAndAssign = initialState.dialogs.createAndAssign;
                }

                slice.loadings.approveRequest = false;
            })
            .addCase(mainAssetsCreateAndAssignAssetAction.fulfilled, (slice, {payload, meta}) => {
                slice.loadings.approveRequest = false;
                slice.dialogs.createAndAssign = initialState.dialogs.createAndAssign;

                slice.incomingRequests.requests = slice.incomingRequests.requests.map(e => e.id === meta.arg.data.data.requestId ? ({
                    ...e,
                    status: AssetsRequestStatus.Approved,
                    resolver: {
                        publicId: meta.arg.addictiveData?.resolver.id || '',
                        fullName: meta.arg.addictiveData?.resolver.name || '',
                    },
                    resolveDate: new Date().toISOString()
                }) : e)
            })

            .addCase(mainAssetsApproveRequestRemoveAssetAction.pending, (slice) => {
                slice.loadings.approveRequest = true;
            })
            .addCase(mainAssetsApproveRequestRemoveAssetAction.rejected, (slice, {payload}) => {
                const errors409 = getErrorsByCode<string>(409, payload?.all || []);
                if (errors409.length > 0) {
                    // slice.dialogs.requestAssetError.message = ['Request was changed. Please, try again.'];
                    slice.dialogs.requestAssetError.message = errors409;
                    slice.dialogs.requestAssetError.title = 'Unassign asset error';
                    slice.dialogs.approveRequestRemove = initialState.dialogs.approveRequestRemove;
                }

                slice.loadings.approveRequest = false;
            })
            .addCase(mainAssetsApproveRequestRemoveAssetAction.fulfilled, (slice, {payload, meta}) => {
                slice.loadings.approveRequest = false;
                slice.dialogs.approveRequestRemove = initialState.dialogs.approveRequestRemove;

                slice.incomingRequests.requests = slice.incomingRequests.requests.map(e => e.id === meta.arg.data.data.requestId ? ({
                    ...e,
                    status: AssetsRequestStatus.Approved,
                    resolver: {
                        publicId: meta.arg.addictiveData?.resolver.id || '',
                        fullName: meta.arg.addictiveData?.resolver.name || '',
                    },
                    resolveDate: new Date().toISOString()
                }) : e)
            })

            .addCase(mainAssetsAssignNotAssignedAssetAction.pending, (slice) => {
                slice.loadings.approveRequest = true;
            })
            .addCase(mainAssetsAssignNotAssignedAssetAction.rejected, (slice, {payload}) => {
                const errors409 = getErrorsByCode<string>(409, payload?.all || []);
                if (errors409.length > 0) {
                    // slice.dialogs.requestAssetError.message = ['Request was changed. Please, try again.'];
                    slice.dialogs.requestAssetError.message = errors409;
                    slice.dialogs.requestAssetError.title = 'Assign asset error';
                    slice.dialogs.approveAssignNotAssigned = initialState.dialogs.approveAssignNotAssigned;
                }

                slice.loadings.approveRequest = false;
            })
            .addCase(mainAssetsAssignNotAssignedAssetAction.fulfilled, (slice, {payload, meta}) => {
                slice.loadings.approveRequest = false;
                slice.dialogs.approveAssignNotAssigned = initialState.dialogs.approveAssignNotAssigned;

                slice.incomingRequests.requests = slice.incomingRequests.requests.map(e => e.id === meta.arg.data.data.requestId ? ({
                    ...e,
                    status: AssetsRequestStatus.Approved,
                    resolver: {
                        publicId: meta.arg.addictiveData?.resolver.id || '',
                        fullName: meta.arg.addictiveData?.resolver.name || '',
                    },
                    resolveDate: new Date().toISOString()
                }) : e)
            })

            .addCase(mainAssetsReassignAssetAction.pending, (slice) => {
                slice.loadings.approveRequest = true;
            })
            .addCase(mainAssetsReassignAssetAction.rejected, (slice, {payload}) => {
                const errors409 = getErrorsByCode<string>(409, payload?.all || []);
                if (errors409.length > 0) {
                    // slice.dialogs.requestAssetError.message = ['Request was changed. Please, try again.'];
                    slice.dialogs.requestAssetError.message = errors409;
                    slice.dialogs.requestAssetError.title = 'Reassign asset error';
                    slice.dialogs.approveReassignAsset = initialState.dialogs.approveReassignAsset;
                }

                slice.loadings.approveRequest = false;
            })
            .addCase(mainAssetsReassignAssetAction.fulfilled, (slice, {payload, meta}) => {
                slice.loadings.approveRequest = false;
                slice.dialogs.approveReassignAsset = initialState.dialogs.approveReassignAsset;

                slice.incomingRequests.requests = slice.incomingRequests.requests.map(e => e.id === meta.arg.data.data.requestId ? ({
                    ...e,
                    status: AssetsRequestStatus.Approved,
                    resolver: {
                        publicId: meta.arg.addictiveData?.resolver.id || '',
                        fullName: meta.arg.addictiveData?.resolver.name || '',
                    },
                    resolveDate: new Date().toISOString()
                }) : e)
            })
    }
})


export const PhysicalAssetsReducer = PhysicalAssetsSlice.reducer;

export const {
    cleanUp,
    selectAsset,
    deselectAsset,
    selectVirtualAsset,
    deselectVirtualAsset,
    selectSoftwareAsset,
    deselectSoftwareAsset,
    selectSaasAsset,
    deselectSaasAsset,
    selectCertificateAsset,
    deselectCertificateAsset,

    openExportCsv,
    hideExportCsv,
    openReleaseDialog,
    hideReleaseDialog,

    setCreateAssetDialogAction,
    setDeleteAssetDialogAction,
    setChangeStatusDialogAction,
    setEditAssetDialogAction,

    setCreateSettingRowDialogAction,
    setEditSettingRowDialogAction,
    setDeleteSettingRowDialogAction,
    replaceMinMaxLoadedPage,

    cleanPhysicalAssets,
    cleanVirtualAssets,
    cleanCertificates,
    cleanSaas,
    cleanSoftware,
    replaceRequestMinMaxLoadedPage,
    eraseRequestList,

    setCreateAndAssignRequestAction,
    setImportCsvDialogAction,

    setRejectingDialogAction,
    setApproveRequestRemoveDialogAction,
    setApproveRequestRequestDialogAction,
    setApproveAssignNotAssignedAction,
    setRequestAssetErrorAction,
    setApproveReassignAssetAction,

    clearSelectedIncomingRequestAction,
} = PhysicalAssetsSlice.actions

//selectors
const selectSelf = (state: AppState): TSlice => state.physicalAssets as TSlice;
export const assetsSelector = createSelector(selectSelf, state => state.assets);
export const shortPhysicalAssetsSelector = createSelector(selectSelf, state => state.shortPhysicalAssets);
export const virtualAssetsSelector = createSelector(selectSelf, state => state.virtualAssets);
export const softwareAssetsSelector = createSelector(selectSelf, state => state.softwareAssets);
export const saasAssetsSelector = createSelector(selectSelf, state => state.saasAssets);
export const certificateAssetsSelector = createSelector(selectSelf, state => state.certificateAssets);
export const selectedAssetSelector = createSelector(selectSelf, state => state.selectedAsset);
export const selectedVirtual = createSelector(selectSelf, state => state.selectedVirtualAsset);
export const selectedSoftware = createSelector(selectSelf, state => state.selectedSoftwareAsset);
export const selectedSaas = createSelector(selectSelf, state => state.selectedSaasAsset);
export const selectedCertificate = createSelector(selectSelf, state => state.selectedCertificateAsset);
export const pageInfoSelector = createSelector(selectSelf, state => state.pageInfo);

export const assetsSettingsSelector = createSelector(selectSelf, state => state.settings);
export const virtualAssetsSettingsSelector = createSelector(selectSelf, state => state.virtualSettings);
export const softwareAssetsSettingsSelector = createSelector(selectSelf, state => state.softwareSettings);
export const saasAssetsSettingsSelector = createSelector(selectSelf, state => state.saasSettings);
export const certificateAssetsSettingsSelector = createSelector(selectSelf, state => state.certificateSettings);

export const loadings = createSelector(selectSelf, state => state.loadings)
export const isRejected = createSelector(selectSelf, state => state.isError);

export const assetsDialogsSelector = createSelector(selectSelf, state => state.dialogs);
export const minMaxLoadedPage = createSelector(selectSelf, state => state.minMaxLoadedPage);
export const incomingRequestsSelector = createSelector(selectSelf, state => state.incomingRequests);



