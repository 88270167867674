import {DocumentExecutorRecipientModel, DocumentExecutorStepModel,} from "../../../../../../GQLTypes";
import {
    ActionsContainer,
    ActorEmailTypography,
    CollapsableContainer,
    GroupContainer,
    StatusRoundBox,
    StatusTableCellContainer,
    StatusTypography,
    StepContainer,
    StepText
} from "./styled";
import {IconButton, Typography} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {useMainTranslation} from "../../../../../../hooks/useMainTranslationHooks/useMainTranslation";
import {useRecipientStep} from "./hook";
import {IN_PROGRESS_STATUS, IStatusProps, REJECTED_STATUS, TDocumentStatus} from "../../../../types";
import {getExpiredTitleColor, getStatusColor} from "../../../../helpers";
import {LoadingButton} from "@mui/lab";
import {diffBetweenDateAndNow} from "../../../../../../utils/dateTools";
import {FlexRow} from "../../../editorTitleWithActionsRow/styled";


export type TRecipientStepProps = {
    step: DocumentExecutorStepModel;
    id: number,
    isAbleToResendInvite: boolean,
    handleResendInvite: (step: DocumentExecutorStepModel, recipient: DocumentExecutorRecipientModel) => void;
    onChangeEta: () => void;
    isLoading: IStatusProps["isLoading"],
    isMain: boolean,
}
export const RecipientStep = (props: TRecipientStepProps) => {
    const {revDir, currentLanguage: {momentLocale}} = useMainTranslation();
    const {step, isAbleToResendInvite, handleResendInvite} = props;
    const {collapsed, toggleCollapse, isExpired} = useRecipientStep(props);

    const inProgress = step.recipients?.some(e => e.status === IN_PROGRESS_STATUS);
    const expiredTitleColor = getExpiredTitleColor(diffBetweenDateAndNow(step.endDate || '', momentLocale));

    return(
        <StepContainer>
            <CollapsableContainer>
                <StepText>{('Step ') + (props.id + 1)}</StepText>
                <IconButton size={'small'} onClick={toggleCollapse}>
                    {collapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>

                {props.step.recipients?.some(e => e.status === IN_PROGRESS_STATUS) && props.isMain && isExpired && <LoadingButton
                    variant={"contained"}
                    loading={props.isLoading.isChangingEtaInProgress}
                    size={'small'}
                    onClick={props.onChangeEta}
                >{('Add ETA')}</LoadingButton>}
            </CollapsableContainer>

            {!collapsed && inProgress && (
                <FlexRow alignItems={'center'} mt={'4px'}>
                    <Typography variant={'body2'}>
                        {'ETA: '}
                        <span style={{color: expiredTitleColor.color}}>{expiredTitleColor.title}</span>
                    </Typography>
                </FlexRow>
            )}

            {!collapsed &&
                <>
                    {step.recipients?.map(e => {
                        const statusColor = getStatusColor(e.status as TDocumentStatus);
                        return(
                            <GroupContainer key={e.email} color={statusColor}>
                                <StatusTableCellContainer>
                                    <StatusRoundBox color={statusColor} revDir={revDir}/>
                                    <StatusTypography color={statusColor}>{e.status?.toUpperCase().replaceAll('_', ' ')}</StatusTypography>
                                </StatusTableCellContainer>

                                <ActorEmailTypography>{e.email}</ActorEmailTypography>

                                <ActionsContainer>
                                    {e.status === REJECTED_STATUS && isAbleToResendInvite && <LoadingButton
                                        variant={"contained"}
                                        loading={props.isLoading.isReinviting}
                                        size={'small'}
                                        onClick={() => handleResendInvite(props.step, e)}
                                    >{('Resend')}</LoadingButton>}
                                </ActionsContainer>
                            </GroupContainer>
                        )
                    })}
                </>
            }
        </StepContainer>
    )
}