import {useFileDropZone} from "../../../../../../../newShared/hooks/useSingleFileDropZone";
import {FileDropZone} from "../../../../../../../newShared/components/fileDropZone";
import {INPUT_CLIENT_DATA_FILE_ACCEPT} from "../../../../constants";
import {FC, useEffect} from "react";
import {uploadFile} from "../../../../api";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {toBase64} from "../../../../../../../newShared/utils/base64/base64";
import {FileDrop} from "react-file-drop";

interface InputClientDataDropZoneProps {
    id: string,
    onFileUploaded: (fileId: string, id: string, fileName: string) => void,
    isLoadingCreateTarget: boolean,
    initialFileName?: string,
    handleDelete: () => void
}

export const InputClientDataDropZone: FC<InputClientDataDropZoneProps> = ({id, onFileUploaded, isLoadingCreateTarget, initialFileName, handleDelete}) => {
    const {workspaceId} = UseManageWorkspacesAndOrganizations();
    const {fileHandler, filePicker, uploadedFile, ref, handleDeleteFile} = useFileDropZone();
    // const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);

    const handleDeleteUploadedFile = () => {
        handleDelete();
        handleDeleteFile();
    }

    useEffect(() => {
        if(uploadedFile) _uploadFile();
        //eslint-disable-next-line
    }, [uploadedFile]);

    useEffect(() => {
        if(!isLoadingCreateTarget){
            handleDeleteFile();
        }
        //eslint-disable-next-line
    }, [isLoadingCreateTarget]);

    const _uploadFile = async() => {
        if(uploadedFile){
            // setIsLoadingUpload(true);
            try{
                const base64File = await toBase64(uploadedFile);
                const fileId = await uploadFile({workspaceId: workspaceId ?? '', file: base64File});
                onFileUploaded(fileId, id, uploadedFile.name);
            }catch (ex){
                console.log(`InputClientDataDropZone _uploadFile ex`, ex)
            }
            // setIsLoadingUpload(false);
        }
    }

    return(
        <FileDrop onTargetClick={filePicker} onDrop={(f) => fileHandler(f)}>
            <FileDropZone
                inputRef={ref}
                fileHandler={(e) => fileHandler(e)}
                accept={INPUT_CLIENT_DATA_FILE_ACCEPT}
                uploadedFiles={uploadedFile ? [uploadedFile] : undefined}
                initialFileNames={initialFileName ? [initialFileName] : undefined}
                handleDelete={handleDeleteUploadedFile}
            />
        </FileDrop>
    )
}
