import {useDispatch, useSelector} from "react-redux";
import {
    dialogs as dialogsSelector,
    eraseTemplates,
    hideExportCsv,
    loadings,
    openExportCsv,
    templates,
    templatesPageInfo
} from "../../../store/slice";
import {useHistory} from "react-router-dom";
import {
    KYC_ROOT_PATH,
    KYC_TEMPLATES_CREATE_PATH,
    KYC_TEMPLATES_LIST_PATH,
    TEMPLATES_DEFAULT_PAGING
} from "../../../constants";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {useScrollPagination} from "../../../../../../newShared/hooks/useScrollPagination";
import {
    ExportClientsByTemplate,
    ExportClientsByTemplateAutoDownload,
    GetKycAvailableFieldsForExport,
    GetKycTemplates
} from "../../../store/actions";
import {useEffect, useState} from "react";

export const useTemplatesList = () => {
    //root
    const dispatch = useDispatch();
    const history = useHistory();
    const {tMenu} = useMainTranslation();
    //selectors
    const _templates = useSelector(templates);
    const {getTemplatesList, isLoadingFields, exportCsv: isLoadingExport, downloadCsvInDeleteTemplateDialog} = useSelector(loadings);
    const pageInfo = useSelector(templatesPageInfo);
    const {
        exportCsv: {isOpen, fields, template},
        deleteTemplate: {isOpen: isOpenDeleteTemplate, clientsCount},
    } = useSelector(dialogsSelector);

    const [isInitial, setIsInitial] = useState<boolean>(true);

    //actions
    const handleGoToCreateTemplate = () => {
        history.push(KYC_TEMPLATES_CREATE_PATH);
    }

    const handleOpenExportCsv = (templateId: string) => {
        dispatch(openExportCsv({}));
        dispatch(GetKycAvailableFieldsForExport({templateId}));
    }

    const handleCloseExportCsv = () => {
        dispatch(hideExportCsv());
    }

    const handleExportCsv = (fieldKeys: string[]) => {
        template && dispatch(ExportClientsByTemplateAutoDownload({templateId: template.id, templateName: template.name, fieldKeys}));
    }

    const handleExportCsvFromDeleteTemplate = (fieldKeys: string[]) => {
        template && dispatch(ExportClientsByTemplate({templateId: template.id, templateName: template.name, fieldKeys, isDownload: clientsCount < 10000}));
    }

    //useEffects
    useSetBreadcrumbs([
        {
            title: tMenu('KYC Management'),
            path: KYC_ROOT_PATH
        },
        {
            title: tMenu('Templates'),
            path: KYC_TEMPLATES_LIST_PATH
        }
    ]);

    useEffect(() => {
        dispatch(GetKycTemplates({data: {pageInfo: {page: 0, count: TEMPLATES_DEFAULT_PAGING}}}));
        setIsInitial(false);

        return () => {
            dispatch(eraseTemplates());
        }
        //eslint-disable-next-line
    }, []);

    //scroll
    const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => {
            dispatch(GetKycTemplates({data: {pageInfo: {page, count: TEMPLATES_DEFAULT_PAGING}}}))
        },
        arrayLength: _templates.length,
        isLoading: getTemplatesList,
        pageInfo: pageInfo,
        // cleanData: () => dispatch(eraseCategories()),
        // controller: controller,
        // isOnTop: true,
        isNoMoreThanTotal: true,
        customDependencies: [],
        is0PageAddictive: false,
        disableInitFetch: true,
    })

    return{
        templates: _templates,
        isLoading: getTemplatesList,
        handleGoToCreateTemplate,
        isNoTemplatesFound: !getTemplatesList && !_templates.length && !isInitial,
        handleOpenExportCsv,

        exportCsv: {
            isOpen,
            availableFields: fields,
            handleExportCsv: isOpenDeleteTemplate ? handleExportCsvFromDeleteTemplate : handleExportCsv,
            handleClose: handleCloseExportCsv,
        },
        isLoadingFields,
        isLoadingExport : isOpenDeleteTemplate ? downloadCsvInDeleteTemplateDialog : isLoadingExport,

        scroll: {
            bottomLoader,
            onScroll,
            scrollRef,
            hasMore: pageInfo.total > ((pageInfo.page + 1) * pageInfo.count),
        }
    }
}
