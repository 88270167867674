import {Popper, styled} from "@mui/material";

export const EditorPopperButtonsStyled = styled(Popper)`
  z-index: 3100;
  margin-bottom: 4px !important;
  
  & > div {
    gap: 4px;
  }
`;

export const EditorPopperButtonsStyledModifiers = (boundary: Element | undefined) => [
    {
        name: 'flip',
        enabled: false,
    },
    {
        name: 'preventOverflow',
        enabled: true,
        options: {
            mainAxis: false,

            boundary,

            altAxis: true,
            altBoundary: true,
            tether: false,
            rootBoundary: 'viewport',
            padding: 8,
        },
    },

    {
        name: 'computeStyles',
        enabled: true,
        options: {
            adaptive: false, // true by default
        },
    },
];

EditorPopperButtonsStyled.defaultProps = {
    disablePortal: false,
    placement: 'top',
    transition: true,
    modifiers: EditorPopperButtonsStyledModifiers(document.querySelector('#EditorPagesAreaStyled') || undefined),
};