import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDispatch, useSelector} from "react-redux";
import {eraseRequestList, incomingRequestsSelector, loadings, replaceRequestMinMaxLoadedPage} from "../../store/store";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    AssetRequestRequestorModel,
    AssetsRequestStatus,
    AssetsRequestType,
    MainAssetsRequestModel
} from "../../../../../newShared/GQLTypes";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {mainAssetsGetRequestsWithFilterPaginationAction} from "../../store/actions";
import {
    assetRequestTypeMapper,
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS,
    PATH_LOCAL_ASSETS_INCOMING_REQUESTS,
    PATH_LOCAL_ASSETS_INCOMING_REQUESTS_EXACT
} from "../../constants";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {resultToValues} from "../../../../../newShared/components/genericFilter/helpers";
import {getAssetsRequestStatusChip} from "../../helpers";
import {getAssetsRequestRequestorsAutocompleteApi} from "../../api";
import {useHistory, useLocation} from "react-router";


export const useAssetsIncomingRequestsPage = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const {t} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets.pathIncomingRequests'});

    const history = useHistory();
    const location = useLocation();

    const dispatch = useDispatch();
    const {requests, pageInfo, minMaxLoadedPage} = useSelector(incomingRequestsSelector);
    const {incomingRequests: isLoadingList} = useSelector(loadings);
    const {minLoadedPage, maxLoadedPage} = minMaxLoadedPage;

    useSetBreadcrumbs([
        {
            title: tMenu('Asset Management'),
            path: PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS
        },
        {
            title: tMenu('Incoming requests'),
            path: PATH_LOCAL_ASSETS_INCOMING_REQUESTS
        },
    ]);

    const handleOpenRequest = (selected: MainAssetsRequestModel) => {
        history.push(`${PATH_LOCAL_ASSETS_INCOMING_REQUESTS_EXACT.replace(':id', selected.id)}${location.search}`)
    }

    //filters
    const requestDateConfig: keyConfig<string> = {
        type: 'date',
        key: 'requestDate',
        name: t('Request date'),
        default: true
    }
    const statusConfig: keyConfig<AssetsRequestStatus> = {
        type: 'filter',
        key: 'status',
        name: t('Status'),
        options: [AssetsRequestStatus.Created, AssetsRequestStatus.Approved, AssetsRequestStatus.Rejected],
        OptionRenderer: (a) => getAssetsRequestStatusChip(a, true),
        // getOptionLabel: (a) => a,
        default: true,
    }

    const requestTypeConfig: keyConfig<AssetsRequestType> = {
        type: 'filter',
        key: 'type',
        name: t('Request type'),
        options: Object.values(AssetsRequestType),
        getOptionLabel: (a) => assetRequestTypeMapper[a],
        default: true,
    }


    const requestorConfig: keyConfig<AssetRequestRequestorModel> = {
        type: 'filter',
        key: 'requestorId',
        name: t('Requestor'),
        fetchOptions: resultToValues<AssetRequestRequestorModel>(getAssetsRequestRequestorsAutocompleteApi),
        getOptionLabel: (a) => a.fullName,
        getFilterValue: (a) => a.publicId,
        isOptionEqual: (a, b) => a.publicId === b.publicId,
        isOptionEqualToSearch: (a, search) => a.fullName.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: true,
    }

    return {
        requests,
        handleOpenRequest,

        genericTable: {
            paging: {
                pageInfo,
                isLoading: isLoadingList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceRequestMinMaxLoadedPage(data)),
                clearRows: () => dispatch(eraseRequestList()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, requestIdLike: string) => {
                    dispatch(mainAssetsGetRequestsWithFilterPaginationAction({data: {pageRequest: {page, count}, filter: {...filters, requestIdLike}}}));
                },
            },
            filters: {
                configs: [requestTypeConfig, requestorConfig, requestDateConfig, statusConfig]
            }
        }

    }
}
