import {useDispatch, useSelector} from "react-redux";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useHistory} from "react-router";
import {
    assignmentsSelector,
    eraseAssignments,
    loadings,
    replaceAssignmentsMinMaxLoadedPage,
    setCreateAssignmentAction,
} from "../../store/slice";
import {
    defaultPageInfo,
    PATH_TRAININGS_ASSIGNMENT_EXACT,
    PATH_TRAININGS_ASSIGNMENTS,
    TRAININGS_PATH
} from "../../constants";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {
    IdNameModel,
    MainTrainingShortAssignmentModel,
    TrainingAssignmentStatus,
    TrainingCollaboratorAutocompleteModel,
} from "../../../../../newShared/GQLTypes";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {getTrainingAssignmentStatusChip} from "../../helpers";
import {mainTrainingsGetAssignmentsWithFilterPaginationAction,} from "../../store/actions";
import {
    mainTrainingGetAssignmentExamsAutocompleteApi,
    mainTrainingGetAssignmentRecipientsAutocompleteApi,
    mainTrainingGetAssignmentRequestorsAutocompleteApi,
    mainTrainingGetAssignmentTrainingsAutocompleteApi
} from "../../api";

export const useAssignmentsListPage = () => {
    //root
    const {t, tMenu} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.pathAssignments'});
    const dispatch = useDispatch();
    const history = useHistory();



    //selectors
    const {assignments, pageInfo, minMaxLoadedPage} = useSelector(assignmentsSelector);
    const {assignmentsList: isLoadingList} = useSelector(loadings);
    const {minLoadedPage, maxLoadedPage} = minMaxLoadedPage;


    //useEffects
    useSetBreadcrumbs([
        {
            title: tMenu('Training management'),
            path: TRAININGS_PATH
        },
        {
            title: tMenu('Assignments'),
            path: PATH_TRAININGS_ASSIGNMENTS
        }
    ]);



    //actions
    const handleOpenExact = (selected: MainTrainingShortAssignmentModel) => {
        history.push(PATH_TRAININGS_ASSIGNMENT_EXACT.replace(':assignmentId', selected.id));
    }

    const handleOpenCreate = () => {
        dispatch(setCreateAssignmentAction({isOpen: true}));
    }



    //filter
    const requestorConfig: keyConfig<TrainingCollaboratorAutocompleteModel> = {
        type: "filter",
        key: "requestor",
        name: t("Requestor"),
        fetchOptions: (workspaceId, {page, count, search}) => mainTrainingGetAssignmentRequestorsAutocompleteApi({workspaceId, pageRequest: {page, count}, search})
            .then(({result, pageInfo}) => ({values: result, pageInfo})),
        getOptionLabel: (a) => a.fullName,
        getFilterValue: (a) => a.collaboratorId,
        isOptionEqual: (a, b) => a.collaboratorId === b.collaboratorId,
        isOptionEqualToSearch: (a, search) =>
            a.fullName.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: false,
    };

    const recipientConfig: keyConfig<TrainingCollaboratorAutocompleteModel> = {
        type: "filter",
        key: "recipient",
        name: t("Recipient"),
        fetchOptions: (workspaceId, {page, count, search}) => mainTrainingGetAssignmentRecipientsAutocompleteApi({workspaceId, pageRequest: {page, count}, search})
            .then(({result, pageInfo}) => ({values: result, pageInfo})),
        getOptionLabel: (a) => a.fullName,
        getFilterValue: (a) => a.collaboratorId,
        isOptionEqual: (a, b) => a.collaboratorId === b.collaboratorId,
        isOptionEqualToSearch: (a, search) =>
            a.fullName.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: false,
    };

    const trainingsConfig: keyConfig<IdNameModel> = {
        type: 'filter',
        key: 'trainingName',
        name: t('Training'),
        fetchOptions: (workspaceId, {page, count, search}) => {
            return mainTrainingGetAssignmentTrainingsAutocompleteApi({ workspaceId, pageRequest: {page, count}, search})
                .then(({result, pageInfo}) => ({values: result, pageInfo}))
        },
        getOptionLabel: (a) => a.name,
        getFilterValue: (a) => a.name,
        isOptionEqual: (a, b) => a.name === b.name,
        isOptionEqualToSearch: (a, search) => a.name.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: false,
    }

    const examsConfig: keyConfig<IdNameModel> = {
        type: 'filter',
        key: 'examName',
        name: t('Exam'),
        fetchOptions: (workspaceId, {page, count, search}) => {
            return mainTrainingGetAssignmentExamsAutocompleteApi({ workspaceId, pageRequest: {page, count}, search})
                .then(({result, pageInfo}) => ({values: result, pageInfo}))
        },
        getOptionLabel: (a) => a.name,
        getFilterValue: (a) => a.name,
        isOptionEqual: (a, b) => a.name === b.name,
        isOptionEqualToSearch: (a, search) => a.name.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: false,
    }


    const statusConfig: keyConfig<TrainingAssignmentStatus> = {
        type: 'filter',
        key: 'status',
        name: t('Status'),
        options: Object.values(TrainingAssignmentStatus),
        OptionRenderer: (a) => getTrainingAssignmentStatusChip(a, true),
        default: true,
    }

    const typeConfig: keyConfig<string> = {
        type: 'filter',
        key: 'type',
        name: t('Type'),
        options: ['Training', 'Exam', 'Mixed'],
        // getOptionLabel: (a) => a,
        getFilterValue: (a) => a.toUpperCase(),
        isOptionEqualToSearch: (a, search) => a.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: true,
    }


    const createdDateConfig: keyConfig<string> = {
        type: 'date',
        key: 'startDate',
        name: t('Start date'),
        default: true
    }

    const dueDateConfig: keyConfig<string> = {
        type: 'date',
        key: 'dueDate',
        name: t('Due date'),
        default: true
    }

    return{
        assignments,
        isLoading: isLoadingList,
        handleOpenExact,
        handleOpenCreate,

        genericTable: {
            paging: {
                pageInfo,
                isLoading: isLoadingList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceAssignmentsMinMaxLoadedPage(data)),
                clearRows: () => dispatch(eraseAssignments()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, assignmentIdLike: string) => {
                    dispatch(mainTrainingsGetAssignmentsWithFilterPaginationAction({data: {pageRequest: {page, count: count || defaultPageInfo.count}, filter: {...filters, assignmentIdLike}}}));
                },
            },
            filters: {
                configs: [
                    typeConfig,
                    statusConfig,
                    createdDateConfig,
                    dueDateConfig,
                    requestorConfig,
                    recipientConfig,
                    trainingsConfig,
                    examsConfig,
                ]
            }
        }

    }
}


