import {FC} from "react";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import {IconButton} from "@mui/material";
import {usePageControl} from "./hooks";
import {TToolBarHandlerAction, TToolBarHandlerPayload} from "../../types";
import {withShrinkZero} from "../../../hoc/withShrinkZero";
import {EditorTooltip} from "../../../commonComponents/editorTooltip";

export interface PageControlProps {
    initialPageOpen: boolean;
    isDisabled: boolean;
    changePageControlStatusHandler?: (action: TToolBarHandlerAction, payload: TToolBarHandlerPayload) => void;
}

const PageControl: FC<PageControlProps> = ({initialPageOpen, changePageControlStatusHandler, isDisabled}) => {
    const {isPagesOpen, togglePageBar} = usePageControl({initialPageOpen, changePageControlStatusHandler });

    return <EditorTooltip title="Pages">
        <span>
            <IconButton onClick={togglePageBar} disabled={isDisabled}>
            <LibraryBooksIcon {...(isPagesOpen ? {style: {color: 'blue'}} : {style: {color: 'grey'}})} />
        </IconButton>
        </span>
    </EditorTooltip>;
}

const wrapper = withShrinkZero<PageControlProps>(PageControl);

export {wrapper as PageControl};

