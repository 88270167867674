import colors from "../../../../../../../../newShared/theme/colors";
import {Flex} from "../../../../../../../../newShared/components/Layouts";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {Typography} from "@mui/material";
import {ReactNode} from "react";

export const SectionButton = ({title, onClick, width, icon}:{title: string | ReactNode, onClick: () => void, width?: string, icon?: ReactNode}) => {
    return(
        <Flex
            cursor={'pointer'}
            onClick={onClick}
            w={width ?? '50%'}
            h={'114px'}
            ai={'center'}
            jc={'center'}
            br={'5px'}
            border={`1px dashed ${colors.text.blue}`}
            direction={'column'}
            gap={'10px'}
            talign={'center'}
        >
            <Flex p={'8px'} br={'10px'} background={colors.backgrounds.grey_light}>
                {icon ?
                    <>{icon}</>
                    :
                    <AddCircleIcon sx={{color: colors.text.grey_dark}}/>
                }
            </Flex>
            {typeof title === 'string' ?
                <Typography variant={'*bodyText2'} color={colors.text.grey_dark}>{title}</Typography>
                :
                <>{title}</>
            }
        </Flex>
    )
}