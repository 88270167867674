import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideAppInDevDialog} from "../../../store/slice";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const AppInDevDialog = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector(dialogs).appInDev;
    const {t} = useMainTranslation('', {keyPrefix: 'pathIntegrations.AddedAppsList'});

    const handleClose = () => {
        dispatch(hideAppInDevDialog());
    }

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Technical maintenance')}</DialogTitle>
            <DialogContent>
                <Typography>{('Integration is being renovated. Will be back shortly.')}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}