import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {dialogs, employees, hideAddNewApp, loadings, vendors, vendorSettings} from "../../../store/slice";
import {useEffect, useState} from "react";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {createAppByVendorFormType, createNewAppFormType, Vendor} from "../../../types";
import {PATH_ACCESS_MANAGEMENT_APPS_EXACT} from "../../../constants";
import {
    CreateAccessManagementAppByVendor,
    CreateNewAccessManagementApp,
    GetDataForCreateApp
} from "../../../store/actions";
import {TCollaborator} from "../../../../../../newShared/components/AutocompleteCollaborators/types";

export type addAppTabs  = 'addNew' | 'selectVendor';

export const useAddAppDialog = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const {addNewApp} = useSelector(dialogs);
    const {create, vendorsAndEmployeesForCreate} = useSelector(loadings);
    const _vendors = useSelector(vendors);
    const _vendorSettings = useSelector(vendorSettings);
    const _empls = useSelector(employees);

    const handleClose = () => {
        dispatch(hideAddNewApp());
        handleCleanUp();
    }

    const handleCleanUp = () => {
        formCreateNew.setForm(createNewInitForm);
        formCreateByVendor.setForm(createByVendorInitForm);
    }

    const handleSubmit = () => {
        if(tab === 'addNew'){
            if(isOkCreateNew()){
                dispatch(CreateNewAccessManagementApp({data: {app: formCreateNew.form, onSuccess}}));
            }
        }
        if(tab === 'selectVendor'){
            if(isOkCreateByVendor()){
                dispatch(CreateAccessManagementAppByVendor({data: {app: formCreateByVendor.form, onSuccess}}));
            }
        }
    }

    const onSuccess = (id: string) => {
        history.push(PATH_ACCESS_MANAGEMENT_APPS_EXACT.replace(':id', id));
        handleCleanUp();
    }

    //useEffect
    useEffect(() => {
        if(addNewApp && (!_vendors.length || !_empls.length || !_vendorSettings)){
            dispatch(GetDataForCreateApp());
        }
        //eslint-disable-next-line
    }, [addNewApp]);

    //tabs
    const [tab, setTab] = useState<addAppTabs>('addNew');

    //form
    const createNewInitForm: createNewAppFormType = {
        name: '',
        owner: null, //from employees
        providedService: null, //from vendor
        riskLevel: null, //from vendor
        type: null //from vendor
    };

    const createByVendorInitForm: createAppByVendorFormType = {
        name: '',
        vendor: null
    };

    //create new
    const formCreateNew = useForm<createNewAppFormType>(createNewInitForm);

    const handleSetOwner = (employee: TCollaborator | null) => {
        formCreateNew.setForm({...formCreateNew.form, owner: employee});
    }

    const handleSetProvidedService = (providedService: string) => {
        formCreateNew.setForm({...formCreateNew.form, providedService});
    }
    const handleSetRiskLevel = (riskLevel: string) => {
        formCreateNew.setForm({...formCreateNew.form, riskLevel});
    }

    const handleSetType = (type: string) => {
        formCreateNew.setForm({...formCreateNew.form, type});
    }

    //create by vendor
    const formCreateByVendor = useForm<createAppByVendorFormType>(createByVendorInitForm);

    const handleSetVendor = (vendor: Vendor) => {
        formCreateByVendor.setForm({...formCreateByVendor.form, vendor});
    }

    //checks
    const isOkCreateNew = () => {
        return formCreateNew.form.name.trim().length > 0 &&
            formCreateNew.form.owner !== null &&
            formCreateNew.form.providedService !== null &&
            formCreateNew.form.riskLevel !== null &&
            formCreateNew.form.type !== null
    }

    const isOkCreateByVendor = () => {
        return formCreateByVendor.form.name.trim().length > 0 &&
            formCreateByVendor.form.vendor !== null
    }

    return{
        //dialog
        isOpen: addNewApp,
        isLoading: create,
        isLoadingInitData: vendorsAndEmployeesForCreate,
        handleClose,
        handleSubmit,
        //other
        tab,
        setTab,
        //
        createNew: {
            form: formCreateNew.form,
            handleChange: formCreateNew.handleChange,
            handleSetOwner,
            handleSetProvidedService,
            handleSetRiskLevel,
            handleSetType,
            employees: _empls,
            vendorsSettings: _vendorSettings
        },
        createByVendor: {
            form: formCreateByVendor.form,
            handleChange: formCreateByVendor.handleChange,
            handleSetVendor,
            vendors: _vendors,
        },
        isOk: tab === 'selectVendor' ? isOkCreateByVendor() : isOkCreateNew(),
    }
}