import {createAsyncThunk} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {getCollectorHistoryApi, getShortCollectorsApi} from "../api";
import {getActionsData} from "../../../../newShared/redux";
import {TWithOptions} from "../../../../newShared/types";

export const getShortCollectorsAction = createAsyncThunk(
    'Collector/getShortCollectors',
    async (data: TWithOptions<{page: number, count: number}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await getShortCollectorsApi({data: data.data, workspaceId}, data.signal)
        return {
            data: data.data,
            resp
        };
    }
);

export const getCollectorHistoryAction = createAsyncThunk(
    'Collector/getCollectorHistory',
    async (data: TWithOptions<{page: number, count: number, collectorId: string}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await getCollectorHistoryApi({id: data.data.collectorId, data: {page: data.data.page, count: data.data.count}, workspaceId}, data.signal)
        return {
            data: data.data,
            resp,
        };
    }
);
//startCollector

// export const StartCollector = createAsyncThunk(
//     'Collector/startCollector',
//     async (collectorId: string, {getState, dispatch}): Promise<string> => {
//         const state = getState() as AppState;
//         const {workspaceId} = getActionsData(state);
//         const message = await startCollector(workspaceId || '', collectorId);
//         dispatch(addSuccessfulSnack(message));
//          return collectorId;
//     }
// );
//
//
// export const DeleteCollector = createAsyncThunk(
//     'Collector/deleteCollector',
//     async (collectorId: string, {getState, dispatch}): Promise<string> => {
//         const state = getState() as AppState;
//         const {workspaceId} = getActionsData(state);
//         const message = await deleteCollector(workspaceId || '', collectorId);
//         dispatch(addSuccessfulSnack(message));
//         return collectorId;
//     }
// );
//
//
// ////////
//
// export const GetCollectorsWithFilter = createAsyncThunk(
//     'Collector/GetCollectorsWithFilter',
//     async (data: { page: number, count: number, name: string, type: GET_CONTROLS_EVIDENCES_REQUEST_TYPES}, {getState, dispatch}): Promise<{collectors: ShortCollectorDialogType[], pageInfo: TPageInfo}> => {
//         const state = getState() as AppState;
//         const {workspaceId} = getActionsData(state);
//         switch(data.type){
//             case "ALL": return await getAllCollectorsDialogData(workspaceId || '', data.page, data.count, data.name);
//             case "REGULAIT": return await getRegulaitCollectorsDialogData(workspaceId || '', data.page, data.count, data.name);
//             case "PRIVATE": return await getPrivateCollectorsDialogData(workspaceId || '', data.page, data.count, data.name);
//             case "PUBLIC": return await getPublicCollectorsDialogData(workspaceId || '', data.page, data.count, data.name);
//             default: throw new Error('GetControlsWithFilter: Invalid type specified');
//         }
//     }
// );
//
//
// export const CreateWCMapper = createAsyncThunk(
//     'Collector/createWCMapper',
//     async (data: {mapper: WCMapper, collector: ShortCollectorType }, {getState, dispatch}): Promise<ShortCollectorType> => {
//         const state = getState() as AppState;
//         const {workspaceId} = getActionsData(state);
//         const id = await createWCMapper(workspaceId || '', data.mapper);
//         dispatch(addSuccessfulSnack('Collector added!'));
//         return {...data.collector, assignId: id};
//     }
// );
//
// export const GetEvidences = createAsyncThunk(
//     'Collector/GetEvidences',
//     async (_, {getState, dispatch}) => {
//         const state = getState() as AppState;
//         const {workspaceId} = getActionsData(state);
//         return await getAutomationEvidences({workspaceId});
//     }
// )
//
// export const GetInstalledApps = createAsyncThunk(
//     'Collector/GetInstalledApps',
//     async (_, {getState}) => {
//         const state = getState() as AppState;
//         const {workspaceId} = getActionsData(state);
//         const response = await getIntegrations(workspaceId || '');
//         return response;
//     }
// );
//
//
// export const ToggleWCMapperActivity = createAsyncThunk(
//     'Collector/toggleWCMapperActivity',
//     async (data: {assignId: string, newStatus: boolean}, {getState, dispatch}) => {
//         const state = getState() as AppState;
//         const {workspaceId} = getActionsData(state);
//         const message = await toggleWCMapperActivity(workspaceId || '', data.assignId, data.newStatus);
//         dispatch(addSuccessfulSnack(message));
//         return data;
//     }
// );
//
// export const UpdateWCMapper = createAsyncThunk(
//     'Collector/updateWCMapper',
//     async (data: {mapper: WCMapper, collector: ShortCollectorType }, {getState, dispatch}): Promise<ShortCollectorType> => {
//         const state = getState() as AppState;
//         const {workspaceId} = getActionsData(state);
//         const message = await updateWCMapper(workspaceId || '', data.mapper);
//         dispatch(addSuccessfulSnack(message));
//         return data.collector;
//     }
// );
// //updateWCMapper