import {Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useEditAppDialog} from "../../../hooks/dialogHooks/useEditAppDialog";
import {Vendor} from "../../../types";
import {DialogContentWrapper, TextFieldCustom} from "../../styled";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";

export const EditAppDialog = () => {
    const {
        isOpen,
        handleClose,
        vendors,
        form,
        handleChange,
        handleSetVendor,
        isOk,
        handleSubmit,
        app,
        isLoadingVendors,
        isLoadingEditNameAndVendor
    } = useEditAppDialog();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.AccessManagementAppsExact.dialogs'});

    return(
        <Dialog open={isOpen} onClose={handleClose}>

            <DialogTitle>{t(`Edit {{name}} application`, {name: app?.name})}</DialogTitle>

            <DialogContent>
                <DialogContentWrapper margin={'8px 0 0 0'}>
                    <Autocomplete
                        id="combo-box-demo"
                        options={vendors.filter(e => e.owner !== null)}
                        getOptionLabel={(option) => option.name}
                        loading={isLoadingVendors}
                        value={form.vendor}
                        onChange={(e, newValue: Vendor | null) => newValue && handleSetVendor(newValue)}
                        renderInput={(params) => <TextFieldCustom
                            {...params}
                            label={t("Select vendor")}
                            size={'small'}
                            required
                            error={form.vendor === null}
                        />}
                    />

                    <TextFieldCustom
                        required
                        value={form.name}
                        name={'name'}
                        onChange={handleChange}
                        placeholder={t('Name')}
                        label={t('Application name')}
                        size={'small'}
                        sx={{margin: '8px 0 0 0'}}
                    />
                </DialogContentWrapper>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    variant={'contained'}
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoadingEditNameAndVendor}
                    onClick={handleSubmit}
                    disabled={!isOk}
                >
                    {t('Save')}
                </LoadingButton>

                {/*<Button*/}
                {/*    variant={'contained'}*/}
                {/*    size={isMobile ? 'small' : 'medium'}*/}
                {/*    onClick={handleSubmit}*/}
                {/*    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}*/}
                {/*    disabled={!isOk}*/}
                {/*>{('Save')}</Button>*/}
            </DialogActions>
        </Dialog>
    )
}