import {createAsyncThunk} from "@reduxjs/toolkit";
import {TPageInfo, TWithOptions} from "../../../../newShared/types";
import {
    AutocompleteHrManagersQueryVariables,
    GetCsvFieldsEmployeesMutation,
    GetCsvFieldsEmployeesMutationVariables,
    GetShortDocumentsByWorkEmailWithFilterPagingQueryVariables,
    MainHrApproveRequestMutation,
    MainHrApproveRequestMutationVariables,
    MainHrApproveTimeOffRequestMutation,
    MainHrApproveTimeOffRequestMutationVariables,
    MainHrDeleteRequestMutation,
    MainHrDeleteRequestMutationVariables,
    MainHrGetAndChangeStepStatusTimeOffRequestQuery,
    MainHrGetAndChangeStepStatusTimeOffRequestQueryVariables,
    MainHrGetIssueByIdQuery,
    MainHrGetIssueByIdQueryVariables,
    MainHrGetIssuesListQuery,
    MainHrGetIssuesListQueryVariables,
    MainHrGetRequestByIdQuery,
    MainHrGetRequestByIdQueryVariables,
    MainHrGetRequestRequestorsAutocompleteQuery,
    MainHrGetRequestRequestorsAutocompleteQueryVariables,
    MainHrGetRequestsWithFilterPaginationQuery,
    MainHrGetRequestsWithFilterPaginationQueryVariables,
    MainHrGetTimeOffRequestsWithFilterPaginationQuery,
    MainHrGetTimeOffRequestsWithFilterPaginationQueryVariables,
    MainHrRejectRequestMutation,
    MainHrRejectRequestMutationVariables,
    MainHrRejectTimeOffRequestMutation,
    MainHrRejectTimeOffRequestMutationVariables,
    MainHrSetIssueStatusInProgressMutation,
    MainHrSetIssueStatusInProgressMutationVariables,
    MainHrSetIssueStatusResolvedMutation,
    MainHrSetIssueStatusResolvedMutationVariables,
    MainHrSkipTimeOffRequestMutation,
    MainHrSkipTimeOffRequestMutationVariables,
    PaginationInput,
    PerformCsvEmployeesMutation,
    PerformCsvEmployeesMutationVariables,
    QueryExportCsvEmployeesArgs,
    ReleaseEmployeesMutationVariables,
    UpdateEmployeesTreeDto
} from "../../../../newShared/GQLTypes";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {getActionsData} from "../../../../newShared/redux";
import {
    addJobRecord,
    autocompleteHrManagers,
    createHrEmployee,
    createHrEmployeeSetting,
    createHrSettingsJobTitle,
    deleteHrEmployeeById,
    deleteHrEmployeeSetting,
    deleteJobRecord,
    deleteSettingsJobTitle,
    editHrSettingsJobTitle,
    exportCsvEmployees,
    getEmployees,
    getEmployeesAvailableFieldsForExport,
    getEmployeesTree,
    getHrEmployeeById,
    getHrEmployeeJobInfosById,
    getHrEmployeesSettings,
    getImportCsvHrManagementTemplate,
    getShortDocumentsByWorkEmailWithFilterPagingApi,
    releaseEmployeesApi,
    updateEmployeeHireDate,
    updateEmployeeStatus,
    updateEmployeesTree,
    updateHrEmployee,
    updateHrEmployeeSetting,
    updateJobRecord
} from "../api";
import {addErrorSnack, addSuccessfulSnack} from "../../../barsEnvironment/snack/store/slice";
import {
    createSettingFormType,
    EmployeeType,
    TEmployeeJobHistory,
    TEmployeeStatus,
    THrSettings,
    THrSettingsJobTitle
} from "../types";
import {eraseSelectedEmployeeJobHistory} from "./slice";
import {saveAs} from "file-saver";
import {b64toBlob} from "../../../../newShared/utils/base64/base64";
import {createCommonAsyncThunk} from "../../../../newShared/utils/asyncThunk";
import {
    getCsvFieldsEmployees,
    mainHrApproveRequest,
    mainHrApproveTimeOffRequest,
    mainHrDeleteRequest,
    mainHrGetAndChangeStepStatusTimeOffRequest,
    mainHrGetIssueById,
    mainHrGetIssuesList,
    mainHrGetRequestById,
    mainHrGetRequestRequestorsAutocomplete,
    mainHrGetRequestsWithFilterPagination,
    mainHrGetTimeOffRequestsWithFilterPagination,
    mainHrRejectRequest,
    mainHrRejectTimeOffRequest,
    mainHrSetIssueStatusInProgress,
    mainHrSetIssueStatusResolved,
    mainHrSkipTimeOffRequest,
    performCsvEmployees
} from "../api/query";

import moment from "moment/moment";

export { };

//getEmployees

export const GetEmployees = createAsyncThunk(
    'EmployeesRefactored/getEmployees',
    async ({data, signal}: TWithOptions<{pageInfo: PaginationInput, search: string}>, {getState, dispatch}) => {
        // if(data.isNewDirection) dispatch(eraseEmployee());
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const res = await getEmployees({workspaceId, pageInfo: data.pageInfo, search: data.search}, signal);
        return{
            res,
        }
    }
);

//getHrEmployeeById
export const GetHrEmployeeById = createAsyncThunk(
    'EmployeesRefactored/getHrEmployeeById',
    async ({data, signal}: TWithOptions<{id: string, onReject: () => void}>, {getState, dispatch}) => {
        // if(data.isNewDirection) dispatch(eraseEmployee());
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        try{
            return await getHrEmployeeById({workspaceId, id: data.id}, signal);
        }catch (ex: any){
            dispatch(addErrorSnack(ex.message));
            data.onReject();
            throw new Error(ex.message);
        }
    }
);

//getHrEmployeesSettings
export const GetHrEmployeesSettings = createAsyncThunk(
    'EmployeesRefactored/getHrEmployeesSettings',
    async ({data, signal}: TWithOptions<{}>, {getState, dispatch}) => {
        // if(data.isNewDirection) dispatch(eraseEmployee());
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getHrEmployeesSettings({workspaceId}, signal);
    }
);

//createHrEmployeeSetting
export const CreateHrEmployeeSetting = createAsyncThunk(
    'EmployeesRefactored/createHrEmployeeSetting',
    async ({data, signal}: TWithOptions<{field: keyof THrSettings, value: string, onSuccess?: () => void}>, {getState, dispatch}): Promise<{field: keyof THrSettings, value: string}> => {
        // if(data.isNewDirection) dispatch(eraseEmployee());
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await createHrEmployeeSetting({workspaceId, field: data.field, value: data.value}, signal);
        data.onSuccess && data.onSuccess();
        return{
            field: data.field,
            value: data.value
        }
    }
);

export const UpdateHrEmployeeSetting = createAsyncThunk(
    'EmployeesRefactored/updateHrEmployeeSetting',
    async ({data, signal}: TWithOptions<{field: keyof THrSettings, value: string, newValue: string, updateHistory: boolean, onSuccess?: () => void}>, {getState, dispatch}): Promise<{field: keyof THrSettings, value: string, newValue: string}> => {
        // if(data.isNewDirection) dispatch(eraseEmployee());
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await updateHrEmployeeSetting({workspaceId, field: data.field, value: data.value, newValue: data.newValue, updateHistory: data.updateHistory}, signal);
        data.onSuccess && data.onSuccess();
        return{
            field: data.field,
            value: data.value,
            newValue: data.newValue,
        }
    }
);
//deleteHrEmployeeSetting
export const DeleteHrEmployeeSetting = createAsyncThunk(
    'EmployeesRefactored/DeleteHrEmployeeSetting',
    async ({data, signal}: TWithOptions<{field: keyof THrSettings, value: string, newValue: string, updateHistory: boolean}>, {getState, dispatch}): Promise<{field: keyof THrSettings, value: string, newValue: string}> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await deleteHrEmployeeSetting({workspaceId, field: data.field, value: data.value, newValue: data.newValue, updateHistory: data.updateHistory}, signal);

        return {
            field: data.field,
            value: data.value,
            newValue: data.newValue
        };
    }
);

//createHrEmployee
export const CreateHrEmployee = createAsyncThunk(
    'EmployeesRefactored/createHrEmployee',
    async ({data, signal}: TWithOptions<{employee: EmployeeType, onSuccess?: (id: string) => void}>, {getState, dispatch}): Promise<EmployeeType> => {
        // if(data.isNewDirection) dispatch(eraseEmployee());
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const id = await createHrEmployee({workspaceId, employee: {...data.employee, collaborator: undefined}}, signal);
        data.onSuccess && data.onSuccess(id);
        return {...data.employee, workspaceId, id};
    }
);

//getHrEmployeeJobInfosById
export const GetHrEmployeeJobInfosById = createAsyncThunk(
    'EmployeesRefactored/getHrEmployeeJobInfosById',
    async ({data, signal}: TWithOptions<{id: string, pageInfo: PaginationInput}>, {getState, dispatch}): Promise<{id: string, pageInfo: TPageInfo, jobInfos: TEmployeeJobHistory[]}> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const jobInfos = await getHrEmployeeJobInfosById({workspaceId, id: data.id, pageInfo: data.pageInfo}, signal);
        return {id: data.id, ...jobInfos};
    }
);

//updateEmployeeHireDate
export const UpdateEmployeeHireDate = createAsyncThunk(
    'EmployeesRefactored/updateEmployeeHireDate',
    async ({data, signal}: TWithOptions<{id: string, hireDate: string}>, {getState, dispatch}): Promise<{id: string, hireDate: string}> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await updateEmployeeHireDate({workspaceId, id: data.id, hireDate: data.hireDate}, signal);
        return {id: data.id, hireDate: data.hireDate};
    }
);

//updateEmployeeStatus
export const UpdateEmployeeStatus = createAsyncThunk(
    'EmployeesRefactored/updateEmployeeStatus',
    async ({data, signal}: TWithOptions<{id: string, status: TEmployeeStatus}>, {getState, dispatch}): Promise<{id: string, status: TEmployeeStatus}> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await updateEmployeeStatus({workspaceId, id: data.id, status: data.status}, signal);
        return {id: data.id, status: data.status};
    }
);

//addJobRecord
export const AddJobRecord = createAsyncThunk(
    'EmployeesRefactored/addJobRecord',
    async ({data, signal}: TWithOptions<{id: string, jobRecord: TEmployeeJobHistory}>, {getState, dispatch}): Promise<void> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await addJobRecord({workspaceId, id: data.id, jobRecord: data.jobRecord}, signal);
        dispatch(eraseSelectedEmployeeJobHistory());
        //todo check with backend!!!
        dispatch(GetHrEmployeeJobInfosById({
            data: {
                id: data.id,
                pageInfo: {
                    page: 0,
                    count: (state.EmployeesRefactor.employeeHistoryPageInfo.page + 1) * state.EmployeesRefactor.employeeHistoryPageInfo.count //fetching all fetched before history
                }
            }
        }))
    }
);

//updateJobRecord
export const UpdateJobRecord = createAsyncThunk(
    'EmployeesRefactored/updateJobRecord',
    async ({data, signal}: TWithOptions<{id: string, jobRecord: TEmployeeJobHistory}>, {getState, dispatch}): Promise<void> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await updateJobRecord({workspaceId, id: data.id, jobRecord: data.jobRecord}, signal);
        dispatch(eraseSelectedEmployeeJobHistory());
        //todo check with backend!!! (the fetch of all histories visible in list)
        dispatch(GetHrEmployeeJobInfosById({
            data: {
                id: data.id,
                pageInfo: {
                    page: 0,
                    count: (state.EmployeesRefactor.employeeHistoryPageInfo.page + 1) * state.EmployeesRefactor.employeeHistoryPageInfo.count //fetching all fetched before history
                }
            }
        }))
    }
);

//deleteJobRecord
export const DeleteJobRecord = createAsyncThunk(
    'EmployeesRefactored/deleteJobRecord',
    async ({data, signal}: TWithOptions<{id: string, jobRecordId: string}>, {getState, dispatch}): Promise<{id: string, jobRecordId: string}> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await deleteJobRecord({workspaceId, id: data.id, jobRecordId: data.jobRecordId}, signal);

        dispatch(GetHrEmployeeJobInfosById({
            data: {
                id: data.id,
                pageInfo: {
                    page: 0,
                    count: (state.EmployeesRefactor.employeeHistoryPageInfo.page + 1) * state.EmployeesRefactor.employeeHistoryPageInfo.count //fetching all fetched before history
                }
            }
        }))
        return data;
    }
);

//deleteHrEmployeeById
export const DeleteHrEmployeeById = createAsyncThunk(
    'EmployeesRefactored/deleteHrEmployeeById',
    async ({data, signal}: TWithOptions<{id: string, onSuccess: () => void}>, {getState, dispatch}): Promise<string> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await deleteHrEmployeeById({workspaceId, id: data.id}, signal);
        data.onSuccess();
        return data.id;
    }
);

//releaseEmployeesApi
export const ReleaseEmployees = createAsyncThunk(
    'EmployeesRefactored/Release',
    async (data: { data: ReleaseEmployeesMutationVariables, onSuccess: () => void }, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await releaseEmployeesApi({...data.data, workspaceId, organizationId});
        data.onSuccess();
        return {
            data,
            resp
        }
    }
)

//updateHrEmployee
export const UpdateHrEmployee = createAsyncThunk(
    'EmployeesRefactored/updateHrEmployee',
    async ({data, signal}: TWithOptions<{employee: EmployeeType, onSuccess?: () => void}>, {getState, dispatch}): Promise<EmployeeType> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await updateHrEmployee({workspaceId, employee: {...data.employee, collaborator: undefined}}, signal);
        data.onSuccess && data.onSuccess();
        return data.employee;
    }
);

export const AutocompleteManagers = createAsyncThunk(
    'EmployeesRefactored/autocompleteHrManagers',
    async (data: AutocompleteHrManagersQueryVariables, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await autocompleteHrManagers({...data, workspaceId, organizationId})
        // console.log(resp);
        return {
            data,
            resp
        }
    }
)

//createHrSettingsJobTitle
export const CreateHrSettingsJobTitle = createAsyncThunk(
    'EmployeesRefactored/createHrSettingsJobTitle',
    async (data: {jobTitle: createSettingFormType, onSuccess?: (jobTitle: THrSettingsJobTitle) => void}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await createHrSettingsJobTitle({...data, workspaceId});
        data.onSuccess && data.onSuccess(resp);
        return {
            resp
        }
    }
)

//editHrSettingsJobTitle
export const EditHrSettingsJobTitle = createAsyncThunk(
    'EmployeesRefactored/editHrSettingsJobTitle',
    async (data: {jobTitle: THrSettingsJobTitle, history: boolean}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await editHrSettingsJobTitle({...data, workspaceId});
        return resp;
    }
)

//deleteSettingsJobTitle
export const DeleteSettingsJobTitle = createAsyncThunk(
    'EmployeesRefactored/deleteSettingsJobTitle',
    async (data: {jobTitle: THrSettingsJobTitle, history: boolean, replaceJobTitleId: string}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        await deleteSettingsJobTitle({...data, workspaceId});
        return data;
    }
)

export const getEmployeesTreeAction = createAsyncThunk(
    'Employees/getEmployeesTree',
    async (data: TWithOptions<{}>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await getEmployeesTree({...data.data, workspaceId, organizationId})
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    }
)

export const updateEmployeesTreeAction = createAsyncThunk(
    'Employees/updateEmployeesTree',
    async (data: TWithOptions<{ employees: UpdateEmployeesTreeDto[], deleted: string[] }>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await updateEmployeesTree({...data.data, workspaceId, organizationId})
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    }
)


//getImportCsvHrManagementTemplate
export const GetImportCsvHrManagementTemplate = createAsyncThunk(
    'employees/getImportCsvHrManagementTemplate',
    async (_, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await getImportCsvHrManagementTemplate({workspaceId}); //id to be sure user has at least 1 app
        resp && saveAs(b64toBlob(resp as string), 'employees-template.csv');
        return resp
    }
)

export const getCsvFieldsEmployeesAction =
    createCommonAsyncThunk<GetCsvFieldsEmployeesMutationVariables, GetCsvFieldsEmployeesMutation["getCsvFieldsEmployees"]>(
    'employees', 'getCsvFieldsEmployees', true, false, getCsvFieldsEmployees
)
export const performCsvEmployeesAction =
    createCommonAsyncThunk<PerformCsvEmployeesMutationVariables, PerformCsvEmployeesMutation["performCsvEmployees"]>(
    'employees', 'performCsvEmployees', true, false, performCsvEmployees,
)

// export const ImportCsvHrManagement = createAsyncThunk(
//     'employees/importCsvHrManagement',
//     async (data: {file: string, pageInfo: PaginationInput, search: string}, {getState, dispatch}) => {
//         const state = getState() as AppState;
//         const {workspaceId} = getActionsData(state);
//
//         const resp = await importCsvHrManagement({workspaceId, file: data.file});
//
//         dispatch(eraseEmployees());
//         dispatch(GetEmployees({data: {pageInfo: data.pageInfo, search: data.search}}));
//
//         return {
//             data,
//             resp
//         }
//     }
// )

//exportCsvAccessManagementApp
export const ExportCsvEmployees = createAsyncThunk(
    'employees/exportCsvEmployees',
    async (data: Omit<QueryExportCsvEmployeesArgs, 'workspaceId'>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const resp = await exportCsvEmployees({...data, workspaceId});

        if (data.isDownload) {
            const date = moment().format("yyyy-MM-DD-hh-mm-ss");
            saveAs(b64toBlob(resp as string), `employees_${date}.csv`);

        } else {
            dispatch(addSuccessfulSnack(`You will receive email with a link to download CSV`));
        }

        return resp;
    }
)

export const GetEmployeesAvailableFieldsForExport = createAsyncThunk(
    'employees/GetEmployeesAvailableFieldsForExport',
    async (_, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const {fields} = await getEmployeesAvailableFieldsForExport({workspaceId});
        return {
            fields,
        };
    }
);

export const GetShortDocumentsByWorkEmailWithFilterPaging = createAsyncThunk(
    'EmployeesRefactored/GetShortDocumentsByWorkEmailWithFilterPaging',
    async ({data, signal}: TWithOptions<GetShortDocumentsByWorkEmailWithFilterPagingQueryVariables>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const resp = await getShortDocumentsByWorkEmailWithFilterPagingApi({...data, workspaceId}, signal);
        return {
            resp,
            isNewOnTop: false,
        }
    }
);
export const mainHrGetRequestByIdAction =
    createCommonAsyncThunk<MainHrGetRequestByIdQueryVariables, MainHrGetRequestByIdQuery["mainHrGetRequestById"]>(
    'EmployeesRefactor', 'mainHrGetRequestById', true, false, mainHrGetRequestById
);
export const mainHrApproveRequestAction =
    createCommonAsyncThunk<MainHrApproveRequestMutationVariables, MainHrApproveRequestMutation['mainHrApproveRequest']>(
        'EmployeeRefactor', 'mainHrApproveRequest', true, false, mainHrApproveRequest
    );

export const mainHrRejectRequestAction =
    createCommonAsyncThunk<MainHrRejectRequestMutationVariables, MainHrRejectRequestMutation['mainHrRejectRequest']>(
        'EmployeeRefactor', 'mainHrRejectRequest', true, false, mainHrRejectRequest
    );
export const mainHrDeleteRequestAction =
    createCommonAsyncThunk<MainHrDeleteRequestMutationVariables, MainHrDeleteRequestMutation['mainHrDeleteRequest']>(
        'EmployeeRefactor', 'mainHrDeleteRequest', true, false, mainHrDeleteRequest
    );

export const getHrRequestsWithFilterPaginationAction =
    createCommonAsyncThunk<MainHrGetRequestsWithFilterPaginationQueryVariables, MainHrGetRequestsWithFilterPaginationQuery["mainHrGetRequestsWithFilterPagination"]>(
        'HR', 'mainHrGetRequestsWithFilterPagination', true, false, mainHrGetRequestsWithFilterPagination
    )

export const getHrRequestRequestorsAutocompleteAction =
    createCommonAsyncThunk<MainHrGetRequestRequestorsAutocompleteQueryVariables, MainHrGetRequestRequestorsAutocompleteQuery["mainHrGetRequestRequestorsAutocomplete"]>(
        'HR', 'mainHrGetRequestRequestorsAutocomplete', true, false, mainHrGetRequestRequestorsAutocomplete
    )

export const mainHrGetIssuesListAction =
    createCommonAsyncThunk<MainHrGetIssuesListQueryVariables, MainHrGetIssuesListQuery["mainHrGetIssuesList"]>(
        'HR', 'mainHrGetIssuesList', true, false, mainHrGetIssuesList
    )

export const mainHrGetIssueByIdAction =
    createCommonAsyncThunk<MainHrGetIssueByIdQueryVariables, MainHrGetIssueByIdQuery["mainHrGetIssueById"]>(
        'HR', 'mainHrGetIssueById', true, false, mainHrGetIssueById
    )

export const mainHrSetIssueStatusInProgressAction =
    createCommonAsyncThunk<MainHrSetIssueStatusInProgressMutationVariables, MainHrSetIssueStatusInProgressMutation["mainHrSetIssueStatusInProgress"]>(
        'HR', 'mainHrSetIssueStatusInProgress', true, false, mainHrSetIssueStatusInProgress
    )

//mainHrGetTimeOffRequestsWithFilterPaginationAction
export const mainHrGetTimeOffRequestsWithFilterPaginationAction =
    createCommonAsyncThunk<MainHrGetTimeOffRequestsWithFilterPaginationQueryVariables, MainHrGetTimeOffRequestsWithFilterPaginationQuery["mainHrGetTimeOffRequestsWithFilterPagination"]>(
        'HR', 'mainHrGetTimeOffRequestsWithFilterPagination', true, false, mainHrGetTimeOffRequestsWithFilterPagination
    )

export const mainHrGetAndChangeStepStatusTimeOffRequestAction =
    createCommonAsyncThunk<MainHrGetAndChangeStepStatusTimeOffRequestQueryVariables, MainHrGetAndChangeStepStatusTimeOffRequestQuery["mainHrGetAndChangeStepStatusTimeOffRequest"]>(
        'HR', 'mainHrGetAndChangeStepStatusTimeOffRequest', true, false, mainHrGetAndChangeStepStatusTimeOffRequest
    )

export const mainHrSetIssueStatusResolvedAction =
    createCommonAsyncThunk<MainHrSetIssueStatusResolvedMutationVariables, MainHrSetIssueStatusResolvedMutation["mainHrSetIssueStatusResolved"], {name: string, publicId: string}>(
        'HR', 'mainHrSetIssueStatusResolved', true, false, mainHrSetIssueStatusResolved
    )

export const mainHrApproveTimeOffRequestAction =
    createCommonAsyncThunk<MainHrApproveTimeOffRequestMutationVariables, MainHrApproveTimeOffRequestMutation["mainHrApproveTimeOffRequest"]>(
    "HR", "mainHrApproveTimeOffRequest", true, false, mainHrApproveTimeOffRequest
);

export const mainHrRejectTimeOffRequestAction =
    createCommonAsyncThunk<MainHrRejectTimeOffRequestMutationVariables, MainHrRejectTimeOffRequestMutation["mainHrRejectTimeOffRequest"]>(
    "HR", "mainHrRejectTimeOffRequest", true, false, mainHrRejectTimeOffRequest
);

export const mainHrSkipTimeOffRequestAction =
    createCommonAsyncThunk<MainHrSkipTimeOffRequestMutationVariables, MainHrSkipTimeOffRequestMutation['mainHrSkipTimeOffRequest']>(
        "HR", 'mainHrSkipTimeOffRequest', true, false, mainHrSkipTimeOffRequest
    );
