import {useDispatch, useSelector} from "react-redux";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {dialogs, hideReleaseRegistry, loadings, selectedRegistry} from "../../../store/slice";
import {releaseRiskRegistryAction} from "../../../store/actions";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TReleaseDialogEmployee} from "../../../../../../newShared/components/releaseDialog/types";


export const useReleaseRiskRegistry = () => {
    const dispatch = useDispatch();
    const {releaseRegistry} = useSelector(dialogs);
    const {registryRelease} = useSelector(loadings);
    const {setMessage} = useMessageDialog();
    const reg = useSelector(selectedRegistry);
    const {tCommon} = useMainTranslation();

    const handleClose = () => {
        dispatch(hideReleaseRegistry());
    }

    const handleRelease = ({frameworkId, controlId, evidenceId, readers, reviewers, signers}: {
        frameworkId: string, controlId: string, evidenceId: string,
        reviewers: TReleaseDialogEmployee[], readers: TReleaseDialogEmployee[], signers: TReleaseDialogEmployee[]
    }) => {
        reg && dispatch(
            releaseRiskRegistryAction({
                data: {
                    workspaceId: '',
                    organizationId: '',
                    registryId: reg.id,
                    frameworkId,
                    controlId,
                    evidenceId,
                    readers,
                    reviewers,
                    signers,
                },
                onSuccess
            })
        );
    }

    const onSuccess = () => {
        setMessage({title: tCommon('Completed successfully'), message: tCommon('Automation created!')});
    }

    return{
        isOpen: releaseRegistry,
        handleClose,
        isLoadingRelease: registryRelease,
        handleRelease,
    }
}