import {genericFiltersArrayPropsTypes, keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

type TResponse = {
    // applyFilter: genericFiltersArrayPropsTypes['fetchResultWithSelectedFilters'];
    configs: genericFiltersArrayPropsTypes['configs'];
}
export const useReportsFilter = (isArchived: boolean): TResponse => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathReports.Table'});

    // const {getGeneratedReports, getArchivedReports, generatedReports, archivedReports} = useReports();

    // const type: keyConfig<{id: string, title: string}> = {
    //     type: 'filter',
    //     key: 'type',
    //     name: t('Report type'),
    //     options: [
    //         {id: 'SNAPSHOT', title: t('Snapshot')},
    //         {id: 'LIVE_LITE', title: t('Live (lite view)')},
    //         {id: 'LIVE_FULL', title: t('Live (full view)')},
    //     ],
    //     isOptionEqualToSearch: (option, search) => option.title.trim().toLowerCase().includes(search.trim().toLowerCase()),
    //     isOptionEqual: (a, b) => a.id === b.id,
    //     getFilterValue: (a) => a.id,
    //     getOptionLabel: (e) => e.title,
    //     default: true,
    // }

    const status: keyConfig<{id: string, title: string}> = {
        type: 'filter',
        key: 'status',
        name: t('Report status'),
        options: [
            {id: 'IN_REVIEW', title: t('In review')},
            {id: 'RESPONSE', title: t('NCR Response')},
            {id: 'PUBLISHED', title: t('NCR Published')},
            {id: 'COMPLETED', title: t('Completed')},
            {id: 'REJECTED', title: t('Rejected')},
        ],
        isOptionEqualToSearch: (option, search) => option.title.trim().toLowerCase().includes(search.trim().toLowerCase()),
        isOptionEqual: (a, b) => a.id === b.id,
        getFilterValue: (a) => a.id,
        getOptionLabel: (e) => e.title,
        default: true,
    };
    //
    // const applyFilter: TResponse["applyFilter"] = (search, filters) => {
    //     if (isArchived) {
    //         getArchivedReports({page: 0, count: archivedReports.pageInfo.count, filters: {...filters, input: [search]}}, true);
    //     } else {
    //         getGeneratedReports({page: 0, count: generatedReports.pageInfo.count, filters: {...filters, input: [search]}}, true);
    //     }
    // }

    return {
        configs: [
            // type,
            status
        ],
        // applyFilter
    };
};