import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {FC, useEffect} from "react";
import {useParams} from "react-router-dom";
import {usePhysicalAssetsRequestsExact} from "../../../hooks/usePhysicalAssetsRequestsExact";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {AssetRequestPhysicalExact} from "../../../components/assetRequestPhysical";
import {useRejectAssetsRequest} from "../../../hooks/dialogs/useRejectAssetsRequest";
import {AssetsRequestStatus, AssetsRequestType} from "../../../../../../newShared/GQLTypes";
import {useCreateAndAssignNewAsset} from "../../../hooks/dialogs/useCreateAndAssignNewAsset";
import {useApproveRequestRequestAssetDialog} from "../../../hooks/dialogs/useApproveRequestRequestAssetDialog";
import {useApproveAssignNotAssignedAsset} from "../../../hooks/dialogs/useApproveAssignNotAssignedAsset";
import {useApproveReassignAsset} from "../../../hooks/dialogs/useApproveReassignAsset";
import {useApproveRequestRemoveAsset} from "../../../hooks/dialogs/useApproveRequestRemoveAsset";
import {useRequestAssetError} from "../../../hooks/dialogs/useRequestAssetError";

export const PhysicalAssetsRequestsExact: FC = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets.pathIncomingRequests'});
    const {id} = useParams<{ id?: string }>();
    const {workspaceId} = UseManageWorkspacesAndOrganizations();

    const {
        selected,
        isLoading,

        getRequestById,
        handleClose,
        clearSelected,
    } = usePhysicalAssetsRequestsExact();

    const {
        isOpen: isRejectOpen,
        handleOpen: handleGoToReject
    } = useRejectAssetsRequest();

    const {
        isOpen: isApproveRequestRequestOpen,
        handleOpen: handleOpenApproveRequestRequest
    } = useApproveRequestRequestAssetDialog();

    const {
        isOpen: isApproveRequestRemoveOpen,
        handleOpen: handleOpenApproveRequestRemove
    } = useApproveRequestRemoveAsset();

    const {
        isOpen: isErrorDialogOpen,
    } = useRequestAssetError();

    const {openCreateAndAssignDialog, isOpen: isOpenCreateAndAssign} = useCreateAndAssignNewAsset();
    const {openApproveAssignNotAssignedDialog, isOpen: isOpenAssignNotAssigned} = useApproveAssignNotAssignedAsset();
    const {openApproveReassignAssetDialog, isOpen: isOpenReassign} = useApproveReassignAsset();

    const isOpen = !!id && !isRejectOpen && !isOpenCreateAndAssign && !isApproveRequestRequestOpen && !isOpenAssignNotAssigned && !isOpenReassign && !isApproveRequestRemoveOpen && !isErrorDialogOpen;

    useEffect(() => {
        if (id) {
            getRequestById(id);
        } else {
            clearSelected();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, workspaceId]);

    // useEffect(() => {
    //     if (isOpen && selected?.request.status === AssetsRequestStatus.Cancelled) {
    //         handleClose();
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isOpen, selected?.request.status]);

    return (
        <Dialog open={isOpen}
                onClose={handleClose} scroll={'paper'}
        >
            <DialogTitle>
                {`${t('Request')} ${id}`}
            </DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <AssetRequestPhysicalExact request={selected?.request || null}
                                           asset={selected?.asset || null}
                                           isLoading={isLoading.incomingRequestsExact}/>
            </DialogContent>

            <DialogActions>
                <Button variant={"text"} onClick={handleClose} size={'small'}>
                    {tCommon('Close')}
                </Button>

                {selected?.request.status === AssetsRequestStatus.Created && (
                    <Button variant={"outlined"} onClick={handleGoToReject} size={'small'}>
                        {tCommon('Reject')}
                    </Button>
                )}

                {!selected?.asset && selected?.request.type === AssetsRequestType.AddAsset && selected?.request.status === AssetsRequestStatus.Created &&
                    <Button onClick={openCreateAndAssignDialog} size={'small'}>
                        {tCommon('Approve')}
                    </Button>
                }

                {selected?.asset && !selected?.asset?.assignedTo && selected?.request.type === AssetsRequestType.AddAsset && selected?.request.status === AssetsRequestStatus.Created &&
                    <Button onClick={openApproveAssignNotAssignedDialog} size={'small'}>
                        {tCommon('Approve')}
                    </Button>
                }

                {selected?.asset?.assignedTo && selected?.request.type === AssetsRequestType.AddAsset && selected?.request.status === AssetsRequestStatus.Created &&
                    <Button onClick={openApproveReassignAssetDialog} size={'small'}>
                        {tCommon('Approve')}
                    </Button>
                }

                {selected?.request.type === AssetsRequestType.Request && selected?.request.status === AssetsRequestStatus.Created &&
                    <Button onClick={handleOpenApproveRequestRequest} size={'small'}>
                        {tCommon('Approve')}
                    </Button>
                }

                {selected?.request.type === AssetsRequestType.Remove && selected?.request.status === AssetsRequestStatus.Created &&
                    <Button onClick={handleOpenApproveRequestRemove} size={'small'}>
                        {tCommon('Approve')}
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
};
