import React, {FC, useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogTitle} from "@mui/material";
import * as S from "./styled";
import {useTaskActionTemplateNewDialog} from "../../../hooks/dialogs/useTaskActionTemplateNewDialog";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";


export const AddGroupMessageDialog: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});
    const [message, setMessage] = useState<string>('');

    const {
        handleSetGroupMessage,
        handleHideAddGroupMessage: handleClose,
        addGroupMessageDialog: {isOpen, approverId, groupId, recipientId, oldMessage},
    } = useTaskActionTemplateNewDialog();


    useEffect(() => {
        if (isOpen) {
            if (oldMessage !== null) setMessage(oldMessage ?? "");
            else setMessage('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    const handleAdd = () => {
       if (approverId && groupId) handleSetGroupMessage({approverId, groupId, message});
       else if (recipientId) handleSetGroupMessage({recipientId, message});
    }


    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t("Private message")}</DialogTitle>
            <S.AddMessageDialogContent>
                <S.MessageField value={message} onChange={e => setMessage(e.target.value)} placeholder={t('Start typing a private message..')} />
            </S.AddMessageDialogContent>
            <DialogActions>
                <Button variant={"contained"} onClick={handleClose}>{'Close'}</Button>
                <Button onClick={handleAdd}>{'Save'}</Button>
            </DialogActions>
        </Dialog>
    )

}
