import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideDeleteTemplate, loadings, openExportCsv} from "../../../store/slice";
import {useEffect, useState} from "react";
import {deleteTemplateSteps} from "../../../types";
import {
    DeleteKycTemplate,
    ExportClientsByTemplate,
    GetDeleteTemplateData,
    GetKycAvailableFieldsForExport
} from "../../../store/actions";
import {addSuccessfulSnack} from "../../../../../barsEnvironment/snack/store/slice";

export const useDeleteTemplate = () => {
    const dispatch = useDispatch();
    const {deleteTemplate: {isOpen, template, archivedClientsCount, clientsCount}} = useSelector(dialogs);
    const {getTemplatesList, getDeleteTemplateData, deleteTemplate, downloadCsvInDeleteTemplateDialog} = useSelector(loadings);

    const [isDisabledDelete, setIsDisabledDelete] = useState<boolean>(true);

    const handleClose = () => dispatch(hideDeleteTemplate());

    useEffect(() => {
        if(isOpen){
            template && dispatch(GetDeleteTemplateData({data: {templateId: template.id}}));
        }else{
            setStep('noClientsOrNoArchivedClients');
        }
        //eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        if(!getDeleteTemplateData){
            if(clientsCount > 0){
                setStep('containsNonArchivedClients');
            }

            if(archivedClientsCount === 0 && clientsCount === 0){
                setStep('noClientsOrNoArchivedClients');
            }

            if(archivedClientsCount > 0 && clientsCount === 0) {
                setStep('allTargetsArchived')
            }
        }
        //eslint-disable-next-line
    }, [getDeleteTemplateData]);

    const [step, setStep] = useState<deleteTemplateSteps>('noClientsOrNoArchivedClients');

    const isOk = () => {
        switch (step){
            case "noClientsOrNoArchivedClients": return true;
            case "allTargetsArchived": return true;
            case "containsNonArchivedClients": return !isDisabledDelete;
        }
    }

    const handleDeleteTemplate = () => {
        if(template){
            if(step === 'allTargetsArchived'){
                let onSuccess = () => dispatch(addSuccessfulSnack('Template and archived clients deleted.'));
                dispatch(DeleteKycTemplate({templateId: template.id, isDeleteTargets: true, onSuccess}));
            }
            if(step === 'noClientsOrNoArchivedClients'){
                let onSuccess = () => dispatch(addSuccessfulSnack('Template deleted.'));
                dispatch(DeleteKycTemplate({templateId: template.id,  isDeleteTargets: false, onSuccess}));
            }

            if(step === 'containsNonArchivedClients'){
                let onSuccess = () => dispatch(addSuccessfulSnack('Template and clients deleted.'));
                dispatch(DeleteKycTemplate({templateId: template.id,  isDeleteTargets: true, onSuccess}));
            }
        }
    }

    const handleExportCsv = (fieldKeys: string[]) => {
        template && dispatch(ExportClientsByTemplate({templateId: template.id, templateName: template.name, fieldKeys, isDownload: clientsCount < 10000}));
    }

    const handleOpenExportCsv = () => {
        if(template){
            dispatch(openExportCsv({template}));
            dispatch(GetKycAvailableFieldsForExport({templateId: template.id}));
        }
    }

    return{
        isOpen,
        handleClose,
        template,
        archivedClientsCount,
        clientsCount,
        isLoadingTemplates: getTemplatesList,
        isLoadingDeleteData: getDeleteTemplateData,
        isLoadingDelete: deleteTemplate,
        isLoadingDownloadCsv: downloadCsvInDeleteTemplateDialog,
        step,
        isOk,
        handleDeleteTemplate,
        getDialogTitle: () => 'Delete template',
        isDisabledDelete,
        setIsDisabledDelete,
        handleExportCsv,
        handleOpenExportCsv,
    }
}
