import * as S from '../styled';
import {NameSpan} from '../styled';
import {useSelector} from "react-redux";
import {dialogs, hideAddToBlackListDialog, loadings} from "../../../store/slice";
import {useAppDispatch} from "../../../../../../newShared/redux";
import React, {useEffect, useState} from "react";
import {Button, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TypographyBody1} from "../../../../../../newShared/components/Inputs/styled";
import CommonCheckbox from "../../../../../../newShared/components/Basic/CommonCheckBox";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {AddKycTargetToBlackList} from "../../../store/actions";


export const AddToBlackListDialog = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.dialogs'});

    const dispatch = useAppDispatch();
    const {isOpen, clientId, clientName} = useSelector(dialogs).addToBlackList;
    const {moveToBlacklist} = useSelector(loadings);
    const [isDescription, setIsDescription] = useState<boolean>(false);
    const [description, setDescription] = useState<string>('');
    const handleClose = () => {
        dispatch(hideAddToBlackListDialog());
    }

    const handleSave = () => {
        clientId && dispatch(AddKycTargetToBlackList({workspaceId: '', targetId: clientId, description}));
    }

    useEffect(() => {
        if (isOpen) {
            setIsDescription(false)
            setDescription('');
        }

        //eslint-disable-next-line
    }, [isOpen]);


    return (
        <S.CommonDialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Add to black list')}</DialogTitle>
            <DialogContent>
                <TypographyBody1>{`${t('Client name')}: `}<NameSpan>{clientName ?? ''}</NameSpan></TypographyBody1>
                <TypographyBody1 sx={{mt: '10px'}}>{t('You’re about to add client to black list.')}</TypographyBody1>
                <CommonCheckbox label={t('Provide reason for adding to black list')} checked={isDescription} onChange={() => setIsDescription(!isDescription)} />

                {isDescription && <TextField minRows={3}
                                             multiline
                                             value={description}
                                             onChange={(e) => setDescription(e.target.value)}
                                             label={t('Reason for adding to black list')} />}

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={'text'} sx={{textTransform: 'none'}}>{tCommon('Cancel')}</Button>
                <LoadingButton disabled={moveToBlacklist}
                               onClick={handleSave} loading={moveToBlacklist }
                               loadingPosition={moveToBlacklist ? 'end' : undefined}
                >
                    {t('Add to black list')}
                </LoadingButton>
            </DialogActions>
        </S.CommonDialog>
    )
}
