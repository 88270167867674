import React, {FC, useEffect, useState} from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideDeleteHrSettingDialog, hrSettings, loadings} from "../../../store/slice";
import {
    DEPARTMENT_ID,
    DIVISION_ID,
    EMPLOYMENT_TYPE_ID,
    HrSettingsNormalizedNames,
    LOCATION_ID
} from "../../../constants";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {CommonRadio} from "../../../../../../newShared/components/Basic/CommonRadio";
import {SettingsTypes} from "../../../types";
import {DeleteHrEmployeeSetting} from "../../../store/actions";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";


export const DeleteSettingDialog: FC = () => {

    const {t} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});

    const dispatch = useDispatch();
    const {isOpen, field, value} = useSelector(dialogs).deleteHRSetting;
    const isLoading = useSelector(loadings).deleteSetting;
    const settings = useSelector(hrSettings);

    const [newValue, setNewValue] = useState<string | null>(value || null);
    const [updateHistory, setUpdateHistory] = useState<boolean>(false);

    const handleClose = () => {
        dispatch(hideDeleteHrSettingDialog());
        setNewValue('');
    }

    const handleConfirm = () => {
        (field && value && newValue) && dispatch(DeleteHrEmployeeSetting({data: {field, value, newValue, updateHistory}}));
    }

    useEffect(() => {
        if (isOpen) value && setNewValue(value) ;
        setUpdateHistory(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <Dialog open={isOpen} onClose={handleClose}
                sx={{'& .MuiPaper-root': {width: '600px'}}}>
            <DialogTitle>{`Delete ${field ? HrSettingsNormalizedNames[field] : ''}`}</DialogTitle>
            <DialogContent>
                <TextField select value={newValue ?? null}
                           sx={{mt: '8px', '& label': {top: '0 !important'}}} size={'small'}
                           label={('Select new')}
                           onChange={(e) => { setNewValue(e.target.value as string) }}>

                    {settings && settings[field as SettingsTypes] &&  Object.keys(settings[field as SettingsTypes])
                        .filter(e => e.trim().toLowerCase() !== value?.trim().toLowerCase())
                        .map((name) => (
                        <MenuItem sx={{display: name === value ? 'none' : undefined}} key={name}
                                  value={name}>{name}</MenuItem>
                    ))}
                </TextField>


                { (field === EMPLOYMENT_TYPE_ID || field === LOCATION_ID || field ===  DEPARTMENT_ID || field === DIVISION_ID) &&
                    <>
                        <Typography variant={'body2'}
                                    sx={{marginTop: '16px'}}>{t('How should this change occur?')}</Typography>
                        <Flex ai={"center"} w={'100%'} m={'16px 0 0 0'}>
                            <CommonRadio size={"small"} checked={!updateHistory} sx={{marginRight: '9px'}}
                                         onClick={() => {setUpdateHistory(false)}}/>
                            <Typography variant={'body1'}>{t('Change anywhere it is used, without history')}</Typography>
                        </Flex>
                        <Flex ai={"center"} w={'100%'}>
                            <CommonRadio size={"small"} checked={updateHistory} sx={{marginRight: '9px'}}
                                         onClick={() => {setUpdateHistory(true)}}/>
                            <Typography variant={'body1'}>{t('Change anywhere it is used, including history')}</Typography>
                        </Flex>
                    </>
                }

            </DialogContent>

            <DialogActions sx={{pt: '16px'}}>
                <Button onClick={handleClose} variant={'text'} sx={{textTransform: 'none'}}>{('Close')}</Button>
                <LoadingButton
                    onClick={handleConfirm}
                    disabled={!newValue || newValue.trim.length > 0}
                    loading={isLoading}
                >
                    {('Confirm')}
                </LoadingButton>
            </DialogActions>


        </Dialog>

    )

}