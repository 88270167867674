import {useDispatch, useSelector} from "react-redux";
import {
    mainHrGetIssueByIdAction,
    mainHrGetIssuesListAction,
    mainHrSetIssueStatusInProgressAction,
    mainHrSetIssueStatusResolvedAction
} from "../../store/actions";
import {MainHrIssuesFilter, SharedPaginationInput} from "../../../../../newShared/GQLTypes";
import {employeesIssuesSelector, eraseIssuesList, loadings, replaceIssuesMinMaxLoadedPage} from "../../store/slice";
import {minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {getName} from "../../../../../newShared/utils/text";

type TResponse = {

} & Record<string, any>;

export const useHrIssues = () => {
    const dispatch = useDispatch();
    const {currData: {currentUser}} = UseManageWorkspacesAndOrganizations();

    const {
        issues, pageInfo, minMaxLoadedPage,
        selected,
    } = useSelector(employeesIssuesSelector);
    const isLoading = useSelector(loadings);

    const getIssues = (pageRequest: SharedPaginationInput, filter: MainHrIssuesFilter) => {
        dispatch(mainHrGetIssuesListAction({
            data: {
                pageRequest,
                filter,
            }
        }));
    };

    const getIssueById = (issueId: string) => {
        dispatch(mainHrGetIssueByIdAction({
            data: {issueId: issueId}
        }));
    };

    const setIssueStatusInProgress = (issueId: string) => {
        dispatch(mainHrSetIssueStatusInProgressAction({
            data: {issueId: issueId},
            snack: 'success',
        }));
    };

    const setIssueStatusResolved = (issueId: string, comment: string) => {
        dispatch(mainHrSetIssueStatusResolvedAction({
            data: {issueId: issueId, comment},
            snack: 'success',
            addictiveData: {
                name: getName(currentUser?.firstName ?? '', currentUser?.lastName ?? ''),
                publicId: currentUser?.publicId ?? '',
            }
        }));
    };

    //FiltersPagination
    const setMinMaxLoadedPage = (data: minMaxLoadedPageType) => dispatch(replaceIssuesMinMaxLoadedPage(data));
    const clearRows = () => dispatch(eraseIssuesList());

    return {
        issues, pageInfo, minMaxLoadedPage,
        selected,
        isLoading,

        getIssues,
        getIssueById,
        setIssueStatusInProgress,
        setIssueStatusResolved,

        setMinMaxLoadedPage, clearRows,
    } satisfies TResponse;
};
