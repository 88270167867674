import {Box, styled, Typography} from "@mui/material";
import colors from "../../../../../../../theme/colors";
import {TypographyBodySmall} from "../../../../../../Inputs/styled";

export const StepContainer = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: "column",
    margin: '18px 0 0 0',
})


export const CollapsableContainer = styled('div')({
    width: '100%',
    display: 'flex',
    alignItems: 'center'
})

export const StepText = styled(Typography)({
    color: colors.grayText,
    fontSize: '14px',
    fontWeight: 600
})

export const GroupContainer = styled('div')(({color}) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0 0 0',
    border: `1px solid ${color ?? colors.primary.blue}`,
    borderRadius: '4px',
    padding: '10px 6px'
}))

export const GroupNameText = styled(Typography)({
    color: colors.grayText,
    fontSize: '14px',
    fontWeight: 400
})


export const StatusTableCellContainer = styled(Box)(() => ({
    display: 'flex',
    alignItems: "center",
    margin: '10px 0 0 0'
}));

interface StatusRoundBoxProps {
    revDir: boolean,
    color?: string
}
export const StatusRoundBox = styled(Box, {shouldForwardProp: (propName) => (
        !['revDir', 'color'].includes(propName.toString())
    )})<StatusRoundBoxProps>(({revDir, color}) => ({
    width: '4px',
    height: "4px",
    backgroundColor: color || colors.primary.blue,
    marginRight: !revDir ? "4px" : undefined,
    marginLeft: revDir ? "4px" : undefined,
}));

interface TypographyBodySmallProps {
    color?: string
}
export const StatusTypography = styled(TypographyBodySmall, {shouldForwardProp: (propName) => (
        !['color'].includes(propName.toString())
    )})<TypographyBodySmallProps>(({color}) => ({
    color: color || colors.primary.blue,
    fontWeight: 500,
}))

StatusTypography.defaultProps = {
    noWrap: true,
}

export const RequiredTypography = styled(Typography)({
    color: colors.text.grey,
    fontSize: '12px',
    fontWeight: 400,
    margin: '10px 0 0 0'

});

export const ActorEmailTypography = styled(Typography)({
    color: colors.primary.blue,
    fontSize: '14px',
    fontWeight: 400,
    margin: '10px 0 0 0'
});

export const ActionsContainer = styled(Box)(() => ({
    display: 'flex',
    alignItems: "center",
    margin: '10px 0 0 0'
}));