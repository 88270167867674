import {useEffect, useState} from "react";
import {exactAppTabsType} from "../../../../newIntegrations/types";
import {useDispatch} from "react-redux";
import {useReportsIntegrations} from "../../../hooks/useReportsIntegrations";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {DownloadLogAction, getIntegrationDataByCollectionReportsAction} from "../../../store/reportView/actions";
import {useReportsParams} from "../../../hooks/useReportsParams";
import {
    deselectCollectionObject,
    eraseCollectionDataBeforeFetch,
    setSelectedCollectionObject
} from "../../../store/reportView/store";
// @ts-ignore
import JsonToYaml from "json-to-pretty-yaml";
import {useReport} from "../../../hooks/useReport";

export const useReportIntegrationExactDetailsPage = () => {

    const dispatch = useDispatch();
    const controller = new AbortController();

    const {
        logsData: {logs, selectedLog, pageInfo, selectedLogResponse}, integrations: {
            selectedApp: {app, collectionDataPageInfo, collectionData, selectedCollectionObject},
            loading: {isDataByCollectionLoading, isAppListLoading, isLogPreviewLoading, isLogsDownloading, isLogDownloading}},
        getAppLogs,
        deselectLog, deselectLogFetchResult,
    } = useReportsIntegrations();
    const {reportId} = useReportsParams();
    const {report: {report}} = useReport();

    // useEffect(() => {
    //     if (app && app.config && reportId && app.config.collectionConfigs) {
    //         //user came from list page, app in store - fetching collection data
    //         try {
    //             const firstCollectionName = app.config.collectionConfigs[0]?.alias;
    //             if(firstCollectionName){
    //                 setCollectionTab(firstCollectionName);
    //                 dispatch(getIntegrationDataByCollectionReportsAction({data: {
    //                         reportId: reportId,
    //                         workspaceId: '',
    //                         appId: app.id,
    //                         collectionAlias: firstCollectionName,
    //                         pageInfo: {page: 0, count: 50},
    //                     },
    //                     signal: controller.signal
    //                 }));
    //             }
    //         } catch (ex) {
    //             //no collections found
    //         }
    //     } else {
    //         //page inits here - fetching app by id
    //         // dispatch(GetIntegrationAppById({data: {
    //         //         appId: id,
    //         //         onReject: handleGoBack
    //         //     },
    //         //     signal: controller.signal
    //         // }))
    //     }
    //
    //
    //     return () => {
    //         controller.abort();
    //     }
    //     //eslint-disable-next-line
    // }, []);

    useEffect(() => {
        if(!isAppListLoading && reportId && app?.config && app.config.collectionConfigs){
            //fetching collection data
            try{
                const firstCollectionName = app.config.collectionConfigs[0]?.alias;
                if(firstCollectionName){
                    setCollectionTab(firstCollectionName);
                    dispatch(getIntegrationDataByCollectionReportsAction({data: {
                            reportId: reportId,
                            workspaceId: '',
                            appId: app.id,
                            collectionAlias: firstCollectionName,
                            pageInfo: {page: 0, count: 50},
                        },
                        signal: controller.signal,
                    }));
                }
            }catch (ex) {
                //no collections found
            }
        }

        return () => {
            dispatch(eraseCollectionDataBeforeFetch());
        }
        //eslint-disable-next-line
    }, [app?.id]);

    //TAB
    const [tab, setTab] = useState<exactAppTabsType>('data');

    const handleSetTab = (ttab: exactAppTabsType) => {
        //actions have too be fired when ever going to any tab
        deselectLogFetchResult();
        deselectLog();

        if(ttab === 'logs'){
            !logs.length && app && report && getAppLogs({
                workspaceId: '',
                appId: app.id,
                pageInfo: {page: 0, count: 50,},
                date: report.generated
                });
        }
        setTab(ttab);
    }

    //DATA
    const [collectionsTab, setCollectionTab] = useState<string>('');

    const handleSetCollectionTab = (ttab: string) => {
        if (isDataByCollectionLoading) return; // if loading - doing nothing
        dispatch(eraseCollectionDataBeforeFetch());
        setForm({...form, search: '', prevSearch: ''})
        setCollectionTab(ttab);
        app && reportId && dispatch(getIntegrationDataByCollectionReportsAction({data: {
                workspaceId: '',
                reportId,
                appId: app.id,
                collectionAlias: ttab,
                pageInfo: {page: 0, count: 50,},
            },
            signal: controller.signal
        }));
    }

    const {form, setForm, handleChange} = useForm<{search: string, prevSearch: string}>({search: '', prevSearch: ''});

    const handleCleanSearch = () => {
        setForm({search: '', prevSearch: ''});
        dispatch(eraseCollectionDataBeforeFetch());
        app && reportId && dispatch(getIntegrationDataByCollectionReportsAction({data: {
                workspaceId: '',
                reportId,
                appId: app.id,
                collectionAlias: collectionsTab,
                pageInfo: {page: 0, count: 50,},
            },
            signal: controller.signal
        }));
    }

    const handleEnterKey = (e: any) => {
        if(e.keyCode === 13 && !form.search.trim().length){
            handleCleanSearch();
        }

        if(e.keyCode === 13 && form.search.trim().length > 0){
            alert(123)
            setForm({...form, prevSearch: form.search});
            dispatch(eraseCollectionDataBeforeFetch());
            app && reportId && dispatch(getIntegrationDataByCollectionReportsAction({data: {
                    workspaceId: '',
                    reportId,
                    appId: app.id,
                    collectionAlias: collectionsTab,
                    pageInfo: {page: 0, count: 50, filters: {input: form.search.trim() !== '' ? [form.search.trim()] : undefined}},
                },
                signal: controller.signal
            }));
        }
    }

    const handleLoadMore = (page: number) => {
        !isDataByCollectionLoading && app && reportId && dispatch(getIntegrationDataByCollectionReportsAction({data: {
                workspaceId: '',
                reportId,
                appId: app.id,
                collectionAlias: collectionsTab,
                pageInfo: {page: collectionDataPageInfo.page + 1, count: collectionDataPageInfo.count, filters: {input: form.search.trim() !== '' ? [form.search.trim()] : undefined}},
            },
            signal: controller.signal
        }));
    }

    //logs
    const handleLoadMoreLogs = (page: number) => {
        !logs && app && report && getAppLogs({
            workspaceId: '',
            appId: app.id,
            pageInfo: {page: 0, count: 50},
            date: report.generated
        });
    }


    return {
        isLoading: isAppListLoading,
        app,
        tab,

        actions: {
            handleSetTab,
        },
        data: {
            // selectedCollection,
            collectionData,
            collectionDataPageInfo,
            isLoading: isDataByCollectionLoading,
            collectionsTab,
            handleSetCollectionTab,
            search: form.search,
            handleChange,
            handleCleanSearch,
            handleEnterKey,
            selectedCollectionObject,
            selectedCollectionObjectInYaml: JsonToYaml.stringify(selectedCollectionObject),
            currentCollectionConfig: app?.config?.collectionConfigs.find(e => e.alias === collectionsTab),
            setSelectedCollectionObject: (obj: { [key: string]: any }) => dispatch(setSelectedCollectionObject(obj)),
            deselectCollectionObject: () => dispatch(deselectCollectionObject()),
            handleLoadMore,
            hasMore: collectionDataPageInfo.total > ((collectionDataPageInfo.page + 1) * collectionDataPageInfo.count),
        },

        logsData: {
            isLoadingList: isLogsDownloading,
            isLoadingExact: isLogPreviewLoading,
            isLoadingDownload: isLogDownloading,
            logs,
            selectedLog,
            hasMore: pageInfo.total > ((pageInfo.page + 1) * pageInfo.count),
            handleLoadMoreLogs,
            deselectLog: () => deselectLog(),
            handleDownloadLog: () => {
                if (selectedLog && app) {
                    dispatch(DownloadLogAction({
                        data: {
                            fileId: selectedLog.fileId,
                            fileName: `${app.name}-${selectedLog.timestamp}-log.txt`
                        }
                    }))
                }
            },
            logResponse: selectedLogResponse
        }
    }
}