import {TSignDialogProps, TSignDialogState, TSignDialogTab} from "../../types";

import {ChangeEvent, createRef, MouseEventHandler, useEffect, useState} from "react";
import {initialState} from "../../constants";
import {toBase64} from "../../../../../../../../utils/base64/base64";
import html2canvas from "html2canvas";

export const useSignDialog = (props: TSignDialogProps) => {
    const [state, setState] = useState<TSignDialogState>(initialState);
    const ref = createRef<HTMLInputElement>();

    const handleChange = (event: React.SyntheticEvent, newValue: TSignDialogTab) => {
        setState((prev) => ({...prev, tab: newValue, sign: '', insertEverywhere: false, file: null, fileName: null}));
    };

    //CANVAS
    const [canvas, setCanvas] = useState<any>(null);

    const handleClearCanvas = () => {
        canvas && canvas.clear()
    }

    //TOGGLE
    const handleToggleSwitch = (newValue: boolean) => {
        setState((prev) => ({...prev, insertEverywhere: newValue}));
    }

    //TYPE
    const handleChangeTypeInput = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setState((prev) => ({...prev, sign: event.target.value}));
    }

    const handleClearInputOrFile = () => {
        setState((prev) => ({...prev, sign: ''}));
    }

    //FILE

    const filePicker = () => {
        ref.current && ref.current.click();
    };

    const fileHandler = async(fileList: FileList | null) => {
        let file = fileList?.item(0);
        if(file !== null && file !== undefined){
            const fileBase64: string = await toBase64(file) as string;
            setState((prev) => ({...prev, file: fileBase64, fileName: file!.name}))
            // console.log(`file.name`, file.name)
        }
    };

    const handleRemoveFile: MouseEventHandler<SVGSVGElement> = (e) => {
        e.stopPropagation();
        setState((prev) => ({...prev, file: null, fileName: null}));
    }

    //OTHER
    async function textToSVGBase64(text: string) {
        const fontSize = 24;
        const textColor = "#000000";
        const backgroundColor = "transparent";

        const container = document.createElement('div');
        container.style.position = 'fixed';
        container.style.left = '-9999px';
        container.style.top = '-9999px';

        container.innerText = text;
        container.style.fontSize = `${fontSize}px`;
        container.style.color = textColor;
        container.style.fontFamily = 'Arial, sans-serif';
        container.style.padding = '4px';
        container.style.backgroundColor = backgroundColor;

        document.body.appendChild(container);

        const canvas = await html2canvas(container, {backgroundColor: null});
        const base64String = canvas.toDataURL('image/png');

        document.body.removeChild(container);

        return base64String;
    }

    const handleSubmit = async () => {
        switch (state.tab){
            case "draw": {
                if(canvas && !canvas.isEmpty()){
                    const base64 = canvas.getCanvas().toDataURL();
                    props.handleSubmit(base64, state.insertEverywhere);
                }
                break;
            }
            case "type": {
                if(isOk()){
                    //
                    const base64Sign = await textToSVGBase64(state.sign);
                    props.handleSubmit(base64Sign, state.insertEverywhere)
                }
                break;
            }
            case "upload": {
                state.file && props.handleSubmit(state.file, state.insertEverywhere)
            }
        }

        props.handleClose();
    }

    const isOk = () => {
        switch (state.tab){
            case "draw": {
                //we will check if canvas empty in submit func
                return true;
            }
            case "upload": {
                return state.file !== null
            }
            default: {
                return state.sign.trim().length > 0
            }
        }
    }

    useEffect(() => {
        setState(initialState);
        // eslint-disable-next-line
    }, [props.isOpen])

    return{
        state,
        handleChange,
        handleClearCanvas,
        setCanvas,
        handleToggleSwitch,
        handleSubmit,
        isOk: isOk(),
        handleChangeTypeInput,
        handleClearInputOrFile,
        filePicker,
        fileHandler,
        ref,
        handleRemoveFile
    }
}