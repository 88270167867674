import {styled, TableCell} from "@mui/material";


export const TrashNameTableCell = styled(TableCell)(() => ({
    width: '500px',
}));
export const TrashedDateTableCell = styled(TableCell)(() => ({
    width: '300px',
}));

export const TrashStatusTableCell = styled(TableCell)(() => ({
    width: '300px',
}));

export const TrashActionTableCell = styled(TableCell)(() => ({
    width: '140px',
}));


