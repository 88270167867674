import {TFont, TFontStyle, TToolBarSlice} from "./types";


export const FontStylesVariants: TFontStyle[] = [
    'Normal text','Heading 1', 'Heading 2', 'Heading 3', 'Heading 4', 'Heading 5'
    // {title: 'Normal text', class: 'font-style-normal-text'},
    // {title: 'Heading 1', class: 'font-style-heading-1'},
    // {title: 'Heading 2', class: 'font-style-heading-2'},
    // {title: 'Heading 3', class: 'font-style-heading-3'},
    // {title: 'Heading 4', class: 'font-style-heading-4'},
    // {title: 'Heading 5', class: 'font-style-heading-5'},
];

export const FontsVariants: TFont[] = [
    "Arial",
    "Georgia",
    "Helvetica",
    "Monospace",
    "Times New Roman"
    // {title: "Arial", class: 'font-arial'},
    // {title: "Georgia", class: 'font-georgia'},
    // {title: "Helvetica", class: 'font-helvetica'},
    // {title: "Monospace", class: 'font-monospace'},
    // {title: "Times New Roman", class: 'font-times-new-roman'},
];

// export const FontAlignmentVariants: TFontAlignment[] = [
//     'left',
//     'center',
//     'right'
// ];

// export const ListTypeVariants: TListType[] = [
//     'Bulleted list',
//     'Numbered list'
// ];
export const FontSizesVariants = [8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 30, 36, 48,60,72,96];

export const initialEditorToolBarState: TToolBarSlice = {
    pagesControl: {
        isDisabled: true,
        isShow: false,
        value: false,
    },
    undoRedoControl: {
        isShow: false,
        undoDisabled: true,
        redoDisabled: true
    },
    styledControl: {
        isShow: true,
        isDisabled: true,
        value: 'Normal text',
    },
    fontControl: {
        isShow: true,
        isDisabled: true,
        value:  "Arial",
    },
    fontSizeControl: {
        isShow: true,
        isDisabled: true,
        value: 16,
    },
    decorationControl: {
        isShow: true,
        isDisabled: true,
        isBold: false,
        isItalic: false,
        isUnderline: false,
        color: "#000",
        isStrikethrough: false,
        isSuperscript: false,
        isSubscript: false,
    },
    alignmentControl: {
        isShow: true,
        isDisabled: true,
        value: 'left',
    },
    listTypeControl: {
        isShow: true,
        isDisabled: true,
        value: null,
    },
    linkControl: {
        isShow: false,
        isDisabled: true,
        isOpenDialog: false,
        value: false,
    },

    removeFormatting: {
        isDisabled: true,
        isShow: true,
    },

    ids: [],
}

export const DEFAULT_COLORS = [
    '#000000',
    '#747474',
    '#C6C6C6',
    '#E7E7E7',
    '#00AEEF',
    '#0072BC',
    '#2E3192', //link color
    '#611DB7',
    '#92278F',
    '#00A99D',
    '#00A651',
    '#8DC63F',
    '#ED1C24',
    '#ED145B',
    '#F7941E',
    '#DFE312'
]

export const LINK_COLOR = DEFAULT_COLORS[6];