import {useCreateAssignment} from "../../../hooks/dialogs/useCreateAssignment";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import React, {FC} from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import colors from "../../../../../../newShared/theme/colors";
import {
    CollaboratorRole,
    MainTrainingLevel,
    MainTrainingsGetExamsAutocomplete,
    MainTrainingsGetTrainingsAutocomplete,
    TrainingExamLevel,
    WorkspaceCollaboratorWithRolesModel
} from "../../../../../../newShared/GQLTypes";
import {optionIsTCollaborator} from "../../../../../../newShared/components/AutocompleteCollaborators/types";
import {getName} from "../../../../../../newShared/utils/text";
import {AutocompleteCollaborators} from "../../../../../../newShared/components/AutocompleteCollaborators";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {LevelAutocompleteBox, LevelAutocompleteTitle} from "../../styled";
import {trainingLevelTitleMapper} from "../../../constants";
import {DEFAULT_DATE_FORMAT} from "../../../../../../newShared/utils/dateTools";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Flex} from "../../../../../../newShared/components/Layouts";
import FormControlLabel from "@mui/material/FormControlLabel";


export const CreateAssignmentDialog: FC = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.dialogs'});
    const {
        isOpen,
        loadings,
        formik,
        fromPage,
        isFirstPart,
        autocomplete,
        handleNext,
        handleBack,
        handleClose,
        limitDuration,
        selectedExam,
        setSelectedExam,
    } = useCreateAssignment();

    const isOptionDisabled = (value: WorkspaceCollaboratorWithRolesModel): boolean => {
        return !value.roles.includes(CollaboratorRole.RolePortalMember);
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('New assignment')}</DialogTitle>
            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '12px'}}>

                { isFirstPart ?
                    <>
                        <Typography variant={'*bodySmall'} color={colors.text.grey_dark}>{t('Only a collaborator with Portal Access can be selected')}</Typography>

                        <AutocompleteCollaborators<false, false, false>
                            // sx={{marginTop: '-10px'}}
                            required
                            getOptionLabelCustom={"name+email"}
                            getOptionLabel={(option) => optionIsTCollaborator(option)
                                ? getName(option.firstName || '', option.lastName || '')
                                : option}
                            defaultValue={formik.values.recipient ? ({publicId: formik.values.recipient.collaboratorId, email: formik.values.recipient.email, firstName: formik.values.recipient.fullName}) : null}
                            inputOnBlur={formik.handleBlur}
                            inputName={'Recipient'}
                            onChange={value => {
                                formik.setFieldValue('recipient', value ? ({
                                    collaboratorId: value.publicId, fullName: getName(value.firstName || '', value.lastName || ''), email: value.email,
                                }) : null);
                            }}
                            label={t('Recipient')}
                            helperText={formik.touched.recipient ? formik.errors.recipient : undefined}
                            getOptionDisabled={value => isOptionDisabled(value)}
                        />

                        <Autocomplete<MainTrainingsGetTrainingsAutocomplete>
                            size={"small"}
                            disabled={!!fromPage.training}
                            options={!!fromPage.training ? [fromPage.training] : autocomplete.trainings}
                            loading={loadings.getTrainingsLoading}
                            onOpen={() => autocomplete.getTrainingsData()}
                            value={!!fromPage.training ? fromPage.training : autocomplete.trainings.find((e) => e.trainingId === formik.values.training?.trainingId) || null}
                            getOptionLabel={e => e.name}
                            onChange={(e, selected) => {
                                formik.setFieldValue('training', !selected ? null : {trainingId: selected.trainingId, limitMinDuration: 0, limitMaxDuration: 0})
                            }}
                            renderInput={(params) => (<TextField {...params} size={'small'} label={t('Training')} />)}
                            renderOption={(props, option) => (
                                <li {...props} key={option.trainingId} style={{ display: "flex", flexDirection: 'column', gap: '6px', alignItems: 'flex-start', zIndex: '999999', position: 'relative', padding: '6px 12px' }}>
                                    {option && <>
                                        <Typography variant={'*bodySmall'}>{option.name}</Typography>
                                        <Typography variant={'*bodySmall'} sx={{ ontSize: '10px', fontWeight: 500, color: colors.text.grey}}>{option.category}</Typography>
                                        <LevelAutocompleteBox bgColor={colors.backgrounds.grey_light}>
                                            <LevelAutocompleteTitle color={colors.primary.blue_dark}>{trainingLevelTitleMapper[option.level as MainTrainingLevel]}</LevelAutocompleteTitle>
                                        </LevelAutocompleteBox>
                                    </>
                                    }
                                </li>
                            )}
                        />

                        <Autocomplete<MainTrainingsGetExamsAutocomplete>
                            size={"small"}
                            options={!!fromPage.exam ? [fromPage.exam] : autocomplete.exams}
                            loading={loadings.getExamsLoading}
                            value={fromPage.exam ? fromPage.exam : selectedExam}
                            disabled={!!fromPage.exam}
                            // value={autocomplete.exams.find((e) => e.examId === formik.values.postExam?.examId) || null}
                            getOptionLabel={e => e.name}
                            onOpen={() => autocomplete.getExamsData()}
                            onChange={(e, selected) => {
                                formik.setFieldValue('postExam', !selected ? null : {examId: selected.examId, totalPoints: selected.totalPoints, examPassingPoints: selected.examPassingPoints, timeToPass: selected.timeToPass})
                                setSelectedExam(selected);
                            }}
                            renderInput={(params) => (<TextField {...params} size={'small'}  label={t('Exam')} />)}
                            renderOption={(props, option) => (
                                <li {...props} key={option.examId} style={{ display: "flex", flexDirection: 'column', gap: '6px', alignItems: 'flex-start', zIndex: '999999', position: 'relative', padding: '6px 12px' }}>
                                    {option && <>
                                        <Typography variant={'*bodySmall'}>{option.name}</Typography>
                                        <Typography variant={'*bodySmall'} sx={{ ontSize: '10px', fontWeight: 500, color: colors.text.grey}}>{option.category}</Typography>
                                        <LevelAutocompleteBox bgColor={colors.backgrounds.grey_light}>
                                            <LevelAutocompleteTitle color={colors.primary.blue_dark}>{trainingLevelTitleMapper[option.level as TrainingExamLevel]}</LevelAutocompleteTitle>
                                        </LevelAutocompleteBox>
                                    </>
                                    }
                                </li>
                            )}
                        />
                    </>
                    :
                    <>
                        <DatePicker
                            label={t('Scheduled start date')}
                            inputFormat={DEFAULT_DATE_FORMAT}
                            value={formik.values.startDate}
                            disablePast
                            onChange={(value) => formik.setFieldValue('startDate', value || null)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size={"small"}
                                    required
                                    fullWidth
                                    name={'startDate'}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                                    helperText={formik.touched.startDate && formik.errors.startDate}
                                />)}
                        />


                        <DatePicker
                            label={t('Due date')}
                            inputFormat={DEFAULT_DATE_FORMAT}
                            value={formik.values.endDate || null}
                            minDate={formik.values.startDate || new Date()}
                            // disablePast
                            onChange={(value) => formik.setFieldValue('endDate', value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size={"small"}
                                    fullWidth
                                    name={'endDate'}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                                    helperText={formik.touched.endDate && formik.errors.endDate}
                                />)}
                        />


                        <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                            {formik.values.postExam?.examId && formik.values.postExam.examId.length > 0 &&
                                <Box display={'flex'} alignItems={'center'} gap={'40px'}>
                                    <Box display={'flex'} alignItems={'center'} gap={'10px'}>

                                        <Typography variant={'*bodyText2'}>{t('Exam passing points')}</Typography>
                                        <TextField name={'postExam.examPassingPoints'} onChange={formik.handleChange}
                                                   value={formik.values.postExam.examPassingPoints}
                                                   size={'small'} type={'number'} sx={{width: '80px'}}
                                                   InputProps={{ inputProps: { min: 1, max: selectedExam?.totalPoints }}}
                                                   error={formik.touched.postExam && Boolean(formik.errors.postExam)}
                                        />
                                    </Box>

                                    <Typography variant={'*bodyText2'} >{`${t('Exam total points')} `}<span style={{fontWeight: 600}}>{selectedExam?.totalPoints ?? ''}</span></Typography>

                                </Box>
                            }

                            {formik.values.training?.trainingId && formik.values.training?.trainingId.length > 0 &&
                                <Flex ai={'center'} w={'100%'} gap={'8px'}>
                                    <FormControlLabel
                                        sx={{marginRight: '0'}}
                                        onClick={() => limitDuration.onLimitMinimumTrainingDurationCheckboxClick()}

                                        control={<Checkbox checked={formik.values.training.limitMinDuration !== 0}/>}
                                        label={<Typography variant={'*bodyText2'} color={colors.grayText}
                                                           noWrap>{"Limit minimum training duration"}</Typography>}
                                    />

                                    {formik.values.training.limitMinDuration !== 0 &&
                                        <>
                                            <TextField
                                                name={'training.limitMinDuration'}
                                                onChange={formik.handleChange}
                                                value={formik.values.training.limitMinDuration}
                                                size={'small'}
                                                type={'number'}
                                                sx={{width: '80px'}}
                                                InputProps={{ inputProps: { min: 1 }}}
                                            />
                                            <Typography variant={'*bodyText2'} color={colors.grayText}>{'hours'}</Typography>
                                        </>
                                    }
                                </Flex>
                            }

                            {formik.values.postExam?.examId && formik.values.postExam.examId.length > 0 &&
                                <Flex ai={'center'} w={'100%'} gap={'8px'}>
                                    <FormControlLabel
                                        sx={{marginRight: '0'}}
                                        onClick={() => limitDuration.onLimitMaximumExamDurationCheckboxClick()}
                                        control={<Checkbox checked={formik.values.postExam?.timeToPass !== 0}/>}
                                        label={<Typography variant={'*bodyText2'} color={colors.grayText}
                                                           noWrap>{"Limit maximum exam duration"}</Typography>}
                                    />

                                    {formik.values.postExam?.timeToPass !== 0 &&
                                        <>
                                            <TextField
                                                name={'postExam.timeToPass'}
                                                onChange={formik.handleChange}
                                                value={formik.values.postExam?.timeToPass}
                                                size={'small'}
                                                type={'number'}
                                                sx={{width: '80px'}}
                                                InputProps={{ inputProps: { min: 1 }}}
                                            />
                                            <Typography variant={'*bodyText2'} color={colors.grayText}>{'minutes'}</Typography>
                                        </>
                                    }
                                </Flex>
                            }

                        </Box>
                    </>
                }
            </DialogContent>

            <DialogActions>
                {!isFirstPart && <Button startIcon={<ArrowBackIosIcon style={{fontSize: 'small'}}/>} size={'small'}
                                         onClick={handleBack} variant={'text'}
                                         sx={{alignSelf: 'start'}}>{tCommon('Back')}</Button>}

                <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} width={'100%'} gap={'12px'} >
                    <Button size={'small'} variant={'text'} onClick={handleClose}>{tCommon('Cancel')}</Button>
                    {isFirstPart && <Button size={'small'} onClick={handleNext} variant={'text'} disabled={!!formik.errors.training || !!formik.errors.postExam || !!formik.errors.recipient} sx={{alignSelf: 'start'}}>{tCommon('Next')}</Button>}

                    {!isFirstPart &&
                        <LoadingButton size={'small'} variant={'contained'} onClick={() => formik.handleSubmit()}
                                       loading={loadings.isLoadingCreate}
                                       disabled={!formik.isValid}>{tCommon('Create')}</LoadingButton>}

                </Box>

            </DialogActions>
        </Dialog>
    )
}




