import {FC} from "react";
import {Menu, MenuItem} from "@mui/material";
import {FontSizeButton} from "./styled";
import {useFontSizeControl} from "./hooks";
import {withShrinkZero} from "../../../hoc/withShrinkZero";
import {TFontSize, TToolBarHandlerAction, TToolBarHandlerPayload} from "../../types";
import {EditorTooltip} from "../../../commonComponents/editorTooltip";

interface FontSizeControlProps {
    initialFontSize: TFontSize | null;
    changeFontSizeHandler: (action: TToolBarHandlerAction, payload: TToolBarHandlerPayload) => void;
    isDisabled: boolean;
}

const FontSizeControl: FC<FontSizeControlProps> = ({initialFontSize, changeFontSizeHandler, isDisabled}) => {
    const {fontSizes, open, anchorEl, handleClose, handleClick,} = useFontSizeControl({
        initialFontSize,
        anchorEl: null,
        changeFontSizeHandler
    });

    return <>
        <EditorTooltip title="Font size">
            <span>
                <FontSizeButton id="font-button"
                                disabled={isDisabled}
                                size="medium"
                                aria-controls={open ? 'font-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}>{initialFontSize ?? '-'}</FontSizeButton>
            </span>
        </EditorTooltip>
        <Menu
            id="font-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose()}
            MenuListProps={{'aria-labelledby': 'font-button'}}
        >
            {fontSizes.map(size => <MenuItem key={size.toString()} onClick={handleClose(size)}>{size.toString()}</MenuItem>)}
        </Menu>
    </>
}

const wrapper = withShrinkZero<FontSizeControlProps>(FontSizeControl);

export {wrapper as FontSizeControl};
