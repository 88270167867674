import colors from "../../../../../newShared/theme/colors";
import {
    Box,
    Button,
    Divider,
    Menu,
    OutlinedInput,
    styled,
    TableCell,
    TableContainer,
    TextField,
    Typography
} from "@mui/material";
import {TypographyBody2} from "../../../../../newShared/components/Inputs/styled";
import Tooltip, {tooltipClasses, TooltipProps} from "@mui/material/Tooltip";

export const PageContainer = styled('div')({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
});

export const TemplateCardsContained = styled('div')({
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    overflowY: 'auto',
    gap: '16px',
    margin: '18px 0',
});

export const CreateTemplateMainContainer = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 20px',
    gap: '32px',
    border: `1px solid ${colors.stroke.grey}`,
    borderRadius: '6px',
    backgroundColor: colors.backgrounds.grey_light2,
    margin: '28px 0',
    overflowY: 'auto'
})

export const CreateTemplateSubContainer = styled('div')(({isMobile}: {isMobile: boolean}) => ({
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: isMobile ? 'column' : 'row',
    display: 'flex',
    gap: '24px'
}));

export const CreateTemplateSubLeftContainer = styled('div')(({isMobile}: {isMobile: boolean}) => ({
    width: isMobile ? '100%' : '38%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
}));

export const CreateTemplateSubRightContainer = styled('div')(({isMobile}: {isMobile: boolean}) => ({
    width: isMobile ? '100%' : '62%',
    display: 'flex',
    flexDirection: 'column'
}));

export const SubLeftContainerTitleText = styled(Typography)({
    fontSize: '14px',
    fontWeight: 600,
    color: colors.text.grey_dark
})

export const SubLeftContainerDescText = styled(Typography)({
    fontSize: '14px',
    fontWeight: 400,
    color: colors.grayText
})


export const InputTitle = styled(Typography)(({_margin}: {_margin?: string | undefined}) => ({
    fontSize: '12px',
    fontWeight: 600,
    color: colors.text.grey_dark,
    margin: _margin
}))

export const RequiredSpan = styled("span")(() => ({
    color: 'red'
}))

export const SubRightContainerInput = styled(TextField)(({_margin}: {_margin?: string | undefined}) => ({
    backgroundColor: colors.backgrounds.white,
    height: '40px',
    margin: _margin
}))
SubRightContainerInput.defaultProps = {
    size: 'small'
}

export const SubRightContainerMultilineInput = styled(TextField)(({_margin}: {_margin?: string | undefined}) => ({
    backgroundColor: colors.backgrounds.white,
    margin: _margin
}))
SubRightContainerMultilineInput.defaultProps = {
    multiline: true,
    minRows: 3
}

export const ContainerDivider = styled(Divider)({
    backgroundColor: colors.primary.disable,
    borderColor: colors.primary.disable
})
ContainerDivider.defaultProps = {flexItem: true}

//
export const InputFieldContainer = styled('div')(({isSystem, isError, opacity}: {isSystem: boolean, isError: boolean, opacity: string} ) => ({
    width: '100%',
    display: 'flex',
    alignItems: "center",
    justifyContent: 'space-between',
    height: '44px',
    backgroundColor: colors.backgrounds.white,
    borderRadius: '4px',
    padding: !isSystem ? '0 16px 0 0' : '2px 16px',
    border: `1px solid ${isError ? colors.stroke.red : colors.stroke.grey}`,
    gap: '10px',
    opacity
}))

export const FieldInput = styled(OutlinedInput)({
    padding: '0',
    height: '40px',
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiButtonBase-root': {
        padding: '13px 10px',
    },
    '& input:disabled': {
        color: colors.text.grey_dark,
    }
})
FieldInput.defaultProps = {
    inputProps: {
        style: {
            width: '88%',
            padding: '16px 10px',
            height: '14px',
        }
    },
    size:'small',
    fullWidth: true
}

export const VerDivider = styled('div')`
  width: 1px;
  background-color: #ccc;
  margin: 0 3px;
  flex-shrink: 0;
  height: 32px;
`;


export const CommonTableContainer = styled(TableContainer)({
    boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    borderRadius: '8px !important',
    border: `1px solid #EAECF0`,
    overflow: 'auto',
    height: '100%',
    margin: '16px 0'

})

export const TargetScreeningsTableContainer = styled(TableContainer)({
    boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    borderRadius: '8px !important',
    border: `1px solid #EAECF0`,
    overflow: 'auto',
    height: 'calc(100% - 32px)',
    margin: '16px 0',
    flexGrow: '1',
})

export const WidthCommonTableCell = styled(TableCell)(() => ({
    maxWidth: '400px',
}));

export const CommonTableCell = styled(TableCell)(() => ({
    width: '200px'
}));
export const SmallTextButton = styled(Button)(() => ({
    textTransform: 'none',
    width: 'max-content',
}));
SmallTextButton.defaultProps = {
    variant: 'text',
    size: 'small'
}

export const ClientRowActionMenu = styled(Menu)(() => ({
    '& .MuiMenu-list': {
        padding: '10px 0'
    },
    '& .MuiMenuItem-root': {
        margin: '0 auto',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: '#F2F4F7'
        }
    }
}));

ClientRowActionMenu.defaultProps = {
    MenuListProps: {'aria-labelledby': 'long-button'},
    PaperProps: {
        style: {
            boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)',
            borderRadius: '8px',
            border: '1px solid #F2F4F7'
        },
    }
}

export const MenuItemBox = styled(Box)(() => ({
    padding: '0 10px',
    display: 'flex',
    alignItems: 'center',

    '& svg': {
        color: colors.text.grey_dark
    }
}));

export const MenuItemText = styled(TypographyBody2)(() => ({
    marginLeft:'16px',
    marginRight: '16px',
    color: colors.text.dark,
}));

export const EmptyRequiredFieldsTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#F7F7F7",
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[0],
        fontSize: 11,
        fontWeight: 400,
        maxWidth: 114,
        padding: 8,
        textAlign: "center",
    },
}));
