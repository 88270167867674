import React from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../../../newShared/components/Layouts";
import {useExactSetting} from "../../hooks/useExactSetting";
import {Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {EditDialog} from "../../components/dialogs/editDialog";
import {DeleteDialog} from "../../components/dialogs/deleteSetting";
import {ExactSettingTableRow} from "../../components/exactSettingTableRow";
import {CreateSettingDialog} from "../../components/dialogs/createSetting";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {GenericFiltersArray} from "../../../../../newShared/components/genericFilter";

export const ExactSetting = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathVendors.VendorsSettings'});
    const {
        settings,
        normalizedName,
        handleGoBack,
        handleCreateNewItem,
        settingOptions,
        handleChange,
        handleDelete,
        isSettingsLoading,
        handleFilter
    } = useExactSetting();


    return(
        <>
            <Flex w={'100%'} h={'100%'} direction={'column'}>
                <PageTitleAndActions
                    title={t(normalizedName)}
                    handleGoBack={handleGoBack}
                    actions={[
                        {
                            title: t('Add new item'),
                            variant: 'text',
                            icon: <AddIcon />,
                            onClick: () => handleCreateNewItem('', null),
                            disabled: isSettingsLoading
                        }
                    ]}
                />
                {/*<S.SearchSettingWrapper>*/}
                {/*    <SearchField*/}
                {/*        sx={{color: colors.grayText, height: '40px'}}*/}
                {/*        variant={'outlined'}*/}
                {/*        onChange={(e) =>{*/}
                {/*            setSearch(e.currentTarget.value)*/}
                {/*        }}*/}
                {/*        onKeyDown={handleEnterKey}*/}
                {/*        label={t('Search')}*/}
                {/*        value={search}*/}
                {/*        onClear={handleCleanSearch}*/}
                {/*        dir={'ltr'}*/}
                {/*        placeholder={t('Search')}*/}
                {/*        disabled={isSettingsLoading}*/}
                {/*    />*/}
                {/*</S.SearchSettingWrapper>*/}

                <GenericFiltersArray
                    configs={[]}
                    fetchResultWithSelectedFilters={(search) => handleFilter(search)}
                    isAddFilters={false}
                />
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableCell>
                                {t('Title')}
                            </TableCell>

                            <TableCell>
                                {t('Vendors')}
                            </TableCell>

                            <TableCell>
                            </TableCell>
                        </TableHead>

                        <TableBody>
                            {isSettingsLoading && <TableRow>
                                <TableCell>
                                    <Skeleton width={80} height={30}/>
                                </TableCell>

                                <TableCell>
                                    <Skeleton width={80} height={30}/>
                                </TableCell>

                                <TableCell>
                                    <Skeleton variant={'circular'} width={20} height={20}/>
                                </TableCell>
                            </TableRow>}

                            {settings && settingOptions.map(e => <ExactSettingTableRow
                                setting={e} key={e.name}
                                handleChange={handleChange}
                                handleDelete={handleDelete}/>
                            )}

                        </TableBody>
                    </Table>
                </TableContainer>

                {/*<S.ExactSettingHeaderWrapper>*/}
                {/*    <Typography*/}
                {/*        sx={{fontSize: isDesktop? '16px':'14px', fontWeight: 700, color: colors.grayText}}>*/}
                {/*        {t('Title')}</Typography>*/}
                {/*    <Typography*/}
                {/*        sx={{fontSize: isDesktop? '16px':'14px', fontWeight: 700, color: colors.grayText}}>*/}
                {/*        {t('Vendors')}</Typography>*/}
                {/*</S.ExactSettingHeaderWrapper>*/}

                {/*{!isSettingsLoading ?*/}
                {/*    <S.SettingsListWrapper>*/}
                {/*        {settings && settingOptions.map(e => <ExactSettingTableRow setting={e} key={e.name}*/}
                {/*                                                                   handleChange={handleChange}*/}
                {/*                                                                   handleDelete={handleDelete}*/}
                {/*        />)}*/}
                {/*    </S.SettingsListWrapper>*/}
                {/*    :*/}
                {/*    <S.SettingsListWrapper>*/}
                {/*        <ExactSettingLoading/>*/}
                {/*    </S.SettingsListWrapper>*/}
                {/*}*/}
            </Flex>

            {/*    DIALOGS*/}
            <EditDialog/>
            <DeleteDialog/>
            <CreateSettingDialog/>
        </>
    )

}