import {useDispatch, useSelector} from "react-redux";
import {selectedLanguage, selectLanguage} from "../../../../newShared/hooks/useMainTranslationHooks/slice";
import {useEffect, useState} from "react";
import {TLanguage} from "../../../../newShared/hooks/useMainTranslationHooks/types";
import {SUPPORTED_LANGUAGES} from "../../../../newShared/constants";
import i18next from "i18next";
import {ChangeLanguagePropTypes} from "../types";

// @ts-ignore
export const useLanguageChangeLogic = ({handleClose, isOpen}: ChangeLanguagePropTypes) => {
    const dispatch = useDispatch();
    const currentLanguage = useSelector(selectedLanguage);
    const [innerCheck, setInnerCheck] = useState<string>('');

    useEffect(() => {
        setInnerCheck(currentLanguage.code);
        //eslint-disable-next-line
    }, [isOpen]);

    const handleChooseLanguage = () => {
        // console.log('handleChooseLanguage');
        const languageCode = innerCheck;
        const currLanguage: TLanguage | undefined = SUPPORTED_LANGUAGES.find((e) => e.code === languageCode);
        if(currLanguage) {
            dispatch(selectLanguage(currLanguage));
            i18next.changeLanguage(currLanguage.code);
            handleClose(false);
        }
    }

    return{
        close: () => handleClose(false),
        onSubmit: handleChooseLanguage,
        currentLanguage,
        isOpen,
        setInnerLanguage: (code: string) => setInnerCheck(code),
        innerLanguage: innerCheck

    }
}