import {FlexColumn} from "../../editorTitleWithActionsRow/styled";
import {styled} from "@mui/material";

export const EditorItemPdfPageStyled = styled(FlexColumn)(() => ({
  position: 'relative',
  boxShadow: '0px 0.586351px 1.75905px rgba(0, 0, 0, 0.1), 0px 3.12721px 7.03621px rgba(0, 0, 0, 0.13)',

  flexShrink: 0,
  marginBottom: '8px',

  '.react-pdf__Page__annotations annotationLayer': {
    display: 'none'
  }
}));
