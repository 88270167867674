import {useDispatch, useSelector} from "react-redux";
import {TImportCsvDialogProps} from "../../../../../../../newShared/components/csvImportDialog";
import {dialogsSelector, selectedWorkspace, setImportCsvDialogAction} from "../../../../store/slice";
import {
    getCsvFieldsCollaboratorsAction,
    GetWorkspaceCollabs,
    importCsvTemplateCollaboratorsAction,
    performCsvCollaboratorsAction
} from "../../../../store/actions";
import {resetImportCsvDialog} from "../../../../../../../newShared/components/csvImportDialog/constants";
import {
    useGenericFiltersStorage
} from "../../../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";

export const useImportCsvCollaborators = (): TImportCsvDialogProps => {
    const dispatch = useDispatch();
    // const {wsId} = useParams<{wsId: string}>();
    const selectedWs = useSelector(selectedWorkspace);

    const {importCsvDialog: {
        isOpen,
        isLoading: {
            isGettingFields,
            isPerformingCsv,
            isLoadingTemplate,
        },
        fields,
        fileId,
        response,
    }} = useSelector(dialogsSelector);

    const setImportDialog: TImportCsvDialogProps["setImportDialog"] = (data) => dispatch(setImportCsvDialogAction(data));
    const downloadTemplate = () => {dispatch(importCsvTemplateCollaboratorsAction())};

    const getFields: TImportCsvDialogProps["getFields"] = (file) => {dispatch(getCsvFieldsCollaboratorsAction({
        data: {...file},
        onError: resetImportCsvDialog,
    }))};

    const {clearFilters} = useGenericFiltersStorage();

    const performCsv: TImportCsvDialogProps["performCsv"] = (fields) => {fileId && selectedWs && dispatch(performCsvCollaboratorsAction({
        data: {fields, fileId , workspaceId: selectedWs.id},
        onSuccess: (_, response) => {
            clearFilters();
            dispatch(GetWorkspaceCollabs({workspaceId: selectedWs.id}));
        },
    }))};

    return {
        isOpen,
        isLoadingTemplate, isPerformingCsv, isGettingFields,
        fields,
        setImportDialog,
        downloadTemplate,
        getFields,
        performCsv,
        response,
        fieldsMapperKey: "collaborators",
    }
}
