import {availableAppType} from "../../types";
import * as S from '../styled';
import {useAddIntegrationCard} from "../../hooks/useAddIntegrationCard";
import {Flex} from "../../../../../newShared/components/Layouts";
import {Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import AddIcon from '@mui/icons-material/Add';
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";

export const AvailableAppCard = ({app, onClick}: {app: availableAppType, onClick: (app: availableAppType) => void}) => {
    const {hover, onMouseLeave, onMouseEnter} = useAddIntegrationCard();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathIntegrations.AvailableAppsList'});
    const {isMobile} = useMedia();

    return(
        <S.IntegrationCard onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={() => onClick(app)}>
            {hover ?
                <>
                    <Flex w={'100%'} jc={'center'} maxh={isMobile ? undefined : '90px'} overflow={'auto'}>
                        <Typography color={colors.text.grey_dark} fontSize={'12px'} fontWeight={400}>{app.description}</Typography>
                    </Flex>
                    <Flex w={'100%'} jc={'center'} ai={'center'} m={'2px 0 0 0'}>
                        <AddIcon sx={{color: colors.primary.blue, margin: !revDir ? '0 8px 0 0': '0 0 0 8px'}} />
                        <Typography color={colors.primary.blue} fontSize={'15px'} fontWeight={600}>{t('INSTALL')}</Typography>
                    </Flex>
                </>
                :
                <>
                    <Flex w={'100%'} jc={'center'} style={{marginBlock: 'auto'}}>
                        <img src={app.logo} alt={app.name} style={{maxWidth: '32%', margin: 'auto', width: 'inherit'}}/>
                    </Flex>

                    <Flex w={'100%'} jc={'space-between'} m={'8px 0 0 0'} direction={'column'}>
                        <Flex w={'100%'} jc={'center'}>
                            <Typography
                                color={colors.grayText}
                                fontSize={'16px'}
                                fontWeight={700}
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    lineHeight: 'normal',
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                {app.name}
                            </Typography>
                        </Flex>

                        <Flex w={'100%'} jc={'center'} ai={'center'} m={'8px 0 0 0'}>
                            <AddIcon sx={{color: colors.primary.blue, margin: !revDir ? '0 8px 0 0': '0 0 0 8px'}} />
                            <Typography color={colors.primary.blue} fontSize={'15px'} fontWeight={600}>{t('AVAILABLE')}</Typography>
                        </Flex>
                    </Flex>
                </>
            }
        </S.IntegrationCard>
    )
}