import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideShowPermissions} from "../../../store/slice";

export const usePreviewRoleDialog = () => {
    //root
    const dispatch = useDispatch();
    const {showPermissions} = useSelector(dialogs);

    //actions
    const handleClose = () => {
        dispatch(hideShowPermissions());
    }

    return{
        handleClose,
        ...showPermissions
    }
}