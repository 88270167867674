import {Flex} from "../../../../../../newShared/components/Layouts";
import colors from "../../../../../../newShared/theme/colors";
import {Typography} from "@mui/material";

export const CsvHistoryStatus = ({status}:{status: string}) => {
    switch (status){
        case "UPLOADED": return <Flex p={'6px 14px'} br={'15px'} background={colors.decorative.background_3}>
            <Typography variant={'body2'} color={colors.secondary.green}>{'Uploaded'}</Typography>
        </Flex>;
        case "PROCESSED": return <Flex p={'6px 14px'} br={'15px'} background={colors.decorative.background_3}>
            <Typography variant={'body2'} color={colors.secondary.green}>{'Processed'}</Typography>
        </Flex>;
        case "CANCELED": return <Flex p={'6px 14px'} br={'15px'} background={colors.decorative.background_red}>
            <Typography variant={'body2'} color={colors.text.red}>{'Canceled'}</Typography>
        </Flex>;
        case "IN_PROGRESS": return <Flex p={'6px 14px'} br={'15px'} background={colors.decorative.background_1}>
            <Typography variant={'body2'} color={colors.decorative.violet}>{'In progress'}</Typography>
        </Flex>;
        case "FILE_NOT_EXISTS": return <Flex p={'6px 14px'} br={'15px'} background={colors.decorative.background_red}>
            <Typography variant={'body2'} color={colors.text.red}>{'File not found'}</Typography>
        </Flex>;
        case "FAILED": return <Flex p={'6px 14px'} br={'15px'} background={colors.decorative.background_red}>
            <Typography variant={'body2'} color={colors.text.red}>{'Failed'}</Typography>
        </Flex>;
        default: return <></>;
    }
}