import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useFrameworks} from "../../../hooks/useFrameworks";
import {useSearchFieldURL} from "../../../../../../newShared/hooks/useSearchFieldURL";
import {useEffect} from "react";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    PATH_LOCAL_COMPLIANCE_FRAMEWORKS,
    PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED
} from "../../../../../../newShared/constants";
import {useFilterUrl} from "../../../../../../newShared/hooks/useFilterUrl";
import {
    useGenericFiltersStorage
} from "../../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";

export const useAdoptedFrameworksPage = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    const {adopted: {frameworks, isLoading, getData, cleanArray}} = useFrameworks();
    // const {filter} = useParameters();
    const {clearFilters} = useGenericFiltersStorage();

    //Controller and first loadData
    const controller = new AbortController();
    useEffect(() => {
        getData({})
        return () => {
            controller.abort();
            cleanArray();
            clearFilters();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Breadcrumbs
    useSetBreadcrumbs([
        {
            title: tMenu('Frameworks'),
            path: PATH_LOCAL_COMPLIANCE_FRAMEWORKS
        },
        {
            title: tMenu('Adopted frameworks'),
            path: PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED
        }
    ]);

    //Pagination and filtering
    const {value: searchValue, handleChange} = useSearchFieldURL({wait: 0});
    const {setFilter, handleKeyDown} = useFilterUrl({
        getData: (filters) => {getData(filters, true)}
    });

    const fetchByFilters = (search: string, filters: Record<string, string[]>, signal: AbortSignal) => {
        getData({input: [search]}, true)
    }

    return {
        searchValue, handleChange, handleKeyDown, setFilter,
        frameworks, isLoading,
        fetchByFilters
    }
}