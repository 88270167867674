import * as S from '../components/styled';
import {AccessProvidedChips, AccessProvidedChipsDot} from '../components/styled';
import {Box, Button, Typography} from '@mui/material';
import colors from '../../../../../../newShared/theme/colors';
import {useHistory} from "react-router-dom";
import {SETTINGS_ORGANIZATIONS_CREATE_PATH} from "../../../constants";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import Lines from "../../../../../../newShared/images/login/lines.svg";
import {ReactComponent as CreateOrgIcon} from "../components/img/createOrgIcon.svg";
import {ReactComponent as PortalIcon} from "../components/img/portalIcon.svg";
import React from "react";
import {
    IS_REGULAIT,
    LABEL,
    PATH_ARI,
    PORTAL_PATH_DEV,
    PORTAL_PATH_PROD,
    PORTAL_PATH_PROD_BAHRAIN,
    PORTAL_PATH_PROD_CA,
    PORTAL_PATH_STAGING
} from "../../../../../../newShared/constants";
import {useSelector} from "react-redux";
import {forbiddenWrapper} from "../../../store/slice";
import {Flex} from '../../../../../../newShared/components/Layouts';

export const WelcomeOnBoardPage = () => {
    const history = useHistory();
    const {portalAccess} = useSelector(forbiddenWrapper);
    const handleStart = () => history.push(SETTINGS_ORGANIZATIONS_CREATE_PATH);

    const handleGoToPortal = () => {
        if(IS_REGULAIT){
            if (PATH_ARI && PATH_ARI.startsWith('https://dev')) {
                window.open(PORTAL_PATH_DEV, "_blank")?.focus()

            } else if (PATH_ARI && PATH_ARI.startsWith('https://staging')) {
                window.open(PORTAL_PATH_STAGING, "_blank")?.focus()

            } else if (PATH_ARI && PATH_ARI.startsWith('https://app')) {
                window.open(PORTAL_PATH_PROD, "_blank")?.focus()

            } else if (PATH_ARI && PATH_ARI.startsWith('https://ca')) {
                window.open(PORTAL_PATH_PROD_CA, "_blank")?.focus()

            } else {
                //localhost
                window.open(PORTAL_PATH_DEV, "_blank")?.focus()
            }
        }else{
            window.open(PORTAL_PATH_PROD_BAHRAIN, "_blank")?.focus()
        }
    }

    const {isTablet, isMobile} = useMedia();
    const {t} = useMainTranslation('', {keyPrefix: 'pathWelcomeAboard'});

    return(
        <Box display={"flex"} alignItems={"center"} flexDirection={"column"} overflow={'hidden'}
             position={"relative"} width={'100%'} height={'100%'}
             sx={{
                 background: 'linear-gradient(107.56deg, #FFFFFF 0%, #F0F6FB 54.69%, #FFFFFF 100%)',
             }}>

            <img src={Lines} alt={'background'} style={{
                position: 'absolute',  top: '100px', zIndex: 0,
                ...(document.body.offsetWidth > document.body.offsetHeight ? {width: '100vw'} : {height: '100%', left: '0'}),
            }}/>

            <S.TextWrapper>
                <Typography variant={'*h1'} color={colors.grayText}
                >{t('Welcome on board!')} &#128075;</Typography>

                {portalAccess &&
                    <S.WelcomeOnBoardCard>
                        <Flex jc={'space-between'} ai={'center'}>
                            <PortalIcon />

                            <AccessProvidedChips>
                                <AccessProvidedChipsDot />
                                <Typography variant={'*bodyText2'}>{'Access provided'}</Typography>
                            </AccessProvidedChips>
                        </Flex>

                        <Typography color={colors.primary.blue_dark} variant={'*h3'} margin={'22px 0 0 0'}>
                            {LABEL} Portal
                        </Typography>

                        <Typography color={colors.grayText} variant={'*bodyText2'} margin={'10px 0 0 0'}>
                            A centralized hub where employees can access company-specific information, policies, training, self-service HR resources and stay up-to-date on updates.
                        </Typography>

                        <Box>
                            <Button
                                onClick={handleGoToPortal}
                                size={isTablet ? 'medium' : (isMobile ? 'small' : 'large')}
                                sx={{margin: '22px 0 0 0'}}
                            >Proceed to {LABEL} Portal</Button>
                        </Box>
                    </S.WelcomeOnBoardCard>
                }

                <S.WelcomeOnBoardCard>
                    <CreateOrgIcon />

                    <Typography color={colors.primary.blue_dark} variant={'*h3'} margin={'22px 0 0 0'}
                    >Create your organization to discover the powerful features provided by {LABEL}.
                    </Typography>

                    <Typography
                        color={colors.grayText} variant={'*bodyText2'}
                        margin={'10px 0 0 0'}
                    >We recommend to start with the short onboarding.
                    </Typography>

                    <Box>
                        <Button
                            onClick={handleStart}
                            variant={'outlined'}
                            size={isTablet ? 'medium' : (isMobile ? 'small' : 'large')}
                            sx={{margin: '22px 0 0 0'}}
                        >{t('Start onboarding')}</Button>
                    </Box>
                </S.WelcomeOnBoardCard>
            </S.TextWrapper>
        </Box>
    )
}