import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideDiscardChangesDialog, loadings} from "../../../store/slice";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import React from "react";
import {ExactEmployeeTabs} from "../../../types";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

type DiscardGeneralInfoChangesDialogPropsType = {
    handleGoToNextTab: (tab: ExactEmployeeTabs) => void;
}

export const DiscardGeneralInfoChangesDialog = ({handleGoToNextTab}: DiscardGeneralInfoChangesDialogPropsType) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathHRManagement.dialogs'});
    const {tCommon} = useMainTranslation();

    const dispatch = useDispatch();
    //sel
    const {isOpen, nextTab} = useSelector(dialogs).discardUpdateGeneralInfoChanges;
    const isLoading = useSelector(loadings).updateEmployee;

    const handleClose = () => {
        dispatch(hideDiscardChangesDialog())
    }

    const handleSubmit = () => {
        handleGoToNextTab(nextTab);
        handleClose();
    }

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Discard or not discard')}</DialogTitle>

            <DialogContent>
                <DialogContentText>
                    <Typography  sx={{lineHeight: 'normal'}}>{t(`You have made changes in general info of this employee. Discard changes?`)}</Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions sx={{gap: '16px'}}>
                <Button onClick={handleClose} variant={'text'}>{tCommon('Cancel')}</Button>
                <LoadingButton onClick={handleSubmit} loading={isLoading} color={'error'}>
                    {tCommon('Discard')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}