import colors from "../../../newShared/theme/colors";
import {IN_PROGRESS, OPEN, READY_TO_SUBMIT} from "./constants";

export {}

export const getEvidenceStatus = (t: (str: string) => string, status: string): {text: string, color: string} => {
    switch (status) {
        case OPEN:
            return {text: t('Open'), color: colors.text.blue};
        case IN_PROGRESS:
            return {text: t('In progress'), color: colors.yellow};
        case READY_TO_SUBMIT:
            return {text: t('Ready to submit'), color: colors.secondary.green};
        default:
            return {text: status, color: 'black'};
    }
};