import {GenericTable} from "../../../../../newShared/components/genericTable";
import {useTrainingsList} from "../../hooks/useTrainingsList";
import {Flex, PageWrapper} from "../../../../../newShared/components/Layouts";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import {MainTrainingModel} from "../../../../../newShared/GQLTypes";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {getTrainingStatusChip} from "../../helpers";
import {trainingLevelTitleMapper} from "../../constants";
import {CoverImageView} from "../../components/trainingCoverComponent/CoverImageView";
import {Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";

export const TrainingsList = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.pathTrainings'});
    const {isMobile} = useMedia();

    const {
        createTraining,
        trainings,
        genericTable,
        handleOpenTraining,
    } = useTrainingsList();

    return (
        <PageWrapper>
            <PageTitleAndActions
                title={t('Trainings')}
                actions={[
                    {
                        title: t('New training'),
                        onClick: createTraining,
                        icon: <AddIcon />,
                        variant: 'contained',
                        size: 'small',
                    },
                ]}

            />
            <GenericTable<MainTrainingModel>
                id={'trainingsList'}
                columnsConfig={{
                    totalName: t('Total trainings'),
                    forceShowCardsWhen: isMobile,
                    disableColumnsSettings: true,
                }}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: trainings,
                    onRowClick: handleOpenTraining,
                    customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id,
                    columnsConfigs: [
                        {key: 'trainingName', name: t('Training name'), default: true, valueRenderer: row => <Flex ai={'center'} gap={'32px'}>
                                {row.coverImage && <CoverImageView cover={row.coverImage} size={'list'}/>}
                                <Typography variant={'*bodyText2'} color={colors.grayText}>{row.name}</Typography>
                            </Flex>},
                        {key: 'category', name: t('Category'), default: true, valueGetter: row => row.category},
                        {key: 'level', name: t('Level'), default: true, valueGetter: row => trainingLevelTitleMapper[row.level]},
                        {key: 'status', name: t('Status'), default: true, valueRenderer: row => getTrainingStatusChip(row.status, false)},
                        {key: 'createdDate', name: t('Created date'), default: true, valueGetter: row => parseDateAuto(row.createdDate ?? '-', undefined, undefined, undefined, '-')},
                        {key: 'lastUpdate', name: t('Last update'), default: true, valueGetter: row => !row.updatedDate ? '-' : parseDateAuto(row.updatedDate ?? '-', undefined, undefined, undefined, '-')}
                    ]
                }}
                filtersConfig={{
                    dontSendSearchIfEmpty: true,
                    searchCustomFilterKeyName: "trainingNameLike",
                    genericFilterProps: {
                        configs: genericTable.filters.configs,
                        isAddFilters: false,
                        collapsable: false,
                        fetchResultWithSelectedFilters: () => null,
                    }
                }}
                emptyArrayImageProps={{ type: 'any', filterNotApplied: {text: t('You do not have any trainings yet'), secondaryText:''}}}
            />


        </PageWrapper>
    )
}
