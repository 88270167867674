import {useDispatch, useSelector} from "react-redux";
import {
    assignmentsSelector,
    autocompleteStorage,
    dialogs,
    loadings,
    setCreateAssignmentAction
} from "../../../store/slice";
import {useEffect, useState} from "react";
import * as yup from 'yup';

import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    mainTrainingsCreateTrainingAssignmentAction,
    mainTrainingsGetAssignmentsWithFilterPaginationAction,
    mainTrainingsGetExamsAutocompleteAction,
    mainTrainingsGetTrainingsAutocompleteAction
} from "../../../store/actions";
import {MainCreateTrainingAssignment} from "../../../types";
import {
    MainCreateTrainingAssignmentPostExamInput,
    MainTrainingsGetExamsAutocomplete
} from "../../../../../../newShared/GQLTypes";
import {DEFAULT_DATE_FORMAT, parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {
    useGenericFiltersStorage
} from "../../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {defaultPageInfo} from "../../../constants";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useCustomFormik} from "../../../../../../newShared/hooks/useCustomFormik";


export const useCreateAssignment = () => {
    //root
    const {t} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.dialogs'});
    const dispatch = useDispatch();

    const {createAssignment: {isOpen, training, exam}} = useSelector(dialogs);
    const {pageInfo: {count}} = useSelector(assignmentsSelector);
    const {assignmentCreate: isLoadingCreate, getExamsAutocomplete: getExamsLoading, getTrainingsAutocomplete: getTrainingsLoading} = useSelector(loadings);
    const {exams, trainings} = useSelector(autocompleteStorage);
    const {setMessage} = useMessageDialog();

    const [isFirstPart, setFirstPart] = useState<boolean>(true);
    const [selectedExam, setSelectedExam] = useState<MainTrainingsGetExamsAutocomplete | null>(null);

    const {currentFiltersForFetch, currentSearchForFetch, getCurrentPage} = useGenericFiltersStorage();

    //actions
    const handleClose = () => {
        dispatch(setCreateAssignmentAction({clear: true}))
    }

    const handleNext = () => {
        setFirstPart(false);
    }

    const handleBack  = () => {
        setFirstPart(true);
    }

    const validationSchema = yup.object({
        recipient: yup.object({
            collaboratorId: yup.string()
                .required(),
            fullName: yup.string()
                .required(),
            email: yup.string()
                .required(),
        })
            .nullable()
            .required(t('Recipient field is required')),

        startDate: yup.date()
            .typeError(`${t('{{dateFormat}} date format required', {dateFormat: DEFAULT_DATE_FORMAT})}`)
            .nullable()
            .required(t('Start date is required')),
        endDate: yup.date()
            .typeError(`${t('{{dateFormat}} date format required', {dateFormat: DEFAULT_DATE_FORMAT})}`)
            .nullable()
            .min(
                yup.ref('startDate'),
                t('End date must be later or equal to Start date')
            ),
        training: yup.object({
            trainingId: yup.string(),
            limitMaxDuration: yup.number(),
            limitMinDuration: yup.number(),
        })
            .nullable()
            .test('training', function (value) {
                const form = this.options.context as MainCreateTrainingAssignment | undefined;
                return Boolean(value?.trainingId?.length || form?.postExam?.examId?.length)
            }),
        postExam: yup.object({
            examId: yup.string(),
            timeToPass: yup.number(),
            totalPoints: yup.number(),
            examPassingPoints: yup.number()
                .max(
                    yup.ref('totalPoints'),
                    t('Total points should be less than passing points.')
                ),
        })
            .test('postExam', function (value) {
                const form = this.options.context as MainCreateTrainingAssignment | undefined;
                return Boolean(value?.examId?.length || form?.training?.trainingId?.length)
            })
            .nullable()
    })

    const formik = useCustomFormik<Omit<MainCreateTrainingAssignment, 'startDate' | 'endDate' | 'postExam'> & {startDate: Date | null, endDate: Date | null, postExam: (MainCreateTrainingAssignmentPostExamInput & {totalPoints: number} | null)}>(isOpen, {
        initialValues: {
            recipient: null,
            startDate: null,
            endDate: null,
            training: {trainingId: '', limitMinDuration: 0, limitMaxDuration: 0},
            postExam: {examId: '', examPassingPoints: 1, timeToPass: 0, totalPoints: 1}
        },
        validationSchema,
        onSubmit: (values) => {
            if (values.recipient && (values.postExam?.examId.length || values.training?.trainingId.length)) {
                dispatch(mainTrainingsCreateTrainingAssignmentAction({
                    data: {data: {
                            ...values,
                            recipient: values.recipient,
                            startDate: values.startDate?.toISOString() ? parseDateAuto(values.startDate?.toISOString(), false) + 'T00:00:00' : '',
                            endDate: values.endDate?.toISOString() ? parseDateAuto(values.endDate?.toISOString(), false) + 'T00:00:00' : undefined,
                            training: values.training?.trainingId.length ? values.training : null,
                            postExam: values.postExam?.examId.length ? {examId: values.postExam.examId, examPassingPoints: values.postExam.examPassingPoints, timeToPass: values.postExam.timeToPass} : null,
                        }},
                    onSuccess: (request, response, addictiveData) => {
                        setMessage({title: 'Completed successfully', message: `Assignment has been created with id ${response.id}.`});
                        dispatch(mainTrainingsGetAssignmentsWithFilterPaginationAction({data: {pageRequest: {page: getCurrentPage() ?? 0, count: count || defaultPageInfo.count}, filter: {...currentFiltersForFetch, assignmentIdLike: currentSearchForFetch}}, clean: true }))
                    },
                    onError: (request, error) => {
                        const e409 = error.e409?.[0];
                        const e404 = error.e404?.[0];

                        if (e409?.type === 'DUPLICATE') {
                            setMessage({title: t('Action conflict error'), message: t(`{{fullName}} already has an assignment like this.`, {fullName: request.data.recipient.fullName})});
                            return;
                        }
                        if (e409?.type === 'NO_PORTAL_ACCESS') {
                            setMessage({title: t('Action conflict error'), message: t(`{{fullName}} does not have access to portal.`, {fullName: request.data.recipient.fullName})});
                            return;
                        }
                        if (e409?.type === 'NOT_COLLABORATOR') {
                            setMessage({title: t('Action conflict error'), message: t(`{{fullName}} has been removed from system.`, {fullName: request.data.recipient.fullName})});
                            return;
                        }

                        if (e404) {
                            setMessage({title: t('Error'), message: e404});
                            return;
                        }
                    },
                }))
            }
        },
    })


    useEffect(() => {
        if (isOpen) {
            setFirstPart(true);
            if(exam) {
                setSelectedExam({category: exam.category, examId: exam.examId, examPassingPoints: exam.examPassingPoints, level: exam.level, name: exam.name, timeToPass: exam.timeToPass, totalPoints: exam.totalPoints});
                formik.setFieldValue('postExam',  {examId: exam.examId, totalPoints: exam.totalPoints, examPassingPoints: exam.examPassingPoints, timeToPass: exam.timeToPass})
            }
            else setSelectedExam(null);

            if (training) formik.setFieldValue('training',{trainingId: training.trainingId, limitMinDuration: training.limitMinDuration, limitMaxDuration: training.limitMaxDuration})
        }
        !isOpen && formik.resetForm();
        formik.validateForm();



        //eslint-disable-next-line
    }, [isOpen]);

    const handleChangeMinTrainingDuration = (value: number) => {
        formik.setFieldValue('training.limitMinDuration', value <= 0 ? 1 : value);
    }

    const handleChangeMaxExamDuration = (value: number) => {
        formik.setFieldValue('exam.timeToPass', value <= 0 ? 1 : value);
    }


    const onLimitMinimumTrainingDurationCheckboxClick = () => {
        formik.setFieldValue('training.limitMinDuration', formik.values.training?.limitMinDuration === 0  ? 1 : 0);
    }
    const onLimitMaximumExamDurationCheckboxClick = () => {
        formik.setFieldValue('postExam.timeToPass', formik.values.postExam?.timeToPass === 0 ? 1 : 0);
    }

    const getExamsData = () => {
        dispatch(mainTrainingsGetExamsAutocompleteAction({data: {pageRequest: {page: 0, count: 9999999}, value: '', includeArchive: true}}))
    }

    const getTrainingsData = () => {
        dispatch(mainTrainingsGetTrainingsAutocompleteAction({data: {pageRequest: {page: 0, count: 9999999}, value: '', includeArchive: true}}))
    }


    return{

        isOpen,
        formik,
        fromPage: {
            exam: !exam ? null : {category: exam.category, examId: exam.examId, examPassingPoints: exam.examPassingPoints, level: exam.level, name: exam.name, timeToPass: exam.timeToPass, totalPoints: exam.totalPoints,},
            training: !training ? null : {category: training.category, level: training.level, limitMinDuration: training.limitMinDuration, name: training.name, trainingId: training.trainingId},
        },
        loadings: {
            isLoadingCreate,
            getExamsLoading,
            getTrainingsLoading,
        },
        isFirstPart,
        handleNext,
        handleBack,
        handleClose,
        selectedExam,
        setSelectedExam,
        autocomplete: {
            exams,
            trainings,
            getExamsData,
            getTrainingsData,
        },
        limitDuration: {
            handleChangeMinTrainingDuration,
            handleChangeMaxExamDuration,
            onLimitMinimumTrainingDurationCheckboxClick,
            onLimitMaximumExamDurationCheckboxClick,
        },
        examDuration: {
            onLimitMaximumExamDurationCheckboxClick: () => {
                formik.setFieldValue('limitMinDuration', formik.values.training?.limitMinDuration === 0  ? 1 : 0);
            }
        }

    }
}
