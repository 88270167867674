import {useReports} from "../../../hooks/useReports";
import {useReport} from "../../../hooks/useReport";
import {useEffect, useState} from "react";
import {TTab} from "../../../../../../newShared/types";
import {useReportsParams} from "../../../hooks/useReportsParams";

export const useControlViewPage = () => {
    const {
        goToReport,
    } = useReports();
    const {
        report: {report}, controls: {selectedControl},
        isLoading: {isLoadingControl},
    } = useReport();
    const {controlId} = useReportsParams();

    //Other logic
    const [tab, setTab] = useState('Evidences');
    const tabs: TTab[] = [
        {
            title: 'NCR',
            handleClick: () => {setTab('NCR')},
            hide: report ? !report.ncrEnabled : false,
        },
        {
            title: 'Evidences',
            handleClick: () => {setTab('Evidences')}
        },
        {
            title: 'Description',
            handleClick: () => {setTab('Description')}
        },
    ]

    useEffect(() => {
        if (selectedControl?.id !== controlId) setTab('Evidences')
        else selectedControl?.ncr.some(e => e.status) && setTab('NCR')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controlId, selectedControl?.ncr.some(e => e.status)]);

    return {
        report, selectedControl,
        isLoadingControl,
        goToReport,
        tabs, tab,
    }
}