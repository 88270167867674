import React from "react";
import {ReleaseDialog} from "../../../../../../newShared/components/releaseDialog";
import {useReleaseEmployees} from "../../../hooks/useReleaseEmployees";

export const ReleaseEmployees = () => {
    const {
        isOpen,
        handleClose,
        isLoadingRelease,
        handleRelease,
    } = useReleaseEmployees();

    return (
        <ReleaseDialog isOpen={isOpen} handleClose={handleClose} isSubmittingLoading={isLoadingRelease}
                       handleSubmit={({evidence, review, sign, read}) => {
                           handleRelease({
                               ...evidence,
                               readers: read,
                               reviewers: review,
                               signers: sign
                           })
                       }}
        />
    )
}