import React from "react";
import {useCreateClient} from "../../../hooks/dialogs/useCreateClient";
import * as S from './styled';
import {
    Autocomplete,
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TypographyBody2} from "../../../../../../newShared/components/Inputs/styled";
import {CommonRadio} from "../../../../../../newShared/components/Basic/CommonRadio";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import colors from "../../../../../../newShared/theme/colors";
import {InputClientDataDropZone} from "../inputClientDataTemplate/components/InputClientDataDropZone";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {TobBarLogo} from "../../../../../../newShared/components/TobBarLogo";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {TemplateTypeChip} from "../../templateTypeChip";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {getKycDictionary} from "../../../helpers";
import {KycExactDictType} from "../../../types";
import {DEFAULT_DATE_FORMAT} from "../../../../../../newShared/utils/dateTools";


export const CreateClientDialog = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.dialogs'});


    const {
        isOpen,
        isOpenCsv,
        selectedTemplate,
        isLoadingTemplatesList,
        templatesList,
        handleSelectTemplate,
        selectTemplatePage,
        handleUseTemplate,
        handleBack,
        handleClose,
        form,
        handleChangeCustomField,
        handleChangeDate,
        isOk,
        handleFileUpload,
        isLoading,
        handleSubmit,
        handleImportCsv,
        handleDeleteUploadedFile,
        dicts,
        handleChangeSelectField
    } = useCreateClient();


    return (
        <S.CreateClientDialog open={isOpen && !isOpenCsv} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{t('New client')}</DialogTitle>
            <DialogContent>
                {selectTemplatePage ?
                    <>
                        <TypographyBody2>{t('Add client using one of the following template:')}</TypographyBody2>
                        {templatesList.map(template => (
                            <S.TemplateBox key={template.id} isSelected={selectedTemplate?.id === template.id ? true : undefined} onClick={() => handleSelectTemplate(template)}>
                                <S.TitleBox>
                                    <CommonRadio checked={selectedTemplate?.id === template.id} disabled={isLoadingTemplatesList} />
                                    <TypographyBody2>{template.name}</TypographyBody2>
                                </S.TitleBox>

                                <TemplateTypeChip type={template.type} />
                            </S.TemplateBox>
                        ))}
                    </>
                    :
                    <S.PreviewTemplateContainer>
                        <TobBarLogo />
                        <Typography variant={'h3'} fontWeight={700}>{t('KYC Verification request')}</Typography>
                        {selectedTemplate && selectedTemplate.fields
                            .slice()
                            .sort((a, b) => a.order - b.order)
                            .map(e => {
                                const currentField = form.fields.find(f => f.id === e.id);
                                const currentDict = getKycDictionary(dicts, e.dictionary);
                                switch (e.type){
                                case 'TEXT': {
                                    return <TextField
                                        required={e.required}
                                        label={e.name}
                                        size={'small'}
                                        name={e.id}
                                        value={currentField?.value ?? ''}
                                        onChange={handleChangeCustomField}
                                    />
                                }
                                case 'NUMBER': {
                                    return <TextField
                                        type={'number'}
                                        required={e.required}
                                        label={e.name}
                                        size={'small'}
                                        name={e.id}
                                        value={currentField?.value ?? ''}
                                        onChange={handleChangeCustomField}
                                    />
                                }
                                case 'DATE': {
                                    return <DatePicker
                                        label={e.name}
                                        inputFormat={DEFAULT_DATE_FORMAT}
                                        value={currentField?.value ?? null}
                                        onChange={(newValue) => handleChangeDate(e.id, newValue)}
                                        renderInput={(params) => <TextField {...params} error={false} required={e.required} size={'small'} name={e.id}/>}
                                    />
                                }
                                case 'FILE': {
                                    return <S.FileDropContainer>
                                        <Typography fontSize={'12px'} fontWeight={600}>
                                            {e.name}
                                            {e.required && <span style={{color: colors.warningActions.red}}>*</span>}
                                        </Typography>

                                        <InputClientDataDropZone
                                            onFileUploaded={handleFileUpload}
                                            isLoadingCreateTarget={isLoading}
                                            handleDelete={() => handleDeleteUploadedFile(e.id)}
                                            id={e.id}
                                        />
                                    </S.FileDropContainer>
                                }
                                    case 'SELECT': {
                                        return <Autocomplete
                                            key={e.id}
                                            id="combo-box-demo"
                                            options={currentDict.slice().sort((a, b) => a.order - b.order)}
                                            value={currentDict.find(f => f.key === currentField?.value)}
                                            isOptionEqualToValue={(a: KycExactDictType, b:  KycExactDictType | undefined) => b !== undefined && a.key === b.key}
                                            getOptionLabel={(e) => e.value}
                                            onChange={(event, value) => handleChangeSelectField(e.id, value)}
                                            sx={{width: '100%'}}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label={e.name}
                                                required={e.required}
                                                fullWidth
                                                error={false}
                                                size={'small'}
                                                name={e.id}
                                                // sx={{'& fieldset': {borderColor: 'black !important'}}}
                                            />}
                                        />
                                    }
                                default: return <></>;
                            }
                        })}

                    </S.PreviewTemplateContainer>
                }

            </DialogContent>
            <DialogActions sx={{display: 'flex', justifyContent: 'space-between'}}>

                <Box sx={{display: 'flex'}}>
                    {!selectTemplatePage && <Button onClick={handleBack} size={'small'} variant={'text'} startIcon={<ArrowBackIosIcon style={{fontSize: 'small'}} />}
                                                    sx={{textTransform: 'none'}}>{tCommon('Back')}</Button>
                    }

                    <Button onClick={handleClose} size={'small'} variant={'text'} sx={{textTransform: 'none', m: '0 8px'}}>{tCommon('Cancel')}</Button>
                </Box>

                {selectTemplatePage ?
                    <Button size={'small'} disabled={!selectedTemplate} onClick={handleUseTemplate} sx={{ml: 0}}>{t('Use template')}</Button>
                    :
                    <Box sx={{display: 'flex'}}>
                        <Button startIcon={<FileUploadIcon />} variant={'text'} size={'small'} sx={{textTransform: 'none', mr: '10px', ml: '10px'}} onClick={handleImportCsv} >{'Import CSV'}</Button>
                        <LoadingButton size={'small'} onClick={handleSubmit} sx={{ml: 0}} loading={isLoading} disabled={!isOk()}>{t('Create client')}</LoadingButton>
                    </Box>
                }
            </DialogActions>
        </S.CreateClientDialog>
    )
}
