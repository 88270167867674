import {FC, Fragment} from "react";
import {Box, Divider, Typography} from "@mui/material";

type TSolutionType = {
    step: string,
    description: string,
    subSteps: TSolutionType[] | null
}

type TProps = {
    solutions: TSolutionType[],
    recNum?: number,
    width?: string,
}
export const SolutionTree: FC<TProps> = ({solutions, recNum = 0, width = '100%'}) => {

    return (
        <Box display={'flex'} flexDirection={'column'} width={width}>
            {solutions.map(({step, description, subSteps}, index) => (
                <Fragment key={JSON.stringify(step + description + subSteps?.toString())}>
                    <Box display={"flex"} margin={index !== 0 && recNum === 0 ? '24px 0 0 0' : undefined}>
                        <Box display={"flex"}>
                            <Typography variant={"body2"} m={'0 12px 0 0'}>{step}</Typography>
                            <Typography variant={"body2"} component={"span"}>{description}</Typography>
                        </Box>

                        {subSteps && subSteps.length > 0 && (
                            <Box display={"flex"} pl={20*recNum + 'px'}>
                                <SolutionTree solutions={subSteps} recNum={recNum + 1}/>
                            </Box>
                        )}
                    </Box>

                    <Divider flexItem sx={{marginTop: '8px', marginBottom: '24px'}}/>
                </Fragment>
            ))}
        </Box>
    )
}