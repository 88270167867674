import {useTemplatesList} from "../../../hooks/templates/useTemplatesList";
import {PageContainer, TemplateCardsContained} from "../../../components/styled/index";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";
import AddIcon from '@mui/icons-material/Add';
import {TemplateCard} from "../../../components/templateCard";
import {TemplateCardSkeleton} from "../../../components/templateCard/skeleton";
import {TemplatesImportCsvDialog} from "../../../components/dialogs/templatesImportCsv";
import {PreviewTemplateDialog} from "../../../components/dialogs/previewTemplate";
import {InputClientDataTemplateDialog} from "../../../components/dialogs/inputClientDataTemplate";
import {SendToClientDialog} from "../../../components/dialogs/sendToClientDialog";
import {EmptyArrayImage} from "../../../../../../newShared/components/EmptyArrayImage";
import React from "react";
import {DeleteTemplateDialog} from "../../../components/dialogs/deleteTemplateDialog";
import {OneTimeScreeningDialog} from "../../../components/dialogs/oneTimeScreening";
import {ShowClientDetailDialog} from "../../../components/dialogs/showClientDetail";
import {ShowFullReportDialog} from "../../../components/dialogs/showFullReportDialog";
import {ExportCsvDialog} from "../../../../../../newShared/components/csvExportDialog";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const TemplatesList = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.templates'});
    const {
        templates,
        handleGoToCreateTemplate,
        scroll,
        isLoading,
        exportCsv,
        isLoadingExport,
        isLoadingFields,
        isNoTemplatesFound,
    } = useTemplatesList();

    return(
        <PageContainer>
            <PageTitleAndActions
                title={t('Templates')}
                actions={[
                    {
                        title: t('Custom template'),
                        icon: <AddIcon />,
                        onClick: handleGoToCreateTemplate,
                        variant: 'contained',
                        size: 'small'
                    }
                ]}
            />

            { !isNoTemplatesFound &&
                <TemplateCardsContained onScroll={scroll.onScroll} ref={scroll.scrollRef}>
                    {templates.map(e => (<TemplateCard template={e} key={e.id}/>))}
                    {(scroll.hasMore || isLoading) && <TemplateCardSkeleton/>}
                </TemplateCardsContained>
            }

            <EmptyArrayImage
                type={'kycTemplates'}
                isShowing={isNoTemplatesFound}
                text={t('No templates found.')}
                withoutAdd
                secondaryText={t('You have not added any template yet. Create new one')}
                onClick={handleGoToCreateTemplate}
            />

            <ExportCsvDialog
                isOpen={exportCsv.isOpen}
                isLoadingFields={isLoadingFields}
                isLoadingExport={isLoadingExport}
                availableFields={exportCsv.availableFields}
                handleExportCsv={exportCsv.handleExportCsv}
                handleClose={exportCsv.handleClose}
                fieldsMapperKey={"kyc"}
            />

            <TemplatesImportCsvDialog />
            <PreviewTemplateDialog />
            <InputClientDataTemplateDialog />
            <OneTimeScreeningDialog />
            <ShowClientDetailDialog />
            <ShowFullReportDialog />
            <SendToClientDialog />
            <DeleteTemplateDialog />
        </PageContainer>
    )
}
