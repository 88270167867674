import {
    MainVicaGetDelegatedTaskPerformerAutocompleteQuery,
    MainVicaGetDelegatedTaskPerformerAutocompleteQueryVariables,
    MainVicaGetDelegatedTaskRequestorAutocompleteQuery,
    MainVicaGetDelegatedTaskRequestorAutocompleteQueryVariables,
    MainVicaGetDelegatedTaskResolverAutocompleteQuery,
    MainVicaGetDelegatedTaskResolverAutocompleteQueryVariables,
    MainVicaGetStepResponsibleCollaboratorsAutocompleteQuery,
    MainVicaGetStepResponsibleCollaboratorsAutocompleteQueryVariables,
    UploadEvidenceAssetMutation,
    UploadEvidenceAssetMutationVariables
} from "../../../../newShared/GQLTypes";
import axiosSync from "../../../../newShared/utils/axios/axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {print} from "graphql";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {AxiosResponse} from "axios";
import {
    mainVicaGetDelegatedTaskPerformerAutocomplete,
    mainVicaGetDelegatedTaskRequestorAutocomplete,
    mainVicaGetDelegatedTaskResolverAutocomplete,
    mainVicaGetStepResponsibleCollaboratorsAutocomplete,
    uploadEvidenceAsset
} from "./query";


export const mainVicaGetStepResponsibleCollaboratorsAutocompleteApi = async (data: MainVicaGetStepResponsibleCollaboratorsAutocompleteQueryVariables, signal?: AbortSignal): Promise<MainVicaGetStepResponsibleCollaboratorsAutocompleteQuery["mainVicaGetStepResponsibleCollaboratorsAutocomplete"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(mainVicaGetStepResponsibleCollaboratorsAutocomplete),
                variables: data,
            },
            {
                headers: buildCommonHeader(),
                // signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.mainVicaGetStepResponsibleCollaboratorsAutocomplete;
    })
)

export const uploadEvidenceAssetApi = async (data: UploadEvidenceAssetMutationVariables, signal?: AbortSignal): Promise<UploadEvidenceAssetMutation["uploadEvidenceAsset"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(uploadEvidenceAsset),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.uploadEvidenceAsset
    })
)


export const mainVicaGetDelegatedTaskPerformerAutocompleteApi = async (data: MainVicaGetDelegatedTaskPerformerAutocompleteQueryVariables, signal?: AbortSignal): Promise<MainVicaGetDelegatedTaskPerformerAutocompleteQuery["mainVicaGetDelegatedTaskPerformerAutocomplete"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(mainVicaGetDelegatedTaskPerformerAutocomplete),
                variables: data,
            },
            {
                headers: buildCommonHeader(),
                // signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.mainVicaGetDelegatedTaskPerformerAutocomplete;
    })
)

export const mainVicaGetDelegatedTaskResolverAutocompleteApi = async (data: MainVicaGetDelegatedTaskResolverAutocompleteQueryVariables, signal?: AbortSignal): Promise<MainVicaGetDelegatedTaskResolverAutocompleteQuery["mainVicaGetDelegatedTaskResolverAutocomplete"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(mainVicaGetDelegatedTaskResolverAutocomplete),
                variables: data,
            },
            {
                headers: buildCommonHeader(),
                // signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.mainVicaGetDelegatedTaskResolverAutocomplete;
    })
)

export const mainVicaGetDelegatedTaskRequestorAutocompleteApi = async (data: MainVicaGetDelegatedTaskRequestorAutocompleteQueryVariables, signal?: AbortSignal): Promise<MainVicaGetDelegatedTaskRequestorAutocompleteQuery["mainVicaGetDelegatedTaskRequestorAutocomplete"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(mainVicaGetDelegatedTaskRequestorAutocomplete),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                // signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.mainVicaGetDelegatedTaskRequestorAutocomplete;
    })
)
