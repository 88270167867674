import {useDispatch, useSelector} from "react-redux";
import {
    campaignReviewInstructions,
    campaignReviewInstructionsMinMaxLoadedPage,
    campaignReviewInstructionsPageInfo,
    clearCampaignReviewInstructions,
    loadings,
    replaceMinMaxLoadedPageInstruction
} from "../../../../store/slice";
import {useHistory} from "react-router-dom";
import {
    PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_EXACT,
    PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_LIST,
    PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_NEW,
    PATH_LOCAL_ACCESS_MANAGEMENT
} from "../../../../constants";
import {useSetBreadcrumbs} from "../../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {GetCampaignReviewInstructionWithPaging} from "../../../../store/actions";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../../../newShared/components/genericTable/types";
import {campaignReviewInstructionType} from "../../../../types";

export const useCampaignReviewInstructionsList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    //selectors
    const instructions = useSelector(campaignReviewInstructions);
    const pageInfo = useSelector(campaignReviewInstructionsPageInfo);
    const {campaignReviewInstructionsList} = useSelector(loadings);
    const {minLoadedPage, maxLoadedPage} = useSelector(campaignReviewInstructionsMinMaxLoadedPage)

    // const controller = new AbortController();
    //useEffects
    useSetBreadcrumbs([
        {
            title: tMenu('Access management'),
            path: PATH_LOCAL_ACCESS_MANAGEMENT
        },
        {
            title: tMenu('Review campaign config'),
            path: PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_LIST
        }
    ]);

    // useEffect(() => {
    //     return () => {
    //
    //     }
    //     //eslint-disable-next-line
    // }, []);
    // //actions
    const handleGoToCreateInstruction = () => {
        history.push(PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_NEW);
    }

    // const handleLoadMore = (page: number) => {
    //     !campaignReviewInstructionsList && dispatch(GetCampaignReviewInstructionWithPaging({data: {page: pageInfo.page + 1, count: DEFAULT_CAMPAIGN_REVIEW_INSTRUCTIONS_PAGING}, signal: controller.signal}));
    // }
    return{
        actions: {
            handleGoToCreateInstruction,
            // handleLoadMore
        },
        selectors: {
            instructions,
            pageInfo,
            isLoadingList: campaignReviewInstructionsList,
            hasMore: pageInfo.total > ((pageInfo.page + 1) * pageInfo.count),
            isNoInstructionsFound: campaignReviewInstructionsList ? false : !instructions.length,
        },
        genericTable: {
            paging: {
                pageInfo,
                isLoading: campaignReviewInstructionsList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceMinMaxLoadedPageInstruction(data)),
                clearRows: () => dispatch(clearCampaignReviewInstructions()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    dispatch(GetCampaignReviewInstructionWithPaging({data: {page, count}}));
                },
            },
            onRowClick: (row: campaignReviewInstructionType) => {
                history.push(PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_EXACT.replace(':id', row.id))
            }
        }
    }
}