import React, {FC} from "react";
import AddIcon from "@mui/icons-material/Add";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";
import {useKycClientsList} from "../../../hooks/clients/useKycClientsList";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {CreateClientDialog} from "../../../components/dialogs/createClient";
import {ArchiveClientDialog} from "../../../components/dialogs/archiveClient";
import {AddToBlackListDialog} from "../../../components/dialogs/addToBlackList";
import {RestoreClientFromBlackListDialog} from "../../../components/dialogs/restoreFromBlackList";
import {PageContainer} from "../../../../../../newShared/components/style";
import {UpdateClientDataDialog} from "../../../components/dialogs/updateClientDataDialog";
import {TemplatesImportCsvDialog} from "../../../components/dialogs/templatesImportCsv";
import GetAppIcon from "@mui/icons-material/GetApp";
import {ExportCsvDialog} from "../../../../../../newShared/components/csvExportDialog";
import {GenericTable} from "../../../../../../newShared/components/genericTable";
import {TKycTargetModel} from "../../../../../../newShared/GQLTypes";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {TableCellValueGetter} from "../../../components/clientsTableRow/TableCellValueGetter";
import CreateIcon from "@mui/icons-material/Create";
import HistoryIcon from "@mui/icons-material/History";
import BlockIcon from "@mui/icons-material/Block";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {EmptyRequiredFieldsTooltip} from "../../../components/styled";
import {Spinner} from "../../../../../../newShared/components/Spinner";

export const KycClientsList: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.clients'});

    const {
        clients,
        hasEmptyRequiredField,
        loadings: {getTemplatesList, exportCsv: isLoadingExportCsv, isLoadingFields},
        configs,
        clientPage,
        pageTitle,
        goToExactClient,
        exportCsv,
        handleOpenExportCsv,
        handleOpenCreateClient,
        handleOpenEditClientInfo,
        handleOpenAddToBlackList,
        handleOpenArchiveClient,
        handleOpenRemoveFromBlackList,
        isOutdated,
        handleReloadData,
        genericTable,
        fields,
    } = useKycClientsList();

    const {isTablet} = useMedia();


    return (
        <PageContainer>
            <PageTitleAndActions
                title={pageTitle}
                showDataHasBeenUpdated={isOutdated}
                onReloadData={handleReloadData}
                actions={[
                    {
                        title: t('New client'),
                        icon: <AddIcon />,
                        onClick: handleOpenCreateClient,
                        variant: 'contained',
                        size: 'small',
                        disabled: isLoadingExportCsv,
                        loading: getTemplatesList,
                    },
                    {
                        title: t('Export CSV'),
                        variant: 'text',
                        onClick: handleOpenExportCsv,
                        disabled: isLoadingExportCsv,
                        icon: <GetAppIcon />,
                        loading: isLoadingExportCsv,
                    },
                ]
            }
            />

            {!genericTable.defaultClientsColumns.length && <Spinner />}
            {genericTable.defaultClientsColumns.length > 0 &&
                <GenericTable<TKycTargetModel>
                    id={clientPage}
                    columnsConfig={{totalName: t('Total clients'), forceShowCardsWhen: isTablet}}
                    paging={genericTable.paging}
                    rowsConfig={{
                        rows: clients,
                        customCellPaddings: '8px',
                        rowActionsConfig: [
                            {
                                title: t('Edit client info'),
                                onClick: handleOpenEditClientInfo,
                                icon: <CreateIcon fontSize={'small'}/>
                            },
                            {
                                title: t('Restore from black list'),
                                onClick: handleOpenRemoveFromBlackList,
                                icon: <HistoryIcon fontSize={'small'}/>,
                                dontShow: (row) => row.status !== 'BLACK_LIST'
                            },
                            {
                                title: t('Add to black list'),
                                onClick: handleOpenAddToBlackList,
                                icon: <BlockIcon fontSize={'small'}/>,
                                dontShow: (row) => row.status === 'BLACK_LIST'
                            },
                            {
                                title: t('Archive client'),
                                onClick: handleOpenArchiveClient,
                                icon: <BlockIcon fontSize={'small'}/>
                            },
                        ],
                        getRowUniqueId: (row) => row.id,
                        onRowClick: goToExactClient,
                        columnsConfigs: [
                            ...fields.map(e => {
                                return {
                                    name: e.name,
                                    key: e.systemAlias ?? e.name,
                                    default: genericTable.defaultClientsColumns.some(s => s === e.systemAlias),
                                    valueRenderer: (row: TKycTargetModel) => <TableCellValueGetter client={row} field={e}/>
                                }
                            }),
                            {name: '', key: 'missingData', default: true, valueRenderer: (row) => {
                                const hasMissingData = hasEmptyRequiredField(row);
                                if(hasMissingData){
                                    return(
                                        <EmptyRequiredFieldsTooltip title={t("Missing data for required fields")} placement={"bottom"} arrow>
                                            <InfoOutlinedIcon sx={{width:20, height: 20, color: '#916A00'}} />
                                        </EmptyRequiredFieldsTooltip>
                                    )
                                }
                            }}
                        ]
                    }}
                    filtersConfig={{
                        dontSendSearchIfEmpty: true,
                        searchCustomFilterKeyName: 'nameLike',
                        genericFilterProps: {
                            configs,
                            fetchResultWithSelectedFilters: () => null,
                            isAddFilters: false
                        }
                    }}
                    emptyArrayImageProps={{
                        type: 'kycTemplates',
                        filterNotApplied: {text: t('Clients will appear here'), secondaryText: t('New client'), onClick: handleOpenCreateClient}
                    }}
                />
            }

            {/*<FilterBox>*/}
            {/*    <GenericFiltersArray*/}
            {/*        configs={configs}*/}
            {/*        isAddFilters={false}*/}
            {/*        dateFilterFormat={KYC_DATE_FORMAT}*/}
            {/*        fetchResultWithSelectedFilters={(search, filters, signal) => {*/}
            {/*            dispatch(eraseClientsList());*/}
            {/*            dispatch(GetKycClientsWithFilterAndPaging({*/}
            {/*                data: {*/}
            {/*                    clientType: clientPage.toUpperCase(),*/}
            {/*                    pageInfo: {*/}
            {/*                        page: 0,*/}
            {/*                        count: CLIENTS_DEFAULT_PAGING,*/}
            {/*                        filters: {...filters, ...(search.trim().length > 0 ? {nameLike: search} : {})},*/}
            {/*                    },*/}
            {/*                },*/}
            {/*                signal*/}
            {/*            }))*/}
            {/*        }}*/}
            {/*    />*/}

            {/*</FilterBox>*/}

            {/*<ViewSettingsButton onClick={columnSettings.handleOpen}> {t('View settings')} </ViewSettingsButton>*/}

            {/*{!isNoClientsFound &&*/}
            {/*    <CommonTableContainer onScroll={scroll.onScroll} ref={scroll.scrollRef} >*/}
            {/*        <Table stickyHeader>*/}
            {/*            <ClientsDynamicTableHead isLoadingList={isLoadingList} fieldsConfig={columnSettings.settings} total={pageInfo.total}/>*/}

            {/*            <CommonTableBody>*/}
            {/*                {clients.map((e) => <ClientsTableRow key={e.id}*/}
            {/*                                                     client={e}*/}
            {/*                                                     selected={selectedTargetId === e.id}*/}
            {/*                                                     clientPage={clientPage as TKycClientPagePath}*/}
            {/*                                                     hasEmptyRequiredField={hasEmptyRequiredField(e)}*/}
            {/*                                                     config={columnSettings.settings}*/}
            {/*                                                     goToExactClient={goToExactClient}*/}
            {/*                                                     handleOpenStartScreening={handleOpenStartScreening}*/}
            {/*                                                     handleOpenEditClientInfo={handleOpenEditClientInfo}*/}
            {/*                                                     handleOpenAddToBlackList={handleOpenAddToBlackList}*/}
            {/*                                                     handleOpenArchiveClient={handleOpenArchiveClient}*/}
            {/*                                                     handleOpenRemoveFromBlackList={handleOpenRemoveFromBlackList}*/}
            {/*                />)}*/}

            {/*                /!*Column actions is not in columnConfig - its generated by default at the end of row*!/*/}
            {/*                {(scroll.hasMore || isLoadingList) && <TableRowSkeleton columns={columnSettings.settings.length + 2}/>}*/}
            {/*            </CommonTableBody>*/}
            {/*        </Table>*/}
            {/*    </CommonTableContainer>*/}
            {/*}*/}

            {/*<EmptyArrayImage*/}
            {/*    type={'kycTemplates'}*/}
            {/*    isShowing={isNoClientsFound}*/}
            {/*    text={('No clients found.')}*/}
            {/*    onClick={handleOpenCreateClient}*/}
            {/*/>*/}


            {/*/!*DIALOGS*!/*/}
            {/*<ColumnsViewSettingsDialog*/}
            {/*    selectedColumns={columnSettings.settings.map(e => ({id: e.id, name: e.name}))}*/}
            {/*    columnVariants={columnSettings.columnVariants.map(e => ({id: e.id, name: e.name}))}*/}
            {/*    isOpen={columnSettings.isOpenDialog}*/}
            {/*    handleClose={columnSettings.handleClose}*/}
            {/*    handleSave={columnSettings.handleSave}*/}
            {/*/>*/}
            <ExportCsvDialog
                isOpen={exportCsv.isOpen}
                isLoadingFields={isLoadingFields}
                isLoadingExport={isLoadingExportCsv}
                availableFields={exportCsv.availableFields}
                handleExportCsv={exportCsv.handleExportCsv}
                handleClose={exportCsv.handleClose}
                fieldsMapperKey={"kyc"}
            />
            <CreateClientDialog />
            <ArchiveClientDialog />
            <AddToBlackListDialog />
            <UpdateClientDataDialog />
            <RestoreClientFromBlackListDialog />
            <TemplatesImportCsvDialog />
        </PageContainer>
    )

}

