import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../../newShared/redux/rootReducer";
import {TDialog, TPageInfo} from "../../../../../newShared/types";
import {
    TKYCTemplate,
    TSetCreateTemplateDialog,
    TSetGenerateTokenDialog,
    TSetPreviewFormDialog,
    TSetSendRequestDialog,
    TSetViewTokenDialog
} from "../../types";
import {
    kycCreateTemplateAction,
    kycDeleteTemplateAction,
    kycGenerateTokenByTemplateIdAction,
    kycGetTemplateByIdAction,
    kycGetTemplateFieldsAction,
    kycGetTemplatesAction,
    kycGetTokenByTemplateIdAction,
    kycSendRequestsToEmailsAction,
    kycUpdateTemplateAction
} from "./actions";
import {setPaginationInStore} from "../../../../../newShared/hooks/useScroll/helpers";
import {KycFormFragmentFragment, KycTemplateFieldDto, KycTokenDto} from "../../../../../newShared/GQLTypes";
import {SHOW_SKIP_BUTTON_IN_MFA} from "../../../../../newShared/constants";

export type TKYCTemplatesSlice = {
    templates: {
        templates: (TKYCTemplate | null)[],
        pageInfo: TPageInfo,
    },

    systemFields: {
        person: KycTemplateFieldDto[],
        company: KycTemplateFieldDto[],
    },

    selected: TKYCTemplate | null,

    isLoading: {
        isLoadingTemplates: boolean,
        isLoadingTemplate: boolean,
        isUpdatingTemplate: boolean,
        isCreatingTemplate: boolean,
        isDeletingTemplate: boolean,
        isGettingTemplateNewFields: boolean,
        isSendingRequestToEmails: boolean,
        isGettingToken: boolean,
        isGeneratingToken: boolean,
    },

    dialogs: {
        sendRequest: TDialog & {
            templateId: string | null,
        },
        previewForm: TDialog & {
            form: KycFormFragmentFragment | null,
        },
        createTemplate: TDialog & {
            data: TKYCTemplate | null,
        },
        generateToken: TDialog & {
            templateId: string | null,
        },
        viewToken: TDialog & {
            token: KycTokenDto | null,
        },
    },
}

const initialState: TKYCTemplatesSlice = {
    templates: {
        templates: [],
        pageInfo: {page: 0, count: 50, total: 0, sort: ''},
    },

    selected: null,

    systemFields: {
        person: [],
        company: [],
    },

    isLoading: {
        isLoadingTemplates: false,
        isLoadingTemplate: false,
        isUpdatingTemplate: false,
        isCreatingTemplate: false,
        isDeletingTemplate: false,
        isGettingTemplateNewFields: false,
        isSendingRequestToEmails: false,
        isGettingToken: false,
        isGeneratingToken: false,
    },

    dialogs: {
        sendRequest: {
            isLoading: false,
            isOpen: false,
            templateId: null,
        },
        previewForm: {
            isLoading: false,
            isOpen: false,
            form: null,
        },
        createTemplate: {
            isLoading: false,
            isOpen: false,
            data: null,
        },
        generateToken: {
            isLoading: false,
            isOpen: false,
            templateId: null,
        },
        viewToken: {
            isLoading: false,
            isOpen: false,
            token: null,
        },
    },
}

const Slice = createSlice({
    name: 'kycTemplates',
    initialState,
    reducers: {
        cleanAction(slice: TKYCTemplatesSlice, {payload}: {payload: string | undefined}) {
            switch (payload) {
                case 'selected': {
                    slice.selected = initialState.selected;
                    break;
                }
                case 'array': {
                    slice.templates = initialState.templates;
                    break;
                }
                case 'dialogs': {
                    slice.dialogs = initialState.dialogs;
                    break;
                }
                case undefined: {
                    slice.templates = initialState.templates;
                    slice.selected = initialState.selected;
                    slice.isLoading = initialState.isLoading;

                    slice.dialogs = initialState.dialogs;
                    break;
                }
            }
        },

        setSelectedTemplateAction(slice: TKYCTemplatesSlice, {payload}: {payload: TKYCTemplate | null}) {
            slice.selected = payload
        },

        setSendRequestDialogAction(slice: TKYCTemplatesSlice, {payload}: {payload: TSetSendRequestDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.sendRequest.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.sendRequest.isLoading = payload.isLoading;
            if (payload.templateId !== undefined) slice.dialogs.sendRequest.templateId = payload.templateId;

            if (payload.clear) slice.dialogs.sendRequest = initialState.dialogs.sendRequest;
        },

        setPreviewFormDialogAction(slice: TKYCTemplatesSlice, {payload}: {payload: TSetPreviewFormDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.previewForm.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.previewForm.isLoading = payload.isLoading;
            if (payload.form !== undefined) slice.dialogs.previewForm.form = payload.form;

            if (payload.clear) slice.dialogs.previewForm = initialState.dialogs.previewForm;
        },

        setCreateTemplateDialogAction(slice: TKYCTemplatesSlice, {payload}: {payload: TSetCreateTemplateDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.createTemplate.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.createTemplate.isLoading = payload.isLoading;
            if (payload.data !== undefined) slice.dialogs.createTemplate.data = payload.data;

            if (payload.clear) slice.dialogs.createTemplate = initialState.dialogs.createTemplate;
        },

        setGenerateTokenDialogAction(slice: TKYCTemplatesSlice, {payload}: {payload: TSetGenerateTokenDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.generateToken.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.generateToken.isLoading = payload.isLoading;
            if (payload.templateId !== undefined) slice.dialogs.generateToken.templateId = payload.templateId;

            if (payload.clear) slice.dialogs.generateToken = initialState.dialogs.generateToken;
        },

        setViewTokenDialogAction(slice: TKYCTemplatesSlice, {payload}: {payload: TSetViewTokenDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.viewToken.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.viewToken.isLoading = payload.isLoading;
            if (payload.token !== undefined) slice.dialogs.viewToken.token = payload.token;

            if (payload.clear) slice.dialogs.viewToken = initialState.dialogs.viewToken;
        },
    },
    extraReducers: builder => {
      builder
          .addCase(kycGetTemplatesAction.pending, (slice: TKYCTemplatesSlice, {meta: {arg: {data, clean}}}) => {
              slice.isLoading.isLoadingTemplates = true;
              if (clean) {slice.templates = initialState.templates}
          })
          .addCase(kycGetTemplatesAction.rejected, (slice: TKYCTemplatesSlice) => {
              slice.isLoading.isLoadingTemplates = false;
          })
          .addCase(kycGetTemplatesAction.fulfilled, (slice: TKYCTemplatesSlice, {payload: {data, resp}}) => {
              slice.isLoading.isLoadingTemplates = false;

              const {array, pageInfo} = setPaginationInStore<TKYCTemplate>(
                  slice.templates.pageInfo,
                  slice.templates.templates,
                  resp.pageInfo,
                  resp.templates,
                  true
              );
              slice.templates.templates = array;
              slice.templates.pageInfo = pageInfo;
          })

          .addCase(kycGetTemplateByIdAction.pending, (slice: TKYCTemplatesSlice, {meta: {arg: {data}}}) => {
              slice.isLoading.isLoadingTemplate = true;
          })
          .addCase(kycGetTemplateByIdAction.rejected, (slice: TKYCTemplatesSlice) => {
              slice.isLoading.isLoadingTemplate = false;
          })
          .addCase(kycGetTemplateByIdAction.fulfilled, (slice: TKYCTemplatesSlice, {payload: {data, resp}}) => {
              slice.isLoading.isLoadingTemplate = false;
              slice.selected = resp;
          })

          .addCase(kycGetTokenByTemplateIdAction.pending, (slice: TKYCTemplatesSlice, {meta: {arg: {data}}}) => {
              slice.isLoading.isGettingToken = true;
          })
          .addCase(kycGetTokenByTemplateIdAction.rejected, (slice: TKYCTemplatesSlice) => {
              slice.isLoading.isGettingToken = false;
          })
          .addCase(kycGetTokenByTemplateIdAction.fulfilled, (slice: TKYCTemplatesSlice, {payload: {data, resp}}) => {
              slice.isLoading.isGettingToken = false;

              slice.dialogs.viewToken = {isOpen: true, isLoading: false, token: {
                      token: `${SHOW_SKIP_BUTTON_IN_MFA ? 'dev.' : ''}kyc.regulait.com?token=${resp.token}`,
                      redirectUrl: resp.redirectUrl
                  }};
          })

          .addCase(kycGenerateTokenByTemplateIdAction.pending, (slice: TKYCTemplatesSlice, {meta: {arg: {data}}}) => {
              slice.isLoading.isGeneratingToken = true;
          })
          .addCase(kycGenerateTokenByTemplateIdAction.rejected, (slice: TKYCTemplatesSlice) => {
              slice.isLoading.isGeneratingToken = false;
          })
          .addCase(kycGenerateTokenByTemplateIdAction.fulfilled, (slice: TKYCTemplatesSlice, {payload: {data, resp}}) => {
              slice.isLoading.isGeneratingToken = false;
              slice.dialogs.generateToken = initialState.dialogs.generateToken;

              slice.dialogs.viewToken = {isOpen: true, isLoading: false, token: {
                      token: `${SHOW_SKIP_BUTTON_IN_MFA ? 'dev.' : ''}kyc.regulait.com?token=${resp.token}`,
                      redirectUrl: resp.redirectUrl
                  }};
          })

          .addCase(kycUpdateTemplateAction.pending, (slice: TKYCTemplatesSlice, {meta: {arg: {data}}}) => {
              slice.isLoading.isUpdatingTemplate = true;
          })
          .addCase(kycUpdateTemplateAction.rejected, (slice: TKYCTemplatesSlice) => {
              slice.isLoading.isUpdatingTemplate = false;
          })
          .addCase(kycUpdateTemplateAction.fulfilled, (slice: TKYCTemplatesSlice, {payload: {data, resp}}) => {
              slice.isLoading.isUpdatingTemplate = false;
              slice.selected = resp;
          })

          .addCase(kycCreateTemplateAction.pending, (slice: TKYCTemplatesSlice, {meta: {arg: {data}}}) => {
              slice.isLoading.isCreatingTemplate = true;
          })
          .addCase(kycCreateTemplateAction.rejected, (slice: TKYCTemplatesSlice) => {
              slice.isLoading.isCreatingTemplate = false;
          })
          .addCase(kycCreateTemplateAction.fulfilled, (slice: TKYCTemplatesSlice, {payload: {data, resp}}) => {
              slice.isLoading.isCreatingTemplate = false;
              slice.selected = resp;
          })

          .addCase(kycDeleteTemplateAction.pending, (slice: TKYCTemplatesSlice, {meta: {arg: {data}}}) => {
              slice.isLoading.isDeletingTemplate = true;
          })
          .addCase(kycDeleteTemplateAction.rejected, (slice: TKYCTemplatesSlice) => {
              slice.isLoading.isDeletingTemplate = false;
          })
          .addCase(kycDeleteTemplateAction.fulfilled, (slice: TKYCTemplatesSlice, {payload: {data, resp}}) => {
              slice.isLoading.isDeletingTemplate = false;
          })

          .addCase(kycGetTemplateFieldsAction.pending, (slice: TKYCTemplatesSlice, {meta: {arg: {data}}}) => {
              slice.isLoading.isGettingTemplateNewFields = true;
          })
          .addCase(kycGetTemplateFieldsAction.rejected, (slice: TKYCTemplatesSlice) => {
              slice.isLoading.isGettingTemplateNewFields = false;
          })
          .addCase(kycGetTemplateFieldsAction.fulfilled, (slice: TKYCTemplatesSlice, {payload: {data, resp}}) => {
              slice.isLoading.isGettingTemplateNewFields = false;
              slice.systemFields.company = resp.company;
              slice.systemFields.person = resp.person;
          })

          .addCase(kycSendRequestsToEmailsAction.pending, (slice: TKYCTemplatesSlice, {meta: {arg: {data}}}) => {
              slice.isLoading.isSendingRequestToEmails = true;
          })
          .addCase(kycSendRequestsToEmailsAction.rejected, (slice: TKYCTemplatesSlice) => {
              slice.isLoading.isSendingRequestToEmails = false;
          })
          .addCase(kycSendRequestsToEmailsAction.fulfilled, (slice: TKYCTemplatesSlice, {payload: {data, resp}}) => {
              slice.isLoading.isSendingRequestToEmails = false;
          })
    }
});

export const kycTemplatesReducer = Slice.reducer;

export const {
    cleanAction,

    setSelectedTemplateAction,

    setSendRequestDialogAction,
    setPreviewFormDialogAction,
    setCreateTemplateDialogAction,
    setGenerateTokenDialogAction,
    setViewTokenDialogAction
} = Slice.actions;

const selectSelf = (state: AppState): TKYCTemplatesSlice => state.kycTemplates;

export const kycTemplatesSelector = createSelector(selectSelf, state => state.templates);
export const kycTemplateSelector = createSelector(selectSelf, state => state.selected);
export const kycTemplateSystemFieldsSelector = createSelector(selectSelf, state => state.systemFields);
export const isLoadingSelector = createSelector(selectSelf, state => state.isLoading);

export const kycTemplatesDialogsSelector = createSelector(selectSelf, state => state.dialogs);
