import {SelectVariablePopperTypes} from "../../../types";
import {useEffect, useState} from "react";

export const useSelectVariablePopper = ({element, isFocused, initialVariables = []}: SelectVariablePopperTypes) => {
    const isOpen = Boolean(element) && isFocused;
    const [form, setForm] = useState<string>('');
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    useEffect(() => {
        if(!isOpen){
            setForm('');
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const filteredVariables = initialVariables.filter(e => e.title.toLowerCase().includes(form.toLowerCase()));

    return{
        form,
        setForm,
        selectedIndex,
        setSelectedIndex,
        filteredVariables,
        isNoValuesFound: !filteredVariables.length,
    }
}