import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    campaignReviewExecutors,
    campaignReviewExecutorsMinMaxLoadedPage,
    campaignReviewExecutorsPageInfo,
    clearCampaignReviewExecutors,
    loadings,
    replaceMinMaxLoadedPageExecutor
} from "../../../../store/slice";
import {useSetBreadcrumbs} from "../../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    PATH_ACCESS_MANAGEMENT_REVIEW_EXECUTORS_EXACT,
    PATH_ACCESS_MANAGEMENT_REVIEW_EXECUTORS_LIST,
    PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_NEW,
    PATH_LOCAL_ACCESS_MANAGEMENT
} from "../../../../constants";
import {GetSmallCampaignReviewExecutors} from "../../../../store/actions";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../../../newShared/components/genericTable/types";
import {smallCampaignReviewExecutorType} from "../../../../types";

export const useCampaignReviewExecutorsList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    //selectors
    const executors = useSelector(campaignReviewExecutors);
    const pageInfo = useSelector(campaignReviewExecutorsPageInfo);
    const {campaignReviewExecutorsList} = useSelector(loadings);
    const {minLoadedPage, maxLoadedPage} = useSelector(campaignReviewExecutorsMinMaxLoadedPage);

    // const controller = new AbortController();

    //useEffects
    useSetBreadcrumbs([
        {
            title: tMenu('Access management'),
            path: PATH_LOCAL_ACCESS_MANAGEMENT
        },
        {
            title: tMenu('Review campaign'),
            path: PATH_ACCESS_MANAGEMENT_REVIEW_EXECUTORS_LIST
        }
    ]);

    // useEffect(() => {
    //     dispatch(GetSmallCampaignReviewExecutors({data: {page: 0, count: DEFAULT_CAMPAIGN_REVIEW_EXECUTORS_PAGING}, signal: controller.signal}));
    //     //eslint-disable-next-line
    // }, []);

    //actions
    // const handleLoadMore = (page: number) => {
    //     !campaignReviewExecutorsList && dispatch(GetSmallCampaignReviewExecutors({data: {page: pageInfo.page + 1, count: DEFAULT_CAMPAIGN_REVIEW_EXECUTORS_PAGING}, signal: controller.signal}));
    // }

    const handleGoToCreateInstruction = () => {
        history.push(PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_NEW);
    }

    return{
        actions: {
            // handleLoadMore,
            handleGoToCreateInstruction
        },
        selectors: {
            executors,
            pageInfo,
            isLoadingList: campaignReviewExecutorsList,
            hasMore: pageInfo.total > ((pageInfo.page + 1) * pageInfo.count),
            isNoExecutorsFound: campaignReviewExecutorsList ? false : !executors.length,
        },
        genericTable: {
            paging: {
                pageInfo,
                isLoading: campaignReviewExecutorsList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceMinMaxLoadedPageExecutor(data)),
                clearRows: () => dispatch(clearCampaignReviewExecutors()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    dispatch(GetSmallCampaignReviewExecutors({data: {page, count}}));
                },
            },
            onRowClick: (row: smallCampaignReviewExecutorType) => {
                history.push(PATH_ACCESS_MANAGEMENT_REVIEW_EXECUTORS_EXACT.replace(':id', row.id))
            }
        }
    }
}