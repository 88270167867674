import {useDispatch, useSelector} from "react-redux";
import {
    hideCreateWorkspace,
    organizationsDialogs,
    organizationsLoadings,
    selectedOrganization
} from "../../../../store/slice";
import {useMessageDialog} from "../../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useHistory} from "react-router-dom";
import {SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_PATH,} from "../../../../constants";
import {useForm} from "../../../../../../../newShared/hooks/useForm";
import {organizationTabs} from "../../../../types";
import {CreateWorkspace} from "../../../../store/actions";

export const useCreateWorkspaceDialog = ({handleChangeTab}: {handleChangeTab: (tab: organizationTabs) => void}) => {
    //root
    const dispatch = useDispatch();
    const history = useHistory();
    const selected = useSelector(selectedOrganization);
    const isOpen = useSelector(organizationsDialogs).createWorkspace;
    const isLoading = useSelector(organizationsLoadings).createWorkspace;
    const {setMessage} = useMessageDialog();

    //form
    const {form, setForm, handleChange} = useForm<{name: string}>({name: ''});

    const cleanUp = () => {
        setForm({name: ''});
    }

    //actions
    const handleClose = () => {
        dispatch(hideCreateWorkspace());
        cleanUp();
    }

    const handleSubmit = () => {
        if(isOk && selected){
            dispatch(CreateWorkspace({
                organizationId: selected.id,
                workspaceName: form.name,
                onSuccess
            }))
        }
    }

    const onSuccess = (name: string, id: string) => {
        setMessage({
            title: 'Completed Successfully!',
            message: `Workspace ${name} was created!`
        });
        if(selected){
            history.push(SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_PATH
                .replace(':orgId', selected.id)
                .replace(':wsId', id)
            );
        }
        cleanUp();
    }

    const handleGoToPlans = () => {
        if(selected){
            handleChangeTab('plans');
            handleClose();
        }
    }

    //
    const isAvailableAdd = selected && selected.workspaceCount < selected.configuration.availableWorkspaceCount;
    const isOk = form.name.trim().length > 0 && isAvailableAdd;

    return{
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        handleGoToPlans,
        name: form.name,
        handleChange,
        isAvailableAdd,
        isOk,
    }
}