import axiosSync from "../../../../newShared/utils/axios/axios";
import {print} from "graphql";
import {gql} from "@apollo/react-hooks";
import {AxiosResponse} from "axios";
import {creatableDocumentFileForUpload, DocumentFile, DocumentFolder, DocVariable} from "../types";
import {PATH_SERVER} from "../../../../newShared/constants";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {
    AutocompleteEmployeesEmailNameQuery,
    AutocompleteEmployeesEmailNameQueryVariables,
    AutocompleteRecipientsEmailNameQuery,
    AutocompleteRecipientsEmailNameQueryVariables,
    ChangeDocumentStageEtaMutation,
    ChangeDocumentStageEtaMutationVariables,
    CreateNewDocumentMutationVariables,
    DeleteFolderMutationVariables,
    DeleteNewDocumentMutationVariables,
    DocApproverInput,
    GenerateFileByHtmlInput,
    GetDocumentByIdQuery,
    GetDocumentByIdQueryVariables,
    GetFilesByFolderIdQuery,
    GetFullDocumentResponseModel,
    GetFullDocumentWithExecutorQueryVariables,
    GetNewDocumentByIdQuery,
    GetNewDocumentByIdQueryVariables,
    GetNewTemplateByIdQuery,
    GetNewTemplateByIdQueryVariables,
    GetShortDocumentsByFolderIdWithFilterPagingQuery,
    GetShortDocumentsByFolderIdWithFilterPagingQueryVariables,
    NewDocDataInput,
    NewDocumentActorInput,
    RecipientInput,
    SendToNextStepByStageIdMutationVariables,
    UploadFileMutation,
    UploadFileMutationVariables
} from "../../../../newShared/GQLTypes";
import {getDocumentById, getNewTemplateById} from "./query";
import {NewDocumentExecutorFragment, NewDocumentFragment, ShortNewDocumentFragment} from "./fragments";

export const getFolderTree = async (workspaceId: string): Promise<DocumentFolder[]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getDocTree($workspaceId:String!){
                        getDocumentsTree(workspaceId:$workspaceId)
                    }
                `),
                variables: {
                    workspaceId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        const data = result.data.data.getDocumentsTree;
        try{
            return JSON.parse(data) as DocumentFolder[];
        }catch (ex){
            throw new Error(`getFolderTree error: ${ex}`);
        }
    })
)

export const getFilesByFolderId = async (workspaceId: string, folderId: string): Promise<GetFilesByFolderIdQuery["getFilesByFolder"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getFilesByFolderId($workspaceId:String!, $folderId:String!){
                        getFilesByFolder(workspaceId:$workspaceId, folderId:$folderId){
                            id
                            workspaceId
                            documentId
                            status
                            origin
                            description
                            type
                            name
                            folderId
                            version
                            latest
                            parentId
                            visible
                            lastEditDate
                            editorId
                            creatorId
                            createdDate
                            automationId
                            tags
                            accessRules{
                                rule
                            }
                            data{
                                config{
                                    page
                                }
                                innerHtml
                                variables{
                                    type
                                    title
                                    key
                                    order
                                    value
                                    format
                                    description
                                }
                                editor
                            }
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    folderId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getFilesByFolder;
    })
)

//getNew docs/templates by id
export const getNewDocumentByIdApi = async (data: GetNewDocumentByIdQueryVariables): Promise<GetNewDocumentByIdQuery["getNewDocumentById"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getNewDocumentById($workspaceId:String!, $fileId:String!){
                        getNewDocumentById(workspaceId:$workspaceId, fileId:$fileId){
                            ...NewDocumentFragment
                        }
                    }
                    ${NewDocumentFragment}
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getNewDocumentById;
    })
)

export const getNewTemplateByIdApi = async (data: GetNewTemplateByIdQueryVariables): Promise<GetNewTemplateByIdQuery["getNewTemplateById"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getNewTemplateById),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getNewTemplateById;
    })
)

//shortData
export const getShortDocumentsByFolderIdWithFilterPagingApi = async (data: GetShortDocumentsByFolderIdWithFilterPagingQueryVariables, signal?: AbortSignal): Promise<GetShortDocumentsByFolderIdWithFilterPagingQuery["getShortDocumentsByFolderIdWithFilterPaging"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getShortDocumentsByFolderIdWithFilterPaging($workspaceId:String!, $folderId:String!, $pageInfo:PaginationInput!){
                        getShortDocumentsByFolderIdWithFilterPaging(workspaceId:$workspaceId, folderId:$folderId, pageInfo:$pageInfo){
                            files {...ShortNewDocumentFragment}
                            pageInfo {
                                page
                                count
                                total
                                sort
                            }
                            
                        }
                    }
                    ${ShortNewDocumentFragment}
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getShortDocumentsByFolderIdWithFilterPaging;
    })
)
export const createFolder = async (workspaceId: string, fatherId: string, name: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation createFolder($workspaceId:String!, $fatherId:String!, $name:String!){
                        createFolder(workspaceId:$workspaceId, fatherId:$fatherId, name:$name){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    fatherId,
                    name
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createFolder.message;
    })
)

export const moveNewItems = async (workspaceId: string, fileIds: string[], folderIds: string[], newFatherId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation moveNewItems($workspaceId:String!, $items:ItemsDto!, $newFatherId: String!){
                        moveNewItems(workspaceId:$workspaceId, items:$items, newFatherId: $newFatherId){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    items: {
                        fileIds,
                        folderIds
                    },
                    newFatherId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.moveNewItems.message;
    })
);
export const restoreNewItem = async (data: {workspaceId: string, fileId: string, newFatherId: string}): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation restoreNewItem($workspaceId:String!, $fileId:String!, $newFatherId: String!){
                        restoreNewItem(workspaceId:$workspaceId, fileId:$fileId, newFatherId: $newFatherId){
                            message
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.restoreNewItem.message;
    })
)

export const deleteNewDocument = async (data: DeleteNewDocumentMutationVariables): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deleteNewDocument($workspaceId:String!, $docId:String!){
                        deleteNewDocument(workspaceId:$workspaceId, docId:$docId){
                            message
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteNewDocument.message;
    })
)

export const deleteFolder = async (data: DeleteFolderMutationVariables): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deleteFolder($workspaceId:String!, $folderId:String!){
                        deleteFolder(workspaceId:$workspaceId, folderId:$folderId){
                            message
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteFolder.message;
    })
)

export const deleteItems = async (workspaceId: string, fileIds: string[], folderIds: string[]): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deleteItems($workspaceId:String!, $items:ItemsDto!){
                        deleteItems(workspaceId:$workspaceId, items:$items){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    items: {
                        fileIds,
                        folderIds
                    }
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteItems.message;
    })
)

export const moveItems = async (workspaceId: string, fileIds: string[], folderIds: string[], newFatherId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation moveItems($workspaceId:String!, $items:ItemsDto!, $newFatherId: String!){
                        moveItems(workspaceId:$workspaceId, items:$items, newFatherId: $newFatherId){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    items: {
                        fileIds,
                        folderIds
                    },
                    newFatherId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.moveItems.message;
    })
)

export const uploadFile = async (data: UploadFileMutationVariables): Promise<UploadFileMutation["uploadFile"]["message"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation uploadFile($workspaceId:String!, $file:String!){
                        uploadFile(workspaceId:$workspaceId, file:$file){
                            message
                        }
                    }

                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.uploadFile.message;
    })
)

export const uploadFileArray = async (workspaceId: string, items: creatableDocumentFileForUpload[]): Promise<DocumentFile[]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation createUploadedFiles($workspaceId:String!, $items:UploadFileArrayDto!){
                        createUploadedFiles(workspaceId:$workspaceId, items:$items){
                            id
                            workspaceId
                            documentId
                            status
                            origin
                            description
                            type
                            name
                            folderId
                            version
                            latest
                            parentId
                            visible
                            lastEditDate
                            editorId
                            creatorId
                            createdDate
                            automationId
                            tags
                            accessRules{
                                rule
                            }
                            data{
                                config{
                                    page
                                }
                                innerHtml
                                variables{
                                    type
                                    title
                                    key
                                    order
                                    value
                                    format
                                    description
                                }
                            }
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    items: { files: items }
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createUploadedFiles;
    })
)

export const renameItem = async (workspaceId: string, itemType: 'folder' | 'file', itemId: string, itemName: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation renameItem($workspaceId:String!, $itemType:String!, $itemId:String!, $itemName:String!){
                        renameItem(workspaceId:$workspaceId, itemType:$itemType, itemId:$itemId, itemName:$itemName){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    itemType,
                    itemName,
                    itemId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.renameItem.message;
    })
)

export const updateDoc = async (documentId: string | null, workspaceId: string, id: string, type: string, name: string, folderId: string, innerHtml: string, editor: any, variables: DocVariable[]): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation updateDoc($workspaceId:String!, $newValue:createOrUpdateDocumentDto!){
                        updateDocument(workspaceId:$workspaceId, newValue:$newValue){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    newValue: {
                        documentId,
                        id,
                        type,
                        data: {
                            innerHtml,
                            editor,
                            variables
                        },
                        name,
                        folderId
                    }
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateDocument.message;
    })
)


export const createNewDoc = async (data: CreateNewDocumentMutationVariables): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation createNewDocument($workspaceId:String!, $newValue:CreateNewDocumentDto!, $file:UploadNewFileDto){
                        createNewDocument(workspaceId:$workspaceId, newValue:$newValue, file:$file){
                            message
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createNewDocument.message;
    })
)

export const generatePdfByHTML = async (data: GenerateFileByHtmlInput, workspaceId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation generatePdfByInnerHTML($workspaceId:String!, $data:GenerateFileByHtmlInput!){
                        generateFileByHtml(workspaceId:$workspaceId, data:$data){
                            fileId
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.generateFileByHtml.fileId;
    })
)

/////////////// FOR EDITOR
export const getDocumentByIdApi = async (variables: GetDocumentByIdQueryVariables, signal?: AbortSignal): Promise<GetDocumentByIdQuery["getDocumentById"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getDocumentById),
                variables
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getDocumentById;
    })
);

export const autocompleteEmployeesEmailName = async (data: AutocompleteEmployeesEmailNameQueryVariables): Promise<AutocompleteEmployeesEmailNameQuery["autocompleteEmployeesEmailName"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query autocompleteEmployeesEmailName($organizationId: String!, $workspaceId: String!, $text: String!, $onlyActive: Boolean!){
                        autocompleteEmployeesEmailName(text: $text, workspaceId: $workspaceId, organizationId: $organizationId, onlyActive: $onlyActive) {
                            firstName
                            lastName
                            email
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.autocompleteEmployeesEmailName;
    })
)

export const autocompleteRecipientsEmailNameApi = async (data: AutocompleteRecipientsEmailNameQueryVariables): Promise<AutocompleteRecipientsEmailNameQuery["autocompleteRecipientsEmailName"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query autocompleteRecipientsEmailName($workspaceId: String!, $folderId: String!, $search: String!, $page: Float!){
                        autocompleteRecipientsEmailName(workspaceId: $workspaceId, folderId: $folderId,  text: $search, page: $page) {
                            recipients {
                                email
                                firstName
                                lastName
                            }
                            pageInfo {
                                page
                                count
                                total
                                sort
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.autocompleteRecipientsEmailName;
    })
)

//@WorkspaceId() workspaceId: string,
//     @Args('folderId') folderId: string,
//     @Args('text') text: string,
//     @Args('page') page: number,


export const getFullDocumentWithExecutorApi = async (data: GetFullDocumentWithExecutorQueryVariables): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getFullDocumentWithExecutor($documentId:String!, $workspaceId: String!){
                        getFullDocumentWithExecutor(documentId:$documentId, workspaceId: $workspaceId){
                            file{...NewDocumentFragment}
                            executor{...NewDocumentExecutorFragment}
                        }
                    }
                    
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getFullDocumentWithExecutor;
    })
)

//
export const restartSignStageByStageIdAndRecipient = async(workspaceId: string, documentId: string, stageId: string, recipientEmail: string): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation restartSignStageByStageIdAndRecipient($stageId:String!, $recipientEmail: String!, $workspaceId: String!, $documentId: String!){
                      restartSignStageByStageIdAndRecipient(stageId:$stageId, recipientEmail: $recipientEmail, workspaceId: $workspaceId, documentId: $documentId){
                        file{...NewDocumentFragment}
                        executor{...NewDocumentExecutorFragment}
                      }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: {
                    stageId,
                    recipientEmail,
                    workspaceId,
                    documentId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.restartSignStageByStageIdAndRecipient;
    })
)

export const restartSignStageByStageIdAndGroupId = async(workspaceId: string, documentId: string, stageId: string, groupId: string): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation restartSignStageByStageIdAndGroupId($stageId:String!, $groupId: String!, $workspaceId: String!, $documentId: String!){
                      restartSignStageByStageIdAndGroupId(stageId:$stageId, groupId: $groupId, workspaceId: $workspaceId, documentId: $documentId){
                        file{...NewDocumentFragment}
                        executor{...NewDocumentExecutorFragment}
                      }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: {
                    stageId,
                    groupId,
                    workspaceId,
                    documentId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.restartSignStageByStageIdAndGroupId;
    })
)

export const sendToNextStepByStageId = async(variables: SendToNextStepByStageIdMutationVariables): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation sendToNextStepByStageId($workspaceId: String!, $documentId: String!, $stageId:String!){
                      sendToNextStepByStageId(stageId:$stageId, workspaceId:$workspaceId, documentId:$documentId){
                        file{...NewDocumentFragment}
                        executor{...NewDocumentExecutorFragment}
                      }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: variables
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.sendToNextStepByStageId;
    })
)

export const invalidateDocument = async(workspaceId: string, documentId: string): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation invalidateDocument($workspaceId:String!, $documentId:String!){
                      invalidateDocument(workspaceId:$workspaceId, documentId:$documentId){
                        file{...NewDocumentFragment}
                        executor{...NewDocumentExecutorFragment}
                      }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: {
                    workspaceId,
                    documentId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.invalidateDocument;
    })
)

export const updateFullDocument = async(workspaceId: string, documentId: string, data: NewDocDataInput, name: string, recipients: RecipientInput[], editors: NewDocumentActorInput[], approvers: DocApproverInput[]): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation updateFullDocument($workspaceId: String!, $documentId: String!, $data: NewDocDataInput!, $name: String!, $recipients: [RecipientInput!]!, $editors: [NewDocumentActorInput!]!, $approvers: [DocApproverInput!]!){
                      updateFullDocument(data:$data, name:$name, recipients:$recipients, editors:$editors, approvers:$approvers, documentId:$documentId, workspaceId:$workspaceId){
                        file{...NewDocumentFragment}
                        executor{...NewDocumentExecutorFragment}
                      }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: {
                    documentId,
                    data,
                    name,
                    recipients,
                    editors,
                    approvers,
                    workspaceId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateFullDocument;
    })
)


export const startNewDocumentExecution = async(workspaceId: string, documentId: string, data: NewDocDataInput, name: string, recipients: RecipientInput[], editors: NewDocumentActorInput[], approvers: DocApproverInput[]): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation startNewDocumentExecution($workspaceId: String!, $documentId: String!, $data: NewDocDataInput!, $name: String!, $recipients: [RecipientInput!]!, $editors: [NewDocumentActorInput!]!, $approvers: [DocApproverInput!]!){
                        startNewDocumentExecution(workspaceId:$workspaceId, data:$data, name:$name, recipients:$recipients, editors:$editors, approvers:$approvers, documentId:$documentId){
                            file{...NewDocumentFragment}
                            executor{...NewDocumentExecutorFragment}
                        }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: {
                    workspaceId,
                    documentId,
                    data,
                    name,
                    recipients,
                    editors,
                    approvers
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.startNewDocumentExecution;
    })
)

export const handlePreviewBeforeSendApi = async(workspaceId: string, documentId: string, data: NewDocDataInput, name: string, recipients: RecipientInput[], editors: NewDocumentActorInput[], approvers: DocApproverInput[]): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation previewEditableDocument($workspaceId: String!, $documentId: String!, $data: NewDocDataInput!, $name: String!, $recipients: [RecipientInput!]!, $editors: [NewDocumentActorInput!]!, $approvers: [DocApproverInput!]!){
                        previewEditableDocument(workspaceId:$workspaceId, data:$data, name:$name, recipients:$recipients, editors:$editors, approvers:$approvers, documentId:$documentId){
                            file{...NewDocumentFragment}
                            executor{...NewDocumentExecutorFragment}
                        }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: {
                    workspaceId,
                    documentId,
                    data,
                    name,
                    recipients,
                    editors,
                    approvers
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.previewEditableDocument;
    })
)

export const changeDocumentStageEtaApi = async(variables: ChangeDocumentStageEtaMutationVariables): Promise<ChangeDocumentStageEtaMutation["changeDocumentStageEta"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation changeDocumentStageEta($workspaceId: String!, $documentId: String!, $stageId:String!, $timeInMs:Float!){
                        changeDocumentStageEta(workspaceId:$workspaceId, documentId:$documentId, stageId:$stageId, timeInMs:$timeInMs){
                            file{...NewDocumentFragment}
                            executor{...NewDocumentExecutorFragment}
                        }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: variables
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.changeDocumentStageEta;
    })
)


export const makeDocDraftCopy = async(workspaceId: string, documentId: string): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation makeDocDraftCopy($workspaceId:String!, $documentId:String!){
                      makeDocDraftCopy(workspaceId:$workspaceId, documentId:$documentId){
                        file{...NewDocumentFragment}
                        executor{...NewDocumentExecutorFragment}
                      }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: {
                    workspaceId,
                    documentId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.makeDocDraftCopy;
    })
)
