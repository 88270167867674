import React, {Component, createRef, MouseEvent as ReactMouseEvent, MouseEventHandler, RefObject} from "react";
import {
    EditorComponentsMethods,
    EditorRowData,
    EditorTableCellData,
    getToolBarProps,
    MapDispatchToPropsObject,
    TEditorBlockElementData,
    TEditorBlockItemType,
    TEditorRowElementData,
    TEditorTableCellElementData,
    TResize,
    ValueOf
} from "../../../../types";
import {ToolBarProps} from "../../../editorToolBar";
import {EditorItemTableCellStyled} from "../../styled";
import {EditorPopperButtonsProps} from "../../../commonComponents/editorPopperButtons";

import {EditorWorkZone} from "../../../editorWorkzone";
import {EditorItemRow} from "../../../editorItemRow";
import {EditorItemTableCellResizer} from "../editorItemTableCellResizer";
import {EditorItemTable} from "../../index";
import {
    changeOldIdToNewId,
    createEventBlockCreatedByDrop,
    createEventDeleteBlock,
    onSaveRepeat
} from "../../../../helpers";
import {EditorDropZoneForBlockItem} from "../../../editorDropZoneForBlockItem";
import {uuid} from "../../../../../../utils";

interface Props extends getToolBarProps {
    block: TEditorTableCellElementData;

    onMouseDown: (e: ReactMouseEvent<HTMLDivElement>) => void;
    isLast: boolean;

    parentRef: RefObject<EditorItemTable>["current"];
    editorWorkZoneRef: RefObject<EditorWorkZone>["current"];
    setPopperButtons: (buttons: EditorPopperButtonsProps["buttons"]) => void;

    onResizeMouseDown: MouseEventHandler;
}

export interface TableCellState {
    id: string;
    type: 'tableCell';
    data: TEditorTableCellElementData["data"];

    // width: number;
    isActive: boolean;

    //for resizing
    resize: TResize,

    wrapperRef: RefObject<HTMLTableCellElement>;
    refs: Record<string, RefObject<EditorItemRow>>;
}

export class EditorItemTableCell extends Component<Props, TableCellState> implements EditorComponentsMethods<EditorTableCellData> {
    constructor(props: EditorItemTableCell["props"]) {
        super(props);

        const refs: TableCellState["refs"] = {};
        props.block.data.content.forEach(row => {
            refs[row.id] = createRef<EditorItemRow>();
        });

        this.state = {
            id: props.block.id,
            type: 'tableCell',
            data: {
                ...props.block.data,
            },

            resize: {x: 0, px: 0, percent: 0},
            // width: props.width,
            isActive: false,

            wrapperRef: createRef<HTMLTableCellElement>(),
            refs,
        }
    }

    componentDidMount() {
        this.setWidth(this.state.data.width);
        // console.log('componentDidMount', 'Cell', this.state.id)
    }

    onClickAway = () => {
        // console.log('onClickAway', 'Cell', this.state.id)
        this.setState(prev => ({...prev, isActive: false}));
    }

    setFocus = () => {
        // console.log('setFocus', 'Cell', this.state.id)
        this.setState(prev => ({...prev, isActive: true}));
    }

    setActiveBlock: EditorWorkZone["setActiveBlock"] = (ids) => {
        this.props.parentRef?.setActiveBlock([this.state.id, ...ids]);
    };

    getData = (): TEditorTableCellElementData => {
        const contentCellData = onSaveRepeat(
            this.state.data.content, this.state.refs
        ) as TEditorRowElementData[];

        return {
            id: this.state.id,
            type: 'tableCell',
            data: {
                ...this.state.data,
                content: contentCellData,
                width: this.getWidth(),
            },
        }
    };

    addCopiedBlock = (block: TEditorBlockElementData, oldId: string) => {

    }

    setWidth = (width: number) => {
        const ref = this.state.wrapperRef.current;
        if (ref) {
            ref.style.width = width + '%';
        }
    }

    getWidth = () => {
        const ref = this.state.wrapperRef.current;
        if (ref) {
            const width = ref.style.width;
            return Number(width?.replace('%', ''));
        }
        return 0;
    }

    ///////ToolBar
    setToolBar: MapDispatchToPropsObject["setToolBar"] = (toolBar, ids) => {
        this.props.parentRef?.setToolBar(toolBar, [this.state.id, ...ids])
    }
    setTextByToolBar: ToolBarProps["toolBarHandler"] = (action, payload, ids) => {
        const nextId = ids[0];
        if (nextId) {
            const ref = this.state.refs[nextId]?.current;
            if (ref) {
                ref.setTextByToolBar(action, payload, ids.slice(1))
            }
        }
    }


    handleAddRow = (index: number, type?: ValueOf<TEditorBlockItemType>, block?: TEditorBlockElementData) => {
        //decide what to add: row or row with block
        const data: EditorRowData = {content: []};
        if (block) {
            const newData = changeOldIdToNewId(JSON.parse(JSON.stringify(block)));
            data.content.push({
                ...newData,
                data: {
                    ...newData.data,
                    width: 100,
                }
            })
        } else {
            type && data.content.push({
                id: 'block' + uuid(),
                type: 'block',
                data: {
                    width: 100,
                    content: [{id: type + uuid(), type, data: {}}]
                }
            })
        }

        const rowId = 'row' + uuid();
        this.setState(prev => {
            //condition for avoiding setState twice firing in StrictMode
            if (!prev.data.content.some(e => e.id === rowId) && !prev.refs[rowId]) {
                prev.data.content.splice(index, 0, {type: 'row', id: rowId, data});
                prev.refs[rowId] = createRef<EditorItemRow>();
            }

            return prev
        });

        createEventBlockCreatedByDrop({
            ids: [data.content[0].id],
        });
        block && createEventDeleteBlock({id: block.id});
    };

    handleDeleteRow = (id: string) => {
        this.setState(prev => {
            prev.data.content = prev.data.content.filter(e => e.id !== id);
            return prev
        });
    };

    handleReplaceRow = (id: string, data: EditorRowData[]) => {
        const newData = data.map((e): TEditorRowElementData => ({
            id: 'row' + uuid(), type: 'row', data: e
        }))

        this.setState(prev => {
            const index = prev.data.content.findIndex(block => block.id === id);
            index > -1 && prev.data.content.splice(index, 1, ...newData);

            delete prev.refs[id];
            newData.forEach(el => {
                prev.refs[el.id] = createRef<EditorItemRow>();
            })

            return prev
        });

        // setTimeout(() => {
        //     createEventUnfocusAllBlocks({});
        // }, 150)
    };

    render() {
        return (
            <EditorItemTableCellStyled ref={this.state.wrapperRef}
                                       onMouseDown={!this.props.readonly ? this.props.onMouseDown : undefined}
                                       isActive={this.state.isActive}
            >

                <div className={'insideCell'}>
                    <div className={'insideCellWrapper'}>
                        {this.state.data.content.length === 0 && (
                            <EditorDropZoneForBlockItem orientation={"horizontal"} isLast inTableCell
                                                        onDrop={(type, block) => this.handleAddRow(0, type, block as TEditorBlockElementData)}
                                                        readonly={this.props.readonly}/>
                        )}

                        {this.state.data.content.map((rowInCell, rowIndexInCell, array) => (
                            <EditorItemRow key={rowInCell.id} ref={this.state.refs[rowInCell.id]}
                                           block={rowInCell}
                                           onDelete={() => this.handleDeleteRow(rowInCell.id)}
                                           parentRef={this}
                                           editorWorkZoneRef={this.props.editorWorkZoneRef}
                                           getToolBarState={this.props.getToolBarState}
                                           disabledFocusOnMount
                                           isDisabledDuplicate

                                           status={this.props.status}
                                           readonly={this.props.readonly}
                                           isLoading={this.props.isLoading}
                            />
                        ))}
                    </div>

                    {!this.props.isLast && (
                        <EditorItemTableCellResizer
                            handleMouseDown={this.props.onResizeMouseDown}
                            readonly={this.props.readonly}
                        />
                    )}
                </div>
            </EditorItemTableCellStyled>
        );
    }
}