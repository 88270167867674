import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";
import {TFillableFieldDialogProps} from "./types";
import {useFillableFieldDialog} from "./hooks/useSignDialog";
// import {ReactComponent as TextFieldIcon} from '../../../../../../images/other/textFieldIcon.svg';
import * as S from './components/styled';
import React from "react";

export const FillableFieldDialog = (props: TFillableFieldDialogProps) => {
    const {
        state,
        // handleToggleSwitch,
        handleSubmit,
        isOk,
        handleChangeTypeInput,
        handleClearInputOrFile,
    } = useFillableFieldDialog(props);
    return(
        <Dialog open={props.isOpen} onClose={props.handleClose} maxWidth={'xs'}>
            <S.DialogTitleContainer>
                {/*<TextFieldIcon style={{fill: colors.primary.blue}}/>*/}
                <S.DialogTitle>{('Text')}</S.DialogTitle>
            </S.DialogTitleContainer>

            <DialogContent>
                <S.SignInputContainer>
                    <S.SignInput type="text" value={state.text} onChange={handleChangeTypeInput}/>
                    <S.SignCanvasLineContainer>
                        <S.SignDivider />
                        <S.ClearButton onClick={handleClearInputOrFile}/>
                    </S.SignCanvasLineContainer>
                </S.SignInputContainer>

                {/*<S.SignSwitchContainer>*/}
                {/*    <CommonSwitch label={<S.SwitchTitle>{('Insert text everywhere')}</S.SwitchTitle>}*/}
                {/*                  value={state.insertEverywhere}*/}
                {/*                  onChange={(e, checked) => handleToggleSwitch(checked)}*/}
                {/*    />*/}
                {/*    <S.SwitchDescTitle>{'The text added will be entered in every text field within the document.'}</S.SwitchDescTitle>*/}
                {/*</S.SignSwitchContainer>*/}
            </DialogContent>

            <DialogActions>
                <Button onClick={props.handleClose} variant={'text'}>{'Cancel'}</Button>
                <Button onClick={handleSubmit} disabled={!isOk} variant={'contained'}>{'Save'}</Button>
            </DialogActions>
        </Dialog>
    )
}