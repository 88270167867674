import {DocumentNode, print} from "graphql";
import axiosSync from "../axios/axios";
import {PATH_SERVER} from "../../constants";
import {AxiosResponse} from "axios";
import {AsyncThunk, createAsyncThunk} from "@reduxjs/toolkit";
import {TWithOptions} from "../../types";
import {AppState} from "../../redux/rootReducer";
import {getActionsData} from "../../redux";
import {addErrorSnack, addInfoSnack, addSuccessfulSnack} from "../../../newDomain/barsEnvironment/snack/store/slice";
import {buildCommonHeader} from "../index";
import {GraphQLErrors} from "@apollo/client/errors";
import {getErrorsMap} from "./helpers";
import {TApolloErrors} from "./types";

const createCommonApi = async <Variables extends object, Response extends object>(
    query: DocumentNode, queryName: string, variables: Variables, signal?: AbortSignal, params?: {disableSnackOnError?: boolean, checkLeftMenuItem?: boolean},
): Promise<Response> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(query),
                variables: variables,
                operationName: queryName,
            },
            {
                headers: buildCommonHeader(),
                signal,
                params
            },
        )
    ).then((result: AxiosResponse<any>) => {
        const errors = result.data.errors as GraphQLErrors;
        if (errors?.length) {
            throw errors;
        }

        try {
            return result.data.data[queryName]
        } catch (e) {
            // eslint-disable-next-line no-throw-literal
            throw [new Error('Query name is not correct')];
        }
    })
)

export const createCommonAsyncThunk = <Variables extends object, Response extends object, MoreData extends object = {}>(
    sliceName: string, queryName: string, needWorkspaceId: boolean, needOrganizationId: boolean,
    query: DocumentNode,
) => createAsyncThunk<Response, TWithOptions<Variables, Response, MoreData>, {rejectValue: TApolloErrors}>(
    `${sliceName}/${queryName}`,
    async ({data, addictiveData, signal, disableSnackOnApiError, checkLeftMenuItem, onSuccess, onError, snack}, {getState, dispatch, rejectWithValue}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const commonVariables: {workspaceId?: string, organizationId?: string} = {};
        if (needWorkspaceId) commonVariables['workspaceId'] = data.workspaceId?.length ? data.workspaceId : workspaceId;
        if (needOrganizationId) commonVariables['organizationId'] = data.organizationId?.length ? data.organizationId : organizationId;

        try {
            const response = await createCommonApi<Variables, Response>(
                query, queryName, {...data, ...commonVariables} as Variables, signal, {
                    disableSnackOnError: disableSnackOnApiError,
                    checkLeftMenuItem: checkLeftMenuItem,
                });

            if (snack && 'message' in response && typeof response.message === "string") {
                switch (snack) {
                    case "success": {
                        dispatch(addSuccessfulSnack(response.message));
                        break;
                    }
                    case "error": {
                        dispatch(addErrorSnack(response.message));
                        break;
                    }
                    case 'info': {
                        dispatch(addInfoSnack(response.message))
                        break;
                    }
                }
            }

            onSuccess?.(data, response, addictiveData);

            return response;
        } catch (_errors) {
            const errors = getErrorsMap(_errors as GraphQLErrors | Error[]);
            onError?.(data, errors, addictiveData);
            return rejectWithValue(errors);
        }
    }
) satisfies AsyncThunk<Response, TWithOptions<Variables, Response, MoreData>, {rejectValue: TApolloErrors}>
