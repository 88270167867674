import {Flex} from "../../../../../newShared/components/Layouts";
import {TRiskModel} from "../../types";
import colors from "../../../../../newShared/theme/colors";
import {Slider, Typography} from "@mui/material";

import {useEffect, useRef, useState} from "react";
import * as S from "../styled";
import lodash from "lodash";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

type CustomSliderProps = {
    model: TRiskModel,
    max: number,
    min: number,
    editMode: boolean,
    setLeftRight?: (left: number, right: number) => void
}
export const CustomSlider = ({model, max, min, editMode, setLeftRight}: CustomSliderProps) => {
    const [leftSideWidth, setLeftSideWidth] = useState<number>(0);
    const [middleSideWidth, setMiddleSideWidth] = useState<number>(0);
    const [rightSideWidth, setRightSideWidth] = useState<number>(0);

    const containerRef = useRef<HTMLDivElement | null>();

    const {t, revDir} = useMainTranslation('', {keyPrefix: 'risks'});

    useEffect(() => {
        // console.log(`useEffect[_model.left, _model.right] { handleUpdateView }`)
        handleUpdateView();
        //eslint-disable-next-line
    }, [model.left, model.right]);

    // console.log(`CustomSlider render width: ${model.left} - ${model.right}`)
    const handleUpdateView = () => {
        if(containerRef && containerRef.current){
            if(revDir){
                const pointer2FromLeft = document.querySelector('.MuiSlider-root span[data-index = "1"]')?.getBoundingClientRect().left;
                const pointer1FromLeft = document.querySelector('.MuiSlider-root span[data-index = "2"]')?.getBoundingClientRect().left;
                const containerFromLeft = containerRef.current?.offsetLeft;
                const containerWidth = containerRef.current?.offsetWidth;
                // console.log(`pointer1FromLeft: ${pointer1FromLeft} - pointer2FromLeft: ${pointer2FromLeft} - containerFromLeft: ${containerFromLeft} - containerWidth: ${containerWidth}`);
                if(pointer1FromLeft && pointer2FromLeft){
                    const rightSideWidth =  (pointer1FromLeft - containerFromLeft) / (containerWidth / 100);
                //     console.log(`leftSideWidth: ${leftSideWidth} - \n
                // (pointer1FromLeft{${pointer1FromLeft}} - containerFromLeft{${containerFromLeft}}){${pointer1FromLeft - containerFromLeft}} / (containerWidth{${containerWidth} / 100){${containerWidth / 100}}
                // `);
                    const middleSideWidth = (pointer2FromLeft - pointer1FromLeft) / (containerWidth / 100);
                //     console.log(`middleSideWidth: ${middleSideWidth} - \n
                // (pointer2FromLeft{${pointer2FromLeft}} - pointer1FromLeft{${pointer1FromLeft}}){${pointer2FromLeft - pointer1FromLeft}} / (containerWidth{${containerWidth} / 100){${containerWidth / 100}}
                // `);
                    const leftSideWidth = 100 - rightSideWidth - middleSideWidth;
                //     console.log(`rightSideWidth: ${rightSideWidth} - \n
                // (100 - leftSideWidth{${leftSideWidth}}) - middleSideWidth{${middleSideWidth}}
                // `);
                //     console.log(`leftSideWidth: ${leftSideWidth} - middleSideWidth: ${middleSideWidth} - rightSideWidth: ${rightSideWidth}`);
                    setLeftSideWidth(rightSideWidth);
                    setMiddleSideWidth(middleSideWidth);
                    setRightSideWidth(leftSideWidth);
                }
            }else{
                const pointer1FromLeft = document.querySelector('.MuiSlider-root span[data-index = "1"]')?.getBoundingClientRect().left;
                const pointer2FromLeft = document.querySelector('.MuiSlider-root span[data-index = "2"]')?.getBoundingClientRect().left;
                const containerFromLeft = containerRef.current.offsetLeft;
                const containerWidth = containerRef.current?.offsetWidth;
                if(pointer1FromLeft && pointer2FromLeft){
                    const leftSideWidth =  (pointer1FromLeft - containerFromLeft) / (containerWidth / 100);
                    // console.log(`leftSideWidth: ${leftSideWidth} - \n
                // (pointer1FromLeft{${pointer1FromLeft}} - containerFromLeft{${containerFromLeft}}){${pointer1FromLeft - containerFromLeft}} / (containerWidth{${containerWidth} / 100){${containerWidth / 100}}
                // `);
                    const middleSideWidth = (pointer2FromLeft - pointer1FromLeft) / (containerWidth / 100);
                //     console.log(`middleSideWidth: ${middleSideWidth} - \n
                // (pointer2FromLeft{${pointer2FromLeft}} - pointer1FromLeft{${pointer1FromLeft}}){${pointer2FromLeft - pointer1FromLeft}} / (containerWidth{${containerWidth} / 100){${containerWidth / 100}}
                // `);
                    const rightSideWidth = 100 - leftSideWidth - middleSideWidth;
                    // console.log(`rightSideWidth: ${rightSideWidth} - \n
                // (100 - leftSideWidth{${leftSideWidth}}) - middleSideWidth{${middleSideWidth}}
                // `);
                    // console.log(`leftSideWidth: ${leftSideWidth} - middleSideWidth: ${middleSideWidth} - rightSideWidth: ${rightSideWidth}`);
                    setLeftSideWidth(leftSideWidth);
                    setMiddleSideWidth(middleSideWidth);
                    setRightSideWidth(rightSideWidth);
                }
            }
        }
    }

    return(
        <S.SliderWrapper ref={(ref) => {containerRef.current = ref;}} revDir={revDir}>
            <Slider
                min={min} max={max}
                value={[model.left, model.right]} step={1}
                marks={[
                    { value: min,  label: min },
                    { value: model.left,  label: model.left },
                    { value: model.right,  label: model.right },
                    { value: max,  label: max }
                ]}
                onChange={
                    lodash.debounce((e, values) => {
                        handleUpdateView();
                        const value = values as number[];
                        if (value[0] !== value[1] && value[0] > min && value[1] < max) {
                            setLeftRight && setLeftRight(value[0], value[1]);
                        }
                    })
                }
                disabled={!editMode}
                sx={{
                    zIndex: 1000,
                    '& .MuiSlider-rail': {
                        background: `transparent`,
                        // height: '20px',
                        borderRadius: '0',
                        border: 'none'
                    },
                    '& .MuiSlider-track': {
                        background: "transparent",
                        // height: '20px',
                        borderRadius: '0',
                        border: 'none'
                    },
                    '& .MuiSlider-thumb': {
                        height: 0,
                        width: 0,
                        backgroundColor: 'transparent',
                        '&:focus, &:hover, &.Mui-active': {
                            backgroundColor: 'transparent',
                            cursor: 'col-resize',
                        },
                        boxShadow: 'none'
                    },
                    '& .MuiSlider-valueLabel': {
                        backgroundColor: 'transparent',
                        color: 'transparent',
                        '& *': {
                            background: 'transparent',
                        },
                    },
                    '& .MuiSlider-markLabel': {
                        backgroundColor: 'transparent',
                        color: 'transparent',
                    },
                    '& .MuiSlider-mark': {
                        width: 0,
                    },
                    '& .MuiSlider-dragging': {
                        backgroundColor: 'transparent',
                        color: 'transparent',
                    }

                }}
                valueLabelDisplay={"off"}
                getAriaLabel={() => ''}
                getAriaValueText={() => ''}
                disableSwap
            />




            {!revDir ?
                <>
                    <Flex
                        zi={'0'}
                        position={'absolute'}
                        left={'0'}
                        top={'0'}
                        w={`${leftSideWidth}%`}
                        background={colors.warningActions.green}
                        // border={`1px solid ${colors.warningActions.green}`}
                        jc={'flex-end'}
                        p={'14px 0'}
                    >
                        <Flex ai={'center'} w={'100%'} jc={'center'}>
                            <Typography color={colors.text.white} variant={'subtitle2'}>{t('Low')}</Typography>
                        </Flex>
                    </Flex>

                    <Flex
                        zi={'0'}
                        position={'absolute'}
                        left={`${leftSideWidth}%`}
                        top={'0'}
                        w={`${middleSideWidth}%`}
                        background={colors.darkOrange}
                        // border={`1px solid ${colors.darkOrange}`}
                        jc={'center'}
                        p={'14px 0'}
                    >
                        <Flex ai={'center'} jc={'center'} w={'100%'}>
                            <Typography color={colors.text.white} variant={'subtitle2'}>{t('Medium')}</Typography>
                        </Flex>
                    </Flex>

                    <Flex
                        zi={'0'}
                        position={'absolute'}
                        left={`${middleSideWidth + leftSideWidth}%`}
                        top={'0'}
                        w={`${rightSideWidth}%`}
                        background={colors.warningActions.red}
                        // border={`1px solid ${colors.warningActions.red}`}
                        p={'14px 0'}
                    >
                        <Flex ai={'center'} jc={'center'} w={'100%'}>
                            <Typography color={colors.text.white} variant={'subtitle2'}>{t('High')}</Typography>
                        </Flex>
                    </Flex>



                    <Flex position={'absolute'} top={'52px'}>
                        <Typography color={colors.darkText} variant={'subtitle2'}>{min}</Typography>
                    </Flex>

                    <Flex position={'absolute'} top={'50px'} left={`${leftSideWidth}%`}>
                        <Typography color={colors.darkText} variant={'subtitle2'}>{model.left}</Typography>
                    </Flex>

                    <Flex position={'absolute'} top={'50px'} left={`${leftSideWidth + middleSideWidth}%`}>
                        <Typography color={colors.darkText} variant={'subtitle2'}>{model.right}</Typography>
                    </Flex>

                    <Flex position={'absolute'} top={'48px'} right={`0`}>
                        <Typography color={colors.darkText} variant={'subtitle2'}>{max}</Typography>
                    </Flex>
                </>
                :
                <>
                    <Flex
                        zi={'0'}
                        position={'absolute'}
                        left={'0'}
                        top={'0'}
                        w={`${leftSideWidth}%`}
                        background={colors.warningActions.red}
                        // border={`1px solid ${colors.warningActions.green}`}
                        jc={'flex-end'}
                        p={'14px 0'}
                    >
                        <Flex ai={'center'} w={'100%'} jc={'center'}>
                            <Typography color={colors.text.white} variant={'subtitle2'}>{t('High')}</Typography>
                        </Flex>
                    </Flex>

                    <Flex
                        zi={'0'}
                        position={'absolute'}
                        left={`${leftSideWidth}%`}
                        top={'0'}
                        w={`${middleSideWidth}%`}
                        background={colors.darkOrange}
                        // border={`1px solid ${colors.darkOrange}`}
                        jc={'center'}
                        p={'14px 0'}
                    >
                        <Flex ai={'center'} jc={'center'} w={'100%'}>
                            <Typography color={colors.text.white} variant={'subtitle2'}>{t('Middle')}</Typography>
                        </Flex>
                    </Flex>

                    <Flex
                        zi={'0'}
                        position={'absolute'}
                        left={`${middleSideWidth + leftSideWidth}%`}
                        top={'0'}
                        w={`${rightSideWidth}%`}
                        background={colors.warningActions.green}
                        // border={`1px solid ${colors.warningActions.red}`}
                        p={'14px 0'}
                    >
                        <Flex ai={'center'} jc={'center'} w={'100%'}>
                            <Typography color={colors.text.white} variant={'subtitle2'}>{t('Low')}</Typography>
                        </Flex>
                    </Flex>


                    <Flex position={'absolute'} top={'52px'}>
                        <Typography color={colors.darkText} variant={'subtitle2'}>{max}</Typography>
                    </Flex>

                    <Flex position={'absolute'} top={'50px'} left={`${leftSideWidth}%`}>
                        <Typography color={colors.darkText} variant={'subtitle2'}>{model.right}</Typography>
                    </Flex>

                    <Flex position={'absolute'} top={'50px'} left={`${leftSideWidth + middleSideWidth}%`}>
                        <Typography color={colors.darkText} variant={'subtitle2'}>{model.left}</Typography>
                    </Flex>

                    <Flex position={'absolute'} top={'48px'} right={`0`}>
                        <Typography color={colors.darkText} variant={'subtitle2'}>{min}</Typography>
                    </Flex>
                </>
            }
        </S.SliderWrapper>
    )
}