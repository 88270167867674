import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
    dialogsSelector,
    hideInviteWorkspaceUserDialog,
    selectedOrganization,
    selectedWorkspace,
    selectedWorkspaceData,
    setImportCsvDialogAction,
    workspacesDialogs,
    workspacesLoadings
} from "../../../../store/slice";
import {useEffect, useState} from "react";
import {InviteMemberIntoWorkspace} from "../../../../store/actions";
import {useForm} from "../../../../../../../newShared/hooks/useForm";
import {TExtendedCollaborator, TWorkspaceRole} from "../../../../types";
import {validateEmail} from "../../../../../../../newShared/utils/text";
import {
    ROLE_PORTAL_MEMBER,
    ROLE_WORKSPACE_VIEWER,
    ROLE_WS_ADMIN,
    ROLE_WS_OWNER,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_PLANS_PATH
} from "../../../../constants";
import {UseManageWorkspacesAndOrganizations} from "../../../../../cookies/hooks/useManageWorkspacesAndOrganizations";

export const useInviteToWorkspace = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const isOpen = useSelector(workspacesDialogs).inviteUser;
    const isLoading = useSelector(workspacesLoadings).inviteUser;
    // const {collabs} = useSelector(selectedOrganizationData);
    const workspaceCollabsAndInvites = useSelector(selectedWorkspaceData);
    const selectedOrg = useSelector(selectedOrganization);
    const selectedWs = useSelector(selectedWorkspace);
    const {user} = UseManageWorkspacesAndOrganizations();
    const {importCsvDialog: { isOpen: isOpenImportCsv }} = useSelector(dialogsSelector);

    // const {form, setForm, handleChange} = useForm<{email: string, orgCollab: TExtendedCollaborator | null, inviteRole: TWorkspaceRole | null, collabRoles: TWorkspaceRole[]}>({email: '', orgCollab: null, inviteRole: null, collabRoles: []});
    const {form, setForm, handleChange} = useForm<{email: string, collabRoles: TWorkspaceRole[]}>({email: '', collabRoles: []});
    const [isAccessToPortal, setIsAccessToPortal] = useState<boolean>(true);


    useEffect(() => {
        if(!isOpen){
            cleanUp();
        }
        //eslint-disable-next-line
    }, [isOpen]);


    const handleClose = () => {
        dispatch(hideInviteWorkspaceUserDialog());
    }

    const handleSubmit = () => {
        const portalMemberRole = workspaceCollabsAndInvites.workspaceRoles.find(e => e.name === ROLE_PORTAL_MEMBER);
        if(selectedOrg && selectedWs && form.email && form.collabRoles.length > 0 && !isNoPlace && !isAlreadyInWS && !isAlreadyInvited){
            dispatch(InviteMemberIntoWorkspace({
                workspaceId: selectedWs.id,
                organizationId: selectedOrg.id,
                email: form.email,
                roles: (isAccessToPortal && portalMemberRole) ? [...form.collabRoles, portalMemberRole] : form.collabRoles,
                onSuccess: cleanUp
            }));
        }


    }

    useEffect(() => {
        // if(isOpen && !collabs.length && selectedOrg){
        //     //getting org users to show in autocomplete
        //     dispatch(GetOrganizationCollaborators({id: selectedOrg.id}));
        // }
        if(isOpen && workspaceCollabsAndInvites.workspaceRoles.length > 0){
            const searchedRole = workspaceCollabsAndInvites.workspaceRoles.find(e => e.name === ROLE_WORKSPACE_VIEWER);
            searchedRole && setForm({...form, collabRoles: [searchedRole]});
        }
        //eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        if (form.collabRoles.some(e => e.name === ROLE_WS_ADMIN)) setIsAccessToPortal(true);

        //eslint-disable-next-line
    }, [form.collabRoles]);


    const isAlreadyInvited = workspaceCollabsAndInvites.invites.some(e => e.emailAddress === form.email);
    const isAlreadyInWS = workspaceCollabsAndInvites.collabs.some(e => e.email === form.email);
    const isNoPlace = selectedWs && selectedOrg && selectedOrg?.configuration.availableCollaboratorsCount < (selectedWs?.collaboratorsCount) + 1;
    const isNoMoreRolesCanBeAdded = !workspaceCollabsAndInvites.workspaceRoles.filter(e => !form.collabRoles.some(cr => cr.id === e.id) && e.name !== ROLE_WS_OWNER).length;
    const isOwner = Boolean(workspaceCollabsAndInvites.collabs.find(e => e.publicId === user?.publicId)?.roles.some(e => e.name === ROLE_WS_OWNER));
    const isAdmin = Boolean(workspaceCollabsAndInvites.collabs.find(e => e.publicId === user?.publicId)?.roles.some(e => e.name === ROLE_WS_ADMIN));


    const checkIfUserCanBeAdded = (collab: TExtendedCollaborator): boolean => {
        return !workspaceCollabsAndInvites.collabs.some(e => e.publicId === collab.publicId) && !workspaceCollabsAndInvites.invites.some(e => e.emailAddress === collab.publicId)
    }
    const isOk = (form.collabRoles.length > 0 || isAccessToPortal) && validateEmail(form.email) && (isOwner || isAdmin) && !isAlreadyInWS && !isAlreadyInvited;

    const getEmailTextFieldErrorText = ():string => {
        if(!form.email.trim().length) return '';
        if(!validateEmail(form.email)) return 'Invalid email';
        if(isAlreadyInWS){
            return 'Email already registered as a collaborator in this workspace.';
        }
        if(isAlreadyInvited){
            return 'An invitation to this email address has already been sent previously';
        }
        return '';
    }

    const handleSetOrganizationMember = (email: string) => {
        validateEmail(email) && setForm({...form, email });
    }

    const handleAddCollabRole = () => {
        const notUsedRoles = workspaceCollabsAndInvites.workspaceRoles.filter(e => !form.collabRoles.some(cr => cr.id === e.id)  && e.name !== ROLE_WS_OWNER && e.name !== ROLE_PORTAL_MEMBER);
        notUsedRoles.length > 0 && setForm({...form, collabRoles: [...form.collabRoles, notUsedRoles[0]]});
    }

    const handleDeleteCollabRole = (role: TWorkspaceRole) => {
        setForm({
            ...form,
            collabRoles: [...form.collabRoles.filter(e => e.id !== role.id)]
        });
    }

    const replaceCollabRole = (id: number, role: TWorkspaceRole) => {
        setForm({
            ...form,
            collabRoles: [...form.collabRoles.map((e, eId) => eId === id ? role : e)]
        });
    }

    const handleGoToPlans = () => {
        if(selectedOrg){
            history.push(SETTINGS_ORGANIZATIONS_EXACT_ORG_PLANS_PATH.replace(':orgId', selectedOrg.id));
            handleClose();
        }
    }

    const cleanUp = () => {
        setForm({email: '', collabRoles: []});
        setIsAccessToPortal(true);
    }

    const handleImportCSV = () => {
        dispatch(setImportCsvDialogAction({isOpen: true}));
    }

    return{
        isOpen: isOpen && !isOpenImportCsv,
        isLoading,
        handleClose,
        handleSubmit,
        // orgCollabs: collabs,
        isContainAdminRole: form.collabRoles.some(e => e.name === ROLE_WS_ADMIN),
        isAccessToPortal,
        setIsAccessToPortal,
        checks: {
            isOk,
            isOwner,
            isAdmin,
            isAlreadyInvited,
            isAlreadyInWS,
            isNoPlace,
            isNoMoreRolesCanBeAdded,
            checkIfUserCanBeAdded
        },
        // tab,
        // handleSetTab,
        user,
        roles: workspaceCollabsAndInvites.workspaceRoles,
        actions: {
            handleAddCollabRole,
            handleDeleteCollabRole,
            replaceCollabRole,
            handleSetOrganizationMember,
            handleGoToPlans,
            handleImportCSV
        },
        email: form.email,
        handleChange,
        form,
        replaceCollabRole,
        getEmailTextFieldErrorText
    }
}
