import {useParams} from "react-router-dom";
import {useReport} from "../useReport";
import {useEffect} from "react";

type TResponse = {
    controlId?: string,
    reportId?: string,
    appId?: string,
}
export const useReportsParams = (): TResponse => {
    const {controlId, reportId, appId} = useParams<TResponse>();

    const {
        getReport, getControlById,
        report: {report},
        controls: {selectedControl},
    } = useReport();

    const controller = new AbortController();
    useEffect(() => {
        reportId && reportId !== report?.id && getReport(reportId, controller.signal);
        return () => {
            controller.abort();
            // cleanReport();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportId]);

    useEffect(() => {
        reportId && controlId && selectedControl?.id !== controlId && getControlById(reportId, controlId, controller.signal);
        return () => {
            controller.abort();
            // cleanSelectedControl();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportId, controlId]);

    return {
        controlId,
        reportId,
        appId
    }
}