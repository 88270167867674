import React, {FC} from 'react';
import {useGallery} from "../../hooks/useGallery";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import * as S from "../styled";
import {GenericFiltersArray} from "../../../../../newShared/components/genericFilter";
import {TemplatesTable} from "../../components/TemplatesTable";
import {AddDocumentDialog} from "../../components/dialogs/addDocument";
import EditorDialog from "../../../../../newShared/components/editorUnion";
import {AddGroupMessageDialog} from "../../components/dialogs/addGroupMessage";
import {DocTemplateViewer} from "../../../../../newShared/components/docTemplateViewer";


export const GalleryPageRefactor:FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.GalleryPage'});
    const {
        files,
        // configs,
        filterTemplates,
        _isLoading,
        tagColors,
        handleUseTemplate,
        doc: {selectedNewDocument, currentEmail, snack, loadings, actions}
    } = useGallery();


    return (
        <S.PageContainer>
            <PageTitleAndActions
                title={t('Template gallery')}
                actions={[]}
            />

            <S.FiltersContainer>
                <GenericFiltersArray
                    configs={[]}
                    // configs={configs}
                    id={'gallery'}
                    fetchResultWithSelectedFilters={filterTemplates}
                    isAddFilters={false}
                />

                <TemplatesTable files={files} tagColors={tagColors} _isLoading={_isLoading} handleUseTemplate={handleUseTemplate}/>
            </S.FiltersContainer>


            {/*DIALOGS*/}
            <AddDocumentDialog />
            <AddGroupMessageDialog />
            {selectedNewDocument?.document?.status && (
                <EditorDialog isOpen={true}
                              documentTitle={selectedNewDocument.document.name}
                              content={selectedNewDocument.document.data?.editor || []}
                              variables={selectedNewDocument.document.data?.variables || []}
                              actors={{
                                  approvers: selectedNewDocument.document.approvers || [],
                                  recipients: selectedNewDocument.document.recipients || [],
                                  editors: selectedNewDocument.document.editors || [],
                              }}
                              pdfFileId={selectedNewDocument.document.data?.pdfFileId || null}

                              currentEmail={currentEmail}
                              currentFileStatus={selectedNewDocument.document.status}
                              execution={selectedNewDocument?.documentExecutor || undefined}

                              onSave={actions.handleSave}
                              onSend={actions.handleSend}
                              onPreviewBeforeSend={actions.handlePreviewBeforeSend}
                              onInvalidate={actions.handleInvalidate}
                              onSkipApprover={actions.handleSkip}
                              onClose={actions.handleCloseEditor}
                              onReturnToDraft={actions.handleReturnToDraft}
                              onRejectSignStage={() => null}
                              onRejectApproveStage={() => null}
                              onApproveApproveStage={() => null}
                              onApproveSignStage={() => null}
                              onFillFields={() => null}
                              onChangeEta={() => null}

                              onResendInviteApprover={actions.onResendInviteApprover}
                              onResendInviteRecipient={actions.onResendInviteRecipient}
                              isLoading={{
                                  isPrev: false,
                                  isSave: loadings.isLoadingSave,
                                  isInvalidating: loadings.isLoadingInvalidate,
                                  isRejecting: false,
                                  isApproving: false,
                                  isReinviting: loadings.isLoadingRestartSignStageByStageIdAndGroupId || loadings.isLoadingRestartSignStageByStageIdAndRecipient,
                                  isSkipping: loadings.isLoadingSendToNextStep,
                                  isFillingField: false,
                                  isSending: loadings.isLoadingExact,
                                  isChangingEtaInProgress: false,
                              }}
                              snack={snack}

                              actualEnvironment={"isMain"}
                />
            )}

            <DocTemplateViewer handleUseTemplate={template => handleUseTemplate(template.id)}/>
        </S.PageContainer>
    )
}
