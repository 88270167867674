import {gql} from "@apollo/react-hooks";

export const getDataForRelease = gql`
    query getDate($organizationId: String!, $workspaceId: String!){
        getDataForRelease(organizationId: $organizationId, workspaceId: $workspaceId){
            frameworks {
                id
                name
            }

            controls {
                id
                name
                frameworkIds
                category
            }

            evidences {
                id
                name
                controlIds
            }
        }
    }
`;