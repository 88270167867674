import {useDispatch, useSelector} from "react-redux";
import {dialogs, files, foldersPath, hideUploadFileDialog, isLoading, selectedFolder} from "../../../store/slice";
import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton} from "@mui/material";
import {FileDrop} from "react-file-drop";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloseIcon from '@mui/icons-material/Close';
import {ACCEPT_UPLOAD_FILES, TEMPLATES_FOLDER_NAME} from "../../../constants";
import {getUniqueFileName} from "../../../helpers";
import {UploadFile} from "../../../store/actions";
import {addInfoSnack} from "../../../../../barsEnvironment/snack/store/slice";
import {toBase64} from "../../../../../../newShared/utils/base64/base64";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {Typo} from "../../../../../../newShared/components/Typography";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import colors from "../../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const UploadFileDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});
    const dispatch = useDispatch();
    const isOpen = useSelector(dialogs).uploadFileDialog;
    const isLoadingUploadFile = useSelector(isLoading).isLoadingUploadFile;
    const path = useSelector(foldersPath);
    const _files = useSelector(files);
    const _selectedFolder = useSelector(selectedFolder);

    const ref = React.createRef<HTMLInputElement>();
    const [fileNames, setNames] = useState<string[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

    const handleClose = () => {
        dispatch(hideUploadFileDialog());
    }

    const isOk = () => {
        if(!fileNames.length) return false;
        fileNames.forEach(e => {
            if(_files.some(f => f?.name === e)) return false;
        })
        return _selectedFolder !== null;
    }

    const submit = async() => {
        if(isOk()){
            const addable: {file: string, fileName: string}[] = [];

            for (const e of uploadedFiles) {
                const fileBase64: string = await toBase64(e) as string;
                addable.push({file: fileBase64, fileName: e.name});
            }

            if(_selectedFolder && path.length > 0){
                dispatch(UploadFile({
                    items: addable,
                    folderId: _selectedFolder.id,
                    snack: false,
                    isTemplate: path[0].name === TEMPLATES_FOLDER_NAME
                }));
            }
        }
    }

    const fileHandler = (files: FileList | null) => {
        if(files){
            Array.from(files).forEach(e => {
                const tmp = e.name.split('.');
                const fileExtension = tmp[tmp.length - 1]?.toLowerCase();
                if(!ACCEPT_UPLOAD_FILES.split(', .').some(ext => ext === fileExtension)){
                    dispatch(addInfoSnack(t(`This extension is not supported!`)));
                    return;
                }

                const uniqueName = getUniqueFileName(e.name, fileNames.concat(_files.map((e, index) => e ? e.name : `Document ${index}`)));
                //updating name and setting file
                Object.defineProperty(e, 'name', {
                    writable: true,
                    value: uniqueName
                });
                setNames(prev => [...prev, uniqueName]);
                setUploadedFiles(prev => [...prev, e]);
            });
        }
    };

    const handleDeleteFile = (fileName: string) => {
        setNames([...fileNames.filter(e => e !== fileName)]);
        setUploadedFiles([...uploadedFiles.filter(e => e.name !== fileName)]);
    }

    const filePicker = () => {
        ref.current && ref.current.click();
    };

    return(
        <Dialog onClose={handleClose} open={isOpen} fullWidth maxWidth={'xs'}>
            <DialogTitle>{t('Upload file to')} {path.length > 0 ? path[0].name : ``}</DialogTitle>

            <DialogContent className={'container'}>
                <Flex w={'100%'} direction={'column'}>
                    <Flex w={'100%'} direction={'column'} jc={'center'} overflowy={'auto'} maxh={'400px'}>
                        {fileNames.map((file, i, arr) => (
                            <div key={file}>
                                <Flex w={'100%'} ai={'center'} jc={'space-between'} m={'0 0 5px 0'} p={'5px'}>
                                    <Flex ai={'center'}>
                                        <InsertDriveFileIcon />
                                        <Typo margin={'0 0 0 5px'}>{file}</Typo>
                                    </Flex>
                                    <IconButton onClick={() => handleDeleteFile(file)}>
                                        <CloseIcon />
                                    </IconButton>
                                </Flex>
                                {i !== arr.length - 1 && <Divider flexItem/>}
                            </div>
                        ))}
                    </Flex>

                    <FileDrop onTargetClick={filePicker} onDrop={(f) => fileHandler(f)}>
                        <Flex direction={'column'} w={'100%'} minh={'100px'} h={'100px'} jc={'center'} m={'10px 0 0 0'} border={`1px dotted ${colors.primary.blue}`} br={'5px'}>
                            <Flex ai={'center'} jc={'center'} w={'100%'} h={'100%'} p={'15px 0 0 0'}>
                                <Typo cursor={'pointer'}>
                                    <b style={{color: colors.primary.blue, fontWeight: 400}}>{t('DRAG')} </b>
                                    {t('FILE HERE OR')}
                                    <b style={{color: colors.primary.blue, fontWeight: 400}}> {t('BROWSE')}</b>
                                </Typo>
                            </Flex>

                            <input
                                accept={ACCEPT_UPLOAD_FILES}
                                value=""
                                style={{ visibility: "hidden", opacity: 0 }}
                                ref={ref}
                                multiple
                                type="file"
                                onChange={(e) => fileHandler(e.target.files)}
                            />
                        </Flex>
                    </FileDrop>
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'} disabled={isLoadingUploadFile}>{t('Cancel')}</Button>
                <LoadingButton loading={isLoadingUploadFile} onClick={submit} disabled={!isOk()}>{t('Upload')}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
