import React from "react";
import {Flex} from "../../../../../newShared/components/Layouts"
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Box, Button, IconButton, MenuItem, Skeleton, Tab, Tabs, TextField, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {Edit} from "@mui/icons-material";
import {TabContext, TabPanel} from "@mui/lab";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {TExactAssetTabs} from "../../types";
import {DeleteAssetDialog} from "../../components/Dialogs/DeleteAssetDialog";
import {CreateSettingRowDialog} from "../../components/Dialogs/CreateSettingRowDialog";
import {useCertificateAssetExact} from "../../hooks/useCertificateAssetExact";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import {AddMenuItem} from "../../../../../newShared/components/Basic/AddMenuItem";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import * as S from "../../components/styled";


export const CertificateAssetExact = () => {
    const {isMobile} = useMedia();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});

    const {
        isOk,
        asset,
        settings,
        tab,
        setTab,
        handleGoBack,
        editInfoMode,
        form,
        // file,
        fileName,
        handleDeleteFile,
        ref,
        filePicker,
        fileHandler,
        handleChange,
        handleSetRenewal,
        isLoadingExact,
        isLoadingSettings,

        handleSaveInfo,
        handleCancelEditInfo,
        handleEditInfoBtn,
        handleChangeCustomField,
        openCreateSettingRowDialog,

        deleteAssetDialog: {setDeleteAssetDialog, responseDelete},
    } = useCertificateAssetExact();


    return (
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={asset?.name}
                handleGoBack={handleGoBack}
                showMenuWhen={isMobile}
                actions={[
                    {
                        title: t('Delete asset'),
                        onClick: () => {setDeleteAssetDialog({isOpen: true, assetType: 'certificate'})},
                        variant: 'text',
                        icon: <DeleteForeverIcon/>,
                        disabled: !asset || isLoadingExact
                    },
                ]}
            />


            {/*TABS*/}
            <TabContext value={tab}>
                <Box sx={{ margin: '30px 0 0 0' }}>
                    <Tabs variant={'scrollable'} value={tab}
                          aria-label="basic tabs example"
                          onChange={(e, newValue: TExactAssetTabs) => setTab(newValue)} >
                        <Tab disabled={isLoadingExact} label={t("Information")} value={'information'} sx={{paddingLeft: '0'}}/>
                        {/*<Tab disabled={isLoadingExact} label={t("History")} value={'history'} />*/}
                        {/*<Tab disabled={true} label={t("Services detected")} value={'servicesDetected'} />*/}
                    </Tabs>
                </Box>

                {/*INFO*/}
                <TabPanel value={'information'} sx={{padding: '16px 0', overflowY: 'auto'}}>
                    { editInfoMode ?
                        <Flex w={'100%'} direction={'column'} ai={'flex-start'}  overflow={'hidden'} >
                            <Flex ai={'center'} p={'6px 0'}  w={'100%'} >
                                <Button size={'large'}
                                        disabled={!isOk() || isLoadingExact}
                                        onClick={handleSaveInfo} >
                                    {t('Save')}
                                </Button>
                                <Button variant={'outlined'} size={'large'}
                                        disabled={isLoadingExact}
                                        sx={{marginLeft: revDir ? undefined : '8px', marginRight: revDir ? '8px' : undefined}}
                                        onClick={handleCancelEditInfo} >{t('Cancel')}</Button>
                            </Flex>

                            <Flex direction={'column'} w={'100%'} ai={'flex-start'} m={'10px 0 0 0'}  >

                                <Box display={'flex'}>
                                    { !!fileName.length ?
                                        <Flex ai={'center'} w={'100%'}>
                                            <Typography sx={{margin: !revDir ? '0 5px 0 0' : '0 0 0 5px'}} color={colors.text.grey_dark}>{fileName}</Typography>
                                            <IconButton onClick={handleDeleteFile}>
                                                <DeleteOutlineSharpIcon fontSize={'small'}/>
                                            </IconButton>
                                        </Flex>
                                        :
                                        <Flex ai={'center'} m={'16px 0 0 0'}>
                                            <Button sx={{width: 'max-content'}} startIcon={<FileUploadIcon />} onClick={filePicker} variant={'text'}>
                                                {t('SELECT CERT FILE')}
                                            </Button>
                                            {/*<Typography fontSize={'13px'} fontWeight={600} color={colors.primary.blue} sx={{margin: !revDir ? '0 5px 0 0' : '0 0 0 5px', cursor: 'pointer', whiteSpace: 'nowrap'}} onClick={filePicker}>{t('SELECT FILE')}</Typography>*/}
                                            <input
                                                accept={'.csv'}
                                                style={{ visibility: "hidden", opacity: 0}}
                                                ref={ref}
                                                type="file"
                                                onChange={(e) => fileHandler(e.target.files)}
                                            />

                                        </Flex>
                                    }
                                </Box>


                                <TextField label={t('Name')} size={"small"}
                                           required
                                           sx={{mt: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                           value={form?.name} onChange={handleChange} name={'name'} />


                                <TextField label={t('Owner')} value={form?.owner} size={'small'}
                                           sx={{mt: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                           onChange={handleChange} name={'owner'} />

                                <TextField select fullWidth label={t('Renewal')} value={form?.renewal} size={'small'}
                                           sx={{mt: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                           onChange={(e) => handleSetRenewal(e.target.value)}
                                >
                                    <AddMenuItem text={t('Add new')} onClick={() => openCreateSettingRowDialog({isOpen: true, field: 'renewal', assetType: 'certificate'})} />
                                    { settings && Object.keys(settings.renewal) && Object.keys(settings.renewal).map((e) => <MenuItem key={e} value={e}>{e}</MenuItem>)}
                                </TextField>


                                {(settings && settings.customFields && !!Object.keys(settings.customFields).length) &&
                                    Object.keys(settings.customFields).map((e) =>
                                        (<TextField key={e} size={'small'} label={e} value={form?.customFields[e] || ''} name={e}
                                                    onChange={handleChangeCustomField} sx={{marginTop: '16px', width: '320px', '& label': {top: '0px !important'}}}
                                        />)
                                    )}

                            </Flex>
                        </Flex>
                        :
                        <Flex w={'100%'} direction={'column'} ai={'flex-start'} style={{opacity: responseDelete ? 0 : 'undefined'}}>
                            <Button variant={'text'} startIcon={<Edit style={{margin: revDir ? '0 0 0 9px' : undefined}}/>}
                                    sx={{marginTop: '10px', paddingLeft: !revDir ? '3px' : undefined, paddingRight: revDir ? '3px' : undefined}}
                                    disabled={isLoadingExact || isLoadingSettings}
                                    onClick={handleEditInfoBtn}>
                                {t('Edit information')}
                            </Button>

                            {isLoadingExact ? <Skeleton width={'250px'} sx={{mt: '16px'}}/> : <S.FieldTitleText> {`${t('Name')}: `}<S.FieldValueText>{asset?.name ?? ''}</S.FieldValueText></S.FieldTitleText>}
                            {isLoadingExact ? <Skeleton width={'250px'} sx={{mt: '16px'}}/> : <S.FieldTitleText> {`${t('File name')}: `}<S.FieldValueText>{asset?.fileName ?? ''}</S.FieldValueText></S.FieldTitleText>}
                            {isLoadingExact ? <Skeleton width={'250px'} sx={{mt: '16px'}}/> : <S.FieldTitleText> {`${t('Owner')}: `}<S.FieldValueText>{asset?.owner ?? ''}</S.FieldValueText></S.FieldTitleText>}
                            {isLoadingExact ? <Skeleton width={'250px'} sx={{mt: '16px'}}/> : <S.FieldTitleText> {`${t('Renewal')}: `}<S.FieldValueText>{asset?.renewal ?? ''}</S.FieldValueText></S.FieldTitleText>}

                            {!isLoadingExact && settings && Object.keys(settings?.customFields ?? {}).map((key) => asset?.customFields?.[key] && (
                                <S.FieldTitleText key={key}> {`${key}: `}<S.FieldValueText>{asset?.customFields[key] ?? ''}</S.FieldValueText> </S.FieldTitleText>
                            )) }
                        </Flex>
                    }
                </TabPanel>

            </TabContext>

            <DeleteAssetDialog />
            <CreateSettingRowDialog onSuccess={handleSetRenewal} />
        </Flex>
    )
}
