import React, {FC, useEffect, useState} from "react";
import {TEditorVariableData} from "../../../types";
import {Button, TextField} from "@mui/material";
import {DataObjectOutlined} from "@mui/icons-material";
import {
    AddVariableDialogActionsStyled,
    AddVariableDialogContentStyled,
    AddVariableDialogStyled,
    AddVariableDialogTitleStyled
} from "./styled";
import {uuid} from "../../../../../utils";

interface IProps {
    isOpen: boolean;
    variableNames: string[];
    initValue?: TEditorVariableData;

    onSave: (variable: TEditorVariableData) => void;
    onCancel: () => void;
}
export const AddVariableDialog: FC<IProps> = ({isOpen, variableNames, onCancel, onSave, initValue}) => {

    const [dialog, setDialog] = useState<{title: string, description: string, value: string}>(defaultDialog);

    useEffect(() => {
        setDialog(initValue ? {description: initValue.description || '', title: initValue.title, value: initValue.value || ''} : defaultDialog)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    const onAdd = () => {
        onSave({
            title: dialog.title.trim(),
            description: dialog.description.trim(),
            value: dialog.value.trim(),
            id: initValue?.id ?? ('variable' + uuid()),
            type: initValue?.type ?? 'TEXT',
            order: initValue?.order ?? variableNames.length,
            count: initValue?.count ?? 0
        })
    }

    return (
        <AddVariableDialogStyled open={isOpen} onClose={onCancel}>
            <AddVariableDialogTitleStyled>
                <DataObjectOutlined/>
                {'Add variable'}
            </AddVariableDialogTitleStyled>

            <AddVariableDialogContentStyled>
                <TextField label={'Title'} fullWidth value={dialog.title} size={"small"} required autoFocus
                           onChange={(e) => setDialog(prev => ({...prev, title: e.target.value}))}/>
                <TextField label={'Description'} fullWidth value={dialog.description} size={"small"}
                           onChange={(e) => setDialog(prev => ({...prev, description: e.target.value}))}/>
                <TextField label={'Value'} fullWidth value={dialog.value} size={"small"}
                           onChange={(e) => setDialog(prev => ({...prev, value: e.target.value}))}/>

                {/*<TextField label={'Order'} fullWidth value={order} onChange={(e) => this.setDialog(prev => ({...prev, order: Number(e.target.value)}))}*/}
                {/*           type={"number"} InputProps={{ inputProps: { min: 0, max: this.state.variables.length } }}/>*/}
            </AddVariableDialogContentStyled>

            <AddVariableDialogActionsStyled>
                <Button variant={"text"} onClick={onCancel}>{'Cancel'}</Button>
                <Button variant={"contained"} onClick={onAdd}
                        disabled={variableNames.includes(dialog.title.trim()) || dialog.title.trim() === ''}>
                    {initValue ? 'Update' : 'Add'}
                </Button>
            </AddVariableDialogActionsStyled>
        </AddVariableDialogStyled>
    )
}

const defaultDialog = {
    title: '', description: '', value: ''
}
