import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {TSoftwareAssetSettingsId} from "../../types";
import {normalizeSettingIdName} from "../../helpers";
import {loadings, softwareAssetsSettingsSelector} from "../../store/store";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_ASSET_MANAGEMENT} from "../../../../../newShared/constants";
import {
    PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE,
    PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_SETTINGS,
    PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_SETTINGS_EXACT,
} from "../../constants";
import {useAppDispatch} from "../../../../../newShared/redux";
import {createSoftwareAssetsSettingRowAction} from "../../store/actions";


export const useSoftwareSettingsNew = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    // const {t} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});
    const settings = useSelector(softwareAssetsSettingsSelector);

    const id = useParams<{id: string}>().id as TSoftwareAssetSettingsId;

    const settingTitle = normalizeSettingIdName(id as TSoftwareAssetSettingsId);

    const {isLoadingSettings, addSettingLoading} = useSelector(loadings);

    const [item, setItem] = useState<string>('');

    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setItem(event.target.value);
    };


    useEffect(() => {
        setItem('');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useSetBreadcrumbs([
        {
            title: tMenu('Asset Management'),
            path: PATH_LOCAL_ASSET_MANAGEMENT
        },
        {
            title: tMenu('Software'),
            path: PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE
        },
        {
            title: 'Settings',
            path: PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_SETTINGS
        },
        {
            title: 'New',
            path: PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_SETTINGS_EXACT.replace(':id', id)
        }
    ]);

    const handleGoBack = () => history.push(PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_SETTINGS_EXACT.replace(':id', id));

    const isOk = () => {
        if (item.trim().length > 0 && settings) {
            return !Object.keys(settings[id]).some(e => e.toLowerCase().trim() === item.toLowerCase().trim())
        } else return false;
    }

    const handleSave = () => {
        if(isOk()){
            dispatch(createSoftwareAssetsSettingRowAction({workspaceId: '', organizationId: '', field: id, title: item.trim()})).then(resp => handleGoBack());
        }
    }


    return {
        id,
        settings,
        settingTitle,
        item,
        setItem,
        handleChange,
        handleGoBack,
        isOk,
        handleSave,

        isLoadingSettings,
        addSettingLoading,

    }

}
