import React, {FC, Fragment} from "react";
import {Button, MenuItem, Typography} from "@mui/material";
import * as S from "../../components/styled"
import colors from "../../../../../newShared/theme/colors";
import {Flex} from "../../../../../newShared/components/Layouts";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useVendorAdd} from "../../hooks/useVendorAdd";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {CreateSettingDialog} from "../../components/dialogs/createSetting";
import {useAddNewSetting} from "../../hooks/useAddNewSetting";
import {optionIsTCollaborator} from "../../../../../newShared/components/AutocompleteCollaborators/types";
import {getName} from "../../../../../newShared/utils/text";
import {AutocompleteCollaborators} from "../../../../../newShared/components/AutocompleteCollaborators";

export const AddVendor: FC = () =>{

    const {
        isLoading,
        handleGoBack,
        createVendor,
        settings,
        isSettingsLoading,
        tmpVendor,
        setTmpVendor,
        resetTmpVendor,
        isExist,
        isOk
    } = useVendorAdd();
    const {isMobile}=useMedia();
    const {t, revDir} = useMainTranslation('',{keyPrefix:'pathVendors.AddVendor'});

    const {TypeBlock, ServiceProvidedBlock, RiskBlock, field} = useAddNewSetting();

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('New vendor')}
                handleGoBack={handleGoBack}
                actions={[]}
            />

                <S.VendorOverviewSubWrapper margin = {"10px 0 0 0"}>
                    <Flex ai={'center'} >
                        <Button
                            variant={'contained'}
                            disabled={!isOk() || isExist || isLoading}
                            size={isMobile ? 'small' : 'medium'}
                            onClick={createVendor}
                            sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}
                        >{t('Save')}</Button>
                        <Button
                            variant={'outlined'}
                            onClick={()=>{
                                resetTmpVendor();
                                handleGoBack();
                            }}
                            disabled={isLoading}
                            color={'lightPrimary'}
                            size={isMobile ? 'small' : 'medium'}
                            sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}
                        >{t('Cancel')}
                        </Button>
                    </Flex>
                </S.VendorOverviewSubWrapper>

            <S.CreateVendorInputsWrapper>
                <Typography variant={"body1"} sx={{color: colors.grayText, padding: revDir ? '0 5px 0 0' : '0 0 0 5px'}}>
                    {t('Basic information')}
                </Typography>
                <S.TextFieldCustom
                    value={tmpVendor?.name}
                    required size={'small'}
                    onChange={(e) => {
                        e.persist();
                        setTmpVendor({...tmpVendor, name: e.target.value})
                    }}
                    disabled={isLoading}
                    name={'name'}
                    label={t('Name')}
                    error={isExist}
                    sx={{margin: '20px 0 0 0', color: colors.grayText, height: '40px'}}
                    helperText={isExist ? t('Such vendor already exists.') : ''}

                />

                {/*<S.TextFieldCustom label={t("Type")} value={tmpVendor?.type || null} size={'small'} required*/}
                {/*           sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}*/}
                {/*           onChange={(event) => {setTmpVendor({...tmpVendor, type: event.target.value as string})}}*/}
                {/*>*/}
                {/*    <AddMenuItem text={t('Add new')} onClick={() => handleCreateNewItem('type')} />*/}
                {/*    { settings?.type.map(({name}) => <MenuItem key={name} value={name}>{name}</MenuItem>)}*/}
                {/*</S.TextFieldCustom>*/}

                <S.AutoComplete
                    sx={{margin: '20px 0 0 0', height: '40px'}}
                    loading={isLoading || isSettingsLoading}
                    id="combo-box-demo"
                    options={settings ? settings.type.map(e => e.name) : []}
                    renderOption={(props, option) => {
                        return <MenuItem {...props}>{option}</MenuItem>
                    }}
                    value={tmpVendor?.type || null}
                    onChange={(event, value) => {
                        event.persist();
                        setTmpVendor({...tmpVendor, type: value as string})}}
                    renderInput={(params) => (
                        <S.TextFieldCustom {...params} size="small" required label={t("Type")}  fullWidth sx={{height: '40px'}}
                                           InputProps={{
                                               ...params.InputProps,
                                               endAdornment: (
                                                   <Fragment >
                                                       {params.InputProps.endAdornment}
                                                   </Fragment>
                                               ),
                                           }}
                        />
                    )}
                    PaperComponent={TypeBlock}
                />

                <S.AutoComplete
                    sx={{margin: '20px 0 0 0', height: '45px'}}
                    loading={isLoading || isSettingsLoading}
                    id="combo-box-demo"
                    renderOption={(props: object, option: unknown) => {
                        return <MenuItem {...props}>{option}</MenuItem>
                    }}
                    options={settings ? settings.serviceProvided.map(e => e.name) : []}
                    value={tmpVendor?.serviceProvided || null}
                    onChange={(event, value) => {
                        setTmpVendor({...tmpVendor, serviceProvided: value as string})}}
                    renderInput={(params) => (
                        <S.TextFieldCustom {...params} size="small" sx={{height: '40px'}} label={t("Service provided")} required
                                           InputProps={{
                                               ...params.InputProps,
                                               endAdornment: (
                                                   <Fragment>
                                                       {params.InputProps.endAdornment}
                                                   </Fragment>
                                               ),
                                           }}/>
                    )}
                    PaperComponent={ServiceProvidedBlock}
                />

                <S.AutoComplete
                    sx={{margin: '20px 0 0 0', height: '40px'}}
                    loading={isLoading || isSettingsLoading}
                    id="combo-box-demo"
                    options={settings ? settings.risk.map(e => e.name) : []}
                    renderOption={(props: object, option: unknown) => {
                        return <MenuItem {...props}>{option}</MenuItem>
                    }}
                    value={tmpVendor?.risk || null}
                    onChange={(event, value) => {
                        setTmpVendor({...tmpVendor, risk: value as string})}}
                    renderInput={(params) => (
                        <S.TextFieldCustom {...params} required size="small" label={t("Risk")} sx={{height: '40px'}}
                                           InputProps={{
                                               ...params.InputProps,
                                               endAdornment: (
                                                   <Fragment>
                                                       {params.InputProps.endAdornment}
                                                   </Fragment>
                                               ),
                                           }}/>
                    )}
                    PaperComponent={RiskBlock}
                />

                <AutocompleteCollaborators
                    // key={uniqueInputId}
                    label={t("Owner")}
                    getOptionLabelCustom={"name+email"}
                    getOptionLabel={(option) => optionIsTCollaborator(option) ? getName(option.firstName || '', option.lastName || '') : option}

                    sx={{
                        width: 'min(100%, 300px)',

                        '& .MuiInputBase-input': {
                            height: '24px',
                        },
                        '& label': {
                            top: '0 !important',
                        },

                        margin: '20px 0 0 0', height: '40px'
                    }}

                    defaultValue={null}
                    onChange={value => {
                        if (!value) setTmpVendor({...tmpVendor, owner: null})
                        else if (optionIsTCollaborator(value)) {
                            setTmpVendor({...tmpVendor, owner: value})
                        }
                    }}
                />

                <S.TextFieldCustom
                    value={tmpVendor?.link || ''}
                    size={'small'}
                    onChange={(e) => {
                        e.persist();
                        setTmpVendor({...tmpVendor, link: e.target.value.trim()})
                    }}
                    disabled={isLoading}
                    name={'link'}
                    label={t('Link')}
                    sx={{margin: '20px 0 0 0', color: colors.grayText, height: '40px'}}/>

                <Typography variant={"body1"} sx={{margin: '20px 0 0 0', color: colors.grayText, padding: revDir ? '0 5px 0 0' : '0 0 0 5px'}}>
                    {t('Custom field')}
                </Typography>

                {settings?.customFields.length > 0 && settings.customFields.map(({name}) =>
                    <S.TextFieldCustom
                        key={name}
                        disabled={isLoading}
                        value={tmpVendor?.customFields[name] || ''}
                        size={'small'}
                        name={name}
                        label={name}
                        sx={{margin: '20px 0 0 0', color: colors.grayText, height: '40px'}}
                        onChange={(event) => {
                            event.persist();

                            setTmpVendor({
                                ...tmpVendor,
                                customFields: {...tmpVendor.customFields, [name]: event.target.value.trim()}
                            })
                        }}
                    />
                )}

            </S.CreateVendorInputsWrapper>

            <CreateSettingDialog onSuccess={(str) => {
                switch (field) {
                    case 'risk': {
                        setTmpVendor({...tmpVendor, risk: str});
                        break;
                    }
                    case 'type': {
                        setTmpVendor({...tmpVendor, type: str});
                        break;
                    }
                    case 'serviceProvided': {
                        setTmpVendor({...tmpVendor, serviceProvided: str});
                        break;
                    }
                }
            }}/>
        </Flex>
    )
}



