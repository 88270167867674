import {Flex} from "../../../../../newShared/components/Layouts";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import React from "react";
import {SettingsLoading} from "../../components/LoadingPages/settingsLoading";
import {Button, TextField} from "@mui/material";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {LoadingButton} from "@mui/lab";
import {useSaasSettingsNew} from "../../hooks/useSaasSettingsNew";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";


export const NewSaasSetting = () => {
    const {isMobile} = useMedia();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});

    const {
        settingTitle,
        item,
        handleChange,
        isOk,
        handleSave,
        handleGoBack,
        addSettingLoading,
        isLoadingSettings,
    } = useSaasSettingsNew();


    return (
        isLoadingSettings ? <SettingsLoading /> :
            <Flex w={'100%'} h={'100%'} direction={'column'}>
                <PageTitleAndActions
                    title={t(settingTitle)}
                    handleGoBack={handleGoBack}
                    actions={[]}
                />

                {/*<Breadcrumbs />*/}

                {/*<Flex ai={'center'} m={'10px 0 0 0'}>*/}
                {/*    {!isMobile &&*/}
                {/*        <IconButton sx={{*/}
                {/*            color: colors.text.blue,*/}
                {/*            margin: !revDir ? `${!isLessThen1050 ? 11 : 4}px 12px ${!isLessThen1050 ? 4 : 2}px 0` : `${!isLessThen1050 ? 11 : 4}px 0 ${!isLessThen1050 ? 4 : 2}px 12px`,*/}
                {/*            padding: '0'*/}
                {/*        }}*/}
                {/*                    onClick={handleGoBack} size={isLessThen1050 ? 'small' : undefined}>*/}
                {/*            {!revDir ? <ArrowBack/> : <ArrowForward/>}*/}
                {/*        </IconButton>}*/}
                {/*    <Typography variant={isMobile ? 'h3' : "h1"} sx={{*/}
                {/*        margin: !revDir ? '0 32px 0 11px' : '0 32px 0 0',*/}
                {/*        color: colors.backgrounds.dark*/}
                {/*    }}>{t(settingTitle)}</Typography>*/}
                {/*</Flex>*/}


                <Flex ai={'center'} m={'32px 0 0 0'}>
                    {addSettingLoading ?
                        <LoadingButton
                            loading
                            sx={{width: '190px', paddingLeft: '30px', margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                            variant="outlined"
                            onClick={handleSave}
                        >{t('Save')}</LoadingButton>
                        :
                        <Button
                            disabled={!isOk()}
                            variant={'contained'}
                            size={isMobile ? 'small' : 'medium'}
                            onClick={handleSave}
                            sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                        >{t('Save')}</Button>
                    }
                    <Button disabled={addSettingLoading} variant={'outlined'} size={isMobile ? 'small' : 'medium'} onClick={handleGoBack} >{t('Cancel')}</Button>
                </Flex>

                <TextField
                    value={item}
                    label={t('Item name')}
                    variant={'outlined'}
                    onChange={handleChange}
                    required
                    size={'small'}
                    sx={{width: '320px', marginTop: '18px', '& label': {top: '0 !important'}}}
                />
            </Flex>
    )

}

