import {createSelector, createSlice} from "@reduxjs/toolkit";
import {
    initialValidateGoogleAuthorizationAction,
    loginAction,
    resendEmailVerificationCodeAction,
    sendEmailToResetPassAction,
    sendPassToResetPassAction,
    signUpAction,
    verifyEmailAction
} from "./actions";
import {AppState} from "../../../../newShared/redux/rootReducer";

export type TSignInSlice = {
    // step: 'Login' | 'Mfa',
    email: string | null;

    initialMfaConfig: {
        isStarted: boolean,
        secret: string,
        qrCode: string,
        recoveryCodes: string[] | null,
    } | null,

    isError: {
        errorMessage: string | null,
        isCodeExpiry: boolean,
    },

    isLoading: {
        isCheckingPass: boolean,

        isCheckingMfa: boolean,
        isResendingMfa: boolean,

        isSendingEmail: boolean,

        isSendingEmailPass: boolean,
        isResettingPass: boolean,

        isEmailVerifying: boolean,
        isEmailVerifyResendingCode: boolean,

        isConfigurationMfaApp: boolean,

        isRegistration: boolean,
    },

    isResetMailSent: boolean
};

const initialState: TSignInSlice = {
    // step: 'Login',
    email: null,

    isError: {
        errorMessage: null,
        isCodeExpiry: false,
    },

    initialMfaConfig: null,

    isLoading: {
        isCheckingPass: false,

        isCheckingMfa: false,
        isResendingMfa: false,

        isSendingEmail: false,

        isSendingEmailPass: false,
        isResettingPass: false,

        isEmailVerifying: false,
        isEmailVerifyResendingCode: false,

        isConfigurationMfaApp: false,

        isRegistration: false,
    },

    isResetMailSent: false,
}

const authSlice = createSlice({
  name: 'signin',
  initialState,
  reducers: {
      // setStepAction: (slice: TSignInSlice, {payload}: {payload: TSignInSlice["step"]}) => {
      //     slice.step = payload;
      // },
      setMfaStartAction: (slice: TSignInSlice) => {
          if (slice.initialMfaConfig) {
              slice.initialMfaConfig.isStarted = true;
          }
      },

      setIsLoadingAction: (slice: TSignInSlice, {payload}: {payload: {key: keyof TSignInSlice["isLoading"], value: boolean}[]}) => {
          for (const payloadKey of payload) {
              slice.isLoading[payloadKey.key] = payloadKey.value
          }
      },
      setIsErrorAction: (slice: TSignInSlice, {payload}: {payload: {
              isCodeExpiry?: boolean,
              errorMessage?: string | null,
          }}) => {

          if (payload.isCodeExpiry !== undefined) slice.isError.isCodeExpiry = payload.isCodeExpiry;
          if (payload.errorMessage !== undefined) slice.isError.errorMessage = payload.errorMessage;
      },

      cleanUpAction: () => initialState,
  },
  extraReducers: builder => {
    builder
        .addCase(loginAction.pending,(slice: TSignInSlice) => {
            slice.isLoading.isCheckingPass = true;
            slice.isError.errorMessage = null;
        })
        .addCase(loginAction.rejected,(slice: TSignInSlice, { payload }) => {
            slice.isLoading.isCheckingPass = false;
            slice.isError.errorMessage = payload as string;
        })
        .addCase(loginAction.fulfilled, (slice: TSignInSlice, {payload}) => {
            slice.isLoading.isCheckingPass = false;
            slice.isError.errorMessage = null;

            if (payload?.email) slice.email = payload.email;
        })

        .addCase(sendEmailToResetPassAction.pending, (slice: TSignInSlice) => {
            slice.isLoading.isSendingEmailPass = true;
            // slice.isError = false;
            // slice.message = undefined
        })
        .addCase(sendEmailToResetPassAction.rejected, (slice: TSignInSlice) => {
            slice.isLoading.isSendingEmailPass = false;
            // slice.isError = 'ERROR';
        })
        .addCase(sendEmailToResetPassAction.fulfilled, (slice: TSignInSlice, {payload}) => {
            slice.isLoading.isSendingEmailPass = false;
            slice.isResetMailSent = true;
        })

        .addCase(sendPassToResetPassAction.pending, (slice: TSignInSlice) => {
            slice.isLoading.isResettingPass = true;
            // slice.isError = false;
            // slice.message = undefined
        })
        .addCase(sendPassToResetPassAction.rejected, (slice: TSignInSlice) => {
            slice.isLoading.isResettingPass = false;
            // slice.isError = 'ERROR';
        })
        .addCase(sendPassToResetPassAction.fulfilled, (slice: TSignInSlice, {payload}) => {
            slice.isLoading.isResettingPass = false;
            // slice.message = payload.message
        })

        .addCase(verifyEmailAction.pending, (slice: TSignInSlice) => {
            slice.isLoading.isEmailVerifying = true;
            // slice.isError = false;
            // slice.message = undefined
        })
        .addCase(verifyEmailAction.rejected, (slice: TSignInSlice) => {
            slice.isLoading.isEmailVerifying = false;
            // slice.isError = 'ERROR';
        })
        .addCase(verifyEmailAction.fulfilled, (slice: TSignInSlice, {payload}) => {
            slice.isLoading.isEmailVerifying = false;

            if (payload.resp.secret) {
                slice.initialMfaConfig = {
                    isStarted: false,
                    qrCode: payload.resp.qrCode!,
                    secret: payload.resp.secret!,
                    recoveryCodes: null,
                }
            }
            // slice.message = payload.message
        })

        .addCase(resendEmailVerificationCodeAction.pending, (slice: TSignInSlice) => {
            slice.isLoading.isEmailVerifyResendingCode = true;
            // slice.isError = false;
            // slice.message = undefined
        })
        .addCase(resendEmailVerificationCodeAction.rejected, (slice: TSignInSlice) => {
            slice.isLoading.isEmailVerifyResendingCode = false;
            // slice.isError = 'ERROR';
        })
        .addCase(resendEmailVerificationCodeAction.fulfilled, (slice: TSignInSlice, {payload}) => {
            slice.isLoading.isEmailVerifyResendingCode = false;
            // slice.message = payload.message
        })

        .addCase(signUpAction.pending, (slice: TSignInSlice) => {
            slice.isLoading.isRegistration = true;
            // slice.isError = false;
            // slice.message = undefined
        })
        .addCase(signUpAction.rejected, (slice: TSignInSlice) => {
            slice.isLoading.isRegistration = false;
            // slice.isError = 'ERROR';
        })
        .addCase(signUpAction.fulfilled, (slice: TSignInSlice, {payload, meta: {arg: {username}}}) => {
            slice.isLoading.isRegistration = false;
            slice.email = username;
            // slice.message = payload.message
        })

        .addCase(initialValidateGoogleAuthorizationAction.pending, (slice: TSignInSlice) => {
            slice.isLoading.isConfigurationMfaApp = true;
            // slice.isError = false;
            // slice.message = undefined
        })
        .addCase(initialValidateGoogleAuthorizationAction.rejected, (slice: TSignInSlice) => {
            slice.isLoading.isConfigurationMfaApp = false;
            // slice.isError = 'ERROR';
        })
        .addCase(initialValidateGoogleAuthorizationAction.fulfilled, (slice: TSignInSlice, {payload}) => {
            slice.isLoading.isConfigurationMfaApp = false;

            if (slice.initialMfaConfig) {
                slice.initialMfaConfig.recoveryCodes = payload.resp
            }
            // slice.message = payload.message
        })



    //SendEmailSupport
    //     .addCase(SendEmailSupportAction.fulfilled, (slice) => {
    //         slice.isLoadingSendMessage = false;
    //         slice.contactDialog = false;
    //         slice.messageSentDialog = true;
    //     }).addCase(SendEmailSupportAction.pending,(slice) => {
    //         slice.isLoadingSendMessage = true;
    //         slice.isError = false;
    //         slice.errMessage = '';
    //     }).addCase(SendEmailSupportAction.rejected,(slice, { payload }) => {
    //         slice.isLoadingSendMessage = false;
    //         slice.isError = true;
    //         slice.errMessage = payload as string;
    //     })
  }
});

export const SigninReducer = authSlice.reducer;

export const {
    // setStepAction,
    setMfaStartAction,

    setIsLoadingAction,
    setIsErrorAction,

    cleanUpAction
} = authSlice.actions;

const selectSelf = (state: AppState): TSignInSlice => state.auth;

// export const stepSelector = createSelector(selectSelf, state => state.step);
export const signInEmailSelector = createSelector(selectSelf, state => state.email);
export const initialMfaSelector = createSelector(selectSelf, state => state.initialMfaConfig);
export const isLoadingSelector = createSelector(selectSelf, state => state.isLoading);
export const isErrorSelector = createSelector(selectSelf, state => state.isError)

export const isResetMailSentSelector = createSelector(selectSelf, state => state.isError);