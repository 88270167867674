import {gql} from "@apollo/react-hooks";

export const kycTemplateFragment = gql`
    fragment kycTemplateFragment on KycTemplateModel {
        id
        name
        description
        system
        type
        fields {
            name
            required
            type
            system
        }
    }
`

export const kycRequestFragment = gql`
    fragment kycRequestFragment on KycRequestModel {
        id
        email
        status
        updatedDate
        createdDate
        templateType
        name
        zipFileId
        
        fields {
            name
            type
            value
            ext
            
            system
        }
        isAnalysed
        result
    }
`

export const kycTemplateFieldFragment = gql`
    fragment kycTemplateFieldFragment on KycTemplateFieldDto {
        name
        type
        system
        required
    }
`

export const kycFormFragment = gql`
    fragment kycFormFragment on KycFormModel {
        organization {
            id
            name
            logo
        }
        
        fields {
            name
            type
            system
            required
            value
        }
    }
`