import {useCreateDelegatedTaskDialog} from "../../../hooks/dialogs/useCreateDelegatedTaskDialog";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {AutocompleteCollaborators} from "../../../../../../newShared/components/AutocompleteCollaborators";
import React from "react";
import {optionIsTCollaborator} from "../../../../../../newShared/components/AutocompleteCollaborators/types";
import {getName} from "../../../../../../newShared/utils/text";
import {CollaboratorRole, WorkspaceCollaboratorWithRolesModel} from "../../../../../../newShared/GQLTypes";

export const CreateDelegatedTaskDialog = () => {
    const {isOpen, formik, handleCreate, handleClose, isLoadingCreate, selectedStep} = useCreateDelegatedTaskDialog();
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathVica.DelegatedTasks'})
    const isOptionDisabled = (value: WorkspaceCollaboratorWithRolesModel): boolean => {
        return !value.roles.includes(CollaboratorRole.RolePortalMember);
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Create a delegated task')}</DialogTitle>
            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                <Typography variant={'*bodyText2_semibold'}>{selectedStep?.name || ''}</Typography>
                {selectedStep?.description &&
                    <Typography variant={'*bodyText2'}>{selectedStep?.description}</Typography>
                }

                <Typography variant={'*bodySmall'}>{t('Only a collaborator with Portal Access can be selected')}</Typography>

                <AutocompleteCollaborators<false, false, false>
                    sx={{marginTop: '-10px'}}
                    getOptionLabelCustom={"name+email"}
                    getOptionLabel={(option) => optionIsTCollaborator(option)
                        ? getName(option.firstName || '', option.lastName || '')
                        : option}
                    defaultValue={null}
                    inputOnBlur={formik.handleBlur}
                    inputName={'collaborator'}
                    onChange={value => {
                        formik.setFieldTouched('collaborator');
                        formik.setFieldValue('collaborator', value ? {
                            collaboratorId: value.publicId, fullName: getName(value.firstName || '', value.lastName || ''), email: value.email,
                        } : null);
                    }}
                    label={t('Select a collaborator')}
                    helperText={formik.touched.collaborator ? formik.errors.collaborator : undefined}
                    getOptionDisabled={value => isOptionDisabled(value)}
                />
                <TextField
                    multiline
                    rows={4}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.comment}
                    name={'comment'}
                    label={t('Enter comment')}
                    error={formik.touched.comment && Boolean(formik.errors.comment)}
                    helperText={formik.touched.comment && formik.errors.comment}
                />


            </DialogContent>
            <DialogActions>
                <Button size={'small'}
                        variant={'text'}
                        onClick={handleClose}
                >{tCommon('Cancel')}</Button>
                <LoadingButton
                    size={'small'}
                    variant={'contained'}
                    onClick={handleCreate}
                    loading={isLoadingCreate}
                    disabled={!formik.isValid}
                >{tCommon('Create')}</LoadingButton>

            </DialogActions>
        </Dialog>
    )
}
