import React, {FC} from "react";
import {TrainingContentNewModel} from "../../../../../newShared/GQLTypes";
import {TrainingSection} from "../trainingSection";

type TExamQuestionsProps = {
    content: TrainingContentNewModel[],
    toggleOpen: (id: string) => void,
    openedSection: string,
}
export const TrainingSectionsBlock: FC<TExamQuestionsProps> = ({content, toggleOpen, openedSection}) => {


    return (
        <>
            {[...content]
                .sort((a, b) => a.order - b.order)
                .map((e, index) => <TrainingSection key={e.sectionId} section={e} toggleOpen={toggleOpen} isOpen={openedSection === e.sectionId} order={e.order + 1} isExactPage />)}
        </>
    )
}
