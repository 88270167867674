import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideIncomingRequestViewDialog, loadings} from "../../store/slice";
import {
    mainHrApproveRequestAction,
    mainHrDeleteRequestAction,
    mainHrGetRequestByIdAction,
    mainHrRejectRequestAction
} from "../../store/actions";
import {useEffect, useState} from "react";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";


export const useIncomingRequestViewDialog = () => {
    const dispatch = useDispatch();
    const {setMessage} = useMessageDialog();
    const {t} = useMainTranslation('', {keyPrefix: 'pathHRManagement.pathIncomingRequests'});
    const{incomingRequestView: {request, requestId, employee}} = useSelector(dialogs);
    const {
        isLoadingApproveRequest,
        isLoadingRejectRequest,
        isLoadingRequest,
        isLoadingDeleteRequest
    } = useSelector(loadings);

    const [isOnReject, setIsOnReject] = useState<boolean>(false);

    const handleClose = () => {
        dispatch(hideIncomingRequestViewDialog());
    }
    const handleApproveRequest = (requestId: string) => {
        requestId && dispatch(mainHrApproveRequestAction({
            data: {id: requestId},
            onSuccess: (request, response) => {
                setMessage({
                    title: t('Completed successfully'),
                    message: [
                        t(`Request {{requestId}} was approved`, {requestId}),
                    ]
                })
            }
            },
        ));
    };

    const handleRejectRequest = (requestId: string) => {
        requestId && dispatch(mainHrRejectRequestAction({
            data: {id: requestId},
        }));
    };

    const handleDeleteRequest = (requestId: string) => {
        requestId && dispatch(mainHrDeleteRequestAction({
                data: {id: requestId}
            }
        ));
    };

    const isOpen = Boolean(requestId);
    const getRequestById = (requestId: string) => {
        dispatch(mainHrGetRequestByIdAction({
            data: {id: requestId},
        }));
    }

    useEffect(() => {
        if(requestId) {
            getRequestById(requestId);
            setIsOnReject(false);
        }

        //eslint-disable-next-line
    },[requestId])


    return {
        isOpen,
        isLoadingRequest,
        handleClose,
        request,
        employee,
        handleApproveRequest,
        getRequestById,
        handleRejectRequest,
        handleDeleteRequest,
        requestId,
        isOnReject,
        setIsOnReject,
        isLoadingApproveRequest,
        isLoadingRejectRequest,
        isLoadingDeleteRequest
    };

}
