import {FC, MouseEventHandler} from "react";
import {useControl} from "../../hooks/useControl";
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TableRowSkeleton} from "../../../../../newShared/components/tableRowSkeleton";
import {useFrameworksPathParams} from "../../hooks/useFrameworksPathParams";
import {TableRowEmpty} from "../../../../../newShared/components/tableRowEmpty";
import {getEvidenceStatus} from "../../helpers";
import {useHistory} from "react-router-dom";
import {PATH_VICA_STEP_EXACT} from "../../../vicaRefactor/constants";
import {LightTooltip} from "../../../../../newShared/components/pageTitleAndActions";

export const TabEvidences: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathFrameworks.pathEvidence'})

    const {
        evidences,
        isLoading,

        goToEvidences,
    } = useControl();
    const {evidenceId} = useFrameworksPathParams();

    const history = useHistory();
    const goToViceStep = (stepId: string): MouseEventHandler<HTMLButtonElement> => (e) => {
        e.stopPropagation();
        history.push(PATH_VICA_STEP_EXACT.replace(':stepId', stepId))
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('Name')}</TableCell>
                        <TableCell>{t('Description')}</TableCell>
                        <TableCell>{t('Status')}</TableCell>
                        <TableCell>{t('Items')}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {evidences.evidences.map(ev => (
                        <TableRow key={ev.id} onClick={() => {goToEvidences({evidenceId: ev.id})}} hover selected={ev.id === evidenceId}>
                            <TableCell>{ev.name}</TableCell>
                            <TableCell>{ev.description}</TableCell>
                            <TableCell sx={{color: getEvidenceStatus(t, ev.status).color, whiteSpace: 'nowrap'}}>{getEvidenceStatus(t, ev.status).text}</TableCell>
                            <TableCell>{ev.files.length + ev.automations.length + ev.statements.length}</TableCell>
                            <TableCell>
                                {ev.vicaStep && (
                                    <LightTooltip title={ev.vicaStep.stepName}>
                                        <Button variant={"text"} size={'small'} onClick={goToViceStep(ev.vicaStep.stepId)} sx={{whiteSpace: 'nowrap'}}>{t('VICA step')}</Button>
                                    </LightTooltip>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                    {isLoading && (
                        <>
                            <TableRowSkeleton columns={5}/>
                            <TableRowSkeleton columns={5}/>
                            <TableRowSkeleton columns={5}/>
                        </>
                    )}
                    {evidences.evidences.length === 0 && !isLoading && <TableRowEmpty columns={5} text={t('Evidences not found')}/>}
                </TableBody>
            </Table>
        </TableContainer>
    )
}