import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    createTarget,
    deleteTarget,
    downloadEvidenceFileAPI,
    getShortTargetById,
    getShortTargets,
    getTargetScanHistory,
    releaseTargetScans,
    runTarget,
    updateTarget
} from "../api";
import {createTargetRequestType, scanTypes, updateTargetRequestType} from "../types";
import {saveAs} from "file-saver";
import {startDownloadFile} from "./slice";
import {PaginationInput} from "../../../../newShared/GQLTypes";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {getActionsData} from "../../../../newShared/redux";
import {b64toBlob} from "../../../../newShared/utils/base64/base64";
import {TWithOptions} from "../../../../newShared/types";

export const GetShortTargets = createAsyncThunk(
    'VulnerabilityScanner/getShortTargets',
    async ({data, signal}: TWithOptions<PaginationInput>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getShortTargets( {workspaceId, pageInfo: data}, signal);
    }
);

//getShortTargetById
export const GetShortTargetById = createAsyncThunk(
    'VulnerabilityScanner/getShortTargetById',
    async ({data, signal}: TWithOptions<{id: string, onError: () => void}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        try{
            return await getShortTargetById({workspaceId, id: data.id}, signal);
        }catch (e){
            data.onError();
            throw new Error(`GetShortTargetById error: ${e}`);
        }
    }
);

//createTarget
export const CreateTarget = createAsyncThunk(
    'VulnerabilityScanner/createTarget',
    async (data: {target: createTargetRequestType, onSuccess: (id: string) => void}, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const res = await createTarget(workspaceId, organizationId, data.target);
        data.onSuccess(res.id);
        return res;
    }
);

//updateTarget
export const UpdateTarget = createAsyncThunk(
    'VulnerabilityScanner/updateTarget',
    async (data: {target: updateTargetRequestType, onSuccess: () => void}, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const res = await updateTarget(workspaceId, data.target);
        data.onSuccess();
        return res;
    }
);

//runTarget
export const RunTarget = createAsyncThunk(
    'VulnerabilityScanner/runTarget',
    async (data: {targetId: string, onSuccess: () => void}, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        await runTarget(workspaceId, data.targetId);
        // dispatch(addInfoSnack(mes));
        data.onSuccess();
        return data.targetId;
    }
);

//deleteTarget
export const DeleteTarget = createAsyncThunk(
    'VulnerabilityScanner/deleteTarget',
    async (data: {targetId: string, onSuccess: () => void}, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        await deleteTarget(workspaceId, data.targetId);
        data.onSuccess();
        return data.targetId;
    }
);

//getTargetScanHistory
export const GetTargetScanHistory = createAsyncThunk(
    'VulnerabilityScanner/getTargetScanHistory',
    async ({data: {page, count, scanType,targetId}, signal}: TWithOptions<{targetId: string, scanType: scanTypes, page: number, count: number}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        return await getTargetScanHistory({workspaceId, pageInfo: {page, count}, scanType, targetId}, signal);
    }
);

export const DownloadReport = createAsyncThunk(
    'VulnerabilityScanner/downloadReport',
    async (data: { fileId: string, name: string, scanId: string }, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        dispatch(startDownloadFile(data.scanId));

        const resp = await downloadEvidenceFileAPI({fileId: data.fileId, fileName: data.name, workspaceId});
        saveAs(b64toBlob(resp.file), resp.filename)
        return {}
    }
);

export const ReleaseTargetScans = createAsyncThunk(
    'VulnerabilityScanner/releaseTargetScans',
    async (data: {targetId: string, evidenceId: string, onSuccess: () => void}, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        await releaseTargetScans(workspaceId, data.targetId, data.evidenceId);
        // dispatch(addSuccessfulSnack(mes));
        data.onSuccess();
        return data.targetId;
    }
);