import {EmptyArrayImage} from "../../../../../../newShared/components/EmptyArrayImage";
import {Flex} from "../../../../../../newShared/components/Layouts";
import React from "react";

export const AccessManagementProfile = () => {
    return(
        <Flex w={'100%'}>
            <EmptyArrayImage
                type={'builderDev'}
                isShowing={true}
                text={'Profiles in development'}
                withoutAdd
            />
        </Flex>
    )
}