import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideCancelRequest, loadings} from "../../../store/slice";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {CancelRequest} from "../../../store/actions";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const CancelRequestDialog = () => {
    const dispatch = useDispatch();
    const {isOpen, request} = useSelector(dialogs).cancelRequest;
    const isLoading = useSelector(loadings).cancelRequest;
    const {t} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.dialogs'});

    const handleClose = () => dispatch(hideCancelRequest());

    const handleSubmit = () => {
        request && dispatch(CancelRequest({requestId: request.id}));
    }

    return(
        <>
            {request &&
                <Dialog open={isOpen} onClose={handleClose}>
                    <DialogTitle>{t('Cancel request for {{sendTo}}', {sendTo: request.sendTo})}</DialogTitle>

                    <DialogContent>
                        <Typography lineHeight={'normal'} variant={'body1'}>{t(`Are you sure you want cancel the request?`)}</Typography>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose} variant={'text'} size={'medium'}>{t('No, close')}</Button>
                        <LoadingButton onClick={handleSubmit} size={'medium'} loading={isLoading}>{t('Yes, cancel request')}</LoadingButton>
                    </DialogActions>
                </Dialog>
            }
        </>
    )
}