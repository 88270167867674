import {Box, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import React, {ReactElement} from "react";
import {MenuItemNoHover} from "../styled";

export const TopBarMenuItem = ({children, isMobile = false, title, text, onClick, isDesktop = true}: {children: ReactElement, isMobile?: boolean, title: string, text: string, onClick: () => void, isDesktop?: boolean}) => {
    return (
        <MenuItemNoHover sx={{padding: !isDesktop ? '0 12px 0 0' : '0 12px'}} onClick={onClick}>
            <Box sx={{padding: '12px', paddingLeft: isMobile ? '20px' : '12px', width: '100%', display: 'flex', gap: '12px', alignItems: 'center', borderRadius: '6px', '&: hover': {backgroundColor: colors.backgrounds.optional}}}>
                {children}
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <Typography variant={'*bodyText2_semibold'} color={colors.primary.blue_dark}>{title}</Typography>
                    <Typography variant={'*bodyText2'} color={colors.text.grey_dark}>{text}</Typography>
                </Box>
            </Box>
        </MenuItemNoHover>
    )
}