import React, {FC, useEffect, useState} from "react";
import {Button, DialogActions, DialogContent, DialogTitle, Skeleton, Typography} from "@mui/material";
import {TExportCsvField, TFieldsMapperKey} from "../../types";
import CommonCheckbox from "../Basic/CommonCheckBox";
import {ColumnRowBox, CommonDialog, FieldsContainer} from "./styled";
import {useMainTranslation} from "../../hooks/useMainTranslationHooks/useMainTranslation";
import GetAppIcon from "@mui/icons-material/GetApp";
import {LoadingButton} from "../Basic/CommonButtons";
import {fieldsMapper} from "../../constants";


interface ExportCsvDialogProps {
    isOpen: boolean;
    isLoadingFields: boolean;
    isLoadingExport: boolean;
    availableFields: TExportCsvField[];
    fieldsMapperKey: TFieldsMapperKey;

    //actions
    handleClose: () => void;
    handleExportCsv: (fieldKeys: string[]) => void;
}


export const ExportCsvDialog: FC<ExportCsvDialogProps> = (props) => {

    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'CommonComponents.exportCsvDialog'});
    const {t: tExport} = useMainTranslation('', {keyPrefix: 'CommonComponents.ImportExportDialogs'});



    const {
        isOpen,
        isLoadingFields,
        isLoadingExport,
        availableFields,
        fieldsMapperKey,
        handleClose,
        handleExportCsv,
    } = props;

    const [checkedFields, setCheckedFields] = useState<TExportCsvField[]>([]);
    const [selectAll, setSelectAll] = useState<boolean>(true);

    const handleToggleField = (field: TExportCsvField) => {
        if (checkedFields.some(e => e.fieldKey === field.fieldKey)) {
            setCheckedFields(checkedFields.filter(e => e.fieldKey !== field.fieldKey));
            selectAll && setSelectAll(false);
        }
        else {
            setCheckedFields([...checkedFields, field]);
        }
    }

    const handleToggleSelectAll = (checked: boolean) => {
        if (checked) {
            setCheckedFields(availableFields);
            setSelectAll(true);
        }
        else {
            setCheckedFields([]);
            setSelectAll(false);
        }
    }

    const handleExport = () => {
        handleExportCsv(checkedFields.map(e => e.fieldKey))
    }


    useEffect(() => {
        if (isOpen) {
            setSelectAll(true);
            setCheckedFields(availableFields);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    useEffect(() => {
        isOpen && setCheckedFields(availableFields);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableFields])

    useEffect(() => {
        if (checkedFields.length !== 0 && checkedFields.length === availableFields.length) setSelectAll(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedFields])



    return (
        <CommonDialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Export CSV')}</DialogTitle>
            <DialogContent sx={{display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%'}}>
                <Typography variant={"body2"} sx={{flexShrink: 0}}>{t('Select fields you would like to export to a CSV file. Filters will not be applied.')}</Typography>

                <CommonCheckbox formSx={{margin: '0 6px', flexShrink: 0}}
                                disableRipple label={t('Select all')}
                                disabled={isLoadingFields || isLoadingExport}
                                checked={selectAll} onChange={() => handleToggleSelectAll(!selectAll)} />

                <FieldsContainer>
                    {isLoadingFields &&
                        <>
                            <ColumnRowBox key={'skeleton_key_1'}>
                                <Skeleton variant="rectangular" width={'18px'} />
                                <Skeleton width={'50%'} sx={{margin: '10px 16px'}} />
                            </ColumnRowBox>
                            <ColumnRowBox key={'skeleton_key_2'}>
                                <Skeleton variant="rectangular" width={'18px'} />
                                <Skeleton width={'50%'} sx={{margin: '10px 16px'}} />
                            </ColumnRowBox>
                            <ColumnRowBox key={'skeleton_key_3'}>
                                <Skeleton variant="rectangular" width={'18px'} />
                                <Skeleton width={'50%'} sx={{margin: '10px 16px'}} />
                            </ColumnRowBox>
                        </>
                    }
                    {!isLoadingFields &&
                        availableFields.map(e => (
                            <ColumnRowBox key={e.fieldKey}>
                                {fieldsMapperKey === "kyc" ?
                                    <CommonCheckbox disableRipple checked={checkedFields.some(field => field.fieldKey === e.fieldKey)}
                                                    label={ e.fieldTitle }
                                                    onChange={() => handleToggleField(e)} />
                                    :
                                    <CommonCheckbox disableRipple checked={checkedFields.some(field => field.fieldKey === e.fieldKey)}
                                                    label={ fieldsMapper[fieldsMapperKey][e.fieldKey] ? tExport(fieldsMapper[fieldsMapperKey][e.fieldKey]) : e.fieldKey }
                                                    onChange={() => handleToggleField(e)} />
                                }
                            </ColumnRowBox>
                        ))
                    }
                </FieldsContainer>

            </DialogContent>
            <DialogActions>
                <Button variant={"text"} onClick={handleClose} disabled={isLoadingExport}>{tCommon('Cancel')}</Button>
                <LoadingButton loading={isLoadingExport} startIcon={<GetAppIcon />} onClick={handleExport} disabled={checkedFields.length === 0 || isLoadingExport}>
                    {t('Export')}
                </LoadingButton>
            </DialogActions>
        </CommonDialog>
    )
}
