import {IKeyIndex, ItemTypes, TEditorActor} from "../../../../../../types";
import React, {FC, useEffect, useState} from "react";
import {useMainTranslation} from "../../../../../../../../hooks/useMainTranslationHooks/useMainTranslation";
import {validateEmail} from "../../../../../../../../utils/text";
import {ConnectableElement, DragSourceMonitor, useDrag, useDrop} from "react-dnd";
import * as S from '../../../styled';
import {Box, IconButton} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {TypographyBodySmall} from "../../../../../../../Inputs/styled";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {ReactComponent as MessageIcon} from "../../../../../../../../../newShared/images/documents/message-icon.svg";
import {
    ReactComponent as CompleteMessageIcon
} from "../../../../../../../../../newShared/images/documents/complete-message-icon.svg";
import {
    ReactComponent as RecipientIcon
} from "../../../../../../../../../newShared/images/documents/recipient-icon.svg";
import {TForm, TIdEditorRecipient} from "../../staff";
import {optionIsTCollaborator} from "../../../../../../../AutocompleteCollaborators/types";
import {AutocompleteCollaborators} from "../../../../../../../AutocompleteCollaborators";
import {defaultGetOptionDisabled} from "../../../../../../../AutocompleteCollaborators/helpers";

interface RecipientItemProps {
    form: TForm,
    isRecipientsCustomOrder: boolean,
    index: number,
    recipient: TIdEditorRecipient;

    handleDelete: (recipientId: string) => void;
    handleOpenAddMessage: (data: {recipientId: string, oldMessage: string}) => void;
    handleSetActorRecipient: (recipientId: string, actor: TEditorActor) => void;
    moveColumn: (key: string, atIndex: number) => void,
    findColumn: (key: string) => {column: TIdEditorRecipient, index: number} | null

    touchedField: string | null;
    setTouchedField: (id: string | null) => void;
    errorActiveFields: string[];
    setErrorActiveFields: (ids: string[]) => void;
    setRecipientsError: (data: Record<string, boolean>) => void;
}
export const RecipientItemEditor: FC<RecipientItemProps> = (props) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});
    const {
        form,
        index,
        recipient,
        isRecipientsCustomOrder,
        handleDelete,
        handleSetActorRecipient,

        handleOpenAddMessage,

        findColumn,
        moveColumn,

        // touchedField,
        // setTouchedField,
        errorActiveFields,
        setErrorActiveFields,
        setRecipientsError,
    } = props;

    const [newRecipient, setNewRecipient] = useState<TEditorActor | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        handleSetActorRecipient(recipient.id, newRecipient as TEditorActor);
        handleIsOkEmail({id: recipient.id, email: newRecipient?.email || ''});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(newRecipient)])

    const handleIsOkEmail = ({id, email}:{id: string, email: string}): boolean => {
        if(email.length === 0) {
            setErrorActiveFields(errorActiveFields.filter(e => e !== id));
            return true;
        }
        else if (!validateEmail(email)) {
            setError('Invalid email');
            setErrorActiveFields([...errorActiveFields, id]);
            return false;
        }
        else if (form.recipients.filter(r => r.id !== id ).some(r =>  r.actor?.email === email)) {
            setError('Email already exists');
            setErrorActiveFields([...errorActiveFields, id]);
            return false;
        }

        setErrorActiveFields(errorActiveFields.filter(e => e !== id));
        return true; //!error
    }

    useEffect(() => {
        if (recipient) {
            setNewRecipient(recipient.actor)
            setError(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipient.sourceEmailRole])

    const searchedColumn = findColumn(recipient.id);
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.CARD,
            item: {key: recipient.id, index: searchedColumn?.index ?? -1},
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const { key: droppedKey, index: originalIndex } = item;

                // console.log(`ROW useDrag END with item ${JSON.stringify(item)}`);
                const didDrop = monitor.didDrop();
                if (!didDrop && item) {
                    moveColumn(droppedKey, originalIndex);
                    // console.log(`ROW useDrag - moveColumn exec: ${droppedKey} | ${originalIndex}`);
                }
            },
        }),
        [recipient.id, searchedColumn, moveColumn],
    )

    const [, drop] = useDrop(
        () => ({
            accept: ItemTypes.CARD,
            hover({ key: draggedKey }: IKeyIndex) {
                // console.log(`Card useDrop draggedKey:${draggedKey} in cardId ${column.key}`);
                if (draggedKey !== recipient.id) {
                    const col = findColumn(recipient.id)
                    if(col){
                        // console.log(`ROW useDrop - moveColumn exec: ${draggedKey} | ${col.index}`);
                        moveColumn(draggedKey, col.index);
                    }else{
                        // console.log(`ROW useDrop - col not found by key ${column.key}`)
                    }
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
                isActive: monitor.canDrop() && monitor.isOver(),
            }),
        }),
        [findColumn, moveColumn],
    )



    return (
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} marginTop={'12px'} width={'100%'} >
            {isRecipientsCustomOrder && <S.OrderBox>{index+1}</S.OrderBox>}

            <S.RecipientsContainer
                isDragging={isDragging ? true : undefined}
                isOrdered={isRecipientsCustomOrder ? true : undefined}
                ref={(node: ConnectableElement) => drag(drop(node))}
            >
                <DragIndicatorIcon sx={{opacity: isRecipientsCustomOrder ? 0 : 1}}/>

                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                    <S.InfoActorFlexBox>
                        <RecipientIcon />
                        <TypographyBodySmall sx={{flexGrow: 1}} >{recipient.role || `${t('Recipient')} ${index+1}`}</TypographyBodySmall>

                        <IconButton size={'small'} onClick={() => handleOpenAddMessage({recipientId: recipient.id, oldMessage: recipient.message || ''})}>
                            {recipient.message  ?  <CompleteMessageIcon /> :  <MessageIcon />}
                        </IconButton>

                        <S.VerticalDivider />
                        <IconButton onClick={() => handleDelete(recipient.id)}>
                            <DeleteOutlineIcon />
                        </IconButton>
                    </S.InfoActorFlexBox>

                    <AutocompleteCollaborators
                        label={t('Email or name')}
                        getOptionLabelCustom={"name+email"}
                        freeSoloType={"email"}
                        freeSolo
                        defaultValue={recipient.actor?.email || ''}
                        helperText={error || undefined}
                        onChange={value => {
                            if (!optionIsTCollaborator(value)) {
                                setNewRecipient({email: value as string, firstName: '', lastName: ''});
                            } else {
                                setNewRecipient({email: value?.email || '', firstName: value?.firstName || '', lastName: value?.lastName || ''});
                            }
                        }}
                        getOptionDisabled={defaultGetOptionDisabled(form.recipients.map(e => e.actor?.email).filter(e => e !== recipient.actor?.email))}

                        sx={{width: '100%', '& label': {top: '0px !important'}}}

                        // onFocus={() => {
                        //     setTouchedField(recipient.id);
                        //     setError(null);
                        // }}
                        // onBlur={() => {
                        //     setTouchedField(null);
                        //     handleIsOkEmail({id: recipient?.id, email: recipient.actor.email})
                        // }}

                        onError={(error) => {
                            setRecipientsError({[recipient.id]: error});
                        }}
                    />


                </Box>
            </S.RecipientsContainer>
        </Box>
    )

}