import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
    assetsDialogsSelector,
    assetsSelector,
    assetsSettingsSelector,
    cleanPhysicalAssets,
    hideExportCsv,
    loadings,
    minMaxLoadedPage,
    openExportCsv,
    openReleaseDialog,
    pageInfoSelector,
    replaceMinMaxLoadedPage,
    selectAsset,
    setImportCsvDialogAction
} from "../../store/store";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TAsset} from "../../types";
import {PATH_LOCAL_ASSET_MANAGEMENT} from "../../../../../newShared/constants";
import {
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS,
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_ID,
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_NEW,
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_SETTINGS
} from "../../constants";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import React, {useEffect, useState} from "react";
import {
    exportCsvPhysicalAssetsAction,
    getPhysicalAssetsAction,
    GetPhysicalAssetsAvailableFieldsForExport,
    getPhysicalAssetsSettingsAction
} from "../../store/actions";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {PhysicalAssetNameCountModel, PhysicalAssetStatusCountModel} from "../../../../../newShared/GQLTypes";


export const useAssetsList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {isLoadingList, isLoadingSettings, isLoadingExport, isLoadingFields} = useSelector(loadings);
    const assets = useSelector(assetsSelector);
    const _pageInfo = useSelector(pageInfoSelector);
    const dialogs = useSelector(assetsDialogsSelector);


    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    useSetBreadcrumbs([
        {
            title: tMenu('Asset Management'),
            path: PATH_LOCAL_ASSET_MANAGEMENT
        },
        {
            title: tMenu('Physical assets'),
            path: PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS
        },
    ]);

    // const controller = new AbortController();

    //search and filters
    // const [filter, setFilter] = useState<AssetsFilter>({
    //     regexName: '',
    //     prevSearch: '',
    //     status: '',
    //     model: '',
    // })


    // const getAssets = (page: number, count: number, _filter: AssetsFilter, enterDown: boolean) => {
    //     !isLoadingList && dispatch(getPhysicalAssetsAction({data: {
    //             page,
    //             count,
    //             filters: {
    //                 regexName: enterDown && _filter.regexName ?  [_filter.regexName] : (!enterDown && _filter.prevSearch ? [_filter.prevSearch] : []),
    //                 status: (_filter.status && _filter.status !== 'Any') ? [_filter.status] : [],
    //                 model: (_filter.model && _filter.model !== 'Any') ? [_filter.model] : [],
    //             }}, signal: controller.signal}))
    //     !isLoadingList && setFilter({..._filter, prevSearch: enterDown && _filter.regexName ? _filter.regexName  : (!enterDown && _filter.prevSearch ? _filter.prevSearch : '')})
    // }

    const getSettings = (signal?: AbortSignal) => {dispatch(getPhysicalAssetsSettingsAction({data: {organizationId: '', workspaceId: ''}, signal}))}
    // const clean = () => dispatch(cleanUp());

    const goToAsset = (asset: TAsset) => {
        if(asset) {
            dispatch(selectAsset(asset));
            history.push(`${PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_ID.replace(':id', asset.id)}`);
        }
    }

    useEffect(() => {
        getSettings();
        // return () => {
        //     clean();
        // }
        //eslint-disable-next-line
    }, []);

    const goToAddAsset = () => history.push(PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_NEW);
    const goToSettings = () => history.push(PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_SETTINGS);

    //MENU
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // const exportCsv = () => {
    //     handleClose();
    //     dispatch(exportCsvPhysicalAssetsAction())
    // };
    const handleOpenExportCsv = () => {
        dispatch(openExportCsv());
        dispatch(GetPhysicalAssetsAvailableFieldsForExport());
    }

    const handleExportCsv = (fieldKeys: string[]) => {
        handleClose();
        dispatch(exportCsvPhysicalAssetsAction({fieldKeys, isDownload: _pageInfo.total < 10000}))
    };

    const handleCloseExportCsv = () => {
        dispatch(hideExportCsv());
    }

    const handleImportCSV = () => {
        dispatch(setImportCsvDialogAction({isOpen: true}));
    }

    //filters
    // const setSearch = (search: string) => setFilter({...filter, regexName: search});

    // const handleCleanSearch = () => {
    //     const newFilter = {...filter, regexName: '', prevSearch: ''};
    //     setFilter(newFilter);
    //     getAssets(0, DEFAULT_COUNT, newFilter, false);
    // }

    // const handleEnterKey = (e: any) => {
    //     if(e.keyCode === 13){
    //         getAssets(0, DEFAULT_COUNT, filter, true);
    //     }
    // }

    // const handleOnChangeModel = (model: string) => {
    //     if (model === 'Any') {
    //         const newFilter = {...filter, model: '' };
    //         setFilter(newFilter);
    //         getAssets(0, DEFAULT_COUNT, newFilter, false);
    //     }
    //     if (model !== 'Any') {
    //         const newFilter = {...filter, model };
    //         setFilter(newFilter);
    //         getAssets(0, DEFAULT_COUNT, newFilter, false);
    //     }
    // }
    //
    // const handleOnChangeStatus = (status: string) => {
    //     if (status === 'Any') {
    //         const newFilter = {...filter, status: '' };
    //         setFilter(newFilter);
    //         getAssets(0, DEFAULT_COUNT, newFilter, false);
    //     }
    //     if (status !== 'Any') {
    //         const newFilter = {...filter, status };
    //         setFilter(newFilter);
    //         getAssets(0, DEFAULT_COUNT, newFilter, false);
    //     }
    // }

    const handleOpenRelease = () => {
        dispatch(openReleaseDialog({assetType: 'physical'}));
        handleClose();
    }


    // pagination
    // const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
    //     getData: ({page, count}) => {
    //         getAssets(
    //             page, count, filter,false
    //         )
    //     },
    //     arrayLength: assets.length,
    //     isLoading: isLoadingList,
    //     pageInfo: _pageInfo,
    //     cleanData: () => {},
    //
    // })

    //
    const settings = useSelector(assetsSettingsSelector);
    const {minLoadedPage, maxLoadedPage} = useSelector(minMaxLoadedPage);

    // status
    //model
    const modelConfig: keyConfig<PhysicalAssetNameCountModel> = {
        type: 'filter',
        key: 'model',
        name: ('Model'),
        options: settings?.model ?? [],
        getOptionLabel: (o) => o.name,
        getFilterValue: (o) => o.name,
        isOptionEqual: (a, b) => a.name === b.name,
        isOptionEqualToSearch: (a, search) => a.name.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: true
    }

    const statusConfig: keyConfig<PhysicalAssetStatusCountModel> = {
        type: 'filter',
        key: 'status',
        name: ('Status'),
        options: settings?.status ?? [],
        getOptionLabel: (o) => o.name,
        getFilterValue: (o) => o.name,
        isOptionEqual: (a, b) => a.name === b.name,
        isOptionEqualToSearch: (a, search) => a.name.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: true
    }

    return {
        settings,
        // setSearch,
        // hasMore: _pageInfo.total > ((_pageInfo.page + 1) * _pageInfo.count),
        assets,
        goToAsset,
        goToAddAsset,
        goToSettings,
        // importCsv,
        handleImportCSV,
        handleOpenExportCsv,
        handleOpenRelease,

        menu: {
            anchorEl,
            handleClose,
            handleClick,
            open,
        },

        // filter: {
        //     filter,
        //     setFilter,
        //     setSearch,
        //     handleEnterKey,
        //     handleCleanSearch,
        //     handleOnChangeModel,
        //     handleOnChangeStatus
        // },

        exportCsv: {
            isOpen: dialogs.exportCsv.isOpen,
            availableFields: dialogs.exportCsv.fields,
            handleExportCsv,
            handleClose: handleCloseExportCsv,
        },

        isLoadingList,
        isLoadingFields,
        // isShowingNoAssets: !isLoadingList && assets.length === 0 && !filter.prevSearch && !filter.status?.length && !filter.regexName.length && !filter.model?.length,
        isLoadingSettings,
        // handleLoadMore,
        isLoadingExport,

        // pagination: {
        //     bottomLoader: bottomLoader && assets.length < _pageInfo.total,
        //     onScroll,
        //     scrollRef
        // },
        genericTable: {
            paging: {
                pageInfo: _pageInfo,
                isLoading: isLoadingList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceMinMaxLoadedPage(data)),
                clearRows: () => dispatch(cleanPhysicalAssets()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    dispatch(getPhysicalAssetsAction({data: {page, count, filters}}));
                },
            },
            filters: {
                configs: [statusConfig, modelConfig]
            }
        }

    }


}
