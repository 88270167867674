import {ReactNode} from "react";
import {Flex} from "../../../../../../../../newShared/components/Layouts";
import {Typography} from "@mui/material";
import colors from "../../../../../../../../newShared/theme/colors";
import Divider from "@mui/material/Divider";

export const TrainingModuleWrapper = ({description, children, margin, childGap}:{description: string | ReactNode | ReactNode[], children: ReactNode | ReactNode[], margin?: string, childGap?: string}) => {
    return(
        <>
            <Flex w={'100%'} jc={'space-between'} gap={'16px'} m={margin} p={'20px'}>
                {/*left side*/}
                <Flex w={'30%'} direction={'column'} gap={'12px'}>
                    {typeof description === 'string' ?
                        <Typography sx={{whiteSpace: 'break-spaces'}} variant={'*bodyText2'} color={colors.grayText}>{description}</Typography>
                        :
                        <>{description}</>
                    }
                </Flex>

                <Flex w={'70%'} direction={'column'} gap={childGap}>
                    {children}
                </Flex>
            </Flex>
            <Divider />
        </>
    )
}