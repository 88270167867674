import React, {FC} from "react";
import {ChipBox, ChipTitle} from "./styled";

interface CommonChipsProps {
    settings: {
        title: string;
        color: string;
        background: string;
        margin?: string;
    },
}
export const CommonChips: FC<CommonChipsProps> = ({settings}) => {
    const {title, color, background, margin} = settings;

    return(
        <ChipBox background={background} color={color} margin={margin}>
            <ChipTitle color={color} noWrap>{title}</ChipTitle>
        </ChipBox>
    )
}
