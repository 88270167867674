//core
import {print} from "graphql";
import {AxiosResponse} from "axios";
import {gql} from "@apollo/react-hooks";
import axiosSync from '../../../../newShared/utils/axios/axios';

//consts
import {PATH_SERVER} from '../../../../newShared/constants';

//types
import {SendSupportMessageMutation, SendSupportMessageMutationVariables} from "../../../../newShared/GQLTypes";
import {buildCommonHeader} from "../../../../newShared/utils/builders";


export const sendSupportMessageAPI = async (variables: SendSupportMessageMutationVariables): Promise<SendSupportMessageMutation["sendSupportMessage"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation sendSupportMessage($data:SupportMessageInput!, $wid: String!){
                        sendSupportMessage(data: $data, workspaceId: $wid){
                            isSent
                        }
                    }
                `),
                variables,
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.sendSupportMessage;
    })
)
