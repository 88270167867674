import {useNonCustomAppAccountMapDialog} from "../../../hooks/dialogHooks/useNonCustomAppAccountMapDialog";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {DialogContentWrapper} from "../../../../newIntegrations/components/styled";
import {Autocomplete} from "@mui/lab";
import {availableCreateRowTypes, EXTERNAL_TYPE, ORHAN_TYPE} from "../../../constants";
import * as S from "../../styled";
import {TextFieldCustom} from "../../styled";
import React from "react";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TShortEmployee} from "../../../../employeesRefactor/types";

export const NonCustomAppAccountMap = () => {
    const {
         form,
        isOpen,
        handleClose,
        row,
        handleSetType,
        handleSetEmployee,
        handleChange,
        employees,
        selectedEmployee,
        handleSubmit,
        isOk,
        isLoading
    } = useNonCustomAppAccountMapDialog();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.AccessManagementAppsExact.dialogs'});

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t(`Map {{account}} account`, {account: row?.account ?? ''})}</DialogTitle>
            <DialogContent>
                <DialogContentWrapper>
                    <Autocomplete
                        id="combo-box-demo"
                        options={availableCreateRowTypes}
                        value={form.accountType}
                        onChange={(e, newValue: string | null) => newValue && handleSetType(newValue)}
                        renderInput={(params) => <S.TextFieldCustom {...params} label={t("Select type")} size={'small'} required/>}
                        sx={{margin: '8px 0 0 0'}}
                    />

                    {form.accountType === EXTERNAL_TYPE || form.accountType === ORHAN_TYPE ?
                        <TextFieldCustom
                            required
                            value={form.employee}
                            name={'employee'}
                            onChange={handleChange}
                            label={t('Type employee')}
                            disabled={form.accountType === ORHAN_TYPE}
                            size={'small'}
                            sx={{margin: '8px 0 0 0'}}
                        />
                        :
                        <Autocomplete
                            id="combo-box-demo"
                            options={employees.filter(e => !['terminated', 'hiring'].includes(e.status?.toLowerCase() || ''))}
                            getOptionLabel={(option: TShortEmployee) => `${option.firstName} ${option.lastName}`}
                            // loading={isLoadingEmployees}
                            value={selectedEmployee}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(e, newValue: TShortEmployee | null) => newValue && handleSetEmployee(newValue)}
                            renderInput={(params) => <TextFieldCustom {...params} label={t("Select employee")} size={'small'} required/>}
                            sx={{margin: '8px 0 0 0'}}
                        />
                    }
                </DialogContentWrapper>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'outlined'}>{t('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    disabled={!isOk}
                    onClick={handleSubmit}
                >
                    {t('Save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>

    )
}