import {filterType, keyConfig, TFetchOptions, TResultFetchOptions} from "./types";
import {CommonFilterType} from "../genericTable/types";

export const sortFilters = (filterA: keyConfig<any>, filterB: keyConfig<any>, defaults: string[], selectionSequence: string[]): number => {
    const isDefaultA = defaults.some(e => e === filterA.key);
    const isDefaultB = defaults.some(e => e === filterB.key);
    if(isDefaultA && isDefaultB){
        return 0;
    }else{
        if(isDefaultA && !isDefaultB){
            return 0;
        }else if(!isDefaultA && isDefaultB){
            return -1;
        }
        //returning original sorting if its not default filters (by added order)
        return selectionSequence.indexOf(filterA.key) - selectionSequence.indexOf(filterB.key)
    }
}

export const sortAddFiltersList = (filterA: filterType, filterB: filterType, disabledChangeOptions: string[]): number => {
    const isDisabledA = disabledChangeOptions.some(e => e === filterA.key);
    const isDisabledB = disabledChangeOptions.some(e => e === filterB.key);
    if(isDisabledA && isDisabledB){
        return 0;
    }else{
        if(isDisabledA && !isDisabledB){
            return 0;
        }else if(!isDisabledA && isDisabledB){
            return 1;
        }
        return 0;
    }
}

export const getFiltersForFetchByFullObjectFilter = (configs: keyConfig<any>[], filters: Record<string, any[]>): CommonFilterType => {
    // console.log(`getFiltersForFetchByFullObjectFilter`, filters);
    const res: CommonFilterType = {};

    Object.entries(filters).forEach((e) => {
        const key = e[0];
        const values = e[1];
        const currentConfig = configs.find(f =>  f.key === key);
        if(currentConfig){
           let filterValues: any[] = [];

           values.forEach((v) => {
               if(v){
                   if(currentConfig.getFilterValue){
                       const value = currentConfig.getFilterValue(v);
                       // console.log(`Found filter:
                       //      key: ${key}
                       //      fullValue: ${v}
                       //      valueForFetch: ${value}
                       // `)
                       value && filterValues.push(value);
                   }else{
                       // console.log(`Found filter:
                       //      key: ${key}
                       //      fullValue: ${v}
                       //      valueForFetch: getFilterValue not found
                       // `)
                       filterValues.push(v);
                   }
               }
           })

           res[currentConfig.key] = filterValues;
        }
    })

    return res;
}

export const resultToValues = <T>(func: TResultFetchOptions<T>): TFetchOptions<T> => (workspaceId, data, signal) => {
    return func(workspaceId, data, signal).then(({result, pageInfo}) => ({values: result, pageInfo}))
}
