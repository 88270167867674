import {MainTrainingsSectionsShortDataForImportResponseModel} from "../../../../../newShared/GQLTypes"
import {useSelector, useDispatch} from "react-redux";
import {sectionsToAddSelector, deselectExistingSection, selectExistingSection} from "../../store/slice";
import React, {useState} from "react";

export const useSectionsBlock = (trainingSection: MainTrainingsSectionsShortDataForImportResponseModel) => {
    const dispatch = useDispatch();
    const {selectedSections} = useSelector(sectionsToAddSelector);
    const [isExpanded, setIsExpanded] = useState(false);
    const isCheckboxChecked = trainingSection.sections?.every(e => selectedSections.some(a => e.sectionId === a.sectionId));
    const isCheckboxUnchecked = !trainingSection.sections?.some(e => selectedSections.some(a => e.sectionId === a.sectionId));
    const isCheckboxIndeterminate = !isCheckboxUnchecked && !isCheckboxChecked;
    const handleCheckboxSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsExpanded(true);
        if(trainingSection.sections?.every(e => selectedSections.some(a => a.sectionId === e.sectionId))) {
            trainingSection.sections?.forEach(e => dispatch(deselectExistingSection(e)));
        } else {
            trainingSection.sections?.forEach(e => dispatch(selectExistingSection(e)));
        }

    }

    return {
        isCheckboxChecked,
        isCheckboxIndeterminate,
        handleCheckboxSelect,
        isExpanded,
        setIsExpanded,
    }
}