import {dialogs, hideEditJobTitleDialog, hrSettings, loadings} from "../../../../store/slice";
import {useDispatch, useSelector} from "react-redux";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    TextField
} from "@mui/material";
import React, {useEffect} from "react";
import {DialogContentWrapper} from "../../../styled";
import {useForm} from "../../../../../../../newShared/hooks/useForm";
import {EditJobTitleType} from "../../../../types";
import {defaultJobTitle} from "../../../../constants";
import {LoadingButton} from "../../../../../../../newShared/components/Basic/CommonButtons";
import {CommonRadio} from "../../../../../../../newShared/components/Basic/CommonRadio";
import {EditHrSettingsJobTitle} from "../../../../store/actions";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const EditJobTitleDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathHRManagement.dialogs'});
    const {tCommon} = useMainTranslation();

    const dispatch = useDispatch();
    const {isOpen, jobTitle} = useSelector(dialogs).editJobTitle;
    const {jobType} = useSelector(hrSettings);
    const isLoading = useSelector(loadings).editJobTitle;

    const {form, setForm, handleChange, setValue} = useForm<EditJobTitleType>(defaultJobTitle);

    useEffect(() => {
        if(isOpen && jobTitle){
            setForm({...jobTitle, history: false});
        }else if(!isOpen){
            setForm(defaultJobTitle)
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const handleClose = () => dispatch(hideEditJobTitleDialog());

    const handleSubmit = () => {
        if(isOk()){
            dispatch(EditHrSettingsJobTitle({
                jobTitle: {
                    name: form.name,
                    workspaceId: form.workspaceId,
                    requirements: form.requirements,
                    description: form.description,
                    count: form.count,
                    id: form.id,
                    jobType: form.jobType
                },
                history: form.history
            }))
        }
    }

    const isOk = ():boolean => {
        return form.name.trim().length > 0 &&
            form.jobType !== null &&
            form.description.trim().length > 0 &&
            form.requirements.trim().length > 0 &&
            JSON.stringify(form) !== JSON.stringify({...jobTitle, history: form.history}) //to not affect on check
    }

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{tCommon('Edit')} {jobTitle?.name}</DialogTitle>

            <DialogContent>
                <DialogContentWrapper margin={'0'}>
                    {jobTitle &&
                        <>
                            <TextField
                                value={form.name}
                                onChange={handleChange}
                                name={'name'}
                                fullWidth
                                required
                                sx={{margin: '16px 0 0 0'}}
                            />

                            <Autocomplete
                                options={jobType}
                                value={form.jobType}
                                onChange={(e, newValue) => setValue('jobType', newValue)}
                                sx={{margin: '16px 0 0 0'}}
                                renderInput={(params) => <TextField {...params} label={t('Job type')} size={'small'} required/>}
                            />

                            <TextField
                                value={form.requirements}
                                onChange={handleChange}
                                name={'requirements'}
                                label={t('Requirements')}
                                fullWidth
                                required
                                multiline
                                minRows={5}
                                sx={{margin: '16px 0 0 0'}}
                            />

                            <TextField
                                value={form.description}
                                onChange={handleChange}
                                name={'description'}
                                label={t('Description')}
                                fullWidth
                                required
                                multiline
                                minRows={5}
                                sx={{margin: '16px 0 0 0'}}
                            />
                        </>
                    }
                </DialogContentWrapper>

                <FormControl sx={{marginTop: '16px'}}>
                    <FormLabel
                        id="demo-radio-buttons-group-label">{t('How should this change occur?')}</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="withoutHistory"
                        name="radio-buttons-group"
                        value={form.history ? 'includingHistory' : 'withoutHistory'}
                        onChange={(e, value) => setValue('history', Boolean(value === 'includingHistory'))}
                    >
                        <FormControlLabel value="withoutHistory" control={<CommonRadio />}
                                          sx={{marginLeft: '-7px'}}
                                          label={t("Change anywhere it is used, without history")}/>
                        <FormControlLabel value="includingHistory" control={<CommonRadio />}
                                          sx={{marginLeft: '-7px'}}
                                          label={t("Change anywhere it is used, including history")}/>
                    </RadioGroup>
                </FormControl>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{tCommon('Close')}</Button>

                <LoadingButton onClick={handleSubmit} loading={isLoading} disabled={!isOk()}>
                    {tCommon('Edit')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
