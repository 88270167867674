import * as React from "react";
import {FC, useCallback, useEffect, useState} from "react";
import {CircularProgress, IconButton, InputAdornment, Popper, TextField} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import colors from "../../../../../newShared/theme/colors";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import {SaveChangeNameButton} from "../styled";
import {FlexRow} from "../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import {AnySchema, ValidationError} from "yup";


type TChangeNameFieldProps = {
    initialValue: string,
    schema: AnySchema,

    onSave: (name: string) => void,
    isLoading: boolean,

    margin?: string,
}

export const ChangeNameField: FC<TChangeNameFieldProps> = ({initialValue, onSave, margin = '12px 0', isLoading, schema}) => {

    const [value, setValue] = useState<string>(initialValue);
    const [editable, setEditable] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const handleChange = (value: string) => {
        setValue(value);
        validateValue(value, schema);
    };
    const handleEditMode = () => {
        handleChange(initialValue);
        setEditable(true);
    };

    const validateValue = async (
        value: string,
        schema: AnySchema
    ) => {
        try {
            await schema.validate(value);
            setError(null);
        } catch (error) {
            if (error instanceof ValidationError) setError(error.message)
            else setError('An unexpected error occurred')
        }
    };

    const handleSave = () => {
        if (value.trim() === initialValue.trim()) handleCancel()
        else !error && onSave(value)
    };
    const handleCancel = () => {
        setEditable(false);
        setValue(initialValue);
    }

    const textFieldRef = React.useRef<HTMLDivElement>(null);
    const popperRef = React.useRef<HTMLDivElement>(null);

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (
            textFieldRef.current && !textFieldRef.current.contains(event.target as Node) &&
            popperRef.current && !popperRef.current.contains(event.target as Node)
        ) {
            handleCancel();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        editable && textFieldRef.current?.querySelector('input')?.focus();
        // editable && textFieldRef.current?.focus();
    }, [editable]);

    useEffect(() => {
        setValue(initialValue);
        setEditable(false);
        setError(null);
    }, [initialValue]);

    return (
        <>
            <TextField id={'change-name-field'}
                       value={value}
                       disabled={!editable}
                       variant={"outlined"}
                       size={'small'}
                       onChange={(e) => handleChange(e.target.value)}
                       sx={{
                           // margin,
                           '& .MuiOutlinedInput-root': {
                               '& input': {
                                   '-webkit-text-fill-color': 'black',
                                   fontWeight: !editable ? 600 : undefined,
                               },
                               '& fieldset': {border: !editable ? 'none' : undefined }
                           },
                           '& .MuiOutlinedInput-root:hover': {
                               backgroundColor: editable ? 'transparent' : colors.backgrounds.grey_light,
                           },
                           '& .MuiOutlinedInput-root:not(:hover)': {
                               '& .editorIcon': {display: 'none'},
                           },
                       }}
                       InputProps={{
                           ref: textFieldRef,
                           endAdornment: (
                               <InputAdornment position={'end'}>
                                   {!editable && <IconButton className={'editorIcon'} onClick={handleEditMode} size={"small"} disableRipple><EditIcon/></IconButton>}
                                   {isLoading ? <CircularProgress size={24} style={{color: colors.stroke.grey}} /> : undefined}
                               </InputAdornment>
                           )
                       }}

                       error={!!error}
                       helperText={error}
            />


            <Popper open={editable} anchorEl={textFieldRef.current}
                    placement={'bottom-end'}
                    sx={{
                        zIndex: 3100,
                    }}
            >
                <FlexRow sx={{gap: '4px', position: 'relative', top: '4px'}} ref={popperRef}>
                    <SaveChangeNameButton onClick={handleCancel}>
                        <CloseIcon style={{color: colors.primary.blue}} />
                    </SaveChangeNameButton>
                    <SaveChangeNameButton onClick={handleSave} disabled={isLoading || !!error}>
                        <CheckIcon style={{color: !!error || isLoading ? colors.stroke.grey : colors.primary.blue}}/>
                    </SaveChangeNameButton>
                </FlexRow>
            </Popper>
        </>
    )
}
