import {VendorInput} from "../../../newShared/GQLTypes";

export const vendorDefault: VendorInput = {
    id: 'NEW',
    name: '',

    link: '',
    owner: null,
    risk: '',
    serviceProvided: '',
    type: '',

    customFields: [],
    documents: [],

    evaluation: {
        evaluator: null,
        price: 0,
        quality: 0,
        service: 0,
        notesAndRecommendations: ''
    }
    }

export const ALL_FILTER_SELECT = 'All';

export const DEFAULT_COUNT = 30;
export const RISK_ID = 'risk';
export const TYPE_ID = 'type';
export const SERVICE_PROVIDED_ID = 'serviceProvided';
export const CUSTOM_FIELDS_ID = 'customFields';
export const IMPORT_SCV_FILE_NOT_VALID_MESSAGE = 'File is not valid';
export const IMPORT_CSV_FILE_SUCCESS_MESSAGE = 'Import finished successfully';


//paths
export const PATH_LOCAL_VENDORS_MANAGEMENT= "/vendors_management";
export const PATH_LOCAL_VENDORS = `${PATH_LOCAL_VENDORS_MANAGEMENT}/vendors`;
export const PATH_LOCAL_VENDORS_SETTINGS = `${PATH_LOCAL_VENDORS_MANAGEMENT}/settings`;
export const PATH_LOCAL_VENDORS_SELECTED_VENDOR = `${PATH_LOCAL_VENDORS}/:id`;
export const PATH_LOCAL_VENDORS_SETTINGS_EXACT = `${PATH_LOCAL_VENDORS_SETTINGS}/exact/:id`;
export const PATH_LOCAL_VENDORS_ADD = `${PATH_LOCAL_VENDORS}/new`;

