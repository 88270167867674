import React, {useState} from "react";

export const useMenu = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event?: any) => {
        event && event.stopPropagation();
        setAnchorEl(null);
    };

    return{
        anchorEl,
        open,
        handleClick,
        handleClose
    }
}