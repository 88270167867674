import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {
    deselectScreening,
    eraseIsDataOutdated,
    eraseScreeningEntities,
    isDataOutdated,
    loadings as loadingsSelector,
    openAddToBlackListDialog,
    openCreateException,
    openDeleteTargetException,
    openRemoveFromBlackListDialog,
    openShowClientDetail,
    openShowFullReport,
    replaceScreeningEntities,
    screeningsSelector
} from "../../../store/slice";
import React, {useEffect, useState} from "react";
import {
    CreateTargetException,
    GetKycScreeningFullInfoById,
    GetKycScreeningFullResultByEntityId,
    GetKycScreeningResults,
    SetKycTargetVerified
} from "../../../store/actions";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    KYC_CLIENTS_ID_FIELDS_PATH,
    KYC_ROOT_PATH,
    KYC_SCREENING_EXACT_PATH,
    KYC_SCREENING_LIST_PATH,
    KYC_TEMPLATES_EXACT_PATH
} from "../../../constants";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {KycScreeningResultModel} from "../../../../../../newShared/GQLTypes";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {getTargetTypeForPath} from "../../../helpers";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../../newShared/components/genericTable/types";


export const useScreeningReport = () => {
    const {tMenu} = useMainTranslation();
    const dispatch = useDispatch();
    const {id} = useParams<{id: string}>();
    const history = useHistory();
    const {selected, selectedScreeningResults, selectedScreeningResultsPageInfo, selectedScreeningResultsMinMaxLoadedPage: {maxLoadedPage, minLoadedPage}} = useSelector(screeningsSelector);
    const {isLoadingExact, setClientVerified: isLoadingSetClientVerified, createException: isLoadingCreateException, getScreeningResults} = useSelector(loadingsSelector);
    const _isDataOutdated = useSelector(isDataOutdated);

    // const controller = new AbortController();
    const [createExceptionResultId, setCrateExceptionResultId] = useState<string | null>(null);
    const [selectedEntityId, setSelectedEntityId] = useState<string | null>(null);


    const onSuccessCreateDefaultException = () => {
        setCrateExceptionResultId(null);
    }

    const createQuickException = (e: React.MouseEvent<HTMLElement, MouseEvent>, res:  KycScreeningResultModel) => {
        e.stopPropagation();
        setSelectedEntityId(res.entityId);
        setCrateExceptionResultId(res.entityId);
        (selected && res) &&  dispatch(CreateTargetException({
            data: {
                targetId: selected.screening.targetId,
                checkId: selected.screening.checkId,
                screeningId: selected.screening.id,
                entityId: res.entityId,
                entityCaption: res.entityCaption,
                score: res.score,
                reason: '',
                limited: false,
                till: null,
            },
            onSuccess: onSuccessCreateDefaultException
        }));
    }

    const createCustomException = (e: React.MouseEvent<HTMLElement, MouseEvent>, entityId: string, entityCaption: string) => {
        e.stopPropagation();
        selected && dispatch(openCreateException({entityId, entityCaption}));
        setSelectedEntityId(entityId);
    }


    const handleAddToBlackList = () => {
        selected && dispatch(openAddToBlackListDialog({clientId: selected.screening.targetId, clientName: selected.screening.targetName}));
    }

    const handleRestoreFromBlackList = () => {
        selected && dispatch(openRemoveFromBlackListDialog({selectedClient: selected.target}));
    }

    const handleSetClientVerified = () => {
        selected && dispatch(SetKycTargetVerified({workspaceId: '', targetId: selected.screening.targetId, screeningId: selected.screening.id }));
    }

    const handleShowClientDetail = () => {
        selected && dispatch(openShowClientDetail({fields: selected.target.fields}));
    }

    const handleDeleteException = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, exceptionId: string, entityId: string) => {
        e.stopPropagation();
        selected && dispatch(openDeleteTargetException({targetId: selected.screening.targetId, exceptionId}));
        setSelectedEntityId(entityId);
    }

    const handleViewFullReport = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, result: KycScreeningResultModel) => {
        e.stopPropagation();
        dispatch(openShowFullReport({entityId: result.entityId}));
        setSelectedEntityId(result.entityId);
    }

    //handleGoBack
    const handleGoBack = () => {
        dispatch(deselectScreening());
        history.push(KYC_SCREENING_LIST_PATH);
    }

    const handleGoToExactTarget = () => {
        if(selected){
            const pathName = getTargetTypeForPath(selected.target).pathName;
            selected && history.push(KYC_CLIENTS_ID_FIELDS_PATH.replace(':id', selected.target.id).replace(':type', pathName));
        }
    }

    const handleGoToExactTemplate = () => {
        selected && history.push(KYC_TEMPLATES_EXACT_PATH.replace(':id', selected.target.templateId));
    }

    const handleGoToLatestScreening = () => {
        if (selected?.screening.latestScreeningId && selected.screening.latestScreeningId !== selected.screening.id) {
            history.push(KYC_SCREENING_EXACT_PATH.replace(':id', selected.screening.latestScreeningId));
            dispatch(eraseIsDataOutdated());
            dispatch(GetKycScreeningFullInfoById({data: {screeningId: selected?.screening.latestScreeningId}}));
        }
    }

    //useEffects
    useSetBreadcrumbs([
        {
            title: tMenu('KYC Management'),
            path: KYC_ROOT_PATH
        },
        {
            title: tMenu('Screening'),
            path: KYC_SCREENING_LIST_PATH
        },
        {
            title: selected?.screening.targetName ?? 'Loading...',
        }
    ]);

    useEffect(() => {
       (!selected && id) && dispatch(GetKycScreeningFullInfoById({data: {screeningId: id}}));
        return () => {
            // controller.abort();
            dispatch(deselectScreening());
            setSelectedEntityId(null);
        }
        //eslint-disable-next-line
    }, []);


    // const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
    //     getData: ({page, count}) => {
    //         // dispatch(GetKycRequests({pageInfo: {page, count: REQUESTS_DEFAULT_PAGING, filters: {...currentFiltersForFetch, nameLike: currentSearchForFetch}}}));
    //         id && dispatch(GetKycScreeningResults({data: {pageInfo: {page, count: SCREENING_RESULTS_PAGING}, screeningId: id}}));
    //     },
    //     arrayLength: selectedScreeningResults.length,
    //     isLoading: getScreeningResults,
    //     pageInfo: selectedScreeningResultsPageInfo,
    //     // cleanData: () => dispatch(eraseCategories()),
    //     // controller: controller,
    //     // isOnTop: true,
    //     isNoMoreThanTotal: true,
    //     customDependencies: [],
    //     is0PageAddictive: false,
    //     disableInitFetch: true,
    // })

    const handleDownloadFullReport = (entityId: string, entityName: string) => {
        setSelectedEntityId(entityId);
        selected && dispatch(GetKycScreeningFullResultByEntityId({data: {entityId, screeningId: selected.screening.id, fileName: `${entityName}_${parseDateAuto(selected.screening.startDate, true)}.json`}}));

    }

    const isDisabledSetClientVerified = () => {
        // console.log(`
        // selectedScreeningResults.length: ${selectedScreeningResults.length} \n
        // selectedScreeningResultsPageInfo.total: ${selectedScreeningResultsPageInfo.total} \n
        // selectedScreeningResults.some(e => e.exceptionId === null): ${selectedScreeningResults.some(e => e.exceptionId === null)} \n
        // selectedScreeningResults.length === selectedScreeningResultsPageInfo.total && !selectedScreeningResults.some(e => e.exceptionId === null): ${selectedScreeningResults.length === selectedScreeningResultsPageInfo.total && !selectedScreeningResults.some(e => e.exceptionId === null)} \n
        // `)
        return !(selectedScreeningResults.length === selectedScreeningResultsPageInfo.total && //all screening result are visible
        !selectedScreeningResults.some(e => e.exceptionId === null)) //there is no screening results without exception
    }

    return {
        selected,
        selectedEntityId,
        isLoadingExact,
        isLoadingCreateException,
        isLoadingSetClientVerified,
        createExceptionResultId,
        handleGoBack,
        handleGoToExactTarget,
        handleGoToExactTemplate,
        handleAddToBlackList,
        handleRestoreFromBlackList,
        handleSetClientVerified,
        handleShowClientDetail,
        handleDeleteException,
        handleViewFullReport,
        createQuickException,
        createCustomException,
        isLatest: !selected?.screening.latestScreeningId || selected?.screening.latestScreeningId === selected?.screening.id,
        isDataOutdated: _isDataOutdated,
        handleGoToLatestScreening,
        totalMatches: selectedScreeningResultsPageInfo.total,
        // totalExceptions: selected?.exceptions?.length ?? 0,

        // isDisabledSetClientVerified: selectedScreeningResultsPageInfo.total !== selected?.exceptions?.length,
        isDisabledSetClientVerified: isDisabledSetClientVerified(),

        // scroll: {
        //     bottomLoader,
        //     onScroll,
        //     scrollRef,
        //     hasMore: selectedScreeningResultsPageInfo.total > ((selectedScreeningResultsPageInfo.page + 1) * selectedScreeningResultsPageInfo.count),
        //     isLoading: getScreeningResults,
        //     hasNoResults: !selectedScreeningResultsPageInfo.total && !getScreeningResults && !isLoadingExact,
        // },
        results: selectedScreeningResults,
        handleDownloadFullReport,
        genericTable: {
            paging: {
                pageInfo: selectedScreeningResultsPageInfo,
                isLoading: getScreeningResults,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceScreeningEntities(data)),
                clearRows: () => dispatch(eraseScreeningEntities()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    id && dispatch(GetKycScreeningResults({data: {pageInfo: {page, count}, screeningId: id}}));
                },
            },
        },
    }


}
