import {
    EmployeeType,
    ExactEmployeeTabs,
    SettingsTypes,
    TEmployeeJobHistory,
    TEmployeesInitialState,
    THrSettings,
    THrSettingsJobTitle,
    TShortDocumentByEmail,
} from "../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {
    AddJobRecord,
    AutocompleteManagers,
    CreateHrEmployee,
    CreateHrEmployeeSetting,
    CreateHrSettingsJobTitle,
    DeleteHrEmployeeById,
    DeleteHrEmployeeSetting,
    DeleteJobRecord,
    DeleteSettingsJobTitle,
    EditHrSettingsJobTitle,
    ExportCsvEmployees,
    getCsvFieldsEmployeesAction,
    GetEmployees,
    GetEmployeesAvailableFieldsForExport,
    getEmployeesTreeAction,
    GetHrEmployeeById,
    GetHrEmployeeJobInfosById,
    GetHrEmployeesSettings,
    getHrRequestsWithFilterPaginationAction,
    GetImportCsvHrManagementTemplate,
    GetShortDocumentsByWorkEmailWithFilterPaging,
    mainHrApproveRequestAction,
    mainHrApproveTimeOffRequestAction,
    mainHrDeleteRequestAction,
    mainHrGetAndChangeStepStatusTimeOffRequestAction,
    mainHrGetIssueByIdAction,
    mainHrGetIssuesListAction,
    mainHrGetRequestByIdAction,
    mainHrGetTimeOffRequestsWithFilterPaginationAction,
    mainHrRejectRequestAction,
    mainHrRejectTimeOffRequestAction,
    mainHrSetIssueStatusInProgressAction,
    mainHrSetIssueStatusResolvedAction,
    mainHrSkipTimeOffRequestAction,
    performCsvEmployeesAction,
    ReleaseEmployees,
    UpdateEmployeeHireDate,
    UpdateEmployeeStatus,
    updateEmployeesTreeAction,
    UpdateHrEmployee,
    UpdateHrEmployeeSetting,
    UpdateJobRecord
} from "./actions";
import {setPaginationInStore} from "../../../../newShared/hooks/useScroll/helpers";
import {TIdName, TPageInfo} from "../../../../newShared/types";
import {getName} from "../../../../newShared/utils/text";
import {
    addImportCsvActionsToBuilder,
    initialImportCsvDialogState,
    setImportCsvDialog
} from "../../../../newShared/components/csvImportDialog/constants";
import {handlePagination} from "../../../../newShared/components/genericTable/helpers";
import {minMaxLoadedPageType} from "../../../../newShared/components/genericTable/types";
import {DownloadFile, GetFullDocumentWithExecutor} from "../../documentsRefactor/store/actions";
import {
    HrIssuesStatus,
    HrTimeOffStatus,
    MainHrIssueModel,
    MainHrRequestModel,
    MainHrRequestStatus,
    MainHrTimeOffRequestModel
} from "../../../../newShared/GQLTypes";
import {minMaxLoadedPageDefault} from "../../../../newShared/components/genericTable/constants";
import {getErrorsByCode} from "../../../../newShared/utils/asyncThunk/helpers";

const defaultPageInfo: TPageInfo = {
    page: 0,
    count: 10, //used to show loader (shows by hasMore) on init, before resolving first fetch
    total: 0 //it will be replaced by resolved data
}

export const initialState: TEmployeesInitialState = {
    loadings: {
        getHrSettings: false,
        getEmployeesList:false,
        exportCsv: false,
        importCsv: false,
        deleteEmployee: false,
        createEmployee: false,
        updateEmployee: false,
        getEmployee: false,
        getEmployeeHistory: false,
        getEmployeeDocuments: false,
        createSetting: false,
        updateSetting: false,
        deleteSetting: false,
        updateHireDate: false,
        updateStatus: false,
        createJobRecord: false,
        updateJobRecord: false,
        deleteJobRecord: false,
        releaseEmployees: false,
        getManagers: false,
        createJobTitle: false,
        editJobTitle: false,
        deleteJobTitle: false,
        isLoadingFields: false,

        isLoadingTree: false,
        isUpdatingTree: false,
        // isChangingEmployeeTree: false,
        isDownloadingTree: false,
        isLoadingAutocomplete: [],
        isLoadingDocument: false,
        isLoadingDocumentId: null,
        isLoadingApproveRequest: false,
        isLoadingRejectRequest: false,
        isLoadingDeleteRequest: false,
        isLoadingRequest: false,
        issueExact: false,
        issuesList: false,
        //requests
        incomingRequests: false,
        issueChangeStatus: false,
        timeOffList: false,
        timeOffExact: false,
        timeOffApprove: false,
        timeOffReject: false,
        timeOffSkip: false,
        downloadFileLoading: [],
  },
  dialogs: {
    incomingRequestView: {
      request: null,
      employee: null,
      requestId: null,
    },
    tableViewSettings: false,
    deleteEmployee: {
      isOpen: false,
      employee: null,
    },
    // isOpenExportCsv: false,
    isOpenImportCsv: false,
    exportCsv: {
      isOpen: false,
      fields: [],
    },
    addSettingDialog: {
      isOpen: false,
      type: null,
      id: -1,
    },
    changeHireDateDialog: false,
    changeEmploymentStatusDialog: false,
    changeHistoryRowDialog: {
      isOpen: false,
      row: null,
    },
    deleteHistoryRowDialog: {
      isOpen: false,
      row: null,
    },
    addHistoryRowDialog: false,
    discardUpdateGeneralInfoChanges: {
      isOpen: false,
      nextTab: "general",
    },
    releaseEmployees: false,
    createHRSetting: {
      isOpen: false,
      field: null,
    },
    editHRSetting: {
      isOpen: false,
      field: null,
      value: null,
    },
    deleteHRSetting: {
      isOpen: false,
      field: null,
      value: null,
    },
    previewJobTitle: {
      isOpen: false,
      jobTitle: null,
    },
    editJobTitle: {
      isOpen: false,
      jobTitle: null,
    },
    deleteJobTitle: {
      isOpen: false,
      jobTitle: null,
    },
    editEmployeesTreeNode: {
      isOpen: false,
      id: null,
    },
      resolveIssue: {
          issueId: null,
      },
      timeOffHistory: {
          isOpen: false,
          selected: null
      },
      requestError: {
          title: '',
          message: [],
      },
      skipRequest: {
        isOpen: false,
      },

    ...initialImportCsvDialogState,
  },
  employeesPageInfo: defaultPageInfo,
  employeeHistoryPageInfo: defaultPageInfo,
  hrSettings: {
    workspaceId: "",
    terminationType: [],
    terminationReason: [],
    eligibleForRehire: [],
    gender: [],
    maritalStatus: [],
    ethnicity: [],
    country: [],
    contactType: [],
    jobType: [],
    location: {},
    department: {},
    division: {},
    relationshipType: {},
    degree: {},
    employmentType: {},
    customFields: {},
    status: [],
    jobTitle: [],
    timeOffType: [],
  },
  employees: [],
  employeesMinLoadedPage: -1,
  employeesMaxLoadedPage: -1,
  selectedEmployee: null,
  employeeDocuments: {
    documents: [],
    pageInfo: defaultPageInfo,
    minLoadedPage: -1,
    maxLoadedPage: -1,
    selectedNewDocument: null,
  },
  managers: [],
  // employeesFilter: [],
  // columnSettings: [],

  tree: {
    employees: [],
    deleted: [],
    isEditMode: false,
    fullView: false,
    direction: "TB",
  },

  incomingRequests: {
    requests: [],
    pageInfo: defaultPageInfo,
    minMaxLoadedPage: minMaxLoadedPageDefault,
  },

  issues: {
    issues: [],
    pageInfo: defaultPageInfo,
    minMaxLoadedPage: minMaxLoadedPageDefault,

    selected: null,
  },

  timeOffRequests: {
    requests: [],
    pageInfo: defaultPageInfo,
    minMaxLoadedPage: minMaxLoadedPageDefault,
    selected: null,
  },
};

export const EmployeesSlice = createSlice({
    name: 'Employees.refactored',
    initialState,
    reducers: {
        openSkipRequestDialog: (slice) => {
            slice.dialogs.skipRequest.isOpen = true;
        },
        hideSkipRequestDialog: (slice) => {
            slice.dialogs.skipRequest.isOpen = false;
        },
        openIncomingRequestViewDialog: (slice, {payload}: {payload: string | null}) => {
            slice.dialogs.incomingRequestView.requestId = payload;
            if (!payload){
                slice.dialogs.incomingRequestView.request = null;
                slice.dialogs.incomingRequestView.employee = null;
            }
        },
        hideIncomingRequestViewDialog: (slice) => {
            slice.dialogs.incomingRequestView = initialState.dialogs.incomingRequestView;
        },
        eraseEmployees: (slice) => {
            slice.employees = [];
            slice.employeesPageInfo = defaultPageInfo;
        },
        eraseHrSettings: (slice: TEmployeesInitialState): void => {
            slice.hrSettings = initialState.hrSettings;
        },
        eraseEmployeeDocuments: (slice) => {
            slice.employeeDocuments.documents = [];
            slice.employeeDocuments.pageInfo = initialState.employeeDocuments.pageInfo;
        },
        setSelectedEmployee: (slice: TEmployeesInitialState, {payload}: {payload: EmployeeType}) => {
            slice.selectedEmployee = payload;
        },
        deselectEmployee: (slice: TEmployeesInitialState) => {
            slice.selectedEmployee = null;
        },

        openDeleteEmployeeDialog: (slice: TEmployeesInitialState, {payload}: {payload: TIdName}) => {
            slice.dialogs.deleteEmployee = {
                isOpen: true,
                employee: payload
            }
        },
        hideDeleteEmployeeDialog: (slice: TEmployeesInitialState) => {
            slice.dialogs.deleteEmployee = {
                isOpen: false,
                employee: null
            }
        },

        openAddSettingDialog: (slice: TEmployeesInitialState, {payload}: {payload: {type: keyof THrSettings, id?: number}}) => {
            slice.dialogs.addSettingDialog = {isOpen: true, type: payload.type, id: payload.id ?? -1};
        },
        hideAddSettingDialog: (slice: TEmployeesInitialState) => {
            slice.dialogs.addSettingDialog = {isOpen: false, type: null, id: -1};
        },

        setImportCsvDialogAction: setImportCsvDialog,

        openReleaseDialog: (slice: TEmployeesInitialState) => {slice.dialogs.releaseEmployees = true},
        hideReleaseDialog: (slice: TEmployeesInitialState) => {slice.dialogs.releaseEmployees = false},

        openChangeHireDateDialog: (slice: TEmployeesInitialState) => {slice.dialogs.changeHireDateDialog = true},
        hideChangeHireDateDialog: (slice: TEmployeesInitialState) => {slice.dialogs.changeHireDateDialog = false},

        openChangeEmploymentStatusDialog: (slice: TEmployeesInitialState) => {slice.dialogs.changeEmploymentStatusDialog = true},
        hideChangeEmploymentStatusDialog: (slice: TEmployeesInitialState) => {slice.dialogs.changeEmploymentStatusDialog = false},

        openChangeHistoryRowDialog: (slice: TEmployeesInitialState, {payload} : {payload: TEmployeeJobHistory}) => {slice.dialogs.changeHistoryRowDialog = {isOpen: true, row: payload}},
        hideChangeHistoryRowDialog: (slice: TEmployeesInitialState) => {slice.dialogs.changeHistoryRowDialog = {isOpen: false, row: null}},

        openDeleteHistoryRowDialog: (slice: TEmployeesInitialState, {payload} : {payload: TEmployeeJobHistory}) => {slice.dialogs.deleteHistoryRowDialog = {isOpen: true, row: payload}},
        hideDeleteHistoryRowDialog: (slice: TEmployeesInitialState) => {slice.dialogs.changeHistoryRowDialog = {isOpen: false, row: null}},

        openAddHistoryRowDialog: (slice: TEmployeesInitialState) => {slice.dialogs.addHistoryRowDialog = true},
        hideAddHistoryRowDialog: (slice: TEmployeesInitialState) => {slice.dialogs.addHistoryRowDialog = false},

        openDiscardChangesDialog: (slice: TEmployeesInitialState, {payload}: {payload: ExactEmployeeTabs}) => {slice.dialogs.discardUpdateGeneralInfoChanges = {isOpen: true, nextTab: payload}},
        hideDiscardChangesDialog: (slice: TEmployeesInitialState) => {slice.dialogs.discardUpdateGeneralInfoChanges = {isOpen: false, nextTab: 'general'}},

        openEditHrSettingDialog: (slice: TEmployeesInitialState, {payload}: {payload: {value: string, field: SettingsTypes}}) => {
            if (payload.value !== undefined && payload.field !== undefined) {
                slice.dialogs.editHRSetting.isOpen = true;
                slice.dialogs.editHRSetting.value = payload.value;
                slice.dialogs.editHRSetting.field = payload.field;
            }
        },
        hideEditHrSettingDialog: (slice: TEmployeesInitialState) => {
            slice.dialogs.editHRSetting = initialState.dialogs.editHRSetting;
        },

        openDeleteHrSettingDialog: (slice: TEmployeesInitialState, {payload}: {payload: {value: string, field: SettingsTypes}}) => {
            if (payload.value !== undefined && payload.field !== undefined) {
                slice.dialogs.deleteHRSetting.isOpen = true;
                slice.dialogs.deleteHRSetting.value = payload.value;
                slice.dialogs.deleteHRSetting.field = payload.field;
            }
        },
        hideDeleteHrSettingDialog: (slice: TEmployeesInitialState) => {
            slice.dialogs.deleteHRSetting = initialState.dialogs.deleteHRSetting;
        },
        eraseSelectedEmployeeJobHistory: (slice: TEmployeesInitialState) => {
            if(slice.selectedEmployee){
                slice.selectedEmployee.jobInfos = [];
            }
        },

        setTreeAction: (slice: TEmployeesInitialState, {payload}: {payload: Partial<TEmployeesInitialState["tree"] & {clear?: boolean}>}) => {
            if (payload.deleted !== undefined) slice.tree.deleted = payload.deleted;
            if (payload.isEditMode !== undefined) slice.tree.isEditMode = payload.isEditMode;
            if (payload.fullView !== undefined) slice.tree.fullView = payload.fullView;
            if (payload.direction !== undefined) slice.tree.direction = payload.direction;

            if (payload.clear !== undefined) slice.tree = initialState.tree;
        },
        openEditEmployeesTreeNode: (slice: TEmployeesInitialState, {payload}: {payload: {id: string | null}}) => {
            if (payload.id) {
                slice.dialogs.editEmployeesTreeNode.isOpen = true;
                slice.dialogs.editEmployeesTreeNode.id = payload.id;
            } else {
                slice.dialogs.editEmployeesTreeNode = initialState.dialogs.editEmployeesTreeNode;
                slice.selectedEmployee = initialState.selectedEmployee;
            }
        },
        openPreviewJobTitleDialog: (slice: TEmployeesInitialState, {payload}: {payload: THrSettingsJobTitle}) => {slice.dialogs.previewJobTitle = {isOpen: true, jobTitle: payload}},
        hidePreviewJobTitleDialog: (slice: TEmployeesInitialState) => {slice.dialogs.previewJobTitle = {isOpen: false, jobTitle: null}},

        openEditJobTitleDialog: (slice: TEmployeesInitialState, {payload}: {payload: THrSettingsJobTitle}) => {slice.dialogs.editJobTitle = {isOpen: true, jobTitle: payload}},
        hideEditJobTitleDialog: (slice: TEmployeesInitialState) => {slice.dialogs.editJobTitle = {isOpen: false, jobTitle: null}},

        openDeleteJobTitleDialog: (slice: TEmployeesInitialState, {payload}: {payload: THrSettingsJobTitle}) => {slice.dialogs.deleteJobTitle = {isOpen: true, jobTitle: payload}},
        hideDeleteJobTitleDialog: (slice: TEmployeesInitialState) => {slice.dialogs.deleteJobTitle = {isOpen: false, jobTitle: null}},
        replaceEmployeesMinMaxPage: (slice: TEmployeesInitialState, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.employeesMinLoadedPage = payload.minLoadedPage;
            slice.employeesMaxLoadedPage = payload.maxLoadedPage;
        },
        replaceEmployeeDocumentsMinMaxPage: (slice: TEmployeesInitialState, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.employeeDocuments.minLoadedPage = payload.minLoadedPage;
            slice.employeeDocuments.maxLoadedPage = payload.maxLoadedPage;
        },
        openExportCsv: (slice) => {
            slice.dialogs.exportCsv.isOpen = true;
        },
        hideExportCsv: (slice) => {
            slice.dialogs.exportCsv = initialState.dialogs.exportCsv;
        },
        deselectDocument: (slice) => {
            slice.employeeDocuments.selectedNewDocument = null;
        },
        setIsLoadingDocumentId: (slice: TEmployeesInitialState, {payload} : {payload: string}) => {
            if(payload !== undefined) slice.loadings.isLoadingDocumentId = payload;
        },

        //requests
        replaceRequestMinMaxLoadedPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.incomingRequests.minMaxLoadedPage = payload;
        },
        eraseRequestList: (slice) => {
            slice.incomingRequests.requests = [];
            slice.incomingRequests.pageInfo = defaultPageInfo;
        },

        //issues
        replaceIssuesMinMaxLoadedPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.issues.minMaxLoadedPage = payload;
        },
        eraseIssuesList: (slice) => {
            slice.issues.issues = initialState.issues.issues;
            slice.issues.pageInfo = initialState.issues.pageInfo;
        },
        setResolveIssueDialogAction: (slice, {payload}: {payload: Partial<TEmployeesInitialState["dialogs"]["resolveIssue"]> & {clear?: boolean}}) => {
            if (payload.issueId !== undefined) slice.dialogs.resolveIssue.issueId = payload.issueId;

            if (payload.clear !== undefined) slice.dialogs.resolveIssue = initialState.dialogs.resolveIssue;
        },
        replaceTimeOffMinMaxLoadedPage: (slice, { payload }: { payload: minMaxLoadedPageType }) => {
            slice.timeOffRequests.minMaxLoadedPage = payload;
        },
        eraseTimeOffList: (slice) => {
            slice.timeOffRequests.requests = [];
            slice.timeOffRequests.pageInfo = defaultPageInfo;
        },
        setTimeOffHistoryDialogAction: (slice: TEmployeesInitialState, {payload}: {payload: Partial<TEmployeesInitialState["dialogs"]["timeOffHistory"] & {clear?: boolean}>}) => {
            if (payload.isOpen !== undefined) slice.dialogs.timeOffHistory.isOpen = payload.isOpen;
            if (payload.selected !== undefined) slice.dialogs.timeOffHistory.selected = payload.selected;
            if (payload.clear !== undefined) slice.dialogs.timeOffHistory = initialState.dialogs.timeOffHistory;
        },
        setRequestErrorAction: (slice, {payload}: {payload: Partial<TEmployeesInitialState["dialogs"]["requestError"]> & {clear?: boolean}}) => {
            if (payload.message) slice.dialogs.requestError.message = payload.message;
            if (payload.title !== undefined) slice.dialogs.requestError.title = payload.title;
            if (payload.clear) slice.dialogs.requestError = initialState.dialogs.requestError;
        },


        clearSelectedTimeOffRequestAction: (slice) => {
            slice.timeOffRequests.selected = null;
        },


        cleanUp: () => initialState
    },
     extraReducers: (builder) => {
         addImportCsvActionsToBuilder<TEmployeesInitialState>(builder, getCsvFieldsEmployeesAction, performCsvEmployeesAction);

         builder
             //GetEmployees
             .addCase(GetEmployees.pending, (slice) => {
                 slice.loadings.getEmployeesList = true;
             })
             .addCase(GetEmployees.rejected, (slice) => {
                 slice.loadings.getEmployeesList = false;
             })
             .addCase(GetEmployees.fulfilled, (slice, {payload}) => {
                 slice.loadings.getEmployeesList = false;

                 const {results, maxLoadedPage, minLoadedPage} = handlePagination<EmployeeType>(
                     slice.employees,
                     slice.employeesPageInfo,
                     payload.res.employees,
                     payload.res.pageInfo,
                     slice.employeesMinLoadedPage,
                     slice.employeesMaxLoadedPage,
                     'id'
                 );

                 slice.employees = results;
                 slice.employeesMinLoadedPage = minLoadedPage;
                 slice.employeesMaxLoadedPage = maxLoadedPage;
                 slice.employeesPageInfo = payload.res.pageInfo;
             })
         //GetHrEmployeeById
             .addCase(GetHrEmployeeById.pending, (slice) => {
                 slice.loadings.getEmployee = true;
             })
             .addCase(GetHrEmployeeById.rejected, (slice) => {
                 slice.loadings.getEmployee = false;
             })
             .addCase(GetHrEmployeeById.fulfilled, (slice, {payload}) => {
                 slice.loadings.getEmployee = false;
                 slice.selectedEmployee = payload;
             })
         //GetHrEmployeesSettings
             .addCase(GetHrEmployeesSettings.pending, (slice) => {
                 slice.loadings.getHrSettings = true;
             })
             .addCase(GetHrEmployeesSettings.rejected, (slice) => {
                 slice.loadings.getHrSettings = false;
             })
             .addCase(GetHrEmployeesSettings.fulfilled, (slice, {payload}) => {
                 slice.loadings.getHrSettings = false;
                 slice.hrSettings = payload;
             })
         //CreateHrEmployeeSetting
             .addCase(CreateHrEmployeeSetting.pending, (slice) => {
                 slice.loadings.createSetting = true;
             })
             .addCase(CreateHrEmployeeSetting.rejected, (slice) => {
                 slice.loadings.createSetting = false;
             })
             .addCase(CreateHrEmployeeSetting.fulfilled, (slice, {payload}) => {
                 slice.loadings.createSetting = false;

                 if(payload.field === 'customFields'){
                     slice.hrSettings.customFields = {
                         ...slice.hrSettings.customFields,
                         [payload.value]: 0
                     };
                     return;
                 }
                 if(payload.field === 'terminationReason' || payload.field === 'contactType'){
                     slice.hrSettings[payload.field] = [...slice.hrSettings[payload.field] as string[], payload.value];
                     return;
                 }

                 if(payload.field === 'location' || payload.field === 'department' || payload.field === 'division' || payload.field === 'relationshipType' || payload.field === 'degree' || payload.field === 'employmentType'){
                     slice.hrSettings[payload.field][payload.value] = 0;
                 }
             })

             .addCase(UpdateHrEmployeeSetting.pending, (slice) => {
                 slice.loadings.updateSetting = true;
             })
             .addCase(UpdateHrEmployeeSetting.rejected, (slice) => {
                 slice.loadings.updateSetting = false;
             })
             .addCase(UpdateHrEmployeeSetting.fulfilled, (slice, {payload}) => {
                 slice.loadings.updateSetting = false;
                 slice.dialogs.editHRSetting = initialState.dialogs.editHRSetting;

                 if(payload.field === 'customFields'){
                     const tmp = slice.hrSettings.customFields;
                     delete tmp[payload.value];
                     slice.hrSettings.customFields = {
                         ...tmp,
                         [payload.newValue]: 0,
                     };
                     return;
                 } else {
                     const tmp = slice.hrSettings[payload.field as SettingsTypes];
                     tmp[payload.newValue] = tmp[payload.value];
                     delete tmp[payload.value];

                     slice.hrSettings[payload.field as SettingsTypes] = tmp;

                 }

             })

             .addCase(DeleteHrEmployeeSetting.pending, (slice) => {
                 slice.loadings.deleteSetting = true;
             })
             .addCase(DeleteHrEmployeeSetting.rejected, (slice) => {
                 slice.loadings.deleteSetting = false;
             })
             .addCase(DeleteHrEmployeeSetting.fulfilled, (slice, {payload}) => {
                 slice.loadings.deleteSetting = false;
                 slice.dialogs.deleteHRSetting = initialState.dialogs.deleteHRSetting;

                 const tmp = slice.hrSettings[payload.field as SettingsTypes];
                 tmp[payload.newValue] = slice.hrSettings[payload.field as SettingsTypes][payload.newValue] + tmp[payload.value];
                 delete tmp[payload.value];

                 slice.hrSettings[payload.field as SettingsTypes] = tmp;
             })
         //CreateHrEmployee
             .addCase(CreateHrEmployee.pending, (slice) => {
                 slice.loadings.createEmployee = true;
             })
             .addCase(CreateHrEmployee.rejected, (slice) => {
                 slice.loadings.createEmployee = false;
             })
             .addCase(CreateHrEmployee.fulfilled, (slice, {payload}) => {
                 slice.loadings.createEmployee = false;
                 slice.selectedEmployee = payload;
                 // slice.employees = [...slice.employees, payload];
                 // slice.employeesPageInfo.total = slice.employeesPageInfo.total + 1;
             })
         //GetHrEmployeeJobInfosById
             .addCase(GetHrEmployeeJobInfosById.pending, (slice) => {
                 slice.loadings.getEmployeeHistory = true;
             })
             .addCase(GetHrEmployeeJobInfosById.rejected, (slice) => {
                 slice.loadings.getEmployeeHistory = false;
             })
             .addCase(GetHrEmployeeJobInfosById.fulfilled, (slice, {payload}) => {
                 slice.loadings.getEmployeeHistory = false;
                 if(slice.selectedEmployee){
                     const {array, pageInfo} = setPaginationInStore<TEmployeeJobHistory>(
                         slice.employeesPageInfo,
                         slice.selectedEmployee.jobInfos,
                         payload.pageInfo,
                         payload.jobInfos,
                         false
                     );

                     slice.selectedEmployee.jobInfos = array.filter(e => e !== null) as TEmployeeJobHistory[];
                     slice.employeeHistoryPageInfo = pageInfo;
                 }
             })
         //UpdateEmployeeHireDate
             .addCase(UpdateEmployeeHireDate.pending, (slice) => {
                 slice.loadings.updateHireDate = true;
             })
             .addCase(UpdateEmployeeHireDate.rejected, (slice) => {
                 slice.loadings.updateHireDate = false;
             })
             .addCase(UpdateEmployeeHireDate.fulfilled, (slice, {payload}) => {
                 slice.loadings.updateHireDate = false;
                 slice.dialogs.changeHireDateDialog = false;
                 if(slice.selectedEmployee){
                     slice.selectedEmployee.hireDate = payload.hireDate;
                     //TODO ask Artem about history
                     //todo maybe update list value too - if list page is not clears on on init
                 }
             })
         //UpdateEmployeeStatus
             .addCase(UpdateEmployeeStatus.pending, (slice) => {
                 slice.loadings.updateStatus = true;
             })
             .addCase(UpdateEmployeeStatus.rejected, (slice) => {
                 slice.loadings.updateStatus = false;
             })
             .addCase(UpdateEmployeeStatus.fulfilled, (slice, {payload}) => {
                 slice.loadings.updateStatus = false;
                 slice.dialogs.changeEmploymentStatusDialog = false;
                 if(slice.selectedEmployee){
                     slice.selectedEmployee.status = payload.status;
                     //todo maybe update list value too - if list page is not clears on on init
                 }
             })
         //AddJobRecord
             .addCase(AddJobRecord.pending, (slice) => {
                 slice.loadings.createJobRecord = true;
             })
             .addCase(AddJobRecord.rejected, (slice) => {
                 slice.loadings.createJobRecord = false;
             })
             .addCase(AddJobRecord.fulfilled, (slice, {payload}) => {
                 slice.loadings.createJobRecord = false;
                 slice.dialogs.addHistoryRowDialog = false;
                 //AddJobRecord will fetch getJobInfos to update all of them
             })
         //UpdateJobRecord
             .addCase(UpdateJobRecord.pending, (slice) => {
                 slice.loadings.updateJobRecord = true;
             })
             .addCase(UpdateJobRecord.rejected, (slice) => {
                 slice.loadings.updateJobRecord = false;
             })
             .addCase(UpdateJobRecord.fulfilled, (slice, {payload}) => {
                 slice.loadings.updateJobRecord = false;
                 slice.dialogs.changeHistoryRowDialog = {
                     isOpen: false,
                     row: null
                 };
                 //UpdateJobRecord will fetch getJobInfos to update all of them
             })
         //DeleteJobRecord
             .addCase(DeleteJobRecord.pending, (slice) => {
                 slice.loadings.deleteJobRecord = true;
             })
             .addCase(DeleteJobRecord.rejected, (slice) => {
                 slice.loadings.deleteJobRecord = false;
             })
             .addCase(DeleteJobRecord.fulfilled, (slice, {payload}) => {
                 slice.loadings.deleteJobRecord = false;
                 slice.dialogs.deleteHistoryRowDialog = {
                     isOpen: false,
                     row: null
                 };
                 //deleteJobRecord will fetch getJobInfos to update all of them
             })
         //DeleteHrEmployeeById
             .addCase(DeleteHrEmployeeById.pending, (slice, {meta}) => {
                 slice.loadings.deleteEmployee = true;
             })
             .addCase(DeleteHrEmployeeById.rejected, (slice) => {
                 slice.loadings.deleteEmployee = false;
             })
             .addCase(DeleteHrEmployeeById.fulfilled, (slice, {payload}) => {
                 slice.loadings.deleteEmployee = false;
                 slice.dialogs.deleteEmployee = {
                     isOpen: false,
                     employee: null
                 };
                 slice.selectedEmployee = null;
                 slice.employees = slice.employees.filter(e => e.id !== payload);
                 slice.employeesPageInfo.total = slice.employeesPageInfo.total - 1;
                 slice.tree.employees = slice.tree.employees.filter(e => e.id !== payload);
             })
         //ReleaseEmployees
             .addCase(ReleaseEmployees.pending, (slice) => {
                 slice.loadings.releaseEmployees = true;
             })
             .addCase(ReleaseEmployees.rejected, (slice) => {
                 slice.loadings.releaseEmployees = false;
             })
             .addCase(ReleaseEmployees.fulfilled, (slice, {payload}) => {
                 slice.loadings.releaseEmployees = false;
                 slice.dialogs.releaseEmployees = false;
             })
         //UpdateHrEmployee
             .addCase(UpdateHrEmployee.pending, (slice) => {
                 slice.loadings.updateEmployee = true;
             })
             .addCase(UpdateHrEmployee.rejected, (slice) => {
                 slice.loadings.updateEmployee = false;
             })
             .addCase(UpdateHrEmployee.fulfilled, (slice, {payload}) => {
                 slice.loadings.updateEmployee = false;
                 if (slice.dialogs.editEmployeesTreeNode.isOpen) {
                     slice.employees = slice.employees.map(e => e.id === payload.id ? payload : e);

                     slice.tree.employees = slice.tree.employees.map(e => e.id === payload.id ? {
                         id: payload.id,
                         name: getName(payload.firstName, payload.lastName),
                         jobTitle: payload.jobInfos?.find(e => e.current)?.jobTitle.name || '',
                         manager: e.manager,
                         workEmail: payload.workEmail,
                         workMobilePhone: e.workMobilePhone,
                     } : e);

                     slice.dialogs.editEmployeesTreeNode = initialState.dialogs.editEmployeesTreeNode;
                     slice.selectedEmployee = initialState.selectedEmployee;
                 } else {
                     if (slice.selectedEmployee){
                         slice.selectedEmployee = payload;
                         slice.employees = slice.employees.map(e => e.id === payload.id ? payload : e);
                     }
                 }
             })
             //AutocompleteManagers
             .addCase(AutocompleteManagers.pending, (slice) => {
                 slice.loadings.getManagers = true;
             })
             .addCase(AutocompleteManagers.rejected, (slice) => {
                 slice.loadings.getManagers = false;
             })
             .addCase(AutocompleteManagers.fulfilled, (slice, {payload}) => {
                 slice.loadings.getManagers = false;
                 slice.managers = payload.resp;
             })

             .addCase(getEmployeesTreeAction.pending, (slice: TEmployeesInitialState, {meta: {arg: {data, clean}}}) => {
                 slice.loadings.isLoadingTree = true;
             })
             .addCase(getEmployeesTreeAction.rejected, (slice: TEmployeesInitialState, {meta: {arg: {data, clean}}}) => {
                 slice.loadings.isLoadingTree = false;
             })
             .addCase(getEmployeesTreeAction.fulfilled, (slice: TEmployeesInitialState, {payload: {data, resp}}) => {
                 slice.loadings.isLoadingTree = false;

                 slice.tree.employees = resp;
             })

             .addCase(updateEmployeesTreeAction.pending, (slice: TEmployeesInitialState, {meta: {arg: {data, clean}}}) => {
                 slice.loadings.isUpdatingTree = true;
             })
             .addCase(updateEmployeesTreeAction.rejected, (slice: TEmployeesInitialState, {meta: {arg: {data, clean}}}) => {
                 slice.loadings.isUpdatingTree = false;
             })
             .addCase(updateEmployeesTreeAction.fulfilled, (slice: TEmployeesInitialState, {payload: {data, resp}}) => {
                 slice.loadings.isUpdatingTree = false;

                 slice.tree.employees = resp;
             })
         //CreateHrSettingsJobTitle
             .addCase(CreateHrSettingsJobTitle.pending, (slice) => {
                 slice.loadings.createSetting = true;
             })
             .addCase(CreateHrSettingsJobTitle.rejected, (slice) => {
                 slice.loadings.createSetting = false;
             })
             .addCase(CreateHrSettingsJobTitle.fulfilled, (slice, {payload}) => {
                 slice.loadings.createSetting = false;
                 slice.hrSettings.jobTitle.push(payload.resp);
             })
         //EditHrSettingsJobTitle
             .addCase(EditHrSettingsJobTitle.pending, (slice) => {
                 slice.loadings.editJobTitle = true;
             })
             .addCase(EditHrSettingsJobTitle.rejected, (slice) => {
                 slice.loadings.editJobTitle = false;
             })
             .addCase(EditHrSettingsJobTitle.fulfilled, (slice, {payload}) => {
                 slice.loadings.editJobTitle = false;
                 slice.hrSettings.jobTitle = slice.hrSettings.jobTitle.map(e => e.id === payload.id ? payload : e);
                 slice.dialogs.editJobTitle = {
                     isOpen: false,
                     jobTitle: null
                 }
             })
         //DeleteSettingsJobTitle
             .addCase(DeleteSettingsJobTitle.pending, (slice) => {
                 slice.loadings.deleteJobTitle = true;
             })
             .addCase(DeleteSettingsJobTitle.rejected, (slice) => {
                 slice.loadings.deleteJobTitle = false;
             })
             .addCase(DeleteSettingsJobTitle.fulfilled, (slice, {payload}) => {
                 slice.loadings.deleteJobTitle = false;
                 slice.hrSettings.jobTitle = slice.hrSettings.jobTitle.filter(e => e.id !== payload.jobTitle.id);
                 //used selected job title for replace - add those people to new job title
                 slice.hrSettings.jobTitle = slice.hrSettings.jobTitle.map(e => e.id === payload.replaceJobTitleId ? {...e, count: e.count + payload.jobTitle.count} : e);
                 slice.dialogs.deleteJobTitle = {
                     isOpen: false,
                     jobTitle: null
                 }
             })
         //ExportCsvEmployees
             .addCase(ExportCsvEmployees.pending, (slice) => {
                 slice.loadings.exportCsv = true;
             })
             .addCase(ExportCsvEmployees.rejected, (slice) => {
                 slice.loadings.exportCsv = false;
             })
             .addCase(ExportCsvEmployees.fulfilled, (slice, {payload}) => {
                 slice.loadings.exportCsv = false;
                 slice.dialogs.exportCsv = initialState.dialogs.exportCsv;
             })


             .addCase(GetImportCsvHrManagementTemplate.pending, (slice: TEmployeesInitialState) => {
                 slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = true;
             })
             .addCase(GetImportCsvHrManagementTemplate.rejected, (slice: TEmployeesInitialState) => {
                 slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = false;
             })
             .addCase(GetImportCsvHrManagementTemplate.fulfilled, (slice: TEmployeesInitialState, {payload}) => {
                 slice.dialogs.importCsvDialog.isLoading.isLoadingTemplate = false;
             })

             //AvailableFieldsForExport
             .addCase(GetEmployeesAvailableFieldsForExport.pending, (slice) => {
                 slice.loadings.isLoadingFields = true;
             })
             .addCase(GetEmployeesAvailableFieldsForExport.rejected, (slice) => {
                 slice.loadings.isLoadingFields = false;

             })
             .addCase(GetEmployeesAvailableFieldsForExport.fulfilled, (slice, {payload}) => {
                 slice.loadings.isLoadingFields = false;
                 slice.dialogs.exportCsv.fields = payload.fields;
             })

         //Employee Documents
             .addCase(GetShortDocumentsByWorkEmailWithFilterPaging.pending, (slice) => {
                 slice.loadings.getEmployeeDocuments = true;
             })
             .addCase(GetShortDocumentsByWorkEmailWithFilterPaging.rejected, (slice) => {
                 slice.loadings.getEmployeeDocuments = false;

             })
             .addCase(GetShortDocumentsByWorkEmailWithFilterPaging.fulfilled, (slice, {payload}) => {
                 slice.loadings.getEmployeeDocuments = false;
                 // slice.employeeDocuments.documents = payload.resp.files;
                 // slice.employeeDocuments.pageInfo = payload.resp.pageInfo;

                 const {results, maxLoadedPage, minLoadedPage} = handlePagination<TShortDocumentByEmail>(
                     slice.employeeDocuments.documents,
                     slice.employeeDocuments.pageInfo,
                     payload.resp.files,
                     payload.resp.pageInfo,
                     slice.employeeDocuments.minLoadedPage,
                     slice.employeeDocuments.maxLoadedPage,
                     'id'
                 );

                 slice.employeeDocuments.documents = results;
                 slice.employeeDocuments.minLoadedPage = minLoadedPage;
                 slice.employeeDocuments.maxLoadedPage = maxLoadedPage;
                 slice.employeeDocuments.pageInfo = payload.resp.pageInfo;
             })

             .addCase(GetFullDocumentWithExecutor.pending, (slice) => {
                 slice.loadings.isLoadingDocument = true;
             })
             .addCase(GetFullDocumentWithExecutor.rejected, (slice) => {
                 slice.loadings.isLoadingDocument = false;
             })
             .addCase(GetFullDocumentWithExecutor.fulfilled, (slice, {payload}) => {
                 slice.loadings.isLoadingDocument = false;
                 if(payload.resp.file !== undefined && payload.resp.file !== null){
                     slice.employeeDocuments.selectedNewDocument = {
                         document: payload.resp.file,
                         documentExecutor: payload.resp.executor ?? null
                     }
                 }
             })
         //GetHrRequestsWithFilterPagination
             .addCase(getHrRequestsWithFilterPaginationAction.pending, (slice) => {
                 slice.loadings.incomingRequests = true;
             })
             .addCase(getHrRequestsWithFilterPaginationAction.rejected, (slice) => {
                 slice.loadings.incomingRequests = false;
             })
             .addCase(getHrRequestsWithFilterPaginationAction.fulfilled, (slice, {payload}) => {
                 slice.loadings.incomingRequests = false;

                 const {results, maxLoadedPage, minLoadedPage} = handlePagination<MainHrRequestModel>(
                     slice.incomingRequests.requests,
                     slice.incomingRequests.pageInfo,
                     payload.result,
                     payload.pageInfo,
                     slice.incomingRequests.minMaxLoadedPage.minLoadedPage,
                     slice.incomingRequests.minMaxLoadedPage.maxLoadedPage,
                     'id'
                 );

                 slice.incomingRequests.requests = results;
                 slice.incomingRequests.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                 slice.incomingRequests.pageInfo = payload.pageInfo;

             })

             .addCase(mainHrGetIssuesListAction.pending, (slice) => {
                 slice.loadings.issuesList = true;
             })
             .addCase(mainHrGetIssuesListAction.rejected, (slice) => {
                 slice.loadings.issuesList = false;
             })
             .addCase(mainHrGetIssuesListAction.fulfilled, (slice, {payload}) => {
                 slice.loadings.issuesList = false;

                 const {results, maxLoadedPage, minLoadedPage} = handlePagination<MainHrIssueModel>(
                     slice.issues.issues,
                     slice.issues.pageInfo,
                     payload.result,
                     payload.pageInfo,
                     slice.issues.minMaxLoadedPage.minLoadedPage,
                     slice.issues.minMaxLoadedPage.maxLoadedPage,
                     'id'
                 );

                 slice.issues.issues = results;
                 slice.issues.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                 slice.issues.pageInfo = payload.pageInfo;
             })

             .addCase(mainHrGetIssueByIdAction.pending, (slice) => {
                 slice.loadings.issueExact = true;
                 slice.issues.selected = null;
             })
             .addCase(mainHrGetIssueByIdAction.rejected, (slice) => {
                 slice.loadings.issueExact = false;
             })
             .addCase(mainHrGetIssueByIdAction.fulfilled, (slice, {payload}) => {
                 slice.loadings.issueExact = false;
                 slice.issues.selected = payload;
             })

             .addCase(mainHrSetIssueStatusInProgressAction.pending, (slice) => {
                 slice.loadings.issueChangeStatus = true;
             })
             .addCase(mainHrSetIssueStatusInProgressAction.rejected, (slice) => {
                 slice.loadings.issueChangeStatus = false;
             })
             .addCase(mainHrSetIssueStatusInProgressAction.fulfilled, (slice, {payload}) => {
                 slice.loadings.issueChangeStatus = false;
                 if (slice.issues.selected) {
                     slice.issues.selected.status = HrIssuesStatus.InProgress;
                 }
             })

             .addCase(mainHrSetIssueStatusResolvedAction.pending, (slice) => {
                 slice.loadings.issueChangeStatus = true;
             })
             .addCase(mainHrSetIssueStatusResolvedAction.rejected, (slice) => {
                 slice.loadings.issueChangeStatus = false;
             })
             .addCase(mainHrSetIssueStatusResolvedAction.fulfilled, (slice, {meta, payload}) => {
                 slice.loadings.issueChangeStatus = false;
                 slice.dialogs.resolveIssue = initialState.dialogs.resolveIssue;

                 if (slice.issues.selected) {
                     slice.issues.selected.status = HrIssuesStatus.Resolved;
                     slice.issues.selected.resolveDate = new Date().toISOString();
                     slice.issues.selected.resolution = meta.arg.data.comment;
                     slice.issues.selected.resolver = {
                         employeeId: null,
                         employeeFullName: null,
                         publicId: meta.arg.addictiveData?.publicId ?? '',
                         collaboratorFullName: meta.arg.addictiveData?.name ?? ''
                     }

                 }
             })

             .addCase(mainHrGetRequestByIdAction.pending, (slice) => {
                 slice.loadings.isLoadingRequest = true;
             })
             .addCase(mainHrGetRequestByIdAction.rejected, (slice) => {
                 slice.loadings.isLoadingRequest = false;
             })
             .addCase(mainHrGetRequestByIdAction.fulfilled, (slice, {payload} )=> {
                 slice.loadings.isLoadingRequest = false;
                 slice.dialogs.incomingRequestView.request = payload.request;
                 slice.dialogs.incomingRequestView.employee = payload.employee;
             })

             .addCase(mainHrApproveRequestAction.pending, (slice) => {
                 slice.loadings.isLoadingApproveRequest = true;
             })
             .addCase(mainHrApproveRequestAction.rejected, (slice) => {
                 slice.loadings.isLoadingApproveRequest = false;
             })
             .addCase(mainHrApproveRequestAction.fulfilled, (slice, {payload, meta}) => {
                 slice.loadings.isLoadingApproveRequest = false;
                 slice.incomingRequests.requests = slice.incomingRequests.requests.map(e => e.id === meta.arg.data.id ? {...e, status: MainHrRequestStatus.Approved} : e);
                 slice.dialogs.incomingRequestView.requestId = null;
             })

             .addCase(mainHrRejectRequestAction.pending, (slice) => {
                 slice.loadings.isLoadingRejectRequest = true;
             })
             .addCase(mainHrRejectRequestAction.rejected, (slice) => {
                 slice.loadings.isLoadingRejectRequest = false;
             })
             .addCase(mainHrRejectRequestAction.fulfilled, (slice, {payload, meta}) => {
                 slice.loadings.isLoadingRejectRequest = false;
                 slice.incomingRequests.requests = slice.incomingRequests.requests.map(e => e.id === meta.arg.data.id ? {...e, status: MainHrRequestStatus.Rejected} : e);
                 slice.dialogs.incomingRequestView.requestId = null;
             })

             .addCase(mainHrDeleteRequestAction.pending, (slice) => {
                 slice.loadings.isLoadingDeleteRequest = true;
             })
             .addCase(mainHrDeleteRequestAction.rejected, (slice) => {
                 slice.loadings.isLoadingDeleteRequest = false;
             })
             .addCase(mainHrDeleteRequestAction.fulfilled, (slice, {payload, meta}) => {
                 slice.loadings.isLoadingDeleteRequest = false;
                 slice.incomingRequests.requests = slice.incomingRequests.requests.filter(e => e.id !== meta.arg.data.id);
                 slice.dialogs.incomingRequestView.requestId = null;
             })

      //time offs
      .addCase(mainHrGetTimeOffRequestsWithFilterPaginationAction.pending, (slice, {meta: {arg: {clean}}}) => {
          slice.loadings.timeOffList = true;

            if (clean) {
                slice.timeOffRequests.requests = initialState.timeOffRequests.requests;
                slice.timeOffRequests.pageInfo = initialState.timeOffRequests.pageInfo;
                slice.timeOffRequests.minMaxLoadedPage = initialState.timeOffRequests.minMaxLoadedPage;
            }
      })
      .addCase(mainHrGetTimeOffRequestsWithFilterPaginationAction.rejected,
        (slice) => {
          slice.loadings.timeOffList = false;
      })
      .addCase(mainHrGetTimeOffRequestsWithFilterPaginationAction.fulfilled,(slice, { payload }) => {
          slice.loadings.timeOffList = false;

          const { results, maxLoadedPage, minLoadedPage } =
            handlePagination<MainHrTimeOffRequestModel>(
              slice.timeOffRequests.requests,
              slice.timeOffRequests.pageInfo,
              payload.result,
              payload.pageInfo,
              slice.timeOffRequests.minMaxLoadedPage.minLoadedPage,
              slice.timeOffRequests.minMaxLoadedPage.maxLoadedPage,
              "id"
            );

          slice.timeOffRequests.requests = results;
          slice.timeOffRequests.minMaxLoadedPage = {
            minLoadedPage,
            maxLoadedPage,
          };
          slice.timeOffRequests.pageInfo = payload.pageInfo;
      })

      .addCase(mainHrGetAndChangeStepStatusTimeOffRequestAction.pending, (slice) => {
          slice.loadings.timeOffExact = true;
      })
      .addCase(mainHrGetAndChangeStepStatusTimeOffRequestAction.rejected, (slice) => {
          slice.loadings.timeOffExact = false;
      })
      .addCase(mainHrGetAndChangeStepStatusTimeOffRequestAction.fulfilled, (slice, { payload }) => {
          slice.loadings.timeOffExact = false;

          if (payload.status === HrTimeOffStatus.Cancelled) {
              if (!slice.timeOffRequests.selected) {
                  slice.dialogs.requestError.message = ['Request was cancelled'];
                  slice.dialogs.requestError.title = `Time off request ${payload.id}`;
              }
          } else {
              slice.timeOffRequests.selected = payload;
          }

          slice.timeOffRequests.requests = slice.timeOffRequests.requests.map(e => e.id === payload.id ? payload : e);
      })

      .addCase(mainHrApproveTimeOffRequestAction.pending, (slice) => {
        slice.loadings.timeOffApprove = true;
      })
      .addCase(mainHrApproveTimeOffRequestAction.rejected, (slice, {payload}) => {
        slice.loadings.timeOffApprove = false;

          const error409 = getErrorsByCode<string>(409, payload?.all || []);
          if (error409.length) {
              slice.dialogs.requestError = {
                  title: 'Error',
                  message: error409,
              };
          }

      })
      .addCase( mainHrApproveTimeOffRequestAction.fulfilled, (slice, { payload, meta }) => {
          slice.loadings.timeOffApprove = false;
          slice.timeOffRequests.requests = slice.timeOffRequests.requests.map(e => e.id === meta.arg.data.requestId ? ({...e, status: HrTimeOffStatus.Approved}) : e);
      })
      .addCase(mainHrRejectTimeOffRequestAction.pending, (slice) => {
        slice.loadings.timeOffReject = true;
      })
      .addCase(mainHrRejectTimeOffRequestAction.rejected, (slice, {payload}) => {
        slice.loadings.timeOffReject = false;
          const error409 = getErrorsByCode<string>(409, payload?.all || []);
          if (error409.length) {
              slice.dialogs.requestError = {
                  title: 'Error',
                  message: error409,
              };
          }
      })
      .addCase(mainHrRejectTimeOffRequestAction.fulfilled, (slice, { payload, meta }) => {
          slice.loadings.timeOffReject = false;
          slice.timeOffRequests.requests = slice.timeOffRequests.requests.map(e => e.id === meta.arg.data.requestId ? ({...e, status: HrTimeOffStatus.Rejected}) : e);
      })

      .addCase(DownloadFile.pending, (slice, {meta}) => {
          slice.loadings.isLoadingDocumentId = meta.arg.fileId;
          slice.loadings.downloadFileLoading.push(meta.arg.fileId);
      })
      .addCase(DownloadFile.rejected, (slice, {payload, meta}) => {
          slice.loadings.isLoadingDocumentId = null;
          slice.loadings.downloadFileLoading = slice.loadings.downloadFileLoading.filter(e => e !== meta.arg.fileId);
      })
      .addCase(DownloadFile.fulfilled, (slice, { payload, meta }) => {
          slice.loadings.isLoadingDocumentId = null;
          slice.loadings.downloadFileLoading = slice.loadings.downloadFileLoading.filter(e => e !== meta.arg.fileId);
      })
      
      .addCase(mainHrSkipTimeOffRequestAction.pending, (slice) => {
        slice.loadings.timeOffSkip = true;
      })
      .addCase(mainHrSkipTimeOffRequestAction.rejected, (slice, {payload}) => {
          slice.loadings.timeOffSkip = false;
          const error409 = getErrorsByCode<string>(409, payload?.all || []);
          if (error409.length) {
              slice.dialogs.requestError = {
                  title: 'Error',
                  message: error409,
              };
          }
      })
      .addCase(mainHrSkipTimeOffRequestAction.fulfilled, (slice, { payload }) => {
          slice.loadings.timeOffSkip = false;
      })
  },
});

export const EmployeesRefactorReducer = EmployeesSlice.reducer;

export const {
    openIncomingRequestViewDialog,
    hideIncomingRequestViewDialog,
    setSelectedEmployee,
    deselectEmployee,
    openDeleteEmployeeDialog,
    hideDeleteEmployeeDialog,
    eraseHrSettings,
    eraseEmployeeDocuments,

  openAddSettingDialog,
  hideAddSettingDialog,

  openAddHistoryRowDialog,
  hideAddHistoryRowDialog,

  openDeleteHistoryRowDialog,
  hideDeleteHistoryRowDialog,

  openChangeHistoryRowDialog,
  hideChangeHistoryRowDialog,

  openChangeHireDateDialog,
  hideChangeHireDateDialog,

  openChangeEmploymentStatusDialog,
  hideChangeEmploymentStatusDialog,

  openDiscardChangesDialog,
  hideDiscardChangesDialog,

  eraseEmployees,
  eraseSelectedEmployeeJobHistory,

  openReleaseDialog,
  hideReleaseDialog,

  openPreviewJobTitleDialog,
  hidePreviewJobTitleDialog,

  openDeleteJobTitleDialog,
  hideDeleteJobTitleDialog,

  openEditJobTitleDialog,
  hideEditJobTitleDialog,

  setTreeAction,
  openEditEmployeesTreeNode,

  openEditHrSettingDialog,
  hideEditHrSettingDialog,
  openDeleteHrSettingDialog,
  hideDeleteHrSettingDialog,

  replaceEmployeesMinMaxPage,
  setImportCsvDialogAction,
  replaceEmployeeDocumentsMinMaxPage,
  deselectDocument,
  setIsLoadingDocumentId,

  openExportCsv,
  hideExportCsv,

  //requests
  replaceRequestMinMaxLoadedPage,
  eraseRequestList,

  replaceIssuesMinMaxLoadedPage,
  eraseIssuesList,
  setResolveIssueDialogAction,
  replaceTimeOffMinMaxLoadedPage,
  eraseTimeOffList,
  setTimeOffHistoryDialogAction,
  setRequestErrorAction,

  cleanUp,
    openSkipRequestDialog, hideSkipRequestDialog,

    clearSelectedTimeOffRequestAction,
} = EmployeesSlice.actions;

const selectSelf = (state: AppState):TEmployeesInitialState => state.EmployeesRefactor as TEmployeesInitialState;

export const employees = createSelector(selectSelf, state => state.employees);
export const employeeHistoryPageInfo = createSelector(selectSelf, state => state.employeeHistoryPageInfo);
export const employeeDocumentsSelector = createSelector(selectSelf, state => state.employeeDocuments);
export const employeesPageInfo = createSelector(selectSelf, state => state.employeesPageInfo);
export const dialogs = createSelector(selectSelf, state => state.dialogs);
export const loadings = createSelector(selectSelf, state => state.loadings);
export const hrSettings = createSelector(selectSelf, state => state.hrSettings);
export const selectedEmployee = createSelector(selectSelf, state => state.selectedEmployee);
export const managers = createSelector(selectSelf, state => state.managers);

export const employeesTree = createSelector(selectSelf, state => state.tree);
export const employeesMinLoadedPage = createSelector(selectSelf, state => state.employeesMinLoadedPage);
export const employeesMaxLoadedPage = createSelector(selectSelf, state => state.employeesMaxLoadedPage);
export const incomingRequests = createSelector(selectSelf, state => state.incomingRequests);
export const employeesIssuesSelector = createSelector(selectSelf, state => state.issues);
export const timeOffSelector = createSelector(selectSelf, state => state.timeOffRequests);
