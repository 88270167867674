import {
    Autocomplete,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormGroup,
    IconButton,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import * as S from '../../../components/styled';
import SideSq from "../../forbiddenWrapper/components/img/sideSquare.svg";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {Flex} from "../../../../../../newShared/components/Layouts";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import colors from "../../../../../../newShared/theme/colors";
import {FileDrop} from "react-file-drop";
import React, {Fragment} from "react";
import {ACCEPT_IMAGE_PROP, LABEL} from "../../../../../../newShared/constants";
import DeleteIcon from '@mui/icons-material/Delete';
import RadioGroup from "@mui/material/RadioGroup";
import CloseIcon from '@mui/icons-material/Close';
import {tableCellData} from "../../../types";

//
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {
    availableBranchesOptions,
    availableTeamSizeOptions,
    BUSINESS_PLAN,
    ENTERPRISE_PLAN,
    FREE_PLAN,
    planBenefits
} from "../../../constants";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {Spinner} from "../../../../../../newShared/components/Spinner";
import {useNewOrganizationCreateRefactor} from "../../../hooks/organizations/useNewOrganizationCreateRefactor";

export const NewOrganizationCreate = () => {
    const {isTablet, isLessThenCustomQuery} = useMedia(600);
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'pathSettings.NewOrganizationCreate'});
    const showLeftSideAndSquares: boolean = !isTablet;

    const {
        commonData,
        commonActions,
        initialForm,
        billing,
    } = useNewOrganizationCreateRefactor();


    return(
        <Dialog open={true} onClose={() => null} fullScreen fullWidth sx={{'.MuiPaper-root': {width: '100%'}, overflow: 'hidden'}}>
            {commonData.isLoadingInitialData && <Spinner />}
            <S.MainContainer>
                {showLeftSideAndSquares &&
                    <S.CreateOrgLeftSideBar>
                        {commonData.allSteps.map((e, id) => {
                            const isComplete = e.isComplete;
                            const isCurrent = commonData.step.order === e.order;

                            return(
                                <Flex
                                    key={e.title}
                                    w={'100%'}
                                    onClick={() => (!isCurrent && isComplete) && commonActions.setStep(e)}
                                    direction={'column'}
                                    cursor={(!isCurrent && isComplete) ? 'pointer' : 'default'}
                                    m={id > 0 ? '40px 0 0 0' : undefined}
                                >
                                    <FormGroup>
                                        <FormControlLabel
                                            disabled={!isComplete}
                                            checked={isComplete}
                                            // defaultChecked={isComplete}
                                            control={<Checkbox disableRipple/>}
                                            label={<Typography fontSize={'16px'} fontWeight={600} color={colors.text.grey_dark}>{t(`createOrgSteps.${e.title}`)}</Typography>}
                                        />
                                    </FormGroup>
                                    <Typography variant={'body2'} sx={{margin: !revDir ? '0 0 0 30px' : '0 30px 0 0'}} color={colors.text.grey}>{t(`createOrgSteps.${e.description}`)}</Typography>
                                </Flex>
                            )
                        })}
                    </S.CreateOrgLeftSideBar>
                }

                <S.CreateOrgRightSideContainer>
                    <IconButton
                        onClick={commonActions.handleClose}
                        sx={!revDir ? {position: 'absolute', top: '45px', right: '20px'} : {position: 'absolute', top: '45px', left: '20px'}}
                    >
                        <CloseIcon/>
                    </IconButton>

                    {!showLeftSideAndSquares &&
                        <S.TabletStepScroller>
                            {commonData.allSteps.map((e, id) => {
                                const isComplete = e.isComplete;
                                const isCurrent = commonData.step.order === e.order;

                                return (
                                    <Flex
                                        key={'step' + e.title}
                                        w={'100%'}
                                        onClick={() => (!isCurrent && isComplete) && commonActions.setStep(e)}
                                        direction={'column'}
                                        cursor={(!isCurrent && isComplete) ? 'pointer' : 'default'}
                                        m={!revDir ? '0 8px 0 0' : '0 0 0 8px'}
                                        minw={'367px'}
                                    >
                                        <FormGroup>
                                            <FormControlLabel
                                                disabled={!isComplete}
                                                checked={isComplete}
                                                // defaultChecked={false}
                                                control={<Checkbox disableRipple/>}
                                                label={<Typography fontSize={'16px'} fontWeight={600}
                                                                   color={colors.text.grey_dark}>{t(`createOrgSteps.${e.title}`)}</Typography>}
                                            />
                                        </FormGroup>
                                        <Typography variant={'body2'}
                                                    sx={{margin: !revDir ? '0 0 0 30px' : '0 30px 0 0'}}
                                                    color={colors.text.grey}>{t(`createOrgSteps.${e.description}`)}</Typography>
                                    </Flex>
                                )
                            })}
                        </S.TabletStepScroller>
                    }

                    {commonData.step.order === 0 &&
                        <S.CreateOrgRightSideFieldsContainer>
                            <Typography
                                sx={{margin: !isTablet ? '' : '22px 0 0 0'}}
                                variant={!isTablet ? 'h1' : 'h3'}
                                color={colors.grayText}
                            >{t('Type company information')}</Typography>
                            <Typography
                                variant={!isTablet ? 'body1' : 'body2'}
                                color={colors.text.grey_dark}
                                sx={{margin: !isTablet ? '16px 0 20px 0' : '10px 0 20px 0'}}
                            >{t('Fill in the required fields with information about your company')}</Typography>
                            <TextField
                                variant={'outlined'}
                                label={t('Company name')}
                                required
                                value={initialForm.form.name}
                                onChange={initialForm.handleChange}
                                name={'name'}
                                size={'small'}
                                sx={{margin: '20px 0 0 0'}}
                            />

                            <Autocomplete
                                options={(commonData.countries).map(e => e.name)}
                                renderInput={(params) => <TextField
                                    {...params}
                                    required sx={{margin: '20px 0 0 0'}}
                                    label={t("Select country")}
                                    size={'small'}
                                />}
                                value={initialForm.form.organizationDetails.country}
                                onChange={(e, value) => initialForm.handleSetCountry(value)}
                            />

                            <TextField
                                variant={'outlined'}
                                label={t('Company address')}
                                required
                                value={initialForm.form.organizationDetails.address}
                                onChange={initialForm.handleChange}
                                name={'address'}
                                id={'.organizationDetails'}
                                size={'small'}
                                sx={{margin: '20px 0 0 0'}}
                            />

                            <TextField
                                variant={'outlined'}
                                label={t('Company website')}
                                required
                                value={initialForm.form.organizationDetails.website}
                                onChange={initialForm.handleChange}
                                name={'website'}
                                id={'.organizationDetails'}
                                size={'small'}
                                sx={{margin: '20px 0 0 0'}}
                            />

                            <Flex ai={'center'} m={'20px 0 0 0'}>
                                <Typography variant={'body1'} color={colors.text.grey_dark}>{('Company logo')}</Typography>
                                <Typography variant={'body1'} color={colors.text.critical}>{('*')}</Typography>
                            </Flex>
                            {initialForm.form.logo.length > 0 ?
                                <S.UploadedlogoContainer>
                                    <img src={initialForm.form.logo} alt="logo"
                                         style={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}/>
                                    <Typography color={colors.primary.blue_dark}>{initialForm.fileName}</Typography>
                                    <IconButton onClick={initialForm.handleDeleteLogo}>
                                        <DeleteIcon fontSize={'small'} sx={{color: colors.primary.blue_dark}}/>
                                    </IconButton>
                                </S.UploadedlogoContainer>
                                :
                                <FileDrop onTargetClick={initialForm.filePicker} onDrop={(f) => initialForm.fileHandler(f)}>
                                    <Flex direction={'column'} w={'100%'} minh={'100px'} h={'100px'} jc={'center'}
                                          m={'16px 0 0 0'}
                                          border={`1px dashed ${colors.text.blue}`}
                                          br={'16px'}>
                                        <Flex ai={'center'} jc={'center'} w={'100%'} h={'100%'} p={'16px 0 0 0'}
                                              cursor={'pointer'}>
                                            <Typography color={colors.text.grey_dark}>
                                                {t('Drop your file here or')}
                                                <span style={{color: colors.primary.blue}}> {t('click to upload')}</span>
                                            </Typography>
                                        </Flex>

                                        <input
                                            accept={ACCEPT_IMAGE_PROP}
                                            value=""
                                            style={{visibility: "hidden", opacity: 0}}
                                            ref={initialForm.ref}
                                            multiple
                                            type="file"
                                            onChange={(e) => initialForm.fileHandler(e.target.files)}
                                        />
                                    </Flex>
                                </FileDrop>
                            }

                            <Typography variant={'body1'} color={'#605E5C'} sx={{margin: '40px 0 0 0'}}>{t('Tell us about the industry in which you provide services')}</Typography>

                            <Flex w={'100%'} m={'40px 0 0 0'} wrap={'wrap'} style={{gap: '18px'}}>
                                {!initialForm.unusedChips.length && <Chip label={''} variant={'outlined'} sx={{
                                    width: '50px',
                                    border: `1px dashed ${colors.stroke.blue}`,
                                    color: colors.text.blue
                                }}/>}
                                {initialForm.unusedChips.map(e => <Chip
                                    // label={e}
                                    label={t(`providedServicesChips.${e}`)}
                                    key={'available' + e}
                                    onClick={() => initialForm.handleAddChip(e)}
                                    variant={'outlined'}
                                    sx={{
                                        borderColor: colors.stroke.blue,
                                        color: colors.text.blue,
                                        lineHeight: 'normal'
                                    }}
                                />)}
                            </Flex>

                            <Typography variant={'body1'} color={'#605E5C'} sx={{margin: '40px 0 0 0'}}>{t('Select some statements that are true for your company')}</Typography>

                            <Flex w={'100%'} m={'40px 0 0 0'} wrap={'wrap'} style={{gap: '18px'}}>
                                {initialForm.form.organizationDetails.providingServices.map(e => <Chip
                                    label={t(`providedServicesChips.${e}`)}
                                    // label={e}
                                    key={'selected' + e}
                                    onClick={() => initialForm.handleDeleteChip(e)}
                                    sx={{
                                        backgroundColor: colors.primary.blue_dark,
                                        color: 'white',
                                        lineHeight: 'normal'
                                    }}/>)}
                                {Array.apply(null, Array(4 - initialForm.form.organizationDetails.providingServices.length >= 0 ? 4 - initialForm.form.organizationDetails.providingServices.length : 0)).map((e, id) =>
                                    <Chip label={''} key={id} variant={'outlined'} sx={{
                                        width: (id % 2 === 0) ? '100px' : '50px',
                                        border: `1px dashed ${colors.stroke.blue}`,
                                        color: colors.text.blue
                                    }}/>)}
                            </Flex>

                            <Typography variant={'body1'} color={'#605E5C'} sx={{margin: '40px 0'}}>{t('How big is your team?')}</Typography>
                            <FormControl>
                                <RadioGroup value={initialForm.form.organizationDetails.howBigIsCompany}
                                            onChange={(e, value) => initialForm.handleSetHowBigIsCompany(value)}>
                                    {availableTeamSizeOptions.map(e => <FormControlLabel key={e} value={e}
                                                                                         control={<Radio/>}
                                                                                         label={t(`teamSizes.${e}`)}/>)}
                                </RadioGroup>
                            </FormControl>

                            <Typography variant={'body1'} color={'#605E5C'} sx={{margin: '40px 0'}}>{t('How many branches/offices do you have?')}</Typography>
                            <FormControl>
                                {/*<FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>*/}
                                <RadioGroup value={initialForm.form.organizationDetails.isSingleBranch}
                                            onChange={(e, value) => initialForm.handleIsSingleBranch(value)}>
                                    {availableBranchesOptions.map(e => <FormControlLabel key={e} value={e}
                                                                                         control={<Radio/>}
                                                                                         label={t(`availableBranchesOptions.${e}`)}/>)}
                                </RadioGroup>
                            </FormControl>

                            {initialForm.form.organizationDetails.isSingleBranch === 'multi branches/offices' &&
                                <TextField
                                    variant={'outlined'}
                                    label={t('How many branches/offices?')}
                                    required
                                    value={initialForm.form.organizationDetails.howMuchBranches}
                                    onChange={initialForm.handleChange}
                                    name={'howMuchBranches'}
                                    type={'number'}
                                    InputProps={{inputProps: {min: 0}}}
                                    id={'.organizationDetails'}
                                    size={'small'}
                                    sx={{margin: '20px 0 0 0', width: !isTablet ? '50%' : '100%'}}
                                />
                            }

                            <Flex w={'100%'} ai={'center'} m={'40px 0 0 0'}>
                                <Button variant={'outlined'} onClick={commonActions.goToPrevStep}>{t('Back')}</Button>
                                <Button
                                    sx={{margin: !revDir ? '0 0 0 16px' : '0 16px 0 0'}}
                                    disabled={!commonActions.isOk(0)}
                                    onClick={commonActions.goToNextStep}
                                >{t('Next step')}</Button>
                            </Flex>
                        </S.CreateOrgRightSideFieldsContainer>
                    }

                    {commonData.step.order === 1 &&
                        <S.CreateOrgPlanSelectionContainer overflow={''}>
                            <Typography
                                // sx={{margin: !isTablet ? '' : '22px 0 0 0'}}
                                variant={!isTablet ? 'h1' : 'h3'}
                                color={colors.grayText}
                            >{t('Select a plan that suits you')}</Typography>
                            <Typography
                                variant={!isTablet ? 'body1' : 'body2'}
                                color={colors.text.grey_dark}
                                sx={{margin: !isTablet ? '16px 0 40px 0' : '10px 0 40px 0'}}
                            >
                                {
                                   t('{{companyName}} pricing is based on the number of collaborators, actively participating in Compliance activities. You will be able to change your subscription plan anytime.', {companyName: LABEL})
                                }
                            </Typography>

                            <TableContainer
                                sx={{
                                    width: '100%',
                                    // overflow: 'hidden',
                                    boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                                    borderRadius: '8px !important',
                                    border: `1px solid #EAECF0`,
                                    backgroundColor: '#fff',
                                    zIndex: '1000',
                                    flexGrow: '1',
                                    // maxHeight: '70vh',
                                    // minHeight: '990px'
                                    // minHeight: '600px',
                                }}
                            >
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ minWidth: isLessThenCustomQuery ? '20%' : '34%'}}>
                                                {''}
                                            </TableCell>

                                            {/*<TableCell sx={{*/}
                                            {/*    backgroundColor: initialForm.selectedPlan.selectedFree ? colors.primary.blue_dark : undefined,*/}
                                            {/*    '& div *' : {color: initialForm.selectedPlan.selectedFree ? '#fff !important' : undefined},*/}
                                            {/*    padding: isLessThenCustomQuery ? '12px' : undefined,*/}
                                            {/*}}*/}
                                            {/*>*/}
                                            {/*    <Flex*/}
                                            {/*        // background={selectedPlan.selectedFree ? colors.linkTextColor : undefined}*/}
                                            {/*        w={'100%'} ai={'center'} jc={'center'} talign={'center'}>*/}
                                            {/*        {isLessThenCustomQuery ?*/}
                                            {/*            <Typography fontSize={'12px'} fontWeight={700} color={colors.grayText}>*/}
                                            {/*                {t('FREE')}*/}
                                            {/*                <br />*/}
                                            {/*                <span style={{fontSize: '18px', fontWeight: 500, color: colors.text.grey_dark}}>{('$0')}</span>*/}
                                            {/*                <span style={{fontSize: '12px', fontWeight: 400, color: colors.text.grey_dark}}>{t('/mo')}</span>*/}
                                            {/*            </Typography>*/}
                                            {/*            :*/}
                                            {/*            <Typography variant={'h4'} color={colors.grayText}>*/}
                                            {/*                {t('FREE')}*/}
                                            {/*                <br />*/}
                                            {/*                <span style={{fontSize: '24px', fontWeight: 500, color: colors.text.grey_dark}}>{'$0'}</span>*/}
                                            {/*                <span style={{fontSize: '14px', fontWeight: 400, color: colors.text.grey_dark}}>{t('/mo')}</span>*/}
                                            {/*            </Typography>*/}
                                            {/*        }*/}
                                            {/*    </Flex>*/}
                                            {/*</TableCell>*/}

                                            <TableCell
                                                sx={{
                                                    backgroundColor: initialForm.selectedPlan.selectedBusiness ? colors.primary.blue_dark : undefined,
                                                    '& div *' : {color: initialForm.selectedPlan.selectedBusiness ? '#fff !important' : undefined},
                                                    padding: isLessThenCustomQuery ? '10px' : undefined,
                                                }}
                                            >
                                                <Flex w={'100%'} ai={'center'} jc={'center'} talign={'center'}>
                                                    {isLessThenCustomQuery ?
                                                        <Typography fontSize={'12px'} fontWeight={700} color={colors.grayText}>
                                                            {t('BUSINESS')}
                                                            {/*<br />*/}
                                                            {/*<span style={{fontSize: '18px', fontWeight: 500, color: colors.text.grey_dark}}>$199</span>*/}
                                                            {/*<span style={{fontSize: '12px', fontWeight: 400, color: colors.text.grey_dark}}>{t('/mo')}</span>*/}
                                                        </Typography>
                                                        :
                                                        <Typography variant={'h4'} color={colors.grayText}>
                                                            {t('BUSINESS')}
                                                            {/*<br />*/}
                                                            {/*<span style={{fontSize: '24px', fontWeight: 500, color: colors.text.grey_dark}}>$199</span>*/}
                                                            {/*<span style={{fontSize: '14px', fontWeight: 400, color: colors.text.grey_dark}}>{t('/editor/mo')}</span>*/}
                                                        </Typography>
                                                    }
                                                </Flex>
                                            </TableCell>

                                            <TableCell
                                                sx={{
                                                    backgroundColor: initialForm.selectedPlan.selectedEnterprise ? colors.primary.blue_dark : undefined,
                                                    '& div *' : {color: initialForm.selectedPlan.selectedEnterprise ? '#fff !important' : undefined},
                                                    padding: isLessThenCustomQuery ? '7px' : undefined,
                                                }}
                                            >
                                                <Flex w={'100%'} ai={'center'} jc={'center'} talign={'center'}>
                                                    {isLessThenCustomQuery ?
                                                        <Typography fontSize={'12px'} fontWeight={700} color={colors.grayText}>
                                                            {t('ENTERPRISE')}
                                                            {/*<br />*/}
                                                            {/*<span style={{fontSize: '14px', fontWeight: 500, color: colors.text.grey_dark}}>{t('Contact us')}</span>*/}
                                                        </Typography>
                                                        :
                                                        <Typography variant={'h4'} color={colors.grayText}>
                                                            {t('ENTERPRISE')}
                                                            {/*<br />*/}
                                                            {/*<span style={{fontSize: '24px', fontWeight: 500, color: colors.text.grey_dark}}>{t('Contact us')}</span>*/}
                                                        </Typography>
                                                    }
                                                </Flex>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {planBenefits.map((e, id, arr) => {
                                            return(
                                                <Fragment key={e.rowName}>
                                                    <TableRow id={e.rowName} sx={{'&:nth-of-type(odd)' : {backgroundColor: '#F9FAFB'}}}>
                                                        <TableCell
                                                            sx={{
                                                                paddingLeft: isLessThenCustomQuery ? '8px' : undefined,
                                                                paddingRight: isLessThenCustomQuery ? '8px' : undefined,
                                                                borderTop: id === 0 ? `1px solid ${colors.primary.disable} !important` : undefined
                                                            }}
                                                        >
                                                            <GetInner data={{type: 'text', value: e.rowName}} />
                                                        </TableCell>

                                                        {/*<TableCell*/}
                                                        {/*    sx={{*/}
                                                        {/*        backgroundColor: initialForm.selectedPlan.selectedFree ? colors.primary.blue_dark : undefined,*/}
                                                        {/*        '& div *' : {color: initialForm.selectedPlan.selectedFree ? '#fff !important' : undefined},*/}
                                                        {/*        borderBottomColor: initialForm.selectedPlan.selectedFree ? colors.primary.blue_dark : undefined,*/}
                                                        {/*        padding: isLessThenCustomQuery ? '12px' : undefined,*/}
                                                        {/*        borderTop: initialForm.selectedPlan.selectedFree ? undefined : (id === 0 ? `1px solid ${colors.primary.disable} !important` : undefined)*/}
                                                        {/*    }}*/}
                                                        {/*>*/}
                                                        {/*    <Flex w={'100%'} ai={'center'} jc={'center'} talign={'center'}>*/}
                                                        {/*        <GetInner data={e.free}/>*/}
                                                        {/*    </Flex>*/}
                                                        {/*</TableCell>*/}

                                                        <TableCell
                                                            sx={{
                                                                backgroundColor: initialForm.selectedPlan.selectedBusiness ? colors.primary.blue_dark : undefined,
                                                                '& div *' : {color: initialForm.selectedPlan.selectedBusiness ? '#fff !important' : undefined},
                                                                borderBottomColor: initialForm.selectedPlan.selectedBusiness ? colors.primary.blue_dark : undefined,
                                                                padding: isLessThenCustomQuery ? '10px' : undefined,
                                                                borderTop: initialForm.selectedPlan.selectedBusiness ? undefined : (id === 0 ? `1px solid ${colors.primary.disable} !important` : undefined)
                                                            }}
                                                        >
                                                            <Flex w={'100%'} ai={'center'} jc={'center'} talign={'center'}>
                                                                <GetInner data={e.business}/>
                                                            </Flex>
                                                        </TableCell>

                                                        <TableCell
                                                            sx={{
                                                                backgroundColor: initialForm.selectedPlan.selectedEnterprise ? colors.primary.blue_dark : undefined,
                                                                '& div *' : {color: initialForm.selectedPlan.selectedEnterprise ? '#fff !important' : undefined},
                                                                borderBottomColor: initialForm.selectedPlan.selectedEnterprise ? colors.primary.blue_dark : undefined,
                                                                padding: isLessThenCustomQuery ? '7px' : undefined,
                                                                borderTop: initialForm.selectedPlan.selectedEnterprise ? undefined : (id === 0 ? `1px solid ${colors.primary.disable} !important` : undefined)
                                                            }}
                                                        >
                                                            <Flex w={'100%'} ai={'center'} jc={'center'} talign={'center'}>
                                                                <GetInner data={e.enterprise}/>
                                                            </Flex>
                                                        </TableCell>
                                                    </TableRow>
                                                    {id === arr.length - 1 &&
                                                        <TableRow
                                                            sx={{
                                                                '& .MuiTableCell-root': {
                                                                    left: 0,
                                                                    bottom: 0,
                                                                    zIndex: 2,
                                                                    position: 'sticky',
                                                                    backgroundColor: '#fff'
                                                                }
                                                            }}
                                                        >
                                                            <TableCell>
                                                                {''}
                                                            </TableCell>

                                                            {/*<TableCell*/}
                                                            {/*    sx={{*/}
                                                            {/*        backgroundColor: initialForm.selectedPlan.selectedFree ? `${colors.primary.blue_dark} !important` : undefined,*/}
                                                            {/*        '& div *' : {color: initialForm.selectedPlan.selectedFree ? '#fff !important' : undefined},*/}
                                                            {/*        borderBottomColor: initialForm.selectedPlan.selectedFree ? colors.primary.blue_dark : undefined*/}
                                                            {/*    }}*/}
                                                            {/*>*/}
                                                            {/*    <Flex w={'100%'} ai={'center'} jc={'center'} onClick={() => initialForm.handleSelectPlan(FREE_PLAN)} cursor={'pointer'}>*/}
                                                            {/*        <Typography fontSize={isLessThenCustomQuery ? '13px':'15px'} fontWeight={600} color={colors.text.blue}>{initialForm.form.subscription === FREE_PLAN ? t('Selected') : (isLessThenCustomQuery ? t('Select') : t('Select plan'))}</Typography>*/}
                                                            {/*    </Flex>*/}
                                                            {/*</TableCell>*/}

                                                            <TableCell
                                                                sx={{
                                                                    backgroundColor: initialForm.selectedPlan.selectedBusiness ? `${colors.primary.blue_dark} !important` : undefined,
                                                                    '& div *' : {color: initialForm.selectedPlan.selectedBusiness ? '#fff !important' : undefined},
                                                                    borderBottomColor: initialForm.selectedPlan.selectedBusiness ? colors.primary.blue_dark : undefined
                                                                }}
                                                            >
                                                                <Flex w={'100%'} ai={'center'} jc={'center'} onClick={() => initialForm.handleSelectPlan(BUSINESS_PLAN)} cursor={'pointer'}>
                                                                    <Typography fontSize={isLessThenCustomQuery ? '13px':'15px'} fontWeight={600} color={colors.text.blue}>{initialForm.form.subscription === BUSINESS_PLAN ? t('Selected') : (isLessThenCustomQuery ? t('Select') : t('Select plan'))}</Typography>
                                                                </Flex>
                                                            </TableCell>

                                                            <TableCell
                                                                sx={{
                                                                    backgroundColor: initialForm.selectedPlan.selectedEnterprise ? `${colors.primary.blue_dark} !important` : undefined,
                                                                    '& div *' : {color: initialForm.selectedPlan.selectedEnterprise ? '#fff !important' : undefined},
                                                                    borderBottomColor: initialForm.selectedPlan.selectedEnterprise ? colors.primary.blue_dark : undefined
                                                                }}
                                                            >
                                                                <Flex w={'100%'} ai={'center'} jc={'center'} onClick={() => initialForm.handleSelectPlan(ENTERPRISE_PLAN)} cursor={'pointer'}>
                                                                    <Typography fontSize={isLessThenCustomQuery ? '13px':'15px'} fontWeight={600} color={colors.text.blue}>{initialForm.form.subscription === ENTERPRISE_PLAN ? t('Selected') : (isLessThenCustomQuery ? t('Select') : t('Select plan'))}</Typography>
                                                                </Flex>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </Fragment>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Flex ai={'center'} m={'40px 0 0 0'}>
                                <Checkbox
                                    value={initialForm.isLicenseAgreed}
                                    onChange={(e, value) => initialForm.setIsLicenseAgreed(value)}
                                />
                                <Typography sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} variant={isLessThenCustomQuery ? 'body2' : 'body1'} color={colors.grayText}>{t('I accept')}</Typography>
                                <Typography
                                    variant={isLessThenCustomQuery ? 'body2' : 'body1'}
                                    color={colors.primary.blue}
                                    sx={{cursor: 'pointer'}}
                                    onClick={initialForm.handleOpenEndUserLicenseAgreement}
                                >{t('{{companyName}} End-user license agreement', {companyName: LABEL})}</Typography>
                            </Flex>

                            <Flex w={'100%'} ai={'center'} m={'40px 0 0 0'}>
                                <Button variant={'outlined'} onClick={commonActions.goToPrevStep}>{t('Back')}</Button>
                                <LoadingButton
                                    sx={{margin: !revDir ? '0 0 0 16px' : '0 16px 0 0'}}
                                    loading={commonData.isLoadingCreate}
                                    disabled={!commonActions.isOk(1)}
                                    onClick={commonActions.commonHandleSubmitOnStep1}
                                >
                                    {initialForm.form.subscription === FREE_PLAN ? t('Create') :
                                        // (initialForm.form.subscription === BUSINESS_PLAN ? t('Next') : t('Send request'))}
                                        (t('Send request'))}
                                </LoadingButton>
                            </Flex>
                        </S.CreateOrgPlanSelectionContainer>
                    }

                    {/*{commonData.step.order === 2 &&*/}
                    {/*    <S.CreateOrgRightSideSubDetails>*/}
                    {/*        <Typography*/}
                    {/*            sx={{margin: !isTablet ? '' : '22px 0 0 0'}}*/}
                    {/*            variant={!isTablet ? 'h1' : 'h3'}*/}
                    {/*            color={colors.grayText}*/}
                    {/*        >{t('createOrgSteps.Subscription details')}</Typography>*/}
                    {/*        <Typography*/}
                    {/*            variant={!isTablet ? 'body1' : 'body2'}*/}
                    {/*            color={colors.text.grey_dark}*/}
                    {/*            sx={{margin: !isTablet ? '16px 0 20px 0' : '10px 0 20px 0'}}*/}
                    {/*        >{t('createOrgSteps.Specify details for correct calculation of the subscription price and click the "Proceed to pay" button')}</Typography>*/}

                    {/*        <Flex ai={'center'} jc={isTablet ? 'space-between' : 'flex-start'}>*/}
                    {/*            <Typography variant={'h2'} color={colors.grayText}>{t('Select number of editors')}</Typography>*/}
                    {/*            <Flex ai={'center'} m={!revDir ? '0 0 0 30px' : '0 30px 0 0'}>*/}
                    {/*                <IconButton disabled={billing.form.collaboratorsCount <= 1} onClick={billing.handleDecreaseCollabsCount}>*/}
                    {/*                    <RemoveCircleIcon />*/}
                    {/*                </IconButton>*/}

                    {/*                <Typography variant={'subtitle2'} color={colors.grayText}>{billing.form.collaboratorsCount}</Typography>*/}

                    {/*                <IconButton disabled={billing.form.collaboratorsCount >= 5} onClick={billing.handleIncreaseCollabsCount}>*/}
                    {/*                    <AddCircleIcon />*/}
                    {/*                </IconButton>*/}

                    {/*                <Typography variant={'subtitle1'} color={colors.text.grey}>{t('Up to 5')}</Typography>*/}
                    {/*            </Flex>*/}
                    {/*        </Flex>*/}

                    {/*        <Flex w={'100%'} m={'20px 0 0 0'} style={{gap: isTablet ? '20px' : '40px'}} wrap={'wrap'}>*/}
                    {/*            <S.PriceCardsContainer>*/}
                    {/*                <Flex jc={'space-between'} ai={'center'}>*/}
                    {/*                    <FormControlLabel*/}
                    {/*                        sx={{margin: !revDir ? '0 -11px' : '0 -11px'}}*/}
                    {/*                        onClick={() => billing.handleSelectAnnualType(YEAR_PERIOD)}*/}
                    {/*                        checked={billing.form.period === YEAR_PERIOD}*/}
                    {/*                        control={<Radio />}*/}
                    {/*                        label={t("Bill yearly")}*/}
                    {/*                    />*/}
                    {/*                    <Flex background={colors.primary.blue} br={'30px'} p={'6px 10px'}>*/}
                    {/*                        <Typography fontSize={'14px'} fontWeight={400} color={colors.text.white}>{t('Save 20%')}</Typography>*/}
                    {/*                    </Flex>*/}
                    {/*                </Flex>*/}

                    {/*                <Typography color={colors.text.grey_dark} variant={'h2'}>*/}
                    {/*                    {('$')}{BUSINESS_COLLABORATOR_YEAR_COST}*/}
                    {/*                    <span style={{fontSize: '14px', fontWeight: 400}}>{t('/editor/mo')}</span>*/}
                    {/*                </Typography>*/}

                    {/*                <Typography color={colors.text.grey} fontWeight={600} fontSize={'13px'}>*/}
                    {/*                    {t('Annual commitment')}*/}
                    {/*                </Typography>*/}
                    {/*            </S.PriceCardsContainer>*/}

                    {/*            <S.PriceCardsContainer>*/}
                    {/*                <FormControlLabel*/}
                    {/*                    onClick={() => billing.handleSelectAnnualType(MONTH_PERIOD)}*/}
                    {/*                    control={<Radio checked={billing.form.period === MONTH_PERIOD} />}*/}
                    {/*                    label={t("Bill monthly")}*/}
                    {/*                />*/}

                    {/*                <Typography color={colors.text.grey_dark} variant={'h2'}>*/}
                    {/*                    {('$')}{BUSINESS_COLLABORATOR_MONTH_COST}*/}
                    {/*                    <span style={{fontSize: '14px', fontWeight: 400}}>{t('/editor/mo')}</span>*/}
                    {/*                </Typography>*/}
                    {/*            </S.PriceCardsContainer>*/}
                    {/*        </Flex>*/}

                    {/*        <Flex*/}
                    {/*            w={'100%'}*/}
                    {/*            m={'20px 0 0 0'}*/}
                    {/*            direction={'column'}*/}
                    {/*            box_shadow={'0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13)'}*/}
                    {/*            br={'10px'}*/}
                    {/*            p={'16px'}*/}
                    {/*            background={colors.backgrounds.white}*/}
                    {/*            zi={'100'}*/}
                    {/*        >*/}
                    {/*            <Typography color={colors.grayText} fontSize={'18px'} fontWeight={600}>*/}
                    {/*                <span style={{color: colors.text.critical}}>{('* ')}</span>*/}
                    {/*                {t('Billing information')}*/}
                    {/*            </Typography>*/}

                    {/*            <Flex w={'100%'} direction={'column'} m={'24px 0 0 0'}>*/}
                    {/*                <Flex w={'100%'} ai={'center'} gap={'14px'}>*/}
                    {/*                    <TextField*/}
                    {/*                        variant={'outlined'}*/}
                    {/*                        label={t('First name')}*/}
                    {/*                        required*/}
                    {/*                        value={billing.form.billingPrimaryInfo.firstName}*/}
                    {/*                        onChange={billing.handleChange}*/}
                    {/*                        id={'.billingPrimaryInfo'}*/}
                    {/*                        name={'firstName'}*/}
                    {/*                        size={'small'}*/}
                    {/*                    />*/}

                    {/*                    <TextField*/}
                    {/*                        variant={'outlined'}*/}
                    {/*                        label={t('Last name')}*/}
                    {/*                        required*/}
                    {/*                        value={billing.form.billingPrimaryInfo.lastName}*/}
                    {/*                        onChange={billing.handleChange}*/}
                    {/*                        id={'.billingPrimaryInfo'}*/}
                    {/*                        name={'lastName'}*/}
                    {/*                        size={'small'}*/}
                    {/*                    />*/}
                    {/*                </Flex>*/}

                    {/*                <TextField*/}
                    {/*                    variant={'outlined'}*/}
                    {/*                    label={t('Address')}*/}
                    {/*                    required*/}
                    {/*                    value={billing.form.billingPrimaryInfo.address}*/}
                    {/*                    onChange={billing.handleChange}*/}
                    {/*                    name={'address'}*/}
                    {/*                    id={'.billingPrimaryInfo'}*/}
                    {/*                    size={'small'}*/}
                    {/*                    sx={{margin: '14px 0 0 0'}}*/}
                    {/*                />*/}

                    {/*                <TextField*/}
                    {/*                    variant={'outlined'}*/}
                    {/*                    label={t('Billing email')}*/}
                    {/*                    required*/}
                    {/*                    value={billing.form.billingPrimaryInfo.email}*/}
                    {/*                    onChange={billing.handleChange}*/}
                    {/*                    name={'email'}*/}
                    {/*                    id={'.billingPrimaryInfo'}*/}
                    {/*                    size={'small'}*/}
                    {/*                    sx={{margin: '14px 0 0 0'}}*/}
                    {/*                    error={billing.isShowErrorInBillingEmail}*/}
                    {/*                    helperText={billing.isShowErrorInBillingEmail ? t('Email is not valid') : undefined}*/}
                    {/*                />*/}

                    {/*                <Flex w={'100%'} ai={'center'} gap={'14px'} m={'14px 0 0 0'}>*/}
                    {/*                    <Autocomplete*/}
                    {/*                        sx={{width: '50%'}}*/}
                    {/*                        options={(commonData.countries).map(e => e.name).slice().sort()}*/}
                    {/*                        renderInput={(params) => <TextField*/}
                    {/*                            {...params}*/}
                    {/*                            required*/}
                    {/*                            placeholder={t('Country')}*/}
                    {/*                            size={'small'}*/}
                    {/*                        />}*/}
                    {/*                        value={billing.form.billingPrimaryInfo.country}*/}
                    {/*                        onChange={(e, value) => value && billing.handleSetBillingCountry(value)}*/}
                    {/*                    />*/}

                    {/*                    <TextField*/}
                    {/*                        variant={'outlined'}*/}
                    {/*                        label={t('Postal / Zip code')}*/}
                    {/*                        required*/}
                    {/*                        value={billing.form.billingPrimaryInfo.zip}*/}
                    {/*                        onChange={billing.handleChange}*/}
                    {/*                        name={'zip'}*/}
                    {/*                        id={'.billingPrimaryInfo'}*/}
                    {/*                        sx={{width: '50%'}}*/}
                    {/*                        size={'small'}*/}
                    {/*                    />*/}
                    {/*                </Flex>*/}

                    {/*                {*/}
                    {/*                    billing.additionalFields.map((f) => {*/}
                    {/*                        return(*/}
                    {/*                            <TextField*/}
                    {/*                                key={'add field ' + f.fieldKey}*/}
                    {/*                                required*/}
                    {/*                                variant={'outlined'}*/}
                    {/*                                label={(f.fieldLabel)}*/}
                    {/*                                value={billing.form.additionalBillingInfo[f.fieldKey] ?? ''}*/}
                    {/*                                onChange={billing.handleChange}*/}
                    {/*                                name={f.fieldKey}*/}
                    {/*                                placeholder={f.fieldPlaceholder}*/}
                    {/*                                id={'.additionalBillingInfo'}*/}
                    {/*                                size={'small'}*/}
                    {/*                                sx={{margin: '14px 0 0 0'}}*/}
                    {/*                            />*/}
                    {/*                        )*/}
                    {/*                    })*/}
                    {/*                }*/}
                    {/*            </Flex>*/}
                    {/*        </Flex>*/}

                    {/*        <Flex ai={'center'} jc={'space-between'} m={'37px 0 0 0'}>*/}
                    {/*            <Typography color={colors.grayText} variant={'subtitle2'}>{t('Amount')}</Typography>*/}
                    {/*            <Typography color={colors.grayText} variant={'subtitle2'}>${billing.due.amount}</Typography>*/}
                    {/*        </Flex>*/}

                    {/*        <Flex ai={'center'} jc={'space-between'} m={'16px 0 0 0'}>*/}
                    {/*            <Typography color={colors.text.success} variant={'subtitle2'}>{t('Discount - Annual prepaid (20%)')}</Typography>*/}
                    {/*            <Typography color={colors.text.success} variant={'subtitle2'}>${billing.due.yearPlanDiscount}</Typography>*/}
                    {/*        </Flex>*/}

                    {/*        <Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable, margin: '16px 0 0 0'}}/>*/}

                    {/*        <Flex ai={'center'} jc={'space-between'} m={'16px 0 0 0'}>*/}
                    {/*            <Typography color={colors.grayText} variant={'subtitle2'}>{t('Subtotal')}</Typography>*/}
                    {/*            <Typography color={colors.grayText} variant={'subtitle2'}>${billing.due.subtotal}</Typography>*/}
                    {/*        </Flex>*/}

                    {/*        {billing.form.isVat &&*/}
                    {/*            <Flex ai={'center'} jc={'space-between'} m={'26px 0 0 0'}>*/}
                    {/*                <Typography color={colors.grayText} variant={'subtitle2'}>{t('VAT (17%)')}</Typography>*/}
                    {/*                <Typography color={colors.grayText} variant={'subtitle2'}>${billing.due.vat}</Typography>*/}
                    {/*            </Flex>*/}
                    {/*        }*/}

                    {/*        <Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable, margin: '20px 0 0 0'}}/>*/}

                    {/*        <Flex ai={'center'} jc={'space-between'} m={'26px 0 0 0'}>*/}
                    {/*            <Typography color={colors.grayText} variant={'subtitle2'}>*/}
                    {/*                {t('total amount')}*/}
                    {/*                {(' ')}*/}
                    {/*                {billing.form.isVat && <span style={{color: colors.text.grey}}>{t('(including VAT)')}</span>}*/}
                    {/*            </Typography>*/}
                    {/*            <Typography color={colors.grayText} variant={'subtitle2'}>${billing.due.total}</Typography>*/}
                    {/*        </Flex>*/}

                    {/*        <Flex w={'100%'} ai={'center'} m={'40px 0 0 0'}>*/}
                    {/*            <Button variant={'outlined'} onClick={commonActions.goToPrevStep}>{t('Back')}</Button>*/}
                    {/*            <LoadingButton*/}
                    {/*                loading={commonData.isLoadingCreate}*/}
                    {/*                sx={{margin: !revDir ? '0 0 0 16px' : '0 16px 0 0'}}*/}
                    {/*                disabled={!commonActions.isOk(2)}*/}
                    {/*                onClick={commonActions.handleCreateBusinessOrganization} //create not completed org + transaction and receive iframe link*/}
                    {/*            >{t('Proceed to Pay')}</LoadingButton>*/}
                    {/*        </Flex>*/}
                    {/*    </S.CreateOrgRightSideSubDetails>*/}
                    {/*}*/}

                    {/*{commonData.step.order === 3 &&*/}
                    {/*    <S.CreateOrgRightSidePaymentIframeContainer>*/}
                    {/*        <Typography*/}
                    {/*            sx={{margin: !isTablet ? '' : '22px 0 0 0'}}*/}
                    {/*            variant={!isTablet ? 'h1' : 'h3'}*/}
                    {/*            color={colors.grayText}*/}
                    {/*        >{t('Billing Information')}</Typography>*/}
                    {/*        <Typography*/}
                    {/*            variant={!isTablet ? 'body1' : 'body2'}*/}
                    {/*            color={colors.text.grey_dark}*/}
                    {/*            sx={{margin: !isTablet ? '16px 0 20px 0' : '10px 0 20px 0'}}*/}
                    {/*        >{t('createOrgSteps.Provide a payment method')}</Typography>*/}


                    {/*        {(commonData.isLoadingCreate || billing.transactionLoading) ? <LocalSpinner/> :*/}
                    {/*            <>*/}
                    {/*                {billing.transactionNumber !== null ?*/}
                    {/*                    <iframe*/}
                    {/*                        style={{border: `1px solid #E7E7E7`}}*/}
                    {/*                        title={'Payment'}*/}
                    {/*                        src={`https://api.sandbox.sensepass.com/api/transactions/sensepass-pci/credit-card/${billing.transactionNumber}?component=landing`}*/}
                    {/*                        // src={`https://pay.sandbox.sensepass.com/orderSummary/${billing.transactionNumber}?viewMode=medium`}*/}
                    {/*                        height={'100%'}*/}
                    {/*                    ></iframe>*/}
                    {/*                    :*/}
                    {/*                    <>Error: no transaction link found</>*/}
                    {/*                }*/}
                    {/*            </>*/}
                    {/*        }*/}

                    {/*    </S.CreateOrgRightSidePaymentIframeContainer>*/}
                    {/*}*/}

                    {/*{commonData.step.order === 4 &&*/}
                    {/*    <S.CreateOrgRightSideSuccessContainer>*/}
                    {/*        <CheckCircleIcon color={'secondary'} sx={{width: '80px', height: '80px'}}/>*/}
                    {/*        <Typography variant={'h1'} color={colors.text.success} sx={{margin: '16px 0 0 0'}}>{t('Thank you!')}</Typography>*/}
                    {/*        <Typography variant={'h3'} color={colors.stroke.dark} sx={{margin: '16px 0 0 0'}}>{t('Payment successful!')}</Typography>*/}
                    {/*        <Button onClick={billing.handleGoToFrameworksAfterSuccessPayment} sx={{margin: '16px 0 0 0'}}>{t('Go to frameworks')}</Button>*/}
                    {/*    </S.CreateOrgRightSideSuccessContainer>*/}
                    {/*}*/}
                </S.CreateOrgRightSideContainer>

                {showLeftSideAndSquares && <img src={SideSq} alt="asd"/>}

                <CreateOrganizationWarningDialog
                    isOpen={initialForm.warningDialog}
                    handleClose={() => initialForm.setWarningDialog(false)}
                    handleSubmit={commonActions.commonHandleSubmitOnStep1}
                    isLoading={commonData.isLoadingCreate}
                />

                <DiscardChangesDialog
                    isOpen={commonData.discardChangesDialog.isOpen}
                    handleClose={commonData.discardChangesDialog.handleClose}
                    isLoading={commonData.isLoadingDeleteNotCompletedOrg}
                    handleSubmit={commonActions.handleDiscardChanges}
                />

                <UnsuccessfulPaymentDialog
                    isOpen={billing.unsuccessfulPaymentDialog}
                    handleClose={() => billing.setUnsuccessfulPaymentDialog(false)}
                    />
            </S.MainContainer>
        </Dialog>
    )
}

export const GetInner = ({data}: { data: tableCellData }) => {
    const {isLessThenCustomQuery} = useMedia(600);
    const {t} = useMainTranslation('', {keyPrefix: 'pathSettings.NewOrganizationCreate.tableValues'});

    if(data.type === 'icon'){
        switch (data.value){
            case 'infinity': return <AllInclusiveIcon sx={{color: colors.text.success}} />;
            case 'minus': return <RemoveCircleIcon sx={{color: colors.text.critical}} />;
            case 'plus': return <AddCircleIcon sx={{color: colors.text.success}} />;
        }
    }else{
        if(isLessThenCustomQuery){
            return <Typography fontSize={'12px'} fontWeight={400} color={colors.grayText}>{t(data.value)}</Typography>;
        }else{
            return <Typography variant={'body1'} color={colors.grayText}>{t(data.value)}</Typography>;
        }
    }

    return <></>;
}

export const CreateOrganizationWarningDialog = ({isOpen, handleClose, isLoading, handleSubmit} : {isOpen: boolean, handleClose: () => void, handleSubmit: () => void, isLoading: boolean}) => {
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'pathSettings.NewOrganizationCreate.CreateOrganizationWarningDialog'});
    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Billing')}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{lineHeight: 'normal'}}>{t('After you submit the request, we will contact you to clarify the payment details. At the moment, you can use the platform and add frameworks.')}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                >
                    {t('Continue')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export const DiscardChangesDialog = ({isOpen, handleClose, isLoading, handleSubmit} : {isOpen: boolean, handleClose: () => void, handleSubmit: () => void, isLoading: boolean}) => {
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'pathSettings.NewOrganizationCreate.CreateOrganizationWarningDialog'});
    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Discard changes')}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{lineHeight: 'normal'}}>{t('This organization info will be deleted')}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                >
                    {t('Continue')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export const UnsuccessfulPaymentDialog = ({isOpen, handleClose} : {isOpen: boolean, handleClose: () => void}) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathSettings.NewOrganizationCreate.CreateOrganizationWarningDialog'});
    return(
        <Dialog
            open={isOpen}
            onClose={handleClose}
            sx={{position: 'absolute', overflow: 'auto'}}
            PaperComponent={(props) => <Flex
                background={colors.backgrounds.white}
                direction={'column'}
                ai={'center'}
                p={'20px 48px '}
                br={'24px'}
            >{props.children}</Flex>}
        >
            <CancelIcon sx={{
                width: '80px',
                height: '80px',
                position: 'relative',
                // left: '50%',
                top: -60,
                zIndex: 1000,
                color: colors.text.critical,
                // background: colors.backgrounds.white,
                borderRadius: '50%',
                // boxShadow: '0px 4.11464px 4.11464px #D59E9E',
            }}/>

            <Flex zi={'900'} w={'40px'} h={'40px'} position={'relative'} top={'-120px'} background={colors.backgrounds.white}>

            </Flex>

            <Typography variant={'h1'} sx={{margin: '-64px 0 0 0', color: colors.text.critical}}>{t('Payment failed')}</Typography>
            <Typography variant={'h3'} sx={{margin: '16px 0 0 0'}}>{t('Try again, or use different payment method')}</Typography>
            <Button onClick={handleClose} variant={'text'} sx={{alignSelf: 'flex-end', margin: '16px 0 0 0'}}>{t('Close')}</Button>
        </Dialog>
    )
}