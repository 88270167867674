import React, {ChangeEvent} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    appDuties,
    categories,
    cleanAppDuties,
    cleanConflicts,
    cleanRuleset,
    cleanScheduler,
    conflicts,
    deselectRule,
    dialogs,
    hideAllowConflict,
    hideAllowedConflictData,
    hideDeleteDuty,
    hideDeleteException,
    hideDeniedConflictData,
    hideDenyConflict,
    hideEditDuty,
    hideEditDutyRule,
    hideExtendException,
    hideViewConflict,
    loadings,
    openAllowConflict,
    openAllowedConflictData,
    openCreateDuty,
    openDeleteDuty,
    openDeleteDutyRule,
    openDeleteException,
    openDeniedConflictData,
    openDenyConflict,
    openEditDuty,
    openEditDutyRule,
    openExtendException,
    openShowPermissions,
    openViewConflict,
    pageInfo,
    ruleset,
    runRuleCheckConflicts,
    selectConflict,
    selectedConflict,
    selectedRule,
    selectRule,
    shortApplications,
    shortCollaborators,
    shortDuties,
    shortRoles,
    sodScheduler
} from "../../store/slice";
import {cleanUp} from "../../../physicalAssets/store/store";
import {TAppDuty, TConflictException, TDutyRule, TRulesConflict, TSodSchedulerData} from "../../types";
import {useHistory} from "react-router";
import {
    PATH_ACCESS_MANAGEMENT_CONFLICT_LIST,
    PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES,
    PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET,
    PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET_NEW
} from "../../constants";
import {
    allowConflictCustomAction,
    allowConflictStandardAction,
    deleteConflictExceptionAction,
    denyConflictAction,
    GetAppDutiesAction,
    getDataForAllowedConflictAction,
    getDataForCreateDutyAction,
    getDataForCreateRuleAction,
    getDataForDenyConflictAction,
    GetDutiesRulesetAction,
    getRoleNamesAction,
    getRulesConflictsAction,
    GetSodSchedulerDataAction,
    updateConflictExceptionAction,
    updateDutyRuleAction
} from "../../store/actions";
import {
    AllowConflictCustomMutationVariables,
    AllowConflictStandardMutationVariables,
    PaginationInput
} from "../../../../../newShared/GQLTypes";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";


export const useSegregationOfDuties = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const _appDuties = useSelector(appDuties);
    // const _conflicts = useSelector(conflicts);
    // const app = useSelector(selectedAppOrigin);
    const _pageInfo = useSelector(pageInfo);
    const _selectedConflict = useSelector(selectedConflict);
    const _ruleset = useSelector(ruleset);
    const _selectedRule = useSelector(selectedRule);
    const _shortApplications = useSelector(shortApplications);
    const _shortDuties = useSelector(shortDuties);
    const _shortCollaborators = useSelector(shortCollaborators);
    const _shortRoles = useSelector(shortRoles);
    const scheduler = useSelector(sodScheduler);
    const {setMessage} = useMessageDialog();
    const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const clean = () => dispatch(cleanUp);


    //getData
    const getDuties = (pageInfo: PaginationInput, signal?: AbortSignal, clean?: boolean) => {
        clean && dispatch(cleanAppDuties());
        dispatch(GetAppDutiesAction({data: {pageInfo}, signal}))
    }

    const getRuleset = (pageInfo: PaginationInput, signal?: AbortSignal, clean?: boolean) => {
        clean && dispatch(cleanRuleset());
        dispatch(GetDutiesRulesetAction({data: {pageInfo}, signal}))
    }

    const getConflicts = (pageInfo: PaginationInput, signal?: AbortSignal, clean?: boolean) => {
        clean && dispatch(cleanConflicts());
        dispatch(getRulesConflictsAction({data: {pageInfo}, signal}));
    }

    const getSchedulerData = (pageInfo: PaginationInput, signal: AbortSignal, clean?: boolean) => {
        clean && dispatch(cleanScheduler());
        dispatch(GetSodSchedulerDataAction({data: {pageInfo}, signal}));
    }

    const getDataForCreateDuty = () => {
        dispatch(getDataForCreateDutyAction())
    }
    const getDataForCreateRule = () => {
        dispatch(getDataForCreateRuleAction())
    }

    const getRoleNamesByAppAccessId = (appAccessId: string) => {
        dispatch(getRoleNamesAction({workspaceId: '', organizationId: '', appAccessId}))
    }

    //goTo
    const goToRuleExact = (ruleId: string) => {
        history.push(`${PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET}/exact/${ruleId}`);
    }

    const goToRuleNew = () => {
        history.push(PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET_NEW);
    }

    const goToDutiesList = () => {
        history.push(PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES);
    }

    const goToConflictList = () => {
        history.push(PATH_ACCESS_MANAGEMENT_CONFLICT_LIST);
    }

    const goToRuleset = () => {
        history.push(PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET);
    }

    const goToConflicts = () => {
        history.push(PATH_ACCESS_MANAGEMENT_CONFLICT_LIST);
    }

    //actions duty
    const handleOpenCreateDuty = () => {
        dispatch(openCreateDuty());
        dispatch(getDataForCreateDutyAction());
    }

    const handleOpenEditDuty = (duty: TAppDuty) => {
        dispatch(openEditDuty({selectedDuty: duty}));
        dispatch(getDataForCreateDutyAction());
    }
    const handleHideEditDuty = () => {
        dispatch(hideEditDuty());
    }

    const handleOpenDeleteDuty = (duty: TAppDuty) => {
        dispatch(openDeleteDuty({selectedDuty: duty}));
    }

    const handleHideDeleteDuty = () => {
        dispatch(hideDeleteDuty());
    }

    //actions rule
    const handleSelectRule = (rule: TDutyRule) => {
        dispatch(selectRule(rule));
    }
    const handleDeselectRule = () => {
        dispatch(deselectRule());
    }
    const handleOpenEditRule = (rule: TDutyRule) => {
        dispatch(openEditDutyRule({selectedRule: rule}));
        dispatch(getDataForCreateRuleAction());
    }

    const handleHideEditRule = () => {
        dispatch(hideEditDutyRule());
    }

    const handleOpenDeleteRule = (rule: TDutyRule) => {
        dispatch(openDeleteDutyRule({selectedRule: rule}));
    }

    const handleChangeIsActive = (e: ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>, rule: TDutyRule) => {
        e.stopPropagation();
        dispatch(updateDutyRuleAction({data: { rule, workspaceId: '', organizationId: ''}}));

    }

    //actions conflict
    const handleOpenViewConflict = (event: React.MouseEvent, conflict: TRulesConflict) => {
        event.stopPropagation();
        dispatch(selectConflict(conflict));
        dispatch(openViewConflict());
    }

    const handleHideViewConflict = () => {
        dispatch(hideViewConflict());
    }

    const handleOpenAllowedConflictData = (e: React.MouseEvent, ruleId: string, account: string) => {
        e.stopPropagation();
        dispatch(openAllowedConflictData({ruleId}));
        dispatch(getDataForAllowedConflictAction({workspaceId: '', organizationId: '', account, ruleId}))
    }
    const handleHideAllowedConflictData = () => {
        dispatch(hideAllowedConflictData());
    }

    const handleOpenDeniedConflictData = (e: React.MouseEvent, conflict: TRulesConflict) => {
        e.stopPropagation();
        dispatch(openDeniedConflictData({conflict}));
    }
    const handleHideDeniedConflictData = () => {
        dispatch(hideDeniedConflictData());
    }

    const handleOpenAllowConflict = () => {
        dispatch(hideViewConflict());
        dispatch(openAllowConflict());
        dispatch(getDataForDenyConflictAction());
    }

    const handleOpenDenyConflict = () => {
        dispatch(hideViewConflict());
        dispatch(openDenyConflict());
        dispatch(getDataForDenyConflictAction());
    }


    const handleHideDenyConflict = () => {
        dispatch(hideDenyConflict());
    }

    const handleHideAllowConflict = () => {
        dispatch(hideAllowConflict());
    }

    const handleOpenExtendException = (exception: TConflictException, ruleId: string) => {
        dispatch(openExtendException({exception, ruleId}));
    }

    const handleHideExtendException = () => {
        dispatch(hideExtendException());
    }

    const handleOpenDeleteException = (exception: TConflictException, ruleId: string) => {
        dispatch(openDeleteException({exception, ruleId}));
    }

    const handleHideDeleteException = () => {
        dispatch(hideDeleteException());
    }

    const handleDenyException = (exception: TConflictException, ruleId: string) => {
        dispatch(updateConflictExceptionAction({data: {workspaceId: '', organizationId: '', ruleId, exception: {...exception, active: false}}}));
    }

    const handleExtendException = (exception: TConflictException, ruleId: string) => {
        dispatch(updateConflictExceptionAction({data: {workspaceId: '', organizationId: '', ruleId, exception}}));
    }

    const handleDeleteException = (exception: TConflictException, ruleId: string) => {
        dispatch(deleteConflictExceptionAction({data: {workspaceId: '', organizationId: '', ruleId, exception}}));
    }

    const onSuccessDeny = () => {
        setMessage({title: t("Completed successfully"), message: t("Your notification has been sent.")})
    }

    const handleSaveDenyConflict = (conflict: TRulesConflict) => {
        dispatch(denyConflictAction({data: {conflict, workspaceId: '', organizationId: ''}, onSuccess: onSuccessDeny}, ))
    }

    const handleSaveAllowConflictStandard = (data: AllowConflictStandardMutationVariables) => {
        dispatch(allowConflictStandardAction({data}))
    }

    const handleSaveAllowConflictCustom = (data: AllowConflictCustomMutationVariables) => {
        dispatch(allowConflictCustomAction({data}))
    }

    const onRoleClick = (roleName: string, rolePermissions: string[]) => {
        rolePermissions.length > 0 && dispatch(openShowPermissions({rolePermissions, roleName}));
    }


    const handleUpdateSodSchedulerData = (newData: TSodSchedulerData) => {
        // dispatch(updateSodSchedulerDataAction({data: {workspaceId: '', organizationId: '', schedulerData: newData}}))
    }



    return {
        appDuties: _appDuties,
        pageInfo: _pageInfo,
        loadings: useSelector(loadings),
        dialogs: useSelector(dialogs),
        categories: useSelector(categories),
        conflicts: useSelector(conflicts),
        runRuleCheckConflicts: useSelector(runRuleCheckConflicts),
        ruleset: _ruleset,
        selectedConflict: _selectedConflict,
        selectedRule: _selectedRule,
        scheduler,

        //data for dialogs
        shortApplications: _shortApplications,
        shortDuties: _shortDuties,
        shortCollaborators: _shortCollaborators,
        shortRoles: _shortRoles,

        getDuties,
        getRuleset,
        getConflicts,
        getDataForCreateDuty,
        getDataForCreateRule,
        getSchedulerData,

        goToRuleExact,
        goToRuleNew,
        goToDutiesList,
        goToConflictList,
        goToRuleset,
        goToConflicts,

        //duty
        handleOpenCreateDuty,
        handleOpenEditDuty,
        handleHideEditDuty,
        handleOpenDeleteDuty,
        handleHideDeleteDuty,
        getRoleNamesByAppAccessId,
        onRoleClick,

        //rules
        handleChangeIsActive,
        handleOpenEditRule,
        handleHideEditRule,
        handleOpenDeleteRule,
        handleSelectRule,
        handleDeselectRule,
        handleOpenExtendException,
        handleHideExtendException,
        handleOpenDeleteException,
        handleHideDeleteException,
        handleDenyException,
        handleDeleteException,
        handleExtendException,


        //conflicts
        handleOpenViewConflict,
        handleHideViewConflict,
        handleOpenAllowConflict,
        handleHideAllowConflict,
        handleSaveAllowConflictStandard,
        handleSaveAllowConflictCustom,
        handleOpenDenyConflict,
        handleHideDenyConflict,
        handleSaveDenyConflict,
        handleHideAllowedConflictData,
        handleOpenAllowedConflictData,
        handleOpenDeniedConflictData,
        handleHideDeniedConflictData,

        //scheduler
        handleUpdateSodSchedulerData,

        clean,
    }


}