import React, {FC, useEffect} from "react";
import {TReportControlNCR} from "../../types";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Collapse,
    Divider,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {getNCRStatus} from "../../helpers";
import {DEFAULT_DATE_FORMAT, parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

import {Delete, ExpandLess, ExpandMore, FileUpload} from "@mui/icons-material";
import {toBase64} from "../../../../../newShared/utils/base64/base64";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {RequestHistoryModel} from "../../../../../newShared/GQLTypes";
import moment from "moment";
import {useNcrBlockPage} from "./hooks/useNcrBlockPage";
import {usePDFView} from "../../../../../newShared/components/docViewer/hooks/usePDFView";
import {LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {TextFieldCustom} from "../styled";
import {useMultiFileDropZone} from "../../../../../newShared/hooks/useMultiFileDropZone";

type TProps = {
    ncr: TReportControlNCR;
    open?: boolean;
    handleOpen?: () => void;
    onlyOne?: boolean,
}
export const NcrBlock: FC<TProps> = ({ncr, open = true, handleOpen, onlyOne = false}) => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathReports.View'});
    const {tCommon} = useMainTranslation();

    const {
        currentUser, users,
        completed, notCompleted,
        PUBLISHED, CAN_REDACT, REJECTED,
        editMode, setEditMode,
        corrective, setCorrective,
        uploadAssetsToNCR, downloadFile, deleteAsset, leaveResponseToNCR,
        isLoading: {isSubmittingNcr, isLoadingFiles, isDeletingFiles, isUploadingAsset},
        history, setHistory,
    } = useNcrBlockPage(ncr, open);
    const {handleOpen: pdfOpen} = usePDFView();

    const requestHistory = ncr.requestHistory || [] as RequestHistoryModel[];
    const {fileHandler, filePicker, uploadedFiles, ref} = useMultiFileDropZone();

    useEffect(() => {
        if (uploadedFiles.length > 0) {
            Promise.all(uploadedFiles.map(file => toBase64(file).then(code => ({
                fileData: code,
                fileName: file.name
            })))).then(files => {
                files.length > 0 && uploadAssetsToNCR({
                    data: files,
                    reportId: ncr.reportId,
                    frameworkId: ncr.frameworkId,
                    controlId: ncr.controlId,
                    ncrId: ncr.id,
                    workspaceId: '',
                })
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedFiles])

    return (
        <Box width={'100%'} display={"flex"} flexDirection={"column"}>

            {handleOpen && (
                <Box display={"flex"} p={'5px 0'} justifyContent={"space-between"} onClick={handleOpen} alignItems={"center"} sx={{
                    '&:hover': {
                        cursor: 'pointer',
                        // backgroundColor: colors.backgrounds.grey_light,
                    }
                }}>
                    <Typography variant={"h3"} color={colors.text.dark} flexGrow={1}>{`Created at ${parseDateAuto(ncr.createDate)}`}</Typography>
                    <Typography variant={"h3"} color={getNCRStatus(t, ncr.status).color} margin={'0 8px'}>{getNCRStatus(t, ncr.status).status}</Typography>
                    <Icon>{open ? (<ExpandLess/>) : (<ExpandMore/>)}</Icon>
                </Box>
            )}

            <Collapse in={open} sx={{'& .MuiCollapse-wrapperInner': {width: '100%', display: "flex", flexDirection: 'column'}}}>
                {(completed || notCompleted || PUBLISHED) ? (
                    <>
                        <Collapse in={CAN_REDACT && !!notCompleted && !editMode}>
                            <Button onClick={() => {setEditMode(true)}}
                                    sx={{margin: handleOpen ? '16px 0 0 0' : undefined, width: 'fit-content'}}>
                                {tCommon('Edit')}
                            </Button>
                        </Collapse>

                        <Typography margin={(CAN_REDACT && notCompleted && !editMode) || handleOpen ? '16px 0 0 0' : undefined} variant={"subtitle2"} color={colors.text.dark}>{t('Type of Non-Conformity')}</Typography>
                        <Typography margin={'8px 0 0 0'} variant={"body1"} color={colors.text.dark}>{ncr.type || ''}</Typography>

                        <Typography margin={'16px 0 0 0'} variant={"subtitle2"} color={colors.text.dark}>{t('Observed Non-Conformity')}</Typography>
                        <Typography margin={'8px 0 0 0'} variant={"body1"} color={colors.text.dark}>{ncr.observed || ''}</Typography>

                        {REJECTED && requestHistory[requestHistory.length - 1]?.correctiveActionAccepted === false && (
                            <>
                                <Typography margin={'16px 0 0 0'} variant={"subtitle2"} color={colors.text.dark}>{t('Reject comment')}</Typography>
                                <Typography margin={'8px 0 0 0'} variant={"body1"} color={colors.text.dark}>{requestHistory[requestHistory.length - 1].comment || ''}</Typography>
                            </>
                        )}

                        <Typography margin={'16px 0 0 0'} variant={"subtitle2"} color={colors.text.dark}>{t('Root cause analysis')}</Typography>
                        {!editMode && <Typography margin={'8px 0 0 0'} variant={"body1"} color={colors.text.dark}>{corrective.causeAnalysis}</Typography>}
                        {editMode && (
                            <TextField sx={{margin: '8px 0 0 0'}} multiline rows={3} value={corrective.causeAnalysis}
                                       onChange={e => {setCorrective(prevState => ({...prevState, causeAnalysis: e.target.value}))}}
                                       disabled={isSubmittingNcr.includes(ncr.id)}/>
                        )}

                        <Typography margin={'16px 0 0 0'} variant={"subtitle2"} color={colors.text.dark}>{t('Corrective action')}</Typography>
                        {!editMode && <Typography margin={'8px 0 0 0'} variant={"body1"} color={colors.text.dark}>{corrective.description}</Typography>}
                        {editMode && (
                            <TextField sx={{margin: '8px 0 0 0'}} multiline rows={3} value={corrective.description}
                                       onChange={e => {setCorrective(prevState => ({
                                           ...prevState,
                                           proposedCompletionDate: e.target.value.trim() === '' ? null : prevState.proposedCompletionDate,
                                           description: e.target.value
                                       }))}}
                                       disabled={isSubmittingNcr.includes(ncr.id)}/>
                        )}

                        <Typography margin={'16px 0 0 0'} variant={"subtitle2"} color={colors.text.dark}>{t('Proposed completion date')}</Typography>
                        {!editMode && <Typography margin={'8px 0 0 0'} variant={"body1"} color={colors.text.dark}>{moment(corrective.proposedCompletionDate).format('DD/MM/YYYY')}</Typography>}
                        {editMode && (
                            <DatePicker
                                disabled={isSubmittingNcr.includes(ncr.id) || !editMode || corrective.description.trim() === ''}
                                value={corrective.proposedCompletionDate}
                                inputFormat={DEFAULT_DATE_FORMAT}
                                onChange={(newValue) => {setCorrective(prevState => ({...prevState, proposedCompletionDate: newValue}))}}
                                renderInput={(params) => <TextFieldCustom {...params} placeholder={'dd/mm/yyyy'} sx={{margin: '8px 0 0 0', maxWidth: '250px'}} />}
                            />
                        )}

                        {editMode && (
                            <LoadingButton loading={isUploadingAsset} startIcon={<FileUpload/>} variant={"text"}
                                           onClick={filePicker}
                                           sx={{margin: '16px 0 0 0', width: 'fit-content', paddingLeft: '10px', paddingRight: '10px'}} disabled={isSubmittingNcr.includes(ncr.id)}>
                                {t('Upload  additional evidences')}
                            </LoadingButton>
                        )}

                        <input
                            // accept={accept}
                            value=""
                            style={{ visibility: "hidden", opacity: 0 }} hidden
                            ref={ref}
                            multiple
                            type="file"
                            onChange={(e) => fileHandler(e.target.files)}
                        />

                        {!editMode && <Typography margin={'16px 0 0 0'} variant={"subtitle2"} color={colors.text.dark}>{t('Additional evidences')}</Typography>}
                        {corrective.assets.length === 0 && (
                            <Typography variant={"body1"} color={colors.text.dark} marginTop={'6px'}>
                                {t('You have not yet published any evidence.')}
                            </Typography>
                        )}
                        <TableContainer style={{overflow: "auto"}} className={'withoutBorder'}>
                            <Table size={"micro"} style={{marginTop: '8px'}}>
                                <TableBody>
                                    {corrective.assets.map(asset => (
                                        <TableRow sx={{'& td': {borderBottom: 'none'}}} key={asset.assetId}>
                                            <TableCell width={1000} style={{paddingLeft: !revDir ? 0 : undefined, paddingRight: !revDir ? undefined : 0}}>
                                                <Box display={"flex"} alignItems={"center"}>
                                                    <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                disabled={isLoadingFiles.includes(asset.assetId)}
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    downloadFile(asset.assetId, asset.fileName)
                                                                }}>
                                                        {isLoadingFiles.includes(asset.assetId)
                                                            ? <CircularProgress size={24}/>
                                                            : <DownloadIcon style={{color: colors.text.blue}}/>}
                                                    </IconButton>

                                                    {asset.fileName.endsWith('.pdf') && (
                                                        <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        pdfOpen({fileId: asset.assetId, fileName: asset.fileName})
                                                                    }}>
                                                            <VisibilityIcon style={{color: colors.text.blue}}/>
                                                        </IconButton>
                                                    )}

                                                    {asset.fileName}
                                                </Box>
                                            </TableCell>

                                            {editMode && (
                                                <TableCell align={"center"}>
                                                    <IconButton size={'small'} disabled={isDeletingFiles.includes(asset.assetId)}
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                    deleteAsset({ncrId: ncr.id, assetId: asset.assetId, workspaceId: ''})
                                                                }}>
                                                        <Delete/>
                                                    </IconButton>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {editMode && (
                            <LoadingButton loading={isSubmittingNcr.includes(ncr.id)}
                                           disabled={
                                               corrective.causeAnalysis.trim() === ''
                                               || (corrective.description.trim() !== '' && !moment(corrective.proposedCompletionDate).isValid())
                                           }
                                           sx={{margin: '16px 0 0 0', width: 'fit-content'}}
                                           onClick={() => {leaveResponseToNCR({
                                               workspaceId: '', frameworkId: ncr.frameworkId, controlId: ncr.controlId, reportId: ncr.reportId, ncrId: ncr.id,
                                               data: {
                                                   causeAnalysis: corrective.causeAnalysis.trim(),
                                                   correctiveAction: {
                                                       authorPublicId: corrective.authorPublicId,
                                                       description: corrective.description.trim(),
                                                       proposedCompletionDate: moment(corrective.proposedCompletionDate).isValid() ? corrective.proposedCompletionDate!.toISOString() : ''
                                                   }}
                                           }, () => {setEditMode(false)})}}>
                                {tCommon('Save')}
                            </LoadingButton>
                        )}
                    </>
                ) : (
                    <Typography variant={"body2"} color={colors.text.dark}>{t("can not redact until auditor not publish this report.")}</Typography>
                )}

                {(requestHistory.filter((e, index, array) => !(e.correctiveActionAccepted === false && index === array.length - 1)).length ?? 0) > 0 && <Typography margin={'16px 0 8px 0'} variant={"subtitle2"} color={colors.text.dark}>{t('Previous NCRs')}</Typography>}
                {requestHistory.filter((e: RequestHistoryModel, index, array) => !(e.correctiveActionAccepted === false && index === array.length - 1)).map((h, index) => (
                    <Accordion expanded={history === index} onChange={() => {setHistory(prev => prev === index ? -1 : index)}} key={JSON.stringify(h)} >
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography variant={"body2"}>
                                {`${t('Added at')} ${
                                    parseDateAuto(h.approveDate, true)
                                } ${
                                    currentUser?.publicId === h.correctiveAction?.authorPublicId
                                        ? t('You')
                                        : users.find(e => e.id === h.correctiveAction?.authorPublicId)?.name}`}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant={"subtitle2"} color={colors.text.dark}>{t('Root cause analysis')}</Typography>
                            <Typography margin={'8px 0 0 0'} variant={"body1"} color={colors.text.dark}>{h.causeAnalysis}</Typography>

                            <Typography margin={'16px 0 0 0'} variant={"subtitle2"} color={colors.text.dark}>{t('Corrective action')}</Typography>
                            <Typography margin={'8px 0 0 0'} variant={"body1"} color={colors.text.dark}>{h.correctiveAction?.description || ''}</Typography>

                            <Typography margin={'16px 0 0 0'} variant={"subtitle2"} color={colors.text.dark}>{t('Proposed completion date')}</Typography>
                            <Typography margin={'8px 0 0 0'} variant={"body1"} color={colors.text.dark}>{parseDateAuto(h.correctiveAction?.proposedCompletionDate)}</Typography>

                            {(h.evidenceAssets?.length || 0) > 0 && <Typography margin={'16px 0 0 0'} variant={"subtitle2"} color={colors.text.dark}>{t('Additional evidences')}</Typography>}
                            <TableContainer style={{overflow: "auto"}} className={'withoutBorder'}>
                                <Table size={"micro"} style={{marginTop: '8px'}}>
                                    <TableBody>
                                        {h.evidenceAssets?.map(asset => (
                                            <TableRow sx={{'& td': {borderBottom: 'none'}}} key={asset.assetId}>
                                                <TableCell width={1000} style={{paddingLeft: !revDir ? 0 : undefined, paddingRight: !revDir ? undefined : 0}}>
                                                    <Box display={"flex"} alignItems={"center"}>
                                                        <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                    disabled={isLoadingFiles.includes(asset.assetId)}
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        downloadFile(asset.assetId, asset.fileName)
                                                                    }}>
                                                            {isLoadingFiles.includes(asset.assetId)
                                                                ? <CircularProgress size={24}/>
                                                                : <DownloadIcon style={{color: colors.text.blue}}/>}
                                                        </IconButton>

                                                        {asset.fileName.endsWith('.pdf') && (
                                                            <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                        onClick={(event) => {
                                                                            event.stopPropagation();
                                                                            pdfOpen({fileId: asset.assetId, fileName: asset.fileName})
                                                                        }}>
                                                                <VisibilityIcon style={{color: colors.text.blue}}/>
                                                            </IconButton>
                                                        )}

                                                        {asset.fileName}
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {h.comment && (
                                <>
                                    <Typography margin={'16px 0 0 0'} variant={"subtitle2"} color={colors.text.dark}>{t('Reject comment')}</Typography>
                                    <Typography margin={'8px 0 0 0'} variant={"body1"} color={colors.text.dark}>{h.comment || ''}</Typography>
                                </>
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))}

                {!onlyOne && <Divider flexItem sx={{marginTop: '10px'}}/>}
            </Collapse>
        </Box>
    )
}
