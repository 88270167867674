import {useRequestsList} from "../../../hooks/requests/useRequestsList";
import * as S from '../../../components/styled/index';
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";
import {IconButton} from "@mui/material";
import {RequestNotCompleted} from "../../../components/dialogs/requestNotCompleted";
import {CancelRequestDialog} from "../../../components/dialogs/cancelRequest";
import React from "react";
import {KycRefactorRequestModel} from "../../../../../../newShared/GQLTypes";
import {getNormalizedTemplateTypeName} from "../../../helpers";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {GenericTable} from "../../../../../../newShared/components/genericTable";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {RequestStatus} from "../../../components/requests/requestStatus";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {GenericTableTooltipWithoutArrow} from "../../../../../../newShared/components/genericTable/components/styled";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const RequestsList = () => {
    const {requests, filter, handleReloadData, isDataOutdated, genericTable} = useRequestsList();
    const {isMobile} = useMedia();
    const {t} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.requests'});
    return(
        <>
            <S.PageContainer>
                <PageTitleAndActions
                    showDataHasBeenUpdated={isDataOutdated}
                    onReloadData={handleReloadData}
                    title={t('Requests')}
                    actions={[]}
                />

                <GenericTable<KycRefactorRequestModel>
                    id={'RequestsList'}
                    columnsConfig={{totalName: t('Total requests'), forceShowCardsWhen: isMobile}}
                    paging={genericTable.paging}
                    rowsConfig={{
                        rows: requests,
                        customCellPaddings: '8px',
                        getRowUniqueId: (row) => row.id,
                        onRowClick: genericTable.onRowClick,
                        columnsConfigs: [
                            {name: t('ID/Email'), key: 'sendTo', default: true},
                            {name: t('Type'), key: 'templateName', default: true, valueGetter: (row) => getNormalizedTemplateTypeName(row.templateType || '')},
                            {name: t('Sent Date'), key: 'Sent Date', default: true, valueGetter: (row) => parseDateAuto(row.sendDate)},
                            {name: t('Response Date'), key: 'Response Date', default: true, valueGetter: (row) => parseDateAuto(row.responseDate)},
                            {name: t('Status'), key: 'Status', default: true, valueRenderer: (row) => <Flex><RequestStatus status={row.status}/></Flex>},
                            {name: '', key: 'button', default: true, valueRenderer: (row) => {
                                    const isInProgress = row.status === 'IN_PROGRESS';
                                    return(
                                        <Flex ai={'center'}>
                                            {isInProgress &&
                                                <GenericTableTooltipWithoutArrow title={t('Cancel request')}>
                                                    <IconButton size={'small'} color={'primary'} onClick={() => genericTable.onCancelRequest(row)}>
                                                        <CancelScheduleSendIcon/>
                                                    </IconButton>
                                                </GenericTableTooltipWithoutArrow>
                                            }
                                        </Flex>
                                    )
                                }}
                        ],
                    }}
                    filtersConfig={{
                        dontSendSearchIfEmpty: true,
                        searchCustomFilterKeyName: 'nameLike',
                        genericFilterProps: {
                            configs: filter.configs,
                            fetchResultWithSelectedFilters: () => null,
                            isAddFilters: false
                        }
                    }}
                    emptyArrayImageProps={{
                        type: 'kycScreenings',
                        filterNotApplied: {text: t('No requests to display'), secondaryText: t('Add requests by sending templates to the clients.')}
                    }}
                />

                {/*<GenericFiltersArray*/}
                {/*    dateFilterFormat={KYC_DATE_FORMAT}*/}
                {/*    configs={filter.configs}*/}
                {/*    fetchResultWithSelectedFilters={filter.applyFilter}*/}
                {/*    isAddFilters={false}*/}
                {/*/>*/}

                {/*{!isNoRequestsFound &&*/}
                {/*    <S.CommonTableContainer onScroll={scroll.onScroll} ref={scroll.scrollRef}>*/}
                {/*        <Table stickyHeader>*/}
                {/*            <RequestsTableHeader total={pageInfo.total}/>*/}
                {/*            <TableBody>*/}
                {/*                {requests.map(e => (<RequestsTableRow key={e.id} request={e}/>))}*/}
                {/*                {(isLoading || scroll.hasMore) && <RequestsTableRowSkeleton />}*/}
                {/*            </TableBody>*/}
                {/*        </Table>*/}
                {/*    </S.CommonTableContainer>*/}
                {/*}*/}

                {/*<EmptyArrayImage*/}
                {/*    type={'kycScreenings'}*/}
                {/*    isShowing={isNoRequestsFound}*/}
                {/*    text={('No requests to display')}*/}
                {/*    secondaryText={('Add requests by sending templates to the clients.')}*/}
                {/*    withoutAdd*/}
                {/*/>*/}

                <RequestNotCompleted />
                <CancelRequestDialog />
            </S.PageContainer>
        </>
    )
}
