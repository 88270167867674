import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideShowVerifyApplicationRevokeReason} from "../../../../store/slice";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const ShowVerifyApplicationRevokeReasonDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.CampaignReviewExecutorExact.dialogs'});
    const dispatch = useDispatch();
    const {isOpen, comment, leavedBy} = useSelector(dialogs).showVerifyApplicationRevokeReason;

    const handleClose = () => {
        dispatch(hideShowVerifyApplicationRevokeReason());
    }

    return(
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{t(`Revoke reason leaved by {{leavedBy}}`, {leavedBy})}</DialogTitle>

            <DialogContent>
                <Typography variant={'body2'}>{comment}</Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{t('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}