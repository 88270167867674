import {FC} from "react";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {DeleteDialogButton} from "../../../../../../../newShared/components/deleteDialogButton";
import {useDeleteEvidence} from "../../../../hooks/dialogs/useDeleteEvidence";

export const DeleteEvidenceDialog: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'builderPage.dialogs'});

    const {
        isOpen,
        isLoading,
        handleClose,
        handleConfirm,
    } = useDeleteEvidence();


    return (
        <DeleteDialogButton isOpen={isOpen} isLoading={isLoading}
                            title={t('Delete evidence')}
                            message={t('Any changes will be effects all frameworks that the control has attached. Effected on 2 frameworks.\n' +
                                'Do you want to delete anyway?')}
                            handleConfirm={handleConfirm}
                            handleClose={handleClose}
        />
    )
}
