import React, {FC, useEffect} from "react";
import {useTrainingsDialogs} from "../../../hooks/useTrainingsDialogs";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Radio,
    TextField,
    Typography
} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    FlexColumn,
    FlexRow
} from "../../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import colors from "../../../../../../newShared/theme/colors";
import {TrainingExamQuestionType} from "../../../../../../newShared/GQLTypes";
import {CoverImageView} from "../../trainingCoverComponent/CoverImageView";

export const ExamPreview: FC = () => {
    const {t, tCommon} = useMainTranslation();
    const {
        exam,
        handleClose
    } = useTrainingsDialogs().examPreview;

    useEffect(() => {
        return () => {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    return exam && (
        <Dialog open onClose={handleClose} sx={{
            '.MuiPaper-root': {
                width: 'min(1120px, 95vw)',
                minWidth: 'min(1120px, 95vw)',
                backgroundColor: colors.backgrounds.grey_light2,
            }
        }}>
            <DialogTitle>{t('Exam preview')}</DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '30px', overflow: 'auto'}}>
                <FlexRow sx={{gap: '22px'}}>
                    {exam?.coverImage && <CoverImageView cover={exam.coverImage} size={'preview'}/> }

                    {/*<FlexRow sx={{width: 'min(100%, 280px)', justifyContent: 'center', alignItems: 'center'}}>*/}
                    {/*    {isLoadingImage ? <CircularProgress size={48}/> :*/}
                    {/*        <img src={exam.coverImage.image} alt={exam?.name + ' cover'} style={{width: 'min(100%, 280px)', height: 'fit-content', borderRadius: '6px'}}/>*/}
                    {/*    }*/}
                    {/*</FlexRow>*/}

                    <FlexColumn sx={{gap: '30px', flexGrow: 1}}>
                        <Typography variant={'*h3'}>{exam.name.trim() || 'Your exam name'}</Typography>
                        <Typography variant={'*bodyText1'} sx={{whiteSpace: 'break-spaces'}}>{exam.description.trim()}</Typography>
                    </FlexColumn>
                </FlexRow>

                {exam.questions.length > 0 &&
                    <FlexColumn sx={{width: '100%', gap: '16px'}}>
                        <Typography variant={'*bodyText1_semibold'}>{t('Questions')}</Typography>

                        {exam.questions.map((question, index) => (
                            <FlexColumn key={question.questionId} sx={{
                                gap: '12px', p: '25px 20px', width: '100%',
                                border: `1px solid ${colors.stroke.table}`,
                                borderRadius: '6px',
                                backgroundColor: colors.backgrounds.white,
                            }}>
                                {question.description.trim() && <Typography variant={'*bodyText1'} sx={{whiteSpace: 'break-spaces'}}>{question.description.trim()}</Typography>}
                                <Typography variant={'*bodyText2_semibold'}>{question.question.trim() || `Question ${index}`}</Typography>

                                <FlexColumn sx={{width: '100%'}}>
                                    {question.options?.map((option, index) =>
                                        <FormControlLabel checked={option.correct} key={option.optionId}
                                                          control={question.type === TrainingExamQuestionType.Checkboxes
                                                              ? <Checkbox disabled size={"small"}/>
                                                              : <Radio disabled size={"small"} sx={{filter: 'grayscale(1)', opacity: 0.5}}/>
                                                          }
                                                          label={
                                                              <FlexRow sx={{gap: '16px', alignItems: 'center'}}>
                                                                  <Typography variant={'*bodyText1'} sx={{filter: 'grayscale(1)'}}>{option.option.trim() || `Option ${index}`}</Typography>
                                                                  {option.correct && (
                                                                      <Typography variant={'*bodyText1'} sx={{color: colors.text.success}}>{t('Correct')}</Typography>
                                                                  )}
                                                              </FlexRow>
                                                          }
                                        />
                                    )}
                                </FlexColumn>

                                {question.type === TrainingExamQuestionType.ShortAnswer &&
                                    <TextField placeholder={t('Type your answer')} variant={"outlined"} fullWidth disabled
                                               size={"small"}/>
                                }

                                {question.type === TrainingExamQuestionType.Paragraph &&
                                    <TextField placeholder={t('Type your answer')} variant={"outlined"} fullWidth disabled
                                               multiline rows={4}/>
                                }
                            </FlexColumn>
                        ))}
                    </FlexColumn>
                }
            </DialogContent>

            <DialogActions>
                <Button variant={"text"} size={'small'} onClick={handleClose}>{tCommon('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}
