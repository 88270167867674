import styled, {keyframes} from "styled-components";
import {FC} from "react";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 30px;
  height: 30px;
  border-radius: 50%;  
`;

const Wrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    position:fixed;
    left:0;
    right:0;
    top:0;
    bottom:0;
    //background-color: inherit; 
    background-color: rgba(255,255,255,0.7); 
    z-index:10000;
`;

export const LocalLoader = styled.div<{width?: string, height?: string}>`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: ${props => props.width ?? '30px'};
  height: ${props => props.height ?? '30px'};
  border-radius: 50%;  
`;

const LocalWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    position:relative;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background-color: inherit;
    z-index:1000;
    padding: 5px; 
`;
type PropsType = {
    isLoading?: boolean;
    width?: string;
    height?: string;
}
export const Spinner:FC<PropsType> = (props: PropsType) => {
    const {isLoading = true} = props;
    return <>{isLoading && (<Wrapper><Loader data-testid={'spinner'} /></Wrapper>)}</>
}

export const LocalSpinner:FC<PropsType> = (props: PropsType) => {
    const {isLoading = true} = props;
    return <>{isLoading && (<LocalWrapper><LocalLoader height={props.height} width={props.width} data-testid={'spinner'}/></LocalWrapper>)}</>
}
