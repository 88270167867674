import {Flex} from "../../../../../newShared/components/Layouts";
import {Typography} from "@mui/material";
import {getTemplateTypeChipColorAndText} from "../../helpers";

export const TemplateTypeChip = ({type}: {type: string}) => {
    const {color, text} = getTemplateTypeChipColorAndText(type);

    return(
        <Flex border={`1px solid ${color}`} p={'4px 16px'} br={'15px'}>
            <Typography color={color} variant={'bodySmall'} whiteSpace={'nowrap'}>{text}</Typography>
        </Flex>
    )
}
