import {useDispatch, useSelector} from "react-redux";
import {hrSettings, loadings, openAddSettingDialog, eraseHrSettings} from "../../store/slice";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_EMPLOYEES_ALL, PATH_LOCAL_EMPLOYEES_JOB_TITLE} from "../../constants";
import {useEffect, useState} from "react";
import {THrSettingsJobTitle} from "../../types";
import {GetHrEmployeesSettings} from "../../store/actions";

export const useSettingsJobTitle = () => {
    const dispatch = useDispatch();
    const {jobTitle} = useSelector(hrSettings);
    const isLoading = useSelector(loadings).getHrSettings;
    const [searchResult, setSearchResult] =  useState<THrSettingsJobTitle[]>([]);

    const handleOpenAddJobTitle = () => {
        dispatch(openAddSettingDialog({type: 'jobTitle'}));
    }

    const handleSearch = (search: string):void => {
        if(search.trim().length !==0) {
            setSearchResult(
                jobTitle.slice().sort((prev, next) => next.count - prev.count).filter(item => (item.name.toLowerCase().includes(search.toLowerCase())))
            );
        } else {
            setSearchResult(jobTitle.slice().sort((prev, next) => next.count - prev.count));
        }
    };

    useEffect(() => {
        if(!isLoading) {
            dispatch(eraseHrSettings());
            setSearchResult([]);
            dispatch(GetHrEmployeesSettings({data: {}}));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!isLoading){
            setSearchResult(jobTitle.slice().sort((prev, next) => next.count - prev.count))
        }
        // eslint-disable-next-line
    }, [isLoading]);

    //breadcrumps
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    useSetBreadcrumbs([
        {
            title: tMenu('HR Management'),
            path: PATH_LOCAL_EMPLOYEES_ALL
        },
        {
            title: tMenu('Job title'),
            path: PATH_LOCAL_EMPLOYEES_JOB_TITLE
        }
    ]);

    return{
        jobTitle,
        isLoading,
        handleOpenAddJobTitle,
        isNoJobTitles: !isLoading && !jobTitle.length,
        handleSearch,
        setSearchResult,
        searchResult
    }
}