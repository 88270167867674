import {useDispatch, useSelector} from "react-redux";
import {
    frameworkControlDelegatedTasks,
    frameworkControlDialogsSelector,
    setCancelDelegatedTaskDialogAction
} from "../../../store/control/store";
import {
    DelegatedTaskModel,
    Exact,
    MainFrameworksCancelTaskByIdMutationVariables,
} from "../../../../../../newShared/GQLTypes";
import {TApolloErrors} from "../../../../../../newShared/utils/asyncThunk/types";
import {frameworkGetDelegatedTaskById, frameworksCancelTaskById} from "../../../store/control/actions";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useFrameworksPathParams} from "../../useFrameworksPathParams";
import * as yup from "yup";
import {useFormik} from "formik";
import React, {useEffect} from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";


export const useCancelDelegatedTask = () => {

    const dispatch = useDispatch();
    const {t} = useMainTranslation("", {keyPrefix: "pathFrameworks.Dialogs"});

    const {cancelDelegatedTask: {isOpen, task}} = useSelector(frameworkControlDialogsSelector);
    const {isLoadingCancel} = useSelector(frameworkControlDelegatedTasks);
    const {setMessage} = useMessageDialog();
    const {taskId} = useFrameworksPathParams();

    const validationSchema = yup.object({
        comment: yup.string()
            .nullable()
            .required(t('Comment field is required'))
            .max(1000, t('Comment should be less than 1000 symbols'))
    });

    const formik = useFormik({
        initialValues: {comment: ''},
        validationSchema,
        onSubmit: (values, formikHelpers) => {
            //timeoffId && handleApproveRequest(timeoffId)
        },
        isInitialValid: false
    })
    const formikOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldTouched(e.target.name, true, false);
        formik.handleChange(e);
    };

    useEffect(() => {
        formik.resetForm();

        //eslint-disable-next-line
    },[isOpen])


    const openCancelDelegatedTask = (task: DelegatedTaskModel) => {
        dispatch(setCancelDelegatedTaskDialogAction({isOpen: true, task}))
    }

    const handleClose = () => {
        dispatch(setCancelDelegatedTaskDialogAction({clear: true}))
    }

    const getTaskById = (_id: string) => {
        dispatch(frameworkGetDelegatedTaskById({data: {workspaceId: '', taskId: _id}, clean: true, onError: handleClose}));
    }

    const handleCancelTask = () => {

        const onSuccess = (request: (Omit<MainFrameworksCancelTaskByIdMutationVariables, "workspaceId" | "organizationId"> & {workspaceId?: string | undefined, organizationId?: string | undefined}), response: DelegatedTaskModel, addictiveData?: ({} | undefined)) => {
            setMessage({title: t('Completed successfully'), message: t(`Delegated task {{responseId}} has been cancelled.`, {responseId: response.id})});
            handleClose();
        }

        const onError = (request: (Omit<Exact<{workspaceId: string, taskId: string, reason: string}>, "workspaceId" | "organizationId"> & {workspaceId?: string | undefined, organizationId?: string | undefined}), error: TApolloErrors, addictiveData?: ({} | undefined)) => {
            const error409 = error.e409?.[0];
            const error404 = error.e404?.[0];
            if(error409?.type === 'STATUS_CHANGED'){
                setMessage({title: t('Action conflict error'), message: t(`Action not applicable! Task status was changed`)});
                taskId && getTaskById(taskId);
                handleClose();
            }
            if(error404){
                setMessage({title: t(`Delegated task {{taskId}}`, {taskId}), message: t(`Delegated task with id {{taskId}} not found`, {taskId})});
                taskId && getTaskById(taskId);
                handleClose();
            }
        }

        task && dispatch(frameworksCancelTaskById({data: {taskId: task.id, reason: formik.values.comment}, onSuccess, onError}));
    }


    return {
        isOpen,
        isLoading: isLoadingCancel,
        task,
        formik: {...formik, handleChange: formikOnChange},
        openCancelDelegatedTask,
        handleClose,
        handleCancelTask,
    }
}
