import {useAppDispatch} from "../../../../../../newShared/redux";
import {useSelector} from "react-redux";
import {
    hideEditSettingRowDialog,
    isSettingsLoadingSelector,
    vendorsDialogsSelector,
    vendorsSettingsSelector
} from "../../../store/slice";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {useEffect, useState} from "react";
import {editVendorsSettingsRowAction} from "../../../store/actions";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {parseForSettingEdit} from "../../../helpers";

export const useEditSetting = () => {
    const dispatch = useAppDispatch();
    const {editSettingRow} = useSelector(vendorsDialogsSelector);
    const isSettingsLoading = useSelector(isSettingsLoadingSelector);
    const settings = useSelector(vendorsSettingsSelector);
    const {setMessage} = useMessageDialog();

    const handleClose = () => dispatch(hideEditSettingRowDialog())

    const {form, setForm, handleChange} = useForm<{ newName: string}>({
        newName: ''
    });
    const [isExist, setIsExists] = useState<boolean>(false);
    const isOk = editSettingRow.type === null? false : form.newName.trim().length && settings && settings[editSettingRow.type].some(e => e.name.trim().toLowerCase() !== form.newName.trim().toLowerCase())
    const onSuccess = () => {
        editSettingRow.type && setMessage({title: 'Completed successfully', message: parseForSettingEdit(editSettingRow.type)});
    }
    const handleSubmit = () => {
        editSettingRow.type &&
        (form.newName && editSettingRow.itemToEdit && settings && !isExist) && dispatch(editVendorsSettingsRowAction({
            data: {
                workspaceId: '',
                organizationId: '',
                field: editSettingRow.type,
                title: form.newName,
                oldTitle: (editSettingRow.itemToEdit).name,
            },
            onSuccess
        }));
    }

    useEffect(() => {
        if (editSettingRow.isOpen && editSettingRow.itemToEdit) {
            setForm({...form, newName: editSettingRow.itemToEdit.name});
        }
        //eslint-disable-next-line
    }, [editSettingRow.isOpen]);

    useEffect(() => {
        if (settings && editSettingRow.type!==null) {
            if (editSettingRow.itemToEdit && editSettingRow.itemToEdit.name === form.newName) {
                setIsExists(false);
            } else {
                const cond = !settings[editSettingRow.type].some(e => e.name.toLowerCase().trim() === form.newName.toLowerCase().trim());
                setIsExists(!cond);
            }
        }
        //eslint-disable-next-line
    }, [form.newName]);

    return{
        type: editSettingRow.type,
        isOpen: editSettingRow.isOpen,
        isLoading: editSettingRow.isLoading,
        isSettingsLoading,
        handleClose,
        handleSubmit,
        handleChange,
        form,
        isOk,
        isExist,
    }
}