import {Box, styled, Typography} from "@mui/material";
import colors from "../../../theme/colors";


export const ChipBox = styled(Box)(({background, color, margin}: {background?: string; color?: string; margin?:string}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '15px',
    padding: '6px 14px',
    width: 'max-content',
    backgroundColor: background ?? '#FBEFFD',
    color: color ?? colors.decorative.lilac,
    margin
}));

export const ChipTitle = styled(Typography)(({color}: {color?: string}) => ({
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.15px',
    color: color ?? colors.decorative.lilac,
}));

ChipTitle.defaultProps = {
    noWrap: true,
}

