import {print} from "graphql";
import {gql} from "@apollo/react-hooks";

export const VendorFragment = print(gql`
    fragment VendorFragment on VendorModel {
        id
        name
        
        link
        
        type
        serviceProvided
        risk
        
        owner {
            publicId
            firstName
            lastName
            email
        }
        
        contactPersons {
            email
            name
            phone
        }
        
        securityReviewDate
        documentStatus
        documents {
            id
            name
            type
        }
        
        evaluation {
            evaluator {
                publicId
                firstName
                lastName
                email
            }
            price
            quality
            service
            notesAndRecommendations
        }
        
        customFields
    }
`)

export const VendorSettingsFragment = print(gql`
    fragment VendorSettingsFragment on VendorsSettingsModel {
        risk {
            name
            count
        }
        type {
            name
            count
        }
        serviceProvided {
            name
            count
        }
        customFields {
            name
            count
        }
    }
`)