import {availableAppType, installedAppType} from "../../types";
import * as S from '../styled';
import {useAddIntegrationCard} from "../../hooks/useAddIntegrationCard";
import {Flex} from "../../../../../newShared/components/Layouts";
import CheckIcon from '@mui/icons-material/Check';
import {Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";

export const AddedAppCard = ({app, onClick, currentApp}: {app: installedAppType, onClick: (app: installedAppType) => void, currentApp?: availableAppType}) => {
    const {hover, onMouseLeave, onMouseEnter} = useAddIntegrationCard();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathIntegrations.AddedAppsList'});
    const {isMobile} = useMedia();

    return(
        <S.IntegrationCard onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={() => onClick(app)}>
            {hover ?
                <>
                    <Flex w={'100%'} jc={'center'} maxh={isMobile ? undefined : '90px'} overflow={'auto'}>
                        <Typography color={colors.text.grey_dark} fontSize={'12px'} fontWeight={400} sx={{margin: '8px 0 0 0'}}>{currentApp?.description}</Typography>
                    </Flex>
                    <Flex w={'100%'} jc={'center'} ai={'center'} m={'8px 0 0 0'}>
                        <Typography color={colors.text.success} fontSize={'15px'} fontWeight={600}>{t('CONFIGURE')}</Typography>
                    </Flex>
                </>
                :
                <>
                    <Flex w={'100%'} jc={'center'} style={{marginBlock: 'auto'}}>
                        <img src={app.logo} alt={app.name} style={{maxWidth: '32%', margin: 'auto', width: 'inherit'}}/>
                    </Flex>

                    <Flex w={'100%'} jc={'center'}>
                        <Typography
                            color={colors.grayText}
                            fontSize={'16px'}
                            fontWeight={700}
                            sx={{
                                margin: '8px 0 0 0',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                lineHeight: 'normal',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            {app.name}
                        </Typography>
                    </Flex>

                    <Flex w={'100%'} jc={'center'} ai={'center'} m={'8px 0 0 0'}>
                        <CheckIcon sx={{color: colors.text.success, margin: !revDir ? '0 8px 0 0': '0 0 0 8px'}} />
                        <Typography color={colors.text.success} fontSize={'15px'} fontWeight={600}>{t('INSTALLED')}</Typography>
                    </Flex>
                </>
            }
        </S.IntegrationCard>
    )
}