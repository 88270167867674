import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_INTEGRATIONS} from "../../../../../newShared/constants";
import {
    DEFAULT_APP_HISTORY_PAGING,
    DEFAULT_APP_LOGS_PAGING,
    DEFAULT_RAW_DATA_PAGING,
    PATH_INTEGRATIONS_ADDED,
    PATH_INTEGRATIONS_ADDED_EXACT
} from "../../constants";
import {
    deselectApplication,
    deselectCollectionObject,
    deselectLog,
    deselectLogFetchResult,
    eraseCollectionDataBeforeFetch,
    loadings,
    logsData,
    openDeleteIntegrationDialog,
    selectedApp,
    setSelectedCollectionObject
} from "../../store/slice";
import {
    DownloadLog,
    GetAppHistory,
    GetAppLogs,
    GetDataByCollection,
    GetIntegrationAppById,
    StartResearch
} from "../../store/actions";
import {exactAppTabsType} from "../../types";
import {getNormalizedStatus} from "../../helpers";
import {useForm} from "../../../../../newShared/hooks/useForm";
// @ts-ignore
import JsonToYaml from "json-to-pretty-yaml";
import {addInfoSnack} from "../../../../barsEnvironment/snack/store/slice";

export const useExactApp = () => {
    //root
    const dispatch = useDispatch();
    const history = useHistory();
    const id = useParams<{id: string}>().id;
    //selectors
    const {
        app,
        details,
        collectionData,
        collectionDataPageInfo,
        appHistory,
        historyPageInfo,
        selectedCollectionObject,

    } = useSelector(selectedApp);
    const {getInstalledAppById, getDataByCollection, getHistory, logsList, logsExact, logsDownload} = useSelector(loadings);
    const {logs, selectedLog, pageInfo, selectedLogResponse} = useSelector(logsData);

    const controller = new AbortController();

    //bread
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    useSetBreadcrumbs([
        {
            title: tMenu('Integrations'),
            path: PATH_LOCAL_INTEGRATIONS
        },
        {
            title: tMenu('Installed integrations'),
            path: PATH_INTEGRATIONS_ADDED
        },
        {
            title: details?.name ?? tMenu('Loading...'),
            path: PATH_INTEGRATIONS_ADDED_EXACT.replace(':id', id)
        }
    ]);

    useEffect(() => {
        if(details && app && app.config && app.config.collectionConfigs){
            //user came from list page, app in store - fetching collection data
            try{
                const firstCollectionName = app.config.collectionConfigs[0]?.alias;
                if(firstCollectionName){
                    setCollectionTab(firstCollectionName);
                    dispatch(GetDataByCollection({data: {
                            appId: id,
                            collectionAlias: firstCollectionName,
                            page: 0,
                            count: DEFAULT_RAW_DATA_PAGING,
                            search: ''
                        },
                        signal: controller.signal
                    }));
                }
            }catch (ex){
                //no collections found
            }
        }else{
            //page inits here - fetching app by id
            dispatch(GetIntegrationAppById({data: {
                    appId: id,
                    onReject: handleGoBack
                },
                signal: controller.signal
            }))
        }


        return () => {
            controller.abort();
            dispatch(deselectApplication());
        }
        //eslint-disable-next-line
    }, []); 

    useEffect(() => {
        if(!getInstalledAppById && details && app && app.config && app.config.collectionConfigs){
            //fetching collection data
            try{
                const firstCollectionName = app.config.collectionConfigs[0]?.alias;
                if(firstCollectionName){
                    setCollectionTab(firstCollectionName);
                    dispatch(GetDataByCollection({data: {
                            appId: id,
                            collectionAlias: firstCollectionName,
                            page: 0,
                            count: DEFAULT_RAW_DATA_PAGING,
                            search: ''
                        },
                        signal: controller.signal,
                    }));
                }
            }catch (ex){
                //no collections found
            }
        }

        return () => {
            dispatch(eraseCollectionDataBeforeFetch());
        }
        //eslint-disable-next-line
    }, [getInstalledAppById]);

    //actions
    const handleGoBack = () => {
        dispatch(eraseCollectionDataBeforeFetch());
        history.push(PATH_INTEGRATIONS_ADDED);
    }

    const handleDeleteIntegration = () => {
        dispatch(openDeleteIntegrationDialog());
    }

    //TAB
    const [tab, setTab] = useState<exactAppTabsType>('data');

    const handleSetTab = (ttab: exactAppTabsType) => {
        //actions have too be fired when ever going to any tab
        dispatch(deselectLogFetchResult());
        dispatch(deselectLog());
        if(ttab === 'history'){
            // !appHistory.length && dispatch(GetAppHistory({data: {
            dispatch(GetAppHistory({data: {
                    appId: id,
                    page: 0,
                    count: DEFAULT_APP_HISTORY_PAGING
                },
                signal: controller.signal
            }));
        }
        if(ttab === 'logs'){
            // !logs.length && dispatch(GetAppLogs({data: {
            dispatch(GetAppLogs({data: {
                appId: id,
                    page: 0,
                    count: DEFAULT_APP_LOGS_PAGING
                }}));
        }
        setTab(ttab);
    }

    //OVERVIEW
    const [overview, setOverview] = useState<boolean>(false);

    //DATA
    const [collectionsTab, setCollectionTab] = useState<string>('');

    const handleSetCollectionTab = (ttab: string) => {
        if(getDataByCollection) return; // if loading - doing nothing
        dispatch(eraseCollectionDataBeforeFetch());
        setForm({...form, search: '', prevSearch: ''})
        setCollectionTab(ttab);
        dispatch(GetDataByCollection({data: {
                appId: id,
                collectionAlias: ttab,
                page: 0,
                count: DEFAULT_RAW_DATA_PAGING,
                search: ''
            },
            signal: controller.signal
        }));
    }

    const {form, setForm, handleChange} = useForm<{search: string, prevSearch: string}>({search: '', prevSearch: ''});

    const handleCleanSearch = () => {
        setForm({search: '', prevSearch: ''});
        dispatch(eraseCollectionDataBeforeFetch());
        dispatch(GetDataByCollection({data: {
                appId: id,
                collectionAlias: collectionsTab,
                page: 0,
                count: DEFAULT_RAW_DATA_PAGING,
                search: ''
            },
            signal: controller.signal
        }));
    }

    const handleEnterKey = (e: any) => {
        if(e.keyCode === 13 && !form.search.trim().length){
            handleCleanSearch();
        }

        if(e.keyCode === 13 && form.search.trim().length > 0){
            setForm({...form, prevSearch: form.search});
            dispatch(eraseCollectionDataBeforeFetch());
            dispatch(GetDataByCollection({data: {
                    appId: id,
                    collectionAlias: collectionsTab,
                    page: 0,
                    count: DEFAULT_RAW_DATA_PAGING,
                    search: form.search.trim()
                },
                signal: controller.signal
            }));
        }
    }

    const handleLoadMore = (page: number) => {
        !getDataByCollection && dispatch(GetDataByCollection({data: {
                appId: id,
                collectionAlias: collectionsTab,
                page: collectionDataPageInfo.page + 1,
                count: DEFAULT_RAW_DATA_PAGING,
                search: form.search.trim()
            },
            signal: controller.signal
        }));
    }

    //HISTORY
    const handleLoadMoreHistory = (page: number) => {
        !getHistory && dispatch(GetAppHistory({data: {
                appId: id,
                page: historyPageInfo.page + 1,
                count: DEFAULT_RAW_DATA_PAGING,
            },
            signal: controller.signal,

        }));
    }


    const godMode = () => {
        dispatch(addInfoSnack('God mode!'))
    };

    //logs
    const handleLoadMoreLogs = (page: number) => {
        !logsList && dispatch(GetAppLogs({data: {
                appId: id,
                page: 0,
                count: DEFAULT_APP_LOGS_PAGING
            }, signal: controller.signal}));
    }

    return{
        isLoading: getInstalledAppById,
        installed: details,
        app,
        tab,
        overview,
        normalizedStatus: getNormalizedStatus(details?.status ?? ''),
        actions: {
            handleSetTab,
            handleGoBack,
            handleDeleteIntegration,
            toggleOverview: () => setOverview(!overview),
            handleRefresh: () => details && app && dispatch(StartResearch({appId: details.applicationId, endpointMappingName: app.endpointMappingName})),
        },
        data: {
            // selectedCollection,
            collectionData,
            collectionDataPageInfo,
            isLoading: getDataByCollection,
            collectionsTab,
            handleSetCollectionTab,
            search: form.search,
            handleChange,
            handleCleanSearch,
            handleEnterKey,
            selectedCollectionObject,
            selectedCollectionObjectInYaml: JsonToYaml.stringify(selectedCollectionObject),
            currentCollectionConfig: app?.config?.collectionConfigs.find(e => e.alias === collectionsTab),
            setSelectedCollectionObject: (obj: {[key: string]: any}) => dispatch(setSelectedCollectionObject(obj)),
            deselectCollectionObject: () => dispatch(deselectCollectionObject()),
            handleLoadMore,
            hasMore: collectionDataPageInfo.total > ((collectionDataPageInfo.page + 1) * collectionDataPageInfo.count),
        },
        history: {
            appHistory,
            historyPageInfo,
            handleLoadMoreHistory,
            hasMore: historyPageInfo.total > ((historyPageInfo.page + 1) * historyPageInfo.count),
            isLoading: getHistory
        },
        godMode,
        logsData: {
            isLoadingList: logsList,
            isLoadingExact: logsExact,
            isLoadingDownload: logsDownload,
            logs,
            selectedLog,
            hasMore: pageInfo.total > ((pageInfo.page + 1) * pageInfo.count),
            handleLoadMoreLogs,
            deselectLog: () => dispatch(deselectLog()),
            handleDownloadLog: () => {
                if(selectedLog && details){
                    dispatch(DownloadLog({fileId: selectedLog.fileId, fileName: `${details.name}-${selectedLog.timestamp}-log.txt`}))
                }
            },
            logResponse: selectedLogResponse
        }
    }
}