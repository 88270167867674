import * as yup from 'yup';
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideSkipRequestDialog, loadings} from "../../../store/slice";
import {useHistory, useParams} from "react-router-dom";
import {
    mainHrGetAndChangeStepStatusTimeOffRequestAction,
    mainHrGetTimeOffRequestsWithFilterPaginationAction,
    mainHrSkipTimeOffRequestAction
} from "../../../store/actions";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {incomingRequestsSelector} from "../../../../physicalAssets/store/store";
import {
    useGenericFiltersStorage
} from "../../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {PATH_LOCAL_TIME_OFF_REQUESTS} from "../../../constants";
import {useLocation} from "react-router";
import {HrTimeOffStatus} from "../../../../../../newShared/GQLTypes";
import {GENERIC_FILTER_OPTIONS_DEFAULT_PAGING} from "../../../../../../newShared/components/genericFilter/constants";
import {useCustomFormik} from "../../../../../../newShared/hooks/useCustomFormik";

export const useSkipTimeOffRequest = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathHRManagement.pathTimeOff'})
    const {timeoffId} = useParams<{timeoffId?: string}>();
    const {timeOffSkip: isLoadingSkip} = useSelector(loadings);
    const {skipRequest: {isOpen}} = useSelector(dialogs);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const {setMessage} = useMessageDialog();

    const handleClose = () => {
        dispatch(hideSkipRequestDialog());
    }

    const {pageInfo: {count}} = useSelector(incomingRequestsSelector);
    const {currentFiltersForFetch, currentSearchForFetch, getCurrentPage} = useGenericFiltersStorage();
    const refreshTable = () => {
        dispatch(mainHrGetTimeOffRequestsWithFilterPaginationAction({data: {pageRequest: {page: getCurrentPage(), count: count || GENERIC_FILTER_OPTIONS_DEFAULT_PAGING}, filter: {...currentFiltersForFetch, timeOffIdLike: currentSearchForFetch}}, clean: true}));
    }

    const handleCloseView = () => {history.push(PATH_LOCAL_TIME_OFF_REQUESTS + location.search)};
    const getTimeOffRequestById = (timeoffId: string) => {
        dispatch(mainHrGetAndChangeStepStatusTimeOffRequestAction({
            data: {requestId: timeoffId},
            onError: (request, error, addictiveData) => {
                setTimeout(() => {handleCloseView()}, 300)
                refreshTable();
            },
            onSuccess: (request, response, addictiveData) => {
                if (response.status === HrTimeOffStatus.Cancelled) {
                    setTimeout(() => {handleCloseView()}, 300)
                    refreshTable();
                }
            },
        }))
    }

    const handleSkip = (id: string) => {
        timeoffId && dispatch(mainHrSkipTimeOffRequestAction({
            data: {requestId: id, comment: formik.values.comment},
            onError: (request, error, addictiveData) => {
                const errors409 = error.e409;
                if (errors409?.length) {
                    getTimeOffRequestById(timeoffId);
                }
            },
            onSuccess: (request, response, addictiveData) => {
                setMessage({
                    title: t('Completed successfully'),
                    message: [
                        t('The manager approval step for time-off request with id {{timeoffId}} has been successfully skipped.', {timeoffId})
                    ]
                });
                getTimeOffRequestById(timeoffId);
                handleClose();
            }
        }))
    }

    const validationSchema = yup.object({
        comment: yup.string()
            .nullable()
            .required(t('Comment field is required'))
            .max(1000, t('Comment should be less than 1000 symbols'))
    });

    const formik = useCustomFormik(isOpen, {
        initialValues: {comment: ''},
        validationSchema,
        onSubmit: (values, formikHelpers) => {
            //timeoffId && handleApproveRequest(timeoffId)
        },
    })

    return {
        handleClose,
        isOpen,
        formik,
        handleSkip,
        isLoadingSkip,
        timeoffId
    }
}
