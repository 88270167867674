import {useCreateWorkspaceDialog} from "../../../../../hooks/organizations/dialogs/useCreateWorkspaceDialog";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import colors from "../../../../../../../../newShared/theme/colors";
import {DialogContentWrapper, TextFieldCustom} from "../../../../styled";
import React from "react";
import {LoadingButton} from "../../../../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {organizationTabs} from "../../../../../types";

export const CreateWorkspace = (props: {handleChangeTab: (tab: organizationTabs) => void}) => {
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'pathSettings.dialogs'});
    const {
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        name,
        handleChange,
        isAvailableAdd,
        isOk,
        handleGoToPlans
    } = useCreateWorkspaceDialog(props);

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Add new workspace')}</DialogTitle>
            <DialogContent>
                {!isAvailableAdd &&
                    <DialogContentText>
                        <Typography variant={'body2'}>{t('You can not add new workspaces because you have reached the maximum workspaces amount.')}</Typography>
                        <Typography
                            variant={'body2'}
                            color={colors.primary.blue}
                            sx={{cursor: 'pointer'}}
                            onClick={handleGoToPlans}
                        >{t('You can upgrade your Plan here.')}</Typography>
                    </DialogContentText>
                }
                <DialogContentWrapper>
                    <TextFieldCustom
                        required
                        value={name}
                        name={'name'}
                        onChange={handleChange}
                        placeholder={t('Name')}
                        label={t('Workspace name')}
                        size={'small'}
                        sx={{margin: '8px 0 0 0'}}
                    />
                </DialogContentWrapper>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                    disabled={!isOk}
                >
                    {t('Add')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
