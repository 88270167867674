import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import ReactMarkdown from "react-markdown";
import styles from "../../../../frameworks/pages/controlView/styles.module.css";
import {useStepDescriptionDialog} from "../../../hooks/dialogs/useStepDescriptionDialog";

export const VicaStepDescriptionViewDialog = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: ''})

    const {
        isOpen, step,
        handleClose
    } = useStepDescriptionDialog();

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>
                {(step?.instruction || step?.description) ? t('Step Description') : t('Description does not exists')}
            </DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                {step?.instruction || step?.description ?
                    <>
                        <Typography variant={'*bodyText1_semibold'}>
                            {step.name}
                        </Typography>

                        {step?.instruction ? (
                            <ReactMarkdown className={styles.FrameworkControlDescription} key={step.instruction}>
                                {step.instruction}
                            </ReactMarkdown>
                        ) : step?.description ? (
                                <ReactMarkdown className={styles.FrameworkControlDescription} key={step.instruction}>
                                    {step.description}
                                </ReactMarkdown>
                            ) : null
                        }
                    </> :
                    <Typography>
                        {t('Opps :( We are working on this and will add a description  for this step as quickly as possible')}
                    </Typography>
                }
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{tCommon('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}