import {useDeclineInviteDialog} from "../../../../hooks/invites/useDeclineInviteDialog";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import colors from "../../../../../../../newShared/theme/colors";
import {LoadingButton} from "@mui/lab";
import React from "react";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../../../newShared/hooks/useMedia";

export const DeclineInvite = () => {
    const {
        handleClose,
        handleDeclineInvite,
        isLoading,
        isOpen,
        invite
    } = useDeclineInviteDialog();
    const {isMobile} = useMedia();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathSettings.Invites'});

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Decline invite')}</DialogTitle>

            <DialogContent>
                <Typography variant={'body2'} color={colors.darkBlueText}>
                    {t(`Are you sure you want to decline invite from {{firstName}} {{lastName}}?`, {firstName: invite?.sender.firstName, lastName: invite?.sender.lastName})}
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{t('Cancel')}</Button>
                {isLoading ?
                    <LoadingButton
                        loading
                        sx={{paddingLeft: '35px', margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                        variant="outlined"
                    >{t('Decline')}</LoadingButton>
                    :
                    <Button
                        variant={'contained'}
                        size={isMobile ? 'small' : 'medium'}
                        onClick={handleDeclineInvite}
                        sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    >{t('Decline')}</Button>
                }
            </DialogActions>
        </Dialog>
    )
}