import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import {useResolveIssue} from "../../../hooks/dialogHooks/useResolveIssue";
import React from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import colors from "../../../../../../newShared/theme/colors";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";


export const ResolveIssueDialog = () => {
    const {t, tCommon, revDir} = useMainTranslation('', {keyPrefix: 'pathHRManagement.dialogs'});

    const {
        isOpen,
        isLoading,
        resolution,
        handleSetResolution,
        handleSave,
        handleClose
    } = useResolveIssue();


    return (
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{t('Resolve issue')}</DialogTitle>
            <DialogContent sx={{display: 'flex', flexDirection: 'column'}}>
                <Typography variant={'body2'}>{t('To set issue “Resolved” please write the resolution below. This resolution will be visible also for the reporter.')}</Typography>
                <Typography variant={'*bodyText2_semibold'} sx={{mt: '16px'}}>{t('Resolution')}<span style={{color: colors.text.critical}}>*</span></Typography>
                <TextField placeholder={`${t('Type here')}...`} value={resolution} multiline minRows={3} onChange={handleSetResolution} sx={{mt: '6px'}} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{tCommon('Cancel')}</Button>
                <LoadingButton size={'small'} sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    onClick={handleSave} loading={isLoading} disabled={!resolution.trim().length}>
                    {tCommon('Save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
