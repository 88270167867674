import {PATH_ARI, PATH_LOCAL_INTEGRATIONS} from "../../../newShared/constants";
import {addAppVendorManage} from "./types";

export const PATH_INTEGRATIONS_ADDED = `${PATH_LOCAL_INTEGRATIONS}/added`;
export const PATH_INTEGRATIONS_AVAILABLE = `${PATH_LOCAL_INTEGRATIONS}/available`;
export const PATH_INTEGRATIONS_ADDED_EXACT = `${PATH_INTEGRATIONS_ADDED}/:id`;

export const DEFAULT_RAW_DATA_PAGING = 50;
export const DEFAULT_APP_HISTORY_PAGING = 30;
export const DEFAULT_APP_LOGS_PAGING = 30;
export const APP_HISTORY_DATE_FORMAT= 'D/M/YYYY';

export const integrationGodModeKeys: string[] = ["u", "z", "u", "m", "y", "m", "w"];
export const IN_PROGRESS_STATUS = 'IN_PROGRESS';
export const resetEggMs = 7000;
//endpointMappingNames
export const GWORKSPACE_ENDPOINT_MAPPING_NAME = 'gworkspace';
export const GITHUB_ENDPOINT_MAPPING_NAME = 'github';
export const CONFLUENCE_ENDPOINT_MAPPING_NAME = 'confluence';
export const JIRA_ENDPOINT_MAPPING_NAME = 'jira';
export const AWS_SECURITY_ENDPOINT_MAPPING_NAME = 'awssecurityhub';
export const AWS_EC2_ENDPOINT_MAPPING_NAME = 'awsec2';
export const AWS_IAM_ENDPOINT_MAPPING_NAME = 'awsiam';
export const AWS_CONFIG_ENDPOINT_MAPPING_NAME = 'aws_config';
export const AWS_RDS_ENDPOINT_MAPPING_NAME = 'awsrds';
export const AWS_KMS_ENDPOINT_MAPPING_NAME = 'awskms';
export const AWS_S3_ENDPOINT_MAPPING_NAME = 'awss3';
export const AWS_CLOUD_TRAIL_ENDPOINT_MAPPING_NAME = 'awscloudtrail';
export const AZUREAD_ENDPOINT_MAPPING_NAME = 'azuread';
export const INTUNE_ENDPOINT_MAPPING_NAME = 'intune';
export const BAMBOOHR_ENDPOINT_MAPPING_NAME = 'bamboohr';
export const ZENDESK_ENDPOINT_MAPPING_NAME = 'zendesk';
export const ONELOGIN_ENDPOINT_MAPPING_NAME = 'onelogin';
export const CLOUDFLARE_ENDPOINT_MAPPING_NAME = 'cloudflare';
export const SALESFORCE_ENDPOINT_MAPPING_NAME = 'salesforce';

export type ENDPOINT_MAPPING_NAMES_TYPE =
    typeof GWORKSPACE_ENDPOINT_MAPPING_NAME
    | typeof GITHUB_ENDPOINT_MAPPING_NAME
    | typeof CONFLUENCE_ENDPOINT_MAPPING_NAME
    | typeof JIRA_ENDPOINT_MAPPING_NAME
    | typeof AWS_SECURITY_ENDPOINT_MAPPING_NAME
    | typeof AWS_EC2_ENDPOINT_MAPPING_NAME
    | typeof AWS_S3_ENDPOINT_MAPPING_NAME
    | typeof AWS_RDS_ENDPOINT_MAPPING_NAME
    | typeof AWS_KMS_ENDPOINT_MAPPING_NAME
    | typeof AWS_CLOUD_TRAIL_ENDPOINT_MAPPING_NAME
    | typeof AWS_IAM_ENDPOINT_MAPPING_NAME
    | typeof AWS_CONFIG_ENDPOINT_MAPPING_NAME
    | typeof AZUREAD_ENDPOINT_MAPPING_NAME
    | typeof INTUNE_ENDPOINT_MAPPING_NAME
    | typeof BAMBOOHR_ENDPOINT_MAPPING_NAME
    | typeof ZENDESK_ENDPOINT_MAPPING_NAME
    | typeof ONELOGIN_ENDPOINT_MAPPING_NAME
    | typeof CLOUDFLARE_ENDPOINT_MAPPING_NAME
    | typeof SALESFORCE_ENDPOINT_MAPPING_NAME
;

export const MAX_LOG_SIZE_PREVIEW = 10000000;
export const LOG_DATE_FORMAT = 'D/M/YYYY hh:mm';

export const GITHUB_URL = 'https://github.com/login/oauth/authorize';
export const ATLASSIAN_URL = 'https://auth.atlassian.com/authorize';
export const ZENDESK_URL = 'https://regulait.zendesk.com/oauth/authorizations/new';
export const ONELOGIN_URL = 'https://regulait.onelogin.com/oidc/2/auth';
export const SALESFORCE_URL = 'https://login.salesforce.com/services/oauth2/authorize';

export const GITHUB_CLIENTID = window.config?.REACT_APP_GITHUB_CLIENTID || '';
export const GITHUB_SCOPE = 'read:user repo read:repo_hook read:gpg_key read:org read:packages read:public_key notifications read:discussion read:enterprise admin:org';
export const GITHUB_REDIRECT_URI = `${PATH_ARI}${PATH_INTEGRATIONS_AVAILABLE}/${GITHUB_ENDPOINT_MAPPING_NAME}/callback`;

export const CONFLUENCE_CLIENTID = window.config?.REACT_APP_CONFLUENCE_CLIENTID || "";
// export const CONFLUENCE_SCOPE = "read:jira-work manage:jira-project manage:jira-configuration read:jira-user write:jira-work read:confluence-props read:confluence-content.all read:confluence-content.summary search:confluence read:confluence-content.permission read:confluence-user read:confluence-groups readonly:content.attachment:confluence offline_access";
export const CONFLUENCE_SCOPE = "read:confluence-space.summary read:confluence-props read:confluence-content.all read:confluence-content.summary search:confluence read:confluence-content.permission read:confluence-user read:confluence-groups readonly:content.attachment:confluence offline_access";
export const CONFLUENCE_REDIRECT_URI = `${PATH_ARI}${PATH_INTEGRATIONS_AVAILABLE}/${CONFLUENCE_ENDPOINT_MAPPING_NAME}/callback`;

export const JIRA_CLIENTID = window.config?.REACT_APP_JIRA_CLIENTID || '';
export const JIRA_SCOPE = "read:jira-work manage:jira-project manage:jira-configuration read:jira-user write:jira-work offline_access";
export const JIRA_REDIRECT_URI = `${PATH_ARI}${PATH_INTEGRATIONS_AVAILABLE}/${JIRA_ENDPOINT_MAPPING_NAME}/callback`;

export const ZENDESK_CLIENTID = window.config?.REACT_APP_ZENDESK_CLIENTID || "";
export const ZENDESK_SCOPE = "read";
export const ZENDESK_REDIRECT_URI = `${PATH_ARI}${PATH_INTEGRATIONS_AVAILABLE}`;

// export const ONELOGIN_CLIENTID = window.config.REACT_APP_ONELOGIN_CLIENTID || "f207e510-89e7-013b-4bcc-0a60ff4d4ebf222847";
// export const ONELOGIN_SCOPE = "openid name groups email profile email params params";
// export const ONELOGIN_REDIRECT_URI = `${PATH_ARI}${PATH_INTEGRATIONS_AVAILABLE}`;

export const SALESFORCE_CLIENTID = window.config?.REACT_APP_SALESFORCE_CLIENTID || '';
export const SALESFORCE_SCOPE = "api full refresh_token offline_access";
export const SALESFORCE_REDIRECT_URI = `${PATH_ARI}${PATH_INTEGRATIONS_AVAILABLE}/${SALESFORCE_ENDPOINT_MAPPING_NAME}/callback`;

// // export const MONDAY = "monday";
// export const GSUITE = "gworkspace";
// export const GDRIVE = "gdrive";

export const initialVendorForm: addAppVendorManage = {
    create: {
        owner: null,
        type: null,
        providedService: null,
        riskLevel: null
    },
    selectedVendor: null
}
