import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideTemplateSendToClient, loadings} from "../../../store/slice";
import {Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {validateEmail} from "../../../../../../newShared/utils/text";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {SendTemplateToClient} from "../../../store/actions";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const SendToClientDialog = () => {
    const dispatch = useDispatch();
    const {setMessage} = useMessageDialog();
    const {isOpen, template} = useSelector(dialogs).templateSendToClient;
    const isLoading = useSelector(loadings).sendToClient;
    const {t} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.dialogs'});


    useEffect(() => {
        if(!isOpen){
            setEmails([]);
        }
    }, [isOpen]);

    const [emails, setEmails] = useState<string[]>([]);
    const {form, handleChange, setValue} = useForm<{input: string}>({input: ''});

    const handleDeleteEmail = (email: string) => {
        setEmails(emails.filter(e => e !== email));
    }

    const handleAddEmail = () => {
        if (isOkAdd) {
            setEmails([...emails, form.input.trim().toLowerCase()]);
            setValue('input', '');
        }
    }

    const handleClose = () => {
        dispatch(hideTemplateSendToClient());
    }

    const handleSubmit = () => {
        if(template){
            dispatch(SendTemplateToClient({templateId: template.id, emails, onSuccess}));
        }
    }

    const onSuccess = () => {
        setMessage({title: t('Completed successfully!'), message: t('We have sent email request for mentioned emails.')})
    }

    const isOkGlobal = emails.length > 0 && !emails.some(e => !validateEmail(e))
    const isOkAdd = validateEmail(form.input) && !emails.includes(form.input.trim());

    return(
        <>
            {template &&
                <Dialog open={isOpen} onClose={handleClose}>
                    <DialogTitle>{t('Send KYC verification form')}</DialogTitle>

                    <DialogContent>
                        <Typography>{t('Please type client’s personal email. You can send the form to several recipients, enter email and click add.')}</Typography>

                        <Flex ai={'center'} gap={'16px'} m={'16px 0 0 0'}>
                            <TextField
                                name={'input'}
                                value={form.input}
                                onChange={handleChange}
                                title={'Email'}
                                placeholder={'example@email.com'}
                                size={'small'}
                            />
                            <Button onClick={handleAddEmail} disabled={!isOkAdd}>{('Add')}</Button>
                        </Flex>

                        <Flex w={'100%'} wrap={'wrap'} m={'16px 0 0 0'}>
                            {emails.map(email => (
                                <Chip
                                    sx={{margin: '4px', borderRadius: '10px', backgroundColor: '#F7F7F7'}}
                                    key={email}
                                    tabIndex={-1}
                                    label={email}
                                    onDelete={() => {handleDeleteEmail(email)}}
                                />
                            ))}
                        </Flex>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose} variant={'text'} size={'medium'}>{t('Cancel')}</Button>
                        <LoadingButton onClick={handleSubmit} size={'medium'} disabled={!isOkGlobal} loading={isLoading}>{t('Send')}</LoadingButton>
                    </DialogActions>
                </Dialog>
            }
        </>
    )
}