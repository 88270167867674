import React, {FC} from "react";
import {
    Box,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {TableRowSkeleton} from "../../../../../newShared/components/tableRowSkeleton";
// import {RemoveFrameworkDialog} from "../../components/dialogs/removeFrameworkDialog";
import {PageWrapper} from "../../../../../newShared/components/Layouts";
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import EditIcon from "@mui/icons-material/Edit";
import {useControlsList} from "../../hooks/useControlsList";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {AddCustomControlDialog} from "../../components/dialogs/controls/addCustomControl";
import {EditControlDialog} from "../../components/dialogs/controls/editControlDialog";
import {DeleteControlDialog} from "../../components/dialogs/controls/deleteControlDialog";
import {useRoutes} from "../../hooks/new/useRoutes";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import colors from "../../../../../newShared/theme/colors";
import {TableCardSkeleton} from "../../../../../newShared/components/tableCardSkeleton";
import {GenericFiltersArray} from "../../../../../newShared/components/genericFilter";


export const ControlsList: FC = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'builderPage'});
    const {isLessThen1050} = useMedia();

    const {
        controls,

        isLoading,

        handleOpenAddControl,
        openEditControlDialog,
        handleOpenDeleteControl,
        currentSearchForFetch
    } = useControlsList();

    const {goToControl} = useRoutes();

    return (
        <PageWrapper>

            <PageTitleAndActions
                title={t('Created controls')}
                actions={[
                    {
                        title: t('Create control'),
                        onClick: handleOpenAddControl,
                        variant: 'text',
                        icon: <LibraryAddOutlinedIcon style={{marginLeft: revDir ? '9px' : undefined}}/>,
                    }
                ]}
            />

            <GenericFiltersArray
                configs={[]}
                fetchResultWithSelectedFilters={() => null}
                isAddFilters={false}
            />

            {/*<Breadcrumbs/>*/}
            {/*<Box display={"flex"} alignItems={"center"} marginTop={'12px'}>*/}
            {/*    <Typography variant={!matches_1440Down ? "h1" : 'h3'} margin={!revDir ? '0 32px 0 8px' : '0 8px 0 32px'}>{t('Created controls')}</Typography>*/}
            {/*    <Button sx={{margin: '0 8px'}} variant={"text"} startIcon={<LibraryAddOutlinedIcon style={{marginLeft: revDir ? '9px' : undefined}}/>} size={matches_1440Down ? 'medium' : undefined}*/}
            {/*            onClick={handleOpenAddControl}>*/}
            {/*        {t('Create control')}*/}
            {/*    </Button>*/}
            {/*</Box>*/}

            {/*<SearchField onClear={() => {setFilter('input'); handleChange({target: {value: ''}})}}*/}
            {/*             value={searchValue} onKeyDown={handleKeyDown} onChange={handleChange} disabled={isLoading}*/}
            {/*             sx={{mt: '16px', mb: isLessThen1050 ? '16px' : undefined, maxWidth: !isMobile ? '320px' : undefined}}/>*/}

            <TableContainer>
                <Table stickyHeader>
                    {isLessThen1050 ?
                        <TableBody>
                            { controls
                                .filter(e =>
                                    e?.name?.trim().toLowerCase().includes(currentSearchForFetch.trim().toLowerCase() || '')
                                    || e?.category?.trim().toLowerCase().includes(currentSearchForFetch.trim().toLowerCase() || '')
                                )
                                .map((control) => control && (
                                    <TableRow key={control.id} hover onClick={() => goToControl(control.id)}>
                                        <TableCell style={{ padding: 0, borderBottom: 'none' }}>
                                            <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} sx={{borderRadius: '8px', m: '6px', p: '8px', boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)'}}>
                                                <Typography variant={'body2'}>{control.name}</Typography>
                                                <Box display={'flex'} >
                                                    <Typography variant={'body2'} sx={{color: colors.lightGrayText, whiteSpace: 'nowrap'}}>{t('Control category')}</Typography>
                                                    <Typography variant={'body2'} sx={{color: colors.darkText, ml: '6px', mr: '6px'}}>{control.category || ''}</Typography>
                                                </Box>
                                                <Box display={'flex'} >
                                                    <Typography variant={'body2'} sx={{color: colors.lightGrayText, whiteSpace: 'nowrap'}}>{t('Number of evidence')}</Typography>
                                                    <Typography variant={'body2'} sx={{color: colors.darkText, ml: '6px', mr: '6px'}}>{control?.evidences?.length || '0'}</Typography>
                                                </Box>
                                                <Box display={'flex'} >
                                                    <Typography variant={'body2'} sx={{color: colors.lightGrayText, whiteSpace: 'nowrap'}}>{t('Attached frameworks')}</Typography>
                                                    <Typography variant={'body2'} sx={{color: colors.darkText, ml: '6px', mr: '6px'}}>{control?.foundInFrameworksCounter}</Typography>
                                                </Box>
                                                <Box display={"flex"} alignSelf={"flex-end"}>
                                                    <Button size={'small'} variant={'text'} startIcon={<EditIcon/>} sx={{textTransform: 'none', ml: '6px', mr: '6px'}} onClick={(e) => control?.id && openEditControlDialog(e, control.id)} >{t('Edit')}</Button>
                                                    <Button size={'small'} variant={'text'} startIcon={<DeleteForeverIcon/>} sx={{textTransform: 'none'}} onClick={(e) => control?.id && handleOpenDeleteControl(control.id, e)} >{t('Delete')}</Button>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }

                            {isLoading && <TableCardSkeleton rows={4} />}
                        </TableBody>
                        :
                        <>
                            <TableHead>
                                <TableRow>
                                    <TableCell align={revDir ? "right" : 'left'}>{t('Name')}</TableCell>
                                    <TableCell align={revDir ? "right" : 'left'}>{t('Control category')}</TableCell>
                                    <TableCell align={revDir ? "right" : 'left'}>{t('Number of evidence')}</TableCell>
                                    <TableCell align={revDir ? "right" : 'left'}>{t('Attached frameworks')}</TableCell>
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {controls
                                    .filter(e =>
                                        e?.name?.trim().toLowerCase().includes(currentSearchForFetch.trim().toLowerCase() || '')
                                        || e?.category?.trim().toLowerCase().includes(currentSearchForFetch.trim().toLowerCase() || '')
                                    )
                                    .map((control) => control && (
                                        <TableRow key={control.id} hover onClick={() => goToControl(control.id)}>
                                            <TableCell align={revDir ? "right" : 'left'}>{control.name}</TableCell>
                                            <TableCell align={revDir ? "right" : 'left'}>{control.category || ''}</TableCell>
                                            <TableCell align={revDir ? "right" : 'left'}>{control.evidences?.length || 0}</TableCell>
                                            <TableCell align={revDir ? "right" : 'left'}>{control.foundInFrameworksCounter}</TableCell>
                                            <TableCell sx={{padding: '0'}}>
                                                <Box display={"flex"} alignItems={"center"}>
                                                    <IconButton size={"medium"} onClick={(e) => control?.id && openEditControlDialog(e, control.id)} >
                                                        <EditIcon/>
                                                    </IconButton>
                                                    <IconButton size={"medium"} onClick={(e) => control?.id && handleOpenDeleteControl(control.id, e)}>
                                                        <DeleteForeverIcon/>
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                {isLoading && <TableRowSkeleton columns={5}/>}
                            </TableBody>
                        </>
                    }

                </Table>
            </TableContainer>

            <EditControlDialog/>
            <DeleteControlDialog />
            <AddCustomControlDialog/>
        </PageWrapper>
    )
}