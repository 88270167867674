import {
    DelegatedTaskModel,
    FrameworksDelegatedTaskDataAttachedFilesFileModel,
} from "../../../../../../../../../newShared/GQLTypes";
import {
    useMainTranslation
} from "../../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Icon,
    IconButton,
    Typography
} from "@mui/material";
import colors from "../../../../../../../../../newShared/theme/colors";
import {Flex} from "../../../../../../../../../newShared/components/Layouts";
import Divider from "@mui/material/Divider";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {LoadingButton} from "../../../../../../../../../newShared/components/Basic/CommonButtons";
import {TypographyNew} from "../../../../../../../../../newShared/components/TypographyNew";

export const ApproveTaskDialog = ({task, handlePreviewFile, handleDownloadFile, handleClose, isOpen, handleSubmit, isLoading}: {
    task: DelegatedTaskModel | null,
    isOpen: boolean,
    isLoading: boolean,
    handleClose: () => void,
    handlePreviewFile: (file: FrameworksDelegatedTaskDataAttachedFilesFileModel) => void,
    handleDownloadFile:(file: FrameworksDelegatedTaskDataAttachedFilesFileModel) => void,
    handleSubmit: (files: FrameworksDelegatedTaskDataAttachedFilesFileModel[]) => void,
}) => {
    const {tCommon} = useMainTranslation();
    const [selectedAttachments, setSelectedAttachments] = useState<FrameworksDelegatedTaskDataAttachedFilesFileModel[]>([]);
    const attachments = task?.data?.attachedFiles ?? [];

    useEffect(() => {
        isOpen && setSelectedAttachments(attachments);
        //eslint-disable-next-line
    }, [isOpen]);

    const handleToggleAttachment = (attachment: FrameworksDelegatedTaskDataAttachedFilesFileModel) => {
        if(selectedAttachments.some(e => e.fileId === attachment.fileId)){
            setSelectedAttachments(selectedAttachments.filter(e => e.fileId !== attachment.fileId));
        }else{
            setSelectedAttachments([...selectedAttachments, attachment]);
        }
    }

    const handleToggleSelectAll = () => {
        if(selectedAttachments.length === attachments.length){
            setSelectedAttachments([])
        }else{
            setSelectedAttachments(attachments)
        }
    }

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{(`Delegated task ${task?.id}`)}</DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column'}}>
                <Typography variant={'*bodyText2_semibold'} color={colors.grayText} margin={'0 0 8px 0'}>{('Select files that will be added to the evidence')}</Typography>

                <Flex gap={'10px'} ai={'center'}>
                    <Checkbox checked={selectedAttachments.length === attachments.length}
                              size="small"
                              onClick={handleToggleSelectAll}
                    />
                    <Typography variant={'body2'}>{selectedAttachments.length === attachments.length ? ('Deselect all') : ('Select all')}</Typography>
                </Flex>

                <Divider />

                <Flex direction={'column'} overflow={'auto'} grow={'1'}>
                    {attachments.map((e, id, arr) => {
                        return(
                            <>
                                <Flex key={e.fileId} ai={'center'} jc={'space-between'} w={'100%'} p={'4px 0'}>
                                    <Flex gap={'10px'} ai={'center'} overflow={'hidden'}>
                                        <Checkbox checked={selectedAttachments.some(s => s.fileId === e.fileId)}
                                                  size="small"
                                                  onClick={() => handleToggleAttachment(e)}
                                        />
                                        <TypographyNew noWrap variant={'body2'}>{e.fileName}</TypographyNew>
                                    </Flex>

                                    <Flex gap={'12px'} ai={'center'} shrink={'0'}>
                                        {e.fileName.endsWith('.pdf') ? (
                                            <IconButton size={'small'} onClick={() => handlePreviewFile(attachments[0])} color={'primary'}>
                                                <VisibilityIcon/>
                                            </IconButton>
                                        ) : (
                                            <Icon/>
                                        )}

                                        <IconButton size={'small'} onClick={() => handleDownloadFile(e)} color={'primary'}>
                                            <FileDownloadIcon />
                                        </IconButton>
                                    </Flex>
                                </Flex>
                                {id !== arr.length - 1 && <Divider />}
                            </>
                        )
                    })}
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button variant={'text'} size={'small'} onClick={handleClose}>{tCommon('Close')}</Button>

                <LoadingButton
                    size={'small'}
                    onClick={() => handleSubmit(selectedAttachments)}
                    disabled={!selectedAttachments.length}
                    loading={isLoading}
                >
                    {('Confirm')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}