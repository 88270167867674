import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import colors from "../../../../../../newShared/theme/colors";
import {LoadingButton} from "@mui/lab";
import React from "react";
import {useSaveRisk} from "../../../hooks/dialogsHooks/useSaveRisk";

export const SaveRisk = ({handleSave}: {handleSave: () => void}) => {
    const {
        isOpen,
        handleClose,
        isLoading,
        handleSubmit,
        handleDiscardChanges
    } = useSaveRisk({handleSave});

    const {revDir } = useMainTranslation('', {keyPrefix: 'risks'});
    const {isMobile} = useMedia();

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{('Save or not to save')}</DialogTitle>

            <DialogContent>
                <Typography variant={'body2'} color={colors.darkBlueText}>
                    {('Save changed you made in this risk?')}
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleDiscardChanges} disabled={isLoading} variant={'text'}>{(`No, thanks`)}</Button>
                {isLoading ?
                    <LoadingButton
                        loading
                        sx={{paddingLeft: '35px', margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                        variant="outlined"
                    >{('Save')}</LoadingButton>
                    :
                    <Button
                        variant={'contained'}
                        size={isMobile ? 'small' : 'medium'}
                        onClick={handleSubmit}
                        sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    >{('Save')}</Button>
                }
            </DialogActions>
        </Dialog>
    )
}