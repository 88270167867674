import React, {forwardRef} from "react";
import {styled, TextField, Tooltip as ToolTipMui, tooltipClasses, TooltipProps} from "@mui/material";
import {TextFieldProps} from "@mui/material/TextField/TextField";
import colors from "../../theme/colors";


export const TextFieldWithHelperPopover = forwardRef<HTMLDivElement, TextFieldProps>(({helperText, ...props}, ref) => {

    return (
        <Tooltip title={helperText || null} open={!!helperText} color={props.error ? colors.text.critical : undefined}>
            <TextField ref={ref} {...props}/>
        </Tooltip>
    )
});

export const Tooltip = styled(({ className, color, ...props }: TooltipProps) => (
    <ToolTipMui {...props} classes={{ popper: className }} />
))(({ theme, color }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: colors.backgrounds.grey_light,
        borderRadius: '6px',
        padding: '8px 16px',
        color: (color || colors.text.dark) + ' !important',
        fontWeight: 400,
        fontSize: '14px',
        marginTop: '4px !important',
    },
}));