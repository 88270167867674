import {useDispatch, useSelector} from "react-redux";
import {currentFilterSelector, erase, handleReplaceStore} from "../../store/slice";
import {CommonFilterType, genericTableSearchType} from "../../../genericTable/types";
import {filterType, genericFilterInitialStateType, keyConfig} from "../../types";
import {getFiltersForFetchByFullObjectFilter} from "../../helpers";
import {urlDecode} from "../../../genericTable/helpers";
import {useHistory} from "react-router";

export const useGenericFiltersStorage = <P extends Object | undefined>() => {
    const currentFilter = useSelector(currentFilterSelector);
    const history = useHistory();
    const dispatch = useDispatch();

    const clearFilters = () => {dispatch(erase());};

    const isFilterEmpty = !Object.entries(currentFilter.filters).some(e => e[1].length > 0); //has any filter filled
    const isEmptySearch = !currentFilter.search.trim().length;

    const injectOriginalSelectedValues = (configs: keyConfig<any>[], fullFilters: CommonFilterType, search: string): CommonFilterType => {
        const initiallyVisibleFilters: filterType[] = configs.filter(e => e.default).map(e => ({key: e.key, name: e.name}));
        let additionalVisibleFiltersBySearch: filterType[] = [];

        Object.entries(fullFilters).forEach((e) => {
            const key = e[0];
            const values = e[1];
            if(!initiallyVisibleFilters.find(s => s.name === key) && values.length > 0){
                const config = configs.find(s => s.key === key);
                if(config){
                    //found filter that is not visible but included in config
                    additionalVisibleFiltersBySearch.push({name: config.name, key: config.key});
                }else{
                    console.error(`injectOriginalSelectedValues: no config found for key ${e}`);
                }
            }
        })

        const filtersForFetch = getFiltersForFetchByFullObjectFilter(configs, fullFilters);

        const req: genericFilterInitialStateType = {
            visibleFilters: [...initiallyVisibleFilters, ...additionalVisibleFiltersBySearch],
            currentFilter: {
                filters: filtersForFetch,
                search,
                originalSelectedValues: fullFilters
            }
        }

        dispatch(handleReplaceStore(req));

        return filtersForFetch;
    }

    return{
        clearFilters,
        isFilterEmpty,
        isEmptySearch,
        currentFiltersForFetch: currentFilter.filters,
        currentSearchForFetch: currentFilter.search,
        isSearchAndFilterEmpty: isFilterEmpty && isEmptySearch,
        originalSelectedValues: currentFilter.originalSelectedValues,
        injectOriginalSelectedValues,
        getAdditionalSearchProps: () => {
            return (JSON.parse(urlDecode(history.location.search.substring(1))) as genericTableSearchType<P>).additionalProps;
        },
        getCurrentPage: () => {
            return (JSON.parse(urlDecode(history.location.search.substring(1))) as genericTableSearchType<P>).page;
        },
        getSearchProps: () => {
            return (JSON.parse(urlDecode(history.location.search.substring(1))) as genericTableSearchType<P>);
        }
    }
}