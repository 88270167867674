import {useAddExistingQuestionDialog} from "../../../hooks/useAddExistingQuestionDialog";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {QuestionsBlock} from '../../questionsBlock';
import {GenericFiltersArray} from "../../../../../../newShared/components/genericFilter";
import {SectionsTableHeader} from "../../sectionsTableHeader";
import {Flex} from "../../../../../../newShared/components/Layouts";
import React from "react";
import {TableRowSkeleton} from "../../../../../../newShared/components/tableRowSkeleton";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {QuestionToConfirmRow} from "../../questionToConfirmRow";
import {EmptyArrayImage} from "../../../../../../newShared/components/EmptyArrayImage";
import {MainTrainingsQuestionsFullDataForImportModel} from "../../../../../../newShared/GQLTypes";

export const AddExistingQuestionDialog = ({onAddQuestions, excludeExamId}: {onAddQuestions: (questions: MainTrainingsQuestionsFullDataForImportModel[]) => void, excludeExamId?: string}) => {
    const {
        handleClose,
        isOpen,
        selectedQuestions,
        shortDataQuestions,
        getTotalQuestions,
        onScroll,
        scrollRef,
        fetchPaging,
        handleConfirm,
        isConfirmButtonDisabled,
        isLoadingConfirm,
        isLoadingQuestions,
        filters,
        isStep1,
        setIsStep1,
        isSearchAndFilterEmpty

    } = useAddExistingQuestionDialog(onAddQuestions, excludeExamId);

    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.pathTrainings'})

    return (
        <Dialog open={isOpen} onClose={handleClose} sx={{
            "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "1200px",
                minHeight: isStep1 ? "500px" : undefined
            },
        }}>
            <DialogTitle>
                {isStep1 ? t('Select question to add to exam') : t('Review and confirm questions')}
            </DialogTitle>
            {isStep1 ?
                <DialogContent sx={{overflow: 'hidden', height: '100%', display: 'flex', flexDirection: 'column'}}>
                    <GenericFiltersArray
                        configs={filters}
                        fetchResultWithSelectedFilters={(search, filters, signal) => fetchPaging(0, 10, filters, search, true)}
                        isAddFilters={false}
                    />
                    {!(shortDataQuestions.length === 0 && !isLoadingQuestions && isSearchAndFilterEmpty) ?
                        <TableContainer onScroll={onScroll} ref={scrollRef} sx={{flexGrow: 1}}>
                            <Table stickyHeader>
                                <SectionsTableHeader isSections={false} totalCount={getTotalQuestions()} selectedCount={selectedQuestions.length}/>
                                <TableBody>
                                    {shortDataQuestions.map(item => <QuestionsBlock questionBlock={item}
                                                                                  key={item.examId}/>)}
                                    {isLoadingQuestions && <TableRowSkeleton columns={4}/>}
                                    {shortDataQuestions.length === 0 && !isLoadingQuestions &&
                                        <TableRow>
                                            <TableCell colSpan={4}>
                                                <Typography variant={'*bodyText2'}>{t('No results were found for your search query.')}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        :<Flex position={'absolute'} top={'0'} left={'0'} w={'100%'} h={'100%'}>
                            <EmptyArrayImage
                                type={'any'}
                                text={t('No questions to display')}
                                secondaryText={('')}
                                isShowing={true}
                                withoutAdd
                            />
                        </Flex>}
                </DialogContent>
                :
                <DialogContent>
                    <Flex gap={'10px'} direction={'column'} ai={'center'} jc={'center'}>
                        {!selectedQuestions.length &&
                            <Typography variant={'*bodyText2'}>{t('You have no questions to add')}</Typography>}
                        {selectedQuestions.map((item) => <QuestionToConfirmRow key={item.questionId} question={item}/>)}
                    </Flex>
                </DialogContent>
            }
            <DialogActions>
                {isStep1 ?
                    <>
                        <Button
                            size={'small'}
                            onClick={handleClose}
                            variant={'text'}
                        >
                            {tCommon('Cancel')}
                        </Button>
                        <Button
                            size={'small'}
                            variant={'contained'}
                            disabled={selectedQuestions.length < 1}
                            onClick={() => setIsStep1(false)}
                        >
                            {t('Review & Confirm')}
                        </Button>
                    </>
                    :
                    <>
                        <Button
                            size={'small'}
                            onClick={() => setIsStep1(true)}
                            variant={'text'}
                        >
                            {t('Back')}
                        </Button>
                        <LoadingButton
                            size={'small'}
                            onClick={handleConfirm}
                            variant={'contained'}
                            loading={isLoadingConfirm}
                            disabled={isConfirmButtonDisabled()}
                        >
                            {t('Confirm')}
                        </LoadingButton>
                    </>
                }
            </DialogActions>
        </Dialog>
    )

}