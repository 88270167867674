import {print} from "graphql";
import {gql} from "@apollo/react-hooks";

export const VendorSettingsFragment = print(gql`
    fragment VendorSettingsFragment on VendorsSettingsModel {
        risk {
            name
            count
        }
        type {
            name
            count
        }
        serviceProvided {
            name
            count
        }
        customFields {
            name
            count
        }
    }
`)

export const DutyFragment = print(gql`
    fragment DutyFragment on DutyModel {
        id
        name
        description
        roles {
            role
            permissions
        }
        category
        application {
            applicationAccessId
            applicationId
            type
            name 
        }
    }
`)

export const DutyRuleFragment = print(gql`
    fragment DutyRuleFragment on DutyRuleModel {
        id
        name
        severityLevel
        securityRisk
        securityMitigation
        active
        firstDuty {
            id
            name
        }
        secondDuty {
            id
            name
        }
        conflictExceptions {
            account
            resolution
            reason
            allowedDate
            active
            deniedBy {
                id
                name
            }
        }
    }
`)

export const RulesConflictFragment = print(gql`
    fragment RulesConflictFragment on RulesConflictModel {
        id
        status
        verifiedDate
        severityLevel
        account
        excepted
        recipients {
            publicId
            email
            firstName
            lastName
        }
        rule {
            id
            name
        }
        applications {
            applicationId
            applicationAccessId
            type
            name
        }
    }
`)

export const SodSchedulerDataFragment = print(gql`
    fragment SodSchedulerDataFragment on SodSchedulerModel {
        startDate
        endDate
        isAllActiveRules
        repeatNumber
        repeatTime
        email
        alerts {
            isEnded
            isError
            isCanceled
        }
                
    }
`)


