//core
import {createAsyncThunk} from "@reduxjs/toolkit";

//api
import {getConfig, updateConfig} from "../api";

//actions
import {addSuccessfulSnack} from "../../snack/store/slice";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {profilesMappingSchemaType} from "../types";
import {getActionsData} from "../../../../newShared/redux";

export const GetConfig = createAsyncThunk(
    'config/getConfig',
    async(_, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const response = await getConfig(workspaceId || '');
        return response;
    }
)

export const UpdateConfig = createAsyncThunk(
    'config/updateAccountsMappedSchema',
    // async (config: {accountsMappedSchema: string}, {dispatch}) => (
    //     updateConfig(config).then((data) => {
    //         dispatch(addSuccessfulSnack("Config Saved!"))
    //         return data;
    //     })

    async(config: {accountsMappingSchema: string, profilesMappingSchema: profilesMappingSchemaType[]}, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const response = await updateConfig(workspaceId || '', config);
        if(response.message !== undefined){
            dispatch(addSuccessfulSnack("Config Saved!"))
        }
        return config;
    }
)
