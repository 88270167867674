import {genericPageGenericRowPropsType} from "../../types";
import {useMenu} from "../../../../hooks/useMenu";
import {
    Button,
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import colors from "../../../../theme/colors";
import React, {MouseEventHandler} from "react";

import {styled} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {Flex} from "../../../Layouts";
import {DEBUG, EMPTY_VALUE_REPLACER, MENU_ITEM_SPINNER_SIZE} from "../../constants";
import {LocalSpinner} from "../../../Spinner";
import {useMainTranslation} from "../../../../hooks/useMainTranslationHooks/useMainTranslation";
import {GenericTableTooltipWithoutArrow} from "../styled";

const Item = styled(Paper)(({ isselected }: {isselected: boolean}) => ({
    backgroundColor: isselected ? colors.backgrounds.blue_light_1 : colors.backgrounds.white,
    padding: '16px',
    borderRadius: '8px',
    border: `1px solid ${colors.stroke.table}`,
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
    '&:hover': {
        backgroundColor: colors.backgrounds.optional
    }
}));

export function DynamicCard<T extends Object>({row, configs, onRowClick, isSelected, isPageBreak, page, index, isAlreadyExists, getRowId, rowActionsConfig, markRow}: genericPageGenericRowPropsType<T> & {isPageBreak: boolean, page: number, index: number, isAlreadyExists: boolean}){
    const menu = useMenu();
    const {revDir} = useMainTranslation();

    return(
        <Grid item
              sm={12}
              md={6}
              c1440={4}
              xl={4}
              c1680={3}
              sx={{width: 'inherit'}}
        >
            <Item isselected={isSelected} onClick={() => onRowClick(row)} sx={{backgroundColor: (DEBUG && isPageBreak) ? 'red' : undefined, cursor: 'pointer', height: '100%'}}>
                <Flex h={'100%'} w={'100%'} direction={'column'} overflow={'hidden'}>
                    {DEBUG &&
                        <>
                            {isPageBreak ? <Typography fontSize={'10px'}>PAGE-{page}</Typography> : <Typography fontSize={'10px'}>INDEX-{index}</Typography>}
                            {isAlreadyExists  && <Typography fontSize={'10px'}>EXISTS</Typography>}
                        </>
                    }

                    {rowActionsConfig && rowActionsConfig.length > 1 && <Flex w={'100%'} jc={'flex-end'}>
                        <IconButton
                            size={'small'}
                            aria-label="more"
                            id="long-button"
                            aria-controls={menu.open ? 'long-menu' : undefined}
                            aria-expanded={menu.open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={(e) => {
                                if (menu.open) {
                                    menu.handleClose()
                                } else {
                                    e.stopPropagation()
                                    menu.handleClick(e)
                                    markRow(row);
                                }
                            }}
                            color={"primary"}
                        >
                            {<MoreHorizIcon/>}
                        </IconButton>

                        <Menu
                            id="long-menu"
                            MenuListProps={{'aria-labelledby': 'long-button',}}
                            anchorEl={menu.anchorEl}
                            open={menu.open}
                            onClose={menu.handleClose}
                            onBackdropClick={(e) => {
                                e.stopPropagation()
                                menu.handleClose();
                            }}
                            PaperProps={{
                                style: {
                                    boxShadow: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1)',
                                    borderRadius: '6px',
                                    border: `1px solid ${colors.stroke.table}`
                                },
                            }}
                            sx={{
                                '& .MuiMenu-list': {
                                    padding: '4px 0'
                                },
                                '& .MuiMenuItem-root': {
                                    margin: '0 auto',
                                    '&:hover': {
                                        backgroundColor: colors.backgrounds.grey_light
                                    }
                                }
                            }}
                        >
                            {rowActionsConfig.map(e => {
                                const isDisabled = e.disabled ? e.disabled(row) : false;
                                const isLoading = e.loading ? e.loading(row) : false;
                                const isDontShow = e.dontShow ? e.dontShow(row) : false;

                                return(
                                    isDontShow ? <></> :
                                        <MenuItem disabled={isDisabled || isLoading} key={e.title} onClick={(event) => {
                                            event.stopPropagation();
                                            markRow(row);
                                            e.onClick(row);
                                            menu.handleClose();
                                        }}>
                                            {e.icon &&
                                                <ListItemIcon>
                                                    {isLoading ? <LocalSpinner width={MENU_ITEM_SPINNER_SIZE} height={MENU_ITEM_SPINNER_SIZE} /> : e.icon}
                                                </ListItemIcon>
                                            }
                                            <ListItemText>
                                                <Typography variant={'body2'} color={colors.grayText}>{e.title}</Typography>
                                            </ListItemText>
                                        </MenuItem>
                                )
                            })}
                        </Menu>
                    </Flex>
                    }

                    <TableContainer className={'withoutBorder'} sx={{margin: '0'}}>
                        <Table>
                            <TableBody>
                                {configs.map(e => {
                                    let valueGetterValue = e.valueGetter && e.valueGetter(row, e.key, false);
                                    if(!valueGetterValue || !valueGetterValue.trim().length) valueGetterValue = EMPTY_VALUE_REPLACER;

                                    let fallBackValue = EMPTY_VALUE_REPLACER;
                                    if(!e.valueRenderer && !e.valueGetter){
                                        const entry = Object.entries(row).find(f => f[0] === e.key);
                                        if(entry && entry[1] && entry[1] !== undefined && entry[1] !== null){
                                            if(typeof entry[1] === 'string'){
                                                fallBackValue = entry[1].trim().length > 0 ? entry[1] : fallBackValue;
                                            }
                                            fallBackValue = String(entry[1]);
                                        }
                                    }

                                    return(
                                        <TableRow>
                                            <TableCell sx={{borderBottom: 'none', padding: !revDir ? '4px 8px 4px 0' : '4px 0 4px  8px'}}>
                                                <Typography variant={'*bodyText2'}>{e.name}</Typography>
                                            </TableCell>
                                            <TableCell sx={{borderBottom: 'none', padding: '4px 0'}}>
                                                {e.valueGetter && <Typography variant={'*bodyText2'} sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{valueGetterValue}</Typography>}
                                                {e.valueRenderer && e.valueRenderer(row, e.key, false, markRow)}
                                                {!e.valueRenderer && !e.valueGetter && <Typography variant={'*bodyText2'} sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{fallBackValue}</Typography>}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {rowActionsConfig && rowActionsConfig.length === 1 &&
                        <Flex jc={'flex-end'}>
                            {rowActionsConfig.filter(e => !e.dontShow || (e.dontShow && !e.dontShow(row))).map(e => {
                                const isDisabled = e.disabled ? e.disabled(row) : false;
                                const onClick: MouseEventHandler = (event) => {
                                    event.stopPropagation();
                                    if(!isDisabled){
                                        markRow(row);
                                        e.onClick(row);
                                    }
                                };

                                if(e.icon){
                                    return (
                                        <GenericTableTooltipWithoutArrow title={e.title}>
                                            <span onClick={onClick}>
                                                <IconButton size={'small'} disabled={e.disabled ? e.disabled(row) : false} onClick={onClick}>{e.icon}</IconButton>
                                            </span>
                                        </GenericTableTooltipWithoutArrow>
                                    )
                                }else{
                                    return <Button variant={e.buttonProps?.variant ?? 'text'} color={e.buttonProps?.color ?? 'primary'} size={'small'} onClick={onClick}>{e.title}</Button>
                                }
                            })}
                        </Flex>
                    }
                </Flex>
            </Item>
        </Grid>
    )
}