import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import * as S from "../inputClientDataTemplate/styled";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import colors from "../../../../../../newShared/theme/colors";
import {InputClientDataDropZone} from "../inputClientDataTemplate/components/InputClientDataDropZone";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import React from "react";
import {useUpdateClient} from "../../../hooks/dialogs/useUpdateClient";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {useSelector} from "react-redux";
import {forbiddenWrapperOrganizations} from "../../../../../authWorkspacesCookies/settings/store/slice";
import {getKycDictionary} from "../../../helpers";
import {KycExactDictType} from "../../../types";
import {DEFAULT_DATE_FORMAT} from "../../../../../../newShared/utils/dateTools";

export const UpdateClientDataDialog = () => {
    const {currData: {selectedOrganization}} = UseManageWorkspacesAndOrganizations();
    const organizations = useSelector(forbiddenWrapperOrganizations);
    const org = organizations.find(e => e.id === selectedOrganization?.id);

    const {isOpen, handleChangeCustomField,
        handleChangeDate, handleClose,
        handleSubmit, handleFileUpload,
        isOk, form, isLoading, client,
        handleDeleteUploadedFile, dicts, handleChangeSelectField
    } = useUpdateClient();
    return(
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{client?.targetType === 'CORPORATE' ? ('Company verification') : ('Person verification')}</DialogTitle>

            <DialogContent>
                <S.PreviewTemplateContainer>
                    {/*<TobBarLogo />*/}
                    {org && <img src={org.logo} alt={org.name} width={'48px'}/>}
                    {org && <Typography align={'center'} variant={'body2'} fontWeight={700}>{`${org.name} asks you fill form for KYC verification.`}</Typography>}
                    {/*<Typography variant={'h3'} fontWeight={700}>{'KYC Verification request'}</Typography>*/}
                    {form.fields
                        .slice()
                        .sort((a, b) => a.order - b.order)
                        .map(e => {
                        const currentField = form.fields.find(f => f.id === e.id);
                        const currentDict = getKycDictionary(dicts, e.dictionary);
                        switch (e.type){
                            case 'TEXT': {
                                return <TextField
                                    required={e.required}
                                    // placeholder={e.name}
                                    label={e.name}
                                    size={'small'}
                                    name={e.id}
                                    value={currentField?.value ?? ''}
                                    onChange={handleChangeCustomField}
                                />
                            }
                            case 'NUMBER': {
                                return <TextField
                                    type={'number'}
                                    required={e.required}
                                    // placeholder={e.name}
                                    label={e.name}
                                    size={'small'}
                                    name={e.id}
                                    value={currentField?.value ?? ''}
                                    onChange={handleChangeCustomField}
                                />
                            }
                            case 'DATE': {
                                return <DatePicker
                                    label={e.name}
                                    value={currentField?.value ?? ''}
                                    inputFormat={DEFAULT_DATE_FORMAT}
                                    onChange={(newValue) => handleChangeDate(e.id, newValue)}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        required={e.required}
                                        error={false}
                                        size={'small'}
                                        name={e.id}
                                        // sx={{'& fieldset': {borderColor: 'black !important'}}}
                                    />
                                    }
                                />
                            }
                            case 'FILE': {
                                return <S.FileDropContainer>
                                    <Typography fontSize={'12px'} fontWeight={600}>
                                        {e.name}
                                        {e.required && <span style={{color: colors.warningActions.red}}>*</span>}
                                    </Typography>

                                    <InputClientDataDropZone
                                        initialFileName={e.fileName ?? undefined}
                                        onFileUploaded={handleFileUpload}
                                        isLoadingCreateTarget={isLoading}
                                        handleDelete={() => handleDeleteUploadedFile(e.id)}
                                        id={e.id}
                                    />
                                </S.FileDropContainer>
                            }
                            case 'SELECT': {
                                return <Autocomplete
                                    key={e.id}
                                    id="combo-box-demo"
                                    options={currentDict.slice().sort((a, b) => a.order - b.order)}
                                    value={currentDict.find(f => f.key === currentField?.value)}
                                    isOptionEqualToValue={(a: KycExactDictType, b:  KycExactDictType | undefined) => b !== undefined && a.key === b.key}
                                    getOptionLabel={(e) => e.value}
                                    onChange={(event, value) => handleChangeSelectField(e.id, value)}
                                    sx={{width: '100%'}}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label={e.name}
                                        required={e.required}
                                        fullWidth
                                        error={false}
                                        size={'small'}
                                        name={e.id}
                                        // sx={{'& fieldset': {borderColor: 'black !important'}}}
                                    />}
                                />
                            }
                            default: return <></>;
                        }
                    })}
                </S.PreviewTemplateContainer>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'} size={'medium'}>{('Cancel')}</Button>
                <LoadingButton onClick={handleSubmit} size={'medium'} disabled={!isOk()} loading={isLoading}>{('Update client')}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
