import {FC} from "react";
import {TableCell, TableRow, Typography} from "@mui/material";

type TProps = {
    columns?: number
    text: string
}
export const TableRowEmpty: FC<TProps> = ({columns = 3, text}) => {

    return (
        <TableRow>
            <TableCell colSpan={columns}>
                <Typography variant={"subtitle2"}>
                    {text}
                </Typography>
            </TableCell>
        </TableRow>
    )
}