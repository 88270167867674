import {useSegregationOfDuties} from "../useSegregationOfDuties";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    PAGE_COUNT,
    PATH_ACCESS_MANAGEMENT_APPS_LIST,
    PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES
} from "../../constants";
import {useEffect} from "react";
import {useScrollPagination} from "../../../../../newShared/hooks/useScrollPagination";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";


export const useDutiesList = () => {
    //
    // const {filter} = useParameters();

    const {
        appDuties,
        pageInfo: {page, count, total},
        getDuties,

        categories,
        loadings: {list: isLoadingList},
        clean,

        handleOpenCreateDuty,
        handleOpenEditDuty,
        handleOpenDeleteDuty,
        onRoleClick,

    } = useSegregationOfDuties();
    
    const {currentFiltersForFetch, clearFilters, isFilterEmpty} = useGenericFiltersStorage();

    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    useSetBreadcrumbs([
        {
            title: tMenu('Access management'),
            path: PATH_ACCESS_MANAGEMENT_APPS_LIST
        },
        {
            title: tMenu('Duties list'),
            path: PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES
        },
    ]);

    const controller = new AbortController();

    useEffect(() => {
        // appDuties.length === 0 && getDuties({page, count: PAGE_COUNT, filters: filter}, controller.signal);

        return () => {
            controller.abort();
            clean();
            clearFilters();
        }
        //eslint-disable-next-line
    }, []);

    // pagination
    const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => { getDuties({page, count: PAGE_COUNT, filters: currentFiltersForFetch}, controller.signal) },
        arrayLength: appDuties.length,
        isLoading: isLoadingList,
        pageInfo: {page, count, total},
        cleanData: () => {},

    })

    // const {value, handleChange} = useSearchFieldURL({wait: 0});
    // const {setFilter, handleKeyDown} = useFilterUrl({
    //     getData: (filters) => {getDuties({page: 0, count: PAGE_COUNT, filters: {...currentFiltersForFetch, input: [currentSearchForFetch]}}, controller.signal, true)}
    // });

    const categoryConfig: keyConfig<string> = {
        type: 'filter',
        key: 'category',
        name: ('Category'),
        options: categories,
        default: true
    }

    const fetchResultWithSelectedFilters = (search: string, filters: Record<string, string[]>, signal: AbortSignal) => {
        getDuties({page: 0, count: PAGE_COUNT, filters}, undefined,true)
    }

    return {
        appDuties,
        categories,
        isLoadingList,
        isNoDutyFound: !isLoadingList && !appDuties.length,
        handleOpenCreateDuty,
        handleOpenEditDuty,
        handleOpenDeleteDuty,
        onRoleClick,

        // filter,
        // value, handleChange, handleKeyDown, setFilter,
        pagination: {
            bottomLoader: bottomLoader && ((appDuties.length < total) || (appDuties.length === total && total === 0)),
            onScroll,
            scrollRef
        },
        filters: {
            configs: [categoryConfig],
            fetchResultWithSelectedFilters,
            isFilterEmpty
        }

    }
}