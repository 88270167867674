import {
    reportsDialogsSelector,
    setArchiveReportDialogAction,
    setDeleteReportDialogAction,
    TReportsSlice
} from "../../store/reports/store";
import {useDispatch, useSelector} from "react-redux";
import {TSetArchiveReportDialog, TSetCollectorView, TSetPreviewReportDialog, TSetStatementDialog} from "../../types";
import {
    reportViewDialogsSelector,
    setCollectorViewAction,
    setPreviewDialogAction,
    setStatementDialogAction,
    TReportViewSlice
} from "../../store/reportView/store";

type TResponse = {
    archiveReport: TReportsSlice["dialogs"]["archiveReport"] & {setArchiveReportDialog: (data: TSetArchiveReportDialog) => void},
    deleteReport: TReportsSlice["dialogs"]["archiveReport"] & {setDeleteReportDialog: (data: TSetArchiveReportDialog) => void},
    preview: TReportViewSlice["dialogs"]["preview"] & {setPreviewDialog: (data: TSetPreviewReportDialog) => void},
    collectorView: TReportViewSlice["dialogs"]["collectorView"] & {setCollectorView: (data: TSetCollectorView) => void},
    statement: TReportViewSlice["dialogs"]["statement"] & {setStatementDialog: (data: TSetStatementDialog) => void},
}
export const useReportDialogs = (): TResponse => {

    const dispatch = useDispatch();

    const {
        archiveReport,
        deleteReport
    } = useSelector(reportsDialogsSelector);
    const {
        preview,
        collectorView,
        statement
    } = useSelector(reportViewDialogsSelector);

    const setArchiveReportDialog: TResponse["archiveReport"]["setArchiveReportDialog"] = (data) => {
        dispatch(setArchiveReportDialogAction(data))
    }

    const setDeleteReportDialog: TResponse["deleteReport"]["setDeleteReportDialog"] = (data) => {
        dispatch(setDeleteReportDialogAction(data))
    }

    const setPreviewDialog: TResponse["preview"]["setPreviewDialog"] = (data) => {
        dispatch(setPreviewDialogAction(data))
    }

    const setCollectorView: TResponse["collectorView"]["setCollectorView"] = (data) => {
        dispatch(setCollectorViewAction(data))
    }

    const setStatementDialog: TResponse["statement"]["setStatementDialog"] = (data) => {
        dispatch(setStatementDialogAction(data))
    }

    return {
        archiveReport: {...archiveReport, setArchiveReportDialog},
        deleteReport: {...deleteReport, setDeleteReportDialog},
        preview: {...preview, setPreviewDialog},
        collectorView: {...collectorView, setCollectorView},
        statement: {...statement, setStatementDialog},
    }
}