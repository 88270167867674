import React from "react";
import {Box} from "@mui/material";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {AddDutyDialog} from "../../../components/dialogs/addDuty";
import {EditDutyDialog} from "../../../components/dialogs/editDuty";
import {useRulesetList} from "../../../hooks/useRulesetList";
import AddIcon from "@mui/icons-material/Add";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";
import {TDutyRule} from "../../../types";
import {GenericTable} from "../../../../../../newShared/components/genericTable";
import CommonSwitch from "../../../../../../newShared/components/Basic/CommonSwitch";
import {useImportCsvRuleset} from "../../../hooks/dialogHooks/useImportCsvRuleset";
import {ImportCsvDialog} from "../../../../../../newShared/components/csvImportDialog";

export const RulesetList = () => {

    const {isMobile} = useMedia();
    const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        ruleset,
        handleChangeIsActive,
        genericTable,
        goToRuleNew
    } = useRulesetList();

    const importCsv = useImportCsvRuleset();

    return(
        <Box width={'100%'} height={'100%'} display={'flex'} flexDirection={'column'}>

            <PageTitleAndActions
                title={t('Segregation of duties ruleset')}
                showMenuWhen={isMobile}
                actions={[
                    {
                        title: t('Create rule'),
                        onClick: goToRuleNew,
                        variant: 'text',
                        icon: <AddIcon />
                    }
                ]}
            />

            <GenericTable<TDutyRule>
                id={'AccessManagementAppsList'}
                columnsConfig={{totalName: 'Total rules', disableColumnsSettings: true, forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: ruleset,
                    customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id,
                    onRowClick: genericTable.onRowClick,
                    columnsConfigs: [
                        {key: "name", name: t('Rule name'), default: true},
                        {key: 'severityLevel', name: t('Severity level'), default: true},
                        {key: 'securityRisk', name: t('Security risk'), default: true, disableNoWrapTable: true},
                        {key: 'securityMitigation', name: t('Security mitigation'), default: true, disableNoWrapTable: true},
                        {key: 'switch', name: 'Status', default: true, valueRenderer: (row, key, isTable) => (
                                <CommonSwitch
                                    checked={row.active}
                                    sx={{mr: '0', ml: '0', p: isTable ? '0 16px' : undefined}}
                                    label={row.active ? t('Active') : t('Inactive')}
                                    onClick={(e) => handleChangeIsActive(e, {...row, active: !row.active})}
                                />
                        )},
                    ],
                }}
                filtersConfig={{
                    searchCustomFilterKeyName: 'input',
                    searchAsArray: true,
                    dontIncludeSearchInFilter: true,
                    genericFilterProps: {
                        configs: genericTable.filters.configs,
                        fetchResultWithSelectedFilters: () => null,
                        isAddFilters: false
                    }
                }}
                emptyArrayImageProps={{type: 'accessManagement', filterNotApplied: {secondaryText: 'You have not added any rule yet. Create new one.', text: 'No rules found', onClick: goToRuleNew}}}
            />

            {/*<EmptyArrayImage*/}
            {/*    type={'accessManagement'}*/}
            {/*    isShowing={isNoRulesFound}*/}
            {/*    text={('No duties found')}*/}
            {/*    secondaryText={('You haven\'t added any rule yet. Create new one.')}*/}
            {/*    onClick={goToRuleNew}*/}
            {/*/>*/}

            {/*{!isNoRulesFound &&*/}
            {/*    <>*/}
            {/*        <Box display={'flex'} alignItems={'center'} marginTop={'20px'} flexWrap={'wrap'}>*/}
            {/*            <SearchField*/}
            {/*                size={'small'}*/}
            {/*                sx={{ml: revDir && !isMobile ? '10px' : undefined, mr: !revDir && !isMobile ? '10px' : undefined, width: isMobile ? '100%' : isTablet ? '45%' : '320px'}}*/}
            {/*                variant={'outlined'}*/}
            {/*                placeholder={t('Search')}*/}
            {/*                onClear={() => {setFilter('input'); handleChange({target: {value: ''}})}}*/}
            {/*                value={value}*/}
            {/*                disabled={isLoadingList}*/}
            {/*                onChange={handleChange}*/}
            {/*                onKeyDown={handleKeyDown}*/}
            {/*            />*/}

            {/*            <TextField label={t('Sort by severity level')} select size={'small'}*/}
            {/*                       value={filter.severityLevel?.[0] ?? 'Any'}*/}
            {/*                       sx={{ml: revDir && !isMobile ? '10px' : undefined, mr: !revDir && !isMobile ? '10px' : undefined, mt: isMobile ? '16px' : undefined,  width: isMobile ? '100%' : isTablet ? '45%' : '320px', '& label': {top: '0 !important'}}}*/}
            {/*                       disabled={isLoadingList}*/}
            {/*            >*/}
            {/*                <MenuItem key={'Any'} value={'Any'} onClick={() => {setFilter('severityLevel')}}>{t('Any')}</MenuItem>*/}
            {/*                {["Low", "High", "Medium"].map((e) => (<MenuItem key={e} value={e.toUpperCase()} onClick={() => {setFilter('severityLevel', e.toUpperCase())}}>{e}</MenuItem>))}*/}
            {/*            </TextField>*/}

            {/*            <TextField label={t('Sort by activity status')} select size={'small'}*/}
            {/*                       value={filter.status?.[0] ?? 'Any'}*/}
            {/*                       sx={{width: isMobile ? '100%' : isTablet ? '45%' : '320px', mt: isMobile || isTablet ? '16px' : undefined, '& label': {top: '0 !important'}}}*/}
            {/*                       disabled={isLoadingList}*/}
            {/*            >*/}
            {/*                <MenuItem key={'Any'} value={'Any'} onClick={() => {setFilter('status')}}>{t('Any')}</MenuItem>*/}
            {/*                <MenuItem key={'active'} value={'active'} onClick={() => {setFilter('status', 'active')}}>{t('Active')}</MenuItem>*/}
            {/*                <MenuItem key={'inactive'} value={'inactive'} onClick={() => {setFilter('status', 'inactive')}}>{t('Inactive')}</MenuItem>*/}
            {/*            </TextField>*/}

            {/*        </Box>*/}

            {/*        <TableContainer onScroll={onScroll} ref={scrollRef} sx={{mt: '22px', minHeight: '60px'}} className={'withoutBorder'}>*/}
            {/*            <Table stickyHeader={!isLessThen1050}>*/}
            {/*                {isLessThen1050 ?*/}
            {/*                    <TableBody>*/}
            {/*                        {ruleset.length === 0 && filter && (!!filter?.input || !!filter?.severityLevel || !!filter?.status) ?*/}
            {/*                            <TableRow><TableCell>{t('Nothing found')}</TableCell></TableRow>*/}
            {/*                            :*/}
            {/*                            ruleset.map((e) => (*/}
            {/*                                <TableRow hover key={e?.id} onClick={() => { e && goToRuleExact(e.id); e && handleSelectRule(e)}}>*/}
            {/*                                    <TableCell style={{ padding: 0, borderBottom: 'none' }}>*/}
            {/*                                        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} sx={{borderRadius: '8px', m: '6px', p: '8px', boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)'}}>*/}
            {/*                                            <Typography variant={'body2'}>{e?.name}</Typography>*/}
            {/*                                            <Box display={'flex'} >*/}
            {/*                                                <Typography variant={'body2'} sx={{color: colors.lightGrayText, whiteSpace: 'nowrap'}}>{t('Severity level')}</Typography>*/}
            {/*                                                <Typography variant={'body2'} sx={{color: colors.darkText, ml: !revDir ? '6px' : undefined, mr: revDir ? '6px' : undefined}}>{e?.severityLevel}</Typography>*/}
            {/*                                            </Box>*/}
            {/*                                            <Box display={'flex'}>*/}
            {/*                                                <Typography variant={'body2'} sx={{color: colors.lightGrayText, whiteSpace: 'nowrap'}}>{t('Security risk')}</Typography>*/}
            {/*                                                <Typography variant={'body2'} sx={{color: colors.darkText, ml: !revDir ? '6px' : undefined, mr: revDir ? '6px' : undefined}}>{e?.securityRisk}</Typography>*/}
            {/*                                            </Box>*/}
            {/*                                            <Box display={'flex'}>*/}
            {/*                                                <Typography variant={'body2'} sx={{color: colors.lightGrayText, whiteSpace: 'nowrap'}}>{t('Security mitigation')}</Typography>*/}
            {/*                                                <Typography variant={'body2'} sx={{color: colors.darkText, ml: !revDir ? '6px' : undefined, mr: revDir ? '6px' : undefined}}>{e?.securityMitigation}</Typography>*/}
            {/*                                            </Box>*/}
            {/*                                            <Box display={'flex'} alignItems={'center'} onClick={(event) => e && handleChangeIsActive(event, {...e, active: !e.active})}>*/}
            {/*                                                <CommonSwitch checked={e?.active} sx={{mr: '6px', ml: '6px'}} label={e?.active ? t('Active') : t('Inactive')} />*/}
            {/*                                            </Box>*/}
            {/*                                        </Box>*/}
            {/*                                    </TableCell>*/}
            {/*                                </TableRow>*/}
            {/*                            ))}*/}

            {/*                        {isLoadingList && <TableCardSkeleton rows={5} withButtons={false} /> }*/}
            {/*                    </TableBody>*/}
            {/*                    :*/}
            {/*                    <>*/}
            {/*                        <TableHead>*/}
            {/*                            <TableRow>*/}
            {/*                                <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {t('Rule name')}</TableCell>*/}
            {/*                                <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {t('Severity level')}</TableCell>*/}
            {/*                                <TableCell sx={{maxWidth: '300px'}} align={revDir ? 'right' : 'left'}> {t('Security risk')}</TableCell>*/}
            {/*                                <TableCell sx={{maxWidth: '250px'}} align={revDir ? 'right' : 'left'}> {t('Security mitigation')}</TableCell>*/}
            {/*                                <TableCell align={revDir ? 'right' : 'left'} />*/}
            {/*                            </TableRow>*/}
            {/*                        </TableHead>*/}

            {/*                        <TableBody>*/}
            {/*                            {ruleset.map((rule) => (*/}
            {/*                                <TableRow hover key={rule?.id} onClick={() => { rule && goToRuleExact(rule.id); rule && handleSelectRule(rule);}} >*/}
            {/*                                    <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {rule?.name}</TableCell>*/}
            {/*                                    <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {rule?.severityLevel}</TableCell>*/}
            {/*                                    <TableCell sx={{maxWidth: '300px'}} align={revDir ? 'right' : 'left'}> {rule?.securityRisk}</TableCell>*/}
            {/*                                    <TableCell sx={{maxWidth: '250px'}} align={revDir ? 'right' : 'left'}> {rule?.securityMitigation}</TableCell>*/}
            {/*                                    <TableCell align={revDir ? 'right' : 'left'} onClick={(event) => rule && handleChangeIsActive(event, {...rule, active: !rule.active})} >*/}
            {/*                                        <CommonSwitch disabled={isLoadingUpdate} checked={rule?.active} label={rule?.active ? t('Active') : t('Inactive')}  />*/}
            {/*                                    </TableCell>*/}
            {/*                                </TableRow>*/}
            {/*                            ))}*/}
            {/*                            {(bottomLoader || isLoadingList) && <TableRowSkeleton columns={5} />}*/}
            {/*                        </TableBody>*/}
            {/*                    </>*/}
            {/*                }*/}
            {/*            </Table>*/}
            {/*        </TableContainer>*/}
            {/*    </>*/}
            {/*}*/}
            {/**/}

            <AddDutyDialog />
            <EditDutyDialog />

            <ImportCsvDialog
                isOpen={importCsv.isOpen}
                fields={importCsv.fields}
                response={importCsv.response}
                setImportDialog={importCsv.setImportDialog}

                isGettingFields={importCsv.isGettingFields}
                isPerformingCsv={importCsv.isPerformingCsv}
                isLoadingTemplate={importCsv.isLoadingTemplate}

                getFields={importCsv.getFields}
                performCsv={importCsv.performCsv}
                downloadTemplate={importCsv.downloadTemplate}
                fieldsMapperKey={importCsv.fieldsMapperKey}

                fileLimitInMB={5}
            />
        </Box>
    )
}
