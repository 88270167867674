import React, {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {TREE_GALLERY_PATH, TREE_PATH, TREE_TRASH_PATH} from "./constants";
import {ExplorerPageRefactor} from "./pages/explorer";
import {GalleryPageRefactor} from "./pages/gallery";
import {TrashPage} from "./pages/trash";

export const Routes: FC = () => {
    return (
        <Switch>
            <Route path={[TREE_TRASH_PATH]} exact component={TrashPage}/>
            <Route path={[TREE_GALLERY_PATH, TREE_GALLERY_PATH + '/:id']} exact component={GalleryPageRefactor}/>
            <Route path={[TREE_PATH, TREE_PATH + '/*']} exact component={ExplorerPageRefactor}/>

            {/*<Route path={'/documents/EDITOR/:fileId'} exact component={EditorInDocuments}/>*/}
            <Redirect to={TREE_PATH}/>
        </Switch>
    );
}
