import {useDispatch, useSelector} from "react-redux";
import {alertsSelector} from "../../store/selectors";
import {TAlert} from "../../types";
import {addAlertAction, removeAlertAction} from "../../store/slice";

export const useAlerts = () => {
    const dispatch = useDispatch();

    const alerts = useSelector(alertsSelector);

    const addAlert = (data: TAlert) => {dispatch(addAlertAction(data))}
    const removeAlert = (id: string) => {dispatch(removeAlertAction(id))}

    return {
        alerts,
        addAlert,
        removeAlert
    }
}