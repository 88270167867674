import {TApproverStepProps} from "../index";
import {useState} from "react";
import {isExpired} from "../../../../../../../utils/dateTools";
import {useMainTranslation} from "../../../../../../../hooks/useMainTranslationHooks/useMainTranslation";
import {IN_PROGRESS_STATUS, REJECTED_STATUS, SKIPPED_STATUS} from "../../../../../types";

export const useApproverStep = (props: TApproverStepProps) => {
    const {currentLanguage: {momentLocale}} = useMainTranslation();
    const [collapsed, setCollapsed] = useState<boolean>(false);

    const toggleCollapse = () => {
        setCollapsed(!collapsed)
    }

    const getGroupName = (id: string): string | null => {
        let groupName: string | null = null;
        props.actorsConfig.approvers.forEach(e => {
            e.groups.forEach(g => {
                // console.log(`GROUP`, g)
                if(g.id === id) groupName = g.name;
            })
        })
        return groupName;
    }

    const _isExpired = isExpired(props.step.endDate || new Date().toISOString(), momentLocale);

    return{
        collapsed,
        toggleCollapse,
        getGroupName,
        isExpired: _isExpired,
        isSkipDisabled: () => {
            // return true;
            //commented because backend is not ready for handling eta 5/07/23
            if(!_isExpired) return true;
            if(props.step.groups?.every(e => e.isRequired)) return true;
            // if any group rejected - disable
            //get all groups with status pending - if any is requeried - disable
            //if all not required - active - Zhenya 11.35 3.07.23
            if(props.step.groups?.some(e => e.status === REJECTED_STATUS)) return true;
            if(props.step.groups?.some(e => e.status === SKIPPED_STATUS)) return true;
            if(props.step.groups?.some(e => e.status === IN_PROGRESS_STATUS && e.isRequired)) return true;
            // if(props.step.groups?.some(e => e.isRequired)) return true;

            return false;
        }
    }
}
