import {useInviteToWorkspace} from "../../../../../hooks/organizations/dialogs/useInviteToWorkspace";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import {IconTextButton, LoadingButton} from "../../../../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TWorkspaceRole} from "../../../../../types";
import {Autocomplete} from "@mui/lab";
import {TextFieldCustom} from "../../../../styled";
import {Flex} from "../../../../../../../../newShared/components/Layouts";
import AddIcon from "@mui/icons-material/Add";
import {ROLE_PORTAL_MEMBER, ROLE_WS_OWNER} from "../../../../../constants";
import colors from "../../../../../../../../newShared/theme/colors";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import UploadIcon from "@mui/icons-material/Upload";
import {validateEmail} from "../../../../../../../../newShared/utils/text";
import CommonCheckbox from "../../../../../../../../newShared/components/Basic/CommonCheckBox";

export const InviteToWorkspaceDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathSettings.dialogs'});
    const {
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        form,
        actions,
        roles,
        checks,
        handleChange,
        getEmailTextFieldErrorText,
        isAccessToPortal,
        setIsAccessToPortal,
        isContainAdminRole,
    } = useInviteToWorkspace();

    return(
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{t(`Invite collaborator`)}</DialogTitle>

            <DialogContent>
                {checks.isNoPlace ?
                    <>
                        <Typography variant={"body2"}>{t("You do not have enough seats to add/invite collaborators.")}</Typography>
                        {checks.isOwner &&
                            <Typography variant={'body2'} sx={{cursor: 'pointer', fontWeight: 600, color:colors.primary.blue}} onClick={actions.handleGoToPlans} >{t('Upgrade your plan here')}</Typography>
                        }
                    </>
                    :
                    <>
                        <Typography variant={"body2"}>{t("Invite collaborator to your workspace and assign role(s).")}</Typography>

                        <TextField
                            label={'Email'}
                            size={'small'}
                            fullWidth
                            value={form.email}
                            onChange={handleChange}
                            name={'email'}
                            error={form.email.trim().length > 0 && (!validateEmail(form.email) || checks.isAlreadyInWS || checks.isAlreadyInvited)}
                            helperText={getEmailTextFieldErrorText()}
                            sx={{mt: '16px', }}
                        />

                        <Box display={'flex'} alignItems={'center'} sx={{margin: '16px -12px 0 -12px'}}>
                            <CommonCheckbox
                                checked={isAccessToPortal}
                                disabled={isContainAdminRole}
                                onChange={() => setIsAccessToPortal(!isAccessToPortal)}
                            />
                            <Typography variant={'body2'}>{t('Allow collaborator to enter Portal')}</Typography>
                        </Box>
                        <Box display={'flex'} alignItems={'center'} sx={{ml: '28px', mt: '-10px'}}>
                            <Typography variant={'body2'} color={colors.text.grey} sx={{lineHeight: '19px'}}>
                                {t('By selecting checkbox you are granting access to Portal area for this collaborator.')}
                            </Typography>
                        </Box>

                        <Typography variant={"body1"} sx={{mt: '26px', fontWeight: 600}}>{t("Assign roles")}</Typography>
                        {form.collabRoles.filter(e => e.name !== ROLE_PORTAL_MEMBER).map((e, id) => {
                            return(
                                <Flex w={'100%'} jc={'space-between'} ai={'center'} key={id} m={!id ? '8px 0 0 0' : '16px 0 0 0'}>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={roles.filter(e => e.name !== ROLE_WS_OWNER && e.name !== ROLE_PORTAL_MEMBER)}
                                        getOptionLabel={(option) => option.name}
                                        getOptionDisabled={(option) => form.collabRoles.some(e => e.id === option.id)}
                                        value={e}
                                        onChange={(e, newValue: TWorkspaceRole | null) => newValue && actions.replaceCollabRole(id, newValue)}
                                        sx={{width: '90%' }}
                                        renderInput={(params) => <TextFieldCustom {...params} label={t("Role")} size={'small'}/>}
                                    />
                                    <IconButton onClick={() => actions.handleDeleteCollabRole(e)}>
                                        <DeleteOutlineIcon style={{color: colors.primary.blue}}/>
                                    </IconButton>
                                </Flex>
                            )
                        })}

                        <IconTextButton
                            variant={'text'}
                            size={'small'}
                            icon={<AddIcon fontSize="small"/>}
                            onClick={actions.handleAddCollabRole}
                            sx={{margin: '8px 0 0 0', textTransform: 'none'}}
                            disabled={checks.isNoMoreRolesCanBeAdded}
                        >{t('Add another role')}
                        </IconTextButton>
                    </>
                }
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} size={'small'} disabled={isLoading} variant={'text'}>{checks.isNoPlace ? t('Close') : t('Cancel')}</Button>
                {!checks.isNoPlace &&
                    <>
                        <Button variant={'text'} size={'small'} startIcon={<UploadIcon />} sx={{m: 0}} onClick={actions.handleImportCSV}>{t('Import CSV')}</Button>
                        <LoadingButton
                            sx={{m: 0}}
                            size={'small'}
                            loading={isLoading}
                            onClick={handleSubmit}
                            disabled={!checks.isOk || checks.isNoPlace || checks.isAlreadyInvited || checks.isAlreadyInWS}
                        >
                            {t('Invite')}
                            {/*{(tab === 'outsideCollab') ? t('Invite') : t('Add')}*/}
                        </LoadingButton>
                    </>
                }

            </DialogActions>
        </Dialog>
    )
}
