import React, {FC} from "react";
import {Box, Button, Card, Typography} from "@mui/material";
import {frameworkType} from "../../types";
import colors from "../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

type TProps = {
    framework: frameworkType,
    // handleOpenEditFramework: (e: React.MouseEvent<HTMLButtonElement>, framework: frameworkType) => void,
    // handleOpenAddControl: (e: React.MouseEvent<HTMLButtonElement>, frameworkId: string) => void,
    onClick: () => void,
}
export const FrameworkCard: FC<TProps> = ({framework, onClick}) => {
    const {t} = useMainTranslation('', {keyPrefix: 'builderPage'});


    return (
        <Card
            sx={{
                padding: '8px', height: '100%', display: 'flex', flexDirection: 'column',
            }}
        >
            <Box display={"flex"} alignItems={"center"}>
                {framework.logo && <img src={framework.logo} alt={framework.name} style={{maxHeight: '40px', maxWidth: '40px'}}/>}
                <Typography variant={"h4"} margin={'0 4px'}>{framework.name}</Typography>
            </Box>

            <Box width={'100%'} height={'38px'} overflow={"hidden"} mt={'8px'}>
                <Typography variant={"body2"} fontSize={'12px'} color={colors.text.grey_dark}
                            sx={{
                                overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', maxHeight: '100%',
                                '-webkit-box-orient': 'vertical', "-webkit-line-clamp": '2', display: '-webkit-box',
                                // whiteSpace: 'nowrap',
                            }}>
                    {framework.description}
                </Typography>
            </Box>

            <Box width={'100%'} flexGrow={1}/>

            <Typography variant={"body1"} color={colors.text.grey_dark} mt={'8px'}>
                {framework.createdBy.toLowerCase() === 'shared' ?  framework.createdBy : `${t('Created by')} ${framework.createdBy}`}
            </Typography>
            {/*<Typography variant={"body1"} color={colors.text.grey_dark} mt={'8px'}>*/}
            {/*    {t('GAP')}*/}
            {/*    <Typography variant={"body1"} color={colors.text.dark} m={'0 8px'} component={"span"}>{`${framework.gap}%`}</Typography>*/}
            {/*</Typography>*/}
            <Typography variant={"body1"} color={colors.text.grey_dark} mt={'8px'}>
                {t('Type')}
                <Typography variant={"body1"} color={colors.text.dark} m={'0 8px'} component={"span"}>{framework.type}</Typography>
            </Typography>
            <Typography variant={"body1"} color={colors.text.grey_dark} mt={'8px'}>
                {t('Controls')}
                <Typography variant={"body1"} color={colors.text.dark} m={'0 8px'} component={"span"}>{framework.controls}</Typography>
            </Typography>
            <Typography variant={"body1"} color={colors.text.grey_dark} mt={'8px'} mb={'16px'}>
                {t('Evidences')}
                <Typography variant={"body1"} color={colors.text.dark} m={'0 8px'} component={"span"}>{`${framework.evidences}/${framework.evidences}`}</Typography>
            </Typography>

            <Box display={"flex"} justifyContent={"flex-end"}>
                <Button variant={"outlined"}
                    onClick={onClick}>
                    {t('Open')}
                </Button>
            </Box>
        </Card>
    )
}