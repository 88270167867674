import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSegregationOfDuties} from "../../../hooks/useSegregationOfDuties";
import {CommonAvatar} from "../../../../../../newShared/components/Basic/CommonAvatar";
import colors from "../../../../../../newShared/theme/colors";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";

export const DeniedConflictDataDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});
    const {isMobile} = useMedia();

    const {
        loadings: {dataForDialog: isLoadingData},
        dialogs: {deniedConflictData: {isOpen, conflict}},
        handleHideDeniedConflictData: handleClose,
        goToRuleExact,
    } = useSegregationOfDuties();

    const handleGoToRuleExact = () => {
        conflict && goToRuleExact(conflict.rule.id);
        handleClose();
    }

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Denied conflict')}</DialogTitle>
            <DialogContent>
                {conflict &&
                    <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} sx={{mt: '6px', p: '8px'}}>
                        <Typography variant={'body1'} sx={{mb: '16px'}}>{conflict.recipients.length > 0 ? t('You sent notifications to') : t('You have not sent any notifications')}</Typography>

                        {conflict.recipients.map((recipient) => (
                            recipient.publicId ?
                                <Box key={recipient.email} display={'flex'} flexDirection={'column'} alignItems={'flex-start'} width={'100%'} >
                                    <Box  width={'100%'} display={'flex'} alignItems={'center'} sx={{ml: '12px', mr: '12px'}}>
                                        <CommonAvatar name={`${recipient.firstName} ${recipient.lastName}`}/>

                                        <Box display={'flex'} flexDirection={'column'} width={isMobile ? '50%' : '70%'} justifyContent={'flex-start'}  sx={{ml: isMobile ? '6px' : '12px', mr: isMobile ?'6px' :  '12px'}}>
                                            <Typography variant={'body2'} sx={{color: colors.text.dark}}>{`${recipient.firstName} ${recipient.lastName}`}</Typography>
                                            <Typography variant={'body2'} sx={{color: colors.text.grey_dark}}>{recipient.email}</Typography>
                                        </Box>

                                    </Box>
                                    <Divider variant={'fullWidth'} textAlign={'center'} sx={{color: colors.stroke.grey, mt: '12px', mb: '12px', width: '100%'}} />
                                </Box>
                                :
                                <Box key={recipient.email} display={'flex'} flexDirection={'column'} alignItems={'flex-start'} width={'100%'} >
                                    <Typography variant={'body2'} sx={{color: colors.text.grey_dark,  m: '6px 12px'}}>{recipient.email}</Typography>
                                    <Divider variant={'fullWidth'} textAlign={'center'} sx={{color: colors.stroke.grey, mt: '12px', mb: '12px', width: '100%'}} />
                                </Box>
                        ))}
                    </Box>
                }
            </DialogContent>
            <DialogActions>
                <Button sx={{textTransform: 'none', ml: '6px', mr: '6px'}} onClick={handleClose} size={'medium'} variant={'text'}>{t('Close')}</Button>
                <Button disabled={isLoadingData || !conflict} sx={{textTransform: 'none'}} onClick={handleGoToRuleExact}>{t('View rule')}</Button>
            </DialogActions>
        </Dialog>
    )
}
