import {IconButton, styled} from "@mui/material";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import SuperscriptIcon from "@mui/icons-material/Superscript";
import SubscriptIcon from "@mui/icons-material/Subscript";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";

interface DecorIconButtonProps {
    active?: boolean;
}

interface DecorMenuIconProps {
    active?: boolean;
}

interface DecorMenuItemTitleProps {
    active?: boolean;
}

interface DecorFormatColorTextIconProps{
    btnColor?: string;
}

export const DecorIconButton = styled(IconButton, {shouldForwardProp: (propName) => (
        !['active'].includes(propName.toString())
    )})<DecorIconButtonProps>`
  border-radius: 2px !important;
  background-color: ${props => props.active ? 'rgba(0, 0, 0, 0.1) !important' : 'transparent'};
  margin-right: 5px !important;
`;

export const DecorStrikethroughSIcon = styled(StrikethroughSIcon, {shouldForwardProp: (propName) => (
    !['active'].includes(propName.toString())
    )})<DecorMenuIconProps>`
  color: ${props => props.active ? '#47B972 !important' : '#707070 !important'};
`;

export const DecorSuperscriptIcon = styled(SuperscriptIcon, {shouldForwardProp: (propName) => (
    !['active'].includes(propName.toString())
    )})<DecorMenuIconProps>`
  color: ${props => props.active ? '#47B972 !important' : '#707070 !important'};
`;

export const DecorSubscriptIcon = styled(SubscriptIcon, {shouldForwardProp: (propName) => (
    !['active'].includes(propName.toString())
    )})<DecorMenuIconProps>`
  color: ${props => props.active ? '#47B972 !important' : '#707070 !important'};
`;

export const MenuItemTitle = styled('span', {shouldForwardProp: (propName) => (
        !['active'].includes(propName.toString())
    )})<DecorMenuItemTitleProps>`
  color: ${props => props.active ? '#47B972' : '#707070 !important'};
  margin-left: 10px;
`;

export const DecorFormatColorTextIcon = styled(FormatColorTextIcon, {shouldForwardProp: propName => (
    !['btnColor', 'active'].includes(propName.toString())
    )})<DecorFormatColorTextIconProps>`
  ${props => props.btnColor ? `color: ${props.btnColor} !important` : ''};
`;
