import {useDispatch, useSelector} from "react-redux";
import {
    hideChangeWorkspaceNameDialog, selectedOrganization,
    selectedWorkspace, updateWorkspaceName,
    workspacesDialogs,
    workspacesLoadings
} from "../../../../store/slice";
import {useForm} from "../../../../../../../newShared/hooks/useForm";
import {useEffect} from "react";
import {UpdateWorkspaceName} from "../../../../store/actions";
import {UseManageWorkspacesAndOrganizations} from "../../../../../cookies/hooks/useManageWorkspacesAndOrganizations";
import {
    setSharedWorkspacesAndOrganizationsData
} from "../../../../../cookies/store/sharedWorkspacesAndOrganizations/slice";

export const useChangeWorkspaceNameDialog = () => {
    //root
    const {currData} = UseManageWorkspacesAndOrganizations();
    const dispatch = useDispatch();
    const selected = useSelector(selectedWorkspace);
    const selectedOrg = useSelector(selectedOrganization);
    const isOpen = useSelector(workspacesDialogs).changeName;
    const isLoading = useSelector(workspacesLoadings).changeName;

    //form
    const {form, setForm, handleChange} = useForm<{name: string}>({name: ''});

    const cleanUp = () => {
        setForm({name: ''});
    }

    //actions
    const handleClose = () => {
        dispatch(hideChangeWorkspaceNameDialog());
        cleanUp();
    }

    const handleSubmit = () => {
        if(isOk && selected){
            dispatch(UpdateWorkspaceName({
                newName: form.name,
                workspaceId: selected.id,
                onSuccess
            }))
        }
    }

    const onSuccess = () => {
        if(currData.selectedWorkspace && currData.selectedOrganization && selectedOrg && selected){
            const copyData = {...currData, selectedWorkspace: {...currData.selectedWorkspace, name: form.name}};
            dispatch(setSharedWorkspacesAndOrganizationsData(copyData));
            dispatch(updateWorkspaceName({workspaceId: selected.id, workspaceName: form.name, organizationId: selectedOrg.id}));
        }
    }

    // const onSuccess = () => {
    //     cleanUp();
    // }

    useEffect(() => {
        if(isOpen && selected){
            setForm({name: selected.name});
        }
        //eslint-disable-next-line
    }, [isOpen]);

    //
    const isOk = form.name.trim().toLowerCase() !== selectedWorkspace.name.trim().toLowerCase() && form.name.trim().length > 0;

    return{
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        name: form.name,
        handleChange,
        isOk,
    }
}