import {useDispatch, useSelector} from "react-redux";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

import {
  DelegatedTaskModel,
  FrameworksDelegatedTasksCollaboratorModel,
  FrameworksDelegatedTaskStatus,
} from "../../../../../newShared/GQLTypes";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {CommonFilterType, minMaxLoadedPageType,} from "../../../../../newShared/components/genericTable/types";
import {
  eraseDelegatedTasks,
  frameworkControlDelegatedTasks,
  frameworkControlEvidencesSelector,
  replaceDelegatedTasksMinMaxLoadedPage
} from "../../store/control/store";
import {DelegatedTaskStatus} from "../../components/delegatedTaskStatus";
import {mainFrameworksGetDelegatedTasksWithFilterPaginationAction} from "../../store/control/actions";
import {useHistory} from "react-router";
import {PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED} from "../../../../../newShared/constants";
import {useFrameworksPathParams} from "../useFrameworksPathParams";
import {PATH_FRAGMENT_DELEGATED_TASKS} from "../../constants";
import {
  mainFrameworksGetDelegatedTaskPerformerAutocompleteApi,
  mainFrameworksGetDelegatedTaskRequestorAutocompleteApi,
  mainFrameworksGetDelegatedTaskResolverAutocompleteApi
} from "../../api";
import {TEvidence} from "../../types";
//import {mainHrGetTimeOffRequestsWithFilterPaginationAction} from "../../../employeesRefactor/store/actions";
//import {GENERIC_FILTER_OPTIONS_DEFAULT_PAGING} from "../../../../../newShared/components/genericFilter/constants";
import {
  useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";

export const useDelegatedTasksPage = () => {
  const {t} = useMainTranslation("", {keyPrefix: "pathFrameworks.pathDelegatedTasks"});

  const dispatch = useDispatch();
  const { tasks, pageInfo, minMaxLoadedPage, isLoadingList } = useSelector(frameworkControlDelegatedTasks);
  const {frId, controlId} = useFrameworksPathParams();
  const history = useHistory();
  const { minLoadedPage, maxLoadedPage } = minMaxLoadedPage;
  const {evidences} = useSelector(frameworkControlEvidencesSelector);


  const handleOpenTask = (selected: DelegatedTaskModel) => {
    selected && history.push(`${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/${frId}/control/${controlId}/${PATH_FRAGMENT_DELEGATED_TASKS}/${selected.id}`);
  };


  //filters
  const evidenceConfig: keyConfig<TEvidence> = {
    type: "filter",
    key: "evidence",
    name: t("Evidence"),
    options: evidences ?? [],
    getOptionLabel: (a) => a.name,
    getFilterValue: (a) => a.id,
    isOptionEqualToSearch: (a, search) => a.name.trim().toLowerCase().includes(search.trim().toLowerCase()),
    default: true,
  };

  const statusConfig: keyConfig<FrameworksDelegatedTaskStatus> = {
    type: "filter",
    key: "status",
    name: t("Status"),
    options: Object.values(FrameworksDelegatedTaskStatus),
    OptionRenderer: (a) => DelegatedTaskStatus({status: a, margin: '8px 0 0 0'}),
    default: true,
  };

  const performerConfig: keyConfig<FrameworksDelegatedTasksCollaboratorModel> = {
    type: "filter",
    key: "performer",
    name: t("Performer"),
    fetchOptions: (workspaceId, {page, count, search}) => mainFrameworksGetDelegatedTaskPerformerAutocompleteApi({workspaceId, controlId: controlId ?? "", pageRequest: {page, count}, search})
        .then(({result, pageInfo}) => ({values: result, pageInfo})),
    getOptionLabel: (a) => a.fullName,
    getFilterValue: (a) => a.collaboratorId,
    isOptionEqual: (a, b) => a.collaboratorId === b.collaboratorId,
    isOptionEqualToSearch: (a, search) => a.fullName.trim().toLowerCase().includes(search.trim().toLowerCase()),
    default: false,
  };

  const resolverConfig: keyConfig<FrameworksDelegatedTasksCollaboratorModel> = {
    type: "filter",
    key: "resolver",
    name: t("Resolver"),
    fetchOptions: (workspaceId, {page, count, search}) => mainFrameworksGetDelegatedTaskResolverAutocompleteApi({workspaceId, controlId: controlId ?? "", pageRequest: {page, count}, search})
        .then(({result, pageInfo}) => ({values: result, pageInfo})),
    getOptionLabel: (a) => a.fullName,
    getFilterValue: (a) => a.collaboratorId,
    isOptionEqual: (a, b) => a.collaboratorId === b.collaboratorId,
    isOptionEqualToSearch: (a, search) => a.fullName.trim().toLowerCase().includes(search.trim().toLowerCase()),
    default: false,
  };

  const requestorConfig: keyConfig<FrameworksDelegatedTasksCollaboratorModel> = {
    type: "filter",
    key: "requestor",
    name: t("Requestor"),
    fetchOptions: (workspaceId, {page, count, search}) => mainFrameworksGetDelegatedTaskRequestorAutocompleteApi({workspaceId, controlId: controlId ?? "", pageRequest: {page, count}, search})
        .then(({result, pageInfo}) => ({values: result, pageInfo})),
    getOptionLabel: (a) => a.fullName,
    getFilterValue: (a) => a.collaboratorId,
    isOptionEqual: (a, b) => a.collaboratorId === b.collaboratorId,
    isOptionEqualToSearch: (a, search) =>
        a.fullName.trim().toLowerCase().includes(search.trim().toLowerCase()),
    default: true,
  };

  const startDateConfig: keyConfig<string> = {
    type: "date",
    key: "createdDate",
    name: t("Created date"),
    default: true,
  };

  const performDateConfig: keyConfig<string> = {
    type: "date",
    key: "performDate",
    name: t("Performed date"),
    default: true,
  };

  const resolveDateConfig: keyConfig<string> = {
    type: "date",
    key: "resolveDate",
    name: t("Resolved date"),
    default: true,
  };

  const {currentFiltersForFetch, currentSearchForFetch, getCurrentPage} = useGenericFiltersStorage();
  const refreshTable = () => {
    dispatch(mainFrameworksGetDelegatedTasksWithFilterPaginationAction({data: {controlId: controlId ?? '', pageRequest: { page: getCurrentPage(), count: pageInfo.count }, filter: { ...currentFiltersForFetch, taskIdLike: currentSearchForFetch }}, clean: true}));
  }


  return {
    tasks,
    handleOpenTask,
    refreshTable,

    genericTable: {
      paging: {
        pageInfo,
        isLoading: isLoadingList,
        minLoadedPage,
        maxLoadedPage,
        setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceDelegatedTasksMinMaxLoadedPage(data)),
        clearRows: () => dispatch(eraseDelegatedTasks()),
        fetchPaging: (page: number, count: number, filters: CommonFilterType, ) => {
          dispatch(mainFrameworksGetDelegatedTasksWithFilterPaginationAction({data: {controlId: controlId ?? '', pageRequest: { page, count }, filter: filters}}));
        },
      },
      filters: {
        configs: [
          evidenceConfig,
          requestorConfig,
          startDateConfig,
          performerConfig,
          performDateConfig,
          resolverConfig,
          resolveDateConfig,
          statusConfig,
        ],
      },
    },
  };
};
