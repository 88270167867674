import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {CommonDialog, NameSpan} from "../styled";
import {useSelector} from "react-redux";
import {dialogs, hideCreateException, loadings, screeningsSelector} from "../../../store/slice";
import {useAppDispatch} from "../../../../../../newShared/redux";
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {useEffect, useState} from "react";
import {TypographyBody1} from "../../../../../../newShared/components/Inputs/styled";
import {RowBox} from "../../../../../../newShared/components/style";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {ExceptionReasonTextField, ScoreLimitTextField, TextDialog} from "./style";
import {CreateTargetException} from "../../../store/actions";


export const CreateExceptionDialog = () => {

    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.dialogs'});
    const dispatch = useAppDispatch();

    const {isOpen, entityId, entityCaption} = useSelector(dialogs).createException;
    const {selected, selectedScreeningResults} = useSelector(screeningsSelector);
    const isLoading = useSelector(loadings).createException;
    const [score, setScore] = useState<number>(0);
    const [reason, setReason] = useState<string>('');



    const handleSave = () => {
        (selected && entityId && entityCaption) &&  dispatch(CreateTargetException({
            data: {
                targetId: selected.screening.targetId,
                checkId: selected.screening.checkId,
                screeningId: selected.screening.id,
                entityId,
                entityCaption,
                score: score,
                reason,
                limited: false,
                till: null,
            }}));
    }



    const handleClose = () => {
        dispatch(hideCreateException());
    }

    useEffect(() => {
        if (isOpen && selected) {
            setScore(selectedScreeningResults.find(res => res.entityId === entityId)?.score ?? 0.50);
            setReason('');

        }
        //eslint-disable-next-line
    }, [isOpen]);

    return (
        <CommonDialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Create resolution')}</DialogTitle>
            <DialogContent>
                <TypographyBody1>{`${t("Client")}: `}<NameSpan>{selected?.screening.targetName ?? ''}</NameSpan></TypographyBody1>
                <TextDialog>{`${t("Resolution for")}: `}<NameSpan>{entityCaption}</NameSpan></TextDialog>
                <RowBox>
                    <TypographyBody1>{`${t("Resolution score limit")}: `}</TypographyBody1>
                    <ScoreLimitTextField value={parseFloat(score.toString().substring(0, 4))} onChange={e => setScore(Number(e.target.value))} />
                </RowBox>
                <TextDialog>{`${t("Please fill resolution reason")}: `}</TextDialog>
                <ExceptionReasonTextField label={t('Comment for resolution')} value={reason} onChange={(e) => setReason(e.target.value)} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={'text'} sx={{textTransform: 'none'}}>{t('Cancel')}</Button>
                <LoadingButton onClick={handleSave} loading={isLoading} loadingPosition={isLoading ? 'end' : undefined}>
                    {tCommon('Save')}
                </LoadingButton>
            </DialogActions>
        </CommonDialog>
    )
}
