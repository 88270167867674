import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {DialogContentWrapper, TextFieldCustom} from "../../../../styled";
import React from "react";
import {LoadingButton} from "../../../../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useChangeWorkspaceNameDialog} from "../../../../../hooks/organizations/dialogs/useChangeWorkspaceNameDialog";

export const ChangeWorkspaceName = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathSettings.dialogs'});
    const {
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        name,
        handleChange,
        isOk,
    } = useChangeWorkspaceNameDialog();

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Change workspace name')}</DialogTitle>
            <DialogContent>
                <DialogContentWrapper>
                    <TextFieldCustom
                        required
                        value={name}
                        name={'name'}
                        onChange={handleChange}
                        label={t('Workspace name')}
                        size={'small'}
                        sx={{margin: '8px 0 0 0'}}
                    />
                </DialogContentWrapper>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                    disabled={!isOk}
                >
                    {t('Change')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
