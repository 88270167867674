import {FC} from 'react';
import {LeftSecondMenu} from "../../barsEnvironment/leftMenu/components/leftSecondMenu";
import {Routes} from "./routes";
import {useMain} from "./hooks/useMain";

export const Vendors:FC = () =>{
    useMain();
    return (
        <LeftSecondMenu>
            <Routes/>
        </LeftSecondMenu>
    )
}