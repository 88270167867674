import {gql} from "@apollo/react-hooks";


export const mainTrainingModelFragment = gql`
    fragment mainTrainingModelFragment on MainTrainingModel {
        id
        trainingId
        workspaceId
        name
        description
        category
        level
        limitMaxDuration
        limitMinDuration
        createdDate
        updatedDate
        version
        latest
        status
        coverImage {
            imageName
            type
            image
            thumbnail
            fileType
        }
        exam {
            examId
            name
            version
        }
        creator {
            email
            collaboratorId
            fullName
        }
        editor {
            email
            collaboratorId
            fullName
        }
        content {
            sectionId
                name
                type
                order
                
                data {
                    innerHtml
                    url
                    name
                    fileId
                    thumbnail
                    url
                    innerHtml
                }
            
        }

    }
`

export const mainTrainingExamModelFragment = gql`
    fragment mainTrainingExamModelFragment on MainTrainingExamModel {
        id
        examId
        workspaceId
        name
        description
        category
        level
        timeToPass
        totalPoints
        examPassingPoints
        coverImage {
            imageName
            type
            image
            thumbnail
            fileType
        }
        questions {
            questionId
            question
            description
            type
            points
            pointsAutoCount
            order
            options {
                option
                correct
                points
                order
            }
        }
        version
        latest
        createdDate
        updatedDate
        status
        creator {
            email
            collaboratorId
            fullName
        }
        editor {
            email
            collaboratorId
            fullName
        }
    }
`

export const mainTrainingAssignmentModelFragment = gql`
    fragment mainTrainingAssignmentModelFragment on MainTrainingAssignmentModel {
        id
        workspaceId
        recipient {
            collaboratorId
            fullName
            email
        }
        requestor {
            collaboratorId
            fullName
            email
        }
        requestDate
        startDate
        endDate
        actualStartDate
        completeDate
        canceledInfo {
            collaboratorId
            fullName
            email
            reason
        }
        status
        training {
            trainingVersionId
            trainingName
            trainingVersion
            trainingCategory
            trainingLevel
            trainingDescription
            trainingCover {
                imageName
                type
                image
                thumbnail
                fileType
            }
            status
            limitMaxDuration
            limitMinDuration
            content {
                sectionId
                sectionName
                type
                status
                order
                data {
                    name
                    fileId
                    thumbnail
                    innerHtml
                    url 
                }
            }
            startDate
            completeDate
        }
        postExam {
            examVersionId
            examName
            examDescription
            examVersion
            examCategory
            examLevel
            examCover {
                imageName
                type
                image
                thumbnail
                fileType
            }
            status
            timeToPass
            examPassingPoints
            totalPoints
            startDate
            completeDate
            totalQuestions
            questions {
                questionId
                question
                description
                type
                order
                points
                options {
                    option
                    correct
                    points
                    order
                }
                status
                answerDate
                answer
                validation {
                    type
                    collaboratorId
                    fullName
                    email
                    correctAnswer
                    points
                    validationDate
                }
            }
        }
    }
`



export const sectionsShortDataFragment = gql`
    fragment sectionsShortDataFragment on MainTrainingsSectionsShortDataForImportResponseModel {
        trainingCategory
        trainingId
        trainingLevel
        trainingName
        trainingStatus
        updatedDate
        sections {
            order
            sectionId
            sectionName
            sectionType
        }
        
    }
`;

export const sectionsFullDataFragment = gql`
    fragment sectionsFullDataFragment on MainTrainingsSectionsFullDataForImportModel {
        name 
        type 
        data {
            name
            fileId
            thumbnail
            url
            innerHtml
        }
    }
`;

export const questionsShortDataFragment = gql`
    fragment questionsShortDataFragment on MainTrainingsQuestionsShortDataForImportResponseModel {
        examCategory
        examId
        examLevel
        examName
        examStatus
        updatedDate
        questions {
            order
            questionId
            questionName
            questionType
        }

    }
`;

export const questionsFullDataFragment = gql`
    fragment questionsFullDataFragment on MainTrainingsQuestionsFullDataForImportModel {
        question
        description
        type
        points
        pointsAutoCount
        order
        options {
            option
            correct
            points
            order
        }
    }
`;

export const mainTrainingTemplateModelFragment = gql`
    fragment mainTrainingTemplateModelFragment on MainTrainingTemplateModel {
        id
        name
        description
        category
        level
        limitMaxDuration
        limitMinDuration
        coverImage {
            imageName
            type
            image
            thumbnail
            fileType
        }
        exam {
            examId
            name
        }
        content {
            sectionId
            name
            type
            order

            data {
                innerHtml
                url
                name
                fileId
                thumbnail
                url
                innerHtml
            }

        }

    }
`



