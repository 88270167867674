import axiosSync from "../../../utils/axios/axios";
import {PATH_SERVER} from "../../../constants";
import {print} from "graphql";
import {buildCommonHeader} from "../../../utils/builders";
import {AxiosResponse} from "axios";
import {gql} from "graphql.macro";

export const downloadFileApi = async (data: {id: string}, signal?: AbortSignal): Promise<{file: string}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query downloadFileTiny($workspaceId: String!, $id: String!){
                        downloadFile(id: $id, workspaceId: $workspaceId) {
                            file
                        }
                    }
                `),
                variables: {...data, workspaceId: ''}
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.downloadFile
    })
)

export const uploadFileApi = async (data: {file: string}, signal?: AbortSignal): Promise<{message: string}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation uploadFileTiny($workspaceId: String!, $file: String!){
                        uploadFile(file: $file, workspaceId: $workspaceId) {
                            message
                        }
                    }
                `),
                variables: {...data, workspaceId: ''}
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.uploadFile
    })
)
