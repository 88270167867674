import React from "react";
import {PageWrapper} from "../../../../../newShared/components/style";
import {useExamsListPage} from "../../hooks/useExamsListPage";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {MainTrainingExamModel} from "../../../../../newShared/GQLTypes";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {examLevelTitleMapper} from "../../constants";
import {getTrainingExamStatusChip} from "../../helpers";
import {Add} from "@mui/icons-material";
import {Flex} from "../../../../../newShared/components/Layouts";
import {CoverImageView} from "../../components/trainingCoverComponent/CoverImageView";
import {Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";


export const ExamsList = () => {

    const {t} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.pathExams'});
    const {isMobile} = useMedia();

    const {
        exams,
        handleOpenExam, handleOpenCreateExam,
        genericTable,
    } = useExamsListPage();

    return (
        <PageWrapper>
            <PageTitleAndActions title={t('Exams')} actions={[
                {title: t('New exam'), onClick: handleOpenCreateExam, icon: <Add/>, size: "small"},
            ]} margin={'0 0 8px 0'} />

            <GenericTable<MainTrainingExamModel>
                id={'trainingExams'}
                columnsConfig={{totalName: 'Total exams', disableColumnsSettings: true, forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: exams,
                    customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id,
                    onRowClick: handleOpenExam,

                    columnsConfigs: [
                        {key: 'name', name: t('Exam name'), default: true, valueRenderer: row => <Flex ai={'center'} gap={'32px'}>
                                {row.coverImage && <CoverImageView cover={row.coverImage} size={'list'}/>}
                                <Typography variant={'*bodyText2'} color={colors.grayText}>{row.name}</Typography>
                            </Flex>},
                        {key: 'category', name: t('Category'), default: true},
                        {key: 'level', name: t('Level'), default: true, valueGetter: (row) => examLevelTitleMapper[row.level]},
                        {key: 'status', name: t('Status'), default: true, valueRenderer: (row) => getTrainingExamStatusChip(row.status)},
                        {key: 'createdDate', name: t('Created date'), default: true, valueGetter: (row) => parseDateAuto(row.createdDate)},
                        {key: 'updatedDate', name: t('Last update'), default: true, valueGetter: (row) => parseDateAuto(row.updatedDate)},
                    ],
                }}
                filtersConfig={{
                    searchCustomFilterKeyName: 'examNameLike',
                    // searchAsArray: true,
                    dontSendSearchIfEmpty: true,
                    genericFilterProps: {
                        configs: genericTable.filters.configs,
                        fetchResultWithSelectedFilters: () => null,
                        isAddFilters: false
                    }
                }}
                emptyArrayImageProps={{type: 'any', filterNotApplied: {text: t('You do not have any exams yet'), secondaryText: ''}}}
            />
        </PageWrapper>
    )
}
