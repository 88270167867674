import {useModelsList} from "../../../hooks/useModelsList";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../../../../newShared/components/Layouts";
import colors from "../../../../../../newShared/theme/colors";
import {Typography} from "@mui/material";
import React from "react";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";
import {GenericTable} from "../../../../../../newShared/components/genericTable";
import {TRiskModel} from "../../../types";
import {getEmptyPaging} from "../../../../../../newShared/components/genericTable/helpers";

export const ModelsList = () => {
    const {
        models,
        goToModel,
        goToCreateModel,
        isLoading,
        filterValues
    } = useModelsList();
    const {t} = useMainTranslation('', {keyPrefix: 'risks'});

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('Models')}
                actions={[
                    {
                        title: t('Create new model'),
                        onClick: goToCreateModel,
                        size: 'small'
                    }
                ]}
            />

            <Typography variant={'body2'} color={colors.text.grey_dark} sx={{margin: '20px 0 0 0'}}>{t('You can use the Basic model we have already created or create your own using our convenient constructor.')}</Typography>

            {/*<S.FilterContainer>*/}
            {/*    <CustomSearchField*/}
            {/*        variant={'outlined'}*/}
            {/*        onChange={handleChange}*/}
            {/*        value={search}*/}
            {/*        onClear={handleCleanSearch}*/}
            {/*        disabled={isLoading}*/}
            {/*        onKeyDown={handleEnterKey}*/}
            {/*        size="small"*/}
            {/*        fullWidth*/}
            {/*        name={'search'}*/}
            {/*        label={t('Search')}*/}
            {/*        dir={'ltr'}*/}
            {/*        placeholder={t('Search')}*/}
            {/*        // sx={{width: isTablet ? '100%' : '25%'}}*/}
            {/*    />*/}
            {/*</S.FilterContainer>*/}


            <GenericTable<TRiskModel>
                disableSkeletons
                id={'ModelsList'}
                columnsConfig={{totalName: 'Total models', disableColumnsSettings: true}}
                paging={getEmptyPaging(models.length, isLoading)}
                rowsConfig={{
                    rows: models ?? [],
                    customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id,
                    onRowClick: goToModel,
                    columnsConfigs: [
                        {key: "name", name: t('Title'), default: true},
                        {key: 'counter', name: t('Used count'), default: true},
                    ],
                }}
                filtersConfig={{
                    genericFilterProps: {
                        configs: [],
                        isAddFilters: false,
                        fetchResultWithSelectedFilters: (search) => filterValues(search),
                    }
                }}
            />


            {/*{isShowNoResult && <Typography color={colors.darkBlueText}>{t('Nothing was found for your query. Try changing the query.')}</Typography>}*/}
            {/*{models && models.map(e => <ModelTableRow key={e.id} model={e} onClick={goToModel} isLoading={isLoading}/>)}*/}
            {/*{isLoading && <ModelTableRowSkeleton />}*/}

        </Flex>
    )
}