import {FC} from "react";
import {useDrag} from "react-dnd";
import {DragIndicator, SvgIconComponent} from "@mui/icons-material";
import {TEditorDragElementData} from "../../types";
import {EditorDragCreateBlockItemStyled} from "./styled";
import colors from "../../../../theme/colors";

interface Props {
    type: string,
    icon: SvgIconComponent,
    title: string,
}
export const EditorDragCreateBlockItem: FC<Props> = ({type, icon: Icon, title}) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: type,
        item: {type: type} satisfies TEditorDragElementData,
        collect: (monitor) => {
            return {
                isDragging: !!monitor.isDragging(),
            }
        }
    }))

    return (
        <EditorDragCreateBlockItemStyled ref={drag} isDragging={isDragging}>
            <DragIndicator/>
            <span>{title}</span>
            <Icon style={{fill: colors.text.grey, marginLeft: 'auto'}}/>
        </EditorDragCreateBlockItemStyled>
    )
}