import {TargetScreeningsTableContainer} from "../../styled/index";
import {Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import colors from "../../../../../../newShared/theme/colors";
import React from "react";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {KycHistoryLogModel} from "../../../../../../newShared/GQLTypes";
import {useSelector} from "react-redux";
import {clientsSelector, loadings} from "../../../store/slice";
import {getTargetHistoryNormalizedAction} from "../../../helpers";

export const TargetHistoryTable = () => {
    const {selectedClientHistory} = useSelector(clientsSelector);
    const {getExactTargetHistory} = useSelector(loadings);

    return(
        <TargetScreeningsTableContainer>
            <Table stickyHeader>
                <TargetHistoryTableHeader />
                <TableBody>
                    {selectedClientHistory.map(e => <TargetHistoryTableRow history={e} key={e.id}/>)}
                    {(getExactTargetHistory) && <TargetHistoryTableRowSkeleton />}
                </TableBody>
            </Table>
        </TargetScreeningsTableContainer>
    )
}

export const TargetHistoryTableHeader = () => {
    const {revDir} = useMainTranslation();
    return(
        <TableHead>
            <TableRow sx={{
                '& .MuiTableCell-root': {
                    padding: '16px 8px',
                    backgroundColor: colors.backgrounds.grey_light
                }
            }}>
                <TableCell width={'20%'} align={!revDir ? 'left' : 'right'}>
                    <Typography variant={'body2'} color={colors.text.grey_dark}>{('Action')}</Typography>
                </TableCell>

                <TableCell width={'20%'} align={!revDir ? 'left' : 'right'}>
                    <Typography variant={'body2'} color={colors.text.grey_dark}>{('Date')}</Typography>
                </TableCell>

                <TableCell width={'20%'} align={!revDir ? 'left' : 'right'}>
                    <Typography variant={'body2'} color={colors.text.grey_dark}>{('Comment')}</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
}

export const TargetHistoryTableRow = ({history}: {history: KycHistoryLogModel}) => {
    // const value = getTargetHistoryValue(history.action, history.value);

    return(
        <>
            <TableRow sx={{'& .MuiTableCell-root': {padding: '16px 8px'}}}>
                <TableCell>
                    <Typography variant={'body2'} color={colors.grayText}>{getTargetHistoryNormalizedAction(history.action)}</Typography>
                </TableCell>

                <TableCell>
                    <Typography variant={'body2'} color={colors.grayText}>{parseDateAuto(history.date, true)}</Typography>
                </TableCell>

                <TableCell>
                    <Typography variant={'body2'} color={colors.grayText}>{(history.value?.length ?? 0) > 0 ? history.value : '-'}</Typography>
                    {/*<Flex direction={'column'}>*/}
                    {/*    {value.map(e => (<Typography key={e} variant={'body2'} color={colors.grayText}>{e ?? '-'}</Typography>))}*/}
                    {/*</Flex>*/}
                </TableCell>
            </TableRow>
            {/*<ContainerDivider />*/}
        </>
    )
}

export const TargetHistoryTableRowSkeleton = () => {
    return(
        <>
            <TableRow hover sx={{'& .MuiTableCell-root': {padding: '16px 8px'}}}>
                <TableCell>
                    <Typography variant={'body2'} color={colors.grayText}><Skeleton width={100}/></Typography>
                </TableCell>

                <TableCell>
                    <Typography variant={'body2'} color={colors.grayText}><Skeleton width={100}/></Typography>
                </TableCell>

                <TableCell>
                    <Typography variant={'body2'} color={colors.grayText}><Skeleton width={100}/></Typography>
                </TableCell>
            </TableRow>
            {/*<ContainerDivider />*/}
        </>
    )
}