import {EditIntegrationCommonPropsType} from "../../../../types";
import {Flex} from "../../../../../../../newShared/components/Layouts";
import * as S from '../../../styled';
import React from "react";
import {LoadingButton} from "../../../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useEditGithub} from "../../../../hooks/integrationsManagement/editIntegrationComponents/github";
import {Button, Icon} from "@mui/material";
import {useDispatch} from "react-redux";
import {useOAuth2} from "../../../../../../../newShared/components/oauth";
import {GITHUB_CLIENTID, GITHUB_REDIRECT_URI, GITHUB_SCOPE, GITHUB_URL} from "../../../../constants";
import {addErrorSnack} from "../../../../../../barsEnvironment/snack/store/slice";

export const EditIntegrationGithub = (props: EditIntegrationCommonPropsType) => {
    const {
        form,
        actions,
        isOk,
        isLoading
    } = useEditGithub(props);
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'pathIntegrations.AvailableAppsList.dialogs'});

    const dispatch = useDispatch();
    const github = useOAuth2<{code: string, state: string}>({
        authorizeUrl: GITHUB_URL,
        clientId: GITHUB_CLIENTID,
        redirectUri: GITHUB_REDIRECT_URI,
        scope: GITHUB_SCOPE,
        responseType: "code",
        exchangeCodeForTokenServerURL: "",
        // exchangeCodeForTokenMethod: "POST",
        // extraQueryParameters:{
        //     audience: GITHUB_URL,
        //     prompt:"consent"
        // },

        onSuccess: (res) => {
            actions.setForm(prev => ({...prev, integration: {...prev.integration, code: res.code}}));
        },
        onError: (error_) => dispatch(addErrorSnack(`Error while connecting to github: ${error_}`))
    });

    return(
        <S.AppConfigContainer>
            <S.TextFieldCustom
                variant={'outlined'}
                name={'name'}
                label={t('Application name')}
                value={form.name}
                onChange={actions.handleChange}
                size={'small'}
            />

            <S.TextFieldCustom
                variant={'outlined'}
                name={'organizationName'}
                label={t('Organization name')}
                disabled
                value={form.integration.organizationName}
                onChange={actions.handleChangeIntegration}
                sx={{margin: '16px 0 0 0'}}
                size={'small'}
            />

            <Button variant={"outlined"} style={{margin: '16px 0 0 0'}} color={form.integration.code.trim().length > 0 ? 'success' : undefined}
                    startIcon={<Icon><img src={'https://cdn.svgporn.com/logos/github-icon.svg?response-content-disposition=attachment%3Bfilename%3Dgithub-icon.svg'} alt={'github icon'} style={{maxHeight: '100%', maxWidth: '100%'}}/></Icon>}
                    onClick={() => github.getAuth()}>
                {t('Sign in with Github')}
            </Button>

            <Flex w={'100%'} jc={'flex-end'} m={'16px 0 0 0'}>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={actions.handleSubmit}
                    disabled={!isOk}
                >
                    {t('Save')}
                </LoadingButton>
            </Flex>
        </S.AppConfigContainer>
    )
}