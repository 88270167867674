import {useExamResultDialog} from "../../../hooks/dialogHooks/useExamResultDialog";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {getAssignmentTrainingAndExamStatusChip} from "../../../helpers";
import colors from "../../../../../../newShared/theme/colors";
import Divider from "@mui/material/Divider";
import {IconTextButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import TableRowsIcon from "@mui/icons-material/TableRows";
import {ExamResultQuestionBlock} from "./components/examResultQuestionBlock";

export const ExamResultDialog = () => {
    const {tCommon} = useMainTranslation();
    const {isOpen, handleClose, assignment, examPoints, questions} = useExamResultDialog();

    return(
        <Dialog open={isOpen} onClose={handleClose} sx={{
            "& .MuiPaper-root": {
                width: "90%",
                maxWidth: "1000px",
            },
        }}>
            <DialogTitle>{('Exam results')}</DialogTitle>

            <DialogContent>
                {assignment && assignment.postExam && assignment.postExam.questions &&
                    <>
                        <Flex w={'100%'} gap={'20px'} ai={'center'}>
                            {getAssignmentTrainingAndExamStatusChip({assignmentStatus: assignment.status, trainingStatus: assignment.training?.status, examStatus: assignment.postExam.status }).examChip}
                            <Typography variant={'*h3'} color={colors.grayText}>{assignment.postExam.examName}</Typography>
                        </Flex>

                        <Divider sx={{margin: '20px 0'}}/>

                        <Flex w={'100%'} gap={'40px'}>
                            <Flex direction={'column'} gap={'6px'}>
                                <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{('Scored points')}</Typography>
                                <Typography variant={'*h3'}>{examPoints.scored}</Typography>
                            </Flex>

                            <Flex direction={'column'} gap={'6px'}>
                                <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{('Passing points')}</Typography>
                                <Typography variant={'*h3'}>{examPoints.passingPoints}</Typography>
                            </Flex>

                            <Flex direction={'column'} gap={'6px'}>
                                <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{('Total points')}</Typography>
                                <Typography variant={'*h3'}>{examPoints.totalPoints}</Typography>
                            </Flex>
                        </Flex>


                        <Flex ai={'center'} m={'16px 0'}>
                            <IconTextButton
                                variant={'text'}
                                icon={<FormatListBulletedIcon />}
                                size={"small"}
                                onClick={questions.collapseAll}
                                disabled={questions.isCollapseAllDisabled}
                            >
                                {('Collapse all')}
                            </IconTextButton>

                            <IconTextButton
                                variant={'text'}
                                icon={<TableRowsIcon />}
                                size={"small"}
                                onClick={questions.expandAll}
                                disabled={questions.isExpandAllDisabled}
                            >
                                {('Expand all')}
                            </IconTextButton>
                        </Flex>

                        <Flex direction={'column'} gap={'10px'}>
                            {assignment.postExam.questions
                                .slice()
                                .sort((a, b) => a.order - b.order)
                                .map((e, id) =>
                                    <ExamResultQuestionBlock
                                        isOpen={questions.isQuestionOpened(e.questionId)}
                                        toggleOpen={questions.toggleQuestion}
                                        question={e}
                                        key={e.questionId}
                                        order={id}
                                    />
                                )}
                        </Flex>
                    </>
                }
            </DialogContent>

            <DialogActions>
                <Button variant={"text"} size={'small'} onClick={handleClose}>{tCommon('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}