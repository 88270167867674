import React, {useCallback, useEffect, useState} from "react";
import {TOpenDialogEventData} from "../../types";
import {DEFAULT_ACTOR, openManageActorsDialogEventName} from "../../constants";
import {EditorSideMenu} from "../../components/editorSideMenu";
import {v4 as uuid} from "uuid";
import {TForm} from "../../components/dialogs/manageActorsDialog/components/staff";
import {getMsFromDays} from "../../../../utils/dateTools";

export const useManageActorsDialogAdmin = (props?: {
    parentRef: React.RefObject<EditorSideMenu>["current"];
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [form, setForm] = useState<TForm>({approvers: [], recipients: []});

    useEffect(() => {
        if (isOpen) {
            const config = props?.parentRef?.getActorsData();

            setForm({
                recipients: config?.recipients.map(r => ({
                    ...r,
                    id: uuid(),
                    sourceEmailRole: r.actor.email + r.role,
                })) ?? [],
                approvers: config?.approvers ?? []
            });
        }
        // eslint-disable-next-line
    }, [isOpen])

    const handleAddRecipient = () => {
        setForm({
            ...form,
            recipients: [
                ...form.recipients,
                {   id: uuid(),
                    fields: null,
                    actor: DEFAULT_ACTOR,
                    order: form.recipients.length + 1,
                    message: '',
                    sourceEmailRole: '',
                    role: '',
                }
            ],
        });

    }

    const handleDeleteRecipient = useCallback((recipientId: string) => {
        setForm({
            ...form,
            recipients: form.recipients.filter((r ) => r.id !== recipientId),
        });
        //eslint-disable-next-line
    }, [form])

    const handleSetRoleRecipient = useCallback((recipientId: string, role: string) => {
        setForm({
            ...form,
            recipients: form.recipients.map((r)=> r.id === recipientId ? ({ ...r, role}) : r),
        });
        //eslint-disable-next-line
    }, [form])

    const handleSetIsOpenByEvent = (ev: Event) => {
        const {detail} = ev as CustomEvent<TOpenDialogEventData>;
        setIsOpen(detail.isOpen);
    }

    const handleClose = () => {
        setIsOpen(false);
    }

    const handleSave = () => {
        props?.parentRef?.setActorsConfig({
            recipients: form.recipients
                .filter(r => r.actor?.email?.length || r.role?.trim().length || r.fields?.length)
                .map((r, index) => ({
                    fields: r.fields,
                    actor: r.actor,
                    order: index + 1,
                    message: r.message,
                    sourceEmailRole: r.sourceEmailRole,
                    role: r.role || '',
                    eta: r.eta ?? getMsFromDays(7),
                })),
            approvers: form.approvers
                .filter(appr => appr.groups.some(gr => gr.actors.some(a => a.email.length > 0)))
                .map(appr => ({
                    id: appr.id?.includes('index') ? null : appr.id ,
                    order: appr.order,
                    eta: appr.eta ?? getMsFromDays(7),
                    groups: appr.groups
                        .map(gr => ({
                            id: gr.id?.includes('index') ? null : gr.id,
                            actors: gr.actors,
                            name: gr.name,
                            isRequired: gr.isRequired,
                            message: gr.message,
                        }))
                })),

        });

        handleClose();
    }

    useEffect(() => {
        document.addEventListener(openManageActorsDialogEventName, handleSetIsOpenByEvent);
        return () => {
            document.removeEventListener(openManageActorsDialogEventName, handleSetIsOpenByEvent);
        }
        //eslint-disable-next-line
    }, []);

    const isDisabledSave = form.recipients.some((value, index, array) =>
        array.filter(e => e.role?.trim() === value.role?.trim() && e.role !== '').length > 1
    );

    return {
        isOpen,
        form,
        setForm,

        handleAddRecipient,
        handleDeleteRecipient,
        handleSetRoleRecipient,
        handleSetIsOpenByEvent,
        handleClose,
        handleSave,
        isDisabledSave,
    }
}
