//core
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

//redux
import {docPolicy, hideDocPolicy, selectedAutomation, setDocument} from "../../../store/slice";

//material
import {Button, Dialog, DialogActions, DialogTitle, IconButton,} from "@mui/material";

//types
import {automationType} from "../../../types";

//components
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {addInfoSnack} from "../../../../../barsEnvironment/snack/store/slice";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {LocalSpinner} from "../../../../../../newShared/components/Spinner";
import {Typo} from "../../../../../../newShared/components/Typography";
import {SelectDocumentMainWrapper, SelectDocumentRightViewWrapper} from "../../styled";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {
    DOCUMENTS_FOLDER_NAME,
    HOVER_FOLDER_COLOR,
    SELECTED_FOLDER_COLOR,
    TRASH_FOLDER_NAME,
    TREE_NESTED_SHIFT_PX
} from "../../../../documentsRefactor/constants";
import {DocumentFile, DocumentFolder} from "../../../../documentsRefactor/types";
import {getFilesByFolderId, getFolderTree} from "../../../../documentsRefactor/api";
import {GetFolderIconByName} from "../../../../documentsRefactor/components/LeftSideTree";

export const DocPolicySelectionDialog = () => {
    const dispatch = useDispatch();
    const {t} = useMainTranslation('', {keyPrefix: 'automations'});
    const {workspaceId} = UseManageWorkspacesAndOrganizations();

    const auto: automationType | null = useSelector(selectedAutomation);
    const {isOpen} = useSelector(docPolicy);

    const [folders, setFolders] = useState<DocumentFolder[]>([]);
    const [files, setFiles] = useState<DocumentFile[]>([]);
    const [expandedFolders, setExpandedFolders] = useState<string[]>([]);
    const [prevOpenedFolder, setPrevOpenedFolder] = useState<DocumentFolder | null>(null);
    const [openedFolder, setOpenedFolder] = useState<DocumentFolder | null>(null);
    const [selectedFiles, setSelectedFiles] = useState<DocumentFile[]>([]);

    const [loading, setLoading] = useState<{folders: boolean, files: boolean}>({folders: false, files: false});


    useEffect(() => {
        if(isOpen && folders.length === 0){
            getFolders();
        }else if(!isOpen){
            setSelectedFiles([]);
            setExpandedFolders([]);
            setFiles([]);

            if(folders.length > 0){
                const docFolder = folders.find(e => e.name === DOCUMENTS_FOLDER_NAME);
                if(docFolder){
                    setOpenedFolder(docFolder);
                    getFiles(docFolder.id);
                }else{
                    setOpenedFolder(folders[0]);
                    getFiles(folders[0].id);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const getFolders = async() => {
        setLoading({...loading, folders: true});
        try{
            const folders = await getFolderTree(workspaceId ?? '');
            setFolders(folders);
            if(folders.length > 0){
                const docFolder = folders.find(e => e.name === DOCUMENTS_FOLDER_NAME);
                if(docFolder){
                    setOpenedFolder(docFolder);
                    getFiles(docFolder.id);
                }else{
                    setOpenedFolder(folders[0]);
                    getFiles(folders[0].id);
                }
            }
            setLoading({...loading, folders: false});
        }catch (ex){
            // console.log(`DocPolicySelectionDialog: getFolderTree: ${ex}`);
            setLoading({...loading, folders: false});
        }
    }

    const getFiles = async(folderId: string) => {
        setLoading({...loading, files: true});
        try{
            const files = await getFilesByFolderId(workspaceId ?? '', folderId);
            setFiles(files);
            setLoading({...loading, files: false});
        }catch (ex){
            // console.log(`DocPolicySelectionDialog: getFiles: ${ex}`);
            setLoading({...loading, files: false});
        }
    }

    const onFolderClick = (folder: DocumentFolder) => {
        // console.log(`onFolderClick: openedFolder: ${JSON.stringify(openedFolder)}`);
        if(folder.name !== 'Documents'){
            setPrevOpenedFolder(openedFolder);
        }else{
            setPrevOpenedFolder(null);
        }
        expandFolder(folder.id);
        setOpenedFolder(folder);
        getFiles(folder.id);
    }

    const expandFolder = (folderId: string) => {
        setExpandedFolders([...expandedFolders, folderId]);
    }

    // const hideFolder = (folderId: string) => {
    //     setExpandedFolders([...expandedFolders.filter(e => e !== folderId)]);
    // }

    const selectFile = (file: DocumentFile) => {
        setSelectedFiles([...selectedFiles, file]);
    }

    const deselectFile = (fileId: string) => {
        setSelectedFiles([...selectedFiles.filter(e => e.id !== fileId)]);
    }

    const goOneFolderBack = () => {
        // console.log(`goOneFolderBack: prevOpenedFolder: ${JSON.stringify(prevOpenedFolder)}`);
        prevOpenedFolder && onFolderClick(prevOpenedFolder);
    }

    const isOk = selectedFiles.length > 0;

    const submit = () => {
        if(isOk){
            selectedFiles.forEach(e => {
                if(!auto?.documents.links.some(l => l.link === e.id)){
                    dispatch(setDocument(
                        {
                            policyId: e.documentId ?? '',
                            fileName: e.name,
                            link: e.id,
                            lastUpdate: new Date(Date.now()).toISOString(),
                        }));
                }else{
                   dispatch(addInfoSnack(t('Document {{docName}} already added', {docName: e.name})));
                }
            });
        }
    }

    // const [policyValue, setPolicyValue] = useState<automationPolicyType | null>(null);
    // const [policyFile, setPolicyFile] = useState<policy | null>(null);
    //
    // const handleSelect = async() => {
    //     if(!policyFile) return;
    //     if(auto?.documents.links.find(e => e.link === policyFile.id) === undefined){ // add .pdf to file
    //         dispatch(setDocument(
    //             {
    //                 policyId: policyValue ? policyValue.id : '',
    //                 fileName: policyFile.name, // add .pdf to file
    //                 link: policyFile.id, //put here id of new document
    //                 lastUpdate: new Date(Date.now()).toISOString(),
    //             }
    //         ));
    //     }else{
    //         dispatch(addInfoSnack(t('Policy File {{policyName}} already added', {policyName: policyFile.name})));
    //     }
    // }

    const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
        if (reason !== 'backdropClick') {
            dispatch(hideDocPolicy());
            setSelectedFiles([]);
            setExpandedFolders([]);
            setOpenedFolder(null);
            setFiles([]);
        }
    };

    return (auto &&

        <div>
            <Dialog open={isOpen} onClose={handleClose} scroll={'paper'} sx={{overflow: 'hidden'}}>
                <DialogTitle>{t('Select document file')}</DialogTitle>

                {/*<DialogContent sx={{overflowx: 'hidden'}}>*/}
                {/*    */}

                {/*</DialogContent>*/}

                <SelectDocumentMainWrapper>
                    {/*<SelectDocumentLeftViewWrapper>*/}
                    {/*    {loading.folders && <LocalSpinner />}*/}
                    {/*    {!loading.folders && folders.filter(e => e.name === DOCUMENTS_FOLDER_NAME).map(f => <Folder*/}
                    {/*        folder={f}*/}
                    {/*        key={f.id}*/}
                    {/*        currPath={[{name: f.name, id: f.id}]}*/}
                    {/*        onExpand={expandFolder}*/}
                    {/*        onHide={hideFolder}*/}
                    {/*        onOpen={onFolderClick}*/}
                    {/*        selectedFolder={openedFolder}*/}
                    {/*        openedFolders={expandedFolders}*/}
                    {/*    />)}*/}
                    {/*</SelectDocumentLeftViewWrapper>*/}

                    <SelectDocumentRightViewWrapper>
                        {!openedFolder && <Flex w={'100%'} jc={'center'} m={'15px 0 0 0'}> <Typo>{t('Open folder')}</Typo></Flex>}
                        {prevOpenedFolder && <FolderRow folder={{id: '', children: [], name: '..', lastEditDate: ''}} openFolder={goOneFolderBack}/>}
                        {openedFolder && openedFolder.children.map(e => <FolderRow key={e.id} folder={e} openFolder={onFolderClick}/>)}
                        {loading.files && <LocalSpinner />}
                        {(!loading.files && !files.length && openedFolder && !openedFolder.children.length) && <Flex w={'100%'} jc={'center'} m={'15px 0 0 0'}> <Typo>{t('No documents found')}</Typo></Flex>}
                        {!loading.files && files.map(e => <FileRow key={e.id} file={e} onSelectFile={selectFile} deselectFile={deselectFile} selectedFiles={selectedFiles}/>)}
                    </SelectDocumentRightViewWrapper>

                </SelectDocumentMainWrapper>

                {selectedFiles.length > 0 && <Flex w={'100%'} jc={'center'}><Typo margin={'10px 0 0 0'}>{t('Selected {{counter}} files.', {counter: selectedFiles.length})}</Typo></Flex>}

                <DialogActions>
                    <Button onClick={handleClose}>{t('Cancel')}</Button>
                    <Button onClick={submit} disabled={!isOk}>{t('Ok')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const Folder = ({folder, onExpand, onHide, onOpen, currPath, selectedFolder, openedFolders}: {
    currPath: {name: string, id: string}[],
    folder: DocumentFolder,
    onExpand: (folderId: string) => void,
    onHide: (folderId: string) => void,
    onOpen: (folder: DocumentFolder) => void,
    selectedFolder: DocumentFolder | null,
    openedFolders: string[]
}) => {
    const {revDir} = useMainTranslation();
    const isOpened = openedFolders.some(e => e === folder.id);
    const isSelected = selectedFolder && selectedFolder.id === folder.id;

    const handleExpandMoreFolder = (e: any) => {
        e.stopPropagation();
        onExpand(folder.id);
    }

    const handleExpandLessFolder = (e: any) => {
        e.stopPropagation();
        onHide(folder.id);
    }

    const handleOpenFolder = () => {
        if(!isSelected) onOpen(folder);
    }

    return (
        <Flex
            m={!revDir ? `0 0 0 ${currPath.length > 1 ? currPath.length * TREE_NESTED_SHIFT_PX : 0}px` : `0 ${currPath.length > 1 ? currPath.length * TREE_NESTED_SHIFT_PX : 0}px 0 0`}
            w={`calc(100% - ${currPath.length > 1 ? currPath.length * TREE_NESTED_SHIFT_PX : 0}px)`}
            ai={'center'}
            direction={'column'}
        >
            <Flex
                w={'100%'}
                ai={'center'}
                hoverStyles={`background-color: ${HOVER_FOLDER_COLOR};`}
                background={isSelected ? SELECTED_FOLDER_COLOR : 'initial'}
                onClick={handleOpenFolder}
                br={'5px'}
            >
                {folder.name !== TRASH_FOLDER_NAME &&
                <IconButton
                    onClick={(e) => isOpened ? handleExpandLessFolder(e) : handleExpandMoreFolder(e)}
                    disabled={!folder.children.length}
                >
                    {isOpened ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </IconButton>
                }
                {GetFolderIconByName(folder.name, currPath.length === 1)}
                <Typo margin={!revDir ? '0 0 0 10px' : '0 10px 0 0'} cursor={'pointer'}>{folder.name}</Typo>
            </Flex>
            {isOpened && <Flex w={'100%'} direction={'column'} overflowy={'auto'}>
                {folder.children.map(f => <Folder folder={f} onExpand={onExpand} onOpen={onOpen} onHide={onHide} selectedFolder={selectedFolder} openedFolders={openedFolders} key={f.id} currPath={[...currPath, {name: f.name, id: f.id}]}/>)}
            </Flex>}
        </Flex>
    )
}

const FolderRow = ({folder, openFolder}: {folder: DocumentFolder, openFolder: (folder: DocumentFolder) => void}) => {
    
    const {revDir} = useMainTranslation();

    const handleOpenFolder = (e: any) => {
        if(e.detail === 2){
            openFolder(folder);
        }
    }

    return(
        <Flex
            w={'100%'}
            jc={'space-between'}
            cursor={'pointer'}
            onClick={(e) => handleOpenFolder(e)}
            hoverStyles={`background-color: ${HOVER_FOLDER_COLOR};`}
            p={'10px'}
            ai={'center'}
        >
            <Flex ai={'center'} w={'60%'}>
                <FolderIcon />
                <Typo margin={!revDir ? '0 0 0 10px' : '0 10px 0 0'} cursor={'pointer'} fontSize={'12px'}>{folder.name}</Typo>
            </Flex>

            <Flex w={'25%'} ai={'center'} jc={'flex-end'}>
                <Typo cursor={'pointer'} fontSize={'12px'}>{parseDateAuto(folder.lastEditDate)}</Typo>
            </Flex>
        </Flex>
    )
}

const FileRow = ({file, onSelectFile, selectedFiles, deselectFile}: { file: DocumentFile, onSelectFile: (file: DocumentFile) => void, deselectFile: (fileId: string) => void, selectedFiles: DocumentFile[] }) => {
    const isSelected = selectedFiles.some(e => e.id === file.id);
    const {revDir} = useMainTranslation();

    const onClick = () => {
        isSelected ? deselectFile(file.id) : onSelectFile(file)
    }

    return(
        <Flex
            w={'100%'}
            jc={'space-between'}
            cursor={'pointer'}
            onClick={onClick}
            hoverStyles={`background-color: ${HOVER_FOLDER_COLOR};`}
            background={(isSelected) ? SELECTED_FOLDER_COLOR : 'initial'}
            p={'10px'}
            ai={'center'}
        >
            <Flex ai={'center'} w={'50%'}>
                <InsertDriveFileIcon />
                <Typo margin={!revDir ? '0 0 0 10px' : '0 10px 0 0'} cursor={'pointer'} fontSize={'12px'}>{file.name}</Typo>
            </Flex>

            <Flex w={'25%'} jc={'flex-end'}>
                <Typo cursor={'pointer'} sx={{lineHeight: 'normal'}} fontSize={'12px'}>{parseDateAuto(file.lastEditDate)}</Typo>
            </Flex>

            {/*<CustomButton onClick={() => onSelectFile(file)} disabled={isSelected}>Select</CustomButton>*/}
        </Flex>
    )
}