import React, {FC, MouseEvent} from "react";
import {Add} from "@mui/icons-material";
import {IconButton} from "@mui/material";

interface Props {
    onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const EditorAddPageButton: FC<Props> = ({onClick}) => {

    return (
        <IconButton onClick={onClick}>
            <Add/>
        </IconButton>
    )
}