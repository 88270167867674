import {Box, Button, Dialog, DialogActions, DialogContent, Divider, styled, TextField} from "@mui/material";
import colors from "../../../../../../../newShared/theme/colors";
import {TypographyBodySmall} from "../../../../../../../newShared/components/Inputs/styled";
import {TypographyNew} from "../../../../../../../newShared/components/TypographyNew";


export const AddFileDialog = styled(Dialog)(() => ({
    '.MuiPaper-root': {
        minWidth: '900px',
        height: '800px'
    },
}));

// AddFileDialog.defaultProps = {
//     fullScreen: true,
// }

export const TitleBox = styled(Box)(() => ({
    width: '100%',
    padding: '16px',
    backgroundColor: colors.backgrounds.grey_light,
    borderBottom: `1px solid ${colors.stroke.grey}`,
    display: 'flex',
    justifyContent: 'space-between',
    height: '52px'
}))


export const AddDocumentDialogContent = styled(DialogContent)(() => ({
    overflow: 'hidden',
    display: "flex",
    width: '100%',
    '&.MuiDialogContent-root': {
        padding: 0,
    }
}));

export const LeftSide = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
    margin: '10px 0'
}));

export const RightSide = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    margin: '32px 25px',
    overflow: 'hidden',
}));


export const LeftSideMenuItem = styled(Box, {
    shouldForwardProp: (propName) => (
        !["isActive"].includes(propName.toString())
    )})(({isActive}:{isActive?: true}) => ({
    display: 'flex',
    alignItems: 'center',
    // width: '100%',
    padding: '16px',
    backgroundColor: isActive ? colors.backgrounds.blue_light_1 : undefined,
    cursor: 'pointer',
}));

export const LeftSideMenuItemTitle = styled(TypographyNew)(() => ({
    marginLeft: '6px',
    marginRight: '6px',
}));
LeftSideMenuItemTitle.defaultProps = {
    noWrap: true,
    variant: '*bodyText1',
}

export const AddDocumentsDialogActions = styled(DialogActions)(() => ({
    '&.MuiDialogActions-root': {
        borderTop: `1px solid ${colors.stroke.grey}`,
        padding: '12px 24px',
        backgroundColor: colors.backgrounds.grey_light,
    },
}));

export const DialogActionsBox = styled(Box)(() => ({
    display: "flex",
    alignItems: 'center',
    width: '100%',
}));

export const SmallTextButton = styled(Button)(() => ({
    textTransform: 'none'
}));

SmallTextButton.defaultProps = {
    size: 'small',
    variant: 'text',
};

export const SmallOutlinedButton = styled(Button)(() => ({
    textTransform: 'none',
    marginLeft: 'auto'
}));

SmallOutlinedButton.defaultProps = {
    size: 'small',
};

export const CloseButton = styled(SmallTextButton)(() => ({
    marginLeft: 'auto',
}));



export const VerticalDivider = styled(Divider)(() => ({
    borderColor: colors.text.grey_light_2,
}));

VerticalDivider.defaultProps = {
    variant: 'fullWidth',
    orientation: 'vertical'
};

export const StartBlankBox = styled(Box, {
    shouldForwardProp: (propName) => (
        !["isSelected"].includes(propName.toString())
    )})(({isSelected}: {isSelected?: true}) => ({
    width: '150px',
    padding: '12px',
    height: '182px',
    borderRadius: '6.868px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    backgroundColor: colors.backgrounds.white,
    border: `1px solid ${isSelected ? colors.primary.blue : colors.stroke.grey}`,
    boxShadow: '0px 0px 1px 0px rgba(125, 151, 174, 0.02), 0px 2px 6px 0px rgba(125, 151, 174, 0.02), 0px 8px 16px 0px rgba(125, 151, 174, 0.08)',
}));

export const TitleBlankBox = styled(Box)(() => ({
   position: 'absolute',
   bottom: '12px',
   left: '12px'
}));

export const TitleBlank = styled(TypographyBodySmall)(() => ({
    color: colors.text.grey,
}));

export const FolderNameTextField = styled(TextField)(() => ({
    '&.MuiInputBase-root': {
        height: '40px',
    },
    marginTop: '10px',
}));

FolderNameTextField.defaultProps = {
    inputProps: {
        style: {
            width: '88%',
            padding: '13px 10px',
            height: '14px',
        }
    },
    size: 'small',
}






