import React from "react";
import * as S from '../../components/styled';
import {TextFieldCustom} from '../../components/styled';
import {Flex} from "../../../../../newShared/components/Layouts";
import colors from "../../../../../newShared/theme/colors";
import {Autocomplete, Button, IconButton, Typography} from "@mui/material";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {LoadingButton} from "@mui/lab";
import {availableCreateRowStatuses, availableCreateRowTypes, EXTERNAL_TYPE, ORHAN_TYPE} from "../../constants";
import {AddRoleDialog} from "../../components/dialogs/addRole";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {DeleteRoleDialog} from "../../components/dialogs/deleteRole";
import {EditRoleDialog} from "../../components/dialogs/editRole";
import {useEditRow} from "../../hooks/useEditRow";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {TShortEmployee} from "../../../employeesRefactor/types";

export const EditRow = () => {
    const {
        actions,
        isLoadingEmployees,
        form,
        handleChange,
        employees,
        isOk,
        selectedEmployee
    } = useEditRow();
    const {isMobile} = useMedia(858);
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.CreateRow'});

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('Edit account')}
                handleGoBack={actions.handleGoBack}
                actions={[]}
            />

            <S.CreateRowBlockWrapper>
                <Flex ai={'center'} m={'16px 0 0 0'}>
                    <LoadingButton
                        loading={false}
                        sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                        variant="contained"
                        size={isMobile ? 'small' : 'medium'}
                        onClick={actions.handleSubmit}
                        disabled={!isOk()}
                    >{t('Save')}</LoadingButton>
                    <Button variant={'outlined'} size={isMobile ? 'small' : 'medium'} onClick={actions.handleGoBack} >{t('Cancel')}</Button>
                </Flex>

                <TextFieldCustom
                    required
                    value={form.account}
                    name={'account'}
                    onChange={handleChange}
                    label={t('Account (email or user name)')}
                    size={'small'}
                    sx={{margin: '16px 0 0 0'}}
                    disabled
                />

                <Autocomplete
                    id="combo-box-demo"
                    options={availableCreateRowTypes}
                    value={form.accountType}
                    onChange={(e, newValue: string | null) => newValue && actions.handleSetType(newValue)}
                    renderInput={(params) => <S.TextFieldCustom {...params} label={t("Select type")} size={'small'} required/>}
                    sx={{margin: '8px 0 0 0'}}
                />

                {form.accountType === EXTERNAL_TYPE || form.accountType === ORHAN_TYPE ?
                    <TextFieldCustom
                        required
                        value={form.employee}
                        name={'employee'}
                        onChange={handleChange}
                        label={t('Type employee')}
                        disabled={form.accountType === ORHAN_TYPE}
                        size={'small'}
                        sx={{margin: '8px 0 0 0'}}
                    />
                    :
                    <Autocomplete
                        id="combo-box-demo"
                        options={employees.filter(e => !['terminated', 'hiring'].includes(e.status?.toLowerCase() || ''))}
                        getOptionLabel={(option: TShortEmployee) => `${option.firstName} ${option.lastName}`}
                        loading={isLoadingEmployees}
                        value={selectedEmployee}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(e, newValue: TShortEmployee | null) => newValue && actions.handleSetEmployee(newValue)}
                        renderInput={(params) => <TextFieldCustom {...params} label={t("Select employee")} size={'small'} required/>}
                        sx={{margin: '8px 0 0 0'}}
                    />
                }


                <Autocomplete
                    id="combo-box-demo"
                    options={availableCreateRowStatuses}
                    value={form.status}
                    onChange={(e, newValue: string | null) => newValue && actions.handleSetStatus(newValue)}
                    renderInput={(params) => <TextFieldCustom {...params} label={t("Select status")} size={'small'} required/>}
                    sx={{margin: '8px 0 0 0'}}
                />

                <Typography fontSize={'16px'} fontWeight={700} color={colors.darkBlueText} sx={{margin: '16px 0 0 0'}}>{t('Added roles')}</Typography>
                {form.roles.map(e => (
                    <Flex key={e.roleName} w={'100%'} ai={'center'} jc={'space-between'}>
                        <Typography variant={'body2'} color={colors.darkBlueText}>{e.roleName} {e.rolePermissions.length} {t('permissions')}</Typography>

                        <Flex ai={'center'}>
                            <IconButton onClick={() => actions.handleOpenEditRole(e)}><EditIcon sx={{color: colors.text.grey_dark}}/></IconButton>
                            <IconButton onClick={() => actions.handleOpenDeleteRole(e)}><DeleteForeverIcon sx={{color: colors.text.grey_dark}}/></IconButton>
                        </Flex>
                    </Flex>
                ))}

                <Button
                    variant={'text'}
                    size={isMobile ? 'small' : 'medium'}
                    onClick={actions.handleOpenAddRole}
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}>
                    {t('Add role')}
                </Button>
            </S.CreateRowBlockWrapper>

            <AddRoleDialog
                handleAddRole={actions.handleAddRole}
                addedRoles={form.roles}
            />
            <DeleteRoleDialog
                handleDeleteRole={actions.handleDeleteRole}
                role={actions.editableRole}
            />
            <EditRoleDialog
                addedRoles={form.roles}
                role={actions.editableRole}
                handleEditRole={actions.handleEditRole}
            />
        </Flex>

    )
}