import {FC} from "react";
import {Redirect, Route, Switch} from "react-router";

//consts
import {
    PATH_RISK_MANAGEMENT_MODELS_ALL,
    PATH_RISK_MANAGEMENT_MODELS_EXACT,
    PATH_RISK_MANAGEMENT_MODELS_EXACT_EDIT,
    PATH_RISK_MANAGEMENT_MODELS_NEW,
    PATH_RISK_MANAGEMENT_RISK_REGISTRIES_ALL,
    PATH_RISK_MANAGEMENT_RISK_REGISTRIES_EXACT,
    PATH_RISK_MANAGEMENT_RISK_REGISTRIES_NEW
} from "./constants";

//pages
import {ModelsList} from "./pages/models/list";
import {ModelsExact} from "./pages/models/exact";
import {ModelsNew} from "./pages/models/new";
import {RegistriesExact} from "./pages/registries/exact";
import {RegistriesList} from "./pages/registries/list";
import {RegistriesNew} from "./pages/registries/new";
import {ModelsEdit} from "./pages/models/edit";

export const Routes: FC = () => {
    return (
        <Switch>
            <Route exact path={PATH_RISK_MANAGEMENT_MODELS_ALL} component={ModelsList}/>
            <Route exact path={PATH_RISK_MANAGEMENT_MODELS_EXACT} component={ModelsExact}/>
            <Route exact path={PATH_RISK_MANAGEMENT_MODELS_NEW} component={ModelsNew}/>
            <Route exact path={PATH_RISK_MANAGEMENT_MODELS_EXACT_EDIT} component={ModelsEdit}/>

            <Route exact path={PATH_RISK_MANAGEMENT_RISK_REGISTRIES_ALL} component={RegistriesList}/>
            <Route exact path={PATH_RISK_MANAGEMENT_RISK_REGISTRIES_EXACT} component={RegistriesExact}/>
            <Route exact path={PATH_RISK_MANAGEMENT_RISK_REGISTRIES_NEW} component={RegistriesNew}/>

            <Redirect to={PATH_RISK_MANAGEMENT_RISK_REGISTRIES_ALL}/>
        </Switch>
    )
}
