import React, {Component, createRef, RefObject} from "react";
import {
    EditorComponentsMethods,
    EditorListLiData,
    getToolBarProps,
    MapDispatchToPropsObject,
    TBlockWithHtml,
    TEditorListLiElementData,
    TEditorTextElementData,
    TSetListElementActiveEventData
} from "../../../../types";
import {ToolBarProps} from "../../../editorToolBar";
import {EditorItemListLiStyled} from "../../styled";
import {EditorPopperButtonsProps} from "../../../commonComponents/editorPopperButtons";

import {EditorWorkZone} from "../../../editorWorkzone";
import {TextComponent} from "../../../TextComponent";
import {EditorItemList} from "../../index";
import {createEventSetListElementActive, isRefText} from "../../../../helpers";
import {setListElementActiveEventName} from "../../../../constants";
import {TListType} from "../../../editorToolBar/types";
import {uuid} from "../../../../../../utils";

interface Props extends getToolBarProps {
    block: TEditorListLiElementData;
    // type: string;

    // parentRef: RefObject<EditorItemList>["current"] | RefObject<EditorItemListLi>["current"];
    parentRef: RefObject<EditorItemList>["current"];
    editorWorkZoneRef: RefObject<EditorWorkZone>["current"];
    setPopperButtons: (buttons: EditorPopperButtonsProps["buttons"]) => void;
}
export interface ListLiState {
    id: string;
    type: 'listElement';
    data: EditorListLiData;

    // isActive: boolean;

    wrapperRef: RefObject<HTMLLIElement>;
    // refs: Record<string, RefObject<TextComponent | EditorItemListLi>>;
    refs: Record<string, RefObject<TextComponent>>;
}

export class EditorItemListLi extends Component<Props, ListLiState> implements EditorComponentsMethods<EditorListLiData> {
    constructor(props: EditorItemListLi["props"]) {
        super(props);

        let textBlock = props.block.data.textBlock;
        if (!textBlock) {
            textBlock = {
                id: 'text' + uuid(),
                type: 'text',
                data: {
                    content: [],
                    innerHtml: '',
                    alignText: 'left',
                },
            }
        }

        const listContent = props.block.data.content || [];
        if (listContent.length === 0) {

        }

        const refs: ListLiState["refs"] = {};
        refs[textBlock.id] = createRef<TextComponent>();
        // listContent.forEach(row => {
        //     refs[row.id] = createRef<EditorItemListLi>();
        // });

        this.state = {
            id: props.block.id,
            type: 'listElement',
            data: {
                ...props.block.data,
                textBlock,
                content: listContent,
            },

            // isActive: false,

            wrapperRef: createRef<HTMLLIElement>(),
            refs,
        }
    }

    componentDidMount() {
        document.addEventListener(setListElementActiveEventName, this.setActiveByEvent);
    }

    componentWillUnmount() {
        document.removeEventListener(setListElementActiveEventName, this.setActiveByEvent);
    }

    setActiveByEvent = (e: Event) => {
        const {detail} = e as CustomEvent<TSetListElementActiveEventData>;
        const isActive = detail.id === this.state.id;

        if (isActive) {
            if (detail.insertHtml?.length) {
                const textRef = this.state.refs[this.state.data.textBlock!.id]?.current;
                if (isRefText(textRef)) {
                    const position = textRef.state.charSequence.length;
                    const oldInnerHtml = textRef.getData().data.innerHtml;
                    const newInnerHtml = oldInnerHtml + detail.insertHtml;

                    textRef.replaceContent(newInnerHtml, position);
                }
            } else {
                this.setFocus(detail.byBackspace);
            }

        } else this.onClickAway();


        // if (detail.id === this.state.id) {
        //     this.setFocus(detail.byBackspace)
        // } else {
        //     this.onClickAway();
        // }
    };

    setFocus = (byBackspace?: boolean) => {
        this.setState(prev => ({...prev, isActive: true}));
        const ref = this.state.refs[this.state.data.textBlock!.id]?.current;

        if (!byBackspace) ref?.setFocus()
        else if (isRefText(ref)) ref.setFocusByList()
    };

    setActiveBlock: EditorWorkZone["setActiveBlock"] = (ids) => {
        this.props.parentRef?.setActiveBlock([this.state.id, ...ids]);
    };


    ///////ToolBar
    setToolBar: MapDispatchToPropsObject["setToolBar"] = (toolBar, ids) => {
        this.props.parentRef?.setToolBar(toolBar, [this.state.id, ...ids])
    }
    setTextByToolBar: ToolBarProps["toolBarHandler"] = (action, payload, ids) => {
        const nextId = ids[0];
        if (nextId) {
            const ref = this.state.refs[nextId]?.current;
            if (ref) {
                ref.setTextByToolBar(action, payload, ids.slice(1))
            }
        }
    }

    // Get data
    getData = (): Props["block"] => {
        const contentListLiData: EditorListLiData["content"] = JSON.parse(JSON.stringify(this.state.data.content));
        const textBlock = this.state.refs[this.state.data.textBlock!.id]?.current?.getData() as TEditorTextElementData;

        contentListLiData.forEach(row => {
            const ref = this.state.refs[row.id]?.current;
            if (isRefText(ref)) {
                row.data.textBlock = ref.getData();
            }
            // if (isRefListLi(ref)) {
            //     row.data = ref.getData().data;
            // }
        });

        return {
            id: this.state.id,
            type: this.state.type,
            data: {
                ...this.state.data,
                textBlock: textBlock,
                content: contentListLiData,
            },
        }
    }

    checkIsFocused = (id: string): boolean => {
        // return this.props.parentRef?.checkIsFocused(id) || false;
        return (this.state.wrapperRef.current?.contains(document.activeElement) && this.state.data.textBlock?.id === id) || false;
    };

    onClickAway = () => {
        // console.log(`onClickAway TABLE`)
        // this.setState(prev => ({...prev, isActive: false}));
    }

    handleLiClick = (e: React.MouseEvent<HTMLLIElement>) => {
        createEventSetListElementActive({id: this.state.id});
    }

    addElement = (afterId: string, html?: string) => {
        const index = this.state.data.content.findIndex(row => row.id === afterId);
        const newElement: TEditorListLiElementData = {
            id: 'listElement' + uuid(),
            type: 'listElement',
            data: {
                content: [],
                textBlock: {
                    id: 'text' + uuid(),
                    type: 'text',
                    data: {
                        content: [],
                        innerHtml: html ?? '',
                        alignText: 'left',
                    },
                },
            },
        };

        this.setState(prev => {
            if (!prev.data.content.some(row => row.id === newElement.id)) {
                // prev.refs[newElement.id] = createRef<EditorItemListLi>()
                prev.data.content.splice(index + 1, 0, newElement);
            }

            return prev;
        });
    }

    // removeElement = (id: string) => {
    //     const index = this.state.data.content.findIndex(row => row.id === id);
    //
    //     this.setState(prev => {
    //         const index = prev.data.content.findIndex(row => row.id === id);
    //         index > -1 && prev.data.content.splice(index, 1);
    //         return prev;
    //     });
    //
    //
    //
    //     if (this.props.parentRef?.state.type === 'list') {
    //         this.props.parentRef?.removeElement(this.state.id, innerHtmlRight);
    //     }
    //
    //     const elementBefore = this.state.data.content[index - 1];
    //     elementBefore && createEventSetListElementActive({id: elementBefore.id, byBackspace: true});
    // }

    handleClickEnter = (html?: string) => {
        // console.log(`LIST handleClickEnter`, html)
        this.props.parentRef?.addElement(this.state.id, html);
    }

    handleClickBackspace = (data: TEditorTextElementData) => {
        // console.log(`LIST handleClickBackspace`, data)
        this.props.parentRef?.removeElement(this.state.id, data.data.innerHtml);
    }


    handleDivideToBlocks = (data: TBlockWithHtml[], type: TListType, id?: string) => {
        // const el = this.state.data.content.find(row => row.id === id);
        // if (el) {
        //     return;
        // }

        this.props.parentRef?.handleDivideToBlocks(data, type, this.state.id);
    }

    render() {
        return (
            <EditorItemListLiStyled ref={this.state.wrapperRef} onMouseDown={this.handleLiClick}>
                <TextComponent block={this.state.data.textBlock!}
                               ref={this.state.refs[this.state.data.textBlock!.id] as RefObject<TextComponent>}
                               parentRef={this}
                               editorWorkZoneRef={this.props.editorWorkZoneRef}
                               getToolBarState={this.props.getToolBarState}
                               handleClickEnter={this.handleClickEnter}
                               handleClickBackspaceOnEmpty={this.handleClickBackspace}

                               status={this.props.status}
                               readonly={this.props.readonly}
                               isLoading={this.props.isLoading}
                />

                {/*{this.state.data.content.length > 0 && (*/}
                {/*    <EditorItemListStyled total={this.state.data.content.length}>*/}
                {/*        {this.state.data.content.map((row) => (*/}
                {/*            <EditorItemListLi key={row.id} ref={this.state.refs[row.id] as RefObject<EditorItemListLi>}*/}
                {/*                              // type={this.props.type}*/}
                {/*                              block={row}*/}
                {/*                              parentRef={this}*/}
                {/*                              editorWorkZoneRef={this.props.editorWorkZoneRef}*/}
                {/*                              getToolBarState={this.props.getToolBarState}*/}
                {/*                              setPopperButtons={this.props.setPopperButtons}*/}

                {/*                              status={this.props.status}*/}
                {/*                              readonly={this.props.readonly}*/}
                {/*                              isLoading={this.props.isLoading}*/}
                {/*            />*/}
                {/*        ))}*/}
                {/*    </EditorItemListStyled>*/}
                {/*)}*/}
            </EditorItemListLiStyled>
        );
    }
}