import {FC} from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {DeleteDialogButton} from "../../../../../../newShared/components/deleteDialogButton";
import {useSegregationOfDuties} from "../../../hooks/useSegregationOfDuties";

export const DeleteExceptionDialog: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        dialogs: {deleteException: {isOpen, exception, ruleId}},
        loadings: {deleteException: isLoadingDelete},
        handleDeleteException: handleDelete,
        handleHideDeleteException: handleCancel
    } = useSegregationOfDuties();

    return (
        <DeleteDialogButton isOpen={isOpen} isLoading={isLoadingDelete}
                            title={t('Delete exception')}
                            message={t('Delete this exception?')}
                            handleConfirm={() => exception && ruleId && handleDelete(exception, ruleId)}
                            handleClose={handleCancel}
        />
    )
}
