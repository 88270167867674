import {EmptyArrayImage} from "../../../../../newShared/components/EmptyArrayImage";
import {Flex} from "../../../../../newShared/components/Layouts";
import React from "react";

export const TrainingsSettings = () => {
    return(
        <Flex w={'100%'}>
            <EmptyArrayImage
                type={'builderDev'}
                isShowing={true}
                text={'Settings in development'}
                withoutAdd
            />
        </Flex>
    )
}