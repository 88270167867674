import React, {FC} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useCreateSettingDialog} from "../../../hooks/useCreateSettingDialog";
import {LoadingButton} from "@mui/lab";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {statusVariants} from "../../../constants";
import {TCreateField} from "../../../types";


export const CreateSettingRowDialog: FC<{onSuccess?: (title: string, field?: TCreateField) => void}> = ({onSuccess}) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});
    const {isMobile} = useMedia();
    const {
        isOk,
        isOpen,
        isOpenChangeStatusDialog,
        isOpenDeleteSettingDialog,
        field,
        settingTitle,

        title,
        handleChangeTitle,
        itemStatus,
        handleChangeStatus,

        handleClose,
        handleSave,

        addSettingLoading,
    } = useCreateSettingDialog();

    const handleSaveSetting = () => {
        handleSave(onSuccess);
    }


    return field ? (
        <Dialog open={isOpen} onClose={handleClose}
                sx={{'& .MuiPaper-root': {width: isMobile ? '358px' : '600px', height: isOpenChangeStatusDialog ? '265px' : isOpenDeleteSettingDialog ? '305px' : undefined}}}>
            <DialogTitle>
                {`${t('Create new')} ${field === 'status' ? t('status').toLowerCase() : t(settingTitle).toLowerCase()}`}
            </DialogTitle>
            <DialogContent sx={{padding: isMobile ?  '10px 0' : '10px 200px 10px 24px'}}>
                <TextField size={'small'} required label={t('Name')} variant={"outlined"} fullWidth color={"primary"} sx={{marginTop: '5px'}} value={title} onChange={handleChangeTitle}/>
                {field === 'status' && (
                    <TextField required size={'small'} label={t('Status')} sx={{mt: '10px'}} select fullWidth value={itemStatus}
                               onChange={handleChangeStatus} >
                        {statusVariants.map((el) => <MenuItem value={el} key={el}>{el}</MenuItem>)}
                    </TextField>
                )}
            </DialogContent>
            <DialogActions sx={{paddingTop: '8px'}}>
                <Button variant={'text'} onClick={handleClose}>{t('Cancel')}</Button>

                <LoadingButton size={"large"} component={"label"}
                               variant={'contained'}
                               disabled={title.trim() === '' || addSettingLoading || !isOk()}
                               onClick={handleSaveSetting} loadingPosition={addSettingLoading ? 'start' : undefined} loading={addSettingLoading}
                               startIcon={addSettingLoading ? <div style={{width: '10px', height: '10px', }}/> : undefined}
                >
                    {t('Save')}
                </LoadingButton>
            </DialogActions>

        </Dialog>
    ) : null
}
