import {useDispatch, useSelector} from "react-redux";
import {hideStageSettings, selectedAutomation, setStageSettings, stageSettings} from "../../../store/slice";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import {mapper, timeValues} from "../../../constants";

import {translateText} from "../../../helpers";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../../../../newShared/components/Layouts";

export const StageSettings = () => {
    const dispatch = useDispatch();
    const {t} = useMainTranslation('', {keyPrefix: 'automations'});

    const auto = useSelector(selectedAutomation);
    const {isOpen, stageId} = useSelector(stageSettings);

    const [type, setType] = useState<string>('');
    const [num, setNum] = useState<number>(1);
    const [value, setValue] = useState<string>('Days');

    useEffect(() => {
        if(auto && stageId !== -1){
            setType(auto.stages[stageId].type);
            const {factor, timeValue} = translateText(auto.stages[stageId].eta);
            setNum(factor);
            setValue(timeValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const handleSelect = () => {
        const inSeconds = num*mapper[value]; //already in miliseconds
        dispatch(setStageSettings({type, eta: inSeconds}));
    }

    const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
        if (reason !== 'backdropClick') {
            dispatch(hideStageSettings());
        }
    };

    return (auto &&

        <div>
            <Dialog disableEscapeKeyDown open={isOpen} onClose={handleClose}>
                <DialogTitle>{t('Stage Settings')}</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        fullWidth
                        id="combo-box-demo"
                        options={['read', 'review', 'sign']}
                        getOptionLabel={(option) => t(`stageTypes.${option}`)}
                        value={type}
                        loading={!isOpen}
                        onChange={(e, newValue: string | null) => {
                            if(!newValue) return;
                            setType(newValue);
                        }}
                        sx={{ width: 300, margin: '8px 0 0 0' }}
                        renderInput={(params) => <TextField required {...params} label={t("Stage Type")} />}
                    />

                    <Flex jc={'space-between'} w={'100%'} m={'8px 0 0 0'}>
                        <Typography variant={'subtitle1'}>{t('ETA')}</Typography>
                    </Flex>

                    <Flex w={'100%'} ai={'center'} jc={'space-between'} m={'8px 0 0 0'}>
                        <TextField
                            required
                            id="filled-number"
                            label={t("Number")}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            value={num}
                            onChange={(e) => {setNum(parseInt(e.target.value) || 1)}}
                        />
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-dialog-select-label" required>{t('Select')}</InputLabel>
                            <Select
                                labelId="demo-dialog-select-label"
                                id="demo-dialog-select"
                                value={value}
                                onChange={(e) => {setValue(e.target.value as string)}}
                                input={<OutlinedInput label={t("Select")} />}
                            >
                                {timeValues.map(e => <MenuItem  key={e} value={e}>{t(`repeatEveryType.${e}`)}</MenuItem>) }
                            </Select>
                        </FormControl>
                    </Flex>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('Cancel')}</Button>
                    <Button onClick={handleSelect}>{t('Ok')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
