import React, {FC, Fragment, useEffect} from "react";
import {Breadcrumbs} from "../../../../barsEnvironment/breadcrumbs";
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Menu,
    MenuItem,
    Skeleton,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tabs,
    Typography
} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {AddToPhotos, ArrowBack, ArrowForward, Delete, FileUpload} from "@mui/icons-material";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import ReactMarkdown from "react-markdown";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {TAssetDataAUTOMATION, TAssetDataFILE} from "../../types";
import {toBase64} from "../../../../../newShared/utils/base64/base64";
import {usePDFView} from "../../../../../newShared/components/docViewer/hooks/usePDFView";
import {DeleteEvidenceAssetDialog} from "../../components/dialogs/deleteEvidenceAssetDialog";

import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {TableRowSkeleton} from "../../../../../newShared/components/tableRowSkeleton";
import styles from '../controlView/styles.module.css';
import {StatementDialog} from "../../components/dialogs/statementDialog";
import {useEvidenceViewPage} from "./hooks/useEvidenceViewPage";
import {LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {TableRowEmpty} from "../../../../../newShared/components/tableRowEmpty";
import {FrameworkCollectorTableDialog} from "../../components/dialogs/collectorTableDialog";
import {useFrameworksDialog} from "../../hooks/useFrameworksDialog";
import {PageWrapper} from "../../../../../newShared/components/Layouts";
import {getEvidenceStatus} from "../../helpers";
import {EVIDENCE_STATUS} from "../../constants";
import {useFileDropZone} from "../../../../../newShared/hooks/useSingleFileDropZone";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {LightTooltip} from "../../../../../newShared/components/pageTitleAndActions";
import {useThreeDotsMenu} from "../../../../../newShared/hooks/useThreeDotsMenu";


export const EvidenceView: FC = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathFrameworks.pathEvidence'});
    const {isLessThen1050} = useMedia();

    const {
        goToEvidences, setEvidenceStatus,
        evidences, currentEvidence,
        uploadFileAsset, downloadFile, downloadTable,
        tab, tabs,
        isLoading,

        goToViceStep,
    } = useEvidenceViewPage();

    const {
        deleteAsset: {setDeleteAssetDialog},
        collectorView: {setCollectorViewDialog},
        statement: {setStatementDialog},
    } = useFrameworksDialog();

    const {
        anchorThreeDotsEl: anchorEl,
        handleThreeDotsOpen: handleStatusOpen,
        handleThreeDotsClose: handleStatusClose
    } = useThreeDotsMenu<HTMLButtonElement>();

    const controller = new AbortController();
    useEffect(() => {

        return () => {
            controller.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const {handleOpen} = usePDFView();

    const {fileHandler, filePicker, uploadedFile, ref} = useFileDropZone();
    useEffect(() => {
        uploadedFile && toBase64(uploadedFile).then(file => {
            currentEvidence && file && uploadFileAsset(file as string, uploadedFile?.name || '', currentEvidence.id)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedFile])
    return (
        <PageWrapper flexBasis={!isLessThen1050 ? '50%' : "100%"}>
            {isLessThen1050 && (
                <Breadcrumbs/>
            )}

            <Box display={"flex"} alignItems={"center"} mt={'12px'} flexWrap={"wrap"} gap={'32px'}>
                <Typography variant={"h3"}>
                    {currentEvidence && (
                        <IconButton sx={{color: colors.text.blue, margin: !revDir ? '0 12px 0 0' : '0 0 0 12px', padding: '0'}}
                                    onClick={() => {goToEvidences({})}}>
                            {!revDir ? <ArrowBack/> : <ArrowForward/>}
                        </IconButton>
                    )}

                    {currentEvidence ? currentEvidence.name : <Skeleton width={'300px'}/>}
                </Typography>

                {currentEvidence?.vicaStep && (
                    <LightTooltip title={currentEvidence.vicaStep.stepName}>
                        <Button variant={"text"} size={'small'} onClick={goToViceStep(currentEvidence.vicaStep.stepId)}>{t('VICA step')}</Button>
                    </LightTooltip>
                )}
            </Box>

            <Box display={"flex"} alignItems={"center"} mt={'12px'} gap={'14px'}>
                <Typography variant={"subtitle2"}>
                    {`${t('Status')}: `}
                </Typography>

                <LoadingButton variant={"outlined"} onClick={handleStatusOpen} loading={!currentEvidence?.status || evidences.isChangingStatus}
                               endIcon={Boolean(anchorEl) ? <ExpandLessIcon /> :  <ExpandMoreIcon />}
                               size={'small'}
                               sx={{border: `1px solid ${getEvidenceStatus(t, currentEvidence?.status || '').color}`, minWidth: '30px', color: getEvidenceStatus(t, currentEvidence?.status || '').color}}>
                    {getEvidenceStatus(t, currentEvidence?.status || '').text}
                </LoadingButton>

                <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleStatusClose}>
                    {EVIDENCE_STATUS.map(status => (
                        <MenuItem key={status}
                                  sx={{minWidth: anchorEl?.offsetWidth}}
                                  onClick={() => {
                                      handleStatusClose();
                                      currentEvidence && currentEvidence?.status !== status && setEvidenceStatus({evidenceId: currentEvidence.id, status}, controller.signal);
                                  }}>
                            <Typography variant={'*bodyText2'} sx={{color: getEvidenceStatus(t, status).color}}>{getEvidenceStatus(t, status).text}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </Box>

            <Tabs value={tab} sx={{marginBottom: '16px'}} variant={"scrollable"}>
                {tabs.map(tab => (
                    <Tab key={tab.title} label={isLoading || !currentEvidence || tab.count === null ? t(tab.title) : `${t(tab.title)} (${tab.count})`}
                         value={tab.title} onClick={tab.handleClick} disabled={isLoading}/>
                ))}
            </Tabs>

            {tab === 'Files' && (
                <>
                    <LoadingButton loading={evidences.isUploadingFile} startIcon={<FileUpload/>} variant={"text"}
                                   onClick={filePicker}
                                   sx={{alignSelf: 'flex-start', margin: '0 6px'}} disabled={!currentEvidence}>
                        {t('Upload manual files')}
                    </LoadingButton>

                    <input
                        // accept={accept}
                        value=""
                        style={{ visibility: "hidden", opacity: 0 }}
                        ref={ref}
                        // multiple={multiple}
                        type="file"
                        onChange={(e) => fileHandler(e.target.files)}
                    />

                    <TableContainer style={{overflow: "auto"}} className={'withoutBorder'}>
                        <Table size={"micro"}>
                            <TableBody>
                                {currentEvidence?.files.map(asset => (
                                    <TableRow sx={{'& td': {borderBottom: 'none'}}} key={asset.id}>
                                        <TableCell width={1000}>
                                            <Box display={"flex"} alignItems={"center"}>
                                                <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                            disabled={evidences.isLoadingFiles.includes(asset.data[0]?.fileId)}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                downloadFile(asset.data[0]?.fileId as string || '', asset.data[0]?.name as string || '')
                                                            }}>
                                                    {evidences.isLoadingFiles.includes(asset.data[0]?.fileId)
                                                        ? <CircularProgress size={24}/>
                                                        : <DownloadIcon style={{color: colors.text.blue}}/>}
                                                </IconButton>

                                                {asset.name.endsWith('.pdf') && (
                                                    <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    handleOpen({fileId: asset.data[0]?.fileId as string || '', fileName: asset.data[0]?.name as string || ''})
                                                                }}>
                                                        <VisibilityIcon style={{color: colors.text.blue}}/>
                                                    </IconButton>
                                                )}

                                                {asset.name}
                                            </Box>
                                        </TableCell>
                                        <TableCell align={"center"} sx={{whiteSpace: 'nowrap'}}>{parseDateAuto(asset.lastCollected)}</TableCell>
                                        <TableCell align={"center"}>
                                            <IconButton size={'small'} onClick={e => {
                                                e.stopPropagation();
                                                setDeleteAssetDialog({isOpen: true, id: asset.id})
                                            }}>
                                                <Delete/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}

                                {isLoading && (
                                    <>
                                        <TableRowSkeleton/>
                                        <TableRowSkeleton/>
                                        <TableRowSkeleton/>
                                    </>
                                )}

                                {currentEvidence && currentEvidence.files.length === 0 && !isLoading && <TableRowEmpty columns={3} text={t('Files not added yet')}/>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}

            {tab === 'Automation' && (
                <TableContainer style={{overflow: "auto"}} className={'withoutBorder'}>
                    <Table size={'micro'}>
                        <TableBody>
                            {currentEvidence?.automations.map(asset => (
                                <Fragment key={asset.id}>
                                    {asset.type === 'COLLECTOR' && (
                                        <TableRow sx={{'& td': {borderBottom: 'none'}}}>
                                            <TableCell width={1000}>
                                                <Box display={"flex"} alignItems={"center"}>
                                                    {asset.type === 'COLLECTOR' && (
                                                        <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                    disabled={evidences.isLoadingFiles.includes(asset.id)}
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        downloadTable(asset.id)
                                                                    }}>
                                                            {evidences.isLoadingFiles.includes(asset.id)
                                                                ? <CircularProgress size={24}/>
                                                                : <DownloadIcon style={{color: colors.text.blue}}/>}
                                                        </IconButton>
                                                    )}

                                                    <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                onClick={() => {setCollectorViewDialog({assetId: asset.id, isOpen: true})}}>
                                                        <ArticleOutlinedIcon style={{color: colors.text.blue}}/>
                                                    </IconButton>

                                                    {asset.name}
                                                </Box>
                                            </TableCell>
                                            <TableCell align={"center"} sx={{whiteSpace: 'nowrap'}}>{parseDateAuto(asset.lastCollected)}</TableCell>
                                            <TableCell align={"center"}>
                                                <IconButton size={'small'} onClick={e => {
                                                    e.stopPropagation();
                                                    setDeleteAssetDialog({isOpen: true, id: asset.id})
                                                }}>
                                                    <Delete/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )}

                                    {asset.type === 'AUTOMATION' && (asset.data as TAssetDataAUTOMATION)
                                        .reduce((prev, curr) => {
                                            const array: TAssetDataFILE = [];
                                            Object.keys(curr).forEach((value) => {
                                                array.push(...curr[value]);
                                            })
                                            return [...prev, ...array]
                                        }, [] as TAssetDataFILE)
                                        .map((file, index, array) => (
                                            <TableRow key={file.fileId} sx={{'& td': {borderBottom: 'none'}}}>
                                                <TableCell width={1000}>
                                                    <Box display={"flex"} alignItems={"center"}>
                                                        <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                    disabled={evidences.isLoadingFiles.includes(file.fileId)}
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        downloadFile(file.fileId as string || '', file.name as string || '')
                                                                    }}>
                                                            {evidences.isLoadingFiles.includes(file.fileId)
                                                                ? <CircularProgress size={24}/>
                                                                : <DownloadIcon style={{color: colors.text.blue}}/>}
                                                        </IconButton>

                                                        {file.name.endsWith('.pdf') && (
                                                            <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                        onClick={(event) => {
                                                                            event.stopPropagation();
                                                                            handleOpen({fileId: file.fileId as string || '', fileName: file.name as string || ''})
                                                                        }}>
                                                                <VisibilityIcon style={{color: colors.text.blue}}/>
                                                            </IconButton>
                                                        )}

                                                        {file.name}
                                                    </Box>
                                                </TableCell>
                                                <TableCell align={"center"} sx={{whiteSpace: 'nowrap'}}>{parseDateAuto(file.lastUpdate)}</TableCell>
                                                {index === 0 && (
                                                    <TableCell align={"center"} rowSpan={array.length}>
                                                        <IconButton size={'small'} onClick={e => {
                                                            e.stopPropagation();
                                                            setDeleteAssetDialog({isOpen: true, id: asset.id})
                                                        }}>
                                                            <Delete/>
                                                        </IconButton>
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        ))}
                                </Fragment>
                            ))}

                            {currentEvidence && currentEvidence.automations.length === 0 && !isLoading && <TableRowEmpty columns={3} text={t('Automations not generated yet')}/>}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {tab === 'Statements' && (
                <>
                    {currentEvidence && (
                        <Button variant={"text"} startIcon={<AddToPhotos/>} sx={{alignSelf: 'flex-start', margin: '0 6px'}}
                                onClick={() => {setStatementDialog({evidenceId: currentEvidence?.id, isOpen: true})}}>{t('Add new statement')}</Button>
                    )}

                    <TableContainer style={{overflow: "auto"}} className={'withoutBorder'}>
                        <Table size={'micro'}>
                            <TableBody>
                                {currentEvidence?.statements.map(asset => (
                                    <TableRow sx={{'& td': {borderBottom: 'none'}}} key={asset.id}>
                                        <TableCell width={1000}>
                                            <Box display={"flex"} alignItems={"center"}>
                                                <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                currentEvidence && setStatementDialog({evidenceId: currentEvidence.id, isOpen: true, assetId: asset.id})
                                                            }}>
                                                    <VisibilityIcon style={{color: colors.text.blue}}/>
                                                </IconButton>

                                                {asset.data?.[0]?.name || ''}
                                            </Box>
                                        </TableCell>
                                        <TableCell align={"center"} sx={{whiteSpace: 'nowrap'}}>{parseDateAuto(asset.lastCollected)}</TableCell>
                                        <TableCell align={"center"}>
                                            <IconButton  size={'small'} onClick={e => {
                                                e.stopPropagation();
                                                setDeleteAssetDialog({isOpen: true, id: asset.id})
                                            }}>
                                                <Delete/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}

                                {currentEvidence && currentEvidence.statements.length === 0 && !isLoading && <TableRowEmpty columns={3} text={t('Statements not added yet')}/>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}

            {tab === 'Description' && (
                <ReactMarkdown className={styles.FrameworkEvidenceDescription}>
                    {currentEvidence?.description || ''}
                </ReactMarkdown>
            )}

            <StatementDialog/>
            <FrameworkCollectorTableDialog/>
            <DeleteEvidenceAssetDialog/>
        </PageWrapper>
    )
}
