import {Box, styled} from "@mui/material";
import colors from "../../../../../theme/colors";

type EditorItemTableStyledProps = {
    align: 'left' | 'center' | 'right';
    withFile?: boolean;
    readonly?: boolean;
}
export const EditorItemImageStyled = styled(Box, {shouldForwardProp: (propName) => (
    !['align', 'readonly', 'withFile'].includes(propName.toString())
    )})<EditorItemTableStyledProps>(({align, readonly, withFile}) => ({
    display: withFile ? 'flex' : undefined,
    justifyContent: align === 'center' ? 'center' : align === 'left' ? 'flex-start' : 'flex-end',
    padding: '10px',

    '& .imageWrapper': {
        boxSizing: 'border-box',
        // alignSelf: align === 'center' ? 'center' : align === 'left' ? 'start' : 'end',

        position: 'relative',
        padding: '10px',

        '& div.resizerDot': {
            width: '6px',
            height: '6px',
            backgroundColor: 'rgba(0,0,0,0.5)',
            borderRadius: '100%',
            position: 'absolute',
            zIndex: 10,

            '&.tl': { cursor: 'nwse-resize', top: '4px', left: '4px' },
            '&.tr': { cursor: 'nesw-resize', top: '4px', right: '4px' },
            '&.br': { cursor: 'nwse-resize', bottom: '4px', right: '4px' },
            '&.bl': { cursor: 'nesw-resize', bottom: '4px', left: '4px' },

            '&.hide': { display: 'none' },
        },

        '& label': {
            cursor: !readonly ? 'pointer' : undefined,
        },

        '& img': {
            width: '100%',
            maxHeight: '100%',
        },
    },
}))

export const EditorItemEmptyImageStyled = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
    gap: '8px',

    width: '100%',
    height: '100%',

    minHeight: '40px',

    background: colors.backgrounds.grey_light,

    outline: '0.8px dashed #605E5C',
    borderRadius: '10px',

    cursor: 'pointer',

    color: colors.text.grey_dark,
    fontWeight: 400,
    fontSize: '12.55px',
    lineHeight: '16.31px',
    letterSpacing: '0.12px',
})

