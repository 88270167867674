import {useDispatch, useSelector} from "react-redux";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useFormik} from "formik";
import * as yup from 'yup';
import React, {useEffect} from "react";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {DelegatedTaskSources, MainFrameworksCreateDelegatedTaskInput} from "../../../../../../newShared/GQLTypes";
import {dialogs, loadings, setCreateDelegatedTaskAction, vicaDelegatedTasksSelector} from "../../../store/slice";
import {
    MainVicaCreateDelegatedTaskAction,
    mainVicaGetDelegatedTasksWithFilterPaginationAction
} from "../../../store/actions";
import {
    useGenericFiltersStorage
} from "../../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";

export const useCreateDelegatedTaskDialog = () => {
    const dispatch = useDispatch();
    const {t} = useMainTranslation('', {keyPrefix: 'pathVica.DelegatedTasks'})
    const {setMessage, isOpenMessageDialog} = useMessageDialog();
    const {createDelegatedTask: {vicaStepId}} = useSelector(dialogs);
    const {isLoadingCreate} = useSelector(loadings);
    const {selectedStep, pageInfo} = useSelector(vicaDelegatedTasksSelector);
    const handleClose = () => {
        dispatch(setCreateDelegatedTaskAction({clear: true}));
    }

    const {currentFiltersForFetch, currentSearchForFetch, getCurrentPage} = useGenericFiltersStorage();
    const refreshTable = () => {
        selectedStep && dispatch(mainVicaGetDelegatedTasksWithFilterPaginationAction({data: {vicaStepId: selectedStep.id, pageRequest: { page: getCurrentPage(), count: pageInfo.count }, filter: { ...currentFiltersForFetch, taskIdLike: currentSearchForFetch }}, clean: true}));
    }
    const isOpen = !!vicaStepId;
    const handleCreate = () => {
        const task: MainFrameworksCreateDelegatedTaskInput = {
            source: DelegatedTaskSources.VicaModule,
            title: selectedStep?.name ?? '',
            description: selectedStep?.description ?? '',
            requestorComment: formik.values.comment || '',
            performer: formik.values.collaborator || {collaboratorId: '', email: '', fullName: ''},
            vicaStepId,
            frameworkId: null,
            controlId: null,
            evidenceId: selectedStep?.evidenceId ?? '',
        };
        dispatch(MainVicaCreateDelegatedTaskAction({
            data: {workspaceId: '', task},
            onSuccess: (request, response) => {
                setMessage({
                    title: t('Completed successfully'),
                    message: t('Delegated task was created')},
                );
                handleClose();
                refreshTable();
            },
            onError: (request, _error, addictiveData) => {
                const error409 = _error.e409?.[0];
                switch (error409?.type){
                    case 'NOT_COLLABORATOR':
                        const performerName = error409?.values?.find(e => e?.parameter === 'performer')?.value;
                        setMessage({
                            title: t('Invalid performer status'),
                            message: t('Collaborator {{name}} has been removed from system', {name: performerName})}
                        );
                        break;
                    case 'DUPLICATE':
                        const id = error409?.values?.find(e => e?.parameter === 'id')?.value;
                        const status = error409?.values?.find(e => e?.parameter === 'status')?.value;
                        setMessage({
                            title: t('Duplicate task error'),
                            message: t('You already have task {{id}} for evidence {{name}} with status {{status}} and performer {{performer}}', {id, name: task.title, status, performer: task.performer.fullName})}
                        );
                        break;
                    case 'NO_PORTAL_ACCESS':
                        const name = error409?.values?.find(e => e?.parameter === 'performer')?.value;
                        setMessage({
                            title: t('Invalid performer status'),
                            message: t('Collaborator {{name}} does not have access to portal', {name})}
                        );
                        break;
                }
            },
        }))
    }
    const validationSchema = yup.object({
        comment: yup.string()
            .nullable()
            .max(1000, t('Comment should be less than 1000 symbols')),
        collaborator: yup.object({
            collaboratorId: yup.string()
                .required(t('Collaborator field is required')),
            fullName: yup.string()
                .required(t('Collaborator field is required')),
            email: yup.string()
                .required(t('Collaborator field is required')),

        })
            .nullable()
            .required(t('Collaborator field is required'))
    });
    const formik = useFormik<{ collaborator: {collaboratorId: string, fullName: string, email: string} | null, comment: string | null }>({
        initialValues: {collaborator: null, comment: ''},
        validationSchema,
        onSubmit: handleCreate,
        isInitialValid: false,
    })
    const formikOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldTouched(e.target.name, true, false);
        formik.handleChange(e);
    };

    useEffect(() => {
        formik.resetForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);


    return {
        isOpen: isOpen && !isOpenMessageDialog,
        isLoadingCreate,
        handleClose,
        handleCreate,
        formik: {...formik, handleChange: formikOnChange},
        selectedStep,
    }


}
