import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideDeclineVerifyStepDialog, loadings, selectedExecutor} from "../../../../store/slice";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import React, {useEffect} from "react";
import {useForm} from "../../../../../../../newShared/hooks/useForm";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {DialogContentWrapper} from "../../../../../newIntegrations/components/styled";
import {VerifyActorChanges} from "../../../../store/actions";

export const DeclineVerifyStepDialog = () => {
    const dispatch = useDispatch();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.CampaignReviewExecutorExact.dialogs'});

    const {
        isOpen,
        stageId,
        email,
        isLastVerify
    } = useSelector(dialogs).declineVerifyStepDialog;
    const isLoading = useSelector(loadings).campaignReviewVerifyChangesForEmails.some(e => e === email);
    const executor = useSelector(selectedExecutor);

    useEffect(() => {
        if(isOpen){
            cleanUp();
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const handleClose = () => {
        dispatch(hideDeclineVerifyStepDialog());
    }

    const handleSubmit = () => {
        if(isOk() && executor && email !== null && stageId){
            dispatch(VerifyActorChanges({
                data: {
                    executorId: executor.id,
                    email,
                    verifyComment: form.comment,
                    stageId,
                    isLastVerify
                }
            }));
        }
    }

    const {form, handleChange, setForm} = useForm<{comment: string}>({comment: ''});

    const cleanUp = () => setForm({comment: ''});

    const isOk = () => form.comment.trim().length > 0;

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t(`Decline changes`)}</DialogTitle>

            <DialogContent>
                <DialogContentWrapper>
                    <TextField
                        autoFocus
                        size={'small'}
                        sx={{margin: '16px 0 0 0'}}
                        value={form.comment}
                        name={'comment'}
                        onChange={handleChange}
                        label={t('Decline reason')}
                        required
                    />
                </DialogContentWrapper>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                >
                    {t('Decline changes')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}