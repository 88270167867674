import {Checkbox, CheckboxProps, FormControlLabel, SxProps} from "@mui/material";
import {FC} from "react";


type TCommonCheckbox = CheckboxProps & {
    label?: string,
    formSx?: SxProps
}

const CommonCheckbox: FC<TCommonCheckbox> = ({label, formSx, ...props}) => {

    return (label && label !== '') ? (
        <FormControlLabel control={<Checkbox {...props} />} label={label} sx={formSx}/>
    ) : (
        <Checkbox {...props} />
    )
}

export default CommonCheckbox;