import {useInvites} from "../../hooks/invites/useInvites";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import React from "react";
import {Flex} from "../../../../../newShared/components/Layouts";
import {DeclineInvite} from "../../components/invites/dialogs/declineInvite";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {isExpired, parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {Spinner} from "../../../../../newShared/components/Spinner";
import {TWorkspaceInvite} from "../../types";
import {AcceptInviteSuccessDialog} from "../../components/invites/dialogs/acceptInviteSuccessDialog";

export const Invites = () => {
    const {
        invites,
        isLoading,
        isLoadingAccept,
        isLoadingDecline,
        handleDeclineInvite,
        handleAcceptInvite,
    } = useInvites();
    const {isMobile} = useMedia(858);
    const {t, currentLanguage} = useMainTranslation('', {keyPrefix: 'pathSettings.Invites'});

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('Invites')}
                actions={[]}
            />


            {isLoading ? <Spinner/> :
                <GenericTable<TWorkspaceInvite>
                    id={'Invites'}
                    columnsConfig={{totalName: 'Total invites', forceShowCardsWhen: isMobile}}
                    disableSkeletons
                    paging={{
                        pageInfo: {page: 0, total: invites.length, count: invites.length},
                        isLoading,
                        minLoadedPage: 0,
                        maxLoadedPage: 0,
                        setMinMaxLoadedPage: () => null,
                        clearRows: () => null,
                        fetchPaging: () => null,
                    }}
                    rowsConfig={{
                        rows: invites,
                        customCellPaddings: '8px',
                        getRowUniqueId: (row) => row.id,
                        onRowClick: () => null,
                        columnsConfigs: [
                            {
                                name: t('Invitation from'),
                                key: 'name',
                                default: true,
                                valueGetter: row => `${row.sender.firstName} ${row.sender.lastName}`
                            },
                            {name: t('Email'), key: 'sender', default: true, valueGetter: row => row.sender.email},
                            {name: t('Organization name'), key: 'organizationName', default: true},
                            {name: t('Workspace name'), key: 'workspaceName', default: true},
                            // {
                            //     name: t('Type'),
                            //     key: 'type',
                            //     default: true,
                            //     valueGetter: row => makeFirstLetterUpper(row.type.toLowerCase())
                            // },
                            {
                                name: t('Expiration date'),
                                key: 'expDate',
                                default: true,
                                valueGetter: row => parseDateAuto(row.expirationDate)
                            },
                            {
                                name: t('Status'),
                                key: 'status',
                                default: true,
                                valueGetter: row => row.inviteStatus.toLowerCase()
                            },
                        ],
                        rowActionsConfig: [
                            {
                                title: t('Accept'),
                                onClick: handleAcceptInvite,
                                // icon: <DeleteForeverIcon />,
                                disabled: (row) => isLoadingAccept(row.id) || isExpired(row.expirationDate, currentLanguage.momentLocale) || isLoadingDecline(row.id),
                            },
                            {
                                title: t('Decline'),
                                onClick: handleDeclineInvite,
                                // icon: <CancelIcon />,
                                disabled: (row) => isLoadingAccept(row.id) || isExpired(row.expirationDate, currentLanguage.momentLocale) || isLoadingDecline(row.id),
                            },
                        ]
                    }}
                    filtersConfig={{
                        genericFilterProps: {
                            configs: [],
                            fetchResultWithSelectedFilters: () => null,
                            isAddFilters: false
                        }
                    }}
                    emptyArrayImageProps={{
                        type: 'any',
                        filterNotApplied: {
                            text: ('Invites will appear here'),
                        },
                    }}
                />
            }


            {/*{!showNoInvites &&*/}
            {/*    <S.FilterContainer>*/}
            {/*        <CustomSearchField*/}
            {/*            variant={'outlined'}*/}
            {/*            onChange={search.handleChange}*/}
            {/*            name={'search'}*/}
            {/*            value={search.search}*/}
            {/*            onClear={search.handleCleanSearch}*/}
            {/*            disabled={isLoading}*/}
            {/*            onKeyDown={search.handleEnterKey}*/}
            {/*            label={t('Search by email')}*/}
            {/*            dir={'ltr'}*/}
            {/*            placeholder={t('Search')}*/}
            {/*            size="small"*/}
            {/*            fullWidth*/}
            {/*        />*/}
            {/*    </S.FilterContainer>*/}
            {/*}*/}

            {/*{(!showNoInvites && !isLessThenCustomQuery) && <InvitesTableHeader />}*/}
            {/*{showNoResult && <Typography sx={{margin: isLessThenCustomQuery ? '16px 0 0 0' : 'initial'}} color={colors.darkBlueText}>{t('Nothing was found for your query. Try changing the query.')}</Typography>}*/}
            {/*{showNoInvites && <Typography color={colors.darkBlueText} sx={{margin: '25px 0 0 0'}}>{t('No invites found.')}</Typography>}*/}

            {/*/!*858*!/*/}
            {/*<S.MainTableScrollWrapper>*/}
            {/*    {!isLessThenCustomQuery ?*/}
            {/*        <>*/}
            {/*            {invites.map(e => <InviteTableRow*/}
            {/*                key={e.id}*/}
            {/*                invite={e}*/}
            {/*                onAccept={handleAcceptInvite}*/}
            {/*                onDecline={handleDeclineInvite}*/}
            {/*                isLoadingAccept={isLoadingAccept(e.id)}*/}
            {/*                isLoadingDecline={isLoadingDecline(e.id)}*/}
            {/*            />)}*/}
            {/*        </>*/}
            {/*        :*/}
            {/*        <>*/}
            {/*            {invites.map(e => <InviteCard*/}
            {/*                key={e.id}*/}
            {/*                invite={e}*/}
            {/*                onAccept={handleAcceptInvite}*/}
            {/*                onDecline={handleDeclineInvite}*/}
            {/*                isLoadingAccept={isLoadingAccept(e.id)}*/}
            {/*                isLoadingDecline={isLoadingDecline(e.id)}*/}
            {/*            />)}*/}
            {/*        </>*/}
            {/*    }*/}
            {/*    {isLoading && (!isLessThenCustomQuery ? <InviteTableSkeletonRow /> : <InviteCardSkeleton />)}*/}
            {/*</S.MainTableScrollWrapper>*/}

            <DeclineInvite />
            <AcceptInviteSuccessDialog/>
        </Flex>
    )
}
