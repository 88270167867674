import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";
import {useTemplatesCreate} from "../../../hooks/templates/useTemplatesCreate";
import PreviewSharpIcon from '@mui/icons-material/PreviewSharp';
import * as S from '../../../components/styled/index';
import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControlLabel,
    Radio,
    TextField,
    Typography
} from "@mui/material";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {AddSystemFieldComponent} from "../../../components/addSystemFieldComponent";
import {AddCustomFieldComponent} from "../../../components/addCustomFieldComponent";
import {selectTemplateTypeDialogProps, TKycTargetType} from "../../../types";
import colors from "../../../../../../newShared/theme/colors";
import {useDrop} from "react-dnd";
import {
    CORPORATE_TARGET_TYPE,
    CRYPTOWALLET_TARGET_TYPE,
    FILE_FIELD_TYPE,
    PERSON_TARGET_TYPE,
    SCREENING_MAX_VALUE,
    SCREENING_MIN_VALUE
} from "../../../constants";
import {AddCustomFileFieldComponent} from "../../../components/addCustomFileFieldComponent";
import {TemplatesChecksTable} from "../../../components/templatesChecksTable";
import {DialogContent} from "../../../../../../newShared/components/editorUnion/components/editorDialog/styled";
import {PreviewTemplateDialog} from "../../../components/dialogs/previewTemplate";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {TemplatesChecksMobileList} from "../../../components/templatesChecksMobileList";
import {CreateTemplateSubContainer} from "../../../components/templatesComponents/CreateTemplateSubContainer";
import {TemplateField} from "../../../components/templatesComponents/templateField";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const TemplatesCreate = () => {
    const {
        actions, isOk, form, fields, handleChange, dragNDrop, screening, isLoading, selectTypeDialog} = useTemplatesCreate(true);
    const [, drop] = useDrop(() => ({ accept: 'field' }))
    const {isMobile} = useMedia();
    const {t} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.templates'});

    return(
        <S.PageContainer>
            <PageTitleAndActions
                handleGoBack={actions.handleGoBack}
                title={t('Custom template')}
                actions={[
                    {
                        title: isMobile ? t('Save') : t('Save template'),
                        onClick: actions.handleSave,
                        disabled: !isOk,
                        variant: 'contained',
                        size: 'small',
                        loading: isLoading
                    },
                    {
                        title: t('Preview'),
                        onClick: actions.handlePreview,
                        icon: <PreviewSharpIcon />,
                        disabled: !isOk || isLoading,
                        variant: 'text',
                        size: 'small'
                    }
                ]}
            />

            <S.CreateTemplateMainContainer>
                <CreateTemplateSubContainer title={t('Description')} description={t('Name your template here, the description is optional.')}>
                    <S.InputTitle>{t('Template name')}<S.RequiredSpan>{" *"}</S.RequiredSpan></S.InputTitle>
                    <S.SubRightContainerInput
                        _margin={'8px 0 0 0'}
                        value={form.name}
                        onChange={handleChange}
                        name={'name'}
                        placeholder={t('Type here...')}
                    />

                    <S.InputTitle _margin={'10px 0 0 0'}>{t('Template description')}</S.InputTitle>
                    <S.SubRightContainerMultilineInput
                        _margin={'8px 0 0 0'}
                        value={form.description}
                        onChange={handleChange}
                        name={'description'}
                        placeholder={t('Type here...')}
                    />
                </CreateTemplateSubContainer>

                <CreateTemplateSubContainer title={t('Fields')} description={t('Some fields are required in templates and can not be removed. You can add Custom or System fields, the order of the System fields can not be reorder.')}>
                    <Flex gap={'10px'} direction={'column'} ref={drop}>
                        {form.fields
                            .filter(e => e.type !== FILE_FIELD_TYPE)
                            .sort((a, b) => a.order - b.order)
                            .map(e => {
                            return(
                                <TemplateField
                                    error={form.fields.filter(f => f.name.trim().toLowerCase() === e.name.trim().toLowerCase()).length > 1}
                                    key={e.id}
                                    field={e}
                                    handleChange={fields.handleChangeCustomField}
                                    handleDelete={fields.handleRemoveField}
                                    handleToggleRequired={fields.handleToggleRequired}
                                    findField={dragNDrop.findField}
                                    moveField={dragNDrop.moveField}
                                />
                            )
                        })}
                        <Flex ai={'center'} gap={'20px'}>
                            <AddSystemFieldComponent
                                title={t('Add system field')}
                                selectedOptions={form.fields.map(e => ({key: e.id, name: e.name}))}
                                options={fields.config.systemFields.filter(e => e.type !== FILE_FIELD_TYPE && e.templateType === form.type).map(e => ({key: e.id, name: e.name}))}
                                disabledChangeOptions={fields.config.systemFields.filter(e => e.requiredForTemplate || (e.templateType !== form.type)).map(e => {
                                    return {name: e.name, key: e.id}
                                })}
                                handleSelectOption={fields.handleSelectOption}
                                handleDeselectOption={fields.handleDeselectOption}
                                handleReset={() => null}
                            />

                            <AddCustomFieldComponent
                                title={t('Add custom field')}
                                onSelect={fields.handleSelectCustomField}
                            />
                        </Flex>
                    </Flex>
                </CreateTemplateSubContainer>

                <CreateTemplateSubContainer title={t('Documents')} description={t('Assign document fields that are essential for this KYC template structure.')}>
                    <Flex gap={'10px'} direction={'column'} ref={drop}>
                        {form.fields
                            .filter(e => e.type === FILE_FIELD_TYPE)
                            .sort((a, b) => a.order - b.order)
                            .map(e => {
                                return(
                                    <TemplateField
                                        error={form.fields.filter(f => f.name.trim().toLowerCase() === e.name.trim().toLowerCase()).length > 1}
                                        key={e.id}
                                        field={e}
                                        handleChange={fields.handleChangeCustomField}
                                        handleDelete={fields.handleRemoveField}
                                        handleToggleRequired={fields.handleToggleRequired}
                                        findField={dragNDrop.findField}
                                        moveField={dragNDrop.moveField}
                                    />
                                )
                            })}
                        <Flex ai={'center'} gap={'20px'}>
                            <AddSystemFieldComponent
                                title={t('Add system field')}
                                selectedOptions={form.fields.filter(e => e.type === FILE_FIELD_TYPE).map(e => ({key: e.id, name: e.name}))}
                                options={fields.config.systemFields.filter(e => e.type === FILE_FIELD_TYPE && e.templateType === form.type).map(e => ({key: e.id, name: e.name}))}
                                disabledChangeOptions={fields.config.systemFields.filter(e => e.requiredForTemplate).map(e => {
                                    return {name: e.name, key: e.id}
                                })}
                                // disabledChangeOptions={[]}
                                handleSelectOption={fields.handleSelectOption}
                                handleDeselectOption={fields.handleDeselectOption}
                                handleReset={() => null}
                            />

                            <AddCustomFileFieldComponent
                                title={t('Add custom field')}
                                onClick={() => fields.handleSelectCustomField('FILE')}
                            />
                        </Flex>
                    </Flex>
                </CreateTemplateSubContainer>

                <CreateTemplateSubContainer disableDivider title={t('Template screening setting')} description={t('Select in how many days the screening will repeat, apply the available checks and matching ratio.')}>
                    <Flex direction={'column'}>
                        <Flex ai={'center'} gap={'10px'}>
                            {/*<Typography variant={'body2'} color={colors.text.grey_dark}>{('Next screening date: ')}</Typography>*/}

                            {/*<Flex>*/}
                            {/*    <DatePicker*/}
                            {/*        value={form.nextScreeningDate}*/}
                            {/*        disablePast*/}
                            {/*        shouldDisableDate={(day) => isSameDay(day, new Date().toISOString(), currentLanguage.momentLocale)}*/}
                            {/*        onChange={(newValue) => screening.handleChangeNextScreeningDate(newValue)}*/}
                            {/*        renderInput={(params) => <TextField*/}
                            {/*            {...params}*/}
                            {/*            error={!form.nextScreeningDate}*/}
                            {/*            size={'small'}*/}
                            {/*        />}*/}
                            {/*    />*/}
                            {/*</Flex>*/}

                            <Typography variant={'body2'} color={colors.grayText}>{t('Repeats every ')}</Typography>

                            <Flex w={'70px'}>
                                <TextField
                                    value={form.screeningSettings.repeat}
                                    onChange={(e) => screening.handleChange(parseInt(e.currentTarget.value))}
                                    type={'number'}
                                    InputProps={{
                                        type: 'number',
                                        inputProps: {
                                            "aria-valuemin": SCREENING_MIN_VALUE,
                                            "aria-valuemax": SCREENING_MAX_VALUE,
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            padding: '10px 8px',
                                            height: '14px'
                                        }}
                                    }
                                    size={'small'}
                                />
                            </Flex>

                            <Typography variant={'body2'} color={colors.grayText}>{t(' days')}</Typography>
                        </Flex>
                    </Flex>
                </CreateTemplateSubContainer>

                {!isMobile ?
                    <TemplatesChecksTable
                        disabledMatchingRatio={form.type === CRYPTOWALLET_TARGET_TYPE}
                        // checks={fields.config.systemChecks}
                        formChecks={form.screeningSettings.checks}
                        onSwitchToggle={screening.onSwitchToggle}
                        onRatioChange={screening.onRatioChange}
                    />
                    :
                    <TemplatesChecksMobileList
                        disabledMatchingRatio={form.type === CRYPTOWALLET_TARGET_TYPE}
                        // checks={fields.config.systemChecks}
                        formChecks={form.screeningSettings.checks}
                        onSwitchToggle={screening.onSwitchToggle}
                        onRatioChange={screening.onRatioChange}
                    />
                }
            </S.CreateTemplateMainContainer>

            <SelectTemplateTypeDialog
                isOpen={selectTypeDialog.isOpen}
                handleGoBack={actions.handleGoBack}
                handleChooseType={selectTypeDialog.handleChooseType}
            />

            <PreviewTemplateDialog />
        </S.PageContainer>
    )
}

const SelectTemplateTypeDialog = ({isOpen, handleChooseType, handleGoBack}: selectTemplateTypeDialogProps) => {
    const [type, setType] = useState<TKycTargetType>(PERSON_TARGET_TYPE);
    const {t} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.dialogs'});
    return(
        <Dialog open={isOpen}>
            <DialogTitle>{t('Custom template')}</DialogTitle>

            <DialogContent sx={{padding: '20px 16px 0', gap: '10px'}}>
                <Typography>{t('Choose which sample you want to use to create a custom template.')}</Typography>
                <Flex
                    p={'8px'}
                    cursor={'pointer'}
                    onClick={() => setType(PERSON_TARGET_TYPE)}
                    w={'100%'}
                    border={`1px solid ${colors.stroke.grey}`}
                    br={'4px'}
                    box_shadow={`0px 0px 1px 0px rgba(125, 151, 174, 0.02), 0px 2px 6px 0px rgba(125, 151, 174, 0.02), 0px 8px 16px 0px rgba(125, 151, 174, 0.08)`}
                >
                    <FormControlLabel value="female" checked={type === PERSON_TARGET_TYPE} control={<Radio />} label={t('Person verification')} />
                </Flex>

                <Flex
                    p={'8px'}
                    cursor={'pointer'}
                    onClick={() => setType(CORPORATE_TARGET_TYPE)}
                    w={'100%'}
                    border={`1px solid ${colors.stroke.grey}`}
                    br={'4px'}
                    box_shadow={`0px 0px 1px 0px rgba(125, 151, 174, 0.02), 0px 2px 6px 0px rgba(125, 151, 174, 0.02), 0px 8px 16px 0px rgba(125, 151, 174, 0.08)`}
                >
                    <FormControlLabel value="female" checked={type === CORPORATE_TARGET_TYPE} control={<Radio />} label={t('Company verification')} />
                </Flex>

                <Flex
                    p={'8px'}
                    cursor={'pointer'}
                    onClick={() => setType(CRYPTOWALLET_TARGET_TYPE)}
                    w={'100%'}
                    border={`1px solid ${colors.stroke.grey}`}
                    br={'4px'}
                    box_shadow={`0px 0px 1px 0px rgba(125, 151, 174, 0.02), 0px 2px 6px 0px rgba(125, 151, 174, 0.02), 0px 8px 16px 0px rgba(125, 151, 174, 0.08)`}
                >
                    <FormControlLabel value="female" checked={type === CRYPTOWALLET_TARGET_TYPE} control={<Radio />} label={t('Crypto wallet verification')} />
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleGoBack} variant={'text'} size={'medium'}>{t('Cancel')}</Button>
                <Button onClick={() => handleChooseType(type)} size={'medium'}>{t('Continue')}</Button>
            </DialogActions>
        </Dialog>
    )
}



