import {useDispatch, useSelector} from "react-redux";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
// import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {dialogs, hideReleaseDialog, loadings, selectedAppOrigin} from "../../../store/slice";
import {ReleaseAccessManagementApp} from "../../../store/actions";
import {TReleaseDialogEmployee} from "../../../../../../newShared/components/releaseDialog/types";

export const useReleaseAccessManagementApp = () => {
    const dispatch = useDispatch();
    const {release} = useSelector(dialogs);
    const {releaseFetch} = useSelector(loadings);
    const app = useSelector(selectedAppOrigin);
    const {setMessage} = useMessageDialog();

    const handleClose = () => {
        dispatch(hideReleaseDialog());
    }

    const handleRelease = ({frameworkId, controlId, evidenceId, readers, reviewers, signers}: {
        frameworkId: string, controlId: string, evidenceId: string,
        reviewers: TReleaseDialogEmployee[], readers: TReleaseDialogEmployee[], signers: TReleaseDialogEmployee[]
    }) => {
        app && dispatch(
            ReleaseAccessManagementApp({
                data: {
                    workspaceId: '',
                    organizationId: '',
                    id: app.id,
                    frameworkId,
                    controlId,
                    evidenceId,
                    readers,
                    reviewers,
                    signers,
                },
                onSuccess
            })
        );
    }

    const onSuccess = () => {
        setMessage({title: ('Completed successfully'), message: ('Automation created!')});
    }

    return {
        isOpen: release,
        handleClose,
        isLoadingRelease: releaseFetch,
        handleRelease,
    }
}