import {useSelector} from 'react-redux';
import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useAppDispatch} from '../../../../../newShared/redux';
import {PATH_LOCAL_VENDORS, PATH_LOCAL_VENDORS_ADD} from '../../constants';
import {
    exportCsvVendorsAction,
    getVendorsAction,
    GetVendorsAvailableFieldsForExport,
    getVendorsSettingsAction,
} from "../../store/actions";
import {
    cleanVendors,
    dialogs,
    hideExportCsv,
    isLoadingSelector,
    loadingsSelector,
    minMaxLoadedPage,
    openExportCsv,
    openReleaseDialog,
    pageInfoSelector,
    replaceMinMaxPage,
    setImportCsvDialogAction,
    setSelectedVendorAction,
    vendorsSelector,
    vendorsSettingsSelector,
} from "../../store/slice"
import {Vendor} from "../../types";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {keyConfig} from '../../../../../newShared/components/genericFilter/types';

export const useVendorsMain = () =>{

    const history = useHistory();
    const dispatch = useAppDispatch();
    const _pageInfo = useSelector(pageInfoSelector);
    const vendors = useSelector(vendorsSelector);
    const isLoading = useSelector(isLoadingSelector);
    const settings = useSelector(vendorsSettingsSelector);
    const {isSettingsLoading, isLoadingFields, isLoadingCSVExport: isLoadingExportCsv} = useSelector(loadingsSelector);
    const {exportCsv: {isOpen, fields}} = useSelector(dialogs);
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    //Vendor
    // const getData = (page: number, count: number, _filter: VendorFilter, enterDown: boolean) => {
    //     !isLoading && dispatch(getVendorsAction({
    //         pageInfo: {
    //             page,
    //             count,
    //             filters: {
    //                 name: enterDown && _filter.search ? [_filter.search] : (!enterDown && _filter.prevSearch ? [_filter.prevSearch] : []),
    //                 risk: (_filter.risk && _filter.risk?.length > 0 && _filter.risk !== ALL_FILTER_SELECT) ? [_filter.risk] : []
    //             }
    //         }
    //     }))
    //    !isLoading && setFilter({..._filter, prevSearch: enterDown && _filter.search ? _filter.search : (!enterDown && _filter.prevSearch ? _filter.prevSearch : '')});
    // }
    const getSettings = () => {
        !isSettingsLoading && dispatch(getVendorsSettingsAction({organizationId: '', workspaceId: ''}));
    }

    const setSelectedVendor = (vendor: Vendor | null) => {dispatch(setSelectedVendorAction(vendor))}

    const goToId = (vendor: Vendor) =>{
        setSelectedVendor(vendor);
        history.push(`${PATH_LOCAL_VENDORS}/${vendor.id}`)
    }

    const handleRelease = () => dispatch(openReleaseDialog());

    //Filter & search
    // const [filter, setFilter] = useState<VendorFilter>({
    //     search: '',
    //     prevSearch: '',
    //     risk: ALL_FILTER_SELECT
    // });
    //
    // const setSearch = (search: string) => setFilter({...filter, search});

    // const handleCleanSearch = () => {
    //     const newFilter = {...filter, search: '', prevSearch: ''};
    //     setFilter(newFilter);
    //     getData(0, DEFAULT_COUNT, newFilter, false);
    // }
    //
    // const handleEnterKey = (e: any) => {
    //     if(e.keyCode === 13 && !filter.search.trim().length){
    //         handleCleanSearch();
    //     }
    //     if(e.keyCode === 13 && filter.search.trim().length > 0){
    //         const newFilter = {...filter, prevSearch: filter.search};
    //         setFilter(newFilter);
    //         getData(0, DEFAULT_COUNT, filter, true);
    //     }
    // }
    //
    // const handleRiskFilterChange = (newRisk: string) => {
    //     const newFilter = {...filter, risk: newRisk ?? ALL_FILTER_SELECT};
    //     setFilter(newFilter);
    //     getData(0, DEFAULT_COUNT, newFilter, false);
    // }
    //
    // const handleLoadMore = (page: number) => {
    //     !isLoading && getData(_pageInfo.page + 1, DEFAULT_COUNT, filter, false);
    // }

    const handleGoToAdd = () => {
        history.push(PATH_LOCAL_VENDORS_ADD)
    };

    const handleImportCSV = () => {
       dispatch(setImportCsvDialogAction({isOpen: true}))
    }

    const handleExportCsv = (fieldKeys: string[]) => {
        dispatch(exportCsvVendorsAction({fieldKeys, isDownload: _pageInfo.total < 10000}))
            // .then(e => {e?.payload && saveAs(b64toBlob(e.payload as string), 'vendors.csv')})
    };

    const handleOpenExportCsv = () => {
        dispatch(openExportCsv());
        dispatch(GetVendorsAvailableFieldsForExport());
    }

    const handleCloseExportCsv = () => {
        dispatch(hideExportCsv());
    }

    const getVendorsSettings = () => {
        dispatch(getVendorsSettingsAction({organizationId: '', workspaceId: ''}));
    }

    useSetBreadcrumbs([
        {
            title: tMenu('Vendors'),
            path: PATH_LOCAL_VENDORS
        }
    ]);

    useEffect(() => {
        !isSettingsLoading && getSettings();
        //eslint-disable-next-line
    },[])

    // useEffect(()=>{
    //     handleCleanSearch();
    //     !isLoading && getData(0, DEFAULT_COUNT, filter, false)
    //     //eslint-disable-next-line
    // },[]);

    const {maxLoadedPage, minLoadedPage} = useSelector(minMaxLoadedPage);

    const riskConfig: keyConfig<string> = {
        type: 'filter',
        key: 'risk',
        name: ('Risk'),
        options: settings.risk.map(e => e.name),
        default: true
    }

    return {
        hasMore: _pageInfo.total > ((_pageInfo.page +1) * _pageInfo.count),
        handleGoToAdd,
        isLoading,
        isLoadingFields,
        vendors,
        // isEmpty: !vendors.length && !filter.prevSearch && filter.risk === ALL_FILTER_SELECT,
        // handleLoadMore,
        goToId,
        handleRelease,

        // setSearch,
        // filter: {
        //     filter: {...filter, risk: filter.risk},
        //     setFilter,
        //     setSearch,
        //     handleEnterKey,
        //     handleCleanSearch,
        //     handleRiskFilterChange,
        // },
        // getData,
        isSettingsLoading,
        settings,
        getSettings,

        handleOpenExportCsv,
        exportCsv: {
            isOpen,
            availableFields: fields,
            handleExportCsv,
            handleClose: handleCloseExportCsv,
        },
        isLoadingExportCsv,
        handleImportCSV,
        getVendorsSettings,
        genericTable: {
            paging: {
                pageInfo: _pageInfo,
                isLoading,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceMinMaxPage(data)),
                clearRows: () => dispatch(cleanVendors()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    dispatch(getVendorsAction({pageInfo: {page, count, filters}}));
                }
            },
            filters: {
                configs: [riskConfig]
            }
        }
    }
}
