import {Component, createRef, Fragment, MouseEventHandler, RefObject} from "react";
import {
    EditorBlockItemType,
    EditorRowData,
    FunctionsForToolBar,
    getToolBarProps,
    MapDispatchToPropsObject,
    TChangeTypeOfBlock,
    TEditorBlockElementData,
    TEditorBlockItemType,
    TEditorColumnElementData,
    TEditorElementData,
    TEditorPagebreakElementData,
    TEditorRowElementData,
    ValueOf
} from "../../types";
import {EditorItemBlock} from "../editorItemBlock";
import {ColumnState, EditorItemColumn} from "../editorItemColumn";
import {EditorItemRowStyled} from "./styled";
import {EditorDropZoneForBlockItem} from "../editorDropZoneForBlockItem";
import {EditorItemPagebreak} from "../editorItemPagebreak";
import {EditorItemPage} from "../editorItemPage";
import {ToolBarProps} from "../editorToolBar";
import {EditorItemTableCell} from "../editorItemTable/components/editorItemTableCell";
import {EditorWorkZone} from "../editorWorkzone";
import {
    changeOldIdToNewId,
    createEventBlockCreatedByDrop,
    createEventDeleteBlock,
    createEventSetHighlightedBlocks,
    isBlockBlock,
    isBlockColumn,
    isRefBlock,
    isRefColumn,
    isRefImage,
    isRefRow
} from "../../helpers";
import {uuid} from "../../../../utils";

interface Props extends getToolBarProps {
    block: TEditorRowElementData;

    onDelete: () => void;

    parentRef: RefObject<EditorItemPage>["current"] | RefObject<EditorItemColumn>["current"] | RefObject<EditorItemTableCell>["current"];
    disabledFocusOnMount?: boolean;
    isDisabledDuplicate?: boolean;
    editorWorkZoneRef: RefObject<EditorWorkZone>["current"];
}
export interface RowState {
    id: string;
    type: string;
    data: TEditorElementData<EditorRowData>["data"];

    wrapperRef: RefObject<HTMLDivElement>;
    refs: Record<string, RefObject<EditorItemColumn> | RefObject<EditorItemBlock> | RefObject<EditorItemPagebreak>>;
}

export class EditorItemRow extends Component<Props, RowState> implements FunctionsForToolBar {
    constructor(props: EditorItemRow["props"]) {
        super(props);

        const refs: RowState["refs"] = {};
        props.block.data.content?.forEach(el => {
            refs[el.id] = createRef<EditorItemBlock>();
            switch (el.type) {
                case EditorBlockItemType.PAGEBREAK: { refs[el.id] = createRef<EditorItemPagebreak>(); break }
                case EditorBlockItemType.COLUMN: { refs[el.id] = createRef<EditorItemColumn>(); break }
                default: { refs[el.id] = createRef<EditorItemBlock>(); break }
            }
        });

        this.state = {
            id: props.block.id,
            type: 'row',
            data: props.block.data,

            wrapperRef: createRef<HTMLDivElement>(),
            refs,
        }
    }

    getFlexBasisOfChildren = (id: string): number => {
        const ref = this.state.refs[id]?.current;
        return ref?.getFlexBasis() ?? 0;
    };
    setFlexBasisOfChildren = (id: string, flexBasis: number) => {
        const ref = this.state.refs[id]?.current;
        ref?.setFlexBasis(flexBasis);
    };

    // handleReplaceRow = (dataRaw: TEditorBlockElementData[]) => {
    //     this.props.parentRef?.handleReplaceRow(this.state.id, dataRaw);
    // }

    handleAddBlock = (index: number, type: ValueOf<TEditorBlockItemType>, dataRaw?: TEditorBlockElementData) => {
        const count = this.state.data.content.length + 1;
        const width = 100 / count;

        this.state.data.content.forEach(e => {
            const oldWidth = this.getFlexBasisOfChildren(e.id);
            this.setFlexBasisOfChildren(e.id, oldWidth - (width / (count - 1)));
        })

        const blockId = 'block' + uuid();
        const data = dataRaw
            ? {
                ...changeOldIdToNewId(JSON.parse(JSON.stringify(dataRaw))).data,
                width: width,
            } : {
                width: width,
                content: [{
                    id: type + uuid(),
                    type,
                    data: {}
                }]
            }

        this.setState(prev => {
            //condition for avoiding setState twice firing in StrictMode
            if (!prev.data.content.some(e => e.id === blockId) && !prev.refs[blockId]) {
                prev.data.content.splice(index, 0, {type: 'block', id: blockId, data});
                prev.refs[blockId] = createRef<EditorItemBlock>()
            }
            return prev
        });

        createEventBlockCreatedByDrop({
            ids: [blockId],
        });
        dataRaw && createEventDeleteBlock({id: dataRaw.id});
    };
    handleDeleteBlock = (id: string) => () => {
        const count = this.state.data.content.length - 1;
        const width = this.getFlexBasisOfChildren(id) / count;

        this.state.data.content.forEach(e => {
            const oldWidth = this.getFlexBasisOfChildren(e.id);
            this.setFlexBasisOfChildren(e.id, oldWidth + width);
        })

        if (this.state.data.content.length === 1) {
            this.props.onDelete();
            return;
        }

        this.setState(prev => {
            const index = prev.data.content.findIndex(e => e.id === id);
            index > -1 && prev.data.content.splice(index, 1);
            delete prev.refs[id];

            return prev
        });
    };

    onResizeParent = () => {
        this.state.data.content.forEach(e => {
            const ref = this.state.refs[e.id]?.current;
            if (isRefImage(ref) || isRefRow(ref) || isRefColumn(ref)) {
                ref.onResizeParent();
            }
        });
    };

    ///////////
    //Resizing
    getLeftRight = (leftIndex: number) => {
        const leftSideId = this.state.data.content[leftIndex].id!;
        const rightSideId = this.state.data.content[leftIndex + 1].id!;
        //
        const leftSide = this.state.refs[leftSideId].current!;
        const rightSide = this.state.refs[rightSideId].current!;

        const leftSideRef = leftSide.state.wrapperRef.current!;
        const rightSideRef = rightSide.state.wrapperRef.current!;

        return {leftSide, rightSide, leftSideRef, rightSideRef};
    };

    mouseDownHandler = (leftIndex: number): MouseEventHandler<HTMLHRElement> => (e) => {
        const {
            leftSide, rightSide,
            leftSideRef, rightSideRef
        } = this.getLeftRight(leftIndex);

        const leftResize = {x: e.clientX, px: leftSideRef.offsetWidth, percent: leftSide.getFlexBasis()}
        const rightResize = {x: e.clientX, px: rightSideRef.offsetWidth, percent: rightSide.getFlexBasis()}

        //@ts-ignore
        leftSide.setState(prev => ({...prev, resize: leftResize}));
        //@ts-ignore
        rightSide.setState(prev => ({...prev, resize: rightResize}));

        // Attach the listeners to `document`
        this.state.wrapperRef.current!.onmousemove = this.mouseMoveHandler(leftIndex);
        this.state.wrapperRef.current!.onmouseup = this.mouseUpHandler(leftIndex);
        this.state.wrapperRef.current!.onmouseleave = this.mouseUpHandler(leftIndex);
        createEventSetHighlightedBlocks({ids: [leftSide.state.id, rightSide.state.id]});
    };

    mouseMoveHandler = (leftIndex: number) => (e: MouseEvent) => {
        const {leftSide, rightSide, leftSideRef, rightSideRef} = this.getLeftRight(leftIndex);

        // How far the mouse has been moved
        const x = leftSide.state.resize.x || 0;
        const dx = e.clientX - x;

        const leftWidth = leftSide.state.resize;
        const rightWidth = rightSide.state.resize;

        const k = (leftWidth.px + rightWidth.px) / (leftWidth.percent + rightWidth.percent);
        const dPercentage = dx / k;

        leftSideRef.style.flexBasis = `${leftWidth.percent + dPercentage}%`;
        rightSideRef.style.flexBasis = `${rightWidth.percent - dPercentage}%`;
        if ((isRefBlock(leftSide) || isRefColumn(leftSide)) && (isRefBlock(rightSide) || isRefColumn(rightSide))) {
            leftSide.onResizeParent();
            rightSide.onResizeParent();
        }

        document.body.style.cursor = 'col-resize';

        leftSideRef.style.userSelect = 'none';
        leftSideRef.style.pointerEvents = 'none';

        rightSideRef.style.userSelect = 'none';
        rightSideRef.style.pointerEvents = 'none';
    };

    mouseUpHandler = (leftIndex: number) => (e: MouseEvent) => {
        const {leftSide, rightSide, leftSideRef, rightSideRef} = this.getLeftRight(leftIndex);

        const leftWidth = leftSide.getFlexBasis();
        const rightWidth = rightSide.getFlexBasis();

        //@ts-ignore
        leftSide.setState((prev: ColumnState) => ({...prev, data: {...prev.data, width: leftWidth}}));
        //@ts-ignore
        rightSide.setState((prev: ColumnState) => ({...prev, data: {...prev.data, width: rightWidth}}));

        document.body.style.removeProperty('cursor');

        leftSideRef.style.removeProperty('user-select');
        leftSideRef.style.removeProperty('pointer-events');

        rightSideRef.style.removeProperty('user-select');
        rightSideRef.style.removeProperty('pointer-events');

        this.state.wrapperRef.current!.onmousemove = null;
        this.state.wrapperRef.current!.onmouseup = null;
        this.state.wrapperRef.current!.onmouseleave = null;
        createEventSetHighlightedBlocks({ids: []});
    };

    changeTypeOfBlock = (id: string) => (newBlock: TChangeTypeOfBlock) => {
        if (this.state.refs[id].current?.state.type === newBlock.type) return;

        //Change column to block
        if (newBlock.type === EditorBlockItemType.BLOCK || newBlock.type === EditorBlockItemType.COLUMN) {
            this.setState(prev => {
                const index = prev.data.content.findIndex(e => e.id === id);
                if (index > -1) {
                    const block = prev.data.content[index];
                    if (isBlockBlock(prev.data.content[index]) || isBlockColumn(prev.data.content[index])) {
                        block.id = id;
                        block.type = newBlock.type;
                        block.data = newBlock.data;
                    }
                }

                return prev
            });
            return;
        }

        //Unwrap row if type row
        if (newBlock.type ===  'row') {
            this.setState(prev => {
                if (!prev.data.content.some(e => e.id === newBlock.data.content[0].id)) {
                    const index = prev.data.content.findIndex(e => e.id === id);
                    if (index > -1) {
                        prev.data.content.splice(index, 1, ...newBlock.data.content);
                        delete prev.refs[id];
                        newBlock.data.content.forEach(el => {
                            switch (el.type) {
                                case EditorBlockItemType.PAGEBREAK: { prev.refs[el.id] = createRef<EditorItemPagebreak>(); break }
                                case EditorBlockItemType.COLUMN: { prev.refs[el.id] = createRef<EditorItemColumn>(); break }
                                default: { prev.refs[el.id] = createRef<EditorItemBlock>(); break }
                            }
                        });
                    }
                }

                return prev
            });
            return;
        }

        if (newBlock.type === "rowArray") {
            this.props.parentRef?.handleReplaceRow(this.state.id, newBlock.data)
        }
    };

    ///////ToolBar
    setToolBar: MapDispatchToPropsObject["setToolBar"] = (toolBar, ids) => {
        this.props.parentRef?.setToolBar(toolBar, [this.state.id, ...ids])
    }
    setTextByToolBar: ToolBarProps["toolBarHandler"] = (action, payload, ids) => {
        const nextId = ids[0];
        if (nextId) {
            const ref = this.state.refs[nextId]?.current;
            if (ref) {
                ref.setTextByToolBar(action, payload, ids.slice(1))
            }
        }
    }

    setActiveBlock: FunctionsForToolBar["setActiveBlock"] = (ids) => {
        this.props.parentRef?.setActiveBlock([this.state.id, ...ids])
    }

    render() {

        return (
            <EditorItemRowStyled ref={this.state.wrapperRef} id={this.state.id}
                                 isChildOfTable={(this.props.parentRef?.state.type === 'tableCell') || undefined}>
                {!this.state.data.content.some(e => e.type === EditorBlockItemType.PAGEBREAK) && (
                    <EditorDropZoneForBlockItem orientation={"vertical"} onDrop={(type, block) => this.handleAddBlock(0, type, block as TEditorBlockElementData)}
                                                readonly={this.props.readonly}/>
                )}

                {this.state.data.content?.map((block, index, array) => (
                    <Fragment key={block.id + ' type ' + block.type}>
                        {block.type === EditorBlockItemType.PAGEBREAK && (
                            <EditorItemPagebreak ref={this.state.refs[block.id] as RefObject<EditorItemPagebreak>}
                                                 block={block as TEditorPagebreakElementData} onDelete={this.props.onDelete}
                                                 parentRef={this}
                                                 editorWorkZoneRef={this.props.editorWorkZoneRef}

                                                 status={this.props.status}
                                                 readonly={this.props.readonly}
                                                 isLoading={this.props.isLoading}
                            />
                        )}
                        {block.type === EditorBlockItemType.COLUMN && (
                            <EditorItemColumn ref={this.state.refs[block.id] as RefObject<EditorItemColumn>}
                                              block={block as TEditorColumnElementData} onDelete={this.handleDeleteBlock(block.id)}
                                              changeTypeOfBlock={this.changeTypeOfBlock(block.id)}
                                              parentRef={this}
                                              editorWorkZoneRef={this.props.editorWorkZoneRef}
                                              getToolBarState={this.props.getToolBarState}
                                              disabledFocusOnMount={this.props.disabledFocusOnMount}
                                              isDisabledDuplicate={this.props.isDisabledDuplicate}

                                              status={this.props.status}
                                              readonly={this.props.readonly}
                                              isLoading={this.props.isLoading}
                            />
                        )}
                        {block.type === EditorBlockItemType.BLOCK && (
                            <EditorItemBlock ref={this.state.refs[block.id] as RefObject<EditorItemBlock>}
                                             block={block as TEditorBlockElementData} onDelete={this.handleDeleteBlock(block.id)}
                                             changeTypeOfBlock={this.changeTypeOfBlock(block.id)}
                                             isNotOneInParent={array.length > 1}
                                             parentRef={this}
                                             editorWorkZoneRef={this.props.editorWorkZoneRef}
                                             getToolBarState={this.props.getToolBarState}

                                             addCopiedBlock={array.length === 1 ? (data) => {
                                                 this.props.parentRef?.addCopiedBlock(data, block.id);
                                             } : undefined}
                                             isDisabledDuplicate={this.props.isDisabledDuplicate}

                                             status={this.props.status}
                                             readonly={this.props.readonly}
                                             isLoading={this.props.isLoading}
                            />
                        )}

                        {!this.state.data.content.some(e => e.type === EditorBlockItemType.PAGEBREAK) && (
                            <EditorDropZoneForBlockItem key={block.id + 'index' + index}
                                                        orientation={"vertical"}
                                                        onDrop={(type, block) => this.handleAddBlock(index + 1, type, block as TEditorBlockElementData)}
                                                        onMouseDown={(index < array.filter(e => e).length - 1) ? this.mouseDownHandler(index) : undefined}
                                                        onHoverResizer={(b) => {
                                                            if (b) createEventSetHighlightedBlocks({ids: [block.id, array[index + 1].id]});
                                                            else createEventSetHighlightedBlocks({ids: []});
                                                        }}
                                                        readonly={this.props.readonly}
                            />
                        )}
                    </Fragment>
                ))}
            </EditorItemRowStyled>
        );
    }
}