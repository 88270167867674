import {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {
    PATH_ACCESS_MANAGEMENT_APPS_EXACT,
    PATH_ACCESS_MANAGEMENT_APPS_EXACT_CREATE_ROW,
    PATH_ACCESS_MANAGEMENT_APPS_EXACT_EDIT_ROW,
    PATH_ACCESS_MANAGEMENT_APPS_LIST,
    PATH_ACCESS_MANAGEMENT_CONFLICT_LIST,
    PATH_ACCESS_MANAGEMENT_PROFILES,
    PATH_ACCESS_MANAGEMENT_REVIEW_EXECUTORS_EXACT,
    PATH_ACCESS_MANAGEMENT_REVIEW_EXECUTORS_LIST,
    PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_EXACT,
    PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_LIST,
    PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_NEW,
    PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES,
    PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET,
    PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET_EXACT,
    PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET_NEW,
    PATH_ACCESS_MANAGEMENT_SOD_SCHEDULER,
} from "./constants";
import {AccessManagementAppsList} from "./pages/list";
import {AccessManagementAppsExact} from "./pages/exact";
import {CreateRow} from "./pages/createRow";
import {EditRow} from "./pages/editRow";
import {SegregationOfDuties} from "./pages/segregationOfDuties/list";
import {AccessManagementProfile} from "./pages/profiles/list";
import {CampaignReviewInstructionsList} from "./pages/compainReview/instruction/list";
import {CampaignReviewInstructionCreate} from "./pages/compainReview/instruction/create";
import {CampaignReviewExact} from "./pages/compainReview/instruction/exact";
import {CampaignReviewExecutorsList} from "./pages/compainReview/executor/list";
import {CampaignReviewExecutorExact} from "./pages/compainReview/executor/exact";
import {RulesetList} from "./pages/ruleset/list";
import {RuleExact} from "./pages/ruleset/ruleExact";
import {ConflictList} from "./pages/conflicts/list";
import {SodScheduler} from "./pages/sodScheduler";
import {RuleNew} from "./pages/ruleset/ruleNew";

export const Routes: FC = () => {
    return (
        <Switch>
            <Route exact path={PATH_ACCESS_MANAGEMENT_APPS_LIST} component={AccessManagementAppsList}/>
            <Route exact path={PATH_ACCESS_MANAGEMENT_APPS_EXACT} component={AccessManagementAppsExact}/>
            <Route exact path={PATH_ACCESS_MANAGEMENT_APPS_EXACT_CREATE_ROW} component={CreateRow}/>
            <Route exact path={PATH_ACCESS_MANAGEMENT_APPS_EXACT_EDIT_ROW} component={EditRow}/>
            {/**/}
            <Route exact path={PATH_ACCESS_MANAGEMENT_REVIEW_EXECUTORS_LIST} component={CampaignReviewExecutorsList} />
            <Route exact path={PATH_ACCESS_MANAGEMENT_REVIEW_EXECUTORS_EXACT} component={CampaignReviewExecutorExact} />
            <Route exact path={PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_LIST} component={CampaignReviewInstructionsList} />
            <Route exact path={PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_EXACT} component={CampaignReviewExact} />
            <Route exact path={PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_NEW} component={CampaignReviewInstructionCreate} />
            {/**/}
            <Route exact path={PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES} component={SegregationOfDuties} />
            <Route exact path={PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET} component={RulesetList} />
            <Route exact path={PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET_EXACT} component={RuleExact} />
            <Route exact path={PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET_NEW} component={RuleNew} />
            <Route exact path={PATH_ACCESS_MANAGEMENT_CONFLICT_LIST} component={ConflictList} />
            <Route exact path={PATH_ACCESS_MANAGEMENT_SOD_SCHEDULER} component={SodScheduler} />
            <Route exact path={PATH_ACCESS_MANAGEMENT_PROFILES} component={AccessManagementProfile} />
            <Redirect to={PATH_ACCESS_MANAGEMENT_APPS_LIST}/>
        </Switch>
    )
}