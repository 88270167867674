import {eraseSelectedTrainingsAction, loadings, trainingsSelector} from "../../store/slice";
import {useDispatch, useSelector} from "react-redux";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_TRAININGS_EXACT, PATH_TRAININGS_LIST, TRAININGS_PATH} from "../../constants";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {
    mainTrainingsChangeTrainingStatusAction,
    mainTrainingsGetTrainingByIdAndVersionAction,
} from "../../store/actions";
import {MainTrainingStatus} from "../../../../../newShared/GQLTypes";
import {useTrainingsDialogs} from "../useTrainingsDialogs";


export const useTrainingsExactPage = () => {
    const {tMenu} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.pathTrainings'});
    const dispatch = useDispatch();
    const {trainingExact: isLoadingExact, changeTrainingStatus: isLoadingChangeTrainingStatus} = useSelector(loadings);
    const {selectedTraining: training} = useSelector(trainingsSelector);
    const {trainingId} = useParams<{trainingId: string}>();
    const {createAssignment: {handleSet: handleSetOpenAssign}} = useTrainingsDialogs();

    const [openedSection, setOpenedSection] = useState<string>('');
    const handleToggleSection = (sectionId: string) => {
        if (openedSection === sectionId) {setOpenedSection('')}
        else setOpenedSection(sectionId);
    }


    const handleOpenAssign = () => {
        handleSetOpenAssign({isOpen: true, training });
    }
    const handleOpenEdit = () => setEditDialog(true);
    const handleChangeStatus = () => {
        if (training && training?.status === MainTrainingStatus.Archived) {
            dispatch(mainTrainingsChangeTrainingStatusAction({data: {trainingId: training.trainingId, status: MainTrainingStatus.Active}, snack: 'success'}))
        } else if (training && training?.status === MainTrainingStatus.Active) {
            dispatch(mainTrainingsChangeTrainingStatusAction({data: {trainingId: training.trainingId, status: MainTrainingStatus.Archived}, snack: 'success'}))
        }
    }


    useSetBreadcrumbs([
        {
            title: tMenu('Training management'),
            path: TRAININGS_PATH,
        },
        {
            title: tMenu('Trainings'),
            path: PATH_TRAININGS_LIST
        },
        {
            title: training?.name ?? 'Loading...',
            path: PATH_TRAININGS_EXACT.replace(':trainingId', trainingId ?? '')
        }
    ]);

    // useEffect(() => {
    //     training && setOpenedSection([]);
    //
    //     return () => {
    //         dispatch(eraseSelectedTrainingsAction());
    //     }
    //     //eslint-disable-next-line
    // }, [training]);


    useEffect(() => {
        training && setOpenedSection('');

        //eslint-disable-next-line
    }, [training]);

    useEffect(() => {
        trainingId && dispatch(mainTrainingsGetTrainingByIdAndVersionAction({data: {trainingId, version: 'latest'}}));

        return () => {
            dispatch(eraseSelectedTrainingsAction());
        }
        //eslint-disable-next-line
    }, []);

    //edit dialog
    const [editDialog, setEditDialog] = useState<boolean>(false);
    const handleEditTrainingClose = () => setEditDialog(false);

    return {
        training,
        openedSection,
        isLoadingExact,
        isLoadingChangeTrainingStatus,
        handleToggleSection,
        handleOpenAssign,
        handleOpenEdit,
        handleChangeStatus,
        editDialog: {
            isOpen: editDialog,
            handleClose: handleEditTrainingClose
        },
    }

}
