import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import React, {FC, useEffect, useState} from "react";
import {useFrameworksDialog} from "../../../hooks/useFrameworksDialog";
import {useControl} from "../../../hooks/useControl";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";


export const NewCommentDialog: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathFrameworks.pathComments'});
    const {tCommon} = useMainTranslation();

    const {newComment: {
        isLoading, isOpen, setNewCommentDialog,
    }} = useFrameworksDialog();
    const {addComment, framework, control} = useControl();
    const {currData: {currentUser}} = UseManageWorkspacesAndOrganizations();
    const handleClose = () => {setNewCommentDialog({clear: true})};
    
    const [text, setText] = useState<string>('');

    useEffect(() => {
        !isOpen && setText('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <Dialog open={isOpen} onClose={handleClose}>

            <DialogTitle>{t('Add new comment')}</DialogTitle>

            <DialogContent>
                <TextField multiline fullWidth minRows={3} maxRows={5} label={tCommon('Enter text')}
                           sx={{marginTop: '10px'}}
                           onChange={e => {setText(e.target.value)}} value={text}/>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => {handleClose()}} variant={"text"}>
                    {tCommon('Cancel')}
                </Button>

                <LoadingButton loading={isLoading} disabled={text.length === 0}
                               onClick={() => {
                                   text !== '' && currentUser && framework && control && addComment({
                                       frameworkId: framework.id,
                                       text, controlId:
                                       control.id, authorId:
                                       currentUser.publicId
                                   })
                               }}>
                    {tCommon('Save')}
                </LoadingButton>
            </DialogActions>

        </Dialog>
    )
}