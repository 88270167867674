import {useControl} from "../../../hooks/useControl";
import {useFrameworksPathParams} from "../../../hooks/useFrameworksPathParams";
import {useEffect, useState} from "react";

export const useAnalysisViewPage = () => {
    const {
        analysis: {selected: currentAnalysis, isLoadingCurrent},
        goToAnalysis, getAnalysisHistory,
    } = useControl();
    const {analysisId, historyId} = useFrameworksPathParams();

    // // Other logic
    const [tab, setTab] = useState<string>('Findings');
    const tabs: {title: string, handleClick: () => void}[] = [
        {
            title: 'Findings',
            handleClick: () => {setTab('Findings')}
        },
        {
            title: 'Solution',
            handleClick: () => {setTab('Solution')}
        },
    ]

    //load analysis if IDs initialized
    useEffect(() => {
        analysisId && historyId && getAnalysisHistory(analysisId, historyId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [analysisId, historyId]);

    return {
        goToAnalysis,
        currentAnalysis,
        tab, tabs,
        isLoadingCurrent
    }
}