import {useAppDispatch} from "../../../../redux";
import React, {useCallback, useEffect, useState} from "react";
import {
    hideAddGroupMessage,
    openAddGroupMessage
} from "../../../../../newDomain/components/documentsRefactor/store/slice";
import {TEditorActor, TOpenDialogEventData} from "../../types";
import {DEFAULT_ACTOR, openManageActorsDialogEventName} from "../../constants";
import {EditorSideMenu} from "../../components/editorSideMenu";
import {v4 as uuid} from "uuid";
import {TForm} from "../../components/dialogs/manageActorsDialog/components/staff";
import {validateEmail} from "../../../../utils/text";
import {getMsFromDays} from "../../../../utils/dateTools";

export const useManageActorsDialogMain = (props?: {
    parentRef: React.RefObject<EditorSideMenu>["current"];
}) => {
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [form, setForm] = useState<TForm>({approvers: [], recipients: []});
    const [isApproversBlock, setIsApproversBlock] = useState<boolean>(false);
    const [isRecipientsCustomOrder, setIsRecipientsCustomOrder] = useState<boolean>(false);
    const [isApproversCustomOrder, setIsApproversCustomOrder] = useState<boolean>(false);

    //recipients
    const [touchedField, setTouchedField] = useState<string | null>(null);
    const [errorActiveFields, setErrorActiveFields] = useState<string[]>([]);


    // const handleIsOkRecipientEmail = ({id, email}:{id: string, email: string}): boolean => {
    //     return email.length > 0 && validateEmail(email) && !errorActiveFields.some(e => e === id);
    // }

    useEffect(() => {
        if (isOpen) {
            const config = props?.parentRef?.getActorsData();
            // const orderedApprovers = config && (config.approvers.length > 1) && (config.approvers[0].order !== config.approvers[1].order);
            // const orderedRecipients = config && (config.recipients.length > 1) && (config.recipients[0]?.order !== config.recipients[1].order);

            const orderedRecipients = config && (config.recipients.length > 0) && (config.recipients[0].order !== 0);
            const orderedApprovers = config && (config.approvers.length > 0) && (config.approvers[0].order !== 0);

            setIsApproversCustomOrder(!!orderedApprovers);
            setIsRecipientsCustomOrder(!!orderedRecipients);
            setIsApproversBlock(Boolean(config && config?.approvers.length > 0));
            setTouchedField(null);
            setErrorActiveFields([]);

            setForm({
                recipients: config?.recipients.map((r): TForm["recipients"][number] => ({
                    ...r,
                    id: uuid(),
                    sourceEmailRole: r.actor.email + r.role,
                })) ?? [],
                approvers: config?.approvers.map(e => e.id ? e : ({
                    ...e, id: 'index' + uuid(),
                    groups: e.groups.map(g => g.id ? g : ({...g, id: 'index' + uuid()}))
                })) ?? []
            });
        } else {
            setForm({approvers: [], recipients: []});
        }
        // eslint-disable-next-line
    }, [isOpen])

    const handleAddRecipient = () => {
        setForm({
            ...form,
            recipients: [
                ...form.recipients,
                {   id: uuid(),
                    fields: null,
                    actor: DEFAULT_ACTOR,
                    order: isRecipientsCustomOrder ? form.recipients.length + 1 : 0, message: '',
                    sourceEmailRole: '',
                    role: '',
                }
            ],
        });

    }
    const handleOpenAddMessage = (data: {approverId?: string, groupId?: string, oldMessage?: string, recipientId?: string}) => {
        dispatch(openAddGroupMessage(data))
    };

    const handleHideAddGroupMessage = () => {
        dispatch(hideAddGroupMessage());
    };

    const handleSetGroupMessage = useCallback((data: {approverId?: string, groupId?: string, recipientId?: string, message: string,}) => {

        if ( data.approverId && data.groupId) {
            setForm({
                ...form,
                approvers: form.approvers.map(appr => appr.id === data.approverId ? ({
                    ...appr,
                    groups: appr.groups.map(gr => gr.id === data.groupId ? ({
                        ...gr,
                        message: data.message,
                    }) : gr)
                }) : appr)
            });

        } else if ( data.recipientId) {
            setForm({
                ...form,
                recipients: form.recipients.map((r) => r.id === data.recipientId ? ({
                    ...r,
                    message: data.message,
                }) : r)
            });

        }
        handleHideAddGroupMessage();
        //eslint-disable-next-line
    }, [form]);

    const handleToggleRecipientOrder = useCallback((isOrdered: boolean) => {
        setForm({
            ...form,
            recipients: form.recipients.map((r, index) => ({
                ...r,
                order: isOrdered ? index + 1 : 0,
            })),
        });

        setIsRecipientsCustomOrder(isOrdered);

        //eslint-disable-next-line
    }, [form]);

    const handleDeleteRecipient = useCallback((recipientId: string) => {
        setForm({
            ...form,
            recipients: form.recipients.filter((r ) => r.id !== recipientId),
        });
        //eslint-disable-next-line
    }, [form])

    const handleSetActorRecipient = useCallback((recipientId: string, actor: TEditorActor) => {
        setForm({
            ...form,
            recipients: form.recipients.map((r)=> r.id === recipientId ? ({ ...r, actor}) : r),
        });
        //eslint-disable-next-line
    }, [form])

    const handleSetIsOpenByEvent = (ev: Event) => {
        const {detail} = ev as CustomEvent<TOpenDialogEventData>;
        setIsOpen(detail.isOpen);
    }

    const handleClose = () => {
        setIsOpen(false);
    }

    const handleSave = () => {
        props?.parentRef?.setActorsConfig({
            recipients: form.recipients
                .filter(r => r.actor?.email?.length || r.role?.trim().length || r.fields?.length)
                .map((r, index) => ({
                    fields: r.fields,
                    actor: validateEmail(r.actor.email) ? r.actor : {email: '', firstName: '', lastName: ''},
                    order: isRecipientsCustomOrder ? index + 1 : 0,
                    message: r.message,
                    sourceEmailRole: r.sourceEmailRole,
                    role: r.role || '',
                    eta: r.eta ?? getMsFromDays(7),
                })),
            approvers: form.approvers
                .filter(appr => appr.groups.some(gr => gr.actors.some(a => a?.email?.length)))
                .map((appr, index) => ({
                    id: appr.id?.includes('index') ? null : appr.id ,
                    order: isApproversCustomOrder ? index + 1 : 0,
                    eta: appr.eta ?? getMsFromDays(7),
                    groups: appr.groups
                        .map(gr => ({
                            id: gr.id?.includes('index') ? null : gr.id,
                            actors: gr.actors,
                            name: gr.name,
                            isRequired: gr.isRequired,
                            message: gr.message,
                        }))
                })),

        });
        handleClose();

    }

    const isDisabledSave = form.recipients.some((e, index, array) =>
        array.filter(e2 => e2.actor?.email?.trim() === e.actor?.email?.trim() && e.actor?.email?.trim().length).length > 1
    );

    useEffect(() => {
        document.addEventListener(openManageActorsDialogEventName, handleSetIsOpenByEvent);
        return () => {
            document.removeEventListener(openManageActorsDialogEventName, handleSetIsOpenByEvent);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        isOpen,
        form,
        setForm,
        isRecipientsCustomOrder,
        isApproversCustomOrder,
        setIsApproversCustomOrder,
        setIsApproversBlock,
        isApproversBlock,

        handleAddRecipient,
        handleOpenAddMessage,
        handleHideAddGroupMessage,
        handleSetGroupMessage,
        handleToggleRecipientOrder,
        handleDeleteRecipient,
        handleSetActorRecipient,
        handleSetIsOpenByEvent,
        handleClose,
        handleSave,
        isDisabledSave,

        //recipients
        touchedField,
        setTouchedField,
        errorActiveFields,
        setErrorActiveFields,

    }

}
