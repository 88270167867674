import {useExactApp} from "../../hooks/useExactApp";
import React from "react";
import {Box as BBox, Flex} from "../../../../../newShared/components/Layouts";
import * as S from '../../components/styled';
import {TextFieldCustom} from '../../components/styled';
import {Skeleton, TabContext} from "@mui/lab";
import {
    Autocomplete,
    Avatar,
    Box,
    Divider,
    IconButton,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography
} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {IconTextButton, LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {DEFAULT_LOGO_URL} from "../../../../authWorkspacesCookies/settings/constants";
import {getAvatarInitials} from "../../../../../newShared/utils/text";
//icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EastIcon from "@mui/icons-material/East";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {exactAppTabsType} from "../../types";
import {rawDataValueGetter} from "../../helpers";
import InfiniteScroll from "react-infinite-scroller";
//import {AppHistoryTableHeader} from "../../components/appHistoryTableHeader";
import {AppHistoryTableRow} from "../../components/appHistoryTableRow";
import {AppHistoryTableRowSkeleton} from "../../components/appHistoryTableRowSkeleton";
import {
    AWS_CLOUD_TRAIL_ENDPOINT_MAPPING_NAME,
    AWS_CONFIG_ENDPOINT_MAPPING_NAME,
    AWS_EC2_ENDPOINT_MAPPING_NAME,
    AWS_IAM_ENDPOINT_MAPPING_NAME,
    AWS_KMS_ENDPOINT_MAPPING_NAME,
    AWS_RDS_ENDPOINT_MAPPING_NAME,
    AWS_S3_ENDPOINT_MAPPING_NAME,
    AWS_SECURITY_ENDPOINT_MAPPING_NAME,
    AZUREAD_ENDPOINT_MAPPING_NAME,
    BAMBOOHR_ENDPOINT_MAPPING_NAME,
    CLOUDFLARE_ENDPOINT_MAPPING_NAME,
    CONFLUENCE_ENDPOINT_MAPPING_NAME,
    GITHUB_ENDPOINT_MAPPING_NAME,
    GWORKSPACE_ENDPOINT_MAPPING_NAME,
    integrationGodModeKeys,
    INTUNE_ENDPOINT_MAPPING_NAME,
    JIRA_ENDPOINT_MAPPING_NAME,
    MAX_LOG_SIZE_PREVIEW,
    ONELOGIN_ENDPOINT_MAPPING_NAME,
    resetEggMs,
    SALESFORCE_ENDPOINT_MAPPING_NAME,
    ZENDESK_ENDPOINT_MAPPING_NAME
} from "../../constants";
import {EditIntegrationGworkspace} from "../../components/integrationsManagement/editIntegrationComponents/gworkspace";
import DownloadIcon from '@mui/icons-material/Download';

// @ts-ignore
import SyntaxHighlighter from 'react-syntax-highlighter';
// @ts-ignore
import {docco} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {DeleteIntegrationDialog} from "../../components/dialogs/deleteIntegration";
import {PreviewErrorReason} from "../../components/dialogs/previewErrorReason";
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import {AppLogsTableRow} from "../../components/appLogsTableRow";
import {AppLogsTableRowSkeleton} from "../../components/appLogsTableRowSkeleton";
import {LocalSpinner} from "../../../../../newShared/components/Spinner";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {EditIntegrationGithub} from "../../components/integrationsManagement/editIntegrationComponents/github";
import {EditIntegrationConfluence} from "../../components/integrationsManagement/editIntegrationComponents/confluence";
import {EditIntegrationJira} from "../../components/integrationsManagement/editIntegrationComponents/jira";
import {
    EditIntegrationAwsSecurity
} from "../../components/integrationsManagement/editIntegrationComponents/aws_security";
import {EditIntegrationAzureAd} from "../../components/integrationsManagement/editIntegrationComponents/azuread";
import {EditIntegrationIntune} from "../../components/integrationsManagement/editIntegrationComponents/intune";
import {EditIntegrationAwsConfig} from "../../components/integrationsManagement/editIntegrationComponents/aws_config";
import {
    EditIntegrationAwsCloudTrail
} from "../../components/integrationsManagement/editIntegrationComponents/aws_cloud_trail";
import {EditIntegrationAwsKms} from "../../components/integrationsManagement/editIntegrationComponents/aws_kms";
import {EditIntegrationAwsIAM} from "../../components/integrationsManagement/editIntegrationComponents/aws_iam";
import {EditIntegrationAwsEC2} from "../../components/integrationsManagement/editIntegrationComponents/aws_ec2";
import {EditIntegrationAwsRDS} from "../../components/integrationsManagement/editIntegrationComponents/aws_rds";
import {EditIntegrationBamboohr} from "../../components/integrationsManagement/editIntegrationComponents/bamboohr";
import {EditIntegrationZendesk} from "../../components/integrationsManagement/editIntegrationComponents/zendesk";
import {EditIntegrationOnelogin} from "../../components/integrationsManagement/editIntegrationComponents/onelogin";
import {EditIntegrationCloudflare} from "../../components/integrationsManagement/editIntegrationComponents/cloudflare";
import {EditIntegrationAwsS3} from "../../components/integrationsManagement/editIntegrationComponents/aws_s3";
import {HiddenEasterEgg} from "../../../../../newShared/components/easter-egg";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {CustomSearchField} from "../../components/customSearch";
import {EditIntegrationSalesforce} from "../../components/integrationsManagement/editIntegrationComponents/salesforce";
import {useScrollPagination} from "../../../../../newShared/hooks/useScrollPagination";
import {TableRowSkeleton} from "../../../../../newShared/components/tableRowSkeleton";

export const ExactApp = () => {
    const {
        isLoading,
        installed,
        app,
        tab,
        overview,
        actions,
        data,
        // config,
        history,
        normalizedStatus,
        godMode,
        logsData
    } = useExactApp();
    const {isMobile, isLessThenCustomQuery} = useMedia(912);
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'pathIntegrations.ExactApp'});
    const {onScroll, scrollRef} = useScrollPagination({
        arrayLength: data.collectionData.length,
        getData: ({page, count}) => {data.handleLoadMore(page)},
        isLoading: data.isLoading,
        pageInfo: data.collectionDataPageInfo,
        isNoMoreThanTotal: true,
        customDependencies: [],
        is0PageAddictive: false,
        disableInitFetch: true,
    });

    return (
        <Box display={"flex"} flexDirection={"column"} width={'100%'} height={'100%'} overflow={"hidden"}>
            <PageTitleAndActions
                title={installed?.name}
                handleGoBack={actions.handleGoBack}
                showMenuWhen={isMobile}
                icon={
                    <Avatar
                        src={app?.logo ?? DEFAULT_LOGO_URL}
                        sx={{
                            width: '45px',
                            height: '45px',
                            backgroundColor: app?.logo !== null ? 'inherit' : colors.primary.blue,
                            margin: '0 8px'
                        }}
                        imgProps={{width: '20px'}}
                    >
                        <Typography fontSize={'16px'}
                                    fontWeight={300}>{getAvatarInitials(installed?.name?.trim() ?? 'NA')}</Typography>
                    </Avatar>
                }
                titleMaxWidth={'300px'}
                actions={[
                    {
                        variant: 'text',
                        icon: <DeleteForeverIcon/>,
                        onClick: actions.handleDeleteIntegration,
                        title: t('Delete integration'),
                        disabled: isLoading,
                    }
                ]}
                isChildrenOnRight
            >
                <HiddenEasterEgg codeMode={'KEY'} resetEggMs={resetEggMs} code={integrationGodModeKeys} cb={godMode}>
                    <IconTextButton
                        variant={'text'}
                        disabled={isLoading}
                        icon={<RefreshOutlinedIcon fontSize="small"/>}
                        onClick={actions.handleRefresh}
                        sx={{'& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px' : '0 -4px 0 8px',}}}
                    >{('Refresh')}
                    </IconTextButton>
                </HiddenEasterEgg>
            </PageTitleAndActions>


            <Flex ai={'center'}>
                <InfoOutlinedIcon sx={{color: colors.text.blue, margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}/>
                <Typography sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', color: colors.text.blue}}
                            variant={'subtitle2'}>{t('Information')}</Typography>
                <IconButton onClick={actions.toggleOverview}>
                    {overview ? <ExpandLessIcon fontSize={'small'} sx={{color: colors.text.blue}}/> :
                        <ExpandMoreIcon fontSize={'small'} sx={{color: colors.text.blue}}/>}
                </IconButton>
            </Flex>

            {overview &&
                <Flex direction={'column'}>
                    <Typography color={colors.text.grey_dark} variant={'body2'}>{app?.description ??
                        <Skeleton width={400}/>}</Typography>
                    <Flex ai={'center'} m={'16px 0 0 0'}>
                        <Typography color={colors.lightGrayText} variant={'body1'}
                                    sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}>{t('Status')}</Typography>
                        <Typography color={normalizedStatus.color}
                                    variant={'body1'}>{t(`statuses.${normalizedStatus.status}`)}</Typography>
                    </Flex>
                    <Flex ai={'center'} m={'20px 0 0 0'}>
                        <Typography color={colors.lightGrayText} variant={'body1'}
                                    sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}>{t('Certifications')}</Typography>
                        <Typography color={colors.grayText}
                                    variant={'body1'}>{app?.certifications.join(', ')}</Typography>
                    </Flex>
                </Flex>
            }

            {/*TABS*/}
            <TabContext value={tab}>
                <Box>
                    <Tabs variant={'scrollable'} value={tab}
                          onChange={(e, newValue: exactAppTabsType) => actions.handleSetTab(newValue)}>
                        <Tab label={t("Data")} value={'data'} disabled={isLoading} sx={{paddingLeft: '0'}}/>
                        <Tab label={t("History")} value={'history'} disabled={isLoading}/>
                        <Tab label={t("Logs")} value={'logs'} disabled={isLoading}/>
                        <Tab label={t("Configuration")} value={'config'} disabled={isLoading}/>
                    </Tabs>
                </Box>
            </TabContext>

            <Box display={"flex"} flexDirection={"column"} width={'100%'} flexGrow={1} overflow={"hidden"}>
                {tab === 'data' &&
                    <Flex w={'100%'} jc={'space-between'} h={'100%'}>
                        {/*COLLECTION SELECT*/}
                        {!isMobile &&
                            <S.ExactAppCollectionsListContainer>
                                <Flex w={'100%'} jc={'space-between'} h={'100%'}>
                                    <BBox w={'99%'} overflow={'auto'} h={'100%'} maxh={'100%'} p={'0 0 16px 0'}>
                                        {app && app.config && app.config.collectionConfigs && app.config.collectionConfigs?.map((e) => {
                                            const isSelected = data.collectionsTab === e.alias;
                                            return <Typography
                                                key={e.alias}
                                                onClick={() => data.handleSetCollectionTab(e.alias)}
                                                color={isSelected ? colors.text.blue : colors.grayText}
                                                sx={{cursor: 'pointer', margin: '32px 0 0 0'}}
                                            >
                                                {e.alias}
                                            </Typography>
                                        })}
                                    </BBox>
                                    <Divider flexItem orientation={'vertical'} style={{
                                        borderColor: colors.primary.disable,
                                        backgroundColor: colors.darkText
                                    }}/>
                                </Flex>
                            </S.ExactAppCollectionsListContainer>
                        }

                        {/*TABLE CONTAINER*/}
                        <S.ExactAppItemsListContainer rightSideOpened={data.selectedCollectionObject !== null}>
                            <S.ExactAppItemsListSearchContainer
                                rightSideOpened={data.selectedCollectionObject !== null}>
                                <CustomSearchField
                                    variant={'outlined'}
                                    onChange={data.handleChange}
                                    value={data.search}
                                    onClear={data.handleCleanSearch}
                                    disabled={isLoading}
                                    onKeyDown={data.handleEnterKey}
                                    size="small"
                                    fullWidth
                                    name={'search'}
                                    label={t('Search')}
                                    dir={'ltr'}
                                    placeholder={t('Search')}
                                />
                                {isMobile &&
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={app?.config?.collectionConfigs.map(e => e.alias) ?? []}
                                        disabled={isLoading}
                                        // getOptionLabel={(option) => option}
                                        loading={isLoading}
                                        value={data.collectionsTab}
                                        onChange={(e, newValue: string | null) => newValue && data.handleSetCollectionTab(newValue)}
                                        renderInput={(params) => <TextFieldCustom {...params} label={t("Collection")}
                                                                                  size={'small'}/>}
                                    />
                                }
                            </S.ExactAppItemsListSearchContainer>

                            {/*TABLE HEADER*/}
                            {data.isLoading && data.collectionData.length === 0 && <LocalSpinner />}
                            {!data.isLoading && !data.collectionData.length && <Typography variant={'*bodyText2'}>{'No values found'}</Typography>}
                            {data.collectionData.length > 0 &&
                                <TableContainer ref={scrollRef} onScroll={onScroll} sx={{marginTop: 0}}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                {data.currentCollectionConfig?.headers.map((e: string, id, arr) =>
                                                    <TableCell key={e}>
                                                        {String(e)}
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        </TableHead>

                                        {/*TABLE*/}
                                        <TableBody>
                                            {/*<S.MainTableScrollWrapper>*/}
                                            {/*    <InfiniteScroll*/}
                                            {/*        pageStart={0}*/}
                                            {/*        loadMore={(page) => data.handleLoadMore(page)}*/}
                                            {/*        hasMore={data.hasMore}*/}
                                            {/*        initialLoad={false}*/}
                                            {/*        threshold={50}*/}
                                            {/*        useWindow={false}*/}
                                            {/*        style={{*/}
                                            {/*            width: '100%',*/}
                                            {/*            display: 'flex',*/}
                                            {/*            flexDirection: 'column',*/}
                                            {/*            overflow: 'auto'*/}
                                            {/*        }}*/}
                                            {/*    >*/}
                                            {data.collectionData.map(e => {
                                                // console.log(`collectionData: ${JSON.stringify(e)}`);
                                                const values = [];
                                                const selected = JSON.stringify(data.selectedCollectionObject) === JSON.stringify(e);
                                                for (let i = 0; i < (data.currentCollectionConfig?.headers.length ?? 0); i++) {
                                                    values.push(rawDataValueGetter(e, app?.config, data.collectionsTab, i));
                                                }
                                                // console.log(`values: ${JSON.stringify(values)} for headers.length: ${data.currentCollectionConfig?.headers.length}`);
                                                return (
                                                    <>
                                                        <TableRow hover
                                                                  key={values[0]}
                                                                  onClick={() => data.setSelectedCollectionObject(e)}
                                                                  selected={selected}>
                                                            {values.map(v => <TableCell key={v}>
                                                                <Typography variant={'body1'}
                                                                            color={colors.grayText}
                                                                            whiteSpace={'nowrap'}
                                                                            textOverflow={'ellipsis'}
                                                                            lineHeight={'normal'}
                                                                            overflow={'hidden'}>{String(v)}</Typography>
                                                            </TableCell>)}
                                                        </TableRow>
                                                        {/*{<Divider flexItem sx={{*/}
                                                        {/*    bgcolor: colors.primary.disable,*/}
                                                        {/*    borderColor: colors.primary.disable,*/}
                                                        {/*    margin: '4px 0 0 0'*/}
                                                        {/*}}/>}*/}
                                                    </>
                                                )
                                            })}
                                            {data.isLoading && <TableRowSkeleton columns={data.currentCollectionConfig?.headers.length ?? 1}/>}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </S.ExactAppItemsListContainer>

                        {data.selectedCollectionObject &&
                            <S.ExactAppExactItemContainer>
                                <Flex w={'100%'} jc={'space-between'} h={'100%'}>
                                    {!isMobile &&
                                        <Divider flexItem orientation={'vertical'} style={{
                                            borderColor: colors.primary.disable,
                                            backgroundColor: colors.darkText
                                        }}/>
                                    }
                                    <Flex w={'99%'} direction={'column'} overflow={'auto'}>
                                        {isMobile &&
                                            <Flex w={'100%'}>
                                                <IconTextButton
                                                    variant={'text'}
                                                    disabled={isLoading}
                                                    icon={!revDir ?
                                                        <KeyboardBackspaceIcon
                                                            sx={{color: colors.text.blue, cursor: 'pointer'}}/>
                                                        : <EastIcon sx={{color: colors.text.blue, cursor: 'pointer'}}/>
                                                    }
                                                    onClick={data.deselectCollectionObject}
                                                    sx={{'& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px' : '0 -4px 0 8px',}}}
                                                >{t('back to table')}
                                                </IconTextButton>
                                            </Flex>
                                        }

                                        <Flex w={'100%'} maxh={'100%'}>
                                            <SyntaxHighlighter customStyle={{direction: 'ltr'}} language="yaml"
                                                               style={docco}>
                                                {data.selectedCollectionObjectInYaml}
                                            </SyntaxHighlighter>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </S.ExactAppExactItemContainer>
                        }
                    </Flex>
                }

                {tab === 'history' &&
                    <TableContainer>
                        <Table stickyHeader sx={{width: '100%', height: '100%'}}>
                            <TableHead sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '0',
                                // borderRadius: '8px'
                            }}>
                                <TableRow sx={{width: '100%', display: 'flex', justifyContent: 'flex-start'}}>
                                    <TableCell sx={{width: '26%'}}>
                                        <Typography sx={{
                                            fontSize: '14px',
                                            fontWeight: 700,
                                            color: colors.grayText
                                        }}>{t('Launch date')}</Typography>
                                    </TableCell>
                                    <TableCell sx={{width: '26%'}}>
                                        <Typography sx={{
                                            fontSize: '14px',
                                            fontWeight: 700,
                                            color: colors.grayText
                                        }}>{t('Status')}</Typography>
                                    </TableCell>
                                    <TableCell sx={{width: '48%'}}>
                                        <Typography sx={{
                                            fontSize: '14px',
                                            fontWeight: 700,
                                            color: colors.grayText
                                        }}>{t('Comment')}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {/*<AppHistoryTableHeader />*/}
                            <TableBody>
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={(page) => data.handleLoadMore(page)}
                                    hasMore={data.hasMore}
                                    initialLoad={false}
                                    threshold={50}
                                    useWindow={false}
                                    style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}
                                >
                                    {history.appHistory.map(e => <AppHistoryTableRow key={e.id} history={e}/>)}
                                    {history.isLoading && <AppHistoryTableRowSkeleton/>}
                                </InfiniteScroll>
                            </TableBody>
                        </Table>
                    </TableContainer>
                }

                {tab === 'logs' &&
                    <Flex w={'100%'} h={'100%'} jc={'space-between'}>
                        <TableContainer sx={{width: `${logsData.selectedLog !== null ? '40%' : '100%'}`}}>
                            <Table sx={{width: '100%'}}>
                                <TableHead sx={{margin: '25px 0 8px 0', width: '100%'}}>
                                    <TableRow sx={{width: '100%', display: 'flex', justifyContent: 'flex-start'}}>
                                        <TableCell sx={{width: `${logsData.selectedLog !== null ? '50%' : '20%'}`}}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: 700,
                                                color: colors.grayText
                                            }}>{t('Launch date')}</Typography>
                                        </TableCell>

                                        <TableCell sx={{width: `${logsData.selectedLog !== null ? '50%' : '80%'}`}}>
                                            <Typography sx={{
                                                fontSize: '14px',
                                                fontWeight: 700,
                                                color: colors.grayText
                                            }}>{t('ID')}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    <InfiniteScroll
                                        pageStart={0}
                                        loadMore={(page) => logsData.handleLoadMoreLogs(page)}
                                        hasMore={logsData.hasMore}
                                        initialLoad={false}
                                        threshold={50}
                                        useWindow={false}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        {logsData.logs.map(e => <AppLogsTableRow key={e.id} log={e}
                                                                                 selectedLog={logsData.selectedLog}/>)}
                                        {logsData.isLoadingList && <AppLogsTableRowSkeleton
                                            isRightSideOpened={logsData.selectedLog !== null}/>}
                                    </InfiniteScroll>

                                </TableBody>
                            </Table>
                        </TableContainer>

                        <S.ExactAppLogsRightSide isRightSideOpened={logsData.selectedLog !== null}>
                            <Flex w={'100%'} jc={'space-between'} h={'100%'}
                                  p={isMobile ? '0 20px 25px 20px' : '25px 20px'}>
                                {!isMobile &&
                                    <Divider flexItem orientation={'vertical'} style={{
                                        borderColor: colors.primary.disable,
                                        backgroundColor: colors.darkText
                                    }}/>
                                }

                                <Flex w={'99%'} direction={'column'} overflow={'auto'}>
                                    {isMobile &&
                                        <Flex w={'100%'}>
                                            <IconTextButton
                                                variant={'text'}
                                                disabled={isLoading}
                                                icon={!revDir ?
                                                    <KeyboardBackspaceIcon
                                                        sx={{color: colors.text.blue, cursor: 'pointer'}}/>
                                                    : <EastIcon sx={{color: colors.text.blue, cursor: 'pointer'}}/>
                                                }
                                                onClick={logsData.deselectLog}
                                                sx={{'& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px' : '0 -4px 0 8px',}}}
                                            >{t('back to table')}
                                            </IconTextButton>
                                        </Flex>
                                    }

                                    <Flex w={'100%'} ai={'center'} jc={'space-between'} wrap={'wrap'}>
                                        <Typography sx={{color: colors.grayText}}
                                                    variant={'body1'}>{t('{{appName}} log dated', {appName: installed?.name})} {parseDateAuto(logsData.selectedLog?.timestamp, true)}</Typography>
                                        <LoadingButton
                                            sx={{margin: isLessThenCustomQuery ? '16px 0 0 0' : ''}}
                                            loading={logsData.isLoadingDownload}
                                            size={'small'}
                                            startIcon={<DownloadIcon/>}
                                            onClick={logsData.handleDownloadLog}
                                        >
                                            {t('Download')}
                                        </LoadingButton>
                                    </Flex>
                                    {<Divider flexItem sx={{
                                        bgcolor: colors.primary.disable,
                                        borderColor: colors.primary.disable,
                                        margin: '16px 0 0 0'
                                    }}/>}
                                    {logsData.isLoadingExact && <LocalSpinner/>}
                                    {logsData.selectedLog && logsData.selectedLog.size >= MAX_LOG_SIZE_PREVIEW ?
                                        <Typography sx={{margin: '16px 0 0 0', color: colors.grayText}}
                                                    variant={'body2'}>{t('The log is too big for preview. But you can download it.')}</Typography>
                                        :
                                        <Flex w={'100%'} m={'16px 8px'} ai={'center'}>
                                            <SyntaxHighlighter customStyle={{direction: 'ltr'}} language="text"
                                                               style={docco} wrapLines wrapLongLines>
                                                {logsData.logResponse}
                                            </SyntaxHighlighter>
                                        </Flex>
                                    }
                                </Flex>
                            </Flex>
                        </S.ExactAppLogsRightSide>
                    </Flex>
                }

                {tab === 'config' &&
                    <Flex w={'100%'} h={'100%'} direction={'column'} overflow={'auto'}>
                        {app && installed && app.endpointMappingName === GWORKSPACE_ENDPOINT_MAPPING_NAME &&
                            <EditIntegrationGworkspace
                                mainAppId={app.id}
                                details={installed}
                            />}

                        {app && installed && app.endpointMappingName === GITHUB_ENDPOINT_MAPPING_NAME &&
                            <EditIntegrationGithub
                                mainAppId={app.id}
                                details={installed}
                            />}

                        {app && installed && app.endpointMappingName === CONFLUENCE_ENDPOINT_MAPPING_NAME &&
                            <EditIntegrationConfluence
                                mainAppId={app.id}
                                details={installed}
                            />}

                        {app && installed && app.endpointMappingName === JIRA_ENDPOINT_MAPPING_NAME &&
                            <EditIntegrationJira
                                mainAppId={app.id}
                                details={installed}
                            />}

                        {app && installed && app.endpointMappingName === AWS_SECURITY_ENDPOINT_MAPPING_NAME &&
                            <EditIntegrationAwsSecurity
                                mainAppId={app.id}
                                details={installed}
                            />}
                        {app && installed && app.endpointMappingName === AWS_CONFIG_ENDPOINT_MAPPING_NAME &&
                            <EditIntegrationAwsConfig
                                mainAppId={app.id}
                                details={installed}
                            />}
                        {app && installed && app.endpointMappingName === AWS_CLOUD_TRAIL_ENDPOINT_MAPPING_NAME &&
                            <EditIntegrationAwsCloudTrail
                                mainAppId={app.id}
                                details={installed}
                            />}
                        {app && installed && app.endpointMappingName === AWS_KMS_ENDPOINT_MAPPING_NAME &&
                            <EditIntegrationAwsKms
                                mainAppId={app.id}
                                details={installed}
                            />}
                        {app && installed && app.endpointMappingName === AWS_IAM_ENDPOINT_MAPPING_NAME &&
                            <EditIntegrationAwsIAM
                                mainAppId={app.id}
                                details={installed}
                            />}
                        {app && installed && app.endpointMappingName === AWS_EC2_ENDPOINT_MAPPING_NAME &&
                            <EditIntegrationAwsEC2
                                mainAppId={app.id}
                                details={installed}
                            />}
                        {app && installed && app.endpointMappingName === AWS_S3_ENDPOINT_MAPPING_NAME &&
                            <EditIntegrationAwsS3
                                mainAppId={app.id}
                                details={installed}
                            />}
                        {app && installed && app.endpointMappingName === AWS_RDS_ENDPOINT_MAPPING_NAME &&
                            <EditIntegrationAwsRDS
                                mainAppId={app.id}
                                details={installed}
                            />}

                        {app && installed && app.endpointMappingName === AZUREAD_ENDPOINT_MAPPING_NAME &&
                            <EditIntegrationAzureAd
                                mainAppId={app.id}
                                details={installed}
                            />}

                        {app && installed && app.endpointMappingName === INTUNE_ENDPOINT_MAPPING_NAME &&
                            <EditIntegrationIntune
                                mainAppId={app.id}
                                details={installed}
                            />}

                        {app && installed && app.endpointMappingName === BAMBOOHR_ENDPOINT_MAPPING_NAME &&
                            <EditIntegrationBamboohr
                                mainAppId={app.id}
                                details={installed}
                            />}

                        {app && installed && app.endpointMappingName === ZENDESK_ENDPOINT_MAPPING_NAME &&
                            <EditIntegrationZendesk
                                mainAppId={app.id}
                                details={installed}
                            />}

                        {app && installed && app.endpointMappingName === ONELOGIN_ENDPOINT_MAPPING_NAME &&
                            <EditIntegrationOnelogin
                                mainAppId={app.id}
                                details={installed}
                            />}

                        {app && installed && app.endpointMappingName === CLOUDFLARE_ENDPOINT_MAPPING_NAME &&
                            <EditIntegrationCloudflare
                                mainAppId={app.id}
                                details={installed}
                            />}

                        {app && installed && app.endpointMappingName === SALESFORCE_ENDPOINT_MAPPING_NAME &&
                            <EditIntegrationSalesforce
                                mainAppId={app.id}
                                details={installed}
                            />}
                    </Flex>
                }
            </Box>


            {/*DIALOGS*/}
            <DeleteIntegrationDialog/>
            <PreviewErrorReason/>
        </Box>
    )
}