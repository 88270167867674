import {useDispatch, useSelector} from "react-redux";
import {dialogs, eraseSections, loadings, sectionsToAddSelector, setAddExistingSectionsAction} from "../../store/slice";
import {mainTrainingsGetTrainingsAutocompleteApi, mainTrainingsGetTrainingsCategoriesAutocompleteApi} from "../../api";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {
    MainSectionsFullDataFilter,
    MainTrainingContentTypeSectionAddDialog,
    MainTrainingLevel,
    MainTrainingsSectionsFullDataForImportModel,
} from "../../../../../newShared/GQLTypes";
import {defaultPageInfo, sectionTypeTitleAddSectionDialogMapper, trainingLevelTitleMapper} from "../../constants";
//import {getTrainingStatusChip} from "../../helpers";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {CommonFilterType} from "../../../../../newShared/components/genericTable/types";
import {
    mainTrainingsGetSectionsFullDataListForImportAction,
    mainTrainingsGetSectionsShortDataListForImportWithFilterPaginationAction
} from "../../store/actions";
import {useScrollPagination} from "../../../../../newShared/hooks/useScrollPagination";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {useEffect, useState} from "react";

export const useAddExistingSectionDialog = (onAddSections: (sections: MainTrainingsSectionsFullDataForImportModel[]) => void, excludeTrainingId?: string) => {
    const [isStep1, setIsStep1] = useState(true);
    const dispatch = useDispatch();
    const {t} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.pathTrainings'})
    const {addExistingSections: {isOpen}} = useSelector(dialogs);
    const {sectionsToAdd: isLoadingSections, fullDataSections: isLoadingConfirm} = useSelector(loadings);
    const {
        shortDataSections,
        selectedSections,
        pageInfo,
        //minMaxLoadedPage: {minLoadedPage, maxLoadedPage}
    } = useSelector(sectionsToAddSelector);
    const {currentFiltersForFetch, currentSearchForFetch, clearFilters, isSearchAndFilterEmpty} = useGenericFiltersStorage();
    const fetchPaging = (page: number, count: number, filters: CommonFilterType, sectionNameLike: string, clean?: boolean) => {
        dispatch(mainTrainingsGetSectionsShortDataListForImportWithFilterPaginationAction({data: {pageRequest: {page, count: count || defaultPageInfo.count}, filter: {...filters, sectionNameLike, excludeTrainingId: excludeTrainingId ? [excludeTrainingId] : []}}, clean}))
    }

    const filtersForFetchSectionsFullData: () => MainSectionsFullDataFilter[] = () => {
        const filters: MainSectionsFullDataFilter[] = [];
        shortDataSections?.forEach(training => {
            const filterToAdd: MainSectionsFullDataFilter = {trainingId: training.trainingId, sectionIds: []};
            training.sections?.forEach(section => {
                if(section.sectionId && selectedSections.some(e => section.sectionId === e.sectionId)) {
                    filterToAdd.sectionIds.push(section.sectionId);
                }
            })
            if(filterToAdd.sectionIds.length) {
                filters.push(filterToAdd)
            }
        })

        return filters;
    }
    const handleConfirm = () => {
        dispatch(mainTrainingsGetSectionsFullDataListForImportAction({
            data: {
                filters: {
                    filters: filtersForFetchSectionsFullData()
                },
            },
            onSuccess: (request, response, addictiveData) => {
                onAddSections(response.result);
                dispatch(setAddExistingSectionsAction({isOpen: false}));
            }
        }))
    }
    const isConfirmButtonDisabled = () => !selectedSections.length;

    useEffect(() => {
        if(isOpen){
            fetchPaging(0, 10, {}, '');
            dispatch(eraseSections());
            setIsStep1(true);
        }else{
            clearFilters();
        }
        // eslint-disable-next-line
    }, [isOpen]);

    const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => {
            fetchPaging(page, count, currentFiltersForFetch, currentSearchForFetch);
        },
        arrayLength: shortDataSections.length,
        isLoading: isLoadingSections,
        pageInfo,
        cleanData: () => dispatch(eraseSections()),
        isOnTop: false,
        isNoMoreThanTotal: true,
    })
    const getTotalSections = (): number => {
        let result = 0;
        shortDataSections.forEach(e => {e.sections && (result += e.sections.length)})
        return result;
    }
    const handleClose = () => {
        dispatch(setAddExistingSectionsAction({isOpen: false}));
        dispatch(eraseSections());
    }

    //Filters config
    const levelConfig: keyConfig<MainTrainingLevel> = {
        type: 'filter',
        key: 'trainingLevel',
        name: t('Level'),
        options: Object.values(MainTrainingLevel),
        getOptionLabel: (e) => trainingLevelTitleMapper[e],
        default: true,
    }

    // const statusConfig: keyConfig<MainTrainingStatus> = {
    //     type: 'filter',
    //     key: 'status',
    //     name: t('Status'),
    //     options: Object.values(MainTrainingStatus),
    //     OptionRenderer: (option) => getTrainingStatusChip(option, true),
    //     default: true,
    // }

    const categoryConfig: keyConfig<string> = {
        type: 'filter',
        key: 'trainingCategory',
        name: t('Category'),
        getOptionLabel: (a) => a,
        getFilterValue: (a) => a,
        isOptionEqualToSearch: (a, search) => a.trim().toLowerCase().includes(search.trim().toLowerCase()),
        fetchOptions: (workspaceId, {page, count, search}) => {
            return mainTrainingsGetTrainingsCategoriesAutocompleteApi({ workspaceId, pageRequest: {page, count}, data: search})
                .then(({result, pageInfo}) => ({values: result, pageInfo}))
        },
        default: true,
    }
    const sectionTypeConfig: keyConfig<MainTrainingContentTypeSectionAddDialog> = {
        type: 'filter',
        key: 'sectionType',
        name: t('Type'),
        options: Object.values(MainTrainingContentTypeSectionAddDialog),
        getOptionLabel: (a) => sectionTypeTitleAddSectionDialogMapper[a],
        getFilterValue: (a) => a,
        default: true,

    }
    const trainingNameConfig: keyConfig<string> = {
        type: 'filter',
        key: 'trainingName',
        name: t('Training'),
        default: true,
        isOptionEqualToSearch: (a, search) => a.trim().toLowerCase().includes(search.trim().toLowerCase()),
        getOptionLabel: (a) => a,
        getFilterValue: (a) => a,
        fetchOptions: (workspaceId, {page, count, search}) => {
            return mainTrainingsGetTrainingsAutocompleteApi({ workspaceId, pageRequest: {page, count}, value: search, includeArchive: true})
                .then(({result, pageInfo}) => ({values: result.map(e => e.name), pageInfo}))
        },
    }
    const filters = [
        trainingNameConfig,
        categoryConfig,
        levelConfig,
        //statusConfig,
        sectionTypeConfig,

        ]

    return {
        bottomLoader,
        onScroll,
        scrollRef,
        shortDataSections,
        getTotalSections,
        handleClose,
        isOpen,
        selectedSections,
        fetchPaging,
        handleConfirm,
        isConfirmButtonDisabled,
        isLoadingConfirm,
        isStep1,
        setIsStep1,
        filters,
        isLoadingSections,
        isSearchAndFilterEmpty
    }
}