import React, {FC, ReactNode} from "react";
import {Skeleton, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import colors from "../../theme/colors";
import {OverridableStringUnion} from "@mui/types";
import {Variant} from "@mui/material/styles/createTypography";
import {TypographyPropsVariantOverrides} from "@mui/material/Typography/Typography";

type TProps = {
    labelVariant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>,
    textVariant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>,
    rows: ({
        noVerticalAlign?: boolean,
        label: string,
        text: string | ReactNode,
    } | null)[],
    isLoading: boolean,
    noWrap?: boolean,
}

export const MicroTableWithoutBorder: FC<TProps> = ({rows, isLoading, textVariant, labelVariant, noWrap}) => {

    return (
        <Table sx={{
            '* .MuiTableCell-root': {
                border: 'none !important',
                verticalAlign: 'top',

                padding: '8px 4px',
            },
        }}>
            <TableBody>
                {rows.map((row) => row && (
                    <TableRow key={row.label}>
                        <TableCell sx={{ verticalAlign: !row?.noVerticalAlign ? 'middle !important' : undefined, maxWidth: '200px'}} width={'30%'}>
                            <Typography variant={labelVariant || '*bodyText2_semibold'} noWrap={noWrap} color={colors.text.grey_dark}>
                                {row.label}
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ verticalAlign: !row?.noVerticalAlign ? 'middle !important' : undefined, maxWidth: '200px'}} width={'70%'}>
                            <Typography variant={textVariant || '*bodyText2'} color={colors.text.grey_dark} sx={{wordBreak: 'break-word'}}>
                                {isLoading ? <Skeleton width={'200px'}/> : row.text || '-'}
                            </Typography>
                        </TableCell>
                    </TableRow>
                ))}

            </TableBody>
        </Table>
    )
}
