import {useDispatch, useSelector} from "react-redux";
import {
    isLoadingUpdateVendor,
    isSettingsLoadingSelector,
    selectedVendorSelector,
    vendorsSelector,
    vendorsSettingsSelector
} from "../../store/slice";
import {useEffect, useState} from "react";
import {VendorInput} from "../../../../../newShared/GQLTypes";
import {getVendorsSettingsAction, updateVendorAction} from "../../store/actions";


export const useExactInformation = () => {

    const dispatch = useDispatch();
    const selectedVendor = useSelector(selectedVendorSelector);
    const isLoadingUpdate = useSelector(isLoadingUpdateVendor);
    const settings = useSelector(vendorsSettingsSelector);
    const isSettingsLoading = useSelector(isSettingsLoadingSelector);
    const vendors = useSelector(vendorsSelector);

    const [isInformationEdit, setInformationEdit] = useState<boolean>(false);
    const [tmpVendor, setTmpVendor] = useState<VendorInput | null>(selectedVendor);
    const [isExist, setIsExists] = useState<boolean>(false);


    const editModeInformation = () => {
        setInformationEdit(!isInformationEdit);
    }

    const updateVendor = () => {
        tmpVendor && setTmpVendor({...tmpVendor, name: tmpVendor.name.trim()})
        tmpVendor && dispatch(updateVendorAction({
            organizationId: '',
            workspaceId: '',
            employee:{
                ...tmpVendor
            }
        }));
    };

    const getSettings = () => {
        !isSettingsLoading && dispatch(getVendorsSettingsAction({organizationId: '', workspaceId: ''}));
    }

    const isOk = () => {
        return Boolean(tmpVendor && tmpVendor.name.trim() !== '' && tmpVendor.type && tmpVendor.serviceProvided && tmpVendor.risk)
    }

    useEffect(() => {
        let cond = true;
        if (selectedVendor && tmpVendor && selectedVendor.name.toLowerCase().trim() === tmpVendor.name.toLowerCase().trim()){
            cond = true;
        } else if (tmpVendor){
            cond = !vendors.some(e => e.name.toLowerCase().trim() === tmpVendor.name.toLowerCase().trim());
        }
        setIsExists(!cond);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tmpVendor?.name]);

    useEffect(() => {
        if(!settings.type.length){
            !isSettingsLoading && getSettings();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        !isLoadingUpdate && setTmpVendor(selectedVendor)
        // eslint-disable-next-line
    },[selectedVendor])


        return{
            selectedVendor,
            isInformationEdit,
            editModeInformation,
            updateVendor,
            isLoadingUpdate,
            settings,
            isSettingsLoading,
            tmpVendor,
            setTmpVendor,
            isExist,
            isOk: isOk(),
        }
}
