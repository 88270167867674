import React, {FC} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    DocNameFlexBox,
    DocumentsHeaderTableTypography,
    DocumentsTableBody,
    DocumentsTableContainer,
    DocumentsTableHead,
    DocumentsTableRow,
    StickyHeaderTable,
    TextSmallButton
} from "../../pages/styled";
import * as S from "./styled";
import {TableCell, TableRow, Typography} from "@mui/material";
import {ReactComponent as TemplateIcon} from "../../../../../newShared/images/documents/template-file.svg";
import {tagsColorMapper} from "../tagsChips";
import {TableRowSkeleton} from "../../../../../newShared/components/tableRowSkeleton";
import {TShortDocument} from "../../types";
import {useTemplatePreview} from "../../../../../newShared/components/docTemplateViewer/hooks/useTemplatePreview";

interface TemplatesTableProps {
    files: (TShortDocument | null)[],
    _isLoading: boolean,
    tagColors: tagsColorMapper[];
    handleUseTemplate: (templateId: string) => void;
}

export const TemplatesTable: FC<TemplatesTableProps> = (props) => {
    const {files, _isLoading, handleUseTemplate} = props;
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.GalleryPage'});

    const {handleOpen} = useTemplatePreview();

    return (
        <DocumentsTableContainer >
            <StickyHeaderTable>
                <DocumentsTableHead>
                    <DocumentsTableRow>
                        <S.TemplateNameTableCell>
                            <DocumentsHeaderTableTypography>{t('Name')}</DocumentsHeaderTableTypography>
                        </S.TemplateNameTableCell>
                        {/*<S.TemplateTagsTableCell>*/}
                        {/*    <DocumentsHeaderTableTypography>{t('Tags')}</DocumentsHeaderTableTypography>*/}
                        {/*</S.TemplateTagsTableCell>*/}
                        <S.TemplateActionTableCell/>
                    </DocumentsTableRow>
                </DocumentsTableHead>

                <DocumentsTableBody>
                    {files.map(e => e && (
                        <TableRow hover key={e.id}>
                            <S.TemplateNameTableCell>
                                <DocNameFlexBox>
                                    <TemplateIcon />
                                    <Typography variant={'*bodyText2'}>{e.name}</Typography>
                                </DocNameFlexBox>
                            </S.TemplateNameTableCell>
                            {/*<S.TemplateTagsTableCell>*/}
                            {/*    <S.TemplatesTagsFlexBox>*/}
                            {/*        {e.tags?.map((tag) => <TagsChips key={tag} tag={tag} tagColors={tagColors} />)}*/}
                            {/*    </S.TemplatesTagsFlexBox>*/}
                            {/*</S.TemplateTagsTableCell>*/}
                            <S.TemplateActionTableCell sx={{textAlign: 'end'}}>
                                <TextSmallButton disabled={_isLoading} onClick={() => e?.id && handleOpen(e.id)}>{t('Preview')}</TextSmallButton>
                                <TextSmallButton disabled={_isLoading} onClick={() => e?.id && handleUseTemplate(e.id)}>{t('Use template')}</TextSmallButton>
                            </S.TemplateActionTableCell>
                        </TableRow>
                    ))}
                    {_isLoading && <TableRowSkeleton columns={2} /> }
                    {!_isLoading && !files.length &&
                        <TableRow><TableCell colSpan={2}>{t('No Document in this folder.')}</TableCell></TableRow>
                    }
                </DocumentsTableBody>
            </StickyHeaderTable>
        </DocumentsTableContainer>
    )
}
