import React, {FC} from "react";
import {Box, Typography} from "@mui/material";
import {DeleteForever, SaveAlt, Visibility} from "@mui/icons-material";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {ArchiveReportDialog} from "../../components/dialogs/archiveReportDialog";
import {DeleteReportDialog} from "../../components/dialogs/deleteReportDialog";
import {getNCRStatus} from "../../helpers";

import {useReportViewPage} from "./hooks/useReportViewPage";
import {ReportViewInfo} from "../../components/reportViewInfo";
import {PreviewFullReportNcrDialog} from "../../components/dialogs/previewFullReportNcrDialog";
import {PageWrapper} from "../../../../../newShared/components/Layouts";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {useReportFilter} from "../../hooks/useReportFilter";
import {TReportSmallControl} from "../../types";
import {GenericTable} from "../../../../../newShared/components/genericTable";

export const ReportView: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathReports.View'});
    const {isLessThen1050, isMobile} = useMedia();

    const {
        controlId,
        report, controls,
        IN_REVIEW, PUBLISHED, COMPLETED, REJECTED, ARCHIVED, ALL_NCR_ANSWERED,
        goToArchived, goToGenerated, goToControl, goToIntegrationDetails,
        changeReportStatus,
        isLoading: {isSubmittingReport},
        setPreviewDialog, setArchiveReportDialog, setDeleteReportDialog,
        genericTable,
        // handleThreeDotsOpen, anchorThreeDotsEl, handleThreeDotsClose,
        // onScroll, scrollRef, bottomLoader,
        // value, handleChange, setFilter, handleKeyDown,
        archivedPage,
        reportId
    } = useReportViewPage();

    const {configs} = useReportFilter()

    return (
        <Box display={"flex"} width={'100%'} height={'100%'} overflow={"hidden"}>
            <PageWrapper>

                <PageTitleAndActions
                    handleGoBack={ARCHIVED ? goToArchived : goToGenerated}
                    title={report?.name}
                    showMenuWhen={Boolean(isLessThen1050 || controlId)}
                    showParticularMenuAfterElementIndex={Boolean(isLessThen1050 || controlId) ? 0 : undefined}
                    actions={[
                        {
                            dontShowWhen: !(!ARCHIVED && !REJECTED && !COMPLETED),
                            title: t('Send NCR Response'),
                            onClick: () => {report && ALL_NCR_ANSWERED && changeReportStatus(report.id)},
                            loading: isSubmittingReport,
                            disabled: !PUBLISHED || !ALL_NCR_ANSWERED
                        },
                        {
                            dontShowWhen: !(report && !IN_REVIEW ),
                            title: t('Preview report'),
                            onClick: () => {report && setPreviewDialog({isOpen: true, reportId: report.id})},
                            icon: <Visibility />,
                            disabled: !report,
                            variant: 'text'
                        },
                        {
                            dontShowWhen: !(report),
                            title: t('Integration details'),
                            onClick: () => {report && goToIntegrationDetails(report.id, archivedPage)},
                            icon: <Visibility />,
                            disabled: !report,
                            variant: 'text'
                        },
                        {
                            dontShowWhen: !(report && !ARCHIVED && (REJECTED || COMPLETED)),
                            icon: <SaveAlt />,
                            title: t('Archive report'),
                            onClick: () => {report && setArchiveReportDialog({reportId: report.id, name: report.name, isOpen: true})},
                            disabled: !report,
                            variant: 'text'
                        },
                        {
                            dontShowWhen: !(report && ARCHIVED),
                            icon: <DeleteForever />,
                            onClick: () => {report && setDeleteReportDialog({reportId: report.id, name: report.name, isOpen: true})},
                            disabled: !report,
                            title: t('Delete report'),
                            variant: 'text'
                        }
                    ]}
                />

                <ReportViewInfo/>

                <GenericTable<TReportSmallControl>
                    id={'ReportView'}
                    columnsConfig={{totalName: 'Total controls', disableShowCards: true, disableColumnsSettings: true, forceShowCardsWhen: isMobile}}
                    paging={genericTable.paging}
                    rowsConfig={{
                        rows: controls,
                        customCellPaddings: '8px',
                        // rowActionsConfig: [
                        //     {title: 'View in Org tree', onClick: handleGoToTree},
                        //     {title: 'Delete employee', onClick: handleDelete}
                        // ],
                        getRowUniqueId: (row) => row.id,
                        onRowClick: (row) => {goToControl(reportId ?? '', row.id, archivedPage)},
                        columnsConfigs: [
                            {key: "name", name: 'Control name', default: true, disableNoWrapTable: true},
                            {key: 'ncr', name: 'Review status', default: true, valueRenderer: (row) => {
                                    const {status, color} = getNCRStatus(t, row.ncr?.map(e => e.status))
                                    return <Typography variant={'*bodyText2'} color={color}>{status}</Typography>
                                }
                            },
                            {key: 'auditorEmail', name: 'NCR status', default: true, valueGetter: (row) => row.reviewed ? t('Reviewed') : t('Unreviewed')},
                        ],
                    }}
                    filtersConfig={{
                        searchAsArray: true,
                        searchCustomFilterKeyName: 'input',
                        genericFilterProps: {
                            configs,
                            fetchResultWithSelectedFilters: () => null,
                            isAddFilters: false
                        }
                    }}
                    emptyArrayImageProps={{type: 'reports', filterNotApplied: {text: 'You have not add any controls'}}}
                />

                {/*<GenericFiltersArray*/}
                {/*    configs={configs}*/}
                {/*    fetchResultWithSelectedFilters={applyFilter}*/}
                {/*    isAddFilters={false}*/}
                {/*/>*/}

                {/*<TableContainer onScroll={onScroll} ref={scrollRef} sx={{minHeight: '60px'}}>*/}
                {/*    <Table stickyHeader>*/}
                {/*        <TableHead>*/}
                {/*            <TableRow>*/}
                {/*                <TableCell style={{whiteSpace: "nowrap"}}>{t('Control name')}</TableCell>*/}
                {/*                {!ARCHIVED && <TableCell style={{whiteSpace: "nowrap"}} width={"15%"}>{t('NCR status')}</TableCell>}*/}
                {/*                {!ARCHIVED && <TableCell style={{whiteSpace: "nowrap"}} width={"15%"}>{t('Review status')}</TableCell>}*/}
                {/*            </TableRow>*/}
                {/*        </TableHead>*/}
                {/*        <TableBody>*/}
                {/*            {controls.map((control, index) => control ? (*/}
                {/*                <TableRow key={control.id} onClick={() => {report && goToControl(report.id, control.id, archivedPage)}} hover selected={control.id === controlId}>*/}
                {/*                    <TableCell>{control.name}</TableCell>*/}
                {/*                    {!ARCHIVED && <TableCell style={{color: getNCRStatus(t, control.ncr?.map(e => e.status)).color}}>{getNCRStatus(t, control.ncr?.map(e => e.status)).status}</TableCell>}*/}
                {/*                    {!ARCHIVED && <TableCell>{control.reviewed ? t('Reviewed') : t('Unreviewed')}</TableCell>}*/}
                {/*                </TableRow>*/}
                {/*            ) : <TableRowSkeleton key={'loader' + index} columns={!ARCHIVED ? 3 : 1}/>)}*/}

                {/*            {(bottomLoader || isLoadingControls) && <TableRowSkeleton columns={!ARCHIVED ? 3 : 1}/>}*/}
                {/*        </TableBody>*/}
                {/*    </Table>*/}
                {/*</TableContainer>*/}

                {/*<EmptyArrayImage type={"frameworks"} isShowing={controls.length === 0 && !isLoadingControls} text={'You have not add any controls'} withoutAdd/>*/}
            </PageWrapper>

            <ArchiveReportDialog/>
            <DeleteReportDialog/>
            <PreviewFullReportNcrDialog/>
        </Box>
    )
}