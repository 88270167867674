import {Flex} from "../../../../../newShared/components/Layouts";
import {Button, ListItemText, Menu, MenuItem, Skeleton, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import React from "react";
import FilterIcon from '@mui/icons-material/Filter';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import ListItemIcon from "@mui/material/ListItemIcon";
import {TrainingCoverComponentPropsType} from "../../types";
import {useTrainingCover} from "../../hooks/useTrainingCover";
import {CoverGalleryDialog} from "./components/coverGalleryDialog";
import {ACCEPT_IMAGE_PROP_WITHOUT_SVG} from "../../../../../newShared/constants";
import {FileTooLargeDialog} from "./components/fileTooLargeDialog";
import {CropImageDialog} from "./components/cropImageDialog";
import {CoverImageView} from "./CoverImageView";

export const TrainingCoverComponent = (props: TrainingCoverComponentPropsType) => {
    const {
        menu,
        currentCover,
        handleUseFromGallery,
        handleSetNewImage,
        coverGalleryDialog,
        drop,
        fileTooLargeDialog,
        cropImageDialog
    } = useTrainingCover(props);

    return(
        <Flex
            w={'100%'}
            jc={'space-between'}
            ai={'center'}
            // gap={'64px'}
            br={'6px'}
            border={`1px solid ${colors.stroke.table}`}
            box_shadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
            p={'10px'}
            overflow={'hidden'}
            m={props.margin}
        >
            <Flex direction={'column'} jc={'space-between'} h={'100%'}>
                <Typography variant={'*bodyText2_semibold'} color={colors.grayText}>{props.customText ?? ('Training cover')}</Typography>

                <Button
                    variant={'outlined'}
                    size={'small'}
                    aria-label="more"
                    id="long-button"
                    aria-controls={menu.open ? 'long-menu' : undefined}
                    aria-expanded={menu.open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={menu.handleClick}
                >
                    {('Change image')}
                </Button>

                <Menu
                    id="long-menu"
                    MenuListProps={{'aria-labelledby': 'long-button',}}
                    anchorEl={menu.anchorEl}
                    open={menu.open}
                    onClose={menu.handleClose}
                    PaperProps={{
                        style: {
                            boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)',
                            borderRadius: '6px',
                            border: '1px solid #F2F4F7'
                        },
                    }}
                    sx={{
                        '& .MuiMenu-list': {
                            padding: '8px'
                        },
                        '& .MuiMenuItem-root': {
                            margin: '0 auto',
                            borderRadius: '4px',
                            '&:hover': {
                                backgroundColor: '#F2F4F7'
                            }
                        }
                    }}
                >
                    <MenuItem onClick={handleUseFromGallery}>
                        <ListItemIcon>
                            <FilterIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant={'*bodyText2'} color={colors.grayText}>{('Use from gallery')}</Typography>
                        </ListItemText>
                    </MenuItem>

                    <MenuItem onClick={handleSetNewImage}>
                        <ListItemIcon>
                            <AddPhotoAlternateOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant={'*bodyText2'} color={colors.grayText}>{('Set new image')}</Typography>
                        </ListItemText>
                    </MenuItem>
                </Menu>
            </Flex>

            <Flex br={'6px'} ai={'center'} jc={'center'} overflow={'hidden'}>
                {currentCover ?
                    <CoverImageView cover={currentCover} size={'create'}/>
                    :
                    <Skeleton width={124} height={93}/>
                }
            </Flex>

            <input
                id={'input'}
                accept={ACCEPT_IMAGE_PROP_WITHOUT_SVG}
                value=""
                style={{ visibility: "hidden", opacity: 0, position: 'absolute' }}
                ref={drop.ref}
                type="file"
                onChange={(e) => drop.fileHandler(e.target.files)}
            />

            <CoverGalleryDialog {...coverGalleryDialog} />
            <FileTooLargeDialog {...fileTooLargeDialog} />
            <CropImageDialog {...cropImageDialog} />
        </Flex>
    )
}