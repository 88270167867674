import React, {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {
    KYC_CLIENTS_ARCHIVE_LIST_PATH,
    KYC_CLIENTS_ID_FIELDS_PATH,
    KYC_CLIENTS_ID_HISTORY_PATH,
    KYC_CLIENTS_ID_PATH,
    KYC_CLIENTS_ID_RESOLUTIONS_PATH,
    KYC_CLIENTS_ID_SCREENINGS_PATH,
    KYC_CLIENTS_LIST_PATH,
    KYC_MATCHES_LIST_PATH,
    KYC_REQUESTS_EXACT_PATH,
    KYC_REQUESTS_LIST_PATH,
    KYC_SCREENING_EXACT_PATH,
    KYC_SCREENING_LIST_PATH,
    KYC_TEMPLATES_CREATE_PATH,
    KYC_TEMPLATES_EXACT_PATH,
    KYC_TEMPLATES_LIST_PATH
} from "./constants";
import {TemplatesList} from "./pages/tempalates/list";
import {KycClientsList} from "./pages/clients/list";
import {TemplatesCreate} from "./pages/tempalates/create";
import {KycScreeningsList} from "./pages/screening/list";
import {RequestsList} from "./pages/requests/list";
import {RequestsExact} from "./pages/requests/exact";
import {TemplatesExact} from "./pages/tempalates/exact";
import {ExactTarget} from "./pages/clients/exact";
import {KycArchiveClients} from "./pages/clients/archive";
import {KycScreeningReport} from "./pages/screening/exact";
import {MatchesList} from "./pages/matches/list";

export const Routes: FC = () => {
    return (
        <Switch>
            <Route path={KYC_SCREENING_LIST_PATH} exact component={KycScreeningsList}/>
            <Route path={KYC_SCREENING_EXACT_PATH} exact component={KycScreeningReport}/>

            <Route path={KYC_MATCHES_LIST_PATH} exact component={MatchesList}/>

            <Route path={KYC_REQUESTS_LIST_PATH} exact component={RequestsList}/>
            <Route path={KYC_REQUESTS_EXACT_PATH} exact component={RequestsExact}/>

            <Route path={KYC_TEMPLATES_LIST_PATH} exact component={TemplatesList}/>
            <Route path={KYC_TEMPLATES_CREATE_PATH} exact component={TemplatesCreate}/>
            <Route path={KYC_TEMPLATES_EXACT_PATH} exact component={TemplatesExact}/>

            <Route path={KYC_CLIENTS_ARCHIVE_LIST_PATH} exact component={KycArchiveClients}/>
            <Route path={[KYC_CLIENTS_ID_PATH, KYC_CLIENTS_ID_FIELDS_PATH, KYC_CLIENTS_ID_SCREENINGS_PATH, KYC_CLIENTS_ID_RESOLUTIONS_PATH, KYC_CLIENTS_ID_HISTORY_PATH]} exact component={ExactTarget}/>
            <Route path={KYC_CLIENTS_LIST_PATH} exact component={KycClientsList}/>
            {/*<Route path={KYC_CLIENTS_CORPORATE_LIST_PATH} exact component={CorporateClientsList}/>*/}
            {/*<Route path={KYC_CLIENTS_ARCHIVE_LIST_PATH} exact component={() => <h1>KYC_CLIENTS_ARCHIVE_LIST_PATH</h1>}/>*/}
            {/*<Route path={KYC_CLIENTS_ID_PATH} exact component={() => <h1>KYC_CLIENTS_ID_PATH</h1>}/>*/}

            <Redirect to={KYC_CLIENTS_LIST_PATH.replace(':type', 'individual')}/>
        </Switch>
    );
}

