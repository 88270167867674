import {EditIntegrationCommonPropsType} from "../../../../types";
import {useEditGworkspace} from "../../../../hooks/integrationsManagement/editIntegrationComponents/gworkspace";
import {Flex} from "../../../../../../../newShared/components/Layouts";
import * as S from '../../../styled';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import React from "react";
import {IconTextButton, LoadingButton} from "../../../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Typography} from "@mui/material";

export const EditIntegrationGworkspace = (props: EditIntegrationCommonPropsType) => {
    const {
        form,
        actions,
        isOk,
        ref,
        fileName,
        isLoading
    } = useEditGworkspace(props);
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'pathIntegrations.AvailableAppsList.dialogs'});

    return(
        <S.AppConfigContainer>
            <S.TextFieldCustom
                variant={'outlined'}
                name={'name'}
                label={t('Application name')}
                value={form.name}
                onChange={actions.handleChange}
                size={'small'}
            />

            <S.TextFieldCustom
                variant={'outlined'}
                name={'ownerEmail'}
                label={t('Admin Email')}
                disabled
                value={form.integration.adminEmail}
                onChange={actions.handleChangeIntegration}
                sx={{margin: '16px 0 0 0'}}
                size={'small'}
            />

            <Flex w="100%" jc="center" m="16px 0 0 0">
                <div onClick={e => e.stopPropagation()}>
                    <input
                        id={"contained-button-file "}
                        type="file"
                        ref={ref}
                        accept={'.json'}
                        style={{display: 'none'}}
                        onChange={actions.onChangeFileInput}
                    />
                    <label htmlFor={"contained-button-file "}>
                        <IconTextButton
                            variant={'text'}
                            size={'small'}
                            icon={<UploadFileIcon fontSize="small"/>}
                            onClick={actions.filePicker}
                            sx={{'& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px'}}}
                        >{t('Upload Service Account config')}
                        </IconTextButton>
                    </label>
                </div>
            </Flex>

            <Flex jc={'center'} w={'100%'} m={'16px 0 0 0'}>
                {fileName.length > 0 && <Typography variant={'subtitle1'}>{t('File Uploaded')}: {fileName}</Typography>}
            </Flex>

            <Flex w={'100%'} jc={'flex-end'} m={'16px 0 0 0'}>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={actions.handleSubmit}
                    disabled={!isOk}
                >
                    {t('Save')}
                </LoadingButton>
            </Flex>
        </S.AppConfigContainer>
    )
}