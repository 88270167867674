import {print} from "graphql";
import {gql} from "@apollo/react-hooks";

export const sendEmailToResetPassGQL = print(gql`
    mutation sendEmailToResetPassGQL($data:EmailInput!){
        resetPassword(data:$data){
            message
        }
    }
`)

export const sendPassToResetPassGQL = print(gql`
    mutation sendPassToResetPassGQL($data:ChangePasswordOutsideInput!){
        updatePassword(data:$data){
            message
        }
    }
`)