import {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {AutomationsList} from "./pages/list";
import {AutomationsExact} from "./pages/exact";
import {AutomationsCreate} from "./pages/create";
import {AUTOMATIONS_CREATE_PATH, AUTOMATIONS_EXACT_PATH, AUTOMATIONS_LIST_PATH} from "./constants";

export const Routes: FC = () => {
    return (
        <Switch>
            <Route exact path={AUTOMATIONS_LIST_PATH} component={AutomationsList}/>
            <Route exact path={AUTOMATIONS_EXACT_PATH} component={AutomationsExact}/>
            <Route exact path={AUTOMATIONS_CREATE_PATH} component={AutomationsCreate} />
            <Redirect to={AUTOMATIONS_LIST_PATH}/>
        </Switch>
    )
}