import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
  DocumentExecutorGroupModel, DocumentExecutorRecipientModel,
  DocumentExecutorStepModel,
  FrameworksDelegatedTaskStatus,
  MainVicaDelegatedTaskCollaboratorModel,
  MainVicaDelegatedTaskModel,
  MainVicaStepStatus, NewDocDataVariableModel,
} from "../../../../../newShared/GQLTypes";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {CommonFilterType, minMaxLoadedPageType,} from "../../../../../newShared/components/genericTable/types";
import {
  deselectDocument,
  eraseDelegatedTasks, handleReturnToDraftAction,
  loadings,
  openActionDialog,
  openStepFrameworksDialog,
  replaceDelegatedTasksMinMaxLoadedPage, selectedNewDocument,
  setCreateDelegatedTaskAction,
  setSelectStepResponsible,
  vicaDelegatedTasksSelector
} from "../../store/slice";
import {DelegatedTaskStatus} from "../../components/delegatedTaskStatus";
import {
  mainVicaGetDelegatedTaskPerformerAutocompleteApi,
  mainVicaGetDelegatedTaskRequestorAutocompleteApi,
  mainVicaGetDelegatedTaskResolverAutocompleteApi
} from "../../api";
import {
  useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {
  mainVicaChangeStepStatusAction,
  mainVicaGetCategoryStepByIdAction,
  mainVicaGetDelegatedTasksWithFilterPaginationAction
} from "../../store/actions";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_VICA, PATH_VICA_STEP_EXACT, PATH_VICA_STEP_EXACT_TASK_EXACT, PATH_VICA_TASKS} from "../../constants";
import React, {MouseEventHandler, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {vicaTaskAction} from "../../../vica/types";
import {deselectDocument as deselectDocumentDocuments} from "../../../documentsRefactor/store/slice";
import {
  handlePreviewBeforeSendAction,
  InvalidateDocument,
  RestartSignStageByStageIdAndGroupId,
  RestartSignStageByStageIdAndRecipient, SendToNextStepByStageId, StartNewDocumentExecution, UpdateFullDocument
} from "../../../documentsRefactor/store/actions";
import {TEditorActorsConfigFromDB, TEditorElementData} from "../../../../../newShared/components/editorUnion/types";
import {
  UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {useSnack} from "../../../../barsEnvironment/snack/hooks/useSnack";


export const useVicaStepExactPage = () => {

  const {t, tMenu} = useMainTranslation("", {keyPrefix: "pathVica.DelegatedTasks"});
  const dispatch = useDispatch();
  const history = useHistory();
  const {user} = UseManageWorkspacesAndOrganizations();
  const {stepId} = useParams<{stepId: string}>();

  //selectors
  const _selectedDoc = useSelector(selectedNewDocument);
  const snack = useSnack();
  const docId = _selectedDoc?.document?.documentId;
  const { tasks, pageInfo, minMaxLoadedPage, selectedStep } = useSelector(vicaDelegatedTasksSelector);
  const { minLoadedPage, maxLoadedPage } = minMaxLoadedPage;
  const { delegatedTaskList: isLoadingList, changeVicaStepStatus, selectedStep: isLoadingStep } = useSelector(loadings);


  useSetBreadcrumbs([
    {
      title: tMenu('VICA'),
      path: PATH_LOCAL_VICA
    },
    {
      title: tMenu('Task management'),
      path: PATH_VICA_TASKS
    },
    {
      title: selectedStep ? selectedStep.name : 'Loading...',
      path: PATH_VICA_STEP_EXACT
    },
  ]);

  useEffect(() => {
    stepId && !isLoadingStep && dispatch(mainVicaGetCategoryStepByIdAction({data: {stepId}}));

    //eslint-disable-next-line
  }, [stepId]);


  const handleOpenTask = (selected: MainVicaDelegatedTaskModel) => {
    selected && history.replace({
      pathname: `${PATH_VICA_STEP_EXACT_TASK_EXACT.replace(':taskId', selected.id).replace(':stepId', stepId)}`,
      search: history.location.search,
    });
  };

  //change status
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const handleClickStatus: MouseEventHandler<HTMLButtonElement> = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleChangeStatus = (status: MainVicaStepStatus) => {
    setAnchorEl(null);
    selectedStep && selectedStep?.status !== status && dispatch(mainVicaChangeStepStatusAction({data: {stepId: selectedStep.id, status}}));
  }

  const openAssignStepResponsibleDialog = () => {
    selectedStep && dispatch(setSelectStepResponsible({isOpen: true, step: selectedStep}));
  };

  const handleGoBack = () => {
    history.push(PATH_VICA_TASKS);
  }

  //action
  const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpenActions = (event: React.MouseEvent<HTMLElement>) => {
    setActionsAnchorEl(event.currentTarget);
  };
  const handleCloseActions = () => {
    setActionsAnchorEl(null);
  };

  const handleOpenActionDialog = (action: vicaTaskAction) => {
    if(action.type === 'LINK'){
      (action.data !== null && action.data.length > 0) && history.push(action.data);
    }else{
      selectedStep && dispatch(openActionDialog({
        task: selectedStep,
        action
      }));
    }
    handleCloseActions();
  }

  const handleOpenCreateDelegatedTaskDialog = () => {
    selectedStep && dispatch(setCreateDelegatedTaskAction({vicaStepId: selectedStep.id}));
  }

  const handleOpenFrameworksDialog = () => {
    selectedStep && dispatch(openStepFrameworksDialog({step: selectedStep}));
  }



  //filters
  const requestorConfig: keyConfig<MainVicaDelegatedTaskCollaboratorModel> = {
    type: "filter",
    key: "requestor",
    name: t("Requestor"),
    fetchOptions: (workspaceId, {page, count, search}) => mainVicaGetDelegatedTaskRequestorAutocompleteApi({workspaceId, vicaStepId: selectedStep?.id ?? '', pageRequest: {page, count}, search})
        .then(({result, pageInfo}) => ({values: result, pageInfo})),
    getOptionLabel: (a) => a.fullName,
    getFilterValue: (a) => a.collaboratorId,
    isOptionEqual: (a, b) => a.collaboratorId === b.collaboratorId,
    isOptionEqualToSearch: (a, search) =>
        a.fullName.trim().toLowerCase().includes(search.trim().toLowerCase()),
    default: true,
  };

  const statusConfig: keyConfig<FrameworksDelegatedTaskStatus> = {
    type: "filter",
    key: "status",
    name: t("Status"),
    options: Object.values(FrameworksDelegatedTaskStatus),
    OptionRenderer: (a) => DelegatedTaskStatus({status: a, margin: '8px 0 0 0'}),
    default: true,
  };

  const performerConfig: keyConfig<MainVicaDelegatedTaskCollaboratorModel> = {
    type: "filter",
    key: "performer",
    name: t("Performer"),
    fetchOptions: (workspaceId, {page, count, search}) => mainVicaGetDelegatedTaskPerformerAutocompleteApi({workspaceId, vicaStepId: selectedStep?.id ?? '', pageRequest: {page, count}, search})
        .then(({result, pageInfo}) => ({values: result, pageInfo})),
    getOptionLabel: (a) => a.fullName,
    getFilterValue: (a) => a.collaboratorId,
    isOptionEqual: (a, b) => a.collaboratorId === b.collaboratorId,
    isOptionEqualToSearch: (a, search) => a.fullName.trim().toLowerCase().includes(search.trim().toLowerCase()),
    default: false,
  };

  const resolverConfig: keyConfig<MainVicaDelegatedTaskCollaboratorModel> = {
    type: "filter",
    key: "resolver",
    name: t("Resolver"),
    fetchOptions: (workspaceId, {page, count, search}) => mainVicaGetDelegatedTaskResolverAutocompleteApi({workspaceId, vicaStepId: selectedStep?.id ?? '', pageRequest: {page, count}, search})
        .then(({result, pageInfo}) => ({values: result, pageInfo})),
    getOptionLabel: (a) => a.fullName,
    getFilterValue: (a) => a.collaboratorId,
    isOptionEqual: (a, b) => a.collaboratorId === b.collaboratorId,
    isOptionEqualToSearch: (a, search) => a.fullName.trim().toLowerCase().includes(search.trim().toLowerCase()),
    default: false,
  };


  const startDateConfig: keyConfig<string> = {
    type: "date",
    key: "createdDate",
    name: t("Created date"),
    default: true,
  };

  const performDateConfig: keyConfig<string> = {
    type: "date",
    key: "performDate",
    name: t("Performed date"),
    default: false,
  };

  const resolveDateConfig: keyConfig<string> = {
    type: "date",
    key: "resolveDate",
    name: t("Resolved date"),
    default: false,
  };

  const {currentFiltersForFetch, currentSearchForFetch, getCurrentPage} = useGenericFiltersStorage();
  const refreshTable = () => {
    selectedStep && dispatch(mainVicaGetDelegatedTasksWithFilterPaginationAction({data: {vicaStepId: selectedStep.id, pageRequest: { page: getCurrentPage(), count: pageInfo.count }, filter: { ...currentFiltersForFetch, taskIdLike: currentSearchForFetch }}, clean: true}));
  }


  return {
    selectedStep,
    isLoadingStep,
    tasks,
    handleOpenTask,
    handleGoBack,
    refreshTable,
    actions: {
      actionsAnchorEl,
      setActionsAnchorEl,
      open: Boolean(actionsAnchorEl),
      handleOpen: handleOpenActions,
      handleClose: handleCloseActions,
      handleOpenActionDialog,
    },
    changeStatus: {
      isLoading: changeVicaStepStatus,
      anchorEl,
      setAnchorEl,
      handleClickStatus,
      handleChangeStatus,
    },

    openAssignStepResponsibleDialog,
    handleOpenCreateDelegatedTaskDialog,
    handleOpenFrameworksDialog,
    doc: {
      selectedNewDocument: _selectedDoc,
      currentEmail: user?.email ?? '',
      snack,
      loadings: useSelector(loadings),
      docActions: {
        handleCloseEditor: () => {
          dispatch(deselectDocumentDocuments())
          dispatch(deselectDocument())
        },
        handleReturnToDraft: () => {
          dispatch(handleReturnToDraftAction())
        },
        onResendInviteApprover: (step: DocumentExecutorStepModel, group: DocumentExecutorGroupModel) => {
          docId && dispatch(RestartSignStageByStageIdAndGroupId({documentId: docId, stageId: step.id, groupId: group.id}));
        },
        onResendInviteRecipient: (step: DocumentExecutorStepModel, recipient: DocumentExecutorRecipientModel) => {
          docId && dispatch(RestartSignStageByStageIdAndRecipient({documentId: docId, stageId: step.id, recipientEmail: recipient.email}));
        },
        handleSkip: (step: DocumentExecutorStepModel) => {
          docId && dispatch(SendToNextStepByStageId({documentId: docId, stageId: step.id}));
        },
        handleInvalidate: () => {
          if(_selectedDoc && _selectedDoc.document){
            docId && dispatch(InvalidateDocument(docId));
          }
        },
        handleSave: (data: TEditorElementData<{}>[], variables: NewDocDataVariableModel[], docTitle: string, actors: TEditorActorsConfigFromDB) => {
          if(_selectedDoc && _selectedDoc.document) {
            dispatch(UpdateFullDocument({
              documentId: _selectedDoc.document.documentId ?? '',
              name: docTitle,
              data: {
                editor: data,
                variables,
                pdfFileId: _selectedDoc.document.data?.pdfFileId || null,
              },
              recipients: actors.recipients,
              approvers: actors.approvers,
              editors: actors.editors
            }))
          }
        },
        handleSend: (data: TEditorElementData<{}>[], variables: NewDocDataVariableModel[], docTitle: string, actors: TEditorActorsConfigFromDB) => {
          if(_selectedDoc && _selectedDoc.document) {
            dispatch(StartNewDocumentExecution({
              documentId: _selectedDoc.document.documentId ?? '',
              name: docTitle,
              data: {
                editor: data,
                variables,
                pdfFileId: _selectedDoc.document.data?.pdfFileId || null,
              },
              recipients: actors.recipients,
              approvers: actors.approvers,
              editors: actors.editors
            }))
          }
        },
        handlePreviewBeforeSend: (data: TEditorElementData<{}>[], variables: NewDocDataVariableModel[], docTitle: string, actors: TEditorActorsConfigFromDB) => {
          if(_selectedDoc && _selectedDoc.document) {
            dispatch(handlePreviewBeforeSendAction({
              documentId: _selectedDoc.document.documentId ?? '',
              name: docTitle,
              data: {
                editor: data,
                variables,
                pdfFileId: _selectedDoc.document.data?.pdfFileId || null,
              },
              recipients: actors.recipients,
              approvers: actors.approvers,
              editors: actors.editors
            }))
          }
        }
      },
    },

    genericTable: {
      paging: {
        pageInfo,
        isLoading: isLoadingList,
        minLoadedPage,
        maxLoadedPage,
        setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceDelegatedTasksMinMaxLoadedPage(data)),
        clearRows: () => dispatch(eraseDelegatedTasks()),
        fetchPaging: (page: number, count: number, filters: CommonFilterType, ) => {
          stepId && dispatch(mainVicaGetDelegatedTasksWithFilterPaginationAction({data: {vicaStepId: stepId, pageRequest: { page, count }, filter: filters}}));
        },
      },
      filters: {
        configs: [
          requestorConfig,
          startDateConfig,
          performerConfig,
          performDateConfig,
          resolverConfig,
          resolveDateConfig,
          statusConfig,
        ],
      },
    },
  };
};
