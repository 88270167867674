import {useAppDispatch} from "../../../../../../newShared/redux";
import {useSelector} from "react-redux";
import {dialogs, hideEditClientDialog, kycDictionaries, loadings} from "../../../store/slice";
import React, {useEffect} from "react";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {createTargetRequestType, KycExactDictType} from "../../../types";
import {UpdateKycClientsFields} from "../../../store/actions";
import {removeTimeZoneDateFields} from "../../../helpers";


export const useUpdateClient = () => {
    const dispatch = useAppDispatch();
    const {isOpen, selectedClient} = useSelector(dialogs).editClientInfo;
    const {updateTargetValues} = useSelector(loadings);
    const dicts = useSelector(kycDictionaries);

    useEffect(() => {
        if(isOpen && selectedClient){
            setForm({templateId: selectedClient.templateId, fields: selectedClient.fields});
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const handleClose = () => {
        dispatch(hideEditClientDialog());
    }

    const {setForm, form} = useForm<createTargetRequestType>({templateId: '', fields: []});

    const handleChangeCustomField = (event: React.ChangeEvent<HTMLInputElement>) => {
        const id = event.currentTarget.name;
        const value = event.currentTarget.value;
        setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === id ? {...e, value} : e)}))
    }

    const handleChangeDate = (id: string, date: string | null) => {
        setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === id ? {...e, value: date} : e)}))

        // if(date !== null){
        //     try {
        //         const parsed = new Date(Date.parse(date)).toISOString();
        //         setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === id ? {...e, value: parsed} : e)}))
        //     }catch (ex){
        //         setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === id ? {...e, value: null} : e)}))
        //         console.log(`InputClientDataTemplateDialog handleChangeDate ex`, ex)
        //     }
        // }else{
        //     setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === id ? {...e, value: null} : e)}))
        // }
    }

    const isOk = () => {
        // console.log(`form`, form.fields, form.fields.some(e => (e.required && e.value === null) || (e.value !== null && !e.value.trim().length)));
        return !form.fields.some(e => {
            //looking for bad fields
            if(e.required && e.value === null) return true;
            if((e.required && e.value !== null && typeof e.value === 'string' && !e.value.trim().length)) return true;
            if(e.required && e.type === 'FILE' && (e.fileName === null || e.fileName === undefined || (e.fileName.trim().length === 0))) return true;
            if(e.type === 'DATE' && e.value !== null && e.value !== undefined){
                let parsed;
                try {
                    parsed = new Date(Date.parse(e.value)).toISOString();
                }catch (ex){
                    parsed = null;
                }

                return parsed === null;
            }
            return false;
        })
    }


    const handleSubmit = () => {
        if(isOk() && selectedClient){
            const newFields = removeTimeZoneDateFields(form.fields);

            dispatch(UpdateKycClientsFields({
                fields: newFields,
                targetId: selectedClient.id,
            }));
        }
    }

    //comp - upload file - get fileId - fetch outer func - save in form
    const handleFileUpload = (fileId: string, fieldId: string, fileName: string) => {
        setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === fieldId ? {...e, value: fileId, fileName} : e)}))
    }

    const handleDeleteUploadedFile = (fieldId: string) => {
        setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === fieldId ? {...e, value: null, fileName: null} : e)}))
    }

    const handleChangeSelectField = (id: string, value: KycExactDictType | null) => {
        setForm((prev) => ({
            ...prev,
            fields: prev.fields.map(e => e.id === id ? {...e, value: value?.key ?? null} : e)
        }))
    }

    return {
        client: selectedClient,
        isOpen,
        handleClose,
        form,
        handleChangeCustomField,
        handleChangeDate,
        isOk,
        handleFileUpload,
        handleDeleteUploadedFile,
        handleSubmit,
        isLoading: updateTargetValues,
        dicts,
        handleChangeSelectField
    }
}
