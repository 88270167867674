import {Checkbox, Dialog, IconButton, InputAdornment, OutlinedInput, Popper, Typography} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import {addFilterComponentPropsType} from "../../../../../newShared/components/genericFilter/types";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useAddFilters} from "../../../../../newShared/components/genericFilter/hooks/useAddFilters";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {AddFilterButton} from "../../../../../newShared/components/genericFilter/components/styled";
import colors from "../../../../../newShared/theme/colors";
import {Flex} from "../../../../../newShared/components/Layouts";
import {TransitionUp} from "../../../../../newShared/components/genericFilter/components/dialogTransaction";
import {sortAddFiltersList} from "../../../../../newShared/components/genericFilter/helpers";
import ClickAwayListener from "@mui/material/ClickAwayListener";

export interface AddSystemFieldComponentProps extends addFilterComponentPropsType{
    title: string;
}

export function AddSystemFieldComponent(config: AddSystemFieldComponentProps){
    const {t} = useMainTranslation('', {keyPrefix: 'genericFilter'});
    const {isOpen, handleOpen, handleClose, anchorEl, id, search, options} = useAddFilters(config);
    const {isMobile} = useMedia(834);

    return(
        <AddFilterButton
            onClick={handleOpen}
            css={`
                background-color: ${isOpen ? colors.chip.pressed : colors.backgrounds.grey_light};
                color: ${isOpen ? colors.text.white : colors.grayText};
                cursor:pointer;
                & button svg{
                  fill: ${isOpen ? colors.text.white : colors.grayText};
                }
                ${!isOpen && `
                    &:hover{
                      background-color: ${colors.chip.hover}; 
                      & p, & button svg{
                        fill: ${colors.grayText} !important;
                        color: ${colors.grayText} !important;
                      }
                    }
                `}
            `}
        >
            <>
                <Flex>
                    <Typography variant={'body2'} noWrap>{config.title}</Typography>
                </Flex>
                <IconButton size={'small'} sx={{padding: '0'}}>
                    {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon/>}
                </IconButton>

                <Dialog
                    open={isOpen && isMobile}
                    onClose={handleClose}
                    fullScreen
                    TransitionComponent={TransitionUp}
                    sx={{'& .MuiPaper-root': {
                            borderRadius: '40px 40px 0 0',
                            height: '60vh',
                            bottom: 0,
                            position: 'absolute'
                        }}}
                >
                    <Flex direction={'column'} onClick={(e) => e.stopPropagation()} grow={'1'}>
                        <OutlinedInput
                            placeholder={t('Search')}
                            value={search.value}
                            onChange={search.onChange}
                            fullWidth
                            sx={{
                                margin: '16px auto 0',
                                padding: '0',
                                height: '40px',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                '& .MuiButtonBase-root': {
                                    padding: '13px 10px',
                                }
                            }}
                            inputProps={{
                                style: {
                                    width: '88%',
                                    padding: '16px 10px',
                                    height: '14px'
                                }}
                            }
                            size={'small'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <SearchIcon sx={{width: '16px', height: '16px', color: colors.text.grey_dark}}/>
                                </InputAdornment>
                            }
                        />

                        <Flex
                            w={'100%'}
                            p={'16px 20px'}
                            style={{borderTop: `1px solid ${colors.stroke.grey}`}}
                            grow={'1'}
                            overflowy={'auto'}
                            direction={'column'}
                        >
                            {options
                                .slice()
                                .sort((a, b) => sortAddFiltersList(a, b, config.disabledChangeOptions.map(e => e.key)))
                                .map((e, id) => {
                                        const isSelected = config.selectedOptions.some(s => s.key === e.key);
                                        const isDisabled = config.disabledChangeOptions.some(d => d.key === e.key);
                                    return(
                                            <Flex key={e.key} w={'100%'} ai={'center'} gap={'19px'} m={(id > 0) ? '8px 0 0 0' : undefined}>
                                                <Checkbox
                                                    disabled={isDisabled}
                                                    sx={{width: '24px', height: '24px'}}
                                                    checked={isSelected}
                                                    onClick={() => isSelected ? config.handleDeselectOption(e) : config.handleSelectOption(e)}
                                                />
                                                {<Typography variant={'body2'} color={isDisabled ? colors.text.grey : colors.grayText} noWrap>{e.name}</Typography>}
                                            </Flex>
                                        )
                                    }
                                )}
                            {!options.length && <Typography variant={'body2'} color={colors.grayText} noWrap>{t('No results')}</Typography>}
                        </Flex>
                    </Flex>
                </Dialog>

                <MiniCustomDialog isOpen={isOpen && !isMobile} onClose={handleClose} elAnchor={anchorEl} id={id}>
                    <OutlinedInput
                        placeholder={t('Search')}
                        value={search.value}
                        onChange={search.onChange}
                        sx={{
                            padding: '0',
                            height: '38px',
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                            '& .MuiButtonBase-root': {
                                padding: '13px 10px',
                            }
                        }}
                        inputProps={{
                            style: {
                                width: '82%',
                                padding: '10px 8px',
                                height: '14px'
                            }}
                        }
                        size={'small'}
                        endAdornment={
                            <InputAdornment position="end">
                                <SearchIcon sx={{width: '16px', height: '16px', color: colors.text.grey_dark}}/>
                            </InputAdornment>
                        }
                    />

                    <Flex
                        w={'100%'}
                        p={'7px 5px'}
                        style={{borderTop: `1px solid ${colors.stroke.grey}`}}
                        maxh={'294px'}
                        overflowy={'auto'}
                        direction={'column'}
                    >
                        {options
                            .sort((a, b) => sortAddFiltersList(a, b, config.selectedOptions.map(e => e.key)))
                            .map((e, id) => {
                            const isSelected = config.selectedOptions.some(s => s.key === e.key);
                            const isDisabled = config.disabledChangeOptions.some(d => d.key === e.key);
                                return(
                                    <Flex key={e.key} w={'100%'} ai={'center'} gap={'19px'} m={(id > 0) ? '8px 0 0 0' : undefined}>
                                        <Checkbox
                                            disabled={isDisabled}
                                            sx={{width: '24px', height: '24px'}}
                                            checked={isSelected}
                                            onClick={() => isSelected ? config.handleDeselectOption(e) : config.handleSelectOption(e)}
                                        />
                                        {<Typography variant={'body2'} color={isDisabled ? colors.text.grey : colors.grayText} noWrap>{e.name}</Typography>}
                                    </Flex>
                                )
                            }
                        )}
                        {!options.length && <Typography variant={'body2'} color={colors.grayText} noWrap>{t('No results')}</Typography>}
                    </Flex>
                </MiniCustomDialog>
            </>
        </AddFilterButton>
    )
}

export const MiniCustomDialog = ({onClose, isOpen, children, id, elAnchor, padding, width}: {
    onClose: () => void,
    isOpen: boolean,
    children: React.ReactNode | React.ReactNode[],
    id: string | undefined,
    elAnchor: HTMLElement | null,
    padding?: string,
    width?: string
}) => {

    return(
        <>
            {isOpen &&
                <ClickAwayListener onClickAway={onClose}>
                    <Popper id={id} open={isOpen} anchorEl={elAnchor} sx={{zIndex: 1000}} placement={'bottom-start'}>
                        <Flex
                            onClick={(e) => e.stopPropagation()}
                            // top={'37px'}
                            // left={getLeft()}
                            // right={getRight()}
                            // position={'absolute'}
                            w={width ?? '270px'}
                            border={`1px solid ${colors.stroke.grey}`}
                            br={'4px'}
                            style={{filter: `drop-shadow(0px 0.6px 1.8px rgba(0, 0, 0, 0.1)) drop-shadow(0px 3.2px 7.2px rgba(0, 0, 0, 0.13))`}}
                            background={colors.backgrounds.white}
                            direction={'column'}
                            zi={'1000'}
                            p={padding ?? '8px'}
                            css={`& input::placeholder{ font-size: 14px; color: ${colors.text.grey_dark}; font-weight: 400; }`}
                            gap={'8px'}
                        >
                            {children}
                        </Flex>
                    </Popper>
                </ClickAwayListener>
            }
        </>
    )
}