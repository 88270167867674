import {Box, styled, TextField} from "@mui/material";
import colors from "../../../../../../theme/colors";

export const LinkPopperTitleInput = styled(TextField)({
    padding: '0',
    height: '38px',
    '& .MuiOutlinedInput-notchedOutline': {
        // border: 'none',
    },
    '& .MuiButtonBase-root': {
        padding: '13px 10px',
    }
})

LinkPopperTitleInput.defaultProps = {
    variant: 'outlined',
    inputProps: {
        style: {
            // width: '82%',
            padding: '10px 8px',
            height: '14px'
        }
    },
    size: 'small'
}


export const LinkPopperLinkInput = styled(TextField)({
    margin: '16px 0 0 0',
    padding: '0',
    height: '38px',
    '& .MuiOutlinedInput-notchedOutline': {
        // border: 'none',
    },
    '& .MuiButtonBase-root': {
        padding: '13px 10px',
    }
})

LinkPopperLinkInput.defaultProps = {
    variant: 'outlined',
    inputProps: {
        style: {
            // width: '82%',
            padding: '10px 8px',
            height: '14px'
        }
    },
    size: 'small'
}

export const UnlinkButtonContainer = styled('div')`
    display: flex;
    justify-content: flex-end;
  width: 100%;
`;

export const LinkPopperInnerWrapper = styled(Box)`
    border: 1px solid ${colors.stroke.grey};
  border-radius: 4px;
  filter: drop-shadow(0px 0.6px 1.8px rgba(0, 0, 0, 0.1)) drop-shadow(0px 3.2px 7.2px rgba(0, 0, 0, 0.13));
  width: 270px;
  display: flex;
  flex-direction: column;
  background-color: ${colors.backgrounds.white};
  align-items: center;
  padding: 8px 16px 16px;
`;
