import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useApproveRequestRequestAssetDialog} from "../../../hooks/dialogs/useApproveRequestRequestAssetDialog";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useEffect} from "react";

export const ApproveRequestRequestAssetDialog = () => {
    const {handleClose, isOpen, isLoadingApprove, formik} = useApproveRequestRequestAssetDialog();
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets.pathIncomingRequests'});

    useEffect(() => {
        formik.resetForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Approve request asset')}</DialogTitle>
            <DialogContent sx={{gap: '10px',display: 'flex', flexDirection: 'column'}}>
                <Typography variant={'*bodyText2'}>{t('Please leave comment for requestor about request.')}</Typography>
                <TextField
                    multiline
                    rows={4}
                    label={'Comment'}
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.comment}
                    name={'comment'}
                    error={Boolean(formik.errors.comment)}
                    helperText={formik.errors.comment}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} size={'small'} variant={'text'}>{tCommon('Cancel')}</Button>
                <LoadingButton
                    variant={'contained'}
                    onClick={formik.submitForm}
                    disabled={!formik.isValid}
                    loading={isLoadingApprove}
                    size={'small'}
                >
                    {tCommon('Approve')}
                </LoadingButton>

            </DialogActions>
        </Dialog>
    );
};
