import {gql} from "graphql.macro";
import {NCR_Fragment, reportAppFragment, reportControlFragment, reportFragment} from "./fragments";


export const createReport = gql`
    mutation createReport($organizationId: String!, $workspaceId: String!, $report: NewReportInput!, $controls: [NewReportActiveControlInput!]!) {
        createReport(organizationId: $organizationId, workspaceId: $workspaceId, report: $report, controls: $controls) {
            id
            message
        }
    }
`

export const getDataForCreateReportSnapshot = gql`
    query getDataForCreateReportSnapshot($organizationId: String!, $workspaceId: String!, $frameworkId: String!) {
        getDataForCreateReportSnapshot(organizationId: $organizationId, workspaceId: $workspaceId, frameworkId: $frameworkId) {
            id
            name
            active
            evidences {
                id
                name
                status
                active
                assets {
                    id
                    name
                    active
                    type
                    data
                    lastUpdate
                }
            }
        }
    }
`

export const getReports = gql`
    query getReports($organizationId: String!, $workspaceId: String!, $pageInfo: PaginationInput!) {
        getReports(organizationId: $organizationId, workspaceId: $workspaceId, pageInfo: $pageInfo) {
            reports {
                ...reportFragment
            }
            pageInfo {
                page
                count
                total
                sort
            }
        }
    }
    ${reportFragment}
`

export const getReport = gql`
    query getReport($organizationId: String!, $workspaceId: String!, $reportId: String!) {
        getReport(organizationId: $organizationId, workspaceId: $workspaceId, reportId: $reportId) {
            report {
                ...reportFragment
            }
            NCRs {
                id
                status
                type
            }
        }
    }
    ${reportFragment}
`

export const getReportControls = gql`
    query getReportControls($organizationId: String!, $workspaceId: String!, $reportId: String!, $pageInfo: PaginationInputAnyFilter!) {
        getReportControls(organizationId: $organizationId, workspaceId: $workspaceId, reportId: $reportId, pageInfo: $pageInfo) {
            controls {
                id
                name
                description
                reviewed
                ncr {
                    id
                    type
                    status
                }
            }
            users {
                id
                name
                email
            }
            pageInfo {
                page
                count
                total
                sort
            }
        }
    }
`

export const getReportControlById = gql`
    query getReportControlById($organizationId: String!, $workspaceId: String!, $reportId: String!, $controlId: String!) {
        getReportControlById(organizationId: $organizationId, workspaceId: $workspaceId, reportId: $reportId, controlId: $controlId) {
            controls {
                ...reportControlFragment
            }
            users {
                id
                name
                email
            }
        }
    }
    ${reportControlFragment}
`

export const archiveReportByUser = gql`
    mutation archiveReportByUser($workspaceId: String!, $reportId: String!) {
        archiveReportByUser(workspaceId: $workspaceId, reportId: $reportId) {
            message
        }
    }
`

export const deleteReport = gql`
    mutation deleteReport($workspaceId: String!, $id: String!) {
        deleteReport(workspaceId: $workspaceId, id: $id) {
            message
        }
    }
`

export const getFrameworksIdNameLogo = gql`
    query getFrameworksIdNameLogo($organizationId: String!, $workspaceId: String!) {
        getFrameworksControlsEvidencesShortModel(organizationId: $organizationId, workspaceId: $workspaceId) {
            id
            name
            logo
        }
    }
`



export const setSnapshotReportResponseByUser = gql`
    mutation setSnapshotReportResponseByUser($workspaceId: String!, $reportId: String!){
        setSnapshotReportResponseByUser(reportId: $reportId, workspaceId: $workspaceId){
            message
        }
    }
`

export const getReportAssetCollectorDataByUser = gql`
    query getReportAssetCollectorDataByUser($organizationId: String!, $workspaceId: String!, $reportId: String!, $evidenceId: String!, $assetId: String!, $pageInfo: PaginationInput!){
        getReportAssetCollectorDataByUser(organizationId: $organizationId, workspaceId: $workspaceId, reportId: $reportId, evidenceId: $evidenceId, assetId: $assetId, pageInfo: $pageInfo){
            id
            name
            lastCollected
            tableFields
            data
            type
            pageInfo {
                page
                count
                total
                sort
            }
            evidenceId
        }
    }
`



// //NCR
export const leaveResponseToNCRByUser = gql`
    mutation leaveResponseToNCRByUser($data: SetCorrectiveActionInput!, $workspaceId: String!, $reportId: String!, $frameworkId: String!, $controlId: String!, $ncrId: String!) {
        leaveResponseToNCRByUser(data: $data, workspaceId: $workspaceId reportId: $reportId, frameworkId: $frameworkId, controlId: $controlId, ncrId: $ncrId) {
            ...NCR_Fragment
        }
    }
    ${NCR_Fragment}
`

export const uploadAssetsToNCRByUser = gql`
    mutation uploadAssetsToNCRByUser($data: [EvidenceAssetsNcrInput!]!, $workspaceId: String!, $reportId: String!, $frameworkId: String!, $controlId: String!, $ncrId: String!) {
        uploadAssetsToNCRByUser(data: $data, workspaceId: $workspaceId reportId: $reportId, frameworkId: $frameworkId, controlId: $controlId, ncrId: $ncrId) {
            ...NCR_Fragment
        }
    }
    ${NCR_Fragment}
`

export const deleteAssetFromNCRByUser = gql`
    mutation deleteAssetFromNCRByUser($assetId: String!, $workspaceId: String!, $ncrId: String!) {
        deleteAssetFromNCRByUser(assetId: $assetId, workspaceId: $workspaceId, ncrId: $ncrId) {
            ...NCR_Fragment
        }
    }
    ${NCR_Fragment}
`

export const getFullNcrReportPreviewByUser = gql`
    query getFullNcrReportPreviewByUser($organizationId: String!, $workspaceId: String!, $reportId: String!){
        getFullNcrReportPreviewByUser(organizationId: $organizationId, workspaceId: $workspaceId, reportId: $reportId){
            header {
                title
                owner
                classification
                auditCommittee
                status

                approver
                approvalDate
                version
                logo
            }
            firstPage {
                organization
                address
                clientID
                auditeeRepresentative
                auditStandard
                auditType
                auditLead
                auditTeam
                datesOfAudit
                reportDate

                majorNcr
                minorNcr
            }
            ncrList {
                ...NCR_Fragment
            }
        }
    }
    ${NCR_Fragment}
`

export const getIntegrationsReport = gql`
    query getIntegrationsReport($workspaceId: String!, $reportId: String!){
        getIntegrationsReport(workspaceId: $workspaceId, reportId: $reportId) {
            report {
                ...reportFragment
            }

            apps {
                ...reportAppFragment
            }
        }
    }
    ${reportAppFragment}
    ${reportFragment}
`

export const getIntegrationDataByCollectionReports = gql`
    query getIntegrationDataByCollectionReports($workspaceId: String!, $reportId: String!, $appId: String!, $collectionAlias: String!, $pageInfo: PaginationInput!){
        getIntegrationDataByCollectionReports(workspaceId: $workspaceId, reportId: $reportId, appId: $appId, collectionAlias: $collectionAlias, pageInfo: $pageInfo) {
            data
            pageInfo {
                page
                count
                total
                sort
            }
        }
    }
`

export const getAppLogsToDate = gql`
    query getAppLogsToDate($workspaceId: String!, $appId: String!, $pageInfo: PaginationInput!, $date: String!){
        getAppLogsToDate(workspaceId: $workspaceId, appId: $appId, pageInfo: $pageInfo, date: $date) {
            logs {
                id
                applicationId
                workspaceId
                fileId
                size
                timestamp
                prefix
            }
            pageInfo {
                page
                count
                total
                sort
            }
        }
    }
`