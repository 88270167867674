import {TImportCsvDialogForSlice, TSetImportCsvDialogPayload} from "./types";
import {createEvent} from "../editorUnion/helpers";
import {ActionReducerMapBuilder, AsyncThunk, Draft} from "@reduxjs/toolkit";
import {TWithOptions} from "../../types";
import {Exact, ImportCsvFieldInput, ImportCsvFieldsWithId, ImportCsvPerformResponse} from "../../GQLTypes";
import {TApolloErrors} from "../../utils/asyncThunk/types";

export const initialImportCsvDialogState: TImportCsvDialogForSlice = {
    importCsvDialog: {
        isOpen: false,
        isLoading: {
            isGettingFields: false,
            isPerformingCsv: false,
            isLoadingTemplate: false,
        },
        fields: [],
        fileId: null,
        data: {},
    }
}

export const IMPORT_CSV_DIALOG_EVENT_NAME = 'importCsvDialog';
export const resetImportCsvDialog = () => {
    document.dispatchEvent(
        createEvent(IMPORT_CSV_DIALOG_EVENT_NAME, undefined)
    )
};
export const onPerformCsvDialog = (data: {error?: string | null}) => {
    document.dispatchEvent(
        createEvent(IMPORT_CSV_DIALOG_EVENT_NAME, data)
    )
};

export const setImportCsvDialog = <TSlice extends {dialogs: object & TImportCsvDialogForSlice}>(slice: TSlice, {payload}: {payload: TSetImportCsvDialogPayload}) => {
    if (payload.isOpen !== undefined) slice.dialogs.importCsvDialog.isOpen = payload.isOpen;
    if (payload.isLoading !== undefined) slice.dialogs.importCsvDialog.isLoading = payload.isLoading;
    if (payload.fields !== undefined) slice.dialogs.importCsvDialog.fields = payload.fields;
    if (payload.data !== undefined) slice.dialogs.importCsvDialog.data = payload.data;
    if (payload.response !== undefined) slice.dialogs.importCsvDialog.response = payload.response;

    if (payload.reset) slice.dialogs.importCsvDialog = {...initialImportCsvDialogState.importCsvDialog, data: slice.dialogs.importCsvDialog.data, isOpen: slice.dialogs.importCsvDialog.isOpen};
    if (payload.clear) slice.dialogs.importCsvDialog = initialImportCsvDialogState.importCsvDialog;
}

export const addImportCsvActionsToBuilder = <TSlice extends {dialogs: object & TImportCsvDialogForSlice}, TExtraGetFields extends Record<string, string> = {}, TExtraPerform extends Record<string, string> = {}>(
    builder: ActionReducerMapBuilder<TSlice>,
    getFieldsAction: AsyncThunk<ImportCsvFieldsWithId, TWithOptions<Exact<{workspaceId: string; file: string; name: string} & TExtraGetFields>, ImportCsvFieldsWithId, any>, {rejectValue: TApolloErrors}>,
    performAction: AsyncThunk<ImportCsvPerformResponse, TWithOptions<Exact<{workspaceId: string, fileId: string, fields: ImportCsvFieldInput | ImportCsvFieldInput[]} & TExtraPerform>, ImportCsvPerformResponse, any>, {rejectValue: TApolloErrors}>,
    after?: {
        afterGetFields?: (slice: Draft<TSlice>) => void,
        afterPerform?: (slice: Draft<TSlice>) => void,
    }
) => {
    builder
        .addCase(getFieldsAction.pending, (slice: Draft<TSlice>) => {
            slice.dialogs.importCsvDialog.isLoading.isGettingFields = true;
        })
        .addCase(getFieldsAction.rejected, (slice: Draft<TSlice>) => {
            slice.dialogs.importCsvDialog.isLoading.isGettingFields = false;
        })
        .addCase(getFieldsAction.fulfilled, (slice: Draft<TSlice>, {payload}) => {
            slice.dialogs.importCsvDialog.isLoading.isGettingFields = false;
            slice.dialogs.importCsvDialog.fields = payload.fields;
            slice.dialogs.importCsvDialog.fileId = payload.fileId;

            after?.afterGetFields?.(slice);
        })

        .addCase(performAction.pending, (slice: Draft<TSlice>) => {
            slice.dialogs.importCsvDialog.isLoading.isPerformingCsv = true;
        })
        .addCase(performAction.rejected, (slice: Draft<TSlice>) => {
            slice.dialogs.importCsvDialog.isLoading.isPerformingCsv = false;
        })
        .addCase(performAction.fulfilled, (slice: Draft<TSlice>, {payload}) => {
            slice.dialogs.importCsvDialog = {
                ...initialImportCsvDialogState.importCsvDialog,
                isOpen: true,
                response: payload,
                data: slice.dialogs.importCsvDialog.data,
            };

            after?.afterPerform?.(slice);
        })
}

export const acceptItemType = 'CSVField';
