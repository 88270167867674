import {useDispatch, useSelector} from "react-redux";
import {hideTriggerRepeat, selectedAutomation, setTriggerRepeat, triggerRepeat} from "../../../store/slice";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField
} from "@mui/material";
import {useEffect, useState} from "react";
import {mapper, timeValues} from "../../../constants";
import {translateText} from "../../../helpers";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../../../../newShared/components/Layouts";


export const TriggerRepeatDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'automations'});
    const dispatch = useDispatch();
    const auto = useSelector(selectedAutomation);
    const {isOpen} = useSelector(triggerRepeat);

    const [num, setNum] = useState<number>(1);
    const [value, setValue] = useState<string>('Days');

    useEffect(() => {
        if(auto && isOpen){
            const {factor, timeValue} = translateText(auto.trigger.repeat);
            setNum(factor);
            setValue(timeValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const handleSelect = () => {
        const inSeconds = num*mapper[value]; //translated to miliseconds already
        if(auto?.trigger.repeat === inSeconds) return;
        dispatch(setTriggerRepeat(inSeconds));
    }

    const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
        if (reason !== 'backdropClick') {
            dispatch(hideTriggerRepeat());
        }
    };

    return (auto &&

        <div>
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle>{t('Repeat every-trippleDots')}</DialogTitle>
                <DialogContent>
                    <Flex w={'100%'} jc={'space-between'} ai={'center'} m={'8px 0 0 0'}>
                        <TextField
                            required
                            id="filled-number"
                            label={t("Number")}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            value={num}
                            onChange={(e) => {setNum(parseInt(e.target.value) || 1)}}
                        />
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-dialog-select-label" required>{t('Select')}</InputLabel>
                            <Select
                                labelId="demo-dialog-select-label"
                                id="demo-dialog-select"
                                value={value}
                                onChange={(e) => {setValue(e.target.value as string)}}
                                input={<OutlinedInput label={t('Select')} />}
                            >
                                {timeValues.map(e => <MenuItem  key={e} value={e}>{t(`repeatEveryType.${e}`)}</MenuItem>) }
                            </Select>
                        </FormControl>
                    </Flex>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>{t('Cancel')}</Button>
                    <Button onClick={handleSelect}>{t('Ok')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
