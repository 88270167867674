import {AxiosResponse} from "axios";
import {print} from "graphql";
import {gql} from "@apollo/react-hooks";
import {
    ActivateAutoMutationVariables,
    AddAutomationMutation,
    AddAutomationMutationVariables,
    DeactivateAutoMutationVariables,
    DeletAutomationMutationVariables,
    DeleteAllFileMutationVariables,
    EmailExistsQueryVariables,
    GeneratePdfByInnerHtmlMutationVariables,
    GetAllPoliciesQuery,
    GetAllPoliciesQueryVariables,
    GetAutoHistoryQuery,
    GetAutoHistoryQueryVariables,
    GetAutomationByIdQueryVariables,
    GetAutomationEvidencesQuery,
    GetAutomationEvidencesQueryVariables,
    GetShortAutomationsQuery,
    GetShortAutomationsQueryVariables,
    RunAutomationMutationVariables,
    StopAutomationMutationVariables,
    UpdateAutomationMutation,
    UpdateAutomationMutationVariables,
    UploadFileMutation,
    UploadFileMutationVariables
} from "../../../../newShared/GQLTypes";
import axiosSync from '../../../../newShared/utils/axios/axios';
import {PATH_SERVER} from "../../../../newShared/constants";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {automationType} from "../types";


export const getShortAutomations = async (data: GetShortAutomationsQueryVariables, signal?: AbortSignal): Promise<GetShortAutomationsQuery["getShortAutomations"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getShortAutomations($workspaceId:String!, $pageInfo: PaginationInput!){
                        getShortAutomations(workspaceId:$workspaceId, pageInfo: $pageInfo){
                            automations{
                                id
                                lastRunStatus
                                lastExecutorId
                                lastRunningDate
                                name
                                active
                            }
                            pageInfo {
                                page 
                                count
                                total
                                sort
                            }
                            filters {
                                name
                                values
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getShortAutomations;
    })
)



// export const getAutomationById = async (data: GetAutomationByIdQueryVariables, signal?: AbortSignal): Promise<GetAutomationByIdQuery["getAutomationById"]> => (
export const getAutomationById = async (data: GetAutomationByIdQueryVariables, signal?: AbortSignal): Promise<automationType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getAutomationById($workspaceId:String!, $data:String!){
                        getAutomationById(workspaceId: $workspaceId, data:$data){
                            id
                            name
                            workspaceId
                            workspaceName
                            active
                            createDate
                            lastRunningDate
                            lastExecutorId
                            trigger{
                                type
                                start
                                repeat
                            }

                            documents{
                                type
                                links{
                                    policyId
                                    link
                                    lastUpdate
                                    fileName
                                }
                            }

                            stages{
                                id
                                type
                                actors{
                                    email
                                    publicId
                                    firstName
                                    lastName
                                }
                                eta
                            }

                            evidence{
                                type
                                data{
                                    name
                                    id
                                    controlId
                                    frameworkId
                                }
                            }
                            history{
                                id
                                runDate
                                status
                                stages{
                                    id
                                    status
                                    type
                                    actors{
                                        email
                                        status
                                        statusChangedDate
                                        publicId
                                        firstName
                                        lastName
                                        fileChanged
                                        signatureId
                                    }
                                }
                                evidence{
                                    data{
                                        id
                                        name
                                        controlId
                                        frameworkId
                                    }
                                    status
                                    statusChangedDate
                                    type
                                }
                            }
                            pageInfo{
                                total
                                count
                                page
                                sort
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAutomationById;
    })
)

export const addAutomation = async (data: AddAutomationMutationVariables): Promise<AddAutomationMutation["addAutomation"]["message"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation addAutomation($workspaceId:String!, $data:AutomationInput!){
                        addAutomation(workspaceId:$workspaceId, data:$data){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addAutomation.message;
    })
)
//

export const getPolicies = async (data: GetAllPoliciesQueryVariables): Promise<GetAllPoliciesQuery["getAllPolicies"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getAllPolicies($workspaceId: String!, $organizationId: String!){
                        getAllPolicies (workspaceId:$workspaceId, organizationId: $organizationId) {
                            id
                            name
                            description
                            files {
                                id
                                name
                                lastUpload
                                innerHTML
                                type
                            }
                        }
                    }`),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAllPolicies;
    })
)


export const uploadFile = async (data: UploadFileMutationVariables): Promise<UploadFileMutation["uploadFile"]["message"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation uploadFile($workspaceId:String!, $file:String!){
                        uploadFile(workspaceId:$workspaceId, file:$file){
                            message
                        }
                    }

                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.uploadFile.message;
    })
)


export const deleteAllFiles = async (data: DeleteAllFileMutationVariables): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deleteAllFile($workspaceId:String!, $fileIds:DeleteAllFilesInput!){
                        deleteAllFile(fileIds:$fileIds, workspaceId:$workspaceId){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.runAutomationById.message;
    })
)

export const updateAutomation = async (data: UpdateAutomationMutationVariables): Promise<UpdateAutomationMutation["updateAutomation"]["message"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation updateAutomation($workspaceId:String!, $data:AutomationInput!){
                        updateAutomation(workspaceId:$workspaceId, data:$data){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateAutomation.message;
    })
)

export const deleteAutomation = async (data: DeletAutomationMutationVariables): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deletAutomation($workspaceId:String!, $data:String!){
                        deleteAutomationById(workspaceId:$workspaceId, data:$data){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteAutomationById.message;
    })
)

export const runAutomation = async (data: RunAutomationMutationVariables): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation runAutomation($workspaceId:String!, $data:String!){
                        runAutomationById(workspaceId:$workspaceId, data:$data){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.runAutomationById.message;
    })
)

export const getAutomationHistory = async (data: GetAutoHistoryQueryVariables, signal?: AbortSignal): Promise<GetAutoHistoryQuery["getAutomationHistory"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getAutoHistory($workspaceId:String!, $data: PaginationInfoInput!, $id:String!){
                        getAutomationHistory(workspaceId:$workspaceId, data:$data, id:$id){
                            pageInfo{
                                page
                                count
                                total
                                sort
                            }
                            history{
                                id
                                runDate
                                status
                                stages{
                                    id
                                    type
                                    status
                                    actors{
                                        publicId
                                        email
                                        status
                                        statusChangedDate
                                        firstName
                                        lastName
                                        signatureId
                                        fileChanged
                                    }
                                }
                                evidence{
                                    data{
                                        id
                                        name
                                        controlId
                                        frameworkId
                                    }
                                    status
                                    statusChangedDate
                                    type
                                }
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAutomationHistory;
    })
)

export const activateAutomation = async (data: ActivateAutoMutationVariables): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation activateAuto($workspaceId:String!, $data:String!){
                        activateAutomation(workspaceId:$workspaceId, data:$data){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.activateAutomation.message;
    })
)

export const deactivateAutomation = async (data: DeactivateAutoMutationVariables): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deactivateAuto($workspaceId:String!, $data:String!){
                        deactivateAutomation(workspaceId:$workspaceId, data:$data){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deactivateAutomation.message;
    })
)

export const getAutomationEvidences = async (data: GetAutomationEvidencesQueryVariables): Promise<GetAutomationEvidencesQuery["getAutomationEvidences"]> => (
    //USED BY VULNERABILITY SCANNER !!!
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getAutomationEvidences($workspaceId:String!){
                        getAutomationEvidences(workspaceId:$workspaceId){
                            frameworks{
                                id
                                name
                            }

                            controls{
                                id
                                name
                                frameworkIds
                                category
                            }

                            evidences{
                                id
                                name
                                controlIds
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAutomationEvidences;
    })
)

export const isEmailExists = async (data: EmailExistsQueryVariables): Promise<boolean> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query emailExists($email:String!){
                        isActorExists(email:$email){
                            isExists
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.isActorExists.isExists;
    })
)

export const stopAutomation = async (data: StopAutomationMutationVariables): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation stopAutomation($workspaceId:String!, $data:String!){
                        stopAutomation(workspaceId:$workspaceId, data:$data){
                            message
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.stopAutomation.message;
    })
)

///////////

export const generatePdfByHTML = async (data: GeneratePdfByInnerHtmlMutationVariables): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation generatePdfByInnerHTML($workspaceId:String!, $data:GenerateFileByHtmlInput!){
                        generateFileByHtml(workspaceId:$workspaceId, data:$data){
                            fileId
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.generateFileByHtml.fileId;
    })
)