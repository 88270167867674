import {Typography} from "@mui/material";
import {getStatusColor} from "../../helpers";
import React from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {Flex} from "../../../../../newShared/components/Layouts";


export const StatusDescription = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});
    const {isMobile} = useMedia();


    return (
        <Flex direction={'column'} ai={'flex-start'} w={'100%'}>
            <Flex w={'100%'} ai={'flex-start'} m={'16px 0 0 0'}>
                <Flex w={isMobile ? '35%' : undefined} ai={'flex-start'} jc={'flex-start'} m={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>
                    <Typography variant={'subtitle2'} style={{color: getStatusColor('UNDEPLOYABLE')}}>
                        {t('Undeployable').toUpperCase()}
                    </Typography>
                </Flex>
               <Flex ai={'flex-start'} w={isMobile ? '60%' : undefined} jc={'flex-start'}>
                   <Typography variant={'body2'}>
                       {t('These assets cannot be assigned to anyone')}
                   </Typography>
               </Flex>
            </Flex>
            <Flex w={'100%'} ai={'flex-start'} m={'16px 0 0 0'}>
                <Flex w={isMobile ? '35%' : undefined} ai={'flex-start'} jc={'flex-start'} m={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>
                    <Typography variant={'subtitle2'} style={{color: getStatusColor('PENDING')}}>
                        {t('Pending').toUpperCase()}
                    </Typography>
                </Flex>
                <Flex ai={'flex-start'} w={isMobile ? '60%' : undefined} jc={'flex-start'}>
                    <Typography variant={'body2'}>
                        {t('These assets cannot yet be assigned to anyone')}
                    </Typography>
                </Flex>
            </Flex>
            <Flex w={'100%'} ai={'flex-start'} m={'16px 0 0 0'}>
                <Flex w={isMobile ? '35%' : undefined} ai={'flex-start'} jc={'flex-start'} m={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>
                    <Typography variant={'subtitle2'} style={{color: getStatusColor('Deployable')}}>
                        {t('Deployable').toUpperCase()}
                    </Typography>
                </Flex>
                <Flex ai={'flex-start'} w={isMobile ? '60%' : undefined} jc={'flex-start'}>
                    <Typography variant={'body2'}>
                        {t('These assets can be assigned to anyone')}
                    </Typography>
                </Flex>
            </Flex>
            <Flex w={'100%'} ai={'flex-start'} m={'16px 0 0 0'}>
                <Flex w={isMobile ? '35%' : undefined} ai={'flex-start'} jc={'flex-start'} m={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>
                    <Typography variant={'subtitle2'} style={{color: getStatusColor('Deployed')}}>
                        {t('Deployed').toUpperCase()}
                    </Typography>
                </Flex>
                <Flex ai={'flex-start'} w={isMobile ? '60%' : undefined} jc={'flex-start'}>
                    <Typography variant={'body2'}>
                        {t('These assets assigned')}
                    </Typography>
                </Flex>
            </Flex>
            <Flex w={'100%'} ai={'flex-start'} m={'16px 0 0 0'}>
                <Flex w={isMobile ? '35%' : undefined} ai={'flex-start'} jc={'flex-start'} m={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>
                    <Typography variant={'subtitle2'} style={{color: getStatusColor('Archived')}}>
                        {t('Archived').toUpperCase()}
                    </Typography>
                </Flex>
                <Flex ai={'flex-start'} w={isMobile ? '60%' : undefined} jc={'flex-start'}>
                    <Typography variant={'body2'}>
                        {t('These assets cannot be assigned to anyone, and will only show up in the Archived view')}
                    </Typography>
                </Flex>
            </Flex>
        </Flex>
    )
}