import {FontSizesVariants} from "../editorToolBar/constants";
import {TFont} from "../editorToolBar/types";

export const BACKSPACE_COMMAND = 'BACKSPACE';
// export const TAB_HTML_CODE = '\x8B';
export const SPACE_HTML_CODE = '\xA0';
export const SPACE_0WIDTH_HTML_CODE = '​';

export const isSpace = (char: string) => char === ' ' || char === SPACE_HTML_CODE || char === '&nbsp;' || char === '\u00A0';
export const SPACE_TO_SET = ' ';

export const NEWLINE_COMMAND = '\n';
export const NEWLINE_HTML = '<br>';
export const NEWLINE_HTML_CODE = '\u00AD';

export const isNewLine = (char: string) => char === NEWLINE_COMMAND || char === NEWLINE_HTML || char === NEWLINE_HTML_CODE;
export const NEWLINE_TO_SET = NEWLINE_COMMAND;

export const VARIABLE_ID_DATA_ATTRIBUTE_NAME = 'variableId_data_attribute';
export const LINK_DATA_ATTRIBUTE_NAME = 'link_data_attribute';

//standard styles
//onInput find styles of prev word
//replace class
// parseHtml func

//onPaste OK
//select and replace OK
//backspace OK
//enter OK
//delete class OK
//remove formatting OK
//reduce renders /OK

//CSS CLASSES
export const BOLD_CSS_CLASS_NAME = 'bold';
export const UNDERLINE_CSS_CLASS_NAME = 'underline';
export const ITALIC_CSS_CLASS_NAME = 'italic';
export const STRIKE_THROUGH_CSS_CLASS_NAME = 'strikethrough';

export const DEFAULT_FONT_SIZE = 16;
export const FONTSIZE_CSS_CLASS_NAME = 'fontSize';
export const GET_FONTSIZE_CSS_CLASS_NAME = (size: typeof FontSizesVariants[number]) => {
    return `${FONTSIZE_CSS_CLASS_NAME}${size}`;
}

export const DEFAULT_FONT: TFont = "Arial";
export const FONT_CSS_CLASS_NAME = 'Font';
export const TIMES_NEW_ROMAN_FONT_CSS_CLASS_NAME = 'TimesNewRoman';
export const GET_FONT_CSS_CLASS_NAME = (font: TFont) => {
    if(font === 'Times New Roman') return `${FONT_CSS_CLASS_NAME}${TIMES_NEW_ROMAN_FONT_CSS_CLASS_NAME}`;
    return `${FONT_CSS_CLASS_NAME}${font}`;
}

export const FONT_COLOR_CSS_CLASS_NAME = 'Color';
export const GET_FONT_COLOR_CSS_CLASS_NAME = (color: string) => `${FONT_COLOR_CSS_CLASS_NAME}${color.substring(1).toUpperCase()}`; //removing #

export const SUPERSCRIPT_CSS_CLASS_NAME = 'superscript';
export const SUBSCRIPT_CSS_CLASS_NAME = 'subscript';

export const GET_SUPERSCRIPT_CSS_CLASS_NAME = (fontSize: number) => `${SUPERSCRIPT_CSS_CLASS_NAME}${fontSize}`;
export const GET_SUBSCRIPT_CSS_CLASS_NAME = (fontSize: number) => `${SUBSCRIPT_CSS_CLASS_NAME}${fontSize}`;


export const CHAR_TO_OPEN_VARS = '{';
export const VARIABLE_CSS_CLASS_NAME = 'variable';
export const FOCUSED_VARIABLE_CSS_CLASS_NAME = 'focusedVariable';

export const LINK_CSS_CLASS_NAME = 'link';

// export const mockVariables: TEditorVariableData[] = [
//     {
//         title: 'Hello World',
//         value: 'hello,hello',
//         id: 'var1',
//         description: '',
//         order: 0
//     },
//     {
//         title: 'Hello World2',
//         value: 'hello,hello2',
//         id: 'var2',
//         description: '',
//         order: 0
//     },
//     {
//         title: 'Hello World3, so sdfa on Lefre, asd, qwe11.',
//         value: '',
//         id: 'var3',
//         description: '',
//         order: 0
//     }
// ]
//1.25 line height
//HEADING 0 16
//HEADING 1 30b
//HEADING 2 24b
//HEADING 3 20b
//HEADING 4 18b
//HEADING 5 16b


//applyFont - fontSize16
//applySub - subscript16
//applySuper = superscript16
//remove subscript - remove classes starting with SUBSCRIPT_CSS_CLASS_NAME


// text|sub|super|text - font is "-"
// select all -


//getClassNamesByToolBarSettings -
//if selecting text with different fontSizes - font size will be "-", so default fontSize16 will be inserted
//and

//select text
//



// [a, b, c, d, e, f, g]

//id
//title
//value
//desc

//<span data-attribute="{var_id}">{var_value}</span>
//var_value or var_title (in case var_value is empty) + var_id has to be stored in charSequence as chars
//var_id has to be stored in charSequence as additional prop ({char: "r", styles: "var", varId: 'var_id'})
//all chars with varId has to have a var class (to mark them)
//when var_value/var_name updates - get event - replace charSequence with new value starting from where var_id first mentioned(in all vars with such id)
//when var copied (var_id, var_value, var_name) - insert - insert in char sequence as mentioned above

//when cursor updates - checking last char - if its "{" - open select var dialog -> handle arrowUp/arrowDown and enter, keep caret back after select or close
//when clicking var - open dialog to update var

//when cursor at index n+1 from char with varId not null - (right after var) - removing will remove whole var

//when cursor/selection is on char with varId not null - no decoration is available

//when selection inside var - force select all chars of var

//----
//when updating variable on rightPanel - debounce event variableChanged
//listen to this event - and update charSequence according to changes

//when copyVar - make copy of all props
//when inserting - insert with corrent style + varId in every char

//when setting cursor at indexes where var mentioned - open dialog

// LINK
//if clicking link when cursor - open dialog - ask for text
//when clicking link when selection 1+ - open dialog to ask for href
//<span data-attribute="link">abc</a> - abc is selected text
//href has to be stored in charSequence as additional prop ({char: "r", styles: "link", href: 'href'})
//when click on link - show href in popper with delete/edit button
//remove link - remove href/styles:link in charSequence
//remove formatting has to remove everything but link className
//if cursor stands right after link - do not continue inserting chars into link ?

//abc  //a b c

//--------
//prevent double zero

//LINK
//on link click - open dialog with text + href
//if cursor n+1 from end of link - insert text without link
//when selecting text and clicking link - open dialog on link
//when selection has link - link button disabled

//when inserting variable - check if in the middle of link - if true insert as link text
//when inserting pasted text - make it link
//when insertChar - make it link
//when replaceWithChar - make link

//LIST
//add list selection in toolBar
//when selecting inside block - splitting selection by \n
//apply alignment to list if in list
//

export const EDITOR_COPY_TEXT_ID = 'charSequenceUpdaterPro';
