import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {dialogs, hideDeleteModel, loadings, selectedModel} from "../../../store/slice";
import {PATH_RISK_MANAGEMENT_MODELS_ALL} from "../../../constants";
import {deleteRisksModelAction} from "../../../store/actions";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const useDeleteModel = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {deleteModel} = useSelector(dialogs);
    const {modelsDelete} = useSelector(loadings);
    const selected = useSelector(selectedModel);
    const {setMessage} = useMessageDialog();
    const {t} = useMainTranslation('', {keyPrefix: 'risks'});

    const handleClose = () => {
        dispatch(hideDeleteModel());
    }

    const handleSubmit = () => {
        selected && dispatch(deleteRisksModelAction({
            data: {
                workspaceId: '',
                organizationId: '',
                riskModelId: selected.id,
            },
            onSuccess,
            basicId: ''
        }))
    }

    const onSuccess = () => {
        history.push(PATH_RISK_MANAGEMENT_MODELS_ALL);
        setMessage({title: t('Completed successfully'), message: t('The model {{name}} has been removed.', {name: selected?.name ?? ''})})
    }

    return{
        isOpen: deleteModel,
        isLoading: modelsDelete,
        handleClose,
        handleSubmit,

    }
}