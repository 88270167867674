import {IconButton, Skeleton, TableCell, TableRow, Typography} from "@mui/material";
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import React from "react";
import {Flex} from "../../../../../../../../newShared/components/Layouts";
import colors from "../../../../../../../../newShared/theme/colors";
import {TypographyNew} from "../../../../../../../../newShared/components/TypographyNew";
import {AddAvatarButton} from "../../../../../../../../newShared/components/Basic/AddAvatarButton";

export const VicaCategorySkeletonRow = () => {
    return(
        <>
            <TableRow>
                <TableCell colSpan={5}>
                    <Flex ai={'center'} gap={'20px'}>
                        <Flex ai={'center'} gap={'10px'}>
                            <IconButton size={'small'} disabled>
                                {<KeyboardArrowRightOutlinedIcon />}
                            </IconButton>

                            <TypographyNew variant={'*bodyText2_semibold'} color={colors.grayText} noWrap>
                                <Skeleton width={100}/>
                            </TypographyNew>
                        </Flex>

                        <Flex w={'100px'} direction={'column'}>
                            <Skeleton width={100} height={16}/>
                        </Flex>

                        <Flex ai={'center'} gap={'8px'}>
                            <Typography variant={'*bodyText2'} color={colors.text.grey}>{('Supervisor:')}</Typography>

                            <AddAvatarButton btnSize={'d24'} disabled />
                        </Flex>
                    </Flex>
                </TableCell>
            </TableRow>
        </>
    )
}