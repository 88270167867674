import {UpdateVariablePopperTypes} from "../../types";
import {useEffect, useState} from "react";
import {createEventChangeVariableByTextComponent} from "../../../../helpers";
import {NewDocDataVariableModel} from "../../../../../../GQLTypes";

export const useUpdateVariablePopper = (props: UpdateVariablePopperTypes) => {
    const isOpen = Boolean(props.element) && props.isFocused;
    const [variable, setVariable] = useState<NewDocDataVariableModel | null>(null);

    useEffect(() => {
        if(isOpen){
            if(props.varId && props.initialVariables){
                const searchVar = props.initialVariables.find(e => e.id === props.varId);
                searchVar && setVariable(searchVar);
            }
        }else{
            setVariable(null);
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const handleChange = (value: string) => {
        variable && setVariable((prev) => ({...prev!, value}))
    }

    const onSubmit = () => {
        variable && createEventChangeVariableByTextComponent({...variable, count: 1});
        props.handleClose();
    }

    return{
        variable,
        handleChange,
        onSubmit
    }
}