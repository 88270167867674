import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useEffect} from "react";
import {useApproveRequestRemoveAsset} from "../../../hooks/dialogs/useApproveRequestRemoveAsset";
import {PhysicalAssetsStatus, PhysicalAssetStatusModel} from "../../../../../../newShared/GQLTypes";

export const ApproveRequestRemoveAssetDialog = () => {
    const {handleClose, isOpen, isLoading, formik, selected, statuses, getSettings, isLoadingSettings} = useApproveRequestRemoveAsset();
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets.pathIncomingRequests'});

    useEffect(() => {
        formik.resetForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
        if (!statuses.length) {
            getSettings();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statuses.length]);

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Unassign asset')}</DialogTitle>
            <DialogContent sx={{gap: '10px',display: 'flex', flexDirection: 'column'}}>
                <Typography variant={'*bodyText2'}>{t('Asset will unassign from {{fullName}}.', {fullName: selected?.asset?.assignedTo || ''})}</Typography>
                <Typography variant={'*bodyText2'}>{t('Select new status for the asset.')}</Typography>

                <Autocomplete<PhysicalAssetStatusModel>
                    loading={isLoadingSettings}

                    value={formik.values.status}
                    options={statuses.map((e) => ({status: e.status as PhysicalAssetsStatus, name: e.name}))}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                        formik.setFieldValue('status', value);
                    }}

                    onBlur={() => formik.setFieldTouched('status', true)}

                    size={"small"}
                    renderInput={(params) => (
                        <TextField {...params} label={t('Asset status')} required
                                   name={'status'}
                                   error={formik.touched.status && Boolean(formik.errors.status)}
                                   helperText={formik.touched.status && formik.errors.status}
                        />
                    )}
                />

                <TextField
                    multiline
                    rows={4}
                    label={'Comment'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.comment}
                    name={'comment'}
                    error={formik.touched.comment && Boolean(formik.errors.comment)}
                    helperText={formik.touched.comment && formik.errors.comment}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} size={'small'} variant={'text'}>{tCommon('Cancel')}</Button>
                <LoadingButton
                    variant={'contained'}
                    onClick={formik.submitForm}
                    disabled={!formik.isValid}
                    loading={isLoading}
                    size={'small'}
                >
                    {tCommon('Approve')}
                </LoadingButton>

            </DialogActions>
        </Dialog>
    );
};
