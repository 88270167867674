import {TrainingContentNewModel,} from "../../../../../../../../../newShared/GQLTypes";
import {Flex} from "../../../../../../../../../newShared/components/Layouts";
import colors from "../../../../../../../../../newShared/theme/colors";
import {TypographyNew} from "../../../../../../../../../newShared/components/TypographyNew";
import {CircularProgress, IconButton} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import {ReactComponent as TemplateIcon} from '../../../../../../../../../newShared/images/documents/template-file.svg';
import {downloadFileAction} from "../../../../../../../../../newShared/actions/downloadFile";
import {useDispatch, useSelector} from "react-redux";
import {loadings} from "../../../../../../store/slice";
import {usePDFView} from "../../../../../../../../../newShared/components/docViewer/hooks/usePDFView";

export const FilePreview = ({section, onDelete}:{section: TrainingContentNewModel, onDelete?: () => void}) => {
    const dispatch = useDispatch();
    const {isDownloadingFile} = useSelector(loadings);
    const {handleOpen} = usePDFView();

    const isLoadingDownload = isDownloadingFile.some(e => e === section.data.fileId)

    const handlePreviewFile = () => {
        if(section.data.fileId && section.data.name){
            handleOpen({fileId: section.data.fileId, fileName: section.data.name, size: 'dialog'});
        }
    }

    const handleDownloadFile = () => {
        if(section.data.fileId && section.data.name) {
            dispatch(downloadFileAction({data: {fileId: section.data.fileId, fileName: section.data.name}}));
        }
    }

    return(
        <Flex ai={'center'} jc={'space-between'} w={'100%'} border={`1px solid ${colors.stroke.grey}`} br={'6px'} p={'10px 20px 10px 10px'}>
            <Flex ai={'center'} gap={'10px'}>
                <TemplateIcon />
                <TypographyNew variant={'*bodyText2'} color={colors.grayText} noWrap>{section.data.name}</TypographyNew>
            </Flex>

            <Flex ai={'center'} gap={'10px'}>
                <IconButton onClick={handlePreviewFile} size={'small'} color={'primary'}>
                    <RemoveRedEyeIcon />
                </IconButton>

                <IconButton onClick={handleDownloadFile} size={'small'} color={'primary'} disabled={isLoadingDownload}>
                    {isLoadingDownload ?
                        <CircularProgress size={24}/>
                        :
                        <DownloadIcon />
                    }
                </IconButton>

                {onDelete &&
                    <IconButton onClick={onDelete} size={'small'} color={'primary'}>
                        <DeleteIcon />
                    </IconButton>
                }
            </Flex>
        </Flex>
    )
}