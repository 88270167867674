import * as S from '../styled';
import {useSelector} from "react-redux";
import {dialogs, hideArchiveClientDialog, loadings} from "../../../store/slice";
import {useAppDispatch} from "../../../../../../newShared/redux";
import React, {useEffect, useState} from "react";
import {Button, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TypographyBody1} from "../../../../../../newShared/components/Inputs/styled";
import CommonCheckbox from "../../../../../../newShared/components/Basic/CommonCheckBox";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {MoveKycTargetToArchive} from "../../../store/actions";
import {useHistory} from "react-router-dom";
import {INDIVIDUAL_CLIENTS_PAGE_TYPE, KYC_CLIENTS_LIST_PATH} from "../../../constants";


export const ArchiveClientDialog = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.dialogs'});

    const dispatch = useAppDispatch();
    const history = useHistory();
    const {isOpen, selectedClient, isFromTargetListPage} = useSelector(dialogs).archiveClient;
    const {moveToArchive} = useSelector(loadings);
    const [isDescription, setIsDescription] = useState<boolean>(false);
    const [description, setDescription] = useState<string>('');
    const handleClose = () => {
        dispatch(hideArchiveClientDialog());
    }

    const handleSave = () => {
        selectedClient && dispatch(MoveKycTargetToArchive({workspaceId: '', targetId: selectedClient.id, description, onSuccess}));
    }

    const onSuccess = () => {
        !isFromTargetListPage && history.push(KYC_CLIENTS_LIST_PATH.replace(':type', selectedClient?.targetType.toLowerCase() ?? INDIVIDUAL_CLIENTS_PAGE_TYPE));
    }

    useEffect(() => {
        if (isOpen) {
            setIsDescription(false)
            setDescription('');
        }

        //eslint-disable-next-line
    }, [isOpen]);



    return (
        <S.CommonDialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Archive client')}</DialogTitle>
            <DialogContent>
                <TypographyBody1>{t('You’re about to archive the client.')}</TypographyBody1>
                <CommonCheckbox label={t('Provide reason for archiving')} checked={isDescription} onChange={() => setIsDescription(!isDescription)} />

                {isDescription && <TextField minRows={3}
                                             multiline
                                             value={description}
                                             onChange={(e) => setDescription(e.target.value)}
                                             label={t('Reason for archiving')} />}

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={'text'} sx={{textTransform: 'none'}}>{tCommon('Cancel')}</Button>
                <LoadingButton disabled={moveToArchive}
                               onClick={handleSave} loading={moveToArchive }
                               loadingPosition={moveToArchive ? 'end' : undefined}
                >
                    {t('Archive')}
                </LoadingButton>
            </DialogActions>
        </S.CommonDialog>
    )
}
