import {useDispatch, useSelector} from "react-redux";
import {
    dialogs,
    eraseOneTimeScreeningResponse,
    hideOneTimeScreening,
    kycDictionaries,
    loadings,
    openShowClientDetail,
    openShowFullReport
} from "../../../store/slice";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {
    insertClientDataByTemplateRequestType,
    KycExactDictType,
    TKycTemplateScreeningSettingsCheck
} from "../../../types";
import {convertTemplateToFillableTemplate, removeTimeZoneDateFields} from "../../../helpers";
import {initialCreateTemplateForm} from "../../../constants";
import React, {useEffect, useState} from "react";
import {GetKycOtsFullResultByEntityId, StartOneTimeScreening} from "../../../store/actions";
import {KycOtsResultModel} from "../../../../../../newShared/GQLTypes";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";


export const useOneTimeScreening = () => {

    const dispatch = useDispatch();
    // const history = useHistory();
    const {
        oneTimeScreening: {isOpen, template, response},
        showClientDetail: {isOpen: isOpenClientDetails},
        showFullReportDialog: {isOpen: isOpenFullReport}
    } = useSelector(dialogs);
    const {startTargetScreening: isLoading} = useSelector(loadings);
    const dicts = useSelector(kycDictionaries);

    const {setForm, form} = useForm<insertClientDataByTemplateRequestType>(convertTemplateToFillableTemplate(initialCreateTemplateForm));
    const [isInputTargetData, setIsInputTargetData] = useState<boolean>(true);
    const [selectedEntityId, setSelectedEntityId] = useState<string | null>(null);

    useEffect(() => {
        if(isOpen && template){
            setForm(convertTemplateToFillableTemplate(template));
        }

        if(!isOpen){
            setForm(convertTemplateToFillableTemplate(initialCreateTemplateForm));
            // setIsCreateAnotherClient(false);
            setIsInputTargetData(true);
        }
        //eslint-disable-next-line
    }, [isOpen]);


    const handleClose = () => {
        dispatch(hideOneTimeScreening());
    }

    const handleChangeCustomField = (event: React.ChangeEvent<HTMLInputElement>) => {
        const id = event.currentTarget.name;
        const value = event.currentTarget.value;
        setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === id ? {...e, value} : e)}))
    }

    const handleChangeDate = (id: string, date: string | null) => {
        setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === id ? {...e, value: date} : e)}))
    }

    const isOk = () => {
        // console.log(`form`, form.fields, form.fields.some(e => (e.required && e.value === null) || (e.value !== null && !e.value.trim().length)));
        return !form.fields.filter(e => e.system).some(e => {
            //looking for bad fields
            if(e.required && e.value === null) return true;
            if((e.required && e.value !== null && typeof e.value === 'string' && !e.value.trim().length)) return true;
            if(e.required && e.type === 'FILE' && (e.fileName === null || e.fileName === undefined || (e.fileName.trim().length === 0))) return true;
            if(e.type === 'DATE' && e.value !== null && e.value !== undefined){
                let parsed;
                try {
                    parsed = new Date(Date.parse(e.value)).toISOString();
                }catch (ex){
                    parsed = null;
                }

                return parsed === null;
            }
            return false;
        })
    }

    const handleSubmit = () => {
        if(isOk()){
            const newFields = removeTimeZoneDateFields(form.fields);
            dispatch(StartOneTimeScreening({
                    data: {
                        data: {
                            fields: newFields,
                            templateId: form.id,
                        },
                        matchingRatio: form ? form.screeningSettings.checks[0].matchingRatio : 0,
                    },

                    onSuccess: () => setIsInputTargetData(false),
                }
            ));
        }
    }

    const handleNewScreening = () => {
        dispatch(eraseOneTimeScreeningResponse());
        handleClearForm();
        setIsInputTargetData(true);
    }

    const handleClearForm = () => {
        setForm((prev) => ({...prev, fields: [...prev.fields.map(e => ({...e, value: e.type === 'DATE' ? null : ''}))]}));
    }

    //comp - upload file - get fileId - fetch outer func - save in form
    const handleFileUpload = (fileId: string, fieldId: string, fileName: string) => {
        setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === fieldId ? {...e, value: fileId, fileName} : e)}))
    }

    const handleDeleteUploadedFile = (fieldId: string) => {
        setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === fieldId ? {...e, value: null, fileName: null} : e)}))
    }

    const handleChangeSelectField = (id: string, value: KycExactDictType | null) => {
        setForm((prev) => ({
            ...prev,
            fields: prev.fields.map(e => e.id === id ? {...e, value: value?.key ?? null} : e)
        }))
    }



    const handleViewFullReport = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, result: KycOtsResultModel) => {
        e.stopPropagation();
        response && dispatch(openShowFullReport({entityId: result.entityId, otsResponse: response}));
        dispatch(GetKycOtsFullResultByEntityId({data: {entityId: result.entityId}}));
        setSelectedEntityId(result.entityId);
    }

    const handleDownloadFullReport = (entityId: string, entityName: string) => {
        setSelectedEntityId(entityId);
        response && dispatch(GetKycOtsFullResultByEntityId({data: {entityId, fileName: `${entityName}_${parseDateAuto(response.startDate, true)}.json`}}));
    }

    //handleGoBack
    const handleGoBack = () => {
        setIsInputTargetData(true);
    }

    const onRatioChange = (check: TKycTemplateScreeningSettingsCheck, ratio: number | number[]) => {
        if(Array.isArray(ratio)) return;
        let _ratio: number = ratio;
        const formCheck = form.screeningSettings.checks.find(e => e.id === check.id);
        if(formCheck){
            setForm((prev) => (
                {...prev,
                    screeningSettings: {
                        ...prev.screeningSettings,
                        checks: prev.screeningSettings.checks.map(e => e.id === check.id ? {...e, matchingRatio: _ratio} : e)
                    }
                }
            ));
        }else{
            setForm((prev) => (
                {...prev,
                    screeningSettings: {
                        ...prev.screeningSettings,
                        checks: [...prev.screeningSettings.checks, {...check, matchingRatio: _ratio}]
                    }
                }
            ));
        }
    }

    const handleShowClientDetail = () => {
        dispatch(openShowClientDetail({fields: form.fields, isOTS: true}));
    }




    return {
        isOpen,
        isOpenClientDetails,
        isOpenFullReport,
        template,
        response,
        dicts,
        form,
        isInputTargetData,
        selectedEntityId,
        isLoading,
        isNoMatchesFound: !!response && !response.results?.length,

        handleClose,
        handleChangeCustomField,
        handleChangeDate,
        isOk,
        handleSubmit,
        handleNewScreening,
        handleFileUpload,
        handleDeleteUploadedFile,
        handleChangeSelectField,
        handleViewFullReport,
        handleDownloadFullReport,
        handleGoBack,
        onRatioChange,
        handleShowClientDetail,
    }
}
