//core
import styled from 'styled-components';
//other
import {
    Dialog as dialog,
    DialogContent as dialogContent,
    Paper as paper,
    TableCell as tablecell,
    TableContainer as tc,
    TextField as textfield,
    Typography
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import {Box, Flex} from '../../../../../newShared/components/Layouts';
import {device} from "../../../../../newShared/constants";
import colors from "../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";


//accessManagement
export const NavButton = styled.div<{isMain: boolean, margin?: string, padding?: string}>`
    padding: ${props => props.padding ? props.padding : "0 20px"};
    height: 46px;
    margin: ${props => props.margin ? props.margin : "none"};
    
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 40px;
    background-color: ${props => props.theme.colors[props.isMain ? "navButtonActive" : "none"]};

    cursor: pointer;
`;

//Table styles

export const Paper = styled(paper)`
    width: 100%;
    margin-top: 18px;
    color: white;
    border-radius: 25px !important;
    background-color: white !important;
    overflow: hidden;

    .MuiTableCell-stickyHeader{
        background-color: white !important;
    }

    .MuiTableCell-stickyHeader:first-child{
        border-radius: 25px 0 0 0 !important;
    }

    .MuiTableCell-stickyHeader:last-child{
        border-radius: 0 25px 0 0 !important;
    }
    `;

export const TableContainer = styled(tc)`
    padding: 0;
`;

export const TableCell = styled(tablecell)<{borderBottom?: string, minWidth?: number, p?: string, maxWidth?:number, width?:string, margin?:string, radius?: boolean}>`
    ${props => props.radius && `
        .MuiTableCell-stickyHeader {
            border-radius: 25px 25px 0 0 !important;
        }
        border-radius: 25px 25px 0 0 !important;
    `}
    
    ${props => props.borderBottom && `border-bottom: ${props.borderBottom} !important;`}
    ${props => props.minWidth && `min-width: ${props.minWidth}px !important;`}
    ${props => props.maxWidth && `max-width: ${props.maxWidth}px !important;`}
    ${props => `padding: ${props.p ? props.p : "10px"} !important;`}
    ${props => props.width && `width: ${props.width} !important;`}
    ${props => props.margin && `margin: ${props.margin} !important;`}


`;

export const Pagination = styled(TablePagination)`
    min-height: 0;
    height: 70px;
    display: flex !important;
    align-items: center;
    justify-content: flex-end !important;
    border-bottom: none !important;
`;

export const Switch = (active: boolean) => {
    if(active){
        return (<Flex background={"#2681FF"} w="44.2px" h="22px" p="4px" br="41px" ai="center" jc="flex-end">
                    <Box border="2px solid white" background={"#2681FF"} w="14px" h="14px" br="50%" />
                </Flex>)
    }else{
        return (<Flex background={"#878BA1"} w="44.2px" h="20px" p="4px 0 4px 8px" br="41px" ai="center" jc="flex-start">
                    <Box background={"white"} w="3px" h="12px" br="10px"/>
                </Flex>)
    }

}

// Delete Dialog
export const DialogContent = styled(dialogContent)`
    width: 30vw;
    // height: 37vh ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

export const Dialog = styled(dialog)`
    .MuiDialog-paper{
        border-radius: 25px;
        box-shadow: 0 0 25px 2px rgba(0,0,0,0.15);
        display: flex;
        align-items: center;
    }
    .MuiDialogContent-root{

    }
`;

export const DeleteDialogInput = styled(textfield)`
    width: 100%;

    .MuiFilledInput-input{
        background-color: #ebebeb !important;
    }

    .MuiFormLabel-root.Mui-focused{
        color: #A0A2B1 !important;
    }

    .MuiFilledInput-root{
        height: 45px;
        // font-size: 12px;
    }

`;

//FilterSideBar
export const SideBarAutoSelectContainer = styled.div`
    .MuiAutocomplete-root{
        width: 100% !important;
        background-color: #f5f5f5 !important;
    }

    .MuiFilledInput-root:hover{
        background-color: #f5f5f5 !important;
        border-bottom: none;
    }

    .MuiFilledInput-root{
        background-color: inherit;
    }

    .MuiFormLabel-root{
        color: #212121 !important;
    }  

    .MuiFormLabel-root.Mui-focused{
        color: #212121 !important;
    }

    .MuiFilledInput-underline{
        border-bottom: none;
    }

    .MuiFilledInput-underline:before{
        border-bottom: none;
    }

    .MuiFilledInput-underline:after{
        border-bottom: none;
    }

    .MuiFilledInput-underline-focused{
        border-bottom: none;
        // border-bottom-color: #ebebeb !important;
    }
`;

//addUser Side Bar

export const AddUserInput = styled(textfield)`
    width: 100%;
    height: 30px !important;
    color: #212121 !important;
    
    .MuiFilledInput-input{
        background-color: #f5f5f5 !important;
    }

    .MuiFormLabel-root.Mui-focused{
        background-color: #f5f5f5 !important;
    }

    .MuiFilledInput-root{
        color: #212121 !important;
        // height: 35px;
        // font-size: 12px;
    }

`;

//change user dialog
// Delete Dialog
export const UpdateUserDialogContent = styled(dialogContent)`
    width: 30vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

export const UpdateUserDialog = styled(dialog)`
    width: 100% !important;
    height: 100% !important;
    .MuiDialog-paper{
        max-width: 2600px;
        max-height:2500px;
        width: 30vw !important;
        height: 80vh !important;
    };
    .MuiDialog-paper{
        width: 60vw;
        height: 70vh;
        border-radius: 25px;
        box-shadow: 0 0 25px 2px rgba(0,0,0,0.15);
        display: flex;
        align-items: center;
    };
    .MuiDialogContent-root{

    }
`;

export const UpdateUserDeleteDialogInput = styled(textfield)`
    width: 80%;

    .MuiFilledInput-input{
        background-color: #ebebeb !important;
    }

    .MuiFormLabel-root.Mui-focused{
        color: #A0A2B1 !important;
    }

    .MuiFilledInput-root{
        height: 45px;
        // font-size: 12px;
    }

`;


export const MainTableScrollWrapper = styled.div`
    overflow: auto;
    padding: 0 5px;
    height: 100%;
    width: 100%;
    
    ${device.generateMax(858)}{
        display: flex;
        flex-wrap: wrap;
        margin: 16px 0 0 0;
    }
`;

export const AutomationsTableHeaderWrapper = styled.div<{minWidth?: string}>`
    ${props => props.minWidth && `min-width: ${props.minWidth};`}
    display: flex;
    width: 90%;
    overflow: auto;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0 24px 0;
    ${device.tablet} {
        width: 100%;
        margin: 25px 0 24px 0;
    }
`;

export const TableHeaderItemWrapper = styled.div<{ width?: string, ai?: string}>`
    width: ${props => props.width ?? '24%'};
    display: flex;
    align-items: ${props => props.ai ?? 'center'};
    & p {
        text-overflow: ellipsis; 
        display: block; 
        overflow: hidden; 
        line-height: normal;
    }
`;

export const MainTableRowWrapper = styled.div<{disableHover?: boolean, width?: string, minWidth?: string}>`
    ${props => props.minWidth && `min-width: ${props.minWidth};`}
    width: ${props => props.width ?? '100%'};
    ${props => props.disableHover ?'' : `
        &:hover{
            background-color: ${colors.backgrounds.grey_light};
            cursor: pointer;
        }
    `}
`;


export const TableRowWrapper = styled.div<{disableHover?: boolean, jc?: string}>`
    width: 90%;
    display: flex;
    justify-content: ${props => props.jc ?? 'space-between'};
    align-items: center;
    ${props => props.disableHover ?'' : `
        &:hover{
            background-color: ${colors.backgrounds.grey_light};
            cursor: pointer;
        }
    `}
   
    ${device.tablet} {
        width: 100%;
    }
`;

export const TableRowItemWrapper = styled.div<{width?: string}>`
    width: ${props => props.width ?? '24%'};
    display: flex;
    align-items: center;
    // justify-content: center;
    padding: 12px 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    & p {
        text-overflow: ellipsis; 
        display: block; 
        overflow: hidden; 
        line-height: normal;
    }
`;

export const GetTextByStatus = (status: string | null) => {
    const {t} = useMainTranslation('', {keyPrefix:'automations'});
    switch(status){
        case 'completed':return <Typography variant={'*bodyText2'} color={'#3BCC89'}>{t('Success')}</Typography>;
        case 'failed': return <Typography variant={'*bodyText2'} color={'#FF5959'}>{t('Failed')}</Typography>;
        case 'inProgress': return <Typography variant={'*bodyText2'} color={'#FFA000'}>{t('In progress')}</Typography>;
        case null: return <Typography variant={'*bodyText2'}>- - -</Typography>;
    }
}

export const UserCardWrapper = styled.div<{revDir: boolean}>`
    margin: 0 auto;
    width: calc(50% - 32px); 
    min-width: 330px;
    // border: 1px solid black;
    border-radius: 8px;
    ${device.desktopNarrow}{
        width: 100%;
    }
    padding: 10px 20px;
    margin: ${props => !props.revDir ? '0 16px 16px 0' : '0 16px 16px 0'};
    
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 1px 0px rgba(125, 151, 174, 0.02);
    box-shadow: 0px 2px 6px 0px rgba(125, 151, 174, 0.02);
    box-shadow: 0px 8px 16px 0px rgba(125, 151, 174, 0.08);
`;

export const ListPageTopButtonContainer = styled.div`
    display: flex;
    margin: 8px 0 0 0;
    align-items: center;
    ${device.generateMax(1049)} {
        width: 100%;
        justify-content: space-between;
    }
    ${device.generateMax(501)} {
        justify-content: space-between;
        margin: 11px 0 0 0;
    }
`;

export const SelectDocumentLeftViewWrapper = styled.div`
    max-height: 300px;
    // min-width: 300px;
    width: 45%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
`;

export const SelectDocumentRightViewWrapper = styled.div`
    max-height: 300px;
    // min-width: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
`;

export const SelectDocumentMainWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 5px;
`;