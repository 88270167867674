import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import {AddFileSectionDialogPropsType} from "../../../../../types";
import {useAddFileSectionDialog} from "../../../../../hooks/dialogHooks/useAddFileSectionDialog";
import colors from "../../../../../../../../newShared/theme/colors";
import {Flex} from "../../../../../../../../newShared/components/Layouts";
import {FileDropZone} from "../../../../../../../../newShared/components/fileDropZone";
import {FILE_SECTION_DROP_ZONE_ACCEPT} from "../../../../../constants";
import {FileDrop} from "react-file-drop";
import React from "react";
import {LocalSpinner} from "../../../../../../../../newShared/components/Spinner";
import {FilePreview} from "../preview/filePreview";

export const AddFileSectionDialog = (props: AddFileSectionDialogPropsType) => {
    const {isOpen, handleClose, formik, fileDropZone, isLoadingUpload, fileActions, isOk, isUpdate}  = useAddFileSectionDialog(props);
    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{('Create new section by file upload')}</DialogTitle>

            <DialogContent>
                <Flex direction={'column'} gap={'20px'}>
                    <TextField
                        required
                        label={('Section name')}
                        name={'name'}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        onBlur={formik.handleBlur}
                        error={Boolean((formik.touched.name || isUpdate) && formik.errors.name)}
                        helperText={(formik.touched.name || isUpdate) && formik.errors.name}
                        size={'small'}
                    />

                    <Flex direction={'column'} gap={'8px'}>
                        <Typography variant={'*bodyText2_semibold'} color={colors.grayText}>{('Training content file')}</Typography>

                        {!formik.values.data.fileId?.length && !isLoadingUpload &&
                            <>
                                <Typography variant={'*bodyText2'} color={colors.text.grey}>{('You can add .pdf, .docx, .xlsx or .pptx file\'s format. Make sure attached file does not exceed 5 MB.')}</Typography>
                                <FileDrop onTargetClick={fileDropZone.filePicker} onDrop={fileDropZone.fileHandler}>
                                    <FileDropZone
                                        accept={FILE_SECTION_DROP_ZONE_ACCEPT}
                                        inputRef={fileDropZone.ref}
                                        fileHandler={fileDropZone.fileHandler}
                                    />
                                </FileDrop>
                            </>
                        }

                        {isLoadingUpload ? <LocalSpinner />
                            :
                            <>
                                {formik.values.data.fileId && formik.values.data.name && formik.values.data.fileId.length > 0 && <FilePreview section={formik.values} onDelete={fileActions.handleDeleteFile}/>}
                            </>
                        }
                    </Flex>
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button variant={'text'} onClick={handleClose} size={'small'}>{('Close')}</Button>
                <Button variant={'text'} onClick={formik.submitForm} size={'small'} disabled={!isOk}>{('Save')}</Button>
            </DialogActions>
        </Dialog>
    )
}