import {TCharSequence} from "./types";
import {
    BOLD_CSS_CLASS_NAME,
    CHAR_TO_OPEN_VARS,
    DEFAULT_FONT,
    DEFAULT_FONT_SIZE,
    EDITOR_COPY_TEXT_ID,
    FOCUSED_VARIABLE_CSS_CLASS_NAME,
    FONT_COLOR_CSS_CLASS_NAME,
    FONT_CSS_CLASS_NAME,
    FONTSIZE_CSS_CLASS_NAME,
    GET_FONT_COLOR_CSS_CLASS_NAME,
    GET_FONT_CSS_CLASS_NAME,
    GET_FONTSIZE_CSS_CLASS_NAME,
    GET_SUBSCRIPT_CSS_CLASS_NAME,
    GET_SUPERSCRIPT_CSS_CLASS_NAME,
    isNewLine,
    isSpace,
    ITALIC_CSS_CLASS_NAME,
    LINK_CSS_CLASS_NAME,
    LINK_DATA_ATTRIBUTE_NAME,
    NEWLINE_COMMAND,
    NEWLINE_HTML,
    NEWLINE_HTML_CODE,
    NEWLINE_TO_SET,
    SPACE_0WIDTH_HTML_CODE,
    SPACE_HTML_CODE,
    SPACE_TO_SET,
    STRIKE_THROUGH_CSS_CLASS_NAME,
    SUBSCRIPT_CSS_CLASS_NAME,
    SUPERSCRIPT_CSS_CLASS_NAME,
    TIMES_NEW_ROMAN_FONT_CSS_CLASS_NAME,
    UNDERLINE_CSS_CLASS_NAME,
    VARIABLE_CSS_CLASS_NAME,
    VARIABLE_ID_DATA_ATTRIBUTE_NAME
} from "./constants";
import {
    TFont,
    TFontAlignmentTitle,
    TFontStyle,
    TFontTitle,
    TToolBarHandlerAction,
    TToolBarHandlerPayload,
    TToolBarSlice
} from "../editorToolBar/types";
import {DEFAULT_COLORS, initialEditorToolBarState, LINK_COLOR} from "../editorToolBar/constants";
import {TBlockWithHtml} from "../../types";
import {createEventUpdateVariableUsages} from "../../helpers";
import {NewDocDataVariableModel} from "../../../../GQLTypes";

export function setCaret(root: HTMLElement, globalCursorIndex: number) {
    //this function is used to set cursor in text by global position
    //this function fires every this.rerender of TextComponent if cursor is not -1
    //basically iterating on root children and searching for node by counter of innerText
    const debug = false;
    if(globalCursorIndex === -1) return;
    // console.log(`setCaret at ${globalCursorIndex}`)
    let sel = window.getSelection();
    let range = document.createRange();
    let offset = 0;
    let counter = 0;

    if(root){
        let startContainer: ChildNode | null = null;
        debug && console.log(`root.childNodes.length`, root.childNodes.length)
        for(let i = 0; i < root.childNodes.length; i++){
            let child = root.childNodes.item(i);
            debug && console.log(`child ${i}`, child)
            if(child.textContent){
                const textContent = clearInnerText(child);
                if(counter + textContent.length >= globalCursorIndex){
                    startContainer = child;
                    offset = Math.abs(globalCursorIndex - counter); //for last tag selection (prevent out of bounce)
                    debug && console.log(`SELECTION IN e.nodeName: ${child.nodeName} | e.textContent: ${child.textContent} | offset: ${offset} (globalCursorIndex: ${globalCursorIndex} - counter: ${counter})`);
                    break;
                }else{
                    debug && console.log(`StartContainer not found, currentCounter: ${counter}, adding child text length ${child.textContent.length} (${child.textContent}) = ${counter + child.textContent.length}`)
                    counter += textContent.length;
                }
            }else{
                if(child.nodeName === 'BR'){
                    //newLine break has 1 place
                    counter ++;
                    continue;
                }
                //child has no inner text
                //after new line there will be empty span
                if(child.nodeName === 'SPAN'){
                    startContainer = child;
                }
            }
        }

        debug && console.log(`startContainer`, startContainer?.firstChild, 'offset', offset)
        if(startContainer && startContainer.firstChild){
            range.setStart(startContainer.firstChild, offset);
            range.collapse(true);
            sel?.removeAllRanges();
            sel?.addRange(range);
            debug && console.log(`cursor set success`)
        }
    }
}

export function setSelection(root: HTMLElement, from: number, to: number) {
    //this function is used to set selection in text by global position
    //this function fires every this.rerender of TextComponent if cursor is -1 (so selection is made)
    //used to prevent removing selection after rerender and if focus is gone
    //basically iterating on root children and searching for start/end nodes by counter of innerText and calculating offsets
    let debug = false;
    if(from === to) return;
    debug && console.log(`-------setSelection from ${from} to ${to}-------`)
    let sel = window.getSelection();
    let range = document.createRange();
    let startNode: ChildNode | null = null;
    let startOffset = 0;
    let endNode: ChildNode | null = null;
    let endOffset = 0;
    let startCounter = 0;
    let endCounter = 0;

    for(let i = 0; i < root.childNodes.length; i++){
        if(startNode && endNode) break;
        let child = root.childNodes.item(i);
        debug && console.log(`child`, child)
        if(child){
            let contentLength = clearInnerText(child).length;
            if(!startNode){
                if(startCounter + contentLength > from){
                    //startNode found
                    startNode = child;
                    startOffset = from - startCounter;
                    debug && console.log(`startNode found, offset ${startOffset} (from ${from} - startCounter ${startCounter})`, child)
                }else{
                    debug && console.log(`startNode not found, adding its contentLength = ${startCounter + contentLength}`, startCounter, contentLength)
                    startCounter += contentLength;
                }
            }

            if(!endNode){
                //abc 123 45|6 {5}
                //add abc 3
                //if counter + content < to - needed child
                //offset 2 ()
                //
                debug && console.log(`endCounter ${endCounter} + contentLength: ${contentLength} >= to: ${to}`)
                if(endCounter + contentLength >= to){
                    debug && console.log(`
                    true \n
                    from: ${from} \n
                    to: ${to} \n
                    startCounter: ${startCounter} \n
                    endCounter: ${endCounter} \n
                    contentLength: ${contentLength}
                    `)
                    //abc ab|c
                    //endNode found
                    endNode = child;
                    endOffset = (to - endCounter);
                    debug && console.log(`endNode found, offset ${endOffset}`, child)
                }else{
                    debug && console.log(`endNode not found, adding its contentLength = ${endCounter + contentLength}`, endCounter, contentLength)
                    endCounter += contentLength;
                }
            }
        }
    }

    debug && console.log(`startNode, startOffset, endNode, endOffset`, startNode, startOffset, endNode, endOffset);
    if(startNode && startNode.firstChild && endNode && endNode.firstChild){
        range.setStart(startNode.firstChild, startOffset);
        range.setEnd(endNode.firstChild, endOffset);
        sel?.removeAllRanges();
        sel?.addRange(range);
        debug && console.log(`-------SELECTION SET-------`)
    }
}

export function getPopperHtmlElementByCursor(): HTMLElement | null{
    const selection = window.getSelection();
    if(selection && selection.rangeCount > 0){
        let startNode = selection.anchorNode;
        return startNode?.parentElement ?? null;
    }

    return null;
}

export function checkIsInNeededRoot(startNode: Node | null, id: string): boolean{
    return startNode?.parentElement?.parentElement?.id === id || startNode?.parentElement?.children.item(0)?.id === id;
}

export function getGlobalRange(id: string): {start: number, end: number} {
    //function is used to find global range across any children of root
    //does not work with text elements like <div {root}><br>hello</div>
    //it has to be any tag children
    //calculating global range by iterating on root's children to find start and end
    //uses reference strict equality to find nodes
    const debug = false;
    const selection = window.getSelection();
    // console.log(`-----------getGlobalRange----------`, selection?.rangeCount)
    if(selection && selection.rangeCount > 0){
        const range = selection.getRangeAt(0);
        debug && console.log('selection', selection);
        debug && console.log('range.commonAncestorContainer', range.commonAncestorContainer)
        debug && console.log('range', range)
        let startNode = selection.anchorNode;
        debug && console.log(`startNode`, startNode);
        let endNode = selection.focusNode;
        debug && console.log('endNode', endNode)


        let startOffset = 0;
        let endOffset = 0;
        let isStartCalculated = false;
        let isEndCalculated = false;

        let root = range.commonAncestorContainer;
        // console.log(`REALROOT ID: ${id}, found root (root.parentNode?.parentElement?.id) ${root.parentNode?.parentElement?.id}`, root.parentNode?.parentElement);
        // console.log(`REALROOT ID: ${id}, found root (root.parentElement?.id) ${root.parentElement?.id}`, root.parentNode?.parentElement);
        // console.log(`root`, root)
        // console.log(`startNode?.parentElement?.children.item(0)`, startNode?.parentElement?.children.item(0)?.id)
        // console.log(`root?.parentElement`, root?.c)
        // console.log(`this.id`, id);
        // console.log(`startNode?.parentElement?.parentElement`, startNode?.parentElement?.parentElement, startNode?.parentElement?.parentElement?.id);
        if(!checkIsInNeededRoot(startNode, id)){
            // console.log(`Root id was not found, root is ${startNode?.parentElement?.parentElement?.id} and not ${id}`);
            // console.log(`Root id was not found, root is ${startNode?.parentElement?.children.item(0)?.id} and not ${id}`);
            return {
                start: -1,
                end: -1
            }
        }
        if(startNode && startNode.isSameNode(endNode) && range.commonAncestorContainer.parentNode?.parentNode){
            root = range.commonAncestorContainer.parentNode.parentNode;
        }

        debug && console.log(`root`, root);
        if(root && root.childNodes && startNode && endNode){
            if(startNode.compareDocumentPosition(endNode) === 2){
                //if endNode is before startNode - reordering
                let buffer = startNode;
                startNode = endNode;
                endNode = buffer;
                debug && console.log(`startNode <-> endNode`)
            }
            for(let i = 0; i < root.childNodes.length; i++){
                const e = root.childNodes.item(i);
                debug && console.log(`e`, e)
                if(isStartCalculated && isEndCalculated) break;

                if(e){
                    if(startNode.isSameNode(endNode) && startNode.isSameNode(e)){
                        //selection within one tag
                        let arr = [startOffset + range.startOffset, startOffset + range.startOffset + selection.toString().length].sort((a, b) => a - b);
                        return {
                            start: arr[0],
                            end: arr[1]
                        }
                    }

                    if(e.isSameNode(startNode.parentNode) && !isStartCalculated){
                        debug && console.log(`START NODE FOUND | startOffset (${range.startOffset}) + range.startOffset (${range.startOffset}) = ${startOffset + range.startOffset}`, e);
                        startOffset += range.startOffset;
                        isStartCalculated = true;
                    }else if(!isStartCalculated){
                        // console.log('Nodes are not equal', e, startNode)
                        // console.log(`Start node not found, adding e.textContent).length of`, e.textContent);
                        startOffset += clearInnerText(e).length;
                        // console.log(`startNode not found, adding its textContent, ${e.textContent} startOffset = `, startOffset)
                    }

                    if(e.isSameNode(endNode.parentNode) && !isEndCalculated){
                        debug && console.log(`END NODE FOUND`, e);
                        endOffset += range.endOffset;
                        isEndCalculated = true;
                    }else if(!isEndCalculated){
                        endOffset += clearInnerText(e).length;
                        debug && console.log(`endNode not found, adding its textContent, ${e.textContent} endOffset = `, endOffset)
                    }
                }
            }
        }

        if(selection.isCollapsed){
            return{
                start: startOffset,
                end: startOffset
            }
        }else{
            let arr = [startOffset, endOffset].sort((a, b) => a - b);
            return {
                start: arr[0],
                end: arr[1]
            }
        }
    }

    return {
        start: 0,
        end: 0
    }
}

export function addClassForSelectedText(prevSequence: TCharSequence[], rangeStart: number, rangeEnd: number, className: string): TCharSequence[] {
    //adding classes into charSequence
    if(rangeStart === rangeEnd) return prevSequence;
    const newSequence = [...prevSequence];
    for(let i = rangeStart; i < rangeEnd; i++){
        const currentChar = newSequence[i];
        newSequence[i] = {...currentChar, styles: [...currentChar.styles, className]}
    }
    return newSequence;
}

export function replaceClassForSelectedText(prevSequence: TCharSequence[], rangeStart: number, rangeEnd: number, removeClassNames: string[], removeClassNamesStartWith: string[], insertClassNames: string[]): TCharSequence[] {
    if(rangeStart === rangeEnd) return prevSequence;
    // const fontSizeClass = GET_FONTSIZE_CSS_CLASS_NAME(fontSize);
    const newSequence = [...prevSequence];
    for(let i = rangeStart; i < rangeEnd; i++){
        const currentChar = newSequence[i];
        newSequence[i] = {
            ...currentChar,
            styles: [
                ...currentChar.styles
                    .filter(e => !removeClassNamesStartWith.find((rc: string) => e.startsWith(rc)) && !removeClassNames.includes(e)),
                ...(insertClassNames)
            ]
        }
    }
    // console.log(`replaceClassForSelectedText: newSequence`, newSequence)
    return newSequence;
}

export function applySubscriptOrSuperscript(isSuperscript: boolean, prevSequence: TCharSequence[], rangeStart: number, rangeEnd: number): TCharSequence[] {
    //applying classNames to selected text, removing old classNames of sub/super in case fontSize was updated
    if(rangeStart === rangeEnd) return prevSequence;
    // const fontSizeClass = GET_FONTSIZE_CSS_CLASS_NAME(fontSize);
    const newSequence = [...prevSequence];
    for(let i = rangeStart; i < rangeEnd; i++){
        const currentChar = newSequence[i];
        const fontSizeClass = currentChar.styles.find(e => e.startsWith(FONTSIZE_CSS_CLASS_NAME));
        const fontSize = fontSizeClass ? parseInt(fontSizeClass.substring(FONTSIZE_CSS_CLASS_NAME.length)) : 16;
        newSequence[i] = {
            ...currentChar,
            styles: [
                ...currentChar.styles
                    .filter(e => !e.startsWith(SUPERSCRIPT_CSS_CLASS_NAME) && !e.startsWith(SUBSCRIPT_CSS_CLASS_NAME)),
                (isSuperscript ? GET_SUPERSCRIPT_CSS_CLASS_NAME(fontSize) : GET_SUBSCRIPT_CSS_CLASS_NAME(fontSize))
            ]
        }
    }
    // console.log(`applySubscriptOrSuperscript: newSequence`, newSequence)
    return newSequence;
}

export function updateSubscriptOrSuperscriptAfterFontSizeUpdate(prevSequence: TCharSequence[]): TCharSequence[] {
    //if super/subscript was applied to some parts of text and fontSize updates -
    //the old class name like superscript16 will be kept, so now we need to apply new superscript according to selected fontSize
    //otherwise super/sub text will be kept with old size
    const newSequence = [...prevSequence];
    for(let i = 0; i < prevSequence.length; i++){
        const currentChar = newSequence[i];
        const fontSizeClass = currentChar.styles.find(e => e.startsWith(FONTSIZE_CSS_CLASS_NAME));
        const fontSize = fontSizeClass ? parseInt(fontSizeClass.substring(FONTSIZE_CSS_CLASS_NAME.length)) : 16;
        const isSuperscript = currentChar.styles.find(e => e.startsWith(SUPERSCRIPT_CSS_CLASS_NAME));
        const isSubscript = currentChar.styles.find(e => e.startsWith(SUBSCRIPT_CSS_CLASS_NAME))
        newSequence[i] = {
            ...currentChar,
            styles: [
                ...currentChar.styles
                    .filter(e => !e.startsWith(SUPERSCRIPT_CSS_CLASS_NAME) && !e.startsWith(SUBSCRIPT_CSS_CLASS_NAME)),
                ...(isSuperscript ? [GET_SUPERSCRIPT_CSS_CLASS_NAME(fontSize)] : (isSubscript ? [GET_SUBSCRIPT_CSS_CLASS_NAME(fontSize)] : []))
            ]
        }
    }
    // console.log(`updateSubscriptOrSuperscriptAfterFontSizeUpdate: newSequence`, newSequence)
    return newSequence;
}

export function removeSubscriptOrSuperscript(prevSequence: TCharSequence[], rangeStart: number, rangeEnd: number): TCharSequence[] {
    if(rangeStart === rangeEnd) return prevSequence;
    // const fontSizeClass = GET_FONTSIZE_CSS_CLASS_NAME(fontSize);
    const newSequence = [...prevSequence];
    for(let i = rangeStart; i < rangeEnd; i++){
        const currentChar = newSequence[i];
        // const fontSizeClass = currentChar.styles.find(e => e.startsWith(FONTSIZE_CSS_CLASS_NAME));
        // const fontSize = fontSizeClass ? parseInt(fontSizeClass.substring(FONTSIZE_CSS_CLASS_NAME.length)) : 16;
        newSequence[i] = {
            ...currentChar,
            styles: [
                ...currentChar.styles
                    .filter(e => !e.startsWith(SUPERSCRIPT_CSS_CLASS_NAME) && !e.startsWith(SUBSCRIPT_CSS_CLASS_NAME)),
            ]
        }
    }
    // console.log(`removeSubscriptOrSuperscript: newSequence`, newSequence)
    return newSequence;
}

export function removeClassForSelectedText(prevSequence: TCharSequence[], rangeStart: number, rangeEnd: number, className: string): TCharSequence[] {
    //adding classes into charSequence
    if(rangeStart === rangeEnd) return prevSequence;
    const newSequence = [...prevSequence]; //maybe replace with json parse/stringify
    for(let i = rangeStart; i < rangeEnd; i++){
        const currentChar = newSequence[i];
        newSequence[i] = {...currentChar, styles: currentChar.styles.filter(e => e !== className)}
    }
    return newSequence;
}

export function removeAllFormattingForSelectedText(prevSequence: TCharSequence[], rangeStart: number, rangeEnd: number): TCharSequence[] {
    //if its cursor - it will affect next styles only
    if(rangeStart === rangeEnd) return prevSequence;
    const newSequence = [...prevSequence]; //maybe replace with json parse/stringify
    for(let i = rangeStart; i < rangeEnd; i++){
        const currentChar = newSequence[i];
        if(currentChar.href !== undefined){
            newSequence[i] = {...currentChar, styles: [GET_FONTSIZE_CSS_CLASS_NAME(16), GET_FONT_CSS_CLASS_NAME('Arial'), LINK_CSS_CLASS_NAME], href: currentChar.href, varId: undefined}
        }else{
            newSequence[i] = {...currentChar, styles: [GET_FONTSIZE_CSS_CLASS_NAME(16), GET_FONT_CSS_CLASS_NAME('Arial')], varId: undefined}
        }
    }
    // console.log(`removeAllFormattingForSelectedText`, newSequence)
    return newSequence;
}

export function insertChar(prevCharSequence: TCharSequence[], cursorPointerIndex: number, char: string, currentStyles: string[], href?: string | undefined | null): {newSequence: TCharSequence[], newCursorPosition: number}{
    //(rerender) will be called after this function
    //is used to insert char in sequence
    //if inserting newLine - inserting \n +  SPACE_HTML_CODE to show new line in div (By default if tag is empty, the br standing before will give no affect)
    //if inserting space - just inserting space char into sequence
    //and by default inserting char into sequence
    //but if index - 2 is newline and index -1 is space (<br><span>SPACE|</span>) and inserting char where is | - replacing space with char
    //so the next line we have created early with SPACE_HTML_CODE will be overwritten with the char
    let debug = false;
    // console.log('handleOnInput', char)
    let copyArr = [...prevCharSequence];

    if ([NEWLINE_COMMAND, NEWLINE_HTML, NEWLINE_HTML_CODE].includes(char)) {

        //insert space only if its next char is \n
        // console.log(`copyArr[cursorPointerIndex]?.char, (${copyArr[cursorPointerIndex]?.char}) === newline? ${copyArr[cursorPointerIndex]?.char === NEWLINE_COMMAND}`)
        if(isNewLine(copyArr[cursorPointerIndex]?.char) || copyArr[cursorPointerIndex + 1]?.char === undefined){
            copyArr.splice(cursorPointerIndex, 0, ...[{char: NEWLINE_TO_SET, styles: currentStyles}, {char: SPACE_0WIDTH_HTML_CODE, styles: currentStyles}]);
            // console.log(`NEWLINE inserted at index ${cursorPointerIndex + 1} and added SPACE`, copyArr);
            return {
                newSequence: copyArr,
                //because we have to add span with space in new line - jumping to this span after space
                newCursorPosition: cursorPointerIndex + 2
            };
        }else{
            copyArr.splice(cursorPointerIndex, 0, ...[{char: NEWLINE_TO_SET, styles: currentStyles}]);
            // console.log(`NEWLINE inserted at index ${cursorPointerIndex + 1}`, copyArr);
            return {
                newSequence: copyArr,
                //because we have to add span with space in new line - jumping to this span after space
                newCursorPosition: cursorPointerIndex + 1
            };
        }

        // copyArr.splice(cursorPointerIndex, 0, ...[{char: NEWLINE_COMMAND, styles: currentStyles}, {char: SPACE_HTML_CODE, styles: currentStyles}]);
        // console.log(`NEWLINE inserted at index ${cursorPointerIndex + 1} and added SPACE`, copyArr);
        // return {
        //     newSequence: copyArr,
        //     //because we have to add span with space in new line - jumping to this span after space
        //     newCursorPosition: cursorPointerIndex + 2
        // };

    } else if (char === SPACE_HTML_CODE || char === ' ' || char === '&nbsp;' || char === '\u00A0') {

        if(href) currentStyles.push(LINK_CSS_CLASS_NAME);
        copyArr.splice(cursorPointerIndex, 0, ...[{char: SPACE_TO_SET, styles: currentStyles, href: href ?? undefined}]);
        debug && console.log(`handleOnInput, SPACE at index ${cursorPointerIndex} `, copyArr);
        return {
            newSequence: copyArr,
            newCursorPosition: cursorPointerIndex + 1
        };

    } else {

        // console.log(`insertChar default ${char}`)
        // debug && console.log(`handleOnInput, char ${char}`, copyArr);
        //
        // debug && console.log(`cursorPointerIndex: ${cursorPointerIndex} | copyArr.length: ${copyArr.length} \n
        // copyArr[cursorPointerIndex - 1]?.char (${copyArr[cursorPointerIndex - 1]?.char}) \n
        // cursorPointerIndex === copyArr.length: ${cursorPointerIndex === copyArr.length} \n
        // copyArr[cursorPointerIndex - 1]?.char === ' ' : ${copyArr[cursorPointerIndex - 1]?.char === SPACE_HTML_CODE}
        // `)
        if(cursorPointerIndex === -1){
            copyArr.splice(0, 1, ...[{char, styles: currentStyles}]);
            return {
                newSequence: copyArr,
                newCursorPosition: 1
            };
        }

        //if cursor stands at link
        //if on left and on right links too with same href
        //insert char with href from char at cursor + link css class
        let prev = copyArr[cursorPointerIndex - 1];
        let curr = copyArr[cursorPointerIndex];
        // let next = copyArr[cursorPointerIndex + 1];
        // console.log(`INSERT CHAR INSIDE LINK`, prev, curr, next)
        if(curr && curr?.href !== undefined && prev?.href !== undefined && prev?.href === curr?.href){
            // console.log(`INSERT CHAR INSIDE LINK`)
            //inserting char inside link - make it link too
            copyArr.splice(cursorPointerIndex, 0, ...[{char, styles: [...currentStyles, LINK_CSS_CLASS_NAME], href: curr.href}]);
            return {
                newSequence: copyArr,
                newCursorPosition: cursorPointerIndex + 1
            };
        }

        if(isNewLine(copyArr[cursorPointerIndex - 2]?.char) && copyArr[cursorPointerIndex - 1]?.char === SPACE_0WIDTH_HTML_CODE){
            //when enter clicked at end of the line - we add br and new span with space inside, so new line will be shown
            //now replacing this space with new char
            debug && console.log(`cursorPointerIndex === copyArr.length && copyArr[cursorPointerIndex - 1]?.char === ' ' TRUE`)
            copyArr.splice(cursorPointerIndex - 1, 1, ...[{char, styles: currentStyles}]);
            // debug && console.log(`deleted`, deleted)
            // console.log(`newCursorPointer`, cursorPointerIndex, copyArr)
            return {
                newSequence: copyArr,
                newCursorPosition: cursorPointerIndex
            };
        }else{
            copyArr.splice(cursorPointerIndex, 0, ...[{char, styles: currentStyles}]);
            // console.log(`JUST INSERTED NEW CHAR`, char, currentStyles, copyArr)
            // console.log(`newCursorPointer`, cursorPointerIndex + 1, copyArr)
            return {
                newSequence: copyArr,
                newCursorPosition: cursorPointerIndex + 1
            };
        }

    }
}

export function replaceWithChar(prevCharSequence: TCharSequence[], cursorPointerIndex: number, selectionStart: number, selectionEnd: number, char: string, currentStyles: string[], href?: string | undefined | null): {newSequence: TCharSequence[], newCursorPosition: number}{
    // console.log(`replaceWithChar href`, href);
    //used to replace selected text with inserted char
    //rerender will be called after this func
    // console.log('handleOnInput', char)
    const copyArr = [...prevCharSequence];
    //selection was made - replacing
    const selectedCharStyles = copyArr[selectionStart]?.styles;
    copyArr.splice(
        selectionStart,
        selectionEnd - selectionStart,
        {
            char,
            styles: selectedCharStyles.filter(e => e !== VARIABLE_CSS_CLASS_NAME && e !== FOCUSED_VARIABLE_CSS_CLASS_NAME) ?? currentStyles,
            href: href ?? undefined
        });
    // console.log(`replaceWithChar from ${selectionStart} to ${selectionEnd}, newIndex: ${selectionStart}`, copyArr);
    return {
        newSequence: copyArr,
        newCursorPosition: selectionStart + 1
    };
}

export const handleBackspace = (prevCharSequence: TCharSequence[], selectionStart: number, selectionEnd: number, cursorAtIndex: number, classNames: string[]): { newSequence: TCharSequence[], newCursorPosition: number } => {
    //used to handle backspace
    //if its cursor - just removing item
    //but if there is new line right before char we backspace - removing it to, to not create double newline (it does not work if their two in a row)
    const copyArr = [...prevCharSequence];
    // console.log(`handleBackspace`, copyArr)
    if(cursorAtIndex === 0) return {newSequence: prevCharSequence, newCursorPosition: cursorAtIndex};
    if(cursorAtIndex !== -1) {
        //its cursor
        if(prevCharSequence[cursorAtIndex -2]?.varId !== undefined && prevCharSequence[cursorAtIndex - 1]?.char === SPACE_0WIDTH_HTML_CODE){
            // VAR->SPACE|
            // console.log(`handleBackspace: VAR->SPACE| at `, cursorAtIndex)
            let variableLength = 0;
            for(let i = cursorAtIndex - 2; i >= 0; i--){
                // console.log(`prevCharSequence[i]`, prevCharSequence[i], i)
                if(prevCharSequence[i]?.varId === undefined || prevCharSequence[i]?.varId !== prevCharSequence[cursorAtIndex -2].varId){
                    variableLength = cursorAtIndex - i;
                    break;
                }
            }
            copyArr.splice(cursorAtIndex - variableLength + 1, variableLength);
            // console.log(`handleBackspace: remove ${variableLength} starting from ${cursorAtIndex - variableLength + 1}, new cursor ${cursorAtIndex - variableLength}`, deleted)
            return {
                newSequence: copyArr,
                newCursorPosition: cursorAtIndex - variableLength
            };
        }

        if(isNewLine(prevCharSequence[cursorAtIndex - 2]?.char)){
            // console.log(`BACKSPACE - 2 chars left is newLine!`)
            copyArr.splice(cursorAtIndex - 1, 1, {char: SPACE_0WIDTH_HTML_CODE, styles: classNames});
            // const deleted = copyArr.splice(cursorAtIndex - 1, 1);
            // console.log(`BACKSPACE CURSOR DELETING {LINE} STARTING FROM ${cursorAtIndex - 1}, NEW INDX: ${cursorAtIndex - 1}`, copyArr)
            return {
                newSequence: copyArr,
                newCursorPosition: cursorAtIndex - 1
            };
        }else{
            // console.log(`prevCharSequence[selectionStart - 1]`, prevCharSequence[cursorAtIndex - 1])
            let prevChar = prevCharSequence[cursorAtIndex - 1];
            if(prevChar?.varId !== undefined){
                // console.log(`BACKSPACE PREV IS VARIABLE`, prevChar)
                //cursor stands n+1 indexes from variable - removing whole variable and return prevCursor - variable length
                for(let i = cursorAtIndex; i >= 0; i--){
                    // console.log(`Removing also`, prevCharSequence[i]);
                    //iterating on chars, until we see char that is not var or char with another varId
                    if(prevCharSequence[i - 1]?.varId === undefined || prevCharSequence[i - 1]?.varId !== prevChar.varId){
                        //char that is not same var was found, removing items from cursorAtIndex to cursorAtIndex - i
                        let deleteCount = cursorAtIndex - i;
                        let isRemoveAlsoNewLine = isNewLine(prevCharSequence[i - 1]?.char);
                        let isRemoveAlsoZESP = prevCharSequence[i + deleteCount + 1]?.char === SPACE_0WIDTH_HTML_CODE;
                        if(isRemoveAlsoNewLine) deleteCount++;
                        if(isRemoveAlsoZESP) deleteCount++;
                        copyArr.splice(i, deleteCount);
                        // console.log(`BACKSPACE REMOVE WHOLE VARIABLE | cursor | seq`, i, copyArr)
                        return {
                            newSequence: copyArr,
                            newCursorPosition: isRemoveAlsoNewLine ? i - 1 : i
                        }
                    }
                }
            }
            // console.log(`prevChar prevCharSequence[cursorAtIndex - 1]`, prevCharSequence[cursorAtIndex - 1])
            // console.log(`curr prevCharSequence[cursorAtIndex]`, prevCharSequence[cursorAtIndex])
            if(prevChar.char === SPACE_0WIDTH_HTML_CODE && prevCharSequence[cursorAtIndex - 1]?.varId !== undefined){
                // console.log(`REMOVE VARIABLE + ZWSP`)
                //cursor stands n+1 indexes from variable - removing whole variable and return prevCursor - variable length
                // for(let i = cursorAtIndex - 1; i >= 0; i--){
                //     // console.log(`Removing also`, prevCharSequence[i]);
                //     //iterating on chars, until we see char that is not var or char with another varId
                //     if(prevCharSequence[i - 1]?.varId === undefined || prevCharSequence[i - 1]?.varId !== prevChar.varId){
                //         //char that is not same var was found, removing items from cursorAtIndex to cursorAtIndex - i
                //         let deleteCount = cursorAtIndex - i;
                //         let isRemoveAlsoNewLine = prevCharSequence[i - 1]?.char === NEWLINE_COMMAND;
                //         if(isRemoveAlsoNewLine) deleteCount++;
                //         copyArr.splice(i, deleteCount);
                //         console.log(`BACKSPACE REMOVE WHOLE VARIABLE+0SPACe | cursor | seq`, i, copyArr)
                //         return {
                //             newSequence: copyArr,
                //             newCursorPosition: isRemoveAlsoNewLine ? i - 1 : i
                //         }
                //     }
                // }
            }
            copyArr.splice(cursorAtIndex - 1, 1);
            // console.log(`BACKSPACE CURSOR DELETING {CHAR} STARTING FROM ${cursorAtIndex - 1}, NEW INDX: ${cursorAtIndex - 1}`, copyArr, deleted)
            return {
                newSequence: copyArr,
                newCursorPosition: cursorAtIndex - 1
            };
        }
    }else{
        let realStart = Math.max(selectionStart, 0)
        if((copyArr.length - 1) === (selectionEnd - realStart)){
            // console.log(`BACKSPACE SELECTION ALL`);
            return {
                newSequence: [],
                newCursorPosition: 0
            }
        }
        //its selection
        // console.log(`BACKSPACE SELECTION BEFORE ACTION`, JSON.parse(JSON.stringify(copyArr)))
        copyArr.splice(realStart, (selectionEnd - realStart));
        // console.log(`BACKSPACE SELECTION NEW INDX: ${realStart} | removing indexes ${(selectionEnd - realStart)} ${realStart} - ${selectionEnd}`, copyArr)
        return {
            newSequence: copyArr,
            newCursorPosition: realStart
        };
    }
}

export const renderCharSequence = (sequence: TCharSequence[], isReadOnly: boolean, ignoreNewLine?: boolean): string => {
    createEventUpdateVariableUsages({});

    //used to generate html by sequence
    //concating char with same styles into single element
    //+ compiles \n into br

    // console.log(`renderCharSequence`, sequence)
    // console.log(`html rerender`)
    const root = document.createElement('div');
    // console.log(`sequence.length: ${sequence.length}`)
    for(let i = 0; i < sequence.length; i++){
        //finding current char
        const currentChar = sequence[i];

        if(isNewLine(currentChar.char)){
            if(ignoreNewLine) continue;
            const child = document.createElement('span');
            child.classList.add(...currentChar.styles);
            child.innerHTML = NEWLINE_TO_SET;
            root.appendChild(child);
            continue;
        }
        //storing char with same styles
        const alikeCharSequence = [currentChar.char];
        const currentStyles = currentChar.styles;
        //starting with next standing char
        let counter = i + 1;
        //checking if it is not end of array and styles matches
        while(
            counter < sequence.length &&
            arraysEqual(sequence[counter].styles, currentStyles) &&
            !isNewLine(sequence[counter].char) &&
            !isSpace(sequence[counter].char) &&
                //if first char is variable - going forward until we see char without varId or with another one, else - continue
            (currentChar.varId ? (sequence[counter]?.varId !== undefined && sequence[counter]?.varId === sequence[counter - 1]?.varId) : true) &&
                //if first char is link - going forward until we see char without href or with another one, else - continue
            (currentChar.href ? (sequence[counter]?.href !== undefined && sequence[counter]?.href === sequence[counter - 1]?.href) : true)
            ){
            //adding char to alikeSequence
            alikeCharSequence.push(sequence[counter].char);
            //updating counter to check next element
            counter++;
        }
        //creating span with classNames
        const child = document.createElement('span');
        child.classList.add(...currentStyles);
        if(isReadOnly){
            child.classList.remove(VARIABLE_CSS_CLASS_NAME);
        }
        //joining the array of chars and setting as innerText
        child.innerText = alikeCharSequence.join('');
        //if its variable - inserting data attribute with varId to parse it on load
        if(currentStyles.includes(VARIABLE_CSS_CLASS_NAME)){
            // console.log(`sequnce`, sequence, counter)
            child.setAttribute(VARIABLE_ID_DATA_ATTRIBUTE_NAME, sequence[counter - 1]?.varId ?? 'varIdNotFound');
            // child.contentEditable = 'false';
        }
        if(currentStyles.includes(LINK_CSS_CLASS_NAME)){
            child.setAttribute(LINK_DATA_ATTRIBUTE_NAME, sequence[counter - 1]?.href ?? 'hrefIdNotFound');
        }
        root.appendChild(child);
        //jumping to next element with different style
        i = counter - 1; //removing 1 because i++ in for loop
    }
    return root.innerHTML;
}

function arraysEqual(a: any[], b: any[]) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

export function getClassNamesByRange(sequence: TCharSequence[], rangeStart: number, rangeEnd: number): string[]{
    //used to find out which css classes were applied to current selection/cursor position
    //need deep copy, otherwise it will mutate the state
    const currentSeq = JSON.parse(JSON.stringify(sequence));
    if(rangeStart === rangeEnd){ //if it is cursor
        rangeStart = rangeStart - 1; //because of indexing in sequence array
        rangeEnd = rangeEnd - 1; //because of indexing in sequence array
        const currentChar = currentSeq[rangeStart];
        if(currentChar){
            // console.log(`currentChar ${currentChar.char} found`, sequence[rangeStart].styles)
            return currentSeq[rangeStart].styles;
        }else{
            if(rangeStart === -1){
                //by default if cursor stands in ab|c -> we return styles of b
                //but if cursor at index 0 |abc -> we have to return styles of a
                //weird behavior but ok
                return currentSeq[0]?.styles ?? [];
            }
            // console.error(`rangeStart (${rangeStart}) was not found in sequence length=(${sequence.length})`)
        }
    }

    let commonStyles: string[] = [];
    let blackListStyles: string[] = [];
    for(let i = rangeStart; i < rangeEnd; i++){
        // console.log(`for loop - ${sequence[i].char} - `, sequence[i].styles)
        if(!commonStyles.length && i === rangeStart){ //first loop
            commonStyles = currentSeq[i]?.styles ?? [];
            // console.log(`commonStyles init`, commonStyles);
        }else{
            for(let style of commonStyles){
                //iterating on commonStyles
                if(!currentSeq[i]?.styles.includes(style)){
                    // console.log(`style ${style} will be removed from commonStyles`, commonStyles)
                    //if first char had style that current chat does not have - remove this from commonStyles (we need only shared styles across the selected chars)
                    commonStyles.splice(commonStyles.indexOf(style), 1);
                    blackListStyles.push(style);
                    // console.log(`commonStyles after deletion`, commonStyles)
                }
            }
            //if current class exists in commonStyles - keep
            //if current class does not exist in commonStyles - dont add it
            //if current class exist in commonStyles - keep
        }
    }
    // console.log(`commonStyles: `, commonStyles)
    // console.log(`sequence in getClassNamesByRange`, sequence)
    return commonStyles;
}

export const clearInnerText = (element: ChildNode): string => {
    //used to get clear child innerText
    //so if there br element which has no inner text, responsing with space string to show it takes 1 index in charSequence,
    // so indexes will work equally for charSequence and selection within html
    //and if this is normal tag child with inner text, replacing its SPACE_HTML_CODE to space so it will take 1  index instead of 4 (\xA0) if such inner text was applied

    if(!element) return '';
    if(element.nodeName === 'BR') return SPACE_TO_SET;
    return element.textContent?.replaceAll(SPACE_HTML_CODE, SPACE_TO_SET)
        .replaceAll('&nbsp;', SPACE_TO_SET)
        .replaceAll('\u00A0', SPACE_TO_SET)
        ?? '';
}

export const insertPastedText = (prevCharSequence: TCharSequence[], text: string, cursorPosition: number, selectionStart: number, selectionEnd: number, currentStyles: string[]): { newSequence: TCharSequence[]; newCursorPosition: number } => {
    //inserting plain text into sequence by selection or cursor, updating with new cursor position
    let copyArr = [...prevCharSequence];
    if(cursorPosition !== -1){
        //insert by cursor
        const prevCharStyles = prevCharSequence[cursorPosition]?.styles;
        let charSet = text.split('').map(e => {return {char: e, styles: currentStyles ?? prevCharStyles ?? []}});
        // console.log(`pasted text charSet`, charSet)

        if(isNewLine(copyArr[cursorPosition - 2]?.char) && isSpace(copyArr[cursorPosition - 1]?.char)) {
            //if user pressed enter there will be new line with space span
            //so searching if prev char is space and one prev is \n
            //if so - removing space that was used to hold place to show new line
            copyArr.splice(cursorPosition - 1, 1, ...charSet);
            // console.log(`insertPastedText | newCurs ${cursorPosition + text.length}`)
            return {
                newSequence: copyArr,
                newCursorPosition: cursorPosition + text.length - 1
            }
        }else{
            //just a insertion
            copyArr.splice(cursorPosition, 0, ...charSet);
            // console.log(`insertPastedText | newCurs ${cursorPosition + text.length}`)
            return {
                newSequence: copyArr,
                newCursorPosition: cursorPosition + text.length
            }
        }
    }else{
        //insert by selection
        const prevCharStyles = prevCharSequence[cursorPosition]?.styles;
        let charSet = text.split('').map(e => {return {char: e, styles: currentStyles ?? prevCharStyles ?? []}});
        copyArr.splice(selectionStart, selectionEnd - selectionStart, ...charSet);
        return {
            newSequence: copyArr,
            newCursorPosition: selectionEnd
        }
    }
}

export const getToolBarSettingsByClassNames = (classNames: string[], align: string, isEmpty: boolean, charSequence: TCharSequence[], selectionStart: number, selectionEnd: number): TToolBarSlice => {
    // console.log(`----getToolBarSettingsByClassNames----`, selectionStart, selectionEnd)
    const fontSizeClass = classNames.find(e => e.startsWith(FONTSIZE_CSS_CLASS_NAME));
    const fontClass = classNames.find(e => e.startsWith(FONT_CSS_CLASS_NAME));
    const fontSize = fontSizeClass ? parseInt(fontSizeClass?.substring(FONTSIZE_CSS_CLASS_NAME.length)) : (isEmpty ? DEFAULT_FONT_SIZE : null);
    const isBold = classNames.includes(BOLD_CSS_CLASS_NAME);
    const fontColorClass = classNames.find(e => e.startsWith(FONT_COLOR_CSS_CLASS_NAME));
    const isLink = selectionStart !== selectionEnd && classNames.some(e => e === LINK_CSS_CLASS_NAME) && charSequence[selectionStart + 1]?.href !== undefined;

    //SUB/SUPERSCRIPT
    let isSuperscript = false;
    let isSubscript = false;
    if(selectionStart === selectionEnd){
        //cursor
        //if cursor - checking only last char styles, so checking classNames
        isSuperscript = classNames.some(e => e.startsWith(SUPERSCRIPT_CSS_CLASS_NAME));
        isSubscript = classNames.some(e => e.startsWith(SUBSCRIPT_CSS_CLASS_NAME));
    }else{
        isSuperscript = true;
        isSubscript = true;
        // selection - checking every char has sub or super
        charSequence.slice(selectionStart, selectionEnd).forEach(e => {
            if(!e.styles.some(e => e.startsWith(SUPERSCRIPT_CSS_CLASS_NAME))){
                // console.log(`---- getToolBarSettingsByClassNames selection has char with no superscript`, e)
                isSuperscript = false;
            }
            if(!e.styles.some(e => e.startsWith(SUBSCRIPT_CSS_CLASS_NAME))){
                // console.log(`---- getToolBarSettingsByClassNames selection has char with no subscript`, e)
                isSubscript = false;
            }
        })
    }
    return {
        ...initialEditorToolBarState,
        styledControl: {
            isShow: true,
            isDisabled: false,
            value: getTextStyleByFontSizeAndIsBold(fontSize, isBold, isEmpty)
        },
        fontControl: {
            isShow: true,
            isDisabled: false,
            value: fontClass ? normalizeFontName(fontClass?.substring(FONT_CSS_CLASS_NAME.length)) : (isEmpty ? DEFAULT_FONT : null)
        },
        fontSizeControl: {
            isShow: true,
            isDisabled: false,
            value: fontSize
        },
        decorationControl: {
            isShow: true,
            isDisabled: false,
            isBold,
            isItalic: classNames.includes(ITALIC_CSS_CLASS_NAME),
            isUnderline: classNames.includes(UNDERLINE_CSS_CLASS_NAME) || isLink,
            color: isLink ? LINK_COLOR : (fontColorClass ? `#${fontColorClass?.substring(FONT_COLOR_CSS_CLASS_NAME.length)}` : DEFAULT_COLORS[0]),
            isStrikethrough: isLink ? false : classNames.includes(STRIKE_THROUGH_CSS_CLASS_NAME),
            isSuperscript,
            isSubscript,
        },
        alignmentControl: {
            isShow: true,
            isDisabled: false,
            value: align as TFontAlignmentTitle
        },

        listTypeControl: {
            isShow: true,
            isDisabled: false,
            value: null
        },
        linkControl: {
            isDisabled: isLinkToolBarDisabled(charSequence, selectionStart, selectionEnd),
            isShow: true,
            isOpenDialog: false,
            value: false
        },
        removeFormatting: {
            isDisabled: false,
            isShow: true
        },
        ids: [],
    }
}

export const getClassNamesByToolBarSettings = (toolBar: TToolBarSlice): string[] => {
    //used to find which classNames insert in next inserting char
    //todo add color/superscript
    const res: string[] = [];
    // console.log(`getClassNamesByToolBarSettings toolBar.styledControl.value`, toolBar.styledControl.value)
    if(toolBar.styledControl.value && toolBar.fontControl.value && toolBar.fontSizeControl.value){
        toolBar.decorationControl.isSubscript && res.push(GET_SUBSCRIPT_CSS_CLASS_NAME(toolBar.fontSizeControl.value));
        toolBar.decorationControl.isSuperscript && res.push(GET_SUPERSCRIPT_CSS_CLASS_NAME(toolBar.fontSizeControl.value));

        if(toolBar.styledControl.value !== "Normal text" && !toolBar.decorationControl.isUnderline && !toolBar.decorationControl.isItalic && !toolBar.decorationControl.isStrikethrough){
            //its heading 1-5
            res.push(GET_FONT_CSS_CLASS_NAME(toolBar.fontControl.value));
            res.push(getFontSizeByFontStyle(toolBar.styledControl.value));
            toolBar.decorationControl.isBold && res.push(BOLD_CSS_CLASS_NAME);
            return res;
        }else{
            // console.log(`getClassNamesByToolBarSettings toolBar.styledControl.value is not Normal text`)
            res.push(GET_FONT_CSS_CLASS_NAME(toolBar.fontControl.value));
            res.push(GET_FONTSIZE_CSS_CLASS_NAME(toolBar.fontSizeControl.value));
            if(toolBar.decorationControl.isBold) res.push(BOLD_CSS_CLASS_NAME);
            if(toolBar.decorationControl.isUnderline) res.push(UNDERLINE_CSS_CLASS_NAME);
            if(toolBar.decorationControl.isItalic) res.push(ITALIC_CSS_CLASS_NAME);
            if(toolBar.decorationControl.isStrikethrough) res.push(STRIKE_THROUGH_CSS_CLASS_NAME);
        }
    }
    res.push(GET_FONT_COLOR_CSS_CLASS_NAME(toolBar.decorationControl.color));
    return res;
}

export const normalizeFontName = (font: string): TFont => {
    if(font === TIMES_NEW_ROMAN_FONT_CSS_CLASS_NAME) return "Times New Roman";
    return font as TFont;
}

export const getTextStyleByFontSizeAndIsBold = (fontSize: number | null, isBold: boolean, isEmpty: boolean):TFontStyle |  null => {
    if(!fontSize) return null;
    if(!isBold || isEmpty) return "Normal text";
    if(fontSize === 30) return "Heading 1";
    if(fontSize === 24) return "Heading 2";
    if(fontSize === 20) return "Heading 3";
    if(fontSize === 18) return "Heading 4";
    if(fontSize === 16) return "Heading 5";
    return null;
}

export const getFontSizeByFontStyle = (fontStyle: TFontStyle):string => {
    switch (fontStyle){
        case "Heading 1": {
            return GET_FONTSIZE_CSS_CLASS_NAME(30);
        }
        case "Heading 2": {
            return GET_FONTSIZE_CSS_CLASS_NAME(24);
        }
        case "Heading 3": {
            return GET_FONTSIZE_CSS_CLASS_NAME(20);
        }
        case "Heading 4": {
            return GET_FONTSIZE_CSS_CLASS_NAME(18);
        }
        default:{
            //Normal text/ heading5 + fallback
            return GET_FONTSIZE_CSS_CLASS_NAME(16);
        }
    }
}

export const getToolBarSettingsByAction = (prevToolBar: TToolBarSlice, action: TToolBarHandlerAction, payload: TToolBarHandlerPayload): TToolBarSlice => {
    switch (action){
        case "bold": {
            if(typeof payload === 'boolean'){
                if(payload){
                    prevToolBar = {
                        ...prevToolBar,
                        decorationControl: {
                            ...prevToolBar.decorationControl,
                            isBold: payload
                        }
                    }
                    switch (prevToolBar.fontSizeControl.value){
                        case 16: {
                            return {
                                ...prevToolBar,
                                styledControl: {
                                    ...prevToolBar.styledControl,
                                    value: 'Heading 5'
                                }
                            }
                        }
                        case 18: {
                            return {
                                ...prevToolBar,
                                styledControl: {
                                    ...prevToolBar.styledControl,
                                    value: 'Heading 4'
                                }
                            }
                        }
                        case 20: {
                            return {
                                ...prevToolBar,
                                styledControl: {
                                    ...prevToolBar.styledControl,
                                    value: 'Heading 3'
                                }
                            }
                        }
                        case 24: {
                            return {
                                ...prevToolBar,
                                styledControl: {
                                    ...prevToolBar.styledControl,
                                    value: 'Heading 2'
                                }
                            }
                        }
                        case 30: {
                            return {
                                ...prevToolBar,
                                styledControl: {
                                    ...prevToolBar.styledControl,
                                    value: 'Heading 1'
                                }
                            }
                        }
                        default: {
                            return {
                                ...prevToolBar,
                                styledControl: {
                                    ...prevToolBar.styledControl,
                                    value: 'Normal text'
                                }
                            }
                        }
                    }
                }else{
                    return {
                        ...prevToolBar,
                        styledControl: {
                            ...prevToolBar.styledControl,
                            value: 'Normal text'
                        },
                        decorationControl: {
                            ...prevToolBar.decorationControl,
                            isBold: false
                        }
                    }
                }
            }
            return prevToolBar;
        }
        case "italic": {
            if (typeof payload === 'boolean') {
                return {
                    ...prevToolBar,
                    decorationControl: {
                        ...prevToolBar.decorationControl,
                        isItalic: payload
                    }
                }
            }
            return prevToolBar;
        }
        case "underline": {
            if (typeof payload === 'boolean') {
                return {
                    ...prevToolBar,
                    decorationControl: {
                        ...prevToolBar.decorationControl,
                        isUnderline: payload
                    }
                }
            }
            return prevToolBar;
        }
        case "strikethrough": {
            if (typeof payload === 'boolean') {
                return {
                    ...prevToolBar,
                    decorationControl: {
                        ...prevToolBar.decorationControl,
                        isStrikethrough: payload
                    }
                }
            }
            return prevToolBar;
        }
        case "fontSizeControl": {
            if (typeof payload === 'number') {
                return {
                    ...prevToolBar,
                    fontSizeControl: {
                        ...prevToolBar.fontSizeControl,
                        value: payload
                    }
                }
            }
            return prevToolBar;
        }
        case "fontControl": {
            if (typeof payload === 'string') {
                return {
                    ...prevToolBar,
                    fontControl: {
                        ...prevToolBar.fontControl,
                        value: payload as TFontTitle | null
                    }
                }
            }
            return prevToolBar;
        }
        case "styledControl":{
            if(typeof payload === 'string') {
                prevToolBar = {
                    ...prevToolBar,
                    fontControl: {
                        ...prevToolBar.fontControl,
                        value: 'Arial'
                    },
                    decorationControl: {
                        ...prevToolBar.decorationControl,
                        isBold: true
                    }
                }
                switch (payload){
                    case 'Normal text': {
                        return {
                            ...prevToolBar,
                            styledControl: {
                                ...prevToolBar.styledControl,
                                value: payload
                            },
                            fontSizeControl: {
                                ...prevToolBar.fontSizeControl,
                                value: 16
                            },
                            decorationControl: {
                                ...prevToolBar.decorationControl,
                                isBold: false
                            }
                        }
                    }
                    case 'Heading 1':{
                        return {
                            ...prevToolBar,
                            styledControl: {
                                ...prevToolBar.styledControl,
                                value: 'Heading 1'
                            },
                            fontSizeControl: {
                                ...prevToolBar.fontSizeControl,
                                value: 30
                            }
                        }
                    }
                    case 'Heading 2': {
                        return {
                            ...prevToolBar,
                            styledControl: {
                                ...prevToolBar.styledControl,
                                value: payload,
                            },
                            fontSizeControl: {
                                ...prevToolBar.fontSizeControl,
                                value: 24
                            }
                        }
                    }
                    case 'Heading 3': {
                        return {
                            ...prevToolBar,
                            styledControl: {
                                ...prevToolBar.styledControl,
                                value: payload
                            },
                            fontSizeControl: {
                                ...prevToolBar.fontSizeControl,
                                value: 20
                            }
                        }
                    }
                    case 'Heading 4': {
                        return {
                            ...prevToolBar,
                            styledControl: {
                                ...prevToolBar.styledControl,
                                value: payload
                            },
                            fontSizeControl: {
                                ...prevToolBar.fontSizeControl,
                                value: 18
                            }
                        }
                    }
                    case 'Heading 5': {
                        return {
                            ...prevToolBar,
                            styledControl: {
                                ...prevToolBar.styledControl,
                                value: payload
                            },
                            fontSizeControl: {
                                ...prevToolBar.fontSizeControl,
                                value: 16
                            }
                        }
                    }
                }
            }
        }
        // TODO? case "removeFormatting": {
    }

    return prevToolBar;
}

export const insertVariable = (prevCharSequence: TCharSequence[], cursorAt: number, selectionStart: number, selectionEnd: number, varId: string, varValue: string): {newCharSequence: TCharSequence[], newCursor: number} => {
    //will be used to insert variable from dialog that opens by {
    //and also in onPaste function, to paste copied variable from rightSidePanel

    //<span data-slate-zero-width="z">​</span>
    //inserting value or title of variable into char sequence instead of {
    //cursorAt will be right after {
    let copyArr = [...prevCharSequence];
    const variableSequence = varValue.split('');
    const charSeq = [...variableSequence.map((e): TCharSequence => {
        return {
            char: e,
            styles: [GET_FONTSIZE_CSS_CLASS_NAME(16), GET_FONT_CSS_CLASS_NAME('Arial'), VARIABLE_CSS_CLASS_NAME],
            varId
        }
    })];

    if(cursorAt !== -1){
        //its cursor
        let prev = prevCharSequence[cursorAt - 1];
        let prevPrev = prevCharSequence[cursorAt - 2];
        // console.log(`currentChar:`, prevCharSequence[cursorAt]);
        // console.log(`prevChar:`, prevCharSequence[cursorAt - 1]);

        if(prev?.char === CHAR_TO_OPEN_VARS && cursorAt === 1){
            // console.log(`INSERT VAR AT EMPTY BLOCK, ADDING SPACES`)
            //inserting var at empty block
            charSeq.unshift({
                char: SPACE_0WIDTH_HTML_CODE,
                styles: [GET_FONTSIZE_CSS_CLASS_NAME(16), GET_FONT_CSS_CLASS_NAME('Arial')],
            });
            charSeq.push({
                char: SPACE_0WIDTH_HTML_CODE,
                styles: [GET_FONTSIZE_CSS_CLASS_NAME(16), GET_FONT_CSS_CLASS_NAME('Arial')],
            });

            copyArr.splice(cursorAt - 1, 1, ...charSeq);
            return {
                newCharSequence: copyArr,
                newCursor: variableSequence.length + 2 //+2 to jump over ZWSP
            }
        }

        if(prev?.char === CHAR_TO_OPEN_VARS){
            //inserting by dialog - adding spaces only of no found
            let isNoSpaceBefore = !isSpace(prevPrev?.char) && prevPrev?.char !== SPACE_0WIDTH_HTML_CODE;
            let isNoSpaceAfter = !isSpace(prevCharSequence[cursorAt + 1]?.char) && prevCharSequence[cursorAt + 1]?.char !== SPACE_0WIDTH_HTML_CODE;
            if(isNoSpaceBefore){
                charSeq.unshift({
                    char: SPACE_0WIDTH_HTML_CODE,
                    styles: [GET_FONTSIZE_CSS_CLASS_NAME(16), GET_FONT_CSS_CLASS_NAME('Arial')],
                });
            }
            if(isNoSpaceAfter){
                charSeq.push({
                    char: SPACE_0WIDTH_HTML_CODE,
                    styles: [GET_FONTSIZE_CSS_CLASS_NAME(16), GET_FONT_CSS_CLASS_NAME('Arial')],
                });
            }
            // console.log(`INSERT VAR BY DIALOG (there is {) isNoSpaceBefore:${isNoSpaceBefore}|isNoSpaceAfter:${isNoSpaceAfter}`)
            copyArr.splice(cursorAt - 1, 1, ...charSeq);
            // console.log(`prev?.char === CHAR_TO_OPEN_VARS, removing 1 starting from ${cursorAt - 1}`, prevCharSequence, copyArr, deleted)
            return {
                newCharSequence: copyArr,
                newCursor: cursorAt - 1 + charSeq.length
            }
        }else{
            //inserting by paste - only add var + spaces if needed
            let isNoSpaceBefore = !isSpace(prevPrev?.char) && prevPrev?.char !== SPACE_0WIDTH_HTML_CODE;
            let isNoSpaceAfter = !isSpace(prevCharSequence[cursorAt + 1]?.char) && prevCharSequence[cursorAt + 1]?.char !== SPACE_0WIDTH_HTML_CODE;
            if(isNoSpaceBefore){
                charSeq.unshift({
                    char: SPACE_0WIDTH_HTML_CODE,
                    styles: [GET_FONTSIZE_CSS_CLASS_NAME(16), GET_FONT_CSS_CLASS_NAME('Arial')],
                });
            }
            if(isNoSpaceAfter){
                charSeq.push({
                    char: SPACE_0WIDTH_HTML_CODE,
                    styles: [GET_FONTSIZE_CSS_CLASS_NAME(16), GET_FONT_CSS_CLASS_NAME('Arial')],
                });
            }

            copyArr.splice(cursorAt, 0, ...charSeq);

            // console.log(`INSERT VAR BY PASTE isNoSpaceBefore:${isNoSpaceBefore}|isNoSpaceAfter:${isNoSpaceAfter}`, cursorAt + variableSequence.length + (isNoSpaceAfter ? 1 : 0), copyArr)
            return {
                newCharSequence: copyArr,
                newCursor: cursorAt + variableSequence.length + 1
            }
        }
    }else{
        // console.log(`INSERT VAR - SELECTION`)
        //its selection
        let deleteCounter = selectionEnd - selectionStart;
        if(deleteCounter > 0){
            let isNoSpaceBefore = !isSpace(prevCharSequence[selectionStart - 1]?.char) && prevCharSequence[selectionStart - 2]?.char !== SPACE_0WIDTH_HTML_CODE;
            let isNoSpaceAfter = !isSpace(prevCharSequence[selectionEnd + 1]?.char) && prevCharSequence[selectionEnd + 1]?.char !== SPACE_0WIDTH_HTML_CODE;
            if(isNoSpaceBefore){
                charSeq.unshift({
                    char: SPACE_0WIDTH_HTML_CODE,
                    styles: [GET_FONTSIZE_CSS_CLASS_NAME(16), GET_FONT_CSS_CLASS_NAME('Arial')],
                });
            }
            if(isNoSpaceAfter){
                charSeq.push({
                    char: SPACE_0WIDTH_HTML_CODE,
                    styles: [GET_FONTSIZE_CSS_CLASS_NAME(16), GET_FONT_CSS_CLASS_NAME('Arial')],
                });
            }

            copyArr.splice(selectionStart, deleteCounter, ...charSeq);
            return{
                newCharSequence: copyArr,
                newCursor: selectionStart + charSeq.length
            }
        }
    }

    return{
        newCharSequence: prevCharSequence,
        newCursor: cursorAt
    }
}

export const isCursorOrSelectionIsOverVariable = (charSequence: TCharSequence[], selectionStart: number, selectionEnd: number): boolean => {
    // //if cursor is in variable

    // console.log(`
    // START
    //     prev: ${charSequence[selectionStart - 1]?.char} | ${charSequence[selectionStart - 1]?.varId} \n
    //     current: ${charSequence[selectionStart]?.char} | ${charSequence[selectionStart]?.varId} \n
    //     next: ${charSequence[selectionStart + 1]?.char} | ${charSequence[selectionStart + 1]?.varId} \n
    // `)
    let prevSelectionStart = charSequence[selectionStart - 1];
    let currSelectionStart = charSequence[selectionStart];

    if(selectionStart === selectionEnd){
        //if its cursor - checking if prev char and current char are variables
        // [|var] -> current char ' '/undefined - var, prev ' '/undefined - not over var
        // [v|ar] -> current char a - var, prev v - var - over var
        // [va|r] -> current char r - var, prev a - var - over var
        // [var|] -> current char ' '/undefined - var, prev r - var - not over var
        return currSelectionStart !== undefined &&
            currSelectionStart.varId !== undefined &&
            prevSelectionStart !== undefined &&
            prevSelectionStart.varId !== undefined
    }else{
        // console.log(`---isCursorOrSelectionIsOverVariable - EXPAND SELECTION`)
        let currSelectionEnd = charSequence[selectionEnd - 1];
        let nextSelectionEnd = charSequence[selectionEnd];
        // console.log(`
        // START
        //     currSelectionStart !== undefined ${currSelectionStart !== undefined} \n
        //     currSelectionStart.varId !== undefined: ${currSelectionStart?.varId !== undefined} \n
        //     prevSelectionStart !== undefined: ${prevSelectionStart !== undefined} \n
        //     prevSelectionStart?.varId !== undefined: ${prevSelectionStart?.varId !== undefined} || \n
        //     currSelectionEnd !== undefined: ${currSelectionEnd !== undefined} \n
        //     currSelectionEnd.varId !== undefined: ${currSelectionEnd?.varId !== undefined} \n
        //     nextSelectionEnd !== undefined: ${nextSelectionEnd !== undefined} \n
        //     nextSelectionEnd?.varId !== undefined: ${nextSelectionEnd?.varId !== undefined}
        // `, currSelectionStart, prevSelectionStart, currSelectionEnd, nextSelectionEnd)
        return (currSelectionStart !== undefined &&
            currSelectionStart?.varId !== undefined &&
            prevSelectionStart !== undefined &&
            prevSelectionStart?.varId !== undefined)
            ||
            (currSelectionEnd !== undefined &&
                currSelectionEnd.varId !== undefined &&
                nextSelectionEnd !== undefined &&
                nextSelectionEnd?.varId !== undefined)
    }
    // console.log(`isCursorOrSelectionIsOverVariable`, charSequence[selectionStart]?.varId !== undefined)
    // return charSequence[selectionStart - 1]?.varId !== undefined || charSequence[selectionEnd + 1]?.varId !== undefined
}

export const getNewSelectionWithVariables = (charSequence: TCharSequence[], selectionStart: number, selectionEnd: number): {start: number; end: number} => {
    //when used sets cursor inside variable or makes partly selection - expanding selection to whole variable
    // console.log(`-------getNewSelectionWithVariables`, charSequence)
    let res = {
        start: selectionStart,
        end: selectionEnd
    }
    //we need to expand selection if selectionStart/selectionEnd is partly covering variable
    if(charSequence[selectionStart]?.varId !== undefined){
        //we have variable where selection started - expanding selection to left to cover whole variable
        for(let i = selectionStart; i >= 0; i--){
            if(charSequence[i]?.varId !== undefined && charSequence[i]?.varId === charSequence[selectionStart]?.varId){
                //left sided char is variable with same id - expanding selection
                // console.log(`MOVING LEFT`, i, charSequence[i]);
            }else{
                // console.log(`STOP MOVING LEFT ON INDEX`, i)
                res.start = i + 1;
                break;
            }
        }
    }

    if(charSequence[selectionEnd]?.varId !== undefined){
        for(let i = selectionEnd; i <= charSequence.length; i++){
            if(charSequence[i]?.varId !== undefined && charSequence[i]?.varId === charSequence[selectionEnd]?.varId){
                //left sided char is variable with same id - expanding selection
                // console.log(`MOVING RIGHT`, i, charSequence[i]);
            }else{
                // console.log(`STOP MOVING RIGHT ON INDEX`, i)
                res.end = i;
                break;
            }
        }
    }

    const resres = {
        start: Math.max(0, res.start),
        end: Math.min(charSequence.length, res.end)
    };

    // console.log('getNewSelectionWithVariables res - ', resres)
    return resres;
}

export function handleVariableUpdate(prevCharSequence: TCharSequence[], varId: string, newValue: string): TCharSequence[]{
    //when variable updates on right side panel - updating it in block
    let debug = false;
    debug && console.log(`-----------handleVariableUpdate`, varId, newValue, prevCharSequence)
    let newCharSequence = [...prevCharSequence];
    for(let i = 0; i < newCharSequence.length; i++){
        // console.log(`handleVariableUpdate for`, i)
        let currentChar = newCharSequence[i];
        let prevChar = newCharSequence[i - 1];
        if(currentChar && currentChar.varId === varId && (!prevChar || prevChar.varId !== currentChar.varId || prevChar.varId === undefined)){
            debug && console.log(`handleVariableUpdate: start variable char found:`, currentChar, i);
            let prevVarLength = -1;
            for(let j = i; j < newCharSequence.length; j++){
                let currChar = newCharSequence[j];
                if((currChar && (currChar.varId === undefined || currChar.varId !== currentChar.varId)) || !currChar){
                    //we have found char that is not same var, stop iterating
                    prevVarLength = j - 1;
                    debug && console.log(`handleVariableUpdate: end variable char found`, currChar, j - 1);
                    break;
                }
            }
            if(prevVarLength === -1) prevVarLength = newCharSequence.length - i; //consider var ends at end
            let firstCharStyles = currentChar.styles;
            debug && console.log(`handleVariableUpdate: inserting variable with styles of first var char`, firstCharStyles);

            debug && console.log(`handleVariableUpdate: splice from ${i} remove ${prevVarLength}`);
            newCharSequence.splice(
                i,
                prevVarLength - i + 1,
                ...newValue.split('').map(e => {return {char: e, styles: firstCharStyles, varId: currentChar.varId}})
            )

            // debug && console.log(`handleVariableUpdate: deleted values`, deleted);

            i = i + newValue.length;

            //find length of prev value
            //replace prev value with next value
            //update i with length of newValue
            //try find next start variable
        }

    }
    // debug && console.log('handleVariableUpdate res', newCharSequence)
    return newCharSequence;
}

export const handleFocusedVariable = (prevCharSequence: TCharSequence[], id: string | null): TCharSequence[] => {
    //when used focuses on variable value input in rightSidePanel - highlighting varirable across while doc
    //just adding focused class
    //if id is null - used blured input, so removing this classname
    let newCharSeq: TCharSequence[] = [];
    prevCharSequence.forEach(e => {
        if(!id){
            newCharSeq.push({...e, styles: e.styles.filter(s => s !== FOCUSED_VARIABLE_CSS_CLASS_NAME)})
        }else{
            if(e.varId === id){
                newCharSeq.push({...e, styles: [...e.styles, FOCUSED_VARIABLE_CSS_CLASS_NAME]})
            }else{
                newCharSeq.push({...e, styles: e.styles.filter(s => s !== FOCUSED_VARIABLE_CSS_CLASS_NAME)})
            }
        }
    })
    return newCharSeq;
}

export const checkIsOverVariable = (charSeq: TCharSequence[], selectionStart: number, selectionEnd: number, cursorAt: number):boolean => {
    //used before expand function
    //checks if cursor is in variable, or selected part of it
    if(cursorAt !== -1){
        //its cursor
        return charSeq[cursorAt]?.varId !== undefined
    }else{
        return charSeq[selectionStart]?.varId !== undefined || charSeq[selectionEnd]?.varId !== undefined;
    }
}

export const getLinkTitleByCursor = (charSeq: TCharSequence[], cursorAtIndex: number, href: string): { linkTitle: string, from: number, to: number } => {
    //used to find link bands and title to open updateLinkPopper
    // console.log(`--getLinkTitleByCursor`, cursorAtIndex, charSeq[cursorAtIndex], href, charSeq);
    let linkStart = cursorAtIndex;
    let linkEnd = cursorAtIndex;

    for(let i = linkStart - 1; i >= 0; i--){
        let currChar = charSeq[i];
        // console.log(`--getLinkTitleByCursor, moving left`, currChar, i)
        if(currChar?.href !== undefined && currChar?.href === href){
            linkStart--;
        }else{
            break;
        }
    }

    for(let i = linkEnd; i < charSeq.length; i++){
        let currChar = charSeq[i];
        // console.log(`--getLinkTitleByCursor, moving right`, currChar, i)
        if(currChar?.href !== undefined && currChar?.href === href){
            linkEnd++;
        }else{
            break;
        }
    }

    // console.log(`LINK ${linkStart} - ${linkEnd}`, charSeq.slice(linkStart, linkEnd))
    return {
        linkTitle: charSeq.slice(linkStart, linkEnd).map(e => e.char).join(''),
        from: linkStart,
        to: linkEnd
    }
}

export const updateLink = (charSeq: TCharSequence[], title: string, href: string, from: number, to: number): {newCharSeq: TCharSequence[], newCursor: number} => {
    //used after user clicks apply in updateLinkPopper
    //replacing old link with new one
    let copyArr = [...charSeq];
    let firstLinkChar = charSeq[from];
    let titleSeq = title.split('').map((e): TCharSequence => {
        return {
            char: e,
            styles: firstLinkChar?.href !== undefined ? firstLinkChar?.styles : [...firstLinkChar?.styles, LINK_CSS_CLASS_NAME],
            href
        }
    });
    copyArr.splice(from, to - from, ...titleSeq);
    // console.log(`----updateLink`, deleted)
    return {
        newCharSeq: copyArr,
        newCursor: from + title.length
    }
}

export const removeLink = (charSeq: TCharSequence[], from: number, to : number): TCharSequence[] => {
    //removing
    return charSeq.map((e, id) => {
        if(id >= from && id <= to){
            return {
                char: e.char,
                styles: e.styles.filter(e => e !== LINK_CSS_CLASS_NAME)
            }
        }else{
            return e;
        }
    })
}

export const getSelectedElement = (): HTMLElement | null => {
    //used to find link span element to open popper (demands)
    const selection = window.getSelection();
    if(selection && selection.rangeCount > 0) {
        let startNode = selection.anchorNode;
        return startNode?.parentElement ?? null;
    }
    return null;
}

export const isLinkToolBarDisabled = (charSeq: TCharSequence[], selectionStart: number, selectionEnd: number): boolean => {
    //link button disabled when - its cursor, and if its selection and contains links, or contains variables
    // console.log(`---isSelectionContainsLink`, charSeq, selectionStart, selectionEnd)
    if(selectionStart === selectionEnd) return true;
    for(let i = selectionStart; i < selectionEnd; i++){
        let char = charSeq[i];
        if(char?.href !== undefined || char?.varId !== undefined){ //
            // console.log(`---isLinkToolBarDisabled char has href NOT undefined`, char)
            return true;
        }
    }
    return false;
}

export const isCursorOrSelectionInLink = (charSeq: TCharSequence[], cursorAt: number, selectionStart: number, selectionEnd: number, acceptWholeLinkSelection?: boolean): string | null => {
    //used in handlePaste to find out if selected whole link or part of it to replace copied variable as text or just copied text
    //used in handleOnInput to find out if inserting(or replacing) space or char inside link
    if(cursorAt !== -1){
        let origin = charSeq[cursorAt];
        let prev = charSeq[cursorAt - 1];
        if(origin !== null && origin?.href !== undefined && prev?.href !== undefined && prev?.href === origin?.href){
            return origin.href ?? null;
        }else{
            return null;
        }
    }else{
        let beforeStart = charSeq[selectionStart - 1];
        let start = charSeq[selectionStart];
        let end = charSeq[selectionEnd - 1];
        let afterEnd = charSeq[selectionEnd];
        // console.log(`beforeStart`, beforeStart)
        // console.log(`start`, start)
        // console.log(`end`, end)
        // console.log(`afterEnd`, afterEnd)

        if((beforeStart?.href === undefined || beforeStart?.href !== start?.href) &&
            (afterEnd?.href === undefined || afterEnd?.href !== end?.href)){
            // console.log(`SELECTED WHOLE LINK`)
            //selected whole link - replace
            return acceptWholeLinkSelection ? (start?.href ?? null) : null;
        }
        if(start?.href !== undefined && end?.href !== undefined && start?.href === end?.href){
            return start.href ?? null;
        }else{
            return null;
        }
    }
}

export const getCharSequenceByHTML = (html: string): TCharSequence[] => {
    // console.log(`----getCharSequenceByHTML`, html)
    let spaceChar = {char: SPACE_0WIDTH_HTML_CODE, styles: [GET_FONT_CSS_CLASS_NAME('Arial'), GET_FONTSIZE_CSS_CLASS_NAME(16), GET_FONT_COLOR_CSS_CLASS_NAME('#000000')]};
    if(!html || !html.length) return [spaceChar];

    let res: TCharSequence[] = [];
    const root = document.createElement('div');
    root.innerHTML = html;

    for(let i = 0; i <= root.children.length; i++){
        let child = root.children.item(i);
        if(child && child.nodeName === 'SPAN'){
            const classNames = Array.from(child.classList.values());
            const varId = child.attributes.getNamedItem(VARIABLE_ID_DATA_ATTRIBUTE_NAME);
            const href = child.attributes.getNamedItem(LINK_DATA_ATTRIBUTE_NAME);
            res = res.concat((child.textContent ?? '').split('').map((e): TCharSequence => {
                return {
                    char: e,
                    styles: classNames,
                    varId: varId?.value ?? undefined,
                    href: href?.value ?? undefined
                }
            }))
        }

        if(child && child.nodeName === 'BR'){
            const classNames = Array.from(child.classList.values());
            res.push({
                char: NEWLINE_TO_SET,
                styles: classNames
            })
        }
    }

    // console.log(`res`, res, res.length - 1, res[res.length - 1])
    if(res[res.length - 1]?.varId !== undefined){
        res.push(spaceChar);
    }
    return res;
}

export const getHtmlForCharsAfterCursorUntilNewline = (charSeq: TCharSequence[], cursorAt: number): {nextLineHtml: string, newCharSeq: TCharSequence[], newCursor: number} => {
    let counter = cursorAt;
    // while(counter <= charSeq.length && charSeq[counter]?.char !== NEWLINE_COMMAND){
    //     counter ++;
    // }
    //removed going until new line because in list where is item with enters - will disappear
    while(counter <= charSeq.length){
        counter ++;
    }
    let partCharSeq = charSeq.slice(cursorAt, counter);
    // console.log(partCharSeq)
    // return ''
    return {
        nextLineHtml: renderCharSequence(partCharSeq, false),
        newCharSeq: charSeq.length === 1 ? charSeq : charSeq.slice(0, cursorAt),
        newCursor: cursorAt 
    }
}

export const getLineRangeByCursor = (charSequence: TCharSequence[], cursorAt: number): {lineStart: number, lineEnd: number} => {
    let debug = false;
    let lineStart = cursorAt;
    let lineEnd = cursorAt;

    if(lineEnd === charSequence.length){
        lineEnd--;
    }

    if(lineStart === charSequence.length){
        lineStart--;
    }

    for(let i = lineStart; i >= 0; i--){
        let currChar = charSequence[i];
        debug && console.log(`going left`, currChar, i);
        if(i === 0){
            debug && console.log(`going left stop - 0`, currChar, i);
            lineStart = 0;
        }
        if((!currChar || isNewLine(currChar?.char)) && i !== lineStart){
            debug && console.log(`going left stop, newline found, new lineStart`, currChar, i  + 1);
            lineStart = i + 1;
            break;
        }
    }

    for(let i = lineEnd; i < charSequence.length; i++){
        let currChar = charSequence[i];
        debug && console.log(`going right`, currChar, i);
        if(i === charSequence.length - 1){
            debug && console.log(`going right stop - end, lineEnd = `, currChar, charSequence.length - 1);
            lineEnd = charSequence.length - 1;
        }
        if(!currChar || isNewLine(currChar?.char)){
            debug && console.log(`going right stop, newline found`, currChar, i);
            lineEnd = i;
            break;
        }
    }

    return {
        lineStart,
        lineEnd
    }
}

export const convertToList = (charSequence: TCharSequence[], cursorAt: number, selectionStart: number, selectionEnd: number): TBlockWithHtml[] => {
    // console.log(`---convertToList`, cursorAt, selectionStart, selectionEnd)
    let res: TBlockWithHtml[] = [];
    if(cursorAt !== -1){
        //cursor
        //if cursor - find the line where cursor stands - return before cursor part as text
        //return after cursor and until newLine  as list
        //if there part after newLine - return it as list
        // let cursorAtLine = charSequence.
        const {lineStart, lineEnd} = getLineRangeByCursor(charSequence, cursorAt);
        // console.log(`Line found`, cursorAt, lineStart, lineEnd)
        if(lineStart > 0){ //if there are chars before line
            let beforeCursorLine = charSequence.slice(0, lineStart);
            res.push({html: renderCharSequence(beforeCursorLine, false), type: 'block'});
        }

        let cursorInLine = charSequence.slice(lineStart, lineEnd + 1);
        res.push({html: renderCharSequence(cursorInLine, false, true), type: 'list'});

        if(lineEnd < charSequence.length - 1){ //if there are chars after line
            let afterCursorLine = charSequence.slice(lineEnd + 1, charSequence.length);
            // console.log(`afterCursorLine`, afterCursorLine)
            res.push({html: renderCharSequence(afterCursorLine, false), type: 'block'});
        }
    }else{
        //selection
        //find lines where selection was made - make all off them list
        //if before selected lines there more chars - make them block
        //same in after selected lines
        const isMultiLineSelection = charSequence.slice(selectionStart, selectionEnd).some(e => isNewLine(e.char));
        if(!isMultiLineSelection){
            //if selection was made within one line - acting like its cursor
            return convertToList(charSequence, selectionStart, -1, -1);
        }else{
            // console.log(`MULTILINE`);
            //selection was made withing couple newLines
            //find firstLine by selectionStart
            //everything before firstLine is block
            //add firstLine to res
            //find lastLine by selectionEnd

            //12|3 -> firstLine by selectionStart - 123 - everything before this is block
            //abc
            //456
            //7|89 -> lastLine by selectionEnd - 789 - everything after this is block

            //take everything between firstLineEnd and lastLineStart -> abc[\n]456
            //split by \n
            //add as list type

            //add lastLine as list
            const firstLine = getLineRangeByCursor(charSequence, selectionStart);
            //inserting everything before firstLine
            if(firstLine.lineStart > 0){
                res.push({html: renderCharSequence(charSequence.slice(0, firstLine.lineStart), false), type: 'block'});
            }
            // console.log(`res: before firstLine block inserted`, res)
            //inserting firstLine as list
            res.push({html: renderCharSequence(charSequence.slice(firstLine.lineStart, firstLine.lineEnd), false), type: 'list'});
            // console.log(`res: firstLine inserted`, res)
            const lastLine = getLineRangeByCursor(charSequence, selectionEnd);
            if(firstLine.lineEnd !== lastLine.lineStart - 1){
                // console.log(`there is more lines between firstLine and lastLine`, firstLine, lastLine);
                //if there is more lines between line where selection started and line where selection ended
                //split by \n and add every elem as list
                //last index where newLine was found (starting from firstLine.lineEnd)
                let prevBreakPointIndex = firstLine.lineEnd;
                //next newLine - searching for this only between lines where selection started and selection ended
                let nextNewLineIndex = charSequence.findIndex((v, id) => (id > prevBreakPointIndex + 1) && (id < lastLine.lineStart) && isNewLine(v.char));
                // console.log(`prevBreakPointIndex: ${prevBreakPointIndex} | nextNewLineIndex: ${nextNewLineIndex}`, charSequence)
                while(nextNewLineIndex !== -1){
                    // console.log(`while`)
                    //while there is next
                    //inserting part of charSequence before newLine
                    res.push({html: renderCharSequence(charSequence.slice(prevBreakPointIndex, nextNewLineIndex), false, true), type: 'list'});
                    //updating prevBreakPointIndex - now its nextNewLineIndex
                    prevBreakPointIndex = nextNewLineIndex;
                    //looking for next newLine
                    //eslint-disable-next-line
                    nextNewLineIndex = charSequence.findIndex((v, id) => (id > prevBreakPointIndex + 1) && (id < lastLine.lineStart) && isNewLine(v.char))
                }
            }
            //inserting last line as list
            res.push({html: renderCharSequence(charSequence.slice(lastLine.lineStart, lastLine.lineEnd + 1), false, true), type: 'list'});
            //insert everything after lastline as block
            if(lastLine.lineEnd + 1 < charSequence.length){
                res.push({html: renderCharSequence(charSequence.slice(lastLine.lineEnd + 1, charSequence.length), false), type: 'block'});
            }
        }
    }
    // console.log(`res`, res);
    return res;
}

export const concatListItemsIntoBlock = (htmls: string[]): string => {
    // console.log(`htmls`, htmls)
    const skippableItems = ['<span class="FontArial fontSize16 Color000000">​</span>', '<span class=\\"FontArial fontSize16 Color000000\\">​</span>'];
    let res = '';
    htmls
        .filter(e => !skippableItems.includes(e))
        .forEach((e, id, arr) => {
            let currCharSeq = getCharSequenceByHTML(e);
            if(arr.length === id + 1){
                //last item
                const charSeq = renderCharSequence(currCharSeq, false)
                // console.log(`e LAST ITEM`, e, charSeq);
                res = res.concat(charSeq);
            }else{
                let lastCharStyles = currCharSeq[currCharSeq.length - 1]?.styles;
                const {newSequence} = insertChar(currCharSeq, currCharSeq.length, NEWLINE_TO_SET, lastCharStyles ?? []);
                const charSeq = renderCharSequence(newSequence, false)
                // console.log(`e NEWLINE`, e, charSeq);
                res = res.concat(charSeq);
            }
    })
    return res;
}

export const handleVariableRemove = (prevCharSeq: TCharSequence[], varId: string): TCharSequence[] => {
    let copyArr = [...prevCharSeq];
    return copyArr.map(e => e.varId === varId ? {char: e.char, styles: e.styles.filter(e => e !== VARIABLE_CSS_CLASS_NAME && e !== FOCUSED_VARIABLE_CSS_CLASS_NAME)} : e)
}

export const getVirtualElementFromSelection = () => {
    const selection = window.getSelection();
    const rect = selection?.getRangeAt(0).getBoundingClientRect();
    const element = {getBoundingClientRect: () => rect} as HTMLElement;

    return element;
}

export const parsePastedHtml = (html: string, prevSeq: TCharSequence[], selectionStart: number, selectionEnd: number, cursorAt: number, currentVariables: NewDocDataVariableModel[]): { charSeq: TCharSequence[], cursorAt: number } => {
    const normalizedHtml = html.substring(`<div data-editor="${EDITOR_COPY_TEXT_ID}">data-editor</div>`.length, html.length - '</div>'.length);
    const charSequenceByHTML = getCharSequenceByHTML(normalizedHtml);
    const copyArr = [...prevSeq];

    if(cursorAt !== -1){
        //cursor
        copyArr.splice(cursorAt, 0, ...charSequenceByHTML);
        const {charSeq, cursorOffset} = clearCharSeqNotCreatedVariables(copyArr, currentVariables);
        // console.log(`
        //     prevSeq: ${prevSeq.length} \n
        //     htmlSeq: ${charSequenceByHTML.length} \n
        //     totalAfterConcat: ${copyArr.length} \n
        //     clearedSeq: ${clearedSeq.length} \n
        //     initialCursorAt: ${cursorAt} \n
        //     newCursorAt: ${cursorAt + clearedSeq.length} (${cursorAt} + ${clearedSeq.length})
        //     new seq length: ${clearedSeq.length}, cursor at: ${cursorAt + clearedSeq.length}
        // `)
        return {charSeq: charSeq, cursorAt: cursorAt + charSequenceByHTML.length + cursorOffset}
    }else{
        copyArr.splice(selectionStart, selectionEnd - selectionStart, ...charSequenceByHTML);
        const {charSeq, cursorOffset} = clearCharSeqNotCreatedVariables(copyArr, currentVariables);
        // console.log(`new seq length: ${clearedSeq.length}, cursor at: ${cursorAt + charSequenceByHTML.length}`)
        return {
            charSeq: charSeq,
            cursorAt: selectionStart + charSequenceByHTML.length + cursorOffset
        };
    }
}

export const clearCharSeqNotCreatedVariables = (charSeq: TCharSequence[], currentVariables: NewDocDataVariableModel[]): { charSeq: TCharSequence[], cursorOffset: number } => {
    const styles = [GET_FONT_COLOR_CSS_CLASS_NAME('#000000'), GET_FONTSIZE_CSS_CLASS_NAME(16), GET_FONT_CSS_CLASS_NAME('Arial')];
    const openBracketChar: TCharSequence = {char: '{', styles};
    const closeBracketChar: TCharSequence = {char: '}', styles};
    const result: TCharSequence[] = [];
    let addedBracketCounter = 0;
    charSeq.forEach((e, id, arr) => {
        const currentChar = e;
        const prevChar = arr[id - 1];
        const nextChar = arr[id + 1];
        if(currentChar.varId !== undefined && currentChar.varId !== null && !currentVariables.some(s => s.id === currentChar.varId)){
            // console.log(`Found char with ${currentChar.varId} | ${currentVariables.map(e => e.id).toString()}`)
            //variable is not from this document
            if(prevChar && (prevChar.varId === undefined || prevChar.varId === null || prevChar.varId !== currentChar.varId)){
                //its first char of variable that does not exist
                //inserting "{" before variable
                result.push(openBracketChar);
                result.push({char: e.char, styles: e.styles.filter(s => s !== VARIABLE_CSS_CLASS_NAME)});
                addedBracketCounter++;
                return;
            }

            if(nextChar && (nextChar.varId === undefined || nextChar.varId === null || nextChar.varId !== currentChar.varId)){
                //its last char of variable that does not exist
                //inserting "}" after variable
                result.push({char: e.char, styles: e.styles.filter(s => s !== VARIABLE_CSS_CLASS_NAME)});
                result.push(closeBracketChar);
                addedBracketCounter++;
                return;
            }

            result.push({char: e.char, styles: e.styles.filter(s => s !== VARIABLE_CSS_CLASS_NAME)});
        }else{
            result.push(e);
        }
    })

    return {
        charSeq: result,
        cursorOffset: addedBracketCounter
    };
}

export const renderTextCharSeq = (seq: TCharSequence[], selectionStart: number, selectionEnd: number): string => {
    if(selectionStart === selectionEnd) return '';
    let res = '';
    seq.slice(selectionStart, selectionEnd).forEach(e => {
        switch (e.char){
            case NEWLINE_COMMAND: res = res.concat(NEWLINE_TO_SET); return;
            case NEWLINE_HTML_CODE: res = res.concat(NEWLINE_TO_SET); return;
            case SPACE_HTML_CODE: res = res.concat(SPACE_TO_SET); return;
            case SPACE_0WIDTH_HTML_CODE: res = res.concat(SPACE_TO_SET); return;
            default: res = res.concat(e.char); return;
        }
    })

    return res;
}

export const isCopiedFromEditor = (html: string): boolean => {
    // console.log(`isCopiedFromEditor`, html.startsWith(`<meta charset='utf-8'><div data-editor="${EDITOR_COPY_TEXT_ID}">`), html);
    return html.startsWith(`<meta charset='utf-8'><div data-editor="${EDITOR_COPY_TEXT_ID}">`);
}