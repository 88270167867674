import React, {FC, useEffect, useRef, useState} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Box, Grid, Skeleton, Typography} from "@mui/material";
import {
    FlexColumn,
    FlexRow
} from "../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import {CoverImageView} from "../trainingCoverComponent/CoverImageView";
import {RemoveRedEye, StickyNote2} from "@mui/icons-material";
import colors from "../../../../../newShared/theme/colors";
import {MainTrainingShortTemplateModel} from "../../../../../newShared/GQLTypes";
import {LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";

type TemplateCardProps = {
    template: MainTrainingShortTemplateModel | null,
    isLoadingPreview?: boolean,
    isLoadingUse?: boolean,
    handlePreview: () => void,
    handleUseTemplate: () => void,
}
export const TemplateCard: FC<TemplateCardProps> = (props) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.pathTemplates'});
    const {template, isLoadingPreview, isLoadingUse, handlePreview, handleUseTemplate} = props;

    const titleRef = useRef<HTMLSpanElement>(null);
    const descriptionRef = useRef<HTMLSpanElement>(null);
    const [descriptionLines, setDescriptionLines] = useState(3);
    const [cardMinHeight, setCardMinHeight] = useState("280px");

    useEffect(() => {
        const titleElement = titleRef.current;
        const descriptionElement = descriptionRef.current;
        if (!titleElement || !descriptionElement) return;

        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                const { height } = entry.contentRect;
                const titleLineHeight = parseInt(window.getComputedStyle(entry.target).lineHeight);
                const titleLines = Math.round(height / titleLineHeight);

                const descriptionLineHeight = parseInt(window.getComputedStyle(descriptionElement).lineHeight);

                const descriptionLines = template?.description?.length ? Math.max(4 - titleLines, 1) : 0;
                setCardMinHeight(`${246 + titleLines * titleLineHeight + descriptionLines * descriptionLineHeight}px`)

                setDescriptionLines(descriptionLines);
            }
        });

        resizeObserver.observe(titleElement);

        return () => {
            resizeObserver.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid item xs={4}
              sx={{
                  minHeight: cardMinHeight,

                  '&:hover': {
                      '.card': {
                          zIndex: 2,
                          height: 'max-content',

                          maxHeight: '1000%',
                          '.description': {WebkitLineClamp: 'unset', whiteSpace: 'unset'},
                      },
                  },
              }}
        >
            <FlexRow sx={{width: '100%', height: '100%', position: 'relative'}}>
                <FlexColumn className={'card'} sx={{
                    backgroundColor: colors.backgrounds.white,
                    position: 'absolute', width: '100%', height: '100%', p: '20px',
                    gap: '16px', overflow: 'hidden',
                    borderRadius: '6px', border: `1px solid ${colors.stroke.grey}`,
                    transition: 'max-height 0.5s ease-in-out',

                    maxHeight: '100%',
                    minHeight: '100%',
                }}>
                    <FlexRow sx={{gap: '16px', justifyContent: 'space-between'}}>
                        {template ? template.coverImage && <CoverImageView cover={template.coverImage} size={"template"}/> : <Skeleton width={'180px'} height={'135px'} />}
                        <FlexRow sx={{gap: '16px', flexWrap: 'wrap', justifyContent: 'flex-end', alignItems: 'flex-start', height: 'fit-content'}}>
                            <LoadingButton size={'small'} startIcon={<RemoveRedEye/>} variant={'outlined'} onClick={handlePreview} loading={isLoadingPreview} disabled={!template}>{t('Preview')}</LoadingButton>
                            <LoadingButton size={'small'} startIcon={<StickyNote2/>}  variant={'contained'} onClick={handleUseTemplate} loading={isLoadingUse} disabled={!template} sx={{whiteSpace: 'nowrap'}}>{t('Use template')}</LoadingButton>
                        </FlexRow>
                    </FlexRow>

                    {template ?
                        <Typography variant={'*bodyText2'} color={colors.text.grey_dark} noWrap sx={{flexShrink: 0}}>{`${t('Category')}: `}<span color={colors.primary.blue_dark}>{template.category}</span></Typography>
                        :
                        <Skeleton width={'200px'} />
                    }

                    {template ?
                        <Typography ref={titleRef} variant={'*bodyText1_semibold'} color={colors.text.dark} className={"description"} sx={{
                            flexShrink: 0,
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                            textOverflow: 'ellipsis',
                        }}>
                            {template.name}
                        </Typography>
                        :
                        <Skeleton width={'200px'} />
                    }

                    {template ?
                        <Typography ref={descriptionRef} variant={'*bodyText2'} color={colors.text.grey_dark} className={"description"} sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitLineClamp: descriptionLines,
                            WebkitBoxOrient: 'vertical',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'break-spaces',
                        }}>
                            {template.description}
                        </Typography>

                        :
                        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} width={'100%'}>
                            <Skeleton width={'100%'} />
                            <Skeleton width={'100%'} />
                            <Skeleton width={'100%'} />
                        </Box>
                    }
                </FlexColumn>
            </FlexRow>
        </Grid>
    )
};