import {TLanguage} from "./types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {SUPPORTED_LANGUAGES} from "../../constants";
import {AppState} from "../../redux/rootReducer";

type I18InitalState = {
    selectedLanguage: TLanguage;
}

const initialState: I18InitalState = {
    selectedLanguage: SUPPORTED_LANGUAGES[0],
}

export const I18NextSlice = createSlice({
    name: 'I18Next',
    initialState,
    reducers: {
        selectLanguage: (slice, {payload}: {payload: TLanguage}) => {slice.selectedLanguage = payload},
    }
});

export const I18NextReducer = I18NextSlice.reducer;

export const {selectLanguage} = I18NextSlice.actions;

const selectSelf = (state: AppState):I18InitalState => state.I18Next as I18InitalState;

export const selectedLanguage = createSelector(selectSelf, state => state.selectedLanguage);
