import React from "react";
import {useDutiesList} from "../../../hooks/useDutiesList";
import {Box, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {AppDutiesRow} from "../../../components/appDutiesRow";
import {AddDutyDialog} from "../../../components/dialogs/addDuty";
import {EditDutyDialog} from "../../../components/dialogs/editDuty";
import {TableRowSkeleton} from "../../../../../../newShared/components/tableRowSkeleton";
import {DeleteDutyDialog} from "../../../components/dialogs/deleteDuty";
import {EmptyArrayImage} from "../../../../../../newShared/components/EmptyArrayImage";
import {PreviewRoleDialog} from "../../../components/dialogs/previewRoleDialog";
import AddIcon from "@mui/icons-material/Add";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";
import {GenericFiltersArray} from "../../../../../../newShared/components/genericFilter";

export const SegregationOfDuties = () => {

    const {isMobile} = useMedia();
    const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        appDuties,
        isLoadingList,
        isNoDutyFound,
        handleOpenCreateDuty,
        handleOpenEditDuty,
        handleOpenDeleteDuty,
        onRoleClick,

        // filter,
        // value, handleChange, handleKeyDown, setFilter,

        pagination: {
            bottomLoader,
            onScroll,
            scrollRef
        },
        filters,
    } = useDutiesList();



    return(
        <Box width={'100%'} height={'100%'} display={'flex'} flexDirection={'column'}>
            <PageTitleAndActions
                title={t('Duties list')}
                actions={[
                    {
                        title: t('Create duty'),
                        onClick: handleOpenCreateDuty,
                        variant: 'text',
                        icon: <AddIcon />
                    }
                ]}
            />

            {/*<Breadcrumbs/>*/}

            {/*<Box display={'flex'} alignItems={'center'} marginTop={'11px'}*/}
            {/*     justifyContent={'flex-start'} >*/}

            {/*    <Typography variant={isMobile ?  'h3' : "h1"}  sx={{margin: !revDir ? '0 32px 0 0' : '0 0 0 32px', color: colors.backgrounds.dark}}>*/}
            {/*        {t('Duties list')}*/}
            {/*    </Typography>*/}
            {/*    <Button size={isMobile ? 'small' : 'medium'}*/}
            {/*            disabled={isLoadingList}*/}
            {/*            sx={{marginRight: isMobile ? '10px' : 'undefined'}}*/}
            {/*            onClick={handleOpenCreateDuty}>{t('Create duty')}</Button>*/}
            {/*</Box>*/}

            <GenericFiltersArray
                configs={filters.configs}
                fetchResultWithSelectedFilters={filters.fetchResultWithSelectedFilters}
                isAddFilters={false}
            />

            {!isNoDutyFound &&
                <>
                    {/*<Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'} alignItems={'center'} marginTop={'20px'}>*/}
                    {/*    <SearchField*/}
                    {/*        onClear={() => {setFilter('input'); handleChange({target: {value: ''}})}}*/}
                    {/*        size={'small'}*/}
                    {/*        sx={{ml: revDir && !isMobile ? '10px' : undefined, mr: !isMobile && !revDir ? '13px' : undefined, width: isMobile ? '100%' : '320px'}}*/}
                    {/*        variant={'outlined'}*/}
                    {/*        placeholder={t('Search')}*/}
                    {/*        onChange={handleChange}*/}
                    {/*        value={value}*/}
                    {/*        disabled={isLoadingList}*/}
                    {/*        onKeyDown={handleKeyDown}*/}
                    {/*    />*/}

                    {/*    <TextField label={t('Sort by category')} select size={'small'}*/}
                    {/*               value={filter?.category?.[0] || 'Any'}*/}
                    {/*               sx={{width: isMobile ? '100%' : '320px', mt: isMobile ? '16px' : undefined, '& label': {top: '0 !important'}}}*/}
                    {/*               disabled={isLoadingList}*/}
                    {/*    >*/}
                    {/*        <MenuItem key={'Any'} value={'Any'} onClick={() => {setFilter('category')}}>{t('Any')}</MenuItem>*/}
                    {/*        {categories.map((e) => (<MenuItem key={e} value={e} onClick={() => {setFilter('category', e)}}>{e}</MenuItem>))}*/}
                    {/*    </TextField>*/}
                    {/*</Box>*/}

                    <TableContainer onScroll={onScroll} ref={scrollRef} sx={{mt: '22px', minHeight: '60px'}} className={'withoutBorder'}>
                        <Table>
                            <TableBody>
                                {appDuties.length === 0 && !isLoadingList && !filters.isFilterEmpty ?
                                    <TableRow><TableCell>{t('Nothing found')}</TableCell></TableRow>
                                    :
                                    appDuties.map(e => (e && <AppDutiesRow key={e.id} name={e.name} duties={e.duties} onEdit={handleOpenEditDuty} onDelete={handleOpenDeleteDuty} onRoleClick={onRoleClick}/>))
                                }

                                {(bottomLoader || isLoadingList) && <TableRowSkeleton columns={isMobile ? 1 : 2} />}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            }

            <EmptyArrayImage
                type={'accessManagement'}
                isShowing={isNoDutyFound}
                text={('No duties found')}
                secondaryText={('You have not added any duty yet. Create new one.')}
                onClick={handleOpenCreateDuty}
            />

            <AddDutyDialog />
            <EditDutyDialog />
            <DeleteDutyDialog />
            <PreviewRoleDialog />
        </Box>
    )
}