import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideEditRegistry, loadings, models, selectedRegistry} from "../../../store/slice";
import {useEffect, useState} from "react";
import {TRiskModel, TRiskRegistry} from "../../../types";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {updateRiskRegistryAction} from "../../../store/actions";

export const useEditRegistry = () => {
    const dispatch = useDispatch();
    const {editRegistry} = useSelector(dialogs);
    const {registryEdit, modelsList} = useSelector(loadings);
    const reg = useSelector(selectedRegistry);
    const _models = useSelector(models);

    const handleClose = () => {
        dispatch(hideEditRegistry());
    }

    const [selectedModel, setSelectedModel] = useState<TRiskModel | null>(null);
    const {form, setForm, handleChange} = useForm<TRiskRegistry>({
        name: '',
        context: '',
        model: '',
        id: '',
        totalRisks: 0,
        lastUpdate: {
            date: null,
            publicId: ''
        }
    });

    useEffect(() => {
        if(editRegistry && reg){
            setForm(reg);
            setSelectedModel(_models.find(e => reg.model === e.id) ?? null);
        }
        //eslint-disable-next-line
    }, [editRegistry]);

    const isOk = () => {
        return (form.name.trim().length > 0 && form.context.trim().length > 0 && form.model.trim().length > 0) &&
            JSON.stringify(reg) !== JSON.stringify(form);
    }

    const handleSubmit = () => {
        if(isOk() && reg){
            dispatch(updateRiskRegistryAction({
                data: {
                    workspaceId: '',
                    organizationId: '',
                    registry: {...reg, ...form}
                },
                isModelReplaced: form.model !== reg.model
            }))
        }
    }

    const handleSetModel = (model: TRiskModel | null) => {
        setSelectedModel(model);
        setForm(prev => {return {...prev, model: model?.id ?? ''}});
    }

    return{
        isOpen: editRegistry,
        isLoading: registryEdit,
        isLoadingModels: modelsList,
        handleClose,
        isOk,
        handleSubmit,
        handleChange,
        selectedModel,
        models: _models,
        form,
        setSelectedModel,
        handleSetModel
    }
}