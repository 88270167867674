import {
    ColumnConfig,
    ColumnConfigWithValueGetter,
    CommonFilterType,
    GenericPageBeautyPropsType,
    genericTableSearchType
} from "../types";
import React, {UIEventHandler, useEffect, useRef, useState} from "react";
import {useHistory} from "react-router";
import {
    DEBUG,
    DEFAULT_PAGING,
    DEFAULT_SEARCH_FILTER_KEY,
    NO_RESULTS_EMPTY_SECONDARY_TEXT,
    NO_RESULTS_EMPTY_TEXT,
    NO_RESULTS_QUERY_SECONDARY_TEXT,
    NO_RESULTS_QUERY_TEXT,
    SKELETON_ROWS_HEIGHT
} from "../constants";
import {
    getCurrentPage,
    getElement,
    getElementHeight,
    getFilterWithSearch,
    getInitialViewSettings,
    getIsTableLS,
    isElementVisible,
    replaceGenericTableSearch,
    saveColumnConfigLS,
    saveIsTableLS,
    urlDecode
} from "../helpers";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../newDomain/authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {useGenericFiltersStorage} from "../../genericFilter/hooks/useGenericFiltersStorage";

export function useGenericPage<T extends Object, P extends Object | any>(props: GenericPageBeautyPropsType<T, P>){
    //INIT
    const {
        id,
        columnsConfig: {disableShowCards},
        paging: {pageInfo, fetchPaging, minLoadedPage, maxLoadedPage, setMinMaxLoadedPage, isLoading, clearRows, additionalSearchProps, onPageDestroy, disableCleanUp = false, forceLoadPageZero = false},
        rowsConfig: {rows, getRowUniqueId, onRowClick = () => null, columnsConfigs, fetchBottomEventIfReachedTotal, defaultPagingCount = DEFAULT_PAGING},
        filtersConfig: {
            dontSendSearchIfEmpty = false,
            searchCustomFilterKeyName = DEFAULT_SEARCH_FILTER_KEY, //search by default
            searchAsArray = false,
            dontIncludeSearchInFilter = false,
            genericFilterProps,
        },
        disableEmptyFilterResultTextRow = false,
        emptyArrayImageProps,
    } = props;

    //HOOKS
    const {currData: {currentUser, selectedWorkspace}} = UseManageWorkspacesAndOrganizations();
    const history = useHistory();
    const {currentFiltersForFetch, currentSearchForFetch, clearFilters, originalSelectedValues, injectOriginalSelectedValues} = useGenericFiltersStorage();


    //STATES
    //used to store last selected item - click on row will be intercepted
    const [lastSelectedId, setLastSelectedId] = useState<string | null>(null);
    //cards/table
    const [rowsView, setRowsView] = useState<boolean>(getIsTableLS(id, currentUser?.publicId, selectedWorkspace?.id, disableShowCards));
    //loading direction - used to define if make scroll if loading top page to keep scroll at place
    const [isLoadingDown, setIsLoadingDown] = useState<boolean>(true);
    //will be set to true by useEffect[] - used to not handle filter/search updates before first fetch
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    //what page does user views now - used to keep page in search and to scroll to this page when moving to cards/rows
    const [currentViewPage, setViewPage] = useState<number>(0);

    //view settings dialog
    const [viewSettingsDialog, setViewSettingsDialog] = useState<boolean>(false);
    const [viewSettings, setViewSettings] = useState<ColumnConfig[]>(getInitialViewSettings(id, columnsConfigs, currentUser?.publicId, selectedWorkspace?.id));

    //ref of scrollable container
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        DEBUG && console.log(`${id} -- INIT USE EFFECT`, new Date().toISOString())
        try{
            const searchConfig:genericTableSearchType<P> = JSON.parse(urlDecode(history.location.search.substring(1))) as genericTableSearchType<P>;
            if(searchConfig && !Number.isNaN(searchConfig.page)){
                DEBUG && console.log(`${id} -- init fetch by search`, history.location.search.substring(1));
                //get full object filter value and check for existing filter key in configs
                if(genericFilterProps && (Object.keys(searchConfig.filters).length > 0 || searchConfig.search.trim().length > 0)){
                    const filtersForSearch = injectOriginalSelectedValues(genericFilterProps.configs, searchConfig.filters, searchConfig.search);
                    handleFetch(forceLoadPageZero ? 0 : searchConfig.page, searchConfig.search, filtersForSearch, true, false, searchConfig.lastId);
                }else{
                    handleFetch(forceLoadPageZero ? 0 : searchConfig.page, searchConfig.search, {}, true, false, searchConfig.lastId);
                }
                //have to inject full object filters into filters store
                //have to inject selected filters (if filtering by, show such filter)
                setLastSelectedId(searchConfig.lastId);
                setViewPage(forceLoadPageZero ? 0 : searchConfig.page);
            }else{
                handleFetch(0, '', {}, false, false, searchConfig.lastId);
            }
        }catch (ex){
            //remove bad search
            history.replace({pathname: history.location.pathname, search: ''});
            handleFetch(0, '', {}, false, false);
            console.log(`${id} -- init fetch ex`, ex, urlDecode(history.location.search.substring(1)))
        }
        setIsInitialized(true);

        return () => {
            if(!disableCleanUp){
                clearRows();
                onPageDestroy && onPageDestroy();
                setMinMaxLoadedPage({ minLoadedPage: -1, maxLoadedPage: -1});
                clearFilters();
            }
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(isInitialized){
            DEBUG && console.log(`${id} -- Fetch by filter/search update`, currentSearchForFetch, currentFiltersForFetch)
            handleFetch(0, currentSearchForFetch, currentFiltersForFetch, true, false);
        }
        //eslint-disable-next-line
    }, [currentSearchForFetch, currentFiltersForFetch]);

    useEffect(() => {
        if(isInitialized){
            const searchConfig = replaceGenericTableSearch({page: currentViewPage, search: currentSearchForFetch, filters: originalSelectedValues, lastId: lastSelectedId, additionalProps: additionalSearchProps});
            pushHistorySearch(searchConfig);
        }
        //eslint-disable-next-line
    }, [additionalSearchProps]);

    // useEffect(() => {
    //     console.log(`${id} -- minLoadedPage, maxLoadedPage UPDATE`, minLoadedPage, maxLoadedPage)
    // }, [minLoadedPage, maxLoadedPage]);

    React.useLayoutEffect(() => {
        //used right after render to check skeletons visibility to auto fetch more for bigger screens
        checkIfScrollableAndFetchMoreIfNot();
        //eslint-disable-next-line
    }, [rows]);


    React.useLayoutEffect(() => {
        //used to scroll to currentViewPage on change to rows/cards view
        //when moving from cards to tables - has to scroll to viewed page
        if(isInitialized){
            const pageElem = getElement("page", `page-${currentViewPage}`);
            if(pageElem){
                DEBUG && console.log(`${id} -- ----scroll to page on view change`, currentViewPage)
                pageElem.scrollIntoView({block: 'start', inline: "nearest"});
                //rowsView ? 'center' : 'end',
            }
        }
        checkIfScrollableAndFetchMoreIfNot();
        //eslint-disable-next-line
    }, [rowsView]);

    //ACTIONS
    const handleFetch = (page: number, search: string, filters: CommonFilterType, isFilterUpdated: boolean, isScrollUp: boolean, lastId?: string | null) => {
        DEBUG && console.log(`${id} -- FETCH: 
        page: ${page}
        count: ${defaultPagingCount}
        search: ${search}
        filters: ${JSON.stringify(filters)}
        isFilterUpdated: ${isFilterUpdated}
        isScrollUp: ${isScrollUp}
        lastId: ${lastId}
        `)

        if(isFilterUpdated) {
            clearRows();
            setIsLoadingDown(true);
            setMinMaxLoadedPage({ minLoadedPage: -1, maxLoadedPage: -1});
        }
        setIsLoadingDown(!isScrollUp);

        fetchPaging(
            page,
            defaultPagingCount,
            getFilterWithSearch(search, filters, dontSendSearchIfEmpty, searchCustomFilterKeyName, searchAsArray, dontIncludeSearchInFilter),
            search,
        );

        const searchConfig = replaceGenericTableSearch({page: currentViewPage, search, filters: originalSelectedValues, lastId: lastId ?? lastSelectedId, additionalProps: additionalSearchProps});
        replaceHistorySearch(searchConfig);
    }


    const toggleRowsView = () => {
        saveIsTableLS(!rowsView, id, currentUser?.publicId ?? '', selectedWorkspace?.id ?? '');
        setRowsView(!rowsView);
    }

    //search actions
    const replaceHistorySearch = (search: string) => {
        console.log(`History replace by genericTable ${id}`, search)
        history.replace({
            pathname: history.location.pathname,
            search
        })
    }

    const pushHistorySearch = (search: string) => {
        console.log(`History push by genericTable ${id}`, search)
        history.push({
            pathname: history.location.pathname,
            search
        })
    }

    const _onRowClick = async (row: T) => {
        const rowId = getRowUniqueId(row);
        setLastSelectedId(rowId);
        const searchConfig = replaceGenericTableSearch({page: pageInfo.page, search: currentSearchForFetch, filters: originalSelectedValues, lastId: rowId});
        replaceHistorySearch(searchConfig);
        onRowClick(row);
    }

    const checkIfScrollableAndFetchMoreIfNot = () => {
        // console.log(`${id} -- checkIfScrollableAndFetchMoreIfNot`, rows.length > 0, !isLoadingDown, scrollRef.current)
        // console.log(`${id} -- checkIfScrollableAndFetchMoreIfNot (all true)`, new Date().toISOString(), scrollRef.current !== null, rows.length > 0, !isLoading);
        if(rows.length > 0 && !isLoadingDown && scrollRef.current){
            //Used to keep scroll at place after load of first page
            //if scroll is 0 - it will immediately jump to top
            if(rowsView){
                if(scrollRef.current.scrollTop < SKELETON_ROWS_HEIGHT){
                    //
                    let firstPageHeight = 0;
                    for(let i = 0; i < defaultPagingCount - 1; i++){
                        firstPageHeight += getElementHeight(`row-${i}`)
                    }
                    scrollRef.current.scrollTo({top: firstPageHeight + SKELETON_ROWS_HEIGHT}); //to replace top skeleton with last loaded item
                }
            }else{
                // if(isElementVisible(scrollRef, 'skeleton', 'top')){
                //     scrollRef.current.scrollTo({top: 80});
                //     console.log(`${id} -- --------------Top skeleton is visible scroll 80`);
                // }
                // console.log(`${id} -- checkIfScrollableAndFetchMoreIfNot else`)
                const pageZeroEndElem = getElement('page', `page-${minLoadedPage}-end`);
                pageZeroEndElem && pageZeroEndElem.scrollIntoView({block: 'start'});
                // if(pageZeroStartElem && pageZeroEndElem){
                //     const pageHeight = Math.abs(pageZeroStartElem?.getBoundingClientRect().top - pageZeroEndElem?.getBoundingClientRect().top);
                //     scrollRef.current.scrollTo({top: 55 + pageHeight}); //80 is top skeleton height
                //     console.log(`${id} -- Scroll 80 + `, pageHeight);
                // }
            }
        }

        checkIsVisibleSkeletonAndFetchIfTrue();
    }

    // Event handler for scroll events
    const onScroll: UIEventHandler<HTMLDivElement> = (event) => {
        const target = event.target as HTMLElement; // Assuming the event target is the scrollable container element
        const scrollPosition = target.scrollTop;

        if(scrollRef.current){
            const listFromTop = target.getBoundingClientRect().top;
            const currentPage = getCurrentPage(minLoadedPage, maxLoadedPage, scrollPosition, listFromTop);
            setViewPage(currentPage);
            // Create a new search configuration based on the current page and other parameters
            const searchConfig = replaceGenericTableSearch({page: currentPage, search: currentSearchForFetch, filters: currentFiltersForFetch, lastId: lastSelectedId});
            // Update the history with the new search configuration
            replaceHistorySearch(searchConfig);

            checkIsVisibleSkeletonAndFetchIfTrue();
        }
    }

    const checkIsVisibleSkeletonAndFetchIfTrue = () => {
        if(isInitialized){
            DEBUG && console.log(`${id} -- checkIsVisibleSkeletonAndFetchIfTrue`)
            const isVisibleSkeletonTopCards = isElementVisible(scrollRef, 'skeletonDiv', 'top');
            const isVisibleSkeletonTopRows = isElementVisible(scrollRef, 'skeletonTr', 'top');
            const isVisibleSkeletonBottomCards = isElementVisible(scrollRef, 'skeletonDiv', 'bottom');
            const isVisibleSkeletonBottomRows = isElementVisible(scrollRef, 'skeletonTr', 'bottom');

            DEBUG && console.log(`${id} -- 
                checkIsVisibleSkeletonAndFetchIfTrue - 
                isVisibleSkeletonTopCards: ${isVisibleSkeletonTopCards}
                isVisibleSkeletonTopRows: ${isVisibleSkeletonTopRows}
                isVisibleSkeletonBottomCards: ${isVisibleSkeletonBottomCards}
                isVisibleSkeletonBottomRows: ${isVisibleSkeletonBottomRows}
                
            `);
            if((isVisibleSkeletonTopCards || isVisibleSkeletonTopRows) && minLoadedPage > 0 && !isLoading){
                DEBUG && console.log(`${id} -- TOP SKELETON IS VISIBLE - LOAD TOP`)
                handleFetch( minLoadedPage - 1, currentSearchForFetch, currentFiltersForFetch, false, true);
            }

            if((isVisibleSkeletonBottomCards || isVisibleSkeletonBottomRows) && (hasMore || fetchBottomEventIfReachedTotal) && !isLoading){
                DEBUG && console.log(`${id} -- BOTTOM SKELETON IS VISIBLE - LOAD BOTTOM`)
                handleFetch(maxLoadedPage + 1, currentSearchForFetch, currentFiltersForFetch, false, false);
            }
        }
    }

    const handleOpenViewSettings = () => setViewSettingsDialog(true);
    const handleCloseViewSettings = () => setViewSettingsDialog(false);
    const handleApplyViewSettings = (columns: ColumnConfig[]) => {
        setViewSettings(columns);
        handleCloseViewSettings();
        saveColumnConfigLS(columns, id, currentUser?.publicId ?? '', selectedWorkspace?.id ?? '');
    }

    //DATA
    const hasMore = pageInfo.total > ((maxLoadedPage + 1) * defaultPagingCount);
    const isNotEmptyFilter = currentSearchForFetch.trim().length > 0 || Object.keys(currentFiltersForFetch).length > 0

    return{
        lastSelectedId,
        onRowClick: _onRowClick,
        onScroll,
        isLoadingDown,
        rows,
        rowsIds: rows.map(f => getRowUniqueId(f)),
        minLoadedPage,
        maxLoadedPage,
        scrollRef,
        viewSettings: {
            isOpen: viewSettingsDialog,
            handleOpenViewSettings,
            handleCloseViewSettings,
            handleApplyViewSettings,
            selectedColumns: viewSettings,
            selectedColumnsWithValueGetter: viewSettings.map(e => columnsConfigs.find(f => f.key === e.key)).filter(e => e !== undefined) as ColumnConfigWithValueGetter<T>[],
            availableColumns: columnsConfigs.map(e => ({key: e.key, name: e.name})),
            toggleRowsView,
            rowsView
        },
        hasMore,
        handleFetch,
        disableEmptyFilterResultTextRow,
        emptyArrayImage: {
            type: emptyArrayImageProps?.type ?? 'any',
            emptyArrayImageProps: emptyArrayImageProps,
            isShow: rows.length === 0 && !isLoading && (!disableEmptyFilterResultTextRow && !isNotEmptyFilter),
            getProps: () => {
                if(isNotEmptyFilter){
                    //filter applied - return props value or default
                    return {
                        text: emptyArrayImageProps?.filterApplied?.text ?? NO_RESULTS_QUERY_TEXT,
                        secondaryText: emptyArrayImageProps?.filterApplied?.secondaryText ?? NO_RESULTS_QUERY_SECONDARY_TEXT,
                        withoutAdd: emptyArrayImageProps?.filterApplied?.withoutAdd ?? emptyArrayImageProps?.filterApplied?.onClick === undefined,
                        onClick: emptyArrayImageProps?.filterApplied?.onClick,
                    }
                }else{
                    //filter not applied - probably not exists
                    return {
                        text: emptyArrayImageProps?.filterNotApplied?.text ?? NO_RESULTS_EMPTY_TEXT,
                        secondaryText: emptyArrayImageProps?.filterNotApplied?.secondaryText ?? NO_RESULTS_EMPTY_SECONDARY_TEXT,
                        withoutAdd: emptyArrayImageProps?.filterNotApplied?.withoutAdd ?? emptyArrayImageProps?.filterNotApplied?.onClick === undefined,
                        onClick: emptyArrayImageProps?.filterNotApplied?.onClick,
                    }
                }
            }
        },
        markRow: (row: T) => {
            const id = getRowUniqueId(row);
            setLastSelectedId(id);
            const searchConfig = replaceGenericTableSearch({page: pageInfo.page, search: currentSearchForFetch, filters: currentFiltersForFetch, lastId: id});
            replaceHistorySearch(searchConfig);
        }
    }
}
