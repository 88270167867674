import axiosSync from "../../../../newShared/utils/axios/axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {print} from "graphql";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {AxiosResponse} from "axios";
import {TPageInfo} from "../../../../newShared/types";
import {
    AccessManagementApp,
    AccessManagementAppAccount,
    campaignReviewExecutorStageActorAppData,
    campaignReviewExecutorType,
    campaignReviewInstructionType,
    createAppByVendorRequestType,
    createNewAppRequestType,
    smallCampaignReviewExecutorType,
    TDutyRule
} from "../types";

import {
    AddConflictsMutation,
    AddConflictsMutationVariables,
    AllowConflictCustomMutation,
    AllowConflictCustomMutationVariables,
    AllowConflictStandardMutation,
    AllowConflictStandardMutationVariables,
    CreateAppDutyMutation,
    CreateAppDutyMutationVariables,
    CreateDutyRuleMutation,
    CreateDutyRuleMutationVariables,
    DeleteAppDutyMutation,
    DeleteAppDutyMutationVariables,
    DeleteConflictExceptionMutation,
    DeleteConflictExceptionMutationVariables,
    DeleteDutyRuleMutation,
    DeleteDutyRuleMutationVariables,
    DenyConflictMutation,
    DenyConflictMutationVariables,
    DownloadEvidenceFileQuery,
    DownloadEvidenceFileQueryVariables,
    EditAppDutyMutation,
    EditAppDutyMutationVariables,
    EditConflictExceptionMutation,
    EditConflictExceptionMutationVariables,
    EditDutyRuleMutation,
    EditDutyRuleMutationVariables,
    EditSodSchedulerMutation,
    EditSodSchedulerMutationVariables,
    ExportCsvAccessManagementAppQueryVariables,
    GetAccessManagementAvailableFieldsForExportQuery,
    GetAccessManagementAvailableFieldsForExportQueryVariables,
    GetAppDutiesQuery,
    GetAppDutiesQueryVariables,
    GetDataForAllowedConflictQuery,
    GetDataForAllowedConflictQueryVariables,
    GetDataForCreateDutyQuery,
    GetDataForCreateDutyQueryVariables,
    GetDataForCreateRuleQuery,
    GetDataForCreateRuleQueryVariables,
    GetDataForDenyConflictQuery,
    GetDataForDenyConflictQueryVariables,
    GetDataForReleaseQuery,
    GetDataForReleaseQueryVariables,
    GetDutiesRulesetQuery,
    GetDutiesRulesetQueryVariables,
    GetDutyRuleByIdQueryVariables,
    GetRoleNamesByAppAccessIdQuery,
    GetRoleNamesByAppAccessIdQueryVariables,
    GetRulesConflictsQuery,
    GetRulesConflictsQueryVariables,
    GetSodSchedulerDataQuery,
    GetSodSchedulerDataQueryVariables,
    GetVendorsQuery,
    GetVendorsSettingsQuery,
    GetVendorsSettingsQueryVariables,
    PaginationInput,
    ReleaseAccessManagementApp1MutationVariables,
    RunRuleCheckMutation,
    RunRuleCheckMutationVariables
} from "../../../../newShared/GQLTypes";
import * as Q from "./query";
import {
    addConflicts,
    allowConflictCustom,
    allowConflictStandard,
    createAppDuty,
    createDutyRule,
    deleteAppDuty,
    deleteConflictException,
    deleteDutyRule,
    denyConflict,
    editAppDuty,
    editConflictException,
    editDutyRule,
    editSodSchedulerData,
    getAppDuties,
    getDataForAllowedConflict,
    getDataForCreateDuty,
    getDataForCreateRule,
    getDataForDenyConflict,
    getDutiesRuleset,
    getDutyRuleById,
    getRoleNamesByAccessId,
    getRulesConflicts,
    getSodSchedulerData,
    runRuleCheck
} from "./query";
import {gql} from "graphql.macro";

export const getAccessManagementApps = async (workspaceId: string, page: number, count: number, signal?: AbortSignal): Promise<{pageInfo: TPageInfo, apps: AccessManagementApp[]}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getAccessManagementApps($workspaceId:String!, $pageInfo:PaginationInput!){
                        getAccessManagementApps(workspaceId:$workspaceId, pageInfo:$pageInfo){
                            pageInfo{
                                count
                                total
                                sort
                                page
                            }
                            apps{
                                id
                                workspaceId
                                vendorId
                                vendorOwner{
                                    id
                                    name
                                }
                                name
                                type
                                integrationTypeConfig{
                                    applicationId
                                    applicationPrefix
                                }
                                lastUpdateDate
                                lastReviewDate
                                includeReview
                                dataTable{
                                    employee
                                    account
                                    accountId
                                    employeeId
                                    accountType
                                    status
                                    roles{
                                        roleName
                                        rolePermissions
                                    }
                                }
                            }
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    pageInfo: {
                        page,
                        count
                    }
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAccessManagementApps;
    })
)

export const changeAccessManagementStatus = async (workspaceId: string, appId: string, newStatus: boolean, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation changeAccessManagementStatus($workspaceId:String!, $appId:String!, $newStatus:Boolean!){
                        changeAccessManagementStatus(workspaceId:$workspaceId, appId:$appId, newStatus:$newStatus){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    appId,
                    newStatus
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.changeAccessManagementStatus.message;
    })
)

export const getVendorsApi = async (workspaceId: string, organizationId: string, pageInfo: PaginationInput): Promise<GetVendorsQuery["getVendors"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: Q.getVendors,
                variables: {
                    pageInfo,
                    workspaceId,
                    organizationId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getVendors;
    })
)

export const getVendorsSettingsApi = async (data: GetVendorsSettingsQueryVariables): Promise<GetVendorsSettingsQuery["getVendorsSettings"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: Q.getVendorsSettings,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getVendorsSettings;
    })
)

export const createNewAccessManagementApp = async (workspaceId: string, app: createNewAppRequestType, signal?: AbortSignal): Promise<AccessManagementApp> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation createNewAccessManagementApp($workspaceId:String!, $app:CreateNewAccessManagementAppInput!){
                        createNewAccessManagementApp(workspaceId:$workspaceId, app:$app){
                            id
                            workspaceId
                            vendorId
                            vendorOwner{
                                id
                                name
                            }
                            name
                            type
                            integrationTypeConfig{
                                applicationId
                                applicationPrefix
                            }
                            lastUpdateDate
                            lastReviewDate
                            includeReview
                            dataTable{
                                accountId
                                employeeId
                                employee
                                account
                                accountType
                                status
                                roles{
                                    roleName
                                    rolePermissions
                                }
                            }
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    app
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createNewAccessManagementApp;
    })
)

export const createAccessManagementAppByVendor = async (workspaceId: string, app: createAppByVendorRequestType, signal?: AbortSignal): Promise<AccessManagementApp> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation createAccessManagementAppByVendor($workspaceId:String!, $app:CreateAccessManagementAppByVendorInput!){
                        createAccessManagementAppByVendor(workspaceId:$workspaceId, app:$app){
                            id
                            workspaceId
                            vendorId
                            vendorOwner{
                                id
                                name
                            }
                            name
                            type
                            integrationTypeConfig{
                                applicationId
                                applicationPrefix
                            }
                            lastUpdateDate
                            lastReviewDate
                            includeReview
                            dataTable{
                                accountId
                                employeeId
                                employee
                                account
                                accountType
                                status
                                roles{
                                    roleName
                                    rolePermissions
                                }
                            }
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    app
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createAccessManagementAppByVendor;
    })
)

export const getAccessManagementAppById = async (workspaceId: string, id: string, signal?: AbortSignal): Promise<AccessManagementApp> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getAccessManagementAppById($workspaceId:String!, $id:String!){
                        getAccessManagementAppById(workspaceId:$workspaceId, id:$id){
                            id
                            workspaceId
                            vendorId
                            vendorOwner{
                                id
                                name
                            }
                            name
                            type
                            integrationTypeConfig{
                                applicationId
                                applicationPrefix
                            }
                            lastUpdateDate
                            lastReviewDate
                            includeReview
                            dataTable{
                                accountId
                                employeeId
                                employee
                                account
                                accountType
                                status
                                roles{
                                    roleName
                                    rolePermissions
                                }
                            }
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    id
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAccessManagementAppById;
    })
)

export const deleteAccessManagementCustomAppById = async (workspaceId: string, id: string, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deleteAccessManagementCustomAppById($workspaceId:String!, $id:String!){
                        deleteAccessManagementCustomAppById(workspaceId:$workspaceId, id:$id){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    id
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteAccessManagementCustomAppById.message;
    })
)

export const editAccessManagementApp = async (workspaceId: string, app: AccessManagementApp, signal?: AbortSignal): Promise<AccessManagementApp> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation editAccessManagementApp($workspaceId:String!, $app:AccessManagementAppEditRequestInput!){
                        editAccessManagementApp(workspaceId:$workspaceId, app:$app){
                            id
                            workspaceId
                            vendorId
                            name
                            type
                            vendorOwner{
                                id
                                name
                            }
                            integrationTypeConfig{
                                applicationId
                                applicationPrefix
                            }
                            lastUpdateDate
                            lastReviewDate
                            includeReview
                            dataTable{
                                accountId
                                employeeId
                                employee
                                account
                                accountType
                                status
                                roles{
                                    roleName
                                    rolePermissions
                                }
                            }
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    app
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.editAccessManagementApp;
    })
)

export const importCsvAccessManagementAppTemplate = async (workspaceId: string, id: string, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query importCsvAccessManagementAppTemplate($workspaceId:String!, $id:String!){
                        importCsvAccessManagementAppTemplate(workspaceId:$workspaceId, id:$id)
                    }
                `),
                variables: {
                    workspaceId,
                    id
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.importCsvAccessManagementAppTemplate;
    })
)

export const updateAccessManagementAppNameAndVendor = async (workspaceId: string, id: string, name: string, vendorId: string, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation updateAccessManagementAppNameAndVendor($workspaceId:String!, $id:String!, $name:String!, $vendorId:String!){
                        updateAccessManagementAppNameAndVendor(workspaceId:$workspaceId, id:$id, vendorId:$vendorId, name:$name){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    id,
                    name,
                    vendorId,
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateAccessManagementAppNameAndVendor.message;
    })
)
//workspaceId: string, id: string, fieldKeys: string[],
export const exportCsvAccessManagementApp = async (data: ExportCsvAccessManagementAppQueryVariables, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query exportCsvAccessManagementApp($workspaceId:String!, $id:String!, $fieldKeys: [String!]!, $isDownload:Boolean!){
                        exportCsvAccessManagementApp(workspaceId:$workspaceId, id:$id, fieldKeys:$fieldKeys, isDownload:$isDownload)
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.exportCsvAccessManagementApp;
    })
)

export const getDataForRelease = async (data: GetDataForReleaseQueryVariables, signal: AbortSignal): Promise<GetDataForReleaseQuery["getDataForRelease"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getDataForRelease($organizationId: String!,$workspaceId:String!){
                        getDataForRelease(organizationId: $organizationId ,workspaceId:$workspaceId){
                            frameworks{
                                id
                                name
                            }

                            controls{
                                id
                                name
                                frameworkIds
                                category
                            }

                            evidences{
                                id
                                name
                                controlIds
                            }
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getDataForRelease;
    })
)

export const releaseAccessManagementApp = async (data: ReleaseAccessManagementApp1MutationVariables, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation releaseAccessManagementApp1 ($workspaceId: String!, $organizationId: String!, $id: String!, $frameworkId: String!, $controlId: String!, $evidenceId: String!, $reviewers: [ReleaseActorInput!]!, $readers: [ReleaseActorInput!]!, $signers: [ReleaseActorInput!]!){
                        releaseAccessManagementApp(workspaceId: $workspaceId, organizationId: $organizationId, id: $id, frameworkId: $frameworkId, controlId: $controlId, evidenceId: $evidenceId, reviewers: $reviewers, readers: $readers, signers: $signers)
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.releaseAccessManagementApp;
    })
)

//campaign review

export const getCampaignReviewInstructionWithPaging = async (workspaceId: string, page: number, count: number, signal?: AbortSignal): Promise<{pageInfo: TPageInfo, instructions: campaignReviewInstructionType[]}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getCampaignReviewInstructionWithPagin($workspaceId:String!, $pageInfo: PaginationInput!){
                        getCampaignReviewInstructionWithPaging(workspaceId:$workspaceId, pageInfo: $pageInfo){
                            pageInfo{
                                page
                                count
                                total
                                sort
                            }
                            instructions{
                                id
                                name
                                workspaceId
                                workspaceName
                                trigger{
                                    start
                                    repeat
                                    type
                                }
                                description
                                applicationAccessIds
                                etaConfig{
                                    etaManager
                                    etaVendor
                                    etaExecution
                                    etaVerify
                                    etaSign
                                }
                                finalApprover{
                                    firstName
                                    lastName
                                    publicId
                                    email
                                }
                                evidence{
                                    type
                                    data{
                                        name
                                        id
                                        controlId
                                        frameworkId
                                    }
                                }
                                active
                                createDate
                                lastRunningDate
                                lastExecutorId
                                lastRunStatus
                                applications{
                                    name
                                    id
                                }
                                evidences{
                                    evidenceId
                                    evidenceName
                                    controlId
                                    controlName
                                    frameworkId
                                    frameworkName
                                }
                            }
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    pageInfo: {
                        page,
                        count
                    }
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getCampaignReviewInstructionWithPaging;
    })
)


export const getCampaignReviewInstructionById = async (workspaceId: string, id: string, signal?: AbortSignal): Promise<campaignReviewInstructionType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getCampaignReviewInstructionById($workspaceId:String!, $id:String!){
                        getCampaignReviewInstructionById(workspaceId:$workspaceId, id:$id){
                            id
                            name
                            workspaceId
                            workspaceName
                            trigger{
                                start
                                repeat
                                type
                            }
                            description
                            applicationAccessIds
                            etaConfig{
                                etaManager
                                etaVendor
                                etaExecution
                                etaVerify
                                etaSign
                            }
                            finalApprover{
                                firstName
                                lastName
                                publicId
                                email
                            }
                            evidence{
                                type
                                data{
                                    name
                                    id
                                    controlId
                                    frameworkId
                                }
                            }
                            active
                            createDate
                            lastRunningDate
                            lastExecutorId
                            lastRunStatus
                            applications{
                                name
                                id
                            }
                            evidences{
                                evidenceId
                                evidenceName
                                controlId
                                controlName
                                frameworkId
                                frameworkName
                            }
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    id
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getCampaignReviewInstructionById;
    })
)

export const createCampaignReviewInstruction = async (workspaceId: string, instruction: campaignReviewInstructionType, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation createCampaignReviewInstruction($workspaceId:String!, $instruction:CampaignReviewInstructionInput!){
                        createCampaignReviewInstruction(workspaceId:$workspaceId, instruction:$instruction){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    instruction
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createCampaignReviewInstruction.message;
    })
)

export const updateCampaignReviewInstruction = async (workspaceId: string, instruction: campaignReviewInstructionType, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation updateCampaignReviewInstruction($workspaceId:String!, $instruction:CampaignReviewInstructionInput!){
                        updateCampaignReviewInstruction(workspaceId:$workspaceId, instruction:$instruction){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    instruction
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateCampaignReviewInstruction.message;
    })
)

export const deleteCampaignReviewInstruction = async (workspaceId: string, id: string, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deleteCampaignReviewInstruction($workspaceId:String!, $id:String!){
                        deleteCampaignReviewInstruction(workspaceId:$workspaceId, id:$id){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    id
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteCampaignReviewInstruction.message;
    })
)

export const runCampaignReviewInstruction = async (workspaceId: string, id: string, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation runCampaignReviewInstruction($workspaceId:String!, $id:String!){
                        runCampaignReviewInstruction(workspaceId:$workspaceId, id:$id){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    id
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.runCampaignReviewInstruction.message;
    })
)

export const toggleCampaignReviewInstructionStatus = async (workspaceId: string, id: string, active: boolean, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation toggleCampaignReviewInstructionStatus($workspaceId:String!, $id:String!, $active:Boolean!){
                        toggleCampaignReviewInstructionStatus(workspaceId:$workspaceId, id:$id, active:$active){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    id,
                    active
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.toggleCampaignReviewInstructionStatus.message;
    })
)

export const updateNonCustomAccessManagementAppRow = async (workspaceId: string, accessManagementAppId: string, applicationId: string, account: AccessManagementAppAccount, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation updateNonCustomAccessManagementAppRow($workspaceId:String!, $accessManagementAppId:String!, $applicationId:String!, $account: AccessManagementAppAccountEditRequestType!){
                        updateNonCustomAccessManagementAppRow(workspaceId:$workspaceId, accessManagementAppId:$accessManagementAppId, applicationId:$applicationId, account:$account){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    accessManagementAppId,
                    applicationId,
                    account
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateNonCustomAccessManagementAppRow.message;
    })
)

//campaign review executors
export const getSmallCampaignReviewExecutors = async (workspaceId: string, page: number, count: number, signal?: AbortSignal): Promise<{pageInfo: TPageInfo, executors: smallCampaignReviewExecutorType[]}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getSmallCampaignReviewExecutors($workspaceId:String!, $pageInfo:PaginationInput!){
                        getSmallCampaignReviewExecutors(workspaceId:$workspaceId, pageInfo: $pageInfo){
                            pageInfo{
                                page
                                total
                                count
                            }
                            executors{
                                id
                                instructionId
                                name
                                workspaceId
                                workspaceName
                                trigger{
                                    repeat
                                    start
                                    type
                                }
                                applicationAccessIds
                                stages{
                                    id
                                    type
                                    runDate
                                    expirationDate
                                    eta
                                    status
                                    statusChangedDate
                                }
                                finalApprover{
                                    email
                                    firstName
                                    lastName
                                    publicId
                                    signId
                                    documentId
                                }
                                evidence{
                                    type
                                    data{
                                        frameworkId
                                        controlId
                                        id
                                        name
                                    }
                                }
                                runDate
                                expirationDate
                                status
                                statusChangedDate
                                etaConfig{
                                    etaManager
                                    etaSign
                                    etaVendor
                                    etaVerify
                                    etaExecution
                                }
                                description
                            }
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    pageInfo: {
                        page,
                        count
                    }
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getSmallCampaignReviewExecutors;
    })
)


export const getFullCampaignReviewExecutorById = async (workspaceId: string, id: string, signal?: AbortSignal): Promise<campaignReviewExecutorType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getFullCampaignReviewExecutorById($workspaceId: String!, $id: String!){
                        getFullCampaignReviewExecutorById(workspaceId: $workspaceId, id: $id){
                            id
                            instructionId
                            name
                            workspaceId
                            workspaceName
                            description
                            trigger{
                                type
                                start
                                repeat
                            }
                            applicationAccessIds
                            etaConfig{
                                etaManager
                                etaVendor
                                etaExecution
                                etaVerify
                                etaSign
                            }
                            dataSnapshots{
                                latest
                                createdDate
                                appDataSnapshot{
                                    vendorId
                                    applicationId
                                    applicationAccessId
                                    dataTable{
                                        employee
                                        employeeId
                                        account
                                        accountId
                                        accountType
                                        status
                                        roles{
                                            roleName
                                            rolePermissions
                                        }
                                    }
                                }
                            }
                            stages{
                                id
                                type
                                runDate
                                expirationDate
                                eta
                                status
                                statusChangedDate
                                actors{
                                    email
                                    status
                                    statusChangedDate
                                    firstName
                                    lastName
                                    publicId
                                    mainControl
                                    verifyRevokeComment
                                    appsData{
                                        applicationAccessId
                                        applicationId
                                        vendorId
                                        vendorName
                                        applicationAccessName
                                        data{
                                            id
                                            employee
                                            employeeId
                                            account
                                            accountId
                                            accountType
                                            status
                                            roles{
                                                roleName
                                                rolePermissions
                                            }
                                            id
                                            commentManager
                                            statusManager
                                            commentVendor
                                            statusVendor
                                            publicIdManager
                                            publicIdVendor
                                        }
                                    }
                                }
                            }
                            finalApprover{
                                email
                                firstName
                                lastName
                                publicId
                                signId
                                documentId
                            }
                            evidence{
                                type
                                data{
                                    id
                                    name
                                    controlId
                                    frameworkId
                                }
                            }
                            runDate
                            expirationDate
                            status
                            statusChangedDate
                            etaConfig{
                                etaManager
                                etaVendor
                                etaExecution
                                etaVerify
                                etaSign
                            }
                            description
                            applications{
                                    name
                                    id
                            }
                            evidences{
                                    evidenceId
                                    evidenceName
                                    controlId
                                    controlName
                                    frameworkId
                                    frameworkName
                            }
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    id
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getFullCampaignReviewExecutorById;
    })
)

export const updateCampaignReviewRows = async (workspaceId: string, email: string, stageId: string, appsData: campaignReviewExecutorStageActorAppData[], executorId: string, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation updateCampaignReviewRows($workspaceId:String!, $email:String!, $stageId:String!, $data:UpdateRowStatusOrCommentDtoRequestDto!, $executorId: String!){
                        updateCampaignReviewRows(workspaceId:$workspaceId, email:$email, stageId:$stageId, data:$data, executorId: $executorId){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    data: {appsData},
                    email,
                    stageId,
                    executorId
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateCampaignReviewRows.message;
    })
)


export const assignStageToMainControl = async (workspaceId: string, email: string, stageId: string, executorId: string, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation assignStageToMainControl($workspaceId:String!, $email:String!, $stageId:String!, $executorId:String!){
                        assignStageToMainControl(workspaceId:$workspaceId, email:$email, stageId:$stageId, executorId:$executorId){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    email,
                    stageId,
                    executorId
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.assignStageToMainControl.message;
    })
)


export const verifyActorChanges = async (workspaceId: string, email: string, stageId: string, verifyComment: string | null, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation verifyActorChanges($workspaceId:String!, $email:String!, $stageId:String!, $verifyComment:String){
                        verifyActorChanges(workspaceId:$workspaceId, email:$email, stageId:$stageId, verifyComment:$verifyComment){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    email,
                    stageId,
                    verifyComment
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.verifyActorChanges.message;
    })
)


export const terminateExecutor = async (workspaceId: string, id: string, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation terminateExecutor($workspaceId:String!, $id:String!){
                        terminateExecutor(workspaceId:$workspaceId, id:$id){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    id
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.terminateExecutor.message;
    })
)


export const downloadEvidenceFileAPI = async (data: DownloadEvidenceFileQueryVariables): Promise<DownloadEvidenceFileQuery["downloadEvidenceFile"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query downloadEvidenceFile($fileId: String!, $workspaceId: String!, $fileName: String!) {
                        downloadEvidenceFile (fileId: $fileId, fileName: $fileName, workspaceId: $workspaceId) {
                            filename
                            filetype
                            file
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.downloadEvidenceFile as {file: string, filename: string, filetype: string};
    })
)


//Segregation of duty
export const getAppDutiesApi = async (data: GetAppDutiesQueryVariables, signal?: AbortSignal): Promise<GetAppDutiesQuery['getAppDuties']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getAppDuties,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.getAppDuties
        })
    )
)

export const createAppDutyApi = async  (data: CreateAppDutyMutationVariables): Promise<CreateAppDutyMutation['createAppDuty']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: createAppDuty,
                variables: data,
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.createAppDuty
        })
    )
)

export const updateAppDutyApi = async (data: EditAppDutyMutationVariables): Promise<EditAppDutyMutation['editAppDuty']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: editAppDuty,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.editAppDuty
        })
    )
)

export const deleteAppDutyApi = async (data: DeleteAppDutyMutationVariables): Promise<DeleteAppDutyMutation['deleteAppDuty']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: deleteAppDuty,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.deleteAppDuty
        })
    )
)



//Rules
export const getDutiesRulesetApi = async (data: GetDutiesRulesetQueryVariables, signal?: AbortSignal): Promise<GetDutiesRulesetQuery['getDutiesRuleset']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getDutiesRuleset,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.getDutiesRuleset
        })
    )
)

export const getDutyRuleByIdApi = async (data: GetDutyRuleByIdQueryVariables, signal?: AbortSignal):Promise<TDutyRule> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getDutyRuleById,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        try{
            return result.data.data.getDutyRuleById;
        }catch (ex){
            throw new Error(result.data.errors[0] ?? 'Unknown error!');
        }
    })
)
export const createDutyRuleApi = async  (data: CreateDutyRuleMutationVariables): Promise<CreateDutyRuleMutation['createDutyRule']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: createDutyRule,
                variables: data,
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.createDutyRule
        })
    )
)

export const updateDutyRuleApi = async (data: EditDutyRuleMutationVariables): Promise<EditDutyRuleMutation['editDutyRule']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: editDutyRule,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.editDutyRule
        })
    )
)

export const deleteDutyRuleApi = async (data: DeleteDutyRuleMutationVariables): Promise<DeleteDutyRuleMutation['deleteDutyRule']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: deleteDutyRule,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.deleteDutyRule
        })
    )
)


export const exportCsvRulesetApi = async (workspaceId: string, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`                                
                    query exportCsvRuleset($workspaceId:String!){
                      exportCsvRuleset(workspaceId:$workspaceId)
                    }
                `),
                variables: {
                    workspaceId,
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.exportCsvRuleset;
    })
)

export const importCsvRuleset = gql`
    mutation importCsvRuleset($workspaceId:String!, $file:String!){
        importCsvRuleset(workspaceId:$workspaceId, file:$file){
            message
        }
    }
`

export const importCsvRulesetTemplateApi = async (workspaceId: string, signal?: AbortSignal): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`                                
                    query importCsvRulesetTemplate($workspaceId:String!){
                      importCsvRulesetTemplate(workspaceId:$workspaceId,)
                    }
                `),
                variables: {
                    workspaceId,
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.importCsvRulesetTemplate;
    })
)

export const runRuleCheckApi = async (data: RunRuleCheckMutationVariables, signal?: AbortSignal): Promise<RunRuleCheckMutation['runRuleCheck']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: runRuleCheck,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.runRuleCheck
        })
    )
)

export const getRulesConflictsApi = async (data: GetRulesConflictsQueryVariables, signal?: AbortSignal): Promise<GetRulesConflictsQuery['getRulesConflicts']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getRulesConflicts,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.getRulesConflicts
        })
    )
)

export const addConflictsApi = async (data: AddConflictsMutationVariables): Promise<AddConflictsMutation['addConflicts']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: addConflicts,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.addConflicts
        })
    )
)

export const getDataForCreateDutyApi = async (data: GetDataForCreateDutyQueryVariables, signal?: AbortSignal): Promise<GetDataForCreateDutyQuery['getDataForCreateDuty']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getDataForCreateDuty,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.getDataForCreateDuty
        })
    )
)

export const getDataForAllowedConflictApi = async (data: GetDataForAllowedConflictQueryVariables, signal?: AbortSignal): Promise<GetDataForAllowedConflictQuery['getDataForAllowedConflict']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getDataForAllowedConflict,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.getDataForAllowedConflict
        })
    )
)

export const getDataForCreateRuleApi = async (data: GetDataForCreateRuleQueryVariables, signal?: AbortSignal): Promise<GetDataForCreateRuleQuery['getDataForCreateRule']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getDataForCreateRule,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.getDataForCreateRule
        })
    )
)


export const getDataForDenyConflictApi = async (data: GetDataForDenyConflictQueryVariables, signal?: AbortSignal): Promise<GetDataForDenyConflictQuery['getDataForDenyConflict']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getDataForDenyConflict,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.getDataForDenyConflict
        })
    )
)

export const getRoleNamesApi = async (data: GetRoleNamesByAppAccessIdQueryVariables, signal?: AbortSignal): Promise<GetRoleNamesByAppAccessIdQuery['getRoleNamesByAppAccessId']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getRoleNamesByAccessId,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.getRoleNamesByAppAccessId
        })
    )
)


export const denyConflictApi = async (data: DenyConflictMutationVariables, signal?: AbortSignal): Promise<DenyConflictMutation['denyConflict']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: denyConflict,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.denyConflict
        })
    )
)

export const allowConflictStandardApi = async (data: AllowConflictStandardMutationVariables, signal?: AbortSignal): Promise<AllowConflictStandardMutation['allowConflictStandard']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: allowConflictStandard,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.allowConflictStandard
        })
    )
)


export const allowConflictCustomApi = async (data: AllowConflictCustomMutationVariables, signal?: AbortSignal): Promise<AllowConflictCustomMutation['allowConflictCustom']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: allowConflictCustom,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.allowConflictCustom
        })
    )
)

export const updateConflictExceptionApi = async (data: EditConflictExceptionMutationVariables): Promise<EditConflictExceptionMutation['editConflictException']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: editConflictException,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.editConflictException
        })
    )
)

export const deleteConflictExceptionApi = async (data: DeleteConflictExceptionMutationVariables): Promise<DeleteConflictExceptionMutation['deleteConflictException']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: deleteConflictException,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.deleteConflictException
        })
    )
)

export const getAccessManagementAvailableFieldsForExport = async (data: GetAccessManagementAvailableFieldsForExportQueryVariables): Promise<GetAccessManagementAvailableFieldsForExportQuery["getAccessManagementAvailableFieldsForExport"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getAccessManagementAvailableFieldsForExport($workspaceId:String!){
                        getAccessManagementAvailableFieldsForExport(workspaceId:$workspaceId){
                            fields {
                                fieldKey
                                fieldTitle
                            }
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAccessManagementAvailableFieldsForExport;
    })
);


export const getSodSchedulerDataApi = async (data: GetSodSchedulerDataQueryVariables, signal?: AbortSignal): Promise<GetSodSchedulerDataQuery['getSodSchedulerData']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getSodSchedulerData,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.getSodSchedulerData
        })
    )
)

export const updateSodSchedulerDataApi = async (data: EditSodSchedulerMutationVariables): Promise<EditSodSchedulerMutation['editSodScheduler']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: editSodSchedulerData,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.editSodSchedulerData
        })
    )
)




