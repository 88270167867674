import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {TBreadcrumb} from "../types";

export type TBreadcrumbsSlice = {
    breadcrumbs: TBreadcrumb[],
    maxItems: number,

    pathHistory: {path: string, search: string}[],
}

const initialState: TBreadcrumbsSlice = {
    breadcrumbs: [],
    maxItems: 10,

    pathHistory: [],
}

const Slice = createSlice({
  name: 'Breadcrumbs',
  initialState,
  reducers: {
      setBreadcrumbsAction(slice: TBreadcrumbsSlice, {payload}: {payload: TBreadcrumbsSlice["breadcrumbs"]}) {
          slice.breadcrumbs = payload
      },
      setBreadcrumbsSizeAction(slice: TBreadcrumbsSlice, {payload}: {payload: TBreadcrumbsSlice["maxItems"]}) {
          slice.maxItems = payload
      },

      addPathnameBeforeAction(slice: TBreadcrumbsSlice, {payload}: {payload: {path: string, search: string}}) {
          if (slice.pathHistory[0]?.path !== payload.path) slice.pathHistory.unshift(payload);
          else if (slice.pathHistory[0]?.search !== payload.search) slice.pathHistory[0].search = payload.search;

          slice.pathHistory = slice.pathHistory.slice(0, 5);
      }
  },
  // extraReducers: builder => {
  //   builder
  //
  // }
});

export const breadcrumbsReducer = Slice.reducer;

export const {
    setBreadcrumbsAction,
    setBreadcrumbsSizeAction,

    addPathnameBeforeAction
} = Slice.actions;

const selectSelf = (state: AppState): TBreadcrumbsSlice => state.breadcrumbs;

export const breadcrumbsSelector = createSelector(selectSelf, state => state.breadcrumbs);
export const maxCountBreadcrumbsSelector = createSelector(selectSelf, state => state.maxItems);

export const pathHistorySelector = createSelector(selectSelf, state => state.pathHistory);
