import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import colors from "../../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDeleteAppDialog} from "../../../hooks/dialogHooks/useDeleteAppDialog";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";

export const DeleteAppDialog = () => {
    const {isOpen, handleSubmit, handleClose, app, isLoading} = useDeleteAppDialog();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.AccessManagementAppsExact.dialogs'});

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Delete {{name}} application', {name: app?.name ?? ''})}</DialogTitle>

            <DialogContent>
                <Typography variant={'body2'} color={colors.darkBlueText}>
                    {t(`If you delete {{name}}, you will not be able to restore it.`, {name: app?.name ?? ''})}
                </Typography>

                <Typography variant={'body2'} color={colors.darkBlueText}>
                    {t(`This application will be deleted from all campaign review configurations it was used in, and scheduled campaign review configurations containing this application only - will be stopped.`)}
                </Typography>

                <Typography variant={'body2'} color={colors.darkBlueText}>
                    {t(`Are you sure you want to delete this application?`)}
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                >
                    {t('Delete')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}