import {gql} from "@apollo/react-hooks";

export const idNameFragment = gql`
    fragment IdNameFragment on IdNameModel {
        id
        name
    }
`

export const appInfoSmallFragment = gql`
    fragment appInfoSmallFragment on AppInfo {
        appId
        name
        logo
    }
`

export const idNameLogoModelFragment = gql`
    fragment idNameLogoModelFragment on IdNameLogoModel {
        id
        name
        logo
    }
`

export const pageInfoFragment = gql`
    fragment pageInfoFragment on PageInfo {
        page
        count
        total
        sort
    }
`

export const getFiltersFragment = gql`
    fragment getFiltersFragment on Filter {
        name
        values
    }
`

export const importCsvFragmentFields = gql`
    fragment importCsvFragmentFields on ImportCsvFieldModel {
        fieldKey
        fieldTitle

        csvField
        description

        isKey

        isRequired
        isDisabled
    }
`

export const performCsvFragment = gql`
    fragment performCsvFragment on ImportCsvPerformResponse {
        message
        successCount
        duplicatesCount
        errorRow
        errorMessage
    }
`