import {useLocation} from "react-router";
import {useMemo} from "react";
import {hideTopBar} from "../../helpers";

export const useSideBar = () => {
    const { pathname } = useLocation();
    const flag = hideTopBar(pathname);
    return useMemo(() => flag
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [flag])
}
