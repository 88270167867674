import styled from "styled-components";
import {device} from "../../../../../newShared/constants";
import colors from "../../../../../newShared/theme/colors";
import {Autocomplete} from "@mui/lab";
import {Box, styled as styledMui} from "@mui/material";

export const AutoComplete = styled(Autocomplete)`
  & .MuiInputBase-input {
    height: 24px;
  }
  & label{
    top: 0 !important;
  }
`;

export const EmployeesTableContainer = styled.div`
    width: 100%;
    display: flex;
    overflow: hidden;
    height: 100%;
    //max-width: 1572px;
    margin: 16px 0 0 0;
`;

export const EmployeeCardContainer = styled.div`
  cursor: pointer;
  &:hover{
    background-color: ${colors.backgrounds.grey_light};
  }
  
  width: 24%;
  height: fit-content;
  max-width: 297px;
  min-width: 255px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(58, 53, 65, 0.1);

  ${device.generateMax(1303)}{
    width: calc(33% - 16px);
    max-width: unset;
  }
  
  ${device.generateMax(860)}{
    width: calc(50% - 16px);
    max-width: unset;
  }
  
  ${device.generateMax(585)}{
    width: 100%;
    max-width: unset;
  }
`;

export const EmployeeOverviewWrapper = styled.div`
    width: 90%;
    display: flex;
    ${device.desktopNarrow} {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
`;

export const EmployeeOverviewSubWrapper = styled.div<{width?: string}>`
    width: ${props => props.width ?? '50%'};
    flex-direction: column;
    display: flex;
    ${device.desktopNarrow} {
        width: 100%;
    }
`;

export const EmployeesJobHistoryTableContainer = styled.div`
    width: 100%;
    display: flex;
    overflow: auto;
    //height: 100%;
    max-width: 1572px;
    margin: 16px 0 0 0;
`;

export const EmployeesJobHistoryCardsContainer = styled.div`
    width: 100%;
    display: flex;
    overflow: auto;
  flex-direction: column;
  //height: 100%;
    max-width: 1572px;
    margin: 16px 0 0 0;
  padding: 8px;
`;

export const EmployeeHistoryCardContainer = styled.div`
    max-width: 400px;
    min-width: 343px;
    display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(58, 53, 65, 0.1);
  margin: 16px 0 0 0;
  
  ${device.desktopNarrow}{
    max-width: unset;
    width: 100%;
  }
`;

export const DialogContentWrapper = styled.div<{margin?: string}>`
    width: 60%;
    ${props => props.margin ? `margin: ${props.margin};` : 'margin: 16px 0 0 0;'}

    ${device.tablet}{
        width: 60%;
    }
    ${device.desktopNarrow}{
        width: 100%;
    }
`;

export const TrainingsTableHeaderWrapper = styled.div<{minWidth?: string, jc?: string}>`
    ${props => props.minWidth && `min-width: ${props.minWidth};`}
    display: flex;
    width: 90%;
    overflow: auto;
    justify-content: ${props => props.jc ?? 'space-between'};
    align-items: center;
    margin: 0 0 24px 0;
    ${device.tablet} {
        width: 100%;
        margin: 0 0 24px 0;
    }
`;

export const TableServicesHeaderItemWrapper = styled.div<{ai?: string}>`
    width: 25%;
    ${device.generateMax(890)}{
        width: 50%;
    }
    display: flex;
    align-items: ${props => props.ai ?? 'center'};
    & p {
        text-overflow: ellipsis; 
        display: block; 
        overflow: hidden; 
        line-height: normal;
    }
`;

export const MainTableRowWrapper = styled.div<{disableHover?: boolean, width?: string, minWidth?: string}>`
    ${props => props.minWidth && `min-width: ${props.minWidth};`}
    width: ${props => props.width ?? '100%'};
    ${props => props.disableHover ?'' : `
        &:hover{
            background-color: ${colors.backgrounds.grey_light};
            cursor: pointer;
        }
    `}
`;

export const TableRowWrapper = styled.div<{disableHover?: boolean, jc?: string}>`
    width: 90%;
    display: flex;
    justify-content: ${props => props.jc ?? 'space-between'};
    align-items: center;
    ${props => props.disableHover ?'' : `
        &:hover{
            background-color: inherit;
            cursor: pointer;
        }
    `}
   
    ${device.tablet} {
        width: 100%;
    }
`;

export const TableServicesItemWrapper = styled.div<{ai?: string}>`
    width: 25%;
    ${device.generateMax(890)}{
        width: 50%;
    }
    display: flex;
    align-items: ${props => props.ai ?? 'center'};
    & p {
        text-overflow: ellipsis; 
        display: block; 
        overflow: hidden; 
        line-height: normal;
    }
`;

export const StatusBox = styledMui(Box, {
    shouldForwardProp: (propName) => (
        !["bgColor"].includes(propName.toString())
    )})(({bgColor, marginTop}:{bgColor: string, marginTop?: string}) => ({
    minWidth: '104px',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 8px',
    borderRadius: '12px',
    backgroundColor: bgColor,
    marginTop,
}));

export const StatusTitle = styled('span')(({color}:{color: string}) => ({
    fontSize: '14px',
    fontWeight: 500,
    color,
}));

export const DescriptionRow = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    gap: '10px'
}));
export const DescriptionRowTitle = styled(Box)(() => ({
    minWidth: '120px',
    width: 'fit-content'
}));
export const RequestTitleSpan = styled("span")(() => ({
    color: colors.text.dark,
}));
