import {useAppDispatch} from "../../../../../../newShared/redux";
import {useSelector} from "react-redux";
import {
    dialogs,
    hideCreateClientDialog,
    kycDictionaries,
    loadings,
    openCreateClientDialog,
    openTemplatesImportCsv,
    templates
} from "../../../store/slice";
import React, {useEffect, useState} from "react";
import {CreateTarget, GetKycTemplates} from "../../../store/actions";
import {KycRefactorTemplateModel} from "../../../../../../newShared/GQLTypes";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {insertClientDataByTemplateRequestType, KycExactDictType} from "../../../types";
import {convertTemplateToFillableTemplate, getTargetTypeForPath, removeTimeZoneDateFields} from "../../../helpers";
import {initialCreateTemplateForm, KYC_CLIENTS_ID_FIELDS_PATH} from "../../../constants";
import {useHistory} from "react-router-dom";


export const useCreateClient = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {isOpen, selectedTemplate} = useSelector(dialogs).createClient;
    const {isOpen: isOpenCsv} = useSelector(dialogs).templatesListImportCsv;
    const {getTemplatesList: isLoadingTemplatesList, createTarget} = useSelector(loadings);
    const templatesList = useSelector(templates);
    const dicts = useSelector(kycDictionaries);
    const [selectTemplatePage, setSelectTemplatePage] = useState<boolean>(true);


    const handleSelectTemplate = (template:  KycRefactorTemplateModel ) => {
        dispatch(openCreateClientDialog({ selectedTemplate: template }));
    }

    const handleUseTemplate = () => {
        setSelectTemplatePage(false);
    }

    const handleBack = () => {
        setSelectTemplatePage(true);
    }

    const handleClose = () => {
        dispatch(hideCreateClientDialog());
    }

    useEffect(() => {
        if (isOpen) {
           !templatesList.length && !isLoadingTemplatesList && dispatch(GetKycTemplates({data: {pageInfo: {page: 0, count: 99999}}}));
           setSelectTemplatePage(true);
        }

        //eslint-disable-next-line
    }, [isOpen]);

    const {setForm, form} = useForm<insertClientDataByTemplateRequestType>(convertTemplateToFillableTemplate(initialCreateTemplateForm));

    useEffect(() => {
        if(selectedTemplate){
            setForm(convertTemplateToFillableTemplate(selectedTemplate));
        }
        //eslint-disable-next-line
    }, [selectedTemplate]);

    const handleChangeCustomField = (event: React.ChangeEvent<HTMLInputElement>) => {
        const id = event.currentTarget.name;
        const value = event.currentTarget.value;
        setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === id ? {...e, value} : e)}))
    }

    const handleChangeDate = (id: string, date: string | null) => {
        setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === id ? {...e, value: date} : e)}))

        // if(date !== null){
        //     try {
        //         const parsed = new Date(Date.parse(date)).toISOString();
        //         setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === id ? {...e, value: parsed} : e)}))
        //     }catch (ex){
        //         setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === id ? {...e, value: null} : e)}))
        //         console.log(`InputClientDataTemplateDialog handleChangeDate ex`, ex)
        //     }
        // }else{
        //     setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === id ? {...e, value: null} : e)}))
        // }
    }

    const isOk = () => {
        // console.log(`form`, form.fields, form.fields.some(e => (e.required && e.value === null) || (e.value !== null && !e.value.trim().length)));
        return !form.fields.some(e => {
            //looking for bad fields
            if(e.required && e.value === null) return true;
            if((e.required && e.value !== null && typeof e.value === 'string' && !e.value.trim().length)) return true;
            if(e.required && e.type === 'FILE' && (e.fileName === null || e.fileName === undefined || (e.fileName.trim().length === 0))) return true;
            if(e.type === 'DATE' && e.value !== null && e.value !== undefined){
                let parsed;
                try {
                    parsed = new Date(Date.parse(e.value)).toISOString();
                }catch (ex){
                    parsed = null;
                }

                return parsed === null;
            }
            return false;
        })
    }

    const handleSubmit = () => {
        if(isOk()){
            const newFields = removeTimeZoneDateFields(form.fields);

            dispatch(CreateTarget({
                data: {
                    fields: newFields,
                    templateId: form.id
                },
                onSuccess: handleGoToTarget}
            ));
        }
    }

    const handleGoToTarget = (id: string) => {
        selectedTemplate && history.push(KYC_CLIENTS_ID_FIELDS_PATH.replace(':type', getTargetTypeForPath(undefined, selectedTemplate.type).pathName).replace(':id', id));
        handleClose();
    }

    //comp - upload file - get fileId - fetch outer func - save in form
    const handleFileUpload = (fileId: string, fieldId: string, fileName: string) => {
        setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === fieldId ? {...e, value: fileId, fileName} : e)}))
    }

    const handleDeleteUploadedFile = (fieldId: string) => {
        setForm((prev) => ({...prev, fields: prev.fields.map(e => e.id === fieldId ? {...e, value: null, fileName: null} : e)}))
    }

    const handleImportCsv = () => {
        selectedTemplate && dispatch(openTemplatesImportCsv(selectedTemplate));
    }

    const handleChangeSelectField = (id: string, value: KycExactDictType | null) => {
        setForm((prev) => ({
            ...prev,
            fields: prev.fields.map(e => e.id === id ? {...e, value: value?.key ?? null} : e)
        }))
    }

    return {
        isOpen,
        isOpenCsv,
        selectedTemplate,
        isLoadingTemplatesList,
        templatesList,
        handleSelectTemplate,
        selectTemplatePage,
        handleUseTemplate,
        handleBack,
        handleClose,
        form,
        handleChangeCustomField,
        handleChangeDate,
        isOk,
        handleFileUpload,
        handleDeleteUploadedFile,
        handleImportCsv,
        isLoading: createTarget,
        handleSubmit,
        dicts,
        handleChangeSelectField
    }
}
