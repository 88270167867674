import {createSelector, createSlice} from "@reduxjs/toolkit";
import {addSetDialogAction} from "../../../../newShared/actions/setDialogAction";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {sendSupportMessageAction} from "./actions";

type TContactUsInitialState = {
    loadings: {
        isLoadingSend: boolean;
    },
    dialogs: {
        contactUsDialog: {
            isOpen: boolean;
        }
    }
}
const initialState: TContactUsInitialState = {
    loadings: {
        isLoadingSend: false,
    },
    dialogs: {
        contactUsDialog: {
            isOpen: false,
        },
    },
}
export const contactUsSlice = createSlice({
    name: 'contactUs',
    initialState,
    reducers: {
        ...addSetDialogAction('contactUsDialog', initialState),
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendSupportMessageAction.pending, (slice) => {
                slice.loadings.isLoadingSend = true;
            })
            .addCase(sendSupportMessageAction.rejected, (slice) => {
                slice.loadings.isLoadingSend = false;
            })
            .addCase(sendSupportMessageAction.fulfilled, (slice) => {
                slice.loadings.isLoadingSend = false;
                slice.dialogs.contactUsDialog = initialState.dialogs.contactUsDialog;
            })

    }
});
export const {
    setContactUsDialogAction
} = contactUsSlice.actions;

export const contactUsReducer = contactUsSlice.reducer;
const selectSelf = (state: AppState): TContactUsInitialState => state.contactUs;
export const dialogs = createSelector(selectSelf, state => state.dialogs);
export const loadings = createSelector(selectSelf, state => state.loadings);