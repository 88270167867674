import React, {FC} from "react";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {ReactComponent as TemplateIcon} from "../../../../../../../newShared/images/documents/template-file.svg";
import * as S from "./styled";
import {Button, Skeleton} from "@mui/material";
import {TShortDocument} from "../../../../types";
import {DocTemplateViewer} from "../../../../../../../newShared/components/docTemplateViewer";
import {useTemplatePreview} from "../../../../../../../newShared/components/docTemplateViewer/hooks/useTemplatePreview";
import {
    FlexRow
} from "../../../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";

interface TemplateGalleryViewProps {
    isLoadingDataForDialog: boolean,
    templatesVariants: TShortDocument[],
    searchTemplate: string,
    setSearchTemplate: (value: string) => void,
    selectedTemplate: TShortDocument | null,
    setSelectedTemplate: (e: React.MouseEvent<HTMLDivElement, MouseEvent> | {detail: number}, template: TShortDocument) => void,
}
export const TemplateGalleryView: FC<TemplateGalleryViewProps> = (props) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});
    const {isLoadingDataForDialog, templatesVariants, searchTemplate, setSearchTemplate, selectedTemplate, setSelectedTemplate,} = props;

    const {handleOpen} = useTemplatePreview();

    return (
        <S.TemplatesWrapper>
            <S.TemplatesTitle>{t('Templates gallery')}</S.TemplatesTitle>
            <S.SearchTemplate value={searchTemplate} onClear={() => setSearchTemplate('')} onChange={(e) => setSearchTemplate(e.target.value)}/>

            <S.TemplatesListContainer>
                {isLoadingDataForDialog && !templatesVariants.length && <Skeleton width={'100%'} />}
                {templatesVariants.map(template => (
                    <S.TemplateItemBox key={template.id} isSelected={selectedTemplate?.id === template.id ? true : undefined} onClick={(e) => setSelectedTemplate(e, template)}>
                        <FlexRow flexGrow={1} alignItems={'center'} overflow={'hidden'}>
                            <TemplateIcon />
                            <S.TemplateIconTitle isSelected={selectedTemplate?.id === template.id ? true : undefined}>{template.name}</S.TemplateIconTitle>
                        </FlexRow>
                        <Button variant={"text"} sx={{}} onClick={() => handleOpen(template.id)}>{t('Preview')}</Button>
                    </S.TemplateItemBox>
                ))}
            </S.TemplatesListContainer>

            <DocTemplateViewer handleUseTemplate={template => setSelectedTemplate({detail: 2}, template as TShortDocument)}/>
        </S.TemplatesWrapper>
    )
}
