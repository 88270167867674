import React, {FC} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {DelegatedTaskModel, FrameworksDelegatedTaskStatus} from "../../../../../newShared/GQLTypes";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useDelegatedTasksPage} from "../../hooks/useDelegatedTasks";
import {DelegatedTaskStatus} from "../delegatedTaskStatus";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import colors from "../../../../../newShared/theme/colors";
import {useCancelDelegatedTask} from "../../hooks/dialogHooks/useCancelDelegatedTask";
import {ExactDelegatedTaskDialog} from "../dialogs/deligatedTasksDialogs/exactDelegatedTask";
import {CancelDelegatedTask} from "../dialogs/cancelDelegatedTaskDialog";
import {Flex} from "../../../../../newShared/components/Layouts";

export const TabDelegatedTasks: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathFrameworks.pathDelegatedTasks'});
    const { isMobile } = useMedia();

    const {tasks, handleOpenTask, genericTable, refreshTable} = useDelegatedTasksPage();
    const {openCancelDelegatedTask} = useCancelDelegatedTask();


    return (
        <>
            <GenericTable<DelegatedTaskModel>
                id={"frameworksDelegatedTasks"}
                columnsConfig={{
                    totalName: "Total tasks",
                    disableColumnsSettings: true,
                    forceShowCardsWhen: isMobile,
                }}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: tasks,
                    getRowUniqueId: (row) => row.id,
                    onRowClick: handleOpenTask,
                    customCellPaddings: '8px',
                    rowActionsConfig: [
                        {
                            title: t('Cancel task'),
                            onClick: (row) => openCancelDelegatedTask(row),
                            icon: <CancelScheduleSendIcon fontSize={'small'} style={{color: colors.primary.blue}}/>,
                            dontShow: (row) => row.status !== FrameworksDelegatedTaskStatus.Created
                        },
                    ],

                    columnsConfigs: [
                        {key: "id", name: t('Task id'), default: true, },
                        {key: "title", name: t('Evidence name'), default: true, valueGetter: (row) => row.title },
                        {key: "requestor", name: t('Requestor'), default: true, valueGetter: (row) => row.requestor.fullName },
                        {key: 'createdDate', name: t('Created date'), default: true, valueGetter: (row) => parseDateAuto(row.createdDate ?? '', undefined, undefined, undefined, '-')},
                        {key: "performer", name: t('Performer'), default: true, valueGetter: (row) => row.performer.fullName },
                        {key: 'performDate', name: t('Performed date'), default: true, valueGetter: (row) => parseDateAuto(row.performDate ?? '', undefined, undefined, undefined, '-')},
                        {key: "resolver", name: t('Resolver'), default: true, valueGetter: (row) => row.resolver?.fullName ?? '-' },
                        {key: 'resolveDate', name: t('Resolved date'), default: true, valueGetter: (row) => parseDateAuto(row.resolveDate ?? '', undefined, undefined, undefined, '-')},
                        {key: 'status', name: t('Status'), default: true, valueRenderer: (row) => <Flex>{DelegatedTaskStatus({status: row.status})}</Flex>},
                    ],
                }}
                filtersConfig={{
                    searchCustomFilterKeyName: "taskIdLike",
                    dontSendSearchIfEmpty: true,
                    genericFilterProps: {
                        configs: genericTable.filters.configs,
                        fetchResultWithSelectedFilters: () => null,
                        collapsable: true,
                    },
                }}
                emptyArrayImageProps={{type: "any", filterNotApplied: {text: t("You do not have any delegated tasks yet"), secondaryText: ""}}}
            />
            <ExactDelegatedTaskDialog
                refreshTable={refreshTable}
            />

            <CancelDelegatedTask />
        </>
    )
}
