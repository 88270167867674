import {FC} from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideDeleteArchivedTarget, loadings} from "../../../store/slice";
import {DeleteKycArchivedTarget} from "../../../store/actions";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {getTargetName} from "../../../helpers";

export const DeleteTargetDialog: FC = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.dialogs'});
    const dispatch = useDispatch();
    const {deleteTarget: isLoading} = useSelector(loadings);
    const {isOpen, target} = useSelector(dialogs).deleteArchivedTarget;

    const handleDelete = () => {
        target && dispatch(DeleteKycArchivedTarget({workspaceId: '', targetId: target.id}));
    }

    const handleClose = () => {
        dispatch(hideDeleteArchivedTarget());
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Delete client')}</DialogTitle>
            <DialogContent>
                <Typography variant={'body2'}>{`${t('Are you sure you want to permanently delete client')} `}<span style={{fontWeight: 600}}>{`${target ? getTargetName(target) : ''}`}</span>?</Typography>
                <Typography variant={'body2'}>{`${t('This action cannot be undone.')}`}</Typography>

            </DialogContent>
            <DialogActions>
                <Button variant={"text"} onClick={handleClose} disabled={isLoading}>{tCommon('Cancel')}</Button>
                <LoadingButton loading={isLoading} onClick={handleDelete}>
                    {tCommon('Delete')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
