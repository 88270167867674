import {FC} from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {DeleteDialogButton} from "../../../../../../newShared/components/deleteDialogButton";
import {useReportDialogs} from "../../../hooks/useReportDialogs";
import {useReports} from "../../../hooks/useReports";

export const DeleteReportDialog: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathReports.Dialogs'});

    const {deleteReport} = useReports();
    const {deleteReport: {
        reportId, name, isOpen, isLoading, setDeleteReportDialog
    }} = useReportDialogs();

    const handleClose = () => {setDeleteReportDialog({clear: true})}
    const handleConfirm = () => {reportId && name && deleteReport(reportId, name)}

    return (
        <DeleteDialogButton isOpen={isOpen} isLoading={isLoading}
                            title={t('Delete report')}
                            message={t("If you delete information about the report '{{name}}', you will not be able to restore it.", {name: name || 'NAME'})}
                            handleConfirm={handleConfirm}
                            handleClose={handleClose}
        />
    )
}
