import {print} from "graphql";
import {gql} from "@apollo/react-hooks";

export const RiskRegistryFragment = print(gql`
    fragment RiskRegistryFragment on RiskRegistryModel {
        id
        name
        context
        model
        totalRisks
        lastUpdate {
            date
            publicId
        }
    }
`)

export const RiskFragment = print(gql`
    fragment RiskFragment on RiskModel {
        id
        name
        type
        description
        
        initialGrade {
            probability
            impact
        }
        control {
            type
            owners
            scheduler
            actions
        }
        residualGrade {
            probability
            impact
        }
    }
`)

export const RiskModelFragment = print(gql`
    fragment RiskModelFragment on RiskModelModel {
        id
        name
        isBasic
        
        probabilities {
            quality
            quantitative
            description
        }
        impacts {
            quality
            quantitative
            description
        }
         
        counter
        left
        right
        workspaceId
    }
`)