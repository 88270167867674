import {createAsyncThunk} from "@reduxjs/toolkit";
import {TWithOptions} from "../../../../../newShared/types";
import {
    DownloadFileQueryVariables,
    KycAnalyseRestartMutationVariables,
    KycCancelRequestByIdMutationVariables,
    KycChangeRequestStatusMutationVariables,
    KycDownloadRequestByIdQueryVariables,
    KycGetRequestByIdQueryVariables,
    KycGetRequestsQueryVariables
} from "../../../../../newShared/GQLTypes";
import {AppState} from "../../../../../newShared/redux/rootReducer";
import {getActionsData} from "../../../../../newShared/redux";
import {
    kycAnalyseRestartApi,
    kycCancelRequestByIdApi,
    kycChangeRequestStatusApi,
    kycDownloadRequestByIdApi,
    kycGetRequestByIdApi,
    kycGetRequestsApi
} from "../../api";
import {downloadEvidenceFileApi} from "../../../frameworks/api";
import {saveAs} from "file-saver";
import {b64toBlob} from "../../../../../newShared/utils/base64/base64";


export const kycGetRequestsAction = createAsyncThunk(
    'KYC/requests/kycGetRequests',
    async (data: TWithOptions<KycGetRequestsQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await kycGetRequestsApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const kycGetRequestByIdAction = createAsyncThunk(
    'KYC/requests/kycGetRequestById',
    async (data: TWithOptions<KycGetRequestByIdQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await kycGetRequestByIdApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const kycChangeRequestStatusAction = createAsyncThunk(
    'KYC/requests/kycChangeRequestStatus',
    async (data: TWithOptions<KycChangeRequestStatusMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await kycChangeRequestStatusApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const kycCancelRequestByIdAction = createAsyncThunk(
    'KYC/requests/kycCancelRequestById',
    async (data: TWithOptions<KycCancelRequestByIdMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await kycCancelRequestByIdApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const kycAnalyseRestartAction = createAsyncThunk(
    'KYC/requests/kycAnalyseRestart',
    async (data: TWithOptions<KycAnalyseRestartMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await kycAnalyseRestartApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const kycGetNotAnalysedRequestsAction = createAsyncThunk(
    'KYC/requests/kycGetNotAnalysedRequests',
    async (_: TWithOptions<{}>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const data: {data: KycGetRequestsQueryVariables} = {data: {
                organizationId: '', workspaceId: '', pageInfo: {page: 0, count: 999999, filters: {notAnalysed: ['true'], status: ['REVIEW']}}
            }};

        const resp = await kycGetRequestsApi({...data.data, organizationId, workspaceId}, _.signal);
        // data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const kycDownloadRequestByIdAction = createAsyncThunk(
    'KYC/requests/kycDownloadRequestById',
    async (data: TWithOptions<KycDownloadRequestByIdQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await kycDownloadRequestByIdApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const downloadFileAction = createAsyncThunk(
    'KYC/requests/downloadRequestFiles',
    async (data: TWithOptions<DownloadFileQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await downloadEvidenceFileApi({...data.data, workspaceId}, data.signal);
        saveAs(b64toBlob(resp.file), resp.filename);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);
