import React, {useEffect, useState} from "react";
import {toBase64} from "../../utils/base64/base64";
import {useSnack} from "../../../newDomain/barsEnvironment/snack/hooks/useSnack";
import {useMainTranslation} from "../useMainTranslationHooks/useMainTranslation";

export const useFileDropZone = (fileLimitInMB: number = 0, handleTooLargeFile?: () => void) => {
    const snack = useSnack();
    const {tCommon} = useMainTranslation()
    const ref = React.createRef<HTMLInputElement>();
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [fileInBase64, setFileInBase64] = useState<{file: string, name: string} | null>(null);

    const fileHandler = (files: FileList | null) => {
        if(files){
            if(fileLimitInMB && files[0].size > fileLimitInMB * 1024 * 1024) {
                if(handleTooLargeFile){
                    handleTooLargeFile();
                }else{
                    snack(tCommon(`File size should be up to {{fileLimitInMB}}MB`, {fileLimitInMB}), "error");
                }
                return;
            }
            setUploadedFile(files[0]);
        } else {
            setUploadedFile(null);
        }
    };

    const handleDeleteFile = () => setUploadedFile(null);

    const filePicker = () => ref.current?.click();

    const makeBase64 = async (file: File) => {
        setFileInBase64({name: file.name, file: await toBase64(file)});
    }

    useEffect(() => {
        if (uploadedFile) makeBase64(uploadedFile)
        else setFileInBase64(null);
    }, [uploadedFile]);

    return{
        filePicker,
        ref,
        fileHandler,
        handleDeleteFile,
        uploadedFile,

        fileInBase64,
    }
}