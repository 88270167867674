import React, {FC} from "react";
import {
    Box,
    Button,
    Icon,
    IconButton,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {DeleteForever, Edit} from "@mui/icons-material";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import colors from "../../../../../newShared/theme/colors";
import {TableRowSkeleton} from "../../../../../newShared/components/tableRowSkeleton";
// import {RemoveFrameworkDialog} from "../../components/dialogs/removeFrameworkDialog";
import {MinimizeInfoWrapper} from "../../../../../newShared/components/minimizeInfoWrapper";
import {PageWrapper} from "../../../../../newShared/components/Layouts";
import {useFrameworkExact} from "../../hooks/useFrameworkExact";
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import EditIcon from "@mui/icons-material/Edit";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import {EditFrameworkDialog} from "../../components/dialogs/frameworks/editFramework";
import {RemoveFrameworkDialog} from "../../components/dialogs/frameworks/removeFrameworkDialog";
import {AddControlDialog} from "../../components/dialogs/controls/addControlDialog";
import {EditControlDialog} from "../../components/dialogs/controls/editControlDialog";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {TableCardSkeleton} from "../../../../../newShared/components/tableCardSkeleton";
import {GenericFiltersArray} from "../../../../../newShared/components/genericFilter";

export const FrameworkExact: FC = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'builderPage'});
    const {isLessThen1050} = useMedia();

    const {
        frameworkId,
        framework,
        _controls,

        // filter,
        // isLoading,

        isOwn,

        // handleKeyDown, handleChange, searchValue, setFilter,

        goToFrameworks,
        goToFrameworkControl,

        handleOpenEditFrameworkDialog,
        handleOpenDeleteFramework,
        openEditFrameworkControlDialog,
        handleOpenAddFrameworkControlDialog,

        handleDeattachControl,

        isLoadingControls,
        isEmpty,
        currentSearchForFetch
        // handleThreeDotsOpen, handleThreeDotsClose, anchorThreeDotsEl,
    } = useFrameworkExact();

    return (
        <PageWrapper>

            <PageTitleAndActions
                title={framework?.name}
                icon={framework?.logo && <img src={framework.logo} alt={'logo'} style={{maxWidth: '40px', maxHeight: '40px'}}/>}
                handleGoBack={goToFrameworks}
                showMenuWhen={isLessThen1050}
                actions={[
                    {
                        title: t('Edit information'),
                        onClick: () => framework && handleOpenEditFrameworkDialog(framework),
                        variant: 'text',
                        icon: <Edit style={{margin: revDir ? '0 0 0 9px' : undefined}}/>,
                        disabled: isLoadingControls || !framework
                    },
                    {
                        title: t('Remove framework'),
                        onClick: () => {frameworkId && handleOpenDeleteFramework(frameworkId)},
                        variant: 'text',
                        icon: <DeleteForever/>,
                        disabled: isLoadingControls || !framework
                    },
                ]}
            />

            {/*<Breadcrumbs/>*/}
            {/*<Box display={"flex"} alignItems={"center"} sx={{mt: '8px'}}>*/}
            {/*    {isLoadingControls && (!framework || framework.id !== frameworkId)*/}
            {/*        ? (*/}
            {/*            <>*/}
            {/*                <Skeleton variant={"rounded"} width={'40px'} height={'40px'}/>*/}
            {/*                <Typography variant={!matches_1440Down ? "h1" : 'h3'} margin={!revDir ? '0 32px 0 8px' : '0 8px 0 32px'}><Skeleton width={'200px'}/></Typography>*/}
            {/*            </>*/}
            {/*        ) : (*/}
            {/*            <>*/}
            {/*                {framework?.logo && <img src={framework.logo} alt={'logo'} style={{maxWidth: '40px', maxHeight: '40px'}}/>}*/}
            {/*                <Typography variant={!matches_1440Down ? "h1" : 'h3'} margin={!revDir ? '0 32px 0 8px' : '0 8px 0 32px'}>{framework?.name || ''}</Typography>*/}
            {/*            </>*/}
            {/*        )*/}
            {/*    }*/}
            {/*    {!isLessThen1050 ? (framework && framework.id === frameworkId) && (*/}
            {/*        <>*/}
            {/*            <Button sx={{margin: '0 8px'}} variant={"text"} startIcon={<Edit style={{margin: revDir ? '0 0 0 9px' : undefined}}/>} size={matches_1440Down ? 'medium' : undefined}*/}
            {/*                    onClick={() => handleOpenEditFrameworkDialog(framework)}>*/}
            {/*                {t('Edit information')}*/}
            {/*            </Button>*/}
            {/*            <Button sx={{margin: '0 8px'}} variant={"text"} startIcon={<DeleteForever/>} size={matches_1440Down ? 'medium' : undefined}*/}
            {/*                    onClick={() => {frameworkId && handleOpenDeleteFramework(frameworkId)}}>*/}
            {/*                {t('Remove framework')}*/}
            {/*            </Button>*/}
            {/*        </>*/}
            {/*    ) : framework && framework.id === frameworkId && (*/}
            {/*        <>*/}
            {/*            <IconButton*/}
            {/*                onClick={handleThreeDotsOpen}*/}
            {/*                size={"large"}*/}
            {/*                sx={{*/}
            {/*                    color: Boolean(anchorThreeDotsEl) ? colors.primary.blue : colors.text.grey_dark,*/}
            {/*                    margin: !revDir ? '0 0 0 auto' : '0 auto 0 0'*/}
            {/*                }}*/}
            {/*            >*/}
            {/*                <MoreVert/>*/}
            {/*            </IconButton>*/}
            {/*            <Menu*/}
            {/*                keepMounted*/}
            {/*                anchorEl={anchorThreeDotsEl}*/}
            {/*                anchorOrigin={{*/}
            {/*                    vertical: 'bottom',*/}
            {/*                    horizontal: 'right',*/}
            {/*                }}*/}
            {/*                transformOrigin={{*/}
            {/*                    vertical: 'top',*/}
            {/*                    horizontal: 'right',*/}
            {/*                }}*/}
            {/*                open={Boolean(anchorThreeDotsEl)}*/}
            {/*                onClose={handleThreeDotsClose}*/}
            {/*            >*/}
            {/*                <MenuItem onClick={() => handleOpenEditFrameworkDialog(framework)}>*/}
            {/*                    {t('Edit information')}*/}
            {/*                </MenuItem>*/}
            {/*                <MenuItem onClick={() => {frameworkId && handleOpenDeleteFramework(frameworkId)}} >*/}
            {/*                    {t('Remove framework')}*/}
            {/*                </MenuItem>*/}
            {/*            </Menu>*/}
            {/*        </>*/}
            {/*    )}*/}
            {/*</Box>*/}

            <MinimizeInfoWrapper margin={'16px 0 0'}>
                {isLoadingControls && (!framework || framework.id !== frameworkId) ? (<>
                    <Typography variant={"body2"} color={colors.text.grey_dark} maxWidth={'800px'} margin={'6px 0 8px'}>
                        <Skeleton/>
                    </Typography>

                    <Typography variant={"body1"} color={colors.text.grey_dark} mt={'16px'}>
                        <Skeleton width={'100px'}/>
                    </Typography>
                    <Typography variant={"body1"} color={colors.text.grey_dark} mt={'16px'}>
                        <Skeleton width={'100px'}/>
                    </Typography>
                    <Typography variant={"body1"} color={colors.text.grey_dark} mt={'16px'}>
                        <Skeleton width={'100px'}/>
                    </Typography>
                </>) : (<>
                    <Typography variant={"body2"} color={colors.text.grey_dark} maxWidth={'800px'} margin={'6px 0 8px'} lineHeight={'22px'}>
                        {framework?.description || ''}
                    </Typography>

                    <Typography variant={"body1"} color={colors.text.grey_dark} mt={'16px'}>
                        {t('Type')}
                        <Typography variant={"body1"} color={colors.text.dark} m={'0 8px'} component={"span"}>
                            {framework?.type || ''}
                        </Typography>
                    </Typography>
                    <Typography variant={"body1"} color={colors.text.grey_dark} mt={'16px'}>
                        {t('Controls')}
                        <Typography variant={"body1"} color={colors.text.dark} m={'0 8px'} component={"span"}>
                            {framework?.controls || ''}
                        </Typography>
                    </Typography>

                </>)}
            </MinimizeInfoWrapper>

            {/*<SearchField onClear={() => {setFilter('input'); handleChange({target: {value: ''}})}}*/}
            {/*             value={searchValue} onKeyDown={handleKeyDown} onChange={handleChange} disabled={isLoading || isLoadingControls}*/}
            {/*             sx={{marginTop: '16px', maxWidth: !isMobile ? '320px' : undefined}}/>*/}

            <GenericFiltersArray
                configs={[]}
                fetchResultWithSelectedFilters={() => null}
                isAddFilters={false}
            />

            <Button startIcon={<LibraryAddOutlinedIcon/>} variant={'text'} size={'small'}
                    sx={{mt: '16px', alignSelf: 'start'}}
                    onClick={(e) => frameworkId && handleOpenAddFrameworkControlDialog(e, frameworkId)}>
                {t('Add control')}
            </Button>

            <TableContainer>
                <Table stickyHeader>
                    { isLessThen1050 ?
                        <TableBody>
                            { _controls
                                .filter(e => e.name.trim().toLowerCase().includes(currentSearchForFetch.trim().toLowerCase()) || e.category.trim().toLowerCase().includes(currentSearchForFetch.trim().toLowerCase()))
                                .map((control) => (
                                    <TableRow hover key={control?.id} onClick={() => {frameworkId && control.id && goToFrameworkControl(frameworkId, control.id)}}>
                                        <TableCell style={{ padding: 0, borderBottom: 'none' }}>
                                            <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} sx={{borderRadius: '8px', m: '6px', p: '8px', boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)'}}>
                                                <Typography variant={'body2'}>{control?.name}</Typography>
                                                <Box display={'flex'} >
                                                    <Typography variant={'body2'} sx={{color: colors.lightGrayText, whiteSpace: 'nowrap'}}>{t('Control category')}</Typography>
                                                    <Typography variant={'body2'} sx={{color: colors.darkText, ml: '6px', mr: '6px'}}>{control?.category || ''}</Typography>
                                                </Box>
                                                <Box display={'flex'} >
                                                    <Typography variant={'body2'} sx={{color: colors.lightGrayText, whiteSpace: 'nowrap'}}>{t('Number of evidence')}</Typography>
                                                    <Typography variant={'body2'} sx={{color: colors.darkText, ml: '6px', mr: '6px'}}>{control?.evidences?.length || ''}</Typography>
                                                </Box>
                                                <Box display={'flex'} >
                                                    <Typography variant={'body2'} sx={{color: colors.lightGrayText, whiteSpace: 'nowrap'}}>{t('Attached frameworks')}</Typography>
                                                    <Typography variant={'body2'} sx={{color: colors.darkText, ml: '6px', mr: '6px'}}>{control?.foundInFrameworksCounter}</Typography>
                                                </Box>
                                                <Box display={'flex'} alignSelf={'flex-end'}>
                                                    {isOwn(control.organizationId || '') && <Button size={'small'} variant={'text'} sx={{ml: '6px', mr: '6px', textTransform: 'none'}} startIcon={<EditIcon/>} onClick={(e) => openEditFrameworkControlDialog(control.id || '', e)}>{t('Edit')}</Button>}
                                                    <Button size={'small'} variant={'text'} startIcon={<LinkOffIcon/>} sx={{textTransform: 'none'}} onClick={(e) => handleDeattachControl(e, control.id || '')} >{t('Detach')}</Button>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }

                            {isLoadingControls && <TableCardSkeleton rows={4}/>}
                            {isEmpty && <Typography variant={"body1"} sx={{margin: '16px'}}>{currentSearchForFetch.trim().length > 0 ? ('Nothing was found for your query') : t('You have not added any controls yet.')}</Typography> }
                        </TableBody>
                        :
                        <>
                            <TableHead>
                                <TableRow>
                                    <TableCell align={revDir ? "right" : 'left'}>{t('Name')}</TableCell>
                                    <TableCell align={revDir ? "right" : 'left'}>{t('Control category')}</TableCell>
                                    <TableCell align={revDir ? "right" : 'left'}>{t('Number of evidence')}</TableCell>
                                    <TableCell align={revDir ? "right" : 'left'}>{t('Attached frameworks')}</TableCell>
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!isLoadingControls && _controls
                                    .filter(e => e.name.trim().toLowerCase().includes(currentSearchForFetch.trim().toLowerCase()) || e.category.trim().toLowerCase().includes(currentSearchForFetch.trim().toLowerCase()))
                                    .map((control, index) => control ? (
                                        <TableRow key={control.id} onClick={() => {frameworkId && control.id && goToFrameworkControl(frameworkId, control.id)}} hover>
                                            <TableCell align={revDir ? "right" : 'left'}>{control.name}</TableCell>
                                            <TableCell align={revDir ? "right" : 'left'}>{control.category || ''}</TableCell>
                                            <TableCell align={revDir ? "right" : 'left'}>{control?.evidences?.length}</TableCell>
                                            <TableCell align={revDir ? "right" : 'left'}>{control?.foundInFrameworksCounter}</TableCell>
                                            <TableCell sx={{padding: '0'}} >
                                                <Box display={"flex"}>
                                                    {isOwn(control.organizationId || '') ? (
                                                        <IconButton size={"medium"} onClick={(e) => openEditFrameworkControlDialog(control.id || '', e)}>
                                                            <EditIcon/>
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton size={"medium"} disabled>
                                                            <Icon/>
                                                        </IconButton>
                                                    )}
                                                    <IconButton size={"medium"} onClick={(e) => handleDeattachControl(e, control.id || '')} >
                                                        <LinkOffIcon/>
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ) : <TableRowSkeleton key={'loader' + index}/>)}

                                {isEmpty && <Typography variant={"body1"} sx={{margin: '16px'}}>{currentSearchForFetch.trim().length > 0 ? ('Nothing was found for your query') : t('You have not added any controls yet.')}</Typography> }

                                {isLoadingControls && <TableRowSkeleton columns={5}/>}
                            </TableBody>
                        </>

                    }
                </Table>
            </TableContainer>

            <AddControlDialog />
            <EditControlDialog />
            <EditFrameworkDialog />
            <RemoveFrameworkDialog />
        </PageWrapper>
    )
}