import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideChangeNameDialog, isLoading, selectedFiles, selectedFolders} from "../../../store/slice";
import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {RenameItem} from "../../../store/actions";
import {CustomInput} from "../../../../../../newShared/components/Inputs";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";


export const ChangeNameDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});
    const dispatch = useDispatch();
    const _selectedFolders = useSelector(selectedFolders);
    const _selectedFiles = useSelector(selectedFiles);
    const {isOpen, folder} = useSelector(dialogs).changeNameDialog;
    const isLoadingRename = useSelector(isLoading).isLoadingRename;

    const [name, setName] = useState<string>('');
    const [type, setType] = useState<'folder' | 'file'>('file');

    const handleClose = () => {
        dispatch(hideChangeNameDialog());
        setName('');
    }

    useEffect(() => {
        if(isOpen){
            if(_selectedFiles.length === 1){
                setName(_selectedFiles[0].name);
                setType('file');
            }else if(_selectedFolders.length === 1 || folder){
                // setName(_selectedFolders[0].name);
                folder && setName(folder.name);
                setType('folder');
            }else{
                handleClose();
            }
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const isOk = () => {
        return Boolean(name.trim().length > 0 && folder?.name.trim() !== name.trim())

        // if(_selectedFiles.length === 1){
        //     return name.trim().length > 0 && _selectedFiles[0].name.trim() !== name.trim();
        // }else if(folder){
        //     return name.trim().length > 0 && folder.name.trim() !== name.trim();
        // }
        //
        // return false;
    }

    const submit = () => {
        if(isOk()){
            dispatch(RenameItem({
                itemType: type,
                itemName: name,
                // itemId: type === "file" ? (_selectedFiles[0]?.fileId ?? '') : (_selectedFolders[0]?.folderId ?? '')
                itemId: folder?.id ?? ''
            }));
        }
    }

    return(
        <Dialog onClose={handleClose} open={isOpen} fullWidth maxWidth={'sm'}>
            <DialogTitle>{t('Update {{{type}} name', {type})}</DialogTitle>

            <DialogContent sx={{margin: '5px 0 0 0'}}>
                <CustomInput
                    value={name}
                    onChange={(e) => {setName(e.currentTarget.value as string)}}
                    title={t(`{{type}} name`, {type})}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} disabled={isLoadingRename}>{t('Cancel')}</Button>
                <LoadingButton loading={isLoadingRename} onClick={submit} disabled={!isOk()}>{t('Update')}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
