import {Flex} from "../../../../../newShared/components/Layouts";
import {useAccessManagementAppsList} from "../../hooks/useAccessManagementAppsList";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {AddApplicationDialog} from "../../components/dialogs/addApplication";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {AccessManagementApp} from "../../types";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";

export const AccessManagementAppsList = () => {
    const {
        apps,
        actions,
        genericTable
    } = useAccessManagementAppsList();
    const {isMobile} = useMedia(858);
    const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.AccessManagementAppsList'});

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('Applications')}
                actions={[
                    {
                        title: t('Add application'),
                        onClick: actions.handleOpenAddApp,
                        icon: <AddIcon />,
                        variant: 'text'
                    }
                ]}
            />
            {/*<Breadcrumbs/>*/}
            {/*<Flex m={isMobile ? '11px 0 0 0' : '0'} ai={'center'}>*/}
            {/*    <Typography variant={matches_1440Down ? 'h3' : "h1"} fontWeight={matches_1920Up ? 700 : 400}  sx={{margin: !revDir ? '0 32px 0 11px' : '0 11px 0 32px', color: colors.backgrounds.dark}}>{('Applications')}</Typography>*/}
            {/*    <Button onClick={actions.handleOpenAddApp} variant="text" startIcon={<AddIcon fontSize={'small'}/>}>*/}
            {/*        {('Add application')}*/}
            {/*    </Button>*/}
            {/*</Flex>*/}

            <GenericTable<AccessManagementApp>
                id={'AccessManagementAppsList'}
                columnsConfig={{totalName: 'Total applications', disableColumnsSettings: true, forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: apps,
                    customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id,
                    onRowClick: genericTable.onRowClick,
                    columnsConfigs: [
                        {key: "name", name: t('Application'), default: true},
                        {key: 'accounts', name: t('Accounts'), default: true, valueGetter: (row) => row.dataTable.length.toString()},
                        {key: 'lastUpdate', name: t('Last update'), default: true, valueGetter: (row) => parseDateAuto(row.lastUpdateDate)},
                        {key: 'lastReview', name: t('Last review'), default: true, valueGetter: (row) => parseDateAuto(row.lastReviewDate)},
                    ],
                }}
                filtersConfig={{}}
                emptyArrayImageProps={{type: 'accessManagement', filterNotApplied: {secondaryText: 'You have not added any applications yet. Create new one.', text: 'No applications found', onClick: actions.handleOpenAddApp}}}
            />

            {/*<EmptyArrayImage*/}
            {/*    type={'accessManagement'}*/}
            {/*    isShowing={isNoAppsFound}*/}
            {/*    text={t('No applications found')}*/}
            {/*    secondaryText={('You have not added any applications yet. Create new one.')}*/}
            {/*    onClick={actions.handleOpenAddApp}*/}
            {/*/>*/}

            {/*{!isNoAppsFound &&*/}
            {/*    <>*/}
            {/*        {!isLessThenCustomQuery && <ApplicationsTableHeader />}*/}
            {/*        <S.MainTableScrollWrapper>*/}
            {/*            <InfiniteScroll*/}
            {/*                pageStart={0}*/}
            {/*                loadMore={(page) => actions.handleLoadMore(page)}*/}
            {/*                hasMore={hasMore}*/}
            {/*                initialLoad={false}*/}
            {/*                threshold={50}*/}
            {/*                useWindow={false}*/}
            {/*                style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}*/}
            {/*            >*/}
            {/*                {*/}
            {/*                    isLessThenCustomQuery ?*/}
            {/*                        <>*/}
            {/*                            {apps.map(e => <ApplicationMobileCard key={e.id} app={e}/>)}*/}
            {/*                            /!*HELLO*!/*/}
            {/*                        </>*/}
            {/*                        :*/}
            {/*                        <>*/}
            {/*                            {apps.map(e => <ApplicationTableTow key={e.id} app={e}/>)}*/}
            {/*                        </>*/}
            {/*                }*/}
            {/*                {isLoading && (isLessThenCustomQuery ? <ApplicationMobileCardSkeleton /> : <ApplicationTableRowSkeleton />)}*/}
            {/*            </InfiniteScroll>*/}
            {/*        </S.MainTableScrollWrapper>*/}
            {/*    </>*/}
            {/*}*/}

            {/*DIALOGS*/}
            <AddApplicationDialog/>
        </Flex>
    )
}