import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {CommonRadio} from "../../../../../../../newShared/components/Basic/CommonRadio";
import {useAddControl} from "../../../../hooks/dialogs/useAddControl";
import {SearchField} from "../../../../../../../newShared/components/Basic/CommonInputs";
import {getControlStatus} from "../../../../helpers";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import colors from "../../../../../../../newShared/theme/colors";
import {TableRowSkeleton} from "../../../../../../../newShared/components/tableRowSkeleton";
import {LoadingButton} from "@mui/lab";
import {LABEL} from "../../../../../../../newShared/constants";


export const AddControlDialog = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'builderPage.dialogs'});

    const {
        isOpen,
        form,
        isExisting,
        handleClose,
        validateForm,
        handleSave,
        category,
        getTitle,
        handleChangeCategory,
        categoryVariants,
        search,
        handleChangeSearch,
        handleKeyDown,
        handleClearSearch,
        _controls,
        _fetchedControls,
        organizationId,
        isLoading,
        handleSetCreateOwnControl,
        handleChangeDesc,
        handleChange,
        handleSetUseExisting,
        handleAddControl,
        handleDeattachControl,
        isShowingNoControls,
        pagination: {
            bottomLoader,
            onScroll,
            scrollRef
        }

    } = useAddControl();

    return(
        <Dialog
            open={isOpen}
            onClose={handleClose}
        >
            <DialogTitle>{t('Add control')}</DialogTitle>
            <DialogContent sx={{overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
               <Typography variant={'body2'} >{t('What type of control do you want to add?')}</Typography>
               <Box display={'flex'} width={'100%'} alignItems={'center'} sx={{ml: '-8px'}}>
                   <CommonRadio size={"small"} checked={!isExisting}  onClick={handleSetCreateOwnControl}/>
                   <Typography variant={'body2'} >{t('Create your own')}</Typography>
                   <CommonRadio size={"small"} checked={isExisting} onClick={handleSetUseExisting}/>
                   <Typography variant={'body2'} >{t('Use existing')}</Typography>
               </Box>


                { isExisting ? (
                        <>
                            <TextField select fullWidth
                                       size={'small'}
                                       value={category}
                                       label={t('Control category')}
                                       sx={{mt: '8px', '& label': {top: '0 !important'}}}
                                       onChange={(e) => handleChangeCategory(e.target.value)}
                            >
                                {categoryVariants.map(e => <MenuItem key={e} value={e}>{getTitle(e)}</MenuItem>)}
                            </TextField>

                            <SearchField
                                sx={{mt: '12px', '& label': {top: '0 !important'}}}
                                fullWidth
                                size={'small'}
                                value={search}
                                onClear={handleClearSearch}
                                label={t('Search')}
                                onChange={handleChangeSearch}
                                onKeyDown={handleKeyDown}
                            />

                            <Typography variant={'body2'} sx={{mt: '12px'}} >{t('You can add one or more controls.')}</Typography>

                            <TableContainer onScroll={onScroll} ref={scrollRef} sx={{overflowY: isShowingNoControls ? 'hidden' : undefined}} className={'withoutBorder'}>
                                <Table >
                                    <TableBody>
                                        {_fetchedControls.map((e) => e && (
                                            <TableRow key={e.id} >
                                                <TableCell align={revDir ? 'right' : 'left'} sx={{maxWidth: '400px'}} >
                                                    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                                                        <Typography noWrap variant={'body1'}>{e.name}</Typography>
                                                        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} sx={{mt: '8px'}}>
                                                            <Typography fontWeight={'400'} fontSize={'12px'}>{t(getControlStatus(e, organizationId), {companyName: LABEL})} </Typography>
                                                            <Typography fontWeight={'400'} fontSize={'12px'}>{`${t('Category')} ${e.category}`} </Typography>
                                                            <Typography fontWeight={'400'} fontSize={'12px'}>{`${t('Evidences')}  ${e?.evidences?.length || 0}`} </Typography>
                                                        </Box>

                                                    </Box>
                                                </TableCell>
                                                <TableCell width={'140px'}  align={'center'}>
                                                    {_controls.some(c => c.id === e.id) ? //isAdded
                                                        <Button size={'small'} disabled={isLoading} variant={'text'} sx={{color: colors.text.grey}} onClick={(event) => handleDeattachControl(event,e.id || '')} endIcon={<DeleteIcon sx={{color: colors.text.grey}} />}>{t('Added')}</Button>
                                                        :
                                                        <Button size={'small'} disabled={isLoading} variant={'text'} onClick={() => handleAddControl(e.id || '')} startIcon={<AddIcon />}>{t('Add')}</Button>
                                                    }

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {(bottomLoader || isLoading) && <TableRowSkeleton columns={2} />}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    ) : (
                    <>
                        <TextField id="filled-basic" label={t("Name")} variant="outlined" required
                                   sx={{mt: '12px', '& label': {top: '0 !important'}}} size={'small'} name={'name'}
                                   value={form.name} onChange={handleChange}/>

                        <TextField id="filled-basic" label={t("Group")} variant="outlined"
                                   sx={{mt: '12px', '& label': {top: '0 !important'}}} size={'small'}
                                   name={'group'} value={form.group} onChange={handleChange}/>

                        <TextField id="filled-basic" label={t("Category")}
                                   variant="outlined" sx={{mt: '12px', '& label': {top: '0 !important'}}} size={'small'}
                                   name={'category'} value={form.category} onChange={handleChange}/>

                        <TextField id="filled-basic" label={t("Description")}
                                   variant="outlined" multiline minRows={5}
                                   sx={{mt: '12px'}} value={form.description[0] || ''} onChange={handleChangeDesc}/>
                    </>
                )}
            </DialogContent>

            <DialogActions>
                <Button variant={'text'} sx={{'&.MuiButton-text': {textTransform: 'none'}}} onClick={handleClose}>{isExisting ? t('Close') : t('Cancel')}</Button>
                {!isExisting &&
                    <LoadingButton size={'medium'} variant={'contained'}
                                   disabled={!validateForm() || isLoading}
                                   onClick={handleSave} loading={isLoading }
                                   endIcon={isLoading ? <div style={{width: '10px', height: '10px', marginLeft: revDir ? '12px' : undefined, marginRight: !revDir ? '12px' : undefined }}/> : undefined}
                                   loadingPosition={isLoading ? 'end' : undefined}
                    >
                        {t('Save')}
                    </LoadingButton> }

            </DialogActions>
        </Dialog>
    )
}
