import {useDispatch, useSelector} from "react-redux";
//import {contentWithEditor, contentWithFile, contentWithVideoFile, contentWithVideoLink} from "../../../helpers";
import {
    autocompleteStorage,
    loadings,
    setTrainingPreviewAction,
    templatesSelector,
    trainingsSelector
} from "../../../store/slice";
import {createEditTrainingCategoriesAutocompleteCount, initialTrainingForm,} from "../../../constants";
import * as yup from "yup";
import {t} from "i18next";
import {useCallback, useState} from "react";
import {localCreateEditTraining} from "../../../types";
import {
    createTrainingAction,
    mainTrainingsChangeTrainingNameAction,
    mainTrainingsGetTrainingsCategoriesAutocompleteAction,
    updateTrainingAction
} from "../../../store/actions";
import {
    Exact,
    MainCreateTrainingInputDto,
    MainTrainingContentType,
    MainTrainingsSectionsFullDataForImportModel,
    TrainingContentNewModel,
    TrainingCoverImageModel,
} from "../../../../../../newShared/GQLTypes";
import {useCustomFormik} from "../../../../../../newShared/hooks/useCustomFormik";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {TApolloErrors} from "../../../../../../newShared/utils/asyncThunk/types";
import {uuid} from "../../../../../../newShared/utils";
import {useTrainingsDialogs} from "../../useTrainingsDialogs";
import {mapTemplateToTrainingType} from "../../../helpers";

export const useCreateEditTraining = ({handleClose, isOpen}: {handleClose: () => void, isOpen: boolean}) => {
    //root
    const dispatch = useDispatch();
    const {setMessage} = useMessageDialog();

    //selectors
    const {selectedTraining} = useSelector(trainingsSelector);
    const {selectedTemplate} = useSelector(templatesSelector);
    const {trainingCategories} = useSelector(autocompleteStorage);
    const {getTrainingCategories, trainingCreate, trainingUpdate, changeTrainingName} = useSelector(loadings);

    const form = selectedTraining || mapTemplateToTrainingType(selectedTemplate) || initialTrainingForm;
    const isCreateMode = form.id === null || form.id === '';

    const validationSchema = yup.object({
        name: yup.string()
            .required(t('Name is required'))
            .min(0, t('Name is required'))
            .max(120, 'Training name cannot be longer than 120 characters'),
        
        category: yup.string()
            .nullable()
            .required(t('Training category is required')),
        level: yup.string().required('Level is required'),
        description: yup.string().max(1000),
        limitMinDuration: yup.number().min(0),
        limitMaxDuration: yup.number().min(0),
        content: yup.array(yup.object({
            name: yup.string()
                .required(t('Name is required'))
                .min(0, t('Name is required'))
                .max(120, 'Section name cannot be longer than 120 characters')
                .test('name', 'Current training already has section with same name. Enter unique section name for this training', function (value) {
                    const training = this.options.context as localCreateEditTraining | undefined;
                    const sections = training?.content ?? []
                    return value !== undefined && sections.filter(e => e.name.trim().toLowerCase() === value.trim().toLowerCase()).length === 1
                }),
            type: yup.string().oneOf([MainTrainingContentType.VideoLink, MainTrainingContentType.VideoFile, MainTrainingContentType.File, MainTrainingContentType.Editor]),
            data: yup.object()
                .when('type', {
                    is: MainTrainingContentType.VideoLink,
                    then: yup.object({
                        url: yup.string().required('Link is required')
                    })
                }).when('type', {
                    is: MainTrainingContentType.VideoFile,
                    then: yup.object({
                        fileId: yup.string().required(),
                        name: yup.string().required()
                    })
                }).when('type', {
                    is: MainTrainingContentType.File,
                    then: yup.object({
                        fileId: yup.string().required(),
                        name: yup.string().required()
                    })
                }).when('type', {
                    is: MainTrainingContentType.Editor,
                    then: yup.object({
                        innerHtml: yup.string().required(),
                    })
                }),
            order: yup.number().min(0)
        })).min(1, 'At least one section is required')
    });

    const formik = useCustomFormik<localCreateEditTraining>(isOpen, {
        initialValues: form,
        validationSchema,
        onSubmit: (values, formikHelpers) => {
            if(isCreateMode){
                handleCreateTraining(values);
            }else{
                handleUpdateTraining(values);
            }
        },
    })


    //SUBMIT
    const handleCreateTraining = (values: localCreateEditTraining) => {
        delete values.id;
        delete values.trainingId;
        delete values.version;
        delete values.creator;
        delete values.editor;
        delete values.status;
        delete values.createdDate;
        delete values.updatedDate;
        delete values.latest;

        dispatch(createTrainingAction({
            data: {data: {...values, category: values.category ?? ''}},
            onSuccess: onCreateTrainingSuccess,
            onError: onCreateTrainingError
        }))
    }

    const onCreateTrainingSuccess = (request: (Omit<Exact<{workspaceId: string, data: MainCreateTrainingInputDto}>, "workspaceId" | "organizationId"> & {workspaceId?: string | undefined, organizationId?: string | undefined}), response: {}, addictiveData?: ({} | undefined)) => {
        handleClose();
        setMessage({title: 'Completed successfully!', message: 'Training has been created! You can find it in the "Trainings"'})
    }

    const onCreateTrainingError = (request: (Omit<Exact<{workspaceId: string, data: MainCreateTrainingInputDto}>, "workspaceId" | "organizationId"> & {workspaceId?: string | undefined, organizationId?: string | undefined}), error: TApolloErrors, addictiveData?: ({} | undefined)) => {
        const error409 = error.e409?.[0];
        if(error409?.type === 'DUPLICATE_NAME'){
            setMessage({title: ('Non unique training name error'), message: (`You already have training with same name. Names for training should be unique.`)});
        }
        if(error409?.type === 'DUPLICATE_SECTION'){
            setMessage({title: ('Non unique section name error'), message: (`Current training has duplicated sections. Enter unique sections names for this training`)});
        }
        // if(error409?.type === 'EXAM_NOT_EXISTS'){
        //     setMessage({title: ('Exam not found'), message: (`Exam ${request.data.exam?.name ?? ''} does not exists.`)});
        //     //todo erase exams value + autocomplete variants
        // }
        // if(error409?.type === 'EXAM_CHANGED'){
        //     setMessage({title: ('Exam has changed'), message: (`Exam ${request.data.exam?.name ?? ''} has changed.`)});
        //     //todo erase exams value + autocomplete variants
        // }
    }

    const handleUpdateTraining = (values: localCreateEditTraining) => {
        // delete values.id;
        // delete values.trainingId;
        // delete values.version;
        // delete values.creator;
        // delete values.editor;
        // delete values.status;
        // delete values.createdDate;
        // delete values.updatedDate;
        // delete values.latest;

        dispatch(updateTrainingAction({
            data: {data: {...values,
                    category: values.category ?? '',
                    createdDate: values.createdDate!,
                    creator: values.creator!,
                    id: values.id!,
                    trainingId: values.trainingId!,
                    latest: values.latest!,
                    status: values.status!,
                    version: values.version!,
            }},
            onSuccess: onUpdateTrainingSuccess,
            onError: onUpdateTrainingError
        }))
    }
    const onUpdateTrainingSuccess = (request: (Omit<Exact<{workspaceId: string, data: MainCreateTrainingInputDto}>, "workspaceId" | "organizationId"> & {workspaceId?: string | undefined, organizationId?: string | undefined}), response: {}, addictiveData?: ({} | undefined)) => {
        handleClose();
        setMessage({title: 'Completed successfully!', message: 'Training has been updated!'})
    }

    const onUpdateTrainingError = (request: (Omit<Exact<{workspaceId: string, data: MainCreateTrainingInputDto}>, "workspaceId" | "organizationId"> & {workspaceId?: string | undefined, organizationId?: string | undefined}), error: TApolloErrors, addictiveData?: ({} | undefined)) => {
        const error409 = error.e409?.[0];
        if(error409?.type === 'DUPLICATE_SECTION'){
            setMessage({title: ('Non unique section name error'), message: (`Current training has duplicated sections. Enter unique sections names for this training`)});
        }
    }

    const handlePreviewTraining = () => {
        dispatch(setTrainingPreviewAction({
            isOpen: true,
            training: {
                ...formik.values,
                id: formik.values.id ?? undefined,
                trainingId: formik.values.trainingId ?? undefined,
                category: formik.values.category ?? undefined,
                creator: formik.values.creator ?? undefined,
                exam: formik.values.exam ?? undefined,
                level: formik.values.level ?? undefined,
                createdDate: formik.values.createdDate ?? undefined,
                latest: formik.values.latest ?? undefined,
                status: formik.values.status ?? undefined,
                version: formik.values.version ?? undefined,
            }}))
    }


    //Checkboxes
    const onLimitMinimumTrainingDurationCheckboxClick = () => {
        formik.setFieldValue('limitMinDuration', formik.values.limitMinDuration === 0  ? 1 : 0);
    }

    const onLimitMaximumTrainingDurationCheckboxClick = () => {
        formik.setFieldValue('limitMaxDuration', formik.values.limitMaxDuration === 0 ? 1 : 0);
    }

    const handleCreateNewSection = () => {setAddSectionDialog(true)}

    const addExistingSections = useTrainingsDialogs().addExistingSections;

    const handleUseSection = () => {
        addExistingSections.setIsOpen(true);
    }

    //addSectionDialog
    const [addSectionDialog, setAddSectionDialog] = useState<boolean>(false);
    const handleCloseAddSectionDialog = () => setAddSectionDialog(false);

    const handleCreateEditor = () => {
        handleCloseAddSectionDialog();
        setAddEditorSectionDialog(true);
    }

    const handleCreateFile = () => {
        handleCloseAddSectionDialog();
        setAddFileSectionDialog(true);
    }

    const handleCreateVideo = () => {
        handleCloseAddSectionDialog();
        setAddVideoSectionDialog(true);
    }

    //add EDITOR
    const [addEditorSectionDialog, setAddEditorSectionDialog] = useState<boolean>(false);
    const handleCloseAddEditorSectionDialog = () => {
        setAddEditorSectionDialog(false);
        setUpdateEditorSectionDialog(null);
    }

    //update EDITOR
    const [updateEditorSectionDialog, setUpdateEditorSectionDialog] = useState<TrainingContentNewModel | null>(null);
    const handleCloseUpdateEditorSectionDialog = () => setUpdateEditorSectionDialog(null);

    //add FILE
    const [addFileSectionDialog, setAddFileSectionDialog] = useState<boolean>(false);
    const [editFileSectionDialog, setEditFileSectionDialog] = useState<TrainingContentNewModel | null>(null);
    const handleCloseAddFileSectionDialog = () => {
        setAddFileSectionDialog(false);
        setEditFileSectionDialog(null);
    }

    const addSection = (section: TrainingContentNewModel) => {
        formik.setValues({...formik.values, content: [...formik.values.content, section]});
        setAddFileSectionDialog(false);
        setAddVideoSectionDialog(false);
        setAddEditorSectionDialog(false);
    }

    //add VIDEO
    const [addVideoSectionDialog, setAddVideoSectionDialog] = useState<boolean>(false);
    const handleCloseAddVideoSectionDialog = () => {
        setAddVideoSectionDialog(false);
        setUpdateAddVideoSectionDialog(null); //close update dialog as it is one dialog depending on addVideoSectionDialog: true ++ setUpdateAddVideoSectionDialog not null
    }

    //update VIDEO
    const [updateVideoSectionDialog, setUpdateAddVideoSectionDialog] = useState<TrainingContentNewModel | null>(null);
    const handleCloseUpdateAddVideoSectionDialog = () => setUpdateAddVideoSectionDialog(null);

    const handleUpdateSection = (section: TrainingContentNewModel) => {
        formik.setValues({...formik.values, content: formik.values.content.map(e => e.sectionId === section.sectionId ? section : e)});
        handleCloseUpdateAddVideoSectionDialog();
        handleCloseAddFileSectionDialog();
        handleCloseUpdateEditorSectionDialog();
    }

    //sections
    const [openedSections, setOpenedSections] = useState<string[]>([]);

    const toggleSection = (section: TrainingContentNewModel) => {
        if(openedSections.some(e => e === section.sectionId)){
            setOpenedSections(openedSections.filter(e => e !== section.sectionId));
        }else{
            setOpenedSections([...openedSections, section.sectionId]);
        }
    }

    const openAllSections = () => setOpenedSections(formik.values.content.map(e => e.sectionId));
    const hideAllSections = () => setOpenedSections([]);

    const handleEditSection = (section: TrainingContentNewModel) => {
        if(section.type === MainTrainingContentType.VideoLink){
            setUpdateAddVideoSectionDialog(section);
            return;
        }
        if(section.type === MainTrainingContentType.VideoFile){
            setUpdateAddVideoSectionDialog(section);
            return;
        }
        if(section.type === MainTrainingContentType.File){
            setEditFileSectionDialog(section);
            return;
        }
        if(section.type === MainTrainingContentType.Editor){
            setUpdateEditorSectionDialog(section);
            return;
        }
    }

    //LEAVE DIALOG
    const [leaveDialog, setLeaveDialog] = useState<boolean>(false);
    const handleCloseLeaveDialog = () => setLeaveDialog(false);

    const handleLeave = () => {
        setLeaveDialog(false);
        handleClose();
    }

    //DELETE SECTION
    const [deleteSectionDialog, setDeleteSectionDialog] = useState<TrainingContentNewModel | null>(null);
    const handleCloseDeleteSectionDialog = () => setDeleteSectionDialog(null);

    const handleDeleteSection = (section: TrainingContentNewModel) => {
        formik.setValues({...formik.values, content: formik.values.content.filter(e => e.sectionId !== section.sectionId)});
        handleCloseDeleteSectionDialog();
    }

    //DRAG N DROP SECTIONS
    const findSection: (id: string, _log?: boolean) => {section: TrainingContentNewModel, index: number} | null = useCallback(
        (id: string, _log) => {
            const section = formik.values.content.find(e => e.sectionId === id);
            // section && _log && console.log(`
            //     Find section with id ${id}
            //     currArr: ${JSON.stringify(formik.values.content.map(e => ({name: e.name, sectionId: e.sectionId, order: e.order})))}
            //     the index is: ${formik.values.content.indexOf(section)}
            // `)
            return section ? {
                section,
                index: formik.values.content.indexOf(section)
            } : null;
        },
        [formik.values.content],
    )

    const moveSection: (id: string, atIndex: number) => void = useCallback(
        (id: string, atIndex: number) => {
            const res = findSection(id, true);
            if(res){
                // console.log(`moveColumn for ${id} - from ${res?.index} | to ${atIndex} \n PREV arr: ${JSON.stringify(formik.values.content.map(e => ({name: e.name, sectionId: e.sectionId, order: e.order})))}`)
                let copyArr:TrainingContentNewModel[] = JSON.parse(JSON.stringify(formik.values.content));
                copyArr.splice(res.index, 1) //remove
                copyArr.splice(atIndex, 0, res.section) //insert
                copyArr = copyArr.map((e, id) => ({...e, order: id}));
                formik.setValues({...formik.values, content: copyArr});
                // console.log(`moveColumn for ${id} - from ${res?.index} | to ${atIndex} \n new arr: ${JSON.stringify(copyArr.map(e => ({name: e.name, sectionId: e.sectionId, order: e.order})))}`);
            }else{
                // console.log(`--Column with key ${id} not found!`);
            }
        },
        [findSection, formik],
    )

    const handleChangeMinDuration = (value: number) => {
        formik.setFieldValue('limitMinDuration', value <= 0 ? 1 : value);
    }

    const handleChangeMaxDuration = (value: number) => {
        formik.setFieldValue('limitMaxDuration', value <= 0 ? 1 : value);
    }

    const updateName = (name: string) => {
        selectedTraining && dispatch(mainTrainingsChangeTrainingNameAction({
            data: {
                trainingId: selectedTraining.trainingId,
                name: name.trim()
            },
            onSuccess: () => {
                formik.setFieldValue('name', name);
            },
            onError: (request, error) => {
                const error409 = error.e409?.[0];
                if(error409?.type === 'DUPLICATE_NAME'){
                    setMessage({title: ('Non unique training name error'), message: (`You already have training with same name. Names for training should be unique.`)});
                }
            }
        }))
    }

    return{
        isCreateMode,
        formik,
        actions: {
            handleClose,
            handlePreviewTraining,
            handleCancel: () => {
                if(!isCreateMode ? JSON.stringify(formik.values) !== JSON.stringify(selectedTraining) : true){
                    setLeaveDialog(true);
                }else{
                    handleClose();
                }
            },
        },
        isOkCommon: selectedTemplate !== null && !formik.dirty ? true : formik.dirty,
        isLoadingSubmit: trainingCreate || trainingUpdate,
        categoriesAutocomplete: {
            getData: (value: string) => dispatch(mainTrainingsGetTrainingsCategoriesAutocompleteAction({data: {pageRequest: {page: 0, count: createEditTrainingCategoriesAutocompleteCount}, data: value}})),
            trainingCategories,
            isLoading: getTrainingCategories,
        },
        trainingDurationLimit: {
            onLimitMinimumTrainingDurationCheckboxClick,
            onLimitMaximumTrainingDurationCheckboxClick,
            handleChangeMinDuration,
            handleChangeMaxDuration
        },
        addSectionDialog: {
            isOpen: addSectionDialog,
            handleClose: handleCloseAddSectionDialog,
            handleCreateFile,
            handleCreateEditor,
            handleCreateVideo,
        },
        addFileSectionDialog: {
            isOpen: addFileSectionDialog || editFileSectionDialog !== null,
            handleClose: handleCloseAddFileSectionDialog,
            existingSections: formik.values.content ?? [],
            onSectionAdd: addSection,
            onSectionUpdate: handleUpdateSection,
            initialSection: editFileSectionDialog || undefined,
        },
        addVideoSectionDialog: {
            isOpen: addVideoSectionDialog || updateVideoSectionDialog !== null,
            handleClose: handleCloseAddVideoSectionDialog,
            existingSections: formik.values.content ?? [],
            onSectionAdd: addSection,
            initialSection: updateVideoSectionDialog || undefined,
            onSectionUpdate: handleUpdateSection
        },
        addEditorSectionDialog: {
            isOpen: addEditorSectionDialog || updateEditorSectionDialog !== null,
            handleClose: handleCloseAddEditorSectionDialog,
            existingSections: formik.values.content ?? [],
            onSectionAdd: addSection,
            initialSection: updateEditorSectionDialog || undefined,
            onSectionUpdate: handleUpdateSection
        },
        sections: {
            openedSections,
            toggleSection,
            openAllSections,
            hideAllSections,
            handleCreateNewSection,
            handleUseSection,
            handleEditSection,
            handleDeleteSection: (section: TrainingContentNewModel) => setDeleteSectionDialog(section),
            isSameNameSectionExists: (section: TrainingContentNewModel) => {
                return formik.values.content.filter(e => e.name.trim().toLowerCase() === section.name.trim().toLowerCase()).length > 1
            },
            isExpandAllDisabled: openedSections.length === formik.values.content.length,
            isCollapseAllDisabled: openedSections.length === 0,
        },
        leaveDialog: {
            isOpen: leaveDialog,
            handleClose: handleCloseLeaveDialog,
            onSuccess: handleLeave,
            isCreateMode
        },
        deleteSectionDialog: {
            handleClose: handleCloseDeleteSectionDialog,
            section: deleteSectionDialog,
            handleDeleteSection
        },
        dragNDrop: {
            findSection,
            moveSection
        },
        changeTrainingName: {
            updateName,
            isLoading: changeTrainingName,
        },
        trainingCover: {
            currentCover: formik.values.coverImage,
            onChange: (cover: TrainingCoverImageModel) => {
                formik.setFieldValue('coverImage', cover);
            }
        },

        validationSchema,
        addSectionByUseSectionDialog: (sections: MainTrainingsSectionsFullDataForImportModel[]) => {
            const prevSections = formik.values.content as TrainingContentNewModel[];
            const nextSections = sections.map((e, id): TrainingContentNewModel => ({...e, sectionId: uuid(), order: prevSections.length + id}));
            formik.setFieldValue('content', [...prevSections, ...nextSections])
        },

    }
}
