import React, {FC} from "react";
import {PageContainer} from "../../../../../../newShared/components/style";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useScreeningReport} from "../../../hooks/screenings/useScreeningReport";
import * as S from "./style";
import {MatchingRatioSpan, SpanLink} from "./style";
import {OverflowHiddenTextBodySmall, TypographyBody2} from "../../../../../../newShared/components/Inputs/styled";
import {CommonChips} from "../../../../../../newShared/components/CommonChips";
import {getNormalizedTargetType, getScreeningPageEntitiesName, getStatusTitleAndColor} from "../../../helpers";
import {IconButton, Skeleton} from "@mui/material";
import {DeleteTargetExceptionDialog} from "../../../components/target/deleteTargetExceptionDialog";
import {CreateExceptionDialog} from "../../../components/dialogs/createException";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {ShowClientDetailDialog} from "../../../components/dialogs/showClientDetail";
import colors from "../../../../../../newShared/theme/colors";
import {AddToBlackListDialog} from "../../../components/dialogs/addToBlackList";
import {BLACK_LIST_STATUS, VERIFIED_STATUS} from "../../../constants";
import {RestoreClientFromBlackListDialog} from "../../../components/dialogs/restoreFromBlackList";
import {ShowFullReportDialog} from "../../../components/dialogs/showFullReportDialog";
import {Flex} from "../../../../../../newShared/components/Layouts";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {FlexBox} from "../../../../../../newShared/components/editorUnion/components/dialogs/manageActorsDialog/styled";
import {LightTooltip} from "../../../../../../newShared/components/editorUnion/components/editorSideMenu/styled";
import {KycScreeningResultModel} from "../../../../../../newShared/GQLTypes";
import {GenericTable} from "../../../../../../newShared/components/genericTable";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";


export const KycScreeningReport: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.screenings'});

    const {
        selected,
        isLoadingExact,
        isLoadingCreateException,
        isLoadingSetClientVerified,
        createExceptionResultId,
        handleAddToBlackList,
        handleRestoreFromBlackList,
        handleSetClientVerified,
        handleShowClientDetail,
        handleViewFullReport,
        handleDeleteException,
        createQuickException,
        createCustomException,
        handleGoBack,
        handleGoToExactTarget,
        handleGoToExactTemplate,
        isDisabledSetClientVerified,
        results,
        handleDownloadFullReport,
        isDataOutdated,
        isLatest,
        handleGoToLatestScreening,
        genericTable
    } = useScreeningReport();
    const {isMobile} = useMedia();



    return (
        <PageContainer>
            <PageTitleAndActions
                title={selected ? selected?.screening.targetName : <Skeleton width={'100px'} />}
                handleTitleClick={handleGoToExactTarget}
                handleGoBack={handleGoBack}
                showDataHasBeenUpdated={(isDataOutdated.exactScreeningPage)}
                onReloadData={handleGoToLatestScreening}
                startMessage={t('New screening result is now available')}
                endMessage={t('to open.')}
                firstElement={selected ? <CommonChips settings={{...getStatusTitleAndColor(selected.target.status)}}/> : <Skeleton width={'100px'} sx={{m: '0 16px'}}/>}
                actions={[
                    {
                        title: t('Set client verified'),
                        size: 'small',
                        onClick: handleSetClientVerified,
                        disabled: isDisabledSetClientVerified || isLoadingSetClientVerified || selected?.target.status === VERIFIED_STATUS || !isLatest,
                        loading: isLoadingSetClientVerified,
                        tooltip: `${t('To set client verified create resolutions for all matched entities.')}`
                    },
                    {
                        title: selected?.target?.status === BLACK_LIST_STATUS ? t('Restore from black list') : t('Add to black list'),
                        size: 'small',
                        onClick: selected?.target?.status === BLACK_LIST_STATUS ? handleRestoreFromBlackList : handleAddToBlackList,
                        disabled: selected?.target.archived,
                    },
                    {
                        title: t('Client details'),
                        variant: 'text',
                        onClick: handleShowClientDetail,
                        disabled: !selected || isLoadingExact,
                    },
                ]}
            />


            {selected ?
                <Flex gap={'4.8px'}>
                    <TypographyBody2 sx={{mt: '16px', color: colors.text.grey}}>
                        {`${('Template name')}: `}
                    </TypographyBody2>
                    <TypographyBody2 sx={{mt: '16px', color: colors.text.grey}}>
                        <SpanLink onClick={handleGoToExactTemplate}>{`${selected.screening.templateName ?? ''}`}</SpanLink>
                    </TypographyBody2>
                    <TypographyBody2 sx={{mt: '16px', color: colors.text.dark}}>
                        ({getNormalizedTargetType(selected?.target.targetType ?? '')})
                    </TypographyBody2>
                </Flex>
                :
                <Skeleton width={'200px'} />}
            {selected ?
                <TypographyBody2 sx={{mt: '10px', color: colors.text.grey}}>
                    {`${('Template matching ratio')}: `}
                    <MatchingRatioSpan>{`${selected.screening.matchingRatio ?? ''}`}</MatchingRatioSpan>
                </TypographyBody2> : <Skeleton width={'200px'} />}

            <GenericTable<KycScreeningResultModel>
                id={'KycScreeningReport'}
                columnsConfig={{totalName: t('Total matches'), additionalTotalName: t('Total resolutions'), additionalTotalNumber: selected?.screening?.exceptionCount ?? 0, forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: results,
                    customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.entityId,
                    onRowClick: () => null,
                    columnsConfigs: [
                        {name: getScreeningPageEntitiesName(selected?.target.targetType), key: 'entityCaption', default: true},
                        {name: t('Score'), key: 'templateName', default: true, valueGetter: (row) => row.score.toString().substring(0, 4)},
                        {name: t('Resolution'), key: 'type', default: true, valueRenderer: (row, key, isTable, markRow) => {
                                const exception = selected?.exceptions?.find(e => e.id === row.exceptionId);
                                return(
                                    <>
                                        {exception ? (
                                                <S.ExceptionsFlexContainer>
                                                    <LightTooltip title={exception?.reason ?? ''}>
                                                        <S.ExceptionsDataBox>
                                                            <OverflowHiddenTextBodySmall>{`${t('Resolution score limit')}: `}<S.ExceptionTextSpan>{exception.score.toString().substring(0, 4)}</S.ExceptionTextSpan></OverflowHiddenTextBodySmall>
                                                            <OverflowHiddenTextBodySmall>{`${t('Resolution reason')}: `}<S.ExceptionTextSpan>{exception?.reason ?? ''}</S.ExceptionTextSpan></OverflowHiddenTextBodySmall>
                                                        </S.ExceptionsDataBox>
                                                    </LightTooltip>

                                                    <IconButton disableRipple sx={{p: '0 12px', alignSelf: 'start'}} disabled={!isLatest} onClick={(e) => handleDeleteException(e, exception.id, row.entityId)}>
                                                        <DeleteOutlineIcon style={{color: isLatest ? colors.primary.blue : undefined}} />
                                                    </IconButton>
                                                </S.ExceptionsFlexContainer>
                                            )
                                            :
                                            <FlexBox>
                                                <S.QuickExceptionButton onClick={(e) => {
                                                    markRow(row);
                                                    createQuickException(e, row);
                                                }}
                                                                        disabled={!isLatest}
                                                                        loading={isLoadingCreateException && row.entityId === createExceptionResultId} >
                                                    {t('Quick resolution')}
                                                </S.QuickExceptionButton>

                                                <S.QuickExceptionButton disabled={!isLatest} onClick={(e) => {
                                                    markRow(row);
                                                    createCustomException(e, row.entityId, row.entityCaption);
                                                }}>
                                                    {t('Resolution')}
                                                </S.QuickExceptionButton>
                                            </FlexBox>
                                        }
                                    </>
                                )
                            }},
                        {name: t('Report'), key: 'matchDate', default: true, valueRenderer: (row, key, isTable, markRow) => {
                            return(
                                <Flex ai={'center'} gap={'16px'}>
                                    <S.SmallTextButton onClick={(e) => {
                                        markRow(row);
                                        handleViewFullReport(e, row);
                                    }}>{t('View full report')}</S.SmallTextButton>

                                    <IconButton size={'small'} onClick={() => handleDownloadFullReport(row.entityId, row.entityCaption)}>
                                        <FileDownloadIcon color={'primary'} />
                                    </IconButton>
                                </Flex>
                            )
                            }},
                    ]
                }}
                filtersConfig={{}}
                emptyArrayImageProps={{
                    type: 'kycScreenings',
                    filterNotApplied: {
                        text: t('No matches to display'),
                        secondaryText: t('It seems that no matches are found according to this client\'s information.')
                    }
                }}
            />

            {/*<EmptyArrayImage*/}
            {/*    type={'kycScreenings'}*/}
            {/*    isShowing={scroll.hasNoResults}*/}
            {/*    text={t('No matches to display')}*/}
            {/*    secondaryText={t('It seems that no matches are found according to this client\'s information.')}*/}
            {/*    withoutAdd*/}
            {/*/>*/}

            {/*/!*Result Screening Table*!/*/}
            {/*{(!scroll.hasNoResults && !isLoadingExact) &&*/}
            {/*    <CommonTableContainer onScroll={scroll.onScroll} ref={scroll.scrollRef}>*/}
            {/*        <Table stickyHeader>*/}
            {/*            <CommonTableHead>*/}
            {/*                <CommonHeaderTableRow >*/}
            {/*                    <TableCell colSpan={4}>*/}
            {/*                        <FlexBox>*/}
            {/*                            <TypographySubtitle2 color={colors.text.grey}>{`${t('Total matches')}: ${isLoadingExact ? '' : totalMatches}`}</TypographySubtitle2>*/}
            {/*                            <TypographySubtitle2 sx={{m: '0 26px'}} color={colors.text.grey}>{`${t('Total resolutions')}: ${isLoadingExact ? '' : selected?.screening?.exceptionCount}`}</TypographySubtitle2>*/}
            {/*                        </FlexBox>*/}
            {/*                    </TableCell>*/}
            {/*                </CommonHeaderTableRow>*/}
            {/*                <ResultTableRow>*/}
            {/*                    <NameTableCell>*/}
            {/*                        <TypographyBody2Grey>{getScreeningPageEntitiesName(selected?.target.targetType)}</TypographyBody2Grey>*/}
            {/*                    </NameTableCell>*/}
            {/*                    <CommonTableCell>*/}
            {/*                        <TypographyBody2Grey>{t('Score')}</TypographyBody2Grey>*/}
            {/*                    </CommonTableCell>*/}
            {/*                    <CommonTableCell>*/}
            {/*                        <LightTooltip title={t('Quick resolution - without comment')+'\n' +*/}
            {/*                            t('Resolution - with comment')}>*/}
            {/*                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100px'}}>*/}
            {/*                                <TypographyBody2Grey>{t('Resolution')}</TypographyBody2Grey>*/}
            {/*                                <InfoOutlinedIcon style={{fontSize: 'medium'}}/>*/}
            {/*                            </Box>*/}
            {/*                        </LightTooltip>*/}


            {/*                    </CommonTableCell>*/}
            {/*                    <ActionTableCell >*/}
            {/*                        <TypographyBody2Grey>{t('Report')}</TypographyBody2Grey>*/}
            {/*                    </ActionTableCell>*/}
            {/*                </ResultTableRow>*/}
            {/*            </CommonTableHead>*/}
            {/*            <CommonTableBody>*/}
            {/*                {results.map((res) => {*/}
            {/*                    const exception = selected?.exceptions?.find(e => e.id === res.exceptionId);*/}

            {/*                    return (*/}
            {/*                        <TableRow key={res.entityId} selected={res.entityId === selectedEntityId}>*/}
            {/*                            <NameTableCell>*/}
            {/*                                <OverflowHiddenTextBody2>{res.entityCaption}</OverflowHiddenTextBody2>*/}
            {/*                            </NameTableCell>*/}
            {/*                            <CommonTableCell>*/}
            {/*                                <TypographyBody2Grey>{res.score.toString().substring(0, 4)}</TypographyBody2Grey>*/}
            {/*                            </CommonTableCell>*/}
            {/*                            <ExceptionTableCell>*/}
            {/*                                {exception ? (*/}
            {/*                                        <S.ExceptionsFlexContainer>*/}
            {/*                                            <LightTooltip title={exception?.reason ?? ''}>*/}
            {/*                                                <S.ExceptionsDataBox>*/}
            {/*                                                    <OverflowHiddenTextBodySmall>{`${t('Resolution score limit')}: `}<S.ExceptionTextSpan>{exception.score.toString().substring(0, 4)}</S.ExceptionTextSpan></OverflowHiddenTextBodySmall>*/}
            {/*                                                    <OverflowHiddenTextBodySmall>{`${t('Resolution reason')}: `}<S.ExceptionTextSpan>{exception?.reason ?? ''}</S.ExceptionTextSpan></OverflowHiddenTextBodySmall>*/}
            {/*                                                </S.ExceptionsDataBox>*/}
            {/*                                            </LightTooltip>*/}

            {/*                                            <IconButton disableRipple sx={{p: '0 12px', alignSelf: 'start'}} disabled={!isLatest} onClick={(e) => handleDeleteException(e, exception.id, res.entityId)}>*/}
            {/*                                                <DeleteOutlineIcon style={{color: isLatest ? colors.primary.blue : undefined}} />*/}
            {/*                                            </IconButton>*/}
            {/*                                        </S.ExceptionsFlexContainer>*/}
            {/*                                    )*/}
            {/*                                    :*/}
            {/*                                    <FlexBox>*/}
            {/*                                        <S.QuickExceptionButton onClick={(e) => createQuickException(e, res)}*/}
            {/*                                                                disabled={!isLatest}*/}
            {/*                                                                loading={isLoadingCreateException && res.entityId === createExceptionResultId} >*/}
            {/*                                            {t('Quick resolution')}*/}
            {/*                                        </S.QuickExceptionButton>*/}

            {/*                                        <S.QuickExceptionButton disabled={!isLatest} onClick={(e) => createCustomException(e, res.entityId, res.entityCaption)}>*/}
            {/*                                            {t('Resolution')}*/}
            {/*                                        </S.QuickExceptionButton>*/}
            {/*                                    </FlexBox>*/}
            {/*                                }*/}
            {/*                            </ExceptionTableCell>*/}
            {/*                            <CommonTableCell>*/}
            {/*                                <Flex ai={'center'} gap={'16px'}>*/}
            {/*                                    <S.SmallTextButton onClick={(e) => handleViewFullReport(e, res)}>{t('View full report')}</S.SmallTextButton>*/}

            {/*                                    <IconButton size={'small'} onClick={() => handleDownloadFullReport(res.entityId, res.entityCaption)}>*/}
            {/*                                        <FileDownloadIcon color={'primary'} />*/}
            {/*                                    </IconButton>*/}

            {/*                                </Flex>*/}
            {/*                            </CommonTableCell>*/}
            {/*                        </TableRow>*/}
            {/*                    )*/}
            {/*                })}*/}

            {/*                {(scroll.hasMore || scroll.isLoading) && <TableRowSkeleton columns={4} /> }*/}

            {/*            </CommonTableBody>*/}

            {/*        </Table>*/}
            {/*    </CommonTableContainer>*/}
            {/*}*/}

            {/*DIALOGS*/}
            <AddToBlackListDialog />
            <RestoreClientFromBlackListDialog />
            <DeleteTargetExceptionDialog />
            <CreateExceptionDialog />
            <ShowClientDetailDialog />
            <ShowFullReportDialog />
        </PageContainer>
    )
}
