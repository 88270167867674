import {Checkbox, Typography} from "@mui/material";
import React from "react";
import {Flex} from "../../../Layouts";
import {checkboxOptionPropsTypes} from "../../types";
import colors from "../../../../theme/colors";


export function CheckboxOption<T>({option, margin, config, handleSelectOption, handleDeselectOption}: checkboxOptionPropsTypes<T>){
    const Option = config.OptionRenderer && config.OptionRenderer(option.value, colors.grayText);
    return(
        <Flex key={option.key} w={'100%'} ai={'center'} gap={'19px'} m={margin}>
            <Checkbox
                sx={{width: '24px', height: '24px'}}
                checked={option.selected}
                onClick={() => option.selected ? handleDeselectOption(option.value) : handleSelectOption(option.value)}
            />
            {Option ?? <Typography variant={'body2'} color={colors.grayText} noWrap>{config.getOptionLabel ? config.getOptionLabel(option.value) : option.value}</Typography>}
        </Flex>
    )
}