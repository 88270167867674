import {Box, styled} from "@mui/material";

type EditorItemTableStyledProps = {}
export const EditorItemTableStyled = styled(Box)<EditorItemTableStyledProps>({

    '& > table': {
        borderCollapse: 'collapse',
        tableLayout: 'fixed',
        width: '100%',
        height: '1px',
    },

    'table, td': {
        border: '1px solid black',
    },
})


type EditorItemTableCellStyledProps = {
    isActive?: boolean;
}
export const EditorItemTableCellStyled = styled('td', {shouldForwardProp: (propName) => (
        !['isActive'].includes(propName.toString())
    )})<EditorItemTableCellStyledProps>(({isActive}) => ({
    verticalAlign: 'top',

    '& .insideCell': {
        display: 'flex',
        '& div.editor': {
            flexGrow: 1,
        },
        height: '100%',

        outline: isActive ? '1.5px solid black' : '1.5px solid transparent',
        outlineOffset: '-1.5px',

        '& div.insideCellWrapper': {
            overflow: 'hidden',
            minHeight: '30px',
            verticalAlign: 'middle',

            padding: '4px',

            flexGrow: 1,
        },
    }
}))

export const EditorItemTableCellResizerStyled = styled('div')<{readonly?: boolean}>(({readonly}) => ({
    width: '3px',
    minHeight: '1px',
    height: 'auto',
    cursor: !readonly ? 'col-resize' : undefined,
    position: 'relative',
    right: '-1.5px',
    // backgroundColor: 'red',
}))