import {TKycTemplateFieldType} from "../../types";
import colors from "../../../../../newShared/theme/colors";
import {Flex} from "../../../../../newShared/components/Layouts";
import {IconButton, Typography} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {AddFilterButton} from "../../../../../newShared/components/genericFilter/components/styled";
import React from "react";
import {MiniCustomDialog} from "../addSystemFieldComponent";
import TextFieldsIcon from '@mui/icons-material/TextFields';
import NumbersIcon from '@mui/icons-material/Numbers';
import DateRangeIcon from '@mui/icons-material/DateRange';

export const AddCustomFieldComponent = ({title, onSelect}: {onSelect: (type: TKycTemplateFieldType) => void, title: string}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);
    const id = isOpen ? 'add-filters' : undefined;

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleSelect = (type : TKycTemplateFieldType) => {
        onSelect(type);
        handleClose();
    }

    return(
        <AddFilterButton
            onClick={handleOpen}
            css={`
                background-color: ${isOpen ? colors.chip.pressed : colors.backgrounds.grey_light};
                color: ${isOpen ? colors.text.white : colors.grayText};
                cursor:pointer;
                & button svg{
                  fill: ${isOpen ? colors.text.white : colors.grayText};
                }
                ${!isOpen && `
                    &:hover{
                      background-color: ${colors.chip.hover}; 
                      & p, & button svg{
                        fill: ${colors.grayText} !important;
                        color: ${colors.grayText} !important;
                      }
                    }
                `}
            `}
        >
            <>
                <Flex>
                    <Typography variant={'body2'} noWrap>{title}</Typography>
                </Flex>
                <IconButton size={'small'} sx={{padding: '0'}}>
                    {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon/>}
                </IconButton>

                <MiniCustomDialog isOpen={isOpen} onClose={handleClose} elAnchor={anchorEl} id={id} padding={'none'} width={'200px'}>
                    <Flex
                        w={'100%'}
                        p={'7px 5px'}
                        maxh={'294px'}
                        overflowy={'auto'}
                        direction={'column'}
                        css={`
                          & div{
                            cursor: pointer;
                            gap: 16px;
                            align-items: center;
                            padding: 6px 8px;
                          }
                          & div svg{
                            color: ${colors.text.grey_dark}
                          }
                          & div p{
                            color: ${colors.grayText}
                          }
                          & div:hover{
                            background-color: ${colors.backgrounds.blue_light_1};
                          }
                        `}
                    >
                        <Flex onClick={() => handleSelect('TEXT')}>
                            <TextFieldsIcon />
                            <Typography variant={'body2'}>{'Text field'}</Typography>
                        </Flex>

                        <Flex onClick={() => handleSelect('NUMBER')}>
                            <NumbersIcon />
                            <Typography variant={'body2'}>{'Number field'}</Typography>
                        </Flex>

                        <Flex onClick={() => handleSelect('DATE')}>
                            <DateRangeIcon />
                            <Typography variant={'body2'}>{'Date field'}</Typography>
                        </Flex>
                    </Flex>
                </MiniCustomDialog>
            </>
        </AddFilterButton>
    )
}