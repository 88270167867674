import {TAsset, TSettingsId} from "./types";
import colors from "../../../newShared/theme/colors";
import {BILLING_CYCLE_ID, RENEWAL_ID, TYPE_ID} from "./constants";
import {AssetsRequestStatus, PhysicalAssetsStatus, PhysicalAssetStatusModel} from "../../../newShared/GQLTypes";
import React from "react";
import {StatusBox, StatusTitle} from "./components/styled";


export const getStatusColor = (status: string) => status.toUpperCase() === 'UNDEPLOYABLE' ? colors.primary.blue_light
    : status.toUpperCase() === 'PENDING' ? colors.decorative.orange
        : status.toUpperCase() === 'DEPLOYABLE' ? colors.decorative.bright_green
            : status.toUpperCase() === 'DEPLOYED' ? colors.warningActions.green
                : status.toUpperCase() === 'ARCHIVED' ? colors.decorative.brown
                    : colors.grey;


export const madeAssetTrim = (asset: TAsset, keys?: string[]): TAsset => {
    if (keys !== undefined && keys?.length > 0) {
        keys.forEach( key => asset.customFields = {
            ...asset.customFields,
            [key]: asset.customFields[key]?.trim(),
        })
    }

    return {
        ...asset,
        assetId: asset.assetId?.trim(),
        serial: asset.serial.trim(),
    }
}


export const normalizeSettingIdName = (value: TSettingsId | typeof TYPE_ID | typeof BILLING_CYCLE_ID | typeof RENEWAL_ID): string => {
    switch (value){
        case "customFields": return 'Custom field';
        case "model": return 'Model';
        case "status": return 'Asset status';
        case "manufacture": return 'Manufacturer';
        case "category": return 'Category';
        case "type": return 'Type';
        case "billingCycle": return 'Billing cycle';
        case "renewal": return 'Renewal';
        default: return '';
    }
}

export const getAssetsRequestStatusChip = (status: AssetsRequestStatus, isFilter?: boolean) => {
    switch (status) {
        case AssetsRequestStatus.Created: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.decorative.background_5}><StatusTitle color={colors.decorative.lilac}>{'Created'}</StatusTitle></StatusBox>;
        case AssetsRequestStatus.Approved: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.decorative.background_3}><StatusTitle color={colors.text.success}>{'Approved'}</StatusTitle></StatusBox>;
        case AssetsRequestStatus.Rejected: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.decorative.background_2}><StatusTitle color={colors.text.critical}>{'Rejected'}</StatusTitle></StatusBox>;
        default:  return <StatusBox margin={isFilter ? '8px 0 0 0' : undefined} bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.text.grey}>{status}</StatusTitle></StatusBox>;
    }
}

export const getPhysicalAssetStatusChip = (status: PhysicalAssetStatusModel, isFilter?: boolean) => {
    switch (status.status) {
        case PhysicalAssetsStatus.Deployed: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={'rgba(209, 250, 223, 1)'}><StatusTitle color={colors.text.success}>{status.name}</StatusTitle></StatusBox>;
        case PhysicalAssetsStatus.Deployable: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.backgrounds.blue_light_1}><StatusTitle color={colors.text.success}>{status.name}</StatusTitle></StatusBox>;
        case PhysicalAssetsStatus.Undeployable: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={'rgba(227, 242, 253, 1)'}><StatusTitle color={colors.text.grey_dark}>{status.name}</StatusTitle></StatusBox>;
        case PhysicalAssetsStatus.Archived: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={'rgba(237, 235, 233, 1)'}><StatusTitle color={colors.text.dark}>{status.name}</StatusTitle></StatusBox>;
        case PhysicalAssetsStatus.Pending: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.backgrounds.warning}><StatusTitle color={'rgba(180, 35, 24, 1)'}>{status.name}</StatusTitle></StatusBox>;

        default:  return <StatusBox margin={isFilter ? '8px 0 0 0' : undefined} bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.text.grey}>{status.name}</StatusTitle></StatusBox>;
    }
}
