import {
    leftMenuIsOpenCustomSelector,
    leftMenuIsOpenSelector,
    leftMenuPinnedItemsSelector,
    leftSecondCheckedItemsSelector,
    leftSecondMenuWidthSelector,
    setOpenCustomMenuAction,
    setOpenMenuAction,
    setPinnedMenuItemsAction,
    setSecondMenuWidthAction,
    TLeftMenuSlice
} from "../../store/slice";
import {useSelector} from "react-redux";
import {useAppDispatch} from "../../../../../newShared/redux";
import {leftMenuItems} from "../../constants";

export const useLeftMenu = () => {
    const dispatch = useAppDispatch();

    const isOpen = useSelector(leftMenuIsOpenSelector);
    const isOpenCustom = useSelector(leftMenuIsOpenCustomSelector);
    const items = leftMenuItems;
    const pinnedItems = useSelector(leftMenuPinnedItemsSelector);
    const checkItems = useSelector(leftSecondCheckedItemsSelector);

    const setOpen = (data: TLeftMenuSlice["isOpen"] = !isOpen) => {dispatch(setOpenMenuAction(data))};
    const setOpenCustom = (data: TLeftMenuSlice["isOpenCustomMenu"] = !isOpenCustom) => {dispatch(setOpenCustomMenuAction(data))};
    const setPinnedItems = (data: TLeftMenuSlice["pinnedItems"]) => {dispatch(setPinnedMenuItemsAction(data))};

    const secondMenuWidth = useSelector(leftSecondMenuWidthSelector);
    const setSecondMenuWidth = (data: TLeftMenuSlice["secondMenuWidth"]) => {dispatch(setSecondMenuWidthAction(data))};

    return {
        isOpen, isOpenCustom,
        pinnedItems,
        items,
        secondMenuWidth,

        checkItems,

        setOpen, setOpenCustom,
        setPinnedItems,
        setSecondMenuWidth,
    }
}