import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideDeleteTargetException, loadings} from "../../../store/slice";
import {DeleteTargetException} from "../../../store/actions";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import colors from "../../../../../../newShared/theme/colors";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import React from "react";

export const DeleteTargetExceptionDialog = () => {
    const dispatch = useDispatch();
    const {isOpen, targetId, exceptionId} = useSelector(dialogs).deleteTargetException;
    // const {selected} = useSelector(clientsSelector);
    const isLoading = useSelector(loadings).deleteTargetException;

    const handleClose = () => dispatch(hideDeleteTargetException());

    const handleSubmit = () => exceptionId && targetId && dispatch(DeleteTargetException({data: {exceptionId, targetId}}));

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{('Delete resolution')}</DialogTitle>

            <DialogContent>
                <Typography variant={'body1'} color={colors.grayText}>{('Are you sure you want to delete resolution?')}</Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'} sx={{textTransform: 'none'}}>{('Cancel')}</Button>
                <LoadingButton onClick={handleSubmit} loading={isLoading} loadingPosition={isLoading ? 'end' : undefined}>
                    {('Delete')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
