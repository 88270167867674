import {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {
    PATH_TRAININGS_ASSIGNMENT_EXACT,
    PATH_TRAININGS_ASSIGNMENTS,
    PATH_TRAININGS_CREATE,
    PATH_TRAININGS_EXACT,
    PATH_TRAININGS_EXAMS,
    PATH_TRAININGS_EXAMS_CREATE,
    PATH_TRAININGS_EXAMS_EXACT,
    PATH_TRAININGS_LIST,
    PATH_TRAININGS_SETTINGS,
    PATH_TRAININGS_TEMPLATES
} from "./constants";
import {TrainingsCreate} from "./pages/trainingsCreate";
import {TrainingsList} from "./pages/trainingsList";
import {ExamsList} from "./pages/exams";
import {TrainingsSettings} from "./pages/settings";
import {TrainingExactPage} from "./pages/trainingExact";
import {ExamExactPage} from "./pages/examExact";
import {AssignmentsList} from "./pages/assignmentsList";
import {AssignmentExact} from "./pages/assignmentExact";
import {TemplatesList} from "./pages/templatesList";

export const Routes: FC = () => {

    return (
        <Switch>
            <Route exact path={PATH_TRAININGS_LIST} component={TrainingsList}/>
            <Route exact path={PATH_TRAININGS_CREATE} component={TrainingsCreate}/>
            <Route exact path={PATH_TRAININGS_EXACT} component={TrainingExactPage}/>
            <Route exact path={PATH_TRAININGS_EXAMS} component={ExamsList}/>
            <Route exact path={[PATH_TRAININGS_EXAMS_CREATE, PATH_TRAININGS_EXAMS_EXACT]} component={ExamExactPage}/>
            <Route exact path={PATH_TRAININGS_ASSIGNMENTS} component={AssignmentsList}/>
            <Route exact path={PATH_TRAININGS_ASSIGNMENT_EXACT} component={AssignmentExact}/>
            <Route exact path={PATH_TRAININGS_TEMPLATES} component={TemplatesList}/>
            <Route exact path={PATH_TRAININGS_SETTINGS} component={TrainingsSettings}/>
            <Redirect to={PATH_TRAININGS_LIST}/>
        </Switch>
    )
}
