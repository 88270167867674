import {TExportCsvField, TIdName, TIdNameLogo, TPageInfo,} from "../../../newShared/types";
import {
  CITY_COLUMN_KEY,
  COUNTRY_COLUMN_KEY,
  CUSTOM_FIELDS_ID,
  DEGREE_ID,
  DEP_COLUMN_KEY,
  DEPARTMENT_ID,
  DIVISION_COLUMN_KEY,
  DIVISION_ID,
  EMP_TYPE_COLUMN_KEY,
  EMPLOYMENT_TYPE_ID,
  EXACT_EMPL_TABS,
  JOB_TITLE_COLUMN_KEY,
  LAST_JOB_START_DATE_COLUMN_KEY,
  LOCATION_COLUMN_KEY,
  LOCATION_ID,
  MANAGER_COLUMN_KEY,
  PHONE_COLUMN_KEY,
  RELATIONSHIP_TYPE_ID,
  STREET_COLUMN_KEY,
  ZIP_COLUMN_KEY,
} from "./constants";
import {MouseEvent} from "react";
import {
  GetEmployeesTreeQuery,
  GetShortDocumentsByWorkEmailWithFilterPagingQuery,
  MainHrGetRequestByIdQuery,
  MainHrIssueModel,
  MainHrRequestModel,
  MainHrTimeOffRequestModel,
  WorkspaceCollaboratorModel,
} from "../../../newShared/GQLTypes";
import {TImportCsvDialogForSlice} from "../../../newShared/components/csvImportDialog/types";
import {TNewDocument, TNewDocumentExecutor} from "../documentsRefactor/types";
import {minMaxLoadedPageType} from "../../../newShared/components/genericTable/types";

export type TSmallEmployee = GetEmployeesTreeQuery["getEmployeesTree"][number];

export type TEmployeeContact = {
  type: string | null; //replace of typeId: string;
  value: string;
};

export type TEmployeeAddress = {
  country: string | null; // replace of countryId: string | null;
  street: string;
  city: string;
  zip: string;
};

export type TEmployeeEmergencyContact = {
  relationType: string | null; //replace of relationTypeId: string;
  fullName: string;
  phone: string;
  email: string;
  address: string;
};

export type TEmployeeEducation = {
  schoolName: string;
  degree: string | null;
  fieldOfStudy: string;
  startDate: string | null;
  endDate: string | null;
};

export type TEmployeeVeteranStatus = {
  activeDutyWartimeOrCampaignBadgeVeteran: boolean;
  armedForcesServiceMedalVeteran: boolean;
  disabledVeteran: boolean;
  recentlySeparatedVeteran: boolean;
};

export type TEmployeeManagerType = { id: string; name: string | null };

export type TEmployeeJobHistoryJobTitleType = {
  jobType: string | null; //replace of jobTypeId: string
  name: string;
  description: string;
  requirements: string;
};

export interface EditJobTitleType {
  jobType: string | null; //replace of jobTypeId: string
  name: string;
  description: string;
  requirements: string;
  history: boolean;
  id: string;
  workspaceId: string;
  count: number;
}

export interface DeleteJobTitleType {
  jobTitle: THrSettingsJobTitle | null;
  history: boolean;
}

export type TEmployeeJobHistory = {
  id: string;
  jobTitle: TEmployeeJobHistoryJobTitleType; //replace of jobTitleId: string;
  employmentType: string; //replace of employeeTypeId: string;
  location: string; //replace of locationId: string;
  department: string; //replace of departmentId: string;
  division: string; //replace of divisionId: string;
  manager: TEmployeeManagerType | null; //replace of manager: string | null;
  startDate: string | null;
  current: boolean;
};

//---
export type THrSettingsJobTitle = {
  id: string;
  jobType: string | null; //replace of jobTypeId: string
  name: string;
  description: string;
  requirements: string;
  workspaceId: string;
  count: number;
};

export type TShortDocumentByEmail =
  GetShortDocumentsByWorkEmailWithFilterPagingQuery["getShortDocumentsByWorkEmailWithFilterPaging"]["files"][number];

export type TEmployeeStatus = {
  id: string;
  name: string;
  textColor: string;
  backgroundColor: string;
  // terminationTypeId: string | null; //id only if employee terminated
  // terminationReasonId: string | null; //id only if employee terminated
  // eligibleForRehireId: string | null; //id only if employee terminated
};
//

export type FiltersStorageType = {
  key: string;
  value: string;
};

export type ColumnKeyType =
  | keyof EmployeeType
  | typeof PHONE_COLUMN_KEY
  | typeof COUNTRY_COLUMN_KEY
  | typeof CITY_COLUMN_KEY
  | typeof STREET_COLUMN_KEY
  | typeof ZIP_COLUMN_KEY
  | typeof JOB_TITLE_COLUMN_KEY
  | typeof DEP_COLUMN_KEY
  | typeof LOCATION_COLUMN_KEY
  | typeof EMP_TYPE_COLUMN_KEY
  | typeof DIVISION_COLUMN_KEY
  | typeof MANAGER_COLUMN_KEY
  | typeof LAST_JOB_START_DATE_COLUMN_KEY;

export type ColumnType = {
  key: ColumnKeyType;
  normalizedName: string; // First name
};

export type TAccountShort = {
  id: string;
  name: string;
  logo: string;
  app: TIdNameLogo;
};
//
export type EmployeeType = {
  workspaceId: string;
  // personalEmail: string;
  maritalStatus: string | null;
  id: string;
  employeeId: string;
  firstName: string; //req
  lastName: string; //req
  nickname: string | null;
  middleName: string | null;
  hireDate: string | null; //req
  birthDay: string | null;
  workEmail: string; // req !!!
  customFields: { [key: string]: string };
  status: TEmployeeStatus | null; //replace of statusId: string;
  gender: string | null; //replace of genderId: string;
  ethnicity: string | null; //replace of ethnicityId: string;
  contactInfo: {
    contacts: TEmployeeContact[];
    address: TEmployeeAddress;
    emergencyContacts: TEmployeeEmergencyContact[];
  };
  personalData: {
    education: TEmployeeEducation[];
    veteranStatus: TEmployeeVeteranStatus;
  };
  jobInfos: TEmployeeJobHistory[];
  trainings: {
    name: string;
    status: string;
    completionDate: string;
    completionStatus: string;
  }[];
  documents: string[];
  accounts: TAccountShort[];

  collaborator?: WorkspaceCollaboratorModel | null;
};

export type THrSettings = {
  workspaceId: string;
  terminationType: string[];
  terminationReason: string[]; //editable
  eligibleForRehire: string[];
  gender: string[];
  maritalStatus: string[];
  ethnicity: string[];
  country: string[];
  contactType: string[]; //editable
  jobType: string[];
  location: Record<string, number>;
  department: Record<string, number>;
  division: Record<string, number>;
  relationshipType: Record<string, number>;
  degree: Record<string, number>;
  employmentType: Record<string, number>;
  customFields: Record<string, number>; //editable
  status: TEmployeeStatus[];
  jobTitle: THrSettingsJobTitle[]; //editable;
  timeOffType: string[];
};

export type TEmployeesInitialState = {
  loadings: {
    getHrSettings: boolean;
    getEmployeesList: boolean;
    exportCsv: boolean;
    importCsv: boolean;
    deleteEmployee: boolean;
    createEmployee: boolean;
    updateEmployee: boolean;
    getEmployee: boolean;
    getEmployeeHistory: boolean;
    getEmployeeDocuments: boolean;
    createSetting: boolean;
    updateSetting: boolean;
    deleteSetting: boolean;
    updateHireDate: boolean;
    updateStatus: boolean;
    createJobRecord: boolean;
    updateJobRecord: boolean;
    deleteJobRecord: boolean;
    releaseEmployees: boolean;
    getManagers: boolean;
    createJobTitle: boolean;
    editJobTitle: boolean;
    deleteJobTitle: boolean;
    isLoadingFields: boolean;

    isLoadingTree: boolean;
    isUpdatingTree: boolean;
    // isChangingEmployeeTree: boolean;
    isDownloadingTree: boolean;
    isLoadingAutocomplete: ColumnKeyType[];
    isLoadingDocument: boolean;
    isLoadingDocumentId: string | null;
    //requests
    incomingRequests: boolean;
    issuesList: boolean;
    issueExact: boolean;
    issueChangeStatus: boolean;
    isLoadingApproveRequest: boolean;
    isLoadingRejectRequest: boolean;
    isLoadingDeleteRequest: boolean;
    isLoadingRequest: boolean;
    timeOffList: boolean;
    timeOffExact: boolean;
    timeOffApprove: boolean;
    timeOffReject: boolean;
    timeOffSkip: boolean;
    downloadFileLoading: string[];
  };
  dialogs: {
    incomingRequestView: {
      request: MainHrRequestModel | null;
      employee:
          | null
          | MainHrGetRequestByIdQuery["mainHrGetRequestById"]["employee"];
      requestId: string | null;
    };
    tableViewSettings: boolean;
    deleteEmployee: {
      isOpen: boolean;
      employee: TIdName | null;
    };
    // isOpenExportCsv: boolean;
    exportCsv: {
      isOpen: boolean;
      fields: TExportCsvField[];
    };
    isOpenImportCsv: boolean;
    addSettingDialog: {
      isOpen: boolean;
      type: keyof THrSettings | null;
      id: number;
    };
    changeHireDateDialog: boolean;
    changeEmploymentStatusDialog: boolean;
    changeHistoryRowDialog: {
      isOpen: boolean;
      row: TEmployeeJobHistory | null;
    };
    deleteHistoryRowDialog: {
      isOpen: boolean;
      row: TEmployeeJobHistory | null;
    };
    addHistoryRowDialog: boolean;
    discardUpdateGeneralInfoChanges: {
      isOpen: boolean;
      nextTab: ExactEmployeeTabs;
    };
    releaseEmployees: boolean;

    editEmployeesTreeNode: {
      isOpen: boolean;
      id: string | null;
    };
    previewJobTitle: {
      isOpen: boolean;
      jobTitle: THrSettingsJobTitle | null;
    };
    editJobTitle: {
      isOpen: boolean;
      jobTitle: THrSettingsJobTitle | null;
    };
    deleteJobTitle: {
      isOpen: boolean;
      jobTitle: THrSettingsJobTitle | null;
    };
    //settings
    createHRSetting: {
      isOpen: boolean;
      field: SettingsTypes | null;
    };
    editHRSetting: {
      isOpen: boolean;
      field: SettingsTypes | null;
      value: string | null;
    };
    deleteHRSetting: {
      isOpen: boolean;
      field: SettingsTypes | null;
      value: string | null;
    };

    resolveIssue: {
      issueId: string | null;
    };

    timeOffHistory: {
      isOpen: boolean;
      selected: MainHrTimeOffRequestModel | null,
    },
    requestError: {
      title: string,
      message: string[],
    },
    skipRequest: {
      isOpen: boolean,
    },

  } & TImportCsvDialogForSlice;
  employeesPageInfo: TPageInfo;
  employeeHistoryPageInfo: TPageInfo;
  hrSettings: THrSettings;
  employees: EmployeeType[];
  employeesMinLoadedPage: number;
  employeesMaxLoadedPage: number;
  selectedEmployee: EmployeeType | null;
  employeeDocuments: {
    documents: TShortDocumentByEmail[];
    pageInfo: TPageInfo;
    minLoadedPage: number;
    maxLoadedPage: number;
    selectedNewDocument: {
      document: TNewDocument | null;
      documentExecutor: TNewDocumentExecutor | null;
    } | null;
  };
  managers: TManager[];
  // employeesFilter: {key: string; value: string}[];
  // columnSettings: ColumnType[];

  tree: {
    employees: TSmallEmployee[];
    deleted: string[];
    isEditMode: boolean;
    fullView: boolean;
    direction: "TB" | "LR" | "RL";
  };

  incomingRequests: {
    requests: MainHrRequestModel[];
    pageInfo: TPageInfo;
    minMaxLoadedPage: minMaxLoadedPageType;
  };

  issues: {
    issues: MainHrIssueModel[];
    pageInfo: TPageInfo;
    minMaxLoadedPage: minMaxLoadedPageType;

    selected: MainHrIssueModel | null;
  };
  timeOffRequests: {
    requests: MainHrTimeOffRequestModel[];
    pageInfo: TPageInfo;
    minMaxLoadedPage: minMaxLoadedPageType;
    selected: MainHrTimeOffRequestModel | null;
  };
};

export type EmployeesTableInputProps = {
  columnConfig: ColumnType[];
};

//
export type ExactEmployeeTabs = (typeof EXACT_EMPL_TABS)[number];

export type editableSettingType =
  | "Contact type"
  | "Relationship type"
  | "Degree"
  | "Job title"
  | "Department"
  | "Employment type"
  | "Division"
  | "Location"
  | "Custom field";

//using this because its cover all the fields
export type createSettingFormType = {
  jobType: string | null; //replace of jobTypeId: string
  name: string;
  description: string;
  requirements: string;
};

export type TEmployeeCreateJobHistory = {
  jobTitle: TEmployeeJobHistoryJobTitleType | null;
  employmentType: string | null;
  location: string | null;
  department: string | null;
  division: string | null;
  manager: TEmployeeManagerType | null;
  startDate: string | null;
};

export interface TEmployeeUpdateJobHistory extends TEmployeeCreateJobHistory {
  id: string;
  current: boolean;
}

export type TManager = {
  id: string;
  name: string;
};

export type SettingsTypes =
  | typeof DEPARTMENT_ID
  | typeof CUSTOM_FIELDS_ID
  | typeof LOCATION_ID
  | typeof DIVISION_ID
  | typeof RELATIONSHIP_TYPE_ID
  | typeof DEGREE_ID
  | typeof EMPLOYMENT_TYPE_ID;

type TEventEdgeConnectEnd = MouseEvent & {
  target?: { dataset?: { nodeid: string } };
  // srcElement?: {dataset?: {nodeid: string}},
};
export function isEventEdgeConnectEnd(
  event: unknown
): event is TEventEdgeConnectEnd {
  return (
    typeof event === "object" &&
    !!event &&
    "target" in event &&
    typeof event.target === "object" &&
    !!event.target &&
    "dataset" in event.target &&
    typeof event.target.dataset === "object" &&
    !!event.target.dataset &&
    "nodeid" in event.target.dataset &&
    typeof event.target.dataset.nodeid === "string"
  );
  // && "srcElement" in event && typeof event.srcElement === "object" && !!event.srcElement
  // && "dataset" in event.srcElement && typeof event.srcElement.dataset === "object" && !!event.srcElement.dataset
  // && "nodeid" in event.srcElement.dataset && typeof event.srcElement.dataset.nodeid === 'string'
}

export type usedManagerAutocompleteValue = { id: string; name: string };

export type TShortEmployee = {
  id: string;
  firstName: string;
  lastName: string;
  jobTitle?: string | null;
  manager?: string | null;
  workEmail?: string | null;
  status?: string | null;
};
