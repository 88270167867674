import React from "react";
import {useVendorsRelease} from "../../../hooks/useVendorsDialogs/release";
import {ReleaseDialog} from "../../../../../../newShared/components/releaseDialog";

export const ReleaseVendors = () => {

   const {
       isOpen,
       handleClose,
       handleRelease,

       isLoadingRelease,
   } = useVendorsRelease();

    return (
        <ReleaseDialog isOpen={isOpen} handleClose={handleClose} isSubmittingLoading={isLoadingRelease}
                       handleSubmit={({evidence, review, sign, read}) => {
                           handleRelease({
                               ...evidence,
                               readers: read,
                               reviewers: review,
                               signers: sign
                           })
                       }}
        />
    )
}
