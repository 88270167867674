import {useDispatch, useSelector} from "react-redux";
import {
    deselectDocument,
    deselectEmployee,
    employeeDocumentsSelector,
    eraseEmployeeDocuments,
    hrSettings,
    loadings,
    openAddHistoryRowDialog,
    openAddSettingDialog,
    openChangeEmploymentStatusDialog,
    openChangeHireDateDialog,
    openDeleteEmployeeDialog,
    replaceEmployeeDocumentsMinMaxPage,
    selectedEmployee,
    setIsLoadingDocumentId,
} from "../../store/slice";
import {useForm} from "../../../../../newShared/hooks/useForm";
import {EmployeeType, ExactEmployeeTabs, TEmployeeVeteranStatus, THrSettings, TShortDocumentByEmail} from "../../types";
import {
    EMPLOYEES_LIST_DEFAULT_PAGING,
    emptyContact,
    emptyEducation,
    emptyEmergencyContact,
    mockEmployee,
    PATH_LOCAL_EMPLOYEES_ALL,
    PATH_LOCAL_EMPLOYEES_ID,
    PATH_LOCAL_EMPLOYEES_TREE_EXACT,
    PHONE_NUMBER_CONTACT_TYPE
} from "../../constants";
import {ChangeEvent, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_EMPLOYEES} from "../../../../../newShared/constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {getName, validateEmail} from "../../../../../newShared/utils/text";
import {
    GetHrEmployeeById,
    GetHrEmployeeJobInfosById,
    GetShortDocumentsByWorkEmailWithFilterPaging,
    UpdateHrEmployee
} from "../../store/actions";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {DownloadFile} from "../../../documentsRefactor/store/actions";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {useSnack} from "../../../../barsEnvironment/snack/hooks/useSnack";
import {TShortDocument} from "../../../documentsRefactor/types";
import {usePDFView} from "../../../../../newShared/components/docViewer/hooks/usePDFView";


export const useEmployeesExact = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const dispatch = useDispatch();
    const history = useHistory();
    const id = useParams<{ id: string }>().id;
    const {handleClose, handleOpen} = usePDFView();

    //selectors
    const employee = useSelector(selectedEmployee);
    const {getEmployee, updateEmployee, getEmployeeDocuments, isLoadingDocument, isLoadingDocumentId} = useSelector(loadings);
    const settings = useSelector(hrSettings);
    const employeeDocuments = useSelector(employeeDocumentsSelector);
    const {user} = UseManageWorkspacesAndOrganizations();
    const snack = useSnack();

    //form
    const {form, setForm, handleChange} = useForm<EmployeeType>(mockEmployee);

    //tabs
    const [tab, setTab] = useState<ExactEmployeeTabs>('general');

    //useEffects
    useSetBreadcrumbs([
        {
            title: tMenu('HR Management'),
            path: PATH_LOCAL_EMPLOYEES
        },
        {
            title: tMenu('Employees'),
            path: PATH_LOCAL_EMPLOYEES_ALL
        },
        {
            title: employee ? `${employee.firstName} ${employee.lastName}` : tMenu('Loading...'),
            path: employee ? PATH_LOCAL_EMPLOYEES_ID.replace(':id', employee.id) : PATH_LOCAL_EMPLOYEES_ALL
        }
    ]);

    useEffect(() => {
        if(employee){
            setForm({
                ...employee,
                contactInfo: {
                    ...employee.contactInfo,
                    contacts: employee.contactInfo.contacts.some(e => e.type === PHONE_NUMBER_CONTACT_TYPE) ? employee.contactInfo.contacts : [{type: PHONE_NUMBER_CONTACT_TYPE, value: ''}, ...employee.contactInfo.contacts]
                }
            });
            dispatch(GetHrEmployeeJobInfosById({data: {pageInfo: {page: 0, count: EMPLOYEES_LIST_DEFAULT_PAGING}, id}}));
        }else{
            dispatch(GetHrEmployeeById({
                data: {
                    id,
                    onReject: handleGoBack
                }
            }));
        }

        return () => {
            dispatch(deselectEmployee());
            handleClose();
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!getEmployee && employee){
            setForm({
                ...employee,
                contactInfo: {
                    ...employee.contactInfo,
                    contacts: employee.contactInfo.contacts.some(e => e.type === PHONE_NUMBER_CONTACT_TYPE) ? employee.contactInfo.contacts : [{type: PHONE_NUMBER_CONTACT_TYPE, value: ''}, ...employee.contactInfo.contacts]
                }
            });
            //waiting for employee to be fetched - because GetHrEmployeeJobInfosById sets jobHistory into selectedEmployee, so pending
            dispatch(GetHrEmployeeJobInfosById({data: {pageInfo: {page: 0, count: EMPLOYEES_LIST_DEFAULT_PAGING}, id}}));
        }
        //eslint-disable-next-line
    }, [getEmployee]);


    //topBarActions
    const handleDelete = () => {
        employee && dispatch(openDeleteEmployeeDialog({id: employee.id, name: getName(employee.firstName, employee.lastName)}));
    }

    const handleGoToTree = () => {
        employee && history.push(PATH_LOCAL_EMPLOYEES_TREE_EXACT.replace(':employeeId', employee.id));
    }

    const handleGoBack = () => {
        dispatch(deselectEmployee());
        history.push(PATH_LOCAL_EMPLOYEES_ALL)
    }

    //tabs
    const handleSetTab = (_tab: ExactEmployeeTabs) => {
        // console.log(`handleSetTab: ${_tab} - prev ${tab} - ${isGeneralInfoChanged(employee, form)} - ${isEditGeneral} - ${employee !== null}`);
        // if(tab === 'general' && isGeneralInfoChanged(employee, form) && isEditGeneral){
        //     dispatch(openDiscardChangesDialog(_tab));
        // }else{
        //     setTab(_tab);
        // }
        setTab(_tab);

    }

    const handleDiscardChangesAndGoToNextTab = (_tab: ExactEmployeeTabs) => {
        handleCancelChanges();
        setTab(_tab);
    }

    //GENERAL INFO
    const [isEditGeneral, setIsEditGeneral] = useState<boolean>(false);

    const handleSaveGeneralInfo = () => {
        if(isOkGeneralInfoUpdate()){
            dispatch(UpdateHrEmployee({data: {employee: form, onSuccess: onSuccessGeneralInfoUpdate}}));
        }
    }

    const onSuccessGeneralInfoUpdate = () => {
        setIsEditGeneral(false);
    }

    const isOkGeneralInfoUpdate = (): boolean => {
        return form.firstName.trim().length > 0 &&
            form.lastName.trim().length > 0 &&
            form.gender !== null &&
            form.maritalStatus !== null &&
            validateEmail(form.workEmail) &&
            !form.personalData.education.find(e => (!e.schoolName.trim().length || !e.degree?.trim().length)) && //schoolName and degree are required in every education
            form.contactInfo.address.country !== null && form.contactInfo.address.country?.trim().length > 0 &&
            form.contactInfo.address.city?.trim().length > 0 &&
            form.contactInfo.contacts.some(e => e.type === PHONE_NUMBER_CONTACT_TYPE) && //has to have phone number
            !form.contactInfo.contacts.some(e => (!e.type || !e.value.trim().length)) && //every contact has non-empty value
            !form.contactInfo.emergencyContacts.some(e => (!e.relationType || !e.address.trim().length || !e.email.trim().length || !e.phone.trim().length) || !e.fullName.trim().length) &&
            JSON.stringify({...form, jobInfos: []}) !== JSON.stringify({...employee, jobInfos: []})
    }

    const handleCancelChanges = () => {
        setIsEditGeneral(false);
        employee && setForm(employee);
    }

    //employee documents filters config
    const lastModified: keyConfig<string> = {
        type: 'date',
        key: 'lastModified',
        name: 'Last modified',
        default: true,
    }

    //e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    const handleOpenDocument = (file: TShortDocumentByEmail) => {
        if(file){
            handleOpen({fileId: file.generatedFileId ?? '', fileName: file.name});
        }
    }

    const handleDownload = (file: TShortDocument) => {
        if(file.type === 'DOCUMENT' && file.generatedFileId ){
            dispatch(DownloadFile({fileId: file.generatedFileId, name: `${file.name}.pdf`}));
            file?.documentId && dispatch(setIsLoadingDocumentId(file.documentId));

        }else if(file.type === 'FILE' && file.documentId){
            dispatch(DownloadFile({fileId: file.documentId, name: file.name}));
            file?.documentId && dispatch(setIsLoadingDocumentId(file.documentId))
        }
    }

    return{
        isLoading: getEmployee,
        form,
        employee,
        handleChange,
        setForm,
        topBarActions: {
            handleDelete,
            handleGoToTree,
            handleGoBack
        },
        tab,
        handleSetTab,
        lastHistory: form.jobInfos.find(e => e.current),
        phoneNumber: form.contactInfo.contacts.find(e => e.type === PHONE_NUMBER_CONTACT_TYPE)?.value ?? '-',
        generalInfo: {
            isLoadingUpdate: updateEmployee,
            isEditGeneral,
            setIsEditGeneral,
            handleCancelChanges,
            handleSaveGeneralInfo,
            isOk: isOkGeneralInfoUpdate(),
            handleDiscardChangesAndGoToNextTab,

            handleSetBirthday: (birthDay: string | null) => {setForm({...form, birthDay})},
            handleSetGender: (gender: string | null) => {gender && setForm({...form, gender})},
            handleSetMartialStatus: (maritalStatus: string | null) => {maritalStatus && setForm({...form, maritalStatus})},
            handleSetEthnicity: (ethnicity: string | null) => {ethnicity && setForm({...form, ethnicity})},
            handleDeleteEducation: (id: number) => {
                setForm({...form, personalData: {
                    ...form.personalData,
                        education: form.personalData.education.filter((e, _id) => _id !== id)
                }})
            },
            handleAddEducation: () => {
                setForm({...form, personalData: {
                        ...form.personalData,
                        education: [emptyEducation, ...form.personalData.education]
                    }})
            },
            handleChangeEducation: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
                setForm((prevValues):EmployeeType => ({
                    ...prevValues,
                    personalData: {
                        ...prevValues.personalData,
                        education: [
                            ...prevValues
                                .personalData
                                .education
                                .map((e, _id) => _id === id ? {...e, [event.target.name]: event.target.value} : e)
                        ]
                    }
                }));
            },
            handleChangeEducationDegree: (degree: string | null, id: number) => {
                setForm((prevValues):EmployeeType => ({
                    ...prevValues,
                    personalData: {
                        ...prevValues.personalData,
                        education: [
                            ...prevValues
                                .personalData
                                .education
                                .map((e, _id) => _id === id ? {...e, degree} : e)
                        ]
                    }
                }));
            },
            handleSetEducationEndDate: (newValue: string | null, id: number) => {
                setForm((prevValues):EmployeeType => ({
                    ...prevValues,
                    personalData: {
                        ...prevValues.personalData,
                        education: [
                            ...prevValues
                                .personalData
                                .education
                                .map((e, _id) => _id === id ? {...e, endDate: newValue} : e)
                        ]
                    }
                }));
            },
            handleSetEducationStartDate: (newValue: string | null, id: number) => {
                setForm((prevValues):EmployeeType => ({
                    ...prevValues,
                    personalData: {
                        ...prevValues.personalData,
                        education: [
                            ...prevValues
                                .personalData
                                .education
                                .map((e, _id) => _id === id ? {...e, startDate: newValue} : e)
                        ]
                    }
                }));
            },
            handleSetAddressCountry: (country: string | null) => {
                setForm((prevValues):EmployeeType => ({
                    ...prevValues,
                    contactInfo: {
                        ...prevValues.contactInfo,
                        address: {
                            ...prevValues.contactInfo.address,
                            country
                        }
                    }
                }));
            },
            handleChangeAddress: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setForm({...form, contactInfo: {
                        ...form.contactInfo,
                        address: {...form.contactInfo.address, [event.target.name]: event.target.value}
                    }})
            },
            handleAddContact: () => {
                setForm({...form, contactInfo: {
                        ...form.contactInfo,
                        contacts: [emptyContact, ...form.contactInfo.contacts]
                    }})
            },
            handleDeleteContact: (id: number) => {
                setForm({...form, contactInfo: {
                        ...form.contactInfo,
                        contacts: form.contactInfo.contacts.filter((e, _id) => _id !== id)
                    }})
            },
            handleSetContactType: (id: number, type: string | null) => {
                setForm({...form, contactInfo: {
                        ...form.contactInfo,
                        contacts: form.contactInfo.contacts.map((e, _id) => _id === id ? {...e, type} : e)
                    }})
            },
            handleChangeContactValue: (id: number, value: string) => {
                setForm({...form, contactInfo: {
                        ...form.contactInfo,
                        contacts: form.contactInfo.contacts.map((e, _id) => _id === id ? {...e, value} : e)
                    }})
            },
            handleAddEmergencyContact: () => {
                setForm({...form, contactInfo: {
                        ...form.contactInfo,
                        emergencyContacts: [emptyEmergencyContact, ...form.contactInfo.emergencyContacts]
                    }})
            },
            handleDeleteEmergencyContact: (id: number) => {
                setForm({...form, contactInfo: {
                        ...form.contactInfo,
                        emergencyContacts: form.contactInfo.emergencyContacts.filter((e, _id) => _id !== id)
                    }})
            },
            handleChangeEmergencyContact: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
                setForm((prevValues):EmployeeType => ({
                    ...prevValues,
                    contactInfo: {
                        ...prevValues.contactInfo,
                        emergencyContacts: [
                            ...prevValues
                                .contactInfo
                                .emergencyContacts
                                .map((e, _id) => _id === id ? {...e, [event.target.name]: event.target.value} : e)
                        ]
                    }
                }));
            },
            handleSetEmergencyContactType: (id: number, relationType: string | null) => {
                setForm((prevValues):EmployeeType => ({
                    ...prevValues,
                    contactInfo: {
                        ...prevValues.contactInfo,
                        emergencyContacts: [
                            ...prevValues
                                .contactInfo
                                .emergencyContacts
                                .map((e, _id) => _id === id ? {...e, relationType} : e)
                        ]
                    }
                }));
            },
            handleAddCustomField: () => {
                dispatch(openAddSettingDialog({type: 'customFields'}));
            },
            openAddContactType: (index: number) => {
                dispatch(openAddSettingDialog({type: 'contactType', id: index}));
            },
            openAddRelationshipType: (index: number) => {
                dispatch(openAddSettingDialog({type: 'relationshipType', id: index}));
            },
            openAddDegree: (index: number) => {
                dispatch(openAddSettingDialog({type: 'degree', id: index}));
            },
            handleChangeVeteranStatus: (key: keyof TEmployeeVeteranStatus, checked: boolean) => {
                setForm({...form, personalData: {...form.personalData, veteranStatus: {...form.personalData.veteranStatus, [key]: checked}}})
            },
            handleSettingAdd: (type: keyof THrSettings, value: string, id?: number) => {
                //job info has its own handler inside createJobRecord/updateJobRecord
                //so no need to handle it here
                if(tab === "jobInfo") return;
                switch (type){
                    case "jobTitle": {
                        const jobRecord = form.jobInfos.find(e => e.current);
                        const currJob = settings.jobTitle.find(e => e.name === value);
                        if(jobRecord && currJob){
                            setForm({...form, jobInfos: [{...jobRecord, jobTitle: currJob}]})
                        }
                        return;
                    }
                    case "employmentType": {
                        const jobRecord = form.jobInfos.find(e => e.current);
                        if(jobRecord){
                            setForm({...form, jobInfos: [{...jobRecord, employmentType: value}]})
                        }
                        return;
                    }
                    case "department": {
                        const jobRecord = form.jobInfos.find(e => e.current);
                        if(jobRecord){
                            setForm({...form, jobInfos: [{...jobRecord, department: value}]})
                        }
                        return;
                    }
                    case "location": {
                        const jobRecord = form.jobInfos.find(e => e.current);
                        if(jobRecord){
                            setForm({...form, jobInfos: [{...jobRecord, location: value}]})
                        }
                        return;
                    }
                    case "division": {
                        const jobRecord = form.jobInfos.find(e => e.current);
                        if(jobRecord){
                            setForm({...form, jobInfos: [{...jobRecord, division: value}]})
                        }
                        return;
                    }
                    case "contactType": {
                        setForm({
                            ...form,
                            contactInfo: {
                                ...form.contactInfo,
                                contacts: form.contactInfo.contacts.map((e, _id) => _id === id ? {...e, type: value} : e)
                            }
                        });
                        return;
                    }
                    case "degree": {
                        setForm({
                            ...form,
                            personalData: {
                                ...form.personalData,
                                education: form.personalData.education.map((e, _id) => _id === id ? {...e, degree: value} : e),
                            }
                        });
                        return;
                    }
                    case "relationshipType": {
                        setForm({
                            ...form,
                            contactInfo: {
                                ...form.contactInfo,
                                emergencyContacts: form.contactInfo.emergencyContacts.map((e, _id) => _id === id ? {...e, relationType: value} : e)
                            }
                        });
                        return;
                    }
                }
            }
        },
        jobInfo: {
            handleChangeHireDate: () => {
                dispatch(openChangeHireDateDialog())
            },

            handleChangeEmployeeStatus: () => {
                dispatch(openChangeEmploymentStatusDialog())
            },

            handleAddHistory: () => {
                dispatch(openAddHistoryRowDialog())
            }
        },
        settings,
        employeeDocuments: {
            documents: employeeDocuments.documents,
            // selectedNewDocument: employeeDocuments.selectedNewDocument,
            selectedNewDocument: JSON.parse(JSON.stringify(employeeDocuments.selectedNewDocument)),
            isLoadingDocument,
            paging: {
                pageInfo: employeeDocuments.pageInfo,
                isLoading: getEmployeeDocuments,
                minLoadedPage: employeeDocuments.minLoadedPage,
                maxLoadedPage: employeeDocuments.maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceEmployeeDocumentsMinMaxPage(data)),
                clearRows: () => dispatch(eraseEmployeeDocuments()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    employee && dispatch(GetShortDocumentsByWorkEmailWithFilterPaging({data: {workEmail: employee.workEmail, pageInfo: {page, count, filters}, search}}));
                }
            },
            filterConfigs: [lastModified],
            handleOpenDocument,
            handleDownload,
            isLoadingDocumentId,
            currentEmail: user?.email ?? '',
            snack,
            actions: {
                handleCloseEditor: () => {
                    dispatch(deselectDocument())
                },
            },

        },
    }
}
