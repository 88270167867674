import React, {FC, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {dialogs, isLoading, setAsEvidenceDialogAction} from "../../../store/slice";
import {setDocumentAsEvidenceAssetAction} from "../../../store/actions";
import {useLazyQuery} from "@apollo/client";
import {getDataForRelease} from "../../../../../../newShared/graphQl/query";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {GetDataForReleaseQuery, GetDataForReleaseQueryVariables} from "../../../../../../newShared/GQLTypes";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const SetDocumentAsEvidenceDialog: FC = () => {
    const dispatch = useDispatch();
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'})
    const {organizationId, workspaceId} = UseManageWorkspacesAndOrganizations();

    const [getData, {loading: isLoadingData, data: _data}] =
        useLazyQuery<GetDataForReleaseQuery, GetDataForReleaseQueryVariables>(
            getDataForRelease, {variables: {organizationId: organizationId || '', workspaceId: workspaceId || ''}}
        );

    const isSettingAsEvidence = useSelector(isLoading).isSettingAsEvidence;
    const documentId = useSelector(dialogs).setAsEvidenceDialog.documentId;
    const isOpen = !!documentId;
    const data = _data?.getDataForRelease;

    const [form, setForm] = useState<{frameworkId: string, controlId: string, evidenceId: string}>(
        {frameworkId: '', controlId: '', evidenceId: ''}
    )

    const handleClose = () => {
        dispatch(setAsEvidenceDialogAction({documentId: null}));
    }

    const handleConfirm = () => {
        documentId && dispatch(setDocumentAsEvidenceAssetAction({
            data: {documentId, evidenceId: form.evidenceId},
            snack: 'success',
        }));
    }

    useEffect(() => {
        if(isOpen) {
            setForm({frameworkId: '', controlId: '', evidenceId: ''});
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const handleSetFrameworkId = (frameworkId: string) => {
        if (frameworkId !== form.frameworkId) setForm({frameworkId, controlId: '', evidenceId: ''});
    }
    const handleSetControlId = (controlId: string) => {
        if (controlId !== form.controlId) setForm(prev => ({...prev, controlId, evidenceId: ''}));
    }
    const handleSetEvidenceId = (evidenceId: string) => {
        if (evidenceId !== form.evidenceId) setForm(prev => ({...prev, evidenceId}));
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle sx={{mb: 0}}>{t('Save to evidence')}</DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '16px', paddingTop: '20px !important'}}>
                <Autocomplete<GetDataForReleaseQuery["getDataForRelease"]["frameworks"][number]>
                    loading={isLoadingData}
                    options={data?.frameworks || []}
                    getOptionLabel={e => e.name}
                    renderOption={(props, option, state) => (<Typography {...props} variant={"body2"} key={option.id}>{option.name}</Typography> )}
                    onChange={(_, newValue) => handleSetFrameworkId(newValue?.id || '')}
                    value={data?.frameworks.find(e => e.id === form.frameworkId) || null}
                    renderInput={(params) => <TextField {...params} variant={"outlined"} label={t('Framework')} />}
                />

                <Autocomplete<GetDataForReleaseQuery["getDataForRelease"]["controls"][number]>
                    loading={isLoadingData}
                    disabled={form.frameworkId === ''}
                    options={data?.controls.filter(e => e.frameworkIds.includes(form.frameworkId)) || []}
                    getOptionLabel={e => e.name}
                    renderOption={(props, option, state) => (<Typography {...props} variant={"body2"} key={option.id}>{option.name}</Typography> )}
                    onChange={(_, newValue) => handleSetControlId(newValue?.id || '')}
                    value={data?.controls.find(e => e.id === form.controlId) || null}
                    renderInput={(params) => <TextField {...params} variant={"outlined"} label={t('Control')} />}
                />

                <Autocomplete<GetDataForReleaseQuery["getDataForRelease"]["evidences"][number]>
                    loading={isLoadingData}
                    disabled={form.frameworkId === '' || form.controlId === ''}
                    options={data?.evidences.filter(e => e.controlIds.includes(form.controlId)) || []}
                    getOptionLabel={e => e.name}
                    renderOption={(props, option, state) => (<Typography {...props} variant={"body2"} key={option.id}>{option.name}</Typography> )}
                    onChange={(_, newValue) => handleSetEvidenceId(newValue?.id || '')}
                    value={data?.evidences.find(e => e.id === form.evidenceId) || null}
                    renderInput={(params) => <TextField {...params} variant={"outlined"} label={t('Evidence')} />}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>{t('Cancel')}</Button>
                <LoadingButton onClick={handleConfirm} disabled={form.evidenceId === ''}
                               loading={isOpen && isSettingAsEvidence.includes(documentId)}>
                    {t('Save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
};