import Box from "@mui/material/Box/Box";
import React from "react";
import {Autocomplete, Button, TextField} from "@mui/material";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useRuleNew} from "../../../hooks/useRuleNew";
import {LoadingButton} from "@mui/lab";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";


export const RuleNew = () => {
    const {isMobile} = useMedia();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        isOk,
        form, handleChange,
        shortDuties,
        handleSave,
        goToRuleset,
        isLoadingCreate,
        isLoadingData,

        // toggleActive,
        handleSetSeverity,
        handleSetFirstDuty,
        handleSetSecondDuty,
    } = useRuleNew();


    return (
        <Box display={'flex'} flexDirection={'column'} width={'100%'} height={'100%'}>

            <PageTitleAndActions
                title={t('Create rule')}
                handleGoBack={goToRuleset}
                actions={[]}
            />

            {/*<Breadcrumbs/>*/}

            {/*<Typography variant={!isLessThen1050 ? "h1" : 'h3'} component={"span"}*/}
            {/*            sx={{mr: !revDir ? '32px' : undefined, ml: revDir ? '32px' : undefined}}>{t('Create rule')}</Typography>*/}

            <Box display={'flex'} flexDirection={'column'} width={isMobile ? '100%' : '578px'} sx={{mt: '20px'}} >

                <Box display={'flex'} alignItems={'center'} >
                    <Button sx={{textTransform: 'none'}} onClick={goToRuleset} size={'medium'} variant={'text'}>{t('Cancel')}</Button>
                    <LoadingButton size={'medium'} variant={'contained'}
                                   sx={{ml: !revDir ? '8px' : undefined, mr: revDir ? '8px' : undefined}}
                                   disabled={!isOk || isLoadingCreate || isLoadingData}
                                   onClick={handleSave} loading={isLoadingCreate}
                                   endIcon={isLoadingCreate ? <div style={{width: '10px', height: '10px', marginLeft: revDir ? '12px' : undefined, marginRight: !revDir ? '12px' : undefined }}/> : undefined}
                                   loadingPosition={isLoadingCreate ? 'end' : undefined}
                    >
                        {t('Save')}
                    </LoadingButton>
                </Box>

                <TextField label={t('Rule name')} value={form?.name} size={'small'}
                           required name={'name'} sx={{mt: '16px', '& label': {top: '0 !important'}}}
                           onChange={handleChange} />

                {/*<Box display={'flex'} sx={{mt: '8px'}} alignItems={'center'}>*/}
                {/*    <Typography variant={'body2'}>{t('Include this rule in the SoD scheduler')}</Typography>*/}
                {/*    <CommonSwitch sx={{ml: !revDir ? '16px' : undefined, mr: revDir ? '16px' : undefined}} disabled={!form || isLoadingData || isLoadingCreate}*/}
                {/*                  onChange={() => toggleActive(!form?.active)} checked={form?.active} label={form?.active ? t('Active') : t('Inactive')} />*/}

                {/*</Box>*/}

                <Autocomplete
                    size={"small"}
                    sx={{mt: '16px'}}
                    options={shortDuties.filter(e => e.id !== form?.firstDuty.id && e.id !== form?.secondDuty.id)}
                    value={form?.firstDuty}
                    onChange={(e, value) => value && handleSetFirstDuty(value)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params}
                                   required
                                   size={"small"}
                                   sx={{'& label': {top: '0 !important'}}}
                                   label={t('Add first duty')}  />
                    )}
                />

                <Autocomplete
                    size={"small"}
                    sx={{mt: '16px'}}
                    options={shortDuties.filter(e => e.id !== form?.firstDuty.id && e.id !== form?.secondDuty.id)}
                    value={form?.secondDuty}
                    onChange={(e, value) => value && handleSetSecondDuty(value)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params}
                                   required
                                   size={"small"}
                                   sx={{'& label': {top: '0 !important'}}}
                                   label={t('Add second duty')}  />
                    )}
                />

                <Autocomplete
                    size={"small"}
                    sx={{mt: '16px'}}
                    options={['LOW', 'MEDIUM', 'HIGH']}
                    value={form?.severityLevel}
                    onChange={(e, value) => value && handleSetSeverity(value)}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                        <TextField {...params}
                                   required
                                   size={"small"}
                                   sx={{'& label': {top: '0 !important'}}}
                                   label={t('Set severity level')}  />
                    )}
                />

                <TextField name={'securityRisk'} label={t("Add security risk")}  variant="outlined"
                           multiline minRows={5} sx={{mt: '16px'}} value={form?.securityRisk} onChange={handleChange}/>

                <TextField name={'securityMitigation'} label={t("Add security mitigation")}  variant="outlined"
                           multiline minRows={5} sx={{mt: '16px'}} value={form?.securityMitigation} onChange={handleChange}/>


            </Box>




        </Box>
    )
}