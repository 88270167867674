import {StartNode} from "./components/customNodes/startNode";
import {TriggerNode} from "./components/customNodes/triggerNode";
import {DocNode} from "./components/customNodes/docNode";
import {PlusNode} from "./components/customNodes/plusNode";
import {PlusEdge} from "./components/customEdges/plusEdge";
import {StageNode} from "./components/customNodes/stageNode";
import {SaveNode} from "./components/customNodes/saveNode";
import {DateNode} from "./components/customNodes/dateNode";
import {RepeatNode} from "./components/customNodes/repeatNode";
import {DocChildNode} from "./components/customNodes/docChildNode";
import {EmailNode} from "./components/customNodes/emailNode";
import {SaveChildNode} from "./components/customNodes/saveChildNode";
import {FinishNode} from "./components/customNodes/finishNode";

export const nodeTypes = {
    start: StartNode,
    finish: FinishNode,
    trigger: TriggerNode,
    doc: DocNode,
    docChild: DocChildNode,
    plus: PlusNode,
    stage: StageNode,
    email: EmailNode,
    save: SaveNode,
    saveChild: SaveChildNode,
    date: DateNode,
    repeat: RepeatNode,
};

export const edgeTypes = {
    plus: PlusEdge
}

export const rowGapY = 100; //doc x:550, y:0 -> docChild x:550, y: 100
export const rowGapX = 50;
export const columnGap = 260;
export const firstRowYDefault = -13; //rectangle custom nodes are bigger and of set then on y:0, they gonna be higher
export const ALLOWDRAG = true;

export const mapper: { [key: string]: number } = {
    Hours: 3600*1000,
    Days: 86400*1000,
    Weeks: 604800*1000,
    Months: 2592000*1000,
    Years: 31556952*1000,
}

export const timeValues: string[] = ['Hours','Days', 'Weeks', 'Months', 'Years'];

//paths
export const PATH_LOCAL_AUTOMATIONS = '/automations';
export const AUTOMATIONS_LIST_PATH = `${PATH_LOCAL_AUTOMATIONS}/list`;
export const AUTOMATIONS_EXACT_PATH = `${PATH_LOCAL_AUTOMATIONS}/exact/:autoId`;
export const AUTOMATIONS_CREATE_PATH = `${PATH_LOCAL_AUTOMATIONS}/create`;

export const AUTOMATIONS_DEFAULT_PAGING = 30;
export const AUTOMATION_HISTORY_DEFAULT_PAGING = 30;