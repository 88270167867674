import {
    Autocomplete,
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Table,
    TableCell,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import React, {Fragment} from "react";
import {PERSON_TARGET_TYPE} from "../../../constants";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

import {KycExactDictType} from "../../../types";
import {getKycDictionary, getStatusTitleAndColor} from "../../../helpers";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import colors from "../../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    CommonTableCell,
    ContentBox,
    DialogActionsBox,
    FileDropContainer,
    FlexBox,
    NameTableCell,
    OTSDialog,
    ResultContentBox,
    RightActionsBox,
    TargetDataBox,
    TemplateNameSpan
} from "./styled";
import * as S from "../../templatesChecksTable/styled";
import {InputClientDataDropZone} from "../inputClientDataTemplate/components/InputClientDataDropZone";
import {CommonChips} from "../../../../../../newShared/components/CommonChips";
import Divider from "@mui/material/Divider";
import {
    CommonHeaderTableRow,
    CommonTableBody,
    CommonTableContainer,
    CommonTableHead,
    TypographyBody2Grey
} from "../../../../../../newShared/components/style";
import {OverflowHiddenTextBody2, TypographySubtitle2} from "../../../../../../newShared/components/Inputs/styled";
import {ResultTableRow} from "../../../pages/screening/exact/style";
import {ActionTableCell, SmallTextButton} from "../../../pages/screening/style";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {useOneTimeScreening} from "../../../hooks/dialogs/useOneTimeScreening";
import {EmptyArrayImage} from "../../../../../../newShared/components/EmptyArrayImage";
import {DEFAULT_DATE_FORMAT} from "../../../../../../newShared/utils/dateTools";

export const OneTimeScreeningDialog = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.dialogs'});

    const {
        isOpen,
        isOpenClientDetails,
        isOpenFullReport,
        isLoading,
        template,
        response,
        dicts,
        form,
        isInputTargetData,
        selectedEntityId,
        isNoMatchesFound,

        handleClose,
        handleChangeCustomField,
        handleChangeDate,
        isOk,
        handleSubmit,
        handleNewScreening,
        handleFileUpload,
        handleDeleteUploadedFile,
        handleChangeSelectField,
        handleViewFullReport,
        handleDownloadFullReport,
        handleGoBack,
        onRatioChange,
        handleShowClientDetail,
    } = useOneTimeScreening();


    return(
        <>
            {template &&
                <OTSDialog open={isOpen && !isOpenClientDetails && !isOpenFullReport} onClose={handleClose} scroll={'paper'}>
                    <DialogTitle>{t('One-Time screening')}</DialogTitle>

                    <DialogContent sx={{overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
                        {isInputTargetData ?
                            <ContentBox>
                                {/*<Typography variant={'body2'} color={colors.text.grey}>{`${t('Check')}: `}<TemplateNameSpan>{('Regulait Sanction Check')}</TemplateNameSpan></Typography>*/}
                                {form.screeningSettings.checks.map(e => (
                                    <Fragment key={e.id}>
                                        <Typography variant={'body2'} color={colors.text.grey}>{`${t('Check')}: `}<TemplateNameSpan>{e.name}</TemplateNameSpan></Typography>
                                        <FlexBox>
                                            <Typography variant={'body2'} color={colors.text.grey}>{`${t('Matching ratio')}: `}</Typography>
                                            <Box width={'70%'} sx={{m: '8px 16px 0 16px'}}>
                                                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                                    <Typography color={!template ? colors.text.grey_dark : colors.grayText} variant={'body2'}>{('0')}</Typography>
                                                    <S.CustomSlider
                                                        size={'small'}
                                                        disabled={!template}
                                                        min={0}
                                                        max={100}
                                                        value={e?.matchingRatio ?? 0}
                                                        valueLabelDisplay={(e.apply) ? 'on' : undefined}
                                                        onChange={(event, value) => onRatioChange(e, value)}
                                                        valueLabelFormat={(a) => `${a / 100}`}
                                                    />
                                                    <Typography color={!template ? colors.text.grey_dark : colors.grayText} variant={'body2'}>{('1')}</Typography>
                                                </Stack>
                                            </Box>
                                        </FlexBox>
                                    </Fragment>

                                ))}

                                {template && template.fields
                                    .slice()
                                    .filter(e => e.system)
                                    .sort((a, b) => a.order - b.order)
                                    .map(e => {
                                        const currentField = form.fields.find(f => f.id === e.id);
                                        const currentDict = getKycDictionary(dicts, e.dictionary);
                                        switch (e.type){
                                            case 'TEXT': {
                                                return <TextField
                                                    key={e.id}
                                                    required={e.required}
                                                    label={e.name}
                                                    size={'small'}
                                                    name={e.id}
                                                    value={currentField?.value ?? ''}
                                                    onChange={handleChangeCustomField}
                                                />
                                            }
                                            case 'NUMBER': {
                                                return <TextField
                                                    key={e.id}
                                                    type={'number'}
                                                    required={e.required}
                                                    // placeholder={e.name}
                                                    label={e.name}
                                                    size={'small'}
                                                    name={e.id}
                                                    value={currentField?.value ?? ''}
                                                    onChange={handleChangeCustomField}
                                                />
                                            }
                                            case 'DATE': {
                                                return <DatePicker
                                                    key={e.id}
                                                    label={e.name}
                                                    inputFormat={DEFAULT_DATE_FORMAT}
                                                    value={currentField?.value ?? null}
                                                    onChange={(newValue) => handleChangeDate(e.id, newValue)}
                                                    renderInput={(params) => <TextField {...params} required={e.required} error={false} size={'small'} name={e.id}/>}
                                                />
                                            }
                                            case 'FILE': {
                                                return <FileDropContainer key={e.id}>
                                                    <Typography fontSize={'12px'} fontWeight={600}>
                                                        {e.name}
                                                        {e.required && <span style={{color: colors.warningActions.red}}>*</span>}
                                                    </Typography>

                                                    <InputClientDataDropZone
                                                        onFileUploaded={handleFileUpload}
                                                        handleDelete={() => currentField && handleDeleteUploadedFile(e.id)}
                                                        isLoadingCreateTarget={isLoading}
                                                        id={e.id}
                                                    />
                                                </FileDropContainer>
                                            }
                                            case 'SELECT': {
                                                return <Autocomplete
                                                    key={e.id}
                                                    id="combo-box-demo"
                                                    options={currentDict.slice().sort((a, b) => a.order - b.order)}
                                                    value={currentDict.find(f => f.key === currentField?.value)}
                                                    isOptionEqualToValue={(a: KycExactDictType, b:  KycExactDictType | undefined) => b !== undefined && a.key === b.key}
                                                    getOptionLabel={(e) => e.value}
                                                    onChange={(event, value) => handleChangeSelectField(e.id, value)}
                                                    sx={{width: '100%'}}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label={e.name}
                                                        required={e.required}
                                                        fullWidth
                                                        error={false}
                                                        size={'small'}
                                                        name={e.id}
                                                        // sx={{'& fieldset': {borderColor: 'black !important'}}}
                                                    />}
                                                />
                                            }
                                            default: return <></>;
                                        }
                                    })}
                            </ContentBox>
                            :
                            <ResultContentBox>
                                <TargetDataBox>
                                    <Typography variant={'body2'} color={colors.text.grey}>{`${t('Client name')}: `}<TemplateNameSpan>{response?.targetName}</TemplateNameSpan></Typography>
                                    <CommonChips settings={{...getStatusTitleAndColor(response?.targetStatus ?? '')}}/>
                                    <Button size={'small'} variant={'text'} onClick={handleShowClientDetail} sx={{textTransform: 'none'}}>{t('Client details')}</Button>
                                </TargetDataBox>
                                <Typography variant={'body2'} color={colors.text.grey}>{`${t('Template name')}: `}<TemplateNameSpan>{response?.templateName}</TemplateNameSpan></Typography>
                                <Typography variant={'body2'} color={colors.text.grey}>{`${t('Matching ratio')}: `}<TemplateNameSpan>{response?.matchingRatio ?? ''}</TemplateNameSpan></Typography>

                                <Divider flexItem variant={'fullWidth'} sx={{mt: '10px', bgcolor: colors.primary.disable, borderColor: colors.primary.disable}}/>

                                <EmptyArrayImage
                                    type={'kycScreenings'}
                                    isShowing={isNoMatchesFound}
                                    text={t('No matches to display')}
                                    secondaryText={t('It seems that no matches are found according to this client\'s information.')}
                                    withoutAdd
                                />

                                {!isNoMatchesFound &&
                                    <CommonTableContainer>
                                        <Table stickyHeader>
                                            <CommonTableHead>
                                                <CommonHeaderTableRow >
                                                    <TableCell colSpan={3}>
                                                        <TypographySubtitle2 color={colors.text.grey}>{`${t('Total matches')}: ${response?.results?.length ?? ''}`}</TypographySubtitle2>
                                                    </TableCell>
                                                </CommonHeaderTableRow>
                                                <ResultTableRow>
                                                    <NameTableCell>
                                                        <TypographyBody2Grey>{(response && response.targetType === PERSON_TARGET_TYPE) ? t('Person matched') : t('Organization matched')}</TypographyBody2Grey>
                                                    </NameTableCell>
                                                    <CommonTableCell>
                                                        <TypographyBody2Grey>{t('Score')}</TypographyBody2Grey>
                                                    </CommonTableCell>
                                                    <ActionTableCell >
                                                        <TypographyBody2Grey>{t('Report')}</TypographyBody2Grey>
                                                    </ActionTableCell>
                                                </ResultTableRow>
                                            </CommonTableHead>
                                            <CommonTableBody>
                                                {response?.results && response.results.map((res) => {

                                                    return (
                                                        <TableRow key={res.entityId} selected={res.entityId === selectedEntityId}>
                                                            <NameTableCell>
                                                                <OverflowHiddenTextBody2>{res.entityCaption}</OverflowHiddenTextBody2>
                                                            </NameTableCell>
                                                            <CommonTableCell>
                                                                <TypographyBody2Grey>{res.score.toString().substring(0, 4)}</TypographyBody2Grey>
                                                            </CommonTableCell>
                                                            <CommonTableCell>
                                                                <Flex ai={'center'} gap={'16px'}>
                                                                    <SmallTextButton onClick={(e) => handleViewFullReport(e, res)}>{t('View report')}</SmallTextButton>

                                                                    <IconButton size={'small'} onClick={() => handleDownloadFullReport(res.entityId, res.entityCaption)}>
                                                                        <FileDownloadIcon color={'primary'} />
                                                                    </IconButton>

                                                                </Flex>
                                                            </CommonTableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </CommonTableBody>

                                        </Table>
                                    </CommonTableContainer>
                                }

                            </ResultContentBox>
                        }
                    </DialogContent>

                    <DialogActions>
                        {isInputTargetData ?
                            <>
                                <Button onClick={handleClose} variant={'text'} sx={{textTransform: 'none'}} size={'small'}>{t('Cancel')}</Button>
                                {isInputTargetData && <LoadingButton onClick={handleSubmit} size={'small'} disabled={!isOk()} loading={isLoading}>{t('Start screening')}</LoadingButton>}
                            </>
                            :
                            <DialogActionsBox>
                                <SmallTextButton startIcon={<ArrowBackIosIcon style={{fontSize: 'small'}} />} onClick={handleGoBack}>{tCommon('Back')}</SmallTextButton>

                                <RightActionsBox>
                                    <Button onClick={handleClose} variant={'text'} sx={{textTransform: 'none'}} size={'small'}>{tCommon('Close')}</Button>
                                    <Button onClick={handleNewScreening} size={'small'}>{t('New screening')}</Button>
                                </RightActionsBox>
                            </DialogActionsBox>
                        }
                    </DialogActions>
                </OTSDialog>
            }
        </>
    )
}
