import {FC, useEffect} from 'react';
import {LeftSecondMenu} from "../../barsEnvironment/leftMenu/components/leftSecondMenu";
import {Routes} from "./routes";
import {cleanUp} from "./store/slice";
import {useDispatch} from "react-redux";
import {GetDataForCreateApp} from "./store/actions";


export const AccessManagement:FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetDataForCreateApp()); //employees array has to be fetched to locally map (not external) rows to employee
        return () => {
            dispatch(cleanUp());
        }
        //eslint-disable-next-line
    }, []);


    return(
        <LeftSecondMenu>
            <Routes/>
        </LeftSecondMenu>
    )
}
