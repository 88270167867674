import {useParameters} from "../../../../../../newShared/hooks/useParameters";
import {useReports} from "../../../hooks/useReports";
import {useFormik} from "formik";
import {TCreateReport, TCreateReportControl} from "../../../types";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_COMPLIANCE_REPORTS} from "../../../../../../newShared/constants";
import * as yup from "yup";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {ChangeEvent, useEffect, useState} from "react";

const validationSchema = yup.object({
    name: yup
        .string()
        .min(1, 'Name is required')
        .required(),
    reportType: yup
        .string()
        .min(1, 'Type is required')
        .required(),
    auditorEmail: yup
        .string()
        .email('Email not valid')
        .required(),
    frameworkId: yup
        .string()
        .min(1, 'Framework is required')
        .required(),
});

export const useNewReportPage = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathReports.NewReport'});
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    const {filter: {frameworkId}} = useParameters();

    const {
        newReport: {isLoading, isLoadingFrameworks, frameworks, reportId, controls: _controls, isLoadingControls},
        createReport, getFrameworksIdNameLogo, getDataForCreateReport,
        cleanNewReport,
        goToReport,
    } = useReports();

    const [controls, setControls] = useState<TCreateReportControl[]>(_controls);
    const [selectedControl, setSelectedControl] = useState<string>(_controls.length === 1 ? _controls[0].id : '');
    const {
        handleChange, handleSubmit,
        setFieldTouched, touched,
        values, errors,
        resetForm, validateForm,
    } = useFormik<TCreateReport>({
        initialValues: {
            name: '',
            reportType: 'SNAPSHOT',
            frameworkId: frameworkId?.[0] || '',
            auditorEmail: '',
            useNcr: true,
        },
        validationSchema: validationSchema,
        validateOnChange: true,
        onSubmit: (values) => {
            createReport(values, controls)
        },
    });

    const types: {value: string, label: string, disable?: boolean}[] = [
        {value: 'SNAPSHOT', label: t('Snapshot')},
        {value: 'LIVE_LITE', label: t('Live (lite view)'), disable: true},
        {value: 'LIVE_FULL', label: t('Live (full view)'), disable: true},
    ];

    //Breadcrumbs
    useSetBreadcrumbs([
        {
            title: tMenu('Reports'),
            path: PATH_LOCAL_COMPLIANCE_REPORTS,
        },
        {
            title: t('Generate new report'),
        },
    ]);

    //Controller and first loadData
    const controller = new AbortController();
    useEffect(() => {
        getFrameworksIdNameLogo();
        validateForm();
        return () => {
            controller.abort();
            resetForm();
            cleanNewReport();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        reportId && goToReport(reportId, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportId])

    useEffect(() => {
        setControls(_controls);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_controls])

    useEffect(() => {
        values.frameworkId && getDataForCreateReport(values.frameworkId, controller.signal);
        return () => {controller.abort()}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.frameworkId])

    const checkControl = (controlId: string) => (event: ChangeEvent<HTMLInputElement>) => {
        setControls(prev => prev.map(co => co.id !== controlId ? co : {...co, active: event.target.checked}))
    }
    const checkEvidence = (controlId: string, evidenceId: string) => (event: ChangeEvent<HTMLInputElement>) => {
        setControls(prev => prev.map(co =>
            co.id !== controlId
                ? co
                : {...co, evidences: co.evidences.map(ev => ev.id !== evidenceId ? ev : {...ev, active: event.target.checked})}
        ))
    }
    const checkEvidenceAsset = (controlId: string, evidenceId: string, assetId: string) => (event: ChangeEvent<HTMLInputElement>) => {
        setControls(prev => prev.map(co =>
            co.id !== controlId
                ? co
                : {...co, evidences: co.evidences.map(ev =>
                        ev.id !== evidenceId
                            ? ev
                            : {...ev, assets: ev.assets.map(as => as.id !== assetId ? as : {...as, active: event.target.checked})}
                    )}
        ))
    }

    //Select/Deselect all
    const isSelectedAll = controls.some(e => e.active);
    const handleDeselectAll = () => {
        setControls(_controls.map(e => {return {
            ...e,
            active: false,
            evidences: e.evidences.map(ev => {
                return {...ev,
                    active: false,
                    assets: ev.assets.map(a => {
                        return {
                            ...a,
                            active: false
                        }}
                    )}
            })
        }}))
    }
    const handleSelectAll = () => {
        setControls(_controls.map(e => {return {
            ...e,
            active: true,
            evidences: e.evidences.map(ev => {
                return {...ev,
                    active: true,
                    assets: ev.assets.map(a => {
                        return {
                            ...a,
                            active: true
                        }}
                    )}
            })
        }}))
    }

    return {
        types, frameworks, controls,
        isLoading, isLoadingFrameworks, isLoadingControls,
        values, touched, errors,
        handleChange, handleSubmit, setFieldTouched,
        checkControl, checkEvidence, checkEvidenceAsset,
        selectedControl, setSelectedControl,
        selectDeselectAll: {
            isSelectedAll,
            handleDeselectAll,
            handleSelectAll
        }
    }
}