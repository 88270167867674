import React, {FC, useEffect} from "react";
import {Spinner} from "../Spinner";
import {useTemplatePreview} from "./hooks/useTemplatePreview";
import EditorDialog from "../editorUnion";
import {useSnack} from "../../../newDomain/barsEnvironment/snack/hooks/useSnack";
import {NewDocumentModel} from "../../GQLTypes";

interface Props {
    handleUseTemplate: (template: NewDocumentModel) => void;
}
export const DocTemplateViewer: FC<Props> = ({handleUseTemplate}) => {
    const {
        isOpen,
        isLoading: {isLoadingTemplate},
        document,

        handleClose,

        controller,
    } = useTemplatePreview();

    const snack = useSnack();

    useEffect(() => {
        !isOpen && controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return isOpen ? (
        isLoadingTemplate
            ? <Spinner/>
            : document?.data?.editor && (
            <EditorDialog documentTitle={document.name}
                          content={document.data.editor}
                          variables={document.data.variables || []}
                          actors={{
                              recipients: document.recipients || [],
                              approvers: [],
                              editors: [],
                          }}

                          currentEmail={''}
                          currentFileStatus={'TEMPLATE'}
                          execution={undefined}

                          onClose={handleClose}
                          handleUseTemplate={() => handleUseTemplate(document)}

                          snack={snack}
                          actualEnvironment={"isMain"}

                          isOpen
                          isLoading={{
                              isSave: false,
                              isPrev: false,
                              isInvalidating: false,
                              isRejecting: false,
                              isApproving: false,
                              isReinviting: false,
                              isSkipping: false,
                              isFillingField: false,
                              isSending: false,
                              isChangingEtaInProgress: false,
                          }}

                          onSave={() => null}
                          onSend={() => null}
                          onPreviewBeforeSend={() => null}
                          onInvalidate={() => null}
                          onApproveSignStage={() => null}
                          onApproveApproveStage={() => null}
                          onRejectSignStage={() => null}
                          onRejectApproveStage={() => null}
                          onReturnToDraft={() => null}
                          onFillFields={() => null}
                          onResendInviteApprover={() => null}
                          onSkipApprover={() => null}
                          onResendInviteRecipient={() => null}
                          onChangeEta={() => null}
            />
        )
    ) : null
}