import {EmployeeType, TEmployeeStatus} from "../../types";
import {Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {Flex} from "../../../../../newShared/components/Layouts";
import {
    CITY_COLUMN_KEY,
    COUNTRY_COLUMN_KEY,
    DEP_COLUMN_KEY,
    DIVISION_COLUMN_KEY,
    EMP_TYPE_COLUMN_KEY,
    JOB_TITLE_COLUMN_KEY,
    LAST_JOB_START_DATE_COLUMN_KEY,
    LOCATION_COLUMN_KEY,
    MANAGER_COLUMN_KEY,
    PHONE_COLUMN_KEY,
    PHONE_NUMBER_CONTACT_TYPE,
    STREET_COLUMN_KEY,
    ZIP_COLUMN_KEY
} from "../../constants";
import React from "react";

export const ValueGetter = ({_key, employee, isDisabled}: {_key: string, employee?: EmployeeType | null | undefined, isDisabled: boolean}) => {
    // keyof EmployeeType
    if(!employee){
        return <Typography variant={'subtitle1'} color={isDisabled ? colors.text.grey : colors.grayText} noWrap>{('N/A')}</Typography>
    }

    if(Object.keys(employee).some(e => e === _key)){
        //its surface entry
        const newKey = _key as keyof EmployeeType;

        if(typeof employee[newKey] === 'string' && newKey !== "birthDay" && newKey !== "hireDate"){
            return <Typography variant={'subtitle1'} color={isDisabled ? colors.text.grey : colors.grayText} noWrap>{employee[newKey]}</Typography>
        }else if(newKey === "birthDay" || newKey === "hireDate"){
            return <Typography variant={'subtitle1'} color={isDisabled ? colors.text.grey : colors.grayText} noWrap>{parseDateAuto(employee[newKey])}</Typography>
        }

        if(newKey === "status"){
            return <StatusChip status={employee.status}/>;
        }
        //fallback
        return <Typography variant={'subtitle1'} color={isDisabled ? colors.text.grey : colors.grayText} noWrap>{('N/A')}</Typography>
    }else{
        switch (_key){
            case PHONE_COLUMN_KEY: {
                const phone = employee.contactInfo.contacts.find(e => e.type === PHONE_NUMBER_CONTACT_TYPE)?.value ?? '-';
                return <Typography variant={'subtitle1'} color={isDisabled ? colors.text.grey : colors.grayText} noWrap>{phone}</Typography>
            }
            case COUNTRY_COLUMN_KEY: {
                const country = employee.contactInfo.address.country ?? '-';
                return <Typography variant={'subtitle1'} color={isDisabled ? colors.text.grey : colors.grayText} noWrap>{country ?? '-'}</Typography>
            }
            case CITY_COLUMN_KEY: {
                const city = employee.contactInfo.address.city ?? '-';
                return <Typography variant={'subtitle1'} color={isDisabled ? colors.text.grey : colors.grayText} noWrap>{city ?? '-'}</Typography>
            }
            case STREET_COLUMN_KEY: {
                const street = employee.contactInfo.address.street ?? '-';
                return <Typography variant={'subtitle1'} color={isDisabled ? colors.text.grey : colors.grayText} noWrap>{street ?? '-'}</Typography>
            }
            case ZIP_COLUMN_KEY: {
                const zip = employee.contactInfo.address.zip ?? '-';
                return <Typography variant={'subtitle1'} color={isDisabled ? colors.text.grey : colors.grayText} noWrap>{zip ?? '-'}</Typography>
            }
            case JOB_TITLE_COLUMN_KEY: {
                const currHistory = employee.jobInfos.find(e => e.current);
                return <Typography variant={'subtitle1'} color={isDisabled ? colors.text.grey : colors.grayText} noWrap>{currHistory?.jobTitle?.name ?? '-'}</Typography>
            }
            case DEP_COLUMN_KEY: {
                const currHistory = employee.jobInfos.find(e => e.current);
                return <Typography variant={'subtitle1'} color={isDisabled ? colors.text.grey : colors.grayText} noWrap>{currHistory?.department ?? '-'}</Typography>
            }
            case LOCATION_COLUMN_KEY: {
                const currHistory = employee.jobInfos.find(e => e.current);
                return <Typography variant={'subtitle1'} color={isDisabled ? colors.text.grey : colors.grayText} noWrap>{currHistory?.location ?? '-'}</Typography>
            }
            case EMP_TYPE_COLUMN_KEY: {
                const currHistory = employee.jobInfos.find(e => e.current);
                return <Typography variant={'subtitle1'} color={isDisabled ? colors.text.grey : colors.grayText} noWrap>{currHistory?.employmentType ?? '-'}</Typography>
            }
            case DIVISION_COLUMN_KEY: {
                const currHistory = employee.jobInfos.find(e => e.current);
                return <Typography variant={'subtitle1'} color={isDisabled ? colors.text.grey : colors.grayText} noWrap>{currHistory?.division ?? '-'}</Typography>
            }
            case MANAGER_COLUMN_KEY: {
                const currHistory = employee.jobInfos.find(e => e.current);
                return <Typography variant={'subtitle1'} color={isDisabled ? colors.text.grey : colors.grayText} noWrap>{currHistory?.manager?.name ?? '-'}</Typography>
            }
            case LAST_JOB_START_DATE_COLUMN_KEY: {
                const currHistory = employee.jobInfos.find(e => e.current);
                return <Typography variant={'subtitle1'} color={isDisabled ? colors.text.grey : colors.grayText} noWrap>{parseDateAuto(currHistory?.startDate)}</Typography>
            }
            default: {
                return <Typography variant={'subtitle1'} color={isDisabled ? colors.text.grey : colors.grayText} noWrap>{(`unknown type: ${_key}`)}</Typography>
            }
        }
    }
}

export const StatusChip = ({status, margin}: {status: TEmployeeStatus | null, margin?: string}) => {
    return(
        <Flex m={margin}>
            <Flex background={status?.backgroundColor ?? colors.backgrounds.grey_light} p={'6px 14px'} br={'15px'}>
                <Typography color={status?.textColor ?? colors.grayText} variant={'body2'}>{status?.name ?? 'Unknown status'}</Typography>
            </Flex>
        </Flex>
    )
}