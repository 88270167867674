import {FileDrop} from "react-file-drop";
import React, {FC} from "react";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {ReactComponent as BlankIcon} from "../../../../../../../newShared/images/documents/add-document-blank-icon.svg";
import {
    ReactComponent as DisabledBlankIcon
} from "../../../../../../../newShared/images/documents/add-document-disabled-blank-icon.svg";
import {ReactComponent as FileIcon} from "../../../../../../../newShared/images/documents/document-icon.svg";
import * as S from "./styled";
import {TNewDocVariant} from "../../../../types";
import {DragAreaTypo, DragAreaWrapper} from "../../../../pages/styled";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {TypographyBodySmallSemiBold} from "../../../../../../../newShared/components/Inputs/styled";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";

interface StartDocumentViewProps {
    newDocVariant: TNewDocVariant;
    handleSetBlank: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    refForInput:  React.LegacyRef<HTMLInputElement>;
    fileName: string | null;
    isDrag: boolean;
    setDrag: (isDrag: boolean) => void;
    isAbleToDrop: boolean;
    fileHandler: (files: FileList | null) => void;
    handleDeleteFile: () => void;
    filePicker: () => void;
}



export const StartDocumentView: FC<StartDocumentViewProps> = (props) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});
    const {
        newDocVariant,
        handleSetBlank,
        refForInput,
        fileName,
        isDrag,
        setDrag,
        isAbleToDrop,
        fileHandler,
        handleDeleteFile,
        filePicker,
    } = props;


    return (
        <S.StartDocumentContainer>
            <FileDrop
                onDrop={(f) => isAbleToDrop && fileHandler(f)}
                onFrameDragEnter={() => isAbleToDrop && setDrag(true)}
                onFrameDragLeave={() => setDrag(false)}
                onFrameDrop={() => setDrag(false)}
                // className={`${classes.fileDropTarget} file-drop`}
            >
                {/*DRAGGABLE AREA*/}
                {isDrag && isAbleToDrop &&
                    <DragAreaWrapper>
                        <UploadFileIcon fontSize="large" color={'primary'}/>
                        <DragAreaTypo>{t('Drop file here')}</DragAreaTypo>
                    </DragAreaWrapper>
                }

                <S.BlankOrInputContainer>
                    <S.StartBlankBox onClick={handleSetBlank} isSelected={newDocVariant === 'BLANK' ? true : undefined}>
                        {!fileName ? <BlankIcon/> : <DisabledBlankIcon />}
                        <S.TitleBlankBox>
                            <S.TitleBlank>{t('Blank')}</S.TitleBlank>
                        </S.TitleBlankBox>

                    </S.StartBlankBox>

                    <TypographyBodySmallSemiBold>{'or'}</TypographyBodySmallSemiBold>

                    <S.UploadFileBox isDisabled={!!fileName ? true : undefined} onClick={filePicker}>
                        <S.UploadFileIconBox>
                            <UploadFileIcon />
                        </S.UploadFileIconBox>

                        <S.UploadFileTitleBox>
                            <S.TypographyBody2Grey isDisabled={!!fileName ? true : undefined}>{t('Drop your file here or')}</S.TypographyBody2Grey>
                            <S.TypographyBody2Blue isDisabled={!!fileName ? true : undefined} >{t('click to upload')}</S.TypographyBody2Blue>
                        </S.UploadFileTitleBox>
                    </S.UploadFileBox>
                </S.BlankOrInputContainer>

                {fileName &&
                    <S.UploadedFileContainer>
                        <FileIcon />
                        <S.UploadedFileNameTitle>{fileName}</S.UploadedFileNameTitle>
                        <S.DeleteUploadedFileIconButton onClick={handleDeleteFile}>
                            <DeleteOutlineSharpIcon fontSize={'small'}/>
                        </S.DeleteUploadedFileIconButton>
                    </S.UploadedFileContainer>
                }
                <input
                    accept={'.pdf, .doc, .docx, .xls, .xlsx'}
                    style={{ visibility: "hidden", opacity: 0}}
                    ref={refForInput}
                    type="file"
                    onChange={(e) => fileHandler(e.target.files)}
                />

            </FileDrop>
        </S.StartDocumentContainer>
    )
}
