import {
    SectionShortDataModel
} from "../../../../../newShared/GQLTypes";
import {Checkbox, TableCell, TableRow, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {Flex} from "../../../../../newShared/components/Layouts";
import {deselectExistingSection, sectionsToAddSelector, selectExistingSection} from "../../store/slice";
import {useDispatch, useSelector} from "react-redux";
import {sectionTypeImageMapper} from "../../helpers";

export const SectionToAddTableRow = ({section}: {section: SectionShortDataModel}) => {
    const {selectedSections} = useSelector(sectionsToAddSelector);
    const [checked, setChecked] = useState(selectedSections.some(e => e.sectionId === section.sectionId));
    const dispatch = useDispatch();

    const sectionTitleImage = section.sectionType ? sectionTypeImageMapper(section.sectionType) : {sectionTitle: '-', sectionImage: '-'};

    const handleSelectSection = () => {
        if(!selectedSections.some(s => s.sectionId === section.sectionId)) {
            dispatch(selectExistingSection(section));
            setChecked(true);
        }else{
            dispatch(deselectExistingSection(section));
            setChecked(false);
        }
    }
    const isSectionSelected = () => {
        return selectedSections.some(e => e.sectionId === section.sectionId);
    }
    useEffect(() => {
        if(selectedSections.some(e => e.sectionId === section.sectionId)) {
            setChecked(true);
        } else {
            setChecked(false);
        }
        // eslint-disable-next-line
    }, [isSectionSelected])

    return (
        <TableRow>
            <TableCell colSpan={3}>
                    <Flex ai={'center'} jc={'flex-start'} style={{marginLeft: '50px'}}>
                    <Checkbox checked={checked} onChange={handleSelectSection} />
                <Typography variant={'*bodyText2'}>{section.sectionName}</Typography>
                </Flex>
            </TableCell>
            <TableCell>
                <Flex ai={'center'} gap={'8px'} jc={'flex-start'} style={{marginLeft: '30px'}}>
                    {sectionTitleImage?.sectionImage ?? '-'}
                    <Typography variant={'*bodyText2'}>{sectionTitleImage?.sectionTitle ?? '-'}</Typography>
                </Flex>
            </TableCell>
        </TableRow>
    )

}