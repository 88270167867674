import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {useCancelDelegatedTask} from "../../../hooks/dialogHooks/useCancelDelegatedTask";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";


export const CancelDelegatedTask = () => {
    const {t, tCommon} = useMainTranslation("", {keyPrefix: "pathFrameworks.Dialogs"});

    const {isOpen, formik, isLoading, handleClose, task, handleCancelTask } = useCancelDelegatedTask();

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Cancel reason')}</DialogTitle>
            <DialogContent>
                <Flex direction={'column'} gap={'16px'}>
                    <Typography>
                        {t('Are you sure you want to cancel task ')}
                        <b>{task?.id}</b>?
                    </Typography>
                    <Typography>{`${t('Please provide cancel reason')}:`}</Typography>
                    <TextField
                        required
                        label={('Comment')}
                        multiline
                        minRows={5}
                        name={'comment'}
                        onChange={formik.handleChange}
                        value={formik.values.comment}
                        onBlur={formik.handleBlur}
                        error={Boolean(formik.touched.comment && formik.errors.comment) }
                        helperText={formik.touched.comment && formik.errors.comment}
                    />
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button variant={'text'} size={'small'} onClick={handleClose}>{tCommon('Close')}</Button>
                <LoadingButton variant={"contained"} onClick={handleCancelTask} loading={isLoading} disabled={!formik.isValid || !formik.values.comment.trim().length} size={'small'}>
                    {tCommon('Confirm')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )


}
