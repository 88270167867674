import colors from "../../../newShared/theme/colors";

export const getReportStatus = (t: (str: string) => string, status?: string): {color: string, status: string} => {
    switch (status) {
        case 'IN_REVIEW': {return {color: colors.decorative.blue_light, status: t('In review')}}
        case 'PUBLISHED': {return {color: colors.decorative.orange, status: t('NCR Published')}}
        case 'RESPONSE': {return {color: colors.decorative.violet, status: t('NCR Response')}}
        case 'COMPLETED': {return {color: colors.text.success, status: t('Completed')}}
        case 'REJECTED': {return {color: colors.warningActions.red, status: t('Rejected')}}
        default: {return {color: colors.text.dark, status: status || ''}}
    }
}

export const getReportType = (t: (str: string) => string, type?: string): string => {
    switch (type) {
        case 'SNAPSHOT': {return t('Snapshot')}
        case 'LIVE_LITE': {return t('Live (lite view)')}
        case 'LIVE_FULL': {return t('Live (full view)')}
        default: {return type || ''}
    }
}

export const getNCRStatus = (t: (str: string) => string, status?: string | null | (string | null | undefined)[]): {color: string, status: string} => {
    if (typeof status === 'string' || !status) {
        switch (status) {
            case 'OPEN': {return {color: colors.text.blue, status: t('Open')}}
            case 'REVIEW': {return {color: colors.decorative.orange, status: t('Review')}}
            case 'REVOKE': {return {color: colors.text.warning, status: t('Revoked')}}
            case 'APPROVED': {return {color: colors.text.success, status: t('Approved')}}
            case 'REJECTED': {return {color: colors.text.critical, status: t('Rejected')}}
            default: {return {color: colors.text.dark, status: status || '---'}}
        }
    } else {
        const statuses = status.filter(e => e);
        if (statuses.includes('OPEN')) {return {color: colors.text.blue, status: t('Open')}}
        else if (statuses.includes('REVIEW')) {return {color: colors.decorative.orange, status: t('Review')}}
        else if (statuses.includes('REVOKE')) {return {color: colors.text.warning, status: t('Revoked')}}
        else if (statuses.includes('APPROVED')) {return {color: colors.text.success, status: t('Approved')}}
        else if (statuses.includes('REJECTED')) {return {color: colors.text.critical, status: t('Rejected')}}
        else {return {color: colors.text.dark, status: '---'}}
    }
}