import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {cleanUpAction} from "../../store/slice";

export const useMain = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(cleanUpAction());
        }
        //eslint-disable-next-line
    }, []);

}