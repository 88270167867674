import {useDispatch, useSelector} from "react-redux";
import {hideDeclineInviteDialog, invitesDialogs, invitesLoadings} from "../../../store/slice";
import {DeclineInvite} from "../../../store/actions";

export const useDeclineInviteDialog = () => {
    const dispatch = useDispatch();
    const {decline} = useSelector(invitesLoadings);
    const {declineInvite: {isOpen, invite}} = useSelector(invitesDialogs);

    const handleClose = () => dispatch(hideDeclineInviteDialog());

    const handleDeclineInvite = () => {
        invite && dispatch(DeclineInvite({
            inviteId: invite.id,
        }));
    }

    return{
        handleClose,
        handleDeclineInvite,
        isLoading: decline.isLoading,
        isOpen,
        invite
    }
}