import {useDispatch, useSelector} from "react-redux";
import {controls, editControlDialog, hideEditControl, isLoading} from "../../../store/builder/slice";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {controlTypeInput} from "../../../types";
import React, {useEffect, useState} from "react";
import {EditControl} from "../../../store/builder/actions";


export const useEditControl = () => {

    const dispatch = useDispatch();
    const {isOpen, controlId} = useSelector(editControlDialog);
    const _controls = useSelector(controls);
    const _isLoading = useSelector(isLoading);
    const {currData} = UseManageWorkspacesAndOrganizations();

    const emptyControl: controlTypeInput = {
        name: '',
        group: '',
        category: '',
        description: [],
        id: '',
        origin: false,
        organizationId: currData.selectedOrganization?.id || '',
        visibility: 'PRIVATE',
        evidences: [],
        foundInFrameworksCounter: 0,
    }

    const [form, updateForm] = useState<controlTypeInput>(emptyControl);
    const [currentControl, setCurrentControl] = useState<controlTypeInput>(emptyControl);

    useEffect(() => {
        const currControl = _controls.find(e => e.id === controlId);
        if (currControl?.id && typeof currControl.id === "string"){
            updateForm(currControl);
            setCurrentControl(currControl);
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const handleClose = () => {
        dispatch(hideEditControl());
        updateForm(emptyControl);
    }

    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        updateForm((prevValues) => ({
            ...prevValues,
            [event.target.name]: event.target.value as string,
        }));
    };

    const handleChangeDesc = (event:React.ChangeEvent<HTMLInputElement>) => {
        updateForm((prevValues) => ({
            ...prevValues,
            description: [event.target.value as string],
        }));
    }

    // const handleSelectVisibility = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    //     updateForm((prevValues) => ({
    //         ...prevValues, visibility: value,
    //     }));
    // }

    const validateForm = (): boolean => {
        return (
            JSON.stringify(currentControl) !== JSON.stringify(form)
            && form.name.trim().length > 0
            // && form.group.trim().length > 0
            // && form.category.trim().length > 0
            // && form.description.length > 0
            // && form.organizationId!.length > 0
        )
    }

    const onSave = () => {
        if (validateForm()) dispatch(EditControl({control: form}));
    }

    return {
        isOpen,
        isLoading: _isLoading,
        form,
        currentControl,
        handleChange,
        handleClose,
        handleChangeDesc,
        validateForm,
        onSave,
    }
}