import {useDispatch, useSelector} from "react-redux";
import {employeeHistoryPageInfo, loadings, selectedEmployee} from "../../store/slice";
import {useScrollPagination} from "../../../../../newShared/hooks/useScrollPagination";
import {GetHrEmployeeJobInfosById} from "../../store/actions";
import {EMPLOYEES_LIST_DEFAULT_PAGING} from "../../constants";

export const useJobInfosTableOrCardsScroll = () => {
    const dispatch = useDispatch();
    const _pageInfo = useSelector(employeeHistoryPageInfo);
    const empl = useSelector(selectedEmployee);
    const isLoading = useSelector(loadings).getEmployeeHistory;

//PAGING
    const {onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => {
            dispatch(GetHrEmployeeJobInfosById({data: {pageInfo: {page, count: EMPLOYEES_LIST_DEFAULT_PAGING}, id: empl?.id ?? ''}}))
        },
        arrayLength: empl?.jobInfos.length ?? 0,
        isLoading,
        pageInfo: _pageInfo,
        // controller: controller,
        // isOnTop: true,
        isNoMoreThanTotal: true,
        customDependencies: [],
        is0PageAddictive: false,
        disableInitFetch: true,
        disableClearOnUnmount: true
    })

    return{
        onScroll,
        scrollRef,
    }
}