import {FC} from "react";
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import {AlignmentIconButton} from "./styled";
import {withShrinkZero} from "../../../hoc/withShrinkZero";
import {TFontAlignment, TToolBarHandlerAction, TToolBarHandlerPayload} from "../../types";
import {EditorTooltip} from "../../../commonComponents/editorTooltip";

interface AlignmentControlProps {
    initialAlignment: TFontAlignment;
    changeAlignmentHandler?: (action: TToolBarHandlerAction, payload: TToolBarHandlerPayload) => void;
    isDisabled: boolean
}

const AlignmentControl: FC<AlignmentControlProps> = ({ initialAlignment , changeAlignmentHandler = () => {}, isDisabled}) => {
    return <>
        <EditorTooltip title="Left">
            <span>
                <AlignmentIconButton active={(initialAlignment === 'left') || undefined}
                                     disabled={isDisabled}
                                     size="medium"
                                     edge={undefined}
                                     onClick={(e) => {
                                         e.stopPropagation();
                                         changeAlignmentHandler("alignmentControl", "left");
                                     }}>
                <FormatAlignLeftIcon/>
            </AlignmentIconButton>
            </span>
        </EditorTooltip>
        <EditorTooltip title="Center">
            <span>
                <AlignmentIconButton active={(initialAlignment === "center") || undefined}
                                     disabled={isDisabled}
                                     size="medium"
                                     edge={undefined}
                                     onClick={(e) => {
                                         e.stopPropagation();
                                         changeAlignmentHandler("alignmentControl", "center");
                                     }}>
                <FormatAlignCenterIcon/>
            </AlignmentIconButton>
            </span>
        </EditorTooltip>
        <EditorTooltip title="Right">
            <span>
                <AlignmentIconButton active={(initialAlignment === "right") || undefined}
                                     disabled={isDisabled}
                                     size="medium"
                                     edge={undefined}
                                     onClick={(e) => {
                                         e.stopPropagation();
                                         changeAlignmentHandler("alignmentControl", "right");
                                     }}>
                <FormatAlignRightIcon/>
            </AlignmentIconButton>
            </span>
        </EditorTooltip>
    </>;
}

const wrapper = withShrinkZero<AlignmentControlProps>(AlignmentControl);

export {wrapper as AlignmentControl};
