import {createAsyncThunk} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {getActionsData} from "../../../../newShared/redux";
import {saveAs} from "file-saver";
import {b64toBlob} from "../../../../newShared/utils/base64/base64";

import {
    ChangeAssetStatusMutationVariables,
    CreateCertificateAssetMutationVariables,
    CreateCertificateAssetSettingRowMutationVariables,
    CreatePhysicalAssetMutationVariables,
    CreatePhysicalAssetSettingRowMutationVariables,
    CreateSaasAssetMutationVariables,
    CreateSaasAssetSettingRowMutationVariables,
    CreateSoftwareAssetMutationVariables,
    CreateSoftwareAssetSettingRowMutationVariables,
    CreateVirtualAssetMutationVariables,
    CreateVirtualAssetSettingRowMutationVariables,
    DeleteCertificateAssetMutationVariables,
    DeleteCertificateAssetSettingRowMutationVariables,
    DeletePhysicalAssetMutationVariables,
    DeletePhysicalAssetSettingRowMutationVariables,
    DeleteSaasAssetMutationVariables,
    DeleteSaasAssetSettingRowMutationVariables,
    DeleteSoftwareAssetMutationVariables,
    DeleteSoftwareAssetSettingRowMutationVariables,
    DeleteVirtualAssetMutationVariables,
    DeleteVirtualAssetSettingRowMutationVariables,
    EditCertificateAssetSettingRowMutationVariables,
    EditPhysicalAssetSettingRowMutationVariables,
    EditSaasAssetSettingRowMutationVariables,
    EditSoftwareAssetSettingRowMutationVariables,
    EditVirtualAssetSettingRowMutationVariables,
    GetCertificateAssetSettingsQueryVariables,
    GetCsvFieldsCertificateMutation,
    GetCsvFieldsCertificateMutationVariables,
    GetCsvFieldsPhysicalAssetsMutation,
    GetCsvFieldsPhysicalAssetsMutationVariables,
    GetCsvFieldsSaasMutation,
    GetCsvFieldsSaasMutationVariables,
    GetCsvFieldsSoftwareMutation,
    GetCsvFieldsSoftwareMutationVariables,
    GetCsvFieldsVirtualMutation,
    GetCsvFieldsVirtualMutationVariables,
    GetPhysicalAssetSettingsQueryVariables,
    GetSaasAssetSettingsQueryVariables,
    GetSoftwareAssetSettingsQueryVariables,
    GetVirtualAssetSettingsQueryVariables,
    MainAssetsApproveRequestRemoveAssetMutation,
    MainAssetsApproveRequestRemoveAssetMutationVariables,
    MainAssetsApproveRequestRequestAssetMutation,
    MainAssetsApproveRequestRequestAssetMutationVariables,
    MainAssetsAssignNotAssignedAssetMutation,
    MainAssetsAssignNotAssignedAssetMutationVariables,
    MainAssetsCreateAndAssignAssetMutation,
    MainAssetsCreateAndAssignAssetMutationVariables,
    MainAssetsGetAssetRequestByIdQuery,
    MainAssetsGetAssetRequestByIdQueryVariables,
    MainAssetsGetRequestsWithFilterPaginationQuery,
    MainAssetsGetRequestsWithFilterPaginationQueryVariables,
    MainAssetsReassignAssetMutation,
    MainAssetsReassignAssetMutationVariables,
    MainAssetsRequestsRejectMutation,
    MainAssetsRequestsRejectMutationVariables,
    PaginationInput,
    PerformCsvCertificateMutation,
    PerformCsvCertificateMutationVariables,
    PerformCsvPhysicalAssetsMutation,
    PerformCsvPhysicalAssetsMutationVariables,
    PerformCsvSaasMutation,
    PerformCsvSaasMutationVariables,
    PerformCsvSoftwareMutation,
    PerformCsvSoftwareMutationVariables,
    PerformCsvVirtualMutation,
    PerformCsvVirtualMutationVariables,
    QueryExportCsvCertificateAssetsArgs,
    QueryExportCsvPhysicalAssetsArgs,
    QueryExportCsvSaasAssetsArgs,
    QueryExportCsvSoftwareAssetsArgs,
    QueryExportCsvVirtualAssetsArgs,
    UpdateCertificateAssetMutationVariables,
    UpdatePhysicalAssetMutationVariables,
    UpdateSaasAssetMutationVariables,
    UpdateSoftwareAssetMutationVariables,
    UpdateVirtualAssetMutationVariables
} from "../../../../newShared/GQLTypes";

import {
    changeAssetStatusApi,
    createCertificateAssetApi,
    createCertificateAssetsSettingRowApi,
    createPhysicalAssetApi,
    createPhysicalAssetsSettingRowApi,
    createSaasAssetApi,
    createSaasAssetsSettingRowApi,
    createSoftwareAssetApi,
    createSoftwareAssetsSettingRowApi,
    createVirtualAssetApi,
    createVirtualAssetsSettingRowApi,
    deleteCertificateAssetApi,
    deleteCertificateAssetsSettingRowApi,
    deletePhysicalAssetApi,
    deletePhysicalAssetsSettingRowApi,
    deleteSaasAssetApi,
    deleteSaasAssetsSettingRowApi,
    deleteSoftwareAssetApi,
    deleteSoftwareAssetsSettingRowApi,
    deleteVirtualAssetApi,
    deleteVirtualAssetsSettingRowApi,
    editCertificateAssetsSettingRowApi,
    editPhysicalAssetsSettingRowApi,
    editSaasAssetsSettingRowApi,
    editSoftwareAssetsSettingRowApi,
    editVirtualAssetsSettingRowApi,
    exportCsvCertificateAssetsApi,
    exportCsvPhysicalAssetsApi,
    exportCsvSaasAssetsApi,
    exportCsvSoftwareAssetsApi,
    exportCsvVirtualAssetsApi,
    getCertificateAssetByIdApi,
    getCertificateAssetsApi,
    getCertificateAssetsSettingsApi,
    getCertificatesAvailableFieldsForExport,
    getPhysicalAssetByIdApi,
    getPhysicalAssetsApi,
    getPhysicalAssetsAvailableFieldsForExport,
    getPhysicalAssetsSettingsApi,
    getSaasAssetByIdApi,
    getSaasAssetsApi,
    getSaasAssetsSettingsApi,
    getSaasAvailableFieldsForExport,
    getShortPhysicalAssetsApi,
    getSoftwareAssetByIdApi,
    getSoftwareAssetsApi,
    getSoftwareAssetsAvailableFieldsForExport,
    getSoftwareAssetsSettingsApi,
    getVirtualAssetByIdApi,
    getVirtualAssetsApi,
    getVirtualAssetsAvailableFieldsForExport,
    getVirtualAssetsSettingsApi,
    importCsvTemplateCertificateAssetsApi,
    importCsvTemplatePhysicalAssetsApi,
    importCsvTemplateSaasAssetsApi,
    importCsvTemplateSoftwareAssetsApi,
    importCsvTemplateVirtualAssetsApi,
    releaseCertificateAssetsApi,
    releasePhysicalAssetsApi,
    releaseSaasAssetsApi,
    releaseSoftwareAssetsApi,
    releaseVirtualAssetsApi,
    updateCertificateAssetApi,
    updatePhysicalAssetApi,
    updateSaasAssetApi,
    updateSoftwareAssetApi,
    updateVirtualAssetApi,
} from "../api";
import {addErrorSnack, addSuccessfulSnack} from "../../../barsEnvironment/snack/store/slice";
import {TIdName, TWithOptions} from "../../../../newShared/types";
import {createCommonAsyncThunk} from "../../../../newShared/utils/asyncThunk";
import {
    getCsvFieldsCertificate,
    getCsvFieldsPhysicalAssets,
    getCsvFieldsSAAS,
    getCsvFieldsSoftware,
    getCsvFieldsVirtual,
    mainAssetsApproveRequestRemoveAsset,
    mainAssetsApproveRequestRequestAsset,
    mainAssetsAssignNotAssignedAsset,
    mainAssetsCreateAndAssignAsset,
    mainAssetsGetAssetRequestById,
    mainAssetsGetRequestsWithFilterPagination,
    mainAssetsReassignAsset,
    mainAssetsRequestsReject,
    performCsvCertificate,
    performCsvPhysicalAssets,
    performCsvSAAS,
    performCsvSoftware,
    performCsvVirtual
} from "../api/query";
import moment from "moment/moment";


export const getPhysicalAssetsAction = createAsyncThunk(
    'physicalAssets/getAssets',
    async ({data, signal}: TWithOptions<PaginationInput>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await getPhysicalAssetsApi({workspaceId, organizationId, pageInfo: data}, signal);
        return {
            resp,
            isNewOnTop: false,
        }
    }
)

export const GetPhysicalAssetById = createAsyncThunk(
    'physicalAssets/getPhysicalAssetById',
    async ({data, signal}: TWithOptions<{id: string, onError: () => void}>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        try{
            return await getPhysicalAssetByIdApi({workspaceId, organizationId, id: data.id}, signal);
        }catch (ex: any){
            dispatch(addErrorSnack(ex.message));
            data.onError();
            throw new Error(ex.message);
        }
    }
)

export const createPhysicalAssetAction = createAsyncThunk(
    'physicalAssets/createPhysicalAssets',
    async ({data, onSuccess} : {data: CreatePhysicalAssetMutationVariables, onSuccess: (id: string) => void}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await createPhysicalAssetApi({...data, workspaceId, organizationId});
        onSuccess(resp.id);
        // dispatch(addSuccessfulSnack('Asset created'))
        return {
            resp
        }
    }
)

export const updatePhysicalAssetAction = createAsyncThunk(
    'physicalAsset/updatePhysicalAsset',
    async (data: UpdatePhysicalAssetMutationVariables, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await updatePhysicalAssetApi({...data, workspaceId, organizationId});

        // dispatch(addSuccessfulSnack('Asset updated'))
        return {
            data,
            resp
        }
    }
)

export const deletePhysicalAssetAction = createAsyncThunk(
    'physicalAsset/deletePhysicalAsset',
    async (data: DeletePhysicalAssetMutationVariables, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await deletePhysicalAssetApi({...data, workspaceId, organizationId});

        dispatch(addSuccessfulSnack(resp.message))
        return {
            data,
            resp
        }
    }
)

export const changeAssetStatusAction = createAsyncThunk(
    'physicalAsset/Assets/changeAssetStatus',
    async ({data, onSuccess}: { data: ChangeAssetStatusMutationVariables, onSuccess: Function }, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await changeAssetStatusApi({...data, workspaceId, organizationId});
        onSuccess();
        return {
            data,
            resp
        }
    }
)

export const releasePhysicalAssetsAction = createAsyncThunk(
    'physicalAsset/releasePhysicalAssets',
    async ({data, onSuccess}: { data: any, onSuccess: () => void }, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const response = await releasePhysicalAssetsApi({...data, workspaceId, organizationId});
        onSuccess();
        return {
            ...data,
            ...response
        }
    }
)

export const importCsvTemplatePhysicalAssetsAction = createAsyncThunk(
    'physicalAsset/importCsvTemplatePhysicalAsset',
    async (_, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await importCsvTemplatePhysicalAssetsApi({workspaceId, organizationId});
        resp && saveAs(b64toBlob(resp as string), 'physical-assets-template.csv');
        return resp
    }
)

export const exportCsvPhysicalAssetsAction = createAsyncThunk(
    'physicalAsset/Assets/exportCsvPhysicalAssets',
    async (data: Omit<QueryExportCsvPhysicalAssetsArgs, 'workspaceId' | 'organizationId'>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await exportCsvPhysicalAssetsApi({...data, workspaceId, organizationId});

        if (data.isDownload) {
            const date = moment().format("yyyy-MM-DD-hh-mm-ss");
            saveAs(b64toBlob(resp as string), `physical assets ${date}.csv`)
        } else {
            dispatch(addSuccessfulSnack(`You will receive email with a link to download CSV`));
        }

        return resp
    }
)



//SETTINGS
export const getPhysicalAssetsSettingsAction = createAsyncThunk(
    'physicalAsset/Settings/getPhysicalAssetsSettings',
    async (data: TWithOptions<GetPhysicalAssetSettingsQueryVariables>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await getPhysicalAssetsSettingsApi({workspaceId, organizationId}, data.signal)

        return {
            data: data.data,
            resp
        }
    }
)

export const createPhysicalAssetsSettingRowAction = createAsyncThunk(
    'physicalAsset/Settings/createPhysicalAssetsSettingRow',
    async ({data, onSuccess}: {data: CreatePhysicalAssetSettingRowMutationVariables, onSuccess?: () => void}, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await createPhysicalAssetsSettingRowApi({...data, workspaceId, organizationId})
        onSuccess && onSuccess();

        return {
            data,
            resp
        }
    }
)

export const editPhysicalAssetsSettingRowAction = createAsyncThunk(
    'physicalAsset/Settings/editPhysicalAssetsSettingRow',
    async (data: EditPhysicalAssetSettingRowMutationVariables, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await editPhysicalAssetsSettingRowApi({...data, workspaceId, organizationId})

        return {
            data,
            resp
        }
    }
)

export const deletePhysicalAssetsSettingRowAction = createAsyncThunk(
    'physicalAsset/Settings/deletePhysicalAssetsSettingRow',
    async ({data, onSuccess}: { data: DeletePhysicalAssetSettingRowMutationVariables, onSuccess: Function }, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await deletePhysicalAssetsSettingRowApi({...data, workspaceId, organizationId});
        onSuccess();
        // dispatch(addInfoSnack(resp.message))
        return {
            data,
            resp
        }
    }
)

//VIRTUAL ASSET
export const getVirtualAssetsAction = createAsyncThunk(
    'physicalAssets/getVirtualAssets',
    async ({data, signal}: TWithOptions<PaginationInput>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await getVirtualAssetsApi({workspaceId, organizationId, pageInfo: data}, signal);
        return {
            resp,
            isNewOnTop: false,
        }
    }
)

export const GetVirtualAssetById = createAsyncThunk(
    'physicalAssets/getVirtualAssetById',
    async ({data, signal}: TWithOptions<{id: string, onError: () => void}>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        try{
            return await getVirtualAssetByIdApi({workspaceId, organizationId, id: data.id}, signal);
        }catch (ex: any){
            dispatch(addErrorSnack(ex.message));
            data.onError();
            throw new Error(ex.message);
        }
    }
)

export const createVirtualAssetAction = createAsyncThunk(
    'physicalAssets/createVirtualAssets',
    async ({data, onSuccess} : {data: CreateVirtualAssetMutationVariables, onSuccess: (id: string) => void}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await createVirtualAssetApi({...data, workspaceId, organizationId});
        onSuccess(resp.id);
        return {
            resp
        }
    }
)

export const updateVirtualAssetAction = createAsyncThunk(
    'physicalAsset/updateVirtualAsset',
    async (data: UpdateVirtualAssetMutationVariables, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await updateVirtualAssetApi({...data, workspaceId, organizationId});

        return {
            data,
            resp
        }
    }
)

export const deleteVirtualAssetAction = createAsyncThunk(
    'physicalAsset/deleteVirtualAsset',
    async (data: DeleteVirtualAssetMutationVariables, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await deleteVirtualAssetApi({...data, workspaceId, organizationId});

        dispatch(addSuccessfulSnack(resp.message))
        return {
            data,
            resp
        }
    }
)

export const releaseVirtualAssetsAction = createAsyncThunk(
    'physicalAsset/releaseVirtualAssets',
    async ({data, onSuccess}: { data: any, onSuccess: () => void }, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const response = await releaseVirtualAssetsApi({...data, workspaceId, organizationId});
        onSuccess();
        return {
            ...data,
            ...response
        }
    }
)

export const importCsvTemplateVirtualAssetsAction = createAsyncThunk(
    'physicalAsset/importCsvTemplateVirtualAsset',
    async (_, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await importCsvTemplateVirtualAssetsApi({workspaceId, organizationId});
        resp && saveAs(b64toBlob(resp as string), 'virtual-assets-template.csv');
        return resp
    }
)

export const exportCsvVirtualAssetsAction = createAsyncThunk(
    'physicalAsset/Assets/exportCsvVirtualAssets',
    async (data: Omit<QueryExportCsvVirtualAssetsArgs, 'workspaceId' | 'organizationId'>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await exportCsvVirtualAssetsApi({...data, workspaceId, organizationId});

        if (data.isDownload) {
            const date = moment().format("yyyy-MM-DD-hh-mm-ss");
            saveAs(b64toBlob(resp as string), `virtual assets ${date}.csv`)
        } else {
            dispatch(addSuccessfulSnack(`You will receive email with a link to download CSV`));
        }
        return resp
    }
)



//SETTINGS
export const getVirtualAssetsSettingsAction = createAsyncThunk(
    'physicalAsset/Settings/getVirtualAssetsSettings',
    async (data: TWithOptions<GetVirtualAssetSettingsQueryVariables>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await getVirtualAssetsSettingsApi({workspaceId, organizationId}, data.signal)

        return {
            data: data.data,
            resp
        }
    }
)

export const createVirtualAssetsSettingRowAction = createAsyncThunk(
    'physicalAsset/Settings/createVirtualAssetsSettingRow',
    async ({data, onSuccess}: {data: CreateVirtualAssetSettingRowMutationVariables, onSuccess?: () => void }, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await createVirtualAssetsSettingRowApi({...data, workspaceId, organizationId});
        onSuccess && onSuccess();

        return {
            data,
            resp
        }
    }
)

export const editVirtualAssetsSettingRowAction = createAsyncThunk(
    'physicalAsset/Settings/editVirtualAssetsSettingRow',
    async (data: EditVirtualAssetSettingRowMutationVariables, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await editVirtualAssetsSettingRowApi({...data, workspaceId, organizationId})

        return {
            data,
            resp
        }
    }
)

export const deleteVirtualAssetsSettingRowAction = createAsyncThunk(
    'physicalAsset/Settings/deleteVirtualAssetsSettingRow',
    async ({data, onSuccess}: {data: DeleteVirtualAssetSettingRowMutationVariables, onSuccess: Function }, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await deleteVirtualAssetsSettingRowApi({...data, workspaceId, organizationId});
        onSuccess();
        // dispatch(addInfoSnack(resp.message))
        return {
            data,
            resp
        }
    }
)

//SOFTWARE
export const getSoftwareAssetsAction = createAsyncThunk(
    'physicalAssets/getSoftwareAssets',
    async ({data, signal}: TWithOptions<PaginationInput>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await getSoftwareAssetsApi({workspaceId, organizationId, pageInfo: data}, signal);
        return {
            resp,
            isNewOnTop: false,
        }
    }
)

export const GetSoftwareAssetById = createAsyncThunk(
    'physicalAssets/getSoftwareAssetById',
    async ({data, signal}: TWithOptions<{id: string, onError: () => void}>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        try{
            return await getSoftwareAssetByIdApi({workspaceId, organizationId, id: data.id}, signal);
        }catch (ex: any){
            dispatch(addErrorSnack(ex.message));
            data.onError();
            throw new Error(ex.message);
        }
    }
)

export const createSoftwareAssetAction = createAsyncThunk(
    'physicalAssets/createSoftwareAssets',
    async ({data, onSuccess} : {data: CreateSoftwareAssetMutationVariables, onSuccess: (id: string) => void}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await createSoftwareAssetApi({...data, workspaceId, organizationId});
        onSuccess(resp.id);
        return {
            resp
        }
    }
)

export const updateSoftwareAssetAction = createAsyncThunk(
    'physicalAsset/updateSoftwareAsset',
    async (data: UpdateSoftwareAssetMutationVariables, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await updateSoftwareAssetApi({...data, workspaceId, organizationId});

        return {
            data,
            resp
        }
    }
)

export const deleteSoftwareAssetAction = createAsyncThunk(
    'physicalAsset/deleteSoftwareAsset',
    async (data: DeleteSoftwareAssetMutationVariables, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await deleteSoftwareAssetApi({...data, workspaceId, organizationId});

        dispatch(addSuccessfulSnack(resp.message))
        return {
            data,
            resp
        }
    }
)

export const releaseSoftwareAssetsAction = createAsyncThunk(
    'physicalAsset/releaseSoftwareAssets',
    async ({data, onSuccess}: { data: any, onSuccess: () => void }, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const response = await releaseSoftwareAssetsApi({...data, workspaceId, organizationId});
        onSuccess();
        return {
            ...data,
            ...response
        }
    }
)

export const importCsvTemplateSoftwareAssetsAction = createAsyncThunk(
    'physicalAsset/importCsvTemplateSoftwareAsset',
    async (_, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await importCsvTemplateSoftwareAssetsApi({workspaceId, organizationId});
        resp && saveAs(b64toBlob(resp as string), 'software-assets-template.csv');
        return resp
    }
)

export const exportCsvSoftwareAssetsAction = createAsyncThunk(
    'physicalAsset/Assets/exportCsvSoftwareAssets',
    async (data: Omit<QueryExportCsvSoftwareAssetsArgs, 'workspaceId' | 'organizationId'>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await exportCsvSoftwareAssetsApi({...data, workspaceId, organizationId});

        if (data.isDownload) {
            const date = moment().format("yyyy-MM-DD-hh-mm-ss");
            saveAs(b64toBlob(resp as string), `software assets ${date}.csv`)
        } else {
            dispatch(addSuccessfulSnack(`You will receive email with a link to download CSV`));
        }

        return resp
    }
)


//SETTINGS
export const getSoftwareAssetsSettingsAction = createAsyncThunk(
    'physicalAsset/Settings/getSoftwareAssetsSettings',
    async (data: TWithOptions<GetSoftwareAssetSettingsQueryVariables>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await getSoftwareAssetsSettingsApi({workspaceId, organizationId}, data.signal)

        return {
            data: data.data,
            resp
        }
    }
)

export const createSoftwareAssetsSettingRowAction = createAsyncThunk(
    'physicalAsset/Settings/createSoftwareAssetsSettingRow',
    async (data: CreateSoftwareAssetSettingRowMutationVariables, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await createSoftwareAssetsSettingRowApi({...data, workspaceId, organizationId})

        return {
            data,
            resp
        }
    }
)

export const editSoftwareAssetsSettingRowAction = createAsyncThunk(
    'physicalAsset/Settings/editSoftwareAssetsSettingRow',
    async (data: EditSoftwareAssetSettingRowMutationVariables, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await editSoftwareAssetsSettingRowApi({...data, workspaceId, organizationId})

        return {
            data,
            resp
        }
    }
)

export const deleteSoftwareAssetsSettingRowAction = createAsyncThunk(
    'physicalAsset/Settings/deleteSoftwareAssetsSettingRow',
    async ({data, onSuccess}: {data: DeleteSoftwareAssetSettingRowMutationVariables, onSuccess: Function }, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await deleteSoftwareAssetsSettingRowApi({...data, workspaceId, organizationId});
        onSuccess();
        // dispatch(addInfoSnack(resp.message))
        return {
            data,
            resp
        }
    }
)

//SAAS
export const getSaasAssetsAction = createAsyncThunk(
    'physicalAssets/getSaasAssets',
    async ({data, signal}: TWithOptions<PaginationInput>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await getSaasAssetsApi({workspaceId, organizationId, pageInfo: data}, signal);
        return {
            resp,
            isNewOnTop: false,
        }
    }
)

export const GetSaasAssetById = createAsyncThunk(
    'physicalAssets/getSaasAssetById',
    async ({data, signal}: TWithOptions<{id: string, onError: () => void}>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        try{
            return await getSaasAssetByIdApi({workspaceId, organizationId, id: data.id}, signal);
        }catch (ex: any){
            dispatch(addErrorSnack(ex.message));
            data.onError();
            throw new Error(ex.message);
        }
    }
)

export const createSaasAssetAction = createAsyncThunk(
    'physicalAssets/createSaasAssets',
    async ({data, onSuccess} : {data: CreateSaasAssetMutationVariables, onSuccess: (id: string) => void}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await createSaasAssetApi({...data, workspaceId, organizationId});
        onSuccess(resp.id);
        return {
            resp
        }
    }
)

export const updateSaasAssetAction = createAsyncThunk(
    'physicalAsset/updateSaasAsset',
    async (data: UpdateSaasAssetMutationVariables, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await updateSaasAssetApi({...data, workspaceId, organizationId});

        return {
            data,
            resp
        }
    }
)

export const deleteSaasAssetAction = createAsyncThunk(
    'physicalAsset/deleteSaasAsset',
    async (data: DeleteSaasAssetMutationVariables, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await deleteSaasAssetApi({...data, workspaceId, organizationId});

        dispatch(addSuccessfulSnack(resp.message))
        return {
            data,
            resp
        }
    }
)

export const releaseSaasAssetsAction = createAsyncThunk(
    'physicalAsset/releaseSaasAssets',
    async ({data, onSuccess}: { data: any, onSuccess: () => void }, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const response = await releaseSaasAssetsApi({...data, workspaceId, organizationId});
        onSuccess();
        return {
            ...data,
            ...response
        }
    }
)

export const importCsvTemplateSaasAssetsAction = createAsyncThunk(
    'physicalAsset/importCsvTemplateSaasAsset',
    async (_, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await importCsvTemplateSaasAssetsApi({workspaceId, organizationId});
        resp && saveAs(b64toBlob(resp as string), 'saas-assets-template.csv');
        return resp
    }
)

export const exportCsvSaasAssetsAction = createAsyncThunk(
    'physicalAsset/Assets/exportCsvSaasAssets',
    async (data: Omit<QueryExportCsvSaasAssetsArgs, 'workspaceId' | 'organizationId'>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await exportCsvSaasAssetsApi({...data, workspaceId, organizationId});

        if (data.isDownload) {
            const date = moment().format("yyyy-MM-DD-hh-mm-ss");
            saveAs(b64toBlob(resp as string), `saas assets ${date}.csv`)
        } else {
            dispatch(addSuccessfulSnack(`You will receive email with a link to download CSV`));
        }
        return resp
    }
)


//SETTINGS
export const getSaasAssetsSettingsAction = createAsyncThunk(
    'physicalAsset/Settings/getSaasAssetsSettings',
    async (data: TWithOptions<GetSaasAssetSettingsQueryVariables>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await getSaasAssetsSettingsApi({workspaceId, organizationId}, data.signal)

        return {
            data: data.data,
            resp
        }
    }
)

export const createSaasAssetsSettingRowAction = createAsyncThunk(
    'physicalAsset/Settings/createSaasAssetsSettingRow',
    async ({data, onSuccess}: {data: CreateSaasAssetSettingRowMutationVariables, onSuccess?: () => void }, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await createSaasAssetsSettingRowApi({...data, workspaceId, organizationId});
        onSuccess && onSuccess();

        return {
            data,
            resp
        }
    }
)

export const editSaasAssetsSettingRowAction = createAsyncThunk(
    'physicalAsset/Settings/editSaasAssetsSettingRow',
    async (data: EditSaasAssetSettingRowMutationVariables, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await editSaasAssetsSettingRowApi({...data, workspaceId, organizationId})

        return {
            data,
            resp
        }
    }
)

export const deleteSaasAssetsSettingRowAction = createAsyncThunk(
    'physicalAsset/Settings/deleteSaasAssetsSettingRow',
    async ({data, onSuccess}: { data: DeleteSaasAssetSettingRowMutationVariables, onSuccess: Function }, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await deleteSaasAssetsSettingRowApi({...data, workspaceId, organizationId});
        onSuccess();
        // dispatch(addInfoSnack(resp.message))
        return {
            data,
            resp
        }
    }
)

//Certificate
export const getCertificateAssetsAction = createAsyncThunk(
    'physicalAssets/getCertificateAssets',
    async ({data, signal}: TWithOptions<PaginationInput>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await getCertificateAssetsApi({workspaceId, organizationId, pageInfo: data}, signal);
        return {
            resp,
            isNewOnTop: false,
        }
    }
)

export const GetCertificateAssetById = createAsyncThunk(
    'physicalAssets/getCertificateAssetById',
    async ({data, signal}: TWithOptions<{id: string, onError: () => void}>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        try{
            return await getCertificateAssetByIdApi({workspaceId, organizationId, id: data.id}, signal);
        }catch (ex: any){
            dispatch(addErrorSnack(ex.message));
            data.onError();
            throw new Error(ex.message);
        }
    }
)

export const createCertificateAssetAction = createAsyncThunk(
    'physicalAssets/createCertificateAssets',
    async ({data, onSuccess} : {data: CreateCertificateAssetMutationVariables, onSuccess: (id: string) => void}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await createCertificateAssetApi({...data, workspaceId, organizationId});
        onSuccess(resp.id);
        return {
            resp
        }
    }
)

export const updateCertificateAssetAction = createAsyncThunk(
    'physicalAsset/updateCertificateAsset',
    async (data: UpdateCertificateAssetMutationVariables, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await updateCertificateAssetApi({...data, workspaceId, organizationId});

        return {
            data,
            resp
        }
    }
)

export const deleteCertificateAssetAction = createAsyncThunk(
    'physicalAsset/deleteCertificateAsset',
    async (data: DeleteCertificateAssetMutationVariables, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await deleteCertificateAssetApi({...data, workspaceId, organizationId});

        dispatch(addSuccessfulSnack(resp.message))
        return {
            data,
            resp
        }
    }
)

export const releaseCertificateAssetsAction = createAsyncThunk(
    'physicalAsset/releaseCertificateAssets',
    async ({data, onSuccess}: { data: any, onSuccess: () => void }, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const response = await releaseCertificateAssetsApi({...data, workspaceId, organizationId});
        onSuccess();
        return {
            ...data,
            ...response
        }
    }
)

export const importCsvTemplateCertificateAssetsAction = createAsyncThunk(
    'physicalAsset/importCsvTemplateCertificateAsset',
    async (_, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await importCsvTemplateCertificateAssetsApi({workspaceId, organizationId});
        resp && saveAs(b64toBlob(resp as string), 'certificate-assets-template.csv');
        return resp
    }
)

export const exportCsvCertificateAssetsAction = createAsyncThunk(
    'physicalAsset/Assets/exportCsvCertificateAssets',
    async (data: Omit<QueryExportCsvCertificateAssetsArgs, 'workspaceId' | 'organizationId'>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await exportCsvCertificateAssetsApi({...data, workspaceId, organizationId});

        if (data.isDownload) {
            const date = moment().format("yyyy-MM-DD-hh-mm-ss");
            saveAs(b64toBlob(resp as string), `certificate_${date}.csv`)
        } else {
            dispatch(addSuccessfulSnack(`You will receive email with a link to download CSV`));
        }

        return resp
    }
)



//SETTINGS
export const getCertificateAssetsSettingsAction = createAsyncThunk(
    'physicalAsset/Settings/getCertificateAssetsSettings',
    async (data: TWithOptions<GetCertificateAssetSettingsQueryVariables>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await getCertificateAssetsSettingsApi({workspaceId, organizationId}, data.signal)

        return {
            data: data.data,
            resp
        }
    }
)

export const createCertificateAssetsSettingRowAction = createAsyncThunk(
    'physicalAsset/Settings/createCertificateAssetsSettingRow',
    async ({data, onSuccess}: {data: CreateCertificateAssetSettingRowMutationVariables, onSuccess?: () => void}, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await createCertificateAssetsSettingRowApi({...data, workspaceId, organizationId});
        onSuccess && onSuccess();

        return {
            data,
            resp
        }
    }
)

export const editCertificateAssetsSettingRowAction = createAsyncThunk(
    'physicalAsset/Settings/editCertificateAssetsSettingRow',
    async (data: EditCertificateAssetSettingRowMutationVariables, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await editCertificateAssetsSettingRowApi({...data, workspaceId, organizationId})

        return {
            data,
            resp
        }
    }
)

export const deleteCertificateAssetsSettingRowAction = createAsyncThunk(
    'physicalAsset/Settings/deleteCertificateAssetsSettingRow',
    async ({data, onSuccess}: {data: DeleteCertificateAssetSettingRowMutationVariables, onSuccess: Function }, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await deleteCertificateAssetsSettingRowApi({...data, workspaceId, organizationId});
        onSuccess();
        // dispatch(addInfoSnack(resp.message))
        return {
            data,
            resp
        }
    }
)

export const GetShortPhysicalAssets = createAsyncThunk(
    'physicalAsset/GetShortPhysicalAssets',
    async (signal: AbortSignal, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        return await getShortPhysicalAssetsApi({organizationId, workspaceId}, signal );
    }
);

export const GetPhysicalAssetsAvailableFieldsForExport = createAsyncThunk(
    'physicalAsset/GetPhysicalAssetsAvailableFieldsForExport',
    async (_, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const {fields} = await getPhysicalAssetsAvailableFieldsForExport({workspaceId});
        return {
            fields,
        };
    }
);


export const GetVirtualAssetsAvailableFieldsForExport = createAsyncThunk(
    'physicalAsset/GetVirtualAssetsAvailableFieldsForExport',
    async (_, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const {fields} = await getVirtualAssetsAvailableFieldsForExport({workspaceId});
        return {
            fields,
        };
    }
);


export const GetSoftwareAssetsAvailableFieldsForExport = createAsyncThunk(
    'physicalAsset/GetSoftwareAssetsAvailableFieldsForExport',
    async (_, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const {fields} = await getSoftwareAssetsAvailableFieldsForExport({workspaceId});
        return {
            fields,
        };
    }
);

export const GetSaasAvailableFieldsForExport = createAsyncThunk(
    'physicalAsset/GetSaasAvailableFieldsForExport',
    async (_, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const {fields} = await getSaasAvailableFieldsForExport({workspaceId});
        return {
            fields,
        };
    }
);

export const GetCertificatesAvailableFieldsForExport = createAsyncThunk(
    'physicalAsset/GetCertificatesAvailableFieldsForExport',
    async (_, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const {fields} = await getCertificatesAvailableFieldsForExport({workspaceId});
        return {
            fields,
        };
    }
);


export const getCsvFieldsPhysicalAssetsAction =
    createCommonAsyncThunk<GetCsvFieldsPhysicalAssetsMutationVariables, GetCsvFieldsPhysicalAssetsMutation["getCsvFieldsPhysicalAssets"]>(
        'PhysicalAssets', 'getCsvFieldsPhysicalAssets', true, false, getCsvFieldsPhysicalAssets
    )
export const performCsvPhysicalAssetsAction =
    createCommonAsyncThunk<PerformCsvPhysicalAssetsMutationVariables, PerformCsvPhysicalAssetsMutation["performCsvPhysicalAssets"]>(
        'PhysicalAssets', 'performCsvPhysicalAssets', true, false, performCsvPhysicalAssets,
    )

export const getCsvFieldsSAASAction =
    createCommonAsyncThunk<GetCsvFieldsSaasMutationVariables, GetCsvFieldsSaasMutation["getCsvFieldsSAAS"]>(
        'PhysicalAssets', 'getCsvFieldsSAAS', true, false, getCsvFieldsSAAS
    )
export const performCsvSAASAction =
    createCommonAsyncThunk<PerformCsvSaasMutationVariables, PerformCsvSaasMutation["performCsvSAAS"]>(
        'PhysicalAssets', 'performCsvSAAS', true, false, performCsvSAAS,
    )

export const getCsvFieldsSoftwareAction =
    createCommonAsyncThunk<GetCsvFieldsSoftwareMutationVariables, GetCsvFieldsSoftwareMutation["getCsvFieldsSoftware"]>(
        'PhysicalAssets', 'getCsvFieldsSoftware', true, false, getCsvFieldsSoftware
    )
export const performCsvSoftwareAction =
    createCommonAsyncThunk<PerformCsvSoftwareMutationVariables, PerformCsvSoftwareMutation["performCsvSoftware"]>(
        'PhysicalAssets', 'performCsvSoftware', true, false, performCsvSoftware,
    )

export const getCsvFieldsVirtualAction =
    createCommonAsyncThunk<GetCsvFieldsVirtualMutationVariables, GetCsvFieldsVirtualMutation["getCsvFieldsVirtual"]>(
        'PhysicalAssets', 'getCsvFieldsVirtual', true, false, getCsvFieldsVirtual
    )
export const performCsvVirtualAction =
    createCommonAsyncThunk<PerformCsvVirtualMutationVariables, PerformCsvVirtualMutation["performCsvVirtual"]>(
        'PhysicalAssets', 'performCsvVirtual', true, false, performCsvVirtual,
    )

export const getCsvFieldsCertificateAction =
    createCommonAsyncThunk<GetCsvFieldsCertificateMutationVariables, GetCsvFieldsCertificateMutation["getCsvFieldsCertificate"]>(
        'PhysicalAssets', 'getCsvFieldsCertificate', true, false, getCsvFieldsCertificate
    )
export const performCsvCertificateAction =
    createCommonAsyncThunk<PerformCsvCertificateMutationVariables, PerformCsvCertificateMutation["performCsvCertificate"]>(
        'PhysicalAssets', 'performCsvCertificate', true, false, performCsvCertificate,
    )

//Requests
export const mainAssetsGetRequestsWithFilterPaginationAction =
    createCommonAsyncThunk<MainAssetsGetRequestsWithFilterPaginationQueryVariables, MainAssetsGetRequestsWithFilterPaginationQuery["mainAssetsGetRequestsWithFilterPagination"]>(
        'PhysicalAssets', 'mainAssetsGetRequestsWithFilterPagination', true, false, mainAssetsGetRequestsWithFilterPagination
    )

export const mainAssetsGetAssetRequestByIdAction =
    createCommonAsyncThunk<MainAssetsGetAssetRequestByIdQueryVariables, MainAssetsGetAssetRequestByIdQuery["mainAssetsGetAssetRequestById"]>(
        'PhysicalAssets', 'mainAssetsGetAssetRequestById', true, false, mainAssetsGetAssetRequestById
    )

export const mainAssetsAssignNotAssignedAssetAction =
    createCommonAsyncThunk<MainAssetsAssignNotAssignedAssetMutationVariables, MainAssetsAssignNotAssignedAssetMutation["mainAssetsAssignNotAssignedAsset"], {resolver: TIdName}>(
        'PhysicalAssets', 'mainAssetsAssignNotAssignedAsset', true, false, mainAssetsAssignNotAssignedAsset
    )

export const mainAssetsRequestsRejectAction =
    createCommonAsyncThunk<MainAssetsRequestsRejectMutationVariables, MainAssetsRequestsRejectMutation["mainAssetsRequestsReject"], {resolver: TIdName}>(
        'PhysicalAssets', 'mainAssetsRequestsReject', true, false, mainAssetsRequestsReject
    )

export const mainAssetsReassignAssetAction =
    createCommonAsyncThunk<MainAssetsReassignAssetMutationVariables, MainAssetsReassignAssetMutation["mainAssetsReassignAsset"], {resolver: TIdName}>(
        'PhysicalAssets', 'mainAssetsReassignAsset', true, false, mainAssetsReassignAsset
    )

export const mainAssetsApproveRequestRequestAssetAction =
    createCommonAsyncThunk<MainAssetsApproveRequestRequestAssetMutationVariables, MainAssetsApproveRequestRequestAssetMutation["mainAssetsApproveRequestRequestAsset"], {resolver: TIdName}>(
        'PhysicalAssets', 'mainAssetsApproveRequestRequestAsset', true, false, mainAssetsApproveRequestRequestAsset
    )

export const mainAssetsCreateAndAssignAssetAction =
    createCommonAsyncThunk<MainAssetsCreateAndAssignAssetMutationVariables, MainAssetsCreateAndAssignAssetMutation["mainAssetsCreateAndAssignAsset"], {resolver: TIdName}>(
        'PhysicalAssets', 'mainAssetsCreateAndAssignAsset', true, false, mainAssetsCreateAndAssignAsset
    )

export const mainAssetsApproveRequestRemoveAssetAction =
    createCommonAsyncThunk<MainAssetsApproveRequestRemoveAssetMutationVariables, MainAssetsApproveRequestRemoveAssetMutation["mainAssetsApproveRequestRemoveAsset"], {resolver: TIdName}>(
        'PhysicalAssets', 'mainAssetsApproveRequestRemoveAsset', true, false, mainAssetsApproveRequestRemoveAsset
    )

