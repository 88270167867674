//types
import {TMenuItem} from "./types";

//icons
//
// import {ReactComponent as QuickStartIcon} from "../../../newShared/images/menu/svg-optimized/power.svg"; // fill
import {ReactComponent as FrameworkIcon} from "../../../newShared/images/menu/svg-optimized/view-compact-outline.svg"; // fill
import {ReactComponent as AutomationIcon} from "../../../newShared/images/menu/svg-optimized/cog-sync-outline.svg"; // fill
// import {ReactComponent as CollectorIcon} from "../../../newShared/images/menu/svg-optimized/bookmark-box-multiple-outline.svg"; // fill
import {
    ReactComponent as DocumentsIcon
} from "../../../newShared/images/menu/svg-optimized/file-document-multiple-outline.svg"; // fill
import {ReactComponent as ReportsIcon} from "../../../newShared/images/menu/svg-optimized/chart-box-outline.svg"; // fill
// import {ReactComponent as AnalysisIcon} from "../../../newShared/images/menu/svg-optimized/text-box-search-outline.svg"; // fill
import {ReactComponent as RisksIcon} from "../../../newShared/images/menu/svg-optimized/Frame 5.svg"; // stroke
import {ReactComponent as VulnerabilityIcon} from "../../../newShared/images/menu/svg-optimized/scanner.svg"; // stroke
import {ReactComponent as HRIcon} from "../../../newShared/images/menu/svg-optimized/account-multiple-outline.svg"; // fill
import {ReactComponent as PhysicalAssetsIcon} from "../../../newShared/images/menu/svg-optimized/Physical assets.svg"; // stroke
import {ReactComponent as VendorsIcon} from "../../../newShared/images/menu/svg-optimized/vendors management.svg"; // fill
// import {ReactComponent as InventoryIcon} from "../../../newShared/images/menu/svg-optimized/view-agenda-outline.svg"; // fill
// import {ReactComponent as InventoryIcon} from "../../../newShared/images/menu/svg-optimized/view-agenda-outline.svg"; // fill
import {ReactComponent as IntegrationIcon} from "../../../newShared/images/menu/integration.svg"; // fill
import {ReactComponent as BuilderIcon} from "../../../newShared/images/menu/svg-optimized/desktop-classic.svg"; // fill
import {ReactComponent as KYCIcon} from "../../../newShared/images/menu/svg-optimized/kyc.svg"; // fill
import {ReactComponent as AccessManagementIcon} from "../../../newShared/images/menu/accessManagement.svg"; // fill
import {ReactComponent as TrainingsIcon} from "../../../newShared/images/menu/trainings.svg"; // fill
import {ReactComponent as CollectorsIcon} from "../../../newShared/images/menu/collectors.svg"; // fill
import {ReactComponent as VicaIcon} from "../../../newShared/images/menu/svg-optimized/vica.svg"; // fill
//
import {
    PATH_LOCAL_ASSET_MANAGEMENT,
    PATH_LOCAL_COLLECTORS,
    PATH_LOCAL_COMPLIANCE_FRAMEWORKS,
    PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED,
    PATH_LOCAL_COMPLIANCE_FRAMEWORKS_AVAILABLE,
    PATH_LOCAL_COMPLIANCE_REPORTS,
    PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED,
    PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED,
    PATH_LOCAL_COMPLIANCE_REPORTS_NEW,
    PATH_LOCAL_DOCUMENTS,
    PATH_LOCAL_EMPLOYEES,
    PATH_LOCAL_INTEGRATIONS,
    PATH_LOCAL_RISK_MANAGEMENT,
    PATH_LOCAL_VULNERABILITY,
    PATH_LOCAL_VULNERABILITY_SCANNED
} from "../../../newShared/constants";
import {
    PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATES,
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS,
    PATH_LOCAL_ASSET_MANAGEMENT_SAAS,
    PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE,
    PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS,
    PATH_LOCAL_ASSETS_INCOMING_REQUESTS,
} from "../../components/physicalAssets/constants";
import {
    PATH_RISK_MANAGEMENT_MODELS_ALL,
    PATH_RISK_MANAGEMENT_RISK_REGISTRIES_ALL
} from "../../components/riskManagement/constants";
import {
    PATH_LOCAL_VENDORS,
    PATH_LOCAL_VENDORS_MANAGEMENT,
    PATH_LOCAL_VENDORS_SETTINGS
} from "../../components/vendors/constants";

import {
    SETTING_ROOT_PATH,
    SETTINGS_INVITES_PATH,
    SETTINGS_LANGUAGE_PATH,
    SETTINGS_ORGANIZATIONS_PATH,
    SETTINGS_PROFILE_PATH,
    SETTINGS_SECURITY_PATH
} from "../../authWorkspacesCookies/settings/constants";

import {AUTOMATIONS_LIST_PATH, PATH_LOCAL_AUTOMATIONS} from "../../components/automations/constants";
import {PATH_INTEGRATIONS_ADDED, PATH_INTEGRATIONS_AVAILABLE} from "../../components/newIntegrations/constants";
import {
    PATH_ACCESS_MANAGEMENT_APPS_LIST,
    PATH_ACCESS_MANAGEMENT_CONFLICT_LIST,
    PATH_ACCESS_MANAGEMENT_PROFILES,
    PATH_ACCESS_MANAGEMENT_REVIEW_EXECUTORS_LIST,
    PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_LIST,
    PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES,
    PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET,
    PATH_LOCAL_ACCESS_MANAGEMENT,
} from "../../components/accessManagement/constants";
import {
    PATH_TRAININGS_ASSIGNMENTS,
    PATH_TRAININGS_EXAMS,
    PATH_TRAININGS_LIST,
    PATH_TRAININGS_TEMPLATES,
    TRAININGS_PATH
} from "../../components/Trainings/constants";
import {ROOT_BUILDER, ROOT_CONTROLS, ROOT_EVIDENCES, ROOT_FRAMEWORKS} from "../../components/builder/constants";
import {PATH_LOCAL_VICA, PATH_VICA_DASHBOARD, PATH_VICA_TASKS} from "../../components/vica/constants";
import {
    PATH_LOCAL_EMPLOYEES_ALL,
    PATH_LOCAL_EMPLOYEES_INCOMING_REQUESTS,
    PATH_LOCAL_EMPLOYEES_ISSUES,
    PATH_LOCAL_EMPLOYEES_JOB_TITLE,
    PATH_LOCAL_EMPLOYEES_SETTINGS,
    PATH_LOCAL_EMPLOYEES_TREE,
    PATH_LOCAL_TIME_OFF_REQUESTS
} from "../../components/employeesRefactor/constants";
import {
    CORPORATE_CLIENTS_PAGE_TYPE,
    CRYPTOWALLET_CLIENTS_PAGE_TYPE,
    INDIVIDUAL_CLIENTS_PAGE_TYPE,
    KYC_CLIENTS_ARCHIVE_LIST_PATH,
    KYC_CLIENTS_LIST_PATH,
    KYC_MATCHES_LIST_PATH,
    KYC_REQUESTS_LIST_PATH,
    KYC_ROOT_PATH,
    KYC_SCREENING_LIST_PATH,
    KYC_TEMPLATES_LIST_PATH
} from "../../components/kycRefactor/constants";

const frameworksAdopted: TMenuItem = {title: 'Adopted frameworks', path: PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED, child: []};
const frameworksAvailable: TMenuItem = {title: 'Available frameworks', path: PATH_LOCAL_COMPLIANCE_FRAMEWORKS_AVAILABLE, child: []};
const frameworks: TMenuItem = {title: 'Frameworks', icon: FrameworkIcon, path: PATH_LOCAL_COMPLIANCE_FRAMEWORKS, child: [frameworksAdopted, frameworksAvailable]};

const newReport: TMenuItem = {title: 'New report', path: PATH_LOCAL_COMPLIANCE_REPORTS_NEW, child: []};
const generatedReports: TMenuItem = {title: 'Generated reports', path: PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED, child: []};
const archivedReports: TMenuItem = {title: 'Archived reports', path: PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED, child: []};
const reports: TMenuItem = {title: 'Reports', icon: ReportsIcon, path: PATH_LOCAL_COMPLIANCE_REPORTS, child: [newReport, generatedReports, archivedReports]};

const vulnerabilityScanner: TMenuItem = {title: 'Scanned items', path: PATH_LOCAL_VULNERABILITY_SCANNED, child: []};
const vulnerabilityScannerMain: TMenuItem = {title: 'Vulnerability Scanner', path: PATH_LOCAL_VULNERABILITY, icon: VulnerabilityIcon, child: [vulnerabilityScanner]};

const physicalAssetsAssets: TMenuItem = {title: 'Physical assets', path: PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS, child: []};
const virtualAssets: TMenuItem = {title: 'Virtual assets', path: PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS, child: []};
const software: TMenuItem = {title: 'Software', path: PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE, child: []};
const saasAssets: TMenuItem = {title: 'SaaS', path: PATH_LOCAL_ASSET_MANAGEMENT_SAAS, child: []};
// const cloudflare: TMenuItem = {title: 'Cloudflare', path: PATH_LOCAL_ASSET_MANAGEMENT_CLOUDFLARE, child: []};
const certificates: TMenuItem = {title: 'Certificates', path: PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATES, child: []};
const physicalAssetsRequests: TMenuItem = {title: 'Incoming requests', path: PATH_LOCAL_ASSETS_INCOMING_REQUESTS, child: []};
// const physicalAssetsSettings: TMenuItem = {title: 'Settings', path: PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_SETTINGS, child: []};
const physicalAssets: TMenuItem = {title: 'Asset Management', path: PATH_LOCAL_ASSET_MANAGEMENT, icon: PhysicalAssetsIcon, child: [physicalAssetsAssets, virtualAssets, software, saasAssets, certificates, physicalAssetsRequests]};

const employeesList: TMenuItem = {title: 'Employees', path: PATH_LOCAL_EMPLOYEES_ALL, child: []};
const employeesTree: TMenuItem = {title: 'Organization tree', path: PATH_LOCAL_EMPLOYEES_TREE, child: []};
const employeesRequests: TMenuItem = {title: 'Incoming requests', path: PATH_LOCAL_EMPLOYEES_INCOMING_REQUESTS, child: []};
const employeesIssues: TMenuItem = {title: 'Employees issues', path: PATH_LOCAL_EMPLOYEES_ISSUES, child: []};
const employeesJobTitles: TMenuItem = {title: 'Job title', path: PATH_LOCAL_EMPLOYEES_JOB_TITLE, child: []};
const employeesSettings: TMenuItem = {title: 'Settings', path: PATH_LOCAL_EMPLOYEES_SETTINGS, child: []};
const employeesTimeOffRequests: TMenuItem = {title: "Time off requests", path: PATH_LOCAL_TIME_OFF_REQUESTS, child: [],};

const employeesMain: TMenuItem = {title: 'HR Management', path: PATH_LOCAL_EMPLOYEES, icon: HRIcon, child: [employeesList, employeesTree, employeesTimeOffRequests, employeesRequests, employeesIssues, employeesJobTitles, employeesSettings]};

const riskManagementModels: TMenuItem = {title: 'Models', path: PATH_RISK_MANAGEMENT_MODELS_ALL, child: []};
const riskManagementRegs: TMenuItem = {title: 'Risk Registries', path: PATH_RISK_MANAGEMENT_RISK_REGISTRIES_ALL, child: []};
const riskManagementMain: TMenuItem = {title: 'Risk Management', path: PATH_LOCAL_RISK_MANAGEMENT, icon: RisksIcon, child: [riskManagementRegs, riskManagementModels]};

const settingsOrganizations: TMenuItem = {title: 'Organizations', path: SETTINGS_ORGANIZATIONS_PATH, child: []};
const settingsInvites: TMenuItem = {title: 'Invites', path: SETTINGS_INVITES_PATH, child: []};
const settingsProfile: TMenuItem = {title: 'Profile', path: SETTINGS_PROFILE_PATH, child: []};
const settingsLanguage: TMenuItem = {title: 'Language', path: SETTINGS_LANGUAGE_PATH, child: []};
const settingsSecurity: TMenuItem = {title: 'Security', path: SETTINGS_SECURITY_PATH, child: []};
const settingsMain: TMenuItem = {title: 'Settings', path: SETTING_ROOT_PATH, dontShowInMenu: true, child: [settingsOrganizations, settingsInvites, settingsProfile, settingsLanguage, settingsSecurity]};

const automations: TMenuItem = {title: 'Created automations', path: AUTOMATIONS_LIST_PATH, child: []};
const automationsMain: TMenuItem = {title: 'Automations', path: PATH_LOCAL_AUTOMATIONS, icon: AutomationIcon, child: [automations]};

const documents: TMenuItem = {title: 'Documents', path: PATH_LOCAL_DOCUMENTS, child: [], icon: DocumentsIcon};

const vendorsMain: TMenuItem = {title: 'Vendors', path: PATH_LOCAL_VENDORS, child: []};
const vendorsSettings: TMenuItem = {title: 'Settings', path: PATH_LOCAL_VENDORS_SETTINGS, child: []};
const vendors: TMenuItem = {title: 'Vendors Management', path: PATH_LOCAL_VENDORS_MANAGEMENT, icon:VendorsIcon, child: [vendorsMain, vendorsSettings]};


const integrationsAvailable: TMenuItem = {title: 'Available integrations', path: PATH_INTEGRATIONS_AVAILABLE, child: []};
const integrationsAdded: TMenuItem = {title: 'Installed integrations', path: PATH_INTEGRATIONS_ADDED, child: []};
const integrationsMain: TMenuItem = {title: 'Integrations', path: PATH_LOCAL_INTEGRATIONS, icon: IntegrationIcon, child: [integrationsAdded, integrationsAvailable]};


const frameworksBuilder: TMenuItem = {title: 'Created frameworks', path: ROOT_FRAMEWORKS, child: []};
const controlsBuilder: TMenuItem = {title: 'Created controls', path: ROOT_CONTROLS, child: []};
const evidencesBuilder: TMenuItem = {title: 'Created evidences', path: ROOT_EVIDENCES, child: []};
const builderMain: TMenuItem = {title: 'Framework builder', path: ROOT_BUILDER, icon: BuilderIcon, child: [frameworksBuilder, controlsBuilder, evidencesBuilder]};

// const kycTemplates: TMenuItem = {title: 'Templates', path: PATH_LOCAL_KYC_TEMPLATES, child: []};
// const kycRequests: TMenuItem = {title: 'Requests', path: PATH_LOCAL_KYC_REQUESTS, child: []};
// const kyc: TMenuItem = {title: 'KYC Management', path: PATH_LOCAL_KYC, child: [kycTemplates, kycRequests], icon: KYCIcon};
//
const kycScreening: TMenuItem = {title: 'Screening', path: KYC_SCREENING_LIST_PATH, child: []};
const kycMatches: TMenuItem = {title: 'Matches', path: KYC_MATCHES_LIST_PATH, child: []};
const kycRequestsRefactor: TMenuItem = {title: 'Requests', path: KYC_REQUESTS_LIST_PATH, child: []};
const kycTemplatesRefactor: TMenuItem = {title: 'Templates', path: KYC_TEMPLATES_LIST_PATH, child: []};
const kycIndividuals: TMenuItem = {title: 'Individual clients', path: KYC_CLIENTS_LIST_PATH.replace(':type', INDIVIDUAL_CLIENTS_PAGE_TYPE), child: []};
const kycCorporate: TMenuItem = {title: 'Corporate clients', path: KYC_CLIENTS_LIST_PATH.replace(':type', CORPORATE_CLIENTS_PAGE_TYPE), child: []};
const kycCryptoWallet: TMenuItem = {title: 'Crypto wallets', path: KYC_CLIENTS_LIST_PATH.replace(':type', CRYPTOWALLET_CLIENTS_PAGE_TYPE), child: []};
const kycArchive: TMenuItem = {title: 'Archive clients', path: KYC_CLIENTS_ARCHIVE_LIST_PATH, child: []};


const kycRefactor: TMenuItem = {title: 'KYC Management', path: KYC_ROOT_PATH, child: [kycIndividuals, kycCorporate, kycCryptoWallet, kycMatches, kycScreening, kycRequestsRefactor, kycTemplatesRefactor,  kycArchive], icon: KYCIcon};

const accessManagementApplications: TMenuItem = {title: 'Applications', path: PATH_ACCESS_MANAGEMENT_APPS_LIST, child: []};
const accessManagementReview: TMenuItem = {title: 'Review campaign', path: PATH_ACCESS_MANAGEMENT_REVIEW_EXECUTORS_LIST, child: []};
const accessManagementReviewInstructions: TMenuItem = {title: 'Review campaign config', path: PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_LIST, child: []};
const accessManagementSegregationOfDuties: TMenuItem = {title: 'Duties list', path: PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES, child: []};
const accessManagementSegregationOfDutiesRuleset: TMenuItem = {title: 'SOD ruleset', path: PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET, child: []};
const accessManagementConflicts: TMenuItem = {title: 'Conflict list', path: PATH_ACCESS_MANAGEMENT_CONFLICT_LIST, child: []};
// const accessManagementSodScheduler: TMenuItem = {title: 'SoD scheduler', path: PATH_ACCESS_MANAGEMENT_SOD_SCHEDULER, child: []};
const accessManagementProfiles: TMenuItem = {title: 'Profiles', path: PATH_ACCESS_MANAGEMENT_PROFILES, child: []};
const accessManagement: TMenuItem = {title: 'Access management', path: PATH_LOCAL_ACCESS_MANAGEMENT, child: [accessManagementApplications, accessManagementReview, accessManagementReviewInstructions, accessManagementProfiles, accessManagementSegregationOfDuties, accessManagementSegregationOfDutiesRuleset, accessManagementConflicts], icon: AccessManagementIcon};


const assignments: TMenuItem = {title: 'Assignments', path: PATH_TRAININGS_ASSIGNMENTS, child: []};
const trainingsExams: TMenuItem = {title: 'Exams', path: PATH_TRAININGS_EXAMS, child: []};
const trainingsTemplates: TMenuItem = {title: 'Templates', path: PATH_TRAININGS_TEMPLATES, child: []};
// const trainingsSettings: TMenuItem = {title: 'Training settings', path: PATH_TRAININGS_SETTINGS, child: []};
const trainings: TMenuItem = {title: 'Trainings', path: PATH_TRAININGS_LIST, child: []};
const trainingsManagement: TMenuItem = {title: 'Training management', path: TRAININGS_PATH, child: [assignments, trainings, trainingsExams, trainingsTemplates], icon: TrainingsIcon};

const collectorsList: TMenuItem = {title: 'Adopted', path: PATH_LOCAL_COLLECTORS, child: []};
const collectors: TMenuItem = {title: 'Collectors', path: PATH_LOCAL_COLLECTORS, child: [collectorsList], icon: CollectorsIcon};

const vicaDashboard: TMenuItem = {title: 'Dashboard', path: PATH_VICA_DASHBOARD, child: []};
const vicaTasks: TMenuItem = {title: 'Task management', path: PATH_VICA_TASKS, child: []};
// const vicaFindings: TMenuItem = {title: 'Findings', path: PATH_CMD_FINDINGS, child: []};
const vica: TMenuItem = {title: 'Vica', path: PATH_LOCAL_VICA, child: [vicaDashboard, vicaTasks], icon: VicaIcon};


export const leftMenuItems: readonly TMenuItem[] = [
    vica,
    frameworks,
    automationsMain,
    documents,
    reports,
    trainingsManagement,
    accessManagement,
    riskManagementMain,
    vulnerabilityScannerMain,
    physicalAssets,
    employeesMain,
    vendors,
    // kyc,
    kycRefactor,
    integrationsMain,
    builderMain,
    settingsMain,
    collectors,
] as const;

export type TMenuTitle = typeof leftMenuItems[number]['title'] & typeof leftMenuItems[number]['child'][number]['title'];
