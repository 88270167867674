import React, {ChangeEvent, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {TSettingsId, TSettingStatus} from "../../types";
import {normalizeSettingIdName} from "../../helpers";
import {assetsSettingsSelector, loadings} from "../../store/store";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_ASSET_MANAGEMENT} from "../../../../../newShared/constants";
import {
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS,
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_SETTINGS,
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_SETTINGS_EXACT
} from "../../constants";
import {useAppDispatch} from "../../../../../newShared/redux";
import {createPhysicalAssetsSettingRowAction} from "../../store/actions";


export const useSettingsNew = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    // const {t} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});
    const settings = useSelector(assetsSettingsSelector);

    const id = useParams<{id: string}>().id as TSettingsId;

    const settingTitle = normalizeSettingIdName(id as TSettingsId);

    const {isLoadingSettings, addSettingLoading} = useSelector(loadings);

    const [item, setItem] = useState<string>('');
    const [status, setStatus] = useState<TSettingStatus | ''>('');

    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setItem(event.target.value);
    };

    const handleChangeStatus = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setStatus(e.target.value as TSettingStatus);
    };

    useEffect(() => {
        setItem('');
        setStatus('')

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useSetBreadcrumbs([
        {
            title: tMenu('Asset Management'),
            path: PATH_LOCAL_ASSET_MANAGEMENT
        },
        {
            title: tMenu('Physical assets'),
            path: PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS
        },
        {
            title: tMenu('Settings'),
            path: PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_SETTINGS
        },
        {
            title: 'New',
            path: PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_SETTINGS_EXACT.replace(':id', id)
        }
    ]);

    const handleGoBack = () => history.push(PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_SETTINGS_EXACT.replace(':id', id));

    const isOk = () => {
        if (item.trim().length > 0) {
            if (settings) {
                switch (id){
                    case 'model': return !settings.model.some(e => e.name.toLowerCase().trim() === item.toLowerCase().trim())
                    case 'category': return !settings.category.some(e => e.name.toLowerCase().trim() === item.toLowerCase().trim())
                    case 'manufacture': return !settings.manufacture.some(e => e.name.toLowerCase().trim() === item.toLowerCase().trim())
                    case 'customFields': return !settings.customFields.some(e => e.toLowerCase().trim() === item.toLowerCase().trim())
                    case 'status': return (!settings.status.some(({name}) => name.toLowerCase().trim() === item.toLowerCase().trim()) && status !== '' )
                }
            }
        }
    }

    const handleSave = () => {
        if(isOk()){
            dispatch(createPhysicalAssetsSettingRowAction({data: {workspaceId: '', organizationId: '', field: id, title: item.trim(), status: {name: item.trim(), status}}})).then(resp => handleGoBack());
        }
    }

    // const getSettings = dispatch(getPhysicalAssetsSettingsAction({organizationId: '', workspaceId: ''}));

    return {
        id,
        settings,
        settingTitle,
        item,
        setItem,
        status,
        setStatus,
        handleChange,
        handleGoBack,
        handleChangeStatus,
        isOk,
        handleSave,

        isLoadingSettings,
        addSettingLoading,

    }

}
