import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {loadings, models, selectedModel} from "../../store/slice";
import {useEffect, useState} from "react";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_RISK_MANAGEMENT} from "../../../../../newShared/constants";
import {
    PATH_RISK_MANAGEMENT_MODELS_ALL,
    PATH_RISK_MANAGEMENT_MODELS_EXACT,
    PATH_RISK_MANAGEMENT_MODELS_EXACT_EDIT
} from "../../constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TRiskModel} from "../../types";
import {updateRisksModelAction} from "../../store/actions";
import {getLow, getMax} from "../../helpers";

export const useModelEdit = () => {
    const id = useParams<{id: string}>().id;

    const dispatch = useDispatch();
    const history = useHistory();

    const selected = useSelector(selectedModel);
    const _models = useSelector(models);
    const {modelsUpdate, modelsList} = useSelector(loadings);

    const [model, setModel] = useState<TRiskModel | null>(null);
    const [displayModel, setDisplayModel] = useState<TRiskModel | null>(null);

    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    useSetBreadcrumbs([
        {
            title: tMenu('Risk Management'),
            path: PATH_LOCAL_RISK_MANAGEMENT
        },
        {
            title: tMenu('Models'),
            path: PATH_RISK_MANAGEMENT_MODELS_ALL
        },
        {
            title: model?.name ?? 'Loading model...',
            path: PATH_RISK_MANAGEMENT_MODELS_EXACT.replace('id', id)
        },
        {
            title: tMenu('Edit model'),
            path: PATH_RISK_MANAGEMENT_MODELS_EXACT_EDIT.replace('id', id)
        }
    ]);

    useEffect(() => {
        if(!selected && id && _models.length > 0){
            const currModel = _models.find(e => e.id === id);
            if(currModel){
                if(currModel.isBasic || currModel.counter > 0){
                    history.push(PATH_RISK_MANAGEMENT_MODELS_ALL);
                }else{
                    setModel(currModel);
                    setDisplayModel(currModel);
                }
            }
        }else if(!id){
            history.push(PATH_RISK_MANAGEMENT_MODELS_ALL);
        }

        if(selected){
            if(selected.isBasic || selected.counter > 0){
                history.push(PATH_RISK_MANAGEMENT_MODELS_ALL);
            }else{
                setModel(selected);
                setDisplayModel(selected);
            }
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!modelsList && !model && id && _models.length > 0){
            const currModel = _models.find(e => e.id === id);
            if(currModel){
                if(currModel.isBasic || currModel.counter > 0){
                    history.push(PATH_RISK_MANAGEMENT_MODELS_ALL);
                }else{
                    setModel(currModel);
                    setDisplayModel(currModel);
                }
            }
        }
        //eslint-disable-next-line
    }, [modelsList]);

    const handleGoBack = () => {
        history.push(PATH_RISK_MANAGEMENT_MODELS_EXACT.replace(':id', id));
    }


    //form
    const setLeftRight = (left: number, right: number) => {
        displayModel && setDisplayModel({...displayModel, left, right});
    }

    const handleSubmit = () => {
        if(isOk() && !isCanBeApplied() && displayModel){
            dispatch(updateRisksModelAction({
                data: {
                    workspaceId: '',
                    organizationId: '',
                    riskModel: displayModel,
                },
                onSuccess: handleGoBack
            }))
        }
    }


    const isOk = () => {
        if(!displayModel) return false;
        return !displayModel.impacts.some(e => !e.quality.trim().length) &&
            !displayModel.impacts.some(e => !e.quantitative) &&
            !displayModel.probabilities.some(e => !e.quality.trim().length) &&
            !displayModel.probabilities.some(e => !e.quantitative) &&
            displayModel.left < displayModel.right &&
            displayModel.right < getMax(model) && displayModel.left > getLow(model) &&
            displayModel.name.trim().length > 0
    }

    const handleChangeProbability = (e: any, id: number) => {
        if(e.target.name === 'quality'){
            model && setModel({
                ...model,
                probabilities: [
                    ...model?.probabilities.map((p, pid ) => pid === id ? {...p, quality: e.target.value} : p)
                ]
            })
        }else{
            model && setModel({
                ...model,
                probabilities: [
                    ...model?.probabilities.map((p, pid ) => pid === id ? {...p, quantitative: e.target.value.length > 0 ? (parseInt(e.target.value) ?? 1) : 1} : p)
                ]
            })
        }
    }

    const handleChangeImpact = (e: any, id: number) => {
        if(e.target.name === 'quality'){
            model && setModel({
                ...model,
                impacts: [
                    ...model?.impacts.map((p, pid ) => pid === id ? {...p, quality: e.target.value} : p)
                ]
            })
        }else{
            model && setModel({
                ...model,
                impacts: [
                    ...model?.impacts.map((p, pid ) => pid === id ? {...p, quantitative: e.target.value.length > 0 ? (parseInt(e.target.value) ?? 1) : 1} : p)
                ]
            })
        }
    }

    const handleDeleteProbability = (id: number) => {
        model && setModel({
            ...model,
            probabilities: model.probabilities.filter((e, _id) => id !== _id)
        })
    }

    const handleDeleteImpact = (id: number) => {
        model && setModel({
            ...model,
            impacts: model.impacts.filter((e, _id) => id !== _id)
        })
    }

    const handleAddProbability = () => {
        model && setModel({
            ...model,
            probabilities: [...model.probabilities, {quantitative: 1,  quality: '', description: ''}]
        })
    }

    const handleAddImpact = () => {
        model && setModel({
            ...model,
            impacts: [...model.impacts, {quantitative: 1,  quality: '', description: ''}]
        })
    }

    const isOkToApply = ():boolean => {
        if(model){
            const low = getLow(model);
            const max = getMax(model);
            if(low !== max && max - low > 1){ // max - low > 1 prevents showing model where min 1 and max 2
                return Boolean(model?.impacts.length && model.impacts.length > 1) &&
                    Boolean(model?.probabilities.length && model.probabilities.length > 1) &&
                    !model.impacts.filter(e => !e.quality.trim().length).length && // no impacts with empty name
                    !model.probabilities.filter(e => !e.quality.trim().length).length //no probabilities with empty name
            }
        }
        return false;
    }

    const isCanBeApplied = () => {
        //can be applied if display model different from original except left/right because its applies to displayModel only
        return JSON.stringify({...model, left: 0, right: 0}) !== JSON.stringify({...displayModel, left: 0, right: 0});
    }

    const handleApplyChanges = () => {
        if(isOkToApply() && model){
            const low = getLow(model);
            const max = getMax(model);
            const newLeft = Math.floor((max - low) * 0.5 + low); // 1/3 of full range + starting from low
            const newRight = Math.floor((max - low) * 0.75 + low); // 2/3 of full range + starting from low
            setDisplayModel({...model, left: newLeft, right: newRight});
        }
    }
    return {
        isCanBeApplied: isCanBeApplied(), //check if model === displayModel and user has seen changes
        handleApplyChanges,
        isOkToApply: isOkToApply(), //is can be applied
        displayModel,

        handleGoBack,
        selected,
        isLoading: modelsUpdate,
        isLoadingModels: modelsList,
        model,
        isOk,
        handleSubmit,
        setLeftRight,
        max: getMax(displayModel), //to show
        low: getLow(displayModel), //getLow getLow getLow
        handleChangeProbability,
        handleChangeImpact,
        handleDeleteProbability,
        handleDeleteImpact,
        handleAddProbability,
        handleAddImpact
    }
}