import {useLocation, useParams} from "react-router-dom";
import {useMemo} from "react";
import {readFilter, readGroupBy, readPage, readRowsPerPage, readSort} from "../../utils/pagination";
import {TypeRequestFilter} from "../../utils/builders";

type TResponse = {
  id: string | undefined,
  page: number,
  count: number,
  sort: string | undefined,
  groupBy: string | undefined,
  filter: TypeRequestFilter
}

export const useParameters = (): TResponse => {
  const {id} = useParams<{ id: string }>();
  const {search} = useLocation();
  const page = Number(readPage(search));
  const count = Number(readRowsPerPage(search));
  const sort = readSort(search);
  const groupBy = readGroupBy(search);
  const filter = readFilter(search);
  return useMemo(() => ({id, page, count, sort, groupBy, filter})
      // eslint-disable-next-line react-hooks/exhaustive-deps
      , [id, page, count, sort, groupBy, JSON.stringify(filter)]);
}

