import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    ChangeRisksModelMutationVariables,
    CreateRiskMutationVariables,
    CreateRiskRegistryMutationVariables,
    CreateRisksModelsMutationVariables,
    DeleteRiskByIdMutationVariables,
    DeleteRiskRegistryMutationVariables,
    DeleteRisksModelMutationVariables,
    ExportCsvRisksQueryVariables,
    GetCsvFieldsRisksMutation,
    GetCsvFieldsRisksMutationVariables,
    GetRisksByRegistryQueryVariables,
    GetRisksModelsQueryVariables,
    PaginationInput,
    PerformCsvRisksMutation,
    PerformCsvRisksMutationVariables,
    ReleaseRiskRegistryMutationVariables,
    UpdateRiskRegistryMutationVariables,
    UpdateRisksByRegistryIdMutationVariables,
    UpdateRisksModelMutationVariables
} from "../../../../newShared/GQLTypes";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {getActionsData} from "../../../../newShared/redux";
import {
    changeRisksModelApi,
    createRiskApi,
    createRiskRegistryApi,
    createRisksModelsApi,
    deleteRiskByIdApi,
    deleteRiskRegistryApi,
    deleteRisksModelApi,
    exportCsvRisksApi,
    getRiskRegistersApi,
    getRiskRegistyById,
    getRisksAvailableFieldsForExport,
    getRisksByRegistryApi,
    getRisksModelsApi,
    importCsvTemplateRisksApi,
    releaseRiskRegistryApi,
    updateRiskRegistryApi,
    updateRisksByRegistryIdApi,
    updateRisksModelApi
} from "../api";
import {TRisk} from "../types";
import {REGISTRY_RISKS_DEFAULT_COUNT} from "../constants";
import {eraseRisks} from "./slice";
import {b64toBlob} from "../../../../newShared/utils/base64/base64";
import {saveAs} from "file-saver";
import {TWithOptions} from "../../../../newShared/types";
import {getShortEmployees} from "../../employeesRefactor/api";
import {createCommonAsyncThunk} from "../../../../newShared/utils/asyncThunk";
import {getCsvFieldsRisks, performCsvRisks} from "../api/query";
import moment from "moment";
import {addSuccessfulSnack} from "../../../barsEnvironment/snack/store/slice";

//getRisksModelsApi
export const getRisksModelsAction = createAsyncThunk(
    'risksRegistries/models/getRisksModels',
    async ({data, signal}: TWithOptions<GetRisksModelsQueryVariables>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const response = await getRisksModelsApi({...data, workspaceId, organizationId}, signal);
        return response
    }
)

export const changeRisksModelAction = createAsyncThunk(
    'risksRegistries/models/changeRisksModel',
    async ({data, onSuccess}: { data: ChangeRisksModelMutationVariables, onSuccess: () => void }, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const response = await changeRisksModelApi({...data, workspaceId, organizationId});
        onSuccess();
        return response;
    }
)

export const updateRisksModelAction = createAsyncThunk(
    'risksRegistries/models/updateRisksModel',
    async ({data, onSuccess}: { data: UpdateRisksModelMutationVariables, onSuccess: () => void }, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const response = await updateRisksModelApi({...data, workspaceId, organizationId});

        onSuccess();
        return {
            data,
            response
        }
    }
)

export const deleteRisksModelAction = createAsyncThunk(
    'risksRegistries/models/deleteRisksModel',
    async ({data, onSuccess}: { data: DeleteRisksModelMutationVariables, onSuccess: () => void } & {basicId: string}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const response = await deleteRisksModelApi({...data, workspaceId, organizationId});

        onSuccess();
        return {
            ...data,
            ...response
        }
    }
)

export const createRisksModelsAction = createAsyncThunk(
    'risksRegistries/models/createRisksModels',
    async ({data, onSuccess}: { data: CreateRisksModelsMutationVariables, onSuccess: (id: string) => void }, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const response = await createRisksModelsApi({...data, workspaceId, organizationId});
        onSuccess(response.id);
        return response
    }
)

export const getRiskRegistersAction = createAsyncThunk(
    'risksRegistries/getRiskRegisters',
    async ({data, signal}: TWithOptions<PaginationInput>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const response = await getRiskRegistersApi({workspaceId, organizationId, pageInfo: data}, signal);
        return {
            ...response
        }
    }
)

export const getRisksByRegistryAction = createAsyncThunk(
    'risks/getRisksByRegistry',
    async ({data, signal}: TWithOptions<GetRisksByRegistryQueryVariables>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const response = await getRisksByRegistryApi({...data, workspaceId, organizationId}, signal);
        return {
            data,
            response,
        }
    }
)

export const getRiskRegistyByIdAction = createAsyncThunk(
    'risks/getRiskRegistyById',
    async ({data, signal}: TWithOptions<{workspaceId: string, organizationId: string, id: string}>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const response = await getRiskRegistyById({...data, workspaceId, organizationId}, signal);
        return {
            data,
            response,
        }
    }
)

export const updateRisksByRegistryIdAction = createAsyncThunk(
    'risks/updateRiskByIdAndRegistryId',
    async ({data, onSuccess = () => null, disableReplaceAll}: { data: UpdateRisksByRegistryIdMutationVariables, onSuccess?: (risks: TRisk[]) => void, disableReplaceAll?: boolean}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const response = await updateRisksByRegistryIdApi({...data, workspaceId, organizationId});
        onSuccess(response.data);
        return {
            data,
            response,
            disableReplaceAll
        }
    }
)

export const deleteRiskRegistryAction = createAsyncThunk(
    'risksRegistries/deleteRiskRegistry',
    async ({data, onSuccess}: { data: DeleteRiskRegistryMutationVariables, onSuccess: () => void }, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const response = await deleteRiskRegistryApi({...data, workspaceId, organizationId});
        onSuccess();
        return {
            ...data,
            ...response
        }
    }
)

export const deleteRiskByIdApiAction = createAsyncThunk(
    'risks/deleteRiskById',
    async ({data, onSuccess}: { data: DeleteRiskByIdMutationVariables, onSuccess: () => void }, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const response = await deleteRiskByIdApi({...data, workspaceId, organizationId});

        onSuccess();
        return {
            ...data,
            ...response
        }
    }
)

export const createRiskRegistryAction = createAsyncThunk(
    'risksRegistries/createRiskRegistry',
    async ({data, onSuccess}: { data: CreateRiskRegistryMutationVariables, onSuccess: (id: string) => void}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const response = await createRiskRegistryApi({...data, workspaceId, organizationId});
        onSuccess(response.id);
        return {
            ...response
        }
    }
)

export const createRiskAction = createAsyncThunk(
    'risks/createRisk',
    async (data: CreateRiskMutationVariables, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const response = await createRiskApi({...data, workspaceId, organizationId });

        return {
            ...response
        }
    }
)

export const updateRiskRegistryAction = createAsyncThunk(
    'risksRegistries/updateRiskRegistry',
    async ({data, isModelReplaced}: { data: UpdateRiskRegistryMutationVariables, isModelReplaced: boolean }, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const response = await updateRiskRegistryApi({...data, workspaceId, organizationId});
        if(isModelReplaced){
            dispatch(eraseRisks()); // if model was updated - fetching all risks and for preventing mess we erase them before ( lazy load)
            dispatch(getRisksByRegistryAction({data: {
                    workspaceId: '',
                    organizationId: '',
                    pageInfo: {
                        page: 0,
                        count: REGISTRY_RISKS_DEFAULT_COUNT,
                        filters: {}
                    },
                    registryId: data.registry.id
                }}));
        }
        return {
            ...response
        }
    }
)

export const importCsvTemplateRisksAction = createAsyncThunk(
    'risks/importCsvTemplateRisks',
    async (_, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await importCsvTemplateRisksApi({workspaceId, organizationId });
        saveAs(b64toBlob(resp as string), 'risks-template.csv');
        return resp
    }
)

export const exportCsvRisksAction = createAsyncThunk(
    'risks/exportCsvRisks',
    async (data: Omit<ExportCsvRisksQueryVariables, "workspaceId" | "organizationId">, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await exportCsvRisksApi({...data, workspaceId, organizationId });

        if (data.isDownload) {
            const date = moment().format("yyyy-MM-DD-hh-mm-ss");
            saveAs(b64toBlob(resp as string), `risks_${date}.csv`);
        } else {
            dispatch(addSuccessfulSnack(`You will receive email with a link to download CSV`));
        }

        return resp
    }
)

export const GetRisksAvailableFieldsForExport = createAsyncThunk(
    'risks/GetRisksAvailableFieldsForExport',
    async (_, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const {fields} = await getRisksAvailableFieldsForExport({workspaceId});
        return {
            fields,
        };
    }
);

export const releaseRiskRegistryAction = createAsyncThunk(
    'risksRegistries/releaseRiskRegistry',
    async ({data, onSuccess}: { data: ReleaseRiskRegistryMutationVariables, onSuccess: () => void }, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const response = await releaseRiskRegistryApi({...data, workspaceId, organizationId});
        onSuccess();
        return {
            ...data,
            ...response
        }
    }
)


//getEmployeesNameEmail
export const GetShortEmployees = createAsyncThunk(
    'risksRegistries/getEmployeesNameEmail',
    async (_, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getShortEmployees({workspaceId});
    }
);



export const getCsvFieldsRisksAction =
    createCommonAsyncThunk<GetCsvFieldsRisksMutationVariables, GetCsvFieldsRisksMutation["getCsvFieldsRisks"]>(
        'risks', 'getCsvFieldsRisks', true, false, getCsvFieldsRisks
    )
export const performCsvRisksAction =
    createCommonAsyncThunk<PerformCsvRisksMutationVariables, PerformCsvRisksMutation["performCsvRisks"]>(
        'risks', 'performCsvRisks', true, false, performCsvRisks,
    )
