import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {collectorDialogs, hideShowResults, selectedCollectorSelector} from "../../../store/slice";
import colors from "../../../../../../newShared/theme/colors";
import React from "react";
import {useFrameworks} from "../../../../frameworks/hooks/useFrameworks";
import {useControl} from "../../../../frameworks/hooks/useControl";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const ShowResultsDialog = () => {
    //root
    const {t, revDir, tCommon} = useMainTranslation('', {keyPrefix: 'pathCollectors'});
    const dispatch = useDispatch();
    const {openFramework} = useFrameworks();
    const {goToEvidences} = useControl();

    //selectors
    const {showResults} = useSelector(collectorDialogs);
    const {evidences} = useSelector(selectedCollectorSelector);

    //actions
    const handleClose = () => dispatch(hideShowResults());

    function groupBy<T>(list: T[], keyGetter: (value: T) => string): Record<string, T[]>{
        let map: Record<string, T[]> = {};
        list.forEach((item) => {
            const key = keyGetter(item);
            // console.log(`key: ${key}`)
            const collection = map[key];
            // console.log(`collection: ${JSON.stringify(collection)}`)
            if (!collection) {
                map[key] = [item];
            } else {
                collection.push(item);
                map[key] = collection;
            }
            // console.log(`map: ${JSON.stringify(map)}`)
        });
        return map;
    }

    return(
        <Dialog open={showResults} onClose={handleClose}>
            <DialogTitle>{t('Result locations')}</DialogTitle>

            <DialogContent>
                {Object.entries(groupBy(evidences, (ev) => ev.frameworkId)).map(entry => {
                    const frameworkName = evidences.find(e => e.frameworkId === entry[0])?.frameworkName ?? 'not found';
                    return(
                        <>
                            <Flex direction={'column'} m={'16px 0 0 0'}>
                                <Typography
                                    sx={{cursor: 'pointer', '&:hover': {color: colors.primary.hovered}}}
                                    onClick={() => openFramework(entry[0])}
                                    color={colors.grayText}>&#x2022; {frameworkName}</Typography>
                                <Flex direction={'column'} p={!revDir ? '0 0 0 16px' : '0 16px 0 0'}>
                                    {entry[1].map(ev => {
                                        return(
                                            <Typography
                                                sx={{cursor: 'pointer', '&:hover': {color: colors.primary.hovered}, margin: '10px 0 0 0'}}
                                                onClick={() => goToEvidences({frameworkId: entry[0], controlId: ev.controlId, evidenceId: ev.evidenceId})}
                                                color={colors.grayText}>&#x2022; {ev.controlName}
                                            </Typography>
                                        )
                                    })}
                                </Flex>
                            </Flex>
                            <Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable,  margin: '10px 0 0 0'}}/>
                        </>
                    )
                })}
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'} size={'small'}>
                    {tCommon('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}