import {Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import React from "react";
import {useTemplateCsvTable} from "../../hooks/templates/useTemplateCsvTable";
import {TargetScreeningsTableContainer} from "../styled/index";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import colors from "../../../../../newShared/theme/colors";
import {KycTemplateCsvHistoryModel} from "../../../../../newShared/GQLTypes";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {CsvHistoryStatus} from "./csvHistoryStatus";
import {getCsvResultData} from "../../helpers";
import {Flex} from "../../../../../newShared/components/Layouts";

export const TemplateCsvHistoryTable = () => {
    const {histories, scroll} = useTemplateCsvTable();

    return(
        <TargetScreeningsTableContainer onScroll={scroll.onScroll} ref={scroll.scrollRef}>
            <Table stickyHeader>
                <TemplateCsvTableHeader />
                <TableBody>
                    {histories.map(e => <TemplateCsvTableRow history={e} key={e.id}/>)}
                    {(scroll.hasMore || scroll.isLoading) && <TargetScreeningTableRowSkeleton />}
                </TableBody>
            </Table>
        </TargetScreeningsTableContainer>
    )
}

export const TemplateCsvTableHeader = () => {
    const {revDir} = useMainTranslation();
    return(
        <TableHead>
            <TableRow sx={{
                '& .MuiTableCell-root': {
                    padding: '16px 8px',
                    backgroundColor: colors.backgrounds.grey_light
                }
            }}>
                <TableCell width={'20%'} align={!revDir ? 'left' : 'right'}>
                    <Typography variant={'body2'} color={colors.text.grey_dark}>{('File name')}</Typography>
                </TableCell>

                <TableCell width={'20%'} align={!revDir ? 'left' : 'right'}>
                    <Typography variant={'body2'} color={colors.text.grey_dark}>{('Uploaded by')}</Typography>
                </TableCell>

                <TableCell width={'20%'} align={!revDir ? 'left' : 'right'}>
                    <Typography variant={'body2'} color={colors.text.grey_dark}>{('Uploaded date')}</Typography>
                </TableCell>

                <TableCell width={'20%'} align={!revDir ? 'left' : 'right'}>
                    <Typography variant={'body2'} color={colors.text.grey_dark}>{('Status')}</Typography>
                </TableCell>

                <TableCell width={'20%'} align={!revDir ? 'left' : 'right'}>
                    <Typography variant={'body2'} color={colors.text.grey_dark}>{('Load statistics')}</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
}

export const TemplateCsvTableRow = ({history}: {history: KycTemplateCsvHistoryModel}) => {
    const res = getCsvResultData(history.result);

    return(
        <>
            <TableRow sx={{'& .MuiTableCell-root': {padding: '16px 8px'}}}>
                <TableCell>
                    <Typography variant={'body2'} color={colors.grayText}>{history.fileName}</Typography>
                </TableCell>

                <TableCell>
                    <Typography variant={'body2'} color={colors.grayText}>{history.createdByName}</Typography>
                </TableCell>

                <TableCell>
                    <Typography variant={'body2'} color={colors.grayText}>{parseDateAuto(history.createdDate)}</Typography>
                </TableCell>

                <TableCell>
                    <Flex>
                        <CsvHistoryStatus status={history.status}/>
                    </Flex>
                </TableCell>

                <TableCell>
                    <Flex direction={'column'}>
                        <Typography variant={'body2'} color={colors.text.grey}>
                            Completed:
                            <span style={{color: colors.grayText}}> {res.completed}</span>
                        </Typography>

                        <Typography variant={'body2'} color={colors.text.grey}>
                            Skipped:
                            <span style={{color: colors.grayText}}> {res.skipped}</span>
                        </Typography>

                        {res.failedAtLine !== -1 &&
                            <Typography variant={'body2'} color={colors.text.grey}>
                                Failed at line:
                                <span style={{color: colors.grayText}}> {res.failedAtLine}</span>
                            </Typography>
                        }
                    </Flex>
                </TableCell>

            </TableRow>
            {/*<ContainerDivider />*/}
        </>
    )
}

export const TargetScreeningTableRowSkeleton = () => {
    return(
        <>
            <TableRow sx={{'& .MuiTableCell-root': {padding: '16px 8px'}}}>
                <TableCell>
                    <Typography variant={'body2'} color={colors.grayText}><Skeleton width={100}/></Typography>
                </TableCell>

                <TableCell>
                    <Typography variant={'body2'} color={colors.grayText}><Skeleton width={100}/></Typography>
                </TableCell>

                <TableCell>
                    <Typography variant={'body2'} color={colors.grayText}><Skeleton width={100}/></Typography>
                </TableCell>

                <TableCell>
                    <Skeleton width={100}/>
                </TableCell>

                <TableCell>
                    <Flex gap={'4px'}>
                        <Typography variant={'body2'} color={colors.text.grey}>Completed:</Typography>
                        <Skeleton width={30}/>
                    </Flex>

                    <Flex gap={'4px'}>
                        <Typography variant={'body2'} color={colors.text.grey}>Skipped:</Typography>
                        <Skeleton width={30}/>
                    </Flex>
                </TableCell>

            </TableRow>
            {/*<ContainerDivider />*/}
        </>
    )
}
