import {useEffect} from "react";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {ROOT_BUILDER, ROOT_EVIDENCES} from "../../constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDialogs} from "../new/useDialogs";
import {useBuilderFrameworks} from "../new/useBuilderFrameworks";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";

export const useEvidencesList = () => {
    const {clearFilters, currentSearchForFetch} = useGenericFiltersStorage();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    const {
        _evidences,
        _isLoadingEvidence, _isLoading,
        getPrivateEvidences,
    } = useBuilderFrameworks();

    const {
        handleOpenEditEvidence,
        handleOpenDeleteEvidence,
        handleOpenAddEvidenceDialog,
    } = useDialogs();

    useSetBreadcrumbs([
        {
            title: tMenu('Framework builder'),
            path: ROOT_BUILDER
        },
        {
            title: tMenu('Created evidences'),
            path: ROOT_EVIDENCES
        },

    ]);

    useEffect(() => {
        getPrivateEvidences()

        return () => {
            clearFilters();
        }
        //eslint-disable-next-line
    }, [])


    return {
        evidences: _evidences,
        isLoading: _isLoadingEvidence,
        isLoadingEvidence: _isLoadingEvidence,
        handleOpenAddEvidenceDialog,
        handleOpenEditEvidence,
        handleOpenDeleteEvidence,
        isLoadingDialogs: _isLoading,
        currentSearchForFetch
    }
}