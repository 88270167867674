import {AddFileSectionDialogPropsType} from "../../../types";
import * as yup from "yup";
import {t} from "i18next";
import {Exact, MainTrainingContentType, TrainingContentNewModel} from "../../../../../../newShared/GQLTypes";
import {useEffect} from "react";
import {useFileDropZone} from "../../../../../../newShared/hooks/useSingleFileDropZone";
import {useDispatch, useSelector} from "react-redux";
import {loadings} from "../../../store/slice";
import {mainTrainingUploadFileAction} from "../../../store/actions";
import {useCustomFormik} from "../../../../../../newShared/hooks/useCustomFormik";
import {uuid} from "../../../../../../newShared/utils";

export const useAddFileSectionDialog = (props: AddFileSectionDialogPropsType) => {
    const dispatch = useDispatch();

    const fileDropZone = useFileDropZone(5);

    const isUpdate = props.initialSection !== undefined;

    //selectors
    const {fileUpload} = useSelector(loadings);

    const validationSchema = yup.object({
        name: yup.string()
            .trim()
            .lowercase()
            .required(t('Name is required'))
            .notOneOf(isUpdate ? props.existingSections.filter(e => e.sectionId !== props.initialSection?.sectionId).map(e => e.name.trim().toLowerCase()) : props.existingSections.map(e => e.name.trim().toLowerCase()), 'Current training already has section with same name. Enter unique section name for this training')
            .max(120, 'Section name cannot be longer than 120 characters')
        ,
        data: yup.object({
            fileId: yup.string().required(),
            name: yup.string().required()
        }).required('File is required')
    });

    const formik = useCustomFormik<TrainingContentNewModel>(props.isOpen,{
        initialValues: props.initialSection ?? { data: {fileId: '', name: '', thumbnail: null}, name: '', type: MainTrainingContentType.File, sectionId: uuid(), order: props.existingSections.length},
        validationSchema,
        onSubmit: (values, formikHelpers) => {
            if(isUpdate){
                props.onSectionUpdate({...values});
            }else{
                props.onSectionAdd({...values});
            }
        },
        initialTouched:{
            name: !!props.initialSection?.name
        }
    })

    const onSuccess = (request: (Omit<Exact<{workspaceId: string, fileBase64: string, fileName: string}>, "workspaceId" | "organizationId"> & {workspaceId?: string | undefined, organizationId?: string | undefined}), response: {id: string, name: string}, addictiveData?: ({} | undefined)) => {
        if(formik.values.type === MainTrainingContentType.File){
            formik.setFieldValue('data', {fileId: response.id, name: response.name, thumbnail: null})
            fileDropZone.handleDeleteFile();
        }
    }

    useEffect(() => {
        if(fileDropZone.fileInBase64){
            //file selected - uploading file

            dispatch(mainTrainingUploadFileAction({
                data: {fileName: fileDropZone.fileInBase64.name, fileBase64: fileDropZone.fileInBase64.file},
                onSuccess,
            }))
        }
        //eslint-disable-next-line
    }, [fileDropZone.fileInBase64]);

    const handleDeleteFile = () => {
        formik.setValues({...formik.values, data: {fileId: '', name: ''}});
    }

    return{
        formik,
        ...props,
        fileDropZone,
        isOk: isUpdate ? formik.isValid && JSON.stringify(formik.values) !== JSON.stringify(props.initialSection) : formik.isValid,
        isLoadingUpload: fileUpload,
        fileActions: {
            handleDeleteFile,
        },
        isUpdate,
    }
}
