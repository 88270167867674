import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import React from "react";
import {IconTextButton, LoadingButton} from "../../../../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TWorkspaceRole} from "../../../../../types";
import {Autocomplete} from "@mui/lab";
import {InviteUserDialogContentWrapper, TextFieldCustom} from "../../../../styled";
import {Flex} from "../../../../../../../../newShared/components/Layouts";
//import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from "@mui/icons-material/Add";
import {useManageUserAccess} from "../../../../../hooks/organizations/dialogs/useManageUserAccess";
import {ROLE_PORTAL_MEMBER, ROLE_WS_OWNER} from "../../../../../constants";
import colors from "../../../../../../../../newShared/theme/colors";
import CommonCheckbox from "../../../../../../../../newShared/components/Basic/CommonCheckBox";

export const ManageUserAccessDialog = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathSettings.dialogs'});
    const {
        isOpen,
        isLoading,
        collab,
        roles,
        userRoles,
        isOk,
        actions,
        isNoMoreRolesCanBeAdded,
        isAccessToPortal,
        isContainAdminRole,
        setIsAccessToPortal,
    } = useManageUserAccess();

    return(
        <Dialog open={isOpen} onClose={actions.handleClose} scroll={'paper'}>
            <DialogTitle>{t('Manage access')}</DialogTitle>

            <DialogContent>
                <Typography variant={'*bodyText1'} color={colors.text.dark}>{t('Manage access for ')}<Box fontWeight='fontWeightMedium' display='inline'>{collab?.email ?? ''}</Box></Typography>

                <Box display={'flex'} alignItems={'center'} sx={{margin: '0 -12px'}}>
                    <CommonCheckbox
                        checked={isAccessToPortal}
                        disabled={isContainAdminRole}
                        onChange={() => setIsAccessToPortal(!isAccessToPortal)}
                    />
                    <Typography variant={'body2'}>{t('Allow collaborator to enter Portal')}</Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'} sx={{ml: '28px', mt: '-10px'}}>
                    <Typography variant={'body2'} color={colors.text.grey} sx={{lineHeight: '19px'}}>
                        {t('By selecting checkbox you are granting access to Portal area for this collaborator.')}
                    </Typography>
                </Box>

                <Typography sx={{lineHeight: 2, display: 'block', mt: '16px'}} variant={'*bodyText1_semibold'}>{t('Roles')}</Typography>
                <InviteUserDialogContentWrapper style={{width: '100%'}}>
                    {userRoles.filter(e => e.name !== ROLE_WS_OWNER && e.name !== ROLE_PORTAL_MEMBER).map((e, id) => {
                        return(
                            <Flex w={'100%'} jc={'space-between'} ai={'center'} key={id} m={!id ? '8px 0 0 0' : '16px 0 0 0'}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={roles.filter(e => e.name !== ROLE_WS_OWNER && e.name !== ROLE_PORTAL_MEMBER)}
                                    getOptionLabel={(option) => option.name}
                                    getOptionDisabled={(option) => userRoles.some(e => e.id === option.id)}
                                    value={e}
                                    onChange={(e, newValue: TWorkspaceRole | null) => newValue && actions.replaceCollabRole(id, newValue)}
                                    sx={{width: '95%' }}
                                    renderInput={(params) => <TextFieldCustom {...params} label={t("Role")} size={'small'}/>}
                                />
                                <IconButton onClick={() => actions.handleDeleteCollabRole(e)}>
                                    <DeleteOutlinedIcon style={{color: `${colors.primary.blue}`}} fontSize={'small'}/>
                                </IconButton>
                            </Flex>
                        )
                    })}

                    <IconTextButton
                        variant={'text'}
                        icon={<AddIcon fontSize="small"/>}
                        onClick={actions.handleAddCollabRole}
                        sx={{margin: '8px 0 0 0'}}
                        disabled={isNoMoreRolesCanBeAdded}
                    >{t('Add another role')}
                    </IconTextButton>
                </InviteUserDialogContentWrapper>
            </DialogContent>

            <DialogActions>
                <Button onClick={actions.handleClose} disabled={isLoading} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={actions.handleSubmit}
                    disabled={!isOk}
                >
                    {t('Save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
