import React, {FC} from "react";
import {SettingsLoading} from "../../components/LoadingPages/settingsLoading";
import {Flex} from "../../../../../newShared/components/Layouts";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {CUSTOM_FIELDS_ID, RENEWAL_ID} from "../../constants";
import {useCertificateSettings} from "../../hooks/useCertificateSettings";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";


export const CertificateAssetsSettings: FC = () => {

    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});

    const {
        handleGoBack,
        isLoadingSettings,
        handleGoToSettingById
    } = useCertificateSettings();

    return (
        <>
            { isLoadingSettings ? <SettingsLoading /> :
                <Flex w={'100%'} h={'100%'} direction={'column'}>
                    <PageTitleAndActions
                        title={t("Certificate settings")}
                        handleGoBack={handleGoBack}
                        actions={[]}
                    />

                    {/*<Breadcrumbs/>*/}

                    {/*<Flex m={'11px 0 0 0'} ai={'center'}>*/}
                    {/*    { !isMobile &&*/}
                    {/*        <IconButton sx={{*/}
                    {/*            color: colors.text.blue,*/}
                    {/*            margin: !revDir ? `${!isLessThen1050 ? 11 : 4}px 12px ${!isLessThen1050 ? 4 : 2}px 0` : `${!isLessThen1050 ? 11 : 4}px 0 ${!isLessThen1050 ? 4 : 2}px 12px`,*/}
                    {/*            padding: '0'*/}
                    {/*        }}*/}
                    {/*                    onClick={handleGoBack} size={isLessThen1050 ? 'small' : undefined}>*/}
                    {/*            {!revDir ? <ArrowBack/> : <ArrowForward/>}*/}
                    {/*        </IconButton>}*/}
                    {/*    <Typography variant={isMobile ?  'h3' : "h1"}  sx={{margin: !revDir ? '0 32px 0 0' : '0 0 0 32px', color: colors.backgrounds.dark}}>{'Certificate settings'}</Typography>*/}
                    {/*</Flex>*/}

                    <TableContainer sx={{marginTop: '10px'}}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{width: '20%'}} align={revDir ? 'right' : 'left'}>{t('Title')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key={RENEWAL_ID} hover onClick={() => handleGoToSettingById(RENEWAL_ID)}>
                                    <TableCell align={revDir ? 'right' : 'left'}>{t('Renewal')}</TableCell>
                                </TableRow>
                                <TableRow key={CUSTOM_FIELDS_ID} hover onClick={() => handleGoToSettingById(CUSTOM_FIELDS_ID)}>
                                    <TableCell align={revDir ? 'right' : 'left'}>{t('Custom field')}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Flex>
            }
        </>
    )
}