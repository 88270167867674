import {gql} from "@apollo/react-hooks";
import {evidenceAutoFile} from "./fragments";

export const uploadEvidenceAsset = gql`
    mutation uploadEvidenceAsset($organizationId: String!, $workspaceId: String!, $data: EvidenceAssetInputDto!){
        uploadEvidenceAsset(organizationId: $organizationId, workspaceId: $workspaceId, data: $data) {
            ...evidenceAutoFile
        }
    }
    ${evidenceAutoFile}
`

export const getOriginTemplateById = gql`
    query getOriginTemplateById($workspaceId: String!, $templateId: String!){
        getOriginTemplateById(workspaceId: $workspaceId, templateId: $templateId) {
            id
            name
            data {
                innerHtml
                variables {
                    type
                    title
                    key
                    order
                    format
                    value
                    description
                }
                editor
            }
        }
    }
`

export const mainVicaCreateDelegatedTask = gql`
    mutation mainVicaCreateDelegatedTask($workspaceId: String!, $task: MainVicaCreateDelegatedTaskInput!) {
        mainVicaCreateDelegatedTask(workspaceId: $workspaceId, task: $task) {
            message
        }
    }

`;

export const createByTemplateTask = gql`
    mutation createByTemplateTask($organizationId: String!, $workspaceId: String!, $taskId: String!, $data: TemplateTaskRequestInput!){
        createByTemplateTask(organizationId: $organizationId, workspaceId: $workspaceId, taskId: $taskId, data: $data) {
            message
        }
    }
`