import {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {PATH_LOCAL_COLLECTORS} from "../../../newShared/constants";
import {CollectorsList} from "./pages/collectorsList";
import {CollectorExact} from "./pages/collectorExact";

export const Routes: FC = () => {

    return (
        <Switch>
            <Route exact path={PATH_LOCAL_COLLECTORS} component={CollectorsList}/>
            <Route exact path={`${PATH_LOCAL_COLLECTORS}/:collectorId`} component={CollectorExact}/>

            <Redirect to={PATH_LOCAL_COLLECTORS}/>
        </Switch>
    )
}