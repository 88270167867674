import {PATH_LOCAL_DOCUMENTS} from "../../../newShared/constants";
import {ColumnType, TCreateFormDoc, TDocumentStatus} from "./types";

export const TRASH_FOLDER_NAME = 'Trash';
export const DOCUMENTS_FOLDER_NAME = 'Documents';
export const ALL_DOCUMENTS_FOLDER_NAME = 'All documents';
export const TEMPLATES_FOLDER_NAME = 'Templates';
export const GALLERY_FOLDER_NAME = 'Gallery';
export const TEMPLATE_GALLERY_FOLDER_NAME = 'Template gallery';
export const GALLERY_LOCAL_ID = 'gallery';
export const TRASH_LOCAL_ID = 'trash';
export const PORTAL_FOLDER_NAME = 'Portal';
export const TREE_NESTED_SHIFT_PX = 10;
export const HOVER_FOLDER_COLOR = '#F7F7F7';
export const SELECTED_FOLDER_COLOR = '#e5e5e5';
export const MAX_TREE_EXPAND_DEPTH = 10;
export const ACCEPT_UPLOAD_FILES = ', .pdf'; //if only one - , .pdf | if more - .csv, .pdfs
export const FILE_COPY_ADDITION = '_copy';
export const NOT_CLICKABLE_FOLDER_NAMES = [TEMPLATES_FOLDER_NAME, PORTAL_FOLDER_NAME];

//paths
export const CREATE_DOCUMENT_PATH = `${PATH_LOCAL_DOCUMENTS}/createDocument`;
export const MANAGE_DOCUMENT_PATH = `${PATH_LOCAL_DOCUMENTS}/manageDocument/:id`;
export const TREE_PATH = `${PATH_LOCAL_DOCUMENTS}/tree/documents`;
export const TREE_GALLERY_PATH = `${PATH_LOCAL_DOCUMENTS}/tree/gallery`;
export const TREE_TRASH_PATH = `${PATH_LOCAL_DOCUMENTS}/tree/trash`;

export const DOCUMENT_NAME_COLUMN_KEY = 'documentName';
export const LAST_MODIFIED_COLUMN_KEY = 'lastModified';
export const RECIPIENTS_COLUMN_KEY = 'recipients';
export const STATUS_COLUMN_KEY = 'status';
export const ACTIONS_COLUMN_KEY = 'actions';

export const DRAFT_STATUS = "DRAFT";
export const SHARED_DRAFT_STATUS = "SHARED_DRAFT";
export const APPROVAL_STATUS = "APPROVAL";
export const REMARKS_STATUS = "REMARKS";
export const SIGNING_STATUS = "SIGNING";
export const COMPLETED_STATUS = "COMPLETED";
export const TERMINATED_STATUS = "TERMINATED";

export const availableColumns: ColumnType[] = [
    {
        key: 'documentName',
        normalizedName: 'Name'
    },
    {
        key: 'lastModified',
        normalizedName: 'Last modified'
    },
    {
        key: 'recipients',
        normalizedName: 'Recipients'
    },
    {
        key: 'status',
        normalizedName: 'Status'
    },
    // {
    //     key: 'actions',
    //     normalizedName: 'Actions'
    // },
];

export const isOkEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email.length > 0 && re.test(email);
}

export const DOCUMENT_LIST_DEFAULT_COUNT = 25;
export const ActorStatusVariants: {status: TDocumentStatus, title: string}[] = [
    {status: DRAFT_STATUS, title: 'Draft'},
    {status: SHARED_DRAFT_STATUS, title: 'Shared Draft'},
    {status: APPROVAL_STATUS, title: 'Approval'},
    {status: REMARKS_STATUS, title: 'Remarks'},
    {status: SIGNING_STATUS, title: 'Signing'},
    {status: COMPLETED_STATUS, title: 'Completed'},
    {status: TERMINATED_STATUS, title: 'Terminated'},
];

export const TrashStatusVariants: {status: TDocumentStatus, title: string}[] = [
    {status: DRAFT_STATUS, title: 'Draft'},
    {status: SHARED_DRAFT_STATUS, title: 'Shared Draft'},
    // {status: APPROVAL_STATUS, title: 'Approval'},
    // {status: REMARKS_STATUS, title: 'Remarks'},
    // {status: SIGNING_STATUS, title: 'Signing'},
    // {status: COMPLETED_STATUS, title: 'Completed'},
    {status: TERMINATED_STATUS, title: 'Terminated'},
];


export const START_DOCUMENT_ITEM = 'Start document';
// export const MY_TEMPLATES_STAGE = 'My templates';
export const TEMPLATE_GALLERY_ITEM = 'Template gallery';
export const INFO_VIEW = 'infoView';


export const defaultFormCreateDoc: TCreateFormDoc = {
    workspaceId: '',
    name: '',
    data: null,
    folderId: '',
    tags: [],
    editors: [],
    approvers: [],
    recipients: [],
}


