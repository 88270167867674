import {minMaxLoadedPageType} from "./types";

export const DEFAULT_PAGING = 10;
export const DEFAULT_SEARCH_FILTER_KEY = 'search';
// export const SCROLL_POSITION_IGNORE_OFFSET = 200;
// export const SCROLL_POSITION_IGNORE_OFFSET_TOP = 40;
export const SKELETON_ROWS_HEIGHT = 60;
export const DEBUG = false;

export const EMPTY_VALUE_REPLACER = '-';
export const NO_RESULTS_QUERY_TEXT = 'No results matched your query';
export const NO_RESULTS_QUERY_SECONDARY_TEXT = 'Note: filter or search applied';
export const NO_RESULTS_EMPTY_TEXT = 'No results';
export const NO_RESULTS_EMPTY_SECONDARY_TEXT = 'This location is item-free';
export const MENU_ITEM_SPINNER_SIZE = '20px';

export const minMaxLoadedPageDefault: minMaxLoadedPageType = {minLoadedPage: -1, maxLoadedPage: -1};
export const DEFAULT_GENERIC_TABLE_SEARCH = '{"page":0,"search":"","filters":{},"lastId":null}';