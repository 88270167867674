import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TActorEmailName, TDocumentStatus} from "../../types";
import {ActorStatusVariants} from "../../constants";
import {useSelector} from "react-redux";
import {selectedFolder} from "../../store/slice";
import {autocompleteRecipientsEmailNameApi} from "../../api";


export const useGetDocumentsFilters = () => {

    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage'});
    const _selectedF = useSelector(selectedFolder);

    const statusConfig: keyConfig<{status: TDocumentStatus, title: string}> = {
        type: 'filter',
        key: 'status',
        name: t('Status'),
        options: ActorStatusVariants,
        isOptionEqualToSearch: (option, search) => option.status.trim().toLowerCase().includes(search.trim().toLowerCase()),
        getFilterValue: (a) => a.status,
        getOptionLabel: (e) => e.title,
        default: true
    }


    const lastModified: keyConfig<string> = {
        type: 'date',
        key: 'lastModified',
        name: t('Last modified'),
        default: true,
    }

    const expired: keyConfig<{id: string, title: string}> = {
        type: 'filter',
        key: 'expired',
        name: t('Expired'),
        options: [
            {id: 'true', title: t('Expired only')},
        ],
        isOptionEqualToSearch: (option, search) => option.title.trim().toLowerCase().includes(search.trim().toLowerCase()),
        isOptionEqual: (a, b) => a.id === b.id,
        getFilterValue: (a) => a.id,
        getOptionLabel: (e) => e.title,
        default: true,
    }

    const recipientsConfig: keyConfig<TActorEmailName> = {
        type: 'filter',
        key: 'recipients',
        name: t('Recipients'),
        fetchOptions: (workspaceId, data, signal) => {
            return autocompleteRecipientsEmailNameApi({...data, folderId: _selectedF?.id ?? '', workspaceId})
                .then(resp => ({values: resp.recipients, pageInfo: resp.pageInfo}))
        },
        // options: _recipientVariants?.recipients ? _recipientVariants?.recipients : [],
        getFilterValue: (a) => a.email,
        getOptionLabel: (e) => e.email,
        isOptionEqual: (a, b) => a.email === b.email,
        isOptionEqualToSearch: (a, search) => a.email.trim().toLowerCase().includes(search.trim().toLowerCase())
            || a.firstName.trim().toLowerCase().includes(search.trim().toLowerCase())  || a.lastName.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: true,
    }

    return{
        configs: [
            statusConfig,
            lastModified,
            recipientsConfig,
            expired,
        ]
    }
}
