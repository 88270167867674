import {TCollaborator} from "../../../../../newShared/components/AutocompleteCollaborators/types";
import {Vendor} from "../../../accessManagement/types";
import {addAppVendorManage} from "../../types";

export const useSelectVendorInIntegration = (
    vendorForm: addAppVendorManage,
    setForm: (form: addAppVendorManage) => void,
) => {
    const handleSetOwner = (owner: TCollaborator | null) => {
        setForm({...vendorForm, create: {...vendorForm.create, owner}});
    }

    const handleSetProvidedService = (providedService: string) => {
        setForm({...vendorForm, create: {...vendorForm.create, providedService}});
    }
    const handleSetRiskLevel = (riskLevel: string) => {
        setForm({...vendorForm, create: {...vendorForm.create, riskLevel}});
    }

    const handleSetType = (type: string) => {
        setForm({...vendorForm, create: {...vendorForm.create, type}});
    }

    const handleSetVendor = (vendor: Vendor) => {
        setForm({...vendorForm, selectedVendor: vendor});
    }

    return {
        handleSetOwner,
        handleSetProvidedService,
        handleSetRiskLevel,
        handleSetType,
        handleSetVendor
    }
};