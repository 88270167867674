import {TLanguage} from "./types";


export const LABEL = window.config?.REACT_APP_LABEL || 'Regulait';
export const IS_REGULAIT =  LABEL === 'Regulait';

export const SUPPORTED_LANGUAGES: TLanguage[] = [
    {
        name: 'English',
        code: 'en',
        countryCode: 'US',
        dir: 'ltr',
        locale: 'enUS',
        momentLocale: 'en-au'
    },
    {
        name: 'العربية',
        code: 'ar',
        countryCode: 'Bahrain',
        dir: 'rtl',
        locale: 'arSA',
        momentLocale: 'ar'
    },
    ...(IS_REGULAIT ? [{
        name: 'עברית',
        code: 'he',
        countryCode: 'IL',
        dir: 'rtl',
        locale: 'heIL',
        momentLocale: 'he'
    }] : []) as TLanguage[]
];

const screenSizes = {
    mobile: '390px',
    mobileL: '414px',
    desktopNarrow: '521px',
    tablet: '770px',
    desktop: '1366px',
    desktopM: '1440px',
    desktopL: '1920px',
}

export const device = {
    mobile: `@media screen and (max-width: ${screenSizes.mobile})`,
    mobileL: `@media screen and (max-width: ${screenSizes.mobileL})`,
    desktopNarrow: `@media screen and (max-width: ${screenSizes.desktopNarrow})`,
    tablet: `@media screen and (max-width: ${screenSizes.tablet})`,
    desktop: `@media screen and (max-width: ${screenSizes.desktop})`,
    desktopM: `@media screen and (max-width: ${screenSizes.desktopM})`,
    desktopL: `@media screen and (min-width: ${screenSizes.desktopL})`,
    generateMax: (width: number) => `@media screen and (max-width: ${width}px)`
};

export const MAIN_DATA_COOKIE_NAME = IS_REGULAIT ? 'regulaitUserData' : 'complicoUserData';

export const TINY_EDITOR_KEY = window.config?.REACT_APP_TINY_EDITOR_KEY || '';
export const PATH_ARI = window.config?.REACT_APP_URI || '';
export const PATH_SERVER = window.config?.REACT_APP_PATH_SERVER || '';
export const PATH_WS_SERVER = window.config?.REACT_APP_PATH_WS_SERVER || '';
export const VERSION = window.config?.REACT_APP_VERSION || '';

// export const SHOW_LOGGER = PATH_ARI === 'http://localhost:3000' || PATH_ARI === 'https://dev.app.regulait.com';
// export const SHOW_EXTENDED_ERRORS = SHOW_LOGGER;
// export const SHOW_SKIP_BUTTON_IN_MFA = SHOW_LOGGER;
export const SHOW_LOGGER = PATH_ARI === 'http://localhost:3000' || window.config?.NODE_ENV === 'development';
export const SHOW_EXTENDED_ERRORS = PATH_ARI === 'http://localhost:3000' || window.config?.NODE_ENV === 'development';
export const SHOW_SKIP_BUTTON_IN_MFA = PATH_ARI === 'http://localhost:3000' || window.config?.NODE_ENV === 'development';
export const SHOW_CHANGE_LANGUAGE_IN_TOPBAR = PATH_ARI === 'http://localhost:3000'; //if development -> change language dialog will be visible in topBar menu


// PATHs FOR TOP BAR
export const MAIN_PATH_DEV = 'https://dev-app.regulait.com';
export const MAIN_PATH_STAGING = 'https://staging-app.regulait.com';
export const MAIN_PATH_PROD = 'https://app.regulait.com';
export const MAIN_PATH_PROD_CA = 'https://ca.app.regulait.com';
export const MAIN_PATH_PROD_BAHRAIN = 'https://app.complico.me';

export const REPORTS_PATH_DEV = 'https://dev-reports.regulait.com';
export const REPORTS_PATH_STAGING = 'https://staging-reports.regulait.com';
export const REPORTS_PATH_PROD = 'https://reports.regulait.com';
export const REPORTS_PATH_PROD_CA = 'https://ca.reports.regulait.com';
export const REPORTS_PATH_PROD_BAHRAIN = 'https://reports.complico.me';

export const DOCS_PATH_DEV = 'https://dev-docs.regulait.com';
export const DOCS_PATH_STAGING = 'https://staging-docs.regulait.com';
export const DOCS_PATH_PROD = 'https://docs.regulait.com';
export const DOCS_PATH_PROD_CA = 'https://ca.docs.regulait.com';
export const DOCS_PATH_PROD_BAHRAIN = 'https://docs.complico.me';

export const PORTAL_PATH_DEV = 'https://dev-portal.regulait.com';
export const PORTAL_PATH_STAGING = 'https://staging-portal.regulait.com';
export const PORTAL_PATH_PROD = 'https://portal.regulait.com';
export const PORTAL_PATH_PROD_CA = 'https://ca.portal.regulait.com';
export const PORTAL_PATH_PROD_BAHRAIN = 'https://portal.complico.me';

//ROUTER PATHS
export const PATH_LOCAL_LOGIN = "/login";
export const PATH_LOCAL_LOGIN_CONTACT_US = PATH_LOCAL_LOGIN + "/contact_us";
export const PATH_LOCAL_LOGOUT = "/logout";
export const PATH_LOCAL_SIGNUP = "/signup";
export const PATH_LOCAL_2FA = '/mfa';
export const PATH_LOCAL_PASSWORD_RESET = '/password_reset';
export const PATH_LOCAL_VERIFY_EMAIL = '/email_verify';
export const PATH_LOCAL_INITIAL_MFA_CONFIGURE = '/initial_mfa';
export const PATH_LOCAL_MFA_RESET = '/mfa_reset';
export const PATH_LOCAL_HELP = "/support";
export const PATH_LOCAL_SETTINGS = "/settings";

export const PATH_LOCAL_COMPLIANCE_FRAMEWORKS = "/frameworks";
export const PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED = PATH_LOCAL_COMPLIANCE_FRAMEWORKS + "/adopted";
export const PATH_LOCAL_COMPLIANCE_FRAMEWORKS_AVAILABLE = PATH_LOCAL_COMPLIANCE_FRAMEWORKS + "/available";

export const PATH_LOCAL_COMPLIANCE_REPORTS = "/reports";
export const PATH_LOCAL_COMPLIANCE_REPORTS_NEW = PATH_LOCAL_COMPLIANCE_REPORTS + "/new";
export const PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED = PATH_LOCAL_COMPLIANCE_REPORTS + "/archive";
export const PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED = PATH_LOCAL_COMPLIANCE_REPORTS + "/generated";

export const PATH_LOCAL_SHARE_REPORTS = "/share/reports";

export const PATH_LOCAL_VULNERABILITY = "/vulnerability_scanner";
export const PATH_LOCAL_VULNERABILITY_SCANNED = "/vulnerability_scanner/scanned";
export const PATH_LOCAL_ASSET_MANAGEMENT = "/asset_management";

export const PATH_LOCAL_EMPLOYEES = '/employees';
export const PATH_LOCAL_RISK_MANAGEMENT = '/riskManagement';
export const PATH_LOCAL_VENDORS_MANAGEMENT= "/vendors_management";
export const PATH_LOCAL_INTEGRATIONS = "/integrations";

export const PATH_LOCAL_DOCUMENTS = "/documents";

export const PATH_LOCAL_KYC = "/kyc";
export const PATH_LOCAL_KYC_TEMPLATES = PATH_LOCAL_KYC + "/templates";
export const PATH_LOCAL_KYC_NEW_TEMPLATE_PERSON = PATH_LOCAL_KYC_TEMPLATES + "/person";
export const PATH_LOCAL_KYC_NEW_TEMPLATE_COMPANY = PATH_LOCAL_KYC_TEMPLATES + "/company";
export const PATH_LOCAL_KYC_REQUESTS = PATH_LOCAL_KYC + "/requests";
export const PATH_LOCAL_KYC_TOKEN = PATH_LOCAL_KYC + "/token";

export const PATH_LOCAL_COLLECTORS = '/collectors';

export const ACCEPT_IMAGE_PROP = 'image/*';
export const ACCEPT_IMAGE_PROP_WITHOUT_SVG = 'image/png, image/jpeg';
export const MAX_LIVE_COOKIE = 1000 * 60 * 60 * 24;

//
export const fieldsMapper: Record<string, Record<string, string>> = {
    employees: {
        firstName: 'First name',
        lastName: 'Last name',
        workEmail: 'Work email',
        hireDate: 'Hire date',
        employeeStatus: 'Employee status',
        jobTitle: 'Job title',
        department: 'Department',
        employmentType: 'Employment type',
        location: 'Location',
        division: 'Division',
        middleName: 'Middle name',
        nickname: 'Nickname',
        maritalStatus: 'Marital status',
        gender: 'Gender',
        ethnicity: 'Ethnicity',
        contacts: 'Contacts',
        street: 'Street',
        city: 'City',
        zip: 'ZIP code',
        country: 'Country',
        emergencyContacts: 'Emergency contacts',
        birthDay: 'Birth date',
        activeDutyWartimeOrCampaignBadgeVeteran: 'Active duty wartime or campaign badge veteran',
        armedForcesServiceMedalVeteran: 'Armed Forces service medal veteran',
        disabledVeteran: 'Disabled veteran',
        recentlySeparatedVeteran: 'Recently separated veteran',
        education: 'Education',
        manager: 'Manager',
        employeeId: 'ID',
    },
    assets: {
        //physical assets
        assetId: "ID",
        serial: "Serial",
        model: "Model",
        manufacturer: "Manufacturer",
        category: "Category",
        added: "Added",
        assigned: "Assigned",
        assignedTo: "Assigned to",
        returned: "Returned",
        status: "Asset status",

        //virtual
        name: "Name",
        type: "Type",
        virtualMachineName: "Virtual machine name",
        virtualMachineIp: "Virtual machine IP",
        ip: "IP",
        owner: "Owner",

        //software
        version: "Version",
        installed: "Installed",
        // installationThreshold: "Number of licenses",
        numberOfLicenses: "Number of licenses",
        physicalAssetIds: "Physical assets",

        //saas
        url: "URL",
        seats: "Seats",
        licenses: "Licenses",
        billingCycle: "Billing cycle",

        //certificate
        renewal: "Renewal",

    },
    vendors: {
        serviceProvided: "Service provided",
        link: "Link",
        name: "Name",
        type: "Type",
        owner: "Owner",
        riskLevel: "Risk level",
    },
    collaborators: {
        email: "Email",
        roles: "Roles",
        portalMember: "Portal member"
    },
    risks: {
        name: "Name",
        type: "Type",
        description: "Description",
        initialProbability: "Initial probability",
        initialImpact: "Initial impact",
        initialRating: "Initial rating",
        residualProbability: "Residual probability",
        residualImpact: "Residual impact",
        residualRating: "Residual rating",
        scheduler: "Scheduler",
        control: "Control",
        actions: "Actions",
        owners: "Owners",

    },
    accessManagement: {
        employee: "Employee",
        account: "Account",
        accountType: "Type",
        status: "Status",
        roleName: "Role name",
        rolePermissions: "Role permissions list",
    },
    ruleset: {

    },


}

export const APP_FILES_LINK = window.config?.REACT_APP_FILES_LINK || '';

