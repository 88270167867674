import {TSodSchedulerData, TSodSchedulerTabs} from "../../types";
import {useForm} from "../../../../../newShared/hooks/useForm";
import {useEffect, useState} from "react";
import {useSegregationOfDuties} from "../useSegregationOfDuties";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_ACCESS_MANAGEMENT_APPS_LIST, PATH_ACCESS_MANAGEMENT_SOD_SCHEDULER} from "../../constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useParameters} from "../../../../../newShared/hooks/useParameters";
import {useSearchFieldURL} from "../../../../../newShared/hooks/useSearchFieldURL";
import {useFilterUrl} from "../../../../../newShared/hooks/useFilterUrl";
import {useScrollPagination} from "../../../../../newShared/hooks/useScrollPagination";


export const useSodScheduler = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const {filter} = useParameters();

    const {
        scheduler,
        pageInfo: {page, count, total},
        getSchedulerData,
        handleUpdateSodSchedulerData,
        loadings: {update: isLoadingUpdate, sodSchedulerData: isLoadingData},
        clean,
    } = useSegregationOfDuties();


    // const initSodSchedulerData: TSodSchedulerData = {
    //     startDate: '',
    //     endDate: '',
    //     startTime: '',
    //     timeZone: '',
    //     isAllActiveRules: false,
    //     repeatNumber: 0, //'1 time each 3 weeks'
    //     repeatPer: 0,
    //     repeatTime: 'days', //days/weeks/years,
    //     alerts: {
    //         isEnded: false,
    //         isError: false,
    //         isCanceled: false,
    //     },
    //     email: '',
    // }

    const {form, handleChange, setForm} = useForm<TSodSchedulerData | null>(null);
    const [tab, setTab] = useState<TSodSchedulerTabs>('scheduler');
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const controller = new AbortController();

    const handleStartDate = (newDate: Date | null) => {
        newDate && setForm(prev => prev && ({...prev, startDate: newDate.toISOString()}))
    }

    // const handleStartTime = (newValue: string | null) => {
    //     newValue && setForm(prev => prev && ({...prev, startDate: newValue.split('T')[1]}));
    // }

    const handleEndBy = (newValue: Date | null) => {
        newValue && setForm(prev => prev && ({...prev, endDate: newValue.toISOString()}));
    }

    const handleIsAllActiveRules = (value: boolean) => {
        setForm(prev => prev && ({...prev, isAllActiveRules: value}))
    }

    const handleRepeatTime = (newValue: string) => {
        newValue && setForm(prev => prev && ({...prev, repeatTime: newValue}));
    }

    const handleAlerts = (value: boolean, field: 'isEnded' | 'isError' | 'isCanceled') => {
        setForm(prev => prev && ({...prev, alerts: {...prev.alerts, [field]: value}}));
    }

    const isOk = () => {
        return form && form.startDate.length > 0 && form.endDate.length > 0 && form.email.length > 0
    }

    const handleClose = () => {
        setIsEdit(false);
    }

    const handleEdit = () => {
        setIsEdit(true);
        scheduler.data && setForm(scheduler.data);
    }


    const handleSave = () => {
        if (isOk() && form) handleUpdateSodSchedulerData(form);
    }

    const {value, handleChange: handleChangeValue} = useSearchFieldURL({wait: 0});
    const {setFilter, handleKeyDown} = useFilterUrl({
        getData: (filters) => {getSchedulerData({page: 0, count, filters}, controller.signal, true)}
    });

    // pagination
    const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => { getSchedulerData({page, count, filters: filter}, controller.signal)},
        arrayLength: scheduler.history.length,
        isLoading: isLoadingData,
        pageInfo: {page, count, total},
        cleanData: () => {},
    })



    useSetBreadcrumbs([
        {
            title: tMenu('Access management'),
            path: PATH_ACCESS_MANAGEMENT_APPS_LIST
        },
        {
            title: tMenu('SoD scheduler'),
            path: PATH_ACCESS_MANAGEMENT_SOD_SCHEDULER
        },
    ]);

    useEffect(() => {
        scheduler.history.length === 0 && getSchedulerData({page, count, filters: filter}, controller.signal);

        return () => {
            controller.abort();
            clean();
        }
        //eslint-disable-next-line
    }, []);

    return {
        isOk: isOk(),
        scheduler: scheduler?.data,
        history: scheduler?.history,
        isLoadingUpdate,
        isLoadingData,
        form, handleChange,
        tab, setTab,
        isEdit, setIsEdit,
        filter,
        value, handleChangeValue, handleKeyDown, setFilter,
        pagination: {
            bottomLoader: bottomLoader && ((scheduler.history.length < total) || (scheduler.history.length === total && total === 0)),
            onScroll,
            scrollRef
        },

        //edit
        handleStartDate,
        handleIsAllActiveRules,
        handleEndBy,
        handleRepeatTime,
        handleAlerts,
        handleClose,
        handleEdit,
        handleSave
    }
}