import {Box, styled} from "@mui/material";
import {TypographyH4} from "../../../../../../../../newShared/components/Inputs/styled";
import colors from "../../../../../../../../newShared/theme/colors";
import {SearchField} from "../../../../../../../../newShared/components/Basic/CommonInputs";
import {TypographyNew} from "../../../../../../../../newShared/components/TypographyNew";

export const TemplatesWrapper = styled(Box)(() => ({
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    // padding: '32px 0 32px 25px'
}));

export const TemplatesTitle = styled(TypographyH4)(() => ({
    color: colors.text.grey_dark
}));

export const SearchTemplate = styled(SearchField)(() => ({
    width: '240px',
    marginTop: '12px',
    '&.MuiInputBase-root': {
        height: '40px',
    }
}));
SearchTemplate.defaultProps = {
    size: 'small',
}

export const TemplatesListContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'auto',
    marginTop: '8px',

}))

export const TemplateItemBox = styled(Box, {
    shouldForwardProp: (propName) => (
        !["isSelected"].includes(propName.toString())
    )})(({isSelected}:{isSelected?: true}) => ({
    marginTop: '10px',
    width: '100%',
    justifyContent: 'flex-start',
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${isSelected ? colors.primary.blue : colors.primary.blue_light}`,
    borderRadius: '6px',
    padding: '10px 16px',
    cursor: 'pointer',
    gap: '10px'
}));

export const TemplateIconTitle = styled(TypographyNew, {
    shouldForwardProp: (propName) => (
        !["isSelected"].includes(propName.toString())
    )})(({isSelected}:{isSelected?: true}) => ({
    marginLeft: '6px',
    marginRight: '6px',
    whiteSpace: 'nowrap',
    maxWidth: '90%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: isSelected ? colors.primary.blue : undefined,
    '&: hover':{
        color: colors.primary.blue,
    }
}));
TemplateIconTitle.defaultProps = {
    variant: '*bodyText2',
    noWrap: true,
}

