import React, {FC, useEffect, useRef} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {TImportCsvField, TSetImportCsvDialogPayload} from "./types";
import {useMainTranslation} from "../../hooks/useMainTranslationHooks/useMainTranslation";
import {DialogContent} from "../editorUnion/components/editorDialog/styled";
import * as S from "../../../newDomain/components/kycRefactor/components/dialogs/templatesImportCsv/styled";
import {FileDrop} from "react-file-drop";
import {FileDropZone} from "../fileDropZone";
import colors from "../../theme/colors";
import {Download, DragIndicator, InfoOutlined} from "@mui/icons-material";
import {Flex} from "../Layouts";
import {LightTooltip} from "../editorUnion/components/editorSideMenu/styled";
import {LoadingButton} from "../Basic/CommonButtons";
import {ImportCsvDraggableRow} from "./components/importCsvDraggableRow";
import {useImportCsvDialog} from "./hooks/useImportCsvDialog";
import {TableRowSkeleton} from "../tableRowSkeleton";
import {IMPORT_CSV_DIALOG_EVENT_NAME} from "./constants";
import {ImportCsvPerformResponse} from "../../GQLTypes";
import {FlexColumn} from "../editorUnion/components/editorTitleWithActionsRow/styled";
import {TFieldsMapperKey} from "../../types";
import {useDndScrolling} from "react-dnd-scrolling";

export type TImportCsvDialogProps = {
    isOpen: boolean;

    getFields: (file: {file: string, name: string}) => void;
    isGettingFields: boolean;
    fields: TImportCsvField[];
    response?: ImportCsvPerformResponse,

    performCsv: (fields: TImportCsvField[]) => void;
    isPerformingCsv: boolean;

    setImportDialog: (data: TSetImportCsvDialogPayload) => void;

    downloadTemplate: () => void;
    isLoadingTemplate: boolean;

    fileLimitInMB?: number;
    fieldsMapperKey: TFieldsMapperKey;
    ignoreTooltipDescription?: string;
    uniqueTooltipDescription?: string;
}
export const ImportCsvDialog: FC<TImportCsvDialogProps> = ({isOpen, response, setImportDialog, getFields, isGettingFields, fields, performCsv, isPerformingCsv, downloadTemplate, isLoadingTemplate,
                                                               fileLimitInMB = 0, fieldsMapperKey,
                                                               uniqueTooltipDescription = 'Lines containing duplicate or already existing in system values in checked column will be ignored during import',
                                                               ignoreTooltipDescription = 'Values not matched to a field in system will be ignored during import'
                                                           }) => {
    const {t} = useMainTranslation('', {keyPrefix: 'CommonComponents.ImportCsvDialog'});
    const {tCommon} = useMainTranslation();
    const {
        onDrop, onChange, onUnassign,
        mismatchFields, setMismatchFields,

        fileInBase64, filePicker, fileHandler, ref, handleDeleteFile,
    } = useImportCsvDialog({getFields, fileLimitInMB});

    const reset = () => {
        handleDeleteFile();
        setMismatchFields([]);
        setImportDialog({reset: true})
    };
    const handleClose = () => {
        setImportDialog({isOpen: false});
    };

    useEffect(() => {
        if (isOpen) {
            reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const tableContainerRef = useRef<HTMLDivElement>(null);
    useDndScrolling(tableContainerRef, {
        
    });

    useEffect(() => {
        setMismatchFields(
            fields.slice().sort((a, b) => {
                if (!!a.fieldTitle !== !!b.fieldTitle) {
                    return b.fieldTitle ? 1 : -1;
                }

                if (a.isRequired !== b.isRequired) {
                    return (b.isRequired || false) ? 1 : -1;
                }

                if (a.fieldTitle !== b.fieldTitle) {
                    return (a.fieldTitle || '').localeCompare(b.fieldTitle || '');
                }

                return (a.csvField || '').localeCompare(b.csvField || '');
            }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields]);

    useEffect(() => {
        document.addEventListener(IMPORT_CSV_DIALOG_EVENT_NAME, handleDeleteFile);
        return () => document.removeEventListener(IMPORT_CSV_DIALOG_EVENT_NAME, handleDeleteFile);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resultMismatchFields = mismatchFields
        .filter(e => e.fieldKey?.length);

    return (
        <Dialog open={isOpen} onClose={handleClose} sx={{
            '& .MuiDialog-paper': {
                width: 'min(100vw, 800px)',
                maxWidth: 'min(100vw, 800px)',
            }
        }}>
            <DialogTitle>{t('Import CSV')}</DialogTitle>

            <DialogContent sx={{padding: '0 16px', display: 'flex', flexDirection: 'column', gap: '16px'}}>
                {response ? (
                    <FlexColumn sx={{gap: '12px'}}>
                        {response.message && <Typography variant={'*bodyText2'} color={colors.text.dark}>{response.message}</Typography>}
                        {typeof response.successCount === "number" && <Typography variant={'*bodyText2'} color={colors.text.dark}>{`${t('Successfully added')}: ${response.successCount}`}</Typography>}
                        {typeof response.duplicatesCount === "number" && <Typography variant={'*bodyText2'} color={colors.text.dark}>{`${t('Rows skipped')}: ${response.duplicatesCount}`}</Typography>}
                        {typeof response.errorRow === "number" && <Typography variant={'*bodyText2'} color={colors.text.dark}>{`${t('Csv analyze stopped at row')}: ${response.errorRow}`}</Typography>}
                        {typeof response.errorMessage === "string" && <Typography variant={'*bodyText2'} color={colors.text.dark}>{response.errorMessage}</Typography>}
                    </FlexColumn>
                ) : !fileInBase64 ? (
                    <>
                        {!!fileLimitInMB && (
                            <Typography variant={'*bodyText2'} color={colors.text.dark}>{tCommon(`File size should be up to {{fileLimitInMB}}MB`, {fileLimitInMB})}</Typography>
                        )}

                        <FileDrop onTargetClick={filePicker} onDrop={fileHandler}>
                            <FileDropZone
                                accept={'.csv'}
                                inputRef={ref}
                                fileHandler={fileHandler}
                            />
                        </FileDrop>

                        <LoadingButton loading={isLoadingTemplate} onClick={downloadTemplate} startIcon={<Download/>}
                                       sx={{width: 'fit-content'}}
                                       variant={"text"}>
                            {t('Download template')}
                        </LoadingButton>
                    </>
                ) : (
                    <>
                        <Typography variant={'*bodyText2'} color={colors.text.dark}>{t("For precise data integration, please assign CSV fields with corresponding template fields. Any unassigned fields will not be uploaded. To incorporate these fields, consider modifying the template accordingly.")}</Typography>

                        <TableContainer ref={tableContainerRef}
                                        sx={{
                                            overflow: 'auto', flexGrow: '1',
                                            // overflowX: 'hidden',
                                            // '& .MuiTableCell-root': {
                                            //     minWidth: 1
                                            // },
                                        }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant={'body2'} color={colors.text.grey_dark}>{t('Template field')}</Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Box sx={{display: 'flex', alignItems: 'center', gap: '16px',}}>
                                                <DragIndicator sx={{opacity: 0}}/>
                                                <Typography variant={'body2'} color={colors.text.grey_dark}>{t('CSV field')}</Typography>
                                            </Box>
                                        </TableCell>

                                        <TableCell sx={{maxWidth: 50}}>
                                            <Flex ai={"center"} gap={'12px'} jc={'center'}>
                                                <Typography variant={'body2'} color={colors.text.grey_dark}>{t('Ignore')}</Typography>
                                                <LightTooltip title={t(ignoreTooltipDescription)}>
                                                    <InfoOutlined fontSize={"small"}/>
                                                </LightTooltip>
                                            </Flex>
                                        </TableCell>

                                        <TableCell sx={{maxWidth: 50}}>
                                            <Flex ai={"center"} gap={'12px'} jc={'center'}>
                                                <Typography variant={'body2'} color={colors.text.grey_dark}>{t('Unique')}</Typography>
                                                <LightTooltip title={t(uniqueTooltipDescription)}>
                                                    <InfoOutlined fontSize={"small"}/>
                                                </LightTooltip>
                                            </Flex>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <S.CsvHeadersTableBody>
                                    {isGettingFields && mismatchFields.length === 0 && (new Array(5)).fill('').map((_, i) => (
                                        <TableRowSkeleton key={'Skeleton' + i} columns={4}/>
                                    ))}

                                    {mismatchFields.map(e => {
                                        const legacyField = fields.find(f => f.fieldKey === e.fieldKey);

                                        return (
                                            <ImportCsvDraggableRow
                                                key={'' + e.fieldKey + e.csvField}
                                                field={e}
                                                fieldsMapperKey={fieldsMapperKey}

                                                isDisabled={!!legacyField?.isDisabled}
                                                isDisabledUnique={legacyField?.isKey || false}
                                                isDisabledDrop={!e.fieldKey?.length || !!legacyField?.csvField?.length}
                                                isDisabledDrag={!(!legacyField?.csvField?.length || !e.fieldKey?.length) || !e.csvField?.length}
                                                onDrop={onDrop(e.fieldKey!)}
                                                onChange={onChange(e.fieldKey!)}
                                                onUnassign={onUnassign(e.fieldKey!, e.csvField || '')}
                                            />
                                        )
                                    })}
                                </S.CsvHeadersTableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'} size={'medium'}>{response ? t('Close') : t('Cancel')}</Button>
                {response?.errorRow ? (
                    <Button onClick={reset}>{t('Retry')}</Button>
                ) : !response && (
                    <LoadingButton size={'medium'} loading={isPerformingCsv}
                                   onClick={() => performCsv(resultMismatchFields)}
                                   disabled={
                                       fields.length === 0 || mismatchFields.length === 0
                                       || resultMismatchFields.some(f => f.isRequired && !f.csvField?.length)
                                   }>
                        {t('Proceed')}
                    </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    )
}
