import {useModelExact} from "../../../hooks/useModelExact";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {Typography} from "@mui/material";
import colors from "../../../../../../newShared/theme/colors";
import React from "react";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import * as S from "../../../components/styled";
import {CustomSlider} from "../../../components/customSlider";
import CommonLoading from "../../../../../../newShared/components/Basic/CommonLoading";
import {ModelTable} from "../../../components/modelTable";
import EditIcon from '@mui/icons-material/Edit';
import {DeleteModelDialog} from "../../../components/dialogs/deleteModel";
import {getLow, getMax} from "../../../helpers";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";

export const ModelsExact = () => {
    const {
        handleGoBack,
        handleDeleteModel,
        handleUseAsTemplate,
        selected,
        isLoading,
        handleGoToEdit
    } = useModelExact();
    const {isLessThen1160} = useMedia();
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'risks'});

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={selected?.name}
                handleGoBack={handleGoBack}
                showMenuWhen={isLessThen1160}
                actions={[
                    {
                        title: t('Use as template'),
                        onClick: handleUseAsTemplate,
                        disabled: !selected
                    },
                    {
                        title: t('Edit model'),
                        onClick: handleGoToEdit,
                        disabled: !selected || (selected && selected.counter > 0) || (selected && selected.isBasic),
                        icon: <EditIcon />,
                        variant: 'text',
                    },
                    {
                        title: t('Delete model'),
                        onClick: handleDeleteModel,
                        disabled: !selected || (selected && selected.isBasic) || (selected && selected.counter > 0),
                        icon: <DeleteForeverIcon />,
                        variant: 'text',
                    }
                ]}
            />

            {isLoading && <CommonLoading margin={'10px 0 0 0'}/>}
            {selected &&
                <>
                    <Typography variant={'body2'} color={colors.text.grey_dark} sx={{margin: '8px 0 0 0'}}>{t('Used count {{count}}', {count: selected.counter})}</Typography>

                    <S.ExactModelOverview>
                        <Flex w={'45%'} direction={'column'}>
                            <Typography variant={'subtitle2'} color={colors.text.grey_dark} sx={{margin: '8px 0'}}>{t('Probability scale')}</Typography>
                            {selected.probabilities.slice().sort((a, b) => a.quantitative - b.quantitative).map(e =>
                                <Flex ai={'center'}>
                                    <Typography variant={'body2'} color={colors.text.grey_dark} sx={{margin: !revDir ? '8px 5px 0 0' : '8px 0 0 5px'}}>{e.quality}: </Typography>
                                    <Typography variant={'body2'} color={colors.text.grey_dark} sx={{margin: '8px 0 0 0'}}> {e.quantitative}</Typography>
                                </Flex>
                            )}
                        </Flex>

                        <Flex w={'45%'} direction={'column'}>
                            <Typography variant={'subtitle2'} color={colors.text.grey_dark} sx={{margin: '8px 0'}}>{t('Impact scale')}</Typography>
                            {selected.impacts.slice().sort((a, b) => a.quantitative - b.quantitative).map(e =>
                                <Flex ai={'center'}>
                                    <Typography variant={'body2'} color={colors.text.grey_dark} sx={{margin: !revDir ? '8px 5px 0 0' : '8px 0 0 5px'}}>{e.quality}: </Typography>
                                    <Typography variant={'body2'} color={colors.text.grey_dark} sx={{margin: '8px 0 0 0'}}> {e.quantitative}</Typography>
                                </Flex>
                            )}
                        </Flex>
                    </S.ExactModelOverview>

                    {/*<Typography>low:{low} - left:{displayModel?.left} | right:{displayModel?.right} - max:{max}</Typography>*/}

                    {/*//TABLE*/}
                    <S.SliderAndModelViewWrapper>
                        {Boolean(selected?.impacts.length && selected.impacts.length > 1) && Boolean(selected?.probabilities.length && selected.probabilities.length > 1) && (
                            <>
                                <CustomSlider model={selected} max={getMax(selected)} min={getLow(selected)} editMode={false}/>
                                <ModelTable model={selected}/>
                            </>
                        )}
                    </S.SliderAndModelViewWrapper>
                </>
            }

            <DeleteModelDialog />
        </Flex>
    )
}