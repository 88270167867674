import {ColumnConfig, ColumnConfigWithValueGetter, CommonFilterType, genericTableSearchType} from "./types";
import {TPageInfo} from "../../types";
import {DEBUG} from "./constants";

export const getColumnConfigLSKey = (id: string, publicId: string, workspaceId: string) => id + '#columnConfig#' + publicId + '#' + workspaceId;
export const getIsTableLSKey = (id: string, publicId: string, workspaceId: string) => id + '#isTable#' + publicId + '#' + workspaceId;


export const saveIsTableLS = (isTable: boolean, id: string, publicId: string, workspaceId: string) => {
    const key = getIsTableLSKey(id, publicId, workspaceId);
    localStorage.setItem(key, JSON.stringify(isTable));
}

export const getIsTableLS = (id: string, publicId?: string, workspaceId?: string, disableShowCards?: boolean):boolean => {
    if(!publicId || !workspaceId || disableShowCards) return true;

    const key = getIsTableLSKey(id, publicId, workspaceId);
    const configJson = localStorage.getItem(key);
    if(configJson){
        try{
            return JSON.parse(configJson) as boolean;
        }catch (ex){
            console.log(`getIsTableLS ex`, ex);
            return true;
        }
    }else return true;
}

export const saveColumnConfigLS = (columns: ColumnConfig[], id: string, publicId: string, workspaceId: string) => {
    const key = getColumnConfigLSKey(id, publicId, workspaceId);
    localStorage.setItem(key, JSON.stringify(columns));
}

export const getColumnConfigLS = (id: string, publicId: string, workspaceId: string):ColumnConfig[] | null => {
    const key = getColumnConfigLSKey(id, publicId, workspaceId);
    const configJson = localStorage.getItem(key);
    if(configJson){
        try{
            return JSON.parse(configJson) as {key: string, name: string}[];
        }catch (ex){
            console.log(`getColumnConfigLS ex`, ex);
            return null;
        }
    }else return null;
}

export const getFilterWithSearch = (search: string, _filters: CommonFilterType, dontSendSearchIfEmpty: boolean, searchCustomFilterKeyName: string, searchAsArray: boolean, dontIncludeSearchInFilter: boolean):CommonFilterType => {
    const filters: CommonFilterType = {};
    for (const [key, value] of Object.entries(_filters)) {
        if (Array.isArray(value)) value.length > 0 && (filters[key] = value)
        else filters[key] = value;
    }

    if(dontIncludeSearchInFilter) return filters;
    if(dontSendSearchIfEmpty){
        return search.trim().length > 0 ? {...filters, [searchCustomFilterKeyName]: searchAsArray ? [search] : search} : filters;
    }else{
        return {...filters, [searchCustomFilterKeyName]: searchAsArray ? [search] : search}
    }
}

export function handlePagination<T>(prevRows: T[], prevPageInfo: TPageInfo, nextRows: T[], nextPageInfo: TPageInfo, minLoadedPage: number, maxLoadedPage: number, idField: keyof T): { results: T[], minLoadedPage: number, maxLoadedPage: number }{
    DEBUG && console.log(`*********handlePagination
    prevRows: ${prevRows.length}
    prevPageInfo: ${JSON.stringify(prevPageInfo)}
    nextRows: ${nextRows.length}
    nextPageInfo: ${JSON.stringify(nextPageInfo)}
    minLoadedPage: ${minLoadedPage}
    maxLoadedPage: ${maxLoadedPage}
    `)

    let _minLoadedPage = minLoadedPage;
    let _maxLoadedPage = maxLoadedPage;

    if(_minLoadedPage === -1 && _maxLoadedPage === -1){
        //initial fetch
        _maxLoadedPage = nextPageInfo.page;
        _minLoadedPage = nextPageInfo.page;
    }else{
        if(_minLoadedPage > nextPageInfo.page){
            //if loading page 2, and before that there was page 3 - replacing with 2
            _minLoadedPage = nextPageInfo.page;
        }
        if(maxLoadedPage < nextPageInfo.page){
            //if loading page 2, and before that there was page 1 - replacing with 2
            _maxLoadedPage = nextPageInfo.page;
        }
    }

    //check minLoadedPage - if nextPageInfo.page === minLoadedPage - 1 --- loaded prev page
    //
    //check maxLoadedPage - if nextPageInfo.page === minLoadedPage + 1 --- loaded next page
    if(nextPageInfo.page === minLoadedPage - 1){
        //loaded prev page
        const arr = [...nextRows, ...prevRows];
        // console.log(`handlePagination LOADED PREV PAGE`);
        // arr.length !== new Set(Array.from(arrIds)).size && console.log(`handlePagination DUPLICATES`);
        return {results: arr, minLoadedPage: _minLoadedPage, maxLoadedPage: _maxLoadedPage};
    }else if(nextPageInfo.page === maxLoadedPage + 1){
        //loaded next page
        // console.log(`handlePagination LOADED NEXT PAGE`)
        const arr = [...prevRows, ...nextRows];
        // arr.length !== new Set(Array.from(arrIds)).size && console.log(`handlePagination DUPLICATES`);
        return {results: arr, minLoadedPage: _minLoadedPage, maxLoadedPage: _maxLoadedPage};
    }

    if(minLoadedPage === maxLoadedPage){
        // first page loaded
        // console.log(`handlePagination - minLoadedPage === maxLoadedPage - INIT PAGE - nextRows`)
        return {results: nextRows, minLoadedPage: _minLoadedPage, maxLoadedPage: _maxLoadedPage};
    }

    // console.log(`handlePagination RETURN PREV`)
    return {results: prevRows, minLoadedPage, maxLoadedPage};//initial
}

//SEARCH MANIPULATIONS
export const replaceGenericTableSearch = <T extends Object | any>(replaceable: genericTableSearchType<T>): string => {
    return JSON.stringify({...replaceable, page: replaceable.page >= 0 ? replaceable.page : 0} as genericTableSearchType<T>);
}

export function urlDecode(input: string): string {
    try {
        return decodeURIComponent(input.replace(/\+/g, ' '));
    } catch (error) {
        console.error("Error decoding URL:", error);
        return input; // Return the original input if decoding fails
    }
}

export const getCurrentPage = (minLoadedPage: number, maxLoadedPage: number, scrollPosition: number, listFromTop: number): number => {
    let nearestPage = minLoadedPage;
    let minDistance = Infinity;

    for (let page = minLoadedPage; page <= maxLoadedPage; page++) {
        const pageElement = document.querySelector(`div[data-page='page-${page}']`);
        if (pageElement) {
            const pagePositionRelativeToList = Math.abs(pageElement.getBoundingClientRect().top - listFromTop);
            // const distance = Math.abs( scrollPosition - pagePositionRelativeToList);

            // console.log(`
            // \n
            // $scrollPosition
            // page: ${page}
            // scrollPosition: ${scrollPosition}
            // pagePositionRelativeToList: ${pagePositionRelativeToList}
            // listFromTop: ${listFromTop}
            // pageElement.getBoundingClientRect().top: ${pageElement.getBoundingClientRect().top}
            // \n
            // \n
            // `);

            if (pagePositionRelativeToList < minDistance) {
                minDistance = pagePositionRelativeToList;
                nearestPage = page;
            }
        }
    }
    // console.log(`getCurrentPage nearestPage: ${nearestPage}`)
    return nearestPage;
}

export const getElement = (type: 'row' | 'page' |  'skeletonDiv' | 'skeletonTr', dataAttribute: string): Element | null => {
    switch (type){
        case "page": return document.querySelector(`div[data-page='${dataAttribute}']`);
        case "row": return document.querySelector(`tr[data-row='${dataAttribute}']`);
        case "skeletonDiv": return document.querySelector(`div[data-skeleton='${dataAttribute}']`);
        case "skeletonTr": return document.querySelector(`tr[data-skeleton='${dataAttribute}']`);
    }
}

export const isElementVisible = (listRef: React.RefObject<HTMLElement>, type: 'row' | 'page' | 'skeletonDiv' | 'skeletonTr', dataAttribute: string): boolean => {
    DEBUG && console.log(`isElementVisible`, type, dataAttribute);
    if (!listRef.current) {
        // console.log(`isElementVisible - no list ref`);
        return false;
    }

    let element:Element | null = getElement(type, dataAttribute);

    if(element){
        const elemRect = element.getBoundingClientRect();
        const listRect = listRef.current.getBoundingClientRect();

        DEBUG && console.log(`isElementVisible -
            type: ${type}
            dataAttribute: ${dataAttribute}
            elemRect: \n${JSON.stringify(elemRect)}
            listRect: \n${JSON.stringify(listRect)}
            listRect.bottom < elemRect.top:(${listRect.top < elemRect.top}) && listRect.bottom < elemRect.bottom:(${listRect.bottom < elemRect.bottom}) = ${listRect.top < elemRect.top && listRect.bottom < elemRect.bottom}
        `);

        //elemRect {"x":327,"y":316.796875,"width":1165,"height":50,"top":316.796875,"right":1492,"bottom":366.796875,"left":327}
        //             listRect:
        //listRect {"x":327,"y":300.796875,"width":1165,"height":446.203125,"top":300.796875,"right":1492,"bottom":747,"left":327}
        //true
        return listRect.bottom > elemRect.top && listRect.top < elemRect.bottom;


        // // Only completely visible elements return true:

        // if(elemTop < 0) return false;
        //
        // if(calculateViaElemTop){
        //     return (elemTop >= 0) && (elemTop <= window.innerHeight)
        // }else{
        //     return (elemTop >= 0) && (elemBottom <= window.innerHeight);
        // }
    }

    return false;
}


export const getElementHeight = (dataAttribute: string): number => {
    let element:Element | null = getElement('row', dataAttribute);
    return element ? element.getBoundingClientRect().height : 0;
}

export const getInitialViewSettings = (id: string, fallback: ColumnConfigWithValueGetter<any>[], publicId?: string, workspaceId?: string): ColumnConfig[] => {
    if(!publicId || !workspaceId) {
        return fallback
            .filter(e => e.default)
            .map((e):ColumnConfig => ({name: e.name, key: e.key}));
    }
    const config = getColumnConfigLS(id, publicId, workspaceId);
    if(!config){
        return fallback
            .filter(e => e.default)
            .map((e):ColumnConfig => ({name: e.name, key: e.key}));
    }

    return config.map((e):ColumnConfig => ({name: e.name, key: e.key}));
}

export const getEmptyPaging = (total: number, isLoading: boolean) => {
    return {
        pageInfo: {page: 0, total: total, count: total},
        isLoading,
        minLoadedPage: 0,
        maxLoadedPage: 0,
        setMinMaxLoadedPage: () => null,
        clearRows: () => null,
        fetchPaging: () => null,
    }
}