import React, {FC, Fragment, MouseEventHandler, RefObject} from "react";
import {FlexRow} from "../../editorTitleWithActionsRow/styled";
import {Divider, IconButton, Zoom, ZoomProps} from "@mui/material";
import {SvgIconComponent} from "@mui/icons-material";
import {EditorPopperButtonsStyled, EditorPopperButtonsStyledModifiers} from "./styled";
import colors from "../../../../../theme/colors";
import {EditorTooltip} from "../editorTooltip";
import {useDrag} from "react-dnd";
import {EditorBlockData, EditorComponentsMethods, TEditorDragElementData} from "../../../types";

export interface EditorPopperButtonsProps {
    popperId: string;
    open: boolean;
    anchorEl: RefObject<HTMLDivElement> | null;

    buttons: {
        title: string;
        icon: SvgIconComponent | FC;
        onClick: MouseEventHandler<HTMLButtonElement>;
        isDisabled?: boolean;

        isDragAndMove?: boolean;
    }[][];

    dragData?: {
        getData: EditorComponentsMethods<EditorBlockData>["getData"],
    };
}
export const EditorPopperButtons: FC<EditorPopperButtonsProps> = ({popperId, open, anchorEl, buttons, dragData}) => {
    const boundary = document.querySelector('#EditorPagesAreaStyled') || undefined;

    const block = dragData?.getData();
    const type = block?.data.content[0]?.type || 'not found';

    const [, drag] = useDrag({
        type: type,
        item: {type: type, block, getData: dragData?.getData} satisfies TEditorDragElementData,
        collect: (monitor) => {
            return {
                isDragging: !!monitor.isDragging(),
            }
        }
    })

    return (
        <EditorPopperButtonsStyled id={popperId} open={open} anchorEl={anchorEl?.current} modifiers={EditorPopperButtonsStyledModifiers(boundary)}>
            {({ TransitionProps }: { TransitionProps: ZoomProps }) => (
                <Zoom {...TransitionProps} timeout={200}>
                    <FlexRow sx={{
                        border: '1px solid #ccc',
                        backgroundColor: colors.background
                    }}>
                        {buttons.map((array, index) => (
                            <Fragment key={JSON.stringify(array.map(e => ({...e, onClick: undefined})))}>
                                {array.map((button) => (
                                    <Fragment key={button.title}>
                                        <EditorTooltip title={button.title}>
                                            <span>
                                                <IconButton size={"small"}
                                                            ref={button.isDragAndMove ? drag : undefined}
                                                            onClick={!button.isDragAndMove ? button.onClick : undefined}
                                                            disabled={button.isDisabled}>
                                                    <button.icon/>
                                                </IconButton>
                                            </span>
                                        </EditorTooltip>
                                    </Fragment>
                                ))}

                                {index < buttons.length - 1 && (
                                    <Divider flexItem orientation={"vertical"}/>
                                )}
                            </Fragment>
                        ))}
                    </FlexRow>
                </Zoom>
            )}
        </EditorPopperButtonsStyled>
    )
}