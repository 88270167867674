import React, {Component, createRef, Fragment, MouseEvent, RefObject} from "react";
import {
    EditorFillableFieldItemType,
    FunctionsForToolBar,
    IStatusProps,
    MapDispatchToPropsObject,
    TEditorActorsConfigFromDB,
    TEditorElementData,
    TEditorFillableBlockData,
    TEditorFillableFieldItemType,
    TEditorPageElementData,
    TEditorPdfPageElementData,
    TFileContent,
    TUpdateDataInFillableFieldEventData,
} from "../../types";
import {EditorItemPage} from "../editorItemPage";
import {EditorPagesAreaStyled, EditorPagesWithSideMenuStyled, EditorWorkzoneStyled} from "./styled";
import {EditorSideMenu} from "../editorSideMenu";
import {EditorAddPageButton} from "../commonComponents/editorAddPageButton";
import {DEFAULT_ACTOR, DEFAULT_PAGE_DATA} from "../../constants";
import {ToolBar, ToolBarProps} from "../editorToolBar";
import {ToolBarContainer} from "../editorToolBar/styled";
import {initialEditorToolBarState} from "../editorToolBar/constants";
import {
    createEventNeedUpdateActors,
    createEventUnfocusAllBlocks,
    createEventUpdateDataInFillableField,
    isBlockPage,
    isBlockPdfPage,
    isRefBlock,
    isRefPage,
    isRefPdfPage,
    onSaveRepeat
} from "../../helpers";
import {uuid} from "../../../../utils";
import {TApproverStepProps} from "../editorSideMenu/components/approverStep";
import {TRecipientStepProps} from "../editorSideMenu/components/recipientStep";
import {LocalSpinner} from "../../../Spinner";
import {Typo} from "../../../Typography";
import {Document} from "react-pdf";
import {Props as DocumentPdfProps} from "react-pdf/dist/Document";
import {EditorItemPdfPage} from "../editorItemPdfPage";
import {Collapse} from "@mui/material";
import {downloadImageInEditorApi, downloadImageInEditorDocsApi, downloadImageInEditorPortalApi} from "../../api";
import debounce from "lodash.debounce";
import {NewDocDataVariableModel} from "../../../../GQLTypes";
import {getMsFromDays} from "../../../../utils/dateTools";
import {EditorFullProps} from "../editorDialog";

interface Props extends IStatusProps {
    content: TFileContent;
    variables: NewDocDataVariableModel[];
    actors: TEditorActorsConfigFromDB;
    pdfFileId?: string | null;

    onFillFields: (stepId: string, data: TUpdateDataInFillableFieldEventData) => void;

    onResendInviteApprover: TApproverStepProps["handleResendInvite"];
    onSkipApprover: TApproverStepProps["handleSkip"];
    onResendInviteRecipient: TRecipientStepProps["handleResendInvite"];
    onChangeEta: EditorFullProps["onChangeEta"];

    fillableFieldsWithValues: TEditorFillableBlockData[];

    // setSaveIsDisabled: (disabled: boolean) => void;
}
export interface EditorWorkZoneState {
    content: TFileContent;

    refs: Record<string, RefObject<EditorItemPage> | RefObject<EditorItemPdfPage>>,
    scrollRef: RefObject<HTMLDivElement>;

    activeZone: {pageId: string, zone: 'header' | 'footer'} | undefined;
    activeBlock: string[]; //components PAGE->ROW->BLOCK

    toolBarRef: RefObject<ToolBar>;
    sideMenuRef: RefObject<EditorSideMenu>;

    target: EventTarget | null;

    pdf: {loading: boolean, pdf: string | null, show: number, pdfId: string | null}; //show: -1 - not show, 0+ - total pages
}

export class EditorWorkZone extends Component<Props, EditorWorkZoneState> implements FunctionsForToolBar {
    constructor(props: EditorWorkZone["props"]) {
        super(props);

        //Creating refs for each pages
        const refs: EditorWorkZoneState["refs"] = {};
        props.content.forEach(block => {
            if (block.type === "page") {
                refs[block.id] = createRef<EditorItemPage>()
            }
            if (block.type === "pdfPage") {
                refs[block.id] = createRef<EditorItemPdfPage>()
            }
        })

        this.state = {
            content: JSON.parse(JSON.stringify(props.content)),

            refs,
            scrollRef: createRef<HTMLDivElement>(),

            activeZone: undefined,
            activeBlock: [],

            toolBarRef: createRef<ToolBar>(),
            sideMenuRef: createRef<EditorSideMenu>(),

            target: null,

            pdf: {loading: false, pdf: null, show: -1, pdfId: null},
        };
    }

    handleBackspace = (e: KeyboardEvent) => {
        if(e.key === 'Tab' && this.state.scrollRef.current?.contains(document.activeElement)){
            e.preventDefault();
        }
    }

    componentDidMount() {
        setTimeout(() => {createEventNeedUpdateActors({})}, 100)
        this.downloadPdfs();

        document.addEventListener('keydown', this.handleBackspace)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleBackspace)
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<EditorWorkZoneState>, snapshot?: any) {
        if (this.state.content.some(block => block.type === "pdfPage") && !this.props.pdfFileId) {
            this.setState(prev => ({...prev, content: prev.content.filter(block => block.type !== "pdfPage")}))
        }

        this.downloadPdfs();
    }

    downloadPdfs = debounce(() => {
        const {pdfFileId} = this.props;

        if (!pdfFileId) return;
        if (this.state.pdf.loading) return;
        if (this.state.pdf.pdf && this.state.pdf.pdfId === pdfFileId) return;

        const refs: EditorWorkZoneState["refs"] = {};
        this.props.content.forEach(block => {
            if (block.type === "pdfPage") {
                refs[block.id] = createRef<EditorItemPdfPage>()
            }
        })

        this.setState(prev => ({
            ...prev,
            content: this.props.content,
            refs: {...prev.refs, ...refs},
            pdf: {loading: true, pdf: null, show: -1, pdfId: this.props.pdfFileId || null},
        }));

        (
            this.props.status.isMain
                ? downloadImageInEditorApi({id: pdfFileId, workspaceId: ''})
                : this.props.status.isPortal
                    ? downloadImageInEditorPortalApi({id: pdfFileId})
                    : downloadImageInEditorDocsApi({id: pdfFileId})
        )
            .then((result) => {
                this.setState(prev => ({...prev, pdf: {loading: false, pdf: result?.file || null, show: -1, pdfId: this.props.pdfFileId || null}}))
            })
            .catch(() => {
                this.setState(prev => ({...prev, pdf: {loading: false, pdf: null, show: -1, pdfId: null}}))
            });
    }, 10)

    getActualVariables = (): NewDocDataVariableModel[] => {
        return this.state.sideMenuRef.current?.state.variables.map((e) => ({
            ...e, count: undefined
        })) ?? [];
    }

    getDataForSave = (): {content: TEditorElementData[], variables: NewDocDataVariableModel[], actors: TEditorActorsConfigFromDB} => {
        const variables: NewDocDataVariableModel[] = JSON.parse(JSON.stringify(this.getActualVariables()));
        const content: TEditorElementData[] = JSON.parse(JSON.stringify(onSaveRepeat(this.state.content, this.state.refs)))

        const _fields: TEditorFillableBlockData[] = [];
        content.forEach(page => {
            const ref = this.state.refs[page.id]?.current;
            if (isRefPage(ref) || isRefPdfPage(ref)) {
                const metaPageHeight = ref.state.wrapperRef.current?.offsetHeight ?? 0;
                _fields.push(...ref.state.fillableFields.map(e => ({
                    ...e,
                    position: {...e.position, metaPageHeight}
                })))
            }

            if (!ref) {
                _fields.push(...this.getEditorFillableBlockDataFromProps(page.id))
            }
        });

        const actors: TEditorActorsConfigFromDB = JSON.parse(JSON.stringify(this.state.sideMenuRef.current?.getActorsData() || {recipients: [], approvers: [], editors: []}));
        if (_fields.some(e => e.actor.email === '' && e.role === '')) actors.recipients.push({
            fields: [],
            actor: DEFAULT_ACTOR,
            role: '',
            order: -1,
            message: '',
            eta: getMsFromDays(7),
        });

        actors.recipients = actors.recipients.map(e => {
            const fields = _fields.filter(f => ('' + e.actor.email + e.role) === f.sourceEmailRole);

            return {
                ...e,
                sourceEmailRole: undefined,
                fields: fields.map(f => ({...f, actor: undefined, data: undefined, sourceEmailRole: undefined, role: undefined})),
            }
        })

        return {
            content,
            variables,
            actors: {
                recipients: actors.recipients.filter(e => !(e.actor.email === '' && !e.fields?.length)),
                approvers: actors.approvers,
                editors: actors.editors,
            }
        }
    };

    addPageHandler = (index: number) => (e: MouseEvent<HTMLButtonElement>) => {
        const previousPage = index > 0 ? this.state.content[index - 1] : undefined;
        const isPreviousPage = isBlockPage(previousPage);

        const id = 'page' + uuid();
        const data: TEditorPageElementData["data"] = {
            content: [],
            header: [],
            footer: [],

            size: (isPreviousPage && previousPage?.data.size) || 'A4',
            orientation: (isPreviousPage && previousPage?.data.orientation) || 'portrait',
            headerHeight: DEFAULT_PAGE_DATA.headerHeight,
            footerHeight: DEFAULT_PAGE_DATA.footerHeight,
            marginTop: (isPreviousPage && previousPage?.data.marginTop) || DEFAULT_PAGE_DATA.marginTop,
            marginBottom: (isPreviousPage && previousPage?.data.marginBottom) || DEFAULT_PAGE_DATA.marginBottom,
            marginLeft: (isPreviousPage && previousPage?.data.marginLeft) || DEFAULT_PAGE_DATA.marginLeft,
            marginRight: (isPreviousPage && previousPage?.data.marginRight) || DEFAULT_PAGE_DATA.marginRight,
        };

        this.setState(prev => {
            //condition for avoiding setState twice firing in StrictMode
            if (!prev.content.some(e => e.id === id) && !prev.refs[id]) {
                prev.content.splice(index, 0, {type: 'page', id, data});
                prev.refs[id] = createRef<EditorItemPage>();

                //scroll to new page after render
                setTimeout(() => {
                    this.state.refs[id]?.current?.state.wrapperRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
                }, 200)
            }

            return prev
        });
    };

    deletePageHandler = (id: string) => (e: MouseEvent<HTMLButtonElement>) => {
        this.setState(prevState => {
            const index = prevState.content.findIndex(block => block.id === id);
            index > -1 && prevState.content.splice(index, 1);
            delete prevState.refs[id];

            return prevState
        })
    };

    setActiveZone = (activeZone: EditorWorkZoneState["activeZone"]) => {
        this.setState(prev => ({...prev, activeZone}))
    }

    ///////ToolBar
    setToolBar: MapDispatchToPropsObject["setToolBar"] = (toolBar, ids) => {
        this.state.toolBarRef.current?.setPartiallyState(toolBar, ids)
    }
    setTextByToolBar: ToolBarProps["toolBarHandler"] = (action, payload, ids) => {
        const nextId = ids[0];
        if (nextId) {
            const ref = this.state.refs[nextId]?.current;
            if (ref) {
                ref.setTextByToolBar(action, payload, ids.slice(1))
            }
        }
    }
    getToolBarState = () => {
        return this.state.toolBarRef.current?.getState() || initialEditorToolBarState;
    }

    setActiveBlock = (ids: EditorWorkZoneState["activeBlock"]) => {
        // ids.slice(-1) !== this.state.activeBlock.slice(-1) && this.setInactiveBlock();
        this.setState(prev => ({...prev, activeBlock: ids}))
    }
    setInactiveBlock = (event: MouseEvent<HTMLDivElement> | null = null) => {
        createEventUnfocusAllBlocks({});
        if (this.state.activeBlock.length === 0) return;

        const ids = [...this.state.activeBlock];
        let nextId: string | null = this.state.activeBlock[0];
        let refs: Record<string, RefObject<any>> = this.state.refs;

        while (nextId) {
            const ref = refs[nextId]?.current;
            ids.splice(0, 1)

            if (ref) {
                if (ids.length === 0 && isRefBlock(ref)) {
                    ref.onClickAway();
                    nextId = null;
                    break;
                }

                nextId = ids[0];
                refs = ref.state?.refs || {};
            } else {
                nextId = null;
            }
        }
    }

    handleSubmitText = (fieldId: string, value: string) => {
        const currentStepId = this.props.execution?.currentStepId;
        currentStepId && this.props.onFillFields(currentStepId, [{fieldId, value}])
        createEventUpdateDataInFillableField([{fieldId, value}]);
    }

    handleSubmitSign = (fieldId: string, value: string, insertEverywhere: boolean) => {
        const currentStepId = this.props.execution?.currentStepId;

        if (!insertEverywhere) {
            createEventUpdateDataInFillableField([{fieldId, value}]);
            currentStepId && this.props.onFillFields(currentStepId, [{fieldId, value}])
        } else {
            const ids = this.state.content.map(e => e.id);
            const fields: TEditorFillableBlockData[] = [];

            ids.forEach(id => {
                const ref = this.state.refs[id]?.current;
                if (ref) {
                    const blocks = ref.state.fillableFields.filter(e => e.type === EditorFillableFieldItemType["SIGN"] && e.actor?.email === this.props.status.currentEmail);
                    fields.push(...blocks);
                }
            });

            const data = fields.map(e => ({fieldId: e.id, value}));
            createEventUpdateDataInFillableField(data);
            currentStepId && this.props.onFillFields(currentStepId, data)
        }
    }

    getEditorFillableBlockDataFromProps: (pageId: string) => TEditorFillableBlockData[] = (pageId) => {
        const result = this.props.actors.recipients.flatMap((actor): TEditorFillableBlockData[] => (
            (actor.fields ?? [])
                .map((field): TEditorFillableBlockData => {

                    return {
                        id: field.id,
                        type: field.type as TEditorFillableFieldItemType[keyof TEditorFillableFieldItemType],
                        pageId: field.pageId,
                        position: field.position,
                        size: field.size,

                        data: this.props.fillableFieldsWithValues.find(e => e.id === field.id)?.data || '', // fileId or text

                        actor: {
                            email: actor.actor.email || '',
                            firstName: actor.actor.firstName || '',
                            lastName: actor.actor.lastName || '',
                        },

                        isRequired: field.isRequired,
                        sourceEmailRole: actor.actor?.email + actor.role,
                        role: actor.role || '',
                    }
                })
                .filter(e => e.pageId === pageId)
        ));

        return result
    }

    onDocumentPdfLoadSuccess: DocumentPdfProps["onLoadSuccess"] = ({numPages}) => {
        if (this.state.content.filter(e => e.type === "pdfPage").length === 0) {
            const pdfPages: TEditorPdfPageElementData[] = [];
            const refs: EditorWorkZoneState["refs"] = this.state.refs;

            for (let i = 1; i <= numPages; i++) {
                const id = 'pdfPage' + uuid();
                pdfPages.push({
                    id: id,
                    type: "pdfPage",
                    data: {
                        index: i,
                        pdfFileId: this.props.pdfFileId || '',
                        content: [],
                    }
                })

                refs[id] = React.createRef<EditorItemPdfPage>();
            }

            this.setState(prev => ({...prev, content: [...prev.content, ...pdfPages], pdf: {...prev.pdf, show: numPages}, refs}))
        } else {
            this.setState(prev => ({...prev, pdf: {...prev.pdf, show: numPages}}))
        }
    }

    makeFillableFieldsEmpty = () => {
        setTimeout(() => {
            const ids: string[] = [];

            this.state.content.forEach(e => {
                const ref = this.state.refs[e.id]?.current;
                if (ref) {
                    ref.state.fillableFields.forEach(field => {
                        ids.push(field.id);
                    })
                }
            })

            createEventUpdateDataInFillableField(ids.map(id => ({fieldId: id, value: ''})));
        }, 10);
    }

    render() {
        if (this.props.status.isDraft) {
            this.makeFillableFieldsEmpty();
        }

        return (
            <EditorWorkzoneStyled>
                {/*<EditorDropZoneForRemovingFillableField>*/}
                {!this.props.readonly && (
                    <Collapse in={!this.props.status.isVerifyPdf} sx={{flexShrink: 0}}>
                        <ToolBarContainer>
                            <ToolBar toolBarHandler={this.setTextByToolBar} ref={this.state.toolBarRef}/>
                        </ToolBarContainer>
                    </Collapse>
                )}

                <EditorPagesWithSideMenuStyled
                    onMouseDown={!this.props.readonly && !this.props.status.isPdf ? (e) => {
                        if (e.target !== this.state.target)
                            this.setState(prev => ({...prev, target: e.target}))
                    } : undefined}
                    onMouseUp={!this.props.readonly && !this.props.status.isPdf ? (e) => {
                        if (this.state.target === e.target && e.currentTarget.id === EditorPagesWithSideMenuStyled.defaultProps?.id) {
                            e.nativeEvent.stopImmediatePropagation();
                            this.setInactiveBlock(e)
                        }
                    } : undefined}
                >
                    {!this.props.status.isPdf ? (
                        <EditorPagesAreaStyled ref={this.state.scrollRef} id={'EditorPagesAreaStyled'}>

                            {!this.props.readonly && (
                                <EditorAddPageButton onClick={this.addPageHandler(0)}/>
                            )}

                            {this.state.content
                                .filter(e => e.type !== "pdfPage")
                                .map((block, index) => (
                                    block.type === "page" && (
                                        <Fragment key={block.id + ' index ' + index}>
                                            <EditorItemPage key={block.id + ' page'} ref={this.state.refs[block.id] as RefObject<EditorItemPage>}
                                                            block={block} onDelete={this.deletePageHandler(block.id)}
                                                            setActiveZone={this.setActiveZone} activeZone={this.state.activeZone}
                                                            parentRef={this}
                                                            editorWorkZoneRef={this}
                                                            getToolBarState={this.getToolBarState}
                                                            fillableFields={this.getEditorFillableBlockDataFromProps(block.id)}

                                                            handleSubmitText={this.handleSubmitText}
                                                            handleSubmitSign={this.handleSubmitSign}

                                                            readonly={this.props.readonly}
                                                            status={this.props.status}
                                                            isLoading={this.props.isLoading}
                                            />

                                            {!this.props.readonly && (
                                                <EditorAddPageButton key={'add page button ' + index} onClick={this.addPageHandler(index + 1)}/>
                                            )}
                                        </Fragment>
                                    )
                                ))}
                        </EditorPagesAreaStyled>
                    ) : this.props.pdfFileId && (
                        <EditorPagesAreaStyled ref={this.state.scrollRef} id={'EditorPagesAreaStyled'}>

                            {this.state.pdf.loading && <LocalSpinner />}
                            {!this.state.pdf.loading && !this.state.pdf.pdf && (
                                <Typo fontSize={'16px'} fontWeight={400}>File not found!</Typo>
                            )}
                            {this.state.pdf.pdf &&
                                <Document
                                    file={`data:application/pdf;base64,${this.state.pdf.pdf}`}
                                    onLoadSuccess={this.onDocumentPdfLoadSuccess}
                                >
                                    {(this.state.pdf.show > 0) && this.state.content
                                        .filter(e => e.type === "pdfPage")
                                        .map((block) => isBlockPdfPage(block) && (
                                            <EditorItemPdfPage key={block.id} ref={this.state.refs[block.id] as RefObject<EditorItemPdfPage>}
                                                               block={block}

                                                               parentRef={this}
                                                               editorWorkZoneRef={this}
                                                               getToolBarState={this.getToolBarState}
                                                               fillableFields={this.getEditorFillableBlockDataFromProps(block.id)}

                                                               handleSubmitText={this.handleSubmitText}
                                                               handleSubmitSign={this.handleSubmitSign}

                                                               readonly={this.props.readonly}
                                                               status={this.props.status}
                                                               isLoading={this.props.isLoading}
                                            />
                                        ))}
                                </Document>
                            }
                        </EditorPagesAreaStyled>
                    )}

                    {!this.props.status.isTemplate && (
                        <EditorSideMenu ref={this.state.sideMenuRef}
                                        variables={this.props.variables} actorsConfig={this.props.actors}

                                        onResendInviteApprover={this.props.onResendInviteApprover}
                                        onResendInviteRecipient={this.props.onResendInviteRecipient}
                                        onSkipApprover={this.props.onSkipApprover}
                                        onChangeEta={this.props.onChangeEta}

                                        status={this.props.status}
                                        readonly={this.props.readonly}
                                        execution={this.props.execution}
                                        isLoading={this.props.isLoading}
                        />
                    )}
                </EditorPagesWithSideMenuStyled>

                {/*</EditorDropZoneForRemovingFillableField>*/}
            </EditorWorkzoneStyled>
        );
    }
}