import {useDispatch, useSelector} from "react-redux";
import {expandLessFolder, expandMoreFolder, folders, isLoading, openFolder, selectedFolder} from "../../store/slice";
import {DocumentFolder} from "../../types";
import {
    ALL_DOCUMENTS_FOLDER_NAME,
    DOCUMENT_LIST_DEFAULT_COUNT,
    DOCUMENTS_FOLDER_NAME,
    GALLERY_FOLDER_NAME,
    MAX_TREE_EXPAND_DEPTH,
    NOT_CLICKABLE_FOLDER_NAMES,
    PORTAL_FOLDER_NAME,
    TEMPLATE_GALLERY_FOLDER_NAME,
    TEMPLATES_FOLDER_NAME,
    TRASH_FOLDER_NAME,
    TRASH_LOCAL_ID,
    TREE_GALLERY_PATH,
    TREE_PATH,
    TREE_TRASH_PATH
} from "../../constants";

//ICONS
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import {ReactComponent as FolderIcon} from "../../../../../newShared/images/menu/folder.svg"; // fill
import {ReactComponent as TrashIcon} from "../../../../../newShared/images/menu/document-trash.svg"; // fill
import {ReactComponent as AllDocumentsIcon} from "../../../../../newShared/images/menu/all-documents.svg"; // fill
import {ReactComponent as GalleryIcon} from "../../../../../newShared/images/menu/documents-template-gallery.svg"; // fill
//
import {IconButton} from "@mui/material";
import {GetShortDocumentsByFolderIdWithFilterPaging} from "../../store/actions";
import {useHistory} from "react-router-dom";
import {LocalSpinner} from "../../../../../newShared/components/Spinner";
import {Flex} from "../../../../../newShared/components/Layouts";
import {useLeftMenu} from "../../../../barsEnvironment/leftMenu/hooks/useLeftMenu";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import * as S from "./styled";
import {useState} from "react";

export const LeftSideTree = () => {

    const _folders = useSelector(folders);
    const isLoadingFolders = useSelector(isLoading).isLoadingTree;

    return (
        <S.LeftList >
            {isLoadingFolders && <LocalSpinner />}
            {!isLoadingFolders && _folders.filter(e => ![TEMPLATES_FOLDER_NAME, PORTAL_FOLDER_NAME].includes(e.name)).map(f => <Folder folder={f} key={f.id} currPath={[{name: f.name, id: f.id}]}/>)}
        </S.LeftList>
    )
}

const Folder = ({folder, currPath}: {folder: DocumentFolder, currPath: {name: string, id: string}[]}) => {
    const {revDir} = useMainTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [isOpened, setIsOpened] = useState<boolean>(false);
    // const isOpened = useSelector(openedFolders).some(e => e === folder.id);
    const _selectedFolder = useSelector(selectedFolder);
    const isSelected = _selectedFolder?.id === TRASH_LOCAL_ID ? folder.id === TRASH_LOCAL_ID : _selectedFolder?.id === folder.id;
    const isDisabled = NOT_CLICKABLE_FOLDER_NAMES.some(e => e === folder.name);

    const handleExpandMoreFolder = (e: any) => {
        e.stopPropagation();
        if(currPath.length <= MAX_TREE_EXPAND_DEPTH && !isDisabled){
            setIsOpened(true);
            dispatch(expandMoreFolder(folder.id));
        }
        // currPath.length <= MAX_TREE_EXPAND_DEPTH && !isDisabled && dispatch(expandMoreFolder(folder.id));
    }

    const handleExpandLessFolder = (e: any) => {
        e.stopPropagation();
        dispatch(expandLessFolder(folder));
        setIsOpened(false);
    }

    const {setOpenCustom} = useLeftMenu();
    const handleOpenFolder = () => {
        if(isDisabled) return;
        if(_selectedFolder && _selectedFolder.id === folder.id) return;
        if(folder.name === GALLERY_FOLDER_NAME){
            history.push(TREE_GALLERY_PATH);
            dispatch(openFolder({folder: folder, path: currPath}));
            // dispatch(GetFilesByFolderId({folderId: folder.id}));
            dispatch(GetShortDocumentsByFolderIdWithFilterPaging({data: {pageInfo: {page: 0, count: DOCUMENT_LIST_DEFAULT_COUNT}, folderId: folder.id, workspaceId: ''}}));
        } else if(folder.name === TRASH_FOLDER_NAME){
            history.push(TREE_TRASH_PATH);
            dispatch(openFolder({folder: folder, path: currPath}));
            // dispatch(GetFilesByFolderId({folderId: folder.id}));
            dispatch(GetShortDocumentsByFolderIdWithFilterPaging({data: {pageInfo: {page: 0, count: DOCUMENT_LIST_DEFAULT_COUNT}, folderId: folder.id, workspaceId: ''}}));
        } else{
            history.push(TREE_PATH);
            dispatch(openFolder({folder: folder, path: currPath}));
            // dispatch(GetFilesByFolderId({folderId: folder.id}));
            dispatch(GetShortDocumentsByFolderIdWithFilterPaging({data: {pageInfo: {page: 0, count: DOCUMENT_LIST_DEFAULT_COUNT}, folderId: folder.id, workspaceId: ''}}));
        }
        setOpenCustom(false);
    }

    const isInnerFolder: boolean = folder.name !== TRASH_FOLDER_NAME && folder.name !== DOCUMENTS_FOLDER_NAME && folder.name !== GALLERY_FOLDER_NAME;

    return (
        <Flex w={'100%'} overflow={'auto'} b={'1px solid red'}>
            <S.FolderItemContainer revDir={revDir ? true : undefined} pathLength={currPath.length}>

                <S.FolderFlexBox isDisabled={isDisabled ? true : undefined} isSelected={isSelected ? true : undefined} onClick={handleOpenFolder} >
                    {folder.name !== TRASH_FOLDER_NAME &&
                        <IconButton
                            size={"medium"}
                            onClick={(e) => isOpened ? handleExpandLessFolder(e) : handleExpandMoreFolder(e)}
                            disabled={isDisabled || !folder.children.length || currPath.length > MAX_TREE_EXPAND_DEPTH}
                        >
                            {isOpened ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
                        </IconButton>
                    }
                    {GetFolderIconByName(folder.name, currPath.length === 1)}
                    {isInnerFolder ?
                        <S.LeftTitleInnerDocument revDir={revDir ? true : undefined}
                                                  isDisabled={isDisabled ? true : undefined}>{folder.name}</S.LeftTitleInnerDocument>
                         :
                        <S.LeftTitleDocument revDir={revDir ? true : undefined}
                                          isDisabled={isDisabled ? true : undefined}>{GetFolderName(folder.name)}</S.LeftTitleDocument>}
                </S.FolderFlexBox>
                {isOpened && <Flex w={'100%'} direction={'column'} overflowy={'auto'}>
                    {folder.children.map(f => <Folder folder={f} key={f.id} currPath={[...currPath, {name: f.name, id: f.id}]}/>)}
                </Flex>}
            </S.FolderItemContainer>
        </Flex>

    )
}

export const GetFolderName = (name: string): string => {
    switch (name){
        case DOCUMENTS_FOLDER_NAME: return ALL_DOCUMENTS_FOLDER_NAME;
        case GALLERY_FOLDER_NAME: return TEMPLATE_GALLERY_FOLDER_NAME;
        default: return name;
    }
}

export const GetFolderIconByName = (name: string, isRoot: boolean) => {
    const {revDir} = useMainTranslation();
    if (!isRoot) return <FolderIcon />;

    switch (name){
        case TRASH_FOLDER_NAME: return <TrashIcon style={{margin: !revDir ? '12px 0 12px 41px' : '12px 41px 12px 0'}}/>;
        case DOCUMENTS_FOLDER_NAME: return <AllDocumentsIcon />;
        case TEMPLATES_FOLDER_NAME: return <RestorePageIcon />;
        case GALLERY_FOLDER_NAME: return <GalleryIcon />;
        case PORTAL_FOLDER_NAME: return <FolderSharedIcon />;
        default: return <FolderIcon />;
    }
}
