import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    activateAutomation,
    addAutomation,
    deactivateAutomation,
    deleteAllFiles,
    deleteAutomation,
    getAutomationById,
    getAutomationEvidences,
    getAutomationHistory,
    getPolicies,
    getShortAutomations,
    runAutomation,
    stopAutomation,
    updateAutomation,
    uploadFile
} from "../api";
import {addAutomationToArray, setSelectedAutomation, updateAutomationInArray} from './slice';
import {getActionsData} from "../../../../newShared/redux";
import {addInfoSnack, addSuccessfulSnack} from "../../../barsEnvironment/snack/store/slice";
import {automationPolicyType, automationType} from "../types";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {FilterInput, PaginationInput} from "../../../../newShared/GQLTypes";
import {getIsoDateTimeNow} from "../helpers";
import {TWithOptions} from "../../../../newShared/types";
import {getShortEmployees} from "../../employeesRefactor/api";

export const GetPolicies = createAsyncThunk(
    'Automations/GetPolicies',
    async (_, {getState}): Promise<automationPolicyType[]> => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        return await getPolicies({organizationId: organizationId || '', workspaceId: workspaceId || ''});
    }
);

export const GetShortAutomations = createAsyncThunk(
    'Automations/GetShortAutomations',
    async ({data, signal}: TWithOptions<{pageInfo: PaginationInput}>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getShortAutomations({workspaceId: workspaceId || '', ...data}, signal);
    }
);

export const GetAutomationById = createAsyncThunk(
    'Automations/GetByID',
    async ({data, signal} : TWithOptions<{id: string}>, {getState}): Promise<automationType> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        return await getAutomationById({workspaceId: workspaceId || '', data: data.id}, signal);
    }
);


export const AddAutomation = createAsyncThunk(
    'Automations/AddAutomation',
    async ({automation, onSuccess}: { automation: automationType, onSuccess: (id: string) => void }, {dispatch, getState}): Promise<void> => {
        const state = getState() as AppState;
        const {workspaceId, workspaceName} = getActionsData(state);
        const request = {
            id: null,
            name: automation.name,
            workspaceId: workspaceId,
            workspaceName: workspaceName,
            active: true,
            trigger: automation.trigger,
            documents: automation.documents,
            stages: automation.stages,
            evidence: automation.evidence,
            createDate: getIsoDateTimeNow(),
            lastRunningDate: null,
            lastExecutorId: null,
        }
        const automationId = await addAutomation({workspaceId: workspaceId, data: request});

        onSuccess(automationId); //going to exact

        dispatch(addAutomationToArray({
            id: automationId,
            name: automation.name,
            lastRunStatus: null,
            lastExecutorId: null,
            lastRunningDate: null,
            active: true,
        }));
        dispatch(setSelectedAutomation({...automation, id: automationId}));
        dispatch(addSuccessfulSnack('Automation added'));
        // dispatch(GetAutomationById(automationId));
    }
);

//uploadFile
export const UploadFile = createAsyncThunk(
    'Automations/UploadFile',
    async (data: {file: string, fileName: string}, {getState}): Promise<{ fileId: string, fileName: string, lastUpdated: string }> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const fileId =  await uploadFile({workspaceId: workspaceId || '', file: data.file});
        // const fileId = data.fileName + 'WITHSOMETESTID';
        // console.log('FILE UPLOADED: ', data.fileName);
        return {
            fileId,
            fileName: data.fileName,
            lastUpdated: getIsoDateTimeNow(),
        }
    }
);

export const DeleteAllFile = createAsyncThunk(
    'Automations/DeleteAllFiles',
    async (data: {fileIds: string[], autoId: string}, {getState, dispatch}): Promise<{fileIds: string[], autoId: string }> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await deleteAllFiles({fileIds: {fileIds: data.fileIds}, workspaceId: workspaceId || ''});
        // console.log('DELETE FILES: ', data.fileIds, ' AUTOID: ', data.autoId);
        if(data.autoId.length > 0) dispatch(addInfoSnack('Warning: All files in documents stage were deleted!'));
        return data;
    }
);


//updateAutomation
export const UpdateAutomation = createAsyncThunk(
    'Automations/UpdateAutomation',
    async (automation: automationType, {dispatch, getState}): Promise<void> => {
        const state = getState() as AppState;
        const {workspaceName, workspaceId} = getActionsData(state);
        //updating workspace too if it was changed since creating time
        const request = {
            id: automation.id,
            name: automation.name,
            workspaceId: automation.workspaceId,
            workspaceName,
            active: true,
            trigger: automation.trigger,
            documents: automation.documents,
            stages: automation.stages,
            evidence: automation.evidence,
            createDate: automation.createDate,
            lastRunningDate: automation.lastRunningDate,
            lastExecutorId: automation.lastExecutorId,
        }

        const message = await updateAutomation({workspaceId: workspaceId, data: request});

        dispatch(updateAutomationInArray({
            id: automation.id,
            lastRunningDate: automation.lastRunningDate,
            lastExecutorId: automation.lastExecutorId,
            lastRunStatus: 'willBeFilledInSlice',
            name: automation.name,
            active: automation.active,
        }));

        dispatch(addSuccessfulSnack(message));
    }
);

//deleteAutomation

//updateAutomation
export const DeleteAutomation = createAsyncThunk(
    'Automations/DeleteAutomation',
    async (data: {id: string, filter: FilterInput, onSuccess?: () => void}, {getState, dispatch}): Promise<string> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        await deleteAutomation({workspaceId: workspaceId, data: data.id});

        data.onSuccess && data.onSuccess();

        // dispatch(addSuccessfulSnack('Automation deleted!'));

        // if(pageInfo.total !== 1 && automations.length === 1){
        //     //if not visible automations on a page (just deleted the last one) and total is more then 1 (just deleted last one - so he will become 0 after return)
        //     if(pageInfo.page === 0){
        //         //if there more automations but this was the last one on a page 0 - reloading by page 0 - they will pop
        //         dispatch(GetShortAutomations({pageInfo: {page: 0, count: pageInfo.count, filters: data.filter}}));
        //     }else if(pageInfo.total - 1 / pageInfo.count === pageInfo.page){ //la
        //         //if its last page - going to page -1
        //         dispatch(GetShortAutomations({pageInfo: {page: pageInfo.page - 1, count: pageInfo.count, filters: data.filter}}));
        //     }else{
        //         dispatch(GetShortAutomations({pageInfo: {page: pageInfo.page, count: pageInfo.count, filters: data.filter}}));
        //     }
        // }
        // dispatch(GetShortAutomations({pageInfo: {page: 0, count: 0}}));
        return data.id;
    }
);

//stopAutomation
export const StopAutomation = createAsyncThunk(
    'Automations/StopAutomation',
    async (id: string, {getState, dispatch}): Promise<string> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const message = await stopAutomation({workspaceId: workspaceId || '', data: id});

        dispatch(addSuccessfulSnack(message));
        dispatch(GetAutomationById({data: {id}}));
        return id;
    }
);

//runAutomation


export const RunAutomation = createAsyncThunk(
    'Automations/RunAutomation',
    async (id: string, {getState, dispatch}): Promise<string> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const {Automations : {selectedAutomation}} = state;

        await runAutomation({workspaceId: workspaceId, data: id});

        dispatch(addSuccessfulSnack('Automation run!'));
        if(selectedAutomation){
            dispatch(GetAutomationHistory({data:{id, page: selectedAutomation.pageInfo.page, count: 5}}));
        }
        return id;
    }
);

//getAutomationHistory

export const GetAutomationHistory = createAsyncThunk(
    'Automations/getAutomationHistory',
    async ({data, signal}: TWithOptions<{id: string, page: number, count: number}>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        return await getAutomationHistory({workspaceId: workspaceId || '', id: data.id, data: {page: data.page, count: data.count}}, signal);
    }
);

//activateAutomation

export const ActivateAutomation = createAsyncThunk(
    'Automations/ActivateAutomation',
    async (id: string, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const mes = await activateAutomation({workspaceId: workspaceId, data: id});
        dispatch(addSuccessfulSnack(mes));
        return id;
    }
);

export const DeactivateAutomation = createAsyncThunk(
    'Automations/DeactivateAutomation',
    async (id: string, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const mes = await deactivateAutomation({workspaceId: workspaceId, data: id});
        dispatch(addSuccessfulSnack(mes));
        return id;
    }
);

//getAutomationEvidences

export const GetAutomationEvidences = createAsyncThunk(
    'Automations/getAutomationEvidences',
    async (_, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getAutomationEvidences({workspaceId});
    }
);
//getEmployeesNameEmail
export const GetEmployeesNameEmail = createAsyncThunk(
    'Automations/getEmployeesNameEmail',
    async (_, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getShortEmployees({workspaceId});
    }
);