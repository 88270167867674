import {
    KYC_ARCHIVED_TARGET_DELETED_payload,
    KYC_EXCEPTION_DELETED_payload,
    KYC_SCREENING_STATUS_CHANGED_payload,
    kycInitialState,
    kycIsDataOutdated,
    TOneTimeScreeningResp
} from "../types";
import {TPageInfo} from "../../../../newShared/types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {
    AddKycTargetToBlackList,
    CancelRequest,
    CreateKycTemplate,
    CreateTarget,
    CreateTargetException,
    DeleteKycArchivedTarget,
    DeleteKycTemplate,
    DeleteTargetException,
    ExportClientsByTemplate,
    ExportCsvTargetsWithFilters,
    GetDeleteTemplateData,
    GetKycArchiveWithFilterAndPaging,
    GetKycAvailableFieldsForExport,
    GetKycAvailableSystemFieldsForExport,
    GetKycClientsFields,
    GetKycClientsWithFilterAndPaging,
    GetKycDictionaries,
    GetKycExceptionsByTargetId,
    GetKycMatches,
    GetKycOtsFullResultByEntityId,
    GetKycRequestById,
    GetKycRequests,
    GetKycScreeningById,
    GetKycScreeningFullInfoById,
    GetKycScreeningFullResultByEntityId,
    GetKycScreeningResults,
    GetKycScreeningsWithFilterAndPaging,
    GetKycTargetById,
    GetKycTemplateById,
    GetKycTemplateConfig,
    GetKycTemplateHistory,
    GetKycTemplates,
    GetTargetHistory,
    MoveKycTargetToArchive,
    PerformCsvByFileId,
    RestoreKycTargetFromArchive,
    RestoreKycTargetFromBlackList,
    SendTemplateToClient,
    SetKycTargetVerified,
    StartOneTimeScreening,
    StartTargetScreening,
    UpdateKycClientsFields,
    UpdateKycTargetRisk,
    UpdateKycTemplate,
    UploadKycCsvFile
} from "./actions";
import {setPaginationInStore} from "../../../../newShared/hooks/useScroll/helpers";
import {
    KycArchivedTargetModel,
    KycExceptionModel,
    KycMatchModel,
    KycRefactorRequestModel,
    KycRefactorTemplateModel,
    KycScreeningFullInfoModel,
    KycScreeningModel,
    KycScreeningResultModel,
    KycTargetFieldModel,
    KycTemplateCsvHistoryModel,
    TKycTargetModel,
} from "../../../../newShared/GQLTypes";
import {
    BLACK_LIST_STATUS,
    MATCHED_STATUS,
    TARGET_BLACKLIST_STATUS,
    TARGET_CREATED_STATUS,
    VERIFIED_STATUS
} from "../constants";
import {minMaxLoadedPageDefault} from "../../../../newShared/components/genericTable/constants";
import {minMaxLoadedPageType} from "../../../../newShared/components/genericTable/types";
import {handlePagination} from "../../../../newShared/components/genericTable/helpers";

const defaultPageInfo: TPageInfo = {
    page: 0,
    count: 10,
    total: 0,
    sort: ''
}


export const initialState: kycInitialState = {
    templates: [],
    templatesPageInfo: defaultPageInfo,
    selectedTemplate: null,
    selectedTemplateCsvHistory: [],
    selectedTemplateCsvHistoryPageInfo: defaultPageInfo,
    templateConfig: {
        systemChecks: [],
        systemFields: []
    },
    clients: {
        clients: [],
        pageInfo: defaultPageInfo,
        minMaxLoadedPage: minMaxLoadedPageDefault,
        selected: null,
        fields: [],
        clientPage: null,

        selectedClintExceptions: [],
        clientExceptionsPageInfo: defaultPageInfo,
        selectedClintExceptionsMinMaxPage: minMaxLoadedPageDefault,
        selectedClientHistory: []
    },
    archive: {
        clients: [],
        pageInfo: defaultPageInfo,
        minMaxLoadedPage: minMaxLoadedPageDefault,
    },

    matches: {
        list: [],
        pageInfo: defaultPageInfo,
        minMaxLoadedPage: minMaxLoadedPageDefault,
    },

    screenings: {
        screenings: [],
        pageInfo: defaultPageInfo,
        selected: null,
        selectedScreeningResults: [],
        selectedScreeningResultsMinMaxLoadedPage: minMaxLoadedPageDefault,
        selectedScreeningResultsPageInfo: defaultPageInfo,
        selectedScreeningSelectedResult: null,
        minMaxLoadedPage: minMaxLoadedPageDefault,
    },

    requests: [],
    requestsPageInfo: defaultPageInfo,
    requestMinMaxPage: minMaxLoadedPageDefault,
    selectedRequest: null,

    loadings: {
        getDictionaries: false,
        getMatchesList: false,
        getTemplatesList: false,
        getKycTemplateConfig: true,
        createTemplate: false,
        getTemplateExact: false,
        updateTemplate: false,
        getTemplateHistory: false,

        isLoadingUploadCsv: false,
        isLoadingPerformCsv: false,

        deleteTemplate: false,
        getDeleteTemplateData: false,
        downloadCsvInDeleteTemplateDialog: false,

        getScreeningResults: false,
        getScreeningResultExact: false,

        updateTargetValues: false,
        updateTargetRisk: false,
        createTarget: false,
        sendToClient: false,
        getExactTarget: false,
        getExactTargetExceptions: false,
        getExactTargetHistory: false,
        startTargetScreening: false,
        moveToArchive: false,
        moveFromArchive: false,
        moveToBlacklist: false,
        moveFromBlacklist: false,
        setClientVerified: false,
        deleteTarget: false,
        exportCsv: false,

        getRequestsList: false,
        getRequestExact: false,
        cancelRequest: false,
        isLoadingList: false,
        isLoadingExact: false,
        isLoadingFields: false,
        createException: false,
        deleteTargetException: false
    },

    dialogs: {
        templatesListImportCsv: {
            isOpen: false,
            template: null,
            uploadCsvResponse: null,
        },
        templatePreview: {
            isOpen: false,
            template: null
        },
        deleteTemplate: {
            isOpen: false,
            template: null,
            archivedClientsCount: 0,
            clientsCount: 0,
            // uploadCsvResponse: null,
            // isCsvDownloaded: false
        },
        templateSendToClient: {
            isOpen: false,
            template: null
        },
        templateInputClientData: {
            isOpen: false,
            template: null
        },
        oneTimeScreening: {
            isOpen: false,
            template: null,
            response: null,
        },
        requestNotCompleted: {
            isOpen: false,
            request: null,
        },
        cancelRequest: {
            isOpen: false,
            request: null
        },
        createClient: {
            isOpen: false,
            selectedTemplate: null
        },

        editClientInfo: {
            isOpen: false,
            selectedClient: null
        },
        addToBlackList: {
            isOpen: false,
            clientId: null,
            clientName: null,
        },
        archiveClient: {
            isOpen: false,
            selectedClient: null,
            isFromTargetListPage: false
        },
        removeFromBlackList: {
            isOpen: false,
            selectedClient: null
        },
        restoreClientFromArchive: {
            isOpen: false,
            selectedClient: null
        },
        deleteArchivedTarget: {
            isOpen: false,
            target: null,
        },
        createException: {
            isOpen: false,
            entityId: null,
            entityCaption: null,
        },

        deleteTargetException: {
            isOpen: false,
            exceptionId: null,
            targetId: null,
        },

        showFullReportDialog: {
            isOpen: false,
            entityId: null,
            otsResponse: null,
        },

        showClientDetail: {
            isOpen: false,
            fields: [],
            isOTS: false,
        },
        exportCsv: {
            isOpen: false,
            fields: [],
            template: null,
        }
    },

    isDataOutdated: {
        exactTargetScreeningTab: false,
        exactTargetExceptionsTab: false,
        exactScreeningPage: false,
        exactTargetEditMode: false,
        matchesList: false,
        clientList: false,
        archiveList: false,
        screeningList: false,
        requestsList: false,
    },

    dictionaries: {
        country: []
    }
}

export const KycRefactorSlice = createSlice({
    name: 'KycRefactor',
    initialState,
    reducers: {
        //selectedClintExceptionsMinMaxPage
        eraseScreeningEntities: (slice) => {
            slice.screenings.selectedScreeningResults = [];
            slice.screenings.selectedScreeningResultsPageInfo = initialState.screenings.selectedScreeningResultsPageInfo;
        },
        replaceScreeningEntities: (slice, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.screenings.selectedScreeningResultsMinMaxLoadedPage = payload;
        },
        replaceSelectedClintExceptionsMinMaxPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.clients.selectedClintExceptionsMinMaxPage = payload;
        },
        replaceArchiveMinMaxLoadedPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.archive.minMaxLoadedPage = payload;
        },
        replaceScreeningsMinMaxLoadedPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.screenings.minMaxLoadedPage = payload;
        },
        replaceMatchesMinMaxLoadedPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.matches.minMaxLoadedPage = payload;
        },
        replaceTargetsMinMaxLoadedPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.clients.minMaxLoadedPage = payload;
        },
        replaceRequestsMinMaxLoadedPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.requestMinMaxPage = payload;
        },
        setIsDataOutdated: (slice, {payload}: { payload: kycIsDataOutdated }) => {
            slice.isDataOutdated = payload;
        },
        eraseIsDataOutdated: (slice) => {slice.isDataOutdated = initialState.isDataOutdated},
        eraseTemplates: (slice) => {slice.templates = []; slice.templatesPageInfo = defaultPageInfo;},
        eraseRequests: (slice) => {slice.requests = []; slice.requestsPageInfo = defaultPageInfo;},
        eraseScreenings: (slice) => {slice.screenings = {...slice.screenings, screenings: [], pageInfo: defaultPageInfo}},

        eraseMatches: (slice) => {slice.matches.list = []; slice.matches.pageInfo = defaultPageInfo;},

        setSelectedTemplate: (slice, {payload}:{payload: KycRefactorTemplateModel}) => {slice.selectedTemplate = payload},
        deselectTemplate: (slice) => {
            slice.selectedTemplate = null;
            slice.selectedTemplateCsvHistoryPageInfo = defaultPageInfo;
            slice.selectedTemplateCsvHistory = [];
        },

        setSelectedRequest: (slice, {payload}:{payload: KycRefactorRequestModel}) => {slice.selectedRequest = payload},
        deselectRequest: (slice) => {slice.selectedRequest = null;},

        setSelectedTarget: (slice, {payload}:{payload: TKycTargetModel}) => {slice.clients.selected = payload},
        deselectTarget: (slice) => {
            slice.clients = {
                ...slice.clients,
                selected: null,
                selectedClintExceptions: [],
                clientExceptionsPageInfo: defaultPageInfo,
                selectedClientHistory: []
            };
        },
        setSelectedScreening: (slice, {payload}:{payload: KycScreeningFullInfoModel}) => {slice.screenings.selected = payload},
        deselectScreening: (slice) => {
            slice.screenings = {...slice.screenings,
                selected: null,
                selectedScreeningResults: [],
                selectedScreeningResultsPageInfo: defaultPageInfo,
                selectedScreeningSelectedResult: null,
            };
        },


        openTemplatesImportCsv: (slice, {payload}:{payload: KycRefactorTemplateModel}) => {
            slice.dialogs.templatesListImportCsv = {isOpen: true, template: payload, uploadCsvResponse: null}
        },
        hideTemplatesImportCsv: (slice) => {
            slice.dialogs.templatesListImportCsv = {isOpen: false, template: null, uploadCsvResponse: null}
        },

        openPreviewTemplate: (slice, {payload}:{payload: KycRefactorTemplateModel}) => {
            slice.dialogs.templatePreview = {isOpen: true, template: payload}
        },
        hidePreviewTemplate: (slice) => {
            slice.dialogs.templatePreview = {isOpen: false, template: null}
        },

        openDeleteTemplate: (slice, {payload}:{payload: KycRefactorTemplateModel}) => {
            slice.dialogs.deleteTemplate = {isOpen: true,
                template: payload,
                archivedClientsCount: 0,
                // isCsvDownloaded: false,
                clientsCount: 0,
                // uploadCsvResponse: null
            }
        },
        hideDeleteTemplate: (slice) => {
            slice.dialogs.deleteTemplate = initialState.dialogs.deleteTemplate;
        },

        openTemplateInputClientData: (slice, {payload}:{payload: KycRefactorTemplateModel}) => {
            slice.dialogs.templateInputClientData = {isOpen: true, template: payload}
        },
        hideTemplateInputClientData: (slice) => {
            slice.dialogs.templateInputClientData = {isOpen: false, template: null}
        },


        eraseOneTimeScreeningResponse: (slice) => {
            slice.dialogs.oneTimeScreening.response = null
        },
        openOneTimeScreening: (slice, {payload}:{payload: KycRefactorTemplateModel}) => {
            slice.dialogs.oneTimeScreening.isOpen = true;
            slice.dialogs.oneTimeScreening.template = payload;
        },
        hideOneTimeScreening: (slice) => {
            slice.dialogs.oneTimeScreening = initialState.dialogs.oneTimeScreening;
        },

        openTemplateSendToClient: (slice, {payload}:{payload: KycRefactorTemplateModel}) => {
            slice.dialogs.templateSendToClient = {isOpen: true, template: payload}
        },
        hideTemplateSendToClient: (slice) => {
            slice.dialogs.templateSendToClient = {isOpen: false, template: null}
        },

        openRequestNotCompleted: (slice, {payload}:{payload: KycRefactorRequestModel}) => {
            slice.dialogs.requestNotCompleted = {isOpen: true, request: payload}
        },
        hideRequestNotCompleted: (slice) => {
            slice.dialogs.requestNotCompleted = {isOpen: false, request: null}
        },

        openCancelRequest: (slice, {payload}:{payload: KycRefactorRequestModel}) => {
            slice.dialogs.cancelRequest = {isOpen: true, request: payload}
        },
        hideCancelRequest: (slice) => {
            slice.dialogs.cancelRequest = {isOpen: false, request: null}
        },

        //Clients
        eraseArchive: (slice) => {
            slice.archive = initialState.archive;
        },
        selectClient: (slice, {payload}: {payload: TKycTargetModel}) => {
            slice.clients.selected = payload;
        },
        deselectClient: (slice) => {
            slice.clients.selected = null;
        },
        // openAddGroupMessage: (slice, {payload}: {payload: {approverId?: string, groupId?: string, oldMessage?: string, recipientId?: string}}) => {
        openCreateClientDialog: (slice, {payload}:{payload: {isOpen?: boolean, selectedTemplate?: KycRefactorTemplateModel}}) => {
            if(payload?.isOpen !== undefined) slice.dialogs.createClient.isOpen = payload.isOpen;
            if(payload?.selectedTemplate !== undefined) slice.dialogs.createClient.selectedTemplate = payload.selectedTemplate;
        },
        hideCreateClientDialog: (slice) => {
            slice.dialogs.createClient = initialState.dialogs.createClient;
        },

        openEditClientDialog: (slice, {payload}:{payload: {selectedClient: TKycTargetModel}}) => {
            slice.dialogs.editClientInfo = {
                isOpen: true,
                selectedClient: payload.selectedClient
            }
        },
        hideEditClientDialog: (slice) => {
            slice.dialogs.editClientInfo = {
                isOpen: false,
                selectedClient: null
            }
        },

        openEditClientInfoDialog: (slice, {payload}:{payload: {selectedClient: TKycTargetModel}}) => {
            slice.dialogs.editClientInfo.isOpen = true;
            if(payload.selectedClient !== undefined) slice.dialogs.editClientInfo.selectedClient = payload.selectedClient;
        },
        hideEditClientInfoDialog: (slice) => {
            slice.dialogs.editClientInfo = initialState.dialogs.editClientInfo;
        },
        openAddToBlackListDialog: (slice, {payload}:{payload: {clientId: string, clientName: string}}) => {
            slice.dialogs.addToBlackList.isOpen = true;
            if(payload.clientId !== undefined) slice.dialogs.addToBlackList.clientId = payload.clientId;
            if(payload.clientName !== undefined) slice.dialogs.addToBlackList.clientName = payload.clientName;
        },
        hideAddToBlackListDialog: (slice) => {
            slice.dialogs.addToBlackList = initialState.dialogs.addToBlackList;
        },
        openArchiveClientDialog: (slice, {payload}:{payload: {selectedClient: TKycTargetModel, isFromTargetListPage?: boolean}}) => {
            slice.dialogs.archiveClient.isOpen = true;
            if(payload.selectedClient !== undefined) slice.dialogs.archiveClient.selectedClient = payload.selectedClient;
            if(payload.isFromTargetListPage !== undefined) slice.dialogs.archiveClient.isFromTargetListPage = payload.isFromTargetListPage;
        },
        hideArchiveClientDialog: (slice) => {
            slice.dialogs.archiveClient = initialState.dialogs.archiveClient;
        },
        openRemoveFromBlackListDialog: (slice, {payload}:{payload: {selectedClient: TKycTargetModel}}) => {
            slice.dialogs.removeFromBlackList.isOpen = true;
            if(payload.selectedClient !== undefined) slice.dialogs.removeFromBlackList.selectedClient = payload.selectedClient;
        },
        hideRemoveFromBlackListDialog: (slice) => {
            slice.dialogs.removeFromBlackList = initialState.dialogs.removeFromBlackList;
        },
        openRestoreClientFromArchiveDialog: (slice, {payload}:{payload: {selectedClient: KycArchivedTargetModel}}) => {
            slice.dialogs.restoreClientFromArchive.isOpen = true;
            if(payload.selectedClient !== undefined) slice.dialogs.restoreClientFromArchive.selectedClient = payload.selectedClient;
        },
        hideRestoreClientFromArchiveDialog: (slice) => {
            slice.dialogs.restoreClientFromArchive = initialState.dialogs.restoreClientFromArchive;
        },
        openDeleteArchivedTarget: (slice, {payload}:{payload: {target: KycArchivedTargetModel}}) => {
            slice.dialogs.deleteArchivedTarget = {isOpen: true, target: payload.target}
        },
        hideDeleteArchivedTarget: (slice) => {
            slice.dialogs.deleteArchivedTarget = initialState.dialogs.deleteArchivedTarget;
        },
        openExportCsv: (slice, {payload}:{payload: {template?: KycRefactorTemplateModel}}) => {
            slice.dialogs.exportCsv.isOpen = true;
            if (payload.template !== undefined) slice.dialogs.exportCsv.template = payload.template;
        },
        hideExportCsv: (slice) => {
            slice.dialogs.exportCsv = initialState.dialogs.exportCsv;
        },

        eraseClientsList: (slice) => {
            slice.clients.clients = [];
            slice.clients.pageInfo = defaultPageInfo;
        },
        eraseClientsListFields: (slice) => {
            slice.clients.fields = [];
        },
        eraseExactClientExceptionsList: (slice) => {
            slice.clients.selectedClintExceptions = [];
            slice.clients.clientExceptionsPageInfo = defaultPageInfo;
        },
        openCreateException: (slice, {payload}:{payload: {entityId: string, entityCaption: string}}) => {
            slice.dialogs.createException = {isOpen: true, entityId: payload.entityId, entityCaption: payload.entityCaption}
        },
        hideCreateException: (slice) => {
            slice.dialogs.createException = initialState.dialogs.createException;
        },

        openShowClientDetail: (slice, {payload}:{payload: {fields: KycTargetFieldModel[], isOTS?: boolean }}) => {
            slice.dialogs.showClientDetail.isOpen = true;
            // if(payload.target !== undefined) slice.dialogs.showClientDetail.target = payload.target;
            if(payload.fields !== undefined) slice.dialogs.showClientDetail.fields = payload.fields;
            if(payload.isOTS !== undefined) slice.dialogs.showClientDetail.isOTS = payload.isOTS;
        },
        hideShowClientDetail: (slice) => {
            slice.dialogs.showClientDetail = initialState.dialogs.showClientDetail;
        },

        openDeleteTargetException: (slice, {payload}:{payload: {targetId: string, exceptionId: string}}) => {
            slice.dialogs.deleteTargetException = {isOpen: true, exceptionId: payload.exceptionId, targetId: payload.targetId}
        },
        hideDeleteTargetException: (slice) => {
            slice.dialogs.deleteTargetException = initialState.dialogs.deleteTargetException;
        },

        openShowFullReport: (slice,{payload}: {payload: {entityId: string, otsResponse?: TOneTimeScreeningResp}}) => {
            slice.dialogs.showFullReportDialog.isOpen = true;
            if(payload.entityId !== undefined) slice.dialogs.showFullReportDialog.entityId = payload.entityId;
            if(payload.otsResponse !== undefined) slice.dialogs.showFullReportDialog.otsResponse = payload.otsResponse;
        },
        hideShowFullReport: (slice) => {
            slice.dialogs.showFullReportDialog = initialState.dialogs.showFullReportDialog;
            slice.screenings.selectedScreeningSelectedResult = null;
        },
        // cleanUploadedFile: (slice) => {
        //     slice.dialogs.templatesListImportCsv = {...slice.dialogs.templatesListImportCsv, uploadCsvResponse: null};
        // },

        cleanUp: () => initialState,
        cleanArchive: (slice) => { slice.archive = initialState.archive; },

        KYC_SCREENING_STATUS_CHANGED_action: (slice, {payload}: {payload: KYC_SCREENING_STATUS_CHANGED_payload}) => {
            slice.screenings.screenings = slice.screenings.screenings.map(e => e.id === payload.screeningId ? {...e, status: payload.status} : e);
            if(slice.screenings.selected && slice.screenings.selected.screening.id === payload.screeningId){
                slice.screenings.selected.screening.status = payload.status;
            }
        },
        KYC_SCREENING_CREATED_action: (slice, {payload}: {payload: KycScreeningModel}) => {
            slice.isDataOutdated = {
                ...slice.isDataOutdated,
                screeningList: true,
                exactTargetScreeningTab: true,
            }
            if (slice.screenings.selected && slice.screenings.selected.target.id === payload.targetId) {
                slice.isDataOutdated = {
                    ...slice.isDataOutdated,
                    exactScreeningPage: true,
                }
                slice.screenings.selected.screening.latestScreeningId = payload.id;
            }
        },
        KYC_MATCH_CREATED_action: (slice, {payload}: {payload: KycMatchModel}) => {
            if(slice.clients.selected && slice.clients.selected.id === payload.targetId){
                slice.clients.selected.status = MATCHED_STATUS;
            }
            if(slice.screenings.selected && slice.screenings.selected.target.id === payload.targetId){
                slice.screenings.selected.target.status = MATCHED_STATUS;
            }
            slice.clients.clients = slice.clients.clients.map(e => e.id === payload.targetId ? {...e, status: MATCHED_STATUS} : e);

            slice.isDataOutdated = {
                ...slice.isDataOutdated,
                matchesList: true
            }
        },
        KYC_CLIENT_ARCHIVED_action: (slice, {payload}: {payload: TKycTargetModel}) => {
            //in useWorkspaceSubscription checking if this was selected target - and pushing to clients list
            if(slice.clients.selected){
                slice.clients.selected = null;
                slice.clients.selectedClientHistory = [];
                slice.clients.selectedClintExceptions = [];
                slice.clients.clientExceptionsPageInfo = defaultPageInfo;
                slice.dialogs = initialState.dialogs; //closing all dialogs
            }
            slice.isDataOutdated = {
                ...slice.isDataOutdated,
                clientList: true,
                archiveList: true,
            };

            if(slice.screenings.selected && slice.screenings.selected.target.id === payload.id){
                slice.screenings.selected.target.archived = true;
            }
        },
        KYC_CLIENT_UNARCHIVED_action: (slice, {payload}: {payload: TKycTargetModel}) => {
            slice.isDataOutdated = {
                ...slice.isDataOutdated,
                clientList: true,
                archiveList: true,
            };

            if(slice.screenings.selected && slice.screenings.selected.target.id === payload.id){
                slice.screenings.selected.target.archived = false;
            }
        },
        KYC_CLIENT_ADDED_action: (slice, {payload}: {payload: TKycTargetModel}) => {
            slice.isDataOutdated = {
                ...slice.isDataOutdated,
                clientList: true,
            };
        },
        KYC_TARGET_STATUS_CHANGED_action: (slice, {payload}: {payload: TKycTargetModel}) => {
            slice.clients.clients = slice.clients.clients.map(e => e.id === payload.id ? {...e, status: payload.status} : e);
            if(slice.clients.selected && slice.clients.selected.id === payload.id){
                slice.clients.selected.status = payload.status;
            }
            if(slice.screenings.selected && slice.screenings.selected.target.id === payload.id){
                slice.screenings.selected.target.status = payload.status;
            }
        },

        KYC_REQUEST_STATUS_CHANGED_action: (slice, {payload}: {payload: KycRefactorRequestModel}) => {
            slice.requests = slice.requests = slice.requests.map(e => e.id === payload.id ? payload : e);
            if(slice.selectedRequest){
                slice.selectedRequest = payload;
            }
        },
        KYC_REQUEST_ADDED_action: (slice, {payload}: {payload: KycRefactorRequestModel}) => {
            slice.isDataOutdated = {
                ...slice.isDataOutdated,
                requestsList: true
            };
        },

        KYC_EXCEPTION_ADDED_action: (slice, {payload}: {payload: KycExceptionModel}) => {
            if(slice.screenings.selected && slice.screenings.selected.target.id === payload.targetId && slice.screenings.selected.exceptions){
                slice.screenings.selected.exceptions.push(payload);
                slice.screenings.selectedScreeningResults = slice.screenings.selectedScreeningResults.map(e => e.entityId === payload.entityId ? {...e, exceptionId: payload.id} : e);
            }
            slice.isDataOutdated = {
                ...slice.isDataOutdated,
                exactTargetExceptionsTab: true
            };
        },
        KYC_EXCEPTION_DELETED_action: (slice, {payload}: {payload: KYC_EXCEPTION_DELETED_payload}) => {
            if(slice.screenings.selected && slice.screenings.selected.exceptions){
                const currException = slice.screenings.selected.exceptions.find(e => e.id === payload.exceptionId);
                slice.screenings.selected.exceptions = slice.screenings.selected.exceptions.filter(e => e.id !== payload.exceptionId);
                if(currException){
                    slice.screenings.selectedScreeningResults = slice.screenings.selectedScreeningResults.map(e => e.entityId === currException.entityId ? {...e, exceptionId: null} : e);
                }
            }
            slice.isDataOutdated = {
                ...slice.isDataOutdated,
                exactTargetExceptionsTab: true
            };
        },
        KYC_ARCHIVED_TARGET_DELETED_action: (slice, {payload}: {payload: KYC_ARCHIVED_TARGET_DELETED_payload}) => {
            slice.isDataOutdated = {
                ...slice.isDataOutdated,
                archiveList: true,
            }
        },
        //KYC_TARGET_UPDATED
        KYC_TARGET_UPDATED_action: (slice, {payload}: {payload: TKycTargetModel}) => {

            if (slice.dialogs.editClientInfo?.isOpen && slice.dialogs.editClientInfo?.selectedClient?.id === payload.id){
                slice.isDataOutdated = {
                    ...slice.isDataOutdated,
                    exactTargetEditMode: true,
                    clientList: true,
                }
            } else if (slice.clients.selected?.id === payload.id){
                slice.clients.selected = payload;
            } else  if (slice.clients.clients){
                slice.clients.clients = slice.clients.clients.map(e => e.id === payload.id ? payload : e);
            } else if (slice.screenings.selected?.target.id === payload.id){
                slice.screenings.selected.target = payload;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetKycTemplates.pending, (slice) => {
                slice.loadings.getTemplatesList = true;
            })
            .addCase(GetKycTemplates.rejected, (slice) => {
                slice.loadings.getTemplatesList = false;

            })
            .addCase(GetKycTemplates.fulfilled, (slice, {payload}) => {
                slice.loadings.getTemplatesList = false;
                const {array, pageInfo} = setPaginationInStore<KycRefactorTemplateModel>(
                    slice.templatesPageInfo,
                    slice.templates,
                    payload.pageInfo,
                    payload.templates,
                    false
                );
                slice.templates = array.filter(e => e !== null) as KycRefactorTemplateModel[];
                slice.templatesPageInfo = pageInfo;
            })
        //GetKycTemplateConfig
            .addCase(GetKycTemplateConfig.pending, (slice) => {
                slice.loadings.getKycTemplateConfig = true;
            })
            .addCase(GetKycTemplateConfig.rejected, (slice) => {
                slice.loadings.getKycTemplateConfig = false;

            })
            .addCase(GetKycTemplateConfig.fulfilled, (slice, {payload}) => {
                slice.loadings.getKycTemplateConfig = false;
                slice.templateConfig = payload;
            })
        //CreateKycTemplate
            .addCase(CreateKycTemplate.pending, (slice) => {
                slice.loadings.createTemplate = true;
            })
            .addCase(CreateKycTemplate.rejected, (slice) => {
                slice.loadings.createTemplate = false;

            })
            .addCase(CreateKycTemplate.fulfilled, (slice, {payload}) => {
                slice.loadings.createTemplate = false;
                slice.templatesPageInfo.total = slice.templatesPageInfo.total + 1;
                slice.templates.unshift(payload);
            })
        //CreateTarget
            .addCase(CreateTarget.pending, (slice) => {
                slice.loadings.createTarget = true;
            })
            .addCase(CreateTarget.rejected, (slice) => {
                slice.loadings.createTarget = false;

            })
            .addCase(CreateTarget.fulfilled, (slice, {payload}) => {
                slice.loadings.createTarget = false;
            })
        //SendTemplateToClient
            .addCase(SendTemplateToClient.pending, (slice) => {
                slice.loadings.sendToClient = true;
            })
            .addCase(SendTemplateToClient.rejected, (slice) => {
                slice.loadings.sendToClient = false;

            })
            .addCase(SendTemplateToClient.fulfilled, (slice, {payload}) => {
                slice.loadings.sendToClient = false;
                slice.dialogs.templateSendToClient = {isOpen: false, template: null};
            })
        //GetKycRequests
            .addCase(GetKycRequests.pending, (slice) => {
                slice.loadings.getRequestsList = true;
            })
            .addCase(GetKycRequests.rejected, (slice) => {
                slice.loadings.getRequestsList = false;

            })
            .addCase(GetKycRequests.fulfilled, (slice, {payload}) => {
                slice.loadings.getRequestsList = false;
                const {results, maxLoadedPage, minLoadedPage} = handlePagination<KycRefactorRequestModel>(
                    slice.requests,
                    slice.requestsPageInfo,
                    payload.requests,
                    payload.pageInfo,
                    slice.requestMinMaxPage.minLoadedPage,
                    slice.requestMinMaxPage.maxLoadedPage,
                    'id'
                );

                slice.requests = results;
                slice.requestMinMaxPage = {minLoadedPage, maxLoadedPage};
                slice.requestsPageInfo = payload.pageInfo

                // const {array, pageInfo} = setPaginationInStore<KycRefactorRequestModel>(
                //     slice.requestsPageInfo,
                //     slice.requests,
                //     payload.pageInfo,
                //     payload.requests,
                //     false
                // );
                // slice.requests = array.filter(e => e !== null) as KycRefactorRequestModel[];
                // slice.requestsPageInfo = pageInfo;
            })

        //GetKycRequestById
            .addCase(GetKycRequestById.pending, (slice) => {
                slice.loadings.getRequestExact = true;
            })
            .addCase(GetKycRequestById.rejected, (slice) => {
                slice.loadings.getRequestExact = false;

            })
            .addCase(GetKycRequestById.fulfilled, (slice, {payload}) => {
                slice.loadings.getRequestExact = false;
                slice.selectedRequest = payload;
            })
        //GetKycTemplateById
            .addCase(GetKycTemplateById.pending, (slice) => {
                slice.loadings.getTemplateExact = true;
            })
            .addCase(GetKycTemplateById.rejected, (slice) => {
                slice.loadings.getTemplateExact = false;

            })
            .addCase(GetKycTemplateById.fulfilled, (slice, {payload}) => {
                slice.loadings.getTemplateExact = false;
                slice.selectedTemplate = payload;
            })
        //UpdateKycTemplate
            .addCase(UpdateKycTemplate.pending, (slice) => {
                slice.loadings.updateTemplate = true;
            })
            .addCase(UpdateKycTemplate.rejected, (slice) => {
                slice.loadings.updateTemplate = false;

            })
            .addCase(UpdateKycTemplate.fulfilled, (slice, {payload}) => {
                slice.loadings.updateTemplate = false;
                slice.selectedTemplate = payload;
                slice.templates = slice.templates.map(e => e.id === payload.id ? payload : e);
            })
        //CancelRequest
            .addCase(CancelRequest.pending, (slice) => {
                slice.loadings.cancelRequest = true;
            })
            .addCase(CancelRequest.rejected, (slice) => {
                slice.loadings.cancelRequest = false;

            })
            .addCase(CancelRequest.fulfilled, (slice, {payload}) => {
                slice.loadings.cancelRequest = false;
                slice.requests = slice.requests.map(e => e.id === payload ? ({...e, status: 'REJECTED'}) : e);
                slice.requestsPageInfo.total = slice.requestsPageInfo.total - 1;
                slice.dialogs.cancelRequest = {isOpen: false, request: null};
            })

        //Clients
            .addCase(GetKycClientsWithFilterAndPaging.pending, (slice) => {
                slice.loadings.isLoadingList = true;
            })
            .addCase(GetKycClientsWithFilterAndPaging.rejected, (slice) => {
                slice.loadings.isLoadingList = false;

            })
            .addCase(GetKycClientsWithFilterAndPaging.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingList = false;
                // const {array, pageInfo} = setPaginationInStore<TKycTargetModel>(
                //     slice.clients.pageInfo,
                //     slice.clients.clients,
                //     payload.pageInfo,
                //     payload.clients,
                //     false
                // );
                // slice.clients.clients = array.filter(e => e !== null) as TKycTargetModel[];
                // slice.clients.pageInfo = pageInfo;
                const {results, maxLoadedPage, minLoadedPage} = handlePagination<TKycTargetModel>(
                    slice.clients.clients,
                    slice.clients.pageInfo,
                    payload.clients,
                    payload.pageInfo,
                    slice.clients.minMaxLoadedPage.minLoadedPage,
                    slice.clients.minMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.clients.clients = results;
                slice.clients.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.clients.pageInfo = payload.pageInfo;
            })

            //archive
            .addCase(GetKycArchiveWithFilterAndPaging.pending, (slice) => {
                slice.loadings.isLoadingList = true;
            })
            .addCase(GetKycArchiveWithFilterAndPaging.rejected, (slice) => {
                slice.loadings.isLoadingList = false;

            })
            .addCase(GetKycArchiveWithFilterAndPaging.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingList = false;
                // const {array, pageInfo} = setPaginationInStore<KycArchivedTargetModel>(
                //     slice.archive.pageInfo,
                //     slice.archive.clients,
                //     payload.pageInfo,
                //     payload.archive,
                //     false
                // );
                // slice.archive.clients = array.filter(e => e !== null) as KycArchivedTargetModel[];
                // slice.archive.pageInfo = pageInfo;

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<KycArchivedTargetModel>(
                    slice.archive.clients,
                    slice.archive.pageInfo,
                    payload.archive,
                    payload.pageInfo,
                    slice.archive.minMaxLoadedPage.minLoadedPage,
                    slice.archive.minMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.archive.clients = results;
                slice.archive.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.archive.pageInfo = payload.pageInfo;
            })

            .addCase(GetKycClientsFields.pending, (slice) => {
                slice.loadings.isLoadingFields = true;
            })
            .addCase(GetKycClientsFields.rejected, (slice) => {
                slice.loadings.isLoadingFields = false;

            })
            .addCase(GetKycClientsFields.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingFields = false;
                slice.clients.fields = payload.fields;
                slice.clients.clientPage = payload.clientType;
            })

        //AvailableFieldsForExport
            .addCase(GetKycAvailableFieldsForExport.pending, (slice) => {
                slice.loadings.isLoadingFields = true;
            })
            .addCase(GetKycAvailableFieldsForExport.rejected, (slice) => {
                slice.loadings.isLoadingFields = false;

            })
            .addCase(GetKycAvailableFieldsForExport.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingFields = false;
                slice.dialogs.exportCsv.fields = payload.fields;
            })

        //AvailableSystemFieldsForExport
            .addCase(GetKycAvailableSystemFieldsForExport.pending, (slice) => {
                slice.loadings.isLoadingFields = true;
            })
            .addCase(GetKycAvailableSystemFieldsForExport.rejected, (slice) => {
                slice.loadings.isLoadingFields = false;

            })
            .addCase(GetKycAvailableSystemFieldsForExport.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingFields = false;
                slice.dialogs.exportCsv.fields = payload.fields;
            })

        //UpdateKycClientsFields
            .addCase(UpdateKycClientsFields.pending, (slice) => {
                slice.loadings.updateTargetValues = true;
            })
            .addCase(UpdateKycClientsFields.rejected, (slice) => {
                slice.loadings.updateTargetValues = false;

            })
            .addCase(UpdateKycClientsFields.fulfilled, (slice, {payload}) => {
                slice.loadings.updateTargetValues = false;

                slice.dialogs.editClientInfo = {isOpen: false, selectedClient: null};
                if(slice.clients.selected){
                    slice.clients.selected.fields = payload.data.fields;
                }
                slice.clients.clients = slice.clients.clients.map(e => e.id === payload.data.targetId ? {...e, fields: payload.data.fields}: e);
            })

            //UpdateKycTargetRisk
            .addCase(UpdateKycTargetRisk.pending, (slice) => {
                slice.loadings.updateTargetRisk = true;
            })
            .addCase(UpdateKycTargetRisk.rejected, (slice) => {
                slice.loadings.updateTargetRisk = false;

            })
            .addCase(UpdateKycTargetRisk.fulfilled, (slice, {payload}) => {
                slice.loadings.updateTargetRisk = false;

                if(slice.clients.selected){
                    slice.clients.selected.risk = payload.data.risk;
                }
            })

            .addCase(MoveKycTargetToArchive.pending, (slice) => {
                slice.loadings.moveToArchive = true;
            })
            .addCase(MoveKycTargetToArchive.rejected, (slice) => {
                slice.loadings.moveToArchive = false;

            })
            .addCase(MoveKycTargetToArchive.fulfilled, (slice, {payload}) => {
                slice.loadings.moveToArchive = false;
                slice.dialogs.archiveClient = initialState.dialogs.archiveClient;
                slice.clients.clients = slice.clients.clients.filter(e => e.id !== payload.targetId);
                slice.clients.pageInfo.total = slice.clients.pageInfo.total - 1;
                if(slice.clients.selected){
                    slice.clients.selected = null;
                    slice.clients.selectedClintExceptions = [];
                    slice.clients.clientExceptionsPageInfo = defaultPageInfo;
                }
            })

            .addCase(RestoreKycTargetFromArchive.pending, (slice) => {
                slice.loadings.moveFromArchive = true;
            })
            .addCase(RestoreKycTargetFromArchive.rejected, (slice) => {
                slice.loadings.moveFromArchive = false;

            })
            .addCase(RestoreKycTargetFromArchive.fulfilled, (slice, {payload}) => {
                slice.loadings.moveFromArchive = false;
                slice.dialogs.restoreClientFromArchive = initialState.dialogs.restoreClientFromArchive;
                slice.archive.clients = slice.archive.clients.filter(e => e.id !== payload.targetId);
                slice.archive.pageInfo.total = slice.archive.pageInfo.total - 1;
            })

            .addCase(AddKycTargetToBlackList.pending, (slice) => {
                slice.loadings.moveToBlacklist = true;
            })
            .addCase(AddKycTargetToBlackList.rejected, (slice) => {
                slice.loadings.moveToBlacklist = false;

            })
            .addCase(AddKycTargetToBlackList.fulfilled, (slice, {payload}) => {
                slice.loadings.moveToBlacklist = false;
                slice.dialogs.addToBlackList = initialState.dialogs.addToBlackList;
                slice.clients.clients = slice.clients.clients.map(e => e.id === payload.targetId ? ({...e, status: TARGET_BLACKLIST_STATUS}) : e)
                if (slice.clients.selected){
                    slice.clients.selected.status = TARGET_BLACKLIST_STATUS;
                }
                if (slice.screenings.selected){
                    slice.screenings.selected.target.status = BLACK_LIST_STATUS;
                }
            })

            .addCase(RestoreKycTargetFromBlackList.pending, (slice) => {
                slice.loadings.moveFromBlacklist = true;
            })
            .addCase(RestoreKycTargetFromBlackList.rejected, (slice) => {
                slice.loadings.moveFromBlacklist = false;

            })
            .addCase(RestoreKycTargetFromBlackList.fulfilled, (slice, {payload}) => {
                slice.loadings.moveFromBlacklist = false;
                slice.dialogs.removeFromBlackList = initialState.dialogs.removeFromBlackList;
                slice.clients.clients = slice.clients.clients.map(e => e.id === payload.targetId ? ({...e, status: TARGET_CREATED_STATUS}) : e);
                if(slice.clients.selected){
                    slice.clients.selected.status = TARGET_CREATED_STATUS;
                }
                if (slice.screenings.selected){
                    slice.screenings.selected.target.status = TARGET_CREATED_STATUS;
                }
            })

            //SetKycTargetVerified
            .addCase(SetKycTargetVerified.pending, (slice) => {
                slice.loadings.setClientVerified = true;
            })
            .addCase(SetKycTargetVerified.rejected, (slice) => {
                slice.loadings.setClientVerified = false;

            })
            .addCase(SetKycTargetVerified.fulfilled, (slice, {payload}) => {
                slice.loadings.setClientVerified = false;
                if (slice.screenings.selected) slice.screenings.selected.target.status = VERIFIED_STATUS
            })

            .addCase(DeleteKycArchivedTarget.pending, (slice) => {
                slice.loadings.deleteTarget = true;
            })
            .addCase(DeleteKycArchivedTarget.rejected, (slice) => {
                slice.loadings.deleteTarget = false;

            })
            .addCase(DeleteKycArchivedTarget.fulfilled, (slice, {payload}) => {
                slice.loadings.deleteTarget = false;
                slice.dialogs.deleteArchivedTarget = initialState.dialogs.deleteArchivedTarget;
                slice.archive.clients = slice.archive.clients.filter(e => e.id !== payload.targetId);
            })

            //Screenings
            //CreateTarget
            .addCase(StartOneTimeScreening.pending, (slice) => {
                slice.loadings.startTargetScreening = true;
            })
            .addCase(StartOneTimeScreening.rejected, (slice) => {
                slice.loadings.startTargetScreening = false;

            })
            .addCase(StartOneTimeScreening.fulfilled, (slice: kycInitialState, {payload}) => {
                slice.loadings.startTargetScreening = false;
                slice.dialogs.oneTimeScreening.response = payload;
            })
            .addCase(GetKycScreeningsWithFilterAndPaging.pending, (slice) => {
                slice.loadings.isLoadingList = true;
            })
            .addCase(GetKycScreeningsWithFilterAndPaging.rejected, (slice) => {
                slice.loadings.isLoadingList = false;

            })
            .addCase(GetKycScreeningsWithFilterAndPaging.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingList = false;

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<KycScreeningModel>(
                    slice.screenings.screenings,
                    slice.screenings.pageInfo,
                    payload.screenings,
                    payload.pageInfo,
                    slice.screenings.minMaxLoadedPage.minLoadedPage,
                    slice.screenings.minMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.screenings.screenings = results;
                slice.screenings.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.screenings.pageInfo = payload.pageInfo;

                // const {array, pageInfo} = setPaginationInStore<KycScreeningModel>(
                //     slice.screenings.pageInfo,
                //     slice.screenings.screenings,
                //     payload.pageInfo,
                //     payload.screenings,
                //     false
                // );
                // slice.screenings.screenings = array.filter(e => e !== null) as KycScreeningModel[];
                // slice.screenings.pageInfo = pageInfo;
            })

            .addCase(GetKycScreeningById.pending, (slice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(GetKycScreeningById.rejected, (slice) => {
                slice.loadings.isLoadingExact = false;

            })
            .addCase(GetKycScreeningById.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingExact = false;
                // slice.screenings.selected = payload;
            })

            //ScreeningFullInfo
            .addCase(GetKycScreeningFullInfoById.pending, (slice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(GetKycScreeningFullInfoById.rejected, (slice) => {
                slice.loadings.isLoadingExact = false;

            })
            .addCase(GetKycScreeningFullInfoById.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingExact = false;
                slice.screenings.selected = payload;
            })

        //GetKycTargetById
            .addCase(GetKycTargetById.pending, (slice) => {
                slice.loadings.getExactTarget = true;
            })
            .addCase(GetKycTargetById.rejected, (slice) => {
                slice.loadings.getExactTarget = false;

            })
            .addCase(GetKycTargetById.fulfilled, (slice, {payload}) => {
                slice.loadings.getExactTarget = false;
                slice.clients.selected = payload;
            })
        //GetKycExceptionsByTargetId
            .addCase(GetKycExceptionsByTargetId.pending, (slice) => {
                slice.loadings.getExactTargetExceptions = true;
            })
            .addCase(GetKycExceptionsByTargetId.rejected, (slice) => {
                slice.loadings.getExactTargetExceptions = false;

            })
            .addCase(GetKycExceptionsByTargetId.fulfilled, (slice, {payload}) => {
                slice.loadings.getExactTargetExceptions = false;
                const {results, maxLoadedPage, minLoadedPage} = handlePagination<KycExceptionModel>(
                    slice.clients.selectedClintExceptions,
                    slice.clients.clientExceptionsPageInfo,
                    payload.exceptions,
                    payload.pageInfo,
                    slice.clients.selectedClintExceptionsMinMaxPage.minLoadedPage,
                    slice.clients.selectedClintExceptionsMinMaxPage.maxLoadedPage,
                    'id'
                );

                slice.clients.selectedClintExceptions = results;
                slice.clients.selectedClintExceptionsMinMaxPage = {minLoadedPage, maxLoadedPage};
                slice.clients.clientExceptionsPageInfo = payload.pageInfo;

                // const {array, pageInfo} = setPaginationInStore<KycExceptionModel>(
                //     slice.clients.clientExceptionsPageInfo,
                //     slice.clients.selectedClintExceptions,
                //     payload.pageInfo,
                //     payload.exceptions,
                //     false
                // );
                // slice.clients.selectedClintExceptions = array.filter(e => e !== null) as KycExceptionModel[];
                // slice.screenings.pageInfo = pageInfo;
            })

            //CreateTargetException
            .addCase(CreateTargetException.pending, (slice) => {
                slice.loadings.createException = true;
            })
            .addCase(CreateTargetException.rejected, (slice) => {
                slice.loadings.createException = false;

            })
            .addCase(CreateTargetException.fulfilled, (slice, {payload}) => {
                slice.loadings.createException = false;
                slice.dialogs.createException = initialState.dialogs.createException;

                slice.screenings.selectedScreeningResults = slice.screenings.selectedScreeningResults
                    .map((res) => res.entityId === payload.data.entityId ? ({...res, exceptionId: payload.resp?.exceptions?.find(e => e.entityId === res.entityId)?.id ?? null}) : res);

                if (slice.screenings.selected?.screening) {
                    slice.screenings.selected = {
                        ...slice.screenings.selected,
                        // screening: payload.resp.screening,
                        screening: {...slice.screenings.selected.screening, exceptionCount: payload.resp.screening.exceptionCount},
                        exceptions: payload.resp.exceptions,
                    }
                }
            })
        //DeleteTargetException
            .addCase(DeleteTargetException.pending, (slice) => {
                slice.loadings.deleteTargetException = true;
            })
            .addCase(DeleteTargetException.rejected, (slice) => {
                slice.loadings.deleteTargetException = false;

            })
            .addCase(DeleteTargetException.fulfilled, (slice, {payload}) => {
                slice.loadings.deleteTargetException = false;
                slice.dialogs.deleteTargetException = initialState.dialogs.deleteTargetException;
                slice.clients = {
                    ...slice.clients,
                    selectedClintExceptions: slice.clients.selectedClintExceptions.filter(e => e.id !== payload.exceptionId),
                    clientExceptionsPageInfo: {
                        ...slice.clients.clientExceptionsPageInfo,
                        total: slice.clients.clientExceptionsPageInfo.total - 1
                    }
                }
                if (slice.screenings.selected) {
                    slice.screenings.selected = {
                        ...slice.screenings.selected,
                        exceptions: slice.screenings.selected.exceptions?.filter(e => e.id !== payload.exceptionId),
                        screening: {
                            ...slice.screenings.selected.screening,
                            exceptionCount: slice.screenings.selected?.screening?.exceptionCount ? slice.screenings.selected?.screening?.exceptionCount - 1 : slice.screenings.selected?.screening?.exceptionCount
                        },
                    }
                }
                if (slice.screenings.selectedScreeningResults) {
                    slice.screenings.selectedScreeningResults = slice.screenings.selectedScreeningResults?.map(e => e.exceptionId === payload.exceptionId ? ({
                        ...e,
                        exceptionId: null
                    }) : e)
                }

            })

        //StartTargetScreening
            .addCase(StartTargetScreening.pending, (slice) => {
                slice.loadings.startTargetScreening = true;
            })
            .addCase(StartTargetScreening.rejected, (slice) => {
                slice.loadings.startTargetScreening = false;

            })
            .addCase(StartTargetScreening.fulfilled, (slice, {payload}) => {
                slice.loadings.startTargetScreening = false;
            })
        //GetTargetHistory
            .addCase(GetTargetHistory.pending, (slice) => {
                slice.loadings.getExactTargetHistory = true;
            })
            .addCase(GetTargetHistory.rejected, (slice) => {
                slice.loadings.getExactTargetHistory = false;

            })
            .addCase(GetTargetHistory.fulfilled, (slice, {payload}) => {
                slice.loadings.getExactTargetHistory = false;
                slice.clients.selectedClientHistory = payload.historyLogs;
            })
        //UploadKycCsvFile
            .addCase(UploadKycCsvFile.pending, (slice) => {
                slice.loadings.isLoadingUploadCsv = true;
            })
            .addCase(UploadKycCsvFile.rejected, (slice) => {
                slice.loadings.isLoadingUploadCsv = false;

            })
            .addCase(UploadKycCsvFile.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingUploadCsv = false;
                slice.dialogs.templatesListImportCsv = {...slice.dialogs.templatesListImportCsv, uploadCsvResponse: payload};
            })
        //PerformCsvByFileId
            .addCase(PerformCsvByFileId.pending, (slice) => {
                slice.loadings.isLoadingPerformCsv = true;
            })
            .addCase(PerformCsvByFileId.rejected, (slice) => {
                slice.loadings.isLoadingPerformCsv = false;

            })
            .addCase(PerformCsvByFileId.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingPerformCsv = false;
            })
        //GetKycMatches
            .addCase(GetKycMatches.pending, (slice) => {
                slice.loadings.getMatchesList = true;
            })
            .addCase(GetKycMatches.rejected, (slice) => {
                slice.loadings.getMatchesList = false;

            })
            .addCase(GetKycMatches.fulfilled, (slice, {payload}) => {
                slice.loadings.getMatchesList = false;
                // const {array, pageInfo} = setPaginationInStore<KycMatchModel>(
                //     slice.matches.pageInfo,
                //     slice.matches.list,
                //     payload.pageInfo,
                //     payload.matches,
                //     false
                // );
                // slice.matches.list = array.filter(e => e !== null) as KycMatchModel[];
                // slice.matches.pageInfo = pageInfo;
                const {results, maxLoadedPage, minLoadedPage} = handlePagination<KycMatchModel>(
                    slice.matches.list,
                    slice.matches.pageInfo,
                    payload.matches,
                    payload.pageInfo,
                    slice.matches.minMaxLoadedPage.minLoadedPage,
                    slice.matches.minMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.matches.list = results;
                slice.matches.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.matches.pageInfo = payload.pageInfo;
            })
        //GetKycTemplateHistory
            .addCase(GetKycTemplateHistory.pending, (slice) => {
                slice.loadings.getTemplateHistory = true;
            })
            .addCase(GetKycTemplateHistory.rejected, (slice) => {
                slice.loadings.getTemplateHistory = false;

            })
            .addCase(GetKycTemplateHistory.fulfilled, (slice, {payload}) => {
                slice.loadings.getTemplateHistory = false;
                const {array, pageInfo} = setPaginationInStore<KycTemplateCsvHistoryModel>(
                    slice.selectedTemplateCsvHistoryPageInfo,
                    slice.selectedTemplateCsvHistory,
                    payload.pageInfo,
                    payload.histories,
                    false
                );
                slice.selectedTemplateCsvHistory = array.filter(e => e !== null) as KycTemplateCsvHistoryModel[];
                slice.selectedTemplateCsvHistoryPageInfo = pageInfo;
            })
            //ExportCsvTargetsWithFilters
            .addCase(ExportCsvTargetsWithFilters.pending, (slice, {meta}) => {
                slice.loadings.exportCsv = true;
            })
            .addCase(ExportCsvTargetsWithFilters.rejected, (slice) => {
                slice.loadings.exportCsv = false;
            })
            .addCase(ExportCsvTargetsWithFilters.fulfilled, (slice, {payload}) => {
                slice.loadings.exportCsv = false;
                slice.dialogs.exportCsv = initialState.dialogs.exportCsv;
            })
            //ExportClientsByTemplateAutoDownload
            // .addCase(ExportCsvTargetsWithFilters.pending, (slice, {meta}) => {
            //     slice.loadings.exportCsv = true;
            // })
            // .addCase(ExportCsvTargetsWithFilters.rejected, (slice) => {
            //     slice.loadings.exportCsv = false;
            // })
            // .addCase(ExportCsvTargetsWithFilters.fulfilled, (slice, {payload}) => {
            //     slice.loadings.exportCsv = false;
            //     slice.dialogs.exportCsv = initialState.dialogs.exportCsv;
            // })

            //GetKycScreeningResults
            .addCase(GetKycScreeningResults.pending, (slice) => {
                slice.loadings.getScreeningResults = true;
            })
            .addCase(GetKycScreeningResults.rejected, (slice) => {
                slice.loadings.getScreeningResults = false;

            })
            .addCase(GetKycScreeningResults.fulfilled, (slice: kycInitialState, {payload}) => {
                slice.loadings.getScreeningResults = false;

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<KycScreeningResultModel>(
                    slice.screenings.selectedScreeningResults,
                    slice.screenings.selectedScreeningResultsPageInfo,
                    payload.result,
                    payload.pageInfo,
                    slice.screenings.selectedScreeningResultsMinMaxLoadedPage.minLoadedPage,
                    slice.screenings.selectedScreeningResultsMinMaxLoadedPage.maxLoadedPage,
                    'entityId'
                );

                slice.screenings.selectedScreeningResults = results;
                slice.screenings.selectedScreeningResultsMinMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.screenings.selectedScreeningResultsPageInfo = payload.pageInfo;

                // const {array, pageInfo} = setPaginationInStore<KycScreeningResultModel>(
                //     slice.screenings.selectedScreeningResultsPageInfo,
                //     slice.screenings.selectedScreeningResults,
                //     payload.pageInfo,
                //     payload.result,
                //     false
                // );
                // slice.screenings.selectedScreeningResults = array.filter(e => e !== null) as KycScreeningResultModel[];
                // slice.screenings.selectedScreeningResultsPageInfo = pageInfo;
            })
        //GetKycScreeningFullResultByEntityId
            .addCase(GetKycScreeningFullResultByEntityId.pending, (slice) => {
                slice.loadings.getScreeningResultExact = true;
            })
            .addCase(GetKycScreeningFullResultByEntityId.rejected, (slice) => {
                slice.loadings.getScreeningResultExact = false;

            })
            .addCase(GetKycScreeningFullResultByEntityId.fulfilled, (slice: kycInitialState, {payload}) => {
                slice.loadings.getScreeningResultExact = false;
                slice.screenings.selectedScreeningSelectedResult = payload;
            })
            .addCase(GetKycOtsFullResultByEntityId.pending, (slice) => {
                slice.loadings.getScreeningResultExact = true;
            })
            .addCase(GetKycOtsFullResultByEntityId.rejected, (slice) => {
                slice.loadings.getScreeningResultExact = false;

            })
            .addCase(GetKycOtsFullResultByEntityId.fulfilled, (slice: kycInitialState, {payload}) => {
                slice.loadings.getScreeningResultExact = false;
                slice.screenings.selectedScreeningSelectedResult = payload;
            })
        //GetKycDictionaries
            .addCase(GetKycDictionaries.pending, (slice) => {
                slice.loadings.getDictionaries = true;
            })
            .addCase(GetKycDictionaries.rejected, (slice) => {
                slice.loadings.getDictionaries = false;

            })
            .addCase(GetKycDictionaries.fulfilled, (slice: kycInitialState, {payload}) => {
                slice.loadings.getDictionaries = false;
                slice.dictionaries = payload;
            })
        //GetDeleteTemplateData
            .addCase(GetDeleteTemplateData.pending, (slice) => {
                slice.loadings.getDeleteTemplateData = true;
            })
            .addCase(GetDeleteTemplateData.rejected, (slice) => {
                slice.loadings.getDeleteTemplateData = false;

            })
            .addCase(GetDeleteTemplateData.fulfilled, (slice: kycInitialState, {payload}) => {
                slice.loadings.getDeleteTemplateData = false;
                slice.dialogs.deleteTemplate = {
                    ...slice.dialogs.deleteTemplate,
                    clientsCount: payload.clientsCount,
                    archivedClientsCount: payload.archivedClientsCount
                }
            })
        //ExportClientsByTemplate
            .addCase(ExportClientsByTemplate.pending, (slice) => {
                slice.loadings.downloadCsvInDeleteTemplateDialog = true;
            })
            .addCase(ExportClientsByTemplate.rejected, (slice) => {
                slice.loadings.downloadCsvInDeleteTemplateDialog = false;

            })
            .addCase(ExportClientsByTemplate.fulfilled, (slice: kycInitialState, {payload}) => {
                slice.loadings.downloadCsvInDeleteTemplateDialog = false;
                slice.dialogs.exportCsv = initialState.dialogs.exportCsv;
            })
        //DeleteKycTemplate
            .addCase(DeleteKycTemplate.pending, (slice) => {
                slice.loadings.deleteTemplate = true;
            })
            .addCase(DeleteKycTemplate.rejected, (slice) => {
                slice.loadings.deleteTemplate = false;

            })
            .addCase(DeleteKycTemplate.fulfilled, (slice: kycInitialState, {payload}) => {
                slice.loadings.deleteTemplate = false;
                slice.templates = slice.templates.filter(e => e.id !== payload);
                slice.dialogs.deleteTemplate = initialState.dialogs.deleteTemplate;
            })
    }
});

export const KycRefactorReducer = KycRefactorSlice.reducer;

export const {
    KYC_SCREENING_STATUS_CHANGED_action,
    KYC_SCREENING_CREATED_action,
    KYC_MATCH_CREATED_action,
    KYC_CLIENT_ARCHIVED_action,
    KYC_CLIENT_UNARCHIVED_action,
    KYC_CLIENT_ADDED_action,
    KYC_TARGET_STATUS_CHANGED_action,
    KYC_REQUEST_STATUS_CHANGED_action,
    KYC_REQUEST_ADDED_action,
    KYC_EXCEPTION_ADDED_action,
    KYC_EXCEPTION_DELETED_action,
    KYC_ARCHIVED_TARGET_DELETED_action,
    KYC_TARGET_UPDATED_action,


    eraseMatches,
    eraseTemplates,
    setSelectedTemplate,
    deselectTemplate,
    deselectTarget,

    setSelectedTarget,

    setSelectedRequest,
    deselectRequest,
    eraseRequests,

    openTemplatesImportCsv,
    hideTemplatesImportCsv,

    openPreviewTemplate,
    hidePreviewTemplate,

    openDeleteTemplate,
    hideDeleteTemplate,

    openTemplateSendToClient,
    hideTemplateSendToClient,

    openTemplateInputClientData,
    hideTemplateInputClientData,

    openOneTimeScreening,
    hideOneTimeScreening,
    eraseOneTimeScreeningResponse,

    openRequestNotCompleted,
    hideRequestNotCompleted,

    openCancelRequest,
    hideCancelRequest,

    //Clients
    selectClient,
    deselectClient,
    eraseArchive,
    openCreateClientDialog,
    hideCreateClientDialog,
    openEditClientInfoDialog,
    hideEditClientInfoDialog,
    openAddToBlackListDialog,
    hideAddToBlackListDialog,
    openArchiveClientDialog,
    hideArchiveClientDialog,
    openRemoveFromBlackListDialog,
    hideRemoveFromBlackListDialog,
    openRestoreClientFromArchiveDialog,
    hideRestoreClientFromArchiveDialog,

    openDeleteTargetException,
    hideDeleteTargetException,

    //screenings
    eraseScreenings,
    setSelectedScreening,
    deselectScreening,
    openCreateException,
    hideCreateException,
    openShowClientDetail,
    hideShowClientDetail,
    openDeleteArchivedTarget,
    hideDeleteArchivedTarget,

    cleanUp,
    cleanArchive,
    eraseClientsList,

    openEditClientDialog,
    hideEditClientDialog,

    openShowFullReport,
    hideShowFullReport,
    openExportCsv,
    hideExportCsv,

    setIsDataOutdated,
    eraseIsDataOutdated,
    eraseExactClientExceptionsList,
    replaceTargetsMinMaxLoadedPage,
    eraseClientsListFields,
    replaceMatchesMinMaxLoadedPage,
    replaceScreeningsMinMaxLoadedPage,
    replaceRequestsMinMaxLoadedPage,
    replaceArchiveMinMaxLoadedPage,
    replaceSelectedClintExceptionsMinMaxPage,
    replaceScreeningEntities,
    eraseScreeningEntities
} = KycRefactorSlice.actions;

const selectSelf = (state: AppState): kycInitialState => state.kycRefactor as kycInitialState;

export const templates = createSelector(selectSelf, state => state.templates);
export const selectedTemplate = createSelector(selectSelf, state => state.selectedTemplate);
export const clientsSelector = createSelector(selectSelf, state => state.clients);
export const archiveSelector = createSelector(selectSelf, state => state.archive);
export const screeningsSelector = createSelector(selectSelf, state => state.screenings);
export const templatesPageInfo = createSelector(selectSelf, state => state.templatesPageInfo);
export const dialogs = createSelector(selectSelf, state => state.dialogs);
export const loadings = createSelector(selectSelf, state => state.loadings);
export const templateConfig = createSelector(selectSelf, state => state.templateConfig);

export const requests = createSelector(selectSelf, state => state.requests);
export const requestsPageInfo = createSelector(selectSelf, state => state.requestsPageInfo);
export const selectedRequest = createSelector(selectSelf, state => state.selectedRequest);

export const matchesSelector = createSelector(selectSelf, state => state.matches);

export const selectedTemplateCsvHistory = createSelector(selectSelf, state => state.selectedTemplateCsvHistory);
export const selectedTemplateCsvHistoryPageInfo = createSelector(selectSelf, state => state.selectedTemplateCsvHistoryPageInfo);
export const isDataOutdated = createSelector(selectSelf, state => state.isDataOutdated);

export const kycDictionaries = createSelector(selectSelf, state => state.dictionaries);
export const requestMinMaxPage = createSelector(selectSelf, state => state.requestMinMaxPage);







