import styled from 'styled-components';
import {device} from "../../../../../newShared/constants";
import {Autocomplete, TextField} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {DatePicker} from "@mui/x-date-pickers";
import {getRisksTableHeaderImpactCellBorderStyles} from "../../helpers";

export const FilterContainer = styled.div`
    display: flex;
    width: 20%;
    margin: 30px 0 0 0;
    & div{
        margin: 0 10px 0 0;
    }
    ${device.tablet} {
        width: 100%;
    }
`;

export const TextFieldCustom = styled(TextField)`
  & .MuiInputBase-input {
    height: 16px;
  }
  & label{
    top: 0 !important;
  }
`;

export const TrainingsTableHeaderWrapper = styled.tr<{minWidth?: string, jc?: string, width?: string, height?: string}>`
    ${props => props.minWidth && `min-width: ${props.minWidth};`}
    display: flex;
    width: ${props => props.width ?? '90%'};
    height: ${props => props.height ?? 'initial'};
    justify-content: ${props => props.jc ?? 'space-between'};
    align-items: center;
    margin: 32px 0 24px 0;
    ${device.tablet} {
        width: 100%;
        margin: 32px 0 24px 0;
    }
    line-height: normal;
`;

export const TableServicesHeaderItemWrapper = styled.div<{ai?: string}>`
    width: 25%;
    ${device.generateMax(890)}{
        width: 50%;
    }
    display: flex;
    align-items: ${props => props.ai ?? 'center'};
    & p {
        text-overflow: ellipsis; 
        display: block; 
        overflow: hidden; 
        line-height: normal;
        white-space: nowrap;
    }
`;

export const MainTableRowWrapper = styled.div<{disableHover?: boolean, width?: string, minWidth?: string, padding?: string, backgroundColor?: string}>`
    ${props => props.minWidth && `min-width: ${props.minWidth};`}
    padding: ${props => props.padding ?? '16px 5px'};
    width: ${props => props.width ?? '100%'};
    background-color: ${props => props.backgroundColor ?? undefined};
     ${props => props.disableHover ?'' : `
        &:hover{
            background-color: ${props.backgroundColor ? undefined : colors.backgrounds.grey_light};
            cursor: pointer;
        },
    `}
    overflow: hidden;    
`;

export const TableRowWrapper = styled.div<{disableHover?: boolean, jc?: string, width?: string}>`
    width: ${props => props.width ?? '90%'};
    display: flex;
    justify-content: ${props => props.jc ?? 'space-between'};
    align-items: center;
    ${props => props.disableHover ?'' : `
        &:hover{
            background-color: 'inherit';
            cursor: pointer;
        }
    `}

    background-color: 'inherit';
   
    ${device.tablet} {
        width: 100%;
    }
`;

export const TableServicesItemWrapper = styled.div<{ai?: string, width?: string, widthFor890?: string}>`
    width: ${props => props.width ?? '25%'};
    padding: 0 2px;
    ${device.generateMax(890)}{
        width: ${props => props.widthFor890 ?? '50%'};
    }
    display: flex;
    align-items: ${props => props.ai ?? 'center'};
    & p {
        text-overflow: ellipsis; 
        display: block; 
        overflow: hidden; 
        line-height: normal;
    }
`;

export const ExactModelOverview = styled.div`
    width: 35%;
    display: flex;
    
    ${device.generateMax(830)}{
        width: 50%;
    }
    
    ${device.generateMax(640)}{
        width: 80%;
    }
    
    ${device.desktopNarrow} {
        width: 100%;
    }
`;

export const ListPageTopButtonContainer = styled.div`
    display: flex;
    align-items: center;
    ${device.generateMax(1049)} {
        width: 100%;
        justify-content: space-between;
    }
    ${device.generateMax(501)} {
        margin: 11px 0 0 0;
    }
`;

export const ExactRegistryActionsBarContainer = styled.div<{jc?: string}>`
    display: flex;
    align-items: center;
    justify-content: ${props => props.jc ?? 'flex-start'};
    
    ${device.tablet} {
        width: 100%;
        margin: 11px 0 0 0;
    }
`;


export const SliderAndModelViewWrapper = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    width: 60%;
    ${device.desktopNarrow}{
        width: 100%;
    }
`;

export const EditModelWrapper = styled.div`
    overflow: auto;
    width: 90%;
    ${device.desktopNarrow}{
        width: 100%;
    }
`;

export const SliderWrapper = styled.div<{revDir: boolean}>`
    display: flex;
    width: 100%;
    position: relative;
    margin: 32px 0;
    ${props => props.revDir ? 'flex-direction: row-reverse;' : ''};
`;

export const EditModelInputsContainer = styled.div`
    width: 90%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    ${device.desktopNarrow} {
        width: 100%;
    }
`;

export const EditModeInputWrapper = styled.div`
    width: 48%;
    display: flex;
    flex-direction: column;
    ${device.desktopNarrow} {
        width: 100%;
    }
`;

export const MainTableScrollWrapper = styled.div`
    overflow: auto;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

export const ExactRegistryLeftContainer = styled.div<{isRightSideOpen: boolean}>`
    width: ${props => props.isRightSideOpen ? '50%' : '100%'};
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
    ${device.desktopM}{
        width: ${props => props.isRightSideOpen ? '50%' : '100%'};
    }
    ${device.tablet}{
        width: ${props => props.isRightSideOpen ? '0' : '100%'};
        display: ${props => props.isRightSideOpen ? 'none' : 'flex'};
    }
`;

export const ExactRegistryRightContainer = styled.div<{isRightSideOpen: boolean, revDir: boolean}>`
    width: ${props => props.isRightSideOpen ? '50%' : '0'};
    height: 100%;
    display: ${props => props.isRightSideOpen ? 'flex' : 'none'};
    justify-content: space-between;
    padding: ${props => !props.revDir ? '0 0 0 32px': '0 32px 0 0'};
    
    ${device.desktopM}{
        width: ${props => props.isRightSideOpen ? '50%' : '0'};
    }
    ${device.tablet}{
        width: ${props => props.isRightSideOpen ? '100%' : '0'};
        display: ${props => props.isRightSideOpen ? 'flex' : 'none'};
        padding: 0;
    }
`;

export const ExactRegistryRightSideInputsWrapper = styled.div`
    width: 80%;
    margin: 16px 0 0 0;
    ${device.desktopNarrow} {
        width: 100%;
    }
`;

export const ExactRegistryRightSideSwitchContainer = styled.div`
    width: 100%;
    display: flex;
    margin: 16px 0 0 0;
    align-items: center;
    justify-content: space-between;
    ${device.generateMax(1020)} {
        padding: 0 0 0 5px;
        align-items: flex-start;
        flex-direction: column;
        & label span .MuiFormControlLabel-label{
            margin: 0 0 0 5px;
        }
    }
    
`;

export const RisksTableSwitchContainer = styled.div`
    width: 40%;
    display: flex;
    margin: 16px 0 0 0;
    align-items: center;
    //justify-content: space-between;
    ${device.tablet}{
        width: 100%;
    }
    
`;


export const RisksCustomTableCell = styled.div<{width?: string, isFirst?: boolean, disablePadding?: boolean, revDir: boolean}>`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.darkText} !important;
    ${props => props.revDir ? 
        `${props.isFirst ? `border-right: 1px solid ${colors.darkText} !important` : 'border-right: none !important'};`:
        `${props.isFirst ? `border-left: 1px solid ${colors.darkText} !important` : `border-left: none !important;`};`    
    }
    padding: ${props => props.disablePadding ? '0 !important' : '10px 0 !important'};
    width: ${props => props.width ?? 'initial'};
    height: 40px;
    min-height: 36px;
`;

export const RisksCustomBodyTableCell = styled.div<{width: string, isFirst?: boolean, disablePadding?: boolean, setMargin?: boolean, h?: string, revDir: boolean}>`
    border: 1px solid ${colors.darkText} !important;
    border-top: none !important;
    ${props => props.revDir ?
        `${props.isFirst ? `border-right: 1px solid ${colors.darkText} !important` : 'border-right: none !important'};`:
        `${props.isFirst ? `border-left: 1px solid ${colors.darkText} !important` : `border-left: none !important;`};`
    }
    width: ${props => props.width ?? 'initial'};
    padding: ${props => props.disablePadding ? '0 !important' : '10px 0 !important'};
    height: ${props => props.h ? props.h : '36px'};
    display: flex;
    align-items: center;
    justify-content: center;
    & p {
        text-overflow: ellipsis; 
        display: block; 
        overflow: hidden; 
        line-height: normal;
        white-space: nowrap;
    }
`;

export const RisksCustomTableCellInput = styled.input`
    border: 0 !important;
    padding: 0;
    outline: 0;
    font-size: 10px;
    font-weight: 400;
    width: 100%;
    height: 100%;
    margin: 0 5px;
`;

export const CustomDatePicker = styled(DatePicker)`
    color: red !important;
    width: 95%;
    // max-width: 80px;
    height: 100%;
    max-height: 36px;
    
    & div fieldset{
        border: none !important;
    }
    
    & .MuiInputBase-root {
        height: 100%;
    }
    
    & .MuiInputBase-input{
        font-size: 10px !important;
        font-weight: 400 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    
    & button{
        padding: 0;
    }
    
`;

export const RisksTableAutocomplete = styled(Autocomplete)`
    & .MuiOutlinedInput-notchedOutline{
        border: none !important;
    }
  
  & label{
    top: 0 !important;
    color: ${colors.text.grey_dark}
  }
  
  & div div input{
    font-size: 10px !important;
    font-weight: 400 !important;
  }
  
  & .MuiAutocomplete-popper {
    border: 1px solid red !important;
    color: red;
    background-color: red;
  }
`;

export const RisksTableTextFieldCustom = styled(TextField)`
  & .MuiInputBase-input {
    height: 16px;
    border: none;
  }
  & label{
    top: 0 !important;
    font-size: 10px;
    font-weight: 400;
    color: ${colors.text.grey_dark}
  }
`;


export const RightSideContainerCustomDatePicker = styled(DatePicker)`
    & label{
        top: -6px !important;
    }
`;

export const RisksTableHeaderImpactCell = styled.div<{revDir: boolean, order: number}>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33.33%;
    border: 1px solid ${colors.darkText};
    border-bottom: none;
    ${props => getRisksTableHeaderImpactCellBorderStyles(props.order, props.revDir)};
`;


export const AutoComplete = styled(Autocomplete)`
  & .MuiInputBase-input {
    height: 24px;
  }
  & label{
    top: 0 !important;
  }
`;

