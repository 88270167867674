// Core
import {ApolloClient, ApolloLink, createHttpLink, InMemoryCache, split} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {PATH_SERVER, PATH_WS_SERVER} from "../constants";
import {GraphQLWsLink} from "@apollo/client/link/subscriptions";
import {createClient} from "graphql-ws";
import {getMainDefinition} from "@apollo/client/utilities";
import {Logging} from "../utils/logs/log";
import {snackError} from '../utils/snacks/snacks';
// import {setTokenMissing} from "../../domain/signUp/store/apollo/local";

// GraphQL Server
const httpLink = createHttpLink({
    uri: PATH_SERVER,
    credentials: 'include'
});


const wsLink = new GraphQLWsLink(
    createClient({
        url: PATH_WS_SERVER,
        connectionParams: {
            credentials: 'include'
        }
    }),
);


// const wsLink = new WebSocketLink({
//     uri: GRAPHQL_WEBSOCKET_URL,
//     options: {
//         reconnect: true,
//         connectionParams: {
//             credentials: 'include',
//         },
//     },
// })

//exclude __typename from variables
const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation).map((data) => {
        return data;
    });
});

const logoutLink = onError(({networkError, graphQLErrors}) => {
    if (networkError) {
        Logging.log('NetworkError', networkError);
        snackError(`GraphQL(${PATH_SERVER}): Network Error`);
        return
    }
    if (graphQLErrors?.some(x => x?.extensions?.exception?.status === 401)) {
        // setTokenMissing();
        snackError('Unauthorized');
    } else {
        Logging.log('GraphQLErrors', graphQLErrors);
        graphQLErrors?.forEach(x => x?.message && snackError(x?.message))
    }
})

const link = ApolloLink.from([logoutLink, cleanTypeName, httpLink]);

const splitedLink = split(
    ({ query }) => {
        let definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    link
);

export const client = new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: splitedLink,
    cache: new InMemoryCache({
        addTypename: false
    }),
});
