import {Flex} from "../../../../../newShared/components/Layouts"
import {useEditTarget} from "../../hooks/useEditTarget";
import colors from "../../../../../newShared/theme/colors";
import {Button, Typography} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import * as S from "../../components/styled";
import CommonLoading from "../../../../../newShared/components/Basic/CommonLoading";
import {CustomInput} from "../../../../../newShared/components/Inputs";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";


export const EditTarget = () => {
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'vulnerabilityScanner'});
    const {isLoading, target, handleGoBack, isOk, form, handleChange, handleUpdateTarget} = useEditTarget();

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={target?.name}
                handleGoBack={handleGoBack}
                actions={[]}
            />

            {isLoading ?
                <S.CreateTargetLoaderWrapper>
                    <CommonLoading/>
                </S.CreateTargetLoaderWrapper>
                :
                <Flex ai={'center'} m={'32px 0 0 0'}>
                    <Button variant={'contained'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}} disabled={!isOk()} onClick={handleUpdateTarget}>{t('Save')}</Button>
                    <Button variant={'outlined'} onClick={handleGoBack} disabled={isLoading}>{t('Cancel')}</Button>
                </Flex>
            }

            <S.CreateTargetInputsWrapper>
                <CustomInput
                    required
                    label={t('Target Name')}
                    value={form.name}
                    onChange={(e) => handleChange(e)}
                    name="name"
                    disabled={isLoading}
                />

                <CustomInput
                    required
                    label={t('Target destination')}
                    sx={{margin: '16px 0 0 0'}}
                    value={form.target}
                    helperText={<Typography sx={{fontSize: '10px', fontWeight: 400, color: colors.text.grey}}>{t('Domain or IP address. For example http//domain.com or https//domain.com or 142.250.185.174')}</Typography>}
                    onChange={(e) => handleChange(e)}
                    name="target"
                    disabled
                />
            </S.CreateTargetInputsWrapper>
        </Flex>
    )
}