import {useDispatch} from "react-redux";
import {useHistory} from "react-router";
import {MainVicaCategoryStepModel} from "../../../../../newShared/GQLTypes";
import {useMenu} from "../../../../../newShared/hooks/useMenu";
import {vicaTaskAction} from "../../../vica/types";
import {useStepDescriptionDialog} from "../dialogs/useStepDescriptionDialog";
import {MouseEventHandler} from "react";
import {PATH_VICA_STEP_EXACT} from "../../constants";
import {openActionDialog, openStepFrameworksDialog, setSelectStepResponsible} from "../../store/slice";
import {MainVicaCategoryLocalModel} from "../../types";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";

export const useVicaStep = ({step, category}: {step: MainVicaCategoryStepModel, category: MainVicaCategoryLocalModel}) => {
    //root
    const dispatch = useDispatch();
    const history = useHistory();
    const menu = useMenu();
    const {handleOpen} = useStepDescriptionDialog();
    const {clearFilters} = useGenericFiltersStorage();

    const handleOpenDescription: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.stopPropagation();
        handleOpen(step);
    }

    const handleOpenResponsibleDialog: MouseEventHandler<HTMLButtonElement | HTMLDivElement> = (event) => {
        event.stopPropagation();
        dispatch(setSelectStepResponsible({isOpen: true, step, categoryName: category.name}));
    }

    const handleOpenFrameworksDialog: MouseEventHandler<HTMLDivElement> = (event) => {
        event.stopPropagation();
        dispatch(openStepFrameworksDialog({step}));
    }

    const handleOpenStep = () => {
        clearFilters();
        history.push(PATH_VICA_STEP_EXACT.replace(':stepId', step.id));
    }

    const handleOpenActionDialog = (action: vicaTaskAction) => {
        if(action.type === 'LINK'){
            (action.data !== null && action.data.length > 0) && history.push(action.data);
        }else{
            dispatch(openActionDialog({
                categoryId: category.name,
                task: step,
                action
            }));
        }
        menu.handleClose();
    }

    return{
        actions: {
            handleOpenDescription,
            handleOpenResponsibleDialog,
            handleOpenFrameworksDialog,
            handleOpenActionDialog,
            handleOpenStep
        },
        menu
    }
}