import React, {FC} from "react";
import {useDrag} from "react-dnd";
import {DragIndicator, SvgIconComponent} from "@mui/icons-material";
import {TEditorActor, TEditorDragElementFillableFieldData, TEditorFillableFieldItemType} from "../../types";
import {EditorDragCreateBlockItemStyled} from "../editorDragCreateBlockItem/styled";
import colors from "../../../../theme/colors";

interface Props {
    type: TEditorFillableFieldItemType[keyof TEditorFillableFieldItemType],
    icon: SvgIconComponent,
    title: string,

    actor: TEditorActor,
    role?: string,
}
export const EditorDragCreateFillableField: FC<Props> = ({type, icon: Icon, title, actor: _actor, role: _role}) => {

    const actor = _actor;
    const role = _role || '';

    const [{ isDragging }, drag] = useDrag(() => ({
        type: type,
        item: {
            id: undefined,
            type: type,
            actor,
            pageId: '',
            size: {width: 115, height: 52},
            position: {x: 0, y: 0},
            data: '',
            sourceEmailRole: '' + actor.email + role,
            role,
        } satisfies TEditorDragElementFillableFieldData,
        collect: (monitor) => {
            return {
                isDragging: !!monitor.isDragging(),
            }
        }
    }), [type, actor, role])

    return (
        <EditorDragCreateBlockItemStyled ref={drag} isDragging={isDragging}>
            <DragIndicator/>
            <span>{title}</span>
            <Icon style={{fill: colors.text.grey, marginLeft: 'auto'}}/>
        </EditorDragCreateBlockItemStyled>
    )
}