import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import React from "react";
import {showFullReportTabs, useShowFullReport} from "../../../hooks/dialogs/useShowFullReport";
import {Flex} from "../../../../../../newShared/components/Layouts";
import colors from "../../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import DownloadIcon from '@mui/icons-material/Download';
import * as S from "../../../pages/screening/exact/style";
import {OverflowHiddenTextBodySmall} from "../../../../../../newShared/components/Inputs/styled";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {FlexBox} from "../../../../../../newShared/components/editorUnion/components/dialogs/manageActorsDialog/styled";
import {topicsDictionary} from "../../../constants";
import openSanctionsDataset from '../../../openSanctionDatasets/index.json';
import {Skeleton} from "@mui/lab";
import {BackTextButton} from "../../../../../../newShared/components/style";
import {randomHSLA} from "../../../../vica/helpers";


export const ShowFullReportDialog = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.dialogs'});

    const {
        isOpen,
        isOneTimeScreening,
        result,
        isLoading,
        handleClose,
        tab,
        setTab,
        handleDownload,
        currentEntityScore,
        createQuickException,
        createCustomException,
        exception,
        isLoadingQuickException,
        handleDeleteException,
        isPerson,
        isCorporate,
        isCryptoWallet,
        countryDict,
        currentTabsArray,
        clientNameTitle,
        targetName,
        topics
    } = useShowFullReport();

    return(
        <Dialog open={isOpen} onClose={handleClose} sx={{'& .MuiPaper-root': {minWidth: '900px', height: '744px'}}}>
            <DialogTitle sx={{marginBottom: '0'}}>{t('Full report')}</DialogTitle>

            <DialogContent sx={{padding: '0', overflow: 'hidden'}}>
                {/*{isLoading && <LocalSpinner/>}*/}
                <Flex direction={'column'} overflow={'hidden'} h={'100%'}>
                    <Flex direction={'column'} gap={isLoading ? '6px' : undefined} p={'20px 16px'}>
                        <Flex ai={'center'} gap={'16px'}>
                            <Typography variant={'body1'} color={colors.text.grey_dark}>{clientNameTitle + ':'}</Typography>
                            {isLoading ? <Skeleton width={'150px'} /> : <Typography variant={'subtitle1'} color={colors.grayText} fontWeight={700}>{targetName}</Typography>}
                        </Flex>

                        <Flex ai={'center'} gap={'8px'} m={'8px 0 0 0'} wrap={'wrap'}>
                            <Flex ai={'center'} gap={'16px'}>
                                <Typography variant={'body1'} color={colors.text.grey_dark}>{t('Match name:')}</Typography>
                                {isLoading ? <Skeleton width={'150px'} /> : <Typography variant={'subtitle1'} color={colors.grayText} fontWeight={700}>{result?.caption}</Typography>}
                            </Flex>
                            {topics.map(e => {
                                const {background, fontColor} = randomHSLA(`CHIPCOLOR234${e}`);
                                return(
                                    <Chip label={e} sx={{
                                        padding: '0 4px',
                                        height: '22px',
                                        fontSize: '10px',
                                        color: fontColor,
                                        backgroundColor: background,
                                        border: `1px solid ${fontColor}`,
                                        '& span': {padding: '0 4px'},
                                    }}/>
                                )
                            })}
                        </Flex>

                        <Flex ai={'center'} gap={'16px'}>
                            <Typography variant={'body1'} color={colors.text.grey_dark}>{t(`Match score:`)}</Typography>
                            {isLoading ? <Skeleton width={'150px'} /> : <Typography variant={'subtitle1'}>{isLoading ? "" : currentEntityScore.toString().substring(0, 4) ?? 0}</Typography>}

                            {!isOneTimeScreening && !isLoading &&
                                <Flex ai={'center'} gap={'16px'} m={'0 16px'}>
                                    {exception ? (
                                            <S.ExceptionsFlexContainer>
                                                <S.ExceptionsDataBox>
                                                    <OverflowHiddenTextBodySmall>{`${t('Resolution score limit')}: `}<S.ExceptionTextSpan>{exception.score.toString().substring(0, 4)}</S.ExceptionTextSpan></OverflowHiddenTextBodySmall>
                                                    <OverflowHiddenTextBodySmall>{`${t('Resolution reason')}: `}<S.ExceptionTextSpan>{exception?.reason ?? ''}</S.ExceptionTextSpan></OverflowHiddenTextBodySmall>
                                                </S.ExceptionsDataBox>

                                                <IconButton onClick={handleDeleteException}>
                                                    <DeleteOutlineIcon style={{color: colors.primary.blue}} />
                                                </IconButton>
                                            </S.ExceptionsFlexContainer>
                                        )
                                        :
                                        <FlexBox>
                                            <S.QuickExceptionButton onClick={createQuickException}
                                                                    loading={isLoadingQuickException} >
                                                {t('Quick resolution')}
                                            </S.QuickExceptionButton>

                                            <S.QuickExceptionButton onClick={createCustomException}>
                                                {t('Resolution')}
                                            </S.QuickExceptionButton>
                                        </FlexBox>
                                    }
                                </Flex>
                            }
                        </Flex>
                    </Flex>

                    <Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable}}/>

                    <Flex w={'100%'} h={'100%'} grow={'1'} overflow={'hidden'}>
                        <Flex w={'30%'} direction={'column'} overflow={'auto'}>
                            {currentTabsArray.map((e: showFullReportTabs) => (
                                <TabItem
                                    key={e}
                                    name={e}
                                    selected={tab === e}
                                    onClick={setTab}
                                />
                            ))}
                        </Flex>

                        <Divider flexItem orientation={'vertical'} sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable}}/>

                        <Flex w={'70%'} grow={'1'} h={'100%'} direction={'column'} p={'20px 16px'} overflow={'auto'}>
                            {isPerson &&
                                <>
                                    {tab === 'General info' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{t('General info')}</Typography>
                                            <CommonTable
                                                configs={[
                                                    {
                                                        object: result.properties,
                                                        key: 'topics',
                                                        normalizedName: t('Topics'),
                                                        values: (result.properties.topics ?? []).map(e => topicsDictionary.find(f => f.key === e)?.value ?? e)
                                                    },
                                                    {object: result, key: 'caption'},
                                                    {
                                                        object: result.properties,
                                                        key: 'birthDate',
                                                        normalizedName: t('Birth date'),
                                                        valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : undefined, false, true)
                                                    },
                                                    {
                                                        object: result.properties,
                                                        key: 'birthPlace',
                                                        normalizedName: t('Place of birth')
                                                    },
                                                    {
                                                        object: result.properties,
                                                        key: 'nationality',
                                                        values: result.properties.nationality ? (result.properties.nationality ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e) : ['-']
                                                    },
                                                    {
                                                        object: result.properties,
                                                        key: 'passportNumber',
                                                        normalizedName: t('Passport number')
                                                    },
                                                    {
                                                        object: result.properties,
                                                        key: 'previousName',
                                                        normalizedName: t('Previous name')
                                                    },
                                                    {object: result.properties, key: 'email'},
                                                    {object: result.properties, key: 'phone'},
                                                    {
                                                        object: result.properties,
                                                        key: 'idNumber',
                                                        normalizedName: t('ID Number')
                                                    },
                                                    {object: result.properties, key: 'innCode', normalizedName: 'INN'},
                                                    {
                                                        object: result.properties,
                                                        key: 'birthCountry',
                                                        normalizedName: t('Country of birth'),
                                                        values: result.properties.birthCountry ? (result.properties.birthCountry ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e) : ['-']
                                                    }, //
                                                    {
                                                        object: result.properties,
                                                        key: 'country',
                                                        values: result.properties.country ? (result.properties.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e) : ['-']
                                                    }, //
                                                    {
                                                        object: result.properties,
                                                        key: 'taxNumber',
                                                        normalizedName: t('Tax number')
                                                    },
                                                    {
                                                        object: result.properties,
                                                        key: 'vatCode',
                                                        normalizedName: t('V.A.T Identifier')
                                                    },
                                                    {
                                                        object: result.properties,
                                                        key: 'deathDate',
                                                        normalizedName: t('Death date'),
                                                        valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : undefined, false, true)
                                                    },
                                                    {object: result.properties, key: 'religion'},
                                                    {
                                                        object: result.properties,
                                                        key: 'political',
                                                        normalizedName: t('Political association')
                                                    },
                                                    {object: result.properties, key: 'keywords'},
                                                    {object: result.properties, key: 'summary'}
                                                ]}
                                            />
                                        </Flex>
                                    }

                                    {tab === 'Name and aliases' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{t('Name and aliases')}</Typography>
                                            <CommonTable
                                                configs={[
                                                    {object: result, key: 'caption'},
                                                    {object: result.properties, key: 'name'},
                                                    {object: result.properties, key: 'title'},
                                                    {
                                                        object: result.properties,
                                                        key: 'firstName',
                                                        normalizedName: t('First name')
                                                    },
                                                    {
                                                        object: result.properties,
                                                        key: 'secondName',
                                                        normalizedName: t('Second name')
                                                    },
                                                    {
                                                        object: result.properties,
                                                        key: 'middleName',
                                                        normalizedName: t('Middle name')
                                                    },
                                                    {
                                                        object: result.properties,
                                                        key: 'fatherName',
                                                        normalizedName: t('Father name')
                                                    },
                                                    {
                                                        object: result.properties,
                                                        key: 'lastName',
                                                        normalizedName: t('Last name')
                                                    },
                                                    {
                                                        object: result.properties,
                                                        key: 'nameSuffix',
                                                        normalizedName: t('Name suffix')
                                                    },
                                                    {object: result.properties, key: 'alias'},
                                                    {
                                                        object: result.properties,
                                                        key: 'motherName',
                                                        normalizedName: t('Matronymic')
                                                    },
                                                    {
                                                        object: result.properties,
                                                        key: 'weakAlias',
                                                        normalizedName: t('Weak alias')
                                                    },
                                                ]}
                                            />
                                        </Flex>
                                    }

                                    {tab === 'Identification' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{t('Identification')}</Typography>

                                            {result.properties && result.properties.identification && result.properties.identification.length > 0 ?
                                                <>
                                                    {result.properties && result.properties.identification && result.properties.identification.map(e => (
                                                        <Flex direction={'column'} gap={'8px'} m={'32px 0 0 0'}>
                                                            <Typography variant={'subtitle2'}>{e.caption}</Typography>

                                                            {e.schema === 'Identification' &&
                                                                <CommonTable
                                                                    configs={[
                                                                        {object: e.properties, key: 'type'},
                                                                        {object: e.properties, key: 'number'},
                                                                        {
                                                                            object: e.properties,
                                                                            key: 'country',
                                                                            values: (e.properties.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                                        },
                                                                        {
                                                                            object: e.properties,
                                                                            key: 'startDate',
                                                                            normalizedName: t('Start date')
                                                                        },
                                                                        {
                                                                            object: e.properties,
                                                                            key: 'endDate',
                                                                            normalizedName: t('End date'),
                                                                            valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : undefined, false, true)
                                                                        },
                                                                        {object: e.properties, key: 'authority'},
                                                                        {object: e.properties, key: 'summary'},
                                                                    ]}
                                                                />
                                                            }

                                                            {e.schema === 'Passport' &&
                                                                <CommonTable
                                                                    configs={[
                                                                        {object: e.properties, key: 'type'},
                                                                        {object: e.properties, key: 'number'},
                                                                        {
                                                                            object: e.properties,
                                                                            key: 'country',
                                                                            values: (e.properties.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                                        },
                                                                        {
                                                                            object: e.properties,
                                                                            key: 'startDate',
                                                                            normalizedName: t('Start date')
                                                                        },
                                                                        {
                                                                            object: e.properties,
                                                                            key: 'endDate',
                                                                            normalizedName: t('End date'),
                                                                            valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : undefined, false, true)
                                                                        },
                                                                        {object: e.properties, key: 'summary'},
                                                                    ]}
                                                                />
                                                            }
                                                        </Flex>
                                                    ))}
                                                </>
                                                :
                                                <Typography
                                                    variant={'subtitle1'}>{t('No identifications found')}</Typography>
                                            }
                                        </Flex>
                                    }

                                    {tab === 'Position' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{t('Position')}</Typography>
                                            {result.properties && result.properties.position && result.properties.position.length > 0 ?
                                                <SingleColumnTable values={result.properties.position}/>
                                                :
                                                <Typography variant={'subtitle1'}>{t('No positions found')}</Typography>
                                            }
                                        </Flex>
                                    }

                                    {tab === 'Addresses' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{t('Addresses')}</Typography>

                                            {(result.properties &&
                                                    result.properties.address !== undefined &&
                                                    result.properties.address?.length > 0) &&
                                                <Flex direction={'column'} gap={'16px'}>
                                                    <Typography variant={'subtitle2'}>Addresses</Typography>
                                                    <SingleColumnTable values={result.properties.address}/>
                                                </Flex>
                                            }

                                            {!(result.properties.addressEntity && result.properties.addressEntity.length > 0) &&
                                                <Typography variant={'subtitle1'}>{t('No address found')}</Typography>
                                            }

                                            {result.properties && result.properties.addressEntity && result.properties.addressEntity.map(e => (
                                                <Flex direction={'column'} gap={'8px'} m={'32px 0 0 0'}>
                                                    <Typography variant={'subtitle2'}>{e.caption}</Typography>

                                                    <CommonTable
                                                        configs={[
                                                            {object: e.properties, key: 'city'},
                                                            {object: e.properties, key: 'street'},
                                                            {object: e.properties, key: 'state'},
                                                            {
                                                                object: e.properties,
                                                                key: 'country',
                                                                values: (e.properties.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                            },
                                                            {object: e.properties, key: 'full'},
                                                        ]}
                                                    />
                                                </Flex>
                                            ))}
                                        </Flex>
                                    }

                                    {tab === 'Notes' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{t('Notes')}</Typography>
                                            {result.properties && result.properties.notes && result.properties.notes.length > 0 ?
                                                <SingleColumnTable values={result.properties.notes}/>
                                                :
                                                <Typography variant={'subtitle1'}>{t('No notes found')}</Typography>
                                            }
                                        </Flex>
                                    }

                                    {tab === 'Sanctions' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{t('Sanctions')}</Typography>
                                            {result.properties && result.properties.sanctions && result.properties.sanctions.length > 0 ?
                                                <>
                                                    {result.properties.sanctions.map(e => (
                                                        <Flex direction={'column'} gap={'8px'}>
                                                            <Typography variant={'subtitle2'}>{e.caption}</Typography>

                                                            <CommonTable
                                                                configs={[
                                                                    {object: e.properties, key: 'authority'},
                                                                    {object: e.properties, key: 'program'},
                                                                    {object: e.properties, key: 'sourceUrl'},
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'country',
                                                                        values: (e.properties.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'listingDate',
                                                                        normalizedName: t('Listing date')
                                                                    },
                                                                    {object: e.properties, key: 'duration'},
                                                                    {object: e.properties, key: 'status'},
                                                                    {object: e.properties, key: 'reason'},
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'provisions',
                                                                        normalizedName: t('Scope of sanctions')
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'unscId',
                                                                        normalizedName: t('UN SC identifier')
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'authorityId',
                                                                        normalizedName: t('Authority-issued identifier')
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'startDate',
                                                                        normalizedName: t('Start date')
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'endDate',
                                                                        normalizedName: t('End date')
                                                                    },
                                                                    {object: e.properties, key: 'date'},
                                                                    {object: e.properties, key: 'summary'},
                                                                    {object: e.properties, key: 'description'},
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'recordId',
                                                                        normalizedName: t('Record ID')
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'publisher',
                                                                        normalizedName: t('Publishing source')
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'publisherUrl',
                                                                        normalizedName: t('Publishing source URL')
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'modifiedAt',
                                                                        normalizedName: t('Modified on')
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'retrievedAt',
                                                                        normalizedName: t('Retrieved on')
                                                                    },
                                                                ]}
                                                            />
                                                        </Flex>
                                                    ))}
                                                </>
                                                :
                                                <Typography variant={'subtitle1'}>{t('No sanction found')}</Typography>
                                            }
                                        </Flex>
                                    }

                                    {tab === 'Directorship' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{t('Directorship')}</Typography>

                                            {result.properties && result.properties.directorshipDirector && result.properties.directorshipDirector.length > 0 ?
                                                <>
                                                    {result.properties.directorshipDirector.map(e => (
                                                        <Flex direction={'column'} gap={'8px'}>
                                                            <Typography
                                                                variant={'subtitle2'}>{e.caption ?? '-'}</Typography>
                                                            <Typography variant={'subtitle1'}>Start
                                                                date: {parseDateAuto(e.startDate, false, true)}</Typography>
                                                            <Typography variant={'subtitle1'}>End
                                                                date: {parseDateAuto(e.endDate, false, true)}</Typography>
                                                            <Typography
                                                                variant={'subtitle1'}>Organization: {(e.properties?.organization ?? [])[0]?.caption ?? '-'}</Typography>


                                                            <CommonTable
                                                                configs={[
                                                                    {
                                                                        object: (e.properties?.organization ?? [])[0]?.properties,
                                                                        key: 'name'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.organization ?? [])[0]?.properties,
                                                                        key: 'alias'
                                                                    },
                                                                    {object: (e.properties?.organization ?? [])[0]?.properties, key: 'jurisdiction', normalizedName: t('Jurisdiction'), values: ((e.properties?.organization ?? [])[0]?.properties.jurisdiction ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)},
                                                                    {
                                                                        object: (e.properties?.organization ?? [])[0]?.properties,
                                                                        key: 'incorporationDate',
                                                                        normalizedName: t('Incorporation date')
                                                                    },
                                                                    {
                                                                        object: (e.properties?.organization ?? [])[0]?.properties,
                                                                        key: 'address'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.organization ?? [])[0]?.properties,
                                                                        key: 'status'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.organization ?? [])[0]?.properties,
                                                                        key: 'sourceUrl'
                                                                    },
                                                                ]}
                                                            />
                                                        </Flex>
                                                    ))}
                                                </>
                                                :
                                                <Typography
                                                    variant={'subtitle1'}>{t('No directorship found')}</Typography>
                                            }
                                        </Flex>
                                    }

                                    {tab === 'Education' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{t('Education')}</Typography>

                                            {result.properties && result.properties.education !== undefined && result.properties.education.length > 0 ?
                                                <SingleColumnTable values={result.properties.education}/>
                                                :
                                                <Typography variant={'subtitle1'}>{t('No education found')}</Typography>
                                            }
                                        </Flex>
                                    }
                                    {tab === 'Family' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{t('Family')}</Typography>
                                            {(!result.properties ||
                                                    (result.properties && !result.properties.familyPerson) ||
                                                    (result.properties && result.properties.familyPerson && result.properties.familyPerson.length === 0)) &&
                                                (!result.properties ||
                                                    (result.properties && !result.properties.familyRelative) ||
                                                    (result.properties && result.properties.familyRelative && result.properties.familyRelative.length === 0)) &&
                                                <Typography
                                                    variant={'subtitle1'}>{t('No family records found')}</Typography>
                                            }

                                            {result.properties &&
                                                result.properties.familyPerson &&
                                                result.properties.familyPerson.length > 0 &&
                                                result.properties.familyPerson.map(e => (
                                                    <Flex direction={'column'} gap={'8px'}>
                                                        <Typography
                                                            variant={'subtitle2'}>{(e.properties?.relative ?? [])[0]?.caption ?? '-'}</Typography>

                                                        {(e.properties?.relative ?? [])[0]?.properties !== null && (e.properties?.relative ?? [])[0]?.properties !== undefined &&
                                                            <CommonTable
                                                                configs={[
                                                                    {object: e.properties, key: 'relationship'},
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'birthDate',
                                                                        normalizedName: t('Birth date'),
                                                                        valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : '-', true)
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'birthPlace',
                                                                        normalizedName: t('Birth place'),
                                                                        valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : '-', true)
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'gender'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'position'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'notes'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'nationality',
                                                                        values: ((e.properties?.relative ?? [])[0]?.properties?.nationality ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'name'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'alias'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'firstName',
                                                                        normalizedName: t('First name')
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: t('lastName'),
                                                                        normalizedName: 'Last name'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'fatherName',
                                                                        normalizedName: t('Patronymic')
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'sourceUrl',
                                                                        normalizedName: t('Source link')
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'country',
                                                                        values: ((e.properties?.relative ?? [])[0]?.properties?.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'taxNumber',
                                                                        normalizedName: t('Tax number')
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'religion'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'status'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'website'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'keywords',
                                                                        normalizedName: t('Key words')
                                                                    },
                                                                ]}
                                                            />
                                                        }
                                                    </Flex>
                                                ))}

                                            {result.properties && result.properties.familyRelative && result.properties.familyRelative.length > 0 && result.properties.familyRelative.map(e => (
                                                <Flex direction={'column'} gap={'8px'}>
                                                    <Typography
                                                        variant={'subtitle2'}>{(e.properties?.person ?? [])[0]?.caption ?? '-'}</Typography>
                                                    {/*(e.properties?.relative ?? e.properties?.familyRelative ?? [])*/}
                                                    {(e.properties?.person ?? [])[0]?.properties !== null &&
                                                        <CommonTable
                                                            configs={[
                                                                {object: e.properties, key: 'relationship'},
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'birthDate',
                                                                    normalizedName: 'Birth date',
                                                                    valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : '-', false, true)
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'birthPlace',
                                                                    normalizedName: 'Birth place'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'gender'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'position'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'notes'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'nationality',
                                                                    values: ((e.properties?.person ?? [])[0].properties.nationality ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'name'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'alias'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'firstName',
                                                                    normalizedName: 'First name'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'lastName',
                                                                    normalizedName: 'Last name'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'fatherName',
                                                                    normalizedName: 'Patronymic'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'sourceUrl',
                                                                    normalizedName: 'Source link'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'country',
                                                                    values: ((e.properties?.person ?? [])[0].properties.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'taxNumber',
                                                                    normalizedName: 'Tax number'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'religion'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'status'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'website'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'keywords',
                                                                    normalizedName: 'Key words'
                                                                },

                                                            ]}
                                                        />
                                                    }
                                                </Flex>
                                            ))}
                                        </Flex>
                                    }

                                    {tab === 'Associates' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{('Associates')}</Typography>

                                            {!(result.properties &&
                                                    ((result.properties.associations &&
                                                            result.properties.associations.length > 0) ||
                                                        (result.properties.associates &&
                                                            result.properties.associates.length > 0))) &&
                                                <Typography variant={'subtitle1'}>{('No associates found')}</Typography>
                                            }

                                            {result.properties && result.properties.associations && result.properties.associations.map(e => (
                                                <Flex direction={'column'} gap={'8px'}>
                                                    <Typography
                                                        variant={'subtitle2'}>{(e.properties.person ?? [])[0].caption ?? '-'}</Typography>

                                                    <CommonTable
                                                        configs={[
                                                            {object: e.properties, key: 'relationship'},
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'birthDate',
                                                                normalizedName: 'Birth date',
                                                                valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : '-', false, true)
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'birthPlace',
                                                                normalizedName: 'Birth place'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'gender'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'position'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'notes'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'nationality',
                                                                values: ((e.properties?.person ?? [])[0].properties.nationality ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'name'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'alias'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'firstName',
                                                                normalizedName: 'First name'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'lastName',
                                                                normalizedName: 'Last name'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'fatherName',
                                                                normalizedName: 'Patronymic'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'sourceUrl',
                                                                normalizedName: 'Source link'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'country',
                                                                values: ((e.properties?.person ?? [])[0].properties.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'taxNumber',
                                                                normalizedName: 'Tax number'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'religion'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'status'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'website'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'keywords',
                                                                normalizedName: 'Key words'
                                                            },
                                                        ]}
                                                    />
                                                </Flex>
                                            ))}

                                            {result.properties && result.properties.associates && result.properties.associates.map(e => (
                                                <Flex direction={'column'} gap={'8px'}>
                                                    <Typography
                                                        variant={'subtitle2'}>{(e.properties.associate ?? [])[0].caption ?? '-'}</Typography>

                                                    <CommonTable
                                                        configs={[
                                                            {object: e.properties, key: 'relationship'},
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'birthDate',
                                                                normalizedName: 'Birth date',
                                                                valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : '-', false, true)
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'birthPlace',
                                                                normalizedName: 'Birth place'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'gender'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'position'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'notes'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'nationality',
                                                                values: ((e.properties?.associate ?? [])[0].properties.nationality ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'name'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'alias'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'firstName',
                                                                normalizedName: 'First name'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'lastName',
                                                                normalizedName: 'Last name'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'fatherName',
                                                                normalizedName: 'Patronymic'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'sourceUrl',
                                                                normalizedName: 'Source link'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'country',
                                                                values: ((e.properties?.associate ?? [])[0].properties.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'taxNumber',
                                                                normalizedName: 'Tax number'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'religion'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'status'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'website'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'keywords',
                                                                normalizedName: 'Key words'
                                                            },
                                                        ]}
                                                    />
                                                </Flex>
                                            ))}
                                        </Flex>
                                    }

                                    {tab === 'Links' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{('Links')}</Typography>

                                            {!(result.properties &&
                                                    ((result.properties.unknownLinkFrom &&
                                                            result.properties.unknownLinkFrom.length > 0) ||
                                                        (result.properties.unknownLinkTo &&
                                                            result.properties.unknownLinkTo.length > 0))) &&
                                                <Typography variant={'subtitle1'}>{('No links found')}</Typography>
                                            }

                                            {result.properties && result.properties.unknownLinkFrom && result.properties.unknownLinkFrom.map(e => (
                                                <Flex direction={'column'} gap={'8px'}>
                                                    <Typography
                                                        variant={'subtitle2'}>{(e.properties?.subject ?? [])[0].caption ?? '-'}</Typography>

                                                    <CommonTable
                                                        configs={[
                                                            {
                                                                object: (e.properties?.subject ?? [])[0].properties,
                                                                key: 'name'
                                                            },
                                                            {
                                                                object: (e.properties?.subject ?? [])[0].properties,
                                                                key: 'alias'
                                                            },
                                                            {
                                                                object: result.properties,
                                                                key: 'topics',
                                                                values: ((e.properties?.subject ?? [])[0].properties?.topics ?? []).map(e => topicsDictionary.find(f => f.key === e)?.value ?? e)
                                                            },
                                                            {
                                                                object: (e.properties?.subject ?? [])[0].properties,
                                                                key: 'notes'
                                                            },
                                                            {
                                                                object: (e.properties?.subject ?? [])[0].properties,
                                                                key: 'sourceUrl',
                                                                normalizedName: 'Source link'
                                                            },
                                                            {
                                                                object: (e.properties?.subject ?? [])[0].properties,
                                                                key: 'datasets'
                                                            },
                                                        ]}
                                                    />
                                                </Flex>
                                            ))}

                                            {result.properties && result.properties.unknownLinkTo && result.properties.unknownLinkTo.map(e => (
                                                <Flex direction={'column'} gap={'8px'}>
                                                    <Typography
                                                        variant={'subtitle2'}>{(e.properties?.object ?? [])[0].caption ?? '-'}</Typography>

                                                    <CommonTable
                                                        configs={[
                                                            {
                                                                object: (e.properties?.object ?? [])[0].properties,
                                                                key: 'name'
                                                            },
                                                            {
                                                                object: (e.properties?.object ?? [])[0].properties,
                                                                key: 'alias'
                                                            },
                                                            {
                                                                object: result.properties,
                                                                key: 'topics',
                                                                values: ((e.properties?.object ?? [])[0].properties?.topics ?? []).map(e => topicsDictionary.find(f => f.key === e)?.value ?? e)
                                                            },
                                                            {
                                                                object: (e.properties?.object ?? [])[0].properties,
                                                                key: 'notes'
                                                            },
                                                            {
                                                                object: (e.properties?.object ?? [])[0].properties,
                                                                key: 'sourceUrl',
                                                                normalizedName: 'Source link'
                                                            },
                                                            {
                                                                object: (e.properties?.object ?? [])[0].properties,
                                                                key: 'datasets'
                                                            },
                                                        ]}
                                                    />
                                                </Flex>
                                            ))}
                                        </Flex>
                                    }

                                    {tab === 'Datasets' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{('Datasets')}</Typography>

                                            {!(result.datasets && result.datasets.length > 0) &&
                                                <Typography variant={'subtitle1'}>{('No datasets found')}</Typography>
                                            }

                                            {result.datasets && result.datasets.map(e => {
                                                const currentDataset = openSanctionsDataset.datasets.find((f: any) => f.name === e);
                                                if (currentDataset) {
                                                    return (
                                                        <Flex direction={'column'} gap={'8px'}>
                                                            <Typography
                                                                variant={'subtitle2'}>{currentDataset.title}</Typography>

                                                            <CommonTable
                                                                configs={[
                                                                    {object: currentDataset, key: 'url'},
                                                                    {object: currentDataset, key: 'summary'},
                                                                    {object: currentDataset, key: 'description'},
                                                                    {
                                                                        object: currentDataset,
                                                                        key: 'name',
                                                                        normalizedName: 'Publisher name'
                                                                    },
                                                                    {
                                                                        object: currentDataset.publisher,
                                                                        key: 'country',
                                                                        normalizedName: 'Publisher country',
                                                                        values: currentDataset.publisher?.country ? [topicsDictionary.find(f => f.key === currentDataset.publisher?.country)?.value ?? e] : ['-']
                                                                    },
                                                                    {
                                                                        object: currentDataset.publisher,
                                                                        key: 'url',
                                                                        normalizedName: 'Publisher url'
                                                                    },
                                                                    {
                                                                        object: currentDataset.publisher,
                                                                        key: 'description',
                                                                        normalizedName: 'Publisher description'
                                                                    },
                                                                ]}
                                                            />
                                                        </Flex>
                                                    )
                                                } else return <></>;
                                            })}
                                        </Flex>
                                    }

                                    {tab === 'Crypto wallets' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{('Crypto wallets')}</Typography>

                                            {!(result.properties.cryptoWallets && result.properties.cryptoWallets.length > 0) &&
                                                <Typography variant={'subtitle1'}>{('No crypto wallets found')}</Typography>
                                            }

                                            {result.properties.cryptoWallets && result.properties.cryptoWallets.map(e => {
                                                return(
                                                    <Flex direction={'column'} gap={'8px'}>
                                                        <Typography
                                                            variant={'subtitle2'}>{e.caption}</Typography>

                                                        <CommonTable
                                                            configs={[
                                                                {object: e.properties, key: 'publicKey', normalizedName: 'Public key'},
                                                                {object: e.properties, key: 'alias'},
                                                                {object: e.properties, key: 'currency'},
                                                                {object: e.properties, key: 'balance'},
                                                                {
                                                                    object: result.properties,
                                                                    key: 'createdAt',
                                                                    normalizedName: 'Created',
                                                                    valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : '-', false, true)
                                                                },
                                                                {
                                                                    object: result.properties,
                                                                    key: 'modifiedAt',
                                                                    normalizedName: 'Modified',
                                                                    valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : '-', false, true)
                                                                },
                                                                {object: result.properties, key: 'amountUsd', normalizedName: 'Amount USD'},
                                                                {
                                                                    object: result.properties,
                                                                    key: 'topics',
                                                                    normalizedName: 'Topics',
                                                                    values: (result.properties.topics ?? []).map(e => topicsDictionary.find(f => f.key === e)?.value ?? e)
                                                                },
                                                            ]}
                                                        />
                                                    </Flex>
                                                )
                                            })}
                                        </Flex>
                                    }
                                </>
                            }

                            {isCorporate &&
                                <>
                                    {tab === 'General info' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'} color={colors.text.grey_dark}>{('General info')}</Typography>

                                            <CommonTable
                                                configs={[
                                                    {object: result.properties, key: 'topics', normalizedName: 'Topics', values: (result.properties.topics ?? []).map(e => topicsDictionary.find(f => f.key === e)?.value ?? e)},
                                                    {object: result.properties, key: 'incorporationDate', normalizedName: 'Incorporation date'},
                                                    {object: result.properties, key: 'legalForm', normalizedName: 'Legal form'},
                                                    {object: result.properties, key: 'registrationNumber', normalizedName: 'Registration number'},
                                                    {object: result.properties, key: 'taxNumber', normalizedName: 'Tax Number'},
                                                    {object: result.properties, key: 'idNumber', normalizedName: 'ID Number'},
                                                    {object: result.properties, key: 'mainCountry', normalizedName: 'Country of origin', values: (result.properties.mainCountry ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)},
                                                    {object: result.properties, key: 'country', normalizedName: 'Country', values: (result.properties.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)},
                                                    {object: result.properties, key: 'jurisdiction', normalizedName: 'Jurisdiction', values: (result.properties.jurisdiction ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)},
                                                    {object: result.properties, key: 'website', normalizedName: 'Website'},
                                                    {object: result.properties, key: 'phone', normalizedName: 'Phone'},
                                                    {object: result.properties, key: 'email', normalizedName: 'E-Mail'},
                                                    {object: result.properties, key: 'program', normalizedName: 'Program'},
                                                    {object: result.properties, key: 'createdAt', normalizedName: 'Created at'},
                                                    {object: result.properties, key: 'modifiedAt', normalizedName: 'Modified on'},
                                                    {object: result.properties, key: 'retrievedAt', normalizedName: 'Retrieved on'},
                                                    {object: result.properties, key: 'amount', normalizedName: 'Amount'},
                                                    {object: result.properties, key: 'currency', normalizedName: 'Currency'},
                                                    {object: result.properties, key: 'amountUsd', normalizedName: 'Amount in USD'},
                                                    {object: result.properties, key: 'amountEur', normalizedName: 'Amount in EUR'},
                                                    {object: result.properties, key: 'ticker', normalizedName: 'Stock ticker symbol'},
                                                    {object: result.properties, key: 'dissolutionDate', normalizedName: 'Dissolution date'},
                                                    {object: result.properties, key: 'keywords', normalizedName: 'Keywords'},
                                                    {object: result.properties, key: 'wikipediaUrl', normalizedName: 'Wikipedia Article'},
                                                    {object: result.properties, key: 'wikidataId', normalizedName: 'Wikidata ID'},
                                                    {object: result.properties, key: 'sourceUrl', normalizedName: 'Source link'},
                                                    {object: result.properties, key: 'publisher', normalizedName: 'Publishing source'},
                                                    {object: result.properties, key: 'publisherUrl', normalizedName: 'Publishing source URL'},
                                                    {object: result.properties, key: 'sector', normalizedName: 'Sector'},
                                                    {object: result.properties, key: 'classification', normalizedName: 'Classification'},
                                                    {object: result.properties, key: 'vatCode', normalizedName: 'V.A.T. Identifier'},
                                                    {object: result.properties, key: 'taxStatus', normalizedName: 'Tax status'},
                                                    {object: result.properties, key: 'status', normalizedName: 'Status'},
                                                    {object: result.properties, key: 'opencorporatesUrl', normalizedName: 'OpenCorporates URL'},
                                                    {object: result.properties, key: 'bvdId', normalizedName: 'Bureau van Dijk ID'},
                                                    {object: result.properties, key: 'icijId', normalizedName: 'ICIJ ID'},
                                                    {object: result.properties, key: 'okpoCode', normalizedName: 'OKPO'},
                                                    {object: result.properties, key: 'innCode', normalizedName: 'INN'},
                                                    {object: result.properties, key: 'ogrnCode', normalizedName: 'OGRN'},
                                                    {object: result.properties, key: 'leiCode', normalizedName: 'LEI'},
                                                    {object: result.properties, key: 'dunsCode', normalizedName: 'D-U-N-S'},
                                                    // {object: result.properties, key: 'identification', normalizedName: 'Identifications'},
                                                    {object: result.properties, key: 'capital', normalizedName: 'Capital'},
                                                    {object: result.properties, key: 'swiftBic', normalizedName: 'SWIFT/BIC'},
                                                    {object: result.properties, key: 'voenCode', normalizedName: 'VOEN'},
                                                    {object: result.properties, key: 'coatoCode', normalizedName: 'COATO / SOATO / OKATO'},
                                                    {object: result.properties, key: 'irsCode', normalizedName: 'IRS Number'},
                                                    {object: result.properties, key: 'ipoCode', normalizedName: 'IPO'},
                                                    {object: result.properties, key: 'cikCode', normalizedName: 'SEC Central Index Key'},
                                                    {object: result.properties, key: 'jibCode', normalizedName: 'JIB'},
                                                    {object: result.properties, key: 'mbsCode', normalizedName: 'MBS'},
                                                    {object: result.properties, key: 'ibcRuc', normalizedName: 'ibcRUC'},
                                                    {object: result.properties, key: 'caemCode', normalizedName: 'COD CAEM'},
                                                    {object: result.properties, key: 'kppCode', normalizedName: 'KPP'},
                                                    {object: result.properties, key: 'okvedCode', normalizedName: 'OKVED(2) Classifier'},
                                                    {object: result.properties, key: 'okopfCode', normalizedName: 'OKOPF'},
                                                    {object: result.properties, key: 'fnsCode', normalizedName: 'Federal tax service code'},
                                                    {object: result.properties, key: 'fssCode', normalizedName: 'FSS'},
                                                    {object: result.properties, key: 'bikCode', normalizedName: 'BIK'},
                                                    {object: result.properties, key: 'pfrNumber', normalizedName: 'PFR Number'},
                                                    {object: result.properties, key: 'oksmCode', normalizedName: 'OKSM'},
                                                    {object: result.properties, key: 'isinCode', normalizedName: 'ISIN'},
                                                    {object: result.properties, key: 'summary', normalizedName: 'Summary'},
                                                    {object: result.properties, key: 'description', normalizedName: 'Description'}
                                                ]}
                                            />
                                        </Flex>
                                    }

                                    {tab === 'Name and aliases' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'} color={colors.text.grey_dark}>{('Name and aliases')}</Typography>

                                            <CommonTable
                                                configs={[
                                                    {object: result, key: 'caption'},
                                                    {object: result.properties, key: 'name'},
                                                    {object: result.properties, key: 'alias'},
                                                    {object: result.properties, key: 'previousName', normalizedName: 'Previous Name'},
                                                    {object: result.properties, key: 'weakAlias', normalizedName: 'Weak alias'},
                                                ]}
                                            />
                                        </Flex>
                                    }

                                    {tab === 'Addresses' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'} color={colors.text.grey_dark}>{('Addresses')}</Typography>

                                            {!(result.properties.addressEntity && result.properties.addressEntity.length > 0) &&
                                                <Typography variant={'subtitle1'}>{('No address found')}</Typography>
                                            }

                                            {result.properties && result.properties.addressEntity && result.properties.addressEntity.map(e => (
                                                <Flex direction={'column'} gap={'8px'} m={'32px 0 0 0'}>
                                                    <Typography variant={'subtitle2'}>{e.caption}</Typography>

                                                    <CommonTable
                                                        configs={[
                                                            {object: e.properties, key: 'full', normalizedName: 'Full address'},
                                                            {object: e.properties, key: 'street', normalizedName: 'Street address'},
                                                            {object: e.properties, key: 'country', values: (e.properties.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)},
                                                        ]}
                                                    />
                                                </Flex>
                                            ))}
                                        </Flex>
                                    }

                                    {tab === 'Notes' && result &&
                                        <Flex>
                                            <Typography variant={'bodySemiBold'} color={colors.text.grey_dark}>{('Notes')}</Typography>

                                            {result.properties && result.properties.notes && result.properties.notes.length > 0 ?
                                                <SingleColumnTable values={result.properties.notes} />
                                                :
                                                <Typography variant={'subtitle1'}>{('No notes found')}</Typography>
                                            }
                                        </Flex>
                                    }

                                    {tab === 'Sanctions' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'} color={colors.text.grey_dark}>{('Sanctions')}</Typography>

                                            {result.properties && result.properties.sanctions && result.properties.sanctions.length > 0 ?
                                                <>
                                                    {result.properties.sanctions.map(e => (
                                                        <Flex direction={'column'} gap={'8px'}>
                                                            <Typography variant={'subtitle2'}>{e.caption}</Typography>

                                                            <CommonTable
                                                                configs={[
                                                                    {object: e.properties, key: 'authority'},
                                                                    {object: e.properties, key: 'program'},
                                                                    {object: e.properties, key: 'sourceUrl'},
                                                                    {object: e.properties, key: 'country', values: (e.properties.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)},
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'listingDate',
                                                                        normalizedName: 'Listing date'
                                                                    },
                                                                    {object: e.properties, key: 'duration'},
                                                                    {object: e.properties, key: 'status'},
                                                                    {object: e.properties, key: 'reason'},
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'provisions',
                                                                        normalizedName: 'Scope of sanctions'
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'unscId',
                                                                        normalizedName: 'UN SC identifier'
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'authorityId',
                                                                        normalizedName: 'Authority-issued identifier'
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'startDate',
                                                                        normalizedName: 'Start date'
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'endDate',
                                                                        normalizedName: 'End date'
                                                                    },
                                                                    {object: e.properties, key: 'date'},
                                                                    {object: e.properties, key: 'summary'},
                                                                    {object: e.properties, key: 'description'},
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'recordId',
                                                                        normalizedName: 'Record ID'
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'publisher',
                                                                        normalizedName: 'Publishing source'
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'publisherUrl',
                                                                        normalizedName: 'Publishing source URL'
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'modifiedAt',
                                                                        normalizedName: 'Modified on'
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'retrievedAt',
                                                                        normalizedName: 'Retrieved on'
                                                                    },
                                                                ]}
                                                            />
                                                        </Flex>
                                                    ))}
                                                </>
                                                :
                                                <Typography variant={'subtitle1'}>{('No sanction found')}</Typography>
                                            }
                                        </Flex>
                                    }

                                    {tab === 'Owners' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'} color={colors.text.grey_dark}>{('Owners')}</Typography>

                                            {result.properties && result.properties.ownershipAsset && result.properties.ownershipAsset.length > 0 ?
                                                <>
                                                    {result.properties.ownershipAsset.map(e => (
                                                        <Flex direction={'column'} gap={'8px'}>
                                                            <Typography variant={'subtitle2'}>{(e.properties?.owner ?? [])[0]?.caption ?? '-'}</Typography>
                                                            <Typography variant={'subtitle1'}>Start date: {parseDateAuto((e.properties?.startDate ?? [])[0], false, true)}</Typography>

                                                            <CommonTable
                                                                configs={[
                                                                    {object: (e.properties?.owner ?? [])[0]?.properties, key: 'name'},
                                                                    {object: (e.properties?.owner ?? [])[0]?.properties, key: 'alias'},
                                                                    {object: (e.properties?.owner ?? [])[0]?.properties, key: 'jurisdiction'},
                                                                    {object: (e.properties?.owner ?? [])[0]?.properties, key: 'jurisdiction', normalizedName: 'Jurisdiction', values: ((e.properties?.owner ?? [])[0]?.properties.jurisdiction ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)},
                                                                    {object: (e.properties?.owner ?? [])[0]?.properties, key: 'incorporationDate', normalizedName: 'Incorporation date'},
                                                                    {object: (e.properties?.owner ?? [])[0]?.properties, key: 'address'},
                                                                    {object: (e.properties?.owner ?? [])[0]?.properties, key: 'status'},
                                                                    {object: (e.properties?.owner ?? [])[0]?.properties, key: 'sourceUrl'},
                                                                ]}
                                                            />
                                                        </Flex>
                                                    ))}
                                                </>
                                                :
                                                <Typography variant={'subtitle1'}>{('No owners found')}</Typography>
                                            }
                                        </Flex>
                                    }

                                    {tab === 'Subsidiaries' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'} color={colors.text.grey_dark}>{('Subsidiaries')}</Typography>

                                            {result.properties && result.properties.ownershipOwner && result.properties.ownershipOwner.length > 0 ?
                                                <>
                                                    {result.properties.ownershipOwner.map(e => (
                                                        <Flex direction={'column'} gap={'8px'}>
                                                            <Typography variant={'subtitle2'}>{((e.properties.asset ?? [])[0] ?? []).caption ?? '-'}</Typography>

                                                            <CommonTable
                                                                configs={[
                                                                    {object: (e.properties.asset ?? [])[0].properties, key: 'name'},
                                                                    {object: (e.properties.asset ?? [])[0].properties, key: 'alias'},
                                                                    {object: (e.properties?.asset ?? [])[0]?.properties, key: 'jurisdiction', normalizedName: 'Jurisdiction', values: ((e.properties?.asset ?? [])[0]?.properties.jurisdiction ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)},
                                                                    {object: (e.properties.asset ?? [])[0].properties, key: 'incorporationDate', normalizedName: 'Incorporation date'},
                                                                    {object: (e.properties.asset ?? [])[0].properties, key: 'address', values: ((e.properties.asset ?? [])[0].properties.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)},
                                                                    {object: (e.properties.asset ?? [])[0].properties, key: 'status'},
                                                                    {object: (e.properties.asset ?? [])[0].properties, key: 'sourceUrl', normalizedName: 'Source link'},
                                                                ]}
                                                            />
                                                        </Flex>
                                                    ))}
                                                </>
                                                :
                                                <Typography variant={'subtitle1'}>{('No subsidiaries found')}</Typography>
                                            }
                                        </Flex>
                                    }

                                    {tab === 'Clients' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'} color={colors.text.grey_dark}>{('Clients')}</Typography>

                                            {result.properties && result.properties.agencyClient && result.properties.agencyClient.length > 0 ?
                                                <>
                                                    {result.properties.agencyClient.map(e => (
                                                        e.properties.client && e.properties.client?.length > 0 ?
                                                            <Flex direction={'column'} gap={'8px'}>
                                                                <Typography variant={'subtitle2'}>{e.properties.client[0].caption ?? '-'}</Typography>

                                                                <CommonTable
                                                                    configs={[
                                                                        {object: e.properties.client[0].properties, key: 'name'},
                                                                        {object: e.properties.client[0].properties, key: 'alias'},
                                                                        {object: e.properties.client[0].properties, key: 'jurisdiction', normalizedName: 'Jurisdiction', values: (e.properties.client[0].properties.jurisdiction ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)},
                                                                        {object: e.properties.client[0].properties, key: 'incorporationDate', normalizedName: 'Incorporation date'},
                                                                        {object: e.properties.client[0].properties, key: 'address', values: (e.properties.client[0].properties.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)},
                                                                        {object: e.properties.client[0].properties, key: 'status'},
                                                                        {object: e.properties.client[0].properties, key: 'sourceUrl', normalizedName: 'Source link'},
                                                                    ]}
                                                                />
                                                            </Flex>
                                                            :
                                                            <></>
                                                    ))}
                                                </>
                                                :
                                                <Typography variant={'subtitle1'}>{('No clients found')}</Typography>
                                            }
                                        </Flex>
                                    }

                                    {tab === 'Securities' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'} color={colors.text.grey_dark}>{('Securities')}</Typography>

                                            {result.properties && result.properties.securities && result.properties.securities.length > 0 ?
                                                <>
                                                    {result.properties.securities.map(e => (
                                                        <Flex direction={'column'} gap={'8px'}>
                                                            <Typography variant={'subtitle2'}>{e.caption ?? '-'}</Typography>

                                                            <CommonTable
                                                                configs={[
                                                                    {object: e.properties, key: 'isin', normalizedName: 'ISIN'},
                                                                    {object: e.properties, key: 'registrationNumber', normalizedName: 'Registration number'},
                                                                    {object: e.properties, key: 'ticker', normalizedName: 'Stock ticker symbol'},
                                                                    {object: e.properties, key: 'issueDate', normalizedName: 'Date issued'},
                                                                    {object: e.properties, key: 'maturityDate', normalizedName: 'Maturity date'},
                                                                    {object: e.properties, key: 'type'},
                                                                    {object: e.properties, key: 'classification'},
                                                                    {object: e.properties, key: 'name'},
                                                                    {object: e.properties, key: 'country',values: (e.properties.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)},
                                                                    {object: e.properties, key: 'sourceUrl', normalizedName: 'Source link'},
                                                                    {object: e.properties, key: 'createdAt', normalizedName: 'created at'},
                                                                    {object: e.properties, key: 'amount'},
                                                                    {object: e.properties, key: 'currency'},
                                                                ]}
                                                            />
                                                        </Flex>
                                                    ))}
                                                </>
                                                :
                                                <Typography variant={'subtitle1'}>{('No securities found')}</Typography>
                                            }
                                        </Flex>
                                    }

                                    {tab === 'Links' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'} color={colors.text.grey_dark}>{('Links')}</Typography>

                                            {!(result.properties &&
                                                    ((result.properties.unknownLinkFrom &&
                                                            result.properties.unknownLinkFrom.length > 0) ||
                                                        (result.properties.unknownLinkTo &&
                                                            result.properties.unknownLinkTo.length > 0))) &&
                                                <Typography variant={'subtitle1'}>{('No links found')}</Typography>
                                            }

                                            {result.properties && result.properties.unknownLinkFrom && result.properties.unknownLinkFrom.map(e => (
                                                <Flex direction={'column'} gap={'8px'}>
                                                    <Typography variant={'subtitle2'}>{(e.properties?.subject ?? [])[0].caption ?? '-'}</Typography>

                                                    <CommonTable
                                                        configs={[
                                                            {object: (e.properties?.subject ?? [])[0].properties, key: 'name'},
                                                            {object: (e.properties?.subject ?? [])[0].properties, key: 'alias'},
                                                            {object: result.properties, key: 'topics', values: ((e.properties?.subject ?? [])[0].properties?.topics ?? []).map(e => topicsDictionary.find(f => f.key === e)?.value ?? e)},
                                                            {object: (e.properties?.subject ?? [])[0].properties, key: 'notes'},
                                                            {object: (e.properties?.subject ?? [])[0].properties, key: 'sourceUrl', normalizedName: 'Source link'},
                                                            {object: (e.properties?.subject ?? [])[0].properties, key: 'datasets'},
                                                        ]}
                                                    />
                                                </Flex>
                                            ))}

                                            {result.properties && result.properties.unknownLinkTo && result.properties.unknownLinkTo.map(e => (
                                                <Flex direction={'column'} gap={'8px'}>
                                                    <Typography variant={'subtitle2'}>{(e.properties?.object ?? [])[0].caption ?? '-'}</Typography>

                                                    <CommonTable
                                                        configs={[
                                                            {object: (e.properties?.object ?? [])[0].properties, key: 'name'},
                                                            {object: (e.properties?.object ?? [])[0].properties, key: 'alias'},
                                                            {object: result.properties, key: 'topics', values: ((e.properties?.object ?? [])[0].properties?.topics ?? []).map(e => topicsDictionary.find(f => f.key === e)?.value ?? e)},
                                                            {object: (e.properties?.object ?? [])[0].properties, key: 'notes'},
                                                            {object: (e.properties?.object ?? [])[0].properties, key: 'sourceUrl', normalizedName: 'Source link'},
                                                            {object: (e.properties?.object ?? [])[0].properties, key: 'datasets'},
                                                        ]}
                                                    />
                                                </Flex>
                                            ))}
                                        </Flex>
                                    }

                                    {tab === 'Datasets' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'} color={colors.text.grey_dark}>{('Datasets')}</Typography>

                                            {!(result.datasets && result.datasets.length > 0) &&
                                                <Typography variant={'subtitle1'}>{('No datasets found')}</Typography>
                                            }

                                            {result.datasets && result.datasets.map(e => {
                                                const currentDataset = openSanctionsDataset.datasets.find((f: any) => f.name === e);
                                                if(currentDataset){
                                                    return(
                                                        <Flex direction={'column'} gap={'8px'}>
                                                            <Typography variant={'subtitle2'}>{currentDataset.title}</Typography>

                                                            <CommonTable
                                                                configs={[
                                                                    {object: currentDataset, key: 'url'},
                                                                    {object: currentDataset, key: 'summary'},
                                                                    {object: currentDataset, key: 'description'},
                                                                    {object: currentDataset, key: 'name', normalizedName: 'Publisher name'},
                                                                    {object: currentDataset.publisher, key: 'country', normalizedName: 'Publisher country', values:  currentDataset.publisher?.country ? [countryDict.find(f => f.key === currentDataset.publisher?.country)?.value ?? e] : ['-']},
                                                                    {object: currentDataset.publisher, key: 'url', normalizedName: 'Publisher url'},
                                                                    {object: currentDataset.publisher, key: 'description', normalizedName: 'Publisher description'},
                                                                ]}
                                                            />
                                                        </Flex>
                                                    )
                                                }else return <></>;
                                            })}
                                        </Flex>
                                    }

                                    {tab === 'Crypto wallets' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{('Crypto wallets')}</Typography>

                                            {!(result.properties.cryptoWallets && result.properties.cryptoWallets.length > 0) &&
                                                <Typography variant={'subtitle1'}>{('No crypto wallets found')}</Typography>
                                            }

                                            {result.properties.cryptoWallets && result.properties.cryptoWallets.map(e => {
                                                return(
                                                    <Flex direction={'column'} gap={'8px'}>
                                                        <Typography
                                                            variant={'subtitle2'}>{e.caption}</Typography>

                                                        <CommonTable
                                                            configs={[
                                                                {object: e.properties, key: 'publicKey', normalizedName: 'Public key'},
                                                                {object: e.properties, key: 'alias'},
                                                                {object: e.properties, key: 'currency'},
                                                                {object: e.properties, key: 'balance'},
                                                                {
                                                                    object: result.properties,
                                                                    key: 'createdAt',
                                                                    normalizedName: 'Created',
                                                                    valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : '-', false, true)
                                                                },
                                                                {
                                                                    object: result.properties,
                                                                    key: 'modifiedAt',
                                                                    normalizedName: 'Modified',
                                                                    valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : '-', false, true)
                                                                },
                                                                {object: result.properties, key: 'amountUsd', normalizedName: 'Amount USD'},
                                                                {
                                                                    object: result.properties,
                                                                    key: 'topics',
                                                                    normalizedName: 'Topics',
                                                                    values: (result.properties.topics ?? []).map(e => topicsDictionary.find(f => f.key === e)?.value ?? e)
                                                                },
                                                            ]}
                                                        />
                                                    </Flex>
                                                )
                                            })}
                                        </Flex>
                                    }
                                </>
                            }

                            {isCryptoWallet &&
                                <>
                                    {tab === 'Crypto wallet info' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{('Crypto wallet info')}</Typography>
                                            <CommonTable
                                                configs={[
                                                    {object: result.properties, key: 'publicKey', normalizedName: 'Public key'},
                                                    {object: result.properties, key: 'alias'},
                                                    {object: result.properties, key: 'currency'},
                                                    {object: result.properties, key: 'balance'},
                                                    {
                                                        object: result.properties,
                                                        key: 'createdAt',
                                                        normalizedName: 'Created',
                                                        valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : '-', false, true)
                                                    },
                                                    {
                                                        object: result.properties,
                                                        key: 'modifiedAt',
                                                        normalizedName: 'Modified',
                                                        valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : '-', false, true)
                                                    },
                                                    {object: result.properties, key: 'amountUsd', normalizedName: 'Amount USD'},
                                                    {
                                                        object: result.properties,
                                                        key: 'topics',
                                                        normalizedName: 'Topics',
                                                        values: (result.properties.topics ?? []).map(e => topicsDictionary.find(f => f.key === e)?.value ?? e)
                                                    },
                                                ]}
                                            />
                                        </Flex>
                                    }

                                    {tab === 'Crypto wallet datasets' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{('Crypto wallet datasets')}</Typography>

                                            {!(result.datasets && result.datasets.length > 0) &&
                                                <Typography variant={'subtitle1'}>{('No datasets found')}</Typography>
                                            }

                                            {result.datasets && result.datasets.map(e => {
                                                const currentDataset = openSanctionsDataset.datasets.find((f: any) => f.name === e);
                                                if (currentDataset) {
                                                    return (
                                                        <Flex direction={'column'} gap={'8px'}>
                                                            <Typography
                                                                variant={'subtitle2'}>{currentDataset.title}</Typography>

                                                            <CommonTable
                                                                configs={[
                                                                    {object: currentDataset, key: 'url'},
                                                                    {object: currentDataset, key: 'summary'},
                                                                    {object: currentDataset, key: 'description'},
                                                                    {
                                                                        object: currentDataset,
                                                                        key: 'name',
                                                                        normalizedName: 'Publisher name'
                                                                    },
                                                                    {
                                                                        object: currentDataset.publisher,
                                                                        key: 'country',
                                                                        normalizedName: 'Publisher country',
                                                                        values: currentDataset.publisher?.country ? [topicsDictionary.find(f => f.key === currentDataset.publisher?.country)?.value ?? e] : ['-']
                                                                    },
                                                                    {
                                                                        object: currentDataset.publisher,
                                                                        key: 'url',
                                                                        normalizedName: 'Publisher url'
                                                                    },
                                                                    {
                                                                        object: currentDataset.publisher,
                                                                        key: 'description',
                                                                        normalizedName: 'Publisher description'
                                                                    },
                                                                ]}
                                                            />
                                                        </Flex>
                                                    )
                                                } else return <></>;
                                            })}
                                        </Flex>
                                    }

                                    {tab === 'Holder info' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{('Holder info')}</Typography>
                                            <CommonTable
                                                configs={[
                                                    {object: result.properties.holder?.properties, key: 'topics', normalizedName: 'Topics', values: (result.properties.holder?.properties.topics ?? []).map(e => topicsDictionary.find(f => f.key === e)?.value ?? e)},
                                                    {object: result.properties.holder, key: 'caption'},
                                                    {object: result.properties.holder?.properties, key: 'birthDate', normalizedName: 'Birth date', valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : '-', false, true)},
                                                    {object: result.properties.holder?.properties, key: 'birthPlace', normalizedName: 'Place of birth'},
                                                    {object: result.properties.holder?.properties, key: 'nationality', values: result.properties.holder?.properties.nationality ? (result.properties.nationality ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e) : ['-']},
                                                    {object: result.properties.holder?.properties, key: 'passportNumber', normalizedName: 'Passport number'},
                                                    {object: result.properties.holder?.properties, key: 'previousName', normalizedName: 'Previous name'},
                                                    {object: result.properties.holder?.properties, key: 'email'},
                                                    {object: result.properties.holder?.properties, key: 'phone'},
                                                    {object: result.properties.holder?.properties, key: 'idNumber', normalizedName: 'ID Number'},
                                                    {object: result.properties.holder?.properties, key: 'innCode', normalizedName: 'INN'},
                                                    {object: result.properties.holder?.properties, key: 'birthCountry', normalizedName: 'Country of birth', values: result.properties.holder?.properties.birthCountry ? (result.properties.holder?.properties.birthCountry ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e) : ['-']}, //
                                                    {object: result.properties.holder?.properties, key: 'country', values: result.properties.holder?.properties.country ? (result.properties.holder?.properties.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e) : ['-']}, //
                                                    {object: result.properties.holder?.properties, key: 'taxNumber', normalizedName: 'Tax number'},
                                                    {object: result.properties.holder?.properties, key: 'vatCode', normalizedName: 'V.A.T Identifier'},
                                                    {object: result.properties.holder?.properties, key: 'deathDate', normalizedName: 'Death date', valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : '-', false, true)},
                                                    {object: result.properties.holder?.properties, key: 'religion'},
                                                    {object: result.properties.holder?.properties, key: 'political', normalizedName: 'Political association'},
                                                    {object: result.properties.holder?.properties, key: 'keywords'},
                                                    {object: result.properties.holder?.properties, key: 'summary'}
                                                ]}
                                            />
                                        </Flex>
                                    }

                                    {tab === 'Name and aliases' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{('Name and aliases')}</Typography>
                                            <CommonTable
                                                configs={[
                                                    {object: result.properties.holder, key: 'caption'},
                                                    {object: result.properties.holder?.properties, key: 'name'},
                                                    {object: result.properties.holder?.properties, key: 'title'},
                                                    {object: result.properties.holder?.properties, key: 'firstName', normalizedName: 'First name'},
                                                    {object: result.properties.holder?.properties, key: 'secondName', normalizedName: 'Second name'},
                                                    {object: result.properties.holder?.properties, key: 'middleName', normalizedName: 'Middle name'},
                                                    {object: result.properties.holder?.properties, key: 'fatherName', normalizedName: 'Father name'},
                                                    {object: result.properties.holder?.properties, key: 'lastName', normalizedName: 'Last name'},
                                                    {object: result.properties.holder?.properties, key: 'nameSuffix', normalizedName: 'Name suffix'},
                                                    {object: result.properties.holder?.properties, key: 'alias'},
                                                    {object: result.properties.holder?.properties, key: 'motherName', normalizedName: 'Matronymic'},
                                                    {object: result.properties.holder?.properties, key: 'weakAlias', normalizedName: 'Weak alias'},
                                                ]}
                                            />
                                        </Flex>
                                    }

                                    {tab === 'Identification' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{('Identification')}</Typography>

                                            {result.properties.holder && result.properties.holder?.properties.identification && result.properties.holder?.properties.identification.length > 0 ?
                                                <>
                                                    {result.properties.holder && result.properties.holder?.properties.identification && result.properties.holder?.properties.identification.map(e => (
                                                        <Flex direction={'column'} gap={'8px'} m={'32px 0 0 0'}>
                                                            <Typography variant={'subtitle2'}>{e.caption}</Typography>

                                                            {e.schema === 'Identification' &&
                                                                <CommonTable
                                                                    configs={[
                                                                        {object: e.properties, key: 'type'},
                                                                        {object: e.properties, key: 'number'},
                                                                        {
                                                                            object: e.properties,
                                                                            key: 'country',
                                                                            values: (e.properties.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                                        },
                                                                        {
                                                                            object: e.properties,
                                                                            key: 'startDate',
                                                                            normalizedName: 'Start date'
                                                                        },
                                                                        {
                                                                            object: e.properties,
                                                                            key: 'endDate',
                                                                            normalizedName: 'End date',
                                                                            valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : '-', false, true)
                                                                        },
                                                                        {object: e.properties, key: 'authority'},
                                                                        {object: e.properties, key: 'summary'},
                                                                    ]}
                                                                />
                                                            }

                                                            {e.schema === 'Passport' &&
                                                                <CommonTable
                                                                    configs={[
                                                                        {object: e.properties, key: 'type'},
                                                                        {object: e.properties, key: 'number'},
                                                                        {
                                                                            object: e.properties,
                                                                            key: 'country',
                                                                            values: (e.properties.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                                        },
                                                                        {
                                                                            object: e.properties,
                                                                            key: 'startDate',
                                                                            normalizedName: 'Start date'
                                                                        },
                                                                        {
                                                                            object: e.properties,
                                                                            key: 'endDate',
                                                                            normalizedName: 'End date',
                                                                            valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : '-', false, true)
                                                                        },
                                                                        {object: e.properties, key: 'summary'},
                                                                    ]}
                                                                />
                                                            }
                                                        </Flex>
                                                    ))}
                                                </>
                                                :
                                                <Typography
                                                    variant={'subtitle1'}>{('No identifications found')}</Typography>
                                            }
                                        </Flex>
                                    }

                                    {tab === 'Position' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{('Position')}</Typography>
                                            {result.properties.holder && result.properties.holder?.properties.position && result.properties.holder?.properties.position.length > 0 ?
                                                <SingleColumnTable values={result.properties.holder?.properties.position}/>
                                                :
                                                <Typography variant={'subtitle1'}>{('No positions found')}</Typography>
                                            }
                                        </Flex>
                                    }

                                    {tab === 'Addresses' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{('Addresses')}</Typography>

                                            {(result.properties.holder &&
                                                    result.properties.holder?.properties.address !== undefined &&
                                                    result.properties.holder?.properties.address?.length > 0) &&
                                                <Flex direction={'column'} gap={'16px'}>
                                                    <Typography variant={'subtitle2'}>Addresses</Typography>
                                                    <SingleColumnTable values={result.properties.holder?.properties.address}/>
                                                </Flex>
                                            }

                                            {!(result.properties.holder?.properties.addressEntity && result.properties.holder?.properties.addressEntity.length > 0) &&
                                                <Typography variant={'subtitle1'}>{('No address found')}</Typography>
                                            }

                                            {result.properties.holder?.properties && result.properties.holder?.properties.addressEntity && result.properties.holder?.properties.addressEntity.map(e => (
                                                <Flex direction={'column'} gap={'8px'} m={'32px 0 0 0'}>
                                                    <Typography variant={'subtitle2'}>{e.caption}</Typography>

                                                    <CommonTable
                                                        configs={[
                                                            {object: e.properties, key: 'city'},
                                                            {object: e.properties, key: 'street'},
                                                            {object: e.properties, key: 'state'},
                                                            {
                                                                object: e.properties,
                                                                key: 'country',
                                                                values: (e.properties.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                            },
                                                            {object: e.properties, key: 'full'},
                                                        ]}
                                                    />
                                                </Flex>
                                            ))}
                                        </Flex>
                                    }

                                    {tab === 'Notes' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{('Notes')}</Typography>
                                            {result.properties.holder && result.properties.holder?.properties.notes && result.properties.holder?.properties.notes.length > 0 ?
                                                <SingleColumnTable values={result.properties.holder?.properties.notes}/>
                                                :
                                                <Typography variant={'subtitle1'}>{('No notes found')}</Typography>
                                            }
                                        </Flex>
                                    }

                                    {tab === 'Sanctions' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{('Sanctions')}</Typography>
                                            {result.properties.holder && result.properties.holder?.properties.sanctions && result.properties.holder?.properties.sanctions.length > 0 ?
                                                <>
                                                    {result.properties.holder?.properties.sanctions.map(e => (
                                                        <Flex direction={'column'} gap={'8px'}>
                                                            <Typography variant={'subtitle2'}>{e.caption}</Typography>

                                                            <CommonTable
                                                                configs={[
                                                                    {object: e.properties, key: 'authority'},
                                                                    {object: e.properties, key: 'program'},
                                                                    {object: e.properties, key: 'sourceUrl'},
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'country',
                                                                        values: (e.properties.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'listingDate',
                                                                        normalizedName: 'Listing date'
                                                                    },
                                                                    {object: e.properties, key: 'duration'},
                                                                    {object: e.properties, key: 'status'},
                                                                    {object: e.properties, key: 'reason'},
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'provisions',
                                                                        normalizedName: 'Scope of sanctions'
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'unscId',
                                                                        normalizedName: 'UN SC identifier'
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'authorityId',
                                                                        normalizedName: 'Authority-issued identifier'
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'startDate',
                                                                        normalizedName: 'Start date'
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'endDate',
                                                                        normalizedName: 'End date'
                                                                    },
                                                                    {object: e.properties, key: 'date'},
                                                                    {object: e.properties, key: 'summary'},
                                                                    {object: e.properties, key: 'description'},
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'recordId',
                                                                        normalizedName: 'Record ID'
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'publisher',
                                                                        normalizedName: 'Publishing source'
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'publisherUrl',
                                                                        normalizedName: 'Publishing source URL'
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'modifiedAt',
                                                                        normalizedName: 'Modified on'
                                                                    },
                                                                    {
                                                                        object: e.properties,
                                                                        key: 'retrievedAt',
                                                                        normalizedName: 'Retrieved on'
                                                                    },
                                                                ]}
                                                            />
                                                        </Flex>
                                                    ))}
                                                </>
                                                :
                                                <Typography variant={'subtitle1'}>{('No sanction found')}</Typography>
                                            }
                                        </Flex>
                                    }

                                    {tab === 'Directorship' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{('Directorship')}</Typography>

                                            {result.properties.holder && result.properties.holder?.properties.directorshipDirector && result.properties.holder?.properties.directorshipDirector.length > 0 ?
                                                <>
                                                    {result.properties.holder?.properties.directorshipDirector.map(e => (
                                                        <Flex direction={'column'} gap={'8px'}>
                                                            <Typography
                                                                variant={'subtitle2'}>{e.caption ?? '-'}</Typography>
                                                            <Typography variant={'subtitle1'}>Start
                                                                date: {parseDateAuto(e.startDate, false, true)}</Typography>
                                                            <Typography variant={'subtitle1'}>End
                                                                date: {parseDateAuto(e.endDate, false, true)}</Typography>
                                                            <Typography
                                                                variant={'subtitle1'}>Organization: {(e.properties?.organization ?? [])[0]?.caption ?? '-'}</Typography>


                                                            <CommonTable
                                                                configs={[
                                                                    {
                                                                        object: (e.properties?.organization ?? [])[0]?.properties,
                                                                        key: 'name'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.organization ?? [])[0]?.properties,
                                                                        key: 'alias'
                                                                    },
                                                                    {object: (e.properties?.organization ?? [])[0]?.properties, key: 'jurisdiction', normalizedName: 'Jurisdiction', values: ((e.properties?.organization ?? [])[0]?.properties.jurisdiction ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)},
                                                                    {
                                                                        object: (e.properties?.organization ?? [])[0]?.properties,
                                                                        key: 'incorporationDate',
                                                                        normalizedName: 'Incorporation date'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.organization ?? [])[0]?.properties,
                                                                        key: 'address'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.organization ?? [])[0]?.properties,
                                                                        key: 'status'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.organization ?? [])[0]?.properties,
                                                                        key: 'sourceUrl'
                                                                    },
                                                                ]}
                                                            />
                                                        </Flex>
                                                    ))}
                                                </>
                                                :
                                                <Typography
                                                    variant={'subtitle1'}>{('No directorship found')}</Typography>
                                            }
                                        </Flex>
                                    }

                                    {tab === 'Education' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{('Education')}</Typography>

                                            {result.properties.holder && result.properties.holder?.properties.education && result.properties.holder?.properties.education?.length > 0 ?
                                                <SingleColumnTable values={result.properties.holder?.properties.education}/>
                                                :
                                                <Typography variant={'subtitle1'}>{('No education found')}</Typography>
                                            }
                                        </Flex>
                                    }
                                    {tab === 'Family' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{('Family')}</Typography>
                                            {(!result.properties.holder ||
                                                    (result.properties.holder?.properties && !result.properties.holder?.properties.familyPerson) ||
                                                    (result.properties.holder && result.properties.holder?.properties.familyPerson && result.properties.holder?.properties.familyPerson.length === 0)) &&
                                                (!result.properties.holder ||
                                                    (result.properties.holder && !result.properties.holder?.properties.familyRelative) ||
                                                    (result.properties.holder && result.properties.holder?.properties.familyRelative && result.properties.holder?.properties.familyRelative.length === 0)) &&
                                                <Typography
                                                    variant={'subtitle1'}>{('No family records found')}</Typography>
                                            }

                                            {result.properties.holder &&
                                                result.properties.holder?.properties.familyPerson &&
                                                result.properties.holder?.properties.familyPerson.length > 0 &&
                                                result.properties.holder?.properties.familyPerson.map(e => (
                                                    <Flex direction={'column'} gap={'8px'}>
                                                        <Typography
                                                            variant={'subtitle2'}>{(e.properties?.relative ?? [])[0]?.caption ?? '-'}</Typography>

                                                        {(e.properties?.relative ?? [])[0]?.properties !== null && (e.properties?.relative ?? [])[0]?.properties !== undefined &&
                                                            <CommonTable
                                                                configs={[
                                                                    {object: e.properties, key: 'relationship'},
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'birthDate',
                                                                        normalizedName: 'Birth date',
                                                                        valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : '-', false, true)
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'birthPlace',
                                                                        normalizedName: 'Birth place',
                                                                        valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : '-', false, true)
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'gender'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'position'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'notes'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'nationality',
                                                                        values: ((e.properties?.relative ?? [])[0]?.properties?.nationality ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'name'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'alias'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'firstName',
                                                                        normalizedName: 'First name'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'lastName',
                                                                        normalizedName: 'Last name'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'fatherName',
                                                                        normalizedName: 'Patronymic'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'sourceUrl',
                                                                        normalizedName: 'Source link'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'country',
                                                                        values: ((e.properties?.relative ?? [])[0]?.properties?.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'taxNumber',
                                                                        normalizedName: 'Tax number'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'religion'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'status'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'website'
                                                                    },
                                                                    {
                                                                        object: (e.properties?.relative ?? [])[0]?.properties,
                                                                        key: 'keywords',
                                                                        normalizedName: 'Key words'
                                                                    },
                                                                ]}
                                                            />
                                                        }
                                                    </Flex>
                                                ))}

                                            {result.properties.holder && result.properties.holder?.properties.familyRelative && result.properties.holder?.properties.familyRelative.length > 0 && result.properties.holder?.properties.familyRelative.map(e => (
                                                <Flex direction={'column'} gap={'8px'}>
                                                    <Typography
                                                        variant={'subtitle2'}>{(e.properties?.person ?? [])[0]?.caption ?? '-'}</Typography>
                                                    {/*(e.properties?.relative ?? e.properties?.familyRelative ?? [])*/}
                                                    {(e.properties?.person ?? [])[0]?.properties !== null &&
                                                        <CommonTable
                                                            configs={[
                                                                {object: e.properties, key: 'relationship'},
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'birthDate',
                                                                    normalizedName: 'Birth date',
                                                                    valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : '-', false, true)
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'birthPlace',
                                                                    normalizedName: 'Birth place'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'gender'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'position'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'notes'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'nationality',
                                                                    values: ((e.properties?.person ?? [])[0].properties.nationality ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'name'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'alias'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'firstName',
                                                                    normalizedName: 'First name'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'lastName',
                                                                    normalizedName: 'Last name'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'fatherName',
                                                                    normalizedName: 'Patronymic'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'sourceUrl',
                                                                    normalizedName: 'Source link'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'country',
                                                                    values: ((e.properties?.person ?? [])[0].properties.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'taxNumber',
                                                                    normalizedName: 'Tax number'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'religion'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'status'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'website'
                                                                },
                                                                {
                                                                    object: (e.properties?.person ?? [])[0]?.properties,
                                                                    key: 'keywords',
                                                                    normalizedName: 'Key words'
                                                                },

                                                            ]}
                                                        />
                                                    }
                                                </Flex>
                                            ))}
                                        </Flex>
                                    }

                                    {tab === 'Associates' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{('Associates')}</Typography>

                                            {!(result.properties.holder &&
                                                    ((result.properties.holder?.properties.associations &&
                                                            result.properties.holder?.properties.associations.length > 0) ||
                                                        (result.properties.holder?.properties.associates &&
                                                            result.properties.holder?.properties.associates.length > 0))) &&
                                                <Typography variant={'subtitle1'}>{('No associates found')}</Typography>
                                            }

                                            {result.properties.holder && result.properties.holder?.properties.associations && result.properties.holder?.properties.associations.map(e => (
                                                <Flex direction={'column'} gap={'8px'}>
                                                    <Typography
                                                        variant={'subtitle2'}>{(e.properties.person ?? [])[0].caption ?? '-'}</Typography>

                                                    <CommonTable
                                                        configs={[
                                                            {object: e.properties, key: 'relationship'},
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'birthDate',
                                                                normalizedName: 'Birth date',
                                                                valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : '-', false, true)
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'birthPlace',
                                                                normalizedName: 'Birth place'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'gender'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'position'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'notes'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'nationality',
                                                                values: ((e.properties?.person ?? [])[0].properties.nationality ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'name'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'alias'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'firstName',
                                                                normalizedName: 'First name'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'lastName',
                                                                normalizedName: 'Last name'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'fatherName',
                                                                normalizedName: 'Patronymic'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'sourceUrl',
                                                                normalizedName: 'Source link'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'country',
                                                                values: ((e.properties?.person ?? [])[0].properties.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'taxNumber',
                                                                normalizedName: 'Tax number'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'religion'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'status'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'website'
                                                            },
                                                            {
                                                                object: (e.properties.person ?? [])[0].properties,
                                                                key: 'keywords',
                                                                normalizedName: 'Key words'
                                                            },
                                                        ]}
                                                    />
                                                </Flex>
                                            ))}

                                            {result.properties.holder && result.properties.holder?.properties.associates && result.properties.holder?.properties.associates.map(e => (
                                                <Flex direction={'column'} gap={'8px'}>
                                                    <Typography
                                                        variant={'subtitle2'}>{(e.properties.associate ?? [])[0].caption ?? '-'}</Typography>

                                                    <CommonTable
                                                        configs={[
                                                            {object: e.properties, key: 'relationship'},
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'birthDate',
                                                                normalizedName: 'Birth date',
                                                                valueGetter: (obj, key) => Array.isArray(obj[key]) ? obj[key].map((f: string) => parseDateAuto(f, false, true)) : parseDateAuto(obj ? obj[key] : '-', false, true)
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'birthPlace',
                                                                normalizedName: 'Birth place'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'gender'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'position'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'notes'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'nationality',
                                                                values: ((e.properties?.associate ?? [])[0].properties.nationality ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'name'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'alias'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'firstName',
                                                                normalizedName: 'First name'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'lastName',
                                                                normalizedName: 'Last name'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'fatherName',
                                                                normalizedName: 'Patronymic'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'sourceUrl',
                                                                normalizedName: 'Source link'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'country',
                                                                values: ((e.properties?.associate ?? [])[0].properties.country ?? []).map(e => countryDict.find(f => f.key === e)?.value ?? e)
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'taxNumber',
                                                                normalizedName: 'Tax number'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'religion'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'status'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'website'
                                                            },
                                                            {
                                                                object: (e.properties.associate ?? [])[0].properties,
                                                                key: 'keywords',
                                                                normalizedName: 'Key words'
                                                            },
                                                        ]}
                                                    />
                                                </Flex>
                                            ))}
                                        </Flex>
                                    }

                                    {tab === 'Links' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{('Links')}</Typography>

                                            {!(result.properties.holder &&
                                                    ((result.properties.holder?.properties.unknownLinkFrom &&
                                                            result.properties.holder?.properties.unknownLinkFrom.length > 0) ||
                                                        (result.properties.holder?.properties.unknownLinkTo &&
                                                            result.properties.holder?.properties.unknownLinkTo.length > 0))) &&
                                                <Typography variant={'subtitle1'}>{('No links found')}</Typography>
                                            }

                                            {result.properties.holder && result.properties.holder?.properties.unknownLinkFrom && result.properties.holder?.properties.unknownLinkFrom.map(e => (
                                                <Flex direction={'column'} gap={'8px'}>
                                                    <Typography
                                                        variant={'subtitle2'}>{(e.properties?.subject ?? [])[0].caption ?? '-'}</Typography>

                                                    <CommonTable
                                                        configs={[
                                                            {
                                                                object: (e.properties?.subject ?? [])[0].properties,
                                                                key: 'name'
                                                            },
                                                            {
                                                                object: (e.properties?.subject ?? [])[0].properties,
                                                                key: 'alias'
                                                            },
                                                            {
                                                                object: result.properties,
                                                                key: 'topics',
                                                                values: ((e.properties?.subject ?? [])[0].properties?.topics ?? []).map(e => topicsDictionary.find(f => f.key === e)?.value ?? e)
                                                            },
                                                            {
                                                                object: (e.properties?.subject ?? [])[0].properties,
                                                                key: 'notes'
                                                            },
                                                            {
                                                                object: (e.properties?.subject ?? [])[0].properties,
                                                                key: 'sourceUrl',
                                                                normalizedName: 'Source link'
                                                            },
                                                            {
                                                                object: (e.properties?.subject ?? [])[0].properties,
                                                                key: 'datasets'
                                                            },
                                                        ]}
                                                    />
                                                </Flex>
                                            ))}

                                            {result.properties.holder && result.properties.holder?.properties.unknownLinkTo && result.properties.holder?.properties.unknownLinkTo.map(e => (
                                                <Flex direction={'column'} gap={'8px'}>
                                                    <Typography
                                                        variant={'subtitle2'}>{(e.properties?.object ?? [])[0].caption ?? '-'}</Typography>

                                                    <CommonTable
                                                        configs={[
                                                            {
                                                                object: (e.properties?.object ?? [])[0].properties,
                                                                key: 'name'
                                                            },
                                                            {
                                                                object: (e.properties?.object ?? [])[0].properties,
                                                                key: 'alias'
                                                            },
                                                            {
                                                                object: result.properties,
                                                                key: 'topics',
                                                                values: ((e.properties?.object ?? [])[0].properties?.topics ?? []).map(e => topicsDictionary.find(f => f.key === e)?.value ?? e)
                                                            },
                                                            {
                                                                object: (e.properties?.object ?? [])[0].properties,
                                                                key: 'notes'
                                                            },
                                                            {
                                                                object: (e.properties?.object ?? [])[0].properties,
                                                                key: 'sourceUrl',
                                                                normalizedName: 'Source link'
                                                            },
                                                            {
                                                                object: (e.properties?.object ?? [])[0].properties,
                                                                key: 'datasets'
                                                            },
                                                        ]}
                                                    />
                                                </Flex>
                                            ))}
                                        </Flex>
                                    }

                                    {tab === 'Datasets' && result &&
                                        <Flex direction={'column'} gap={'16px'}>
                                            <Typography variant={'bodySemiBold'}
                                                        color={colors.text.grey_dark}>{('Datasets')}</Typography>

                                            {!(result.properties.holder?.datasets && result.properties.holder?.datasets.length > 0) &&
                                                <Typography variant={'subtitle1'}>{('No datasets found')}</Typography>
                                            }

                                            {result.properties.holder?.datasets && result.properties.holder.datasets.map(e => {
                                                const currentDataset = openSanctionsDataset.datasets.find((f: any) => f.name === e);
                                                if (currentDataset) {
                                                    return (
                                                        <Flex direction={'column'} gap={'8px'}>
                                                            <Typography
                                                                variant={'subtitle2'}>{currentDataset.title}</Typography>

                                                            <CommonTable
                                                                configs={[
                                                                    {object: currentDataset, key: 'url'},
                                                                    {object: currentDataset, key: 'summary'},
                                                                    {object: currentDataset, key: 'description'},
                                                                    {
                                                                        object: currentDataset,
                                                                        key: 'name',
                                                                        normalizedName: 'Publisher name'
                                                                    },
                                                                    {
                                                                        object: currentDataset.publisher,
                                                                        key: 'country',
                                                                        normalizedName: 'Publisher country',
                                                                        values: currentDataset.publisher?.country ? [topicsDictionary.find(f => f.key === currentDataset.publisher?.country)?.value ?? e] : ['-']
                                                                    },
                                                                    {
                                                                        object: currentDataset.publisher,
                                                                        key: 'url',
                                                                        normalizedName: 'Publisher url'
                                                                    },
                                                                    {
                                                                        object: currentDataset.publisher,
                                                                        key: 'description',
                                                                        normalizedName: 'Publisher description'
                                                                    },
                                                                ]}
                                                            />
                                                        </Flex>
                                                    )
                                                } else return <></>;
                                            })}
                                        </Flex>
                                    }
                                </>
                            }
                        </Flex>
                    </Flex>
                </Flex>
                {/*{result &&*/}
                {/*    <SyntaxHighlighter customStyle={{direction: 'ltr'}} language="yaml" style={docco}>*/}
                {/*        {JsonToYaml.stringify(JSON.parse(result))}*/}
                {/*    </SyntaxHighlighter>*/}
                {/*}*/}
            </DialogContent>

            <DialogActions sx={{marginTop: '0'}}>
                {isOneTimeScreening ?
                    <BackTextButton revDir={revDir || undefined} onClick={handleClose}>{('Back to screening')}</BackTextButton>
                    :
                    <>
                        <Button onClick={handleClose} variant={'text'} size={'medium'}>{('Close')}</Button>
                        <Button onClick={handleDownload} variant={'text'} size={'medium'} startIcon={<DownloadIcon />}>{('Download')}</Button>
                    </>

                }
            </DialogActions>
        </Dialog>
    )
}

export const TabItem = ({name, onClick, selected}:{name: showFullReportTabs, onClick: (name: showFullReportTabs) => void, selected: boolean}) => {
    return(
        <Flex w={'100%'}
              p={'10px 0 10px 32px'}
              background={selected ? colors.backgrounds.blue_light_1 : undefined}
              hoverStyles={!selected ? `background-color: ${colors.backgrounds.blue_light_2};` : undefined}
              onClick={() => onClick(name)}
              cursor={'pointer'}
        >
            <Typography color={selected ? colors.primary.blue : colors.text.grey_dark} variant={'bodySemiBold'}>{name}</Typography>
        </Flex>
    )
}

export type commonTableConfig<T> = {
    object: T,
    key: keyof T,
    normalizedName?: string,
    valueGetter?: (object: T, key: keyof T) => string | string[],
    values?: string[]
};

export const CommonTable = ({configs}: {configs: commonTableConfig<any>[]}) => {
    return(
        <TableContainer
            sx={{
                width: '100%',
                boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                borderRadius: '8px !important',
                border: `1px solid #EAECF0`,
            }}
        >
            <Table>
                <TableBody>
                    {configs.map((e, id) => {
                        const getValue = ():any => {
                            if(e.values) return e.values;

                            if(e.object){
                                return e.valueGetter ? e.valueGetter(e.object, e.key) : e.object[e.key];
                            }else{
                                return '-';
                            }
                        }
                        const value = getValue();
                        return(
                            <TableRow key={(e.key ?? '-').toString()} sx={{backgroundColor: id % 2 === 0 ? colors.backgrounds.white : colors.backgrounds.grey_light2}}>

                                <TableCell>
                                    <Typography variant={'body2'} color={colors.grayText}>
                                        {e.normalizedName ? e.normalizedName : `${(e.key ?? '-').toString()[0].toUpperCase()}${(e.key ?? '-').toString().toLowerCase().substring(1)}`}
                                    </Typography>
                                </TableCell>

                                <TableCell>
                                    {Array.isArray(value) ?
                                        <Flex direction={'column'} gap={'8px'}>
                                            {value.map(e => (<Typography key={e} variant={'body2'} color={colors.grayText}>{(e ?? '-').toString()}</Typography>))}
                                        </Flex>
                                        :
                                        <Typography variant={'body2'} color={colors.grayText}>{value ?? '-'}</Typography>
                                    }
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export const SingleColumnTable = ({values}:{values: string[] | string}) => {
    return(
        <TableContainer
            sx={{
                width: '100%',
                boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                borderRadius: '8px !important',
                border: `1px solid #EAECF0`,
            }}
        >
            <Table>
                <TableBody>
                    {(Array.isArray(values) ? values : [values]).map((e, id) => {
                        return(
                            <TableRow key={(e ?? '-').toString()} sx={{backgroundColor: id % 2 === 0 ? colors.backgrounds.white : colors.backgrounds.grey_light2}}>
                                <TableCell>
                                    <Typography variant={'body2'} color={colors.grayText}>{(e ?? '-')}</Typography>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
