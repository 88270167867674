import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideDeleteHistoryRowDialog, loadings, selectedEmployee} from "../../../store/slice";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import React from "react";
import {DeleteJobRecord} from "../../../store/actions";

export const DeleteJobRecordDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathHRManagement.dialogs'});
    const {tCommon} = useMainTranslation();
    const dispatch = useDispatch();
    //sel
    const empl = useSelector(selectedEmployee);
    const {isOpen, row} = useSelector(dialogs).deleteHistoryRowDialog;
    const isLoading = useSelector(loadings).deleteJobRecord;

    const handleClose = () => {
        dispatch(hideDeleteHistoryRowDialog())
    }

    const handleSubmit = () => {
        if(empl && row){
            dispatch(DeleteJobRecord({
                data: {
                    id: empl.id,
                    jobRecordId: row.id
                }
            }))
        }
    }

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{'Delete job record'}</DialogTitle>

            <DialogContent>
                <DialogContentText>
                    <Typography  sx={{lineHeight: 'normal'}}>{t("Are you sure you want to delete job record from {{date}} ?", {date: parseDateAuto(row?.startDate)})}</Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions sx={{gap: '16px'}}>
                <Button onClick={handleClose} variant={'text'}>{tCommon('Close')}</Button>
                <LoadingButton onClick={handleSubmit} loading={isLoading}>
                    {tCommon('Delete')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}