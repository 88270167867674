import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useParams} from "react-router";
import {
    deselectTemplate,
    loadings,
    openPreviewTemplate,
    selectedTemplate,
    selectedTemplateCsvHistoryPageInfo,
    templateConfig
} from "../../../store/slice";
import {useEffect, useState} from "react";
import {GetKycTemplateById, GetKycTemplateHistory, UpdateKycTemplate} from "../../../store/actions";
import {
    DAY_IN_MILLISECONDS,
    KYC_ROOT_PATH,
    KYC_TEMPLATES_EXACT_PATH,
    KYC_TEMPLATES_LIST_PATH,
    TEMPLATE_CSV_PAGING
} from "../../../constants";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {createTemplateRequestType, exactTemplateTabType} from "../../../types";
import {KycRefactorTemplateModel} from "../../../../../../newShared/GQLTypes";

export const useTemplatesExact = ({setForm, isOk, form}: {setForm: (form: createTemplateRequestType) => void, isOk: boolean, form: createTemplateRequestType}) => {
    const {tMenu} = useMainTranslation();

    const dispatch = useDispatch();
    const history = useHistory();
    const {getTemplateExact, updateTemplate, getTemplateHistory} = useSelector(loadings);
    const {total} = useSelector(selectedTemplateCsvHistoryPageInfo);
    const config = useSelector(templateConfig);

    const {id} = useParams<{id: string}>();
    const selected = useSelector(selectedTemplate);

    //useEffects
    useEffect(() => {
        if(!selected && id){
            dispatch(GetKycTemplateById({templateId: id, onReject: handleGoBack}))
        }

        if(selected){
            // const nextScreeningDate = new Date(Date.now() + DAY_IN_MILLISECONDS).toISOString()
            // setForm({...selected, nextScreeningDate: selected.nextScreeningDate || nextScreeningDate});
            setForm(selected);
        }

        return () => {
            dispatch(deselectTemplate());
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!getTemplateExact && selected){
            const nextScreeningDate = new Date(Date.now() + DAY_IN_MILLISECONDS).toISOString()
            setForm({...selected, nextScreeningDate: selected.nextScreeningDate || nextScreeningDate});
        }
        //eslint-disable-next-line
    }, [getTemplateExact]);

    useSetBreadcrumbs([
        {
            title: tMenu('KYC Management'),
            path: KYC_ROOT_PATH
        },
        {
            title: tMenu('Templates'),
            path: KYC_TEMPLATES_LIST_PATH
        },
        {
            title: selected ? selected.name : tMenu('Loading...'),
            path: KYC_TEMPLATES_EXACT_PATH.replace(':id', id)
        }
    ]);

    //
    const handleGoBack = () => history.push(KYC_TEMPLATES_LIST_PATH);

    const handlePreview = () => {
        if(!editMode && selected){
            dispatch(openPreviewTemplate(selected));
        }else{
            dispatch(openPreviewTemplate(form));
        }
        // selected && dispatch(openPreviewTemplate(form));
    }

    //
    const [editMode, setEditMode] = useState<boolean>(false);

    const handleEditTemplate = () => {
        if(selected){
            setEditMode(true);
            const nextScreeningDate = new Date(Date.now() + DAY_IN_MILLISECONDS).toISOString()
            setForm({...selected, nextScreeningDate: selected.nextScreeningDate || nextScreeningDate});
        }
    }

    const [tab, setTab] = useState<exactTemplateTabType>('fields');

    const isOkToSave = isOk && JSON.stringify(form) !== JSON.stringify(selected);

    const handleSaveChanges = () => {
        if(isOkToSave){
            dispatch(UpdateKycTemplate({template: form, onSuccess}));
        }
    }

    const onSuccess = (template: KycRefactorTemplateModel) => {
        setEditMode(false);
        setForm(template);
    }

    return{
        isNoHistoriesFound: !getTemplateHistory && total === 0,
        template: selected,
        isLoading: getTemplateExact,
        isLoadingSave: updateTemplate,
        editMode,
        actions: {
            handleGoBack,
            handlePreview,
            handleEditTemplate,
            handleSaveChanges,
            handleCancelChanges: () => {
                selected && setForm(selected);
                setEditMode(false);
            }
        },
        tab,
        setTab: (tab: exactTemplateTabType) => {
            if(tab ==='csv'){
                selected && dispatch(GetKycTemplateHistory({data: {pageInfo: {page: 0, count: TEMPLATE_CSV_PAGING}, templateId: selected.id}}));
            }
            setTab(tab);
        },
        config,
        isOkToSave
    }
}