//core
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

//components
//redux
import {deletePipeline, hideDeletePipeline, loadings, selectedAutomation} from '../../../store/slice';
import {DeleteAutomation} from "../../../store/actions";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import colors from "../../../../../../newShared/theme/colors";
import {LoadingButton} from "@mui/lab";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useHistory} from "react-router-dom";
import {AUTOMATIONS_LIST_PATH} from "../../../constants";
import {useMainTranslation} from '../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation';


export const DeletePipelineDialog = () => {
    const history = useHistory();
    const {t} = useMainTranslation('', {keyPrefix: 'automations'});
    // const {filter} = useParameters();
    const dispatch = useDispatch();
    const {isOpen, pipelineId} = useSelector(deletePipeline);
    const selected = useSelector(selectedAutomation);
    const {deleteAutomation} = useSelector(loadings);
    const {setMessage} = useMessageDialog();

    const handleClick = () => {
        if(pipelineId){
            // if(auto.documents.type === 'file' && auto.documents.links.length > 0){
            //     dispatch(DeleteAllFile({
            //         fileIds:  auto.documents.links.map(e => e.link),
            //         autoId: auto.id
            //     }));
            // }
            dispatch(DeleteAutomation({id: pipelineId, filter: {},
                onSuccess: selected ? onSuccess : () => null
            }));
        }
    }

    const onSuccess = () => {
        setMessage({
            title: t('Completed Successfully!'),
            message: t(`Automation deleted!`)
        });
        history.push(AUTOMATIONS_LIST_PATH);
    }

    const handleCloseButton = () => {
        dispatch(hideDeletePipeline());
    }

    return(
        <Dialog
            open={isOpen}
            onClose={handleCloseButton}
        >
            <DialogTitle>{t('Delete automation')}</DialogTitle>

            <DialogContent>
                <Typography variant={'body2'} color={colors.darkBlueText}>
                    {t(`If you delete information about an item, you will not be able to restore it.`)}
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleCloseButton} disabled={deleteAutomation} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    variant={'contained'}
                    loading={deleteAutomation}
                    onClick={handleClick}
                >{t('Delete')}</LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
