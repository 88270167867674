import React, {FC} from "react";
import {useTrainingsDialogs} from "../../../hooks/useTrainingsDialogs";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSelector} from "react-redux";
import {assignmentsSelector} from "../../../store/slice";
import colors from "../../../../../../newShared/theme/colors";
import {getCommonStatusChip, getTrainingAssignmentStatusChip} from "../../../helpers";
import {
    MainTrainingAssignmentPostExamAnswerValidationType,
    MainTrainingProgressStatus,
    TrainingAssignmentObjectStatus,
    TrainingAssignmentStatus,
    TrainingExamQuestionType
} from "../../../../../../newShared/GQLTypes";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";

export const AssignmentHistory: FC = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.dialogs'});

    const {isOpen, handleClose } = useTrainingsDialogs().assignmentHistory;
    const {selectedAssignment: selected} = useSelector(assignmentsSelector);


    const isExamCircleBackgroundBlue = (status: TrainingAssignmentObjectStatus) => {
        return status === TrainingAssignmentObjectStatus.Done || status === TrainingAssignmentObjectStatus.Failed || status === TrainingAssignmentObjectStatus.Validation || status === TrainingAssignmentObjectStatus.Passed;
    }


    const isExamCircleBorderBackgroundBlue = (status: TrainingAssignmentObjectStatus) => {

        if (!selected?.training || selected?.training?.status === TrainingAssignmentObjectStatus.Done || selected?.training?.status === TrainingAssignmentObjectStatus.Failed || selected?.training?.status === TrainingAssignmentObjectStatus.Passed) {
            return isExamCircleBackgroundBlue(status) || status === TrainingAssignmentObjectStatus.InProgress || selected?.status === TrainingAssignmentStatus.Passed || TrainingAssignmentStatus.Failed;
        }
        else return false;
    }

    const isValidationExist = () => {
        return selected?.postExam?.questions && selected?.postExam?.questions.some(e => (e.type === TrainingExamQuestionType.Paragraph || e.type === TrainingExamQuestionType.ShortAnswer) && e?.validation?.type !== MainTrainingAssignmentPostExamAnswerValidationType.Key  )
    }

    const getBorderColorToValidation = (status: TrainingAssignmentObjectStatus) => {
        return status === TrainingAssignmentObjectStatus.Failed || status === TrainingAssignmentObjectStatus.Validation || status === TrainingAssignmentObjectStatus.Done || status === TrainingAssignmentObjectStatus.Passed ? colors.primary.blue : colors.stroke.grey;
    }

    const isValidationActive = (status: TrainingAssignmentObjectStatus) => {
        return status === TrainingAssignmentObjectStatus.Validation || status === TrainingAssignmentObjectStatus.Failed || status === TrainingAssignmentObjectStatus.Passed || status === TrainingAssignmentObjectStatus.Done;
    }

    const isValidationCircleBlue = (status: TrainingAssignmentObjectStatus) => {
        return status === TrainingAssignmentObjectStatus.Failed || status === TrainingAssignmentObjectStatus.Passed || status === TrainingAssignmentObjectStatus.Done;
    }

    const getExamStatus = () => {
        if (!selected?.training || selected?.training?.status === TrainingAssignmentObjectStatus.Done || selected?.training?.status === TrainingAssignmentObjectStatus.Passed || selected.training?.status === TrainingAssignmentObjectStatus.Failed) return getCommonStatusChip(selected?.postExam?.status)
        else return undefined;
    }

    const getTrainingBorderColor = (status: MainTrainingProgressStatus, i: number) => {
        if (selected?.postExam) return status === MainTrainingProgressStatus.Done || selected.status === TrainingAssignmentStatus.Passed || selected.status === TrainingAssignmentStatus.Failed ? colors.primary.blue :  colors.stroke.grey;
        else if (selected?.training?.content) return i < selected.training.content.length - 1 ? status === MainTrainingProgressStatus.Done ? colors.primary.blue :  colors.stroke.grey : undefined;
    }

    const getValidatorFullName = () => {
        const validation = selected?.postExam?.questions?.find(e => (e.type === TrainingExamQuestionType.Paragraph || e.type === TrainingExamQuestionType.ShortAnswer) && e?.validation?.type !== MainTrainingAssignmentPostExamAnswerValidationType.Key )?.validation
        return validation ? validation.fullName : '---';
    }


  
    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Assignment history')}</DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', overflow: 'auto', mb: '20px'}}>
                <Box display={'flex'} flexDirection={'column'} width={'100%'} sx={{mt: '20px'}}>

                    {/*Title*/}
                    <Box display={"flex"} width={'100%'} gap={'20px'} justifyContent={'flex-start'} alignItems={'center'}>
                        <Box sx={{ width: '12px', height: '12px', borderRadius: '50%',
                            background: colors.primary.blue,
                            border: `2px solid ${colors.primary.blue}` }}
                        />

                        <Box display={'flex'} width={'100%'} position={'relative'}>
                            <Box sx={{position: 'absolute', bottom: '35px'}}>
                                <Typography variant={'*bodySmall'} color={colors.text.grey}>{`${t('Step')} 1`}</Typography>
                            </Box>
                            <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography variant={'*bodyText1_semibold'} color={colors.primary.blue}>{t('Assignment creation')}</Typography>
                                {selected && getTrainingAssignmentStatusChip(TrainingAssignmentStatus.Assigned)}
                            </Box>
                        </Box>
                    </Box>

                    {/*Body*/}
                    <Box display={"flex"} flexDirection={'column'} width={'100%'} gap={'6px'} sx={{ml: '4px', p: '0 26px 45px',borderLeft: `2px solid ${colors.primary.blue}` }}>
                        <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('by {{fullName}}', {fullName: selected?.requestor.fullName ?? ''})}</Typography>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={'10px'}>
                            <Typography variant={'*bodySmall'} color={colors.text.grey}>{`${t('Start date')}: `}<span style={{color: colors.text.grey_dark}}>{parseDateAuto(selected?.startDate, false, undefined, undefined, '-')}</span></Typography>
                            <Typography variant={'*bodySmall'} color={colors.text.grey}>{`${t('Due date')}: `}<span style={{color: colors.text.grey_dark}}>{parseDateAuto(selected?.endDate, false, undefined, undefined, '-')}</span></Typography>
                        </Box>
                    </Box>
                </Box>

                {selected?.status === TrainingAssignmentStatus.Cancelled ?
                    <>
                        <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                            {/*Title*/}
                            <Box display={"flex"} width={'100%'} gap={'20px'} justifyContent={'flex-start'} alignItems={'center'}>
                                <Box sx={{ width: '12px', height: '12px', borderRadius: '50%',
                                    background: colors.primary.blue,
                                    border: `2px solid ${colors.primary.blue}` }}
                                />

                                <Box display={'flex'} width={'100%'} position={'relative'}>
                                    <Box sx={{position: 'absolute', bottom: '35px'}}>
                                        <Typography variant={'*bodySmall'} color={colors.text.grey}>{`${t('Step')} 2`}</Typography>
                                    </Box>
                                    <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={"center"}>
                                        <Typography variant={'*bodyText1_semibold'} color={colors.primary.blue}>{t('Assignment cancelled')}</Typography>
                                        {getTrainingAssignmentStatusChip(TrainingAssignmentStatus.Cancelled)}
                                    </Box>
                                </Box>
                            </Box>

                            {/*Body*/}
                            <Box display={"flex"} flexDirection={'column'} width={'100%'} gap={'6px'} sx={{ml: '4px', p: '0 26px 45px' }}>
                                <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('by {{fullName}}', {fullName: selected?.canceledInfo?.fullName ?? ''})}</Typography>

                                {/*<Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={'10px'}>*/}
                                {/*    <Typography variant={'*bodySmall'} color={colors.text.grey}>{t('Cancelled')}<span style={{color: colors.text.grey_dark}}>{parseDateAuto(selected.canceledInfo., false, undefined, undefined, '-')}</span></Typography>*/}
                                {/*    <Typography variant={'*bodySmall'} color={colors.text.grey}>{t('Scheduled start date')}<span style={{color: colors.text.grey_dark}}>{parseDateAuto(selected.startDate, false, undefined, undefined, '-')}</span></Typography>*/}
                                {/*</Box>*/}
                            </Box>
                        </Box>
                    </>
                    :
                    <>
                        { selected?.training &&
                            <>
                                {[...selected.training.content]
                                    .sort((a, b) => a.order - b.order)
                                    .map((section, i) => (
                                        <Box key={section.sectionId} display={'flex'} flexDirection={'column'} width={'100%'}>
                                            {/*Title*/}
                                            <Box display={"flex"} width={'100%'} gap={'20px'} justifyContent={'flex-start'} alignItems={'center'}>
                                                <Box sx={{ width: '12px', height: '12px', borderRadius: '50%',
                                                    background: section.status === MainTrainingProgressStatus.Done || selected.status === TrainingAssignmentStatus.Passed || selected.status === TrainingAssignmentStatus.Failed ? colors.primary.blue : undefined,
                                                    border: `2px solid ${section.status === MainTrainingProgressStatus.Done || section.status === MainTrainingProgressStatus.InProgress || selected.status === TrainingAssignmentStatus.Passed || selected.status === TrainingAssignmentStatus.Failed ? colors.primary.blue :  colors.stroke.grey}` }}
                                                />

                                                <Box display={'flex'} width={'100%'} position={'relative'}>
                                                    <Box sx={{position: 'absolute', bottom: '35px'}}>
                                                        <Typography variant={'*bodySmall'} color={colors.text.grey}>{`${t('Step')} ${section.order + 2}`}</Typography>
                                                    </Box>
                                                    <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={"center"}>
                                                        <Typography variant={'*bodyText1_semibold'} color={section.status === MainTrainingProgressStatus.Todo && selected.status !== TrainingAssignmentStatus.Passed && selected.status !== TrainingAssignmentStatus.Failed ? colors.text.grey : colors.primary.blue}>{`${t('Section')} ${section.order + 1}`}</Typography>
                                                        { selected.status === TrainingAssignmentStatus.Assigned || selected.status === TrainingAssignmentStatus.Scheduled ? (i === 0 ? getCommonStatusChip('Waiting') : undefined) : section.status !== MainTrainingProgressStatus.Todo && getCommonStatusChip(section.status) }
                                                        {/*{selected.status !== TrainingAssignmentStatus.Assigned && selected.status !== TrainingAssignmentStatus.Scheduled ? getCommonStatusChip(section.status) : i === 0 ? getCommonStatusChip('Waiting') : undefined}*/}
                                                    </Box>
                                                </Box>
                                            </Box>

                                            {/*Body*/}
                                            <Box display={"flex"} flexDirection={'column'} width={'100%'} gap={'6px'}
                                                 sx={{ml: '4px', p: '0 26px 45px',borderLeft: `2px solid ${getTrainingBorderColor(section.status, i)}` }}>

                                                <Typography variant={'*bodyText2'} color={colors.text.dark}>{section.sectionName}</Typography>
                                            </Box>

                                        </Box>
                                    ))}
                            </>
                        }

                        { selected?.postExam &&
                            <>
                                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                                    {/*Title*/}
                                    <Box display={"flex"} width={'100%'} gap={'20px'} justifyContent={'flex-start'} alignItems={'center'}>
                                        <Box sx={{ width: '12px', height: '12px', borderRadius: '50%',
                                            background: isExamCircleBackgroundBlue(selected.postExam.status) || selected.status === TrainingAssignmentStatus.Passed || selected.status === TrainingAssignmentStatus.Failed ? colors.primary.blue : undefined,
                                            border: `2px solid ${isExamCircleBorderBackgroundBlue(selected.postExam.status) || selected.status === TrainingAssignmentStatus.Passed || TrainingAssignmentStatus.Failed ? colors.primary.blue : colors.stroke.grey}` }}
                                        />

                                        <Box display={'flex'} width={'100%'} position={'relative'}>
                                            <Box sx={{position: 'absolute', bottom: '35px'}}>
                                                <Typography variant={'*bodySmall'} color={colors.text.grey}>{`${t('Step')} ${selected.training ? selected.training.content.length + 2 : 2}`}</Typography>
                                            </Box>
                                            <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={"center"}>
                                                <Typography variant={'*bodyText1_semibold'} color={isExamCircleBorderBackgroundBlue(selected.postExam.status) || selected.status === TrainingAssignmentStatus.Passed || selected.status === TrainingAssignmentStatus.Failed ? colors.primary.blue : colors.text.grey }>{t('Exam')}</Typography>
                                                {getExamStatus()}
                                            </Box>
                                        </Box>
                                    </Box>

                                    {/*Body*/}
                                    <Box display={"flex"} flexDirection={'column'} width={'100%'} gap={'6px'}
                                         sx={{ml: '4px', p: '0 26px 45px',borderLeft: (isValidationExist() && selected.postExam.status !== TrainingAssignmentObjectStatus.Abandoned) ? `2px solid ${getBorderColorToValidation(selected.postExam.status)}` : undefined}}>

                                        <Typography variant={'*bodyText2'} color={colors.text.dark}>{selected.postExam.examName}</Typography>
                                        {/*<Typography variant={'*bodySmall'} color={colors.text.grey}>{`${t('Time to pass')}: `}<span style={{color: colors.text.grey_dark}}>{selected.postExam.timeToPass}</span>{` ${t('minutes')}`}</Typography>*/}

                                        {/*{ (selected.postExam.status === TrainingAssignmentObjectStatus.Failed || selected.postExam.status === TrainingAssignmentObjectStatus.Passed || selected.postExam.status === TrainingAssignmentObjectStatus.Validation) &&*/}
                                        {/*    <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={'10px'}>*/}
                                        {/*        <Typography variant={'*bodySmall'} color={colors.text.grey}>{`${t('Started on')}: `}<span style={{color: colors.text.grey_dark}}>{parseDateAuto(selected.postExam.startDate, false, undefined, undefined, '-')}</span></Typography>*/}
                                        {/*        <Typography variant={'*bodySmall'} color={colors.text.grey}>{`${t('Finished on')}: `}<span style={{color: colors.text.grey_dark}}>{parseDateAuto(selected.postExam.completeDate, false, undefined, undefined, '-')}</span></Typography>*/}
                                        {/*    </Box>*/}
                                        {/*}*/}
                                    </Box>

                                </Box>

                                {/*VALIDATION*/}
                                { isValidationExist() && selected.postExam.status !== TrainingAssignmentObjectStatus.Abandoned &&
                                    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                                        {/*Title*/}
                                        <Box display={"flex"} width={'100%'} gap={'20px'} justifyContent={'flex-start'} alignItems={'center'}>
                                            <Box sx={{ width: '12px', height: '12px', borderRadius: '50%',
                                                background: isValidationCircleBlue(selected.postExam.status) ? colors.primary.blue : undefined,
                                                border: `2px solid ${isValidationActive(selected.postExam.status)  ? colors.primary.blue : colors.stroke.grey}` }}
                                            />

                                            <Box display={'flex'} width={'100%'} position={'relative'}>
                                                <Box sx={{position: 'absolute', bottom: '35px'}}>
                                                    <Typography variant={'*bodySmall'} color={colors.text.grey}>{`${t('Step')} ${selected.training ? selected.training.content.length + 3 : 3}`}</Typography>
                                                </Box>
                                                <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={"center"}>
                                                    <Typography variant={'*bodyText1_semibold'} color={selected.postExam.status === TrainingAssignmentObjectStatus.Todo ? colors.text.grey : colors.primary.blue}>{t('Validation')}</Typography>
                                                    {selected.postExam.status === TrainingAssignmentObjectStatus.Validation  && getCommonStatusChip(TrainingAssignmentObjectStatus.InProgress)}
                                                    {(selected.postExam.status === TrainingAssignmentObjectStatus.Failed || selected.postExam.status === TrainingAssignmentObjectStatus.Passed) && getCommonStatusChip(TrainingAssignmentObjectStatus.Done)}
                                                </Box>
                                            </Box>
                                        </Box>

                                        {/*Body*/}
                                        {(selected.status === TrainingAssignmentStatus.Passed || selected.status === TrainingAssignmentStatus.Failed) &&
                                            <Typography sx={{ml: '30px', mr: '30px'}} variant={'*bodyText2'} color={colors.text.dark}>{t('by {{fullName}}', {fullName: getValidatorFullName()})}</Typography>
                                        }
                                    </Box>
                                }
                            </>
                        }
                    </>
                }
            </DialogContent>

            <DialogActions>
                <Button variant={"text"} size={'small'} onClick={handleClose}>{tCommon('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}
