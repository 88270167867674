import React, {FC} from "react";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../../../newShared/components/Layouts";
import {ReleasePhysicalAssets} from "../../components/Dialogs/ReleaseDialog";
import {useCertificateAssetsList} from "../../hooks/useCertificateAssetsList";
import AddIcon from "@mui/icons-material/Add";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {TCertificateAsset} from "../../types";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {useImportCsvCertificate} from "../../hooks/dialogs/useImportCsvCertificate";
import {ImportCsvDialog} from "../../../../../newShared/components/csvImportDialog";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {ExportCsvDialog} from "../../../../../newShared/components/csvExportDialog";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {EmptyRequiredFieldsTooltip} from "../../components/styled";


export const CertificateAssetsList:FC = () => {
    const {isLessThen1050} = useMedia();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});

    const {
        certificateAssets,
        // settings,
        // filter,
        // setSearch,
        // isShowingNoAssets,
        goToAsset,
        goToAddAsset,
        goToSettings,
        // exportCsv,
        handleImportCSV,
        handleOpenExportCsv,
        exportCsv,
        isLoadingList,
        isLoadingExport,
        isLoadingFields,
        // isLoadingSettings,
        handleOpenRelease,

        // pagination: {
        //     bottomLoader,
        //     onScroll,
        //     scrollRef
        // },
        genericTable
    } = useCertificateAssetsList();

    const importCsv = useImportCsvCertificate();

    return (
        <Flex w={'100%'} h={'100%'} direction={'column'} >
            <PageTitleAndActions
                title={t('Certificates')}
                showMenuWhen={isLessThen1050}
                actions={[
                    {
                        title: t('Add'),
                        onClick: goToAddAsset,
                        variant: 'text',
                        icon: <AddIcon />,
                        disabled: isLoadingList,
                        size: 'small'
                    },
                    {
                        title: t('Settings'),
                        onClick: goToSettings,
                        // variant: 'text',
                        disabled: isLoadingList,
                        size: 'small'
                    },
                    {
                        title: t('Release'),
                        onClick: handleOpenRelease,
                        disabled: isLoadingList,
                        color: 'secondary',
                        size: 'small'
                    },
                    {
                        title: t('Export CSV'),
                        onClick: handleOpenExportCsv,
                        variant: 'text',
                        icon: <FileDownloadIcon style={{marginLeft: revDir ? '9px' : undefined }}/>,
                        loading: isLoadingExport,
                        disabled: isLoadingExport || isLoadingList,
                        size: 'small'
                    },
                    {
                        title: t('Import CSV'),
                        icon: <FileUploadIcon style={{marginLeft: revDir ? '9px' : undefined }}/>,
                        onClick: handleImportCSV,
                        variant: 'text',
                        disabled: isLoadingList || isLoadingExport,
                        size: 'small'
                    },
                ]}
            />

            <GenericTable<TCertificateAsset>
                id={'CertificateAssetsList'}
                columnsConfig={{totalName: 'Total assets', disableColumnsSettings: true, disableShowCards: true}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: certificateAssets,
                    // customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id,
                    onRowClick: goToAsset,
                    columnsConfigs: [
                        {key: "name", name: t('Name'), default: true},
                        {name: '', key: 'missingData', default: true, valueRenderer: (row) => {
                                const hasMissingData = !row.fileName || row.fileName.length === 0;

                                if(hasMissingData){
                                    return(
                                        <EmptyRequiredFieldsTooltip title={"Missing data for required fields"} placement={"bottom"} arrow>
                                            <InfoOutlinedIcon sx={{width:20, height: 20, color: '#916A00'}} />
                                        </EmptyRequiredFieldsTooltip>
                                    )
                                }
                            }}
                    ],
                }}
                filtersConfig={{
                    searchCustomFilterKeyName: 'nameLike',
                    searchAsArray: true,
                    dontSendSearchIfEmpty: true,
                    genericFilterProps: {
                        configs: [],
                        fetchResultWithSelectedFilters: () => null,
                        isAddFilters: false
                    }
                }}
                emptyArrayImageProps={{type: 'physicalAssets', filterNotApplied: {secondaryText: t('You have not added any asset yet.'), text: t('Create asset')}}}
            />


            <ReleasePhysicalAssets />

            <ExportCsvDialog
                isOpen={exportCsv.isOpen}
                isLoadingFields={isLoadingFields}
                isLoadingExport={isLoadingExport}
                availableFields={exportCsv.availableFields}
                handleExportCsv={exportCsv.handleExportCsv}
                handleClose={exportCsv.handleClose}
                fieldsMapperKey={"assets"}
            />

            <ImportCsvDialog
                isOpen={importCsv.isOpen}
                fields={importCsv.fields}
                response={importCsv.response}
                setImportDialog={importCsv.setImportDialog}

                isGettingFields={importCsv.isGettingFields}
                isPerformingCsv={importCsv.isPerformingCsv}
                isLoadingTemplate={importCsv.isLoadingTemplate}

                getFields={importCsv.getFields}
                performCsv={importCsv.performCsv}
                downloadTemplate={importCsv.downloadTemplate}
                fieldsMapperKey={importCsv.fieldsMapperKey}

                fileLimitInMB={5}
            />
        </Flex>
    )

}
