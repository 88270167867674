import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {LoadingButton} from "../../../../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDeleteRoleDialog} from "../../../../../hooks/organizations/dialogs/useDeleteRoleDialog";

export const DeleteWorkspaceRole = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathSettings.dialogs'});
    const {
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        role,
        roleUsedBy
    } = useDeleteRoleDialog();
    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Delete workspace role')}</DialogTitle>

            <DialogContent>
                <DialogContentText>
                    <Typography variant={'body2'}>{t('Role {{roleName}} will be deleted.', {roleName: role?.name ?? ''})}</Typography>
                    <Typography variant={'body2'} sx={{margin: '8px 0 0 0'}}>{t('Are you sure you want to delete this role?')}</Typography>

                    {roleUsedBy.length > 0 && <Typography variant={'body2'} sx={{margin: '8px 0 0 0'}}>{t('This role is used by  {{emails}}. Reassign those user\'s roles to delete it.', {emails: roleUsedBy.join(',')})}</Typography>}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                    disabled={roleUsedBy.length > 0}
                >
                    {t('Delete')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}