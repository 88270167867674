import {UpdateLinkPopperTypes} from "../../types";
import {CommonPopper} from "../selectVariablePopper/styled";
import {useUpdateLinkPopper} from "./useUpdateLinkPopper";
import React from "react";
import {Button, IconButton, Typography} from "@mui/material";
import {ButtonsContainer} from "../updateVariablePopper/styled";
import * as S from './styled';
import LinkOffIcon from '@mui/icons-material/LinkOff';

export const UpdateLinkPopper = (props: UpdateLinkPopperTypes) => {
    const {form, handleChange, isDisabled, handleSubmit, handleUnlink} = useUpdateLinkPopper(props);

    return(
        <CommonPopper open={Boolean(props.element) && props.isFocused} anchorEl={props.element} onMouseDown={(e) => {e.stopPropagation()}}>
            <S.LinkPopperInnerWrapper onClick={(e) => e.stopPropagation()}>
                <S.UnlinkButtonContainer>
                    <IconButton size={'small'} onClick={handleUnlink}>
                        <LinkOffIcon />
                    </IconButton>
                </S.UnlinkButtonContainer>

                <S.LinkPopperTitleInput
                    autoFocus
                    onChange={handleChange}
                    name={'title'}
                    value={form.title ?? ''}
                    label={'Text'}
                    fullWidth
                    // onKeyDown={handleKeyDown}
                />

                <S.LinkPopperLinkInput
                    autoFocus
                    onChange={handleChange}
                    name={'href'}
                    value={form.href ?? ''}
                    label={'Link'}
                    helperText={<Typography fontSize={'12px'}>{'https://domain.com or domain.com'}</Typography>}
                    fullWidth
                    // onKeyDown={handleKeyDown}
                />

                <ButtonsContainer>
                    <Button variant={'text'} size={'small'} onClick={props.handleClose}>
                        Cancel
                    </Button>

                    <Button variant={'contained'} size={'small'} onClick={handleSubmit} disabled={isDisabled}>
                        Apply
                    </Button>
                </ButtonsContainer>
            </S.LinkPopperInnerWrapper>
        </CommonPopper>
    )
}