import {useDispatch, useSelector} from "react-redux";
import {
    hideManageCollabAccess,
    selectedWorkspace,
    selectedWorkspaceData,
    workspacesDialogs,
    workspacesLoadings
} from "../../../../store/slice";
import {useEffect, useState} from "react";
import {TWorkspaceRole} from "../../../../types";
import {UpdateUserRole} from "../../../../store/actions";
import {ROLE_PORTAL_MEMBER, ROLE_WS_ADMIN, ROLE_WS_OWNER} from "../../../../constants";

export const useManageUserAccess = () => {
    const dispatch = useDispatch();
    const {workspaceRoles} = useSelector(selectedWorkspaceData);
    const selectedWS = useSelector(selectedWorkspace);

    const {isLoading} = useSelector(workspacesLoadings).changeCollabRole;
    const {isOpen, collab} = useSelector(workspacesDialogs).manageCollabRole;

    const [userRoles, setUserRoles] = useState<TWorkspaceRole[]>([]);
    const [isAccessToPortal, setIsAccessToPortal] = useState<boolean>(true);

    const handleClose = () => {
        dispatch(hideManageCollabAccess());
    }

    const handleSubmit = () => {
        const portalMemberRole = workspaceRoles.find(e => e.name === ROLE_PORTAL_MEMBER);
        if(isOk() && selectedWS && collab){
            dispatch(UpdateUserRole({
                workspaceId: selectedWS.id,
                roles: (isAccessToPortal && portalMemberRole) ? [...userRoles, portalMemberRole] : userRoles,
                publicId: collab.publicId
            }));
        }
    }

    const cleanUp = () => {
        setUserRoles([]);
        setIsAccessToPortal(true);
    }

    useEffect(() => {
        if(isOpen && collab){
            setUserRoles(collab.roles.filter(e => e.name !== ROLE_PORTAL_MEMBER));
            setIsAccessToPortal(collab.roles.some(e => e.name === ROLE_PORTAL_MEMBER));

        }else if(!isOpen){
            cleanUp();
        }
        //eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        if (userRoles.some(e => e.name === ROLE_WS_ADMIN)) setIsAccessToPortal(true);

        //eslint-disable-next-line
    }, [userRoles]);


    const isOk = () => isAccessToPortal ? JSON.stringify(userRoles) !== JSON.stringify(collab?.roles.filter(e => e.name !== ROLE_PORTAL_MEMBER)) || collab?.roles.some(e => e.name === ROLE_PORTAL_MEMBER) !== isAccessToPortal
        : userRoles.length > 0 && (JSON.stringify(userRoles) !== JSON.stringify(collab?.roles.filter(e => e.name !== ROLE_PORTAL_MEMBER)) || collab?.roles.some(e => e.name === ROLE_PORTAL_MEMBER) !== isAccessToPortal);


    const handleAddCollabRole = () => {
        const notUsedRoles = workspaceRoles.filter(e => !userRoles.some(cr => cr.id === e.id) && e.name !== ROLE_WS_OWNER && e.name !== ROLE_PORTAL_MEMBER);
        notUsedRoles.length > 0 && setUserRoles([...userRoles, notUsedRoles[0]])
    }

    const handleDeleteCollabRole = (role: TWorkspaceRole) => {
        setUserRoles([...userRoles.filter(e => e.id !== role.id)]);
    }

    const replaceCollabRole = (id: number, role: TWorkspaceRole) => {
        setUserRoles([...userRoles.map((e, eId) => eId === id ? role : e)])
    }

    const isNoMoreRolesCanBeAdded = !workspaceRoles.filter(e => !userRoles.some(cr => cr.id === e.id) && e.name !== ROLE_WS_OWNER).length;

    return{
        isOpen,
        isLoading,
        collab,
        roles: workspaceRoles,
        userRoles,
        setUserRoles,
        isOk: isOk(),
        isContainAdminRole: userRoles.some(e => e.name === ROLE_WS_ADMIN),
        isAccessToPortal,
        setIsAccessToPortal,
        actions: {
            handleAddCollabRole,
            handleDeleteCollabRole,
            replaceCollabRole,
            handleSubmit,
            handleClose,
        },
        isNoMoreRolesCanBeAdded
    }
}
