import {useEffect, useState} from "react";

export const useDndBackend = () => {
    const [isTouch, setIsTouch] = useState<boolean>(false);

    const is_touch_device = () => {
        try {
            let prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

            //eslint-disable-next-line
            let mq = function (query: any) {
                return window.matchMedia(query).matches;
            };

            //@ts-ignore
            if (('ontouchstart' in window) || (typeof window.DocumentTouch !== "undefined" && document instanceof window.DocumentTouch)) {
                return true;
            }

            return mq(['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(''));
        } catch (e) {
            console.error('(Touch detect failed)', e);
            return false;
        }
    }

    useEffect(() => {
        setIsTouch(is_touch_device());
    }, []);

    return isTouch;

}