import {DragSourceMonitor, useDrag, useDrop} from "react-dnd";
import {FieldItem, TemplateFieldProps} from "../../../types";

export const useTemplateField = ({findField, field, moveField}: TemplateFieldProps) => {
    const searchedField = findField(field.id);

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: 'field',
            item: {key: field.id, index: searchedField?.index ?? -1},
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const { key: droppedKey, index: originalIndex } = item;

                // console.log(`ROW useDrag END with item ${JSON.stringify(item)}`);
                const didDrop = monitor.didDrop()
                if (!didDrop && item) {
                    moveField(droppedKey, originalIndex);
                    // console.log(`ROW useDrag - moveColumn exec: ${droppedKey} | ${originalIndex}`);
                }
            },
        }),
        [field.id, searchedField, moveField],
    )

    const [, drop] = useDrop(
        () => ({
            accept: 'field',
            hover({ key: draggedKey }: FieldItem) {
                // console.log(`Card useDrop draggedKey:${draggedKey} in cardId ${column.key}`);
                if (draggedKey !== field.id) {
                    const col = findField(field.id)
                    if(col){
                        // console.log(`ROW useDrop - moveColumn exec: ${draggedKey} | ${col.index}`);
                        moveField(draggedKey, col.index);
                    }else{
                        // console.log(`ROW useDrop - col not found by key ${column.key}`)
                    }
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
                isActive: monitor.canDrop() && monitor.isOver(),
            }),
        }),
        [findField, moveField],
    )

    const opacity = isDragging ? 0 : 1

    const getNormalizedTypeName = (): string => {
        switch (field.type){
            case "FILE": return 'File field';
            case "DATE": return 'Date field';
            case "NUMBER": return 'Number field';
            case "TEXT": return 'Text field';
            default: return field.type;
        }
    }

    return{
        opacity,
        isDragging,
        drag, drop,
        normalizedTypeName: getNormalizedTypeName()
    }
}