import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {dialogs, loadings, setSelectStepResponsible, wsCollaboratorsSelector} from "../../../store/slice";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {MainVicaCategoryCollaboratorModel,} from "../../../../../../newShared/GQLTypes";
import {
    mainVicaGetWSCollaboratorsAction,
    mainVicaUpdateStepResponsibleCollaboratorsAction
} from "../../../store/actions";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const useSelectTaskCollabsDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathVica.TasksList.dialogs'});
    const dispatch = useDispatch();
    const {isOpen, step} = useSelector(dialogs).selectStepResponsible;
    const selectedStep = step;
    const {updateStepResponsibleCollaborators, getCollaborators: isLoadingWsCollabs} = useSelector(loadings);
    const collaborators = useSelector(wsCollaboratorsSelector);
    const {setMessage} = useMessageDialog();

    const handleClose = () => {dispatch(setSelectStepResponsible({clear: true}))}

    const [taskCollabs, setTaskCollabs] = useState<MainVicaCategoryCollaboratorModel[]>([]);
    const {form, setForm, handleChange} = useForm<{search: string}>({search: ''});

    const handleAddCollab = (collab: MainVicaCategoryCollaboratorModel) => {
        setTaskCollabs([...taskCollabs, collab])
    }

    const handleDeleteCollab = (collab: MainVicaCategoryCollaboratorModel) => {
        setTaskCollabs([...taskCollabs.filter(e => e.collaboratorId !== collab.collaboratorId)])
    }

    const isOk = selectedStep && JSON.stringify(selectedStep.responsible) !== JSON.stringify(taskCollabs);

    const handleSubmit = () => {
        if(isOk && selectedStep) dispatch(mainVicaUpdateStepResponsibleCollaboratorsAction({
            data: {stepId: selectedStep.id, responsible: taskCollabs.filter(e => collaborators.some(c => c.collaboratorId === e.collaboratorId))},

            onError: (request, error, addictiveData) => {
                const error409 = error.e409?.[0];
                const error404 = error.e404?.[0];

                if (error409?.type === 'NOT_COLLABORATOR') {
                    handleClose();
                    setMessage({
                        title: t('Action conflict error'),
                        message: t('Action not applicable! {{responsible}} is not collaborator of workspace', { responsible: error409.values[0].value }),
                    });

                }
                if (error404) handleClose();
            }

        }));
    }

    useEffect(() => {
        if (isOpen) {
            console.log(`selectedStep`, selectedStep);
            selectedStep?.responsible && setTaskCollabs(selectedStep.responsible);
            !collaborators.length && !isLoadingWsCollabs && dispatch(mainVicaGetWSCollaboratorsAction({data: {}}));
        }
        else {
            setTaskCollabs([]);
            setForm({search: ''});
        }

        //eslint-disable-next-line
    }, [isOpen]);


    return{
        isOpen,
        handleClose,
        isLoading: updateStepResponsibleCollaborators,
        isLoadingWsCollabs,
        handleSubmit,
        isOk,
        search: {
            value: form.search,
            handleChange,
            handleCleanSearch: () => setForm({search: ''})
        },
        selectedStep,
        filteredCollabs: collaborators.filter(e => e.fullName.includes(form.search) || e.email.includes(form.search)),
        taskCollabs,
        handleAddCollab,
        handleDeleteCollab
    }
}
