import {TPageInfo} from "../../../newShared/types";
import {
    MainTrainingAssignmentPostExamAnswerModel,
    MainTrainingContentType,
    MainTrainingContentTypeSectionAddDialog,
    MainTrainingLevel,
    MainTrainingStatus,
    TrainingAssignmentExamAnswerStatus,
    TrainingExamLevel,
    TrainingExamQuestionType
} from "../../../newShared/GQLTypes";
import {localCreateEditTraining, MainTrainingExamFormikQuestion, MainTrainingExamFormikQuestionOption} from "./types";
import {uuid} from "../../../newShared/utils";

export const TRAININGS_PATH = '/trainings-management';
export const PATH_TRAININGS_LIST = `${TRAININGS_PATH}/trainings`;
export const PATH_TRAININGS_CREATE = `${PATH_TRAININGS_LIST}/create`;
export const PATH_TRAININGS_EXACT = `${PATH_TRAININGS_LIST}/:trainingId`;
export const PATH_TRAININGS_EXAMS = `${TRAININGS_PATH}/exams`;
export const PATH_TRAININGS_EXAMS_CREATE = `${PATH_TRAININGS_EXAMS}/create`;
export const PATH_TRAININGS_EXAMS_EXACT = `${PATH_TRAININGS_EXAMS}/:examId`;
export const PATH_TRAININGS_ASSIGNMENTS = `${TRAININGS_PATH}/assignments`;
export const PATH_TRAININGS_ASSIGNMENT_EXACT = `${PATH_TRAININGS_ASSIGNMENTS}/:assignmentId`;
export const PATH_TRAININGS_QUESTIONS = `${TRAININGS_PATH}/questions`;

export const PATH_TRAININGS_SETTINGS = `${TRAININGS_PATH}/settings`;
export const PATH_TRAININGS_TEMPLATES = `${TRAININGS_PATH}/templates`;
export const PATH_TRAININGS_TEMPLATE_EXACT = `${PATH_TRAININGS_TEMPLATES}/:templateId`;


export const defaultPageInfo: TPageInfo = {
    page: 0,
    count: 10, //used to show loader (shows by hasMore) on init, before resolving first fetch
    total: 0 //it will be replaced by resolved data
}

export const examLevelTitleMapper: Record<TrainingExamLevel, string> = {
    [TrainingExamLevel.Basic]: 'Basic',
    [TrainingExamLevel.Medium]: 'Medium',
    [TrainingExamLevel.High]: 'High',
}

export const trainingLevelTitleMapper: Record<MainTrainingLevel | TrainingExamLevel, string> = {
    [MainTrainingLevel.Basic]: 'Basic',
    [MainTrainingLevel.Medium]: 'Medium',
    [MainTrainingLevel.High]: 'High',
}

export const sectionTypeTitleMapper: Record<MainTrainingContentType, string> = {
    [MainTrainingContentType.VideoLink]: 'Video service',
    [MainTrainingContentType.VideoFile]: 'Video file',
    [MainTrainingContentType.File]: 'File',
    [MainTrainingContentType.Editor]: 'Editor',
}

export const sectionTypeTitleAddSectionDialogMapper: Record<MainTrainingContentTypeSectionAddDialog, string> = {
    [MainTrainingContentTypeSectionAddDialog.Video]: 'Video',
    [MainTrainingContentTypeSectionAddDialog.File]: 'File',
    [MainTrainingContentTypeSectionAddDialog.Editor]: 'Editor',
}

export const questionTypeTitleAddQuestionDialogMapper: Record<TrainingExamQuestionType, string> = {
    [TrainingExamQuestionType.ShortAnswer]: 'Short answer',
    [TrainingExamQuestionType.Paragraph]: 'Paragraph',
    [TrainingExamQuestionType.Checkboxes]: 'Checkboxes',
    [TrainingExamQuestionType.MultipleChoice]: 'Multiple choice',
}

export const initialTrainingForm: localCreateEditTraining = {
    id: null,
    trainingId: null,
    category: null,
    level: MainTrainingLevel.Basic,
    exam: null,
    content: [],
    creator: null,
    createdDate: new Date().toISOString(),
    updatedDate: null,
    description: '',
    name: '',
    limitMinDuration: 0,
    limitMaxDuration: 0,
    latest: true,
    status: MainTrainingStatus.Active,
    version: 1,
    workspaceId: '',
}

export const createEditTrainingCategoriesAutocompleteCount = 999;


// EXAMS
export const createExamQuestionOption = (optionIndex: number): MainTrainingExamFormikQuestionOption => ({
    option: '',
    optionId: 'new' + uuid(),
    correct: optionIndex === 0,
    points: optionIndex === 0 ? 5 : 0,
    order: optionIndex,
});

export const createExamQuestion = (optionIndex: number, questionId: string) => ({
    questionId,
    question: '',
    description: '',
    points: 5,

    type: TrainingExamQuestionType.MultipleChoice,

    pointsAutoCount: false,
    options: [],

    order: optionIndex,
} satisfies MainTrainingExamFormikQuestion);

export const VIDEO_FILE_DROP_ZONE_ACCEPT = '.mp4, .webm';
export const FILE_SECTION_DROP_ZONE_ACCEPT = '.pdf, .pptx, .docx, .xlsx';

export const QUESTION_DND_TYPE = 'QUESTION_DND_TYPE';
export const QUESTION_OPTION_DND_TYPE = 'QUESTION_OPTION_DND_TYPE';

export const DEFAULT_GALLERY_TRAINING_IMAGE_ID = window.config?.REACT_APP_DEFAULT_GALLERY_TRAINING_IMAGE_ID;
export const DEFAULT_GALLERY_EXAM_IMAGE_ID = window.config?.REACT_APP_DEFAULT_GALLERY_EXAM_IMAGE_ID;
export const CROP_IMAGE_ASPECT_RATIO = 4 / 3;




export const questionsToValidate: MainTrainingAssignmentPostExamAnswerModel[] = [
    {
        answer: ["The concept of least privilege involves granting the minimal level of access and permissions necessary for individuals or processes to perform their tasks. In secure development, it's crucial as it limits potential exposure to security risks. By giving only the precise permissions needed and nothing more, the principle significantly reduces the attack surface for potential threats. This approach helps prevent unauthorized access, minimizes the impact of security breaches, and enhances overall system security by restricting unnecessary privileges."],
        description: "The \"least privilege\" concept in secure software development involves providing the minimum level of access and permissions necessary to perform tasks. It's crucial for reducing potential vulnerabilities and limiting the possible impact of security breaches.",
        question: "The concept of least privilege involves granting",
        questionId: 'a-13',
        status: TrainingAssignmentExamAnswerStatus.Validation,
        order: 1,
        type: TrainingExamQuestionType.Paragraph,
        points: 13,
        options: [{
            correct: true,
            option: "Option1",
            order: 2,
            points: 5,
        }]
    },
    {
        answer: ["The concept of least privilege involves granting the minimal level of access and permissions necessary for individuals or processes to perform their tasks. In secure development, it's crucial as it limits potential exposure to security risks. By giving only the precise permissions needed and nothing more, the principle significantly reduces the attack surface for potential threats. This approach helps prevent unauthorized access, minimizes the impact of security breaches, and enhances overall system security by restricting unnecessary privileges."],
        description: "The \"least privilege\" concept in secure software development involves providing the minimum level of access and permissions necessary to perform tasks. It's crucial for reducing potential vulnerabilities and limiting the possible impact of security breaches.",
        question: "The concept of least privilege involves granting",
        questionId: 'b-22',
        order: 1,
        status: TrainingAssignmentExamAnswerStatus.Validation,
        type: TrainingExamQuestionType.Paragraph,
        points: 22,
        options: [{
            correct: true,
            option: "Option1",
            order: 2,
            points: 5,
        }]
    },
    {
        answer: ["The concept of least privilege involves granting the minimal level of access and permissions necessary for individuals or processes to perform their tasks. In secure development, it's crucial as it limits potential exposure to security risks. By giving only the precise permissions needed and nothing more, the principle significantly reduces the attack surface for potential threats. This approach helps prevent unauthorized access, minimizes the impact of security breaches, and enhances overall system security by restricting unnecessary privileges."],
        description: "The \"least privilege\" concept in secure software development involves providing the minimum level of access and permissions necessary to perform tasks. It's crucial for reducing potential vulnerabilities and limiting the possible impact of security breaches.",
        question: "The concept of least privilege involves granting",
        questionId: 'c-43',
        status: TrainingAssignmentExamAnswerStatus.Validation,
        type: TrainingExamQuestionType.ShortAnswer,
        points: 43,
        order: 1,
        options: [{
            correct: true,
            option: "Option1",
            order: 2,
            points: 5,
        }]
    }
]