import {useAppDispatch} from "../../../../../../newShared/redux";
import {useSelector} from "react-redux";
import {
    hideDeleteSettingRowDialog,
    isSettingsLoadingSelector,
    vendorsDialogsSelector,
    vendorsSettingsSelector
} from "../../../store/slice";
import {deleteVendorsSettingsRowAction} from "../../../store/actions";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {parseForSettingDelete} from "../../../helpers";

export const useDeleteSetting = () => {
    const dispatch = useAppDispatch();
    const {deleteSettingRow} = useSelector(vendorsDialogsSelector);
    const isSettingsLoading = useSelector(isSettingsLoadingSelector);
    const settings = useSelector(vendorsSettingsSelector);
    const {setMessage} = useMessageDialog();

    const handleClose = () => {
        dispatch(hideDeleteSettingRowDialog());
    }

    const onSuccess = () => {
        deleteSettingRow.type && setMessage({title: 'Completed successfully', message: parseForSettingDelete(deleteSettingRow.type)});
    }

    const handleSubmit = () => {
        deleteSettingRow.type && deleteSettingRow.itemToDelete && settings && dispatch(deleteVendorsSettingsRowAction({
           data:{
            workspaceId: '',
            organizationId: '',
            field: deleteSettingRow.type,
            title: '',
            oldTitle: (deleteSettingRow.itemToDelete).name,
           },
            onSuccess
        }));
    }


    return{
        type: deleteSettingRow.type,
        isOpen: deleteSettingRow.isOpen,
        isLoading: deleteSettingRow.isLoading,
        isSettingsLoading,
        handleClose,
        handleSubmit,
    }
}