import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import ReportIcon from '@mui/icons-material/Report';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import React from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Typo} from "../../../../../newShared/components/Typography";
import {Flex} from "../../../../../newShared/components/Layouts";
import {invitationPageErrorType, useAcceptInvitation} from "../../hooks/invites/useAcceptInvitation";
// import {ChangeLanguageDialog} from "../../../../../shared/components/changeLanguageDialog/hook";
import {LocalSpinner} from "../../../../../newShared/components/Spinner";

export const AcceptInvite = () => {
    const {
        errors,
        isLoading,
        // invitationData,
        checkIfExistsError,
    } = useAcceptInvitation();

    // const {
    //     signUp,
    //     goToLicense,
    //     view,
    //     configure2Fa,
    //     chooseToUse2Fa,
    //     recoveryCodes,
    //     handleGoToLogin,
    //     showSecret,
    //     setShowSecret
    // } = useUserSignUpByInvite({invitationData});

    // const {toggleVisibility, isOpen} = ChangeLanguageDialog();



    return (
        <Flex w={'100%'} maxw={'400px'} m={'0 auto'} h={'100%'} ai={'center'} jc={'center'}>
            {isLoading && <LocalSpinner />}
            {!isLoading && checkIfExistsError && <ErrorsElement errors={errors}/>}
        </Flex>
    )
}

const ErrorsElement = ({errors}: { errors: invitationPageErrorType }) => {

    const {t} = useMainTranslation('', {keyPrefix: 'pathLogin.inviteErrorsElement'});

    return(
        <Flex w={'100%'} m={'16px 0 0 0'} direction={'column'} ai={'center'} talign={'center'}>
            {errors.isInviteExpired &&
                <>
                    <RunningWithErrorsIcon style={{width: '100px', height: '100px', color: '#ff5c5c'}}/>
                    <Typo fontSize={'20px'} fontWeight={500}>{t('Invite is expired. Contact your organization owner or admin to resend invite.')}</Typo>
                </>
            }
            {errors.isInviteNotFound &&
                <>
                    <ReportIcon style={{width: '100px', height: '100px', color: '#ff5c5c'}}/>
                    <Typo fontSize={'20px'} fontWeight={500}>{t('Invite not found. Contact your organization owner or admin to resend invite.')}</Typo>
                </>
            }
            {errors.isNotForThisUser &&
                <>
                    <ReportIcon style={{width: '100px', height: '100px', color: '#ff5c5c'}}/>
                    <Typo fontSize={'20px'} fontWeight={500}>{t('This invite was sent to another user. If you sure this invite belongs to you - sign in with a correct account.')}</Typo>
                </>
            }
            {errors.isAlreadyActivated &&
                <>
                    <VerifiedUserIcon style={{width: '100px', height: '100px', color: '#64B5F6'}}/>
                    <Typo fontSize={'20px'} fontWeight={500}>This invite is already used.</Typo>
                </>
            }
            {errors.badInvitationCode &&
                <>
                    <SearchOffIcon style={{width: '100px', height: '100px', color: '#ff5c5c'}}/>
                    <Typo fontSize={'20px'} fontWeight={500}>Invitation not found.</Typo>
                </>
            }
            {errors.isUserExistsButNotSignedIn &&
                <>
                    <ReportIcon style={{width: '100px', height: '100px', color: '#ff5c5c'}}/>
                    <Typo fontSize={'20px'} fontWeight={500}>{t('This invitation sent to user that already exists. Please sign in to accept invite.')}</Typo>
                </>
            }
        </Flex>
    )
}