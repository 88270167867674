import colors from "../../../../../../../newShared/theme/colors";
import {styled, Typography} from "@mui/material";

export const ExactRequestContainer = styled('div')(({margin}:{margin: string}) => ({
    width: '100%',
    padding: '32px 20px',
    borderRadius: '6px',
    border: `1px solid ${colors.stroke.grey}`,
    boxShadow: `0px 0px 1px 0px rgba(125, 151, 174, 0.02), 0px 2px 6px 0px rgba(125, 151, 174, 0.02), 0px 8px 16px 0px rgba(125, 151, 174, 0.08)`,
    display: 'flex',
    flexDirection: 'column',
    margin,
}))

export const ExactRequestContainerTitle = styled(Typography)({

})
ExactRequestContainerTitle.defaultProps = {
    variant: 'bodySemiBold',
    color: colors.grayText
}

export const ExactRequestTextUnionContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
})

export const FieldTitleText = styled(Typography)({

})
FieldTitleText.defaultProps = {
    variant: 'body1',
    color: colors.text.grey
}

export const FieldValueText = styled(Typography)({

})
FieldValueText.defaultProps = {
    variant: 'body1',
    color: colors.grayText
}

export const FileContainer = styled('div')({
    display: 'flex',
    // border: `1px solid ${colors.stroke.green}`,
    // borderRadius: '6px',
    padding: '8px 16px',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap:  '16px'
})