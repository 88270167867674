import {TRiskModel} from "../../types";
import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {Flex} from "../../../../../newShared/components/Layouts";
import colors from "../../../../../newShared/theme/colors";
import React, {Fragment} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const ModelTable = ({model}: {model: TRiskModel}) => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'risks'});
    return(
        <Table size={"small"} sx={{
            '& .MuiTableCell-root': {border: '1px solid black'},
        }}>
            <TableHead>
                <TableRow>
                    <TableCell rowSpan={2} style={{
                        padding: '3px',
                        backgroundImage: !revDir ? 'linear-gradient(to right top, white calc(50% - 1px), black, white calc(50% + 1px))' : 'linear-gradient(to left top, white calc(50% - 1px), black, white calc(50% + 1px))'
                    }}>
                        <Flex direction={"column"} w={'100%'} h={'100%'} jc={"space-between"} minh={'50px'}>
                                                <span style={{alignSelf: "flex-end", maxWidth: '50%'}}>
                                                    <Typography fontSize={'10px'} fontWeight={400} color={colors.grayText}>{t('Impact')}</Typography>
                                                </span>
                            <span style={{alignSelf: "flex-start", maxWidth: '50%'}}>
                                                    <Typography fontSize={'10px'} fontWeight={400} color={colors.grayText}>{t('Probability')}</Typography>
                                                </span>
                        </Flex>
                    </TableCell>
                    {model.impacts.slice().sort((a, b) => a.quantitative - b.quantitative).map(impact => (
                        <TableCell key={'header impact ' + Math.random()} align={"center"}
                                   width={Math.floor(100 / (model.impacts.length + 1)) + '%'}
                                   style={{borderBottom: '1px solid black'}}>
                            <Typography fontSize={'10px'} fontWeight={400} color={colors.grayText}>{impact.quality}</Typography>
                        </TableCell>
                    ))}
                </TableRow>
                <TableRow>
                    {model.impacts.slice().sort((a, b) => a.quantitative - b.quantitative).map(impact => (
                        <TableCell key={'header impact ' + Math.random()} align={"center"}
                                   width={Math.floor(100 / (model.impacts.length + 1)) + '%'}
                                   style={{borderTop: '1px solid black'}}>
                            <Typography fontSize={'10px'} fontWeight={400} color={colors.grayText}>{impact.quantitative}</Typography>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>

            <TableBody>
                {model.probabilities.slice().sort((a, b) => a.quantitative - b.quantitative).map(probability => (
                    <Fragment key={'row probability' + probability.quality}>
                        <TableRow>
                            <TableCell align={"center"} style={{borderBottom: '1px solid black'}}>
                                <Typography fontSize={'10px'} fontWeight={400} color={colors.grayText}>{probability.quality}</Typography>
                            </TableCell>

                            {model.impacts.slice().sort((a, b) => a.quantitative - b.quantitative).map(impact => (
                                <TableCell key={'column probability impact ' + Math.random().toString()}
                                           align={"center"} rowSpan={2} width={Math.floor(100 / (model.impacts.length + 1)) + '%'}
                                           style={{
                                               fontSize: '18px', fontWeight: 400,
                                               backgroundColor: probability.quantitative * impact.quantitative < model.left
                                                   ? colors.warningActions.green
                                                   : probability.quantitative * impact.quantitative <= model.right
                                                       ? colors.darkOrange
                                                       : colors.warningActions.red
                                           }}
                                >
                                    <Typography fontSize={'10px'} fontWeight={400} color={colors.grayText}>{probability.quantitative * impact.quantitative}</Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell align={"center"}
                                       style={{border: '1px solid black'}}>
                                <Typography fontSize={'10px'} fontWeight={400} color={colors.grayText}>{probability.quantitative}</Typography>
                            </TableCell>
                        </TableRow>
                    </Fragment>
                ))}
            </TableBody>
        </Table>
    )
}