import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    CORPORATE_CLIENTS_PAGE_TYPE,
    CORPORATE_TARGET_TYPE,
    CRYPTOWALLET_TARGET_TYPE,
    INDIVIDUAL_CLIENTS_PAGE_TYPE,
    KYC_CLIENTS_ID_FIELDS_PATH,
    KYC_ROOT_PATH,
    KYC_SCREENING_LIST_PATH,
    PERSON_TARGET_TYPE,
    SCREENINGS_DEFAULT_PAGING,
    ScreeningStatusVariants
} from "../../../constants";
import {
    eraseIsDataOutdated,
    eraseScreenings,
    isDataOutdated,
    loadings as loadingsSelector,
    replaceScreeningsMinMaxLoadedPage,
    screeningsSelector
} from "../../../store/slice";
import {GetKycScreeningsWithFilterAndPaging,} from "../../../store/actions";
import {useEffect} from "react";

import {KycScreeningModel} from "../../../../../../newShared/GQLTypes";
import {keyConfig} from "../../../../../../newShared/components/genericFilter/types";
import {TScreeningStatus} from "../../../types";
import {
    useGenericFiltersStorage
} from "../../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {getNormalizedTargetType} from "../../../helpers";
import {TIdName} from "../../../../../../newShared/types";
import {autocompleteKycTemplatesByScreenings} from "../../../api";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../../newShared/components/genericTable/types";


export const useKycScreeningsList = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.screenings'});

    //root
    const dispatch = useDispatch();
    const history = useHistory();

    //selectors
    const {tMenu} = useMainTranslation();
    const isOutdated = useSelector(isDataOutdated).screeningList;
    const {screenings, pageInfo, minMaxLoadedPage: {minLoadedPage, maxLoadedPage}} = useSelector(screeningsSelector);
    const loadings = useSelector(loadingsSelector);
    // const [isInitial, setIsInitial] = useState<boolean>(true);
    const {clearFilters} = useGenericFiltersStorage();
    const {currentSearchForFetch, currentFiltersForFetch} = useGenericFiltersStorage();

    //controlled
    // const controller = new AbortController();


    //useEffects
    useSetBreadcrumbs([
        {
            title: tMenu('KYC Management'),
            path: KYC_ROOT_PATH
        },
        {
            title: tMenu('Screening'),
            path: KYC_SCREENING_LIST_PATH
        }
    ]);

    useEffect(() => {
        // dispatch(GetKycScreeningsWithFilterAndPaging({
        //     data: { pageInfo: {page: 0, count: SCREENINGS_DEFAULT_PAGING} },
        //     signal: controller.signal
        // }));
        // setIsInitial(false);

        return () => {
            // controller.abort();
            dispatch(eraseScreenings());
            clearFilters();
        }
        //eslint-disable-next-line
    }, []);


    const handleViewFullReport = (screening: KycScreeningModel) => {
        // dispatch(setSelectedScreening(screening));
        history.push(`${KYC_SCREENING_LIST_PATH}/${screening.id}`);
    }

    const handleGoToExactTarget = (targetId: string, targetType: string) => {
        // e.stopPropagation();
        history.push(KYC_CLIENTS_ID_FIELDS_PATH.replace(':id', targetId).replace(':type', targetType === PERSON_TARGET_TYPE ? INDIVIDUAL_CLIENTS_PAGE_TYPE : CORPORATE_CLIENTS_PAGE_TYPE));
        // history.push(KYC_CLIENTS_ID_PATH.replace(':id', targetId).replace(':type', targetType === PERSON_TARGET_TYPE ? INDIVIDUAL_CLIENTS_PAGE_TYPE : CORPORATE_CLIENTS_PAGE_TYPE));
    }

    //config filters
    const statusConfig: keyConfig<{status: TScreeningStatus, title: string}> = {
        type: 'filter',
        key: 'status',
        name: t('Status'),
        options: ScreeningStatusVariants,
        isOptionEqualToSearch: (option, search) => option.status.trim().toLowerCase().includes(search.trim().toLowerCase()),
        getFilterValue: (a) => a.status,
        getOptionLabel: (e) => e.title,
        default: true
    }

    const startDate: keyConfig<string> = {
        type: 'date',
        key: 'startDate',
        name: t('Start date'),
        default: true,
    }

    const targetType: keyConfig<string> = {
        type: 'filter',
        key: 'targetType',
        name: t('Type'),
        options: [PERSON_TARGET_TYPE, CORPORATE_TARGET_TYPE, CRYPTOWALLET_TARGET_TYPE],
        getOptionLabel: (e) => getNormalizedTargetType(e),
        default: true
    };

    const templatesConfig: keyConfig<TIdName> = {
        type: 'filter',
        key: 'templateId',
        name: t('Template'),
        fetchOptions: (workspaceId, data, signal) => {
            return autocompleteKycTemplatesByScreenings({...data, workspaceId})
                .then(resp => ({values: resp.templates, pageInfo: resp.pageInfo}))
        },
        // options: _recipientVariants?.recipients ? _recipientVariants?.recipients : [],
        getFilterValue: (a) => a.id,
        getOptionLabel: (e) => e.name,
        isOptionEqual: (a, b) => a.id === b.id,
        isOptionEqualToSearch: (a, search) => a.name.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: true,
    }


    //scroll
    // const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
    //     getData: ({page, count}) => {
    //         dispatch(GetKycScreeningsWithFilterAndPaging({data: {pageInfo: {page, count: SCREENINGS_DEFAULT_PAGING, filters: {...currentFiltersForFetch, nameLike: [currentSearchForFetch] }}}, signal: controller.signal }))
    //     },
    //     arrayLength: screenings.length,
    //     isLoading: loadings.isLoadingList,
    //     pageInfo: pageInfo,
    //     // cleanData: () => dispatch(eraseCategories()),
    //     // controller: controller,
    //     // isOnTop: true,
    //     isNoMoreThanTotal: true,
    //     customDependencies: [],
    //     is0PageAddictive: false,
    //     disableInitFetch: true,
    // })

    const handleReloadData = () => {
        const filters = currentFiltersForFetch;
        const search = currentSearchForFetch;

        dispatch(eraseScreenings());
        dispatch(eraseIsDataOutdated());
        dispatch(replaceScreeningsMinMaxLoadedPage({maxLoadedPage: -1, minLoadedPage: -1}));
        dispatch(GetKycScreeningsWithFilterAndPaging({data: {pageInfo: {page: 0, count: SCREENINGS_DEFAULT_PAGING, filters: {...filters, nameLike: [search] }}}}))
    } 

    return {
        screenings,
        pageInfo,
        loadings,
        configs: [
            templatesConfig,
            statusConfig,
            startDate,
            targetType
        ],

        handleViewFullReport,
        handleGoToExactTarget,
        // isNoScreeningsFound: !loadings.isLoadingList && !screenings.length && !isInitial ,

        // scroll: {
        //     bottomLoader,
        //     onScroll,
        //     scrollRef,
        //     hasMore: pageInfo.total > ((pageInfo.page + 1) * pageInfo.count),
        // },
        isOutdated,
        handleReloadData,
        genericTable: {
            paging: {
                pageInfo,
                isLoading: loadings.isLoadingList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceScreeningsMinMaxLoadedPage(data)),
                clearRows: () => dispatch(eraseScreenings()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    dispatch(GetKycScreeningsWithFilterAndPaging({data: {pageInfo: {page, count, filters}}}));
                },
            },
        },
    }

}
