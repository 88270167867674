import {MainVicaCategoryLocalModel} from "../../../../../types";
import {
    IconButton,
    styled,
    TableCell,
    TableRow,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import React from "react";
import {Flex} from "../../../../../../../../newShared/components/Layouts";
import colors from "../../../../../../../../newShared/theme/colors";
import {CommonAvatar} from "../../../../../../../../newShared/components/Basic/CommonAvatar";
import {ProgressBar} from "../progressBar";
import {TypographyNew} from "../../../../../../../../newShared/components/TypographyNew";
import {useVicaCategory} from "../../../../../hooks/useVicaCategory";
import {VicaTaskTableRow} from "../vicaTaskTableRow";
import {AddAvatarButton} from "../../../../../../../../newShared/components/Basic/AddAvatarButton";
import {VicaTaskTableRowSkeleton} from "../vicaTaskTableRowSkeleton";
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const ProgressToolTip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({

    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: colors.backgrounds.grey_light,
        borderRadius: '6px',
        padding: '8px 16px',
        color: colors.text.dark,
        fontWeight: 400,
        fontSize: '14px',
        marginTop: '0px !important',
    },

    [`& .${tooltipClasses.arrow}`]: {
        color: colors.backgrounds.grey_light,
    }
}));

export const VicaCategoryTableRow = ({category}: {category: MainVicaCategoryLocalModel}) => {
    const {revDir} = useMainTranslation();
    const {handleOpenSupervisorDialog, handleToggleCategory, progress, isLoading} = useVicaCategory({category});
    return(
        <>
            <TableRow>
                <TableCell colSpan={4}>
                    <Flex ai={'center'} gap={'20px'}>
                        <Flex ai={'center'} gap={'10px'}>
                            <IconButton size={'small'} onClick={handleToggleCategory}>
                                {category.isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightOutlinedIcon />}
                            </IconButton>

                            <TypographyNew variant={'*bodyText2_semibold'} color={colors.grayText} noWrap sx={{cursor: 'pointer'}}>{category.name}</TypographyNew>
                        </Flex>

                        <Flex ai={'center'} gap={'8px'} cursor={'pointer'} onClick={handleOpenSupervisorDialog}>
                            <Typography variant={'*bodyText2'} color={colors.text.grey}>{('Supervisor:')}</Typography>

                            {category.supervisor ?
                                <Flex ai={'center'} gap={'8px'}>
                                    <CommonAvatar size={'d24'} name={(category.supervisor.fullName.trim())}/>
                                    <TypographyNew variant={'*bodyText2'} color={colors.grayText} noWrap>{category.supervisor.fullName}</TypographyNew>
                                </Flex>
                                :
                                <AddAvatarButton btnSize={'d24'} />
                            }
                        </Flex>
                    </Flex>
                </TableCell>

                <TableCell>
                    <ProgressToolTip
                        placement={'left'}
                        title={<Typography variant={'bodySmall'} color={colors.grayText}>{`${category.progress.completedSteps}/${category.progress.totalSteps} completed`}</Typography>}
                    >
                        <Flex maxw={'150px'}>
                            <ProgressBar progress={progress}/>
                        </Flex>
                    </ProgressToolTip>
                </TableCell>
            </TableRow>

            {category.isOpen &&
                <>
                    {!category.stepsLocal.length ?
                        <>
                            {isLoading ?
                                <VicaTaskTableRowSkeleton />
                                :
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <Flex m={!revDir ? '0 0 0 50px' : '0 50px 0 0'}>
                                            <Typography variant={'*bodyText2'} color={colors.grayText}>{('Nothing was found for your query')}</Typography>
                                        </Flex>
                                    </TableCell>
                                </TableRow>
                            }
                        </>
                        :
                        category.stepsLocal.map(e => <VicaTaskTableRow key={e.id} step={e} category={category}/>)}
                </>
            }
        </>
    )
}