import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {useEffect, useState} from "react";
import {AutocompleteCollaborators} from "../../../../../../newShared/components/AutocompleteCollaborators";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {uuid} from "../../../../../../newShared/utils";
import {useSelectSupervisorDialog} from "../../../hooks/dialogs/useSelectSupervisorDialog";

export const SelectSupervisorDialog = () => {
    const {
        category,
        supervisor,
        setSupervisor,
        isOpen,
        handleClose,
        isOk,
        handleSubmit,
        isLoading
    } = useSelectSupervisorDialog();
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'pathVica.TasksList.dialogs'});

    const [uniqueInputId, setUniqueInputId] = useState<string>(uuid());
    useEffect(() => {
        setUniqueInputId(uuid())
    }, [category?.supervisor]);

    return(
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{t(`Set {{name}} category supervisor`, {name: category?.name ?? ''})}</DialogTitle>

            <DialogContent>
                <AutocompleteCollaborators<false, false, false>
                    label={t("Supervisor")}
                    getOptionLabelCustom={"name+email"}
                    key={uniqueInputId}
                    defaultValue={supervisor}
                    onChange={setSupervisor}
                    size={'small'}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{t('Close')}</Button>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                    disabled={!isOk}
                >
                    {t('Save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}