import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import colors from "../../../../../../newShared/theme/colors";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDeleteRoleDialog} from "../../../hooks/dialogHooks/useDeleteRoleDialog";
import {deleteRoleDialogPropsTypes} from "../../../types";

export const DeleteRoleDialog = (props: deleteRoleDialogPropsTypes) => {
    const {isOpen, role, handleSubmit, handleClose} = useDeleteRoleDialog(props);
    const {isMobile} = useMedia();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.CreateRow'});

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t(`Delete {{roleName}} role`, {roleName: role?.roleName ?? ''})}</DialogTitle>

            <DialogContent>
                <Typography variant={'body2'} color={colors.darkBlueText}>
                    {t(`If you delete {{roleName}}, you will not be able to restore it.`, {roleName: role?.roleName ?? ''})}
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{t('Cancel')}</Button>
                <Button
                    variant={'contained'}
                    size={isMobile ? 'small' : 'medium'}
                    onClick={handleSubmit}
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                >{t('Delete')}</Button>
            </DialogActions>
        </Dialog>
    )
}