import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TInvitationPageInviteType, TInvitationPageSearchType} from "../../../types";
import {SETTINGS_INVITES_PATH} from "../../../constants";
import {isExpired} from "../../../../../../newShared/utils/dateTools";
import {getInviteByIdForAccept} from "../../../api";
import {UseManageWorkspacesAndOrganizations} from "../../../../cookies/hooks/useManageWorkspacesAndOrganizations";
import {useParameters} from "../../../../../../newShared/hooks/useParameters";

export type invitationPageErrorType = {
    isInviteExpired: boolean;
    isNotForThisUser: boolean;
    isAlreadyActivated: boolean;
    badInvitationCode: boolean;
    isInviteNotFound: boolean;
    isUserExistsButNotSignedIn: boolean;
}

export const useAcceptInvitation = () => {
    // const {t} = useMainTranslation('', {keyPrefix: 'workspacesPage.invites'});
    const {search} = useLocation();
    // const dispatch = useDispatch();
    const {user} = UseManageWorkspacesAndOrganizations();
    const history = useHistory();
    const {filter} = useParameters();
    const {currentLanguage} = useMainTranslation();

    const [errors, setErrors] = useState<invitationPageErrorType>({isAlreadyActivated: false, isInviteExpired: false, isNotForThisUser: false, badInvitationCode: false, isInviteNotFound: false, isUserExistsButNotSignedIn: false});
    const [invitationData, setInvitationData] = useState<TInvitationPageSearchType | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [fetchedInvite, setFetchedInvite] = useState<TInvitationPageInviteType | null>(null);

    const parseSearch = (search: string): TInvitationPageSearchType | null => {
        const cuttedSaerch = search.substring('?INVITE_CODE='.length);
        // console.log(cuttedSaerch);
        try{
            // const parsedBase64 = Buffer.from(cuttedSaerch, 'base64').toString();
            const parsedBase64 = window.atob(cuttedSaerch);
            if(parsedBase64){
                const parsedJSON = JSON.parse(parsedBase64) as TInvitationPageSearchType;
                return parsedJSON;
            }
        }catch (ex:any){
            console.log(`useAcceptInvitation: parseSearch error ${ex.message}`);
            setErrors({...errors, badInvitationCode: true});
        }

        return null;
    }

    useEffect(() => {
        const data = parseSearch(search);
        data && setInvitationData(data);
        if(data && user){
            //user signed in
            if(user.email.trim().toLowerCase() !== data.email.trim().toLowerCase()){
                //user signed in but the invite is not for this user
                setErrors({...errors, isNotForThisUser: true});
            }else{
                //user signed in - going to invites
                history.push(SETTINGS_INVITES_PATH);
                // history.push(PATH_LOCAL_NEW_WORKSPACES + INVITES + `/${data.inviteId}`);
            }
        }else{
            //user is not signed in - FETCH DATA
            data && getInviteById(data.inviteId);
        }

        return function cleanUp(){
            setErrors({isAlreadyActivated: false, isInviteExpired: false, isNotForThisUser: false, badInvitationCode: false, isInviteNotFound: false, isUserExistsButNotSignedIn: false});
            setFetchedInvite(null);
            setInvitationData(null);
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        //waiting for invite fetch and checking if it is expired
        if(fetchedInvite){
            if(!user && fetchedInvite.isUserExists){
                setErrors({...errors, isUserExistsButNotSignedIn: true});
            }
            if(user && fetchedInvite.emailAddress.trim().toLowerCase() !== user.email.trim().toLowerCase()){
                setErrors({...errors, isNotForThisUser: true});
            }
            if(isExpired(fetchedInvite.expirationDate, currentLanguage.momentLocale)){
                setErrors({...errors, isInviteExpired: true});
            }else if(fetchedInvite.activationDate){
                setErrors({...errors, isAlreadyActivated: true});
            }
        }
        //eslint-disable-next-line
    }, [fetchedInvite]);

    const getInviteById = async(id: string) => {
        try{
            setIsLoading(true);
            //setFetchedInvite
            const data = await getInviteByIdForAccept(id);
            setFetchedInvite(data);
            setIsLoading(false);
        }catch (ex){
            setIsLoading(false);
            setErrors({...errors, isInviteNotFound: true});
        }
    }

    const checkIfExistsError = (): boolean => {
        return filter?.INVITE_CODE && (errors.badInvitationCode || errors.isInviteNotFound || errors.isAlreadyActivated || errors.isNotForThisUser || errors.isInviteExpired || errors.isUserExistsButNotSignedIn);
    }

    return{
        isLoading,
        errors,
        fetchedInvite,
        invitationData,
        checkIfExistsError: checkIfExistsError(),
    }
}