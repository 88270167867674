import axiosSync from "../../../../newShared/utils/axios/axios";

import {
    changeRisksModel,
    createRisk,
    createRiskRegistry,
    createRisksModels,
    deleteRiskById,
    deleteRiskRegistry,
    deleteRisksModel,
    exportCsvRisks,
    getRiskByIdAndRegistryId,
    getRiskRegisters,
    getRiskRegistyByIdQuery,
    getRisksByRegistry,
    getRisksModelById,
    getRisksModels,
    importCsvTemplateRisks,
    releaseRiskRegistry,
    updateRiskRegistry,
    updateRisksByRegistryId,
    updateRisksModel
} from "./query";

import {AxiosResponse} from "axios";
import {
    ChangeRisksModelMutation,
    ChangeRisksModelMutationVariables,
    CreateRiskMutation,
    CreateRiskMutationVariables,
    CreateRiskRegistryMutation,
    CreateRiskRegistryMutationVariables,
    CreateRisksModelsMutation,
    CreateRisksModelsMutationVariables,
    DeleteRiskByIdMutation,
    DeleteRiskByIdMutationVariables,
    DeleteRiskRegistryMutation,
    DeleteRiskRegistryMutationVariables,
    DeleteRisksModelMutation,
    DeleteRisksModelMutationVariables,
    ExportCsvRisksQuery,
    ExportCsvRisksQueryVariables,
    GetRiskByIdAndRegistryIdQuery,
    GetRiskByIdAndRegistryIdQueryVariables,
    GetRiskRegistersQuery,
    GetRiskRegistersQueryVariables,
    GetRisksAvailableFieldsForExportQuery,
    GetRisksAvailableFieldsForExportQueryVariables,
    GetRisksByRegistryQueryVariables,
    GetRisksModelByIdQuery,
    GetRisksModelByIdQueryVariables,
    GetRisksModelsQuery,
    GetRisksModelsQueryVariables,
    ReleaseRiskRegistryMutation,
    ReleaseRiskRegistryMutationVariables,
    RiskRegistryModel,
    UpdateRiskRegistryMutation,
    UpdateRiskRegistryMutationVariables,
    UpdateRisksByRegistryIdMutation,
    UpdateRisksByRegistryIdMutationVariables,
    UpdateRisksModelMutation,
    UpdateRisksModelMutationVariables
} from "../../../../newShared/GQLTypes";
import {PATH_SERVER} from "../../../../newShared/constants";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {TRisk, TRiskRegistry} from "../types";
import {TPageInfo} from "../../../../newShared/types";
import {print} from "graphql";
import {gql} from "@apollo/react-hooks";
// import {TExtendedCollaborator} from "../../../../domain/newWorkspaces/types";


export const getRiskRegistersApi = async (data: GetRiskRegistersQueryVariables, signal?: AbortSignal): Promise<GetRiskRegistersQuery['getRiskRegisters']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getRiskRegisters,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.getRiskRegisters
        })
    )
)

export const getRiskRegistyById = async (data: {workspaceId: string, organizationId: string, id: string}, signal?: AbortSignal): Promise<RiskRegistryModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getRiskRegistyByIdQuery,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.getRiskRegisteryById
        })
    )
)

export const createRiskRegistryApi = async (data: CreateRiskRegistryMutationVariables): Promise<CreateRiskRegistryMutation['createRiskRegistry']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: createRiskRegistry,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.createRiskRegistry
        })
    )
)

export const updateRiskRegistryApi = async (data: UpdateRiskRegistryMutationVariables): Promise<UpdateRiskRegistryMutation['updateRiskRegistry']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: updateRiskRegistry,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.updateRiskRegistry
        })
    )
)

export const deleteRiskRegistryApi = async (data: DeleteRiskRegistryMutationVariables): Promise<DeleteRiskRegistryMutation['deleteRiskRegistry']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: deleteRiskRegistry,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.deleteRiskRegistry
        })
    )
)

export const releaseRiskRegistryApi = async (data: ReleaseRiskRegistryMutationVariables): Promise<ReleaseRiskRegistryMutation['releaseRiskRegistry']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: releaseRiskRegistry,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.releaseRiskRegistry
        })
    )
)

///
///
///
export const getRisksByRegistryApi = async (data: GetRisksByRegistryQueryVariables, signal?: AbortSignal): Promise<{data: TRisk[], pageInfo: TPageInfo, registry: TRiskRegistry}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getRisksByRegistry,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.getRisksByRegistry
        })
    )
)


export const createRiskApi = async ( data: CreateRiskMutationVariables):Promise<CreateRiskMutation['createRisk']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: createRisk,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.createRisk
        })
    )
)

export const getRiskByIdAndRegistryIdApi = async (data: GetRiskByIdAndRegistryIdQueryVariables, signal?: AbortSignal): Promise<GetRiskByIdAndRegistryIdQuery['getRiskByIdAndRegistryId']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getRiskByIdAndRegistryId,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.getRiskByIdAndRegistryId
        })
    )
)

export const updateRisksByRegistryIdApi = async (data: UpdateRisksByRegistryIdMutationVariables): Promise<UpdateRisksByRegistryIdMutation['updateRisksByRegistryId']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: updateRisksByRegistryId,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.updateRisksByRegistryId
        })
    )
)

export const deleteRiskByIdApi = async (data: DeleteRiskByIdMutationVariables): Promise<DeleteRiskByIdMutation['deleteRiskById']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: deleteRiskById,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.deleteRiskById
        })
    )
)

//
//
// Models
export const getRisksModelsApi = async (data: GetRisksModelsQueryVariables, signal?: AbortSignal): Promise<GetRisksModelsQuery['getRisksModels']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getRisksModels,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.getRisksModels
        })
    )
)

export const getRisksModelByIdApi = async (data: GetRisksModelByIdQueryVariables, signal?: AbortSignal): Promise<GetRisksModelByIdQuery['getRisksModelById']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: getRisksModelById,
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.getRisksModelById
        })
    )
)

export const createRisksModelsApi = async (data: CreateRisksModelsMutationVariables): Promise<CreateRisksModelsMutation['createRisksModels']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: createRisksModels,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.createRisksModels
        })
    )
)

export const changeRisksModelApi = async (data: ChangeRisksModelMutationVariables): Promise<ChangeRisksModelMutation['changeRisksModel']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: changeRisksModel,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.changeRisksModel
        })
    )
)

export const updateRisksModelApi = async (data: UpdateRisksModelMutationVariables): Promise<UpdateRisksModelMutation['updateRisksModel']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: updateRisksModel,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.updateRisksModel
        })
    )
)

export const deleteRisksModelApi = async (data: DeleteRisksModelMutationVariables): Promise<DeleteRisksModelMutation['deleteRisksModel']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: deleteRisksModel,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.deleteRisksModel
        })
    )
)

export const exportCsvRisksApi = async (data: ExportCsvRisksQueryVariables): Promise<ExportCsvRisksQuery['exportCsvRisks']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: exportCsvRisks,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.exportCsvRisks
        })
    )
)

export const getRisksAvailableFieldsForExport = async (data: GetRisksAvailableFieldsForExportQueryVariables): Promise<GetRisksAvailableFieldsForExportQuery["getRisksAvailableFieldsForExport"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getRisksAvailableFieldsForExport($workspaceId:String!){
                        getRisksAvailableFieldsForExport(workspaceId:$workspaceId){
                            fields {
                                fieldKey
                                fieldTitle
                            }
                        }
                    }
                `),
                variables: {
                    ...data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getRisksAvailableFieldsForExport;
    })
);


export const importCsvTemplateRisksApi = async (data: { workspaceId: string, organizationId: string }): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: importCsvTemplateRisks,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        ).then((result: AxiosResponse<any>) => {
            return result.data.data.importCsvTemplateRisks
        })
    )
)

// export const getOrganizationMembers = async (organizationId: string): Promise<TExtendedCollaborator[]> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                 query getOrganizationMembers($organizationId:String!){
//                   getProfilesOfOrganizationMembers(organizationId: $organizationId){
//                     firstName
//                     lastName
//                     email
//                     publicId
//                     role
//                     status
//                     lastLoginDate
//                     createDate
//                   }
//                 }
//                 `,
//                 variables: {
//                     organizationId
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.getProfilesOfOrganizationMembers;
//     })
// )
