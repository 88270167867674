import * as S from './styled';
import {TemplateTypeChip} from "../templateTypeChip";
import {useTemplateCard} from "../../hooks/templates/useTemplateCard";
import {Button, ListItemIcon, Typography} from "@mui/material";
import React from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {KycRefactorTemplateModel} from "../../../../../newShared/GQLTypes";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {Flex} from '../../../../../newShared/components/Layouts';
import colors from "../../../../../newShared/theme/colors";
import {CRYPTOWALLET_TARGET_TYPE} from "../../constants";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import GetAppIcon from '@mui/icons-material/GetApp';

export const TemplateCard = ({template} : {template: KycRefactorTemplateModel}) => {
    const {menu, actions} = useTemplateCard({template});
    const {isTablet} = useMedia();

    return(
        <S.TemplateCardContainer isMobile={isTablet} onClick={actions.handleView}>
            <S.TemplateCardTitleTypeMenuContainer>
                <S.TemplateCardTitleTypeContainer>
                    <S.TitleTypo>{template.name}</S.TitleTypo>
                    <TemplateTypeChip type={template.type}/>
                </S.TemplateCardTitleTypeContainer>

                <S.MenuButton
                    aria-label="long-button"
                    id="long-button"
                    aria-controls={menu.open ? 'long-menu' : undefined}
                    aria-expanded={menu.open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={menu.handleClick}
                >
                    <S.MenuButtonIcon />
                </S.MenuButton>

                <S.CardMenu
                    aria-haspopup="true"
                    id="long-button"
                    MenuListProps={{'aria-labelledby': 'long-button',}}
                    anchorEl={menu.anchorEl}
                    open={menu.open}
                    onClose={menu.handleClose}

                >
                    <S.MenuItemStyled onClick={menu.actions.handlePreview}>
                        <ListItemIcon>
                            <VisibilityIcon />
                        </ListItemIcon>
                        <S.ListItemText>{'Preview'}</S.ListItemText>
                    </S.MenuItemStyled>

                    <S.MenuItemStyled onClick={menu.actions.handleUseAsTemplate}>
                        <ListItemIcon>
                            <ContentCopyIcon />
                        </ListItemIcon>
                        <S.ListItemText>{'Make copy'}</S.ListItemText>
                    </S.MenuItemStyled>

                    <S.MenuItemStyled onClick={menu.actions.handleOpenExportCsv}>
                        <ListItemIcon>
                            <GetAppIcon />
                        </ListItemIcon>
                        <S.ListItemText>{'Export CSV'}</S.ListItemText>
                    </S.MenuItemStyled>

                    <S.MenuItemStyled onClick={menu.actions.handleDelete}>
                        <ListItemIcon>
                            <DeleteOutlineIcon />
                        </ListItemIcon>
                        <S.ListItemText>{'Delete'}</S.ListItemText>
                    </S.MenuItemStyled>
                </S.CardMenu>
            </S.TemplateCardTitleTypeMenuContainer>

            <Flex direction={'column'}>
                <Typography fontSize={'14px'} sx={{margin: '0 0 16px 0'}} fontWeight={600}>Check match ratio</Typography>
                {template.screeningSettings.checks.map(e => {
                    return(
                        <Typography key={e.name} fontSize={'14px'} fontWeight={500} color={e.apply ? colors.text.blue : colors.text.grey}>{e.name} : {e.matchingRatio / 100}</Typography>
                    )
                })}
            </Flex>
            {/*<S.TemplateDescriptionText>{template.description}</S.TemplateDescriptionText>*/}

            <S.TemplateCardActionsRow>
                <Button onClick={actions.handleInputClientData}  size={'small'}>{'Create client'}</Button>
                {template.type !== CRYPTOWALLET_TARGET_TYPE && <Button onClick={actions.handleSendToClient} variant={'outlined'} size={'small'}>{'Send to client'}</Button>}
                <Button onClick={actions.handleOTS} variant={'outlined'}  size={'small'}>{'OTS'}</Button>
                <Button onClick={menu.actions.handleImportCsv} variant={'outlined'}  size={'small'}>{'Import'}</Button>
            </S.TemplateCardActionsRow>
        </S.TemplateCardContainer>
    )
}
