import React, {FC, useEffect, useState} from "react";
import {Button, Typography} from "@mui/material";
import {SmallSizeDialogStyled} from "../manageActorsDialog/styled";
import {
    AddVariableDialogActionsStyled,
    AddVariableDialogContentStyled,
    AddVariableDialogTitleStyled
} from "../addVariableDialog/styled";
import {createEventCommonDialogOpen} from "../../../helpers";
import {TCommonDialogOpenEventData} from "../../../types";
import {commonDialogOpenEventName} from "../../../constants";
import {LoadingButton} from "../../../../Basic/CommonButtons";

interface IProps {
    onConfirm: () => void;
    isLoading: boolean;
}
export const OnRejectDialog: FC<IProps> = ({onConfirm, isLoading}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const type: TCommonDialogOpenEventData["type"] = "onReject";
    const text = `You are about to decline the document.`;
    const title = 'Decline the document';

    const handleClose = () => {
        createEventCommonDialogOpen({type, isOpen: false, data: []});
    };

    const handleEvent = (ev: Event) => {
        const {detail} = ev as CustomEvent<TCommonDialogOpenEventData>;
        // console.log('detail.isOpen', detail.isOpen, isOpen)
        if (detail.type === type) {
            setIsOpen(detail.isOpen);
        }
    }

    useEffect(() => {
        document.addEventListener(commonDialogOpenEventName, handleEvent);
        return () => {
            document.removeEventListener(commonDialogOpenEventName, handleEvent);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <SmallSizeDialogStyled open={isOpen} onClose={handleClose}>
            <AddVariableDialogTitleStyled>
                {title}
            </AddVariableDialogTitleStyled>

            <AddVariableDialogContentStyled>
                <Typography>{text}</Typography>
            </AddVariableDialogContentStyled>

            <AddVariableDialogActionsStyled>
                <Button variant={"text"} onClick={handleClose}>{'Cancel'}</Button>
                <LoadingButton loading={isLoading} variant={"contained"} onClick={onConfirm}>{'Decline'}</LoadingButton>
            </AddVariableDialogActionsStyled>
        </SmallSizeDialogStyled>
    )
}
