import {Box, Dialog, Divider, styled, TextField} from "@mui/material";
import colors from "../../../../../../theme/colors";
import {TypographyBody2} from "../../../../../Inputs/styled";

export const EditorManageActorsDialogStyled = styled(Dialog)(() => ({
    '.MuiPaper-root': {
        minWidth: '900px',
        height: '800px'
    },
}))

export const SmallSizeDialogStyled = styled(Dialog)(() => ({
    '.MuiPaper-root': {
        minWidth: '600px',
    }
}))

export const InfoViewContainer = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '32px 125px',
    overflow: 'auto',
}));


export const DocNameTextField = styled(TextField)(() => ({
    '&.MuiInputBase-root': {
        height: '40px',
    }
}));
DocNameTextField.defaultProps = {
    size: 'small'
}

export const DocDivider = styled(Divider)(() => ({
    color: colors.text.grey_light_2,
    borderColor: colors.text.grey_light_2,
    width: '100%',
    margin: '20px 0'
}));

export const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
}));

export const InfoActorFlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '42px',
    padding: '6px 0'
}));

export const AutocompleteBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
}));

export const SwitchInfoBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    border: '1px solid black',
    marginLeft: '8px',
    marginRight: '8px',

    '&: hover': {
        border: `1px solid ${colors.primary.blue}`,
    }
}));

export const ApproverBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

export const OrderBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '33px',
    height: '28px',
    border: `0.5px solid ${colors.text.grey}`
}));

export const InfoActorBox = styled(Box)(({h, mt}: {h?: string, mt?: string}) => ({
    marginTop: mt ? mt : 'undefined',
    padding: '4px 4px 4px 16px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: h ? h : '48px',
    border: `1px solid ${colors.stroke.grey}`,
    borderRadius: '8px',
    boxShadow: '0px 0px 1px 0px rgba(125, 151, 174, 0.02), 0px 2px 6px 0px rgba(125, 151, 174, 0.02), 0px 8px 16px 0px rgba(125, 151, 174, 0.08)',

}));

export const ApproverTitle = styled(TypographyBody2)(() => ({
    margin: '0 16px',
    flexGrow: 1,
}));

export const VerticalDivider = styled(Divider)(() => ({
    margin: '0 8px',
}));

VerticalDivider.defaultProps = {
    orientation: 'vertical',
    variant: 'middle',
}


export const InfoActorContainer = styled(Box, {
    shouldForwardProp: (propName) => (
        !["isDragging", 'isOneApprover', 'isOrdered'].includes(propName.toString())
    )})(({isDragging, isOneApprover, isOrdered}:{isDragging?: true, isOneApprover?: true, isOrdered?: true}) => ({
    padding: isOneApprover ? `2px 4px 0 ${isOrdered ? 0 : '6px'}` : `2px 12px 12px ${isOrdered ? 0 : '6px'}`,
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '610px',
    border: `1px solid ${colors.stroke.grey}`,
    borderRadius: '8px',
    opacity: isDragging ? 0 : 1,
    boxShadow: '0px 0px 1px 0px rgba(125, 151, 174, 0.02), 0px 2px 6px 0px rgba(125, 151, 174, 0.02), 0px 8px 16px 0px rgba(125, 151, 174, 0.08)',

}));

export const RecipientsContainer = styled(Box,  {
    shouldForwardProp: (propName) => (
        !["isOrdered", "isDragging"].includes(propName.toString())
    )})(({isOrdered, isDragging}: {isOrdered?: true, isDragging?: true}) => ({
    padding: '6px 16px 16px 3px',
    display: 'flex',
    // flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    opacity: isDragging ? 0 : 1,
    width: isOrdered ? '93%' : '100%',
    border: `1px solid ${colors.stroke.grey}`,
    borderRadius: '8px',
    boxShadow: '0px 0px 1px 0px rgba(125, 151, 174, 0.02), 0px 2px 6px 0px rgba(125, 151, 174, 0.02), 0px 8px 16px 0px rgba(125, 151, 174, 0.08)',
}));


export const ActorRowBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: "center",
    gap: '16px',
    overflow: 'hidden',
    flexGrow: 1,
    margin: "5px 0",
}));

export const ActorDataContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: "column",
    overflow: "hidden",
    flexGrow: 1,
}));

export const ActorRowDivider = styled(Divider)(() => ({
    borderBottom: '1px solid ' + colors.divider,
    marginTop: '4px',
    marginRight: '4px',
    flexItem: true,
}));
ActorRowDivider.defaultProps = {
    variant: 'fullWidth'
}


export const TypographyName = styled(TypographyBody2)(() => ({
    color: colors.text.dark,
    noWrap: true,
}));

export const TypographyEmail = styled(TypographyBody2)(() => ({
    color: colors.text.grey_dark,
    noWrap: true,
}));




