import React, {FC} from "react";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {RestoreClientFromArchiveDialog} from "../../../components/dialogs/restoreFromArchive";
import {PageContainer} from "../../../../../../newShared/components/style";
import {useKycArchive} from "../../../hooks/clients/useKycArchive";
import {DeleteTargetDialog} from "../../../components/dialogs/deleteTarget";
import {KycArchivedTargetModel} from "../../../../../../newShared/GQLTypes";
import {getNormalizedTargetType, getTargetName} from "../../../helpers";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {GenericTable} from "../../../../../../newShared/components/genericTable";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import RestoreIcon from "@mui/icons-material/Restore";
import {DeleteOutline} from "@mui/icons-material";

export const KycArchiveClients: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.archive'});

    const {
        clients,
        filter,
        isOutdated,
        handleReloadData,
        genericTable
    } = useKycArchive();

    const {isMobile} = useMedia();


    return (
        <PageContainer>
            <PageTitleAndActions
                title={t('Archive')}
                showDataHasBeenUpdated={isOutdated}
                onReloadData={handleReloadData}
                actions={[]}
            />

            <GenericTable<KycArchivedTargetModel>
                id={'MatchesList'}
                columnsConfig={{totalName: 'Total clients', forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: clients,
                    customCellPaddings: '8px',
                    rowActionsConfig: [
                        {
                            title: t('Restore'),
                            onClick: genericTable.handleRestoreFromArchive,
                            icon: <RestoreIcon fontSize={'small'}/>,
                        },
                        {
                            title: t('Delete'),
                            onClick: genericTable.handleDeleteArchivedTarget,
                            icon: <DeleteOutline fontSize={'small'}/>,
                        }
                    ],
                    getRowUniqueId: (row) => row.id,
                    onRowClick: () => null,
                    columnsConfigs: [
                        {name: t('Client name'), key: 'targetName', default: true, valueGetter: (row) => getTargetName(row)},
                        {name: t('Type'), key: 'type', default: true, valueGetter: (row) => getNormalizedTargetType(row.targetType)},
                        {name: t('Archive date'), key: 'templateName', default: true, valueGetter: (row) => parseDateAuto(row.archivedDate)},
                        {name: t('Reason for archiving'), key: 'archivedReason', default: true},
                    ]
                }}
                filtersConfig={{
                    dontSendSearchIfEmpty: true,
                    searchCustomFilterKeyName: 'nameLike',
                    genericFilterProps: {
                        configs:filter.configs,
                        fetchResultWithSelectedFilters: () => null,
                        isAddFilters: false
                    }
                }}
                emptyArrayImageProps={{
                    type: 'kycTemplates',
                    filterNotApplied: {text: t('No archived clients found'), secondaryText: t('Archived clients will appear here')}
                }}
            />

            {/*<FilterBox>*/}
            {/*    <GenericFiltersArray*/}
            {/*        dateFilterFormat={KYC_DATE_FORMAT}*/}
            {/*        isAddFilters={false}*/}
            {/*        configs={filter.configs}*/}
            {/*        fetchResultWithSelectedFilters={(search: string, filters: Record<string, string[]>, signal: AbortSignal) => {*/}
            {/*            dispatch(eraseArchive())*/}
            {/*            dispatch(GetKycArchiveWithFilterAndPaging({data: {pageInfo: {page: 0, count: CLIENTS_DEFAULT_PAGING, filters: {...filters, nameLike: search}}}, signal }))*/}
            {/*        }}*/}
            {/*    />*/}

            {/*</FilterBox>*/}


            {/*{!isNoArchiveClientsFound &&*/}
            {/*    <CommonTableContainer onScroll={scroll.onScroll} ref={scroll.scrollRef} >*/}
            {/*        <Table stickyHeader>*/}
            {/*            <CommonTableHead>*/}
            {/*                <CommonHeaderTableRow>*/}
            {/*                    <TableCell colSpan={5}>*/}
            {/*                        <Typography variant={'subtitle1'} color={colors.text.grey}>{`${t('Total clients')}: ${isLoadingList ? '' : pageInfo.total}`}</Typography>*/}
            {/*                    </TableCell>*/}
            {/*                </CommonHeaderTableRow>*/}
            {/*                <CommonTableRow sx={{'& .MuiTableCell-root': {top: '33px'}}}>*/}
            {/*                    <NameTableCell>*/}
            {/*                        <TypographyBody2Grey>{t('Client name')}</TypographyBody2Grey>*/}
            {/*                    </NameTableCell>*/}
            {/*                    <CommonTableCell>*/}
            {/*                        <TypographyBody2Grey>{t('Type')}</TypographyBody2Grey>*/}
            {/*                    </CommonTableCell>*/}
            {/*                    <CommonTableCell>*/}
            {/*                        <TypographyBody2Grey>{t('Archive date')}</TypographyBody2Grey>*/}
            {/*                    </CommonTableCell>*/}
            {/*                    /!*<CommonTableCell>*!/*/}
            {/*                    /!*    <TypographyBody2Grey>{t('Risk')}</TypographyBody2Grey>*!/*/}
            {/*                    /!*</CommonTableCell>*!/*/}
            {/*                    <NameTableCell>*/}
            {/*                        <TypographyBody2Grey>{t('Reason for archiving')}</TypographyBody2Grey>*/}
            {/*                    </NameTableCell>*/}
            {/*                    <TableCell />*/}
            {/*                </CommonTableRow>*/}
            {/*            </CommonTableHead>*/}

            {/*            <CommonTableBody>*/}
            {/*                {clients.map((client) => (*/}
            {/*                    <ArchiveTableRow*/}
            {/*                        key={client.id}*/}
            {/*                        client={client}*/}
            {/*                        restoreClientFromArchive={handleOpenRestoreClientFromArchive}*/}
            {/*                        deleteArchivedTarget={handleOpenDeleteArchivedTarget}*/}
            {/*                    />*/}
            {/*                ))}*/}

            {/*                /!*Column actions is not in columnConfig - its generated by default at the end of row*!/*/}
            {/*                {(scroll.hasMore || isLoadingList) && <TableRowSkeleton columns={5}/>}*/}
            {/*            </CommonTableBody>*/}
            {/*        </Table>*/}
            {/*    </CommonTableContainer>*/}
            {/*}*/}

            {/*<EmptyArrayImage*/}
            {/*    type={'kycTemplates'}*/}
            {/*    isShowing={isNoArchiveClientsFound}*/}
            {/*    text={('No archive clients found.')}*/}
            {/*    withoutAdd*/}
            {/*    // secondaryText={('No screening to display.')}*/}
            {/*    // onClick={actions.handleOpenAddApp}*/}
            {/*/>*/}

            {/*DIALOGS*/}
            <DeleteTargetDialog />
            <RestoreClientFromArchiveDialog />
        </PageContainer>
    )
}
