import React, {FC} from "react";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useAssetsList} from "../../hooks/useAssetsList";
import {Flex} from "../../../../../newShared/components/Layouts";
import {Typography} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {getStatusColor} from "../../helpers";
import {ReleasePhysicalAssets} from "../../components/Dialogs/ReleaseDialog";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import AddIcon from "@mui/icons-material/Add";
import {ExportCsvDialog} from "../../../../../newShared/components/csvExportDialog";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {TAsset} from "../../types";
import {useImportCsvPhysicalAssets} from "../../hooks/dialogs/useImportCsvPhysicalAssets";
import {ImportCsvDialog} from "../../../../../newShared/components/csvImportDialog";


export const AssetsList:FC = () => {
    const {isMobile, isLessThen1050} = useMedia();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});

    const {
        assets,
        // settings,
        // filter,
        // setSearch,
        // isShowingNoAssets,
        goToAsset,
        goToAddAsset,
        goToSettings,
        exportCsv,
        handleOpenExportCsv,
        handleImportCSV,
        isLoadingList,
        isLoadingFields,
        isLoadingExport,
        // isLoadingSettings,
        handleOpenRelease,

        // pagination: {
        //     bottomLoader,
        //     onScroll,
        //     scrollRef
        // },
        genericTable
    } = useAssetsList();

    const importCsv = useImportCsvPhysicalAssets();

    return (
        <Flex w={'100%'} h={'100%'} direction={'column'} >

            <PageTitleAndActions
                title={t('Physical assets')}
                showMenuWhen={isLessThen1050}
                actions={[
                    {
                        title: t('Add'),
                        onClick: goToAddAsset,
                        variant: 'text',
                        icon: <AddIcon />,
                        disabled: isLoadingList || isLoadingExport,
                        size: "small"
                    },
                    {
                        title: t('Settings'),
                        onClick: goToSettings,
                        // variant: 'text',
                        disabled: isLoadingList || isLoadingExport,
                        size: "small"
                    },
                    {
                        title: t('Release'),
                        onClick: handleOpenRelease,
                        disabled: isLoadingList || isLoadingExport,
                        color: 'secondary',
                        size: "small"
                    },
                    {
                        title: t('Export CSV'),
                        onClick: handleOpenExportCsv,
                        variant: 'text',
                        icon: <FileDownloadIcon style={{marginLeft: revDir ? '9px' : undefined }}/>,
                        loading: isLoadingExport,
                        disabled: isLoadingExport || isLoadingList,
                        size: "small"
                    },
                    {
                        title: t('Import CSV'),
                        icon: <FileUploadIcon style={{marginLeft: revDir ? '9px' : undefined }}/>,
                        onClick: handleImportCSV,
                        variant: 'text',
                        disabled: isLoadingList || isLoadingExport,
                        size: "small"
                    },
                ]}
            />

            <GenericTable<TAsset>
                id={'AssetsList'}
                columnsConfig={{totalName: 'Total assets', disableColumnsSettings: true, forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: assets,
                    // customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id,
                    onRowClick: goToAsset,
                    columnsConfigs: [
                        {key: "assetId", name: t('ID'), default: true},
                        {key: 'model', name: t('Model'), default: true},
                        {key: 'status', name: t('Status'), default: true, valueRenderer: (row) => {
                            const color = getStatusColor(row.status?.status);
                            return <Typography color={color} variant={'*bodyText2'}>{row.status.name}</Typography>
                        }},
                    ],
                }}
                filtersConfig={{
                    searchCustomFilterKeyName: 'regexName',
                    searchAsArray: true,
                    dontSendSearchIfEmpty: true,
                    genericFilterProps: {
                        configs: genericTable.filters.configs,
                        fetchResultWithSelectedFilters: () => null,
                        isAddFilters: false
                    }
                }}
                emptyArrayImageProps={{type: 'physicalAssets', filterNotApplied: {secondaryText: t('You have not added any asset yet.'), text: t('Create asset')}}}
            />


            {/*<Flex w={'100%'} m={'20px 0 0 0'} ai={'center'}>*/}
            {/*    <SearchField*/}
            {/*        size={'small'}*/}
            {/*        sx={{margin: revDir ? '0 0 0 10px' : '0 10px 0 0', width: '320px'}}*/}
            {/*        variant={'outlined'}*/}
            {/*        placeholder={t('Search')}*/}
            {/*        onChange={(e) => {setSearch(e.currentTarget.value)}}*/}
            {/*        value={filter.filter.regexName}*/}
            {/*        disabled={isLoadingList}*/}
            {/*        onClear={filter.handleCleanSearch}*/}
            {/*        onKeyDown={filter.handleEnterKey}*/}
            {/*    />*/}

            {/*    { !isMobile && (*/}
            {/*        <TextField label={t('Model')} select size={'small'}*/}
            {/*                   onChange={(e) => {filter.handleOnChangeModel(e.target.value)}}*/}
            {/*                   value={filter.filter.model ?? null}*/}
            {/*                   sx={{margin: revDir ? '0 0 0 10px' : '0 10px 0 0', width: '320px', '& label': {top: '0 !important'}}}*/}
            {/*                   disabled={isLoadingList || isLoadingSettings}*/}
            {/*        >*/}
            {/*            <MenuItem key={'Any'} value={'Any'}>{'Any'}</MenuItem>*/}
            {/*            {settings?.model.map(({name}) => (<MenuItem key={name} value={name}>{name}</MenuItem>))}*/}
            {/*        </TextField>*/}
            {/*    )}*/}
            {/*    { (!isMobile && !isTablet) && (*/}
            {/*        <TextField label={t('Status')} select size={'small'}*/}
            {/*                   value={filter.filter.status ?? null}*/}
            {/*                   sx={{margin: revDir ? '0 0 0 10px' : '0 10px 0 0', width: '320px', '& label': {top: '0 !important'}}}*/}
            {/*                   onChange={(e) => {filter.handleOnChangeStatus(e.target.value)}}*/}
            {/*                   disabled={isLoadingList || isLoadingSettings}*/}
            {/*        >*/}
            {/*            <MenuItem key={'Any'} value={'Any'}>{t('Any')}</MenuItem>*/}
            {/*            {settings?.status.map(({name}) => (*/}
            {/*                <MenuItem key={name} value={name}>{name}</MenuItem>*/}
            {/*            ))}*/}
            {/*        </TextField>*/}
            {/*    )}*/}
            {/*</Flex>*/}

            {/*<TableContainer onScroll={onScroll} ref={scrollRef} sx={{overflowY: isShowingNoAssets ? 'hidden' : undefined}}>*/}
            {/*    <Table stickyHeader >*/}
            {/*        <TableHead>*/}
            {/*            <TableRow >*/}
            {/*                <TableCell sx={{width: (isMobile || isTablet) ? '25%' : '320px'}} align={revDir ? 'right' : 'left'}> {t('ID')}</TableCell>*/}
            {/*                <TableCell sx={{width: (isMobile || isTablet) ? '30%' : '330px'}} align={revDir ? 'right' : 'left'}> {t('Model')}</TableCell>*/}
            {/*                <TableCell sx={{width: isMobile ? '30%' : undefined}} align={revDir ? 'right' : 'left'}> {t('Asset status')}</TableCell>*/}
            {/*            </TableRow>*/}
            {/*        </TableHead>*/}

            {/*        {(!isShowingNoAssets) &&*/}
            {/*            <TableBody>*/}
            {/*                {assets.map((asset) => asset && (*/}
            {/*                    <TableRow*/}
            {/*                        hover*/}
            {/*                        key={asset?.id}*/}
            {/*                        onClick={() => {goToAsset(asset)}}*/}
            {/*                    >*/}
            {/*                        <TableCell sx={{width: (isMobile || isTablet) ? '25%' : '320px'}} align={revDir ? 'right' : 'left'}  >{asset.assetId}</TableCell>*/}
            {/*                        <TableCell sx={{width: (isMobile || isTablet) ? '30%' : '330px'}}  align={revDir ? 'right' : 'left'}>{asset.model}</TableCell>*/}
            {/*                        <TableCell sx={{color: getStatusColor(asset.status?.status) || colors.grey, width: isMobile ? '30%' : undefined}}*/}
            {/*                                   align={revDir ? 'right' : 'left'}>{asset.status?.name}</TableCell>*/}

            {/*                    </TableRow>*/}
            {/*                ))}*/}
            {/*                {(bottomLoader || isLoadingList) && <TableRowSkeleton columns={3} />}*/}
            {/*            </TableBody>*/}
            {/*        }*/}

            {/*    </Table>*/}
            {/*</TableContainer>*/}

            {/*{filter.filter.prevSearch && !isLoadingList && !assets.length &&*/}
            {/*    <Typography color={colors.darkBlueText}>{t('Nothing was found for your query. Try changing the query.')}</Typography>}*/}

            {/*<EmptyArrayImage*/}
            {/*    type={'physicalAssets'}*/}
            {/*    isShowing={isShowingNoAssets}*/}
            {/*    withoutAdd*/}
            {/*    text={t('Create asset')}*/}
            {/*    secondaryText={t('You have not added any asset yet.')}*/}
            {/*/>*/}



            {/*DIALOGS*/}
            <ExportCsvDialog
                isOpen={exportCsv.isOpen}
                isLoadingFields={isLoadingFields}
                isLoadingExport={isLoadingExport}
                availableFields={exportCsv.availableFields}
                handleExportCsv={exportCsv.handleExportCsv}
                handleClose={exportCsv.handleClose}
                fieldsMapperKey={"assets"}
            />
            <ReleasePhysicalAssets />

            <ImportCsvDialog
                isOpen={importCsv.isOpen}
                fields={importCsv.fields}
                response={importCsv.response}
                setImportDialog={importCsv.setImportDialog}

                isGettingFields={importCsv.isGettingFields}
                isPerformingCsv={importCsv.isPerformingCsv}
                isLoadingTemplate={importCsv.isLoadingTemplate}

                getFields={importCsv.getFields}
                performCsv={importCsv.performCsv}
                downloadTemplate={importCsv.downloadTemplate}
                fieldsMapperKey={importCsv.fieldsMapperKey}

                fileLimitInMB={5}
            />

        </Flex>
    )

}
