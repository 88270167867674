import {setGot403} from "../../../newDomain/authWorkspacesCookies/settings/store/slice";
import {store} from "../../redux";
import {
    setAuthNotComplete
} from "../../../newDomain/authWorkspacesCookies/cookies/store/sharedWorkspacesAndOrganizations/slice";


export const LogOut = () => {
    store.dispatch(setAuthNotComplete());
    //     when setting isAuthComplete and is2FaComplete to false - useWorkspacesAndOrganizations has useEffect that looks after this consts
    //     so when it will become false we ll make all the shi to logout
    //     all this - bacause can not use hooks inside of regular func (here)
}

export const Handle403 = (message: string) => {
    console.log(`-------- 403 ---------`)
    store.dispatch(setGot403({
        subscription: false,
        permission: false,
        message
    }));
    //when user gets 403 - updating store where putting 403: true
    //useWorkspacesAndOrganizations has useEffect that looks after this and updated cookies + sends user to choose workspace and sets 403: false
}