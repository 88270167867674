import {Button, Dialog, FormControl, Radio, TextField, Typography} from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import React from "react";
import {TransitionUp} from "../dialogTransaction";
import {filterDateType} from "../../types";
import {useMainTranslation} from "../../../../hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../Layouts";
import colors from "../../../../theme/colors";
import {isFutureDate, isPastDate, isSameDay} from "../../../../utils/dateTools";

export const DateFilterDialog = ({onClose, isOpen, date}:
                                     {
                                         onClose: () => void,
                                         isOpen: boolean,
                                         date: {
                                             dateType: filterDateType,
                                             handleSelectDateType: (type: filterDateType) => void,
                                             selectedDates: (Date | null)[],
                                             handleSelectExactDate: (date: (Date | null)) => void,
                                             handleSelectRangeDateA: (dateA: (Date | null)) => void,
                                             handleSelectRangeDateB: (dateB: (Date | null)) => void,
                                             handleResetDateFilter: () => void,
                                             isResetButtonDisabled: boolean
                                         },
                                     }
) => {
    const {revDir, currentLanguage, t} = useMainTranslation('', {keyPrefix: 'genericFilter'});
    return(
        <Dialog
            open={isOpen}
            onClose={onClose}
            TransitionComponent={TransitionUp}
            fullScreen
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '40px 40px 0 0',
                    height: '60vh',
                    bottom: 0,
                    position: 'absolute',
                    padding: '16px'
                }
            }}
        >
            <Flex direction={'column'} onClick={(e) => e.stopPropagation()} grow={'1'}>
                <Flex jc={'flex-end'} p={'0 16px'}>
                    <Button
                        variant={'text'}
                        size={'large'}
                        sx={{padding: '8px 2px', minWidth: '0'}}
                        onClick={date.handleResetDateFilter}
                        disabled={date.isResetButtonDisabled}
                    >{t('Reset')}</Button>
                </Flex>

                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={date.dateType as filterDateType}
                        onChange={(e, value) => date.handleSelectDateType(value as filterDateType)}
                    >
                        <FormControlLabel value={'exact'} control={<Radio sx={{width: '24px', height: '24px', margin: !revDir ? '0 16px 0 8px' : '0 8px 0 16px'}}/>} label={<Typography variant={'body2'} color={colors.text.grey_dark}>{t('Exact date')}</Typography>} />

                        {date.dateType === 'exact' &&
                            <>
                                <DatePicker
                                    value={date.selectedDates[0] || null}
                                    onChange={(newValue) => {date.handleSelectExactDate(newValue)}}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        size={'small'}
                                        sx={{'.MuiSvgIcon-root': {fill: colors.text.grey_dark}, margin: '6px 0'}}
                                    />}
                                />
                            </>
                        }

                        <FormControlLabel value={'period'} control={<Radio sx={{width: '24px', height: '24px', margin: !revDir ? '0 16px 0 8px' : '0 8px 0 16px'}}/>} label={<Typography variant={'body2'} color={colors.text.grey_dark}>{t('Period')}</Typography>} />

                        {date.dateType === 'period' &&
                            <Flex ai={'center'} gap={'10px'}>
                                <DatePicker
                                    value={date.selectedDates[0] ?? null}
                                    onChange={(newValue) => {date.handleSelectRangeDateA(newValue)}}
                                    shouldDisableDate={(_date) => {
                                        //if TO selected - disable selecting same as TO date or any date after
                                        if(_date && date.selectedDates[1] !== null && date.selectedDates[1] !== undefined){
                                            return isSameDay(_date.toISOString(), date.selectedDates[1].toISOString(), currentLanguage.momentLocale) ||
                                                isFutureDate(_date.toISOString(), currentLanguage.momentLocale, date.selectedDates[1].toISOString())
                                        }
                                        return false;
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        size={'small'}
                                        sx={{
                                            'button': {padding: '0 6px 0 0'},
                                            '.MuiSvgIcon-root': {fill: colors.text.grey_dark, width: '24px', height: '24px'},
                                            margin: '6px 0 0 0',
                                            backgroundColor: colors.backgrounds.grey_light
                                        }}
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: t("From"),
                                            style: {
                                                padding: '10px 0 10px 6px',
                                                height: '20px',
                                                fontSize: '14px',
                                                fontWeight: 400
                                            }
                                        }}
                                    />}
                                />

                                <DatePicker
                                    value={date.selectedDates[1] ?? null}
                                    shouldDisableDate={(_date) => {
                                        //disable selecting same as FROM date or any date before
                                        if(_date && date.selectedDates[0] !== null && date.selectedDates[0] !== undefined){
                                            return isSameDay(_date.toISOString(), date.selectedDates[0]?.toISOString(), currentLanguage.momentLocale) ||
                                                isPastDate(_date.toISOString(), currentLanguage.momentLocale, date.selectedDates[0]?.toISOString())
                                        }
                                        return false;
                                    }}
                                    onChange={(newValue) => {date.handleSelectRangeDateB(newValue)}}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        size={'small'}
                                        sx={{
                                            'button': {padding: '0 6px 0 0'},
                                            '.MuiSvgIcon-root': {fill: colors.text.grey_dark, width: '24px', height: '24px'},
                                            margin: '6px 0 0 0',
                                            backgroundColor: colors.backgrounds.grey_light
                                        }}
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: t("To"),
                                            style: {
                                                padding: '10px 0 10px 6px',
                                                height: '20px',
                                                fontSize: '14px',
                                                fontWeight: 400
                                            }
                                        }}
                                    />}
                                />

                            </Flex>
                        }
                    </RadioGroup>
                </FormControl>
            </Flex>
        </Dialog>
    )
}