import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {loadings, selectedModel} from "../../store/slice";
import {
    PATH_RISK_MANAGEMENT_MODELS_ALL,
    PATH_RISK_MANAGEMENT_MODELS_EXACT,
    PATH_RISK_MANAGEMENT_MODELS_NEW
} from "../../constants";
import {useState} from "react";
import {TRiskModel} from "../../types";
import {getInitCreateModel, getLow, getMax} from "../../helpers";
import {createRisksModelsAction} from "../../store/actions";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_RISK_MANAGEMENT} from "../../../../../newShared/constants";

export const useModelCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {modelsCreate} = useSelector(loadings);
    const selected = useSelector(selectedModel);

    const handleGoBack = () => history.push(PATH_RISK_MANAGEMENT_MODELS_ALL);

    const [model, setModel] = useState<TRiskModel>(selected ? {...selected, id: '', isBasic: false} : getInitCreateModel());
    const [displayModel, setDisplayModel] = useState<TRiskModel | null>(selected ? {...selected, id: '', isBasic: false} : getInitCreateModel());

    const handleSubmit = () => {
        if(isOk() && !isCanBeApplied() && displayModel){
            dispatch(createRisksModelsAction({
                data: {
                    workspaceId: '',
                    organizationId: '',
                    riskModel: displayModel
                },
                onSuccess
            }))
        }
    }

    const onSuccess = (id: string) => {
        history.push(PATH_RISK_MANAGEMENT_MODELS_EXACT.replace(':id', id));
    }

    const isOk = () => {
        if(!displayModel) return false;
        return !displayModel.impacts.some(e => !e.quality.trim().length) && !displayModel.impacts.some(e => !e.quantitative)
            && !displayModel.probabilities.some(e => !e.quality.trim().length) && !displayModel.probabilities.some(e => !e.quantitative)
            && displayModel.left < displayModel.right && displayModel.right < getMax(model) && displayModel.left > getLow(model) &&
            displayModel.name.trim().length > 0
    }

    const handleChangeProbability = (e: any, id: number) => {
        if(e.target.name === 'quality'){
            model && setModel({
                ...model,
                probabilities: [
                    ...model?.probabilities.map((p, pid ) => pid === id ? {...p, quality: e.target.value} : p)
                ]
            })
        }else{
            model && setModel({
                ...model,
                probabilities: [
                    ...model?.probabilities.map((p, pid ) => pid === id ? {...p, quantitative: e.target.value.length > 0 ? (parseInt(e.target.value) ?? 1) : 1} : p)
                ]
            })
        }
    }

    const handleChangeImpact = (e: any, id: number) => {
        if(e.target.name === 'quality'){
            model && setModel({
                ...model,
                impacts: [
                    ...model?.impacts.map((p, pid ) => pid === id ? {...p, quality: e.target.value} : p)
                ]
            })
        }else{
            model && setModel({
                ...model,
                impacts: [
                    ...model?.impacts.map((p, pid ) => pid === id ? {...p, quantitative: e.target.value.length > 0 ? (parseInt(e.target.value) ?? 1) : 1} : p)
                ]
            })
        }
    }

    const setLeftRight = (left: number, right: number) => {
        displayModel && setDisplayModel({...displayModel, left, right});
    }

    const handleSetName = (e: any) => {
        setDisplayModel({...model, name: e.target.value});
    }

    const handleAddProbability = () => {
        setModel({
            ...model,
            probabilities: [...model.probabilities, {quantitative: 1,  quality: '', description: ''}]
        })
    }

    const handleAddImpact = () => {
        setModel({
            ...model,
            impacts: [...model.impacts, {quantitative: 1,  quality: '', description: ''}]
        })
    }

    const handleDeleteProbability = (id: number) => {
        setModel({
            ...model,
            probabilities: model.probabilities.filter((e, _id) => id !== _id)
        })
    }

    const handleDeleteImpact = (id: number) => {
        setModel({
            ...model,
            impacts: model.impacts.filter((e, _id) => id !== _id)
        })
    }


    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    useSetBreadcrumbs([
        {
            title: tMenu('Risk Management'),
            path: PATH_LOCAL_RISK_MANAGEMENT
        },
        {
            title: tMenu('Models'),
            path: PATH_RISK_MANAGEMENT_MODELS_ALL
        },
        {
            title: tMenu('Create model'),
            path: PATH_RISK_MANAGEMENT_MODELS_NEW
        }
    ]);

    //
    const isOkToApply = ():boolean => {
        if(model){
            const low = getLow(model);
            const max = getMax(model);
            if(low !== max && max - low > 1){ // max - low > 1 prevents showing model where min 1 and max 2
                return Boolean(model?.impacts.length && model.impacts.length > 1) &&
                    Boolean(model?.probabilities.length && model.probabilities.length > 1) &&
                    !model.impacts.filter(e => !e.quality.trim().length).length && // no impacts with empty name
                    !model.probabilities.filter(e => !e.quality.trim().length).length //no probabilities with empty name
            }
        }
        return false;
    }

    const isCanBeApplied = () => {
        //can be applied if display model different from original except left/right and name because its applies to displayModel only
        return JSON.stringify({...model, left: 0, right: 0, name: ''}) !== JSON.stringify({...displayModel, left: 0, right: 0, name: ''});
    }

    const handleApplyChanges = () => {
        if(isOkToApply() && model){
            const low = getLow(model);
            const max = getMax(model);
            const newLeft = Math.floor((max - low) * 0.5 + low); // 1/3 of full range + starting from low
            const newRight = Math.floor((max - low) * 0.75 + low); // 2/3 of full range + starting from low
            setDisplayModel({...model, left: newLeft, right: newRight, name: displayModel?.name ?? ''});
        }
    }

    return{
        isCanBeApplied: isCanBeApplied(), //check if model === displayModel and user has seen changes
        handleApplyChanges,
        isOkToApply: isOkToApply(), //is can be applied
        displayModel,
        //
        isLoading: modelsCreate,
        handleGoBack,
        model,
        isOk,
        handleSubmit,
        handleChangeImpact,
        handleChangeProbability,
        setLeftRight,
        handleSetName,
        handleAddProbability,
        handleAddImpact,
        handleDeleteProbability,
        handleDeleteImpact
    }
}