import {Flex} from "../../../../../../../../../newShared/components/Layouts";
import {TrainingContentNewModel} from "../../../../../../../../../newShared/GQLTypes";
import React, {useEffect, useState} from "react";
import colors from "../../../../../../../../../newShared/theme/colors";
import {Typography} from "@mui/material";
import {ReactComponent as VideoIcon} from "../../../../../../../../../newShared/images/empty/video.svg";

export const VideoLinkPreview = ({section, onError}:{section: TrainingContentNewModel, onError?: (isError: boolean) => void}) => {
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        setError(false);
        onError && onError(false);
        //eslint-disable-next-line
    }, [section.data.url]);

    return(
    <Flex w={'480px'} ai={'center'} jc={'center'} m={'0 auto'}>
        {error ?
            <Flex
                w={'100%'}
                jc={'center'}
                ai={'center'}
                h={'270px'}
                br={'6px'}
                direction={'column'}
                background={colors.primary.disable2}
            >
                <Flex w={'50%'} ai={'center'} jc={'space-between'} gap={'24px'}>
                    <VideoIcon style={{height: '72px'}}/>
                    <Flex direction={'column'} gap={'6px'}>
                        <Typography variant={'*bodyText1'} color={colors.grayText}>{('Preview not available')}</Typography>
                    </Flex>
                </Flex>
            </Flex>
            :
            <video width={480}
                   controls
                   src={section.data.url ?? ''}
                   onError={(e) => {
                       setError(true);
                       onError && onError(true);
                   }}
            />
        }
    </Flex>
    )
}