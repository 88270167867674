import {QuestionShortDataModel} from "../../../../../newShared/GQLTypes";
import {Checkbox, TableCell, TableRow, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {Flex} from "../../../../../newShared/components/Layouts";
import {deselectExistingQuestion, questionsToAddSelector, selectExistingQuestion} from "../../store/slice";
import {useDispatch, useSelector} from "react-redux";
import {questionTypeImageMapper} from "../../helpers";

export const QuestionToAddTableRow = ({question}: {question: QuestionShortDataModel}) => {
    const {selectedQuestions} = useSelector(questionsToAddSelector);
    const [checked, setChecked] = useState(selectedQuestions.some(e => e.questionId === question.questionId));
    const dispatch = useDispatch();
    const questionTitleImage = question.questionType ? questionTypeImageMapper(question.questionType) : {questionTitle: '-', questionImage: '-'};


    const handleSelectQuestion = () => {
        if(!selectedQuestions.some(s => s.questionId === question.questionId)) {
            dispatch(selectExistingQuestion(question));
            setChecked(true);
        }else{
            dispatch(deselectExistingQuestion(question));
            setChecked(false);
        }
    }
    const isQuestionSelected = () => {
        return selectedQuestions.some(e => e.questionId === question.questionId);
    }
    useEffect(() => {
        if(selectedQuestions.some(e => e.questionId === question.questionId)) {
            setChecked(true);
        } else {
            setChecked(false);
        }
        // eslint-disable-next-line
    }, [isQuestionSelected])

    return (
        <TableRow>
            <TableCell colSpan={3}>
                <Flex ai={'center'} jc={'flex-start'} style={{marginLeft: '50px'}}>
                    <Checkbox checked={checked} onChange={handleSelectQuestion} />
                    <Typography variant={'*bodyText2'}>{question.questionName}</Typography>
                </Flex>
            </TableCell>
            <TableCell>
                <Flex ai={'center'} gap={'8px'} jc={'flex-start'} style={{marginLeft: '30px'}}>
                    {questionTitleImage?.questionImage ?? '-'}
                    <Typography variant={'*bodyText2'}>{questionTitleImage?.questionTitle ?? '-'}</Typography>
                </Flex>
            </TableCell>
        </TableRow>
    )

}