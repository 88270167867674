import {
    FlexColumn
} from "../../../../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import {Button, Typography} from "@mui/material";
import colors from "../../../../../../../../newShared/theme/colors";
import {Add} from "@mui/icons-material";
import React, {FC, RefObject, useCallback, useEffect} from "react";
import {MainTrainingExamFormik} from "../../../../../types";
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {FormikErrors} from "formik";
import {TrainingExamQuestionInput} from "../../../../../../../../newShared/GQLTypes";
import {useCustomFormik} from "../../../../../../../../newShared/hooks/useCustomFormik";
import {ExamViewQuestionOption} from "../examViewQuestionOption";

type ExamViewQuestionRadioRowsProps = {
    questionIndex: number;
    masterFormik: ReturnType<typeof useCustomFormik<MainTrainingExamFormik>>;
    handleAddOption: () => void;
    moveOptionRef: RefObject<(dragId: string, toId: string) => void>;
    handleRemoveOptionRef: RefObject<(optionId: string) => void>;
}
export const ExamViewQuestionRadioRows: FC<ExamViewQuestionRadioRowsProps> = React.memo(({masterFormik: formik, questionIndex, handleAddOption, moveOptionRef, handleRemoveOptionRef}) => {
    const {t} = useMainTranslation();

    const row = formik.values.questions[questionIndex];

    const commonOptionError = typeof (formik.errors.questions?.[questionIndex] as FormikErrors<TrainingExamQuestionInput>)?.options === 'string' && (formik.errors.questions?.[questionIndex] as FormikErrors<TrainingExamQuestionInput>)?.options;
    const commonOptionTouched = formik.touched.questions?.[questionIndex]?.options;

    const setOptionChecked = useCallback((optionId: string) => {
        formik.setFieldValue(`questions[${questionIndex}]options`, row.options.map(option => ({
            ...option,
            correct: optionId === option.optionId,
        })), true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [row.options, questionIndex]);

    const setOptionCheckedRef = React.useRef(setOptionChecked);

    useEffect(() => {
        setOptionCheckedRef.current = setOptionChecked;
    }, [setOptionChecked]);

    return (
        <FlexColumn sx={{flexShrink: 0, width: '100%', gap: '16px'}}>
            <Typography variant={'*bodyText2_semibold'} noWrap sx={{flexGrow: 1}}
                        color={commonOptionTouched && commonOptionError ? colors.text.critical : colors.text.grey_dark}>
                {t('Add answer options and mark the correct one.')}
            </Typography>

            <FlexColumn sx={{flexShrink: 0, width: '100%', gap: '4px'}}>
                {row.options.map((option, optionIndex) => (
                    <ExamViewQuestionOption key={option.optionId} questionIndex={questionIndex} optionIndex={optionIndex} masterFormik={formik} setOptionCheckedRef={setOptionCheckedRef}
                                            moveOptionRef={moveOptionRef} handleRemoveOptionRef={handleRemoveOptionRef}/>
                ))}
            </FlexColumn>

            <Button variant={"text"} size={"small"} onClick={handleAddOption} startIcon={<Add/>} sx={{width: 'fit-content'}}>
                {t('Add option')}
            </Button>
        </FlexColumn>
    )}, (prevProps, nextProps) => {

    return (
        prevProps.questionIndex === nextProps.questionIndex

        && JSON.stringify(prevProps.masterFormik.values.questions[prevProps.questionIndex].options) === JSON.stringify(nextProps.masterFormik.values.questions[nextProps.questionIndex].options)
        && JSON.stringify((prevProps.masterFormik.errors.questions?.[prevProps.questionIndex] as FormikErrors<TrainingExamQuestionInput>)?.options) === JSON.stringify((nextProps.masterFormik.errors.questions?.[nextProps.questionIndex] as FormikErrors<TrainingExamQuestionInput>)?.options)
        && JSON.stringify(prevProps.masterFormik.touched.questions?.[prevProps.questionIndex]?.options) === JSON.stringify(nextProps.masterFormik.touched.questions?.[nextProps.questionIndex]?.options)
    )
});