import {ManagePermissionsDialogProps} from "../../../../../types";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    ListItemIcon,
    Typography
} from "@mui/material";
import {useManagePermissionsDialog} from "../../../../../hooks/organizations/dialogs/useManagePermissionsDialog";
import React from "react";
import {CustomSearchField} from "../../../../customSearch";
import {DialogContentWrapper} from "../../../../styled";
import {Flex} from "../../../../../../../../newShared/components/Layouts";
import {FixedSizeList as List} from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import colors from "../../../../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";


export const ManagePermissionsDialog = (props: ManagePermissionsDialogProps) => {
    const {
        availablePermissions,
        selectedPermissions,
        isOpen,
        handleClose,
        roleName,
        onCellClick,
        search,
        selectAllPermission,
        deselectAllPermissions,
        isSelectAllCheckboxSelected,
        isShowNoResult
    } = useManagePermissionsDialog(props);
    const {t} = useMainTranslation('', {keyPrefix: 'pathSettings.RolesCreate'});

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t(`Manage {{roleName}} permissions`, {roleName})}</DialogTitle>

            <DialogContent>
                <DialogContentWrapper>
                    <CustomSearchField
                        variant={'outlined'}
                        onChange={search.handleChange}
                        name={'search'}
                        value={search.search}
                        onClear={search.handleCleanSearch}
                        onKeyDown={search.handleEnterKey}
                        label={t('Search')}
                        dir={'ltr'}
                        placeholder={t('Search')}
                        size="small"
                        fullWidth
                        sx={{margin: '8px 0 0 0'}}
                    />
                </DialogContentWrapper>

                {!isShowNoResult && <Flex w={'100%'} ai={'center'} p={'0 16px'} style={{direction: 'ltr'}}>
                    <ListItemIcon>
                        <Checkbox
                            edge="start"
                            checked={isSelectAllCheckboxSelected} //checked if filtered array has 1 or more selected rows
                            // disableRipple
                            //availablePermissions is filtered array - after search if click on top checkbox - selecting all permissions that were on screen
                            onClick={() => isSelectAllCheckboxSelected ? deselectAllPermissions(availablePermissions) : selectAllPermission(availablePermissions)}
                        />
                    </ListItemIcon>

                    <Typography>{t('Permission name')}</Typography>
                </Flex>}

                {!isShowNoResult && <Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable}}/>}

                {isShowNoResult && <Typography color={colors.darkBlueText} sx={{margin: '16px 0 0 0'}}>{t('Nothing was found for your query. Try changing the query.')}</Typography>}

                <Flex w={'100%'} minh={'300px'} h={'300px'}>
                    <AutoSizer>
                        {({height, width}: {height: number, width: number}) => (
                            <List
                                height={height ?? 0}
                                itemCount={availablePermissions.length}
                                itemSize={58}
                                width={width ?? 0}
                                // itemData={{availablePermissions, selectedPermissions, onCellClick}}
                            >
                                {(props) => RowRenderer({...props, availablePermissions, selectedPermissions, onCellClick})}
                                {/*{rowRenderer}*/}
                            </List>
                        )}
                    </AutoSizer>
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{t('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}

function RowRenderer(props: any) {
    const {revDir} = useMainTranslation();
    const { index, style, onCellClick, selectedPermissions, availablePermissions} = props;
    const permission = availablePermissions.find((e: string, id: number) => id === index);
    const isChecked = selectedPermissions.find((e: string) => e === (permission ?? ''));
    const labelId = `checkbox-list-label-${permission}`;
    return (
        <Flex
            w={'100%'}
            ai={'center'}
            p={'8px 16px'}
            style={style}
            hoverStyles={`background-color: ${colors.backgrounds.grey_light}`}
            onClick={() => onCellClick(permission)}
        >
            <Checkbox
                edge="start"
                checked={isChecked}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
            />
            <Typography variant={'body1'} sx={{margin: !revDir ? '0 0 0 20px' : '0 20px 0 0'}}>{permission}</Typography>
        </Flex>
    );
}
