import styled from "styled-components";
import {Divider, Typography} from "@mui/material";
import colors from "../../../../../../../../theme/colors";
import CloseIcon from '@mui/icons-material/Close';


export const DialogTitleContainer = styled.div`
    display: flex;
  align-items: center;
  padding: 16px;
  gap: 16px;
`;

export const DialogTitle = styled(Typography)({
})

DialogTitle.defaultProps = {
    variant: 'subtitle2',
    color: colors.grayText
}

export const DialogTabTitle = styled(Typography)({
})

DialogTabTitle.defaultProps = {
    variant: 'subtitle2',
}

export const SignCanvasContainer = styled.div`
    width: 400px;
    height: 140px;
  background-color: ${colors.backgrounds.grey_light};
  position: relative;
`;


export const SignCanvasLineContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
    position: absolute;
  bottom: 20px;
  width: 100%;
  z-index: 100;
`;

export const SignSwitchContainer = styled.div`
    width: 100%;
  display: flex;
  flex-direction: column;
  margin: 16px 0 0 0;
`;

export const SwitchTitle = styled(Typography)({
})
SwitchTitle.defaultProps = {
    variant: 'body2',
    color: colors.grayText
}

export const SwitchDescTitle = styled(Typography)({
})
SwitchDescTitle.defaultProps = {
    variant: 'body2',
    color: colors.text.grey_dark
}

export const ActionDescTitle = styled(Typography)({
})
ActionDescTitle.defaultProps = {
    color: colors.text.grey,
    fontSize: '12px',
    fontWeight: 400,
    padding: '0 24px'
}

export const SignInputContainer = styled.div`
    width: 400px;
    height: 100px;
  background-color: ${colors.backgrounds.grey_light};
  position: relative;
`;

export const SignInput = styled.input`
  width: 80%;
  height: 24px;
  border: none;
  background-color: transparent;
  font-size: 24px;

  position: absolute;
  bottom: 20px;
  left: 8%;
  z-index: 200;
  
  &:focus-visible{
    outline: none;
  }
`;

export const SignDivider = styled(Divider)({
    width: '80%',
    color: colors.stroke.dark_grey
})

SignDivider.defaultProps = {
    flexItem: true
}

export const ClearButton = styled(CloseIcon)({
    color: colors.text.grey,
    cursor: 'pointer'
})

export const ClearImageButton = styled(CloseIcon)({
    color: colors.text.grey,
    cursor: 'pointer',
    // position: "absolute",
    // right: 0,
    // bottom: 0
})


export const DropZoneWrapper = styled.div`
    display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 150px;
  height: 150px;
  justify-content: center;
  margin: 10px 0 0 0;
  border: 1px dashed ${colors.primary.blue};
  border-radius: 5px;
`;

export const DropZoneInnerWrapper = styled.div`
    display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
    align-items: center;
  //padding: 15px 0 0 0;
  position: relative;
`;

export const ImageContainer = styled.div`
    display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px;
  //position: relative;
`;