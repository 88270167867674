import {
  eraseTimeOffList,
  hrSettings,
  loadings,
  replaceTimeOffMinMaxLoadedPage,
  timeOffSelector,
} from "../../store/slice";
import { useDispatch, useSelector } from "react-redux";
import { useMainTranslation } from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import { useSetBreadcrumbs } from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import { PATH_LOCAL_EMPLOYEES } from "../../../../../newShared/constants";
import {
  PATH_LOCAL_TIME_OFF_REQUESTS,
  PATH_LOCAL_TIME_OFF_REQUESTS_EXACT,
} from "../../constants";
import {
  HrRequestRequestorModel,
  HrTimeOffStatus,
  MainHrTimeOffRequestModel,
} from "../../../../../newShared/GQLTypes";
import { keyConfig } from "../../../../../newShared/components/genericFilter/types";
import { getTimeOffStatusChip } from "../../helpers";
import {
  CommonFilterType,
  minMaxLoadedPageType,
} from "../../../../../newShared/components/genericTable/types";
import {
  GetHrEmployeesSettings,
  mainHrGetTimeOffRequestsWithFilterPaginationAction,
} from "../../store/actions";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { resultToValues } from "../../../../../newShared/components/genericFilter/helpers";
import { mainHrGetTimeOffEmployeesAutocompleteApi } from "../../api";

export const useTimeOffRequests = () => {
  const { t: tMenu } = useMainTranslation("", { keyPrefix: "LeftMenu" });
  const { t } = useMainTranslation("", {
    keyPrefix: "pathHRManagement.pathTimeOff",
  });

  const dispatch = useDispatch();
  const { requests, pageInfo, minMaxLoadedPage } = useSelector(timeOffSelector);
  const { timeOffList: isLoadingList } = useSelector(loadings);
  const settings = useSelector(hrSettings);
  const history = useHistory();
  const location = useLocation();
  const { minLoadedPage, maxLoadedPage } = minMaxLoadedPage;

  useSetBreadcrumbs([
    {
      title: tMenu("HR Management"),
      path: PATH_LOCAL_EMPLOYEES,
    },
    {
      title: tMenu("Time off requests"),
      path: PATH_LOCAL_TIME_OFF_REQUESTS,
    },
  ]);

  useEffect(() => {
    if (!settings) dispatch(GetHrEmployeesSettings({ data: {} }));

    //eslint-disable-next-line
  }, []);

  const handleOpenTimeOff = (selected: MainHrTimeOffRequestModel) => {
    selected && history.push(`${PATH_LOCAL_TIME_OFF_REQUESTS_EXACT.replace(":timeoffId", selected?.id)}${location.search}`);
  };

  //filters
  const timeOffTypeConfig: keyConfig<string> = {
    type: "filter",
    key: "type",
    name: t("Time off type"),
    options: settings?.timeOffType ?? [],
    getOptionLabel: (a) => a,
    default: true,
  };

  const statusConfig: keyConfig<HrTimeOffStatus> = {
    type: "filter",
    key: "status",
    name: t("Status"),
    options: Object.values(HrTimeOffStatus).filter((e) => e !== HrTimeOffStatus.Cancelled),
    OptionRenderer: (a) => getTimeOffStatusChip(a, true),
    default: true,
  };

  const employeeConfig: keyConfig<HrRequestRequestorModel> = {
    type: "filter",
    key: "employee",
    name: t("Requestor"),
    fetchOptions: resultToValues<HrRequestRequestorModel>(
      mainHrGetTimeOffEmployeesAutocompleteApi
    ),
    getOptionLabel: (a) => a.fullName,
    getFilterValue: (a) => a.employeeId,
    isOptionEqual: (a, b) => a.employeeId === b.employeeId,
    isOptionEqualToSearch: (a, search) =>
      a.fullName.trim().toLowerCase().includes(search.trim().toLowerCase()),
    default: true,
  };

  const startDateConfig: keyConfig<string> = {
    type: "date",
    key: "startDate",
    name: t("Start date"),
    default: true,
  };

  const endDateConfig: keyConfig<string> = {
    type: "date",
    key: "endDate",
    name: t("End date"),
    default: true,
  };

  const requestDateConfig: keyConfig<string> = {
    type: "date",
    key: "requestDate",
    name: t("Request date"),
    default: true,
  };

  return {
    requests,
    handleOpenTimeOff,

    genericTable: {
      paging: {
        pageInfo,
        isLoading: isLoadingList,
        minLoadedPage,
        maxLoadedPage,
        setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceTimeOffMinMaxLoadedPage(data)),
        clearRows: () => dispatch(eraseTimeOffList()),
        fetchPaging: (page: number, count: number, filters: CommonFilterType, timeOffIdLike: string) => {
          dispatch(mainHrGetTimeOffRequestsWithFilterPaginationAction({data: {pageRequest: { page, count }, filter: { ...filters, timeOffIdLike }}}));
        },
      },
      filters: {
        configs: [
          employeeConfig,
          startDateConfig,
          endDateConfig,
          timeOffTypeConfig,
          statusConfig,
          requestDateConfig,
        ],
      },
    },
  };
};
