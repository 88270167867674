import {useDispatch, useSelector} from "react-redux";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    SETTING_ROOT_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_EDIT_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH,
    SETTINGS_ORGANIZATIONS_PATH
} from "../../../constants";
import {
    openManagePermissionsDialog,
    selectedOrganization,
    selectedWorkspace,
    selectedWorkspaceData,
    workspacesLoadings
} from "../../../store/slice";
import {useHistory, useParams} from "react-router-dom";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {TWorkspaceRole} from "../../../types";
import {useEffect, useState} from "react";
import {GetWorkspaceById, GetWorkspaceCollabs, UpdateRole} from "../../../store/actions";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";

export const useEditRole = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {orgId, wsId, roleId} = useParams<{orgId: string, wsId: string, roleId: string}>();
    const {setMessage} = useMessageDialog();

    //selectors
    const selectedWs = useSelector(selectedWorkspace);
    const selectedOrg = useSelector(selectedOrganization);
    const {workspaceRoles, availablePermissions} = useSelector(selectedWorkspaceData);
    const {editRole, getUsers} = useSelector(workspacesLoadings);

    //bread
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    const [currRole, setCurrRole] = useState<TWorkspaceRole | null>(null);

    useSetBreadcrumbs([
        {
            title: tMenu('Settings'),
            path: SETTING_ROOT_PATH
        },
        {
            title: tMenu('Organizations'),
            path: SETTINGS_ORGANIZATIONS_PATH
        },
        {
            title: (selectedOrg?.name ?? 'Loading...'),
            path: SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH.replace(':orgId', selectedOrg?.id ?? '-')
        },
        {
            title: tMenu('Workspaces'),
            path: SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH.replace(':orgId', selectedOrg?.id ?? '-')
        },
        {
            title: (selectedWs?.name ?? 'Loading...'),
            path: SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_PATH
                .replace(':orgId', selectedOrg?.id ?? '-')
                .replace(':wsId', selectedWs?.id ?? '-')
        },
        {
            title: (currRole ? `Edit ${currRole.name}` : `Loading...`),
            path: SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_EDIT_PATH
                .replace(':orgId', selectedOrg?.id ?? '-')
                .replace(':wsId', selectedWs?.id ?? '-')
                .replace(':roleId', roleId)
        },
    ]);

    useEffect(() => {
        if (!selectedWs || !selectedOrg) {
            if (!orgId || !wsId) {
                handleGoBack();
            } else {
                dispatch(GetWorkspaceById({
                    workspaceId: wsId,
                    organizationId: orgId,
                    onReject: handleGoBack
                }));
            }
        }
        //getting available permissions
        dispatch(GetWorkspaceCollabs({workspaceId: wsId, onReject: handleGoBack}));
        if(workspaceRoles.length > 0){
            const searchedRole = workspaceRoles.find(e => e.id === roleId);
            if(searchedRole){
                setCurrRole(searchedRole);
                setForm(searchedRole);
            }else{
                handleGoBack();
            }
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!getUsers && !currRole && workspaceRoles.length > 0){
            const searchedRole = workspaceRoles.find(e => e.id === roleId);
            if(searchedRole){
                setCurrRole(searchedRole);
                setForm(searchedRole);
            }else{
                handleGoBack();
            }
        }
        //eslint-disable-next-line
    }, [getUsers]);


    const handleGoBack = () => {
        history.push(
            SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_PATH
                .replace(':orgId', selectedOrg?.id ?? '-')
                .replace(':wsId', selectedWs?.id ?? '-')
        )
    }

    //form
    const {form, handleChange, setForm} = useForm<TWorkspaceRole>({
        id: '',
        name: '',
        workspaceId: '',
        permissions: [],
        createDate: '',
        lastUpdateDate: '',
        origin: false,
        system: false
    });

    const isOk = () => {
        if(!currRole) return false;
        if(!form.permissions.length) return false;
        if(form.name.trim().toLowerCase().length > 0){
            if(form.name.trim().toLowerCase() === currRole.name.trim().toLowerCase()){
                //if same name - name.length > 0 && any permission change
                return form.name.trim().length > 0 && JSON.stringify(form.permissions) !== JSON.stringify(currRole.permissions) && form.permissions.length > 0;
            }else{
                //if it is another name - checking if it is already exists
                return !workspaceRoles.some(e => e.name.trim().toLowerCase() === form.name.trim().toLowerCase())
            }
        }
        return false;
    }

    const handleOpenManagePermissionDialog = () => {
        dispatch(openManagePermissionsDialog());
    }

    const selectPermission = (permission: string) => {
        setForm({
            ...form,
            permissions: [...form.permissions, permission]
        })
    }

    const selectAllPermission = (permissions: string[]) => {
        // console.log(`SELECT ALL: ${permissions}`);
        setForm({
            ...form,
            permissions: Array.from(new Set([...form.permissions, ...permissions]))
        })
    }

    const deselectAllPermissions = (permissions: string[]) => {
        setForm({
            ...form,
            permissions: form.permissions.filter(e => !permissions.some(p => p === e))
        })
    }
    const deselectPermission = (permission: string) => {
        setForm({
            ...form,
            permissions: form.permissions.filter(e => e !== permission)
        })
    }

    const onSuccess = () => {
        setMessage({
            title: 'Role updated',
            message: `Role ${form.name} with ${form.permissions.length} updated!`
        });
        handleGoBack();
    }

    const handleSubmit = () => {
        if(isOk() && selectedWs){
            dispatch(UpdateRole({role: form, workspaceId: selectedWs.id, onSuccess}));
        }
    }

    return{
        handleGoBack,
        form,
        handleChange,
        selectedPermissions: form.permissions,
        isOk: isOk(),
        isLoading: editRole,
        handleOpenManagePermissionDialog,
        availablePermissions,
        selectPermission,
        selectAllPermission,
        deselectAllPermissions,
        deselectPermission,
        handleSubmit,
        isManagePermissionButtonDisabled: getUsers,
        currRole
    }
}