import colors from "../../../newShared/theme/colors";
import {
    ACCOUNT_DELETE_STATUS,
    ACCOUNT_KEEP_STATUS,
    ACCOUNT_REVOKE_STATUS,
    COMPLETE_STATUS,
    EXTERNAL_TYPE,
    IN_PROGRESS_STATUS,
    ORHAN_TYPE,
    REVIEW_CAMPAIGN_FINISH_STEP_CUSTOM,
    REVIEW_CAMPAIGN_STEP_EXECUTOR,
    REVIEW_CAMPAIGN_STEP_MANAGER,
    REVIEW_CAMPAIGN_STEP_SIGN,
    REVIEW_CAMPAIGN_STEP_VENDOR,
    REVIEW_CAMPAIGN_STEP_VERIFY,
    SCHEDULE_TRIGGER_TYPE,
    TERMINATED_STATUS
} from "./constants";
import {
    AccessManagementAppAccount,
    applicationAccessDataWithoutAccounts,
    campaignReviewExecutorAccountFillableType,
    campaignReviewExecutorAccountFillableTypeWithApplication,
    campaignReviewExecutorStageActorAppData,
    campaignReviewExecutorStageActorType,
    campaignReviewExecutorStageType,
    campaignReviewInstructionEtaConfigType,
    campaignReviewInstructionType,
    smallCampaignReviewExecutorStageType
} from "./types";
// import moment from 'moment';

export const getColorAndTextByStatus = (status: string | null): {text: string, color: string} => {
    switch (status){
        // case 'rejected' inProgress
        case IN_PROGRESS_STATUS: return {text: 'In progress', color: colors.decorative.orange};
        case TERMINATED_STATUS: return {text: 'Terminated', color: colors.decorative.violet};
        case COMPLETE_STATUS: return {text: 'Completed', color: colors.text.success};
        case null: return {text: 'Not started', color: colors.text.grey_dark};
    }
    return {text: status, color: colors.darkText};
}

export const getColorAndTextByStage = (status: string | null): {text: string, color: string} => {
    switch (status){
        // case 'rejected' inProgress
        case REVIEW_CAMPAIGN_STEP_MANAGER: return {text: 'Managers', color: colors.darkText};
        case REVIEW_CAMPAIGN_STEP_VENDOR: return {text: 'Vendor owners', color: colors.darkText};
        case REVIEW_CAMPAIGN_STEP_EXECUTOR: return {text: 'Execution', color: colors.darkText};
        case REVIEW_CAMPAIGN_STEP_VERIFY: return {text: 'Verify', color: colors.darkText};
        case REVIEW_CAMPAIGN_STEP_SIGN: return {text: 'Sign', color: colors.darkText};
        case REVIEW_CAMPAIGN_FINISH_STEP_CUSTOM: return {text: 'Complete', color: ''}; //used only in executors to map names
        case null: return {text: '- - -', color: colors.text.grey_dark};
    }
    return {text: status, color: colors.darkText};
}

export const getColorAndTextByAccountStatus = (status: string | null): {text: string, color: string} => {
    switch (status){
        case ACCOUNT_KEEP_STATUS: return {text: 'Kept', color: colors.text.success};
        case ACCOUNT_REVOKE_STATUS: return {text: 'Revoked', color: colors.warningActions.red};
        case ACCOUNT_DELETE_STATUS: return {text: 'Delete account', color: colors.text.grey_dark};
        default: return {text: 'Pending', color: colors.decorative.orange};
    }
}

export const calculateCurrentStage = (stages: smallCampaignReviewExecutorStageType[] | campaignReviewExecutorStageType[]): string => {
    //searching for stage that is in progress -> if not such step - executor was completed or terminated -> if no terminated status - review campaign completed
    return stages.find(e => e.status === IN_PROGRESS_STATUS)?.type ?? stages.find(e => e.status === TERMINATED_STATUS)?.type ?? REVIEW_CAMPAIGN_FINISH_STEP_CUSTOM;
}


export const mapper: { [key: string]: number } = {
    Hours: 3600*1000,
    Days: 86400*1000,
    Weeks: 604800*1000,
    Months: 2592000*1000,
    Years: 31556952*1000,
}

export const timeValues: string[] = ['Hours','Days', 'Weeks', 'Months', 'Years'];


export const getInitialInstruction = (): campaignReviewInstructionType => {
    return {
        id: '',
        name: '',
        workspaceName: '',
        workspaceId: '',
        trigger: {
            type: 'manual',
            start: new Date(new Date(Date.now() + mapper.Days).setMinutes(0, 0, 0)).toISOString(),
            repeat: mapper.Years
        },
        etaConfig: {
            etaManager: 7,
            etaVendor: 7,
            etaExecution: 7,
            etaVerify: 7,
            etaSign: 7,
        },
        finalApprover: {
            email: '',
            firstName:'',
            lastName: '',
            publicId: ''
        },
        active: true,

        createDate: new Date().toISOString(),
        lastRunningDate: null,
        lastExecutorId: null,
        lastRunStatus: null,


        applications: [],
        evidences: [],
        description: '',
        applicationAccessIds: [],
        evidence: {
            data: [],
            type :''
        }
    }
}

export const translateText = (data: number): {factor: number, timeValue: string} => {
    if(data === mapper.Years) return {factor: 1, timeValue: timeValues[4]};
    if(data === mapper.Months) return {factor: 1, timeValue: timeValues[3]};
    if(data === mapper.Weeks) return {factor: 1, timeValue: timeValues[2]};
    if(data === mapper.Days) return {factor: 1, timeValue: timeValues[1]};
    if(data === mapper.Hours) return {factor: 1, timeValue: timeValues[0]};

    if(data % mapper.Years === 0  ) return {factor: data / mapper.Years, timeValue: timeValues[4]};
    if(data % mapper.Months === 0) return {factor: data / mapper.Months, timeValue: timeValues[3]};
    if(data % mapper.Weeks === 0) return {factor: data / mapper.Weeks, timeValue: timeValues[2]};
    if(data % mapper.Days === 0) return {factor: data / mapper.Days, timeValue: timeValues[1]};
    if(data % mapper.Hours === 0) return {factor: data / mapper.Hours, timeValue: timeValues[0]};

    return {factor: -1, timeValue: ' error'};
}

export const getEtaConfigByInstruction = (instruction: campaignReviewInstructionEtaConfigType): campaignReviewInstructionEtaConfigType => {
    return {
        etaManager: instruction.etaManager / mapper.Days,
        etaVendor: instruction.etaVendor / mapper.Days,
        etaExecution: instruction.etaExecution / mapper.Days,
        etaVerify: instruction.etaVerify / mapper.Days,
        etaSign: instruction.etaSign / mapper.Days
    }
}

export const calculateNextStart = (instruction: campaignReviewInstructionType): string | null => {
    if(!instruction.active || instruction.trigger.type !== SCHEDULE_TRIGGER_TYPE) return null;
    let date:number = Date.parse(instruction.trigger.start);
    let nowDate: number = Date.now();
    // console.log(`date: ${date} and nowDate: ${nowDate}`);
    while (date < nowDate){
        // console.log(`${date} is < now(${nowDate})`);
        date = date + instruction.trigger.repeat;
    }
    return new Date(date).toISOString();
}


// {employeeId}: {
//     employeeId
//     employeeName
//     accounts
// }


export type groupByEmployeeReturnType = {
    [key: string]: employeeAndAccountsWithApp
}

export type employeeAndAccountsWithApp = {
    employee: string,
    employeeId: string,
    accounts: campaignReviewExecutorAccountFillableTypeWithApplication[]
}

export const groupByEmployee = (apps: campaignReviewExecutorStageActorAppData[], notMappedAccountName?: string): employeeAndAccountsWithApp[] => {
    const res: groupByEmployeeReturnType = {};
    apps.forEach(app => {
        app.data.forEach(data => {
            if(data.employeeId){
                const obj = res[data.employeeId];
                if(obj){
                    //if already added - adding current account with appId
                    const newObj = {
                        ...obj,
                        accounts: [...obj.accounts, {
                            ...data,
                            applicationAccessId: app.applicationAccessId,
                            applicationAccessName: app.applicationAccessName,
                            applicationId: app.applicationId,
                            vendorId: app.vendorId,
                            vendorName: app.vendorName
                        }]
                    }
                    res[data.employeeId] = newObj;
                }else{
                    //no such employee in array
                    res[data.employeeId] = {
                        employee: data.employee ?? '',
                        employeeId: data.employeeId,
                        accounts: [{
                            ...data,
                            applicationAccessId: app.applicationAccessId,
                            applicationAccessName: app.applicationAccessName,
                            applicationId: app.applicationId,
                            vendorId: app.vendorId,
                            vendorName: app.vendorName
                        }]
                    }
                }
            }else{
                //setting here all not mapped employees
                const customId = 'noEmployeeCustomId';
                const obj = res[customId];
                if(obj){
                    //if already added - adding current account with appId
                    const newObj = {
                        ...obj,
                        accounts: [...obj.accounts, {
                            ...data,
                            applicationAccessId: app.applicationAccessId,
                            applicationAccessName: app.applicationAccessName,
                            applicationId: app.applicationId,
                            vendorId: app.vendorId,
                            vendorName: app.vendorName
                        }]
                    }
                    res[customId] = newObj;
                }else{
                    //no such employee in array
                    res[customId] = {
                        employee: notMappedAccountName ?? 'Not mapped accounts',
                        employeeId: customId,
                        accounts: [{
                            ...data,
                            applicationAccessId: app.applicationAccessId,
                            applicationAccessName: app.applicationAccessName,
                            applicationId: app.applicationId,
                            vendorId: app.vendorId,
                            vendorName: app.vendorName
                        }]
                    }
                }
            }
        })
    })
    return Object.values(res);
}

export const mapCampaignReviewExecutorAccountFillableTypeWithApplicationForSingleUpdateRequest = (account: campaignReviewExecutorAccountFillableTypeWithApplication): {app: applicationAccessDataWithoutAccounts, account: campaignReviewExecutorAccountFillableType} => {
    return {
        app: {
            applicationAccessId: account.applicationAccessId,
            applicationAccessName: account.applicationAccessName,
            applicationId: account.applicationId,
            vendorId: account.vendorId,
            vendorName: account.vendorName
        },
        account: {
            employee: account.employee,
            employeeId: account.employeeId,
            account: account.account,
            accountId: account.accountId,
            accountType: account.accountType,
            roles: account.roles,
            status: account.status,

            id: account.id,
            commentManager: account.commentManager,
            statusManager: account.statusManager,
            publicIdManager: account.publicIdManager,
            commentVendor: account.commentVendor,
            statusVendor: account.statusVendor,
            publicIdVendor: account.publicIdVendor,
        }
    }

}


export const mapAppsByAccountIds = (accountIds: string[], actor: campaignReviewExecutorStageActorType, setStatus: string, isManagers: boolean): campaignReviewExecutorStageActorAppData[] => {
    const res: campaignReviewExecutorStageActorAppData[] = [];

    (actor.appsData ?? []).forEach(e => {
        const app:campaignReviewExecutorStageActorAppData = {...e, data: []};
        e.data.forEach(data => {
            if(accountIds.some(a => a === data.id)){
                //if this account selected - changing status and pushing to new app obj
                app.data.push({...data, statusManager: isManagers ? setStatus : data.statusManager, statusVendor: !isManagers ? setStatus : data.statusVendor});
            }
        })
        if(app.data.length > 0) res.push(app);
    })

    return res;
}

export const getPermissionArray = (permissions: string): string[] => {
    const array = permissions.split(',');
    if(array.length === 1 && array[0] === ''){
        return [];
    }
    return array.map(e => e.trim());
}

export const getEmployeeText = (row: AccessManagementAppAccount) => {
    if(row.accountType === EXTERNAL_TYPE){
        return row.employee ?? '- - -';
    }else if(row.accountType === ORHAN_TYPE){
        return '- - -';
    }else{
        return row.employee ?? '';
    }
}

export const campaignReviewInstructionGetAppsText = (row: campaignReviewInstructionType) => {
    return row.applications.length > 2 ? `${row.applications.map(e => e.name).filter((_, id) => id < 3).join(', ')}...` : row.applications.map(e => e.name).join(', ')
}