import {TCollectorHistory, TShortCollector} from "../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {getCollectorHistoryAction, getShortCollectorsAction} from "./actions";
import {TPageInfo} from "../../../../newShared/types";
// import {AddableApp} from "../../../../domain/Integrations/types";
import {setPaginationInStore} from "../../../../newShared/hooks/useScroll/helpers";
import {CollectorEvidenceData} from "../../../../newShared/GQLTypes";
import {AddableApp} from "../helpers";

export type initialCollectorState = {
    collectors: {
        isLoading: boolean
        collectors: (TShortCollector | null)[];
        pageInfo: TPageInfo;
    },
    selectedCollector: {
        isLoading: boolean
        selectedCollector: TShortCollector | null;
        evidences: CollectorEvidenceData[],
        history: (TCollectorHistory | null)[];
        pageInfo: TPageInfo;
    },
    dialogs: {
        showResults: boolean
    }

    apps: {
        apps: AddableApp[],
        isLoading: boolean,
    },
}

const initialState: initialCollectorState = {
    collectors: {
        isLoading: false,
        collectors: [],
        pageInfo: {
            total: 0,
            page: 0,
            count: 50,
            sort: ''
        },
    },

    selectedCollector: {
        isLoading: false,
        selectedCollector: null,
        evidences: [],
        history: [],
        pageInfo: {
            total: 0,
            page: 0,
            count: 50,
            sort: ''
        },
    },

    dialogs: {
        showResults: false
    },

    apps: {
        apps: [],
        isLoading: false,
    },
}

export const CollectorsSlice = createSlice({
    name: 'Collector',
    initialState,
    reducers: {
        clearAction: (state: initialCollectorState, {payload}: {payload: string[]}) => {
            if (payload.includes('apps') || payload.length === 0) {state.apps = initialState.apps}
            if (payload.includes('collectors') || payload.length === 0) {state.collectors = initialState.collectors}
            if (payload.includes('selectedCollector') || payload.length === 0){
                state.selectedCollector = initialState.selectedCollector;
                state.dialogs.showResults = false
            }
        },

        openShowResults: (slice) => { slice.dialogs.showResults = true},
        hideShowResults: (slice) => { slice.dialogs.showResults = false}

    },
    extraReducers: builder => {
        builder
            .addCase(getShortCollectorsAction.pending, (slice: initialCollectorState) => {
                slice.collectors.isLoading = true;
            })
            .addCase(getShortCollectorsAction.rejected, (slice: initialCollectorState) => {
                slice.collectors.isLoading = false;
            })
            .addCase(getShortCollectorsAction.fulfilled, (slice: initialCollectorState, {payload: { resp}}) => {
                slice.collectors.isLoading = false;

                const {array, pageInfo} = setPaginationInStore<TShortCollector>(
                    slice.collectors.pageInfo,
                    slice.collectors.collectors,
                    resp.pageInfo,
                    resp.collectors,
                    false
                );
                slice.collectors.collectors = array;
                slice.collectors.pageInfo = pageInfo;
            })
            //GetCollectorHistory
            .addCase(getCollectorHistoryAction.pending, (slice: initialCollectorState) => {
                slice.selectedCollector.isLoading = true;
            })
            .addCase(getCollectorHistoryAction.rejected, (slice: initialCollectorState) => {
                slice.selectedCollector.isLoading = false;
            })
            .addCase(getCollectorHistoryAction.fulfilled, (slice: initialCollectorState, {payload: {resp}}) => {
                slice.selectedCollector.isLoading = false;

                const {array, pageInfo} = setPaginationInStore<TCollectorHistory>(
                    slice.selectedCollector.pageInfo,
                    slice.selectedCollector.history,
                    resp.pageInfo,
                    resp.history,
                    true
                );
                slice.selectedCollector.history = array;
                slice.selectedCollector.pageInfo = pageInfo;
                slice.selectedCollector.selectedCollector = resp.collector;
                slice.selectedCollector.evidences = resp.evidences;
            })

    }
});

export const CollectorReducer = CollectorsSlice.reducer;

export const {
    clearAction,
    openShowResults,
    hideShowResults
} = CollectorsSlice.actions;

const selectSelf = (state: AppState):initialCollectorState => state.Collector;

export const collectorsSelector = createSelector(selectSelf, state => state.collectors);
export const selectedCollectorSelector = createSelector(selectSelf, state => state.selectedCollector);
export const collectorAppsSelector = createSelector(selectSelf, state => state.apps);
export const collectorDialogs = createSelector(selectSelf, state => state.dialogs);






