import {
    ROLE_ORG_ADMIN,
    ROLE_WS_ADMIN,
    ROLE_WS_OWNER,
    SETTING_ROOT_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_CREATE_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_EDIT_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_USERS_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH,
    SETTINGS_ORGANIZATIONS_PATH,
} from "../../../constants";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import React, {useEffect, useState} from "react";
import {TBreadcrumb} from "../../../../../barsEnvironment/breadcrumbs/types";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {
    openChangeWorkspaceNameDialog,
    openDeleteRoleDialog,
    openDeleteWorkspaceDialog,
    openDeleteWSCollaboratorDialog,
    openInviteWorkspaceUserDialog,
    openManageCollabAccess,
    openPreviewRoleDialog,
    selectedOrganization,
    selectedWorkspace,
    selectedWorkspaceData,
    setIsLoadingCancelWSInvite,
    setIsLoadingResendWSInvite,
    workspacesLoadings
} from "../../../store/slice";
import {useHistory, useParams} from "react-router-dom";
import {
    CancelWorkspaceInvite,
    GetWorkspaceById,
    GetWorkspaceCollabs,
    kycSetLimitAction,
    ResendWorkspaceInvite
} from "../../../store/actions";
import {useRouteMatch} from "react-router";
import {TWorkspaceCollaborator, TWorkspaceInvite, TWorkspaceRole, workspacesTabs} from "../../../types";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {UseManageWorkspacesAndOrganizations} from "../../../../cookies/hooks/useManageWorkspacesAndOrganizations";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {
    useGenericFiltersStorage
} from "../../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";

export const useWorkspacesExact = () => {
    const dispatch = useDispatch();
    const {currentSearchForFetch} = useGenericFiltersStorage();
    const history = useHistory();
    const {orgId, wsId} = useParams<{orgId: string, wsId: string}>();
    const {user} = UseManageWorkspacesAndOrganizations();
    const {setMessage} = useMessageDialog();
    //ROOT
    const selectedWs = useSelector(selectedWorkspace);
    const selectedOrg = useSelector(selectedOrganization);
    const {getUsers, deleteCollab, changeInviteRole, inviteUser, cancelInvite, changeCollabRole,
        addRole, deleteRole, editRole, joinWorkspaceByAdmin, exact, resendInvite
    } = useSelector(workspacesLoadings);
    const additionalData = useSelector(selectedWorkspaceData);

    //ROUTES
    const isRolesTab = useRouteMatch(SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_PATH);
    const isUsersTab = useRouteMatch(SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_USERS_PATH);

    //bread
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const [customTabBreadCrumb, setCustomTabBreadCrumb] = useState<TBreadcrumb | null>(null);

    useSetBreadcrumbs([
        {
            title: tMenu('Settings'),
            path: SETTING_ROOT_PATH
        },
        {
            title: tMenu('Organizations'),
            path: SETTINGS_ORGANIZATIONS_PATH
        },
        {
            title: (selectedOrg?.name ?? 'Loading...'),
            path: SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH.replace(':orgId', orgId)
        },
        {
            title: tMenu('Workspaces'),
            path: SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH.replace(':orgId', orgId)
        },
        {
            title: (selectedWs?.name ?? 'Loading...'),
            path: SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_USERS_PATH.replace(':orgId', orgId).replace(':wsId', wsId)
        },
        ...(customTabBreadCrumb ? [customTabBreadCrumb] : [])
    ]);

    useEffect(() => {
        //fetch
        if (!selectedWs || !selectedOrg) {
            if (!orgId || !wsId) {
                onReject(`No valid id found.`);
            } else {
                dispatch(GetWorkspaceById({
                    workspaceId: wsId,
                    organizationId: orgId,
                    onReject
                }));
            }
        }

        dispatch(GetWorkspaceCollabs({workspaceId: wsId, onReject}));

        if(isUsersTab){
            setTab("users");
            setCustomTabBreadCrumb({
                title: tMenu('Collaborators'),
                path: SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_USERS_PATH.replace(':orgId', orgId).replace(':wsId', wsId)
            });
        }
        if(isRolesTab){
            setTab("roles");
            setCustomTabBreadCrumb({
                title: tMenu('Roles'),
                path: SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_PATH.replace(':orgId', orgId).replace(':wsId', wsId)
            });
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!getUsers && (additionalData.collabs.length > 0 || additionalData.invites.length > 0)){
            //after fetch set invites/collabs into local filtered array (local search)
            setFilteredInvites(additionalData.invites);
            setFilteredCollabs(additionalData.collabs);
            setFilteredRoles(additionalData.workspaceRoles);
        }
        //eslint-disable-next-line
    }, [getUsers]);

    useEffect(() => {
        //if role was changed or invite/collab deleted - rerender list
        if(changeInviteRole.email !== null || !cancelInvite.isLoading || !inviteUser || !deleteCollab || !changeCollabRole.isLoading || !joinWorkspaceByAdmin){
            handleFilter();
        }
        //eslint-disable-next-line
    }, [changeInviteRole.email, cancelInvite.isLoading, inviteUser, deleteCollab, changeCollabRole.isLoading, joinWorkspaceByAdmin]);

    useEffect(() => {
        handleFilter();
        handleFilterRoles();
        //eslint-disable-next-line
    }, [currentSearchForFetch]);

    useEffect(() => {
        //if role was created/updated/deleted - rerender list
        if(!addRole || !deleteRole || !editRole){
            handleFilterRoles();
        }
        //eslint-disable-next-line
    }, [addRole, deleteRole, editRole]);


    //tabs
    const [tab, setTab] = useState<workspacesTabs>('users');

    const handleSetTab = (actionTab: workspacesTabs) => {
        switch (actionTab){
            case 'users': {
                setTab('users');
                setCustomTabBreadCrumb({
                    title: tMenu('Collaborators'),
                    path: SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_USERS_PATH.replace(':orgId', orgId).replace(':wsId', wsId)
                });
                history.push(SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_USERS_PATH.replace(':orgId', orgId).replace(':wsId', wsId));
                return;
            }
            case 'roles': {
                setTab('roles');
                setCustomTabBreadCrumb({
                    title: tMenu('Roles'),
                    path: SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_PATH.replace(':orgId', orgId).replace(':wsId', wsId)
                });
                history.push(SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_PATH.replace(':orgId', orgId).replace(':wsId', wsId));
                return;
            }
            default: {
                setTab(actionTab);
            }
        }
    }

    //actions

    const onReject = (snack: string) => {
        // dispatch(addErrorSnack(snack));
        history.push(SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH.replace(':orgId', orgId));
    }

    const handleGoBack = () => {
        history.push(SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH.replace(':orgId', orgId));
    }

    const handleOpenInviteUserDialog = () => {
        dispatch(openInviteWorkspaceUserDialog());
        handleClose();
    }

    const handleOpenDeleteWorkspaceDialog = () => {
        dispatch(openDeleteWorkspaceDialog());
        handleClose();
    }

    const handleOpenChangeNameDialog = () => {
        dispatch(openChangeWorkspaceNameDialog());
        handleClose();
    }

    const handleChangeKycLimit = (workspaceId: string, limitEnabled: boolean, signal?: AbortSignal) => {
        dispatch(kycSetLimitAction({data: {organizationId: '', workspaceId, limitEnabled}, signal}))
    }

    //MENU
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //USERS
    const usersForm = useForm<{search: string}>({search: ''});
    const [filteredCollabs, setFilteredCollabs] = useState<TWorkspaceCollaborator[]>([]);
    const [filteredInvites, setFilteredInvites] = useState<TWorkspaceInvite[]>([]);

    // const handleCleanSearchUsers = () => {
    //     usersForm.setForm({search: ''});
    //     setFilteredInvites(additionalData.invites);
    //     setFilteredCollabs(additionalData.collabs);
    // }

    // const handleEnterKeyUsers = (e: any) => {
    //     if(e.keyCode === 13 && usersForm.form.search.trim().length > 0){
    //         handleFilter();
    //     }
    // }

    const handleFilter = () => {
        setFilteredInvites(additionalData.invites.filter(e => e.emailAddress.trim().toLowerCase().startsWith(currentSearchForFetch.trim().toLowerCase())));
        setFilteredCollabs(additionalData.collabs.filter(e => e.email.trim().toLowerCase().startsWith(currentSearchForFetch.trim().toLowerCase())));
    }

    const handleOpenAddUser = () => {
        dispatch(openInviteWorkspaceUserDialog());
    }

    //ROLES
    const rolesForm = useForm<{search: string}>({search: ''});
    const [filteredRoles, setFilteredRoles] = useState<TWorkspaceRole[]>([]);

    // const handleCleanSearchRoles = () => {
    //     rolesForm.setForm({search: ''});
    //     setFilteredRoles(additionalData.workspaceRoles);
    // }
    //
    // const handleEnterKeyRoles = (e: any) => {
    //     if(e.keyCode === 13 && rolesForm.form.search.trim().length > 0){
    //         handleFilterRoles();
    //     }
    // }

    const handleFilterRoles = () => {
        setFilteredRoles(additionalData.workspaceRoles.filter(e => e.name.trim().toLowerCase().includes(currentSearchForFetch.trim().toLowerCase())));
    }

    const handleGoToCreateRole = () => {
        history.push(SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_CREATE_PATH.replace(':wsId', wsId).replace(':orgId', orgId))
    }

    const isOrgAdminAndNotMemberOfWS = (): boolean => {
        if(!user) return false;
        if(!additionalData.collabs.some(e => e.publicId === user.publicId)){
            //not part of workspace
            if(selectedOrg){
                const currUser = selectedOrg.collaborators.find(e => e.publicId === user.publicId);
                if(currUser && currUser.role === ROLE_ORG_ADMIN){
                    //current user is admin of org and not part of workspace
                    return true;
                }
            }
        }
        return false;
    };

    const isNoPlace = Boolean(selectedWs && selectedOrg && selectedOrg?.configuration.availableCollaboratorsCount < (selectedWs?.collaboratorsCount) + 1);

    // const handleUpdateRoleOfInvited = (row: TWorkspaceInvite, role: string) => {
    //     const searchedRole = workspaceRoles.find(e => e.name === role);
    //     if(selectedWs && !changeInviteRole && searchedRole){
    //         dispatch(setPrevWSInviteRole({prevRole: row.role, email: row.emailAddress, newRole: searchedRole}));
    //         dispatch(ChangeWSInviteRole({workspaceId: selectedWs.id, email: row.emailAddress, newRole: role, inviteId: row.id}));
    //     }
    // }

    const handleCancelInvite = (row: TWorkspaceInvite) => {
        if(selectedWs){
            // //setting loading to true to show loading on exact row/card
            dispatch(setIsLoadingCancelWSInvite({email: row.emailAddress}));
            dispatch(CancelWorkspaceInvite({
                workspaceId: selectedWs.id,
                email: row.emailAddress,
                inviteId: row.id,
                onSuccess: () => {
                    setMessage({title: 'Invite canceled!', message: `Invite for ${row.emailAddress} is canceled.`});
                }
            }));
        }
    }

    const handleResendInvite = (row: TWorkspaceInvite) => {
        if(selectedWs){
            //setting loading to true to show loading on exact row/card
            dispatch(setIsLoadingResendWSInvite({email: row.emailAddress}));
            dispatch(ResendWorkspaceInvite({
                workspaceId: selectedWs.id,
                email: row.emailAddress,
                inviteId: row.id,
                onSuccess: () => {
                    setMessage({title: 'Invite resent!', message: `Invite email sent to ${row.emailAddress}.`});
                }
            }));
        }
    }

    const handleDeleteUser = (row: TWorkspaceCollaborator) => {
        dispatch(openDeleteWSCollaboratorDialog({collab: row}));
    }

    const handleOpenRoleManage = (row: TWorkspaceCollaborator) => {
        dispatch(openManageCollabAccess({collab: row}));
    }

    const handlePreviewRole = (role: TWorkspaceRole) => {
        dispatch(openPreviewRoleDialog(role));
    }

    const handleEditRole = (role: TWorkspaceRole) => {
        selectedWs && selectedOrg && history.push(
            SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_EDIT_PATH
                .replace(':orgId', selectedOrg.id)
                .replace(':wsId', selectedWs.id)
                .replace(':roleId', role.id)
        )
    }

    const handleDeleteRole = (role: TWorkspaceRole) => {
        dispatch(openDeleteRoleDialog(role));
    }

    return{
        isWorkspaceOwner: additionalData.collabs.find(e => e.publicId === user?.publicId)?.roles.some(e => e.name === ROLE_WS_OWNER),
        isWorkspaceAdmin: additionalData.collabs.find(e => e.publicId === user?.publicId)?.roles.some(e => e.name === ROLE_WS_ADMIN),
        workspace: selectedWs,
        organization: selectedOrg,
        isLoadingWorkspace: exact,
        isLoadingUsers: getUsers,
        // isChangingKycLimit: changeKycLimit,
        isLoadingJoinWSByAdmin: joinWorkspaceByAdmin,
        isNoPlace,
        tab,
        handleSetTab,
        actions: {
            handleGoBack,
            handleOpenInviteUserDialog,
            handleOpenDeleteWorkspaceDialog,
            handleOpenChangeNameDialog,
            handleOpenAddUser,
            handleGoToCreateRole,
            handleChangeKycLimit,
            // handleJoinWorkspaceByAdmin: () => {
            //     if(!joinWorkspaceByAdmin && !isNoPlace && user && selectedWs){
            //         //if not already loading + if there is a place for admin
            //         const adminWsRole = additionalData.workspaceRoles.find(e => e.name === ROLE_WS_ADMIN);
            //         adminWsRole && dispatch(JoinWorkspaceByOrgAdmin({
            //             workspaceId: selectedWs.id,
            //             user,
            //             adminWsRole,
            //             onSuccess: () => setMessage({title: 'Successfully completed!', message: 'You have joined the workspace'})
            //         }))
            //     }
            // }
        },
        menu: {
            anchorEl,
            handleClose,
            handleClick,
            open,
        },
        user,
        invites: additionalData.invites,
        collabs: additionalData.collabs,
        users: {
            search: usersForm.form.search,
            handleChange: usersForm.handleChange,
            collabs: filteredCollabs,
            invites: filteredInvites,
            // handleCleanSearchUsers,
            // handleEnterKeyUsers,
            showNoResult: usersForm.form.search.trim().length > 0 && !filteredInvites.length && !filteredCollabs.length,
        },
        roles: {
            roles: filteredRoles,
            search: rolesForm.form.search,
            handleChange: rolesForm.handleChange,
            // handleCleanSearchRoles,
            // handleEnterKeyRoles,
            showNoResult: rolesForm.form.search.trim().length > 0 && !filteredRoles.length,
        },
        isOrgAdminAndNotMemberOfWS: isOrgAdminAndNotMemberOfWS(),
        // workspaceRoles,
        // handleUpdateRoleOfInvited,
        // isLoadingChangeInviteRole: changeInviteRole,
        handleCancelInvite,
        handleResendInvite,
        handleDeleteUser,
        handleOpenRoleManage,
        isLoadingResendInvite: resendInvite,
        isLoadingCancelInvite: cancelInvite,
        handlePreviewRole,
        handleEditRole,
        handleDeleteRole
    }
}

//check if there a seat for admin
//update searchable array after add OK
//show dialog after join

