import {styled} from "@mui/material";

export const Container = styled('div')`
  display: flex;
  padding: 5px 10px;
  //border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  overflow-x: scroll;
  height: 51px;
`;

export const VerDivider = styled('div')`
  width: 1px;
  background-color: #ccc;
  margin: 0 3px;
  flex-shrink: 0;
`;


export const ToolBarContainer = styled('div')`
  background-color: #ffffff;
  height: 51px;
`;

