import React, {FC, useRef} from "react";
import {
    Box,
    Checkbox,
    CircularProgress,
    Collapse,
    Divider,
    FormControl,
    Icon,
    IconButton,
    MenuItem,
    Skeleton,
    TextField,
    Typography
} from "@mui/material";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

import CommonCheckbox from "../../../../../newShared/components/Basic/CommonCheckBox";
import {useNewReportPage} from "./hooks/useNewReportPage";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import colors from "../../../../../newShared/theme/colors";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {TAssetDataAUTOMATION} from "../../../frameworks/types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import {StatementDialog} from "../../../frameworks/components/dialogs/statementDialog";
import {useFrameworksDialog} from "../../../frameworks/hooks/useFrameworksDialog";
import {useControl} from "../../../frameworks/hooks/useControl";
import {usePDFView} from "../../../../../newShared/components/docViewer/hooks/usePDFView";
import {LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {FrameworkCollectorTableDialog} from "../../../frameworks/components/dialogs/collectorTableDialog";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {getEvidenceStatus} from "../../../frameworks/helpers";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";

export const NewReport: FC = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathReports.NewReport'});
    const {t: tEvidence} = useMainTranslation('', {keyPrefix: 'pathFrameworks.pathEvidence'});
    const {isLessThen1050} = useMedia();

    const {
        types, frameworks, controls,
        isLoading, isLoadingFrameworks, isLoadingControls,
        values, touched, errors,
        handleChange, handleSubmit, setFieldTouched,
        checkControl, checkEvidence, checkEvidenceAsset,
        selectedControl, setSelectedControl,
        selectDeselectAll
    } = useNewReportPage();

    const {
        evidences: {isLoadingFiles}, downloadFile, downloadTable,
    } = useControl();
    const {
        collectorView: {setCollectorViewDialog},
        statement: {setStatementDialog},
    } = useFrameworksDialog();
    const {handleOpen} = usePDFView();

    const refTop = useRef<HTMLDivElement>(null);
    const refSmall = useRef<HTMLDivElement>(null);
    const refBig = useRef<HTMLDivElement>(null);
    return (
        <Box width={'100%'} display={"flex"} flexDirection={isLessThen1050 ? "column" : undefined} height={'100%'} overflow={!isLessThen1050 ? 'hidden' : 'auto'}
             p={isLessThen1050 ? !revDir ? '0 15px 0 0' : '0 0 0 15px' : undefined} ref={refSmall}>
            <Box display={"flex"} flexDirection={"column"} minWidth={'400px'} flexBasis={!isLessThen1050 ? '30%' : "100%"} ref={refTop}>
                <PageTitleAndActions
                    title={t('Generate new report')}
                    actions={[]}
                />

                {/*<Breadcrumbs/>*/}

                {/*<Box display={"flex"} alignItems={"center"} mt={'12px'}>*/}
                {/*    <Typography variant={!matches_1440Down ? "h1" : "h3"}>{t('Generate new report')}</Typography>*/}
                {/*</Box>*/}

                <FormControl sx={{width: '100%', maxWidth: '320px'}} component={"form"} onSubmit={handleSubmit}>
                    <TextField variant={"outlined"} label={t('Report name')} disabled={isLoading}
                               onFocus={() => {setFieldTouched('name', false)}}
                               onBlur={() => {setFieldTouched('name')}}
                               error={touched.name && Boolean(errors.name)}
                               id={'name'} name={'name'} value={values.name} onChange={handleChange}
                               sx={{marginTop: '16px'}}
                    />

                    <TextField variant={"outlined"} label={t('Report type')} select disabled={isLoading}
                               onFocus={() => {setFieldTouched('reportType', false)}}
                               onBlur={() => {setFieldTouched('reportType')}}
                               error={touched.reportType && Boolean(errors.reportType)}
                               id={'reportType'} name={'reportType'} value={values.reportType} onChange={handleChange}
                               sx={{marginTop: '16px'}}
                    >
                        {types.map(type => (<MenuItem key={type.value} value={type.value} disabled={type.disable}>{type.label}</MenuItem>))}
                    </TextField>

                    <TextField variant={"outlined"} label={t('Framework')} select disabled={isLoading || isLoadingFrameworks}
                               onFocus={() => {setFieldTouched('frameworkId', false)}}
                               onBlur={() => {setFieldTouched('frameworkId')}}
                               error={touched.frameworkId && Boolean(errors.frameworkId)}
                               id={'frameworkId'} name={'frameworkId'} value={isLoadingFrameworks ? 'loading' : values.frameworkId} onChange={handleChange}
                               sx={{marginTop: '16px'}}
                    >
                        {isLoadingFrameworks && frameworks.length === 0 && <MenuItem value={'loading'}>{'Loading...'}</MenuItem>}
                        {frameworks.map(fr => (<MenuItem key={fr.id} value={fr.id}>{fr.name}</MenuItem>))}
                    </TextField>

                    <TextField variant={"outlined"} label={t('Add auditor')} disabled={isLoading} placeholder={'example@mail.com'} type={"email"}
                               onFocus={() => {setFieldTouched('auditorEmail', false)}}
                               onBlur={() => {setFieldTouched('auditorEmail')}}
                               error={touched.auditorEmail && Boolean(errors.auditorEmail)}
                               id={'auditorEmail'} name={'auditorEmail'} value={values.auditorEmail} onChange={handleChange}
                               sx={{marginTop: '16px'}}
                    />

                    <CommonCheckbox id={'useNcr'} name={'useNcr'} checked={values.useNcr} onChange={handleChange} label={t('Enable NCR')}
                                    formSx={{marginTop: '8px'}}/>

                    <LoadingButton type={"submit"} loading={isLoading}
                                   disabled={Boolean(errors.name || errors.reportType || errors.frameworkId || errors.auditorEmail)}
                                   sx={{marginTop: '16px', width: 'fit-content'}}
                    >
                        {t('Generate new report')}
                    </LoadingButton>
                </FormControl>
            </Box>

            {/*RightSide*/}
            {(values.frameworkId) && (
                <>
                    {!isLessThen1050 && <Divider flexItem orientation={"vertical"} variant={"fullWidth"} sx={{margin: '0 40px', borderColor: colors.backgrounds.grey_dark}}/>}
                    <Box display={"flex"} flexDirection={"column"} overflow={!isLessThen1050 ? 'auto' : undefined} ref={refBig}
                         flexBasis={!isLessThen1050 ? '70%' : "100%"} mt={isLessThen1050 ? '10px' : undefined} p={!isLessThen1050 ? !revDir ? '0 15px 0 0' : '0 0 0 15px' : undefined}>
                        {isLoadingControls ? (
                            <>
                                <Box display={"flex"} alignItems={"center"} p={'10px 0'}>
                                    <Checkbox disabled/>
                                    <Typography variant={"subtitle2"} color={colors.text.dark} flexGrow={1} m={'0 10px'}><Skeleton/></Typography>
                                </Box>
                                <Divider flexItem/>
                                <Box display={"flex"} alignItems={"center"} p={'10px 0'}>
                                    <Checkbox disabled/>
                                    <Typography variant={"subtitle2"} color={colors.text.dark} flexGrow={1} m={'0 10px'}><Skeleton/></Typography>
                                </Box>
                                <Divider flexItem/>
                                <Box display={"flex"} alignItems={"center"} p={'10px 0'}>
                                    <Checkbox disabled/>
                                    <Typography variant={"subtitle2"} color={colors.text.dark} flexGrow={1} m={'0 10px'}><Skeleton/></Typography>
                                </Box>
                                <Divider flexItem/>
                            </>
                        ) :
                            <>
                                <Box display={"flex"} flexDirection={"column"} width={'100%'}>
                                    <Box display={"flex"} alignItems={"center"} p={'5px 0'} sx={{cursor: 'pointer'}}>
                                        <Checkbox checked={selectDeselectAll.isSelectedAll}  size={"medium"}
                                                  onChange={(e, checked) => selectDeselectAll.isSelectedAll ? selectDeselectAll.handleDeselectAll() : selectDeselectAll.handleSelectAll()}
                                        />
                                        <Typography variant={"subtitle2"} color={colors.text.dark} flexGrow={1} m={'0 10px'}>{selectDeselectAll.isSelectedAll ? 'Deselect all' : 'Select all'}</Typography>
                                    </Box>
                                    <Divider flexItem/>
                                </Box>

                                {(controls.map((control, index) => (
                                    <Box display={"flex"} flexDirection={"column"} width={'100%'}>
                                        <Box display={"flex"} alignItems={"center"} p={'5px 0'}
                                             onClick={(e) => {
                                                 setSelectedControl(prev => prev === control.id ? '' : control.id);
                                                 !isLessThen1050 && refBig.current && refBig.current.scrollTo({
                                                     top: 53 * index,
                                                     behavior: "smooth"
                                                 })
                                                 isLessThen1050 && refSmall.current && refTop.current
                                                 && refSmall.current.scrollTo({
                                                     top: refTop.current.clientHeight + 10 + 53 * index,
                                                     behavior: "smooth"
                                                 })
                                             }}
                                             sx={{cursor: 'pointer'}}>
                                            <Checkbox checked={control.active} onClick={e => {
                                                e.stopPropagation()
                                            }} size={"medium"}
                                                      onChange={checkControl(control.id)}/>
                                            <Typography variant={"subtitle2"} color={colors.text.dark} flexGrow={1}
                                                        m={'0 10px'}>{control.name}</Typography>
                                            {control.evidences.length > 0 &&
                                                <Icon>{selectedControl === control.id ? (<ExpandLess/>) : (
                                                    <ExpandMore/>)}</Icon>}
                                        </Box>
                                        <Collapse in={selectedControl === control.id}
                                                  style={{padding: !revDir ? '0 0 0 10px' : '0 10px 0 0'}} mountOnEnter>
                                            {control.evidences.map(evidence => (
                                                <Box display={"flex"} flexDirection={"column"} width={'100%'}
                                                     p={'5px 0'}>
                                                    <Box display={"flex"} alignItems={"center"} gap={'10px'}>
                                                        <Checkbox checked={evidence.active} onClick={e => {
                                                            e.stopPropagation()
                                                        }} size={"small"}
                                                                  onChange={checkEvidence(control.id, evidence.id)}
                                                                  disabled={!control.active}/>
                                                        
                                                        <Typography variant={"body2"} color={getEvidenceStatus(tEvidence, evidence.status).color}>
                                                            {`${getEvidenceStatus(tEvidence, evidence.status).text}`}
                                                        </Typography>

                                                        <Typography variant={"body2"} color={colors.text.dark}
                                                                    flexGrow={1}>{`Evidence: ${evidence.name}`}</Typography>
                                                    </Box>
                                                    <Box display={"flex"} flexDirection={"column"} width={'100%'}
                                                         style={{padding: !revDir ? '0 0 0 15px' : '0 15px 0 0'}}>
                                                        {evidence.assets.map(asset => (
                                                            <Box display={"flex"} flexDirection={"column"}
                                                                 width={'100%'} p={'2px 0'}>
                                                                <Box display={"flex"} alignItems={"center"}>
                                                                    <Checkbox checked={asset.active} onClick={e => {
                                                                        e.stopPropagation()
                                                                    }} size={"small"}
                                                                              onChange={checkEvidenceAsset(control.id, evidence.id, asset.id)}
                                                                              disabled={!control.active || !evidence.active}/>

                                                                    {asset.type === 'FILE' && (
                                                                        <IconButton size={'small'}
                                                                                    sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                                    disabled={isLoadingFiles.includes(asset.data[0]?.fileId)}
                                                                                    onClick={(event) => {
                                                                                        event.stopPropagation();
                                                                                        downloadFile(asset.data[0]?.fileId as string || '', asset.data[0]?.name as string || '')
                                                                                    }}>
                                                                            {isLoadingFiles.includes(asset.data[0]?.fileId)
                                                                                ? <CircularProgress size={24}/>
                                                                                : <DownloadIcon
                                                                                    style={{color: colors.text.blue}}/>}
                                                                        </IconButton>
                                                                    )}
                                                                    {asset.type === 'FILE' && asset.name.endsWith('.pdf') && (
                                                                        <IconButton size={'small'}
                                                                                    sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                                    onClick={(event) => {
                                                                                        event.stopPropagation();
                                                                                        handleOpen({
                                                                                            fileId: asset.data[0]?.fileId as string || '',
                                                                                            fileName: asset.data[0]?.name as string || ''
                                                                                        })
                                                                                    }}>
                                                                            <VisibilityIcon
                                                                                style={{color: colors.text.blue}}/>
                                                                        </IconButton>
                                                                    )}

                                                                    {asset.type === 'COLLECTOR' && (
                                                                        <IconButton size={'small'}
                                                                                    sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                                    disabled={isLoadingFiles.includes(asset.id)}
                                                                                    onClick={(event) => {
                                                                                        event.stopPropagation();
                                                                                        downloadTable(asset.id)
                                                                                    }}>
                                                                            {isLoadingFiles.includes(asset.id)
                                                                                ? <CircularProgress size={24}/>
                                                                                : <DownloadIcon
                                                                                    style={{color: colors.text.blue}}/>}
                                                                        </IconButton>
                                                                    )}

                                                                    {asset.type === 'COLLECTOR' && (
                                                                        <IconButton size={'small'}
                                                                                    sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                                    onClick={() => {
                                                                                        setCollectorViewDialog({
                                                                                            assetId: asset.id,
                                                                                            isOpen: true
                                                                                        })
                                                                                    }}>
                                                                            <ArticleOutlinedIcon
                                                                                style={{color: colors.text.blue}}/>
                                                                        </IconButton>
                                                                    )}

                                                                    {asset.type === 'CUSTOM' && (
                                                                        <IconButton size={'small'}
                                                                                    sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                                    onClick={(event) => {
                                                                                        event.stopPropagation();
                                                                                        setStatementDialog({
                                                                                            evidenceId: evidence.id,
                                                                                            isOpen: true,
                                                                                            assetId: asset.id
                                                                                        })
                                                                                    }}>
                                                                            <VisibilityIcon
                                                                                style={{color: colors.text.blue}}/>
                                                                        </IconButton>
                                                                    )}

                                                                    <Typography variant={"body2"}
                                                                                color={colors.text.dark} flexGrow={1}
                                                                                style={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}>{asset.name}</Typography>
                                                                    <Typography variant={"body2"}
                                                                                color={colors.text.dark}
                                                                                whiteSpace={"nowrap"}>{parseDateAuto(asset.lastUpdate)}</Typography>
                                                                </Box>

                                                                {asset.type === 'AUTOMATION' && (
                                                                    <Box display={"flex"} flexDirection={"column"}
                                                                         width={'100%'}
                                                                         p={!revDir ? '0 0 0 20px' : '0 20px 0 0'}>
                                                                        {asset.data && (asset.data as TAssetDataAUTOMATION).map(d => (
                                                                            Object.values(d).map(file => file.map(file => (
                                                                                <Box display={"flex"}
                                                                                     alignItems={"center"}>
                                                                                    <IconButton size={'small'}
                                                                                                sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                                                disabled={isLoadingFiles.includes(file.fileId)}
                                                                                                onClick={(event) => {
                                                                                                    event.stopPropagation();
                                                                                                    downloadFile(file.fileId as string || '', file.name as string || '')
                                                                                                }}>
                                                                                        {isLoadingFiles.includes(file.fileId)
                                                                                            ? <CircularProgress
                                                                                                size={24}/>
                                                                                            : <DownloadIcon
                                                                                                style={{color: colors.text.blue}}/>}
                                                                                    </IconButton>

                                                                                    {file.name.endsWith('.pdf') && (
                                                                                        <IconButton size={'small'}
                                                                                                    sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                                                    onClick={(event) => {
                                                                                                        event.stopPropagation();
                                                                                                        handleOpen({
                                                                                                            fileId: file.fileId as string || '',
                                                                                                            fileName: file.name as string || ''
                                                                                                        })
                                                                                                    }}>
                                                                                            <VisibilityIcon
                                                                                                style={{color: colors.text.blue}}/>
                                                                                        </IconButton>
                                                                                    )}

                                                                                    <Typography variant={"body2"}
                                                                                                color={colors.text.dark}
                                                                                                flexGrow={1}
                                                                                                m={'0 10px'}>{file.name || 'Not found name'}</Typography>
                                                                                </Box>
                                                                            )))
                                                                        ))}
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Collapse>
                                        <Divider flexItem/>
                                    </Box>
                                )))}
                            </>
                        }
                    </Box>
                </>
            )}

            <StatementDialog/>
            <FrameworkCollectorTableDialog/>
        </Box>
    )
}