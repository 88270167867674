import {CreateTemplateSubContainerPropsType} from "../../types";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import * as S from "../styled/index";
import React from "react";

export const CreateTemplateSubContainer = ({title, description, children, disableDivider = false}: CreateTemplateSubContainerPropsType) => {
    const {isMobile} = useMedia();
    return(
        <>
            <S.CreateTemplateSubContainer isMobile={isMobile}>
                <S.CreateTemplateSubLeftContainer isMobile={isMobile}>
                    <S.SubLeftContainerTitleText>{title}</S.SubLeftContainerTitleText>
                    <S.SubLeftContainerDescText>{description}</S.SubLeftContainerDescText>
                </S.CreateTemplateSubLeftContainer>

                <S.CreateTemplateSubRightContainer isMobile={isMobile}>
                    {children}
                </S.CreateTemplateSubRightContainer>
            </S.CreateTemplateSubContainer>
            {!disableDivider && <S.ContainerDivider/>}
        </>
    )
}