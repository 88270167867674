export {};

export const PATH_FRAGMENT_EVIDENCES = 'evidences';
export const PATH_FRAGMENT_COMMENTS = 'comments';
export const PATH_FRAGMENT_DELEGATED_TASKS = 'delegated-tasks';
export const PATH_FRAGMENT_ANALYSIS = 'analysis';

export const OPEN = 'OPEN';
export const IN_PROGRESS = 'IN_PROGRESS';
export const READY_TO_SUBMIT = 'READY_TO_SUBMIT';

export const EVIDENCE_STATUS = [OPEN, IN_PROGRESS, READY_TO_SUBMIT];

