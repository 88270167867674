import {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {TEditorActor} from "../../../../../../newShared/components/editorUnion/types";
import {useAppDispatch} from "../../../../../../newShared/redux";
import {TIdName} from "../../../../../../newShared/types";
import {getMsFromDays} from "../../../../../../newShared/utils/dateTools";
import {isOkEmail} from "../../../helpers";
import {
    dialogs,
    folders,
    hideActionDialog,
    hideAddGroupMessage,
    loadings,
    openAddGroupMessage,
    selectedNewTemplateSelector,
    setFormCreateDoc
} from "../../../store/slice";
import {TCreateFormDoc} from "../../../types";
import {CreateNewDoc, GetFolderTree, GetNewTemplateById} from "../../../../documentsRefactor/store/actions";


export const useTaskActionTemplateNewDialog = () => {
    const dispatch = useAppDispatch();
    const {
        actionTemplateDialog: {task, action, isOpen, form},
        addGroupMessage: addGroupMessageDialog,
    } = useSelector(dialogs);
    const selectedNewTemplate = useSelector(selectedNewTemplateSelector);
    const rootFolders = useSelector(folders);
    const _loadings = useSelector(loadings);

    // const [form, setForm] = useState<TCreateFormDoc>(defaultFormCreateDoc);
    const [isApproversBlock, setIsApproversBlock] = useState<boolean>(false);
    const [isRecipientsCustomOrder, setIsRecipientsCustomOrder] = useState<boolean>(false);
    const [path, setPath] = useState<TIdName[]>([]);
    const [selectedFolder, setSelectedFolder] = useState<TIdName | null>(null);

    // const [isDrag, setDrag] = useState<boolean>(false);
    const isAbleToDrop = isOpen;

    //recipients
    const [touchedField, setTouchedField] = useState<string | null>(null);
    const [errorActiveFields, setErrorActiveFields] = useState<string[]>([]);

    const handleIsOkRecipientEmail = ({id, email}:{id: string, email: string}): boolean => {
        return !!email?.length && isOkEmail(email) && !errorActiveFields.some(e => e === id);
    }

    const handleClose = () => {
        dispatch(hideActionDialog(['actionTemplateDialog']));
    }

    const handleSetForm = (form: TCreateFormDoc) => {
        dispatch(setFormCreateDoc({form}))
    }

    const handleSetDocName = (name: string) => {
        handleSetForm({
            ...form,
            name
        });
    }

    const handleSetFolder = (folder: TIdName) => {
        handleSetForm({
            ...form,
            folderId: folder.id
        });
        setSelectedFolder(folder);
        setPath([...path, folder]);
    }

    const handleAddRecipient = () => {
        handleSetForm({
            ...form,
            recipients: [
                ...form.recipients,
                {   id: uuid(),
                    fields: null,
                    actor: { email: '', lastName: '', firstName: '' },
                    order: isRecipientsCustomOrder ? form.recipients.length + 1 : 0, message: '',
                    eta: getMsFromDays(7),
                }
            ],
        });

    }

    const handleOpenAddMessage = (data: {approverId?: string, groupId?: string, oldMessage?: string, recipientId?: string}) => {
        dispatch(openAddGroupMessage(data))
    };

    const handleHideAddGroupMessage = () => {
        dispatch(hideAddGroupMessage());
    };

    const handleSetGroupMessage = useCallback((data: {approverId?: string, groupId?: string, recipientId?: string, message: string,}) => {

        if ( data.approverId && data.groupId) {
            handleSetForm({
                ...form,
                approvers: form.approvers.map(appr => appr.id === data.approverId ? ({
                    ...appr,
                    groups: appr.groups.map(gr => gr.id === data.groupId ? ({
                        ...gr,
                        message: data.message,
                    }) : gr)
                }) : appr)
            });

        } else if ( data.recipientId) {
            handleSetForm({
                ...form,
                recipients: form.recipients.map((r) => r.id === data.recipientId ? ({
                    ...r,
                    message: data.message,
                }) : r)
            });

        }
        handleHideAddGroupMessage();
        //eslint-disable-next-line
    }, [form]);

    const handleToggleRecipientOrder = useCallback((isOrdered: boolean) => {
        handleSetForm({
            ...form,
            recipients: form.recipients.map((r, index) => ({
                ...r,
                order: isOrdered ? index + 1 : 0,
            })),
        });

        setIsRecipientsCustomOrder(isOrdered);

        //eslint-disable-next-line
    }, [form]);

    const handleDeleteRecipient = useCallback((recipientId: string) => {
        handleSetForm({
            ...form,
            recipients: form.recipients.filter((r ) => r.id !== recipientId),
        });
        //eslint-disable-next-line
    }, [form]);

    const handleSetActorRecipient = useCallback((recipientId: string, actor: TEditorActor) => {
        handleSetForm({
            ...form,
            recipients: form.recipients.map((r)=> r.id === recipientId ? ({ ...r, actor}) : r),
        });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    const handleSave = () => {
        const isOrderedApprovers = form.approvers.some(appr => appr.order > 0);
        const isOrderedRecipients = form.recipients.some(rec => rec.order > 0);

        dispatch(CreateNewDoc({newValue: {
                ...form,
                name: form.name.length ? form.name : 'New Document',
                approvers: form.approvers
                    .filter(appr => appr.groups.some(gr => gr.actors.some(a => a.email.length)))
                    .map((appr, index) => ({
                        order: isOrderedApprovers ? index + 1 : 0,
                        eta: appr.eta ?? getMsFromDays(7),
                        groups: appr.groups
                            .map(gr => ({
                                actors: gr.actors,
                                name: gr.name,
                                isRequired: gr.isRequired,
                                message: gr.message,
                            }))
                    })),
                recipients: form.recipients
                    .filter(r => r.actor?.email?.length || r.role?.trim().length || r.fields?.length)
                    .map((r, index) => ({
                        fields: r.fields,
                        actor: handleIsOkRecipientEmail({id: r.id, email: r.actor.email})
                            ? {email: r.actor.email, firstName: r.actor.firstName, lastName: r.actor.lastName}
                            : {email: '', firstName: '', lastName: ''},
                        order: isOrderedRecipients ? index + 1 : 0,
                        message: r.message,
                        role: r.role || '',
                        eta: r.eta ?? getMsFromDays(7),
                    })),
                data: {
                    innerHtml: selectedNewTemplate?.data?.innerHtml,
                    variables: selectedNewTemplate?.data?.variables,
                    editor: selectedNewTemplate?.data?.editor,
                }
            }, workspaceId: ''}))
    }

    // useEffect(() => {
    //     console.log('form', form);
    // }, [form])


    useEffect(() => {
        if (isOpen) {

            action?.data && dispatch(GetNewTemplateById({fileId: action?.data, workspaceId: '' }));
            dispatch(GetFolderTree());

            // handleSetForm(defaultFormCreateDoc);
            setIsApproversBlock(false);
            setIsRecipientsCustomOrder(true);
            setTouchedField(null);
            setErrorActiveFields([]);
            setPath([]);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    // useEffect(() => {
    //     if (selectedNewTemplate) {
    //         handleSetForm({...form, name: selectedNewTemplate.name})
    //         handleSetDocRecipients(selectedNewTemplate?.recipients || []);
    //     }
    //
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedNewTemplate]);

    useEffect(() => {

        if (rootFolders) {
            let tmpDocumentsFolder = rootFolders.find(p => p.name === 'Documents') ?? null;
            let tmpVicaFolder = rootFolders.find(p => p.name === 'Documents')?.children.find(e => e.name.toLowerCase() === 'vica') ?? null;
            let tmpPath = tmpVicaFolder ? [{id: tmpDocumentsFolder?.id ?? '', name: tmpDocumentsFolder?.name ?? ''}, {id: tmpVicaFolder.id, name: tmpVicaFolder.name}] : [{id: tmpDocumentsFolder?.id ?? '', name: tmpDocumentsFolder?.name ?? ''}];

            setPath(tmpPath)
            if (tmpVicaFolder) {
                setSelectedFolder(tmpVicaFolder);
                handleSetForm({...form, folderId: tmpVicaFolder?.id ?? ''})
            }
            else {
                setSelectedFolder(tmpDocumentsFolder);
                handleSetForm({...form, folderId: tmpDocumentsFolder?.id ?? ''})
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ rootFolders]);



    return {
        isOpen,
        handleClose,
        task,
        action,
        rootFolders,
        addGroupMessageDialog,
        loadings: _loadings,
        path,
        form,
        setForm: handleSetForm,
        isApproversBlock,
        setIsApproversBlock,
        isRecipientsCustomOrder,
        setIsRecipientsCustomOrder,
        selectedFolder,
        isAbleToDrop,
        touchedField,
        setTouchedField,
        errorActiveFields,
        setErrorActiveFields,

        handleIsOkRecipientEmail,
        handleSetDocName,
        handleSetFolder,
        handleAddRecipient,
        handleOpenAddMessage,
        handleHideAddGroupMessage,
        handleSetGroupMessage,
        handleToggleRecipientOrder,
        handleDeleteRecipient,
        handleSetActorRecipient,
        handleSave,

    }

}
