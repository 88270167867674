import React, {ChangeEvent} from "react";
import {Flex} from "../../../../../newShared/components/Layouts"
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {usePhysicalAssetExact} from "../../hooks/usePhysicalAssetExact";
import {
    Box,
    Button,
    MenuItem,
    Skeleton,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField
} from "@mui/material";
import {Edit} from "@mui/icons-material";
import {TabContext, TabPanel} from "@mui/lab";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {getStatusColor} from "../../helpers";
import {TCreateField, TExactAssetTabs} from "../../types";
import {DeleteAssetDialog} from "../../components/Dialogs/DeleteAssetDialog";
import {ChangeStatusDialog} from "../../components/Dialogs/ChangeStatusDialog";
import {CreateSettingRowDialog} from "../../components/Dialogs/CreateSettingRowDialog";
import {AddMenuItem} from "../../../../../newShared/components/Basic/AddMenuItem";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import * as S from "../../components/styled";


export const PhysicalAssetExact = () => {
    const {isMobile, isTablet, isLessThen1050} = useMedia();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});

    const {
        isOkId,
        asset,
        settings,
        tab,
        setTab,
        handleGoBack,
        editInfoMode,
        tmpAsset,
        setTmpAsset,
        isLoadingExact,
        isLoadingSettings,
        handleSetModel,
        handleSetCategory,
        handleSetManufacture,

        handleSaveInfo,
        handleCancelEditInfo,
        handleEditInfoBtn,
        setCreateSettingRowDialog,
        onSuccessCreateSetting,

        deleteAssetDialog: {setDeleteAssetDialog, responseDelete},
        changeStatusDialog: {setChangeStatusDialog, isOpenChangeStatusDialog},

    } = usePhysicalAssetExact();



    return (
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={asset?.assetId}
                handleGoBack={handleGoBack}
                showMenuWhen={isLessThen1050}
                actions={[
                    {
                        title: t('Change status'),
                        onClick:() => {setChangeStatusDialog({isOpen: true})},
                        variant: 'text',
                        icon: <PublishedWithChangesIcon style={{marginLeft: revDir ? '8px' : undefined}}/>,
                        disabled: !asset || isLoadingExact
                    },
                    {
                        title: t('Delete asset'),
                        onClick: () => {setDeleteAssetDialog({isOpen: true, assetType: 'physical'})},
                        variant: 'text',
                        icon: <DeleteForeverIcon/>,
                        disabled: !asset || isLoadingExact
                    },
                ]}
            />


            {/*TABS*/}
            <TabContext value={tab}>
                <Box sx={{ margin: '30px 0 0 0' }}>
                    <Tabs variant={'scrollable'} value={tab}
                          aria-label="basic tabs example"
                          onChange={(e, newValue: TExactAssetTabs) => setTab(newValue)} >
                        <Tab disabled={isLoadingExact} label={t("Information")} value={'information'} sx={{paddingLeft: '0'}}/>
                        <Tab disabled={isLoadingExact} label={t("History")} value={'history'} />
                        <Tab disabled={true} label={t("Services detected")} value={'servicesDetected'} />
                    </Tabs>
                </Box>

                {/*INFO*/}
                <TabPanel value={'information'} sx={{padding: '16px 0', overflowY: 'auto'}}>
                    { editInfoMode ?
                        <Flex w={'100%'} direction={'column'} ai={'flex-start'}  overflow={'hidden'} >
                            <Flex ai={'center'} p={'6px 0'}  w={'100%'} >
                                <Button size={'large'}
                                        disabled={!isOkId() || tmpAsset?.assetId?.trim() === '' || tmpAsset?.assetId?.trim() === '' || isLoadingExact}
                                        onClick={handleSaveInfo} >
                                    {t('Save')}
                                </Button>
                                <Button variant={'outlined'} size={'large'}
                                        disabled={isLoadingExact}
                                        sx={{marginLeft: revDir ? undefined : '8px', marginRight: revDir ? '8px' : undefined}}
                                        onClick={handleCancelEditInfo} >{t('Cancel')}</Button>
                            </Flex>

                            <Flex direction={'column'} w={'100%'} ai={'flex-start'} m={'10px 0 0 0'}  >
                                <TextField label={t('ID')} value={tmpAsset?.assetId || ''} variant={"outlined"}
                                           required
                                           size={'small'}
                                           error={!isOkId()}
                                           disabled={isLoadingExact}
                                           helperText={!isOkId() ? 'cannot contain special characters or spaces' : undefined}
                                           sx={{marginTop: '6px', width: '320px', '& label': {top: '0 !important'}}}
                                           onChange={e => {setTmpAsset(prev => prev ? ({...prev, assetId: e.target.value}) : null)}}/>

                                <TextField label={t('Serial')} value={tmpAsset?.serial || ''} variant={"outlined"} size={'small'}
                                           disabled={isLoadingExact}
                                           required
                                           sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                           onChange={e => {setTmpAsset(prev => prev ? ({...prev, serial: e.target.value}) : null)}}/>


                                <TextField label={t('Model')} select size={'small'}
                                           required
                                           sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                           value={tmpAsset?.model}
                                           disabled={isLoadingExact}
                                           onChange={(e) => handleSetModel(e.target.value)} >
                                    <AddMenuItem text={t('Add new')}
                                                 onClick={() => {setCreateSettingRowDialog({isOpen: true, field: 'model', assetType: 'physical'})}}/>
                                    {settings?.model.map(({name}) => (
                                        <MenuItem key={name} value={name}>{name}</MenuItem>
                                    ))}

                                </TextField>

                                <TextField label={t('Category')} select size={'small'}
                                           sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                           required
                                           value={tmpAsset?.category}
                                           disabled={isLoadingExact}
                                           onChange={(e) => handleSetCategory(e.target.value)} >
                                    <AddMenuItem text={t('Add new')} onClick={() => {setCreateSettingRowDialog({isOpen: true, field: 'category', assetType: 'physical'})}}/>
                                    {settings?.category.map(({name}) => (<MenuItem key={name} value={name}>{name}</MenuItem>))}

                                </TextField>

                                <TextField label={t('Manufacturer')} select size={'small'}
                                           sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                           value={tmpAsset?.manufacture}
                                           required
                                           disabled={isLoadingExact}
                                           onChange={(e) => handleSetManufacture(e.target.value)} >
                                    <AddMenuItem text={t('Add new')} onClick={() => {setCreateSettingRowDialog({isOpen: true, field: 'manufacture', assetType: 'physical'})}}/>
                                    {settings?.manufacture.map(({name}) => (<MenuItem key={name} value={name}>{name}</MenuItem>))}

                                </TextField>

                                { settings?.customFields.map( (name) =>   (
                                        <TextField key={name} label={name} value={tmpAsset?.customFields[name] || ''}
                                                   variant={"outlined"} size={'small'}
                                                   sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                                   disabled={isLoadingExact}
                                                   onChange={ (e: ChangeEvent<HTMLInputElement>) => {
                                                       setTmpAsset(prev => prev ? ({
                                                           ...prev,
                                                           customFields: {
                                                               ...prev.customFields,
                                                               [name]: e.target.value,
                                                           }
                                                       }) : null)
                                                   }}/>
                                    )
                                )}
                            </Flex>
                        </Flex>
                        :
                        <Flex w={'100%'} direction={'column'} ai={'flex-start'} style={{opacity: responseDelete ? 0 : 'undefined'}}>
                            <Button variant={'text'} startIcon={<Edit style={{margin: revDir ? '0 0 0 9px' : undefined}}/>}
                                    sx={{marginTop: '10px', paddingLeft: !revDir ? '3px' : undefined, paddingRight: revDir ? '3px' : undefined}}
                                    disabled={isLoadingExact || isLoadingSettings}
                                    onClick={handleEditInfoBtn}>
                                {t('Edit information')}
                            </Button>

                            {isLoadingExact ? <Skeleton width={'250px'} sx={{mt: '16px'}}/> : <S.FieldTitleText> {`${t('ID')}: `}<S.FieldValueText>{asset?.assetId}</S.FieldValueText></S.FieldTitleText>}
                            {isLoadingExact ? <Skeleton width={'250px'} sx={{mt: '16px'}}/> : <S.FieldTitleText> {`${t('Serial')}: `}<S.FieldValueText>{asset?.serial}</S.FieldValueText></S.FieldTitleText>}
                            {isLoadingExact ? <Skeleton width={'250px'} sx={{mt: '16px'}}/> : <S.FieldTitleText> {`${t('Model')}: `}<S.FieldValueText>{asset?.model}</S.FieldValueText></S.FieldTitleText>}
                            {isLoadingExact ? <Skeleton width={'250px'} sx={{mt: '16px'}}/> : <S.FieldTitleText> {`${t('Category')}: `}<S.FieldValueText>{asset?.category}</S.FieldValueText></S.FieldTitleText>}
                            {isLoadingExact ? <Skeleton width={'250px'} sx={{mt: '16px'}}/> : <S.FieldTitleText> {`${t('Manufacturer')}: `}<S.FieldValueText>{asset?.manufacture}</S.FieldValueText></S.FieldTitleText>}
                            {isLoadingExact ? <Skeleton width={'250px'} sx={{mt: '16px'}}/> : <S.FieldTitleText> {`${t('Asset status')}: `}<S.FieldValueText textColor={asset ? getStatusColor(asset?.status.status) : undefined}>{asset?.status?.name}</S.FieldValueText></S.FieldTitleText>}

                            {/*//asset && !isLoadingExact && Object.keys(asset?.customFields)*/}
                            {settings && !isLoadingExact && settings?.customFields.map((key) => (
                                asset?.customFields[key] && <S.FieldTitleText key={key}> {`${key}: `}<S.FieldValueText>{asset?.customFields[key]}</S.FieldValueText></S.FieldTitleText>
                            )) }
                        </Flex>
                    }
                </TabPanel>

                {/*HISTORY*/}
                <TabPanel value={'history'} sx={{p: '16px 0 0 0', overflowY: 'auto'}}>
                    {asset &&
                        <TableContainer sx={{mt: '10px'}}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow >
                                        <TableCell sx={{ width: isMobile ? '100px' : isTablet ? '140px' : '320px'}} align={revDir ? 'right' : 'left'}>{t('Date')}</TableCell>
                                        {!isMobile && <TableCell sx={{ width: isTablet ? '150px' : '200px'}} align={revDir ? 'right' : 'left'}>{t('Status')}</TableCell>}
                                        <TableCell sx={{ width: isMobile ? '100px' : isTablet ? '200px' : '300px'}} align={revDir ? 'right' : 'left'}>{t('Action')}</TableCell>
                                        <TableCell align={revDir ? 'right' : 'left'}>{t('Action actor')}</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    { asset.statuses
                                        .slice()
                                        .sort((a,b) => a.date < b.date ? 1 : -1)
                                        .map(({date, status, action, actor}, index) => (
                                            <TableRow key={date + index + asset.id } >
                                                <TableCell align={revDir ? 'right' : 'left'} >{parseDateAuto(date)}</TableCell>
                                                {!isMobile && <TableCell sx={{color: getStatusColor(status.status)}} align={revDir ? 'right' : 'left'}>{status.name}</TableCell>}
                                                <TableCell align={revDir ? 'right' : 'left'}>{`${action?.name ?? ''} ${action?.employee?.name ?? ''}`}</TableCell>
                                                <TableCell align={revDir ? 'right' : 'left'}>{actor?.name ?? ''}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </TabPanel>

                {/*SERVICES DETECTED*/}
                <TabPanel value={'servicesDetected'} sx={{p: '16px 0 0 0', overflowY: 'auto'}}>
                    {asset &&
                        <TableContainer sx={{mt: '10px', overflowY: 'auto'}}>
                            <Table stickyHeader >
                                <TableHead >
                                    <TableRow>
                                        <TableCell sx={{ width: (isMobile || isTablet) ? '140px' : '320px'}}>{t('Application')}</TableCell>
                                        <TableCell >{t('Email')}</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    { asset.statuses
                                        .slice()
                                        .sort((a,b) => a.date < b.date ? 1 : -1)
                                        .map(({date, status, action, actor}, index) => (
                                            <TableRow key={'service' + index + asset.id } >
                                                <TableCell>{'GoogleDrive'}</TableCell>
                                                <TableCell>{'gibson@gmail.com'}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                    { asset.statuses
                                        .slice()
                                        .sort((a,b) => a.date < b.date ? 1 : -1)
                                        .map(({date, status, action, actor}, index) => (
                                            <TableRow key={'service' + index + asset.id }>
                                                <TableCell>{'GoogleDrive'}</TableCell>
                                                <TableCell>{'gibson@gmail.com'}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </TabPanel>
            </TabContext>

            <ChangeStatusDialog />
            <DeleteAssetDialog />
            {!isOpenChangeStatusDialog && <CreateSettingRowDialog onSuccess={(title: string, field?: TCreateField, ) => onSuccessCreateSetting(title, field)}/>}
        </Flex>
    )
}
