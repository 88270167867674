export const ROOT_BUILDER = '/builder';
export const ROOT_FRAMEWORKS = `${ROOT_BUILDER}/frameworks`;
export const ROOT_CONTROLS = `${ROOT_BUILDER}/controls`;
export const ROOT_EVIDENCES = `${ROOT_BUILDER}/evidences`;



export const BUILDER_WITH_FRAME = `${ROOT_FRAMEWORKS}/:frameworkId`;
export const BUILDER_WITH_FRAME_AND_CONTROL = `${ROOT_FRAMEWORKS}/:frameworkId/control/:controlId`;
export const CONTROLS = `${ROOT_CONTROLS}/:controlId`;
export const EVIDENCES = `${ROOT_EVIDENCES}/:evidenceId`;

export const FRAMEWORK_VISIBILITY = ['PRIVATE', 'PUBLIC', 'RESTRICTED'];

export const LOGO_MAX_SIZE = 1048576;

