import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_VULNERABILITY, PATH_LOCAL_VULNERABILITY_SCANNED} from "../../../../../newShared/constants";
import {useDispatch, useSelector} from "react-redux";
import {
    cleanTargets,
    loadings,
    pageInfo,
    replaceTargetsMinMaxLoadedPage,
    selectTarget,
    smallTargets,
    targetsMinMaxLoadedPage
} from "../../store/slice";
import {GetShortTargets} from "../../store/actions";
import {useHistory} from "react-router-dom";
import {PATH_LOCAL_VULNERABILITY_NEW} from "../../constants";
import {smallTarget} from "../../types";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";

export const useTargetsMain = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const _pageInfo = useSelector(pageInfo);
    const _isLoadingData = useSelector(loadings).isLoadingSmallTargets;
    const _targets = useSelector(smallTargets);

    // const controller = new AbortController();

    useSetBreadcrumbs([
        {
            title: tMenu('Vulnerability Scanner'),
            path: PATH_LOCAL_VULNERABILITY_SCANNED
        },
        {
            title: tMenu('Scanned items'),
            path: PATH_LOCAL_VULNERABILITY
        }
    ]);

    // const [filter, setFilter] = useState<string>('All');
    // const [search, setSearch] = useState<string>('');
    // const [lastSearch, setLastSearch] = useState<string | null>(null);

    const values = ['Failed', 'Completed', 'In Progress'];

    // const getData = (status: string, name: string, page: number, count: number) => {
    //     if(status === 'All'){
    //         if(name.trim().length > 0){
    //             dispatch(GetShortTargets({
    //                 data: {
    //                     page,
    //                     count,
    //                     filters: {status: [], name: [name]}
    //                 },
    //                 signal: controller.signal,
    //             }));
    //             setLastSearch(name);
    //             return;
    //         }else{
    //             dispatch(GetShortTargets({
    //                 data: {
    //                     page,
    //                     count,
    //                     filters: {status: [], name: []}
    //                 },
    //                 signal: controller.signal,
    //             }));
    //             setLastSearch('');
    //             return;
    //         }
    //     }else{
    //         if(name.trim().length > 0){
    //             dispatch(GetShortTargets({
    //                 data: {
    //                     page,
    //                     count,
    //                     filters: {status: [status], name: [name]}
    //                 },
    //                 signal: controller.signal,
    //             }));
    //             setLastSearch(name);
    //             return;
    //         }else{
    //             dispatch(GetShortTargets({
    //                 data: {
    //                     page,
    //                     count,
    //                     filters: {status: [status], name: []}
    //                 },
    //                 signal: controller.signal,
    //             }));
    //             setLastSearch('');
    //             return;
    //         }
    //     }
    // }

    // useEffect(() => {
    //     !_isLoadingData && getData('All', '', 0, DEFAULT_COUNT);
    //
    //     return () => {
    //         controller.abort();
    //     }
    //     //eslint-disable-next-line
    // }, []);

    // const handleCleanSearch = () => {
    //     setSearch('');
    //     setLastSearch('');
    //     getData(filter, '', 0, DEFAULT_COUNT);
    // }
    //
    // const handleEnterKey = (e: any) => {
    //     if(e.keyCode === 13 && search.trim().length > 0){
    //         getData(filter, search, 0, DEFAULT_COUNT);
    //     }
    // }
    //
    // const handleStatusFilterChange = (newStatus: string) => {
    //     setFilter(newStatus);
    //     getData(newStatus, search, 0, DEFAULT_COUNT);
    // }

    // const handleLoadMore = (page: number) => {
    //     // console.log(`LAZY ${page} vs ${_pageInfo.page + 1} AND LOAD : ${_isLoadingData}`);
    //     !_isLoadingData && getData(filter, search, _pageInfo.page + 1, DEFAULT_COUNT);
    // }

    const handleGoToAdd = () => history.push(PATH_LOCAL_VULNERABILITY_NEW);

    const goToId = (target: smallTarget) => {
        dispatch(selectTarget(target));
        history.push(`${PATH_LOCAL_VULNERABILITY_SCANNED}/${target.id}`);
    }

    const {minLoadedPage, maxLoadedPage} = useSelector(targetsMinMaxLoadedPage);

    const statusConfig: keyConfig<string> = {
        type: 'filter',
        key: 'status',
        name: ('Status'),
        options: values,
        default: true
    }

    return{
        targets: _targets,
        isLoadingTargets: _isLoadingData,
        handleGoToAdd,
        goToId,
        genericTable: {
            paging: {
                pageInfo: _pageInfo,
                isLoading: _isLoadingData,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceTargetsMinMaxLoadedPage(data)),
                clearRows: () => dispatch(cleanTargets()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    dispatch(GetShortTargets({
                        data: {
                            page,
                            count,
                            filters
                        },
                    }));
                },
            },
            filters: {
                configs: [statusConfig]
            }
        }
    }
}