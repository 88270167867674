import {
    automationHistoryHotReload,
    automationStage,
    automationType,
    FlowHistory,
    flowsInitialState,
    shortAutomation
} from "../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {
    ActivateAutomation,
    AddAutomation,
    DeactivateAutomation,
    DeleteAllFile,
    DeleteAutomation,
    GetAutomationById,
    GetAutomationEvidences,
    GetAutomationHistory,
    GetEmployeesNameEmail,
    GetPolicies,
    GetShortAutomations,
    RunAutomation,
    StopAutomation,
    UpdateAutomation,
    UploadFile
} from "./actions";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {getIsoDateTimeNow} from "../helpers";
import {minMaxLoadedPageType} from "../../../../newShared/components/genericTable/types";
import {handlePagination} from "../../../../newShared/components/genericTable/helpers";

const initialState: flowsInitialState = {
    automations: [],
    minMaxLoadedPage: {minLoadedPage: -1, maxLoadedPage: -1},
    policies: [],
    isLoading: false,
    pageInfo: {
        page: 0,
        count: 10,
        total: 0,
        sort: ''
    },

    isLoadingEvidences: false,
    frameworks: [],
    controls: [],
    evidences: [],

    addedFilesArray: [],
    deletedFielsArray: [],
    preferToOpen: {
        pipeline: true,
        history: true,
    },
    isLoadingPolicies: false,
    selectedAutomation: null,
    prevSelected: null,
    trigger: {
        isOpen: false,
    },
    triggerDate: {
        isOpen: false,
    },
    triggerRepeat: {
        isOpen: false,
    },
    doc: {
        isOpen: false,
    },
    docPolicy: {
        isOpen: false,
    },
    docLink: {
        isOpen: false,
    },
    docFile: {
        isOpen: false,
    },
    stageSettings: {
        isOpen: false,
        stageId: -1,
    },
    stageActor: {
        isOpen: false,
        stageId: -1,
    },
    addEvidence: {
        isOpen: false,
    },
    changeName: {
        isOpen: false,
    },
    deletePipeline: {
        isOpen: false,
        pipelineId: null,
    },
    saveChanges: {
        isOpen: false,
        transiteTo: '',
        isAdd: false,
    },
    addPipeline: {
        isOpen: false,
    },
    loadings: {
        runAutomation: {
            isLoading: false,
            autoId: null,
        },
        deleteAutomation: false,
        getList: false,
        getExact: false,
        toggleActivity: false,
        getHistory: false,
        stopAutomation: false,
        createAutomation: false,
        getEmployees: false,
    },
    employees: []
}

const FlowsSlice = createSlice({
    name: 'Flows_bish',
    initialState,
    reducers: {
        replaceMinMaxLoadedPage: (state, {payload}: {payload: minMaxLoadedPageType}) => {
            state.minMaxLoadedPage = payload;
        },
        updateAutomation: (state, {payload}) => {
            state.automations = state.automations.map(aut => aut.id === payload.id ? payload: aut);
        },
        selectAutomation: (state, {payload}) => {
            // state.selectedAutomation = state.automations.find(e => e.id === payload) || null;
            state.saveChanges = {isOpen: false, transiteTo: '', isAdd: false};
            state.saveChanges.isOpen = false;
        },
        //dialogs actions
        //TRIGGER
        openTrigger: (state) => {
            state.trigger.isOpen = true
        },
        hideTrigger: (state) => {
            state.trigger.isOpen = false;
        },
        setTriggerType: (state, {payload}) => {
            if(state.selectedAutomation){
                state.selectedAutomation.trigger.type = payload;
                state.trigger = {isOpen: false};
            }
        },
        //TRIGGER DATE
        openTriggerDate: (state) => {state.triggerDate.isOpen = true},
        hideTriggerDate: (state) => {state.triggerDate.isOpen = false},
        setTriggerDate: (state, {payload}) => {
            if(state.selectedAutomation){
                state.selectedAutomation.trigger.start = payload;
                state.triggerDate = {isOpen: false};
            }
        },
        //TRIGGER REPEAT
        openTriggerRepeat: (state) => {state.triggerRepeat.isOpen = true},
        hideTriggerRepeat: (state) => {state.triggerRepeat.isOpen = false},
        setTriggerRepeat: (state, {payload}) => {
            if(state.selectedAutomation){
                state.selectedAutomation.trigger.repeat = payload;
                state.triggerRepeat = {isOpen: false};
            }
        },
        //DOC
        openDoc: (state) => {state.doc.isOpen = true},
        hideDoc: (state) => {state.doc.isOpen = false},
        setDocType: (state, {payload}) => {
            if(state.selectedAutomation){
                if(state.selectedAutomation.documents.type === 'file' && state.selectedAutomation.documents.links.length > 0){
                    state.deletedFielsArray.concat(state.selectedAutomation.documents.links.map(e => e.link));
                }
                state.selectedAutomation.documents.type = payload;
                state.selectedAutomation.documents.links = [];
                state.doc = {isOpen: false};
            }
        },
        //DOC POLICY
        openDocPolicy: (state) => {state.docPolicy.isOpen = true},
        hideDocPolicy: (state) => {state.docPolicy.isOpen = false},
        setDocument: (state, {payload}) => {
            if(state.selectedAutomation){
                state.selectedAutomation.documents.links = [...state.selectedAutomation.documents.links, payload];
                if(state.selectedAutomation.documents.type === 'file'){
                    state.addedFilesArray.push(payload.link);
                }
            }
            state.docPolicy.isOpen = false;
            state.docLink.isOpen = false;
            state.docFile.isOpen = false;
        },
        deleteDocument: (state, {payload}: {payload: {fileId: string, fileName: string}}) => {
            if(state.selectedAutomation){
                state.selectedAutomation.documents.links = state.selectedAutomation.documents.links.filter(e => e.fileName !== payload.fileName);
                state.deletedFielsArray.push(payload.fileId);
                // state.deletedFielsArray.concat(state.selectedAutomation.documents.links.map(e => e.link));
            }
        },
        //DOC LINK
        openDocLink: (state) => {state.docLink.isOpen = true},
        hideDocLink: (state) => {state.docLink.isOpen = false},
        //DOC FILE
        openDocFile: (state) => {state.docFile.isOpen = true},
        hideDocFile: (state) => {state.docFile.isOpen = false},
        //STAGE
        createStage: (state, {payload}: {payload: {edgeId: string, type: string}}) => {
            if(state.selectedAutomation){
                const stage: automationStage = {
                    id: payload.edgeId + '-' + state.selectedAutomation.id,
                    type: payload.type,
                    actors: [],
                    eta: 86400*1000 //default 1 day,
                };

                if(payload.edgeId.startsWith('document')){ //IF ADD STAGE IN FIRST POSITION
                    state.selectedAutomation.stages = [stage, ...state.selectedAutomation.stages];
                }else if(payload.edgeId.endsWith('save')){ //IF ADD STAGE ON LAST POSITION
                    state.selectedAutomation.stages = [...state.selectedAutomation.stages, stage];
                }else{ //IF ADD BETWEEN STAGES
                    const insertAfterStageId = payload.edgeId.split('-')[0].split('stage')[1]; //edge id built by stage${id}-to-stage${id}
                    state.selectedAutomation.stages.splice(parseInt(insertAfterStageId) + 1, 0, stage);
                }
            }
        },
        deleteStage: (state, {payload}: {payload: {stageId: number}}) => {
            if(state.selectedAutomation){
                // console.log(payload.stageId + ' AFTER SPLICE ', state.selectedAutomation.stages.splice(payload.stageId, 1));
                // state.selectedAutomation.stages = state.selectedAutomation.stages.splice(payload.stageId, 1);
                state.selectedAutomation.stages = state.selectedAutomation.stages.filter((e, id) => id !== payload.stageId);
                // state.selectedAutomation.stages = state.selectedAutomation.stages.filter(e => e.id !== payload.stageId);
            }
        },
        //STAGE SETTINGS
        openStageSettings: (state, {payload} : {payload: {stageId: number}}) => {state.stageSettings = {isOpen: true, stageId: payload.stageId}},
        hideStageSettings: (state) => {state.stageSettings.isOpen = false},
        setStageSettings: (state, {payload} : {payload: {type: string, eta: number}}) => {
            if(state.selectedAutomation && state.stageSettings.stageId !== -1){
                state.selectedAutomation.stages[state.stageSettings.stageId] = {
                    ...state.selectedAutomation.stages[state.stageSettings.stageId],
                    type: payload.type,
                    eta: payload.eta,
                }
                state.stageSettings.isOpen = false;
                state.stageSettings.stageId = -1;
            }
        },
        //STAGE ACTOR
        openStageActor: (state, {payload} : {payload: {stageId: number}}) => {state.stageActor = {isOpen: true, stageId: payload.stageId}},
        hideStageActor: (state) => {state.stageActor.isOpen = false},
        setStageActor: (state, {payload}: {payload: {email: string, firstName?: string, lastName?: string}}) => {
            if(state.selectedAutomation && state.stageActor.stageId !== -1){
                state.selectedAutomation.stages[state.stageActor.stageId] = {
                    ...state.selectedAutomation.stages[state.stageActor.stageId],
                    actors: [...state.selectedAutomation.stages[state.stageActor.stageId].actors,
                        {
                            email: payload.email,
                            publicId: null,
                            firstName: payload.firstName ?? null,
                            lastName: payload.lastName ?? null
                        }
                    ],
                }
            }
        },
        deleteStageActor: (state, {payload} : {payload: {email: string, stageId: number}}) => {
            if(state.selectedAutomation){
                state.selectedAutomation.stages[payload.stageId] = {
                    ...state.selectedAutomation.stages[payload.stageId],
                    actors: state.selectedAutomation.stages[payload.stageId].actors.filter(e => e.email !== payload.email)
                }
            }
        },
        //SAVE EVIDENCE
        openAddEvidence: (state) => {state.addEvidence.isOpen = true},
        hideAddEvidence: (state) => {state.addEvidence.isOpen = false},
        setEvidence: (state, {payload}) => {
            state.addEvidence.isOpen = false;
            if(state.selectedAutomation){
                state.selectedAutomation.evidence.data.push(payload);
            }
        },
        deleteEvidence: (state, {payload}) => {
            if(state.selectedAutomation){
                state.selectedAutomation.evidence.data = state.selectedAutomation.evidence.data.filter(e => e.id !== payload);
            }
        },
        //CHANGE NAME
        openChangeName: (state) => {state.changeName.isOpen = true},
        hideChangeName: (state) => {state.changeName.isOpen = false},
        setNewName: (state, {payload}) => {
            if(state.selectedAutomation) state.selectedAutomation.name = payload;
            state.changeName.isOpen = false;
        },
        //DELETE PIPELINE
        openDeletePipeline: (state, {payload}: {payload: string}) => {
            state.deletePipeline = {
                isOpen: true,
                pipelineId: payload
            }
        },
        hideDeletePipeline: (state) => {state.deletePipeline = {isOpen: false, pipelineId: null}},
        //SAVE CHANGES
        openSaveChanges: (state, {payload}: {payload: {transiteTo: string; isAdd: boolean}}) => {
                state.saveChanges.isOpen = true;
                state.saveChanges.transiteTo = payload.transiteTo;
                state.saveChanges.isAdd = payload.isAdd;
            },
        hideSaveChanges: (state) => {
            state.saveChanges = {isOpen: false, transiteTo: '', isAdd: false};
        },
        //ADD PIPELINE
        openAddPipeline: (state) => {
            state.addPipeline.isOpen = true;
            state.saveChanges = {isOpen: false, transiteTo: '', isAdd: false};
        },
        hideAddPipeline: (state) => {state.addPipeline.isOpen = false},
        addNewPipeline: (state, {payload}) => {
            state.selectedAutomation = payload;
            state.addPipeline.isOpen = false;
            state.preferToOpen.pipeline = true;
            state.addedFilesArray = [];
            state.deletedFielsArray = [];
            },
        //PREFER TO OPEN
        setPreferPipeLine: (state, {payload}) => {state.preferToOpen.pipeline = payload},
        setPreferHistory: (state, {payload}) => {state.preferToOpen.history = payload},

        //SET AUTOMATION TO ARRAY
        addAutomationToArray: (state, {payload}: {payload: shortAutomation}) => {
            state.automations.push(payload);
        },
        updateAutomationInArray: (state, {payload}: {payload: shortAutomation}) => {
            state.automations = state.automations.map(e => e.id === payload.id ? {...payload, lastRunStatus: e.lastRunStatus} : e);
        },
        setSelectedAutomation: (state, {payload}: {payload: automationType}) => {
            state.selectedAutomation = payload;
            state.prevSelected = payload;
        },
        automationsHotReloadHistory: (state, {payload}: {payload: automationHistoryHotReload}) => {
            if(state.selectedAutomation){
                state.selectedAutomation.history = state.selectedAutomation.history.map(e => e.id === payload.id ? payload : e);
                state.selectedAutomation.lastExecutorId = payload.id;
                state.selectedAutomation.lastRunningDate = payload.runDate;
            }
            if(state.prevSelected){
                state.prevSelected.history = state.prevSelected.history.map(e => e.id === payload.id ? payload : e);
                state.prevSelected.lastExecutorId = payload.id;
                state.prevSelected.lastRunningDate = payload.runDate;
            }
            state.automations = state.automations.map(e => e.id === payload.instructionId ? {...e, lastRunningDate: payload.runDate, lastRunStatus: payload.status} : e)
        },
        cleanUp: () => initialState,
        //new
        setRunAutomationLoading: (state, {payload}: {payload: string}) => {
            state.loadings.runAutomation = {
                isLoading: true,
                autoId: payload,
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetShortAutomations.pending, (slice) => {
                slice.isLoading = true;
                slice.loadings.getList = true;
            })
            .addCase(GetShortAutomations.rejected, (slice) => {
                slice.isLoading = false;
                slice.loadings.getList = false;
            })
            .addCase(GetShortAutomations.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.loadings.getList = false;
                // // slice.filters = payload.filters; //???
                // if (payload.pageInfo.page === 0) {
                //     slice.automations = payload.automations;
                // } else {
                //     if (slice.pageInfo.page < payload.pageInfo.page) {
                //         slice.automations = [...slice.automations, ...payload.automations];
                //     } else {
                //         slice.automations = [...payload.automations, ...slice.automations];
                //     }
                // }

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<shortAutomation>(
                    slice.automations,
                    slice.pageInfo,
                    payload.automations,
                    payload.pageInfo,
                    slice.minMaxLoadedPage.minLoadedPage,
                    slice.minMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.automations = results;
                slice.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.pageInfo = payload.pageInfo;
            })

            //GetAutomationById

            .addCase(GetAutomationById.pending, (slice) => {
                slice.saveChanges = {isOpen: false, transiteTo: '', isAdd: false};
                slice.isLoading = true;
                slice.loadings.getExact = true;
            })
            .addCase(GetAutomationById.rejected, (slice) => {
                slice.isLoading = false;
                slice.loadings.getExact = false;
            })
            .addCase(GetAutomationById.fulfilled, (slice, {payload}) => {
                // slice.saveChanges = {isOpen: false, transiteTo: '', isAdd: false};
                slice.loadings.getExact = false;

                slice.selectedAutomation = payload as automationType
                slice.prevSelected = payload as automationType;
                slice.isLoading = false;
                slice.addedFilesArray = [];
                slice.deletedFielsArray = [];
                // if(payload.automations.length > 0){
                //     slice.selectedAutomation = payload.automations[0];
                // }
            })
            .addCase(AddAutomation.pending, (slice) => {
                slice.isLoading = true;
                slice.loadings.createAutomation = true;
            })
            .addCase(AddAutomation.rejected, (slice) => {
                slice.isLoading = false;
                slice.loadings.createAutomation = false;
            })
            .addCase(AddAutomation.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.loadings.createAutomation = false;

                slice.addedFilesArray = [];
                slice.deletedFielsArray = [];
                slice.pageInfo.total = slice.pageInfo.total + 1;
            })
        //GetPolicies
            .addCase(GetPolicies.pending, (slice) => {
                slice.isLoadingPolicies = true;
            })
            .addCase(GetPolicies.rejected, (slice) => {
                slice.isLoadingPolicies = false;
            })
            .addCase(GetPolicies.fulfilled, (slice, {payload}) => {
                slice.policies = payload.filter(e => e.files.length > 0);
                slice.isLoadingPolicies = false;
            })
            //UploadFile
            .addCase(UploadFile.pending, (slice) => {
                slice.isLoading = true;
                slice.docFile.isOpen = false;
            })
            .addCase(UploadFile.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(UploadFile.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                if(slice.selectedAutomation){
                    slice.selectedAutomation.documents.links.push({
                        policyId: null,
                        fileName: payload.fileName,
                        link: payload.fileId,
                        lastUpdate: payload.lastUpdated,
                    });
                    slice.addedFilesArray.push(payload.fileId);
                }
            })
        // DeleteAllFile
            .addCase(DeleteAllFile.pending, (slice) => {
                slice.isLoading = true;
                slice.saveChanges.isOpen = false;
                slice.doc.isOpen = false;
            })
            .addCase(DeleteAllFile.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(DeleteAllFile.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.deletedFielsArray = [];
                if(slice.selectedAutomation && slice.selectedAutomation.id === payload.autoId){
                    slice.selectedAutomation.documents.links = [];
                }
            })
        //UpdateAutomation
            .addCase(UpdateAutomation.pending, (slice) => {
                slice.isLoading = true;
                slice.saveChanges.isOpen = false;
            })
            .addCase(UpdateAutomation.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(UpdateAutomation.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.deletedFielsArray = [];
                slice.addedFilesArray = [];
                slice.prevSelected = slice.selectedAutomation;
            })
        // DeleteAutomation
            .addCase(DeleteAutomation.pending, (slice) => {
                slice.isLoading = true;
                slice.loadings.deleteAutomation = true;
            })
            .addCase(DeleteAutomation.rejected, (slice) => {
                slice.isLoading = false;
                slice.loadings.deleteAutomation = false;
            })
            .addCase(DeleteAutomation.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.selectedAutomation = null;
                slice.prevSelected = null;
                slice.deletedFielsArray = [];
                slice.addedFilesArray = [];
                slice.automations = slice.automations.filter(e => e.id !== payload);
                slice.pageInfo.total = slice.pageInfo.total - 1;
                //
                slice.deletePipeline = {
                    isOpen: false,
                    pipelineId: null
                }
                slice.loadings.deleteAutomation = false;
            })
        //RunAutomation
            .addCase(RunAutomation.pending, (slice) => {
                slice.isLoading = true;
                slice.loadings.runAutomation.isLoading = true;
            })
            .addCase(RunAutomation.rejected, (slice) => {
                slice.isLoading = false;
                slice.loadings.runAutomation.isLoading = false;
            })
            .addCase(RunAutomation.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.loadings.runAutomation = {
                    isLoading: false,
                    autoId: null
                }
                slice.automations = slice.automations.map(e => e.id === payload ?
                    {...e,
                        lastRunStatus: 'inProgress',
                        lastRunningDate: new Date(Date.now()).toISOString()
                    }
                    :
                    e);
                if(slice.selectedAutomation && slice.prevSelected){
                    const dateNow = getIsoDateTimeNow();
                    slice.selectedAutomation.lastRunningDate = dateNow;
                    slice.prevSelected.lastRunningDate = dateNow;
                    slice.automations = slice.automations.map(e => e.id === payload ? {...e, lastRunStatus: 'inProgress'} : e);
                }
            })
        // GetAutomationHistory
            .addCase(GetAutomationHistory.pending, (slice) => {
                slice.isLoading = true;
                slice.loadings.getHistory = true;
            })
            .addCase(GetAutomationHistory.rejected, (slice) => {
                slice.isLoading = false;
                slice.loadings.getHistory = false;
            })
            .addCase(GetAutomationHistory.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.loadings.getHistory = false;
                if(slice.selectedAutomation && slice.prevSelected){
                    slice.selectedAutomation.pageInfo = payload.pageInfo;
                    slice.selectedAutomation.history = payload.history as FlowHistory[];

                    slice.prevSelected.pageInfo = payload.pageInfo;
                    slice.prevSelected.history = payload.history as FlowHistory[];
                }
            })
        //ActivateAutomation
            .addCase(ActivateAutomation.pending, (slice) => {
                slice.isLoading = true;
                slice.loadings.toggleActivity = true;
            })
            .addCase(ActivateAutomation.rejected, (slice) => {
                slice.isLoading = false;
                slice.loadings.toggleActivity = false;
            })
            .addCase(ActivateAutomation.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.loadings.toggleActivity = false;
                if(slice.selectedAutomation && slice.prevSelected){
                    slice.selectedAutomation.active = !slice.selectedAutomation.active;
                    slice.prevSelected.active = !slice.selectedAutomation.active;
                    slice.automations = slice.automations.map(e => e.id === payload ? {...e, active: !e.active} : e);
                }
            })
        //DeactivateAutomation
            .addCase(DeactivateAutomation.pending, (slice) => {
                slice.isLoading = true;
                slice.loadings.toggleActivity = true;
            })
            .addCase(DeactivateAutomation.rejected, (slice) => {
                slice.isLoading = false;
                slice.loadings.toggleActivity = false;
            })
            .addCase(DeactivateAutomation.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.loadings.toggleActivity = false;
                if(slice.selectedAutomation && slice.prevSelected){
                    slice.selectedAutomation.active = !slice.selectedAutomation.active;
                    slice.prevSelected.active = !slice.selectedAutomation.active;
                    slice.automations = slice.automations.map(e => e.id === payload ? {...e, active: !e.active} : e);
                }
            })
        //GetAutomationEvidences
            .addCase(GetAutomationEvidences.pending, (slice) => {
                slice.isLoadingEvidences = true;
            })
            .addCase(GetAutomationEvidences.rejected, (slice) => {
                slice.isLoadingEvidences = false;
            })
            .addCase(GetAutomationEvidences.fulfilled, (slice, {payload}) => {
                slice.isLoadingEvidences = false;
                slice.frameworks = payload.frameworks;
                slice.controls = payload.controls;
                slice.evidences = payload.evidences;
            })
        //message
            .addCase(StopAutomation.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(StopAutomation.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(StopAutomation.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.automations.forEach(e => {
                    if(e.id === payload) e.lastRunStatus = 'failed';
                    return e;
                })
                // if(slice.selectedAutomation && slice.selectedAutomation.history[0]){
                //     slice.selectedAutomation.lastRunningDate = 'failed';
                //     slice.selectedAutomation.history[0].status = 'failed';
                //     slice.selectedAutomation.history[0].stages.forEach((e) => {
                //
                //     })
                // }
            })
        //GetEmployeesNameEmail
            .addCase(GetEmployeesNameEmail.pending, (slice) => {
                slice.loadings.getEmployees = true;
            })
            .addCase(GetEmployeesNameEmail.rejected, (slice) => {
                slice.loadings.getEmployees = false;
            })
            .addCase(GetEmployeesNameEmail.fulfilled, (slice, {payload}) => {
                slice.loadings.getEmployees = false;
                slice.employees = payload;
            })
    }
});

export const AutomationsReducer = FlowsSlice.reducer;

export const {
    updateAutomation,
    selectAutomation,
    //TRIGGER
    openTrigger,
    hideTrigger,
    setTriggerType,
    //TRIGGER DATE
    openTriggerDate,
    hideTriggerDate,
    setTriggerDate,
    //TRIGGER REPEAT
    openTriggerRepeat,
    hideTriggerRepeat,
    setTriggerRepeat,
    //DOC
    openDoc,
    hideDoc,
    setDocType,
    //DOC POLICY
    openDocPolicy,
    hideDocPolicy,
    setDocument,
    deleteDocument,
    //DOC LINK
    openDocLink,
    hideDocLink,
    //DOC FILE
    openDocFile,
    hideDocFile,
    //STAGE
    createStage,
    deleteStage,
    openStageSettings,
    hideStageSettings,
    setStageSettings,
    //STAGE ACTOR
    openStageActor,
    hideStageActor,
    setStageActor,
    deleteStageActor,
    //SAVE EVIDENCE
    openAddEvidence,
    hideAddEvidence,
    setEvidence,
    deleteEvidence,
    //CHANGE NAME
    openChangeName,
    hideChangeName,
    setNewName,
    // DELETEPIPLINE
    openDeletePipeline,
    hideDeletePipeline,
    //SAVE CHANGES
    openSaveChanges,
    hideSaveChanges,
    //ADD PIPELINE
    openAddPipeline,
    hideAddPipeline,
    addNewPipeline,
    //PREFER
    setPreferHistory,
    setPreferPipeLine,

    addAutomationToArray,
    setSelectedAutomation,
    updateAutomationInArray,
    automationsHotReloadHistory,
    cleanUp,
    //
    setRunAutomationLoading,
    replaceMinMaxLoadedPage
} = FlowsSlice.actions;

//selectors
const selectSelf = (state: AppState):flowsInitialState => state.Automations as flowsInitialState;

export const isLoading = createSelector(selectSelf, state => state.isLoading);
export const isLoadingPolicies = createSelector(selectSelf, state => state.isLoadingPolicies);

export const automations = createSelector(selectSelf, state => state.automations);
export const pageInfoSelector = createSelector(selectSelf, state => state.pageInfo);
// export const filtersSelector = createSelector(selectSelf, state => state.filters);

export const policies = createSelector(selectSelf, state => state.policies);
export const selectedAutomation = createSelector(selectSelf, state => state.selectedAutomation);

export const trigger = createSelector(selectSelf, state => state.trigger);
export const triggerDate = createSelector(selectSelf, state => state.triggerDate);
export const triggerRepeat = createSelector(selectSelf, state => state.triggerRepeat);

export const doc = createSelector(selectSelf, state => state.doc);
export const docPolicy = createSelector(selectSelf, state => state.docPolicy);
export const docLink = createSelector(selectSelf, state => state.docLink);
export const docFile = createSelector(selectSelf, state => state.docFile);

export const stageActor = createSelector(selectSelf, state => state.stageActor);
export const stageSettings = createSelector(selectSelf, state => state.stageSettings);

export const addEvidence = createSelector(selectSelf, state => state.addEvidence);
export const frameworks = createSelector(selectSelf, state => state.frameworks);
export const controls = createSelector(selectSelf, state => state.controls);
export const evidences = createSelector(selectSelf, state => state.evidences);
export const isLoadingEvidences = createSelector(selectSelf, state => state.isLoadingEvidences);


export const changeName = createSelector(selectSelf, state => state.changeName);
export const deletePipeline = createSelector(selectSelf, state => state.deletePipeline);
export const saveChanges = createSelector(selectSelf, state => state.saveChanges);
export const addPipeline = createSelector(selectSelf, state => state.addPipeline);

export const preferToOpen = createSelector(selectSelf, state => state.preferToOpen);
export const prevSelected = createSelector(selectSelf, state => state.prevSelected);

export const deletedFilesArray = createSelector(selectSelf, state => state.deletedFielsArray);
export const addedFilesArray = createSelector(selectSelf, state => state.addedFilesArray);



export const loadings = createSelector(selectSelf, state => state.loadings);
export const employees = createSelector(selectSelf, state => state.employees);
export const minMaxLoadedPage = createSelector(selectSelf, state => state.minMaxLoadedPage);







