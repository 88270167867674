import React from "react";
import * as S from './styled';
import {MenuItemText} from './styled';
import {Button, InputAdornment, MenuItem, MenuList} from "@mui/material";
import {SelectVariablePopperTypes} from "../../types";
import {useSelectVariablePopper} from "./hooks";
import colors from "../../../../../../theme/colors";
import {Flex} from "../../../../../Layouts";

export const SelectVariablePopper = (props: SelectVariablePopperTypes) => {
    const {element, isFocused, handleClose, handleSelectVariable} = props;
    const {form, setForm, filteredVariables, isNoValuesFound} = useSelectVariablePopper(props);

    return(
        <S.CommonPopper open={Boolean(element) && isFocused} anchorEl={element} onMouseDown={(e) => {e.stopPropagation()}}>
            <S.CommonPopperInnerWrapper>
                <S.PopperSearchInput
                    autoFocus
                    placeholder={('Search')}
                    value={form ?? ''}
                    onChange={(e) => setForm(e.currentTarget.value as string)}
                    endAdornment={
                        <InputAdornment position="end">
                            <S.SearchIconStyled />
                        </InputAdornment>
                    }
                />

                <MenuList sx={{
                    maxHeight: '120px',
                    overflow: 'auto',
                    '& .MuiMenuItem-root': {padding: '8px', '&.Mui-focusVisible': {backgroundColor: colors.backgrounds.blue_light_1}}}
                    // '& .Mui-focusVisible': {backgroundColor: colors.backgrounds.blue_light_1}}
                }>
                    {filteredVariables.map((e) => {
                        return(
                            <MenuItem onClick={() => handleSelectVariable(e)} key={e.id}>
                                <MenuItemText >{e.title}</MenuItemText>
                            </MenuItem>
                        )
                    })
                    }
                    {isNoValuesFound && <Flex p={'0 8px'}><S.VariableNameText>{'No variables found by your query'}</S.VariableNameText></Flex>}
                </MenuList>

                <S.CancelButtonContainer>
                    <Button variant={'text'} onClick={handleClose}>Cancel</Button>
                </S.CancelButtonContainer>
            </S.CommonPopperInnerWrapper>
        </S.CommonPopper>
    )
}
