import {
    Box,
    Divider,
    styled,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography
} from "@mui/material";
import {FlexRow} from "../../editorTitleWithActionsRow/styled";
import colors from "../../../../../theme/colors";
import FormControlLabel from "@mui/material/FormControlLabel";
import React from "react";

export const EditorSideMenuStyled = styled(Box)({
    width: '350px',
    height: '100%',
    backgroundColor: 'white',
    display: 'flex',
    overflow: 'hidden'
});

const EditorSideMenuTabsStyledWidth = 40;
export const EditorSideMenuTabsStyled = styled(Tabs)(() => ({
    '& .MuiButtonBase-root.MuiTab-root': {
        minWidth: EditorSideMenuTabsStyledWidth + 'px',
        width: EditorSideMenuTabsStyledWidth + 'px',
        minHeight: '80px',
    },
    '& * .MuiTabs-flexContainer': {
        borderBottom: 'none',
    },

    backgroundColor: colors.backgrounds.grey_light,
    borderRight: '1px solid ' + colors.stroke.grey,

    '& .MuiTabs-indicator': {
        display: 'none',
    },
}));
EditorSideMenuTabsStyled.defaultProps = {
    orientation: "vertical",
}

type TEditorSideMenuTabStyledProps = {
    isActive?: boolean;
};
export const EditorSideMenuTabStyled = styled(Tab, {shouldForwardProp: (propName) => (
        !['isActive'].includes(propName.toString())
    )})<TEditorSideMenuTabStyledProps>(({isActive}) => ({
    backgroundColor: isActive ? 'white' : undefined,
    border: '1px solid ' + colors.stroke.grey,
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
}));

export const EditorSideMenuCurrentColumnTabStyled = styled(Box, {shouldForwardProp: (propName) => (
        !['fullWidth'].includes(propName.toString())
    )})<{fullWidth?: boolean}>(({fullWidth}) => ({
    gap: '12px',
    width: !fullWidth ? `calc(100% - ${EditorSideMenuTabsStyledWidth}px)` : '100%',
    display: 'flex',
    overflow: 'auto',
    padding: '16px',
    height: '100%',
    flexDirection: 'column',
}));

export const EditorSideMenuTitleStyled = styled(Typography)({
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px',
    color: colors.text.dark,
})
export const EditorSideMenuDescriptionStyled = styled(Typography)({
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '130%',
    color: colors.text.grey,
})

export const EditorSideMenuVariableBlockStyled = styled(Box)(() => ({
    width: '100%',
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.backgrounds.white,
    border: '0.5px solid ' + colors.text.grey_light_2,
    borderRadius: '6px',
    padding: '10px 8px',
    gap: '6px',

    // boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)',
    // borderRadius: '6px',

    '& div.titleWithButtons': {
        gap: '10px',
        display: 'flex',
        alignItems: 'start',

        '& div.name': {
            flexGrow: 1,
            padding: '8px 16px',
            backgroundColor: 'rgba(254, 251, 222, 1)',

            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.15px',
            color: colors.text.dark,

            wordBreak: 'break-word',
        },

        '& div.buttons': {
            // opacity: 0,
            // transition: 'opacity 0.2s ease-in-out',
            gap: '8px',

            textAlign: 'end',

            display: 'flex',
            alignItems: 'center',
            // justifyContent: 'space-between',
            justifyContent: 'end',
            width: '35%',
            flexShrink: 0,

            'span.usedCount': {
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '15.6px',
                letterSpacing: '0.15px',
                color: colors.text.grey,

                whiteSpace: 'nowrap',

                'usedCount-count': {
                    color: colors.text.grey_dark,
                }
            },

            '.MuiIconButton-root': {
                padding: '0',

                'svg': {
                    width: '20px',
                    height: '20px',
                },
            },

            [`.MuiTooltip-tooltip`]: {
                backgroundColor: colors.backgrounds.grey_light,
                borderRadius: '2px',
                padding: '4px 16px',
                color: colors.text.dark,
                fontWeight: 400,
                fontSize: '12px',
            },
        },
    },

    // '&:hover': {
    //     '& div.titleWithButtons div.buttons': {
    //         opacity: 1,
    //     },
    // },
    // transition: 'opacity 0.2s ease-in-out',

    '.MuiInputBase-root': {
        padding: '8px',
    },

    '.MuiInputBase-input': {
        fontSize: '14px'
    },
}));

export const EditorSideMenuDividerStyled = styled(Divider)(() => ({
    borderBottom: '0.5px solid ' + colors.stroke.grey,
    width: '100%'
}));
EditorSideMenuDividerStyled.defaultProps = {
    flexItem: true,
}

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({

    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: colors.backgrounds.grey_light,
        borderRadius: '6px',
        padding: '8px 16px',
        color: colors.text.dark,
        fontWeight: 400,
        fontSize: '14px',
        marginTop: '0px !important',
    },

    [`& .${tooltipClasses.arrow}`]: {
        color: colors.backgrounds.grey_light,
    }
}));

export const TooltipWithoutArrow = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({

    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: colors.backgrounds.grey_light,
        borderRadius: '4px',
        padding: '8px 16px',
        color: colors.text.dark,
        fontWeight: 400,
        fontSize: '12px',
        marginTop: '0px !important',
    },

    [`& .${tooltipClasses.arrow}`]: {
        color: colors.backgrounds.grey_light,
    }
}));

export const EditorSideMenuTitleRowStyled = styled(FlexRow)({
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '20px',
});

export const EditorSideMenuSwitchStyled = styled(FormControlLabel)({
    gap: '10px',
    fontSize: '14px !important',
    lineHeight: '130%',
    paddingInline: '12px'
});
EditorSideMenuSwitchStyled.defaultProps = {
    // control: <Switch/>,
}

export const EditorSideMenuETAFieldStyled = styled(TextField)({
    width: '80px',
    '& .MuiOutlinedInput-root': {
        height: '32px',
        fontSize: '14px',
    },
    '& input.MuiOutlinedInput-input': {
        fontSize: '14px',
        padding: '8px 12px',
    },
});
EditorSideMenuETAFieldStyled.defaultProps = {
    type: 'number',
    size: 'small',
    inputProps: { inputMode: 'numeric', step: 1, min: 1 },
}

//history
export const HistoryContainer = styled('div')({
    width: '100%',
    height: '100%',
})

export const HistoryTitleAndCloseButtonContainer = styled('div')({
    width: '100%',
    display: "flex",
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px'
})

export const HistoryTitleText = styled(Typography)({
    color: colors.grayText,
    fontSize: '16px',
    fontWeight: 700
})

export const CategoryText = styled(Typography)({
    color: colors.text.grey_dark,
    margin: '20px 0 0 0'
})
HistoryTitleText.defaultProps = {
    variant: 'subtitle1'
}