import {useAddExistingSectionDialog} from "../../../hooks/useAddExistingSectionDialog";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {SectionsBlock} from '../../sectionsBlock'
import {GenericFiltersArray} from "../../../../../../newShared/components/genericFilter";
import {SectionsTableHeader} from "../../sectionsTableHeader";
import {Flex} from "../../../../../../newShared/components/Layouts";
import React from "react";
import {TableRowSkeleton} from "../../../../../../newShared/components/tableRowSkeleton";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {SectionToConfirmRow} from "../../sectionToConfirmRow";
import {MainTrainingsSectionsFullDataForImportModel} from "../../../../../../newShared/GQLTypes";
import {EmptyArrayImage} from "../../../../../../newShared/components/EmptyArrayImage";

export const AddExistingSectionDialog = ({onAddSections, excludeTrainingId}: {onAddSections: (sections: MainTrainingsSectionsFullDataForImportModel[]) => void, excludeTrainingId?: string}) => {
    const {
        handleClose,
        isOpen,
        selectedSections,
        shortDataSections,
        getTotalSections,
        onScroll,
        scrollRef,
        fetchPaging,
        handleConfirm,
        isConfirmButtonDisabled,
        isLoadingConfirm,
        isLoadingSections,
        filters,
        isStep1,
        setIsStep1,
        isSearchAndFilterEmpty
    } = useAddExistingSectionDialog(onAddSections, excludeTrainingId);

    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.pathTrainings'})

    return (
        <Dialog open={isOpen} onClose={handleClose} sx={{
            "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "1200px",
                minHeight: isStep1 ? "500px" : undefined
            },
        }}>
            <DialogTitle>
                {isStep1 ? t('Select section to add to training') : t('Review and confirm sections')}
            </DialogTitle>
            {isStep1 ?
                <DialogContent sx={{overflow: 'hidden', height: '100%', display: 'flex', flexDirection: 'column'}}>
                    <GenericFiltersArray
                        configs={filters}
                        fetchResultWithSelectedFilters={(search, filters, signal) => fetchPaging(0, 10, filters, search, true)}
                        isAddFilters={false}
                    />
                    {!(shortDataSections.length === 0 && !isLoadingSections && isSearchAndFilterEmpty) ?
                    <TableContainer onScroll={onScroll} ref={scrollRef} sx={{flexGrow: 1}}>
                        <Table stickyHeader>
                            <SectionsTableHeader isSections={true} totalCount={getTotalSections()} selectedCount={selectedSections.length}/>
                            <TableBody>
                                {shortDataSections.map(item => <SectionsBlock trainingSection={item}
                                                                              key={item.trainingId}/>)}
                                {isLoadingSections && <TableRowSkeleton columns={4}/>}
                                {shortDataSections.length === 0 && !isLoadingSections &&
                                    <TableRow>
                                        <TableCell colSpan={4}>
                                            <Typography variant={'*bodyText2'}>{t('No results were found for your search query.')}</Typography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                        :<Flex position={'absolute'} top={'0'} left={'0'} w={'100%'} h={'100%'}>
                         <EmptyArrayImage
                            type={'any'}
                            text={t('No sections to display')}
                            secondaryText={('')}
                            isShowing={true}
                            withoutAdd
                            />
                        </Flex>}
                </DialogContent>
                :
                <DialogContent>
                    <Flex gap={'10px'} direction={'column'} ai={'center'} jc={'center'}>
                        {!selectedSections.length &&
                            <Typography variant={'*bodyText2'}>{t('You have no sections to add')}</Typography>}
                        {selectedSections.map((item) => <SectionToConfirmRow key={item.sectionId} section={item}/>)}
                    </Flex>
                </DialogContent>
            }
            <DialogActions>
                {isStep1 ?
                    <>
                        <Button
                            size={'small'}
                            onClick={handleClose}
                            variant={'text'}
                        >
                            {tCommon('Cancel')}
                        </Button>
                        <Button
                            size={'small'}
                            variant={'contained'}
                            disabled={selectedSections.length < 1}
                            onClick={() => setIsStep1(false)}
                        >
                            {t('Review & Confirm')}
                        </Button>
                    </>
                    :
                    <>
                        <Button
                            size={'small'}
                            onClick={() => setIsStep1(true)}
                            variant={'text'}
                        >
                            {t('Back')}
                        </Button>
                        <LoadingButton
                            size={'small'}
                            onClick={handleConfirm}
                            variant={'contained'}
                            loading={isLoadingConfirm}
                            disabled={isConfirmButtonDisabled()}
                        >
                            {t('Confirm')}
                        </LoadingButton>
                    </>
                }
            </DialogActions>
        </Dialog>
    )

}