import {styled, TextField} from "@mui/material";
import {FlexRow} from "../../../../editorTitleWithActionsRow/styled";

export const PagePropertiesSideMenuTitleWithExitStyled = styled(FlexRow)({
    justifyContent: 'space-between',
    alignItems: 'center',
});

export const PagePropertiesSideMenuMarginRowStyled = styled(FlexRow)({
    gap: '14px',
    alignItems: 'center',
});

export const PagePropertiesSideMenuMarginTextFieldStyled = styled(TextField)({

});
PagePropertiesSideMenuMarginTextFieldStyled.defaultProps = {
    size: 'small',
    // InputProps: {inputProps: { type: 'number', step: 0.01 }},
    inputProps: { inputMode: 'numeric', step: 0.01, min: 0 },
    type: 'number',
}