import {useSelector} from "react-redux";
import {folders, isLoading} from "../../store/slice";
import {findFolderById} from "../../helpers";
import {Breadcrumbs as MuiBreadcrumbs, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {TREE_PATH} from "../../constants";
import {useHistory} from "react-router";


export const PathLine = ({path}: {path: {name: string, id: string}[]}) => {
    const history = useHistory();
    const _folders = useSelector(folders);
    const _isLoadingsFiles = useSelector(isLoading).isLoadingFiles;

    const onFolderClick = (item: {name: string, id: string}, id: number) => {
        if(_isLoadingsFiles) return;
        const folder = findFolderById(item.id, _folders);
        if(folder){
            let newPath = [...path.filter((e, eId) => eId <= id)];

            history.push(TREE_PATH + '/' + newPath.map(e => e.id).slice(1).join('/'));

            // dispatch(openFolder({folder: folder, path: newPath}));
            //TODO open left tree if needed

            // dispatch(GetShortDocumentsByFolderIdWithFilterPaging({data: {pageInfo: {page: 0, count: DOCUMENT_LIST_DEFAULT_COUNT}, folderId: folder.id, workspaceId: ''}}));
        }
    }

    return (
        <MuiBreadcrumbs itemsAfterCollapse={2} itemsBeforeCollapse={0} maxItems={5}
                        sx={{'& .MuiButtonBase-root': {marginLeft: 0, marginRight: 0}}}>
            {path.map((bc, index, array) => {
                const isNotFirstAndNotLast = (index !== array.length - 1);
                return (
                    <Typography variant={"subtitle1"} key={bc.name + index}
                                sx={{
                                    color: array.length - 1 === index ? colors.text.grey_dark : colors.text.grey,
                                    '&:hover': {cursor: 'pointer'},
                                }}
                                onClick={() => isNotFirstAndNotLast ? onFolderClick(bc, index) : () => null}
                    >
                        {bc.name}
                    </Typography>
                )
            })}
        </MuiBreadcrumbs>
    )
}
