import React, {Component, createRef, MouseEventHandler, RefObject} from "react";
import {
    EditorComponentsMethods,
    EditorTableData,
    getToolBarProps,
    MapDispatchToPropsObject,
    TEditorTableCellElementData,
    TEditorTableElementData,
    TEditorTableRowElementData,
    TEditorTextElementData
} from "../../types";
import {ToolBarProps} from "../editorToolBar";
import {EditorItemTableStyled} from "./styled";
import {EditorItemBlock} from "../editorItemBlock";
import {EditorPopperButtonsProps} from "../commonComponents/editorPopperButtons";

import {ReactComponent as AddRowBefore} from "./icons/row_before.svg";
import {ReactComponent as AddRowAfter} from "./icons/row_after.svg";
import {ReactComponent as AddColumnBefore} from "./icons/column_before.svg";
import {ReactComponent as AddColumnAfter} from "./icons/column_after.svg";
import {ReactComponent as RemoveRow} from "./icons/remove_row.svg";
import {ReactComponent as RemoveColumn} from "./icons/remove_column.svg";
import {EditorWorkZone} from "../editorWorkzone";
import {EditorItemTableCell} from "./components/editorItemTableCell";
import {ColumnState} from "../editorItemColumn";
import {uuid} from "../../../../utils";
import {SPACE_0WIDTH_HTML_CODE} from "../TextComponent/constants";

interface Props extends getToolBarProps {
    block: TEditorTableElementData;

    parentRef: RefObject<EditorItemBlock>["current"];
    editorWorkZoneRef: RefObject<EditorWorkZone>["current"];
    setPopperButtons: (buttons: EditorPopperButtonsProps["buttons"]) => void;
}
export interface TableState {
    id: string;
    type: 'table';
    data: TEditorTableElementData["data"];

    selected: [number, number] | undefined; // [row, column]

    wrapperRef: RefObject<HTMLDivElement>;
    refs: Record<string, RefObject<EditorItemTableCell>>;
}

export class EditorItemTable extends Component<Props, TableState> implements EditorComponentsMethods<EditorTableData> {
    constructor(props: EditorItemTable["props"]) {
        super(props);

        const tableContent = props.block.data.content || [];

        if (tableContent.length === 0) {
            tableContent.push(
                this.createRow(2, [50, 50]),
                this.createRow(2, [50, 50]),
            );
        }

        const refs: TableState["refs"] = {};
        tableContent.forEach(row => {
            row.data.content.forEach(el => {
                refs[el.id] = createRef<EditorItemTableCell>();
            });
        });

        this.state = {
            id: props.block.id,
            type: 'table',
            data: {
                ...props.block.data,
                content: tableContent,
            },

            selected: undefined,

            wrapperRef: createRef<HTMLDivElement>(),
            refs,
        }
    }

    componentDidMount() {
        this.setButtons();
    }

    setActiveBlock: EditorWorkZone["setActiveBlock"] = (ids) => {
        this.props.parentRef?.setActiveBlock([this.state.id, ...ids]);
    };

    setFocus = () => {
        !this.props.readonly && this.setSelection([0, 0]);
    }

    // checkIsFocused: (id: string) => boolean = (id) => {
    //     const selected = this.state.selected;
    //
    //     return (selected && this.props.parentRef?.checkIsFocused(id) && this.state.data.content[selected[0]]?.data.content[selected[1]].id === id) || false;
    // }

    setSelection = (selected: TableState["selected"]) => {
        this.state.data.content.forEach((tableRow, tableRowIndex) => {
            tableRow.data.content.forEach((tableCell, tableRowCell) => {
                if (tableRowIndex !== selected?.[0] || tableRowCell !== selected?.[1]) {

                    this.state.refs[tableCell.id]?.current?.onClickAway();
                }
            });
        });

        selected && this.state.refs[this.state.data.content[selected[0]]?.data.content[selected[1]].id]?.current?.setFocus();
        if (this.state.selected?.[0] !== selected?.[0] && this.state.selected?.[1] !== selected?.[1]) {
            this.setButtons(selected);
        }
        this.setState(prev => ({...prev, selected}));
    }

    getRowSizes = () => {
        const sizes: number[] = [];
        this.state.data.content[0].data.content.forEach(cell => {
            const ref = this.state.refs[cell.id].current;
            if (ref) {
                sizes.push(ref.getWidth());
            }
        });

        return sizes;
    }

    createRow = (columns: number, colSizes?: number[]): TEditorTableRowElementData => {
        const sizes = colSizes || this.getRowSizes();

        return {
            id: 'tableRow' + uuid(),
            type: 'tableRow',
            data: {
                content: Array(columns).fill('null').map((_, index) => this.createCell(undefined, sizes[index])),
            },
        }
    }

    createCell = (type: 'text' | 'image' | 'list' = "text", width: number): TEditorTableCellElementData => {
        const textBlock: TEditorTextElementData = {
            id: 'text' + uuid(),
            type: 'text',
            data: {
                content: [],
                innerHtml: `<span class="Color000000 fontSize14 FontArial">${SPACE_0WIDTH_HTML_CODE}</span>`,
                alignText: 'left',
            },
        }

        return {
            id: 'tableCell' + uuid(),
            type: "tableCell",
            data: {
                content: [{
                    id: 'row' + uuid(),
                    type: 'row',
                    data: {
                        content: [{
                            id: 'block' + uuid(),
                            type: 'block',
                            data: {
                                width: 100,
                                content: [textBlock],
                            },
                        }]
                    },
                }],
                width,
                colSpan: 1,
                rowSpan: 1,
            },
        }
    }

    ///Table
    addRow = (addAfter: boolean = false) => {
        if (!this.state.selected) return;

        const columnCount = this.state.data.content[0].data.content.length;
        const newRow = this.createRow(columnCount);

        const prev = this.state;
        const table = JSON.parse(JSON.stringify(prev.data.content)) as EditorTableData["content"];
        const selected = JSON.parse(JSON.stringify(prev.selected)) as TableState["selected"];

        table.splice(addAfter ? selected![0] + 1 : selected![0], 0, newRow);

        selected![0] = addAfter ? selected![0] : selected![0] + 1;

        this.setState(prev => {
            newRow.data.content.forEach(cell => {
                prev.refs[cell.id] = createRef<EditorItemTableCell>();
            });

            return {
                ...prev,
                data: {...prev.data, content: table},
                selected,
            }
        })

        !addAfter && this.setButtons(this.state.selected)
    }
    addColumn = (addAfter: boolean = false) => {
        if (!this.state.selected) return;

        const newColumnCount = this.state.data.content[0].data.content.length + 1;

        const rowCount = this.state.data.content.length;
        const newColumn: TEditorTableCellElementData[] = [];
        for (let i = 0; i < rowCount; i++) {
            newColumn.push(this.createCell(undefined, 100 / newColumnCount));
        }

        const prev = this.state;
        const content = JSON.parse(JSON.stringify(prev.data.content)) as EditorTableData["content"];
        const selected = JSON.parse(JSON.stringify(prev.selected)) as TableState["selected"];

        const newWidth: number[] = [];

        this.state.data.content[0]?.data.content.forEach((cell, cellIndex) => {
            const ref = this.state.refs[cell.id].current;
            if (ref) {
                newWidth[cellIndex] = ref.getWidth() * (newColumnCount - 1) / newColumnCount;
            }
        });

        this.state.data.content.forEach((row, rowIndex) => {
            row.data.content.forEach((cell, cellIndex) => {
                const ref = this.state.refs[cell.id].current;
                if (ref) {
                    ref.setWidth(newWidth[cellIndex]);
                }
            });
        });

        content.forEach((row, i) =>
            content[i].data.content.splice(addAfter ? selected![1] + 1 : selected![1], 0, newColumn[i])
        );

        selected![1] = addAfter ? selected![1] : selected![1] + 1;

        this.setState(prev => {
            newColumn.forEach(el => {
                prev.refs[el.id] = createRef<EditorItemTableCell>();
            })

            return {
                ...prev,
                data: {...prev.data, content},
                selected
            }
        });

        !addAfter && this.setButtons(this.state.selected)
    }

    deleteRow = () => {
        if (!this.state.selected) return;
        if (this.state.data.content.length === 1) {this.props.parentRef?.props.onDelete(); return;}

        const newCount = this.state.data.content.length - 1;

        this.setState(prev => {
            if (prev.data.content.length !== newCount) {
                prev.data.content = prev.data.content
                    .filter((_, i) => i !== this.state.selected![0]);
            }

            return prev
        })
    }

    deleteColumn = () => {
        if (!this.state.selected) return;
        if (this.state.data.content[0]?.data.content.length === 1) {this.props.parentRef?.props.onDelete(); return;}

        const removedBlockId = this.state.data.content[this.state.selected![0]]?.data.content[this.state.selected![1]].id;

        const newCount = this.state.data.content[0].data.content.length - 1;
        const removedWidth = this.state.refs[removedBlockId]?.current?.getWidth() || 0;

        const oldSum = this.state.data.content[0].data.content.reduce((sum, cell) => (
            sum + (cell.id !== removedBlockId ? this.state.refs[cell.id]?.current?.getWidth() || 0 : 0)
        ), 0);

        this.state.data.content.forEach((row, rowIndex) => {
            row.data.content.forEach((cell, cellIndex) => {
                const ref = this.state.refs[cell.id].current;
                if (ref && cell.id !== removedBlockId) {
                    const width = ref.getWidth();
                    ref.setWidth(width + removedWidth * width / oldSum);
                }
            });
        });

        this.setState(prev => {
            if (prev.data.content[0]?.data.content.length !== newCount) {
                prev.data.content = prev.data.content
                    .map(row => {
                        return {
                            ...row,
                            data: {
                                ...row.data,
                                content: row.data.content.filter((_, i) => i !== this.state.selected![1]),
                            }
                        }
                    });
            }

            return prev
        })
    }

    ///////ToolBar
    setToolBar: MapDispatchToPropsObject["setToolBar"] = (toolBar, ids) => {
        this.props.parentRef?.setToolBar(toolBar, [this.state.id, ...ids])
    }
    setTextByToolBar: ToolBarProps["toolBarHandler"] = (action, payload, ids) => {
        const nextId = ids[0];
        if (nextId) {
            const ref = this.state.refs[nextId]?.current;
            if (ref) {
                ref.setTextByToolBar(action, payload, ids.slice(1))
            }
        }
    }

    getData = (): Props["block"] => {
        const contentTableData: EditorTableData["content"] = JSON.parse(JSON.stringify(this.state.data.content));
        contentTableData.forEach((row, rowIndex) =>
            row.data.content.forEach((cell, cellIndex) => {
                const cellRef = this.state.refs[cell.id]?.current;
                if (cellRef) {
                    contentTableData[rowIndex].data.content[cellIndex] = cellRef.getData();
                }
            }));

        return {
            id: this.state.id,
            type: this.state.type,
            data: {
                ...this.state.data,
                content: contentTableData,
            },
        }
    }

    onClickAway = () => {
        // console.log(`onClickAway TABLE`)
        this.setSelection(undefined);
    }

    setButtons = (selected?: TableState["selected"]) => {
        // console.log(`setButtons TABLE`, selected)
        const buttons: EditorPopperButtonsProps["buttons"] = [
            [
                {
                    title: 'Add row above',
                    icon: AddRowBefore,
                    onClick: (e) => {
                        e.stopPropagation();
                        this.addRow();
                    },
                    isDisabled: !selected,
                },
                {
                    title: 'Add row below',
                    icon: AddRowAfter,
                    onClick: (e) => {
                        e.stopPropagation();
                        this.addRow(true);
                    },
                    isDisabled: !selected,
                },
                {
                    title: 'Add column before',
                    icon: AddColumnBefore,
                    onClick: (e) => {
                        e.stopPropagation();
                        this.addColumn();
                    },
                    isDisabled: !selected,
                },
                {
                    title: 'Add column after',
                    icon: AddColumnAfter,
                    onClick: (e) => {
                        e.stopPropagation();
                        this.addColumn(true);
                    },
                    isDisabled: !selected,
                },
            ],[
                {
                    title: 'Remove row',
                    icon: RemoveRow,
                    onClick: (e) => {
                        e.stopPropagation();
                        this.deleteRow();
                    },
                    isDisabled: !selected,
                },
                {
                    title: 'Remove column',
                    icon: RemoveColumn,
                    onClick: (e) => {
                        e.stopPropagation();
                        this.deleteColumn();
                    },
                    isDisabled: !selected,
                },
            ],
        ]

        this.props.setPopperButtons(buttons);
    }

    setColumnSizes = (colIndexLeft: number, sizeLeft: number, sizeRight: number) => {
        this.state.data.content.forEach((row, rowIndex) => {
            const {leftSide, rightSide} = this.getLeftRight(rowIndex, colIndexLeft);

            leftSide.setWidth(sizeLeft);
            rightSide.setWidth(sizeRight);
        });
    }

    // getCellSizes = (leftIndex: number) => {
    //     const leftWidth = this.state.;
    // }

    ///////////
    //Resizing
    getLeftRight = (rowIndex: number, colIndex: number) => {
        const leftSideId = this.state.data.content[rowIndex].data.content[colIndex].id!;
        const rightSideId = this.state.data.content[rowIndex].data.content[colIndex + 1].id!;
        //
        const leftSide = this.state.refs[leftSideId].current!;
        const rightSide = this.state.refs[rightSideId].current!;

        const leftSideRef = leftSide.state.wrapperRef.current!;
        const rightSideRef = rightSide.state.wrapperRef.current!;

        return {leftSide, rightSide, leftSideRef, rightSideRef};
    };

    mouseDownHandler = (rowIndex: number, colIndex: number): MouseEventHandler<HTMLDivElement> => (e) => {
        const {
            leftSide, rightSide,
            leftSideRef, rightSideRef
        } = this.getLeftRight(rowIndex, colIndex);

        const leftResize = {x: e.clientX, px: leftSideRef.offsetWidth, percent: leftSide.getWidth()}
        const rightResize = {x: e.clientX, px: rightSideRef.offsetWidth, percent: rightSide.getWidth()}

        //@ts-ignore
        leftSide.setState(prev => ({...prev, resize: leftResize}));
        //@ts-ignore
        rightSide.setState(prev => ({...prev, resize: rightResize}));

        // Attach the listeners to `document`
        document.onmousemove = this.mouseMoveHandler(rowIndex, colIndex);
        document.onmouseup = this.mouseUpHandler(rowIndex, colIndex);
    };

    mouseMoveHandler = (rowIndex: number, colIndex: number) => (e: MouseEvent) => {
        const {leftSide, rightSide, leftSideRef, rightSideRef} = this.getLeftRight(rowIndex, colIndex);

        // How far the mouse has been moved
        const x = leftSide.state.resize.x || 0;
        const dx = e.clientX - x;

        const leftWidth = leftSide.state.resize;
        const rightWidth = rightSide.state.resize;

        const k = (leftWidth.px + rightWidth.px) / (leftWidth.percent + rightWidth.percent);
        const dPercentage = dx / k;

        // leftSideRef.style.flexBasis = `${leftWidth.percent + dPercentage}%`;
        // rightSideRef.style.flexBasis = `${rightWidth.percent - dPercentage}%`;

        this.setColumnSizes(colIndex, leftWidth.percent + dPercentage, rightWidth.percent - dPercentage);

        document.body.style.cursor = 'col-resize';

        leftSideRef.style.userSelect = 'none';
        leftSideRef.style.pointerEvents = 'none';

        rightSideRef.style.userSelect = 'none';
        rightSideRef.style.pointerEvents = 'none';
    };
    mouseUpHandler = (rowIndex: number, colIndex: number) => (e: MouseEvent) => {
        const {leftSide, rightSide, leftSideRef, rightSideRef} = this.getLeftRight(rowIndex, colIndex);

        const leftWidth = leftSide.getWidth();
        const rightWidth = rightSide.getWidth();

        //@ts-ignore
        leftSide.setState((prev: ColumnState) => ({...prev, data: {...prev.data, width: leftWidth}}));
        //@ts-ignore
        rightSide.setState((prev: ColumnState) => ({...prev, data: {...prev.data, width: rightWidth}}));

        document.body.style.removeProperty('cursor');

        leftSideRef.style.removeProperty('user-select');
        leftSideRef.style.removeProperty('pointer-events');

        rightSideRef.style.removeProperty('user-select');
        rightSideRef.style.removeProperty('pointer-events');

        document.onmousemove = null;
        document.onmouseup = null;
    };

    render() {
        return (
            <EditorItemTableStyled ref={this.state.wrapperRef}>
                <table>
                    <tbody>
                    {this.state.data.content.map((row, rowIndex, array) => (
                        <tr key={row.id}>
                            {row.data.content.map((cell, colIndex, array) => (
                                <EditorItemTableCell key={cell.id} ref={this.state.refs[cell.id]} block={cell}
                                                     isLast={colIndex === array.length - 1}
                                                     parentRef={this}
                                                     editorWorkZoneRef={this.props.editorWorkZoneRef}
                                                     setPopperButtons={this.props.setPopperButtons}
                                                     getToolBarState={this.props.getToolBarState}

                                                     onMouseDown={() => setTimeout(() => this.setSelection([rowIndex, colIndex]), 10)}

                                                     onResizeMouseDown={this.mouseDownHandler(rowIndex, colIndex)}

                                                     status={this.props.status}
                                                     readonly={this.props.readonly}
                                                     isLoading={this.props.isLoading}
                                />
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </EditorItemTableStyled>
        );
    }
}