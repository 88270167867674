import React, {FC, useEffect, useState} from "react";
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Step,
    StepButton,
    Stepper,
    TextField,
    Typography
} from "@mui/material";
import {LoadingButton} from "../Basic/CommonButtons";
import {useMainTranslation} from "../../hooks/useMainTranslationHooks/useMainTranslation";
import {TReleaseDialogReturnData, TReleaseDialogStep} from "./types";
import {getReleaseDialogDescriptionByStep, getReleaseDialogTitleByStep} from "./helpers";
import {useLazyQuery} from "@apollo/client";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../newDomain/authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {
    GetDataForReleaseQuery,
    GetDataForReleaseQueryVariables,
    GetDataForReleaseWithEmployeesQuery
} from "../../GQLTypes";
import {getName} from "../../utils/text";
import {getDataForRelease} from "../../graphQl/query";
import {AutocompleteCollaborators} from "../AutocompleteCollaborators";
import {v4 as uuid} from "uuid";
import {optionIsTCollaborator, TCollaborator, TCollaboratorWithRole} from "../AutocompleteCollaborators/types";
import {defaultGetOptionDisabled} from "../AutocompleteCollaborators/helpers";

const defaultDialogData = {review: [], sign: [], read: [], evidence: {frameworkId: '', controlId: '', evidenceId: ''}};

type TProps = {
    isOpen: boolean,
    handleClose: () => void,

    isSubmittingLoading: boolean,
    handleSubmit: (data: TReleaseDialogReturnData) => void,

    onlyEvidence?: boolean,
}
export const ReleaseDialog: FC<TProps> = ({isOpen, handleClose, handleSubmit, isSubmittingLoading, onlyEvidence }) => {
    const {t} = useMainTranslation('', {keyPrefix: 'ReleaseDialog'});
    const {organizationId, workspaceId} = UseManageWorkspacesAndOrganizations();

    const [getDataFramework, {loading: isLoading, data: _dataFramework}] = useLazyQuery<GetDataForReleaseQuery, GetDataForReleaseQueryVariables>(getDataForRelease);
    const data = _dataFramework?.getDataForRelease || {frameworks: [], controls: [], evidences: []};

    const [uniqueInputId, setUniqueInputId] = useState<string>(uuid());
    const steps: {step: TReleaseDialogStep, label: string}[] = [{step: "review", label: t('Reviewers') + "*"}, {step: "sign", label: t('Signers') + "*"}, {step: "read", label: t('Readers')}];
    const [step, setStep] = useState<TReleaseDialogStep>(!onlyEvidence ? "review" : "evidence");
    useEffect(() => {
        if (isOpen) {
            setStep(!onlyEvidence ? "review" : "evidence");
            getDataFramework({variables: {organizationId: organizationId || '', workspaceId: workspaceId || ''}});
        } else setDialogData(defaultDialogData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    const [dialogData, setDialogData] = useState<TReleaseDialogReturnData>(defaultDialogData);

    const handleAddEmail = (collaborator: TCollaborator | TCollaboratorWithRole | string) => {
        const newCollaborator = optionIsTCollaborator(collaborator) ? collaborator : {email: collaborator};

        setDialogData(prev => ({...prev, [step as 'review' | 'sign' | 'read']: [...prev[step as 'review' | 'sign' | 'read'], newCollaborator]}))
        setUniqueInputId(uuid());
    }
    const handleRemoveEmail = (email: string) => {
        setDialogData(prev => ({...prev, [step as 'review' | 'sign' | 'read']: prev[step as 'review' | 'sign' | 'read'].filter(ee => ee.email !== email)}))
        setUniqueInputId(uuid());
    }

    const handleNext = () => {
        switch (step) {
            case "review": {setStep("sign"); break; }
            case "sign": {setStep("read"); break; }
            case "read": {setStep("evidence"); break; }
            case "evidence": {handleSubmit(dialogData); break; }
        }
    }
    const handleBefore = () => {
        switch (step) {
            case "review": { handleClose(); break; }
            case "sign": { setStep("review"); break; }
            case "read": { setStep("sign"); break; }
            case "evidence": {
                if (!onlyEvidence) setStep("read")
                else handleClose();
                break;
            }
        }
    }

    useEffect(() => {
        setUniqueInputId(uuid());
    }, [step]);

    useEffect(() => {
        setDialogData(prev => ({...prev, evidence: {frameworkId: prev.evidence.frameworkId, controlId: '', evidenceId: ''}}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialogData.evidence.frameworkId])
    useEffect(() => {
        setDialogData(prev => ({...prev, evidence: {frameworkId: prev.evidence.frameworkId, controlId: prev.evidence.controlId, evidenceId: ''}}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialogData.evidence.controlId])

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t(getReleaseDialogTitleByStep(step, onlyEvidence))}</DialogTitle>
            <DialogContent sx={{overflow: 'hidden', display: 'flex', flexDirection: 'column', width: '100%'}}>

                {step !== "evidence" && (
                    <>
                        <Stepper nonLinear activeStep={steps.findIndex(e => e.step === step)} sx={{mt: '8px'}}>
                            {steps.map(({step: st, label}, index) => (
                                <Step key={label} disabled={isLoading || isSubmittingLoading}
                                      completed={
                                          st === "review"
                                              ? dialogData.review.length > 0 && step !== "review"
                                              : st === "sign"
                                                  ? dialogData.sign.length > 0 && step !== "sign"
                                                  : st === "read"
                                                      ? dialogData.review.length > 0 && dialogData.sign.length > 0
                                                      : true
                                      }>
                                    <StepButton color="inherit" onClick={() => setStep(st)}>
                                        {label}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>

                        <Typography variant={"body2"} mt={'16px'}>{t(getReleaseDialogDescriptionByStep(step))}</Typography>

                        <Box display={"flex"} alignItems={"center"} gap={'8px'} flexWrap={"wrap"} p={(dialogData[step as 'review' | 'sign' | 'read']?.length || 0) !== 0 ? '16px 0' : '8px 0'}>
                            {dialogData[step as 'review' | 'sign' | 'read']?.map(employee => {
                                const haveName = employee.firstName?.length || employee.lastName?.length;
                                return (
                                    <Chip key={employee.email} label={haveName ? getName(employee.firstName || '', employee.lastName || '') : employee.email}
                                          sx={{backgroundColor: (haveName) ? '#F0F9FF' : '#EBE9FE'}}
                                          onDelete={() => handleRemoveEmail(employee.email)}
                                    />
                                )
                            })}
                        </Box>

                        <AutocompleteCollaborators<false, false, true>
                            key={uniqueInputId}
                            getOptionLabelCustom={'name+email'}

                            freeSolo
                            freeSoloType={"email"}

                            label={t('Enter email or name')}
                            sx={{mt: '8px'}}

                            defaultValue={null}
                            getOptionDisabled={defaultGetOptionDisabled(dialogData[step as 'review' | 'sign' | 'read'].map(e => ({email: e.email, publicId: uuid(), firstName: '', lastName: ''})))}
                            onChange={value => {
                                if (value) handleAddEmail(value);
                            }}
                        />
                    </>
                )}

                {step === "evidence" && (
                    <>
                        <Autocomplete<GetDataForReleaseWithEmployeesQuery["getDataForReleaseWithEmployees"]["frameworks"][number]>
                            loading={isLoading}
                            sx={{mt: '16px'}}
                            options={data.frameworks || []}
                            getOptionLabel={e => e.name}
                            renderOption={(props, option, state) => (<Typography {...props} variant={"body2"} key={option.id}>{option.name}</Typography> )}
                            onChange={(_, newValue) => setDialogData({...dialogData, evidence: {...dialogData.evidence, frameworkId: newValue?.id || ''}})}
                            value={data.frameworks.find(e => e.id === dialogData.evidence.frameworkId) || null}
                            renderInput={(params) => <TextField required {...params} variant={"outlined"} label={t('Framework')} />}
                        />

                        <Autocomplete<GetDataForReleaseWithEmployeesQuery["getDataForReleaseWithEmployees"]["controls"][number]>
                            loading={isLoading}
                            sx={{mt: '16px'}}
                            disabled={dialogData.evidence.frameworkId === ''}
                            options={data.controls.filter(e => e.frameworkIds.includes(dialogData.evidence.frameworkId)) || []}
                            getOptionLabel={e => e.name}
                            renderOption={(props, option, state) => (<Typography {...props} variant={"body2"} key={option.id}>{option.name}</Typography> )}
                            onChange={(_, newValue) => setDialogData({...dialogData, evidence: {...dialogData.evidence, controlId: newValue?.id || ''}})}
                            value={data.controls.find(e => e.id === dialogData.evidence.controlId) || null}
                            renderInput={(params) => <TextField required {...params} variant={"outlined"} label={t('Control')} />}
                        />

                        <Autocomplete<GetDataForReleaseWithEmployeesQuery["getDataForReleaseWithEmployees"]["evidences"][number]>
                            loading={isLoading}
                            sx={{mt: '16px'}}
                            disabled={dialogData.evidence.frameworkId === '' || dialogData.evidence.controlId === ''}
                            options={data.evidences.filter(e => e.controlIds.includes(dialogData.evidence.controlId)) || []}
                            getOptionLabel={e => e.name}
                            renderOption={(props, option, state) => (<Typography {...props} variant={"body2"} key={option.id}>{option.name}</Typography> )}
                            onChange={(_, newValue) => setDialogData({...dialogData, evidence: {...dialogData.evidence, evidenceId: newValue?.id || ''}})}
                            value={data.evidences.find(e => e.id === dialogData.evidence.evidenceId) || null}
                            renderInput={(params) => <TextField required {...params} variant={"outlined"} label={t('Evidence')} />}
                        />
                    </>
                )}

            </DialogContent>
            <DialogActions>
                <Button variant={"text"} onClick={handleBefore} disabled={isLoading}>
                    {step === 'review' ? t('Cancel') : t('Back')}
                </Button>
                <LoadingButton loading={isSubmittingLoading} onClick={handleNext}
                               disabled={
                                   isLoading
                                   || (step === 'review' && dialogData.review.length === 0)
                                   || (step === 'sign' && dialogData.sign.length === 0)
                                   || (step === 'read' && (dialogData.review.length === 0 || dialogData.sign.length === 0))
                                   || (step === 'evidence' && !onlyEvidence && dialogData.review.length === 0 && dialogData.sign.length === 0)
                                   || (step === 'evidence' && (dialogData.evidence.frameworkId === '' || dialogData.evidence.controlId === '' || dialogData.evidence.evidenceId === ''))
                }>
                    {step !== 'evidence' ? t('Next') : t('Release')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
