import {useDispatch, useSelector} from "react-redux";

import {
    DelegatedTaskModel,
    Exact,
    MainFrameworksCancelTaskByIdMutationVariables,
} from "../../../../../../newShared/GQLTypes";
import {TApolloErrors} from "../../../../../../newShared/utils/asyncThunk/types";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import * as yup from "yup";
import {useFormik} from "formik";
import React, {useEffect} from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {dialogs, loadings, setCancelDelegatedTaskAction} from "../../../store/slice";
import {mainVicaCancelDelegatedTaskByIdAction, mainVicaGetDelegatedTaskByIdAction} from "../../../store/actions";


export const useVicaCancelDelegatedTask = () => {

    const dispatch = useDispatch();
    const {t} = useMainTranslation("", {keyPrefix: "pathVica.TasksList.dialogs"});

    const {cancelDelegatedTask: {isOpen, task}} = useSelector(dialogs);
    const {cancelDelegateTask: isLoading} = useSelector(loadings);
    const {setMessage} = useMessageDialog();

    const validationSchema = yup.object({
        comment: yup.string()
            .nullable()
            .required(t('Comment field is required'))
            .max(1000, t('Comment should be less than 1000 symbols'))
    });

    const formik = useFormik({
        initialValues: {comment: ''},
        validationSchema,
        onSubmit: (values, formikHelpers) => {
            //timeoffId && handleApproveRequest(timeoffId)
        },
        isInitialValid: false
    })
    const formikOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldTouched(e.target.name, true, false);
        formik.handleChange(e);
    };

    useEffect(() => {
        formik.resetForm();

        //eslint-disable-next-line
    },[isOpen])


    const openCancelDelegatedTask = (task: DelegatedTaskModel) => {
        dispatch(setCancelDelegatedTaskAction({isOpen: true, task}))
    }

    const handleClose = () => {
        dispatch(setCancelDelegatedTaskAction({clear: true}))
    }

    const getTaskById = (_id: string) => {
        task && dispatch(mainVicaGetDelegatedTaskByIdAction({data: {taskId: task.id}, clean: true, onError: handleClose}));
    }

    const handleCancelTask = () => {
        task && dispatch(mainVicaCancelDelegatedTaskByIdAction({
            data: {taskId: task.id, reason: formik.values.comment},
            onSuccess: (request: (Omit<MainFrameworksCancelTaskByIdMutationVariables, "workspaceId" | "organizationId"> & {workspaceId?: string | undefined, organizationId?: string | undefined}), response: DelegatedTaskModel, addictiveData?: ({} | undefined)) => {
                setMessage({title: t('Completed successfully'), message: t(`Delegated task {{responseId}} has been cancelled.`, {responseId: response.id})});
                handleClose();
            },
            onError: (request: (Omit<Exact<{workspaceId: string, taskId: string, reason: string}>, "workspaceId" | "organizationId"> & {workspaceId?: string | undefined, organizationId?: string | undefined}), error: TApolloErrors, addictiveData?: ({} | undefined)) => {
                const error409 = error.e409?.[0];
                const error404 = error.e404?.[0];
                if(error409?.type === 'STATUS_CHANGED'){
                    setMessage({title: t('Action conflict error'), message: t(`Action not applicable! Task status was changed`)});
                    task && getTaskById(task.id);
                    handleClose();
                }
                if(error404){
                    setMessage({title: t(`Delegated task {{taskId}}`, {taskId: task?.id ?? ''}), message: t(`Delegated task with id {{taskId}} not found`, {taskId: task?.id ?? ''})});
                    task && getTaskById(task.id);
                    handleClose();
                }
            }
        }));
    }


    return {
        isOpen,
        isLoading,
        task,
        formik: {...formik, handleChange: formikOnChange},
        openCancelDelegatedTask,
        handleClose,
        handleCancelTask,
    }
}
