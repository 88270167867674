import {Box, DialogContent, Divider, styled} from "@mui/material";
import {TypographyBody2} from "../../../../../../../newShared/components/Inputs/styled";
import colors from "../../../../../../../newShared/theme/colors";
import {SearchField} from "../../../../../../../newShared/components/Basic/CommonInputs";


export const RecipientsDialogContent = styled(DialogContent)(() => ({
    overflow: 'hidden',
    display: "flex",
    flexDirection: 'column',
    width: '100%',
}));

export const RecipientSearchField = styled(SearchField)(() => ({
    marginTop: '8px',
}));

export const RecipientsContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: "column",
    gap: "8px",
    // marginTop: "16px",
    overflow: "auto",
    flexGrow: 1,
}));

export const RecipientRowContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: "column",
}));

export const RecipientRowBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: "center",
    gap: '16px',
    overflow: 'hidden',
    flexGrow: 1,
    margin: "5px 0"
}));

export const RecipientsDataContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: "column",
    overflow: "hidden",
    flexGrow: 1,
}));

export const TypographyName = styled(TypographyBody2)(() => ({
    color: colors.text.dark,
    noWrap: true,
}));

export const TypographyEmail = styled(TypographyBody2)(() => ({
    color: colors.text.grey_dark,
    noWrap: true,
}));

export const RecipientRowDivider = styled(Divider)(() => ({
    // borderBottom: '1px solid ' + colors.divider,
    // borderColor: colors.divider,
    backgroundColor: colors.primary.disable,
    borderColor: colors.primary.disable,
    marginTop: '4px',
    marginRight: '4px',
    flexItem: true,
}));
