import {
    addDialogType,
    appHistory,
    availableAppType,
    installedAppType,
    integrationLogType,
    integrationsInitialState
} from "../types";
import {TPageInfo} from "../../../../newShared/types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {
    AddIntegration,
    DeleteIntegration,
    DownloadLog,
    DownloadLogForPreview,
    GetAppHistory,
    GetAppLogs,
    GetDataByCollection,
    GetDataForCreateApp,
    GetInitIntegrationsData,
    GetIntegrationAppById,
    StartResearch,
    UpdateIntegration
} from "./actions";
import {ENDPOINT_MAPPING_NAMES_TYPE, IN_PROGRESS_STATUS} from "../constants";

const defaultPageInfo: TPageInfo = {
    page: 0,
    count: 0,
    total: 0,
    sort: ''
}

export const initialState: integrationsInitialState = {
    installedApps: [],
    availableApps: [],
    selectedApp: {
        app: null,
        details: null,
        selectedCollection: null,
        collectionData: [],
        collectionDataPageInfo: defaultPageInfo,
        appHistory: [],
        historyPageInfo: defaultPageInfo,
        selectedCollectionObject: null,
        logsData: {
            logs: [],
            pageInfo: defaultPageInfo,
            selectedLog: null,
            selectedLogResponse: ''
        }
    },
    loadings: {
        getInstalledAndAvailableApps: false,
        getInstalledAppById: false,
        getDataByCollection: false,
        getHistory: false,
        addIntegration: false,
        isLoadingDeleteIntegration: false,
        runIntegration: false,
        updateIntegration: false,
        vendorsAndEmployeesForCreate: false,
        logsExact: false,
        logsList: false,
        logsDownload: false
    },
    dialogs: {
        deleteIntegration: false,
        errorReason: {
            isOpen: false,
            history: null,
        },
        addDialogs: {
            gworkspace: {
                isOpen: false,
                app: null
            },
            github: {
                isOpen: false,
                app: null
            },
            confluence: {
                isOpen: false,
                app: null
            },
            jira: {
                isOpen: false,
                app: null
            },
            awssecurityhub: {
                isOpen: false,
                app: null
            },
            aws_config: {
                isOpen: false,
                app: null
            },
            awscloudtrail: {
                isOpen: false,
                app: null
            },
            awskms: {
                isOpen: false,
                app: null
            },
            awsiam: {
                isOpen: false,
                app: null
            },
            awsec2: {
                isOpen: false,
                app: null
            },
            awss3: {
                isOpen: false,
                app: null
            },
            awsrds: {
                isOpen: false,
                app: null
            },
            azuread: {
                isOpen: false,
                app: null
            },
            intune: {
                isOpen: false,
                app: null
            },
            bamboohr: {
                isOpen: false,
                app: null
            },
            zendesk: {
                isOpen: false,
                app: null
            },
            onelogin: {
                isOpen: false,
                app: null
            },
            cloudflare: {
                isOpen: false,
                app: null
            },
            salesforce: {
                isOpen: false,
                app: null
            },
        },
        appInDev: false
    },
    additionalDataForCreateIntegration: {
        vendors: [],
        vendorSettings: {
            risk: [],
            type: [],
            customFields: [],
            serviceProvided: [],
        },
    },
}

export const newIntegrationsSlice = createSlice({
    name: 'newIntegration',
    initialState,
    reducers: {
        openAddIntegrationDialog: (slice, {payload}: {payload: keyof addDialogType}) => {
            const currApp = slice.availableApps.find(e => e.endpointMappingName === payload);
            if(currApp){
                slice.dialogs.addDialogs[payload] = {
                    isOpen: true,
                    app: currApp
                };
            }
        },
        hideAddIntegrationDialog: (slice, {payload}: {payload: keyof addDialogType}) => {
            slice.dialogs.addDialogs[payload] = {
                isOpen: false,
                app: null
            };
        },
        setSelectedApplication: (slice, {payload}: {payload: {app: availableAppType, details: installedAppType}}) => {
            slice.selectedApp.app = payload.app;
            slice.selectedApp.details = payload.details;
        },
        deselectApplication: (slice) => {
            slice.selectedApp = {
                app: null,
                details: null,
                selectedCollection: null,
                collectionData: [],
                collectionDataPageInfo: defaultPageInfo,
                appHistory: [],
                historyPageInfo: defaultPageInfo,
                selectedCollectionObject: null,
                logsData: {
                    logs: [],
                    pageInfo: defaultPageInfo,
                    selectedLog: null,
                    selectedLogResponse: ''
                }
            }
        },
        eraseAppsBeforeFetch: (slice) => {
            slice.availableApps = [];
            slice.installedApps = [];
        },
        eraseCollectionDataBeforeFetch: (slice) => {
            slice.selectedApp.selectedCollectionObject = null;
            slice.selectedApp.collectionData = [];
        },
        setSelectedCollectionObject: (slice, {payload}: {payload: {[key: string]: any}}) => {
            slice.selectedApp.selectedCollectionObject = payload;
        },
        deselectCollectionObject: (slice) => {slice.selectedApp.selectedCollectionObject = null},

        //
        openDeleteIntegrationDialog: (slice) => {slice.dialogs.deleteIntegration = true},
        hideDeleteIntegrationDialog: (slice) => {slice.dialogs.deleteIntegration = false},

        openAppInDevDialog: (slice) => {slice.dialogs.appInDev = true},
        hideAppInDevDialog: (slice) => {slice.dialogs.appInDev = false},

        openErrorReasonDialog: (slice, {payload}: {payload: appHistory}) => {slice.dialogs.errorReason = {isOpen: true, history: payload}},
        hideErrorReasonDialog: (slice) => {slice.dialogs.errorReason = {isOpen: false, history: null}},

        selectLog: (slice, {payload}: {payload: integrationLogType}) => {
            if(slice.selectedApp){
                slice.selectedApp.logsData.selectedLog = payload;
            }
        },
        deselectLog: (slice) => {slice.selectedApp.logsData.selectedLog = null},
        deselectLogFetchResult: (slice) => {slice.selectedApp.logsData.selectedLogResponse = ''},


        cleanUp: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            //GetInitIntegrationsData
            .addCase(GetInitIntegrationsData.pending, (slice) => {
                slice.loadings.getInstalledAndAvailableApps = true;
            })
            .addCase(GetInitIntegrationsData.rejected, (slice) => {
                slice.loadings.getInstalledAndAvailableApps = false;
            })
            .addCase(GetInitIntegrationsData.fulfilled, (slice, {payload}) => {
                slice.loadings.getInstalledAndAvailableApps = false;
                slice.availableApps = payload.availableApps;
                slice.installedApps = payload.installedApps;
            })
        //GetIntegrationAppById
            .addCase(GetIntegrationAppById.pending, (slice) => {
                slice.loadings.getInstalledAppById = true;
            })
            .addCase(GetIntegrationAppById.rejected, (slice) => {
                slice.loadings.getInstalledAppById = false;
            })
            .addCase(GetIntegrationAppById.fulfilled, (slice, {payload}) => {
                slice.loadings.getInstalledAppById = false;
                const currAvailableApp = payload.availableApps.find(e => e.id === payload.app.detailsId);
                if(currAvailableApp){
                    slice.selectedApp.app = currAvailableApp;
                    slice.selectedApp.details = payload.app;
                }
            })
        //GetDataByCollection
            .addCase(GetDataByCollection.pending, (slice) => {
                slice.loadings.getDataByCollection = true;
            })
            .addCase(GetDataByCollection.rejected, (slice) => {
                slice.loadings.getDataByCollection = false;
            })
            .addCase(GetDataByCollection.fulfilled, (slice, {payload}) => {
                slice.loadings.getDataByCollection = false;
                if (payload.pageInfo.page === 0) {
                    slice.selectedApp.collectionData = payload.data;
                } else {
                    if (slice.selectedApp.collectionDataPageInfo.page < payload.pageInfo.page) {
                        slice.selectedApp.collectionData = [...slice.selectedApp.collectionData, ...payload.data];
                    } else {
                        slice.selectedApp.collectionData = [...payload.data, ...slice.selectedApp.collectionData];
                    }
                }
                slice.selectedApp.collectionDataPageInfo = payload.pageInfo;
            })
        //GetAppHistory
            .addCase(GetAppHistory.pending, (slice, {meta: {arg: {data: {appId}}}}) => {
                slice.loadings.getHistory = true;
                if (slice.selectedApp?.app?.id !== appId) {
                    slice.selectedApp.appHistory = [];
                }
            })
            .addCase(GetAppHistory.rejected, (slice) => {
                slice.loadings.getHistory = false;
            })
            .addCase(GetAppHistory.fulfilled, (slice, {payload}) => {
                slice.loadings.getHistory = false;
                if (payload.pageInfo.page === 0) {
                    slice.selectedApp.appHistory = payload.histories;
                } else {
                    if (slice.selectedApp.historyPageInfo.page < payload.pageInfo.page) {
                        slice.selectedApp.appHistory = [...slice.selectedApp.appHistory, ...payload.histories];
                    } else {
                        slice.selectedApp.appHistory = [...payload.histories, ...slice.selectedApp.appHistory];
                    }
                }
                slice.selectedApp.historyPageInfo = payload.pageInfo;
            })
        //UpdateIntegration
            .addCase(UpdateIntegration.pending, (slice) => {
                slice.loadings.updateIntegration = true;
            })
            .addCase(UpdateIntegration.rejected, (slice) => {
                slice.loadings.updateIntegration = false;
            })
            .addCase(UpdateIntegration.fulfilled, (slice, {payload}) => {
                slice.loadings.updateIntegration = false;
                if(slice.selectedApp && slice.selectedApp.details){
                    slice.selectedApp.details.name = payload.name;
                }
                slice.installedApps = slice.installedApps.map(e => e.detailsId === payload.detailsId ? {...e, name: payload.name} : e);
            })
        //DeleteIntegration
            .addCase(DeleteIntegration.pending, (slice) => {
                slice.loadings.isLoadingDeleteIntegration = true;
            })
            .addCase(DeleteIntegration.rejected, (slice) => {
                slice.loadings.isLoadingDeleteIntegration = false;
            })
            .addCase(DeleteIntegration.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingDeleteIntegration = false;
                slice.selectedApp = {
                    app: null,
                    details: null,
                    selectedCollection: null,
                    collectionData: [],
                    collectionDataPageInfo: defaultPageInfo,
                    appHistory: [],
                    historyPageInfo: defaultPageInfo,
                    selectedCollectionObject: null,
                    logsData: {
                        logs: [],
                        pageInfo: defaultPageInfo,
                        selectedLog: null,
                        selectedLogResponse: ''
                    }
                }
                slice.installedApps = slice.installedApps.filter(e => e.detailsId !== payload);

                slice.dialogs.deleteIntegration = false;
            })
        //StartResearch
            .addCase(StartResearch.pending, (slice) => {
                slice.loadings.runIntegration = true;
            })
            .addCase(StartResearch.rejected, (slice) => {
                slice.loadings.runIntegration = false;
            })
            .addCase(StartResearch.fulfilled, (slice, {payload}) => {
                slice.loadings.runIntegration = false;
                if(slice.selectedApp.details){
                    slice.selectedApp.details.status = IN_PROGRESS_STATUS;
                }
                slice.installedApps = slice.installedApps.map(e => e.detailsId !== payload ? {...e, status: IN_PROGRESS_STATUS} : e);
            })
        //AddIntegration
            .addCase(AddIntegration.pending, (slice) => {
                slice.loadings.addIntegration = true;
            })
            .addCase(AddIntegration.rejected, (slice) => {
                slice.loadings.addIntegration = false;
            })
            .addCase(AddIntegration.fulfilled, (slice: integrationsInitialState, {payload}) => {
                slice.loadings.addIntegration = false;

                slice.dialogs.addDialogs[payload.endpointMappingName as ENDPOINT_MAPPING_NAMES_TYPE].isOpen = false;
            })
        //GetDataForCreateApp
            .addCase(GetDataForCreateApp.pending, (slice, {meta}) => {
                slice.loadings.vendorsAndEmployeesForCreate = true;
            })
            .addCase(GetDataForCreateApp.rejected, (slice) => {
                slice.loadings.vendorsAndEmployeesForCreate = false;
            })
            .addCase(GetDataForCreateApp.fulfilled, (slice, {payload}) => {
                slice.loadings.vendorsAndEmployeesForCreate = false;
                slice.additionalDataForCreateIntegration.vendors = payload.vendors;
                slice.additionalDataForCreateIntegration.vendorSettings = payload.vendorSettings;
            })
        //GetAppLogs
            .addCase(GetAppLogs.pending, (slice, {meta: {arg: {data: {appId}}}}) => {
                slice.loadings.logsList = true;
                if (slice.selectedApp?.app?.id !== appId) {
                    slice.selectedApp.logsData.logs = [];
                }
                // if (page === 0) {
                //     slice.selectedApp.logsData.logs = [];
                // }
            })
            .addCase(GetAppLogs.rejected, (slice) => {
                slice.loadings.logsList = false;
            })
            .addCase(GetAppLogs.fulfilled, (slice, {payload}) => {
                slice.loadings.logsList = false;
                if (payload.pageInfo.page === 0) {
                    slice.selectedApp.logsData.logs = payload.logs;
                } else {
                    if (slice.selectedApp.logsData.pageInfo.page < payload.pageInfo.page) {
                        slice.selectedApp.logsData.logs = [...slice.selectedApp.logsData.logs, ...payload.logs];
                    } else {
                        slice.selectedApp.logsData.logs = [...payload.logs, ...slice.selectedApp.logsData.logs];
                    }
                }
                slice.selectedApp.logsData.pageInfo = payload.pageInfo;
            })
        //DownloadReport
            .addCase(DownloadLog.pending, (slice) => {
                slice.loadings.logsDownload = true;
            })
            .addCase(DownloadLog.rejected, (slice) => {
                slice.loadings.logsDownload = false;
            })
            .addCase(DownloadLog.fulfilled, (slice, {payload}) => {
                slice.loadings.logsDownload = false;
            })
        //DownloadLogForPreview
            .addCase(DownloadLogForPreview.pending, (slice) => {
                slice.loadings.logsExact = true;
            })
            .addCase(DownloadLogForPreview.rejected, (slice) => {
                slice.loadings.logsExact = false;
            })
            .addCase(DownloadLogForPreview.fulfilled, (slice, {payload}) => {
                slice.loadings.logsExact = false;
                if(slice.selectedApp){
                    slice.selectedApp.logsData.selectedLogResponse = payload;
                }
            })
    }
});

export const newIntegrationReducer = newIntegrationsSlice.reducer;

export const {
    cleanUp,
    openAddIntegrationDialog,
    hideAddIntegrationDialog,
    setSelectedApplication,
    deselectApplication,
    eraseAppsBeforeFetch,

    openDeleteIntegrationDialog,
    hideDeleteIntegrationDialog,

    setSelectedCollectionObject,
    deselectCollectionObject,
    eraseCollectionDataBeforeFetch,

    openAppInDevDialog,
    hideAppInDevDialog,

    openErrorReasonDialog,
    hideErrorReasonDialog,

    deselectLogFetchResult,

    selectLog,
    deselectLog
} = newIntegrationsSlice.actions;

const selectSelf = (state: AppState):integrationsInitialState => state.newIntegration as integrationsInitialState;

export const availableApps = createSelector(selectSelf, state => state.availableApps);
export const installedApps = createSelector(selectSelf, state => state.installedApps);
export const dialogs = createSelector(selectSelf, state => state.dialogs);
export const loadings = createSelector(selectSelf, state => state.loadings);
export const selectedApp = createSelector(selectSelf, state => state.selectedApp);
export const additionalDataForCreateIntegration = createSelector(selectSelf, state => state.additionalDataForCreateIntegration);
export const logsData = createSelector(selectSelf, state => state.selectedApp.logsData);

