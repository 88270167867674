import {Flex} from "../../../../../../newShared/components/Layouts";
import {IconButton, Typography} from "@mui/material";
import colors from "../../../../../../newShared/theme/colors";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";

export const ExpandableContainer = ({text, isOpen, toggle, disabled = false, error = false, margin = '16px 0 0 0',  children}: {isOpen: boolean, toggle: () => void, text: string, disabled?: boolean, error?: boolean, margin?: string, children: JSX.Element[] | JSX.Element}) => {
    return(
        <Flex w={'100%'} direction={'column'} m={margin} p={'4px'}>
            <Flex ai={'center'}>
                <Typography sx={{fontSize: '16px', fontWeight: 700, color: !error ? colors.grayText : colors.warningActions.red}}>{text}</Typography>
                <IconButton onClick={toggle} disabled={disabled}>
                    {isOpen ?
                        <ExpandLessIcon/>
                        :
                        <ExpandMoreIcon/>
                    }
                </IconButton>
            </Flex>

            {isOpen && children}
        </Flex>
    )
}