import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import colors from "../../../../../../../newShared/theme/colors";
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useAcceptInviteSuccessDialog} from "../../../../hooks/useAcceptInviteSuccessDialog";
import {LABEL} from "../../../../../../../newShared/constants";

export const AcceptInviteSuccessDialog = () => {
    const {isOpen, handleClose, handleGoToPortal, portalAccess, mainAccess, workspaceName} = useAcceptInviteSuccessDialog();
    const {t} = useMainTranslation('', {keyPrefix: 'pathSettings.Invites'});
    const handleClickOnPortalButton = () => {
        handleGoToPortal();
        handleClose();
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Completed successfully')}</DialogTitle>
                <DialogContent sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: '10px'}}>
                    {mainAccess && <Typography variant={'*bodyText2'}>{t('You can now access {{workspaceName}} workspace!', {workspaceName})}</Typography>}
                    {portalAccess && <Typography variant={'*bodyText2_semibold'}>{t('Organization has granted you access to {{LABEL}} Portal.', {LABEL})}</Typography>}
                    {portalAccess && <Typography variant={'*bodyText2'}>{`Regulait ${LABEL} is a centralized hub where employees can access company-specific information, policies, training, self-service HR resources and stay up-to-date on updates.`}</Typography>}
                    {!portalAccess && !mainAccess &&
                        <>
                            <Typography variant={'*bodyText2'}>{t('You’ve been invited as a collaborator of {{workspaceName}} workspace.', {workspaceName})}</Typography>
                            <Typography variant={'*bodyText2_semibold'}>{`Please note that access to the Main App and ${LABEL} Portal has not been granted at this time. You can request access from the workspace admin.`}</Typography>
                        </>
                    }
                </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>
                    <Typography variant={'*btnLarge'} color={colors.primary.blue}>{t('Close')}</Typography>
                </Button>
                {portalAccess &&
                    <Button onClick={handleClickOnPortalButton} variant={'contained'} sx={{display: 'flex', gap: '10px'}}>
                        <Typography variant={'*btnLarge'} color={colors.text.white}>{t('Proceed to {{LABEL}} Portal', {LABEL})}</Typography>
                        <LaunchOutlinedIcon sx={{color: `${colors.text.white}`}} />
                    </Button>
                    }

            </DialogActions>
        </Dialog>
    )

}