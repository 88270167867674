import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {dialogs, hideSelectCategorySupervisor, loadings} from "../../../store/slice";
import {
    Exact,
    MainVicaCategoryCollaboratorInput,
    WorkspaceCollaboratorModel
} from "../../../../../../newShared/GQLTypes";
import {mainVicaUpdateCategorySupervisorAction} from "../../../store/actions";
import {getName} from "../../../../../../newShared/utils/text";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {TApolloErrors} from "../../../../../../newShared/utils/asyncThunk/types";

export const useSelectSupervisorDialog = () => {
    const dispatch = useDispatch();
    const {category, isOpen} = useSelector(dialogs).selectCategorySupervisor;
    const {updateCategorySupervisor} = useSelector(loadings);
    const {setMessage, isOpenMessageDialog} = useMessageDialog();

    const handleClose = () => {
        dispatch(hideSelectCategorySupervisor())
    }

    const [supervisor, setSupervisor] = useState<WorkspaceCollaboratorModel | null>(null);

    useEffect(() => {
        if(isOpen){
            category && category.supervisor && setSupervisor({publicId: category.supervisor.collaboratorId, email: category.supervisor.email, firstName: category.supervisor.fullName, lastName: null});
        }else{
            setSupervisor(null);
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const isOk = category && category.supervisor?.email !== supervisor?.email;

    const onSuccess = (request: Omit<Exact<{workspaceId: string, categoryId: string, supervisor: MainVicaCategoryCollaboratorInput}>, "workspaceId" | "organizationId">, response: {}, addictiveData?: ({} | undefined)) => {
        setMessage({title: 'Completed successfully', message: `Supervisor has been ${request.supervisor ? 'assigned' : 'unassigned'}`});
        handleClose();
    }

    const onError = (request: Omit<Exact<{workspaceId: string, categoryId: string, supervisor: MainVicaCategoryCollaboratorInput}>, "workspaceId" | "organizationId">, error: TApolloErrors, addictiveData?: ({} | undefined)) => {
        const error409 = error.e409?.[0];
        const error404 = error.e404?.[0];
        if(error409?.type === 'NOT_COLLABORATOR'){
            setMessage({title: 'Action conflict error', message: `Action not applicable! ${request.supervisor.fullName} is not collaborator.`});
        }
        if(error404){
            handleClose();
        }
    }

    const handleSubmit = () => {
        if(isOk && category && supervisor){
            dispatch(mainVicaUpdateCategorySupervisorAction({
                data: {
                    categoryId: category.name,
                    supervisor: {
                        fullName: getName(supervisor.firstName ?? '', supervisor.lastName ?? ''),
                        collaboratorId: supervisor.publicId,
                        email: supervisor.email,
                    }
                },
                onSuccess,
                onError
            }))
        }
    }

    return{
        isOpen: isOpen && !isOpenMessageDialog,
        category,
        handleClose,
        supervisor,
        setSupervisor,
        isLoading: updateCategorySupervisor,
        isOk,
        handleSubmit
    }
}