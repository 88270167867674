import {useDispatch, useSelector} from "react-redux";
import {
    dialogs,
    eraseQuestions,
    loadings,
    questionsToAddSelector,
    setAddExistingQuestionsAction
} from "../../store/slice";
import {mainTrainingsGetExamCategoriesAutocompleteApi, mainTrainingsGetExamsAutocompleteApi} from "../../api";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {
    MainQuestionsFullDataFilter,
    MainTrainingsQuestionsFullDataForImportModel,
    TrainingExamLevel,
    TrainingExamQuestionType,
} from "../../../../../newShared/GQLTypes";
import {defaultPageInfo, examLevelTitleMapper, questionTypeTitleAddQuestionDialogMapper} from "../../constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {CommonFilterType} from "../../../../../newShared/components/genericTable/types";
import {
    mainTrainingsGetQuestionsFullDataListForImportAction,
    mainTrainingsGetQuestionsShortDataListForImportWithFilterPaginationAction
} from "../../store/actions";
import {useScrollPagination} from "../../../../../newShared/hooks/useScrollPagination";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {useEffect, useState} from "react";

export const useAddExistingQuestionDialog = (onAddQuestions: (questions: MainTrainingsQuestionsFullDataForImportModel[]) => void, excludeExamId?: string) => {
    const [isStep1, setIsStep1] = useState(true);
    const dispatch = useDispatch();
    const {t} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.pathTrainings'})
    const {addExistingQuestions: {isOpen}} = useSelector(dialogs);
    const {questionsToAdd: isLoadingQuestions, fullDataQuestions: isLoadingConfirm} = useSelector(loadings);
    const {
        shortDataQuestions,
        selectedQuestions,
        pageInfo,
    } = useSelector(questionsToAddSelector);
    const {currentFiltersForFetch, currentSearchForFetch, clearFilters, isSearchAndFilterEmpty} = useGenericFiltersStorage();
    const fetchPaging = (page: number, count: number, filters: CommonFilterType, questionNameLike: string, clean?: boolean) => {
        dispatch(mainTrainingsGetQuestionsShortDataListForImportWithFilterPaginationAction({data: {pageRequest: {page, count: count || defaultPageInfo.count}, filter: {...filters, questionNameLike, excludeExamId: excludeExamId ? [excludeExamId] : []}}, clean}))
    }

    const filtersForFetchQuestionsFullData: () => MainQuestionsFullDataFilter[] = () => {
        const filters: MainQuestionsFullDataFilter[] = [];
        shortDataQuestions?.forEach(exam => {
            const filterToAdd: MainQuestionsFullDataFilter = {examId: exam.examId, questionIds: []};
            exam.questions?.forEach(question => {
                if(question.questionId && selectedQuestions.some(e => question.questionId === e.questionId)) {
                    filterToAdd.questionIds.push(question.questionId);
                }
            })
            if(filterToAdd.questionIds.length) {
                filters.push(filterToAdd)
            }
        })

        return filters;
    }
    const handleConfirm = () => {
        dispatch(mainTrainingsGetQuestionsFullDataListForImportAction({
            data: {
                filters: {
                    filters: filtersForFetchQuestionsFullData()
                },
            },
            onSuccess: (request, response, addictiveData) => {
                onAddQuestions(response.result);
                dispatch(setAddExistingQuestionsAction({isOpen: false}));
            }
        }))
    }
    const isConfirmButtonDisabled = () => !selectedQuestions.length;

    useEffect(() => {
        if(isOpen){
            fetchPaging(0, 10, {}, '');
            dispatch(eraseQuestions());
            setIsStep1(true);
        }else{
            clearFilters();
        }
        // eslint-disable-next-line
    }, [isOpen]);

    const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => {
            fetchPaging(page, count, currentFiltersForFetch, currentSearchForFetch);
        },
        arrayLength: shortDataQuestions.length,
        isLoading: isLoadingQuestions,
        pageInfo,
        cleanData: () => dispatch(eraseQuestions()),
        isOnTop: false,
        isNoMoreThanTotal: true,
    })
    const getTotalQuestions = (): number => {
        let result = 0;
        shortDataQuestions.forEach(e => {e.questions && (result += e.questions.length)})
        return result;
    }
    const handleClose = () => {
        dispatch(setAddExistingQuestionsAction({isOpen: false}));
        dispatch(eraseQuestions());
    }

    //Filters config
    const levelConfig: keyConfig<TrainingExamLevel> = {
        type: 'filter',
        key: 'examLevel',
        name: t('Level'),
        options: Object.values(TrainingExamLevel),
        getOptionLabel: (e) => examLevelTitleMapper[e],
        default: true,
    }

    const categoryConfig: keyConfig<string> = {
        type: 'filter',
        key: 'examCategory',
        name: t('Category'),
        getOptionLabel: (a) => a,
        getFilterValue: (a) => a,
        isOptionEqualToSearch: (a, search) => a.trim().toLowerCase().includes(search.trim().toLowerCase()),
        fetchOptions: (workspaceId, {page, count, search}) => {
            return mainTrainingsGetExamCategoriesAutocompleteApi({ workspaceId, pageRequest: {page, count}, search})
                .then(({result, pageInfo}) => ({values: result, pageInfo}))
        },
        default: true,
    }
    const questionTypeConfig: keyConfig<TrainingExamQuestionType> = {
        type: 'filter',
        key: 'questionType',
        name: t('Type'),
        options: Object.values(TrainingExamQuestionType),
        getOptionLabel: (a) => questionTypeTitleAddQuestionDialogMapper[a],
        getFilterValue: (a) => a,
        default: true,

    }
    const examNameConfig: keyConfig<string> = {
        type: 'filter',
        key: 'examName',
        name: t('Exam'),
        default: true,
        isOptionEqualToSearch: (a, search) => a.trim().toLowerCase().includes(search.trim().toLowerCase()),
        getOptionLabel: (a) => a,
        getFilterValue: (a) => a,
        fetchOptions: (workspaceId, {page, count, search}) => {
            return mainTrainingsGetExamsAutocompleteApi({ workspaceId, pageRequest: {page, count}, value: search, includeArchive: true})
                .then(({result, pageInfo}) => ({values: result.map(e => e.name), pageInfo}))
        },
    }
    const filters = [
        examNameConfig,
        categoryConfig,
        levelConfig,
        questionTypeConfig,

    ]

    return {
        bottomLoader,
        onScroll,
        scrollRef,
        shortDataQuestions,
        getTotalQuestions,
        handleClose,
        isOpen,
        selectedQuestions,
        fetchPaging,
        handleConfirm,
        isConfirmButtonDisabled,
        isLoadingConfirm,
        isStep1,
        setIsStep1,
        filters,
        isLoadingQuestions,
        isSearchAndFilterEmpty

    }
}