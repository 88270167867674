import {gql} from "graphql.macro";
import {kycFormFragment, kycRequestFragment, kycTemplateFieldFragment, kycTemplateFragment} from "./fragments";

export const kycGetTemplates = gql`
    query kycGetTemplates($organizationId: String!, $workspaceId: String!, $pageInfo: PaginationInput!) {
        kycGetTemplates(organizationId: $organizationId, workspaceId: $workspaceId, pageInfo: $pageInfo) {
            templates {
                ...kycTemplateFragment
            }
            pageInfo {
                page
                count
                total
                sort
            }
        }
    }
    ${kycTemplateFragment}
`

export const kycGetTemplateById = gql`
    query kycGetTemplateById($organizationId: String!, $workspaceId: String!, $id: String!) {
        kycGetTemplateById(organizationId: $organizationId, workspaceId: $workspaceId, id: $id) {
            ...kycTemplateFragment
        }
    }
    ${kycTemplateFragment}
`

export const kycGetTokenByTemplateId = gql`
    query kycGetTokenByTemplateId($organizationId: String!, $workspaceId: String!, $templateId: String!) {
        kycGetTokenByTemplateId(organizationId: $organizationId, workspaceId: $workspaceId, templateId: $templateId) {
            token
            redirectUrl
        }
    }
`

export const kycGenerateTokenByTemplateId = gql`
    mutation kycGenerateTokenByTemplateId($organizationId: String!, $workspaceId: String!, $templateId: String!, $redirectUrl: String!) {
        kycGenerateTokenByTemplateId(organizationId: $organizationId, workspaceId: $workspaceId, templateId: $templateId, redirectUrl: $redirectUrl) {
            token
            redirectUrl
        }
    }
`

export const kycUpdateTemplate = gql`
    mutation kycUpdateTemplate($organizationId: String!, $workspaceId: String!, $template: KycTemplateInput!) {
        kycUpdateTemplate(organizationId: $organizationId, workspaceId: $workspaceId, template: $template) {
            ...kycTemplateFragment
        }
    }
    ${kycTemplateFragment}
`

export const kycCreateTemplate = gql`
    mutation kycCreateTemplate($organizationId: String!, $workspaceId: String!, $template: KycTemplateInput!) {
        kycCreateTemplate(organizationId: $organizationId, workspaceId: $workspaceId, template: $template) {
            ...kycTemplateFragment
        }
    }
    ${kycTemplateFragment}
`

export const kycDeleteTemplate = gql`
    mutation kycDeleteTemplate($organizationId: String!, $workspaceId: String!, $id: String!) {
        kycDeleteTemplate(organizationId: $organizationId, workspaceId: $workspaceId, id: $id) {
            message
        }
    }
`

export const kycGetTemplateFields = gql`
    query kycGetTemplateFields($organizationId: String!, $workspaceId: String!) {
        kycGetTemplateFields(organizationId: $organizationId, workspaceId: $workspaceId) {
            company {...kycTemplateFieldFragment}
            person {...kycTemplateFieldFragment}
        }
    }
    ${kycTemplateFieldFragment}
`

export const kycSendRequestsToEmails = gql`
    mutation kycSendRequestsToEmails($organizationId: String!, $workspaceId: String!, $id: String!, $emails: [String!]!) {
        kycSendRequestsToEmails(organizationId: $organizationId, workspaceId: $workspaceId, id: $id, emails: $emails) {
            message
        }
    }
`

export const kycGetRequests = gql`
    query kycGetRequests($organizationId: String!, $workspaceId: String!, $pageInfo: PaginationInput!) {
        kycGetRequests(organizationId: $organizationId, workspaceId: $workspaceId, pageInfo: $pageInfo) {
            requests {
                ...kycRequestFragment
            }
            pageInfo {
                page
                count
                total
                sort
            }
        }
    }
    ${kycRequestFragment}
`

export const kycGetRequestById = gql`
    query kycGetRequestById($organizationId: String!, $workspaceId: String!, $id: String!) {
        kycGetRequestById(organizationId: $organizationId, workspaceId: $workspaceId, id: $id) {
            ...kycRequestFragment
        }
    }
    ${kycRequestFragment}
`

export const kycChangeRequestStatus = gql`
    mutation kycChangeRequestStatus($organizationId: String!, $workspaceId: String!, $id: String!, $status: String!) {
        kycChangeRequestStatus(organizationId: $organizationId, workspaceId: $workspaceId, id: $id, status: $status) {
            ...kycRequestFragment
        }
    }
    ${kycRequestFragment}
`

export const kycCancelRequestById = gql`
    mutation kycCancelRequestById($organizationId: String!, $workspaceId: String!, $id: String!) {
        kycCancelRequestById(organizationId: $organizationId, workspaceId: $workspaceId, id: $id) {
            message
        }
    }
`

export const kycAnalyseRestart = gql`
    mutation kycAnalyseRestart($organizationId: String!, $workspaceId: String!, $ids: [String!]!) {
        kycAnalyseRestart(organizationId: $organizationId, workspaceId: $workspaceId, ids: $ids) {
            message
        }
    }
`

export const kycDownloadRequestById = gql`
    query kycDownloadRequestById($organizationId: String!, $workspaceId: String!, $id: String!) {
        kycDownloadRequestById(organizationId: $organizationId, workspaceId: $workspaceId, id: $id)
    }
`

export const kycGetQuotes = gql`
    query kycGetQuotes($organizationId: String!, $workspaceId: String!) {
        kycGetQuotes(organizationId: $organizationId, workspaceId: $workspaceId) {
            count 
            total
            isLimitEnabled
        }
    }
`

export const kycSetLimit = gql`
    mutation kycSetLimit($organizationId: String!, $workspaceId: String!, $limitEnabled: Boolean!) {
        kycSetLimit(organizationId: $organizationId, workspaceId: $workspaceId, limitEnabled: $limitEnabled) {
            count
            total
            isLimitEnabled
        }
    }
`

// FORM without auth
export const kycGetFormByToken = gql`
    query kycGetFormByToken($token: String!) {
        kycGetFormByToken(token: $token) {
            ...kycFormFragment
        }
    }
    ${kycFormFragment}
`

export const kycSubmitFormByToken = gql`
    mutation kycSubmitFormByToken($token: String!, $form: KycFormInput!) {
        kycSubmitFormByToken(token: $token, form: $form) {
            message
            redirectUrl
        }
    }
`

export const kycRejectFormByToken = gql`
    mutation kycRejectFormByToken($token: String!) {
        kycRejectFormByToken(token: $token) {
            message
        }
    }
`
