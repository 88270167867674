import {useEffect} from "react";
import {GetFolderTree, GetShortDocumentsByFolderIdWithFilterPaging} from "../../store/actions";
import {cleanUp, folders, isLoading, openFolder, openTemplatesFolder, setOpenFolderNull} from "../../store/slice";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useRouteMatch} from "react-router";
import {
    CREATE_DOCUMENT_PATH,
    DOCUMENT_LIST_DEFAULT_COUNT,
    DOCUMENTS_FOLDER_NAME,
    GALLERY_FOLDER_NAME,
    GALLERY_LOCAL_ID,
    MANAGE_DOCUMENT_PATH,
    TRASH_FOLDER_NAME,
    TRASH_LOCAL_ID,
    TREE_GALLERY_PATH,
    TREE_PATH,
    TREE_TRASH_PATH
} from "../../constants";
import {useLocation} from "react-router-dom";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {getFolderAndPathFromPathIds} from "../../helpers";

export const useDocumentsMain = () => {
    const historyRoutes = useHistory();
    const dispatch = useDispatch();
    const path = useLocation().pathname;
    const isGallery = useRouteMatch(TREE_GALLERY_PATH)?.isExact;
    const _isTree = useRouteMatch(TREE_PATH);
    const _isModificatedTree = useRouteMatch<{'0'?: string}>(TREE_PATH + '/*');
    const isTrashPage = useRouteMatch(TREE_TRASH_PATH)?.isExact;
    const isCreateDoc = useRouteMatch(CREATE_DOCUMENT_PATH)?.isExact;
    const isManageDoc = useRouteMatch(MANAGE_DOCUMENT_PATH)?.isExact;
    const {isLoadingTree} = useSelector(isLoading);
    const folds = useSelector(folders);
    const {clearFilters} = useGenericFiltersStorage();

    const isTree = _isTree?.isExact || _isModificatedTree?.isExact;

    // const match = useRouteMatch<{'0'?: string}>();

    // useEffect(() => {
    //     clearFilters();
    //     //eslint-disable-next-line
    // }, [path]);


    useEffect(() => {
        dispatch(GetFolderTree());
        if(isGallery){
            //making this to select gallery in tree if path match
            dispatch(openFolder({
                folder: {id: GALLERY_LOCAL_ID, name: GALLERY_FOLDER_NAME, lastEditDate: '', children: []},
                path: [{name: GALLERY_FOLDER_NAME, id: GALLERY_LOCAL_ID}]
            }));
            // dispatch(GetFilesByFolderId({folderId: GALLERY_LOCAL_ID}));
            dispatch(GetShortDocumentsByFolderIdWithFilterPaging({data: {pageInfo: {page: 0, count: DOCUMENT_LIST_DEFAULT_COUNT}, folderId: GALLERY_LOCAL_ID, workspaceId: ''}}));
        } else if (isTrashPage) {
            dispatch(openFolder({
                folder: {id: TRASH_LOCAL_ID, name: TRASH_FOLDER_NAME, lastEditDate: '', children: []},
                path: [{name: TRASH_FOLDER_NAME, id: TRASH_LOCAL_ID}]
            }));
            dispatch(GetShortDocumentsByFolderIdWithFilterPaging({data: {pageInfo: {page: 0, count: DOCUMENT_LIST_DEFAULT_COUNT}, folderId: TRASH_LOCAL_ID, workspaceId: ''}}));
        }

        if(isTree){
            dispatch(setOpenFolderNull());
        }

        //TODO if manage doc page - fetch doc file by id in path

        return () => {
            dispatch(cleanUp());
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        clearFilters();
        // dispatch(setOpenFolderNull());

        const docFolder = folds.find(e => e.name === DOCUMENTS_FOLDER_NAME);
        if (docFolder) dispatch(openTemplatesFolder({
            folder: docFolder,
            path: [{name: docFolder.name, id: docFolder.id}]
        }));

        if(isGallery && !isLoadingTree ){

            dispatch(openFolder({
                folder: {id: GALLERY_LOCAL_ID, name: GALLERY_FOLDER_NAME, lastEditDate: '', children: []},
                path: [{name: GALLERY_FOLDER_NAME, id: GALLERY_LOCAL_ID}]
            }));

            // dispatch(GetFilesByFolderId({folderId: docFolder.id}))
            dispatch(GetShortDocumentsByFolderIdWithFilterPaging({data: {workspaceId: '', folderId: GALLERY_LOCAL_ID, pageInfo: {page: 0, count: DOCUMENT_LIST_DEFAULT_COUNT}}}))
        }

        if(isTrashPage && !isLoadingTree ){

            dispatch(openFolder({
                folder: {id: TRASH_LOCAL_ID, name: TRASH_FOLDER_NAME, lastEditDate: '', children: []},
                path: [{name: TRASH_FOLDER_NAME, id: TRASH_LOCAL_ID}]
            }));
            dispatch(GetShortDocumentsByFolderIdWithFilterPaging({data: {workspaceId: '', folderId: TRASH_LOCAL_ID, pageInfo: {page: 0, count: DOCUMENT_LIST_DEFAULT_COUNT}}}))

        }

        if(isTree && !isLoadingTree && folds.length > 0){
            const pathIds = _isModificatedTree?.params?.['0']?.split('/') || [];

            if(docFolder){
                if (pathIds.length > 0) {
                    const {folder, path} = getFolderAndPathFromPathIds([docFolder.id, ...pathIds], folds);

                    if (folder) {
                        dispatch(openFolder({folder: folder, path}));
                        dispatch(GetShortDocumentsByFolderIdWithFilterPaging({data: {workspaceId: '', folderId: folder.id, pageInfo: {page: 0, count: DOCUMENT_LIST_DEFAULT_COUNT}}}))
                    } else {
                        historyRoutes.replace(TREE_PATH)
                    }
                } else {
                    dispatch(openFolder({folder: docFolder, path: [{name: docFolder.name, id: docFolder.id}]}));
                    dispatch(GetShortDocumentsByFolderIdWithFilterPaging({data: {workspaceId: '', folderId: docFolder.id, pageInfo: {page: 0, count: DOCUMENT_LIST_DEFAULT_COUNT}}}))
                }
            }
        }
        //eslint-disable-next-line
    }, [path, isLoadingTree]);


    return{
        hideTreeComponent: isCreateDoc || isManageDoc
    }
}
