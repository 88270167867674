import {Box, Dialog, styled} from "@mui/material";
import colors from "../../../../../../../newShared/theme/colors";
import {TypographyBody2} from "../../../../../../../newShared/components/Inputs/styled";


export const CreateClientDialog = styled(Dialog)(() => ({
    // '.MuiPaper-root': {
    //     minWidth: '678px',
    //     maxHeight: '800px'
    // },
}));

export const TemplateBox = styled(Box, {
    shouldForwardProp: (propName) => (
        !["isSelected"].includes(propName.toString())
    )})(({isSelected}: {isSelected?: true}) => ({
    width: '100%',
    display: 'flex',
    marginTop: '10px',
    alignItems: 'center',
    justifyContent: 'space-between',
    // justifyContent: 'flex-start',
    padding: '8px',
    borderRadius: '4px',
    border: `1px solid ${isSelected ? colors.primary.blue : colors.stroke.grey}`,
    boxShadow: '0px 0px 1px 0px rgba(125, 151, 174, 0.02), 0px 2px 6px 0px rgba(125, 151, 174, 0.02), 0px 8px 16px 0px rgba(125, 151, 174, 0.08);',

}));

export const FileRowContainer = styled('div')({
    width: '100%',
    gap: '10px',
    display: "flex",
    alignItems: 'center',
    marginTop: '16px'
})

export const TitleBox = styled(Box)(() => ({
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
}));


export const PreviewTemplateContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    // marginTop: '16px',
    alignItems: 'center',
    maxWidth: '420px',
    margin: '0 auto'
})

export const FileDropContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
})

export const FileNameTypography = styled(TypographyBody2)(() => ({
    flexGrow: 1
}))
