import {Button, Divider, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import React, {FC} from "react";
import {TShortCollaborator} from "../../../types";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {CommonAvatar} from "../../../../../../newShared/components/Basic/CommonAvatar";
import colors from "../../../../../../newShared/theme/colors";


type TCollaboratorRowProps = {
    collaborator: TShortCollaborator,
    selectedCollaborators: TShortCollaborator[],
    onDelete: () => void,
    onSelect: () => void,
}

export const CollaboratorRow: FC<TCollaboratorRowProps> = ({collaborator, selectedCollaborators, onDelete, onSelect}) => {

    const {isMobile} = useMedia();
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'releaseDialog'});


    return (
        <Flex direction={'column'} w={'100%'}>
            <Flex  w={'100%'} ai={'center'}>
                <CommonAvatar name={`${collaborator.firstName} ${collaborator.lastName}`}/>

                <Flex direction={'column'} w={isMobile ? '50%' : '70%'} jc={'flex-start'}  style={{marginLeft: !revDir ? isMobile ?'6px' : '16px' : undefined, marginRight: revDir ? isMobile ?'6px' :  '16px' : undefined}}>
                    <Typography variant={'body2'} sx={{color: colors.text.dark}}>{`${collaborator.firstName} ${collaborator.lastName}`}</Typography>
                    <Typography variant={'body2'} sx={{color: colors.text.grey_dark}}>{collaborator.email}</Typography>
                </Flex>

                {selectedCollaborators.findIndex((rev) => rev.publicId === collaborator.publicId) > -1 ?
                    <Button variant={'text'}
                            size={isMobile ? 'small' : 'medium'}
                            onClick={onDelete}
                            sx={{color: colors.text.grey, ml: 'auto'}}
                            endIcon={<DeleteIcon />}>
                        {t('Added')}
                    </Button>
                    :
                    <Button variant={'text'}
                            size={isMobile ? 'small' : 'medium'}
                            sx={{ml: 'auto'}}
                            onClick={onSelect}
                            startIcon={<AddIcon />}>
                        {t('Add')}
                    </Button>
                }

            </Flex>
            <Divider variant={'fullWidth'} textAlign={'center'} sx={{color: colors.divider, marginTop: '12px', marginBottom: '12px'}} />
        </Flex>
    )
}