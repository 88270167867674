import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../../newShared/redux/rootReducer";
import {
    TAnalysis,
    TAssignment,
    TComment,
    TControl,
    TEvidence,
    TEvidenceAsset,
    TFramework,
    TSetCollectorViewDialog,
    TSetDeleteAssetDialog,
    TSetStatementDialog
} from "../../types";
import {TDialog, TPageInfo, TSetDialog} from "../../../../../newShared/types";
import {
    addCommentAction,
    addControlAssignmentAction,
    deleteControlAssignmentAction,
    downloadCollectorAssetPdfAction,
    downloadEvidenceFileAction,
    frameworkGetDelegatedTaskById,
    frameworksApproveTaskById,
    frameworksCancelTaskById,
    frameworksDeclineTaskById,
    getAnalysisHistoryByHistoryIdAction,
    getAnalysisHistoryDetailsFRAction,
    getAnalysisHistoryFRAction,
    getAutoAssetDataAction,
    getCommentsAction,
    getControlAssignmentsAction,
    getControlByIdFrameworkIdAction,
    getStatementByIdAction,
    mainFrameworksCreateDelegatedTaskAction,
    mainFrameworksGetDelegatedTasksWithFilterPaginationAction,
    removeEvidenceAssetAction,
    setEvidenceStatusAction,
    uploadEvidenceAssetAction
} from "./actions";
import {setPaginationInStore} from "../../../../../newShared/hooks/useScroll/helpers";
import {DelegatedTaskModel} from "../../../../../newShared/GQLTypes";
import {minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {defaultPageInfo} from "../../../../authWorkspacesCookies/settings/store/slice";
import {minMaxLoadedPageDefault} from "../../../../../newShared/components/genericTable/constants";
import {handlePagination} from "../../../../../newShared/components/genericTable/helpers";


export type TFrameworkControlSlice = {
    framework: TFramework | null,
    control: TControl | null,

    isLoading: boolean,

    evidences: {
        evidences: TEvidence[],
        isLoadingFiles: string[],
        isLoadingEvidence: boolean,
        isUploadingFile: boolean,

        isChangingStatus: boolean,
    },
    comments: {
        comments: (TComment | null)[],
        pageInfo: TPageInfo,
        isLoading: boolean,
    },
    analysis: {
        analysis: (TAnalysis | null)[],
        selected: TAnalysis | null,
        pageInfo: TPageInfo,
        isLoading: boolean,
        isLoadingCurrent: boolean,
    },
    delegatedTasks: {
        isLoadingList: boolean;
        isLoadingExact: boolean;
        tasks: DelegatedTaskModel[];
        pageInfo: TPageInfo;
        minMaxLoadedPage: minMaxLoadedPageType;
        isLoadingCancel: boolean;
        isLoadingDecline: boolean;
        isLoadingApprove: boolean;
        exactDelegatedTask: DelegatedTaskModel | null;
    },

    dialogs: {
        // exactDelegatedTask: TDialog & {
        //     id: string | null,
        // }
        exactDelegatedTask: TDialog & {
            id: string | null,
        },
        createDelegatedTaskDialog: {
            isOpen: boolean,
            isLoadingCreate: boolean,
        },
        deleteAsset: TDialog & {
            id: string | null,
        },
        statement: TDialog & {
            assetId: string | null,
            evidenceId: string | null,
            asset: TEvidenceAsset | null,
        },
        collectorView: TDialog & {
            assetId: string | null,
            keys: string[],
            data: ({[key: string]: string | string[]} | null)[],
            pageInfo: TPageInfo
        },
        collaborators: TDialog & {
            collaborators: TAssignment[],
            isAddingIds: string[],
            isDeletingIds: string[],
        },
        newComment: TDialog & {

        },
        cancelDelegatedTask: TDialog & {
            task: DelegatedTaskModel | null,
        }
    },
}

const initialState: TFrameworkControlSlice = {
    framework: null,
    control: null,

    isLoading: false,

    evidences: {
        evidences: [],
        isLoadingFiles: [],
        isLoadingEvidence: false,
        isUploadingFile: false,

        isChangingStatus: false,
    },
    comments: {
        comments: [],
        pageInfo: { page: 0,count: 50, total: 0},
        isLoading: false,
    },
    analysis: {
        analysis: [],
        selected: null,
        pageInfo: { page: 0,count: 50, total: 0},
        isLoading: false,
        isLoadingCurrent: false,
    },

    delegatedTasks: {
        tasks: [],
        isLoadingList: false,
        pageInfo: defaultPageInfo,
        minMaxLoadedPage: minMaxLoadedPageDefault,

        isLoadingCancel: false,
        isLoadingExact: false,
        isLoadingDecline: false,
        exactDelegatedTask: null,
        isLoadingApprove: false
    },

    dialogs: {
        // exactDelegatedTask: {
        //     isOpen: true,
        //     isLoading: false,
        //     id: 'asd',
        // },
        exactDelegatedTask: {
            isOpen: false,
            isLoading: false,
            id: null,
        },
        createDelegatedTaskDialog: {
            isOpen: false,
            isLoadingCreate: false,
        },
        deleteAsset: {
            isOpen: false,
            isLoading: false,
            id: null,
        },
        collectorView: {
            isOpen: false,
            isLoading: false,
            assetId: null,
            data: [],
            keys: [],
            pageInfo: {page: 0, count: 50, total: 0}
        },
        statement: {
            isOpen: false,
            isLoading: false,
            assetId: null,
            evidenceId: null,
            asset: null,
        },
        collaborators: {
            isOpen: false,
            isLoading: false,
            isAddingIds: [],
            isDeletingIds: [],
            collaborators: [],
        },
        newComment: {
            isOpen: false,
            isLoading: false,

        },
        cancelDelegatedTask: {
            isOpen: false,
            task: null,
            isLoading: false,
        }
    },
}

const Slice = createSlice({
    name: 'currentFramework',
    initialState,
    reducers: {
        openCreateDelegatedTaskDialog(slice) {
            slice.dialogs.createDelegatedTaskDialog.isOpen = true;
        },
        hideCreateDelegatedTaskDialog(slice) {
            slice.dialogs.createDelegatedTaskDialog.isOpen = false;
        },
        cleanAction(slice: TFrameworkControlSlice) {
            slice.framework = initialState.framework;
            slice.control = initialState.control;

            slice.evidences = initialState.evidences;
            slice.comments = initialState.comments;
            slice.analysis = initialState.analysis;

            slice.dialogs = initialState.dialogs;
        },
        cleanCommentsAction(slice: TFrameworkControlSlice) {
            slice.comments = initialState.comments;
        },
        cleanAnalysisAction(slice: TFrameworkControlSlice) {
            slice.analysis = initialState.analysis;
        },


        setCollectorViewDialogAction(slice: TFrameworkControlSlice, {payload}: {payload: TSetCollectorViewDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.collectorView.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.collectorView.isLoading = payload.isLoading;
            if (payload.assetId !== undefined) slice.dialogs.collectorView.assetId = payload.assetId;
            if (payload.data !== undefined) slice.dialogs.collectorView.data = payload.data;
            if (payload.keys !== undefined) slice.dialogs.collectorView.keys = payload.keys;
            if (payload.pageInfo !== undefined) slice.dialogs.collectorView.pageInfo = payload.pageInfo;

            if (payload.clear) slice.dialogs.collectorView = initialState.dialogs.collectorView;
        },
        setDeleteAssetDialogAction(slice: TFrameworkControlSlice, {payload}: {payload: TSetDeleteAssetDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.deleteAsset.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.deleteAsset.isLoading = payload.isLoading;
            if (payload.id !== undefined) slice.dialogs.deleteAsset.id = payload.id;

            if (payload.clear) slice.dialogs.deleteAsset = initialState.dialogs.deleteAsset;
        },
        setStatementDialogAction(slice: TFrameworkControlSlice, {payload}: {payload: TSetStatementDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.statement.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.statement.isLoading = payload.isLoading;
            if (payload.assetId !== undefined) slice.dialogs.statement.assetId = payload.assetId;
            if (payload.evidenceId !== undefined) slice.dialogs.statement.evidenceId = payload.evidenceId;

            if (payload.clear) slice.dialogs.statement = initialState.dialogs.statement;
        },
        setNewCommentDialogAction(slice: TFrameworkControlSlice, {payload}: {payload: TSetDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.newComment.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.newComment.isLoading = payload.isLoading;

            if (payload.clear) slice.dialogs.newComment = initialState.dialogs.newComment;
        },
        setCollaboratorsDialogAction(slice: TFrameworkControlSlice, {payload}: {payload: TSetDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.collaborators.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.collaborators.isLoading = payload.isLoading;

            if (payload.clear) slice.dialogs.collaborators = initialState.dialogs.collaborators;
        },
        setCancelDelegatedTaskDialogAction(slice: TFrameworkControlSlice, {payload}: {payload: Partial<TFrameworkControlSlice["dialogs"]["cancelDelegatedTask"]> & {clear?: boolean}}) {
            if (payload.isOpen !== undefined) slice.dialogs.cancelDelegatedTask.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.cancelDelegatedTask.isLoading = payload.isLoading;
            if (payload.task !== undefined) slice.dialogs.cancelDelegatedTask.task = payload.task;

            if (payload.clear) slice.dialogs.cancelDelegatedTask = initialState.dialogs.cancelDelegatedTask;
        },

        // openExactDelegatedTaskDialog(slice: TFrameworkControlSlice, {payload}: {payload: string}){
        //     slice.dialogs.exactDelegatedTask = {isOpen: true, isLoading: false, id: payload}
        // },
        // hideExactDelegatedTaskDialog(slice: TFrameworkControlSlice){
        //     slice.dialogs.exactDelegatedTask = {isOpen: false, isLoading: false, id: null}
        // },
        replaceDelegatedTasksMinMaxLoadedPage: (slice, { payload }: { payload: minMaxLoadedPageType }) => {
            slice.delegatedTasks.minMaxLoadedPage = payload;
        },
        eraseDelegatedTasks: (slice) => {
            slice.delegatedTasks.tasks = [];
            slice.delegatedTasks.pageInfo = defaultPageInfo;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getControlByIdFrameworkIdAction.pending, (slice: TFrameworkControlSlice) => {
                slice.isLoading = true;
            })
            .addCase(getControlByIdFrameworkIdAction.rejected, (slice: TFrameworkControlSlice) => {
                slice.isLoading = false;
            })
            .addCase(getControlByIdFrameworkIdAction.fulfilled, (slice: TFrameworkControlSlice, {payload}) => {
                slice.isLoading = false;
                slice.evidences.evidences = payload.resp.evidences;
                slice.control = payload.resp.control;
                slice.framework = payload.resp.framework;
            })

            .addCase(setEvidenceStatusAction.pending, (slice: TFrameworkControlSlice) => {
                slice.evidences.isChangingStatus = true;
            })
            .addCase(setEvidenceStatusAction.rejected, (slice: TFrameworkControlSlice) => {
                slice.evidences.isChangingStatus = false;
            })
            .addCase(setEvidenceStatusAction.fulfilled, (slice: TFrameworkControlSlice, {payload, meta: {arg}}) => {
                slice.evidences.isChangingStatus = false;

                const index = slice.evidences.evidences.findIndex(e => e.id === arg.data.evidenceId);
                if (index !== -1) {
                    slice.evidences.evidences[index].status = arg.data.status;
                }
            })

            .addCase(downloadEvidenceFileAction.pending, (slice: TFrameworkControlSlice, payload) => {
                slice.evidences.isLoadingFiles.push(payload.meta.arg.data.fileId);
            })
            .addCase(downloadEvidenceFileAction.rejected, (slice: TFrameworkControlSlice, payload) => {
                slice.evidences.isLoadingFiles = slice.evidences.isLoadingFiles.filter(e => e !== payload.meta.arg.data.fileId);
            })
            .addCase(downloadEvidenceFileAction.fulfilled, (slice: TFrameworkControlSlice, {payload}) => {
                slice.evidences.isLoadingFiles = slice.evidences.isLoadingFiles.filter(e => e !== payload.data.fileId);
            })

            .addCase(downloadCollectorAssetPdfAction.pending, (slice: TFrameworkControlSlice, payload) => {
                slice.evidences.isLoadingFiles.push(payload.meta.arg.data.assetId);
            })
            .addCase(downloadCollectorAssetPdfAction.rejected, (slice: TFrameworkControlSlice, payload) => {
                slice.evidences.isLoadingFiles = slice.evidences.isLoadingFiles.filter(e => e !== payload.meta.arg.data.assetId);
            })
            .addCase(downloadCollectorAssetPdfAction.fulfilled, (slice: TFrameworkControlSlice, {payload}) => {
                slice.evidences.isLoadingFiles = slice.evidences.isLoadingFiles.filter(e => e !== payload.data.assetId);
            })

            .addCase(uploadEvidenceAssetAction.pending, (slice: TFrameworkControlSlice, payload) => {
                slice.evidences.isUploadingFile = true;
                slice.dialogs.statement.isLoading = true;
            })
            .addCase(uploadEvidenceAssetAction.rejected, (slice: TFrameworkControlSlice, payload) => {
                slice.evidences.isUploadingFile = false;
                slice.dialogs.statement.isLoading = true;
            })
            .addCase(uploadEvidenceAssetAction.fulfilled, (slice: TFrameworkControlSlice, {payload}) => {
                slice.evidences.isUploadingFile = false;
                slice.dialogs.statement = initialState.dialogs.statement;

                const tmp = slice.evidences.evidences;
                const index = tmp.findIndex(e => e.id === payload.data.data.evidenceId)
                if (index > -1) {
                    switch (payload.resp.type) {
                        case 'CUSTOM': {
                            slice.evidences.evidences[index].statements.push(payload.resp);
                            break;
                        }
                        case 'COLLECTOR': {
                            slice.evidences.evidences[index].automations.push(payload.resp);
                            break;
                        }
                        case 'FILE': {
                            slice.evidences.evidences[index].files.push(payload.resp);
                            break;
                        }
                    }
                    slice.evidences.evidences = tmp;
                }
            })

            .addCase(removeEvidenceAssetAction.pending, (slice: TFrameworkControlSlice, payload) => {
                slice.dialogs.deleteAsset.isLoading = true;
            })
            .addCase(removeEvidenceAssetAction.rejected, (slice: TFrameworkControlSlice, payload) => {
                slice.dialogs.deleteAsset.isLoading = false;
            })
            .addCase(removeEvidenceAssetAction.fulfilled, (slice: TFrameworkControlSlice, {payload}) => {
                slice.dialogs.deleteAsset = initialState.dialogs.deleteAsset;

                const tmp = slice.evidences.evidences;
                const index = tmp.findIndex(e => e.statements.some(e => e.id === payload.data.assetId) || e.files.some(e => e.id === payload.data.assetId) || e.automations.some(e => e.id === payload.data.assetId))
                if (index > -1) {
                    tmp[index].automations = tmp[index].automations.filter(e => e.id !== payload.data.assetId);
                    tmp[index].files = tmp[index].files.filter(e => e.id !== payload.data.assetId);
                    tmp[index].statements = tmp[index].statements.filter(e => e.id !== payload.data.assetId);

                    slice.evidences.evidences = tmp;
                }
            })

            .addCase(getAutoAssetDataAction.pending, (slice: TFrameworkControlSlice, payload) => {
                slice.dialogs.collectorView.isLoading = true;
            })
            .addCase(getAutoAssetDataAction.rejected, (slice: TFrameworkControlSlice, payload) => {
                slice.dialogs.collectorView.isLoading = false;
            })
            .addCase(getAutoAssetDataAction.fulfilled, (slice: TFrameworkControlSlice, {payload}) => {
                slice.dialogs.collectorView.isLoading = false;
                slice.dialogs.collectorView.keys = payload.resp.keys;

                const {array, pageInfo} = setPaginationInStore<{[key: string]: string | string[]}>(
                    slice.dialogs.collectorView.pageInfo,
                    slice.dialogs.collectorView.data,
                    payload.resp.pageInfo,
                    payload.resp.data,
                    false
                );
                slice.dialogs.collectorView.data = array;
                slice.dialogs.collectorView.pageInfo = pageInfo;
            })

            .addCase(getCommentsAction.pending, (slice: TFrameworkControlSlice, payload) => {
                slice.comments.isLoading = true;
            })
            .addCase(getCommentsAction.rejected, (slice: TFrameworkControlSlice, payload) => {
                slice.comments.isLoading = false;
            })
            .addCase(getCommentsAction.fulfilled, (slice: TFrameworkControlSlice, {payload}) => {
                slice.comments.isLoading = false;

                const {array, pageInfo} = setPaginationInStore<TComment>(
                    slice.comments.pageInfo,
                    slice.comments.comments,
                    payload.resp.pageInfo,
                    payload.resp.comments,
                    payload.isNewOnTop
                );
                slice.comments.comments = array;
                slice.comments.pageInfo = pageInfo;
            })

            .addCase(addCommentAction.pending, (slice: TFrameworkControlSlice, payload) => {
                slice.dialogs.newComment.isLoading = true;
            })
            .addCase(addCommentAction.rejected, (slice: TFrameworkControlSlice, payload) => {
                slice.dialogs.newComment.isLoading = false;
            })
            .addCase(addCommentAction.fulfilled, (slice: TFrameworkControlSlice, {payload}) => {
                slice.dialogs.newComment = initialState.dialogs.newComment;
                if (slice.comments.comments.length + 1 > ((slice.comments.pageInfo.page + 1) * slice.comments.pageInfo.count)) {
                    slice.comments.pageInfo.page += 1;
                }
                slice.comments.pageInfo.total += 1;

                slice.comments.comments.unshift(payload.resp);
            })

            .addCase(getStatementByIdAction.pending, (slice: TFrameworkControlSlice, payload) => {
                slice.dialogs.statement.isLoading = true;
            })
            .addCase(getStatementByIdAction.rejected, (slice: TFrameworkControlSlice, payload) => {
                slice.dialogs.statement.isLoading = false;
            })
            .addCase(getStatementByIdAction.fulfilled, (slice: TFrameworkControlSlice, {payload}) => {
                slice.dialogs.statement.isLoading = false;
                slice.dialogs.statement.asset = payload.resp;
            })

            .addCase(getControlAssignmentsAction.pending, (slice: TFrameworkControlSlice, payload) => {
                slice.dialogs.collaborators.isLoading = true;
                slice.dialogs.collaborators.collaborators = [];
            })
            .addCase(getControlAssignmentsAction.rejected, (slice: TFrameworkControlSlice, payload) => {
                slice.dialogs.collaborators.isLoading = false;
            })
            .addCase(getControlAssignmentsAction.fulfilled, (slice: TFrameworkControlSlice, {payload}) => {
                slice.dialogs.collaborators.isLoading = false;
                slice.dialogs.collaborators.collaborators = payload.resp.assignments;
            })

            .addCase(addControlAssignmentAction.pending, (slice: TFrameworkControlSlice, payload) => {
                slice.dialogs.collaborators.isAddingIds.push(payload.meta.arg.data.userId);
            })
            .addCase(addControlAssignmentAction.rejected, (slice: TFrameworkControlSlice, payload) => {
                slice.dialogs.collaborators.isAddingIds = slice.dialogs.collaborators.isAddingIds.filter(e => e !== payload.meta.arg.data.userId);
            })
            .addCase(addControlAssignmentAction.fulfilled, (slice: TFrameworkControlSlice, {payload}) => {
                slice.dialogs.collaborators.isAddingIds = slice.dialogs.collaborators.isAddingIds.filter(e => e !== payload.data.userId);

                slice.control?.collaborators.push({
                    id: payload.data.userId,
                    name: slice.dialogs.collaborators.collaborators.find(e => e.id === payload.data.userId)?.name || ''
                })

                const tmp = slice.dialogs.collaborators.collaborators;
                const index = tmp.findIndex(e => e.id === payload.data.userId);
                if (index > -1) {
                    tmp[index].added = payload.resp.id;

                    slice.dialogs.collaborators.collaborators = tmp;
                }
            })

            .addCase(deleteControlAssignmentAction.pending, (slice: TFrameworkControlSlice, payload) => {
                slice.dialogs.collaborators.isDeletingIds.push(payload.meta.arg.data.assignmentId);
            })
            .addCase(deleteControlAssignmentAction.rejected, (slice: TFrameworkControlSlice, payload) => {
                slice.dialogs.collaborators.isDeletingIds = slice.dialogs.collaborators.isAddingIds.filter(e => e !== payload.meta.arg.data.assignmentId);
            })
            .addCase(deleteControlAssignmentAction.fulfilled, (slice: TFrameworkControlSlice, {payload}) => {
                slice.dialogs.collaborators.isDeletingIds = slice.dialogs.collaborators.isAddingIds.filter(e => e !== payload.data.assignmentId);

                if (slice.control) {
                    slice.control.collaborators = slice.control.collaborators
                        .filter(e => e.id !== slice.dialogs.collaborators.collaborators.find(c => c.added === payload.data.assignmentId)?.id)
                }

                const tmp = slice.dialogs.collaborators.collaborators;
                const index = tmp.findIndex(e => e.added === payload.data.assignmentId);
                if (index > -1) {
                    tmp[index].added = null;

                    slice.dialogs.collaborators.collaborators = tmp;
                }
            })

            .addCase(getAnalysisHistoryFRAction.pending, (slice: TFrameworkControlSlice, payload) => {
                slice.analysis.isLoading = true;
            })
            .addCase(getAnalysisHistoryFRAction.rejected, (slice: TFrameworkControlSlice, payload) => {
                slice.analysis.isLoading = false;
            })
            .addCase(getAnalysisHistoryFRAction.fulfilled, (slice: TFrameworkControlSlice, {payload}) => {
                slice.analysis.isLoading = false;

                const {array, pageInfo} = setPaginationInStore<TAnalysis>(
                    slice.analysis.pageInfo,
                    slice.analysis.analysis,
                    payload.resp.pageInfo,
                    payload.resp.history,
                    payload.isNewOnTop
                );
                slice.analysis.analysis = array;
                slice.analysis.pageInfo = pageInfo;
            })

            .addCase(getAnalysisHistoryDetailsFRAction.pending, (slice: TFrameworkControlSlice, payload) => {
                slice.analysis.isLoadingCurrent = true;
                slice.analysis.selected = slice.analysis.analysis.find(e => e?.historyId === payload.meta.arg.data.historyId) || null;
            })
            .addCase(getAnalysisHistoryDetailsFRAction.rejected, (slice: TFrameworkControlSlice, payload) => {
                slice.analysis.isLoadingCurrent = false;
            })
            .addCase(getAnalysisHistoryDetailsFRAction.fulfilled, (slice: TFrameworkControlSlice, {payload}) => {
                slice.analysis.isLoadingCurrent = false;

                const tmp = slice.analysis.analysis;
                const index = tmp.findIndex(e => e?.historyId === payload.data.historyId);
                if (index > -1) {
                    tmp[index]!.findings = payload.resp.details;

                    slice.analysis.analysis = tmp;
                }
            })

            .addCase(getAnalysisHistoryByHistoryIdAction.pending, (slice: TFrameworkControlSlice, payload) => {
                slice.analysis.isLoadingCurrent = true;
                slice.analysis.selected = slice.analysis.analysis.find(e => e?.historyId === payload.meta.arg.data.historyId) || null;
            })
            .addCase(getAnalysisHistoryByHistoryIdAction.rejected, (slice: TFrameworkControlSlice, payload) => {
                slice.analysis.isLoadingCurrent = false;
            })
            .addCase(getAnalysisHistoryByHistoryIdAction.fulfilled, (slice: TFrameworkControlSlice, {payload}) => {
                slice.analysis.isLoadingCurrent = false;

                const tmp = slice.analysis.analysis;
                const index = tmp.findIndex(e => e?.historyId === payload.data.historyId);
                if (index > -1) {
                    tmp[index] = payload.resp;

                    slice.analysis.analysis = tmp;
                }

                slice.analysis.selected = payload.resp;
            })

        // frameworkGetDelegatedTaskById
            .addCase(frameworkGetDelegatedTaskById.pending, (slice: TFrameworkControlSlice, {meta}) => {
                slice.delegatedTasks.isLoadingExact = true;
                if(meta.arg.clean){
                    slice.delegatedTasks.exactDelegatedTask = null;
                }
            })
            .addCase(frameworkGetDelegatedTaskById.rejected, (slice: TFrameworkControlSlice, payload) => {
                slice.delegatedTasks.isLoadingExact = false;
            })
            .addCase(frameworkGetDelegatedTaskById.fulfilled, (slice: TFrameworkControlSlice, {payload}) => {
                slice.delegatedTasks.isLoadingExact = false;
                slice.delegatedTasks.exactDelegatedTask = payload;
            })
        //frameworksCancelTaskById
            .addCase(frameworksCancelTaskById.pending, (slice: TFrameworkControlSlice, {meta}) => {
                slice.delegatedTasks.isLoadingCancel = true;
            })
            .addCase(frameworksCancelTaskById.rejected, (slice: TFrameworkControlSlice, payload) => {
                slice.delegatedTasks.isLoadingCancel = false;
            })
            .addCase(frameworksCancelTaskById.fulfilled, (slice: TFrameworkControlSlice, {payload}) => {
                slice.delegatedTasks.isLoadingCancel = false;
                slice.delegatedTasks.exactDelegatedTask = null;
                slice.delegatedTasks.tasks = slice.delegatedTasks.tasks.map(e => e.id === payload.id ? payload : e);
            })
        //frameworksDeclineTaskById
            .addCase(frameworksDeclineTaskById.pending, (slice: TFrameworkControlSlice, {meta}) => {
                slice.delegatedTasks.isLoadingDecline = true;
            })
            .addCase(frameworksDeclineTaskById.rejected, (slice: TFrameworkControlSlice, payload) => {
                slice.delegatedTasks.isLoadingDecline = false;
            })
            .addCase(frameworksDeclineTaskById.fulfilled, (slice: TFrameworkControlSlice, {payload}) => {
                slice.delegatedTasks.isLoadingDecline = false;
                slice.delegatedTasks.exactDelegatedTask = null;
                slice.delegatedTasks.tasks = slice.delegatedTasks.tasks.map(e => e.id === payload.id ? payload : e);
            })

        //mainFrameworksGetDelegatedTasksWithFilterPaginationAction
            .addCase(mainFrameworksGetDelegatedTasksWithFilterPaginationAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.delegatedTasks.isLoadingList = true;

                if (clean) {
                    slice.delegatedTasks.tasks = initialState.delegatedTasks.tasks;
                    slice.delegatedTasks.pageInfo = initialState.delegatedTasks.pageInfo;
                    slice.delegatedTasks.minMaxLoadedPage = initialState.delegatedTasks.minMaxLoadedPage;
                }
            })
            .addCase(mainFrameworksGetDelegatedTasksWithFilterPaginationAction.rejected,
                (slice) => {
                    slice.delegatedTasks.isLoadingList = false;
                })
            .addCase(mainFrameworksGetDelegatedTasksWithFilterPaginationAction.fulfilled,(slice, { payload }) => {
                slice.delegatedTasks.isLoadingList = false;

                const { results, maxLoadedPage, minLoadedPage } =
                    handlePagination<DelegatedTaskModel>(
                        slice.delegatedTasks.tasks,
                        slice.delegatedTasks.pageInfo,
                        payload.result,
                        payload.pageInfo,
                        slice.delegatedTasks.minMaxLoadedPage.minLoadedPage,
                        slice.delegatedTasks.minMaxLoadedPage.maxLoadedPage,
                        "id"
                    );

                slice.delegatedTasks.tasks = results;
                slice.delegatedTasks.minMaxLoadedPage = {
                    minLoadedPage,
                    maxLoadedPage,
                };
                slice.delegatedTasks.pageInfo = payload.pageInfo;
            })
        //frameworksApproveTaskById
            .addCase(frameworksApproveTaskById.pending, (slice: TFrameworkControlSlice, {meta}) => {
                slice.delegatedTasks.isLoadingApprove = true;
            })
            .addCase(frameworksApproveTaskById.rejected, (slice: TFrameworkControlSlice, payload) => {
                slice.delegatedTasks.isLoadingApprove = false;
            })
            .addCase(frameworksApproveTaskById.fulfilled, (slice: TFrameworkControlSlice, {payload}) => {
                slice.delegatedTasks.isLoadingApprove = false;
                slice.delegatedTasks.exactDelegatedTask = null;
                slice.delegatedTasks.tasks = slice.delegatedTasks.tasks.map(e => e.id === payload.id ? payload : e);
            })

            .addCase(mainFrameworksCreateDelegatedTaskAction.pending, (slice) => {
                slice.dialogs.createDelegatedTaskDialog.isLoadingCreate = true;
            })
            .addCase(mainFrameworksCreateDelegatedTaskAction.rejected, (slice) => {
                slice.dialogs.createDelegatedTaskDialog.isLoadingCreate = false;
            })
            .addCase(mainFrameworksCreateDelegatedTaskAction.fulfilled, (slice) => {
                slice.dialogs.createDelegatedTaskDialog = initialState.dialogs.createDelegatedTaskDialog;
            })

    }
});

export const frameworkControlReducerReducer = Slice.reducer;

export const {
    cleanAction,
    cleanCommentsAction,
    cleanAnalysisAction,

    setNewCommentDialogAction,
    setStatementDialogAction,
    setCollaboratorsDialogAction,
    setCollectorViewDialogAction,
    setDeleteAssetDialogAction,

    // openExactDelegatedTaskDialog,
    // hideExactDelegatedTaskDialog
    openCreateDelegatedTaskDialog,
    hideCreateDelegatedTaskDialog,
    replaceDelegatedTasksMinMaxLoadedPage,
    eraseDelegatedTasks,
    setCancelDelegatedTaskDialogAction,
} = Slice.actions;

const selectSelf = (state: AppState): TFrameworkControlSlice => state.frameworkControl;

export const frameworkControlFrameworkSelector = createSelector(selectSelf, state => state.framework);
export const frameworkControlControlSelector = createSelector(selectSelf, state => state.control);
export const frameworkControlEvidencesSelector = createSelector(selectSelf, state => state.evidences);
export const frameworkControlCommentsSelector = createSelector(selectSelf, state => state.comments);
export const frameworkControlAnalysisSelector = createSelector(selectSelf, state => state.analysis);
export const frameworkControlIsLoadingSelector = createSelector(selectSelf, state => state.isLoading);

export const frameworkControlDialogsSelector = createSelector(selectSelf, state => state.dialogs);

export const frameworkControlDelegatedTasks = createSelector(selectSelf, state => state.delegatedTasks);
