import React, {ComponentType, MouseEvent} from 'react';
import {EdgeProps, getBezierPath, ReactFlowState, useReactFlow, useStore} from 'reactflow';

import styles from "./style.module.css";
import 'reactflow/dist/style.css';

const foreignObjectSize = 40;

export const EmployeeTreeEdgeSuccess: ComponentType<EdgeProps> = (props) => {

    const {
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        target,
        // source,
        // style = {},
    } = props;

    const {setEdges} = useReactFlow();

    const onEdgeClick = (event: MouseEvent<HTMLButtonElement>, id: string) => {
        event.stopPropagation();
        setEdges(edges => edges.filter(e => e.id !== id));
    }

    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const isRedSelector = (state: ReactFlowState) => state.edges.filter(e => e.target === target).length > 1;
    const isRed = useStore(isRedSelector);

    return (
        <>
            <path
                id={id}
                style={{stroke: isRed ? "red" : "green"}}
                strokeWidth={1.5}
                className={"react-flow__edge-path"}
                d={edgePath}
            />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={labelX - foreignObjectSize / 2}
                y={labelY - foreignObjectSize / 2}
                className={styles.edgebuttonforeignobject}
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                <div>
                    <button className={styles.edgebutton} onClick={(event) => onEdgeClick(event, id)}>
                        ×
                    </button>
                </div>
            </foreignObject>
        </>
    )
};
