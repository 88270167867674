import {
    Autocomplete,
    Avatar,
    Box,
    Divider,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography
} from "@mui/material";
import React, {FC, useEffect} from "react";
import {Box as BBox, Flex, PageWrapper} from "../../../../../newShared/components/Layouts";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    PATH_LOCAL_COMPLIANCE_REPORTS,
    PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED,
    PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED
} from "../../../../../newShared/constants";
import {useHistory} from "react-router";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import colors from "../../../../../newShared/theme/colors";
import {useReports} from "../../hooks/useReports";
import {useReportsIntegrations} from "../../hooks/useReportsIntegrations";
import {useReportsParams} from "../../hooks/useReportsParams";
import {TabContext} from "@mui/lab";
import {exactAppTabsType} from "../../../newIntegrations/types";
import * as S from "../../../newIntegrations/components/styled";
import {TextFieldCustom} from "../../../newIntegrations/components/styled";
import InfiniteScroll from "react-infinite-scroller";
import {rawDataValueGetter} from "../../../newIntegrations/helpers";
import {IconTextButton, LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import EastIcon from "@mui/icons-material/East";
import SyntaxHighlighter from "react-syntax-highlighter";
import {docco} from "react-syntax-highlighter/dist/esm/styles/hljs";
import {AppLogsTableRowSkeleton} from "../../../newIntegrations/components/appLogsTableRowSkeleton";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {MAX_LOG_SIZE_PREVIEW} from "../../../newIntegrations/constants";
import DownloadIcon from "@mui/icons-material/Download";
import {LocalSpinner} from "../../../../../newShared/components/Spinner";
import {useReport} from "../../hooks/useReport";
import {useReportIntegrationExactDetailsPage} from "./useReportIntegrationExactDetailsPage";
import {ReportAppLogsTableRow} from "../../components/reportAppLogsTableRow";
import {CustomSearchField} from "../../components/customSearch";
import {getAvatarInitials} from "../../../../../newShared/utils/text";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {TableRowSkeleton} from "../../../../../newShared/components/tableRowSkeleton";
import {useScrollPagination} from "../../../../../newShared/hooks/useScrollPagination";

export const ReportIntegrationExactDetails: FC = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathReports.View'});

    const {isMobile, isLessThenCustomQuery} = useMedia(912);
    const history = useHistory();

    const {
        integrations: {selectedApp},
        getReportIntegrations,
    } = useReportsIntegrations();
    const {goToReportApp} = useReports();
    const {reportId} = useReportsParams();
    const {
        report: {report},
    } = useReport();

    const {
        isLoading,
        app,
        tab,
        actions,
        data,
        logsData
    } = useReportIntegrationExactDetailsPage();

    const ARCHIVED = report?.archived;

    const {onScroll, scrollRef} = useScrollPagination({
        arrayLength: data.collectionData.length,
        getData: ({page, count}) => {data.handleLoadMore(page)},
        isLoading: data.isLoading,
        pageInfo: data.collectionDataPageInfo,
        isNoMoreThanTotal: true,
        customDependencies: [],
        is0PageAddictive: false,
        disableInitFetch: true,
    });

    //Breadcrumbs
    useSetBreadcrumbs([
        {
            title: tMenu('Reports'),
            path: PATH_LOCAL_COMPLIANCE_REPORTS,
        },
        {
            title: tMenu('Generated reports'),
            path: PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED,
            skip: ARCHIVED ?? !history.location.pathname.includes(PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED),
        },
        {
            title: tMenu('Archived reports'),
            path: PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED,
            skip: !ARCHIVED ?? !history.location.pathname.includes(PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED),
        },
        {
            title: report?.name || 'Loading report...',
            path: report ? `${report.archived ? PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED : PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED}/${report.id}` : undefined,
        },
        {
            title: t('Integration details'),
            path: report ? `${report.archived ? PATH_LOCAL_COMPLIANCE_REPORTS_ARCHIVED : PATH_LOCAL_COMPLIANCE_REPORTS_GENERATED}/${report.id}/integrations` : undefined,
        },
        {
            title: selectedApp.app?.name || 'Loading integration...',
        },
    ]);

    useEffect(() => {
        reportId && getReportIntegrations({workspaceId: '', reportId: reportId})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportId])

    return (
        <Box display={"flex"} width={'100%'} height={'100%'} overflow={"hidden"}>
            <PageWrapper>
                <PageTitleAndActions
                    title={app?.name}
                    actions={[]}
                    handleGoBack={() => report && goToReportApp(report.id, '', !!ARCHIVED)}
                    icon={
                        <Avatar src={app?.logo ?? ''} sx={{width: '25px', height: '25px', backgroundColor: 'inherit'}}>
                            <Typography fontSize={'10px'} fontWeight={300}>{getAvatarInitials(app?.name ?? '')}</Typography>
                        </Avatar>
                    }
                />

                {/*TABS*/}
                <TabContext value={tab} >
                    <Box>
                        <Tabs variant={'scrollable'} value={tab} onChange={(e, newValue: exactAppTabsType) => actions.handleSetTab(newValue)}>
                            <Tab label={("Data")} value={'data'} disabled={isLoading} sx={{paddingLeft: '0'}}/>
                            <Tab label={("Logs")} value={'logs'} disabled={isLoading} />
                        </Tabs>
                    </Box>
                </TabContext>

                <Box display={"flex"} flexDirection={"column"} width={'100%'} flexGrow={1} overflow={"hidden"}>
                    {tab === 'data' &&
                        <Flex w={'100%'} jc={'space-between'} h={'100%'}>
                            {/*COLLECTION SELECT*/}
                            {!isMobile &&
                                <S.ExactAppCollectionsListContainer>
                                    <Flex w={'100%'} jc={'space-between'} h={'100%'}>
                                        <BBox w={'99%'} overflow={'auto'} h={'100%'} maxh={'100%'} p={'0 0 16px 0'}>
                                            {app && app.config && app.config.collectionConfigs && app.config.collectionConfigs?.map((e) => {
                                                const isSelected = data.collectionsTab === e.alias;
                                                return <Typography
                                                    key={e.alias}
                                                    onClick={() => data.handleSetCollectionTab(e.alias)}
                                                    color={isSelected ? colors.text.blue : colors.grayText}
                                                    sx={{cursor: 'pointer', margin: '32px 0 0 0'}}
                                                >
                                                    {e.alias}
                                                </Typography>
                                            })}
                                        </BBox>
                                        <Divider flexItem orientation={'vertical'} style={{
                                            borderColor: colors.primary.disable,
                                            backgroundColor: colors.darkText
                                        }}/>
                                    </Flex>
                                </S.ExactAppCollectionsListContainer>
                            }

                            {/*TABLE CONTAINER*/}
                            <S.ExactAppItemsListContainer rightSideOpened={data.selectedCollectionObject !== null}>
                                <S.ExactAppItemsListSearchContainer
                                    rightSideOpened={data.selectedCollectionObject !== null}>
                                    <CustomSearchField
                                        variant={'outlined'}
                                        onChange={data.handleChange}
                                        value={data.search}
                                        onClear={data.handleCleanSearch}
                                        disabled={isLoading}
                                        onKeyDown={data.handleEnterKey}
                                        size="small"
                                        fullWidth
                                        name={'search'}
                                        label={('Search')}
                                        dir={'ltr'}
                                        placeholder={('Search')}
                                    />
                                    {isMobile &&
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={app?.config?.collectionConfigs.map(e => e.alias) ?? []}
                                            disabled={isLoading}
                                            // getOptionLabel={(option) => option}
                                            loading={isLoading}
                                            value={data.collectionsTab}
                                            onChange={(e, newValue: string | null) => newValue && data.handleSetCollectionTab(newValue)}
                                            renderInput={(params) => <TextFieldCustom {...params} label={t("Collection")}
                                                                                      size={'small'}/>}
                                        />
                                    }
                                </S.ExactAppItemsListSearchContainer>

                                {/*TABLE HEADER*/}
                                {data.isLoading && data.collectionData.length === 0 && <LocalSpinner />}
                                {!data.isLoading && !data.collectionData.length && <Typography variant={'*bodyText2'}>{'No values found'}</Typography>}
                                {data.collectionData.length > 0 &&
                                    <TableContainer ref={scrollRef} onScroll={onScroll} sx={{marginTop: 0}}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    {data.currentCollectionConfig?.headers.map((e: string, id, arr) =>
                                                        <TableCell key={e}>
                                                            {String(e)}
                                                        </TableCell>
                                                    )}
                                                </TableRow>
                                            </TableHead>

                                            {/*TABLE*/}
                                            <TableBody>
                                                {/*<S.MainTableScrollWrapper>*/}
                                                {/*    <InfiniteScroll*/}
                                                {/*        pageStart={0}*/}
                                                {/*        loadMore={(page) => data.handleLoadMore(page)}*/}
                                                {/*        hasMore={data.hasMore}*/}
                                                {/*        initialLoad={false}*/}
                                                {/*        threshold={50}*/}
                                                {/*        useWindow={false}*/}
                                                {/*        style={{*/}
                                                {/*            width: '100%',*/}
                                                {/*            display: 'flex',*/}
                                                {/*            flexDirection: 'column',*/}
                                                {/*            overflow: 'auto'*/}
                                                {/*        }}*/}
                                                {/*    >*/}
                                                {data.collectionData.map(e => {
                                                    // console.log(`collectionData: ${JSON.stringify(e)}`);
                                                    const values = [];
                                                    const selected = JSON.stringify(data.selectedCollectionObject) === JSON.stringify(e);
                                                    for (let i = 0; i < (data.currentCollectionConfig?.headers.length ?? 0); i++) {
                                                        values.push(rawDataValueGetter(e, app?.config, data.collectionsTab, i));
                                                    }
                                                    // console.log(`values: ${JSON.stringify(values)} for headers.length: ${data.currentCollectionConfig?.headers.length}`);
                                                    return (
                                                        <>
                                                            <TableRow hover
                                                                      key={values[0]}
                                                                      onClick={() => data.setSelectedCollectionObject(e)}
                                                                      selected={selected}>
                                                                {values.map(v => <TableCell key={v}>
                                                                    <Typography variant={'body1'}
                                                                                color={colors.grayText}
                                                                                whiteSpace={'nowrap'}
                                                                                textOverflow={'ellipsis'}
                                                                                lineHeight={'normal'}
                                                                                overflow={'hidden'}>{String(v)}</Typography>
                                                                </TableCell>)}
                                                            </TableRow>
                                                            {/*{<Divider flexItem sx={{*/}
                                                            {/*    bgcolor: colors.primary.disable,*/}
                                                            {/*    borderColor: colors.primary.disable,*/}
                                                            {/*    margin: '4px 0 0 0'*/}
                                                            {/*}}/>}*/}
                                                        </>
                                                    )
                                                })}
                                                {data.isLoading && <TableRowSkeleton columns={data.currentCollectionConfig?.headers.length ?? 1}/>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </S.ExactAppItemsListContainer>

                            {data.selectedCollectionObject &&
                                <S.ExactAppExactItemContainer>
                                    <Flex w={'100%'} jc={'space-between'} h={'100%'}>
                                        {!isMobile &&
                                            <Divider flexItem orientation={'vertical'} style={{
                                                borderColor: colors.primary.disable,
                                                backgroundColor: colors.darkText
                                            }}/>
                                        }
                                        <Flex w={'99%'} direction={'column'} overflow={'auto'}>
                                            {isMobile &&
                                                <Flex w={'100%'}>
                                                    <IconTextButton
                                                        variant={'text'}
                                                        disabled={isLoading}
                                                        icon={!revDir ?
                                                            <KeyboardBackspaceIcon
                                                                sx={{color: colors.text.blue, cursor: 'pointer'}}/>
                                                            : <EastIcon sx={{color: colors.text.blue, cursor: 'pointer'}}/>
                                                        }
                                                        onClick={data.deselectCollectionObject}
                                                        sx={{'& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px' : '0 -4px 0 8px',}}}
                                                    >{t('back to table')}
                                                    </IconTextButton>
                                                </Flex>
                                            }

                                            <Flex w={'100%'} maxh={'100%'}>
                                                <SyntaxHighlighter customStyle={{direction: 'ltr'}} language="yaml"
                                                                   style={docco}>
                                                    {data.selectedCollectionObjectInYaml}
                                                </SyntaxHighlighter>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </S.ExactAppExactItemContainer>
                            }
                        </Flex>
                    }

                    {tab === 'logs' &&
                        <Flex w={'100%'} h={'100%'} jc={'space-between'}>
                            <TableContainer sx={{width: `${logsData.selectedLog !== null ? '40%' : '100%'}`}}>
                                <Table sx={{width: '100%'}}>
                                    <TableHead sx={{margin: '25px 0 8px 0', width: '100%'}}>
                                        <TableRow sx={{width: '100%', display: 'flex', justifyContent: 'flex-start'}}>
                                            <TableCell sx={{width: `${logsData.selectedLog !== null ? '50%' : '20%'}`}}>
                                                <Typography sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 700,
                                                    color: colors.grayText
                                                }}>{('Launch date')}</Typography>
                                            </TableCell>

                                            <TableCell sx={{width: `${logsData.selectedLog !== null ? '50%' : '80%'}`}}>
                                                <Typography sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 700,
                                                    color: colors.grayText
                                                }}>{('ID')}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        <InfiniteScroll
                                            pageStart={0}
                                            loadMore={(page) => logsData.handleLoadMoreLogs(page)}
                                            hasMore={logsData.hasMore}
                                            initialLoad={false}
                                            threshold={50}
                                            useWindow={false}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}
                                        >
                                            {logsData.logs.map(e => e && <ReportAppLogsTableRow key={e.id} log={e} selectedLog={logsData.selectedLog}/>)}

                                            {/*{logsData.logs.map(e => <AppLogsTableRow key={e.id} log={e}*/}
                                            {/*                                         selectedLog={logsData.selectedLog}/>)}*/}
                                            {logsData.isLoadingList && <AppLogsTableRowSkeleton
                                                isRightSideOpened={logsData.selectedLog !== null}/>}
                                        </InfiniteScroll>

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <S.ExactAppLogsRightSide isRightSideOpened={logsData.selectedLog !== null}>
                                <Flex w={'100%'} jc={'space-between'} h={'100%'}
                                      p={isMobile ? '0 20px 25px 20px' : '25px 20px'}>
                                    {!isMobile &&
                                        <Divider flexItem orientation={'vertical'} style={{
                                            borderColor: colors.primary.disable,
                                            backgroundColor: colors.darkText
                                        }}/>
                                    }

                                    <Flex w={'99%'} direction={'column'} overflow={'auto'}>
                                        {isMobile &&
                                            <Flex w={'100%'}>
                                                <IconTextButton
                                                    variant={'text'}
                                                    disabled={isLoading}
                                                    icon={!revDir ?
                                                        <KeyboardBackspaceIcon
                                                            sx={{color: colors.text.blue, cursor: 'pointer'}}/>
                                                        : <EastIcon sx={{color: colors.text.blue, cursor: 'pointer'}}/>
                                                    }
                                                    onClick={logsData.deselectLog}
                                                    sx={{'& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px' : '0 -4px 0 8px',}}}
                                                >{('back to table')}
                                                </IconTextButton>
                                            </Flex>
                                        }

                                        <Flex w={'100%'} ai={'center'} jc={'space-between'} wrap={'wrap'}>
                                            <Typography sx={{color: colors.grayText}} variant={'body1'}>{app?.name} log dated {parseDateAuto(logsData.selectedLog?.timestamp)}</Typography>

                                            {/*<Typography sx={{color: colors.grayText}}*/}
                                            {/*            variant={'body1'}>{t('{{appName}} log dated', {appName: installed?.name})} {parseDateAuto(logsData.selectedLog?.timestamp, true)}</Typography>*/}
                                            <LoadingButton
                                                sx={{margin: isLessThenCustomQuery ? '16px 0 0 0' : ''}}
                                                loading={logsData.isLoadingDownload}
                                                size={'small'}
                                                startIcon={<DownloadIcon/>}
                                                onClick={logsData.handleDownloadLog}
                                            >
                                                {('Download')}
                                            </LoadingButton>
                                        </Flex>
                                        {<Divider flexItem sx={{
                                            bgcolor: colors.primary.disable,
                                            borderColor: colors.primary.disable,
                                            margin: '16px 0 0 0'
                                        }}/>}
                                        {logsData.isLoadingExact && <LocalSpinner/>}
                                        {logsData.selectedLog && logsData.selectedLog.size >= MAX_LOG_SIZE_PREVIEW ?
                                            <Typography sx={{margin: '16px 0 0 0', color: colors.grayText}}
                                                        variant={'body2'}>{('The log is too big for preview. But you can download it.')}</Typography>
                                            :
                                            <Flex w={'100%'} m={'16px 8px'} ai={'center'}>
                                                <SyntaxHighlighter customStyle={{direction: 'ltr'}} language="text"
                                                                   style={docco} wrapLines wrapLongLines>
                                                    {logsData.logResponse}
                                                </SyntaxHighlighter>
                                            </Flex>
                                        }
                                    </Flex>
                                </Flex>
                            </S.ExactAppLogsRightSide>
                        </Flex>
                    }
                </Box>
            </PageWrapper>

            {/*DIALOGS*/}
            {/*<PreviewErrorReason />*/}
        </Box>
    )
}