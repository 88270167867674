import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    styled,
    Table,
    TableCell,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import {ReactComponent as FolderIcon} from "../../../../../../src/newShared/images/documents/document-icon.svg";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useTimeOffRequestExactView} from "../../hooks/useTimeOffExactView";
import {MicroTableWithoutBorder} from "../../../../../newShared/components/microTableWithoutBorder";
import {getTimeOffStatusChip} from "../../helpers";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import colors from "../../../../../newShared/theme/colors";
import {HrTimeOffRouteType, HrTimeOffStepsStatus, HrTimeOffStepsType} from "../../../../../newShared/GQLTypes";
import {LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {useHrTimeOffHistory} from "../../hooks/dialogHooks/useTimeOffHistory";
import {useRequestError} from "../../hooks/dialogHooks/useRequestError";
import {FileDownloadOutlined} from "@mui/icons-material";
import {useSkipTimeOffRequest} from "../../hooks/dialogHooks/useSkipTimeOffRequest";

const NoPaddingTableCell = styled(TableCell)({
    paddingBlock: '4px !important',
})

export const TimeOffRequestExactView = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathHRManagement.pathTimeOff'})
    const {isOpen,
        handleClose,
        loadings,
        request,
        handleRejectRequest,
        handleDownloadFile,
        handleApproveRequest,
        formik,
        timeoffId,
        openSkipDialog
    } = useTimeOffRequestExactView();

    const {handleOpenTimeOffHistory, isOpen: isOpenHistory} = useHrTimeOffHistory();
    const {isOpen: isOpenError} = useRequestError()
    const {isOpen: isOpenSkip} = useSkipTimeOffRequest();

    const isApproveRejectAvailable = () => {
        // const managerStep = request?.approvalRoutes.find(e => e.routeType === HrTimeOffRouteType.Initial)?.steps.find((step) => step.type === HrTimeOffStepsType.ManagerApproval);
        const hrManagerStep = request?.approvalRoutes.find(e => e.routeType === HrTimeOffRouteType.Initial)?.steps.find((step) => step.type === HrTimeOffStepsType.HrManagerApproval);

        return hrManagerStep?.status === HrTimeOffStepsStatus.InProgress; // || (managerStep?.status === HrTimeOffStepsStatus.Skipped && hrManagerStep?.status !== HrTimeOffStepsStatus.Approved);
    }

    const isSkippedAvailable = () => {
        const managerStep = request?.approvalRoutes.find(e => e.routeType === HrTimeOffRouteType.Initial)?.steps.find((step) => step.type === HrTimeOffStepsType.ManagerApproval);

        return managerStep?.status === HrTimeOffStepsStatus.Created || managerStep?.status === HrTimeOffStepsStatus.InProgress || managerStep?.status === HrTimeOffStepsStatus.Pending;
    }


    return (
        <Dialog open={isOpen && !isOpenHistory && !isOpenSkip && !isOpenError} onClose={handleClose}>
            <DialogTitle>{t('Time off request {{id}}', {id: request?.id})}</DialogTitle>
            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                                 <MicroTableWithoutBorder
                                                    isLoading={loadings.isLoadingExact}
                                                    rows={[
                                                        {
                                                            label: `${t('Status')}: `,
                                                            text: request ? getTimeOffStatusChip(request?.status) : '',
                                                        },
                                                        {
                                                            label: `${t('Requestor')}: `,
                                                            text: request?.requestor ? request?.requestor?.employeeFullName ? request?.requestor?.employeeFullName : request?.requestor?.collaboratorFullName : '-',
                                                        },
                                                        {
                                                            label: `${t('Time off type')}: `,
                                                            text: request ? request.type : '-',
                                                        },
                                                        {
                                                            label: `${t('Start date')}: `,
                                                            text: request?.startDate ? parseDateAuto(request?.startDate) : '-',
                                                        },
                                                        {
                                                            label: `${t('End date')}: `,
                                                            text: request?.endDate ? parseDateAuto(request?.endDate) : '-',
                                                        },
                                                        {
                                                            label: `${t('Comment')}: `,
                                                            text: request?.comment ? (request?.comment) : '-',
                                                        },
                                                        {
                                                            label: `${t('Attachments')}: `,
                                                            noVerticalAlign: true,
                                                            text: !!request?.attachments?.length ? (
                                                                    <Table sx={{mt: '-10px'}}>
                                                                        {request?.attachments.map((item, index) => {
                                                                            const isLoading = loadings.fileDownloading.includes(item.fileId);
                                                                            return (
                                                                                <TableRow sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} key={item.fileId}>
                                                    <NoPaddingTableCell sx={{display: 'flex', justifyContent: 'flex-start', gap: '10px'}}>
                                                        <FolderIcon/>
                                                        <Typography key={index}>{item.fileName}</Typography>
                                                    </NoPaddingTableCell>
                                                    <NoPaddingTableCell>
                                                        <IconButton size={"small"} sx={{color: colors.primary.blue}}
                                                                    disabled={isLoading}
                                                                    onClick={() => {handleDownloadFile(item.fileId, item.fileName)}}>
                                                            {isLoading ? <CircularProgress size={24}/> : <FileDownloadOutlined/>}
                                                        </IconButton>
                                                    </NoPaddingTableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </Table>
                                )
                                : '-',
                        }

                    ]}

                />
                {isApproveRejectAvailable() && !loadings.isLoadingExact &&
                    <>
                        <Divider sx={{borderColor: colors.primary.disable}} />
                        <Typography variant={'*bodyText2'}>{t('Please leave comment for requestor (to reject request comment is required)')}</Typography>
                        <TextField multiline rows={4} label={t('Comment')} onChange={formik.handleChange} value={formik.values.comment}
                                   onBlur={formik.handleBlur} name={'comment'} error={Boolean(formik.errors.comment)} helperText={formik.errors.comment}
                        />
                    </>
                }

                <Button startIcon={ <GradingOutlinedIcon sx={{ color: colors.primary.blue}} />} sx={{width: 'fit-content' }} onClick={() => request && handleOpenTimeOffHistory(request)} variant={"text"} >
                    {t("View approval history")}
                </Button>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} size={'small'} variant={'text'}>{(tCommon('Close'))}</Button>

                {isApproveRejectAvailable() && !loadings.isLoadingExact &&
                    <>
                        <LoadingButton disabled={!formik.isValid || !formik.values.comment.trim().length} variant={"outlined"} onClick={() => {timeoffId && handleRejectRequest(timeoffId)}} loading={loadings.isLoadingReject} size={'small'}>
                            {tCommon('Reject')}
                        </LoadingButton>
                        <LoadingButton disabled={!formik.isValid} variant={"contained"} onClick={() => {timeoffId && handleApproveRequest(timeoffId)}} loading={loadings.isLoadingApprove} size={'small'}>
                            {tCommon('Approve')}
                        </LoadingButton>
                    </>
                }

                { isSkippedAvailable() && !loadings.isLoadingExact &&
                    <LoadingButton disabled={!formik.isValid} variant={"contained"} onClick={() => {openSkipDialog()}} loading={loadings.isLoadingApprove} size={'small'}>
                        {t('Skip manager approval')}
                    </LoadingButton>
                }
            </DialogActions>
        </Dialog>
    )
}
