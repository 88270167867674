import {useAppDispatch} from "../../../../../newShared/redux";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {TPhysicalAssetSettings, TSettingsId, TVirtualAssetSettingsId} from "../../types";
import {useSelector} from "react-redux";
import {
    assetsDialogsSelector,
    assetsSettingsSelector,
    loadings,
    setEditSettingRowDialogAction
} from "../../store/store";
import {
    editCertificateAssetsSettingRowAction,
    editPhysicalAssetsSettingRowAction,
    editSaasAssetsSettingRowAction,
    editSoftwareAssetsSettingRowAction,
    editVirtualAssetsSettingRowAction
} from "../../store/actions";
import {normalizeSettingIdName} from "../../helpers";


export const useEditSettingsDialog = () => {
    const dispatch = useAppDispatch();
    const id = useParams<{id: TSettingsId | TVirtualAssetSettingsId}>().id as TSettingsId;
    const {editSettingRow: {isOpen, oldTitle, status, response: editResponse, assetType, field}} = useSelector(assetsDialogsSelector);
    const settings = useSelector(assetsSettingsSelector);
    const settingTitle = normalizeSettingIdName(id as TSettingsId);
    const {isLoadingSettings, editSettingLoading} = useSelector(loadings);

    const [ newTitle, setNewTitle ] = useState<string>(oldTitle ?? '');
    const [ newStatus, setNewStatus ] = useState<TPhysicalAssetSettings['status'][number] | null>(status || null);
    const [withHistoryChange, setWithHistoryChange] = useState<boolean>(false);

    useEffect(() => {
        setNewTitle(oldTitle ?? '')
        setWithHistoryChange(false);
        if (status !== null && status !== undefined) setNewStatus(status);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, oldTitle]);

    useEffect(() => {
        setNewTitle(oldTitle ?? '')
        setWithHistoryChange(false);
        if (status !== null && status !== undefined) setNewStatus(status);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = () => {
        dispatch(setEditSettingRowDialogAction({clear: true}))
    }

    const handleChangeTitle = (text: string) => {
        if (id !== 'status') setNewTitle(text);
        if (id === 'status') setNewStatus((prev: any) => prev && ({...prev, name: text}));
    }

    const handleChangeStatus = (status: string) => {
        setNewStatus((prev: any) => prev && ({...prev, status}));
    }

    const handleSave = () => {
        if (id === 'status') {
            newStatus && dispatch(editPhysicalAssetsSettingRowAction({workspaceId: '', organizationId: '', field : id, oldTitle, status: newStatus, withHistoryChange, title: newStatus.name.trim()}))
        }
        if (assetType === 'physical' && id !== 'status') {
            dispatch(editPhysicalAssetsSettingRowAction({workspaceId: '', organizationId: '', field: id, oldTitle, title: newTitle.trim()}))
        }
        if (assetType === 'virtual') dispatch(editVirtualAssetsSettingRowAction({workspaceId: '', organizationId: '', field: id, oldTitle, title: newTitle.trim()}))
        if (assetType === 'software') dispatch(editSoftwareAssetsSettingRowAction({workspaceId: '', organizationId: '', field: id, oldTitle, title: newTitle.trim()}))
        if (assetType === 'saas') dispatch(editSaasAssetsSettingRowAction({workspaceId: '', organizationId: '', field: id, oldTitle, title: newTitle.trim()}))
        if (assetType === 'certificate') dispatch(editCertificateAssetsSettingRowAction({workspaceId: '', organizationId: '', field: id, oldTitle, title: newTitle.trim()}))
    }

    const isOk = () => {
        if (id === 'status') return newStatus && status?.name !== newStatus?.name && newStatus?.name.trim().length > 0
        else  return newTitle.trim().length > 0 || newTitle.trim() !== oldTitle
    }

    const isOkNewTitle = () => {
        if (field === 'model' || field === 'manufacture' || field === 'category') return !settings?.[field].some((e) => e?.name.toLowerCase().trim() === newTitle.toLowerCase().trim()) && newTitle.trim().length > 0;
        else if (field === 'status') return newStatus && !settings?.[field].some((e) => e?.name.toLowerCase() === newStatus?.name.toLowerCase()) && newStatus.name.trim().length > 0;
        else if (field?.toLowerCase() === 'customfields')  return !settings?.[field as TSettingsId].some(e => e === newTitle.trim() )
        else return true;
    }


    return {
        id,
        isOk,
        isOkNewTitle: isOkNewTitle(),
        isOpen,
        oldTitle,
        status,
        settingTitle,

        newTitle,
        setNewTitle,
        newStatus,
        setNewStatus,
        withHistoryChange,
        setWithHistoryChange,

        handleChangeTitle,
        handleChangeStatus,
        handleClose,
        handleSave,
        isLoadingSettings,
        editSettingLoading,
        editResponse
    }

}
