import {optionIsTCollaborator, TAutocompleteCollaboratorsProps, TCollaborator, TCollaboratorWithRole} from "./types";
import {getName} from "../../utils/text";
import {AutocompleteFreeSoloValueMapping} from "@mui/material";
import {AutocompleteValue} from "@mui/base/useAutocomplete/useAutocomplete";

export const getOptionLabel = (getOptionLabelCustom: TAutocompleteCollaboratorsProps<any, any, any>["getOptionLabelCustom"]) => (option: TCollaborator | TCollaboratorWithRole | string) => {
    if (typeof option === "string") return option;
    return getOptionLabelCustom === 'name'
        ? getName(option.firstName || '', option.lastName || '')
        : option.email;
};

export const defaultGetOptionDisabled = (disabledOptions: (Partial<TCollaboratorWithRole> | string)[]) => (option: TCollaboratorWithRole | string): boolean => {

    return (
        !optionIsTCollaborator(option)
            ? disabledOptions?.some(e => (
                typeof e === "string"
                    ? e === option
                    : (
                        e?.email === option ||
                        e?.publicId === option
                        // getName(e.firstName || '', e.lastName || '') === option ||
                        // getName(e.lastName || '', e.firstName || '') === option
                    )
            ))
            : disabledOptions?.some(e => (
                typeof e === "string"
                    ? (
                        e === option?.email ||
                        e === option?.publicId
                        // e === getName(option?.firstName || '', option?.lastName || '') ||
                        // e === getName(option?.lastName || '', option?.firstName || '')
                    )
                    : (
                        e?.email === option?.email ||
                        e?.publicId === option?.publicId
                        // getName(e.firstName || '', e.lastName || '') === getName(option.firstName || '', option.lastName || '') ||
                        // getName(e.lastName || '', e.firstName || '') === getName(option.lastName || '', option.firstName || '')
                    )
            ))
    ) || false
}

export const modifyCollaborator = <M, D, F>(collaborators: AutocompleteValue<TCollaboratorWithRole | AutocompleteFreeSoloValueMapping<F>, M, D, F>): AutocompleteValue<TCollaborator | AutocompleteFreeSoloValueMapping<F>, M, D, F> => {

    if (!collaborators) return collaborators;

    if (Array.isArray(collaborators)) {
        return collaborators.map(e => {
            if (typeof e === "string") return e;
            else return {
                email: e.email,
                firstName: e.firstName,
                lastName: e.lastName,
                publicId: e.publicId,
            }
        }) as AutocompleteValue<TCollaborator | AutocompleteFreeSoloValueMapping<F>, M, D, F>
    } else {
        if (typeof collaborators === "string") return collaborators;
        else return {
            email: collaborators.email,
            firstName: collaborators.firstName,
            lastName: collaborators.lastName,
            publicId: collaborators.publicId,
        } as AutocompleteValue<TCollaborator | AutocompleteFreeSoloValueMapping<F>, M, D, F>
    }
}