import {Flex} from "../../../../../../newShared/components/Layouts";
import colors from "../../../../../../newShared/theme/colors";
import {Typography} from "@mui/material";
import {getNormalizedStatusName} from "../../../helpers";

export const RequestStatus = ({status, margin}: {status: string, margin?: string}) => {
    switch (status){
        case "IN_PROGRESS": return <Flex p={'6px 14px'} br={'15px'} background={colors.decorative.background_1} m={margin}>
            <Typography variant={'body2'} color={colors.decorative.violet}>{getNormalizedStatusName(status)}</Typography>
        </Flex>;
        case "COMPLETED": return <Flex p={'6px 14px'} br={'15px'} background={colors.decorative.background_3} m={margin}>
            <Typography variant={'body2'} color={colors.secondary.green}>{getNormalizedStatusName(status)}</Typography>
        </Flex>;
        case "COMPLETE": return <Flex p={'6px 14px'} br={'15px'} background={colors.decorative.background_3} m={margin}>
            <Typography variant={'body2'} color={colors.secondary.green}>{getNormalizedStatusName(status)}</Typography>
        </Flex>;
        case "CANCELED": return <Flex p={'6px 14px'} br={'15px'} background={colors.decorative.background_red} m={margin}>
            <Typography variant={'body2'} color={colors.text.red}>{getNormalizedStatusName(status)}</Typography>
        </Flex>;
        case "REJECTED": return <Flex p={'6px 14px'} br={'15px'} background={colors.decorative.background_red} m={margin}>
            <Typography variant={'body2'} color={colors.text.red}>{getNormalizedStatusName(status)}</Typography>
        </Flex>;
        case "REJECTED_BY_USER": return <Flex p={'6px 14px'} br={'15px'} background={colors.decorative.background_red} m={margin}>
            <Typography variant={'body2'} color={colors.text.red}>{getNormalizedStatusName(status)}</Typography>
        </Flex>;
        default: return <></>;
    }
}