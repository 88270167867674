import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React from "react";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {LoadingButton} from "@mui/lab";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useCreateRisk} from "../../../hooks/dialogsHooks/useCreateRisk";
import { TextFieldCustom } from "../../styled";

export const CreateRisk = () => {
    const {
        form,
        handleChange,
        handleClose,
        isOpen,
        isLoading,
        isOk,
        handleSubmit,
    } = useCreateRisk();
    const {isTablet, isMobile} = useMedia();
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'risks'});


    return(
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{t('Add risk')}</DialogTitle>

            <DialogContent>
                <TextFieldCustom
                    required
                    variant={'outlined'}
                    onChange={handleChange}
                    value={form.name}
                    size="small"
                    fullWidth
                    dir={'ltr'}
                    name={'name'}
                    label={t('Name')}
                    sx={{width: isTablet ? '100%' : '60%', margin: '8px 0 0 0'}}
                />

                <TextFieldCustom
                    required
                    variant={'outlined'}
                    onChange={handleChange}
                    value={form.type}
                    size="small"
                    fullWidth
                    name={'type'}
                    dir={'ltr'}
                    label={t('Type')}
                    sx={{width: isTablet ? '100%' : '60%', margin: '16px 0 0 0'}}
                />

                <TextFieldCustom
                    required
                    variant={'outlined'}
                    onChange={handleChange}
                    value={form.description}
                    // size="small"
                    fullWidth
                    name={'description'}
                    dir={'ltr'}
                    label={t('Description')}
                    multiline
                    minRows={5}
                    sx={{margin: '16px 0 0 0'}}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{t('Cancel')}</Button>
                {isLoading ?
                    <LoadingButton
                        loading
                        sx={{paddingLeft: '35px', margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                        variant="outlined"
                    >{t('Add')}</LoadingButton>
                    :
                    <Button
                        variant={'contained'}
                        size={isMobile ? 'small' : 'medium'}
                        onClick={handleSubmit}
                        disabled={!isOk()}
                        sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    >{t('Add')}</Button>
                }
            </DialogActions>
        </Dialog>
    )
}