import {Box, Divider, IconButton, styled} from "@mui/material";
import colors from "../../../../../../../../newShared/theme/colors";
import {TypographyBody2, TypographyBodySmall} from "../../../../../../../../newShared/components/Inputs/styled";


export const StartDocumentContainer = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '15px',
    flexGrow: 1,
    overflow: 'hidden',
    position: 'relative',
    '& .file-drop': {
        overflow: 'hidden',
        height: '100%',

        '& .file-drop-target': {
            overflow: 'hidden',
            height: '100%',
        },
    },
}));

export const BlankOrInputContainer = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
}));

export const VerticalDivider = styled(Divider)(() => ({
    borderColor: colors.stroke.grey,

}));

VerticalDivider.defaultProps = {
    variant: 'fullWidth',
    orientation: 'vertical'
};

export const StartBlankBox = styled(Box, {
    shouldForwardProp: (propName) => (
        !["isSelected"].includes(propName.toString())
    )})(({isSelected}: {isSelected?: true}) => ({
    width: '150px',
    padding: '12px',
    height: '182px',
    borderRadius: '6.868px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    backgroundColor: colors.backgrounds.white,
    border: `1px solid ${isSelected ? colors.primary.blue : colors.stroke.grey}`,
    boxShadow: 'px 0px 1px 0px rgba(125, 151, 174, 0.02), 0px 2px 6px 0px rgba(125, 151, 174, 0.02), 0px 8px 16px 0px rgba(125, 151, 174, 0.08);'
}));

export const TitleBlankBox = styled(Box)(() => ({
    position: 'absolute',
    bottom: '12px',
    left: '12px'
}));

export const TitleBlank = styled(TypographyBodySmall)(() => ({
    color: colors.text.grey,
}));


export const UploadFileBox = styled(Box, {
    shouldForwardProp: (propName) => (
        !["isDisabled"].includes(propName.toString())
    )})(({isDisabled}: {isDisabled?: true}) => ({
    width: '400px',
    padding: '12px',
    height: '182px',
    borderRadius: '6.868px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    // position: 'relative',
    backgroundColor: colors.backgrounds.white,
    border: `1px dashed ${isDisabled ? colors.stroke.grey : colors.primary.blue}`,
    boxShadow: '0px 0px 1px 0px rgba(125, 151, 174, 0.02), 0px 2px 6px 0px rgba(125, 151, 174, 0.02), 0px 8px 16px 0px rgba(125, 151, 174, 0.08)',
}));

export const UploadFileTitleBox = styled(Box)(() => ({
    display: 'flex',
    marginTop: '8px'
}));

export const UploadFileIconBox = styled(Box)(() => ({
    display: 'flex',
    width: '35px',
    height: '41px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px',
    borderRadius: '6px',
    backgroundColor: colors.backgrounds.grey_light,
}));

export const TypographyBody2Grey = styled(TypographyBody2, {
    shouldForwardProp: (propName) => (
        !["isDisabled"].includes(propName.toString())
    )})(({isDisabled}: {isDisabled?: true}) => ({
    color: isDisabled ? colors.text.grey : undefined,
}));

export const TypographyBody2Blue = styled(TypographyBody2, {
    shouldForwardProp: (propName) => (
        !["isDisabled"].includes(propName.toString())
    )})(({isDisabled}: {isDisabled?: true}) => ({
    color: isDisabled ? colors.text.grey : colors.primary.blue,
    marginLeft: '6px',
}));

export const UploadedFileContainer = styled(Box)(() => ({
    marginTop: '32px',
    width: '100%',
    justifyContent: 'flex-start',
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${colors.primary.blue}`,
    borderRadius: '6px',
    padding: '8px',
}));

export const UploadedFileNameTitle = styled(TypographyBody2)(() => ({
    marginLeft: '6px',
    marginRight: '6px',
}));

export const DeleteUploadedFileIconButton = styled(IconButton)(() => ({
    marginLeft: 'auto',
    padding: '3px',
}));



