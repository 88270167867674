import {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {
    SETTINGS_INVITES_PATH,
    SETTINGS_LANGUAGE_PATH,
    SETTINGS_ORGANIZATIONS_CREATE_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_BILLING_HISTORY_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_BILLING_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_NOTIFICATIONS_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_CREATE_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_EDIT_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_USERS_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_NOTIFICATIONS_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_PLANS_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_USERS_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH,
    SETTINGS_ORGANIZATIONS_PATH,
    SETTINGS_PROFILE_PATH,
    SETTINGS_SECURITY_PATH,
    SETTINGS_SELECTOR_PATH
} from "./constants";
import {OrganizationsList} from "./pages/organizations/organizationsList";
import {Invites} from "./pages/invites";
import {Profile} from "./pages/profile";
import {Language} from "./pages/language";
import {Security} from "./pages/security";
import {OrganizationExact} from "./pages/organizations/organizationExact";
import {WorkspacesExact} from "./pages/organizations/workspacesExact";
import {RolesCreate} from "./pages/organizations/rolesCreate";
import {RolesEdit} from "./pages/organizations/rolesEdit";
import {NewOrganizationCreate} from "./pages/organizations/newOrganizationCreate";

export const Routes: FC = () => {
    return (
        <Switch>
            {/*ORGANIZATIONS*/}
            <Route exact path={SETTINGS_ORGANIZATIONS_PATH} component={OrganizationsList}/>
            <Route exact path={SETTINGS_ORGANIZATIONS_CREATE_PATH} component={NewOrganizationCreate}/>
            <Route exact path={SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH} component={OrganizationExact}/>
            <Route exact path={SETTINGS_ORGANIZATIONS_EXACT_ORG_USERS_PATH} component={OrganizationExact}/>
            <Route exact path={SETTINGS_ORGANIZATIONS_EXACT_ORG_PLANS_PATH} component={OrganizationExact}/>
            <Route exact path={SETTINGS_ORGANIZATIONS_EXACT_ORG_NOTIFICATIONS_PATH} component={OrganizationExact}/>
            <Route exact path={SETTINGS_ORGANIZATIONS_EXACT_ORG_BILLING_PATH} component={OrganizationExact}/>
            <Route exact path={SETTINGS_ORGANIZATIONS_EXACT_ORG_BILLING_HISTORY_PATH} component={OrganizationExact}/>
            <Route exact path={SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH} component={OrganizationExact}/>
            <Route exact path={SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_PATH} component={WorkspacesExact}/>
            <Route exact path={SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_USERS_PATH} component={WorkspacesExact}/>
            <Route exact path={SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_PATH} component={WorkspacesExact}/>
            <Route exact path={SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_CREATE_PATH} component={RolesCreate}/>
            <Route exact path={SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_EDIT_PATH} component={RolesEdit}/>
            <Route exact path={SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_NOTIFICATIONS_PATH} component={WorkspacesExact}/>

            {/*OTHER*/}
            <Route exact path={SETTINGS_INVITES_PATH} component={Invites}/> 
            <Route exact path={SETTINGS_PROFILE_PATH} component={Profile}/>
            <Route exact path={SETTINGS_LANGUAGE_PATH} component={Language}/>
            <Route exact path={SETTINGS_SECURITY_PATH} component={Security}/>

            <Route exact path={SETTINGS_SELECTOR_PATH} component={() => <h1>SETTINGS_SELECTOR_PATH</h1>}/>

            <Redirect to={SETTINGS_ORGANIZATIONS_PATH}/>
        </Switch>
    )
}