import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
    assetsDialogsSelector,
    cleanSoftware,
    hideExportCsv,
    loadings,
    minMaxLoadedPage,
    openExportCsv,
    openReleaseDialog,
    pageInfoSelector,
    replaceMinMaxLoadedPage,
    selectSoftwareAsset,
    setImportCsvDialogAction,
    softwareAssetsSelector,
    softwareAssetsSettingsSelector,
} from "../../store/store";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TSoftwareAsset} from "../../types";
import {PATH_LOCAL_ASSET_MANAGEMENT} from "../../../../../newShared/constants";
import {
    PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE,
    PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_ID,
    PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_NEW,
    PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_SETTINGS,
} from "../../constants";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import React, {useEffect, useState} from "react";
import {
    exportCsvSoftwareAssetsAction,
    getSoftwareAssetsAction,
    GetSoftwareAssetsAvailableFieldsForExport,
    getSoftwareAssetsSettingsAction,
} from "../../store/actions";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";


export const useSoftwareAssetsList = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    const history = useHistory();
    const dispatch = useDispatch();
    const {isLoadingList, isLoadingSettings, isLoadingExport, isLoadingFields} = useSelector(loadings);
    const softwareAssets = useSelector(softwareAssetsSelector);
    const _pageInfo = useSelector(pageInfoSelector);
    const dialogs = useSelector(assetsDialogsSelector);


    useSetBreadcrumbs([
        {
            title: tMenu('Asset Management'),
            path: PATH_LOCAL_ASSET_MANAGEMENT
        },
        {
            title: tMenu('Software'),
            path: PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE
        },
    ]);

    // const controller = new AbortController();
    //
    // //search and filters
    // const [filter, setFilter] = useState<TCommonAssetsFilter>({
    //     nameLike: '',
    //     prevSearch: '',
    // })


    // const getAssets = (page: number, count: number, _filter: TCommonAssetsFilter, enterDown: boolean) => {
    //     !isLoadingList && dispatch(getSoftwareAssetsAction({data: {
    //             page,
    //             count,
    //             filters: {
    //                 nameLike: enterDown && _filter.nameLike ?  [_filter.nameLike] : (!enterDown && _filter.prevSearch ? [_filter.prevSearch] : []),
    //                 // type: (_filter.type && _filter.type !== 'Any') ? [_filter.type] : [],
    //             }}, signal: controller.signal}))
    //     !isLoadingList && setFilter({..._filter, prevSearch: enterDown && _filter.nameLike ? _filter.nameLike  : (!enterDown && _filter.prevSearch ? _filter.prevSearch : '')})
    // }

    const getSettings = (signal?: AbortSignal) => {dispatch(getSoftwareAssetsSettingsAction({data: {organizationId: '', workspaceId: ''}, signal}))}

    // const clean = () => dispatch(cleanUp());

    const goToAsset = (asset: TSoftwareAsset) => {
        if(asset) {
            dispatch(selectSoftwareAsset(asset));
            history.push(`${PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_ID.replace(':id', asset.id)}`);
        }
    }

    useEffect(() => {
        !isLoadingList && getSettings();

        // return () => {
        //     clean();
        // }
        //eslint-disable-next-line
    }, []);

    const goToAddAsset = () => history.push(PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_NEW);
    const goToSettings = () => history.push(PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_SETTINGS);


    //MENU
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // const exportCsv = () => {
    //     handleClose();
    //     dispatch(exportCsvSoftwareAssetsAction())
    // };

    const handleExportCsv = (fieldKeys: string[]) => {
        handleClose();
        dispatch(exportCsvSoftwareAssetsAction({fieldKeys, isDownload: _pageInfo.total < 10000}))
    };

    const handleCloseExportCsv = () => {
        dispatch(hideExportCsv());
    }

    const handleOpenExportCsv = () => {
        dispatch(openExportCsv());
        dispatch(GetSoftwareAssetsAvailableFieldsForExport());
    }


    const handleImportCSV = () => {
        handleClose();
        dispatch(setImportCsvDialogAction({isOpen: true}));
    }

    //filters
    // const setSearch = (search: string) => setFilter({...filter, nameLike: search});
    //
    // const handleCleanSearch = () => {
    //     const newFilter = {...filter, nameLike: '', prevSearch: ''};
    //     setFilter(newFilter);
    //     getAssets(0, DEFAULT_COUNT, newFilter, false);
    // }
    //
    // const handleEnterKey = (e: any) => {
    //     if(e.keyCode === 13){
    //         getAssets(0, DEFAULT_COUNT, filter, true);
    //     }
    // }

    const handleOpenRelease = () => {
        dispatch(openReleaseDialog({assetType: 'software'}));
        handleClose();
    }

    // // pagination
    // const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
    //     getData: ({page, count}) => {
    //         getAssets(
    //             page, count, filter,false
    //         )
    //     },
    //     arrayLength: softwareAssets.length,
    //     isLoading: isLoadingList,
    //     pageInfo: _pageInfo,
    //     cleanData: () => {},
    //
    // })

    const {minLoadedPage, maxLoadedPage} = useSelector(minMaxLoadedPage);

    return {
        settings: useSelector(softwareAssetsSettingsSelector),
        // setSearch,
        // hasMore: _pageInfo.total > ((_pageInfo.page + 1) * _pageInfo.count),
        softwareAssets,
        goToAsset,
        goToAddAsset,
        goToSettings,
        // importCsv,
        handleImportCSV,
        handleOpenExportCsv,
        handleOpenRelease,

        menu: {
            anchorEl,
            handleClose,
            handleClick,
            open,
        },

        // filter: {
        //     filter,
        //     setFilter,
        //     setSearch,
        //     handleEnterKey,
        //     handleCleanSearch,
        // },

        exportCsv: {
            isOpen: dialogs.exportCsv.isOpen,
            availableFields: dialogs.exportCsv.fields,
            handleExportCsv,
            handleClose: handleCloseExportCsv,
        },

        isLoadingList,
        // isShowingNoAssets: !isLoadingList && softwareAssets.length === 0 && !filter.prevSearch && !filter.type?.length && !filter.nameLike.length,
        isLoadingSettings,
        isLoadingExport,
        isLoadingFields,

        // pagination: {
        //     bottomLoader: bottomLoader && softwareAssets.length < _pageInfo.total,
        //     onScroll,
        //     scrollRef
        // },

        genericTable: {
            paging: {
                pageInfo: _pageInfo,
                isLoading: isLoadingList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceMinMaxLoadedPage(data)),
                clearRows: () => dispatch(cleanSoftware()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    dispatch(getSoftwareAssetsAction({data: {page, count, filters}}));
                },
            },
        }
    }
}
