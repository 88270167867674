import {expandsType} from "./types";

export const PATH_LOCAL_ACCESS_MANAGEMENT = '/accessManagement';

export const PATH_ACCESS_MANAGEMENT_APPS_LIST = `${PATH_LOCAL_ACCESS_MANAGEMENT}/applications`;
export const PATH_ACCESS_MANAGEMENT_APPS_EXACT = `${PATH_ACCESS_MANAGEMENT_APPS_LIST}/exact/:id`;
export const PATH_ACCESS_MANAGEMENT_APPS_EXACT_CREATE_ROW = `${PATH_ACCESS_MANAGEMENT_APPS_LIST}/:id/createRow`;
export const PATH_ACCESS_MANAGEMENT_APPS_EXACT_EDIT_ROW = `${PATH_ACCESS_MANAGEMENT_APPS_LIST}/:id/editRow/:account`;
//review
export const PATH_ACCESS_MANAGEMENT_REVIEW = `${PATH_LOCAL_ACCESS_MANAGEMENT}/reviewCampaign`;
export const PATH_ACCESS_MANAGEMENT_REVIEW_EXECUTORS_LIST = `${PATH_LOCAL_ACCESS_MANAGEMENT}/reviewCampaign/executors/list`;
export const PATH_ACCESS_MANAGEMENT_REVIEW_EXECUTORS_EXACT = `${PATH_LOCAL_ACCESS_MANAGEMENT}/reviewCampaign/executors/exact/:id`;
export const PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS = `${PATH_LOCAL_ACCESS_MANAGEMENT}/reviewCampaign/instructions`;
export const PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_LIST = `${PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS}/list`;
export const PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_EXACT = `${PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS}/exact/:id`;
export const PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS_NEW = `${PATH_ACCESS_MANAGEMENT_REVIEW_INSTRUCTIONS}/new`;

//segregation
export const PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES = `${PATH_LOCAL_ACCESS_MANAGEMENT}/segregationOfDuties`;
export const PATH_ACCESS_MANAGEMENT_CONFLICT_LIST = `${PATH_LOCAL_ACCESS_MANAGEMENT}/conflictList`;
export const PATH_ACCESS_MANAGEMENT_SOD_SCHEDULER = `${PATH_LOCAL_ACCESS_MANAGEMENT}/sodScheduler`;
export const PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET = `${PATH_LOCAL_ACCESS_MANAGEMENT}/dutiesRuleset`;

export const PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET_EXACT = `${PATH_LOCAL_ACCESS_MANAGEMENT}/dutiesRuleset/exact/:id`;
export const PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET_NEW = `${PATH_LOCAL_ACCESS_MANAGEMENT}/dutiesRuleset/new`;
//profiles
export const PATH_ACCESS_MANAGEMENT_PROFILES = `${PATH_LOCAL_ACCESS_MANAGEMENT}/profiles`;

export const DEFAULT_APPS_PAGING = 20;
export const CREATE_APP_EMPLOYEES_AND_VENDORS_PAGING = 1000;

export const CUSTOM_APP_TYPE = 'CUSTOM';

export const availableCreateRowTypes = ['Mapped', 'Orphan', 'Service', 'External'];
export const availableCreateRowStatuses = ['Active', 'Inactive', 'Suspended'];

export const MAPPED_TYPE = 'Mapped';
export const EXTERNAL_TYPE = 'External';
export const ORHAN_TYPE = 'Orphan';

//campaign review
export const GET_ALL_ACCESS_MANAGE_APPS_FOR_CREATE_AND_EDIT = 1000;

export const IN_PROGRESS_STATUS = 'inProgress';
export const COMPLETE_STATUS = 'complete';
export const TERMINATED_STATUS = 'terminated';
export const FAILED_STATUS = 'failed';
export const SCHEDULE_TRIGGER_TYPE = 'schedule';

export const EXACT_INSTRUCTION_DATE_FORMAT = 'D/M/YYYY';
export const EXACT_EXECUTOR_DATE_FORMAT = 'D/M/YYYY';


export const DEFAULT_CAMPAIGN_REVIEW_INSTRUCTIONS_PAGING = 30;
export const DEFAULT_CAMPAIGN_REVIEW_EXECUTORS_PAGING = 30;

//stages
export const REVIEW_CAMPAIGN_STEP_MANAGER = 'MANAGER';
export const REVIEW_CAMPAIGN_STEP_VENDOR = 'VENDOR';
export const REVIEW_CAMPAIGN_STEP_EXECUTOR = 'EXECUTION';
export const REVIEW_CAMPAIGN_STEP_VERIFY = 'VERIFY';
export const REVIEW_CAMPAIGN_STEP_SIGN = 'SIGN';
export const REVIEW_CAMPAIGN_FINISH_STEP_CUSTOM = 'finish';

export const STAGE_SEQUENCE = [REVIEW_CAMPAIGN_STEP_MANAGER, REVIEW_CAMPAIGN_STEP_VENDOR, REVIEW_CAMPAIGN_STEP_EXECUTOR, REVIEW_CAMPAIGN_STEP_VERIFY, REVIEW_CAMPAIGN_STEP_SIGN, REVIEW_CAMPAIGN_FINISH_STEP_CUSTOM];

//account statuses
export const ACCOUNT_KEEP_STATUS = 'KEEP';
export const ACCOUNT_REVOKE_STATUS = 'REVOKE';
export const ACCOUNT_DELETE_STATUS = 'DELETE';

export const defaultExpands: expandsType = {
    info: true,
    eta: false,
    evidence: false,
    apps: false,
    schedule: false,
    finalApprover: false
}

export const PAGE_COUNT: number = 50;