import React, {FC} from "react";
import {Box, CircularProgress, circularProgressClasses, Skeleton, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {forDescription} from "../../../../../newShared/components/Typography";
import {useFrameworks} from "../../../frameworks/hooks/useFrameworks";
import {TypographyNew} from "../../../../../newShared/components/TypographyNew";


type TProps = {
    framework: {
        id: string,
        name: string,
        logo: string | null,
        description: string,
        controls: number,
        complete: number,
        evidences: number,
        evidencesFinished: number,
    } | null
}
export const FrameworkWidget: FC<TProps> = ({framework}) => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathVica.pathDashboard'});
    const {openFramework} = useFrameworks();

    if (!framework) return (
        <Box sx={{
            boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)',
            borderRadius: '20px',
            border: '0.5px solid #EAECF0',
            padding: '8px', gap: '16px',
            display: 'flex', flexDirection: 'column',
            width: '100%', height: 'fit-content',
            overflow: 'hidden',
        }}>
            <Box display={"flex"} alignItems={"center"} gap={'6px'}>
                <Skeleton variant={"circular"} width={'30px'} height={'30px'}/>
                <Typography variant={"h4"} noWrap><Skeleton width={'150px'}/></Typography>
            </Box>

            <Box display={"flex"} gap={'6px'} height={'100%'}>
                <Box display={"flex"} flexDirection={"column"} gap={'16px'} flexBasis={'66%'} justifyContent={"space-between"}
                     maxWidth={'calc(100% - 120px)'} overflow={"hidden"}>
                    <Typography variant={"body2"} fontSize={'12px'}>
                        <Skeleton width={'175px'}/>
                        <Skeleton width={'175px'}/>
                        <Skeleton width={'175px'}/>
                    </Typography>


                    <Box display={"flex"} flexDirection={"column"} gap={'6px'} mt={'auto'}>
                        <Typography variant={"body2"} color={colors.text.grey_dark}>
                            <Skeleton width={'70px'}/>
                        </Typography>
                        <Typography variant={"body2"} color={colors.text.grey_dark}>
                            <Skeleton width={'70px'}/>
                        </Typography>
                    </Box>
                </Box>

                <Box display={"flex"} flexDirection={"column"} gap={'16px'} position={'relative'} justifyContent={"center"}>
                    <Box sx={{
                        top: 0, left: 0, bottom: 0, right: 0,
                        position: 'absolute',
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                    }}>
                        <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                        ><Skeleton width={'20px'}/></Typography>
                    </Box>

                    <CircularProgress
                        size={120} variant={"determinate"} value={100}
                        sx={{
                            color: (theme) =>
                                theme.palette.grey[theme.palette.mode === "light" ? 200 : 800]
                        }}
                        thickness={5}
                    />
                    <CircularProgress
                        size={120} variant={"indeterminate"}
                        disableShrink
                        sx={{
                            color: (theme) =>
                                theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
                            position: "absolute",
                            left: 0,
                            [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: "round"
                            }
                        }}
                        thickness={5}
                    />
                </Box>
            </Box>
        </Box>
    );


    const {name, logo, description, controls, id, evidences, evidencesFinished} = framework;

    return (
        <Box sx={{
            boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)',
            borderRadius: '20px',
            border: '0.5px solid #EAECF0',
            padding: '10px', gap: '16px',
            display: 'flex', flexDirection: 'column',
            width: '100%', height: 'fit-content',
            overflow: 'hidden',
        }}>
            <Box display={"flex"} alignItems={"center"} gap={'6px'}
                 sx={{
                     '& h3': {
                         cursor: 'pointer'
                     },
                     '& h3:hover': {
                         color: colors.primary.hovered
                     }
                 }}
            >
                <img src={logo || undefined} alt={name + ' logo'} style={{maxWidth: '30px', maxHeight: '30px'}}/>
                <Typography variant={"h3"} noWrap onClick={() => openFramework(id)}>{name}</Typography>
            </Box>

            <Box display={"flex"} gap={'6px'} height={'100%'} width={'100%'}>
                <Box display={"flex"} flexDirection={"column"} gap={'16px'} flexGrow={1} justifyContent={"space-between"}
                     maxWidth={'calc(100% - 120px)'} overflow={"hidden"}>
                    <TypographyNew variant={"body2"} fontSize={'12px'} {...forDescription(revDir)}
                                sx={{
                                    overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', maxHeight: '100%',
                                    '-webkit-box-orient': 'vertical', "-webkit-line-clamp": '3', display: '-webkit-box',
                                    overflowWrap: 'break-word',
                                }}>
                        {description}
                    </TypographyNew>

                    <Box display={"flex"} flexDirection={"column"} gap={'6px'} mt={'auto'}>
                        <Typography variant={"body2"} color={colors.text.grey_dark}>
                            {`${t('Controls')}: ${controls}`}
                        </Typography>
                        <Typography variant={"body2"} color={colors.text.grey_dark}>
                            {`${t('Completed evidences')}: ${evidencesFinished}/${evidences}`}
                        </Typography>
                    </Box>
                </Box>

                <Box display={"flex"} flexDirection={"column"} gap={'16px'} position={'relative'} justifyContent={"center"}>
                    <Box sx={{
                        top: 0, left: 0, bottom: 0, right: 0,
                        position: 'absolute',
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                    }}>
                        <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                        >{`${controls ? Math.round(evidencesFinished/evidences*100) : 100}%`}</Typography>
                    </Box>

                    <CircularProgress
                        size={120} variant={"determinate"} value={100}
                        sx={{
                            color: (theme) =>
                                theme.palette.grey[theme.palette.mode === "light" ? 200 : 800]
                        }}
                        thickness={5}
                    />
                    <CircularProgress
                        size={120} variant={"determinate"} value={Math.round(evidencesFinished/evidences*100)}
                        disableShrink
                        sx={{
                            color: (theme) =>
                                theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
                            position: "absolute",
                            left: 0,
                            [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: "round"
                            }
                        }}
                        thickness={5}
                    />
                </Box>
            </Box>
        </Box>
    )
}
