import {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {
    PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED,
    PATH_LOCAL_COMPLIANCE_FRAMEWORKS_AVAILABLE
} from "../../../newShared/constants";
import {AdoptedFrameworks} from "./pages/adoptedFrameworks";
import {AvailableFrameworks} from "./pages/availableFrameworks";
import {FrameworkView} from "./pages/frameworkView";
import {ControlView} from "./pages/controlView";
import {PATH_FRAGMENT_DELEGATED_TASKS} from "./constants";

export const Routes: FC = () => {

    return <Switch>
        <Route exact path={PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED} component={AdoptedFrameworks}/>
        <Route exact path={`${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/:frId`} component={FrameworkView}/>
        <Route exact path={`${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/:frId/control/:controlId`} component={ControlView}/>

        <Route exact path={[
            `${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/:frId/control/:controlId/evidences`,
            `${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/:frId/control/:controlId/evidences/:evidenceId`
        ]} component={ControlView}/>
        <Route exact path={`${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/:frId/control/:controlId/comments`} component={ControlView}/>
        {/*<Route exact path={[*/}
        {/*    `${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/:frId/control/:controlId/analysis`,*/}
        {/*    `${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/:frId/control/:controlId/analysis/:analysisId/:historyId`*/}
        {/*]} component={ControlView}/>*/}
        <Route exact path={[
            `${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/:frId/control/:controlId/${PATH_FRAGMENT_DELEGATED_TASKS}`,
            `${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/:frId/control/:controlId/${PATH_FRAGMENT_DELEGATED_TASKS}/:taskId`,
        ]} component={ControlView}/>

        <Route exact path={PATH_LOCAL_COMPLIANCE_FRAMEWORKS_AVAILABLE} component={AvailableFrameworks}/>

        <Redirect to={PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/>
    </Switch>
}
