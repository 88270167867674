import {createSelector, createSlice} from "@reduxjs/toolkit";
import {TPageInfo} from "../../../../../newShared/types";
import {AppState} from "../../../../../newShared/redux/rootReducer";
import {TFramework} from "../../types";
import {adoptFrameworkToWorkspaceAction, getAdoptedFrameworksAction, getAvailableFrameworksAction} from "./actions";
import {setPaginationInStore} from "../../../../../newShared/hooks/useScroll/helpers";

export type TFrameworkSlice = {
    adopted: {
        frameworks: TFramework[],
        // pageInfo: TPageInfo,

        isLoading: boolean,
    },

    gallery: {
        frameworks: (TFramework | null)[],
        pageInfo: TPageInfo,

        isLoading: boolean,
    },

    isLoading: {
        isAdopting: string[],
    },

    dialogs: {

    },
}

const initialState: TFrameworkSlice = {
    adopted: {
        frameworks: [],
        // pageInfo: {count: 20, page: 0, total: 0},

        isLoading: false,
    },

    gallery: {
        frameworks: [],
        pageInfo: {page: 0, count: 50, total: 0},

        isLoading: false,
    },

    isLoading: {
        isAdopting: [],
    },

    dialogs: {

    },
}

const Slice = createSlice({
    name: 'frameworks',
    initialState,
    reducers: {
        cleanAction(slice: TFrameworkSlice) {
            slice.adopted = initialState.adopted;
            slice.gallery = initialState.gallery;
            slice.dialogs = initialState.dialogs;
            slice.isLoading = initialState.isLoading;
        },

        cleanArrayAction(slice: TFrameworkSlice, {payload}: {payload: 'adopted' | 'gallery'}) {
            switch (payload) {
                case "gallery": {
                    slice.gallery = initialState.gallery;
                    break;
                }
                case "adopted": {
                    slice.adopted = initialState.adopted;
                    break;
                }
            }
        },
    },
    extraReducers: builder => {
      builder
          .addCase(getAdoptedFrameworksAction.pending, (slice: TFrameworkSlice) => {
              slice.adopted.isLoading = true;
          })
          .addCase(getAdoptedFrameworksAction.rejected, (slice: TFrameworkSlice) => {
              // slice.adopted.frameworks = [];
              slice.adopted.isLoading = false;
          })
          .addCase(getAdoptedFrameworksAction.fulfilled, (slice: TFrameworkSlice, {payload}) => {
              slice.adopted.isLoading = false;
              slice.adopted.frameworks = payload.resp;
          })

          .addCase(getAvailableFrameworksAction.pending, (slice: TFrameworkSlice, {meta}) => {
              slice.gallery.isLoading = true;
          })
          .addCase(getAvailableFrameworksAction.rejected, (slice: TFrameworkSlice) => {
              slice.gallery.isLoading = false;
          })
          .addCase(getAvailableFrameworksAction.fulfilled, (slice: TFrameworkSlice, {payload, meta}) => {
              slice.gallery.isLoading = false;

              const {array, pageInfo} = setPaginationInStore<TFramework>(
                  slice.gallery.pageInfo,
                  slice.gallery.frameworks,
                  payload.pageInfo,
                  payload.frameworks,
                  // payload.isNewOnTop
              );
              slice.gallery.frameworks = array;
              slice.gallery.pageInfo = pageInfo;
          })

          .addCase(adoptFrameworkToWorkspaceAction.pending, (slice: TFrameworkSlice, payload) => {
              slice.isLoading.isAdopting.push(payload.meta.arg.frameworkId);
          })
          .addCase(adoptFrameworkToWorkspaceAction.rejected, (slice: TFrameworkSlice, payload) => {
              slice.isLoading.isAdopting = slice.isLoading.isAdopting.filter(e => e !== payload.meta.arg.frameworkId);
          })
          .addCase(adoptFrameworkToWorkspaceAction.fulfilled, (slice: TFrameworkSlice, {payload}) => {
              slice.isLoading.isAdopting = slice.isLoading.isAdopting.filter(e => e !== payload.resp.id);
              const frameworks = slice.gallery.frameworks;
              const index = frameworks.findIndex(e => e?.id === payload.resp.id);
              if (index > -1) {
                  frameworks[index] = payload.resp;
                  slice.gallery.frameworks = frameworks;
              }
          })
    }
});

export const frameworksReducerReducer = Slice.reducer;

export const {
    cleanAction, cleanArrayAction,

} = Slice.actions;

const selectSelf = (state: AppState): TFrameworkSlice => state.frameworks;

export const adoptedFrameworksSelector = createSelector(selectSelf, state => state.adopted);
export const galleryFrameworksSelector = createSelector(selectSelf, state => state.gallery);

export const isLoadingFrameworksSelector = createSelector(selectSelf, state => state.isLoading);
export const dialogsFrameworksSelector = createSelector(selectSelf, state => state.dialogs);
