import {MainTrainingsSectionsShortDataForImportResponseModel} from "../../../../../newShared/GQLTypes";
import {Typography, Checkbox, TableRow, TableCell, IconButton} from "@mui/material";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import React from "react";
import {SectionToAddTableRow} from "../sectionToAddTableRow";
import {trainingLevelTitleMapper} from "../../constants";
import {Flex} from "../../../../../newShared/components/Layouts";
import {useSectionsBlock} from "../../hooks/useSectionsBlock";

export const SectionsBlock = ({trainingSection}: {trainingSection: MainTrainingsSectionsShortDataForImportResponseModel}) => {
    const {
        isCheckboxChecked,
        isCheckboxIndeterminate,
        handleCheckboxSelect,
        isExpanded,
        setIsExpanded} = useSectionsBlock(trainingSection);

    return (
        <>
            <TableRow>
                <TableCell>
                    <Flex ai={'center'} jc={'flex-start'} gap={'10px'}>
                        <Checkbox checked={isCheckboxChecked} onChange={handleCheckboxSelect} indeterminate={isCheckboxIndeterminate}/>
                        {isExpanded
                        ?
                        <IconButton sx={{cursor: 'pointer'}} onClick={() => setIsExpanded(false)}>
                            <ExpandLessOutlinedIcon/>
                        </IconButton>
                        :
                        <IconButton sx={{cursor: 'pointer'}} onClick={() => setIsExpanded(true)}>
                            <ExpandMoreOutlinedIcon/>
                        </IconButton>}
                        <Typography variant={'*bodyText2'}>{trainingSection.trainingName}</Typography>
                    </Flex>
                </TableCell>
                <TableCell>
                    <Typography variant={'*bodyText2'}>{trainingSection.trainingCategory}</Typography>
                </TableCell>
                <TableCell colSpan={2}>
                    <Typography variant={'*bodyText2'}>{trainingLevelTitleMapper[trainingSection.trainingLevel]}</Typography>
                </TableCell>
            </TableRow>
            {isExpanded && trainingSection.sections &&
                trainingSection.sections.map(item => <SectionToAddTableRow key={item.sectionId} section={item} />)
            }

        </>
    )

}