import React from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import colors from "../../../../../../newShared/theme/colors";
import {
    HrTimeOffStepsStatus,
    HrTimeOffStepsType,
    PortalHrTimeOffRequestApprovalRoutesStepsModel
} from "../../../../../../newShared/GQLTypes";
import {hrTimeOffStepStatusTitleMapper, hrTimeOffStepTypeTitleMapper} from "../../../constants";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {useHrTimeOffHistory} from "../../../hooks/dialogHooks/useTimeOffHistory";
import {getTimeOffStepStatusChip} from "../../../helpers";


export const TimeOffHistoryDialog = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathHRManagement.pathTimeOff'});
    const {
        isOpen,
        selected,
        handleBack,
    } = useHrTimeOffHistory();

    const isStatusViewed = (currentStep: PortalHrTimeOffRequestApprovalRoutesStepsModel) => {
        if (currentStep.type === HrTimeOffStepsType.HrManagerApproval) return currentStep.status !== HrTimeOffStepsStatus.Pending && currentStep.status !== HrTimeOffStepsStatus.Created;
        else return true;
    }

    const isCircleBackgroundBlue = (currentStep: PortalHrTimeOffRequestApprovalRoutesStepsModel) => {
        return currentStep.type === HrTimeOffStepsType.Creation || currentStep.status === HrTimeOffStepsStatus.Approved || currentStep.status === HrTimeOffStepsStatus.Rejected || currentStep.status === HrTimeOffStepsStatus.Skipped;
    }

    const circleBorderColor = (currentStep: PortalHrTimeOffRequestApprovalRoutesStepsModel) => {
         return currentStep.type === HrTimeOffStepsType.Creation || currentStep.stepNumber === selected?.currentStepNumber
                || currentStep.status === HrTimeOffStepsStatus.Approved || currentStep.status === HrTimeOffStepsStatus.Rejected
                || currentStep.status === HrTimeOffStepsStatus.Skipped ?  colors.primary.blue :  colors.stroke.grey;
    }

    const titleColor = (currentStep: PortalHrTimeOffRequestApprovalRoutesStepsModel) => {
        if (currentStep.type === HrTimeOffStepsType.Creation || currentStep.stepNumber === selected?.currentStepNumber) return colors.primary.blue;
        else return currentStep.status === HrTimeOffStepsStatus.Approved || currentStep.status === HrTimeOffStepsStatus.Rejected || currentStep.status === HrTimeOffStepsStatus.Skipped ? colors.primary.blue : colors.text.grey;
    }

    const contentBorderColor = (currentStep: PortalHrTimeOffRequestApprovalRoutesStepsModel) => {
        if (currentStep.type === HrTimeOffStepsType.Creation ) return colors.primary.blue;
        else if (currentStep.type === HrTimeOffStepsType.ManagerApproval) return currentStep.status === HrTimeOffStepsStatus.Approved || currentStep.status === HrTimeOffStepsStatus.Rejected || currentStep.status === HrTimeOffStepsStatus.Skipped ? colors.primary.blue : colors.stroke.grey;
        else if (currentStep.type === HrTimeOffStepsType.HrManagerApproval) return undefined;
    }

    return (
        <Dialog open={isOpen} onClose={handleBack} scroll={'paper'}>
            <DialogTitle>{t('Time off request approval history')}</DialogTitle>

            <DialogContent>
                { selected && selected.approvalRoutes.map((e, index) => (
                    <Box key={JSON.stringify(e)} display={'flex'} flexDirection={'column'} width={'100%'} >
                        { e.steps.map((step, i) => (
                            <Box key={JSON.stringify(step)} display={'flex'} flexDirection={'column'} width={'100%'}>

                                {/*Title*/}
                                <Box display={"flex"} width={'100%'} marginTop={i===0 ? '26px' : 0} gap={'20px'} justifyContent={'flex-start'} alignItems={'center'}>
                                    <Box sx={{ width: '12px', height: '12px', borderRadius: '50%',
                                        background: isCircleBackgroundBlue(step) ? colors.primary.blue : undefined,
                                        border: `2px solid ${circleBorderColor(step)}` }}
                                    />

                                    <Box display={'flex'} width={'100%'} position={'relative'}>
                                        <Box sx={{position: 'absolute', bottom: '35px'}}>
                                            <Typography variant={'*bodySmall'} color={colors.text.grey}>{`${t('Step')} ${step.stepNumber}`}</Typography>
                                        </Box>
                                        <Box width={'100%'} display={'flex'}  justifyContent={'space-between'}>
                                            <Typography variant={'*h3'} color={titleColor(step)}>{hrTimeOffStepTypeTitleMapper[step.type]}</Typography>
                                            { isStatusViewed(step) && getTimeOffStepStatusChip(step.status)}
                                        </Box>
                                    </Box>
                                </Box>

                                {/*Body*/}
                                <Box display={"flex"} flexDirection={'column'} width={'100%'} gap={'10px'}
                                     sx={{ml: '4px', p: '12px 26px 60px',borderLeft: `2px solid ${contentBorderColor(step)}` }}>

                                    {step.type === HrTimeOffStepsType.Creation &&
                                        <Box display={'flex'} justifyContent={'space-between'}>
                                            <Typography variant={'*bodyText2'} color={colors.text.dark}>{`by ${selected.requestor.employeeFullName?.length ? selected.requestor.employeeFullName : selected.requestor.collaboratorFullName}`}</Typography>
                                            <Typography variant={'*bodyText2'} color={colors.text.dark}>{parseDateAuto(selected.requestDate, false, undefined, undefined, '-')}</Typography>
                                        </Box>
                                    }

                                    {step.status === HrTimeOffStepsStatus.Skipped &&
                                        <Box display={'flex'} justifyContent={'space-between'}>
                                            <Typography variant={"*bodyText2"} color={colors.text.dark}>{`${t('Manager')}: ${e.steps.find(s => s.type === HrTimeOffStepsType.ManagerApproval)?.resolver?.employeeFullName ?? e.steps.find(s => s.type === HrTimeOffStepsType.ManagerApproval)?.resolver?.collaboratorFullName}`}</Typography>
                                            <Typography variant={'*bodyText2'} color={colors.text.dark}>{parseDateAuto(step.resolveDate ?? '', false, undefined, undefined, '-')}</Typography>
                                        </Box>
                                    }

                                    {(step.status === HrTimeOffStepsStatus.Approved || step.status === HrTimeOffStepsStatus.Rejected) &&
                                        <Box display={'flex'} justifyContent={'space-between'}>
                                            <Typography variant={'*bodyText2'} color={colors.text.dark}>{`${hrTimeOffStepStatusTitleMapper[step.status]} by ${step.resolver?.employeeFullName ?? step.resolver?.collaboratorFullName}`}</Typography>
                                            <Typography variant={'*bodyText2'} color={colors.text.dark}>{parseDateAuto(step.resolveDate ?? '', false, undefined, undefined, '-')}</Typography>
                                        </Box>
                                    }

                                    {(step.type === HrTimeOffStepsType.Creation || step.status === HrTimeOffStepsStatus.Approved || step.status === HrTimeOffStepsStatus.Rejected || step.status === HrTimeOffStepsStatus.Skipped) && !!step.comment?.length &&
                                        <>
                                            <Typography variant={'*bodySmall'} color={colors.text.grey}>{t('Comment')}</Typography>
                                            <Typography variant={'*bodySmall'} color={colors.text.grey_dark} sx={{whiteSpace: 'pre-line'}}>{step.comment}</Typography>
                                        </>
                                    }
                                    {step.stepNumber === selected.currentStepNumber &&
                                        <Typography variant={'*bodySmall'} color={colors.text.grey_dark}>{step.type === HrTimeOffStepsType.ManagerApproval ?
                                            `${t('Awaiting approval from {{employeeFullName}}', {employeeFullName: step.resolver?.employeeFullName ?? step.resolver?.collaboratorFullName})}` : t('Awaiting approval')}
                                        </Typography>
                                    }
                                </Box>
                            </Box>
                        ))}
                    </Box>
                ))}
            </DialogContent>
            <DialogActions sx={{justifyContent: 'flex-start'}}>
                <Button startIcon={<ArrowBackIosIcon style={{fontSize: 'small'}} />} size={'small'}
                        onClick={handleBack} variant={'text'} sx={{alignSelf: 'start'}}>
                    {tCommon('Back')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
