import {FC, useEffect} from "react";
import {Menu, MenuItem, Popover} from "@mui/material";
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {useDecorationControl} from "./hooks";
import {CirclePicker, ColorResult} from 'react-color';

import {
    DecorFormatColorTextIcon,
    DecorIconButton,
    DecorStrikethroughSIcon,
    DecorSubscriptIcon,
    DecorSuperscriptIcon,
    MenuItemTitle
} from "./styled";
import {withShrinkZero} from "../../../hoc/withShrinkZero";
import {TToolBarHandlerAction, TToolBarHandlerPayload} from "../../types";
import {DEFAULT_COLORS} from "../../constants";
import {EditorTooltip} from "../../../commonComponents/editorTooltip";


export interface DecorationControlProps {
    isBold: true | undefined;
    isItalic: true | undefined;
    isUnderline: true | undefined;
    isStrikethrough: true | undefined;
    color: string;
    isSuperscript: true | undefined;
    isSubscript: true | undefined;
    changeDecorationControlHandler: (action: TToolBarHandlerAction, payload: TToolBarHandlerPayload) => void;
    isDisabled: boolean;
}

const DecorationControl: FC<DecorationControlProps> = ({
                                                           isBold,
                                                           isItalic,
                                                           isUnderline,
                                                           color,
                                                           isStrikethrough,
                                                           isSuperscript,
                                                           isSubscript,
                                                           changeDecorationControlHandler,
                                                           isDisabled
                                                       }) => {

    const {
        openMenu,
        menuAnchorEl,
        menuHandleClick,
        menuHandleClose,
        openColor,
        colorAnchorEl,
        colorHandleClick,
        colorHandleClose,
        // internalColor,
        changeColorHookHandler
    } = useDecorationControl({menuAnchorEl: null, colorAnchorEl: null, color, changeDecorationControlHandler});


    const changeColorPickerHandler = (color: string) => {
        changeColorHookHandler(color);
        // changeDecorationControlHandler("color", color);
        // colorHandleClose();
    }

    const isMac = navigator.userAgent.toUpperCase().indexOf('MAC') >= 0;

    function shortCutHandler (e: KeyboardEvent) {
        if (isDisabled || !(isMac ? e.metaKey : e.ctrlKey)) return;

        switch (e.key) {
            case "b": {
                e.preventDefault();
                changeDecorationControlHandler("bold", !isBold);
                break;
            }
            case "i": {
                e.preventDefault();
                changeDecorationControlHandler("italic", !isItalic);
                break;
            }
            case "u": {
                e.preventDefault();
                changeDecorationControlHandler("underline", !isUnderline);
                break;
            }
            case "s": {
                if (!e.shiftKey) return;
                e.preventDefault();
                changeDecorationControlHandler("strikethrough", !isStrikethrough);
                break;
            }
            case ",": {
                if (!e.shiftKey) return;
                e.preventDefault();
                changeDecorationControlHandler("subscript", !isSubscript);
                break;
            }
            case ".": {
                if (!e.shiftKey) return;
                e.preventDefault();
                changeDecorationControlHandler("superscript", !isSuperscript);
                break;
            }
        }
    }

    useEffect(() => {
        !isDisabled && document.addEventListener("keydown", shortCutHandler);
        return () => {
            document.removeEventListener("keydown", shortCutHandler);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDisabled, changeDecorationControlHandler, isBold, isItalic, isUnderline, isStrikethrough, isSuperscript, isSubscript]);

    return <>
        <EditorTooltip title="Bold">
            <span>
                <DecorIconButton active={isBold}
                                 disabled={isDisabled}
                                 size="medium"
                                 edge={undefined}
                                 onClick={(e) => {
                                     e.stopPropagation();
                                     changeDecorationControlHandler("bold", !isBold)
                                 }}>
                <FormatBoldIcon/>
            </DecorIconButton>
            </span>
        </EditorTooltip>
        <EditorTooltip title="Italic">
            <span>
                <DecorIconButton active={isItalic}
                                 disabled={isDisabled}
                                 size="medium"
                                 onClick={(e) => {
                                     e.stopPropagation();
                                     changeDecorationControlHandler("italic", !isItalic)
                                 }}>
                <FormatItalicIcon/>
            </DecorIconButton>
            </span>
        </EditorTooltip>
        <EditorTooltip title="Underline">
            <span>
                <DecorIconButton active={isUnderline}
                                 disabled={isDisabled}
                                 size="medium"
                                 onClick={(e) => {
                                     e.stopPropagation();
                                     changeDecorationControlHandler("underline", !isUnderline)
                                 }}>
                <FormatUnderlinedIcon/>
            </DecorIconButton>
            </span>
        </EditorTooltip>
        <EditorTooltip title="Text color">
            <span>
                <DecorIconButton id="color-button"
                                 disabled={isDisabled}
                                 size="medium"
                                 aria-controls={openColor ? 'color-menu' : undefined}
                                 aria-haspopup="true"
                                 aria-expanded={openColor ? 'true' : undefined}
                                 onClick={colorHandleClick}>
                <DecorFormatColorTextIcon btnColor={isDisabled ? undefined : color}/>
            </DecorIconButton>
            </span>
        </EditorTooltip>
        <EditorTooltip title="More options">
            <span>
                <DecorIconButton id="more-button"
                                 disabled={isDisabled}
                                 size="medium"
                                 aria-controls={openMenu ? 'font-menu' : undefined}
                                 aria-haspopup="true"
                                 aria-expanded={openMenu ? 'true' : undefined}
                                 onClick={(e) => {
                                     menuHandleClick(e);
                                     changeDecorationControlHandler("moreToolsOpenClose", true)
                                 }}>
                <MoreHorizIcon/>
            </DecorIconButton>
            </span>
        </EditorTooltip>

        <Menu id="more-menu"
              anchorEl={menuAnchorEl}
              open={openMenu}
              onClose={menuHandleClose}
              MenuListProps={{'aria-labelledby': 'more-button'}}>
            <MenuItem onClick={(e) => {
                e.stopPropagation();
                changeDecorationControlHandler("strikethrough", !isStrikethrough)
                menuHandleClose();
            }}>
                <DecorStrikethroughSIcon active={isStrikethrough}/>
                <MenuItemTitle active={isStrikethrough}>Strikethrough</MenuItemTitle>
            </MenuItem>
            <MenuItem onClick={(e) => {
                e.stopPropagation();
                changeDecorationControlHandler("superscript", !isSuperscript)
                menuHandleClose();
            }}>
                <DecorSuperscriptIcon active={isSuperscript}/>
                <MenuItemTitle active={isSuperscript}>Superscript</MenuItemTitle>
            </MenuItem>
            <MenuItem onClick={(e) => {
                e.stopPropagation();
                changeDecorationControlHandler("subscript", !isSubscript)
                menuHandleClose();
            }}>
                <DecorSubscriptIcon active={isSubscript}/>
                <MenuItemTitle active={isSubscript}>Subscript</MenuItemTitle>
            </MenuItem>
        </Menu>
        <Popover
            id='color-menu'
            open={openColor}
            anchorEl={colorAnchorEl}
            onClose={colorHandleClose}
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
            <CirclePicker
                styles={{default: {card: {padding: '8px'}}}}
                circleSize={28}
                circleSpacing={10}
                onChange={(color: ColorResult) => changeColorPickerHandler(color.hex)}
                colors={DEFAULT_COLORS}
                color={color}/>
        </Popover>
    </>;
}

const wrapper = withShrinkZero<DecorationControlProps>(DecorationControl);

export {wrapper as DecorationControl};
