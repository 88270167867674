import {FC, useEffect} from 'react';
import {useDispatch} from "react-redux";
import {useGenericFiltersStorage} from "../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {LeftSecondMenu} from "../../barsEnvironment/leftMenu/components/leftSecondMenu";
import { Routes } from './routes';
import { cleanUp } from './store/slice';


export const VicaRefactor:FC = () => {
    const dispatch = useDispatch();
    const {clearFilters} = useGenericFiltersStorage();

    useEffect(() => {
        return () => {
            clearFilters();
            dispatch(cleanUp());
        }
        //eslint-disable-next-line
    }, []);

    return(
        <LeftSecondMenu>
            <Routes />
        </LeftSecondMenu>
    )
}
