import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_VULNERABILITY, PATH_LOCAL_VULNERABILITY_SCANNED} from "../../../../../newShared/constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {loadings, selectedTarget} from "../../store/slice";
import React, {useEffect, useState} from "react";
import {GetShortTargetById, UpdateTarget} from "../../store/actions";
import {scanTypes, updateTargetRequestType} from "../../types";
import {getDefaultUpdateTargetForm} from "../../helpers/index";

export const useEditTarget = () => {
    const id = useParams<{id: string}>().id;
    const dispatch = useDispatch();
    const history = useHistory();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const _target = useSelector(selectedTarget);
    const {isLoadingUpdateTarget, isLoadingSmallTargetById} = useSelector(loadings);

    const controller = new AbortController();

    useEffect(() => {
        if(!_target && !isLoadingUpdateTarget){
            dispatch(GetShortTargetById({data: {id, onError: handleGoBack}, signal: controller.signal}));
        }

        return () => {
            controller.abort();
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(_target){
            setForm({id: _target.id, name: _target.name, target: _target.target, configTarget: {availableScanTypes: _target.configTarget.availableScanTypes as scanTypes[]}});
        }
        //eslint-disable-next-line
    }, [_target]);

    useSetBreadcrumbs([
        {
            title: tMenu('Vulnerability Scanner'),
            path: PATH_LOCAL_VULNERABILITY_SCANNED
        },
        {
            title: tMenu('Scanned items'),
            path: PATH_LOCAL_VULNERABILITY
        },
        {
            title: _target?.name ?? 'Loading target...',
            path: `${PATH_LOCAL_VULNERABILITY_SCANNED}/${id}`
        },
        {
            title: 'Edit target',
            path: `${PATH_LOCAL_VULNERABILITY_SCANNED}/edit/${id}`
        }
    ]);

    const handleGoBack = () => {
        history.push(`${PATH_LOCAL_VULNERABILITY_SCANNED}/${id}`);
    }

    const isOk = () => {
        if(!form.name.length) return false;
        return _target ? form.name.trim() !== _target.name.trim() : false;
    }

    const [form, setForm] = useState<updateTargetRequestType>(getDefaultUpdateTargetForm());

    const handleChange = (e: React.ChangeEvent<{name?:string | undefined; value?:unknown}>) => {
        e.target.name && setForm({...form, [e.target.name]: e.target.value as string});
    }

    const handleUpdateTarget = () => {
        isOk() && dispatch(UpdateTarget({target: form, onSuccess: handleGoBack}));
    }

    return{
        handleGoBack,
        isLoading: isLoadingUpdateTarget || isLoadingSmallTargetById,
        target: _target,
        isOk,
        form,
        handleChange,
        handleUpdateTarget,
        id
    }
}