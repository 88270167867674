import {FC, ReactNode} from "react";
import {FormControlLabel, Switch, SwitchProps} from "@mui/material";

type TCommonSwitch = SwitchProps & {
    label?: string | ReactNode,
}

const CommonSwitch: FC<TCommonSwitch> = ({label, sx, ...props}) => {

    return (label && label !== '') ? (
            <FormControlLabel
                sx={sx}
                control={<Switch sx={{ m: 1 }} {...props} />}
                label={label}
            />
        ) : (
            <Switch {...props} sx={sx} />
        )
}

export default CommonSwitch;
