import * as S from '../styled';
import {Flex} from "../../../../../newShared/components/Layouts";
import {Skeleton, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";

export const AppCardSkeleton = () => {
    return(
        <S.IntegrationCard disableHover>
            <Flex w={'100%'} jc={'center'}>
                <Skeleton width={'40%'} height={'100px'}/>
            </Flex>

            <Flex w={'100%'} jc={'center'}>
                <Typography
                    color={colors.grayText}
                    fontSize={'16px'}
                    fontWeight={700}
                    sx={{
                        margin: '8px 0 0 0',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        lineHeight: 'normal',
                        whiteSpace: 'nowrap'
                    }}
                >
                    <Skeleton width={100}/>
                </Typography>
            </Flex>

            <Flex w={'100%'} jc={'center'} ai={'center'} m={'8px 0 0 0'}>
                <Typography color={colors.primary.blue} fontSize={'15px'} fontWeight={600}>
                    <Skeleton width={100}/>
                </Typography>
            </Flex>
        </S.IntegrationCard>
    )
}