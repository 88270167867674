import {useHistory, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    assetsDialogsSelector,
    assetsSettingsSelector,
    deselectAsset,
    loadings,
    selectedAssetSelector,
    setChangeStatusDialogAction,
    setCreateSettingRowDialogAction,
    setDeleteAssetDialogAction
} from "../../store/store";
import {
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS,
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_ID
} from "../../constants";
import {
    deletePhysicalAssetAction,
    GetPhysicalAssetById,
    getPhysicalAssetsSettingsAction,
    updatePhysicalAssetAction
} from "../../store/actions";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_ASSET_MANAGEMENT} from "../../../../../newShared/constants";
import {
    TAsset,
    TCreateField,
    TExactAssetTabs,
    TSetChangeStatusDialog,
    TSetCreateSettingRowDialog,
    TSetDeleteAssetDialog
} from "../../types";
import {madeAssetTrim} from "../../helpers";


export const usePhysicalAssetExact = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const dispatch = useDispatch();
    const history = useHistory();
    const id = useParams<{id: string}>().id;
    const _selected = useSelector(selectedAssetSelector);
    const settings = useSelector(assetsSettingsSelector);
    const {isLoadingExact, isLoadingSettings} = useSelector(loadings);
    const {
        deleteAsset: {isOpen: isOpenDeleteAssetDialog, response: responseDelete},
        changeStatus: {isOpen: isOpenChangeStatusDialog},
    }= useSelector(assetsDialogsSelector);

    const handleGoBack = () => {
        history.push(PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS);
    }

    const controller = new AbortController();

    const [editInfoMode, setEditInfoMode] = useState<boolean>(false);
    const [tmpAsset, setTmpAsset] = useState<TAsset | null>(null);
    const [oldId, setOldId] = useState<string>('');


    useEffect(() => {
        if(!_selected){
            dispatch(GetPhysicalAssetById({data: {id, onError: handleGoBack}, signal: controller.signal}));
        }
        if(!settings) {
            dispatch(getPhysicalAssetsSettingsAction({data: {organizationId: '', workspaceId: ''}, signal: controller.signal}))
        }

        return () => {
            dispatch(deselectAsset());
            setTmpAsset(null);
            setOldId('');
            setEditInfoMode(false);
            controller.abort();
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (_selected && _selected?.id !== id) history.push(`${PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_ID.replace(':id', _selected?.id)}`);

        //eslint-disable-next-line
    }, [_selected?.id]);

    useSetBreadcrumbs([
        {
            title: tMenu('Asset Management'),
            path: PATH_LOCAL_ASSET_MANAGEMENT
        },
        {
            title: tMenu('Physical assets'),
            path: PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS
        },
        {
            title: _selected?.assetId ? _selected.assetId : 'Loading...',
            path: _selected ? PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_ID.replace(':id', _selected.id) : undefined
        },
    ]);

    //MENU
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //tabs
    const [tab, setTab] = useState<TExactAssetTabs>('information');

    //actions
    const handleSaveInfo = () => {
        tmpAsset && isOkId() && dispatch(updatePhysicalAssetAction({workspaceId: '', organizationId: '', asset: madeAssetTrim(tmpAsset), assetId: oldId}));
        setEditInfoMode(false);
    }

    const handleCancelEditInfo = () => {
        setTmpAsset(_selected);
        setEditInfoMode(false)
    }

    const handleEditInfoBtn = () => {
        setEditInfoMode(true);
        if (_selected) {
            setOldId(_selected.id);
            setTmpAsset(_selected);
        }
    }

    const isOkId = () => {
        return  tmpAsset?.assetId?.trim() === "" || tmpAsset?.assetId?.match(/^[A-Za-z0-9-_]+$/);
    }




    //Dialogs
    const setDeleteAssetDialog = (data: TSetDeleteAssetDialog) => {dispatch(setDeleteAssetDialogAction(data))};
    const setChangeStatusDialog = (data: TSetChangeStatusDialog) => {dispatch(setChangeStatusDialogAction(data))}

    const handleDeleteAsset = () => {
        handleClose();
        dispatch(deletePhysicalAssetAction({workspaceId: '', organizationId: '', assetId: _selected?.id || ''}));
    }

    const handleCloseDeleteDialog = () => {
        setDeleteAssetDialog({clear: true});
        history.push(PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS);
    }

    const handleCancelDeleteAsset = () => {
        setDeleteAssetDialog({clear: true});
    }

    const setCreateSettingRowDialog = (data: TSetCreateSettingRowDialog) => {dispatch(setCreateSettingRowDialogAction(data))};

    const handleSetModel = (title: string) => {
        setTmpAsset(prev => prev ? ({...prev, model: title}): null);
    }

    const handleSetCategory = (title: string) => {
        setTmpAsset(prev => prev ? ({...prev, category: title}): null);
    }

    const handleSetManufacture = (title: string) => {
        setTmpAsset(prev => prev ? ({...prev, manufacture: title}): null);
    }


    const onSuccessCreateSetting = (title: string, field?: TCreateField) => {
        switch (field) {
            case 'model': handleSetModel(title); break;
            case 'category': handleSetCategory(title); break;
            case 'manufacture': handleSetManufacture(title); break;
            // case 'status': handleSetStatus(title); break;
        }
    }

    return {
        asset: _selected,
        settings,
        menu: {
            anchorEl,
            handleClose,
            handleClick,
            open,
        },
        tab,
        setTab,
        handleGoBack,

        isLoadingExact,
        isLoadingSettings,
        isOkId,

        editInfoMode,
        setEditInfoMode,
        tmpAsset,
        setTmpAsset,
        handleSetModel,
        handleSetCategory,
        handleSetManufacture,
        oldId,
        setOldId,
        handleSaveInfo,
        handleCancelEditInfo,
        handleEditInfoBtn,
        setCreateSettingRowDialog,
        onSuccessCreateSetting,

        deleteAssetDialog: {
            responseDelete,
            setDeleteAssetDialog,
            isOpenDeleteAssetDialog,
            handleDeleteAsset,
            handleCancelDeleteAsset,
            handleCloseDeleteDialog,
        },
        changeStatusDialog: {
            isOpenChangeStatusDialog,
            setChangeStatusDialog,
        }
    }

}
