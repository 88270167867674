import {deleteFrameworkDialog, hideDeleteFramework, isLoading} from "../../../store/builder/slice";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {useHistory} from "react-router";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {DeleteFramework} from "../../../store/builder/actions";
import {ROOT_BUILDER} from "../../../constants";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";


export const useRemoveFramework = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'builderPage.dialogs'});
    const dispatch = useDispatch();
    const history = useHistory();
    const {setMessage} = useMessageDialog();

    const {isOpen, frameworkId} = useSelector(deleteFrameworkDialog);
    const [field, setField] = React.useState<string>("");
    const handleChange = (e: React.ChangeEvent<{name?:string | undefined; value?:unknown}>) => {
        setField(e.target.value as string);
    }

    const onSuccess = () => {
        setMessage({title: 'Completed successfully', message: `Information about the framework has been removed.`});
        history.push(ROOT_BUILDER);
    }

    const handleDelete = () => {
        if(field.trim().toLowerCase() === t('Confirm').toLowerCase()){
            frameworkId && dispatch(DeleteFramework({data: frameworkId, onSuccess}));
        }
    }
    const handleClose = () => dispatch(hideDeleteFramework())

    useEffect(() => {
        !isOpen && setField('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return {
        isOpen,
        field,
        handleDelete,
        handleChange,
        handleClose,
        isLoading: useSelector(isLoading),
    }
}