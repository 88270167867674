import {FormikErrors, FormikState, useFormik} from "formik";
import React, {useCallback, useEffect, useMemo} from "react";
import {FormikConfig, FormikValues} from "formik/dist/types";
import moment from "moment";


export const useCustomFormik = <Values extends FormikValues, FieldsForTest extends Record<string, never> = {}>(
    isOpen: boolean, // for trigger reset form // provide false if you don't need it
    {onSubmitValidationFailed, ...props}: Omit<FormikConfig<Values & FieldsForTest>, 'initialValues'> & {initialValues: Values} & {onSubmitValidationFailed?: (errors: FormikErrors<Values & FieldsForTest>) => void},
    dependencies?: Record<keyof FieldsForTest, (keyof Values)[]>,
) => {
    type TFormikValues = Values & FieldsForTest;
    type TKeyofValues = Extract<keyof Values, string> | string;

    const formik = useFormik<TFormikValues>({
        ...props,
        initialValues: props.initialValues as TFormikValues,
        validateOnMount: false,
        validateOnBlur: true,
    });

    const setTouchedWhileChanges = useCallback(async (targetName: TKeyofValues) => {
        const keys = Object.entries(dependencies || {}).reduce((prev, [key, dependencies]) => {
            if (dependencies.includes(targetName)) prev.push(key);
            return prev;
        }, [] as string[]);

        await Promise.all([targetName, ...keys].map(key => (
            formik.setFieldTouched(key, true, false)
        )))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dependencies]);

    const setNestedTouched = useCallback((field: string, value: unknown, withoutChildTouch: boolean = false) => {
        const isDate = moment.isDate(value) || moment.isMoment(value);

        if (Array.isArray(value)) {
            !withoutChildTouch && value.forEach((item, index) => {
                setNestedTouched(`${field}[${index}]`, item);
            });
        } else if (value && typeof value === 'object' && !isDate) {
            !withoutChildTouch && Object.entries(value).forEach(([key, val]) => {
                setNestedTouched(`${field}.${key}`, val);
            });
        } else {
            // Assuming it's a primitive value
            setTouchedWhileChanges(field);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setTouchedWhileChanges]);

    const formikOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement> & {target: {name: TKeyofValues}}) => {
        formik.handleChange(e);
        setTouchedWhileChanges(e.target.name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formikOnBlur = useCallback((e: React.FocusEvent<HTMLInputElement> & {target: {name: TKeyofValues}}) => {
        formik.handleBlur(e);
        setTouchedWhileChanges(e.target.name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formikOnForceChange = useCallback(async <TField extends TKeyofValues>(field: TField, value: Values[TField], withoutChildTouch: boolean = false, shouldValidate: boolean = true) => {
        await formik.setFieldValue(field, value, shouldValidate);
        setNestedTouched(field, value, withoutChildTouch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeAutocomplete = useCallback(<TField extends string>(field: TField) => async (e: React.SyntheticEvent, value: Values[TField]) => {
        await formik.setFieldValue(field, value, true);
        setTouchedWhileChanges(field);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitForm = useCallback(() => {
        !formik.isValid && console.log(formik.errors);
        if (!formik.isValid && onSubmitValidationFailed) onSubmitValidationFailed(formik.errors);
        return formik.submitForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.isValid, formik.errors]);

    const resetForm = useCallback((nextState?: Partial<FormikState<TFormikValues>>) => {
        formik.resetForm(nextState);
        formik.validateForm(nextState?.values as TFormikValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isOpen) {
            formik.resetForm({
                values: props.initialValues as TFormikValues,
                errors: props.initialErrors || {},
                touched: props.initialTouched || {},
            });
            formik.validateForm(props.initialValues as TFormikValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const jsonValues = JSON.stringify(formik.values);
    const jsonErrors = JSON.stringify(formik.errors);
    const jsonTouched = JSON.stringify(formik.touched);

    return useMemo(() => ({
        ...formik,
        handleChange: formikOnChange,
        handleBlur: formikOnBlur,
        setFieldValue: formikOnForceChange,
        submitForm: submitForm,
        handleSubmit: submitForm,
        resetForm: resetForm,
        handleChangeAutocomplete,
        setNestedTouched,
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [
        jsonValues,
        jsonErrors,
        jsonTouched,
        formik.isValid,
    ]);
}