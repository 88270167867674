import React from "react";
import {PageWrapper} from "../../../../../newShared/components/Layouts";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useTrainingsExactPage} from "../../hooks/useTrainingsExact";
import {Box, Divider, Skeleton, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {MicroTableWithoutBorder} from "../../../../../newShared/components/microTableWithoutBorder";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {getTrainingStatusChip} from "../../helpers";
import {PATH_TRAININGS_LIST, trainingLevelTitleMapper} from "../../constants";
import {MainTrainingStatus} from "../../../../../newShared/GQLTypes";
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import * as S from "../../components/styled";
import {CreateEditTrainingDialog} from "../../components/dialogs/createEditTrainingDialog";
import {TrainingSectionsBlock} from "../../components/trainingSectionBlock";
import {CoverImageView} from "../../components/trainingCoverComponent/CoverImageView";
import {CreateAssignmentDialog} from "../../components/dialogs/createAssignment";


export const TrainingExactPage = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.pathTrainings'})
    const {isLessThen1050} = useMedia();
    const {
        training,
        openedSection,
        isLoadingExact,
        isLoadingChangeTrainingStatus,
        handleToggleSection,
        handleOpenAssign,
        handleOpenEdit,
        handleChangeStatus,
        editDialog,
    } = useTrainingsExactPage();

    return (
        <PageWrapper>
            <PageTitleAndActions
                title={training?.name}
                handleGoBack={PATH_TRAININGS_LIST}
                actions={[
                    {
                        title: t('Assign'),
                        onClick: handleOpenAssign,
                        icon: <HowToRegOutlinedIcon />,
                        variant: 'contained',
                        size: 'small',
                    },
                    {
                        title: t('Edit'),
                        onClick: handleOpenEdit,
                        icon: <HowToRegOutlinedIcon />,
                        variant: 'outlined',
                        size: 'small',
                        disabled: isLoadingExact
                    },
                    {
                        title:  t('Move to archive'),
                        onClick: handleChangeStatus,
                        icon: <ArchiveOutlinedIcon />,
                        variant: 'outlined',
                        size: 'small',
                        loading: isLoadingChangeTrainingStatus,
                        dontShowWhen:  training?.status !== MainTrainingStatus.Active
                    },
                    {
                        title:  t('Restore from archive'),
                        onClick: handleChangeStatus,
                        icon:  <UnarchiveOutlinedIcon />,
                        variant: 'outlined',
                        size: 'small',
                        loading: isLoadingChangeTrainingStatus,
                        dontShowWhen:  training?.status !== MainTrainingStatus.Archived
                    },
                ]}

            />

            <S.ExactPageContainer>
                <S.ExactPageLeftContainer isLessThen1050={isLessThen1050}>
                    {training?.coverImage && <CoverImageView cover={training.coverImage} size={'exact'}/> }
                    {/*{training?.coverImage?.image &&*/}
                    {/*    <Box maxWidth={'400px'}>*/}
                    {/*        <img src={training?.coverImage?.image} style={{borderRadius: '6px'}} width={'400px'} alt={training?.coverImage?.imageName}/>*/}
                    {/*    </Box>*/}
                    {/*}*/}

                    <MicroTableWithoutBorder
                        isLoading={isLoadingExact}
                        noWrap
                        rows={[
                            {
                                label: `${t('Status')}:`,
                                text: training ? getTrainingStatusChip(training.status) : '-',
                            },
                            {
                                label: `${t('Last update')}:`,
                                text: training?.updatedDate ? parseDateAuto(training.updatedDate) : '-',
                            },
                            {
                                label: `${t('Category')}:`,
                                text: training?.category ?? '-',
                            },
                            {
                                label: `${t('Level')}:`,
                                text: training ? trainingLevelTitleMapper[training.level] : '-',
                            }
                        ]}
                    />
                    <Divider flexItem sx={{bgcolor: colors.stroke.table, flexShrink: 0}}/>

                    <Typography variant={'*bodyText2_semibold'}>{`${t('Training description')}:`}</Typography>
                    {isLoadingExact ? <Skeleton width={'50%'} /> : <Typography variant={'*bodyText2'} sx={{whiteSpace: 'break-spaces'}}>{training?.description || '-'}</Typography>}

                    {isLessThen1050 &&
                        <>
                            <Divider flexItem sx={{bgcolor: colors.stroke.table, flexShrink: 0}}/>
                            {training && <TrainingSectionsBlock content={training.content} toggleOpen={handleToggleSection} openedSection={openedSection} />}
                        </>
                    }
                </S.ExactPageLeftContainer>
                {!isLessThen1050 &&
                    <>
                        <Divider flexItem orientation={'vertical'} sx={{bgcolor: colors.stroke.table, flexShrink: 0}}/>

                        <Box display={'flex'} flexDirection={'column'} gap={'10px'} p={'20px'} flexGrow={1} height={'100%'} overflow={'auto'}>
                            {training && <TrainingSectionsBlock content={training.content} toggleOpen={handleToggleSection} openedSection={openedSection} />}
                        </Box>
                    </>
                }
            </S.ExactPageContainer>

            <CreateEditTrainingDialog {...editDialog}/>
            <CreateAssignmentDialog />
        </PageWrapper>
    )
}
