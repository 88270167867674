import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useFrameworks} from "../../../hooks/useFrameworks";
import {useSearchFieldURL} from "../../../../../../newShared/hooks/useSearchFieldURL";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    PATH_LOCAL_COMPLIANCE_FRAMEWORKS,
    PATH_LOCAL_COMPLIANCE_FRAMEWORKS_AVAILABLE
} from "../../../../../../newShared/constants";
import {useFilterUrl} from "../../../../../../newShared/hooks/useFilterUrl";
import {
    useGenericFiltersStorage
} from "../../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {useEffect} from "react";
import {useScrollPagination} from "../../../../../../newShared/hooks/useScrollPagination";

export const useAvailableFrameworksPage = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const {clearFilters} = useGenericFiltersStorage();

    const {
        available: {frameworks, pageInfo: {page, count, total}, isLoading, getData, cleanArray},
    } = useFrameworks();
    // const {filter} = useParameters();

    //Controller and first loadData
    const controller = new AbortController();

    //Breadcrumbs
    useSetBreadcrumbs([
        {
            title: tMenu('Frameworks'),
            path: PATH_LOCAL_COMPLIANCE_FRAMEWORKS
        },
        {
            title: tMenu('Available frameworks'),
            path: PATH_LOCAL_COMPLIANCE_FRAMEWORKS_AVAILABLE
        }
    ]);

    // pagination and filtering
    const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => {
            getData({
                page, count, filters: {},
            }, controller.signal)
        },
        arrayLength: frameworks.length,
        isLoading: isLoading,
        pageInfo: {page, count, total},
        cleanData: cleanArray,
        controller: controller,
        isOnTop: false,
        isNoMoreThanTotal: true,
        // customDependencies: [],
        // is0PageAddictive: fr !== framework?.id
    })

    useEffect(() => {
        return () => clearFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {value: searchValue, handleChange} = useSearchFieldURL({wait: 0});
    const {setFilter, handleKeyDown} = useFilterUrl({
        getData: (filters) => {getData({page: 0, count, filters}, controller.signal, true)}
    });

    const fetchByFilters = (search: string, filters: Record<string, string[]>, signal: AbortSignal) => {
        getData({page: 0, count: 10, filters: {input: [search]}}, undefined, true);
        // dispatch(cleanArrayAction('gallery'));
        // dispatch(getAvailableFrameworksAction({data: {pageInfo: {pa}}, signal}))
    }

    return {
        frameworks,
        isLoading,
        searchValue, handleChange, handleKeyDown, setFilter,
        onScroll, scrollRef, bottomLoader: bottomLoader && ((frameworks.length < total) || (frameworks.length === total && total === 0)),
        fetchByFilters
    }
}