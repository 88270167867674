import styled from 'styled-components';
import colors from "../../../../../newShared/theme/colors";
import {device} from "../../../../../newShared/constants";
import {TextField} from "@mui/material";


export const FilterContainer = styled.div`
    display: flex;
    width: 100%;
    margin: 30px 0 0 0;
    & div{
        margin: 0 10px 0 0;
    }
`;

export const TableHeaderWrapper = styled.div`
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0 24px 0;
    ${device.tablet} {
        width: 100%;
        margin: 25px 0 24px 0;
    }
`;

export const MainTableRowWrapper = styled.div`
    width: 100%;
    &:hover{
        background-color: ${colors.backgrounds.grey_light};
        cursor: pointer;
    }
`;

export const TableRowWrapper = styled.div`
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: inherit;
  
    &:hover{
        //background-color: ${colors.backgrounds.grey_light};
        cursor: pointer;
    }
    ${device.tablet} {
        width: 100%;
    }
`;

export const TableHeaderItemWrapper = styled.div<{ width?: string}>`
    width: ${props => props.width ?? '24%'};
    display: flex;
    align-items: center;
`;

export const TableRowItemWrapper = styled.div<{width?: string}>`
    width: ${props => props.width ?? '24%'};
    display: flex;
    align-items: center;
    // justify-content: center;
    padding: 16px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const MainTableScrollWrapper = styled.div`
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const CreateTargetInputsWrapper = styled.div`
    width: 30%;
    margin: 20px 0 0 0;
    ${device.generateMax(958)} {
        width: 60%;
    }
    ${device.mobileL} {
        width: 90%;
    }
`;

export const CreateTargetLoaderWrapper = styled.div`
    width: 30%;
    margin: 32px 0 0 0;
    ${device.generateMax(958)} {
        width: 60%;
    }
    ${device.mobileL} {
        width: 90%;
    }
`;

export const ExactTargetTableHeaderContainer = styled.div<{isRightSideOpen: boolean}>`
    flex-basis: ${props => props.isRightSideOpen ? '100%' : '50%'};
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${device.tablet} {
      flex-basis: ${props => props.isRightSideOpen ? '0' : '100%'};
    }
    ${device.desktopNarrow} {
      flex-basis: ${props => props.isRightSideOpen ? '0' : '100%'};
    }
`;

export const ExactTargetTableRowContainer = styled.div<{selected: boolean}>`
    width: 100%;
    background-color: ${props => props.selected ? colors.backgrounds.blue_light_1 : 'inherit'};
    &:hover{
        background-color: ${props => props.selected ? 'undefined' : colors.backgrounds.grey_light}
    }
    cursor: pointer;
`;

export const ExactTargetTableRowItem = styled.div<{revDir: boolean, isRightSideOpen: boolean}>`
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${props => !props.revDir ? '24px 0 24px 5px' : '24px 5px 24px 0'};
    
    ${device.desktopM}{
        width: ${props => props.isRightSideOpen ? '60%' : '35%'};
    }
    ${device.tablet} {
        width: 70%;
    }
    ${device.mobileL} {
        width: 90%;
    }
`;

export const ExactTargetLeftContainer = styled.div<{isRightSideOpen: boolean}>`
    width: ${props => props.isRightSideOpen ? '50%' : '100%'};
    height: 100%;
    display: flex;
    flex-direction: column;
    
    ${device.desktopM}{
        width: ${props => props.isRightSideOpen ? '50%' : '100%'};
    }
    ${device.tablet}{
        width: ${props => props.isRightSideOpen ? '0' : '100%'};
        display: ${props => props.isRightSideOpen ? 'none' : 'flex'};
    }
`;

export const ExactTargetRightContainer = styled.div<{isRightSideOpen: boolean}>`
    flex-basis: ${props => props.isRightSideOpen ? '50%' : '0'};
    height: 100%;
    display: ${props => props.isRightSideOpen ? 'flex' : 'none'};
    justify-content: space-between;
    //padding: 0 0 0 32px;
    
    ${device.desktopM}{
      flex-basis: ${props => props.isRightSideOpen ? '50%' : '0'};
    }
    ${device.tablet}{
      flex-basis: ${props => props.isRightSideOpen ? '100%' : '0'};
        display: ${props => props.isRightSideOpen ? 'flex' : 'none'};
        padding: 0;
    }
`;

export const TextFieldCustom = styled(TextField)`
  & .MuiInputBase-input {
    height: 16px;
  }
  & label{
    top: 0 !important;
  }
`;