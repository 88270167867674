import {useDispatch, useSelector} from "react-redux";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {SETTING_ROOT_PATH, SETTINGS_INVITES_PATH} from "../../../constants";
import {invites, invitesLoadings, openDeclineInviteDialog} from "../../../store/slice";
import {useEffect, useState} from "react";
import {AcceptInvite, GetInvites} from "../../../store/actions";
//import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {
    useGenericFiltersStorage
} from "../../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {TWorkspaceInvite} from "../../../types";

export const useInvites = () => {
    const dispatch = useDispatch();
    const {list, decline, accept} = useSelector(invitesLoadings);
    const _invites = useSelector(invites);
    //const {setMessage} = useMessageDialog();
    const {currentSearchForFetch} = useGenericFiltersStorage();

    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    useSetBreadcrumbs([
        {
            title: tMenu('Settings'),
            path: SETTING_ROOT_PATH
        },
        {
            title: tMenu('Invites'),
            path: SETTINGS_INVITES_PATH
        }
    ]);

    //useEffect
    useEffect(() => {
        getData();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        setFiltered(filterInvites(_invites, currentSearchForFetch));
        //eslint-disable-next-line
    }, [_invites])

    useEffect(() => {
        // console.log(`useEffect [JSON.stringify(_invites)] length: ${_invites.length}`);
        //if _invites were changed (invite accepted or declined) updating local filtered array
        if(currentSearchForFetch.trim().length > 0){
            setFiltered(filterInvites(_invites, currentSearchForFetch));
        }else{
            setFiltered(_invites);
        }
        //eslint-disable-next-line
    }, [currentSearchForFetch]);

    useEffect(() => {
        if(!list && _invites.length > 0){
            setFiltered(_invites);
        }
        //eslint-disable-next-line
    }, [list]);

    //actions
    const getData = () => {
        dispatch(GetInvites());
    }

    const handleDeclineInvite = (invite: TWorkspaceInvite) => {
        dispatch(openDeclineInviteDialog(invite));
    }

    const handleAcceptInvite = (invite: TWorkspaceInvite) => {
        dispatch(AcceptInvite({
            invite,
            // onSuccess: () => {
            //     setMessage({title: 'Completed Successfully!', message: `You can access ${invite.workspaceName} now!`});
            // }
        }));
    }

    const filterInvites = (invites: TWorkspaceInvite[], search: string) => {
        return invites.filter(e => e.sender.email.trim().toLowerCase().includes(search.trim().toLowerCase()))
    }

    //search
    // const {form, setForm, handleChange} = useForm<{search: string, prevSearch: string}>({search: '', prevSearch: ''});
    const [filtered, setFiltered] = useState<TWorkspaceInvite[]>([]);

    // const handleCleanSearch = () => {
    //     setForm({search: '', prevSearch: ''});
    //     setFiltered(_invites);
    // }

    // const handleEnterKey = (e: any) => {
    //     if(e.keyCode === 13 && !form.search.trim().length){
    //         setForm({search: '', prevSearch: ''});
    //         setFiltered(_invites);
    //     }
    //     if(e.keyCode === 13 && form.search.trim().length > 0){
    //         setForm({...form, prevSearch: form.search});
    //         setFiltered(filterInvites(_invites, form.search));
    //     }
    // }

    return{
        invites: filtered,
        isLoading: list,
        handleDeclineInvite,
        handleAcceptInvite,
        // search: {
        //     search: form.search,
        //     handleChange,
        //     handleCleanSearch,
        //     handleEnterKey
        // },
        // showNoResult: form.prevSearch.trim().length > 0 && !filtered.length,
        showNoInvites: !_invites.length && !list,
        isLoadingAccept: (id: string) => accept.isLoading && accept.inviteId === id,
        isLoadingDecline: (id: string) => decline.isLoading && decline.inviteId === id,
    }
}