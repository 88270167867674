import {useDispatch, useSelector} from "react-redux";
import {
    dialogs,
    folders,
    foldersPath,
    hideRestoreDialog,
    isLoading,
    templatesFoldersSelector,
    templatesPathSelector,
} from "../../../store/slice";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {useEffect, useState} from "react";
import {RestoreNewItem} from "../../../store/actions";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {SelectFolderTree} from "../../selectFolderTree";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";

export const RestoreDialog = () => {
    const dispatch = useDispatch();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathDocuments.TrashPage'});
    const rootFolders = useSelector(folders);
    const {isOpen, file} = useSelector(dialogs).restoreDialog;
    const isLoadingMoveTo = useSelector(isLoading).isLoadingMoveTo;
    const path = useSelector(foldersPath);
    const templatesPath = useSelector(templatesPathSelector);
    const templatesFolders = useSelector(templatesFoldersSelector);

    const [moveToFolder, setMoveToFolder] = useState<{id: string, name: string} | null>(null);

    //set root opened
    useEffect(() => {
        if(isOpen && path.length > 0){
            const tmpDoc = rootFolders.find(e => e.name === "Documents");
            if (tmpDoc) setMoveToFolder({id: tmpDoc.id, name: tmpDoc.name});

        }else if(!isOpen){
            setMoveToFolder(null);
        }

        //eslint-disable-next-line
    }, [isOpen]);

    const handleClose = () => {
        dispatch(hideRestoreDialog());
    }

    // const isOk = ():boolean => {
    //     if (file && moveToFolder) return file.folderId !== moveToFolder.id;
    //     // if (folder && moveToFolder) return path[path.length - 1].id !== moveToFolder.id;
    //     return !!moveToFolder;
    // }

    const submit = () => {
        if (moveToFolder && file) dispatch(RestoreNewItem({
            fileId: file.documentId ?? '',
            newFatherId: moveToFolder!.id,
        }));
    }


    return(
        <Dialog onClose={handleClose} open={isOpen} fullWidth maxWidth={'sm'} scroll={'paper'}>
            <DialogTitle>{t('Restore document')}</DialogTitle>
            <DialogContent>
                <SelectFolderTree t={t} maxWidth={'545px'} folders={templatesFolders} path={templatesPath} selectedFolder={moveToFolder} setSelectedFolder={setMoveToFolder}/>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{t('Cancel')}</Button>
                {/*<Button onClick={submit} disabled={!isOk()}>{t('Restore')}</Button>*/}
                <LoadingButton size={'medium'}
                               variant={'contained'}
                               disabled={!moveToFolder || isLoadingMoveTo}
                               onClick={submit} loading={isLoadingMoveTo }
                               loadingPosition={isLoadingMoveTo ? 'end' : undefined}
                               endIcon={isLoadingMoveTo ? <div style={{width: '10px', height: '10px', marginLeft: revDir ? '12px' : undefined, marginRight: !revDir ? '12px' : undefined }}/> : undefined}
                >
                    {t('Restore')}
                </LoadingButton>
            </DialogActions>


        </Dialog>
    )
}
