import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSegregationOfDuties} from "../../../hooks/useSegregationOfDuties";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {TConflictException} from "../../../types";
import {LoadingButton} from "@mui/lab";
import {DEFAULT_DATE_FORMAT} from "../../../../../../newShared/utils/dateTools";

export const ExtendExceptionDialog = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        loadings: {updateException: isLoadingUpdateException},
        dialogs: {extendException: {isOpen, exception, ruleId}},
        handleHideExtendException: handleClose,
        handleExtendException
    } = useSegregationOfDuties();

    const {form, setForm} = useForm<TConflictException | null>(exception);

    const handleSetAllowTill = (newValue: string) => {
        newValue && setForm(prev => prev && ({...prev, allowedDate: newValue}));
    }

    useEffect(() => {
        if (isOpen) {
            setForm(exception)
        }


        //eslint-disable-next-line
    }, [isOpen]);

    const isOk = () => {
        return form && exception && form.allowedDate !== exception.allowedDate
    }



    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Extend exception')}</DialogTitle>
            <DialogContent>
                {form &&
                    <>
                        <Typography variant={'body1'}>{t('Add an exception action date')}</Typography>
                        <DatePicker
                            label={t('Allow till')}
                            inputFormat={DEFAULT_DATE_FORMAT}
                            value={form.allowedDate || null}
                            disabled={isLoadingUpdateException}
                            disablePast
                            onChange={(newValue) => {
                                if(newValue){
                                    try{
                                        const date = new Date(Date.parse(newValue)).toISOString();
                                        handleSetAllowTill(date);
                                    }catch (ex){
                                        console.log(`Allow till date: invalid date`)
                                    }
                                }
                            }}
                            renderInput={(params) => <TextField required size={'small'} {...params} sx={{mt: '8px', '& label': {top: '0 !important'}}}/>}
                        />
                    </>

                }
            </DialogContent>
            <DialogActions>
                <Button sx={{textTransform: 'none', ml: '6px', mr: '6px'}} onClick={handleClose} size={'medium'} variant={'text'}>{t('Close')}</Button>
                {/*<Button disabled={isLoadingUpdateException || !exception || !isOk} */}
                {/*        sx={{textTransform: 'none'}} */}
                {/*        onClick={()=> ruleId && form && handleExtendException(form, ruleId)}>*/}
                {/*    {t('Save')}</Button>*/}

                <LoadingButton size={'medium'} variant={'contained'} sx={{textTransform: 'none'}}
                               disabled={isLoadingUpdateException || !exception || !isOk}
                               onClick={()=> ruleId && form && handleExtendException(form, ruleId)} loading={isLoadingUpdateException}
                               endIcon={isLoadingUpdateException ? <div style={{width: '10px', height: '10px', marginLeft: revDir ? '12px' : undefined, marginRight: !revDir ? '12px' : undefined }}/> : undefined}
                               loadingPosition={isLoadingUpdateException ? 'end' : undefined}
                >
                    {t('Save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
