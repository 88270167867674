import React, {FC} from 'react';
import {Flex, PageWrapper} from "../../../../../newShared/components/Layouts";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    Box,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_COLLECTORS} from "../../../../../newShared/constants";
import {useScrollPagination} from "../../../../../newShared/hooks/useScrollPagination";
import {useCollectors} from "../../hooks/useCollectors";
import {TableRowSkeleton} from "../../../../../newShared/components/tableRowSkeleton";
import {useRoutes} from "../../hooks/useRoutes";
import {EmptyArrayImage} from "../../../../../newShared/components/EmptyArrayImage";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import BlockIcon from "@mui/icons-material/Block";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import colors from "../../../../../newShared/theme/colors";
import {MinimizeInfoWrapper} from "../../../../../newShared/components/minimizeInfoWrapper";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {ShowResultsDialog} from "../../components/dialogs/showResults";


export const CollectorExact: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathCollectors'});
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    const {
        collectorId,
        goToCollectors
    } = useRoutes();

    // const {filter} = useParameters();
    const {
        selectedCollector: {
            selectedCollector,
            evidences,
            history,
            pageInfo: {page, count, total},
            isLoading,
        },
        getHistory,
        controller,
        clean,
        openResultDialog
    } = useCollectors();

    // const {openFramework} = useFrameworks();
    // const {openControl} = useCurrentFramework();
    // const {goToEvidences} = useControl();

    //First loadData
    // useEffect(() => {
    //     collectorId && getHistory({page: 0, count, collectorId})
    //     return () => {
    //         // clean(['array'])
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [collectorId]);

    //Breadcrumbs
    useSetBreadcrumbs([
        {
            title: tMenu('Collectors'),
            path: PATH_LOCAL_COLLECTORS
        },
        {
            title: selectedCollector?.name || 'Is loading...',
        },
    ]);

    //Pagination and filtering
    const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => {
            collectorId && getHistory({
                page,
                count,
                collectorId,
                // filters: filter
            })
        },
        arrayLength: history.length,
        isLoading: isLoading,
        pageInfo: {page, count, total},
        cleanData: () => {clean(['selectedCollector'])},
        controller: controller,
        isOnTop: true,
        isNoMoreThanTotal: true,
        // customDependencies: [frId],
        // is0PageAddictive: frId !== framework?.id,
    })

    // const {value: searchValue, handleChange} = useSearchFieldURL({wait: 0});
    // const {setFilter, handleKeyDown} = useFilterUrl({
    //     getData: (filters) => {getCollectors({page: 0, count, filters})}
    // });

    return (
        <PageWrapper>
            <PageTitleAndActions
                title={selectedCollector?.name}
                handleGoBack={goToCollectors}
                actions={[]}
            />

            {/*<Breadcrumbs/>*/}
            {/*<Box display={"flex"} alignItems={"center"} mt={'12px'} mb={'16px'}>*/}
            {/*    <Typography variant={!matches_1440Down ? "h1" : 'h3'} component={"span"} margin={!revDir ? '0 32px 0 0' : '0 0 0 32px'}*/}
            {/*                overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"}>*/}
            {/*        {selectedCollector?.name && (*/}
            {/*            <IconButton sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!matches_1440Down ? 4 : 2}px 0` : `0 0 ${!matches_1440Down ? 4 : 2}px 12px`, padding: '0'}}*/}
            {/*                        onClick={goToCollectors} size={matches_1440Down ? 'small' : undefined}>*/}
            {/*                {!revDir ? <ArrowBack/> : <ArrowForward/>}*/}
            {/*            </IconButton>*/}
            {/*        )}*/}

            {/*        {selectedCollector?.name || <Skeleton width={'200px'}/>}*/}
            {/*    </Typography>*/}
            {/*</Box>*/}

            <MinimizeInfoWrapper initiallyOpened>
                <Box display={"flex"} alignItems={"center"} m={'4px 0 0 0'}>
                    <Typography variant={"subtitle2"}>{`${t('Last run')}:`}</Typography>
                    <Typography variant={"body2"} m={'0 8px'}>
                        {selectedCollector ? parseDateAuto(selectedCollector.lastRun) : <Skeleton width={'200px'}/> }
                    </Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} m={'4px 0 0 0'}>
                    <Typography variant={"subtitle2"}>{`${t('Last run status')}:`}</Typography>
                    <Typography variant={"body2"} m={'0 8px'}
                                sx={{color: selectedCollector?.lastRunStatus === 'SUCCESS' ? colors.warningActions.green : colors.warningActions.red}}>
                        {selectedCollector ? selectedCollector.lastRunStatus ? selectedCollector.lastRunStatus?.toUpperCase() : 'NEVER' : <Skeleton width={'200px'}/> }
                    </Typography>
                </Box>

                {evidences.length > 0 && (
                    <Typography variant={"subtitle2"} m={'4px 0 0 0'} color={colors.primary.blue} sx={{cursor: 'pointer'}} onClick={openResultDialog}>{`${t('Show result locations')}`}</Typography>
                )}
            </MinimizeInfoWrapper>

            <TableContainer onScroll={onScroll} ref={scrollRef}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Start')}</TableCell>
                            <TableCell>{t('Status')}</TableCell>
                            <TableCell>{t('End')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {history.map((history) => history && (
                            <TableRow key={history.id}>
                                <TableCell>{parseDateAuto(history.start)}</TableCell>
                                <TableCell>{getStatus(history.status || null)}</TableCell>
                                <TableCell>{parseDateAuto(history.end)}</TableCell>
                            </TableRow>
                        ))}

                        {(bottomLoader || isLoading) && <TableRowSkeleton/>}
                    </TableBody>
                </Table>
            </TableContainer>

            <EmptyArrayImage type={"Collectors"} isShowing={history.length === 0 && !isLoading} text={"Collectors doesn't exists"} withoutAdd/>

            {/*<SelectExistingCollector />*/}
            <ShowResultsDialog />
        </PageWrapper>
    )
}

const getStatus = (status: string | null) => {
    switch(status){
        case 'SUCCESS': return <CheckCircleOutlineOutlinedIcon style={{color: '#3BCC89'}}/>;
        case 'inProgress': return <TimerOutlinedIcon style={{color: '#FFA000'}}/>
        case 'FAILED': return <BlockIcon style={{color: '#FF5959'}}/>
        case null: return <Flex w={'20px'} h={'20px'} br={'50%'} border={'1px solid #A0A2B1'} />
    }
}
