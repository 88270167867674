import {Box, styled} from "@mui/material";
import colors from "../../../../../theme/colors";

type EditorFillableBlockStyledProps = {
    x: number;
    y: number;
    width: number;
    height: number;
    backgroundColor?: string;
    borderColor?: string;

    haveTextData?: boolean;
    isDragging?: boolean;

    isActiveUser?: boolean;
}
export const EditorFillableBlockStyled = styled(Box, {shouldForwardProp: (propName) => (
    ![ 'x', 'y', 'isDragging', 'width', 'height', 'backgroundColor', 'borderColor', 'haveTextData', 'isActiveUser' ].includes(propName.toString())
)})<EditorFillableBlockStyledProps>(({x, y, width, height, isDragging, backgroundColor, borderColor, haveTextData, isActiveUser}) => ({
    position: 'absolute',
    top: y + 'px',
    left: x + 'px',
    width: width + 'px',
    height: !haveTextData ? height + 'px' : undefined,
    minHeight: !haveTextData ? undefined : height + 'px',

    display: !isDragging ? 'flex' : 'none',
    transition: 'opacity 2s',
    alignItems: 'center',
    justifyContent: 'center',

    border: '1px solid ' + borderColor ?? colors.decorative.violet,
    borderRadius: '8px',
    cursor: isActiveUser === undefined || isActiveUser ? 'pointer' : 'not-allowed',

    backgroundColor: backgroundColor ?? 'white',
    zIndex: 200,

    color: colors.text.dark,
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '20px',
    letterSpacing: '0.48px',

    opacity: isDragging ? 0 : 0.8,

    '&:hover': {
        color: colors.primary.blue,
        boxShadow: '0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13)'
    },

    '& img': {
        maxWidth: '100%',
        maxHeight: '100%',
    }


}))

export type EditorFillableBlockResizeLineProps = {
    width: number;
    height: number;
    className: string;
}
export const EditorFillableBlockResizeLine = styled(Box, {shouldForwardProp: (propName) => (
    ![ 'width', 'cursor', ].includes(propName.toString())
    )})<EditorFillableBlockResizeLineProps>(({width, height, className}) => ({
    position: 'absolute',
    // backgroundColor: 'red',

    '&.vertical-left': { left: -2 },
    '&.vertical-right': { right: -2 },
    '&.horizontal-top': { top: -2 },
    '&.horizontal-bottom': { bottom: -2 },

    cursor: className.startsWith('horizontal') ? 'ns-resize' : 'ew-resize',
    width: className.startsWith('horizontal') ? width : '3px',
    height: className.startsWith('horizontal') ? '3px' : height,
}))