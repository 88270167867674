import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {LoadingButton} from "../../../../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    useDeleteWorkspaceCollabDialog
} from "../../../../../hooks/organizations/dialogs/useDeleteWorkspaceCollabDialog";

export const DeleteWorkspaceUser = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathSettings.dialogs'});
    const {
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        collab,
        selected
    } = useDeleteWorkspaceCollabDialog();
    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{('Delete workspace collaborator')}</DialogTitle>

            <DialogContent>
                <DialogContentText>
                    <Typography variant={'body2'}>
                        {t(`{{firstName}} {{lastName}} will lose access to {{workspaceName}}. After deletion of a collaborator all links from entities will be automatically deleted.`, {firstName: collab?.firstName, lastName: collab?.lastName, workspaceName: selected?.name})}
                    </Typography>
                    <Typography variant={'body2'} sx={{margin: '8px 0 0 0'}}>{t('Are you sure you want to delete this collaborator?')}</Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                >
                    {t('Delete')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}