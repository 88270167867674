import * as S from '../styled';
import {useSelector} from "react-redux";
import {dialogs, hideRestoreClientFromArchiveDialog, loadings} from "../../../store/slice";
import {useAppDispatch} from "../../../../../../newShared/redux";
import React, {useEffect, useState} from "react";
import {Button, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TypographyBody1} from "../../../../../../newShared/components/Inputs/styled";
import CommonCheckbox from "../../../../../../newShared/components/Basic/CommonCheckBox";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {RestoreKycTargetFromArchive} from "../../../store/actions";


export const RestoreClientFromArchiveDialog = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.dialogs'});

    const dispatch = useAppDispatch();
    const {isOpen, selectedClient} = useSelector(dialogs).restoreClientFromArchive;
    const {moveFromArchive} = useSelector(loadings);
    const [isDescription, setIsDescription] = useState<boolean>(false);
    const [description, setDescription] = useState<string>('');
    const handleClose = () => {
        dispatch(hideRestoreClientFromArchiveDialog());
    }

    const handleSave = () => {
        selectedClient && dispatch(RestoreKycTargetFromArchive({workspaceId: '', targetId: selectedClient.id, description}));
    }

    useEffect(() => {
        if (isOpen) {
            setIsDescription(false)
            setDescription('');
        }

        //eslint-disable-next-line
    }, [isOpen]);



    return (
        <S.CommonDialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Restore client from archive')}</DialogTitle>
            <DialogContent>
                <TypographyBody1>{t('You’re about to restore the client from archive.')}</TypographyBody1>
                <CommonCheckbox label={t('Provide reason for restoring')} checked={isDescription} onChange={() => setIsDescription(!isDescription)} />

                {isDescription && <TextField minRows={3}
                                             multiline
                                             value={description}
                                             onChange={(e) => setDescription(e.target.value)}
                                             label={t('Reason for restoring')} />}

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={'text'} sx={{textTransform: 'none'}}>{tCommon('Cancel')}</Button>
                <LoadingButton disabled={moveFromArchive}
                               onClick={handleSave} loading={moveFromArchive }
                               loadingPosition={moveFromArchive ? 'end' : undefined}
                >
                    {t('Restore')}
                </LoadingButton>
            </DialogActions>
        </S.CommonDialog>
    )
}
