import React, {FC} from 'react';
import {useDocuments} from "../../hooks/useDocuments";
import {PathLine} from "../../components/pathLine";
import {MoveToDialog} from "../../components/dialogs/moveToDialog";
import {ChangeNameDialog} from "../../components/dialogs/changeNameDialog";
import {DeleteDialog} from "../../components/dialogs/deleteDialog";
import {AddFolderDialog} from "../../components/dialogs/addFolderDialog";
import {UploadFileDialog} from "../../components/dialogs/uploadFileDialog";
import {FileNotSupportedDialog} from "../../components/dialogs/fileNotSupportedDialog";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import * as S from "../styled";
import {GenericFiltersArray} from "../../../../../newShared/components/genericFilter";
import {useGetDocumentsFilters} from "../../hooks/useGetDocumentsFilters";
import {DocumentsTable} from "../../components/documentsTable";
import {RecipientsViewDialog} from "../../components/dialogs/recipientsView";
import {AddActionButtons} from "../../components/AddActionButtons";
import {AddDocumentDialog} from "../../components/dialogs/addDocument";
import {AddGroupMessageDialog} from "../../components/dialogs/addGroupMessage";
import {DOCUMENT_LIST_DEFAULT_COUNT} from "../../constants";
import EditorDialog from '../../../../../newShared/components/editorUnion';
import {Spinner} from "../../../../../newShared/components/Spinner";
import {SetDocumentAsEvidenceDialog} from "../../components/dialogs/setDocumentAsEvidenceDialog";

export const ExplorerPageRefactor: FC = () => {

    const {
        path,
        eraseFilesHandler,
        getFilesByFolderIdWithFilter,
        doc: {selectedNewDocument, currentEmail, snack, loadings, actions}
    } = useDocuments();

    const {configs} = useGetDocumentsFilters();

    return (
        <S.PageContainer>
            {/*PATH*/}
            <PageTitleAndActions
                // title={path.length > 0 ? path[path.length-1].name : ('Documents')}
                title={('All documents')}
                actions={[]}
                customBreadcrumbs={<PathLine path={path}/>}
                // titleAndActionsBoxJC={'space-between'}
            >
                {path.length > 0 && <AddActionButtons />}
            </PageTitleAndActions>

            <S.FiltersContainer>
                <GenericFiltersArray
                    configs={configs}
                    id={'explorer'}
                    fetchResultWithSelectedFilters={(search, filters, signal) => {
                        // console.log(`filters: ${JSON.stringify(filters)} | search: ${JSON.stringify(search)}`);
                        eraseFilesHandler();
                        getFilesByFolderIdWithFilter({
                            workspaceId: '',
                            folderId: "",
                            pageInfo: {
                                page: 0,
                                count: DOCUMENT_LIST_DEFAULT_COUNT,
                                filters: {
                                    ...filters,
                                    search
                                }
                            }
                        })
                    }}
                    isAddFilters={false}
                />

                <DocumentsTable />
            </S.FiltersContainer>

            {/*DIALOGS*/}
            <AddDocumentDialog />
            <AddGroupMessageDialog />
            <RecipientsViewDialog />
            <MoveToDialog />
            <ChangeNameDialog />
            <DeleteDialog />
            <AddFolderDialog />
            <UploadFileDialog />
            <FileNotSupportedDialog />
            <SetDocumentAsEvidenceDialog />


            {loadings.isLoadingExact && <Spinner />}
            {selectedNewDocument?.document?.status && (
                <EditorDialog isOpen={true}
                              documentTitle={selectedNewDocument.document.name}
                              content={selectedNewDocument.document.data?.editor || []}
                              variables={selectedNewDocument.document.data?.variables || []}
                              actors={{
                                  approvers: selectedNewDocument.document.approvers || [],
                                  recipients: selectedNewDocument.document.recipients || [],
                                  editors: selectedNewDocument.document.editors || [],
                              }}
                              pdfFileId={selectedNewDocument.document.data?.pdfFileId || null}

                              currentEmail={currentEmail}
                              currentFileStatus={selectedNewDocument.document.status}
                              execution={selectedNewDocument?.documentExecutor || undefined}

                              onSave={actions.handleSave}
                              onSend={actions.handleSend}
                              onPreviewBeforeSend={actions.handlePreviewBeforeSend}
                              onInvalidate={actions.handleInvalidate}
                              onSkipApprover={actions.handleSkip}
                              onClose={actions.handleCloseEditor}
                              onReturnToDraft={actions.handleReturnToDraft}
                              onRejectSignStage={() => null}
                              onRejectApproveStage={() => null}
                              onApproveApproveStage={() => null}
                              onApproveSignStage={() => null}
                              onFillFields={() => null}
                              onChangeEta={actions.onChangeEta}
                              onSaveToEvidence={actions.onSaveToEvidence}

                              onResendInviteApprover={actions.onResendInviteApprover}
                              onResendInviteRecipient={actions.onResendInviteRecipient}
                              isLoading={{
                                  isPrev: false,
                                  isSave: loadings.isLoadingSave,
                                  isInvalidating: loadings.isLoadingInvalidate,
                                  isRejecting: false,
                                  isApproving: false,
                                  isReinviting: loadings.isLoadingRestartSignStageByStageIdAndGroupId || loadings.isLoadingRestartSignStageByStageIdAndRecipient,
                                  isSkipping: loadings.isLoadingSendToNextStep,
                                  isFillingField: false,
                                  isSending: loadings.isLoadingExact,
                                  isChangingEtaInProgress: loadings.isLoadingChangeEtaOnStage,
                              }}
                              snack={snack}

                              actualEnvironment={"isMain"}
                />
            )}
        </S.PageContainer>
    )
}
