import {useDispatch, useSelector} from "react-redux";
import {
    hideDeleteRoleDialog,
    selectedWorkspace,
    selectedWorkspaceData,
    workspacesDialogs,
    workspacesLoadings
} from "../../../../store/slice";
import {DeleteRole} from "../../../../store/actions";

export const useDeleteRoleDialog = () => {
    //root
    const dispatch = useDispatch();
    const selected = useSelector(selectedWorkspace);
    const {invites, collabs} = useSelector(selectedWorkspaceData);
    const {isOpen, role} = useSelector(workspacesDialogs).deleteRole;
    const isLoading = useSelector(workspacesLoadings).deleteRole;

    const roleUsedByInvites = invites
        .filter(e => e.roles.some(s => s === role?.name))
        .map(e => e.emailAddress)
        .concat(collabs
            .filter(e => e.roles.some(r => r.id === role?.id))
            .map(e => e.email)
        );


    const roleUsedByCollabs = collabs
        .filter(e => e.roles.some(s => s.id === role?.id))
        .map(e => e.email)
        .concat(collabs
            .filter(e => e.roles.some(r => r.id === role?.id))
            .map(e => e.email)
        );

    //actions
    const handleClose = () => {
        dispatch(hideDeleteRoleDialog());
    }

    const handleSubmit = () => {
        if(selected && role && !roleUsedByInvites.length && !roleUsedByCollabs.length){
            dispatch(DeleteRole({
                workspaceId: selected.id,
                role,
            }));
        }
    }

    // const onSuccess = () => {
    //     role && setMessage({
    //         title: 'Completed successfully!',
    //         message: `${role.name} was deleted!`
    //     })
    // }

    return{
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        role,
        selected,
        roleUsedBy: [...roleUsedByInvites, ...roleUsedByCollabs]
    }
}
