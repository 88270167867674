import {gql} from "graphql.macro";

export const WORKSPACE_SUBSCRIPTION_GQL = gql`
  subscription subscribeToWorkspace($workspaceId:String!, $token: String!, $publicId: String!, $organizationId: String!){
      subscribeToWorkspace(workspaceId:$workspaceId, token: $token, publicId: $publicId, organizationId: $organizationId){
        type
        publicId
        workspaceId
        organizationId
        payload
      }
  }
`;