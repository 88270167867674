import {FC} from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useRuleExact} from "../../../hooks/useRuleExact";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

export const SaveConflictsSuccessDialog: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        isOpenSaveConflictsSuccess: isOpen,
        handleCloseSaveConflictsSuccessDialog: handleClose,
    } = useRuleExact();

    return (
        <Dialog open={isOpen} onClose={() => handleClose(false)}>
            <DialogTitle>{'Completed successfully'}</DialogTitle>
            <DialogContent>{'The selected found conflicts are saved in the conflict list. Go to the conflict list?'}</DialogContent>
            <DialogActions>
                <Button size={'medium'} variant={"text"} onClick={()=>handleClose(false)} >{t('Close')}</Button>
                <Button size={'medium'} sx={{textTransform: 'none'}} onClick={() => handleClose(true)}>{t('Go to conflict list')}</Button>
            </DialogActions>
        </Dialog>
    )
}
