import {DocumentFile, DocumentFolder, folderSort, TDocumentStatus} from "./types";
import {
    APPROVAL_STATUS,
    COMPLETED_STATUS,
    DOCUMENTS_FOLDER_NAME,
    DRAFT_STATUS,
    FILE_COPY_ADDITION,
    GALLERY_FOLDER_NAME,
    PORTAL_FOLDER_NAME,
    REMARKS_STATUS,
    SHARED_DRAFT_STATUS,
    SIGNING_STATUS,
    TEMPLATES_FOLDER_NAME,
    TERMINATED_STATUS,
    TRASH_FOLDER_NAME
} from "./constants";
import colors from "../../../newShared/theme/colors";
import {tagsColorMapper} from "./components/tagsChips";
// @ts-ignore
import {getPastelColor} from "pastel-color";
import {
    APPROVED_STATUS,
    IN_PROGRESS_STATUS,
    PENDING_STATUS,
    REJECTED_STATUS,
    SKIPPED_STATUS,
    TDocumentExecutorStatus
} from "../../../newShared/components/editorUnion/types";
import {NewDocumentModel, ShortDocumentModel} from "../../../newShared/GQLTypes";

export const getChildIds = (folder: DocumentFolder): string[] => {
    let res: string[] = [];
    res.push(folder.id);
    folder.children.forEach(e => {
        res = res.concat(getChildIds(e));
    });
    return res;
}

export const findFolderById = (id: string, folders: DocumentFolder[]): DocumentFolder | undefined => {
    let folder = folders.find(f => f.id === id);

    if(!folder){
        for (let i = 0; i < folders.length; i++) {
            const f = folders[i];
            folder = findFolderById(id,f.children);
            if(folder) break;
        }
    }

    return folder;
}

export const getFolderAndPathFromPathIds = (pathIds: string[], folders: DocumentFolder[]): {folder: DocumentFolder | undefined, path: DocumentFolder[]} => {
    let folder: DocumentFolder | undefined = undefined;
    const path: DocumentFolder[] = [];

    for(let i = 0; i < pathIds.length; i++){
        const id = pathIds[i];
        const currFolder = folders.find(e => e.id === id);
        if(currFolder){
            path.push(currFolder);
            folder = currFolder;
            folders = currFolder.children;
        }else{
            break;
        }
    }

    if (folder?.id !== pathIds[pathIds.length - 1]) folder = undefined;
    return {folder, path};
}

export const sortFolders = (folder1: string, folder2: string, sortedArr: folderSort[]): number => {
    const f1 = sortedArr.find(e => e.id === folder1);
    const f2 = sortedArr.find(e => e.id === folder2);
    if(f1 && f2){
        if(f1.sort < f2.sort) return -1;
        if(f1.sort > f2.sort) return 1;
        if(f1.sort === f2.sort) return 0;
    }
    return 0;
}

export const sortRootFolders = (folders: DocumentFolder[]): folderSort[] => {
    const resp: folderSort[] = [];
    folders.forEach(e => {
        switch (e.name){
            case TEMPLATES_FOLDER_NAME: {
                resp.push({id: e.id, sort: 0});
                return;
            }
            case DOCUMENTS_FOLDER_NAME: {
                resp.push({id: e.id, sort: 1});
                return;
            }
            case PORTAL_FOLDER_NAME: {
                resp.push({id: e.id, sort: 2});
                return;
            }
            case GALLERY_FOLDER_NAME: {
                resp.push({id: e.id, sort: 3});
                return;
            }
            case TRASH_FOLDER_NAME: {
                resp.push({id: e.id, sort: 4});
                return;
            }
            default: return;
        }
    })
    return resp;
}

export const setCopyToFileName = (fileName: string): string => {
    const twoPieces = fileName.split('.');
    if(twoPieces.length >= 2){
        return `${twoPieces.slice(0, twoPieces.length - 1).join('.')}${FILE_COPY_ADDITION}.${twoPieces[twoPieces.length - 1]}`;
    }else{
        throw new Error(`File name is not splittable! - ${fileName}`);
    }
}

export const getUniqueFileName = (fileName: string, filesNames: string[]): string => {
    if(filesNames.some(e => e === fileName)){
        const newName = setCopyToFileName(fileName);
        return getUniqueFileName(newName, filesNames);
    }else{
        return fileName;
    }
}

export const insertNewFolder = (fatherId: string, folder: DocumentFolder, folders: DocumentFolder[]): DocumentFolder[] => {
    for(let i = 0; i < folders.length; i++){
        const currFolder = folders[i];
        if(currFolder.id === fatherId){
            currFolder.children.push(folder);
            return folders;
        }else{
            currFolder.children = insertNewFolder(fatherId, folder, currFolder.children);
        }
    }
    return folders;
}

export const deleteFolders = (folderIds: string[], folders: DocumentFolder[]): DocumentFolder[] => {
    for(let i = 0; i < folders.length; i++){
        const currFolder = folders[i];
        currFolder.children = currFolder.children.filter(e => !folderIds.some(f => f === e.id));
        if(currFolder.children.length > 0){
            currFolder.children = deleteFolders(folderIds, currFolder.children);
        }
    }
    return folders;
}

export const moveFolders = (foldersToMove: DocumentFolder[], rootFolders: DocumentFolder[], newFatherId: string): DocumentFolder[] => {
    for(let i = 0; i < rootFolders.length; i++){
        const currFolder = rootFolders[i];
        if(currFolder.id === newFatherId){
            //it is new parent - putting folders inside
            currFolder.children = currFolder.children.concat(foldersToMove);
            currFolder.children = moveFolders(foldersToMove, currFolder.children, newFatherId);
        }else{
            //its not new parent - filtering its children and going inside those we kept
            currFolder.children = currFolder.children.filter(e => !foldersToMove.some(f => f.id === e.id));
            if(currFolder.children.length > 0){
                currFolder.children = moveFolders(foldersToMove, currFolder.children, newFatherId);
            }
        }
    }
    return rootFolders;
}

export const moveToFoldersFilter = (folder: DocumentFolder, path: {name: string, id: string}[]): boolean => {
    if(!path.length) return false;
    if(path[0].name === TRASH_FOLDER_NAME){
        //if it is trash folder - can be moved to any folder except trash and gallery
        return folder.name !== TRASH_FOLDER_NAME && folder.name !== GALLERY_FOLDER_NAME;
    }else{
        //if it is any other folder - can be moved to root only
        return folder.name === path[0].name;
    }
}


export const renameFolder = (id: string, newName: string, folders: DocumentFolder[]): DocumentFolder[] => {
    for(let i = 0; i < folders.length; i++){
        const currFolder = folders[i];
        if(currFolder.id === id){
            currFolder.name = newName;
            return folders;
        }else{
            currFolder.children = renameFolder(id, newName, currFolder.children);
        }
    }
    return folders;
}

export const getColorByStatus = (status: string | null): string => {
    switch (status){
        case 'draft': return '#E9E9E9';
        case 'sent': return '#2D9BF0';
        case 'viewed': return '#652CB3';
        case 'expired': return '#F24727';
        case 'declined': return '#DA0063';
        case 'completed': return '#0CA789';
        case 'complexed': return '#404BB2';
        case 'signed': return '#404BB2';
        default: return 'black';
    }
}

export const getBlankDocument = (folderId: string, workspaceId: string): DocumentFile => {
    return {
        documentId: null,
        description: null,
        name: '',
        folderId,
        id: '',
        workspaceId,
        status: null,
        origin: false,
        type: 'DOCUMENT',
        version: null,
        latest: null,
        parentId:null,
        visible:null,
        lastEditDate: '',
        editorId: null,
        creatorId: null,
        createdDate: '',
        automationId: null,
        tags: [],
        accessRules: null,
        data: {
            config: null,
            innerHtml: '',
            variables: [],
            editor: [{"id":"ee04e9bc7-3423-4719-88ae-a444352eafca","type":"page","data":{"content":[{"id":"ed1454dd4-f73f-4d8c-81ee-3b14261353f9","type":"row","data":{"content":[{"id":"ef08c8c68-05a9-4e68-a7d3-5538ef260c73","type":"text","data":{"content":[{"id":"efdb074c9-20fe-4b75-a2ea-a3574976444e","type":"paragraph","dataJS":{"align":"start","text":""}}],"width":"100"}}]}}],"header":[],"footer":[],"size":"A4","orientation":"portrait"}}]
        }
    }
}

export const getStatusColor = (status?: TDocumentStatus | TDocumentExecutorStatus): string => {

    switch (status) {
        case DRAFT_STATUS: return colors.text.blue;
        case SHARED_DRAFT_STATUS: return colors.text.blue;
        case APPROVAL_STATUS: return colors.decorative.additional;
        case REMARKS_STATUS: return colors.text.warning;
        case SIGNING_STATUS: return colors.decorative.additional_3;
        case COMPLETED_STATUS: return colors.text.success;
        case TERMINATED_STATUS: return colors.decorative.orange;
        //
        case PENDING_STATUS: return colors.primary.blue_dark;
        case IN_PROGRESS_STATUS: return colors.decorative.violet;
        case REJECTED_STATUS: return colors.warningActions.red;
        case APPROVED_STATUS: return colors.decorative.green_dark;
        case SKIPPED_STATUS: return colors.text.grey_dark;
        default: return colors.text.blue;
    }
}

export const getStatusTitle = (status?: TDocumentStatus): string => {

    switch (status) {
        case DRAFT_STATUS: return 'Draft';
        case SHARED_DRAFT_STATUS: return 'Shared Draft';
        case APPROVAL_STATUS: return 'Approval';
        case REMARKS_STATUS: return 'Remarks';
        case SIGNING_STATUS: return 'Signing';
        case COMPLETED_STATUS: return 'Completed';
        case TERMINATED_STATUS: return 'Terminated';
        default: return '-----';
    }
}

export const ActorStatusVariants: {status: TDocumentStatus, title: string}[] = [
    {status: DRAFT_STATUS, title: 'Draft'},
    {status: SHARED_DRAFT_STATUS, title: 'Shared Draft'},
    {status: APPROVAL_STATUS, title: 'Approval'},
    {status: REMARKS_STATUS, title: 'Remarks'},
    {status: SIGNING_STATUS, title: 'Signing'},
    {status: COMPLETED_STATUS, title: 'Completed'},
    {status: TERMINATED_STATUS, title: 'Terminated'},
];



export const generateColorsForTags = (existingMapper: tagsColorMapper[], tagIds: string[]): tagsColorMapper[] => {
    //copy array
    const mapper = [...existingMapper];
    //searching for new framework
    const neededGenerate = tagIds.filter((e) => !existingMapper.some(m => m.id === e));
    //generating color for each
    neededGenerate.forEach(e => {
        const color = randomHSLA(`${e}#${e.length.toString()}`);
        mapper.push({id: e, ...color});
    })
    return mapper;
}

type backAndFontColorType = {background: string, fontColor: string};
export function randomHSLA(name: string): backAndFontColorType{
    const background: string | number[] = getPastelColor(`SOME_TEXT6669_${name}`, {alpha: '1'}).hslRaw; //hslRaw: [ 288, '70%', '70%' ]
    const hue: number = background[0] as number;
    // const r = ~~(360 * Math.random());
    return {
        background: `hsl(${hue}, 76%, 96%)`,
        fontColor: `hsl(${hue}, 47%, 68%)`
    }
}


export const convertFullDocumentToSmallDocument = (fullDoc: NewDocumentModel): ShortDocumentModel => {
    return {
        id: fullDoc.id,
        documentId: fullDoc.documentId,
        workspaceId: fullDoc.workspaceId,
        type:     fullDoc.type,
        name:     fullDoc.name,
        folderId: fullDoc.folderId,
        status:   fullDoc.status,
        origin:  fullDoc.origin,
        version:  fullDoc.version,
        latest:   fullDoc.latest,
        createdDate: fullDoc.createdDate,
        tags:     fullDoc.tags,
        lastModified: fullDoc.lastModified,
        recipients: fullDoc.recipients,
        generatedFileId: fullDoc.generatedFileId,
        workspaceName: fullDoc.workspaceName,
        sender: fullDoc.sender,

        currentStepEndDate: fullDoc.currentStepEndDate,
        deletedDate: fullDoc.deletedDate,
    }
}