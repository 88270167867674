import {Box, styled} from "@mui/material";

type TEditorDropZoneForFillableFieldStyledProps = {
    isOver?: boolean;
    canDrop?: boolean;
}
export const EditorDropZoneForFillableFieldStyled = styled(Box, {shouldForwardProp: (propName) => (
        !['isOver', 'canDrop'].includes(propName.toString())
)})<TEditorDropZoneForFillableFieldStyledProps>(({isOver, canDrop, onMouseDown}) => ({
    minHeight: '5px', minWidth: '5px',
    outline: canDrop
        ? '1px solid lightblue'
        : onMouseDown
            ? '1px solid transparent'
            : '1px solid transparent',
    borderRadius: '1px',
    cursor: onMouseDown ? 'col-resize' : undefined,
    backgroundColor: isOver ? 'lightblue' : undefined,
    // backgroundColor:  'lightblue',

    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    // gap: '4px',
    // zIndex: canDrop ? 99999 : undefined,
}))
EditorDropZoneForFillableFieldStyled.defaultProps = {
    className: 'dropZoneForFillableItem',
}