import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    assetsDialogsSelector,
    loadings,
    saasAssetsSettingsSelector,
    setCreateSettingRowDialogAction,
} from "../../store/store";
import {useForm} from "../../../../../newShared/hooks/useForm";
import {
    PATH_LOCAL_ASSET_MANAGEMENT_SAAS,
    PATH_LOCAL_ASSET_MANAGEMENT_SAAS_ID,
    PATH_LOCAL_ASSET_MANAGEMENT_SAAS_NEW
} from "../../constants";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_ASSET_MANAGEMENT} from "../../../../../newShared/constants";
import {createSaasAssetAction, getSaasAssetsSettingsAction,} from "../../store/actions";
import {TSaasAsset, TSetCreateSettingRowDialog} from "../../types";


export const useSaasNew = () => {
    //root
    const dispatch = useDispatch();
    const history = useHistory();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    //selectors
    const {isLoadingSettings, isLoadingCreateAsset} = useSelector(loadings);
    const settings = useSelector(saasAssetsSettingsSelector);
    const {createAsset: {isOpen: isOpenCreateAsset}} = useSelector(assetsDialogsSelector);


    const initialSaasAsset: TSaasAsset = {
        id: '',
        name: '',
        owner: '',
        url: '',
        seeds: 0,
        licenses: 0,
        integration: '',
        billingCycle: '',
        customFields: {},
    }
    //form
    const {form, setForm, handleChange} = useForm<TSaasAsset>(initialSaasAsset);

    const controller = new AbortController();
    useEffect(() => {
        if(!settings) {
            dispatch(getSaasAssetsSettingsAction({data: {organizationId: '', workspaceId: ''}, signal: controller.signal}));
        }
        if(settings){
            const customFields: { [key: string]: string } = {};
            Object.keys(settings.customFields).length > 0 && Object.keys(settings.customFields).forEach((key) => customFields[key] = '');
            setForm({...form, customFields});
        }

        return () => {
            controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(settings && Object.keys(settings.customFields).length > 0 && !Object.keys(form.customFields).length){
            const customFields: { [key: string]: string } = {};
            Object.keys(settings.customFields).forEach((key) => customFields[key] = '');
            setForm({...form, customFields});
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    useEffect(() => {
        isOpenCreateAsset && setForm(initialSaasAsset);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenCreateAsset]);


    useSetBreadcrumbs([
        {
            title: tMenu('Asset Management'),
            path: PATH_LOCAL_ASSET_MANAGEMENT
        },
        {
            // title: tMenu('Saas'),
            title: 'Saas',
            path: PATH_LOCAL_ASSET_MANAGEMENT_SAAS
        },
        {
            title: tMenu('New asset'),
            path: PATH_LOCAL_ASSET_MANAGEMENT_SAAS_NEW
        },
    ]);

    //actions
    const handleGoBack = () => history.push(PATH_LOCAL_ASSET_MANAGEMENT_SAAS);

    // const isOkId = () => {
    //     return form.id.trim().length > 0 ? Boolean( form.id.match(/^[A-Za-z0-9-_]+$/)) : true;
    // }

    const handleSave = () => {
        form.name.trim().length && dispatch(createSaasAssetAction({
            data: {
                workspaceId: '',
                organizationId: '',
                asset: {...form, seeds: Number(form.seeds), licenses: Number(form.licenses)}
            }, onSuccess}));
    }

    const onSuccess = (id: string) => {
        history.push(PATH_LOCAL_ASSET_MANAGEMENT_SAAS_ID.replace(':id', id));
    }


    const handleSetBillingCycle = (title: string) => {
        setForm({...form, billingCycle: title});
    }

    const handleChangeCustomField = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setForm((prevValues) => ({
            ...prevValues,
            customFields: {
                ...prevValues.customFields,
                [event.target.name]: event.target.value,
            },
        }));
    };


    //CreateAssetDialog
    const openCreateSettingRowDialog = (data: TSetCreateSettingRowDialog) => {dispatch(setCreateSettingRowDialogAction(data))};


    return {
        settings,
        form,
        handleChange,

        handleSave,
        handleGoBack,
        onSuccess,
        handleChangeCustomField,
        handleSetBillingCycle,

        isLoadingSettings,
        isLoadingCreateAsset,

        createAssetDialog: {
            isOpenCreateAsset,
        },

        openCreateSettingRowDialog,
    }
}
