import {usePreviewRoleDialog} from "../../../../../hooks/organizations/dialogs/usePreviewRoleDialog";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const PreviewRoleDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathSettings.dialogs'});
    const {
        role,
        isOpen,
        handleClose
    } = usePreviewRoleDialog();

    if(!role){
        return(<></>);
    }

    return(
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{t(`Role {{roleName}} permissions`, {roleName: role.name})}</DialogTitle>

            <DialogContent>
                {role.permissions.slice().sort().map(e => <Typography key={e} fontSize={'12px'} fontWeight={400}>{e}</Typography>)}
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{t('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}