import {useDispatch, useSelector} from "react-redux";
import {deleteRow, dialogs, hideDeleteRow} from "../../../store/slice";

export const useDeleteRowDialog = () => {
    const dispatch = useDispatch();

    const {deleteRowDialog} = useSelector(dialogs);

    const handleClose = () => {
        dispatch(hideDeleteRow());
    }

    const handleSubmit = () => {
        deleteRowDialog.row && dispatch(deleteRow(deleteRowDialog.row));
    }

    return{
        isOpen: deleteRowDialog.isOpen,
        row: deleteRowDialog.row,
        handleClose,
        handleSubmit
    }
}