import {EditIntegrationCommonPropsType} from "../../../../types";
import {Flex} from "../../../../../../../newShared/components/Layouts";
import * as S from '../../../styled';
import React from "react";
import {LoadingButton} from "../../../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useEditBamboohr} from "../../../../hooks/integrationsManagement/editIntegrationComponents/bamboohr";

export const EditIntegrationBamboohr = (props: EditIntegrationCommonPropsType) => {
    const {
        form,
        actions,
        isOk,
        isLoading
    } = useEditBamboohr(props);
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'pathIntegrations.AvailableAppsList.dialogs'});

    return(
        <S.AppConfigContainer>
            <S.TextFieldCustom
                variant={'outlined'}
                name={'name'}
                label={t('Application name')}
                value={form.name}
                onChange={actions.handleChange}
                size={'small'}
            />

            <S.TextFieldCustom
                variant={'outlined'}
                name={'companyDomain'}
                label={t('Company domain')}
                value={form.integration.companyDomain}
                onChange={actions.handleChangeIntegration}
                sx={{margin: '16px 0 0 0'}}
                size={'small'}
            />

            <S.TextFieldCustom
                variant={'outlined'}
                name={'apiKey'}
                label={t('Api key')}
                value={form.integration.apiKey}
                onChange={actions.handleChangeIntegration}
                sx={{margin: '16px 0 0 0'}}
                size={'small'}
            />

            {/*<S.TextFieldCustom*/}
            {/*    variant={'outlined'}*/}
            {/*    name={'password'}*/}
            {/*    label={'Password'}*/}
            {/*    value={form.integration.password}*/}
            {/*    onChange={actions.handleChangeIntegration}*/}
            {/*    sx={{margin: '16px 0 0 0'}}*/}
            {/*    size={'small'}*/}
            {/*/>*/}

            <Flex w={'100%'} jc={'flex-end'} m={'16px 0 0 0'}>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={actions.handleSubmit}
                    disabled={!isOk}
                >
                    {t('Save')}
                </LoadingButton>
            </Flex>
        </S.AppConfigContainer>
    )
}