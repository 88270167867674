import React, {useEffect} from "react";
import * as yup from "yup";
import {useFormik} from "formik";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import {DelegatedTaskModel} from "../../../../../../../newShared/GQLTypes";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../../../../../newShared/components/Layouts";
import {LoadingButton} from "../../../../../../../newShared/components/Basic/CommonButtons";

export const CancelDelegatedTaskDialog = ({task, isOpen, handleCancelTask, handleClose, isLoading}:
                                              {
                                                  task: DelegatedTaskModel | null,
                                                  isOpen: boolean,
                                                  isLoading: boolean,
                                                  handleClose: () => void,
                                                  handleCancelTask: (reason: string) => void}) => {
    const {tCommon} = useMainTranslation();

    useEffect(() => {
        if(isOpen) formik.resetForm();
        //eslint-disable-next-line
    }, [isOpen]);

    let validationSchema = yup.object({
        reason: yup.string()
            .required('Comment is required')
            .max(1000, ('Comment should be less than 1000 symbols')),
    });

    const formik = useFormik({
        initialValues: {reason: ''},
        isInitialValid: false,
        validationSchema,
        onSubmit: (values) => handleCancelTask(values.reason)
    });
    const formikOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldTouched(e.target.name, true, false);
        formik.handleChange(e);
    };

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{('Cancel reason')}</DialogTitle>
            <DialogContent>
                <Flex direction={'column'} gap={'16px'}>
                    <Typography>
                        {('Are you sure you want to cancel task ')}
                        <b>{task?.id}</b>?
                    </Typography>
                    <Typography>{('Please provide cancel reason:')}</Typography>
                    <TextField
                        required
                        onChange={formikOnChange}
                        onBlur={formik.handleBlur}
                        label={('Comment')}
                        name={'reason'}
                        multiline
                        minRows={5}
                        value={formik.values.reason}
                        error={formik.touched.reason && Boolean(formik.errors.reason)}
                        helperText={formik.touched.reason && formik.errors.reason}
                    />
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button variant={'text'} size={'small'} onClick={handleClose}>{tCommon('Close')}</Button>
                <LoadingButton
                    onClick={formik.submitForm}
                    size={'small'}
                    disabled={!formik.isValid}
                    loading={isLoading}
                >
                    {('Confirm')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}