import {eraseSelectedExamAction, examsSelector, loadings,} from "../../store/slice";
import {useDispatch, useSelector} from "react-redux";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_TRAININGS_EXAMS, PATH_TRAININGS_EXAMS_EXACT, TRAININGS_PATH} from "../../constants";

import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {
    mainTrainingsGetExamByIdAndVersionAction,
    mainTrainingsMoveExamToArchiveAction,
    mainTrainingsRestoreExamFromArchiveAction,
} from "../../store/actions";
import {useTrainingsDialogs} from "../useTrainingsDialogs";


export const useExamExactPage = () => {
    const {tMenu} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.pathExams'});
    const dispatch = useDispatch();
    const {examExact: isLoadingExact, changeExamStatus: isLoadingChangeStatus} = useSelector(loadings);
    const {selectedExam: exam} = useSelector(examsSelector);
    const {examId} = useParams<{examId: string}>();
    const {createAssignment: {handleSet: handleSetOpenAssign}} = useTrainingsDialogs();

    const createExamDialog = useTrainingsDialogs().createExam;

    const [openedSection, setOpenedSection] = useState<string>('');
    const handleToggleSection = (sectionId: string) => {
        if (openedSection === sectionId) {setOpenedSection('')}
        else setOpenedSection(sectionId);
    }

    const handleOpenAssign = () => {
        handleSetOpenAssign({isOpen: true, exam })
    }
    const handleMoveToArchive = () => {
        exam && dispatch(mainTrainingsMoveExamToArchiveAction({data: {examId: exam.examId}, snack: 'success'}))
    }

    const handleRestoreFromArchive = () => {
        exam && dispatch(mainTrainingsRestoreExamFromArchiveAction({data: {examId: exam.examId}, snack: 'success'}))
    }


    useSetBreadcrumbs([
        {
            title: tMenu('Training management'),
            path: TRAININGS_PATH,
        },
        {
            title: tMenu('Exams'),
            path: PATH_TRAININGS_EXAMS
        },
        {
            title: exam?.name ?? 'Loading...',
            path: PATH_TRAININGS_EXAMS_EXACT.replace(':examId', examId ?? '')
        }
    ]);


    useEffect(() => {
        exam && setOpenedSection('');

        //eslint-disable-next-line
    }, [exam]);


    useEffect(() => {
        if (examId) dispatch(mainTrainingsGetExamByIdAndVersionAction({data: {examId, version: 'latest'}}))
        else createExamDialog.handleSet({isOpen: !examId});

        return () => {
            dispatch(eraseSelectedExamAction());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [examId]);



    return {
        exam,
        openedSection,
        isLoadingExact,
        isLoadingChangeStatus,
        handleToggleSection,
        handleOpenAssign,
        handleMoveToArchive,
        handleRestoreFromArchive,
    }

}
