import {useHistory, useLocation} from "react-router-dom";
import {useEffect} from "react";
import {ACCEPT_INVITATION_PROTECTED, ACCEPT_INVITATION_UNPROTECTED} from "../../constants";
import {Spinner} from "../../../../../newShared/components/Spinner";
import {UseManageWorkspacesAndOrganizations} from "../../../cookies/hooks/useManageWorkspacesAndOrganizations";

export const InvitationAcceptSelector = () => {
    const history = useHistory();
    const {search} = useLocation();

    const {getCookieData} = UseManageWorkspacesAndOrganizations();

    useEffect(() => {
        const data = getCookieData();
        if(data && data.is2FaAuthComplete && data.isAuthComplete){
            // console.log('cookies data present');
            history.push(ACCEPT_INVITATION_PROTECTED + search);
        }else{
            // console.log('cookies data NOT present');
            history.push(ACCEPT_INVITATION_UNPROTECTED + search);
        }
        //eslint-disable-next-line
    }, []);

    return(
        <Spinner />
    )
}