import {Handle, Position} from "react-flow-renderer";
import AddIcon from '@mui/icons-material/Add';
import {ALLOWDRAG} from "../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {
    openAddEvidence,
    openDocFile,
    openDocLink,
    openDocPolicy,
    openStageActor,
    selectedAutomation
} from "../../../store/slice";
import {Flex} from "../../../../../../newShared/components/Layouts";

type plusNodeProps = {
    type: 'document' | 'actor' | 'evidence';
    stageId?: number;
    revDir: boolean
}

export const PlusNode = ({data} : {data:  plusNodeProps}) => {
    const {revDir, type, stageId} = data;
    const dispatch = useDispatch();
    const auto = useSelector(selectedAutomation);

    const handleClick = () => {
        if(type === 'document'){
            if(auto?.documents.type === 'document'){
                dispatch(openDocPolicy());
            }else if(auto?.documents.type === 'link'){
                dispatch(openDocLink());
            }else if(auto?.documents.type === 'file'){
                dispatch(openDocFile());
            }
        }else if(type === 'actor'){
            if(stageId !== undefined) dispatch(openStageActor({stageId}));
        }else if(type === 'evidence'){
            dispatch(openAddEvidence());
        }
    }
    return(
        <Flex
            w={'1.5rem'}
            h={'1.5rem'}
            br={'50%'}
            background={'white'}
            border={'1px solid dotted'}
            ai={'center'}
            jc={'center'}
            cursor={'pointer'}
            className={ALLOWDRAG ? 'nodrag': ''}
            onClick={handleClick}
        >
            <Handle
                type="target"
                position={!revDir ? Position.Left : Position.Right}
                id="a"
                isConnectable={false}
                style={{opacity: '0'}}
            />
            <AddIcon style={{color: '#FAC000'}}/>
        </Flex>
    )
}