import {AxiosResponse} from "axios";
import {print} from "graphql";
import axiosSync from "../../../../newShared/utils/axios/axios";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {PATH_SERVER} from "../../../../newShared/constants";


import {
    archiveReportByUser,
    createReport,
    deleteAssetFromNCRByUser,
    deleteReport,
    getAppLogsToDate,
    getDataForCreateReportSnapshot,
    getFrameworksIdNameLogo,
    getFullNcrReportPreviewByUser,
    getIntegrationDataByCollectionReports,
    getIntegrationsReport,
    getReport,
    getReportAssetCollectorDataByUser,
    getReportControlById,
    getReportControls,
    getReports,
    leaveResponseToNCRByUser,
    setSnapshotReportResponseByUser,
    uploadAssetsToNCRByUser,
} from "./query";
import {
    ArchiveReportByUserMutation,
    ArchiveReportByUserMutationVariables,
    CreateReportMutation,
    CreateReportMutationVariables,
    DeleteAssetFromNcrByUserMutation,
    DeleteAssetFromNcrByUserMutationVariables,
    DeleteReportMutation,
    DeleteReportMutationVariables,
    GetAppLogsToDateQuery,
    GetAppLogsToDateQueryVariables,
    GetDataForCreateReportSnapshotQuery,
    GetDataForCreateReportSnapshotQueryVariables,
    GetFrameworksIdNameLogoQuery,
    GetFrameworksIdNameLogoQueryVariables,
    GetFullNcrReportPreviewByUserQuery,
    GetFullNcrReportPreviewByUserQueryVariables,
    GetIntegrationDataByCollectionReportsQuery,
    GetIntegrationDataByCollectionReportsQueryVariables,
    GetIntegrationsReportQuery,
    GetIntegrationsReportQueryVariables,
    GetReportAssetCollectorDataByUserQuery,
    GetReportAssetCollectorDataByUserQueryVariables,
    GetReportControlByIdQuery,
    GetReportControlByIdQueryVariables,
    GetReportControlsQuery,
    GetReportControlsQueryVariables,
    GetReportQuery,
    GetReportQueryVariables,
    GetReportsQuery,
    GetReportsQueryVariables,
    LeaveResponseToNcrByUserMutation,
    LeaveResponseToNcrByUserMutationVariables,
    SetSnapshotReportResponseByUserMutation,
    SetSnapshotReportResponseByUserMutationVariables,
    UploadAssetsToNcrByUserMutation,
    UploadAssetsToNcrByUserMutationVariables,
} from "../../../../newShared/GQLTypes";


export const createReportApi = async (data: CreateReportMutationVariables, signal?: AbortSignal): Promise<CreateReportMutation["createReport"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(createReport),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createReport
    })
)

export const getDataForCreateReportSnapshotApi = async (data: GetDataForCreateReportSnapshotQueryVariables, signal?: AbortSignal): Promise<GetDataForCreateReportSnapshotQuery["getDataForCreateReportSnapshot"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getDataForCreateReportSnapshot),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getDataForCreateReportSnapshot
    })
)

export const getReportsApi = async (data: GetReportsQueryVariables, signal?: AbortSignal): Promise<GetReportsQuery["getReports"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getReports),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getReports
    })
)

export const getReportApi = async (data: GetReportQueryVariables, signal?: AbortSignal): Promise<GetReportQuery["getReport"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getReport),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getReport
    })
)

export const getReportControlsApi = async (data: GetReportControlsQueryVariables, signal?: AbortSignal): Promise<GetReportControlsQuery["getReportControls"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getReportControls),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getReportControls
    })
)

export const getReportControlByIdApi = async (data: GetReportControlByIdQueryVariables, signal?: AbortSignal): Promise<GetReportControlByIdQuery["getReportControlById"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getReportControlById),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getReportControlById
    })
)

export const getFrameworksIdNameLogoApi = async (data: GetFrameworksIdNameLogoQueryVariables, signal?: AbortSignal): Promise<GetFrameworksIdNameLogoQuery["getFrameworksControlsEvidencesShortModel"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getFrameworksIdNameLogo),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getFrameworksControlsEvidencesShortModel
    })
)

export const archiveReportByUserApi = async (data: ArchiveReportByUserMutationVariables, signal?: AbortSignal): Promise<ArchiveReportByUserMutation["archiveReportByUser"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(archiveReportByUser),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.archiveReportByUser
    })
)

export const deleteReportApi = async (data: DeleteReportMutationVariables, signal?: AbortSignal): Promise<DeleteReportMutation["deleteReport"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(deleteReport),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteReport
    })
)

export const setSnapshotReportResponseByUserApi = async (data: SetSnapshotReportResponseByUserMutationVariables, signal?: AbortSignal): Promise<SetSnapshotReportResponseByUserMutation["setSnapshotReportResponseByUser"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(setSnapshotReportResponseByUser),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.setSnapshotReportResponseByUser
    })
)

export const getReportAssetCollectorDataByUserApi = async (data: GetReportAssetCollectorDataByUserQueryVariables, signal?: AbortSignal): Promise<GetReportAssetCollectorDataByUserQuery["getReportAssetCollectorDataByUser"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getReportAssetCollectorDataByUser),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getReportAssetCollectorDataByUser
    })
)

// NCR NCR NCR NCR
export const leaveResponseToNCRByUserApi = async (data: LeaveResponseToNcrByUserMutationVariables, signal?: AbortSignal): Promise<LeaveResponseToNcrByUserMutation["leaveResponseToNCRByUser"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(leaveResponseToNCRByUser),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.leaveResponseToNCRByUser
    })
)

export const uploadAssetsToNCRByUserApi = async (data: UploadAssetsToNcrByUserMutationVariables, signal?: AbortSignal): Promise<UploadAssetsToNcrByUserMutation["uploadAssetsToNCRByUser"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(uploadAssetsToNCRByUser),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.uploadAssetsToNCRByUser
    })
)

export const deleteAssetFromNCRByUserApi = async (data: DeleteAssetFromNcrByUserMutationVariables, signal?: AbortSignal): Promise<DeleteAssetFromNcrByUserMutation["deleteAssetFromNCRByUser"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(deleteAssetFromNCRByUser),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteAssetFromNCRByUser
    })
)

export const getFullNcrReportPreviewByUserApi = async (data: GetFullNcrReportPreviewByUserQueryVariables, signal?: AbortSignal): Promise<GetFullNcrReportPreviewByUserQuery["getFullNcrReportPreviewByUser"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getFullNcrReportPreviewByUser),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getFullNcrReportPreviewByUser
    })
)

export const getIntegrationsReportApi = async (data: GetIntegrationsReportQueryVariables, signal?: AbortSignal): Promise<GetIntegrationsReportQuery["getIntegrationsReport"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getIntegrationsReport),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getIntegrationsReport
    })
)

export const getIntegrationDataByCollectionReportsApi = async (data: GetIntegrationDataByCollectionReportsQueryVariables, signal?: AbortSignal): Promise<GetIntegrationDataByCollectionReportsQuery["getIntegrationDataByCollectionReports"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getIntegrationDataByCollectionReports),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getIntegrationDataByCollectionReports
    })
)

export const getAppLogsToDateApi = async (data: GetAppLogsToDateQueryVariables, signal?: AbortSignal): Promise<GetAppLogsToDateQuery["getAppLogsToDate"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getAppLogsToDate),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAppLogsToDate
    })
)
