import {FC} from "react";
import {IconButton} from "@mui/material";
import {Settings} from "@mui/icons-material";
import {useHistory} from "react-router";
import {PATH_LOCAL_SETTINGS} from "../../../../../newShared/constants";
import {useLeftMenu} from "../../../leftMenu/hooks/useLeftMenu";

export const TopBarSettings: FC = () => {
    const history = useHistory();

    const {setOpen} = useLeftMenu();

    const onClick = () => {
        setOpen(false);
        history.push(PATH_LOCAL_SETTINGS);
    }

    return (
        <>
            <IconButton
                onClick={onClick}
                size={"large"}
            >
                <Settings />
            </IconButton>
        </>
    )
}