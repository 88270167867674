import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import colors from "../../../../../../../../newShared/theme/colors";

type LeaveDialogPropsType = {
    isOpen: boolean,
    handleClose: () => void,
    onSuccess: () => void,
    isCreateMode: boolean
}
export const LeaveDialog = ({isOpen, onSuccess, handleClose, isCreateMode}: LeaveDialogPropsType) => {
    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{isCreateMode ? ('Cancel creation') : ('Cancel edit')}</DialogTitle>

            <DialogContent>
                <Typography variant={'*bodyText2'} color={colors.grayText}>{('Your changes will be lost if you don’t save them.')}</Typography>
            </DialogContent>

            <DialogActions>
                <Button variant={'text'} size={'small'} onClick={handleClose}>{('Close')}</Button>
                <Button size={'small'} onClick={onSuccess}>{("Don't save")}</Button>
            </DialogActions>
        </Dialog>
    )
}