import {TRisk} from "../../../types";
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from "@mui/material";
import React, {Fragment, useEffect, useState} from "react";
import colors from "../../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from "@mui/material/Divider";
import {getAvatarInitials, getName} from "../../../../../../newShared/utils/text";
import {AutocompleteCollaborators} from "../../../../../../newShared/components/AutocompleteCollaborators";
import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideAddActionOwner} from "../../../store/slice";
import {optionIsTCollaborator,} from "../../../../../../newShared/components/AutocompleteCollaborators/types";
import {getOptionLabel} from "../../../../../../newShared/components/AutocompleteCollaborators/helpers";
import {uuid} from "../../../../../../newShared/utils";
import {WorkspaceCollabModel} from "../../../../../../newShared/GQLTypes";

export const AddControlOwner = ({onSuccess}: {onSuccess: (updatedRisk: TRisk) => void, risk?: TRisk | undefined}) => {
    const {t} = useMainTranslation('', {keyPrefix: 'risks'});

    const dispatch = useDispatch();
    const {addControlOwner: {isOpen, risk}} = useSelector(dialogs);


    const handleDeleteOwner = (json: string) => {
        setOwners([...owners.filter(e => JSON.stringify(e) !== json)]);
        setUniqueInputId(uuid());
    };

    const handleClose = () => {
        dispatch(hideAddActionOwner());
    }

    const handleSubmit = () => {
        if(risk){
            const strings = owners.map(e => typeof e === "string" ? e : getName(e?.firstName || '', e?.lastName || ''));

            onSuccess({...risk, control: {...risk.control, owners: [...new Set(strings)]}});
            handleClose();
        }
    }

    const [uniqueInputId, setUniqueInputId] = useState<string>(uuid());
    const [owners, setOwners] = useState<(WorkspaceCollabModel | string)[]>([]);
    useEffect(() => {
        setOwners(risk?.control?.owners || [])
    }, [risk?.control?.owners]);

    return (
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{t('Add owners')}</DialogTitle>
            <DialogContent sx={{gap: '8px', display: 'flex', flexDirection: 'column'}}>
                <Typography variant={'body2'} color={colors.darkBlueText}>{t('Select a owner from the collaborators list or add an owner manually')}</Typography>
                <AutocompleteCollaborators
                    key={uniqueInputId}
                    hideCount
                    freeSolo
                    multiple
                    getOptionLabelCustom={"name+email"}
                    getOptionLabel={option => optionIsTCollaborator(option) ? getName(option.firstName || '', option.lastName || '') : option}
                    defaultValue={owners}
                    onChange={value => setOwners(JSON.parse(JSON.stringify(value)))}
                />

                <List dense>
                    {owners.map((e) => (
                        <Fragment key={JSON.stringify(e)}>
                            <ListItem secondaryAction={
                                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteOwner(JSON.stringify(e))}>
                                    <DeleteIcon fontSize={'medium'} fill={colors.text.grey}/>
                                </IconButton>
                            }>
                                <ListItemAvatar>
                                    <Avatar>
                                        <Typography color={colors.text.white} fontSize={'16px'} fontWeight={600}>
                                            {getAvatarInitials(getOptionLabel("name")(e))}
                                        </Typography>
                                    </Avatar>
                                </ListItemAvatar>

                                <ListItemText>
                                    <Typography color={colors.grayText} variant={'body2'}>{getOptionLabel("name")(e)}</Typography>
                                </ListItemText>
                            </ListItem>
                            <Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable, margin: '16px 0 0 0'}}/>
                        </Fragment>
                    ))}
                </List>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>Cancel</Button>
                <Button variant={'contained'} onClick={handleSubmit}
                    // disabled={!isOkToSave()}
                >{t('Save')}</Button>
            </DialogActions>
        </Dialog>
    )
}