import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../../../../../../newShared/components/Layouts";
import {Typography} from "@mui/material";
import React from "react";
import colors from "../../../../../../../../newShared/theme/colors";

export const ProgressBar = ({progress}: {progress: number}) => {
    const {revDir} = useMainTranslation();
    const fontColor = '#344054';
    const strokeColor = colors.primary.hovered;
    const notFilledStrokeColor = '#F2F4F7';

    return(
        <Flex w={'100%'} jc={'space-between'} ai={'center'}>

            <Flex w={'100%'} ai={'center'} position={'relative'}>
                <Flex w={`${progress}%`} h={'8px'} background={strokeColor} br={'8px'} position={'absolute'}/>
                <Flex w={`100%`} h={'8px'} background={notFilledStrokeColor} br={'8px'}/>
            </Flex>

            <Typography variant={'bodySmall'} color={fontColor} sx={{margin: !revDir ? '0 0 0 12px' : '0 12px 0 0'}}>{progress}%</Typography>
        </Flex>
    )
}