import {useDispatch, useSelector} from "react-redux";
import {
    deselectDocument as deselectDocumentDocuments,
    dialogs,
    eraseFiles,
    expandMoreFolder,
    files,
    foldersPath,
    handleReturnToDraftAction,
    hideExplorerCreateDocEditorDialog,
    hideExplorerEditorDialog,
    hideShowRecipients,
    isLoading,
    openShowRecipients,
    pageInfoSelector,
    selectedFiles,
    selectedFolder,
    selectedFolders,
    selectedNewDocument,
    setAsEvidenceDialogAction,
} from "../../store/slice";
import {
    ACCEPT_UPLOAD_FILES,
    availableColumns,
    GALLERY_FOLDER_NAME,
    TEMPLATES_FOLDER_NAME,
    TRASH_FOLDER_NAME,
    TREE_PATH
} from "../../constants";
import React, {useEffect, useState} from "react";
import {
    changeDocumentStageEtaAction,
    DownloadFile,
    GetFullDocumentWithExecutor,
    GetShortDocumentsByFolderIdWithFilterPaging,
    handlePreviewBeforeSendAction,
    InvalidateDocument,
    RestartSignStageByStageIdAndGroupId,
    RestartSignStageByStageIdAndRecipient,
    SendToNextStepByStageId,
    StartNewDocumentExecution,
    UpdateFullDocument,
    UploadFile
} from "../../store/actions";
import {getUniqueFileName} from "../../helpers";
import {addInfoSnack} from "../../../../barsEnvironment/snack/store/slice";
import {toBase64} from "../../../../../newShared/utils/base64/base64";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {DocumentFolder, TShortDocument} from "../../types";
import {
    DocumentExecutorGroupModel,
    DocumentExecutorRecipientModel,
    DocumentExecutorStepModel,
    GetShortDocumentsByFolderIdWithFilterPagingQueryVariables,
    NewDocDataVariableModel
} from "../../../../../newShared/GQLTypes";
import {useScrollPagination} from "../../../../../newShared/hooks/useScrollPagination";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {useSnack} from "../../../../barsEnvironment/snack/hooks/useSnack";
import {TEditorActorsConfigFromDB, TEditorElementData} from "../../../../../newShared/components/editorUnion/types";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {deselectDocument as deselectDocumentVica} from "../../../vica/store/tasks/slice";
import {useHistory} from "react-router";

export const useDocuments = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage'});
    const {user} = UseManageWorkspacesAndOrganizations();
    const history = useHistory();
    const dispatch = useDispatch();
    const snack = useSnack();
    const _isLoading = useSelector(isLoading);
    const {isLoadingFiles} = _isLoading;
    const {uploadFileDialog, addDocumentDialog} = useSelector(dialogs)
    const recipientsViewDialog = useSelector(dialogs).recipientsView;
    const _selectedFiles =  useSelector(selectedFiles);
    const _selectedFolders = useSelector(selectedFolders);
    const _selectedF = useSelector(selectedFolder);
    const _files = useSelector(files);
    const {currentFiltersForFetch, currentSearchForFetch} = useGenericFiltersStorage();

    const pageInfo = useSelector(pageInfoSelector);
    const _path = useSelector(foldersPath);
    const [isDrag, setDrag] = useState<boolean>(false);

    const getFilesByFolderIdWithFilter = (data: GetShortDocumentsByFolderIdWithFilterPagingQueryVariables, signal?: AbortSignal) => {
        dispatch(
            GetShortDocumentsByFolderIdWithFilterPaging({data: {...data, folderId: _selectedF?.id ?? '---'}, signal})
        );
    }

    const handleOpenFolder = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, folder: DocumentFolder) => {
        if(!isDrag){

            if(e.detail === 2 && !isLoadingFiles){
                // dispatch(openFolder({folder: folder, path: [..._path, {name: folder.name, id: folder.id}]}));
                // getFilesByFolderIdWithFilter({pageInfo: {page: 0, count: DOCUMENT_LIST_DEFAULT_COUNT}, folderId: folder.id, workspaceId: ''})

                history.push(TREE_PATH + '/' + [..._path.map(e => e.id).slice(1), folder.id].join('/'));

                if(_path[_path.length - 1]){ //if there is parent folder
                    dispatch(expandMoreFolder(_path[_path.length - 1].id));
                }
                return;
            }
        }
    }

    const handleOpenFile = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, file: TShortDocument) => {
        if(!isDrag && file){
            if(e.detail === 2){
                dispatch(GetFullDocumentWithExecutor({documentId: file.documentId ?? '', workspaceId: ''}))
            }
        }
    }

    const handleDownload = (file: TShortDocument) => {
        if(file.type === 'DOCUMENT' && file.generatedFileId ){
            dispatch(DownloadFile({fileId: file.generatedFileId, name: `${file.name}.pdf`}));

        }else if(file.type === 'FILE' && file.documentId){
            dispatch(DownloadFile({fileId: file.documentId, name: file.name}));
        }
    }


    const eraseFilesHandler = () => {dispatch(eraseFiles())};
    const openShowRecipientsDialog = (recipients: TShortDocument["recipients"]) => {dispatch(openShowRecipients({recipients}))};
    const hideShowRecipientsDialog = () => {dispatch(hideShowRecipients())};



    const fileHandler = async(files: FileList | null) => {
        const uploadedFiles:File[] = [];
        const fileNames: string[] = [];

        if(files){
            Array.from(files).forEach(e => {
                const tmp = e.name.split('.');
                const fileExtension = tmp[tmp.length - 1]?.toLowerCase();
                if(!ACCEPT_UPLOAD_FILES.split(', .').some(ext => ext === fileExtension)){
                    dispatch(addInfoSnack(t(`This extension is not supported!`)));
                    return;
                }

                const uniqueName = getUniqueFileName(e.name, fileNames.concat(_files.map((e, index) => e ? e.name : `Document ${index}`)));
                //updating name and setting file
                Object.defineProperty(e, 'name', {
                    writable: true,
                    value: uniqueName
                });

                fileNames.push(uniqueName);
                uploadedFiles.push(e);
            });
        }

        if(uploadedFiles.length > 0 && _selectedF && _path.length > 0){
            const addable: {file: string, fileName: string}[] = [];

            for (const e of uploadedFiles) {
                const fileBase64: string = await toBase64(e) as string;
                addable.push({file: fileBase64, fileName: e.name});
            }

            dispatch(UploadFile({
                items: addable,
                folderId: _selectedF.id,
                snack: true,
                isTemplate: _path[0].name === TEMPLATES_FOLDER_NAME
            }));
        }
    };



    useEffect(() => {
        return () => {
            // clearFilters();
            dispatch(hideExplorerCreateDocEditorDialog());
            dispatch(hideExplorerEditorDialog());
        }
        //eslint-disable-next-line
    }, [])


    const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => {
            _selectedF && getFilesByFolderIdWithFilter({pageInfo: { page, count, filters: {...currentFiltersForFetch, search: currentSearchForFetch}}, workspaceId: '', folderId: _selectedF.id});
        },
        arrayLength: _files.length,
        isLoading: isLoadingFiles,
        pageInfo,
        // cleanData: () => dispatch(eraseEmployees()),
        isNoMoreThanTotal: true,
        // customDependencies: [],
        // is0PageAddictive: false,
    })


    const _selectedDoc = useSelector(selectedNewDocument);
    const docId = _selectedDoc?.document?.documentId
    return{
        path: _path,
        isLoadingFiles,
        files: _files,
        pageInfo,
        selectedFolder: _selectedF,
        isFilesOrFoldersSelected: _selectedFiles.length > 0 || _selectedFolders.length > 0,
        fileHandler,
        isDrag,
        setDrag,
        columnSettings: availableColumns,
        handleOpenFile,
        handleOpenFolder,
        getFilesByFolderIdWithFilter,
        openShowRecipientsDialog,
        hideShowRecipientsDialog,
        recipientsViewDialog,
        handleDownload,
        isAbleToDrop: !addDocumentDialog && !uploadFileDialog && _selectedF !== null && _path.length > 0 && _path[0].name !== TRASH_FOLDER_NAME && _path[0].name !== GALLERY_FOLDER_NAME,
        eraseFilesHandler,

        scroll: {
            hasMore: pageInfo.total > ((pageInfo.page + 1) *pageInfo.count),
            bottomLoader,
            onScroll,
            scrollRef,
            isLoading: _isLoading,
            total: !files.length ? 'loading' : pageInfo.total,
        },
        doc: {
            selectedNewDocument: JSON.parse(JSON.stringify(_selectedDoc)),
            currentEmail: user?.email ?? '',
            snack,
            loadings: _isLoading,
            actions: {
                handleCloseEditor: () => {
                    dispatch(deselectDocumentDocuments())
                    dispatch(deselectDocumentVica())
                },
                handleReturnToDraft: () => {
                    dispatch(handleReturnToDraftAction())
                },
                onResendInviteApprover: (step: DocumentExecutorStepModel, group: DocumentExecutorGroupModel) => {
                    docId && dispatch(RestartSignStageByStageIdAndGroupId({documentId: docId, stageId: step.id, groupId: group.id}));
                },
                onResendInviteRecipient: (step: DocumentExecutorStepModel, recipient: DocumentExecutorRecipientModel) => {
                    docId && dispatch(RestartSignStageByStageIdAndRecipient({documentId: docId, stageId: step.id, recipientEmail: recipient.email}));
                },
                handleSkip: (step: DocumentExecutorStepModel) => {
                    docId && dispatch(SendToNextStepByStageId({documentId: docId, stageId: step.id}));
                },
                handleInvalidate: () => {
                    if(_selectedDoc && _selectedDoc.document){
                        docId && dispatch(InvalidateDocument(docId));
                    }
                },
                handleSave: (data: TEditorElementData<{}>[], variables: NewDocDataVariableModel[], docTitle: string, actors: TEditorActorsConfigFromDB) => {
                    if(_selectedDoc && _selectedDoc.document) {
                        dispatch(UpdateFullDocument({
                            documentId: _selectedDoc.document.documentId ?? '',
                            name: docTitle,
                            data: {
                                editor: data,
                                variables,
                                pdfFileId: _selectedDoc.document.data?.pdfFileId || null,
                            },
                            recipients: actors.recipients,
                            approvers: actors.approvers,
                            editors: actors.editors
                        }))
                    }
                },
                handleSend: (data: TEditorElementData<{}>[], variables: NewDocDataVariableModel[], docTitle: string, actors: TEditorActorsConfigFromDB) => {
                    if(_selectedDoc && _selectedDoc.document) {
                        dispatch(StartNewDocumentExecution({
                            documentId: _selectedDoc.document.documentId ?? '',
                            name: docTitle,
                            data: {
                                editor: data,
                                variables,
                                pdfFileId: _selectedDoc.document.data?.pdfFileId || null,
                            },
                            recipients: actors.recipients,
                            approvers: actors.approvers,
                            editors: actors.editors
                        }))
                    }
                },
                handlePreviewBeforeSend: (data: TEditorElementData<{}>[], variables: NewDocDataVariableModel[], docTitle: string, actors: TEditorActorsConfigFromDB) => {
                    if(_selectedDoc && _selectedDoc.document) {
                        dispatch(handlePreviewBeforeSendAction({
                            documentId: _selectedDoc.document.documentId ?? '',
                            name: docTitle,
                            data: {
                                editor: data,
                                variables,
                                pdfFileId: _selectedDoc.document.data?.pdfFileId || null,
                            },
                            recipients: actors.recipients,
                            approvers: actors.approvers,
                            editors: actors.editors
                        }))
                    }
                },
                onChangeEta: (stepId: string, timeInMs: number) => {
                    if (_selectedDoc && _selectedDoc.document) {
                        dispatch(changeDocumentStageEtaAction({documentId: _selectedDoc.document.documentId ?? '', stageId: stepId, timeInMs}))
                    }
                },
                onSaveToEvidence: () => {
                    if (_selectedDoc?.document?.documentId) {
                        dispatch(setAsEvidenceDialogAction({documentId: _selectedDoc.document.documentId}));
                    }
                }
            },
        }
    }
}
