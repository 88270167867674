import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

import {Box, Tab, Tabs} from "@mui/material";
import {TabContext} from "@mui/lab";

import {Flex} from "../../../../../newShared/components/Layouts";
import {ExactVendorTabs} from "../../types";

import {ExactVendorInformation} from "../../components/exactVendorInformation"
import {ExactVendorEvaluation} from "../../components/exactVendorEvaluation";
import {useExactVendor} from "../../hooks/useExactVendor";
import React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {DeleteVendor} from "../../components/dialogs/deleteVendor";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";

export const ExactVendor = () => {
    const {
        selectedVendor,
        tab,
        setTab,
        handleGoBack,
        isLoading,
        handleOpenDeleteDialog
    } = useExactVendor();

    const {t} = useMainTranslation('',{keyPrefix:'pathVendors.ExactVendor'});

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={selectedVendor?.name}
                handleGoBack={handleGoBack}
                actions={[
                    {
                        variant: 'text',
                        title: t('Delete'),
                        onClick: handleOpenDeleteDialog,
                        icon: <DeleteForeverIcon />,
                        disabled: isLoading || !selectedVendor,
                    }
                ]}
            />


                <TabContext value={tab}>
                    <Box sx={{ margin: '30px 0 0 0' }}>
                        <Tabs variant={'scrollable'} value={tab} onChange={(e, newValue: ExactVendorTabs) => setTab(newValue)} aria-label="basic tabs example">
                            <Tab disabled={!selectedVendor} label={t("Information")} value={'Information'}/>
                            <Tab disabled label={t("Documents")} value={'Documents'} />
                            <Tab disabled={!selectedVendor} label={t("Evaluation")} value={'Evaluation'}/>
                        </Tabs>
                    </Box>
                    
                    {/*INFORMATION*/}
                   <ExactVendorInformation/>

                    {/*Documents*/}

                    {/*EVALUATIONS*/}
                    <ExactVendorEvaluation/>

                </TabContext>

            <DeleteVendor />
        </Flex>
    )
}