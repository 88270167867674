import {Dialog, List, ListItem, ListItemText} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {doc, hideDoc, selectedAutomation, setDocType} from "../../../store/slice";
import {Typo} from "../../../../../../newShared/components/Typography";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

const values = [
    'document',
    'file',
    // 'link'
];

export const DocDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'automations'});
    const dispatch = useDispatch();
    const auto = useSelector(selectedAutomation);
    const {isOpen} = useSelector(doc);

    const handleClose = () => {
        dispatch(hideDoc());
    };

    const handleSelect = (value: string) => {
        if(auto?.documents.type === value) {
            handleClose();
            return;
        }
        dispatch(setDocType(value));
    }

    return (auto &&
        <Dialog onClose={handleClose} open={isOpen} maxWidth={'xl'}>
            <Flex ai={'center'} jc={'center'} p={'30px 50px 15px'}>
                <Typo fontWeight={600} fontSize={'18px'}>{t('Select document type')}</Typo>
            </Flex>
            {auto.documents.links.length > 0 &&
                <Flex direction={'column'} ai={'center'} jc={'space-evenly'} w={'100%'} p={'5px 20px'}>
                    <Typo fontWeight={600} fontSize={'16px'}>{t('NOTE')}</Typo>
                    <Typo fontWeight={300} fontSize={'14px'}>{t('All files/links or document already connected will be deleted')}</Typo>
                </Flex>
            }
            <List sx={{ pt: 0 }}>
                {values.map((val) => (
                    <ListItem selected={val === auto?.documents.type} button onClick={() => {
                        handleSelect(val);
                    }} key={val}>
                        <ListItemText primary={t(`documentTypes.${val}`)} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}