import {Box, Dialog, styled, Typography} from "@mui/material";
import colors from "../../../theme/colors";

export const CommonDialog = styled(Dialog)(() => ({
    '.MuiPaper-root': {
        minWidth: '678px',
        height: '600px',
        // minHeight: '400px',
    },
}));

export const ColumnRowBox = styled(Box, {
    shouldForwardProp: (propName) => (
        !["isDragging"].includes(propName.toString())
    )})(({isDragging}:{isDragging?: true}) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    opacity: isDragging ? 0 : 1,
    border: `1px solid ${colors.stroke.grey}`,
    borderRadius: '4px',
    marginBottom: '10px',
    background: colors.backgrounds.white,
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    overflow: 'hidden',
    padding: '0 16px',
    flexShrink: 0,
}));

export const FieldsContainer = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto'
}));

export const SubTitle = styled(Typography)(() => ({
    color: colors.text.grey,
    marginBottom: '16px'
}));
SubTitle.defaultProps = {
    variant: 'body2',
}
