import {downloadFileApi, uploadFileApi} from "./api";
import {
    defaultMaxImageSize,
    tinyMceEditorImgBase64PrefixDataAttribute,
    tinyMceEditorImgFileIdDataAttribute
} from "./constants";
import {Editor} from "@tinymce/tinymce-react";
import {spinnerSvg} from "./components/spinner";
import colors from "../../theme/colors";


export const uploadFile = async (file: string, signal?: AbortSignal): Promise<{fileId: string}> => {
    const {message: fileId} = await uploadFileApi({file}, signal);

    return {fileId};
}
export const getBase64 = async (fileId: string): Promise<{base64: string}> => {
    const {file: base64} = await downloadFileApi({id: fileId});

    return {base64};
}

export const getDataForSave = (innerHtml: string): string => {
    const doc = new DOMParser().parseFromString(innerHtml || '', 'text/html');
    doc.querySelectorAll('img').forEach(img => {
        img.removeAttribute('src');
    });
    return doc.body.innerHTML || '';
};

export const getDataForSaveFromEditor = (editor: Editor["editor"]): string => {
    return getDataForSave(editor?.getContent() || '')
};

export const uploadImageToTinyMce = (editor: Editor, file: {fileId: string, name: string, base64: string, file: File}) => {
    const img = new Image();
    img.src = file.base64;
    img.setAttribute(tinyMceEditorImgFileIdDataAttribute, file.fileId);
    img.setAttribute(tinyMceEditorImgBase64PrefixDataAttribute, file.base64.split(',')[0]);
    img.alt = file.name;

    img.onload = (e) => {
        const ratio = img.height / img.width;
        const maxSize = Math.max(img.width, img.height);

        if (maxSize === img.width && maxSize > defaultMaxImageSize) {
            img.width = defaultMaxImageSize;
            img.height = defaultMaxImageSize * ratio;
        }
        else if (maxSize === img.height && maxSize > defaultMaxImageSize) {
            img.height = defaultMaxImageSize;
            img.width = defaultMaxImageSize / ratio;
        }

        editor.editor?.insertContent(img.outerHTML);
    }
};

export const initializeContent = async (editor?: Editor["editor"], initialContent?: string): Promise<boolean> => {
    const content = initialContent || '';
    if (!editor) return false;

    try {
        // set loaders in images
        {
            const doc = new DOMParser().parseFromString(content, 'text/html');
            const images = doc.querySelectorAll(`img[${tinyMceEditorImgFileIdDataAttribute}]`) as NodeListOf<HTMLImageElement>;

            // if all images have src, then we can set content immediately
            if (Array.from(images).every(e => e.src)) {
                editor.setContent(doc.body.innerHTML);
                return true;
            }

            // otherwise, set loaders for images without src
            for (const img of images) {
                if (img.src) continue;
                const base64EncodedSvg = btoa(spinnerSvg(48 / Math.min(img.width, img.height)));
                img.src = `data:image/svg+xml;base64,${base64EncodedSvg}`;
                img.style.border = `1px dashed ${colors.stroke.grey}`;
            }

            editor.setContent(doc.body.innerHTML);
        }

        // set base64 in images
        {
            const doc = new DOMParser().parseFromString(content, 'text/html');
            const images = doc.querySelectorAll(`img[${tinyMceEditorImgFileIdDataAttribute}]`) as NodeListOf<HTMLImageElement>;
            for (const img of images) {
                if (img.src) continue;
                const fileId = img.getAttribute(tinyMceEditorImgFileIdDataAttribute)!;
                const { base64 } = await getBase64(fileId);
                const base64Prefix = img.getAttribute(tinyMceEditorImgBase64PrefixDataAttribute);
                img.src = `${base64Prefix},${base64}`;
            }

            editor.setContent(doc.body.innerHTML);
        }
    } catch (e) {
        console.error('tiny', e);
        return false;
    }

    return true;
};
