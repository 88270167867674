import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React, {FC} from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import colors from "../../../../../../newShared/theme/colors";
import {useTrainingsDialogs} from "../../../hooks/useTrainingsDialogs";
import {assignmentsSelector, loadings} from "../../../store/slice";
import {useDispatch, useSelector} from "react-redux";
import {LoadingButton} from "@mui/lab";
import {mainTrainingsCancelTrainingAssignmentAction} from "../../../store/actions";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";


export const CancelAssignmentDialog: FC = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.dialogs'});
    const { isOpen, handleClose} = useTrainingsDialogs().cancelAssignment;
    const {selectedAssignment: selected} = useSelector(assignmentsSelector);
    const {assignmentCancel: isLoading} = useSelector(loadings);
    const dispatch = useDispatch();
    const {setMessage} = useMessageDialog();

    // const validationSchema = yup.object({
    //     reason: yup.string()
    //         .nullable()
    //         .required(t('Comment field is required'))
    //         .max(1000, t('Comment should be less than 1000 symbols'))
    // });

    // const formik = useCustomFormik(isOpen, {
    //     initialValues: {reason: ''},
    //     validationSchema,
    //     onSubmit: (values, formikHelpers) => {
    //         selected && dispatch(mainTrainingsCancelTrainingAssignmentAction({data: {assignmentId: selected.id, reason: values.reason}}))
    //     },
    //     isInitialValid: false
    // })

    const handleSubmit = () => {
        selected && dispatch(mainTrainingsCancelTrainingAssignmentAction({
            data: {assignmentId: selected.id, reason: ''},
            onSuccess: (request, response, addictiveData) => {
                setMessage({title: 'Completed successfully', message: t('Assignment id {{assignmentId}} was cancelled', {assignmentId: request.assignmentId})});
            },
            onError: (request, error) => {
                const e409 = error.e409?.[0];
                const e404 = error.e404?.[0];

                if (e409?.type === 'STATUS_CHANGED') {
                    setMessage({title: 'Action conflict error', message: t('Action not applicable! Assignment status was changed')});
                }
                
                if (e404) {
                    setMessage({title: t('Error'), message: e404});
                }
            }
        }))
    }

    // useEffect(() => {
    //     isOpen && formik.resetForm();
    //
    //     //eslint-disable-next-line
    // }, [isOpen]);



    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Cancel assignment')}</DialogTitle>
            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '12px'}} >
                <Typography variant={'*bodyText2'} color={colors.text.grey_dark}>{t('Are you sure you want to cancel assignment?')}</Typography>
            </DialogContent>

            <DialogActions sx={{gap: '12px'}}>
                <Button size={'small'} variant={'text'} onClick={handleClose}>{tCommon('Cancel')}</Button>
                <LoadingButton size={'small'} variant={'contained'} onClick={handleSubmit} loading={isLoading} disabled={!selected}>{t('Cancel assignment')}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}




