import {
    insertClientDataByTemplateRequestType,
    KycDictionaryType,
    KycExactDictType,
    targetHistoryCreateDeleteExceptionValue,
    targetHistoryUpdateActionValue,
    TKycTargetType,
    TKycTemplateField,
    TKycTemplateFieldFillable,
    TKycTemplateFieldType,
    TScreeningStatus
} from "./types";
import {
    ARCHIVE_CLIENTS_PAGE_TYPE,
    COMPANY_ID_FIELD_ALIAS_NAME,
    COMPANY_NAME_FIELD_ALIAS_NAME,
    CORPORATE_CLIENTS_PAGE_TYPE,
    CORPORATE_TARGET_TYPE,
    CRYPTO_WALLET_PUBLIC_KEY_ALIAS_NAME,
    CRYPTOWALLET_CLIENTS_PAGE_TYPE,
    CRYPTOWALLET_TARGET_TYPE,
    EMAIL_FIELD_ALIAS_NAME,
    FILE_FIELD_TYPE,
    FIRST_NAME_FIELD_ALIAS_NAME,
    INDIVIDUAL_CLIENTS_PAGE_TYPE,
    LAST_NAME_FIELD_ALIAS_NAME,
    PASSPORT_NUMBER_FIELD_ALIAS_NAME,
    PERSON_TARGET_TYPE,
    PHONE_FIELD_ALIAS_NAME,
    SAVE_COLUMNS_ARCHIVE_CONFIG_PREFIX,
    SAVE_COLUMNS_CORPORATE_CONFIG_PREFIX,
    SAVE_COLUMNS_CRYPTOWALLET_CONFIG_PREFIX,
    SAVE_COLUMNS_INDIVIDUAL_CONFIG_PREFIX,
    SYSTEM_FIELDS_CORPORATE_SEQ,
    SYSTEM_FIELDS_INDIVIDUAL_SEQ,
    TARGET_BLACKLIST_STATUS,
    TARGET_CREATED_STATUS,
    TARGET_HIGH_RISK,
    TARGET_LOW_RISK,
    TARGET_MATCHED_STATUS,
    TARGET_MEDIUM_RISK,
    TARGET_MODIFIED_STATUS,
    TARGET_VERIFIED_STATUS
} from "./constants";
import {
    KycArchivedTargetModel,
    KycRefactorTemplateModel,
    KycTargetFieldModel,
    SystemFieldModel,
    TKycTargetModel,
    TKycTemplateFieldFillableModel,
} from "../../../newShared/GQLTypes";
import colors from "../../../newShared/theme/colors";
import React from "react";
import {TypographyBody2} from "../../../newShared/components/Inputs/styled";
import {RiskCircle} from "./components/clientsTableRow/style";
import {FlexBox} from "../../../newShared/components/editorUnion/components/dialogs/manageActorsDialog/styled";
import moment from 'moment';
import {parseDateAuto} from "../../../newShared/utils/dateTools";

export {}

export const getOrderBySystemField = (field: TKycTemplateField | KycTargetFieldModel | SystemFieldModel, isPerson: boolean): number => {
    // console.log(`getOrderBySystemField`, field);
    if(field.type === FILE_FIELD_TYPE){
        if(field.systemAlias !== null && field.systemAlias !== undefined){
            // console.log(`1getOrderBySystemField`, field, (isPerson ? SYSTEM_FIELDS_INDIVIDUAL_SEQ : SYSTEM_FIELDS_CORPORATE_SEQ).indexOf(field.systemAlias) + 10000);
            return (isPerson ? SYSTEM_FIELDS_INDIVIDUAL_SEQ : SYSTEM_FIELDS_CORPORATE_SEQ).indexOf(field.systemAlias) + 10000;
        }
    }else{
        // console.log(`getOrderBySystemField field is not FILE`, field);
        if(field.systemAlias !== null && field.systemAlias !== undefined){
            // console.log(`2getOrderBySystemField`, field, (isPerson ? SYSTEM_FIELDS_INDIVIDUAL_SEQ : SYSTEM_FIELDS_CORPORATE_SEQ), (isPerson ? SYSTEM_FIELDS_INDIVIDUAL_SEQ : SYSTEM_FIELDS_CORPORATE_SEQ).indexOf(field.systemAlias));
            return (isPerson ? SYSTEM_FIELDS_INDIVIDUAL_SEQ : SYSTEM_FIELDS_CORPORATE_SEQ).indexOf(field.systemAlias);
        }
    }
    return -1;
}

export const setOrderForFields = (fields: TKycTemplateField[], systemFieldsTotal: number, isPerson: boolean): TKycTemplateField[] => {
    return fields.map((e, id) => {
        // console.log(`setOrderForFields`, e);
        if(e.system){
            return {...e, order: getOrderBySystemField(e, isPerson)};
        }else{
            return {...e, order: systemFieldsTotal + id + (e.type === FILE_FIELD_TYPE ? 10000 : 0)}
        }
    });
}

export const convertTemplateToFillableTemplate = (template: KycRefactorTemplateModel): insertClientDataByTemplateRequestType => {
    return {
        ...template,
        fields: template.fields.map(e => ({...e, value: e.type === 'DATE' ? null : '', type: e.type as TKycTemplateFieldType, fileName: null, systemAlias: e.systemAlias})),
        type: template.type as TKycTargetType
    }
}

export const getNormalizedTemplateTypeName = (type: string): string => {
    switch (type){
        case PERSON_TARGET_TYPE: return 'Individual';
        case CORPORATE_TARGET_TYPE: return 'Corporate';
        case CRYPTOWALLET_TARGET_TYPE: return 'CryptoWallet';
        default: return type;
    }
}

export const getNormalizedStatusName = (type: string): string => {
    switch (type){
        case 'IN_PROGRESS': return 'In progress';
        case 'COMPLETE': return 'Completed';
        case 'CANCELED': return 'Canceled';
        case 'COMPLETED': return 'Completed';
        case 'REJECTED_BY_USER': return 'Rejected by user';
        case 'REJECTED': return 'Rejected';
        default: return type;
    }
}

export const getScreeningNormalizedStatusName = (type: string): string => {
    switch (type){
        case 'IN_PROGRESS': return 'In progress';
        case 'COMPLETE': return 'Completed';
        case 'FAILED': return 'Failed';
        case 'PENDING': return 'Pending';
        default: return type;
    }
}

export const getNormalizedClientStatusName = (type: string): string => {
    switch (type){
        case TARGET_CREATED_STATUS: return 'Created';
        case TARGET_BLACKLIST_STATUS: return 'Black list';
        case TARGET_VERIFIED_STATUS: return 'Verified';
        case TARGET_MODIFIED_STATUS: return 'Modified';
        case TARGET_MATCHED_STATUS: return 'Matched';
        default: return type;
    }
}

export const getNormalizedClientRiskName = (risk: string | null): string => {
    switch (risk){
        case TARGET_LOW_RISK: return 'Low';
        case TARGET_MEDIUM_RISK: return 'Medium';
        case TARGET_HIGH_RISK: return 'High';
        default: return 'None';
    }
}


export const getIndividualKey = (workspaceId: string, publicId: string): string => `${SAVE_COLUMNS_INDIVIDUAL_CONFIG_PREFIX}${workspaceId}#${publicId}`;
export const getCorporateKey = (workspaceId: string, publicId: string): string => `${SAVE_COLUMNS_CORPORATE_CONFIG_PREFIX}${workspaceId}#${publicId}`;
export const getArchiveKey = (workspaceId: string, publicId: string): string => `${SAVE_COLUMNS_ARCHIVE_CONFIG_PREFIX}${workspaceId}#${publicId}`;
export const getCryptowalletKey = (workspaceId: string, publicId: string): string => `${SAVE_COLUMNS_CRYPTOWALLET_CONFIG_PREFIX}${workspaceId}#${publicId}`;

export const getKycIndividualClientsColumnsSettingsLocalStorage = (publicId: string, workspaceId: string, fields: KycTargetFieldModel[]): KycTargetFieldModel[] => {
    const storage = localStorage.getItem(getIndividualKey(workspaceId, publicId));
    return storage ? JSON.parse(storage) as KycTargetFieldModel[] : getDefaultClientsColumns(fields, PERSON_TARGET_TYPE);
}

export const setKycIndividualClientsColumnsSettingsLocalStorage = (publicId: string, workspaceId: string, columns: KycTargetFieldModel[]): void => {
    localStorage.setItem(getIndividualKey(workspaceId, publicId), JSON.stringify(columns));
}

export const getKycCorporateClientsColumnsSettingsLocalStorage = (publicId: string, workspaceId: string, fields: KycTargetFieldModel[]): KycTargetFieldModel[] => {
    const storage = localStorage.getItem(getCorporateKey(workspaceId, publicId));
    return storage ? JSON.parse(storage) as KycTargetFieldModel[] : getDefaultClientsColumns(fields , CORPORATE_TARGET_TYPE);
}

export const setKycCorporateClientsColumnsSettingsLocalStorage = (publicId: string, workspaceId: string, columns: KycTargetFieldModel[]): void => {
    localStorage.setItem(getCorporateKey(workspaceId, publicId), JSON.stringify(columns));
}

export const getKycCryptowalletsClientsColumnsSettingsLocalStorage = (publicId: string, workspaceId: string, fields: KycTargetFieldModel[]): KycTargetFieldModel[] => {
    const storage = localStorage.getItem(getCryptowalletKey(workspaceId, publicId));
    return storage ? JSON.parse(storage) as KycTargetFieldModel[] : getDefaultClientsColumns(fields, PERSON_TARGET_TYPE);
}

export const setKycCryptowalletsClientsColumnsSettingsLocalStorage = (publicId: string, workspaceId: string, columns: KycTargetFieldModel[]): void => {
    localStorage.setItem(getCryptowalletKey(workspaceId, publicId), JSON.stringify(columns));
}

// export const getKycArchiveClientsColumnsSettingsLocalStorage = (publicId: string, workspaceId: string, fields: KycTargetFieldModel[]): KycTargetFieldModel[] => {
//     const storage = localStorage.getItem(getArchiveKey(workspaceId, publicId));
//     return storage ? JSON.parse(storage) as KycTargetFieldModel[] : getDefaultClientsColumns(fields, );
// }

export const setKycArchiveClientsColumnsSettingsLocalStorage = (publicId: string, workspaceId: string, columns: KycTargetFieldModel[]): void => {
    localStorage.setItem(getArchiveKey(workspaceId, publicId), JSON.stringify(columns));
}

export const getDefaultClientsColumns = (fields: KycTargetFieldModel[], targetType: string): KycTargetFieldModel[] => {
    if (targetType === PERSON_TARGET_TYPE)
        return fields?.filter(e => e.systemAlias === 'name' || e.systemAlias === 'firstName' || e.systemAlias === 'lastName' ||  e.systemAlias === 'templateName' || e.systemAlias === 'lastModified' || e.systemAlias === 'status' || e.systemAlias === 'risk') ?? [];

    else if (targetType === CORPORATE_TARGET_TYPE)
        return fields?.filter(e => e.systemAlias === 'name' ||  e.systemAlias === 'templateName' || e.systemAlias === 'lastModified'  ||  e.systemAlias === 'status' || e.systemAlias === 'risk') ?? [];

    else if (targetType === CRYPTOWALLET_TARGET_TYPE)
        return fields?.filter(e => e.systemAlias === CRYPTO_WALLET_PUBLIC_KEY_ALIAS_NAME || e.systemAlias === 'lastModified'  ||  e.systemAlias === 'status' || e.systemAlias === 'risk') ?? [];

    else return [];
}

export const getStatusTitleAndColor = (status?: string) => {
    switch(status){
        case 'CREATED': return {title: 'Created', background: colors.decorative.background_1, color: colors.decorative.violet};
        case 'MATCHED': return {title: 'Matched', background: colors.decorative.background_2, color: colors.decorative.bright_red};
        case 'VERIFIED': return {title: 'Verified', background: colors.decorative.background_3, color: colors.secondary.green_dark};
        case 'MODIFIED': return {title: 'Modified', background: colors.decorative.background_1, color: colors.decorative.violet};
        case 'BLACK_LIST': return {title: 'Black list', background: colors.decorative.background_4, color: colors.text.dark};
        default: return {title: 'N/A', background: colors.decorative.background_1, color: colors.decorative.violet};
    }
}

export const getTargetRisk = (risk: string) => {
    switch(risk){
        case 'NONE': return <FlexBox gap={'10px'}><RiskCircle color={colors.text.grey_dark}/><TypographyBody2 sx={{color: colors.text.grey_dark}}>{'None'}</TypographyBody2></FlexBox>;
        case 'LOW': return <FlexBox gap={'10px'}><RiskCircle color={colors.text.success}/><TypographyBody2 sx={{color: colors.text.success}}>{'Low'}</TypographyBody2></FlexBox>;
        case 'HIGH': return <FlexBox gap={'10px'}><RiskCircle color={colors.text.critical}/><TypographyBody2 sx={{color: colors.text.critical}}>{'High'}</TypographyBody2></FlexBox>;
        case 'MEDIUM': return <FlexBox gap={'10px'}><RiskCircle color={colors.text.warning}/><TypographyBody2 sx={{color: colors.text.warning}}>{'Medium'}</TypographyBody2></FlexBox>;
        default: return <FlexBox gap={'10px'}><RiskCircle color={colors.text.grey_dark}/><TypographyBody2 sx={{color: colors.text.grey_dark}}>{'None'}</TypographyBody2></FlexBox>;
    }
}

export const getNormalizedTargetType = (type: string) => {
    switch(type){
        case PERSON_TARGET_TYPE: return 'Individual';
        case CORPORATE_TARGET_TYPE: return 'Corporate';
        case CRYPTOWALLET_TARGET_TYPE: return 'Crypto wallet';
        default: return '-';
    }
}

export const getTargetName = (target: TKycTargetModel | KycArchivedTargetModel): string => {
    // console.log(`target`, target);
    if(!target) return 'N/A Client';
    if(target?.targetType === PERSON_TARGET_TYPE){
        const fullName = target.fields?.find(e => e.systemAlias === COMPANY_NAME_FIELD_ALIAS_NAME)?.value;
        if(fullName) return fullName;
        const firstName = target.fields?.find(e => e.systemAlias === FIRST_NAME_FIELD_ALIAS_NAME)?.value;
        const lastName = target.fields?.find(e => e.systemAlias === LAST_NAME_FIELD_ALIAS_NAME)?.value;
        if(firstName && lastName){
            return `${firstName} ${lastName}`
        }else{
            const passportNumber = target.fields?.find(e => e.systemAlias === PASSPORT_NUMBER_FIELD_ALIAS_NAME)?.value;
            if(passportNumber) return passportNumber;
            const emailField = target.fields?.find(e => e.systemAlias === EMAIL_FIELD_ALIAS_NAME)?.value;
            if(emailField) return emailField;
            const phoneField = target.fields?.find(e => e.systemAlias === PHONE_FIELD_ALIAS_NAME)?.value;
            if(phoneField) {
                return phoneField;
            }else{
                return target.id;
            }
        }
    }
    if(target.targetType === CORPORATE_TARGET_TYPE){
        const companyName = target.fields?.find(e => e.systemAlias === COMPANY_NAME_FIELD_ALIAS_NAME)?.value;
        if(companyName) return companyName;

        const companyId = target.fields?.find(e => e.systemAlias === COMPANY_ID_FIELD_ALIAS_NAME)?.value;
        if(companyId) return companyId;

        const emailField = target.fields?.find(e => e.systemAlias === EMAIL_FIELD_ALIAS_NAME)?.value;
        if(emailField) return emailField;
        const phoneField = target.fields?.find(e => e.systemAlias === PHONE_FIELD_ALIAS_NAME)?.value;
        if(phoneField) {
            return phoneField;
        }else{
            return target.id;
        }
    }
    if(target.targetType === CRYPTOWALLET_TARGET_TYPE){
        const publicKey = target.fields?.find(e => e.systemAlias === CRYPTO_WALLET_PUBLIC_KEY_ALIAS_NAME)?.value;
        return publicKey ?? target.id;
    }

    return target.id;
}

export const getTargetTypeForPath = (target?: TKycTargetModel | null, byTemplateType?: string): {pathName: string, breadcrumbName: string} => {
    // console.log(`getTargetTypeForPath`, target, byTemplateType);
    if(!target) return {pathName: INDIVIDUAL_CLIENTS_PAGE_TYPE, breadcrumbName: 'Individual clients'};
    const switchBy = !target ? byTemplateType : target.targetType;
    switch (switchBy){
        case PERSON_TARGET_TYPE: return {pathName: INDIVIDUAL_CLIENTS_PAGE_TYPE, breadcrumbName: 'Individual clients'};
        case CORPORATE_TARGET_TYPE: return {pathName: CORPORATE_CLIENTS_PAGE_TYPE, breadcrumbName: 'Corporate clients'};
        case CRYPTOWALLET_TARGET_TYPE: return {pathName: CRYPTOWALLET_CLIENTS_PAGE_TYPE, breadcrumbName: 'Crypto wallets'}
    }
    if(target.archived){
        return {pathName: ARCHIVE_CLIENTS_PAGE_TYPE, breadcrumbName: 'Clients archive'};
    }
    return {pathName: INDIVIDUAL_CLIENTS_PAGE_TYPE, breadcrumbName: 'Individual clients'};
}

export const getScreeningStatus = (status: TScreeningStatus) => {
    switch(status){
        // case 'IN_PROGRESS': return {title: 'In progress', background: colors.decorative.background_1, color: colors.decorative.violet};
        // case 'FAILED': return {title: 'Failed', background: colors.decorative.background_2, color: colors.text.red};
        // case 'COMPLETE': return {title: 'Complete', background: colors.decorative.background_3, color: colors.secondary.green_dark};
        // case 'PENDING': return {title: 'Pending', background: colors.backgrounds.orange, color: colors.decorative.orange};
        case 'IN_PROGRESS': return <TypographyBody2 sx={{color: colors.text.blue}}>{'In progress'}</TypographyBody2>;
        case 'FAILED': return <TypographyBody2 sx={{color: colors.text.critical}}>{'Failed'}</TypographyBody2>;
        case 'COMPLETE': return <TypographyBody2 sx={{color: colors.decorative.additional_2}}>{'Complete'}</TypographyBody2>;
        case 'PENDING': return <TypographyBody2 sx={{color: colors.decorative.orange}}>{'Pending'}</TypographyBody2>;

        default: return <TypographyBody2 sx={{color: colors.decorative.violet}}>{'N/A'}</TypographyBody2>;;
    }
}


export const getCsvResultData = (result?: string | null): {completed: number, failed: number, skipped: number, failedAtLine: number} => {
    let res = {completed: 0, failed: 0, skipped: 0, failedAtLine: -1};
    if(!result || result.length === 0) return res;
    const comaSplitted = result.split(', ');
    comaSplitted.forEach(e => {
        const splitted = e.split(': ');
        if(splitted[0] === 'completed'){
            res.completed = parseInt(splitted[1]);
        }
        if(splitted[0] === 'failed'){
            res.failed = parseInt(splitted[1]);
        }
        if(splitted[0] === 'skipped'){
            res.skipped = parseInt(splitted[1]);
        }
    })

    return {...res, failedAtLine: res.failed > 0 ? res.completed + res.skipped + 1 : -1};
}

export const removeTimeZoneDateFields = (fields: TKycTemplateFieldFillable[] | KycTargetFieldModel[]): TKycTemplateFieldFillable[] => {
    let res: TKycTemplateFieldFillable[] = [];
    fields.forEach(e => {
        if(e.type === 'DATE' && e.value){
            const momentDate = moment(e.value).format();
            // console.log(`removeTimeZoneDateFields momentDate`, momentDate)
            res.push({...e, value: momentDate, fileName: e.fileName ?? null});
        }else{
            res.push({...e, fileName: e.fileName ?? null, value: e.value ?? null});
        }
    })
    return res;
}

export const getTargetHistoryNormalizedAction = (action: string) => {
    switch (action){
        case 'CREATE': return 'Client created';
        case 'UPDATE': return 'Client updated';
        case 'MOVE_TO_ARCHIVE': return 'Client moved to archive';
        case 'RETURN_FROM_ARCHIVE': return 'Client restored from archive';
        case 'MOVE_TO_BLACK_LIST': return 'Client moved to black list';
        case 'REMOVE_FROM_BLACK_LIST': return 'Client restored from black list';
        case 'UPDATE_EXCEPTION': return 'Resolution updated';
        case 'CREATE_EXCEPTION': return 'Resolution created';
        case 'DELETE_EXCEPTION': return 'Resolution deleted';
        case 'SET_VERIFIED': return 'Client verified';
        case 'VERIFIED': return 'Client verified';
        case 'UPDATE_RISK': return 'Client risk level updated';
        default: return action;
    }
}

export const getTargetHistoryValue = (action: string, value: string | null | undefined): string[] => {
    if(value){
        switch (action){
            case 'UPDATE': {
                try{
                    // console.log(`UPDATE value`, value)
                    const parsedValue: targetHistoryUpdateActionValue = JSON.parse(value) as targetHistoryUpdateActionValue;
                    if(parsedValue.updatedFields && parsedValue.updatedFields.length > 0){
                        return parsedValue.updatedFields.map(e => `Changed ${e.name} from ${e.type === 'DATE' ? parseDateAuto(e.prevValue, false, true) : e.prevValue} to ${e.type === 'DATE' ? parseDateAuto(e.nextValue, false, true) : e.nextValue}`);
                    }
                    return [value];
                }catch (ex){
                    return [value];
                }
            }
            case 'CREATE_EXCEPTION': {
                try{
                    const parsedValue: targetHistoryCreateDeleteExceptionValue = JSON.parse(value) as targetHistoryCreateDeleteExceptionValue;
                    return [`Resolution for ${parsedValue.entityCaption} with score ${parsedValue.score} and reason: ${parsedValue.reason ?? '-'} has been created.`]
                }catch (ex){
                    return [value];
                }
            }
            case 'DELETE_EXCEPTION': {
                try{
                    const parsedValue: targetHistoryCreateDeleteExceptionValue = JSON.parse(value) as targetHistoryCreateDeleteExceptionValue;
                    return [`Resolution for ${parsedValue.entityCaption} with score ${parsedValue.score} and reason: ${parsedValue.reason ?? '-'} has been deleted.`]
                }catch (ex){
                    return [value];
                }
            }
            default: return [value];
        }
    }else{
        return ['-'];
    }
}

export const getFieldValue = (field: TKycTemplateFieldFillable | KycTargetFieldModel | TKycTemplateFieldFillableModel, dictionaries: KycDictionaryType):string => {
    switch (field.type){
        case 'FILE': return field.fileName ?? '-';
        case 'DATE': return parseDateAuto(field.value, false, true);
        case 'SELECT': {
            if(field.dictionary && Object.keys(dictionaries).some(e => e === field.dictionary)){
                let key: keyof KycDictionaryType = field.dictionary as keyof KycDictionaryType;
                const currentDict: KycExactDictType[] = dictionaries[key];
                const dictObj = currentDict.find(e => e.key === field.value);
                // console.log(`dictObj`, dictObj)
                return dictObj?.value ?? field.value ?? '-';
            }else{
                return (field.value && field.value?.trim().length > 0) ? field.value : '-';
            }
        }
        default: {
            return (field.value && field.value?.trim().length > 0) ? field.value : '-';
        }
    }
}

export const getKycDictionary = (dictionaries: KycDictionaryType, key: string | null | undefined): KycExactDictType[] => {
    if(key && Object.keys(dictionaries).some(e => e === key)){
        let _key: keyof KycDictionaryType = key as keyof KycDictionaryType;
        const currentDict: KycExactDictType[] = dictionaries[_key];
        return currentDict ?? [];
    }else{
        return [];
    }
}

export const getBreadcrumbsClientType = (clientPage: string): string => {
    switch (clientPage){
        case INDIVIDUAL_CLIENTS_PAGE_TYPE: return 'Individual clients';
        case CORPORATE_CLIENTS_PAGE_TYPE: return 'Corporate clients';
        case CRYPTOWALLET_CLIENTS_PAGE_TYPE: return 'Crypto wallets';
        default: return clientPage;
    }
}

export const getTemplateTypeChipColorAndText = (type: string): {color: string, text: string} => {
    switch (type){
        case PERSON_TARGET_TYPE: return {color: colors.decorative.additional_2, text: 'Individual'};
        case CORPORATE_TARGET_TYPE: return {color: colors.decorative.violet, text: 'Corporate'};
        case CRYPTOWALLET_TARGET_TYPE: return {color: colors.decorative.brown, text: 'Crypto wallet'};
        default: return {color: colors.decorative.additional_2, text: 'Individual'};
    }
}

export const getScreeningPageEntitiesName = (type?: string): string => {
    switch (type){
        case PERSON_TARGET_TYPE: return 'Person matched';
        case CORPORATE_TARGET_TYPE: return 'Organization matched';
        case CRYPTOWALLET_TARGET_TYPE: return 'Crypto wallet matched';
        default: return 'Person matched';
    }
}
