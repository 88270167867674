import {useSelector} from "react-redux";
import {
    deselectedRisk,
    loadings,
    openAddActionOwner,
    openDeleteRisk,
    openEditRisk,
    openSaveRisk,
    risksQuantitative,
    selectedRegistry,
    setRiskEdited,
    toggleRisksQuantitative
} from "../../store/slice";
import {tControl, TRisk, TRiskModel, TRiskModelProbability} from "../../types";
import {
    getColorByRiskRate,
    getInitialControlValue,
    getInitialGradeImpactValue,
    getInitialGradeProbabilityValue,
    getRiskRate
} from "../../helpers";
import React, {useEffect, useState} from "react";

import {updateRisksByRegistryIdAction} from "../../store/actions";
import {firstNoneObject} from "../../constants";
import {useAppDispatch} from "../../../../../newShared/redux";


export const useExactRegistryRightSide = ({risk, model}: {risk: TRisk | null, model?: TRiskModel}) => {
    const dispatch = useAppDispatch();
    const [currRisk, setCurrRisk] = useState<TRisk | null>(risk);
    const {modelsList, riskEdit} = useSelector(loadings);
    const reg = useSelector(selectedRegistry);
    const isQuantitative = useSelector(risksQuantitative);
    // const _pageInfo = useSelector(registryRisksPageInfo);
    //actions
    const handleClose = () => {
        if(isEditMode){
            dispatch(openSaveRisk(null)); //if risk in rightSide was changed -> opening save dialog -> then after save/discard closing right side
        }else{
            dispatch(deselectedRisk()); //risk was not updated -> just closing right side
        }
    }
    const handleEditRisk = () => dispatch(openEditRisk());
    const handleDeleteRisk = () => risk && dispatch(openDeleteRisk(risk));
    const handleChangeRisksQuantitative = () => dispatch(toggleRisksQuantitative());

    useEffect(() => {
        risk && setCurrRisk(risk);
        // console.log('useExactRegistryRightSide: useEffect[risk]');
    }, [risk]);

    const handleSetInitialGradeProbability = (prob: TRiskModelProbability | null) => {
        if(!prob) return;
        if(prob.quantitative === firstNoneObject.quantitative){
            //default none object - setting null
            currRisk && setCurrRisk(prev => {
                return {...prev!, initialGrade: {...currRisk!.initialGrade, probability: null}};
            });
        }else{
            currRisk && setCurrRisk(prev => {
                return {...prev!, initialGrade: {...currRisk!.initialGrade, probability: prob.quantitative}};
            });
        }
    }

    const handleSetInitialGradeImpact = (prob: TRiskModelProbability | null) => {
        if(!prob) return;
        if(prob.quantitative === firstNoneObject.quantitative){
            //default none object - setting null
            currRisk && setCurrRisk({
                ...currRisk,
                initialGrade: {...currRisk.initialGrade, impact: null},
                id: currRisk?.id ?? '',
                name: currRisk?.name ?? ''
            });
        }else{
            currRisk && setCurrRisk({
                ...currRisk,
                initialGrade: {...currRisk.initialGrade, impact: prob.quantitative},
                id: currRisk?.id ?? '',
                name: currRisk?.name ?? ''
            });
        }
    }

    const handleSetControlType = (value: tControl | null) => {
        if(value && value.type === 'None'){
            currRisk && setCurrRisk({...currRisk, control: {type: value.type, owners: [], scheduler: null}});
        }else{
            currRisk && value && setCurrRisk({...currRisk, control: {...currRisk.control, type: value.type}});
        }
    }

    const handleSetControlScheduler = (date: any) => {
        try{
            const parsedDate = new Date(new Date(date).setHours(12)).toISOString();
            const normalizedForSave = parsedDate.slice(0, '2022-11-09'.length);
            if(parsedDate && currRisk){
                setCurrRisk({...currRisk, control: {...currRisk.control, scheduler: normalizedForSave}})
            }
        }catch (ex: any){
            console.log(`useExactRegistryRightSide: handleSetControlScheduler: ${ex.message}`);
        }
        // currRisk && setCurrRisk({...currRisk, control: {...currRisk.control, scheduler: date}})
    }

    const handleDeleteOwner = (owner: string) => {
        currRisk && setCurrRisk({
            ...currRisk,
            control: {
                ...currRisk.control,
                owners: [...currRisk.control.owners.filter(e => e !== owner)]
            }
        })
    }

    const handleOpenAddOwners = () => {
        currRisk && dispatch(openAddActionOwner(currRisk));
    }

    const setControlOwners = (_risk: TRisk) => {
        if(_risk && currRisk){
            setCurrRisk((prev) => {return {...prev!, control: {...prev!.control, owners: _risk.control.owners}}})
        }
    }

    const handleChangeActions = (event:React.ChangeEvent<HTMLInputElement>) => {
        currRisk && setCurrRisk({...currRisk, control: {...currRisk.control, actions: event.target.value}})
    }

    //Residual grade

    const handleSetResidualGradeProbability = (prob: TRiskModelProbability | null) => {
        if(!prob) return;
        if(prob.quantitative === firstNoneObject.quantitative){
            //default none object - setting null
            currRisk && setCurrRisk(prev => {
                return {...prev!, residualGrade: {...currRisk!.residualGrade, probability: null}};
            });
        }else{
            currRisk && setCurrRisk(prev => {
                return {...prev!, residualGrade: {...currRisk!.residualGrade, probability: prob.quantitative}};
            });
        }
    }

    const handleSetResidualGradeImpact = (prob: TRiskModelProbability | null) => {
        if(!prob) return;
        if(prob.quantitative === firstNoneObject.quantitative){
            //default none object - setting null
            currRisk && setCurrRisk({
                ...currRisk,
                residualGrade: {...currRisk.residualGrade, impact: null},
                id: currRisk?.id ?? '',
                name: currRisk?.name ?? ''
            });
        }else{
            currRisk && setCurrRisk({
                ...currRisk,
                residualGrade: {...currRisk.residualGrade, impact: prob.quantitative},
                id: currRisk?.id ?? '',
                name: currRisk?.name ?? ''
            });
        }
    }

    //OTHER ACTIONS
    const handleUpdateRisk = async () => {
        //this endpoint returns array of risks - fetching all fetched before risks to replace all in once
        reg && currRisk && await dispatch(updateRisksByRegistryIdAction({
            data: {
                workspaceId: '',
                organizationId: '',
                risks: [currRisk],
                deleteIds: [],
                registryId: reg.id,
                // pageInfo: {
                //     page: 0,
                //     count: !_pageInfo.page ? DEFAULT_COUNT : _pageInfo.page * _pageInfo.count,
                //     sort: _pageInfo.sort,
                //     filters: {}
                // },
            },
            onSuccess: () => null,
            disableReplaceAll: false
        }));
    }

    const handleCancelUpdateRisk = () => {
        setCurrRisk(risk);
    }


    const riskRate = (currRisk && model) ? getRiskRate(currRisk.initialGrade.probability ?? 0, currRisk.initialGrade.impact ?? 0, model) : 'Unknown';
    const residualRiskRate = (currRisk && model) ? getRiskRate(currRisk.residualGrade.probability ?? 0, currRisk.residualGrade.impact ?? 0, model) : 'Unknown';

    const isEditMode = risk ?
            (risk.initialGrade.probability !== currRisk?.initialGrade.probability ||
                risk.initialGrade.impact !== currRisk?.initialGrade.impact ||
                risk.control.type !== currRisk?.control.type ||
                JSON.stringify(risk.control.owners) !== JSON.stringify(currRisk?.control.owners) ||
                risk.control.scheduler !== currRisk?.control.scheduler ||
                risk.control.actions?.trim() !== currRisk?.control.actions?.trim() ||
                risk.residualGrade.probability !== currRisk?.residualGrade.probability ||
                risk.residualGrade.impact !== currRisk?.residualGrade.impact
            ) : false;

    useEffect(() => {
        dispatch(setRiskEdited(isEditMode));
        //eslint-disable-next-line
    }, [isEditMode]);

    return{
        actions: {
            handleClose,
            handleEditRisk,
            handleDeleteRisk,
            handleChangeRisksQuantitative,

            handleSetControlScheduler,
            handleUpdateRisk,
            handleCancelUpdateRisk,
            handleSetInitialGradeProbability,
            handleSetInitialGradeImpact,
            handleSetControlType,
            handleDeleteOwner,
            handleOpenAddOwners,
            handleChangeActions,
            setControlOwners,

            handleSetResidualGradeImpact,
            handleSetResidualGradeProbability,

        },
        editableRisk: currRisk,
        loadings: {
            modelsList,
            riskEdit
        },
        riskRate,
        riskRateColor: getColorByRiskRate(riskRate),
        residualRiskRate,
        residualRiskRateColor: getColorByRiskRate(residualRiskRate),
        isQuantitative,
        isEditMode,
        initialGradeProbabilityValue: getInitialGradeProbabilityValue(currRisk, model),
        initialGradeImpactValue: getInitialGradeImpactValue(currRisk, model),
        riskTreatmentControlValue: getInitialControlValue(currRisk),
    }
}