import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useHistory, useParams} from "react-router-dom";
import {
    TSaasAssetSettings,
    TSaasAssetSettingsId,
    TSetDeleteSettingRowDialog,
    TSetEditSettingRowDialog,
} from "../../types";
import {normalizeSettingIdName} from "../../helpers";
import {useSelector} from "react-redux";
import {
    loadings,
    saasAssetsSettingsSelector,
    setDeleteSettingRowDialogAction,
    setEditSettingRowDialogAction,
} from "../../store/store";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_ASSET_MANAGEMENT} from "../../../../../newShared/constants";
import {
    PATH_LOCAL_ASSET_MANAGEMENT_SAAS,
    PATH_LOCAL_ASSET_MANAGEMENT_SAAS_SETTINGS,
    PATH_LOCAL_ASSET_MANAGEMENT_SAAS_SETTINGS_EXACT,
    PATH_LOCAL_SAAS_ASSETS_SETTINGS_NEW,
} from "../../constants";
import {useEffect, useState} from "react";
import {useParameters} from "../../../../../newShared/hooks/useParameters";
import {useAppDispatch} from "../../../../../newShared/redux";
import {getSaasAssetsSettingsAction} from "../../store/actions";


export const useExactSaasSettings = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    // const {t} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});
    const id = useParams<{id: TSaasAssetSettingsId}>().id;
    const {filter} = useParameters();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const settingTitle = normalizeSettingIdName(id as TSaasAssetSettingsId);
    const settings = useSelector(saasAssetsSettingsSelector);
    const {isLoadingSettings} = useSelector(loadings);

    const controller = new AbortController();

    const [search, setSearch] = useState<string>(filter?.[id]?.[0] || '');
    const [settingsView, setSettingsView] = useState<TSaasAssetSettings | null>(settings);

    useSetBreadcrumbs([
        {
            title: tMenu('Asset Management'),
            path: PATH_LOCAL_ASSET_MANAGEMENT
        },
        {
            title: 'SaaS',
            path: PATH_LOCAL_ASSET_MANAGEMENT_SAAS
        },
        {
            title: 'Settings',
            path: PATH_LOCAL_ASSET_MANAGEMENT_SAAS_SETTINGS
        },
        {
            title: isLoadingSettings ? 'Loading...' : settingTitle,
            path: PATH_LOCAL_ASSET_MANAGEMENT_SAAS_SETTINGS_EXACT.replace(':id', id)
        }
    ]);

    useEffect(() => {
        !settings && dispatch(getSaasAssetsSettingsAction({data: {organizationId: '', workspaceId: ''}, signal: controller.signal}));

        return () => {
            controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        settings && setSettingsView(settings)
    }, [settings])

    const handleCreateNewItem = (id: TSaasAssetSettingsId) => history.push(PATH_LOCAL_SAAS_ASSETS_SETTINGS_NEW.replace(':id', id));

    const handleGoBack = () => history.push(PATH_LOCAL_ASSET_MANAGEMENT_SAAS_SETTINGS);

    const handleCleanSearch = () => {
        setSearch('');
        delete filter[id];
        dispatch(getSaasAssetsSettingsAction({data: {organizationId: '', workspaceId: ''}, signal: controller.signal}));
    }

    const handleEnterKey = (e: any) => {
        if (e.keyCode === 13 && search.trim().length === 0 && settings) setSettingsView(settings);
        if(e.keyCode === 13 && search.trim().length > 0 && settings){
            // const regex = new RegExp(search.toLowerCase());
            switch (id){
                // case 'type': setSettingsView({...settings, type: settings.type.filter(e => regex.test(e.name.toLowerCase()))}); break;
                // case 'customFields': setSettingsView({...settings, customFields: settings.customFields.filter(e => regex.test(e.toLowerCase()))}); break;
            }
        }
    }

    const setEditSettingRowDialog = (data: TSetEditSettingRowDialog) => {dispatch(setEditSettingRowDialogAction(data))};
    const setDeleteSettingRowDialog = (data: TSetDeleteSettingRowDialog) => {dispatch(setDeleteSettingRowDialogAction(data))};

    return {
        settingsView,
        id: id as TSaasAssetSettingsId,
        isLoadingSettings,
        settingTitle,
        handleCreateNewItem,
        handleGoBack,

        search,
        setSearch,
        handleCleanSearch,
        handleEnterKey,
        setEditSettingRowDialog,
        setDeleteSettingRowDialog

    }
}
