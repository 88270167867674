import React, {FC, useEffect, useState} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSignIn} from "../../hooks/useSignIn";
import {useRoutes} from "../../hooks/useRoutes";
import {useThreeDotsMenu} from "../../../../../newShared/hooks/useThreeDotsMenu";
import {
    Box,
    Checkbox,
    Collapse,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import {DropdownButton, LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {CheckCircleOutlineRounded, Language, Visibility, VisibilityOff} from "@mui/icons-material";
import {LABEL, SUPPORTED_LANGUAGES} from "../../../../../newShared/constants";
import colors from "../../../../../newShared/theme/colors";
import {useFormik} from "formik";
import {validationSchemaRegistration} from "../../constants";
import {usePDFView} from "../../../../../newShared/components/docViewer/hooks/usePDFView";
import {invitationPageErrorType, useAcceptInvitation} from "../../../settings/hooks/invites/useAcceptInvitation";
import {Flex} from "../../../../../newShared/components/Layouts";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import {Typo} from "../../../../../newShared/components/Typography";
import ReportIcon from "@mui/icons-material/Report";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import {LocalSpinner} from "../../../../../newShared/components/Spinner";
import {useMedia} from "../../../../../newShared/hooks/useMedia";


export const BlockSignUp: FC = () => {
    const {t, revDir, currentLanguage: {name, code}, handleChooseLanguage} = useMainTranslation('', {keyPrefix: 'pathLogin.signUpPage'});
    const {isLessThenCustomQuery} = useMedia(480);

    const {
        errors: inviteErrors,
        isLoading,
        invitationData,
        checkIfExistsError,
    } = useAcceptInvitation();

    const {
        isLoading: {isRegistration},
        registration,
    } = useSignIn();

    const {handleOpenLicenseView} = usePDFView();

    const {errors, touched, values, handleSubmit, handleChange, setFieldTouched: setFormikTouched, setFieldValue} = useFormik<{firstName: string, lastName: string, organizationName: string, email: string, password: string, passwordConfirm: string, licence: boolean, invitationCode: string}>({
        initialValues: {
            firstName: '',
            lastName: '',
            organizationName: '',
            email: '',
            password: '',
            passwordConfirm: '',
            licence: false,
            invitationCode: '',
        },
        validationSchema: validationSchemaRegistration,
        validateOnChange: true,
        isInitialValid: false,
        validateOnMount: true,
        onSubmit: (values) => {
            registration({
                firstName: values.firstName.trim(),
                lastName: values.lastName.trim(),
                organizationName: values.organizationName.trim(),
                password: values.password,
                username: values.email.trim(),
                license: values.licence,
                invitationCode: values.invitationCode
            })
        },
    });

    const {goToLogin} = useRoutes();

    const {anchorThreeDotsEl, handleThreeDotsOpen, handleThreeDotsClose} = useThreeDotsMenu();
    const [showPass, setShowPass] = useState(false);
    const [fieldTouched, setFieldTouched] = useState<string>('');

    useEffect(() => {
        if (invitationData && !checkIfExistsError) {
            setFieldValue('invitationCode', invitationData.inviteId);
            setFieldValue('email', invitationData.email);
            setFieldValue('organizationName', invitationData.orgName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invitationData])

    return (
        <>
            <Typography variant={"h1"} textAlign={"center"}>{t('Create new account')}</Typography>

            <DropdownButton value={code} sx={{m: '32px auto 0', alignSelf: 'center'}} variant={"text"}
                            icon={<Language/>} open={!!anchorThreeDotsEl} onClick={handleThreeDotsOpen}>
                <Typography variant={"body1"}>{name}</Typography>
            </DropdownButton>
            <Menu open={!!anchorThreeDotsEl} anchorEl={anchorThreeDotsEl} onClose={handleThreeDotsClose}>
                {SUPPORTED_LANGUAGES.map((e) => (
                    <MenuItem value={e.code} key={e.code}
                              sx={{width: anchorThreeDotsEl?.offsetWidth}}
                              onClick={() => {
                                  handleChooseLanguage(e.code);
                                  handleThreeDotsClose();
                              }}>
                        {e.name}
                    </MenuItem>
                ))}
            </Menu>

            {isLoading && <LocalSpinner />}
            {!isLoading && checkIfExistsError && <ErrorsElement errors={inviteErrors}/>}

            {!checkIfExistsError && !isLoading && (
                <form onSubmit={handleSubmit}>
                    <TextField name={'firstName'} label={t('First name')} value={values.firstName} onChange={handleChange}
                               sx={{mt: '16px'}}/>
                    <TextField name={'lastName'} label={t('Last name')} value={values.lastName} onChange={handleChange}
                               sx={{mt: '16px'}}/>
                    <TextField name={'organizationName'} label={t('Organization name')} value={values.organizationName} onChange={handleChange}
                               disabled={!!invitationData?.orgName && !checkIfExistsError}
                               sx={{mt: '16px'}}/>
                    <TextField name={'email'} label={t('Email')} value={values.email} onChange={handleChange}
                               disabled={!!invitationData?.email && !checkIfExistsError}
                               sx={{mt: '16px'}} type={"email"}/>

                    <TextField name={'password'} label={t('New password')} value={values.password} onChange={handleChange}
                               sx={{mt: '16px'}} type={showPass ? 'text' : 'password'}
                               error={!!errors.password && fieldTouched !== 'password' && touched.password}
                               onFocus={() => {
                                   setFieldTouched('password');
                                   setFormikTouched('password', true);
                               }}
                               onBlur={() => setFieldTouched('')}
                               InputProps={{endAdornment: (
                                       <InputAdornment position="end">
                                           <IconButton onClick={() => setShowPass(prev => !prev)} edge="end">
                                               {showPass ? <VisibilityOff /> : <Visibility />}
                                           </IconButton>
                                       </InputAdornment>
                                   )}}
                    />

                    <Collapse in={fieldTouched === 'password'} sx={{width: '100%'}}>
                        <Box display={"flex"} flexDirection={"column"} width={'100%'}
                             sx={{
                                 mt: '16px',
                                 borderRadius: '8px',
                                 padding: '16px',
                                 boxShadow: '0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13)',
                             }}>
                            <Typography variant={"body2"}>{t('Password must meet the following requirements_doubleDot')}</Typography>

                            <Box display={"flex"} alignItems={"center"} gap={'8px'} mt={'8px'}>
                                <CheckCircleOutlineRounded color={values.password.length >= 8 ? "success" : 'error'}/>
                                <Typography variant={"body2"}>
                                    {`${t('Minimum 8 symbols')}`}
                                </Typography>
                            </Box>
                            <Box display={"flex"} alignItems={"center"} gap={'8px'} mt={'8px'}>
                                <CheckCircleOutlineRounded color={/(?=.*[A-Z])/.test(values.password) ? "success" : 'error'}/>
                                <Typography variant={"body2"}>
                                    {`${t('At least one capital letter')}`}
                                </Typography>
                            </Box>
                            <Box display={"flex"} alignItems={"center"} gap={'8px'} mt={'8px'}>
                                <CheckCircleOutlineRounded color={/(?=.*[a-z])/.test(values.password) ? "success" : 'error'}/>
                                <Typography variant={"body2"}>
                                    {`${t('At least one lowercase letter')}`}
                                </Typography>
                            </Box>
                            <Box display={"flex"} alignItems={"center"} gap={'8px'} mt={'8px'}>
                                <CheckCircleOutlineRounded color={/(?=.*[0-9])/.test(values.password) ? "success" : 'error'}/>
                                <Typography variant={"body2"}>
                                    {`${t('At least one number')}`}
                                </Typography>
                            </Box>
                            <Box display={"flex"} alignItems={"center"} gap={'8px'} mt={'8px'}>
                                <CheckCircleOutlineRounded color={/(?=.*[!@#$%^&*])/.test(values.password) ? "success" : 'error'}/>
                                <Typography variant={"body2"}>
                                    {`${t('At least one of the following symbols')} `}<span style={{fontWeight: 600}}>{'!@#$%^&*'}</span>
                                </Typography>
                            </Box>

                        </Box>
                    </Collapse>

                    <TextField name={'passwordConfirm'} label={t('Confirm password')} value={values.passwordConfirm} onChange={handleChange}
                               sx={{mt: '16px'}} type={showPass ? 'text' : 'password'}
                               error={!!errors.passwordConfirm && fieldTouched !== 'passwordConfirm' && touched.passwordConfirm}
                               onFocus={() => {
                                   setFieldTouched('passwordConfirm');
                                   setFormikTouched('passwordConfirm', true);
                               }}
                               onBlur={() => setFieldTouched('')}
                               InputProps={{endAdornment: (
                                       <InputAdornment position="end">
                                           <IconButton onClick={() => setShowPass(prev => !prev)} edge="end">
                                               {showPass ? <VisibilityOff /> : <Visibility />}
                                           </IconButton>
                                       </InputAdornment>
                                   )}}
                    />

                    {!!invitationData?.inviteId && !checkIfExistsError && (
                        <TextField name={'invitationCode'} label={t('Invite ID')} value={values.invitationCode} onChange={handleChange}
                                   disabled
                                   sx={{mt: '16px'}}/>
                    )}

                    <Box display={"flex"} alignItems={'center'} gap={'4px'} mt={'8px'} m={!revDir ? '8px 0 0 -10px' : '8px -10px 0 0'}>
                        <Checkbox name={'licence'} value={values.licence} onChange={e => setFieldValue('licence', e.target.checked, true)}/>
                        <Box display={"flex"}
                             alignItems={!isLessThenCustomQuery ? 'center' : undefined}
                             gap={!isLessThenCustomQuery ? '12px' : '2px'}
                             flexDirection={!isLessThenCustomQuery ? undefined : "column"}>
                            <Typography variant={"body2"}>{t('I accept the {{companyName}}', {companyName: LABEL})} </Typography>
                            <Typography variant={"body2"} onClick={handleOpenLicenseView}
                                        sx={{
                                            textAlign: 'left',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            color: colors.linkTextColor
                                        }}>
                                {t('Terms of Service')}
                            </Typography>
                        </Box>
                    </Box>

                    <LoadingButton loading={isRegistration}
                                   type={'submit'}
                                   disabled={!!errors.passwordConfirm || !!errors.password || !!errors.email || !!errors.firstName || !!errors.lastName || !!errors.organizationName || !!errors.licence}
                                   sx={{mt: '12px', alignSelf: 'flex-start'}}>
                        {t('Create')}
                    </LoadingButton>
                </form>
            )}

            <Typography sx={{mt: '16px', fontWeight: 500, fontSize: '18px', lineHeight: '24px'}}>
                {`${t('Already a member?')} `}<span onClick={goToLogin} style={{color: colors.primary.blue, cursor: 'pointer', fontWeight: 600}}>{t('Log In')}</span>
            </Typography>

            {/*{checkIfExistsError && (*/}
            {/*    <Typography sx={{mt: '16px', fontWeight: 500, fontSize: '18px', lineHeight: '24px'}}>*/}
            {/*        <span onClick={goToSignUp} style={{color: colors.primary.blue, cursor: 'pointer', fontWeight: 600}}>{t('Create new account')}</span>{` ${t('without invitation')}`}*/}
            {/*    </Typography>*/}
            {/*)}*/}
        </>
    )
}

const ErrorsElement = ({errors}: { errors: invitationPageErrorType }) => {

    const {t} = useMainTranslation('', {keyPrefix: 'pathLogin.inviteErrorsElement'});

    return(
        <Flex w={'100%'} m={'16px 0 0 0'} direction={'column'} ai={'center'} talign={'center'}>
            {errors.isInviteExpired &&
                <>
                    <RunningWithErrorsIcon style={{width: '100px', height: '100px', color: '#ff5c5c'}}/>
                    <Typo fontSize={'20px'} fontWeight={500}>{t('Invite is expired. Contact your organization owner or admin to resend invite.')}</Typo>
                </>
            }
            {errors.isInviteNotFound &&
                <>
                    <ReportIcon style={{width: '100px', height: '100px', color: '#ff5c5c'}}/>
                    <Typo fontSize={'20px'} fontWeight={500}>{t('Invite not found. Contact your organization owner or admin to resend invite.')}</Typo>
                </>
            }
            {errors.isNotForThisUser &&
                <>
                    <ReportIcon style={{width: '100px', height: '100px', color: '#ff5c5c'}}/>
                    <Typo fontSize={'20px'} fontWeight={500}>{t('This invite is for another user. If you sure this invite belongs to you - sign with correct account.')}</Typo>
                </>
            }
            {errors.isAlreadyActivated &&
                <>
                    <VerifiedUserIcon style={{width: '100px', height: '100px', color: '#64B5F6'}}/>
                    <Typo fontSize={'20px'} fontWeight={500}>${t('This invite is already used.')}</Typo>
                </>
            }
            {errors.badInvitationCode &&
                <>
                    <SearchOffIcon style={{width: '100px', height: '100px', color: '#ff5c5c'}}/>
                    <Typo fontSize={'20px'} fontWeight={500}>{t('Invitation not found.')}</Typo>
                </>
            }
            {errors.isUserExistsButNotSignedIn &&
                <>
                    <ReportIcon style={{width: '100px', height: '100px', color: '#ff5c5c'}}/>
                    <Typo fontSize={'20px'} fontWeight={500}>{t('This invitation sent to user that already exists. Please sign in to accept invite.')}</Typo>
                </>
            }
        </Flex>
    )
}