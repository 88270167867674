import {useDispatch, useSelector} from "react-redux";
import {
    eraseFiles,
    files,
    foldersPath,
    isLoading,
    openRestoreDialog,
    pageInfoSelector,
    selectedFiles,
    selectedFolder,
    selectedFolders,
} from "../../store/slice";
import {availableColumns, TrashStatusVariants, TREE_PATH, TREE_TRASH_PATH} from "../../constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {DocumentFolder, TDocumentStatus, TShortDocument} from "../../types";
import {GetShortDocumentsByFolderIdWithFilterPaging} from "../../store/actions";
import {GetShortDocumentsByFolderIdWithFilterPagingQueryVariables} from "../../../../../newShared/GQLTypes";
import {useScrollPagination} from "../../../../../newShared/hooks/useScrollPagination";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {useEffect} from "react";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";

export const useTrashPage = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.TrashPage'});
    const dispatch = useDispatch();
    const {isLoadingFiles} = useSelector(isLoading);
    const _selectedFiles =  useSelector(selectedFiles);
    const _selectedFolders = useSelector(selectedFolders);
    const _selectedF = useSelector(selectedFolder);
    const _files = useSelector(files);
    const pageInfo = useSelector(pageInfoSelector);
    const _path = useSelector(foldersPath);
    const {clearFilters} = useGenericFiltersStorage();

    useSetBreadcrumbs([
        {title: t('Documents'), path: TREE_PATH},
        {title: t('Trash'), path: TREE_TRASH_PATH}
    ])

    const eraseFilesHandler = () => {dispatch(eraseFiles())};

    const getTrashDocuments = (data: GetShortDocumentsByFolderIdWithFilterPagingQueryVariables) => dispatch(GetShortDocumentsByFolderIdWithFilterPaging({data}));

    //filter config
    const trashedDate: keyConfig<string> = {
        type: 'date',
        key: 'deletedDate',
        name: t('Trashed date'),
        default: true,
    }

    const statusConfig: keyConfig<{status: TDocumentStatus, title: string}> = {
        type: 'filter',
        key: 'status',
        name: t('Status'),
        options: TrashStatusVariants,
        isOptionEqualToSearch: (option, search) => option.status.trim().toLowerCase().includes(search.trim().toLowerCase()),
        getFilterValue: (a) => a.status,
        getOptionLabel: (e) => e.title,
        default: true
    }

    const handleOpenRestoreDialog = (data: {folder?: DocumentFolder, file?: TShortDocument}) => {
        dispatch(openRestoreDialog(data));
    }


    const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => {
            _selectedF && getTrashDocuments({pageInfo: { page, count, }, workspaceId: '', folderId: 'trash'});
        },
        arrayLength: files.length,
        isLoading: isLoadingFiles,
        pageInfo,
        // cleanData: () => dispatch(eraseEmployees()),
        // isNoMoreThanTotal: true,
        // customDependencies: [],
        // is0PageAddictive: false,
    })

    useEffect(() => {

        return () => {
            clearFilters();
        }
        //eslint-disable-next-line
    }, []);



    return{
        path: _path,
        isLoadingFiles,
        files: _files,
        pageInfo,
        selectedFolder: _selectedF,
        isFilesOrFoldersSelected: _selectedFiles.length > 0 || _selectedFolders.length > 0,
        columnSettings: availableColumns,
        getTrashDocuments,
        eraseFilesHandler,
        handleOpenRestoreDialog,
        configs: [
            trashedDate,
            statusConfig,
        ],

        scroll: {
            hasMore: pageInfo.total > ((pageInfo.page + 1) *pageInfo.count),
            bottomLoader,
            onScroll,
            scrollRef,
            isLoading,
            total: !files.length ? 'loading' : pageInfo.total,
        }

    }
}

