import {ChangeEvent, useEffect, useState} from "react";
import {useAppDispatch} from "../../../../../newShared/redux";
import {useSelector} from "react-redux";
import {
    assetsDialogsSelector,
    assetsSettingsSelector,
    loadings,
    setCreateSettingRowDialogAction
} from "../../store/store";
import {TCreateField, TSetCreateSettingRowDialog, TSettingsId, TSettingStatus} from "../../types";
import {normalizeSettingIdName} from "../../helpers";
import {
    createCertificateAssetsSettingRowAction,
    createPhysicalAssetsSettingRowAction,
    createSaasAssetsSettingRowAction,
    createSoftwareAssetsSettingRowAction,
    createVirtualAssetsSettingRowAction
} from "../../store/actions";

import {statusVariants} from "../../constants";


export const useCreateSettingDialog = () => {
    const dispatch = useAppDispatch();

    const {
        createSettingRow: {isOpen, field, assetType},
        changeStatus: {isOpen: isOpenChangeStatusDialog},
        deleteSettingRow: {isOpen: isOpenDeleteSettingDialog}
    } = useSelector(assetsDialogsSelector);
    const {addSettingLoading} = useSelector(loadings);
    const settingTitle = normalizeSettingIdName(field as TSettingsId);

    const [title, setTitle] = useState<string>('');
    const [itemStatus, setItemStatus] = useState<TSettingStatus>(statusVariants[0]);
    const settings = useSelector(assetsSettingsSelector);

    useEffect(() => {
        setTitle('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, field]);

    const setCreateSettingRowDialog = (data: TSetCreateSettingRowDialog) => {dispatch(setCreateSettingRowDialogAction(data))};

    const handleChangeTitle = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setTitle(e.target.value);
    }

    const handleChangeStatus = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setItemStatus(e.target.value as TSettingStatus);
    }

    const handleClose = () => {
        setCreateSettingRowDialog({clear: true});
        setTitle('');
        setItemStatus(statusVariants[0])
    }

    const isOk = () => {
       if (field === 'model' || field === 'manufacture' || field === 'category' || field === 'status') return !settings?.[field].some((e) => e?.name === title)
       if (field?.toLowerCase() === 'customfields') return !field && settings?.[field as TSettingsId].some(e => e === title )
        else return true;
    }

    const handleSave = (onSuccess?: (title: string, field?: TCreateField) => void) => {
        if (assetType === 'virtual') (field && title.trim() !== '') && dispatch(createVirtualAssetsSettingRowAction({data: {workspaceId: '', organizationId: '', field, title: title.trim()}, onSuccess: onSuccess ? () => onSuccess(title.trim()) : undefined}));
        if (assetType === 'saas') (field && title.trim() !== '') && dispatch(createSaasAssetsSettingRowAction({data: {workspaceId: '', organizationId: '', field, title: title.trim()}, onSuccess: onSuccess ? () => onSuccess(title.trim()) : undefined}));
        if (assetType === 'software') (field && title.trim() !== '') && dispatch(createSoftwareAssetsSettingRowAction({workspaceId: '', organizationId: '', field, title: title.trim()}))
        if (assetType === 'certificate') (field && title.trim() !== '') && dispatch(createCertificateAssetsSettingRowAction({data: {workspaceId: '', organizationId: '', field, title: title.trim()}, onSuccess: onSuccess ? () => onSuccess(title.trim()) : undefined}));
        if (assetType === 'physical')  (field && title.trim() !== '') && dispatch(createPhysicalAssetsSettingRowAction({data: {workspaceId: '', organizationId: '', field, title: title.trim(), status: {name: title.trim(), status: itemStatus}}, onSuccess: onSuccess ? () => onSuccess(title.trim(), field as TCreateField) : undefined}))
    }

    return {
        isOk,
        isOpen,
        isOpenChangeStatusDialog,
        isOpenDeleteSettingDialog,
        field,
        settingTitle,

        title,
        handleChangeTitle,
        itemStatus,
        handleChangeStatus,

        handleClose,
        handleSave,

        addSettingLoading,

    }



}