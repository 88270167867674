import {useControl} from "../../../hooks/useControl";
import {useFrameworksDialog} from "../../../hooks/useFrameworksDialog";
import {useFrameworksPathParams} from "../../../hooks/useFrameworksPathParams";
import {useScrollPagination} from "../../../../../../newShared/hooks/useScrollPagination";

export const useTabCommentsPage = () => {
    const {
        framework,
        comments: {
            comments, pageInfo: {page, count, total}, isLoading
        },
        getComments, cleanComments,
    } = useControl();
    const {newComment: {setNewCommentDialog}} = useFrameworksDialog();
    const {frId, controlId} = useFrameworksPathParams();

    //Controller and first loadData
    const controller = new AbortController();

    //Pagination
    const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => {
            frId && controlId && getComments({
                frameworkId: frId, controlId,
                pageInfo: {page, count, filters: {}},
            }, controller.signal)
        },
        arrayLength: comments.length,
        isLoading: isLoading,
        pageInfo: {page, count, total},
        cleanData: cleanComments,
        controller: controller,
        isOnTop: true,
        // isNoMoreThanTotal: true,
        customDependencies: [frId, controlId],
        is0PageAddictive: frId !== framework?.id,
    })

    return {
        comments,
        setNewCommentDialog,
        isLoading,
        bottomLoader, onScroll, scrollRef,
    }
}