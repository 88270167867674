import {useState} from "react";

export const ChangeLanguageDialog = () => {
    const [isOpen, toggleVisibility] = useState<boolean>(false);

    return{
        isOpen,
        toggleVisibility
    }
}
