import {useParameters} from "../../../../../newShared/hooks/useParameters";
import {useDispatch, useSelector} from "react-redux";
import {messageSelector} from "../../../settingsProfile/reducer";
import {useSettingsProfile} from "../../../settingsProfile/hooks/useSettingsProfile";
import * as yup from "yup";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useEffect} from "react";
import {useFormik} from "formik";
import {useSnack} from "../../../../barsEnvironment/snack/hooks/useSnack";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useRoutes} from "../useRoutes";
import {isResetMailSentSelector} from "../../store/slice";
import {sendEmailToResetPassAction, sendPassToResetPassAction} from "../../store/actions";


export const useRestorePass = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'loginRestorePasswordPage'});

    const {setMessage} = useMessageDialog();
    const {goToLogin} = useRoutes();

    const dispatch = useDispatch();

    const snack = useSnack();
    const {filter: {hash, email}} = useParameters();

    const message = useSelector(messageSelector);

    useEffect(() => {
        message && snack(message, "success")

        return cleanMessage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message])

    const sendEmail = (email: string) => {
        dispatch(sendEmailToResetPassAction({email}))
    }

    const sendNewPass = (hash: string, password: string, email: string) => {
        dispatch(sendPassToResetPassAction({hash, password, email, onSuccess}))
    }

    const onSuccess = () => {
        setMessage({
            title: 'Completed successfully!',
            message: 'You password has been changed!'
        })
        goToLogin();
    }

    const {cleanMessage} = useSettingsProfile();

    const validationSchema = yup.object({
        email: yup
            .string()
            .email(t('Not valid email'))
            .required(t('Email is required')),
        password: yup
            .string()
            .min(8, 'Password should be of minimum 8 characters length')
            .matches(
                /(?=.*[A-Z])/,
                t("Must Contain one Uppercase character")
            )
            .matches(
                /(?=.*[a-z])/,
                t("Must Contain one Lowercase character")
            )
            .matches(
                /(?=.*[0-9])/,
                t("Must Contain one Number character")
            )
            .matches(
                /(?=.*[!@#$%^&*])/,
                t("Must contain one Special case character")
            )
            .required('New password is required'),
        passwordConfirm: yup
            .string()
            .oneOf([yup.ref("password"), null], t("Password does not match"))
            .required(t('Confirm password is required')),
    });

    const formik = useFormik<{email: string, password: string, passwordConfirm: string}>({
        initialValues: {
            email: email ? email[0] || '' : '',
            password: '',
            passwordConfirm: '',
        },
        validationSchema: validationSchema,
        validateOnChange: true,
        isInitialValid: false,
        validateOnMount: true,
        onSubmit: (values) => {
            hash?.[0] && email?.[0] && sendNewPass(hash[0], values.password, email[0]);
        },
    });

    return {
        hash: hash?.[0] || undefined,
        isResetMailSent: useSelector(isResetMailSentSelector),

        sendEmail,
        formik,
    }
}
