import {useDispatch, useSelector} from "react-redux";
import {
    cleanAction,
    cleanArrayAction,
    currentFrameworkControlsSelector,
    currentFrameworkIsLoadingSelector,
    currentFrameworkPageInfoSelector,
    currentFrameworkSelector,
    TCurrentFrameworkSlice
} from "../../store/currentFramework/store";
import {PaginationInput} from "../../../../../newShared/GQLTypes";
import {useHistory} from "react-router";
import {PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED} from "../../../../../newShared/constants";
import {useFrameworksPathParams} from "../useFrameworksPathParams";
import {getControlsByFrameworkAction, getFrameworkZipAction} from "../../store/currentFramework/actions";
import {removeFrameworkFromWorkspaceAction} from "../../store/frameworks/actions";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";

type TResponse = {
    frId: string | undefined,
    framework: TCurrentFrameworkSlice["framework"],
    controls: TCurrentFrameworkSlice["controls"],
    pageInfo: TCurrentFrameworkSlice["pageInfo"],
    isLoading: TCurrentFrameworkSlice["isLoading"],

    getData: (frId: string, pageInfo: PaginationInput, clean: boolean, signal?: AbortSignal) => void,
    getFrameworkZip: (frId: string, frName: string) => void,

    openControl: (controlId: string, customFrId?: string) => void,

    removeFramework: () => void,

    clean: () => void,
    cleanArray: () => void,
}
export const useCurrentFramework = (): TResponse => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useMainTranslation('', {keyPrefix: 'pathFrameworks'});
    const {setMessage} = useMessageDialog();
    const {frId} = useFrameworksPathParams()

    const framework = useSelector(currentFrameworkSelector);
    const controls = useSelector(currentFrameworkControlsSelector);

    const pageInfo = useSelector(currentFrameworkPageInfoSelector);
    const isLoading = useSelector(currentFrameworkIsLoadingSelector);

    const getData: TResponse["getData"] = (frameworkId, pageInfo, clean) => {clean && cleanArray(); dispatch(getControlsByFrameworkAction({
        workspaceId: '', organizationId: '', language: '', frameworkId, pageInfo
    }))};
    const getFrameworkZip: TResponse["getFrameworkZip"] = (frameworkId, frameworkName) => {dispatch(getFrameworkZipAction({
        frameworkId, frameworkName
    }))};

    const openControl: TResponse["openControl"] = (controlId,customFrId) => {
        (frId || customFrId) && history.push(`${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/${(customFrId ?? frId)}/control/${controlId}`)
    };

    const removeFramework: TResponse["removeFramework"] = () => {framework && dispatch(removeFrameworkFromWorkspaceAction(
        {
            wfId: framework.wfId,
            endFunc: () => {
                setMessage({message: t('Information about the framework has been removed.'), title: t('Completed successfully')});
                history.push(PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED);
            }
        }
    ))};

    const clean = () => {dispatch(cleanAction())};
    const cleanArray = () => {dispatch(cleanArrayAction())};

    return {
        frId,
        framework,
        controls,

        pageInfo,
        isLoading,

        getData,
        getFrameworkZip,

        openControl,

        removeFramework,

        clean,
        cleanArray,
    }
}