import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    assetsDialogsSelector,
    assetsSettingsSelector,
    loadings,
    setCreateSettingRowDialogAction
} from "../../store/store";
import {useForm} from "../../../../../newShared/hooks/useForm";
import {
    defaultAsset,
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS,
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_ID,
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_NEW
} from "../../constants";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_ASSET_MANAGEMENT} from "../../../../../newShared/constants";
import {createPhysicalAssetAction, getPhysicalAssetsSettingsAction} from "../../store/actions";
import {madeAssetTrim} from "../../helpers";
import {TAsset, TCreateField, TSetCreateSettingRowDialog} from "../../types";
import {PhysicalAssetStatusCountModel} from "../../../../../newShared/GQLTypes";
import {TCollaborator} from "../../../../../newShared/components/AutocompleteCollaborators/types";


export const useAssetNew = () => {
    //root
    const dispatch = useDispatch();
    const history = useHistory();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    //selectors
    const {isLoadingSettings, isLoadingCreateAsset} = useSelector(loadings);
    const settings = useSelector(assetsSettingsSelector);
    const {createAsset: {isOpen: isOpenCreateAsset}} = useSelector(assetsDialogsSelector);


    //form
    const {form, setForm, handleChange} = useForm<TAsset>(defaultAsset);

    const controller = new AbortController();
    useEffect(() => {
        if(!settings) {
            dispatch(getPhysicalAssetsSettingsAction({data: {organizationId: '', workspaceId: ''}, signal: controller.signal}));
        }
        if(settings){
            const customFields: { [key: string]: string } = {};
            settings.customFields.forEach(name => {
                customFields[name] = '';
            });
            setForm({...form, customFields});
        }

        return () => {
            controller.abort();
            setForm(defaultAsset);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(settings && !Object.keys(form.customFields)?.length){
            const customFields: { [key: string]: string } = {};
            settings.customFields.forEach(name => {
                customFields[name] = '';
            });
            setForm({...form, customFields});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    useEffect(() => {
        setForm(defaultAsset);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenCreateAsset]);

    useEffect(() => {
        form.status.status.toLowerCase() !== 'deployed' && setForm({...form, assignedTo: null}) ;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.status.status]);


    useSetBreadcrumbs([
        {
            title: tMenu('Asset Management'),
            path: PATH_LOCAL_ASSET_MANAGEMENT
        },
        {
            title: tMenu('Physical assets'),
            path: PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS
        },
        {
            title: tMenu('New asset'),
            path: PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_NEW
        },
    ]);

    //actions
    const handleGoBack = () => history.push(PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS);

    const isOkId = () => {
        return !form.assetId || form.assetId?.trim() === "" || form.assetId?.match(/^[A-Za-z0-9-_]+$/);
    }

    const isOk = () => {
        if (form.status.status.toLowerCase() === 'deployed') {
            return (form.assignedTo?.publicId  && form.serial?.trim() && form.model?.length > 0 && form.category?.length > 0 && form.manufacture?.length > 0)
        } else return (form.serial?.trim() && form.model?.length > 0 && form.category?.length > 0 && form.manufacture?.length > 0)
    }

    const handleSave = () => {
        const newStatus: PhysicalAssetStatusCountModel | undefined = settings?.status.find(s => s.name === form.status.name);
        isOk() && newStatus && dispatch(createPhysicalAssetAction({
            data: {
                workspaceId: '',
                organizationId: '',
                asset: {
                    ...madeAssetTrim(form),
                    status: {
                        name: form.status.name,
                        status: newStatus.status
                    }}},
            onSuccess}));

    }

    const onSuccess = (id: string) => {
        history.push(PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_ID.replace(':id', id));
    }


    const handleSetModel = (title: string) => {
        setForm({...form, model: title});
    }

    const handleSetManufacture = (title: string) => {
        setForm({...form, manufacture: title})
    }

    const handleSetCategory = (title: string) => {
        setForm({...form, category: title})
    }

    //TextField/select
    const handleSetStatus = (title: string) => {
        setForm({...form, status: {name: title, status: settings?.status.find(({name}) => name === title)?.status || ""}})
    }


    const handleSetManager = (newValue: TCollaborator | null) => {
        setForm({...form, assignedTo: newValue});
    }

    const handleChangeCustomField = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setForm((prevValues) => ({
            ...prevValues,
            customFields: {
                ...prevValues.customFields,
                [event.target.name]: event.target.value,
            },
        }));
    };


    //CreateAssetDialog
    const openCreateSettingRowDialog = (data: TSetCreateSettingRowDialog) => {dispatch(setCreateSettingRowDialogAction(data))};

    const onSuccessCreateSetting = (title: string, field?: TCreateField) => {
        switch (field) {
            case 'model': handleSetModel(title); break;
            case 'category': handleSetCategory(title); break;
            case 'manufacture': handleSetManufacture(title); break;
            case 'status': handleSetStatus(title); break;
        }
    }


    return {
        settings,
        form,
        handleChange,

        isOk,
        isOkId,
        handleSave,
        handleGoBack,
        onSuccess,
        handleSetModel,
        handleSetCategory,
        handleSetManufacture,
        handleSetStatus,
        handleSetManager,
        handleChangeCustomField,
        onSuccessCreateSetting,

        isLoadingSettings,
        isLoadingCreateAsset,

        createAssetDialog: {
            isOpenCreateAsset,
        },

        openCreateSettingRowDialog,
    }
}
