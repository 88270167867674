//core
import React from "react";
import {useDispatch, useSelector} from "react-redux";

//redux
import {docFile, hideDocFile, selectedAutomation} from "../../../store/slice";

//material
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

//types
import {automationType} from "../../../types";

//components
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {UploadFile} from "../../../store/actions";
import {addInfoSnack} from "../../../../../barsEnvironment/snack/store/slice";
import {FileDropZone} from "../../../../../../newShared/components/fileDropZone";
import {FileDrop} from "react-file-drop";
import {useFileDropZone} from "../../../../../../newShared/hooks/useSingleFileDropZone";
import {Typo} from "../../../../../../newShared/components/Typography";
import {shortString} from "../../../../../../newShared/utils/text";

export const DocFileSelectionDialog = () => {

    const {t} = useMainTranslation('', {keyPrefix: 'automations'});

    const dispatch = useDispatch();
    const auto: automationType | null = useSelector(selectedAutomation);
    const {isOpen} = useSelector(docFile);

    const {fileInBase64, filePicker, fileHandler, ref, handleDeleteFile} = useFileDropZone();

    // const [file, setFile] = useState<any>(null);
    // const [fileName, setFileName] = useState<string>('');

    const handleSelect = async() => {
        if(!fileInBase64) return;
        if(auto?.documents.links.find(e => e.fileName === fileInBase64.name) === undefined){
            dispatch(UploadFile({file: fileInBase64.file, fileName: fileInBase64.name}));
            // setFileName('');
            // setFile(null);
        }else{
            dispatch(addInfoSnack(t('File {{name}} already added', {name: fileInBase64.name})));
        }
    }

    const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
        if (reason !== 'backdropClick') {
            dispatch(hideDocFile());
            handleDeleteFile();
        }
    };

    return (auto &&

        <div>
            <Dialog disableEscapeKeyDown open={isOpen} onClose={handleClose} maxWidth={'xl'}>
                <DialogTitle>{t('Select File')}</DialogTitle>
                <DialogContent sx={{padding: '0 16px', display: 'flex', flexDirection: 'column', gap: '16px'}}>
                    <FileDrop onTargetClick={filePicker} onDrop={fileHandler}>
                        <FileDropZone
                            accept={''}
                            inputRef={ref}
                            fileHandler={fileHandler}
                        />
                    </FileDrop>

                    {!!fileInBase64?.name.length && <Typo margin={'15px 0 0 0'} fontSize={'18px'} fontWeight={300}>{t('File {{name}} uploaded', {name: shortString(fileInBase64.name, 30)})}</Typo>}
            </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>{t('Cancel')}</Button>
                    <Button onClick={handleSelect} disabled={!fileInBase64 || !fileInBase64.name.length}>{t('Ok')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}