import {createAsyncThunk} from "@reduxjs/toolkit";
import {TWithOptions} from "../../../../../newShared/types";
import {
    DeleteAssetFromNcrByUserMutationVariables,
    DownloadEvidenceFileQueryVariables,
    GetAppLogsToDateQueryVariables,
    GetFullNcrReportPreviewByUserQueryVariables,
    GetIntegrationDataByCollectionReportsQueryVariables,
    GetIntegrationsReportQueryVariables,
    GetReportAssetCollectorDataByUserQueryVariables,
    GetReportControlByIdQueryVariables,
    GetReportControlsQueryVariables,
    GetReportQueryVariables,
    LeaveResponseToNcrByUserMutationVariables,
    SetSnapshotReportResponseByUserMutationVariables,
    UploadAssetsToNcrByUserMutationVariables
} from "../../../../../newShared/GQLTypes";
import {AppState} from "../../../../../newShared/redux/rootReducer";
import {getActionsData} from "../../../../../newShared/redux";
import {
    deleteAssetFromNCRByUserApi,
    getAppLogsToDateApi,
    getFullNcrReportPreviewByUserApi,
    getIntegrationDataByCollectionReportsApi,
    getIntegrationsReportApi,
    getReportApi,
    getReportAssetCollectorDataByUserApi,
    getReportControlByIdApi,
    getReportControlsApi,
    leaveResponseToNCRByUserApi,
    setSnapshotReportResponseByUserApi,
    uploadAssetsToNCRByUserApi
} from "../../api";
import {downloadEvidenceFileApi, downloadReportCollectorAssetApi} from "../../../frameworks/api";
import {saveAs} from "file-saver";
import {b64toBlob} from "../../../../../newShared/utils/base64/base64";
import {downloadAppLogFile} from "../../../newIntegrations/api";
import {Buffer} from "buffer";


export const getReportAction = createAsyncThunk(
    'ReportView/getReport',
    async (data: TWithOptions<GetReportQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await getReportApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const getReportControlsAction = createAsyncThunk(
    'ReportView/getReportControls',
    async (data: TWithOptions<GetReportControlsQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await getReportControlsApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp,
            isNewOnTop: false,
        }
    },
);

export const getReportControlByIdAction = createAsyncThunk(
    'ReportView/getReportControlById',
    async (data: TWithOptions<GetReportControlByIdQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await getReportControlByIdApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const setSnapshotReportResponseByUserAction = createAsyncThunk(
    'ReportView/setSnapshotReportResponseByUser',
    async (data: TWithOptions<SetSnapshotReportResponseByUserMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await setSnapshotReportResponseByUserApi({...data.data, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const downloadEvidenceFileAction = createAsyncThunk(
    'ReportView/downloadEvidenceFile',
    async (data: TWithOptions<DownloadEvidenceFileQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await downloadEvidenceFileApi({...data.data, workspaceId}, data.signal);
        saveAs(b64toBlob(resp.file), resp.filename);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const downloadReportCollectorAssetAction = createAsyncThunk(
    'ReportView/downloadReportCollectorAsset',
    async (data: TWithOptions<{reportId: string, assetId: string, evidenceId: string}>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {organizationId, workspaceId} = getActionsData(state);

        const resp = await downloadReportCollectorAssetApi({...data.data, organizationId, workspaceId}, data.signal);
        saveAs(b64toBlob(resp.file), resp.filename);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const getReportAssetCollectorDataByUserAction = createAsyncThunk(
    'ReportView/getReportAssetCollectorData',
    async (data: TWithOptions<GetReportAssetCollectorDataByUserQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await getReportAssetCollectorDataByUserApi({...data.data, workspaceId, organizationId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

// NCR NCR NCR NCR
export const leaveResponseToNCRByUserAction = createAsyncThunk(
    'ReportView/NCR/leaveResponseToNCRByUser',
    async (data: TWithOptions<LeaveResponseToNcrByUserMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await leaveResponseToNCRByUserApi({...data.data, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const uploadAssetsToNCRByUserAction = createAsyncThunk(
    'ReportView/NCR/uploadAssetsToNCRByUser',
    async (data: TWithOptions<UploadAssetsToNcrByUserMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await uploadAssetsToNCRByUserApi({...data.data, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const deleteAssetFromNCRByUserAction = createAsyncThunk(
    'ReportView/NCR/deleteAssetFromNCRByUser',
    async (data: TWithOptions<DeleteAssetFromNcrByUserMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await deleteAssetFromNCRByUserApi({...data.data, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const getFullNcrReportPreviewByUserAction = createAsyncThunk(
    'ReportView/getFullNcrReportPreviewByUser',
    async (data: TWithOptions<GetFullNcrReportPreviewByUserQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await getFullNcrReportPreviewByUserApi({...data.data, workspaceId, organizationId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const getIntegrationsReportAction = createAsyncThunk(
    'ReportView/getIntegrationsReport',
    async (data: TWithOptions<GetIntegrationsReportQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await getIntegrationsReportApi({...data.data, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const getIntegrationDataByCollectionReportsAction = createAsyncThunk(
    'ReportView/getIntegrationDataByCollectionReports',
    async (data: TWithOptions<GetIntegrationDataByCollectionReportsQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await getIntegrationDataByCollectionReportsApi({...data.data, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const GetAppLogsAction = createAsyncThunk(
    'Reports/getAppLogs',
    async (data: TWithOptions<GetAppLogsToDateQueryVariables>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await getAppLogsToDateApi({...data.data, workspaceId}, data.signal);
        return {
            data: data.data,
            resp
        }
    }
);

export const DownloadLogAction = createAsyncThunk(
    'Reports/downloadLog',
    async (data: TWithOptions<{ fileId: string, fileName: string }>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await downloadAppLogFile({fileId: data.data.fileId, workspaceId});
        saveAs(b64toBlob(resp.file), data.data.fileName)
        return {
            data: {},
            resp: {}
        }
    }
);

export const DownloadLogForPreviewAction = createAsyncThunk(
    'Reports/DownloadLogForPreview',
    async (data: TWithOptions<{ fileId: string }>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await downloadAppLogFile({fileId: data.data.fileId, workspaceId});
        //receiving base64 plain text
        //decode base64 -> put in store
        const b = await Buffer.from(resp.file, 'base64').toString();

        // saveAs(b64toBlob(resp.file), resp.filename)
        return {
            data: data.data,
            resp: b
        };
    }
);