import React, {FC} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {LoadingButton} from "@mui/lab";
import {useDeleteAssetDialog} from "../../../hooks/useDeleteAssetDialog";


export const DeleteAssetDialog: FC = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});
    const {
        isOpen,
        isLoadingExact,
        responseDelete,
        handleDeleteAsset,
        handleCancelDeleteAsset,
        handleCloseDeleteDialog,
    } = useDeleteAssetDialog();

    return (
        <Dialog open={isOpen} onClose={handleCancelDeleteAsset} sx={{'& .MuiPaper-root': {width: '600px'}}}>

            <DialogTitle>
                {responseDelete ? t('Completed successfully') : t('Delete asset')}
            </DialogTitle>
            <DialogContent>
                <Typography variant={'body2'}>{ responseDelete ? responseDelete :
                    t('If you delete information about an asset, you will not be able to restore it.')
                }</Typography>
            </DialogContent>
            <DialogActions>
                { !responseDelete &&
                    <>
                        <Button variant={'text'} onClick={handleCancelDeleteAsset}
                                disabled={isLoadingExact}>{t('Cancel')} </Button>
                        <LoadingButton size={"large"} component={"label"}
                                       variant={'contained'}
                                       disabled={isLoadingExact}
                                       onClick={handleDeleteAsset} loadingPosition={isLoadingExact ? 'start' : undefined} loading={isLoadingExact}
                                       startIcon={isLoadingExact ? <div style={{width: '10px', height: '10px', }}/> : undefined}
                                       sx={{margin: revDir ? '0 16px 0 0' : '0 0 0 16px'}}>
                            {t('Confirm')}
                        </LoadingButton>
                    </>
                }

                {responseDelete && <Button variant={'text'} onClick={handleCloseDeleteDialog}
                         disabled={isLoadingExact}>{t('Close')} </Button>}


            </DialogActions>

        </Dialog>
    )
}