import {styled} from "@mui/material";
import {FlexColumn, FlexRow} from "../../editorTitleWithActionsRow/styled";
import colors from "../../../../../theme/colors";

export const EditorWorkzoneStyled = styled(FlexColumn)`
  flex-grow: 1;
  overflow: hidden;
  
  width: 100%;
  border-top: 1px solid #ccc;
`;

export const EditorPagesWithSideMenuStyled = styled(FlexRow)(({theme}) => ({
    flexGrow: 1,
    overflow: 'hidden',
    backgroundColor: colors.backgrounds.grey_dark,
}));
EditorPagesWithSideMenuStyled.defaultProps = {
    id: 'workzoneIDDDD',
}

export const EditorPagesAreaStyled = styled(FlexColumn)`
  flex-grow: 1;
  line-height: 1 !important;
  align-items: center;
  overflow: auto;
  gap: 10px;
  padding-block: 10px;
  font-size: 10px;
  
`;