import {messageDialogSelector, setMessageDialogAction, TMessageDialogSlice} from "../store/store";
import {useDispatch, useSelector} from "react-redux";
import {TMessage} from "../types";

type TResponse = {
    message: TMessageDialogSlice["message"],

    setMessage: (message: TMessage | null) => void,
    handleClose: () => void,
    isOpenMessageDialog: boolean
}
export const useMessageDialog = (): TResponse => {
    const dispatch = useDispatch();

    const message = useSelector(messageDialogSelector);

    const setMessage = (message: TMessage | null) => {dispatch(setMessageDialogAction(message))};
    const handleClose = () => {dispatch(setMessageDialogAction(null))};

    return {
        message,

        setMessage,
        handleClose,
        isOpenMessageDialog: !!message
    }
}