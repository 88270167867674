import {DialogContent, styled, TextField} from "@mui/material";


export const AddMessageDialogContent = styled(DialogContent)(() => ({
    overflow: 'hidden',
    display: "flex",
    flexDirection: 'column',
    width: '100%',
}));

export const MessageField = styled(TextField)(() => ({
    marginTop: '8px',
}));
MessageField.defaultProps = {
    minRows: 3,
    fullWidth: true,
    multiline: true,
}



