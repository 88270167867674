import styled from 'styled-components';
import {device} from '../../../../../newShared/constants';

export const QrCodeWrapper = styled.div`
    width: 30%;
    margin: 0 auto;
    display; flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0 0 0;
    ${device.tablet}{
        width: 100%;
    }
                     
`;

export const SecretRevealWrapper = styled.div`
    width: 110%;
    min-width: 300px;
    display; flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 41px 0 0 0;
    & p{
        display: inline-block;
    }
    flex-wrap: nowrap;
    ${device.tablet}{
        width: 100%;
    }
`;