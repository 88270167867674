import {Box, Button, styled, TableBody, TableContainer, TableHead, TableRow} from "@mui/material";
import colors from "../../theme/colors";
import {TypographyBody2} from "../Inputs/styled";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";


export const PageContainer = styled(Box)({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
});

export const CommonTableContainer = styled(TableContainer)(() => ({
    margin: '16px 0',
    width: '100%',
    boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    borderRadius: '8px !important',
    border: `1px solid #EAECF0`,
    height: '100%',
    flexGrow: '1',
    overflow: 'auto',
}));

export const CommonTableBody = styled(TableBody)(() => ({
    '& .MuiTableRow-root:last-child td': {
        borderBottom: `1px solid ${colors.primary.disable}`
    }
}));

export const CommonTableHead = styled(TableHead)(() => ({
    backgroundColor: colors.backgrounds.grey_light
}));

export const CommonHeaderTableRow = styled(TableRow)(() => ({
    '& .MuiTableCell-root': {
        backgroundColor: colors.backgrounds.grey_light,
        width: '100%',
        paddingTop: '6px',
        paddingBottom: '6px'
    }
}));

export const CommonTableRow = styled(TableRow)(() => ({
    '& .MuiTableCell-root': {
        backgroundColor: colors.backgrounds.grey_light,
    }
}));


export const TypographyBody2Grey = styled(TypographyBody2)(() => ({
    color: colors.text.dark,
}));

export const ViewReportButton = styled(Button)(() => ({
    textTransform: 'none',
    width: 'max-content',
}));
ViewReportButton.defaultProps = {
    variant: 'text',
    size: 'small'
};

export const RowBox = styled(Box, {
    shouldForwardProp: (propName) => (
        !["isFirst"].includes(propName.toString())
    )})(({isFirst}: {isFirst?: true}) => ({
    marginTop: isFirst ? 0 : '16px',
    display: 'flex',
    alignItems: 'center'
}));

export const TypographyEllipsis = styled(TypographyBody2)(() => ({
    color: colors.text.grey_dark,
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
}));

export const BackTextButton = styled(Button, {
    shouldForwardProp: (propName) => (
        !["revDir"].includes(propName.toString())
    )})(({revDir}: {revDir?: true}) => ({
    textTransform: 'none',
    width: 'max-content',
    marginRight: !revDir ? 'auto' : undefined,
    marginLeft: revDir ? 'auto' : undefined,
}));

BackTextButton.defaultProps = {
    variant: 'text',
    size: 'small',
    startIcon: <ArrowBackIosIcon style={{fontSize: 'small'}} />
}

export const PageWrapper = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
}));



