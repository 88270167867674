import {useDispatch, useSelector} from "react-redux";
import {getCollectorHistoryAction, getShortCollectorsAction} from "../../store/actions";
import {
    clearAction,
    collectorAppsSelector,
    collectorsSelector,
    initialCollectorState,
    openShowResults,
    selectedCollectorSelector
} from "../../store/slice";
import {useEffect} from "react";

type TResponse = {
    collectors: initialCollectorState["collectors"],
    selectedCollector: initialCollectorState["selectedCollector"],
    apps: initialCollectorState["apps"],

    getCollectors: (data: {page: number, count: number}) => void,
    getHistory: (data: {page: number, count: number, collectorId: string}) => void,

    clean: (data: string[]) => void,

    controller: AbortController,

    openResultDialog: () => void

}
export const useCollectors = (): TResponse => {
    const dispatch = useDispatch();
    const controller = new AbortController();

    const collectors = useSelector(collectorsSelector);
    const selectedCollector = useSelector(selectedCollectorSelector);
    const apps = useSelector(collectorAppsSelector);

    const getCollectors: TResponse["getCollectors"] = (data) => {
        dispatch(getShortCollectorsAction({data, signal: controller.signal}))
    }
    const getHistory: TResponse["getHistory"] = (data) => {
        dispatch(getCollectorHistoryAction({data, signal: controller.signal}))
    }

    const clean: TResponse["clean"] = (data) => {
        dispatch(clearAction(data))
    }

    useEffect(() => {
        return () => {
            controller.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        collectors,
        selectedCollector,
        apps,

        getCollectors,
        getHistory,

        clean,
        controller: controller,

        openResultDialog: () => {
            dispatch(openShowResults())
        }
    }
}