import {FC} from 'react';
import {useWorkspaceSubscription} from "./hooks/useWorkspaceSubscription";
import {useGetToken} from "./hooks/useGetToken";
import {
    UseManageWorkspacesAndOrganizations
} from "../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";


const WorkspaceSubscriptionWS:FC<{workspaceId: string, token: string, publicId: string, organizationId: string}> = ({workspaceId, token, publicId, organizationId}: {workspaceId: string, token: string, publicId: string, organizationId: string}) => {
    useWorkspaceSubscription(workspaceId, token, publicId, organizationId);
    return null;
}

export const SubscriptionHandler: FC = () => {
    const {workspaceId, user, organizationId} = UseManageWorkspacesAndOrganizations();
    const token = useGetToken();
    return(
        <>
            {(workspaceId && token) ? <WorkspaceSubscriptionWS
                workspaceId={workspaceId}
                token={token}
                key={workspaceId}
                publicId={user?.publicId ?? ''}
                organizationId={organizationId ?? ''}
            /> : null}
        </>
    )
}
