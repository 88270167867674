import React from "react";
import {Flex} from "../../../../../newShared/components/Layouts";
import colors from "../../../../../newShared/theme/colors";
import {IconButton, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export const CategoryFieldsContainer = ({margin = '18px 0 0 0', disableRequiredFieldWarning = false, children, onDelete, _key}: {
    margin?: string,
    disableRequiredFieldWarning?: boolean,
    children: React.ReactNode | React.ReactNode[],
    onDelete?: (_key: string) => void | undefined,
    _key?: string
}) => {
    return(
        <Flex
            m={margin}
            maxw={'920px'}
            w={'100%'}
            br={'8px'}
            background={colors.backgrounds.grey_light}
            border={`1px solid ${colors.text.grey_light_2}`}
            p={'20px'}
            direction={'column'}
        >
            {!disableRequiredFieldWarning && <Flex m={'0 0 20px 0'} jc={'space-between'} ai={'center'}>
                <Typography color={colors.text.grey} fontSize={'13px'} fontWeight={400}>
                    <span style={{color: colors.text.critical}}>*</span>
                    {'Required field'}
                </Typography>
                {onDelete && <IconButton size={'small'} onClick={() => onDelete(_key ?? '')} color={'error'}>
                    <DeleteIcon />
                </IconButton>}
            </Flex>}
            {children}
        </Flex>
    )
}