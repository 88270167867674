import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideReleaseDialog, loadings} from "../../store/slice";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {TReleaseDialogEmployee} from "../../../../../newShared/components/releaseDialog/types";
import {ReleaseEmployees} from "../../store/actions";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const useReleaseEmployees = () => {
    const dispatch = useDispatch();
    const {tCommon} = useMainTranslation();
    const {releaseEmployees} = useSelector(dialogs);
    const isLoading = useSelector(loadings).releaseEmployees;
    const {setMessage} = useMessageDialog();

    const handleClose = () => {
        dispatch(hideReleaseDialog());
    }

    const handleRelease = ({frameworkId, controlId, evidenceId, readers, reviewers, signers}: {
        frameworkId: string, controlId: string, evidenceId: string,
        reviewers: TReleaseDialogEmployee[], readers: TReleaseDialogEmployee[], signers: TReleaseDialogEmployee[]
    }) => {
        dispatch(
            ReleaseEmployees({
                data: {
                    workspaceId: '',
                    organizationId: '',
                    frameworkId,
                    controlId,
                    evidenceId,
                    readers,
                    reviewers,
                    signers,
                },
                onSuccess
            })
        );
    }

    const onSuccess = () => {
        setMessage({title: tCommon('Completed successfully'), message: tCommon('Automation created!')});
    }

    return {
        isOpen: releaseEmployees,
        handleClose,
        isLoadingRelease: isLoading,
        handleRelease,
    }
}