import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    builderInitResponseData,
    controlInputType,
    controlType,
    evidenceInputType,
    evidenceType,
    frameworkInputType,
    frameworkType,
    GET_CONTROLS_EVIDENCES_REQUEST_TYPES,
    RequestEvidencesWithFilterResponseType
} from "../../types";
import {
    addControl,
    addEvidence,
    addFramework,
    changeFrameworkName,
    changeFrameworkVisibility,
    deattachControl,
    deattachEvidence,
    deleteControl,
    deleteEvidence,
    deleteFramework,
    editControl,
    editEvidence,
    getAllControlsDialogData,
    getAllEvidecnesDialogData,
    getBuilderData,
    getControls,
    getPrivateControlsDialogData,
    getPrivateEvidencesDialogData,
    getPublicAndPrivateControlsDialogData,
    getPublicAndPrivateEvidencesDialogData,
    getPublicControlsDialogData,
    getPublicEvidencesDialogData,
    getRegulaitControlsDialogData,
    getRegulaitEvidencesDialogData,
    linkControl,
    linkEvidence,
    updateCustomFramework
} from "../../api";
import {
    recalculateFrameworkWhenControlLinkedOrUnlinked,
    recalculateFrameworkWhenEvidenceLinkedOrUnlinked
} from "../../helpers";
import {AppState} from "../../../../../newShared/redux/rootReducer";
import {getActionsData} from "../../../../../newShared/redux";
import {addSuccessfulSnack} from "../../../../barsEnvironment/snack/store/slice";
import {TWithOptions} from "../../../../../newShared/types";
import {EditControlInput} from "../../../../../newShared/GQLTypes";

const defaultResponse: builderInitResponseData = {
    frameworks: [],
    allControlsCounter: 0,
    allEvidencesCounter: 0,
    allPoliciesCounter: 0,
};

export const GetBuilderData = createAsyncThunk(
    'Builder/GetBuilderData',
    async (signal: AbortSignal, {getState}) => {
        const state = getState() as AppState;
        const {organizationId} = getActionsData(state);
        try {
            return await getBuilderData(organizationId, signal);
        } catch (ex) {
            return defaultResponse;
        }
    }
);

export const UpdateCustomFramework = createAsyncThunk(
    'Builder/updateCustomFramework',
    async (data: {framework: frameworkType}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {organizationId, workspaceId} = getActionsData(state);
        const message = await updateCustomFramework({organizationId, workspaceId, framework: data.framework});
        dispatch(addSuccessfulSnack(message));
        return data;
    }
);

export const ChangeFrameworkName = createAsyncThunk(
    'Builder/changeFrameworkName',
    async (data: {frameworkId: string, name: string}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {organizationId} = getActionsData(state);
        const message = await changeFrameworkName({organizationId, data});
        dispatch(addSuccessfulSnack(message));
        return data;
    }
);

//changeFrameworkVisibility

export const ChangeFrameworkVisibility = createAsyncThunk(
    'Builder/changeFrameworkVisibility',
    async (data: {frameworkId: string, visibility: string}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {organizationId} = getActionsData(state);
        const message = await changeFrameworkVisibility({organizationId, data});
        dispatch(addSuccessfulSnack(message));
        return data;
    }
);

//deleteFramework
export const DeleteFramework = createAsyncThunk(
    'Builder/deleteFramework',
    async ({data, onSuccess}: {data: string, onSuccess: Function}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {organizationId} = getActionsData(state);
        await deleteFramework({organizationId, data});
        // dispatch(addSuccessfulSnack(message));
        onSuccess();
        return data;
    }
);

//getControls

export const GetControls = createAsyncThunk(
    'Builder/getControls',
    async ({data, signal}: TWithOptions<{ frameworkId: string, controlId?: string, onReject?: () => void }>, {getState}) => {
        const state = getState() as AppState;
        const {organizationId} = getActionsData(state);
        try {
            const resp = await getControls({organizationId, data: data.frameworkId}, signal);
            return {
                controls: resp,
                frameworkId: data.frameworkId,
                controlId: data.controlId,
            }
        } catch (ex: any) {
            data.onReject && data.onReject();
            throw new Error('error'); //just rejecting (error threw in axios rejection) /shared/utils/axios
        }
    }
);

//addControl

export const AddControl = createAsyncThunk(
    'Builder/addControl',
    async (data: {control: controlInputType, onSuccess?: (id: string) => void}, {getState}) => {
        const state = getState() as AppState;
        const {organizationId} = getActionsData(state);
        try {
            const control = await addControl({organizationId, data: data.control});
            if (control && control.id && data.onSuccess) data.onSuccess(control.id);
            return{
                control,
                frameworkId: data.control.frameworkId,
            }
        } catch (ex: any) {
            // data.onReject && data.onReject();
            throw new Error(''); //just rejecting (error threw in axios rejection) /shared/utils/axios
        }
    }
);

//editControl

export const EditControl = createAsyncThunk(
    'Builder/editControl',
    async (data: {control: EditControlInput}, {getState}) => {
        const state = getState() as AppState;
        const {organizationId} = getActionsData(state);
        try {
            return await editControl({organizationId, data: data.control});
        } catch (ex: any) {
            // data.onReject && data.onReject();
            throw new Error(''); //just rejecting (error threw in axios rejection) /shared/utils/axios
        }
    }
);

//deattachControl

export const DeattachControl = createAsyncThunk(
    'Builder/deattachControl',
    async (data: {controlId: string, frameworkId: string, onSuccess?: () => void}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {organizationId} = getActionsData(state);
        const {Builder: {controls, frameworks}} = state;
        try {
            const message = await deattachControl({organizationId, data: {controlId: data.controlId, frameworkId: data.frameworkId}});
            // if(data.controlId === selectedFramework && data.onSuccess) data.onSuccess();
            dispatch(addSuccessfulSnack(message));
            return {
                controlId: data.controlId,
                framework: recalculateFrameworkWhenControlLinkedOrUnlinked(frameworks.find(e => e.id === data.frameworkId), controls, 'UNLINK', undefined, data.controlId)

        };
        } catch (ex: any) {
            // data.onReject && data.onReject();
            throw new Error(''); //just rejecting (error threw in axios rejection) /shared/utils/axios
        }
    }
);

//addEvidence
export const AddEvidence = createAsyncThunk(
    'Builder/addEvidence',
    async (data: {evidence: evidenceInputType, frameworkId?: string, isEvidencesPage: boolean}, {getState}) => {
        const state = getState() as AppState;
        const {organizationId} = getActionsData(state);
        try {
            const evidence = await addEvidence({organizationId, data: data.evidence});
            return{
                evidence,
                controlId: data.evidence.controlId,
                frameworkId: data.frameworkId,
                isEvidencesPage: data.isEvidencesPage,
            }
        } catch (ex: any) {
            // data.onReject && data.onReject();
            throw new Error(''); //just rejecting (error threw in axios rejection) /shared/utils/axios
        }
    }
);

//editEvidence
export const EditEvidence = createAsyncThunk(
    'Builder/editEvidence',
    async (data: {evidence: evidenceType, isEvidencesPage: boolean}, {getState}) => {
        // console.log('actions is ev page', data.isEvidencesPage);
        const state = getState() as AppState;
        const {organizationId} = getActionsData(state);
        try {
            return {
                evidence: await editEvidence({organizationId, data: data.evidence}),
                isEvidencesPage: data.isEvidencesPage,
            };
        } catch (ex: any) {
            // data.onReject && data.onReject();
            throw new Error(''); //just rejecting (error threw in axios rejection) /shared/utils/axios
        }
    }
);

//deattachEvidence
export const DeattachEvidence = createAsyncThunk(
    'Builder/deattachEvidence',
    async (data: {controlId: string, evidenceId: string, frameworkId?: string}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {organizationId} = getActionsData(state);
        const {Builder: {controls,frameworks}} = state;
        try {
            const message = await deattachEvidence({organizationId, data});
            dispatch(addSuccessfulSnack(message));
            return {
                ...data,
                framework: data.frameworkId ? recalculateFrameworkWhenEvidenceLinkedOrUnlinked(
                    controls,
                    'UNLINK',
                    data.controlId,
                    frameworks.find(e => e.id === data.frameworkId),
                    undefined,
                    data.evidenceId
                ): null
            };
        } catch (ex: any) {
            // data.onReject && data.onReject();
            throw new Error(''); //just rejecting (error threw in axios rejection) /shared/utils/axios
        }
    }
);

//getControlsWithFilter
export const GetControlsWithFilter = createAsyncThunk(
    'Builder/getControlsWithFilter',
    async ({data, signal}: TWithOptions<{ page: number, count: number, name: string, type: GET_CONTROLS_EVIDENCES_REQUEST_TYPES}>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {organizationId} = getActionsData(state);

        let result;

        switch (data.type) {
            case "ALL": result = await getAllControlsDialogData({organizationId, data: {page: data.page, count: data.count}, name: data.name}, signal); break;
            case "REGULAIT": result = await getRegulaitControlsDialogData({organizationId, data: {page: data.page, count: data.count}, name: data.name}, signal); break;
            case "PRIVATE": result = await getPrivateControlsDialogData({organizationId, data: {page: data.page, count: data.count}, name: data.name}, signal); break;
            case "PUBLIC": result = await getPublicControlsDialogData({organizationId, data: {page: data.page, count: data.count}, name: data.name}, signal); break;
            default: throw new Error('GetControlsWithFilter: Invalid type specified');
        }

        return {
            result,
            data
        }
    }
);

//linkControl
export const LinkControl = createAsyncThunk(
    'Builder/linkControl',
    async (data: {frameworkId: string, controlId: string}, {getState, dispatch}): Promise<{ control: controlType, framework: frameworkType }> => {
        const state = getState() as AppState;
        const {organizationId} = getActionsData(state);
        const {Builder: {controls, frameworks}} = state;
        const control = await linkControl({organizationId, data});
        return {
            control,
            framework: recalculateFrameworkWhenControlLinkedOrUnlinked(frameworks.find(e => e.id === data.frameworkId), controls, 'LINK', control)
        }
    }
);

//getAllEvidecnesDialogData

//getControlsWithFilter
export const GetEvidencesWithFilter = createAsyncThunk(
    'Builder/GetEvidencesWithFilter',
    async ({data, signal}: TWithOptions<{ page: number, count: number, name: string, type: GET_CONTROLS_EVIDENCES_REQUEST_TYPES}>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {organizationId} = getActionsData(state);

        let result;

        switch(data.type){
            case "ALL": result = await getAllEvidecnesDialogData({organizationId, data: {page: data.page, count: data.count}, name: data.name}, signal); break;
            case "REGULAIT": result = await getRegulaitEvidencesDialogData({organizationId, data: {page: data.page, count: data.count}, name: data.name}, signal); break;
            case "PRIVATE": result = await getPrivateEvidencesDialogData({organizationId, data: {page: data.page, count: data.count}, name: data.name}, signal); break;
            case "PUBLIC": result = await getPublicEvidencesDialogData({organizationId, data: {page: data.page, count: data.count}, name: data.name}, signal); break;
            default: throw new Error('GetEvidencesWithFilter: Invalid type specified');
        }

        return {
            result,
            data
        }
    }
);

export const LinkEvidence = createAsyncThunk(
    'Builder/linkEvidence',
    async (data: {evidenceId: string, controlId: string, frameworkId?: string}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {organizationId} = getActionsData(state);
        const {Builder: {controls, frameworks}} = state;

        const evidence = await linkEvidence({organizationId, data});
        return {
            controlId: data.controlId,
            evidence,
            framework: data.frameworkId ?  recalculateFrameworkWhenEvidenceLinkedOrUnlinked(
                controls,
                'LINK',
                data.controlId,
                frameworks.find(e => e.id === data.frameworkId),
                evidence,
            ) : null
        }
    }
);

//addFramework

export const AddFramework = createAsyncThunk(
    'Builder/addFramework',
    async (data: {framework: frameworkInputType, onSuccess?: (id: string) => void}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {organizationId} = getActionsData(state);
        const addedFrame =  await addFramework({organizationId, data: data.framework});
        if(addedFrame && data.onSuccess) data.onSuccess(addedFrame.id);
        return addedFrame;
    }
);

export const GetPrivateControls = createAsyncThunk(
    'Builder/GetPrivateControls',
    async ({data, signal}: TWithOptions<{ page: number, count: number}>, {getState}) => {
        // console.log(`Builder/GetPrivateControls ${data.page} - - ${data.count}`);
        const state = getState() as AppState;
        const {organizationId} = getActionsData(state);
        return await getPublicAndPrivateControlsDialogData({organizationId, data: {page: data.page, count: data.count}, name: ''}, signal);
    }
);

export const GetPrivateEvidences = createAsyncThunk(
    'Builder/GetPrivateEvidences',
    async ({data, signal}: TWithOptions<{ page: number, count: number}>, {getState, dispatch}): Promise<RequestEvidencesWithFilterResponseType> => {
        const state = getState() as AppState;
        const {organizationId} = getActionsData(state);
        return await getPublicAndPrivateEvidencesDialogData({organizationId, data: {page: data.page, count: data.count}, name: ''}, signal);
    }
);


//deleteControl

export const DeleteControl = createAsyncThunk(
    'Builder/deleteControl',
    async (data: string, {getState, dispatch})=> {
        const state = getState() as AppState;
        const {organizationId} = getActionsData(state);
        const mes = await deleteControl({organizationId, data});
        dispatch(addSuccessfulSnack(mes));
        return data;
    }
);

//deleteEvidence

export const DeleteEvidence = createAsyncThunk(
    'Builder/deleteEvidence',
    async (data: string, {getState, dispatch})=> {
        const state = getState() as AppState;
        const {organizationId} = getActionsData(state);
        const mes = await deleteEvidence({organizationId, data});
        dispatch(addSuccessfulSnack(mes));
        return data;
    }
);


// //getPrivatePolicies
// export const GetPrivatePolicies = createAsyncThunk(
//     'Builder/getPrivatePolicies',
//     async ({data, signal}: TWithOptions<{ page: number, count: number}>, {getState, dispatch}): Promise<RequestPoliciesWithFilterResponseType> => {
//         const state = getState() as AppState;
//         const {organizationId} = getActionsData(state);
//         return await getPrivatePolicies(organizationId, data.page, data.count, signal);
//     }
// );
//
// // addPolicy
//
// export const AddPolicy = createAsyncThunk(
//     'Builder/addPolicy',
//     async (data: policyInputType, {getState, dispatch}): Promise<policyType> => {
//         const state = getState() as AppState;
//         const {organizationId} = getActionsData(state);
//         return await addPolicy(organizationId, data);
//     }
// );
//
// //updatePolicy
//
// export const UpdatePolicy = createAsyncThunk(
//     'Builder/updatePolicy',
//     async (data: policyType, {getState, dispatch}): Promise<policyType> => {
//         const state = getState() as AppState;
//         const {organizationId} = getActionsData(state);
//         return await updatePolicy(organizationId, data);
//     }
// );
//
// //deletePolicy
//
// export const DeletePolicy = createAsyncThunk(
//     'Builder/deletePolicy',
//     async (data: string, {getState, dispatch})=> {
//         const state = getState() as AppState;
//         const {organizationId} = getActionsData(state);
//         const mes =  await deletePolicy(organizationId, data);
//         dispatch(addInfoSnack(mes));
//         return data;
//     }
// );
//
//
// //uploadFile
// export const UploadFile = createAsyncThunk(
//     'Builder/UploadFile',
//     async (data: {file: string, fileName: string}, {getState}): Promise<{ fileId: string, fileName: string, lastUpdated: string }> => {
//         const state = getState() as AppState;
//         const {organizationId} = getActionsData(state);
//         const fileId =  await uploadFile(data.file, organizationId);
//         // const fileId = data.fileName + 'WITHSOMETESTID';
//         // console.log('FILE UPLOADED: ', data.fileName);
//         return {
//             fileId,
//             fileName: data.fileName,
//             lastUpdated: getIsoDateTimeNow(),
//         }
//     }
// );
//
// export const DeleteAllFile = createAsyncThunk(
//     'Builder/DeleteAllFiles',
//     async (data: {fileIds: string[], autoId: string}, {getState, dispatch}): Promise<{fileIds: string[], autoId: string }> => {
//         const state = getState() as AppState;
//         const {organizationId} = getActionsData(state);
//         await deleteAllFiles(data.fileIds, organizationId);
//         // console.log('DELETE FILES: ', data.fileIds, ' AUTOID: ', data.autoId);
//         if(data.autoId.length > 0) dispatch(addInfoSnack('Warning: All files in documents stage were deleted!'));
//         return data;
//     }
// );
