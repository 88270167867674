import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    AUTOMATIONS_CREATE_PATH,
    AUTOMATIONS_DEFAULT_PAGING,
    AUTOMATIONS_EXACT_PATH,
    AUTOMATIONS_LIST_PATH,
    PATH_LOCAL_AUTOMATIONS
} from "../../constants";
import {PaginationInput} from "../../../../../newShared/GQLTypes";
import {GetShortAutomations, RunAutomation} from "../../store/actions";
import {
    automations,
    cleanUp,
    loadings,
    minMaxLoadedPage,
    openDeletePipeline,
    pageInfoSelector,
    replaceMinMaxLoadedPage,
    setRunAutomationLoading
} from "../../store/slice";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {shortAutomation} from "../../types";

export const useAutomationsList = () => {
    //root
    const history = useHistory();
    const dispatch = useDispatch();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    //selectors
    const {getList, runAutomation, deleteAutomation} = useSelector(loadings);
    const _pageInfo = useSelector(pageInfoSelector);
    const _autos = useSelector(automations);
    const _minMaxLoadedPage = useSelector(minMaxLoadedPage);
    const controller = new AbortController();

    //bread
    useSetBreadcrumbs([
        {
            title: tMenu('Automations'),
            path: PATH_LOCAL_AUTOMATIONS
        },
        {
            title: tMenu('Created automations'),
            path: AUTOMATIONS_LIST_PATH
        }
    ]);

    //useEffect
    // useEffect(() => {
    //     // getData({page: 0, count: AUTOMATIONS_DEFAULT_PAGING})
    //     return function cleanUpAutomations(){
    //         dispatch(cleanUp());
    //         controller.abort();
    //     }
    //     //eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    //actions
    const getData = (pageInfo: PaginationInput) => {
        dispatch(GetShortAutomations({data: {pageInfo}, signal: controller.signal}));
    }

    const handleGoToCreateAutomation = () => {
        history.push(AUTOMATIONS_CREATE_PATH);
    }

    const handleLoadMore = (page: number) => {
        // console.log(`LAZY ${page} vs ${_pageInfo.page + 1} AND LOAD : ${_isLoadingData}`);
        !getList && getData({page: _pageInfo.page + 1, count: AUTOMATIONS_DEFAULT_PAGING});
    }

    return{
        isLoading: getList,
        allAutomations: _autos,
        handleGoToCreateAutomation,
        handleLoadMore,
        hasMore: _pageInfo.total > ((_pageInfo.page + 1) * _pageInfo.count),
        isShowNoAutomations: _autos.length === 0 && !getList,
        paging: {
            pageInfo: _pageInfo,
            isLoading: getList,
            minLoadedPage: _minMaxLoadedPage.minLoadedPage,
            maxLoadedPage: _minMaxLoadedPage.maxLoadedPage,
            setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceMinMaxLoadedPage(data)),
            clearRows: () => dispatch(cleanUp()),
            onPageDestroy: () => dispatch(cleanUp()),
            fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                getData({page, count})
            }
        },
        onRowClick: (row: shortAutomation) => history.push(AUTOMATIONS_EXACT_PATH.replace(':autoId', row.id)),
        isLoadingRun: runAutomation,
        isLoadingDelete: deleteAutomation,
        handleRunAuto: (row: shortAutomation) => {
            dispatch(setRunAutomationLoading(row.id));
            dispatch(RunAutomation(row.id));
        },
        handleDeleteAuto: (row: shortAutomation) => {
            dispatch(openDeletePipeline(row.id));
        }
    }
}