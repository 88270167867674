import {Flex} from "../../../../../../../../newShared/components/Layouts";
import {Collapse, Icon, IconButton, styled, Tooltip, tooltipClasses, TooltipProps, Typography} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import colors from "../../../../../../../../newShared/theme/colors";
import {TypographyNew} from "../../../../../../../../newShared/components/TypographyNew";
import Divider from "@mui/material/Divider";
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import React, {FC, PropsWithChildren} from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {DragSourceMonitor, useDrag, useDrop} from "react-dnd";
import {FieldItem} from "../../../../../../kycRefactor/types";
import {MainTrainingContentType, TrainingContentNewModel} from "../../../../../../../../newShared/GQLTypes";

type TrainingCollapsableSectionPropsType = {
    isOpen: boolean,
    toggleOpen: (section: TrainingContentNewModel) => void,
    section: TrainingContentNewModel,
    handleDeleteSection: (section: TrainingContentNewModel) => void,
    handleEditSection: (section: TrainingContentNewModel) => void,
    // children: ReactNode | ReactNode[],
    sameNameSectionExists: boolean,
    moveSection: (key: string, atIndex: number) => void,
    findSection: (key: string) => {section: TrainingContentNewModel, index: number} | null,
}
export const TrainingCollapsableSection: FC<PropsWithChildren<TrainingCollapsableSectionPropsType>> = ({toggleOpen, isOpen, section, handleEditSection, handleDeleteSection, children, sameNameSectionExists, moveSection, findSection}) => {
    const searchedField = findSection(section.sectionId);

    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: 'section',
            item: {key: section.sectionId, index: searchedField?.index ?? -1},
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const { key: droppedKey, index: originalIndex } = item;

                // console.log(`ROW useDrag END with item ${JSON.stringify(item)}`);
                const didDrop = monitor.didDrop()
                if (!didDrop && item) {
                    moveSection(droppedKey, originalIndex);
                    // console.log(`ROW useDrag - moveColumn exec: ${droppedKey} | ${originalIndex}`);
                }
            },
        }),
        [section.sectionId, searchedField, moveSection],
    )

    const [{canDrop}, drop] = useDrop(
        () => ({
            accept: 'section',
            hover({ key: draggedKey }: FieldItem) {
                // console.log(`Card useDrop draggedKey:${draggedKey} in cardId ${column.key}`);
                if (draggedKey !== section.sectionId) {
                    const col = findSection(section.sectionId)
                    if(col){
                        // console.log(`ROW useDrop - moveColumn exec: ${draggedKey} | ${col.index}`);
                        moveSection(draggedKey, col.index);
                    }else{
                        // console.log(`ROW useDrop - col not found by key ${column.key}`)
                    }
                }
            },
            collect: (monitor) => ({
                canDrop: monitor.canDrop(),
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
                isActive: monitor.canDrop() && monitor.isOver(),
            }),
        }),
        [findSection, moveSection],
    )

    const opacity = isDragging ? 0 : 1

    return(
        <Flex w={'100%'} direction={'column'} ref={drop} opacity={opacity.toString()}>
            <Flex
                ref={preview}
                w={'100%'}
                gap={'16px'}
                ai={'center'}
                jc={'space-between'}
                background={colors.backgrounds.grey_light}
                border={`1px solid ${colors.stroke.grey}`}
                br={isOpen ? '4px 4px 0 0' : '4px'}
                p={'8px 8px'}
                overflow={'hidden'}
            >
                <Flex ai={'center'} overflow={'hidden'}>
                    <IconButton onClick={() => toggleOpen(section)} size={'small'}>
                        {isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon/>}
                    </IconButton>

                    <TypographyNew variant={'*bodyText1_semibold'} color={colors.grayText} noWrap>{section.name}</TypographyNew>

                    {sameNameSectionExists &&
                        <ToolTip title={'Current training already has section with same name. Enter unique section name for this training'}>
                            <ErrorOutlineOutlinedIcon sx={{color: colors.warningActions.red, margin: '0 4px'}}/>
                        </ToolTip>
                    }
                </Flex>

                <Flex ai={'center'} gap={'8px'}>
                    <Divider orientation={'vertical'} flexItem/>

                    {(section.type === MainTrainingContentType.VideoLink || section.type === MainTrainingContentType.VideoFile) &&
                        <Flex ai={'center'} gap={'8px'} m={'0 8px'}>
                            <SlowMotionVideoIcon fontSize={'small'} sx={{color: colors.text.grey_dark}}/>
                            <TypographyNew variant={'*bodyText2'} color={colors.grayText} noWrap>{('Video')}</TypographyNew>
                        </Flex>
                    }

                    {section.type === MainTrainingContentType.File &&
                        <Flex ai={'center'} gap={'8px'} m={'0 8px'}>
                            <PictureInPictureIcon fontSize={'small'} sx={{color: colors.text.grey_dark}}/>
                            <TypographyNew variant={'*bodyText2'} color={colors.grayText} noWrap>{'File'}</TypographyNew>
                        </Flex>
                    }

                    {section.type === MainTrainingContentType.Editor &&
                        <Flex ai={'center'} gap={'8px'} m={'0 8px'}>
                            <TextFieldsIcon fontSize={'small'} sx={{color: colors.text.grey_dark}}/>
                            <TypographyNew variant={'*bodyText2'} color={colors.grayText} noWrap>{'Editor'}</TypographyNew>
                        </Flex>
                    }

                    <Divider orientation={'vertical'} flexItem/>

                    <IconButton onClick={() => handleEditSection(section)} size={'small'}>
                        <EditIcon sx={{color: colors.grayText}}/>
                    </IconButton>

                    <Divider orientation={'vertical'} flexItem/>

                    <IconButton onClick={() => handleDeleteSection(section)} size={'small'}>
                        <DeleteOutlineIcon sx={{color: colors.grayText}}/>
                    </IconButton>

                    <Divider orientation={'vertical'} flexItem/>

                    <Icon ref={drag}>
                        <DragIndicatorIcon fontSize={'small'} sx={{color: colors.grayText}}/>
                    </Icon>
                </Flex>
            </Flex>

            <Collapse in={isOpen && !canDrop}>
                <Flex w={'100%'}
                      p={'15px 20px'}
                      br={'0 0 4px 4px'}
                      border={`1px solid ${colors.stroke.grey}`}
                      style={{borderTop: 'none'}}
                      direction={'column'}
                >
                    <Flex w={'100%'} jc={'space-between'} gap={'16px'}>
                        <Flex>
                            <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark} noWrap>{'Section name:'}</Typography>
                        </Flex>

                        <Flex grow={'1'}>
                            <Typography variant={'*bodyText2'} color={colors.grayText}>{section.name}</Typography>
                        </Flex>
                    </Flex>

                    <Divider sx={{margin: '20px 0'}}/>

                    {children}
                </Flex>
            </Collapse>
        </Flex>
    )
}

export const ToolTip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({

    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: colors.backgrounds.grey_light,
        borderRadius: '6px',
        padding: '8px 16px',
        color: colors.text.dark,
        fontWeight: 400,
        fontSize: '14px',
        marginTop: '0px !important',
    },

    [`& .${tooltipClasses.arrow}`]: {
        color: colors.backgrounds.grey_light,
    }
}));
