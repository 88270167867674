import {Flex} from "../../../../../newShared/components/Layouts"
import {Typography} from "@mui/material";
import React from "react";
import {useTargetsMain} from "../../hooks/useTargetsMain";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {smallTarget} from "../../types";
import {parseTargetStatus} from "../../helpers";

export const VulnerabilityScannerMain = () => {
    const {
        targets,
        handleGoToAdd,
        goToId,
        genericTable
    } = useTargetsMain();
    const {isMobile} = useMedia();
    const {t} = useMainTranslation('', {keyPrefix: 'vulnerabilityScanner'});

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('Scanned items')}
                actions={[
                    {
                        onClick: handleGoToAdd,
                        title: t('Add new target'),
                        size: 'small'
                    }
                ]}
            />

            <GenericTable<smallTarget>
                id={'VulnerabilityScannerMain'}
                columnsConfig={{totalName: 'Total targets', disableColumnsSettings: true, forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: targets,
                    customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id,
                    onRowClick: goToId,
                    columnsConfigs: [
                        {key: "name", name: t('Name'), default: true},
                        {key: 'target', name: t('Target'), default: true},
                        {key: 'status', name: t('Status'), default: true, valueRenderer: (row) => {
                                const {color, text} = parseTargetStatus(row.status);
                                return <Typography variant={'*bodyText2'} color={color}>{text}</Typography>
                            }},
                        {key: 'lastUpdate', name: t('Last status changed'), default: true, valueGetter: (row) => parseDateAuto(row.statusChangedDate, true)},
                    ],
                }}
                filtersConfig={{
                    searchCustomFilterKeyName: 'name',
                    searchAsArray: true,
                    dontSendSearchIfEmpty: true,
                    genericFilterProps: {
                        configs: genericTable.filters.configs,
                        isAddFilters: false,
                        fetchResultWithSelectedFilters: () => null
                    }
                }}
                emptyArrayImageProps={{type: 'vulnerability', filterNotApplied: {secondaryText: 'Add target', text: ('Targets will appear here'), onClick: handleGoToAdd}}}
            />

            {/*<EmptyArrayImage*/}
            {/*    type={'vulnerability'}*/}
            {/*    isShowing={!isLoadingTargets && !targets.length && !lastSearch && filter === 'All'}*/}
            {/*    withoutAdd*/}
            {/*    text={t('Create a target')}*/}
            {/*    secondaryText={t('You have not accepted any targets yet. Click to the Add new target select and it will appear here.')}*/}
            {/*/>*/}

            {/*{!(!isLoadingTargets && !targets.length && !lastSearch && filter === 'All') &&*/}
            {/*    <>*/}
            {/*        <S.FilterContainer>*/}
            {/*            <CustomSearchField*/}
            {/*                placeholder={t('Search')}*/}
            {/*                variant={'outlined'}*/}
            {/*                sx={{width: 320}}*/}
            {/*                onChange={(e) => setSearch(e.currentTarget.value)}*/}
            {/*                value={search}*/}
            {/*                onClear={handleCleanSearch}*/}
            {/*                size={'small'}*/}
            {/*                disabled={isLoadingTargets}*/}
            {/*                onKeyDown={handleEnterKey}*/}
            {/*            />*/}
            {/*            /!*<StatusFilter*!/*/}
            {/*            /!*    values={[]}*!/*/}
            {/*            /!*    selected={''}*!/*/}
            {/*            /!*    onSelect={() => null}*!/*/}
                        {/*/>*/}
            {/*            {!isMobile &&*/}
            {/*                <TextFieldCustom*/}
            {/*                    id="outlined-select-currency"*/}
            {/*                    select*/}
            {/*                    disabled={isLoadingTargets}*/}
            {/*                    sx={{*/}
            {/*                        width: 320,*/}
            {/*                        '& div div': {*/}
            {/*                            padding: !revDir ? '8px 32px 9px 13px !important' : '8px 13px 9px 32px !important',*/}
            {/*                        }*/}
            {/*                    }}*/}
            {/*                    label={t("Select status")}*/}
            {/*                    value={filter}*/}
            {/*                    onChange={(e) => handleStatusFilterChange(e.target.value)}*/}
            {/*                >*/}
            {/*                    {values.map((option) => (*/}
            {/*                        <MenuItem key={option} value={option}>*/}
            {/*                            {t(option)}*/}
            {/*                        </MenuItem>*/}
            {/*                    ))}*/}
            {/*                </TextFieldCustom>*/}
            {/*            }*/}
            {/*        </S.FilterContainer>*/}

            {/*        <TableHeader />*/}

            {/*        {lastSearch && !isLoadingTargets && !targets.length && <Typography color={colors.darkBlueText}>{('Nothing was found for your query. Try changing the query.')}</Typography>}*/}

            {/*        <S.MainTableScrollWrapper>*/}
            {/*            <InfiniteScroll*/}
            {/*                pageStart={0}*/}
            {/*                loadMore={(page) => handleLoadMore(page)}*/}
            {/*                hasMore={hasMore}*/}
            {/*                initialLoad={false}*/}
            {/*                threshold={50}*/}
            {/*                useWindow={false}*/}
            {/*            >*/}
            {/*                {targets.map(e => <TargetTableRow target={e} key={e.id} onClick={goToId}/>)}*/}
            {/*                {isLoadingTargets && <TargetTableRowSkeleton />}*/}
            {/*            </InfiniteScroll>*/}
            {/*        </S.MainTableScrollWrapper>*/}
            {/*    </>*/}
            {/*}*/}
        </Flex>
    )
}