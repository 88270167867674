import {openReleaseDialog} from "../../../store/slice";
import {useDispatch} from "react-redux";
import React, {useState} from "react";

type TResponse = {
    handleRelease: () => void;
    handleExportCSV: () => void;
    handleImportCsv: () => void;
    menu: {
        anchorEl: null | HTMLElement;
        handleClose: () => void;
        handleClick: (event: React.MouseEvent<HTMLElement>) => void;
        open: boolean;
    },
}
export const useCommonDialogs = (): TResponse => {
    const dispatch = useDispatch();

    //MENU
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        };

    const handleRelease = () => dispatch(openReleaseDialog());

    const handleImportCsv = () => {
        handleClose();
        // dispatch(openImportCsv());
    }
    const handleExportCSV = () => {
        handleClose();
        // dispatch(ExportCsvEmployees());
    }


    return {
        handleRelease,
        handleExportCSV,
        handleImportCsv,
        menu: {
            anchorEl,
            handleClose,
            handleClick,
            open,
        },
    }

}