import {TAppDuty, TShortApplications} from "../../../types";
import {useDispatch} from "react-redux";
import {hideEditDuty} from "../../../store/slice";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {useSegregationOfDuties} from "../../useSegregationOfDuties";
import {updateAppDutyAction} from "../../../store/actions";
import {useEffect} from "react";

export const useEditDutyDialog = () => {
    const dispatch = useDispatch();

    const {
        appDuties,
        shortRoles,
        categories,
        shortApplications,
        loadings: {update: isLoadingUpdate, dataForDialog: isLoadingDialogData},
        dialogs: {editDuty: {isOpen, selectedDuty}},
        getRoleNamesByAppAccessId,
    } = useSegregationOfDuties();

    const handleClose = () => {
        dispatch(hideEditDuty());
    }


    //form
    const {form, setForm, handleChange} = useForm<TAppDuty | null>(selectedDuty);

    const handleSetCategory = (category: string) => {
        setForm(prev => prev && ({...prev, category}));
    }
    //TextField
    // const handleSetCategory = (e:React.ChangeEvent<HTMLInputElement>) => {
    //     setForm({...form, category: e.target.value});
    // }

    const handleSetRole = (roles: readonly string[]) => {
        // const roles: DutyRoleModel[] = Array.from(new Set(dutyRoles));
        setForm(prev => prev && ({...prev, roles: roles.map(e => ({role: e, permissions: null})) }));
    }

    const handleSetApplication = (app: TShortApplications) => {
        app && setForm(prev => prev && {...prev, application: app, roles: app.applicationAccessId !== prev.application.applicationAccessId ? [] : prev.roles});
        getRoleNamesByAppAccessId(app.applicationAccessId);
    }

    const handleSubmit = () => {
        if(isOk() && form){
            dispatch(updateAppDutyAction({duty: form, organizationId: '', workspaceId: ''}));
            handleClose();
        }
    }

    const isAlreadyExists = selectedDuty?.name !== form?.name && appDuties.find(e => e?.id === form?.application.applicationAccessId)?.duties.some(e => e.name === form?.name);

    const isOk = () => {
        return form && form.name.trim().length > 0 && form.category.length > 0 && form.application.applicationAccessId.trim().length > 0 && !isAlreadyExists && !!form.roles.length
            &&  JSON.stringify(selectedDuty) !== JSON.stringify(form);
    }

    useEffect(() => {
        if(isOpen && selectedDuty){
            setForm(selectedDuty);
            getRoleNamesByAppAccessId(selectedDuty.application.applicationAccessId);
        }
        //eslint-disable-next-line
    }, [isOpen]);

    return{
        isOpen,
        shortApplications,
        categories,
        rolesVariants: shortRoles,
        handleClose,
        handleSubmit,
        handleSetCategory,
        handleSetApplication,
        handleSetRole,

        handleChange,
        form,
        setForm,
        isOk: isOk(),
        isAlreadyExists,

        isLoadingUpdate,
        isLoadingDialogData
    }
}