import {EditIntegrationCommonPropsType, GworkspaceIntergationDetailsType} from "../../../../types";
import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import {useForm} from "../../../../../../../newShared/hooks/useForm";
import {GWORKSPACE_ENDPOINT_MAPPING_NAME} from "../../../../constants";
import {validateEmail} from "../../../../../../../newShared/utils/text";
import {UpdateIntegration} from "../../../../store/actions";
import {loadings} from "../../../../store/slice";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {addErrorSnack} from "../../../../../../barsEnvironment/snack/store/slice";

export const useEditGworkspace = (props: EditIntegrationCommonPropsType) => {
    const dispatch = useDispatch();
    const {workspaceId} = UseManageWorkspacesAndOrganizations();
    const {updateIntegration} = useSelector(loadings);

    const [fileName, setFileName] = useState<String>("");
    const {form, setForm, handleChange} = useForm<GworkspaceIntergationDetailsType>({
        applicationId: props.details.applicationId,
        workspaceId: workspaceId ?? '',
        status: '',
        integration: {
            credentials: '',
            adminEmail: '',
        },
        identity: props.details.identity,
        hris: props.details.hris,
        endpointMappingName: GWORKSPACE_ENDPOINT_MAPPING_NAME,
        detailsId: props.details.detailsId,
        //additional:
        name: props.details.name,
    });
    const handleChangeIntegration = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm(prev => ({...prev, integration: {...prev.integration, [e.target.name]: e.target.value}}));
    }

    const isOk = form.integration.credentials.length > 0 && form.name.trim().length > 0 && validateEmail(form.integration.adminEmail);

    const handleSubmit = () => {
        if(isOk){
            dispatch(UpdateIntegration({integrationDetails: form, name: form.name, detailsId: form.detailsId}));
        }
    }

    //other
    const checkIfJsonHasAllKeys = (obj: any) => {
        return ("auth_provider_x509_cert_url" in obj &&
            "auth_uri" in obj &&
            "client_email" in obj &&
            "client_id" in obj &&
            "client_x509_cert_url" in obj &&
            "private_key" in obj &&
            "private_key_id" in obj &&
            "project_id" in obj &&
            "token_uri" in obj &&
            "type" in obj);
    }

    const checkIfJsonAllValuesExists = (obj: any) => {
        return (
            obj.auth_uri?.trim().length > 0 &&
            obj.client_email?.trim().length > 0 &&
            obj.client_id?.trim().length > 0 &&
            obj.client_x509_cert_url?.trim().length > 0 &&
            obj.private_key?.trim().length > 0 &&
            obj.private_key_id?.trim().length > 0 &&
            obj.project_id?.trim().length > 0 &&
            obj.token_uri?.trim().length > 0 &&
            obj.type?.trim().length > 0
        )
    }

    const onChangeFileInput = (e: any) => {
        const fileReader = new FileReader();
        const prevFileName = fileName;
        if(e!.currentTarget.files){
            try{
                fileReader.readAsText(e!.currentTarget?.files[0], "UTF-8");
                setFileName(e!.currentTarget.files[0].name || "");
            }catch (ex){
                return;
            }
        }else{
            return;
        }
        fileReader.onload = (onLoad) => {
            // console.log('file: ', onLoad?.target?.result);
            const json = onLoad?.target?.result as string;
            // console.log(`JOSN: ${json}`);
            if(json.length){
                const obj = JSON.parse(json);
                // console.log(`CHEKS: ${checkIfJsonHasAllKeys(obj)} - - - ${checkIfJsonAllValuesExists(obj)}`);
                if(checkIfJsonHasAllKeys(obj) && checkIfJsonAllValuesExists(obj)){
                    setForm({...form, integration: {...form.integration, credentials: json}});
                }else{
                    dispatch(addErrorSnack('File is not valid!'));
                    setFileName(prevFileName);
                }
            }
        };
    }

    //
    const ref = React.createRef<HTMLInputElement>();
    const filePicker = () => {
        ref.current && ref.current.click();
    };

    return{
        form,
        isOk,
        ref,
        actions: {
            handleSubmit,
            onChangeFileInput,
            handleChange,
            handleChangeIntegration,
            filePicker
        },
        fileName,
        isLoading: updateIntegration
    }
}