import {
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    styled,
    SxProps,
    TextField,
    Theme
} from '@mui/material'
import {Visibility, VisibilityOff} from '@mui/icons-material'
import React, {ChangeEvent, FC, useState} from 'react'


export const CustomInput = styled(TextField)``
CustomInput.defaultProps = {
    variant: 'outlined',
    fullWidth: true,
}

export const TextArea = styled(TextField)``;
TextArea.defaultProps = {
    multiline: true,
    fullWidth: true,
}


type TPasswordField = {
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    name?: string,
    label?: string,
    m?: string,
    value?: string,
    onKeyDown?:  React.KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined,
    sx?:  SxProps<Theme> | undefined,
    error?: boolean,
    onFocus?: () => void,
    onBlur?: () => void,
    nonrequired?: boolean,
}

export const PasswordField: FC<TPasswordField> = React.forwardRef((props, ref) => {
    const [isVisible, changeVisibility] = useState<boolean>(false);

    const changeVisiblePassword = () => {
        changeVisibility(prevState => !prevState);
    }

    return (
        <FormControl variant={'outlined'} sx={{direction: 'ltr', margin: props.m ?? '0'}} required={!props.nonrequired}>
            <InputLabel htmlFor="filled-adornment-password">{props.label ?? 'Password'}</InputLabel>
            <OutlinedInput
                {...props}
                ref={ref}
                id="filled-adornment-password"
                type={isVisible ? 'text' : 'password'}
                onChange={props.onChange}
                label={props.label ?? 'Password'}
                name={props.name ?? 'password'}
                // value={props.value}

                error={props.error}
                onFocus={props.onFocus}
                onBlur={props.onBlur}

                endAdornment={
                    <InputAdornment position={'end'}>
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={changeVisiblePassword}
                            onMouseDown={changeVisiblePassword}
                            edge="end"
                        >
                            {isVisible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    )
})