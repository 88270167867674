import {useDispatch, useSelector} from "react-redux";
import {dialogs, folders, foldersPath, hideAddFolderDialog, isLoading, selectedFolder} from "../../../store/slice";
import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {findFolderById} from "../../../helpers";
import {CreateFolder} from "../../../store/actions";
import {CustomInput} from "../../../../../../newShared/components/Inputs";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const AddFolderDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});
    const dispatch = useDispatch();
    const isOpen = useSelector(dialogs).addFolderDialog;
    const isLoadingAddFolder = useSelector(isLoading).isLoadingAddFolder;
    const path = useSelector(foldersPath);
    const _folders = useSelector(folders);
    const _selectedFolder = useSelector(selectedFolder);

    const [name, setName] = useState<string>('');

    const handleClose = () => {
        dispatch(hideAddFolderDialog());
        setName('');
    }

    const isOk = () => {
        if(path.length > 0 && _selectedFolder){
            const folder = findFolderById(path[0].id, _folders);
            if(!folder) return false;
            return name.trim().length > 0 && !folder.children.some(e => e.name.trim().toLowerCase() === name.trim().toLowerCase());
        }
        return false;
    }

    const submit = () => {
        if(isOk()){
            //_selectedFolder checked in isOk()
            dispatch(CreateFolder({name, fatherId: _selectedFolder!.id}));
            setName('');
        }
    }

    return(
        <Dialog onClose={handleClose} open={isOpen} fullWidth maxWidth={'sm'}>
            <DialogTitle>{t('Create folder in')} {path.length > 0 ? path[0].name : ``}</DialogTitle>

            <DialogContent>
                <CustomInput
                    sx={{margin: '5px 0 0 0'}}
                    value={name}
                    onChange={(e) => {setName(e.currentTarget.value as string)}}
                    label={t('Folder name')}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={"text"} disabled={isLoadingAddFolder}>{t('Cancel')}</Button>
                <LoadingButton loading={isLoadingAddFolder} onClick={submit} disabled={!isOk()}>{t('Create')}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}