import React from "react";
import {useReleasePhysicalAssets} from "../../../hooks/useReleaseDialog";
import {ReleaseDialog} from "../../../../../../newShared/components/releaseDialog";

export const ReleasePhysicalAssets = () => {
    const {
        isOpen,
        handleClose,
        isLoadingRelease,
        handleRelease,
    } = useReleasePhysicalAssets();

    return (
        <ReleaseDialog isOpen={isOpen} handleClose={handleClose} isSubmittingLoading={isLoadingRelease}
                       handleSubmit={({evidence, review, sign, read}) => {
                           handleRelease({
                               ...evidence,
                               readers: read,
                               reviewers: review,
                               signers: sign
                           })
                       }}
        />
    )
}