import {useDispatch, useSelector} from "react-redux";
import {MainHrTimeOffRequestModel} from "../../../../../../newShared/GQLTypes";
import {dialogs, setTimeOffHistoryDialogAction,} from "../../../store/slice";


export const useHrTimeOffHistory = () => {

    const dispatch = useDispatch();
    const {timeOffHistory: {isOpen, selected}} = useSelector(dialogs);

    const handleBack = () => {
        dispatch(setTimeOffHistoryDialogAction({clear: true}))
    }

    const handleOpenTimeOffHistory = (selected: MainHrTimeOffRequestModel) => {
        dispatch(setTimeOffHistoryDialogAction({isOpen: true, selected}))
    }

    return {
        isOpen,
        selected,
        handleBack,
        handleOpenTimeOffHistory,
    }

}
