import {gql} from "@apollo/react-hooks";
import {NewDocumentFragment} from "./fragments";

export const getDocumentById = gql`
    query getDocumentById($fileId: String!, $workspaceId: String!) {
        getDocumentById(fileId: $fileId, workspaceId: $workspaceId) {
            id
            workspaceId
            documentId
            status
            origin
            description
            type
            name
            folderId
            version
            latest
            parentId
            visible
            lastEditDate
            editorId
            creatorId
            createdDate
            automationId
            tags
            accessRules {
                rule
            }
            data {
                editor
                innerHtml
                config {
                    page
                }
                variables {
                    type
                    title
                    key
                    order
                    value
                    format
                    description
                }
            }
        }
    }`

export const setDocumentAsEvidenceAsset = gql`
    mutation setDocumentAsEvidenceAsset($documentId: String!, $workspaceId: String!, $evidenceId: String!) {
        setDocumentAsEvidenceAsset(documentId: $documentId, workspaceId: $workspaceId, evidenceId: $evidenceId) {
            message
        }
    }
`

export const getNewTemplateById = gql`
    query getNewTemplateById($workspaceId:String!, $fileId:String!){
        getNewTemplateById(workspaceId:$workspaceId, fileId:$fileId){
            ...NewDocumentFragment
        }
    }
    ${NewDocumentFragment}
`