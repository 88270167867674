import {createTemplateRequestType, TClientStatus, TScreeningStatus} from "./types";
import {ACCEPT_IMAGE_PROP} from "../../../newShared/constants";

export const KYC_ROOT_PATH = '/kyc'

export const KYC_SCREENING_LIST_PATH = `${KYC_ROOT_PATH}/screening`;
export const KYC_SCREENING_EXACT_PATH = `${KYC_ROOT_PATH}/screening/:id`;
export const KYC_MATCHES_LIST_PATH = `${KYC_ROOT_PATH}/matches`;
export const KYC_REQUESTS_LIST_PATH = `${KYC_ROOT_PATH}/requests`;
export const KYC_REQUESTS_EXACT_PATH = `${KYC_ROOT_PATH}/requests/exact/:id`;

export const KYC_TEMPLATES_LIST_PATH = `${KYC_ROOT_PATH}/templates`;
export const KYC_TEMPLATES_CREATE_PATH = `${KYC_ROOT_PATH}/templates/create`;
export const KYC_TEMPLATES_EXACT_PATH = `${KYC_ROOT_PATH}/templates/exact/:id`;
export const KYC_TEMPLATES_CREATE_LIST_PATH = `${KYC_ROOT_PATH}/templates/create`;
export const KYC_CLIENTS_LIST_PATH = `${KYC_ROOT_PATH}/clients/:type`;
// export const KYC_CLIENTS_ID_PATH = `${KYC_ROOT_PATH}/clients/:type/:id`;
// export const KYC_CLIENTS_CORPORATE_LIST_PATH = `${KYC_ROOT_PATH}/clients/:type/corporate`;
// export const KYC_CLIENTS_CORPORATE_ID_PATH = `${KYC_ROOT_PATH}/clients/corporate/:id`;

export const KYC_CLIENTS_ARCHIVE_LIST_PATH = `${KYC_ROOT_PATH}/clients/archive`;

export const KYC_CLIENTS_ID_PATH = `${KYC_ROOT_PATH}/clients/:type/exact/:id`;
export const KYC_CLIENTS_ID_FIELDS_PATH = `${KYC_ROOT_PATH}/clients/:type/exact/:id/fields`;
export const KYC_CLIENTS_ID_SCREENINGS_PATH = `${KYC_ROOT_PATH}/clients/:type/exact/:id/screenings`;
export const KYC_CLIENTS_ID_RESOLUTIONS_PATH = `${KYC_ROOT_PATH}/clients/:type/exact/:id/resolutions`;
export const KYC_CLIENTS_ID_HISTORY_PATH = `${KYC_ROOT_PATH}/clients/:type/exact/:id/history`;

export const INDIVIDUAL_CLIENTS_PAGE_TYPE = 'individual';
export const CORPORATE_CLIENTS_PAGE_TYPE = 'corporate';
export const CRYPTOWALLET_CLIENTS_PAGE_TYPE = 'cryptowallet';
export const ARCHIVE_CLIENTS_PAGE_TYPE = 'archive';

export const SAVE_COLUMNS_INDIVIDUAL_CONFIG_PREFIX = 'COLUMN_INDIVIDUAL_CONFIG_';
export const SAVE_COLUMNS_CORPORATE_CONFIG_PREFIX = 'COLUMN_CORPORATE_CONFIG_';
export const SAVE_COLUMNS_ARCHIVE_CONFIG_PREFIX = 'COLUMN_ARCHIVE_CONFIG_';
export const SAVE_COLUMNS_CRYPTOWALLET_CONFIG_PREFIX = 'COLUMN_CRYPTOWALLET_CONFIG_';

export const TEMPLATES_DEFAULT_PAGING = 50;
export const REQUESTS_DEFAULT_PAGING = 50;
export const EXCEPTIONS_DEFAULT_PAGING = 50;
export const MATCHES_DEFAULT_PAGING = 50;
export const TEMPLATE_CSV_PAGING = 50;
export const SCREENING_RESULTS_PAGING = 20;

export const SCREENING_MIN_VALUE = 0;
export const SCREENING_MAX_VALUE = 365;


export const CLIENTS_DEFAULT_PAGING = 50;
export const SCREENINGS_DEFAULT_PAGING = 50;


export const FILE_FIELD_TYPE = 'FILE';

export const INPUT_CLIENT_DATA_FILE_ACCEPT = ACCEPT_IMAGE_PROP;

export const PERSON_TARGET_TYPE = 'INDIVIDUAL';
export const CORPORATE_TARGET_TYPE = 'CORPORATE';
export const CRYPTOWALLET_TARGET_TYPE = 'CRYPTOWALLET';

export const initialCreateTemplateForm: createTemplateRequestType = {
    id: '',
    description: '',
    name: '',
    screeningSettings: {
        repeat: 7,
        checks: []
    },
    fields: [],
    type: PERSON_TARGET_TYPE,
    nextScreeningDate: null,
    lastScreeningDate: null
}


export const FIRST_NAME_FIELD_ALIAS_NAME = 'firstName';
export const LAST_NAME_FIELD_ALIAS_NAME = 'lastName';
export const PASSPORT_NUMBER_FIELD_ALIAS_NAME = 'passportNumber';
export const EMAIL_FIELD_ALIAS_NAME = 'email';
export const PHONE_FIELD_ALIAS_NAME = 'phone';
export const COMPANY_NAME_FIELD_ALIAS_NAME = 'name';
export const COMPANY_ID_FIELD_ALIAS_NAME = 'companyId';
export const CRYPTO_WALLET_PUBLIC_KEY_ALIAS_NAME = 'publicKey';

export const SYSTEM_FIELDS_CORPORATE_SEQ = [
    //company
    COMPANY_NAME_FIELD_ALIAS_NAME,
    'legalForm',
    'incorporationDate',
    'registrationNumber',
    'taxNumber',
    'mainCountry',
    'jurisdiction',
    'website',
    PHONE_FIELD_ALIAS_NAME,
    EMAIL_FIELD_ALIAS_NAME,
    'address',
    'country',
    'previousName',
    'alias',
    'ticker',
    'keywords',
    'wikipediaUrl',
    'wikidataId',
    'sourceUrl',
    'publisher',
    'publisherUrl',
    'sector',
    'classification',
    'vatCode',
    'taxStatus',
    'status',
    'opencorporatesUrl',
    'bvdId',
    'icijId',
    'okpoCode',
    'innCode',
    'ogrnCode',
    'leiCode',
    'dunsCode',
    'swiftBic',
    'voenCode',
    'coatoCode',
    'cikCode',
    'jibCode',
    'mbsCode',
    'ibcRuc',
    'caemCode',
    'kppCode',
    'okvedCode',
    'okopfCode',
    'fnsCode',
    'fssCode',
    'bikCode',
    'pfrNumber',
    'oksmCode',
    'isinCode',
]

export const SYSTEM_FIELDS_INDIVIDUAL_SEQ = [
    COMPANY_NAME_FIELD_ALIAS_NAME,
    'title',
    FIRST_NAME_FIELD_ALIAS_NAME,
    'secondName',
    'middleName',
    'fatherName',
    'lastName',
    LAST_NAME_FIELD_ALIAS_NAME,
    'nameSuffix',
    EMAIL_FIELD_ALIAS_NAME,
    PHONE_FIELD_ALIAS_NAME,
    'idNumber',
    'birthDate',
    'birthPlace',
    'birthCountry',
    'nationality',
    'passportNumber',
    'gender',
    'address',
    'ethnicity',
    'education',
    'taxNumber',
    'vatCode',
    'position',
    'religion',
    'political',
    'previousName',
    'alias',
    'country',
    'motherName',
    'keywords'
]

export const TARGET_CREATED_STATUS = 'CREATED';
export const TARGET_BLACKLIST_STATUS = 'BLACK_LIST';
export const TARGET_VERIFIED_STATUS = 'VERIFIED';
export const TARGET_MATCHED_STATUS = 'MATCHED';
export const TARGET_MODIFIED_STATUS = 'MODIFIED';


//clients / screening statuses

export const IN_PROGRESS_STATUS = 'IN_PROGRESS';
export const COMPLETE_STATUS = 'COMPLETE';
export const FAILED_STATUS = 'FAILED';
export const PENDING_STATUS = 'PENDING';
export const CREATED_STATUS = 'CREATED'; //client
export const MATCHED_STATUS = 'MATCHED'; //client
export const VERIFIED_STATUS = 'VERIFIED'; //client
export const BLACK_LIST_STATUS = 'BLACK_LIST'; //client


//Risk
export const TARGET_NONE_RISK = 'NONE';
export const TARGET_LOW_RISK = 'LOW';
export const TARGET_MEDIUM_RISK = 'MEDIUM';
export const TARGET_HIGH_RISK = 'HIGH';






export const ScreeningStatusVariants: {status: TScreeningStatus, title: string}[] = [
    {status: IN_PROGRESS_STATUS, title: 'In progress'},
    {status: COMPLETE_STATUS, title: 'Complete'},
    {status: FAILED_STATUS, title: 'Failed'},
    {status: PENDING_STATUS, title: 'Pending'},
];


export const ClientsStatusVariants: {status: TClientStatus, title: string}[] = [
    {status: CREATED_STATUS, title: 'Created'},
    {status: MATCHED_STATUS, title: 'Matched'},
    {status: VERIFIED_STATUS, title: 'Verified'},
    {status: BLACK_LIST_STATUS, title: 'Black List'},
];


export const DAY_IN_MILLISECONDS = 86400000;

export const topicsDictionary: {key: string, value: string}[] = [
    {key: "crime", value: "Crime"},
    {key: "crime.fraud", value: "Fraud"},
    {key: "crime.cyber", value: "Cybercrime"},
    {key: "crime.fin", value: "Financial crime"},
    {key: "crime.theft", value: "Theft"},
    {key: "crime.war", value: "War crimes"},
    {key: "crime.boss", value: "Criminal leadership"},
    {key: "crime.terror", value: "Terrorism"},
    {key: "crime.traffick", value: "Trafficking"},
    {key: "crime.traffick.drug", value: "Drug trafficking"},
    {key: "crime.traffick.human", value: "Human trafficking"},
    {key: "corp.offshore", value: "Offshore"},
    {key: "corp.shell", value: "Shell company"},
    {key: "gov", value: "Government"},
    {key: "gov.national", value: "National government"},
    {key: "gov.state", value: "State government"},
    {key: "gov.muni", value: "Municipal government"},
    {key: "gov.soe", value: "State-owned enterprise"},
    {key: "gov.igo", value: "Intergovernmental organization"},
    {key: "fin", value: "Financial services"},
    {key: "fin.bank", value: "Bank"},
    {key: "fin.fund", value: "Fund"},
    {key: "fin.adivsor", value: "Financial advisor"},
    {key: "role.pep", value: "Political"},
    {key: "role.rca", value: "Close Associate"},
    {key: "role.judge", value: "Judge"},
    {key: "role.civil", value: "Civil servant"},
    {key: "role.diplo", value: "Diplomat"},
    {key: "role.lawyer", value: "Lawyer"},
    {key: "role.acct", value: "Accountant"},
    {key: "role.spy", value: "Spy"},
    {key: "role.oligarch", value: "Oligarch"},
    {key: "role.journo", value: "Journalist"},
    {key: "role.act", value: "Activist"},
    {key: "pol.party", value: "Political party"},
    {key: "pol.union", value: "Union"},
    {key: "rel", value: "Religion"},
    {key: "mil", value: "Military"},
    {key: "asset.frozen", value: "Frozen asset"},
    {key: "sanction", value: "Sanctioned entity"},
    {key: "sanction.linked", value: "Sanction-linked entity"},
    {key: "debarment", value: "Debarred entity"},
    {key: "poi", value: "Person of interest"}
]

export const KYC_DATE_FORMAT = 'YYYY-MM-DD';
export const KYC_DATE_WITH_TIME_FORMAT = 'YYYY-MM-DD LT'; 