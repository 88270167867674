import {useDeleteRowDialog} from "../../../hooks/dialogHooks/useDeleteRowDialog";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import colors from "../../../../../../newShared/theme/colors";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const DeleteRowDialog = () => {
    const {isOpen, row, handleSubmit, handleClose} = useDeleteRowDialog();
    const {isMobile} = useMedia();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.AccessManagementAppsExact.dialogs'});

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Delete row')}</DialogTitle>

            <DialogContent>
                <Typography variant={'body2'} color={colors.darkBlueText}>
                    {t('If you delete {{account}}, you will not be able to restore it.', {account: row?.account ?? ''})}
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{t('Cancel')}</Button>
                <Button
                    variant={'contained'}
                    size={isMobile ? 'small' : 'medium'}
                    onClick={handleSubmit}
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                >{t('Delete')}</Button>
            </DialogActions>
        </Dialog>
    )
}