import {
    cleanAction,
    cleanAnalysisAction,
    cleanCommentsAction,
    frameworkControlAnalysisSelector,
    frameworkControlCommentsSelector,
    frameworkControlControlSelector,
    frameworkControlEvidencesSelector,
    frameworkControlFrameworkSelector,
    frameworkControlIsLoadingSelector,
    TFrameworkControlSlice
} from "../../store/control/store";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED} from "../../../../../newShared/constants";
import {
    PATH_FRAGMENT_ANALYSIS,
    PATH_FRAGMENT_COMMENTS,
    PATH_FRAGMENT_DELEGATED_TASKS,
    PATH_FRAGMENT_EVIDENCES
} from "../../constants";
import {PaginationInput} from "../../../../../newShared/GQLTypes";
import {useFrameworksPathParams} from "../useFrameworksPathParams";
import {
    addCommentAction,
    addControlAssignmentAction,
    deleteControlAssignmentAction,
    downloadCollectorAssetPdfAction,
    downloadEvidenceFileAction,
    getAnalysisHistoryByHistoryIdAction,
    getAnalysisHistoryFRAction,
    getAutoAssetDataAction,
    getCommentsAction,
    getControlAssignmentsAction,
    getControlByIdFrameworkIdAction,
    getStatementByIdAction,
    removeEvidenceAssetAction,
    setEvidenceStatusAction,
    uploadEvidenceAssetAction
} from "../../store/control/actions";
import {TAnalysis, TEvidence} from "../../types";
import {getName} from "../../../../../newShared/utils/text";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";

type TResponse = {
    framework: TFrameworkControlSlice["framework"],
    control: TFrameworkControlSlice["control"],

    evidences: TFrameworkControlSlice["evidences"],
    comments: TFrameworkControlSlice["comments"],
    analysis: TFrameworkControlSlice["analysis"],

    isLoading: TFrameworkControlSlice["isLoading"],

    goToEvidences: (data: {frameworkId?: string, controlId?: string, evidenceId?: string}, hard?: boolean) => void,
    goToComments: (data: {frameworkId?: string, controlId?: string}, hard?: boolean) => void,
    goToAnalysis: (data: {frameworkId?: string, controlId?: string, analysis?: {analysisId: string, historyId: string}}, hard?: boolean) => void,
    goToDelegatedTasks: (data: {frameworkId?: string, controlId?: string}, hard?: boolean) => void,

    getData: (data: {frameworkId: string, controlId: string}, signal: AbortSignal) => void,
    getComments: (data: {frameworkId: string, controlId: string, pageInfo: PaginationInput}, stopSignal: AbortSignal) => void,
    getAnalysis: (data: {frameworkId: string, controlId: string, pageInfo: PaginationInput}, stopSignal: AbortSignal) => void,

    setEvidenceStatus: (data: {evidenceId: string, status: string}, stopSignal: AbortSignal) => void,

    addComment: (data: {frameworkId: string, controlId: string, authorId: string, text: string}) => void,
    getAnalysisHistory: (analyseId: string, historyId: string) => void,

    cleanComments: () => void,
    cleanAnalysis: () => void,

    evidencesOpen: boolean,
    commentsOpen: boolean,
    analysisOpen: boolean,
    delegatedTasksOpen: boolean,

    downloadFile: (fileId: string, fileName: string) => void,
    downloadTable: (assetId: string) => void,
    getStatement: (assetId: string) => void,
    getCollectorData: (assetId: string, pageInfo: PaginationInput, signal?: AbortSignal) => void,

    deleteAsset: (assetId: string) => void,
    uploadFileAsset: (file: string, name: string, evidenceId: string) => void,
    uploadStatement: (text: string, evidenceId: string) => void,

    getAssignments: (data: {frameworkId: string, controlId: string, text: string}) => void,
    addAssignment: (data: {frameworkId: string, controlId: string, userId: string}) => void,
    removeAssignment: (assignmentId: string) => void,

    currentEvidence: TEvidence | null,
    currentAnalysis: TAnalysis | null,

    clean: () => void,
}
export const useControl = (): TResponse => {
    const dispatch = useDispatch();
    const {location: {pathname}, push, replace} = useHistory();
    const {frId: frIdPath, controlId: controlIdPath, evidenceId} = useFrameworksPathParams();
    const {t} = useMainTranslation('', {keyPrefix: 'pathFrameworks'});
    const {workspaceId, user} = UseManageWorkspacesAndOrganizations();
    const {setMessage} = useMessageDialog();

    const framework = useSelector(frameworkControlFrameworkSelector);
    const control = useSelector(frameworkControlControlSelector);
    const evidences = useSelector(frameworkControlEvidencesSelector);
    const comments = useSelector(frameworkControlCommentsSelector);
    const analysis = useSelector(frameworkControlAnalysisSelector);

    const isLoading = useSelector(frameworkControlIsLoadingSelector);

    const goToEvidences: TResponse["goToEvidences"] = ({frameworkId, controlId, evidenceId}, hard) => {
        !hard
            ? push(`${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/${frameworkId || frIdPath}/control/${controlId || controlIdPath}/${PATH_FRAGMENT_EVIDENCES}${evidenceId ? '/'+evidenceId : ''}`)
            : replace(`${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/${frameworkId || frIdPath}/control/${controlId || controlIdPath}/${PATH_FRAGMENT_EVIDENCES}${evidenceId ? '/'+evidenceId : ''}`)
    }
    const goToComments: TResponse["goToComments"] = ({frameworkId, controlId}) => {
        push(`${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/${frameworkId || frIdPath}/control/${controlId || controlIdPath}/${PATH_FRAGMENT_COMMENTS}`)
    }
    const goToAnalysis: TResponse["goToAnalysis"] = ({frameworkId, controlId, analysis}) => {
        push(`${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/${frameworkId || frIdPath}/control/${controlId || controlIdPath}/${PATH_FRAGMENT_ANALYSIS}${analysis ? `/${analysis.analysisId}/${analysis.historyId}` : ''}`)
    }

    const goToDelegatedTasks: TResponse["goToDelegatedTasks"] = ({frameworkId, controlId}) => {
        push(`${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/${frameworkId || frIdPath}/control/${controlId || controlIdPath}/${PATH_FRAGMENT_DELEGATED_TASKS}`)
    }

    const getData: TResponse["getData"] = (data, signal) => {dispatch(getControlByIdFrameworkIdAction(
        {data: {workspaceId: '', organizationId: '', language: '', ...data}, signal}
    ))};
    const getComments: TResponse["getComments"] = (data, stopSignal) => {dispatch(getCommentsAction(
        {data: {workspaceId: '', ...data}, signal: stopSignal}
    ))};
    const getAnalysis: TResponse["getAnalysis"] = (data, stopSignal) => {dispatch(getAnalysisHistoryFRAction(
        {data: {workspaceId: '', organizationId: '', ...data}, signal: stopSignal}
    ))};

    const setEvidenceStatus: TResponse["setEvidenceStatus"] = (data) => {dispatch(setEvidenceStatusAction(
        {data: {workspaceId: '', organizationId: '', ...data}}
    ))};

    const addComment: TResponse["addComment"] = (data) => {dispatch(addCommentAction({
        data: {workspaceId: '', ...data,},
        onSuccess: () => {
            setMessage({message: t('A comment has been added.'), title: t('Completed successfully')});
        }
    }))};
    const getAnalysisHistory: TResponse["getAnalysisHistory"] = (analyseId, historyId) => {
        dispatch(getAnalysisHistoryByHistoryIdAction({data: {workspaceId: '', organizationId: '', historyId, analyseId}}))
    };

    const cleanComments: TResponse["cleanComments"] = () => {dispatch(cleanCommentsAction())};
    const cleanAnalysis: TResponse["cleanAnalysis"] = () => {dispatch(cleanAnalysisAction())};

    const downloadFile: TResponse["downloadFile"] = (fileId, fileName) => {dispatch(downloadEvidenceFileAction({data: {workspaceId: '', fileId, fileName}}))};
    const downloadTable: TResponse["downloadTable"] = (assetId) => {dispatch(downloadCollectorAssetPdfAction({data: {assetId}}))};
    const deleteAsset: TResponse["deleteAsset"] = (assetId) => {dispatch(removeEvidenceAssetAction({data: {workspaceId: '', organizationId: '', assetId}}))};
    const uploadFileAsset: TResponse["uploadFileAsset"] = (file, name, evidenceId) => {workspaceId && dispatch(uploadEvidenceAssetAction({
        data: {workspaceId: '', organizationId: '', data: {
                data: [{file}],
                evidenceId,
                name: name,
                type: 'FILE',
                workspaceId,
            }},
    }))};
    const uploadStatement: TResponse["uploadStatement"] = (text, evidenceId) => {workspaceId && user && dispatch(uploadEvidenceAssetAction({
        data: {workspaceId: '', organizationId: '', data: {
                data: [{
                    text,
                    name: `${getName(user.firstName, user.lastName)} (${user.email})`,
                    author: `${getName(user.firstName, user.lastName)}`,
                    authorId: user.publicId,
                    email: user.email,
                }],
                evidenceId,
                name: `${getName(user.firstName, user.lastName)} (${user.email})`,
                type: 'CUSTOM',
                workspaceId,
            }},
        onSuccess: () => {
            setMessage({message: t('New statement has been added.'), title: t('Completed successfully')});
        }
    }))};
    const getStatement: TResponse["getStatement"] = (id) => {dispatch(getStatementByIdAction({data:{id}}))};
    const getCollectorData: TResponse["getCollectorData"] = (assetId, pageInfo, signal) => {dispatch(getAutoAssetDataAction({data: {workspaceId: '', assetId, data: pageInfo}, signal}))};

    const getAssignments: TResponse["getAssignments"] = (data) => {dispatch(getControlAssignmentsAction({data: {...data, workspaceId: '', pageInfo: {page: 0, count: 999999}}}))}
    const addAssignment: TResponse["addAssignment"] = (data) => {dispatch(addControlAssignmentAction({data: {...data, workspaceId: ''}}))};
    const removeAssignment: TResponse["removeAssignment"] = (assignmentId) => {dispatch(deleteControlAssignmentAction({data: {assignmentId, workspaceId: ''}}))};

    const evidencesOpen = pathname.startsWith(PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED) && pathname.includes(PATH_FRAGMENT_EVIDENCES);
    const commentsOpen = pathname.startsWith(PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED) && pathname.includes(PATH_FRAGMENT_COMMENTS);
    const analysisOpen = pathname.startsWith(PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED) && pathname.includes(PATH_FRAGMENT_ANALYSIS);
    const delegatedTasksOpen = pathname.startsWith(PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED) && pathname.includes(PATH_FRAGMENT_DELEGATED_TASKS);

    const clean: TResponse["clean"] = () => {dispatch(cleanAction())}
    return {
        framework,
        control,

        evidences,
        comments,
        analysis,

        isLoading,

        goToEvidences,
        goToComments,
        goToAnalysis,
        goToDelegatedTasks,

        getData,

        getComments,
        addComment,
        cleanComments,

        getAnalysis,
        getAnalysisHistory,
        cleanAnalysis,

        evidencesOpen,
        commentsOpen,
        analysisOpen,
        delegatedTasksOpen,

        downloadFile,
        downloadTable,
        getStatement,
        getCollectorData,

        deleteAsset,
        uploadStatement,
        uploadFileAsset,

        setEvidenceStatus,

        getAssignments,
        addAssignment,
        removeAssignment,

        currentEvidence: evidences.evidences.find(e => e.id === evidenceId) || null,
        currentAnalysis: analysis.selected,

        clean
    }
}
