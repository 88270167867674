import {Handle, Position} from "react-flow-renderer";
import {useDispatch} from "react-redux";
import {deleteStageActor} from '../../../store/slice';
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {Typo} from "../../../../../../newShared/components/Typography";

type emailNodeProps = {
    email: string;
    stageId: number;
    revDir: boolean;
}

export const EmailNode = ({data} : {data: emailNodeProps}) => {
    const {revDir} = data;
    const dispatch = useDispatch();

    const deleteSelf = () => {dispatch(deleteStageActor({stageId: data.stageId, email: data.email}))};

    return(
        <>
            <Flex
                w={'160px'}
                h={'25px'}
                br={'11px'}
                background={'#E8E8E8'}
                box_shadow={'0px 0px 5.853034973144531px 0px rgba(0, 0, 0, 0.12)'}
                ai={'center'}
                jc={'center'}
                p={'5px'}
                className={'nodrag'}
                cursor={'pointer'}
            >
                <Handle
                    type="target"
                    position={!revDir ? Position.Left : Position.Right}
                    id="a"
                    isConnectable={false}
                    style={{opacity: '0'}}
                />

                <Typo fontWeight={400} fontSize={'12px'} align={'center'}>{data.email.length > 20 ? data.email.split('').slice(0, 20).join('') + '...' : data.email}</Typo>
            </Flex>

            <Flex style={{position: 'relative', bottom: '22px', left: !revDir ? '165px' : '-30px'}} className={'nodrag'}>
                <DeleteOutlinedIcon
                    style={{width: '20px', height: '20px', color: '#5D5D5D', cursor: 'pointer'}}
                    onClick={deleteSelf}
                />
            </Flex>
        </>
    )
}