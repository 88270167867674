import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import {useMainTranslation} from "../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useContactUsDialog} from "../hooks/useContactUsDialog";
import {LoadingButton} from "../../../../newShared/components/Basic/CommonButtons";
import colors from "../../../../newShared/theme/colors";
import {SUBJECTS} from "../constants";

export const ContactUsDialog = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'ContactUs'});
    const {
        isOpen,
        handleClose,
        formik,
        handleSend,
        isLoadingSend
    } = useContactUsDialog();
    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Contact us')}</DialogTitle>
            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '16px', justifyContent: 'space-between'}}>
                <Typography variant={'*bodyText1_semibold'} color={colors.text.dark}>{t('How can we help?')}</Typography>
                <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('Complete the form below and we will get to work on your request.')}</Typography>
                <TextField
                    required
                    select
                    label={t('Select a subject')}
                    size={'small'}
                    name={'subject'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.subject}
                    error={formik.touched.subject && Boolean(formik.errors.subject)}
                    helperText={formik.touched.subject && formik.errors.subject}
                    >
                    {SUBJECTS.map((item) => (
                        <MenuItem key={item} value={item} sx={{padding: '6px'}}>
                            {t(item)}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    size={'small'}
                    required
                    label={t('Title')}
                    name={'title'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                />
                <TextField
                    required
                    multiline
                    rows={4}
                    label={t('Describe your request')}
                    name={'message'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                    error={formik.touched.message && Boolean(formik.errors.message)}
                    helperText={formik.touched.message && formik.errors.message}
                />

            </DialogContent>
            <DialogActions>
                <Button variant={'text'}
                        size={'small'}
                        onClick={handleClose}>
                    {tCommon('Close')}
                </Button>
                <LoadingButton
                    variant={'contained'}
                    size={'small'}
                    onClick={handleSend}
                    loading={isLoadingSend}
                    disabled={!formik.isValid}
                >
                    {tCommon('Send')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}