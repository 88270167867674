import {useDispatch, useSelector} from "react-redux";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    SETTING_ROOT_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_CREATE_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH,
    SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH,
    SETTINGS_ORGANIZATIONS_PATH
} from "../../../constants";
import {
    openManagePermissionsDialog,
    selectedOrganization,
    selectedWorkspace,
    selectedWorkspaceData,
    workspacesLoadings
} from "../../../store/slice";
import {useHistory, useParams} from "react-router-dom";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {TWorkspaceRole} from "../../../types";
import {useEffect} from "react";
import {CreateRole, GetWorkspaceById, GetWorkspaceCollabs} from "../../../store/actions";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";

export const useCreateRole = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {orgId, wsId} = useParams<{orgId: string, wsId: string}>();
    const {setMessage} = useMessageDialog();

    //selectors
    const selectedWs = useSelector(selectedWorkspace);
    const selectedOrg = useSelector(selectedOrganization);
    const {workspaceRoles, availablePermissions} = useSelector(selectedWorkspaceData);
    const {addRole, getUsers} = useSelector(workspacesLoadings);

    //bread
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    useSetBreadcrumbs([
        {
            title: tMenu('Settings'),
            path: SETTING_ROOT_PATH
        },
        {
            title: tMenu('Organizations'),
            path: SETTINGS_ORGANIZATIONS_PATH
        },
        {
            title: (selectedOrg?.name ?? 'Loading...'),
            path: SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH.replace(':orgId', selectedOrg?.id ?? '-')
        },
        {
            title: tMenu('Workspaces'),
            path: SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH.replace(':orgId', selectedOrg?.id ?? '-')
        },
        {
            title: (selectedWs?.name ?? 'Loading...'),
            path: SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_PATH
                .replace(':orgId', selectedOrg?.id ?? '-')
                .replace(':wsId', selectedWs?.id ?? '-')
        },
        {
            title: ('Create role'),
            path: SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_CREATE_PATH
                .replace(':orgId', selectedOrg?.id ?? '-')
                .replace(':wsId', selectedWs?.id ?? '-')
        },
    ]);

    useEffect(() => {
        if (!selectedWs || !selectedOrg) {
            if (!orgId || !wsId) {
                handleGoBack();
            } else {
                dispatch(GetWorkspaceById({
                    workspaceId: wsId,
                    organizationId: orgId,
                    onReject: handleGoBack
                }));
            }
        }
        dispatch(GetWorkspaceCollabs({workspaceId: wsId, onReject: handleGoBack}));
        //eslint-disable-next-line
    }, []);


    const handleGoBack = () => {
        history.push(
            SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_PATH
                .replace(':orgId', selectedOrg?.id ?? '-')
                .replace(':wsId', selectedWs?.id ?? '-')
        )
    }

    //form
    const {form, handleChange, setForm} = useForm<TWorkspaceRole>({
        id: '',
        name: '',
        workspaceId: '',
        permissions: [],
        createDate: '',
        lastUpdateDate: '',
        origin: false,
        system: false
    });

    const isOk = form.name.trim().length > 0 && !workspaceRoles.some(e => e.name.trim().toLowerCase() === form.name.trim().toLowerCase()) && form.permissions.length > 0;

    const handleOpenManagePermissionDialog = () => {
        dispatch(openManagePermissionsDialog());
    }

    const selectPermission = (permission: string) => {
        setForm({
            ...form,
            permissions: [...form.permissions, permission]
        })
    }

    const selectAllPermission = (permissions: string[]) => {
        // console.log(`SELECT ALL: ${permissions}`);
        setForm({
            ...form,
            permissions: Array.from(new Set([...form.permissions, ...permissions]))
        })
    }

    const deselectAllPermissions = (permissions: string[]) => {
        setForm({
            ...form,
            permissions: form.permissions.filter(e => !permissions.some(p => p === e))
        })
    }
    const deselectPermission = (permission: string) => {
        setForm({
            ...form,
            permissions: form.permissions.filter(e => e !== permission)
        })
    }

    const onSuccess = () => {
        setMessage({
            title: 'Role created',
            message: `Role ${form.name} with ${form.permissions.length} permissions created!`
        });
        handleGoBack();
    }

    const handleSubmit = () => {
        if(isOk && selectedWs){
            dispatch(CreateRole({role: form, workspaceId: selectedWs.id, onSuccess}));
        }
    }

    return{
        handleGoBack,
        form,
        handleChange,
        selectedPermissions: form.permissions,
        isOk,
        isLoading: addRole,
        handleOpenManagePermissionDialog,
        availablePermissions,
        selectPermission,
        selectAllPermission,
        deselectAllPermissions,
        deselectPermission,
        handleSubmit,
        isManagePermissionButtonDisabled: getUsers,
    }
}