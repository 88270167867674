import {useMainTranslation} from "../../../../hooks/useMainTranslationHooks/useMainTranslation";
import {TEditorActorsConfigFromDB} from "../../types";
import * as S from './styled';
import {ButtonIcon, ButtonTitle, InviteButtonContainer} from './styled';

export type TEditorEditorsAvatarsProps = {
    actors: TEditorActorsConfigFromDB;
    handleOpenRecipientsApproversDialog: () => void;
    isMain: boolean;
}

export const EditorApproversAndRecipientsAvatars = ({actors, handleOpenRecipientsApproversDialog, isMain}: TEditorEditorsAvatarsProps) => {
    const {revDir} = useMainTranslation();



    return(
        <S.AvatarsContainer revDir={revDir}>
            <S.DocumentAvatarGroup onClick={handleOpenRecipientsApproversDialog} revDir={revDir} max={4} total={actors.recipients.filter(e => e.actor.email?.trim().length).length} >
                {actors.recipients.filter(e => e.actor.email.trim().length > 0).map((e, index) => (
                    <S.DocumentAvatar key={e.actor.email} index={index} name={`${e.actor.firstName || e.actor.email} ${e.actor.lastName || e.actor.email}`} alt={e.actor.email ?? ''} />
                ))}
            </S.DocumentAvatarGroup>

            {isMain &&
                <InviteButtonContainer onClick={handleOpenRecipientsApproversDialog}>
                    <ButtonIcon />
                    <ButtonTitle>{('Manage')}</ButtonTitle>
                </InviteButtonContainer>
            }
        </S.AvatarsContainer>
    )
}
