import {useDispatch} from "react-redux";
import {deselectExistingSection} from "../../store/slice";
import {SectionShortDataModel} from "../../../../../newShared/GQLTypes";

export const useSectionToConfirmRow = (section: SectionShortDataModel) => {
    const dispatch = useDispatch();
    const handleDeselectSection = () => {
        dispatch(deselectExistingSection(section));
    }

    return {
        handleDeselectSection
    }

}