import {useEditOrganizationDialog} from "../../../../hooks/organizations/dialogs/useEditOrganizationDialog";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React from "react";
import {IconTextButton, LoadingButton} from "../../../../../../../newShared/components/Basic/CommonButtons";
import {DialogContentWrapper, TextFieldCustom} from "../../../styled";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../../../../../newShared/components/Layouts";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {ACCEPT_IMAGE_PROP} from "../../../../../../../newShared/constants";

export const EditOrganization = () => {
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'pathSettings.dialogs'});
    const {
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        form,
        handleChange,
        isOk,
        setFile,
        filePicker,
        ref,
    } = useEditOrganizationDialog();

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Edit organization')}</DialogTitle>

            <DialogContent>
                <DialogContentWrapper>
                    <Flex w={'100%'} ai={'center'} m={'8px 0'}>
                        <img src={form.logo} alt={form.name} width={'70px'} height={'70px'}/>

                        <IconTextButton
                            variant={'text'}
                            icon={<FileUploadIcon fontSize={'small'}/>}
                            onClick={filePicker}
                            sx={{margin: !revDir ? '8px 0 0 8px' : '8px 8px 0 0'}}
                        >{t('Upload logo')}
                        </IconTextButton>
                    </Flex>

                    <input
                        style={{display: 'none'}}
                        id={"contained-button-file-createORg"}
                        type="file"
                        ref={ref}
                        accept={ACCEPT_IMAGE_PROP}
                        onChange={async e => {
                            await setFile(e.currentTarget.files![0]);
                        }}
                    />

                    <TextFieldCustom
                        required
                        value={form.name}
                        name={'name'}
                        onChange={handleChange}
                        placeholder={('Name')}
                        label={t('Organization name')}
                        size={'small'}
                        sx={{margin: '8px 0 0 0'}}
                    />
                </DialogContentWrapper>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                    disabled={!isOk}
                >
                    {t('Edit')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
