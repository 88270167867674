import {useDispatch, useSelector} from "react-redux";
import {dialogs, setImportCsvDialogAction} from "../../../store/slice";
import {ImportCsvRulesetAction, ImportCsvRulesetTemplateAction,} from "../../../store/actions";
import {TImportCsvDialogProps} from "../../../../../../newShared/components/csvImportDialog";
import {resetImportCsvDialog} from "../../../../../../newShared/components/csvImportDialog/constants";

export const useImportCsvRuleset = (): TImportCsvDialogProps => {
    const dispatch = useDispatch();
    const {importCsvDialog: {
        isOpen,
        isLoading: {
            isGettingFields,
            isPerformingCsv,
            isLoadingTemplate,
        },
        fields,
        // fileId,
        data,
        response,
    }} = useSelector(dialogs);

    const setImportDialog: TImportCsvDialogProps["setImportDialog"] = (data) => dispatch(setImportCsvDialogAction(data));
    const downloadTemplate = () => {data.applicationAccessId && dispatch(ImportCsvRulesetTemplateAction())};
    // const getFields: TImportCsvDialogProps["getFields"] = (file) => {dispatch(getCsvFieldsAccessManagementAction({
    //     data: {...file},
    //     onError: resetImportCsvDialog
    // }))};
    // const performCsv: TImportCsvDialogProps["performCsv"] = (fields) => {fileId && dispatch(performCsvAccessManagementAction({
    //     data: {fields, fileId},
    //     snack: "success",
    // }))};
    const getFields: TImportCsvDialogProps["getFields"] = (file) => {dispatch(ImportCsvRulesetAction({
        data: {file: file.file},
        onError: resetImportCsvDialog
    }))};
    const performCsv: TImportCsvDialogProps["performCsv"] = (fields) => {

    };

    return {
        isOpen,
        isLoadingTemplate, isPerformingCsv, isGettingFields,
        fields,
        setImportDialog,
        downloadTemplate,
        getFields,
        performCsv,
        response,
        fieldsMapperKey: "ruleset"
    }
}
