import React, {FC, useState} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSignIn} from "../../hooks/useSignIn";
import {useRoutes} from "../../hooks/useRoutes";
import {useThreeDotsMenu} from "../../../../../newShared/hooks/useThreeDotsMenu";
import {Box, Collapse, IconButton, InputAdornment, Menu, MenuItem, TextField, Typography} from "@mui/material";
import {DropdownButton, LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {CheckCircleOutlineRounded, Language, Visibility, VisibilityOff} from "@mui/icons-material";
import {SUPPORTED_LANGUAGES} from "../../../../../newShared/constants";
import colors from "../../../../../newShared/theme/colors";
import {validateEmail} from "../../../../../newShared/utils/text";
import {useRestorePass} from "../../hooks/useRestorePass";

export const BlockSignInRestorePassword: FC = () => {
    const {t, currentLanguage: {name, code}, handleChooseLanguage} = useMainTranslation('', {keyPrefix: 'pathLogin.loginRestorePasswordPage'});

    const {
        isLoading: {isSendingEmailPass, isResettingPass}
    } = useSignIn();
    const {
        hash,
        sendEmail,
        formik: {values, handleSubmit, handleChange, errors, touched, setFieldTouched: setFormikTouched},
    } = useRestorePass();

    const {goToLogin} = useRoutes();

    const {anchorThreeDotsEl, handleThreeDotsOpen, handleThreeDotsClose} = useThreeDotsMenu();
    const [showPass, setShowPass] = useState(false);
    const [fieldTouched, setFieldTouched] = useState<string>('');

    const [email, setEmail] = useState<string>('');

    // const disabled = !hash
    //     ? !validateEmail(email)
    //     : !!errors.passwordConfirm || !!errors.password || !!errors.email;
    // const handleKeyDown = (e: React.KeyboardEvent) => {
    //     if (!disabled && e.key === 'Enter') {
    //         if (hash) {
    //             handleSubmit();
    //         } else {
    //             sendEmail(email);
    //         }
    //     }
    // };

    return (
        <>
            <Typography variant={"h1"} textAlign={"center"}>{t('Resetting password')}</Typography>

            <DropdownButton value={code} sx={{m: '32px auto 0', alignSelf: 'center'}} variant={"text"}
                            icon={<Language/>} open={!!anchorThreeDotsEl} onClick={handleThreeDotsOpen}>
                <Typography variant={"body1"}>{name}</Typography>
            </DropdownButton>
            <Menu open={!!anchorThreeDotsEl} anchorEl={anchorThreeDotsEl} onClose={handleThreeDotsClose}>
                {SUPPORTED_LANGUAGES.map((e) => (
                    <MenuItem value={e.code} key={e.code}
                              sx={{width: anchorThreeDotsEl?.offsetWidth}}
                              onClick={() => {
                                  handleChooseLanguage(e.code);
                                  handleThreeDotsClose();
                              }}>
                        {e.name}
                    </MenuItem>
                ))}
            </Menu>

            {!hash ? (
                <>
                    <TextField name={'email'} label={t('Email')} value={email} onChange={(e) => setEmail(e.target.value)}
                               sx={{mt: '16px'}} type={"email"}/>

                    <LoadingButton loading={isSendingEmailPass}
                                   onClick={() => sendEmail(email)}
                                   disabled={!validateEmail(email) }
                                   sx={{mt: '16px', alignSelf: 'flex-start'}}>
                        {t('Submit')}
                    </LoadingButton>
                </>
            ) : (
                <form onSubmit={handleSubmit}>
                    <TextField name={'email'} label={t('Email')} value={values.email} onChange={handleChange} disabled
                               sx={{mt: '16px'}} type={"email"}/>

                    <TextField name={'password'} label={t('New password')} value={values.password} onChange={handleChange}
                               sx={{mt: '16px'}} type={showPass ? 'text' : 'password'}
                               error={!!errors.password && fieldTouched !== 'password' && touched.password}
                               onFocus={() => {
                                   setFieldTouched('password');
                                   setFormikTouched('password', true);
                               }}
                               onBlur={() => setFieldTouched('')}
                               InputProps={{endAdornment: (
                                       <InputAdornment position="end">
                                           <IconButton onClick={() => setShowPass(prev => !prev)} edge="end">
                                               {showPass ? <VisibilityOff /> : <Visibility />}
                                           </IconButton>
                                       </InputAdornment>
                                   )}}
                    />

                    <Collapse in={fieldTouched === 'password'} sx={{width: '100%'}}>
                        <Box display={"flex"} flexDirection={"column"} width={'100%'}
                             sx={{
                                 mt: '16px',
                                 borderRadius: '8px',
                                 padding: '16px',
                                 boxShadow: '0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13)',
                             }}>
                            <Typography variant={"body2"}>{t('Password must meet the following requirements_doubleDot')}</Typography>

                            <Box display={"flex"} alignItems={"center"} gap={'8px'} mt={'8px'}>
                                <CheckCircleOutlineRounded color={values.password.length >= 8 ? "success" : 'error'}/>
                                <Typography variant={"body2"}>
                                    {`${t('Minimum 8 symbols')}`}
                                </Typography>
                            </Box>
                            <Box display={"flex"} alignItems={"center"} gap={'8px'} mt={'8px'}>
                                <CheckCircleOutlineRounded color={/(?=.*[A-Z])/.test(values.password) ? "success" : 'error'}/>
                                <Typography variant={"body2"}>
                                    {`${t('At least one capital letter')}`}
                                </Typography>
                            </Box>
                            <Box display={"flex"} alignItems={"center"} gap={'8px'} mt={'8px'}>
                                <CheckCircleOutlineRounded color={/(?=.*[a-z])/.test(values.password) ? "success" : 'error'}/>
                                <Typography variant={"body2"}>
                                    {`${t('At least one lowercase letter')}`}
                                </Typography>
                            </Box>
                            <Box display={"flex"} alignItems={"center"} gap={'8px'} mt={'8px'}>
                                <CheckCircleOutlineRounded color={/(?=.*[0-9])/.test(values.password) ? "success" : 'error'}/>
                                <Typography variant={"body2"}>
                                    {`${t('At least one number')}`}
                                </Typography>
                            </Box>
                            <Box display={"flex"} alignItems={"center"} gap={'8px'} mt={'8px'}>
                                <CheckCircleOutlineRounded color={/(?=.*[!@#$%^&*])/.test(values.password) ? "success" : 'error'}/>
                                <Typography variant={"body2"}>
                                    {`${t('At least one of the following symbols')} `}<span style={{fontWeight: 600}}>{'!@#$%^&*'}</span>
                                </Typography>
                            </Box>

                        </Box>
                    </Collapse>

                    <TextField name={'passwordConfirm'} label={t('Confirm password')} value={values.passwordConfirm} onChange={handleChange}
                               sx={{mt: '16px'}} type={showPass ? 'text' : 'password'}
                               error={!!errors.passwordConfirm && fieldTouched !== 'passwordConfirm' && touched.passwordConfirm}
                               onFocus={() => {
                                   setFieldTouched('passwordConfirm');
                                   setFormikTouched('passwordConfirm', true);
                               }}
                               onBlur={() => setFieldTouched('')}
                               InputProps={{endAdornment: (
                                       <InputAdornment position="end">
                                           <IconButton onClick={() => setShowPass(prev => !prev)} edge="end">
                                               {showPass ? <VisibilityOff /> : <Visibility />}
                                           </IconButton>
                                       </InputAdornment>
                                   )}}
                    />

                    <LoadingButton loading={isResettingPass}
                                   type={'submit'}
                                   disabled={!!errors.passwordConfirm || !!errors.password || !!errors.email}
                                   sx={{mt: '16px', alignSelf: 'flex-start'}}>
                        {t('Submit')}
                    </LoadingButton>
                </form>
            )}

            <Typography sx={{mt: '16px', fontWeight: 500, fontSize: '18px', lineHeight: '24px'}}>
                {`${t('Back to')} `}<span onClick={goToLogin} style={{color: colors.primary.blue, cursor: 'pointer', fontWeight: 600}}>{t('Log In')}</span>
            </Typography>
        </>
    )
}