import {Slider, styled, TableContainer} from "@mui/material";
import colors from "../../../../../../newShared/theme/colors";

export const ChecksTableContainer = styled(TableContainer)({
    boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    borderRadius: '8px !important',
    border: `1px solid #EAECF0`,
    overflow: 'initial'
})

export const CustomSlider = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-valueLabel': {
        fontSize: 12,
        fontWeight: 400,
        top: '5px',
        backgroundColor: 'unset',
        color: colors.grayText,
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-thumb:not(.Mui-disabled)': {
        height: 18,
        width: 18,
        backgroundColor: colors.primary.blue,
        '&:focus, &:hover, &.Mui-active': {
            boxShadow: 'none'
        },
    },
}));