import {useDispatch, useSelector} from "react-redux";
import {deselectedModel, loadings, models, setSelectedModel} from "../../store/slice";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_RISK_MANAGEMENT,} from "../../../../../newShared/constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useHistory} from "react-router-dom";
import {
    PATH_RISK_MANAGEMENT_MODELS_ALL,
    PATH_RISK_MANAGEMENT_MODELS_EXACT,
    PATH_RISK_MANAGEMENT_MODELS_NEW
} from "../../constants";
import {useEffect, useState} from "react";
import {TRiskModel} from "../../types";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";

export const useModelsList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const _models = useSelector(models);
    const {modelsList} = useSelector(loadings);
    const {clearFilters, currentSearchForFetch} = useGenericFiltersStorage();


    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    useSetBreadcrumbs([
        {
            title: tMenu('Risk Management'),
            path: PATH_LOCAL_RISK_MANAGEMENT
        },
        {
            title: tMenu('Models'),
            path: PATH_RISK_MANAGEMENT_MODELS_ALL
        }
    ]);

    const goToModel = (model: TRiskModel) => {
        dispatch(setSelectedModel(model));
        history.push(PATH_RISK_MANAGEMENT_MODELS_EXACT.replace(':id', model.id));
    }

    const goToCreateModel = () => {
        history.push(PATH_RISK_MANAGEMENT_MODELS_NEW);
    }

    //search
    const [filtered, setFiltered] = useState<TRiskModel[]>([]);
    // const {form, setForm, handleChange} = useForm<{search: string, prevSearch: string}>({search: '', prevSearch: ''});

    useEffect(() => {
        dispatch(deselectedModel());

        return () => {
            clearFilters();
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        setFiltered(_models);

        //eslint-disable-next-line
    }, [_models]);

    useEffect(() => {
        if(!modelsList && _models.length > 0 && currentSearchForFetch.trim().length > 0){
            filterValues(currentSearchForFetch);
        }

        //eslint-disable-next-line
    }, [modelsList]);

    const filterValues = (search: string) => {
        if(!search.trim().length){
            setFiltered(_models);
        }else{
            setFiltered(_models.filter(e => e.name.trim().toLowerCase().includes(search.trim().toLowerCase())));
        }
    }

    return{
        isNoModels: !_models.length,
        models: filtered,
        goToModel,
        goToCreateModel,
        isLoading: modelsList,
        filterValues
    }
}