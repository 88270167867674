import {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {
    PATH_LOCAL_EMPLOYEES_ALL,
    PATH_LOCAL_EMPLOYEES_ID,
    PATH_LOCAL_EMPLOYEES_INCOMING_REQUESTS,
    PATH_LOCAL_EMPLOYEES_ISSUES,
    PATH_LOCAL_EMPLOYEES_ISSUES_EXACT,
    PATH_LOCAL_EMPLOYEES_JOB_TITLE,
    PATH_LOCAL_EMPLOYEES_NEW,
    PATH_LOCAL_EMPLOYEES_SETTINGS,
    PATH_LOCAL_EMPLOYEES_SETTINGS_EXACT,
    PATH_LOCAL_EMPLOYEES_TREE,
    PATH_LOCAL_TIME_OFF_REQUESTS,
    PATH_LOCAL_TIME_OFF_REQUESTS_EXACT
} from "./constants";
import {EmployeesList} from "./pages/employeesList";
import {EmployeesExact} from "./pages/employeesExact";
import {EmployeesCreate} from "./pages/employeesCreate";
import {SettingsJobTitle} from "./pages/settingsJobTitle";
import {EmployeesTree} from "./pages/employeesTree";
import {EmployeesSettings} from "./pages/settings";
import {ExactHRSetting} from "./pages/exactHRSetting";
import {IncomingRequests} from "./pages/incomingRequests";
import {EmployeesIssuesList} from "./pages/employeesIssuesList";
import {EmployeesIssuesExact} from "./pages/employeesIssuesExact";
import {TimeOffRequests} from "./pages/timeOffRequests";


export const Routes: FC = () => {
    return (
        <Switch>
            <Route exact path={PATH_LOCAL_EMPLOYEES_ALL} component={EmployeesList}/>
            <Route exact path={PATH_LOCAL_EMPLOYEES_ID} component={EmployeesExact}/>
            <Route exact path={PATH_LOCAL_EMPLOYEES_NEW} component={EmployeesCreate}/>
            <Route exact path={PATH_LOCAL_EMPLOYEES_INCOMING_REQUESTS} component={IncomingRequests}/>
            <Route exact path={PATH_LOCAL_EMPLOYEES_ISSUES} component={EmployeesIssuesList}/>
            <Route exact path={PATH_LOCAL_EMPLOYEES_ISSUES_EXACT} component={EmployeesIssuesExact}/>
            <Route exact path={PATH_LOCAL_EMPLOYEES_SETTINGS_EXACT} component={ExactHRSetting}/>
            <Route exact path={PATH_LOCAL_EMPLOYEES_TREE} component={EmployeesTree}/>
            <Route exact path={PATH_LOCAL_EMPLOYEES_SETTINGS} component={EmployeesSettings}/>
            <Route exact path={PATH_LOCAL_EMPLOYEES_JOB_TITLE} component={SettingsJobTitle}/>
            <Route exact path={[PATH_LOCAL_TIME_OFF_REQUESTS, PATH_LOCAL_TIME_OFF_REQUESTS_EXACT]} component={TimeOffRequests}/>

            <Redirect to={PATH_LOCAL_EMPLOYEES_ALL}/>
        </Switch>
    )
}
