import React, {FC} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import {useEditSettingsDialog} from "../../../hooks/useEditSettingsDialog";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {LoadingButton} from "@mui/lab";
import {statusVariants} from "../../../constants";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {CommonRadio} from "../../../../../../newShared/components/Basic/CommonRadio";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";


export const EditSettingDialog: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});
    const {isMobile} = useMedia();
    const {
        id,
        settingTitle,
        isOpen,
        oldTitle,
        // status,
        newTitle,
        // setNewTitle,
        newStatus,
        // setNewStatus,
        withHistoryChange,
        setWithHistoryChange,
        // isOk,
        isOkNewTitle,

        handleChangeTitle,
        handleChangeStatus,
        handleClose,
        handleSave,
        isLoadingSettings,
        editSettingLoading,
        editResponse
    } = useEditSettingsDialog();


    return id ? (
        <Dialog open={isOpen} onClose={handleClose} sx={{'& .MuiPaper-root': {width: '600px'}}}>

            <DialogTitle >{editResponse ? t('Completed successfully') :`${t('Edit')} ${t(settingTitle).toLowerCase()}`}</DialogTitle>
            <DialogContent sx={{padding: isMobile ?  '5px 10px' :  '10px 24px'}}>
                {editResponse ? `The ${settingTitle.toLowerCase()} ${editResponse}` :
                    <>
                        <Flex ai={"flex-start"} direction={'column'} w={isMobile ? '100%' : '350px'} >
                            <TextField fullWidth placeholder={oldTitle} label={t('Title')}
                                       required
                                       value={id === 'status' ? newStatus?.name : newTitle}
                                       size={'small'}
                                       sx={{mt: '6px', width: '320px', '& label': {top: '0 !important'}}}
                                       onChange={(e) => {handleChangeTitle(e.currentTarget.value) }} />

                            {id === 'status' && (
                                <TextField fullWidth select label={'Status'} disabled={isLoadingSettings}
                                           value={newStatus?.status} required
                                           size={'small'}
                                           sx={{mt: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                           onChange={(e) => handleChangeStatus(e.target.value as string)}
                                >
                                    {statusVariants.map(status => (<MenuItem key={status} value={status}>{status}</MenuItem> ))}
                                </TextField>
                            )}
                        </Flex>
                        { id === 'status' &&
                            <>
                                <Flex ai={"center"} w={'100%'} m={'16px 0 0 0'}>
                                    <CommonRadio size={"small"} checked={!withHistoryChange} sx={{marginRight: '9px'}}
                                                 onClick={() => {setWithHistoryChange(false)}}/>
                                    <Typography variant={'body1'}>{t('Change anywhere it is used, without history')}</Typography>
                                </Flex>
                                <Flex ai={"center"} w={'100%'}>
                                    <CommonRadio size={"small"} checked={withHistoryChange} sx={{marginRight: '9px'}}
                                                 onClick={() => {setWithHistoryChange(true)}}/>
                                    <Typography variant={'body1'}>{t('Change anywhere it is used, including history')}</Typography>
                                </Flex>
                            </>
                        }
                    </>
                }
            </DialogContent>
            <DialogActions sx={{paddingTop: '8px'}}>
                <Button variant={'text'} onClick={handleClose} disabled={editSettingLoading}>{editResponse ? t('Close') : t('Cancel')} </Button>

                {!editResponse &&
                    <LoadingButton size={"large"} component={"label"}
                                   variant={'contained'}
                                   disabled={!isOkNewTitle || editSettingLoading || isLoadingSettings }
                                   onClick={handleSave} loadingPosition={editSettingLoading ? 'start' : undefined} loading={editSettingLoading}
                                   startIcon={editSettingLoading ? <div style={{width: '10px', height: '10px', }}/> : undefined}
                    >
                        {t('Confirm')}
                    </LoadingButton>
                }
            </DialogActions>

        </Dialog>

    ) : null
}
