import React, {FC} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDeleteSettingDialog} from "../../../hooks/useDeleteSettingDialog";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {CommonRadio} from "../../../../../../newShared/components/Basic/CommonRadio";
import {AddMenuItem} from "../../../../../../newShared/components/Basic/AddMenuItem";
import {LoadingButton} from "@mui/lab";
import colors from "../../../../../../newShared/theme/colors";

export const DeleteSettingDialog: FC = () => {

    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});
    const {
        assetType,
        settingTitle,
        settings,
        isOpen,
        field,
        oldTitle,
        virtualSettings,
        saasSettings,
        certificateSettings,

        handleSetTitle,
        handleClose,
        title, setTitle,
        withHistoryChange, setWithHistoryChange,
        openCreateSettingRowDialog,
        handleDeleteSettingRow,

        // isLoadingSettings,
        deleteSettingLoading,
        isOkConfirm,
    } = useDeleteSettingDialog();

    return  field ? (
        <Dialog open={isOpen} onClose={handleClose}
                sx={{'& .MuiPaper-root': {width: '600px'}}}>

            <DialogTitle>
                {`${t('Delete')} ${t(settingTitle).toLowerCase()}`}
            </DialogTitle>

            {assetType === 'physical' &&
                <DialogContent>
                    {(field === 'model' || field === 'category' || field === 'manufacture' || field === 'status') &&
                        <TextField select sx={{width: '320px', mt: '10px', '& label': {top: '0 !important'}}} size={'small'}
                                   label={`${t('Select new')} ${field === 'status' ? t('status').toLowerCase() : t(settingTitle).toLowerCase()}`}
                                   value={title ?? ''} required

                                   onChange={handleSetTitle}
                        >
                            <AddMenuItem text={t('Add new')} onClick={() => openCreateSettingRowDialog({
                                isOpen: true,
                                field,
                                assetType: 'physical'
                            })}/>
                            {settings && settings[field].map(({name}) => <MenuItem
                                sx={{display: name === oldTitle ? 'none' : undefined}} key={name}
                                value={name}>{name}</MenuItem>)}
                        </TextField>
                    }

                    {(field === 'customFields') &&
                        <Typography variant={'body2'} color={colors.darkBlueText}>
                            {t(`If you delete information about an item, you will not be able to restore it.`)}
                        </Typography>
                        // <TextField select value={title ?? null}
                        //            sx={{width: '320px', '& label': {top: '0 !important'}}} size={'small'}
                        //            label={`${t('Select new')} ${settingTitle.toLowerCase()}`}
                        //            onChange={(e) => {
                        //                setTitle(e.target.value as string)
                        //            }}>
                        //     <AddMenuItem text={t('Add new')} onClick={() => openCreateSettingRowDialog({
                        //         isOpen: true,
                        //         field,
                        //         assetType: 'physical'
                        //     })}/>
                        //     {settings && settings[field].map((name) => (
                        //         <MenuItem sx={{display: name === oldTitle ? 'none' : undefined}} key={name}
                        //                   value={name}>{name}</MenuItem>
                        //     ))}
                        // </TextField>
                    }
                    {(field === 'status') &&
                        <>
                            <Typography variant={'body2'}
                                        sx={{marginTop: '16px'}}>{t('How should this change occur?')}</Typography>

                            <Flex ai={"center"} w={'100%'} m={!revDir ? '16px 0 0 -8px' : '16px -8px 0 0'}>
                                <CommonRadio size={"small"} checked={!withHistoryChange} sx={{marginRight: '9px'}}
                                             onClick={() => {
                                                 setWithHistoryChange(false)
                                             }}/>
                                <Typography
                                    variant={'body1'}>{t('Change anywhere it is used, without history')}</Typography>
                            </Flex>
                            <Flex ai={"center"} w={'100%'} m={!revDir ? '0 0 0 -8px' : '0 -8px 0 0'}>
                                <CommonRadio size={"small"} checked={withHistoryChange} sx={{marginRight: '9px'}}
                                             onClick={() => {
                                                 setWithHistoryChange(true)
                                             }}/>
                                <Typography
                                    variant={'body1'}>{t('Change anywhere it is used, including history')}</Typography>
                            </Flex>

                        </>
                    }

            </DialogContent>}



            {assetType === 'virtual' &&
                <DialogContent>
                    { field === 'customFields' &&
                        <Typography variant={'body2'} color={colors.darkBlueText}>
                            {t(`If you delete information about an item, you will not be able to restore it.`)}
                        </Typography> }
                    { field === 'type' &&
                        <TextField select value={title ?? null} required
                                   sx={{mt: '8px', width: '320px', '& label': {top: '0 !important'}}} size={'small'}
                                   label={`${t('Select new')} ${settingTitle.toLowerCase()}`}
                                   onChange={(e) => {
                                       setTitle(e.target.value as string)
                                   }}>
                            <AddMenuItem text={t('Add new')} onClick={() => openCreateSettingRowDialog({
                                isOpen: true,
                                field,
                                assetType: 'virtual'
                            })}/>
                            {virtualSettings && virtualSettings[field] && Object.keys(virtualSettings[field]).map((name) => (
                                <MenuItem sx={{display: (!name || name === oldTitle) ? 'none' : undefined}} key={name}
                                          value={name}>{name}</MenuItem>
                            ))}
                        </TextField>
                    }
                </DialogContent>}

            {assetType === 'software' &&
                <DialogContent>
                    <Typography variant={'body2'} color={colors.darkBlueText}>
                        {t(`If you delete information about an item, you will not be able to restore it.`)}
                    </Typography>
                </DialogContent>}

            {assetType === 'saas' &&
                <DialogContent>
                    { field === 'customFields' &&
                        <Typography variant={'body2'} color={colors.darkBlueText}>
                            {t(`If you delete information about an item, you will not be able to restore it.`)}
                        </Typography> }
                    { field === 'billingCycle' &&
                        <TextField select value={title ?? null} required
                                   sx={{mt: '8px', width: '320px', '& label': {top: '0 !important'}}} size={'small'}
                                   label={`${t('Select new')} ${settingTitle.toLowerCase()}`}
                                   onChange={(e) => {
                                       setTitle(e.target.value as string)
                                   }}>
                            <AddMenuItem text={t('Add new')} onClick={() => openCreateSettingRowDialog({
                                isOpen: true,
                                field,
                                assetType: 'saas'
                            })}/>
                            {saasSettings && saasSettings[field] && Object.keys(saasSettings[field]).map((name) => (
                                <MenuItem sx={{display: (!name || name === oldTitle) ? 'none' : undefined}} key={name}
                                          value={name}>{name}</MenuItem>
                            ))}
                        </TextField>
                    }
                </DialogContent>}

            {assetType === 'certificate' &&
                <DialogContent>
                    { field === 'customFields' &&
                        <Typography variant={'body2'} color={colors.darkBlueText}>
                            {t(`If you delete information about an item, you will not be able to restore it.`)}
                        </Typography> }
                    { field === 'renewal' &&
                        <TextField select value={title ?? null} required
                                   sx={{mt: '8px', width: '320px', '& label': {top: '0 !important'}}} size={'small'}
                                   label={`${t('Select new')} ${settingTitle.toLowerCase()}`}
                                   onChange={(e) => {
                                       setTitle(e.target.value as string)
                                   }}>
                            <AddMenuItem text={t('Add new')} onClick={() => openCreateSettingRowDialog({
                                isOpen: true,
                                field,
                                assetType: 'certificate'
                            })}/>
                            {certificateSettings && certificateSettings[field] && Object.keys(certificateSettings[field]).map((name) => (
                                <MenuItem sx={{display: (!name || name === oldTitle) ? 'none' : undefined}} key={name}
                                          value={name}>{name}</MenuItem>
                            ))}
                        </TextField>
                    }
                </DialogContent>}

            <DialogActions>
                <Button variant={'text'} onClick={handleClose}>{t('Cancel')} </Button>

                <LoadingButton
                    variant={'contained'}
                    disabled={!isOkConfirm}
                    onClick={handleDeleteSettingRow} loadingPosition={deleteSettingLoading ? 'start' : undefined} loading={deleteSettingLoading}
                    startIcon={deleteSettingLoading ? <div style={{width: '10px', height: '10px', }}/> : undefined}
                >
                    {t('Confirm')}
                </LoadingButton>

            </DialogActions>
        </Dialog>
    ) : null
}
