import React from "react";
import {Flex} from "../../../../../newShared/components/Layouts"
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Box, Button, Chip, MenuItem, Skeleton, Tab, Tabs, TextField} from "@mui/material";
import {Edit} from "@mui/icons-material";
import {TabContext, TabPanel} from "@mui/lab";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {TExactAssetTabs} from "../../types";
import {DeleteAssetDialog} from "../../components/Dialogs/DeleteAssetDialog";
import {useSoftwareAssetExact} from "../../hooks/useSoftwareExact";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import * as S from "../../components/styled";


export const SoftwareAssetExact = () => {
    const {isLessThen1050} = useMedia();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});


    const {
        isOk,
        asset,
        settings,
        shortPhysicalAssets,
        tab,
        setTab,
        handleGoBack,
        editInfoMode,
        form,
        handleChange,
        handleSetPhysicalAsset,
        handleDeletePhysicalAsset,
        isLoadingExact,
        isLoadingSettings,
        isLoadingShortPhysicalAssets,

        handleSaveInfo,
        handleCancelEditInfo,
        handleEditInfoBtn,
        handleChangeCustomField,

        deleteAssetDialog: {setDeleteAssetDialog, responseDelete},
    } = useSoftwareAssetExact();

    const isPhysicalAssets = asset && asset?.physicalAssets && asset.physicalAssets?.length > 0;

    return (
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={asset?.name}
                handleGoBack={handleGoBack}
                showMenuWhen={isLessThen1050}
                actions={[
                    {
                        title: t('Delete asset'),
                        onClick: () => {setDeleteAssetDialog({isOpen: true, assetType: 'software'})},
                        variant: 'text',
                        icon: <DeleteForeverIcon/>,
                        disabled: !asset || isLoadingExact
                    },
                ]}
            />



            {/*TABS*/}
            <TabContext value={tab}>
                <Box sx={{ margin: '30px 0 0 0' }}>
                    <Tabs variant={'scrollable'} value={tab}
                          aria-label="basic tabs example"
                          onChange={(e, newValue: TExactAssetTabs) => setTab(newValue)} >
                        <Tab disabled={isLoadingExact} label={t("Information")} value={'information'} sx={{paddingLeft: '0'}}/>
                        {/*<Tab disabled={isLoadingExact} label={t("History")} value={'history'} />*/}
                        {/*<Tab disabled={true} label={t("Services detected")} value={'servicesDetected'} />*/}
                    </Tabs>
                </Box>

                {/*INFO*/}
                <TabPanel value={'information'} sx={{padding: '16px 0', overflowY: 'auto'}}>
                    { editInfoMode ?
                        <Flex w={'100%'} direction={'column'} ai={'flex-start'}  overflow={'hidden'} >
                            <Flex ai={'center'} p={'6px 0'}  w={'100%'} >
                                <Button size={'large'}
                                        disabled={!isOk() || isLoadingExact}
                                        onClick={handleSaveInfo} >
                                    {t('Save')}
                                </Button>
                                <Button variant={'outlined'} size={'large'}
                                        disabled={isLoadingExact}
                                        sx={{marginLeft: revDir ? undefined : '8px', marginRight: revDir ? '8px' : undefined}}
                                        onClick={handleCancelEditInfo} >{t('Cancel')}</Button>
                            </Flex>

                            <Flex direction={'column'} w={'100%'} ai={'flex-start'} m={'10px 0 0 0'}  >

                                <TextField label={t('Name')} size={"small"}
                                           required
                                           sx={{marginTop: '6px', width: '320px', '& label': {top: '0 !important'}}}
                                           value={form?.name} onChange={handleChange} name={'name'} />


                                <TextField label={t('Version')} value={form?.version} size={'small'}
                                           sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                           onChange={handleChange} name={'version'} />

                                <TextField label={t('Installed')} value={form?.installed} size={'small'}
                                           sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                           onChange={handleChange} name={'installed'} />

                                <TextField label={t('Number of licenses')} value={form?.installationThreshold} size={'small'}
                                           type={'number'}
                                           InputProps={{ inputProps: { min: 0 } }}
                                           sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                           onChange={handleChange} name={'installationThreshold'} />

                                {(settings && settings.customFields &&  Object.keys(settings.customFields).length > 0) &&
                                    Object.keys(settings.customFields).map((e) =>
                                        <TextField key={e} size={'small'} label={e} value={form?.customFields[e] || ''} name={e}
                                                   onChange={handleChangeCustomField} sx={{marginTop: '16px', width: '320px', '& label': {top: '0px !important'}}}
                                        />
                                    )}

                                <TextField select fullWidth label={t('Add Physical Assets')}
                                           size={'small'}
                                           disabled={isLoadingShortPhysicalAssets}
                                           sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                                           // onChange={handleSetPhysicalAsset}
                                >
                                    {shortPhysicalAssets.filter((e) => !form?.physicalAssets?.find(pa => pa.id === e.id)).map((e) => <MenuItem key={e.id} onClick={() => handleSetPhysicalAsset(e)} value={e.id}>{e.assetId || ''}</MenuItem>)}
                                </TextField>
                                <Box display={'flex'} width={'350px'} maxHeight={'300px'} sx={{mt: '12px', overflowY: 'scroll', p: '5px', ml: !revDir? '-10px' : undefined, mr: revDir ? '-10px' : undefined}} flexWrap={'wrap'} >
                                    {form?.physicalAssets?.map( e => (
                                        <Chip key={e.id} sx={{width: '150px', mt: '6px', ml: !revDir ? '9px' : undefined, display: 'flex', justifyContent: 'space-between'}} label={e.assetId} onDelete={() => handleDeletePhysicalAsset(e.id)} />
                                    ))
                                    }
                                </Box>



                            </Flex>
                        </Flex>
                        :
                        <Flex w={'100%'} direction={'column'} ai={'flex-start'} style={{opacity: responseDelete ? 0 : 'undefined'}}>
                            <Button variant={'text'} startIcon={<Edit style={{margin: revDir ? '0 0 0 9px' : undefined}}/>}
                                    sx={{marginTop: '10px', paddingLeft: !revDir ? '3px' : undefined, paddingRight: revDir ? '3px' : undefined}}
                                    disabled={isLoadingExact || isLoadingSettings}
                                    onClick={handleEditInfoBtn}>
                                {t('Edit information')}
                            </Button>

                            {isLoadingExact ? <Skeleton width={'250px'}/> : <S.FieldTitleText> {`${t('Name')}: `}<S.FieldValueText>{asset?.name ?? ''}</S.FieldValueText></S.FieldTitleText>}
                            {isLoadingExact ? <Skeleton width={'250px'}/> : <S.FieldTitleText> {`${t('Version')}: `}<S.FieldValueText>{asset?.version ?? ''}</S.FieldValueText></S.FieldTitleText>}
                            {isLoadingExact ? <Skeleton width={'250px'}/> : <S.FieldTitleText> {`${t('Installed')}: `}<S.FieldValueText>{asset?.installed ?? ''}</S.FieldValueText></S.FieldTitleText>}
                            {isLoadingExact ? <Skeleton width={'250px'}/> : <S.FieldTitleText> {`${t('Number of licenses')}: `}<S.FieldValueText>{asset?.installationThreshold ?? ''}</S.FieldValueText></S.FieldTitleText>}


                            {!isLoadingExact && settings && Object.keys(settings?.customFields ?? {}).map((key) => asset?.customFields?.[key] && (
                                <S.FieldTitleText key={key}>{`${key}: `}<S.FieldValueText>{asset?.customFields[key]}</S.FieldValueText></S.FieldTitleText>
                            )) }

                            <Box display={'flex'} width={'100%'} flexDirection={isPhysicalAssets ? 'column' : 'row'}>
                                {isLoadingExact ? <Skeleton width={'250px'}/> : <S.FieldTitleText> {`${t('Physical assets')}: `} {!isPhysicalAssets && <S.FieldValueText> {isLoadingExact ? '' : 'None'}</S.FieldValueText>}</S.FieldTitleText>}

                                {isPhysicalAssets &&
                                    <Box display={'flex'} width={'350px'} maxHeight={'300px'} sx={{mt: '12px', overflowY: 'scroll', p: '5px', ml: !revDir? '-10px' : undefined, mr: revDir ? '-10px' : undefined}} flexWrap={'wrap'} >
                                        {asset?.physicalAssets?.map(e => <Chip key={e.id} sx={{width: '150px', mt: '6px', ml: !revDir ? '9px' : undefined, display: 'flex', justifyContent: 'space-between'}} label={e.assetId}/>)}
                                    </Box>
                                }

                            </Box>

                        </Flex>
                    }
                </TabPanel>

            </TabContext>

            <DeleteAssetDialog />
        </Flex>
    )
}
