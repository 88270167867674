import {useDispatch, useSelector} from "react-redux";
import {dialogs, setRequestErrorAction} from "../../../store/slice";


export const useRequestError = () => {
    const dispatch = useDispatch();
    const {requestError: {message, title}} = useSelector(dialogs);

    const handleClose = () => {
        dispatch(setRequestErrorAction({clear: true}));
    }

    return {
        isOpen: !!message.length,
        title,
        message,
        handleClose,
    }
}
