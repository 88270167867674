import {DEFAULT_COUNT, PATH_LOCAL_VENDORS, PATH_LOCAL_VENDORS_ADD} from "../../constants";
import {useAppDispatch} from "../../../../../newShared/redux";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import {createVendorAction, getVendorsAction, getVendorsSettingsAction} from "../../store/actions";
import {useSelector} from "react-redux";
import {
    isLoadingAddVendorSelector,
    isLoadingSelector,
    isSettingsLoadingSelector,
    pageInfoSelector,
    vendorsSelector,
    vendorsSettingsSelector
} from "../../store/slice";
import {CreateVendorType} from "../../types";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const useVendorAdd = () =>{

    const isLoading =  useSelector(isLoadingAddVendorSelector);
    const settings = useSelector(vendorsSettingsSelector);
    const isSettingsLoading = useSelector(isSettingsLoadingSelector);
    const getSettingsVariables = () => {
        !isSettingsLoading && dispatch(getVendorsSettingsAction({organizationId: '', workspaceId: ''}))};
    const dispatch = useAppDispatch();
    const history = useHistory();
    const vendors = useSelector(vendorsSelector);
    const _pageInfo = useSelector(pageInfoSelector);
    const isLoadingData = useSelector(isLoadingSelector);

    const getData = (page:number, count:number) => {
        dispatch(getVendorsAction({pageInfo: {
                page: page,
                count: count,
                filters: {}
            }})
        )
    }

    const hasMore: boolean = _pageInfo.total > ((_pageInfo.page +1) * _pageInfo.count);

    const [tmpVendor, setTmpVendor] = useState<CreateVendorType>({
        customFields: {},
        documents: [],
        id: 'NEW',
        name: '',
        evaluation: {}
    });

    const isOk = () => {
        return tmpVendor.name.length && tmpVendor.name.trim() !== '' && tmpVendor.type && tmpVendor.serviceProvided && tmpVendor.risk
    }

    const resetTmpVendor = () => {
        setTmpVendor({
            customFields: {},
            documents: [],
            id: 'NEW',
            name: ''
        })
    }

    const goToId = (id: string) => {
            history.push(`${PATH_LOCAL_VENDORS}/${id}`)
    }

    const createVendor = () => {
        setTmpVendor({...tmpVendor, name: tmpVendor.name.trim()})
        dispatch(createVendorAction({data:tmpVendor, onSuccess: goToId}));
    };

    const [isExist, setIsExists] = useState<boolean>(false);

    useEffect(() => {
        const cond = !vendors.some(e => e.name.toLowerCase().trim() === tmpVendor.name.toLowerCase().trim());
        setIsExists(!cond);
        //eslint-disable-next-line
    }, [tmpVendor.name]);

    useEffect(() => {
        !isSettingsLoading && getSettingsVariables();
        !isLoadingData && !vendors.length && getData(0,DEFAULT_COUNT);
        // eslint-disable-next-line
    },[])

    useEffect(() => {
        if (hasMore){
            !isLoading && getData(_pageInfo.page +1, DEFAULT_COUNT)
        }
        //eslint-disable-next-line
    },[vendors])

    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const {t} = useMainTranslation('',{keyPrefix:'pathVendors.AddVendor'});
    useSetBreadcrumbs([
        {
            title: tMenu('Vendors'),
            path: PATH_LOCAL_VENDORS
        },
        {
            title: t('New vendor'),
            path: PATH_LOCAL_VENDORS_ADD
        }
    ]);

    return{
        isLoading,
        handleGoBack: () => history.push(PATH_LOCAL_VENDORS),
        createVendor,
        settings,
        getSettingsVariables,
        isSettingsLoading,
        tmpVendor,
        setTmpVendor,
        resetTmpVendor,
        isExist,
        isOk
    }


}