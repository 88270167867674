import {Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs} from "@mui/material";
import React from "react";
import {LoadingButton} from "../../../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import * as S from "../../../styled";
import {DialogContentWrapper} from "../../../styled";
import {TabContext, TabPanel} from "@mui/lab";
import {Vendor} from "../../../../../accessManagement/types";
import {TextFieldCustom} from "../../../../../accessManagement/components/styled";
import {
    useAddIntegrationCloudflareDialog
} from "../../../../hooks/integrationsManagement/addIntegrationDialogs/cloudflare";
import {useAddNewSetting} from "../../../../../vendors/hooks/useAddNewSetting";
import {CreateSettingDialog} from "../../../../../vendors/components/dialogs/createSetting";
import {VendorsTabAddNew} from "../../../vendorsTabAddNew";

export const AddIntegrationCloudflareDialog = () => {
    const {
        isOpen,
        app,
        isLoading,
        isOk,
        isOkVendorsStep,
        actions,
        form,
        stepper
    } = useAddIntegrationCloudflareDialog();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathIntegrations.AvailableAppsList.dialogs'});
    const {field} = useAddNewSetting();

    // const dispatch = useDispatch();
    //
    // const zendesk = useOAuth2({
    //     authorizeUrl: ONELOGIN_URL,
    //     clientId: ONELOGIN_CLIENTID,
    //     redirectUri: ONELOGIN_REDIRECT_URI,
    //     scope: ONELOGIN_SCOPE,
    //     responseType: "code",
    //     exchangeCodeForTokenServerURL: "",
    //     // exchangeCodeForTokenMethod: "POST",
    //     // extraQueryParameters:{
    //     //     audience: "https://api.atlassian.com",
    //     //     prompt:"consent"
    //     // },
    //
    //     onSuccess: (res) => {
    //         console.log(res)
    //         // actions.setForm(prev => ({...prev, integration: {...prev.integration, authorization_code: res.access_token}}));
    //         // stepper.setStep('vendor');
    //     },
    //     onError: (error_) => dispatch(addErrorSnack(`Error while connecting to onelogin: ${error_}`))
    // });

    return(
        <Dialog open={isOpen} onClose={actions.handleClose} scroll={'paper'}>
            <DialogTitle>{t(`Add {{name}} integration`, {name: app?.name})}</DialogTitle>

            <DialogContent>
                {stepper.step === 'app' &&
                    <DialogContentWrapper margin={'8px 0 0 0'}>
                        <S.TextFieldCustom
                            required
                            variant={'outlined'}
                            name={'name'}
                            label={t('Application name')}
                            value={form.name}
                            onChange={actions.handleChange}
                            size={'small'}
                        />

                        <S.TextFieldCustom
                            required
                            variant={'outlined'}
                            name={'token'}
                            label={t('Token')}
                            value={form.integration.token}
                            onChange={actions.handleChangeIntegration}
                            sx={{margin: '16px 0 0 0'}}
                            size={'small'}
                        />

                        {/*<Button variant={"outlined"} style={{margin: '16px 0 0 0'}} color={form.integration["client-secret"].trim().length > 0 && form.integration["client-secret"].trim().length > 0 ? 'success' : undefined}*/}
                        {/*        onClick={() => zendesk.getAuth()}>*/}
                        {/*    {t('Sign in with Onelogin')}*/}
                        {/*</Button>*/}
                    </DialogContentWrapper>
                }
                {/*VENDOR MANAGE*/}
                {stepper.step === 'vendor' &&
                    <DialogContentWrapper>
                        <TabContext value={stepper.tab} >
                            <Box>
                                <Tabs variant={'scrollable'} value={stepper.tab} onChange={(e, newValue: any) => stepper.setTab(newValue)} aria-label="basic tabs example">
                                    <Tab label={t("Select vendor")} value={'selectVendor'} sx={{paddingLeft: '0'}}/>
                                    <Tab label={t("Create vendor")} value={'createVendor'} />
                                </Tabs>
                            </Box>

                            <TabPanel value={'selectVendor'} sx={{padding: '16px 0 0 0', overflowY: 'auto'}}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={stepper.additionalDataForCreate.vendors.filter(e => e.owner !== null)}
                                    getOptionLabel={(option) => option.name}
                                    loading={stepper.isLoadingAdditionalData}
                                    value={stepper.form.selectedVendor}
                                    onChange={(e, newValue: Vendor | null) => newValue && stepper.handleSetVendor(newValue)}
                                    renderInput={(params) => <TextFieldCustom required {...params} label={t("Select vendor")} size={'small'} />}
                                />
                            </TabPanel>

                            <TabPanel value={'createVendor'} sx={{padding: '16px 0 0 0', overflowY: 'auto'}}>
                                <VendorsTabAddNew form={stepper.form}
                                                  handleSetOwner={stepper.handleSetOwner}
                                                  handleSetType={stepper.handleSetType}
                                                  handleSetProvidedService={stepper.handleSetProvidedService}
                                                  handleSetRiskLevel={stepper.handleSetRiskLevel}
                                />
                            </TabPanel>
                        </TabContext>

                        <CreateSettingDialog onSuccess={(newValue) => {
                            switch (field) {
                                case 'risk': {
                                    stepper.handleSetRiskLevel(newValue);
                                    break;
                                }
                                case 'type': {
                                    stepper.handleSetType(newValue);
                                    break;
                                }
                                case 'serviceProvided': {
                                    stepper.handleSetProvidedService(newValue);
                                    break;
                                }
                            }
                        }}/>
                    </DialogContentWrapper>
                }
            </DialogContent>

            <DialogActions>
                {stepper.step === 'app' &&
                    <>
                        <Button onClick={actions.handleClose} variant={'text'}>{t('Cancel')}</Button>
                        <LoadingButton
                            sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                            onClick={() => stepper.setStep('vendor')}
                            disabled={!isOk}
                        >
                            {t('Next')}
                        </LoadingButton>
                    </>
                }
                {stepper.step === 'vendor' &&
                    <>
                        <Button onClick={actions.handleClose} variant={'text'}>{t('Cancel')}</Button>
                        <Button onClick={() => stepper.setStep('app')} variant={'text'}>{t('Previous')}</Button>
                        <LoadingButton
                            sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                            loading={isLoading}
                            onClick={actions.handleSubmit}
                            disabled={!isOkVendorsStep()}
                        >
                            {t('Add integration')}
                        </LoadingButton>
                    </>
                }
            </DialogActions>

            {/*{isOpen && <OAuthPopup/>}*/}
        </Dialog>
    )
}
