import {Flex} from "../../../../../newShared/components/Layouts";
import {FrameworksDelegatedTaskStatus} from "../../../../../newShared/GQLTypes";
import {Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";

export const DelegatedTaskStatus = ({status, margin}: {status: FrameworksDelegatedTaskStatus, margin?: string}) => {
    switch (status){
        case FrameworksDelegatedTaskStatus.Created: return <Flex
            m={margin}
            p={'6px 14px'}
            br={'12px'}
            ai={'center'}
            jc={'center'}
            background={colors.status.violet_1}
        >
            <Typography variant={'*bodyText2'} color={colors.text.grey_dark}>{('Created')}</Typography>
        </Flex>;

        case FrameworksDelegatedTaskStatus.Approved: return <Flex
            m={margin}
            p={'6px 14px'}
            br={'12px'}
            ai={'center'}
            jc={'center'}
            background={colors.decorative.background_3}
        >
            <Typography variant={'*bodyText2'} color={colors.text.success}>{('Approved')}</Typography>
        </Flex>;

        case FrameworksDelegatedTaskStatus.Cancelled: return <Flex
            m={margin}
            p={'6px 14px'}
            br={'12px'}
            ai={'center'}
            jc={'center'}
            background={colors.status.violet_1}
        >
            <Typography variant={'*bodyText2'} color={colors.textStatus.negative}>{('Cancelled')}</Typography>
        </Flex>;

        case FrameworksDelegatedTaskStatus.Declined: return <Flex
            m={margin}
            p={'6px 14px'}
            br={'12px'}
            ai={'center'}
            jc={'center'}
            background={colors.decorative.background_2}
        >
            <Typography variant={'*bodyText2'} color={colors.textStatus.negative}>{('Declined')}</Typography>
        </Flex>;

        case FrameworksDelegatedTaskStatus.Performed: return <Flex
            m={margin}
            p={'6px 14px'}
            br={'12px'}
            ai={'center'}
            jc={'center'}
            background={colors.status.turquoise_1}
        >
            <Typography variant={'*bodyText2'} color={colors.textStatus.positive}>{('Performed')}</Typography>
        </Flex>;

        default: return <Flex
            m={margin}
            p={'6px 14px'}
            br={'12px'}
            ai={'center'}
            jc={'center'}
            background={colors.status.violet_1}
        >
            <Typography variant={'*bodyText2'} color={colors.text.grey_dark}>{status}</Typography>
        </Flex>;
    }
}
