import * as S from '../../components/styled';
import {Divider, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {appHistory} from "../../types";
import {getNormalizedStatus} from "../../helpers";
import React from "react";
import {shortString} from "../../../../../newShared/utils/text";
import {useDispatch} from "react-redux";
import {openErrorReasonDialog} from '../../store/slice';
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const AppHistoryTableRow = ({history}: {history: appHistory}) => {
    const dispatch = useDispatch();
    const {color, status} = getNormalizedStatus(history.status);
    const onClick = () => history.errorReason !== null && dispatch(openErrorReasonDialog(history));
    const {t} = useMainTranslation('', {keyPrefix: 'pathIntegrations.ExactApp'});

    return(
        <>
            <S.AppHistoryTableHeaderWrapper hoverable={history.errorReason !== null} onClick={onClick}>
                <S.AppHistoryTableHeaderItemWrapper width={'33%'}>
                    <Typography sx={{fontSize: '14px', fontWeight: 400, color: colors.grayText}}>{history.start}</Typography>
                </S.AppHistoryTableHeaderItemWrapper>

                <S.AppHistoryTableHeaderItemWrapper width={'33%'}>
                    <Typography sx={{fontSize: '14px', fontWeight: 400, color: color}}>{t(`statuses.${status}`)}</Typography>
                </S.AppHistoryTableHeaderItemWrapper>

                <S.AppHistoryTableHeaderItemWrapper width={'33%'}>
                    <Typography sx={{fontSize: '14px', fontWeight: 400, color: colors.grayText}}>{history.errorReason ? shortString(history.errorReason, 40) : '- - -'}</Typography>
                </S.AppHistoryTableHeaderItemWrapper>
            </S.AppHistoryTableHeaderWrapper>
            {<Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable, margin: '4px 0 0 0'}}/>}
        </>
    )
}