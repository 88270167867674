import {useLanguage} from "../../hooks/language/useLanguage";
import * as S from "../../components/styled";
import {FormControlLabel, List, ListItem, ListItemIcon, Radio} from "@mui/material";
import React from "react";
import {Flex} from "../../../../../newShared/components/Layouts";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {SUPPORTED_LANGUAGES} from "../../../../../newShared/constants";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";

export const Language = () => {
    //reusing hook for dev language dialog
    const {handleChooseLanguage, currentLanguage} = useLanguage();
    const {t} = useMainTranslation('', {keyPrefix: 'pathSettings'});

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('Language')}
                actions={[]}
            />

            <S.ProfileSettingWrapper>
                <List sx={{ pt: 0 }}>
                    {SUPPORTED_LANGUAGES.map((e) => (
                        <ListItem onClick={() => handleChooseLanguage(e.code)} key={e.code}>
                            <ListItemIcon>
                                <FormControlLabel value={e.code === currentLanguage.code} control={<Radio />} label={e.name} checked={currentLanguage.code === e.code} />
                            </ListItemIcon>
                        </ListItem>
                    ))}
                </List>
            </S.ProfileSettingWrapper>
        </Flex>
    )
}