import {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";

import {useDispatch, useSelector} from "react-redux";

// import {isUnProtectedPaths} from "../../../leftSideBar/helpers";
import {UseManageWorkspacesAndOrganizations} from "../useManageWorkspacesAndOrganizations";
// import {logoutAction} from "../../../signin/store/actions";
import {Logging} from "../../../../../newShared/utils/logs/log";
import {MAIN_DATA_COOKIE_NAME, PATH_LOCAL_LOGIN} from "../../../../../newShared/constants";
import {isUnProtectedPaths} from "../../../../barsEnvironment/shell/helpers";
import {
    allMultiStoreData,
    setSharedWorkspacesAndOrganizationsData
} from "../../store/sharedWorkspacesAndOrganizations/slice";
import {cookieStoreType} from "../../types";
import {isPathAvailableForEveryone} from "../../constants";


export const useWorkspacesAndOrganizations = () => {
    //this hook is used to keep workspaces, organizations and user data up to date
    //this hook looks for updated in cookies + sending to login/workspaces page if no data / or workkspace not selected
    const {getCookieData, deleteCookieByName, setCookieData, logout} = UseManageWorkspacesAndOrganizations();
    const [isLoadingCookieData, setIsLoadingCookieData] = useState<boolean>(true);
    const history = useHistory();
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    //current saved data
    // const _is403 = useSelector(is403);
    const multiStore = useSelector(allMultiStoreData) as cookieStoreType;
    const cookiesData = getCookieData();
    //useEffect init
        // try get all data -> if no data present going to login
        //else if only workspaces and organizations present going to workspaces page for choose //updating store
        //if all the data present -> updating store
    //useEffect checking cookies
        //if new data present -> update

    //

    // const handleSaveLastPath = (path: string) => {
    //     if(multiStore.currentUser && multiStore.selectedWorkspace?.id){
    //         localStorage.setItem(`${SAVE_PATH_PREFIX}${multiStore.currentUser.publicId}#${multiStore.selectedWorkspace?.id}`, path);
    //     }
    // }

    //

    useEffect(() => {
        Logging.log(`useEffect(multiStore) - init`);
        if(JSON.stringify(multiStore) !== JSON.stringify(cookiesData) && !isLoadingCookieData){
            Logging.log(`useEffect(multiStore)  - init: multiStore ${JSON.stringify(multiStore)} is NOT equel to cookies - updating cookies`);
            setCookieData(multiStore);
        }
        //used for user profile updates - if user set new first/lastName - updating store in actions
        //updating cookies by changing store
        //eslint-disable-next-line
    }, [multiStore]);

    useEffect(() => {
        Logging.log(`useEffect(multiStore) 2 - init`);
        if(multiStore.currentUser){
            Logging.log(`useEffect(multiStore) 2 - currentUser FOUND - set loading FALSE`);
            setIsLoadingCookieData(false);
        }
    }, [multiStore]);

    useEffect(() => {
        Logging.log(`isLoadingCookieData: ${isLoadingCookieData}`);
    }, [isLoadingCookieData]);

    useEffect(() => {

        //init useEffect - used to get data from cookies and put in inner store
        Logging.log(`useWorkspacesAndOrganizations - init`);
        if(cookiesData){
            if((!cookiesData.is2FaAuthComplete || !cookiesData.isAuthComplete) && !isUnProtectedPaths(pathname)){
                setIsLoadingCookieData(false);
                logout();
                history.push(PATH_LOCAL_LOGIN); //user has no cookies - sending to login
                deleteCookieByName(MAIN_DATA_COOKIE_NAME);
                return
            }
            Logging.log(`useWorkspacesAndOrganizations - init - setting cookie data`);
            //todo check if org and ws relevant
            dispatch(setSharedWorkspacesAndOrganizationsData(cookiesData));
            setIsLoadingCookieData(false);

            if(isUnProtectedPaths(pathname) && !isPathAvailableForEveryone(pathname) && cookiesData.selectedWorkspace && cookiesData.selectedOrganization){
                //if cookies t there and selected org and workspace and its unsecured path - going to frameworks (it was unprotected path like login)
                history.push('');
                Logging.log(`useWorkspacesAndOrganizations - init - ws | org | UnProtected path (${pathname}) - going to frameworks`);

            }
        }else{
            setIsLoadingCookieData(false);
            if(!isUnProtectedPaths(pathname) && !isPathAvailableForEveryone(pathname)){
                logout();
                history.push(PATH_LOCAL_LOGIN); //user has no cookies - sending to login
                Logging.log(`useWorkspacesAndOrganizations - init - no cookies - going to login`);
            }
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        Logging.log(`useWorkspacesAndOrganizations - useEffect(cookiesData)`);
        if(!isUnProtectedPaths(pathname) && !isPathAvailableForEveryone(pathname) && !cookiesData) {
            history.push(PATH_LOCAL_LOGIN);
            logout();
            Logging.log(`useWorkspacesAndOrganizations - useEffect(cookiesData) - protected path (${pathname}) | no cookies - going to login`);
            return;
            //if on protected path and has no cookies -> going to login
        }
        //eslint-disable-next-line
    }, [cookiesData]);

    useEffect(() => {
        Logging.log(`useWorkspacesAndOrganizations - useEffect(pathname)`);
        // cookiesData && cookiesData.selectedWorkspace && handleSaveLastPath(pathname);
        //this useEffect is used to watch after pathname - if user signed in and on unprotected path - going to framewroks
        if(isUnProtectedPaths(pathname) && !isPathAvailableForEveryone(pathname) && cookiesData && cookiesData.isAuthComplete && cookiesData.is2FaAuthComplete && cookiesData.selectedWorkspace && cookiesData.selectedOrganization){
            history.push('');
            Logging.log(`useWorkspacesAndOrganizations - useEffect(pathname) - uprotected path (${pathname}) | isPathAvailableForEveryone: false | cookies present | isAuthComplete: true | is2FaAuthComplete: true | selectedWorkspace | selectedOrganization - going to frameworks`);
        }
        //eslint-disable-next-line
    }, [pathname]);

    useEffect(() => {
        Logging.log(`useWorkspacesAndOrganizations - useEffect(multiStore.isAuthComplete, multiStore.is2FaAuthComplete)`);
        if(!multiStore.isAuthComplete && !isPathAvailableForEveryone(pathname) && !isLoadingCookieData){
            Logging.log(`useWorkspacesAndOrganizations - useEffect(multiStore.isAuthComplete, multiStore.is2FaAuthComplete) - isAuthComplete: false  - LOGOUT (401?)`);
            //when user signs in - setting isAuthComplete & is2FaAuthComplete to true
            //if seeing that its false - looks like user got 401 and have to logout him
            logout();
            setIsLoadingCookieData(false);
            Logging.log(`useWorkspacesAndOrganizations - useEffect(multiStore.isAuthComplete, multiStore.is2FaAuthComplete) - logout by 401 complete`);
        }
        //eslint-disable-next-line
    }, [multiStore.isAuthComplete, multiStore.is2FaAuthComplete]);

    return{
        isDisabledProtectedTopBar: !multiStore.selectedWorkspace || !multiStore.selectedOrganization,
        isLoadingCookieData,
        isSignedIn: multiStore.currentUser !== null,
        isWorkspaceSelected: multiStore.selectedWorkspace !== null
    }
}