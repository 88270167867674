import React from "react";
import {TAutocompleteCollaboratorsProps, TCollaboratorWithRole} from "../../types";
import {AutocompleteFreeSoloValueMapping, Typography} from "@mui/material";
import {CommonAvatar} from "../../../Basic/CommonAvatar";
import {getOptionLabel} from "../../helpers";
import {FlexColumn} from "../../../editorUnion/components/editorTitleWithActionsRow/styled";

type TProps<FreeSolo extends boolean | undefined> = {
    getOptionLabelCustom: TAutocompleteCollaboratorsProps<any, any, FreeSolo>['getOptionLabelCustom'],
    getOptionLabel?: TAutocompleteCollaboratorsProps<any, any, FreeSolo>['getOptionLabel']
}

export function AutocompleteCollaboratorOption <FreeSolo extends boolean | undefined> (allProps: TProps<FreeSolo>) {

    return (
        allProps.getOptionLabelCustom === 'name+email'
            ? (props: React.HTMLAttributes<HTMLLIElement>, option: TCollaboratorWithRole | AutocompleteFreeSoloValueMapping<FreeSolo>) => (
                <li {...props} key={JSON.stringify(option)} style={{...props.style, display: "flex", gap: '8px', alignItems: 'center', zIndex: '999999', position: 'relative'}}
                    onMouseDown={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                    }}
                    onMouseUp={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                    }}
                >
                    <CommonAvatar size={'d32'} name={getOptionLabel("name")(option)}/>
                    <FlexColumn>
                        {typeof option === "string" ? (
                            <Typography variant={'body2'}>{option}</Typography>
                        ) : (
                            <>
                                <Typography variant={'body2'}>{getOptionLabel("name")(option)}</Typography>
                                <Typography variant={'body2'}>{option.email}</Typography>
                            </>
                        )}
                    </FlexColumn>
                </li>
            )
            : (props: React.HTMLAttributes<HTMLLIElement>, option: TCollaboratorWithRole | AutocompleteFreeSoloValueMapping<FreeSolo>) => (
                <li {...props} key={JSON.stringify(option)}>
                    {allProps.getOptionLabel ? allProps.getOptionLabel(option) : getOptionLabel(allProps.getOptionLabelCustom)(option)}
                </li>
            )
    )
}