import {useDispatch, useSelector} from "react-redux";
import {dialogs, hidePreviewTemplate} from "../../../store/slice";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import * as S from './styled';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {FileDropZone} from "../../../../../../newShared/components/fileDropZone";
import colors from "../../../../../../newShared/theme/colors";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {forbiddenWrapperOrganizations} from "../../../../../authWorkspacesCookies/settings/store/slice";
import {DEFAULT_DATE_FORMAT} from "../../../../../../newShared/utils/dateTools";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const PreviewTemplateDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.dialogs'});
    const {currData: {selectedOrganization}} = UseManageWorkspacesAndOrganizations();
    const organizations = useSelector(forbiddenWrapperOrganizations);
    
    const org = organizations.find(e => e.id === selectedOrganization?.id);

    const dispatch = useDispatch();
    const {isOpen, template} = useSelector(dialogs).templatePreview;

    const handleClose = () => {
        dispatch(hidePreviewTemplate());
    }

    return(
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{t('Preview template')}</DialogTitle>

            <DialogContent>
                <S.PreviewTemplateContainer>
                    {/*<TobBarLogo />*/}
                    {org && <img src={org.logo} alt={org.name} width={'48px'}/>}
                    {org && <Typography align={'center'} variant={'body2'} fontWeight={700}>{t(`${org.name} asks you fill form for KYC verification.`)}</Typography>}
                    {/*<Typography variant={'h3'} fontWeight={700}>{'KYC Verification request'}</Typography>*/}
                    {template && template.fields
                        .slice()
                        .sort((a, b) => a.order - b.order)
                        .map(e => {
                            switch (e.type){
                            case 'TEXT': {
                                return <TextField
                                    disabled
                                    required={e.required}
                                    label={e.name}
                                    size={'small'}
                                />
                            }
                            case 'NUMBER': {
                                return <TextField
                                    disabled
                                    inputMode={'numeric'}
                                    required={e.required}
                                    label={e.name}
                                    size={'small'}
                                />
                            }
                            case 'DATE': {
                                return <DatePicker
                                    label={e.name}
                                    disabled
                                    value={null}
                                    inputFormat={DEFAULT_DATE_FORMAT}
                                    onChange={(newValue) => null}
                                    renderInput={(params) => <TextField required={e.required} {...params} size={'small'} />}
                                />
                            }
                            case 'FILE': {
                                return <S.FileDropContainer>
                                    <Typography fontSize={'12px'} fontWeight={600}>
                                        {e.name}
                                        {e.required && <span style={{color: colors.warningActions.red}}>*</span>}
                                    </Typography>

                                    <FileDropZone
                                        inputRef={null}
                                        fileHandler={() => null}
                                        accept={'.pdf'}
                                    />
                                </S.FileDropContainer>
                            }
                            case 'SELECT': {
                                return <Autocomplete
                                    id="combo-box-demo"
                                    options={[]}
                                    disabled
                                    // isOptionEqualToValue={(a, b) => a.key === b.key}
                                    // getOptionLabel={(e) => e.value}
                                    onChange={() => null}
                                    sx={{width: '100%'}}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label={e.name}
                                        required={e.required}
                                        fullWidth
                                        error={false}
                                        size={'small'}
                                        name={e.id}
                                        // sx={{'& fieldset': {borderColor: 'black !important'}}}
                                    />}
                                />
                            }
                            default: return <></>;
                        }
                    })}
                </S.PreviewTemplateContainer>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'} size={'medium'}>{t('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}