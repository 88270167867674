import {FC} from "react";
import {LeftSecondMenu} from "../../barsEnvironment/leftMenu/components/leftSecondMenu";
import {Routes} from "./routes";

export const Collectors: FC = () => {

    return (
        <LeftSecondMenu>
            <Routes/>
        </LeftSecondMenu>
    )
}