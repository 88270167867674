import {createAsyncThunk} from "@reduxjs/toolkit";
import {TWithOptions} from "../../../../../newShared/types";
import {
    KycGetFormByTokenQueryVariables,
    KycRejectFormByTokenMutationVariables,
    KycSubmitFormByTokenMutationVariables
} from "../../../../../newShared/GQLTypes";
import {kycGetFormByTokenApi, kycRejectFormByTokenApi, kycSubmitFormByTokenApi} from "../../api";


export const kycGetFormByTokenAction = createAsyncThunk(
    'KYC/form/kycGetFormByToken',
    async (data: TWithOptions<KycGetFormByTokenQueryVariables>, {dispatch, getState}) => {
        // const state = getState() as AppState;
        // const {workspaceId, organizationId} = getActionsData(state);

        const resp = await kycGetFormByTokenApi({...data.data}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const kycSubmitFormByTokenAction = createAsyncThunk(
    'KYC/form/kycSubmitFormByToken',
    async (data: TWithOptions<KycSubmitFormByTokenMutationVariables>, {dispatch, getState}) => {
        // const state = getState() as AppState;
        // const {workspaceId, organizationId} = getActionsData(state);

        const resp = await kycSubmitFormByTokenApi({...data.data}, data.signal);
        // const resp = await kycSubmitFormByTokenRestApi({...data.data}, data.signal);

        // if (resp.redirectUrl) {window.parent.location.replace(resp.redirectUrl)}
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const kycRejectFormByTokenAction = createAsyncThunk(
    'KYC/form/kycRejectFormByToken',
    async (data: TWithOptions<KycRejectFormByTokenMutationVariables>, {dispatch, getState}) => {
        // const state = getState() as AppState;
        // const {workspaceId, organizationId} = getActionsData(state);

        const resp = await kycRejectFormByTokenApi({...data.data}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);


