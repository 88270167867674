// Core
import {combineReducers} from 'redux';

// Reducers
import {I18NextReducer} from "../hooks/useMainTranslationHooks/slice";
import {snackReducer} from "../../newDomain/barsEnvironment/snack/store/slice";
import {leftMenuReducerReducer} from "../../newDomain/barsEnvironment/leftMenu/store/slice";
import {breadcrumbsReducer} from "../../newDomain/barsEnvironment/breadcrumbs/store/slice";
import {frameworksReducerReducer} from "../../newDomain/components/frameworks/store/frameworks/store";
import {currentFrameworkReducerReducer} from "../../newDomain/components/frameworks/store/currentFramework/store";
import {messageDialogReducerReducer} from "../../newDomain/barsEnvironment/MessageDialog/store/store";
import {frameworkControlReducerReducer} from "../../newDomain/components/frameworks/store/control/store";
import {settingsProfileReducer} from "../../newDomain/authWorkspacesCookies/settingsProfile/reducer";
import {ConfigReducer} from "../../newDomain/barsEnvironment/config/store/slice";
import {VulnerabilityScannerReducer} from "../../newDomain/components/vulnerabilityScanner/store/slice";
import {PhysicalAssetsReducer} from "../../newDomain/components/physicalAssets/store/store";
import {PDFViewReducer} from "../components/docViewer/store/slice";
import {reportsReducerReducer} from "../../newDomain/components/reports/store/reports/store";
import {reportViewReducerReducer} from "../../newDomain/components/reports/store/reportView/store";
import {RiskManagementReducer} from "../../newDomain/components/riskManagement/store/slice";
import {VendorsReducer} from "../../newDomain/components/vendors/store/slice";
import {SettingsReducer} from "../../newDomain/authWorkspacesCookies/settings/store/slice";
import {AutomationsReducer} from "../../newDomain/components/automations/store/slice";
import {newIntegrationReducer} from "../../newDomain/components/newIntegrations/store/slice";
import {BuilderReducer} from "../../newDomain/components/builder/store/builder/slice";
import {kycRequestsReducer} from "../../newDomain/components/kyc/store/requests/store";
import {kycTemplatesReducer} from "../../newDomain/components/kyc/store/templates/store";
import {kycFormReducer} from "../../newDomain/components/kyc/store/form/store";
import {
    SharedWorkspacesAndOrganizationsReducer
} from "../../newDomain/authWorkspacesCookies/cookies/store/sharedWorkspacesAndOrganizations/slice";
import {AccessManagementReducer} from "../../newDomain/components/accessManagement/store/slice";
import {CollectorReducer} from "../../newDomain/components/collectors/store/slice";
import {SigninReducer} from "../../newDomain/authWorkspacesCookies/signIn/store/slice";
import {vicaTasksReducer} from "../../newDomain/components/vica/store/tasks/slice";
import {EmployeesRefactorReducer} from "../../newDomain/components/employeesRefactor/store/slice";
import {genericFiltersReducer} from "../components/genericFilter/store/slice";
import {DocumentsRefactorReducer} from "../../newDomain/components/documentsRefactor/store/slice";
import {KycRefactorReducer} from "../../newDomain/components/kycRefactor/store/slice";
import {templatePreviewReducer} from "../components/docTemplateViewer/store/slice";
import {vicaRefactorTasksReducer} from "../../newDomain/components/vicaRefactor/store/slice";
import {contactUsReducer} from "../../newDomain/components/contactUs/store/slice";
import {trainingsReducer} from "../../newDomain/components/Trainings/store/slice";

export const rootReducer = combineReducers({
    //INIT
    auth: SigninReducer,

    snack: snackReducer,
    I18Next: I18NextReducer,
    settingsProfile: settingsProfileReducer,
    Config: ConfigReducer,
    //WORKSPACES
    SharedWorkspacesAndOrganizations: SharedWorkspacesAndOrganizationsReducer,
    // ChooseWorkspace: ChooseWorkspaceReducer,
    // ManageOrganization: ManageOrganizationReducer,
    // ManageNewWorkspaceReducer: ManageNewWorkspaceReducer,
    // Invites: InvitesReducer,
    //
    Settings: SettingsReducer,
    //
    leftMenu: leftMenuReducerReducer,
    breadcrumbs: breadcrumbsReducer,
    messageDialog: messageDialogReducerReducer,
    //
    pdfView: PDFViewReducer,
    templatePreview: templatePreviewReducer,
    //OTHER
    frameworks: frameworksReducerReducer,
    currentFramework: currentFrameworkReducerReducer,
    frameworkControl: frameworkControlReducerReducer,
    VulnerabilityScanner: VulnerabilityScannerReducer,
    reports: reportsReducerReducer,
    reportVew: reportViewReducerReducer,
    physicalAssets: PhysicalAssetsReducer,
    RiskManagement: RiskManagementReducer,
    vendors: VendorsReducer,
    documents: DocumentsRefactorReducer,
    // documents: DocumentsReducer,
    kycRequests: kycRequestsReducer,
    kycTemplates: kycTemplatesReducer,
    kycForm: kycFormReducer,
    Automations: AutomationsReducer,
    newIntegration: newIntegrationReducer,
    Builder: BuilderReducer,
    AccessManagement: AccessManagementReducer,
    Collector: CollectorReducer,
    vicaTasks: vicaTasksReducer,
    EmployeesRefactor: EmployeesRefactorReducer,
    genericFilters: genericFiltersReducer,
    kycRefactor: KycRefactorReducer,
    vicaTasksRefactor: vicaRefactorTasksReducer,
    trainings: trainingsReducer,
    contactUs: contactUsReducer,
});


export type AppState = ReturnType<typeof rootReducer>;
