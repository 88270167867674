import {MainTrainingsQuestionsShortDataForImportResponseModel} from "../../../../../newShared/GQLTypes";
import {Typography, Checkbox, TableRow, TableCell, IconButton} from "@mui/material";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import React from "react";
import {QuestionToAddTableRow} from "../questionToAddTableRow";
import {examLevelTitleMapper} from "../../constants";
import {Flex} from "../../../../../newShared/components/Layouts";
import {useQuestionsBlock} from "../../hooks/useQuestionsBlock";

export const QuestionsBlock = ({questionBlock}: {questionBlock: MainTrainingsQuestionsShortDataForImportResponseModel}) => {
    const {
        isCheckboxChecked,
        isCheckboxIndeterminate,
        handleCheckboxSelect,
        isExpanded,
        setIsExpanded} = useQuestionsBlock(questionBlock);

    return (
        <>
            <TableRow>
                <TableCell>
                    <Flex ai={'center'} jc={'flex-start'} gap={'10px'}>
                        <Checkbox checked={isCheckboxChecked} onChange={handleCheckboxSelect} indeterminate={isCheckboxIndeterminate}/>
                        {isExpanded
                            ?
                            <IconButton sx={{cursor: 'pointer'}} onClick={() => setIsExpanded(false)}>
                                <ExpandLessOutlinedIcon/>
                            </IconButton>
                            :
                            <IconButton sx={{cursor: 'pointer'}} onClick={() => setIsExpanded(true)}>
                                <ExpandMoreOutlinedIcon/>
                            </IconButton>}
                        <Typography variant={'*bodyText2'}>{questionBlock.examName}</Typography>
                    </Flex>
                </TableCell>
                <TableCell>
                    <Typography variant={'*bodyText2'}>{questionBlock.examCategory}</Typography>
                </TableCell>
                <TableCell colSpan={2}>
                    <Typography variant={'*bodyText2'}>{examLevelTitleMapper[questionBlock.examLevel]}</Typography>
                </TableCell>
            </TableRow>
            {isExpanded && questionBlock.questions &&
                questionBlock.questions.map(item => <QuestionToAddTableRow key={item.questionId} question={item} />)
            }

        </>
    )

}