import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {dialogs, hideActionDialog, loadings} from "../../../store/slice";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {toBase64} from "../../../../../../newShared/utils/base64/base64";
import {EVIDENCE_UPLOAD_TYPE} from "../../../constants";
import {uploadEvidenceAssetAction} from "../../../store/actions";

export const useActionUploadDialog = () => {
    const dispatch = useDispatch();
    const {workspaceId} = UseManageWorkspacesAndOrganizations();
    const {task, action, categoryId, isOpen} = useSelector(dialogs).actionUploadDialog;
    const {isLoadingUploadFile} = useSelector(loadings);
    const {setMessage} = useMessageDialog();

    useEffect(() => {
        if(!isOpen){
            //cleanup
            setNames([]);
            setUploadedFiles([]);
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const handleClose = () => {
        dispatch(hideActionDialog([]));
    }

    const ref = React.createRef<HTMLInputElement>();
    const [fileNames, setNames] = useState<string[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

    const fileHandler = (files: FileList | null) => {
        if(files){
            setNames([files[0].name]);
            setUploadedFiles([files[0]]);
        }
    };

    const handleDeleteFile = (fileName: string) => {
        setNames([...fileNames.filter(e => e !== fileName)]);
        setUploadedFiles([...uploadedFiles.filter(e => e.name !== fileName)]);
    }

    const filePicker = () => {
        ref.current && ref.current.click();
    };

    const isOk = () => {
        return fileNames.length > 0 && task && action;
    }

    const handleSubmit = () => {
        if(isOk() && action && task){
            toBase64(uploadedFiles[0]).then(file => {
                dispatch(uploadEvidenceAssetAction({
                    data: {
                        workspaceId: '',
                        organizationId: '',
                        data: {
                            name: fileNames[0] ?? task.name,
                            evidenceId: task.evidenceId ?? '',
                            data:[ {file: file as string}],
                            type: EVIDENCE_UPLOAD_TYPE,
                            workspaceId: workspaceId ?? '',
                        },
                        taskId: task.id,
                        categoryId: categoryId
                    },
                    onSuccess: () => {
                        setMessage({
                            title: 'Completed successfully!',
                            message: 'File has been uploaded to evidence'
                        })
                    }
                }))
            });
        }
    }

    return{
        task,
        action,
        categoryId,
        isOpen,
        handleClose,
        ref,
        fileNames,
        uploadedFiles,
        filePicker,
        fileHandler,
        handleDeleteFile,
        isOk,
        isLoadingUploadFile,
        handleSubmit
    }
}