import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_ACCESS_MANAGEMENT_APPS_LIST, PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET} from "../../constants";
import {useEffect} from "react";
import {useSegregationOfDuties} from "../useSegregationOfDuties";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {cleanConflicts, conflictsMinMaxLoadedPage, replaceMinMaxLoadedPageConflicts} from "../../store/slice";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {useDispatch, useSelector} from "react-redux";


export const useConflictList = () => {
    const dispatch = useDispatch();
    // const {filter} = useParameters();
    // const dispatch = useDispatch();

    const {
        getConflicts,
        conflicts,
        pageInfo: {page, count, total},
        loadings: {list: isLoadingList, dataForDialog: isLoadingData},
        clean,
        handleOpenViewConflict,
        handleOpenAllowConflict,
        handleOpenDenyConflict,
        goToRuleExact,
        handleOpenAllowedConflictData,
        handleOpenDeniedConflictData,

    } = useSegregationOfDuties();

    const {minLoadedPage, maxLoadedPage} = useSelector(conflictsMinMaxLoadedPage);

    // const controller = new AbortController();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});


    useSetBreadcrumbs([
        {
            title: tMenu('Access management'),
            path: PATH_ACCESS_MANAGEMENT_APPS_LIST
        },
        {
            title: tMenu('Conflict list'),
            path: PATH_ACCESS_MANAGEMENT_SEGREGATION_OF_DUTIES_RULESET
        },
    ]);


    useEffect(() => {
        // conflicts.length === 0 && getConflicts({page, count: PAGE_COUNT, filters: filter}, controller.signal);

        return () => {
            // controller.abort();
            clean();
        }
        //eslint-disable-next-line
    }, []);

    // const {value, handleChange} = useSearchFieldURL({wait: 0});
    // const {setFilter, handleKeyDown} = useFilterUrl({
    //     getData: (filters) => {getConflicts({page: 0, count: PAGE_COUNT, filters}, controller.signal, true)}
    // });
    //
    // // pagination
    // const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
    //     getData: ({page, count}) => { getConflicts({page, count: PAGE_COUNT, filters: filter}, controller.signal)},
    //     arrayLength: conflicts.length,
    //     isLoading: isLoadingList,
    //     pageInfo: {page, count, total},
    //     cleanData: () => {},
    //
    // })

    //
    const severityLevelConfig: keyConfig<string> = {
        type: 'filter',
        key: 'severityLevel',
        name: ('Severity level'),
        options: ["Low", "High", "Medium"],
        getFilterValue: (v) => v.toUpperCase(),
        default: true
    }

    const statusConfig: keyConfig<string> = {
        type: 'filter',
        key: 'status',
        name: ('Activity status'),
        options: ["UNRESOLVED", "ALLOWED", 'DENIED'],
        default: true
    }


    return {
        conflicts,
        isLoadingList,
        isLoadingData,
        isNoConflictsFound: !isLoadingList && !conflicts.length,

        handleOpenViewConflict,
        handleOpenAllowConflict,
        handleOpenDenyConflict,
        goToRuleExact,
        handleOpenAllowedConflictData,
        handleOpenDeniedConflictData,

        // filter,
        // value, handleChange, handleKeyDown, setFilter,

        // pagination: {
        //     bottomLoader: bottomLoader && ((conflicts.length < total) || (conflicts.length === total && total === 0)),
        //     onScroll,
        //     scrollRef
        // },
        genericTable: {
            paging: {
                pageInfo: {page, count, total},
                isLoading: isLoadingList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceMinMaxLoadedPageConflicts(data)),
                clearRows: () => dispatch(cleanConflicts()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    getConflicts({page, count, filters: {...filters, input: [search]}})
                },
            },
            filters: {
                configs: [statusConfig, severityLevelConfig]
            }
        }
    }
}