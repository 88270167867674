import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {evidenceType} from "../../../types";
import {editEvidenceDialog, hideEditEvidence, isLoading} from "../../../store/builder/slice";
import {EditEvidence} from "../../../store/builder/actions";


export const useEditEvidence = () => {

    const dispatch = useDispatch();
    const {isOpen, isEvidencesPage, evidence} = useSelector(editEvidenceDialog);
    const _isLoading = useSelector(isLoading);
    const [form, updateForm] = useState<evidenceType | null>(evidence);

    useEffect(() => {
        isOpen && updateForm(evidence);
        !isOpen && updateForm(null);

        //eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        evidence && updateForm(evidence);

        //eslint-disable-next-line
    }, [evidence]);



    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        updateForm((prev) => prev && ({
            ...prev,
            [event.target.name]: event.target.value as string,
        }));
    }

    const handleChangeCategories = (event:React.ChangeEvent<HTMLInputElement>) => {
        updateForm((prev) => prev && ({
            ...prev,
            categories: [event.target.value as string],
        }));
    }

    const validateForm = (): boolean => {
        return (
            !!form
            && JSON.stringify(form) !== JSON.stringify(evidence)
            && form.name.trim().length > 0
            // && form.description.trim().length > 0
            // && form.categories.length > 0
            // && form.organizationId!.length > 0
        )
    }

    const handleClose = () => {
        dispatch(hideEditEvidence());
    }

    const handleSave = () => {
        if(validateForm()) {
           form && dispatch(EditEvidence({evidence: form, isEvidencesPage}));
        }
    }

    return {
        isOpen,
        form,
        handleChange,
        handleChangeCategories,
        validateForm,
        handleSave,
        handleClose,
        isLoading: _isLoading,

    }
}