import {useDispatch, useSelector} from "react-redux";
import {cleanVendors, dialogs, replaceMinMaxPage, setImportCsvDialogAction} from "../../../store/slice";
import {
    GetCsvFieldsVendorsAction,
    GetCsvTemplateVendorsAction,
    getVendorsAction,
    PerformCsvVendorsAction
} from "../../../store/actions";
import {TImportCsvDialogProps} from "../../../../../../newShared/components/csvImportDialog";
import {resetImportCsvDialog} from "../../../../../../newShared/components/csvImportDialog/constants";
import {
    useGenericFiltersStorage
} from "../../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {DEFAULT_PAGING} from "../../../../../../newShared/components/genericTable/constants";

export const useImportCsv = (): TImportCsvDialogProps => {
    const dispatch = useDispatch();
    const {importCsvDialog: {
        isOpen,
        isLoading: {
            isGettingFields,
            isPerformingCsv,
            isLoadingTemplate,
        },
        fields,
        fileId,
        response,
    }} = useSelector(dialogs);

    const setImportDialog: TImportCsvDialogProps["setImportDialog"] = (data) => dispatch(setImportCsvDialogAction(data));
    const downloadTemplate = () => {dispatch(GetCsvTemplateVendorsAction())};

    const getFields: TImportCsvDialogProps["getFields"] = (file) => {dispatch(GetCsvFieldsVendorsAction({
        data: {...file},
        onError: resetImportCsvDialog
    }))};

    const {clearFilters} = useGenericFiltersStorage();

    const performCsv: TImportCsvDialogProps["performCsv"] = (fields) => {fileId && dispatch(PerformCsvVendorsAction({
        data: {fields, fileId},
        // snack: "success",
        // onError: (request, error, addictiveData) => {dispatch(setImportCsvDialogAction({error: error[0]?.message || 'Unknown error'}))},
        onSuccess: (request, message, addictiveData) => {
            clearFilters();
            dispatch(cleanVendors());
            dispatch(replaceMinMaxPage({minLoadedPage: -1, maxLoadedPage: -1}));
            dispatch(getVendorsAction({pageInfo: {page: 0, count: DEFAULT_PAGING, filters: {}}}));
        },
    }))};

    return {
        isOpen,
        isLoadingTemplate, isPerformingCsv, isGettingFields,
        fields,
        setImportDialog,
        downloadTemplate,
        getFields,
        performCsv,
        response,
        fieldsMapperKey: "vendors",
    }
}
