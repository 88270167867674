import {Slice, SliceCaseReducers} from "@reduxjs/toolkit";
import {IsOpenExists} from "./types";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../redux/rootReducer";

export type TSliceWithDialogs = Record<string, any> & {dialogs: Record<string, Record<string, any>>};

export type TResultDataPerDialog<TSlice extends TSliceWithDialogs, key extends Extract<keyof TSlice["dialogs"], string>> = {
    handleSet: (payload: Partial<TSlice["dialogs"][key]> & {clear?: boolean}) => void;
    handleClose: () => void;
} & TSlice["dialogs"][key] & (IsOpenExists<TSlice["dialogs"][key]> extends true ? { setIsOpen: (isOpen: TSlice["dialogs"][key]['isOpen']) => void } : {});

export const useSliceDialogsWithActions = <TSliceName extends string, TSlice extends {dialogs: Record<string, Record<string, any>>}>
(actions: Slice<TSlice, SliceCaseReducers<TSlice>, TSliceName>["actions"], dialogsSelector: (state: AppState) => TSlice["dialogs"]) => {
    const dispatch = useDispatch();
    const dialogs = useSelector(dialogsSelector);

    const data = {} as {
        [key in Extract<keyof TSlice["dialogs"], string>]: TResultDataPerDialog<TSlice, key>
    };

    Object.keys(dialogs).forEach(key => {
        const dialogName = key as Extract<keyof TSlice["dialogs"], string>;
        const actionName = `set${dialogName.charAt(0).toUpperCase() + dialogName.slice(1)}Action` as `set${Capitalize<typeof dialogName>}Action`;

        if (!actions[actionName]) throw new Error(`Action ${actionName} not found in slice. Please, add ...addSetAllDialogActions(initialState) in reducer`);

        data[dialogName] = {
            ...dialogs[dialogName],

            handleSet: (payload: Partial<TSlice["dialogs"][typeof dialogName]> & {clear?: boolean}) => {
                dispatch(actions[actionName](payload))
            },
            handleClose: () => {
                dispatch(actions[actionName]({clear: true}))
            },

            ...('isOpen' in dialogs[dialogName] ? {
                setIsOpen: (isOpen) => {
                    dispatch(actions[actionName]({isOpen}))
                }
            } : {}),
        } as TResultDataPerDialog<TSlice, typeof dialogName>;
    });

    return data as {
        [key in Extract<keyof TSlice["dialogs"], string>]: TResultDataPerDialog<TSlice, key>
    };
}