import {AddVideoSectionDialogPropsType} from "../../../types";
import * as yup from "yup";
import {Exact, MainTrainingContentType, TrainingContentNewModel,} from "../../../../../../newShared/GQLTypes";
import {useCustomFormik} from "../../../../../../newShared/hooks/useCustomFormik";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useFileDropZone} from "../../../../../../newShared/hooks/useSingleFileDropZone";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {mainTrainingUploadFileAction} from "../../../store/actions";
import {loadings} from "../../../store/slice";
import {uuid} from "../../../../../../newShared/utils";

export const useAddVideoSectionDialog = (props: AddVideoSectionDialogPropsType) => {
    const dispatch = useDispatch();
    const fileDropZone = useFileDropZone(50);
    const {t} = useMainTranslation();

    //selectors
    const {fileUpload} = useSelector(loadings);

    const [isVideoPlayerError, setVideoPlayerError] = useState<boolean>(false);

    const isUpdate = props.initialSection !== undefined;

    const validationSchema = yup.object({
        name: yup.string()
            .trim()
            .lowercase()
            .required(t('Name is required'))
            .notOneOf(isUpdate ? props.existingSections.filter(e => e.sectionId !== props.initialSection?.sectionId).map(e => e.name.trim().toLowerCase()) : props.existingSections.map(e => e.name.trim().toLowerCase()), 'Current training already has section with same name. Enter unique section name for this training')
            .max(120, 'Section name cannot be longer than 120 characters')
        ,
        type: yup.string().oneOf([MainTrainingContentType.VideoLink, MainTrainingContentType.VideoFile]),
        data: yup.object()
            .when('type', {
            is: MainTrainingContentType.VideoLink,
            then: yup.object({
                url: yup.string()
                    .required('URL is required')
                    .test('url', 'Invalid URL format', testUri)
                    // .test('url', 'Invalid URL', () => !isVideoPlayerError)
            })
        }).when('type', {
            is: MainTrainingContentType.VideoFile,
            then: yup.object({
                fileId: yup.string().required(),
                name: yup.string().required()
            })
        }),
    });

    function testUri(value?: string){
        const pattern = new RegExp('^((?:https?|ftp)?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(value ?? '');
    }

    const formik = useCustomFormik<TrainingContentNewModel>(props.isOpen, {
        initialValues: props.initialSection ?? { data: {url: '', fileId: '', name: ''}, name: '', type: MainTrainingContentType.VideoLink, sectionId: uuid(), order: props.existingSections.length},
        validationSchema,
        onSubmit: (values) => {
            if(isUpdate){
                props.onSectionUpdate({...values});
            }else{
                props.onSectionAdd({...values});
            }
        },
        initialTouched:{
            name: !!props.initialSection?.name
        }
    })

    const handleChooseVideoType = (type: MainTrainingContentType) => {
        if(type === MainTrainingContentType.VideoLink){
            formik.setValues({ ...formik.values, data: {url: ''}, type: MainTrainingContentType.VideoLink})
        }
        if(type === MainTrainingContentType.VideoFile){
            formik.setValues({ ...formik.values, data: {fileId: '', name: ''}, type: MainTrainingContentType.VideoFile})
        }
    }


    useEffect(() => {
        if(fileDropZone.fileInBase64){
            //file selected - uploading file

            dispatch(mainTrainingUploadFileAction({
                data: {fileName: fileDropZone.fileInBase64.name, fileBase64: fileDropZone.fileInBase64.file},
                onSuccess,
            }))
        }
        //eslint-disable-next-line
    }, [fileDropZone.fileInBase64]);

    const onSuccess = (request: (Omit<Exact<{workspaceId: string, fileBase64: string, fileName: string}>, "workspaceId" | "organizationId"> & {workspaceId?: string | undefined, organizationId?: string | undefined}), response: {id: string, name: string}, addictiveData?: ({} | undefined)) => {
        if(formik.values.type === MainTrainingContentType.VideoFile){
            formik.setFieldValue('data', {fileId: response.id, name: response.name, thumbnail: null})
            fileDropZone.handleDeleteFile();
        }
    }

    const handleDeleteFile = () => {
        formik.setValues({...formik.values, data: {fileId: '', name: ''}});
    }

    return{
        formik,
        isOk: (isUpdate ? formik.isValid && JSON.stringify(formik.values) !== JSON.stringify(props.initialSection) : formik.isValid) && !isVideoPlayerError,
        handleChooseVideoType,
        ...props,
        fileDropZone,
        isLoadingUpload: fileUpload,
        fileActions: {
            handleDeleteFile
        },
        isUpdate,
        testUri,
        setVideoPlayerError: (isError: boolean) => {
            setVideoPlayerError(isError);
            formik.validateField('data.url');
        },
        isVideoPlayerError
    }
}