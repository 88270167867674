import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    createByTemplateTaskApi,
    getOriginTemplateByIdApi,
    getVicaCategoriesWithPaging,
    getVicaTasksByCategory,
    getWorkspaceCollabs,
    setTaskCategoryResponsible,
    setTaskCollabs,
    uploadEvidenceAssetApi
} from "../../api";
import {getActionsData} from "../../../../../newShared/redux";
import {AppState} from "../../../../../newShared/redux/rootReducer";
import {
    fetchedWsCollabType,
    TVicaTask,
    TVicaTaskCategory,
    UploadEvidenceAssetVicaMutationVariables,
    vicaCollab
} from "../../types";
import {TPageInfo, TWithOptions} from "../../../../../newShared/types";
import {
    CreateByTemplateTaskMutationVariables,
    GetOriginTemplateByIdQueryVariables,
    MainVicaCreateDelegatedTaskMutation,
    MainVicaCreateDelegatedTaskMutationVariables,
    WorkspaceCollaboratorModel
} from "../../../../../newShared/GQLTypes";
import {mainVicaCreateDelegatedTask} from "../../api/query";
import {createCommonAsyncThunk} from "../../../../../newShared/utils/asyncThunk";

//getWorkspaceCollabs
export const GetWorkspaceCollabs = createAsyncThunk(
    'vica/getWorkspaceCollabs',
    async (data: {onSuccess?: () => void}, {getState, dispatch} ): Promise<fetchedWsCollabType[]> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        try{
            const res = await getWorkspaceCollabs(workspaceId);
            data.onSuccess && data.onSuccess();
            return res;
        }catch (e: any){
            // data.onReject && data.onReject(e.message);
            throw new Error(e.message);
        }
    }
);


//getVicaCategoriesWithPaging
export const GetVicaCategoriesWithPaging = createAsyncThunk(
    'vica/getVicaCategoriesWithPaging',
    async ({data, signal}: TWithOptions<{page: number, count: number, search: string, filters: Record<string, string[]>}>, {getState, dispatch} ): Promise<{pageInfo: TPageInfo, categories: TVicaTaskCategory[]}> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return getVicaCategoriesWithPaging(workspaceId, data.page, data.count, data.search, data.filters, signal);
    }
);

//getVicaTasksByCategory
export const GetVicaTasksByCategory = createAsyncThunk(
    'vica/getVicaTasksByCategory',
    async ({data, signal}: TWithOptions<{categoryId: string, search: string, filters: Record<string, string[]>}>, {getState, dispatch} ): Promise<{tasks: TVicaTask[], categoryId: string}> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const {tasks} = await getVicaTasksByCategory(workspaceId, data.categoryId, data.search, data.filters, signal);
        return {
            categoryId: data.categoryId,
            tasks
        }
    }
);

//setTaskCategoryResponsible
export const SetTaskCategoryResponsible = createAsyncThunk(
    'vica/setTaskCategoryResponsible',
    async ({data, signal}: TWithOptions<{categoryId: string, collab: WorkspaceCollaboratorModel | null}>, {getState, dispatch} ): Promise<{categoryId: string, collab: WorkspaceCollaboratorModel | null}> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await setTaskCategoryResponsible(workspaceId, data.categoryId, data?.collab?.publicId ?? null, signal);
        return data;
    }
);

//setTaskCollabs
export const SetTaskCollabs = createAsyncThunk(
    'vica/setTaskCollabs',
    async ({data, signal}: TWithOptions<{categoryId: string, taskId: string, collabs: vicaCollab[]}>, {getState, dispatch} ): Promise<{categoryId: string, taskId: string, collabs: vicaCollab[]}> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await setTaskCollabs(workspaceId, data.taskId, data.collabs.map(e => e.publicId), signal);
        return data;
    }
);

export const uploadEvidenceAssetAction = createAsyncThunk(
    'vica/uploadEvidenceAsset',
    async (data: TWithOptions<UploadEvidenceAssetVicaMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await uploadEvidenceAssetApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

// set actions about template (policy)
export const getOriginTemplateByIdAction = createAsyncThunk(
    'vica/getOriginTemplateById',
    async (data: TWithOptions<GetOriginTemplateByIdQueryVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await getOriginTemplateByIdApi({...data.data, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);
export const createByTemplateTaskAction = createAsyncThunk(
    'vica/createByTemplateTask',
    async (data: TWithOptions<CreateByTemplateTaskMutationVariables>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await createByTemplateTaskApi({...data.data, organizationId, workspaceId}, data.signal);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const MainVicaCreateDelegatedTaskAction =
    createCommonAsyncThunk<MainVicaCreateDelegatedTaskMutationVariables, MainVicaCreateDelegatedTaskMutation['mainVicaCreateDelegatedTask']>(
        'vicaTasks', 'mainVicaCreateDelegatedTask', true, false, mainVicaCreateDelegatedTask
    );
