import {FlexColumn, FlexRow} from "../../../editorTitleWithActionsRow/styled";
import {Box, Divider, Typography} from "@mui/material";
import colors from "../../../../../../theme/colors";
import {EditorSideMenuETAFieldStyled} from "../../styled";
import {getMsFromDays} from "../../../../../../utils/dateTools";
import React, {FC} from "react";
import {TEditorStageApprover} from "../../../../types";

type TProps = {
    groupTitle: string,
    step: TEditorStageApprover,

    value: number,
    setETA: (eta: number) => void,
}
export const VerifyApprover: FC<TProps> = ({step, setETA, value, groupTitle}) => {


    return (
        <Box key={JSON.stringify(step)}
             sx={{
                 display: 'flex', flexDirection: 'column',
                 gap: '10px'
             }}>

            {step.groups.map(group => (
                <FlexColumn gap={'10px'} key={JSON.stringify(group)}>
                    <Typography variant={"body2"} fontWeight={600} sx={{color: colors.text.dark}}>
                        {groupTitle}
                    </Typography>

                    <FlexColumn gap={'6px'}>
                        {group.actors.map(actor => (
                            <Typography key={actor.email} variant={"body2"} sx={{color: colors.text.blue}}>{actor.email}</Typography>
                        ))}
                    </FlexColumn>

                    {step.order !== 0 && (
                        <FlexRow alignItems={'center'} gap={'10px'}>
                            <EditorSideMenuETAFieldStyled
                                value={value}
                                onChange={e => {
                                    setETA(getMsFromDays(Number(e.target.value)));
                                }}/>

                            <Typography variant={"body2"} sx={{color: colors.text.dark}}>{'days to complete'}</Typography>
                        </FlexRow>
                    )}

                    {step.order !== 0 && <Divider flexItem/>}
                </FlexColumn>
            ))}
        </Box>
    )
};