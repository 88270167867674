import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {Flex} from "../../../../../../../../newShared/components/Layouts";
import {SectionButton} from "../sectionButton";
import TextFieldsIcon from '@mui/icons-material/TextFields';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';

export type AddSectionDialogPropsType = {
    isOpen: boolean,
    handleClose: () => void,
    handleCreateEditor: () => void,
    handleCreateFile: () => void,
    handleCreateVideo: () => void,
}
export const AddSectionDialog = ({isOpen, handleCreateFile, handleCreateVideo, handleCreateEditor, handleClose}: AddSectionDialogPropsType) => {
    return(
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth={'xl'}>
            <DialogTitle>{('Create new section')}</DialogTitle>
            <DialogContent>
                <Flex w={'100%'} jc={'space-between'} gap={'16px'}>
                    <SectionButton
                        width={'30%'}
                        title={'Use editor'}
                        onClick={handleCreateEditor}
                        icon={<TextFieldsIcon />}
                    />

                    <SectionButton
                        width={'30%'}
                        title={'Upload file'}
                        onClick={handleCreateFile}
                        icon={<FileUploadIcon />}
                    />

                    <SectionButton
                        width={'30%'}
                        title={'Add video'}
                        onClick={handleCreateVideo}
                        icon={<SlowMotionVideoIcon />}
                    />
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button size={'small'} variant={'text'} onClick={handleClose}>{('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}