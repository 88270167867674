import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    assetsDialogsSelector,
    loadings,
    setCreateSettingRowDialogAction,
    shortPhysicalAssetsSelector,
    softwareAssetsSettingsSelector
} from "../../store/store";
import {useForm} from "../../../../../newShared/hooks/useForm";
import {
    PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE,
    PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_ID,
    PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_NEW
} from "../../constants";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_ASSET_MANAGEMENT} from "../../../../../newShared/constants";
import {createSoftwareAssetAction, GetShortPhysicalAssets, getSoftwareAssetsSettingsAction,} from "../../store/actions";
import {TSetCreateSettingRowDialog, TSoftwareAsset} from "../../types";
import {SoftwarePhysicalAssetModel} from "../../../../../newShared/GQLTypes";


export const useSoftwareAssetNew = () => {
    //root
    const dispatch = useDispatch();
    const history = useHistory();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    //selectors
    const shortPhysicalAssets = useSelector(shortPhysicalAssetsSelector);
    const {isLoadingSettings, isLoadingCreateAsset, isLoadingShortPhysicalAssets} = useSelector(loadings);
    const settings = useSelector(softwareAssetsSettingsSelector);
    const {createAsset: {isOpen: isOpenCreateAsset}} = useSelector(assetsDialogsSelector);


    const initialSoftwareAsset: TSoftwareAsset = {
        id: '',
        name: '',
        version: '',
        installed: 0,
        installationThreshold: 0,
        physicalAssets: [],
        customFields: {},
    }
    //form
    const {form, setForm, handleChange} = useForm<TSoftwareAsset>(initialSoftwareAsset);

    const controller = new AbortController();
    useEffect(() => {
        if(!settings) {
            dispatch(getSoftwareAssetsSettingsAction({data: {organizationId: '', workspaceId: ''}, signal: controller.signal}));
        }
        if(settings){
            const customFields: { [key: string]: string } = {};
            Object.keys(settings.customFields).length && Object.keys(settings.customFields).forEach((key) => customFields[key] = '');
            setForm({...form, customFields});
        }

        return () => {
            controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(settings && settings.customFields && Object.keys(settings.customFields).length){
            const customFields: { [key: string]: string } = {};
            Object.keys(settings.customFields).forEach((key) => customFields[key] = '');
            setForm({...form, customFields});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    useEffect(() => {
        !shortPhysicalAssets.length && dispatch(GetShortPhysicalAssets(controller.signal));
        isOpenCreateAsset && setForm(initialSoftwareAsset);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenCreateAsset]);


    useSetBreadcrumbs([
        {
            title: tMenu('Asset Management'),
            path: PATH_LOCAL_ASSET_MANAGEMENT
        },
        {
            title: tMenu('Software'),
            path: PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE
        },
        {
            title: tMenu('New asset'),
            path: PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_NEW
        },
    ]);

    //actions
    const handleGoBack = () => history.push(PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE);

    const handleSave = () => {
        form.name.trim().length && dispatch(createSoftwareAssetAction({
            data: {
                workspaceId: '',
                organizationId: '',
                asset: {...form, installed: Number(form.installed), installationThreshold: Number(form.installationThreshold)},
            },
            onSuccess}));
    }

    const onSuccess = (id: string) => {
        history.push(PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE_ID.replace(':id', id));
    }


    const handleChangeCustomField = (event:React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setForm((prevValues) => ({
            ...prevValues,
            customFields: {
                ...prevValues.customFields,
                [event.target.name]: event.target.value,
            },
        }));
    };


    //CreateAssetDialog
    const openCreateSettingRowDialog = (data: TSetCreateSettingRowDialog) => {dispatch(setCreateSettingRowDialogAction(data))};

    const handleSetPhysicalAsset = (pa: SoftwarePhysicalAssetModel) => {
        let tmp: SoftwarePhysicalAssetModel[] = (form && form.physicalAssets && form?.physicalAssets?.length > 0) ? form.physicalAssets : [];
        setForm(prev => prev && ({...prev, physicalAssets: [...tmp , pa]}));
    }

    const handleDeletePhysicalAsset = (id: string) => {
        setForm(prev => prev && ({...prev, physicalAssets: prev.physicalAssets?.filter( e => e.id !== id )}));
    }

    return {
        settings,
        form,
        handleChange,

        handleSave,
        handleGoBack,
        onSuccess,
        handleChangeCustomField,

        isLoadingSettings,
        isLoadingCreateAsset,

        createAssetDialog: {
            isOpenCreateAsset,
        },

        openCreateSettingRowDialog,
        isLoadingShortPhysicalAssets,
        handleSetPhysicalAsset,
        shortPhysicalAssets,
        handleDeletePhysicalAsset
    }
}
