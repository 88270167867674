import { gql } from "@apollo/react-hooks";

export const employeeFragment = gql`
  fragment employeeFragment on HrEmployeeModel {
    collaborator {
      publicId
      email
      firstName
      lastName
    }
    workspaceId
    maritalStatus
    id
    employeeId
    firstName
    lastName
    nickname
    middleName
    hireDate
    birthDay
    workEmail
    customFields
    status {
      id
      textColor
      backgroundColor
      name
    }
    gender
    ethnicity
    contactInfo {
      contacts {
        type
        value
      }
      address {
        city
        street
        country
        zip
      }
      emergencyContacts {
        relationType
        fullName
        phone
        email
        address
      }
    }
    personalData {
      education {
        schoolName
        degree
        fieldOfStudy
        startDate
        endDate
      }
      veteranStatus {
        activeDutyWartimeOrCampaignBadgeVeteran
        armedForcesServiceMedalVeteran
        disabledVeteran
        recentlySeparatedVeteran
      }
    }
    jobInfos {
      id
      jobTitle {
        jobType
        name
        description
        requirements
      }
      employmentType
      location
      department
      division
      manager {
        name
        id
      }
      startDate
      current
    }
    trainings {
      name
      status
      completionDate
      completionStatus
    }
    documents
    accounts {
      id
      name
      logo
      app {
        id
        name
        logo
      }
    }
  }
`;

export const mainHrRequestFragment = gql`
  fragment mainHrRequestFragment on MainHrRequestModel {
    id
    workspaceId
    type
    requestor {
      employeeId
      fullName
    }
    requestDate
    status
    resolver {
      employeeId
      employeeFullName
      publicId
      collaboratorFullName
    }
    resolveDate
    data {
      editProfile {
        fieldName
        fieldValue
      }
    }
  }
`;

export const mainHrIssueFragment = gql`
  fragment mainHrIssueFragment on MainHrIssueModel {
    id
    issueType
    requestDate
    resolution
    resolveDate
    status
    description
    workspaceId
    anonymous
    requestor {
      employeeId
      fullName
    }
    offender {
      employeeId
      fullName
    }
    resolver {
      collaboratorFullName
      employeeFullName
      employeeId
      publicId
    }
  }
`;

export const mainHrTimeOffFragment = gql`
  fragment mainHrTimeOffFragment on MainHrTimeOffRequestModel {
    id
    startDate
    endDate
    type
    status
    comment
    attachments {
      fileId
      fileName
    }
    approvalRoutes {
      routeType
      steps {
        stepNumber
        type
        status
        resolver {
          email
          employeeId
          employeeFullName
          publicId
          collaboratorFullName
        }
        resolveDate
        comment
      }
    }
    requestor {
      email
      employeeId
      employeeFullName
      publicId
      collaboratorFullName
    }
    requestDate
    currentRouteType
    currentStepNumber
  }
`;
