import React, {Component, createRef, RefObject} from "react";
import {ClickAwayListener, Divider, Typography} from "@mui/material";
import {
    EditorBlockItemType,
    FunctionsForToolBar,
    IStatusProps,
    MapDispatchToPropsObject,
    TEditorElementData,
    TEditorPagebreakElementData,
    TResize
} from "../../types";
import {EditorItemPagebreakStyled} from "./styled";
import {Delete} from "@mui/icons-material";
import {ToolBarProps} from "../editorToolBar";
import {EditorItemRow} from "../editorItemRow";
import {EditorPopperButtons} from "../commonComponents/editorPopperButtons";
import {EditorWorkZone} from "../editorWorkzone";
import colors from "../../../../theme/colors";


interface Props extends IStatusProps {
    block: TEditorPagebreakElementData;
    onDelete: () => void;

    parentRef: RefObject<EditorItemRow>["current"];
    editorWorkZoneRef: RefObject<EditorWorkZone>["current"];
}
interface State {
    id: string;
    type: 'pagebreak';
    data: TEditorElementData["data"];

    //for resizing
    resize: TResize,

    popper: {open: boolean, anchorEl: HTMLDivElement | null},

    wrapperRef:  RefObject<HTMLDivElement>;
}

export class EditorItemPagebreak extends Component<Props, State> implements FunctionsForToolBar {
    constructor(props: Props) {
        super(props);

        this.state = {
            id: props.block.id,
            type: EditorBlockItemType.PAGEBREAK,
            data: props.block.data,

            resize: {x: 0, px: 0, percent: 0},
            popper: {open: false, anchorEl: null},

            wrapperRef: createRef<HTMLDivElement>(),
        }
    }
    
    setActiveBlock: (ids: string[]) => void = () => {};

    getFlexBasis = (): number => {
        return 100;
    };
    setFlexBasis = (value: number) => {};

    setPopper = (open: boolean, anchorEl: HTMLDivElement | null) => {
        this.setState({
            popper: {
                open: open,
                anchorEl: anchorEl,
            }
        });
    }

    ///////ToolBar
    setToolBar: MapDispatchToPropsObject["setToolBar"] = (toolBar, ids) => {
        this.props.parentRef?.setToolBar(toolBar, [this.state.id, ...ids])
    }
    setTextByToolBar: ToolBarProps["toolBarHandler"] = (action, payload, ids) => {
        // const nextId = ids[0];
        // if (nextId) {
        //     const ref = this.state.refs[nextId].current;
        //     if (ref) {
        //         ref.setTextByToolBar(action, payload, ids.slice(1))
        //     }
        // }
    }

    render() {

        return (
            <ClickAwayListener onClickAway={() => this.setPopper(false, null)}>
                <EditorItemPagebreakStyled ref={this.state.wrapperRef}
                                           onClick={e => this.setPopper(true, e.currentTarget)}>
                    <div>
                        <Typography variant={"body2"} align={"center"} color={colors.text.grey}>Page break</Typography>
                        <Divider flexItem/>
                    </div>

                    {!this.props.readonly && (
                        <EditorPopperButtons popperId={this.state.id + ' popper'}
                                             open={this.state.popper.open} anchorEl={this.state.wrapperRef}
                                             buttons={[
                                                 [
                                                     {title: 'Delete', icon: Delete, onClick: this.props.onDelete},
                                                 ]
                                             ]}/>
                    )}
                </EditorItemPagebreakStyled>
            </ClickAwayListener>
        );
    }
}