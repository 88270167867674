import {TypeRequestFilter} from "../../utils";
import {useHistory} from "react-router";
import {replaceFilter} from "../../utils/pagination";
import {KeyboardEvent} from "react";
import {useParameters} from "../useParameters";

export const useFilterUrl = ({getData, customHistoryPush}: {
    getData: (filters: TypeRequestFilter) => void,
    customHistoryPush?: (search: string) => void,
}) => {
    const history = useHistory();
    const {filter} = useParameters();

    const setFilter = (key: string, value?: string) => {
        const newFilter = filter;
        if (value) newFilter[key] = [value]
        else delete newFilter[key];

        const search = replaceFilter(history.location.search, newFilter, false);
        if (!!customHistoryPush) customHistoryPush(search)
        else history.push(history.location.pathname + search);

        getData(filter)
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement> | {keyCode: number}) => {
        event.keyCode === 13 && getData(filter)
    }

    return {
        setFilter,
        handleKeyDown
    }
}