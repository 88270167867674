import {styled, TableBody, TableContainer, TableHead} from "@mui/material";
import colors from "../../../../../../../newShared/theme/colors";

export const FileRowContainer = styled('div')({
    width: '100%',
    gap: '10px',
    display: "flex",
    alignItems: 'center',
    marginTop: '16px'
})

export const CsvHeadersTableContainer = styled(TableContainer)({
    boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    borderRadius: '8px !important',
    border: `1px solid #EAECF0`,
    margin: '16px 0 0 0',

    // minHeight: '300px',

    '& .MuiTableCell-root:nth-of-type(1)': {
        width: '40%'
    },
    '& .MuiTableCell-root:nth-of-type(3)': {
        width: '10%'
    },
    '& .MuiTableCell-root:nth-of-type(4)': {
        width: '10%'
    },
})

export const CsvHeadersTableHead = styled(TableHead)({
    '& .MuiTableCell-root': {
        padding: '10px 8px 10px 16px',
        backgroundColor: colors.backgrounds.grey_light
    }
})

export const CsvHeadersTableBody = styled(TableBody)({
    '& .MuiTableCell-root': {
        padding: '10px 8px 10px 16px',
    }
})
