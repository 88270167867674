import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import ReactCrop, {centerCrop, Crop, makeAspectCrop} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import {CROP_IMAGE_ASPECT_RATIO} from "../../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {mainUploadCoverImageAction} from "../../../../store/actions";
import {loadings} from "../../../../store/slice";
import {LoadingButton} from "../../../../../../../newShared/components/Basic/CommonButtons";

export type CropImageDialogPropsType = {
    isOpen: boolean;
    handleClose: () => void;
    imageBase64?: string | undefined,
    onSave: (image: {imageId: string, thumbnailId: string}) => void,
}
export const CropImageDialog = ({isOpen, imageBase64, onSave, handleClose}: CropImageDialogPropsType) => {
    const dispatch = useDispatch();
    const {uploadCoverImage} = useSelector(loadings);
    const [cropConfig, setCropConfig] = useState<Crop>();
    const [croppedImage, setCroppedImage] = useState<string | null>(null);

    const imageRef = useRef<HTMLImageElement>(null);

    function onImageLoad(e: any) {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget

        const crop = centerCrop(
            makeAspectCrop(
                {unit: '%', width: 90,},
                CROP_IMAGE_ASPECT_RATIO,
                width,
                height
            ),
            width,
            height
        )

        setCropConfig(crop)
    }

    async function cropImage(crop: any) {
        if (imageRef.current && crop.width && crop.height) {
            const _croppedImage = getCroppedImage(imageRef.current, crop,);
            setCroppedImage(_croppedImage);
        }
    }

    function getCroppedImage(sourceImage: HTMLImageElement, cropConfig: any) {
        // creating the cropped image from the source image
        // const container = document.getElementById('canvas-container');
        const canvas = document.createElement('canvas');
        const scaleX = sourceImage.naturalWidth / sourceImage.width;
        const scaleY = sourceImage.naturalHeight / sourceImage.height;

        canvas.width = cropConfig.width;
        canvas.height = cropConfig.height;

        const ctx = canvas.getContext('2d');

        // @ts-ignore
        ctx.drawImage(
            sourceImage,
            cropConfig.x * scaleX,
            cropConfig.y * scaleY,
            cropConfig.width * scaleX,
            cropConfig.height * scaleY,
            0,
            0,
            cropConfig.width,
            cropConfig.height
        );

        return canvas.toDataURL('image/png', 1);
    }

    const handleSave = () => {
        croppedImage && !uploadCoverImage && dispatch(mainUploadCoverImageAction({
            data: {file: croppedImage, format: 'png'},
            onSuccess: (request, response) => {
                onSave(response);
            }
        }))
    }

    useEffect(() => {
        if(!isOpen){
            setCropConfig(undefined);
            setCroppedImage(null);
        }
    }, [isOpen]);

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>
                {'Crop new cover image'}
            </DialogTitle>

            <DialogContent>
                {imageBase64 && (
                    <ReactCrop
                        onComplete={(c) => cropImage(c)}
                        crop={cropConfig}
                        onChange={(crop) => setCropConfig(crop)}
                        aspect={CROP_IMAGE_ASPECT_RATIO}
                        minHeight={200}
                    >
                        <img
                            ref={imageRef}
                            alt="Crop me"
                            src={imageBase64}
                            style={{width: '100%'}}
                            onLoad={onImageLoad}
                            // style={{width: '550px', height: '310px',
                                // objectFit: 'contain'
                            // onLoad={(imageRef) => setImageRef(imageRef)}
                            // onLoad={onImageLoad}
                            // onImageLoaded={setImage}
                        />
                    </ReactCrop>
                )}

                {/*{imageBase64 &&*/}
                {/*    <ReactCrop*/}
                {/*        crop={crop}*/}
                {/*        onChange={c => setCrop(c)}*/}
                {/*        maxWidth={125}*/}
                {/*        maxHeight={94}*/}
                {/*        minWidth={124}*/}
                {/*        minHeight={93}*/}
                {/*    >*/}
                {/*        <img src={imageBase64} width={'100%'} alt=""/>*/}
                {/*    </ReactCrop>*/}
                {/*}*/}
            </DialogContent>

            <DialogActions>
                <Button variant={'text'} size={'small'} onClick={handleClose}>{('Cancel')}</Button>

                <LoadingButton size={'small'} loading={uploadCoverImage} disabled={!croppedImage} onClick={handleSave}>
                    {('Set new image')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}