import {gql} from "@apollo/react-hooks";
import {evidenceAutoFile, mainVicaCategoryStepFragment, mainVicaDelegatedTaskModelFragment} from "./fragments";
import {pageInfoFragment} from "../../../../newShared/graphQl/fragments";
import {DelegatedTaskModelFragment} from "../../frameworks/api/fragments";


export const mainVicaGetCategoriesWithFilter = gql`
    query mainVicaGetCategoriesWithFilter($workspaceId: String!, $filters: MainVicaCategoryStepsFilter!) {
        mainVicaGetCategoriesWithFilter(workspaceId: $workspaceId, filters: $filters) {
            result {
                name
                order
                supervisor {
                    collaboratorId
                    fullName
                    email
                }
                delegatedTasks
                progress{
                    totalSteps
                    completedSteps
                }
            }
        }
    }

`;

export const mainVicaGetCategoryStepsWithFilter = gql`
    query mainVicaGetCategoryStepsWithFilter($workspaceId: String!, $filters: MainVicaCategoryStepsFilter!, $category: String!) {
        mainVicaGetCategoryStepsWithFilter(workspaceId: $workspaceId, filters: $filters, category: $category) {
            result {...mainVicaCategoryStepFragment}
        }
    }
    ${mainVicaCategoryStepFragment}
`;

export const mainVicaGetCategoryStepById = gql`
    query mainVicaGetCategoryStepById($workspaceId: String!, $stepId: String!) {
        mainVicaGetCategoryStepById(workspaceId: $workspaceId, stepId: $stepId) {
            ...mainVicaCategoryStepFragment
        }
    }
    ${mainVicaCategoryStepFragment}
`;

export const mainVicaGetStepResponsibleCollaboratorsAutocomplete = gql`
    query mainVicaGetStepResponsibleCollaboratorsAutocomplete($workspaceId: String!, $pageRequest: SharedPaginationInput!, $search: String!) {
        mainVicaGetStepResponsibleCollaboratorsAutocomplete(workspaceId: $workspaceId, pageRequest: $pageRequest, search: $search) {
            result {
                collaboratorId
                fullName
                email
            }
            pageInfo {
                page
                count
                total
            }
        }
    }

`;

export const mainVicaUpdateCategorySupervisor = gql`
    mutation mainVicaUpdateCategorySupervisor($workspaceId: String!, $categoryId: String!, $supervisor: MainVicaCategoryCollaboratorInput!) {
        mainVicaUpdateCategorySupervisor(workspaceId: $workspaceId, categoryId: $categoryId, supervisor: $supervisor) {
            message
        }
    }

`;

export const mainVicaChangeStepStatus = gql`
    mutation mainVicaChangeStepStatus($workspaceId: String!, $stepId: String!, $status: MainVicaStepStatus!) {
        mainVicaChangeStepStatus(workspaceId: $workspaceId, stepId: $stepId, status: $status) {
            message
        }
    }

`;

export const mainVicaUpdateStepResponsibleCollaborators = gql`
    mutation mainVicaUpdateStepResponsibleCollaborators($workspaceId: String!, $stepId: String!, $responsible: [MainVicaCategoryCollaboratorInput!]!) {
        mainVicaUpdateStepResponsibleCollaborators(workspaceId: $workspaceId, stepId: $stepId, responsible: $responsible) {
            message
        }
    }

`;

export const mainVicaGetDelegatedTasksWithFilterPagination = gql`
    query mainVicaGetDelegatedTasksWithFilterPagination($workspaceId: String!, $vicaStepId: String!, $pageRequest: SharedPaginationInput!, $filter: MainFrameworksDelegatedTasksFilter!) {
        mainVicaGetDelegatedTasksWithFilterPagination(workspaceId: $workspaceId, vicaStepId: $vicaStepId, pageRequest: $pageRequest, filter: $filter) {
            result {...mainVicaDelegatedTaskModelFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${mainVicaDelegatedTaskModelFragment}
`;
export const mainVicaGetDelegatedTaskPerformerAutocomplete = gql`
    query mainVicaGetDelegatedTaskPerformerAutocomplete($workspaceId:String!, $vicaStepId: String!, $pageRequest:SharedPaginationInput!, $search: String!){
        mainVicaGetDelegatedTaskPerformerAutocomplete(workspaceId:$workspaceId, vicaStepId: $vicaStepId, pageRequest:$pageRequest, search: $search){
            result {
                collaboratorId
                fullName
            }
            pageInfo{...pageInfoFragment}
        }
    }
    ${pageInfoFragment}
`

export const mainVicaGetDelegatedTaskResolverAutocomplete = gql`
    query mainVicaGetDelegatedTaskResolverAutocomplete($workspaceId:String!, $vicaStepId: String!, $pageRequest:SharedPaginationInput!, $search: String!){
        mainVicaGetDelegatedTaskResolverAutocomplete(workspaceId:$workspaceId, vicaStepId: $vicaStepId, pageRequest:$pageRequest, search: $search){
            result {
                collaboratorId
                fullName
            }
            pageInfo{...pageInfoFragment}
        }
    }
    ${pageInfoFragment}
`

export const mainVicaGetDelegatedTaskRequestorAutocomplete = gql`
    query mainVicaGetDelegatedTaskRequestorAutocomplete($workspaceId:String!, $vicaStepId: String!, $pageRequest:SharedPaginationInput!, $search: String!){
        mainVicaGetDelegatedTaskRequestorAutocomplete(workspaceId:$workspaceId, vicaStepId: $vicaStepId, pageRequest:$pageRequest, search: $search){
            result {
                collaboratorId
                fullName
            }
            pageInfo{...pageInfoFragment}
        }
    }
    ${pageInfoFragment}
`

export const mainVicaGetWSCollaborators = gql`
    query mainVicaGetWSCollaborators($workspaceId: String!) {
        mainVicaGetWSCollaborators(workspaceId: $workspaceId) {
            result {
                collaboratorId
                fullName
                email
            }
        }
    }
`

export const mainVicaGetDelegatedTaskById = gql`
    query mainVicaGetDelegatedTaskById($workspaceId: String!, $taskId: String!) {
        mainVicaGetDelegatedTaskById(workspaceId: $workspaceId, taskId: $taskId) {
            ...DelegatedTaskModelFragment
        }
    }
    ${DelegatedTaskModelFragment}
`

export const mainVicaCancelDelegatedTaskById = gql`
    mutation mainVicaCancelDelegatedTaskById($workspaceId: String!, $taskId: String!, $reason: String!) {
        mainVicaCancelDelegatedTaskById(workspaceId: $workspaceId, taskId: $taskId, reason: $reason) {
            ...DelegatedTaskModelFragment
        }
    }
    ${DelegatedTaskModelFragment}
`

export const mainVicaDeclineDelegatedTaskById = gql`
    mutation mainVicaDeclineDelegatedTaskById($workspaceId: String!, $taskId: String!, $reason: String!) {
        mainVicaDeclineDelegatedTaskById(workspaceId: $workspaceId, taskId: $taskId, reason: $reason) {
            ...DelegatedTaskModelFragment
        }
    }
    ${DelegatedTaskModelFragment}
`

export const mainVicaApproveDelegatedTaskById = gql`
    mutation mainVicaApproveDelegatedTaskById($workspaceId: String!, $taskId: String!, $selectedAttachments: FrameworksDelegatedTaskDataFilesListInput!) {
        mainVicaApproveDelegatedTaskById(workspaceId: $workspaceId, taskId: $taskId, selectedAttachments: $selectedAttachments) {
            ...DelegatedTaskModelFragment
        }
    }
    ${DelegatedTaskModelFragment}
`
export const uploadEvidenceAsset = gql`
    mutation uploadEvidenceAsset($organizationId: String!, $workspaceId: String!, $data: EvidenceAssetInputDto!){
        uploadEvidenceAsset(organizationId: $organizationId, workspaceId: $workspaceId, data: $data) {
            ...evidenceAutoFile
        }
    }
    ${evidenceAutoFile}
`
export const mainVicaCreateDelegatedTask = gql`
    mutation mainVicaCreateDelegatedTask($workspaceId: String!, $task: MainVicaCreateDelegatedTaskInput!) {
        mainVicaCreateDelegatedTask(workspaceId: $workspaceId, task: $task) {
            message
        }
    }

`;
