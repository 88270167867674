import React from "react";
import {Flex} from "../../../../../newShared/components/Layouts";
import * as S from '../../components/styled';
import {Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useAddedApps} from "../../hooks/useAddedApps";
import {AddedAppCard} from "../../components/addedAppCard";
import {AppCardSkeleton} from "../../components/appCardSkeleton";
import {EmptyArrayImage} from "../../../../../newShared/components/EmptyArrayImage";
import {AppInDevDialog} from "../../components/dialogs/appInDevDialog";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {GenericFiltersArray} from "../../../../../newShared/components/genericFilter";

export const AddedAppsList = () => {
    const {apps, availables, actions, isLoading, isNoApps, isNoResult, fetchByFilters} = useAddedApps();
    const {t} = useMainTranslation('', {keyPrefix: 'pathIntegrations.AddedAppsList'});

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={t('Installed integrations')}
                actions={[]}
            />

            <GenericFiltersArray
                configs={[]}
                fetchResultWithSelectedFilters={fetchByFilters}
                isAddFilters={false}
            />

            {/*<S.FilterContainer>*/}
            {/*    <CustomSearchField*/}
            {/*        variant={'outlined'}*/}
            {/*        onChange={search.handleChange}*/}
            {/*        value={search.form.search}*/}
            {/*        onClear={search.handleCleanSearch}*/}
            {/*        disabled={isLoading}*/}
            {/*        onKeyDown={search.handleEnterKey}*/}
            {/*        size="small"*/}
            {/*        fullWidth*/}
            {/*        name={'search'}*/}
            {/*        label={t('Search')}*/}
            {/*        dir={'ltr'}*/}
            {/*        placeholder={t('Search')}*/}
            {/*    />*/}
            {/*</S.FilterContainer>*/}

            {isNoResult && <Typography color={colors.darkBlueText} sx={{margin: '8px 0 0 0'}}>{t('Nothing was found for your query. Try changing the query.')}</Typography>}

            <EmptyArrayImage
                type={'installedApps'}
                text={t('No installed apps found.')}
                secondaryText={t('Install new one here')}
                onClick={actions.handleGoToCreateApp}
                isShowing={isNoApps}
            />

            <S.IntegrationCardsContainer>
                {isLoading && <AppCardSkeleton />}
                {apps.map(e => <AddedAppCard
                    key={e.applicationId}
                    app={e}
                    onClick={actions.handleGoToApp}
                    currentApp={availables.find(a => a.id === e.detailsId)}
                />)}
            </S.IntegrationCardsContainer>

            <AppInDevDialog />
        </Flex>
    )
}