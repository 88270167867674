import {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {
    PATH_LOCAL_VENDORS,
    PATH_LOCAL_VENDORS_ADD,
    PATH_LOCAL_VENDORS_SELECTED_VENDOR,
    PATH_LOCAL_VENDORS_SETTINGS,
    PATH_LOCAL_VENDORS_SETTINGS_EXACT,
} from "./constants"
import {VendorsMain} from "./pages/main";
import {AddVendor} from "./pages/addVendor";
import {ExactVendor} from "./pages/exactVendor";
import {VendorsSettings} from "./pages/settings";
import {ExactSetting} from "./pages/exactSetting";

export const Routes: FC = () => {
    return (
        <Switch>
            <Route exact path={PATH_LOCAL_VENDORS} component={VendorsMain}/>
            <Route exact path={PATH_LOCAL_VENDORS_ADD} component={AddVendor}/>
            <Route exact path={PATH_LOCAL_VENDORS_SELECTED_VENDOR} component={ExactVendor}/>
            <Route exact path={PATH_LOCAL_VENDORS_SETTINGS} component={VendorsSettings}/>
            <Route exact path={PATH_LOCAL_VENDORS_SETTINGS_EXACT} component={ExactSetting}/>
            <Redirect to={PATH_LOCAL_VENDORS}/>
        </Switch>
    )
}