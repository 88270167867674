import {UpdateVariablePopperTypes} from "../../types";
import {CommonPopper} from "../selectVariablePopper/styled";
import React from "react";
import {Button} from "@mui/material";
import {useUpdateVariablePopper} from "./useUpdateVariablePopper";
import * as S from './styled';

export const UpdateVariablePopper = (props: UpdateVariablePopperTypes) => {
    const {variable, handleChange, onSubmit} = useUpdateVariablePopper(props);
    return(
        <CommonPopper open={Boolean(props.element) && props.isFocused} anchorEl={props.element} onMouseDown={(e) => {e.stopPropagation()}}>
            <S.CommonPopperInnerWrapper onClick={(e) => e.stopPropagation()}>
                <S.PopperValueInput
                    autoFocus
                    onChange={(e) => handleChange(e.currentTarget.value)}
                    value={variable?.value ?? ''}
                    label={variable?.title}
                    helperText={variable?.description}
                    fullWidth
                />

                <S.ButtonsContainer>
                    <Button variant={'text'} size={'small'} onClick={props.handleClose}>
                        Cancel
                    </Button>

                    <Button variant={'contained'} size={'small'} onClick={onSubmit}>
                        Apply
                    </Button>
                </S.ButtonsContainer>
            </S.CommonPopperInnerWrapper>
        </CommonPopper>
    )
}