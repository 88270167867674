import {styled} from "@mui/material";
import {FlexColumn} from "../../editorTitleWithActionsRow/styled";
import colors from "../../../../../theme/colors";

export const EditorItemPagebreakStyled = styled(FlexColumn)`
  flex-basis: 100%;
  
  & > div {
    padding: 10px;
    flex-grow: 1;
    cursor: pointer;
    
    & > hr {
      margin-top: 4px;
      border-bottom: 1px dashed ${colors.text.grey};
    }
  }
`;