import {Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, TextField} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSkipTimeOffRequest} from "../../../hooks/dialogHooks/useSkipTimeOffRequest";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {useRequestError} from "../../../hooks/dialogHooks/useRequestError";
import {useHrTimeOffHistory} from "../../../hooks/dialogHooks/useTimeOffHistory";

export const SkipTimeOffRequest = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathHRManagement.pathTimeOff'})
    const {
        handleClose,
        isOpen,
        handleSkip,
        formik,
        isLoadingSkip,
        timeoffId
    } = useSkipTimeOffRequest();

    const {isOpen: isOpenError} = useRequestError();
    const {isOpen: isOpenHistory} = useHrTimeOffHistory();

    return (
        <Dialog open={isOpen && !isOpenError && ! isOpenHistory} onClose={handleClose}>
            <DialogTitle>{t('Skip time off request')}</DialogTitle>
            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <Typography variant={'*bodyText2'}>{t('You are about to skip request {{id}} manager approval step. Are you sure you want to skip the step?', {id: timeoffId})}</Typography>
                <Typography variant={'*bodyText2'}>{t('Provide comment for skipping.')}</Typography>
                <TextField
                    multiline
                    rows={4}
                    required
                    label={t('Comment')}
                    onChange={formik.handleChange}
                    value={formik.values.comment}
                    onBlur={formik.handleBlur}
                    name={'comment'}
                    error={Boolean(formik.errors.comment)}
                    helperText={formik.errors.comment}

                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={'text'} size={'small'}>{(tCommon('Cancel'))}</Button>
                <LoadingButton disabled={!formik.isValid || !formik.values.comment.trim().length}
                               variant={"contained"}
                               onClick={() => {
                                   timeoffId && handleSkip(timeoffId)
                               }}
                               loading={isLoadingSkip}
                               size={'small'}>
                    {t('Skip')}
                </LoadingButton>

            </DialogActions>
        </Dialog>
    )
}