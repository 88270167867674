import {FC} from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useCurrentFramework} from "../../../hooks/useCurrentFramework";
import {useFrameworksDialog} from "../../../hooks/useFrameworksDialog";
import {DeleteDialogButton} from "../../../../../../newShared/components/deleteDialogButton";

export const RemoveFrameworkDialog: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathFrameworks.Dialogs'});

    const {removeFramework} = useCurrentFramework();
    const {removeFramework: {
        isOpen, isLoading, setRemoveFrameworkDialog
    }} = useFrameworksDialog();

    const handleClose = () => {setRemoveFrameworkDialog({clear: true})}

    return (
        <DeleteDialogButton isOpen={isOpen} isLoading={isLoading}
                            title={t('Remove framework')}
                            message={t('If you delete information about the framework, you will not be able to restore it.')}
                            handleConfirm={removeFramework}
                            handleClose={handleClose}
        />
    )
}
