import {gql} from "@apollo/react-hooks";
import {
    mainTrainingAssignmentModelFragment,
    mainTrainingExamModelFragment,
    mainTrainingModelFragment,
    mainTrainingTemplateModelFragment,
    questionsFullDataFragment,
    questionsShortDataFragment,
    sectionsFullDataFragment,
    sectionsShortDataFragment
} from "./fragments";

export const mainTrainingsGetTrainingsWithFilterPagination = gql`
    query mainTrainingsGetTrainingsWithFilterPagination($workspaceId: String!, $pageRequest: SharedPaginationInput!, $filter: MainTrainingsFilterInput!) {
        mainTrainingsGetTrainingsWithFilterPagination(workspaceId: $workspaceId, pageRequest: $pageRequest, filter: $filter) {
            result {...mainTrainingModelFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${mainTrainingModelFragment}
`;

export const mainTrainingsGetTrainingByIdAndVersion = gql`
    query mainTrainingsGetTrainingByIdAndVersion($workspaceId: String!, $trainingId: String!, $version: String!, ) {
        mainTrainingsGetTrainingByIdAndVersion(workspaceId: $workspaceId, trainingId: $trainingId, version: $version) {
            ...mainTrainingModelFragment
        }
    }
    ${mainTrainingModelFragment}
`;


export const mainTrainingsGetExamsWithFilterPagination = gql`
    query mainTrainingsGetExamsWithFilterPagination($workspaceId: String!, $pageRequest: SharedPaginationInput!, $filter: MainTrainingExamsFilterInput!) {
        mainTrainingsGetExamsWithFilterPagination(workspaceId: $workspaceId, pageRequest: $pageRequest, filter: $filter) {
            result {...mainTrainingExamModelFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${mainTrainingExamModelFragment}
`;

export const mainTrainingsGetExamCategoriesAutocomplete = gql`
    query mainTrainingsGetExamCategoriesAutocomplete($workspaceId:String!, $pageRequest:SharedPaginationInput!, $search: String!){
        mainTrainingsGetExamCategoriesAutocomplete(workspaceId:$workspaceId, pageRequest:$pageRequest, search: $search){
            result 
            pageInfo{
                page
                count
                total
            }
        }
    }
`

export const mainTrainingsGetTrainingsCategoriesAutocomplete = gql`
    query mainTrainingsGetTrainingsCategoriesAutocomplete($workspaceId: String!, $pageRequest: SharedPaginationInput!, $data: String!) {
        mainTrainingsGetTrainingsCategoriesAutocomplete(workspaceId: $workspaceId, pageRequest: $pageRequest, data: $data) {
            result
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
`;

export const mainTrainingsGetExamByIdAndVersion = gql`
    query mainTrainingsGetExamByIdAndVersion($workspaceId: String!, $examId: String!, $version: String!, ) {
        mainTrainingsGetExamByIdAndVersion(workspaceId: $workspaceId, examId: $examId, version: $version) {
            ...mainTrainingExamModelFragment
        }
    }
    ${mainTrainingExamModelFragment}
`;

export const mainTrainingsChangeTrainingName = gql`
    mutation mainTrainingsChangeTrainingName($workspaceId: String!, $trainingId: String!, $name: String!) {
        mainTrainingsChangeTrainingName(workspaceId: $workspaceId, trainingId: $trainingId, name: $name) {
            message
        }
    }
`;

export const mainTrainingsChangeTrainingStatus = gql`
    mutation mainTrainingsChangeTrainingStatus($workspaceId: String!, $trainingId: String!, $status: MainTrainingStatus!) {
        mainTrainingsChangeTrainingStatus(workspaceId: $workspaceId, trainingId: $trainingId, status: $status) {
            message
        }
    }
`;

export const mainTrainingsChangeExamName = gql`
    mutation mainTrainingsChangeExamName($workspaceId: String!, $examId: String!, $name: String!) {
        mainTrainingsChangeExamName(workspaceId: $workspaceId, examId: $examId, name: $name) {
            message
        }
    }
`;

export const mainTrainingsCreateExam = gql`
    mutation mainTrainingsCreateExam($workspaceId:String!, $exam: MainTrainingExamInput!){
        mainTrainingsCreateExam(workspaceId:$workspaceId, exam: $exam){
            ...mainTrainingExamModelFragment
        }
    }
    ${mainTrainingExamModelFragment}
`

export const mainTrainingsUpdateExam = gql`
    mutation mainTrainingsUpdateExam($workspaceId:String!, $exam: MainTrainingExamInput!){
        mainTrainingsUpdateExam(workspaceId:$workspaceId, exam: $exam){
            ...mainTrainingExamModelFragment
        }
    }
    ${mainTrainingExamModelFragment}
`

export const mainTrainingUploadFile = gql`
    mutation mainTrainingUploadFile($workspaceId: String!, $fileBase64: String!, $fileName: String!){
        mainTrainingUploadFile(workspaceId: $workspaceId, fileBase64: $fileBase64, fileName: $fileName){
            id
            name
        }
    }
`

export const mainTrainingsCreateTraining = gql`
    mutation mainTrainingsCreateTraining($workspaceId: String!, $data: MainCreateTrainingInputDto!){
        mainTrainingsCreateTraining(workspaceId: $workspaceId, data: $data){
            ...mainTrainingModelFragment
        }
    }
    ${mainTrainingModelFragment}
`
export const mainTrainingsMoveExamToArchive = gql`
    mutation mainTrainingsMoveExamToArchive($workspaceId: String!, $examId: String!) {
        mainTrainingsMoveExamToArchive(workspaceId: $workspaceId, examId: $examId) {
            message
        }
    }
`;

export const mainTrainingsRestoreExamFromArchive = gql`
    mutation mainTrainingsRestoreExamFromArchive($workspaceId: String!, $examId: String!) {
        mainTrainingsRestoreExamFromArchive(workspaceId: $workspaceId, examId: $examId) {
            message
        }
    }
`;

export const mainTrainingsUpdateTraining = gql`
    mutation mainTrainingsUpdateTraining($workspaceId: String!, $data: MainTrainingModelInput!){
        mainTrainingsUpdateTraining(workspaceId: $workspaceId, data: $data){
            ...mainTrainingModelFragment
        }
    }
    ${mainTrainingModelFragment}
`

export const mainGetSystemCovers = gql`
    query mainGetSystemCovers($workspaceId: String!, $type: String!){
        mainGetSystemCovers(workspaceId: $workspaceId, type: $type){
            id
            type
            image
            thumbnail
        }
    }
`

export const mainUploadCoverImage = gql`
    mutation mainUploadCoverImage($workspaceId: String!, $file: String!, $format: String!){
        mainUploadCoverImage(workspaceId: $workspaceId, file: $file, format: $format){
            imageId
            thumbnailId
        }
    }
`


export const mainTrainingsGetAssignmentsWithFilterPagination = gql`
    query mainTrainingsGetAssignmentsWithFilterPagination($workspaceId: String!, $pageRequest: SharedPaginationInput!, $filter: MainTrainingAssignmentFilterInput!) {
        mainTrainingsGetAssignmentsWithFilterPagination(workspaceId: $workspaceId, pageRequest: $pageRequest, filter: $filter) {
            result {
                id
                recipient
                requestor
                requestDate
                startDate
                endDate
                status
                trainingName
                examName
            }
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
`;

export const mainTrainingsGetAssignmentDetails = gql`
    query mainTrainingsGetAssignmentDetails($workspaceId: String!, $assignmentId: String!) {
        mainTrainingsGetAssignmentDetails(workspaceId: $workspaceId, assignmentId: $assignmentId, ) {
            ...mainTrainingAssignmentModelFragment
        }
    }
    ${mainTrainingAssignmentModelFragment}
`;


export const mainTrainingGetAssignmentRequestorsAutocomplete = gql`
    query mainTrainingGetAssignmentRequestorsAutocomplete($workspaceId: String!, $pageRequest: SharedPaginationInput!, $search: String!) {
        mainTrainingGetAssignmentRequestorsAutocomplete(workspaceId: $workspaceId, pageRequest: $pageRequest, search: $search) {
            result {
                collaboratorId
                fullName
            }
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
`;

export const mainTrainingGetAssignmentRecipientsAutocomplete = gql`
    query mainTrainingGetAssignmentRecipientsAutocomplete($workspaceId: String!, $pageRequest: SharedPaginationInput!, $search: String!) {
        mainTrainingGetAssignmentRecipientsAutocomplete(workspaceId: $workspaceId, pageRequest: $pageRequest, search: $search) {
            result {
                collaboratorId
                fullName
            }
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
`;


export const mainTrainingGetAssignmentTrainingsAutocomplete = gql`
    query mainTrainingGetAssignmentTrainingsAutocomplete($workspaceId: String!, $pageRequest: SharedPaginationInput!, $search: String!) {
        mainTrainingGetAssignmentTrainingsAutocomplete(workspaceId: $workspaceId, pageRequest: $pageRequest, search: $search) {
            result {
                id
                name
            }
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
`;

export const mainTrainingGetAssignmentExamsAutocomplete = gql`
    query mainTrainingGetAssignmentExamsAutocomplete($workspaceId: String!, $pageRequest: SharedPaginationInput!, $search: String!) {
        mainTrainingGetAssignmentExamsAutocomplete(workspaceId: $workspaceId, pageRequest: $pageRequest, search: $search) {
            result {
                id
                name
            }
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
`;

export const mainTrainingsCreateTrainingAssignment = gql`
    mutation mainTrainingsCreateTrainingAssignment($workspaceId: String!, $data: MainCreateTrainingAssignmentInput!){
        mainTrainingsCreateTrainingAssignment(workspaceId: $workspaceId, data: $data){
            ...mainTrainingAssignmentModelFragment
        }
    }
    ${mainTrainingAssignmentModelFragment}
`

export const mainTrainingsCancelTrainingAssignment = gql`
    mutation mainTrainingsCancelTrainingAssignment($workspaceId: String!, $assignmentId: String!, $reason: String!){
        mainTrainingsCancelTrainingAssignment(workspaceId: $workspaceId, assignmentId: $assignmentId, reason: $reason){
            message
        }
    }
`


export const mainTrainingsGetSectionsShortDataListForImportWithFilterPagination = gql`
    query mainTrainingsGetSectionsShortDataListForImportWithFilterPagination($workspaceId: String!, $pageRequest: SharedPaginationInput!, $filter: MainSectionsShortDataFilterInput!) {
        mainTrainingsGetSectionsShortDataListForImportWithFilterPagination(workspaceId: $workspaceId, pageRequest: $pageRequest, filter: $filter) {
            result {...sectionsShortDataFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${sectionsShortDataFragment}
`;

export const mainTrainingsGetSectionsFullDataListForImport = gql`
    query mainTrainingsGetSectionsFullDataListForImport($workspaceId: String!, $filters: MainSectionsFullDataFilterInput!) {
        mainTrainingsGetSectionsFullDataListForImport(workspaceId: $workspaceId, filters: $filters) {
            result {...sectionsFullDataFragment}
        }
    }${sectionsFullDataFragment}
`;

export const mainTrainingsGetTrainingsAutocomplete = gql`
    query mainTrainingsGetTrainingsAutocomplete($workspaceId: String!, $pageRequest: SharedPaginationInput!, $value: String!, $includeArchive: Boolean!) {
        mainTrainingsGetTrainingsAutocomplete(workspaceId: $workspaceId, pageRequest: $pageRequest, value: $value, includeArchive: $includeArchive) {
            result {
                trainingId
                name 
                category
                level
                limitMinDuration
            }
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
`;

export const mainTrainingsGetQuestionsShortDataListForImportWithFilterPagination = gql`
    query mainTrainingsGetQuestionsShortDataListForImportWithFilterPagination($workspaceId: String!, $pageRequest: SharedPaginationInput!, $filter: MainQuestionsShortDataFilterInput!) {
        mainTrainingsGetQuestionsShortDataListForImportWithFilterPagination(workspaceId: $workspaceId, pageRequest: $pageRequest, filter: $filter) {
            result {...questionsShortDataFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${questionsShortDataFragment}
`;

export const mainTrainingsGetQuestionsFullDataListForImport = gql`
    query mainTrainingsGetQuestionsFullDataListForImport($workspaceId: String!, $filters: MainQuestionsFullDataFilterInput!) {
        mainTrainingsGetQuestionsFullDataListForImport(workspaceId: $workspaceId, filters: $filters) {
            result {...questionsFullDataFragment}
        }
    }${questionsFullDataFragment}
`;

export const mainTrainingsGetExamsAutocomplete = gql`
    query mainTrainingsGetExamsAutocomplete($workspaceId: String!, $pageRequest: SharedPaginationInput!, $value: String!, $includeArchive: Boolean!) {
        mainTrainingsGetExamsAutocomplete(workspaceId: $workspaceId, pageRequest: $pageRequest, value: $value, includeArchive: $includeArchive) {
            result {
                examId
                name
                category
                level
                timeToPass
                totalPoints
                examPassingPoints
            }
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
`;

export const mainTrainingsAcceptValidationReviewByAssignmentIdAndExamType = gql`
    mutation mainTrainingsAcceptValidationReviewByAssignmentIdAndExamType($workspaceId: String!, $examType: TrainingAssignmentExamType!, $assignmentId: String!, $questions: [MainTrainingAssignmentExamValidationInput!]!) {
        mainTrainingsAcceptValidationReviewByAssignmentIdAndExamType(workspaceId: $workspaceId, examType: $examType, assignmentId: $assignmentId, questions: $questions) {
            message
        }
    }
`;

export const mainTrainingsGetTrainingTemplatesWithFilterPagination = gql`
    query mainTrainingsGetTrainingTemplatesWithFilterPagination($pageRequest: SharedPaginationInput!, $filter: MainTrainingTemplateFilterInput!) {
        mainTrainingsGetTrainingTemplatesWithFilterPagination(pageRequest: $pageRequest, filter: $filter) {
            result {
                id
                name
                description
                category
                coverImage {
                    imageName
                    type
                    image
                    thumbnail
                    fileType
                }
            }
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
`;

export const mainTrainingsGetTrainingTemplateById = gql`
    query mainTrainingsGetTrainingTemplateById($templateId: String!) {
        mainTrainingsGetTrainingTemplateById(templateId: $templateId) {
            ...mainTrainingTemplateModelFragment
        }
    }
    ${mainTrainingTemplateModelFragment}
`;

export const mainTrainingsGetTemplatesCategoriesAutocomplete = gql`
    query mainTrainingsGetTemplatesCategoriesAutocomplete($pageRequest: SharedPaginationInput!, $search: String!) {
        mainTrainingsGetTemplatesCategoriesAutocomplete(pageRequest: $pageRequest, search: $search) {
            result
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
`;