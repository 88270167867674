import Box from "@mui/material/Box/Box";
import {useRuleExact} from "../../../hooks/useRuleExact";
import React from "react";
import {
    Button,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import colors from "../../../../../../newShared/theme/colors";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {MinimizeInfoWrapper} from "../../../../../../newShared/components/minimizeInfoWrapper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CommonSwitch from "../../../../../../newShared/components/Basic/CommonSwitch";
import {TableRowSkeleton} from "../../../../../../newShared/components/tableRowSkeleton";
import {DeleteRuleDialog} from "../../../components/dialogs/deleteRule";
import {LoadingButton} from "@mui/lab";
import {TerminateRuleCheckDialog} from "../../../components/dialogs/terminateRuleCheck";
import {RunRuleCheckDialog} from "../../../components/dialogs/runRuleCheck";
import {SaveConflictsSuccessDialog} from "../../../components/dialogs/saveConflictsSuccess";
import {EditRuleDialog} from "../../../components/dialogs/editRule";
import {DeleteExceptionDialog} from "../../../components/dialogs/deleteException";
import {ExtendExceptionDialog} from "../../../components/dialogs/extendException";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";


export const RuleExact = () => {
    const {isMobile, isLessThen1050} = useMedia();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        selectedRule: rule,
        goToRuleset,
        isLoadingExact,
        isLoadingUpdate,
        isLoadingUpdateException,
        isLoadingRunRuleCheck,
        isOpenExtendDialog,
        handleOpenEditRule,
        handleOpenDeleteRule,
        handleChangeIsActive,
        handleOpenRunRuleCheck,
        handleOpenExtendException,
        handleOpenDeleteException,
        handleDenyException,
        // menu
    } = useRuleExact();


    return (
        <Box display={'flex'} flexDirection={'column'} width={'100%'} height={'100%'}>

            <PageTitleAndActions
                title={rule?.name}
                handleGoBack={goToRuleset}
                showMenuWhen={isLessThen1050}
                isChildrenOnRight={!revDir || !isLessThen1050}
                actions={[
                    {
                        title: t('Run rule check'),
                        onClick: () => {rule && handleOpenRunRuleCheck(rule)},
                        // variant: 'text',
                        // icon: <PlayArrowIcon />,
                        loading: isLoadingRunRuleCheck,
                        disabled: !rule || isLoadingExact || isLoadingRunRuleCheck
                    },
                    {
                        title: t('Edit rule'),
                        onClick: () => {rule && handleOpenEditRule(rule)},
                        variant: 'text',
                        icon: <EditIcon />,
                        loading: isLoadingExact,
                        disabled: !rule || isLoadingExact || isLoadingRunRuleCheck
                    },
                    {
                        title: t('Delete rule'),
                        onClick: () => {rule && handleOpenDeleteRule(rule)},
                        variant: 'text',
                        icon: <DeleteIcon />,
                        loading: isLoadingExact,
                        disabled: !rule || isLoadingExact || isLoadingRunRuleCheck
                    },

                ]}
            >
                <CommonSwitch disabled={!rule || isLoadingExact || isLoadingUpdate} onChange={(e) => rule && handleChangeIsActive(e, {...rule, active: !rule.active})} checked={rule?.active} label={rule?.active ? t('Active') : t('Inactive')} />
            </PageTitleAndActions>

            {/*<Breadcrumbs/>*/}
            {/*<Box display={'flex'} alignItems={'center'} marginTop={'11px'} marginBottom={isMobile ? undefined : '32px'}*/}
            {/*     justifyContent={ (isMobile || isTablet) ? 'space-between' : 'flex-start'}>*/}
            {/*    {isLoadingExact ? <Skeleton width={'170px'} height={'24px'}/> :*/}
            {/*        <Box display={'flex'}>*/}
            {/*            <IconButton sx={{*/}
            {/*                color: colors.text.blue,*/}
            {/*                margin: !revDir ? `${!isLessThen1050 ? 11 : 4}px 12px ${!isLessThen1050 ? 4 : 2}px 0` : `${!isLessThen1050 ? 11 : 4}px 0 ${!isLessThen1050 ? 4 : 2}px 12px`,*/}
            {/*                padding: '0'*/}
            {/*            }}*/}
            {/*                        onClick={goToRuleset} size={isLessThen1050 ? 'small' : undefined}>*/}
            {/*                {!revDir ? <ArrowBack/> : <ArrowForward/>}*/}
            {/*            </IconButton>*/}
            {/*            <Typography variant={!isLessThen1050 ? "h1" : 'h3'} component={"span"}*/}
            {/*                        sx={{mr: !revDir ? '32px' : undefined, ml: revDir ? '32px' : undefined}}>{rule?.name}</Typography>*/}
            {/*        </Box>*/}
            {/*    }*/}
            {/*    {isMobile ?*/}
            {/*        <>*/}
            {/*            <IconButton*/}
            {/*                aria-label="more"*/}
            {/*                id="long-button"*/}
            {/*                aria-controls={menu.open ? 'long-menu' : undefined}*/}
            {/*                aria-expanded={menu.open ? 'true' : undefined}*/}
            {/*                aria-haspopup="true"*/}
            {/*                onClick={menu.handleClick}*/}
            {/*            >*/}
            {/*                <MoreVertIcon sx={{color: colors.primary.blue}}/>*/}
            {/*            </IconButton>*/}
            {/*            <Menu*/}
            {/*                id="long-menu"*/}
            {/*                MenuListProps={{*/}
            {/*                    'aria-labelledby': 'long-button',*/}
            {/*                }}*/}
            {/*                anchorEl={menu.anchorEl}*/}
            {/*                open={menu.open}*/}
            {/*                onClose={menu.handleClose}*/}
            {/*                PaperProps={{*/}
            {/*                    style: {*/}
            {/*                        maxHeight: 48 * 4.5,*/}
            {/*                        width: '20ch',*/}
            {/*                    },*/}
            {/*                }}>*/}
            {/*                <MenuItem onClick={() => {rule && handleOpenRunRuleCheck(rule)}} disabled={!rule || isLoadingExact || isLoadingRunRuleCheck}>*/}
            {/*                    <Typography>{t('Run rule check')}</Typography>*/}
            {/*                </MenuItem>*/}
            {/*                <MenuItem onClick={() => {rule && handleOpenEditRule(rule)}} disabled={!rule || isLoadingExact || isLoadingRunRuleCheck}>*/}
            {/*                    <Typography>{t('Edit rule')}</Typography>*/}
            {/*                </MenuItem>*/}
            {/*                <MenuItem onClick={() => {rule && handleOpenDeleteRule(rule)}} disabled={!rule || isLoadingExact || isLoadingRunRuleCheck}>*/}
            {/*                    <Typography>{t('Delete rule')}</Typography>*/}
            {/*                </MenuItem>*/}
            {/*            </Menu>*/}
            {/*        </>*/}
            {/*        :*/}
            {/*        <>*/}
            {/*            <LoadingButton size={isMobile ? 'small' : 'medium'} variant={'contained'}*/}
            {/*                           disabled={!rule || isLoadingExact || isLoadingRunRuleCheck}*/}
            {/*                           onClick={() => {rule && handleOpenRunRuleCheck(rule)}}*/}
            {/*                           loadingPosition={isLoadingRunRuleCheck ? 'start' : undefined} loading={isLoadingRunRuleCheck}*/}
            {/*                           startIcon={isLoadingRunRuleCheck ? <div style={{width: '10px', height: '10px', marginLeft: revDir ? '9px' : undefined }}/> : undefined}*/}
            {/*                           sx={{mr: '16px', ml: '16px', textTransform: 'none'}}*/}
            {/*            >*/}
            {/*                {t('Run rule check')}*/}
            {/*            </LoadingButton>*/}
            {/*            <Button startIcon={<EditIcon />}*/}
            {/*                    variant={'text'}*/}
            {/*                    size={isMobile ? 'small' : 'medium'}*/}
            {/*                    sx={{mr: !revDir ? '16px' : undefined, ml: revDir ? '16px' : undefined, textTransform: 'none'}}*/}
            {/*                    disabled={!rule || isLoadingExact || isLoadingRunRuleCheck}*/}
            {/*                    onClick={() => {rule && handleOpenEditRule(rule)}} >{t('Edit rule')}</Button>*/}
            {/*            <Button startIcon={<DeleteIcon />}*/}
            {/*                    variant={'text'}*/}
            {/*                    size={isMobile ? 'small' : 'medium'}*/}
            {/*                    sx={{mr: !revDir ? '16px' : undefined, ml: revDir ? '16px' : undefined, textTransform: 'none'}}*/}
            {/*                    disabled={!rule || isLoadingExact || isLoadingRunRuleCheck}*/}
            {/*                    onClick={() => {rule && handleOpenDeleteRule(rule)}} >{t('Delete rule')}</Button>*/}
            {/*            <CommonSwitch disabled={!rule || isLoadingExact || isLoadingUpdate} onChange={(e) => rule && handleChangeIsActive(e, {...rule, active: !rule.active})} checked={rule?.active} label={rule?.active ? t('Active') : t('Inactive')} />*/}
            {/*        </>*/}
            {/*    }*/}
            {/*    </Box>*/}
            {/*{isMobile && <CommonSwitch disabled={!rule || isLoadingExact || isLoadingUpdate} sx={{m: 0}} onChange={(e) => rule && handleChangeIsActive(e, {...rule, active: !rule.active})} checked={rule?.active} label={rule?.active ? t('Active') : t('Inactive')} />}*/}

            <MinimizeInfoWrapper>
                <Box display={"flex"} alignItems={"center"} m={'8px 0'}>
                    <Typography variant={"body1"} color={colors.lightGrayText}>{`${t('Included to SoD autorun')}:`}</Typography>
                    <Typography variant={"body1"} color={colors.text.dark} m={'0 8px'}>
                        {rule ? rule?.active ? "Active" : "Inactive" : <Skeleton width={'200px'}/> }
                    </Typography>
                </Box>
            </MinimizeInfoWrapper>

            {!isLessThen1050 ?
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {t('First duty')}</TableCell>
                            <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {t('Second duty')}</TableCell>
                            <TableCell  align={revDir ? 'right' : 'left'}> {t('Severity level')}</TableCell>
                            <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {t('Security risk')}</TableCell>
                            <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {t('Security mitigation')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {rule?.firstDuty.name}</TableCell>
                            <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {rule?.secondDuty.name}</TableCell>
                            <TableCell align={revDir ? 'right' : 'left'}> {rule?.severityLevel}</TableCell>
                            <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {rule?.securityRisk}</TableCell>
                            <TableCell sx={{maxWidth: '200px'}} align={revDir ? 'right' : 'left'}> {rule?.securityMitigation}</TableCell>
                        </TableRow>
                        {isLoadingExact && <TableRowSkeleton columns={5} />}
                    </TableBody>
                </Table>
                :
                <>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} sx={{borderRadius: '8px', m: '6px', p: '8px', boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)'}}>
                        <Box display={'flex'} >
                            <Typography variant={'body2'} sx={{color: colors.lightGrayText, whiteSpace: 'nowrap'}}>{isLoadingExact ? <Skeleton width={'70px'} /> : t('First duty')}</Typography>
                            <Typography variant={'body2'} sx={{color: colors.darkText, ml: !revDir ? '6px' : undefined, mr: revDir ? '6px' : undefined}}>{isLoadingExact ? <Skeleton width={'150px'} /> : rule?.firstDuty.name}</Typography>
                        </Box>
                        <Box display={'flex'}>
                            <Typography variant={'body2'} sx={{color: colors.lightGrayText, whiteSpace: 'nowrap'}}>{isLoadingExact ? <Skeleton width={'70px'} /> : t('Second duty')}</Typography>
                            <Typography variant={'body2'} sx={{color: colors.darkText, ml: !revDir ? '6px' : undefined, mr: revDir ? '6px' : undefined}}>{isLoadingExact ? <Skeleton width={'150px'} /> : rule?.secondDuty.name}</Typography>
                        </Box>
                        <Box display={'flex'}>
                            <Typography variant={'body2'} sx={{color: colors.lightGrayText, whiteSpace: 'nowrap'}}>{isLoadingExact ? <Skeleton width={'70px'} /> : t('Severity level')}</Typography>
                            <Typography variant={'body2'} sx={{color: colors.darkText, ml: !revDir ? '6px' : undefined, mr: revDir ? '6px' : undefined}}>{isLoadingExact ? <Skeleton width={'150px'} /> : rule?.severityLevel}</Typography>
                        </Box>
                        <Box display={'flex'}>
                            <Typography variant={'body2'} sx={{color: colors.lightGrayText, whiteSpace: 'nowrap'}}>{isLoadingExact ? <Skeleton width={'70px'} /> : t('Security risk')}</Typography>
                            <Typography variant={'body2'} sx={{color: colors.darkText, ml: !revDir ? '6px' : undefined, mr: revDir ? '6px' : undefined}}>{isLoadingExact ? <Skeleton width={'150px'} /> : rule?.securityRisk}</Typography>
                        </Box>
                        <Box display={'flex'}>
                            <Typography variant={'body2'} sx={{color: colors.lightGrayText, whiteSpace: 'nowrap'}}>{isLoadingExact ? <Skeleton width={'70px'} /> : t('Security mitigation')}</Typography>
                            <Typography variant={'body2'} sx={{color: colors.darkText, ml: !revDir ? '6px' : undefined, mr: revDir ? '6px' : undefined}}>{isLoadingExact ? <Skeleton width={'150px'} /> : rule?.securityMitigation}</Typography>
                        </Box>
                    </Box>
                </>

            }

            <TableContainer sx={{mt: '16px'}} className={'withoutBorder'}>
                <Table>
                    <TableBody>
                        {rule && rule.conflictExceptions?.map((e, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ padding: 0, borderBottom: 'none' }}>
                                    <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} sx={{borderRadius: '8px', m: '6px', p: '8px', boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)'}}>
                                        <Typography variant={'body1'}>{e.active ? t('Exception added') : `${t('Exception was denied by')} ${e.deniedBy?.name}`}</Typography>
                                        <Box display={'flex'} sx={{mt: '8px'}}>
                                            <Typography variant={'body2'} sx={{color: colors.text.grey_dark, whiteSpace: 'nowrap'}}>{t('Account')}</Typography>
                                            <Typography variant={'body2'} sx={{ml: '6px', mr: '6px'}} >{e.account}</Typography>
                                        </Box>
                                        <Box display={'flex'} sx={{mt: '8px'}}>
                                            <Typography variant={'body2'} sx={{color: colors.text.grey_dark, whiteSpace: 'nowrap'}}>{t('Specify resolution')}</Typography>
                                            <Typography variant={'body2'} sx={{ml: '6px', mr: '6px'}} >{e.resolution}</Typography>
                                        </Box>
                                        <Box display={'flex'} sx={{mt: '8px'}}>
                                            <Typography variant={'body2'} sx={{color: colors.text.grey_dark, whiteSpace: 'nowrap'}}>{t('Specify reason')}</Typography>
                                            <Typography variant={'body2'} sx={{ml: '6px', mr: '6px'}} >{e.reason}</Typography>
                                        </Box>
                                        <Box display={'flex'} sx={{mt: '8px'}}>
                                            <Typography variant={'body2'} sx={{color: colors.text.grey_dark, whiteSpace: 'nowrap'}}>{t('Allow till')}</Typography>
                                            <Typography variant={'body2'} sx={{ml: '6px', mr: '6px'}}>{e?.allowedDate.split("T")[0]}</Typography>
                                        </Box>
                                        {e.active &&

                                            <Box display={'flex'} alignItems={'flex-start'}>

                                                <LoadingButton size={isMobile ? 'small' : 'large'}
                                                               variant={'text'}
                                                               sx={{textTransform: 'none', pl: '2px', pr: '2px'}}
                                                               disabled={ isLoadingUpdateException}
                                                               onClick={() => handleDenyException(e, rule.id)} loading={isLoadingUpdateException && !isOpenExtendDialog }
                                                               loadingPosition={isLoadingUpdateException ? 'end' : undefined}
                                                               endIcon={isLoadingUpdateException ? <div style={{width: '10px', height: '10px', marginLeft: revDir ? '12px' : undefined, marginRight: !revDir ? '12px' : undefined }}/> : undefined}
                                                >
                                                    {t('Deny exception')}
                                                </LoadingButton>

                                                {(e.active && new Date(e.allowedDate) < new Date()) &&
                                                    <Button disabled={ isLoadingUpdateException} variant={'text'} sx={{textTransform: 'none', pl: '2px', pr: '2px', mr: '6px', ml: '6px'}}
                                                            onClick={() => handleOpenExtendException(e, rule.id)}>{t('Extend exception')}</Button>
                                                }
                                            </Box>

                                        }
                                        {!e.active && <Button variant={'text'} sx={{textTransform: 'none', pl: '2px', pr: '2px'}}
                                                             onClick={() => handleOpenDeleteException(e, rule.id)}>{t('Delete exception')}</Button>}
                                        {/*{(e.active && new Date(e.allowedDate) < new Date()) &&*/}
                                        {/*    <Box display={'flex'} alignItems={'flex-start'}>*/}
                                        {/*        <Button variant={'text'} sx={{textTransform: 'none', pl: '2px', pr: '2px'}}*/}
                                        {/*                onClick={() => handleOpenExtendException(e, rule.id)}>{t('Extend exception')}</Button>*/}
                                        {/*    </Box>*/}
                                        {/*}*/}
                                    </Box>

                                </TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>

            <DeleteRuleDialog />
            <DeleteExceptionDialog />
            <ExtendExceptionDialog />
            {!isLoadingRunRuleCheck && <RunRuleCheckDialog/>}
            <TerminateRuleCheckDialog />
            <SaveConflictsSuccessDialog />
            <EditRuleDialog />
        </Box>
    )
}