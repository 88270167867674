import {Box, Button, Divider, Menu, MenuItem, styled, TableRow, Typography} from "@mui/material";
import colors from "../../../../../../../newShared/theme/colors";
import {TypographyBody1, TypographyBody2} from "../../../../../../../newShared/components/Inputs/styled";
import {LoadingButton} from "../../../../../../../newShared/components/Basic/CommonButtons";


export const ScreeningDataContainer = styled(Box)(() => ({
    marginTop: '18px',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: '32px 20px',
    borderRadius: '6px',
    border: `1px solid ${colors.stroke.grey}`,
    flexGrow: 1,
    boxShadow: '0px 0px 1px 0px rgba(125, 151, 174, 0.02), 0px 2px 6px 0px rgba(125, 151, 174, 0.02), 0px 8px 16px 0px rgba(125, 151, 174, 0.08)',
}));



export const TypographyRowBox = styled(Box)(() => ({
    width: '150px',
}));

export const SmallTextButton = styled(Button)(() => ({
    textTransform: 'none',
    width: 'max-content',
}));

SmallTextButton.defaultProps = {
    variant: 'text',
    size: 'small'
}

export const CenterSmallTextButton = styled(SmallTextButton)(() => ({
    margin: 'auto',
}));

 export const TypographyBody1Grey = styled(TypographyBody1)(() => ({
     color: colors.text.grey,
 }));

export const TypographyBody2Warning = styled(TypographyBody2, {
    shouldForwardProp: (propName) => (
        !["revDir"].includes(propName.toString())
    )})(({revDir}:{revDir?: true}) => ({
    marginLeft: !revDir ? '6px' : undefined,
    marginRight: revDir ? '6px' : undefined,
    color: colors.text.warning,
}));

export const DataDivider = styled(Divider)(() => ({
    margin: '20px 0 6px',
    color: colors.text.grey_light_2,
}));


DataDivider.defaultProps = {
    flexItem: true,
    variant: 'fullWidth',
    orientation: 'horizontal'
};

export const ExceptionsFlexContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
    width: '300px'
}));

export const ExceptionsDataBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '250px'
}));

export const ExceptionTextSpan = styled('span')(() => ({
    color: colors.text.dark,
}));

export const QuickExceptionButton = styled(LoadingButton)({
    textTransform: 'none',
    width: 'max-content',
});
QuickExceptionButton.defaultProps = {
    size: 'small',
    variant: 'text'
}


export const MenuButton = styled(LoadingButton)({
    textTransform: 'none',
})

MenuButton.defaultProps = {
    size: 'small',
    variant: 'text'
}

export const CardMenu = styled(Menu)({
    '& .MuiMenu-list': {
        padding: '8px'
    },
    '& .MuiMenuItem-root': {
        margin: '0 auto',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: '#F2F4F7'
        }
    }
})

CardMenu.defaultProps = {
    PaperProps: {
        style: {
            boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)',
            borderRadius: '8px',
            border: '1px solid #F2F4F7'
        },
    },
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
    }
}

export const MenuItemStyled = styled(MenuItem)({
    padding: '8px'
})

export const ListItemText = styled(Typography)({
    color: colors.grayText
})

ListItemText.defaultProps = {variant: 'body1'}

export const SpanLink = styled('span')(() => ({
    '&:hover': {
        color: colors.primary.blue,
    },
    color: colors.text.dark,
    cursor: 'pointer',
}));

export const MatchingRatioSpan = styled('span')(() => ({
    color: colors.text.dark,
}));

export const TableHeadBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
}));

export const ResultTableRow = styled(TableRow)(() => ({
    '& .MuiTableCell-root': {
        backgroundColor: colors.backgrounds.grey_light,
        top: '33px'
    }
}));

export const InfoRound = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    border: '1px solid grey',
    width: '14px',
    height: '14px',
}))




