import {Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {useUpdateEmployeeTreeNode} from "../../../hooks/dialogHooks/useUpdateEmployeeTreeNode";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {LoadingButton} from "@mui/lab";
import React from "react";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {CategoryFieldsContainer} from "../../categoryFieldsContainer";
import colors from "../../../../../../newShared/theme/colors";
import {LocalSpinner} from "../../../../../../newShared/components/Spinner";
import EditIcon from '@mui/icons-material/Edit';
import {IconTextButton} from "../../../../../../newShared/components/Basic/CommonButtons";

export const UpdateEmployeeTreeNode = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathHRManagement.tree'});
    const {tCommon} = useMainTranslation();
    // const {isMobile} = useMedia();

    const {
        settings,
        isOk,
        isOpen,
        form,
        generalInfo,

        handleChange,
        handleClose,
        handleSave,
        isLoadingExact,
        isLoadingHrSettings,
        isLoadingEditPersonalInfo,
        goToEditPersonalPage
    } = useUpdateEmployeeTreeNode();

    // const inputWidth = isMobile ? '100%' : '49%';
    const inputWidth = '320px';
    const inputBackgroundColor = colors.backgrounds.white;

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{tCommon('Edit')}</DialogTitle>
            <DialogContent>

                {isLoadingExact ? (
                    <LocalSpinner/>
                    ) : (
                    <CategoryFieldsContainer>
                        <Flex w={'100%'} wrap={'wrap'} gap={'16px'}>
                            <TextField
                                value={form.employeeId}
                                onChange={handleChange}
                                label={t('ID')}
                                name={'employeeId'}
                                sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                size={'small'}
                            />

                            <TextField
                                value={form.firstName}
                                onChange={handleChange}
                                label={t('First name')}
                                name={'firstName'}
                                required
                                sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                size={'small'}
                            />

                            <TextField
                                value={form.middleName}
                                onChange={handleChange}
                                label={t('Middle name')}
                                name={'middleName'}
                                sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                size={'small'}
                            />

                            <TextField
                                value={form.lastName}
                                onChange={handleChange}
                                label={t('Last name')}
                                name={'lastName'}
                                required
                                sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                size={'small'}
                            />

                            <TextField
                                value={form.nickname}
                                onChange={handleChange}
                                label={t('Nickname')}
                                name={'nickname'}
                                sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                size={'small'}
                            />

                            <DatePicker
                                label={t('Birth date')}
                                value={form.birthDay}
                                onChange={(newValue) => {generalInfo.handleSetBirthday(newValue)}}
                                renderInput={(params) => <TextField {...params} sx={{width: inputWidth, backgroundColor: inputBackgroundColor}} size={'small'} />}
                            />

                            <Autocomplete
                                options={settings.gender}
                                value={form.gender}
                                onChange={(e, newValue) => generalInfo.handleSetGender(newValue)}
                                sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                renderInput={(params) => <TextField {...params} label={t('Gender')} size={'small'} required/>}
                            />

                            <Autocomplete
                                options={settings.maritalStatus}
                                value={form.maritalStatus}
                                onChange={(e, newValue) => generalInfo.handleSetMartialStatus(newValue)}
                                sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                renderInput={(params) => <TextField {...params} label={t('Marital status')} size={'small'} required/>}
                            />

                            <Autocomplete
                                options={settings.ethnicity}
                                value={form.ethnicity}
                                onChange={(e, newValue) => generalInfo.handleSetEthnicity(newValue)}
                                sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                renderInput={(params) => <TextField {...params} label={t('Ethnicity')} size={'small'}/>}
                            />

                            <TextField
                                value={form.workEmail}
                                onChange={handleChange}
                                label={t('Work email')}
                                name={'workEmail'}
                                required
                                sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                size={'small'}
                            />
                        </Flex>

                        <Flex m={'16px 0 0 0'}>
                            <IconTextButton size={'small'} variant={'text'} icon={<EditIcon />} onClick={() => goToEditPersonalPage(form.id)}>
                                {('Show all edit preferences')}
                            </IconTextButton>
                        </Flex>
                        {/*<IconButton>*/}
                        {/*    <EditIcon />*/}
                        {/*</IconButton>*/}
                        {/*<Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} mt={'18px'} sx={{cursor: 'pointer'}} onClick={() => goToEditPersonalPage(form.id)} >*/}
                        {/*    <Typography*/}
                        {/*        variant={'subtitle1'}*/}
                        {/*        sx={{color: colors.text.grey_dark, ml: revDir ? '7px' : undefined, mr: !revDir ? '7px' : undefined, borderBottom: `1px solid ${colors.text.grey_dark}`}}*/}
                        {/*    >*/}
                        {/*        {('Show all edit preferences')}*/}
                        {/*    </Typography>*/}
                        {/*    */}
                        {/*</Box>*/}
                    </CategoryFieldsContainer>
                )}
            </DialogContent>

            <DialogActions>

                <Button onClick={handleClose} size={'small'} disabled={isLoadingExact || isLoadingHrSettings || isLoadingEditPersonalInfo} variant={'text'}>{tCommon('Cancel')}</Button>

                <LoadingButton size={"small"} component={"label"}
                               variant={'contained'}
                               disabled={isLoadingExact || isLoadingHrSettings || isLoadingEditPersonalInfo || !isOk}
                               onClick={handleSave} loadingPosition={isLoadingEditPersonalInfo ? !revDir ? 'start' : 'end' : undefined} loading={isLoadingEditPersonalInfo}
                               startIcon={isLoadingEditPersonalInfo ? <div style={{width: '10px', height: '10px', marginRight: !revDir ? '9px' : undefined, marginLeft: revDir ? '9px' : undefined }}/> : undefined}
                               sx={{mr: revDir ? '16px' : undefined, ml: !revDir ? '16px' : undefined}}
                >
                    {tCommon('Save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}