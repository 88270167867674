import {useReport} from "../../../hooks/useReport";
import {useEffect, useState} from "react";
import {TCorrective} from "../../../../frameworks/types";
import {parseDateInDate} from "../../../../../../newShared/utils/dateTools";
import {TReportControlNCR} from "../../../types";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";

export const useNcrBlockPage = (ncr: TReportControlNCR, open: boolean) => {
    const {currentLanguage: {momentLocale}} = useMainTranslation('', {keyPrefix: 'pathReports.View'});
    const {currData: {currentUser}} = UseManageWorkspacesAndOrganizations();

    const {
        report: {report}, users,
        isLoading,

        leaveResponseToNCR,
        uploadAssetsToNCR,
        deleteAsset,
        downloadFile,
    } = useReport();

    const OPEN = ncr.status === 'OPEN';
    const REVIEW = ncr.status === 'REVIEW';
    // const REVOKED = ncr.status === 'REVOKE';
    const REJECTED = ncr.status === 'REJECTED';
    // const COMPLETED = ncr.status === 'COMPLETED';

    const PUBLISHED = report?.reportStatus === 'PUBLISHED';

    const CAN_REDACT = (OPEN || REVIEW || REJECTED) && PUBLISHED;
    const notCompleted = ncr.requestHistory?.find(e => e.correctiveActionAccepted === null) || null;
    const completed = ncr.requestHistory?.find(e => e.correctiveActionAccepted === true) || null;

    const [editMode, setEditMode] = useState<boolean>(CAN_REDACT && !notCompleted);
    const [corrective, setCorrective] = useState<TCorrective>({
        causeAnalysis: '', authorPublicId: currentUser?.publicId || '', description: '', proposedCompletionDate: null, assets: []
    });

    useEffect(() => {
        notCompleted && setCorrective(prev => ({
            causeAnalysis: prev.causeAnalysis === '' ? notCompleted.causeAnalysis || '' : prev.causeAnalysis,
            description: prev.description === '' ? notCompleted.correctiveAction?.description || '' : prev.description,
            proposedCompletionDate: !prev.proposedCompletionDate
                ? parseDateInDate(notCompleted.correctiveAction?.proposedCompletionDate, momentLocale) || null
                : prev.proposedCompletionDate || null,
            authorPublicId: prev.causeAnalysis === '' ? currentUser?.publicId || '' : prev.causeAnalysis,
            assets: notCompleted.evidenceAssets || []
        }))
        completed && setCorrective({
            causeAnalysis: completed.causeAnalysis || '',
            description: completed.correctiveAction?.description || '',
            proposedCompletionDate: parseDateInDate(completed.correctiveAction?.proposedCompletionDate, momentLocale) || null,
            authorPublicId: completed.correctiveAction?.authorPublicId || '',
            assets: completed.evidenceAssets || []
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notCompleted, completed]);

    const [history, setHistory] = useState<number>(-1);
    useEffect(() => {
        setHistory(-1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ncr, open]);

    return {
        report, currentUser, users,
        completed, notCompleted,
        PUBLISHED, CAN_REDACT, REJECTED,
        editMode, setEditMode,
        corrective, setCorrective,
        uploadAssetsToNCR, downloadFile, deleteAsset, leaveResponseToNCR,
        isLoading,
        history, setHistory,
    }
}