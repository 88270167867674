import {Flex} from "../../../../../newShared/components/Layouts";
import {SectionShortDataModel} from "../../../../../newShared/GQLTypes";
import colors from "../../../../../newShared/theme/colors";
import {IconButton, Typography} from "@mui/material";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import {useSectionToConfirmRow} from "../../hooks/useSectionToConfirmRow";
import {sectionTypeImageMapper} from "../../helpers";

export const SectionToConfirmRow = ({section}: {section: SectionShortDataModel}) => {
    const {handleDeselectSection} = useSectionToConfirmRow(section);
    const sectionTitleImage = section.sectionType ? sectionTypeImageMapper(section.sectionType) : {sectionTitle: '-', sectionImage: '-'};

    return (
        <Flex jc={'space-between'} ai={'center'} style={{padding: '10px 20px', borderRadius: '4px', backgroundColor: colors.backgrounds.grey_light, width: '100%'}}>
            <Typography variant={'*bodyText2'}>{section.sectionName}</Typography>
            <Flex gap={'30px'} jc={'space-between'} ai={'center'}>
                <Flex ai={'center'} jc={'flex-start'} gap={'8px'} style={{padding: '0 80px 0 16px', height: '100%', width: '200px', borderLeft: `solid 1px ${colors.text.grey_light_2}`, borderRight: `solid 1px ${colors.text.grey_light_2}`}}>
                    {sectionTitleImage?.sectionImage ?? '-'}
                    {sectionTitleImage?.sectionTitle ?? '-'}
                </Flex>
                <IconButton sx={{cursor: 'pointer'}} size={'small'} onClick={handleDeselectSection}>
                    <ClearOutlinedIcon/>
                </IconButton>
            </Flex>

        </Flex>
    )
}