import {ChangeEvent, useMemo, useState} from "react";
import lodash from "lodash";
import {replaceFilter} from "../../utils/pagination";
import {useHistory} from "react-router";
import {TypeRequestFilter} from "../../utils/builders";
import {useParameters} from "../useParameters";

type TProps = {
    cleanArray?: () => void,
    wait?: number,
}
export const useSearchFieldURL = ({cleanArray, wait = 500}: TProps) => {

    const {filter} = useParameters();

    const [value, setValue] = useState<string>(filter?.input?.[0] || '');
    const {location: {search, pathname}, push} = useHistory();

    const historyPush = (filter: TypeRequestFilter) => {
        cleanArray && cleanArray();
        push(pathname + replaceFilter(search, filter, false))
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceFn = useMemo(() => lodash.debounce(historyPush, wait), []);

    const handleChange = (event: ChangeEvent<HTMLInputElement> | {target: {value: string}}) => {
        setValue(event.target.value);

        const newFilter = filter;
        if (event.target.value.trim()) {newFilter.input = [event.target.value.trim()]}
        else {delete newFilter.input}

        debounceFn(newFilter);
    }

    return {
        value,
        handleChange
    }
}