import {TemplateFieldProps} from "../../types";
import {useTemplateField} from "../../hooks/templates/useTemplateField";
import * as S from "../styled/index";
import {VerDivider} from "../styled/index";
import {Flex} from "../../../../../newShared/components/Layouts";
import colors from "../../../../../newShared/theme/colors";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import DateRangeIcon from "@mui/icons-material/DateRange";
import NumbersIcon from "@mui/icons-material/Numbers";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {Checkbox, FormControlLabel, IconButton} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import React from "react";

export const TemplateField = (props: TemplateFieldProps) => {
    const {handleDelete, handleToggleRequired, handleChange, field, disabled = false, withoutDelete = false, error = false} = props;
    const {drag, drop, opacity, normalizedTypeName} = useTemplateField(props);

    return(
        <S.InputFieldContainer
            isSystem={field.system || disabled}
            isError={!field.name.trim().length || error}
            ref={(node) => drag(drop(node))}
            opacity={opacity.toString()}
        >
            {!field.system && !disabled && <Flex
                h={'100%'}
                cursor={'pointer'}
                br={'4px 0 0 4px'}
                ai={'center'}
                jc={'center'}
                background={colors.backgrounds.grey_light}
            >
                <DragIndicatorIcon sx={{color: colors.text.grey_dark}}/>
            </Flex>}
            {!field.system && field.type === 'TEXT' && <TextFieldsIcon sx={{color: disabled ? colors.text.grey : colors.text.grey_dark}}/>}
            {!field.system && field.type === 'DATE' && <DateRangeIcon sx={{color: disabled ? colors.text.grey : colors.text.grey_dark}}/>}
            {!field.system && field.type === 'NUMBER' && <NumbersIcon sx={{color: disabled ? colors.text.grey : colors.text.grey_dark}}/>}
            {field.type === 'FILE' && <AttachFileIcon sx={{color: disabled ? colors.text.grey : colors.text.grey_dark}}/>}
            <S.FieldInput
                placeholder={!field.name.length ? normalizedTypeName : undefined}
                value={field.name}
                onChange={field.system || disabled ? undefined : handleChange}
                name={field.id}
                disabled={field.system || disabled}
            />
            <FormControlLabel
                checked={field.required}
                disabled={field.requiredForTemplate || disabled}
                control={<Checkbox size={'small'} />}
                label="Required"
                onChange={() => handleToggleRequired(field.id)}
            />
            {!withoutDelete &&
                <>
                    <VerDivider />
                    <IconButton
                        onClick={() => handleDelete(field.id)}
                        size={"small"}
                        // disabled={isTemplateRequired}
                        disabled={field.requiredForTemplate || disabled}
                    >
                        <DeleteOutlineIcon />
                    </IconButton>
                </>
            }
        </S.InputFieldContainer>
    )
}