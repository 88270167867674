import {UIEvent, useMemo, useRef, useState} from "react";
import debounce from "lodash.debounce";

//
// type TProps = {
//     newElementsTop: boolean,
//
//     pageInfo: TPageInfo,
//     elCount: number,
// };
export const useScroll = () => {

    const [isTrigger, setIsTrigger] = useState<{isTop: boolean, isBottom: boolean, bottomLoader: boolean, isFirst: boolean, isScrollable: boolean}>(
        {isTop: true, isBottom: true, bottomLoader: false, isFirst: true, isScrollable: false}
    );
    const [scrollTopBefore, setScrollTopBefore] = useState<number>(0);

    const scrollRef = useRef<HTMLDivElement>(null);
    const getTrigger = ({topOff, bottomOff}: {topOff?: boolean, bottomOff?: boolean}) => {
        if (scrollRef?.current) {
            const {scrollTop, scrollHeight, clientHeight} = scrollRef.current as UIEvent<HTMLDivElement>["currentTarget"];
            if (scrollTop === scrollTopBefore) return;
            setScrollTopBefore(scrollTop);

            const isTop = scrollTop === 0;
            const isBottom = scrollTop + clientHeight >= scrollHeight-1;
            const isScrollable = clientHeight < scrollHeight;
            // console.log({scrollTop: (top ?? scrollTop), clientHeight, scrollHeight, isBottom, isTop})

            setIsTrigger({
                isTop: topOff ?? isTop,
                isBottom: bottomOff ?? isBottom,
                bottomLoader: !isBottom,
                isFirst: false,
                isScrollable,
            })
        } else {
            // console.log('Not found scroll component')
        }
    }

    const onScroll = debounce((event: UIEvent<HTMLDivElement>) => {
        // console.log(`SCROLLLLL`)
        if (event.target) {
            const {scrollTop, scrollHeight, clientHeight} = event.target as UIEvent<HTMLDivElement>["currentTarget"];
            const isTop = scrollTop === 0;
            const isBottom = scrollTop + clientHeight >= scrollHeight-1;
            const isScrollable = clientHeight < scrollHeight;
            // console.log({scrollTop, clientHeight, scrollHeight, isBottom, isTop})

            setIsTrigger({
                isTop,
                isBottom,
                bottomLoader: !isBottom,
                isFirst: false,
                isScrollable,
            })
        } else {
            // console.log('Not found scroll component')
        }
    }, 10)

    const goTop = () => {
        scrollRef?.current?.scrollTo({top: 0, behavior: "smooth"});
        getTrigger({})
    };
    const goTop1 = () => {
        scrollRef?.current?.scrollTo({top: 0.1});
        getTrigger({topOff: false})
    };

    const goBottom = () => {
        if (scrollRef.current) {
            const { scrollHeight, clientHeight } = scrollRef.current;
            scrollRef?.current?.scrollTo({top: scrollHeight - clientHeight, behavior: "smooth"});
        }
        getTrigger({})
    };

    const goBottom1 = () => {
        if (scrollRef.current) {
            const { scrollHeight, clientHeight } = scrollRef.current;
            scrollRef?.current?.scrollTo({top: scrollHeight - clientHeight - 0.1});
        }
        getTrigger({bottomOff: false})
    };

    return useMemo(() => ({
        scrollRef,
        onScroll,

        isBottom: isTrigger.isBottom,
        isTop: isTrigger.isTop,
        bottomLoader: isTrigger.bottomLoader,
        isFirst: isTrigger.isFirst,
        isScrollable: isTrigger.isScrollable,

        goTop,
        goTop1,
        goBottom,
        goBottom1,
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [
        scrollRef,
        isTrigger.isBottom,
        isTrigger.isTop,
        isTrigger.isFirst,
        isTrigger.bottomLoader,
        isTrigger.isScrollable,
    ]);
}