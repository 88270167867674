import {useDispatch, useSelector} from "react-redux";
import {
    cleanUp,
    deselectDocument,
    handleReturnToDraftAction,
    loadings,
    selectedNewDocument,
    vicaTasksSelector
} from "../../store/slice";
import {useEffect, useState} from "react";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_VICA, PATH_VICA_TASKS} from "../../../vica/constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {getVicaTaskFrameworksAutocomplete} from "../../../vica/api";
import {mainVicaGetCategoriesWithFilterAction} from "../../store/actions";
import {mainVicaGetStepResponsibleCollaboratorsAutocompleteApi} from "../../api";
import {
    DocumentExecutorGroupModel,
    DocumentExecutorRecipientModel,
    DocumentExecutorStepModel,
    MainVicaStepStatus,
    NewDocDataVariableModel
} from "../../../../../newShared/GQLTypes";
import {useHistory} from "react-router";
import {urlDecode} from "../../../../../newShared/components/genericTable/helpers";
import {vicaFilterIdNameType, vicaSearchType} from "../../types";
import {PATH_LOCAL_COMPLIANCE_FRAMEWORKS_AVAILABLE} from "../../../../../newShared/constants";
import {deselectDocument as deselectDocumentDocuments} from "../../../documentsRefactor/store/slice";
import {
    handlePreviewBeforeSendAction,
    InvalidateDocument,
    RestartSignStageByStageIdAndGroupId,
    RestartSignStageByStageIdAndRecipient,
    SendToNextStepByStageId,
    StartNewDocumentExecution,
    UpdateFullDocument
} from "../../../documentsRefactor/store/actions";
import {TEditorActorsConfigFromDB, TEditorElementData} from "../../../../../newShared/components/editorUnion/types";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {useSnack} from "../../../../barsEnvironment/snack/hooks/useSnack";

export const useVicaCategoriesList = () => {
    //root
    const {tMenu} = useMainTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const {user} = UseManageWorkspacesAndOrganizations();
    const {clearFilters, injectOriginalSelectedValues, originalSelectedValues, isSearchAndFilterEmpty} = useGenericFiltersStorage();
    const snack = useSnack();

    //states
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    //selectors
    const {categories} = useSelector(vicaTasksSelector);
    const _loadings = useSelector(loadings);
    const _selectedDoc = useSelector(selectedNewDocument);
    const docId = _selectedDoc?.document?.documentId;

    //useEffects
    useSetBreadcrumbs([
        {
            title: tMenu('Vica'),
            path: PATH_LOCAL_VICA
        },
        {
            title: tMenu('Task management'),
            path: PATH_VICA_TASKS
        }
    ]);

    useEffect(() => {
        if(isInitialized){
            const openedCategories = categories.filter(e => e.isOpen).map(e => e.name);
            const searchString = JSON.stringify({search: '', filters: originalSelectedValues, openedCategories} as vicaSearchType);
            historyReplace(searchString);
        }
        //eslint-disable-next-line
    }, [originalSelectedValues, categories]);

    useEffect(() => {
        try{
            const searchConfig:vicaSearchType = JSON.parse(urlDecode(history.location.search.substring(1))) as vicaSearchType;
            if(searchConfig){
                // console.log(`init fetch by search`, history.location.search.substring(1));
                //get full object filter value and check for existing filter key in configs
                if((Object.keys(searchConfig.filters).length > 0 || searchConfig.search.trim().length > 0)){
                    const filtersForSearch = injectOriginalSelectedValues(configs, searchConfig.filters, searchConfig.search);
                    fetchCategories(filtersForSearch, searchConfig.openedCategories);
                }else{
                    fetchCategories({}, searchConfig.openedCategories);
                }
                //have to inject full object filters into filters store
                //have to inject selected filters (if filtering by, show such filter)
                // setLastSelectedId(searchConfig.lastId);
                // setViewPage(forceLoadPageZero ? 0 : searchConfig.page);
            }else{
                // console.log(`searchConfig not found`)
                fetchCategories({});
            }
        }catch (ex){
            //remove bad search
            history.replace({pathname: history.location.pathname, search: ''});
            fetchCategories({});
            // console.log(`init fetch ex`, ex, urlDecode(history.location.search.substring(1)))
        }
        setIsInitialized(true);

        return () => {
            clearFilters();
            dispatch(cleanUp());
        }
        //eslint-disable-next-line
    }, []);

    //actions
    const historyReplace = (search: string) => {
        history.replace({pathname: history.location.pathname, search});
    }

    const fetchCategories = (filters: Record<string, string[]>, previouslyOpenedCategories?: string[]) => {
        dispatch(mainVicaGetCategoriesWithFilterAction({data: {filters}, addictiveData: {previouslyOpenedCategories}}));
    }

    //filter
    const responsibleConfig: keyConfig<{ collaboratorId: string; fullName: string; email: string; }> = {
        type: 'filter',
        key: 'responsible',
        name: ('Responsible'),
        fetchOptions: (workspaceId, data) => mainVicaGetStepResponsibleCollaboratorsAutocompleteApi({workspaceId, search: data.search, pageRequest: {page: data.page, count: data.count}}).then(e => ({values: e.result, pageInfo: e.pageInfo})),
        getOptionLabel: (a) => a.fullName,
        getFilterValue: (a) => a.collaboratorId,
        isOptionEqual: (a, b) => a.collaboratorId === b.collaboratorId,
        isOptionEqualToSearch: (a, search) => a.fullName.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: true
    }

    const frameworkConfig: keyConfig<vicaFilterIdNameType> = {
        type: 'filter',
        key: 'frameworks',
        name: ('Framework'),
        fetchOptions: getVicaTaskFrameworksAutocomplete,
        getOptionLabel: (a) => a.name,
        getFilterValue: (a) => a.id,
        isOptionEqual: (a, b) => a.id === b.id,
        isOptionEqualToSearch: (a, search) => a.name.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: true
    }

    const statusConfig: keyConfig<{id: MainVicaStepStatus, name: string}> = {
        type: 'filter',
        key: 'status',
        name: ('Status'),
        options: [
            {id: MainVicaStepStatus.Completed, name: ('Complete')},
            {id: MainVicaStepStatus.InProgress, name: ('In progress')},
            {id: MainVicaStepStatus.NotStarted, name: ('Not started')},
        ],
        getOptionLabel: (a) => a.name,
        getFilterValue: (a) => a.id,
        isOptionEqual: (a, b) => a.id === b.id,
        isOptionEqualToSearch: (a, search) => a.name.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: true
    }

    const configs = [frameworkConfig, statusConfig, responsibleConfig];

    return{
        categories,
        isLoading: _loadings.categoriesList,
        configs,
        actions: {fetchCategories},
        isShowingNoCategoriesResult: !_loadings.categoriesList && !categories.length && isSearchAndFilterEmpty,
        handleGoToAdoptFramework: () => {
            history.push(PATH_LOCAL_COMPLIANCE_FRAMEWORKS_AVAILABLE)
        },
        doc: {
            selectedNewDocument: _selectedDoc,
            currentEmail: user?.email ?? '',
            snack,
            loadings: _loadings,
            docActions: {
                handleCloseEditor: () => {
                    dispatch(deselectDocumentDocuments())
                    dispatch(deselectDocument())
                },
                handleReturnToDraft: () => {
                    dispatch(handleReturnToDraftAction())
                },
                onResendInviteApprover: (step: DocumentExecutorStepModel, group: DocumentExecutorGroupModel) => {
                    docId && dispatch(RestartSignStageByStageIdAndGroupId({documentId: docId, stageId: step.id, groupId: group.id}));
                },
                onResendInviteRecipient: (step: DocumentExecutorStepModel, recipient: DocumentExecutorRecipientModel) => {
                    docId && dispatch(RestartSignStageByStageIdAndRecipient({documentId: docId, stageId: step.id, recipientEmail: recipient.email}));
                },
                handleSkip: (step: DocumentExecutorStepModel) => {
                    docId && dispatch(SendToNextStepByStageId({documentId: docId, stageId: step.id}));
                },
                handleInvalidate: () => {
                    if(_selectedDoc && _selectedDoc.document){
                        docId && dispatch(InvalidateDocument(docId));
                    }
                },
                handleSave: (data: TEditorElementData<{}>[], variables: NewDocDataVariableModel[], docTitle: string, actors: TEditorActorsConfigFromDB) => {
                    if(_selectedDoc && _selectedDoc.document) {
                        dispatch(UpdateFullDocument({
                            documentId: _selectedDoc.document.documentId ?? '',
                            name: docTitle,
                            data: {
                                editor: data,
                                variables,
                                pdfFileId: _selectedDoc.document.data?.pdfFileId || null,
                            },
                            recipients: actors.recipients,
                            approvers: actors.approvers,
                            editors: actors.editors
                        }))
                    }
                },
                handleSend: (data: TEditorElementData<{}>[], variables: NewDocDataVariableModel[], docTitle: string, actors: TEditorActorsConfigFromDB) => {
                    if(_selectedDoc && _selectedDoc.document) {
                        dispatch(StartNewDocumentExecution({
                            documentId: _selectedDoc.document.documentId ?? '',
                            name: docTitle,
                            data: {
                                editor: data,
                                variables,
                                pdfFileId: _selectedDoc.document.data?.pdfFileId || null,
                            },
                            recipients: actors.recipients,
                            approvers: actors.approvers,
                            editors: actors.editors
                        }))
                    }
                },
                handlePreviewBeforeSend: (data: TEditorElementData<{}>[], variables: NewDocDataVariableModel[], docTitle: string, actors: TEditorActorsConfigFromDB) => {
                    if(_selectedDoc && _selectedDoc.document) {
                        dispatch(handlePreviewBeforeSendAction({
                            documentId: _selectedDoc.document.documentId ?? '',
                            name: docTitle,
                            data: {
                                editor: data,
                                variables,
                                pdfFileId: _selectedDoc.document.data?.pdfFileId || null,
                            },
                            recipients: actors.recipients,
                            approvers: actors.approvers,
                            editors: actors.editors
                        }))
                    }
                }
            },
        },
    }
}