import {
    openAddControl,
    openAddEvidence,
    openAddFramework,
    openDeleteControl,
    openDeleteEvidence,
    openDeleteFramework,
    openEditControl,
    openEditEvidence,
    openEditFramework
} from "../../../store/builder/slice";
import {useDispatch} from "react-redux";
import React from "react";
import {evidenceType, frameworkType} from "../../../types";

export const useDialogs = () => {
    const dispatch = useDispatch();

    const openAddFrameworkDialog = () => dispatch(openAddFramework());
    const handleOpenDeleteFramework = (frId: string) => {
        dispatch(openDeleteFramework(frId))
    };
    const handleOpenEditFrameworkDialog = (framework: frameworkType) => {
        dispatch(openEditFramework({framework}))
    };

    const openEditFrameworkControlDialog = (id: string, e?: React.MouseEvent<HTMLButtonElement | HTMLLIElement>) => {
        e && e.stopPropagation();
        dispatch(openEditControl(id));
    };
    const handleOpenAddFrameworkControlDialog = (e: React.MouseEvent<HTMLButtonElement>, frameworkId: string) => {
        e.stopPropagation();
        dispatch(openAddControl(frameworkId));
    };


    const handleOpenAddControlEvidence = (e: React.MouseEvent<HTMLButtonElement>, controlId: string) => {
        e.stopPropagation();
        dispatch(openAddEvidence({isEvidencesPage: false, controlId}));
    }
    const handleOpenDeleteControl = (controlId: string, e?: React.MouseEvent<HTMLButtonElement | HTMLLIElement>) => {
        e && e.stopPropagation();
        dispatch(openDeleteControl(controlId));
    }

    const handleOpenAddControl = () => {
        dispatch(openAddControl(''));
    }

    const openEditControlDialog = (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
        e.stopPropagation();
        dispatch(openEditControl(id));
    }

    const handleOpenEditEvidence = (id: string, evidence: evidenceType) => {
        dispatch(openEditEvidence({evidenceId: id, isEvidencesPage: true, evidence}));
    }

    const handleOpenAddEvidenceDialog = (controlId: string | null) => {
        dispatch(openAddEvidence({isEvidencesPage: true, controlId}))
    }
    const handleOpenDeleteEvidence = (id: string) => {
        dispatch(openDeleteEvidence(id));
    }

    return {
        openAddFrameworkDialog,
        handleOpenDeleteFramework,
        handleOpenEditFrameworkDialog,
        openEditFrameworkControlDialog,
        handleOpenAddFrameworkControlDialog,

        handleOpenAddControlEvidence,
        handleOpenDeleteControl,

        handleOpenAddControl,
        openEditControlDialog,

        handleOpenEditEvidence,
        handleOpenDeleteEvidence,
        handleOpenAddEvidenceDialog,
    }
}