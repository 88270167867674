import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {useRunReviewWarningDialog} from "../../../../hooks/campaignReviewHooks/dialogs/useRunReviewWarningDialog";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import colors from "../../../../../../../newShared/theme/colors";
import {LoadingButton} from "@mui/lab";

export const RunReviewWarningDialog = () => {
    const {isOpen, handleSubmit, handleClose, instruction, isLoading} = useRunReviewWarningDialog();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.CampaignReviewInstructionCreate.dialogs'});


    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t(`Warning! {{name}} is already running.`, {name: instruction?.name ?? ''})}</DialogTitle>

            <DialogContent>
                <Typography variant={'body2'} color={colors.darkBlueText}>
                    {t(`An automation lifecycle is in process now. If you re-run it, current process will be terminated. If you are aware and want to re-run this review campaign, please proceed and click RERUN.`)}
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                >
                    {t('Rerun')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}