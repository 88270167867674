import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideCreateClientDialog, hideTemplatesImportCsv, loadings} from "../../../../store/slice";
import {useFileDropZone} from "../../../../../../../newShared/hooks/useSingleFileDropZone";
import {useEffect, useState} from "react";
import {PerformCsvByFileId, UploadKycCsvFile} from "../../../../store/actions";
import {toBase64} from "../../../../../../../newShared/utils/base64/base64";
import {KycCsvFieldWithAliasModel} from "../../../../../../../newShared/GQLTypes";

export const useTemplatesImportCsv = () => {
    const dispatch = useDispatch();
    const {filePicker, fileHandler, uploadedFile, ref, handleDeleteFile} = useFileDropZone();
    const {isOpen, template, uploadCsvResponse} = useSelector(dialogs).templatesListImportCsv;
    const [step, setStep] = useState<'upload' | 'structureMismatch' | 'success'>('upload');
    const {isLoadingPerformCsv, isLoadingUploadCsv} = useSelector(loadings);

    const handleClose = () => {
        dispatch(hideTemplatesImportCsv());
        handleDeleteFile();
    }

    const onSuccessUploadKycCsvFile = () => {
        setStep('structureMismatch');
    }
    const handleContinue = async() => {
        if(uploadedFile && template){
            const base64File = await toBase64(uploadedFile);
            dispatch(UploadKycCsvFile({data: {fileName: uploadedFile.name, file: base64File, templateId: template.id}, onSuccess: onSuccessUploadKycCsvFile}));
            // setStep('structureMismatch');
        }
    }

    useEffect(() => {
        isOpen && setStep('upload');

        //eslint-disable-next-line
    }, [isOpen]);

    const handlePerformCsv = (config: KycCsvFieldWithAliasModel[]) => {
        uploadCsvResponse && dispatch(PerformCsvByFileId({data: {fileId: uploadCsvResponse.fileId, config, onSuccess: () => setStep("success")}}));
        dispatch(hideCreateClientDialog());
    }

    return{
        isOpen,
        template,
        handleClose,
        handleContinue,
        handlePerformCsv,
        fileUpload: {
            filePicker,
            fileHandler,
            ref,
            uploadedFile
        },
        step,
        uploadCsvResponse,
        isLoadingPerformCsv,
        isLoadingUploadCsv,
        handleDeleteFile,
    }
}
