import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useAddControl} from "../../../../hooks/dialogs/useAddControl";
import {LoadingButton} from "@mui/lab";


export const AddCustomControlDialog = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'builderPage.dialogs'});

    const {
        isOpen,
        form,
        isExisting,
        handleClose,
        validateForm,
        handleSave,
        isLoading,
        handleChangeDesc,
        handleChange,
    } = useAddControl();

    return(
        <Dialog
            open={isOpen}
            onClose={handleClose}
        >
            <DialogTitle>{t('Create control')}</DialogTitle>
            <DialogContent>
                <TextField id="filled-basic" required label={t("Name")} variant="outlined"
                           sx={{mt: '12px', '& label': {top: '0 !important'}}} size={'small'} name={'name'}
                           value={form.name} onChange={handleChange}/>

                <TextField id="filled-basic" label={t("Group")} variant="outlined"
                           sx={{mt: '12px', '& label': {top: '0 !important'}}} size={'small'}
                           name={'group'} value={form.group} onChange={handleChange}/>

                <TextField id="filled-basic" label={t("Category")}
                           variant="outlined" sx={{mt: '12px', '& label': {top: '0 !important'}}} size={'small'}
                           name={'category'} value={form.category} onChange={handleChange}/>

                <TextField id="filled-basic" label={t("Description")}
                           variant="outlined" multiline minRows={5}
                           sx={{mt: '12px'}} value={form.description[0] || ''} onChange={handleChangeDesc}/>
            </DialogContent>

            <DialogActions>
                <Button variant={'text'} sx={{'&.MuiButton-text': {textTransform: 'none'}}} onClick={handleClose}>{isExisting ? t('Close') : t('Cancel')}</Button>
                <LoadingButton size={'medium'} variant={'contained'}
                               disabled={!validateForm() || isLoading}
                               onClick={handleSave} loading={isLoading }
                               endIcon={isLoading ? <div style={{width: '10px', height: '10px', marginLeft: revDir ? '12px' : undefined, marginRight: !revDir ? '12px' : undefined }}/> : undefined}
                               loadingPosition={isLoading ? 'end' : undefined}
                >
                    {t('Save')}
                </LoadingButton>

            </DialogActions>
        </Dialog>
    )
}
