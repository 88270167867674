import {AvatarGroup, IconButton, Skeleton, TableCell, TableRow} from "@mui/material";
import React from "react";
import {Flex} from "../../../../../../../../newShared/components/Layouts";
import {TypographyNew} from "../../../../../../../../newShared/components/TypographyNew";
import colors from "../../../../../../../../newShared/theme/colors";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export const VicaTaskTableRowSkeleton = () => {
    const {revDir} = useMainTranslation();
    return(
        <TableRow>
            <TableCell>
                <Flex ai={'center'} m={!revDir ? '0 0 0 50px' : '0 50px 0 0'}>
                    <TypographyNew variant={'body2'} color={colors.grayText} noWrap>
                        <Skeleton width={150}/>
                    </TypographyNew>

                    <IconButton size={'small'} disabled>
                        <InfoOutlinedIcon />
                    </IconButton>
                </Flex>
            </TableCell>

            <TableCell>
                <Skeleton width={72} height={42} sx={{borderRadius: '10px'}}/>
            </TableCell>

            <TableCell>
                <Flex ai={'center'}>
                    <AvatarGroup
                        max={3}
                        sx={{
                            '& .MuiAvatar-root': {
                                maxWidth: '24px', maxHeight: '24px',
                                fontSize: '10px',
                            },
                            '& .MuiAvatar-root:hover': {
                                cursor: 'pointer'
                            },
                            '& .MuiAvatar-root:last-child': {
                                marginLeft: !revDir ? undefined : '-8px'
                            },
                        }}
                    >

                        <Skeleton width={24} height={24} />
                        <Skeleton width={24} height={24}/>
                        <Skeleton width={24} height={24}/>
                    </AvatarGroup>
                </Flex>
            </TableCell>

            <TableCell>
                <Flex ai={'center'} gap={'9px'}>
                    <Skeleton width={18} height={18} variant={'circular'}/>
                    <Skeleton width={18} height={18} variant={'circular'}/>
                    <Skeleton width={18} height={18} variant={'circular'}/>
                    <Skeleton width={18} height={18} variant={'circular'}/>
                </Flex>
            </TableCell>

            <TableCell>
                <Flex jc={'space-between'} ai={'center'}>
                    <Flex ai={'center'} gap={'10px'}>
                        <AvatarGroup
                            max={4}
                            sx={{
                                '& .MuiAvatar-root': {
                                    maxWidth: '24px', maxHeight: '24px',
                                    fontSize: '10px',
                                },
                                '& .MuiAvatar-root:hover': {
                                    cursor: 'pointer'
                                },
                                '& .MuiAvatar-root:last-child': {
                                    marginLeft: !revDir ? undefined : '-8px'
                                },
                            }}
                        >

                            <Skeleton width={24} height={24}/>
                            <Skeleton width={24} height={24}/>
                        </AvatarGroup>
                    </Flex>

                    <IconButton
                        size={'small'}
                        sx={{margin: !revDir ? '0 0 0 16px' : '0 16px 0 0'}}
                        aria-label="more"
                        id="long-button"
                        aria-haspopup="true"
                        disabled
                    >
                        {<MoreVertIcon />}
                    </IconButton>
                </Flex>
            </TableCell>
        </TableRow>
    )
}