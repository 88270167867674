import React, {FC, useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useChangeStatusDialog} from "../../../hooks/useChangeStatusDialog";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {CreateSettingRowDialog} from "../CreateSettingRowDialog";
import {AddMenuItem} from "../../../../../../newShared/components/Basic/AddMenuItem";
import {optionIsTCollaborator} from "../../../../../../newShared/components/AutocompleteCollaborators/types";
import {getName} from "../../../../../../newShared/utils/text";
import {AutocompleteCollaborators} from "../../../../../../newShared/components/AutocompleteCollaborators";
import {uuid} from "../../../../../../newShared/utils";

export const ChangeStatusDialog: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});
    const {isMobile} = useMedia();

    const {
        isOk,
        settings,
        isOpen,

        status,
        employee,
        setEmployee,

        isLoadingExact,
        isLoadingSettings,

        handleClose,
        handleConfirm,
        handleSetStatus,
        // handleOnSuccessCreateSetStatus,

        openCreateSettingRowDialog

    } = useChangeStatusDialog();


    const [uniqueInputId, setUniqueInputId] = useState<string>(uuid());
    useEffect(() => {
        setUniqueInputId(uuid())
    }, [status?.status, status?.name]);

    return settings ? (
        <Dialog open={isOpen} onClose={handleClose}
                sx={{'& .MuiPaper-root': {width: isMobile ? '358px' : '600px'}}}>
            <DialogTitle>{t('Change asset status')}</DialogTitle>
            <DialogContent sx={{padding: isMobile ?  '10px 10px' : '10px 24px'}}>
                <TextField select fullWidth label={t('Status')} value={status?.name ?? ''} size={'small'}
                           sx={{marginTop: '16px', width: '320px', '& label': {top: '0 !important'}}}
                           onChange={(e) => handleSetStatus(e.target.value)}
                >
                    <AddMenuItem text={t('Add new')} onClick={openCreateSettingRowDialog} />
                    { settings && settings.status.map(({name}) => <MenuItem key={name} value={name}>{name}</MenuItem>)}
                </TextField>

                <AutocompleteCollaborators
                    key={uniqueInputId}
                    label={t('Manager')}
                    getOptionLabelCustom={"name+email"}
                    getOptionLabel={(option) => optionIsTCollaborator(option) ? getName(option.firstName || '', option.lastName || '') : option}
                    sx={{marginTop: '18px', width: isMobile ? '100%' : '320px', '& label': {top: '0px !important'}}}

                    disabled={settings?.status.find(e => e.name === status?.name)?.status.toLowerCase() !== 'deployed'}
                    defaultValue={employee}
                    onChange={value => {
                        if (!value) setEmployee(null)
                        else if (optionIsTCollaborator(value)) {
                            setEmployee(value)
                        }
                    }}
                />
            </DialogContent>
            <DialogActions >
                <Button variant={'text'} onClick={handleClose}>{t('Cancel')}</Button>

                <LoadingButton size={"large"}
                               variant={'contained'}
                               disabled={isLoadingExact || isLoadingSettings || !isOk() || (settings?.status.find(e => e.name === status?.name)?.status.toLowerCase() === 'deployed' && !employee?.publicId)}
                               onClick={handleConfirm} loading={isLoadingExact}
                    // sx={{margin: revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                >
                    {t('Confirm')}
                </LoadingButton>

            </DialogActions>
            {<CreateSettingRowDialog onSuccess={handleSetStatus}/>}

        </Dialog>
    ) : null
}
