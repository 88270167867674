import {TRisk, TRiskModel, TRiskModelProbability} from "./types";
import colors from "../../../newShared/theme/colors";
import {controls, firstNoneObject} from "./constants";

export const getInitCreateModel = (): TRiskModel => {
    return {
        id: '',
        name: '',
        isBasic: false,
        counter: 0,
        left: 6,
        right: 19,
        workspaceId: '',
        probabilities: [
            {quality: "Rare", quantitative: 1, description: 'Current actions are in order. Issue can be resolved quickly and easily.'},
            {quality: "Unlikely", quantitative: 2, description: "Current strategy should resolve this issue."},
            {quality: "Moderate", quantitative: 3, description: 'Current strategy may not resolve this issue.  Alternatives may be required,  actions are to be considered.'},
            {quality: "Likely", quantitative: 4, description: 'Current strategy will probably not resolve this issue.  Alternatives will be required, mitigation actions needed.'},
            {quality: "Almost Certain", quantitative: 5, description: 'No strategy or current strategy will resolve this issue, Alternatives will be required, mitigation actions urgently to be done.'},
        ],
        impacts: [
            {quality: "Insignificant", quantitative: 1, description: "Minimal operational impact"},
            {quality: "Minor", quantitative: 2, description: "Limited operational impact"},
            {quality: "Moderate", quantitative: 3, description: "Remedial action required"},
            {quality: "Major", quantitative: 4, description: "Loss of operational capability"},
            {quality: "Extreme", quantitative: 5, description: "Unacceptable, operational failure"},
        ]
    }
}

export const getRiskRate = (probability: number, impact: number, borders?: {left: number, right: number} | null): string => {
    if (probability === 0 || impact === 0 || !borders) return 'None';
    const result = probability * impact;
    return result < borders.left
        ? 'Low'
        : result <= borders.right
            ? 'Medium'
            : 'High'
}

export const getColorByRiskRate = (riskRate: string): string => {
    switch (riskRate){
        case 'Low': return colors.warningActions.green;
        case 'Medium': return colors.decorative.orange;
        case 'High': return colors.warningActions.red;
        default: return colors.text.grey_dark;
    }
}

//InitialGrade
export const getInitialGradeProbabilityValue = (risk: TRisk | null | undefined, model: TRiskModel | null | undefined): TRiskModelProbability => {
    if(model && risk){
        const prob = model.probabilities.find(e => e.quantitative === risk.initialGrade.probability);
        if(prob) return prob;
    }
    return firstNoneObject;
}

export const getInitialGradeImpactValue = (risk: TRisk | null, model: TRiskModel | null | undefined): TRiskModelProbability => {
    if(model && risk){
        const prob = model.impacts.find(e => e.quantitative === risk.initialGrade.impact);
        if(prob) return prob;
    }
    return firstNoneObject;
}

export const getInitialGradeProbabilityLabel = (option: unknown, isQuantitative: boolean): string => {
    if((option as TRiskModelProbability).quantitative === -777){
        return 'None';
        //have to add custom first object to add None option
        //since if its isQuantitative will take quantitative.toString - replacing this with None
    }
    if (isQuantitative) {
        return (option as TRiskModelProbability).quantitative ? (option as TRiskModelProbability).quantitative.toString() : '';
    } else {
        return (option as TRiskModelProbability).quality ? (option as TRiskModelProbability).quality : '';
    }
}

export const isNullObject = (option: unknown) => (option as TRiskModelProbability).quantitative === -777;

//Risk treatment
export const getInitialControlValue = (risk: TRisk | null):{description: string, type: string} => {
    if(risk){
        const res = controls.find((e) => e.type.toUpperCase() === risk.control.type?.toUpperCase());
        if(res){
            return res;
        }else{
            return {
                type: 'None',
                description: '',
            }
        }
    }
    return {
        type: 'None',
        description: '',
    }
}

//Residual grade
export const getResidualGradeProbabilityValue = (risk: TRisk | null, model: TRiskModel | null | undefined): TRiskModelProbability => {
    if(model && risk){
        // console.log(`getResidualGradeProbabilityValue: model:ok, risk:ok, searching by: risk.residualGrade.probability: ${risk.residualGrade.probability}`);
        const prob = model.probabilities.find(e => e.quantitative === risk.residualGrade.probability);
        // console.log(`getResidualGradeProbabilityValue: prob found: ${JSON.stringify(prob)}`);
        if(prob) return prob;
    }
    return firstNoneObject;
}

export const getResidualGradeImpactValue = (risk: TRisk | null, model: TRiskModel | null | undefined): TRiskModelProbability => {
    if(model && risk){
        const prob = model.impacts.find(e => e.quantitative === risk.residualGrade.impact);
        if(prob) return prob;
    }
    return firstNoneObject;
}

export const modifyDateForSave = (date: string) => {
    return date.slice(0, date.length - 'T22:00:00.000Z'.length);
}

export const getRisksTableHeaderImpactCellBorderStyles = (order: number, revDir: boolean):string => {
    if(revDir){
        switch (order){
            case 0: return 'border-left: none; border-right: none; borderBottom: none;';
            case 1: return 'borderBottom: none;';
            case 2: return 'border-left: none; border-right: none; borderBottom: none;';
            default: return '';
        }
    }else{
        switch (order){
            case 0: return 'border-left: none; border-right: none; borderBottom: none;';
            case 1: return 'borderBottom: none;';
            case 2: return 'border-left: none; border-right: none; borderBottom: none;';
            default: return '';
        }
    }
}


export const getMax = (model: TRiskModel | null):number => {
    if(!model) return 0;
    const maxOfImpact = model.impacts.map(e => Number(e.quantitative)).sort((a, b) => a - b);
    const maxOfProbability = model.probabilities.map(e => Number(e.quantitative)).sort((a, b) => a - b);
    console.log(`
        maxOfImpact - ${maxOfImpact} - ${maxOfImpact[maxOfImpact.length - 1] ?? 1} \n
        maxOfProbability - ${maxOfProbability} - ${maxOfProbability[maxOfProbability.length - 1] ?? 1}
    `)
    return (maxOfImpact[maxOfImpact.length - 1] || 1) * (maxOfProbability[maxOfProbability.length - 1] || 1);
}

export const getLow = (model: TRiskModel | null):number => {
    if(!model) return 0;
    const minOfImpact = model.impacts.map(e => e.quantitative).sort();
    const minOfProbability = model.probabilities.map(e => e.quantitative).sort();
    return (minOfImpact[0] || 1) * (minOfProbability[0] || 1);
}
