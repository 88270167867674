import {useDispatch, useSelector} from "react-redux";
import {dialogs, folders, foldersPath, hideMoveToDialog, isLoading} from "../../../store/slice";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {useEffect, useState} from "react";
import {MoveNewItems} from "../../../store/actions";
import {LocalSpinner} from "../../../../../../newShared/components/Spinner";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {SelectFolderTree} from "../../selectFolderTree";


export const MoveToDialog = () => {
    const dispatch = useDispatch();
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});
    // const _selectedFolders = useSelector(selectedFolders);
    // const _selectedFiles = useSelector(selectedFiles);
    const rootFolders = useSelector(folders);
    const {isOpen, folder, file} = useSelector(dialogs).moveToDialog;
    const isLoadingMoveTo = useSelector(isLoading).isLoadingMoveTo;
    const path = useSelector(foldersPath);

    const [moveToFolder, setMoveToFolder] = useState<{id: string, name: string} | null>(null);
    // const [openedFolders, setOpenedFolders] = useState<string[]>([]);

    //set root opened
    useEffect(() => {
        if(isOpen && path.length > 0){
            // setOpenedFolders([path[0].id]);
        }else if(!isOpen){
            setMoveToFolder(null);
            // setOpenedFolders([]);
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const handleClose = () => {
        dispatch(hideMoveToDialog());
    }

    const isOk = ():boolean => {
        if (file && moveToFolder) return file.folderId !== moveToFolder.id;
        if (folder && moveToFolder) return path[path.length - 1].id !== moveToFolder.id;
        return !!moveToFolder;
    }

    const submit = () => {
        if(isOk()){
            //moveToFolder !== null -> checked in isOk()
            dispatch(MoveNewItems({
                fileIds: file ? [file.documentId ?? ''] : [],
                folderIds: folder ? [folder?.id] : [],
                newFatherId: moveToFolder!.id,
                // fileIds: _selectedFiles.map(e => e.fileId),
                // folderIds: _selectedFolders.map(e => e.folderId),
                // newFatherId: moveToFolder!.id
            }));
        }
    }

    // const itemsLength = _selectedFolders.length + _selectedFiles.length;

    return(
        <Dialog onClose={handleClose} open={isOpen} fullWidth maxWidth={'sm'} scroll={'paper'}>
            <DialogTitle>{t('Move to folder')}</DialogTitle>
            <DialogContent>
                <SelectFolderTree t={t} maxWidth={'545px'} folders={rootFolders} path={path} selectedFolder={moveToFolder} setSelectedFolder={setMoveToFolder}/>


                {/*{rootFolders.filter(e => moveToFoldersFilter(e, path)).map((e) => <MoveToDialogFolder*/}
                {/*    key={e.id}*/}
                {/*    folder={e}*/}
                {/*    selectedFolderId={moveToFolder}*/}
                {/*    onSelectFolder={(id: string, name: string) => setMoveToFolder({id, name})}*/}
                {/*    openedFolders={openedFolders}*/}
                {/*    openFolder={(id: string) => setOpenedFolders([...openedFolders, id])}*/}
                {/*    hideFolder={(id: string) => setOpenedFolders([...openedFolders.filter(e => e !== id)])}*/}
                {/*    currPath={[{id: e.id, name: e.name}]}*/}
                {/*/>)}*/}
            </DialogContent>

            {isLoadingMoveTo ? <LocalSpinner/> :
                <DialogActions>
                    <Button onClick={handleClose} variant={'text'}>{t('Cancel')}</Button>
                    <Button onClick={submit} disabled={!isOk()}>{t('Move')}</Button>
                </DialogActions>
            }
        </Dialog>
    )
}


