import {Handle, Position} from "react-flow-renderer";
import {ALLOWDRAG} from "../../../constants";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import {useDispatch, useSelector} from "react-redux";
import {deleteDocument, selectedAutomation} from "../../../store/slice";
import {shortString} from "../../../helpers";
import {automationType} from "../../../types";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {Typo} from "../../../../../../newShared/components/Typography";

type docChildNodeProps = {
    text: string,
    id: string,
    revDir: string
}

export const DocChildNode = ({data} : {data: docChildNodeProps}) => {
    const {text, id, revDir} = data;
    const dispatch = useDispatch();

    const auto: automationType | null = useSelector(selectedAutomation);

    const deleteSelf = () => {dispatch(deleteDocument({fileName: text, fileId: id}))};

    return(
        <>
        <Flex
            overflow={'hidden'}
            w={'140px'}
            h={'60px !important'}
            br={'11px'}
            background={'#FAC000'}
            box_shadow={'0px 0px 5.853034973144531px 0px rgba(0, 0, 0, 0.12)'}
            p={'5px'}
            // jc={'center'}
            ai={'center'}
            direction={'column'}
            className={ALLOWDRAG ? 'nodrag': ''}
            cursor={'pointer'}
        >
            <Handle
                type="target"
                position={!revDir ? Position.Left : Position.Right}
                id="doc-left-target"
                isConnectable={false}
                style={{opacity: '0'}}
            />
            <Flex ai={'center'} jc={'center'} w={'100%'} h={'100%'}>
                <Typo fontWeight={400} fontSize={'14px'}  align={'center'}>{shortString(text, 30)}</Typo>
            </Flex>
        </Flex>
            {auto && auto.documents.links.length > 1 &&
                <Flex style={{position: 'relative', bottom: '40px', left: !revDir ? '140px' : '-25px'}} className={'nodrag'}>
                    <DeleteOutlinedIcon
                        style={{width: '20px', height: '20px', color: '#5D5D5D', cursor: 'pointer'}}
                        onClick={deleteSelf}
                    />
                </Flex>
            }
        </>
    )
}