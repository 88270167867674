import {Button, styled} from "@mui/material";


export const Arial = styled('span')`
  font-family: Arial, sans-serif !important;
`;

export const Georgia = styled('span')`
  font-family: Georgia, serif !important;
`;

export const Helvetica = styled('span')`
  font-family: Helvetica, sans-serif !important;
`;

export const Monospace = styled('span')`
  font-family: Monospace, serif !important;
`;

export const TimesNewRoman = styled('span')`
  font-family: "Times New Roman", serif !important;
`;

export const FontButton = styled(Button)`
  text-transform:capitalize !important;
  color: #707070 !important;
  width:120px !important;
  overflow: hidden !important;
  display: inline-block !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  justify-content: flex-start !important;
  text-align: left !important;
  font-weight: 500;
`;
FontButton.defaultProps = {
    variant: "text",
}
