import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_VULNERABILITY, PATH_LOCAL_VULNERABILITY_SCANNED} from "../../../../../newShared/constants";
import {DEFAULT_COUNT, PATH_LOCAL_VULNERABILITY_EDIT} from "../../constants";
import React, {useEffect, useState} from "react";
import {
    histories,
    historyPageInfo,
    loadings,
    openDeleteTarget,
    openReleaseTarget,
    selectedScanType,
    selectedTarget,
    selectScanType
} from "../../store/slice";
import {GetShortTargetById, GetTargetScanHistory, RunTarget} from "../../store/actions";
import {parseTargetStatus} from "../../helpers/index";
import {scanTypes} from "../../types";
import {addInfoSnack} from "../../../../barsEnvironment/snack/store/slice";
import {files} from "../../../documentsRefactor/store/slice";
import {useScrollPagination} from "../../../../../newShared/hooks/useScrollPagination";

export const useTargetsExact = () => {
    const id = useParams<{id: string}>().id;
    const dispatch = useDispatch();
    const history = useHistory();
    const _target = useSelector(selectedTarget);
    const {isLoadingHistory, isLoadingSmallTargetById} = useSelector(loadings);
    const scanType = useSelector(selectedScanType);
    const pageInfo = useSelector(historyPageInfo);

    const controller = new AbortController();

    const handleGoBack = () => {
        history.push(PATH_LOCAL_VULNERABILITY_SCANNED)
    }

    useEffect(() => {
        if(!_target && !isLoadingSmallTargetById){
            dispatch(GetShortTargetById({data: {id, onError: handleGoBack}, signal: controller.signal}));
        }

        return () => {
            controller.abort();
        }
        //eslint-disable-next-line
    }, []);

    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    useSetBreadcrumbs([
        {
            title: tMenu('Vulnerability Scanner'),
            path: PATH_LOCAL_VULNERABILITY_SCANNED
        },
        {
            title: tMenu('Scanned items'),
            path: PATH_LOCAL_VULNERABILITY
        },
        {
            title: _target?.name ?? 'Loading target...',
            path: `${PATH_LOCAL_VULNERABILITY_SCANNED}/${id}`
        }
    ]);

    const handleSelectScanType = (newType: scanTypes) => {
        if(newType !== scanType && !isLoadingHistory && _target){
            dispatch(selectScanType(newType));
            dispatch(GetTargetScanHistory({data: {targetId: _target.id, scanType: newType, page: 0, count: DEFAULT_COUNT}, signal: controller.signal}));
        }
    }

    const handleDeselectScanType = () => {
        dispatch(selectScanType(null));
    }

    //MENU
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //HISTORY
    const handleLoadMore = (page: number) => {
        if(_target && !isLoadingHistory){
            dispatch(GetTargetScanHistory({data: {targetId: _target.id, scanType: scanType ?? 'OPENVAS', page: pageInfo.page + 1, count: DEFAULT_COUNT}, signal: controller.signal}));
        }
    }

    const handleEditTarget = () => {
        _target && history.push(PATH_LOCAL_VULNERABILITY_EDIT.replace(':id', _target.id));
        handleClose();
    }

    const openDeleteTargetDialog = () => {
        _target && dispatch(openDeleteTarget({targetId: _target.id, targetName: `${_target.name} (${_target.target})`}));
        handleClose();
    }

    const onSuccessRun = () => {
        dispatch(addInfoSnack('Scanning started!'));
    }

    const runTarget = () => {
        _target && dispatch(RunTarget({targetId: _target.id, onSuccess: onSuccessRun}));
        handleClose();
    }

    const releaseTarget = () => {
        _target && dispatch(openReleaseTarget())
    }

    //history paging
    const _histories = useSelector(histories);
    const _historyPageInfo = useSelector(historyPageInfo);

    const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => {
            _target && dispatch(GetTargetScanHistory({data: {targetId: _target.id, scanType: scanType ?? 'OPENVAS', page, count: DEFAULT_COUNT}, signal: controller.signal}));
        },
        arrayLength: _histories.length,
        isLoading: isLoadingHistory,
        pageInfo: _historyPageInfo,
        // cleanData: () => dispatch(eraseEmployees()),
        isNoMoreThanTotal: true,
        // customDependencies: [],
        // is0PageAddictive: false,
    })

    return{
        handleGoBack,
        target: _target,
        id,
        isLoading: isLoadingSmallTargetById,
        status: parseTargetStatus(_target?.status ?? ''),
        handleSelectScanType,
        scanType,
        handleDeselectScanType,
        menu: {
            anchorEl,
            handleClose,
            handleClick,
            open,
        },
        history: {
            handleLoadMore,
            hasMore: pageInfo.total > ((pageInfo.page + 1) * pageInfo.count),
            histories: _histories,
            isLoading: isLoadingHistory,
        },
        handleEditTarget,
        openDeleteTargetDialog,
        runTarget,
        releaseTarget,
        historyScroll: {
            hasMore: pageInfo.total > ((pageInfo.page + 1) *pageInfo.count),
            bottomLoader,
            onScroll,
            scrollRef,
            isLoading: isLoadingHistory,
            total: !files.length ? 'loading' : pageInfo.total,
        },
    }
}