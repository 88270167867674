import {
    buildSearchString,
    readValueByKey,
    replaceFilterInSearch,
    replaceValueInSearchByKey,
    searchStringToFilter
} from "../builders";

export const replaceRowsPerPage = (search: string, rowsPerPage: number) => (
  replaceValueInSearchByKey(search, 'count', rowsPerPage)
);

export const replacePage = (search: string, page: number) => (
  replaceValueInSearchByKey(search, 'page', page)
);

export const replaceGroupBy = (search: string, groupBy: string) => (
  replaceValueInSearchByKey(search, 'groupBy', groupBy)
);

export const replaceFilter = (search: string, filter: { [key: string]: string[] }, usePagination?: boolean) => {
    return (
        usePagination === undefined || usePagination ?
            replaceFilterInSearch(replacePage(search, 0), filter, 'page', 'count', 'sort', 'groupBy')
            : replaceFilterInSearch(search, filter, 'page', 'count', 'sort', 'groupBy')

    )
};

export const readPage = (search: string) => (readValueByKey(search, 'page'));
export const readRowsPerPage = (search: string) => (readValueByKey(search, 'count'));
export const readSort = (search: string) => (readValueByKey(search, 'sort'));
export const readGroupBy = (search: string) => (readValueByKey(search, 'groupBy'));
export const readFilter = (search: string) => searchStringToFilter(search, 'count', 'page', 'sort', 'groupBy');
export const convertObjectToSearchString = (obj: { [key: string] : string | string[]}) => buildSearchString(obj);
export const buildChipsFromFilter = (filter: {[key: string]: string[]}) => (
  Object.entries(filter).flatMap(([key, values]: [key: string, values: string[]]) => values.map((value: string) => ({
    filterName: key,
    value: value
  })))
)
