import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Skeleton, Typography} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../../../../newShared/components/Layouts";
import colors from "../../../../../../newShared/theme/colors";
import {CommonAvatar} from "../../../../../../newShared/components/Basic/CommonAvatar";
import {Add, Delete} from "@mui/icons-material";
import {useSelectTaskCollabsDialog} from "../../../hooks/dialogs/useSelectTaskCollabsDialog";
import {CustomSearchField} from "../../customSearch";
import {MainVicaCategoryCollaboratorModel} from "../../../../../../newShared/GQLTypes";
import {LoadingButton} from "@mui/lab";

export const SelectTaskCollabsDialog = () => {
    const {
        isOpen,
        handleClose,
        handleSubmit,
        isOk,
        isLoadingWsCollabs,
        isLoading,
        search,
        selectedStep,
        filteredCollabs,
        taskCollabs,
        handleDeleteCollab,
        handleAddCollab
    } = useSelectTaskCollabsDialog();
    const {t} = useMainTranslation('', {keyPrefix: 'pathVica.TasksList.dialogs'});

    return(
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{t('Assign step')}</DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden'}}>
                <Typography variant={"*bodyText2"}>{t(`Assign responsible collaborators for the step `)}<span style={{fontWeight: 700}}>{selectedStep?.name ?? ''}</span></Typography>
                <Box margin={'16px 0'}>
                    <CustomSearchField variant={'outlined'} value={search.value}
                        onChange={search.handleChange}
                        onClear={search.handleCleanSearch}
                        disabled={isLoading} dir={'ltr'} placeholder={t('Search')}
                        fullWidth size="small" name={'search'} label={t('Search')}
                    />
                </Box>

                {isLoadingWsCollabs &&
                    <Flex ai={'center'} jc={'center'} p={'8px'}>
                        <Skeleton width={40} height={40} variant={'circular'}/>
                        <Box sx={{margin: 'auto 8px'}} display={"flex"} flexGrow={1} flexDirection={"column"} justifyContent={"space-around"} alignItems={"flex-start"}>
                            <Typography variant={"body2"} color={colors.text.dark}><Skeleton width={120}/></Typography>
                            <Typography variant={"body2"} color={colors.text.grey_dark}><Skeleton width={200}/></Typography>
                        </Box>
                        <Skeleton width={100} height={50}/>
                    </Flex>
                }

                <Box display={'flex'} flexDirection={'column'} flexGrow={1} overflow={'auto'}>
                    {filteredCollabs.map(e => <CollabRow key={e.collaboratorId}
                                                         collab={e}
                                                         isAdded={taskCollabs.some(tc => tc.collaboratorId === e.collaboratorId)}
                                                         handleAdd={handleAddCollab}
                                                         handleDelete={handleDeleteCollab} />)}
                </Box>

            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'} size={'small'}>{t('Close')}</Button>
                <LoadingButton variant={'contained'} size={'small'} loading={isLoading} onClick={handleSubmit} disabled={!isOk} >
                    {t('Save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

const CollabRow = ({collab, isAdded, handleAdd, handleDelete} : {collab: MainVicaCategoryCollaboratorModel, isAdded: boolean, handleAdd: (collab: MainVicaCategoryCollaboratorModel) => void, handleDelete: (collab: MainVicaCategoryCollaboratorModel) => void}) => {

    const {t} = useMainTranslation('', {keyPrefix: 'pathVica.TasksList.dialogs'});
    return (
        <Box display={"flex"} alignItems={"center"} p={'6px 0'} borderBottom={'1px solid ' + colors.divider} gap={'10px'}>
            <CommonAvatar name={collab.fullName} size={"d32"} sx={{margin: 'auto 16px'}}/>
            <Box display={"flex"} flexGrow={1}
                 flexDirection={"column"} justifyContent={"space-around"} alignItems={"flex-start"} overflow={"hidden"}>
                <Typography variant={"body2"} color={colors.text.dark}>{collab.fullName}</Typography>
                <Typography variant={"body2"} color={colors.text.grey_dark} noWrap maxWidth={'100%'}>{collab.email}</Typography>
            </Box>

            <LoadingButton variant={"text"} startIcon={isAdded ? <Delete/> : <Add/>}
                           sx={{color: isAdded ? colors.text.grey : colors.text.blue}}
                           onClick={() => {isAdded ? handleDelete(collab) : handleAdd(collab)}}>
                {isAdded ? t('Remove') : t('Add')}
            </LoadingButton>
        </Box>
    )
}
