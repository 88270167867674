import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {GetKycDictionaries, GetKycTemplateConfig} from "../../store/actions";
import {cleanUp, eraseIsDataOutdated, loadings} from "../../store/slice";
import {useLocation} from "react-router-dom";

export const useKycMain = () => {
    const dispatch = useDispatch();
    const {getKycTemplateConfig, getDictionaries} = useSelector(loadings);
    const {pathname} = useLocation();

    useEffect(() => {
        dispatch(GetKycTemplateConfig());
        dispatch(GetKycDictionaries());

        return () => {
            dispatch(cleanUp());
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(eraseIsDataOutdated());
        //eslint-disable-next-line
    }, [pathname]);

    return getKycTemplateConfig || getDictionaries;
}