import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {useFormik} from "formik";
import * as yup from 'yup';
import {clearSelectedTimeOffRequestAction, loadings, openSkipRequestDialog, timeOffSelector} from "../../store/slice";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    mainHrApproveTimeOffRequestAction,
    mainHrGetAndChangeStepStatusTimeOffRequestAction,
    mainHrGetTimeOffRequestsWithFilterPaginationAction,
    mainHrRejectTimeOffRequestAction
} from "../../store/actions";
import {PATH_LOCAL_TIME_OFF_REQUESTS} from "../../constants";
import {DownloadFile} from "../../../documentsRefactor/store/actions";
import {incomingRequestsSelector} from "../../../physicalAssets/store/store";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {HrTimeOffStatus} from "../../../../../newShared/GQLTypes";
import {GENERIC_FILTER_OPTIONS_DEFAULT_PAGING} from "../../../../../newShared/components/genericFilter/constants";

export const useTimeOffRequestExactView = () => {
    const {selected: request} = useSelector(timeOffSelector);
    const dispatch = useDispatch();
    const {setMessage} = useMessageDialog();
    const {timeoffId} = useParams<{timeoffId?: string}>();
    const {t} = useMainTranslation('', {keyPrefix: 'pathHRManagement.pathTimeOff'});
    const {timeOffExact: isLoadingExact, timeOffApprove: isLoadingApprove, timeOffReject: isLoadingReject, downloadFileLoading} = useSelector(loadings);
    const isOpen = !!timeoffId;

    const history = useHistory();
    const location = useLocation();

    const openSkipDialog = () => {
        dispatch(openSkipRequestDialog());
    }

    const handleApproveRequest = (timeoffId: string) => {
        dispatch(mainHrApproveTimeOffRequestAction({
            data: {requestId: timeoffId, comment: formik.values.comment},
            onError: (request, error, addictiveData) => {
                const errors409 = error.e409;
                if (errors409?.length) {
                    getTimeOffRequestById(timeoffId);
                }
            },
            onSuccess: (request, response, addictiveData) => {
                setMessage({
                    title: t('Completed successfully'),
                    message: [
                        t('Time off request {{requestId}} has been approved', {requestId: timeoffId})
                    ]
                });
                handleClose();
            }
        }))
    }

    const handleRejectRequest = (timeoffId: string) => {
        dispatch(mainHrRejectTimeOffRequestAction({
            data: {requestId: timeoffId, comment: formik.values.comment},
            onError: (request, error, addictiveData) => {
                const errors409 = error.e409;
                if (errors409?.length) {
                    getTimeOffRequestById(timeoffId);
                }
            },
            onSuccess: (request, response, addictiveData) => {
                setMessage({
                    title: t('Completed successfully'),
                    message: [
                        t('Time off request {{requestId}} has been rejected', {requestId: timeoffId})
                    ]
                });
                handleClose();
            }
        }))
    }

    const validationSchema = yup.object({
        comment: yup.string()
            .nullable()
            .max(1000, t('Comment should be less than 1000 symbols'))
    });

    const formik = useFormik({
        initialValues: {comment: ''},
        validationSchema,
        onSubmit: (values, formikHelpers) => {
            timeoffId && handleApproveRequest(timeoffId)
        }
    })
    const formikOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldTouched(e.target.name, true, false);
        formik.handleChange(e);
    };

    const cleanSelected = () => {
        dispatch(clearSelectedTimeOffRequestAction());
    };

    const handleClose = () => {
        history.push(PATH_LOCAL_TIME_OFF_REQUESTS + location.search)
    }

    const handleDownloadFile = (fileId: string, name: string) => {
        dispatch(DownloadFile({fileId, name}));
    };

    const {pageInfo: {count}} = useSelector(incomingRequestsSelector);
    const {currentFiltersForFetch, currentSearchForFetch, getCurrentPage} = useGenericFiltersStorage();
    const refreshTable = () => {
        dispatch(mainHrGetTimeOffRequestsWithFilterPaginationAction({data: {pageRequest: {page: getCurrentPage(), count: count || GENERIC_FILTER_OPTIONS_DEFAULT_PAGING}, filter: {...currentFiltersForFetch, timeOffIdLike: currentSearchForFetch}}, clean: true}));
    }

    const getTimeOffRequestById = (timeoffId: string) => {
        dispatch(mainHrGetAndChangeStepStatusTimeOffRequestAction({
            data: {requestId: timeoffId},
            onError: (request, error, addictiveData) => {
                setTimeout(() => {handleClose()}, 300)
                refreshTable();
            },
            onSuccess: (request, response, addictiveData) => {
                if (response.status === HrTimeOffStatus.Cancelled) {
                    setTimeout(() => {handleClose()}, 300)
                    refreshTable();
                }
            },
        }))
    }

    useEffect(() => {
        if (timeoffId) {
            getTimeOffRequestById(timeoffId);
        } else {
            cleanSelected();
        }

        formik.resetForm();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeoffId])


    return{
        isOpen,
        handleClose,
        loadings: {
            isLoadingExact,
            isLoadingApprove,
            isLoadingReject,
            fileDownloading: downloadFileLoading,
        },
        request,
        handleRejectRequest,
        handleApproveRequest,
        handleDownloadFile,
        formik: {...formik, handleChange: formikOnChange},
        timeoffId,
        openSkipDialog
    }
}
