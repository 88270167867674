import {createAsyncThunk} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {getActionsData} from "../../../../newShared/redux";
import {
    addIntegration,
    deleteIntegration,
    downloadAppLogFile,
    getAppHistory,
    getAppLogs,
    getDataByCollection,
    getInitIntegrationsData,
    getIntegrationAppById,
    startResearch,
    updateIntegration
} from "../api";
import {eraseAppsBeforeFetch} from "./slice";
import {addSuccessfulSnack} from "../../../barsEnvironment/snack/store/slice";
import {getVendorsApi, getVendorsSettingsApi} from "../../accessManagement/api";
import {CREATE_APP_EMPLOYEES_AND_VENDORS_PAGING} from "../../accessManagement/constants";
import {createIntegrationWithVendorRequest} from "../types";

import {TWithOptions} from "../../../../newShared/types";
import {saveAs} from "file-saver";
import {b64toBlob} from "../../../../newShared/utils/base64/base64";
import {Buffer} from 'buffer';

//getInitIntegrationsData
export const GetInitIntegrationsData = createAsyncThunk(
    'newIntegrations/getInitIntegrationsData',
    async ({data, signal}: TWithOptions<{availableName: string, installedName: string, eraseBeforeFetch: boolean}>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        if(data.eraseBeforeFetch){
            dispatch(eraseAppsBeforeFetch());
        }
        return await getInitIntegrationsData(workspaceId, data.availableName, data.installedName, signal);
    }
);

//getIntegrationAppById
export const GetIntegrationAppById = createAsyncThunk(
    'newIntegrations/getIntegrationAppById',
    async ({data, signal}: TWithOptions<{appId: string, onReject: () => void}>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        try{
            return await getIntegrationAppById(workspaceId, data.appId, signal);
        }catch (ex: any){
            data.onReject();
            throw new Error(ex.message);
        }
    }
);

//getDataByCollection
export const GetDataByCollection = createAsyncThunk(
    'newIntegrations/getDataByCollection',
    async ({data: {appId, collectionAlias, page,count,search}, signal}: TWithOptions<{appId: string, collectionAlias: string, page: number, count: number, search: string}>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        // console.log(`GetDataByCollection fetch`);
        try{
            const res =  await getDataByCollection(workspaceId, appId, collectionAlias, page, count, search, signal);
            // console.log(`GetDataByCollection res: ${JSON.stringify(res)}`);
            return res;
        }catch (ex: any){
            // data.onReject();
            throw new Error(ex.message);
        }
    }
);


//getAppHistory
export const GetAppHistory = createAsyncThunk(
    'newIntegrations/getAppHistory',
    async ({data: {appId, page,count}, signal}: TWithOptions<{appId: string, page: number, count: number}>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        try{
            return await getAppHistory(workspaceId, appId, page, count, signal);
        }catch (ex: any){
            // data.onReject();
            throw new Error(ex.message);
        }
    }
);

export const UpdateIntegration = createAsyncThunk(
    'newIntegrations/UpdateIntegration',
    async ({integrationDetails, name, detailsId}: { integrationDetails: any, name: string, detailsId: string }, {dispatch, getState}): Promise<{ integrationDetails: any, name: string, detailsId: string }> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await updateIntegration(workspaceId || '', integrationDetails);
        dispatch(addSuccessfulSnack(`Integration updated!`));
        return {integrationDetails, name, detailsId};
    }
);


export const DeleteIntegration = createAsyncThunk(
    'newIntegrations/deleteIntegration',
    async (data: {appId: string, endpointMappingName: string, onSuccess: () => void}, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const response = await deleteIntegration(workspaceId || '', data.appId, data.endpointMappingName);
        response && data.onSuccess();
        return data.appId;
    }
);

export const StartResearch = createAsyncThunk(
    'newIntegrations/startResearch',
    async (data: {appId: string, endpointMappingName: string}, {getState, dispatch}): Promise<string> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await startResearch(workspaceId || '', data.endpointMappingName, data.appId)
            .then(() => {
                dispatch(addSuccessfulSnack('Integration refreshed!'))
                return data.appId;
            })
    }
);


export const AddIntegration = createAsyncThunk(
    'newIntegrations/addIntegration',
    async ({integrationDetails, createVendor, vendorId, onSuccess}:{integrationDetails: any, createVendor: createIntegrationWithVendorRequest | null, vendorId: string | null, onSuccess: (id: string) => void}, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        // console.log(integrationDetails);
        const {id} = await addIntegration(workspaceId || '', integrationDetails, createVendor, vendorId);
        id && onSuccess(id);
        let newIntegrationDetails = {
            appId: id,
            discoveryStatus: 'IN_PROGRESS',
            name: integrationDetails.name,
            idp: integrationDetails.identity,
            hris: integrationDetails.hris,
            detailsId: integrationDetails.detailsId,
            ownerEmail: integrationDetails.ownerEmail,
        }
        return {integrationDetails: newIntegrationDetails, endpointMappingName: integrationDetails.endpointMappingName};
        // return null;
    }
);

export const GetDataForCreateApp = createAsyncThunk(
    'accessManagement/GetDataForCreateApp',
    async (_, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const [{vendors}, vendorSettings] = await Promise.all([
            getVendorsApi(workspaceId,
                organizationId,
                {page: 0, count: CREATE_APP_EMPLOYEES_AND_VENDORS_PAGING, filters: {}}
            ),
            getVendorsSettingsApi({workspaceId, organizationId})
        ]);

        return {
            vendors,
            vendorSettings
        }
    }
);


export const GetAppLogs = createAsyncThunk(
    'newIntegrations/getAppLogs',
    async ({data: {appId, page,count}, signal}: TWithOptions<{appId: string, page: number, count: number}>, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        try{
            return await getAppLogs(workspaceId, appId, page, count, signal);
        }catch (ex: any){
            // data.onReject();
            throw new Error(ex.message);
        }
    }
);

export const DownloadLog = createAsyncThunk(
    'newIntegrations/downloadLog',
    async (data: { fileId: string, fileName: string }, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await downloadAppLogFile({fileId: data.fileId, workspaceId});
        saveAs(b64toBlob(resp.file), data.fileName)
        return {}
    }
);

export const DownloadLogForPreview = createAsyncThunk(
    'newIntegrations/DownloadLogForPreview',
    async (data: { fileId: string }, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await downloadAppLogFile({fileId: data.fileId, workspaceId});
        //receiving base64 plain text
        //decode base64 -> put in store
        const b = await Buffer.from(resp.file, 'base64').toString();

        // saveAs(b64toBlob(resp.file), resp.filename)
        return b;
    }
);