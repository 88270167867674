import {Box, Button, Dialog, DialogActions, DialogContent, Divider, styled, TextField} from "@mui/material";
import {
    TypographyBody1,
    TypographyBody2,
    TypographyBodySmall
} from "../../../../../../../newShared/components/Inputs/styled";
import colors from "../../../../../../../newShared/theme/colors";

export const AddFileDialog = styled(Dialog)(() => ({
    '.MuiPaper-root': {
        minWidth: '900px',
        height: '800px'
    },
}));

// AddFileDialog.defaultProps = {
//     fullScreen: true,
// }

export const TitleBox = styled(Box)(() => ({
    width: '100%',
    padding: '16px',
    backgroundColor: colors.backgrounds.grey_light,
    borderBottom: `1px solid ${colors.stroke.grey}`,
    display: 'flex',
    justifyContent: 'space-between',
    height: '52px'
}))


export const AddDocumentDialogContent = styled(DialogContent)(() => ({
    overflow: 'hidden',
    display: "flex",
    width: '100%',
    '&.MuiDialogContent-root': {
        padding: 0,
    }
}));

export const LeftSide = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
    margin: '10px 0'
}));

export const RightSide = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    margin: '32px 25px',
}));


export const LeftSideMenuItem = styled(Box, {
    shouldForwardProp: (propName) => (
        !["isActive"].includes(propName.toString())
    )})(({isActive}:{isActive?: true}) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '16px',
    backgroundColor: isActive ? colors.backgrounds.blue_light_1 : undefined,
    cursor: 'pointer',
}));

export const LeftSideMenuItemTitle = styled(TypographyBody1)(() => ({
    marginLeft: '6px',
    marginRight: '6px',
}))

export const AddDocumentsDialogActions = styled(DialogActions)(() => ({
    '&.MuiDialogActions-root': {
        borderTop: `1px solid ${colors.stroke.grey}`,
        padding: '12px 24px',
        backgroundColor: colors.backgrounds.grey_light,
    },
}));

export const DialogActionsBox = styled(Box)(() => ({
    display: "flex",
    alignItems: 'center',
    width: '100%',
}));

export const SmallTextButton = styled(Button)(() => ({
    textTransform: 'none'
}));

SmallTextButton.defaultProps = {
    size: 'small',
    variant: 'text',
};

export const SmallOutlinedButton = styled(Button)(() => ({
    textTransform: 'none',
    marginLeft: 'auto'
}));

SmallOutlinedButton.defaultProps = {
    size: 'small',
};

export const CloseButton = styled(SmallTextButton)(() => ({
    marginLeft: 'auto',
}));



export const VerticalDivider = styled(Divider)(() => ({
    borderColor: colors.text.grey_light_2,
}));

VerticalDivider.defaultProps = {
    variant: 'fullWidth',
    orientation: 'vertical'
};

export const StartBlankBox = styled(Box, {
    shouldForwardProp: (propName) => (
        !["isSelected"].includes(propName.toString())
    )})(({isSelected}: {isSelected?: true}) => ({
    width: '150px',
    padding: '12px',
    height: '182px',
    borderRadius: '6.868px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    backgroundColor: colors.backgrounds.white,
    border: `1px solid ${isSelected ? colors.primary.blue : colors.stroke.grey}`,
    boxShadow: '0px 0px 1px 0px rgba(125, 151, 174, 0.02), 0px 2px 6px 0px rgba(125, 151, 174, 0.02), 0px 8px 16px 0px rgba(125, 151, 174, 0.08)',
}));

export const TitleBlankBox = styled(Box)(() => ({
   position: 'absolute',
   bottom: '12px',
   left: '12px'
}));

export const TitleBlank = styled(TypographyBodySmall)(() => ({
    color: colors.text.grey,
}));

export const FolderNameTextField = styled(TextField)(() => ({
    '&.MuiInputBase-root': {
        height: '40px',
    },
    marginTop: '10px',
}));

FolderNameTextField.defaultProps = {
    inputProps: {
        style: {
            width: '88%',
            padding: '13px 10px',
            height: '14px',
        }
    },
    size: 'small',
}


export const InfoViewContainer = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '32px 125px',
    overflow: 'auto',
}));


export const DocNameTextField = styled(TextField)(() => ({
    '&.MuiInputBase-root': {
        height: '40px',
    }
}));
DocNameTextField.defaultProps = {
    size: 'small'
}

export const DocDivider = styled(Divider)(() => ({
    color: colors.text.grey_light_2,
    borderColor: colors.text.grey_light_2,
    width: '100%',
    margin: '20px 0'
}));

export const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
}));

export const InfoActorFlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '42px',
    padding: '6px 0'
}));

export const AutocompleteBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
}));

export const SwitchInfoBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    border: '1px solid black',
    marginLeft: '8px',
    marginRight: '8px',

    '&: hover': {
        border: `1px solid ${colors.primary.blue}`,
    }
}));

export const ApproverBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

export const OrderBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '33px',
    height: '28px',
    border: `0.5px solid ${colors.text.grey}`
}));

export const InfoActorBox = styled(Box)(({h, mt}: {h?: string, mt?: string}) => ({
    marginTop: mt ? mt : 'undefined',
    padding: '4px 4px 4px 16px',
    display: 'flex',
    // flexGrow: 1,
    alignItems: 'center',
    width: '100%',
    height: h ? h : '48px',
    border: `1px solid ${colors.stroke.grey}`,
    borderRadius: '8px',
    boxShadow: '0px 0px 1px 0px rgba(125, 151, 174, 0.02), 0px 2px 6px 0px rgba(125, 151, 174, 0.02), 0px 8px 16px 0px rgba(125, 151, 174, 0.08)',

}));


export const InfoActorContainer = styled(Box, {
    shouldForwardProp: (propName) => (
        !["isDragging", 'isOneApprover', 'isOrdered'].includes(propName.toString())
    )})(({isDragging, isOneApprover, isOrdered}:{isDragging?: true, isOneApprover?: true, isOrdered?: true}) => ({
    padding: isOneApprover ? `2px 4px 0 ${isOrdered ? 0 : '16px'}` : `2px 12px 12px ${isOrdered ? 0 : '16px'}`,
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '580px',
    border: `1px solid ${colors.stroke.grey}`,
    borderRadius: '8px',
    opacity: isDragging ? 0 : 1,
    boxShadow: '0px 0px 1px 0px rgba(125, 151, 174, 0.02), 0px 2px 6px 0px rgba(125, 151, 174, 0.02), 0px 8px 16px 0px rgba(125, 151, 174, 0.08)',

}));

export const RecipientsContainer = styled(Box,  {
    shouldForwardProp: (propName) => (
        !["isOrdered", "isDragging"].includes(propName.toString())
    )})(({isOrdered, isDragging}: {isOrdered?: true, isDragging?: true}) => ({
    padding: `2px 12px 12px ${isOrdered ? 0 : '16px'}`,
    display: 'flex',
    // flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    opacity: isDragging ? 0 : 1,
    width: isOrdered ? '93%' : '100%',
    border: `1px solid ${colors.stroke.grey}`,
    borderRadius: '8px',
    boxShadow: '0px 0px 1px 0px rgba(125, 151, 174, 0.02), 0px 2px 6px 0px rgba(125, 151, 174, 0.02), 0px 8px 16px 0px rgba(125, 151, 174, 0.08)',
}));


export const ActorRowBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: "center",
    gap: '16px',
    overflow: 'hidden',
    flexGrow: 1,
    margin: "5px 0",
}));

export const ActorDataContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: "column",
    overflow: "hidden",
    flexGrow: 1,
}));

export const ActorRowDivider = styled(Divider)(() => ({
    borderBottom: '1px solid ' + colors.divider,
    marginTop: '4px',
    marginRight: '4px',
    flexItem: true,
}));
ActorRowDivider.defaultProps = {
    variant: 'fullWidth'
}


export const TypographyName = styled(TypographyBody2)(() => ({
    color: colors.text.dark,
    noWrap: true,
}));

export const TypographyEmail = styled(TypographyBody2)(() => ({
    color: colors.text.grey_dark,
    noWrap: true,
}));

export const ApproverTitle = styled(TypographyBody2)(() => ({
    margin: '0 16px',
    flexGrow: 1,
}));












