import {useDispatch, useSelector} from "react-redux";
import {
    assetsDialogsSelector,
    incomingRequestsSelector,
    loadings as loadingsSelector,
    setApproveReassignAssetAction
} from "../../../store/store";
import * as yup from "yup";
import {useFormik} from "formik";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {mainAssetsReassignAssetAction} from "../../../store/actions";
import {useHistory, useLocation} from "react-router";
import {PATH_LOCAL_ASSETS_INCOMING_REQUESTS} from "../../../constants";
import {getName} from "../../../../../../newShared/utils/text";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {usePhysicalAssetsRequestsExact} from "../../usePhysicalAssetsRequestsExact";
import React from "react";


export const useApproveReassignAsset = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const {t} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets.dialogs'})
    const {approveReassignAsset: {isOpen}} = useSelector(assetsDialogsSelector);
    const {isLoadingSettings, approveRequest: isLoadingApprove} = useSelector(loadingsSelector);
    const {selected} = useSelector(incomingRequestsSelector);
    const {setMessage} = useMessageDialog();
    const {currData: {currentUser}} = UseManageWorkspacesAndOrganizations();


    const {getRequestById} = usePhysicalAssetsRequestsExact();
    const handleConfirm = () => {
        selected && selected.asset && dispatch(mainAssetsReassignAssetAction({
            data: { data: {
                    requestId: selected.request.id,
                    resolution: formik.values.resolution,
                }},
            addictiveData: {resolver: {id: currentUser?.publicId || '', name: getName(currentUser?.firstName || '', currentUser?.lastName || '')}},
            onError: (request, _error, addictiveData) => {
                const errors = _error.e409;
                if (errors?.length) {
                    getRequestById(selected.request.id);
                }
            },
            onSuccess: (request, response) => {
                setMessage({
                    title: t('Completed successfully'),
                    message: t(`Asset with id {{assetId}} was reassigned to {{fullName}}.`, {assetId: response.id, fullName: selected.request.requestor.fullName} )
                });
                history.push(`${PATH_LOCAL_ASSETS_INCOMING_REQUESTS}${location.search}`);
            }
        }))
    }

    const handleClose = () => {
        dispatch(setApproveReassignAssetAction({clear: true}))
    }

    const openApproveReassignAssetDialog = () => dispatch(setApproveReassignAssetAction({isOpen: true}));

    let validationSchema = yup.object({
        resolution: yup.string()
            .nullable()
            .max(1000, t('Comment should be less than 1000 symbols')),
    });

    const formik = useFormik({
        initialValues: {resolution: ''},
        validationSchema,
        onSubmit: handleConfirm
    });
    const formikOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldTouched(e.target.name, true, false);
        formik.handleChange(e);
    };

    return {
        isOpen,
        selected,
        formik: {...formik, handleChange: formikOnChange},
        handleClose,
        handleConfirm,
        isLoadingSettings,
        isLoadingApprove,

        openApproveReassignAssetDialog,
    }
}
