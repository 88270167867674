import React, {FC} from 'react';
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import * as S from "../styled";
import {GenericFiltersArray} from "../../../../../newShared/components/genericFilter";
import {useTrashPage} from "../../hooks/useTrashPage";
import {TrashTable} from "../../components/trashTable";
import {DOCUMENT_LIST_DEFAULT_COUNT} from "../../constants";
import {RestoreDialog} from "../../components/dialogs/restoreDialog";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const TrashPage: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.TrashPage'});

    const {
        getTrashDocuments,
        eraseFilesHandler,
        // selectedFolder,
        configs
    } = useTrashPage();


    return (
        <S.PageContainer>
            {/*PATH*/}
            <PageTitleAndActions
                title={t('Trash')}
                actions={[]}
            />

            <S.FiltersContainer>
                <GenericFiltersArray
                    configs={configs}
                    fetchResultWithSelectedFilters={(search, filters, signal) => {
                        // console.log(`filters: ${JSON.stringify(filters)} | search: ${JSON.stringify(search)}`);
                        eraseFilesHandler();
                        getTrashDocuments({
                            workspaceId: '',
                            folderId: "trash",
                            pageInfo: {
                                page: 0,
                                count: DOCUMENT_LIST_DEFAULT_COUNT,
                                filters: {
                                    ...filters,
                                    search
                                }
                            },

                        });
                    }}
                    isAddFilters={false}
                />

                <TrashTable />
            </S.FiltersContainer>

            {/*DIALOGS*/}
            <RestoreDialog />


        </S.PageContainer>
    )
}
