import React, {useState} from "react";

export const useMultiFileDropZone = () => {
    const ref = React.createRef<HTMLInputElement>();
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

    const fileHandler = (files: FileList | null) => {
        if(files){
            setUploadedFiles([files[0]]);
        }
    };

    const handleDeleteFile = (fileName: string) => {
        setUploadedFiles([...uploadedFiles.filter(e => e.name !== fileName)]);
    }

    const filePicker = () => ref.current && ref.current.click();

    return{
        filePicker,
        ref,
        fileHandler,
        handleDeleteFile,
        uploadedFiles
    }
}