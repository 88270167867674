import {useAppDispatch} from "../../../../../../newShared/redux";
import {useSelector} from "react-redux";
import {
    dialogs,
    folders,
    foldersPath,
    hideAddDocumentDialog,
    hideAddGroupMessage,
    isLoading,
    openAddGroupMessage,
    selectedFolder as _selectedFolder,
    selectedNewTemplate,
    setFormCreateDoc,
    templatesFoldersSelector,
    templatesPathSelector,
    templatesVariantsSelector
} from "../../../store/slice";
import React, {useCallback, useEffect, useState} from "react";
import {TCreateDocumentMenu, TCreateFormDoc, TEditorFillableBlockData, TShortDocument} from "../../../types";
import {INFO_VIEW, isOkEmail, START_DOCUMENT_ITEM, TEMPLATE_GALLERY_ITEM} from "../../../constants";
import {CreateNewDoc, GetNewTemplateById, GetTemplatesVariants} from "../../../store/actions";
import {TIdName} from "../../../../../../newShared/types";
import {uuid} from "../../../../../../newShared/utils";
import {toBase64} from "../../../../../../newShared/utils/base64/base64";
import {RecipientModel} from "../../../../../../newShared/GQLTypes";
import {getMsFromDays} from "../../../../../../newShared/utils/dateTools";
import {useTemplatePreview} from "../../../../../../newShared/components/docTemplateViewer/hooks/useTemplatePreview";


export const useAddDocumentDialog = () => {

    const dispatch = useAppDispatch();
    const rootFolders = useSelector(folders);
    const templatesFolders = useSelector(templatesFoldersSelector);
    const path = useSelector(foldersPath);
    const templatesPath = useSelector(templatesPathSelector);
    const { isOpen, form, newDocVariant, isTemplatePage, errorActiveFields } = useSelector(dialogs).addDocumentDialog;
    const addGroupMessageDialog = useSelector(dialogs).addGroupMessage;
    const selectedF = useSelector(_selectedFolder);
    const loadings = useSelector(isLoading);

    const isLoadingGetSelectedTemplate = useSelector(isLoading).isLoadingExact;
    const templatesVariants = useSelector(templatesVariantsSelector);
    const _selectedNewTemplate = useSelector(selectedNewTemplate);
    const [pageView, setPageView] = useState<TCreateDocumentMenu>(START_DOCUMENT_ITEM);

    const [searchTemplate, setSearchTemplate] = useState<string>('');
    const [selectedTemplate, setSelectedTemplate] = useState<TShortDocument | null>(null);
    const [selectedFolder, setSelectedFolder] = useState<TIdName | null>(null);
    //upload file
    const [file, setFile] = useState<any>(null);
    const [fileName, setFileName] = useState<string | null>(null);
    const [isDrag, setDrag] = useState<boolean>(false);
    const isAbleToDrop = isOpen || !newDocVariant;
    // const [errorActiveFields, setErrorActiveFields] = useState<string[]>([]);

    const {handleClose: handleClosePreview} = useTemplatePreview();

    const handleSetErrorActiveFields = (errorActiveFields: string[]) => {
        dispatch(setFormCreateDoc({errorActiveFields}));
    }

    const handleIsOkRecipientEmail = ({id, email}:{id: string, email: string}): boolean => {
        return !!email?.length && isOkEmail(email) && !errorActiveFields.some(e => e === id);
    }


    const handleCreateNewDoc = async () => {
        const isOrderedApprovers = form.approvers.some(appr => appr.order > 0);
        const isOrderedRecipients = form.recipients.some(rec => rec.order > 0);

        const fileBase64: string | null = file ? await toBase64(file) as string : null;
        const uploadedFile = (file && fileName && fileBase64) ? {file: fileBase64, extension: fileName.split('.')[fileName.split('.').length-1]} : null;

        dispatch(CreateNewDoc({newValue: {
            ...form,
                name: form.name.length ? form.name : 'New Document',
                approvers: form.approvers
                    .filter(appr => appr.groups.some(gr => gr.actors.some(a => a.email.length)))
                    .map((appr, index) => ({
                        order: isOrderedApprovers ? index + 1 : 0,
                        eta: appr.eta ?? getMsFromDays(7),
                        groups: appr.groups
                            .map(gr => ({
                                actors: gr.actors,
                                name: gr.name,
                                isRequired: gr.isRequired,
                                message: gr.message,
                            }))
                    })),
                recipients: form.recipients
                    .filter(r => r.actor?.email?.length || r.role?.trim().length || r.fields?.length)
                    .map((r, index) => ({
                        fields: r.fields,
                        actor: handleIsOkRecipientEmail({id: r.id, email: r.actor.email})
                            ? {email: r.actor.email, firstName: r.actor.firstName, lastName: r.actor.lastName}
                            : {email: '', firstName: '', lastName: ''},
                        order: isOrderedRecipients ? index + 1 : 0,
                        message: r.message,
                        role: r.role || '',
                        eta: r.eta ?? getMsFromDays(7),
                    })),
                data: {
                    innerHtml: newDocVariant === 'TEMPLATE' ? _selectedNewTemplate?.data?.innerHtml : null,
                    variables: newDocVariant === 'TEMPLATE' ? _selectedNewTemplate?.data?.variables : null,
                    editor: (newDocVariant === 'TEMPLATE' && _selectedNewTemplate?.data?.editor?.length > 0) ? _selectedNewTemplate?.data?.editor :
                        [{
                            id: 'page' + uuid(),
                            type: 'page',
                            data: {
                                content: []
                            }
                    }],
                }
            },
            file: uploadedFile,
            workspaceId: ''}))
    }


    const handleClose = () => {
        dispatch(hideAddDocumentDialog());
    }

    const handleSetBlank = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (e.detail === 2 && !loadings.isLoadingDataForDialog){
                dispatch(setFormCreateDoc({form: {...form, name: '', data: null }, docVariant: 'BLANK'}));
                setPageView(INFO_VIEW)
            } else {
                dispatch(setFormCreateDoc({form: {...form, name: '', data: null }, docVariant: 'BLANK'}));
            }

            setSelectedTemplate(null);
            setFileName(null);
            setFile(null);
        }

    const handleSetTemplate = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | {detail: number}, template: TShortDocument) => {
        if (e.detail === 2 && !loadings.isLoadingDataForDialog ){
            dispatch(GetNewTemplateById({fileId: template.id ?? '', workspaceId: ''}))
            // setSelectedTemplate(template);
            // dispatch(setFormCreateDoc({docVariant: 'TEMPLATE'}));
            setPageView(INFO_VIEW)
        }
        dispatch(setFormCreateDoc({docVariant: 'TEMPLATE'}));
        setSelectedTemplate(template);

    }

    const handleNext = () => {
        if (newDocVariant === 'TEMPLATE' && selectedTemplate){
            dispatch(GetNewTemplateById({fileId: selectedTemplate.id ?? '', workspaceId: ''}))
            setPageView(INFO_VIEW)
        } else {
            (newDocVariant === 'BLANK' || newDocVariant === 'UPLOADED') && setPageView(INFO_VIEW);
        }

    }

    const fileHandler = (file: FileList | null) => {
        if(file && file?.length > 0){
            setFile(file[0]);
            setFileName(file[0].name);
            // setNewDocumentVariant('uploaded');
            dispatch(setFormCreateDoc({docVariant: 'UPLOADED'}));
        }
    };

    const handleDeleteFile = () => {
        setFileName('');
        setFile(null);
        // setNewDocumentVariant(null);
        dispatch(setFormCreateDoc({docVariant: null}));
    }

    const ref = React.createRef<HTMLInputElement>();

    const filePicker = () => {
        ref.current && ref.current.click();
    };

    //add message dialog
    const handleOpenAddMessage = (data: {approverId?: string, groupId?: string, oldMessage?: string, recipientId?: string}) => {
        dispatch(openAddGroupMessage(data))
    };

    const handleHideAddGroupMessage = () => {
        dispatch(hideAddGroupMessage());
    };

    //setForm
    const handleSetForm = useCallback((form: TCreateFormDoc) => {
        dispatch(setFormCreateDoc({form}))

        // eslint-disable-next-line
    }, [form])
    const handleSetDocName = (name: string) => {
        handleSetForm({
            ...form,
            name
        });
    }
    const handleSetDocRecipients = (recipients: RecipientModel[]) => {
        handleSetForm({
            ...form,
            recipients: recipients.map(r => ({
                actor: r.actor,
                order: r.order,
                message: r.message || '',
                role: r.role || '',
                id: uuid(),
                eta: r.eta ?? getMsFromDays(7),
                fields: r.fields?.map((f): TEditorFillableBlockData => ({
                    pageId: f.pageId,
                    id: f.id,
                    type: f.type as TEditorFillableBlockData['type'],
                    size: f.size,
                    position: f.position
                })) || []
            }))
        });
    }

    const handleGoToGalleryVariants = () => {
        dispatch(GetTemplatesVariants());
        setPageView(TEMPLATE_GALLERY_ITEM);
    }

    const handleSetFolder = (folder: TIdName) => {
        handleSetForm({
            ...form,
            folderId: folder.id
        });
        setSelectedFolder(folder);
    }

    const handleSetGroupMessage = (data: {approverId?: string, groupId?: string, recipientId?: string, message: string}) => {

        if ( data.approverId && data.groupId) {
            handleSetForm({
                ...form,
                approvers: form.approvers.map(appr => appr.id === data.approverId ? ({
                    ...appr,
                    groups: appr.groups.map(gr => gr.id === data.groupId ? ({
                        ...gr,
                        message: data.message,
                    }) : gr)
                }) : appr)
            });

        } else if ( data.recipientId) {
            handleSetForm({
                ...form,
                recipients: form.recipients.map(r => r.id === data.recipientId ? ({
                    ...r,
                    message: data.message,
                }) : r)
            });

        }
        handleHideAddGroupMessage();
    };


    useEffect(() => {
        if (isOpen) {

            if (isTemplatePage) {
                setSelectedFolder(rootFolders.find(p => p.name === 'Documents') ?? null);
                handleSetForm({...form, folderId: rootFolders.find(p => p.name === 'Documents')?.id ?? ''});
                setPageView(INFO_VIEW);

            } else {
                setSelectedFolder(selectedF);
                setPageView(START_DOCUMENT_ITEM);
                handleSetForm({...form, folderId: selectedF?.id ?? ''});
            }

            //dispatch(AutocompleteManagersEmailName({workspaceId: '', organizationId: '', text: ''}));

            setFile(null);
            setFileName(null);
            setSearchTemplate('');
            setSelectedTemplate(null);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
        handleClosePreview();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageView, isOpen]);


    return {
        isOpen,
        isTemplatePage,
        templatesPath,
        addGroupMessageDialog,
        loadings,
        rootFolders,
        templatesFolders,
        path,
        ref,
        templatesVariants: templatesVariants.filter(t => t.name.toLowerCase().includes(searchTemplate.toLowerCase())),
        pageView,
        setPageView,
        newDocVariant,
        handleSetBlank,
        file,
        setFile,
        fileName,
        setFileName,
        isDrag,
        setDrag,
        isAbleToDrop,
        handleClose,
        fileHandler,
        handleDeleteFile,
        filePicker,
        isNextDisabled: Boolean(!newDocVariant && !fileName && !selectedTemplate ),

        handleNext,
        isLoadingGetSelectedTemplate,
        searchTemplate,
        setSearchTemplate,
        selectedTemplate,
        handleSetTemplate,
        handleGoToGalleryVariants,

        //doc
        selectedFolder,
        setSelectedFolder,
        handleSetFolder,
        formActors: {
            form,
            setForm: handleSetForm,
        },
        handleSetDocName,

        handleOpenAddMessage,
        handleHideAddGroupMessage,
        handleSetGroupMessage,


        handleCreateNewDoc,
        errorActiveFields,
        setErrorActiveFields: handleSetErrorActiveFields,

        _selectedNewTemplate,
        handleSetDocRecipients,
    }
}
