import {
    MainTrainingAssignmentsExamsAutocompleteModel,
    MainTrainingAssignmentsRecipientsAutocompleteModel,
    MainTrainingAssignmentsRequestorsAutocompleteModel,
    MainTrainingAssignmentsTrainingsAutocompleteModel,
    MainTrainingCategoriesAutocompleteModel,
    MainTrainingExamsCategoriesAutocompleteModel,
    MainTrainingGetAssignmentExamsAutocompleteQueryVariables,
    MainTrainingGetAssignmentRecipientsAutocompleteQueryVariables,
    MainTrainingGetAssignmentRequestorsAutocompleteQueryVariables,
    MainTrainingGetAssignmentTrainingsAutocompleteQueryVariables,
    MainTrainingsGetExamCategoriesAutocompleteQueryVariables,
    MainTrainingsGetExamsAutocompletePaginatedResponseModel,
    MainTrainingsGetExamsAutocompleteQueryVariables,
    MainTrainingsGetTemplatesCategoriesAutocompleteQueryVariables,
    MainTrainingsGetTrainingsAutocompletePaginatedResponseModel,
    MainTrainingsGetTrainingsAutocompleteQueryVariables,
    MainTrainingsGetTrainingsCategoriesAutocompleteQueryVariables
} from "../../../../newShared/GQLTypes";
import axiosSync from "../../../../newShared/utils/axios/axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {print} from "graphql";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {AxiosResponse} from "axios";
import {
    mainTrainingGetAssignmentExamsAutocomplete,
    mainTrainingGetAssignmentRecipientsAutocomplete,
    mainTrainingGetAssignmentRequestorsAutocomplete,
    mainTrainingGetAssignmentTrainingsAutocomplete,
    mainTrainingsGetExamCategoriesAutocomplete,
    mainTrainingsGetExamsAutocomplete,
    mainTrainingsGetTemplatesCategoriesAutocomplete,
    mainTrainingsGetTrainingsAutocomplete,
    mainTrainingsGetTrainingsCategoriesAutocomplete
} from "./query";


export const mainTrainingsGetExamCategoriesAutocompleteApi = async (data: MainTrainingsGetExamCategoriesAutocompleteQueryVariables, signal?: AbortSignal): Promise<MainTrainingExamsCategoriesAutocompleteModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(mainTrainingsGetExamCategoriesAutocomplete),
                variables: {
                    ...data,
                }
            },
            {
                headers: buildCommonHeader(),
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.mainTrainingsGetExamCategoriesAutocomplete;
    })
)

export const mainTrainingsGetTrainingsCategoriesAutocompleteApi = async (data: MainTrainingsGetTrainingsCategoriesAutocompleteQueryVariables, signal?: AbortSignal): Promise<MainTrainingCategoriesAutocompleteModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(mainTrainingsGetTrainingsCategoriesAutocomplete),
                variables: {
                    ...data,
                }
            },
            {
                headers: buildCommonHeader(),
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.mainTrainingsGetTrainingsCategoriesAutocomplete;
    })
)

export const mainTrainingGetAssignmentRequestorsAutocompleteApi = async (data: MainTrainingGetAssignmentRequestorsAutocompleteQueryVariables, signal?: AbortSignal): Promise<MainTrainingAssignmentsRequestorsAutocompleteModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(mainTrainingGetAssignmentRequestorsAutocomplete),
                variables: {
                    ...data,
                }
            },
            {
                headers: buildCommonHeader(),
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.mainTrainingGetAssignmentRequestorsAutocomplete;
    })
)

export const mainTrainingGetAssignmentRecipientsAutocompleteApi = async (data: MainTrainingGetAssignmentRecipientsAutocompleteQueryVariables, signal?: AbortSignal): Promise<MainTrainingAssignmentsRecipientsAutocompleteModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(mainTrainingGetAssignmentRecipientsAutocomplete),
                variables: {
                    ...data,
                }
            },
            {
                headers: buildCommonHeader(),
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.mainTrainingGetAssignmentRecipientsAutocomplete;
    })
)


export const mainTrainingGetAssignmentTrainingsAutocompleteApi = async (data: MainTrainingGetAssignmentTrainingsAutocompleteQueryVariables, signal?: AbortSignal): Promise<MainTrainingAssignmentsTrainingsAutocompleteModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(mainTrainingGetAssignmentTrainingsAutocomplete),
                variables: {
                    ...data,
                }
            },
            {
                headers: buildCommonHeader(),
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.mainTrainingGetAssignmentTrainingsAutocomplete;
    })
)

export const mainTrainingGetAssignmentExamsAutocompleteApi = async (data: MainTrainingGetAssignmentExamsAutocompleteQueryVariables, signal?: AbortSignal): Promise<MainTrainingAssignmentsExamsAutocompleteModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(mainTrainingGetAssignmentExamsAutocomplete),
                variables: {
                    ...data,
                }
            },
            {
                headers: buildCommonHeader(),
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.mainTrainingGetAssignmentExamsAutocomplete;
    })
)

export const mainTrainingsGetTrainingsAutocompleteApi = async  (data: MainTrainingsGetTrainingsAutocompleteQueryVariables, signal?: AbortSignal): Promise<MainTrainingsGetTrainingsAutocompletePaginatedResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(mainTrainingsGetTrainingsAutocomplete),
                variables: {
                    ...data,
                }
            },
            {
                headers: buildCommonHeader(),
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.mainTrainingsGetTrainingsAutocomplete;
    })
)

export const mainTrainingsGetExamsAutocompleteApi = async  (data: MainTrainingsGetExamsAutocompleteQueryVariables, signal?: AbortSignal): Promise<MainTrainingsGetExamsAutocompletePaginatedResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(mainTrainingsGetExamsAutocomplete),
                variables: {
                    ...data,
                }
            },
            {
                headers: buildCommonHeader(),
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.mainTrainingsGetExamsAutocomplete;
    })
)

export const mainTrainingsGetTemplatesCategoriesAutocompleteApi = async (data: MainTrainingsGetTemplatesCategoriesAutocompleteQueryVariables, signal?: AbortSignal): Promise<MainTrainingCategoriesAutocompleteModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(mainTrainingsGetTemplatesCategoriesAutocomplete),
                variables: {
                    ...data,
                }
            },
            {
                headers: buildCommonHeader(),
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.mainTrainingsGetTemplatesCategoriesAutocomplete;
    })
)

