import {evidenceWithNames} from "../../../types";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {SelectedEvidenceCardWrapper} from "../../styled";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {IconButton, Typography} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React from "react";
import {useFrameworks} from "../../../../frameworks/hooks/useFrameworks";
import {useCurrentFramework} from "../../../../frameworks/hooks/useCurrentFramework";
import {useControl} from "../../../../frameworks/hooks/useControl";
import colors from "../../../../../../newShared/theme/colors";

export const SelectedEvidenceCard = ({evidence, onDelete}:{evidence: evidenceWithNames, onDelete?: (evidenceId: string) => void }) => {
    const {revDir} = useMainTranslation('', {keyPrefix: 'accessManagement.campaignReview'});
    const {openFramework} = useFrameworks();
    const {openControl} = useCurrentFramework();
    const {goToEvidences} = useControl();

    const isEditMode = onDelete !== undefined;

    return(
        <SelectedEvidenceCardWrapper revDir={revDir}>
            <Flex w={isEditMode ? '85%' : '100%'} direction={'column'}>
                <Flex ai={'center'}>
                    <Typography
                        variant={"body2"}
                        noWrap
                        sx={!isEditMode ? {'&:hover': {cursor: 'pointer', color: colors.text.blue, textDecoration: 'underline'}} : {}}
                        onClick={!isEditMode ? () => {openFramework(evidence.frameworkId)} : () => null}
                    >{evidence.frameworkName}</Typography>
                    <Typography variant={"body2"} m={'0 8px'}>{!revDir ? '→' : '←'}</Typography>
                </Flex>

                <Flex ai={'center'}>
                    <Typography
                        variant={"body2"}
                        noWrap
                        sx={!isEditMode ? {'&:hover': {cursor: 'pointer', color: colors.text.blue, textDecoration: 'underline'}} : {}}
                        onClick={!isEditMode ? () => {openControl(evidence.controlId, evidence.frameworkId)} : () => null}
                    >{evidence.controlName}</Typography>
                    <Typography variant={"body2"} m={'0 8px'}>{!revDir ? '→' : '←'}</Typography>
                </Flex>

                <Flex ai={'center'}>
                    <Typography
                        variant={"body2"}
                        noWrap
                        sx={!isEditMode ? {'&:hover': {cursor: 'pointer', color: colors.text.blue, textDecoration: 'underline'}} : {}}
                        onClick={!isEditMode ? () => {goToEvidences(evidence)} : () => null}
                    >{evidence.evidenceName}</Typography>
                </Flex>
            </Flex>

            {onDelete &&
                <Flex w={'15%'} ai={'center'} jc={'center'}>
                    <IconButton size={'small'} onClick={() => isEditMode ? onDelete(evidence.evidenceId) : () => null}>
                        <ClearIcon fontSize={'small'}/>
                    </IconButton>
                </Flex>
            }
        </SelectedEvidenceCardWrapper>
    )
}