import {dialogs, hidePreviewJobTitleDialog} from "../../../../store/slice";
import {useDispatch, useSelector} from "react-redux";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {DialogContentWrapper} from "../../../styled";
import {Flex} from "../../../../../../../newShared/components/Layouts";
import colors from "../../../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const PreviewJobTitleDialog = () => {
    const dispatch = useDispatch();
    const {isOpen, jobTitle} = useSelector(dialogs).previewJobTitle;
    const {t} = useMainTranslation('', {keyPrefix: 'pathHRManagement.dialogs'});
    const {tCommon} = useMainTranslation();

    const handleClose = () => dispatch(hidePreviewJobTitleDialog());

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{jobTitle?.name} {t('preview')}</DialogTitle>

            <DialogContent>
                <DialogContentWrapper>
                    {jobTitle &&
                        <>
                            <Flex ai={'center'} gap={'8px'} m={'16px 0 0 0'}>
                                <Typography color={colors.text.grey}>{t('Name')}</Typography>
                                <Typography color={colors.grayText}>{jobTitle.name}</Typography>
                            </Flex>

                            <Flex ai={'center'} gap={'8px'} m={'16px 0 0 0'}>
                                <Typography color={colors.text.grey}>{t('Type')}</Typography>
                                <Typography color={colors.grayText}>{jobTitle.jobType}</Typography>
                            </Flex>

                            <Flex  gap={'8px'} m={'16px 0 0 0'}>
                                <Typography color={colors.text.grey}>{t('Requirements')}</Typography>
                                <Typography color={colors.grayText}>{jobTitle.requirements}</Typography>
                            </Flex>

                            <Flex  gap={'8px'} m={'16px 0 0 0'}>
                                <Typography color={colors.text.grey}>{t('Description')}</Typography>
                                <Typography color={colors.grayText}>{jobTitle.description}</Typography>
                            </Flex>
                        </>
                    }
                </DialogContentWrapper>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{tCommon('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}
