import * as S from "../styled";
import {Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";

export type customTableHeader = {
    columns: {name: string, width?: string}[];
    width?: string | undefined;
    hideIf: boolean;
    padding?: string;
}

export const CustomTableHeader = ({columns, width, hideIf, padding}: customTableHeader) => {
    return(
        hideIf ? <></> :
        <S.AutomationsTableHeaderWrapper width={width} p={padding}>
            {columns.map(e => {
                return(
                    <S.TableHeaderItemWrapper width={e.width ?? `${100 / columns.length}%`} key={e.name}>
                        <Typography sx={{fontSize: '16px', fontWeight: 700, color: colors.grayText}}>{e.name}</Typography>
                    </S.TableHeaderItemWrapper>
                )
            })}
        </S.AutomationsTableHeaderWrapper>
    )
}