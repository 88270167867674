import {Box, OutlinedInput, Popper, styled, Typography} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import colors from "../../../../../../theme/colors";
import {TypographyBody2} from "../../../../../Inputs/styled";


export const CommonPopper = styled(Popper)`
  z-index: 3100;
`;

export const CommonPopperInnerWrapper = styled(Box)`
    border: 1px solid ${colors.stroke.grey};
  border-radius: 4px;
  filter: drop-shadow(0px 0.6px 1.8px rgba(0, 0, 0, 0.1)) drop-shadow(0px 3.2px 7.2px rgba(0, 0, 0, 0.13));
  width: 270px;
  display: flex;
  flex-direction: column;
  background-color: ${colors.backgrounds.white};
`;

export const PopperSearchInput = styled(OutlinedInput)({
    padding: '0',
    height: '38px',
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiButtonBase-root': {
        padding: '13px 10px',
    }
})

PopperSearchInput.defaultProps = {
    inputProps: {
        style: {
            width: '82%',
            padding: '10px 8px',
            height: '14px'
        }
    },
    size: 'small'
}

export const SearchIconStyled = styled(SearchIcon)({width: '16px', height: '16px', color: colors.text.grey_dark});

export const VariablesScrollContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 114px;
  background-color: ${colors.backgrounds.white};
`;

export const VariableWrapper = styled('div')<{selected?: boolean}>`
  padding: 8px;
  ${props => props.selected ? `background-color: ${colors.backgrounds.blue_light_1}` : ''}
`;

export const VariableNameText = styled(Typography)({
    color: colors.grayText
})

VariableNameText.defaultProps = {
    variant: 'body2',
    noWrap: true
}

export const CancelButtonContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const MenuItemText = styled(TypographyBody2)(() => ({
    marginLeft:'16px',
    marginRight: '16px',
    color: colors.text.dark,
}));