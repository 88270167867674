import {
    MainTrainingAssignmentPostExamAnswerStatusForValidation,
    TrainingExamQuestionType
} from "../../../../../newShared/GQLTypes";
import * as S from '../styled';
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Collapse, Divider, FormControlLabel, Radio, TextField, Typography} from "@mui/material";
import React, {FC, useEffect, useState} from "react";
import {SectionArrow} from "../../../../../newShared/components/SectionArrow";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import colors from "../../../../../newShared/theme/colors";
import RadioGroup from "@mui/material/RadioGroup";
import {useCustomFormik} from "../../../../../newShared/hooks/useCustomFormik";
import {TQuestionWithValidationInput} from "../../types";
import {
    FlexColumn,
    FlexRow
} from "../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import {handleMinMaxStep, questionTypeImageMapper} from "../../helpers";
import {LightTooltip} from "../../../../../newShared/components/pageTitleAndActions";

type QuestionToValidateProps = {
    masterFormik: ReturnType<typeof useCustomFormik<{questions: TQuestionWithValidationInput[]}>>;
    questionIndex: number;
};

export const QuestionToValidate: FC<QuestionToValidateProps> = ({questionIndex, masterFormik: formik}) => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.dialogs'});

    const question = formik.values.questions[questionIndex];
    const error = formik.errors.questions?.[questionIndex];
    const touched = formik.touched.questions?.[questionIndex];

    const [isOpen, setIsOpen] = useState(false);

    const handleChangeStatus = (_: any, status: string) => {
        formik.setFieldValue(`questions[${questionIndex}]validation`, {
            validationStatus: status as MainTrainingAssignmentPostExamAnswerStatusForValidation,
            validationComment: {
                points: status === MainTrainingAssignmentPostExamAnswerStatusForValidation.PartiallyPassed ? (question.points ?? 2) - 1 : 0,
                correctAnswer: [],
            }
        });
    };

    const commentError = typeof error !== "string" && error?.validation?.validationComment?.correctAnswer?.[0];
    const commentTouched = touched && touched?.validation?.validationComment?.correctAnswer;

    const errorPoints = typeof error !== "string" && error?.validation?.validationComment?.points;
    const touchedPoints = touched && touched?.validation?.validationComment?.points;

    useEffect(() => {
        isOpen && setTimeout(() => {
            const questionElement = document.getElementById('question-' + question.questionId);
            questionElement?.scrollIntoView({behavior: 'smooth', inline: 'start'});
        }, 150);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <>
            <S.TrainingSectionBox revDir={revDir} onClick={() => setIsOpen(prev => !prev)} sx={{cursor: 'pointer'}} id={'question-' + question.questionId}>
                <FlexRow sx={{alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                    <FlexRow sx={{alignItems: 'center', gap: '10px'}} >
                        <SectionArrow isOpen={isOpen}/>
                        <Typography variant={'*bodyText2'}>{`${t('Question')} ${questionIndex + 1}`}</Typography>
                    </FlexRow>

                    <FlexRow display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                        {!!question.validation.validationStatus &&
                            <FlexRow sx={{
                                alignItems: 'center', justifyContent: 'flex-start', gap: '8px',
                                padding: '0 16px',
                                borderLeft: `1px solid ${colors.text.grey_light_2}`
                            }}>
                                <CheckCircleOutlineOutlinedIcon/>
                                <Typography variant={'*bodyText2'}>{t('Rated')}</Typography>
                            </FlexRow>
                        }

                        <FlexRow sx={{
                            alignItems: 'center', justifyContent: 'flex-start', gap: '8px',
                            padding: '0 16px',
                            width: '200px',
                            cursor: 'pointer',
                            borderLeft: `1px solid ${colors.text.grey_light_2}`
                        }}>
                            {questionTypeImageMapper(question.type).questionImage}
                            {questionTypeImageMapper(question.type).questionTitle}
                        </FlexRow>

                        <LightTooltip placement={'bottom-end'} title={t('Number of points for the correct answer')}>
                            <FlexRow sx={{
                                alignItems: 'center', justifyContent: 'flex-start', gap: '8px',
                                padding: '0 16px',
                                width: '200px',
                                cursor: 'pointer',
                                borderLeft: `1px solid ${colors.text.grey_light_2}`
                            }}>
                                <BeenhereIcon/>
                                {question.points}
                            </FlexRow>
                        </LightTooltip>
                    </FlexRow>
                </FlexRow>
            </S.TrainingSectionBox>

            <Collapse in={isOpen} sx={{flexShrink: 0, width: '100%'}}>
                <FlexColumn sx={{justifyContent: 'space-between', alignItems: 'flex-start', gap: '27px', padding: '20px'}}>
                    <Typography sx={{whiteSpace: 'break-spaces'}} color={colors.text.dark} variant={'*bodyText1'}>{question.description}</Typography>
                    <Typography color={colors.text.dark} variant={'*bodyText1'}>{question.question}</Typography>

                    <FlexColumn sx={{
                        alignItems: 'flex-start', justifyContent: 'flex-start', gap: '6px',
                        borderRadius: '6px',
                        width: '100%',
                        backgroundColor: colors.primary.disable2,
                        padding: '12px 16px'
                    }}>
                        <Typography color={colors.text.dark} variant={'*bodyText1_semibold'}>{t('Recipient answer')}</Typography>
                        <Typography sx={{whiteSpace: 'break-spaces'}} color={colors.text.dark} variant={'*bodyText1'}>{question.answer?.[0] || ''}</Typography>
                    </FlexColumn>

                    <Divider flexItem/>

                    <Typography color={colors.text.dark} variant={'*bodyText1_semibold'}>{`${t('Evaluate answer')}:`}</Typography>

                    <FlexRow sx={{alignItems: 'center', justifyContent: 'flex-start', gap: '52px', marginTop: '-18px'}}>
                        <RadioGroup sx={{gap: '36px'}} row value={question.validation.validationStatus} onChange={handleChangeStatus}>
                            <FormControlLabel value={MainTrainingAssignmentPostExamAnswerStatusForValidation.Passed}
                                              control={<Radio/>} label={t("Correct")}/>
                            <FormControlLabel value={MainTrainingAssignmentPostExamAnswerStatusForValidation.PartiallyPassed}
                                              control={<Radio/>} label={t("Partially correct")}/>
                            <FormControlLabel value={MainTrainingAssignmentPostExamAnswerStatusForValidation.Failed}
                                              control={<Radio/>} label={t("Incorrect")}/>
                        </RadioGroup>

                        {question.validation.validationStatus === MainTrainingAssignmentPostExamAnswerStatusForValidation.PartiallyPassed &&
                            <FlexRow sx={{alignItems: 'center', justifyContent: 'flex-start', gap: '10px'}}>
                                <Typography variant={'*bodyText2_semibold'}>{t('Points for answer')}</Typography>

                                <TextField name={`questions[${questionIndex}]validation.validationComment.points`}

                                           value={question.validation.validationComment.points}
                                           error={!!errorPoints && touchedPoints}
                                           helperText={touchedPoints && errorPoints}

                                           onChange={handleMinMaxStep(1, (question.points || 2) - 1, 1, formik.handleChange)}
                                           onBlur={formik.handleBlur}

                                           sx={{width: '90px'}} size={'small'}
                                           type={"number"}
                                           InputProps={{inputProps: {min: 1, max: (question.points || 2) - 1}}}
                                />
                                <Typography variant={'*bodyText2'}>{t('points')}</Typography>
                            </FlexRow>
                        }
                    </FlexRow>

                    {(
                            question.validation.validationStatus === MainTrainingAssignmentPostExamAnswerStatusForValidation.PartiallyPassed
                            || question.validation.validationStatus === MainTrainingAssignmentPostExamAnswerStatusForValidation.Failed
                        ) &&
                        <TextField
                            label={t('Provide your comment')}
                            name={`questions[${questionIndex}]validation.validationComment.correctAnswer[0]`}

                            value={question.validation.validationComment.correctAnswer[0] || ''}
                            error={!!commentError && commentTouched}
                            helperText={commentTouched && commentError}

                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}

                            {...(question.type === TrainingExamQuestionType.ShortAnswer ? {
                                size: 'small',
                            } : {
                                multiline: true,
                                rows: 4,
                            })}
                        />
                    }
                </FlexColumn>
            </Collapse>
        </>
    )
}