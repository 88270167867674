import {useDispatch, useSelector} from "react-redux";
import {frameworkControlDelegatedTasks, frameworkControlEvidencesSelector} from "../../../store/control/store";
import {useEffect, useState} from "react";
import {
    frameworkGetDelegatedTaskById,
    frameworksApproveTaskById,
    frameworksCancelTaskById,
    frameworksDeclineTaskById,
    getControlByIdFrameworkIdAction
} from "../../../store/control/actions";
import {
    DelegatedTaskModel,
    Exact,
    FrameworksDelegatedTaskDataAttachedFilesFileModel,
    FrameworksDelegatedTaskDataFilesListInput,
    MainFrameworksApproveTaskByIdMutationVariables,
    MainFrameworksCancelTaskByIdMutationVariables,
    MainFrameworksDeclineTaskByIdMutationVariables,
} from "../../../../../../newShared/GQLTypes";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useFrameworksPathParams} from "../../useFrameworksPathParams";
import {useHistory} from "react-router";
import {PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED} from "../../../../../../newShared/constants";
import {PATH_FRAGMENT_DELEGATED_TASKS} from "../../../constants";
import {usePDFView} from "../../../../../../newShared/components/docViewer/hooks/usePDFView";
import {useControl} from "../../useControl";
import {TApolloErrors} from "../../../../../../newShared/utils/asyncThunk/types";

export const useExactDelegatedTask = ({refreshTable}: {refreshTable: () => void}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {handleOpen} = usePDFView();
    const {downloadFile} = useControl();

    //selectors
    // const {isOpen, id} = useSelector(frameworkControlDialogsSelector).exactDelegatedTask;
    const {evidences} = useSelector(frameworkControlEvidencesSelector);
    const {exactDelegatedTask, isLoadingExact, isLoadingApprove, isLoadingCancel, isLoadingDecline} = useSelector(frameworkControlDelegatedTasks);
    const {setMessage, isOpenMessageDialog} = useMessageDialog();
    const {taskId, frId, controlId} = useFrameworksPathParams();
    const id = taskId;
    const isOpen = id !== undefined;

    //useEffects
    useEffect(() => {
        if(isOpen && id){
            //remove old selected task - refetch new one
            getTaskById(id);
        }
        //eslint-disable-next-line
    }, [isOpen]);

    //stores
    const [cancelTaskDialog, setCancelTaskDialog] = useState<boolean>(false);
    const [declineTaskDialog, setDeclineTaskDialog] = useState<boolean>(false);
    const [allDocsPreviewDialog, setAllDocsPreviewDialog] = useState<boolean>(false);
    const [evidenceDocsPreviewDialog, setEvidenceDocsPreviewDialog] = useState<boolean>(false);
    const [approveTaskDialog, setApproveTaskDialog] = useState<boolean>(false);

    //actions
    const getTaskById = (_id: string) => {
        dispatch(frameworkGetDelegatedTaskById({data: {workspaceId: '', taskId: _id}, clean: true, onError: handleClose}));
    }
    const handleClose = () => {
        history.push({
            pathname: `${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/${frId}/control/${controlId}/${PATH_FRAGMENT_DELEGATED_TASKS}`,
            search: history.location.search
        });
        setCancelTaskDialog(false);
        setDeclineTaskDialog(false);
        setApproveTaskDialog(false);
        setEvidenceDocsPreviewDialog(false);
        setAllDocsPreviewDialog(false);
    }

    const handleOpenCancelTask = () => setCancelTaskDialog(true);

    const handleCancelTask = (reason: string) => {
        const onSuccess = (request: (Omit<MainFrameworksCancelTaskByIdMutationVariables, "workspaceId" | "organizationId"> & {workspaceId?: string | undefined, organizationId?: string | undefined}), response: DelegatedTaskModel, addictiveData?: ({} | undefined)) => {
            setMessage({title: 'Completed successfully', message: `Delegated task ${response.id} has been cancelled.`});
            handleClose();
            setCancelTaskDialog(false);
        }

        const onError = (request: (Omit<Exact<{workspaceId: string, taskId: string, reason: string}>, "workspaceId" | "organizationId"> & {workspaceId?: string | undefined, organizationId?: string | undefined}), error: TApolloErrors, addictiveData?: ({} | undefined)) => {
            const error409 = error.e409?.[0];
            const error404 = error.e404?.[0];
            if(error409?.type === 'STATUS_CHANGED'){
                setMessage({title: 'Action conflict error', message: `Action not applicable! Task status was changed`});
                setCancelTaskDialog(false);
                id && getTaskById(id);
            }
            if(error404){
                setMessage({title: `Delegated task ${id}`, message: `Delegated task with id ${id} not found`});
                setCancelTaskDialog(false);
                id && getTaskById(id);
                refreshTable();
                handleClose();
            }
        }
        

        exactDelegatedTask && dispatch(frameworksCancelTaskById({data: {taskId: exactDelegatedTask.id, reason}, onSuccess, onError}));
    }

    const handlePreviewFile = (file: FrameworksDelegatedTaskDataAttachedFilesFileModel) => {
        handleOpen({...file});
    }

    const handleDownloadFile = (file: FrameworksDelegatedTaskDataAttachedFilesFileModel) => {
        downloadFile(file.fileId, file.fileName);
    }

    const openPreviewAllFiles = () => setAllDocsPreviewDialog(true);

    const openPreviewAllAddedToEvidenceFiles = () => setEvidenceDocsPreviewDialog(true);

    const handleOpenDeclineTask = () => setDeclineTaskDialog(true);
    const handleDeclineTask = (reason: string) => {
        const onSuccess = (request: (Omit<MainFrameworksDeclineTaskByIdMutationVariables, "workspaceId" | "organizationId"> & {workspaceId?: string | undefined, organizationId?: string | undefined}), response: DelegatedTaskModel, addictiveData?: ({} | undefined)) => {
            setMessage({title: 'Completed successfully', message: `Delegated task ${response.id} has been declined.`});
            handleClose();
            setDeclineTaskDialog(false);
        }

        const onError = (request: (Omit<Exact<{workspaceId: string, taskId: string, reason: string}>, "workspaceId" | "organizationId"> & {workspaceId?: string | undefined, organizationId?: string | undefined}), error: TApolloErrors, addictiveData?: ({} | undefined)) => {
            const error409 = error.e409?.[0];
            const error404 = error.e404?.[0];
            if(error409?.type === 'STATUS_CHANGED'){
                setMessage({title: 'Action conflict error', message: `Action not applicable! Task status was changed`});
                setDeclineTaskDialog(false);
                id && getTaskById(id);
            }
            if(error404){
                setMessage({title: `Delegated task ${id}`, message: `Delegated task with id ${id} not found`});
                setDeclineTaskDialog(false);
                id && getTaskById(id);
                refreshTable();
                handleClose();
            }
        }

        exactDelegatedTask && dispatch(frameworksDeclineTaskById({data: {taskId: exactDelegatedTask.id, reason}, onSuccess, onError}));
    }

    const handleOpenApproveTask = () => setApproveTaskDialog(true);

    const handleApproveTask = (files: FrameworksDelegatedTaskDataAttachedFilesFileModel[]) => {
        const onSuccess = (request: (Omit<MainFrameworksApproveTaskByIdMutationVariables, "workspaceId" | "organizationId"> & {workspaceId?: string | undefined, organizationId?: string | undefined}), response: DelegatedTaskModel, addictiveData?: ({} | undefined)) => {
            setMessage({title: 'Completed successfully', message: `Delegated task ${response.id} has been approved. All selected files have been added to evidence.`});
            handleClose();
            setApproveTaskDialog(false);
            dispatch(getControlByIdFrameworkIdAction({data: {workspaceId: '', organizationId: '', language: '', frameworkId: frId ?? '', controlId: controlId ?? ''}}))
        }

        const onError = (request: Omit<Exact<{workspaceId: string, taskId: string, selectedAttachments: FrameworksDelegatedTaskDataFilesListInput}>, "workspaceId" | "organizationId">, error: TApolloErrors, addictiveData?: ({} | undefined)) => {
            const error409 = error.e409?.[0];
            const error404 = error.e404?.[0];
            if(error409?.type === 'STATUS_CHANGED'){
                setMessage({title: 'Action conflict error', message: `Action not applicable! Task status was changed`});
                setApproveTaskDialog(false);
                id && getTaskById(id);
            }
            if(error404){
                setMessage({title: `Delegated task ${id}`, message: `Delegated task with id ${id} not found`});
                setApproveTaskDialog(false);
                id && getTaskById(id);
                refreshTable();
                handleClose();
            }
        }

        if(exactDelegatedTask){
            dispatch(frameworksApproveTaskById({
                data: {taskId: exactDelegatedTask.id, selectedAttachments: {files}},
                onSuccess,
                onError
            }));
        }
    }

    const linkedEvidence = exactDelegatedTask ? evidences.find(e => e.id === exactDelegatedTask.evidenceId) : undefined;

    const handleGoToEvidence = () => {
        linkedEvidence && history.push(`${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/${frId}/control/${controlId}/evidences/${linkedEvidence.id}`)
    }

    return{
        isOpen: isOpen && !isOpenMessageDialog &&
            !cancelTaskDialog && !declineTaskDialog &&
            !allDocsPreviewDialog && !approveTaskDialog && !evidenceDocsPreviewDialog
        ,
        exactDelegatedTask,
        isLoadingExact,
        actions: {
            handleOpenCancelTask,
            handleClose,
            handleDownloadFile,
            handlePreviewFile,
            openPreviewAllFiles,
            handleOpenApproveTask,
            handleOpenDeclineTask,
            openPreviewAllAddedToEvidenceFiles,
            handleGoToEvidence
        },
        id,
        cancelTaskDialog:{
            isOpen: cancelTaskDialog && !isOpenMessageDialog,
            handleClose: () => setCancelTaskDialog(false),
            handleCancelTask,
            isLoading: isLoadingCancel
        },
        declineTaskDialog: {
            isOpen: declineTaskDialog && !isOpenMessageDialog,
            handleClose: () => setDeclineTaskDialog(false),
            handleDeclineTask,
            isLoading: isLoadingDecline
        },
        previewAllDocsDialog: {
            isOpen: allDocsPreviewDialog || evidenceDocsPreviewDialog,
            handleClose: () => {
                setAllDocsPreviewDialog(false);
                setEvidenceDocsPreviewDialog(false);
            },
            attachments: exactDelegatedTask?.data?.attachedFiles ?? [],
            isEvidenceAttachments: evidenceDocsPreviewDialog,
        },
        approveTaskDialog: {
            isOpen: approveTaskDialog && !isOpenMessageDialog,
            handleClose: () => setApproveTaskDialog(false),
            handleApproveTask,
            isLoading: isLoadingApprove
        },
        evidenceName: linkedEvidence?.name ?? 'N/A',
    }
}