import {useScroll} from "../useScroll";
import {useEffect, useMemo, useState} from "react";
import {TPageInfo} from "../../types";

type TAny = (string | object | boolean | undefined | null);
type TProps = {
    getData: (pageInfo: {page: number, count: number}) => void,
    arrayLength: number,
    isLoading: boolean,
    pageInfo: TPageInfo,
    cleanData?: () => void,
    controller?: AbortController,

    isOnTop?: boolean,
    isNoMoreThanTotal?: boolean,
    customDependencies?: (TAny | TAny[])[],
    is0PageAddictive?: boolean,
    disableInitFetch?: boolean,
    disableClearOnUnmount?: boolean
}
export const useScrollPagination = ({getData, arrayLength, isLoading, pageInfo, cleanData, controller, isOnTop, isNoMoreThanTotal, customDependencies = [], is0PageAddictive, disableInitFetch = false, disableClearOnUnmount = false}: TProps) => {
    const {bottomLoader, isBottom, isTop, isFirst ,onScroll, scrollRef, goTop1} = useScroll();
    const [isInitial, setIsInitial] = useState<boolean>(true);

    useEffect(() => {
        const {page, count, total} = pageInfo;
        const isFinallyTop = isFirst || (isTop && isOnTop);
        const isFinallyBottom = isNoMoreThanTotal
            ? (isBottom && (arrayLength < total)) || (arrayLength === total && total === 0)
            : isBottom;

        // console.log({isFinallyTop, isFinallyBottom});
        if((isFinallyTop || isFinallyBottom) && !isLoading ) {
            // console.log(`disableInitFetch: ${disableInitFetch} | isInitial: ${isInitial}`)
            if(!(disableInitFetch && isInitial)){
                getData({
                    page: isFinallyTop || is0PageAddictive
                        ? 0
                        : arrayLength < (page + 1) * count
                            ? page
                            : page + 1,
                    count,
                });
                // setIsInitial(false);
                // console.log(`pagination fetched!`);
            }
        }
        setIsInitial(false);
        // isFinallyTop && goTop1();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(isFirst || (isTop && isOnTop)), isBottom, ...customDependencies]);
    // !!!  if need to disable auto get data - remove isLoading in previous row

    useEffect(() => {
        (isFirst || isTop) && goTop1();
        // isBottom && goBottom1();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);


    useEffect(() => {
        return () => {
            cleanData && !disableClearOnUnmount && cleanData();
            controller && controller.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return useMemo(() => ({
        onScroll, scrollRef,
        isTop, isBottom,
        bottomLoader: !isNoMoreThanTotal ? bottomLoader : arrayLength < pageInfo.total && bottomLoader,
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [
        scrollRef,
        isBottom,
        isTop,
        bottomLoader,
        pageInfo.total,
    ]);
}