import {FC, Fragment} from "react";
import {TComment} from "../../../../types";
import {Box, Typography} from "@mui/material";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import colors from "../../../../../../../newShared/theme/colors";
import {parseDateAuto} from "../../../../../../../newShared/utils/dateTools";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";

type TProps = {
    comment: TComment
}
export const CommentSendCard: FC<TProps> = ({comment}) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathFrameworks.pathComments'});

    const {currData: {currentUser}} = UseManageWorkspacesAndOrganizations();
    const isYou = currentUser?.publicId === comment.author.id;

    return (
        <Box display={"flex"} flexDirection={"column"} minWidth={'500px'} maxWidth={'800px'} marginTop={'16px'}>
            <Typography variant={"subtitle1"}>{`${t('Added at')} ${parseDateAuto(comment.date, true)} ${isYou ? t('You') : comment.author.name}`}</Typography>
            <Box bgcolor={isYou ? colors.backgrounds.orange : colors.backgrounds.green} p={'8px'}>
                {comment.text.split('\n').map(text => (
                    <Fragment key={text}>
                        <Typography variant={"body1"} fontSize={'12px'}>{text}</Typography>
                    </Fragment>
                ))}
                <Typography variant={"body1"} fontSize={'12px'}>{}</Typography>
            </Box>
        </Box>
    )
}