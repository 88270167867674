import {
    DocumentFile,
    DocumentFolder,
    DocumentsInitState,
    TCreateFormDoc,
    TEditorFillableBlockData,
    TNewDocVariant,
    TShortDocument
} from "../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {
    convertFullDocumentToSmallDocument,
    deleteFolders,
    findFolderById,
    generateColorsForTags,
    getChildIds,
    insertNewFolder,
    moveFolders,
    renameFolder,
    sortFolders,
    sortRootFolders
} from "../helpers";
import {
    AutocompleteManagersEmailName,
    AutocompleteRecipientsEmailName,
    changeDocumentStageEtaAction,
    CreateFolder,
    CreateNewDoc,
    DeleteFolder,
    DeleteItems,
    DeleteNewDocument,
    DownloadFile,
    GeneratePdfByHTML,
    GetFolderTree,
    GetFullDocumentWithExecutor,
    GetNewDocumentById,
    GetNewTemplateById,
    GetShortDocumentsByFolderIdWithFilterPaging,
    GetTemplatesVariants,
    handlePreviewBeforeSendAction,
    InvalidateDocument,
    MakeDocDraftCopy,
    MoveItems,
    MoveNewItems,
    RenameItem,
    RestartSignStageByStageIdAndGroupId,
    RestartSignStageByStageIdAndRecipient,
    RestoreNewItem,
    SendToNextStepByStageId,
    setDocumentAsEvidenceAssetAction,
    StartNewDocumentExecution,
    UpdateFullDocument,
    UploadFile,
    UploadShortFileArray
} from "./actions";
import {defaultFormCreateDoc, GALLERY_FOLDER_NAME, GALLERY_LOCAL_ID} from "../constants";
import {tagsColorMapper} from "../components/tagsChips";
import {setPaginationInStore} from "../../../../newShared/hooks/useScroll/helpers";
import {TFileContent} from "../../../../newShared/components/editorUnion/types";
import {uuid} from "../../../../newShared/utils";
import {getMsFromDays} from "../../../../newShared/utils/dateTools";

export { };

const initialState: DocumentsInitState = {
    documentsFolders: [
        {
            name: GALLERY_FOLDER_NAME,
            id: GALLERY_LOCAL_ID,
            lastEditDate: '',
            children: [],
        }
    ],
    templatesFolders: [
        {
            name: GALLERY_FOLDER_NAME,
            id: GALLERY_LOCAL_ID,
            lastEditDate: '',
            children: [],
        }
    ],
    sortedFolders: [{id: 'gallery', sort: 3}],
    files: [],
    templatesVariants: [],
    tagColors: [],
    pageInfo: {
        page: 0,
        count: 50,
        total: 0,
        sort: 'default'
    },

    openedFolders: [],
    foldersPath: [],
    templatesPath: [],
    selectedFolder: null,
    selectedNewDocument: null,
    selectedNewTemplate: null,

    selectedFolders: [],
    selectedFiles: [],
    employees: [],
    recipientVariants: {
        recipients: [],
        pageInfo: {
            page: 0,
            count: 0,
            total: 0,
            sort: ''
        }
    },

    isLoading: {
        isLoadingTree: false,
        isLoadingFiles: false,
        isLoadingExact: false,
        isLoadingMoveTo: false,
        isLoadingRename: false,
        isLoadingDelete: false,
        isLoadingAddFolder: false,
        isLoadingUploadFile: false,
        isLoadingDownload: false,
        isLoadingSaveDoc: false,
        isLoadingDataForDialog: false,
        isLoadingEmployees: false,
        isLoadingRestartSignStageByStageIdAndRecipient: false,
        isLoadingRestartSignStageByStageIdAndGroupId: false,
        isLoadingSendToNextStep: false,
        isLoadingInvalidate: false,
        isLoadingSave: false,
        isLoadingMakeDraftCopy: false,
        isLoadingChangeEtaOnStage: false,
        isSettingAsEvidence: [],
    },

    dialogs: {
        addFolderDialog: false,
        addDocumentDialog: {
            isOpen: false,
            form: defaultFormCreateDoc,
            newDocVariant: null,
            isTemplatePage: false,
            errorActiveFields: []
        },
        moveToDialog: {
            isOpen: false,
            folder: null,
            file: null,
        },
        restoreDialog: {
            isOpen: false,
            folder: null,
            file: null,
        },
        deleteItemsDialog: {
            isOpen: false,
            folder: null,
            file: null,
        },
        uploadFileDialog: false,
        changeNameDialog: {
            isOpen: false,
            folder: null,
        },
        docEditorDialog: {
            isOpenFolderSelectionDialog: false,
            isOpenVariablesDialog: false,
            isOpenEditorDialog: false,
            selectedFolder: null,
            selectedPath: [],
            templateId: null,
            editorDocument: null
        },
        fileNotSupportedDialog: {
            isOpen: false,
            file: null
        },
        //EDITOR
        galleryEditorDialog: {
            isOpen: false,
            doc: null
        },
        explorerEditorDialog: {
            isOpen: false,
            doc: null
        },
        createdNewDoc: {
            isOpen: false,
            docId: null
        },
        explorerCreateDocEditorDialog: false,
        recipientsView: {
            isOpen: false,
            recipients: [],
        },
        addGroupMessage: {
            isOpen: false,
            approverId: null,
            groupId: null,
            recipientId: null,
            oldMessage: null,
        },

        setAsEvidenceDialog: {
            documentId: null,
        }
    }
}

export const DocumentsSlice = createSlice({
    name: 'Documents',
    initialState,
    reducers: {
        expandMoreFolder: (state, {payload}: {payload: string}) => {
            if(!state.openedFolders.some(e => e === payload)){
                state.openedFolders.push(payload);
            }
        },
        expandLessFolder: (state, {payload}: {payload: DocumentFolder}) => {
            const childIds = getChildIds(payload);
            state.openedFolders = state.openedFolders.filter(e => !childIds.some(c => c === e));
        },
        openFolder: (state, {payload}: {payload: {folder: DocumentFolder, path: {name: string, id: string}[]}}) => {
            state.selectedFolder = payload.folder;
            state.foldersPath = payload.path;
            state.selectedFolders = [];
            state.selectedFiles = [];
            state.files = []
        },
        openTemplatesFolder: (state, {payload}: {payload: {folder: DocumentFolder, path: {name: string, id: string}[]}}) => {
            state.selectedFolder = payload.folder;
            state.templatesPath = payload.path;
            // state.selectedFolders = [];
            // state.selectedFiles = [];
            // state.files = []
        },
        setOpenFolderNull: (state) => {
            state.selectedFolder = null;
            state.foldersPath = [];
            state.selectedFolders = [];
            state.selectedFiles = [];
        },
        toggleSelectFolder: (state, {payload}: {payload: {parentId: string, folderId: string, name: string, containsChildren: boolean, cmdDown: boolean}}) => {
            if(payload.cmdDown){
                if(state.selectedFolders.some(e => e.folderId === payload.folderId)){
                    state.selectedFolders = state.selectedFolders.filter(e => e.folderId !== payload.folderId);
                }else{
                    state.selectedFolders.push(payload);
                }
            }else{
                if(state.selectedFolders.some(e => e.folderId === payload.folderId)) {
                    state.selectedFolders = state.selectedFolders.filter(e => e.folderId !== payload.folderId);
                }else{
                    state.selectedFolders = [
                        {
                            parentId: payload.parentId,
                            folderId: payload.folderId,
                            name: payload.name,
                            containsChildren: payload.containsChildren
                        }
                    ];
                    state.selectedFiles = [];
                }
            }
        },
        toggleSelectFile: (state, {payload}: {payload: {parentId: string | null, fileId: string, name: string, cmdDown: boolean}}) => {
            if(payload.cmdDown){
                if(state.selectedFiles.some(e => e.fileId === payload.fileId)){
                    state.selectedFiles = state.selectedFiles.filter(e => e.fileId !== payload.fileId);
                }else{
                    state.selectedFiles.push(payload);
                }
            }else{
                if(state.selectedFiles.some(e => e.fileId === payload.fileId)) {
                    state.selectedFiles = state.selectedFiles.filter(e => e.fileId !== payload.fileId);
                }else{
                    state.selectedFiles = [
                        {
                            parentId: payload.parentId,
                            fileId: payload.fileId,
                            name: payload.name
                        }
                    ];
                    state.selectedFolders = [];
                }
            }

        },

        openMoveToDialog: (state, {payload}: {payload: {folder?: DocumentFolder, file?: TShortDocument}}) => {
            if (payload.folder !== undefined) state.dialogs.moveToDialog = { isOpen: true, folder: payload.folder, file: null};
            if (payload.file !== undefined) state.dialogs.moveToDialog = { isOpen: true, file: payload.file, folder: null};
        },
        hideMoveToDialog: (state) => {state.dialogs.moveToDialog = initialState.dialogs.moveToDialog},

        openRestoreDialog: (state, {payload}: {payload: {folder?: DocumentFolder, file?: TShortDocument}}) => {
            if (payload.folder !== undefined) state.dialogs.restoreDialog = { isOpen: true, folder: payload.folder, file: null};
            if (payload.file !== undefined) state.dialogs.restoreDialog = { isOpen: true, file: payload.file, folder: null};
        },
        hideRestoreDialog: (state) => {state.dialogs.restoreDialog = initialState.dialogs.restoreDialog},
        openChangeNameDialog: (state, {payload}: {payload: {folder: DocumentFolder}}) => {
            if (payload.folder !== undefined )state.dialogs.changeNameDialog = {isOpen: true, folder: payload.folder}
        },
        hideChangeNameDialog: (state) => {state.dialogs.changeNameDialog = initialState.dialogs.changeNameDialog},

        openDeleteDialog: (state,  {payload}: {payload: {folder?: DocumentFolder, file?: TShortDocument}}) => {
            if (payload.folder !== undefined) state.dialogs.deleteItemsDialog = { isOpen: true, folder: payload.folder, file: null};
            if (payload.file !== undefined) state.dialogs.deleteItemsDialog = { isOpen: true, file: payload.file, folder: null};
        },
        hideDeleteDialog: (state) => {state.dialogs.deleteItemsDialog = initialState.dialogs.deleteItemsDialog},

        openAddFolderDialog: (state) => {state.dialogs.addFolderDialog = true},
        hideAddFolderDialog: (state) => {state.dialogs.addFolderDialog = false},

        setFormCreateDoc: (state, {payload}: {payload: { form?: TCreateFormDoc,  docVariant?: TNewDocVariant, errorActiveFields?: string[]}}) => {
            if(payload.form !== undefined) state.dialogs.addDocumentDialog.form = payload.form;
            if(payload.docVariant !== undefined) state.dialogs.addDocumentDialog.newDocVariant = payload.docVariant;
            if(payload.errorActiveFields !== undefined) state.dialogs.addDocumentDialog.errorActiveFields = payload.errorActiveFields;

            if (payload.docVariant === "BLANK" || payload.docVariant === 'UPLOADED') state.selectedNewTemplate = null;
        },

        openAddDocumentDialog: (state, {payload}:{payload: {isTemplate?: boolean}}) => {
            state.dialogs.addDocumentDialog.isOpen = true;
            state.dialogs.addDocumentDialog.form = {...defaultFormCreateDoc};
            if (payload.isTemplate !== undefined) state.dialogs.addDocumentDialog.isTemplatePage = payload.isTemplate;
        },
        hideAddDocumentDialog: (state) => {
            state.dialogs.addDocumentDialog = initialState.dialogs.addDocumentDialog;
            state.templatesVariants = [];
            state.employees = [];
        },

        openUploadFileDialog: (state) => {state.dialogs.uploadFileDialog = true},
        hideUploadFileDialog: (state) => {state.dialogs.uploadFileDialog = false},

        eraseFiles: (slice) => {
            slice.files = [];
            slice.pageInfo = initialState.pageInfo;
        },

        setTagColors: (slice, {payload}: {payload: tagsColorMapper[]}) => {
            slice.tagColors = payload;
        },

        //EDITOR
        openSelectFolderDialog: (state, {payload}: {payload: string}) => {state.dialogs.docEditorDialog = {...state.dialogs.docEditorDialog, templateId: payload, isOpenFolderSelectionDialog: true}},
        hideSelectFolderDialog: (state) => {state.dialogs.docEditorDialog = {...state.dialogs.docEditorDialog, templateId: null, isOpenFolderSelectionDialog: false}},
        setFolderForSave: (state, {payload}: {payload: {folder: DocumentFolder, path: {name: string, id: string}[]}}) => {state.dialogs.docEditorDialog = {...state.dialogs.docEditorDialog, selectedFolder: payload.folder, selectedPath: payload.path}},

        openVariablesSetDialog: (state) => {
            state.dialogs.docEditorDialog = {
                ...state.dialogs.docEditorDialog,
                isOpenFolderSelectionDialog: false,
                isOpenVariablesDialog: true,
                isOpenEditorDialog: false
            }
        },
        hideVariablesSetDialog: (state) => {state.dialogs.docEditorDialog = {...state.dialogs.docEditorDialog, isOpenVariablesDialog: false}},

        openEditorDialog: (state, {payload}: {payload: DocumentFile | null}) => {
            // console.log(payload)
            state.dialogs.docEditorDialog = {
                ...state.dialogs.docEditorDialog,
                isOpenEditorDialog: true,
                isOpenVariablesDialog: false,
                editorDocument: payload
            }
        },
        hideEditorDialog: (state) => {
            state.dialogs.docEditorDialog = {
                isOpenFolderSelectionDialog: false,
                isOpenVariablesDialog: false,
                isOpenEditorDialog: false,
                selectedFolder: null,
                selectedPath: [],
                templateId: null,
                editorDocument: null,
            }
        },

        insertFile: (state, {payload}: {payload: TShortDocument}) => {
            //this action is used for editor dialog
            //when creating copy of template - we select folder - then copied document opens in editor and selected folder in background
            //because of editor document selection logic - have to search for file in files in redux store
            //and if it is loading in same second - editor can not find this doc and opens blank
            //inserting doc for couple of seconds to open editor - then it will be rewrote by new fetched data
            state.files.push(payload);
        },

        openFileNotSupportedDialog: (state, {payload}: {payload: TShortDocument}) => {
            state.dialogs.fileNotSupportedDialog = {isOpen: true, file: payload};
        },
        hideFileNotSupportedDialog: (state) => {
            state.dialogs.fileNotSupportedDialog = {isOpen: false, file: null};
        },

        cleanUp: () => initialState,

        openGalleryEditorDialog: (state, {payload}: {payload: TShortDocument}) => {
            state.dialogs.galleryEditorDialog = {
                isOpen: true,
                doc: payload
            }
        },
        hideGalleryEditorDialog: (state) => {
            state.dialogs.galleryEditorDialog = {
                isOpen: false,
                doc: null
            }
        },

        openExplorerEditorDialog: (state, {payload}: {payload: TShortDocument}) => {
            state.dialogs.explorerEditorDialog = {
                isOpen: true,
                doc: payload
            }
        },
        hideExplorerEditorDialog: (state) => {
            state.dialogs.explorerEditorDialog = {
                isOpen: false,
                doc: null
            }
        },

        openExplorerCreateDocEditorDialog: (state) => {
            state.dialogs.explorerCreateDocEditorDialog = true;
        },
        hideExplorerCreateDocEditorDialog: (state) => {
            state.dialogs.explorerCreateDocEditorDialog = false;
        },

        openShowRecipients: (slice, {payload}: {payload: {recipients: TShortDocument["recipients"]}}) => {
            if(payload.recipients !== undefined) slice.dialogs.recipientsView = {isOpen: true, ...payload};
        },
        hideShowRecipients: (slice) => {
            slice.dialogs.recipientsView = initialState.dialogs.recipientsView;
        },
        openAddGroupMessage: (slice, {payload}: {payload: {approverId?: string, groupId?: string, oldMessage?: string, recipientId?: string}}) => {
            if(payload.approverId !== undefined && payload.groupId !== undefined) {
                slice.dialogs.addGroupMessage.isOpen = true;
                slice.dialogs.addGroupMessage.approverId = payload.approverId;
                slice.dialogs.addGroupMessage.groupId = payload.groupId;
            }
            if(payload.recipientId !== undefined) {
                slice.dialogs.addGroupMessage.isOpen = true;
                slice.dialogs.addGroupMessage.recipientId = payload.recipientId;
            }
            if(payload.oldMessage !== undefined) slice.dialogs.addGroupMessage.oldMessage = payload.oldMessage;
        },
        hideAddGroupMessage: (slice) => {
            slice.dialogs.addGroupMessage = initialState.dialogs.addGroupMessage;
        },

        deselectDocument: (slice) => {
            slice.selectedNewDocument = null;
        },
        handleReturnToDraftAction: (slice) => {
            if (slice.selectedNewDocument?.document?.data) {
                slice.selectedNewDocument.document.recipients = slice.selectedNewDocument.document.recipients!.map(e => ({...e, fields: e.fields?.filter(e => !e.pageId.startsWith('pdfPage'))}));

                slice.selectedNewDocument.document.data.editor = slice.selectedNewDocument.document.data.editor.filter((e: TFileContent[number]) => e.type !== 'pdfPage');

                slice.selectedNewDocument.document.data.pdfFileId = null;
            }
        },

        setAsEvidenceDialogAction: (slice, {payload}: {payload: Partial<DocumentsInitState["dialogs"]["setAsEvidenceDialog"] & {clear?: boolean}>}) => {
            if (payload.documentId !== undefined) slice.dialogs.setAsEvidenceDialog.documentId = payload.documentId;

            if (payload.clear) slice.dialogs.setAsEvidenceDialog = initialState.dialogs.setAsEvidenceDialog;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetFolderTree.pending, (slice) => {
                slice.isLoading.isLoadingTree = true;
            })
            .addCase(GetFolderTree.rejected, (slice) => {
                slice.isLoading.isLoadingTree = false;

            })
            .addCase(GetFolderTree.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingTree = false;
                let newFolders = [{name: GALLERY_FOLDER_NAME, id: GALLERY_LOCAL_ID, lastEditDate: '', children: [],}, ...payload]
                    .map(e => e.name === 'Trash' ? ({...e, id: 'trash'}) : e);
                const sortMap = sortRootFolders(newFolders);
                newFolders = newFolders.sort((a, b) => sortFolders(a.id, b.id, sortMap));
                slice.documentsFolders = newFolders;
                slice.templatesFolders = newFolders;

                slice.sortedFolders = sortMap;
            })
        //GetShortDocuments
            .addCase(GetShortDocumentsByFolderIdWithFilterPaging.pending, (slice) => {
                slice.isLoading.isLoadingFiles = true;
                slice.selectedFolders = [];
                slice.selectedFiles = [];
            })
            .addCase(GetShortDocumentsByFolderIdWithFilterPaging.rejected, (slice) => {
                slice.isLoading.isLoadingFiles = false;

            })
            .addCase(GetShortDocumentsByFolderIdWithFilterPaging.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingFiles = false;

                const {array, pageInfo} = setPaginationInStore<TShortDocument>(
                    slice.pageInfo,
                    slice.files,
                    payload.resp.pageInfo,
                    payload.resp.files,
                    payload.isNewOnTop
                );

                slice.files = array;
                slice.pageInfo = pageInfo;

                const tagIds: string[] = [];
                slice.files?.forEach((file) => {
                    file?.tags?.forEach(e => {
                        if (!tagIds.some(tag => tag === e)) tagIds.push(e)
                    })
                });
                slice.tagColors = generateColorsForTags(slice.tagColors, tagIds);
            })
            .addCase(GetTemplatesVariants.pending, (slice) => {
                slice.isLoading.isLoadingDataForDialog = true;
            })
            .addCase(GetTemplatesVariants.rejected, (slice) => {
                slice.isLoading.isLoadingDataForDialog = false;

            })
            .addCase(GetTemplatesVariants.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingDataForDialog = false;
                slice.templatesVariants = payload.files;


            })
            //GetNewDocById /Template
            .addCase(GetNewDocumentById.pending, (slice) => {
                slice.isLoading.isLoadingExact = true;
            })
            .addCase(GetNewDocumentById.rejected, (slice) => {
                slice.isLoading.isLoadingExact = false;

            })
            .addCase(GetNewDocumentById.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingExact = false;
                slice.selectedNewDocument = { document: payload, documentExecutor: null};
            })

            .addCase(GetNewTemplateById.pending, (slice: DocumentsInitState, {meta: {arg: {fileId}}}) => {
                slice.isLoading.isLoadingExact = true;

                const template =
                    slice.templatesVariants.find(e => e.id === fileId) ||
                    slice.files.find(e => e?.id === fileId);
                slice.dialogs.addDocumentDialog.form.name = template?.name || '';
            })
            .addCase(GetNewTemplateById.rejected, (slice) => {
                slice.isLoading.isLoadingExact = false;

            })
            .addCase(GetNewTemplateById.fulfilled, (slice: DocumentsInitState, {payload}) => {
                slice.isLoading.isLoadingExact = false;
                slice.selectedNewTemplate = payload;

                if (slice.dialogs.addDocumentDialog.isOpen) {
                    slice.dialogs.addDocumentDialog.form.name = payload.name;
                    slice.dialogs.addDocumentDialog.form.recipients = payload.recipients?.map(e => ({
                        id: uuid(),
                        role: e.role,
                        message: e.message || '',
                        actor: e.actor,
                        fields: e.fields?.map(f => ({...f, type: f.type as TEditorFillableBlockData['type']})) || [],
                        order: e.order,
                        eta: e.eta ?? getMsFromDays(7),
                    })) || [];
                }
            })
        //CreateFolder
            .addCase(CreateFolder.pending, (slice) => {
                slice.isLoading.isLoadingAddFolder = true;
            })
            .addCase(CreateFolder.rejected, (slice) => {
                slice.isLoading.isLoadingAddFolder = false;

            })
            .addCase(CreateFolder.fulfilled, (slice: DocumentsInitState, {payload}) => {
                slice.isLoading.isLoadingAddFolder = false;
                const newFolder = {
                    name: payload.name,
                    id: payload.id,
                    lastEditDate: new Date(Date.now()).toISOString(),
                    children: []
                };
                slice.documentsFolders = insertNewFolder(payload.fatherId, newFolder, slice.documentsFolders);
                slice.templatesFolders = insertNewFolder(payload.fatherId, newFolder, slice.templatesFolders);
                slice.dialogs.addFolderDialog = false;
                if(slice.selectedFolder){
                    slice.selectedFolder.children.push(newFolder);
                }
            })

            .addCase(MoveNewItems.pending, (slice) => {
                slice.isLoading.isLoadingMoveTo = true;
            })
            .addCase(MoveNewItems.rejected, (slice) => {
                slice.isLoading.isLoadingMoveTo = false;

            })
            .addCase(MoveNewItems.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingMoveTo = false;
                slice.dialogs.moveToDialog = initialState.dialogs.moveToDialog;
                slice.dialogs.restoreDialog = initialState.dialogs.restoreDialog;

                //filter moved files
                // slice.files = slice.files.filter(e => !payload.fileIds.some(f => f === e?.documentId));
                slice.files = slice.files.filter((e) => payload.fileIds[0] !== e?.documentId);

                //update tree
                const foldersToMove: DocumentFolder[] = [];

                payload.folderIds.forEach(e => {
                    const folder = findFolderById(e, slice.documentsFolders);
                    folder && foldersToMove.push(folder);
                });

                slice.documentsFolders = moveFolders(foldersToMove, slice.documentsFolders, payload.newFatherId);

                //change selected folder
                if(slice.selectedFolder){
                    slice.selectedFolder.children = slice.selectedFolder.children.filter(e => !payload.folderIds.some(f => f === e.id));
                    if(!slice.selectedFolder.children.length && !slice.files.length){
                        //if after filtering in this folder is no more folders and files -> closing this folder in tree
                        //selectedFolder is not null - checked in line 278
                        slice.openedFolders = slice.openedFolders.filter(e => e !== slice.selectedFolder!.id);
                    }
                }

                slice.selectedFiles = [];
                slice.selectedFolders = [];
            })
            //RestoreNewItem
            .addCase(RestoreNewItem.pending, (slice) => {
                slice.isLoading.isLoadingMoveTo = true;
            })
            .addCase(RestoreNewItem.rejected, (slice) => {
                slice.isLoading.isLoadingMoveTo = false;

            })
            .addCase(RestoreNewItem.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingMoveTo = false;
                slice.dialogs.restoreDialog = initialState.dialogs.restoreDialog;

                //filter restore file
                slice.files = slice.files.filter((e) => payload.fileId !== e?.documentId);
            })

        //DeleteItems
            .addCase(DeleteItems.pending, (slice) => {
                slice.isLoading.isLoadingDelete = true;
            })
            .addCase(DeleteItems.rejected, (slice) => {
                slice.isLoading.isLoadingDelete = false;

            })
            .addCase(DeleteItems.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingDelete = false;
                slice.dialogs.deleteItemsDialog = initialState.dialogs.deleteItemsDialog;
                slice.files = slice.files.filter(e => !payload.fileIds.some(f => f === e?.id));
                slice.documentsFolders = deleteFolders(payload.folderIds, slice.documentsFolders);
                if(slice.selectedFolder){
                    slice.selectedFolder.children = slice.selectedFolder.children.filter(e => !payload.folderIds.some(f => f === e.id));
                }
            })
            
            //DeleteNewDocument
            .addCase(DeleteNewDocument.pending, (slice) => {
                slice.isLoading.isLoadingDelete = true;
            })
            .addCase(DeleteNewDocument.rejected, (slice) => {
                slice.isLoading.isLoadingDelete = false;
            })
            .addCase(DeleteNewDocument.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingDelete = false;
                slice.dialogs.deleteItemsDialog = initialState.dialogs.deleteItemsDialog;
                slice.files = slice.files.filter(e => e?.documentId !== payload.docId);
            })

            // DeleteFolder
            .addCase(DeleteFolder.pending, (slice) => {
                slice.isLoading.isLoadingDelete = true;
            })
            .addCase(DeleteFolder.rejected, (slice) => {
                slice.isLoading.isLoadingDelete = false;
            })
            .addCase(DeleteFolder.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingDelete = false;
                slice.dialogs.deleteItemsDialog = initialState.dialogs.deleteItemsDialog;
                slice.documentsFolders = deleteFolders([payload.folderId], slice.documentsFolders);
                if(slice.selectedFolder){
                    slice.selectedFolder.children = slice.selectedFolder.children.filter(e => e?.id !== payload.folderId);
                }
            })
        //MoveItems
            .addCase(MoveItems.pending, (slice) => {
                slice.isLoading.isLoadingMoveTo = true;
            })
            .addCase(MoveItems.rejected, (slice) => {
                slice.isLoading.isLoadingMoveTo = false;

            })
            .addCase(MoveItems.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingMoveTo = false;
                slice.dialogs.moveToDialog = initialState.dialogs.moveToDialog;
                //filter moved files
                slice.files = slice.files.filter(e => !payload.fileIds.some(f => f === e?.id));

                //update tree
                const foldersToMove: DocumentFolder[] = [];

                payload.folderIds.forEach(e => {
                    const folder = findFolderById(e, slice.documentsFolders);
                    folder && foldersToMove.push(folder);
                });

                slice.documentsFolders = moveFolders(foldersToMove, slice.documentsFolders, payload.newFatherId);

                //change selected folder
                if(slice.selectedFolder){
                    slice.selectedFolder.children = slice.selectedFolder.children.filter(e => !payload.folderIds.some(f => f === e.id));
                    if(!slice.selectedFolder.children.length && !slice.files.length){
                        //if after filtering in this folder is no more folders and files -> closing this folder in tree
                        //selectedFolder is not null - checked in line 278
                        slice.openedFolders = slice.openedFolders.filter(e => e !== slice.selectedFolder!.id);
                    }
                }

                slice.selectedFiles = [];
                slice.selectedFolders = [];
            })

            //UploadFile
            .addCase(UploadFile.pending, (slice) => {
                slice.isLoading.isLoadingUploadFile = true;
            })
            .addCase(UploadFile.rejected, (slice) => {
                slice.isLoading.isLoadingUploadFile = false;
            })
            .addCase(UploadFile.fulfilled, (slice, {payload}) => {
                //pass (UploadFile.then execute UploadFileArray)
            })
        //UploadFileArray
            .addCase(UploadShortFileArray.pending, (slice) => {
                slice.isLoading.isLoadingUploadFile = true;
            })
            .addCase(UploadShortFileArray.rejected, (slice) => {
                slice.isLoading.isLoadingUploadFile = false;

            })
            .addCase(UploadShortFileArray.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingUploadFile = false;
                slice.files = [...slice.files, ...payload];
                slice.dialogs.uploadFileDialog = false;
            })
        //RenameItem
            .addCase(RenameItem.pending, (slice) => {
                slice.isLoading.isLoadingRename = true;
            })
            .addCase(RenameItem.rejected, (slice) => {
                slice.isLoading.isLoadingRename = false;

            })
            .addCase(RenameItem.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingRename = false;
                if(payload.itemType === 'file'){
                    slice.files = slice.files.map(e => e?.id === payload.itemId ? {...e, name: payload.itemName} : e);
                }
                if(payload.itemType === 'folder'){
                    slice.documentsFolders = renameFolder(payload.itemId, payload.itemName, slice.documentsFolders);
                    if(slice.selectedFolder){
                        slice.selectedFolder.children = slice.selectedFolder.children.map(e => e.id === payload.itemId ? {...e, name: payload.itemName} : e);
                    }
                }

                slice.dialogs.changeNameDialog = initialState.dialogs.changeNameDialog;
            })
        //DownloadFile
            .addCase(DownloadFile.pending, (slice) => {
                slice.isLoading.isLoadingDownload = true;
            })
            .addCase(DownloadFile.rejected, (slice) => {
                slice.isLoading.isLoadingDownload = false;

            })
            .addCase(DownloadFile.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingDownload = false;
            })
            //GeneratePdfByHTML
            .addCase(GeneratePdfByHTML.pending, (slice) => {
                slice.isLoading.isLoadingDownload = true;
            })
            .addCase(GeneratePdfByHTML.rejected, (slice) => {
                slice.isLoading.isLoadingDownload = false;

            })
            .addCase(GeneratePdfByHTML.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingDownload = false;
            })

        // CreateDoc
            .addCase(CreateNewDoc.pending, (slice) => {
                slice.isLoading.isLoadingSaveDoc = true;
            })
            .addCase(CreateNewDoc.rejected, (slice) => {
                slice.isLoading.isLoadingSaveDoc = false;

            })
            .addCase(CreateNewDoc.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingSaveDoc = false;
                slice.dialogs.galleryEditorDialog = {isOpen: false, doc: null};
                slice.dialogs.createdNewDoc = {isOpen: false, docId: payload.id};
                slice.dialogs.addDocumentDialog = initialState.dialogs.addDocumentDialog;
            })
        // AutocompleteManagersEmailName
            .addCase(AutocompleteManagersEmailName.pending, (slice) => {
                slice.isLoading.isLoadingEmployees = true;
            })
            .addCase(AutocompleteManagersEmailName.rejected, (slice) => {
                slice.isLoading.isLoadingEmployees = false;

            })
            .addCase(AutocompleteManagersEmailName.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingEmployees = false;
                slice.employees = payload.resp;
            })
            //AutocompleteRecipientsEmailName
            .addCase(AutocompleteRecipientsEmailName.pending, (slice) => {
                slice.isLoading.isLoadingEmployees = true;
            })
            .addCase(AutocompleteRecipientsEmailName.rejected, (slice) => {
                slice.isLoading.isLoadingEmployees = false;

            })
            .addCase(AutocompleteRecipientsEmailName.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingEmployees = false;
                if( payload.resp !== undefined ) slice.recipientVariants =  {...payload.resp}
            })
            //GetFullDocumentWithExecutorForStaff
            .addCase(GetFullDocumentWithExecutor.pending, (slice) => {
                slice.isLoading.isLoadingExact = true;
            })
            .addCase(GetFullDocumentWithExecutor.rejected, (slice) => {
                slice.isLoading.isLoadingExact = false;
            })
            .addCase(GetFullDocumentWithExecutor.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingExact = false;
                if(payload.resp.file !== undefined && payload.resp.file !== null){
                    slice.selectedNewDocument = {
                        document: payload.resp.file,
                        documentExecutor: payload.resp.executor ?? null
                    }
                    if(!slice.files.filter(e => e !== null).some(e => e!.documentId === payload.resp.file!.documentId)){
                        if(slice.selectedFolder && slice.selectedFolder.id === payload.resp.file?.folderId){
                            //if there is not such file in folder - and we in folder where file was created - inserting
                            slice.files = [convertFullDocumentToSmallDocument(payload.resp.file!), ...slice.files]
                        }
                    }
                }
            })
            //handlePreviewBeforeSendAction
            .addCase(handlePreviewBeforeSendAction.pending, (slice) => {
                slice.isLoading.isLoadingExact = true;
            })
            .addCase(handlePreviewBeforeSendAction.rejected, (slice) => {
                slice.isLoading.isLoadingExact = false;
            })
            .addCase(handlePreviewBeforeSendAction.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingExact = false;
                if(payload.file){
                    slice.selectedNewDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                    slice.files = slice.files.filter(e => e !== null).map(e => e!.documentId === payload.file!.documentId ? convertFullDocumentToSmallDocument(payload.file!) : e)
                }
            })
            //StartNewDocumentExecution
            .addCase(StartNewDocumentExecution.pending, (slice) => {
                slice.isLoading.isLoadingExact = true;
            })
            .addCase(StartNewDocumentExecution.rejected, (slice) => {
                slice.isLoading.isLoadingExact = false;
            })
            .addCase(StartNewDocumentExecution.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingExact = false;
                if(payload.file){
                    slice.selectedNewDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                    slice.files = slice.files.filter(e => e !== null).map(e => e!.documentId === payload.file!.documentId ? convertFullDocumentToSmallDocument(payload.file!) : e)
                }
                // if(payload.resp.file !== undefined && payload.resp.executor !== undefined){
                //     slice.selectedNewDocument = {
                //         document: payload.resp.file,
                //         documentExecutor: payload.resp.executor
                //     }
                // }
            })
            //changeDocumentStageEtaAction
            .addCase(changeDocumentStageEtaAction.pending, (slice) => {
                slice.isLoading.isLoadingChangeEtaOnStage = true;
            })
            .addCase(changeDocumentStageEtaAction.rejected, (slice) => {
                slice.isLoading.isLoadingChangeEtaOnStage = false;
            })
            .addCase(changeDocumentStageEtaAction.fulfilled, (slice: DocumentsInitState, {payload}) => {
                slice.isLoading.isLoadingChangeEtaOnStage = false;
                if(payload.file){
                    slice.selectedNewDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                    slice.files = slice.files.filter(e => e !== null).map(e => e!.documentId === payload.file!.documentId ? convertFullDocumentToSmallDocument(payload.file!) : e)
                }
            })
            //RestartSignStageByStageIdAndRecipient
            .addCase(RestartSignStageByStageIdAndRecipient.pending, (slice) => {
                slice.isLoading.isLoadingRestartSignStageByStageIdAndRecipient = true;
            })
            .addCase(RestartSignStageByStageIdAndRecipient.rejected, (slice) => {
                slice.isLoading.isLoadingRestartSignStageByStageIdAndRecipient = false;
            })
            .addCase(RestartSignStageByStageIdAndRecipient.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingRestartSignStageByStageIdAndRecipient = false;
                if(payload.file){
                    slice.selectedNewDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                    slice.files = slice.files.filter(e => e !== null).map(e => e!.documentId === payload.file!.documentId ? convertFullDocumentToSmallDocument(payload.file!) : e)
                }
            })
            //RestartSignStageByStageIdAndGroupId
            .addCase(RestartSignStageByStageIdAndGroupId.pending, (slice) => {
                slice.isLoading.isLoadingRestartSignStageByStageIdAndGroupId = true;
            })
            .addCase(RestartSignStageByStageIdAndGroupId.rejected, (slice) => {
                slice.isLoading.isLoadingRestartSignStageByStageIdAndGroupId = false;
            })
            .addCase(RestartSignStageByStageIdAndGroupId.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingRestartSignStageByStageIdAndGroupId = false;
                if(payload.file){
                    slice.selectedNewDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                    slice.files = slice.files.filter(e => e !== null).map(e => e!.documentId === payload.file!.documentId ? convertFullDocumentToSmallDocument(payload.file!) : e)
                }
            })
            //SendToNextStepByStageId
            .addCase(SendToNextStepByStageId.pending, (slice) => {
                slice.isLoading.isLoadingSendToNextStep = true;
            })
            .addCase(SendToNextStepByStageId.rejected, (slice) => {
                slice.isLoading.isLoadingSendToNextStep = false;
            })
            .addCase(SendToNextStepByStageId.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingSendToNextStep = false;
                if(payload.file){
                    slice.selectedNewDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                    slice.files = slice.files.filter(e => e !== null).map(e => e!.documentId === payload.file!.documentId ? convertFullDocumentToSmallDocument(payload.file!) : e)
                }
            })
            //InvalidateDocument
            .addCase(InvalidateDocument.pending, (slice) => {
                slice.isLoading.isLoadingInvalidate = true;
            })
            .addCase(InvalidateDocument.rejected, (slice) => {
                slice.isLoading.isLoadingInvalidate = false;
            })
            .addCase(InvalidateDocument.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingInvalidate = false;
                if(payload.file){
                    slice.selectedNewDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                    slice.files = slice.files.filter(e => e !== null).map(e => e!.documentId === payload.file!.documentId ? convertFullDocumentToSmallDocument(payload.file!) : e)
                }
            })
            //UpdateFullDocument
            .addCase(UpdateFullDocument.pending, (slice) => {
                slice.isLoading.isLoadingSave = true;
            })
            .addCase(UpdateFullDocument.rejected, (slice) => {
                slice.isLoading.isLoadingSave = false;
            })
            .addCase(UpdateFullDocument.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingSave = false;
                if(payload.file){
                    slice.selectedNewDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                    slice.files = slice.files.filter(e => e !== null).map(e => e!.documentId === payload.file!.documentId ? convertFullDocumentToSmallDocument(payload.file!) : e)
                }
            })
        //MakeDocDraftCopy
            .addCase(MakeDocDraftCopy.pending, (slice) => {
                slice.isLoading.isLoadingMakeDraftCopy = true;
            })
            .addCase(MakeDocDraftCopy.rejected, (slice) => {
                slice.isLoading.isLoadingMakeDraftCopy = false;
            })
            .addCase(MakeDocDraftCopy.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingMakeDraftCopy = false;
                if(payload.file) {
                    slice.selectedNewDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                    slice.files.unshift(convertFullDocumentToSmallDocument(payload.file!));
                }
            })
        //setDocumentAsEvidenceAssetAction
            .addCase(setDocumentAsEvidenceAssetAction.pending, (slice, {meta: {arg: {data: {documentId}}}}) => {
                slice.isLoading.isSettingAsEvidence.push(documentId);
            })
            .addCase(setDocumentAsEvidenceAssetAction.rejected, (slice, {meta: {arg: {data: {documentId}}}}) => {
                slice.isLoading.isSettingAsEvidence = slice.isLoading.isSettingAsEvidence.filter(e => e !== documentId);
            })
            .addCase(setDocumentAsEvidenceAssetAction.fulfilled, (slice, {meta: {arg: {data: {documentId}}}}) => {
                slice.isLoading.isSettingAsEvidence = slice.isLoading.isSettingAsEvidence.filter(e => e !== documentId);
                slice.dialogs.setAsEvidenceDialog = initialState.dialogs.setAsEvidenceDialog;
            })
        //


    }
});

export const DocumentsRefactorReducer = DocumentsSlice.reducer;

export const {
    expandMoreFolder,
    expandLessFolder,
    openFolder,
    toggleSelectFolder,
    toggleSelectFile,
    openMoveToDialog,
    hideMoveToDialog,
    openRestoreDialog,
    hideRestoreDialog,
    openChangeNameDialog,
    hideChangeNameDialog,
    openDeleteDialog,
    hideDeleteDialog,
    openAddFolderDialog,
    hideAddFolderDialog,

    openAddDocumentDialog,
    hideAddDocumentDialog,
    setFormCreateDoc,

    openUploadFileDialog,
    hideUploadFileDialog,
    setOpenFolderNull,

    openSelectFolderDialog,
    hideSelectFolderDialog,
    setFolderForSave,

    openVariablesSetDialog,
    hideVariablesSetDialog,

    openEditorDialog,
    hideEditorDialog,
    insertFile,

    openFileNotSupportedDialog,
    hideFileNotSupportedDialog,

    cleanUp,
    eraseFiles,
    openTemplatesFolder,

    //EDITOR
    openGalleryEditorDialog,
    hideGalleryEditorDialog,

    openExplorerEditorDialog,
    hideExplorerEditorDialog,

    openExplorerCreateDocEditorDialog,
    hideExplorerCreateDocEditorDialog,
    setTagColors,

    openShowRecipients,
    hideShowRecipients,

    openAddGroupMessage,
    hideAddGroupMessage,
    deselectDocument,
    handleReturnToDraftAction,

    setAsEvidenceDialogAction,
} = DocumentsSlice.actions;

const selectSelf = (state: AppState): DocumentsInitState => state.documents;
export const folders = createSelector(selectSelf, state => state.documentsFolders);
export const templatesFoldersSelector = createSelector(selectSelf, state => state.templatesFolders);
export const files = createSelector(selectSelf, state => state.files);
export const templatesVariantsSelector = createSelector(selectSelf, state => state.templatesVariants);
export const selectedNewDocument = createSelector(selectSelf, state => state.selectedNewDocument);
export const selectedNewTemplate = createSelector(selectSelf, state => state.selectedNewTemplate);
export const pageInfoSelector = createSelector(selectSelf, state => state.pageInfo);
export const isLoading = createSelector(selectSelf, state => state.isLoading);
export const openedFolders = createSelector(selectSelf, state => state.openedFolders);
export const selectedFolder = createSelector(selectSelf, state => state.selectedFolder);
export const recipientVariants = createSelector(selectSelf, state => state.recipientVariants);
export const foldersPath = createSelector(selectSelf, state => state.foldersPath);
export const templatesPathSelector = createSelector(selectSelf, state => state.templatesPath);
export const selectedFolders = createSelector(selectSelf, state => state.selectedFolders);
export const selectedFiles = createSelector(selectSelf, state => state.selectedFiles);
export const dialogs = createSelector(selectSelf, state => state.dialogs);
export const sortedFolders = createSelector(selectSelf, state => state.sortedFolders);
export const tagColors = createSelector(selectSelf, state => state.tagColors);
export const employees = createSelector(selectSelf, state => state.employees);
