import {useForm} from "../../../../../../newShared/hooks/useForm";
import {useState} from "react";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {SETTING_ROOT_PATH, SETTINGS_PROFILE_PATH} from "../../../constants";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDispatch, useSelector} from "react-redux";
import {ChangeCurrentUserInfo} from "../../../store/actions";
import {profileLoadings} from "../../../store/slice";
import {UseManageWorkspacesAndOrganizations} from "../../../../cookies/hooks/useManageWorkspacesAndOrganizations";

export const useProfile = () => {
    const dispatch = useDispatch();
    const {user, setUser} = UseManageWorkspacesAndOrganizations();
    const {isLoadingUpdateProfile} = useSelector(profileLoadings);

    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    useSetBreadcrumbs([
        {
            title: tMenu('Settings'),
            path: SETTING_ROOT_PATH
        },
        {
            title: tMenu('Profile'),
            path: SETTINGS_PROFILE_PATH
        }
    ]);

    const {form, setForm, handleChange} = useForm<{firstName: string, lastName: string}>({firstName: '', lastName: ''});
    const [isChangeMode, setIsChangeMode] = useState<boolean>(false);

    const handleEdit = () => {
        if(user){
            setIsChangeMode(true);
            setForm({firstName: user.firstName, lastName: user.lastName});
        }
    }

    const isOk = () => {
        return user && form.firstName.trim().length > 0 && form.lastName.trim().length > 0 &&
            (form.firstName.trim().toLowerCase() !== user.firstName.trim().toLowerCase() ||
            form.lastName.trim().toLowerCase() !== user.lastName.trim().toLowerCase())
    }

    const onSuccess = () => {
        if(user){
            setUser({...user, firstName: form.firstName, lastName: form.lastName});
            setIsChangeMode(false);
        }
    }

    const handleSave = () => {
        if(isOk() && user){
            dispatch(ChangeCurrentUserInfo({
                data: {
                    firstName: form.firstName,
                    lastName: form.lastName,
                    publicId: user.publicId
                },
                onSuccess
            }));
        }
    }

    const handleCancelEdit = () => {
        user ? setForm({firstName: user.firstName, lastName: user.lastName}) : setForm({firstName: '', lastName: ''});
        setIsChangeMode(false);
    }

    return{
        origin: {
            firstName: user?.firstName ?? '',
            lastName: user?.lastName ?? '',
        },
        form,
        handleChange,
        isLoading: isLoadingUpdateProfile,
        isChangeMode,
        handleEdit,
        isOk: isOk(),
        handleSave,
        handleCancelEdit
    }
}