import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {usePreviewRoleDialog} from "../../../hooks/dialogHooks/usePreviewRoleDialog";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const PreviewRoleDialog = () => {
    const {
        roleName,
        rolePermissions,
        isOpen,
        handleClose
    } = usePreviewRoleDialog();
    const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.AccessManagementAppsExact.dialogs'});

    return(
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{t(`Role {{roleName}} permissions`, {roleName})}</DialogTitle>

            <DialogContent>
                {rolePermissions.slice().sort().map(e => <Typography key={e} fontSize={'12px'} fontWeight={400}>{e}</Typography>)}
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{t('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}