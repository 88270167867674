import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
    clientsSelector,
    deselectTarget,
    eraseExactClientExceptionsList,
    eraseIsDataOutdated,
    eraseScreenings,
    isDataOutdated,
    kycDictionaries,
    loadings,
    openAddToBlackListDialog,
    openArchiveClientDialog,
    openDeleteTargetException,
    openEditClientDialog,
    openRemoveFromBlackListDialog,
    replaceScreeningsMinMaxLoadedPage,
    replaceSelectedClintExceptionsMinMaxPage,
    screeningsSelector,
} from "../../../store/slice";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    EXCEPTIONS_DEFAULT_PAGING,
    KYC_CLIENTS_ID_FIELDS_PATH,
    KYC_CLIENTS_ID_HISTORY_PATH,
    KYC_CLIENTS_ID_PATH,
    KYC_CLIENTS_ID_RESOLUTIONS_PATH,
    KYC_CLIENTS_ID_SCREENINGS_PATH,
    KYC_CLIENTS_LIST_PATH,
    KYC_ROOT_PATH,
    KYC_SCREENING_EXACT_PATH,
    KYC_TEMPLATES_EXACT_PATH,
    SCREENINGS_DEFAULT_PAGING,
    ScreeningStatusVariants,
    TARGET_BLACKLIST_STATUS,
    TARGET_HIGH_RISK,
    TARGET_LOW_RISK,
    TARGET_MEDIUM_RISK,
    TARGET_NONE_RISK
} from "../../../constants";
import {getTargetName, getTargetTypeForPath} from "../../../helpers";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {useLocation, useParams} from "react-router";
import {exactTargetTabType, TScreeningStatus} from "../../../types";
import {useEffect, useState} from "react";
import {useMenu} from "../../../../../../newShared/hooks/useMenu";
import {
    DownloadFile,
    GetKycExceptionsByTargetId,
    GetKycScreeningsWithFilterAndPaging,
    GetKycTargetById,
    GetTargetHistory,
    StartTargetScreening,
    UpdateKycTargetRisk
} from "../../../store/actions";
import {keyConfig} from "../../../../../../newShared/components/genericFilter/types";
import {
    useGenericFiltersStorage
} from "../../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {DEFAULT_GENERIC_TABLE_SEARCH} from "../../../../../../newShared/components/genericTable/constants";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../../newShared/components/genericTable/types";
import {KycExceptionModel, KycScreeningModel} from "../../../../../../newShared/GQLTypes";

export const useExactTarget = () => {
    const {tMenu} = useMainTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const menu = useMenu();
    const {currentFiltersForFetch, currentSearchForFetch, clearFilters} = useGenericFiltersStorage();

    const {id} = useParams<{id: string}>();
    const _isDataOutdated = useSelector(isDataOutdated);
    const {selected, selectedClintExceptions, selectedClientHistory} = useSelector(clientsSelector);
    const {getExactTarget, startTargetScreening, moveToArchive, moveFromArchive, moveToBlacklist, moveFromBlacklist, updateTargetRisk, isLoadingList, getExactTargetExceptions} = useSelector(loadings);
    const targetName = selected ? getTargetName(selected) : undefined;
    const targetTypePath = getTargetTypeForPath(selected);
    const {pathname} = useLocation();
    const dicts = useSelector(kycDictionaries);

    //history
    const isLoadingHistory = useSelector(loadings).getExactTargetHistory;

    const handleTabUpdate = () => {
        if(history.location.pathname === KYC_CLIENTS_ID_PATH.replace(':id', id).replace(':type', targetTypePath.pathName)){
            //tab not selected
            history.push(KYC_CLIENTS_ID_FIELDS_PATH.replace(':id', id).replace(':type', targetTypePath.pathName));
            handleSetTab('fields');
        }else{
            //tab selected
            switch (history.location.pathname){
                case KYC_CLIENTS_ID_SCREENINGS_PATH.replace(':id', id).replace(':type', targetTypePath.pathName): {
                    handleSetTab('screening');
                    return ;
                }
                case KYC_CLIENTS_ID_RESOLUTIONS_PATH.replace(':id', id).replace(':type', targetTypePath.pathName): {
                    handleSetTab('resolutions');
                    return ;
                }
                case KYC_CLIENTS_ID_HISTORY_PATH.replace(':id', id).replace(':type', targetTypePath.pathName): {
                    handleSetTab('history');
                    return ;
                }
                case KYC_CLIENTS_ID_FIELDS_PATH.replace(':id', id).replace(':type', targetTypePath.pathName): {
                    handleSetTab('fields');
                    return ;
                }
            }
        }
    }

    useEffect(() => {
        // console.log(`history.location.pathname`, history.location.pathname, KYC_CLIENTS_ID_PATH.replace(':id', id).replace(':type', targetTypePath.pathName));
        if(!selected) {
            dispatch(GetKycTargetById({data: {targetId: id}}));
        }else{
            if(!pathname.includes(`/${targetTypePath.pathName}/`)){
                history.push(KYC_CLIENTS_ID_FIELDS_PATH.replace(':id', id).replace(':type', targetTypePath.pathName));
            }
        }

        handleTabUpdate();
        return () => {
            clearFilters();
            dispatch(deselectTarget());
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        handleTabUpdate();
        //eslint-disable-next-line
    }, [history.location.pathname]);

    // useEffect(() => {
    //     if(!getExactTarget && selected){
    //         if(!pathname.includes(`/${targetTypePath.pathName}/`)){
    //             history.push(KYC_CLIENTS_ID_PATH.replace(':id', id).replace(':type', targetTypePath.pathName));
    //         }
    //     }
    //     //eslint-disable-next-line
    // }, [getExactTarget]);

    // useEffect(() => {
    //     if(!startTargetScreening && tab === 'screening'){
    //         //screening started - reloading list of screenings
    //         selected && dispatch(GetKycScreeningsWithFilterAndPaging({data: {pageInfo: {page: 0, count: SCREENINGS_DEFAULT_PAGING, filters: {targetId: [selected.id], ...currentFiltersForFetch, ...(currentSearchForFetch.trim().length > 0 ? {nameLike: currentSearchForFetch} : {})}}}}));
    //
    //     }
    //     //eslint-disable-next-line
    // }, [startTargetScreening]);

    //useEffects
    useSetBreadcrumbs([
        {
            title: tMenu('KYC Management'),
            path: KYC_ROOT_PATH
        },
        {
            title: tMenu(targetTypePath.breadcrumbName),
            path: KYC_CLIENTS_LIST_PATH.replace(':type', targetTypePath.pathName)
        },
        {
            title: selected ? getTargetName(selected) : tMenu('Loading...'),
            path: KYC_CLIENTS_ID_PATH.replace(':id', id).replace(':type', targetTypePath.pathName)
        }
    ]);

    const handleGoBack = () => {
        clearFilters();
        dispatch(deselectTarget());
        dispatch(eraseScreenings());
        history.push(KYC_CLIENTS_LIST_PATH.replace(':type', targetTypePath.pathName));
    }

    const handleStartScreening = () => {
        selected && dispatch(StartTargetScreening({data: {targetId: selected.id}}))
        menu.handleClose();
    }

    const handleEditInfo = () => {
        selected && dispatch((openEditClientDialog({selectedClient: selected})));
        menu.handleClose();
    }

    const handleAddToBlackList = () => {
        selected && dispatch(openAddToBlackListDialog({clientId: selected.id, clientName: targetName ?? selected.id}))
        menu.handleClose();
    }

    const handleRestoreFromBlackList = () => {
        selected && dispatch(openRemoveFromBlackListDialog({selectedClient: selected}));
        menu.handleClose();
    }

    const handleArchiveClient = () => {
        selected && dispatch(openArchiveClientDialog({selectedClient: selected}));
        menu.handleClose();
    }

    const handleUnArchive = () => {
        // selected && dispatch(openRestoreClientFromArchiveDialog({selectedClient: selected}));
        menu.handleClose();
    }

    const handleDownloadFile = (fileId: string, name: string) => {
        dispatch(DownloadFile({fileId, name}));
    }

    const updateRisk = (risk: string) => {
        selected && dispatch(UpdateKycTargetRisk({workspaceId: '', targetId: selected?.id, risk}))
    }

    //OtherActionsButton
    // const [otherActionsButtonPopperm,]
    //TABS
    const [tab, setTab] = useState<exactTargetTabType>('fields');

    const handleSetTab = (_tab: exactTargetTabType) => {
        if(_tab === 'screening'){
            history.replace({pathname: KYC_CLIENTS_ID_SCREENINGS_PATH.replace(':id', id).replace(':type', targetTypePath.pathName), search: DEFAULT_GENERIC_TABLE_SEARCH});
        }
        if(_tab === 'resolutions'){
            history.replace({pathname: KYC_CLIENTS_ID_RESOLUTIONS_PATH.replace(':id', id).replace(':type', targetTypePath.pathName), search: DEFAULT_GENERIC_TABLE_SEARCH});
        }
        if(_tab === 'history'){
            history.replace({pathname: KYC_CLIENTS_ID_HISTORY_PATH.replace(':id', id).replace(':type', targetTypePath.pathName)});
            dispatch(GetTargetHistory({data: {targetId: id}}));
        }
        if(_tab === 'fields'){
            history.replace(KYC_CLIENTS_ID_FIELDS_PATH.replace(':id', id).replace(':type', targetTypePath.pathName));
        }

        dispatch(eraseIsDataOutdated());
        setTab(_tab);
    }

    const startDate: keyConfig<string> = {
        type: 'date',
        key: 'startDate',
        name: ('Start date'),
        default: true,
    }

    //config filters
    const statusConfig: keyConfig<{status: TScreeningStatus, title: string}> = {
        type: 'filter',
        key: 'status',
        name: ('Status'),
        options: ScreeningStatusVariants,
        isOptionEqualToSearch: (option, search) => option.status.trim().toLowerCase().includes(search.trim().toLowerCase()),
        getFilterValue: (a) => a.status,
        getOptionLabel: (e) => e.title,
        default: true
    }

    const handleReloadData = () => {
        const filters = currentFiltersForFetch;
        const search = currentSearchForFetch;
        if(tab === 'resolutions' && selected){
            dispatch(eraseExactClientExceptionsList());
            dispatch(eraseIsDataOutdated());
            dispatch(GetKycExceptionsByTargetId({
                data: {
                    pageInfo: {page: 0, count: EXCEPTIONS_DEFAULT_PAGING, filters: {targetId: [selected.id]}},
                    targetId: selected.id
                }
            }));
        }
        if(tab === 'screening' && selected){
            dispatch(eraseScreenings());
            dispatch(eraseIsDataOutdated());
            dispatch(GetKycScreeningsWithFilterAndPaging({data: {pageInfo: {page: 0, count: SCREENINGS_DEFAULT_PAGING, filters: {targetId: [selected.id], ...filters, ...(search.trim().length > 0 ? {nameLike: search} : {})}}}}));
        }
    }

    const handleGoToExactTemplate = () => {
        selected && history.push(KYC_TEMPLATES_EXACT_PATH.replace(':id', selected.templateId));
    }

    //screeenigns
    const screenings = useSelector(screeningsSelector);
    const clients = useSelector(clientsSelector);

    return{
        target: selected,
        targetName,
        isNoHistory: !isLoadingHistory && !selectedClientHistory.length,

        isBlacklisted: selected ? selected.status === TARGET_BLACKLIST_STATUS : false,
        isArchived: selected ? selected.archived : false,
        isLoading: getExactTarget,
        otherLoadings: {
            moveToArchive,
            moveFromArchive,
            moveToBlacklist,
            moveFromBlacklist,
            startTargetScreening,
            updateTargetRisk,
        },
        exceptions: selectedClintExceptions,
        actions: {
            handleGoBack,
            handleAddToBlackList,
            handleRestoreFromBlackList,
            handleArchiveClient,
            handleUnArchive,
            handleEditInfo,
            handleStartScreening,
            handleDownloadFile
        },
        tab,
        setTab: handleSetTab,
        menu,
        updateRisk,
        riskVariants: [TARGET_NONE_RISK, TARGET_LOW_RISK, TARGET_MEDIUM_RISK, TARGET_HIGH_RISK],

        filterConfig: [startDate, statusConfig],
        applyFilter: (search: string, filters: Record<string, string[]>, signal: AbortSignal) => {
            dispatch(eraseScreenings());
            dispatch(GetKycScreeningsWithFilterAndPaging({data: {pageInfo: {page: 0, count: SCREENINGS_DEFAULT_PAGING, filters: {targetId: [id], ...filters, ...(currentSearchForFetch.trim().length > 0 ? {nameLike: currentSearchForFetch} : {})}}}}));
        },
        isDataOutdated: _isDataOutdated,
        handleReloadData,
        dicts,
        handleGoToExactTemplate,
        genericTableScreenings: {
            screenings: screenings.screenings,
            paging: {
                pageInfo: screenings.pageInfo,
                isLoading: isLoadingList,
                minLoadedPage: screenings.minMaxLoadedPage.minLoadedPage,
                maxLoadedPage: screenings.minMaxLoadedPage.maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceScreeningsMinMaxLoadedPage(data)),
                clearRows: () => dispatch(eraseScreenings()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    dispatch(GetKycScreeningsWithFilterAndPaging({data: {pageInfo: {page, count, filters: {...filters, targetId: [id]}}}}));
                },
            },
            onViewFullReport: (row: KycScreeningModel) => {
                row.id && history.push({pathname: KYC_SCREENING_EXACT_PATH.replace(':id', row.id), search: DEFAULT_GENERIC_TABLE_SEARCH});
                // screening.result && dispatch(openShowFullReport(screening.result));
            }
        },
        genericTableResolutions: {
            resolutions: clients.selectedClintExceptions,
            paging: {
                pageInfo: clients.clientExceptionsPageInfo,
                isLoading: getExactTargetExceptions,
                minLoadedPage: clients.selectedClintExceptionsMinMaxPage.minLoadedPage,
                maxLoadedPage: clients.selectedClintExceptionsMinMaxPage.maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceSelectedClintExceptionsMinMaxPage(data)),
                clearRows: () => dispatch(eraseExactClientExceptionsList()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, search: string) => {
                    selected && dispatch(GetKycExceptionsByTargetId({
                        data: {
                            pageInfo: {page, count, filters: {targetId: [selected.id], ...filters}},
                            targetId: selected.id
                        }
                    }));
                },
            },
            handleDeleteRow: (row: KycExceptionModel) => dispatch(openDeleteTargetException({exceptionId: row.id, targetId: row.targetId})),
        },
    }
}
