import {AddEditorSectionDialogPropsType} from "../../../types";
import * as yup from "yup";
import {MainTrainingContentType, TrainingContentNewModel,} from "../../../../../../newShared/GQLTypes";
import {useCustomFormik} from "../../../../../../newShared/hooks/useCustomFormik";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {uuid} from "../../../../../../newShared/utils";

export const useAddEditorSectionDialog = (props: AddEditorSectionDialogPropsType) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.dialogs'});
    const isUpdate = props.initialSection !== undefined;


    const validationSchema = yup.object({
        name: yup.string()
            .trim()
            .lowercase()
            .required(t('Name is required'))
            .notOneOf(isUpdate ? props.existingSections.filter(e => e.sectionId !== props.initialSection?.sectionId).map(e => e.name.trim().toLowerCase()) : props.existingSections.map(e => e.name.trim().toLowerCase()), 'Current training already has section with same name. Enter unique section name for this training')
            .max(120, 'Section name cannot be longer than 120 characters')
        ,
        data: yup.object({
            innerHtml: yup.string().required()
        })
        //.required('Editor data is required')
    });

    const formik = useCustomFormik<TrainingContentNewModel>(props.isOpen, {
        initialValues: props.initialSection ? {...props.initialSection} : { data: {innerHtml: ''}, name: '', type: MainTrainingContentType.Editor, sectionId: uuid(), order: props.existingSections.length},
        validationSchema,
        onSubmit: (values) => {
            if(isUpdate){
                props.onSectionUpdate({...values});
            }else{
                props.onSectionAdd({...values})
            }
        },
        initialTouched:{
            name: !!props.initialSection?.name
        }
    })

    return{
        formik,
        isUpdate,
        isOk: isUpdate ? formik.isValid && JSON.stringify(formik.values) !== JSON.stringify(props.initialSection) : formik.isValid,
        ...props,
    }
}
