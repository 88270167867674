import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideEditRisk, loadings, selectedRegistry, selectedRisk} from "../../../store/slice";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {useEffect} from "react";
import {updateRisksByRegistryIdAction} from "../../../store/actions";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const useEditRisk = () => {
    const dispatch = useDispatch();
    const {editRisk} = useSelector(dialogs);
    const {riskEdit} = useSelector(loadings);
    const {setMessage} = useMessageDialog();
    const reg = useSelector(selectedRegistry);
    const currRisk = useSelector(selectedRisk);
    const {t} = useMainTranslation('', {keyPrefix: 'risks'});


    const handleClose = () => {
        dispatch(hideEditRisk());
        setForm({name: '', type: '', description: ''});
    }

    const {form, setForm, handleChange} = useForm<{name: string, type: string, description: string}>({name: '', type: '', description: ''});

    useEffect(() => {
        if(editRisk && currRisk){
            setForm({name: currRisk.name, type: currRisk.type, description: currRisk.description});
        }
        //eslint-disable-next-line
    }, [editRisk]);

    const onSuccess = () => {
        setMessage({title: t('Completed successfully'), message: t('The {{name}} has been changed successfully.', {name: form.name})})
    }

    const handleSubmit = () => {
        //todo
        currRisk && dispatch(updateRisksByRegistryIdAction(
            {
                data: {
                    workspaceId: '',
                    registryId: reg!.id,
                    organizationId: '',
                    risks: [{...currRisk, name: form.name, type: form.type, description: form.description}],
                    deleteIds: [],
                    // pageInfo: {
                    //     page: 0,
                    //     count: 1, //does not matter, those will not replace local risks because of disableReplaceAll: true
                    //     filters: {}
                    //     //if 0 -> 10 = 10
                    //     //if 1 -> (1 + 1) * 10 = 20
                    //     //...
                    // }
                },
                onSuccess,
                disableReplaceAll: true
            }
        ))
    }

    const isOk = () => {
        if(!currRisk) return false;
        return(
            (form.name.trim().length > 0 && form.type.trim().length > 0 && form.description.trim().length > 0) &&
            JSON.stringify({name: currRisk.name, type: currRisk.type, description: currRisk.description}) !== JSON.stringify(form)
        )
    }

    return{
        form,
        handleChange,
        handleClose,
        isOpen: editRisk,
        isLoading: riskEdit,
        isOk,
        handleSubmit,

    }
}
