import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {dialogs, hideDeleteRegistry, loadings, selectedModel, selectedRegistry} from "../../../store/slice";
import {PATH_RISK_MANAGEMENT_RISK_REGISTRIES_ALL} from "../../../constants";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {deleteRiskRegistryAction} from "../../../store/actions";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const useDeleteRegistry = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {deleteRegistry} = useSelector(dialogs);
    const {registryDelete} = useSelector(loadings);
    const selected = useSelector(selectedModel);
    const {setMessage} = useMessageDialog();
    const reg = useSelector(selectedRegistry);
    const { t} = useMainTranslation('', {keyPrefix: 'risks'});

    const handleClose = () => {
        dispatch(hideDeleteRegistry());
    }

    const handleSubmit = () => {
        reg && dispatch(deleteRiskRegistryAction({
            data: {
                workspaceId: '',
                organizationId: '',
                registryId: reg.id
            },
            onSuccess
        }))
    }

    const onSuccess = () => {
        history.push(PATH_RISK_MANAGEMENT_RISK_REGISTRIES_ALL);
        setMessage({title: t('Completed successfully'), message: t('The model {{name}} has been removed.', {name: selected?.name ?? ''})})
    }

    return{
        isOpen: deleteRegistry,
        isLoading: registryDelete,
        handleClose,
        handleSubmit,

    }
}