import {dialogs, hideDeleteJobTitleDialog, hrSettings, loadings} from "../../../../store/slice";
import {useDispatch, useSelector} from "react-redux";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import {DialogContentWrapper} from "../../../styled";
import {DeleteJobTitleType} from "../../../../types";
import {useForm} from "../../../../../../../newShared/hooks/useForm";
import {CommonRadio} from "../../../../../../../newShared/components/Basic/CommonRadio";
import {LoadingButton} from "../../../../../../../newShared/components/Basic/CommonButtons";
import {DeleteSettingsJobTitle} from "../../../../store/actions";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const DeleteJobTitleDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathHRManagement.dialogs'});
    const {tCommon} = useMainTranslation();

    const dispatch = useDispatch();
    const {isOpen, jobTitle} = useSelector(dialogs).deleteJobTitle;
    const isLoading = useSelector(loadings).deleteJobTitle;
    const settings = useSelector(hrSettings);

    const {form, setValue, setForm} = useForm<DeleteJobTitleType>({jobTitle: null, history: false});

    const handleClose = () => {
        dispatch(hideDeleteJobTitleDialog());
        setForm({jobTitle: null, history: false});
    }

    const handleSubmit = () => {
        if(jobTitle && form.jobTitle !== null){
            dispatch(DeleteSettingsJobTitle({jobTitle, history: form.history, replaceJobTitleId: form.jobTitle.id}));
            setForm({jobTitle: null, history: false});
        }
    }

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{tCommon('Delete')} {jobTitle?.name}</DialogTitle>

            <DialogContent>
                <Typography>{t('If you delete information about a job title, you will not be able to restore it.')}</Typography>

                <DialogContentWrapper>
                    <Autocomplete
                        options={settings.jobTitle}
                        getOptionDisabled={(option) => option.id === jobTitle?.id}
                        getOptionLabel={(a) => a.name}
                        value={form.jobTitle}
                        isOptionEqualToValue={(a, b) => a.id === b.id}
                        onChange={(e, newValue) => setValue('jobTitle', newValue)}
                        sx={{margin: '16px 0 0 0'}}
                        renderInput={(params) => <TextField {...params} label={t('Job title')} size={'small'} required/>}
                    />
                </DialogContentWrapper>

                <FormControl sx={{marginTop: '16px'}}>
                    <FormLabel
                        id="demo-radio-buttons-group-label">{t('How should this change occur?')}</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="withoutHistory"
                        name="radio-buttons-group"
                        value={form.history ? 'includingHistory' : 'withoutHistory'}
                        onChange={(e, value) => setValue('history', Boolean(value === 'includingHistory'))}
                    >
                        <FormControlLabel value="withoutHistory" control={<CommonRadio />}
                                          sx={{marginLeft: '-7px'}}
                                          label={t("Change anywhere it is used, without history")}/>
                        <FormControlLabel value="includingHistory" control={<CommonRadio />}
                                          sx={{marginLeft: '-7px'}}
                                          label={t("Change anywhere it is used, including history")}/>
                    </RadioGroup>
                </FormControl>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{tCommon('Close')}</Button>

                <LoadingButton onClick={handleSubmit} loading={isLoading} disabled={!form.jobTitle} color={"error"}>
                    {tCommon('Delete')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
