import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideDeleteDialog, isLoading} from "../../../store/slice";
import React from "react";
import {Button, Dialog, DialogActions, DialogContentText, DialogTitle} from "@mui/material";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {DeleteFolder, DeleteNewDocument} from "../../../store/actions";

export const DeleteDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});
    const dispatch = useDispatch();
    const {isOpen, file, folder} = useSelector(dialogs).deleteItemsDialog;
    const isLoadingDelete = useSelector(isLoading).isLoadingDelete;

    const handleClose = () => {
        dispatch(hideDeleteDialog());
        // setFoldersWithChildren([]);
    }

    const submit = () => {
        if (file && file.documentId) dispatch(DeleteNewDocument({workspaceId: '', docId: file.documentId}))
        if (folder && folder.id) dispatch(DeleteFolder({workspaceId: '', folderId: folder.id}))
    }


    return(
        <Dialog onClose={handleClose} open={isOpen} fullWidth maxWidth={'sm'}>
            {folder && <DialogTitle>{`${t('Delete')} ${folder.name}`}</DialogTitle>}
            {file && <DialogTitle>{`${t('Delete')} ${file.name}`}</DialogTitle>}

            {folder &&
                <DialogContentText sx={{padding: '0 20px'}}>
                    {folder.children.length > 0 ?
                        `${folder.name} folder contains folder/s inside.Delete those folder/s and file/s inside first.`
                        :
                        `${t('Are you sure you want to delete')} ${folder.name}`
                    }
                </DialogContentText>
            }

            {file &&
                <DialogContentText sx={{padding: '0 20px'}}>
                    {`${t('Are you sure you want to delete')} ${file.name}`}
                </DialogContentText>
            }

            <DialogActions>
                <Button onClick={handleClose} disabled={isLoadingDelete} variant={'text'}>{t('Cancel')}</Button>
                {/*(folder && folder?.children.length === 0 || file)*/}
                { (file || folder?.children.length === 0) && <LoadingButton loading={isLoadingDelete} onClick={submit}>{t('Delete')}</LoadingButton>}
            </DialogActions>

        </Dialog>
    )
}
