import {useSelector, useDispatch} from "react-redux";
import {eraseHrSettings, hrSettings, loadings} from "../../store/slice";
import {useHistory} from "react-router-dom";
import {PATH_LOCAL_EMPLOYEES_SETTINGS, PATH_LOCAL_EMPLOYEES_SETTINGS_EXACT} from "../../constants";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_EMPLOYEES} from "../../../../../newShared/constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {SettingsTypes} from "../../types";
import {useEffect} from "react";
import {GetHrEmployeesSettings} from "../../store/actions";


export const useHRSettings = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector(loadings).getHrSettings;
    const history = useHistory();
    const settings = useSelector(hrSettings);
    const {getHrSettings} = useSelector(loadings);

    //breadcrumps
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    useSetBreadcrumbs([
        {
            title: tMenu('HR Management'),
            path: PATH_LOCAL_EMPLOYEES
        },
        {
            title: tMenu('Settings'),
            path: PATH_LOCAL_EMPLOYEES_SETTINGS
        }
    ]);

    useEffect(() => {
        if(!isLoading) {
            dispatch(eraseHrSettings());
            dispatch(GetHrEmployeesSettings({data: {}}));
        }
        // eslint-disable-next-line
    }, []);


    const handleGoToSettingById = (id: SettingsTypes) => {
        history.push(PATH_LOCAL_EMPLOYEES_SETTINGS_EXACT.replace(':id', id));
    }

    return{
        settings,
        isLoadingHrSettings: getHrSettings,
        handleGoToSettingById,
    }
}