import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useHistory, useParams} from "react-router-dom";
import {TPhysicalAssetSettings, TSetDeleteSettingRowDialog, TSetEditSettingRowDialog, TSettingsId} from "../../types";
import {normalizeSettingIdName} from "../../helpers";
import {useSelector} from "react-redux";
import {
    assetsSettingsSelector,
    loadings,
    setDeleteSettingRowDialogAction,
    setEditSettingRowDialogAction
} from "../../store/store";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_ASSET_MANAGEMENT} from "../../../../../newShared/constants";
import {
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS,
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_SETTINGS,
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_SETTINGS_EXACT,
    PATH_LOCAL_PHYSICAL_ASSETS_SETTINGS_NEW
} from "../../constants";
import {useEffect, useState} from "react";
import {useParameters} from "../../../../../newShared/hooks/useParameters";
import {useAppDispatch} from "../../../../../newShared/redux";
import {getPhysicalAssetsSettingsAction} from "../../store/actions";


export const useExactSettings = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const {t} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});
    const id = useParams<{id: TSettingsId}>().id;
    const {filter} = useParameters();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const settingTitle = normalizeSettingIdName(id as TSettingsId);
    const settings = useSelector(assetsSettingsSelector);
    const {isLoadingSettings} = useSelector(loadings);

    const controller = new AbortController();

    const [search, setSearch] = useState<string>(filter?.[id]?.[0] || '');
    const [settingsView, setSettingsView] = useState<TPhysicalAssetSettings | null>(settings);

    useSetBreadcrumbs([
        {
            title: tMenu('Asset Management'),
            path: PATH_LOCAL_ASSET_MANAGEMENT
        },
        {
            title: tMenu('Physical assets'),
            path: PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS
        },
        {
            title: tMenu('Settings'),
            path: PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_SETTINGS
        },
        {
            title: isLoadingSettings ? 'Loading...' : t(settingTitle),
            path: PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_SETTINGS_EXACT.replace(':id', id)
        }
    ]);

    useEffect(() => {
        !settings && dispatch(getPhysicalAssetsSettingsAction({data: {organizationId: '', workspaceId: ''}, signal: controller.signal}));

        return () => {
            controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        settings && setSettingsView(settings)
    }, [settings])

    const handleCreateNewItem = (id: TSettingsId) => history.push(PATH_LOCAL_PHYSICAL_ASSETS_SETTINGS_NEW.replace(':id', id));

    const handleGoBack = () => history.push(PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS_SETTINGS);

    const handleCleanSearch = () => {
        setSearch('');
        delete filter[id];
        dispatch(getPhysicalAssetsSettingsAction({data: {organizationId: '', workspaceId: ''}, signal: controller.signal}));
    }

    const handleEnterKey = (e: any) => {
        if (e.keyCode === 13 && search.trim().length === 0 && settings) setSettingsView(settings);
        if(e.keyCode === 13 && search.trim().length > 0 && settings){
            const regex = new RegExp(search.toLowerCase());
            switch (id){
                case 'model': setSettingsView({...settings, model: settings.model.filter(e => regex.test(e.name.toLowerCase()))}); break;
                case 'category': setSettingsView({...settings, category: settings.category.filter(e => regex.test(e.name.toLowerCase()))}); break;
                case 'manufacture': setSettingsView({...settings, manufacture: settings.manufacture.filter(e => regex.test(e.name.toLowerCase()))}); break;
                case 'status': setSettingsView({...settings, status: settings.status.filter(e => regex.test(e.name.toLowerCase()))}); break;
                case 'customFields': setSettingsView({...settings, customFields: settings.customFields.filter(e => regex.test(e.toLowerCase()))}); break;
            }
        }
    }

    const setEditSettingRowDialog = (data: TSetEditSettingRowDialog) => {dispatch(setEditSettingRowDialogAction(data))};
    const setDeleteSettingRowDialog = (data: TSetDeleteSettingRowDialog) => {dispatch(setDeleteSettingRowDialogAction(data))};

    return {
        settingsView,
        id: id as TSettingsId,
        isLoadingSettings,
        settingTitle,
        handleCreateNewItem,
        handleGoBack,

        search,
        setSearch,
        handleCleanSearch,
        handleEnterKey,
        setEditSettingRowDialog,
        setDeleteSettingRowDialog

    }
}
