import {createAsyncThunk} from "@reduxjs/toolkit";
import {AppState} from '../../../../newShared/redux/rootReducer';
import {
    addVendorFileApi,
    createVendorApi,
    createVendorsSettingRowApi,
    deleteVendorApi,
    deleteVendorById,
    deleteVendorFileApi,
    deleteVendorsSettingRowApi,
    downloadEvidenceFileAPI,
    editVendorsSettingRowApi,
    exportCsvVendorsApi,
    getVendorById,
    getVendorsApi,
    getVendorsAvailableFieldsForExport,
    getVendorsSettingsApi,
    importCsvTemplateVendorsApi,
    releaseVendorsApi,
    updateVendorApi,
} from "../api";
import {
    AddVendorFileMutationVariables,
    CreateVendorsSettingRowMutationVariables,
    DeleteVendorFileMutationVariables,
    DeleteVendorMutationVariables,
    DeleteVendorsSettingRowMutationVariables,
    EditVendorsSettingRowMutationVariables,
    ExportCsvVendorsQueryVariables,
    GetCsvFieldsVendorsMutation,
    GetCsvFieldsVendorsMutationVariables,
    GetVendorsSettingsQueryVariables,
    PaginationInput,
    PerformCsvVendorsMutation,
    PerformCsvVendorsMutationVariables,
    ReleaseVendorsMutationVariables,
    UpdateVendorMutationVariables,
    VendorInput,
} from "../../../../newShared/GQLTypes";
import {getActionsData} from "../../../../newShared/redux";
import {saveAs} from "file-saver";
import {b64toBlob} from "../../../../newShared/utils/base64/base64";
import {Vendor} from "../types";
import {TWithOptions} from "../../../../newShared/types";
import {createCommonAsyncThunk} from "../../../../newShared/utils/asyncThunk";
import {getCsvFieldsVendors, performCsvVendors} from "../api/query";
import moment from "moment";
import {addSuccessfulSnack} from "../../../barsEnvironment/snack/store/slice";

export const getVendorsAction = createAsyncThunk(
    'Vendors/Vendors/getVendors',
    async (data:{pageInfo: PaginationInput }, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        return await getVendorsApi(workspaceId, organizationId, data.pageInfo)
    }
);

export const createVendorAction = createAsyncThunk(
    'Vendors/Vendors/createVendor',
    async ({data, onSuccess}: {data: VendorInput, onSuccess: (id: string) => void}, {getState}) => {
        const state = getState() as AppState;
        const employee = data;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await createVendorApi({organizationId, workspaceId, employee});
        onSuccess(resp.id)
        return {
            data,
            resp
        }
    }
)

export const updateVendorAction = createAsyncThunk(
    'Vendors/Vendors/updateVendor',
    async (data: UpdateVendorMutationVariables, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await updateVendorApi({...data, workspaceId, organizationId})
        return {
            data,
            resp
        }
    }
)

export const deleteVendorAction = createAsyncThunk(
    'Vendors/Vendors/deleteVendor',
    async (data: DeleteVendorMutationVariables, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await deleteVendorApi({...data, workspaceId, organizationId})
        // dispatch(addInfoSnack(resp.message))
        return {
            data,
            resp
        }
    }
)

export const releaseVendorsAction = createAsyncThunk(
    'Vendors/Vendors/releaseVendors',
    async ({data, onSuccess}: {data: ReleaseVendorsMutationVariables, onSuccess: () => void}, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await releaseVendorsApi({...data, workspaceId, organizationId});
        onSuccess();
        return {
            data,
            resp
        }
    }
);

export const exportCsvVendorsAction = createAsyncThunk(
    'Vendors/Vendors/exportCsvVendors',
    async (data: Omit<ExportCsvVendorsQueryVariables, "workspaceId" | "organizationId">, {getState, dispatch}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await exportCsvVendorsApi({workspaceId, organizationId, fieldKeys: data.fieldKeys, isDownload: data.isDownload});

        if (data.isDownload) {
            const date = moment().format("yyyy-MM-DD-hh-mm-ss");
            saveAs(b64toBlob(resp as string), `vendors_${date}.csv`);
        } else {
            dispatch(addSuccessfulSnack(`You will receive email with a link to download CSV`));
        }
        return resp;

    }
)

export const GetVendorsAvailableFieldsForExport = createAsyncThunk(
    'vendors/GetVendorsAvailableFieldsForExport',
    async (_, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const {fields} = await getVendorsAvailableFieldsForExport({workspaceId});
        return {
            fields,
        };
    }
);

//settings
export const getVendorsSettingsAction = createAsyncThunk(
    'Vendors/Settings/getVendorsSettings',
    async (data: GetVendorsSettingsQueryVariables, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await getVendorsSettingsApi({...data, workspaceId, organizationId})

        return {
            data,
            resp
        }
    }
)

export const createVendorsSettingsRowAction = createAsyncThunk(
    'Vendors/Settings/createVendorsSettingsRow',
    async (data: TWithOptions<CreateVendorsSettingRowMutationVariables>, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await createVendorsSettingRowApi({...data.data, workspaceId, organizationId});
        data.onSuccess && data.onSuccess(data.data, resp);

        return {
            data,
            resp
        }
    }
)

export const editVendorsSettingsRowAction = createAsyncThunk(
    'Vendors/Settings/editVendorsSettingsRow',
    async ({data, onSuccess}: { data: EditVendorsSettingRowMutationVariables, onSuccess: () => void }, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await editVendorsSettingRowApi({...data, workspaceId, organizationId})
        onSuccess();

        return {
            data,
            resp
        }
    }
)

export const deleteVendorsSettingsRowAction = createAsyncThunk(
    'Vendors/Settings/deleteVendorsSettingsRow',
    async ({data, onSuccess}: { data: DeleteVendorsSettingRowMutationVariables, onSuccess: () => void }, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await deleteVendorsSettingRowApi({...data, workspaceId, organizationId})
        onSuccess();
        // dispatch(addInfoSnack(resp.message))
        return {
            data,
            resp
        }
    }
)

//

export const addVendorFileAction = createAsyncThunk(
    'Vendors/files/addVendorFile',
    async (data: AddVendorFileMutationVariables, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await addVendorFileApi({...data, workspaceId, organizationId})
        return {
            data,
            resp
        }
    }
)

export const deleteVendorFileAction = createAsyncThunk(
    'Vendors/Vendors/deleteVendorFile',
    async (data: DeleteVendorFileMutationVariables, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);

        const resp = await deleteVendorFileApi({...data, workspaceId, organizationId})
        return {
            data,
            resp
        }
    }
)

export const downloadVendorFileAction = createAsyncThunk(
    'Vendors/Vendors/downloadFile',
    async (data: { fileId: string, name: string }, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await downloadEvidenceFileAPI({fileId: data.fileId, fileName: data.name, workspaceId});
        saveAs(b64toBlob(resp.file), resp.filename);
        return {}
    }
);

export const GetVendorById = createAsyncThunk(
    'Vendors/Vendors/getVendorById',
    async ({id, onReject}: {id: string, onReject: () => void}, {getState}): Promise<Vendor> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        try{
            const res = await getVendorById(workspaceId, id);
            return res;
        }catch (ex: any){
            onReject();
            throw new Error(ex.message);
        }
    }
)


export const DeleteVendorById = createAsyncThunk(
    'Vendors/Vendors/deleteVendorById',
    async ({id, onSuccess}: {id: string, onSuccess: () => void}, {getState}): Promise<string> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        await deleteVendorById(workspaceId, id);

        onSuccess();
        return id;
    }
)

export const GetCsvTemplateVendorsAction = createAsyncThunk(
    'Vendors/Vendors/importCsvTemplateVendors',
    async (_, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId, organizationId} = getActionsData(state);
        const resp = await importCsvTemplateVendorsApi({workspaceId, organizationId});
        resp && saveAs(b64toBlob(resp as string), 'vendors-template.csv');

        return resp
    }
)

export const GetCsvFieldsVendorsAction =
    createCommonAsyncThunk<GetCsvFieldsVendorsMutationVariables, GetCsvFieldsVendorsMutation["getCsvFieldsVendors"]>(
        'vendors', 'getCsvFieldsVendors', true, false, getCsvFieldsVendors
    )
export const PerformCsvVendorsAction =
    createCommonAsyncThunk<PerformCsvVendorsMutationVariables, PerformCsvVendorsMutation["performCsvVendors"]>(
        'vendors', 'performCsvVendors', true, false, performCsvVendors,
    )

