import axiosSync from "../../../../newShared/utils/axios/axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {AxiosResponse} from "axios";
import {
    appHistory,
    availableAppType,
    createIntegrationWithVendorRequest,
    installedAppType,
    integrationLogType
} from "../types";
import {TPageInfo} from "../../../../newShared/types";
import {gql} from "graphql.macro";
import {print} from "graphql";
import {DownloadEvidenceFileQuery} from "../../../../newShared/GQLTypes";

export const getInitIntegrationsData = async (workspaceId: string, availableName: string, installedName: string, signal?: AbortSignal): Promise<{availableApps: availableAppType[], installedApps: installedAppType[]}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getInitIntegrationsData($workspaceId:String!, $availableName: String!, $installedName: String!){
                        getInitIntegrationsData(workspaceId:$workspaceId, availableName: $availableName, installedName: $installedName){
                            availableApps{
                                id
                                name
                                type
                                applicationGroup
                                logo
                                certifications
                                securityScope
                                description
                                documentationLink
                                endpointMappingName
                                status
                                releaseDate
                                config{
                                    collectionPrefix
                                    collectionConfigs{
                                        name
                                        alias
                                        headers
                                        data
                                    }
                                }
                            }
                            installedApps{
                                applicationId
                                workspaceId
                                name
                                detailsId
                                logo
                                status
                                ownerEmail
                                identity
                                hris
                            }
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    availableName,
                    installedName
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getInitIntegrationsData;
    })
)

export const getIntegrationAppById = async (workspaceId: string, appId: string, signal?: AbortSignal): Promise<{availableApps: availableAppType[], app: installedAppType}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getIntegrationAppById($workspaceId:String!, $appId:String!){
                        getIntegrationAppById(workspaceId:$workspaceId, appId:$appId){
                            availableApps{
                                id
                                name
                                type
                                applicationGroup
                                logo
                                certifications
                                securityScope
                                description
                                documentationLink
                                endpointMappingName
                                status
                                releaseDate
                                config{
                                    collectionPrefix
                                    collectionConfigs{
                                        name
                                        alias
                                        headers
                                        data
                                    }
                                }
                            }
                            app{
                                applicationId
                                workspaceId
                                name
                                detailsId
                                logo
                                status
                                ownerEmail
                                identity
                                hris
                            }
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    appId
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getIntegrationAppById;
    })
)

export const getDataByCollection = async (workspaceId: string, appId: string, collectionAlias: string, page: number, count: number, search: string, signal?: AbortSignal): Promise<{data: {[key: string]: string}[], pageInfo: TPageInfo}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getDataByCollection($workspaceId:String!, $appId:String!,$collectionAlias:String!, $page:Float!,$count:Float!,$search:String!){
                        getDataByCollection(workspaceId: $workspaceId, appId:$appId, collectionAlias:$collectionAlias, page:$page, count:$count, search:$search){
                            pageInfo{
                                total
                                page
                                count
                            }
                            data
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    appId,
                    collectionAlias,
                    page,
                    count,
                    search
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getDataByCollection;
    })
)

export const getAppHistory = async (workspaceId: string, appId: string, page: number, count: number, signal?: AbortSignal): Promise<{histories: appHistory[], pageInfo: TPageInfo}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getAppHistory($workspaceId:String!, $appId:String!, $page:Float!,$count:Float!){
                        getAppHistory(workspaceId:$workspaceId, appId:$appId, page:$page, count:$count){
                            histories{
                                id
                                workspaceId
                                applicationId
                                start
                                end
                                status
                                prefix
                                errorReason
                            }
                            pageInfo{
                                page
                                count
                                total
                            }
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    appId,
                    page,
                    count,
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAppHistory;
    })
)

export const updateIntegration = async (workspaceId: string, integrationDetails: any) => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation updateIntergation($workspaceId:String!, $data:UpdateIntegrationInput!){
                        updateIntergation(workspaceId:$workspaceId, data:$data){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    data: {
                        ...integrationDetails
                    }
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateIntergation;
    })
)


export const deleteIntegration = async (workspaceId: string, appId: string, endpointMappingName: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deleteIntegration($workspaceId:String!, $data:DeleteIntegrationInput!){
                        deleteIntegration(workspaceId:$workspaceId, data:$data){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    data: {
                        appId,
                        endpointMappingName
                    }
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteIntegration.message;
    })
)

export const startResearch = async (workspaceId: string, endpointMappingName: string, appId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation startResearch($workspaceId:String!, $data:StartResearchInput!){
                        startResearch(workspaceId:$workspaceId, data:$data){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    data: {
                        endpointMappingName,
                        appId
                    }
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.startResearch.message;
    })
)

export const addIntegration = async (workspaceId: string, integrationDetails: any, createVendor: createIntegrationWithVendorRequest | null, vendorId: string | null): Promise<{ id: string }> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation addIntergation($workspaceId:String!, $data:AddIntegrationInput!, $createVendor:AddIntegrationVendorInput, $vendorId:String){
                      addIntergation(workspaceId:$workspaceId, data:$data, createVendor:$createVendor, vendorId:$vendorId){
                        id
                      }
                    }
                `),
                variables: {
                    workspaceId,
                    data: {
                        ...integrationDetails
                    },
                    createVendor,
                    vendorId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addIntergation;
    })
)


export const getAppLogs = async (workspaceId: string, appId: string, page: number, count: number, signal?: AbortSignal): Promise<{logs: integrationLogType[], pageInfo: TPageInfo}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getAppLogs($workspaceId:String!, $appId:String!, $page:Float!,$count:Float!){
                      getAppLogs(workspaceId:$workspaceId, appId:$appId, page:$page, count:$count){
                        logs{
                          id
                          applicationId
                          workspaceId
                          fileId
                          size
                          timestamp
                          prefix
                        }
                        pageInfo{
                                page
                                count
                                total
                            }
                      }
                    }
                `),
                variables: {
                    workspaceId,
                    appId,
                    page,
                    count,
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAppLogs;
    })
)


export const downloadAppLogFile = async (data: {workspaceId: string, fileId: string}): Promise<DownloadEvidenceFileQuery["downloadEvidenceFile"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query downloadAppLogFile($fileId: String!, $workspaceId: String!) {
                        downloadAppLogFile (fileId: $fileId, workspaceId: $workspaceId) {
                            filename
                            filetype
                            file
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.downloadAppLogFile as {file: string, filename: string, filetype: string};
    })
)