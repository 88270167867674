import {GraphQLErrors} from "@apollo/client/errors";
import {T409Error, TApolloErrors} from "./types";

export const getErrorsByCode = <TData>(code: number, errors: GraphQLErrors | Error[]): TData[] => {
    return (errors as GraphQLErrors)?.filter(e => e?.extensions?.exception?.status === code).map(e => e?.extensions?.exception?.response) ?? [];
}

export const getErrorsMap = (errors: GraphQLErrors | Error[]): TApolloErrors => {
    const result: TApolloErrors & Record<string, any> = {
        all: [],
    };

    (errors as GraphQLErrors)?.forEach(e => {
        const code = e?.extensions?.exception?.status as number;
        const data = e?.extensions?.exception?.response;

        if (code) {
            result.all.push(e);
            if (result['e' + code]) {
                result['e' + code].push(data);
            } else {
                result['e' + code] = [data];
            }
        }
    })

    return result
}


//////////////////////////////
export const getError409ValuesMap = <Value = string, TExtendData extends object = {}>(errors: GraphQLErrors | Error[]): {type: string, values: Record<string, T409Error<Value, TExtendData>["values"][number]>} | undefined => {
    const error409 = getErrorsByCode<T409Error<Value, TExtendData>>(409, errors)[0] || undefined;
    if (!error409) return undefined;

    const result: Record<string, T409Error<Value, TExtendData>["values"][number]> = {};
    error409?.values.forEach(value => {
        result[value.parameter] = value;
    })

    return {
        type: error409.type,
        values: result,
    };
}


//////////////////////////////