import React, {FC} from "react";
import {TImportCsvField} from "../../types";
import colors from "../../../../theme/colors";
import {Box, Button, Checkbox, TableCell, TableRow} from "@mui/material";
import {DragIndicator} from "@mui/icons-material";
import {useDrag, useDrop} from "react-dnd";
import {TypographyNew} from "../../../TypographyNew";
import {useMainTranslation} from "../../../../hooks/useMainTranslationHooks/useMainTranslation";
import {acceptItemType} from "../../constants";
import {TFieldsMapperKey} from "../../../../types";
import {fieldsMapper} from "../../../../constants";

export type TImportCsvDraggableRowProps = {
    field: TImportCsvField;
    fieldsMapperKey: TFieldsMapperKey;

    isDisabledUnique?: boolean,
    isDisabledDrop?: boolean,
    isDisabledDrag?: boolean,
    isDisabled?: boolean,
    onDrop: (csvField: string) => void,
    onChange: (isKey: boolean) => void,
    onUnassign: () => void,
}
export const ImportCsvDraggableRow: FC<TImportCsvDraggableRowProps> = ({field, isDisabled, fieldsMapperKey, isDisabledDrag, isDisabledDrop, isDisabledUnique, onDrop, onUnassign, onChange}) => {
    const {t} = useMainTranslation('', {keyPrefix: 'CommonComponents.ImportCsvDialog'});
    const {t: tImport} = useMainTranslation('', {keyPrefix: 'CommonComponents.ImportExportDialogs'});


    const [{isOver}, drop] = useDrop(
        () => ({
            accept: [acceptItemType],
            drop: (item: TImportCsvField) => {
                item.csvField?.length && onDrop(item.csvField)
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            })
        }),
        []
    );

    const [, drag] = useDrag(() => ({
        type: acceptItemType,
        item: field satisfies TImportCsvField,
        collect: (monitor) => {
            return {
                isDragging: !!monitor.isDragging(),
            }
        }
    }))

    const showUnassign = !isDisabledDrag && !!field.fieldKey?.length && !!field.csvField?.length;
    const showDrag = !isDisabledDrag;

    return (
        <TableRow
            id={field.fieldKey === '' ? field.csvField?.replaceAll(' ', '_') : undefined}
            ref={!isDisabledDrop ? drop : undefined}
            sx={{
                position: 'relative', backgroundColor: isOver ? colors.backgrounds.blue_light_1 : undefined,
                opacity: isDisabled ? 0.5 : undefined
            }}
        >
            <TableCell sx={{maxWidth: 100}}>
                <Box sx={{display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
                    {/*<TypographyNew noWrap variant={'body2'}>{(field.fieldTitle || '') + `${field.isRequired ? '*' : ''}`}</TypographyNew>*/}
                    {field?.fieldKey && <TypographyNew noWrap variant={'body2'}>{
                        fieldsMapper[fieldsMapperKey][field.fieldKey] ?
                            tImport(fieldsMapper[fieldsMapperKey][field.fieldKey]) : field.fieldKey + `${field.isRequired ? '*' : ''}`}</TypographyNew> }

                    {/*{ field.description && <TypographyNew fontSize={'10px'} lineHeight={'130%'} fontWeight={400} noWrap>{field.description}</TypographyNew> }*/}
                </Box>
            </TableCell>

            <TableCell ref={showDrag ? drag : undefined} sx={{
                maxWidth: 100,
                '& svg': {opacity: showDrag ? 1 : 0},
                '& button': {opacity: showDrag ? 1 : 0},

                ':hover': {
                    '& svg': {color: colors.primary.blue},
                },
            }}>
                <Box sx={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                    <DragIndicator/>
                    <TypographyNew variant={'body2'} sx={{flexGrow: 1}} noWrap>{field.csvField}</TypographyNew>
                    {showUnassign && (
                        <Button variant={'text'} onClick={onUnassign} disableRipple sx={{textTransform: 'none'}} size={"small"}>
                            {t('Unassign')}
                        </Button>
                    )}
                </Box>
            </TableCell>

            <TableCell sx={{maxWidth: 70, textAlign: 'center'}}>
                <Checkbox checked={!field.fieldKey?.length} disableRipple size={"small"} disabled/>
            </TableCell>

            <TableCell sx={{maxWidth: 70, textAlign: 'center'}}>
                <Checkbox checked={!!field.fieldKey?.length && !!field.isKey} disableRipple size={"small"}
                          // disabled={!field.fieldKey?.length || !field.csvField?.length || isDisabledUnique}
                          disabled
                          onChange={e => onChange(e.target.checked)}/>
            </TableCell>
        </TableRow>
    )
}
