import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isLoadingExact, isLoadingUpdateVendor, openDeleteDialog, selectedVendorSelector,} from "../../store/slice";
import {GetVendorById} from "../../store/actions";
import {PATH_LOCAL_VENDORS, PATH_LOCAL_VENDORS_SELECTED_VENDOR} from "../../constants";
import {ExactVendorTabs} from "../../types";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const useExactVendor = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const id = useParams<{id: string}>().id;
    const selectedVendor = useSelector(selectedVendorSelector);
    const isLoading = useSelector(isLoadingExact);

    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    useSetBreadcrumbs([
        {
            title: tMenu('Vendors'),
            path: PATH_LOCAL_VENDORS
        },
        {
            title: selectedVendor? `${selectedVendor.name}` : tMenu('Loading...'),
            path: PATH_LOCAL_VENDORS_SELECTED_VENDOR.replace(':id',id)
        },
    ]);

    const isLoadingUpdate = useSelector(isLoadingUpdateVendor)

    const handleGoBack = () => {
        history.push(PATH_LOCAL_VENDORS);
    }

    //tabs
    const [tab, setTab] = useState<ExactVendorTabs>('Information')

    useEffect(() => {
        (!selectedVendor || (selectedVendor && selectedVendor.id !== id)) && dispatch(GetVendorById({id, onReject: handleGoBack}))

        //eslint-disable-next-line
    },[])

    const handleOpenDeleteDialog = () => dispatch(openDeleteDialog());

    return {
        selectedVendor,
        isLoadingUpdate,
        tab,
        setTab,
        handleGoBack,
        isLoading,
        handleOpenDeleteDialog
    }
}