import React from "react";
import {useIncomingRequestViewDialog} from "../../../hooks/useIncomingRequestViewDialog";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {getHrRequestStatusChip} from "../../../helpers";
import {hrFieldsMapper, statusTypeTitleMapper} from "../../../constants";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import colors from "../../../../../../newShared/theme/colors";
import {DescriptionRow, DescriptionRowTitle, RequestTitleSpan} from "../../styled";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {MainHrGetRequestByIdQuery, MainHrRequestStatus,} from "../../../../../../newShared/GQLTypes";


export const IncomingRequestViewDialog = () => {
    const {t, tCommon, revDir} = useMainTranslation('', {keyPrefix: 'pathHRManagement.dialogs'});
    const {
        isOpen,
        isLoadingRequest,
        employee,
        request,
        requestId,
        isOnReject,
        setIsOnReject,
        handleRejectRequest,
        handleClose,
        handleApproveRequest,
        handleDeleteRequest,
        isLoadingApproveRequest,
        isLoadingRejectRequest,
        isLoadingDeleteRequest
    } = useIncomingRequestViewDialog();

    return (
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{`${t('Request')} ${request?.id ?? ''}`}</DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                {!isLoadingRequest && !employee && <Typography variant={'*bodyText2_semibold'}>{t('This request is not relevant as the associated employee no longer exists.')}</Typography>}
                {isOnReject ?
                    <Typography variant={'body2'}>{t('Are you sure you want to reject this request?')}</Typography>
                    :
                    <>
                        <DescriptionRow>
                            <DescriptionRowTitle><Typography variant={'body2'}>{t('Status')}</Typography></DescriptionRowTitle>
                            {isLoadingRequest ? <Skeleton width={'200px'}/> : <Box><Typography variant={'body2'}>{request?.status ? getHrRequestStatusChip(request?.status) : ''}</Typography></Box>}
                        </DescriptionRow>
                        <DescriptionRow>
                            <DescriptionRowTitle><Typography variant={'body2'}>{t('Requestor')}</Typography></DescriptionRowTitle>
                            {isLoadingRequest ? <Skeleton width={'200px'}/> :
                                <Box><Typography variant={'body2'}>{request?.requestor.fullName}</Typography></Box>}
                        </DescriptionRow>
                        <DescriptionRow>
                            <DescriptionRowTitle><Typography variant={'body2'}>{t('Request type')}</Typography></DescriptionRowTitle>
                            {isLoadingRequest ? <Skeleton width={'200px'}/> :
                                <Box><Typography variant={'body2'}>{request?.type ? statusTypeTitleMapper[request?.type] ?? request?.type : ''}</Typography></Box>}
                        </DescriptionRow>
                        <DescriptionRow>
                            <DescriptionRowTitle><Typography variant={'body2'}>{t('Request date')}</Typography></DescriptionRowTitle>
                            {isLoadingRequest ? <Skeleton width={'200px'}/> :
                                <Box><Typography variant={'body2'}>{parseDateAuto(request?.requestDate ?? '')}</Typography></Box>}
                        </DescriptionRow>

                        { employee && !isLoadingRequest && (
                            request?.status === MainHrRequestStatus.Created ?
                                <>
                                    <Divider flexItem sx={{bgcolor: colors.text.grey_light_2, flexShrink: 0,}}/>
                                    <Typography variant={'body1'}>{t('Changes requested:')}</Typography>

                                    <Table stickyHeader >
                                        <TableHead>
                                            <TableRow >
                                                <TableCell>{t('Current')}</TableCell>
                                                <TableCell>{t('Requested')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {request?.data.editProfile.map(field => (
                                                <TableRow key={field.fieldName}>
                                                    <TableCell>
                                                        <Typography variant={'body2'}>{`${hrFieldsMapper[field.fieldName] ? t(hrFieldsMapper[field.fieldName]) : field.fieldName}: `}
                                                            <RequestTitleSpan >
                                                                {field.fieldName === 'birthDay' ? parseDateAuto(employee?.['birthDay']) : employee?.[field.fieldName as keyof MainHrGetRequestByIdQuery["mainHrGetRequestById"]["employee"]] || '-'}
                                                            </RequestTitleSpan>
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant={'body2'}>{`${hrFieldsMapper[field.fieldName] ? t(hrFieldsMapper[field.fieldName]) : field.fieldName}: `}
                                                            <RequestTitleSpan >{field.fieldName === 'birthDay' ? parseDateAuto(field?.fieldValue ?? '-') : field.fieldValue || '-'}</RequestTitleSpan>
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>

                                    </Table>
                                </>
                                :
                                <Table stickyHeader sx={{mt: '20px'}} >
                                    <TableHead>
                                        <TableRow >
                                            <TableCell>{t('Requested')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {request?.data.editProfile.map(field => (
                                            <TableRow key={field.fieldName}>
                                                <TableCell>
                                                    <Typography variant={'body2'}>{`${hrFieldsMapper[field.fieldName] ? t(hrFieldsMapper[field.fieldName]) : field.fieldName}: `}
                                                        <RequestTitleSpan >{field.fieldName === 'birthDay' ?  parseDateAuto(field.fieldValue ?? '-') : field.fieldValue || '-'}</RequestTitleSpan>
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                        )
                        }
                    </>
                }

            </DialogContent>
            <DialogActions>
                {isOnReject ? (
                    <>
                        <Button onClick={() => setIsOnReject(false)} variant={'text'}>{tCommon('Cancel')}</Button>
                        <LoadingButton
                            size={'small'}
                            sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                            onClick={() => requestId && handleRejectRequest(requestId)}
                            variant={'outlined'}
                            loading={isLoadingRejectRequest}
                        >
                            {tCommon('Confirm')}
                        </LoadingButton>
                    </>
                ) : (
                    <>
                        <Button onClick={handleClose} variant={'text'}>{tCommon('Close')}</Button>
                        {(employee || isLoadingRequest) ? (
                                request?.status === MainHrRequestStatus.Created &&
                                <>
                                    <Button size={'small'} onClick={() => setIsOnReject(true)} variant={'outlined'}> {t('Reject')} </Button>
                                    <LoadingButton
                                        size={'small'}
                                        sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                        onClick={() => requestId && handleApproveRequest(requestId)}
                                        disabled={!request}
                                        loading={isLoadingApproveRequest}
                                    >
                                        {t('Approve')}
                                    </LoadingButton>
                                </>

                            ) :
                            <LoadingButton sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}} size={'small'}
                                           onClick={() => requestId && handleDeleteRequest(requestId)}
                                           disabled={!request} loading={isLoadingDeleteRequest}>
                                {t('Delete request')}
                            </LoadingButton>
                        }
                    </>
                )
                }

            </DialogActions>
        </Dialog>
    )

}
