import {useDispatch} from "react-redux";
import {useMenu} from "../../../../../newShared/hooks/useMenu";
import {useEffect, useState} from "react";
import {galleryImageType, TrainingCoverComponentPropsType} from "../../types";
import {MainCoverImageType, MainTrainingCoverImageType} from "../../../../../newShared/GQLTypes";
import {mainGetSystemCoversAction} from "../../store/actions";
import {DEFAULT_GALLERY_EXAM_IMAGE_ID, DEFAULT_GALLERY_TRAINING_IMAGE_ID} from "../../constants";
import {useFileDropZone} from "../../../../../newShared/hooks/useSingleFileDropZone";

export const useTrainingCover = ({currentCover, onChange, type}: TrainingCoverComponentPropsType) => {
    const dispatch = useDispatch();
    const menu = useMenu();
    const drop = useFileDropZone(5, () => setFileTooLargeDialog(true));

    const [gallery, setGallery] = useState<galleryImageType[]>([]);
    const [galleryDialog, setGalleryDialog] = useState<boolean>(false);
    const [fileTooLargeDialog, setFileTooLargeDialog] = useState<boolean>(false);
    const [cropDialog, setCropDialog] = useState<boolean>(false);

    const handleUseFromGallery = () => {
        setGalleryDialog(true);
        menu.handleClose();
    }

    const handleSetNewImage = () => {
        drop.filePicker();
        menu.handleClose();
    }

    const setGalleryImage = ({image, thumbnail, id}: galleryImageType) => {
        onChange({
            type: MainTrainingCoverImageType.Url,
            image,
            thumbnail,
            imageName: id
        });
        setGalleryDialog(false);
    }

    useEffect(() => {
        dispatch(mainGetSystemCoversAction({
            data: {type},
            onSuccess: (request, response) => {
                setGallery(response);
                if(!currentCover && response.length > 0){
                    const defaultImage = response.find(e => type === MainCoverImageType.Training ? e.id === DEFAULT_GALLERY_TRAINING_IMAGE_ID : e.id === DEFAULT_GALLERY_EXAM_IMAGE_ID);
                    defaultImage && setGalleryImage(defaultImage);
                }
            }
        }))
        //eslint-disable-next-line
    }, []);

    const [imageToCrop, setImageToCrop] = useState<string>('');
    useEffect(() => {
        if(drop.uploadedFile){
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                setImageToCrop(reader.result?.toString() || ""),
            );
            reader.readAsDataURL(drop.uploadedFile);
            setCropDialog(true);
        }
    }, [drop.uploadedFile]);

    return{
        menu,
        currentCover,
        handleUseFromGallery,
        handleSetNewImage,
        coverGalleryDialog: {
            isOpen: galleryDialog,
            handleClose: () => setGalleryDialog(false),
            images: gallery,
            onSelect: setGalleryImage
        },
        drop,
        fileTooLargeDialog: {
            isOpen: fileTooLargeDialog,
            handleClose: () => setFileTooLargeDialog(false),
            handleUploadImage: () => {
                drop.filePicker();
                setFileTooLargeDialog(false);
            }
        },
        cropImageDialog: {
            isOpen: cropDialog,
            handleClose: () => setCropDialog(false),
            imageBase64: imageToCrop,
            onSave: (image: {imageId: string, thumbnailId: string}) => {
                if(drop.fileInBase64){
                    onChange({
                        imageName: drop.fileInBase64.name,
                        image: image.imageId,
                        thumbnail: image.thumbnailId,
                        type: MainTrainingCoverImageType.FileId,
                        fileType: 'png'
                    });
                    setCropDialog(false);
                }

            }
        }
    }
}