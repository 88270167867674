import {FC} from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useFrameworksDialog} from "../../../hooks/useFrameworksDialog";
import {DeleteDialogButton} from "../../../../../../newShared/components/deleteDialogButton";
import {useControl} from "../../../hooks/useControl";

export const DeleteEvidenceAssetDialog: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathFrameworks.Dialogs'});

    const {deleteAsset} = useControl();
    const {deleteAsset: {
        id, isOpen, isLoading, setDeleteAssetDialog
    }} = useFrameworksDialog();

    const handleClose = () => {setDeleteAssetDialog({clear: true})}
    const handleConfirm = () => {id && deleteAsset(id)}

    return (
        <DeleteDialogButton isOpen={isOpen} isLoading={isLoading}
                            title={t('Delete asset')}
                            message={t('If you delete this asset, you will not be able to restore it.')}
                            handleConfirm={handleConfirm}
                            handleClose={handleClose}
        />
    )
}
