import {gql} from "@apollo/react-hooks";
import {importCsvFragmentFields, pageInfoFragment, performCsvFragment} from "../../../../newShared/graphQl/fragments";
import {mainHrRequestFragment, mainHrIssueFragment, mainHrTimeOffFragment} from "./fragments";

export const getEmployeesTree = gql`
    query getEmployeesTree($organizationId: String!, $workspaceId: String!){
        getEmployeesTree(organizationId: $organizationId, workspaceId: $workspaceId) {
            id
            name
            manager
            jobTitle
            workMobilePhone
            workEmail
        }
    }
`

export const updateEmployeesTree = gql`
    mutation updateEmployeesTree($organizationId: String!, $workspaceId: String!, $employees: [UpdateEmployeesTreeDto!]!, $deleted: [String!]!){
        updateEmployeesTree(organizationId: $organizationId, workspaceId: $workspaceId, employees: $employees, deleted: $deleted) {
            id
            name
            manager
            jobTitle
            workMobilePhone
            workEmail
        }
    }
`

export const getCsvFieldsEmployees = gql`
    mutation getCsvFieldsEmployees($workspaceId: String!, $file: String!, $name: String!){
        getCsvFieldsEmployees(workspaceId: $workspaceId, file: $file, name: $name) {
            fileId
            fields {
                ...importCsvFragmentFields
            }
        }
    }
    ${importCsvFragmentFields}
`

export const performCsvEmployees = gql`
    mutation performCsvEmployees($workspaceId: String!, $fileId: String!, $fields: [ImportCsvFieldInput!]!){
        performCsvEmployees(workspaceId: $workspaceId, fileId: $fileId, fields: $fields) {
            ...performCsvFragment
        }
    }
    ${performCsvFragment}
`

export const checkCollaboratorIsNotAssigned = gql`
    query checkCollaboratorIsNotAssigned($workspaceId: String!, $workEmail: String!){
        checkCollaboratorIsNotAssigned(workspaceId: $workspaceId, workEmail: $workEmail) {
            collaborator {
                publicId
                firstName
                lastName
                email
            }
            isEmployeeExist
        }
    }
`

export const mainHrGetRequestsWithFilterPagination = gql`
    query mainHrGetRequestsWithFilterPagination($workspaceId: String!, $pageRequest: SharedPaginationInput!, $filter: MainHrRequestsFilter!) {
        mainHrGetRequestsWithFilterPagination(workspaceId: $workspaceId, pageRequest: $pageRequest, filter: $filter) {
            result {...mainHrRequestFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${mainHrRequestFragment}
`

export const mainHrGetRequestRequestorsAutocomplete = gql`
    query mainHrGetRequestRequestorsAutocomplete($workspaceId:String!, $pageRequest:SharedPaginationInput!, $search: String!){
        mainHrGetRequestRequestorsAutocomplete(workspaceId:$workspaceId, pageRequest:$pageRequest, search: $search){
            result {
                employeeId
                fullName
            }
            pageInfo{
                page
                count
                total
            }
        }
    }
`

export const mainHrGetRequestById = gql`
    query mainHrGetRequestById($id: String!, $workspaceId: String!){
        mainHrGetRequestById(id: $id, workspaceId: $workspaceId){
            request {
                id
                workspaceId
                type
                requestor {
                    employeeId
                    fullName
                }
                requestDate
                status
                resolver {
                    employeeId
                    employeeFullName
                    publicId
                    collaboratorFullName
                }
                resolveDate
                data{
                    editProfile{
                        fieldName
                        fieldValue
                    }
                }
                
            }
            employee {
                birthDay
                city
                country
                firstName
                id
                lastName
                middleName
                nickname
                street
                workMobilePhone
                zip
            }
        }
    }
    `;
export const mainHrApproveRequest = gql`
    mutation mainHrApproveRequest($id: String!, $workspaceId: String!){
        mainHrApproveRequest(id: $id, workspaceId: $workspaceId){
            message
        }
    }

`;

export const mainHrRejectRequest = gql`
    mutation mainHrRejectRequest($id: String!, $workspaceId: String!){
        mainHrRejectRequest(id: $id, workspaceId: $workspaceId){
            message
        }
    }


`;

export const mainHrDeleteRequest = gql`
    mutation mainHrDeleteRequest($id: String!, $workspaceId: String!){
        mainHrDeleteRequest(id: $id, workspaceId: $workspaceId){
            message
        }
    }


`;
export const mainHrGetIssuesList = gql`
    query mainHrGetIssuesList($workspaceId: String!, $pageRequest: SharedPaginationInput!, $filter: MainHrIssuesFilter!) {
        mainHrGetIssuesList(pageRequest: $pageRequest, workspaceId: $workspaceId, filter: $filter) {
            result {
                ...mainHrIssueFragment
            }
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${mainHrIssueFragment}
`

export const mainHrGetIssueById = gql`
    query mainHrGetIssueById($workspaceId: String!, $issueId: String!) {
        mainHrGetIssueById(workspaceId: $workspaceId, issueId: $issueId) {
            ...mainHrIssueFragment
        }
    }
    ${mainHrIssueFragment}
`

export const mainHrSetIssueStatusInProgress = gql`
    mutation mainHrSetIssueStatusInProgress($workspaceId: String!, $issueId: String!) {
        mainHrSetIssueStatusInProgress(workspaceId: $workspaceId, issueId: $issueId) {
            message
        }
    }
`

export const mainHrSetIssueStatusResolved = gql`
    mutation mainHrSetIssueStatusResolved($workspaceId: String!, $issueId: String!, $comment: String!) {
        mainHrSetIssueStatusResolved(workspaceId: $workspaceId, issueId: $issueId, comment: $comment) {
            message
        }
    }
`

export const mainHrIssuesReportersAutocomplete = gql`
    query mainHrIssuesReportersAutocomplete($workspaceId: String!, $pageRequest: SharedPaginationInput!, $search: String!) {
        mainHrIssuesReportersAutocomplete(workspaceId: $workspaceId, pageRequest: $pageRequest, search: $search) {
            result {
                id
                name
            }
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
`

export const mainHrIssuesOffendersAutocomplete = gql`
    query mainHrIssuesOffendersAutocomplete($workspaceId: String!, $pageRequest: SharedPaginationInput!, $search: String!) {
        mainHrIssuesOffendersAutocomplete(workspaceId: $workspaceId, pageRequest: $pageRequest, search: $search) {
            result {
                id
                name
            }
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
`
export const mainHrGetTimeOffRequestsWithFilterPagination = gql`
    query mainHrGetTimeOffRequestsWithFilterPagination($workspaceId: String!, $pageRequest: SharedPaginationInput!, $filter: MainHrTimeOffRequestsFilter!) {
        mainHrGetTimeOffRequestsWithFilterPagination(workspaceId: $workspaceId, pageRequest: $pageRequest, filter: $filter) {
            result {...mainHrTimeOffFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${mainHrTimeOffFragment}
`;

export const mainHrGetTimeOffEmployeesAutocomplete = gql`
    query mainHrGetTimeOffEmployeesAutocomplete($workspaceId: String!, $pageRequest: SharedPaginationInput!, $search: String!) {
        mainHrGetTimeOffEmployeesAutocomplete(workspaceId: $workspaceId, pageRequest: $pageRequest, search: $search) {
            result {
                employeeId
                fullName
            }
            pageInfo {...pageInfoFragment}
        }
    }
    ${pageInfoFragment}
`;

export const mainHrGetAndChangeStepStatusTimeOffRequest = gql`
    query mainHrGetAndChangeStepStatusTimeOffRequest($workspaceId: String!, $requestId: String!) {
        mainHrGetAndChangeStepStatusTimeOffRequest(workspaceId: $workspaceId, requestId: $requestId) {
            ...mainHrTimeOffFragment
        }
    }
    ${mainHrTimeOffFragment}
`;

export const mainHrApproveTimeOffRequest = gql`
    mutation mainHrApproveTimeOffRequest($workspaceId: String!, $requestId: String!, $comment: String!) {
        mainHrApproveTimeOffRequest(workspaceId: $workspaceId, requestId: $requestId, comment: $comment) {
            message
        }
    }
`;

export const mainHrRejectTimeOffRequest = gql`
    mutation mainHrRejectTimeOffRequest($workspaceId: String!, $requestId: String!, $comment: String!) {
        mainHrRejectTimeOffRequest(workspaceId: $workspaceId, requestId: $requestId, comment: $comment) {
            message
        }
    }
`;

export const mainHrSkipTimeOffRequest = gql`
    mutation mainHrSkipTimeOffRequest($workspaceId: String!, $requestId: String!, $comment: String!) {
        mainHrSkipTimeOffRequest(workspaceId: $workspaceId, requestId: $requestId, comment: $comment) {
            message
        }
    }
`;
