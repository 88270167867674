import React, {FC, useEffect, useState} from "react";
import {Divider, IconButton} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import * as S from "./styled";
import {TypographySubtitle1} from "../../../../../../newShared/components/Inputs/styled";
import CloseIcon from '@mui/icons-material/Close';
import {INFO_VIEW, START_DOCUMENT_ITEM, TEMPLATE_GALLERY_ITEM} from "../../../constants";
import {ReactComponent as StartDocumentIcon} from "../../../../../../newShared/images/documents/start-document.svg";
import {
    ReactComponent as TemplateGalleryIcon
} from "../../../../../../newShared/images/menu/documents-template-gallery.svg";
import {useAddDocumentDialog} from "../../../hooks/dialogs/useAddDocumentDialog";
import {TemplateGalleryView} from "./templateGalleryView";
import {FillInfoView} from "./fillInfoView";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {StartDocumentView} from "./startDocumentView";

export const AddDocumentDialog: FC = () => {

    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage.dialogs'});

    const {
        isOpen,
        isTemplatePage,
        handleNext,
        formActors: {
            form
        },
        isNextDisabled,
        loadings: {isLoadingSaveDoc, isLoadingDataForDialog},
        templatesVariants,
        searchTemplate,
        setSearchTemplate,
        selectedTemplate,
        handleSetTemplate,
        pageView,
        setPageView,
        newDocVariant,
        handleSetBlank,
        fileName,
        handleClose,
        handleGoToGalleryVariants,

        handleCreateNewDoc,
        ref,
        isDrag,
        setDrag,
        isAbleToDrop,
        fileHandler,
        handleDeleteFile,
        filePicker,
    } = useAddDocumentDialog();

    const [recipientsError, _setRecipientsError] = useState<Record<string, boolean>>({});
    const setRecipientsError = (value: Record<string, boolean>) => {
        _setRecipientsError(prev => ({...prev, ...value}));
    }

    useEffect(() => {
        _setRecipientsError({})
    }, [isOpen]);

    return (
        <S.AddFileDialog open={isOpen} onClose={handleClose}>
            <S.TitleBox>
                <TypographySubtitle1>{t("Get started")}</TypographySubtitle1>
                <IconButton disableRipple onClick={handleClose}><CloseIcon /></IconButton>
            </S.TitleBox>
            <S.AddDocumentDialogContent>
                { pageView === INFO_VIEW ?
                    <DndProvider backend={HTML5Backend}>
                        <FillInfoView isTemplatePage={isTemplatePage} setRecipientsError={setRecipientsError}/>
                    </DndProvider>
                    :
                    <>
                        <S.LeftSide>
                            <S.LeftSideMenuItem onClick={() => setPageView(START_DOCUMENT_ITEM)} isActive={pageView === START_DOCUMENT_ITEM || undefined}>
                                <StartDocumentIcon />
                                <S.LeftSideMenuItemTitle>{t(START_DOCUMENT_ITEM)}</S.LeftSideMenuItemTitle>
                            </S.LeftSideMenuItem>
                            <S.LeftSideMenuItem onClick={handleGoToGalleryVariants} isActive={pageView === TEMPLATE_GALLERY_ITEM || undefined}>
                                <TemplateGalleryIcon />
                                <S.LeftSideMenuItemTitle>{t(TEMPLATE_GALLERY_ITEM)}</S.LeftSideMenuItemTitle>
                            </S.LeftSideMenuItem>
                        </S.LeftSide>
                        <Divider variant={"fullWidth"} orientation={'vertical'}/>
                        <S.RightSide>
                            {pageView === START_DOCUMENT_ITEM &&
                                <StartDocumentView
                                    newDocVariant={newDocVariant}
                                    handleSetBlank={handleSetBlank}
                                    refForInput={ref}
                                    fileName={fileName}
                                    isDrag={isDrag}
                                    setDrag={setDrag}
                                    isAbleToDrop={isAbleToDrop}
                                    fileHandler={fileHandler}
                                    handleDeleteFile={handleDeleteFile}
                                    filePicker={filePicker}
                                />
                                // <>
                                //
                                //     <S.StartBlankBox onClick={(e) => handleSetBlank(e)} isSelected={newDocVariant === 'BLANK' ? true : undefined}>
                                //         {!fileName ? <BlankIcon/> : <DisabledBlankIcon />}
                                //         <S.TitleBlankBox>
                                //             <S.TitleBlank>{t('Blank')}</S.TitleBlank>
                                //         </S.TitleBlankBox>
                                //
                                //     </S.StartBlankBox>
                                // </>
                            }

                            {pageView === TEMPLATE_GALLERY_ITEM && (
                                <TemplateGalleryView
                                    isLoadingDataForDialog={isLoadingDataForDialog}
                                    templatesVariants={templatesVariants}
                                    searchTemplate={searchTemplate}
                                    setSearchTemplate={setSearchTemplate}
                                    selectedTemplate={selectedTemplate}
                                    setSelectedTemplate={handleSetTemplate}
                                />) }
                        </S.RightSide>
                    </>

                }


            </S.AddDocumentDialogContent>
            <S.AddDocumentsDialogActions >
                <S.DialogActionsBox>
                    {(pageView === INFO_VIEW && !isTemplatePage) &&
                        <S.SmallTextButton startIcon={<ArrowBackIosIcon style={{fontSize: 'small'}} />}
                                           onClick={() => setPageView(START_DOCUMENT_ITEM)}>{'Back'}</S.SmallTextButton>
                    }

                    {/*<S.CloseButton onClick={handleClose}>{'Close'}</S.CloseButton>*/}

                    { (pageView === START_DOCUMENT_ITEM || pageView === TEMPLATE_GALLERY_ITEM) &&
                        <S.SmallOutlinedButton endIcon={<ArrowForwardIosIcon style={{fontSize: 'small'}}/>}
                                               disabled={isNextDisabled}
                                               onClick={handleNext}>{'Next'}</S.SmallOutlinedButton>
                    }

                    { pageView === INFO_VIEW &&
                        // <S.SmallOutlinedButton disabled={!form.folderId.length} onClick={handleCreateNewDoc}>{'Create'}</S.SmallOutlinedButton>

                        <LoadingButton size={'small'} sx={{ml: 'auto'}} variant={'contained'}
                                       disabled={!form.folderId.length || isLoadingSaveDoc || Object.values(recipientsError).some((item) => item)}
                                       onClick={handleCreateNewDoc} loading={isLoadingSaveDoc}
                                       loadingPosition={isLoadingSaveDoc ? 'end' : undefined}
                                       endIcon={isLoadingSaveDoc ? <div style={{width: '10px', height: '10px', marginLeft: revDir ? '12px' : undefined, marginRight: !revDir ? '12px' : undefined }}/> : undefined}
                        >
                            {'Create'}
                        </LoadingButton>
                    }
                </S.DialogActionsBox>

            </S.AddDocumentsDialogActions>
        </S.AddFileDialog>
    )

}





