import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {VendorInput} from "../../../../../newShared/GQLTypes";
import {isLoadingUpdateVendor, selectedVendorSelector} from "../../store/slice";
import {updateVendorAction} from "../../store/actions";
import {TCollaborator} from "../../../../../newShared/components/AutocompleteCollaborators/types";

export const useExactEvaluation = () => {
    const dispatch = useDispatch();

    const selectedVendor = useSelector(selectedVendorSelector);
    const isLoadingUpdate = useSelector(isLoadingUpdateVendor)

    const [isEvaluationEdit, setEvaluationEdit] = useState<boolean>(false);
    const [tmpVendor, setTmpVendor] = useState<VendorInput | null>(selectedVendor);

    const editModeEvaluation = () => {
        setEvaluationEdit(!isEvaluationEdit);
    }

    const updateVendor = () => {
        tmpVendor && dispatch(updateVendorAction({
            organizationId: '',
            workspaceId: '',
            employee:{
                ...tmpVendor
            }
        }));
    };

    const changeEvaluator = (evaluator: TCollaborator) : void =>{
        setTmpVendor(prev => prev ? {...prev, evaluation: {
                evaluator: (evaluator),
                price: prev.evaluation?.price || 0,
                service: prev.evaluation?.service || 0,
                quality: prev.evaluation?.quality || 0,
                notesAndRecommendations: prev.evaluation?.notesAndRecommendations || '',
            }} : null)
    }
    const changePrice = (price: number | null) : void => {
        //eslint-disable-next-line
        {setTmpVendor(prev => prev ? {...prev, evaluation: {
                        evaluator: prev.evaluation?.evaluator || null,
                        price: price || 0,
                        service: prev.evaluation?.service || 0,
                        quality: prev.evaluation?.quality || 0,
                        notesAndRecommendations: prev.evaluation?.notesAndRecommendations || '',
                    }} : null)}
    }
    const changeQuality = (quality : number | null) : void => {
        setTmpVendor(prev => prev ? {...prev, evaluation: {
                evaluator: prev.evaluation?.evaluator || null,
                price: prev.evaluation?.price || 0,
                service: prev.evaluation?.service || 0,
                quality: quality || 0,
                notesAndRecommendations: prev.evaluation?.notesAndRecommendations || '',
            }} : null)}
    const changeService = (service : number | null) : void => {
        setTmpVendor(prev => prev ? {...prev, evaluation: {
                evaluator: prev.evaluation?.evaluator || null,
                price: prev.evaluation?.price || 0,
                service: service || 0,
                quality: prev.evaluation?.quality || 0,
                notesAndRecommendations: prev.evaluation?.notesAndRecommendations || '',
            }} : null)
    }
    const changeNotesAndRecommendations = (value: string | null) : void => {
        setTmpVendor(prev => prev ? {...prev, evaluation: {
                evaluator: prev.evaluation?.evaluator || null,
                price: prev.evaluation?.price || 0,
                service: prev.evaluation?.service || 0,
                quality: prev.evaluation?.quality || 0,
                notesAndRecommendations: value|| '',
            }} : null)
    }

    useEffect(() => {
        !isLoadingUpdate && setTmpVendor(selectedVendor)
        // eslint-disable-next-line
    },[selectedVendor])

    return {
        isEvaluationEdit,
        editModeEvaluation,
        updateVendor,
        isLoadingUpdate,
        selectedVendor,
        tmpVendor,
        setTmpVendor,
        changeEvaluator,
        changePrice,
        changeQuality,
        changeService,
        changeNotesAndRecommendations
    }
}
