import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton} from "@mui/material";
import React from "react";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloseIcon from "@mui/icons-material/Close";
import {FileDrop} from "react-file-drop";
import {useActionUploadDialog} from "../../../hooks/dialogs/useActionUploadDialog";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {Typo} from "../../../../../../newShared/components/Typography";
import colors from "../../../../../../newShared/theme/colors";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";

export const TaskActionUploadDialog = () => {
    const {
        // task,
        action,
        isOpen,
        handleClose,
        fileNames,
        fileHandler,
        filePicker,
        ref,
        handleDeleteFile,
        handleSubmit,
        isLoadingUploadFile,
        isOk
    } = useActionUploadDialog();
    const {t} = useMainTranslation('', {keyPrefix: 'pathVica.TasksList.dialogs'});

    return(
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{`${action?.title}`}</DialogTitle>

            <DialogContent>
                <Flex w={'100%'} direction={'column'}>
                    <Flex w={'100%'} direction={'column'} jc={'center'} overflowy={'auto'} maxh={'400px'}>
                        {fileNames.map((file, i, arr) => (
                            <div key={file}>
                                <Flex w={'100%'} ai={'center'} jc={'space-between'} m={'0 0 5px 0'} p={'5px'}>
                                    <Flex ai={'center'}>
                                        <InsertDriveFileIcon />
                                        <Typo margin={'0 0 0 5px'}>{file}</Typo>
                                    </Flex>
                                    <IconButton onClick={() => handleDeleteFile(file)}>
                                        <CloseIcon />
                                    </IconButton>
                                </Flex>
                                {i !== arr.length - 1 && <Divider flexItem/>}
                            </div>
                        ))}
                    </Flex>

                    {!fileNames.length &&
                        <FileDrop onTargetClick={filePicker} onDrop={(f) => fileHandler(f)}>
                            <Flex direction={'column'} w={'100%'} minh={'100px'} h={'100px'} jc={'center'} m={'10px 0 0 0'} border={`1px dashed ${colors.primary.blue}`} br={'5px'}>
                                <Flex ai={'center'} jc={'center'} w={'100%'} h={'100%'} p={'15px 0 0 0'}>
                                    <Typo cursor={'pointer'}>
                                        <b style={{color: colors.primary.blue, fontWeight: 400}}>{t('DRAG')} </b>
                                        {t('FILE HERE OR')}
                                        <b style={{color: colors.primary.blue, fontWeight: 400}}> {t('BROWSE')}</b>
                                    </Typo>
                                </Flex>

                                <input
                                    // accept={ACCEPT_UPLOAD_FILES}
                                    value=""
                                    style={{ visibility: "hidden", opacity: 0 }}
                                    ref={ref}
                                    // multiple
                                    type="file"
                                    onChange={(e) => fileHandler(e.target.files)}
                                />
                            </Flex>
                        </FileDrop>
                    }
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{t('Close')}</Button>
                <LoadingButton loading={isLoadingUploadFile} onClick={handleSubmit} disabled={!isOk()}>{t('Upload')}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}