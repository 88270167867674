import React, {FC} from "react";
import {useDispatch, useSelector} from "react-redux";
import {preferToOpen, selectedAutomation} from "../../store/slice";
import {Divider, TablePagination} from "@mui/material";
import {automationType} from "../../types";
import {AutomationHistoryTable} from "./table";
import {GetAutomationHistory} from "../../store/actions";

//icons
import CachedIcon from '@mui/icons-material/Cached';
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../../../newShared/components/Layouts";


export const AutomationHistory:FC = () => {
    const dispatch = useDispatch();
    const { revDir} = useMainTranslation();
    const auto: automationType | null = useSelector(selectedAutomation);
    const {history, pipeline} = useSelector(preferToOpen);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number,) => {
        if(auto){
            dispatch(GetAutomationHistory({data: {id: auto.id, page: newPage, count: 5}}));
        }
    };

    // const onClickPrefer = (status: boolean) => {dispatch(setPreferHistory(status))};

    //reload button puts page:0 because only one automation can be executed at once, so history of curr executor will be on first page
    const handleReloadHistory = () => auto && dispatch(GetAutomationHistory({data: {id: auto.id, page: 0, count: 5}}));

    if(!auto || !auto?.id) return <></>;

    return(auto &&
        <Flex w={'100%'} direction={'column'} ai={'center'}>
            <Flex jc={'space-between'} ai={'center'} w={'100%'}>
                <Flex ai={'center'}>
                    <TablePagination
                        component="div"
                        count={auto.pageInfo.total}
                        page={auto.pageInfo.page}
                        onPageChange={handleChangePage}
                        rowsPerPage={auto.pageInfo.count}
                        rowsPerPageOptions={[]}
                        // onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                    <CachedIcon style={{cursor: 'pointer', margin: !revDir ? '0 0 0 10px' : '0 10px 0 0', color: '#FAC000'}} onClick={handleReloadHistory}/>
                </Flex>

            </Flex>
            <Divider flexItem style={{backgroundColor: '#FAC000'}} />
            {history && <AutomationHistoryTable histories={auto?.history || []} isOnlyHistory={!pipeline && history}/>}

        </Flex>
    )
}