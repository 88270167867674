import {TAppDuty, TShortApplications} from "../../../types";
import {useDispatch} from "react-redux";
import {hideCreateDuty} from "../../../store/slice";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {useSegregationOfDuties} from "../../useSegregationOfDuties";
import {createAppDutyAction} from "../../../store/actions";

export const useAddDutyDialog = () => {
    const dispatch = useDispatch();

    const {
        appDuties,
        shortRoles,
        categories,
        shortApplications,
        getRoleNamesByAppAccessId,
        loadings: {create: isLoadingCreate, dataForDialog: isLoadingDialogData},
        dialogs: {createDuty: isOpen},
    } = useSegregationOfDuties();


    const handleClose = () => {
        dispatch(hideCreateDuty());
        cleanUp();
    }


    const initDuty: TAppDuty = {
        id: '',
        name: '',
        description: '',
        category: '',
        application: { applicationId: '', applicationAccessId: '', name: '', type: '' },
        roles: [],
    }

    //form
    const {form, setForm, handleChange} = useForm<TAppDuty>(initDuty);
    const cleanUp = () => setForm(initDuty);

    const handleSetCategory = (category: string) => {
        setForm({...form, category});
    }
    //TextField
    // const handleSetCategory = (e:React.ChangeEvent<HTMLInputElement>) => {
    //     setForm({...form, category: e.target.value});
    // }

    const handleSetRole = (roles: readonly string[]) => {
        // const roles: DutyRoleModel[] = Array.from(new Set(dutyRoles));
        setForm(prev => prev && ({...prev, roles: roles.map(e => ({role: e, permissions: null}))}));
    }

    const handleSetApplication = (app: TShortApplications) => {
        app && setForm({...form, application: app, roles: app.applicationAccessId !== form.application.applicationAccessId ? [] : form.roles});
        getRoleNamesByAppAccessId(app.applicationAccessId);
    }

    const handleSubmit = () => {
        if(isOk()){
            dispatch(createAppDutyAction({data: {duty: form, organizationId: '', workspaceId: ''}}))
            handleClose();
        }
    }

    const isAlreadyExists = appDuties.find(e => e?.id === form.application.applicationAccessId)?.duties.some(e => e.name === form.name);

    const isOk = () => {
        return form.name.trim().length > 0 && form.category.length > 0 && form.application.applicationAccessId.trim().length > 0 && !isAlreadyExists && form.roles.length;
    }

    return{
        isOpen,
        shortApplications,
        categories,
        rolesVariants: shortRoles,
        handleClose,
        handleSubmit,
        handleSetCategory,
        handleSetApplication,
        handleSetRole,

        handleChange,
        form,
        setForm,
        isOk: isOk(),
        isAlreadyExists,

        isLoadingCreate,
        isLoadingDialogData
    }
}