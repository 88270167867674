import {RisksTableProps, RiskTableRowProps, tControl, TRiskModelProbability} from "../../types";
import React from "react";
import {Avatar, AvatarGroup, Box, Button, IconButton, Typography} from "@mui/material";
import {Flex} from "../../../../../newShared/components/Layouts";
import {controls, firstNoneObject,} from "../../constants";
import colors from "../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import CommonLoading from "../../../../../newShared/components/Basic/CommonLoading";
import InfiniteScroll from "react-infinite-scroller";
import * as S from '../styled';
import {CustomDatePicker} from '../styled';
import {useRiskTableRow} from "../../hooks/useRiskTableRow";
import {useRisksTable} from "../../hooks/useRisksTable";
import {LoadingButton} from "@mui/lab";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import CommonSwitch from "../../../../../newShared/components/Basic/CommonSwitch";
import {getInitialGradeProbabilityLabel, isNullObject} from "../../helpers";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {AddControlOwner} from "../dialogs/addControlOwner";
import {AddAvatarButton} from "../../../../../newShared/components/Basic/AddAvatarButton";
import {getAvatarInitials} from "../../../../../newShared/utils/text";
import {DEFAULT_DATE_FORMAT} from "../../../../../newShared/utils/dateTools";


export const RisksTable = (props: RisksTableProps) => {
    const {model, loadMore, isLoading, hasMore} = props;
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'risks'});
    const {isMobile} = useMedia();

    const {
        actions,
        localRisks,
        neededSave,
        isLoadingUpdate,
        isQuantitative
    } = useRisksTable(props);


    return(
        <>
            {/*ACTIONS*/}
            <Flex w={'100%'} direction={'column'}>
                <S.RisksTableSwitchContainer>
                    <Typography variant={"body2"} sx={{color: colors.text.grey_dark}}>{t('You can represent all values as numbers')}</Typography>
                    <CommonSwitch sx={{margin: !revDir ? '0 0 0 32px' : '0 32px 0 0'}}
                                  label={t('Quantitative')} checked={isQuantitative} onChange={actions.handleChangeRisksQuantitative} />
                </S.RisksTableSwitchContainer>

                <Flex ai={'center'} m={'16px 0 5px 0'}>
                    {isLoadingUpdate ?
                        <LoadingButton
                            loading
                            sx={{paddingLeft: '35px', margin: !revDir ? '0 16px 0 0' : '0 0 0 16px', height: '36px'}}
                            variant="outlined"
                        >{t('Save')}</LoadingButton>
                        :
                        <Button
                            variant={'contained'}
                            disabled={!neededSave || !actions.isOk()}
                            size={isMobile ? 'small' : 'medium'}
                            onClick={actions.handleSaveChanges}
                            sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px', height: '36px'}}
                        >{t('Save')}</Button>
                    }
                    <Button disabled={isLoadingUpdate || !neededSave} variant={'outlined'} size={isMobile ? 'small' : 'medium'} onClick={actions.handleGoBackToOrigins} sx={{height: '36px'}}>{t('Cancel')}</Button>
                </Flex>
            </Flex>

            <Flex w={'100%'} overflow={'auto'} m={'16px 0 0 0'}>
                {/*TABLE*/}
                <Flex w={'100%'} minw={'1700px'} direction={'column'} overflow={'auto'}>
                    {/*HEADER*/}
                    <Flex ai={'center'} w={'100%'} minw={'1700px'} overflow={'auto'} style={{flexShrink: '0'}}>
                        <S.RisksCustomTableCell  width={'5%'} isFirst revDir={revDir}>
                            <Typography fontSize={'10px'} fontWeight={400}>{t('Risk ID')}</Typography>
                        </S.RisksCustomTableCell>

                        <S.RisksCustomTableCell  width={'10%'} revDir={revDir}>
                            <Typography fontSize={'10px'} fontWeight={400}>{t('Name')}</Typography>
                        </S.RisksCustomTableCell>

                        <S.RisksCustomTableCell width={'5%'} revDir={revDir}>
                            <Typography fontSize={'10px'} fontWeight={400}>{t('Type')}</Typography>
                        </S.RisksCustomTableCell>

                        <S.RisksCustomTableCell  width={'10%'} revDir={revDir}>
                            <Typography fontSize={'10px'} fontWeight={400}>{t('Description')}</Typography>
                        </S.RisksCustomTableCell>

                        <S.RisksCustomTableCell width={'20%'} disablePadding revDir={revDir}>
                            <Flex direction={'column'} w={'100%'}>
                                <Flex h={'50%'} w={'100%'} ai={'center'} jc={'center'}
                                      // p={'5px 0'}
                                >
                                    <Typography fontSize={'10px'} fontWeight={400}>{t('Initial risk analysis')}</Typography>
                                </Flex>
                                <Flex w={'100%'} ai={'center'} h={'50%'} jc={'space-between'}>
                                    <S.RisksTableHeaderImpactCell revDir={revDir} order={0}>
                                        <Typography fontSize={'10px'} fontWeight={400}>{t('Probability')}</Typography>
                                    </S.RisksTableHeaderImpactCell>

                                    <S.RisksTableHeaderImpactCell revDir={revDir} order={1}>
                                        <Typography fontSize={'10px'} fontWeight={400}>{t('Impact')}</Typography>
                                    </S.RisksTableHeaderImpactCell>


                                    <S.RisksTableHeaderImpactCell revDir={revDir} order={2}>
                                        <Typography fontSize={'10px'} fontWeight={400}>{t('Rating')}</Typography>
                                    </S.RisksTableHeaderImpactCell>
                                </Flex>
                            </Flex>
                        </S.RisksCustomTableCell>

                        <S.RisksCustomTableCell width={'10%'} revDir={revDir}>
                            <Typography fontSize={'10px'} fontWeight={400}>{t('Control')}</Typography>
                        </S.RisksCustomTableCell>

                        <S.RisksCustomTableCell width={'10%'} revDir={revDir}>
                            <Typography fontSize={'10px'} fontWeight={400}>{t('Action')}</Typography>
                        </S.RisksCustomTableCell>

                        <S.RisksCustomTableCell width={'20%'} disablePadding revDir={revDir}>
                            <Flex direction={'column'} w={'100%'}>
                                <Flex h={'50%'} w={'100%'} ai={'center'} jc={'center'}
                                      // p={'5px 0'}
                                >
                                    <Typography fontSize={'10px'} fontWeight={400}>{t('Residual risk analysis')}</Typography>
                                </Flex>
                                <Flex w={'100%'} ai={'center'} h={'50%'} jc={'space-between'}>
                                    <S.RisksTableHeaderImpactCell revDir={revDir} order={0}>
                                        <Typography fontSize={'10px'} fontWeight={400}>{t('Probability')}</Typography>
                                    </S.RisksTableHeaderImpactCell>

                                    <S.RisksTableHeaderImpactCell revDir={revDir} order={1}>
                                        <Typography fontSize={'10px'} fontWeight={400}>{t('Impact')}</Typography>
                                    </S.RisksTableHeaderImpactCell>


                                    <S.RisksTableHeaderImpactCell revDir={revDir} order={2}>
                                        <Typography fontSize={'10px'} fontWeight={400}>{t('Rating')}</Typography>
                                    </S.RisksTableHeaderImpactCell>
                                </Flex>
                            </Flex>
                        </S.RisksCustomTableCell>

                        <S.RisksCustomTableCell width={'10%'} revDir={revDir}>
                            <Typography fontSize={'10px'} fontWeight={400}>{t('Owners')}</Typography>
                        </S.RisksCustomTableCell>

                        <S.RisksCustomTableCell width={'8%'} revDir={revDir}>
                            <Typography fontSize={'10px'} fontWeight={400}>{t('Scheduler')}</Typography>
                        </S.RisksCustomTableCell>

                        <S.RisksCustomTableCell width={'5%'} revDir={revDir}>
                            <Typography fontSize={'10px'} fontWeight={400}>{t('Options')}</Typography>
                        </S.RisksCustomTableCell>
                    </Flex>

                    {/*BODY*/}
                    <S.MainTableScrollWrapper>
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={(page) => loadMore(page)}
                            hasMore={hasMore}
                            initialLoad={false}
                            threshold={50}
                            useWindow={false}
                        >
                            {localRisks.map(e => e && <RiskTableLineRow
                                risk={e}
                                key={e.id}
                                model={model}
                                actions={actions}
                            />)}
                            {isLoading && <CommonLoading margin={'10px 0 0 0'}/>}
                        </InfiniteScroll>
                    </S.MainTableScrollWrapper>
                </Flex>
            </Flex>

            {/*DIALOGS*/}
            <AddControlOwner onSuccess={actions.setControlOwners}/>
        </>
    )
}


const RiskTableLineRow = ({risk, model, actions}: RiskTableRowProps) => {
    const {
        riskTreatmentControlValue,
        residualGradeImpactValue,
        residualGradeProbabilityValue,
        residualRiskRateColor,
        riskRateColor,
        initialGradeImpactValue,
        isQuantitative,
        initialGradeProbabilityValue,
        modelsList,
        riskRate,
        residualRiskRate,
    } = useRiskTableRow({risk, model, actions});
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'risks'});

    const renderOption = (props: any, option: unknown, type: 'control' | 'other', isQuantitative: boolean) => {
        switch (type){
            case "other": return (
                <li {...props}>
                    <Box display={'flex'} flexDirection={'row'}>
                        <Typography color={'text.primary'} fontSize={'10px'}>
                            {isNullObject(option) ? t('None') : getInitialGradeProbabilityLabel(option, isQuantitative)}
                        </Typography>
                    </Box>
                </li>
            );
            case "control": return (
                <li {...props}>
                    <Box display={'flex'} flexDirection={'row'}>
                        <Typography color={'text.primary'} fontSize={'10px'}>
                            {t((option as tControl).type)}
                        </Typography>
                    </Box>
                </li>
            );
        }
    }

    return(
        <Flex key={risk.id} w={'100%'} ai={'center'} overflow={'hidden'}>
            <S.RisksCustomBodyTableCell width={'5%'} isFirst revDir={revDir}>
                <Typography fontSize={'10px'} fontWeight={400}>{risk.id}</Typography>
            </S.RisksCustomBodyTableCell>

            <S.RisksCustomBodyTableCell width={'10%'} revDir={revDir}>
                <S.RisksCustomTableCellInput type="text" value={risk.name} onChange={(e) => {actions.handleChangeName(risk.id, e.target.value)}}/>
            </S.RisksCustomBodyTableCell>

            <S.RisksCustomBodyTableCell width={'5%'} revDir={revDir}>
                <S.RisksCustomTableCellInput type="text" value={risk.type} onChange={(e) => actions.handleChangeType(risk.id, e.target.value)}/>
            </S.RisksCustomBodyTableCell>

            <S.RisksCustomBodyTableCell width={'10%'} revDir={revDir}>
                <S.RisksCustomTableCellInput type="text" value={risk.description} onChange={(e) => actions.handleChangeDescription(risk.id, e.target.value)}/>
            </S.RisksCustomBodyTableCell>

            <S.RisksCustomBodyTableCell width={'20%'} disablePadding revDir={revDir}>
                <Flex w={'100%'} ai={'center'} h={'100%'} jc={'space-between'}>
                    <Flex
                        w={'33.3%'}
                        ai={'center'}
                        jc={'center'}
                        h={'100%'}
                        m={'12px 0'}
                        style={{borderRight: !revDir ? '1px solid black' : 'initial', borderLeft: !revDir ? 'none' : '1px solid black'}}
                    >
                        {model &&
                            <S.RisksTableAutocomplete
                                disablePortal
                                disabled={modelsList} //loading
                                id="combo-box-demo"
                                fullWidth
                                dir={'ltr'}
                                options={[firstNoneObject, ...model.probabilities] as TRiskModelProbability[]}
                                value={initialGradeProbabilityValue}
                                getOptionLabel={(option: unknown) => isNullObject(option) ? t('None') : getInitialGradeProbabilityLabel(option, isQuantitative)}
                                disableClearable
                                onChange={(e, newValue) => actions.handleSetInitialGradeProbability(risk.id, newValue as TRiskModelProbability | null)}
                                renderInput={(params: any) => <S.RisksTableTextFieldCustom {...params} size="small"/>}
                                renderOption={(props, option) => renderOption(props, option, 'other', isQuantitative)}
                            />
                        }
                    </Flex>
                    <Flex
                        w={'33.3%'}
                        ai={'center'}
                        jc={'center'}
                        h={'100%'}
                        m={'12px 0'}
                        style={{borderRight: !revDir ? '1px solid black' : 'initial', borderLeft: !revDir ? 'none' : '1px solid black'}}
                    >
                        {model &&
                            <S.RisksTableAutocomplete
                                disabled={modelsList} //loading
                                id="combo-box-demo"
                                fullWidth
                                dir={'ltr'}
                                getOptionLabel={(option: unknown) => isNullObject(option) ? t('None') : getInitialGradeProbabilityLabel(option, isQuantitative)}
                                options={[firstNoneObject, ...model.impacts] as TRiskModelProbability[]}
                                value={initialGradeImpactValue}
                                disableClearable
                                onChange={(e, newValue) => actions.handleSetInitialGradeImpact(risk.id, newValue as TRiskModelProbability | null)}
                                renderInput={(params: any) => <S.RisksTableTextFieldCustom {...params} size="small"/>}
                                renderOption={(props, option) => renderOption(props, option, 'other', isQuantitative)}
                            />
                        }
                    </Flex>
                    <Flex
                        w={'33.3%'}
                        ai={'center'}
                        jc={'center'}
                        background={riskRateColor}
                        h={'100%'}
                        m={'12px 0'}
                    >
                        <Typography fontSize={'10px'} fontWeight={400} color={colors.text.white}>{isQuantitative ? ((risk?.initialGrade?.probability ?? 0) * (risk?.initialGrade?.impact ?? 0)) : t(riskRate)}</Typography>
                    </Flex>
                </Flex>
            </S.RisksCustomBodyTableCell>

            <S.RisksCustomBodyTableCell width={'10%'} revDir={revDir}>
                <S.RisksTableAutocomplete
                    disabled={modelsList} //loading
                    id="combo-box-demo"
                    fullWidth
                    getOptionLabel={(option) => t((option as tControl).type)}
                    options={controls as tControl[]}
                    value={riskTreatmentControlValue}
                    disableClearable
                    dir={'ltr'}
                    onChange={(e, newValue) => actions.handleSetControlType(risk.id, newValue as tControl | null)}
                    isOptionEqualToValue={(e, newValue) => (e as tControl).type === (newValue as tControl).type}
                    renderInput={(params: any) => <S.RisksTableTextFieldCustom {...params} size="small" />}
                    renderOption={(props, option) => renderOption(props, option, 'control', isQuantitative)}
                />
            </S.RisksCustomBodyTableCell>

            <S.RisksCustomBodyTableCell width={'10%'} revDir={revDir}>
                <S.RisksCustomTableCellInput type="text" value={risk.control.actions ?? ''} onChange={(e) => actions.handleChangeControlActions(risk.id, e.target.value) }/>
            </S.RisksCustomBodyTableCell>

            <S.RisksCustomBodyTableCell width={'20%'} disablePadding revDir={revDir}>
                <Flex w={'100%'} ai={'center'} h={'100%'} jc={'space-between'}>
                    <Flex
                        w={'33.3%'}
                        ai={'center'}
                        jc={'center'}
                        h={'100%'}
                        m={'12px 0'}
                        style={{borderRight: !revDir ? '1px solid black' : 'initial', borderLeft: !revDir ? 'none' : '1px solid black'}}
                    >
                        {model &&
                            <S.RisksTableAutocomplete
                                disabled={modelsList} //loading
                                id="combo-box-demo"
                                fullWidth
                                dir={'ltr'}
                                options={[firstNoneObject, ...model.probabilities] as TRiskModelProbability[]}
                                getOptionLabel={(option: unknown) => isNullObject(option) ? t('None') : getInitialGradeProbabilityLabel(option, isQuantitative)}
                                value={residualGradeProbabilityValue}
                                disableClearable
                                // onChange={(e, newValue) => null}
                                onChange={(e, newValue) => actions.handleSetResidualGradeProbability(risk.id, newValue as TRiskModelProbability | null)}
                                renderInput={(params: any) => <S.RisksTableTextFieldCustom {...params} size="small"  />}
                                renderOption={(props, option) => renderOption(props, option, 'other', isQuantitative)}
                            />
                        }
                    </Flex>
                    <Flex
                        w={'33.3%'}
                        ai={'center'}
                        jc={'center'}
                        h={'100%'}
                        m={'12px 0'}
                        style={{borderRight: !revDir ? '1px solid black' : 'initial', borderLeft: !revDir ? 'none' : '1px solid black'}}
                    >
                        {model &&
                            <S.RisksTableAutocomplete
                                disabled={modelsList} //loading
                                id="combo-box-demo"
                                fullWidth
                                dir={'ltr'}
                                options={[firstNoneObject, ...model.impacts] as TRiskModelProbability[]}
                                getOptionLabel={(option: unknown) => isNullObject(option) ? t('None') : getInitialGradeProbabilityLabel(option, isQuantitative)}
                                value={residualGradeImpactValue}
                                disableClearable
                                onChange={(e, newValue) => actions.handleSetResidualGradeImpact(risk.id, newValue as TRiskModelProbability | null)}
                                renderInput={(params: any) => <S.RisksTableTextFieldCustom {...params} size="small" />}
                                renderOption={(props, option) => renderOption(props, option, 'other', isQuantitative)}
                            />
                        }
                    </Flex>
                    <Flex
                        w={'33.3%'}
                        ai={'center'}
                        jc={'center'}
                        background={residualRiskRateColor}
                        h={'100%'}
                        m={'12px 0'}
                    >
                        <Typography fontSize={'10px'} fontWeight={400} color={colors.text.white}>{isQuantitative ? ((risk?.residualGrade?.probability ?? 0) * (risk?.residualGrade?.impact ?? 0)) : t(residualRiskRate)}</Typography>
                    </Flex>
                </Flex>
            </S.RisksCustomBodyTableCell>

            <S.RisksCustomBodyTableCell width={'10%'} revDir={revDir}>
                <>
                    <AvatarGroup
                        max={4}
                        componentsProps={{
                            additionalAvatar: {
                                onClick: (e) => {
                                    e.stopPropagation();
                                    actions.handleOpenAddOwners(risk.id);
                                }
                            }
                        }}
                        sx={{
                            '& .MuiAvatar-root': {
                                maxWidth: '24px', maxHeight: '24px',
                                fontSize: '10px',
                            },
                            '& .MuiAvatar-root:hover': {
                                cursor: 'pointer'
                            },
                            '& .MuiAvatar-root:last-child': {
                                marginLeft: !revDir ? undefined : '-8px'
                            },
                        }}
                    >

                        {risk.control.owners.map((e, id, arr) => {
                            return(
                                <Avatar key={e} sx={{ width: 24, height: 24, backgroundColor: ((id % 2) !== 0) ? colors.primary.blue : colors.text.success}}>
                                    <Typography color={colors.text.white} fontSize={'10px'} fontWeight={400}>{getAvatarInitials(e)}</Typography>
                                </Avatar>
                            )
                        })}
                    </AvatarGroup>

                    <AddAvatarButton onClick={() => actions.handleOpenAddOwners(risk.id)}
                                     btnSize={'d24'}
                                     sx={{marginRight: !revDir ? undefined : '8px'}}
                    />
                </>
            </S.RisksCustomBodyTableCell>

            <S.RisksCustomBodyTableCell width={'8%'} disablePadding revDir={revDir}>
                <CustomDatePicker
                    inputFormat={DEFAULT_DATE_FORMAT}
                    value={risk.control.scheduler}
                    onChange={(newValue: unknown) => newValue && actions.handleSetControlScheduler(risk.id, newValue)}
                    renderInput={(params) => <S.RisksTableTextFieldCustom {...params} size="small" />}
                />
            </S.RisksCustomBodyTableCell>

            <S.RisksCustomBodyTableCell width={'5%'} revDir={revDir}>
                <IconButton onClick={() => actions.handleDeleteRisk(risk.id)} sx={{padding: 0}}>
                    <DeleteOutlineIcon fontSize={'small'}/>
                </IconButton>
            </S.RisksCustomBodyTableCell>
        </Flex>
    )
}