import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {LoadingButton} from "@mui/lab";
import colors from "../../../../../../newShared/theme/colors";
import {useDispatch, useSelector} from "react-redux";
import {hideDeleteDialog, isLoadingDelete, selectedVendorSelector, vendorsDialogsSelector} from "../../../store/slice";
import {DeleteVendorById} from "../../../store/actions";
import {useHistory} from "react-router-dom";
import {PATH_LOCAL_VENDORS} from "../../../constants";

export const DeleteVendor = () => {
    const dispatch = useDispatch();
    const {revDir, t} = useMainTranslation('',{keyPrefix:'pathVendors.ExactVendor'});
    const history = useHistory();
    const isLoading = useSelector(isLoadingDelete);
    const selected = useSelector(selectedVendorSelector);
    const {deleteVendor} = useSelector(vendorsDialogsSelector);

    const handleClose = () => {
        dispatch(hideDeleteDialog());
    }

    const onSuccess = () => history.push(PATH_LOCAL_VENDORS);

    const handleSubmit = () => {
        selected && dispatch(DeleteVendorById({id: selected.id, onSuccess}));

    }

    return(
        <Dialog open={deleteVendor.isOpen} onClose={handleClose}>
            <DialogTitle>{t(`Delete {{name}} vendor`, {name: selected?.name ?? ''})}</DialogTitle>

            <DialogContent>
                <Typography variant={'body2'} color={colors.darkBlueText}>
                    {t('Are you sure you want delete this vendor?')}
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                >
                    {t('Delete')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}