import {
    FlexColumn,
    FlexRow
} from "../../../../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import {Button, Icon, Typography} from "@mui/material";
import colors from "../../../../../../../../newShared/theme/colors";
import {Add} from "@mui/icons-material";
import React, {FC, RefObject, useCallback, useEffect, useRef} from "react";
import {MainTrainingExamFormik} from "../../../../../types";
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {FormikErrors} from "formik";
import {TrainingExamQuestionInput} from "../../../../../../../../newShared/GQLTypes";
import {useCustomFormik} from "../../../../../../../../newShared/hooks/useCustomFormik";
import {ExamViewQuestionOption} from "../examViewQuestionOption";

type ExamViewQuestionCheckboxesRowsProps = {
    questionIndex: number;
    masterFormik: ReturnType<typeof useCustomFormik<MainTrainingExamFormik>>;
    handleAddOption: () => void;
    moveOptionRef: RefObject<(dragId: string, toId: string) => void>;
    handleRemoveOptionRef: RefObject<(optionId: string) => void>;
}
export const ExamViewQuestionCheckboxesRows: FC<ExamViewQuestionCheckboxesRowsProps> = React.memo(({questionIndex, masterFormik: formik, handleAddOption, moveOptionRef, handleRemoveOptionRef}) => {
    const {t} = useMainTranslation();

    const row = formik.values.questions[questionIndex];

    const commonOptionError = typeof (formik.errors.questions?.[questionIndex] as FormikErrors<TrainingExamQuestionInput>)?.options === 'string' && (formik.errors.questions?.[questionIndex] as FormikErrors<TrainingExamQuestionInput>)?.options;
    const commonOptionTouched = formik.touched.questions?.[questionIndex]?.options;

    const setOptionChecked = useCallback((optionId: string, checked: boolean) => {
        const optionIndex = row.options.findIndex(option => option.optionId === optionId);
        if (optionIndex === -1) return;

        formik.setFieldValue(`questions[${questionIndex}]options[${optionIndex}]`, {
            ...row.options[optionIndex],
            correct: checked,
            points: row.pointsAutoCount && checked ? 5 : 0,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [row.options, questionIndex, row.pointsAutoCount]);

    const setOptionCheckedRef = useRef(setOptionChecked);

    useEffect(() => {
        setOptionCheckedRef.current = setOptionChecked;
    }, [setOptionChecked]);

    return (
        <FlexColumn sx={{flexShrink: 0, width: '100%', gap: '16px'}}>
            <FlexRow alignItems={'center'}>
                <Typography variant={'*bodyText2_semibold'} noWrap sx={{flexGrow: 1}}
                            color={commonOptionTouched && commonOptionError ? colors.text.critical : colors.text.grey_dark}>
                    {t('Add answer options and mark the correct one.')}
                </Typography>

                {!!row.options.length &&
                    <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark} noWrap>
                        {`${t('Assign points for correct answers')}:`}
                    </Typography>
                }

                <Icon/>
            </FlexRow>

            <FlexColumn sx={{flexShrink: 0, width: '100%', gap: '4px'}}>
                {row.options.map((option, optionIndex) => (
                    <ExamViewQuestionOption key={option.optionId} questionIndex={questionIndex} optionIndex={optionIndex} masterFormik={formik} setOptionCheckedRef={setOptionCheckedRef}
                                            moveOptionRef={moveOptionRef} handleRemoveOptionRef={handleRemoveOptionRef}/>
                ))}
            </FlexColumn>

            <Button variant={"text"} size={"small"} startIcon={<Add/>} sx={{width: 'fit-content'}}
                    onClick={handleAddOption}>
                {t('Add option')}
            </Button>
        </FlexColumn>
    )}, (prevProps, nextProps) => (
    prevProps.questionIndex === nextProps.questionIndex

    && JSON.stringify(prevProps.masterFormik.values.questions[prevProps.questionIndex].pointsAutoCount) === JSON.stringify(nextProps.masterFormik.values.questions[nextProps.questionIndex].pointsAutoCount)

    && JSON.stringify(prevProps.masterFormik.values.questions[prevProps.questionIndex].options) === JSON.stringify(nextProps.masterFormik.values.questions[nextProps.questionIndex].options)
    && JSON.stringify((prevProps.masterFormik.errors.questions?.[prevProps.questionIndex] as FormikErrors<TrainingExamQuestionInput>)?.options) === JSON.stringify((nextProps.masterFormik.errors.questions?.[nextProps.questionIndex] as FormikErrors<TrainingExamQuestionInput>)?.options)
    && JSON.stringify(prevProps.masterFormik.touched.questions?.[prevProps.questionIndex]?.options) === JSON.stringify(nextProps.masterFormik.touched.questions?.[nextProps.questionIndex]?.options)
));