import {FC} from "react";
import {MicroTableWithoutBorder} from "../../../../../newShared/components/microTableWithoutBorder";
import {Divider, Typography} from "@mui/material";
import {
    AssetsRequestStatus,
    AssetsRequestType,
    MainAssetsRequestModel,
    MainAssetsRequestsAssetModel
} from "../../../../../newShared/GQLTypes";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {getAssetsRequestStatusChip, getPhysicalAssetStatusChip} from "../../helpers";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {assetRequestTypeMapper} from "../../constants";

type TProps = {
    request: MainAssetsRequestModel | null,
    asset: MainAssetsRequestsAssetModel | null,
    isLoading: boolean
}
export const AssetRequestPhysicalExact: FC<TProps> = ({request, asset, isLoading}) => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets.pathIncomingRequests'});

    const id = request?.data?.find((a) => a.fieldName === 'assetId')?.fieldValue;
    const serial = request?.data?.find((a) => a.fieldName.toLowerCase() === 'serial')?.fieldValue;
    const model = request?.data?.find((a) => a.fieldName.toLowerCase() === 'model')?.fieldValue;
    const category = request?.data?.find((a) => a.fieldName.toLowerCase() === 'category')?.fieldValue;
    const manufacture = request?.data?.find((a) => a.fieldName.toLowerCase() === 'manufacture')?.fieldValue;
    return (
        <>
            <Typography variant={"*bodyText1_semibold"}>
                {`${t('Request details')}: `}
            </Typography>

            <MicroTableWithoutBorder
                rows={[
                    {label: t('Status') + ':', text: getAssetsRequestStatusChip(request?.status || AssetsRequestStatus.Created)},
                    {label: t('Request type') + ':', text: request ? assetRequestTypeMapper[request?.type] : ''},
                    {label: t('Requestor') + ':', text: request?.requestor.fullName},
                    {label: t('Request date') + ':', text: parseDateAuto(request?.requestDate, false, undefined, undefined, '-')},
                    id ? {label: t('ID') + ':', text: id || '-'} : null,
                    serial ? {label: t('Serial') + ':', text: serial || '-'} : null,
                    {label: t('Model') + ':', text: model || '-'},
                    {label: t('Category') + ':', text: category || '-'},
                    {label: t('Manufacturer') + ':', text: manufacture || '-'},
                    {label: t('Comment') + ':', text: request?.comment || '-'},
                ]}
                isLoading={isLoading}
            />

            {asset && request?.status === AssetsRequestStatus.Created && request?.type !== AssetsRequestType.Remove && (
                <>
                    <Divider flexItem variant={"fullWidth"}/>

                    <Typography variant={"*bodyText1_semibold"}>
                        {`${t('Existing asset details')}: `}
                    </Typography>

                    <MicroTableWithoutBorder
                        rows={[
                            {label: `${t('ID')}:`, text: asset?.assetId || '-'},
                            {label: `${t('Serial')}:`, text: asset?.serial || '-'},
                            {label: `${t('Status')}:`, text: asset?.status ? getPhysicalAssetStatusChip(asset?.status) : '-'},

                            request?.status === AssetsRequestStatus.Created
                                ? {label: `${t('Assigned to')}:`, text: asset?.assignedTo || '-'}
                                : null,
                        ]}
                        isLoading={isLoading}/>
                </>
            )}

            {(request?.status === AssetsRequestStatus.Approved || request?.status === AssetsRequestStatus.Rejected) && (
                <>
                    <Divider flexItem variant={"fullWidth"}/>

                    <Typography variant={"*bodyText1_semibold"}>
                        {request?.status === AssetsRequestStatus.Approved ? `${t('Approved info')}: ` : `${t('Rejected info')}: `}
                    </Typography>

                    <MicroTableWithoutBorder
                        rows={[
                            {label: `${t('Resolver')}:`, text: request?.resolver?.fullName || '-'},
                            {label: `${t('Resolved date')}:`, text: parseDateAuto(request?.resolveDate, false, undefined, undefined, '-')},
                            {label: `${t('Comment')}:`, text: request.resolution || '-'},
                        ]}
                        isLoading={isLoading}/>
                </>
            )}
        </>
)
}
