import {useDispatch, useSelector} from "react-redux";
import {dialogs, loadings, setResolveIssueDialogAction} from "../../../store/slice";
import React, {useEffect, useState} from "react";
import {useHrIssues} from "../../useHrIssues";


export const useResolveIssue = () => {

    const dispatch = useDispatch();
    const {resolveIssue: {issueId}} = useSelector(dialogs);
    const {setIssueStatusResolved} = useHrIssues();


    const {issueChangeStatus} = useSelector(loadings);

    const [resolution, setResolution] = useState<string>('');

    const handleSetResolution = (event: React.ChangeEvent<HTMLInputElement>) => {
        setResolution(event.target.value);
    }

    const handleClose = () => {
        dispatch(setResolveIssueDialogAction({clear: true}))
    }

    const handleSave = () => {
        issueId && resolution.trim().length && setIssueStatusResolved(issueId, resolution);
    }

    useEffect(() => {
        issueId && setResolution('');

        //eslint-disable-next-line
    }, [issueId]);

    return {
        isOpen: Boolean(issueId),
        isLoading: issueChangeStatus,
        resolution,
        handleSetResolution,
        handleClose,
        handleSave,

    }
}
