import {GenericPageBeautyPropsType} from "./types";
import {useGenericPage} from "./hooks";
import {
    Grid,
    IconButton,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import colors from "../../theme/colors";
import React, {Fragment} from "react";
import {Flex} from "../Layouts";
import {ViewSettingsDialog} from "./components/viewSettingsDialog/viewSettingsDialog";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ListIcon from '@mui/icons-material/List';
import GridViewIcon from '@mui/icons-material/GridView';
import {DynamicHeader} from "./components/dynamicHeader";
import {DynamicRowSkeleton} from "./components/dynamicRowSkeleton";
import {DynamicRow} from "./components/dynamicRow";
import {DynamicCard} from "./components/dynamicCard";
import {DynamicCardSkeleton} from "./components/dynamicCardSkeleton";
import {DEFAULT_PAGING} from "./constants";
import {GenericFiltersArray} from "../genericFilter";
import {EmptyArrayImage} from "../EmptyArrayImage";
import {useMainTranslation} from "../../hooks/useMainTranslationHooks/useMainTranslation";

export function GenericTable<T extends Object, P extends Object | any = undefined>(props: GenericPageBeautyPropsType<T, P>){
    const {lastSelectedId, onRowClick, scrollRef, rowsIds, viewSettings, onScroll, hasMore, emptyArrayImage, markRow} = useGenericPage<T, P>(props);
    const {
        columnsConfig: {totalName = 'Total', totalNumber, additionalTotalName, additionalTotalNumber, disableColumnsSettings = false, disableShowCards = false, forceShowCardsWhen = false},
        paging: {pageInfo, minLoadedPage, isLoading},
        rowsConfig: {rows, getRowUniqueId, rowActionsConfig, defaultPagingCount = DEFAULT_PAGING, customCellPaddings, columnsConfigs},
        filtersConfig: {genericFilterProps},
        disableSkeletons = false,
        disableEmptyFilterResultTextRow,
    } = props;

    const {t} = useMainTranslation('', { keyPrefix: 'genericFilter' })

    return(
        <Fragment>
            {genericFilterProps && <GenericFiltersArray {...genericFilterProps}/>}

            {(!emptyArrayImage.isShow && !forceShowCardsWhen ? viewSettings.rowsView : false) ?
                <Flex position={'relative'} overflow={'hidden'}>
                    <Flex w={'100%'} ai={'center'} gap={'8px'} position={'absolute'} top={'16px'} zi={'1002'} p={'5px 4px 5px 4px'} jc={'space-between'}>
                        <Flex ai={'center'} p={!disableColumnsSettings ? undefined : '8px 8px 0 0'} gap={'8px'}>
                            {!disableColumnsSettings &&
                                <IconButton
                                    onClick={viewSettings.handleOpenViewSettings}
                                    size={'small'}
                                    color={'primary'}
                                    disabled={emptyArrayImage.isShow}
                                >
                                    <SettingsOutlinedIcon />
                                </IconButton>
                            }
                            <Typography variant={'*bodyText2'} color={colors.text.grey}>{`${totalName}: ${totalNumber ?? pageInfo.total}`}</Typography>
                            {additionalTotalName && <Typography variant={'*bodyText2'} color={colors.text.grey}>{`${additionalTotalName}: ${additionalTotalNumber ?? pageInfo.total}`}</Typography>}
                        </Flex>

                        {(!disableShowCards && !forceShowCardsWhen) &&
                            <Flex ai={'center'} gap={'8px'}>
                                <IconButton disabled={emptyArrayImage.isShow} onClick={viewSettings.toggleRowsView} size={'small'} color={viewSettings.rowsView ? 'primary' : 'default'}>
                                    <ListIcon/>
                                </IconButton>

                                <IconButton disabled={emptyArrayImage.isShow} onClick={viewSettings.toggleRowsView} size={'small'} color={viewSettings.rowsView ? 'default' : 'primary'}>
                                    <GridViewIcon/>
                                </IconButton>
                            </Flex>
                        }
                    </Flex>

                    <TableContainer onScroll={onScroll} ref={scrollRef}>
                        <Table stickyHeader>

                            {<DynamicHeader
                                    columnConfig={viewSettings.selectedColumns}
                                    isShowColumnSettings={!disableColumnsSettings}
                                    isActionCellExists={Boolean(rowActionsConfig && rowActionsConfig.length > 0)}
                                />
                            }

                            <TableBody sx={{'& .MuiTableRow-root:last-child td': {borderBottom: `1px solid ${colors.primary.disable}`}}}>
                                {minLoadedPage > 0 && !disableSkeletons && <DynamicRowSkeleton<T>
                                    isTop
                                    configs={viewSettings.selectedColumnsWithValueGetter}
                                    isActionCellExists={Boolean(rowActionsConfig && rowActionsConfig.length > 0)}
                                />}

                                {rows.length === 0 && !isLoading && !disableEmptyFilterResultTextRow &&
                                    <TableRow>
                                        <TableCell colSpan={columnsConfigs.length}>{t('No results were found for your search query.')}</TableCell>
                                    </TableRow>
                                }

                                {rows.map((e, index, arr) => {
                                    const rowId = getRowUniqueId(e);
                                    const isPageBreak = (index % defaultPagingCount) === 0;
                                    const currentPage = minLoadedPage + (index - index % defaultPagingCount) / defaultPagingCount;
                                    const isNextPageBreak = ((index + 1) % defaultPagingCount) === 0;
                                    const isIdAlreadyExists = rowsIds.filter(f => f === rowId).length > 1;

                                    return (
                                        <Fragment key={rowId}>
                                            {/*{isPageBreak && minLoadedPage !== currentPage ? <Flex css={`display: table-row`} w={'100%'} data-page={`page-${currentPage}`} position={'relative'}>*/}
                                            {/*    <Flex position={'absolute'} w={'100%'} top={'-10px'} zi={'1000'} jc={'center'} cursor={'default'} css={`user-select: none;`}>*/}
                                            {/*        <Flex background={colors.backgrounds.white} border={`1px solid ${colors.stroke.table}`} br={'4px'} p={'2px 6px'}>*/}
                                            {/*            <Typography fontSize={'10px'} color={colors.text.grey}>Page {currentPage + 1}</Typography>*/}
                                            {/*        </Flex>*/}
                                            {/*    </Flex>*/}
                                            {/*</Flex> : <div data-page={`page-${currentPage}`}></div>}*/}
                                            {isPageBreak && <div data-page={`page-${currentPage}`}></div>}
                                            <DynamicRow<T>
                                                markRow={markRow}
                                                getRowId={getRowUniqueId}
                                                isAlreadyExists={isIdAlreadyExists}
                                                index={index}
                                                page={currentPage}
                                                isPageBreak={isPageBreak}
                                                row={e}
                                                configs={viewSettings.selectedColumnsWithValueGetter}
                                                onRowClick={onRowClick}
                                                isSelected={rowId === lastSelectedId}
                                                rowActionsConfig={rowActionsConfig}
                                                customCellPaddings={customCellPaddings}
                                            />
                                            {isNextPageBreak && <div data-page={`page-${currentPage}-end`}></div>}
                                        </Fragment>
                                    )
                                })}

                                {(hasMore || isLoading) && !disableSkeletons && <DynamicRowSkeleton
                                    isTop={false}
                                    configs={viewSettings.selectedColumnsWithValueGetter}
                                    isActionCellExists={Boolean(rowActionsConfig && rowActionsConfig.length > 0)}
                                />}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Flex>
                :
                <Flex w={'100%'} h={'100%'} direction={'column'} position={'relative'} overflow={'hidden'}>
                    <Flex background={colors.backgrounds.white} w={'100%'} ai={'center'} gap={'8px'} position={'absolute'} top={'16px'} zi={'1002'} p={'5px 4px 5px 4px'} jc={'space-between'}>
                        <Flex ai={'center'}>
                            {!disableColumnsSettings &&
                                <IconButton
                                    disabled={emptyArrayImage.isShow}
                                    onClick={viewSettings.handleOpenViewSettings}
                                    size={'small'}
                                    color={'primary'}
                                >
                                    <SettingsOutlinedIcon />
                                </IconButton>
                            }
                            <Typography variant={'*bodyText2'} color={colors.text.grey}>{`${totalName}: ${totalNumber ?? pageInfo.total}`}</Typography>
                            {additionalTotalName && <Typography variant={'*bodyText2'} color={colors.text.grey}>{`${additionalTotalName}: ${additionalTotalNumber ?? pageInfo.total}`}</Typography>}
                        </Flex>

                        {(!disableShowCards && !forceShowCardsWhen) &&
                            <Flex ai={'center'} gap={'8px'}>
                                <IconButton
                                    disabled={emptyArrayImage.isShow}
                                    onClick={viewSettings.toggleRowsView}
                                    size={'small'}
                                    color={viewSettings.rowsView ? 'primary' : 'default'}
                                >
                                    <ListIcon/>
                                </IconButton>

                                <IconButton
                                    disabled={emptyArrayImage.isShow}
                                    onClick={viewSettings.toggleRowsView}
                                    size={'small'}
                                    color={viewSettings.rowsView ? 'default' : 'primary'}
                                >
                                    <GridViewIcon/>
                                </IconButton>
                            </Flex>
                        }
                    </Flex>

                    {emptyArrayImage.isShow && <Flex position={'absolute'} top={'0'} left={'0'} w={'100%'} h={'100%'}>
                        <EmptyArrayImage type={emptyArrayImage.type}
                                         isShowing={emptyArrayImage.isShow}
                                         {...emptyArrayImage.getProps()}
                        />
                    </Flex>}

                    <Grid container
                          onScroll={onScroll}
                          ref={scrollRef}
                          sx={{margin: '45px 0 16px 0', overflow: 'auto', width: '100%'}}
                          spacing={2}
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="inherit"
                    >
                        {minLoadedPage > 0 && !disableSkeletons && <Flex w={'100%'} m={'16px 0 0 0'} data-skeleton={'top'}>
                            <Skeleton width={'100%'} height={50}/>
                        </Flex>}

                        {rows.map((e, index) => {
                            const rowId = getRowUniqueId(e);
                            const isPageBreak = (index % defaultPagingCount) === 0;
                            const currentPage = minLoadedPage + (index - index % defaultPagingCount) / defaultPagingCount;
                            const isNextPageBreak = ((index + 1) % defaultPagingCount) === 0;
                            const isIdAlreadyExists = rowsIds.filter(f => f === rowId).length > 1;

                            return(
                                <Fragment key={rowId}>
                                    {isPageBreak && <div data-page={`page-${currentPage}`}></div>}
                                    <DynamicCard<T>
                                        markRow={markRow}
                                        key={rowId}
                                        getRowId={getRowUniqueId}
                                        isAlreadyExists={isIdAlreadyExists}
                                        index={index}
                                        page={currentPage}
                                        isPageBreak={isPageBreak}
                                        row={e}
                                        configs={viewSettings.selectedColumnsWithValueGetter}
                                        onRowClick={onRowClick}
                                        isSelected={rowId === lastSelectedId}
                                        rowActionsConfig={rowActionsConfig}
                                        customCellPaddings={customCellPaddings}
                                    />
                                    {isNextPageBreak && <div data-page={`page-${currentPage}-end`}></div>}
                                </Fragment>
                            )
                        })}

                        {!emptyArrayImage.isShow && !disableSkeletons && (hasMore || isLoading) && <DynamicCardSkeleton<T>
                            isTop={false}
                            configs={viewSettings.selectedColumnsWithValueGetter}
                            isActions={rowActionsConfig ? rowActionsConfig?.length > 0 : false}
                        />}
                    </Grid>
                </Flex>
            }

            <ViewSettingsDialog
                selectedColumns={viewSettings.selectedColumns.filter(e => e.name.trim().length > 0)}
                columnVariants={viewSettings.availableColumns.filter(e => e.name.trim().length > 0)}
                isOpen={viewSettings.isOpen}
                handleClose={viewSettings.handleCloseViewSettings}
                handleSave={viewSettings.handleApplyViewSettings}
            />
        </Fragment>
    )
}
