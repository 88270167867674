import {Handle, Position} from "react-flow-renderer";
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import {useDispatch} from "react-redux";
import {openTrigger} from '../../../store/slice';
import {ALLOWDRAG} from "../../../constants";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Box, Flex} from "../../../../../../newShared/components/Layouts";
import {Typo} from "../../../../../../newShared/components/Typography";

type triggerNodeProps = {
    type: string;
    revDir: boolean
}

export const TriggerNode = ({data} : {data: triggerNodeProps}) => {
    const {type, revDir} = data;
    // console.log(`${revDir} = TRIGGER NODE - ${JSON.stringify({left: !revDir ? '20%' : 'initial', right: revDir ? '20%' : 'initial'})}`)

    const {t} = useMainTranslation('', {keyPrefix: 'automations'});

    const dispatch = useDispatch();
    // const selected: automationType | null = useSelector(selectedAutomation);

    const handleSettingButton = () => {
        dispatch(openTrigger());
    }

    return(
        <Box
            w={'140px'}
            h={'60px'}
            br={'11px'}
            background={'#3BCC89'}
            box_shadow={'0px 0px 5.853034973144531px 0px rgba(0, 0, 0, 0.12)'}
            p={'5px 10px'}
            className={ALLOWDRAG ? 'nodrag': ''}
            cursor={'pointer'}
        >
            <Handle
                type="target"
                position={!revDir ? Position.Left : Position.Right}
                id="trigger-left-target"
                isConnectable={false}
                style={{opacity: '0'}}
            />
            <Typo
                style={{position: 'relative', bottom: '40px', left: '30px'}}
                fontWeight={400}
                fontSize={'14px'}
            >{t('Trigger')}</Typo>

            <Flex ai={'center'} jc={'space-between'} w={'100%'} style={{position: 'relative', bottom: '30px', left: '0'}}>
                <Typo fontWeight={400} fontSize={'14px'} align={'center'} margin={!revDir ? '0 5px 0 0' : '0 0 0 5px'}>{t(`triggerTypes.${type}`)}</Typo>
                <SettingsApplicationsIcon style={{width: '20px', height: '20px'}} onClick={handleSettingButton}/>
            </Flex>

            <Handle
                type="source"
                position={!revDir ? Position.Right : Position.Left}
                id="a"
                isConnectable={false}
                style={{backgroundColor: 'inherit'}}
            />

            {/*{selected && selected.trigger.type === 'schedule' &&  <Handle*/}
            {/*    type="source"*/}
            {/*    position={Position.Bottom}*/}
            {/*    id="trigger-bottom-source"*/}
            {/*    style={{left: '20%', borderRadius: '50%'}}*/}
            {/*    isConnectable={false}*/}
            {/*/>}*/}
            {/*{type !== 'manual' &&*/}
                <Handle
                    type="source"
                    position={Position.Bottom}
                    id="trigger-bottom-source"
                    style={{left: !revDir ? '20%' : 'initial', right: revDir ? '20%' : 'initial', borderRadius: '50%'}}
                    isConnectable={false}
                />
            {/*}*/}
        </Box>
    )
}