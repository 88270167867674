import {builderInitialState, controlType, evidenceType, frameworkType} from "../../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {
    AddControl,
    AddEvidence,
    AddFramework,
    ChangeFrameworkName,
    ChangeFrameworkVisibility,
    DeattachControl,
    DeattachEvidence,
    DeleteControl,
    DeleteEvidence,
    DeleteFramework,
    EditControl,
    EditEvidence,
    GetBuilderData,
    GetControls,
    GetControlsWithFilter,
    GetEvidencesWithFilter,
    GetPrivateControls,
    GetPrivateEvidences,
    LinkControl,
    LinkEvidence,
    UpdateCustomFramework,
} from "./actions";
import {AppState} from "../../../../../newShared/redux/rootReducer";
import {setPaginationInStore} from "../../../../../newShared/hooks/useScroll/helpers";

export const initialState: builderInitialState = {
    //data
    frameworks: [],
    controls: [],
    evidences: [],

    //DIALOG DATA
    fetchedControls: [], //controls for dialog of selection created controls (all)
    pageInfo: {
        page: 0,
        count: 50,
        total: 0,
        sort: '',
    },
    fetchedEvidences: [],

    //counters
    allControlsCounter: 0,
    allEvidencesCounter: 0,
    allPoliciesCounter: 0,

    //selections
    // selectedFramework: null,
    // selectedControl: null,
    // selectedEvidence: null,
    // selectedPolicy: null,
    isLoading: false,
    isLoadingControlAndEvidencesByFrameworkId: false,
    isLoadingControls: false,
    isLoadingEvidences: false,
    isLoadingPolicies: false,
    isLoaded: false,

    deletableFiles: [],

    //dialogs

    //FRAMEWORKS
    addFrameworkDialog: {
        isOpen: false,
    },
    deleteFrameworkDialog: {
        isOpen: false,
        frameworkId: null,
    },
    editFrameworkDialog: {
        isOpen: false,
        framework: null,
    },
    editNameFrameworkDialog: {
        isOpen: false,
    },

    //CONTROLS
    addControlDialog: {
        isOpen: false,
        isLoading: false,
        frameworkId: null,
    },
    deleteControlDialog: {
        isOpen: false,
        controlId: null,
    },
    editControlDialog: {
        isOpen: false,
        controlId: null,
    },
    attachControlDialog: {
        isOpen: false,
    },
    unattachControlDialog: {
        isOpen: false,
        controlId: null,
        isLoading: false,
    },

    //EVIDENCES
    addEvidenceDialog: {
        isOpen: false,
        isEvidencesPage: false,
        controlId: null,
    },
    deleteEvidenceDialog: {
        isOpen: false,
        evidenceId: null,
    },
    editEvidenceDialog: {
        isOpen: false,
        evidenceId: null,
        isEvidencesPage: false,
        evidence: null,
    },
    attachEvidenceDialog: {
        isOpen: false,
        isEvidencesPage: false,
    },
    unattachEvidenceDialog: {
        isOpen: false,
        evidenceId: null,
    },
};

export const BuilderSlice = createSlice({
    name: 'Builder',
    initialState,
    reducers: {
    //FRAMEWORKS
        //ADD
        openAddFramework: (slice) => {slice.addFrameworkDialog.isOpen = true},
        hideAddFramework: (slice) => {slice.addFrameworkDialog.isOpen = false},
        //DELETE
        openDeleteFramework: (slice, {payload}:{payload: string}) => {
            slice.deleteFrameworkDialog.isOpen = true;
            if (payload !== undefined) slice.deleteFrameworkDialog.frameworkId = payload;
        },
        hideDeleteFramework: (slice) => {
            slice.deleteFrameworkDialog.isOpen = false;
            slice.deleteFrameworkDialog.frameworkId = null;
        },
        //EDIT
        openEditFramework: (slice, {payload}: {payload: {framework: frameworkType}} ) => {
            slice.editFrameworkDialog.isOpen = true;
            if (payload !== undefined) slice.editFrameworkDialog.framework = payload.framework;
        },
        hideEditFramework: (slice) => {
            slice.editFrameworkDialog.isOpen = false;
            slice.editFrameworkDialog.framework = null;
        },
        //CHANGE NAME
        openEditNameFramework: (slice) => {slice.editNameFrameworkDialog.isOpen = true},
        hideEditNameFramework: (slice) => {slice.editNameFrameworkDialog.isOpen = false},
    //CONTROLS
        //ADD
        openAddControl: (slice, {payload}: {payload: string | null}) => {
            slice.addControlDialog.isOpen = true;
            if(payload) slice.addControlDialog.frameworkId = payload;
        },
        hideAddControl: (slice) => {slice.addControlDialog = initialState.addControlDialog},
        //DELETE
        openDeleteControl: (slice, {payload}: {payload: string}) => {slice.deleteControlDialog.isOpen = true; slice.deleteControlDialog.controlId = payload},
        hideDeleteControl: (slice) => {slice.deleteControlDialog.isOpen = false; slice.deleteControlDialog.controlId = null},
        //EDIT
        openEditControl: (slice, {payload}: {payload: string}) => {slice.editControlDialog.isOpen = true; slice.editControlDialog.controlId = payload},
        hideEditControl: (slice) => {slice.editControlDialog.isOpen = false; slice.editControlDialog.controlId = null},
        //ATTACH
        openAttachControl: (slice) => {slice.attachControlDialog.isOpen = true;},
        hideAttachControl: (slice) => {
            slice.attachControlDialog.isOpen = false;
            slice.fetchedControls = initialState.fetchedControls;
            slice.pageInfo = initialState.pageInfo
        },
        //UNATTACH
        openUnattachControl: (slice, {payload}: {payload: string}) => {
            slice.unattachControlDialog.isOpen = true;
            slice.editControlDialog.controlId = payload
        },
        hideUnattachControl: (slice) => {slice.editControlDialog.isOpen = false; slice.unattachControlDialog.controlId = null},
    //EVIDENCES
        //ADD
        openAddEvidence: (slice, {payload}: {payload: {isEvidencesPage: boolean, controlId: string | null}}) => {
            slice.addEvidenceDialog.isOpen = true;
            if(payload.isEvidencesPage) slice.addEvidenceDialog.isEvidencesPage = payload.isEvidencesPage;
            if(payload.controlId) slice.addEvidenceDialog.controlId = payload.controlId;
            slice.editEvidenceDialog.isOpen = false;
            // slice.selectedEvidence = null;
        },
        hideAddEvidence: (slice) => {slice.addEvidenceDialog.isOpen = false},
        //DELETE
        openDeleteEvidence: (slice, {payload}: {payload:  string}) => {
            slice.deleteEvidenceDialog.isOpen = true;
            slice.deleteEvidenceDialog.evidenceId = payload;
        },
        hideDeleteEvidence: (slice) => {
            slice.deleteEvidenceDialog.isOpen = false;
            slice.deleteEvidenceDialog.evidenceId = null;
        },
        //EDIT
        openEditEvidence: (slice, {payload}: {payload: {evidenceId: string, isEvidencesPage: boolean, evidence?: evidenceType}}) => {
            slice.editEvidenceDialog.isOpen = true;
            slice.editEvidenceDialog.evidenceId = payload.evidenceId;
            slice.editEvidenceDialog.isEvidencesPage = payload.isEvidencesPage;
            if(payload.evidence !== undefined) slice.editEvidenceDialog.evidence = payload.evidence;
            slice.addEvidenceDialog.isOpen = false;
        },
        hideEditEvidence: (slice) => {
            slice.editEvidenceDialog = initialState.editEvidenceDialog;
            // slice.editEvidenceDialog.evidenceId = null;
            // slice.editEvidenceDialog.isEvidencesPage = false;
        },
        //ATTACH
        openAttachEvidence: (slice, {payload}: {payload: boolean}) => {
            slice.attachEvidenceDialog.isOpen = true;
            slice.attachEvidenceDialog.isEvidencesPage = payload;

        },
        hideAttachEvidence: (slice) => {
            slice.attachEvidenceDialog.isOpen = false;
            slice.fetchedEvidences = [];
            slice.attachEvidenceDialog.isEvidencesPage = false;
        },
        //UNATTACH
        openUnattachEvidence: (slice, {payload}: {payload: string}) => {slice.unattachEvidenceDialog.isOpen = true; slice.unattachEvidenceDialog.evidenceId = payload},
        hideUnattachEvidence: (slice) => {
            slice.unattachEvidenceDialog.isOpen = false;
            slice.unattachEvidenceDialog.evidenceId = null;
            slice.pageInfo = {
                page: 0,
                count: 0,
                total: 0,
                sort: ''
            };
        },

    //OTHER
    //     selectFramework: (slice, {payload}: {payload: string | null}) => {
    //         slice.activeFramework = slice.frameworks.find(e => e.id === payload) || null;
    //         slice.selectedFramework = payload;
    //         slice.selectedControl = null;
    //         slice.selectedEvidence = null;
    //         slice.addEvidenceDialog.isOpen = false;
    //         slice.editEvidenceDialog.isOpen = false;
    //         slice.deleteEvidenceDialog.isOpen = false;
    //     },
    //     selectControl: (slice, {payload}: {payload: string | null}) => {
    //         slice.activeControl = slice.controls.find(e => e.id === payload) || null;
    //         console.log('slice.activeControl ', slice.controls);
    //         slice.selectedControl = payload;
    //         slice.selectedEvidence = null;
    //     },
    //     selectEvidence: (slice, {payload}: {payload: string | null}) => {
    //         slice.activeEvidence = slice.evidences.find(e => e.id === payload) || null;
    //         slice.selectedEvidence = payload;
    //         slice.addEvidenceDialog.isOpen = false;
    //         slice.addEvidenceDialog.isEvidencesPage = false;
    //         slice.editEvidenceDialog.isOpen = true;
    //         slice.editEvidenceDialog.isEvidencesPage = true;
    //     },
        setDeletableFile: (slice, {payload}: {payload: string}) => {
            slice.deletableFiles.push(payload);
        },
        deleteFromDeletableFiles: (slice, {payload}: {payload: string}) => {
            slice.deletableFiles = slice.deletableFiles.filter(e => e !== payload);
        },
        clearDeletableFiles: (slice) => {slice.deletableFiles = []},

        cleanUp: () => initialState,
    },

    extraReducers: (builder) => {
        builder
            .addCase(GetBuilderData.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(GetBuilderData.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(GetBuilderData.fulfilled, (slice, {payload}) => {
                slice.frameworks = payload.frameworks;
                slice.allControlsCounter = payload.allControlsCounter;
                slice.allEvidencesCounter = payload.allEvidencesCounter;
                slice.allPoliciesCounter = payload.allPoliciesCounter;

                slice.isLoading = false;
                slice.isLoaded = true;

                // if(payload.frameworks.length > 0){
                //     slice.selectedFramework = payload.frameworks[0].id;
                // }
            })
            //UpdateCustomFramework
            .addCase(UpdateCustomFramework.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(UpdateCustomFramework.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(UpdateCustomFramework.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.frameworks = slice.frameworks.map(e => e.id === payload.framework.id ? payload.framework : e);
                slice.editFrameworkDialog = initialState.editFrameworkDialog;
            })
            //ChangeFrameworkName
            .addCase(ChangeFrameworkName.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(ChangeFrameworkName.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(ChangeFrameworkName.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.frameworks = slice.frameworks.map(e => e.id === payload.frameworkId ? {
                    ...e,
                    name: payload.name
                } : e);

                slice.editNameFrameworkDialog = initialState.editNameFrameworkDialog;
            })
            //ChangeFrameworkVisibility
            .addCase(ChangeFrameworkVisibility.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(ChangeFrameworkVisibility.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(ChangeFrameworkVisibility.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.frameworks = slice.frameworks.map(e => e.id === payload.frameworkId ? {
                    ...e,
                    visibility: payload.visibility
                } : e);
            })
            //DeleteFramework
            .addCase(DeleteFramework.pending, (slice) => {
                slice.isLoading = true;
                slice.deleteFrameworkDialog.isOpen = false;
            })
            .addCase(DeleteFramework.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(DeleteFramework.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.frameworks = slice.frameworks.filter(e => e.id !== payload);
                slice.controls = initialState.controls;
                slice.evidences = initialState.evidences;

                slice.deleteFrameworkDialog = initialState.deleteFrameworkDialog;
            })
            //GetControls
            .addCase(GetControls.pending, (slice) => {
                slice.isLoadingControlAndEvidencesByFrameworkId = true;
            })
            .addCase(GetControls.rejected, (slice) => {
                slice.isLoadingControlAndEvidencesByFrameworkId = false;
            })
            .addCase(GetControls.fulfilled, (slice, {payload}) => {
                slice.isLoadingControlAndEvidencesByFrameworkId = false;
                slice.controls = payload.controls;
            })
            //AddControl
            .addCase(AddControl.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(AddControl.rejected, (slice) => {
                slice.isLoading = false;
                slice.addControlDialog = initialState.addControlDialog
            })
            .addCase(AddControl.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.controls.push(payload.control);
                slice.allControlsCounter = slice.allControlsCounter + 1;
                slice.frameworks = slice.frameworks.map((e) => e.id === payload.frameworkId ? {
                    ...e,
                    controls: e.controls + 1
                } : e);

                slice.addControlDialog = initialState.addControlDialog;
            })
            //EditControl
            .addCase(EditControl.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(EditControl.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(EditControl.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.editControlDialog = initialState.editControlDialog;
                slice.controls = slice.controls.map(e => e.id === payload.id ? payload : e);

                slice.editControlDialog = initialState.editControlDialog;
            })
            //DeattachControl
            .addCase(DeattachControl.pending, (slice) => {
                slice.isLoading = true;
                slice.unattachControlDialog = initialState.unattachControlDialog
            })
            .addCase(DeattachControl.rejected, (slice) => {
                slice.isLoading = false;
                slice.unattachControlDialog = initialState.unattachControlDialog
            })
            .addCase(DeattachControl.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.controls = slice.controls.filter((e) => e.id !== payload.controlId);
                slice.frameworks = slice.frameworks.map((f) => f.id === payload.framework.id ? payload.framework : f);

                slice.unattachControlDialog = initialState.unattachControlDialog;
            })
            //AddEvidence
            .addCase(AddEvidence.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(AddEvidence.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(AddEvidence.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.addEvidenceDialog = initialState.addEvidenceDialog;

                if(payload.isEvidencesPage){
                    slice.evidences = [...slice.evidences, payload.evidence];
                    // slice.evidences.push(payload.evidence);
                    slice.allEvidencesCounter = slice.allEvidencesCounter + 1;
                }
                slice.controls = slice.controls.map(e => {
                    if (e.id === payload.controlId) {
                        if (e.evidences) {
                            e.evidences.push(payload.evidence)
                        } else {
                            e.evidences = [payload.evidence]
                        }
                    }
                    return e;
                })

                slice.allEvidencesCounter = slice.allEvidencesCounter + 1;
                slice.frameworks = slice.frameworks.map((e) => e.id === payload.frameworkId ? {
                    ...e,
                    evidences: e.evidences + 1
                } : e);
            })

            //EditEvidence
            .addCase(EditEvidence.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(EditEvidence.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(EditEvidence.fulfilled, (slice, {payload}) => {
                // slice.editEvidenceDialog = {isOpen: false, evidenceId: null, isEvidencesPage: false};
                slice.isLoading = false;
                if(payload.isEvidencesPage){
                    slice.evidences = slice.evidences.map(ev => ev.id === payload.evidence.id ? payload.evidence : ev);
                    return;
                }
                slice.controls = slice.controls
                    .map(e => e.evidences?.some(e => e.id === payload.evidence.id)
                        ? {...e, evidences: e.evidences?.map(ev => ev.id === payload.evidence.id ? payload.evidence : ev) || []}
                        : e
                    );

                slice.editEvidenceDialog = initialState.editEvidenceDialog;
            })
            //DeattachEvidence

            .addCase(DeattachEvidence.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(DeattachEvidence.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(DeattachEvidence.fulfilled, (slice, {payload}) => {
                slice.unattachEvidenceDialog = {isOpen: false, evidenceId: null};
                slice.isLoading = false;
                slice.controls = slice.controls
                    .map(e => e.id === payload.controlId ?
                        {...e, evidences: e.evidences?.filter((ev) => ev.id !== payload.evidenceId) || []}
                        : e
                    );
                if(payload.framework){ //IF BUILDER PAGE AND NEEDED TO REACALCULATE FRAMEWORK
                    slice.frameworks = slice.frameworks.map((f) => f.id === payload.framework!.id ? payload.framework! : f);
                }
            })
            //GetControlsWithFilter
            .addCase(GetControlsWithFilter.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.isLoading = true;
                if (clean) slice.fetchedControls = initialState.fetchedControls;
                if (clean) slice.pageInfo = initialState.pageInfo;
            })
            .addCase(GetControlsWithFilter.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(GetControlsWithFilter.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;

                const {array, pageInfo} = setPaginationInStore<controlType>(
                    slice.pageInfo,
                    slice.fetchedControls,
                    payload.result.pageInfo,
                    payload.result.controls
                )

                slice.fetchedControls = array;
                slice.pageInfo = pageInfo;
            })
            //LinkControl
            .addCase(LinkControl.pending, (slice) => {
                slice.isLoading = true;
                // slice.attachControlDialog.isOpen = false;
            })
            .addCase(LinkControl.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(LinkControl.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.controls.push(payload.control);
                slice.frameworks = slice.frameworks.map((f) => f.id === payload.framework.id ? payload.framework : f);
            })
            //GetEvidencesWithFilter
            .addCase(GetEvidencesWithFilter.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.isLoading = true;
                if (clean) slice.fetchedEvidences = initialState.fetchedEvidences;
                if (clean) slice.pageInfo = initialState.pageInfo;
            })
            .addCase(GetEvidencesWithFilter.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(GetEvidencesWithFilter.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;

                const {array, pageInfo} = setPaginationInStore<evidenceType>(
                    slice.pageInfo,
                    slice.fetchedEvidences,
                    payload.result.pageInfo,
                    payload.result.evidences
                )

                slice.fetchedEvidences = array;
                slice.pageInfo = pageInfo;
            })
            //LinkEvidence
            .addCase(LinkEvidence.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(LinkEvidence.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(LinkEvidence.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.controls = slice.controls.map(e => e.id === payload.controlId
                    ? {...e, evidences: [...e.evidences || [], payload.evidence]}
                    : e
                )
                if(payload.framework){
                    slice.frameworks = slice.frameworks.map((f) => f.id === payload.framework!.id ? payload.framework! : f);
                }
            })
        // AddFramework
            .addCase(AddFramework.pending, (slice) => {
                slice.isLoading = true;
            })
            .addCase(AddFramework.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(AddFramework.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.isLoadingControlAndEvidencesByFrameworkId = false;
                slice.frameworks.push(payload);
                slice.controls = [];

                slice.addFrameworkDialog = initialState.addFrameworkDialog
            })
        //GetPrivateControls
            .addCase(GetPrivateControls.pending, (slice) => {
                slice.isLoadingControls = true;
                slice.controls = [];
            })
            .addCase(GetPrivateControls.rejected, (slice) => {
                slice.isLoadingControls = false;
            })
            .addCase(GetPrivateControls.fulfilled, (slice, {payload}) => {
                slice.isLoadingControls = false;
                slice.controls = payload.controls;
                slice.pageInfo = payload.pageInfo;
            })
        //GetPrivateEvidences
            .addCase(GetPrivateEvidences.pending, (slice) => {
                slice.isLoadingEvidences = true;
            })
            .addCase(GetPrivateEvidences.rejected, (slice) => {
                slice.isLoadingEvidences = false;
            })
            .addCase(GetPrivateEvidences.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.isLoadingEvidences = false;
                slice.evidences = payload.evidences;
                slice.pageInfo = payload.pageInfo;
            })
        //DeleteControl
            .addCase(DeleteControl.pending, (slice) => {
                slice.isLoading = true;
                slice.deleteControlDialog.isOpen = false;
                slice.deleteControlDialog.controlId = null;
            })
            .addCase(DeleteControl.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(DeleteControl.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.controls = slice.controls.filter(e => e.id !== payload);
                slice.allControlsCounter = slice.allControlsCounter - 1;
            })
        //DeleteEvidence
            .addCase(DeleteEvidence.pending, (slice) => {
                slice.isLoading = true;
                slice.deleteEvidenceDialog.isOpen = false;
                slice.deleteEvidenceDialog.evidenceId = null;
            })
            .addCase(DeleteEvidence.rejected, (slice) => {
                slice.isLoading = false;
            })
            .addCase(DeleteEvidence.fulfilled, (slice, {payload}) => {
                slice.isLoading = false;
                slice.evidences = slice.evidences.filter(e => e.id !== payload);
                slice.allEvidencesCounter = slice.allEvidencesCounter - 1;
            })

    },
});

export const {
//FRAMEWORKS
    openAddFramework,
    hideAddFramework,
    openEditFramework,
    hideEditFramework,
    openDeleteFramework,
    hideDeleteFramework,
    openEditNameFramework,
    hideEditNameFramework,
//CONTROLS
    openAddControl,
    hideAddControl,
    openDeleteControl,
    hideDeleteControl,
    openEditControl,
    hideEditControl,
    openAttachControl,
    hideAttachControl,
    openUnattachControl,
    hideUnattachControl,
//EVIDENCES
    openAddEvidence,
    hideAddEvidence,
    openDeleteEvidence,
    hideDeleteEvidence,
    openEditEvidence,
    hideEditEvidence,
    openAttachEvidence,
    hideAttachEvidence,
    openUnattachEvidence,
    hideUnattachEvidence,
//OTHER
    setDeletableFile,
    deleteFromDeletableFiles,
    clearDeletableFiles,
    cleanUp
} = BuilderSlice.actions;

export const BuilderReducer = BuilderSlice.reducer;

const selectSelf = (state: AppState):builderInitialState => state.Builder as builderInitialState;

//DATA
export const frameworks = createSelector(selectSelf, state => state.frameworks);
export const controls = createSelector(selectSelf, state => state.controls);
export const evidencesSelector = createSelector(selectSelf, state => state.evidences);


//DIALOG DATA
export const fetchedControls = createSelector(selectSelf, state => state.fetchedControls);
export const fetchedEvidences = createSelector(selectSelf, state => state.fetchedEvidences);
export const pageInfo = createSelector(selectSelf, state => state.pageInfo);

//counters
export const allControlsCounter = createSelector(selectSelf, state => state.allControlsCounter);
export const allEvidencesCounter = createSelector(selectSelf, state => state.allEvidencesCounter);
export const allPoliciesCounter = createSelector(selectSelf, state => state.allPoliciesCounter);

//SELECTORS
export const isLoading = createSelector(selectSelf, state => state.isLoading);
export const isLoadingControlAndEvidencesByFrameworkId = createSelector(selectSelf, state => state.isLoadingControlAndEvidencesByFrameworkId);
export const isLoadingControls = createSelector(selectSelf, state => state.isLoadingControls);
export const isLoadingEvidences = createSelector(selectSelf, state => state.isLoadingEvidences);
export const isLoadingPolicies = createSelector(selectSelf, state => state.isLoadingPolicies);
export const deletableFiles = createSelector(selectSelf, state => state.deletableFiles);


//isLoadingControls
export const isLoaded = createSelector(selectSelf, state => state.isLoaded);
// export const selectedFramework = createSelector(selectSelf, state => state.selectedFramework);
// export const selectedControl = createSelector(selectSelf, state => state.selectedControl);
// export const selectedEvidence = createSelector(selectSelf, state => state.selectedEvidence);
// export const selectedPolicy = createSelector(selectSelf, state => state.selectedPolicy);

//DIALOGS

//FRAMEWORKS
export const addFrameworkDialog = createSelector(selectSelf, state => state.addFrameworkDialog);
export const deleteFrameworkDialog = createSelector(selectSelf, state => state.deleteFrameworkDialog);
export const editFrameworkDialog = createSelector(selectSelf, state => state.editFrameworkDialog);
export const editNameFrameworkDialog = createSelector(selectSelf, state => state.editNameFrameworkDialog);

//CONTROLS
export const addControlDialog = createSelector(selectSelf, state => state.addControlDialog);
export const deleteControlDialog = createSelector(selectSelf, state => state.deleteControlDialog);
export const editControlDialog = createSelector(selectSelf, state => state.editControlDialog);
export const attachControlDialog = createSelector(selectSelf, state => state.attachControlDialog);
export const unattachControlDialog = createSelector(selectSelf, state => state.unattachControlDialog);

//EVIDENCES
export const addEvidenceDialog = createSelector(selectSelf, state => state.addEvidenceDialog);
export const deleteEvidenceDialog = createSelector(selectSelf, state => state.deleteEvidenceDialog);
export const editEvidenceDialog = createSelector(selectSelf, state => state.editEvidenceDialog);
export const attachEvidenceDialog = createSelector(selectSelf, state => state.attachEvidenceDialog);
export const unattachEvidenceDialog = createSelector(selectSelf, state => state.unattachEvidenceDialog);
