import {Flex} from "../../../../../newShared/components/Layouts";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import React, {useEffect} from "react";

//icons
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useParams} from "react-router-dom";
import {useHrIssues} from "../../hooks/useHrIssues";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_EMPLOYEES} from "../../../../../newShared/constants";
import {PATH_LOCAL_EMPLOYEES_ISSUES} from "../../constants";
import {
    FlexColumn,
    FlexRow
} from "../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import {
    CircularProgress,
    MenuItem,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {HrIssuesStatus} from "../../../../../newShared/GQLTypes";
import Divider from "@mui/material/Divider";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {setResolveIssueDialogAction} from "../../store/slice";
import {useDispatch} from "react-redux";
import {ResolveIssueDialog} from "../../components/dialogs/resolveIssue";
//

export const EmployeesIssuesExact = () => {
    const {t, tMenu} = useMainTranslation('', {keyPrefix: 'pathHRManagement.pathIssuesExact'});
    const dispatch = useDispatch();

    const {issueId} = useParams<{issueId: string | undefined}>();
    const {
        getIssueById, selected: issue, isLoading: {issueExact, issueChangeStatus },
        setIssueStatusInProgress,
    } = useHrIssues();

    const openDialog = (issueId: string) => {
        dispatch(setResolveIssueDialogAction({issueId}))
    };

    const handleClickStatus = (status: HrIssuesStatus) => () => {
        if (!issue || issue.status === status) return;

        if (status === HrIssuesStatus.InProgress) {
            setIssueStatusInProgress(issue.id);
        }
        if (status === HrIssuesStatus.Resolved) {
            openDialog(issue.id);
        }
    };

    useSetBreadcrumbs([
        {
            title: tMenu('HR Management'),
            path: PATH_LOCAL_EMPLOYEES
        },
        {
            title: tMenu('Employees issues'),
            path: PATH_LOCAL_EMPLOYEES_ISSUES
        },
        {
            title: issue ? `${t('Issue')} ${issue.id}` : 'Loading...',
        },
    ]);

    useEffect(() => {
        if (issueId) {
            getIssueById(issueId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issueId]);

    return (
        <Flex w={'100%'} h={'100%'} direction={'column'} overflow={'hidden'}>
            <PageTitleAndActions
                title={issue ? `${t('Issue')} ${issue.id}` : undefined}
                actions={[]}
                handleGoBack={PATH_LOCAL_EMPLOYEES_ISSUES}
            />

            <FlexColumn sx={{mt: '20px', gap: '16px', width: '100%', overflow: 'auto', flexGrow: 1}}>
                <FlexRow sx={{gap: '16px', alignItems: 'center'}}>
                    <Typography variant={'*bodyText2'} color={colors.text.grey_dark}>{`${t('Status')}:`}</Typography>

                    <TextField select value={!(issueChangeStatus || issueExact) && issue ? issue.status : 'loading'}
                               size={'small'} disabled={issueChangeStatus}
                               onClick={
                                   issue?.status === HrIssuesStatus.Resolved
                                       ? (e) => e.stopPropagation()
                                       : undefined
                               }
                               sx={{minWidth: '214px', width: 'fit-content'}}>
                        <MenuItem value={HrIssuesStatus.Reported} sx={{display: 'none'}}
                                  disabled={(issueChangeStatus || issueExact) || issue?.status === HrIssuesStatus.Reported || issue?.status === HrIssuesStatus.InProgress}
                        >
                            {t('Reported')}
                        </MenuItem>
                        <MenuItem value={HrIssuesStatus.InProgress} onClick={handleClickStatus(HrIssuesStatus.InProgress)}
                                  // disabled={(issueChangeStatus || issueExact) || issue?.status === HrIssuesStatus.Resolved}
                                  disabled={(issueChangeStatus || issueExact) || issue?.status === HrIssuesStatus.InProgress || issue?.status === HrIssuesStatus.Resolved}
                        >
                            {t('In progress')}
                        </MenuItem>
                        <MenuItem value={HrIssuesStatus.Resolved} onClick={handleClickStatus(HrIssuesStatus.Resolved)}
                                  // disabled={(issueChangeStatus || issueExact) || issue?.status === HrIssuesStatus.InProgress}
                                  disabled={(issueChangeStatus || issueExact) || issue?.status === HrIssuesStatus.Resolved}
                        >
                            {t('Resolved')}
                        </MenuItem>
                        {(issueChangeStatus || issueExact) && <MenuItem value={'loading'}><CircularProgress size={20} sx={{marginInline: 'auto'}}/></MenuItem>}
                    </TextField>
                </FlexRow>

                <FlexColumn sx={{
                    gap: '16px',
                    width: 'min(100%, 1020px)',
                    padding: '32px 20px',
                    border: `1px solid ${colors.stroke.grey}`,
                    backgroundColor: colors.backgrounds.grey_light2,
                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                    borderRadius: '6px',
                }}>
                    {issue?.status === HrIssuesStatus.Resolved && (
                        <>
                            <Table sx={{
                                '* .MuiTableCell-root': {
                                    border: 'none !important',
                                    verticalAlign: 'top',

                                    padding: '8px 4px',
                                },
                            }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell width={'20%'}>
                                            <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>
                                                {t('Resolve date')}:
                                            </Typography>
                                        </TableCell>
                                        <TableCell width={'80%'}>
                                            <Typography variant={'*bodyText2'} color={colors.text.grey_dark}>
                                                {issueExact ? <Skeleton width={'200px'}/> : parseDateAuto(issue?.resolveDate) || '-'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell width={'20%'}>
                                            <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>
                                                {t('Resolver')}:
                                            </Typography>
                                        </TableCell>
                                        <TableCell width={'80%'}>
                                            <Typography variant={'*bodyText2'} color={colors.text.grey_dark}>
                                                {issueExact ? <Skeleton width={'200px'}/> : issue?.resolver?.employeeFullName || issue?.resolver?.collaboratorFullName || '-'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell width={'20%'}>
                                            <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>
                                                {t('Resolution')}:
                                            </Typography>
                                        </TableCell>
                                        <TableCell width={'80%'}>
                                            <Typography variant={'*bodyText2'} color={colors.text.grey_dark}>
                                                {issueExact ? <Skeleton width={'200px'}/> : issue?.resolution || '-'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                            <Divider flexItem sx={{borderColor: colors.stroke.grey, marginBlock: '16px'}}/>
                        </>
                    )}

                    <Table sx={{
                        '* .MuiTableCell-root': {
                            border: 'none !important',
                            verticalAlign: 'top',

                            padding: '8px 0',
                        },
                    }}>
                        <TableBody>
                            <TableRow>
                                <TableCell width={'20%'}>
                                    <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>
                                        {`${t('Type of issue')}: `}
                                    </Typography>
                                </TableCell>
                                <TableCell width={'80%'}>
                                    <Typography variant={'*bodyText2'} color={colors.text.grey_dark}>
                                        {issueExact ? <Skeleton width={'200px'}/> : issue?.issueType || '-'}
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell width={'20%'}>
                                    <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>
                                        {t('Offender name')}:
                                    </Typography>
                                </TableCell>
                                <TableCell width={'80%'}>
                                    <Typography variant={'*bodyText2'} color={colors.text.grey_dark}>
                                        {issueExact ? <Skeleton width={'200px'}/> : issue?.offender?.fullName || '-'}
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell width={'20%'}>
                                    <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>
                                        {t('What happened?')}
                                    </Typography>
                                </TableCell>
                                <TableCell width={'80%'}>
                                    <Typography variant={'*bodyText2'} color={colors.text.grey_dark}>
                                        {issueExact ? <Skeleton width={'200px'}/> : issue?.description || '-'}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </FlexColumn>
            </FlexColumn>

        {/*    DIALOGS*/}
            <ResolveIssueDialog />
        </Flex>
    )
}
