import {useDispatch, useSelector} from "react-redux";
import {
    hideTransferOrganization,
    organizationsDialogs,
    organizationsLoadings,
    selectedOrganization
} from "../../../../store/slice";
import {useMessageDialog} from "../../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useState} from "react";
import {TransferOrganizationOwnership} from "../../../../store/actions";
import {useHistory} from "react-router-dom";
import {SETTINGS_ORGANIZATIONS_PATH} from "../../../../constants";
import {UseManageWorkspacesAndOrganizations} from "../../../../../cookies/hooks/useManageWorkspacesAndOrganizations";
import {WorkspaceCollaboratorModel} from "../../../../../../../newShared/GQLTypes";

export const useTransferOwnershipDialog = () => {
    //root
    const history = useHistory();
    const dispatch = useDispatch();
    const selected = useSelector(selectedOrganization);
    const {user} = UseManageWorkspacesAndOrganizations();
    const {transferOwnershipDialog} = useSelector(organizationsDialogs);
    const {transferOwnership} = useSelector(organizationsLoadings);
    const {setMessage} = useMessageDialog();
    const selectedOrg = useSelector(selectedOrganization);

    const cleanUp = () => {
        setSelectedCollab(null);
    }

    //actions
    const handleClose = () => {
        dispatch(hideTransferOrganization());
        cleanUp();
    }

    const handleSubmit = () => {
        if(isOk && selected && user && selectedCollab){
            //todo
            dispatch(TransferOrganizationOwnership({
                organizationId: selected.id,
                transferFromPublicId: user.publicId,
                transferToPublicId: selectedCollab.publicId,
                onSuccess
            }))

            cleanUp();
        }
    }

    const onSuccess = () => {
        setMessage({
            title: 'Completed successfully!',
            message: `Organization transferred to ${selectedCollab?.email}!`
        });
        history.push(SETTINGS_ORGANIZATIONS_PATH);
    }

    const [selectedCollab, setSelectedCollab] = useState<WorkspaceCollaboratorModel | null>(null);

    //
    const isOk = selectedCollab !== null;

    const handleSelectCollab = (collab: WorkspaceCollaboratorModel | WorkspaceCollaboratorModel[] | null) => {
        if(!collab) setSelectedCollab(null);
        if(!Array.isArray(collab)){
            setSelectedCollab(collab);
        }
    }

    return{
        isOpen: transferOwnershipDialog.isOpen,
        isLoading: transferOwnership,
        handleClose,
        handleSubmit,
        isOk,
        selectedCollab,
        handleSelectCollab,
        organizationId: selectedOrg?.id ?? '',
        currentUserEmail: user?.email,
    }
}