import {useAutomationsExact} from "../../hooks/useAutomationsExact";
import React from "react";
import {Flex} from "../../../../../newShared/components/Layouts";
import colors from "../../../../../newShared/theme/colors";
import {Box, Button, Tab, Tabs, Typography} from "@mui/material";
import {LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import BlockIcon from "@mui/icons-material/Block";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import EditIcon from '@mui/icons-material/Edit';
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {TabContext} from "@mui/lab";
import {Pipeline} from "../../components/pipeLine";
import {isNeededETCTooBigWarning, needSave} from "../../helpers";
import {Typo} from "../../../../../newShared/components/Typography";
import {ChangeNameDialog} from "../../components/dialogs/changeName";
import {DeletePipelineDialog} from "../../components/dialogs/deletePipeline";
import {DocFileSelectionDialog} from "../../components/dialogs/docFileSelection";
import {DocLinkSelectionDialog} from "../../components/dialogs/docLinkSelection";
import {DocPolicySelectionDialog} from "../../components/dialogs/docPolicySelection";
import {DocDialog} from "../../components/dialogs/docTypeSelection";
import {SaveChangesDialog} from "../../components/dialogs/saveChanges";
import {SaveEvidenceSelectionDialog} from "../../components/dialogs/saveEvidenceSelection";
import {StageActorDialog} from "../../components/dialogs/stageActor";
import {StageSettings} from "../../components/dialogs/stageSettings";
import {TriggerDateDialog} from "../../components/dialogs/triggerDate";
import {TriggerRepeatDialog} from "../../components/dialogs/triggerRepeat";
import {TriggerDialog} from "../../components/dialogs/triggerSelection";
import {AutomationHistory} from "../../components/automationHistory";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";

export const AutomationsExact = () => {
    const {
        auto,
        actions,
        isLoadingRun,
        isLoadingSetActivity,
        tab,
        setTab,
        isGenereting,
        isLoadingExact,
        onLoad,
        allElements,
        prev
    } = useAutomationsExact();
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'automations'});
    const {isLessThen1050, isMobile} = useMedia(858);

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <PageTitleAndActions
                title={auto?.name}
                handleGoBack={actions.handleGoBack}
                showMenuWhen={isLessThen1050}
                actions={[
                    {
                        title: t('Run automation'),
                        onClick: actions.handleRunAuto,
                        variant: 'text',
                        icon: <PlayArrowIcon />,
                        loading: Boolean(isLoadingRun),
                        disabled: isLoadingRun || !auto
                    },
                    {
                        title: (auto && auto.active) ? t('set inactive') : t('set active'),
                        onClick: actions.handleSetActivity,
                        variant: 'text',
                        icon: (auto && auto.active) ? <ErrorOutlineIcon /> : <BlockIcon />,
                        loading: isLoadingSetActivity,
                        disabled: !auto
                    },
                    {
                        title: t('Change name'),
                        onClick: actions.handleOpenChangeNameDialog,
                        variant: 'text',
                        icon: <EditIcon />,
                        loading: isLoadingSetActivity,
                        disabled: !auto
                    },
                    {
                        title: t('Delete automation'),
                        onClick: actions.handleOpenDeleteDialog,
                        variant: 'text',
                        icon: <DeleteForeverIcon />,
                        loading: isLoadingSetActivity,
                        disabled: !auto
                    }
                ]}
            />

            <Flex ai={'center'} m={'16px 0 0 0'}>
                <Typography variant={"body1"}  sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', color: colors.lightGrayText}}>{t('Last run')}</Typography>
                <Typography variant={"body1"}  sx={{color: colors.grayText}}>{(parseDateAuto(auto?.lastRunningDate ?? ''))}</Typography>
            </Flex>

            <Flex ai={'center'} m={'8px 0 0 0'}>
                <Typography variant={"body1"}  sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', color: colors.lightGrayText}}>{t('Status')}</Typography>
                <Typography variant={"body1"}  sx={{color: auto?.active ? colors.warningActions.green : colors.warningActions.red}}>{auto?.active ? t('Active') : t('Inactive')}</Typography>
            </Flex>

            <Flex ai={'center'} m={'8px 0 0 0'}>
                <Typography variant={"body1"}  sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', color: colors.lightGrayText}}>{t('ID')}</Typography>
                <Typography variant={"body1"}  sx={{color: colors.grayText}}>{auto?.id}</Typography>
            </Flex>

            <Flex w={'100%'} ai={'center'} m={'16px 0 0 0'}>
                <LoadingButton
                    loading={false}
                    onClick={actions.handleSave}
                    disabled={!auto || !needSave(auto, prev)}
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                >
                    {('Save')}
                </LoadingButton>

                <Button disabled={!auto || !needSave(auto, prev)} onClick={actions.handleDiscardChanges}>{t('Cancel')}</Button>
            </Flex>

            {auto &&
                <TabContext value={tab}>
                    <Box sx={{ margin: !isMobile ? '16px 0 0 0' : '0' }}>
                        <Tabs variant={'scrollable'} value={tab} onChange={(e, newValue: "history" | "automation") => setTab(newValue)} aria-label="basic tabs example">
                            <Tab label={t("Automation")} value={'automation'} sx={{paddingLeft: '0'}}/>
                            <Tab label={t("History")} value={'history'} />
                        </Tabs>
                    </Box>

                    {tab === 'automation' &&
                        <>
                            {auto && isNeededETCTooBigWarning(auto.trigger.type, auto.trigger.repeat, auto.stages.map(e => e.eta)) &&
                                <Typo fontWeight={300} fontSize={'14px'} color={'#FF5959'} sx={{margin: '16px 0 0 0', lineHeight: 'normal'}}>{t('Warning: Common execution time of stages is bigger then trigger repeat variable.')}</Typo>
                            }

                            <Pipeline
                                isLoading={isLoadingExact}
                                isGenereting={isGenereting}
                                preferPipeline={true} //does nothing
                                onLoad={onLoad}
                                setPreferPipeLine={() => null}
                                allElements={allElements}
                            />
                        </>
                    }

                    {tab === 'history' &&
                        <Flex w={'100%'} minw={'670px'} overflow={'auto'}>
                            <AutomationHistory />
                        </Flex>
                    }
                </TabContext>
            }

            <ChangeNameDialog />
            <DeletePipelineDialog />
            <DocFileSelectionDialog />
            <DocLinkSelectionDialog />
            <DocPolicySelectionDialog />
            <DocDialog />
            <SaveChangesDialog/>
            <SaveEvidenceSelectionDialog />
            <StageActorDialog />
            <StageSettings />
            <TriggerDateDialog />
            <TriggerRepeatDialog />
            <TriggerDialog />
        </Flex>
    )
}