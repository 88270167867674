import React, {FC, useCallback, useEffect, useRef} from "react";
import {
    FlexColumn,
    FlexRow
} from "../../../../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import {Button, TextField, Typography} from "@mui/material";
import {ExamViewBlockRow} from "../examViewRow";
import {MainTrainingExamFormik, MainTrainingExamFormikQuestion} from "../../../../../types";
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../../../../../../newShared/components/Layouts";
import {SectionButton} from "../../../createEditTrainingDialog/components/sectionButton";
import colors from "../../../../../../../../newShared/theme/colors";
import {Add, ContentCopy, List, TableRows} from "@mui/icons-material";
import {uuid} from "../../../../../../../../newShared/utils";
import {createEventCollapseAllQuestions, createEventExpandAllQuestions, handleMinMaxStep} from "../../../../../helpers";
import {ExamViewQuestionRow} from "../examViewQuestionRow";
import {useCustomFormik} from "../../../../../../../../newShared/hooks/useCustomFormik";
import {insert, move} from "formik";
import {createExamQuestion} from "../../../../../constants";

type CreateNewExamBlockNameProps = {
    masterFormik: ReturnType<typeof useCustomFormik<MainTrainingExamFormik>>;
    handleUseQuestion: () => void;
}
export const CreateNewExamBlockQuestions: FC<CreateNewExamBlockNameProps> = ({masterFormik: formik, handleUseQuestion}) => {
    const {t} = useMainTranslation('', {keyPrefix: ''});

    const handleDeleteQuestion = useCallback(
        (questionId: string) => {
            formik.setFieldValue('questions', formik.values.questions.filter(question => question.questionId !== questionId));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [formik.values.questions]
    );

    const handleAddQuestion = () => {
        const questionId = 'new' + uuid();
        const newIndex = formik.values.questions.length;
        formik.setFieldValue(`questions[${newIndex}]`, createExamQuestion(newIndex, questionId), true);

        setTimeout(() => {
            const el = document.getElementById(`question-${questionId}`);
            el?.scrollIntoView({behavior: 'smooth', block: 'start'});
        }, 50);
    };

    const handleCopyQuestion = (newIndex: number, oldRow: MainTrainingExamFormikQuestion) => {
        const newQuestionId = 'new' + uuid();
        const newArray = insert<MainTrainingExamFormikQuestion>(formik.values.questions, newIndex, {
            ...oldRow,
            questionId: newQuestionId,
            options: oldRow.options.map((option) => ({...option, optionId: 'new' + uuid()})),
        }) as MainTrainingExamFormikQuestion[];

        formik.setFieldValue('questions', newArray).then(() => {
            formik.setFieldTouched(`questions[${newIndex-1}]question`, true, false);
            formik.setFieldTouched(`questions[${newIndex}]question`, true, false);
        });
        setTimeout(() => {
            const el = document.getElementById(`question-${newQuestionId}`);
            el?.scrollIntoView({behavior: 'smooth', block: 'start'});
        }, 50);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const moveSection = useCallback((id: string, toId: string) => {
        if (id === toId) return;

        const from = formik.values.questions.findIndex(question => question.questionId === id);
        const to = formik.values.questions.findIndex(question => question.questionId === toId);
        if (from === -1 || to === -1) return;

        formik.setFieldValue('questions', move<MainTrainingExamFormikQuestion>(formik.values.questions, from, to) as MainTrainingExamFormikQuestion[]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.questions]);

    const moveSectionRef = useRef<(id: string, toId: string) => void>(() => {});
    const handleDeleteQuestionRef = useRef<(questionId: string) => void>(() => {});

    useEffect(() => {
        handleDeleteQuestionRef.current = handleDeleteQuestion;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleDeleteQuestion]);

    useEffect(() => {
        moveSectionRef.current = moveSection;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moveSection]);

    return (
        <ExamViewBlockRow title={t('Questions')}
                          description={t('Build your exam questions set')}
                          isLastElement
                          gapChild={'16px'}
                          stickyLeft
                          titleChild={
                              <FlexRow sx={{gap: '0 16px', overflow: 'hidden', flexWrap: 'wrap', mt: '30px'}}>
                                  <FlexRow sx={{gap: '8px', overflow: 'hidden', alignItems: 'center'}}>
                                      <Typography variant={'*bodyText2_semibold'} color={colors.text.dark} sx={{flexWrap: 'nowrap'}}>
                                          {t('Total points')}
                                      </Typography>

                                      <TextField variant={'outlined'}
                                                 size={"small"}
                                                 value={formik.values.totalPoints}
                                                 disabled
                                                 sx={{
                                                     width: '170px',
                                                     '& .MuiOutlinedInput-root': {
                                                         '& fieldset': {border: 'none'},
                                                         '& input': {'-webkit-text-fill-color': 'black'},
                                                     },
                                                 }}
                                      />
                                  </FlexRow>

                                  {formik.values.questions.length > 0 &&
                                      <FlexRow sx={{gap: '8px', overflow: 'hidden', alignItems: 'center'}}>
                                          <Typography variant={'*bodyText2_semibold'} color={colors.text.dark} sx={{whiteSpace: 'nowrap'}}>
                                              {t('Exam passing points')}
                                          </Typography>

                                          <TextField name={'examPassingPoints'}
                                                     variant={'outlined'}
                                                     size={"small"}
                                                     value={formik.values.examPassingPoints}
                                                     onChange={handleMinMaxStep(1, undefined, 1, formik.handleChange)}
                                                     onBlur={formik.handleBlur}
                                                     error={formik.touched.examPassingPoints && !!formik.errors.examPassingPoints}
                                              // helperText={formik.touched.examPassingPoints && formik.errors.examPassingPoints}
                                                     type={"number"}
                                                     InputProps={{inputProps: {min: 1, max: formik.values.totalPoints, step: 1}}}
                                                     sx={{width: '170px'}}
                                          />
                                      </FlexRow>
                                  }
                              </FlexRow>
                          }
        >
            {formik.errors.questions && formik.touched.questions && typeof formik.errors.questions === 'string' && (
                <Typography variant={'*bodyText2'} color={colors.text.critical}>
                    {formik.errors.questions}
                </Typography>

            )}

            {formik.values.questions.length === 0 ? (
                <Flex w={'100%'} jc={'space-between'} gap={'16px'}>
                    <SectionButton title={'Create new question'} onClick={handleAddQuestion}/>
                    <SectionButton title={'Use existing question'} onClick={handleUseQuestion}/>
                </Flex>
            ) : (
                <FlexRow sx={{
                    gap: '12px', flexWrap: 'wrap', alignItems: 'center', position: 'sticky', top: 0, zIndex: 2,
                    backgroundColor: colors.backgrounds.white, paddingBlock: '8px',
                    borderBottom: `1px solid ${colors.stroke.grey}`,
                }}>
                    <Button variant={'text'} size={"small"} onClick={handleAddQuestion} startIcon={<Add/>}>{t('Add question')}</Button>
                    <Button variant={'text'} size={"small"} onClick={handleUseQuestion} startIcon={<ContentCopy/>}>{t('Use question')}</Button>
                    <Button variant={'text'} size={"small"} onClick={createEventCollapseAllQuestions} disabled={formik.values.questions.length === 0} startIcon={<List/>}>{t('Collapse all')}</Button>
                    <Button variant={'text'} size={"small"} onClick={() => createEventExpandAllQuestions()} disabled={formik.values.questions.length === 0} startIcon={<TableRows/>}>{t('Expand all')}</Button>
                </FlexRow>
            )}

            <FlexColumn sx={{width: '100%', gap: '16px'}}>
                {formik.values.questions.map((question, index) => (
                    <ExamViewQuestionRow key={question.questionId} questionIndex={index} masterFormik={formik}
                                         handleCopyQuestion={() => handleCopyQuestion(index + 1, question)}
                                         handleDeleteQuestionRef={handleDeleteQuestionRef}
                                         moveSectionRef={moveSectionRef}/>
                ))}
            </FlexColumn>
        </ExamViewBlockRow>
    )
}