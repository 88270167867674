import {useSegregationOfDuties} from "../../useSegregationOfDuties";
import {useEffect, useState} from "react";
import {TAddCollaboratorsTab, TAllowConflictData, TReleaseCollaborator} from "../../../types";
import {useForm} from "../../../../../../newShared/hooks/useForm";


export const useAllowConflict = () => {
    // const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        selectedConflict: conflict,
        loadings: {dataForDialog: isLoadingData, allowConflict: isLoadingAllow},
        dialogs: {allowConflict: isOpen},
        shortCollaborators: collaborators,


        handleHideAllowConflict,
        handleSaveAllowConflictStandard,
        handleSaveAllowConflictCustom,


    } = useSegregationOfDuties();

    //tabs
    const [tab, setTab] = useState<TAddCollaboratorsTab>('readers');
    const [isCustomApproach, setIsCustomApproach] = useState<boolean>(false);

    //filter
    const [search, setSearch] = useState<string>('');

    const initAllowConflictData: TAllowConflictData = {
        resolution: '',
        reason: '',
        allowedDate: '',
        active: true,
        reviewers: [],
        readers: [],
        signers: []
    }

    const {form, handleChange, setForm} = useForm<TAllowConflictData>(initAllowConflictData);

    const handleCleanSearch = () => {
        setSearch('');
    };

    const handleSetCollaborator = (collab: TReleaseCollaborator, field: TAddCollaboratorsTab) => {
        setForm(prev => ({
            ...prev,
            [field]: [...prev[field], collab]
        }))
    }
    const handleDeleteCollaborator = (id: string, field: TAddCollaboratorsTab) => {
        setForm(prev => ({
            ...prev,
            [field]: prev[field].filter(e => e.publicId !== id)
        }))
    }

    const handleSetAllowTill = (newValue: string) => {
        // console.log(JSON.stringify({...personalForm.form, allowTillDate: newValue}));
        newValue && setForm(prev => prev && ({...prev, allowedDate: newValue}));
    }



    //TODO
    const isOk = () => {
        if (isCustomApproach) return conflict && form && form.reason.trim().length > 0 && form.reason.trim().length > 0 && form.allowedDate.length > 0 && form.reviewers.length > 0 && form.signers.length > 0;
        else return !!form && conflict && form.resolution.trim().length > 0 && form.reason.trim().length > 0 && form.allowedDate.length > 0;
    }


    const handleSave = () => {
        if(isCustomApproach && isOk() && conflict) handleSaveAllowConflictCustom({
            workspaceId: '',
            organizationId: '',
            conflict,
            data: {
                reason: form.reason,
                account: conflict.account,
                resolution: form.resolution,
                allowedDate: form.allowedDate,
                active: form.active,
                deniedBy: "",
            },
            readers: form.readers,
            signers: form.signers,
            reviewers: form.reviewers,
        });
        else if (isOk() && conflict) handleSaveAllowConflictStandard({
            workspaceId: '',
            organizationId: '',
            conflict,
            data: {
                reason: form.reason,
                account: conflict.account,
                resolution: form.resolution,
                allowedDate: form.allowedDate,
                active: form.active,
                deniedBy: null,
            }
        })
    }


    useEffect(() => {
        if (isOpen) {
            setTab('readers');
            setForm(initAllowConflictData)
        }


        //eslint-disable-next-line
    }, [isOpen]);



    return {
        isOk: isOk(),
        isOpen,
        form, handleChange,
        tab, setTab,
        isCustomApproach,
        setIsCustomApproach,
        search, setSearch,
        filteredCollaborators: collaborators.filter(e => e.firstName.toLowerCase().includes(search.toLowerCase()) || e.lastName.toLowerCase().includes(search.toLowerCase()) || e.email.toLowerCase().includes(search.toLowerCase())),
        isLoadingData,
        isLoadingAllow,
        handleHideAllowConflict,
        handleSave,
        handleCleanSearch,
        handleSetCollaborator,
        handleDeleteCollaborator,
        handleSetAllowTill,
    }
}