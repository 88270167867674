import {Handle, Position} from "react-flow-renderer";
import {ALLOWDRAG} from "../../../constants";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import {useDispatch} from "react-redux";
import {openTriggerDate} from "../../../store/slice";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Box, Flex} from "../../../../../../newShared/components/Layouts";
import {Typo} from "../../../../../../newShared/components/Typography";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";

type dateNodeProps = {
    date: string;
    revDir: boolean
}

export const DateNode = ({data} : {data: dateNodeProps}) => {
    const {revDir, date} = data;
    const {t} = useMainTranslation('', {keyPrefix: 'automations'});

    const dispatch = useDispatch();

    const handleSetting = () => {dispatch(openTriggerDate())};

    return(
        <Box
            w={'165px'}
            h={'50px'}
            br={'11px'}
            background={'#3BCC89'}
            box_shadow={'0px 0px 5.853034973144531px 0px rgba(0, 0, 0, 0.12)'}
            // ai={'center'}
            // jc={'center'}
            p={'5px'}
            className={ALLOWDRAG ? 'nodrag': ''}
            cursor={'default'}
        >
            <Handle
                type="target"
                position={!revDir ? Position.Left : Position.Right}
                id="a"
                isConnectable={false}
                style={{opacity: '0'}}
            />

            <Flex ai={'center'} direction={'column'} jc={'space-evenly'}>
                <Typo fontWeight={400} fontSize={'12px'} margin={'0 0 5px'} sx={{lineHeight: 'normal'}}>{t('Start at')} </Typo>
                <Typo fontWeight={400} fontSize={'12px'} style={{direction: revDir ? 'rtl' : 'ltr'}} sx={{lineHeight: 'normal'}}>{parseDateAuto(date, true)}</Typo>
            </Flex>

            <Flex style={{position: 'relative', bottom: '16px', right: !revDir ? '-170px' : '30px'}}>
                <CalendarTodayOutlinedIcon
                    style={{width: '18px', height: '18px', color: '#5D5D5D', cursor: 'pointer'}}
                    onClick={handleSetting}
                />
            </Flex>
        </Box>
    )
}