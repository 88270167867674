import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDispatch} from "react-redux";
import {deleteDutyRuleAction} from "../../../store/actions";
import {hideDeleteDutyRule} from "../../../store/slice";
import {useSegregationOfDuties} from "../../useSegregationOfDuties";


export const useDeleteRule = () => {
    const dispatch = useDispatch();
    const {setMessage} = useMessageDialog();
    const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagement'});

    const {
        goToRuleset,
        loadings: {delete: isLoadingDelete},
        dialogs: {deleteDutyRule: {isOpen, selectedDutyRule: rule}}
    } = useSegregationOfDuties();


    const onSuccess = () => {
        // setMessage({title: t("Completed successfully"), message: `${t('The rule')} ${rule?.name} ${t('has been deleted.')}`});
        setMessage({title: t("Completed successfully"), message: t('The rule {{name}} has been deleted.', {name: rule?.name || ''})});
        goToRuleset();
    }
    const handleDelete = () =>  {
        rule && dispatch(deleteDutyRuleAction({data: {workspaceId: '', organizationId: '', id: rule.id}, onSuccess}));
    }

    const handleCancel = () => {
        dispatch(hideDeleteDutyRule());
    }

    return {
        isOpen,
        handleDelete,
        handleCancel,
        isLoadingDelete,
    }
}