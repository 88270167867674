import {TrainingContentNewModel} from "../../../../../../../../newShared/GQLTypes";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import colors from "../../../../../../../../newShared/theme/colors";

type DeleteSectionDialogPropsType = {
    handleClose: () => void,
    section:  TrainingContentNewModel | null,
    handleDeleteSection: (section:  TrainingContentNewModel) => void,
}
export const DeleteSectionDialog = ({section, handleDeleteSection, handleClose}: DeleteSectionDialogPropsType) => {
    return(
        <Dialog open={section !== null} onClose={handleClose}>
            <DialogTitle>{('Delete section')}</DialogTitle>

            <DialogContent>
                <Typography variant={'*bodyText2'} color={colors.grayText}>
                    {('Are you sure you want to delete ')}
                    <b>{(section?.name)}</b>
                    {(' section?')}
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button variant={'text'} size={'small'} onClick={handleClose}>{('Cancel')}</Button>
                <Button size={'small'} onClick={() => section && handleDeleteSection(section)}>{('Delete')}</Button>
            </DialogActions>
        </Dialog>
    )
}