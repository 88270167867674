import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {TMessage} from "../types";

export type TMessageDialogSlice = {
    message: TMessage | null,
}

const initialState: TMessageDialogSlice = {
    message: null
}

const Slice = createSlice({
    name: 'messageDialog',
    initialState,
    reducers: {
        setMessageDialogAction(slice: TMessageDialogSlice, {payload}: {payload: TMessage | null}) {
            slice.message = payload;
        },
    },
    // extraReducers: builder => {
    //   builder
    //
    // }
});

export const messageDialogReducerReducer = Slice.reducer;

export const {
    setMessageDialogAction
} = Slice.actions;

const selectSelf = (state: AppState): TMessageDialogSlice => state.messageDialog;

export const messageDialogSelector = createSelector(selectSelf, state => state.message);
