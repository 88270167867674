import cookies from 'js-cookie';
import {useEffect, useState} from "react";
import {SUPPORTED_LANGUAGES} from "../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {selectedLanguage, selectLanguage} from "../slice";
import {basicTheme} from "../../../theme/basicTheme";
import * as locales from '@mui/material/locale';
import {createTheme, Theme} from "@mui/material";


export const useMainTranslationHookInit = () => {
    const dispatch = useDispatch();
    const currLanguage = useSelector(selectedLanguage);
    const [currTheme, setTheme] = useState<Theme>(createTheme(basicTheme, locales[currLanguage.locale]));

    useEffect(() => {
        const currentCookie = cookies.get('i18next') || 'en';
        const currentLanguage = SUPPORTED_LANGUAGES.find((e) => e.code === currentCookie);
        if(currentLanguage) dispatch(selectLanguage(currentLanguage));
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        // console.log(`useMainTranslationHookInit: useEffect [${JSON.stringify(currLanguage)}]`);
        document.body.dir = currLanguage?.dir || 'ltr';
        setTheme(createTheme({...basicTheme, direction: currLanguage.dir}, locales[currLanguage.locale]));
        // i18next.changeLanguage(currLanguage.code);
    }, [currLanguage]);

    // const themeWithLocale = React.useMemo(
    //     () => createTheme(muiTheme, locales[currLanguage.locale]),
    //     [currLanguage],
    // );

    return{
        revDir: currLanguage.dir === 'rtl',
        themeWithLocale: currTheme
    }
}

