import {TPageInfo} from "../../types";

export const setPaginationInStore = <T>(
    oldPageInfo: TPageInfo, oldArray: (T | null)[],
    payloadPageInfo: TPageInfo, payloadArray: T[],
    isNewOnTop: boolean = false
) => {
    if (oldPageInfo.total === 0) { // initial request
        oldArray = payloadArray;
        oldPageInfo = payloadPageInfo;
    } else {                                           // not initial request
        const diffTotal = payloadPageInfo.total - oldPageInfo.total;
        const tmp = diffTotal > 0 && isNewOnTop
            ? [...Array(payloadPageInfo.total - oldPageInfo.total).fill(null) as null[], ...oldArray]
            : oldArray;

        tmp.splice(payloadPageInfo.page * payloadPageInfo.count, payloadPageInfo.count, ...payloadArray);
        oldArray = tmp;

        if (payloadPageInfo.page >= oldPageInfo.page) oldPageInfo = payloadPageInfo
        else oldPageInfo.total = payloadPageInfo.total;
    }

    return {array: oldArray, pageInfo: oldPageInfo}
}