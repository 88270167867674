import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {cleanUp} from "../../store/builder/slice";


export const useMain = () => {
    const dispatch = useDispatch();

    useEffect(() => {

        return () => {
            dispatch(cleanUp());
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}