import {TRecipientStepProps} from "../index";
import {useState} from "react";
import {isExpired} from "../../../../../../../utils/dateTools";
import {useMainTranslation} from "../../../../../../../hooks/useMainTranslationHooks/useMainTranslation";

export const useRecipientStep = (props: TRecipientStepProps) => {
    const {currentLanguage: {momentLocale}} = useMainTranslation();
    const [collapsed, setCollapsed] = useState<boolean>(false);

    const toggleCollapse = () => {
        setCollapsed(!collapsed)
    }

    const _isExpired = isExpired(props.step.endDate || new Date().toISOString(), momentLocale);

    return{
        isExpired: _isExpired,
        collapsed,
        toggleCollapse,
    }
}