import {PATH_LOCAL_RISK_MANAGEMENT} from "../../../newShared/constants";
import {tControl} from "./types";

export const PATH_RISK_MANAGEMENT_MODELS_ALL = `${PATH_LOCAL_RISK_MANAGEMENT}/models/all`;
export const PATH_RISK_MANAGEMENT_MODELS_EXACT = `${PATH_LOCAL_RISK_MANAGEMENT}/models/exact/:id`;
export const PATH_RISK_MANAGEMENT_MODELS_NEW = `${PATH_LOCAL_RISK_MANAGEMENT}/models/new`;
export const PATH_RISK_MANAGEMENT_MODELS_EXACT_EDIT = `${PATH_LOCAL_RISK_MANAGEMENT}/models/exact/:id/edit`;

export const PATH_RISK_MANAGEMENT_RISK_REGISTRIES_ALL = `${PATH_LOCAL_RISK_MANAGEMENT}/registries/all`;
export const PATH_RISK_MANAGEMENT_RISK_REGISTRIES_EXACT = `${PATH_LOCAL_RISK_MANAGEMENT}/registries/exact/:id`;
export const PATH_RISK_MANAGEMENT_RISK_REGISTRIES_NEW = `${PATH_LOCAL_RISK_MANAGEMENT}/registries/new`;

export const REGISTRIES_DEFAULT_COUNT = 30;
export const REGISTRY_RISKS_DEFAULT_COUNT = 30;
export const PARSE_DATE_CONFIG = 'D/M/YYYY';

export const controls: tControl[] = [
    {type: 'None', description: ''},
    {type: 'Avoid', description: 'This option is about making the uncertain situation certain by removing the risk, often by removing the cause of the risk'},
    {type: 'Reduce', description: 'This option is about taking action now to change the probability and/or impact of a risk'},
    {type: 'Transfer', description: 'This option is about transferring part of the risk to a third-party (e.g. taking an insurance policy'},
    {type: 'Share', description: 'This option is about sharing the risk with another entity or multiple entities, in order to minimize the potential impact on one single entity'},
    {type: 'Accept', description: 'This option means that the organization decides to accept that a risk may occur, and decides to take no action, exposing itself to the full possible impact'},
    {type: 'Prepare a contingency plan', description: 'This option means that the organization decides to accept that a risk may occur, and decides to take no immediate action, but puts in place a series of contingencies to deal with the potential impact'},
]

export const firstNoneObject = {quantitative: -777, quality: '', description: ''};

