import {styled} from "@mui/material";

type EditorItemListStyledProps = {
    listStyle?: string,
    isRoot?: boolean,
    total: number,
}
export const EditorItemListStyled = styled('ul', {shouldForwardProp: (propName) => (
    !['listStyle', 'isRoot', 'total'].includes(propName.toString())
    )})<EditorItemListStyledProps>(({listStyle, isRoot, total}) => ({

    listStyle: listStyle,

    '&, & * ul': {
        listStyle: isRoot ? listStyle + ' !important' : undefined,
        paddingLeft: listStyle && ['decimal'].includes(listStyle) ? `${10 + String(total).length * 10}px` : '20px',
    },

    paddingBlock: isRoot ? '4px' : undefined,

    '& li::marker': {
        fontSize: '16px',
        fontFamily: 'Arial',
    },
}))

type EditorItemListLiStyledProps = {}
export const EditorItemListLiStyled = styled('li')<EditorItemListLiStyledProps>({
    [`& > div.TextComponentWrapper`]: {
        padding: '4px',
    },

})
