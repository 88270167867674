import {Flex} from "../../../../../newShared/components/Layouts";
import {useEmployeesExact} from "../../hooks/useEmployeesExact";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import React, {FC, useEffect, useState} from "react";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {MinimizeInfoWrapper} from "../../../../../newShared/components/minimizeInfoWrapper";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    FormGroup,
    Paper,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import * as S from "../../components/styled";
import {TabContext, TabPanel} from "@mui/lab";
import {ExactEmployeeTabs, TShortDocumentByEmail} from "../../types";
import {DEFAULT_DATE_FORMAT, parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {sortEducation} from "../../helpers";
import {PHONE_NUMBER_CONTACT_TYPE} from "../../constants";
import {ValueGetter} from "../../components/valueGetter";
import {JobHistoryTable} from "../../components/jobHistoryTable";
import {CategoryTitle} from "../../components/categoryTitle";
import {CategoryFieldsContainer} from "../../components/categoryFieldsContainer";
import {AddSettingsDialog} from "../../components/dialogs/addSettingsDialog";
import {ChangeHireDateDialog} from "../../components/dialogs/changeHireDateDialog";
import {ChangeEmploymentStatusDialog} from "../../components/dialogs/changeEmploymentStatusDialog";
import {AddJobRecordDialog} from "../../components/dialogs/addJobRecordDialog";
import {UpdateJobRecordDialog} from "../../components/dialogs/updateJobRecordDialog";
import {DeleteJobRecordDialog} from "../../components/dialogs/deleteJobRecordDialog";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

//icons
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SchemaIcon from "@mui/icons-material/Schema";
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from "@mui/icons-material/Edit";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import HomeIcon from '@mui/icons-material/Home';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import AddIcon from '@mui/icons-material/Add';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import WorkIcon from '@mui/icons-material/Work';
import EventIcon from '@mui/icons-material/Event';
import {EmployeeServicesHistoryHeaderRow} from "../../components/EmployeeServicesHeaderRow";
import {EmployeeServiceRow} from "../../components/EmployeeServiceRow";
import {DeleteEmployeeDialog} from "../../components/dialogs/deleteEmployeeDialog";
import {PaperTypeMap} from "@mui/material/Paper/Paper";
import {DiscardGeneralInfoChangesDialog} from "../../components/dialogs/discardGeneralInfoChangesDialog";
import {useJobInfosTableOrCardsScroll} from "../../hooks/useJobInfosTableOrCardsScroll";
import {LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {Spinner} from "../../../../../newShared/components/Spinner";
import {DocViewerDialog} from "../../../../../newShared/components/docViewer";
import {getName, validateEmail} from "../../../../../newShared/utils/text";
import {FlexRow} from "../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import {Done, InfoOutlined, WarningAmberOutlined} from "@mui/icons-material";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {useLazyQuery} from "@apollo/client";
import {
    CheckCollaboratorIsNotAssignedQuery,
    CheckCollaboratorIsNotAssignedQueryVariables
} from "../../../../../newShared/GQLTypes";
import {checkCollaboratorIsNotAssigned} from "../../api/query";
import {optionIsTCollaborator} from "../../../../../newShared/components/AutocompleteCollaborators/types";
import {AutocompleteCollaborators} from "../../../../../newShared/components/AutocompleteCollaborators";
import {v4 as uuid} from "uuid";

export const EmployeesExact = () => {
    const {workspaceId} = UseManageWorkspacesAndOrganizations();
    const {
        topBarActions,
        employee,
        isLoading,
        lastHistory,
        form,
        handleChange,
        setForm,
        phoneNumber,
        tab,
        handleSetTab,
        settings,

        generalInfo,
        jobInfo,
        employeeDocuments: {
            documents,
            //selectedNewDocument,
            isLoadingDocument,
            paging,
            filterConfigs,
            handleOpenDocument,
            handleDownload,
            isLoadingDocumentId,
            //currentEmail,
            //snack,
            //actions,
        },
    } = useEmployeesExact();
    const scroll = useJobInfosTableOrCardsScroll();
    const {isLessThen1050, isMobile, isTablet} = useMedia();
    const {t, revDir, tCommon} = useMainTranslation('', {keyPrefix: 'pathHRManagement.exact'});
    const {t: tList} = useMainTranslation('', {keyPrefix: 'pathHRManagement.list'});
    // const {tCommon} = useMainTranslation();
    const inputWidth = isMobile ? '100%' : (isTablet ? '49%' : '32%');
    const inputBackgroundColor = colors.backgrounds.white;

    const [uniqueInputId, setUniqueInputId] = useState<string>(uuid());
    const [checkWorkEmail, {loading: isCheckingEmail, data: checkEmailData}] =
        useLazyQuery<CheckCollaboratorIsNotAssignedQuery, CheckCollaboratorIsNotAssignedQueryVariables>(checkCollaboratorIsNotAssigned, {
            fetchPolicy: "network-only",
        });
    useEffect(() => {
        if (validateEmail(form.workEmail)) {
            checkWorkEmail({
                variables: {
                    workspaceId: workspaceId || '',
                    workEmail: form.workEmail
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.workEmail]);

    useEffect(() => {
        setUniqueInputId(uuid());
    }, [form.employeeId]);

    useEffect(() => {
        setForm(prev => prev.workEmail === checkEmailData?.checkCollaboratorIsNotAssigned?.collaborator?.email ? ({...prev, collaborator: checkEmailData?.checkCollaboratorIsNotAssigned?.collaborator}) : prev);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkEmailData?.checkCollaboratorIsNotAssigned?.collaborator])

    const AddContactTypeLink: FC<PaperTypeMap["props"] & {index: number}> = ({ children, index, ...other }: any) => (
        <Paper {...other}>
            <Button onMouseDown={() => generalInfo.openAddContactType(index)} variant="text" startIcon={<AddIcon fontSize={'small'}/>} sx={{color: colors.primary.blue, width: '100%'}}>
                {t('Add new')}
            </Button>
            {children}
        </Paper>
    );

    const AddRelationshipTypeLink: FC<PaperTypeMap["props"] & {index: number}> = ({ children, index, ...other }: any) => (
        <Paper {...other}>
            <Button onMouseDown={() => generalInfo.openAddRelationshipType(index)} variant="text" startIcon={<AddIcon fontSize={'small'}/>} sx={{color: colors.primary.blue, width: '100%'}}>
                {t('Add new')}
            </Button>
            {children}
        </Paper>
    );

    const AddDegreeLink:  FC<PaperTypeMap["props"] & {index: number}> = ({ children, index, ...other }: any) => (
        <Paper {...other}>
            <Button onMouseDown={() => generalInfo.openAddDegree(index)} variant="text" startIcon={<AddIcon fontSize={'small'}/>} sx={{color: colors.primary.blue, width: '100%'}}>
                {t('Add new')}
            </Button>
            {children}
        </Paper>
    );

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'} p={'0 0 16px 0'}>
            <PageTitleAndActions
                title={employee ? `${employee.firstName} ${employee.lastName}` : undefined}
                handleGoBack={topBarActions.handleGoBack}
                showMenuWhen={isLessThen1050}
                actions={[
                    {
                        variant: 'text',
                        title: tList('Org tree'),
                        onClick: topBarActions.handleGoToTree,
                        icon: <SchemaIcon fontSize="small"/>,
                        disabled: !employee || isLoading,
                        dontShowWhen: !employee?.status || employee.status.name?.toLowerCase() === 'terminated',
                    },
                    {
                        variant: 'text',
                        title: tList('Delete employee'),
                        onClick: topBarActions.handleDelete,
                        icon: <DeleteForeverIcon fontSize="small"/>,
                        disabled: !employee || isLoading,
                    }
                ]}
            />

            <MinimizeInfoWrapper text={t('Overview')}>
                <S.EmployeeOverviewWrapper>
                    <S.EmployeeOverviewSubWrapper>
                        <Flex m={'16px 0 0 0'} ai={'center'}>
                            <Typography variant={"body1"}  sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', color: colors.text.grey_dark}}>{t('Job title')}</Typography>
                            <Typography variant={"subtitle2"}  sx={{color: colors.grayText}}>{lastHistory?.jobTitle.name}</Typography>
                        </Flex>

                        <Flex m={'16px 0 0 0'} ai={'center'}>
                            <Typography variant={"body1"}  sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', color: colors.text.grey_dark}}>{t('Department')}</Typography>
                            <Typography variant={"subtitle2"}  sx={{color: colors.grayText}}>{lastHistory?.department}</Typography>
                        </Flex>

                        <Flex m={'16px 0 0 0'} ai={'center'}>
                            <Typography variant={"body1"}  sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', color: colors.text.grey_dark}}>{t('Employment type')}</Typography>
                            <Typography variant={"subtitle2"}  sx={{color: colors.grayText}}>{lastHistory?.employmentType}</Typography>
                        </Flex>
                    </S.EmployeeOverviewSubWrapper>

                    <S.EmployeeOverviewSubWrapper>
                        <Flex m={'16px 0 0 0'} ai={'center'}>
                            <Typography variant={"body1"}  sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', color: colors.text.grey_dark}}>{t('Phone number')}</Typography>
                            <Typography variant={"subtitle2"}  sx={{color: colors.grayText}}>{phoneNumber}</Typography>
                        </Flex>

                        <Flex m={'16px 0 0 0'} ai={'center'}>
                            <Typography variant={"body1"}  sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', color: colors.text.grey_dark}}>{t('Email')}</Typography>
                            <Typography variant={"subtitle2"}  sx={{color: colors.grayText}}>{form.workEmail}</Typography>
                        </Flex>

                        <Flex m={'16px 0 0 0'} ai={'center'}>
                            <Typography variant={"body1"}  sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px', color: colors.text.grey_dark}}>{t('Manager')}</Typography>
                            <Typography variant={"subtitle2"}  sx={{color: colors.grayText}}>{lastHistory?.manager?.name ?? '-'}</Typography>
                        </Flex>
                    </S.EmployeeOverviewSubWrapper>
                </S.EmployeeOverviewWrapper>
            </MinimizeInfoWrapper>

            <TabContext value={tab}>
                <Box sx={{ margin: '8px 0 0 0'}}>
                    <Tabs variant={'scrollable'} value={tab} onChange={(e, newValue: ExactEmployeeTabs) => handleSetTab(newValue)}>
                        <Tab label={t("General")} value={'general'}/>
                        <Tab label={t("Job info")} value={'jobInfo'}/>
                        <Tab label={t("Documents")} value={'docs'} />
                        <Tab label={t("Trainings")} value={'trainings'} disabled/>
                        <Tab label={t("Assets")} value={'assets'} disabled/>
                        <Tab label={t("Accounts")} value={'accounts'}/>
                    </Tabs>
                </Box>

                <TabPanel value={'general'} sx={{padding: '33px 0 0 0', overflow: 'auto', height: '100%'}}>
                    <Flex w={'100%'} direction={'column'}>
                        <Flex ai={'center'} gap={'16px'}>
                            {generalInfo.isEditGeneral ?
                                <>
                                    <LoadingButton
                                        onClick={generalInfo.handleSaveGeneralInfo}
                                        disabled={!generalInfo.isOk || isCheckingEmail || (checkEmailData?.checkCollaboratorIsNotAssigned?.isEmployeeExist && form.workEmail !== employee?.workEmail)}
                                        loading={generalInfo.isLoadingUpdate}
                                        size={'small'}
                                    >{tCommon('Save')}</LoadingButton>

                                    <Button
                                        onClick={generalInfo.handleCancelChanges}
                                        size={'small'}
                                        variant={'outlined'}
                                    >{tCommon('Cancel')}</Button>
                                </>
                                :
                                <Button
                                    onClick={() => generalInfo.setIsEditGeneral(true)}
                                    startIcon={<EditIcon />}
                                    size={'small'}
                                >{tCommon('Edit')}</Button>
                            }
                        </Flex>

                        {generalInfo.isEditGeneral ?
                            <Flex w={'100%'} h={'100%'} direction={'column'}>
                                <CategoryTitle
                                    disableDivider
                                    name={t('Personal information')}
                                    icon={<PersonIcon style={{color: colors.primary.blue_dark}}/>}
                                    margin={'18px 0 0 0'}
                                />
                                <CategoryFieldsContainer>
                                    <Flex w={'100%'} wrap={'wrap'} gap={'16px'}>
                                        <TextField
                                            value={form.firstName}
                                            onChange={handleChange}
                                            label={t('First name')}
                                            name={'firstName'}
                                            required
                                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                            size={'small'}
                                        />

                                        <TextField
                                            value={form.middleName}
                                            onChange={handleChange}
                                            label={t('Middle name')}
                                            name={'middleName'}
                                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                            size={'small'}
                                        />

                                        <TextField
                                            value={form.lastName}
                                            onChange={handleChange}
                                            label={t('Last name')}
                                            name={'lastName'}
                                            required
                                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                            size={'small'}
                                        />

                                        <TextField
                                            value={form.nickname}
                                            onChange={handleChange}
                                            label={t('Nickname')}
                                            name={'nickname'}
                                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                            size={'small'}
                                        />

                                        <DatePicker
                                            label={t('Birth date')}
                                            inputFormat={DEFAULT_DATE_FORMAT}
                                            value={form.birthDay}
                                            onChange={(newValue) => {generalInfo.handleSetBirthday(newValue)}}
                                            renderInput={(params) => <TextField {...params} sx={{width: inputWidth, backgroundColor: inputBackgroundColor}} size={'small'} />}
                                        />

                                        <Autocomplete
                                            options={settings.gender}
                                            value={form.gender}
                                            onChange={(e, newValue) => generalInfo.handleSetGender(newValue)}
                                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                            renderInput={(params) => <TextField {...params} label={t('Gender')} size={'small'} required/>}
                                        />

                                        <TextField
                                            value={form.employeeId}
                                            onChange={handleChange}
                                            label={t('ID')}
                                            name={'employeeId'}
                                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                            size={'small'}
                                        />

                                        <Autocomplete
                                            options={settings.maritalStatus}
                                            value={form.maritalStatus}
                                            onChange={(e, newValue) => generalInfo.handleSetMartialStatus(newValue)}
                                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                            renderInput={(params) => <TextField {...params} label={t('Marital status')} size={'small'} required/>}
                                        />

                                        <Autocomplete
                                            options={settings.ethnicity}
                                            value={form.ethnicity}
                                            onChange={(e, newValue) => generalInfo.handleSetEthnicity(newValue)}
                                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                            renderInput={(params) => <TextField {...params} label={t('Ethnicity')} size={'small'}/>}
                                        />

                                        <AutocompleteCollaborators<false, true, true>
                                            key={uniqueInputId}
                                            label={t('Work email')}
                                            getOptionLabelCustom={"name+email"}
                                            freeSolo
                                            freeSoloType={'email'}
                                            defaultValue={form.workEmail || ''}
                                            sx={{
                                                width: inputWidth,
                                                '* .MuiInputBase-root': { backgroundColor: inputBackgroundColor },
                                            }}
                                            required
                                            size={'small'}
                                            shouldForwardErrorValue
                                            onChange={value => setForm(prev => ({
                                                ...prev,
                                                workEmail: optionIsTCollaborator(value) ? value.email : (value || ''),
                                                collaborator: optionIsTCollaborator(value) ? value : null,
                                            }))}
                                        />

                                        {validateEmail(form.workEmail) && (
                                            <FlexRow sx={{alignItems: 'center', gap: '8px', flexShrink: 1}}>
                                                {isCheckingEmail ? <CircularProgress size={20}/> : (
                                                    checkEmailData?.checkCollaboratorIsNotAssigned?.isEmployeeExist && form.workEmail !== employee?.workEmail ? (
                                                        <>
                                                            <WarningAmberOutlined sx={{color: colors.text.critical}}/>

                                                            <Typography variant={'*bodyText2'} color={colors.text.critical}>
                                                                {t(`Email “{{email}}” is already assigned to another collaborator in the workspace. Please select another email.`, {
                                                                    email: form.workEmail
                                                                })}
                                                            </Typography>
                                                        </>
                                                    ) : (
                                                        checkEmailData?.checkCollaboratorIsNotAssigned?.collaborator ? (
                                                            <>
                                                                <Done sx={{color: colors.text.success}}/>

                                                                <Typography variant={'*bodyText2'} color={colors.text.success}>
                                                                    {t('{{name}} connected successfully.', {
                                                                        name: `${getName(checkEmailData?.checkCollaboratorIsNotAssigned?.collaborator.firstName || '', checkEmailData?.checkCollaboratorIsNotAssigned?.collaborator.lastName || '')} (${checkEmailData?.checkCollaboratorIsNotAssigned?.collaborator.email})`
                                                                    })}
                                                                </Typography>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <InfoOutlined sx={{color: colors.text.warning}}/>

                                                                <Typography variant={'*bodyText2'} color={colors.text.warning}>
                                                                    {t('Collaborator not found for the provided work email. Some services may be unavailable. Verify the email or add a new collaborator in workspace settings.')}
                                                                </Typography>
                                                            </>
                                                        )
                                                    )
                                                )}
                                            </FlexRow>
                                        )}
                                    </Flex>
                                </CategoryFieldsContainer>

                                <CategoryTitle
                                    disableDivider
                                    name={t('Education')}
                                    icon={<MenuBookIcon style={{color: colors.primary.blue_dark}}/>}
                                    button={{text: t('Add Education'), onClick: generalInfo.handleAddEducation, icon: <AddIcon />  }}/>

                                {!form.personalData.education.length &&
                                    <CategoryFieldsContainer disableRequiredFieldWarning>
                                        <Typography color={colors.text.grey} fontSize={'13px'} fontWeight={400}>{t('No education records')}</Typography>
                                    </CategoryFieldsContainer>
                                }
                                {form.personalData.education.map((e, id) => {
                                    return(
                                        <CategoryFieldsContainer onDelete={() => generalInfo.handleDeleteEducation(id)}>
                                            <Flex w={'100%'} wrap={'wrap'} gap={'16px'}>
                                                <TextField
                                                    value={e.schoolName}
                                                    onChange={(event) => generalInfo.handleChangeEducation(event, id)}
                                                    label={t('School name')}
                                                    name={'schoolName'}
                                                    required
                                                    sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                                    size={'small'}
                                                />

                                                <Autocomplete
                                                    options={Object.keys(settings.degree)}
                                                    value={e.degree}
                                                    onChange={(e, newValue) => generalInfo.handleChangeEducationDegree(newValue, id)}
                                                    sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                                    renderInput={(params) => <TextField {...params} label={t('Degree')} size={'small'} required/>}
                                                    PaperComponent={(props) => <AddDegreeLink {...props} index={id}/>}
                                                />

                                                <TextField
                                                    value={e.fieldOfStudy}
                                                    onChange={(event) => generalInfo.handleChangeEducation(event, id)}
                                                    label={t('Field of study')}
                                                    name={'fieldOfStudy'}
                                                    sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                                    size={'small'}
                                                />

                                                <DatePicker
                                                    label={t('Start date')}
                                                    inputFormat={DEFAULT_DATE_FORMAT}
                                                    value={e.startDate}
                                                    onChange={(newValue) => {generalInfo.handleSetEducationStartDate(newValue, id)}}
                                                    renderInput={(params) => <TextField {...params} sx={{width: inputWidth, backgroundColor: inputBackgroundColor}} size={'small'} />}
                                                />

                                                <DatePicker
                                                    label={t('End date')}
                                                    inputFormat={DEFAULT_DATE_FORMAT}
                                                    value={e.endDate}
                                                    onChange={(newValue) => {generalInfo.handleSetEducationEndDate(newValue, id)}}
                                                    renderInput={(params) => <TextField {...params} sx={{width: inputWidth, backgroundColor: inputBackgroundColor}} size={'small'} />}
                                                />
                                            </Flex>
                                        </CategoryFieldsContainer>
                                    )
                                })}

                                <CategoryTitle
                                    name={t('Address')}
                                    icon={<HomeIcon style={{color: colors.primary.blue_dark}}/>}
                                />

                                <CategoryFieldsContainer>
                                    <Flex>
                                        <Autocomplete
                                            options={settings.country}
                                            value={form.contactInfo.address.country}
                                            onChange={(e, newValue) => generalInfo.handleSetAddressCountry(newValue)}
                                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                            renderInput={(params) => <TextField {...params} label={t('Country')} size={'small'} required/>}
                                        />
                                    </Flex>

                                    <Flex w={'100%'} wrap={'wrap'} gap={'16px'} m={'16px 0 0 0'}>
                                        <TextField
                                            value={form.contactInfo.address.city}
                                            onChange={generalInfo.handleChangeAddress}
                                            label={t('City')}
                                            name={'city'}
                                            required
                                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                            size={'small'}
                                        />

                                        <TextField
                                            value={form.contactInfo.address.street}
                                            onChange={generalInfo.handleChangeAddress}
                                            label={t('Street')}
                                            name={'street'}
                                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                            size={'small'}
                                        />

                                        <TextField
                                            value={form.contactInfo.address.zip}
                                            onChange={generalInfo.handleChangeAddress}
                                            label={t('ZIP code')}
                                            name={'zip'}
                                            sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                            size={'small'}
                                        />
                                    </Flex>
                                </CategoryFieldsContainer>

                                <CategoryTitle
                                    name={t('Contacts')}
                                    icon={<LocalPhoneIcon style={{color: colors.primary.blue_dark}}/>}
                                    button={{text: t('Add contact'), onClick: generalInfo.handleAddContact, icon: <AddIcon />}}
                                />

                                {form.contactInfo.contacts.map((e, id) => {
                                    return(
                                        <CategoryFieldsContainer onDelete={e.type === PHONE_NUMBER_CONTACT_TYPE ? undefined : () => generalInfo.handleDeleteContact(id)}>
                                            <Flex w={'100%'} wrap={'wrap'} gap={'16px'}>
                                                <Autocomplete
                                                    disabled={e.type === PHONE_NUMBER_CONTACT_TYPE}
                                                    options={settings.contactType}
                                                    getOptionDisabled={(o) => form.contactInfo.contacts.some(e => e.type === o)}
                                                    value={e.type}
                                                    onChange={(e, newValue) => generalInfo.handleSetContactType(id, newValue)}
                                                    sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                                    renderInput={(params) => <TextField {...params} label={t('Contact type')} size={'small'} required/>}
                                                    PaperComponent={(props) => <AddContactTypeLink {...props} index={id}/>}
                                                />

                                                <TextField
                                                    value={e.value}
                                                    onChange={(e) => generalInfo.handleChangeContactValue(id, e.currentTarget.value)}
                                                    label={t('Value')}
                                                    required
                                                    sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                                    size={'small'}
                                                />
                                            </Flex>
                                        </CategoryFieldsContainer>
                                    )
                                })}

                                <CategoryTitle
                                    name={t('Emergency contacts')}
                                    icon={<ContactPhoneIcon style={{color: colors.primary.blue_dark}}/>}
                                    button={{text: t('Add contact'), onClick: generalInfo.handleAddEmergencyContact, icon: <AddIcon />}}/>

                                {!form.contactInfo.emergencyContacts.length &&
                                    <CategoryFieldsContainer disableRequiredFieldWarning>
                                        <Typography color={colors.text.grey} fontSize={'13px'} fontWeight={400}>{t('No emergency contacts')}</Typography>
                                    </CategoryFieldsContainer>
                                }
                                {form.contactInfo.emergencyContacts.map((e, id) => {
                                    return(
                                        <CategoryFieldsContainer onDelete={() => generalInfo.handleDeleteEmergencyContact(id)}>
                                            <Flex w={'100%'} wrap={'wrap'} gap={'16px'}>
                                                <TextField
                                                    value={e.fullName}
                                                    onChange={(e) => generalInfo.handleChangeEmergencyContact(e, id)}
                                                    label={t('Full name')}
                                                    name={'fullName'}
                                                    required
                                                    sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                                    size={'small'}
                                                />

                                                <Autocomplete
                                                    options={Object.keys(settings.relationshipType)}
                                                    value={e.relationType}
                                                    onChange={(e, newValue) => generalInfo.handleSetEmergencyContactType(id, newValue)}
                                                    sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                                    renderInput={(params) => <TextField {...params} label={t('Relationship to employee')} size={'small'} required/>}
                                                    PaperComponent={(props) => <AddRelationshipTypeLink {...props} index={id}/>}
                                                />

                                                <TextField
                                                    value={e.phone}
                                                    onChange={(e) => generalInfo.handleChangeEmergencyContact(e, id)}
                                                    label={t('Phone')}
                                                    name={'phone'}
                                                    required
                                                    sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                                    size={'small'}
                                                />

                                                <TextField
                                                    value={e.email}
                                                    onChange={(e) => generalInfo.handleChangeEmergencyContact(e, id)}
                                                    label={t('Email')}
                                                    name={'email'}
                                                    required
                                                    sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                                    size={'small'}
                                                />

                                                <TextField
                                                    value={e.address}
                                                    onChange={(e) => generalInfo.handleChangeEmergencyContact(e, id)}
                                                    label={t('Address')}
                                                    name={'address'}
                                                    required
                                                    sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                                    size={'small'}
                                                />
                                            </Flex>
                                        </CategoryFieldsContainer>
                                    )
                                })}

                                <CategoryTitle
                                    name={t('Veteran status')}
                                    icon={<MilitaryTechIcon style={{color: colors.primary.blue_dark}}/>}
                                />

                                <CategoryFieldsContainer disableRequiredFieldWarning>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={form.personalData.veteranStatus.activeDutyWartimeOrCampaignBadgeVeteran}
                                                onChange={(e) => generalInfo.handleChangeVeteranStatus("activeDutyWartimeOrCampaignBadgeVeteran", e.target.checked)}
                                            />}
                                            label={t('Active duty wartime or campaign badge veteran')}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={form.personalData.veteranStatus.armedForcesServiceMedalVeteran}
                                                onChange={(e) => generalInfo.handleChangeVeteranStatus("armedForcesServiceMedalVeteran", e.target.checked)}
                                            />}
                                            label={t('Armed Forces service medal veteran')}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={form.personalData.veteranStatus.disabledVeteran}
                                                onChange={(e) => generalInfo.handleChangeVeteranStatus("disabledVeteran", e.target.checked)}
                                            />}
                                            label={t('Disabled veteran')}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={form.personalData.veteranStatus.recentlySeparatedVeteran}
                                                onChange={(e) => generalInfo.handleChangeVeteranStatus("recentlySeparatedVeteran", e.target.checked)}
                                            />}
                                            label={t('Recently separated veteran')}
                                        />
                                    </FormGroup>
                                </CategoryFieldsContainer>

                                <CategoryTitle
                                    name={t('Custom fields')}
                                    icon={<PlaylistAddIcon style={{color: colors.primary.blue_dark}}/>}
                                    button={{text: t('Add Custom Field'), onClick: generalInfo.handleAddCustomField, icon: <AddIcon />}}
                                />

                                <CategoryFieldsContainer disableRequiredFieldWarning>
                                    {Object.keys(settings.customFields).length > 0 ?
                                        <Flex w={'100%'} wrap={'wrap'} gap={'16px'}>
                                            {Object.keys(settings.customFields).map(e => {
                                                return (
                                                    <TextField
                                                        key={e}
                                                        value={form.customFields[e]}
                                                        onChange={handleChange}
                                                        label={e}
                                                        name={e}
                                                        id={'.customFields'}
                                                        sx={{width: inputWidth, backgroundColor: inputBackgroundColor}}
                                                        size={'small'}
                                                    />
                                                )
                                            })}
                                        </Flex>
                                        :
                                        <Typography color={colors.text.grey} fontSize={'13px'} fontWeight={400}>{t('No custom field created. Add fields to specify any additional information.')}</Typography>
                                    }
                                </CategoryFieldsContainer>
                            </Flex>
                            :
                            <Flex w={'100%'} h={'100%'} direction={'column'}>
                                <CategoryTitle
                                    name={t('Personal information')}
                                    icon={<PersonIcon style={{color: colors.primary.blue_dark}}/>}
                                    margin={'18px 0 0 0'}
                                />

                                <Flex w={'100%'} wrap={'wrap'}>
                                    <KeyValue _key={t('First name')} value={form.firstName} />
                                    <KeyValue _key={t('Middle name')} value={form.middleName} />
                                    <KeyValue _key={t('Last name')} value={form.lastName}/>

                                    <KeyValue _key={t('Nickname')} value={form.nickname}/>
                                    <KeyValue _key={t('Birth date')} value={parseDateAuto(form.birthDay, false, true)} />
                                    <KeyValue _key={t('Gender')} value={form.gender} />

                                    <KeyValue _key={t('ID')} value={form.employeeId} />
                                    <KeyValue _key={t('Marital status')} value={form.maritalStatus} />
                                    <KeyValue _key={t('Ethnicity')} value={form.ethnicity}/>
                                </Flex>

                                <KeyValue _key={t('Work email')} value={form.workEmail} disableAutoWidth/>
                                <KeyValue _key={t('Collaborator')} value={getName(form.collaborator?.firstName || '', form.collaborator?.lastName || '')} disableAutoWidth/>

                                {form.personalData.education.length > 0 &&
                                    <>
                                        <CategoryTitle
                                            name={t('Education')}
                                            icon={<MenuBookIcon style={{color: colors.primary.blue_dark}}/>}
                                        />

                                        {form.personalData.education.slice().sort(sortEducation).map((e, id, arr) => {
                                            return(
                                                <>
                                                    <Flex w={'100%'} wrap={'wrap'}>
                                                        <KeyValue _key={t('School name')} value={e.schoolName} />
                                                        <KeyValue _key={t('Degree')} value={e.degree} />
                                                        <KeyValue _key={t('Field of study')} value={e.fieldOfStudy}/>
                                                        <KeyValue _key={t('Start date')} value={parseDateAuto(e.startDate, false, true)}/>
                                                        <KeyValue _key={t('End date')} value={parseDateAuto(e.endDate, false, true)}/>
                                                    </Flex>
                                                    {id !== arr.length -1 && <Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable, margin: '16px 0 0 0'}}/>}
                                                </>
                                            )
                                        })}
                                    </>
                                }

                                <CategoryTitle
                                    name={t('Address')}
                                    icon={<HomeIcon style={{color: colors.primary.blue_dark}}/>}
                                />

                                <KeyValue _key={t('Country')} value={form.contactInfo.address.country} disableAutoWidth/>

                                <Flex w={'100%'} wrap={'wrap'}>
                                    <KeyValue _key={t('City')} value={form.contactInfo.address.city}/>
                                    <KeyValue _key={t('Street')} value={form.contactInfo.address.street}/>
                                    <KeyValue _key={t('ZIP code')} value={form.contactInfo.address.zip}/>
                                </Flex>

                                <CategoryTitle
                                    name={t('Contacts')}
                                    icon={<LocalPhoneIcon style={{color: colors.primary.blue_dark}}/>}
                                />

                                {form.contactInfo.contacts.map(e => <KeyValue _key={e.type ?? 'N/A'} value={e.value}/>)}

                                {form.contactInfo.emergencyContacts.length > 0 &&
                                    <>
                                        <CategoryTitle
                                            name={t('Emergency contacts')}
                                            icon={<ContactPhoneIcon style={{color: colors.primary.blue_dark}}/>}
                                        />

                                        {form.contactInfo.emergencyContacts.map((e, id, arr) => {
                                            return(
                                                <>
                                                    <Flex w={'100%'} wrap={'wrap'}>
                                                        <KeyValue _key={t('First name')} value={e.fullName} />
                                                        <KeyValue _key={t('Relationship to employee')} value={e.relationType} />
                                                        <KeyValue _key={t('Phone number')} value={e.phone}/>
                                                        <KeyValue _key={t('Email')} value={e.email}/>
                                                        <KeyValue _key={t('Address')} value={e.address}/>
                                                    </Flex>
                                                    {id !== arr.length -1 && <Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable, margin: '16px 0 0 0'}}/>}
                                                </>
                                            )
                                        })}
                                    </>
                                }

                                <CategoryTitle
                                    name={t('Veteran status')}
                                    icon={<MilitaryTechIcon style={{color: colors.primary.blue_dark}}/>}
                                />

                                <KeyValue _key={t('Armed Forces service medal veteran')} value={''} disableAutoWidth keyColor={colors.grayText}>
                                    <Typography variant={'body1'} color={form.personalData.veteranStatus.armedForcesServiceMedalVeteran ? colors.text.success : colors.text.critical} sx={{lineHeight: 'normal'}}>{form.personalData.veteranStatus.armedForcesServiceMedalVeteran ? 'Yes' : 'No'}</Typography>
                                </KeyValue>

                                <KeyValue _key={t('Disabled veteran')} value={''} disableAutoWidth keyColor={colors.grayText}>
                                    <Typography variant={'body1'} color={form.personalData.veteranStatus.disabledVeteran ? colors.text.success : colors.text.critical} sx={{lineHeight: 'normal'}}>{form.personalData.veteranStatus.disabledVeteran ? 'Yes' : 'No'}</Typography>
                                </KeyValue>

                                <KeyValue _key={t('Active duty wartime or campaign badge veteran')} value={''} disableAutoWidth keyColor={colors.grayText}>
                                    <Typography variant={'body1'} color={form.personalData.veteranStatus.activeDutyWartimeOrCampaignBadgeVeteran ? colors.text.success : colors.text.critical} sx={{lineHeight: 'normal'}}>{form.personalData.veteranStatus.activeDutyWartimeOrCampaignBadgeVeteran ? 'Yes' : 'No'}</Typography>
                                </KeyValue>

                                <KeyValue _key={t('Recently separated veteran')} value={''} disableAutoWidth keyColor={colors.grayText}>
                                    <Typography variant={'body1'} color={form.personalData.veteranStatus.recentlySeparatedVeteran ? colors.text.success : colors.text.critical} sx={{lineHeight: 'normal'}}>{form.personalData.veteranStatus.recentlySeparatedVeteran ? 'Yes' : 'No'}</Typography>
                                </KeyValue>

                                {Object.values(form.customFields).filter(e => e.trim().length > 0).length > 0 &&
                                    <>
                                        <CategoryTitle
                                            name={t('Custom fields')}
                                            icon={<PlaylistAddIcon style={{color: colors.primary.blue_dark}}/>}
                                        />

                                        <Flex w={'100%'} wrap={'wrap'} gap={'16px'}>
                                            {Object.entries(form.customFields).filter(e => e[1].trim().length > 0).map(e => {
                                                return <KeyValue _key={e[0]} value={e[1]} />
                                            })}
                                        </Flex>
                                    </>
                                }
                            </Flex>
                        }
                    </Flex>
                </TabPanel>

                <TabPanel value={'jobInfo'}
                          sx={{padding: '33px 0 0 0', overflow: 'auto', height: '100%'}}
                          onScroll={scroll.onScroll}
                          ref={scroll.scrollRef}
                >
                    <CategoryTitle
                        margin={'0'}
                        name={t('Hire date')}
                        icon={<EventIcon style={{color: colors.primary.blue_dark}}/>}
                        button={{
                            text: t('Edit info'),
                            onClick: jobInfo.handleChangeHireDate,
                        }}
                    />

                    <Typography variant={'body1'} color={colors.grayText} sx={{margin: '16px 0 0 0'}}>{parseDateAuto(employee?.hireDate, false, true)}</Typography>

                    <CategoryTitle
                        name={t('Employee status')}
                        icon={<PrivacyTipIcon style={{color: colors.primary.blue_dark}}/>}
                        button={{
                            text: t('Edit info'),
                            onClick: jobInfo.handleChangeEmployeeStatus,
                        }}
                        margin={'34px 0 0 0'}
                        dividerMargin={'12px 0 16px 0'}
                    />

                    <ValueGetter _key={'status'} employee={employee} isDisabled={false}/>

                    <CategoryTitle
                        name={t('Job history')}
                        icon={<WorkIcon style={{color: colors.primary.blue_dark}}/>}
                        button={{
                            text: t('Add job record'),
                            onClick: jobInfo.handleAddHistory,
                            icon: <AddIcon />
                        }}
                        margin={'34px 0 16px 0'}
                    />

                    <JobHistoryTable />
                </TabPanel>

                <TabPanel value={'docs'} sx={{padding: '33px 0 0 0', flexGrow: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column'}} >
                    {isLoadingDocument && <Spinner />}
                    <GenericTable<TShortDocumentByEmail>
                        id={'employeeDocuments'}
                        columnsConfig={{totalName: 'Total documents', forceShowCardsWhen: isMobile}}
                        paging={paging}
                        rowsConfig={{
                            rows: documents,
                            customCellPaddings: '8px',
                            rowActionsConfig: [
                                {
                                    title: tCommon('Download'),
                                    onClick: handleDownload,
                                    icon: <DownloadOutlinedIcon />,
                                    loading: (row) => row.documentId === isLoadingDocumentId,
                                },
                                // {
                                //     title: tCommon('Delete'),
                                //     onClick: () => {},
                                //     icon: <DeleteOutlineIcon />,
                                // },

                            ],
                            getRowUniqueId: (row) => row.documentId ?? row.id,
                            onRowClick: (row) => handleOpenDocument(row),
                            columnsConfigs: [
                                {key: "name", name: 'Name', default: true},
                                {key: "lastModified", name: 'Last modified', default: true, valueGetter: (row) => parseDateAuto(row.lastModified)},
                            ]
                        }}

                        filtersConfig={{
                            dontIncludeSearchInFilter: true,
                            genericFilterProps: {
                                configs: filterConfigs,
                                fetchResultWithSelectedFilters: () => null,
                                isAddFilters: false
                            }
                        }}
                    />

                </TabPanel>

                <TabPanel value={'trainings'} sx={{padding: '33px 0 0 0', overflow: 'auto', height: '100%'}} />

                <TabPanel value={'assets'} sx={{padding: '33px 0 0 0', overflow: 'auto', height: '100%'}}/>

                <TabPanel value={'accounts'}>
                    {employee &&
                        <>
                            {employee.accounts.length > 0 ?
                                <EmployeeServicesHistoryHeaderRow/>
                                :
                                <Typography variant={'body1'} sx={{margin: '0 0 5px 0'}} color={colors.darkBlueText}>{t('Detected accounts will be displayed here.')}</Typography>
                            }
                            {employee.accounts.map(e => <EmployeeServiceRow service={e} key={e.id}/>)}
                        </>
                    }
                </TabPanel>
            </TabContext>

            <AddSettingsDialog onSuccess={generalInfo.handleSettingAdd}/>
            <ChangeHireDateDialog />
            <ChangeEmploymentStatusDialog />
            <AddJobRecordDialog />
            <UpdateJobRecordDialog />
            <DeleteJobRecordDialog />
            <DeleteEmployeeDialog isOnTree={false} />
            <DiscardGeneralInfoChangesDialog handleGoToNextTab={generalInfo.handleDiscardChangesAndGoToNextTab}/>

            <DocViewerDialog/>
        </Flex>
    )
}

export const KeyValue = ({_key, value, margin = '16px 0 0 0', disableAutoWidth = false, children, keyColor = colors.text.grey}: {_key: string, value: string | undefined | null, margin?: string, disableAutoWidth?: boolean, children?: React.ReactNode, keyColor?: string}) => {
    const {isTablet, isMobile} = useMedia();
    return(
        <>
            <Flex ai={'center'} gap={'8px'} m={margin} w={disableAutoWidth ? undefined : isMobile ? '100%' : (isTablet ? '50%' : '33%')}>
                <Typography variant={'body1'} color={keyColor} sx={{lineHeight: 'normal'}}>{_key}:</Typography>
                {children ? children : <Typography variant={'body1'} color={colors.grayText} sx={{lineHeight: 'normal'}}>{(value && value.length > 0) ? value : '-'}</Typography>}
            </Flex>
        </>
    )
}
