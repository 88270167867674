import {TFillableFieldDialogProps, TFillableFieldDialogState,} from "../../types";
import {ChangeEvent, useEffect, useState} from "react";
import {initialState} from "../../constants";

export const useFillableFieldDialog = (props: TFillableFieldDialogProps) => {
    const [state, setState] = useState<TFillableFieldDialogState>(props.initialValue ? {text: props.initialValue, insertEverywhere: false} : initialState);



    //TOGGLE
    const handleToggleSwitch = (newValue: boolean) => {
        setState((prev) => ({...prev, insertEverywhere: newValue}));
    }

    //TYPE
    const handleChangeTypeInput = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setState((prev) => ({...prev, text: event.target.value}));
    }

    const handleClearInputOrFile = () => {
        setState((prev) => ({...prev, text: ''}));
    }

    //OTHER

    const handleSubmit = () => {
        if(isOk()){
            props.handleSubmit(state.text, state.insertEverywhere)

            props.handleClose();
        }
    }

    const isOk = () => {
        return state.text.trim().length > 0;
    }

    useEffect(() => {
        if(props.isOpen){
            setState({...initialState, text: props.initialValue || ''});
        }else{
            setState(initialState);
        }
        // eslint-disable-next-line
    }, [props.isOpen])

    return{
        state,
        handleToggleSwitch,
        handleSubmit,
        isOk: isOk(),
        handleChangeTypeInput,
        handleClearInputOrFile,
    }
}