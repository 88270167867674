import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideChangeHireDateDialog, loadings, selectedEmployee} from "../../../store/slice";
import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {DialogContentWrapper} from "../../styled";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {isFutureDate, isSameDay, parseDateInDate} from "../../../../../../newShared/utils/dateTools";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {UpdateEmployeeHireDate} from "../../../store/actions";

export const ChangeHireDateDialog = () => {
    const dispatch = useDispatch();
    const {t, currentLanguage: {momentLocale}} = useMainTranslation('', {keyPrefix: 'pathHRManagement.dialogs'});
    const {tCommon} = useMainTranslation();
    //sel
    const isOpen = useSelector(dialogs).changeHireDateDialog;
    const empl = useSelector(selectedEmployee);
    const isLoading = useSelector(loadings).updateHireDate;

    const handleClose = () => {
        dispatch(hideChangeHireDateDialog());
    }

    const [date, setDate] = useState<Date | null>(null);

    useEffect(() => {
        if(isOpen && empl){
            setDate(parseDateInDate(empl.hireDate, momentLocale));
        }else if(!isOpen){
            setDate(null);
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const handleSubmit = () => {
        if(isOk()){
            dispatch(UpdateEmployeeHireDate({data: {id: empl!.id, hireDate: date!.toISOString()}}));
        }
    }

    const isOk = (): boolean => {
        if(empl && isFutureDate(date?.toISOString(), momentLocale)) return false;
        return date !== null && empl !== null && !isSameDay(empl.hireDate, date.toISOString(), momentLocale);
    }

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Change hire date')}</DialogTitle>

            <DialogContent>
                <DialogContentWrapper>
                    <DatePicker
                        label={t('Hire date')}
                        disableFuture
                        value={date}
                        onChange={(newValue) => {setDate(newValue)}}
                        renderInput={(params) => <TextField {...params} size={'small'} required/>}
                    />
                </DialogContentWrapper>
            </DialogContent>

            <DialogActions sx={{gap: '16px'}}>
                <Button onClick={handleClose} variant={'text'}>{tCommon('Close')}</Button>
                <LoadingButton
                    onClick={handleSubmit}
                    disabled={!isOk()}
                    loading={isLoading}
                >
                    {tCommon('Change')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}