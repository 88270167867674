import React, {FC, MouseEventHandler, useEffect, useState} from "react";
import {Divider, Menu, Typography} from "@mui/material";
import {useDrop} from "react-dnd";
import {
    EditorBlockData,
    EditorBlockItemType,
    TEditorBlockItemType,
    TEditorDragElementData,
    TEditorElementData,
    ValueOf
} from "../../types";
import {EditorDropZoneForBlockItemStyled, EditorDropZoneForBlockItemWithIcon, EditorDropZoneMenuItem} from "./styled";
import {editorBlocks} from "../../constants";
import {Add} from "@mui/icons-material";


interface Props {
    orientation: 'horizontal' | 'vertical';
    isDisabled?: boolean;

    onDrop: (type: ValueOf<TEditorBlockItemType>, block?: TEditorElementData<EditorBlockData>) => void;
    onMouseDown?: MouseEventHandler;
    accept?: ValueOf<TEditorBlockItemType>[];

    onHoverResizer?: (e: boolean) => void;

    allowPushAdd?: boolean;
    isLast?: boolean;
    inTableCell?: boolean;

    readonly?: boolean
};
export const EditorDropZoneForBlockItem: FC<Props> = ({orientation, isLast, inTableCell, onDrop, onMouseDown, onHoverResizer, accept, isDisabled, allowPushAdd, readonly}) => {
    const isVertical = orientation === "vertical";
    const acceptItems = accept ?? [
        EditorBlockItemType.TEXT, EditorBlockItemType.TABLE, EditorBlockItemType.IMAGE, EditorBlockItemType.LIST,
    ];

    const [{isOver, canDrop}, drop] = useDrop(
        () => ({
            accept: acceptItems,
            drop: (item: TEditorDragElementData) => {
                onDrop(item.type as ValueOf<TEditorBlockItemType>, item.getData?.() as TEditorElementData<EditorBlockData>);
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            })
        }),
        []
    );

    const [isPopperOpen, setIsPopperOpen] = useState<HTMLDivElement | null>(null);
    const [isHover, setIsHover] = useState<boolean>(false);
    const [isHoverVertical, setIsHoverVertical] = useState<boolean>(false);

    const handleAddByMenu: (type: ValueOf<TEditorBlockItemType>) => MouseEventHandler<HTMLLIElement> = (type) => (e) => {
        onDrop(type);
        handleCloseMenu();
    };
    const handleOpenMenu: MouseEventHandler<HTMLDivElement> = (e) => {
        setIsPopperOpen(e.currentTarget);
    };
    const handleCloseMenu = () => {
        setIsHover(false);
        setIsPopperOpen(null);
    };

    useEffect(() => {
        onHoverResizer?.(isHoverVertical);
        // eslint-disable-next-line 
    }, [isHoverVertical]);

    useEffect(() => {

        if (isOver && canDrop) {
            document.body.style.cursor = 'pointer';
        } else {
            document.body.style.cursor = '';
        }
    }, [isOver, canDrop])
    return (
        <EditorDropZoneForBlockItemStyled ref={!readonly ? drop : undefined}
                                          onMouseDown={onMouseDown}
                                          className={'dropZoneForBlockItem'}
                                          isOver={isOver || undefined}
                                          canDrop={canDrop || undefined}
                                          // isHover={isHover && !isDisabled && !canDrop}
                                          isVertical={isVertical || undefined}
                                          scalePower={{height: 12, width: 16}} //increase the drop zone size to make it easier to drop

                                          isDisabled={isDisabled}
                                          onMouseOver={() => {
                                              allowPushAdd && setIsHover(true);
                                              onMouseDown && setIsHoverVertical(!!onMouseDown);
                                          }}
                                          onMouseLeave={() => {
                                              allowPushAdd && setIsHover(false);
                                              onMouseDown && setIsHoverVertical(false);
                                          }}
                                          isLast={isLast}
                                          inTableCell={inTableCell}
                                          readonly={readonly}
        >
            <div>
                {allowPushAdd && (
                    <EditorDropZoneForBlockItemWithIcon onClick={handleOpenMenu} isHover={(isHover && !readonly) ?? undefined} className={'EditorDropZoneForBlockItemWithIcon'}>
                        <div className={'thinRow'}>
                            <div className={'circle'}>
                                <Add/>
                            </div>
                        </div>
                    </EditorDropZoneForBlockItemWithIcon>
                )}

                {/*For resizing*/}
                {onMouseDown && !canDrop && !readonly && <Divider flexItem orientation={orientation}/>}

                <div className={'indicator'}/>
            </div>

            {allowPushAdd && !readonly && (
                <Menu open={!!isPopperOpen} anchorEl={isPopperOpen}
                      onClose={handleCloseMenu}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                      transformOrigin={{vertical: 'top', horizontal: 'center'}}
                      disableAutoFocusItem>
                    {acceptItems.map((item) => {
                        const block = editorBlocks.find(e => e.type === item)!;
                        return (
                            <EditorDropZoneMenuItem key={item} onClick={handleAddByMenu(item)}>
                                <block.icon/>

                                <Typography >{block.title}</Typography>
                            </EditorDropZoneMenuItem>
                        )
                    })}
                </Menu>
            )}
        </EditorDropZoneForBlockItemStyled>
    )
}