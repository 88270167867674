import {Flex} from "../../../../../newShared/components/Layouts";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import colors from "../../../../../newShared/theme/colors";
import {Button, IconButton, Menu, MenuItem, Typography} from "@mui/material";
import * as S from "../styled";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {IconTextButton} from "../../../../../newShared/components/Basic/CommonButtons";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import TableViewIcon from "@mui/icons-material/TableView";
import EditIcon from "@mui/icons-material/Edit";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TRisk, TRiskRegistry} from "../../types";
import {useSelector} from "react-redux";
import {isTableNeedsUpdate, loadings, risksViewTypeBlock} from "../../store/slice";
import {LoadingButton, Skeleton} from "@mui/lab";
import EastIcon from "@mui/icons-material/East";

type ExactRegistryActionsBarProps = {
    actions: {
        handleGoBack: () => void,
        handleOpenRelease: () => void,
        handleOpenAddRisk: () => void,
        handleToggleViewMode: () => void,
        handleOpenEditRegistry: () => void,
        handleOpenImportCsv: () => void,
        handleExportCsv: () => void,
        handleDeleteRegistry: () => void,
        handleOpenModelInNewTab: () => void,
        handleOpenRisk: (risk: TRisk) => void
    },
    selected: TRiskRegistry | null,
    menu: {
        anchorEl:  HTMLElement | null,
        handleClose: () => void,
        handleClick: (event: React.MouseEvent<HTMLElement>) => void,
        open: boolean,
    },
    isRightSideOpen: boolean,
    isLoadingRisks: boolean,
    isNoRisks: boolean
}

export const ExactRegistryActionsBar = ({selected, actions, menu, isRightSideOpen, isLoadingRisks, isNoRisks}: ExactRegistryActionsBarProps) => {
    const {isTablet, isMobile, matches_1513Down, matches_1336Down, matches_1440Down, matches_1920Up} = useMedia();
    const {revDir, t} = useMainTranslation('', {keyPrefix: 'risks'});
    const isBlockView = useSelector(risksViewTypeBlock);
    const _isTableNeedsUpdate = useSelector(isTableNeedsUpdate);
    const isLoadingExport = useSelector(loadings).registryExport;
    return(
        <>
            {isTablet &&
                <S.ExactRegistryActionsBarContainer jc={'space-between'}>
                    <Flex ai={'center'}>
                        {selected ?
                            <>
                                {!revDir ?
                                    <KeyboardBackspaceIcon sx={{color: colors.text.blue, cursor: 'pointer'}} onClick={actions.handleGoBack}/>
                                    :
                                    <EastIcon sx={{color: colors.text.blue, cursor: 'pointer'}} onClick={actions.handleGoBack}/>
                                }
                                <Typography variant={'h3'} fontWeight={400} sx={{
                                    margin: !revDir ? '0 16px 0 11px' : '0 16px 0 0',
                                    color: colors.backgrounds.dark
                                }}>{selected.name}</Typography>
                            </>
                            :
                            <Skeleton width={200} height={30} sx={{marginRight: !revDir ? '16px' : undefined, marginLeft: revDir? '16px' : undefined}} />
                        }
                    </Flex>
                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={menu.open ? 'long-menu' : undefined}
                        aria-expanded={menu.open ? 'true' : undefined}
                        aria-haspopup="true"
                        disabled={!selected || isLoadingRisks}
                        onClick={menu.handleClick}
                    >
                        <MoreVertIcon sx={{color: (!selected || isLoadingRisks) ? colors.primary.disable : colors.primary.blue}}/>
                    </IconButton>
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={menu.anchorEl}
                        open={menu.open}
                        onClose={menu.handleClose}
                        PaperProps={{
                            style: {
                                width: '20ch',
                            },
                        }}
                    >
                        <MenuItem onClick={actions.handleOpenRelease} disabled={!selected || _isTableNeedsUpdate || isLoadingRisks || isNoRisks}>
                            <Typography>{t('Release')}</Typography>
                        </MenuItem>

                        <MenuItem onClick={actions.handleOpenAddRisk} disabled={!selected || isLoadingRisks}>
                            <Typography>{t('Add risk')}</Typography>
                        </MenuItem>

                        <MenuItem onClick={actions.handleToggleViewMode} disabled={!selected || _isTableNeedsUpdate || isLoadingRisks}>
                            <Typography>{isBlockView ? t('Table view') : t('Block view')}</Typography>
                        </MenuItem>

                        <MenuItem onClick={actions.handleOpenEditRegistry} disabled={!selected || _isTableNeedsUpdate || isLoadingRisks}>
                            <Typography>{t('Edit')}</Typography>
                        </MenuItem>

                        <MenuItem onClick={actions.handleOpenImportCsv} disabled={!selected || _isTableNeedsUpdate || isLoadingRisks}>
                            <Typography>{t('Import CSV')}</Typography>
                        </MenuItem>

                        <MenuItem onClick={actions.handleExportCsv} disabled={!selected || _isTableNeedsUpdate || isLoadingExport || isLoadingRisks || isNoRisks}>
                            <Typography>{t('Export CSV')}</Typography>
                        </MenuItem>

                        <MenuItem onClick={actions.handleDeleteRegistry} disabled={!selected || isLoadingRisks}>
                            <Typography>{t('Delete')}</Typography>
                        </MenuItem>
                    </Menu>
                </S.ExactRegistryActionsBarContainer>
            }

            {matches_1336Down && !isTablet && //770 < x < 1512
                <>
                    <Flex ai={'center'}>
                        {selected ?
                            <>
                                {!revDir ?
                                    <KeyboardBackspaceIcon sx={{color: colors.text.blue, cursor: 'pointer'}} onClick={actions.handleGoBack}/>
                                    :
                                    <EastIcon sx={{color: colors.text.blue, cursor: 'pointer'}} onClick={actions.handleGoBack}/>
                                }
                                <Typography variant={matches_1513Down ? 'h3' : "h1"} sx={{
                                    margin: !revDir ? '0 16px 0 11px' : '0 16px 0 0',
                                    color: colors.backgrounds.dark
                                }}>{selected.name}</Typography>
                            </>
                            :
                            <Skeleton width={200} height={30} sx={{marginRight: !revDir ? '16px' : undefined, marginLeft: revDir? '16px' : undefined}} />
                        }

                    </Flex>

                    <S.ExactRegistryActionsBarContainer>
                        <Button variant={'contained'} color={'secondary'} size={isMobile ? 'small' : 'medium'} onClick={actions.handleOpenRelease} disabled={!selected || isLoadingRisks || isNoRisks} >{t('Release')}</Button>

                        <IconTextButton
                            variant={'text'}
                            disabled={!selected || isLoadingRisks}
                            icon={<VerticalSplitIcon fontSize="small"/>}
                            onClick={actions.handleOpenAddRisk}
                            sx={{
                                '& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}
                            }}
                        >
                            <Typography fontWeight={600} fontSize={matches_1513Down ? '13px' : '15px'}>{t('Add risk')}</Typography>
                        </IconTextButton>

                        <IconTextButton
                            variant={'text'}
                            disabled={!selected || _isTableNeedsUpdate || isLoadingRisks}
                            icon={<TableViewIcon fontSize="small"/>}
                            onClick={actions.handleToggleViewMode}
                            sx={{
                                '& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}
                            }}
                        >
                            <Typography fontWeight={600} fontSize={matches_1513Down ? '13px' : '15px'}>{isBlockView ? t('Table view') : t('Block view')}</Typography>
                        </IconTextButton>

                        <IconTextButton
                            variant={'text'}
                            disabled={!selected || _isTableNeedsUpdate || isLoadingRisks}
                            icon={<EditIcon fontSize="small"/>}
                            onClick={actions.handleOpenEditRegistry}
                            fullWidth={false}
                            sx={{
                                '& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}
                            }}
                        >
                            <Typography fontWeight={600} fontSize={matches_1513Down ? '13px' : '15px'}>{t('Edit')}</Typography>
                        </IconTextButton>

                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={menu.open ? 'long-menu' : undefined}
                            aria-expanded={menu.open ? 'true' : undefined}
                            aria-haspopup="true"
                            disabled={!selected || isLoadingRisks}
                            onClick={menu.handleClick}

                        >
                            <MoreVertIcon sx={{color: (!selected || isLoadingRisks) ? colors.primary.disable : colors.primary.blue}}/>
                        </IconButton>

                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={menu.anchorEl}
                            open={menu.open}
                            onClose={menu.handleClose}
                            PaperProps={{
                                style: {
                                    width: '20ch',
                                },
                            }}
                        >
                            <MenuItem onClick={actions.handleOpenImportCsv} disabled={!selected || _isTableNeedsUpdate || isLoadingRisks}>
                                <Typography>{t('Import CSV')}</Typography>
                            </MenuItem>

                            <MenuItem onClick={actions.handleExportCsv} disabled={!selected || _isTableNeedsUpdate || isLoadingExport || isLoadingRisks || isNoRisks}>
                                <Typography>{t('Export CSV')}</Typography>
                            </MenuItem>

                            <MenuItem onClick={actions.handleDeleteRegistry} disabled={!selected || isLoadingRisks}>
                                <Typography>{t('Delete')}</Typography>
                            </MenuItem>
                        </Menu>
                    </S.ExactRegistryActionsBarContainer>
                </>
            }

            {matches_1440Down && !matches_1336Down && //1336 < x < 1440
                <>
                    {isRightSideOpen ?
                        <>
                            <S.ExactRegistryActionsBarContainer>
                                <Flex ai={'center'}>
                                    {selected ?
                                        <>
                                            {!revDir ?
                                                <KeyboardBackspaceIcon sx={{color: colors.text.blue, cursor: 'pointer'}} onClick={actions.handleGoBack}/>
                                                :
                                                <EastIcon sx={{color: colors.text.blue, cursor: 'pointer'}} onClick={actions.handleGoBack}/>
                                            }
                                            <Typography variant={matches_1513Down ? 'h3' : "h1"} fontWeight={400} sx={{
                                                margin: !revDir ? '0 16px 0 11px' : '0 16px 0 0',
                                                color: colors.backgrounds.dark
                                            }}>{selected.name}</Typography>
                                        </>
                                        :
                                        <Skeleton width={200} height={30} sx={{marginRight: !revDir ? '16px' : undefined, marginLeft: revDir? '16px' : undefined}} />
                                    }
                                </Flex>

                                <Button variant={'contained'} color={'secondary'} size={isMobile ? 'small' : 'medium'} onClick={actions.handleOpenRelease} disabled={!selected || isLoadingRisks || isNoRisks}>{t('Release')}</Button>

                                <IconTextButton
                                    variant={'text'}
                                    disabled={!selected || isLoadingRisks}
                                    icon={<VerticalSplitIcon fontSize="small"/>}
                                    onClick={actions.handleOpenAddRisk}
                                    sx={{
                                        '& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}
                                    }}
                                >
                                    <Typography fontWeight={600} fontSize={matches_1513Down ? '13px' : '15px'}>{t('Add risk')}</Typography>
                                </IconTextButton>

                                <IconTextButton
                                    variant={'text'}
                                    disabled={!selected || _isTableNeedsUpdate || isLoadingRisks}
                                    icon={<TableViewIcon fontSize="small"/>}
                                    onClick={actions.handleToggleViewMode}
                                    sx={{
                                        '& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}
                                    }}
                                >
                                    <Typography fontWeight={600} fontSize={matches_1513Down ? '13px' : '15px'}>{isBlockView ? t('Table view') : t('Block view')}</Typography>
                                </IconTextButton>

                                <IconTextButton
                                    variant={'text'}
                                    disabled={!selected || _isTableNeedsUpdate || isLoadingRisks}
                                    icon={<EditIcon fontSize="small"/>}
                                    onClick={actions.handleOpenEditRegistry}
                                    sx={{
                                        '& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}
                                    }}
                                >
                                    <Typography fontWeight={600} fontSize={matches_1513Down ? '13px' : '15px'}>{t('Edit')}</Typography>
                                </IconTextButton>

                                <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={menu.open ? 'long-menu' : undefined}
                                    aria-expanded={menu.open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    disabled={!selected || isLoadingRisks}
                                    onClick={menu.handleClick}
                                >
                                    <MoreVertIcon sx={{color: (!selected || isLoadingRisks) ? colors.primary.disable : colors.primary.blue}}/>
                                </IconButton>

                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={menu.anchorEl}
                                    open={menu.open}
                                    onClose={menu.handleClose}
                                    PaperProps={{
                                        style: {
                                            width: '20ch',
                                        },
                                    }}
                                >
                                    <MenuItem onClick={actions.handleOpenImportCsv} disabled={!selected || _isTableNeedsUpdate || isLoadingRisks}>
                                        <Typography>{t('Import CSV')}</Typography>
                                    </MenuItem>

                                    <MenuItem onClick={actions.handleExportCsv} disabled={!selected || _isTableNeedsUpdate || isLoadingExport || isLoadingRisks || isNoRisks}>
                                        <Typography>{t('Export CSV')}</Typography>
                                    </MenuItem>

                                    <MenuItem onClick={actions.handleDeleteRegistry} disabled={!selected || isLoadingRisks}>
                                        <Typography>{t('Delete')}</Typography>
                                    </MenuItem>
                                </Menu>
                            </S.ExactRegistryActionsBarContainer>
                        </>
                        :
                        <>
                            <Flex ai={'center'}>
                                {selected ?
                                    <>
                                        {!revDir ?
                                            <KeyboardBackspaceIcon sx={{color: colors.text.blue, cursor: 'pointer'}} onClick={actions.handleGoBack}/>
                                            :
                                            <EastIcon sx={{color: colors.text.blue, cursor: 'pointer'}} onClick={actions.handleGoBack}/>
                                        }
                                        <Typography variant={matches_1513Down ? 'h3' : "h1"} fontWeight={400} sx={{
                                            margin: !revDir ? '0 16px 0 11px' : '0 11px 0 16px',
                                            color: colors.backgrounds.dark
                                        }}>{selected.name}</Typography>
                                    </>
                                    :
                                    <Skeleton width={200} height={30} sx={{marginRight: !revDir ? '16px' : undefined, marginLeft: revDir? '16px' : undefined}} />
                                }
                            </Flex>

                            <S.ExactRegistryActionsBarContainer>
                                <Button variant={'contained'} color={'secondary'} size={isMobile ? 'small' : 'medium'} onClick={actions.handleOpenRelease} disabled={!selected || isLoadingRisks || isNoRisks}>{t('Release')}</Button>

                                <IconTextButton
                                    variant={'text'}
                                    disabled={!selected || isLoadingRisks}
                                    icon={<VerticalSplitIcon fontSize="small"/>}
                                    onClick={actions.handleOpenAddRisk}
                                    sx={{
                                        '& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}
                                    }}
                                >
                                    <Typography fontWeight={600} fontSize={matches_1513Down ? '13px' : '15px'}>{t('Add risk')}</Typography>
                                </IconTextButton>

                                <IconTextButton
                                    variant={'text'}
                                    disabled={!selected || _isTableNeedsUpdate || isLoadingRisks}
                                    icon={<TableViewIcon fontSize="small"/>}
                                    onClick={actions.handleToggleViewMode}
                                    sx={{
                                        '& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}
                                    }}
                                >
                                    <Typography fontWeight={600} fontSize={matches_1513Down ? '13px' : '15px'}>{isBlockView ? t('Table view') : t('Block view')}</Typography>
                                </IconTextButton>

                                <IconTextButton
                                    variant={'text'}
                                    disabled={!selected || _isTableNeedsUpdate || isLoadingRisks}
                                    icon={<EditIcon fontSize="small"/>}
                                    onClick={actions.handleOpenEditRegistry}
                                    sx={{
                                        '& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}
                                    }}
                                >
                                    <Typography fontWeight={600} fontSize={matches_1513Down ? '13px' : '15px'}>{t('Edit')}</Typography>
                                </IconTextButton>

                                <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={menu.open ? 'long-menu' : undefined}
                                    aria-expanded={menu.open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    disabled={!selected || isLoadingRisks}
                                    onClick={menu.handleClick}
                                >
                                    <MoreVertIcon sx={{color: (!selected || isLoadingRisks) ? colors.primary.disable : colors.primary.blue}}/>
                                </IconButton>

                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={menu.anchorEl}
                                    open={menu.open}
                                    onClose={menu.handleClose}
                                    PaperProps={{
                                        style: {
                                            width: '20ch',
                                        },
                                    }}
                                >
                                    <MenuItem onClick={actions.handleOpenImportCsv} disabled={!selected || _isTableNeedsUpdate || isLoadingRisks}>
                                        <Typography>{t('Import CSV')}</Typography>
                                    </MenuItem>

                                    <MenuItem onClick={actions.handleExportCsv} disabled={!selected || _isTableNeedsUpdate || isLoadingExport || isLoadingRisks || isNoRisks}>
                                        <Typography>{t('Export CSV')}</Typography>
                                    </MenuItem>

                                    <MenuItem onClick={actions.handleDeleteRegistry} disabled={!selected || isLoadingRisks}>
                                        <Typography>{t('Delete')}</Typography>
                                    </MenuItem>
                                </Menu>
                            </S.ExactRegistryActionsBarContainer>
                        </>
                    }
                </>
            }

            {!matches_1440Down && // 1513 < x
                <>
                    {isRightSideOpen ?
                        <>
                            <Flex ai={'center'}>
                                {selected ?
                                    <>
                                        <KeyboardBackspaceIcon sx={{color: colors.text.blue, cursor: 'pointer'}} onClick={actions.handleGoBack}/>
                                        <Typography variant={matches_1513Down ? 'h3' : "h1"} fontWeight={matches_1920Up ? 700 : 400}  sx={{
                                            margin: !revDir ? '0 16px 0 11px' : '0 16px 0 0',
                                            color: colors.backgrounds.dark
                                        }}>{selected.name}</Typography>
                                    </>
                                    :
                                    <Skeleton width={200} height={30} sx={{marginRight: !revDir ? '16px' : undefined, marginLeft: revDir? '16px' : undefined}} />
                                }
                            </Flex>

                            <S.ExactRegistryActionsBarContainer>
                                <Button variant={'contained'} color={'secondary'} size={isMobile ? 'small' : 'medium'} onClick={actions.handleOpenRelease} disabled={!selected || isLoadingRisks || isNoRisks}>{t('Release')}</Button>

                                <IconTextButton
                                    variant={'text'}
                                    disabled={!selected || isLoadingRisks}
                                    icon={<VerticalSplitIcon fontSize="small"/>}
                                    onClick={actions.handleOpenAddRisk}
                                    sx={{
                                        '& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}
                                    }}
                                >
                                    <Typography fontWeight={600} fontSize={matches_1513Down ? '13px' : '15px'}>{t('Add risk')}</Typography>
                                </IconTextButton>

                                <IconTextButton
                                    variant={'text'}
                                    disabled={!selected || _isTableNeedsUpdate || isLoadingRisks}
                                    icon={<TableViewIcon fontSize="small"/>}
                                    onClick={actions.handleToggleViewMode}
                                    sx={{
                                        '& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}
                                    }}
                                >
                                    <Typography fontWeight={600} fontSize={matches_1513Down ? '13px' : '15px'}>{isBlockView ? t('Table view') : t('Block view')}</Typography>
                                </IconTextButton>

                                <IconTextButton
                                    variant={'text'}
                                    disabled={!selected || _isTableNeedsUpdate || isLoadingRisks}
                                    icon={<EditIcon fontSize="small"/>}
                                    onClick={actions.handleOpenEditRegistry}
                                    sx={{
                                        '& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}
                                    }}
                                >
                                    <Typography fontWeight={600} fontSize={matches_1513Down ? '13px' : '15px'}>{t('Edit')}</Typography>
                                </IconTextButton>

                                <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={menu.open ? 'long-menu' : undefined}
                                    aria-expanded={menu.open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    disabled={!selected || isLoadingRisks}
                                    onClick={menu.handleClick}
                                >
                                    <MoreVertIcon sx={{color: (!selected || isLoadingRisks) ? colors.primary.disable : colors.primary.blue}}/>
                                </IconButton>

                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={menu.anchorEl}
                                    open={menu.open}
                                    onClose={menu.handleClose}
                                    PaperProps={{
                                        style: {
                                            width: '20ch',
                                        },
                                    }}
                                >
                                    <MenuItem onClick={actions.handleOpenImportCsv} disabled={!selected || _isTableNeedsUpdate || isLoadingRisks}>
                                        <Typography>{t('Import CSV')}</Typography>
                                    </MenuItem>

                                    <MenuItem onClick={actions.handleExportCsv} disabled={!selected || _isTableNeedsUpdate || isLoadingRisks || isNoRisks}>
                                        <Typography>{t('Export CSV')}</Typography>
                                    </MenuItem>

                                    <MenuItem onClick={actions.handleDeleteRegistry} disabled={!selected || isLoadingRisks}>
                                        <Typography>{t('Delete')}</Typography>
                                    </MenuItem>
                                </Menu>
                            </S.ExactRegistryActionsBarContainer>
                        </>
                        :
                        <S.ListPageTopButtonContainer>
                            <Flex ai={'center'}>
                                {selected ?
                                    <>
                                        {!revDir ?
                                            <KeyboardBackspaceIcon sx={{color: colors.text.blue, cursor: 'pointer'}} onClick={actions.handleGoBack}/>
                                            :
                                            <EastIcon sx={{color: colors.text.blue, cursor: 'pointer'}} onClick={actions.handleGoBack}/>
                                        }
                                        <Typography variant={matches_1513Down ? 'h3' : "h1"} fontWeight={400} sx={{
                                            margin: !revDir ? '0 16px 0 11px' : '0 11px 0 16px',
                                            color: colors.backgrounds.dark
                                        }}>{selected.name}</Typography>
                                    </>
                                    :
                                    <Skeleton width={200} height={30} sx={{marginRight: !revDir ? '16px' : undefined, marginLeft: revDir? '16px' : undefined}} />
                                }
                            </Flex>

                            <Button variant={'contained'} color={'secondary'} size={isMobile ? 'small' : 'medium'} onClick={actions.handleOpenRelease} disabled={!selected || isLoadingRisks || isNoRisks}>{t('Release')}</Button>

                            <IconTextButton
                                variant={'text'}
                                disabled={!selected || isLoadingRisks}
                                icon={<VerticalSplitIcon fontSize="small"/>}
                                onClick={actions.handleOpenAddRisk}
                                sx={{
                                    '& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}
                                }}
                            >
                                <Typography fontWeight={600} fontSize={matches_1513Down ? '13px' : '15px'}>{t('Add risk')}</Typography>
                            </IconTextButton>

                            <IconTextButton
                                variant={'text'}
                                disabled={!selected || isLoadingRisks}
                                icon={<TableViewIcon fontSize="small"/>}
                                onClick={actions.handleToggleViewMode}
                                sx={{
                                    '& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}
                                }}
                            >
                                <Typography fontWeight={600} fontSize={matches_1513Down ? '13px' : '15px'}>{isBlockView ? t('Table view') : t('Block view')}</Typography>
                            </IconTextButton>

                            <IconTextButton
                                variant={'text'}
                                disabled={!selected || _isTableNeedsUpdate || isLoadingRisks}
                                icon={<EditIcon fontSize="small"/>}
                                onClick={actions.handleOpenEditRegistry}
                                sx={{
                                    '& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}
                                }}
                            >
                                <Typography fontWeight={600} fontSize={matches_1513Down ? '13px' : '15px'}>{t('Edit')}</Typography>
                            </IconTextButton>

                            <IconTextButton
                                variant={'text'}
                                disabled={!selected || _isTableNeedsUpdate || isLoadingRisks}
                                icon={<FileUploadIcon fontSize="small"/>}
                                onClick={actions.handleOpenImportCsv}
                                sx={{
                                    '& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}
                                }}
                            >
                                <Typography fontWeight={600} fontSize={matches_1513Down ? '13px' : '15px'}>{t('Import CSV')}</Typography>
                            </IconTextButton>

                            {isLoadingExport ?
                                <LoadingButton
                                    loading
                                    variant={'text'}
                                    sx={{paddingLeft: '35px', margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                    // variant="outlined"
                                >
                                    <Typography fontWeight={600} fontSize={matches_1513Down ? '13px' : '15px'}>{t('Export CSV')}</Typography>
                                </LoadingButton>
                                :
                                <IconTextButton
                                    variant={'text'}
                                    disabled={!selected || _isTableNeedsUpdate || isLoadingRisks || isNoRisks}
                                    icon={<DownloadIcon fontSize="small"/>}
                                    onClick={actions.handleExportCsv}
                                    sx={{
                                        '& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}
                                    }}
                                >
                                    <Typography fontWeight={600} fontSize={matches_1513Down ? '13px' : '15px'}>{t('Export CSV')}</Typography>
                                </IconTextButton>
                            }

                            <IconTextButton
                                variant={'text'}
                                disabled={!selected || isLoadingRisks}
                                icon={<DeleteIcon fontSize="small"/>}
                                onClick={actions.handleDeleteRegistry}
                                sx={{
                                    '& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}
                                }}
                            >
                                <Typography fontWeight={600} fontSize={matches_1513Down ? '13px' : '15px'}>{t('Delete')}</Typography>
                            </IconTextButton>

                        </S.ListPageTopButtonContainer>
                    }
                </>
            }
        </>
    )

}