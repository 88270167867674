import {useParams} from "react-router-dom";

type TResponse = {
    frId?: string,
    controlId?: string,
    evidenceId?: string,
    analysisId?: string,
    historyId?: string,
    taskId?: string,
}
export const useFrameworksPathParams = (): TResponse => {
    const {frId, analysisId, controlId, evidenceId, historyId, taskId} = useParams<TResponse>();
    return {
        frId,
        analysisId,
        controlId,
        evidenceId,
        historyId,
        taskId
    }
}
