import React, {FC} from "react";
import {Autocomplete} from "@mui/material";
import {TextFieldCustom} from "../../../accessManagement/components/styled";
import {useAddNewSetting} from "../../../vendors/hooks/useAddNewSetting";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {additionalDataForCreateIntegration, loadings} from "../../store/slice";
import {useSelector} from "react-redux";
import {addAppVendorManage} from "../../types";
import {
    optionIsTCollaborator,
    TCollaborator
} from "../../../../../newShared/components/AutocompleteCollaborators/types";
import {AutocompleteCollaborators} from "../../../../../newShared/components/AutocompleteCollaborators";
import {getName} from "../../../../../newShared/utils/text";


type TProps = {
    form: addAppVendorManage,

    handleSetOwner: (owner: TCollaborator | null) => void,
    handleSetType: (type: string) => void,
    handleSetProvidedService: (providedService: string) => void,
    handleSetRiskLevel: (riskLevel: string) => void,
}
export const VendorsTabAddNew: FC<TProps> = (props) => {
    const {
        form,

        handleSetOwner,
        handleSetType,
        handleSetProvidedService,
        handleSetRiskLevel,
    } = props;

    const additionalDataForCreate = useSelector(additionalDataForCreateIntegration);
    const isLoadingAdditionalData = useSelector(loadings).vendorsAndEmployeesForCreate;

    const {t} = useMainTranslation('', {keyPrefix: 'pathIntegrations.AvailableAppsList.dialogs'});
    const {TypeBlock, ServiceProvidedBlock, RiskBlock} = useAddNewSetting();

    return (
        <>
            <AutocompleteCollaborators
                required
                label={t("Select owner")}
                sx={{margin: '8px 0 0 0'}}
                getOptionLabelCustom={"name+email"}
                getOptionLabel={(option) => optionIsTCollaborator(option) ? getName(option.firstName || '', option.lastName || '') : option}

                defaultValue={form.create.owner}
                onChange={value => optionIsTCollaborator(value) && handleSetOwner(value)}
            />

            <Autocomplete
                options={additionalDataForCreate.vendorSettings.type.map(e => e.name)}
                loading={isLoadingAdditionalData}
                value={form.create.type}
                onChange={(e, newValue: string | null) => newValue && handleSetType(newValue)}
                renderInput={(params) => <TextFieldCustom required {...params} label={t("Select type")} size={'small'} />}
                sx={{margin: '8px 0 0 0'}}
                PaperComponent={TypeBlock}
            />

            <Autocomplete
                options={additionalDataForCreate.vendorSettings.serviceProvided.map(e => e.name)}
                loading={isLoadingAdditionalData}
                value={form.create.providedService}
                onChange={(e, newValue: string | null) => newValue && handleSetProvidedService(newValue)}
                renderInput={(params) => <TextFieldCustom required {...params} label={t("Select provided service")} size={'small'} />}
                sx={{margin: '8px 0 0 0'}}
                PaperComponent={ServiceProvidedBlock}
            />

            <Autocomplete
                options={additionalDataForCreate.vendorSettings.risk.map(e => e.name)}
                loading={isLoadingAdditionalData}
                value={form.create.riskLevel}
                onChange={(e, newValue: string | null) => newValue && handleSetRiskLevel(newValue)}
                renderInput={(params) => <TextFieldCustom required {...params} label={t("Select risk level")} size={'small'} />}
                sx={{margin: '8px 0 0 0'}}
                PaperComponent={RiskBlock}
            />
        </>
    )
};
