import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideDeleteApp, loadings, selectedAppEditable} from "../../../store/slice";
import {useHistory} from "react-router-dom";
import {PATH_ACCESS_MANAGEMENT_APPS_LIST} from "../../../constants";
import {DeleteAccessManagementCustomAppById} from "../../../store/actions";

export const useDeleteAppDialog = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isOpen = useSelector(dialogs).deleteApp;
    const isLoading = useSelector(loadings).deleteCustom;
    const app = useSelector(selectedAppEditable);

    const handleClose = () => {
        dispatch(hideDeleteApp());
    }

    const handleSubmit = () => {
        app && dispatch(DeleteAccessManagementCustomAppById({data: {id: app.id, onSuccess}}))
    }

    const onSuccess = () => {
        history.push(PATH_ACCESS_MANAGEMENT_APPS_LIST);
    }

    return{
        isOpen,
        isLoading,
        handleClose,
        handleSubmit,
        app,
    }
}