import {styled} from "@mui/material";
import colors from "../../../theme/colors";

export const CommonContainer = styled('div')({
    display: "flex",
    flexDirection: 'column',
})

export const DropZoneContainer = styled('div')({
    display: "flex",
    flexDirection: 'column',
    width: '100%',
    minHeight: '100px',
    justifyContent: 'center',
    margin: '10px 0 0 0',
    border: `1px dashed ${colors.stroke.blue}`,
    borderRadius: '5px'
})

export const DropZoneInnerContainer = styled('div')({
    display: "flex",
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    padding: '15px 0 0 0',
    cursor: 'pointer'
})

export const UploadIconContainer = styled('div')({
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px',
    backgroundColor: colors.backgrounds.grey_light,
    borderRadius: '10px'
})

export const FileRowContainer = styled('div')({
    width: '100%',
    gap: '10px',
    display: "flex",
    alignItems: 'center',
    marginTop: '12px'
})
