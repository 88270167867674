import {Box, CircularProgress} from "@mui/material";
import React, {FC, RefObject, useEffect, useRef, useState} from "react";
import {Editor} from '@tinymce/tinymce-react';
import {getDataForSaveFromEditor, initializeContent, uploadFile, uploadImageToTinyMce} from "./helpers";
import {tinyMceKey} from "./constants";
import {useFileDropZone} from "../../hooks/useSingleFileDropZone";
import {useMessageDialog} from "../../../newDomain/barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useMainTranslation} from "../../hooks/useMainTranslationHooks/useMainTranslation";
import {Spinner} from "../Spinner";
import {ACCEPT_IMAGE_PROP} from "../../constants";
import {uuid} from "../../utils";

type TinyMceEditorProps = {
    initialContent: string;
    onSave: (innerHtml: string) => void;
    readonly?: boolean;
    isSaving?: boolean;

    cloneRef?: (ref: RefObject<Editor>) => void;
    handleSelectionChange?: (editor: Editor | null) => void;
    hideMenu?: boolean;
    hideSave?: boolean
};
export const TinyMceEditor: FC<TinyMceEditorProps> = ({initialContent, onSave, readonly, isSaving, cloneRef, hideMenu, hideSave, handleSelectionChange}) => {
    const {tCommon} = useMainTranslation();
    const {setMessage} = useMessageDialog();

    const editorRef = useRef<Editor>(null);
    const [inputId] = useState('input_file_tiny_' + uuid());

    const [initialized, setInitialized] = useState(false);
    const [initLoadingImages, setInitLoadingImages] = useState(true);
    const [imageUploading, setImageUploading] = useState(false);

    const limitMB = 5;
    const {fileHandler, fileInBase64, uploadedFile, ref: inputRef, handleDeleteFile: handleDeleteFileInPicker} = useFileDropZone(0);

    useEffect(() => {
        if (uploadedFile && fileInBase64) {
            if (uploadedFile?.size > limitMB * 1024 * 1024) {
                setMessage({title: 'Upload image error', message: tCommon('Make sure that the attached file does not exceed {{mb}} MB.', {mb: limitMB})});
            } else if (fileInBase64?.file) {
                setImageUploading(true);
                uploadFile(fileInBase64.file).then(({fileId}) => {
                    uploadImageToTinyMce(editorRef.current!, {file: uploadedFile, base64: fileInBase64.file, name: fileInBase64.name, fileId: fileId});
                })
            }

            setImageUploading(false);
            handleDeleteFileInPicker();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileInBase64?.file, editorRef]);


    // useEffect(() => {
    //     editorRef.current?.editor?.setContent(initialContent);
    //
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [initialContent, editorRe])

    useEffect(() => {
        cloneRef?.(editorRef);
        const trigger = () => handleSelectionChange?.(editorRef.current);

        handleSelectionChange && editorRef.current?.editor?.on('SelectionChange', trigger);
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            handleSelectionChange && editorRef.current?.editor?.off('SelectionChange', trigger);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editorRef.current?.editor]);



    return (
        <Box sx={{
            width: '100%', height: '100%',
            overflow: 'hidden',
            flexGrow: 1,
            display: 'flex', flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& .file-drop': {
                flexGrow: 1,
                display: 'flex',
            },
            '& .tox-tinymce': {
                flexGrow: 1,
                border: hideMenu ? 'none' : 'undefined',
            },
        }}>
            {!initialized && <CircularProgress/>}
            <Editor
                ref={editorRef}
                apiKey={tinyMceKey}

                disabled={readonly || initLoadingImages || isSaving || imageUploading}
                onInit={(_, editor) => {
                    setInitialized(true);
                    initializeContent(editor, initialContent).then((success) => {success && setInitLoadingImages(false)});
                }}

                init={{
                    height: '100%', width: '100%',
                    resize: false,
                    elementpath: false,
                    branding: false,

                    paste_block_drop: true,

                    placeholder: 'Type your text here...',

                    plugins: 'anchor autolink lists code table',
                    table_grid: false,
                    paste_data_images: false,

                    menubar: !hideMenu && 'edit view insert format tools',
                    toolbar: hideMenu ? false : 'customSaveButton | undo redo | blocks | bold italic strikethrough backcolor | customImageButton | align bullist numlist | table | code ',
                    setup: (editor) => {
                        !hideSave && editor.ui.registry.addButton('customSaveButton', {
                            text: 'Save',
                            onAction: () => {
                                const data = getDataForSaveFromEditor(editor);
                                onSave(data);
                            },
                        });

                        editor.ui.registry.addButton('customImageButton', {
                            icon: 'image',
                            tooltip: 'Insert image',
                            onAction: () => {
                                const input = document.getElementById(inputId) as HTMLInputElement | undefined;
                                input?.click();
                            },
                        });

                        // handleSelectionChange && editor?.on('SelectionChange', () => handleSelectionChange(editor as any as Editor))
                    },
                }}
            />

            {imageUploading && <Spinner/>}
            <input ref={inputRef}
                   id={inputId}
                   accept={ACCEPT_IMAGE_PROP}
                   style={{ visibility: "hidden", opacity: 0, display: 'none' }}
                   type="file"
                   onChange={(e) => fileHandler(e.target.files)}
            />
        </Box>
    );
};
