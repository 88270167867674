import {FC} from "react";
import {Box, Grid, Skeleton, Typography} from "@mui/material";
import {useReport} from "../../hooks/useReport";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import colors from "../../../../../newShared/theme/colors";
import {getReportStatus, getReportType} from "../../helpers";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";

export const ReportViewInfo: FC = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathReports.Table'});

    const {report: {report, NCRs}, isLoading: {isLoadingReport}} = useReport();

    return (
        <Grid container columnSpacing={2} width={'100%'}>
            <Grid item>
                <Box display={"flex"} alignItems={"center"} marginTop={'8px'}>
                    <Typography minWidth={'100px'} variant={"body2"} color={colors.text.grey_dark} whiteSpace={"nowrap"} margin={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>{t('Report status')}</Typography>
                    <Typography minWidth={'200px'} variant={"body2"} color={getReportStatus(t, report?.reportStatus).color} whiteSpace={"nowrap"}>{!isLoadingReport ? report ? getReportStatus(t, report.reportStatus).status : '' : <Skeleton/>}</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} marginTop={'8px'}>
                    <Typography minWidth={'100px'} variant={"body2"} color={colors.text.grey_dark} whiteSpace={"nowrap"} margin={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>{t('Report type')}</Typography>
                    <Typography minWidth={'200px'} variant={"body2"} color={colors.text.dark} whiteSpace={"nowrap"}>{!isLoadingReport ? report ? getReportType(t, report.reportType) : '' : <Skeleton/>}</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} marginTop={'8px'}>
                    <Typography minWidth={'100px'} variant={"body2"} color={colors.text.grey_dark} whiteSpace={"nowrap"} margin={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>{t('Auditor')}</Typography>
                    <Typography minWidth={'200px'} variant={"body2"} color={colors.text.dark} whiteSpace={"nowrap"}>{!isLoadingReport ? report ? report.auditor?.email || '' : '' : <Skeleton/>}</Typography>
                </Box>
            </Grid>

            <Grid item>
                <Box display={"flex"} alignItems={"center"} marginTop={'8px'}>
                    <Typography minWidth={'100px'} variant={"body2"} color={colors.text.grey_dark} whiteSpace={"nowrap"} margin={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>{t('Framework')}</Typography>
                    <Typography minWidth={'200px'} variant={"body2"} color={colors.text.dark} whiteSpace={"nowrap"}>{!isLoadingReport ? report ? report.framework.name : '' : <Skeleton/>}</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} marginTop={'8px'}>
                    <Typography minWidth={'100px'} variant={"body2"} color={colors.text.grey_dark} whiteSpace={"nowrap"} margin={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>{t('Generated')}</Typography>
                    <Typography minWidth={'200px'} variant={"body2"} color={colors.text.dark} whiteSpace={"nowrap"}>{!isLoadingReport ? report ? parseDateAuto(report.generated) : '' : <Skeleton/>}</Typography>
                </Box>
                {report?.reportStatus === 'PUBLISHED' && report.ncrEnabled && (
                    <Box display={"flex"} alignItems={"center"} marginTop={'8px'}>
                        <Typography minWidth={'100px'} variant={"body2"} color={colors.text.grey_dark} whiteSpace={"nowrap"} margin={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>{t('Unanswered NCR')}</Typography>
                        <Typography minWidth={'200px'} variant={"body2"} color={colors.text.dark} whiteSpace={"nowrap"}>{!isLoadingReport ? report ? NCRs.filter(e => (e.status === 'OPEN' || e.status === 'REJECTED') && report.reportStatus === 'PUBLISHED').length : '0' : <Skeleton/>}</Typography>
                    </Box>
                )}
            </Grid>
        </Grid>
    )
}