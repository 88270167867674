import {AutomationFramework, selectEvidenceDialogPropsTypes} from "../../../../types";
import {useSelectEvidenceDialogs} from "../../../../hooks/campaignReviewHooks/instructions/useSelectEvidenceDialogs";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React from "react";
import {Autocomplete} from "@mui/lab";
import {TextFieldCustom} from "../../../styled";
import {Flex} from "../../../../../../../newShared/components/Layouts";
import {useMedia} from "../../../../../../../newShared/hooks/useMedia";
import {LoadingButton} from "../../../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";


export const SelectEvidenceDialog = (props: selectEvidenceDialogPropsTypes) => {
    const {
        frameworks,
        controls,
        evidences,

        selectedFramework,
        selectedControl,
        selectedEvidence,

        handleChangeFramework,
        handleChangeControl,
        handleChangeEvidence,

        isLoading,
        isOpen,
        isOk,

        actions
    } = useSelectEvidenceDialogs(props);
    const {t} = useMainTranslation('', {keyPrefix: 'pathAccessManagementAppsAndCampaign.CampaignReviewInstructionCreate'});

    const {isMobile} = useMedia();

    return(
        <Dialog open={isOpen} onClose={actions.handleClose} >
            <DialogTitle>{t('Select place to save results')}</DialogTitle>
            <DialogContent>

                <Flex style={{marginTop: '10px'}} direction={'column'} ai={isMobile ? 'center' : 'flex-start'}>
                    {/*<Typography variant={'body2'} >{('Select evidence for scan release.')}</Typography>*/}

                    <Autocomplete
                        id="combo-box-demo"
                        options={frameworks}
                        getOptionLabel={(option) => option.name}
                        loading={isLoading}
                        disabled={isLoading}
                        dir={'ltr'}
                        size={'small'}
                        value={selectedFramework}
                        onChange={(e, newValue: AutomationFramework | null) => handleChangeFramework(newValue)}
                        sx={{width: isMobile ? '100%' : '320px', marginTop: '16px'}}
                        renderInput={(params) => <TextFieldCustom {...params} label={t("Framework")} required/>}
                    />

                    <Autocomplete
                        id="combo-box-demo"
                        options={selectedFramework ? controls.filter(e => e.frameworkIds.includes(selectedFramework.id)).sort((a, b) => a.id > b.id ? 1 : -1) : []}
                        getOptionLabel={(option) => option.name}
                        disabled={!selectedFramework || isLoading}
                        // groupBy={(e) => e.category}
                        dir={'ltr'}
                        loading={isLoading}
                        value={selectedControl}
                        onChange={(e, newValue) => handleChangeControl(newValue)}
                        sx={{width: isMobile ? '100%' : '320px', marginTop: '16px'}}
                        size={'small'}
                        renderInput={(params) => <TextFieldCustom {...params} label={t("Control")} required/>}
                    />

                    <Autocomplete
                        id="combo-box-demo"
                        options={selectedControl ? evidences.filter(e => e.controlIds.includes(selectedControl.id)) : []}
                        getOptionLabel={(option) => option.name}
                        getOptionDisabled={(option) => props.addedEvidenceIds.some(e => option.id === e)} //disabling option if such evidence already added
                        disabled={!selectedControl || isLoading}
                        loading={isLoading}
                        dir={'ltr'}
                        value={selectedEvidence}
                        onChange={(e, newValue) => handleChangeEvidence(newValue)}
                        sx={{width: isMobile ? '100%' : '320px', marginTop: '16px'}}
                        size={'small'}
                        renderInput={(params) => <TextFieldCustom {...params} label={t("Evidence")} required/>}
                    />

                </Flex>


            </DialogContent>

            <DialogActions>
                <Button onClick={actions.handleClose} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    loading={isLoading}
                    onClick={actions.handleSubmit}
                    disabled={!isOk}
                >
                    {t('Select')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}