import {Box, styled} from "@mui/material";

export const TextComponentWrapper = styled(Box)<{align: string}>`
  //border: 1px solid blue;
  //height: 100px;
  padding: 8px;
  //line-height: 130%;
  text-align: ${props => props.align};
  &:focus-visible{
    border: none;
    outline: none;
  }
  
  //span {
  //  display: inline-block;
  //  word-break: break-all;
  //};
  
  //br {
  //  display: block;
  //  content: "";
  //  margin: 0;
  //}
`;