import {useMatchesList} from "../../../hooks/matches/useMatchesList";
import * as S from '../../../components/styled/index';
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";
import React from "react";
import {GenericTable} from "../../../../../../newShared/components/genericTable";
import {KycMatchModel} from "../../../../../../newShared/GQLTypes";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {getNormalizedTargetType} from "../../../helpers";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const MatchesList = () => {
    const {matches, configs,isOutdated, handleReloadData, genericTable, handleViewResult} = useMatchesList();
    const {isMobile} = useMedia();
    const {t} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.screenings'});
    return(
        <S.PageContainer>
            <PageTitleAndActions
                showDataHasBeenUpdated={isOutdated}
                onReloadData={handleReloadData}
                title={t('Matches')}
                actions={[]}
            />


                <GenericTable<KycMatchModel>
                    id={'MatchesList'}
                    columnsConfig={{totalName: t('Total matches'), forceShowCardsWhen: isMobile}}
                    paging={genericTable.paging}
                    rowsConfig={{
                        rows: matches,
                        customCellPaddings: '8px',
                        rowActionsConfig: [
                            {
                                title: t('View screening'),
                                onClick: handleViewResult,
                                buttonProps: {variant: 'outlined', color: 'primary'}
                            }
                        ],
                        getRowUniqueId: (row) => row.id,
                        onRowClick: () => null,
                        columnsConfigs: [
                            {name: t('Client name'), key: 'targetName', default: true},
                            {name: t('Template'), key: 'templateName', default: true},
                            {name: t('Type'), key: 'type', default: true, valueGetter: (row) => getNormalizedTargetType(row.templateType ?? '-')},
                            {name: t('Match date'), key: 'matchDate', default: true, valueGetter: (row) => parseDateAuto(row.createdDate)},
                        ]
                    }}
                    filtersConfig={{
                        dontSendSearchIfEmpty: true,
                        searchCustomFilterKeyName: 'nameLike',
                        genericFilterProps: {
                            configs,
                            fetchResultWithSelectedFilters: () => null,
                            isAddFilters: false
                        }
                    }}
                    emptyArrayImageProps={{
                        type: 'kycTemplates',
                        filterNotApplied: {text: t('Matches will appear here')}
                    }}
                />


            {/*<GenericFiltersArray*/}
            {/*    configs={configs}*/}
            {/*    dateFilterFormat={KYC_DATE_FORMAT}*/}
            {/*    fetchResultWithSelectedFilters={filter.applyFilter}*/}
            {/*    isAddFilters={false}*/}
            {/*/>*/}

            {/*{!isNoMatchesFound &&*/}
            {/*    <S.CommonTableContainer onScroll={scroll.onScroll} ref={scroll.scrollRef}>*/}
            {/*        <Table stickyHeader>*/}
            {/*            <MatchesTableHeader total={pageInfo.total}/>*/}
            {/*            <TableBody>*/}
            {/*                {matches.map(e => (<MatchesTableRow key={e.id} match={e}/>))}*/}
            {/*                {(isLoading || scroll.hasMore) && <TableRowSkeleton columns={5} />}*/}
            {/*            </TableBody>*/}
            {/*        </Table>*/}
            {/*    </S.CommonTableContainer>*/}
            {/*}*/}


            {/*<EmptyArrayImage*/}
            {/*    type={'kycTemplates'}*/}
            {/*    isShowing={isNoMatchesFound}*/}
            {/*    text={('No matches clients found.')}*/}
            {/*    withoutAdd*/}
            {/*/>*/}
        </S.PageContainer>
    )
}
