import {FC} from 'react';
import {LeftSecondMenu} from "../../barsEnvironment/leftMenu/components/leftSecondMenu";
import {Routes} from "./routes";
import {useInit} from "./hooks/init";
import {Spinner} from "../../../newShared/components/Spinner";


export const RiskManagement:FC = () => {
    const isLoading = useInit();
    return(
        <LeftSecondMenu>
            {isLoading ? <Spinner /> : <Routes/>} 
        </LeftSecondMenu>
    )
}
