import {FC, Fragment} from "react";
import {Breadcrumbs} from "../../../../barsEnvironment/breadcrumbs";
import {Box, Divider, IconButton, Skeleton, Tab, Tabs, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {ArrowBack, ArrowForward} from "@mui/icons-material";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {SolutionTree} from "./components/solutionTree";
import {useAnalysisViewPage} from "./hooks/useAnalysisViewPage";
import {PageWrapper} from "../../../../../newShared/components/Layouts";

export const AnalysisView: FC = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathFrameworks.pathAnalysis'});
    const {isLessThen1050} = useMedia();

    const {
        goToAnalysis,
        currentAnalysis,
        tab, tabs,
        isLoadingCurrent
    } = useAnalysisViewPage();

    return (
        <PageWrapper flexBasis={!isLessThen1050 ? '50%' : "100%"}>
            {isLessThen1050 && (
                <>
                    <Breadcrumbs/>

                    <Box display={"flex"} alignItems={"center"} mt={'12px'} flexWrap={"wrap"}>
                        <Typography variant={"h3"} margin={!revDir ? '0 32px 0 8px' : '0 8px 0 32px'} component={"span"}>
                            {currentAnalysis && (
                                <IconButton sx={{color: colors.text.blue, margin: !revDir ? '0 12px 4px 0' : '0 0 4px 12px', padding: '0'}}
                                            onClick={() => {goToAnalysis({})}}>
                                    {!revDir ? <ArrowBack/> : <ArrowForward/>}
                                </IconButton>
                            )}

                            {currentAnalysis ? currentAnalysis.auditName : <Skeleton width={'300px'}/>}
                        </Typography>
                    </Box>
                </>
            )}

            <Tabs value={tab} variant={"scrollable"}>
                {tabs.map(tab => (
                    <Tab key={tab.title} label={t(tab.title)} value={tab.title} onClick={tab.handleClick}/>
                ))}
            </Tabs>

            {tab === 'Findings' && (
                <Box width={'100%'} display={'flex'} flexDirection={'column'} overflow={'auto'}>
                    {isLoadingCurrent ? (
                        <>
                            <Typography variant={"body2"} sx={{marginTop: '8px'}}><Skeleton/></Typography>
                            <Typography variant={"body2"} sx={{marginTop: '8px'}}><Skeleton/></Typography>
                            <Typography variant={"body2"} sx={{marginTop: '8px'}}><Skeleton/></Typography>
                            <Divider flexItem sx={{marginTop: '8px', marginBottom: '16px'}}/>
                            <Typography variant={"body2"} sx={{marginTop: '8px'}}><Skeleton/></Typography>
                            <Typography variant={"body2"} sx={{marginTop: '8px'}}><Skeleton/></Typography>
                            <Typography variant={"body2"} sx={{marginTop: '8px'}}><Skeleton/></Typography>
                            <Divider flexItem sx={{marginTop: '8px', marginBottom: '16px'}}/>
                            <Typography variant={"body2"} sx={{marginTop: '8px'}}><Skeleton/></Typography>
                            <Typography variant={"body2"} sx={{marginTop: '8px'}}><Skeleton/></Typography>
                            <Typography variant={"body2"} sx={{marginTop: '8px'}}><Skeleton/></Typography>
                            <Divider flexItem sx={{marginTop: '8px', marginBottom: '16px'}}/>
                        </>
                    ) : ((currentAnalysis?.findings as {[key: string]: string}[] | undefined)?.map(el => (
                        <Fragment key={JSON.stringify(el)}>
                            <Box width={'100%'} display={'flex'} flexDirection={'column'}>
                                {Object.entries(el).map(([key, value]) => <Typography key={`${key}: ${value}`} variant={"body2"} sx={{marginTop: '8px'}}>{`${key}: ${value}`}</Typography>)}
                            </Box>

                            <Divider flexItem sx={{marginTop: '8px', marginBottom: '16px'}}/>
                        </Fragment>
                        ))
                    )}
                </Box>
            )}

            {tab === 'Solution' && (
                <Box width={'100%'} display={'flex'} flexDirection={'column'} overflow={'auto'}>
                    {currentAnalysis ? <SolutionTree solutions={currentAnalysis.solution}/>
                        : isLoadingCurrent && (
                        <>
                            <Skeleton/>
                            <Skeleton/>
                            <Skeleton/>
                            <Skeleton/>
                            <Skeleton/>
                            <Skeleton/>
                        </>
                    )}
                </Box>
            )}


        </PageWrapper>
    )
}