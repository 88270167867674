import {genericPageGenericRowSkeletonPropsType} from "../../types";
import {Skeleton, TableCell, TableRow, Typography} from "@mui/material";
import colors from "../../../../theme/colors";
import React from "react";

export function DynamicRowSkeleton<T extends Object>({configs, isActionCellExists, isTop}: genericPageGenericRowSkeletonPropsType<T>){
    return(
        <TableRow data-skeleton={isTop ? 'top' : 'bottom'}>
            {configs.map(e => {
                return(
                    <TableCell key={String(e.key)} sx={{padding: '10.5px 16px'}}>
                        {<Typography variant={'subtitle1'} noWrap sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}><Skeleton /></Typography>}
                    </TableCell>
                )
            })}

            {isActionCellExists &&
                <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                    <Typography variant={'body2'} color={colors.grayText}><Skeleton /></Typography>
                </TableCell>
            }
        </TableRow>
    )
}