import {Breadcrumbs} from "../../../../../barsEnvironment/breadcrumbs";
import * as S from "../../../components/styled";
import {Flex} from "../../../../../../newShared/components/Layouts";
import colors from "../../../../../../newShared/theme/colors";
import {Button, Typography} from "@mui/material";
import React from "react";
import {useRegistriesExact} from "../../../hooks/useRegistriesExact";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

//icons
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {ExactRegistryRightSide} from "../../../components/exactRegistryRightSide";
import {ExactRegistryActionsBar} from "../../../components/exactRegistryActionsBar";
import {RisksTable} from "../../../components/risksTable";
import {DeleteRisk} from "../../../components/dialogs/deleteRisk";
import {EditRisk} from "../../../components/dialogs/editRisk";
import {CreateRisk} from "../../../components/dialogs/createRisk";
import {EditRegistry} from "../../../components/dialogs/editRegistry";
import {DeleteRegistry} from "../../../components/dialogs/deleteRegistry";
import {ReleaseRisks} from "../../../components/dialogs/releaseRisks";
import {Skeleton} from "@mui/lab";
import {ImportCsvDialog} from "../../../../../../newShared/components/csvImportDialog";
import {useImportCsv} from "../../../hooks/dialogsHooks/useImportCsv";
import {GenericTable} from "../../../../../../newShared/components/genericTable";
import {TRisk} from "../../../types";
import {getColorByRiskRate, getRiskRate} from "../../../helpers";
import {ExportCsvDialog} from "../../../../../../newShared/components/csvExportDialog";


export const RegistriesExact = () => {
    const {
        // isLoading,
        isLoadingFields,
        isLoadingExportCsv,
        exportCsv,
        selected,
        actions,
        menu,
        isBlockView,
        currModel,
        risks,
        isLoadingRisks,
        hasMore,
        selectedRisk,
        isRightSideOpen,
        genericTable,
        handleLoadMore
    } = useRegistriesExact();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'risks'});
    const importCsv = useImportCsv();

    return(
        <Flex w={'100%'} h={'100%'} jc={'space-between'}>
            <S.ExactRegistryLeftContainer isRightSideOpen={isRightSideOpen}>
                <Breadcrumbs/>

                <ExactRegistryActionsBar
                    menu={menu}
                    actions={actions}
                    isRightSideOpen={isRightSideOpen}
                    selected={selected}
                    isLoadingRisks={isLoadingRisks}
                    isNoRisks={!risks.length}
                />

                <Flex ai={'center'} m={'8px 0 0 0'}>
                    <Typography variant={'body2'} color={colors.text.grey_dark}>{(!selected) ? <Skeleton width={200}/> : t('Risks_doubleDot {{counter}}', {counter: selected.totalRisks})}</Typography>
                </Flex>
                <Flex ai={'center'} m={'8px 0 0 0'}>
                    <Typography variant={'body2'} color={colors.text.grey_dark}>
                        {(!selected) ? <Skeleton width={200} /> : `${t('Last update')}: ${parseDateAuto(selected.lastUpdate.date)}`}
                    </Typography>

                </Flex>
                <Flex ai={'center'} m={'8px 0 0 0'}>
                    <Typography variant={'body2'} color={colors.text.grey_dark}>{(!selected) ? <Skeleton width={200}/> : t('Model: {{name}}', {name: currModel?.name ?? ''})}</Typography>
                    {!isLoadingRisks && <Button variant={'text'} size={'small'} onClick={actions.handleOpenModelInNewTab}
                                                disabled={!selected}>{t('Open in new tab')}</Button>}
                </Flex>

                {isBlockView ?
                    <GenericTable<TRisk>
                        id={'RegistriesExact'}
                        columnsConfig={{totalName: 'Total risks', disableColumnsSettings: true, disableShowCards: true}}
                        paging={genericTable.paging}
                        rowsConfig={{
                            rows: risks,
                            customCellPaddings: '8px',
                            getRowUniqueId: (row) => row.id,
                            onRowClick: actions.handleOpenRisk,
                            columnsConfigs: [
                                {key: "name", name: t('Name'), default: true},
                                {key: 'type', name: t('Type'), default: true},
                                {key: 'rating', name: t('Rating'), default: true, valueRenderer: (row) => {
                                        const riskRate = currModel ? getRiskRate(row.initialGrade.probability ?? 0, row.initialGrade.impact ?? 0, currModel) : 'Unknown';
                                        const colorRate = getColorByRiskRate(riskRate);
                                        return(
                                            <Typography variant={'*bodyText2'} color={colorRate}>{t(riskRate)}</Typography>
                                        )
                                    }},
                                {key: 'control', name: t('Control'), default: true, valueGetter: (row) => row.control.type ?? t('None')},
                                {key: 'resRating', name: t('Residual rating'), default: true, valueRenderer: (row) => {
                                        const residualRate = currModel ? getRiskRate(row.residualGrade.probability ?? 0, row.residualGrade.impact ?? 0, currModel) : 'Unknown';
                                        const colorResidualRate = getColorByRiskRate(residualRate);
                                        return(
                                            <Typography variant={'*bodyText2'} color={colorResidualRate}>{t(residualRate)}</Typography>
                                        )
                                    }},
                            ],
                        }}
                        filtersConfig={{}}
                        emptyArrayImageProps={{type: 'risks', filterNotApplied: {text: ('Risks will be displayed here'), onClick: actions.handleOpenAddRisk}}}
                    />
                    :
                    <>
                        {!risks.length && !isLoadingRisks && <Typography color={colors.darkBlueText} sx={{margin: '8px 0 0 0'}}>{t('Risks will be displayed here.')}</Typography>}
                        <RisksTable
                            risks={risks}
                            handleSetNeedUpdate={() => null}
                            hasMore={hasMore}
                            loadMore={(page) => handleLoadMore(page)}
                            isLoading={isLoadingRisks}
                            model={currModel}
                        />
                    </>
                }
            </S.ExactRegistryLeftContainer>

            <S.ExactRegistryRightContainer isRightSideOpen={isRightSideOpen} revDir={revDir}>
                <ExactRegistryRightSide risk={selectedRisk} model={currModel}/>
            </S.ExactRegistryRightContainer>

            {/*DIALOGS*/}
            <DeleteRisk />
            <EditRisk />
            <CreateRisk />
            <EditRegistry />
            <DeleteRegistry />
            <ReleaseRisks />

            <ExportCsvDialog
                isOpen={exportCsv.isOpen}
                isLoadingFields={isLoadingFields}
                isLoadingExport={isLoadingExportCsv}
                availableFields={exportCsv.availableFields}
                handleExportCsv={exportCsv.handleExportCsv}
                handleClose={exportCsv.handleClose}
                fieldsMapperKey={"risks"}
            />

            <ImportCsvDialog
                isOpen={importCsv.isOpen}
                fields={importCsv.fields}
                response={importCsv.response}
                setImportDialog={importCsv.setImportDialog}

                isGettingFields={importCsv.isGettingFields}
                isPerformingCsv={importCsv.isPerformingCsv}
                isLoadingTemplate={importCsv.isLoadingTemplate}

                getFields={importCsv.getFields}
                performCsv={importCsv.performCsv}
                downloadTemplate={importCsv.downloadTemplate}
                fieldsMapperKey={importCsv.fieldsMapperKey}

                fileLimitInMB={5}
            />
        </Flex>
    )
}
