import moment, {unitOfTime} from 'moment';
// import 'moment/locale/ar';
import 'moment/locale/he';
import 'moment/locale/en-au';
import {Logging} from "../logs/log";
import {store} from "../../redux";


export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATE_WITH_TIME_FORMAT = 'YYYY-MM-DD LT';

export const parseDateAuto = (date: string | Date | null | undefined, withTime?: boolean, ignoreLocale?: boolean, customFormat?: string, fallback?: string): string => {
    withTime = withTime ?? false;
    customFormat = customFormat ?? DEFAULT_DATE_FORMAT;
    fallback =  fallback ?? '- - -';
    if(customFormat === DEFAULT_DATE_FORMAT && withTime) customFormat = DEFAULT_DATE_WITH_TIME_FORMAT;
    //checks
    if(!date || ((typeof date === 'string') && date.trim().length === 0)){
        return fallback;
    }else{
        if(typeof date === 'string'){
            date = date.substring(0, '2023-08-04T00:00:00'.length);
            if(ignoreLocale){
                return moment(date).format(customFormat);
            }else{
                const locale = store.getState().I18Next.selectedLanguage.momentLocale;
                date = date.endsWith('Z') ? date : `${date}Z`;
                return moment(date).locale(locale).format(customFormat);
            }
        }else{
            const locale = store.getState().I18Next.selectedLanguage.momentLocale;
            return moment(date).locale(locale).format(customFormat);
        }
    }
}

// export const parseDate = (date: string | Date | null | undefined, locale: string, format?: string, ignoreLocale?: boolean): string => {
//     if(!date) return '- - -';
//     try{
//         if(typeof date === 'string'){
//             if(!date.length) return '- - -';
//             date = date.substring(0, '2023-08-04T00:00:00'.length);
//             if(ignoreLocale){
//                 // console.log(`parseDate`, date, moment(date).format(format ?? 'L'));
//                 return moment(date).format(format ?? 'L');
//             }else{
//                 date = date.endsWith('Z') ? date : `${date}Z`;
//                 // console.log(`parseDate`, date, moment(date).locale(locale).format(format ?? 'L'));
//                 return moment(date).locale(locale).format(format ?? 'L');
//             }
//         }else{
//             // console.log(`parseDate`, date, moment(date).locale(locale).format(format ?? 'L'));
//             return moment(date).locale(locale).format(format ?? 'L');
//         }
//     }catch (ex){
//         console.log(`parseDate ex`, ex);
//         return '- - -';
//     }
// }

export const parseDateInDate = (date: string | null | undefined, locale: string): Date | null => {
    if(!date || !date.length) return null;
    const parsedDate = new Date(date.endsWith('Z') ? date : `${date}Z`);
    return moment(parsedDate).locale(locale).toDate();
}

export const isExpired = (date: string, locale: string): boolean => {
    if(!date || !date.length) return true;
    const parsedDate = new Date(date.endsWith('Z') ? date : `${date}Z`);
    return moment(parsedDate).locale(locale).isBefore(moment(new Date(Date.now())));
}

export const diffBetweenDateAndNow = (date: string, locale: string, unit: unitOfTime.Diff = 'days'): number => {
    if(!date || !date.length) return 0;
    const parsedDate = new Date(date.endsWith('Z') ? date : `${date}Z`);
    return moment(parsedDate).locale(locale).diff(moment(new Date(Date.now())), unit, true);
}

export const isSameDay = (a: string | null | undefined, b: string | null | undefined, locale: string): boolean => {
    if(!a || !b) return false;
    try{
        const dateA = new Date(Date.parse(a)).toISOString();
        const dateB = new Date(Date.parse(b)).toISOString();
        return moment(dateA).locale(locale).isSame(moment(dateB).locale(locale), 'day');
    }catch (ex){
        Logging.log(ex);
        return false;
    }
}

export const isFutureDate = (a: string | null | undefined, locale?: string, fromDate?: string | null | undefined): boolean => {
    // console.log(`isFutureDate`, a, locale, fromDate)
    locale = locale ?? store.getState().I18Next.selectedLanguage.momentLocale;
    if(!a) return false;
    try {
        const dateA = new Date(Date.parse(a)).toISOString();
        const dateB = fromDate ? new Date(Date.parse(fromDate)).toISOString() : new Date().toISOString();

        return moment(dateA).locale(locale).isAfter(moment(dateB).locale(locale))
    }catch (ex){
        Logging.log(ex);
        return false;
    }
}

export const isPastDate = (a: string | null | undefined, locale: string, toDate?: string | null | undefined): boolean => {
    if(!a) return false;
    try {
        const dateA = new Date(Date.parse(a));
        const dateB = toDate ? new Date(Date.parse(toDate)).toISOString() : new Date().toISOString();
        // console.log(`isPastDate`, moment(dateA).utc(true).locale(locale), moment(dateB).locale(locale))
        return moment(dateA).locale(locale).isSameOrBefore(moment(dateB).locale(locale))
    }catch (ex){
        Logging.log(ex);
        return false;
    }
}

export const getNormalizedForBackendDateDay = (date: string): string => {
    return date?.slice(0, '2022-07-15T10:19:26'.length) || date;
}

export const getMsFromDays = (days: number): number => {
    return days * 24 * 60 * 60 * 1000;
}

export const getDaysFromMs = (ms: number): number => {
    return Math.floor(ms / (24 * 60 * 60 * 1000));
}

export const removeTimeZoneDate = (date: string | null): string | null => {
    if(!date) return date;
    return moment(date).format();
}