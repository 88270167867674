import {genericPageGenericHeaderPropsType} from "../../types";
import {TableCell, TableHead, TableRow} from "@mui/material";
import colors from "../../../../theme/colors";
import React from "react";


export function DynamicHeader({columnConfig, isShowColumnSettings, isActionCellExists}: genericPageGenericHeaderPropsType){
    return(
        <TableHead sx={{backgroundColor: colors.backgrounds.grey_light}}>
            <TableRow sx={{'& .MuiTableCell-root': {backgroundColor: colors.backgrounds.grey_light, width: '100%', padding: isShowColumnSettings ? '5px 4px 5px 4px':'10.5px 16px'}}}>
                <TableCell colSpan={columnConfig.length + (isActionCellExists ? 1 : 0)} sx={{height: '45px !important', zIndex: '1001'}}>

                </TableCell>
            </TableRow>

            <TableRow sx={{'& .MuiTableCell-root': {top: '45px'}}}>
                {columnConfig.map(e => {
                    return (
                        <TableCell key={String(e.key)} sx={{whiteSpace: 'nowrap', zIndex: '1001'}}>
                            {e.name}
                        </TableCell>
                    )
                })}

                {isActionCellExists &&
                    <TableCell sx={{'& p': {whiteSpace: 'nowrap', zIndex: '1001'}}}>
                    </TableCell>
                }
            </TableRow>
        </TableHead>
    )
}
