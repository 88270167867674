import {useEffect} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_VICA, PATH_VICA_DASHBOARD} from "../../constants";
import {useFrameworks} from "../../../frameworks/hooks/useFrameworks";

export const useDashboardVicaPage = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    const {
        adopted: {
            getData, frameworks, isLoading
        }
    } = useFrameworks();

    //Controller and first loadData
    const controller = new AbortController();
    useEffect(() => {
        getData({})
        return () => {
            controller.abort();
            // cleanArray()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Breadcrumbs
    useSetBreadcrumbs([
        {
            title: tMenu('Vica'),
            path: PATH_LOCAL_VICA
        },
        {
            title: tMenu('Dashboard'),
            path: PATH_VICA_DASHBOARD
        }
    ]);


    return {
        // searchValue, handleChange, handleKeyDown, setFilter,
        frameworks, isLoading,
    }
}
