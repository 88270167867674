import React from "react";
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {useHRSettings} from "../../hooks/useSettings";
import {
    CUSTOM_FIELDS_ID,
    DEGREE_ID,
    DEPARTMENT_ID,
    DIVISION_ID,
    EMPLOYMENT_TYPE_ID,
    LOCATION_ID,
    RELATIONSHIP_TYPE_ID
} from "../../constants";
import {TableRowSkeleton} from "../../../../../newShared/components/tableRowSkeleton";

export const EmployeesSettings = () => {
    const {settings, isLoadingHrSettings, handleGoToSettingById} = useHRSettings();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathHRManagement.settings'});

    return(
        <Box width={'100%'} height={'100%'} display={'flex'} flexDirection={'column'}>
            <PageTitleAndActions
                title={t('Settings')}
                actions={[]}
            />

            <TableContainer sx={{mt: '10px'}}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell width={'50%'} align={revDir ? 'right' : 'left'}>{t('Title')}</TableCell>
                            <TableCell align={revDir ? 'right' : 'left'}>{t('Employees')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoadingHrSettings ?
                            <TableRowSkeleton columns={2} />
                            :
                            <>
                                <TableRow hover onClick={() => handleGoToSettingById(DEGREE_ID)}>
                                    <TableCell width={'70%'}  align={revDir ? 'right' : 'left'} >{t('Degree')}</TableCell>
                                    <TableCell   align={revDir ? 'right' : 'left'} >{settings && settings.degree  ? Object.values(settings.degree).reduce((prev, next) => {return prev + next}, 0).toString() : '0'}</TableCell>
                                </TableRow>
                                <TableRow hover onClick={() => handleGoToSettingById(DEPARTMENT_ID)}>
                                    <TableCell width={'70%'} align={revDir ? 'right' : 'left'} >{t('Department')}</TableCell>
                                    <TableCell align={revDir ? 'right' : 'left'} >{settings && settings.department  ? Object.values(settings.department).reduce((prev, next) => {return prev + next}, 0).toString() : '0'}</TableCell>
                                </TableRow>
                                <TableRow hover onClick={() => handleGoToSettingById(DIVISION_ID)}>
                                    <TableCell width={'70%'}  align={revDir ? 'right' : 'left'} >{t('Division')}</TableCell>
                                    <TableCell   align={revDir ? 'right' : 'left'} >{settings && settings.division  ? Object.values(settings.division).reduce((prev, next) => {return prev + next}, 0).toString() : '0'}</TableCell>
                                </TableRow>
                                <TableRow hover onClick={() => handleGoToSettingById(EMPLOYMENT_TYPE_ID)}>
                                    <TableCell width={'70%'}  align={revDir ? 'right' : 'left'} >{t('Employment type')}</TableCell>
                                    <TableCell   align={revDir ? 'right' : 'left'} >{settings && settings.employmentType  ? Object.values(settings.employmentType).reduce((prev, next) => {return prev + next}, 0).toString() : '0'}</TableCell>
                                </TableRow>
                                <TableRow hover onClick={() => handleGoToSettingById(LOCATION_ID)}>
                                    <TableCell width={'70%'}  align={revDir ? 'right' : 'left'} >{t('Location')}</TableCell>
                                    <TableCell   align={revDir ? 'right' : 'left'} >{settings && settings.location  ? Object.values(settings.location).reduce((prev, next) => {return prev + next}, 0).toString() : '0'}</TableCell>
                                </TableRow>

                                <TableRow hover onClick={() => handleGoToSettingById(RELATIONSHIP_TYPE_ID)}>
                                    <TableCell width={'70%'}  align={revDir ? 'right' : 'left'} >{t('Relationship type')}</TableCell>
                                    <TableCell   align={revDir ? 'right' : 'left'} >{settings && settings.relationshipType  ? Object.values(settings.relationshipType).reduce((prev, next) => {return prev + next}, 0).toString() : '0'}</TableCell>
                                </TableRow>
                                <TableRow hover onClick={() => handleGoToSettingById(CUSTOM_FIELDS_ID)}>
                                    <TableCell width={'70%'}  align={revDir ? 'right' : 'left'} >{t('Custom fields')}</TableCell>
                                    <TableCell   align={revDir ? 'right' : 'left'} >{'-'}</TableCell>
                                </TableRow>
                            </>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}