import {FC} from "react";
import {IconButton} from "@mui/material";
import {QuestionMark} from "@mui/icons-material";
// import {useHistory} from "react-router";
// import {PATH_LOCAL_HELP} from "../../../../../newShared/constants";
import {useDispatch} from "react-redux";
import {ContactUsDialog} from "../../../../components/contactUs/contactUsDialog";
import {setContactUsDialogAction} from "../../../../components/contactUs/store/slice";

export const TopBarHelp: FC = () => {
    const dispatch = useDispatch();
    const openContactUsDialog = () => {
        dispatch(setContactUsDialogAction({isOpen: true}))
    }
    //const history = useHistory();

    return (
        <>
            <IconButton
                onClick={openContactUsDialog}
                size={"large"}
            >
                <QuestionMark />
            </IconButton>
            <ContactUsDialog/>
        </>
    )
}