import { PATH_LOCAL_EMPLOYEES } from "../../../newShared/constants";
import {
  ColumnType,
  EditJobTitleType,
  EmployeeType,
  TEmployeeContact,
  TEmployeeCreateJobHistory,
  TEmployeeEducation,
  TEmployeeEmergencyContact,
  TEmployeeUpdateJobHistory,
  THrSettings,
} from "./types";
import {HrIssuesStatus, HrTimeOffStepsStatus, HrTimeOffStepsType, RequestType} from "../../../newShared/GQLTypes";

export const PATH_LOCAL_EMPLOYEES_ALL = `${PATH_LOCAL_EMPLOYEES}/all`;
export const PATH_LOCAL_EMPLOYEES_ID = `${PATH_LOCAL_EMPLOYEES_ALL}/exact/:id`;
export const PATH_LOCAL_EMPLOYEES_NEW = `${PATH_LOCAL_EMPLOYEES_ALL}/new`;
export const PATH_LOCAL_EMPLOYEES_SETTINGS = `${PATH_LOCAL_EMPLOYEES}/settings`;
export const PATH_LOCAL_EMPLOYEES_JOB_TITLE = `${PATH_LOCAL_EMPLOYEES}/jobTitles`;
export const PATH_LOCAL_EMPLOYEES_JOB_TITLE_EXACT = `${PATH_LOCAL_EMPLOYEES}/jobTitles/exact/:id`;
export const PATH_LOCAL_EMPLOYEES_SETTINGS_NEW = `${PATH_LOCAL_EMPLOYEES}/settings/:id/new`;
export const PATH_LOCAL_EMPLOYEES_SETTINGS_EXACT = `${PATH_LOCAL_EMPLOYEES}/settings/exact/:id`;
export const PATH_LOCAL_EMPLOYEES_JOB_TITLE_NEW = `${PATH_LOCAL_EMPLOYEES}/jobTitles/new`;
export const PATH_LOCAL_EMPLOYEES_TREE = `${PATH_LOCAL_EMPLOYEES}/tree`;
export const PATH_LOCAL_EMPLOYEES_TREE_EXACT = `${PATH_LOCAL_EMPLOYEES_TREE}?id=:employeeId`;
export const PATH_LOCAL_EMPLOYEES_INCOMING_REQUESTS = `${PATH_LOCAL_EMPLOYEES}/incomingRequests`;
export const PATH_LOCAL_TIME_OFF_REQUESTS = `${PATH_LOCAL_EMPLOYEES}/timeoff`;
export const PATH_LOCAL_TIME_OFF_REQUESTS_EXACT = `${PATH_LOCAL_EMPLOYEES}/timeoff/:timeoffId`;
export const PATH_LOCAL_EMPLOYEES_ISSUES = `${PATH_LOCAL_EMPLOYEES}/issues`;
export const PATH_LOCAL_EMPLOYEES_ISSUES_EXACT = `${PATH_LOCAL_EMPLOYEES_ISSUES}/:issueId`;

//COLUMNS
//those are custom keys for valueGetter
//those keys are not present in object and can be captured from inner objects
//making consts to use then in switch func
export const PHONE_COLUMN_KEY = "phoneNumber";
export const COUNTRY_COLUMN_KEY = "country";
export const CITY_COLUMN_KEY = "city";
export const STREET_COLUMN_KEY = "street";
export const ZIP_COLUMN_KEY = "zip";
export const JOB_TITLE_COLUMN_KEY = "jobTitle";
export const DEP_COLUMN_KEY = "department";
export const LOCATION_COLUMN_KEY = "location";
export const EMP_TYPE_COLUMN_KEY = "employeeType";
export const DIVISION_COLUMN_KEY = "division";
export const MANAGER_COLUMN_KEY = "manager";
export const LAST_JOB_START_DATE_COLUMN_KEY = "lastJobStartDate";

export const availableColumns: ColumnType[] = [
  {
    key: "firstName",
    normalizedName: "First name",
  },
  {
    key: "lastName",
    normalizedName: "Last name",
  },
  {
    key: "employeeId",
    normalizedName: "ID",
  },
  {
    //will be handled by value getter because it is placed deeper into jobHistory object in last obj
    key: JOB_TITLE_COLUMN_KEY,
    normalizedName: "Job title",
  },
  {
    //will be handled by value getter because it is placed deeper into jobHistory object in last obj
    key: DEP_COLUMN_KEY,
    normalizedName: "Department",
  },
  {
    key: PHONE_COLUMN_KEY,
    normalizedName: "Phone",
  },
  {
    key: "workEmail",
    normalizedName: "Email",
  },
  {
    key: EMP_TYPE_COLUMN_KEY,
    normalizedName: "Employment type",
  },
  {
    key: COUNTRY_COLUMN_KEY,
    normalizedName: "Country",
  },
  {
    key: CITY_COLUMN_KEY,
    normalizedName: "City",
  },
  {
    key: STREET_COLUMN_KEY,
    normalizedName: "Street",
  },
  {
    key: ZIP_COLUMN_KEY,
    normalizedName: "ZIP",
  },
  {
    key: LOCATION_COLUMN_KEY,
    normalizedName: "Location",
  },
  {
    key: DIVISION_COLUMN_KEY,
    normalizedName: "Division",
  },
  {
    key: MANAGER_COLUMN_KEY,
    normalizedName: "Manager",
  },
  {
    key: LAST_JOB_START_DATE_COLUMN_KEY,
    normalizedName: "Job start date",
  },
  {
    key: "status",
    normalizedName: "Status",
  },
  {
    key: "birthDay",
    normalizedName: "Birth day",
  },
  {
    key: "gender",
    normalizedName: "Gender",
  },
  {
    key: "maritalStatus",
    normalizedName: "Marital status",
  },
  {
    key: "ethnicity",
    normalizedName: "Ethnicity",
  },
];

export const defaultColumnsConfig: ColumnType[] = [
  {
    key: "firstName",
    normalizedName: "First name",
  },
  {
    key: "lastName",
    normalizedName: "Last name",
  },
  {
    key: "employeeId",
    normalizedName: "ID",
  },
  {
    //will be handled by value getter because it is placed deeper into jobHistory object in last obj
    key: JOB_TITLE_COLUMN_KEY,
    normalizedName: "Job title",
  },
  {
    //will be handled by value getter because it is placed deeper into jobHistory object in last obj
    key: DEP_COLUMN_KEY,
    normalizedName: "Department",
  },
  {
    //will be handled by value getter because it is placed deeper into contacts array in object with phone type
    key: PHONE_COLUMN_KEY,
    normalizedName: "Phone",
  },
  {
    key: "workEmail",
    normalizedName: "Email",
  },
  {
    key: EMP_TYPE_COLUMN_KEY,
    normalizedName: "Employment type",
  },
  {
    key: LOCATION_COLUMN_KEY,
    normalizedName: "Location",
  },
  {
    key: MANAGER_COLUMN_KEY,
    normalizedName: "Manager",
  },
  {
    key: LAST_JOB_START_DATE_COLUMN_KEY,
    normalizedName: "Job start date",
  },
  {
    key: "hireDate",
    normalizedName: "Hire date",
  },
  {
    key: "status",
    normalizedName: "Status",
  },
];

//TODO
//used to define which contact of contacts array is with type phone
export const PHONE_NUMBER_CONTACT_TYPE = "Work Mobile Phone";

//
export const SAVE_COLUMNS_CONFIG_PREFIX = "COLUMN_CONFIG_";

export const mockEmployee: EmployeeType = {
  collaborator: null,
  workspaceId: "",
  trainings: [],
  documents: [],
  id: "",
  employeeId: "",
  firstName: "",
  lastName: "",
  nickname: "",
  middleName: "",
  hireDate: null,
  birthDay: null,
  workEmail: "",
  customFields: {},
  status: null,
  gender: "",
  maritalStatus: "",
  ethnicity: "",
  contactInfo: {
    contacts: [{ type: PHONE_NUMBER_CONTACT_TYPE, value: "" }],
    address: {
      country: null,
      city: "",
      zip: "",
      street: "",
    },
    emergencyContacts: [],
  },
  personalData: {
    education: [],
    veteranStatus: {
      activeDutyWartimeOrCampaignBadgeVeteran: false,
      armedForcesServiceMedalVeteran: false,
      disabledVeteran: false,
      recentlySeparatedVeteran: false,
    },
  },
  jobInfos: [
    {
      current: true,
      id: "",
      jobTitle: {
        name: "",
        description: "",
        requirements: "",
        jobType: "",
      },
      employmentType: "",
      location: "",
      department: "",
      division: "",
      manager: null,
      startDate: null,
    },
  ],
  accounts: [],
};

export const emptyEducation: TEmployeeEducation = {
  schoolName: "",
  degree: "",
  fieldOfStudy: "",
  startDate: null,
  endDate: null,
};

export const emptyContact: TEmployeeContact = {
  type: null,
  value: "",
};

export const emptyEmergencyContact: TEmployeeEmergencyContact = {
  relationType: null,
  fullName: "",
  phone: "",
  email: "",
  address: "",
};

export const defaultJobHistoryForm: TEmployeeCreateJobHistory = {
  startDate: null,
  division: null,
  location: null,
  employmentType: null,
  jobTitle: null,
  department: null,
  manager: null,
};

export const defaultEditJobHistoryForm: TEmployeeUpdateJobHistory = {
  startDate: null,
  division: null,
  location: null,
  employmentType: null,
  jobTitle: null,
  department: null,
  manager: null,
  id: "",
  current: false,
};

//tabs
export const EXACT_EMPL_TABS = [
  "general",
  "jobInfo",
  "docs",
  "trainings",
  "assets",
  "accounts",
];

export const EMPLOYEES_LIST_DEFAULT_PAGING = 50;

export const HrSettingsNormalizedNames: Record<keyof THrSettings, string> = {
  //only editable
  workspaceId: "",
  terminationType: "",
  terminationReason: "termination reason",
  eligibleForRehire: "",
  gender: "gender",
  maritalStatus: "marital status",
  ethnicity: "",
  country: "",
  contactType: "contact type",
  jobType: "",
  location: "location",
  department: "department",
  division: "division",
  relationshipType: "relationship type",
  degree: "degree",
  employmentType: "employment type",
  customFields: "custom field",
  status: "",
  jobTitle: "job title",
  timeOffType: "time off type",
};

export const EMPLOYEE_DISABLED_STATUSES = ["Terminated"];

export const defaultJobTitle: EditJobTitleType = {
  name: "",
  jobType: null,
  description: "",
  requirements: "",
  history: false,
  id: "",
  workspaceId: "",
  count: 0,
};

// export const EMPLOYEE_STATUS_ID = 'employeeStatus';
// export const TERMINATION_REASON_ID = 'terminationReason';
export const DEPARTMENT_ID = "department";
export const LOCATION_ID = "location";
export const DIVISION_ID = "division";
export const DEGREE_ID = "degree";
export const RELATIONSHIP_TYPE_ID = "relationshipType";
export const EMPLOYMENT_TYPE_ID = "employmentType";
export const CUSTOM_FIELDS_ID = "customFields";

export const statusTypeTitleMapper: Record<RequestType, string> = {
  EDIT_PROFILE: "Edit profile",
};

export const statusFilterTitleMapper: Record<HrIssuesStatus, string> = {
  IN_PROGRESS: "In progress",
  REPORTED: "Reported",
  RESOLVED: "Resolved",
};

export const hrFieldsMapper: Record<string, string> = {
  firstName: "First name",
  lastName: "Last name",
  workEmail: "Work email",
  hireDate: "Hire date",
  employeeStatus: "Employee status",
  jobTitle: "Job title",
  department: "Department",
  employmentType: "Employment type",
  location: "Location",
  division: "Division",
  middleName: "Middle name",
  nickname: "Nickname",
  maritalStatus: "Marital status",
  gender: "Gender",
  ethnicity: "Ethnicity",
  contacts: "Contacts",
  street: "Street",
  city: "City",
  zip: "ZIP code",
  country: "Country",
  emergencyContacts: "Emergency contacts",
  birthDay: "Birth date",
  activeDutyWartimeOrCampaignBadgeVeteran:
    "Active duty wartime or campaign badge veteran",
  armedForcesServiceMedalVeteran: "Armed Forces service medal veteran",
  disabledVeteran: "Disabled veteran",
  recentlySeparatedVeteran: "Recently separated veteran",
  education: "Education",
  manager: "Manager",
  employeeId: "ID",
  workMobilePhone: "Work mobile phone",
};

export const hrTimeOffStepTypeTitleMapper: Record<HrTimeOffStepsType, string> = {
  [HrTimeOffStepsType.Creation]: 'Request created',
  [HrTimeOffStepsType.ManagerApproval]: 'Manager approval',
  [HrTimeOffStepsType.HrManagerApproval]: 'HR approval',
  [HrTimeOffStepsType.ManagerAcknowledgement]: 'Manager acknowledgement',
  [HrTimeOffStepsType.HrManagerAcknowledgement]: 'HR acknowledgement',
}

export const hrTimeOffStepStatusTitleMapper: Record<HrTimeOffStepsStatus, string> = {
  [HrTimeOffStepsStatus.Approved]: 'Approved',
  [HrTimeOffStepsStatus.Rejected]: 'Rejected',
  [HrTimeOffStepsStatus.Pending]: 'Pending',
  [HrTimeOffStepsStatus.Created]: 'Created',
  [HrTimeOffStepsStatus.Skipped]: 'Skipped',
  [HrTimeOffStepsStatus.InProgress]: 'In progress',
  [HrTimeOffStepsStatus.Acknowledged]: 'Acknowledged',
}
