import {useDispatch, useSelector} from "react-redux";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useHistory} from "react-router";
import {eraseExams, examsSelector, loadings, replaceExamsMinMaxLoadedPage} from "../../store/slice";
import {
    defaultPageInfo,
    examLevelTitleMapper,
    PATH_TRAININGS_EXAMS,
    PATH_TRAININGS_EXAMS_CREATE,
    PATH_TRAININGS_EXAMS_EXACT,
    TRAININGS_PATH
} from "../../constants";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {MainTrainingExamModel, MainTrainingExamStatus, TrainingExamLevel,} from "../../../../../newShared/GQLTypes";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {getTrainingExamStatusChip} from "../../helpers";
import {mainTrainingsGetExamsWithFilterPaginationAction} from "../../store/actions";
import {mainTrainingsGetExamCategoriesAutocompleteApi} from "../../api";

export const useExamsListPage = () => {
    //root
    const {t, tMenu} = useMainTranslation('', {keyPrefix: 'pathTrainingsManagement.pathExams'});
    const dispatch = useDispatch();
    const history = useHistory();

    //states

    //selectors
    const {exams, pageInfo, minMaxLoadedPage} = useSelector(examsSelector);
    const {examsList: isLoadingList} = useSelector(loadings);
    const {minLoadedPage, maxLoadedPage} = minMaxLoadedPage;


    //useEffects
    useSetBreadcrumbs([
        {
            title: tMenu('Training management'),
            path: TRAININGS_PATH
        },
        {
            title: tMenu('Exams'),
            path: PATH_TRAININGS_EXAMS
        }
    ]);



    //actions
    const handleOpenExam = (selected: MainTrainingExamModel) => {
        history.push(PATH_TRAININGS_EXAMS_EXACT.replace(':examId', selected.examId));
    }

    const handleOpenCreateExam = () => {
        history.push(PATH_TRAININGS_EXAMS_CREATE);
    }


    //filter
    const categoriesConfig: keyConfig<string> = {
        type: 'filter',
        key: 'category',
        name: t('Category'),
        fetchOptions: (workspaceId, {page, count, search}) => {
            return mainTrainingsGetExamCategoriesAutocompleteApi({ workspaceId, pageRequest: {page, count}, search})
                .then(({result, pageInfo}) => ({values: result, pageInfo}))
        },
        getOptionLabel: (a) => a,
        getFilterValue: (a) => a,
        // isOptionEqual: (a, b) => a === b,
        isOptionEqualToSearch: (a, search) => a.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: true,
    }

    const statusConfig: keyConfig<MainTrainingExamStatus> = {
        type: 'filter',
        key: 'status',
        name: t('Status'),
        options: Object.values(MainTrainingExamStatus),
        OptionRenderer: (a) => getTrainingExamStatusChip(a, true),
        default: true,
    }

    const levelConfig: keyConfig<TrainingExamLevel> = {
        type: 'filter',
        key: 'level',
        name: t('Level'),
        options: Object.values(TrainingExamLevel),
        getOptionLabel: (a) => examLevelTitleMapper[a],
        default: true,
    }

    const createdDateConfig: keyConfig<string> = {
        type: 'date',
        key: 'createdDate',
        name: t('Created date'),
        default: true
    }

    const lastUpdateDateConfig: keyConfig<string> = {
        type: 'date',
        key: 'lastUpdate',
        name: t('Last update'),
        default: true
    }

    return{
        exams,
        isLoading: isLoadingList,
        handleOpenExam,
        handleOpenCreateExam,

        genericTable: {
            paging: {
                pageInfo,
                isLoading: isLoadingList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceExamsMinMaxLoadedPage(data)),
                clearRows: () => dispatch(eraseExams()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, examNameLike: string) => {
                    dispatch(mainTrainingsGetExamsWithFilterPaginationAction({data: {pageRequest: {page, count: count || defaultPageInfo.count}, filter: {...filters, examNameLike}}}));
                },
            },
            filters: {
                configs: [
                    categoriesConfig,
                    levelConfig,
                    statusConfig,
                    createdDateConfig,
                    lastUpdateDateConfig,
                ]
            }
        }

    }
}
