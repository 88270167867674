import {Component, MouseEventHandler} from "react";
import {Container, VerDivider} from "./styled";
import {FontControl} from "./components/font-control";
import {StyleControl} from "./components/style-control";
import {PageControl} from "./components/page-control";
import {UndoRedoControl} from "./components/undo-redo-control";
import {FontSizeControl} from "./components/font-size-control";
import {DecorationControl} from "./components/decoration-control";
import {AlignmentControl} from "./components/alignment-control";
import {ListTypeControl} from "./components/list-type-control";
import {LinkControl} from "./components/link-control";
import {TToolBarHandlerAction, TToolBarHandlerPayload, TToolBarSlice} from "./types";
import {initialEditorToolBarState} from "./constants";
import {RemoveFormattingControl} from "./components/removeFormatting-control";

export interface ToolBarProps {
    toolBarHandler: (action: TToolBarHandlerAction, payload: TToolBarHandlerPayload, ids: string[]) => void;
    handleUndo?: MouseEventHandler<HTMLButtonElement>;
    handleRedo?: MouseEventHandler<HTMLButtonElement>;
}
export class ToolBar extends Component<ToolBarProps, TToolBarSlice> {

    constructor(props: ToolBarProps) {
        super(props);

        this.state = {
            ...initialEditorToolBarState,
        }
    }

    toolBarHandlerWithIds = (ids: string[]) => (action: TToolBarHandlerAction, payload: TToolBarHandlerPayload) => this.props.toolBarHandler(action, payload, ids);

    setPartiallyState = (state: Partial<TToolBarSlice>, ids: string[]) => {
        this.setState(prev => ({...prev, ...state, ids}));
    };

    getState = (): TToolBarSlice => {
        return this.state;
    };

    render() {

        const {
            pagesControl,
            undoRedoControl,
            styledControl,
            fontControl,
            fontSizeControl,
            decorationControl,
            alignmentControl,
            listTypeControl,
            linkControl,
            removeFormatting,
            ids,
        } = this.state;


        return (
            <Container>
                {
                    pagesControl.isShow &&
                    <>
                        <PageControl isDisabled={pagesControl.isDisabled} initialPageOpen={pagesControl.value} changePageControlStatusHandler={this.toolBarHandlerWithIds(ids)}/>
                        <VerDivider/>
                    </>
                }
                {
                    undoRedoControl.isShow &&
                    <>
                        <UndoRedoControl undoDisabled={undoRedoControl.undoDisabled}
                                         redoDisabled={undoRedoControl.redoDisabled}
                                         undoClickHandler={this.props.handleUndo}
                                         redoClickHandler={this.props.handleRedo}/>
                        <VerDivider/>
                    </>
                }
                {
                    styledControl.isShow &&
                    <>
                        <StyleControl isDisabled={styledControl.isDisabled} initialStyle={styledControl.value} changeStyleHandler={this.toolBarHandlerWithIds(ids)}/>
                        <VerDivider/>
                    </>
                }
                {
                    fontControl.isShow &&
                    <>
                        <FontControl isDisabled={fontControl.isDisabled} initialFont={fontControl.value} changeFontHandler={this.toolBarHandlerWithIds(ids)}/>
                        <VerDivider/>
                    </>
                }
                {
                    fontSizeControl.isShow &&
                    <>
                        <FontSizeControl isDisabled={fontSizeControl.isDisabled} initialFontSize={fontSizeControl.value} changeFontSizeHandler={this.toolBarHandlerWithIds(ids)}/>
                        <VerDivider/>
                    </>
                }
                {
                    decorationControl.isShow &&
                    <>
                        <DecorationControl
                            isDisabled={decorationControl.isDisabled}
                            isBold={decorationControl.isBold || undefined}
                            isItalic={decorationControl.isItalic || undefined}
                            isUnderline={decorationControl.isUnderline || undefined}
                            color={decorationControl.color}
                            isStrikethrough={decorationControl.isStrikethrough || undefined}
                            isSuperscript={decorationControl.isSuperscript || undefined}
                            isSubscript={decorationControl.isSubscript || undefined}
                            changeDecorationControlHandler={this.toolBarHandlerWithIds(ids)}
                        />
                        <VerDivider/>
                    </>
                }
                {
                    alignmentControl.isShow && <>
                        <AlignmentControl isDisabled={alignmentControl.isDisabled} initialAlignment={alignmentControl.value} changeAlignmentHandler={this.toolBarHandlerWithIds(ids)}/>
                        <VerDivider/>
                    </>
                }
                {
                    listTypeControl.isShow &&
                    <>
                        <ListTypeControl isDisabled={listTypeControl.isDisabled} initialListType={listTypeControl.value} changeListTypeHandler={this.toolBarHandlerWithIds(ids)}/>
                        <VerDivider/>
                    </>
                }
                {
                    linkControl.isShow &&
                    <>
                        <LinkControl isDisabled={linkControl.isDisabled} isLinkSet={linkControl.value} linkSetClickHandler={this.toolBarHandlerWithIds(ids)}/>
                        <VerDivider/>
                    </>
                }

                {
                    removeFormatting.isShow &&
                    <>
                        <RemoveFormattingControl isDisabled={removeFormatting.isDisabled} linkSetClickHandler={this.toolBarHandlerWithIds(ids)}/>
                        <VerDivider/>
                    </>
                }
            </Container>
        )
    }
}
