import {CreateEditTrainingDialog} from "../../components/dialogs/createEditTrainingDialog";
import {useHistory} from "react-router";
import {PATH_TRAININGS_LIST} from "../../constants";

export const TrainingsCreate = () => {
    const history = useHistory();
    const handleGoBack = () => history.push(PATH_TRAININGS_LIST);

    return <CreateEditTrainingDialog
        isOpen={true}
        handleClose={handleGoBack}
    />
}