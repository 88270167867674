import {Dialog, DialogContent as DialogContentBase, styled} from "@mui/material";

export const DialogContent = styled(DialogContentBase)` 
  & .MuiPaper-root {
      width: 100%;
      height: 100%;
      
      
      overflow: hidden;
  }

  padding: 0;
  display: flex;
  flex-direction: column;
  
  //gap: 10px;
`

type EditorDialogProps = {
    isTemplate?: boolean
}
export const EditorDialogStyled = styled(Dialog, {shouldForwardProp: (propName) => (
        !['isTemplate'].includes(propName.toString())
    )})<EditorDialogProps>(({isTemplate}) => ({
    "& .MuiDialog-paper": !isTemplate ? {
        width: '100vw',
        height: '100vh',
    } : {
        width: 'min(95vw, 900px)',
        minWidth: 'min(95vw, 900px)',
        height: 'min(95vh, 800px)',
    },

    '& .MuiPopper-root': {
        zIndex: 200,
    },
}))