import {FC, useEffect} from 'react';
import {LeftSecondMenu} from "../../barsEnvironment/leftMenu/components/leftSecondMenu";
import {Routes} from "./routes";
import {cleanUp} from "./store/slice";
import {useDispatch} from "react-redux";


export const VulnerabilityScanner:FC = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        return () => {
            dispatch(cleanUp());
        }
        //eslint-disable-next-line
    }, []);
    
    return(
        <LeftSecondMenu>
            <Routes/>
        </LeftSecondMenu>
    )
}
