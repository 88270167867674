import {TScanHistory} from "../../types";
import {useDispatch, useSelector} from "react-redux";
import {Flex} from "../../../../../newShared/components/Layouts";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {IconButton, TableCell, TableRow, Typography} from "@mui/material";
import React from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {selectedScanType} from "../../store/slice";
import DownloadIcon from "@mui/icons-material/Download";
import {DownloadReport} from "../../store/actions";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import colors from "../../../../../newShared/theme/colors";
import {parseTargetHistoryStatus} from "../../helpers/index";
import {usePDFView} from "../../../../../newShared/components/docViewer/hooks/usePDFView";

export const TargetHistoryRow = ({history}: {history: TScanHistory}) => {
    const {t} = useMainTranslation('', {keyPrefix: 'vulnerabilityScanner'});
    const dispatch = useDispatch();
    const scanType = useSelector(selectedScanType);
    const searchedActiveScan = history.activeScans.find(e => e.scanType === scanType);
    const neededResult = searchedActiveScan?.scanResult?.results.find(e => e.contentType === 'application/pdf');
    const {handleOpen} = usePDFView();


    const {color, text} = parseTargetHistoryStatus(searchedActiveScan?.status ?? null);

    return(
        <TableRow>
            <TableCell>
                <Flex ai={'center'} gap={'4px'}>
                    <IconButton size={'small'}
                                color={'primary'}
                                // sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                disabled={!neededResult}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    neededResult && searchedActiveScan && handleOpen({fileId: neededResult.originReportFileId as string || '', fileName: `${scanType}_${searchedActiveScan.finishScanDate ?? ''}.pdf`})
                                }}>
                        <VisibilityIcon sx={{color: !neededResult ? colors.skip : colors.primary.blue}}/>
                    </IconButton>

                    <IconButton size={'small'}
                                disabled={!neededResult}
                                // sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    neededResult && dispatch(DownloadReport({
                                        fileId: neededResult.originReportFileId as string || '',
                                        name: `${scanType}_${searchedActiveScan?.finishScanDate ?? ''}.pdf`,
                                        scanId: neededResult.originReportFileId
                                    }));
                                }}
                    >
                        <DownloadIcon sx={{color: !neededResult ? colors.skip : colors.primary.blue}}/>
                    </IconButton>
                </Flex>
            </TableCell>

            <TableCell>
                {parseDateAuto(searchedActiveScan?.startScanDate, true)}
            </TableCell>

            <TableCell>
                {parseDateAuto(searchedActiveScan?.finishScanDate, true)}
            </TableCell>

            <TableCell>
                <Typography variant={'*bodyText2'} color={color}>{t(text)}</Typography>
            </TableCell>
        </TableRow>
    )

}
