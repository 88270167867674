import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {Flex} from "../../../../../../../newShared/components/Layouts";
import colors from "../../../../../../../newShared/theme/colors";
import React from "react";

export type FileTooLargeDialogPropsType = {
    isOpen: boolean;
    handleClose: () => void,
    handleUploadImage: () => void,
}
export const FileTooLargeDialog = ({isOpen, handleClose, handleUploadImage}: FileTooLargeDialogPropsType) => {
    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>
                {'Select image'}
            </DialogTitle>

            <DialogContent>
                <Flex
                    w={'100%'}
                    jc={'center'}
                    ai={'center'}
                    h={'131px'}
                    br={'6px'}
                    direction={'column'}
                    background={colors.primary.disable2}
                >
                    <Typography variant={'*bodyText2'} color={colors.grayText}>{('Your image is too large.')}</Typography>
                    <Typography variant={'*bodyText2'} color={colors.grayText}>{('Make sure attached file does not exceed 5 MB.')}</Typography>
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button variant={'text'} size={'small'} onClick={handleClose}>{('Cancel')}</Button>
                <Button size={'small'} onClick={handleUploadImage}>{('Select another image')}</Button>
            </DialogActions>
        </Dialog>
    )
}