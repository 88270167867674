import React, {FC} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import * as S from "./styled";
// import {getStatusColor} from "../../helpers";
import {
    DocNameFlexBox,
    DocumentsHeaderTableTypography,
    DocumentsTableBody,
    DocumentsTableContainer,
    DocumentsTableHead,
    DocumentsTableRow,
    StatusRoundBox,
    StatusTableCellContainer,
    StatusTypography,
    StickyHeaderTable,
    TextSmallButton
} from "../../pages/styled";
import {useTrashPage} from "../../hooks/useTrashPage";
import {TableCell, TableRow, Typography} from "@mui/material";

import {ReactComponent as DocumentIcon} from "../../../../../newShared/images/documents/document-icon.svg";
import {TableRowSkeleton} from "../../../../../newShared/components/tableRowSkeleton";
import {getStatusColor} from "../../helpers";
import {TDocumentStatus} from "../../types";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";


export const TrashTable: FC = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathDocuments.TrashPage'});

    const {
        isLoadingFiles,
        files,
        handleOpenRestoreDialog,
        selectedFolder,
    } = useTrashPage();



    return (
        <DocumentsTableContainer >
            <StickyHeaderTable>
                <DocumentsTableHead>
                    <DocumentsTableRow>
                        <S.TrashNameTableCell>
                            <DocumentsHeaderTableTypography>{t('Name')}</DocumentsHeaderTableTypography>
                        </S.TrashNameTableCell>
                        <S.TrashedDateTableCell>
                            <DocumentsHeaderTableTypography>{t('Trashed date')}</DocumentsHeaderTableTypography>
                        </S.TrashedDateTableCell>
                        <S.TrashStatusTableCell>
                            <DocumentsHeaderTableTypography>{t('Status')}</DocumentsHeaderTableTypography>
                        </S.TrashStatusTableCell>
                        <S.TrashActionTableCell/>
                    </DocumentsTableRow>
                </DocumentsTableHead>

                <DocumentsTableBody>
                    {files.map(e => e && (
                        <TableRow hover key={e.id}>
                            <S.TrashNameTableCell>
                                <DocNameFlexBox>
                                    <DocumentIcon />
                                    <Typography variant={'*bodyText2'} noWrap>{e?.name ?? ''}</Typography>
                                </DocNameFlexBox>
                            </S.TrashNameTableCell>

                            <S.TrashedDateTableCell>
                                <Typography variant={'*bodyText2'}>{parseDateAuto(e?.deletedDate)}</Typography>
                                {/*<TypographyBodySmall>{e?.deletedDate?.split("T")[0] ?? "--/--/----"}</TypographyBodySmall>*/}
                            </S.TrashedDateTableCell>

                            <S.TrashStatusTableCell>
                                <StatusTableCellContainer>
                                    <StatusRoundBox color={getStatusColor(e?.status as TDocumentStatus)} revDir={revDir ? true : undefined}/>
                                    <StatusTypography color={getStatusColor(e?.status as TDocumentStatus)}>{e?.status?.toUpperCase()}</StatusTypography>
                                </StatusTableCellContainer>
                            </S.TrashStatusTableCell>
                            <S.TrashActionTableCell>
                                <TextSmallButton onClick={() => selectedFolder && e && handleOpenRestoreDialog({folder: selectedFolder, file: e})} >{t('Restore')}</TextSmallButton>
                            </S.TrashActionTableCell>
                        </TableRow>
                    ))}
                    {isLoadingFiles && <TableRowSkeleton columns={4} /> }
                    {!isLoadingFiles && !files.length &&
                        <TableRow><TableCell colSpan={4}>{t('No Document in this folder.')}</TableCell></TableRow>
                    }
                </DocumentsTableBody>
            </StickyHeaderTable>
        </DocumentsTableContainer>
    )

}
