import {useSelector} from "react-redux";
import {
    isSettingsLoadingSelector,
    openCreateSettingRowDialog,
    openDeleteSettingRowDialog,
    openEditSettingRowDialog,
    vendorsSettingsSelector
} from "../../store/slice";
import {getVendorsSettingsAction} from "../../store/actions";
import {useEffect, useState} from "react";
import {useAppDispatch} from "../../../../../newShared/redux";
import {PATH_LOCAL_VENDORS_SETTINGS, PATH_LOCAL_VENDORS_SETTINGS_EXACT} from "../../constants";
import {useHistory, useParams} from "react-router-dom";
import {normalizeSettingIdName} from "../../helpers";
import {SettingsTypes} from "../../types";
import {NameCountModel} from "../../../../../newShared/GQLTypes";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";


export const useExactSetting = () => {
    const dispatch = useAppDispatch();
    const settings = useSelector(vendorsSettingsSelector);
    const isSettingsLoading = useSelector(isSettingsLoadingSelector);
    const history = useHistory();
    const id = useParams<{id: SettingsTypes}>().id;
    const normalizedName = normalizeSettingIdName(id as SettingsTypes);

    //Search
    const [settingOptions, setSettingOptions] = useState<NameCountModel[]>(settings[id])

    const handleGoBack = () => {
        history.push(PATH_LOCAL_VENDORS_SETTINGS);
    }

    const handleCreateNewItem = (event: any, customId?: SettingsTypes | null) => {
        dispatch(openCreateSettingRowDialog({
            type: customId || id,
        }))
    }

    const handleChange = (value: NameCountModel) => {
        dispatch(openEditSettingRowDialog({
            type: id,
            itemToEdit: value
        }))}

    const handleDelete = (value: NameCountModel) => {
        dispatch(openDeleteSettingRowDialog({
            type: id,
            itemToDelete: value
        }))}

    const getSettingsVariables = () => {
        !isSettingsLoading && dispatch(getVendorsSettingsAction({organizationId: '', workspaceId: ''}))};

    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const {t} = useMainTranslation('', {keyPrefix: 'pathVendors.VendorsSettings'});
    useSetBreadcrumbs([
        {
            title:tMenu('Settings'),
            path: PATH_LOCAL_VENDORS_SETTINGS
        },
        {
            title: isSettingsLoading? 'Loading...' : t(normalizedName),
            path: PATH_LOCAL_VENDORS_SETTINGS_EXACT.replace(':id', id)
        },

    ]);

    useEffect(()=>{
        !settings[id].length && !isSettingsLoading && getSettingsVariables();
        //eslint-disable-next-line
    },[])

    useEffect(()=>{
        setSettingOptions(settings[id])
        //eslint-disable-next-line
    },[settings])

    const handleFilter = (search: string) => {
        if(search.trim().length === 0){
            setSettingOptions(settings[id])
        }else{
            setSettingOptions (settings[id].filter((el)=>{return el.name.toLowerCase().includes(search.toLowerCase())}));
        }
    }

    return{
        settings,
        id: id as SettingsTypes,
        isSettingsLoading,
        normalizedName,
        handleGoBack,
        handleCreateNewItem,
        handleChange,
        handleDelete,
        settingOptions,
        // search,
        // setSearch,
        // handleCleanSearch,
        getSettingsVariables,
        // handleEnterKey,
        handleFilter
    }
}