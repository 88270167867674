import React from "react";
import {Flex} from "../../../../../newShared/components/Layouts";
import {Divider, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {IconTextButton} from "../../../../../newShared/components/Basic/CommonButtons";
import EditIcon from "@mui/icons-material/Edit";

export const CategoryTitle = ({name, icon, margin = '34px 0 0 0', button, disableDivider, dividerMargin='12px 0 0 0'}: {name: string, icon: React.ReactNode, margin?: string, button?: {text: string, onClick: () => void, icon?: React.ReactNode} | undefined, disableDivider?: boolean, dividerMargin?: string}) => {
    return(
        <>
            <Flex ai={'center'} gap={'20px'} m={margin}>
                {icon}
                <Typography variant={'subtitle2'} color={colors.grayText}>{name}</Typography>
                {button &&
                    <IconTextButton variant={'text'} icon={button.icon ? button.icon : <EditIcon />} onClick={button.onClick} size={'small'}>
                        {button.text}
                    </IconTextButton>
                }
            </Flex>
            {!disableDivider && <Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable, margin: dividerMargin}}/>}
        </>
    )
}