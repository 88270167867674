import {useAppDispatch} from "../../../../../newShared/redux";
import {
    currentFrameworkDialogsSelector,
    setRemoveFrameworkDialogAction,
    TCurrentFrameworkSlice
} from "../../store/currentFramework/store";
import {TSetDialog} from "../../../../../newShared/types";
import {useSelector} from "react-redux";
import {
    frameworkControlDialogsSelector,
    setCollaboratorsDialogAction,
    setCollectorViewDialogAction,
    setDeleteAssetDialogAction,
    setNewCommentDialogAction,
    setStatementDialogAction,
    TFrameworkControlSlice
} from "../../store/control/store";
import {
    TSetCollectorViewDialog,
    TSetDeleteAssetDialog,
    TSetRemoveFrameworkDialog,
    TSetStatementDialog
} from "../../types";

type TResponse = {
    removeFramework: TCurrentFrameworkSlice["dialogs"]["removeFramework"] & {setRemoveFrameworkDialog: (data: TSetRemoveFrameworkDialog) => void},
    deleteAsset: TFrameworkControlSlice["dialogs"]["deleteAsset"] & {setDeleteAssetDialog: (data: TSetDeleteAssetDialog) => void},
    collectorView: TFrameworkControlSlice["dialogs"]["collectorView"] & {setCollectorViewDialog: (data: TSetCollectorViewDialog) => void},
    statement: TFrameworkControlSlice["dialogs"]["statement"] & {setStatementDialog: (data: TSetStatementDialog) => void},
    newComment: TFrameworkControlSlice["dialogs"]["newComment"] & {setNewCommentDialog: (data: TSetDialog) => void},
    collaborators: TFrameworkControlSlice["dialogs"]["collaborators"] & {setCollaboratorsDialog: (data: TSetDialog) => void},
}
export const useFrameworksDialog = (): TResponse => {
    const dispatch = useAppDispatch();
    // const history = useHistory();

    const {
        removeFramework
    } = useSelector(currentFrameworkDialogsSelector);
    const {
        collaborators, statement, newComment, collectorView, deleteAsset,
    } = useSelector(frameworkControlDialogsSelector);

    const setRemoveFrameworkDialog = (data: TSetRemoveFrameworkDialog) => {
        dispatch(setRemoveFrameworkDialogAction(data))
    }
    const setDeleteAssetDialog = (data: TSetDeleteAssetDialog) => {
        dispatch(setDeleteAssetDialogAction(data))
    }
    const setCollectorViewDialog = (data: TSetCollectorViewDialog) => {
        dispatch(setCollectorViewDialogAction(data))
    }
    const setStatementDialog = (data: TSetStatementDialog) => {
        dispatch(setStatementDialogAction(data))
    }
    const setNewCommentDialog = (data: TSetDialog) => {
        dispatch(setNewCommentDialogAction(data))
    }
    const setCollaboratorsDialog = (data: TSetDialog) => {
        dispatch(setCollaboratorsDialogAction(data))
    }


    return {
        removeFramework: {
            ...removeFramework,
            setRemoveFrameworkDialog
        },
        collectorView: {
            ...collectorView,
            setCollectorViewDialog,
        },
        deleteAsset: {
            ...deleteAsset,
            setDeleteAssetDialog,
        },
        statement: {
            ...statement,
            setStatementDialog
        },
        collaborators: {
            ...collaborators,
            setCollaboratorsDialog
        },
        newComment: {
            ...newComment,
            setNewCommentDialog
        },
    }
}