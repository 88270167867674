import {styled, Typography} from "@mui/material";
import colors from "../../../theme/colors";


export const TypographyH1 = styled(Typography)`
  font-size: 32px;
  line-height: 44px;
  letter-spacing: 0.25px;
  font-weight: 700;
`;

export const TypographyH2 = styled(Typography)`
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
`;

export const TypographyH3 = styled(Typography)`
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
  font-weight: 400;
`;

export const TypographyH4 = styled(Typography)`
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
`;

export const TypographySubtitle1 = styled(Typography)`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
`;

export const TypographySubtitle2 = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;
export const TypographyBody1 = styled(Typography)`
  font-size: 16px;
  line-height: 20.8px;
  font-weight: 400;
  letter-spacing: 0.15px;
`;

export const TypographyBody2 = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.15px;
`;

export const TypographyBodySmall = styled(Typography)`
  font-size: 12px;
  line-height: 15.6px;
  font-weight: 400;
  letter-spacing: 0.15px;
`;

export const TypographyBodySmallSemiBold = styled(TypographyBodySmall)`
  font-weight: 600;
`;

export const OverflowHiddenTextBodySmall = styled(TypographyBodySmall)(() => ({
    color: colors.text.grey,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));

export const OverflowHiddenTextBody2 = styled(TypographyBody2)(() => ({
    color: colors.text.dark,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));

