import {CaseReducer} from "@reduxjs/toolkit/src/createReducer";
import {PayloadAction} from "@reduxjs/toolkit/src/createAction";
import {Draft} from "@reduxjs/toolkit";

type TAddSetDialogAction<TState extends {dialogs: Record<string, Record<string, any>>}, TFieldName extends Exclude<keyof TState["dialogs"], number | symbol>> = CaseReducer<TState, PayloadAction<Partial<TState["dialogs"][TFieldName]> & {clear?: boolean}>>;
type TActionName<Key extends string> = `set${Capitalize<Key>}Action`;

export const addSetDialogAction =
    <TState extends {dialogs: Record<string, Record<string, any>>}, TFieldName extends Exclude<keyof TState["dialogs"], number | symbol>>(
        dialogField: TFieldName, initialState: TState,
    ) => {
        const actionName = `set${dialogField.charAt(0).toUpperCase() + dialogField.slice(1)}Action` as TActionName<TFieldName>;

        const action: TAddSetDialogAction<TState, TFieldName> = ((slice, {payload}) => {
            Object.keys(payload).forEach(key => {
                if (payload[key] !== undefined) slice.dialogs[dialogField][key] = payload[key];
            })

            if (payload.clear) slice.dialogs[dialogField] = (initialState as Draft<TState>).dialogs[dialogField];
        });

        return {[actionName]: action} as { [key in TActionName<TFieldName>]: TAddSetDialogAction<TState, TFieldName> }
    };


export const addSetAllDialogActions =
    <TState extends {dialogs: Record<string, Record<string, any>>}>(initialState: TState) => {

        return Object.keys(initialState.dialogs).reduce((prev, dialogField) => {
            return {...prev, ...addSetDialogAction(dialogField as Exclude<keyof TState["dialogs"], number | symbol>, initialState)}
        }, {}) as {
            [K in Exclude<keyof TState["dialogs"], number | symbol> as TActionName<K>]: TAddSetDialogAction<TState, K>
        }
    }
