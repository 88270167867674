import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../../../newShared/components/Layouts";
import AddIcon from "@mui/icons-material/Add";
import colors from "../../../../../newShared/theme/colors";
import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {EditSettingDialog} from "../../components/Dialogs/EditSettingDialog";
import {DeleteSettingDialog} from "../../components/Dialogs/DeleteSetting";
import {CreateSettingRowDialog} from "../../components/Dialogs/CreateSettingRowDialog";
import {TableRowSkeleton} from "../../../../../newShared/components/tableRowSkeleton";
import {useExactSoftwareSettings} from "../../hooks/useExactSoftwareSettings";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";


export const ExactSoftwareSettings = () => {
    const {isMobile, isTablet, isLessThen1050} = useMedia();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets'});

    const {
        settingsView,
        id,
        isLoadingSettings,
        settingTitle,
        handleCreateNewItem,
        handleGoBack,

        setEditSettingRowDialog,
        setDeleteSettingRowDialog
    } = useExactSoftwareSettings();

    return (
        <Flex w={'100%'} h={'100%'} direction={'column'} overflowy={(isMobile || isTablet) ? "scroll" : undefined}>
            <PageTitleAndActions
                title={t(settingTitle)}
                handleGoBack={handleGoBack}
                showMenuWhen={isMobile}
                actions={[
                    {
                        title: t('Add new item'),
                        onClick: () => handleCreateNewItem(id),
                        variant: 'text',
                        icon: <AddIcon style={{marginLeft: revDir ? '8px' : undefined}}/>,
                        disabled: isLoadingSettings
                    },
                ]}
            />

            {/*<Breadcrumbs/>*/}
            {/*<Flex ai={'center'} m={'10px 0 0 0'}>*/}
            {/*    {isLoadingSettings ? <Skeleton width={'170px'} height={'40px'} /> :*/}
            {/*        <>*/}
            {/*            {!isMobile &&*/}
            {/*                <IconButton sx={{color: colors.text.blue, padding: '0', margin: !revDir ? `${!isLessThen1050 ? 11 : 4}px 12px ${!isLessThen1050 ? 4 : 2}px 0` : `${!isLessThen1050 ? 11 : 4}px 0 ${!isLessThen1050 ? 4 : 2}px 12px`}} onClick={handleGoBack} size={isLessThen1050 ? 'small' : undefined}>*/}
            {/*                    {!revDir ? <ArrowBack/> : <ArrowForward/>}*/}
            {/*                </IconButton>*/}
            {/*            }*/}
            {/*            <Typography variant={isMobile ? 'h3' : "h1"} sx={{*/}
            {/*                margin: !revDir ? '0 22px 0 11px' : '0 11px 0 22px',*/}
            {/*                color: colors.backgrounds.dark*/}
            {/*            }}>{t(settingTitle)}</Typography>*/}
            {/*        </>*/}
            {/*    }*/}

            {/*    <Button startIcon={<AddIcon style={{marginLeft: revDir ? '8px' : undefined}}*/}
            {/*            fontSize={'small'}/>} variant="text" size={'large'}*/}
            {/*            disabled={isLoadingSettings}*/}
            {/*            onClick={() => handleCreateNewItem(id)} >*/}
            {/*        {t('Add new item')}*/}
            {/*    </Button>*/}
            {/*</Flex>*/}

            {/*<SearchField*/}
            {/*    size={'small'}*/}
            {/*    sx={{margin: revDir ? '30px 0 0 10px' : '30px 10px 0 0', width: '320px'}}*/}
            {/*    variant={'outlined'}*/}
            {/*    placeholder={t('Search')}*/}
            {/*    onClear={handleCleanSearch}*/}
            {/*    onChange={(e) => {setSearch(e.target.value)}}*/}
            {/*    value={search}*/}
            {/*    disabled={isLoadingSettings}*/}
            {/*    onKeyDown={handleEnterKey}*/}
            {/*/>*/}

            {!isLessThen1050 ?
                <TableContainer sx={{marginTop: '10px'}}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell width={isMobile ? 150 : isTablet ? 320 : 520} align={revDir ? 'right' : 'left'}>{t('Title')}</TableCell>
                                {/*<TableCell width={isMobile ? 60 : 200} align={revDir ? 'right' : 'left'}/>*/}
                                <TableCell width={40} align={revDir ? 'right' : 'left'}/>
                                <TableCell align={revDir ? 'right' : 'left'} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { settingsView?.[id] && Object.keys(settingsView[id]).map((key) => key && (
                                <TableRow key={key} >
                                    <TableCell align={revDir ? 'right' : 'left'}>{key}</TableCell>
                                    {/*<TableCell align={revDir ? 'right' : 'left'}>{settingsView[id][key]}</TableCell>*/}
                                    <TableCell onClick={() => setEditSettingRowDialog({isOpen: true, field: id, oldTitle: key, assetType: 'software'})} align={revDir ? 'right' : 'left'}>
                                        <IconButton
                                            sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}
                                            size={isLessThen1050 ? 'small' : 'large'}>
                                            <EditIcon/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align={revDir ? 'right' : 'left'}>
                                        <IconButton
                                            sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}
                                            onClick={() => setDeleteSettingRowDialog({isOpen: true, field: id, oldTitle: key, assetType: 'software'})}
                                            size={isLessThen1050 ? 'small' : 'large'}>
                                            <DeleteForeverIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>

                            ))}

                            {isLoadingSettings && <TableRowSkeleton columns={1}/>}
                        </TableBody>
                    </Table>
                </TableContainer>

                :
                <Table>
                    <TableHead>
                        <TableRow >
                            <TableCell width={isMobile ? 150 : isTablet ? 320 : 520} align={revDir ? 'right' : 'left'}>{t('Title')}</TableCell>
                            {/*<TableCell width={isMobile ? 60 : 200} align={revDir ? 'right' : 'left'}/>*/}
                            <TableCell width={40} align={revDir ? 'right' : 'left'}/>
                            <TableCell align={revDir ? 'right' : 'left'} />
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        { settingsView?.[id] && Object.keys(settingsView[id]).map((key) =>  key && (
                            <TableRow key={key} >
                                <TableCell align={revDir ? 'right' : 'left'} >{key}</TableCell>
                                {/*<TableCell align={revDir ? 'right' : 'left'}>{settingsView[id][key]}</TableCell>*/}
                                <TableCell onClick={() => setEditSettingRowDialog({isOpen: true, field: id, oldTitle: key, assetType: 'software'})} align={revDir ? 'right' : 'left'}>
                                    <IconButton
                                        sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}
                                        size={isLessThen1050 ? 'small' : 'large'}>
                                        <EditIcon/>
                                    </IconButton>
                                </TableCell>
                                <TableCell align={revDir ? 'right' : 'left'}>
                                    <IconButton
                                        sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}
                                        onClick={() => setDeleteSettingRowDialog({isOpen: true, field: id, oldTitle: key, assetType: 'software'})}
                                        size={isLessThen1050 ? 'small' : 'large'}>
                                        <DeleteForeverIcon/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>

                        ))}

                    </TableBody>
                </Table>
            }

            <EditSettingDialog />
            <CreateSettingRowDialog />
            <DeleteSettingDialog />

        </Flex>
    )
}