import {isLoading, isLoadingControls} from "../../store/builder/slice";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {ROOT_BUILDER, ROOT_CONTROLS} from "../../constants";
import {useBuilderFrameworks} from "../new/useBuilderFrameworks";
import {useDialogs} from "../new/useDialogs";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";

export const useControlsList = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const {clearFilters, currentSearchForFetch} = useGenericFiltersStorage();

    const {
        _controls, getPrivateControls,
    } = useBuilderFrameworks();

    const {
        handleOpenAddControl,
        openEditControlDialog,
        handleOpenDeleteControl,
    } = useDialogs();

    useSetBreadcrumbs([
        {
            title: tMenu('Framework builder'),
            path: ROOT_BUILDER
        },
        {
            title: tMenu('Created controls'),
            path: ROOT_CONTROLS
        },

    ]);

    useEffect(() => {
        getPrivateControls()

        return () => {
            clearFilters();
        }
        //eslint-disable-next-line
    }, [])

    return {
        controls: _controls,

        isLoading: useSelector(isLoadingControls),
        isLoadingDialogs: useSelector(isLoading),

        handleOpenAddControl,
        openEditControlDialog,
        handleOpenDeleteControl,
        currentSearchForFetch
    }
}