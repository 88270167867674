import {print} from "graphql";
import {gql} from "@apollo/react-hooks";
import {
    AssetFragment,
    CertificateAssetFragment,
    CertificateAssetSettingsFragment,
    mainAssetsRequestAssetFragment,
    mainAssetsRequestFragment,
    PhysicalAssetSettingsFragment,
    SaasAssetFragment,
    SaasAssetSettingsFragment,
    SoftwareAssetFragment,
    SoftwareAssetSettingsFragment,
    VirtualAssetFragment,
    VirtualAssetSettingsFragment
} from "./fragments";
import {importCsvFragmentFields, performCsvFragment} from "../../../../newShared/graphQl/fragments";

export const getPhysicalAssets = print(gql`
    query getPhysicalAssets ($workspaceId: String!, $organizationId: String!, $pageInfo:PaginationInput!){
        getPhysicalAssets(pageInfo: $pageInfo, workspaceId: $workspaceId, organizationId: $organizationId){
            data {...AssetFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${AssetFragment}
`)

export const getPhysicalAssetById = print(gql`
    query getPhysicalAssetById($organizationId: String!, $workspaceId: String!, $id: String!){
        getPhysicalAssetById(id: $id, organizationId: $organizationId, workspaceId: $workspaceId) {
            ...AssetFragment
        }
    }
    ${AssetFragment}
`)

export const createPhysicalAsset = print(gql`
    mutation createPhysicalAsset ($workspaceId: String!, $organizationId: String!, $asset: PhysicalAssetInput!){
        createPhysicalAsset(asset: $asset, workspaceId: $workspaceId, organizationId: $organizationId){
            ...AssetFragment
        }
    }
    ${AssetFragment}
`)

export const updatePhysicalAsset = print(gql`
    mutation updatePhysicalAsset ($workspaceId: String!, $organizationId: String!, $assetId: String!, $asset: PhysicalAssetInput!){
        updatePhysicalAsset(asset: $asset, assetId: $assetId, workspaceId: $workspaceId, organizationId: $organizationId){
            ...AssetFragment
        }
    }
    ${AssetFragment}
`)

export const deletePhysicalAsset = print(gql`
    mutation deletePhysicalAsset ($workspaceId: String!, $organizationId: String!, $assetId: String!){
        deletePhysicalAsset(assetId: $assetId, workspaceId: $workspaceId, organizationId: $organizationId){
            message
        }
    }
`)

export const changeAssetStatus = print(gql`
    mutation changeAssetStatus($organizationId: String!, $workspaceId: String!, $assetId: String!, $status: PhysicalAssetStatusInputOld!, $assignedTo: String){
        changeAssetStatus(status: $status, assetId: $assetId, organizationId: $organizationId, workspaceId: $workspaceId, assignedTo: $assignedTo) {
            ...AssetFragment
        }
    }
    ${AssetFragment}
`)

export const exportCsvPhysicalAssets = print(gql`
    query exportCsvPhysicalAssets($organizationId: String!, $workspaceId: String!, $fieldKeys:[String!]!, $isDownload: Boolean!){
        exportCsvPhysicalAssets(workspaceId: $workspaceId, organizationId: $organizationId, fieldKeys: $fieldKeys, isDownload: $isDownload)
    }
`)

export const importCsvTemplatePhysicalAssets = print(gql`
    query importCsvTemplatePhysicalAssets($organizationId: String!, $workspaceId: String!){
        importCsvTemplatePhysicalAssets(workspaceId: $workspaceId, organizationId: $organizationId)
    }
`)

//Settings
export const getPhysicalAssetSettings = print(gql`
    query getPhysicalAssetSettings($organizationId: String!, $workspaceId: String!){
        getPhysicalAssetSettings(organizationId: $organizationId, workspaceId: $workspaceId,) {
            ...PhysicalAssetSettingsFragment
        }
    }
    ${PhysicalAssetSettingsFragment}
`)

export const createPhysicalAssetSettingRow = print(gql`
    mutation createPhysicalAssetSettingRow($organizationId: String!, $workspaceId: String!, $field: String!, $title: String!, $status: PhysicalAssetStatusInputOld){
        createPhysicalAssetSettingRow(organizationId: $organizationId, workspaceId: $workspaceId, field: $field, title: $title, status: $status) {
            ...PhysicalAssetSettingsFragment
        }
    }
    ${PhysicalAssetSettingsFragment}
`)

export const editPhysicalAssetSettingRow = print(gql`
    mutation editPhysicalAssetSettingRow($organizationId: String!, $workspaceId: String!, $field: String!, $title: String!, $oldTitle: String!, $status: PhysicalAssetStatusCountInput, $withHistoryChange: Boolean){
        editPhysicalAssetSettingRow(organizationId: $organizationId, workspaceId: $workspaceId, field: $field, title: $title, oldTitle: $oldTitle, status: $status, withHistoryChange: $withHistoryChange) {
            ...PhysicalAssetSettingsFragment
        }
    }
    ${PhysicalAssetSettingsFragment}
`)

export const deletePhysicalAssetSettingRow = print(gql`
    mutation deletePhysicalAssetSettingRow($organizationId: String!, $workspaceId: String!, $field: String!, $title: String!, $oldTitle: String!, $withHistoryChange: Boolean, $status: String){
        deletePhysicalAssetSettingRow(organizationId: $organizationId, workspaceId: $workspaceId, field: $field, title: $title, oldTitle: $oldTitle, withHistoryChange: $withHistoryChange, status: $status) {
            ...PhysicalAssetSettingsFragment
        }
    }
    ${PhysicalAssetSettingsFragment}
`)

export const releasePhysicalAssets = print(gql`
    mutation releasePhysicalAssets ($workspaceId: String!, $organizationId: String!, $frameworkId: String!, $controlId: String!, $evidenceId: String!, $reviewers: [ReleaseActorInput!]!, $readers: [ReleaseActorInput!]!, $signers: [ReleaseActorInput!]!){
        releasePhysicalAssets(workspaceId: $workspaceId, organizationId: $organizationId, frameworkId: $frameworkId, controlId: $controlId, evidenceId: $evidenceId, reviewers: $reviewers, readers: $readers, signers: $signers){
            message
        }
    }
`)

//VIRTUAL ASSETS
export const getVirtualAssets = print(gql`
    query getVirtualAssets ($workspaceId: String!, $organizationId: String!, $pageInfo:PaginationInput!){
        getVirtualAssets(pageInfo: $pageInfo, workspaceId: $workspaceId, organizationId: $organizationId){
            data {...VirtualAssetFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${VirtualAssetFragment}
`)

export const getVirtualAssetById = print(gql`
    query getVirtualAssetById($organizationId: String!, $workspaceId: String!, $id: String!){
        getVirtualAssetById(id: $id, organizationId: $organizationId, workspaceId: $workspaceId) {
            ...VirtualAssetFragment
        }
    }
    ${VirtualAssetFragment}
`)

export const createVirtualAsset = print(gql`
    mutation createVirtualAsset ($workspaceId: String!, $organizationId: String!, $asset: VirtualAssetInput!){
        createVirtualAsset(asset: $asset, workspaceId: $workspaceId, organizationId: $organizationId){
            ...VirtualAssetFragment
        }
    }
    ${VirtualAssetFragment}
`)

export const updateVirtualAsset = print(gql`
    mutation updateVirtualAsset ($workspaceId: String!, $organizationId: String!, $assetId: String!, $asset: VirtualAssetInput!){
        updateVirtualAsset(asset: $asset, assetId: $assetId, workspaceId: $workspaceId, organizationId: $organizationId){
            ...VirtualAssetFragment
        }
    }
    ${VirtualAssetFragment}
`)

export const deleteVirtualAsset = print(gql`
    mutation deleteVirtualAsset ($workspaceId: String!, $organizationId: String!, $assetId: String!){
        deleteVirtualAsset(assetId: $assetId, workspaceId: $workspaceId, organizationId: $organizationId){
            message
        }
    }
`)


export const exportCsvVirtualAssets = print(gql`
    query exportCsvVirtualAssets($organizationId: String!, $workspaceId: String!, $fieldKeys:[String!]!, $isDownload: Boolean!){
        exportCsvVirtualAssets(workspaceId: $workspaceId, organizationId: $organizationId, fieldKeys: $fieldKeys, isDownload: $isDownload)
    }
`)

export const importCsvTemplateVirtualAssets = print(gql`
    query importCsvTemplateVirtualAssets($organizationId: String!, $workspaceId: String!){
        importCsvTemplateVirtualAssets(workspaceId: $workspaceId, organizationId: $organizationId)
    }
`)

//Settings
export const getVirtualAssetSettings = print(gql`
    query getVirtualAssetSettings($organizationId: String!, $workspaceId: String!){
        getVirtualAssetSettings(organizationId: $organizationId, workspaceId: $workspaceId,) {
            ...VirtualAssetSettingsFragment
        }
    }
    ${VirtualAssetSettingsFragment}
`)

export const createVirtualAssetSettingRow = print(gql`
    mutation createVirtualAssetSettingRow($organizationId: String!, $workspaceId: String!, $field: String!, $title: String!){
        createVirtualAssetSettingRow(organizationId: $organizationId, workspaceId: $workspaceId, field: $field, title: $title) {
            ...VirtualAssetSettingsFragment
        }
    }
    ${VirtualAssetSettingsFragment}
`)

export const editVirtualAssetSettingRow = print(gql`
    mutation editVirtualAssetSettingRow($organizationId: String!, $workspaceId: String!, $field: String!, $title: String!, $oldTitle: String!, $withHistoryChange: Boolean){
        editVirtualAssetSettingRow(organizationId: $organizationId, workspaceId: $workspaceId, field: $field, title: $title, oldTitle: $oldTitle, withHistoryChange: $withHistoryChange) {
            ...VirtualAssetSettingsFragment
        }
    }
    ${VirtualAssetSettingsFragment}
`)

export const deleteVirtualAssetSettingRow = print(gql`
    mutation deleteVirtualAssetSettingRow($organizationId: String!, $workspaceId: String!, $field: String!, $title: String!, $oldTitle: String!, $withHistoryChange: Boolean){
        deleteVirtualAssetSettingRow(organizationId: $organizationId, workspaceId: $workspaceId, field: $field, title: $title, oldTitle: $oldTitle, withHistoryChange: $withHistoryChange) {
            ...VirtualAssetSettingsFragment
        }
    }
    ${VirtualAssetSettingsFragment}
`)

export const releaseVirtualAssets = print(gql`
    mutation releaseVirtualAssets ($workspaceId: String!, $organizationId: String!, $frameworkId: String!, $controlId: String!, $evidenceId: String!, $reviewers: [ReleaseActorInput!]!, $readers: [ReleaseActorInput!]!, $signers: [ReleaseActorInput!]!){
        releaseVirtualAssets(workspaceId: $workspaceId, organizationId: $organizationId, frameworkId: $frameworkId, controlId: $controlId, evidenceId: $evidenceId, reviewers: $reviewers, readers: $readers, signers: $signers){
            message
        }
    }
`)

//Software
export const getSoftwareAssets = print(gql`
    query getSoftwareAssets ($workspaceId: String!, $organizationId: String!, $pageInfo:PaginationInput!){
        getSoftwareAssets(pageInfo: $pageInfo, workspaceId: $workspaceId, organizationId: $organizationId){
            data {...SoftwareAssetFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${SoftwareAssetFragment}
`)

export const getSoftwareAssetById = print(gql`
    query getSoftwareAssetById($organizationId: String!, $workspaceId: String!, $id: String!){
        getSoftwareAssetById(id: $id, organizationId: $organizationId, workspaceId: $workspaceId) {
            ...SoftwareAssetFragment
        }
    }
    ${SoftwareAssetFragment}
`)

export const createSoftwareAsset = print(gql`
    mutation createSoftwareAsset ($workspaceId: String!, $organizationId: String!, $asset: SoftwareAssetInput!){
        createSoftwareAsset(asset: $asset, workspaceId: $workspaceId, organizationId: $organizationId){
            ...SoftwareAssetFragment
        }
    }
    ${SoftwareAssetFragment}
`)

export const updateSoftwareAsset = print(gql`
    mutation updateSoftwareAsset ($workspaceId: String!, $organizationId: String!, $assetId: String!, $asset: SoftwareAssetInput!){
        updateSoftwareAsset(asset: $asset, assetId: $assetId, workspaceId: $workspaceId, organizationId: $organizationId){
            ...SoftwareAssetFragment
        }
    }
    ${SoftwareAssetFragment}
`)

export const deleteSoftwareAsset = print(gql`
    mutation deleteSoftwareAsset ($workspaceId: String!, $organizationId: String!, $assetId: String!){
        deleteSoftwareAsset(assetId: $assetId, workspaceId: $workspaceId, organizationId: $organizationId){
            message
        }
    }
`)


export const exportCsvSoftwareAssets = print(gql`
    query exportCsvSoftwareAssets($organizationId: String!, $workspaceId: String!, $fieldKeys:[String!]!, $isDownload: Boolean!){
        exportCsvSoftwareAssets(workspaceId: $workspaceId, organizationId: $organizationId, fieldKeys: $fieldKeys, isDownload: $isDownload)
    }
`)

export const importCsvTemplateSoftwareAssets = print(gql`
    query importCsvTemplateSoftwareAssets($organizationId: String!, $workspaceId: String!){
        importCsvTemplateSoftwareAssets(workspaceId: $workspaceId, organizationId: $organizationId)
    }
`)

//Settings
export const getSoftwareAssetSettings = print(gql`
    query getSoftwareAssetSettings($organizationId: String!, $workspaceId: String!){
        getSoftwareAssetSettings(organizationId: $organizationId, workspaceId: $workspaceId,) {
            ...SoftwareAssetSettingsFragment
        }
    }
    ${SoftwareAssetSettingsFragment}
`)

export const createSoftwareAssetSettingRow = print(gql`
    mutation createSoftwareAssetSettingRow($organizationId: String!, $workspaceId: String!, $field: String!, $title: String!){
        createSoftwareAssetSettingRow(organizationId: $organizationId, workspaceId: $workspaceId, field: $field, title: $title) {
            ...SoftwareAssetSettingsFragment
        }
    }
    ${SoftwareAssetSettingsFragment}
`)

export const editSoftwareAssetSettingRow = print(gql`
    mutation editSoftwareAssetSettingRow($organizationId: String!, $workspaceId: String!, $field: String!, $title: String!, $oldTitle: String!, $withHistoryChange: Boolean){
        editSoftwareAssetSettingRow(organizationId: $organizationId, workspaceId: $workspaceId, field: $field, title: $title, oldTitle: $oldTitle, withHistoryChange: $withHistoryChange) {
            ...SoftwareAssetSettingsFragment
        }
    }
    ${SoftwareAssetSettingsFragment}
`)

export const deleteSoftwareAssetSettingRow = print(gql`
    mutation deleteSoftwareAssetSettingRow($organizationId: String!, $workspaceId: String!, $field: String!, $title: String!, $oldTitle: String!, $withHistoryChange: Boolean){
        deleteSoftwareAssetSettingRow(organizationId: $organizationId, workspaceId: $workspaceId, field: $field, title: $title, oldTitle: $oldTitle, withHistoryChange: $withHistoryChange) {
            ...SoftwareAssetSettingsFragment
        }
    }
    ${SoftwareAssetSettingsFragment}
`)

export const releaseSoftwareAssets = print(gql`
    mutation releaseSoftwareAssets ($workspaceId: String!, $organizationId: String!, $frameworkId: String!, $controlId: String!, $evidenceId: String!, $reviewers: [ReleaseActorInput!]!, $readers: [ReleaseActorInput!]!, $signers: [ReleaseActorInput!]!){
        releaseSoftwareAssets(workspaceId: $workspaceId, organizationId: $organizationId, frameworkId: $frameworkId, controlId: $controlId, evidenceId: $evidenceId, reviewers: $reviewers, readers: $readers, signers: $signers){
            message
        }
    }
`)

//SAAS
export const getSaasAssets = print(gql`
    query getSaasAssets ($workspaceId: String!, $organizationId: String!, $pageInfo:PaginationInput!){
        getSaasAssets(pageInfo: $pageInfo, workspaceId: $workspaceId, organizationId: $organizationId){
            data {...SaasAssetFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${SaasAssetFragment}
`)

export const getSaasAssetById = print(gql`
    query getSaasAssetById($organizationId: String!, $workspaceId: String!, $id: String!){
        getSaasAssetById(id: $id, organizationId: $organizationId, workspaceId: $workspaceId) {
            ...SaasAssetFragment
        }
    }
    ${SaasAssetFragment}
`)

export const createSaasAsset = print(gql`
    mutation createSaasAsset ($workspaceId: String!, $organizationId: String!, $asset: SaasAssetInput!){
        createSaasAsset(asset: $asset, workspaceId: $workspaceId, organizationId: $organizationId){
            ...SaasAssetFragment
        }
    }
    ${SaasAssetFragment}
`)

export const updateSaasAsset = print(gql`
    mutation updateSaasAsset ($workspaceId: String!, $organizationId: String!, $assetId: String!, $asset: SaasAssetInput!){
        updateSaasAsset(asset: $asset, assetId: $assetId, workspaceId: $workspaceId, organizationId: $organizationId){
            ...SaasAssetFragment
        }
    }
    ${SaasAssetFragment}
`)

export const deleteSaasAsset = print(gql`
    mutation deleteSaasAsset ($workspaceId: String!, $organizationId: String!, $assetId: String!){
        deleteSaasAsset(assetId: $assetId, workspaceId: $workspaceId, organizationId: $organizationId){
            message
        }
    }
`)


export const exportCsvSaasAssets = print(gql`
    query exportCsvSaasAssets($organizationId: String!, $workspaceId: String!, $fieldKeys:[String!]!, $isDownload: Boolean!){
        exportCsvSaasAssets(workspaceId: $workspaceId, organizationId: $organizationId, fieldKeys: $fieldKeys, isDownload: $isDownload)
    }
`)

export const importCsvTemplateSaasAssets = print(gql`
    query importCsvTemplateSaasAssets($organizationId: String!, $workspaceId: String!){
        importCsvTemplateSaasAssets(workspaceId: $workspaceId, organizationId: $organizationId)
    }
`)

//Settings
export const getSaasAssetSettings = print(gql`
    query getSaasAssetSettings($organizationId: String!, $workspaceId: String!){
        getSaasAssetSettings(organizationId: $organizationId, workspaceId: $workspaceId,) {
            ...SaasAssetSettingsFragment
        }
    }
    ${SaasAssetSettingsFragment}
`)

export const createSaasAssetSettingRow = print(gql`
    mutation createSaasAssetSettingRow($organizationId: String!, $workspaceId: String!, $field: String!, $title: String!){
        createSaasAssetSettingRow(organizationId: $organizationId, workspaceId: $workspaceId, field: $field, title: $title) {
            ...SaasAssetSettingsFragment
        }
    }
    ${SaasAssetSettingsFragment}
`)

export const editSaasAssetSettingRow = print(gql`
    mutation editSaasAssetSettingRow($organizationId: String!, $workspaceId: String!, $field: String!, $title: String!, $oldTitle: String!, $withHistoryChange: Boolean){
        editSaasAssetSettingRow(organizationId: $organizationId, workspaceId: $workspaceId, field: $field, title: $title, oldTitle: $oldTitle, withHistoryChange: $withHistoryChange) {
            ...SaasAssetSettingsFragment
        }
    }
    ${SaasAssetSettingsFragment}
`)

export const deleteSaasAssetSettingRow = print(gql`
    mutation deleteSaasAssetSettingRow($organizationId: String!, $workspaceId: String!, $field: String!, $title: String!, $oldTitle: String!, $withHistoryChange: Boolean){
        deleteSaasAssetSettingRow(organizationId: $organizationId, workspaceId: $workspaceId, field: $field, title: $title, oldTitle: $oldTitle, withHistoryChange: $withHistoryChange) {
            ...SaasAssetSettingsFragment
        }
    }
    ${SaasAssetSettingsFragment}
`)

export const releaseSaasAssets = print(gql`
    mutation releaseSaasAssets ($workspaceId: String!, $organizationId: String!, $frameworkId: String!, $controlId: String!, $evidenceId: String!, $reviewers: [ReleaseActorInput!]!, $readers: [ReleaseActorInput!]!, $signers: [ReleaseActorInput!]!){
        releaseSaasAssets(workspaceId: $workspaceId, organizationId: $organizationId, frameworkId: $frameworkId, controlId: $controlId, evidenceId: $evidenceId, reviewers: $reviewers, readers: $readers, signers: $signers){
            message
        }
    }
`)

// Certificate
export const getCertificateAssets = print(gql`
    query getCertificateAssets ($workspaceId: String!, $organizationId: String!, $pageInfo:PaginationInput!){
        getCertificateAssets(pageInfo: $pageInfo, workspaceId: $workspaceId, organizationId: $organizationId){
            data {...CertificateAssetFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${CertificateAssetFragment}
`)

export const getCertificateAssetById = print(gql`
    query getCertificateAssetById($organizationId: String!, $workspaceId: String!, $id: String!){
        getCertificateAssetById(id: $id, organizationId: $organizationId, workspaceId: $workspaceId) {
            ...CertificateAssetFragment
        }
    }
    ${CertificateAssetFragment}
`)

export const createCertificateAsset = print(gql`
    mutation createCertificateAsset ($workspaceId: String!, $organizationId: String!, $asset: CertificateAssetInput!, $file: String!){
        createCertificateAsset(asset: $asset, workspaceId: $workspaceId, organizationId: $organizationId, file: $file){
            ...CertificateAssetFragment
        }
    }
    ${CertificateAssetFragment}
`)

export const updateCertificateAsset = print(gql`
    mutation updateCertificateAsset ($workspaceId: String!, $organizationId: String!, $assetId: String!, $asset: CertificateAssetInput!, $file: String, $fileName: String){
        updateCertificateAsset(asset: $asset, assetId: $assetId, workspaceId: $workspaceId, organizationId: $organizationId, file: $file, fileName: $fileName){
            ...CertificateAssetFragment
        }
    }
    ${CertificateAssetFragment}
`)

export const deleteCertificateAsset = print(gql`
    mutation deleteCertificateAsset ($workspaceId: String!, $organizationId: String!, $assetId: String!){
        deleteCertificateAsset(assetId: $assetId, workspaceId: $workspaceId, organizationId: $organizationId){
            message
        }
    }
`)


export const exportCsvCertificateAssets = print(gql`
    query exportCsvCertificateAssets($organizationId: String!, $workspaceId: String!, $fieldKeys: [String!]!, $isDownload: Boolean!){
        exportCsvCertificateAssets(workspaceId: $workspaceId, organizationId: $organizationId, fieldKeys: $fieldKeys, isDownload: $isDownload)
    }
`)

export const importCsvTemplateCertificateAssets = print(gql`
    query importCsvTemplateCertificateAssets($organizationId: String!, $workspaceId: String!){
        importCsvTemplateCertificateAssets(workspaceId: $workspaceId, organizationId: $organizationId)
    }
`)

//Settings
export const getCertificateAssetSettings = print(gql`
    query getCertificateAssetSettings($organizationId: String!, $workspaceId: String!){
        getCertificateAssetSettings(organizationId: $organizationId, workspaceId: $workspaceId,) {
            ...CertificateAssetSettingsFragment
        }
    }
    ${CertificateAssetSettingsFragment}
`)

export const createCertificateAssetSettingRow = print(gql`
    mutation createCertificateAssetSettingRow($organizationId: String!, $workspaceId: String!, $field: String!, $title: String!){
        createCertificateAssetSettingRow(organizationId: $organizationId, workspaceId: $workspaceId, field: $field, title: $title) {
            ...CertificateAssetSettingsFragment
        }
    }
    ${CertificateAssetSettingsFragment}
`)

export const editCertificateAssetSettingRow = print(gql`
    mutation editCertificateAssetSettingRow($organizationId: String!, $workspaceId: String!, $field: String!, $title: String!, $oldTitle: String!, $withHistoryChange: Boolean){
        editCertificateAssetSettingRow(organizationId: $organizationId, workspaceId: $workspaceId, field: $field, title: $title, oldTitle: $oldTitle, withHistoryChange: $withHistoryChange) {
            ...CertificateAssetSettingsFragment
        }
    }
    ${CertificateAssetSettingsFragment}
`)

export const deleteCertificateAssetSettingRow = print(gql`
    mutation deleteCertificateAssetSettingRow($organizationId: String!, $workspaceId: String!, $field: String!, $title: String!, $oldTitle: String!, $withHistoryChange: Boolean){
        deleteCertificateAssetSettingRow(organizationId: $organizationId, workspaceId: $workspaceId, field: $field, title: $title, oldTitle: $oldTitle, withHistoryChange: $withHistoryChange) {
            ...CertificateAssetSettingsFragment
        }
    }
    ${CertificateAssetSettingsFragment}
`)

export const releaseCertificateAssets = print(gql`
    mutation releaseCertificateAssets ($workspaceId: String!, $organizationId: String!, $frameworkId: String!, $controlId: String!, $evidenceId: String!, $reviewers: [ReleaseActorInput!]!, $readers: [ReleaseActorInput!]!, $signers: [ReleaseActorInput!]!){
        releaseCertificateAssets(workspaceId: $workspaceId, organizationId: $organizationId, frameworkId: $frameworkId, controlId: $controlId, evidenceId: $evidenceId, reviewers: $reviewers, readers: $readers, signers: $signers){
            message
        }
    }
`)

export const getShortPhysicalAssets = print(gql`
    query getShortPhysicalAssets($organizationId: String!, $workspaceId: String!){
        getShortPhysicalAssets(organizationId: $organizationId, workspaceId: $workspaceId) {
            shortPhysicalAssets {
                id
                assetId
            }
        }
    }
`)


export const getCsvFieldsPhysicalAssets = gql`
    mutation getCsvFieldsPhysicalAssets($workspaceId: String!, $file: String!, $name: String!){
        getCsvFieldsPhysicalAssets(workspaceId: $workspaceId, file: $file, name: $name) {
            fileId
            fields {
                ...importCsvFragmentFields
            }
        }
    }
    ${importCsvFragmentFields}
`

export const performCsvPhysicalAssets = gql`
    mutation performCsvPhysicalAssets($workspaceId: String!, $fileId: String!, $fields: [ImportCsvFieldInput!]!){
        performCsvPhysicalAssets(workspaceId: $workspaceId, fileId: $fileId, fields: $fields) {
            ...performCsvFragment
        }
    }
    ${performCsvFragment}
`

export const getCsvFieldsSAAS = gql`
    mutation getCsvFieldsSAAS($workspaceId: String!, $file: String!, $name: String!){
        getCsvFieldsSAAS(workspaceId: $workspaceId, file: $file, name: $name) {
            fileId
            fields {
                ...importCsvFragmentFields
            }
        }
    }
    ${importCsvFragmentFields}
`

export const performCsvSAAS = gql`
    mutation performCsvSAAS($workspaceId: String!, $fileId: String!, $fields: [ImportCsvFieldInput!]!){
        performCsvSAAS(workspaceId: $workspaceId, fileId: $fileId, fields: $fields) {
            ...performCsvFragment
        }
    }
    ${performCsvFragment}
`

export const getCsvFieldsCertificate = gql`
    mutation getCsvFieldsCertificate($workspaceId: String!, $file: String!, $name: String!){
        getCsvFieldsCertificate(workspaceId: $workspaceId, file: $file, name: $name) {
            fileId
            fields {
                ...importCsvFragmentFields
            }
        }
    }
    ${importCsvFragmentFields}
`

export const performCsvCertificate = gql`
    mutation performCsvCertificate($workspaceId: String!, $fileId: String!, $fields: [ImportCsvFieldInput!]!){
        performCsvCertificate(workspaceId: $workspaceId, fileId: $fileId, fields: $fields) {
            ...performCsvFragment
        }
    }
    ${performCsvFragment}
`

export const getCsvFieldsSoftware = gql`
    mutation getCsvFieldsSoftware($workspaceId: String!, $file: String!, $name: String!){
        getCsvFieldsSoftware(workspaceId: $workspaceId, file: $file, name: $name) {
            fileId
            fields {
                ...importCsvFragmentFields
            }
        }
    }
    ${importCsvFragmentFields}
`

export const performCsvSoftware = gql`
    mutation performCsvSoftware($workspaceId: String!, $fileId: String!, $fields: [ImportCsvFieldInput!]!){
        performCsvSoftware(workspaceId: $workspaceId, fileId: $fileId, fields: $fields) {
            ...performCsvFragment
        }
    }
    ${performCsvFragment}
`

export const getCsvFieldsVirtual = gql`
    mutation getCsvFieldsVirtual($workspaceId: String!, $file: String!, $name: String!){
        getCsvFieldsVirtual(workspaceId: $workspaceId, file: $file, name: $name) {
            fileId
            fields {
                ...importCsvFragmentFields
            }
        }
    }
    ${importCsvFragmentFields}
`

export const performCsvVirtual = gql`
    mutation performCsvVirtual($workspaceId: String!, $fileId: String!, $fields: [ImportCsvFieldInput!]!){
        performCsvVirtual(workspaceId: $workspaceId, fileId: $fileId, fields: $fields) {
            ...performCsvFragment
        }
    }
    ${performCsvFragment}
`

export const mainAssetsGetRequestsWithFilterPagination = gql`
    query mainAssetsGetRequestsWithFilterPagination($workspaceId: String!, $pageRequest: SharedPaginationInput!, $filter: MainAssetsRequestsFilters!) {
        mainAssetsGetRequestsWithFilterPagination(workspaceId: $workspaceId, pageRequest: $pageRequest, filter: $filter) {
            result {...mainAssetsRequestFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${mainAssetsRequestFragment}
`

export const mainAssetsGetAssetRequestRequestorsAutocomplete = gql`
    query mainAssetsGetAssetRequestRequestorsAutocomplete($workspaceId:String!, $pageRequest:SharedPaginationInput!, $search: String!){
        mainAssetsGetAssetRequestRequestorsAutocomplete(workspaceId:$workspaceId, pageRequest:$pageRequest, search: $search){
            result {
                publicId
                fullName
            }
            pageInfo{
                page
                count
                total
            }
        }
    }
`

export const mainAssetsGetAssetRequestById = gql`
    query mainAssetsGetAssetRequestById($workspaceId: String!, $id: String!) {
        mainAssetsGetAssetRequestById(workspaceId: $workspaceId, requestId: $id) {
            request {
                ...mainAssetsRequestFragment
            }
            asset {
                ...mainAssetsRequestAssetFragment
            }
        }
    }
    ${mainAssetsRequestFragment}
    ${mainAssetsRequestAssetFragment}
`

export const mainAssetsRequestsReject = gql`
    mutation mainAssetsRequestsReject($workspaceId: String!, $data: MainAssetsApproveRequestWithCommentInput!) {
        mainAssetsRequestsReject(workspaceId: $workspaceId, data: $data) {
            message
        }
    }
`

export const mainAssetsApproveRequestRequestAsset = gql`
    mutation mainAssetsApproveRequestRequestAsset($workspaceId: String!, $data: MainAssetsApproveRequestWithCommentInput!) {
        mainAssetsApproveRequestRequestAsset(workspaceId: $workspaceId, data: $data) {
            id
            message
        }
    }
`

export const mainAssetsReassignAsset = gql`
    mutation mainAssetsReassignAsset($workspaceId: String!, $data: MainAssetsApproveRequestWithCommentInput!) {
        mainAssetsReassignAsset(workspaceId: $workspaceId, data: $data) {
            id
            message
        }
    }
`

export const mainAssetsAssignNotAssignedAsset = gql`
    mutation mainAssetsAssignNotAssignedAsset($workspaceId: String!, $data: MainAssetsApproveRequestWithCommentInput!) {
        mainAssetsAssignNotAssignedAsset(workspaceId: $workspaceId, data: $data) {
            id
            message
        }
    }
`

export const mainAssetsCreateAndAssignAsset = gql`
    mutation mainAssetsCreateAndAssignAsset($workspaceId: String!, $data: MainAssetsCreateAndAssignRequestInput!) {
        mainAssetsCreateAndAssignAsset(workspaceId: $workspaceId, data: $data) {
            id
            message
        }
    }
`

export const mainAssetsApproveRequestRemoveAsset = gql`
    mutation mainAssetsApproveRequestRemoveAsset($workspaceId: String!, $data: MainAssetsApproveRequestWithCommentWithStatusInput!) {
        mainAssetsApproveRequestRemoveAsset(workspaceId: $workspaceId, data: $data) {
            id
            message
        }
    }
`
