import React, {FC, useEffect, useState} from "react";
import {Alert, AppBar, Box, Button, Collapse, IconButton, Menu, Toolbar, Typography} from "@mui/material";
import {Apps, Menu as MenuIcon} from "@mui/icons-material";

import {useMainTranslation} from "../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useTopBar} from "./hooks/useTopBar";

import colors from "../../../newShared/theme/colors";
import {TopBarHelp} from "./components/topBarHelp";
import {TopBarSettings} from "./components/topBarSettings";
import {TopBarAvatar} from "./components/topBarAvatar";
import {TopBarWorkspace} from "./components/topBarWorkspace";
import {useLeftMenu} from "../leftMenu/hooks/useLeftMenu";
import {LABEL, PATH_LOCAL_LOGIN} from "../../../newShared/constants";
import {useHistory} from "react-router";
import {ThreeDotsTopBar} from "./components/threeDotsTopBar";
import {useMedia} from "../../../newShared/hooks/useMedia";
import {showUnProtectedTopBar} from "../shell/helpers";
import {useAlerts} from "../snack/hooks/useAlerts";
import {
    UseManageWorkspacesAndOrganizations
} from "../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
// import {useAlertOnChangeWorkspace} from "../snack/hooks/useAlertOnChangeWorkspace";
import {TobBarLogo} from "../../../newShared/components/TobBarLogo";
import {diffBetweenDateAndNow} from "../../../newShared/utils/dateTools";
import {FREE_PLAN, SETTINGS_ORGANIZATIONS_EXACT_ORG_PLANS_PATH} from "../../authWorkspacesCookies/settings/constants";
import {ReactComponent as PortalIcon} from "../../../newShared/images/other/portalIcon.svg";
import {ReactComponent as ReportsIcon} from "../../../newShared/images/other/reportsIcon.svg";
import {TopBarMenuItem} from "./components/topBarMenuItem";

type TProps = {
    isHidden: boolean,
}
export const TopBar: FC<TProps> = ({isHidden}) => {
    const {t, revDir, currentLanguage} = useMainTranslation('', {keyPrefix: 'TopBar'});
    const history = useHistory();

    const {currData: {
        currentUser, selectedWorkspace, selectedOrganization, is2FaAuthComplete
    }} = UseManageWorkspacesAndOrganizations();

    const {
        anchorPageEl, handlePageOpen, handlePageClose,
        handleGoToReports, handleGoToPortal,
    } = useTopBar();

    const {isOpen, setOpen, secondMenuWidth} = useLeftMenu();
    const {isDesktop, matches_1440Down} = useMedia();

    const {alerts, removeAlert} = useAlerts();
    const orgExpiresIn = diffBetweenDateAndNow(selectedOrganization?.subscriptionExpiry ?? '', currentLanguage.momentLocale);
    const isFreeOrg = selectedOrganization?.subscription === FREE_PLAN;
    const handleGoToOrgPlans = () => {
        selectedOrganization && history.push(SETTINGS_ORGANIZATIONS_EXACT_ORG_PLANS_PATH.replace(':orgId', selectedOrganization.id));
    }

    const [isSecondMenuExists, setIsSecondMenuExists] = useState<boolean>(false);

    //eslint-disable-next-line
    useEffect(() => {
        const leftSecondMenu = document.getElementById('leftSecondMenu');
        setIsSecondMenuExists(leftSecondMenu !== null);
        //eslint-disable-next-line
    });


    // useAlertOnChangeWorkspace();

    // console.log(`TOPBAR: ${JSON.stringify(currData)}`);
    // Logging.log(`TOPBAR: ${JSON.stringify(currData)}`);
    // console.log(`IS_REGULAIT`, IS_REGULAIT, window.config.REACT_APP_LABEL)
    return (
        <>
            {!isHidden ? (
                <AppBar position={"static"} style={{minHeight: matches_1440Down ? '48px' : undefined}}
                        sx={{
                            zIndex: (theme) => theme.zIndex.drawer + 30,
                            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)'
                        }} >
                    <Toolbar>
                        {!showUnProtectedTopBar(history.location.pathname) && (
                            <IconButton
                                size={"large"}
                                sx={{color: isOpen ? colors.primary.blue : colors.text.grey_dark}}
                                onClick={() => {
                                    setOpen()
                                }}
                            >
                                <MenuIcon/>
                            </IconButton>
                        )}

                        <TobBarLogo />

                        {!showUnProtectedTopBar(history.location.pathname) && (
                            isDesktop ? (
                                <>
                                    {currentUser?.publicId && (
                                        <Button variant={"text"} onClick={handlePageOpen} sx={{padding: '8px 4px', textTransform: 'none'}}>
                                            <Box p={'0 0 2px 0'} borderBottom={'2px solid ' + Boolean(anchorPageEl) ? colors.primary.blue : colors.text.grey_dark}>
                                                <Typography variant={"body2"} color={colors.text.grey_dark}>{t('page.Main app')}</Typography>
                                            </Box>
                                            <Apps sx={{
                                                color: Boolean(anchorPageEl) ? colors.primary.blue : colors.text.grey_dark,
                                                margin: !revDir ? '0 0 0 8px' : '0 8px 0 0'
                                            }}/>
                                        </Button>
                                    )}
                                    <Menu
                                        keepMounted
                                        anchorEl={anchorPageEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        open={Boolean(anchorPageEl)}
                                        onClose={handlePageClose}
                                    >
                                        <TopBarMenuItem title={`${LABEL} Portal`} text={t('page.A centralized hub for employees')} onClick={handleGoToPortal}>
                                            <PortalIcon width={'34px'}/>
                                        </TopBarMenuItem>
                                        <TopBarMenuItem title={t('page.Audit Reports')} text={t('page.A centralized hub for auditors')} onClick={handleGoToReports}>
                                            <ReportsIcon width={'34px'}/>
                                        </TopBarMenuItem>
                                    </Menu>

                                    <Box height={'100%'} minWidth={'10px'} flexGrow={1}/>


                                    {is2FaAuthComplete && currentUser?.publicId && selectedWorkspace && selectedOrganization && <TopBarWorkspace/>}
                                    {/*{is2FaAuthComplete && currentUser?.publicId && <TopBarNotifications/>}*/}
                                    <TopBarHelp/>
                                    {is2FaAuthComplete && currentUser?.publicId && <TopBarSettings/>}
                                    {is2FaAuthComplete && currentUser?.publicId && <TopBarAvatar/>}
                                </>
                            ) : (
                                <>
                                    <Box height={'100%'} minWidth={'10px'} flexGrow={1}/>
                                    {is2FaAuthComplete && currentUser?.publicId && <ThreeDotsTopBar/>}
                                </>
                            )
                        )}

                        {!showUnProtectedTopBar(history.location.pathname) && !currentUser?.publicId && (
                            <Button onClick={() => {history.push(PATH_LOCAL_LOGIN)}}>{t('Login')}</Button>
                        )}
                    </Toolbar>
                </AppBar>
            ) : null}

            <Box sx={{
                zIndex: theme => theme.zIndex.drawer - 1,
                width: isSecondMenuExists ? `calc(100% - ${secondMenuWidth}px)` : '100%',
                // left: secondMenuWidth,
                position: 'relative',
                alignSelf: 'end',
                // top: '64px',
            }}>
                {alerts.map(alert => (
                    <Collapse key={alert.key}
                              in={alert.isOpen}>
                        <Alert severity={alert.variant} variant={"filled"}
                               onClose={() => {removeAlert(alert.key)}}
                        >
                            {alert.message}
                        </Alert>
                    </Collapse>
                ))}

                {selectedOrganization && isFreeOrg && <Alert severity={'error'} variant={"filled"} sx={{padding: '4px 8px'}} icon={false}
                >
                    <Typography variant={'*bodyText2_semibold'} color={colors.text.white} gap={'4px'}>
                        {('Free Plan is discontinued on September 10, 2023. ')}
                        <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={handleGoToOrgPlans}>{'Send request'}</span>
                        {(' to upgrade your plan.')}
                    </Typography>
                </Alert>}

                {selectedOrganization && !isFreeOrg && orgExpiresIn <=10 && <Alert severity={'error'} variant={"filled"} sx={{padding: '4px 8px'}} icon={false}
                >
                    <Typography variant={'*bodyText2_semibold'} color={colors.text.white}>
                        Your organization will expire in {Math.floor(orgExpiresIn)} days.
                    </Typography>
                </Alert>}
            </Box>
        </>
    )
}