import {TCreateFormDoc} from "./types";

export const PATH_LOCAL_VICA = '/vica';

export const PATH_VICA_DASHBOARD = `${PATH_LOCAL_VICA}/dashboard`;
export const PATH_VICA_TASKS = `${PATH_LOCAL_VICA}/tasks`;
export const PATH_VICA_FINDINGS = `${PATH_LOCAL_VICA}/findings`;

//tasks statuses
export const COMPLETE_STATUS = 'COMPLETE';
export const IN_PROGRESS_STATUS = 'IN_PROGRESS';
export const NOT_STARTED_STATUS = 'NOT_STARTED';

//action types
export const ACTION_LINK_TYPE = 'LINK';
export const ACTION_UPLOAD_TYPE = 'UPLOAD';
export const ACTION_TEMPLATE_TYPE = 'TEMPLATE';

//default paging
export const TASKS_DEFAULT_PAGING = 30;

//
export const EVIDENCE_UPLOAD_TYPE = 'FILE';

export const defaultFormCreateDoc = {
    workspaceId: '',
    name: '',
    data: null,
    folderId: '',
    tags: [],
    editors: [],
    approvers: [],
    recipients: [],
} satisfies TCreateFormDoc;
