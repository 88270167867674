import {styled, TableCell} from "@mui/material";
import {TypographyBody2} from "../../../../../../../newShared/components/Inputs/styled";


export const ClientDetailsTableCell = styled(TableCell)(() => ({
    padding: '10px 16px',
    width: '50%',
}));

export const TextBody2 = styled(TypographyBody2)(() => ({
    textWrap: 'wrap',
}));
