import {gql} from "@apollo/react-hooks";

export const reportFragment = gql`
    fragment reportFragment on GeneratedReportModel {
        id
        name
        reportStatus
        reportType
        generated
        archived
        ncrEnabled
        auditor {
            id
            name
            email
        }
        auditorTeam {
            id
            name
            email
        }
        framework {
            id
            name
            logo
        }
    }
`

export const NCR_Fragment = gql`
    fragment NCR_Fragment on NcrModel {
        id
        workspaceId
        reportId
        frameworkId
        controlId
        status
        createDate
        createAuditorId
        type
        observed
        clause
        clauseDescription
        requestHistory {
            causeAnalysis
            correctiveAction {
                description
                proposedCompletionDate
                authorPublicId
            }
            evidenceAssets {
                assetId
                fileName
            }
            approveAuditor
            approveDate
            correctiveActionAccepted
            comment
        }
    }
`

export const reportControlFragment = gql`
    fragment reportControlFragment on ReportControlModel {
        id
        name
        description
        reviewed
        
        evidences {
            statements {
                id
                name
                lastCollected
                data
                type
                evidenceId
            }
            automations {
                id
                name
                lastCollected
                data
                type
                evidenceId
            }
            files {
                id
                name
                lastCollected
                data
                type
                evidenceId
            }
        }

        ncr {
            ...NCR_Fragment
        }
    }
    ${NCR_Fragment}
`

export const reportAppFragment = gql`
    fragment reportAppFragment on ReportAppModel {
        id
        name
        logo
        description
        config {
            collectionPrefix
            collectionConfigs {
                name
                alias
                headers
                data
            }
        }
    }
`