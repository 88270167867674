import {Flex} from "../../../../../newShared/components/Layouts";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import React from "react";
import {useMedia} from "../../../../../newShared/hooks/useMedia";

//icons
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useHistory} from "react-router";
import {useHrIssues} from "../../hooks/useHrIssues";
import {PATH_LOCAL_EMPLOYEES_ISSUES, PATH_LOCAL_EMPLOYEES_ISSUES_EXACT, statusFilterTitleMapper} from "../../constants";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {HrIssuesStatus, MainHrIssueModel, MainHrIssuesFilter} from "../../../../../newShared/GQLTypes";
import {Skeleton} from "@mui/material";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {PATH_LOCAL_EMPLOYEES} from "../../../../../newShared/constants";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {mainHrIssuesOffendersAutocompleteApi, mainHrIssuesReportersAutocompleteApi} from "../../api";
import {TIdName} from "../../../../../newShared/types";
import {getHrIssueStatusChip} from "../../helpers";
//

export const EmployeesIssuesList = () => {
    const {t, tMenu} = useMainTranslation('', {keyPrefix: 'pathHRManagement.pathIssuesList'});
    const {isLessThen1050, isMobile} = useMedia();

    const history = useHistory();

    const {
        issues, pageInfo, minMaxLoadedPage, isLoading: {issuesList},
        setMinMaxLoadedPage,
        clearRows,
        getIssues,
    } = useHrIssues();


    const handleOpenIssue = (issueId: string) => {
        history.push(PATH_LOCAL_EMPLOYEES_ISSUES_EXACT.replace(':issueId', issueId));
    }

    useSetBreadcrumbs([
        {
            title: tMenu('HR Management'),
            path: PATH_LOCAL_EMPLOYEES
        },
        {
            title: tMenu('Employees issues'),
            path: PATH_LOCAL_EMPLOYEES_ISSUES
        },
    ]);

    const issueTypeFilter: keyConfig<string> = {
        type: 'filter',
        key: 'issueType',
        name: t('Issue type'),
        options: ['Sexual harassment', 'Discrimination', 'Money laundering', 'Theft', 'Fraud', 'ESG Violation', 'Conflict of interest', 'Other'],
        default: true
    };
    const issueStatusFilter: keyConfig<HrIssuesStatus> = {
        type: 'filter',
        key: 'status',
        name: t('Status'),
        options: Object.values(HrIssuesStatus),
        getOptionLabel: (option) => statusFilterTitleMapper[option],
        default: true
    };
    const offenderFilter: keyConfig<TIdName> = {
        type: 'filter',
        key: 'offender',
        name: t('Offender'),
        getOptionLabel: (option) => option.name,
        getFilterValue: (option) => option.id,
        isOptionEqual: (option, value) => option.id === value.id,
        fetchOptions: (workspaceId, data) => {
            return mainHrIssuesOffendersAutocompleteApi(workspaceId, data)
                .then(resp => ({values: resp.result, pageInfo: resp.pageInfo}))
        },
        default: true
    };
    const reporterFilter: keyConfig<TIdName> = {
        type: 'filter',
        key: 'requestor',
        name: t('Reporter'),
        topOptions: [{id: 'Anonymous', name: 'Anonymous' }],
        getOptionLabel: (option) => option.name,
        getFilterValue: (option) => option.id,
        isOptionEqual: (option, value) => option.id === value.id,
        fetchOptions: (workspaceId, data) => {
            return mainHrIssuesReportersAutocompleteApi(workspaceId, data)
                .then(resp => ({values: resp.result, pageInfo: resp.pageInfo}))
        },
        default: true
    };

    const config: keyConfig<any>[] = [
        issueTypeFilter, issueStatusFilter, offenderFilter, reporterFilter,
    ];

    return (
        <Flex w={'100%'} h={'100%'} direction={'column'} overflow={'hidden'}>
            <PageTitleAndActions
                title={tMenu('Employees issues')}
                showMenuWhen={isLessThen1050}
                actions={[]}
            />

            <GenericTable<MainHrIssueModel>
                id={'employeesIssuesList'}
                columnsConfig={{totalName: 'Total issues', forceShowCardsWhen: isMobile}}
                paging={{
                    pageInfo,
                    isLoading: issuesList,
                    minLoadedPage: minMaxLoadedPage.minLoadedPage,
                    maxLoadedPage: minMaxLoadedPage.maxLoadedPage,
                    setMinMaxLoadedPage,
                    clearRows,
                    fetchPaging: (page: number, count: number, filter: MainHrIssuesFilter, search) => {
                        getIssues({page, count}, {...filter, issueIdLike: search});
                    }
                }}
                rowsConfig={{
                    rows: issues,
                    rowActionsConfig: [],
                    getRowUniqueId: (row) => row.id,
                    onRowClick: (row) => handleOpenIssue(row.id),
                    columnsConfigs: [
                        {key: "id", name: t('Issue ID'), default: true},
                        {key: 'issueType', name: t('Issue type'), default: true},
                        {key: 'offender', name: t('Offender'), default: true, valueGetter: (row) => row.offender?.fullName},
                        {key: 'requestDate', name: t('Reported date'), default: true, valueGetter: (row) => parseDateAuto(row.requestDate, false)},
                        {key: 'reporter', name: t('Reporter'), default: true, valueGetter: (row) => row.anonymous ? 'Anonymous' : row.requestor?.fullName},
                        {key: 'status', name: t('Status'), default: true, valueRenderer: (row) => getHrIssueStatusChip(row.status), customSkeleton: <Skeleton width={94} height={32} variant={'rectangular'}/>},
                        {key: 'resolver', name: t('Resolved'), default: true, valueGetter: (row) => row.resolver?.employeeFullName || row.resolver?.collaboratorFullName},
                        {key: 'resolveDate', name: t('Resolved date'), default: true, valueGetter: (row) => parseDateAuto(row.resolveDate, false)},
                    ]
                }}
                filtersConfig={{
                    dontIncludeSearchInFilter: true,
                    genericFilterProps: {
                        configs: config,
                        collapsable: false,
                        isAddFilters: false,
                        fetchResultWithSelectedFilters: () => null,
                    }
                }}
            />
        </Flex>
    )
}
