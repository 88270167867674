import {useDispatch, useSelector} from "react-redux";
import {
    assetsDialogsSelector,
    loadings,
    selectedAssetSelector,
    selectedCertificate,
    selectedSaas,
    selectedSoftware,
    selectedVirtual,
    setDeleteAssetDialogAction
} from "../../store/store";
import {
    deleteCertificateAssetAction,
    deletePhysicalAssetAction,
    deleteSaasAssetAction,
    deleteSoftwareAssetAction,
    deleteVirtualAssetAction
} from "../../store/actions";
import {useHistory} from "react-router-dom";
import {
    PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATES,
    PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS,
    PATH_LOCAL_ASSET_MANAGEMENT_SAAS,
    PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE,
    PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS
} from "../../constants";

export const useDeleteAssetDialog = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const _selectedPhysical = useSelector(selectedAssetSelector);
    const _selectedVirtual = useSelector(selectedVirtual);
    const _selectedSoftware = useSelector(selectedSoftware);
    const _selectedSaas = useSelector(selectedSaas);
    const _selectedCertificate = useSelector(selectedCertificate);

    const {
        deleteAsset: {isOpen, response: responseDelete, assetType},
    }= useSelector(assetsDialogsSelector);


    const handleDeleteAsset = () => {
        if (assetType === 'physical') dispatch(deletePhysicalAssetAction({workspaceId: '', organizationId: '', assetId: _selectedPhysical?.id || ''}));
        if (assetType === 'virtual') dispatch(deleteVirtualAssetAction({workspaceId: '', organizationId: '', assetId: _selectedVirtual?.id || ''}));
        if (assetType === 'software') dispatch(deleteSoftwareAssetAction({workspaceId: '', organizationId: '', assetId: _selectedSoftware?.id || ''}));
        if (assetType === 'saas') dispatch(deleteSaasAssetAction({workspaceId: '', organizationId: '', assetId: _selectedSaas?.id || ''}));
        if (assetType === 'certificate') dispatch(deleteCertificateAssetAction({workspaceId: '', organizationId: '', assetId: _selectedCertificate?.id || ''}));
    }

    const handleCancelDeleteAsset = () => {
        dispatch(setDeleteAssetDialogAction({clear: true}));
    }

    const handleCloseDeleteDialog = () => {
        dispatch(setDeleteAssetDialogAction({clear: true}));
        if (assetType === 'physical') history.push(PATH_LOCAL_ASSET_MANAGEMENT_PHYSICAL_ASSETS);
        if (assetType === 'virtual') history.push(PATH_LOCAL_ASSET_MANAGEMENT_VIRTUAL_ASSETS);
        if (assetType === 'software') history.push(PATH_LOCAL_ASSET_MANAGEMENT_SOFTWARE);
        if (assetType === 'saas') history.push(PATH_LOCAL_ASSET_MANAGEMENT_SAAS);
        if (assetType === 'certificate') history.push(PATH_LOCAL_ASSET_MANAGEMENT_CERTIFICATES);
    }


    return {
        isOpen,
        isLoadingExact: useSelector(loadings).isLoadingExact,
        responseDelete,
        handleDeleteAsset,
        handleCancelDeleteAsset,
        handleCloseDeleteDialog,
    }
}