import * as S from "../styled";
import colors from "../../../../../newShared/theme/colors";
import Divider from "@mui/material/Divider";

export type customTableRowPropsType = {
    onClick?: () => void;
    disableHover?: boolean;
    width?: string | undefined;
    innerWrapperWidth?: string | undefined;
    innerWrapperJc?: string | undefined;
    minWidth?: string | undefined;
    divider?: boolean;
    children: JSX.Element[]
}

export const CustomTableRow = ({onClick = () => null, disableHover, width, innerWrapperWidth, innerWrapperJc, minWidth, divider, children}: customTableRowPropsType) => {
    return(
        <>
            <S.MainTableRowWrapper onClick={onClick} width={width} minWidth={minWidth} disableHover={disableHover}>
                <S.TableRowWrapper width={innerWrapperWidth} jc={innerWrapperJc} disableHover={disableHover}>
                    {children}

                    {/*<S.TableRowItemWrapper width={'25%'}>*/}
                    {/*    <Typography sx={{color: colors.darkText, fontSize: '16px', fontWeight: 400}}>{app.name}</Typography>*/}
                    {/*</S.TableRowItemWrapper>*/}
                </S.TableRowWrapper>
            </S.MainTableRowWrapper>
            {divider && <Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable}}/>}
        </>
    )
}