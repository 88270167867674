import styled from "styled-components";

export const FilterButton = styled.button<{css: string}>`
    ${props => props.css};
      border: none;
      position: relative;
      gap: 6px;
      border-radius:4px;
      display: flex;
      align-items: center;
      padding: 4.5px 6px;
`;

export const AddFilterButton = styled.button<{css: string}>`
    ${props => props.css};
      border: none;
      //margin: 0 10px;
      position: relative;
      gap: 6px;
      border-radius:4px;
      display: flex;
      align-items: center;
      padding: 4.5px 6px;
      max-width: 250px;
`;