import React, {FC} from "react";
import {Box, Divider, Skeleton, Tab, Tabs, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {AvatarGroup} from "../../../../../newShared/components/AvatarGroup";
import {TabEvidences} from "../../components/tabEvidences";
import {TabComments} from "../../components/tabComments";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {EvidenceView} from "../evidenceView";
import {AnalysisView} from "../analysisView";
import ReactMarkdown from "react-markdown";
import styles from './styles.module.css';
import {ControlAssignmentsDialog} from "../../components/dialogs/controlAssignmentsDialog";
import {useControlViewPage} from "./hooks/useControlViewPage";
import {MinimizeInfoWrapper} from "../../../../../newShared/components/minimizeInfoWrapper";
import {useGoInPast} from "../../../../barsEnvironment/breadcrumbs/hooks/usePathHistory";
import {PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED} from "../../../../../newShared/constants";
import {PageWrapper} from "../../../../../newShared/components/Layouts";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {TabDelegatedTasks} from "../../components/tabDelegatedTasks";
import {DocViewerDialog} from "../../../../../newShared/components/docViewer";
//import {CreateDelegatedTaskDialog} from "../../components/dialogs/createDelegatedTaskDialog";
//import {CancelDelegatedTask} from "../../components/dialogs/cancelDelegatedTaskDialog";
import {useDelegatedTasksPage} from "../../hooks/useDelegatedTasks";
import {CreateDelegatedTaskDialog} from "../../components/dialogs/createDelegatedTaskDialog";

export const ControlView: FC = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathFrameworks.pathFrameworkControls'})
    const {isLessThen1050} = useMedia();
    const {refreshTable} = useDelegatedTasksPage();

    const {
        evidenceId, analysisId, historyId, frId,
        evidencesOpen, commentsOpen, analysisOpen, delegatedTasksOpen,
        // openFramework,
        control,
        setCollaboratorsDialog,
        openCreateDelegatedTask,
        tabs
    } = useControlViewPage();

    const goInPast = useGoInPast({path: `${PATH_LOCAL_COMPLIANCE_FRAMEWORKS_ADOPTED}/${frId}`});

    return (
        <Box display={"flex"} width={'100%'} height={'100%'}>
            <PageWrapper
                 flexBasis={(evidenceId || analysisId) ? '50%' : "100%"}
                 display={(evidenceId || analysisId) && isLessThen1050 ? 'none' : "flex"}>
                <PageTitleAndActions
                    title={control?.name}
                    actions={[
                        {
                            title: t('Create delegated task'),
                            onClick: openCreateDelegatedTask,
                            variant: 'contained',
                            size: "small"
                        },
                    ]}
                    handleGoBack={() => {frId && goInPast()}}
                />

                <MinimizeInfoWrapper>
                    {control && (
                        <Box display={"flex"} alignItems={"center"} mb={'10px'}>
                            <Typography variant={"subtitle2"} sx={{margin: !revDir ? '0 10px 0 0' : '0 0 0 10px'}}>{t('Collaborators')}</Typography>
                            <AvatarGroup assignments={control?.collaborators || []}
                                         handleAddButton={() => {setCollaboratorsDialog({isOpen: true})}}
                                         handleDelete={() => {setCollaboratorsDialog({isOpen: true})}}/>
                        </Box>
                    )}

                    {control ? <Box sx={{overflowY: 'scroll', maxHeight: '200px'}}>{(control?.description || []).map((description, index) => (
                        <ReactMarkdown className={styles.FrameworkControlDescription} key={description}>
                            {description}
                        </ReactMarkdown>
                    ))}</Box> : (
                        <>
                            <Typography variant={"body2"} color={colors.text.grey_dark} maxWidth={'800px'} margin={'4px'}>{<Skeleton width={'200px'}/>}</Typography>
                            <Typography variant={"body2"} color={colors.text.grey_dark} maxWidth={'800px'} margin={'4px'}>{<Skeleton width={'200px'}/>}</Typography>
                            <Typography variant={"body2"} color={colors.text.grey_dark} maxWidth={'800px'} margin={'4px'}>{<Skeleton width={'200px'}/>}</Typography>
                        </>
                    )}
                </MinimizeInfoWrapper>

                <Tabs value={evidencesOpen ? 'Evidences' : commentsOpen ? 'Comments' : analysisOpen ? 'Analysis' : delegatedTasksOpen ? 'Delegated tasks' : null}
                      sx={{marginTop: '8px', marginBottom: '20px'}}
                >
                    {tabs.map(tab => (
                        <Tab key={tab.title} label={t(tab.title)} value={tab.title} onClick={tab.handleClick}/>
                    ))}
                </Tabs>

                {evidencesOpen && (
                    <TabEvidences/>
                )}
                {commentsOpen && (
                    <TabComments/>
                )}

                {delegatedTasksOpen &&  (
                    <TabDelegatedTasks />
                )}

                {/*{analysisOpen && (*/}
                {/*    <TabAnalysis/>*/}
                {/*)}*/}

            </PageWrapper>

            {/*RightSide*/}
            {(evidenceId) && (
                <>
                    {!isLessThen1050 && <Divider flexItem orientation={"vertical"} variant={"fullWidth"} sx={{margin: '0 40px', borderColor: colors.backgrounds.grey_dark}}/>}
                    <EvidenceView/>
                </>
            )}

            {(analysisId && historyId) && (
                <>
                    {!isLessThen1050 && <Divider flexItem orientation={"vertical"} variant={"fullWidth"} sx={{margin: '0 40px', borderColor: colors.backgrounds.grey_dark}}/>}
                    <AnalysisView/>
                </>
            )}

            <ControlAssignmentsDialog/>


            <CreateDelegatedTaskDialog
                refreshTable={refreshTable}
                delegatedTasksOpen={delegatedTasksOpen}
            />
            <DocViewerDialog />

        </Box>
    )
}
