import {useDispatch, useSelector} from "react-redux";
import {
    assetsDialogsSelector,
    assetsSettingsSelector,
    incomingRequestsSelector,
    loadings,
    setApproveRequestRemoveDialogAction
} from "../../../store/store";
import {getPhysicalAssetsSettingsAction, mainAssetsApproveRequestRemoveAssetAction} from "../../../store/actions";
import * as yup from "yup";
import {useFormik} from "formik";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {PhysicalAssetsStatus, PhysicalAssetStatusModel} from "../../../../../../newShared/GQLTypes";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {getName} from "../../../../../../newShared/utils/text";
import {PATH_LOCAL_ASSETS_INCOMING_REQUESTS} from "../../../constants";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useHistory, useLocation} from "react-router";
import {usePhysicalAssetsRequestsExact} from "../../usePhysicalAssetsRequestsExact";
import React from "react";

type TResponse = {
    isOpen: boolean,
    isLoading: boolean,

    handleOpen: () => void,
    handleClose: () => void,
} & Record<string, any>;

export const useApproveRequestRemoveAsset = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathPhysicalAssets.pathIncomingRequests'});
    const {setMessage} = useMessageDialog();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const {selected} = useSelector(incomingRequestsSelector);
    const requestId = selected?.request?.id;
    const settings = useSelector(assetsSettingsSelector);

    const {approveRequestRemove: {isOpen}} = useSelector(assetsDialogsSelector);
    const {approveRequest, isLoadingSettings} = useSelector(loadings);

    const handleOpen = () => {
        dispatch(setApproveRequestRemoveDialogAction({isOpen: true}))
    }
    const handleClose = () => {
        dispatch(setApproveRequestRemoveDialogAction({clean: true}))
    }

    const {getRequestById} = usePhysicalAssetsRequestsExact();
    const {currData: {currentUser}} = UseManageWorkspacesAndOrganizations();
    const handleSubmit = (resolution: string, status: PhysicalAssetStatusModel) => {
        requestId && dispatch(mainAssetsApproveRequestRemoveAssetAction({
            data: {data: {requestId: requestId, resolution, status}},
            addictiveData: {resolver: {id: currentUser?.publicId || '', name: getName(currentUser?.firstName || '', currentUser?.lastName || '')}},
            onError: (request, _error, addictiveData) => {
                const errors = _error.e409;
                if (errors?.length) {
                    getRequestById(requestId);
                }
            },
            onSuccess: (request, response) => {
                setMessage({
                    title: t('Completed successfully'),
                    message: t(`Asset with id {{assetId}} was unassigned from {{fullName}}`, {assetId: response.id, fullName: selected.request.requestor.fullName} )
                });
                history.push(`${PATH_LOCAL_ASSETS_INCOMING_REQUESTS}${location.search}`);
            },
        }))
    }

    const validationSchema = yup.object({
        comment: yup.string()
            // .required(t('Comment field is required'))
            .nullable()
            .max(1000, t('Comment should be less than 1000 symbols')),
        status: yup.object()
            .required(t('Status is required'))
            .nullable()
            .shape({
                name: yup.string().oneOf(settings?.status.map(e => e.name) || [], t('Should be in settings')),
                // status: yup.string().oneOf(settings?.status.map(e => e.status) || [], t('')),
            })
    });

    const formik = useFormik<{comment: string, status: PhysicalAssetStatusModel | null}>({
        initialValues: {comment: '', status: null},
        validationSchema,
        onSubmit: (values) => {
            values.status && handleSubmit(values.comment, values.status);
        }
    })
    const formikOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldTouched(e.target.name, true, false);
        formik.handleChange(e);
    };

    const getSettings = () => dispatch(getPhysicalAssetsSettingsAction({data: {organizationId: '', workspaceId: ''}}));

    return {
        isOpen,
        isLoading: approveRequest,
        isLoadingSettings,

        handleOpen,
        handleClose,

        formik: {...formik, handleChange: formikOnChange},
        selected,
        statuses: settings?.status.filter(e => e.status.toLowerCase() !== PhysicalAssetsStatus.Deployed.toLowerCase()) || [],

        getSettings,
    } satisfies TResponse;
};