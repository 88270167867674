import {useRequestExact} from "../../../hooks/requests/useRequestExact";
import {PageContainer} from "../../../components/styled/index";
import {PageTitleAndActions} from "../../../../../../newShared/components/pageTitleAndActions";
import * as S from './styled';
import {FieldTitleText, FieldValueText} from './styled';
import {Flex} from "../../../../../../newShared/components/Layouts";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import colors from "../../../../../../newShared/theme/colors";
import {IconButton, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {FILE_FIELD_TYPE} from "../../../constants";
import React from "react";
import {getFieldValue} from "../../../helpers";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const RequestsExact = () => {
    const {request, actions, dicts} = useRequestExact();
    const {t} = useMainTranslation('', {keyPrefix: 'pathKYCRefactor.requests'});

    return(
        <PageContainer>
            <PageTitleAndActions
                title={request ? t('Request {{id}}', {id: request.id}) : undefined}
                handleGoBack={actions.handleGoBack}
                actions={[]}
            />

            <Flex direction={'column'} overflowy={'auto'}>
                <S.ExactRequestContainer margin={'16px 0 0 0'}>
                    <S.ExactRequestContainerTitle>{t('Client data')}</S.ExactRequestContainerTitle>

                    <Flex ai={'center'} m={'20px 0 0 0'} gap={'32px'}>

                        <Table sx={{width: 'auto'}} size={"small"}>
                            <TableBody>
                                {request && request.filledFields
                                    .filter(e => e.type !== FILE_FIELD_TYPE)
                                    .slice()
                                    .sort((a, b) => a.order - b.order)
                                    .map(e => {
                                        return (
                                            <TableRow key={e.id}>
                                                <TableCell sx={{borderBottom: 'none', paddingInlineStart: 0, paddingBottom: '10px'}}>
                                                    <FieldTitleText>{e.name}:</FieldTitleText>
                                                </TableCell>
                                                <TableCell sx={{width: '20px', borderBottom: 'none', paddingBottom: '10px'}}/>
                                                <TableCell sx={{borderBottom: 'none', paddingInlineStart: 0, paddingBottom: '10px'}}>
                                                    <FieldValueText>{getFieldValue(e, dicts)}</FieldValueText>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                            </TableBody>
                        </Table>

                        {/*<S.ExactRequestTextUnionContainer>*/}
                        {/*    {request && request.filledFields*/}
                        {/*        .filter(e => e.type !== FILE_FIELD_TYPE)*/}
                        {/*        .slice()*/}
                        {/*        .sort((a, b) => a.order - b.order)*/}
                        {/*        .map(e => {*/}
                        {/*        return  <S.FieldTitleText>{e.name}:</S.FieldTitleText>*/}
                        {/*    })}*/}
                        {/*</S.ExactRequestTextUnionContainer>*/}

                        {/*<S.ExactRequestTextUnionContainer>*/}
                        {/*    {request && request.filledFields*/}
                        {/*        .filter(e => e.type !== FILE_FIELD_TYPE)*/}
                        {/*        .slice()*/}
                        {/*        .sort((a, b) => a.order - b.order)*/}
                        {/*        .map(e => {*/}
                        {/*        return  <FieldValueText>{e.type === 'DATE' ? parseDate(e.value, currentLanguage.momentLocale) : ((e.value?.length ?? 0) > 0 ? e.value : '-')}</FieldValueText>*/}
                        {/*    })}*/}
                        {/*</S.ExactRequestTextUnionContainer>*/}
                    </Flex>
                </S.ExactRequestContainer>

                {request && request.filledFields.filter(e => e.type === FILE_FIELD_TYPE).length > 0 &&
                    <S.ExactRequestContainer margin={'10px 0 0 0'}>
                        <S.ExactRequestContainerTitle>{t('Documents')}</S.ExactRequestContainerTitle>

                        <Flex m={'20px 0 0 0'} gap={'32px'} direction={'column'}>
                            {request && request.filledFields
                                .filter(e => e.type === FILE_FIELD_TYPE)
                                .slice()
                                .sort((a, b) => a.order - b.order)
                                .map(e => {
                                return  <Flex ai={'center'} gap={'32px'} >
                                    <S.FieldTitleText>{e.name}:</S.FieldTitleText>
                                    {e.fileName !== null && e.fileName !== undefined ?
                                        <FileBlock fileName={e.fileName} fileId={e.value ?? ''} onDownload={actions.handleDownloadFile}/>
                                        :
                                        <Typography variant={'body1'} color={colors.text.grey_dark}>{t('No file uploaded')}</Typography>}
                                </Flex>
                            })}
                            {/*<S.ExactRequestTextUnionContainer>*/}
                            {/*    {request && request.filledFields.filter(e => e.type === FILE_FIELD_TYPE).map(e => {*/}
                            {/*        return  <Flex ai={'center'} gap={'32px'}>*/}
                            {/*            <S.FieldTitleText>{e.name}:</S.FieldTitleText>*/}
                            {/*            {e.fileName !== null && e.fileName !== undefined ?*/}
                            {/*                <FileBlock fileName={e.fileName} fileId={e.value ?? ''} onDownload={actions.handleDownloadFile}/>*/}
                            {/*                :*/}
                            {/*                <Typography variant={'body1'} color={colors.text.critical}>{('No file uploaded')}</Typography>}*/}
                            {/*        </Flex>*/}
                            {/*    })}*/}
                            {/*</S.ExactRequestTextUnionContainer>*/}

                            {/*<S.ExactRequestTextUnionContainer>*/}
                            {/*    {request && request.filledFields.filter(e => e.type === FILE_FIELD_TYPE).map(e => {*/}
                            {/*        return  e.fileName !== null && e.fileName !== undefined ?*/}
                            {/*            <FileBlock fileName={e.fileName} fileId={e.value ?? ''} onDownload={actions.handleDownloadFile}/>*/}
                            {/*            :*/}
                            {/*            <Typography variant={'body1'} color={colors.text.critical}>{('No file uploaded')}</Typography>*/}
                            {/*    })}*/}
                            {/*</S.ExactRequestTextUnionContainer>*/}
                        </Flex>
                    </S.ExactRequestContainer>
                }
            </Flex>
        </PageContainer>
    )
}

export const FileBlock = ({fileName, fileId, onDownload}: {fileName: string, fileId: string, onDownload: (fieldId: string, fileName: string) => void}) => {
    return(
        <S.FileContainer>
            <Flex ai={'center'} gap={'8px'}>
                <InsertDriveFileIcon sx={{color: colors.primary.blue_light}} />
                <Typography variant={'body2'} color={colors.grayText}>{fileName}</Typography>
            </Flex>

            <IconButton size={'small'} onClick={() => onDownload(fileId, fileName)}>
                <FileDownloadIcon color={'primary'} />
            </IconButton>
        </S.FileContainer>
    )
}